export default {
  "ccm.agent.label.promptParameters": "Tono de Prompt",
  "ccm.agent.label.queueParameters": "Cola",
  "ccm.agent.label.queueTransferType": "Tipo de objeto transferido",
  "ccm.agent.label.queueTransferObj": "Tipo de objeto transferido",
  "ccm.agent.label.busyRatePrefix": "Cuando el uso del dispositivo excede ",
  "ccm.agent.label.busyRateSuffix": "Las llamadas no se pueden transferir a la cola.",
  "ccm.agent.label.busyJudging": "Transferencia de llamadas en ocupado o desbordamiento",
  "ccm.agent.label.deviceUsageThresholdPrefix": "Cuando el uso del dispositivo es mayor o igual a",
  "ccm.agent.label.deviceUsageThresholdSuffix": ". La cola está ocupada.",
  "ccm.agent.label.waitTimePrefix": "Cuando la duración de espera prevista excede",
  "ccm.agent.label.waitTimeSuffix": ". La cola está ocupada.",
  "ccm.agent.label.queuingCallsMaxNumPrefix": "Cuando el número de llamadas en cola excede",
  "ccm.agent.label.queuingCallsMaxNumSuffix": ": la cola se desborda.",
  "ccm.agent.label.scaleFactorPrefix": "Cuando el número de llamadas en cola excede (",
  "ccm.agent.label.scaleFactorSuffix": "x Número de agentes que han iniciado sesión), los desbordamientos de cola.",

  "ccm.satisfactionconfig.group.createsuccess": "El grupo de directivas se agrega correctamente",
  "ccm.satisfactionlevel.title.templateupdate": "Plantilla de actualización",
  "ccm.satisfactionconfig.group.deletesuccess": "El grupo de directivas se elimina correctamente",
  "ccm.satisfactionconfig.strategy.createsuccess": "Lograr añadir la política de satisfacción del canal",
  "ccm.satisfactionconfig.strategy.updateuccess": "Lograr modificar la política de satisfacción del canal",
  "ccm.satisfactionconfig.strategy.deletesuccess": "Se logró eliminar la política de satisfacción del canal",
  "ccm.satisfactionconfig.strategy.surveytyperequired": "El tipo de encuesta no puede estar vacío",

  "ccm.transfer.number.pop.create.title": "Creación de un número externo reenviado",
  "ccm.transfer.number.pop.modify.title": "Editar número externo reenviado",
  "ccm.transfer.number.pop.delete.title": "Eliminar",
  "ccm.transfer.number.pop.delete.message": "¿Está seguro de que desea eliminar el número externo seleccionado?",
  "ccm.transfer.number.search.number": "Por favor, introduzca el número",
  "ccm.transfer.number.search.name": "Introduzca un nombre",
  "ccm.agent.button.coloumedit":"Configuración de campo",
  "ccm.transfer.number.label.index": "Índice",
  "ccm.transfer.number.label.number": "Número",
  "ccm.agent.message.addSkill": "Adición de cola de habilidades",
  "ccm.satisfactiontemplate.message.confirm":"Confirme si se debe actualizar el contenido de la plantilla en función del nivel de satisfacción.",
  "ccm.satisfactiontemplate.message.queryfail":"Error al consultar la plantilla de encuesta de satisfacción.",
  "ccm.satisfactiontemplate.tipscontent":"La plantilla de mensaje de encuesta de satisfacción se utiliza para enviar mensajes de encuesta de satisfacción a los clientes en los canales de Instagram, Telegram, WhatsApp, Línea, Chat Web, Face Book, X (Twitter), 5G RCS y SMS.",
  "ccm.satisfaction.changetips":"Nota: Se cambia la configuración del nivel de satisfacción. Modifique la plantilla de encuesta de satisfacción en consecuencia.",
  "ccm.satisfactionconfig.message.question":"La plantilla de mensaje de encuesta de satisfacción se utiliza para enviar mensajes de encuesta de satisfacción a los clientes en los canales de Instagram, Telegram, WhatsApp, LINE, Chat Web, Facebook, X (Twitter), 5G RCS y SMS.",
  "ccm.satisfactionlevel.satislevel.config":"Configuración de nivel de satisfacción",
  "ccm.satisfactionlevel.satislevel.button":"Botones",
  "ccm.satisfactionlevel.satislevel.description":"Descripcion",
  "ccm.satisfactionlevel.satislevel.operat":"Operación",
  "ccm.satisfactionlevel.satislevel.moveup":"Arriba",
  "ccm.satisfactionlevel.satislevel.movedown":"Abajo",
  "ccm.satisfactionconfig.msg.templateenpty": "Seleccione una plantilla de SMS.",
  "ccm.satisfactionconfig.msg.timelimitenpty": "El tiempo de respuesta válido es nulo.",
  "ccm.transfer.number.label.name": "Nombre",
  "ccm.transfer.number.label.flag": "Reenvío de número de usuario",
  "ccm.transfer.number.label.flag.yes": "Sí",
  "ccm.transfer.number.label.flag.no": "No",
  "ccm.pageinterface.custTableConfig": "Configuración básica de la tabla",

  "ccm.transtask.label.friday": "Viernes",
  "ccm.ccagent.management.additions":"Adiciones",
  "ccm.skillgroup.message.skillgroup": "Introduzca un nombre de grupo de habilidades.",
  "ccm.skillgroup.label.searchname": "Habilidades Grupo Nombre",
  "ccm.agent.label.waitVoiceSelect": "Seleccione un tono de espera",
  "ccm.skillgroup.label.groupid": "Números",
  "ccm.callreason.case.check.error": "El tipo de orden de trabajo {0} no existe.",
  "ccm.skillgroup.label.groupname": "Nombre",
  "ccm.skillgroup.label.orgname": "Organización",
  "ccm.agent.label.skillgroup": "Grupo de habilidades",
  "ccm.skillgroup.label.groupskills": "Cola de habilidades asociada",
  "ccm.skillgroup.title.add": "Agregar un grupo de habilidades",
  "ccm.skillgroup.title.update": "Actualizar grupo de habilidades",
  "ccm.agent.label.noAgents": "Procesamiento de llamadas a nadie en servicio",
  "ccm.custdata.operlog.refresh": "Refrescar",
  "ccm.agent.label.waitTimeRange": "Rango de valores: [0-9999]",
  "ccm.agent.contact.isExistTalkReason": "Motivo de llamada establecido o no",
  "ccm.agent.contact.QUERU_SFTP_FAIL_CODE": "La información SFTP está vacía.",
  "ccm.note.config.notename": "Nombre",
  "ccm.certificate.management.update.certfile": "Actualizar archivo de certificado",
  "ccm.certificate.management.add.title": "Adición de un certificado",
  "ccm.certificate.management.update.title": "Actualización de certificados",
  "ccm.transtask.label.reportData": "Datos del informe",
  "ccm.agent.sysparamconfig.emailMaxRecipientsNoCheck": "El número máximo de destinatarios reenviados por los agentes de correo debe estar entre 1 y 50",
  "ccm.urlconfig.label.oauth.callback.alerttip": "Si se transfiere una URL de devolución de llamada cuando se invoca la API de creación de llamada bidireccional y se configura la siguiente información de autenticación, esta información se usa durante la devolución de llamada de la URL al liberar la llamada bidireccional. De lo contrario, no se realiza ninguna autenticación durante la devolución de llamada.",
  "ccm.certificate.management.certificate.crlfile.certhasexpired": "El certificado ha caducado.",
  "ccm.ivr.placeholder.ivrdesc": "Por favor, introduzca la descripción del proceso.",
  "ccm.calledconfig.message.nonumbers": "Por favor, elija números",
  "ccm.callout.lable.default": "Valor predeterminado",
  "ccm.verifyidentity.fail.USERIDPARSEERROR": "El número de verificación no puede contener caracteres especiales",
  "ccm.pageinterface.saveSuccess": "El conjunto de interfaz de página se guardo correctamente.",
  "ccm.speciallist.msg.selectonly": "Solo se puede seleccionar una lista especial para su modificación",
  "ccm.custdata.button.exportTemplate": "Plantilla de exportación",
  "ccm.chatbot.query.list.fail": "Error al obtener la lista de bots",
  "ccm.ivr.button.traceInfo": "Haga clic para ver el registro de seguimiento actual.",
  "ccm.verifyidentity.callId": "Identificador de llamadas",
  "ccm.verifyidentity.verifyName": "Verifique nombre",
  "ccm.contactitemconfig.label.data.item.identification": "ID de elemento de datos",
  "ccm.chatbot.query.name.fail": "Error al obtener el nombre del robot",
  "ccm.agent.message.pageLayoutFail": "El diseño de página o el número de filas no está seleccionado.",
  "ccm.speciallist.msg.suffix": "La extensión del nombre de archivo importado es incorrecta",
  "ccm.agent.leavemessage.downloadLeaveMessage": "Descargar mensaje",
  "ccm.custdata.operlog.exportEndTime": "Hora de finalización de exportación",
  "ccm.agent.restrecord.rest.currentstatus": "Estado actual",
  "ccm.agent.ccagentinfo.unregister.success": "Anular el registro de éxito",
  "ccm.speciallist.msg.tip": "Se puede importar un máximo de 1000 registros cada vez",
  "ccm.agent.message.createAgentAndSkillFail": "Error al crear la asociación de la cola de habilidades del agente.",
  "ccm.recognition.label.openBreakDown": "Corte de voz",
  "ccm.contact.detail.receiver": "Destinatario",
  "ccm.agent.button.close": "Cerrar",
  "ccm.certificate.management.type": "Tipo de certificado",
  "ccm.speciallist.msg.confirmdeletehis": "¿Está seguro de que desea eliminar el historial de la lista especial seleccionada?",
  "ccm.sysparam.success.save": "Éxito",
  "ccm.satisfactionconfig.message.accepttime": "Tiempo de manejo",
  "ccm.agent.label.callDispatchModel.incomingCall": "Asignación de habilidades de llamada entrante antes de la asignación de habilidades de llamada saliente",
  "ccm.custdata.label.status": "Estado",
  "ccm.recognition.label.breakDownVoice": "Corte de voz",
  "ccm.calledroute.title.selectskill": "Seleccionar cola de habilidades",
  "ccm.recognition.label.openIvrAgentSuccess": "El asistente de agente inteligente está habilitado correctamente.",
  "ccmanagement.satisfaction.strategy.accepttime.accepttime": "Tiempo de Manejo",
  "ccm.transtask.label.dataname": "Nombre del servidor de volcado",
  "ccm.agent.message.longCharacters": "El número puede contener un máximo de 24 caracteres.",
  "ccm.agent.message.longCharacters.form": "El número puede contener un máximo de 24 caracteres",
  "ccm.speciallist.msg.partexport": "¿Está seguro de que desea exportar los registros seleccionados?",
  "ccm.sysparam.config.itemname.tips": "Introduzca un nombre de parámetro.",
  "ccm.satisfactionconfig.message.chooseivrflow": "Elegir flujo IVR",
  "ccm.accesscode.label.accesscodedesc": "Descripción del código de acceso",
  "ccm.accesscode.title.accesscodedesc": "Editar descripción del código de acceso",
  "ccm.custdata.label.endTime": "Hora de finalización",
  "ccm.agent.calledroute.success": "Éxito",
  "ccm.botherlist.label.approveComment.toApprove": "Acordado",
  "ccm.agent.contact.assistantCallid": "ID de llamada colaborativa",
  "ccm.contact.customer.config.referenced": "El elemento de datos ha sido referenciado por el área de información del cliente de contacto.",
  "ccm.contact.record.data.referenced": "El elemento de datos ha sido referenciado por el elemento de datos del registro de contactos.",
  "ccm.agent.label.description": "Descripcion",
  "ccm.agent.label.endworkno": "Finalizar ID de empleado",
  "ccm.contactitemconfig.label.data.extended.dataset.source.interface": "Interfaz de origen de conjunto de datos",
  "ccm.transtask.label.day": "Día",
  "ccm.agent.message.updateCtiAndDbFail": "Error al actualizar cti y Db.",
  "ccm.transserver.label.obsnotinwhite": "Error en la operación. Póngase en contacto con el administrador del sistema para agregar la dirección del servicio OBS a la lista de confianza del servidor OBS de volcado de recursos.",
  "ccm.agent.recall.param.callid.empty": "No hay un número de devolución de llamada disponible.",
  "ccm.label.contactexport.customfield": "Campo personalizado",
  "ccm.agent.calledroute.placeholder.accesscode": "Seleccione un código de acceso.",
  "ccm.systemparam.success.refresh": "Parámetro actualizado correctamente.",
  "ccm.speciallist.msg.errorreason": "Causa de falla",
  "ccm.contactitemconfig.label.data.item.sequence.number": "No",
  "ccm.agent.pageurls.accessaddr": "Dirección de acceso",
  "ccm.custdata.operlog.confirmMessage": "¿Está seguro de crear una nueva tarea de exportación?",
  "ccm.recognition.label.orderfailpd": "Error en la suscripción. Inténtalo de nuevo más tarde.",
  "ccm.verifyidentity.NOTRETURN": "No volver",
  "ccm.urlconfig.label.xNumber": "Número X",
  "ccm.satisfactionconfig.message.cancel": "Cancelar",
  "ccm.agent.calledroute.editsuccess": "Se ha editado correctamente.",
  "ccm.speciallist.msg.transfererror": "Error al transferir al historial",
  "ccm.agent.leavemessage.status.unprocessed": "Sin procesar",
  "ccm.satisfactionconfig.message.comfirm": "¿Está seguro de que desea eliminar el grupo de directivas?",
  "ccm.agent.skill.skillExist": "ya existe. Introduzca otro nombre.",
  "ccm.agent.contact.audio-visual": "Audio/Video",
  "ccm.transtask.label.taskfail": "Error al crear la tarea porque se permite un máximo de {0} tareas del tipo de registro de contacto.",
  "ccm.ivr.label.processid": "ID de proceso",
  "ccm.agent.label.parameter": "Parámetro 1",
  "ccm.agent.title.agentEdit": "Configuración de información del agente",
  "ccm.speciallist.msg.modifyerror": "Error de modificación",
  "ccm.label.contactexport.selectdatafield": "Campo de exportación",
  "ccm.agent.contact.preCallout": "Vista previa de la llamada saliente",
  "ccm.agent.pageurls.pagetype": "Tipo de página",
  "ccm.agent.calledroute.create.accesscode.none": "El código de acceso al sistema configurado para la parte llamada no está asignado.",
  "ccm.transtask.label.vdnReport": "Reporte de tráfico VDN",
  "ccm.agent.tips.inputpassword": "Introduzca la contraseña de la cuenta de inicio de sesión actual.",
  "ccm.basicdata.msg.nodata": "Sin datos",
  "ccm.contact.customer.config.writeBackFieldError": "El campo de escritura de registro de contacto extendido no puede estar en uso.",
  "ccm.contact.customer.config.dataItemCodeError": "El identificador de elemento de datos es incorrecto.",
  "ccm.agent.restReason.restCauseTime.formate": "La duración restante debe estar en formato HH:MM:SS.",
  "ccm.agent.Transfer.theAgent": "Agente",
  "ccm.transtask.label.updateexsit": "Error en la operación porque el nombre del servidor ya existe.",
  "ccm.speciallist.msg.endtimeerror": "La hora de caducidad debe ser posterior a la hora actual",
  "ccm.custdata.button.moveDown": "Abajo",
  "ccm.custdata.option.isMaskDisplay.yes": "Sí",
  "ccm.contactitemconfig.label.data.extended.dictionary.value": "Valor del diccionario de datos",
  "ccm.verifyidentity.fail.WORKNOPARSEERROR": "El número de trabajo debe ser numérico",
  "ccm.transtask.label.monday": "Lunes",
  "ccm.satisfactionconfig.message.validatename": "El nombre del grupo de directivas no puede estar vacío ni duplicado.",
  "ccm.agent.ten.videoIvrchannels": "Cantidad de canal de video IVR",
  "ccm.satisfactionconfig.message.calltime": "Duración de llamada",
  "ccm.calledconfig.message.savemobileagentsuccess": "La configuración del agente móvil se guarda correctamente",
  "ccm.agent.contact.callin": "Llamada entrante",
  "ccm.custdata.msg.updatedItemNotExist": "El campo que se va a actualizar no existe. Actualice la página e inténtelo de nuevo.",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.no": "No",
  "ccm.agent.contact.vidio": "Video",
  "ccm.satisfactionconfig.message.failed": "Error",
  "ccm.transtask.label.skillAccessCodeReport": "Informe de tráfico de cola de habilidades por código de acceso",
  "ccm.agent.contact.callid": "ID de llamada",
  "ccm.agent.contact.contactdetail": "Datos de contacto",
  "ccm.custdata.operlog.exportTaskStatus": "Estado",
  "ccm.verifyidentity.ERROR": "Error",
  "ccm.agent.label.positiveEmotionNumber": "positivos",
  "ccm.speciallist.msg.view": "Vistas",
  "ccm.satisfactionconfig.message.smscloud": "SMS en la nube de Huawei",
  "ccm.agent.label.waitVoiceCustom": "Personalización del tono de espera",
  "ccm.agent.button.edit": "Editar",
  "ccm.agent.accesscode.description.limit": "La descripción del código de acceso no puede contener más de 50 caracteres.",
  "ccm.agent.calledroute.isconfdelete": "¿Está seguro de que desea eliminar la llamada seleccionada?",
  "ccm.custdata.operlog.exportBeginTime": "Exportar hora de inicio",
  "ccm.custdata.option.maskStyle.retainLast4": "Reserva los últimos cuatro dígitos",
  "ccm.agent.pageurls.updateinfo": "Actualizado correctamente.",
  "ccm.agent.moveup.error": "El movimiento hacia arriba falló.",
  "ccm.agent.moveup.success": "Mover hacia arriba con éxito.",
  "ccm.agent.movedown.error": "El movimiento hacia abajo falló.",
  "ccm.agent.movedown.success": "Bajar con éxito.",
  "ccm.custdata.label.tableName": "Nombre de tabla",
  "ccm.custdata.title.edit": "Editar datos",
  "ccm.custdata.title.coloum.add": "Creación de una columna de campo",
  "ccm.custdata.title.coloum.edit": "Editar columna de campo",
  "ccm.custdata.title.coloum.detail": "Consulta de detalles de campo",
  "ccm.urlconfig.label.input.appKey": "AppKey",
  "ccm.satisfactionconfig.message.numberlimit": "Se permite un máximo de 100 configuraciones de canal de encuesta de satisfacción en un espacio de inquilino.",
  "ccm.contactitemconfig.label.data.extended.Type": "Tipo de datos",
  "ccm.speciallist.button.modify": "Modificar",
  "ccm.levelMessage.title.modify": "Modificar administración de niveles",
  "ccm.speciallist.title.modify": "Modificar lista especial",
  "ccm.speciallistreminder.button.modify": "Modificar recordatorio de lista especial",
  "ccm.pageinterface.interfaceType.externalExplain": "Las interfaces externas se configuran en la página Administración de API. Póngase en contacto con el administrador del sistema.",
  "ccm.agent.contact.multimedia.web": "Multimedia-WEB",
  "ccm.pageurls.select.embedding": "Insertar",
  "ccm.agent.contact.talkcontent": "Contenido conversacional",
  "ccm.speciallist.msg.reviewerror": "Error en la aprobación",
  "ccm.agent.callreason.modify.failed": "Error al modificar el motivo de la llamada.",
  "ccm.agent.callreason.delete.confirm": "¿Está seguro de que desea eliminar el motivo de la llamada?",
  "ccm.speciallist.msg.sltype.illegal": "Tipo de lista especial no válida",
  "ccm.agent.contact.play": "Reproducir",
  "ccm.custdata.msg.selectItem": "Seleccione un elemento antes de ordenar.",
  "ccm.speciallevel.msg.deleteyerror": "No se pudo eliminar el nivel de lista especial.",
  "ccm.speciallist.button.finish": "Finalizar",
  "ccm.agent.message.allExport": "Exportar todo",
  "ccm.agent.updateSoftPhone.modifyLocalPwd": "Error al cambiar la contraseña del softphone local.",
  "ccm.custdata.msg.dictionaryValidateFailed": "Formato incorrecto del rango de valores del diccionario de datos",
  "ccm.contactitemconfig.label.data.item.basic": "Datos básicos",
  "ccm.contract.management.invalid.confirm": "¿Está seguro de que desea invalidar este documento?",
  "ccm.create.case.confirm": "El tipo de caso está vacío. Al crear caso, el tipo de caso debe seleccionarse manualmente. ¿Estás seguro de que quieres continuar?",
  "ccm.agent.message.deleteSkillAndAgentFail": "Error al eliminar la habilidad y el agente.",
  "ccm.agent.label.audio": "Agente de voz",
  "ccm.agent.label.on": "Encienda",
  "ccm.agent.login.ID": "Secuencia de operación",
  "ccm.satisfactionconfig.web.satisfaction.survey": "Web Channel Satisfaction Encuesta preguntas",
  "ccm.agent.calledroute.require": "Introduzca todos los campos obligatorios.",
  "ccm.agent.label.afterVoiceSelect": "Después de que se notifique el ID del agente",
  "ccm.agent.message.delete": "Borrar",
  "ccm.agent.message.pageInterface": "Interfaz de página",
  "ccm.recognition.resetSK.failed": "Error al restablecer el SK.",
  "ccm.agent.ten.anonymousSipInfo": "Dirección IP y número de puerto del servidor de llamadas anónimas",
  "ccm.agent.contact.endtime": "Hora de finalización",
  "ccm.custdata.msg.duplicateTableName": "Duplicar el nombre de la tabla. Cambia el nombre.",
  "ccm.calledconfig.message.callreasontips": "Por favor note: El TT asociado al nivel actual no está disponible. Seleccione una razón de llamada de subconjunto para establecer el TT asociado.",
  "ccm.agent.tips.confFail": "Error de configuración",
  "ccm.agent.pageurls.configpage": "Página Config",
  "ccm.agent.contact.called": "Número Llamado",
  "ccm.speciallist.operlog.downloadData": "Exportación de datos de lista especial",
  "ccm.systemparam.error.noparamselected": "Seleccione primero un parámetro.",
  "ccm.satisfactionconfig.message.webchannel": "Canal multimedia web",
  "ccm.agent.leavemessage.auditdate": "Tiempo de actualización",
  "ccm.agent.message.videoagentzero": "El recuento de agentes de vídeo es 0. No se puede configurar la cola de habilidades de clic para marcar de vídeo.",
  "ccmanagement.satisfaction.strategy.accepttime.timeEnd": "Hora final",
  "ccm.calledconfig.table.devtype": "Propósito Tipo de dispositivo",
  "ccm.agent.label.deviceUsageThresholdRange": "Rango de valores: [0-100]",
  "ccm.agent.message.cancelconfig": "Cancelar configuración",
  "ccm.agent.contact.preemptionCallout": "Llamada saliente preferida",
  "ccm.urlconfig.message.urlnotinwhite": "Error en la operación. Póngase en contacto con el administrador del sistema para agregar la dirección URL a la lista de confianza.",
  "ccm.agent.title.querysippwd": "Comprobar Contraseña de Softphone",
  "ccm.policy.label.updateVerificationFailed": "Falha ao verificar o parâmetro de solicitação.",
  "ccm.agent.label.scaleFactorRangeTwo": "Rango de valores: [1-10000]",
  "ccm.custdata.operlog.zippwd": "Contraseña comprimida",
  "ccm.dual.call.record.talkingTime": "Tiempo de respuesta de la parte llamada",
  "ccm.note.config.agent.ring.setUp": "Configuración del mensaje de solicitud",
  "ccm.dual.call.record.dualCallType": "Tipo de llamada bidireccional",
  "ccm.agent.leavemessage.processsuccess": "El mensaje se procesa correctamente.",
  "ccm.agent.contact.caller": "Número de llamada",
  "ccm.agent.calledroute.delete.skill.default": "La cola de habilidades privadas predeterminada hace referencia a la configuración de la parte llamada y no se puede eliminar.",
  "ccm.agent.label.transferType": "Método de proceso",
  "ccm.agent.restrecord.reststatus": "Estado de reposo",
  "ccm.contact.detail.view.message.content": "Ver",
  "ccmanagement.tenantspace.option.weeklist_firtolast": "Último",
  "ccm.agent.message.updateagentandskillfail": "Error al actualizar la asociación entre el agente y la cola de habilidades.",
  "ccm.agent.pageurls.delfail": "Error en la eliminación.",
  "ccm.agent.resetskillrecord.adjusttime": "Ajustar el tiempo",
  "ccm.contact.customer.config.value": "Valor de datos",
  "ccm.satisfactionconfig.message.comfirmtitle": "Confirm",
  "ccm.agent.operation.CONFERENCE": "Conferencia tripartita",
  "ccm.calledroute.button.cancel": "Cancelar",
  "ccm.urlconfig.label.input.domainName": "Nombre de dominio ITA",
  "ccm.agent.label.transfer": "Transferir",
  "ccm.taskrecord.message.reexecutetranstaskerror": "Error en la re-ejecución.",
  "ccm.agent.message.allExportAgent": "¿Está seguro de que desea exportar toda la información del agente?",
  "ccm.custdata.operlog.inputzippwd": "Introduzca la contraseña de compresión",
  "ccm.satisfactionlevel.message.samedesc": "Existe una descripción duplicada. Por favor, modificarlo.",
  "ccm.ucconfig.message.clientSecret": "Contraseña de la aplicación (cliente)",
  "ccm.agent.operation.countid": "Número de cuenta",
  "ccm.note.config.agent.page.ring.select": "Seleccionar tono de llamada",
  "ccm.agent.restReason.updateFailed": "Error en la modificación.",
  "ccm.agent.label.qualityChecker": "Comprobador de calidad",
  "ccm.agent.contact.playRecording": "reproducir una grabación",
  "ccm.agent.label.callDispatchModel.manySkills": "Agente con más habilidades",
  "ccm.custdata.msg.sortNoChange": "La clasificación no cambia.",
  "ccm.agent.contact.downloadFail": "Error en la descarga.",
  "ccm.policy.label.updateFailed": "Failed to update the policy information.",
  "casemanagement.user.button.ok": "OK",
  "ccm.agent.button.search": "Consultar",
  "ccm.agent.label.serviceAccountName": "Nombre de cuenta de Service Cloud",
  "ccm.contactitemconfig.label.contact.extended.information.dataset": "Conjunto de datos de información de contacto extendido",
  "ccm.agent.export.agentinfo.tips": "Los datos exportados contienen datos personales, que deben ser utilizados y protegidos correctamente.",
  "ccm.speciallist.msg.userNum": "Número de usuario",
  "ccm.transserver.delete.css.success": "La configuración de CSS se ha eliminado correctamente.",
  "ccm.agent.contact.calledfailed": "Error en la llamada saliente",
  "ccm.agent.updateSoftPhone.updateOpertionSuccess": "Advertencia",
  "ccm.idauth.title.edit": "Modificación del proceso de autenticación",
  "ccm.urlconfig.message.deletesuccess": "Error al restablecer las urls",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnumber": "El valor debe ser un entero positivo.",
  "ccm.ivr.label.processaccesscode": "Código de flujo",
  "ccm.agent.pageurlparam.channelassociateddata": "Datos asociados al canal",
  "ccm.agent.operation.AllOpType": "Todos los tipos de operación",
  "ccm.agent.Transfer.tranfermode": "Modo de transferencia",
  "ccm.calledconfig.table.selectnumbers": "Seleccionar números",
  "ccm.agent.protocol.check": "Tenga en cuenta que el protocolo de red utilizado por la dirección URL no es un protocolo seguro. Si decide utilizar este protocolo, pueden existir riesgos de seguridad.",
  "ccm.common.label.success": "Exitoso",
  "ccm.certificate.management.certificatefile.notupload": "Por favor, cargue el archivo cert.",
  "ccm.speciallist.msg.selectspeciallist": "Por favor seleccione una lista especial",
  "ccm.speciallist.msg.selectspeciallisthis": "Por favor seleccione un historial de lista especial",
  "ccm.agent.ten.companyName": "Empresa",
  "ccm.agent.label.calcOfMaxCallNum": "Método de cálculo de datos de cola de llamadas máximas",
  "ccm.speciallevel.speciallist.level": "Nivel",
  "ccm.agent.label.serverAddress": "Dirección del servidor de registro",
  "ccm.verifyidentity.fail.CALLIDPARSEERROR": "El identificador de llamada debe ser numérico.",
  "ccm.agent.pageurls.openMode": "Modo abierto",
  "ccm.agent.operation.noMoreThan1Days": "El intervalo de tiempo no puede exceder de 1 día.",
  "ccm.pageinterface.selectedAll": "Seleccionar todo",
  "ccm.calledconfig.message.deletemobileagentsuccess": "La configuración del agente móvil se elimina correctamente",
  "ccm.calledconfig.label.selectcalled": "Seleccionar Llamado",
  "ccm.note.config.agent.page.ring.warn": "Se recomienda realizar una prueba de llamada para evitar que el tono de llamada se superponga con el tono de llamada configurado en el teléfono",
  "ccmanagement.satisfaction.strategy.addsuccess": "Ahorrar con éxito.",
  "ccm.transtask.label.vdnAccessCodeReport": "Informe de tráfico de VDN por código de acceso",
  "ccm.calledroute.title.selectaccesscode": "Seleccione el código de acceso",
  "ccm.calledroute.tip.selectaccesscode": "Seleccione primero un código de acceso.",
  "ccm.botherlist.label.contactrecorddetail": "Datos de contacto",
  "ccm.transserver.delete.fail": "Error al eliminar el servidor de volcado.",
  "ccm.message.center.nocitiction.sending.records.status": "Estado",
  "ccm.agent.label.startworkno": "Iniciar ID de empleado",
  "ccm.agent.updateSoftPhone.queryNodeFailed": "Error al consultar la información del nodo del inquilino.",
  "ccm.agent.message.pageConfSuccess": "Operación exitosa.",
  "ccm.dual.call.record.caller": "Número de llamada",
  "ccm.agent.password.wrong": "Error al verificar la contraseña.",
  "ccm.satisfactionconfig.message.validtime": "Periodo de respuesta válido",
  "ccm.agent.label.negativeEmotionNumber": "negativos",
  "ccm.custdata.option.isMandatory.no": "No",
  "ccm.certificate.management.updatetime": "Tiempo de actualización",
  "ccm.speciallist.button.add": "Añadir",
  "ccm.levelMessage.title.add": "Creación de Gestión de Niveles",
  "ccm.speciallist.title.add": "Crear lista especial",
  "ccm.speciallistreminder.button.add": "Crear recordatorio de lista especial",
  "ccm.agent.calledroute.ivr": "Por favor elija IVR",
  "title.contact.agent.name": "Nombre de la cuenta de servicio",
  "ccm.agent.title.IVRTrace": "Rastreo IVR",
  "ccm.agent.videoDownload.RSETimeout": "Solicitar el tiempo de espera del servicio RSE",
  "ccm.agent.label.cti": "cti:",
  "ccm.custdata.option.columnType.string": "Cadena de texto",
  "ccm.speciallist.msg.crateerrorreason": "El número de abonado ya existe",
  "ccm.agent.contact.multimedia.instagram": "Multimedia-Instagram",
  "ccm.agent.message.agentConfSuccess": "La información del agente se configura correctamente.",
  "ccm.speciallevel.tip.stop": "¿Está seguro de que desea desactivar el servicio?",
  "ccm.agent.label.skillChoice": "Seleccionar habilidad",
  "ccm.agent.message.agentnumreachmax": "Número de tipo de agente de destino ya alcanzado el máximo",
  "ccm.agent.operation.ANSWER": "Respuestas",
  "ccm.speciallist.msg.overlimit": "La longitud del mensaje de recordatorio supera los 256 caracteres.",
  "ccm.transtask.label.abandonedRingReport": "Informe resumido sobre llamadas abandonadas durante el timbre",
  "ccm.contact.detail.message.sending.record": "Registro de envío de mensajes",
  "ccm.chatbot.invalid.empty": "El asistente de robot no puede estar vacío",
  "ccm.idauth.title.code": "Codificación del modo de autenticación",
  "ccm.custdata.msg.reachUpperTableNum": "El número de tablas personalizadas ha alcanzado el límite superior.",
  "ccm.agent.page.cancel.release": "Inédito",
  "ccm.recognition.label.cancelorderfail": "Error de cancelación de suscripción.",
  "ccm.agent.calledroute.extcode": "Código de extensión",
  "ccm.callback.url.auth.tip": "Modo de autenticación de URL de devolución de llamada",
  "ccm.agent.pageurls.maxcallingnum": ". Asegúrese de que el número de pantallas emergentes no exceda de 5.",
  "ccm.sysparam.error.validate.notmatchruleoption": "El parámetro de entrada no está en el rango de enumeración",
  "ccm.agent.calledroute.device": "Seleccione un dispositivo.",
  "ccm.agent.operation.WHISPER": "Susurro",
  "ccm.agent.contact.predictiveCall": "Llamada saliente predicha",
  "ccm.taskrecord.label.reexecute": "Volver a ejecutar",
  "ccm.agent.contact.others-channels": "Otros canales",
  "ccm.agent.contact.skillhungup": "Transferir a la cola de habilidades",
  "ccm.callout.lable.caller": "Llamador de llamada",
  "ccm.agent.sysparamconfig.positiveinteger": "Por favor, introduzca un entero positivo!",
  "ccm.certificate.management.scenename": "Descripción del certificado",
  "ccm.taskrecord.label.choosetask": "Seleccionar tarea",
  "ccm.agent.restrecord.reststatus.Abnormal.endrest": "El descanso terminó anormalmente",
  "iolp.configur.service.label.none.desc": "No hay ninguna configuración de categoría de servicio disponible. Cree primero una categoría de servicio.",
  "ccm.speciallist.msg.restores": "Restauraciones",
  "ccm.agent.calledroute.devicedesc": "Nombre del dispositivo",
  "ccm.agent.recall.fail": "Error al devolver la llamada al cliente.",
  "ccm.transtask.label.isOpenCssServer": "Especifica si se va a habilitar CSS.",
  "ccm.agent.mobileagent.label.deletehint": "¿Está seguro de borrar?",
  "ccm.agent.label.isaireconition": "VERDADERO",
  "ccm.calledconfig.message.nullparent": "El nodo principal no existe.",
  "ccm.speciallist.msg.applyTimeTo": "Hora de finalización de la aplicación",
  "ccm.ucconfig.message.updatesuccess": "Configuración de integración de UC se guardó correctamente.",
  "ccm.agent.restReason.isusing.warning": "Los códigos de razón de descanso están en vigor. Esto puede hacer que el motivo de descanso se muestre incorrectamente en el registro de descanso.",
  "ccm.agent.contact.callerPresent": "Número de usuario",
  "ccm.custdata.label.maskStyle": "Estilo de máscara",
  "ccm.agent.button.restSoftPhonePwdError": "Enlazar el agente",
  "ccm.speciallist.msg.transferToHistory": "Transferir a la Historia",
  "ccm.dual.call.record.normal.end": "Extremo normal",
  "ccm.note.config.agent.page.ring.media": "Recordatorio multimedia de llamadas entrantes",
  "ccmanagement.tenantspace.option.month_9": "septiembre",
  "ccm.speciallist.msg.deleteerror": "Error al eliminar el recordatorio de lista especial.",
  "ccm.agent.restReason.restCauseDesc.limit": "El resto de la descripción del motivo puede contener un máximo de 100 caracteres.",
  "ccm.agent.restReason.restCauseDesc.limit.form": "El resto de la descripción del motivo puede contener un máximo de 100 caracteres",
  "ccmanagement.tenantspace.option.month_7": "Julio",
  "ccm.agent.pageurls.successinfo": "Creado correctamente.",
  "ccm.agent.pageurls.successinfo.noPoint": "Creado correctamente",
  "ccmanagement.tenantspace.option.month_8": "Agosto",
  "ccmanagement.tenantspace.option.month_5": "Mayo",
  "ccmanagement.tenantspace.option.month_6": "junio",
  "ccmanagement.tenantspace.option.month_3": "Marzo",
  "ccmanagement.tenantspace.option.month_4": "Abril",
  "ccmanagement.tenantspace.option.month_1": "Enero",
  "ccm.agent.ten.versatileagents": "Agentes versátiles",
  "ccmanagement.tenantspace.option.month_2": "Febrero",
  "ccm.satisfactionconfig.message.calltypein": "Llamada entrante",
  "ccm.contact.customer.config.type": "Datos de contacto del área de información del cliente",
  "ccm.transtask.label.voiceFile": "Archivo de voz",
  "ccm.anonymous.message.fail.paranull": "El código de acceso en el parámetro está vacío",
  "ccm.certificate.management.certificatefile.certcodeerror": "El código de certificado es erroneo.",
  "ccm.agent.label.maxWaitNum": "Máx. llamadas en cola",
  "ccm.satisfactionconfig.message.enterflowname": "Por favor, introduzca el nombre del proceso",
  "ccm.systemparam.error.refresh": "Error al actualizar los parámetros.",
  "ccm.note.config.agent.page.ring.newMedia": "Notificación de nuevo mensaje multimedia",
  "ccm.agent.message.pageConfFail": "Error en la operación",
  "ccm.transtask.label.certTip.proxyCert": "Seleccione un certificado de servidor proxy",
  "ccm.agent.pageurls.integrationType": "Tipo de integración",
  "ccm.agent.message.sysParamCofig.openVersatileAgentFail": "Cambie el tipo de agente en la página Administración de agentes. El número de agentes versátiles y agentes de vídeo no puede ser mayor que el número máximo de agentes de vídeo a los que un inquilino puede suscribirse.",
  "ccm.recognition.label.updatesucess": "Actualice el éxito del fautl del inquilino.",
  "ccm.agent.calledroute.delfail": "Error al eliminar los datos locales.",
  "ccm.urlconfig.message.overlimit.appSecret": "La clave secreta configurada para llamadas bidireccionales ITA no puede exceder los 512 caracteres.",
  "ccm.custdata.button.importRecord": "Importar vista de resultados",
  "ccm.custdata.msg.confirmWithdraw": "¿Estás seguro de que quieres retirarlo?",
  "ccm.satisfactionconfig.message.operate": "Operación",
  "ccm.agent.message.validatormsg": "Las entradas relacionadas con la contraseña deben estar en blanco o no en blanco en absoluto",
  "ccm.agent.message.skillConfFail": "Error al configurar la información de la cola de habilidades.",
  "ccm.recognition.label.sucess": "Éxito",
  "ccm.agent.calledroute.exception": "Compruebe el estado de Redis o la configuración de CC-Gateway.",
  "ccm.satisfactionconfig.message.strategygroupmember": "Policy",
  "ccm.speciallisthis.operlog.downloadData": "Exportación de Historial de Listas Especiales",
  "ccm.agent.message.skillHasbeenUsedByAgent": "La cola de habilidades ha sido ocupada por un agente. Elimine primero el agente.",
  "ccm.agent.leavemessage.notLoginIn": "El agente no ha iniciado sesión.",
  "ccm.custdata.label.columnType": "Tipo de campo",
  "ccm.agent.label.workNo": "ID de empleado",
  "ccm.satisfactionconfig.message.strategy": "Política de encuestas de satisfacción",
  "ccm.urlconfig.pageurls.urlvalidate": "Las urls deben comenzar con http:// o https://",
  "ccm.callout.lable.number": "Valor del parámetro",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkRepeat": "¡Hay duplicación en la sección seleccionada!",
  "ccm.speciallist.msg.enable.fail": "Error al habilitar",
  "ccm.agent.message.updateCtiAndDbSuccess": "Sincronización exitosa.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnumber": "La hora de inicio o finalización debe ser un número.",
  "ccm.agent.message.batchExport": "Exportación por lotes",
  "ccm.agent.contact.clicktodial": "Haga clic para marcar",
  "ccm.custdata.label.dataImportRecord": "Registro de importación de datos",
  "ccm.custdata.option.maskStyle.maskFirst4": "Máscara de los primeros cuatro dígitos",
  "ccm.label.contactexport.exceedlimit2": ". Reduzca el intervalo de tiempo de los datos o añada criterios de filtro de datos.",
  "ccm.transserver.label.edit": "Servidor de volcado de recursos",
  "ccm.transtask.label.exportdata": "Campo de datos de exportación:",
  "ccm.agent.accesscode.index": "No",
  "ccm.certificate.message.tip.warning": "El algoritmo de la firma y la longitud de la clave del certificado no cumplen los requerimientos, lo que es riesgoso. ¿Está seguro que desea continuar?",
  "ccm.satisfactionconfig.message.save": "Guardar",
  "ccm.certificate.management.certcode": "Nombre del certificado",
  "ccm.agent.message.ctipasswdsimple": "La contraseña no cumple con los requisitos de complejidad de la plataforma. Póngase en contacto con el personal de OM para verificar la contraseña.",
  "ccm.agent.calledroute.fail": "Fallo",
  "ccm.agent.message.agentNotFoundFail": "Error al buscar la información del agente.",
  "ccm.custdata.label.isEncrypt": "Encriptado",
  "ccm.agent.message.existDualchannelrecAgent": "El reconocimiento inteligente y la grabación de doble pista no se pueden habilitar al mismo tiempo, mientras que la grabación de doble pista se ha habilitado para que algunos de los agentes se modifiquen en lotes.",
  "ccm.transtask.label.contactchoose": "Campos personalizados en el registro de contacto",
  "ccm.satisfactionconfig.message.grouppriority": "Prioridad",
  "ccm.agent.label.sipPwd": "Contraseña de Softphone",
  "ccm.recognition.label.modifymobileagentsuccess": "Se ha modificado con éxito el estado del agente móvil!",
  "ccm.agent.operation.RECORDPLAY": "Reproducir grabación",
  "ccm.label.contactexport.curpage": "Página actual",
  "ccm.transserver.label.servertype": "Tipo",
  "ccm.label.contactexport.exceedlimit1": "Se puede exportar un máximo de {0} registros de datos",
  "ccm.agent.contact.pictures": "Fotos",
  "ccm.agent.operation.CANCELINSERT": "Cancelar Inserción",
  "ccm.agent.label.waitSkillWeight": "Introduce un peso de habilidad.",
  "ccm.agent.page.release": "Lanzamiento",
  "ccm.satisfactionconfig.audio.and.video": "Encuesta de satisfacción del canal de voz o video",
  "ccm.agent.pageurls.isconfreset": "¿Está seguro de que desea restablecerlo?",
  "ccm.agent.page.pageParameter": "Parámetros de página",
  "ccm.agent.operation.optype": "Tipo de operación",
  "ccm.agent.label.skillWeight": "Peso de la habilidad del agente",
  "ccm.case.label.recommendedWorkOrder0": "Tipo de orden de trabajo recomendado 1",
  "ccm.botherlist.label.approveStatus.approved": "Aprobado",
  "ccm.agent.Transfer.devicetype": "Tipo de dispositivo de destino",
  "ccm.agent.leavemessage.QUERU_LEAVEMESSAGE_FAIL_CODE": "Información SFTP vacía para consultar mensajes.",
  "ccm.agent.pageurls.confreset": "Confirmar reinicio",
  "ccm.agent.operation.noMoreThan3Days": "El intervalo de tiempo no puede exceder de 3 días.",
  "ccm.case.label.recommendedWorkOrder2": "Tipo de orden de trabajo recomendado 3",
  "ccm.agent.sysparamconfig.outboundtimecheck": "El límite de tiempo para la vista previa automática de llamadas salientes debe oscilar entre 1s y 3600s.",
  "ccm.case.label.recommendedWorkOrder1": "Tipo de orden de trabajo recomendado 2",
  "ccm.agent.resetskillrecord.adjustresult": "Ajustar el resultado",
  "ccmanagement.satisfaction.strategy.calltime.second": "s",
  "ccm.speciallist.msg.restoreout": "Un espacio de inquilino puede almacenar un máximo de {0} registros de lista especial",
  "ccm.custdata.option.importDoing": "Procesamiento",
  "ccm.agent.contact.CONNECT_FAIL_CODE": "Error al conectarse al servidor SFTP.",
  "ccm.basicdata.msg.modifysuccess": "Los datos básicos se modifican correctamente.",
  "ccm.sysparam.error.validate.notmatchruleminlength": "El parámetro de entrada es menor que la longitud mínima",
  "ccm.contactitemconfig.message.itemValidate": "La primera letra debe ser una letra, un guión bajo (_), o signo de dólar ($). Otras letras pueden ser subrayado (_), letras de signo de dólar, o dígitos.",
  "ccm.urlconfig.modify.callnotify.ak.tooshort": "La clave debe contener al menos 16 caracteres.",
  "ccm.agent.contact.callednook": "Llamada saliente sin respuesta",
  "ccm.recognition.label.appSecret.reset": "reinicio SK",
  "ccm.recognition.msg.unsubscribeConfirm": "Si el reconocimiento inteligente está deshabilitado, el asistente del agente inteligente también está deshabilitado.",
  "ccm.agent.contact.acceptno": "Número manejado",
  "ccm.satisfactionconfig.message.updatefailed": "Error al actualizar la configuración de la encuesta de satisfacción",
  "ccm.speciallist.msg.applyTimeFrom": "Hora de inicio de la aplicación",
  "ccm.agent.contact.resetAll": "Restablecer todo",
  "ccm.transtask.label.cancel": "Cancelar",
  "ccm.anonymous.message.fail.open": "Error al abrir el indicador. Comprobar la configuración de CTI",
  "ccm.idauth.create.error.length": "La longitud del código del modo de autenticación supera el máximo.",
  "ccm.asr.create.case.chat.info": "Puede hacer clic en Crear caso solo durante un chat o después de que finalice un chat.",
  "ccm.agent.label.subtype": "Subtipo",
  "ccm.agent.message.aiagentnummore": "El número de agentes inteligentes habilitados no puede exceder el número de agentes asignados cuando el inquilino está habilitado.",
  "ccm.taskrecord.label.executeFail": "Error",
  "casemanagement.user.title.account": "Cuenta",
  "ccm.agent.message.modifymobileagentsuccess": "Se ha modificado con éxito la información sobre el número de softphone del agente móvil.",
  "ccm.certificate.management.certificatefile.suffixerror.pre": "los certificados de tipo pueden contener solo archivos con la extensión",
  "ccm.transtask.label.task.uniqueness": "Sólo se puede crear una tarea de datos de informe en el mismo intervalo de volcado para el mismo tipo de datos de volcado.",
  "ccm.operlog.contactfile.download": "Descargar archivo",
  "ccm.agent.message.agentnumcannotmuchthanmax": "El número de tipo de agente de destino no puede ser mucho mayor que el maximo.",
  "ccm.callout.lable.index": "Índice",
  "ccmanagement.satisfaction.strategy.minutevalidate": "El minuto tiene un formato incorrecto.",
  "ccm.satisfactionconfig.message.failtogetivrflow": "Active la encuesta de satisfacción",
  "ccm.agent.restReason.selectRestReasons": "Seleccione un motivo de descanso.",
  "ccm.agent.clear.message.agentnotexist": "Error al cancelar la configuración porque el agente no existe.",
  "ccmanagement.satisfaction.strategy.deletesuccess": "Eliminación correcta.",
  "ccm.callreason.label.selectedreason": "Razones Seleccionadas",
  "ccm.certificate.management.certificatefile.certtypeerror": "El tipo de certificado es error.",
  "ccm.agent.message.searchAccount": "Introduzca un ID de agente.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnull": "La hora de inicio o finalización no puede estar vacía.",
  "ccm.agent.contact.callinandcallout": "Llamadas entrantes y salientes",
  "ccm.speciallist.msg.restoreerror": "Error al restaurar la lista especial",
  "ccm.urlconfig.message.missing.appKeyOrDomainName": "La clave de la aplicación y el nombre de dominio en la configuración de llamada bidireccional ITA deben estar configurados.",
  "ccmanagement.satisfaction.strategy.calltime.lengthlimit": "La duración mínima o máxima de la llamada puede contener un máximo de 12 dígitos.",
  "ccm.agent.calledroute.create.ivr.limit": "Si el tipo de dispositivo es IVR, el tipo de medio del código de acceso solo puede ser clic para marcar, solicitud de devolución de llamada o llamada común.",
  "ccm.agent.contact.playcomfirm": "Confirmar juego",
  "ccm.sysparam.error.validate.notmatchrulerangelength": "El parámetro de entrada excede la longitud máxima o está por debajo de la longitud mínima",
  "ccm.contactitemconfig.label.data.extended.dataset.mapping.field": "Campo de asignación de conjuntos de datos",
  "ccm.accesscode.label.index": "No",
  "ccm.transserverparam.label.proxyUserName": "Nombre de usuario",
  "ccm.common.ajax.searchErr": "Error de búsqueda",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnumber": "La fecha de inicio o fin tiene un formato incorrecto.",
  "ccm.agent.label.weight": "Peso",
  "ccm.vdn.label.defaultvoice": "por defecto",
  "ccm.transtask.label.dumpinterval": "Duración del volcado",
  "ccm.agent.operation.INSERT": "Comprobación de calidad de inserción",
  "ccm.contact.customer.config.foregroundColor": "Color de primer plano",
  "ccm.satisfactionconfig.message.strategychannelid": "Id. de directiva de canal",
  "ccm.agent.label.batchselectway": "Modo de selección por lotes",
  "ccm.calledroute.title.selectivrflow": "Seleccionar IVR",
  "ccm.agent.Transfer.deviceAddr": "Dirección del dispositivo de destino",
  "ccm.agent.label.agentWeightTip": "Las llamadas se asignan preferentemente a los agentes con mayor peso.",
  "ccm.contact.customer.config.datasource.objName": "Campo de asignación de objetos de origen de datos",
  "ccm.note.config.choosenote": "Por favor, seleccione un archivo de nota",
  "ccm.agent.softPhoneStatus.Registered": "Registrado",
  "ccm.satisfactionlevel.message.satislevel": "Nivel de satisfacción",
  "ccm.note.config.agent.ring.back.tone": "Configuración del tono de retorno de llamada del agente",
  "ccm.pageinterface.addObject": "Añadir objeto",
  "ccm.transtask.label.discarded": "Descartado",
  "ccm.agent.label.inputstartworkno": "Ingrese el ID de empleado inicial",
  "ccm.sysparam.error.validate.notmatchruleemail": "Por favor, introduzca una dirección de correo electrónico válida",
  "ccm.satisfactionconfig.message.audio": "Encuesta de satisfacción",
  "ccm.agent.ten.DSTOffset": "Desfase de DST (min)",
  "ccm.agent.button.export": "Exportar",
  "ccm.contactitemconfig.label.data.extended.Type.String": "Cuerda",
  "ccm.agent.contact.calltype": "Tipo de llamada",
  "ccm.agent.message.createfailed": "Error al crear agente",
  "ccm.dual.call.record.noMatchingDualCallRecordExists": "Ningún registro de llamada bidireccional cumple los criterios.",
  "ccm.urlconfig.message.overlimit.xNumber": "El número X configurado para las llamadas bidireccionales ITA no puede exceder los 20 caracteres.",
  "ccm.agent.calledroute.updatecalledroute": "Editar",
  "ccm.agent.calledroute.title.create": "Creación de una configuración de parte llamada",
  "ccm.agent.calledroute.title.updatecalledroute": "Editar configuración de la parte llamada",
  "ccm.transtask.label.cdrData": "Datos de CDR",
  "ccm.agent.leavemessage.auditcomments": "Comentarios de auditoría",
  "ccmanagement.satisfaction.strategy.operation": "Operation",
  "ccm.speciallist.msg.typeName": "Tipo de lista especial",
  "ccm.satisfactionconfig.message.strategychanneltitle": "Configurar directiva de canal",
  "ccm.satisfactionconfig.add.strategychanneltitle": "Creación de una directiva de satisfacción del canal",
  "ccm.satisfactionconfig.update.strategychanneltitle": "Editar estrategia de satisfacción del canal",
  "ccm.transtask.label.contactrcd": "Contacto Volcado de registros",
  "ccm.agent.calledroute.accesscodewarn": "¡El código de acceso solo puede contener dígitos!",
  "ccm.whatsapp.template.message.name": "Nombre del mensaje",
  "ccm.speciallist.msg.delete.fail": "Error al eliminar",
  "ccm.agent.contact.clicktocall": "Hacer clic para marcar",
  "ccm.agent.ten.asrchannelnums": "Cantidad ASR",
  "ccm.agent.label.scaleFactor": "factor de escala",
  "ccm.custdata.msg.confirmDelete": "¿Está seguro de que desea eliminarlo?",
  "ccm.speciallist.msg.errorNum": "Error {limit}",
  "ccm.certificate.management.passwd": "Certificar Contraseña",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnull": "La duración mínima o máxima de la llamada no puede estar vacía.",
  "ccm.transtask.label.dumpintervalInitial": "Duración del volcado Inicio",
  "ccm.agent.message.willDeleteSkills": "¿Está seguro de que desea eliminar las colas de habilidades {0}?",
  "ccm.agent.message.willDeleteSkillGroups": "¿Está seguro de que desea eliminar los grupos de habilidades seleccionados?",
  "ccm.custdata.operlog.confirm": "Confirmar",
  "ccm.agent.contact.multi-media": "Multimedia",
  "ccm.contactitemconfig.message.itemValidatefirst": "La primera letra debe ser una letra, subrayado (_), o signo de dólar ($)",
  "ccm.pageinterface.pleaseSelectAttribute": "Seleccione un atributo de objeto.",
  "ccm.urlconfig.message.httpurl.safetip": "(No es seguro)",
  "ccm.agent.message.agentConfFail": "Error al configurar la información del agente.",
  "ccm.satisfactionconfig.message.flowname": "Nombre del proceso",
  "ccm.custdata.label.importantTips": "Nota: Los datos personales o los datos confidenciales deben estar encriptados para su almacenamiento y enmascarados para su visualización.",
  "ccm.recognition.label.appSecret.download.querySK.error": "Error al consultar el SK.",
  "ccm.agent.contact.weChat": "WeChat",
  "ccm.agent.contact.oircall": "Número de llamada original",
  "ccm.agent.label.scaleFactorRange": "Rango de valores: [0-10000]",
  "ccm.agent.restrecord.reststatus.timeout.Forcible.endrest": "Descanso forzado a terminar",
  "ccm.urlconfig.modify.authbothnull": "La información de autorización no puede estar vacía.",
  "ccmanagement.agent.batchmodify.agentnum": "Por ID de empleado",
  "ccm.speciallist.msg.necessary": "El número de usuario es obligatorio",
  "ccm.recognition.label.closeanonymoussuccess": "Se ha deshabilitado correctamente el indicador de llamada saliente anónima",
  "ccm.transserver.label.servername": "Nombre del servidor",
  "ccm.agent.message.numInRowFail": "El valor debe ser un número entero comprendido entre 2 y 4.",
  "ccm.transtask.label.status": "Estado",
  "ccm.agent.label.callDispatchModel": "Modo de asignación de llamadas",
  "ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE": "La información de grabación está vacía.",
  "ccm.recognition.label.cancelordersucess": "La suscripción se cancela correctamente.",
  "ccm.agent.leavemessage.statusFailed": "Error al cambiar el estado del registro de mensajes.",
  "ccm.agent.message.sysParamCofig.closeVersatileAgentFail": "Cambie el tipo de agente en la página Administración de agentes. Un agente versátil no se puede asociar a una cola de habilidades de vídeo o vídeo de clic para marcar.",
  "ccm.skillcreate.repeat.fail": "El nombre de la cola de habilidades ya existe.",
  "ccm.speciallist.msg.selectonlyslreminder": "Solo se puede modificar un recordatorio de lista especial.",
  "ccm.skill.label.skillname": "Nombre de Habilidad",
  "ccm.agent.title.skillCreate": "Nueva cola de habilidades",
  "ccm.custdata.option.maskStyle.maskEmail": "Máscara de correo electrónico",
  "ccm.agent.contact.QUERY_SERVER_FAIL_CODE": "Error al invocar el servicio de consulta.",
  "ccm.satisfactionconfig.message.strategygroupname": "Nombre del grupo de políticas",
  "ccm.satisfactionconfig.title.strategygroup": "Creación de un grupo de directivas",
  "ccm.sysparam.config.itemdesc": "Descripcion",
  "ccm.agent.label.keepVoiceCustom": "Personalización del tono de mantenimiento",
  "ccm.agent.label.numInRow": "Escriba un número entero comprendido entre 2 y 4.",
  "ccm.transserver.label.view": "Vistas",
  "ccm.custdata.label.columnNo": "No",
  "ccm.urlconfig.label.oauth.oauth2loginurl": "Autenticación URL de inicio de sesión OAuth 2.0",
  "ccm.custdata.label.isMaskDisplay": "Enmascarado",
  "ccm.agent.button.batchconfig": "Configuración por lotes",
  "ccm.agent.pageurls.errorsave": "Error",
  "ccm.certificate.management.update.cancel.certfile": "¿Está seguro de que desea cancelar la actualización del certificado?",
  "ccm.common.label.error": "Error",
  "ccm.agent.operation.noMoreThan31Days": "El intervalo de tiempo no puede exceder los 31 días.",
  "ccm.agent.label.validTime": "Período de validez de la contraseña (días)",
  "ccm.agent.operation.UNHOLD": "Desenganchar",
  "ccm.speciallist.msg.endtime": "La fecha de vencimiento no puede ser posterior al 19 de enero de 2038",
  "ccm.speciallist.label.line": "Línea",
  "ccm.agent.operation.CANCELREST": "Cancelar Resto",
  "ccm.custdata.option.importDone": "Finalizado",
  "ccm.certificate.management.expiretime": "Tiempo de caducidad",
  "ccm.label.contactexport.exception": "Error al generar la tarea de exportación. Revisa los registros.",
  "ccm.certificate.management.certificatecrlfile": "Archivo CRL",
  "ccm.pageinterface.objectList": "Lista de objetos",
  "ccm.agent.label.information": "Sugerencias",
  "ccm.agent.label.selectagent": "Agentes seleccionados",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "Ingrese la palabra clave",
  "ccm.satisfactionconfig.message.surveytype": "Tipo de encuesta",
  "ccm.verifyidentity.FAIL": "Fallar",
  "ccm.basicdata.error.datatypenotmatch": "El formato de datos no es coherente con el formato de definición de campo",
  "ccm.agent.ten.timezone": "Zona horaria",
  "ccm.agent.recall": "Devolución de llamada",
  "ccm.pageinterface.interfaceType.external": "Interfaz externa",
  "ccm.dual.call.record.talkDuration": "Duración (es) de la llamada",
  "ccm.custdata.label.columnLength": "Longitud de campo",
  "ccm.agent.title.selectskill": "Seleccionar cola de habilidades",
  "ccm.pageurls.select.externalInterface": "Página externa",
  "ccm.agent.contact.itacall": "Llamada bidireccional ITA",
  "ccm.agent.message.specialCharacters": "El número contiene caracteres especiales y letras.",
  "ccm.custdata.msg.completeSort": "Completa la clasificación primero.",
  "ccm.transserver.delete.css.confirm": "¿Está seguro de que desea eliminar la configuración de CSS?",
  "ccm.agent.restReason.delete": "Borrar",
  "ccm.agent.contact.accountname": "Nombre de cuenta",
  "ccm.contact.customer.config.datasource.msgDataset": "Conjunto de datos de información",
  "ccm.speciallist.msg.operationerror": "Error en la operación",
  "ccm.agent.message.ctipasswdrepeat": "La nueva contraseña no puede ser la misma que las últimas cinco contraseñas antiguas.",
  "ccm.agent.ten.dstOn": "Habilitado",
  "ccmanagement.tenantspace.option.weekday_mon": "Lunes",
  "ccm.agent.contact.relCallBackFlag": "Indicador de liberación",
  "ccm.agent.label.batchselect": "Seleccionar agentes en lotes",
  "ccm.ucconfig.message.title": "Registro de aplicaciones con la plataforma de identidad de Microsoft",
  "ccm.callreason.label.enable": "Activar",
  "ccm.agent.message.agenttypeinvalid": "El tipo de agente no es válido.",
  "ccm.contactitemconfig.label.data.extended.set": "Conjunto de datos de origen",
  "ccm.agent.message.worknonoexit": "El ID de empleado no existe.",
  "ccm.agent.message.worknull": "El ID de empleado está vacío.",
  "ccm.satisfactionconfig.message.update": "Edit",
  "ccm.agent.label.busy": "Habilidad Ocupado",
  "ccm.speciallist.msg.exists": "El usuario seleccionado ya está incluido en la lista especial",
  "ccm.satisfactionconfig.message.surveystrategygroup": "Política de encuestas",
  "ccm.recognition.label.modifymobileagentflowidnull": "¡El ID de flujo está vacío!",
  "ccm.agent.message.configvalidatefailed": "Error en la validación del elemento de configuración de puerta de enlace",
  "ccm.agent.label.agentList": "Cuenta de servicio",
  "ccm.sysparam.error.validate.notmatchrulerange": "El parámetro de entrada excede el valor máximo o está por debajo del valor mínimo",
  "ccm.calledconfig.message.willdeleteassociation": "Está seguro de que desea eliminar la información de asociación de motivo de llamada?",
  "ccm.urlconfig.label.numberAllocationMode1": "Automático",
  "ccm.urlconfig.label.numberAllocationMode2": "Especificado",
  "ccm.agent.label.ninetydays": "90 Días",
  "ccm.agent.url.params.tips": "Tenga en cuenta que la forma en que se hace el parámetro GET REQUEST URL expone sus parámetros a una dirección abierta.",
  "ccm.contact.customer.config.visible": "Visible",
  "ccm.agent.updateSoftPhone.accountPasswordFailed": "Error al verificar la contraseña de la cuenta.",
  "ccm.contact.customer.config.preview": "Vista previa",
  "ccm.agent.message.sysParamCofig": "Error al configurar los parámetros del sistema.",
  "ccm.custdata.operlog.success": "Éxito",
  "ccm.agent.leavemessage.inputauditcomments": "Introduce sugerencias.",
  "ccm.agent.mobileagent.label.nodata": "¡No se encontraron datos coincidentes!",
  "ccm.asr.create.case.text.info": "No se admite la creación de casos con un solo clic porque no hay contenido de texto.",
  "ccm.agent.operation.SAYBUSY": "Ocupado",
  "ccm.note.config.agent.page.ring": "Tono de llamada",
  "ccm.agent.pageurls.moveup": "Arriba",
  "ccm.custdata.operlog.downloadTemplate": "Descargar la plantilla de tabla básica",
  "ccm.agent.leavemessage.notAgent": "La cuenta no está vinculada a un agente.",
  "ccm.agent.pageurls.confdelete": "Confirmar Borrar",
  "ccm.agent.password.agentNull": "La información del agente está vacía.",
  "ccm.agent.operation.TWOSTAGEDIALING": "Marcación en dos etapas",
  "ccm.label.contactexport.nocount": "Ningún registro de contacto cumple con las condiciones.",
  "ccm.label.dualcallexport.nocount": "No hay registros de llamadas dobles que cumplan las condiciones.",
  "ccm.recognition.label.processaccesscode": "Introduzca un código de acceso de flujo.",
  "ccm.transtask.label.saturday": "Sábado",
  "ccm.custdata.label.columnNum": "Campos",
  "ccm.agent.leavemessage.workNo": "agente",
  "ccm.agent.contact.callinandout": "Doble llamada",
  "ccm.agent.contact.user.recall": "El usuario ha retirado el mensaje",
  "ccm.agent.mobileagent.label.deletecomplete": "¡Elimine el agente completo!",
  "ccm.agent.calledroute.refresh": "Refrescar",
  "ccm.verifyidentity.fail.TIMEPARAMNULLERROR": "El tiempo de consulta no puede estar vacío",
  "ccm.contactitemconfig.label.data.extended.dataset.name": "Nombre del conjunto de datos",
  "ccm.agent.label.adjustTime": "Duración (es) in Estado de organización",
  "ccm.agent.contact.gateway.exception": "Excepción interna de puerta de enlace. Compruebe la configuración de la puerta de enlace.",
  "ccm.speciallist.msg.success": "Exitoso",
  "ccm.contact.detail.message.content": "Contenido del mensaje",
  "ccm.custdata.msg.fileSizeValidateFail": "Seleccione un archivo de menos de 20 MB.",
  "ccm.speciallist.label.reason": "Motivo de la adhesión",
  "ccm.callout.message.editfail": "Error al editar el llamador de llamada",
  "ccm.note.config.agent.page.ring.voice": "Notificación de llamadas entrantes de audio y vídeo",
  "ccm.agent.restrecord.rest.beginTime": "Hora de inicio de descanso",
  "ccm.agent.speciallist.validityPeriod": "Período de vigencia",
  "ccm.agent.speciallist.begintime": "Tiempo efectivo",
  "ccm.transserverparam.label.bucket": "Cubo de datos",
  "ccm.agent.calledroute.editfail": "Error al editar los datos locales.",
  "ccm.agent.contact.resourcefail": "Se produce un error durante la carga de la grabación.",
  "ccm.satisfactionconfig.message.creategroup": "Agregar grupo de directivas",
  "ccm.agent.contact.calledhangup": "Fin de la llamada, llamado colgar",
  "ccm.agent.calledroute.skill": "Cola de habilidades",
  "ccm.sysparam.config.checkmessage": "Contiene caracteres especiales. (\"<\", \">\", \"/\", etc.)",
  "ccm.contact.customer.config.regular": "Normativa",
  "ccm.basicpage.not.sk.download": "No hay permiso disponibe de descarga de SK.",
  "ccm.satisfactionconfig.sms.satisfaction.survey": "Encuesta de Satisfacción SMS",
  "ccm.agent.label.restQueueRule.lessRest": "Agente con menos descanso en el día actual",
  "ccm.recognition.label.interconnection.parameters": "Parámetros de interconexión",
  "ccm.agent.label.transparentDisplayFlag": "Indicador de anonimización de número de agente",
  "ccm.calledconfig.message.numbersinvalid": "Más de 15 o menos de 1 números",
  "ccm.agent.operation.noMoreThan7Days": "El rango de tiempo no puede exceder los 7 días.",
  "ccm.agent.callreason.modify.success": "Modificar la razón de la llamada con éxito.",
  "ccm.speciallist.msg.specialchar": "El mensaje recordatorio no puede contener caracteres especiales.",
  "ccm.speciallevel.speciallist.use": "Disponible",
  "ccm.custdata.option.status.draft": "Borrador",
  "ccm.urlconfig.message.warning": "Asegúrese de que la URL de inserción de CDR se haya configurado en el ITA. De lo contrario, el ITA no puede empujar las CDR a la CEC. Una URL de inserción de ejemplo es la siguiente:",
  "ccm.agent.contact.transferRecord": "Registro de transferencia",
  "ccm.pageinterface.viewAttribute": "Ver atributo de objeto",
  "ccm.urlconfig.message.addfail": "Error al agregar las urls",
  "ccm.skill.message.skillpara": "No se puede configurar la habilidad en sí",
  "ccm.agent.label.callDispatchModel.average": "Promedio",
  "ccm.idauth.title.name": "Nombre del modo de autenticación",
  "ccm.agent.label.serviceAccountCode": "Cuenta",
  "ccm.page.label.pageInput": "Introduzca un nombre de página",
  "ccm.agent.label.thirtyonedays": "31 Días",
  "ccm.recognition.label.appSecret.download.appId.empty.error": "El campo appId no puede estar vacío.",
  "ccm.agent.contact.line": "LINE",
  "ccm.transtask.label.delete": "La tarea de volcado se elimina correctamente.",
  "ccm.certificate.management.certificatefile.certpwderror": "La contraseña del certificado es un error.",
  "ccm.agent.label.sensitiveWord": "Se detectan palabras prohibidas. Cuidado con tus palabras.",
  "ccm.idauth.title.create": "Crear proceso de autenticación",
  "ccm.speciallist.msg.typeidempty": "el tipo de lista especial no puede estar vacío.",
  "ccm.agent.label.deviceType": "Tipo de dispositivo",
  "ccm.agent.label.ivr": "IVR",
  "ccm.agent.leavemessage.assign": "Asignar",
  "ccm.agent.leavemessage.title.assign": "Asignación de mensajes",
  "ccm.custdata.operlog.popwindowmessage": "Introduzca la contraseña utilizada para generar el archivo comprimido. La contraseña comprimida debe contener letras, números, caracteres especiales _ {'@'} {'%'}",
  "ccm.agent.label.bindTheSkill": "Antes de usar la cola de habilidades, desagregue primero la ruta llamada.",
  "ccm.agent.label.groupUseTheSkill": ". primero elimine la configuración de la cola de habilidades en el grupo de habilidades.",
  "ccm.agent.label.groupBind": "La cola de habilidades se ha vinculado al grupo de habilidades ",
  "ccm.transtask.label.month": "Meses",
  "ccm.agent.contact.gateway.fail": "Solicitud a la excepción de puerta de enlace. Compruebe la configuración de la puerta de enlace.",
  "ccm.recognition.label.mobileagent": "Agente móvil/llamada bidireccional con un solo clic",
  "ccm.urlconfig.label.relmethod": "Método de solicitud HTTP",
  "ccm.agent.contact.robothungup": "Robot",
  "ccm.agent.pageurls.maxcalling": "El número máximo de pantallas emergentes de llamadas entrantes es {limit}.",
  "ccm.contact.customer.config.underline": "Subrayado",
  "ccm.contactitemconfig.message.confOnlyDataItemCode": "Error en la operación porque el ID del elemento de datos ya existe.",
  "ccm.agent.leavemessage.channel": "Canales",
  "ccm.vdn.label.index": "meses",
  "ccm.agent.message.updateAgentAndSkillFail": "Error al actualizar la asociación entre el agente y la cola de habilidades.",
  "ccm.pageinterface.selectedAttribute": "Atributo seleccionado",
  "ccm.agent.calledroute.devicetype": "Tipo de dispositivo",
  "ccm.verifyidentity.SUCCESS": "Pasar",
  "ccm.contact.customer.config.backgroundColor": "Color de fondo",
  "ccm.transserverparam.label.login.cssClusterUser": "Nombre de usuario de inicio de sesión de clúster",
  "ccm.agent.label.parameterType": "Método de cola",
  "ccm.transtask.label.thurs": "Jueves",
  "ccm.agent.contact.talkRemark": "Comentarios de la llamada",
  "ccm.pageinterface.cancelAll": "Cancelar todo",
  "SM.ORGSTAFF.LABEL.ORGANIZATION": "Unidad de Organización",
  "ccm.agent.message.searchSkillName": "Introduzca el nombre de la cola de habilidades.",
  "ccm.recognition.label.intellagent": "Asistente de agente inteligente",
  "ccm.label.pageName": "Nombre de página",
  "ccm.agent.ccagentinfo.unregister.fail": "Error de cancelación de registro",
  "ccm.agent.operation.CALLOUT": "CallOut",
  "ccm.sysparam.error.validate.notmatchruledigits": "Introduzca un entero positivo válido",
  "ccm.agent.calledroute.nmsfail": "Error en la sincronización con el NMS.",
  "ccm.agent.message.agentCTIFail": "Error en los agentes de consulta modificados en la puerta de enlace. Compruebe la configuración de la puerta de enlace.",
  "ccm.transtask.label.notEnabled": "Deshabilitado",
  "ccm.agent.button.cancel": "Cancelar",
  "ccm.custdata.label.description": "Descripcion",
  "ccm.agent.addbother.msg.emailOrPhoneValidate": "Formato incorrecto.",
  "ccm.basicdata.error.firstlinenotmatch": "La primera línea del archivo importado no es coherente con la de la plantilla",
  "ccm.agent.ten.dstOff": "Desactivado",
  "ccm.agent.ten.systemdefault": "Zona horaria predeterminada",
  "ccm.agent.message.dataIntegrityFail": "Datos incompletos configurados para el diseño de página o el conjunto de interfaces. Error en la operación.",
  "ccm.contract.management.confirm": "El documento contiene datos confidenciales. ¿Estás seguro de que quieres continuar?",
  "ccm.contract.management.confirmtitle": "Confirmar Operar",
  "ccm.speciallist.msg.restoresuccess": "Restauración exitosa",
  "ccm.agent.callreason.need": "Por favor, seleccione al menos un motivo de llamada.",
  "ccm.pageinterface.title": "Interfaz de página",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputlength": "La duración mínima o máxima de la llamada puede contener un máximo de 12 dígitos.",
  "ccm.satisfactionconfig.message.version": "número de versión",
  "ccm.agent.sysparamconfig.resttimenumberwarn": "La duración del descanso debe oscilar entre 1 y 1440 (no contiene 1440)",
  "ccm.agent.pageurls.edit": "Editar",
  "ccm.speciallist.tip.time.max": "Seleccione una fecha anterior a la fecha de vencimiento del proyecto (2038-01-19)",
  "ccm.agent.message.addAIRecogFail": "El número de agentes inteligentes habilitados no puede exceder el número de agentes asignados cuando el tenant está habilitado.",
  "ccm.agent.label.singlePhoneFlag": "Agente de Solo Teléfono",
  "ccm.transtask.label.enable": "Activar",
  "ccm.agent.message.searchSipAccount": "Introduzca un número de teléfono SIP.",
  "ccm.agent.restrecord.reststatus.timeout.endrest": "Tiempo de descanso agotado",
  "ccm.agent.operation.FORCEIDEL": "Fuerza inactiva",
  "ccm.agent.button.refresh": "Refrescar",
  "ccm.agent.updateSoftPhone.newPasswordFailed": "Error al verificar la nueva contraseña.",
  "ccm.agent.message.omsAgentTypeCheck": "El rol seleccionado no coincide con el tipo de agente de un agente en línea.",
  "ccm.agent.restReason.restCauseDesc": "Descripcion",
  "ccm.agent.message.videoNotEnough": "El número de recursos de vídeo es insuficiente porque tanto los agentes de vídeo como los agentes configurados con colas de habilidades de vídeo ocupan recursos de vídeo. Asigne recursos de vídeo correctamente.",
  "ccm.satisfactionconfig.message.smstitle": "SMS",
  "ccm.agent.ten.resourceinfor": "Información de recursos",
  "ccm.agent.label.deviceUsage": "Uso del dispositivo (%)",
  "ccm.speciallist.msg.warning": "Advertencia",
  "ccm.custdata.option.maskStyle.maskBankAccountNum": "Máscara de cuenta bancaria",
  "ccm.basicdata.msg.queryfail": "Error al consultar los datos básicos.",
  "ccm.agent.message.agenttypebothempty": "No se configura un tipo de agente para el agente seleccionado. Es necesario configurar el tipo de agente.",
  "ccm.agent.contact.multimedia": "Multimedia",
  "ccm.agent.message.querycenterfailed": "Error al consultar el agente inteligente del centro de llamadas.",
  "ccm.ucconfig.message.integration": "Integración de Microsoft Teams",
  "ccm.ucconfig.message.info.alert": "Copie la siguiente dirección a Microsoft para el registro de la aplicación como URI Aceptar redireccionamiento de autenticación. Copie el resultado del registro y complete la siguiente información de solicitud de registro.",
  "ccm.ucconfig.message.copy": "Copiado",
  "ccm.ucconfig.message.register": "Registro de la información de la aplicación",
  "ccm.ucconfig.message.required.fields": "Campos obligatorios",
  "ccm.ucconfig.message.required.limit": "La longitud no puede exceder los 64 caracteres.",
  "ccm.verifyidentity.userId": "Identificación de usuario",
  "ccm.agent.pageurlparam.calldata": "Datos de llamada",
  "ccm.custdata.option.status.abandon": "Desechar",
  "ccm.agent.label.release": "Lanzamiento",
  "ccm.agent.message.synToCcpFailed": "Error al sincronizar el modo de autenticación del agente con cc-provision.",
  "ccm.agent.pageurls.adressval": "La dirección de acceso debe comenzar con 'https://'' o 'http://'.",
  "ccm.agent.pageurls.adressval.noPoint": "La dirección de acceso debe comenzar con 'https://'' o 'http://'",
  "ccmanagement.satisfaction.strategy.acceptdate.dateStart": "Fecha de inicio",
  "ccm.calledconfig.label.querysoftnum": "Ver números de Softphone",
  "ccm.agent.ten.tenantName": "Nombre del Tenant",
  "ccm.callreason.label.createrelatecase": "Relacionar Casos",
  "ccmanagement.satisfaction.strategy.fail.accepttime.startafterend": "La hora de inicio debe ser anterior a la hora de finalización.",
  "ccm.agent.updateSoftPhone.updateSuccess": "Contraseña cambiada correctamente",
  "ccm.agent.ten.DSTEndTime": "Hora de finalización del horario de verano",
  "ccm.agent.input.userNumber": "Número de usuario",
  "ccmanagement.satisfaction.strategy.fail.calltime.startafterend": "La duración mínima de la llamada debe ser menor que la duración máxima de la llamada.",
  "ccm.skill.placeholder.skilldesc": "Introduzca un nombre de cola de habilidades.",
  "ccm.sysparam.error.validate.notmatchruleip": "Ingrese una dirección IPV4 o IPV6 válida",
  "ccm.ucconfig.message.failed": "fallar",
  "ccm.pageurls.select.internalInterface": "Página interna",
  "ccm.agent.calledroute.refreshsuccess": "Refrescar con éxito.",
  "ccm.transtask.label.ivrAccessCodeReport": "Informe de estadísticas de datos IVR por código de acceso",
  "ccm.contact.customer.config.writeBackField": "Campo de escritura de registro de contacto extendido",
  "ccm.verifyidentity.label": "Autenticación de identidad",
  "ccm.agent.calledroute.ccivrdata.error": "Error al sincronizar los datos con el NMS. Los datos del proceso CC-IVR son incorrectos.",
  "ccm.certificate.management.search.certcode": "Introduzca el código del certificado",
  "ccm.systemparam.error.reset": "Error al restablecer los parámetros.",
  "ccm.sysparam.config.item.detail": "Información de parámetros",
  "ccm.agent.contact.multimedia.facebook": "Multimedia-Facebook",
  "ccm.certificate.management.certificate.tip": "Consejos:",
  "ccm.agent.pageurls.sernum": "No",
  "ccm.whatsapp.whatsApp.message.template": "Plantilla de WhatsApp",
  "ccm.agent.message.aiagentclosed": "La función de identificación inteligente del inquilino está deshabilitada. Póngase en contacto con el administrador del sistema.",
  "ccm.agent.callreason.exceedmaxnum": "Se permite un máximo de cinco razones de llamada.",
  "ccm.agent.label.configAdmin": "Administrador de configuración",
  "ccm.satisfactionconfig.message.satisreport": "Informe de la encuesta de satisfacción",
  "ccm.agent.label.maxTalkTimeRange": "Rango de valores: [1-86400]",
  "ccm.certificate.management.certificatefile.typeerror": "Tipo de archivo incorrecto.",
  "ccm.agent.restReason.restCauseCode": "Código de motivo de suspención",
  "ccm.agent.button.save": "Guardar",
  "ccm.agent.message.agentandskillnotmatch": "El tipo de habilidad debe coincidir con el tipo de agente",
  "ccm.custdata.operlog.addExportTaskSuccess": "La nueva tarea de exportación se ha realizado correctamente",
  "ccm.label.page.delete.confirm": "¿Está seguro de que desea eliminarlo?",
  "ccm.calledconfig.message.overmaxnum": "Se ha alcanzado la cantidad máxima de {0}.",
  "ccm.custdata.option.maskStyle.maskIdCardNum": "Máscara de número de tarjeta de identificación",
  "ccmanagement.tenantspace.option.weeklist_fir": "En primer lugar",
  "ccm.systemparam.success.reset": "El parámetro se restablece correctamente.",
  "ccm.agent.updateSoftPhone.unFoundInfo": "El centro de llamadas no está sincronizado.",
  "ccm.sysparam.error.validate.notmatchrulerequired": "Requerido",
  "ccm.custdata.operlog.uploadDataFile": "Carga del archivo de datos de tabla básica",
  "ccm.whatsapp.select.whatsApp.channel": "Por favor, selecciona un canal de WhatsApp.",
  "ccm.transserver.label.proxynotinwhite": "Error en la operación. Póngase en contacto con el administrador del sistema para agregar la dirección del servidor proxy a la lista de confianza proxy del servidor OBS.",
  "ccm.agent.leavemessage.cannotBeModified": "No se puede modificar el mensaje en el estado actual.",
  "ccm.dual.call.record.endTime": "Hora de finalización de llamadas",
  "ccm.transtask.label.tasknameexits": "Error al crear la tarea porque el nombre de la tarea ya existe.",
  "ccm.recognition.label.anonymous": "Indicador de llamada saliente anónimo",
  "ccm.custdata.option.status.release": "Lanzamiento",
  "ccm.contact.customer.config.key": "Clave de datos",
  "ccm.custdata.msg.tableExistInstData": "La tabla personalizada contiene datos de instancia y no se puede retirar para su modificación.",
  "ccm.transtask.config.pathvalidate": "La ruta del archivo de volcado no puede contener \"./\".",
  "ccm.custdata.operlog.downloadData": "Descarga de datos básicos de la tabla",
  "ccm.agent.label.queuingCallsMaxNumRangeTwo": "Rango de valores: [1-10000]",
  "ccm.speciallist.msg.sltype": "Tipo de lista especial",
  "ccm.satisfactionlevel.message.resetsuccess": "Los niveles de satisfacción y las plantillas de encuestas de satisfacción se restablecen correctamente. Si se ha configurado un proceso de satisfacción, suelte el proceso en la página Administración de procesos. De lo contrario, los resultados de satisfacción se mostrarán incorrectamente. La nueva encuesta de satisfacción se basará en los resultados de esta configuración.",
  "ccm.speciallist.msg.userno.error": "El formato del número de usuario es incorrecto",
  "ccm.systemparam.error.notmatchrule": "El parámetro no cumple con la regla de verificación.",
  "ccm.agent.leavemessage.process": "Proceso",
  "ccm.agent.label.skillQueue": "Cola de habilidades:",
  "ccm.certificate.management.certificatefile.maxrecord": "El número máximo de certificados es {0}.",
  "ccm.contact.detail.sending.time": "Tiempo de envío",
  "ccm.agent.message.searchAgentServiceNo": "Introduzca el número de servicio del agente",
  "ccm.agent.calledroute.create.gateway.none": "El script de enrutamiento al que hace referencia la configuración de la parte llamada no existe.",
  "ccm.agent.label.sipAccount": "Número de Softphone",
  "ccm.agent.updateSoftPhone.updateGatewayFailed": "Error al cambiar la contraseña de la puerta de enlace.",
  "ccm.recognition.label.openVoiceNotificationSuccess": "La función de notificación por voz se habilita correctamente.",
  "ccm.agent.sysparamconfig.verifyTemplateIdLength": "El ID interno de la plantilla de mensaje debe ser un entero y no puede ser un número negativo.",
  "ccm.agent.message.validatordigit": "La contraseña de configuración del agente de teléfono único debe ser numérica solamente",
  "ccm.agent.leavemessage.messageprocess": "Mensaje de proceso",
  "ccm.agent.label.agentType": "Tipo de agente",
  "ccm.recognition.label.voiceNotification": "Indicador de notificación de voz",
  "ccmanagement.tenantspace.option.weeklist_sec": "En segundo lugar",
  "ccm.agent.message.deleteSkill": "Eliminar cola de habilidades",
  "ccm.agent.label.notselectcallreason": "Por favor seleccione el motivo de la llamada",
  "ccm.recognition.label.orderfailwhilte": "Error en la suscripción. Error al sincronizar la lista de confianza.",
  "ccm.urlconfig.label.relurl": "Cuelgue URL",
  "ccm.agent.message.modifySkillFail": "Error al actualizar la habilidad.",
  "ccm.transtask.label.contactRecord": "Registro de contacto",
  "ccm.transserver.discard.confirm": "¿Está seguro de que desea descartar la tarea?",
  "ccm.satisfactionconfig.message.media": "Canales multimedia",
  "ccm.speciallist.msg.limit": "El número de contenidos importados supera los 1000",
  "ccm.label.page.modifyPage": "Página Modificar",
  "ccm.satisfactionconfig.message.groupbatchdelete": "Eliminación por lotes",
  "ccm.custdata.title.modify": "Edición de una tabla base",
  "ccm.satisfactionconfig.web.switch.remark": "Después de activar este interruptor, la encuesta de satisfacción se enviará al cliente una vez finalizado el servicio.",
  "ccm.agent.label.authtype.uap": "Autenticación UAP",
  "ccm.idauth.create.error.limit": "El proceso de autenticación admite la creación de solo cinco.",
  "ccm.whatsapp.template.message.id": "ID de mensaje de plantilla",
  "ccm.agent.contact.talkReason": "Motivo de la llamada",
  "ccm.satisfactionconfig.message.createsuccess": "Se ha añadido correctamente la configuración de la encuesta de satisfacción",
  "ccm.satisfactionservice.evaluationerror": "Error en la evaluación",
  "ccmanagement.satisfactionconfig.calltime.timeerror.laterThanEndTime": "La duración mínima de la llamada debe ser menor que la duración máxima de la llamada.",
  "ccm.transtask.label.restores": "Restaurar",
  "ccm.pageinterface.inputInterfaceName": "Entrar",
  "ccm.agent.contact.startcallfailed": "Error al iniciar la llamada",
  "ccm.agent.message.deleteSkillConfSuccess": "La información de la cola de habilidades se elimina correctamente.",
  "ccm.transtask.label.taskname": "Nombre de tarea",
  "ccm.custdata.operlog.conditions": "Condiciones",
  "ccm.label.contactexport.selectdata": "Seleccionar datos",
  "ccm.agent.message.agenttypecannotbenull": "Seleccione un tipo de agente si se selecciona la cola de habilidades.",
  "ccm.agent.pageurlparam.relSysParamType": "Tipo de parámetro de datos de sistema asociados",
  "ccm.agent.label.inputendworkno": "Ingrese el ID del empleado final",
  "ccm.agent.message.pageNameValidateFail": "No se permiten caracteres especiales",
  "ccm.agent.message.itemNameValidateFail": "Los siguientes caracteres especiales no están permitidos &<>\";%/[]=+{'@'}",
  "ccm.calledconfig.message.willdeletemobileagent": "¿Está seguro de que desea eliminar el agente móvil seleccionado?",
  "ccm.label.page.createPage": "Nueva página",
  "ccm.agent.sysparamconfig.refreshAllSysParamSuccess": "Refrescar con éxito.",
  "ccm.recognition.label.openmobileagentsuccess": "Habilitado con éxito el agente móvil.!",
  "ccm.callreasonconfig.message.deletecallreason.failed": "Error eliminado.",
  "ccm.speciallist.msg.dateerror": "Introduzca el tiempo efectivo y el tiempo de caducidad",
  "ccm.agent.contact.assistant": "Llamada colaborativa",
  "ccm.custdata.operlog.authFailed": "Error de autenticación",
  "ccm.agent.label.useTheSkill": "La cola de habilidades {0} se ha enlazado al ID de agente {1}. Elimine primero la configuración de la cola de habilidades bajo el agente.",
  "ccm.agent.sysparamconfig.worknowarn": "Utilice comas (,) para separar los ID de agente.",
  "ccm.agent.title.agentinfo": "Información del agente",
  "ccm.agent.ten.agentSipInfo": "Dirección IP y número de puerto del servidor de registro del agente",
  "ccmanagement.satisfaction.strategy.acceptdate.startdate": "Fecha de inicio",
  "ccm.satisfactionconfig.message.calltype": "Tipo de llamada",
  "ccm.dual.call.record.option": "Operación",
  "ccm.botherlist.label.comment": "Opiniones",
  "ccm.agent.contact.playVideo": "reproducir un vídeo",
  "ccm.satisfactionconfig.message.channelusing": "Un canal hace referencia al grupo de directivas y no se puede eliminar.",
  "ccm.callout.message.confreset": "Confirmación de reinicio",
  "ccm.custdata.label.isMandatory": "Obligatorio",
  "ccm.note.config.agent.page.ring.title": "Esta configuración solo se aplica cuando la página está oculta, como la minimización del navegador, o cuando la página está en una pestaña en segundo plano.",
  "ccm.calledconfig.table.skillormumbers": "Dispositivo de propósito",
  "ccm.agent.restrecord.reststatus.normal.endrest": "El descanso terminó normalmente",
  "ccm.agent.label.playVoice": "Toca el tono de espera",
  "ccm.agent.calledroute.confdelete": "Confirmar elimicación",
  "ccm.satisfactionlevel.message.savefail": "Error al guardar el nivel de satisfacción.",
  "ccm.agent.label.createWorkOrdeType": "Tipo de caso asociado",
  "ccm.transtask.label.datacondition": "Criterios de filtro de datos",
  "ccm.transtask.label.agentOutBoundReport": "Informe de resumen de llamadas salientes del agente",
  "ccm.agent.message.warning": "Advertencia",
  "ccm.agent.sysparamconfig.multimediawarn": "El número de mensajes multimedia simultáneos debe oscilar entre 1 y 60.",
  "ccm.systemparam.success.save": "El parámetro se ha guardado correctamente.",
  "ccm.custdata.operlog.exportiong": "Exportación",
  "ccm.agent.calledroute.errorsave": "Error",
  "ccm.agent.message.searchsoftphone": "Ingrese el número de softphone.",
  "ccm.agent.contact.calltime": "Duración (es) de la llamada",
  "ccm.agent.button.downloadvideoterminal": "Descargar OpenEye",
  "ccm.calledconfig.label.numbers": "Números",
  "ccm.custdata.label.isIndex": "Utilizado como índice",
  "ccm.agent.message.synToUapFailed": "Error al sincronizar el modo de autenticación del agente con el UAP.",
  "ccm.sysparam.error.validate.notmatchrulemaxlength": "Longitud máxima del parámetro",
  "ccm.urlconfig.label.talkmethod": "Método de devolución de llamada de conexión",
  "ccm.custdata.label.columnName": "Nombre del campo",
  "ccm.agent.message.nocomments": "No hay comentarios",
  "ccm.agent.message.willDeleteAgents": "¿Está seguro de que desea eliminar el agente seleccionado?",
  "ccm.transserverparam.label.info": "Información Básica",
  "ccm.custdata.msg.columnNoMatch": "El campo de la tabla no es coherente con el de la base de datos. Actualice la tabla e inténtelo de nuevo.",
  "ccm.agent.contact.recordingfiles": "Archivo de grabación",
  "ccm.agent.message.selectMaxAgents": "Puede procesar un máximo de 20 a la vez.",
  "ccmanagement.satisfaction.strategy.fail": "Failure",
  "ccm.agent.message.existAIAgent": "El reconocimiento inteligente y la grabación de doble pista no se pueden habilitar al mismo tiempo, mientras que el reconocimiento inteligente se ha habilitado para que algunos de los agentes se modifiquen en lotes.",
  "ccm.agent.label.fixedValue": "Valor fijo",
  "ccm.satisfactionconfig.message.delete": "Delete",
  "ccm.agent.operation.FORCELOGOUT": "Forzar la salida",
  "ccm.agent.mobileagent.notice": "Aviso",
  "ccm.agent.operation.optime": "Tiempo de operación",
  "ccm.certificate.management.certificate.crlfile.sizeerror": "El archivo CRL no puede superar los 2048 KB.",
  "ccm.agent.title.specialListReminder": "Recordatorio de lista especial",
  "ccm.recognition.label.fail": "Fallo",
  "ccm.certificate.management.certificatefile.certsceneerror": "La escena del cert es un error.",
  "ccm.agent.calledroute.pagename": "Nombre de página",
  "ccm.agent.leavemessage.channelType": "Tipo de canal",
  "ccm.satisfactionconfig.message.strategygroupsrv": "Configuración de canal de directiva de encuesta de satisfacción",
  "ccm.transserverparam.label.agentport": "El puerto",
  "ccm.transserverparam.label.agentip": "Dirección del servidor proxy",
  "ccm.agent.label.dualChannelRecFlag": "Grabación de doble pista",
  "ccm.custdata.operlog.operation": "Operar",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.overlimit": "Se puede guardar un máximo de 100 directivas de fecha de manejo.",
  "ccm.agent.operation.HOLD": "Espera",
  "ccm.custdata.option.columnType.time": "DateTime",
  "ccm.satisfactionconfig.message.duplicateconfig": "La prioridad de cada política para el mismo canal debe ser única.",
  "ccm.botherlist.label.approveComment.approved": "Rechazar",
  "ccm.satisfactionconfig.message.callskill": "Cola de habilidades",
  "ccm.callreason.label.setcallreason": "Configuración de Motivo de Llamada",
  "ccm.satisfactionconfig.message.audiofeedbacktype": "voz IVR",
  "ccm.callout.placeholder.caller": "Por favor, introduzca el número de llamada de la llamada saliente",
  "ccm.speciallist.msg.level": "nivel de lista especial",
  "ccm.basicdata.error.syserror": "Error del sistema. Póngase en contacto con el personal de mantenimiento",
  "ccm.certificate.management.certificatefile.uploadcertcrlfile": "Cargar archivo CRL",
  "ccm.satisfactionconfig.message.acceptagent": "Agente de manejo",
  "ccm.satisfactionconfig.message.channelstrategyconfig": "Política de encuesta de satisfacción del canal",
  "ccm.agent.resetskillrecord.adjustagentworkno": "Ajuste de ID de agente",
  "ccm.speciallist.msg.alreadyexist": "ya tiene una notificación de lista especial del mismo tipo y nivel.",
  "ccm.contactitemconfig.label.data.item.basicext": "Datos básicos extendidos",
  "ccm.callout.title.success": "Éxito",
  "ccmanagement.tenantspace.option.weeklist_fth": "Cuarto",
  "ccm.agent.message.weight.limit": "[1-100]",
  "ccm.agent.message.agentweight.limit": "[1-300]",
  "ccm.querycustdata.msg.selectTable": "Seleccionar un nombre de tabla",
  "ccm.custdata.label.template": "_Plantilla",
  "ccm.agent.label.registrationMessage": "Información de registro",
  "ccm.transserver.label.serveraddr": "Dirección",
  "ccm.agent.button.submit": "Enviar",
  "ccm.agent.calledroute.create.accesscode.noexist": "El código de acceso al que hace referencia la configuración de la parte llamada no existe.",
  "ccm.custdata.option.isMandatory.yes": "Requerido",
  "ccm.note.config.filepath": "Seleccionar archivo de nota",
  "ccm.agent.contact.multimedia.telegram": "Multimedia-Telegram",
  "ccm.verifyidentity.verifyTime": "Verificar tiempo",
  "ccm.agent.contact.playLeaveMessage": "Se reproduce el siguiente mensaje:",
  "ccm.datatask.checkNum": "El valor es un entero positivo.",
  "ccm.agent.Transfer.Intercept": "Interceptar",
  "ccm.satisfactionconfig.message.smschannel": "SMS Push Channel",
  "ccmanagement.satisfaction.strategy.calltime.maxvalue": "Duración (es) máxima de la llamada",
  "ccm.agent.conditionOfBusyTransfer.error": "Configure los parámetros de condición de reenvío ocupado.",
  "ccm.agent.resetskillrecord.skillbeforeadjust": "Cola de habilidades antes del ajuste",
  "ccm.agent.label.mobileagententer": "Entrar",
  "ccm.agent.label.expired": "Caducado",
  "ccm.agent.pageurls.createcall": "Nueva pantalla emergente de llamada entrante",
  "ccm.agent.pageurls.config.screen.pop.up": "Los datos no guardados existen. Por favor guárdelo primero.",
  "ccm.agent.pageurls.unsaved.data.exists":"Unsaved data exists. Please save it first.",
  "ccm.agent.label.preVoiceSelect": "Anuncio antes de informar ID del agente",
  "ccm.accesscode.label.accesscode": "Código de acceso",
  "ccm.speciallevel.speciallist.levelMessage": "Gestión de Niveles",
  "casemanagement.user.title.name": "Nombre de usuario",
  "ccm.satisfactionconfig.message.confirm": "Confirmar",
  "ccm.note.config.agent.page.ring.tipTitle": "Si se configura un cuadro de diálogo de notificación, el cuadro de diálogo sólo tiene efecto después de que el agente configure manualmente Permitir que este sitio web use ventanas emergentes en el navegador cuando se integra la conexión",
  "ccm.speciallist.tip.startTime.min": "La hora efectiva debe ser posterior a la hora actual",
  "ccm.agent.label.usedMode": "Tipo",
  "ccm.agent.calledroute.operation": "Operación",
  "ccm.agent.title.batchagentEdit": "Configuración de información del agente por lotes",
  "ccm.contactitemconfig.label.data.item.category": "Tipo de datos",
  "ccm.agent.contact.allcalltype": "Todos los tipos de llamadas",
  "ccm.basicdata.msg.nodatamodify": "No es necesario modificar ningún dato.",
  "ccm.basicdata.msg.modifyfail": "Error al modificar los datos básicos.",
  "ccm.speciallist.msg.approve": "Homologación",
  "ccm.speciallist.label.approve": "Comentarios de aprobación",
  "ccm.speciallist.poptitle.approve": "Aprobar registros de acoso",
  "ccm.speciallist.poptitle.view": "Visualización de registros de acoso",
  "ccm.speciallist.title.approve": "Información de aprobación",
  "ccm.basicdata.error.databaseerror": "Los datos del índice están duplicados. Como resultado, los datos no se pueden importar a la base de datos en lotes",
  "ccm.agent.button.clear": "Restablecer",
  "ccm.recognition.label.openanonymoussuccess": "Habilitado con éxito el indicador de llamada saliente anónima. Seleccione un código de acceso \"click-to-call\" configurado para la ruta llamada.",
  "ccm.satisfactionconfig.message.channeltype": "Tipo de canal",
  "ccm.sysparam.error.validate.notmatchrulemin": "El parámetro de entrada es menor que el mínimo",
  "ccm.satisfactionconfig.param.faile": "Parámetro incorrecto.",
  "ccm.agent.updateSoftPhone.updateFailed": "Error al cambiar la contraseña",
  "ccm.agent.calcOfMaxCallNum.error": "Establezca el número máximo de llamadas en cola.",
  "ccm.contact.tip.not.get.workname": "No se obtuvo el nombre de la cuenta correspondiente al agente",
  "ccm.contactitemconfig.message.DatasetName.inuse": "El conjunto de datos no se puede eliminar porque ya está configurado en el elemento de datos del registro de contactos.",
  "ccm.sysparam.url.safe.tip": "El protocolo HTTP inseguro tiene riesgos de seguridad",
  "ccm.agent.label.keepWaitType": "Método Keeping",
  "ccm.transtask.label.tasktype": "Tipo",
  "casemanagement.common.label.nodata": "No hay datos disponibles.",
  "ccm.agent.label.Account": "Cuenta",
  "ccm.custdata.option.columnType.date": "Fecha",
  "ccm.callout.lable.type": "Tipo de parámetro",
  "ccm.agent.calledroute.create.media.match": "El código de acceso al sistema configurado para la parte llamada es diferente del tipo de medios configurado para la parte llamada.",
  "ccm.agent.label.noaireconition": "FALSO",
  "ccm.agent.label.maxWaitTime": "Tiempo (s) de espera máximo (s)",
  "ccm.transserver.label.cssnotinwhite": "Error en la operación. Póngase en contacto con el administrador del sistema para agregar la dirección del servicio CSS a la lista de confianza del servidor CSS de volcado de recursos.",
  "ccm.chatbot.label.assist": "Asistente de robot",
  "ccm.verifyidentity.loginId": "Cuenta de negocios",
  "ccm.custdata.button.moveTop": "Arriba",
  "ccm.agent.message.agentUpdateFail": "Error en el registro de actualización.",
  "ccm.transtask.label.task.chatrecordall.uniqueness": "No puede agregar todos los tipos de canal porque existen tareas para algunos tipos de canal.",
  "ccm.satisfactionconfig.message.strategygroupdesc": "Policy Group Descripción",
  "ccm.agent.message.clear": "Borrar todo",
  "ccm.agent.restrecord.rest.reason": "Razón del descanso",
  "ccm.skill.label.skilldesc": "Descripcion",
  "ccm.pageinterface.interfaceType.internal": "Interfaz interna",
  "ccm.calledroute.button.finish": "Completo",
  "ccm.agent.label.operate": "Operación",
  "ccm.transtask.label.ivrReport": "Reporte de tráfico IVR",
  "ccm.agent.contact.contactno": "Número de contacto",
  "ccm.agent.resetskillrecord.adjustedbusinessaccount": "Cuenta de servicio ajustada",
  "ccm.agent.label.selfPhoneNotNull": "Cuando la función de anonimización del número de agente está habilitada, el número de teléfono de fijo o  móvil del agente no pueden estar vacíos.",
  "ccm.agent.title.agentpassword": "Contraseña de la cuenta",
  "ccmanagement.tenantspace.option.weeklist_sectolast": "Último pero uno",
  "ccm.verifyidentity.noMásThan30Days": "El rango de tiempo no puede exceder los 30 días.",
  "ccm.agent.title.serviceAccount": "Cuenta de servicio asociado en la nube",
  "ccm.urlconfig.label.oauth.ak": "AK",
  "ccm.agent.button.delete": "Borrar",
  "ccm.vcallcenter.config.popup.authentication": "Autenticación",
  "ccm.note.config.searchnotename": "Por favor ingrese su nombre",
  "ccm.custdata.operlog.addExportTaskError": "Error en la nueva tarea de exportación, compruebe el registro",
  "ccm.sysparam.error.validate.notmatchruleurl": "Por favor, introduzca una dirección URL válida",
  "ccm.agent.restReason.create": "Añadir",
  "ccm.agent.softPhoneStatus": "estado del número de softphone",
  "ccm.agent.ten.DSTStartDate": "Fecha de inicio del horario de verano",
  "ccm.anonymous.message.fail.save": "Error al guardar la ruta llamada. Compruebe la configuración del CTI",
  "ccm.agent.label.video": "Agente de vídeo",
  "ccm.sysparam.error.validate.notmatchrulemax": "El parámetro de entrada excede el máximo",
  "ccmanagement.tenantspace.option.weeklist_fthtolast": "Últimos pero tres",
  "ccm.agent.title.specialListHis": "Historia de la lista especial",
  "ccm.custdata.operlog.specCharValidate": "No puede contener caracteres especiales excepto _ @ %",
  "ccm.agent.message.selectAgents": "Seleccione un agente.",
  "ccm.urlconfig.message.overlimit.appId": "La clave de acceso configurada para llamadas bidireccionales ITA no puede exceder los 128 caracteres.",
  "ccm.agent.contact.joinreason": "Razón para unirse",
  "ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE": "Error al descargar el archivo de grabación del servidor SFTP.",
  "ccm.sysparam.error.validate.inner": "Error al validar el parámetro",
  "ccm.agent.message.redislocknull": "Error al obtener el bloqueo Redis.",
  "ccm.sysparam.config.reset": "Restablecer",
  "ccm.urlconfig.message.deletefail": "Error al restablecer las urls",
  "ccm.agent.label.waitAgentWeight": "Introduzca un peso de agente.",
  "ccm.calledroute.message.selectcalledroute": "Seleccione la configuración de la parte llamada.",
  "ccm.agent.login.LOGIN": "Iniciar sesión",
  "ccm.custdata.msg.operationSuccess": "La operación tuvo éxito.",
  "ccm.agent.contact.recordInfo": "Detalles del archivo de grabación",
  "ccm.recognition.label.modifyVoiceNotificationClose": "La función de notificación por voz no está habilitada para el espacio del tenant.",
  "ccm.agent.operation.CANCELLISTEN": "Cancelar Escuchar",
  "ccm.agent.mobileagent.success": "Éxito",
  "ccm.agent.contact.SFTP_INCORRECT_FILE_CODE": "La información del servidor sftp es incorrecta",
  "ccm.agent.pageurls.whitelisturl": ". Póngase en contacto con el administrador del sistema para agregar la URL a la lista de confianza.",
  "ccm.agent.ten.cobrowseMaxNumber": "Número máximo de conexiones de colaboración de páginas web",
  "ccm.idauth.title.ivr": "Proceso de verificación",
  "ccm.speciallist.msg.selectslreminder": "Seleccione un recordatorio de lista especial.",
  "ccm.agent.contact.email": "Correo Electrónico",
  "ccm.agent.contact.Email": "Email",
  "ccm.pageurls.select.webApi": "API web",
  "ccm.callreason.label.name": "Nombre",
  "ccm.agent.calledroute.createcalledroute": "Nuevo",
  "ccm.taskrecord.label.executing": "Ejecutando",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.yes": "Sí",
  "ccm.skill.label.maxwaittime": "Duración máx. de cola",
  "ccm.recognition.label.appSecret.download.SK.not.exist": "La información SK no existe.",
  "ccm.agent.label.skillpara": "Configuración de parámetros de habilidad",
  "ccm.agent.contact.subtype.none": "Ninguno",
  "ccm.agent.leavemessage.lengthcodewarn": "Un mensaje puede contener un máximo de 300 caracteres.",
  "ccm.agent.mobileagent.confirm": "Confirmar",
  "ccm.recognition.label.save": "Guardar",
  "ccm.agent.updateSoftPhone.unErrorInfo": "Excepción del sistema.",
  "ccm.basicdata.msg.error": "Error",
  "ccm.custdata.operlog.passValidateFailed": "Debe contener letras, números, caracteres especiales _ {'@'} {'%'}",
  "ccm.urlconfig.modify.oauth.ak.tooshort": "El AK debe contener al menos 16 caracteres.",
  "ccm.agent.message.createSkillConfSuccess": "La información de la cola de habilidades se crea correctamente.",
  "ccm.dual.call.record.statistics": "Estadísticas",
  "ccm.agent.ccagent.qcmodify.fail": "Seleccione un tipo de agente de audio.",
  "ccm.agent.contact.norecording": "No hay grabación.",
  "ccm.speciallist.msg.delete": "Borrar",
  "ccm.satisfactionconfig.message.strategychoosed": "Políticas seleccionadas",
  "ccm.pageinterface.selectAttributeTitle": "Seleccionar atributo de objeto",
  "ccm.agent.pageurls.movedown": "Abajo",
  "ccm.agent.label.maxWaitTimeSpan": "Valor [1 –60000]",
  "ccm.custdata.msg.dictionaryInputFormat": "Formato: Clave 1: Valor 1, Clave 2: Valor 2",
  "ccm.speciallist.msg.empty": "El mensaje de recordatorio no puede ser nulo.",
  "ccm.contactitemconfig.message.confOnlyDataItemName": "Error en la operación porque el nombre del elemento de datos ya existe.",
  "ccm.agent.pageurls.confnaming": ". Asegúrese de que el nombre no existe en el sistema.",
  "ccm.agent.login.FORCELOGIN": "Forzar el inicio de sesión",
  "ccm.transtask.label.pause": "Pausa",
  "ccm.agent.pageurlparam.urlParamName": "Parámetro de URL emergente de pantalla",
  "ccm.ucconfig.message.success": "Éxito",
  "ccm.agent.accesscode.n400number": "Número de servicio",
  "ccm.custdata.button.withdraw": "Retirar",
  "ccm.satisfactionconfig.satisfaction.switch": "interruptor",
  "ccm.agent.label.off": "Apagado",
  "ccm.calledconfig.message.casetype": "Tipo de caso",
  "ccm.satisfactionconfig.message.configstrategydata": "Configurar datos de directiva",
  "ccm.speciallist.label.blocklist": "Lista de bloqueos",
  "ccm.agent.contact.customername": "Nombre del cliente",
  "CCM.AGENT.CONTACT.ORG": "Organización del agente",
  "ccm.agent.contact.agent.recall": "El agente ha retirado el mensaje",
  "ccm.agent.calledroute.placeholder.ivr": "Seleccione un IVR.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputparse": "La fecha de inicio o fin tiene un formato incorrecto.",
  "ccm.agent.operation.ID": "Operación No.",
  "ccm.agent.operation.HELP": "Consultar",
  "ccm.speciallist.button.cancel": "Cancelar",
  "ccm.certificate.management.certificate.crlfile.crlfileinvalid": "Formato de archivo CRL incorrecto.",
  "ccm.agent.leavemessage.status.inProcess": "Procesamiento",
  "ccm.agent.button.ok": "OK",
  "ccmanagement.satisfaction.strategy.success": "Success",
  "ccm.custdata.button.release": "Lanzamiento",
  "ccm.agent.message.samePageNameFail": "Duplicar el nombre de página.",
  "ccm.agent.ten.daylightSavingTime": "DST",
  "ccm.satisfactionlevel.message.savesuccess": "El nivel de satisfacción y la plantilla de encuesta de satisfacción se guardan correctamente. Si se ha configurado el proceso de satisfacción, suelte el proceso en la página Administración de procesos. De lo contrario, el resultado de satisfacción se mostrará incorrectamente. La nueva encuesta de satisfacción se basará en los resultados de esta configuración.",
  "ccm.custdata.option.importFail": "Error",
  "ccm.satisfactionconfig.message.staistip1": "Después de activar este interruptor, el cliente será transferido a la encuesta de satisfacción después de que el agente cuelgue.",
  "ccmanagement.tenantspace.option.weekday_wed": "Miércoles",
  "ccm.agent.mobileagent.fail": "Error",
  "ccm.label.contactexport.parainnull": "Seleccione los datos que desea exportar.",
  "ccm.agent.restReason.operation": "Operación",
  "ccm.agent.videoDownload.noPermission": "El agente no tiene permiso para descargar",
  "ccm.custdata.option.isEncrypt.no": "No",
  "ccm.satisfactionconfig.message.success": "Éxito",
  "ccm.speciallist.operlog.downloadtemplate": "Descargar plantilla",
  "ccm.satisfactionconfig.message.strategygroupmemchoose": "Seleccionar políticas.",
  "ccm.custdata.msg.indexColumnValidateFailed": "Un campo utilizado como índice es obligatorio y no se puede cifrar.",
  "ccm.custdata.operlog.viewExportTask": "Exportar vista de tareas",
  "ccm.satisfactionconfig.message.surveyconfig": "Configuración de topografía",
  "ccm.agent.message.modifymobileagentfail": "Error al modificar la información sobre el número de softphone del agente móvil.",
  "ccm.ivr.label.processfilename": "Nombre de archivo",
  "ccm.custdata.msg.tableBeUsed": "Un objeto de página de configuración hace referencia a la tabla personalizada. Asegúrese de que no se hace referencia a la tabla antes de realizar esta operación.",
  "ccm.agent.contact.agenthungup": "Agente",
  "ccm.agent.message.typenosame": "Los tipos de cola de habilidades deben ser los mismos.",
  "ccm.agent.contact.contactinfo": "Información de contacto",
  "ccm.agent.leavemessage.paramisempty": "Parámetro vacío.",
  "ccm.agent.message.queryctiagentmessage": "Error al encontrar la información del agente en CTI.",
  "ccm.agent.calledroute.delsuccess": "Se ha eliminado correctamente.",
  "ccm.satisfactionconfig.message.webfeedbacktype": "Web",
  "ccm.agent.operation.SAYFREE": "Disponible",
  "ccm.callout.message.choosecaller": "Por favor, elija una persona que  llame a la llamada",
  "ccm.satisfactionconfig.message.smsgateway": "Puerta de enlace SMS",
  "ccm.contactitemconfig.message.init.basicdata.success": "Los datos básicos se inicializan correctamente.",
  "ccm.agent.ccagent.modify.fail": "El reconocimiento inteligente y la grabación de doble pista no se pueden activar al mismo tiempo.",
  "ccm.agent.label.mediaagent": "Agente multimedia",
  "ccm.anonymous.message.fail.cti": "Error al consultar el flujo de llamadas salientes anónimas en CTI",
  "ccm.custdata.label.relaBaseColumn": "Campo en la tabla subyacente",
  "casemanagement.user.title.select": "Seleccionar",
  "ccm.custdata.msg.notAllowOperation": "Esta operación no está permitida. Actualice la página e inténtelo de nuevo.",
  "ccm.basicdata.error.suffix": ".",
  "ccm.agent.label.paramsConfig": "Configuración de parámetros del sistema",
  "ccm.agent.label.answerType": "Tipo de respuesta",
  "ccm.agent.operation.noMoreThan90Days": "El intervalo de tiempo no puede exceder los 90 días.",
  "ccm.agent.contact.workno": "ID de empleado",
  "ccm.agent.verify.fail": "Error en la verificación.",
  "ccm.note.config": "Configuración del tono de marcado",
  "ccm.note.select": "Seleccione un tono de vuelta de anillo",
  "ccm.agent.restReason.restCauseTime.limit": "La duración del descanso debe ser superior a 0 segundos y inferior a 24 horas.",
  "ccm.taskrecord.label.executeStatus": "Estado de la tarea",
  "ccm.skillcreate.maxnumber.failed": "El número de colas de habilidades del tenant no puede exceder el límite superior:",
  "ccm.pageinterface.interfaceName": "Nombre de la interfaz",
  "ccm.verifyidentity.fail.VERIFYTIMERANGEERROR": "Intervalo de tiempo incorrecto para la consulta",
  "ccm.page.label.status": "Estado de la página",
  "ccm.agent.operation.UNMUTE": "Desactivar el silencio",
  "ccm.sysparam.error.save": "Errores",
  "ccm.ivrflow.placeholder.ivrflowdesc": "Por favor, introduzca una descripción de flujo de ivr.",
  "ccm.agent.message.redisFailed": "Error al obtener el bloqueo Redis.",
  "ccm.calledconfig.message.deletemobileagentfail": "Error al eliminar la asociación de la configuración del agente móvil",
  "ccm.recognition.label.clicktocallaccesscode": "Código de acceso Click-to-call",
  "ccm.whatsapp.select.whatsApp.message.template": "Por favor, seleccione una plantilla de WhatsApp mensaje.",
  "ccm.sysparam.error.validate.notmatchrulenumber": "Por favor, introduzca un número",
  "ccmanagement.tenantspace.option.weekday_sat": "Sábado",
  "ccmanagement.satisfaction.strategy.save": "Save",
  "ccm.agent.pageurls.errorinfo": "Error de creación.",
  "ccm.agent.operation.SWITCH": "Interruptor",
  "ccm.label.contactexport.selectrange": "Seleccionar rango",
  "ccm.transserver.delete.time.fail": "Elimine la tarea diez minutos después de descartarla.",
  "ccm.transserver.label.css": "Servidor CSS",
  "ccm.agent.label.serviceAccountId": "ID de cuenta de Service Cloud asociado",
  "ccm.agent.message.deleteAgentSkillFail": "Error al eliminar la asociación de la cola de habilidades del agente.",
  "ccmanagement.satisfaction.strategy.hourformatvalidate": "La hora está en un formato incorrecto.",
  "ccm.agent.message.isornoairecognition": "Estado Inteligente",
  "ccm.agent.ten.phonenumber": "Número de teléfono móvil",
  "ccm.page.select.tileLayout": "Diseño de cuadrícula",
  "ccm.agent.label.sensitiveWordNumber": "Palabras prohibidas",
  "ccm.agent.sysparamconfig.worknorangewarn": "El ID del agente debe estar comprendido entre 101 y 50000.",
  "ccm.satisfactionconfig.message.strategygroupid": "Identificador de grupo de políticas",
  "ccm.recognition.label.closeVoiceNotificationSuccess": "La función de notificación por voz se deshabilita correctamente.",
  "ccm.satisfactionconfig.ivr.voice.satisfaction.survey": "Encuesta de Satisfacción de Voz IVR",
  "ccm.operlog.contactexport.download": "Descargar registros de contactos",
  "ccm.agent.ten.recordtime": "Periodo de retención de grabación",
  "ccm.agent.operation.FORCEDROPCALL": "Liberación forzada",
  "ccm.agent.agentcontact.nosearch": "No se puede consultar en el manejo no comercial.",
  "ccm.agent.recall.agent.calltype.invalid": "El tipo de llamada actual no admite la operación de devolución de llamada.",
  "ccm.agent.ten.vdnid": "ID de VDN",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkLimit": "No se permiten más de cinco asientos.",
  "ccm.contactitemconfig.message.itemValidateall": "Introducir guion bajo (_), signo de dólar ($), letras o dígitos",
  "ccm.note.config.policy": "Tono trasero del anillo del agente",
  "ccmanagement.tenantspace.option.weeklist_tid": "Tercero",
  "ccm.speciallist.msg.disable.fail": "Error al deshabilitar",
  "ccm.agent.label.ordinaryAgent": "Agente común",
  "ccm.agent.label.paramValue": "Valor",
  "ccm.transserverparam.label.cssClusterPass": "Contraseña",
  "ccm.satisfactionlevel.message.resetfail": "Error al restablecer el nivel de satisfacción.",
  "ccm.agent.label.agentWeight": "Peso del agente",
  "ccm.agent.calledroute.recordexist": "El código de acceso está enlazado al código de extensión. Introduzca un nuevo código de extensión.",
  "ccm.agent.contact.authHandle": "Póngase en contacto con el personal de gestión relacionado.",
  "ccm.custdata.button.return": "Regreso",
  "ccm.agent.label.busyRate": "Uso de colas (%)",
  "ccm.urlconfig.message.talkurlnotinwhite": "Error en la operación. Póngase en contacto con el administrador del sistema para agregar la URL de devolución de llamada de conexión a la lista de confianza de devolución de llamada.",
  "ccm.agent.calledroute.edit": "Editar",
  "ccm.satisfactionconfig.message.prioritytip": "*Un valor más pequeño indica una prioridad más alta. La política con mayor prioridad se corresponde preferentemente para ejecutar la encuesta.",
  "casemanagement.user.button.cancel": "Cancelar",
  "ccm.custdata.option.isEncrypt.yes": "Sí",
  "ccm.speciallist.msg.deleteyerror": "Error al eliminar la lista especial",
  "ccm.custdata.label.failNum": "Número de registros fallidos",
  "ccm.custdata.label.dualCall.sucNum": "Número de éxitos",
  "ccm.custdata.label.dualCall.failNum": "Número de fallos",
  "ccm.contact.customer.config.italic": "En cursiva",
  "ccm.agent.calledroute.delete.skill.use": "Una cola de habilidades hace referencia a la configuración de grupo llamada y no se puede eliminar.",
  "ccm.transtask.label.sunday": "Domingo",
  "ccm.agent.label.waitVoiceDefault": "Tono de espera predeterminado",
  "ccmanagement.satisfaction.strategy.calltime.interval": "Rango de duración de llamada",
  "ccm.urlconfig.message.relurlnotinwhite": "Error en la operación. Póngase en contacto con el administrador del sistema para agregar la URL de devolución de llamada a la lista de confianza de devolución de llamada.",
  "ccm.speciallevel.speciallist.restrictHours": "Duración restringida (Hora)",
  "ccm.agent.contact.multimedia.twitter": "Multimedia-X (Twitter)",
  "ccm.satisfactionconfig.select.ivr.process": "Seleccione la encuesta de satisfacción IVR flow",
  "ccm.urlconfig.label.oauth.sk": "SK",
  "ccm.agent.message.sysparamservicerr": "Error de servicio interno. Por favor, revise el registro.",
  "ccm.agent.message.download.err": "Error en la descarga. Póngase en contacto con los ingenieros de O&M del sistema.",
  "ccm.custdata.option.isIndex.no": "No",
  "ccm.agent.accesscode.description.containspatial": "La descripción del código de acceso no puede contener caracteres especiales % _ ^ & * < > $ /",
  "ccm.custdata.msg.confirmAbandon": "¿Estás seguro de que quieres descartarlo?",
  "ccm.agent.calledroute.delete": "Borrar",
  "ccm.agent.restReason.restCauseTime": "Duración del descanso",
  "ccmanagement.satisfactionconfig.acceptdata.timeVerification": "Seleccione una fecha posterior a la hora actual y anterior a 2038-01-19.",
  "ccm.transserverparam.label.sk": "SK",
  "ccm.vcallcenter.config.popup.current.user.locked.error": "El usuario actual está bloqueado y será desbloqueado 10 minutos más tarde.",
  "ccm.urlconfig.message.notNumber.xNumber": "El número X configurado para las llamadas bidireccionales ITA no es número.",
  "ccm.certificate.management.certificatefile": "Archivo de certificado",
  "ccm.sysparam.config.select.null": "Por favor, seleccione un parámetro.",
  "ccm.note.config.configsuccess": "Configurar nota correcta.",
  "ccm.certificate.management.warn.selectcerttype": "Seleccione el tipo de certificado.",
  "ccm.note.config.agent.page.ring.audition": "Audición",
  "ccm.transtask.label.reports": "Informe de volcado",
  "ccm.agent.calledroute.nmsconnect.fail": "Error de conexión NMS.",
  "ccm.chatbot.pop.title": "Seleccionar un robot",
  "ccm.recognition.label.calledroutesuccess": "Configurada correctamente La ruta llamada",
  "ccm.agent.contact.web": "Web",
  "ccm.agent.message.omsAgentTypeBatchCheck": "El rol o tipo de agente seleccionado no coincide cuando el agente (ID de empleado [{0}]) está habilitado en línea.",
  "ccm.speciallist.label.msgContent": "Mensaje de recordatorio",
  "ccm.agent.message.willExportAgent": "\u0020Mensajes. ¿Está seguro de exportar?",
  "ccm.calledconfig.message.deletemobileagent": "Eliminar agente móvil",
  "ccm.agent.label.deviceUsageThreshold": "Umbral de uso del dispositivo (%)",
  "ccm.agent.button.restSoftPhonePwd.tips": "Restablecer la contraseña del softphone afectará al softphone en uso, lo que requerirá que el agente inicie sesión de nuevo. ¿Está seguro de que desea restablecer la contraseña del softphone?",
  "ccm.transtask.label.tuesday": "Martes",
  "ccm.custdata.msg.importDataFail": "Error al importar datos.",
  "ccm.agent.operation.MUTE": "Silenciar",
  "ccm.agent.mobileagent.label.choosehint": "¡Elije agentes antes de eliminarlos!",
  "ccm.skill.label.maxwaitnum": "Máx. llamadas en cola",
  "ccm.recognition.label.closemobileagentsuccess": "Desactivado con éxito el agente móvil.!",
  "ccm.anonymous.message.fail.delete": "Error al eliminar la ruta llamada. Compruebe la configuración del CTI",
  "ccm.sysparam.config.save": "Guardar",
  "ccm.transserver.label.css.serveraddr": "Dirección CSS",
  "ccm.verifyidentity.fail.unknown": "Consulta fallida",
  "ccm.agent.restrecord.rest.duration": "Duración del descanso",
  "ccm.agent.operation.vdnNotEnabled": "El VDN no está habilitado.",
  "ccm.sysparam.config.opterate": "Operación",
  "ccm.callout.title.fail": "Fallar",
  "ccm.satisfactionconfig.message.surveymethod": "Método de encuesta",
  "ccm.recognition.queryAK.error": "Error al consultar la información de la clave de la aplicación.",
  "ccmanagement.tenantspace.option.weekday_fri": "Viernes",
  "ccm.agent.label.theAgent": "Agente:",
  "ccm.operlog.dualcallexport.download": "Descargar registros de llamadas bidireccionales",
  "ccm.callout.message.nocallers": "No se ha elegido ninguna llamada",
  "ccm.agent.callacceptno.set": "Conjunto",
  "ccm.agent.leavemessage.status.solved": "Procesado",
  "ccm.recognition.queryAK.not.exist": "El espacio del tenant actual no tiene una clave de aplicación.",
  "ccm.agent.login.LOGOUT": "Cerrar sesión",
  "ccm.contactitemconfig.message.confOnlyDatasetName": "Error en la operación porque el nombre del conjunto de datos ya existe.",
  "ccm.transserver.delete.success": "El servidor de volcado se elimina correctamente.",
  "ccm.agent.addbother.fail": "Error al agregar.",
  "ccm.verifyidentity.workNo": "Número de agente",
  "ccm.agent.contact.resourceloading": "Cargando recursos...",
  "ccm.agent.message.searchTimeRemaining": "Caducidad de la contraseña",
  "ccm.transtask.label.cantDelete": "No se puede eliminar el servidor porque una tarea de volcado ya hace referencia a él.",
  "ccm.agent.contact.reset": "Restablecer",
  "ccm.agent.operation.failCode": "Código de la razón del fallo",
  "ccm.custdata.option.isIndex.yes": "Sí",
  "ccm.agent.label.versatileagent": "Agente versátil",
  "ccm.speciallevel.tip.start": "¿Está seguro de que desea habilitar?",
  "ccm.ucconfig.message.urlTitle": "Redirigir el URI configurado en la plataforma de Microsoft para aceptar respuestas de autenticación:",
  "ccm.calledconfig.table.addnumbers": "Añadir números",
  "ccm.agent.message.inSpecialStrFail": "El nombre de la página no puede contener caracteres especiales.",
  "ccm.agent.ten.maxVideoAgents": "Cantidad de agente de video",
  "ccm.agent.message.ctipasswdless": "La longitud de la contraseña no puede ser inferior a 8.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnagetive": "La duración mínima o máxima de la llamada no puede ser inferior a 0.",
  "ccm.chatbot.invalid.bot": "El robot no es válido",
  "ccm.urlconfig.label.relurl.voicemsg.alerttip": "Si no se transfiere ninguna URL de devolución de llamada cuando se invoca la API de creación de notificaciones de voz, se vuelve a llamar a esta URL una vez finalizada la notificación de voz. De lo contrario, se devuelve la llamada a la URL transferida.",
  "ccm.sysparam.config.mod": "Versión",
  "ccm.speciallist.msg.size": "El tamaño del archivo que se va a importar supera los 10 MB",
  "ccm.recognition.label.appSecret.download.SK.only.once.error": "Si olvida la clave, restablezca la clave y descárguela de nuevo.",
  "ccm.agent.contact.signaturedate": "Fecha de firma",
  "ccm.recognition.label.closeIvrAgentSuccess": "El asistente de agente inteligente se deshabilita correctamente.",
  "ccm.transserver.label.css.edit": "Configuración del servidor de búsqueda en la nube",
  "ccm.agent.speciallist.addList": "Agregar registro de acoso",
  "ccm.speciallist.label.expirationDate": "Tiempo de caducidad",
  "ccm.agent.label.queuingCallsMaxNum": "Número máximo de llamadas en cola",
  "ccm.transtask.label.datapath": "Ruta del archivo de volcado",
  "ccm.transtask.label.reportedir": "Informe de volcado",
  "ccm.agent.label.timeOut": "Tiempo de espera de habilidad",
  "ccm.sysparam.config.item.catalog": "Tipo de parámetro",
  "ccm.agent.leavemessage.contactNumber": "Número de contacto",
  "ccm.agent.resetskillrecord.adjustedagentworkno": "ID de agente ajustado",
  "ccm.agent.contact.twitter": "X (Twitter)",
  "ccm.agent.operation.searchworkno": "Ingrese el ID de empleado",
  "ccm.agent.ten.sipInfo": "Dirección y puerto SIP",
  "ccm.certificate.management.certificatefile.parseerror": "Error al analizar el certificado. Compruebe si el formato del certificado o la contraseña son correctos.",
  "ccm.agent.login.logIP": "IP de inicio de sesión",
  "ccm.transtask.label.task.otheruniquene": "Solo se puede crear un archivo de voz, datos de inspección inteligente o tarea de tipo de datos CDR para el mismo tipo de datos de volcado.",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility": "Registro de contacto visible",
  "ccm.botherlist.label.approveComment.agree": "Acepto",
  "ccmanagement.satisfaction.strategy.acceptdate.dateEnd": "Fecha de finalización",
  "ccm.recognition.label.updatefail": "Error al actualizar el fautl del inquilino.",
  "ccm.custdata.msg.confirmRelease": "¿Estás seguro de que quieres liberarlo?",
  "ccm.agent.contact.audioclicktodial": "Haga clic por voz para marcar",
  "ccm.agent.ten.maxVideoCalls": "Máx. videollamadas simultáneas",
  "ccm.callout.message.editsuccess": "Éxito para editar la persona que llama",
  "ccm.agent.contact.customizes": "Personalización",
  "ccmanagement.satisfaction.strategy.calltime.minvalue": "Duración mínima de la llamada",
  "ccm.satisfactionconfig.message.strategygroup": "Grupo de directivas de encuestas de satisfacción",
  "ccmanagement.satisfaction.strategy.delete": "Delete",
  "ccm.note.config.failed": "Error de configuración de la nota",
  "ccm.taskrecord.label.executeEndTime": "Hora de finalización",
  "ccm.agent.contact.status": "Estado del documento",
  "ccm.speciallevel.speciallist.unuse": "No disponible",
  "ccm.custdata.msg.fileNameValidateFail": "El nombre del archivo solo puede contener caracteres chinos, letras minúsculas, dígitos o guiones bajos (_)",
  "ccm.callreason.label.callreason": "Motivo de la llamada",
  "ccm.callreason.title.callreason": "Motivo de llamada asociado",
  "ccm.callreason.label.parentcallreason": "Motivo de la llamada de los padres",
  "ccm.callReason.title.createCallReason": "Crear Motivo de la Llamada",
  "ccm.callReason.title.editCallReason": "Editar Motivo de la llamada",
  "ccm.agent.label.authtype.unified": "Autenticación unificada",
  "ccm.agent.calledroute.dnid": "ID de configuración",
  "ccm.agent.label.answerType4": "ID de agente definido por el usuario",
  "ccm.agent.restReason.partDeleteFailed": "Error de eliminación parcial.",
  "ccm.agent.contact.videoclicktodial": "Haga clic en video para marcar",
  "ccm.agent.contact.multimedia.whatsapp": "Multimedia-Whatsapp",
  "ccm.satisfactionconfig.message.createfailed": "Error en la configuración de la nueva encuesta de satisfacción",
  "ccm.agent.label.waitVoiceConfig": "Configuración de colas y esperas",
  "ccm.agent.operation.opresult": "Resultado",
  "ccm.agent.mobileagent.syserror": "Error al habilitar el reconocimiento inteligente. Póngase en contacto con el administrador del sistema para comprobar la configuración del entorno.",
  "ccm.agent.pageurlparam.paramIsConfiged": "El parámetro URL emergente de pantalla o el parámetro de datos del sistema asociado ya existe.",
  "ccm.agent.label.answerType2": "Reporte sin voz",
  "ccm.satisfactionconfig.message.flowaccesscode": "Código de acceso de proceso",
  "ccm.agent.label.answerType0": "Informar ID de empleado",
  "ccm.agent.label.skills": "Cola de habilidades",
  "ccm.agent.label.serviceCode": "Tipo de servicio",
  "ccm.agent.label.duration": "Duración",
  "ccm.agent.label.queueTime": "Duración de la cola",
  "ccm.agent.label.availabilityTime": "Duración disponible",
  "ccm.agent.label.mutetime": "Duración del silencio",
  "ccm.agent.label.calltime": "No hay duración de llamada silenciosa",
  "ccm.agent.label.ringingTime": "Duración del timbre",
  "ccm.agent.label.serviceLevel": "Porcentaje de tiempo de espera (%)",
  "ccm.agent.label.transferInformation": "Transferir o no",
  "ccm.agent.label.transfer": "Tiempos de transferencia",
  "ccm.agent.label.ivrUsage": "Tiempos de reenvío de llamadas IVR",
  "ccm.agent.label.originId": "ID de trabajo del transferidor",
  "ccm.agent.label.queueName": "Cola de habilidades de transferencia",
  "ccm.agent.label.wrapUpCode": "Estado General",
  "ccm.agent.label.uuiInfo": "UUI information",
  "ccm.agent.label.overallState": "Estado general",
  "ccm.note.config.agent.page.ring.setUp": "Configuración de notificación de mensaje de página",
  "ccm.agent.ten.maxAgents": "Agentes de voz",
  "ccm.agent.operation.FORCEBUSY": "Fuerza Ocupado",
  "ccm.note.config.success": "Éxito",
  "ccm.agent.message.audioagentzero": "El recuento de agentes de audio es 0. No se puede configurar la cola de habilidades de clic para marcado de audio.",
  "ccm.agent.contact.callserialno": "Llamar No de serie",
  "ccm.agent.ten.ivrchannels": "Cantidad de canal de audio IVR",
  "ccm.anonymous.message.fail.feature": "La función auth de la llamada saliente anónima es anormal",
  "ccm.label.contactexport.selectdatarange": "Alcance de los datos",
  "ccm.agent.pageurls.pagename": "Nombre de página",
  "ccm.agent.sysparamconfig.paramwarn": "Seleccione un valor de parámetro válido.",
  "ccm.policy.label.updatesucess": "Informações da política atualizadas com sucesso.",
  "ccm.agent.label.notreply": "No puedo responder",
  "ccm.callreasonconfig.message.deletecallreason.used": "Se ha eliminado un error, se ha utilizado el motivo de la llamada.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.startafterend": "La fecha de inicio debe ser anterior a la fecha de finalización.",
  "ccm.custdata.button.importData": "Importar datos",
  "ccm.callreason.input.specialStrValidateFailed": "El nombre del motivo de la llamada no puede contener caracteres especiales.",
  "ccm.chatbot.pop.table.botname": "Nombre del robot",
  "ccm.agent.calledroute.placeholder.extcode": "Por favor, introduzca un código de extensión.",
  "ccm.asr.create.case.info": "Puede hacer clic en Crear caso solo durante una llamada o después de que finalice una llamada.",
  "ccm.agent.speciallist.endtime": "Tiempo de revocación",
  "ccm.agent.leavemessage.status.allocationFailed": "Error de asignación",
  "ccm.worknoaftervoice.config.choosenote": "Seleccione la voz después de la identificación del empleado",
  "ccm.custdata.option.all": "Todo",
  "ccm.agent.pageurls.create": "Nuevo",
  "ccm.agent.label.modifymobileagent": "Editar el número de softphone",
  "ccm.transserver.label.cssaddr": "Dirección de servicio CSS",
  "ccm.recognition.label.api.fabric.id": "identificador API",
  "ccm.agent.calledroute.extcodewarn": "El código de extensión solo puede contener dígitos.",
  "ccm.agent.login.FORCELOGOUT": "Forzar el cierre de sesión",
  "ccm.agent.label.deletecallreason": "Razón actual Contiene razón secundaria No se puede eliminar",
  "ccm.agent.label.signedinAgentsPercentage": "Proporción de agentes de inicio de sesión",
  "ccm.recognition.label.ordersucess": "La suscripción se ha realizado correctamente.",
  "ccm.sysparam.config.select.count": "Se puede seleccionar un máximo de 10 parámetros.",
  "ccmanagement.satisfaction.strategy.hourvalidate": "La hora no puede ser mayor que 24.",
  "ccm.recognition.resetSK.success": "El SK se restableció correctamente.",
  "ccm.custdata.option.maskStyle.maskAll": "Enmascarar todos los dígitos",
  "ccm.transserver.delete.confirm": "¿Está seguro de que desea eliminar el servidor?",
  "ccm.pageinterface.selectedAttributeNum": "Atributos de objeto seleccionados",
  "ccm.agent.pageurls.typenosupported": "Multimedia no admite la configuración interna de la página.",
  "ccm.calledconfig.message.nocalled": "Por favor, elija llamado",
  "ccm.agent.message.idIsEmpty": "El ID de la cola de habilidades está vacío.",
  "ccm.agent.pageurlparam.paramCountLimit": "Se puede configurar un máximo de {limit} parámetros de URL emergentes.",
  "ccm.agent.message.webRTCVerification": "Este parámetro no se puede configurar cuando la característica WebRTC está habilitada.",
  "ccm.agent.contact.callserno": "Llamada S/N",
  "ccm.agent.contact.minCallDuration": "Inicio de la duración de la llamada",
  "ccm.agent.contact.maxCallDuration": "Duración de la llamada aceptada",
  "ccm.agent.contact.lessThan": "Menos de",
  "ccm.agent.contact.callDurationError":"La hora de inicio de la duración de la llamada es menor que la hora de finalización de la duración de la llamada",
  "ccm.agent.contact.talkDurationError":"La duración de la llamada no puede exceder los 31 días.",
  "ccm.agent.contact.fuzzyCallNoError":"El rango de números de contacto (búsqueda difusa) no puede exceder los 31 días.",
  "ccm.agent.contact.fuzzyCallNo": "Número de contacto (consulta difusa)",
  "ccm.agent.leavemessage.status": "estado",
  "ccm.speciallist.tip.endTime.min": "La hora de caducidad debe ser posterior a la hora actual",
  "ccm.agent.sysparamconfig.resttimewarn": "El valor debe ser un entero.",
  "ccm.speciallist.msg.levelName": "Nivel",
  "ccm.callreason.label.selectedcallreason": "Motivo de llamada seleccionado",
  "ccm.custdata.button.moveUp": "Arriba",
  "ccm.agent.label.querymobileagent": "Consultar el número de softphone",
  "ccm.agent.label.addChildReason": "La configuración de causa no debe exceder el nivel 6",
  "ccm.ucconfig.message.updatefailed": "Error al guardar la configuración de integración de UC.",
  "ccm.pageinterface.custTableName": "Nombre de tabla subyacente",
  "ccmanagement.satisfaction.strategy.fail.accepttime.overlimit": "Se puede guardar un máximo de 100 políticas de tiempo de manejo.",
  "ccm.agent.pageurls.updatecall": "Editar la pantalla emergente de llamadas entrantes",
  "ccmanagement.satisfaction.strategy.fail.unknown": "Error al guardar.",
  "ccm.agent.ten.DSTStartTime": "Hora de inicio del horario de verano",
  "ccm.agent.ten.contactInfor": "Método de contacto",
  "ccm.verifyidentity.fail.LIMITOVERLIMIT": "El parámetro de paginación de la consulta excede el límite",
  "ccm.speciallist.msg.operationdoing": "Otros revisores están aprobando",
  "ccm.agent.contact.otherexception": "Otra excepción",
  "ccm.satisfactionservice.noevaluation": "No evaluado",
  "ccm.contactitemconfig.label.data.item.name": "Nombre del elemento de datos",
  "ccm.certificate.management.certificatefile.certcodeexist": "El código del certificado ya existe.",
  "ccm.certificate.management.search.status": "Seleccione el estado del certificado",
  "ccm.certificate.management.status": "Estado del certificado",
  "ccm.certificate.management.status.value.inuse": "En uso",
  "ccm.certificate.management.status.value.discard": "Abandono",
  "ccm.certificate.management.status.oper.reuse": "Reutilización",
  "ccm.certificate.management.status.oper.discard": "Abandono",
  "ccm.certificate.management.status.confirm.reuse": "Confirmación de reutilización",
  "ccm.certificate.management.status.confirm.discard": "Confirmación de abandono",
  "ccm.certificate.management.status.confirmReuse": "¿Está seguro de que desea continuar utilizando este certificado?",
  "ccm.certificate.management.status.confirmDiscard": "Compruebe si el certificado se ha utilizado en ningún escenario de servicio y si el certificado se ha descartado.",
  "ccm.certificate.management.status.update.success": "El estado del certificado se ha modificado correctamente.",
  "ccm.certificate.management.status.update.failed": "Error al cambiar el estado del certificado",
  "ccm.certificate.management.certificatefile.status.error": "El certificado ha sido obsoleto y no se puede modificar",
  "ccm.callout.message.resetsuccess": "Se ha logrado restablecer el parámetro.",
  "ccm.agent.message.deleteSkillConfFail": "Error al eliminar la información de la cola de habilidades.",
  "ccm.agent.message.deleteSkillConfFailID": "Error al eliminar {0}.",
  "ccm.agent.message.modifySkillConfSuccess": "La información de la cola de habilidades se modifica correctamente.",
  "ccm.agent.calledroute.placeholder.skill": "Seleccione una cola de habilidades.",
  "ccmanagement.tenantspace.option.weekday_sun": "Domingo",
  "ccm.pageurls.select.independently": "Abierto de forma independiente",
  "ccm.custdata.label.failreaon": "Causa de falla",
  "ccm.agent.label.agentTel": "Número de teléfono móvil/fijo del agente",
  "ccm.transtask.label.taskedit": "Tarea de volcado de recursos",
  "ccm.custdata.msg.confirmReturn": "La clasificación no está completa. ¿Está seguro de que desea volver a la página para ver la tabla personalizada?",
  "ccm.querycustdata.msg.inputIndexData": "Por favor ingrese",
  "ccm.agent.calledroute.extlengthcodewarn": "La longitud del código de extensión no puede ser mayor que 12",
  "ccm.agent.contact.numhungup": "Transferencia a Número Externo",
  "ccm.agent.label.restQueueRule": "Directiva de Rest Queuing",
  "ccm.agent.contact.multimedia.fiveG": "Multimedia-5G RCS",
  "ccm.transtask.label.chatrecordinf": "Configuración de volcado de datos de chat multimedia",
  "ccm.agent.leavemessage.allStatus": "Estado de todos los mensajes",
  "ccm.satisfactionlevel.message.nopara": "La descripción no puede estar vacía.",
  "ccm.speciallist.label.startDate": "Tiempo efectivo",
  "ccm.transserver.label.cssproxynotinwhite": "Error en la operación. Póngase en contacto con el administrador del sistema para agregar la dirección del servidor proxy a la lista de confianza proxy del servidor CSS.",
  "ccm.agent.restReason.pleaseRefresh": "Sincronice e inténtelo de nuevo.",
  "ccm.whatsapp.whatsApp.channel": "Canal de WhatsApp",
  "ccm.callreason.label.input": "Por favor ingrese",
  "ccm.agent.ten.DSTEndDate": "Fecha de finalización del horario de verano",
  "ccm.transtask.label.saveexit": "Error al agregar un servidor de volcado porque el nombre del servidor ya existe.",
  "ccm.custdata.msg.typeColumnNumValidateFailed": "El número de campos excede el límite superior: solo se permite un campo de índice y un máximo de 30 campos no cifrados y 20 campos cifrados.",
  "ccm.agent.contact.reasonCode": "Código de razón",
  "ccm.satisfactionconfig.message.groupcreate": "Añadir",
  "ccm.urlconfig.label.oauth.voicemsg.alerttip": "Si se transfiere una URL de devolución de llamada cuando se invoca la API de creación de notificación de voz y se configura la siguiente información de autenticación, esta información se usa durante la devolución de llamada de la URL al completarse la notificación de voz. De lo contrario, no se realiza ninguna autenticación durante la devolución de llamada.",
  "ccmanagement.satisfaction.strategy.accepttime.timeStart": "Hora de inicio",
  "ccm.sysparam.config.search": "consultando",
  "ccm.certificate.management.certificatecrlfile.suffixerror": "El archivo de lista de revocación de certificados debe estar en formato .crl.",
  "ccm.agent.ten.createDate": "Tiempo de creación",
  "ccm.custdata.button.abandon": "Desechar",
  "ccm.label.contactexport.parainvalid": "Error al verificar las condiciones de exportación.",
  "ccm.agent.restrecord.reststatus.resting": "Descanso",
  "ccm.note.config.agent.page.ring.choose": "Por favor seleccione un tono de llamada",
  "ccm.agent.contact.playfail": "Error en la reproducción.",
  "ccm.transtask.label.start": "Iniciar",
  "ccm.agent.contact.sameContactRecord": "Mismo registro de contacto de llamada",
  "ccm.agent.calledroute.nmsdata.error": "Error al sincronizar los datos con el NMS. Los datos de configuración de la parte llamada del NMS no son coherentes  con los datos locales del CCM.",
  "ccm.agent.sysparamconfig.worknonumwarn": "El número de ID de agente no puede exceder de 10.",
  "ccm.agent.operation.LISTEN": "Comprobación de calidad de escucha",
  "ccm.speciallevel.speciallist.remark": "Comentario",
  "ccm.speciallist.msg.willdelete": "¿Está seguro de que desea eliminar estos {limit} elementos?",
  "ccm.common.msg.willdelete": "¿Está seguro de que desea eliminar los {limit} registros seleccionados?",
  "ccm.calledconfig.title.numbersadded": "Números añadidos",
  "ccm.ivr.label.processdesc": "Descripción del proceso",
  "ccm.custdata.operlog.failed": "Error",
  "ccm.agent.message.willClearAgents": "¿Está seguro de que desea borrar los agentes seleccionados?",
  "ccm.pageurls.select.urlParameter": "URL OBTENER SOLICITUD Parámetro",
  "ccm.note.config.error": "Error",
  "ccm.sysparam.error.validate.notmatchruleregex": "El parámetro de entrada no coincide con la expresión regular de la regla de verificación",
  "ccm.agent.operation.mediaswitch": "Interruptor de audio/vídeo",
  "ccm.contact.customer.config.fonts": "Tipo de letra",
  "ccm.sysparam.config.agent.title": "Parámetros del agente",
  "ccm.accesscode.placeholder.accessdesc": "Por favor, introduzca una descripción del código de acceso.",
  "ccm.agent.ten.expiryDate": "Fecha de caducidad",
  "ccm.agent.sysparamconfig.refreshAllSysParamFail": "Error del sistema, error de actualización.",
  "ccm.transserverparam.label.ak": "AK",
  "ccm.agent.softPhoneStatusNode1": "Nodo 1",
  "ccm.agent.softPhoneStatusNode2": "Nodo 2",
  "ccm.speciallevel.speciallist.restrictMode": "Modo de restricción",
  "ccm.agent.contact.nohaveevent": "No hay tal evento",
  "ccm.agent.label.authType": "Modo de autenticación",
  "ccm.speciallist.msg.modify": "Modificar",
  "ccm.common.label.no": "No",
  "ccm.satisfactionconfig.message.sms": "SMS",
  "ccm.taskrecord.message.reexecutetranstasksuccess": "Re-ejecución exitosa.",
  "ccm.agent.leavemessage.processfail": "Error al procesar el mensaje.",
  "ccm.agent.button.unregister": "Anular el registro",
  "ccm.agent.contact.talkCallbackFlag": "Bandera que habla",
  "ccm.agent.contact.begintime": "Hora de inicio",
  "ccm.agent.calledroute.createsuccess": "Se ha añadido correctamente.",
  "ccm.agent.button.cancelconfig": "Cancelar configuración",
  "ccm.agent.pageurls.errorupdate": "Error al actualizar.",
  "ccm.custdata.msg.operationFailed": "Error en la operación.",
  "ccm.satisfactionconfig.message.smstemplate": "SMS Template",
  "ccm.agent.restReason.createReason": "Añadir Motivo de descanso",
  "ccm.urlconfig.message.modifyfail": "Error al modificar las urls",
  "ccm.urlconfig.message.overlimit.appKey": "La clave de la aplicación en la configuración de llamada bidireccional ITA no puede exceder los 128 caracteres.",
  "ccm.agent.leavemessage.assignSuccess": "El mensaje se asigna correctamente",
  "ccm.agent.contact.businesshungup": "Traslado al Agente",
  "ccm.agent.label.param.index": "No",
  "ccm.callout.message.isconfreset": "¿Está seguro de que desea restablecer la llamada?",
  "ccm.verifyidentity.fail.VERIFYRESULTPARSEERROR": "El parámetro del resultado de la verificación es incorrecto",
  "ccm.agent.calledroute.create.skill.none": "La cola de habilidades a la que hace referencia la configuración de la parte llamada no existe.",
  "ccm.agent.button.create": "Nuevo",
  "ccm.transtask.label.certTip.cssCert": "Seleccione un certificado CSS.",
  "ccm.agent.label.keepVoiceSelect": "Por favor, seleccione un tono de mantenimiento",
  "ccm.transtask.label.wednesday": "Miércoles",
  "ccm.agent.message.pageLayout": "Diseño de página",
  "ccm.agent.message.name.limit": "El nombre es demasiado largo.",
  "ccm.agent.message.name.grouplimit": "El nombre no puede exceder 64.",
  "ccm.certificate.management.certificatefile.certfile.sizeerror": "El tamaño del archivo de certificado no puede superar los 20 KB.",
  "ccm.agent.message.agentCreateFail": "Error al crear el agente en CTI.",
  "ccm.agent.ten.maxCalls": "Máx. llamadas de voz simultáneas",
  "ccm.agent.contact.whatsapp": "WhatsApp",
  "ccm.pageconfig.label.layoutType": "Tipo de diseño",
  "ccm.agent.contact.agentPresent": "Número del agente",
  "ccm.calledconfig.message.duplicatecalled": "Duplicado llamado",
  "ccm.label.chatRecord.channeltypeinnull": "Seleccione los tipos de canal.",
  "ccm.satisfactionconfig.satisfaction.process": "Flujo de la encuesta de satisfacción",
  "ccm.calledroute.tip.selectivrexception": "Error al consultar los flujos de IVR en CTI.",
  "ccm.agent.softPhoneStatus.UnRegistered": "No registrado",
  "ccm.agent.accesscode.description.notempty": "La descripción del código de acceso no puede estar vacía.",
  "ccm.agent.contact.sms": "SMS",
  "ccm.agent.contact.Instagram": "Instagram",
  "ccm.agent.contact.Telegram": "Telegram",
  "ccm.transtask.label.chatRecord": "Datos de chat multimedia",
  "ccm.transtask.label.intelligentInspection": "Datos de inspección de IA",
  "ccm.satisfactionconfig.message.acceptdate": "Fecha de manejo",
  "ccm.urlconfig.modify.authtype2": "OAuth 2.0",
  "ccm.agent.pageurlparam.relSysParamName": "Parámetro de datos del sistema asociado",
  "ccm.urlconfig.modify.authtype3": "Ninguno",
  "ccm.urlconfig.modify.authtype1": "Clave compartida",
  "ccm.agent.message.agentnummaxoperation": "Selecciona hasta 100 agentes.",
  "ccm.agent.label.basicInfo": "Información básica",
  "ccm.speciallist.msg.datecompareerror": "El tiempo de caducidad debe ser posterior al tiempo efectivo.",
  "ccm.transtask.label.task.chatrecord.uniqueness": "Existen tareas para algunos tipos de canales seleccionados.",
  "ccm.basicdata.msg.deletefail": "Error al eliminar los datos básicos.",
  "ccm.satisfactionconfig.audio.and.video.remark": "Cuando los conmutadores de encuesta de satisfacción de voz y SMS de IVR están activados y el agente cuelga primero, no se realizará ninguna encuesta de satisfacción de SMS si se ha realizado una encuesta de satisfacción de voz de IVR",
  "ccm.sysparam.config.value": "Valor del parámetro",
  "ccm.agent.contact.voiceVidio": "Audio/Video",
  "ccm.custdata.option.maskStyle.maskDate": "Máscara de fecha",
  "ccm.recognition.label.appSecret.download": "descargar SK",
  "ccm.agent.operation.REST": "Solicitud de Descanso",
  "ccm.calledconfig.message.savemobileagentfail": "Error al guardar la asociación de la configuración del agente móvil",
  "ccm.certificate.management.certificatefile.uploadcertfile": "Cargar archivo de certificado",
  "ccm.contact.customer.config.label": "Contacto Área de información al cliente",
  "ccm.verifyidentity.verifyResult": "Verificar resultado",
  "ccm.urlconfig.message.modifysuccess": "Éxito al modificar las urls",
  "ccm.callreason.label.mark": "Comentarios",
  "ccm.transtask.label.datapath.tips": "Ruta OBS",
  "ccm.custdata.msg.importDataSuccess": "Exportando datos...Ver detalles en Resultado de importación.",
  "ccm.agent.label.keepVoiceDefault": "Tono de mantenimiento predeterminado",
  "ccm.agent.label.selectSkill": "Seleccionar cola de habilidades",
  "ccm.transserver.label.cssServerPort": "Puerto de servicio CSS",
  "ccm.agent.label.keepVoiceConfig": "Mantener y esperar la configuración",
  "ccm.urlconfig.label.noramalOrITA.input": "Configuración de parámetros ITA",
  "ccm.transtask.label.agentOperationReport": "Informe de operación de conexión de agente",
  "ccm.agent.contact.createbother": "Crear registro de ambos",
  "ccm.custdata.option.columnType.dictionary": "Diccionario",
  "ccmanagement.satisfaction.strategy.accepttime.minute": "minute",
  "ccm.agent.calledroute.search.devicedesc": "Introduzca una descripción del dispositivo.",
  "ccm.agent.pageurls.isconfdelete": "¿Está seguro de que desea eliminarlo?",
  "ccm.agent.contact.updatedate": "Fecha de actualización",
  "ccm.agent.log.downloadVideoTerminal": "Descargue el programa de video OpenEye",
  "ccm.contact.detail.sendmessage": "Envíanos",
  "ccm.agent.skill.nameInfo": "Nombre de la cola de habilidades:",
  "ccm.agent.Transfer.CallForwarding": "Llamada de transferencia",
  "ccm.satisfactionconfig.message.strategygroupmemconfig": "Configuración de la política",
  "ccm.pageurls.select.postParameter": "URL POST REQUEST Parámetro",
  "ccm.agent.message.modifySkillConfFail": "Error al modificar la información de la cola de habilidades.",
  "ccm.agent.calledroute.gateway.fail": "Error al sincronizar la información de la plataforma CTI. Compruebe la configuración de la puerta de enlace.",
  "ccm.urlconfig.label.oauth.label": "Información de autorización de OAuth 2.0",
  "ccm.agent.restrecord.rest.endTime": "Hora de finalización del descanso",
  "ccm.pageinterface.optionalAttribute": "Atributo opcional",
  "ccm.contact.customer.config.datasource.customData": "Datos locales",
  "ccmanagement.satisfaction.strategy.deleteselect": "¿Está seguro de que desea eliminar las políticas seleccionadas?",
  "ccm.agent.contact.callerfailed": "Error de llamada saliente",
  "ccm.agent.resetskillrecord.skillafteradjust": "Cola de habilidades después del ajuste",
  "ccm.agent.message.selectSkills": "Seleccione una cola de habilidades.",
  "ccm.sysparam.config.resetAbb": "Restablecer",
  "ccm.agent.label.airecog": "Reconocimiento inteligente",
  "ccm.certificate.management.certificatefile.suffixerror": "Solo se pueden cargar archivos en formato jks,cer,crt,pem,pfx,p12.",
  "ccm.agent.message.inputWeight": "Introduzca un valor de peso.",
  "ccm.agent.leavemessage.assignFailed": "Error al asignar el mensaje.",
  "ccm.agent.restReason.selectRestReasons.limit": "Un máximo de 10 motivos de descanso se pueden eliminar en lotes.",
  "ccmanagement.satisfaction.strategy.deletefail": "Error en la eliminación.",
  "ccm.vcallcenter.config.popup.authentication.failed": "Error en la autenticación.",
  "ccm.satisfactionconfig.message.smsfeedbacktype": "SMS",
  "ccm.agent.contact.voiceBot": "Voz",
  "ccm.custdata.msg.msgValidateFailed": "No se permiten caracteres especiales.",
  "ccm.agent.label.deleteAgentFailed": "Una cola de habilidades se ha vinculado a una cuenta de empresa. Elimine primero la configuración de la cola de habilidades del agente.",
  "ccm.transtask.label.discard": "Desechar",
  "ccm.agent.Transfer.deviceDesc": "Dispositivo de destino",
  "ccm.basicdata.error.capacitylimit": "La capacidad de datos ha alcanzado el límite superior {0}",
  "ccm.dual.call.record.callCompletionRate": "Tasa de finalización de llamadas",
  "ccm.speciallist.button.import": "Importación",
  "ccm.speciallist.title.import": "Importar lista especial",
  "ccm.agent.ten.ttschannelnums": "TTS Cantidad",
  "ccm.nms.satisfactionservice.buttonNumber": "El número de claves de la encuesta de satisfacción no es superior a 5 y no inferior a 3.",
  "ccm.recognition.label.modifymobileagentclose": "¡El agente móvil y las funciones de llamada bidireccional con un solo clic no están habilitadas para el espacio del inquilino!",
  "ccm.contact.customer.config.style": "Estilo de visualización",
  "ccm.dual.call.record.unexpected.end": "Fin inesperado",
  "ccm.agent.contact.playdefault": "La duración de la grabación está vacía. ¿Desea utilizar la duración predeterminada de 60 minutos?",
  "ccm.satisfactionconfig.sms.switch.remark": "Después de activar este interruptor, se enviará un mensaje SMS de encuesta de satisfacción al cliente después de que finalice la llamada",
  "ccm.satisfactionconfig.satisfaction.sending.channel": "Canal de envío de SMS",
  "ccm.agent.message.refreshSkillException": "Refrescar la excepción de habilidad.",
  "ccm.recognition.label.policy.information": "InformaciÃ3n de la politica",
  "ccm.recognition.label.editPolicy.information": "Editar información de directiva",
  "ccm.agent.leavemessage.status.toBeAssigned": "Se asignará",
  "ccm.agent.leavemessage.status.toBeProcessed": "Para ser procesado",
  "ccm.speciallist.msg.applyTime": "Tiempo de aplicación",
  "ccm.agent.contact.callershutdown": "Fin de la llamada, apagado de la persona que llama",
  "ccm.satisfactionlevel.message.satistip": "Asegurar que los niveles de satisfacción utilizados en la encuesta de satisfacción de cada canal sean los mismos. Como la encuesta de satisfacción por voz, la encuesta de satisfacción por SMS y la encuesta de satisfacción web.",
  "ccm.urlconfig.label.noramalOrITA": "Seleccione la llamada bidireccional CEC o el tipo de llamada bidireccional ITA.",
  "ccm.anonymous.message.fail.close": "Error al cerrar el indicador. comprobar la configuración de CTI",
  "ccm.taskrecord.label.executeBeginTime": "Hora de inicio",
  "ccm.agent.label.usedMode.sipAccount": "Cuenta Sip",
  "ccm.callback.url.callnotify": "Configuración de devolución de llamada de notificación de voz",
  "ccm.callreason.label.allreason": "Todas las razones",
  "ccmanagement.satisfactionconfig.acceptdate.timeerror.laterThanEndTime": "La fecha de inicio debe ser anterior a la fecha de finalización.",
  "ccm.agent.ten.mediaagents": "Número de agentes multimedia",
  "ccm.agent.contact.callernook": "Llamador saliente sin respuesta",
  "ccm.satisfactionconfig.message.nofeedbacktype": "Sin encuesta",
  "ccm.contact.customer.config.datasource.callData": "Datos asociados a la llamada",
  "ccm.custdata.button.detail": "Detalles",
  "ccm.speciallist.msg.confirmdelete": "¿Está seguro de que desea eliminar la lista especial seleccionada?",
  "ccm.label.contactexport.currentfield": "Campo en la lista actual",
  "ccmanagement.tenantspace.option.month_12": "Diciembre",
  "ccmanagement.tenantspace.option.month_11": "Noviembre",
  "ccmanagement.tenantspace.option.month_10": "Octubre",
  "ccm.agent.ten.tenantId": "ID del espacio del inquilino",
  "ccmanagement.satisfaction.strategy.saveerror": "Failure",
  "ccm.custdata.msg.inputTableName": "Introduzca un nombre de tabla.",
  "ccm.agent.label.ifBusyNoAnswerAgent": "Establecer el estado ocupado de los agentes que no responden durante un largo período",
  "ccm.agent.label.asyncUserDispatchMode": "Condición de recuperación de mensajes sin conexión",
  "ccm.agent.label.asyncUserDispatchMode.zero": "por recuento de lotes",
  "ccm.agent.label.asyncUserDispatchMode.one": "por recuento de lotes y habilidad",
  "ccm.agent.label.asyncUserDispatchMode.two": "por recuento de lotes y estado de conexión",
  "ccm.agent.label.asyncUserDispatchMode.three": "por recuento de lotes, habilidad y estado en línea",
  "ccm.agent.label.asyncUserDispatchMode.four": "por recuento de lotes, agente, habilidad y estado en línea",
  "ccm.transserverparam.label.password": "Contraseña",
  "ccm.agent.videoDownload.disabled": "La función de descarga de vídeo está desactivada",
  "ccm.common.label.yes": "Sí",
  "ccm.basicdata.msg.deletesuccess": "Los datos básicos se eliminan correctamente.",
  "ccm.callreason.label.status": "Estado",
  "ccm.contact.customer.config.writeBack": "Escribir de nuevo registro de contacto",
  "ccm.case.title.selectWorkOrder": "Seleccione una categoría de orden de trabajo",
  "ccm.agent.label.ccRoleId": "Función de plataforma",
  "ccm.agent.title.skillModify": "Modificar cola de habilidades",
  "ccm.pageinterface.pleaseSelectInterfaceType": "Seleccione un tipo de interfaz.",
  "ccm.speciallist.msg.withoutauth": "No tiene el permiso de operación.",
  "ccm.agent.label.ifReleaseNoAnswerCall": "Liberar llamadas no contestadas durante un largo período",
  "ccm.speciallist.msg.fail": "Error",
  "ccm.agent.pageurl.httptip": "(No es seguro)",
  "ccm.certificate.management.update.cancel.crlfile": "¿Está seguro de que desea cancelar la actualización del archivo crl?",
  "ccm.agent.label.withinSevenDays": "En el plazo de 7 días",
  "ccm.speciallist.label.operator": "Operador",
  "ccm.agent.label.waitTime": "Tiempo de espera (s)",
  "ccm.agent.contact.userhungup": "Usuario",
  "ccm.agent.label.ivrChoice": "Seleccionar IVR",
  "ccm.agent.message.noReleaseFail": "Solo se pueden eliminar los datos no publicados.",
  "ccm.calledconfig.label.add": "Añadir",
  "ccm.sysparam.error.validate.notmatchrule": "El parámetro de entrada no coincide con la regla de verificación",
  "ccm.custdata.msg.missRequiredColumn": "Una tabla personalizada sólo se puede liberar cuando contiene al menos un campo de índice y otros campos de información.",
  "ccm.sysparam.error.validate.watermarkinfo.notmatchruleregex": "El parámetro solo permite de 0 a 20 caracteres ingleses que contengan letras mayúsculas y minúsculas, números o -_/@.",
  "ccm.agent.operation.RELEASE": "Lanzamiento",
  "ccm.systemparam.error.save": "Error al guardar los parámetros.",
  "ccm.agent.ten.timezoneOffset": "Desfase de zona horaria",
  "ccm.dual.call.record.called": "Número Llamado",
  "ccm.agent.addbother.success": "Se logró crear el registro de molestia.",
  "ccm.transserver.label.obsaddr": "Dirección OBS",
  "ccm.callreasonconfig.message.deletecallreason.success": "Se ha eliminado correctamente.",
  "ccm.agent.operation.CALLINNER": "Llamada interna",
  "ccmanagement.tenantspace.option.weeklist_tidtolast": "Últimos pero dos",
  "ccm.agent.pageurlparam.paramLengthLimit": "La longitud del parámetro no puede exceder los 64 caracteres",
  "ccm.agent.contact.multimedia.wechat": "Multimedia-WeChat",
  "ccm.recognition.label.orderfail": "Error en la suscripción.",
  "ccm.transtask.label.savefail": "Error al agregar un servidor de volcado porque se permite un maximo de servidores de volcado de {0}.",
  "ccm.custdata.label.valueRange": "Rango de valores",
  "ccm.agent.operation.ADJUSTSKILL": "Ajustar la cola de habilidades",
  "ccm.agent.leavemessage.view": "Ver mensajes",
  "ccm.speciallist.msg.norecord": "No se puede exportar ningún registro",
  "ccm.urlconfig.message.addsuccess": "Éxito al agregar las urls",
  "ccm.recognition.label.appSecret.download.SK.io.error": "Error al descargar el SK. Excepción de E/S.",
  "ccm.contactitemconfig.label.contact.recorddata": "Elemento de datos de registro de contacto",
  "ccm.speciallist.msg.error": "Errores",
  "ccm.agent.pageurls.config": "Configuración",
  "ccm.custdata.button.moveBottom": "Fondo",
  "ccm.custdata.msg.deletedItemNotExist": "El elemento de eliminación no existe. Actualice la página e inténtelo de nuevo.",
  "ccm.agent.calledroute.create.accesscode.repeat": "La combinación del código de acceso al sistema y el código de extensión para la parte llamada debe ser única.",
  "ccm.custdata.option.isMaskDisplay.no": "No",
  "ccm.transtask.label.taskupload": "Servidor de carga",
  "ccm.transtask.label.expirationDuration": "Período de retención de archivos de volcado",
  "ccm.agent.calledroute.accesscode": "Código de acceso",
  "ccm.transtask.label.View": "Vistas",
  "ccm.dual.call.record.startTime": "Hora de inicio de llamada",
  "ccm.contact.tip.no.workname": "No se obtuvo el agente correspondiente",
  "ccm.ucconfig.message.userId": "ID del directorio (inquilino)",
  "ccm.speciallist.msg.transferout": "El número de registros históricos ha alcanzado {0}",
  "ccm.satisfactionconfig.message.channelname": "Canales",
  "ccm.urlconfig.label.input.appSecret": "SecretKey",
  "ccm.agent.updateSoftPhone.reason": "La razón",
  "ccm.agent.contact.download.frequently": "Usted intentó descargarlo varias veces. Por favor, inténtelo de nuevo más tarde.",
  "ccm.recognition.label.modifymobileagentfailed": "Error al modificar el estado del agente móvil.!",
  "ccmanagement.satisfaction.strategy.fail.calltime.overlimit": "Se puede guardar un máximo de 100 políticas de duración de llamada.",
  "ccm.agent.contact.hungup": "Fiesta para colgar",
  "ccm.agent.label.skillId": "ID de cola de habilidades",
  "ccm.note.config.save": "Guardar",
  "ccm.agent.pageurlparam.checkSpecialChar": "Introduzca dígitos, letras, guiones bajos (_), y guiones (-). El punto (.) debe estar en el medio.",
  "ccm.agent.pageurlparam.checkSpecialChar.noPoint": "Introduzca dígitos, letras, guiones bajos (_), y guiones (-). El punto (.) debe estar en el medio",
  "ccm.agent.pageurlparam.checkSpecialChar.name": "Introducir dígitos, letras, guiones bajos (_), y guiones (-)",
  "ccm.agent.contact.otherhungup": "Otros",
  "ccm.sysparam.error.validate.ruleregexinvalid": "Error de sintaxis de regla de validación",
  "ccm.agent.contact.multimedia.line": "Multimedia-Line",
  "ccm.speciallist.button.history": "Transferir a la Historia",
  "ccm.agent.pageurls.confonlynaming": ". Asegúrese de que el nombre es único.",
  "ccm.agent.ten.email": "Correo Electrónico",
  "ccm.agent.message.description.limit": "La descripción de la cola de habilidades es demasiado larga",
  "ccm.page.select.blockLayout": "Diseño de bloque",
  "ccm.agent.sysparamconfig.resetAllParamsFail": "Falta el archivo de configuración de parámetros. Por favor, compruébelo.",
  "ccm.agent.accesscode.description.require": "La descripción del código de acceso no puede estar vacía.",
  "ccm.sysparam.url.unsafe": "(inseguro)",
  "ccm.agent.sysparamconfig.checkWebhookValidityDays": "La certificación Webhook debe ser válida entre 7 y 365 días",
  "ccm.asr.create.case": "Crear caso",
  "ccm.transserver.delete.css.fail": "Error al eliminar la información de configuración de CSS.",
  "ccm.speciallist.msg.besureexport": "¿Está seguro de que desea exportar los datos?",
  "ccm.speciallevel.msg.selectspecialLevel": "Seleccione un nivel especial",
  "ccm.agent.label.skillName": "Nombre de Habilidad",
  "ccm.agent.contact.name": "Nombre del documento",
  "ccm.agent.contact.searchName": "Nombre del documento",
  "ccm.agent.agentcontact.error": "Error de consulta",
  "ccm.agent.label.positiveEmotion": "Buen trabajo, sigue así.",
  "ccm.agent.restReason.edit": "Editar Motivo de descanso",
  "ccm.urlconfig.message.missing.appIdOrAppSecret": "La clave de acceso o clave secreta no se establecen en la configuración de llamada bidireccional ITA.",
  "ccm.urlconfig.label.oauth.oauth2loginurl.illegal": "URL de inicio de sesión no válida.",
  "ccm.satisfactionconfig.message.memberstrategychoose": "Políticas disponibles",
  "ccm.worknobeforevoice.config.choosenote": "Seleccione la voz antes de la identificación del empleado",
  "ccm.agent.label.deleteCtiFailed": "Error en la eliminación de datos de la plataforma Cti.",
  "ccm.agent.button.restSoftPhonePwd": "Restablecer contraseña de Softphone",
  "ccm.agent.message.export": "Exportar",
  "ccm.agent.message.export.skill": "Exportar cola de habilidades",
  "ccm.agent.info.message.export": "Exportación de información de agente",
  "ccm.verifyidentity.noMoreThan30Days": "El rango de tiempo de la consulta no puede exceder los 30 días.",
  "ccm.speciallist.msg.download": "Descargar la plantilla de importación de lista especial",
  "ccm.speciallist.msg.import.template": "Plantilla de importación de lista especial",
  "ccm.agent.button.config": "Configurar",
  "ccm.custdata.operlog.displayMask": "Máscara de visualización",
  "ccm.agent.message.agentnotexist": "El agente no existe. Actualice la página Administración de agentes e inténtelo de nuevo.",
  "ccm.certificate.management.search.scenename": "Ingrese la escena del certificado",
  "ccm.agent.message.createSkillConfFail": "Error al crear la información de la cola de habilidades.",
  "ccm.satisfactionconfig.message.audiochannel": "Canal de voz o vídeo",
  "ccm.agent.leavemessage.auditAccount": "Cuenta de auditoría",
  "ccm.satisfactionconfig.message.calltypeout": "Llamada saliente",
  "ccm.pageinterface.interfaceType": "Tipo de interfaz",
  "ccm.agent.contact.ivrhungup": "Reenviar a IVR",
  "ccmanagement.tenantspace.option.weekday_thu": "Jueves",
  "ccm.agent.operation.CANCELWHISPER": "Cancelar Ruido",
  "ccm.contact.customer.config.datasource.objId": "Objeto de origen de datos",
  "ccm.recognition.label.intellrecogn": "Reconocimiento inteligente",
  "ccm.agent.label.negativeEmotion": "Una redacción inadecuada. Utilice un script apropiado.",
  "ccm.verifyidentity.fail.INPUTNULLERROR": "El parámetro de consulta no puede estar vacío",
  "ccm.sysparam.error.validate.notmatchrulealpha": "Ingrese alfanuméricos o guiones bajos y comience solo con letras o guiones bajos",
  "ccm.urlconfig.label.radio.radioType2": "Llamada bidireccional ITA",
  "ccm.agent.label.CommomAgent": "Agente común",
  "ccm.callout.lable.reset": "Restablecer",
  "ccm.agent.label.timeRemaining": "Caducidad de la contraseña (día)",
  "ccm.transtask.label.week": "Semana",
  "ccm.speciallist.msg.maxCountQuery": "El volumen de datos actual es demasiado grande. El sistema muestra sólo los últimos {0} registros de datos",
  "ccm.agent.contact.downloadvideo": "Descargar vídeo",
  "ccm.agent.pageurls.delete": "Borrar",
  "ccm.speciallist.msg.operationsuccess": "Operación exitosa",
  "ccm.urlconfig.label.radio.radioType1": "Llamada bidireccional de la CCA",
  "ccm.recognition.label.characteristics": "Características",
  "ccm.satisfactionconfig.message.updatesuccess": "Se ha actualizado correctamente la configuración de la encuesta de satisfacción",
  "ccm.botherlist.label.approveStatus.rejected": "Rechazado",
  "ccm.urlconfig.modify.oauth.sk.tooshort": "El SK debe contener al menos 16 caracteres.",
  "ccm.certificate.management.certificatefile.suffixerror.suffix": "o extensión vacía.",
  "ccm.pageinterface.objectName": "Nombre del objeto",
  "ccm.agent.sysparamconfig.refreshSuccess": "Se ha realizado el restablecimiento con un solo clic.",
  "ccm.urlconfig.label.talkurl": "Hablar Url",
  "ccm.agent.label.fixedLine": "Número de línea fija/móvil",
  "ccm.agent.operation.ADJUSTVIDEOMODE": "Ajuste del tamaño de la ventana de vídeo",
  "ccm.calledconfig.message.samename": "El mismo nombre ya existe.",
  "ccm.satisfactionconfig.message.satis": "Encuesta de satisfacción",
  "ccm.agent.pageurls.independentlynosupported": "La configuración interna de la página no se admite para la apertura independiente.",
  "ccm.querycustdata.msg.noData": "Sin datos",
  "ccm.agent.message.airecognull": "El indicador de reconocimiento inteligente está vacío.",
  "ccm.note.config.policy.systemdefault": "Predeterminado del sistema",
  "ccm.custdata.label.sucNum": "Número de registros exitosos",
  "ccm.transtask.label.cantsave": "No se puede modificar el servidor porque una tarea de volcado hace referencia a él.",
  "ccm.agent.contact.noAuth": "No hay permiso.",
  "ccm.agent.contact.download": "Descargar",
  "ccm.agent.leavemessage.serviceContactNumber": "Número de contacto",
  "ccm.agent.label.usedMode.phone": "Número de Teléfono",
  "ccm.agent.message.addSkillFail": "Error al agregar habilidad.",
  "ccm.agent.message.addSkillSuccess": "Cola de habilidades agregada correctamente.",
  "ccm.agent.message.maxSkillFail": "La directiva de cola de habilidades puede contener hasta 100 elementos.",
  "ccm.agent.ten.tenantInfor": "Información del tenant",
  "ccm.agent.restReason.confOnlyCauseCode": "Error en la operación. El código de motivo ya existe.",
  "ccm.agent.message.skillConfSuccess": "La información de la cola de habilidades se configura correctamente.",
  "ccm.taskrecord.label.executeSuccess": "Exitoso",
  "ccm.speciallist.msg.crateerror": "Error al agregar",
  "ccm.urlconfig.label.relurl.callback.alerttip": "Si no se transfiere ninguna URL de devolución de llamada cuando se invoca la API de creación de llamada bidireccional, esta URL se llama de nuevo al liberar la llamada bidireccional. De lo contrario, se devuelve la llamada a la URL transferida.",
  "ccm.agent.contact.recordtime": "Duración de la grabación (s)",
  "ccm.transtask.label.skillReport": "Reporte de tráfico de cola de espera de llamadas",
  "ccm.custdata.operlog.addExportTask": "Nueva tarea de exportación",
  "ccm.transtask.label.skillSummaryReport": "Reporte de resumen de tráfico",
  "ccm.agent.contact.QUERU_VCCINFO_FAIL_CODE": "La información del centro de llamadas virtual está vacía.",
  "ccm.label.contactexport.selectall": "Todo",
  "ccm.speciallist.label.redlist": "Lista roja",
  "ccm.agent.pageurls.success": "Exitoso",
  "ccm.transserver.label.obs": "Servidor OBS",
  "ccm.agent.label.queuingCallsMaxNumRange": "Rango de valores: [0-10000]",
  "ccm.note.config.policy.custom": "Personalización",
  "ccm.transtask.label.agentReport": "Informe de resumen de rendimiento del agente",
  "ccm.vcallcenter.config.popup.currentaccount.pwd": "Contraseña de cuenta actual",
  "ccm.transtask.label.contactrcddata": "Campos en la lista de registros de contactos",
  "ccm.agent.accesscode.description": "Descripción del código de acceso",
  "ccm.idauth.title.idauthconfig": "Configuración del proceso de autenticación",
  "ccm.agent.label.callDispatchModel.best": "Óptima",
  "ccm.agent.label.restQueueRule.applyFirst": "Agente que solicita un descanso primero",
  "ccm.custdata.msg.duplicateColumnName": "Nombre de campo duplicado. Cambia el nombre.",
  "ccm.contactitemconfig.label.data.extended.Type.DataDictionary": "Diccionario de datos",
  "ccm.agent.calledroute.create": "Nuevo",
  "ccm.agent.operation.searchcountid": "Cuenta de servicio",
  "ccm.callreason.label.disable": "Desactivar",
  "ccm.transserver.label.objobs": "OBS",
  "ccm.urlconfig.label.appsecret": "Clave compartida",
  "ccm.urlconfig.label.key": "Clave secreta",
  "ccm.agent.resetskillrecord.adjustbusinessaccount": "Ajustar cuenta de servicio",
  "ccm.transtask.label.save": "OK",
  "ccm.callout.message.resetfail": "Error al restablecer los parámetros.",
  "ccm.speciallist.msg.successNum": "{limit} éxito",
  "ccm.agent.message.pageNumFail": "Se permite un máximo de {0} páginas.",
  "ccm.agent.recall.agent.status.invalid": "El agente no está en estado inactivo o ocupado y no puede devolver la llamada.",
  "ccm.callback.url.callback": "Configuración de llamada de retorno de llamada bidireccional",
  "ccm.transtask.label.taskid": "Id de tarea",
  "ccm.contact.customer.config.dataItemError": "El nombre o ID del elemento de datos debe ser único.",
  "ccm.agent.button.batchmodify": "Modificación por lotes",
  "ccm.custdata.operlog.importdata": "Importar datos",
  "ccm.urlconfig.label.input.appId": "AccessKey",
  "ccm.agent.button.restores": "Restauraciones",
  "ccm.agent.label.skillWeightTip": "Cuando un agente entra en varias colas, las llamadas en la cola de habilidades con el peso más alto se procesan primero.",
  "ccm.agent.restReason.createFailed": "Error al agregar.",
  "ccm.urlconfig.message.overlimit.domainName": "El nombre de dominio en la configuración de llamada bidireccional ITA no puede exceder los 128 caracteres.",
  "ccm.agent.label.maxTalkTime": "Duración máxima de la llamada (s)",
  "ccm.custdata.option.maskStyle.retainFirst4": "Reserva los primeros cuatro dígitos",
  "ccm.transserverparam.label.isagent": "Habilitar proxy",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputlength": "La duración de la hora de inicio o finalización no puede exceder el límite superior.",
  "ccm.agent.tips.confSuccess": "Configuración exitosa.",
  "ccm.agent.tips.noagent": "No existe ningún agente en el segmento de número seleccionado.",
  "ccm.speciallist.label.operTime": "Tiempo de creación",
  "ccm.agent.operation.noMoreThan30Days": "El intervalo de tiempo no puede exceder los 30 días.",
  "ccm.agent.contact.fiveg": "5G RCS",
  "ccm.sysparam.config.reset.multiple.error": "No se pueden restablecer los siguientes parámetros:",
  "ccm.agent.message.quotingDataOperationFail": "La pantalla emergente hace referencia a los datos. Error en la operación.",
  "ccm.agent.contact.refreshAll": "Refrescar",
  "ccm.agent.pageurls.fail": "Error",
  "ccm.custdata.operlog.download": "Descargar",
  "ccm.recognition.label.cancelorderfailpd": "Error al cancelar la suscripción. Inténtalo de nuevo más tarde.",
  "ccmanagement.satisfaction.strategy.accepttime.hour": "hour",
  "ccm.transserver.label.cloudsearchserver.css": "Configuración de CSS",
  "ccm.agent.message.queryCallCenterFail": "Error al centro de llamadas de consulta.",
  "ccm.speciallevel.speciallist.levelName": "Nombre de nivel",
  "ccm.agent.calledroute.createfail": "Error al agregar datos locales.",
  "ccm.speciallist.tip.timeVerification": "Seleccione una hora posterior a la hora actual y anterior a 2038-01-19.",
  "ccm.sysparam.error.validate.ruleinvalid": "La regla de verificación no es válida",
  "ccm.sysparam.error.validate.ccme.alarmThreshold": "La gravedad de la alarma es inconsistente con el umbral de alarma. El umbral de alarma de baja gravedad debe ser menor que (igual a) el umbral de alarma de alta gravedad",
  "ccm.sysparam.error.validate.ccme.daysAlarmOverSync": "El parámetro de entrada debe ser menor que el tiempo configurado (7 días de forma predeterminada) para migrar usuarios sin conexión a la tabla de historial",
  "ccm.agent.message.nulagentincti": "El agente seleccionado actualmente no se crea en CTI,Necesita seleccionar la información de rol.",
  "ccm.agent.contact.satiscomment": "Evaluación de satisfacción",
  "ccm.note.config.agent.page.windowTip": "Recordatorio de cuadro de diálogo",
  "ccm.agent.label.maxAgentWrapupTime": "Valor [0 – 3600]",
  "ccmanagement.agent.batchmodify.agentlis": "Por segmento",
  "ccm.agent.message.deletefailed": "Error al eliminar el agente",
  "ccm.sysparam.config.select.prompt": "Consejos",
  "ccm.agent.contact.Voice": "Voz",
  "ccm.agent.accesscode.accesscode": "Código de acceso",
  "ccm.custdata.msg.fileSuffixValidateFail": "Formato de archivo incorrecto. Seleccione un archivo CSV.",
  "ccm.agent.message.accRepeatFail": "El parámetro Cuenta es duplicado.",
  "ccm.dual.call.record.agentTalkingTime": "Tiempo de respuesta de la parte que llama",
  "ccm.transtask.delete.confirm": "¿Está seguro de que desea eliminar la tarea de volcado y los registros historicos de tareas?",
  "ccm.sysparam.config.itemname": "Nombre del parámetro",
  "ccm.anonymous.message.fail.notmatch": "El indicador de función no coincide con el estado actual",
  "ccm.agent.label.conditionOfBusyTransfer": "Condición de transferencia de ocupado",
  "ccm.speciallist.title.specialList": "Lista Especial",
  "ccm.transtask.label.chatrecordday": "Duración del volcado de datos de chat (día)",
  "ccm.agent.label.ccRole": "Rol",
  "ccm.agent.label.withinFifteenDays": "En el plazo de 15 días",
  "ccm.agent.contact.downloadrecord": "Descargar Grabación",
  "ccm.agent.message.willcancelconfig": "Si cancela la configuración, no estará disponible para los agentes que utilicen la configuración. ¿Está seguro de que desea cancelar la configuración?",
  "ccm.contact.customer.config.bold": "En negrita",
  "ccm.botherlist.label.approveStatus.toApprove": "Pendiente de aprobación",
  "ccm.dual.call.record.reasonCode": "Motivo de lanzamiento",
  "ccm.speciallist.msg.userNo": "Número de suscriptor",
  "ccm.agent.message.selected": "Usted ha seleccionado\u0020",
  "ccm.speciallist.msg.maxlimit": "El número máximo de registros supera {limit}",
  "ccm.agent.pageurls.isenable": "Activar",
  "ccm.agent.button.createComplete": "Completo",
  "ccm.agent.pageurls.delsuccess": "Se ha eliminado correctamente.",
  "ccm.sysparam.config.refresh": "Refrescar",
  "ccm.contact.customer.config.datasourceType": "Tipo de origen de datos",
  "ccm.transtask.label.datatype": "Tipo de datos de volcado",
  "ccm.custdata.option.maskStyle.maskLast4": "Máscara últimos cuatro dígitos",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnull": "La fecha de inicio o finalización no puede estar vacía.",
  "ccm.certificate.management.certificatefile.injectionerror": "El archivo tiene riesgos de inyección.",
  "ccm.calledconfig.message.noskill": "Por favor, elija la cola de habilidades.",
  "ccm.pageconfig.label.numInRow": "Número de filas",
  "ccm.agent.label.CallOutAgent": "Agente de llamada",
  "ccm.satisfactionconfig.message.number": "Número",
  "ccm.agent.message.searchAccountName": "Ingrese una cuenta.",
  "ccm.agentparam.message.editsuccess": "Se ha editado correctamente.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputparse": "La hora de inicio o finalización tiene un formato incorrecto.",
  "ccm.calledconfig.message.selectcasetype": "Seleccione un tipo de caso.",
  "ccm.agent.calledroute.delete.work.use": "El agente hace referencia a la configuración de la parte llamada y no se puede eliminar.",
  "ccm.contactitemconfig.label.data.extended.writeback.extended.field": "Campo de escritura extendido",
  "ccm.ucconfig.message.clientId": "ID de aplicación (cliente)",
  "ccm.agent.speciallist.levelName": "Nivel de lista especial",
  "ccm.certificate.management.update.crlfile": "Actualizar archivo CRL",
  "ccm.certificate.management.certificatefile.fileempty": "El archivo está vacío.",
  "ccm.custdata.title.create": "Creación de una tabla base",
  "ccm.agent.contact.multimedia.email": "Multimedia-Correo electrónico",
  "ccm.idauth.create.error.format": "El código del modo de autenticación solo puede contener dígitos, letras y guiones bajos (_).",
  "ccmanagement.satisfaction.strategy.acceptdate.enddate": "Fecha de finalización",
  "ccm.custdata.option.columnType.number": "Número",
  "ccm.idauth.create.error.name": "El nombre del modo de autenticación ya existe.",
  "ccm.agentparam.message.editfail": "Error al editar.",
  "ccm.agent.label.mobileagentremark": "Comentarios",
  "ccm.agent.operation.INTERCEPT": "Interceptar",
  "ccm.agent.contact.faceBook": "Facebook",
  "ccmanagement.tenantspace.option.weekday_tue": "Martes",
  "ccm.agent.message.worknostartbig": "El ID de empleado inicial no puede ser mayor que el ID de empleado final.",
  "ccm.agent.label.busyRateRange": "Rango de valores: [0-100]",
  "ccm.agent.pageurls.operation": "Operación",
  "ccm.contactitemconfig.label.data.item.extended": "Datos extendidos",
  "ccm.sysparam.config.title": "Parámetros del tenant",
  "ccm.custdata.label.beginTime": "Hora de inicio",
  "ccm.custdata.msg.uploadSelect": "Seleccione un archivo",
  "ccm.agent.contact.callout": "Llamada saliente",
  "ccm.agent.contact.mediaType": "Tipo de medios",
  "ccm.botherlist.label.approveComment.reject": "Rechazar",
  "ccm.ivrvoice.play.error":"Al cargar la excepci\u00F3n de recurso\, compruebe la configuraci\u00F3n relacionada.",
  "ccm.contact.details.tab.title.audio":'Voz',
  "ccm.contact.details.tab.title.video":'Vídeos',
  "ccm.contact.placeholder.searchCustomerNo": 'Número de contacto',
  "ccm.agent.contact.transfercontent": 'Contenido de ASR',
  "ccm.agent.label.oneday": '1 Día',
  "ccm.agent.label.threedays": '3 Días',
  "ccm.agent.label.sevendays": '7 Días',
  "ccm.agent.label.thirtydays": '30 Días',
  "ccm.contactitemconfig.message.noBaseData":"No se dispone de datos básicos",
  "ccm.label.downloadAttachment.confirm":"¿Está seguro de que desea descargar el archivo adjunto seleccionado?",
  "ccm.agent.contact.info": "Información del documento",
  "ccm.contact.detail.talk.content.seachtips":"Resultado de la búsqueda: {num}",
  "ccm.contact.detail.talk.content.not.support":"El texto de búsqueda no puede contener\"/()",
  "ccm.contact.detail.talk.content.seach.remark":"Solo se puede buscar el contenido de chat de texto que se muestra en esta página",
  "ccm.contact.detail.talk.content.seach.remark.emaile":"Solo se pueden buscar los mensajes que se hayan mostrado en la página de búsqueda. Una vez completada la búsqueda\, solo se muestran los mensajes coincidentes.",
  "ccm.download.passwd.validator.specialStr": "Solo se permiten los siguientes caracteres especiales: _ {'@'} {'%'}",
  "ccm.contact.label.signed": "Firmada",
  "ccm.skillgroup.message.groupBind": "No se puede seleccionar la habilidad actual para el tipo de agente enlazado al grupo de habilidades",
  "ccm.agent.contact.preview": "Vista previa",
  "ccm.ccagent.management.default.options": "Opciones predeterminadas",
  "ccm.login.record.noMatchingLoginRecordExists": 'No hay registros de check-in que coincidan con los criterios de búsqueda',
  "ccm.agent.message.searchSkillQueueName": "Nombre de la cola de habilidades",
  "ccm.agent.button.skill.synchronize": "Sincronizar",
  "ccm.skillgroup.msg.deleteerror": "Seleccione el grupo de habilidades que desea eliminar.",
  "ccm.skillgroup.create.fail": "Error al crear el grupo de habilidades.",
  "ccm.skillgroup.update.fail": "Error al actualizar el conjunto de habilidades",
  "ccm.skillgroup.query.fail": "Error al obtener la configuración del grupo de habilidades.",
  "ccm.skillgroup.update.failtip": "Error al actualizar el grupo de habilidades. Sincroniza primero la cola de habilidades y los datos del grupo de habilidades.",
  "ccm.skillgroup.message.nameValidateFail": "El grupo de habilidades no puede contener los siguientes characters: [<>\"()]&=;,",
  "ccm.skillgroup.message.namePrefixSuffix": "El grupo de habilidades no puede comenzar o terminar con un espacio",
  "ccm.skillgroup.message.nameLimit": "El nombre del grupo de habilidades es demasiado largo",
  "ccm.skillgroup.message.nameExistence": "El nombre del grupo de habilidades ya existe",
  "ccm.agent.download.forbidden": "No hay permiso de descarga.",
  "ccm.contact.label.inactive":"Caducado",
  "ccm.contact.label.unsigned":"Sin firmar",
  "ccm.agent.contact.invalid": "Invalidación",
  "ccm.title.org.all": "Seleccione una organización",
  'ccm.title.template.select.email': 'Seleccionar plantilla de correo electrónico',
  'ccm.title.template.select.sms': 'Seleccionar plantilla SMS',
  "ccm.skillgroup.msg.deleteTips": "El grupo de habilidades actual se ha asociado con el agente {0}. Elimine la asociación y, a continuación, elimínela.",
  "ccm.skillgroup.create.limit": "Se pueden crear un máximo de 2000 grupos de habilidades.",
  "ccm.skillgroup.create.failtip": "Error al crear el grupo de habilidades. Sincronice primero la información del grupo de habilidades y habilidades.",
  "ccm.sendmsg.whatsapp.withoutTemplate": "Sin contenido. Seleccione una plantilla primero.",
  "ccm.skillgroup.msg.deleteFailed": "Error al eliminar el grupo de habilidades",
  "ccm.agent.label.skillType": "Tipo de habilidad",
  "ccm.agent.label.selectSkillGroup": "Seleccionar grupo de habilidades",
  "ccm.skill.label.defaultskill": "Cola de habilidades predeterminada",
  "ccm.agent.leavemessage.overtime":"Se agotó el tiempo de procesamiento. Por favor, abra la ventana emergente de nuevo.",
  "ccm.agent.message.samepassword": "La nueva contraseña no puede ser la misma que la contraseña actual",
  "ccm.agent.ten.allowedTTSAndASRCallNum": "Número de canales de IVR inteligentes",
  "ccm.contact.msg.dataEmpty": "Los datos de operación no existen en la AICC.",
  "ccm.contact.msg.dataverification": "Error al verificar el parámetro de solicitud.",
  "ccm.contact.msg.inactivesuccess": "La validación se realizó correctamente.",
  "ccm.satisfaction.message.resetconfirm": "¿Está seguro de que desea restablecer el nivel de satisfacción y la plantilla de encuesta de satisfacción? Cada canal necesita usar el nivel de satisfacción de restablecimiento y el contenido de la plantilla.",
  "ccm.satisfactionconfig.message.channelused": "Un canal hace referencia al grupo de directivas y no se puede eliminar.",
  "ccm.satisfactionconfig.message.choosestrategy": "*Seleccione la estrategia de encuesta de satisfacción",
  "ccm.satisfactionconfig.message.memberstrategyconfig": "Configuración del miembro del grupo de estrategia",
  "ccm.satisfactionconfig.message.validatemaxnumber": "Se puede crear un máximo de 100 grupos de directivas.",
  "ccm.satisfactionlevel.message.leastcalltype": "Seleccione al menos un tipo de llamada.",
  "ccm.satisfactiontemplate.message.resetfail": "Error al restablecer el contenido de la plantilla de encuesta de satisfacción.",
  "ccm.satisfactiontemplate.message.savefail": "Error al guardar la plantilla de encuesta de satisfacción.",
  "ccm.urlconfig.message.oauth.tip.warning": "Ninguna autenticación de URL de devolución de llamada puede causar riesgos de seguridad. ¿Está seguro de que desea utilizar este modo de autenticación?",
  "ccm.urlconfig.message.tip.warning": "Asegúrese de haber adquirido servicios relacionados con ITA. De lo contrario, las llamadas se verán afectadas.",
  "ccm.notifition.sendmessage.smsconfignull":"La configuración del mensaje SMS está vacía. Seleccione Centro de configuración > Configuración de acceso > Configuración de SMS para configurar la información de SMS.",

  "iolp.label.basicInfo": "Información básica",
  "iolp.label.workflow": "Flujo de trabajo",
  "iolp.workflow.node.title": "Atributos de nodo establecidos correctamente.",
  "iolp.workflow.line.title": "Atributos de conexión establecidos correctamente.",
  "iolp.workflow.node.label.name": "Nombre",
  "iolp.workflow.node.label.nodeType": "Tipo de nodo",
  "iolp.workflow.node.label.nodeId": "id del nodo",
  "iolp.workflow.node.label.executeType": "Tipo de ejecución",
  "iolp.workflow.node.label.autoNodeInfo": "Número correspondiente del resultado del nodo automático",
  "iolp.workflow.node.label.executeContent": "Contenido de ejecución",
  "iolp.workflow.node.label.explain": "Dimensiones de negocio",
  "iolp.workflow.node.label.associateKbs": "Conocimiento asociado",
  "iolp.workflow.node.label.associateBusiness": "Enlace de gestión de servicio asociado",
  "iolp.workflow.node.label.hintTextInfo": "Información de descripción",
  "iolp.workflow.node.label.sms": "Plantilla SMS",
  "iolp.workflow.node.label.associateJs": "Funciones de JS relacionadas",
  "iolp.workflow.nodetype.andsign": "nodo de tarea",
  "iolp.workflow.nodetype.fork": "Nodo de decisión",
  "iolp.workflow.nodetype.andjoin": "Combinación",
  "iolp.workflow.executetype.common": "Tipo normal",
  "iolp.workflow.executetype.SMS": "SMS enviado",
  "iolp.workflow.executetype.email": "Enviar correo electrónico",
  "iolp.workflow.executetype.IVR": "Transferir al flujo automático IVR",
  "iolp.workflow.executetype.auto": "tareas automáticas",
  "iolp.workflow.executetype.subFlow": "Transferir a otros subprocesos de negocio",
  "iolp.workflow.executetype.case": "Crear ticket",
  "iolp.configur.service.label.basicInfo": "Información básica",
  "iolp.configur.service.label.workflow": "Proceso guiado",
  "iolp.configur.service.label.name": "Nombre",
  "iolp.configur.service.label.workflow.name": "Nombre del proceso",
  "iolp.configur.service.label.directory.name": "Nombre de la categoría",
  "iolp.configur.service.label.serviceCode": "código de servicio",
  "iolp.configur.service.label.upperLevelServiceCode": "Código de servicio de nivel superior",
  "iolp.configur.service.label.imageUrl": "Dirección de la imagen",
  "iolp.configur.service.label.customizedPageUrl": "dirección de página personalizada",
  "iolp.configur.service.label.customizedPageContainerId": "Identidad de contenedor de página personalizada",
  "iolp.configur.servicelabel.shortcutButtonPage": "Dirección de la página del botón de acceso directo",
  "iolp.configur.service.label.shortcutButtonContainerId": "ID de contenedor de página de botón de acceso directo",
  "iolp.configur.service.label.search.tips": "Introduzca un nombre",
  "iolp.configur.service.add": "Nuevo",
  "iolp.configur.service.operate.edit": "Editar",
  "iolp.configur.service.operate.delete": "Eliminar",
  "iolp.configur.service.tips.customizedPageUrl": "ejemplo: CustomerInfoDemo.html",
  "iolp.configur.service.add.select.title": "Nombre de categoría seleccionado",
  "iolp.configur.service.add.parent.title": "Nombre de categoría principal",
  "iolp.configur.service.dialog.root.directory.title": "No hay categoría padre",
  "iolp.configur.service.add.operate.title": "Nuevo tipo",
  "iolp.configur.service.dialog.add.directory.title": "Creación de una categoría de guía de servicio",
  "iolp.configur.service.dialog.modify.directory.title": "Editar categoría de guía de negocios",
  "iolp.configur.service.dialog.add.workflow.title": "Creación de un proceso de orientación empresarial",
  "iolp.configur.service.dialog.modify.workflow.title": "Editar proceso de orientación empresarial",
  "iolp.configur.servicelabel.label.serialNumber": "Número de secuencia de clasificación",
  "iolp.configur.servicelabel.label.status": "Estado",
  "iolp.configur.servicelabel.label.description": "Información de descripción",
  "iolp.configur.servicelabel.button.saveDraft": "Guardar como borrador",
  "iolp.configur.servicelabel.status.draft": "Draft",
  "iolp.configur.servicelabel.button.publish": "Publicado",
  "iolp.configur.servicelabel.common.tips.error": "Error",
  "iolp.configur.servicelabel.common.tips.success": "Éxito",
  "iolp.tree.tips": "No se puede agregar ningún nodo bajo un nodo hoja.",
  "iolp.configur.servicelabel.save.successful": "Guardado correctamente",
  "iolp.configur.servicelabel.release.successful": "PublicaciÃ³n exitosa.",
  "iolp.configur.servicelabel.save.failed": "No se pudo guardar",
  "iolp.configur.servicelabel.release.failed": "Error en la publicación",
  "iolp.configur.servicelabel.contact.administrator": "No se pudo generar el código de servicio. Póngase en contacto con el administrador.",
  "iolp.configur.servicelabel.select.node": "Seleccione un nodo",
  "iolp.configur.servicelabel.confirm.delete": "Eliminar",
  "iolp.configur.servicelabel.confirm.deleteAll": "¿Está seguro de que desea eliminar el nodo actual y sus subnodos?",
  "iolp.configur.servicelabel.confirm.deleteCurrent": "¿Está seguro de que desea eliminar el nodo actual?",
  "iolp.configur.servicelabel.delete.successful": "Eliminación exitosa",
  "iolp.configur.servicelabel.delete.failed": "No se pudo eliminar",
  "iolp.configur.servicelabel.tips.info": "Tip",
  "iolp.workflow.label.graphZoomOut": "Zoom Out",
  "iolp.workflow.label.graphZoomIn": "Zoom in",
  "iolp.workflow.label.previous": "Cancelar",
  "iolp.workflow.label.next": "Recuperación",
  "iolp.workflow.label.cleanGraph": "Clear canvas",
  "iolp.workflow.label.taskElement": "Elemento de diagrama de función",
  "iolp.workflow.label.decisionElement": "Elemento del diagrama de rama",
  "iolp.workflow.label.operation": "Acción",
  "iolp.workflow.label.information": "Información de asociación",
  "iolp.workflow.label.unmatchedFlow": "El servicio correspondiente no se puede hacer coincidir",
  "iolp.workflow.button.restart": "Reiniciar el proceso",
  "iolp.workflow.button.hideFlowChart": "Ocultar diagrama de flujo",
  "iolp.workflow.button.showFlowChart": "Mostrar diagrama de flujo",
  "iolp.workflow.label.currentNodeName": "Nombre del nodo actual",
  "iolp.workflow.label.customizeInfo": "Información personalizada",
  "iolp.workflow.label.flowInfo": "Información del proceso de negocio",
  "iolp.workflow.button.kbs": "Base de conocimiento",
  "iolp.workflow.button.service": "Procesamiento de negocios",
  "iolp.workflow.title.name": "Proceso de Negocio",
  "iolp.configur.service.label.service.title": "Tipo de negocio",
  "iolp.run.service.label.customizedPage": "Información de personalización",
  "iolp.run.service.label.overview": "Descripción general del proceso",
  "iolp.run.service.label.nodeName": "Nombre del nodo:",
  "agentconsole.ccnotification.recipient.validate.error": "Cada número puede contener solo dígitos y un máximo de 20 dígitos.",
  "ccm.sendwhatsapp.content.too.long": "El contenido de la plantilla es demasiado largo.",
  "ccm.sendwhatsapp.body.too.long": "El contenido corporal es demasiado largo.",
  "ccm.notifition.sendmessage.largerthanlimit":"El número de destinatarios supera el máximo {n}.",
  "ccm.satisfactionconfig.message.switchtip": "Una vez activada esta función, el sistema muestra automáticamente la página de la encuesta de satisfacción una vez finalizado el servicio de canal web. Una vez que otros servicios de canal multimedia están completos, el sistema automáticamente envía el contenido de la plantilla de satisfacción.",
  "ccm.skillgroup.message.noskill": "La lista de habilidades del grupo de habilidades no puede estar vacía.",
  "ccm.satisfactionconfig.message.timelimittip": "Si el tiempo de respuesta del cliente excede el tiempo de respuesta válido, la respuesta no tiene efecto.",
  "ccm.ccagent.management.advanced.search": "Búsqueda avanzada",
  "ccm.satisfactiontemplate.tips": "Nota: El sistema utiliza el idioma predeterminado del inquilino para generar el contenido de la plantilla. La plantilla de mensaje de encuesta de satisfacción se utiliza para enviar mensajes de encuesta de satisfacción a los clientes en Instagram, Telegram, Whatsapp, Line, Web Chat, Face Book, X (Twitter), 5G RCS, y canales de SMS.",
  "ccm.satisfactionconfig.message.textmsg": "Mensaje de texto",
  "ccm.agent.message.agentweight.invalid": "El peso del agente debe variar de 1 a 300.",
  "ccm.agent.message.skillweight.outlimit": "El valor de peso debe oscilar entre 1 y 100.",
  "ccm.ccagent.management.cannot.exceed.25":"El número no puede exceder los 25 caracteres.",
  "ccm.satisfactiontemplate.title":"Configuración de contenido de la plantilla de encuesta de satisfacción",
  "ccm.satisfactiontemplate.icontitle": "Actualizar el contenido de la plantilla por nivel de satisfacción",
  "ccm.agent.message.skillweight.invalid": "El peso de la habilidad debe oscilar entre 1 y 100.",
  "ccm.satisfaction.leveltips":"Nota: Todos los canales utilizan la misma configuración de satisfacción.",
  "ccm.ccagent.management.batch.operation":"Operación por lotes",
  "ccm.agent.callreason.must": "El motivo de la llamada es obligatorio.",
  "iolp.workflow.efficiency.analysis":"Análisis de eficiencia",
  "iolp.configur.servicelabel.label.version": "Versión de lanzamiento",
  "iolp.configur.servicelabel.label.version.number": "Número de versiones",
  "iolp.configur.servicelabel.status.abandon": "No válido",
  "iolp.configur.servicelabel.status.publish": "Release",
  "iolp.configur.servicelabel.status.unpublish": "No publicado",
  "iolp.configur.service.operate.copy": "Replicación",
  "iolp.configur.service.operate.publish": "Release",
  "iolp.configur.service.operate.cancel": "Cancelar publicación",
  "iolp.configur.servicelabel.publish.successful": "publicado correctamente",
  "iolp.configur.servicelabel.publish.failed": "No se pudo publicar",
  "iolp.configur.servicelabel.cancel.successful": "liberación cancelada con éxito",
  "iolp.configur.servicelabel.cancel.failed": "No se pudo cancelar",
  "iolp.configur.servicelabel.copy.successful": "se ha copiado correctamente.",
  "iolp.configur.servicelabel.copy.failed": "Error de replicación",
  "iolp.configur.servicelabel.confirm.delete.flow": "¿Está seguro de que desea eliminar la versión actual?",
  "iolp.process.utilization.efficiency.analysis":"Análisis de eficiencia de utilización de procesos",
  "iolp.flow.number.of.contacts":"Número de contactos (piezas)",
  "iolp.flow.first.solution.rate":"Tasa de la primera solución (%)",
  "iolp.flow.please.select.time.before.today":"Por favor seleccione una hora antes de hoy",
  "iolp.flow.the.interval.validate":"El intervalo es de 1 día o de 3 a 30 días.",
  "iolp.flow.analysis.date.time":"Fecha/Hora",
  "ccm.empty.template": "La plantilla no existe. Compruebe la plantilla de notificación.",
  "ccm.empty.variables": "La variable de plantilla no existe. Compruebe la plantilla de notificación.",
  "ccm.no.meeting.variables": "La variable plantilla no contiene la variable Meeting_URL. Compruebe la plantilla de notificación.",
  "ccm.agent.meeting.failed": "El agente no puede crear una conferencia.",
  "ccm.agent.link.failed": "El agente no puede generar el enlace SMS.",
  "ccm.agent.meeting.receiver.check.error": "El número de destinatarios no puede exceder de 1.",
  "ccm.agent.contact.timeouthungup": "Cierre de tiempo de espera",
  "ccm.agent.contact.skilltimeouthungup": "Transferencia de tiempo de espera a la cola de habilidades",
  "ccm.agent.leavemessage.opinionempty": "Los comentarios de auditoría son obligatorios.",
  "ccm.sms.meeting.link": "enlace de conferencia SMS",
  "ccm.agent.sms.meeting.gateway.commonerror": "Error al crear la conferencia. Código de error: {n}",
  "ccm.agent.sms.meeting.gateway.error": "Error al crear la conferencia. Código de error: {n}. No cree la conferencia en modo silencioso, en espera o multipartidario.",
  "ccm.contactRecordDetail.notSetTraceLogError": "No se encuentra la pista de flujo. Agregue la configuración de seguimiento y, a continuación, vea la pista de flujo del registro de contacto correspondiente",
  "ccm.agent.callreason.modify.exceeds.limit": "Error al modificar el motivo de la llamada. El intervalo excede el período de validez de la razón de la llamada.",
  "ccm.label.appcube.switch": "P\u00E1gina AppCube-Integrada",
  "ccm.label.appcube.app": "Seleccionar aplicaci\u00F3n",
  "ccm.label.appcube.menu": "Seleccionar men\u00FA",
  "ccm.contactitemconfig.label.data.item.sequence.number.seq": "Orden de campo",
  "ccm.contactitemconfig.label.data.item.add": "Crear campo de registro de contacto",
  "ccm.contactitemconfig.label.data.item.edit": "Editar campo de registro de contacto",
  "ccm.contactitemconfig.label.data.item.tips": "La interfaz de origen del conjunto de datos debe configurarse en la página de administración de la interfaz. Póngase en contacto con el administrador de operaciones del sistema.",
  "ccm.contactitemconfig.label.data.item.basic.item": "Campo extendido básico",
  "ccm.contactitemconfig.label.data.item.custom.item": "Campo extendido definido por el usuario",
  "ccm.contactitemconfig.label.dataInfo.item.add": "Crear elemento de datos",
  "ccm.contactitemconfig.label.dataInfo.item.edit": "Editar elemento de datos",
  "ccm.custdata.label.data.table.template": "Plantilla de tabla de datos",
  "ccm.custdata.msg.uploadType": "Método de importación de datos duplicados",
  "ccm.custdata.msg.uploadTypeInfo": "Si hay datos duplicados\, si elige sobrescribir los datos originales\, el registro de datos se actualizará\, si elige no sobrescribir los datos originales\, se considerará como datos fallidos",
  "ccm.custdata.msg.uploadType.cover": "Sobrescribir datos originales",
  "ccm.custdata.msg.uploadType.notcover": "No sobrescriba los datos originales",
  "ccm.pageconfig.label.page": "Página de inicio",
  "ccm.pageconfig.label.publishpage": "Página de publicación",
  "ccm.transtask.label.intelligentInspectionConfig":"Configuración de descarga de datos de inspección AI",
  "ccm.validate.portLengthError":"El rango de puertos debe ser 1-65535",
  "ccm.resource.dump.server.add":"Crear un servidor de volcado de recursos",
  "ccm.resource.dump.server.edit":"Editar servidor de volcado de recursos",
  "ccm.resource.dump.server.view":"Ver servidor de volcado de recursos",
  "ccm.resource.dump.css.add":"Crear un servidor de búsqueda en la nube",
  "ccm.resource.dump.css.edit":"Editar servidor de búsqueda en la nube",
  "ccm.resource.dump.css.view":"Ver servidor de búsqueda en la nube",
  "ccm.resource.dump.task.add":"Crear una tarea de volcado de recursos",
  "ccm.resource.dump.task.edit":"Editar una tarea de volcado de recursos",
  "ccm.resource.dump.task.view":"Ver una tarea de volcado de recursos",
  "ccm.transtask.label.intelligentInspectionDay":"Duración del volcado de datos de inspección AI",
  "ccm.transtask.css.server.num":"El número de Cloud Search Server no puede exceder de 1.",
  "ccm.contact.customer.config.num":"El número de columna de información del cliente de contacto no puede exceder de 10.",
  "ccm.export.password.error.tips": "La contraseña del archivo comprimido contiene de 8 a 12 caracteres, incluyendo letras, dígitos y caracteres especiales {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.export.password.error.message": "El valor debe contener letras, dígitos y caracteres especiales {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.agent.ccagentinfo.modifyselfphone.success":"Modificación exitosa del número de teléfono fijo / móvil",
  "ccm.agent.ccagentinfo.modifyselfphone.fail":"Falló la modificación del número de teléfono fijo / móvil",
  "ccm.export.table.taskTime": "Tiempo de tarea",
  "ccm.export.table.taskStatus": "Estado de la tarea",
  "ccm.agent.contact.multimedia.sms":"Multimedia-SMS",
  "ccm.agent.button.randomRestSoftPhonePwd":"Restablecer la contraseña del softphone",
  "ccm.agent.label.skillGroup":"Grupo de cola de habilidades",
  "ccm.agent.message.searchSkillGroupName":"Nombre",
  "ccm.skill.label.skillGroupName":"Nombre del grupo de colas de habilidades",
  "ccm.agent.button.restSoftPhonePwd.remark":"Si restablece las contrase\u00F1as del softphone del agente a contrase\u00F1as aleatorias en lotes\, los agentes deben restablecer sus propias contrase\u00F1as del softphone antes de usar la funci\u00F3n del softphone. El administrador tambi\u00E9n puede hacer clic en Restablecer contrase\u00F1a de Softphone en la lista para cambiar manualmente la contrase\u00F1a del agente.",
  "ccm.agent.management.exporting.agent.information":"Exportación de información de agente",
  "ccm.agent.clear.message.clearcommonerror":"Error al cancelar la configuraci\u00F3n. El servicio no est\u00E1 disponible.",
  "ccm.contactitemconfig.label.dataInfo.item.name.exist": "El nombre del elemento de datos ya existe.",
  "ccm.agent.button.downloadvrc": "Descargar VRC",
  "ccm.dual.call.callback.url.config":"Configuración de URL de devolución de llamada",
  "ccm.dual.call.callback.url.auth":"Interfaz Invocar autenticación de URL",
  "ccm.dual.call.callback.url.authType":"Modo de autenticación de URL de devolución de llamada",
  "ccm.dual.call.callback.url.ita":"Configuración de parámetros ITA",
  "ccm.dual.call.callback.url.xNumberType":"Modo de asignación de números X",
  "ccm.agent.management.select.agent":"Selección de agentes por ID de empleado",
  'ccm.password.length.limit.min': 'La contraseña es demasiado corta. Introduzca {min}-{max} caracteres',
  'ccm.password.length.limit.max': 'La contraseña es demasiado larga. Introduzca {min}-{max} caracteres',
  "ccm.agent.label.skill":"Habilidades",
  "ccm.agent.operation.SCREENMONITOR": "Monitoreo de pantalla",
  'ccm.mobile.agent.front.add': 'Creación de un agente móvil',
  'ccm.mobile.agent.front.edit': 'Editar agente móvil',
  'ccm.mobile.agent.front.checkNum': 'Seleccione al menos un número.',
  'ccm.mobile.agent.front.maxNum': 'El número no puede exceder de 15.',
  'ccm.contactitemconfig.label.contactData.add': 'Creación de un conjunto de datos de registros de contactos',
  'ccm.contactitemconfig.label.contactData.edit': 'Edición de un conjunto de datos de registros de contactos',
  'ccm.agent.button.downloading':'Descargando...',
  'ccm.label.operationexport.nodata': 'Ningún registro de operación cumple las condiciones.',
  'ccm.label.transferexport.nodata': 'Ningún registro de reenvío cumple las condiciones.',
  'ccm.label.leaveexport.nodata': 'Ningún registro de descanso cumple con los requisitos.',
  'ccm.label.send.whatsapp': 'Enviar Whatsapp',
  "ccm.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "El nombre del centro de llamadas no puede contener caracteres especiales",
  'ccm.common.validation.numbers': 'Ingrese solo dígitos',
  'ccm.common.validation.click.number': 'Por favor, haga clic en el número',
  'ccm.ccagent.agent.weight.tips': 'Un valor mayor indica una prioridad más alta. Cuando hay múltiples agentes inactivos\, el sistema asigna preferentemente llamadas entrantes al agente con un peso más alto.',
  'ccm.ccagent.skill.weight.tips': 'Un valor mayor indica una prioridad más alta. Cuando hay llamadas en varias colas\, el sistema asigna las llamadas en la cola con un peso más alto al agente inactivo.',
  'ccm.ccagent.batch.modify.special.character.verification': 'no puede contener’',
  'ccm.resource.dump.obs.cert.warn.tip': 'Póngase en contacto con el personal de O&M para importar el certificado de confianza de OBS a la biblioteca de certificados.',
  "ccm.audiovideoworkbench.mood" : "Estado de ánimo",
  "ccm.audiovideoworkbench.intention" : "Intención",
  "ccm.audiovideoworkbench.complain" : "Propensidad a quejarse",
  "ccm.audiovideoworkbench.aiswitch" : "Modelo de lenguaje grande",
  "ccm.audiovideoworkbench.airecommendation" : "Recomendación inteligente",
};