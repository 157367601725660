import enLocale from './en_US'
import zhLocale from './zh_CN'
import esLocale from './es_ES'
import frLocale from './fr_FR'
import ptLocale from './pt_BR'
import thLocale from './th_TH'
import faLocale from './fa_IR'
import arLocale from './ar_SA'
import deLocale from './de_DE'


const ccmanagement = {
  en_US: {
    ...enLocale,
  },
  zh_CN: {
    ...zhLocale,
  },
  es_ES: {
    ...esLocale,
  },
  th_TH: {
    ...thLocale,
  },
  pt_BR: {
    ...ptLocale,
  },
  fr_FR: {
    ...frLocale,
  },
  fa_IR: {
    ...faLocale,
  },
  ar_SA: {
    ...arLocale,
  },
  de_DE: {
    ...deLocale,
  }
}

export default ccmanagement