/**
 * 创建表情框
 *
 * @param emojis emojis
 * @param emojiMaps emojiMaps
 * @param length length
 * @returns {string}
 */

import {emojiUs, emojiCn} from './emojiInternationalization.js'
export const emotionCreate = (emojis, emojiMaps, length) => {
    let emojiStr = '';
    $.map(emojis, (item, index) => {
        index = index.toLowerCase();
        let arr;
        let emojiChild;
        let imgPath;
        switch (index) {
            case 'wechat':
                arr = emojiMaps[index];
                imgPath = emojis[index]['path'];
                emojiChild = createFace(arr, '.png', ':', imgPath, length);
                emojiStr += emojiChild;
                break;
            case 'emoji':
                arr = emojiMaps[index];
                imgPath = emojis[index]['path'];
                emojiChild = createFace(arr, '.png', ',', imgPath, length);
                emojiStr += emojiChild;
                break;
            case 'iframe' :
                arr = emojiMaps[index];
                imgPath = emojis[index]['path'];
                emojiChild = createIframeFace(arr, '.png', ',', imgPath, length);
                emojiStr += emojiChild;
                break;
            default:
                break;
        }
    });

    function createFace(arr, suffix, code, imgPath, length) {
        let finalimgPath
        if (imgPath.charAt(imgPath.length - 1) !== '/') {
            // 以/分割路径获取最后一级目录
            let finalimgPathArr = imgPath.split("/");
            finalimgPath = finalimgPathArr[finalimgPathArr.length - 1];
            imgPath = imgPath + '/';
        }
        let count = 0;
        let emojiChild = '';
        let emojiStr1 = '';
        $.map(arr, function (item, index) {
            let src = imgPath + index + suffix;
            if ($['cookie']('u-locale') === 'en_US') {
                let emojiIndex = emojiCn.indexOf(item);
                item = emojiUs[emojiIndex];
            }
            if (count < length) {
                emojiChild += '<li data-code="' + code + '" data-emojiImg="' + src + '" title="' + item + '"><div class="menu_img ' + finalimgPath + '_background_img ' + finalimgPath + '_background_img_' + index + '" /></li>'
            } else {
                emojiStr1 += '<ul>' + emojiChild + '</ul>';
                count = 0;
                emojiChild = '';
                emojiChild += '<li data-code="' + code + '" data-emojiImg="' + src + '" title="' + item + '"><div class="menu_img ' + finalimgPath + '_background_img ' + finalimgPath + '_background_img_' + index + '"  /></li>';
            }
            if (Object.keys(arr).length === parseInt(index)) {
                var tmp = length - count;
                for (var i = 0; i < tmp - 1; i++) {
                    emojiChild += '<li data-code="' + code + '"><img src="#" width="25" height="25" style="visibility: hidden;"></li>';
                }
                emojiStr1 += '<ul>' + emojiChild + '</ul>'
            }
            count++;
        });
        return '<div  class="emoji_item">' + emojiStr1 + '</div>';
    }

    return emojiStr;
}

/**
 * 创建表情item
 *
 * @param arr arr
 * @param suffix suffix
 * @param code code
 * @param imgPath imgPath
 * @param length length
 * @returns {string}
 */
export const createIframeFace = (arr, suffix, code, imgPath, length) => {
    if (imgPath.charAt(imgPath.length - 1) !== '/') {
        imgPath = imgPath + '/';
    }
    let count = 0;
    let emojiChild = '';
    let emojiStr1 = '';
    $.map(arr, function (item, index) {
        let src = imgPath + index + suffix;

        if (count < length) {
            emojiChild += '<li  data-emojiImg="' + src + '" title="' + item + '">' + item + '</li>'
        } else {
            emojiStr1 += '<ul>' + emojiChild + '</ul>';
            count = 0;
            emojiChild = '';
            emojiChild += '<li  data-emojiImg="' + src + '" title="' + item + '">' + item + '</li>';
        }
        if (Object.keys(arr).length === parseInt(index)) {
            let tmp = length - count;
            for (let i = 0; i < tmp - 1; i++) {
                emojiChild += '<li data-code="' + code + '"><img src="#" width="25" height="25" style="visibility: hidden;"></li>';
            }
            emojiStr1 += '<ul>' + emojiChild + '</ul>'
        }
        count++;
    });
    return '<div  class="emoji_item">' + emojiStr1 + '</div>';
}