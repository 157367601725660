export default {
    'sm.assign.employee': 'Mitarbeiter zuweisen',
    'SM.USERGROUP.SHOW.LIST': 'Benutzergruppen',
    'common.baseui.LABEL.HEADING_BASEINFO': 'Grundlegende Informationen',
    'SM.USERGROUP.LABEL.NAME': 'Benutzergruppenname',
    'common.bremui.label.description': 'Beschreibung',
    'SM.SERVLET.EMPLOYEE': 'Mitarbeiter',
    'common.statemachineui.button.add': 'Hinzufügen',
    'sm.add.usergroup': 'Erstellen einer Benutzergruppe',
    'sm.edit.usergroup': 'Benutzergruppe bearbeiten',
    'SM.ORGSTAFF.BUTTON.COMMON_OK': 'OK',
    'SM.COMMON.LABEL.SYSMENU_SUSPENDED': 'Deaktivieren',
    'SM.ORGSTAFF.MESSAGE.OPERATESUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_CONTBEADMIN_ERR': 'Das Systemadministratorkonto kann nicht als Quellkonto verwendet werden.',
    'SM.ORGSTAFF.MESSAGE.BE_DELETESUCCESS': 'Erfolgreich gelöscht.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_DELETE_FAILED': 'Benutzer konnte nicht gelöscht werden.',
    'SM.ORGSTAFF.TIPS.INVALID_IP_ADDRESS': 'Ungültige IP-Adresse.',
    'SM.COMMON.BUTTON.BANKCARD_SEARCH': 'Suche',
    'SM.ORGSTAFF.LABEL.ACCESS.ADD_FAIL': 'Hinzufügen fehlgeschlagen.',
    'SM.ORGSTAFF.TITLE.CONTACT_DESC': 'Beschreibung',
    'SM.COMMON.LABEL.AREALIST': 'Bereichsliste',
    'SM.COMMON.LABEL.CURRENCY_DECIMALSYMBOL': 'Dezimalzeichen',
    'SM.COMMON.TIPS.CHARGECODE_CODEINPUT': 'Sie können nur Buchstaben und Zahlen eingeben.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT': 'Secondment',
    'SM.COMMON.LABEL.CURRENCY_STANDARD_MEASUREMENT': 'Standardeinheitenmarkierung',
    'SM.COMMON.LABEL.HOLIDAY_THURSDAY': 'Thu',
    'SM.COMMON.LABEL.SYSMENU_AUTHID': 'Menüberechtigungs-ID',
    'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_USEPARENT': 'Wenn die Daten des übergeordneten Knotens verwendet werden, wird die aktuelle Zeitzonenkonfiguration gelöscht. Möchten Sie wirklich fortfahren?',
    'SM.ORGSTAFF.TITLE.CONTACT_TYPE': 'Kontakttyp',
    'SM.ORGSTAFF.TITLE.BE_PRECISION': 'Währungsgenauigkeit',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_SET_ROLE': 'Rolle festlegen',
    'SM.ORGSTAFF.MESSAGE.EMPLOYEE_SECONDMENT_CONFIRM': 'Möchten Sie wirklich beenden?',
    'SM.ORGSTAFF.LABEL.ORG_BASICINFO': 'Grundlegende Informationen',
    'SM.COMMON.LABEL.MEASUREMENT_UNIT_OPERARION': 'Operation',
    'SM.ORGSTAFF.LABEL.BANK_TYPE': 'Banktyp',
    'SM.COMMON.BUTTON.SURVEY_DELETE_ANSWER_OPTIONS': 'Antwortoptionen löschen',
    'SM.COMMON.MESSAGE.SYSMENU_DEACTIVATED_NOT_SUPPORT': 'Der Status für das deaktivierte Menü unterstützt keine Berechtigungsverwaltung.',
    'SM.ORGSTAFF.TIPS.RESTRICTIP': 'Geben Sie eine IP-Adresse im Format xx.* oder xx.xx.* oder xx.xx.xx.* ein.',
    'SM.ORGSTAFF.TITLE.CONTACT_EMAIL': 'E-Mail',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_ENDDATE': 'Enddatum',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_BE': 'BE',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_VIEWFILE': 'Ansicht',
    'SM.ORGSTAFF.MESSAGE.ORG_PARENT_FORBID': 'Die übergeordnete Organisationseinheit wurde ausgesetzt.',
    'SM.COMMON.LABEL.ATTR_LANGUAGE': 'Sprache',
    'SM.ORGSTAFF.LABEL.TEAM_ASSIGN': 'Es können maximal 2000 Mitarbeiter zugewiesen werden.',
    'SM.COMMON.LABEL.ATTACHMENT_DEL_ACCESSORY': 'Löschen',
    'SM.ORGSTAFF.LABEL.SEARCH_ALL': 'Alle Mitarbeiter durchsuchen',
    'SM.ORGSTAFF.LABEL.ADDR_DISPLAY_ORDER': 'Falsche Reihenfolge der Adressanzeige.',
    'SM.COMMON.LABEL.MEASUREMENT_DESTMEAUNIT': 'Zielmaßeinheit',
    'SM.ORGSTAFF.MESSAGE.CHOOSE_SELECTED_OU_FIRST': 'Wählen Sie zuerst einen Knoten in der Struktur der Organisationsbeziehungen aus.',
    'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_EQUALTOTARGET_ERROR': 'Das Quellkonto darf nicht im Zielkonto enthalten sein.',
    'SM.ORGSTAFF.TITLE.ROLE_BASIC': 'Grundrolle',
    'SM.ORGSTAFF.LABEL.STAFF_SESSION_VALIDITY_PERIOD': 'Gültigkeitsdauer der Sitzung (Min.)',
    'SM.COMMON.LABEL.CURRENCY_MEASUREMENT': 'Währungseinheit',
    'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_EMPLOYEE': 'Alle exportieren',
    'SM.ORGSTAFF.LABEL.DEPTMACRESTRICT': 'Einschränkung der MAC-Adresse',
    'SM.ORGSTAFF.LABEL.REGION_DAYLIGHTSAVINGTIME': 'DST',
    'SM.ORGSTAFF.MESSAGE.BE_CANNOTDELETECURRENCY': 'Die Standardwährung kann nicht gelöscht werden.',
    'SM.ORGSTAFF.TITLE.CONTACT_FULL_NAME': 'Vollständiger Name',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_VST': 'Sitzungstimeouttyp',
    'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_LIST': 'Währungsmaßeinheit',
    'SM.COMMON.LABEL.MEASUREMENT_CONV_LIST': 'Umrechnungsliste für Messungen',
    'SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB': 'Geben Sie mindestens 0 Zeichen ein.',
    'SM.ORGSTAFF.LABEL.REGION_DESCRIPTION': 'Beschreibung',
    'SM.COMMON.LABEL.BANKCARD_ACCT_LENG_PARAM': 'Länge der Kartennummer',
    'SM.COMMON.LABEL.SURVEY_CLASS_SORT': 'Klassifizierungssequenz',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_IP_TYPE': 'IP-Typ',
    'SM.COMMON.LABEL.CURRENCY_DISPFORMAT_DISPLAYTYPE': 'Anzeigetyp',
    'SM.COMMON.LABEL.SYSMENU_CONFIGURESYSMENU': 'Systemmenü konfigurieren',
    'SM.ORGSTAFF.LABEL.TEAM_ORG_EMPLOYEE': 'Mitarbeiter organisieren',
    'SM.ORGSTAFF.LABEL.REGION_DST_ENDDATE': 'Enddatum der Sommerzeit',
    'SM.ORGSTAFF.LABEL.ORG_STOP_TIPS': 'Nach der Suspendierung wären die Organisation oder die Organisation und ihre untergeordnete Organisation nicht mehr verfügbar. Bestätigen Sie die Suspendierung?',
    'SM.ORGSTAFF.TITLE.OPPAUTH_TITLE_MUTEXAUTH_INFOLIST': 'Liste der exklusiven Berechtigungen',
    'SM.COMMON.LABEL.ATTACHMENT_DELETE_INFO': 'Möchten Sie die Datei wirklich löschen?',
    'SM.ORGSTAFF.OPTION.STAFF_REMOVE_PERMISSION': 'Genehmigung des Zielbetreibers entfernen',
    'SM.COMMON.LABEL.MEASUREMENT_EXPORT_SUCCESS': 'ERFOLGNIS',
    'SM.ORGSTAFF.TITLE.CONTACT_TITLE': 'Titel',
    'SM.ORGSTAFF.BUTTON.EMPLOYEE_CANCLE': 'Abbrechen',
    'SM.ORGSTAFF.LABEL.REGION_DST_SWITCHINTERVAL': 'DST-Versatz',
    'SM.COMMON.LABEL.SYSMENU_AFFECTED_MENU': 'Betroffenes Menü',
    'SM.ORGSTAFF.MESSAGE.ENDEARLIERSTART': 'Das Startdatum darf nicht nach dem Enddatum liegen.',
    'SM.COMMON.LABEL.MEASUREMENT_UPLOAD_FILE': 'Datei hochladen',
    'SM.COMMON.LABEL.SYSMENU_USEPARENT': 'Übergeordnete Daten verwenden',
    'SM.COMMON.LABEL.HOLIDAY_FRIDAY': 'Fr',
    'SM.COMMON.LABEL.EDIT_CURRENCY_EXCHANGE_RATE': 'Wechselkurs ändern',
    'SM.COMMON.LABEL.CURRENCY_TYPE_SYMBOL': 'Symbol',
    'SM.COMMON.LABEL.SYSMENU_MENUNAME': 'Menüname',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MAX': 'Die hochgeladene Datei hat die maximale Größe von 0 KB überschritten.',
    'SM.COMMON.LABEL.SURVEY_ITEM_NAME': 'Artikelname',
    'SM.COMMON.TIPS.POPUP_INFO_TITLE': 'Informationen',
    'SM.COMMON.MESSAGE.ERATE_NUMBER_INVALID': 'Ungültige Nummer',
    'SM.COMMON.LABEL.CHARGECODE_STATUS': 'Status',
    'SM.COMMOM.MESSAGE.SYSMENU_MENU_STATUS_NOT_CONSISTENT': 'Menüstatus ist nicht konsistent.',
    'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_HEAD': 'Fragebogenkopf',
    'SM.COMMON.LABEL.MODULE_SHORTNAME': 'Kurzname',
    'SM.ORGSTAFF.LABEL.REGION_DST_MONTHDAY': 'Monat/Tag',
    'SM.TENANTSPACE.AUTHTYPE.TITLE': 'Authentifizierungstyp',
    'SM.ORGSTAFF.MESSAGE.DSTOFFSET.OUTRANAGE': 'Der Sommerzeit-Offset liegt nicht im Bereich zwischen 1 und 86400.',
    'SM.ORGSTAFF.LABEL.WORKGROUP_CONTENTS': 'Inhalt des Arbeitsgruppenpfads',
    'SM.ORGSTAFF.MESSAGE.SELECT_EMPLOYEE': 'Wählen Sie einen Mitarbeiter aus',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD': 'Neues Kennwort',
    'SM.COMMON.MESSAGE.EXPORT_VIEW': ', rufen Sie das Menü Systemverwaltung > Projektbasierte Konfiguration > Projekt auf, um die exportierten Ergebnisse anzuzeigen.',
    'SM.ORGSTAFF.LABEL.TEAM_TEAMLEVER': 'Teamebene',
    'SM.ORGSTAFF.LABEL.ROLE_TYPE_PUB': 'Öffentliche Rolle',
    'SM.ORGSTAFF.TITLE.BE_CURRENCY': 'Währung',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_QUERY_LIST': 'Mitarbeiterliste',
    'SM.COMMON.BUTTON.MEASUREMENT_ADD': 'Neu',
    'SM.COMMON.LABEL.SEARCH_NAME': 'Bitte Namen eingeben',
    'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDAY': 'Urlaub',
    'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT_NO_PERIOD': 'Mitarbeiter auswählen',
    'SM.ORGSTAFF.TITLE.ASSIGNED_MANAGER': 'Zugewiesene Manager',
    'SM.TENANTSPACE.LDAP_RDNPREFIX': 'RDN-Präfix',
    'SM.ORGSTAFF.MESSAGE.EXPORT_LIMIT': 'Es wird nicht empfohlen, mehr als 10.000 zu exportieren.',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_BE': 'BE',
    'SM.COMMON.LABEL.OPERLOG_ENDTIME': 'Endzeit',
    'SM.COMMON.LABEL.CURRENCY_TYPE_CODE': 'Code',
    'SM.ORGSTAFF.BUTTON.DELETE': 'Löschen',
    'SM.COMMON.BUTTON.OK': 'OK',
    'SM.ORGSTAFF.BUTTON.ADDR_ADD': 'Hinzufügen',
    'SM.COMMON.LABEL.CHARGECODE_CODE': 'Code',
    'SM.ORGSTAFF.BUTTON.ADDR_CREATE': 'Neu',
    'SM.COMMON.TITLE.SYSMENU_ERROR': 'Fehler',
    'SM.COMMON.LABEL.SYSMENU_DEACTIVATED': 'Verwerfen',
    'SM.COMMON.LABEL.SURVEY_APPLICATION_SCOPE': 'Anwendungsbereich',
    'SM.COMMON.LABEL.SURVEY_OPTION_NAME': 'Optionsname',
    'SM.ORGSTAFF.LABEL.ORG_SHORT_NAME': 'OU-Kurzname',
    'SM.ORGSTAFF.BUTTON.EMPLOYEE_CLEAR': 'Klar',
    'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_CREATE': 'Neuer Fragebogen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_DESC': 'Beschreibung',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_VALID': 'Gültig',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR': 'Ungültige E-Mail-Adresse',
    'SM.COMMON.LABEL.CHARGECODE_TEST': 'Test',
    'SM.COMMON.LABEL.SURVEY_CREATED_TIME': 'Erstellt am',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNTRANGE': 'Kontobereich',
    'SM.ORGSTAFF.LABEL.REGION_DST_MONTHWEEKDAY': 'Monat/Woche/Tag',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_EXT_TITLE': 'Hochladen fehlgeschlagen. Zulässige Dateiformate: 0; Nicht zulässige Dateiformate: {1}.',
    'SM.ORGSTAFF.LABEL.TEAM_STATUS': 'Status',
    'SM.ORGSTAFF.MESSAGE.ERROR_QUERY': 'Abfrage fehlgeschlagen.',
    'SM.COMMON.LABEL.BANKCARD_BANKTYPE_PARAM': 'Banktyp',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR': 'Der Benutzername enthält ungültige Sonderzeichen.',
    'SM.COMMON.LABEL.CHARGECODE': 'Gebührencode',
    'SM.ORGSTAFF.TITLE.ORG_ASSIGNED_EMP': 'Zugewiesene Mitarbeiter',
    'SM.ORGSTAFF.MESSAGE.SAVE_SUGGESTION': 'Informationen',
    'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_EDIT': 'Fragebogen ändern',
    'SM.COMMON.MESSAGE.CHARGECODE_PERFORM_DELETION': 'Möchten Sie wirklich löschen?',
    'SM.COMMON.BUTTON.CHARGECODE_ADD': 'Neu',
    'SM.COMMON.MESSAGE.ERATE_CODE_COMPARE': 'Der Quellwährungscode darf nicht mit dem Zielwährungscode identisch sein.',
    'SM.ORGSTAFF.LABEL.BANK_LEVEL': 'Bankebene',
    'SM.ORGSTAFF.LABEL.RESTRICTTYPE': 'Typ der Anmeldeeinschränkung',
    'SM.COMMON.LABEL.HOLIDAY_WEDNESDAY': 'Mit',
    'SM.ORGSTAFF.TITLE.CONTACT_PHONE': 'Telefonnummer',
    'SM.ORGSTAFF.LABEL.BE_ADDRESS_FORMAT': 'Adressformatvorlage',
    'SM.ORGSTAFF.LABEL.ORG_DISPLAYORDER': 'Anzeigereihenfolge',
    'SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE': 'Das Kennwort entspricht nicht den Regeln.',
    'SM.COMMON.LABEL.SYSMENU_LABEL': 'Systemmenü',
    'SM.COMMON.MESSAGE.AREA_DELETE_INFO': 'Möchten Sie wirklich löschen?',
    'SM.ORGSTAFF.TITLE.STAFF_ROLE_ASSIGN': 'Rolle zuweisen',
    'SM.ORGSTAFF.OPTION.WEEKDAY_FRI': 'Freitag',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_RESETPWD': 'Passwort zurücksetzen',
    'SM.COMMON.LABEL.MODULE_MODULE': 'Modul',
    'SM.ORGSTAFF.TITLE.STAFF_LOGIN_RESTRICT': 'Eintragungsbeschränkung',
    'SM.COMMON.LABEL.MODULE_ADDRESS': 'URL',
    'SM.ORGSTAFF.TITLE.CURRENCY_DISPLAY_FORMAT': 'Währungsanzeigeformat',
    'SM.COMMON.LABEL.MEASUREMENT_IMPORT_RESULT': 'Ergebnis importieren',
    'SM.ORGSTAFF.OPTION.STAFF_IP_SEGMENT': 'IP-Adresssegment',
    'SM.COMMON.TITLE.ACCESS.SELECT_STAFF': 'Zugriffsmitarbeiter auswählen',
    'SM.COMMON.MESSAGE.SYSMENU_ACCESS_TIME_ERROR': 'Die Endzeit der Menüzugriffszeit sollte größer als die Startzeit sein.',
    'SM.ORGSTAFF.MESSAGE.CONTAINS_SUB_ORGS': 'Die Organisation enthält Unterorganisationen.',
    'SM.ORGSTAFF.TITLE.ORG_ACCESS_EMP': 'Auf zugewiesenen Mitarbeiter zugreifen',
    'SM.COMMON.LABEL.BANKCARD_ACCT_LENG': 'Länge der Kartennummer',
    'SM.COMMON.LABEL.SYSMENU_VISITCOUNT': 'Besuchsanzahl',
    'SM.ORGSTAFF.BUTTON.OURELATIONSHIP_RELOAD': 'Aktualisieren',
    'SM.COMMON.LABEL.OPERLOG_ACCOUNT': 'Konto',
    'SM.COMMON.LABEL.BANKCARD_SEARCH_CONDITION': 'Suchbedingung',
    'SM.COMMON.LABEL.ATTACHMENT_ADD_ACCESSORY': 'Neu',
    'SM.COMMON.MESSAGE.CHARGECODE_TO_TEST': 'Möchten Sie ihn wirklich in den Teststatus ändern?',
    'SM.COMMON.LABEL.SYSMENU_MENUURL': 'Menü-URL',
    'SM.ORGSTAFF.AUTHTYPE.LABEL': 'Authentifizierungstyp',
    'SM.COMMON.LABEL.ERATE_TODATE': 'an',
    'SM.COMMON.TIPS.HOME_PHONE': 'Bitte geben Sie die richtige Telefonnummer ein, z. B. (+)(0123-)1234567890(-0123)',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAMINFO': 'Teaminformationen',
    'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_TITLE': 'Währungsmaßeinheit',
    'SM.COMMON.LABEL.SURVEY_CLASS_Code': 'Klassifizierungscode',
    'SM.COMMON.LABEL.SEARCHCONDITION': 'Suchkriterien',
    'SM.ORGSTAFF.MESSAGE.SAVESUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.TENANTSPACE.LDAP_DOMAIN': 'Domäne',
    'SM.ORGSTAFF.LABEL.ORGANIZATION': 'Organisationseinheit',
    'SM.COMMON.BUTTON.ADD_MENU_ACCESS_TIME': 'Menüzugriffszeit hinzufügen. Wenn nicht konfiguriert, kann jederzeit auf das Menü zugegriffen werden.',
    'SM.AUC.LABEL.BANK_NAME_INPUT': 'Name der Bank eingeben',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_SUCCESS': 'Das Mitarbeiterkennwort wurde erfolgreich geändert. Folgendes kann nicht geändert werden:',
    'SM.ORGSTAFF.LABEL.ORGIMPORT_TIP': 'Stellen Sie sicher, dass die Daten des Transportunternehmens in das System importiert wurden und der aktuelle Betreiber der Administrator ist.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD': 'Der Wert darf nur Ziffern, Buchstaben, Unterstriche (_), Bindestrichen (-), Punkte (.), und at-Zeichen enthalten ({@}).',
    'SM.COMMON.LABEL.MENU_INFO_TITLE': 'Menü Grundlegende Informationen',
    'SM.COMMON.LABEL.HOLIDAY_YEAR': 'Jahr',
    'SM.COMMON.MESSAGE.DELETE_CONFIRM': 'Möchten Sie wirklich löschen?',
    'SM.COMMON.LABEL.SYSMENU_ACTIVE_A': 'Aktivieren',
    'SM.COMMON.LABEL.HOLIDAY_SATURDAY': 'Sat',
    'SM.COMMON.BUTTON.SURVEY_ENABLE': 'Aktiviert',
    'SM.ORGSTAFF.TITLE.OPERATION': 'Operation',
    'SM.ORGSTAFF.MESSAGE.FORBID_EXSIT_EMPLOYEE': 'Die Abteilung konnte nicht dauerhaft deaktiviert werden, da der Abteilung Mitarbeiter unterstellt sind.',
    'SM.ORGSTAFF.LABEL.AUTHVIEW_AUTH_BENAME': 'Heimat-BE der Genehmigung',
    'SM.COMMON.TIPS.MENU_REBUILD': 'Informationen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_SUSPEND': 'Aussetzen',
    'SM.ORGSTAFF.LABEL.REGION_SHORTNAME': 'Kurzbezeichnung der Region',
    'SM.ORGSTAFF.LABEL.STAFF_ADD_ROLE': 'Beziehungsrolle hinzufügen',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTID': 'Anhang',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_NOT_ALLOW_4A': 'Direkte Webseitenanmeldung zulassen',
    'SM.COMMON.LABEL.CURRENCY_TYPE_NAME': 'Name',
    'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_ALLOWED': 'Zulassen',
    'SM.COMMON.LABEL.OPERLOG_RESET': 'Zurücksetzen',
    'SM.COMMON.LABEL.CHARGECODE_NAME': 'Name',
    'SM.ORGSTAFF.LABEL.STAFF_DESC': 'Beschreibung',
    'SM.ORGSTAFF.LABEL.TEAM_DESCRIPTION': 'Beschreibung',
    'SM.COMMON.MESSAGE.DATADICT_DELETE_FAILED': 'Löschen fehlgeschlagen.',
    'SM.COMMON.LABEL.BANKCARD_NAME': 'Kartenname',
    'SM.COMMON.BUTTON.CANCEL': 'Abbrechen',
    'SM.COMMON.LABEL.ATTACHMENT_DEL_SUCCESS': 'Zusatzdatei erfolgreich gelöscht',
    'SM.ORGSTAFF.LABEL.TO_BE_ASSIGNED_ROLE': 'Rolle zugewiesen werden',
    'SM.COMMON.LABEL.SYSMENU_STATUS': 'Status',
    'SM.ORGSTAFF.OPTION.STAFF_RETAIN_PERMISSION': 'Genehmigung des Zielbetreibers einbehalten',
    'SM.COMMON.LABEL.MEASUREMENT_ENTITY_ID': 'ID',
    'SM.COMMON.LABEL.MEASUREMENT_UNIT_SUNIT': 'Standardeinheit',
    'SM.COMMON.MESSAGE.PROPERTY_SUCCESS_CREATE_INFO': 'Vorgang erfolgreich ausgeführt.',
    'SM.ORGSTAFF.BUTTON.TEAM_OK': 'OK',
    'SM.COMMON.MESSAGE.CHARGECODE_DELETE_DATA': 'Möchten Sie den Gebührencode wirklich löschen?',
    'SM.COMMON.MESSAGE.VALIDATE_RANGELENGTHB': 'Geben Sie einen Wert von 0 bis 1 Zeichen ein.',
    'SM.COMMON.LABEL.OPERLOG_OBJECT_TYPE': 'Objekttyp',
    'SM.COMMON.BUTTON.BANKCARD_CANCEL': 'Abbrechen',
    'SM.ORGSTAFF.TITLE.BE_ADDRESSSETTING': 'Adresse',
    'SM.ORGSTAFF.LABEL.ORG_EMPLOYEE_QUERY_INFO': 'Suchkriterien',
    'SM.COMMON.LABEL.MEASUREMENT_EXPORT_MEA': 'Maßnahme',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLODATPERSON': 'Hochgeladen von',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_SEARCH': 'Suche',
    'SM.COMMON.BUTTON.MENUVSEARCH': 'Menüindex neu erstellen',
    'SM.ORGSTAFF.TITLE.CONTACT_HOME_FAX': 'Privatfax',
    'SM.COMMON.LABEL.ERATE_DCURRENCY_CODE': 'Zielwährungscode',
    'SM.COMMON.LABEL.CREATE_OPER': 'Erstellt von',
    'SM.COMMON.LABEL.CURRENCY_DISPLAY_POSTFIX': 'Postfix anzeigen',
    'SM.ORGSTAFF.LABEL.STAFF_ROLE_DESC': 'Beschreibung',
    'SM.ORGSTAFF.TITLE.ADDRESS_DISPLAY_FORMAT': 'Adressanzeigeformat',
    'SM.ORGSTAFF.TITLE.ADDR_NAME': 'Name',
    'SM.COMMON.MESSAGE.SYSMENU_DELSUCCESS': 'Erfolgreich gelöscht.',
    'SM.COMMON.LABEL.MEASUREMENT_ENTITY_CODE': 'Gerätecode',
    'SM.ORGSTAFF.LABEL.INPUT_ROLE_NAME': 'Bitte geben Sie den Rollennamen ein',
    'SM.ORGSTAFF.TIPS.DESC': 'Geben Sie keine Sonderzeichen ein <>',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD': 'Das Mitarbeiterkennwort konnte nicht bestätigt werden.',
    'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_FAX': 'Bürofax',
    'SM.COMMON.LABEL.CURRENCY_FORMAT_DISPLAY': 'Vorschauformat',
    'SM.ORGSTAFF.AUTHTYPE.LDAP': 'LDAP',
    'SM.PROJECT.TITLE.IMPORT_ORG_PAR': 'Par-Paket importieren',
    'SM.ORGSTAFF.MESSAGE.DSTFLAG_WITHOUT_TIMEZONE': 'Keine Zeitzone kann die Sommerzeit nicht öffnen.',
    'SM.COMMON.LABEL.MEASUREMENT_CONV_EDIT': 'Umrechnung von Maßeinheiten ändern',
    'SM.ORGSTAFF.BUTTON.BE_COPYPARENT': 'Daten des übergeordneten Knotens kopieren',
    'SM.ORGSTAFF.LABEL.ORG_FORBID': 'Deaktivieren',
    'SM.ORGSTAFF.LABEL.STAFF_ROLE_TYPE': 'Rollentyp',
    'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_ID': 'ID',
    'SM.COMMON.LABEL.OPERLOG_STARTTIME': 'Startzeit',
    'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_TIMESAME': 'Die Start- und Endzeit einer Zeitzone darf nicht identisch sein.',
    'SM.COMMON.LABEL.HOLIDAY_SUNDAY': 'Sonne',
    'SM.COMMON.TIPS.ERATE_ERATE_TIPS': 'Geben Sie eine positive numerische Zeichenfolge mit maximal 13 ganzen Stellen und 6 Dezimalstellen ein, wobei die letzte nicht 0 sein darf.',
    'SM.ORGSTAFF.LABEL.REGION_CODE': 'Regionscode',
    'SM.ORGSTAFF.TITLE.REGION_ADMINREGION': 'Verwaltungsregion',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_NATIONALITY': 'Nationalität',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_VALUE_TYPE': 'Werttyp',
    'SM.COMMON.MESSAGE.OPERATESUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.ORGSTAFF.TITLE.SUCCESS': 'Informationen',
    'SM.COMMON.LABEL.MEASUREMENT_UNIT_RATIO': 'Verhältnis der Messeinheit',
    'SM.COMMON.TITLE.SURVEY_QUESTION_NAME_INFO': 'Informationen zum Fragennamen',
    'SM.COMMON.BUTTON.RESET': 'Zurücksetzen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_PASSWORD': 'das aktuelle Kennwort ',
    'SM.COMMON.BUTTON.ADD_ADD': 'Hinzufügen',
    'SM.COMMON.LABEL.ATTACHMENT_SAVE_SUCCESS': 'Anhang erfolgreich hinzugefügt.',
    'SM.ORGSTAFF.TITLE.TEAM_NAMEEXIST': 'Der Teamname existiert bereits.',
    'SM.ORGSTAFF.LABEL.TEAM_ACCOUNTRANGE': 'Kontobereich',
    'SM.COMMON.BUTTON.CHARGECODE_SAVE': 'Speichern',
    'SM.COMMON.LABEL.OPERLOG_EMPLOYEE_NAME_NORMAL': 'Name des Mitarbeiters',
    'SM.COMMON.LABEL.BANKCARD_BANKCODE': 'Kartenausstellende Bankleitzahl',
    'SM.COMMON.LABEL.SURVEY_INTERLOCUTION': 'Fragen und Antworten',
    'SM.ORGSTAFF.LABEL.ORG_IMPORT': 'Importieren',
    'SM.COMMON.BUTTON.MEASUREMENT_DELETE': 'Löschen',
    'SM.COMMON.MESSAGE.CHARGECODE_TO_RELEASE': 'Möchten Sie ihn wirklich in den Status Release ändern?',
    'SM.COMMON.LABEL.CHARGECODE_FORBIDEN_BE': 'Verbotenes BE',
    'SM.COMMON.MESSAGE.OPERATE_FAIL': 'Operation fehlgeschlagen.',
    'SM.COMMON.MESSAGE.CURRENCY_MEASUREMNET_CONFIRMCOVERMSG': 'Der aktuelle Währungstyp verfügt bereits über eine Standard-Währungseinheit oder eine Mindest-Währungseinheit. Möchten Sie wirklich fortfahren?',
    'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_RECOVER': 'Nach der Wiederaufnahme stehen die Organisationsbeziehung und ihre untergeordnete Beziehung zur Verfügung. Möchten Sie die Wiederaufnahme bestätigen?',
    'SM.ORGSTAFF.LABEL.ACCESS.USER_REQUIRED': 'Wählen Sie einen Mitarbeiter aus.',
    'SM.ORGSTAFF.TITLE.REGION_IPRANGE': 'IP-Segment',
    'SM.COMMON.LABEL.SURVEY_CLASS_SORT_DESCRIPTION': 'Der Eingabebereich ist 1-100',
    'SM.ORGSTAFF.BUTTON.REMOVE_OUT': 'Entfernen',
    'SM.ORGSTAFF.TITLE.ADDR_DESCRIPTION': 'Beschreibung',
    'SM.ORGSTAFF.TIPS.LOGINID': 'Geben Sie keine Sonderzeichen ein außer @.',
    'SM.ORGSTAFF.TITLE.COMMON_QUERYCONDITION_TITLE': 'Suchkriterien',
    'SM.ORGSTAFF.LABEL.ORG_SELECTREGION': 'Wählen Sie eine Region aus',
    'SM.ORGSTAFF.MESSAGE.ERROR.SAVE': 'Fehler',
    'SM.ORGSTAFF.MESSAGE.BE_SELECTREGION': 'Wählen Sie eine Region aus',
    'SM.ORGSTAFF.MESSAGE.BE_MAKE_SURE_DELETE': 'Möchten Sie das Mitglied wirklich löschen?',
    'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_CONFIRM': 'Möchten Sie wirklich löschen?',
    'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPEXIST': 'Die IP-Adresse ist doppelt vorhanden.',
    'SM.COMMON.TITLE.SUCCESS': 'Informationen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_IS_LOCKED': 'Der Mitarbeiter wurde gesperrt.',
    'SM.ORGSTAFF.TITLE.ASSIGNE_EMPLOYEE': 'Mitarbeiter zuweisen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PWDRULEGROUP': 'Regelgruppe für Konto und Kennwort',
    'SM.COMMON.BUTTON.MEASUREMENT_EXPORT': 'Exportieren',
    'SM.ORGSTAFF.BUTTON.EMPLOYEE_SELECTORG': 'Organisationseinheit auswählen',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTDESC': 'Beschreibung',
    'SM.ORGSTAFF.TITLE.IMPORT_MAC_TITLE': 'Einschränkung der Anmeldung importieren',
    'SM.ORGSTAFF.OPTION.WEEKLIST_FTH': 'Vierter',
    'SM.COMMON.MESSAGE.SAVESUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEE': 'Mitarbeiter',
    'SM.ORGSTAFF.BUTTON.FORBID_FOREVER': 'Für immer deaktivieren',
    'SM.COMMON.LABEL.CURRENCY_GROUPSIZE': 'Gruppengröße',
    'SM.COMMON.LABEL.OPERLOG_OBJECT_ID': 'Objekt-ID',
    'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP': 'Fragebogen',
    'SM.COMMON.LABEL.OPERLOG_RESULT': 'Operationsergebnis',
    'SM.COMMON.MESSAGE.OPERATEFAIL': 'Operation fehlgeschlagen.',
    'SM.ORGSTAFF.MESSAGE.BE_SUBBE': 'Für die BU wurden BUs, OUs, Teams oder Rollen auf niedrigerer Ebene konfiguriert.',
    'SM.ORGSTAFF.MESSAGE.STAFF_INFOAUTH_CONFIRM': 'Bitte bestätigen Sie, dass die eingegebenen Mitarbeiterdaten von mir autorisiert wurden.',
    'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_SUSPEND': 'Nach der Aussetzung sind die Organisationsbeziehung und ihre untergeordnete Beziehung nicht mehr verfügbar. Bestätigen Sie die Aussetzung?',
    'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION': 'Frage löschen',
    'SM.ORGSTAFF.LABEL.STAFF_LOCALE': 'Standardsprache',
    'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_STATUS': 'Status',
    'SM.ORGSTAFF.MESSAGE.SRC_ACCOUNT': 'Das Quellkonto existiert nicht.',
    'SM.ORGSTAFF.MESSAGE.CONTACT_ACCOUNT': 'Konto',
    'SM.COMMON.LABEL.ACCESS_RELATION': 'Zugangsfähige Organisationen',
    'SM.COMMON.TIPS.SPECIAL_CHAR_AVAILABLE': 'Der Eingabewert muss eine Ziffer, ein Alpha-Zeichen oder ein _.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_CREATE_FAILD': 'Der Mitarbeiter konnte nicht erstellt werden. Bitte bestätigen Sie die Kontoinformationen.',
    'SM.ORGSTAFF.OPTION.WEEKDAY_TUE': 'Dienstag',
    'SM.ORGSTAFF.BUTTON.CANCEL': 'Abbrechen',
    'SM.COMMON.BUTTON.BANKCARD_SAVE': 'Speichern',
    'SM.COMMON.TITLE.ERROR': 'Fehler',
    'SM.ORGSTAFF.LABEL.REGION_DST_STARTDATE': 'Startdatum Sommerzeit',
    'SM.COMMON.LABEL.AREANAME': 'Bereichsname',
    'SM.ORGSTAFF.LABEL.TEAM_TEAMCODE': 'Teamcode',
    'SM.COMMON.LABEL.MEASUREMENT_ENTITY_NAME': 'Name der Einheit',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_ORG': 'Roam-Organisation',
    'SM.COMMON.LABEL.BANKCARD_BANKCODE_PARAM': 'Kartenausstellende Bankleitzahl',
    'SM.ORGSTAFF.TITLE.CONTACT_MOBILE_PHONE': 'Mobiltelefonnummer',
    'SM.ORGSTAFF.LABEL.ORG_CHANNEL_CLASS': 'Kanalklassifizierung',
    'SM.COMMON.BUTTON.TOP': 'Nach oben',
    'SM.AUC.LABEL.BANK_LIST': 'Bankenliste',
    'SM.COMMON.TITLE.MEASUREMENT_STDLIST': 'Liste der Messsysteme',
    'SM.COMMON.LABEL.ERATE_RANGEDATE': 'Gültigkeitsdauer',
    'SM.COMMON.LABEL.BANKCARD_OPERATION': 'Operation',
    'SM.ORGSTAFF.TITLE.BE_INFORMATION': 'Informationen',
    'SM.ORGSTAFF.OPTION.MONTH_12': 'Dezember',
    'SM.ORGSTAFF.OPTION.MONTH_11': 'November',
    'SM.ORGSTAFF.MESSAGE.TIMEZONE_TIME_ERROR': 'Die Startzeit kann nicht größer oder gleich der Endzeit sein.',
    'SM.ORGSTAFF.LABEL.REGION_LAYER': 'Regionsebene',
    'SM.ORGSTAFF.OPTION.MONTH_10': 'Oktober',
    'SM.ORGSTAFF.OPTION.WEEKDAY_SAT': 'Samstag',
    'SM.ORGSTAFF.LABEL.REGION_NAME': 'Regionsname',
    'SM.COMMON.MESSAGE.SYSMENU_CONFIRM_MODIFY_MENU_STATUS_TO': 'Sind Sie sicher, dass Sie:',
    'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG_PARAM': 'Länge der kartenausstellenden Bankkennung',
    'SM.COMMON.BUTTON.CHARGECODE_EDIT': 'Ändern',
    'SM.ORGSTAFF.LABEL.BE_ADDRESS_DISPLAYFORMAT': 'Adressanzeigeformatvorlage',
    'SM.COMMON.LABEL.HOLIDAY': 'Urlaub',
    'SM.ORGSTAFF.LABEL.ORG_CODE': 'OU-Code',
    'SM.ORGSTAFF.LABEL.USER_COUNT': 'Benutzeranzahl',
    'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_ERROR': 'Bitte wählen Sie die korrekte Standardmessung.',
    'SM.COMMON.TIPS.DIGITS_ONLY': 'Bitte geben Sie Ziffern ein.',
    'SM.ORGSTAFF.TITLE.ADDR_LINE_NUMBER': 'Zeilennummer',
    'SM.COMMON.LABEL.BANKCARD_BANKNAME': 'Name der kartenausstellenden Bank',
    'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_ERROR': 'Löschfehler.',
    'SM.ORGSTAFF.TITLE.REGION_TIMEZONE': 'Zeitzone',
    'SM.COMMON.LABEL.OPERLOG_FAILUER': 'Versagen',
    'SM.COMMON.LABEL.HIGHRISKLOG': 'Überwachung von Vorgängen mit hohem Risiko',
    'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_NAME': 'Einheit',
    'SM.ORGSTAFF.TITLE.CONTACT': 'Kontakt',
    'SM.ORGSTAFF.LABEL.BE_CODE': 'BE-Code',
    'SM.COMMON.LABEL.SURVEY_SINGAL_CHOOSE': 'Einzige Wahl',
    'SM.ORGSTAFF.LABEL.BE_SHORT_NAME': 'BE-Kurzname',
    'SM.ORGSTAFF.TITLE.CONTACT_ID': 'Kontakt-ID',
    'SM.ORGSTAFF.LABEL.TEAM_CODE': 'OU-Code',
    'SM.COMMON.MESSAGE.MODULE_MODULEADDRESSNOTMATCH': 'Die Moduladresse stimmt nicht überein.',
    'SM.COMMON.LABEL.CHARGECODE_RELEASE': 'Freigabe',
    'SM.COMMON.TITLE.SYSMENU_MENUNAME': 'Informationen zum Menünamen',
    'SM.COMMON.LABEL.SYSMENU_SELECTORGANIZATION': 'Berechtigung auswählen',
    'SM.ORGSTAFF.LABEL.TEAM_SEARCH': 'Suche',
    'SM.ORGSTAFF.TITLE.CHARGE_CODE': 'Gebührencode',
    'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_INPUT': 'Geben Sie einen Organisationsnamen ein',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT': 'Konto',
    'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_AND_SUB': 'Ausgewähltes Element exportieren',
    'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION_TYPE': 'Neuer Fragentyp',
    'SM.ORGSTAFF.LABEL.BE_LANGUAGE': 'Sprache',
    'SM.COMMON.LABEL.SYSMENU_SEARCH_RESULT': 'Suchergebnis',
    'SM.COMMON.LABEL.HEADING_SEARCHCRITERIA': 'Suchkriterien',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_TIMERANGEERROR': 'Endzeit der Anmeldung muss später sein als Beginn der Anmeldung.MAC-Adressbindung',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_FAILD': 'Mitarbeiterkennwort konnte nicht zurückgesetzt werden',
    'SM.ORGSTAFF.TITLE.STAFF_INFO_ADD': 'Mitarbeiter hinzufügen',
    'SM.ORGSTAFF.OPTION.EMPLOYEE_DELETE_ROLE': 'Rolle löschen',
    'SM.ORGSTAFF.TITLE.ADDRESS_FORMAT': 'Adressformat',
    'SM.ORGSTAFF.LABEL.FUNCAUTH_OPPAUTHNAME': 'Gegenseitig ausschließende Berechtigung',
    'SM.COMMON.LABEL.CURRENCY_DECIMALPOSITIONS': 'Dezimalstellen',
    'SM.ORGSTAFF.BUTTON.INCREASE': 'Hinzufügen',
    'SM.ORGSTAFF.LABEL.TEAM_PARENTORG': 'OU der oberen Ebene',
    'SM.ORGSTAFF.TITLE.NEW_ORG': 'Organisationseinheit erstellen',
    'SM.ORGSTAFF.TITLE.EDIT_ORG': 'Organisationseinheit bearbeiten',
    'SM.ORGSTAFF.MESSAGE.INPUT_ORG_NAME': 'Geben Sie einen Organisationseinheitsnamen ein',
    'SM.ORGSTAFF.TITLE.NEW_ACCESS_USER': 'Autorisierte Mitarbeiter hinzufügen',
    'SM.ORGSTAFF.TITLE.NEW_MANAGER': 'Manager hinzufügen',
    'SM.ORGSTAFF.TITLE.USER_ACCOUNT': 'Mitarbeiterkonto',
    'SM.ORGSTAFF.MESSAGE.SEARCH_USERNAME': 'Geben Sie einen Mitarbeiternamen ein',
    'SM.ORGSTAFF.TITLE.SELECT_ROLE': 'Rollen auswählen',
    'SM.ORGSTAFF.MESSAGE.FOLLOW_TENANT': 'Folgen Sie der Mandantenkonfiguration',
    'SM.ORGSTAFF.MESSAGE.SEARCH_ROLE_NAME': 'Geben Sie einen Rollennamen ein',
    'SM.ORGSTAFF.TIP.ACCESS_USER_DESC': 'Mitarbeiter, die nicht der Organisationseinheit angehören, aber auf Ressourcen der Organisationseinheit zugreifen und diese nutzen dürfen',
    'SM.COMMON.BUTTON.CHARGECODE_SUCCESS': 'Informationen',
    'SM.ORGSTAFF.LABEL.TEAM_MANAGER': 'Vorgesetzter',
    'SM.COMMON.LABEL.MEASUREMENT_EXPORT_COUNT': 'Ausfuhrmenge',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTH': 'Geburtsdatum',
    'SM.COMMON.LABEL.CREATE_TIME': 'Erstellt am',
    'SM.ORGSTAFF.TITLE.COMMON_CONTACT_INFORMATION': 'Kontaktinformationen',
    'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_PATTERN': 'Kopiermodus',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_IP': 'IP-Adresse',
    'SM.COMMON.LABEL.MEASUREMENT_NO': 'Nein',
    'SM.COMMON.TITLE.BANKCARD_ADD': 'Neue Bankkarte',
    'SM.ORGSTAFF.MESSAGE.STAFF_RESUME_CONFIRM': 'Sind Sie sicher, dass Sie das Mitarbeiterkonto fortsetzen möchten?',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_NAME': 'Mitarbeiter',
    'SM.ORGSTAFF.MESSAGE.SELECT_REGION': 'Wählen Sie eine Region aus.',
    'SM.ORGSTAFF.LABEL.DEPTIPRESTRICT': 'IP-Einschränkung',
    'SM.ORGSTAFF.LABEL.INPUT_REGION_NAME': 'Bitte geben Sie den Namen der Region ein',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_MIDDLENAME': 'Zwischenname',
    'SM.ORGSTAFF.TITLE.TEAM_OPERSUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.COMMON.BUTTON.IMPORT': 'Importieren',
    'SM.ORGSTAFF.LABEL.TEAM_TEAMNAME': 'Teamname',
    'SM.ORGSTAFF.MESSAGE.BE_COPYCURRENCY': 'Möchten Sie den übergeordneten Knoten wirklich kopieren?',
    'SM.ORGSTAFF.TITLE.REGION_ADDRESS': 'Adresse',
    'SM.ORGSTAFF.LABEL.STAFFAUTH_AUTHSOURCE': 'Berechtigungsquelle',
    'SM.ORGSTAFF.LABEL.BE_DESCRIPTION': 'Beschreibung',
    'SM.ORGSTAFF.LABEL.ORG_DELETE_CONFIRM': 'Nachdem eine Organisation gelöscht wurde, kann das entsprechende Konto der Organisation nicht wiederhergestellt, sondern nur neu erstellt werden. Löschen oder nicht?',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_NAME_EXIST': 'Der Mitarbeiter konnte nicht erstellt werden, da das Konto bereits im System vorhanden ist.',
    'SM.COMMON.LABEL.BANKCARD_BANKSIGN': 'ID der kartenausstellenden Bank',
    'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION_TYPE': 'Fragentyp löschen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_RESET': 'Zurücksetzen',
    'SM.COMMON.LABEL.NEW_CURRENCY_EXCHANGE_RATE': 'Neuer Wechselkurs',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_TO': 'An',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE': 'Speichern',
    'SM.COMMON.LABEL.OPERLOG_SUCCESS': 'Erfolg',
    'SM.COMMON.MESSAGE.DATADICT_DELETE_SUCCESSFULLY': 'Erfolgreich gelöscht.',
    'SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS': 'Möchten Sie die Organisation wirklich deaktivieren?',
    'SM.COMMON.TIPS.AREA_DELETE_SUCCESS': 'Erfolgreich gelöscht.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_FIRSTNAME': 'Vorname',
    'SM.ORGSTAFF.TITLE.ERROR': 'Fehler',
    'SM.ORGSTAFF.TITLE.REGION_ENDIP': 'End-IP',
    'SM.ORGSTAFF.LABEL.ORG_NAME': 'Name der Organisationseinheit',
    'SM.ORGSTAFF.LABEL.ORG_IMPORTDATA': 'Daten importieren',
    'SM.COMMON.LABEL.SURVEY_DRAFT': 'Entwurf',
    'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_NOTUSEPARENT': 'Wenn die Daten des übergeordneten Knotens gelöscht werden, wird die Konfiguration des übergeordneten Knotens nicht verwendet. Fortfahren?',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ORG': 'Eigentümerorganisation',
    'SM.ORGSTAFF.LABEL.BANK_CODE': 'Bankleitzahl',
    'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_PHONE': 'Telefonnummer des Büros',
    'SM.COMMON.BUTTON.ADD': 'Neu',
    'SM.COMMON.LABEL.THE_MENU': '?',
    'SM.ORGSTAFF.TIPS.UNLOCK.USER': 'Geben Sie das Kennwort des aktuellen Bedieners zur Bestätigung ein:',
    'SM.COMMON.MESSAGE.CURRENCY_DECIMALNOZEROFLAG': 'Letzte Nullen entfernen',
    'SM.COMMON.LABEL.ERATE_EFFDATE': 'Effektive Zeit',
    'SM.COMMON.LABEL.HOLIDAY_IMPORTDATA': 'Daten importieren',
    'SM.ORGSTAFF.LABEL.ONLINEOPERATORNUM': 'Gesamtzahl der Online-Betreiber',
    'SM.ORGSTAFF.LABEL.ORG_PARENTORG': 'Übergeordnete Organisationseinheit',
    'SM.COMMON.LABEL.BANKCARD_NAME_PARAM': 'Kartenname',
    'SM.COMMON.LABEL.CHARGECODE_DRAFT': 'Entwurf',
    'SM.COMMON.LABEL.OPERLOG_OPER_TYPE': 'Vorgangsart',
    'SM.COMMON.TITLE.MEASUREMENT_INFO': 'Grundlegende Informationen',
    'SM.ORGSTAFF.TITLE.REGION_STARTIP': 'IP starten',
    'SM.ORGSTAFF.LABEL.IMPORT_TEMPLATE': 'Dateivorlage importieren',
    'SM.COMMON.LABEL.SURVEY_TITLE_NAME': 'Fragenname',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_9': 'September',
    'SM.COMMON.LABEL.HOLIDAY_IMPORT': 'Feiertagsimport',
    'SM.TENANTSPACE.LDAP_BASICDN': 'Basis-DN',
    'SM.TENANTSPACE.LDAP_TITLE': 'LDAP-Authentifizierung',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_8': 'August',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_7': 'Juli',
    'SM.ORGSTAFF.LABEL.BE_NAME': 'Name des BE',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_6': 'Juni',
    'SM.COMMON.LABEL.CHARGECODE_SEARCH_CRITERIA': 'Suchkriterien',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_5': 'Mai',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_4': 'April',
    'SM.ORGSTAFF.OPTION.WEEKLIST_SEC': 'Zweite',
    'SM.ORGSTAFF.LABEL.WORKGROUP_NAME': 'Arbeitsgruppenname',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_3': 'März',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_2': 'Februar',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_1': 'Januar',
    'SM.ORGSTAFF.LABEL.ORG_STOP': 'Aussetzen',
    'SM.COMMON.TIPS.CURRENCY_GROUPSIZE_TIPS': 'Anzahl der durch Trennzeichen getrennten Ziffern in einem Betrag. Wert 0 gibt an, dass der Betrag nicht durch Trennzeichen getrennt ist.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL': 'Normal',
    'SM.COMMON.LABEL.BANKCARD_BANKNAME_PARAM': 'Name der kartenausstellenden Bank',
    'SM.ORGSTAFF.TITLE.REGION_EX_ATTR': 'Erweiterte Attribute',
    'SM.COMMON.TITLE.SUCCESS_SUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.COMMON.LABEL.MEASUREMENT_CONV_CREATE': 'Neue Messumrechnung',
    'SM.ORGSTAFF.LABEL.TEAM_TEAMID': 'Team-ID',
    'SM.ORGSTAFF.BUTTON.ORG_EDIT': 'Ändern',
    'SM.ORGSTAFF.TITLE.TEAM_ASSIGNEDEMPL': 'Zugewiesene Mitarbeiter (kann nicht mehr als 2000 Mitarbeiter binden.)',
    'SM.ORGSTAFF.TITLE.STAFF_INFO_EXT': 'Erweiterte Informationen',
    'SM.ORGSTAFF.TITLE.SELECT_ORG': 'Arbeitsgruppe auswählen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTHPLACE': 'Geburtsort',
    'SM.ORGSTAFF.MESSAGE.REFRESH_SUCCESS': 'Aktualisiert erfolgreich.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR': 'Der Wert darf nur Ziffern, Buchstaben, chinesische Zeichen, Unterstriche (_), Bindestrichen (-), Punkte (.), und at-Zeichen enthalten (@).',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_IP_ADDR': 'IP-Adresse',
    'SM.ORGSTAFF.TITLE.TEAM_DELETEFSUCCESS': 'Erfolgreich gelöscht.',
    'SM.ORGSTAFF.LABEL.ORG_INFO': 'OU-Info',
    'SM.COMMON.TIPS.CHARGECODE_NAME': 'Der Eingabewert darf keine Sonderzeichen enthalten.',
    'SM.COMMON.MESSAGE.SYSMENU_AUTH_ASSIGNED_MUTIL_MENUS': 'Die Berechtigung für das Menü wurde mehreren Menüs zugewiesen. Wenn Sie das Menü verwerfen, werden alle Berechtigungen widerrufen. Fortfahren?',
    'SM.COMMON.LABEL.MEASUREMENT_IGNORE_IMPORT': 'Wenn während des Batchdateiimports eine Datei nicht importiert werden kann, wird der Import nachfolgender Dateien fortgesetzt.',
    'SM.COMMON.TIPS.POPUP_LABEL_DELETE': 'Möchten Sie wirklich löschen?',
    'SM.ORGSTAFF.AUTHTYPE.DEFAULT': 'Standard',
    'SM.ORGSTAFF.LABEL.BE_BIZCODE': 'BE Business Code',
    'SM.COMMON.LABEL.SYSMENU_DIS_POSTION': 'Anzeigeposition',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID': 'Verbieten',
    'SM.ORGSTAFF.LABEL.CREATESOURCE': 'Quelle erstellen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_FORBIDFOREVER': 'Für immer deaktivieren',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_CLIENT': 'Client-IP',
    'SM.COMMON.TITLE.MEASUREMENT_UNIT': 'Messungstyp',
    'SM.ORGSTAFF.TIPS.STAFF_LOCALE': 'Standardanmeldesprache des Mitarbeiters, die verwendet wird, wenn die Sprache beim Anmelden nicht ausgewählt ist.',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_EXT_TITLE': 'Die Größe einer hochzuladenden Datei kann zwischen {1} KB und 0 KB liegen. {2} Dateien sind zulässig, {3} Dateien jedoch nicht.',
    'SM.SM.COMMON.TITLE.NO_CHARGECODE': 'Kein ChargeCode',
    'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_QUERY': 'Suche',
    'SM.ORGSTAFF.OPTION.WEEKLIST_FIV': 'Fünfter',
    'SM.ORGSTAFF.LABEL.ATTR_ATTRSETTING': 'Erweiterte Attribute',
    'SM.COMMON.LABEL.HIGHRISKLOG_SERVICENAME': 'Dienstname',
    'SM.ORGSTAFF.MESSAGE.AUTHENTICATION': 'Identität überprüfen',
    'SM.COMMON.MESSAGE.DATADICT_SURE_DELETE': 'Möchten Sie wirklich löschen?',
    'SM.ORGSTAFF.OPTION.WEEKLIST_FIR': 'Erstes',
    'SM.COMMON.BUTTON.COMMON_ADD': 'Hinzufügen',
    'SM.COMMON.LABEL.SYS_MEASUREMENT_METRIC': 'Metrische Systemmarkierung',
    'SM.ORGSTAFF.TIPS.BE_LOGO': 'Wählen Sie ein PNG-, GIF- oder JPG-Bild aus, dessen Größe 50 KB und 145*50 Pixel nicht überschreitet.',
    'SM.COMMON.BUTTON.CHARGECODE_ADDMEMBER': 'Gebührencode hinzufügen',
    'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_TAIL': 'Fragebogenschwanz',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_MATCH_VALUE': 'Übereinstimmungswert',
    'SM.ORGSTAFF.LABEL.ORG_SUBTYPE': 'Unter-OU-Typ',
    'SM.ORGSTAFF.MESSAGE.SELECT_ORG': 'Wählen Sie eine Organisationseinheit aus.',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA': 'Suchkriterien',
    'SM.COMMON.LABEL.SURVEY_ALLOW_INPUT': 'Manuelle Eingabe zulassen',
    'SM.ORGSTAFF.LABEL.BE_ID': 'BE-ID',
    'SM.ORGSTAFF.LABEL.ROLE_TYPE_DUTY': 'Pflichtrolle',
    'SM.COMMON.LABEL.SURVEY_MODIFIED_ACCOUNT': 'Geändert von',
    'SM.ORGSTAFF.LABEL.TEAM_TDISPLAYORDER': 'Anzeigereihenfolge',
    'SM.COMMON.LABEL.SYSMENU_LANGUAGE': 'Sprache',
    'SM.COMMON.LABEL.MENU_ACCESS_TIME': 'Menüzugriffszeit',
    'SM.COMMON.LABEL.ERATE_INFO_LIST': 'Währungswechselkursliste',
    'SM.ORGSTAFF.BUTTON.IMPORT': 'Importieren',
    'SM.COMMON.LABEL.MODULE_ID': 'ID',
    'SM.COMMON.LABEL.OPERLOG_OPER_TIME': 'Betriebszeit',
    'SM.COMMOM.MESSAGE.BESEXCEPTION': 'Der aktuelle Vorgang kann nicht abgeschlossen werden. Wenden Sie sich an den Administrator.',
    'SM.COMMON.LABEL.SURVEY_PROVINCE': 'Provinziell',
    'SM.ORGSTAFF.LABEL.STAFF_ACCOUNT': 'Konto',
    'SM.ORGSTAFF.BUTTON.RESET_TEAM': 'Zurücksetzen',
    'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID2': 'die größer als die maximale Anzahl ist, ist die maximale Anzahl:',
    'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID1': 'Die Anzahl der eigenen Aufgaben des Mitarbeiters in der Organisation:',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTCODE': 'Anhangstyp',
    'SM.ORGSTAFF.LABEL.ORG_FORCEDELETE': 'Endgültig löschen',
    'SM.ORGSTAFF.TITLE.CURRENCY_TYPE': 'Währungsart',
    'SM.COMMON.LABEL.HIGHRISKLOGList': 'Überwachung von Vorgängen mit hohem Risiko',
    'SM.COMMON.LABEL.SURVEY_TITLE_SORT': 'Fragensequenz',
    'SM.ORGSTAFF.LABEL.BANK_NAME': 'Name der Bank',
    'SM.COMMON.LABEL.CURRENCY_CREATE_TITLE': 'Grundlegende Informationen zum Währungstyp',
    'SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS': 'Einheit: Zweiter. Bereich: 1 bis 86400. Wenn dieser Parameter leer bleibt, wird der Standardwert 3600 verwendet.',
    'SM.COMMON.LABEL.SURVEY_MUTI_CHOOSE': 'Mehrfachauswahl',
    'SM.ORGSTAFF.OPTION.WEEKLIST_LST': 'Letztes',
    'SM.ORGSTAFF.TITLE.CURRENCY_EXCHANGE_RATE': 'Währungswechselkurs',
    'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG': 'Länge der kartenausstellenden Bankkennung',
    'SM.COMMON.LABEL.CHARGE_CODE_GROUP': 'Gebührencodegruppe',
    'SM.COMMON.LABEL.CHARGECODE_GROUPTYPE': 'Gruppentyp',
    'SM.COMMON.BUTTON.PROPERTY_CREATE_GROUP': 'Neue erweiterte Attributgruppen',
    'SM.COMMON.MESSAGE.DATADICT_SAVE_SUCCESSFULLY': 'Vorgang erfolgreich ausgeführt.',
    'SM.ORGSTAFF.TITLE.ADDR_CFG_LIST': 'Adressanzeigeformat',
    'SM.ORGSTAFF.OPTION.WEEKDAY_THU': 'Donnerstag',
    'SM.COMMON.LABEL.HOLIDAY_MONDAY': 'Mon',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_SIGNTIME': 'Anmeldezeit',
    'SM.COMMON.LABEL.OPERLOG_MODULE': 'Modul',
    'SM.COMMON.LABEL.SURVEY_OPTION_TYPE': 'Optionstyp',
    'SM.ORGSTAFF.LABEL.ADDR_ADDRESS_NAME': 'Adressname',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICT_MODE': 'Einschränkungsmodus',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAM': 'Arbeitsteam',
    'SM.ORGSTAFF.TITLE.CONTACT_INFO': 'Vorgesetzter',
    'SM.COMMON.LABEL.OPERLOG_OBJECT_TYP_NAME': 'Objekttypname',
    'SM.COMMON.LABEL.SYSMENU_SELECT_MENUS': 'Ausgewähltes Menü',
    'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION': 'Neue Frage',
    'SM.COMMON.LABEL.MENU_ACCESS_END_TIME': 'Endzeit',
    'SM.ORGSTAFF.LABEL.AFFILIATED_BE': 'Affiliated BE',
    'SM.COMMON.LABEL.AREAINFO_MAINTAIN': 'Bereichscodeverwaltung',
    'SM.ORGSTAFF.LABEL.REGION_LEVEL': 'Regionsebene',
    'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPFORMATERROR': 'Ungültige IP-Adresse',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_GENDER': 'Geschlecht',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_ALLSUCCESS': 'Mitarbeiterpasswort erfolgreich zurückgesetzt',
    'SM.ORGSTAFF.TIPS.ENTER_ORGNAME': 'Geben Sie den Arbeitsgruppennamen ein.',
    'SM.COMMON.LABEL.SYSMENU_STATUSDATE': 'Statusdatum',
    'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_RATE': 'Preis',
    'SM.ORGSTAFF.TITLE.CONFIRM_DEL': 'Bestätigen',
    'SM.COMMON.LABEL.COLON': ':',
    'SM.COMMON.BUTTON.ACCESS_RELATION': 'Zugangsorganisation hinzufügen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_MARITALSTATUS': 'Familienstand',
    'SM.COMMON.BUTTON.PROPERTY_CREATE': 'Neu',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE': 'Deaktiv',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_STARTDATE': 'Startdatum',
    'SM.COMMON.BUTTON.ATTACHMENT_ADDACCESSORY': 'Speichern',
    'SM.COMMON.LABEL.MEASUREMENT_SOUMEASYS': 'Quellmesssystem',
    'SM.COMMON.LABEL.ERATE_EXPDATE': 'Ablaufzeit',
    'SM.ORGSTAFF.MESSAGE.DELETE_TITLE': 'Bestätigen',
    'SM.COMMON.TITLE.PRJ_EXPORT': 'Haben mit dem Export begonnen',
    'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_TARGETNO': 'Zielkonto',
    'SM.ORGSTAFF.TITLE.ERROR_TITLE': 'Fehler',
    'SM.COMMON.MESSAGE.ERATE_TIME_COMPARE': 'Die Endzeit muss nach der Startzeit liegen.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET': 'Mitarbeiterpasswort zurücksetzen',
    'SM.ORGSTAFF.LABEL.ADDR_DISP_DETAIL_LIST': 'Detailliste formatieren',
    'SM.ORGSTAFF.MESSAGE.TEAM_BLANK_VIEW': 'Teams im Organisationsbaum verwalten.',
    'SM.COMMON.LABEL.BANKCARD_CREATETIME': 'Zeit hinzufügen',
    'SM.ORGSTAFF.OPTION.EMPLOYEE_ADD_ROLE': 'Rolle hinzufügen',
    'SM.ORGSTAFF.LABEL.STAFF_INFO_BASIC': 'Grundlegende Informationen',
    'SM.ORGSTAFF.OPTION.STAFF_IP_SINGLE': 'Einzelne IP-Adresse',
    'SM.COMMON.TITLE.CONFIRM_DEL': 'Bestätigen',
    'SM.ORGSTAFF.OPTION.MONTH_9': 'September',
    'SM.ORGSTAFF.OPTION.MONTH_8': 'August',
    'SM.COMMON.TITLE.SURVEY_CLASS_NAME_INFO': 'Klassifizierungsnamen-Info',
    'SM.ORGSTAFF.OPTION.MONTH_7': 'Juli',
    'SM.COMMON.MESSAGE.SYSMENU_AUTHIDREQUIRE': 'Geben Sie die Menüberechtigungs-ID ein.',
    'SM.ORGSTAFF.OPTION.MONTH_6': 'Juni',
    'SM.ORGSTAFF.OPTION.MONTH_5': 'Mai',
    'SM.ORGSTAFF.OPTION.MONTH_4': 'April',
    'SM.ORGSTAFF.TITLE.MEASUREMENT': 'Maßeinheit',
    'SM.ORGSTAFF.MESSAGE.ORG_NOT_EXSIT': 'Die Organisationseinheit existiert nicht.',
    'SM.ORGSTAFF.OPTION.MONTH_3': 'März',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME': 'Mitarbeiter',
    'SM.ORGSTAFF.OPTION.MONTH_2': 'Februar',
    'SM.COMMON.LABEL.CURRENCY_TYPE_ID': 'ID',
    'SM.ORGSTAFF.OPTION.MONTH_1': 'Januar',
    'SM.COMMON.TIPS.BANKCARD_LENGTH': 'Geben Sie eine Ganzzahl ein, die nicht mehr als 2 Stellen enthält.',
    'SM.ORGSTAFF.TITLE.TEAM_SAVESUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.ORGSTAFF.LABEL.BE_LOGO': 'Logo',
    'SM.ORGSTAFF.MESSAGE.MAX_TREE_LEVEL': 'Sie können nur bis zu 6 Ebenen von Unterorganisationen erstellen.',
    'SM.PROJECT.TITLE.EXPORT_SELECTED_EMPLOYEE': 'Ausgewähltes Element exportieren',
    'SM.ORGSTAFF.LABEL.TEAM_INCLUDESUB': 'Unterorganisationseinheit einschließen',
    'SM.ORGSTAFF.LABEL.ORG_LEVEL': 'OU-Ebene',
    'SM.ORGSTAFF.TITLE.EMPTY': 'E-Mail und ContactPhone dürfen nicht beide leer sein',
    'SM.ORGSTAFF.TITLE.COMMON_STAFF_INFO': 'Liste der Mitarbeiterkonten',
    'SM.COMMON.TITLE.ACCESS.SELECT_ALL_STAFF': 'Wählen Sie alle Mitarbeiter der Organisation aus',
    'SM.COMMON.LABEL.SYSMENU_IDEL': 'Freigabe',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTNAME': 'Name des Anhangs',
    'SM.COMMON.LABEL.SYSMENU_MENUID': 'Menü-ID',
    'SM.ORGSTAFF.LABEL.ROLETMPL_PERMISSION_NAME': 'Funktionsberechtigungsname',
    'SM.COMMON.LABEL.CURRENCY_TYPE_INPUT_TIPS': 'Geben Sie den Namen des Währungstyps ein',
    'SM.ORGSTAFF.LABEL.ADDR_ORDER': 'Anzeigereihenfolge',
    'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP_LIST': 'Fragebogenliste',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICTIONTYPE': 'Verarbeitungsmodus',
    'SM.COMMON.LABEL.MEASUREMENT_SOUMEAUNIT': 'Quellmesseinheit ',
    'SM.ORGSTAFF.MESSAGE.ERROR.SAVEFAILED': 'Speichern fehlgeschlagen',
    'SM.ORGSTAFF.LABEL.TEAM_ACCESS_EMPLOYEE': 'Besucherpersonal',
    'SM.ORGSTAFF.LABEL.NO': 'Nein',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_TERMINATE_SECONDMENT': 'Kündigung der Abordnung',
    'SM.COMMON.LABEL.HOLIDAY_TUESDAY': 'Tue',
    'SM.COMMON.LABEL.CHARGECODE_DESC': 'Beschreibung',
    'SM.ORGSTAFF.BUTTON.EMPLOYEE_EXPORTALL': 'Exportieren',
    'SM.ORGSTAFF.OPTION.QUERYSUBDEPT': 'Abfrage der unteren Abteilung',
    'SM.COMMON.LABEL.MEASUREMENT_DESTMEASYS': 'Ziel-Messsystem-',
    'SM.ORGSTAFF.MESSAGE.SELECTEDOU_CANNOT_SELECTED_AGAIN': 'Die ausgewählte Organisation kann nicht erneut ausgewählt werden.',
    'SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM': 'Nachdem ein Mitarbeiter gelöscht wurde, kann das entsprechende Konto des Mitarbeiters nicht wiederhergestellt, sondern nur neu erstellt werden. Löschen oder nicht?',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_BE': 'BE',
    'SM.ORGSTAFF.LABEL.REGION_DST_ENDTIME': 'Endzeit Sommerzeit',
    'SM.TENANTSPACE.LDAP_SERVERURL': 'Server-URL',
    'SM.ORGSTAFF.TITLE.BE_CURRENCYCODE': 'Währungscode',
    'SM.ORGSTAFF.TITLE.ATTR_ATTRSETTING': 'Erweitertes Attribut',
    'SM.COMMON.LABEL.BANKCARD_TYPE': 'Kartentyp',
    'SM.COMMON.MESSAGE.DELETE_SUCCESS': 'Erfolgreich gelöscht.',
    'SM.COMMON.TIPS.NEED_TO_NEW_STD_MIN_CURRENCY_UNIT': 'Die Standard-Währungseinheit und die kleinste Einheit müssen konfiguriert werden.',
    'SM.COMMON.LABEL.OPERLOG_OPER_LIST': 'Vorgangsprotokollliste',
    'SM.ORGSTAFF.LABEL.INH_BY_SUB': 'Vererbung durch untergeordnete Organisationseinheit zulassen',
    'SM.COMMON.LABEL.MEASUREMENT_ERROR_DESCRIPTION': 'Fehlerbeschreibung',
    'SM.COMMON.LABEL.SYSMENU_SUSPENDED_S': 'deaktivieren',
    'SM.COMMON.LABEL.ERATE_DESCRIPTION': 'Beschreibung',
    'SM.ORGSTAFF.TITLE.CHARGE_CODE_GROUP': 'Gebührencodegruppe',
    'SM.COMMON.TIPS.CURRENCY_DECIMALPOSITIONS_TIPS': 'Wert -1 gibt an, dass Dezimalstellen basierend auf der Genauigkeit der Währungsspeicherung angezeigt werden.',
    'SM.COMMON.LABEL.OPERLOG_CONTENT': 'Operationsinhalt',
    'SM.ORGSTAFF.LABEL.TEAM_MANAGERINFO': 'Informationen zum Vorgesetzten',
    'SM.COMMON.MESSAGE.MODIFY_AREAID_TYPE': 'Die Informationen existieren bereits. Ändern Sie die Ortskennzahl oder den Typ.',
    'SM.COMMON.LABEL.SYSMENU_ACTIVE': 'Aktivieren',
    'SM.ORGSTAFF.OPTION.YES': 'Ja',
    'SM.ORGSTAFF.LABEL.ORG_REGION': 'Region',
    'SM.ORGSTAFF.BUTTON.ADD_IN': 'Zusatzmodul',
    'SM.COMMON.LABEL.SYS_MEASUREMENT_DEFAULT': 'Standardmessung',
    'SM.ORGSTAFF.LABEL.STAFF_PASSWORD': 'Neues Kennwort',
    'SM.ORGSTAFF.LABEL.TEAM_ORGANIZATION': 'Organisationseinheit',
    'SM.COMMON.MESSAGE.CHARGECODE_TO_RETIRE': 'Möchten Sie ihn wirklich in den Status Abgebrochen ändern?',
    'SM.ORGSTAFF.LABEL.ACCESS.ORG_REQUIRED': 'Wählen Sie eine Organisation aus.',
    'SM.ORGSTAFF.TITLE.REGION_USEPARENT': 'Daten der übergeordneten Region übernehmen',
    'SM.ORGSTAFF.TITLE.CONTACT_MIDDLE_NAME': 'Zwischenname',
    'SM.ORGSTAFF.LABEL.STAFF_PASSWORDAGAIN': 'Passwort bestätigen',
    'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE': 'Menü',
    'SM.COMMON.LABEL.HIGHRISKLOG_PERATOR': 'Bediener',
    'SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER': 'Manager zuweisen',
    'SM.COMMON.BUTTON.MEASUREMENT_IMPORT': 'Importieren',
    'SM.COMMON.LABEL.ATTACHMENT_ADD_TITLE': 'Neuer Anhang',
    'SM.ORGSTAFF.MESSAGE.PWD.CHECKMSG': 'Die beiden Passwörter sind unterschiedlich.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_FORCE_DELETE': 'Löschen erzwingen',
    'SM.ORGSTAFF.BUTTON.TEAM_CANCEL': 'Abbrechen',
    'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT': 'Wählen Sie einen Mitarbeiter aus.',
    'SM.COMMON.LABEL.SYSMENU_DEACTIVATED_D': 'verwerfen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_RESUME': 'Lebenslauf',
    'SM.ORGSTAFF.MESSAGE.STAFF_PWD_RESET_CONFIRM': 'Möchten Sie das Kennwort wirklich zurücksetzen?',
    'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDESCRIPTION': 'Beschreibung',
    'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_ADD': 'Organisationseinheit auswählen',
    'SM.COMMON.LABEL.SYSMENU_CREATEDATE': 'Erstellungsdatum',
    'SM.COMMOM.BUTTON.IMPORT': 'Importieren',
    'SM.ORGSTAFF.TIPS.ENTER_EMPNAME': 'Bitte geben Sie den Namen des Mitarbeiters ein.',
    'SM.ORGSTAFF.LABEL.ORG_ORGID': 'Organisationseinheiten-ID',
    'SM.ORGSTAFF.MESSAGE.ADDR_USE_DELETE': 'Löschen fehlgeschlagen, da auf die Adresse verwiesen wurde.',
    'SM.ORGSTAFF.MESSAGE.BLANK_VIEW': 'Abteilungen im Organisationsbaum verwalten.',
    'SM.ORGSTAFF.TITLE.CONTACT_EMPLOYEE_CODE': 'Mitarbeiter kontaktieren',
    'SM.COMMON.MESSAGE.PROJECT_CODE': 'Der Projektcode lautet:',
    'SM.COMMON.LABEL.AREAID': 'Bereichs-ID',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLOADTIME': 'Hochladezeit',
    'SM.ORGSTAFF.LABEL.CHANNEL_ID': 'Kanal-ID',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_INVALID': 'Ungültig',
    'SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD': 'Bitte geben Sie ein Schlüsselwort ein',
    'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FILE_FORMAT': 'Format der exportierten Datei',
    'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FAILURE': 'Versagen',
    'SM.ORGSTAFF.MESSAGE.ORG_ROLE_DELETE': 'Wenn Sie diese Daten löschen, wird die Beziehung zwischen Benutzer und Rolle automatisch gelöscht. Fortfahren?',
    'SM.COMMON.LABEL.SURVEY_DESC': 'Beschreibung ',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PVT': 'Gültigkeitstyp des Kennworts',
    'SM.COMMON.LABEL.CHARGECODE_OPERATION': 'Operation',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTCODE': 'Anhangstyp',
    'SM.COMMON.LABEL.ERATE_SEARCH_CRITERIA': 'Suchkriterien',
    'SM.COMMON.LABEL.MODULE_MODULETYPE': 'Kategorie',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_CREATEDATE': 'Erstellungsdatum',
    'SM.COMMON.LABEL.OPERLOG_OBJECT_NAME': 'Objektname',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGIONRESTRICTION': 'Eintragungsbeschränkung',
    'SM.COMMON.LABEL.SYSMENU_PARENT': 'Übergeordnete Menü-ID',
    'SM.COMMON.TIPS.PLZ_SAVE_CURRENCY_MEASUREMENT_UNIT': 'Bitte speichern Sie die Währungseinheit.',
    'SM.COMMON.LABEL.CURRENCY_FORMAT_TITLE': 'Währungsanzeigeformat',
    'SM.COMMON.LABEL.SYSMENU_GRANT_MENU_AUTH': 'Menüberechtigung verwalten',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST': 'Anhangliste',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_MAC_ADDR': 'MAC-Adresse',
    'SM.COMMON.LABEL.MEASUREMENT_YES': 'Ja',
    'SM.ORGSTAFF.LABEL.ADDR_REGION_LAYER': 'Verwaltungsregionsebene',
    'SM.COMMON.TITLE.ACCESS.FILTER': 'Geben Sie Schlüsselwörter zum Filtern ein',
    'SM.ORGSTAFF.TITLE.TEAM_SUCCESS': 'Informationen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_TITLE': 'Titel',
    'SM.ORGSTAFF.OPTION.WEEKLIST_TID': 'Drittes',
    'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_DETAIL': 'Detail',
    'SM.ORGSTAFF.OPTION.WEEKDAY_WED': 'Mittwoch',
    'SM.ORGSTAFF.LABEL.ORG_RECOVER': 'Lebenslauf',
    'SM.ORGSTAFF.MESSAGE.DELETE_MSG': 'Möchten Sie es wirklich löschen?',
    'SM.ORGSTAFF.TITLE.BE_CURRENCYNAME': 'Währungsbezeichnung',
    'SM.COMMON.LABEL.CURRENCY_DISPLAY_PREFIX': 'Präfix anzeigen',
    'SM.COMMON.LABEL.ADD_EXCHANGE_RATE': 'Wechselkurs zugehöriger Währungen hinzufügen/anpassen.',
    'SM.COMMON.BUTTON.SAVE': 'Speichern',
    'SM.ORGSTAFF.LABEL.TEAM_REFRESH': 'Aktualisieren',
    'SM.COMMON.TITLE.ERATE_ERROR': 'Fehler',
    'SM.ORGSTAFF.LABEL.STAFF_ROLE_NAME': 'Rollenname',
    'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEENAME': 'Mitarbeiter',
    'SM.COMMON.LABEL.SURVEY_TYPE_NAME': 'Kategoriename',
    'SM.ORGSTAFF.LABEL.REGION_CONFIG_TIMEZONE': 'Die Zeitzoneninformationen der Region fehlen. Bitte konfigurieren Sie die Zeitzone für die Region oder die übergeordnete Region.',
    'SM.ORGSTAFF.TITLE.IMPORT_EMPLOYEE_TITLE': 'Mitarbeiter importieren',
    'SM.COMMON.LABEL.HIGHRISKLOG_PARAMS': 'Parameter',
    'SM.ORGSTAFF.LABEL.TEAM_ACCOUNT': 'Konto',
    'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_DISALLOWED': 'Ablehnen',
    'SM.ORGSTAFF.MESSAGE.BE_SELECT_DEFAULT_CURRENCY': 'Wählen Sie die Standardwährung aus.',
    'SM.COMMON.BUTTON.CHARGECODE_CONFIRM': 'Informationen',
    'SM.ORGSTAFF.TITLE.CONTACT_FIRST_NAME': 'Vorname',
    'SM.COMMOM.LABEL.SURVEY_INFO': 'Fragebogeninfo',
    'SM.ORGSTAFF.LABEL.BANK_CHILEBEUSED': 'Kind die Verwendung von BE erlauben',
    'SM.COMMON.LABEL.HEADING_BASEINFO': 'Grundlegende Informationen',
    'SM.ORGSTAFF.LABEL.TEAM_ADD': 'Neu',
    'SM.ORGSTAFF.TITLE.TEAM_AVAILABLEEMPL': 'Verfügbare Mitarbeiter',
    'SM.ORGSTAFF.LABEL.STAFF_INFO': 'Mitarbeiterinformationen',
    'SM.ORGSTAFF.LABEL.STAFF_ROLE_ID': 'Rollen-ID',
    'SM.COMMON.LABEL.SYSMENU_VERSION': 'Version',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_LASTNAME': 'Nachname',
    'SM.ORGSTAFF.LABEL.TEAM_BUSINESSENTITY': 'Name des BE',
    'SM.COMMON.LABEL.SYSMENU_CURRENCYLANGUAGE': 'Aktuelle Sprache',
    'SM.ORGSTAFF.TITLE.CONTACT_HOME_PHONE': 'Private Telefonnummer',
    'SM.ORGSTAFF.LABEL.CHANNEL_NAME': 'Kanalname',
    'SM.COMMON.MESSAGE.CHARGECODE_DELETE_GROUP': 'Möchten Sie die Gebührencodegruppe wirklich löschen?',
    'SM.COMMON.LABEL.AREATYPE': 'Bereichstyp',
    'SM.COMMON.LABEL.SYSMENU_IDEL_I': 'Freigabe',
    'SM.COMMON.MESSAGE.CURRENCY_UNIT_TIPS': 'Bitte geben Sie eine Nummer ein.',
    'SM.COMMON.LABEL.MEASUREMENT_ENTITY_TYPE': 'Einheitentyp',
    'SM.ORGSTAFF.LABEL.ORG_IMPORTSUCCESS': 'Daten erfolgreich importiert.',
    'SM.COMMON.TIPS.MENU_REBUILD_INFO': 'Die Informationen zur Neuerstellung des Menüindex wurden an die Nachrichtenwarteschlange gesendet. Bitte warten Sie.',
    'SM.ORGSTAFF.LABEL.SELECTED_ROLE': 'Ausgewählte Rolle',
    'SM.COMMON.LABEL.MEASUREMENT_CONV_RATIO': 'Verhältnis der Messeinheit',
    'SM.COMMON.LABEL.OPERLOG_SEARCH': 'Suche',
    'SM.COMMON.LABEL.SYSMENU_GRANT_AUTH': 'Berechtigung verwalten',
    'SM.ORGSTAFF.LABEL.BREGION_NAME': 'Region',
    'SM.ORGSTAFF.TITLE.BE_DEFAULT': 'Standardwährung',
    'SM.COMMON.TITLE.MEASUREMENT_UNITLIST': 'Liste der Maßeinheiten',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_BE': 'BE-ID',
    'SM.COMMON.LABEL.HIGHRISKLOG_OPERATIONNAME': 'Name der Operation',
    'SM.COMMON.MESSAGE.DELETE_CURRENCY_STD_MIN_MEASUREMENT': 'Für die Währung muss die Standardeinheit und die Mindesteinheit verwendet werden. Möchten Sie sie wirklich löschen?',
    'SM.ORGSTAFF.LABEL.STAFF_ROLE_PATH': 'Pfad',
    'SM.ORGSTAFF.TITLE.MSGBOX_INFO': 'Tipps',
    'SM.ORGSTAFF.OPTION.WEEKDAY_MON': 'Montag',
    'SM.COMMON.LABEL.CURRENCY_TYPE_FLAG': 'Virtuell',
    'SM.COMMON.LABEL.OPERLOG_LOGIN_IP': 'Anmelde-IP',
    'SM.COMMON.LABEL.CHARGECODE_GROUPMEMBER': 'Gebührencodes',
    'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_NAME_LANG': 'Sprache des Fragebogennamens',
    'SM.COMMON.LABEL.CURRENCY_ROUNDMODE': 'Rundungsmodus',
    'SM.ORGSTAFF.LABEL.ACCESS.ADD_SUCCESS': 'Hinzufügen erfolgreich.',
    'SM.COMMON.LABEL.SYSMENU_MENU_AUTH_ROLE_LIST': 'Liste der zugewiesenen Rollen',
    'SM.COMMON.TITLE.BANKCARD_CONFIRM': 'Informationen',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_12': 'Dezember',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_11': 'November',
    'SM.COMMON.LABEL.HOLIDAY_MONTH_10': 'Oktober',
    'SM.COMMON.LABEL.CHARGECODE_DATAGIRD_CATALOG': 'Katalog',
    'SM.COMMON.TIPS.ONLYONE_UNDERLINE_FORBIDDEN': 'Der Eingabewert darf keine Sonderzeichen enthalten.',
    'SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT': 'Bitte Namen eingeben',
    'SM.COMMOM.BUTTON.EDIT': 'Ändern',
    'SM.ORGSTAFF.MESSAGE.SUCCESS.SAVE': 'Erfolg',
    'SM.COMMON.LABEL.MEASUREMENT_NOTIFICATION_NAME': 'Name in Benachrichtigung',
    'SM.COMMON.LABEL.BANKCARD_BANKTYPE': 'Banktyp',
    'SM.ORGSTAFF.BUTTON.ADDR_LINE': 'Linie',
    'SM.ORGSTAFF.TITLE.TEAM_DELETE_CONFIRM': 'Bestätigen',
    'SM.COMMON.LABEL.CHARGECODE_RETIRE': 'Abbrechen',
    'SM.COMMON.TIPS.SPECIAL_CHAR_CANNOT_START_NUMBER': 'Es darf nicht mit einer Zahl begonnen werden.',
    'SM.COMMON.LABEL.CURRENCY_DISPLAY_FORMATE': 'Anzeigeformat',
    'SM.ORGSTAFF.LABEL.TEAM_CONTACTINFO': 'Kontaktinformationen',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTNAME': 'Name des Anhangs',
    'SM.ORGSTAFF.LABEL.ADDR_ADDERSS': 'Adresse',
    'SM.ORGSTAFF.MESSAGE.SURE_TO_DO': 'Fortfahren?',
    'SM.ORGSTAFF.LABEL.TEAM_TEAMSHORTNAME': 'Teamkurzname',
    'SM.ORGSTAFF.TITLE.BANK_TITLE': 'Bank',
    'SM.COMMON.MESSAGE.CHARGECODE_OPERATION_SUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.ORGSTAFF.MESSAGE.USERRESTRICT_CONFIRM_SAVE': 'Das Anmeldezeitsegment kann nur einen Datensatz speichern, und das andere Anmeldezeitsegment wird gelöscht, wenn es hinzugefügt wird.',
    'SM.COMMON.LABEL.BANKCARD_BANKSIGN_PARAM': 'ID der kartenausstellenden Bank',
    'SM.COMMON.BUTTON.SURVEY_NEW_ANSWER_OPTIONS': 'Neue Antwortoption',
    'SM.ORGSTAFF.TIPS.MAC_FORMAT_ERROR': 'MAC-Adressformatfehler',
    'SM.COMMON.LABEL.MODULE_BASICINFO': 'Grundlegende Informationen',
    'SM.ORGSTAFF.LABEL.REGION_DST_MODE': 'DST-Modus',
    'SM.COMMON.BUTTON.EDIT': 'Ändern',
    'SM.COMMON.MESSAGE.ERATE_VLUEW_RANGE': 'Der Wert liegt zwischen 0 und {1}.',
    'SM.COMMON.LABEL.PROVINCE': 'Provinz',
    'SM.ORGSTAFF.LABEL.STAFF_OPRATION': 'Operation',
    'SM.COMMON.LABEL.ATTACHMENT_LIMIT_INFO': 'Keine Einschränkung',
    'SM.COMMON.MESSAGE.BANKCARD_DELETE_CONFIRM': 'Möchten Sie wirklich löschen?',
    'SM.COMMON.LABEL.SYSMENU_DESCRIPTION': 'Menübeschreibung',
    'SM.COMMON.MESSAGE.CURRENCY_UNIT_EXCHAGE_TIPS': 'Bitte geben Sie eine ganze Zahl ein.',
    'SM.ORGSTAFF.MESSAGE.CHOOSE_BE_FIRST': 'Bitte wählen Sie zuerst BE',
    'SM.COMMON.LABEL.MODULE_NAME': 'Name',
    'SM.ORGSTAFF.TITLE.MEASUREMENT_CONV': 'Messumrechnung',
    'SM.COMMON.LABEL.SURVEY_REMARKS': 'Anmerkungen',
    'SM.ORGSTAFF.OPTION.ROLE_OPTION_MODE': 'Betriebsmodus',
    'SM.ORGSTAFF.LABEL.OU_HIERARCHY': 'OU-Hierarchie',
    'SM.COMMON.BUTTON.VIEW': 'Ansicht',
    'SM.COMMON.MESSAGE.CHARGECODE_CREATE_DATA': 'Möchten Sie diese Gebührencodes wirklich hinzufügen?',
    'SM.ORGSTAFF.BUTTON.ADD': 'Neu',
    'SM.ORGSTAFF.MESSAGE.STAFF_SUSPEND_CONFIRM': 'Möchten Sie das Mitarbeiterkonto wirklich sperren?',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_SVD': 'Sitzungs-Timeout-Intervall',
    'SM.ORGSTAFF.MESSAGE.BANK_STATUS_ERROR': 'Speichern fehlgeschlagen, da die Bankdaten abgelaufen sind.',
    'SM.COMMON.LABEL.HOLIDAY_BASICINFORMATION': 'Grundlegende Informationen',
    'SM.ORGSTAFF.LABEL.ORG_ADDITIONINFO': 'Weitere Informationen',
    'SM.ORGSTAFF.TITLE.BE_ADDRESS_CFG': 'Adressformat',
    'SM.ORGSTAFF.TITLE.STAFF_INFO_MDY': 'Mitarbeiter ändern',
    'SM.COMMON.TITLE.ACCESS.SELECT_ORG': 'Zugriffsorganisation auswählen',
    'SM.ORGSTAFF.MESSAGE.STAFF_FORBID_CONFIRM': 'Nachdem ein Mitarbeiter dauerhaft deaktiviert wurde, kann das Konto des Mitarbeiters erst dann wiederholt erstellt werden, nachdem es gelöscht wurde. Möchten Sie den Mitarbeiter wirklich dauerhaft deaktivieren?',
    'SM.ORGSTAFF.MESSAGE.ERROR.SAVEACCESSLIMIT': 'Zugangsfähige Mitarbeiter können bis zu 100 pro Stapel hinzufügen',
    'SM.COMMON.LABEL.BANKCARD_LIST': 'Bankkartenliste',
    'SM.COMMON.LABEL.OU_HIERARCHY_NAME': 'RelationShip-Name der Organisationseinheit',
    'SM.COMMON.MESSAGE.SYSMENU_MENUNAMEREQUIRE': 'Geben Sie den Menünamen ein.',
    'SM.COMMON.MESSAGE.MEASUREMENT_SAVE_CONFIRM': 'Wenn Sie eine Standardmaßeinheit speichern, ersetzt sie eine vorhandene Standardmaßeinheit und ändert die Standardeinheiten anderer Maßeinheiten.',
    'SM.COMMON.LABEL.SYSMENU_SEARCH_MENU': 'Suchkriterien',
    'SM.ORGSTAFF.TITLE.ADDR_TEMPATE_ID': 'Vorlagen-ID',
    'SM.ORGSTAFF.TITLE.ADDR_DISP_FORMAT': 'Anzeigeformat',
    'SM.ORGSTAFF.MESSAGE.STARTANDTEND_ISNULL': 'Das Start- und das Enddatum dürfen nicht gleichzeitig leer sein.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_TIMERANGE': 'Zeitbereich',
    'SM.ORGSTAFF.LABEL.SELECT_EXCEL': 'Wählen Sie eine Excel-Datei aus',
    'SM.ORGSTAFF.TITLE.EMP_BELONG_DEPT': 'Abteilung',
    'SM.ORGSTAFF.LABEL.BUSINESSENTITY_DISPLAY_NO': 'Anzeigereihenfolge',
    'SM.COMMON.BUTTON.CHARGECODE_CANCEL': 'Abbrechen',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_ADDR': 'Adresse',
    'SM.ORGSTAFF.LABEL.TEAM_TEAMTYPE': 'Teamtyp',
    'SM.COMMON.LABEL.SYSMENU_MENUCRUMB': 'Pfad',
    'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_NAME': 'Name des Fragebogens',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD_CONFIRM': 'Bestätigung des neuen Kennworts',
    'SM.COMMON.LABEL.CURRENCY_TYPE': 'Währungsart',
    'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_RESET': 'Zurücksetzen',
    'SM.COMMON.LABEL.MENU_ATTR_TITLE': 'Attribut erweitern',
    'SM.ORGSTAFF.LABEL.TEAM_REMOVE': 'Entfernen',
    'SM.ORGSTAFF.BUTTON.SAVE': 'Speichern',
    'SM.COMMON.LABEL.CHOOSE_OU_ADD': 'Wählen Sie die hinzuzufügende Organisationseinheit',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_COPYPERMISSION': 'Berechtigung zum Kopieren',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD41': 'Sperre aufgrund aufeinanderfolgender Anmeldefehler innerhalb des angegebenen Zeitraums in Minuten',
    'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPRANGEERROR': 'Das IP-Adresssegment ist falsch konfiguriert.',
    'SM.ORGSTAFF.MESSAGE.ERROR.MAXLIMIT': 'Die Anzahl der Einrichtungen unter einem Mieter darf 999 nicht überschreiten.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD40': 'Angepasste Regel',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_ACCOUNT': 'Konto',
    'SM.COMMON.LABEL.CREATE_AREAINFO_MAINTAIN': 'Informationen zur neuen Region',
    'SM.COMMON.TITLE.BANKCARD_MDF': 'Bankkarte ändern',
    'SM.ORGSTAFF.TIPS.RESTRICTMAC': 'Geben Sie die korrekte MAC-Adresse ein.',
    'SM.ORGSTAFF.TITLE.QUERYFAIL_TITLE': 'Suche fehlgeschlagen.',
    'SM.COMMON.BUTTON.CREATE': 'Neu',
    'SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB': 'Es sind maximal 0 Zeichen zulässig.',
    'SM.COMMON.LABEL.HOLIDAY_HOLIDAYTYPE': 'Typ',
    'SM.COMMON.LABEL.SURVEY_MODIFIED_TIME': 'Änderungszeit',
    'SM.COMMON.LABEL.OPERLOG_SUCCESS_MEW': 'Erfolg',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD39': 'Das Passwort darf nur Ziffern enthalten',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD38': 'Zeichen, die nicht in einem Passwort enthalten sein dürfen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD37': 'Das Passwort darf nicht mit dem Konto oder einer Teilmenge des Kontos identisch sein',
    'SM.COMMON.MESSAGE.SYSMENU_MAX_EDIT_LIMIT': 'Maximale Bearbeitungsgrenze.',
    'SM.COMMON.MESSAGE.CHARGECODE_TO_DRAFT': 'Möchten Sie ihn wirklich in den Status Entwurf ändern?',
    'SM.COMMON.BUTTON.BANKCARD_RESET': 'Zurücksetzen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD36': 'Liste verbotener Passwortformate, z. B. ABCABC und ABCDEF',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD35': 'Standardpasswort',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD34': 'Mindestgültigkeitsdauer des Kennworts in Stunden',
    'SM.COMMON.LABEL.MENU_ACCESS_STRART_TIME': 'Startzeit',
    'SM.COMMON.LABEL.MEASUREMENT_RESULT_LIST': 'Ergebnisliste',
    'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_SUCCESS': 'Erfolgreich gelöscht.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD33': 'Das Kennwort darf nicht aus aufeinanderfolgenden Zeichen oder Ziffern wie AB, 123, AA und abc bestehen',
    'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MIN': 'Die hochgeladene Datei ist kleiner als die Mindestgröße von 0 KB.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD32': 'Mindestanzahl von Zeichen jedes Typs, die in einem Kennwort enthalten sein müssen',
    'SM.ORGSTAFF.LABEL.ORG_TYPE': 'Organisationseinheitentyp',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD31': 'Mindestkontolänge',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD30': 'Maximale Kontolänge',
    'SM.COMMON.LABEL.OPERLOG_SERRCH_CRITERIA': 'Suchkriterien',
    'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG2': 'Tag(e).',
    'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG1': 'Der Zeitraum darf nicht überschreiten',
    'SM.COMMON.LABEL.CURRENCY_BASIC_INFO': 'Grundlegende Informationen',
    'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_DELETE': 'Nach der Deaktivierung werden die Organisationsbeziehung und ihre untergeordnete Beziehung gelöscht. Möchten Sie den Löschvorgang bestätigen?',
    'SM.COMMON.LABEL.ERATE_SCURRENCY_CODE': 'Quellwährungscode',
    'SM.COMMON.LABEL.SELECTED_OUS': 'Ausgewählte Organisationseinheiten',
    'SM.ORGSTAFF.LABEL.REGION_DST_STARTTIME': 'Startzeit Sommerzeit',
    'SM.ORGSTAFF.TITLE.POPUP_NEW_PROPERTY': 'Neue Eigenschaft',
    'SM.COMMON.LABEL.SURVEY_TITLE_TYPE': 'Fragentyp',
    'SM.ORGSTAFF.MESSAGE.DELETE_CONFIRM': 'Möchten Sie wirklich löschen?',
    'SM.ORGSTAFF.OPTION.NO': 'Nein',
    'SM.ORGSTAFF.LABEL.BE_TYPE': 'BE-Typ',
    'SM.COMMON.LABEL.OPERLOG_SESSION_ID': 'Sitzungs-ID',
    'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_MUSTEXIST': 'Das Maßsystem muss eine Standardmaßeinheit haben.',
    'SM.ORGSTAFF.OPTION.WEEKDAY_SUN': 'Sonntag',
    'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU': 'Effektive OU',
    'SM.ORGSTAFF.VALIDATOR.AFTER_TODAY': 'Die eingegebene Zeit muss vor oder gleich 00:00 Uhr heute sein.',
    'SM.SM.COMMON.TITLE.GROUP_NAME_EXIST': 'Der Benutzergruppenname wurde verschoben.',
    'SM.ORGSTAFF.LABEL.ORG_RECOVER_TIPS': 'Nach der Wiederaufnahme stünde die Organisation oder die Organisation und ihre untergeordnete Organisation zur Verfügung. Bestätigen Sie die Wiederaufnahme?',
    'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGINTIMERANGE': 'Anmeldezeitsegment',
    'SM.COMMON.LABEL.CURRENCY_MINIMUM_MEASUREMENT': 'Min. Einheitenflagge',
    'SM.ORGSTAFF.TITLE.BE_ADDRESS_INFO': 'Adressinformationen',
    'SM.ORGSTAFF.TIPS.ENTER_OUNAME': 'Geben Sie den Organisationsnamen ein.',
    'SM.COMMON.LABEL.SURVEY_SECTION': 'Regional',
    'SM.COMMON.TIPS.SPECIAL_CHAR_FORBIDDEN': 'Der Eingabewert darf keine Sonderzeichen enthalten.',
    'SM.SM.COMMON.TITLE.NO_CHINAWORD': 'Sonderzeichen sind nicht zulässig.',
    'SM.COMMON.LABEL.SYS_MEASUREMENT_NAME': 'Name',
    'SM.COMMON.LABEL.SURVEY_CREATED_ACCOUNT': 'Erstellt von',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD19': 'Erzwungene Kennwortänderung bei der ersten Anmeldung oder nach Zurücksetzen des Kennworts',
    'SM.COMMON.LABEL.MODULE_MODULELIST': 'Modulliste',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD18': 'Anzahl der Tage im Voraus, die die Benutzer über das Ablaufen des Kennworts informiert werden',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_HISTORY': 'Secondment History',
    'SM.COMMON.TITLE.SURVEY_ITEM_NAME_INFO': 'Informationen zum Artikelnamen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD15': 'Maximale Anzahl von Kennwortwiederholungsversuchen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD14': 'Maximale Gültigkeitsdauer des Kennworts in Tagen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD13': 'Das Passwort darf den Namen nicht enthalten',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD12': 'Das Passwort muss sich von dem Konto oder dem Konto in umgekehrter Reihenfolge unterscheiden',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD11': 'Ungültiges Kennwort',
    'SM.COMMON.LABEL.SURVEY_NOT_INPUT': 'Manuelle Eingabe nicht zulassen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD10': 'Gültige Sonderzeichen',
    'SM.COMMON.MESSAGE.SYSMENU_SAVESUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.ORGSTAFF.TITLE.CONTACT_LAST_NAME': 'Nachname',
    'SM.COMMON.LABEL.MEASUREMENT_CONV': 'Messumrechnung',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS': 'Status',
    'SM.COMMON.LABEL.MEASUREMENT_UNIT_STANDARD': 'Standardeinheitenmarkierung',
    'SM.ORGSTAFF.LABEL.REGION_DISPLAYORDER': 'Anzeigereihenfolge',
    'SM.ORGSTAFF.LABEL.TEAM_TO': 'An',
    'SM.COMMON.LABEL.SURVEY_CLASS_NAME': 'Kategoriename',
    'SM.COMMON.LABEL.BANKCARD_TYPE_PARAM': 'Kartentyp',
    'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_SOURCENO': 'Quellkonto',
    'SM.COMMON.LABEL.CURRENCY_GROUPSYMBOL': 'Trenner',
    'SM.ORGSTAFF.TITLE.STAFF_ROLE_BASIC': 'Rolle',
    'SM.ORGSTAFF.TITLE.ADDR_DISP_LIST': 'Adressanzeigeformatliste',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD09': 'Maximale Anzahl aufeinander folgender Zeichen in einem Kennwort',
    'SM.COMMON.LABEL.DATA_OPRATION': 'Operation',
    'SM.ORGSTAFF.LABEL.TEAM_EXTENDPRO': 'Erweiterte Attribute',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD08': 'Das Passwort muss sich von den letzten Passwörtern unterscheiden',
    'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_ID': 'Fragebogen-ID',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD07': 'Das Kennwort muss Sonderzeichen enthalten',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD06': 'Das Kennwort muss Kleinbuchstaben enthalten',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD05': 'Das Kennwort muss Großbuchstaben enthalten',
    'SM.COMMON.LABEL.MEASUREMENT_UNIT_CODE': 'Code',
    'SM.ORGSTAFF.MESSAGE.ADDR_LEVELS_HIGH': 'Die Adresse ist nicht sequenziell.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD04': 'Das Passwort muss Buchstaben enthalten',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD03': 'Das Passwort muss Ziffern enthalten',
    'SM.ORGSTAFF.LABEL.PARENT_BE': 'Eltern-BE',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD02': 'Mindestlänge des Passworts',
    'SM.ORGSTAFF.MESSAGE.SELECT_WG_INFO': 'Bitte wählen Sie eine Arbeitsgruppe aus.',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD01': 'Maximale Passwortlänge',
    'SM.COMMON.BUTTON.PROPERTY_CONFIRM': 'Informationen',
    'SM.COMMON.LABEL.EDIT_AREAINFO_MAINTAIN': 'Regionsinformationen ändern',
    'SM.COMMON.TITLE.PROPERTY_SUCCESS': 'Informationen',
    'SM.ORGSTAFF.LABEL.ORG_SELECTORG': 'Organisationseinheit auswählen',
    'SM.COMMON.TITLE.ITEM_NOTE': 'Bild anzeigen',
    'SM.COMMON.BUTTON.SEARCH': 'Suche',
    'SM.COMMON.LABEL.ERATE_EXCHANGE_RATE': 'Wechselkurs',
    'SM.ORGSTAFF.BUTTON.EMPLOYEE_DELETE': 'Löschen',
    'SM.COMMON.BUTTON.DELETE': 'Löschen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_MATCH_MODE': 'Zuordnungsmodus',
    'SM.ORGSTAFF.TITLE.WORKGROUP': 'Arbeitsgruppe',
    'SM.COMMON.LABEL.PROPERTY_CREATE_PROPERTY': 'Neues Attribut',
    'SM.ORGSTAFF.MESSAGE.RECOVERORG': 'Nehmen Sie die OU wieder auf.',
    'SM.AUC.LABEL.LOGINLOG_EMPLOYEE_NAME': 'Name des Mitarbeiters',
    'SM.AUC.LABEL.LOGINLOG_LOGIN_IP_ADDRESS': 'Anmelde-IP',
    'SM.AUC.LABEL.LOGINLOG_OPER_CONTENT': 'Operationsinhalt',
    'SM.AUC.LABEL.LOGINLOG_OPER_TIME': 'Betriebszeit',
    'common.bremui.title.operation': 'Operation',
    'SM.ORGSTAFF.HINT.PLEASE_INPUT': 'Bitte eingeben',
    'SM.MENU.SEARCH.INPUT': 'Suchmenüname',
    'SM.MENU.ADD.CUSTOM.MENU': 'Menü hinzufügen',
    'SM.MENU.ADD.CUSTOM.PERM_REPEAT': 'Doppelter Berechtigungsname',
    'SM.AUC.MESSAGE.PWDR_VALIDATE': 'Überprüfungsregel',
    'SM.AUC.MESSAGE.PWDR_MANAGE': 'Verwaltungsregel',
    'SM.AUC.MESSAGE.RULEGROUP': 'Regelgruppe',
    'SM.AUC.MESSAGE.RULEGROUPNAME': 'Regelgruppenname',
    'SM.AUC.MESSAGE.RULEGROUPList': 'Regelliste',
    'SM.AUC.MESSAGE.RULEGROUPListEDIT': 'Regelgruppe bearbeiten',
    'SM.AUC.MESSAGE.RULEGROUPListADD': 'Regelgruppe hinzufügen',
    'SM.AUC.MESSAGE.LOGIN.IP': 'Anmelde-IP-Adresse',
    'SM.AUC.MESSAGE.PWDRULE_ACCOUNT': 'Kontenregel',
    'SM.AUC.LABEL.PWD_PWDRULE': 'Kennwortregel',
    'SM.AUC.LABEL.PWD_CUSTOMIZEDRULE': 'Angepasste Regel',
    'SM.AUC.LABEL.KIND': 'Kleinanzeigen',
    'SM.AUC.LABEL.VALUE': 'Wert',
    'SM.MENU.DELETE.CUSTOM.ROLE.FAIL': 'Das Menü wurde erfolgreich gelöscht. Die mit dem Menü verknüpfte Berechtigung konnte nicht gelöscht werden. Überprüfen Sie, ob andere Menüs oder Rollen auf die Berechtigung verweisen.',
    'SM.AUC.ACCOUNT.LOCK.SEARCH.ACCOUNT': 'Suche nach einem Konto',
    'SM.AUC.ACCOUNT.LOCK.USER.NOT.EXIT': 'Der Benutzer existiert nicht',
    'SM.AUC.LABEL.LOGINSESSION_SESSION': 'Sitzungsstatus',
    'SM.AUC.LABEL.LOGINSESSION_LOGINSTATUS': 'Anmeldestatus',
    'SM.AUC.LABEL.USERLOGUI_LOGINIDACCOUNT': 'Anmeldekonto',
    'SM.AUC.MESSAGE.OPERLOG_TIPS_LOGOUT': 'Force Out',
    'SM.AUC.LABEL.LOGINSESSION_LOCKSTATUS': 'Sperrstatus',
    'SM.AUC.LABEL.LOGINSESSION_LOCKTIME': 'Sperrzeit',
    'SM.AUC.LABEL.LOGINSESSION_LOCK': 'Sperre',
    'SM.AUC.LABEL.LOGINSESSION_UNLOCK': 'Entsperren',
    'SM.AUC.LABEL.LOGINSESSION_SURE_LOCK': 'Möchten Sie den Benutzer wirklich sperren?',
    'SM.AUC.LABEL.LOGINSESSION_LOCKFLAG': 'Sperrflagge',
    'SM.AUC.LABEL.LOGINSESSION_LOCKTYPE': 'Sperrtyp',
    'SM.AUC.LABEL.LOGINSESSION_LOCK_EMPLOYEE': 'Gesperrt durch',
    'SM.AUC.LABEL.LOGINSESSION_LOCKBYHAND': 'Manuell sperren',
    'SM.AUC.LABEL.LOGINSESSION_UNLOCKBYHAND': 'Manuell entsperren',
    'SM.AUC.LABEL.LOGINSESSION_AUTHSTATUS': 'Akkreditierungsstatus',
    'SM.AUC.LABEL.LOGINSESSION_NEARESTPAWDAUTHTYPE': 'Status der letzten Kennwortauthentifizierung',
    'SM.AUC.LABEL.LOGINSESSION_NEARESTPWDAUTHTIME': 'Letzte Kennwortauthentifizierung',
    'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAILTIME': 'Fehlerzeiten der Kennwortauthentifizierung',
    'SM.AUC.MESSAGE.LOGINSESSION_LOGININ_STATUS': 'Eingetragen',
    'SM.AUC.MESSAGE.LOGINSESSION_LOGOUT_STATUS': 'Nicht angemeldet',
    'SM.AUC.MESSAGE.LOGINSESSION_LOCKSTATUS': 'Gesperrt',
    'SM.AUC.MESSAGE.LOGINSESSION_UNLOCKSTATUS': 'Nicht gesperrt',
    'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAIL': 'Kennwort kann nicht authentifiziert werden',
    'SM.USERGROUP.DELETEG_GROUP.TITLE': 'Die ausgewählte Benutzergruppe löschen?',
    'SM.USERGROUP.DELETEG_GROUP.CONTENT': 'Nachdem eine Benutzergruppe gelöscht wurde, kann sie nur neu erstellt werden.',
    'SM.USERGROUP.DELETEG_EMPLOYEE.TITLE': 'Möchten Sie die ausgewählten Mitarbeiter aus der aktuellen Benutzergruppe löschen?',
    'SM.USERGROUP.DELETEG_EMPLOYEE.CONTENT': 'Nachdem ein Mitarbeiter aus einer Benutzergruppe gelöscht wurde, können Sie den Mitarbeiter wieder zur Benutzergruppe hinzufügen, wenn Sie den Mitarbeiter wiederherstellen möchten.',
    'SM.AUTH.MESSAGE.FUNCAUTH_MENU': 'Menü',
    'SM.AUTH.LABEL.ROLETMPL_OPERTIME': 'Betriebszeit',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD_INPUT': 'Name des Feldnamens:',
    'SM.AUTH.TITLE.ROLE_FUNCAUTH_FAIL': 'Einige Funktionsberechtigungen konnten nicht gespeichert werden. Diese Berechtigungen sind exklusiv oder können Ihnen nicht gewährt werden.',
    'SM.AUTH.MESSAGE.CHANGE_SCOPE_SUGGESTION': 'Wenn der übergeordnete Knoten den Umfang der Rolle ändert, muss der Umfang des untergeordneten Knotens an den des übergeordneten Knotens angepasst werden.',
    'SM.AUTH.TITLE.ROLE_DISALLOWED_FUNCTION_PERMISSION': 'Ausgeschlossene Funktionsberechtigung ',
    'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE_INPUT': 'Berechtigungsquelle:',
    'SM.FEDERATION.COMMON.DELETE.FAIL': 'Löschen fehlgeschlagen.',
    'SM.AUTH.TITLE.ERROR': 'Fehler',
    'SM.AUTH.TITLE.SELECT_OPEN_LOGIN': 'Wählen Sie Login Open Menu',
    'SM.AUTH.LABEL.OPERATOR_ACCOUNT_INPUT': 'Konto:',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST_INPUT': 'Listen-ID des Vorgangstyps:',
    'SM.AUTH.LABEL.MODULE_INPUT': 'Modul:',
    'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEINHER': 'Die beiden Objekte sind exklusiv und eine Vererbung ist nicht zulässig.',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_NORMAL': 'Normal',
    'SM.AUTH.LABEL.ROLE_AUTH_DATA': 'Datenberechtigung',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD': 'Name des Felds PARENT_ID',
    'SM.AUTH.TITLE.ROLE_GRANTABLE_DATA_PERMISSION': 'Erlaubte Datenberechtigung',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION': 'Name der Operation',
    'SM.AUTH.LABEL.AUTH_CONF_DATA': 'Datenberechtigungseinstellungen',
    'SM.AUTH.LABEL.ROLE_GENERALROLELIST': 'Allgemeine Rollenliste',
    'SM.FEDERATION.FEDERATIONAPP.PHONE': 'Kontaktnummer',
    'SM.AUTH.BUTTON.ADD': 'Neu',
    'SM.AUTH.MESSAGE.ROLETMPL_SELECT_ONE_ROLE': 'Wählen Sie mindestens eine Rolle aus.',
    'SM.AUTH.MESSAGE.TIME_LOG2': 'Tag(e).',
    'SM.AUTH.MESSAGE.TIME_LOG1': 'Der Zeitraum darf nicht überschreiten',
    'SM.AUTH.TITLE.USERAUTHVIEW_USERHAVAEROLES': 'Benutzerrolle',
    'SM.AUTH.LABEL.ROLE_GRANTED_AUTH_TYPE': 'Zugelassener Typ',
    'SM.AUTH.TITLE.INPUT_MENU_NAME': 'Geben Sie einen Menünamen ein',
    'SM.AUTH.LABEL.FUNCAUTH_STATUS': 'Status',
    'SM.AUTH.TITLE.USERAUTHVIEW_MENU_AUTHID': 'Berechtigungs-ID',
    'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS': 'Die sich gegenseitig ausschließende Beziehung besteht bereits.',
    'SM.AUTH.TITLE.ROLE_OPPAUTHNAME': 'Gegenseitig ausschließende Berechtigung',
    'SM.AUTH.LABEL.ROLETMPL_ROLE_LIST': 'Rollenliste',
    'SM.AUTH.MESSAGE.BIZ_OBJ_POLICY_DUPLICATE': 'Es gibt eine Politik mit der gleichen Rolle und dem gleichen Umfang.',
    'SM.FEDERATION.FEDERATIONAPP.CLIENTNAME': 'Drittanbieter-Authentifizierungssystem',
    'SM.AUTH.MESSAGE.ROLETMPL_CAN_NOT_DELETE': 'Der Knoten konnte nicht gelöscht werden, da der Knoten untergeordnete Knoten enthält.',
    'SM.AUTH.LABEL.ROLE_NORMALROLE_BENAME': 'Heimat-BE der gemeinsamen Rolle',
    'SM.AUTH.TITLE.MENU_NAME': 'Menüname',
    'SM.AUTH.TIPS.ROLETMPL_HAS_OPP_AUTH': 'Speichern fehlgeschlagen, da eine sich gegenseitig ausschließende Berechtigung vorhanden ist.',
    'SM.AUTH.TITLE.ROLE_LOGIN_MENU_UPON': 'Menü bei der Anmeldung automatisch anzeigen',
    'SM.AUTH.TIPS.FUNCAUTH_NO': 'Nein',
    'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_CONFIGURATE': 'Rollenvorlage konfigurieren',
    'SM.AUTH.LABEL.OPERATOR_ACCOUNT': 'Konto',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE_INPUT': 'Dienstname:',
    'SM.FEDERATION.IMPORT.FILESIZEVALIDATEFAIL': 'Wählen Sie eine Datei mit weniger als 10 MB aus.',
    'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE_INPUT': 'Vorgangstyp:',
    'SM.AUTH.LABEL.ROLE_PARENTROLE_NAME': 'Allgemeiner Rollenname',
    'SM.AUTH.LABEL.ROLETMPL_OPERATOR': 'Bediener',
    'SM.AUTH.MESSAGE.ROLE_ERROR_BEEN_EXTENDED': 'Die Rolle konnte nicht gelöscht werden, da sie von einer anderen Rolle geerbt wurde oder von einer anderen Rolle geerbt wurde.',
    'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_DEPARTMENT': 'Abteilung',
    'SM.AUTH.MESSAGE.ROLE_THE_ROLES_PERMISSION_OPPED': 'Speichern fehlgeschlagen, da die Basisfunktionsberechtigung eine sich gegenseitig ausschließende Beziehung hat.',
    'SM.AUTH.TITLE.PERMISSION_RECOVERY': 'Genehmigungsrecycling',
    'SM.AUTH.LABEL.THIRD_DATASOURCE_NAME': 'Datenquellenname',
    'SM.AUTH.BUTTON.EXPORT_CURRENT_ROLE': 'Ausgewähltes Element exportieren',
    'SM.FEDERATION.COMMON.REFRESH': 'Aktualisieren',
    'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE': 'Rollenberechtigung nicht verfügbar',
    'SM.AUTH.TITLE.USERAUTHVIEW_FUNCTIONLIST': 'Funktionsberechtigungsliste',
    'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW': 'Berechtigungsansicht',
    'SM.AUTH.MESSAGE.PERM_RECOVERY_SUCCESS': 'Genehmigung erfolgreich widerrufen.',
    'SM.AUTH.LABEL.FUNCAUTH_AUTHID': 'Berechtigungs-ID',
    'SM.AUTH.LABEL.ROLE_EMPLOYEE_ACCOUNT': 'Konto',
    'SM.AUTH.MESSAGE.ROLETMPL_INFORMATION': 'Details',
    'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE': 'Vorgangsart',
    'SM.AUTH.TITLE.EMPOLYEE_ROLESCOPE': 'Mitarbeiter',
    'SM.AUTH.BUTTON.CLEAR': 'Klar',
    'SM.AUTH.LABEL.ROLE_CODE_INPUT': 'Rollencode:',
    'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE': 'Feldprivileg',
    'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE': 'Berechtigungstyp',
    'SM.FEDERATION.COMMON.DETAIL': 'Details',
    'SM.FEDERATION.COMMON.NEW': 'Erstellen',
    'SM.AUTH.LABEL.MENU_AUTHID_INPUT': 'Berechtigungs-ID:',
    'SM.AUTH.TITLE.USER_PERMISSION_COMPARE': 'Vergleich der Personalberechtigungen',
    'SM.AUTH.TIPS.DATA_AUTH_PARAM_INVALID': 'Tabellenname und Spaltenname dürfen nur Buchstaben, Zahlen und _ enthalten und nur mit Buchstaben beginnen.',
    'SM.AUTH.TITLE.ROLE_GRANTABLE_FUNCTION_PERMISSION': 'Erteilbare Funktionsberechtigung ',
    'SM.AUTH.TITLE.ROLE_PERMISSION_COMPARE': 'Vergleich der Rollenberechtigungen',
    'SM.AUTH.TITLE.ROLE_BUSINESS_ENTITY': 'BE-ID',
    'SM.AUTH.LABEL.ROLE_DERIVE_PARENTROLE': 'Geerbte allgemeine Rolle',
    'SM.AUTH.MESSAGE.ROLE_ADD_SUCCESSFUL': 'Erfolgreich hinzugefügt.',
    'SM.AUTH.LABEL.ROLE_EMPLOYEE_NAME': 'Mitarbeiter',
    'SM.AUTH.MESSAGE.FUNCAUTH_INTERFACE': 'Schnittstelle',
    'SM.AUTH.BUTTON.ROLETMPL_RESET': 'Zurücksetzen',
    'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_TYPE': 'Berechtigungsobjekttyp',
    'SM.AUTH.TITLE.INPUT_AUTH_OBJ_NAME': 'Geben Sie einen Berechtigungsobjektnamen ein',
    'SM.FEDERATION.MAPPINGRULE.LOCAL': 'Informationen zum lokalen Benutzer',
    'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_FRONT': 'Reservierungsdauer: Monate (30 Tage)',
    'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME': 'Rollenname',
    'SM.AUTH.LABEL.ROLE_ID_B_INPUT': 'Rollen-ID (B):',
    'SM.AUTH.LABEL.PERMISSION_CLASS': 'Berechtigungsklassifizierung',
    'SM.AUTH.BUTTON.OK': 'OK',
    'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_EXCLUDED': 'Die Rolle konnte nicht gelöscht werden, da sie eine andere Rolle ausschließt.',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF': 'Dynamisches Konfigurationsflag',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_FUNCTION': 'Funktion',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION': 'Datenfilterkriterien',
    'SM.FEDERATION.COMMON.UPDATE.FAIL': 'Änderung fehlgeschlagen.',
    'SM.FEDERATION.FEDERATIONAPP.VALDATEHTTP': 'Das URL-Format ist ungültig',
    'SM.AUTH.LABEL.ROLETMPL_START_TIME': 'Startzeit',
    'SM.AUTH.LABEL.DATA_DICTABLE_ID': 'Datenwörterbuchcode',
    'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF_INPUT': 'Berechtigungsname:',
    'SM.AUTH.LABEL.ROLE_EXCLUDINGROLE': 'Ausschließende Rolle',
    'SM.AUTH.MESSAGE.OPERATE_SUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH': 'Elternberechtigungs-ID',
    'SM.AUTH.LABEL.PERMISSION_INPUT': 'Behörde:',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_MENU': 'Menü',
    'SM.AUTH.MESSAGE.ROLETMPL_QUERY_FAILED': 'Suche fehlgeschlagen.',
    'SM.AUTH.LABEL.ROLE_NAME_INPUT': 'Rollenname:',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_FUNCTION': 'Erteilbare Funktionsberechtigung',
    'SM.AUTH.LABEL.ROLE_NORMALROLE': 'Gemeinsame Rolle',
    'SM.AUTH.OPTION.DISALLOWED_FUNCTION_PERM': 'Ausgeschlossene Funktionsberechtigung ',
    'SM.AUTH.MESSAGE.ROLETMPL_ROLETMPL_EXISTS': 'Der Rollenvorlagenname ist bereits vorhanden.',
    'SM.AUTH.MESSAGE.DATAPER_NO_PERM': 'Sie haben keine Betriebsgenehmigung.',
    'SM.AUTH.LABEL.ROLE_ROLE_ID': 'Rollen-ID',
    'SM.AUTH.LABEL.OPERATION_TYPE': 'Vorgangsart',
    'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADINFO': 'Anhangformat auswählen:',
    'SM.AUTH.LABEL.DATA': 'Datenberechtigung',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD': 'Sortierfeld',
    'SM.AUTH.TITLE.ORG_SELECTION': 'Auswahl der Organisation',
    'SM.AUTH.LABEL.ROLE': 'Rolle',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_PAUSE': 'Aussetzen',
    'SM.AUTH.TITLE.USERAUTHVIEW_ROLEVIEW': 'Rollenansicht',
    'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE': 'Erlaubte Berechtigung der Rolle',
    'SM.AUTH.LABEL.DATAPROVIDER_TYPE_INPUT': 'Quelltyp des Datenwerts:',
    'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_BE': 'BE',
    'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_TYPE': 'Rollentyp',
    'SM.FEDERATION.IMPORT.USER': 'Verbundenen Benutzer importieren',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD_INPUT': 'Sortierfeld:',
    'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_END': 'Monatsdaten (ein Monat besteht aus 30 Tagen).',
    'SM.AUTH.TITLE.STAFFROLELOG_LIST': 'Mitarbeiterrollen-Protokollliste',
    'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_SECRET': 'Geheimnis',
    'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_USERS': 'Die Rolle konnte nicht gelöscht werden, da die Rolle an einen Mitarbeiter gebunden wurde.',
    'SM.FEDERATION.IMPORT.FAIL': 'Nicht bestanden',
    'SM.AUTH.LABEL.ROLE_PARENTROLE_BENAME': 'Heimat-BE der allgemeinen Rolle',
    'SM.AUTH.TITLE.ROLE_HORTCUT_MENU': 'Shortcut-Menü',
    'SM.AUTH.LABEL.FUNCAUTH_STATUS_INPUT': 'Status:',
    'SM.AUTH.LABEL.CLEAR_POPUP_TITLE': 'Abgelaufene Daten löschen',
    'SM.AUTH.LABEL.MODULE': 'Modul',
    'SM.AUTH.LABEL.ROLETMPL_ASSINGED_PERMISSION': 'Berechtigung zur Rolle erteilen',
    'SM.AUTH.TITLE.INPUT_ROLE_NAME': 'Rollennamen eingeben',
    'SM.AUTH.LABEL.ROLE_NO_INFO': 'Rollen im Rollenbaum auf der linken Seite verwalten.',
    'SM.AUTH.LABEL.BIZ_OBJ_LIST': 'Liste der Geschäftsobjekte',
    'SM.AUTH.TITLE.ROLEAUTHVIEW_USERVIEW': 'Mitarbeiteransicht',
    'SM.AUTH.LABEL.ROLE_SUPERID': 'ID der übergeordneten Rolle',
    'SM.AUTH.LABEL.ROLE_CONFIG_INFO': 'Rolleninformationen konfigurieren',
    'SM.AUTH.LABEL.THIRD_DATASOURCE': 'Dritte Datenquelle',
    'SM.AUTH.LABEL.STAFFROLELOG_OPER_TIME': 'Betriebszeit',
    'SM.AUTH.BUTTON.ADDAUTH': 'Hinzufügen',
    'SM.AUTH.TIPS.FUNCAUTH_YES': 'Ja',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_DISCARDED': 'Löschen',
    'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADSIZE': 'Max. Anhanggröße (Byte):',
    'SM.FEDERATION.COMMON.UPDATE.SUCCESS': 'Änderung erfolgreich.',
    'SM.AUTH.MESSAGE.DATAPER_OBJ_TREE_ROOT': 'Berechtigungsobjektbaum',
    'SM.AUTH.TITLE.ROLEAUTHVIEW_AUTHVIEW': 'Berechtigungsansicht',
    'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_CONFIDENTIAL': 'Vertraulich',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION_INPUT': 'Datenfilterkriterien:',
    'SM.AUTH.LABEL.ROLEBUSINESS_ENTITY_ID': 'Die BU-ID der Rolle',
    'SM.AUTH.MESSAGE.FUNCAUTH_DISABLE': 'Nicht verfügbar',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST': 'Listen-ID des Vorgangstyps',
    'SM.AUTH.BUTTON.IMPORT_ROLE': 'Rolle importieren',
    'SM.FEDERATION.COMMON.RESET': 'Zurücksetzen',
    'SM.FEDERATION.FEDERATIONAPP.MAPPINGRULE': 'Zuordnungsregel für verbundene Benutzer',
    'SM.AUTH.TITLE.USERAUTHVIEW_DATA_OBJECT_TREE': 'Berechtigungsobjektbaum',
    'SM.AUTH.BUTTON.ROLE_COPY_ROLE_AND_CREATE': 'Funktionsberechtigung und Datenberechtigung kopieren und Rolle erstellen',
    'SM.AUTH.LABEL.ROLETMPL_SEARCH_CRITERIA': 'Suchkriterien',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD_INPUT': 'Name des Felds PARENT_ID:',
    'SM.AUTH.LABEL.ROLE_EXCLUDEDROLE': 'Ausgeschlossene Rolle',
    'SM.AUTH.TITLE.ROLEAUTHVIEW_PARENT_ROLETYPE': 'Allgemeine Rolle',
    'SM.FEDERATION.FEDERATIONAPP.EMAIL': 'E-Mail',
    'SM.AUTH.LABEL.ROLE_COPY_MODE_INPUT': 'Kopiermodus:',
    'SM.FEDERATION.COMMON.OPERATION': 'Operation',
    'SM.AUTH.LABEL.ROLEPERMISSION_LOGL_IST': 'Protokollliste der Rollenberechtigungen',
    'SM.AUTH.TITLE.ROLE_ASSIGNMENT_TIME': 'Zuordnungszeit',
    'SM.AUTH.LABEL.FUNCAUTH_DEPENDENT': 'Genehmigungsabhängig',
    'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT_INPUT': 'Konto:',
    'SM.AUTH.LABEL.FUNCAUTH_LOADMORE': 'Weitere laden',
    'SM.FEDERATION.IMPORT.FILENAMEVALIDATEFAIL': 'Falscher Dateiname. Wählen Sie eine Datei aus, deren Name nur chinesische Zeichen, Buchstaben, Ziffern oder Unterstriche enthält (_).',
    'SM.AUTH.TITLE.ROLE_NOT_HOLD': 'Liste nicht zulässiger Informationen',
    'SM.AUTH.TITLE.ROLE_EXTENDED_PROPERTY': 'Erweitertes Attribut',
    'SM.AUTH.TITLE.USER_PERM_COMPARE': 'Vergleich der Benutzerberechtigungen',
    'SM.AUTH.LABEL.BIZ_OBJ_SCOPE': 'Geltungsbereich',
    'SM.FEDERATION.IMPORT.DOWLOADIMPORTRESULT': 'Herunterladen',
    'SM.FEDERATION.IMPORT.UPLOADSELECT': 'Wählen Sie eine Datei aus.',
    'SM.AUTH.TITLE.USERAUTHVIEW_OPERTYPE': 'Vorgangsart',
    'SM.AUTH.LABEL.ROLE_TMPL_LIST': 'Liste der Rollenvorlagen',
    'SM.AUTH.MESSAGE.DATAPER_CONFIRM_DEL': 'Möchten Sie wirklich löschen?',
    'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME': 'Berechtigungsname',
    'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE': 'Zielrolle',
    'SM.AUTH.BUTTON.ROLE_DESELECT_ALL': 'Alle abwählen',
    'SM.AUTH.BUTTON.ROLETMPL_SEARCH': 'Suche',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE_INPUT': 'Tabelle:',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_FUNCTION': 'Basisfunktionsberechtigung ',
    'SM.AUTH.LABEL.ROLE_STAFF_LIST': 'Liste der zugewiesenen Mitarbeiter',
    'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_ID': 'Rollen-ID',
    'SM.AUTH.MESSAGE.ROLETMPL_CANNOT_SELECT_MODULE': 'Das Modul kann nicht ausgewählt werden.',
    'SM.AUTH.MESSAGE.ROLETMPL_TIME_CONDITION': 'Die Zeit muss nach der Startzeit liegen.',
    'SM.FEDERATION.IMPORT.IPLODATIP': 'Daten werden importiert ... Zeigen Sie Details im Bereich Ergebnisse des Imports verbundener Benutzer an.',
    'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_NAME': 'Name des Berechtigungsobjekts',
    'SM.AUTH.TITLE.CONFIRM_PERM_RECOVERY': 'Bestätigen',
    'SM.AUTH.TITLE.USERAUTHVIEW_TITLE_AUTHNAME_CONF': 'Berechtigungsname',
    'SM.AUTH.TITLE.SELECT_SHORT_CUT': 'Kontextmenü auswählen',
    'SM.AUTH.TITLE.ROLE_SHORTCUT_MENU_TOOLBAR': 'Shortcut-Menü',
    'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVACY': 'Datenschutzstufe im Feld',
    'SM.FEDERATION.FEDERATIONAPP.EDIT': 'Verbundene App ändern',
    'SM.AUTH.LABEL.ROLE_ID_B': 'Rollen-ID (B)',
    'SM.AUTH.LABEL.ROLE_ID_A': 'Rollen-ID (A)',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER_INPUT': 'Filtern nach BE:',
    'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_NAME': 'Objektname',
    'oms.operationparam.msg.unsafeinfo': 'Beachten Sie, dass das von der aktuellen Adresse verwendete Netzwerkprotokoll nicht sicher ist. Wenn Sie sich für die Verwendung entscheiden, können Sicherheitsrisiken bestehen.',
    'SM.AUTH.LABEL.ROLE_TYPE_INPUT': 'Rollentyp:',
    'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE_INPUT': 'Quellrolle:',
    'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_41': 'Wenn der Zeitraum zu kurz ist, wird die Passwortsicherheit beeinträchtigt. Möchten Sie sie wirklich ändern?',
    'SM.AUTH.LABEL.DATA_DICTABLE_ID_INPUT': 'Datenwörterbuchcode:',
    'SM.AUTH.TITLE.USERAUTHVIEW_USER_INFORMATION': 'Mitarbeiterinformationen',
    'SM.FEDERATION.IMPORT.STARTTIME': 'Startzeit',
    'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK2': 'Rollen werden ebenfalls gelöscht:',
    'SM.AUTH.TIPS.ROLE_INPUTROLENAME': 'Geben Sie einen Rollennamen ein',
    'SM.AUTH.MESSAGE.ROLETMPL_CANT_COPY_PERMISSION': 'Sie können bestimmte Berechtigungen nicht kopieren. Fortfahren?',
    'SM.AUTH.MESSAGE.FUNCAUTH_LANGUAGE': 'Sprache',
    'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK1': 'Wenn Sie die Berechtigung, die Autorisierungsinformationen zugehöriger Mitarbeiter und Folgendes löschen',
    'SM.AUTH.MESSAGE.PASSWORD_LENTH_LESS_THAN_8': 'Ein kurzes Passwort birgt Sicherheitsrisiken. Möchten Sie dieses neue Kennwort wirklich verwenden?',
    'SM.FEDERATION.FEDERATIONAPP.ERROR.INVALIDFIELD': 'Das Feld ist ungültig.',
    'SM.FEDERATION.FEDERATIONAPP.ERROR.PARSEFAIL': 'Externe Benutzerinformationen konnten nicht analysiert werden.',
    'SM.AUTH.LABEL.ROLE_COPY_MODE': 'Kopiermodus',
    'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE_DETAIL': 'Rollen, die anderen die Berechtigung zur Funktion erteilen können',
    'SM.AUTH.LABEL.OPERATION_TYPE_INPUT': 'Vorgangstyp:',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF_INPUT': 'Zusätzliche Baumkonfiguration:',
    'SM.AUTH.LABEL.ROLE_TYPE': 'Rollentyp',
    'SM.FEDERATION.IMPORT.FILESUFFIXVALIDATEFAIL': 'Falsches Dateiformat. Wählen Sie eine XLSX-Datei aus.',
    'SM.AUTH.LABEL.ROLETMPL_END_TIME_INPUT': 'Endzeit:',
    'SM.FEDERATION.FEDERATIONAPP.NAME': 'UserAccount',
    'SM.FEDERATION.COMMON.DELETE.SUCCESS': 'Löschung erfolgreich.',
    'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_39': 'Das Öffnen dieser Regel wirkt sich auf die Kennwortsicherheit aus. Bitte bestätigen Sie, wenn Sie das Öffnen fortsetzen?',
    'SM.AUTH.TITLE.INPUT_ROLE_TMPL_NAME': 'Name der Eingaberollenvorlage',
    'SM.AUTH.TIPS.ROLETMPL_CREATE_AND_ASSIGN': 'Eine Rolle erstellen und Berechtigung zuweisen',
    'SM.AUTH.LABEL.ROLETMPL_ROLE_ASSOCIATION_LOG': 'Rollenzuordnungsprotokoll',
    'SM.AUTH.TITLE.INPUT_USER_LOGINID': 'Geben Sie ein Benutzerkonto ein',
    'SM.FEDERATION.FEDERATIONAPP.ERROR.EXISTS': 'Der Name des Authentifizierungssystems, die URL oder der Name der Zuordnungsregel des Drittanbieters ist bereits vorhanden.',
    'SM.AUTH.LABEL.EMPLOYEEINCLUDED': 'Mitarbeiter der untergeordneten Organisationseinheiten einschließen',
    'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE_INPUT': 'Berechtigungstyp:',
    'SM.AUTH.BUTTON.SAVE': 'Speichern',
    'SM.AUTH.TITLE.PERMISSION_SELECTION': 'Berechtigung auswählen',
    'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT_INPUT': 'Bediener:',
    'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME_INPUT': 'Funktionsberechtigungsname:',
    'SM.AUTH.LABEL.FUNCAUTH_MODULE_INPUT': 'Teilsystem-ID:',
    'SM.AUTH.TITLE.ROLE_PERMISSION_TIPS': 'Um Batch-Operationen im Berechtigungsbaum auszuführen, verwenden Sie Kontextmenüs auf Berechtigungsknoten.',
    'SM.FEDERATION.IMPORT.FAILCOUNT': 'Fehlerhafte Datensätze',
    'SM.AUTH.LABEL.ROLE_ID_INPUT': 'Rollen-ID:',
    'SM.AUTH.LABEL.FUNCAUTH_BASICINFO': 'Grundlegende Informationen',
    'SM.AUTH.MESSAGE.ROLE_ERROR_BEED_EXCLUDED': 'Die Rolle konnte nicht gelöscht werden, da sie eine andere Rolle ausschließt.',
    'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_NAME': 'Rollenname',
    'SM.AUTH.TITLE.ROLEAUTHVIEW_DATAAUTHLIST': 'Liste der Berechtigungsobjekte',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD': 'Name des Feldnamens',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD': 'Name der Feld-ID',
    'SM.AUTH.OPTION.ROLE_AUTH_REMOVE': 'Löschen',
    'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_ID': 'ID des Berechtigungsobjekts',
    'SM.AUTH.MESSAGE.ROLE_DELETE_SUCCESSFUL': 'Erfolgreich gelöscht.',
    'SM.AUTH.LABEL.PERMISSION': 'Erlaubnis',
    'SM.AUTH.LABEL.ROLE_PARENTROLE_ID': 'Allgemeine Rollen-ID',
    'SM.AUTH.TITLE.USERAUTHVIEW_AUTH': 'Funktionsberechtigung',
    'SM.AUTH.LABEL.FUNCAUTH_ATTRIBUTE': 'Attribut erweitern',
    'SM.AUTH.BUTTON.USERAUTHVIEW_RESET': 'Zurücksetzen',
    'SM.AUTH.TITLE.ASSIGN_EMPLOYEE': 'Mitarbeiter zuweisen',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE': 'Tisch',
    'SM.AUTH.MESSAGE.DATAPER_DEL_SUCCESS': 'Erfolgreich gelöscht.',
    'SM.AUTH.TITLE.USERAUTHVIEW_ACCOUNTLOGIN': 'Konto',
    'SM.FEDERATION.COMMON.CONFIRM': 'Bestätigen',
    'SM.AUTH.TIPS.ROLETMPL_VIEW_DETAIL_PERMISSION': 'Details zu Berechtigungen anzeigen, die Sie in der Rollenvorlage nicht kopieren können.',
    'SM.AUTH.TIPS.ROLETMPL_ASSIGN_PERMISSON': 'Berechtigung zuweisen',
    'SM.AUTH.TITLE.ROLE_BASIC_FUNCTION_PERMISSION': 'Basisfunktionsberechtigung ',
    'SM.AUTH.HEADVALUE.AUTHUSERVIEW_AUTH_DATA': 'Bitte wählen Sie ...',
    'SM.AUTH.MESSAGE.OPERATEFAIL': 'Speichern fehlgeschlagen.',
    'SM.FEDERATION.FEDERATIONAPP.ERROR.PARAMNULL': 'Der Anforderungsparameter ist leer.',
    'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME_INPUT': 'Rollenvorlagenname:',
    'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE_PERMISSION': 'Wenn der Nutzungsbereich kein Mitarbeiter ist, können Sie Mitarbeitern keine Rollen zuweisen',
    'SM.FEDERATION.COMMON.SUCCESS': 'Erfolg',
    'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_12': 'Das Deaktivieren dieser Regel wirkt sich auf die Kennwortsicherheit aus. Möchten Sie sie wirklich deaktivieren?',
    'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE': 'Berechtigungsquelle',
    'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_10': 'Gültige Sonderzeichen beeinträchtigen die Kennwortsicherheit. Möchten Sie sie wirklich verwenden?',
    'SM.AUTH.LABEL.ROLETMPL_PERMISSION_ID': 'Funktionsberechtigungs-ID',
    'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE_DETAIL': 'Rollen, die die Funktionsberechtigung verwenden können',
    'SM.AUTH.TITLE.ROLE_DELEVER_DATA_PERMISSION': 'Datenberechtigung erteilen',
    'SM.FEDERATION.IMPORT.UNDO': 'Ausstehend',
    'SM.AUTH.LABEL.ROLE_PARENTS_TITLE': 'Rollenvererbungsinfo',
    'SM.FEDERATION.FEDERATIONAPP.ACCOUNT': 'Konto',
    'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE_INPUT': 'Zielrolle:',
    'SM.AUTH.MESSAGE.SELECTA_ROLE': 'Wählen Sie eine Rolle aus.',
    'SM.AUTH.BUTTON.USERAUTHVIEW_SEARCH': 'Suche',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE': 'Dienstname',
    'SM.AUTH.BUTTON.PERM_RECOVERY': 'Recycling',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER': 'Nach BE filtern',
    'SM.AUTH.MESSAGE.ROLE_SURE_TO_DELETE': 'Möchten Sie wirklich löschen?',
    'SM.AUTH.LABEL.ROLE_ID_A_INPUT': 'Rollen-ID (A):',
    'SM.AUTH.LABEL.DATAAUTHMGR_TAB_AUTH_ROLE_VIEW': 'Rollen im Zusammenhang mit Datenberechtigungen',
    'SM.AUTH.LABEL.ROLE_DERIVE_NORMALROLE': 'Abgeleitete gemeinsame Rolle',
    'SM.AUTH.LABEL.ROLE_ASSIGN_STAFF': 'Zugewiesener Mitarbeiter',
    'SM.AUTH.TITLE.ROLE_TMPLATE_ID': 'Rollenvorlagen-ID',
    'SM.AUTH.LABEL.ATTR_LANGUAGE': 'Sprache',
    'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE_SETTING': 'Einstellung',
    'SM.AUTH.BUTTON.EXPORT': 'Exportieren',
    'SM.AUTH.MESSAGE.OPERATE_WITH_ROLES': 'Die Berechtigung wurde erfolgreich erstellt und dem aktuellen Betreiber sowie den folgenden Rollen zugewiesen:',
    'SM.AUTH.MESSAGE.ROLETMPL_DELETE_SUCCESS': 'Erfolgreich gelöscht.',
    'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID_INPUT': 'Rollen-BE:',
    'SM.AUTH.TIPS.FUNCAUTH_STOPUSE': 'Aussetzen',
    'SM.AUTH.MESSAGE.OPERATE_WITHOUT_ROLES': 'Die Berechtigung wurde erfolgreich erstellt und dem aktuellen Betreiber zugewiesen.',
    'SM.AUTH.MESSAGE.ROLE_OPP_WITH_PARENT': 'Die ausgewählte Rolle steht der geerbten Rolle gegenüber:',
    'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME_INPUT': 'Rollenname:',
    'SM.AUTH.TIPS.ENTER_AUTHNAME': 'Geben Sie den Berechtigungsnamen ein',
    'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL_INPUT': 'Zu wählende Rollenvorlage:',
    'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_MODULE': 'Modul',
    'SM.AUTH.MESSAGE.FUNCAUTH_PAUTHNAME': 'Berechtigungsname',
    'SM.AUTH.LABEL.LOGINLOG_RESET': 'Zurücksetzen',
    'SM.AUTH.LABEL.FUNCAUTH_AUTHID_INPUT': 'Berechtigungs-ID:',
    'SM.AUTH.TITLE.ROLE_BASIC_DATA_PERMISSION': 'Basisdatenberechtigung',
    'SM.AUTH.MESSAGE.ROLE_CAN_NOT_ADD_ITSELF': 'Kann seine eigene Rolle nicht erben.',
    'SM.FEDERATION.MAPPINGRULE.NAME': 'Zuordnungsregel',
    'SM.AUTH.MESSAGE.SAVE_DEPENDENT_SELF': 'Die Erlaubnis hängt selbst ab, dies ist nicht erlaubt.',
    'SM.FEDERATION.IMPORT.STATUS': 'Status',
    'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE_DETAIL': 'Rollen, die die Funktionsberechtigung nicht verwenden können',
    'SM.AUTH.LABEL.ROLETMPL_HOME_ENTITY': 'Heimat-BE der Genehmigung',
    'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_DATA': 'Datenberechtigung',
    'SM.AUTH.LABEL.BIZ_OBJ_FIELD_IS_WRITEABLE': 'Ist beschreibbar',
    'SM.AUTH.MESSAGE.FUNCAUTH_NORMAL': 'Normal',
    'SM.AUTH.MESSAGE.ROLE_NAME_SAME': 'Der Rollenname ist bereits vorhanden.',
    'SM.AUTH.LABEL.USERAUTHVIEW_SEARCH': 'Abfrage',
    'SM.AUTH.LABEL.DATAPROVIDER_TYPE': 'Quelltyp des Datenwerts',
    'SM.AUTH.BUTTON.ROLE_COPY_ROLE': 'Rolleninformationen kopieren (nur Berechtigung zum Kopieren von Funktionen und Daten)',
    'SM.AUTH.TITLE.USERAUTHVIEW_USER_LIST': 'Mitarbeiterliste',
    'SM.AUTH.LABEL.USER_ACCOUNTB': 'Konto (B)',
    'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME': 'Funktionsberechtigungsname',
    'SM.AUTH.LABEL.USER_ACCOUNTA': 'Konto (A)',
    'SM.FEDERATION.COMMON.DOWLOADTEM': 'Vorlage herunterladen',
    'SM.AUTH.LABEL.ROLE_NAME': 'Rollenname',
    'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME': 'Rollenname',
    'SM.AUTH.LABEL.ORGANIZATION_INPUT': 'Organisationseinheit:',
    'SM.AUTH.LABEL.ROLE_SCOPE': 'Rollenumfang',
    'SM.AUTH.MESSAGE.ROLE_NOT_EXSIT': 'Die Rolle existiert nicht.',
    'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_ID': 'Heimat-BE-ID der Rolle',
    'SM.AUTH.MESSAGE.DATAPER_TREE_ROOT': 'Datenberechtigungsbaum',
    'SM.AUTH.TITLE.SUCCESS': 'Informationen',
    'SM.AUTH.BUTTON.AUTH_EDIT': 'Ändern',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION_INPUT': 'Name des Vorgangs:',
    'SM.AUTH.NO.SELECT': 'Es wurde keine Berechtigung ausgewählt.',
    'SM.AUTH.MESSAGE.AUTH_DATA_OBJECT_TREE': 'Berechtigungsobjektbaum',
    'SM.FEDERATION.COMMON.CREATE.FAIL': 'Erstellung fehlgeschlagen.',
    'SM.FEDERATION.FEDERATIONAPP.NEW': 'Verbundene App erstellen',
    'SM.AUTH.BUTTON.ROLE_SELECT_ALL': 'Alle auswählen',
    'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH_INPUT': 'Elternberechtigungs-ID:',
    'SM.AUTH.LABEL.USER_ACCOUNTB_INPUT': 'Konto (B):',
    'SM.FEDERATION.COMMON.QUERY.CONDITION': 'Suchkriterien',
    'SM.AUTH.BUTTON.CANCEL': 'Abbrechen',
    'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_ID': 'Objekt-ID',
    'SM.AUTH.TITLE.USERAUTHVIEW_QUERYCONDITION_TITLE': 'Suchkriterien',
    'SM.AUTH.LABEL.ROLE_ID': 'Rollen-ID',
    'SM.AUTH.LABEL.DATAAUTHMGR_ATTRIBUTE': 'Attribut erweitern',
    'SM.FEDERATION.DOWNLOAD.RESULT': 'Ergebnis des Imports verbundener Benutzer herunterladen',
    'SM.FEDERATION.FEDERATIONAPP.CLIENTURL': 'URL',
    'SM.AUTH.LABEL.ROLETMPL_END_TIME': 'Endzeit',
    'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID': 'Rolle ist BE',
    'SM.AUTH.TITLE.ASSIGNED_EMPLOYEE': 'Zugewiesener Mitarbeiter',
    'SM.AUTH.LABEL.ROLETMPL_BASIC_FUNCTION_PERMISSION': 'Basisfunktionsberechtigung ',
    'SM.AUTH.LABEL.ROLETMPL_DESCRITPION': 'Beschreibung',
    'SM.AUTH.TITLE.ADD_ROLE_FROM_ROLE_TMPL': 'Rollenvorlage auswählen',
    'SM.AUTH.TITLE.USERAUTHVIEW_QUERY_AUTHTYPE': 'Berechtigungstyp',
    'SM.FEDERATION.IMPORT.SUCCESSCOUNT': 'Erfolgreiche Aufzeichnungen',
    'SM.AUTH.LABEL.ROLE_CODE': 'Rollencode',
    'SM.AUTH.TIPS.ROLETMPL_WARNING': 'Warnung',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_DAUTH': 'Basisdatenberechtigung',
    'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE': 'Kunde',
    'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID': 'Rollen-ID',
    'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT': 'Konto',
    'SM.AUTH.LABEL.ROLE_DERIVE': 'Rollenvererbung',
    'SM.AUTH.LABEL.BIZ_OBJ_FULL_NAME': 'Vollständiger Objektname',
    'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE': 'Quellrolle',
    'SM.AUTH.MESSAGE.ROLE_SELECT_A_ROLE': 'Wählen Sie eine Rolle aus.',
    'SM.AUTH.LABEL.DETAIL_INFO': 'Wählen Sie einen Berechtigungsknoten im Navigationsbaum auf der linken Seite aus, um die Informationen zur Berechtigung anzuzeigen.',
    'SM.FEDERATION.FEDERATIONAPP.ERROR.ISUSED': 'Die Daten können nicht gelöscht oder geändert werden, da sie von einem Mandanten verwendet wurden.',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF': 'Zusätzliche Baumkonfiguration',
    'SM.AUTH.LABEL.FUNCAUTH_ISDY': 'Dynamisches Konfigurationsflag',
    'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME': 'Name der Rollenvorlage',
    'SM.AUTH.BUTTON.CONFIRM_DELETE': 'Löschvorgang bestätigen',
    'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW_DATAAUTHLIST': 'Liste der Berechtigungsobjekte',
    'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL': 'Rollenvorlage auswählen',
    'SM.AUTH.LABEL.CLEAR_CONFIRM_CLEAR_DATA': 'Möchten Sie wirklich Berechtigungen löschen, die in letzter Zeit nicht verwendet wurden?',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE_INPUT': 'Baumstruktur:',
    'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_PUBLIC': 'Öffentlich',
    'SM.FEDERATION.FEDERATIONAPP.REMOTETIP': 'Der Wert ist das Zuordnungsfeld, das dem externen Benutzer entspricht.',
    'SM.AUTH.LABEL.ROLE_AUTH_DATA_INPUT': 'Datenberechtigung:',
    'SM.AUTH.LABEL.PERMISSION_CLASS_INPUT': 'Berechtigungsklassifizierung:',
    'SM.FEDERATION.MAPPINGRULE.DESC': 'Beschreibung',
    'SM.AUTH.LABEL.ROLE_SUPERCODE_INPUT': 'Elternrollencode:',
    'SM.FEDERATION.IMPORT.RESULT': 'Ergebnis des Imports verbundener Benutzer',
    'SM.AUTH.TITLE.ROLEAUTHVIEW_COMMON_ROLETYPE': 'Gemeinsame Rolle',
    'SM.AUTH.LABEL.ROLE_SUPERCODE': 'Elternrollencode',
    'SM.AUTH.LABEL.ROLETMPL_ROLE_ID': 'Rollen-ID',
    'SM.AUTH.LABEL.CONFIRM_DELETE_DEPEND_AUTH': 'Löschen der abhängigen Berechtigung bestätigen?',
    'SM.AUTH.MESSAGE.ROLETMPL_SHOWNAME': 'Rollenvorlage',
    'SM.AUTH.TITLE.ROLE_MUTUALLY_EXCLUSIVE_PERMISSION': 'Gegenseitig ausschließende Berechtigungsliste',
    'SM.AUTH.LABEL.COMPARE_RESULT': 'Vergleichsergebnis',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE_INPUT': 'Berechtigungscode:',
    'SM.FEDERATION.FEDERATIONAPP.DELETETIP': 'Möchten Sie die Daten wirklich löschen?',
    'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_STATUS': 'Status',
    'SM.AUTH.TITLE.BELONG_ROLE_TMPL': 'Eigentümerrollenvorlage',
    'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE': 'Verfügbare Rollenberechtigung',
    'SM.AUTH.LABEL.USER_ACCOUNTA_INPUT': 'Konto (A):',
    'SM.FEDERATION.COMMON.FAIL': 'Fehler',
    'SM.FEDERATION.IMPORT.IMPORTRESULT': 'Ergebnis importieren',
    'SM.AUTH.OPTION.ROLE_REMOVE_INFO_OF_TARGET_ROLE': 'Zielrolleninformationen löschen',
    'SM.FEDERATION.IMPORT.UPLOADFILE': 'Datei',
    'SM.AUTH.TITLE.USERAUTHVIEW_AUTHSOURCE': 'Berechtigungsquelle',
    'SM.AUTH.TITLE.AUTHNAMEINFO': 'Informationen zum Berechtigungsnamen',
    'SM.AUTH.LABEL.LOGINLOG_SEARCH': 'Suche',
    'SM.AUTH.TIPS.ROLE_PARENTROLE': 'Rollenname',
    'SM.AUTH.LABEL.ROLE_DESC': 'Beschreibung',
    'SM.FEDERATION.IMPORT.ENDTIME': 'Endzeit',
    'SM.AUTH.LABEL.FUNCAUTH_OPPAUTHNAME': 'Gegenseitig ausschließende Berechtigung',
    'SM.FEDERATION.COMMON.OPERATION.DELETE': 'Löschen',
    'SM.FEDERATION.FEDERATIONAPP.LIST': 'Verbundene Apps',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_DAUTH': 'Erlaubte Datenberechtigung',
    'SM.AUTH.LABEL.ROLE_NORMALROLE_ID': 'Gemeinsamer Rollencode',
    'SM.AUTH.TITLE.ROLE_TMPL_ROLE_LIST': 'Rollenliste',
    'SM.FEDERATION.COMMON.OPERATION.EDIT': 'Bearbeiten',
    'SM.AUTH.LABEL.ROLE_ROLE_NAME': 'Rollenname',
    'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NO_ENTITY': 'Wählen Sie mindestens eine Rolle oder GE aus.',
    'SM.AUTH.BUTTON.CLEAR_ALL_TIPS': 'Nicht verfügbare Berechtigung löschen',
    'SM.AUTH.MESSAGE.OK': 'OK',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE': 'Berechtigungs-ID',
    'SM.AUTH.TITLE.CONFIRM_DEL': 'Bestätigen',
    'SM.AUTH.OPTION.ROLEAUTHVIEW_OPP_FUNCTION': 'Ausgeschlossene Funktionsberechtigung ',
    'SM.AUTH.LABEL.FUNCAUTH_MODULE': 'Teilsystem-ID',
    'SM.AUTH.LABEL.ROLE_INFO': 'Rolleninformationen',
    'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF': 'Berechtigungsname',
    'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NODE_INFO': 'Wählen Sie einen übergeordneten Knoten in der Navigationsstruktur auf der linken Seite aus und verwalten Sie Rollenvorlagen in der Rollenvorlagenstruktur.',
    'SM.AUTH.LABEL.MENU_AUTHID': 'Berechtigungs-ID',
    'SM.AUTH.TITLE.INFO': 'Informationen',
    'SM.AUTH.MESSAGE.FUNCAUTH_READONLY': 'Nicht zuweisbar',
    'SM.AUTH.LABEL.ROLETMPL_START_TIME_INPUT': 'Startzeit:',
    'SM.FEDERATION.IMPORT.FAILFILE': 'Fehlerhafte Dateien',
    'SM.AUTH.LABEL.FUNCAUTH_DESC_INPUT': 'Beschreibung:',
    'SM.AUTH.LABEL.BIZ_OBJ_NAME': 'Name',
    'SM.AUTH.LABEL.ORGANIZATION': 'Organisationseinheit',
    'SM.AUTH.BUTTON.DELETE': 'Löschen',
    'SM.AUTH.BUTTON.CLOSE': 'Schließen',
    'SM.AUTH.MESSAGE.PERM_RECOVERY': 'Wenn Sie auf OK klicken, werden die Berechtigungen aller folgenden Rollen widerrufen. Wenn Sie auf Abbrechen klicken, wird kein Vorgang ausgeführt.',
    'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEEXCLUDING': 'Die Vererbungsbeziehung besteht bereits und die Rolle kann nicht ausgeschlossen werden.',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD_INPUT': 'Name der Feld-ID:',
    'SM.FEDERATION.IMPORT.FAILREASON': 'Fehlerursache',
    'SM.AUTH.LABEL.FUNCAUTH_UPLOAD': 'Anhang hochladen',
    'SM.AUTH.LABEL.BIZ_OBJ_FIELD': 'Feld',
    'SM.AUTH.BUTTON.ROLE_DELETE_FOREVER': 'Endgültig löschen',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF_INPUT': 'Dynamisches Konfigurationsflag:',
    'SM.FEDERATION.COMMON.CREATE.SUCCESS': 'Erstellung erfolgreich.',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_INPUT': 'Beschreibung:',
    'SM.AUTH.LABEL.BE_BASIC_INFORMATION': 'Grundlegende Informationen',
    'SM.AUTH.TITLE.USERAUTHVIEW_NAME': 'Mitarbeiter',
    'SM.AUTH.MESSAGE.SAVE_SUCCESS': 'Vorgang erfolgreich ausgeführt.',
    'SM.AUTH.LABEL.ROLE_DESC_INPUT': 'Beschreibung:',
    'SM.AUTH.TITLE.USERAUTHVIEW_STAFF_BE_NAME': 'Heimat-BE des Mitarbeiters',
    'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_INFO': 'Rollenvorlageninformationen',
    'SM.AUTH.TITLE.TO_ASSIGNED_EMPLOYEE': 'Zuzuweisender Mitarbeiter',
    'SM.AUTH.LABEL.ROLE_NORMALROLE_NAME': 'Gemeinsamer Rollenname',
    'SM.AUTH.OPTION.GEANTABLE_FUNCTION_PERM': 'Erteilbare Funktionsberechtigung ',
    'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE': 'Zugangsregel',
    'SM.AUTH.LABEL.ROLETMPL_DESCRITPION_INPUT': 'Beschreibung:',
    'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_PARENTS_TITLE': 'Rollenvererbungsinfo',
    'SM.AUTH.TIPS.ENTER_ORGNAME': 'Geben Sie den Namen der Organisation ein',
    'SM.FEDERATION.MAPPINGRULE.REMOTE': 'Informationen zu externen Benutzern',
    'SM.AUTH.MESSAGE.FUNCAUTH_FUNCTION': 'Funktion',
    'SM.FEDERATION.COMMON.QUERY': 'Suche',
    'SM.AUTH.LABEL.ROLETMPL_FUNCTION_PERMISSION': 'Zugriffsfunktionsberechtigung',
    'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT': 'Bediener',
    'SM.AUTH.MESSAGE.ROLE_ERROR_SUBROLES': 'Die Rolle kann nicht gelöscht werden, da sie untergeordnete Rollen hat.',
    'SM.AUTH.LABEL.CLEAR_TIMEOUT_SUCCESS_MSG': 'Berechtigungen, die während der Zeit nicht verfügbar waren, wurden erfolgreich gelöscht.',
    'SM.AUTH.TITLE.USERAUTHVIEW_USER_LOGINID': 'Konto',
    'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_NAME': 'Rollen-Heimat-BE',
    'SM.AUTH.LABEL.BIZ_OBJ_IS_OWNER': 'Ist Eigentümer',
    'SM.AUTH.OPTION.ROLE_RETAIN_INFO_OF_TARGET_ROLE': 'Zielrolleninformationen beibehalten',
    'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE_ROLE_POLICY': 'Rollenzugriffsrichtlinie',
    'SM.AUTH.LABEL.PERMRECOVERY_ROLE_NAME': 'Rolle mit Genehmigung',
    'SM.AUTH.LABEL.FUNCAUTH_DESC': 'Beschreibung',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC': 'Beschreibung',
    'SM.AUTH.LABEL.DETAIL': 'Details',
    'SM.AUTH.TITLE.INPUT_AUTHID_AUTHNAME': 'Geben Sie eine Berechtigungs-ID oder einen Namen ein',
    'SM.AUTH.LABEL.TITLE_CLEAR_SUCCESS': 'Aufforderung',
    'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID_INPUT': 'Rollen-ID:',
    'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME_INPUT': 'Berechtigungsname:',
    'SM.AUTH.LABEL.ROLE_OPERTYPE': 'Vorgangsart',
    'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME_INPUT': 'Rollenname:',
    'SM.AUTH.TITLE.USERAUTHVIEW_DETAIL_INFO': 'Wählen Sie einen Berechtigungsknoten mit einer URL aus.',
    'SM.FEDERATION.FEDERATIONAPP.VALDATEJSON': 'Das Konfigurationselement muss im JSON-Format vorliegen.',
    'SM.AUTH.LABEL.FUNCAUTH_INFO': 'Grundlegende Informationen',
    'SM.AUTH.TITLE.INPUT_PERMISSION_NAME': 'Geben Sie einen Berechtigungsnamen ein',
    'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE': 'Baumstruktur',
    'SM.AUTH.MESSAGE.DELETE_CONFIRM': 'Möchten Sie wirklich löschen?',
    'SM.AUTH.TITLE.THIRD_DATASOURCE': 'Operation erfolgreich ausgeführt (Die dritte Datenquelle ist geschlossen, da die Datenquelle leer ist.)',
    'SM.AUTH.TITLE.ADD_ROLE_FROM_AUTH_TREE': 'Funktionsberechtigung auswählen',
    'SM.FEDERATION.COMMON.CANEL': 'Abbrechen',
    'SM.FEDERATION.COMMON.SUBMIT': 'Senden',
    'SM.AUTH.OPTION.ROLE_AUTH_ADD': 'Hinzufügen',
    'SM.AUTH.MESSAGE.ROLE_INHERITANCE_RELATIONSHIP_EXISTS': 'Die Erbschaft existiert bereits.',
    'SM.AUTH.LABEL.ASSIGNEMPLOYEE': 'Mitarbeiterzuweisung',
    'SM.AUTH.LABEL.ASSIGNRIGHTS': 'Zuweisbare Berechtigungen',
    'SM.AUTH.LABEL.OWNEDRIGHTS': 'Eigene Berechtigungen',
    'SM.ORGSTAFF.LABEL.ALLORG': 'Alle Organisationen',
    'SM.FEDERATION.USER.TEMPLATE': 'Vorlage für den Import verbundener Benutzer herunterladen',
    'common.baseui.MESSAGE.VALIDATE_MAXLENGTHB': 'Es sind maximal 0 Zeichen zulässig.',
    'SM.GADGET.VALUE.MENUPERM': 'Menüberechtigungen',
    'SM.GADGET.VALUE.FUNCPERM': 'Operationsberechtigungen',
    'SM.GADGET.VALUE.INTFPERM': 'API-Berechtigungen',
    'SM.ROLE.PARENT.ROLE': 'Rolle der oberen Ebene',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_SET_ROLE': 'Rollen in Batches hinzufügen',
    'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_DELETE_ROLE': 'Rollen in Batches löschen',
    'SM.USERPWDGROUP.RANGE.VALIDATE': 'Der Eingabebereich beträgt %-%.',
    'SM.ROLE.SELECT.BATCH': 'Wählen Sie eine Rolle aus',
    'SM.ROLE.SELECT.ERRORMSG': 'Ausnahmebeschreibung:',
    'SM.ROLE.SELECT.ERRORCAUSE': 'Ausnahmeursache:',
    'SM.ROLE.SELECT.ERRORSLOUTION': 'Handhabungsvorschläge:',
    'SM.ROLE.MESSAGE.DELETE_CONFIRM': 'Nachdem eine Rolle gelöscht wurde, kann sie nur neu erstellt werden. Löschen?',
    'SM.ORG.DISABLE.FAILED.PARENT': 'Deaktivieren Sie alle untergeordneten Organisationen der Organisation, bevor Sie die aktuelle Organisation deaktivieren.',
    'SM.ORG.DISABLE.FAILED.EMPLOYEE': 'Löschen Sie alle Mitarbeiter in der Organisation und deaktivieren Sie dann die Organisation.',
    'SM.EMPLOYEE.SELECTED.EMPLOYEE': 'Ausgewählte Mitarbeiter',
    'SM.EMPLOYEE.ALLOCATE.WORKNO': 'Mitarbeiter-ID zuweisen',
    'SM.EMPLOYEE.SELECTED.WORKNO.NOT.ENOUGH': 'Die Anzahl der Agenten-IDs, die dem ausgewählten Agententyp zugewiesen werden können, ist nicht ausreichend.',
    'SM.EMPLOYEE.CHECKED-IN.PASSWORD': 'Anmeldekennwort',
    'SM.EMPLOYEE.SOFTCONSOLE.GROUP': 'Heimverkehrsgruppe',
    'SM.EMPLOYEE.INSPECTION.GROUP': 'Start-QK-Gruppe',
    'SM.EMPLOYEE.PLATFORM.CONFIG': 'Plattformkonfiguration',
    'SM.EMPLOYEE.CONFIG.EMPLOYEE': 'Mitarbeiter konfigurieren',
    'SM.EMPLOYEE.CANNOT.CHOOSE.WORKNO': 'Sie können keinen Mitarbeiter auswählen, dem eine Mitarbeiter-ID zugewiesen wurde.',
    'SM.EMPLOYEE.OPERATE.MAX.HUNDRED': 'Es können maximal 100 Mitarbeiter gleichzeitig eingesetzt werden.',
    'SM.EMPLOYEE.SKILL.INFO': 'Informationen zu Fähigkeiten',
    'SM.EMPLOYEE.FEATURE.CONFLICT': 'Intelligente Erkennung und Dual-Track-Aufnahme können nicht gleichzeitig aktiviert werden. Wenn sie aktiviert sind, wird die Auswahl der anderen aufgehoben.',
    'SM.EMPLOYEE.BATCH.CONFIG': 'Stapelkonfiguration',
    'SM.TENANT.LABEL': 'Tenant',
    'SM.TENANT.INFO': 'Informationen zum Mieter',
    'SM.TENANT.VIEW': 'Mieter besuchen',
    'SM.TENANT.EDIT': 'Mieter bearbeiten',
    'SM.TENANT.CREATE': 'Mieter hinzufügen',
    'SM.TENANT.TENANTSPACENAME': 'TenantSpaceName',
    'SM.TENANT.TENANTNAME': 'TenantName',
    'SM.TENANT.ADMINACCOUNT': 'AdminAccount',
    'SM.TENANT.PHONE': 'Telefonnummer',
    'SM.TENANT.CREATETIME': 'Erstellungszeit',
    'SM.TENANT.DELETE_CONFIRM': 'Gelöschte Mieter können nicht wiederhergestellt werden. Möchten Sie sie wirklich löschen?',
    'SM.PWDRULE.PLEASE.INPUT': 'Geben Sie einen Regelgruppennamen ein',
    'SM.EMPLOYEE.CANNOT.CHOOSE.STATUS': 'Mitarbeiter im deaktivierten Status können nicht ausgewählt werden.',
    'SM.EMPLOYEE.CANCEL.CONFIG.CONFIRM': 'Möchten Sie die Konfiguration wirklich abbrechen?',
    'SM.EMPLOYEE.ROLE.EFFECT': 'Die Angaben zur Rolle des Mitarbeiters werden [] Minute später wirksam.',
    'SM.ROLE.AUTH.EFFECT': 'Die Berechtigungsinformationen der Rolle werden [] Minute später wirksam.',
    'SM.ROLE.TEMPLATE': 'Rollenvorlage',
    'SM.LABEL.APPCUBE.SWITCH': 'AppCube-integrierte Seite',
    'SM.LABEL.APPCUBE.APP': 'Anwendung auswählen',
    'SM.LABEL.APPCUBE.MENU': 'Menü auswählen',
    'SM.MENU.NAME.SPECIAL.CHAR': "Sonderzeichen sind keine allowed: !#{'$'}%&()￥+:\"{'{'}{'}'},\\/'[]=-^<>",
    'SM.COMMON.MESSAGE.DUTY.FORBIDDEN': 'Die ausgewählten Mitarbeiter enthalten Mitarbeiter, die verboten wurden.',
    'SM.LABEL.ACCOUNT': 'Bitte geben Sie ein Konto ein',
    'SM.LABEL.USERNAME': 'Geben Sie einen Benutzernamen ein',
    'SM.LABEL.EMAIL': 'Geben Sie eine E-Mail-Adresse ein',
    'SM.LABEL.PHONE': 'Bitte geben Sie die Telefonnummer ein',
    'SM.LABEL.PASSWORD': 'Bitte geben Sie das Kennwort ein',
    'SM.LABEL.DESCRIPTION': 'Geben Sie eine Beschreibung ein',
    'SM.ORGSTAFF.BUTTON.SaveAndConfigure': 'SaveAndConfigure',
    'SM.ROLE.BUTTON.DELETE': 'Möchten Sie die ausgewählten Mitarbeiter wirklich löschen?',
    'SM.ROLE.ADD': 'Rolle erstellen',
    'SM.ROLE.ADD.NAME': 'Geben Sie einen Rollennamen ein',
    'SM.ROLE.ADD.DECRI': 'Geben Sie Anmerkungen ein',
    'sumweb.export.confirm.exportall': 'Sie haben keine Datensätze ausgewählt. Alle Datensätze exportieren? Sie können auch auf einen bestimmten Datensatz klicken, den Sie exportieren möchten.',
    'sumweb.export.export.select': 'Sie haben {0} Datensatz ausgewählt. Möchten Sie wirklich fortfahren?',
    'sumweb.export.org': 'Organisationsinformationen werden exportiert',
    'sumweb.export.employee': 'Mitarbeiterdaten exportieren',
    'sumweb.view.import.list': 'Importliste',
    "sumweb.import.success": 'Es wurde eine Importaufgabe erstellt. Möchten Sie die Importliste wirklich anzeigen?',
    "SM.ROLE.EDIT": 'Rolle bearbeiten',
    "SM.AUTH.BUTTON.CONFIRM.CLOSE": 'Abschluss bestätigen',
    "sumweb.agent.config": 'Agenten konfigurieren',
    "sumweb.employee.export.nodata": 'Keine Mitarbeiterdaten erfüllen die Anforderungen.',
    "sumweb.employee.view.config": 'Konfiguration anzeigen'
}