import cookie from '@/utils/cookie'
import i18n from '@/lang/index'

const $t = i18n.global.t

// 根据不同语言设置不同的列宽度
export function getWidth(localeArr) {
  if (!localeArr || localeArr.length < 6) {
    // 至少有6种语言，后续可能新增语言
    return null
  }
  const currentLocale = cookie.getCookie('u-locale')
  for (const obj of localeArr) {
    if (currentLocale === obj.locale) {
      return obj.width
    }
  }
}

// 构造密码规则
export function createOuterRuleMsg(data) {
  let ruleMap = new Map()
  for (let i = 0; i < data.length; i++) {
    // 从（**）中截取**
    ruleMap.set(data[i].slice(1, 3), data[i].substring(4))
  }

  // 长度部分
  let lengthRule = ''
  if (ruleMap.has('01') && ruleMap.has('02')) {
    let min = ruleMap.get('02')
    let max = ruleMap.get('01')
    lengthRule = $t('base.passWordRuleLength')
      .replace('[1]', min.replace(/[^0-9]/gi, ''))
      .replace('[2]', max.replace(/[^0-9]/gi, ''))
  } else if (ruleMap.has('01')) {
    let max = ruleMap.get('01')
    lengthRule = $t('base.passWordRuleMaxLength').replace('[1]', max.replace(/[^0-9]/gi, ''))
  } else if (ruleMap.has('02')) {
    let min = ruleMap.get('02')
    $t('base.passWordRuleMinLength').replace('[1]', min.replace(/[^0-9]/gi, ''))
  }

  // 必须包含部分
  let necessaryContains = ''
  if (
    ruleMap.has('03') ||
    ruleMap.has('04') ||
    ruleMap.has('05') ||
    ruleMap.has('06') ||
    ruleMap.has('07')
  ) {
    necessaryContains = $t('base.necessaryContains')
    if (ruleMap.has('03')) {
      necessaryContains = necessaryContains + $t('base.digit') + '、'
    }
    if (ruleMap.has('04') || ruleMap.has('05') || ruleMap.has('06')) {
      if (ruleMap.has('05') || ruleMap.has('06')) {
        if (ruleMap.has('05')) {
          necessaryContains = necessaryContains + $t('base.uppercaseLetters') + '、'
        }
        if (ruleMap.has('06')) {
          necessaryContains = necessaryContains + $t('base.lowercaseLetters') + '、'
        }
      } else if (ruleMap.has('04')) {
        necessaryContains = necessaryContains + $t('base.letter') + '、'
      }
    }
    if (ruleMap.has('07')) {
      necessaryContains = necessaryContains + $t('base.specialCharacters') + '、'
    }
    necessaryContains = necessaryContains.substring(0, necessaryContains.length - 1)
  }

  // 禁止部分
  let forbidRule = ''
  if (ruleMap.has('09')) {
    let forbidRuleContinueCharacter = ruleMap.get('09')
    forbidRule =
      forbidRule +
      $t('base.passwordRuleForbidContinueCharacter').replace(
        '[1]',
        forbidRuleContinueCharacter.replace(/[^0-9]/gi, '')
      ) +
      '、'
  }
  if (ruleMap.has('12')) {
    forbidRule = forbidRule + $t('base.passwordRuleForbidAccount') + '、'
  }
  if (ruleMap.has('13')) {
    forbidRule = forbidRule + $t('base.passwordRuleForbidName') + '、'
  }
  if (forbidRule.length != 0) {
    forbidRule = forbidRule.substring(0, forbidRule.length - 1)
  }

  // 拼接成完整的密码规则
  let outerRule = ''
  if (lengthRule != '') {
    outerRule = lengthRule + '，'
  }
  if (necessaryContains != '') {
    outerRule = outerRule + necessaryContains + '，'
  }
  if (forbidRule != '') {
    outerRule = outerRule + forbidRule + '，'
  }
  if (outerRule == '') {
    return ''
  }
  return outerRule.substring(0, outerRule.length - 1) + '。'
}

// 生成uuid
export function uuid() {
  let len = 36
  let buffer = new Uint8Array(len / 2)
  crypto.getRandomValues(buffer)
  return Array.from(buffer, dec2hex).join('')
}

export function dec2hex(dec) {
  return ('0' + dec.toString(16)).substr(-2)
}

export function defaultFormatter(row, column, cellValue){
  if (
      cellValue === null ||
      cellValue === undefined ||
      cellValue === '' ||
      String(cellValue).trim() === ''
  ) {
    return '-'
  } else {
    return cellValue
  }
}
