import i18n from '@/lang/index.js'
import cookieUtils from "@/utils/cookie.js";

export const channelTypeMap: ReadonlyMap<string, string> = new Map<string, string>([
  ["0", i18n.global.t("ccm.agent.contact.others-channels")],
  ["1", i18n.global.t("ccm.agent.contact.web")],
  ["2", i18n.global.t("ccm.agent.contact.weChat")],
  ["3", i18n.global.t("ccm.agent.contact.faceBook")],
  ["4", i18n.global.t("ccm.agent.contact.twitter")],
  ["5", i18n.global.t("ccm.agent.contact.fiveg")],
  ["6", i18n.global.t("ccm.agent.contact.Email")],
  ["7", i18n.global.t("ccm.agent.contact.voiceBot")],
  ["8", i18n.global.t("ccm.agent.contact.line")],
  ["9", i18n.global.t("ccm.agent.contact.whatsapp")],
  ["10", i18n.global.t("ccm.agent.contact.sms")]
]);

export const mediaAbilityMap: ReadonlyMap<string, string> = new Map<string, string>([
  ["1", i18n.global.t("ccm.agent.contact.Voice")],
  ["3", i18n.global.t("ccm.agent.contact.vidio")],
  ["2", i18n.global.t("ccm.agent.contact.vidio")],
  ["5", i18n.global.t("ccm.agent.contact.multimedia")]
]);

export const staffHangupMap: ReadonlyMap<string, string> = new Map<string, string>([
  ["0", i18n.global.t("ccm.agent.contact.userhungup")],
  ["1", i18n.global.t("ccm.agent.contact.agenthungup")],
  ["3", i18n.global.t("ccm.agent.contact.otherhungup")],
  ["5", i18n.global.t("ccm.agent.contact.skillhungup")],
  ["6", i18n.global.t("ccm.agent.contact.businesshungup")],
  ["7", i18n.global.t("ccm.agent.contact.numhungup")],
  ["8", i18n.global.t("ccm.agent.contact.ivrhungup")],
  ["9", i18n.global.t("ccm.agent.contact.timeouthungup")],
  ["10", i18n.global.t("ccm.agent.contact.skilltimeouthungup")]
]);

export const dataTypeItems =[
  {
    "dataType": 'voiceFile',
    "dataTypeName": i18n.global.t('ccm.transtask.label.voiceFile')
  },
  {
    "dataType": 'report',
    "dataTypeName": i18n.global.t('ccm.transtask.label.reportData')
  },
  {
    "dataType": 'billLog',
    "dataTypeName": i18n.global.t('ccm.transtask.label.cdrData')
  },
  {
    "dataType": 'contactRed',
    "dataTypeName": i18n.global.t('ccm.transtask.label.contactRecord')
  },
  {
    "dataType": 'chatRecord',
    "dataTypeName": i18n.global.t('ccm.transtask.label.chatRecord')
  },
  {
    "dataType": 'intelligentInspection',
    "dataTypeName": i18n.global.t('ccm.transtask.label.intelligentInspection')
  }
];

/**
 *  呼叫类型国际化Map
 */
export const callTypeMap: ReadonlyMap<string, string> = new Map<string, string>([
  ["0", i18n.global.t("ccm.agent.contact.callin")],
  ["1", i18n.global.t("ccm.agent.contact.callout")],
  ["2", i18n.global.t("ccm.agent.contact.predictiveCall")],
  ["3", i18n.global.t("ccm.agent.contact.preCallout")],
  ["4", i18n.global.t("ccm.agent.contact.preemptionCallout")],
  ["5", i18n.global.t("ccm.agent.contact.itacall")],
  ["10",i18n.global.t( "ccm.agent.contact.callinandout")],
  ["11",i18n.global.t( "ccm.agent.contact.assistant")],
  ["12",i18n.global.t( "ccm.agent.contact.clicktocall")]
]);

/**
 * 原因码类型
 */
export const reasonCodeTypeMap: ReadonlyMap<String, String> = new Map<String, String>([
  ["0", i18n.global.t("ccm.agent.contact.calledhangup")],
  ["1", i18n.global.t("ccm.agent.contact.callershutdown")],
  ["2", i18n.global.t("ccm.agent.contact.callerfailed")],
  ["3", i18n.global.t("ccm.agent.contact.calledfailed")],
  ["4", i18n.global.t("ccm.agent.contact.callernook")],
  ["5", i18n.global.t("ccm.agent.contact.callednook")],
  ["6", i18n.global.t("ccm.agent.contact.startcallfailed")]
]);

/**
 * 导出任务状态映射Map
 * @return {ReadonlyMap<String,String>}
 */
export const exportTaskStatusMap: ReadonlyMap<String, String> = new Map<String, String>([
  ["0", i18n.global.t('ccm.custdata.operlog.exportiong')],
  ["1", i18n.global.t('ccm.custdata.operlog.failed')],
  ["2", i18n.global.t('ccm.custdata.operlog.success')],
])

/**
 * 多媒体会话内容一次查询100条聊天消息
 */
export const MULTIMEDIA_MESSAGE_LIMIT = 100

/**
 * 邮件渠道
 */
 export const CHANNEL_TYPE_EMAIL = 6

/**
 * 接触激励录音文件列宽度信息映射表
 *
 * @description 需要适配不同语言不同分辨率下的字体大小变化，为保证内容展示完整，也需要对其处理
 */
const CONTACT_FILERECORDING_COLUMN_WIDTH_MAP : ReadonlyMap<String, ReadonlyMap<Number, String>> = new Map<String, ReadonlyMap<Number, String>>([
  ['zh_CN', new Map<Number, String>([[1920, "110px"], [1440, "105px"]])],
  ["en_US", new Map<Number, String>([[1920, "145px"], [1440, "125px"]])],
  ["es_ES", new Map<Number, String>([[1920, "185px"], [1440, "165px"]])],
  ["th_TH", new Map<Number, String>([[1920, "130px"], [1440, "115px"]])],
  ["ar_SA", new Map<Number, String>([[1920, "185px"], [1440, "165px"]])],
  ["fa_IR", new Map<Number, String>([[1920, "185px"], [1440, "165px"]])]
]);

/**
 * 获取接触记录 录音文件 列宽度配置
 * @return { String } 录音文件列宽度信息
 */
export const getContactFileRecordsColumnWidth = (): String => {
  // 获取应设置的宽度值
  const widthKey: Number = isNotLessThan1920Resolution() ? 1920 : 1440;
  const localeKey = cookieUtils.getSystemLanguage();
  return CONTACT_FILERECORDING_COLUMN_WIDTH_MAP.get(localeKey)?.get(widthKey) || "auto";
}


/**
 * 当前分辨率是否小于 1920*1080
 */
function isLessThan1920Resolution() {
  return (document.documentElement.clientWidth || 0) < 1920;
}

/**
 * 当前分辨率是否不小于1920*1080
 * @see isLessThan1920Resolution
 */
function isNotLessThan1920Resolution() {
  return !isLessThan1920Resolution();
}

/**
 * 获取接触记录 呼叫流水号列宽
 * @description 因不同分辨率下，字体大小不同。故需要根据分辨率设置不同的宽度
 */
export const getContactCallSerialnoColumnWidth = (): String => {
  return isNotLessThan1920Resolution() ? "178px" : "156px"
}

/**
 * 获取接触记录 时间相关列宽
 * @description 因不同分辨率下，字体大小不同。故需要根据分辨率设置不同的宽度
 */
export const getContactDateTimeColumnWidth = () : String => {
  return isNotLessThan1920Resolution() ? "168px" : "144px"
}

/**
 * 接触详情文档信息操作列宽度信息映射表
 *
 * @description 需要适配不同语言不同分辨率下的字体大小变化，为保证内容展示完整，也需要对其处理
 */
const CONTRACT_OPERATION_COLUMN_WIDTH_MAP : ReadonlyMap<String, ReadonlyMap<Number, String>> = new Map<String, ReadonlyMap<Number, String>>([
  ['zh_CN', new Map<Number, String>([[1920, "98px"], [1440, "86px"]])],
  ["en_US", new Map<Number, String>([[1920, "150px"], [1440, "132px"]])],
  ["es_ES", new Map<Number, String>([[1920, "180px"], [1440, "160px"]])],
  ["th_TH", new Map<Number, String>([[1920, "150px"], [1440, "136px"]])],
  ["pt_BR", new Map<Number, String>([[1920, "200px"], [1440, "180px"]])],
  ["fr_FR", new Map<Number, String>([[1920, "200px"], [1440, "180px"]])],
  ["ar_SA", new Map<Number, String>([[1920, "200px"], [1440, "180px"]])]
]);

/**
 * 获取接触详情文档信息操作列宽度配置
 * @return { String } 操作列宽度信息
 */
export const getContractOperationColumnWidth = (): String => {
  // 获取应设置的宽度值
  const widthKey: Number = (document.documentElement.clientWidth || 0) >= 1920 ? 1920 : 1440;
  const localeKey = cookieUtils.getSystemLanguage();
  return CONTRACT_OPERATION_COLUMN_WIDTH_MAP.get(localeKey)?.get(widthKey) || "auto";
}

/**
 * 媒体类型映射关系
 */
export const mediaTypeMap: ReadonlyMap<number, string> = new Map<number, string>([
  [5, i18n.global.t('ccm.agent.contact.Voice')],
  [53, i18n.global.t('ccm.agent.contact.multimedia')],
  [19, i18n.global.t('ccm.agent.contact.vidio')],
  [2, i18n.global.t('ccm.agent.contact.audioclicktodial')],
  [18, i18n.global.t('ccm.agent.contact.videoclicktodial')]
]);

/**
 * 媒体子类型映射关系
 */
export const subMediaTypeMap: ReadonlyMap<number, string> = new Map<number, string>([
  [1, i18n.global.t("ccm.agent.contact.web")],
  [2, i18n.global.t("ccm.agent.contact.whatsapp")],
  [3, i18n.global.t("ccm.agent.contact.line")],
  [4, i18n.global.t("ccm.agent.contact.weChat")],
  [5, i18n.global.t("ccm.agent.contact.faceBook")],
  [6, i18n.global.t("ccm.agent.contact.twitter")],
  [7, i18n.global.t("ccm.agent.contact.fiveg")],
  [8, i18n.global.t("ccm.agent.contact.Email")],
  [9, i18n.global.t("ccm.agent.contact.sms")],
  [11, i18n.global.t("ccm.agent.contact.Instagram")],
  [12, i18n.global.t("ccm.agent.contact.Telegram")],
]);

export const mediaTypeObject={
  '5':'voice',
  '2':'audioclicktodial',
  '18':'videoclicktodial',
  '19':'vidio',
  '53-1':'Web',
  '53-2':'WhatsApp',
  '53-3':'LINE',
  '53-4':'WeChat',
  '53-5':'Facebook',
  '53-6':'X (Twitter)',
  '53-7':'fiveG',
  '53-8':'Email',
  '53-9':'SMS',
  '53-11':'Instagram',
  '53-12':'Telegram',
  '53':'multimedia',
}

export const subMediaTypeObject={
  '1':'Web',
  '2':'WhatsApp',
  '3':'LINE',
  '4':'WeChat',
  '5':'Facebook',
  '6':'X (Twitter)',
  '7':'fiveG',
  '8':'Email',
  '9':'SMS',
  '11':'Instagram',
  '12':'Telegram',
}

/** 系统参数枚举 */
export enum SystemParamEnum {

  /** 导出数据设置压缩密码 */
  EXPORT_DATA_WITH_ZIP_PWD = "6001001000029"
}