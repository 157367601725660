export default {
  "webclient.body.message.bot.recommend": "Suponho que queira perguntar",
  "webclient.body.message.card.url": "Copiar link",
  "webclient.body.message.cobrowse.agree": "Concordo",
  "webclient.body.message.cobrowse.cancel": "Cancelar",
  "webclient.body.message.cobrowse.target": "Procurar destino:",
  "webclient.body.message.cobrowse.title": "O agente solicita uma navegação colaborativa com você",
  "webclient.body.message.file.download": "Baixar",
  "webclient.body.message.file.download.again": "Baixar novamente",
  "webclient.body.message.locate.latitude": "Latitude",
  "webclient.body.message.locate.longitude": "Longitude",
  "webclient.body.message.recall": "retirou uma mensagem",
  "webclient.body.name.assistant": "Assistente inteligente",
  "webclient.body.tip.leave.click": "Clique aqui para deixar uma mensagem",
  "webclient.body.tip.leave.message": "Se necessário, por favor",
  "webclient.body.tip.leave.message.finish": "Sua mensagem foi enviada com sucesso.Você pode deixar outra mensagem.",
  "webclient.body.tip.privacy": "Para melhorar a qualidade do serviço, vamos manter o histórico de bate-papo entre você e o representante de atendimento ao cliente ou o histórico de bate-papo do robô.Garantiremos a segurança dos dados por criptografia ou controle de direitos de acesso.",
  "webclient.body.tip.queue.cancel": "Canceando fila",
  "webclient.body.tip.queue.cancel.done": "O enfileiramento cancelado com êxito, por favor, continue enfileirando mensagens",
  "webclient.body.tip.queue.cancel.robot": "Cancelamento de fila cancelado com sucesso, conectando robô...",
  "webclient.body.tip.queue.part1": "Você é o número ",
  "webclient.body.tip.queue.part2": " na fila e precisa esperar ",
  "webclient.body.tip.queue.part3": " segundos. ",
  "webclient.body.tip.queue.timeout": "A fila expirou o tempo limite",
  "webclient.body.tip.session.end": "Sessão encerrada",
  "webclient.body.tip.session.reconnect": "reinicializar sessão",
  "webclient.body.tip.session.timeout": "Você não respondeu dentro do tempo especificado, o sistema encerrou automaticamente a sessão. Você pode",
  "webclient.body.tip.token.invalid": "Token expirou ou foi conectado de forma diferente",
  "webclient.body.title.call.disconnect": "Chamada desconectada",
  "webclient.body.title.call.end": "Chamada encerrada",
  "webclient.body.title.call.fail": "Falha na chamada",
  "webclient.body.title.call.queue": "Chamada na fila",
  "webclient.body.title.call.start": "Chamada",
  "webclient.body.title.call.timeout": "Timeout de fila de chamadas",
  "webclient.body.title.call.transfer": "Transferência de chamada",
  "webclient.box.tip.click.cannot.link": "Não pode se conectar ao gateway WebRTC",
  "webclient.box.tip.click.check.device.fail": "Falha ao verificar se o Click-to-Call é suportado pelo locatário",
  "webclient.box.tip.click.no.audio.input": "Dispositivos de entrada de áudio não disponíveis",
  "webclient.box.tip.click.no.audio.output": "Dispositivos de saída de áudio não disponíveis",
  "webclient.box.tip.click.no.video.input": "Dispositivos de entrada de vídeo não disponíveis",
  "webclient.box.tip.click.not.support.browser": "O navegador não suporta WebRTC",
  "webclient.box.tip.click.not.support.channel": "Click-to-Call não suportado pelo canal",
  "webclient.box.tip.click.not.support.tenant": "WebRTC não suportado pelo locatário",
  "webclient.box.tip.cobrowse.no.auth": "O agente não tem permissão de colaboração na Web",
  "webclient.chat.nickname.system": "Sistema",
  "webclient.dialog.evaluate.placeholder": "Insira sua análise",
  "webclient.dialog.evaluate.title": "Por favor, evalúe el servicio de atención al cliente!",
  "webclient.dialog.leave.format.content": "O conteúdo da mensagem não pode estar vazio e não pode conter caracteres especiais",
  "webclient.dialog.leave.format.phone": "O número não pode estar vazio e pode conter apenas dígitos, - ou",
  "webclient.dialog.leave.phone": "Insira um número de celular",
  "webclient.dialog.leave.success": "Mensagem deixada com sucesso",
  "webclient.dialog.leave.text": "Deixe uma mensagem",
  "webclient.dialog.leave.title": "mensagem de saída",
  "webclient.dialog.selectArea.title": "Selecione a área a ser obscurecida",
  "webclient.dialog.video.default": "Padrão",
  "webclient.dialog.video.hide": "Ocultar vídeo local",
  "webclient.dialog.video.large": "Tamanho grande",
  "webclient.dialog.video.medium": "Tamanho médio",
  "webclient.dialog.video.pause": "Pausar vídeo",
  "webclient.dialog.video.share.off": "Cancelar o compartilhamento",
  "webclient.dialog.video.share.on": "Uso compartido de escritorio",
  "webclient.dialog.video.size": "Redimensionamento de vídeo",
  "webclient.dialog.video.small": "Tamanho pequeno",
  "webclient.dialog.video.start": "Desmutar vídeo",
  "webclient.footer.label.send.message": "Enviar mensagem",
  "webclient.footer.label.share.direct": "Compartilhamento Direto",
  "webclient.footer.label.share.sensitive": "Selecionar áreas sensíveis",
  "webclient.footer.label.stop.direct": "Parar de compartilhar",
  "webclient.footer.placeholder.richtext": "Por favor, insira a pergunta que você deseja fazer",
  "webclient.footer.send.fail.content.big": "O conteúdo enviado excede o comprimento máximo (500 caracteres)",
  "webclient.footer.send.fail.file.format": "O formato de arquivo não atende aos requisitos",
  "webclient.footer.send.fail.file.other": "Falha ao enviar o arquivo",
  "webclient.footer.send.fail.file.screen": "Falha ao enviar o arquivo de captura de tela",
  "webclient.footer.send.fail.file.size.audio": "O tamanho do arquivo de áudio não pode exceder ",
  "webclient.footer.send.fail.file.size.common": "O tamanho do arquivo não pode exceder ",
  "webclient.footer.send.fail.file.size.video": "O tamanho do arquivo de vídeo não pode exceder ",
  "webclient.footer.send.fail.no.file": "Selecionar arquivo",
  "webclient.footer.send.fail.picture.with.text": "Atualmente, imagens não podem ser enviadas juntamente com textos ou emoticons",
  "webclient.footer.tip.cobrowse.share": "A ação atual compartilhará a página do seu navegador, que pode conter seus dados pessoais. Continuar?",
  "webclient.footer.tip.connect.fail": "Falha na conexão , tente novamente ou entre em contato com o administrador",
  "webclient.footer.tip.download": "O conteúdo do bate-papo que você baixou está em formato HTML, e o conteúdo multimídia no bate-papo é baseado no tempo. É recomendável convertê-lo em um arquivo PDF imediatamente após o download. Passos para converter HTML em PDF: 1. Abra o arquivo HTML que deseja converter em um navegador; Pressione Ctrl+P (Windows) ou Command+P (Mac) para abrir a caixa de diálogo de impressão; 3. Na caixa de diálogo Imprimir, selecione \"Salvar como PDF\" em \"Impressora de destino\"; 4. Clique no botão Salvar para converter a página HTML para o formato PDF e salvá-la em seu computador local.",
  "webclient.footer.tooltip.audio": "Áudio",
  "webclient.footer.tooltip.cobrowse": "Compartilhar",
  "webclient.footer.tooltip.download": "Baixar conversas",
  "webclient.footer.tooltip.emotion": "Emoção",
  "webclient.footer.tooltip.evaluation": "Avaliar",
  "webclient.footer.tooltip.file": "Carregar arquivos",
  "webclient.footer.tooltip.more": "Mais",
  "webclient.footer.tooltip.send": "Enviar",
  "webclient.footer.tooltip.send.way": "Método de envio",
  "webclient.footer.tooltip.video": "Vídeo",
  "webclient.header.title.input": "Atendimento ao Cliente Online",
  "webclient.header.title.service": "Atendimento ao Cliente",
  "webclient.header.tooltip.close": "Encerrar",
  "webclient.header.tooltip.transfer.agent": "Transformar para agente",
  "webclient.header.tooltip.transfer.robot": "Transformar para robô",
}