export default {
  "agentconsole.menu.title": "ปรับแต่งเสียงและวิดีโอ",
  "casemanagement.casequery.button.create": "สร้างเคส",
  "agentconsole.callreason.tips.saved": "บันทึกเหตุผลการโทรแล้ว.",
  "iolp.workflow.title.name": "กระบวนการทางธุรกิจ",
  "agentconsole.title.onlycustomer": "เฉพาะลูกค้า",
  "agentconsole.title.allchat": "เนื้อหาทั้งหมด",
  "agentconsole.title.realtimetransfer": "การถ่ายโอนตามเวลาจริง",
  "agentconsole.title.intelligentcase": "คําสั่งอัจฉริยะ",
  'agentconsole.management.message.filterYes': 'ใช่',
  'agentconsole.management.message.filterNo': 'ไม่ใช่',
  "agentconsole.dashboard.enumeration.aweek": "สัปดาห์ที่แล้ว",
  "SM.LOGIN.BUTTON.CHANGEPWD_CONFIRM": "ตกลง",
  "agentconsole.Intelligent.details": "รายละเอียด",
  "agentconsole.workbench.label.intelligentrecommendation": "ผู้ช่วยตัวแทน",
  "agentconsole.dashboard.title.case": "ภาพรวมกรณีศึกษา",
  "agentconsole.header.label.searchtask": "ค้นหาในงาน",
  "SM.LOGIN.TITLE.NOTICE_EXP_TIME": "ระยะเวลาหมดอายุ",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SUFFIX": "), ยืนยัน:",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME_TO": "ถึง",
  "SM.LOGIN.LABEL.OPER_PHONE": "หมายเลขโทรศัพท์:",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_OPEN_ES": "ระบบฉุกเฉินไม่เริ่มทำงาน กรุณาเข้าไปที่เมนูการผลิตเพื่อดำเนินการทางธุรกิจ",
  "agentconsole.workbench.label.callinforeasons": "เหตุผลระดับแรก / เหตุผลระดับสอง / เหตุผลระดับสาม / เหตุผลระดับสี่",
  "agentconsole.dashboard.title.averageevalution": "ความพึงพอใจโดยเฉลี่ย",
  "agentconsole.header.title.send.sms.notification": "การแจ้งเตือน SMS ขาออก",
  "agentconsole.index.label.caseconsole": "พื้นที่ทำงาน ของเคส",
  "agentconsole.login.label.empty": "ชื่อผู้ใช้หรือรหัสผ่านว่างเปล่า",
  "agentconsole.authmanage.label.authtype.menu": "สิทธิ์ของเมนู",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT_TIPS": "ป้อนบัญชีสำหรับลงชื่อเข้าใช้",
  "SM.LOGIN.LABEL.CHANGEPWD_OLDPWD": "รหัสผ่านเก่า",
  "agentconsole.login.label.password": "รหัสผ่าน",
  "agentconsole.header.title.send.whatsapp.notification": "การส่งข้อความแจ้งเตือน WhatsApp",
  "agentconsole.login.label.signin": "ลงชื่อเข้าใช้",
  "agentconsole.index.label.chatconsole": "พื้นที่ทำงาน ของแชท",
  "SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE": "รหัสผ่านไม่เป็นไปตามกฎรหัสผ่าน",
  "agentconsole.menumanage.message.paramvalidatefailed": "สร้างเมนูไม่สำเร็จ ตรวจสอบพารามิเตอร์",
  "SM.LOGIN.LABEL.OPER_REGION": "พื้นที่หน้าหลัก:",
  "SM.LOGIN.TIPS.CHANGEPWD_COMPARE": "รหัสผ่านใหม่ต้องเหมือนกับรหัสผ่านยืนยัน",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG": "รว Child OU",
  "agentconsole.common.button.modify": "ปรับเปลี่ยน",
  "agentconsole.msg.noData": "ไม่มีข้อมูล",
  "SM.LOGIN.TITLE.NOTICE": "กระดานข่าว",
  "SM.LOGIN.TIPS.LOGINSUCCESS": "ลงชื่อเข้าใช้สำเร็จ",
  "SM.LOGIN.TITLE.PORTAL_REGION_TITLE": "ภูมิภาค",
  "agentconsole.quickentrance.classname": "กรุณาระบุบัญชีรายการการตีราคาทรัพย์สิน เช่น การบริหารจัดการทรัพยากรของผู้เช่า",
  "SM.LOGIN.TITLE.PORTAL_TITLE": "ระบบ BES Portal",
  "SM.LOGIN.MESSAGE.LOGOUT": "ต้องการออกใช่หรือไม่?",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SWITCH": "เข้าถึงระบบหน้าหลัก",
  "agentconsole.dashboard.title.taskunassigned": "ยังไม่ได้จัดสรร",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_EMPTY": "รหัสผ่านใหม่ว่างเปล่า",
  "SM.LOGIN.BUTTON.RESET": "รีเซ็ต",
  "agentconsole.ccnotification.inputOneRecipient": "โปรดป้อนผู้รับ",
  "agentconsole.ccnotification.inputRecipient": "โปรดป้อนผู้รับ ผู้รับหลายคนคั่นด้วยเครื่องหมายจุลภาค",
  "agentconsole.ccnotification.recipient.validate.limit.one": "จํานวนผู้รับต้องไม่เกิน 1 คน",
  "agentconsole.callmessage.callerNo": "ผู้โทร",
  "SM.LOGIN.LABEL.SUM": "การบริหารจัดการผู้ใช้ระบบ",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES": "ธุรกิจได้ถูกสลับอยู่ในสถานะฉุกเฉิน จะจัดการธุรกิจได้อีกครั้งหลังจากระบบกลับคืนสู่สถานะปกติแล้วเท่านั้น",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NOT_EXIST": "ไม่มีรหัสยืนยัน",
  "agentconsole.dashboard.evaluation.medium": "3-4 คะแนน",
  "agentconsole.header.title.logout": "ลงชื่ออก",
  "agentconsole.index.label.casedec": "คุณสามารถสร้างเคสสำหรับคำขอรับบริการของลูกค้าแต่ละรายได้ และสามารถตรวจสอบขั้นตอนการดำเนินการของคำขอรับบริการของลูกค้าผ่านเคส เพื่อให้แน่ใจว่าคำขอของลูกค้าได้รับการดำเนินการแล้วเสร็จและปรับปรุงความพึงพอใจของลูกค้า",
  "agentconsole.common.tips.error": "ผิดพลาด",
  "agentconsole.menumanage.title.selectauth": "กรุณาเลือกการอนุญาต",
  "SM.LOGIN.BUTTON.FORGETPWD_CANCEL": "ยกเลิก",
  "agentconsole.Intelligent.noRecord": "บริการไม่ได้รับการจัดการหรือผลการค้นหาว่างเปล่า",
  "agentconsole.dashboard.evaluation.low": "1-2 คะแนน",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_RULE": "รหัสผ่านใหม่ไม่ตรงกับเกณฑ์การตั้งรหัสผ่าน",
  "SM.LOGIN.MESSAGE.SENDSMS_FAILED": "การส่งข้อความ SMS ล้มเหลว ตรวจสอบ backend log",
  "agentconsole.header.title.notificationcenter": "การแจ้งเตือน",
  "agentconsole.login.indexforipcc.help": "ช่วยเหลือ",
  "agentconsole.contacthistory.title": "ประวัติการติดต่อ",
  "agentconsole.authmanage.field.auth_type": "ประเภทการอนุญาต",
  "SM.PORTAL.LABEL.INPUT_OLD_PWD": "กรอกรหัสผ่านเก่า",
  "SM.LOGIN.BUTTON.FORGETPWD_CONFIRM": "ตกลง",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL_TIPS": "กรอกอีเมล",
  "SM.AUC.MESSAGE.PWDRULE_NO": "ไม่",
  "agentconsole.callmessage.faq.spread": "ขยาย",
  "agentconsole.querycustominfo.msg.inputHandleNumber": "ป้อนหมายเลขที่จัดการ",
  "agentconsole.login.indexforipcc.difbutton": "ปุ่มควบคุมการทำงานที่ต่างกันสามารถแสดงตามอำนาจของเจ้าหน้าที่บริการลูกค้า เพื่อหลีกเลี่ยงการทำงานที่ผิดพลาดและการรบกวนที่ไม่จำเป็น ดังนั้นช่วยปรับปรุงประสิทธิภาพของการให้บริการของเจ้าหน้าที่บริการลูกค้า",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGIN_ID_NULL": "การลงชื่อเข้าใช้ไม่สามารถว่างเปล่าได้",
  "agentconsole.common.button.ok": "ตกลง",
  "agentconsole.login.indexforipcc.agentcall": "การควบคุมการโทรของเจ้าหน้าที่เช่น การรับสาย การโอนสาย การโทรสามสาย การขอความช่วยเหลือ ฯลฯ",
  "agentconsole.contacthistory.oneMin": "1 นาทีที่ผ่านมา",
  "SM.LOGIN.TITLE.PASSWORD_HASEXPIRED": "รหัสผ่านของคุณหมดอายุ กรุณาติดต่อผู้ดูแลระบบเพื่อรีเซ็ตรหัสผ่าน",
  "SM.LOGIN.LABEL.FRAMEWORK_CALCULATOR": "เครื่องคิดเลข",
  "SM.LOGIN.LABEL.NOTICE_GRADE_NORMAL": "ปกติ",
  "SM.LOGIN.LABEL.CUS_LOGIN": "การลงชื่อเข้าใช้ของลูกค้า",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLOYEE": "การรีเซ็ตรหัสผ่านผู้เช่าเป็นชุด",
  "agentconsole.sessionmanagement.label.clientIp": "IP ลูกค้า",
  "agentconsole.header.label.searchbase": "ค้นหาในศูนย์ความรู้",
  "agentconsole.login.resetpwd.empty": "ชื่อผู้ใช้หรือที่อยู่อีเมลว่างเปล่า",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED": "เปิดเมนูไม่สำเร็จ",
  "SM.LOGIN.TITLE.LOGIN_TITLE": "หน้าลงชื่อเข้าใช้ระบบ BES Portal",
  "agentconsole.login.indexforipcc.cccbar": "แถบที่นั่งในกรอบเจ้าหน้าที่จัดให้มีฟังก์ชันการประมวลผลการโทรหลักและการควบคุมสถานะเจ้าหน้าที่",
  "SM.LOGIN.TIPS.UPDATE_PWD": "เปลี่ยนรหัสผ่าน",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNT": "บัญชี",
  "SM.LOGIN.TITLE.CURRENT_BE": "เมืองปัจจุบัน",
  "agentconsole.workbench.queNum": "จำนวนลูกค้าที่อยู่ในคิวปัจจุบัน:",
  "agentconsole.callmessage.faq.folded": "ยุบ",
  "SM.LOGIN.TITLE.PROJECT_CREATER": "ผู้สร้างสรรค์:",
  "SM.LOGIN.MESSAGE.LOGIN.LABEL.EMPTY": "ชื่อผู้ใช้หรือรหัสผ่านว่างเปล่า",
  "agentconsole.menumanage.message.urlvalidatefailed": "สร้างเมนูไม่สำเร็จ ติดต่อผู้ดูแลระบบเพื่อตรวจสอบรายการความน่าเชื่อถือของที่อยู่ IP",
  "SM.LOGIN.TITLE.PASSWORD_INIT_SHOULD": "คุณกำลังใช้รหัสผ่านเริ่มต้น เปลี่ยนรหัสผ่านทันที",
  "SM.LOGIN.LABEL.RESETPWD.FAIL": "การรีเซ็ตรหัสผ่านล้มเหลว",
  "agentconsole.dashboard.title.caseunhandle": "ไม่ผ่านการดำเนินการ",
  "agentconsole.login.indexforipcc.connectcontrol": "ควบคุมสถานะการเชื่อมต่อ",
  "SM.LOGIN.TIPS.MODIFY_SUCCESS": "ปรับเปลี่ยนสำเร็จ",
  "SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS": "หน่วย: วินาที ช่วง: 1 ถึง 86400 หากให้พารามิเตอร์นี้เว้นว่างไว้จะใช้ค่าเริ่มต้น 3600",
  "agentconsole.dashboard.title.casecreate": "จำนวนเคสที่ถูกสร้าง",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGINID_NO_BIND_MSISDN": "ผู้ใช้ไม่ได้ผูกมัดกับหมายเลขโทรศัพท์มือถือใด ๆ",
  "SM.PORTAL.LABEL.INPUT_NEW_PWD": "กรอกรหัสผ่านใหม่",
  "agentconsole.menumanage.message.renamenode": "ชื่อเมนูซ้ํากัน",
  "agentconsole.contacthistory.accept": "หมายเลขที่กำลังดำเนินการ",
  "SM.LOGIN.LABEL.REMEMBER": "จดจำชื่อผู้ใช้",
  "agentconsole.login.indexforipcc.signinsuccess": "หลังจากเจ้าหน้าที่เข้าทำงาน เจ้าหน้าที่สามารถรับสายลูกค้าและจัดการธุรกิจ ระบบกำหนดการโทรของลูกค้าตามจำนวนเจ้าหน้าที่ที่เข้าทำงาน เจ้าหน้าที่บริหารจัดการการเข้าร่วมตามเวลาการเข้าออกงานของเจ้าหน้าที่",
  "agentconsole.login.indexforipcc.usenow": "ใช้ทันที",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD": "ยืนยันรหัสผ่าน",
  "agentconsole.authmanage.label.authtype.interface": "สิทธิ์ในการเชื่อมต่อ",
  "SM.LOGIN.LABEL.RESETPWD": "คลิกรีเซ็ต รหัสผ่านใหม่จะถูกส่งไปยังกล่องจดหมายของคุณ",
  "agentconsole.login.indexforipcc.callcontrol": "การควบคุมการโทรด้วยเสียง",
  "agentconsole.header.title.send.notification": "ส่งการแจ้งเตือน",
  "agentconsole.login.indexforipcc.difauth": "จัดการ องค์กร บทบาท สิทธิ์ และพนักงานของผู้เช่า หลังจากเจ้าหน้าที่บริการลูกค้าเข้าสู่ระบบโดยใช้บัญชีพนักงาน ปุ่มควบคุมการโทรและหน้าเมนูที่สามารถเรียกดูได้จะแตกต่างกันไปตามสิทธิ์ที่แตกต่างกัน เฉพาะเมื่อผู้ใช้เข้าสู่ระบบ แต่ไม่ได้เข้าทำงานจะไม่สามารถดำเนินการเกี่ยวกับการโทรได้",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.IP_CHECK_FAILED": "การยืนยันที่อยู่ IP ล้มเหลว ที่อยู่ IP ปัจจุบันแตกต่างจากที่อยู่ในการลงชื่อเข้าใช้ครั้งแรก",
  "agentconsole.menumanage.message.menuname": "จํานวนไบต์ต้องน้อยกว่า 50",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_OPEN_BE_ES": "พื้นที่ปัจจุบันถูกสลับอยู่ในสถานะเป็นฉุกเฉิน ลงชื่อเข้าใช้ระบบฉุกเฉินเพื่อจัดการธุรกิจ",
  "agentconsole.login.indexforipcc.doublecontrol": "ระบบคลาวด์ของการบริการลูกค้าอัจฉริยะมีการควบคุมการพิสูจน์ตัวตนสองชั้นสำหรับระบบการล็อกอินของเจ้าหน้าที่:",
  "SM.LOGIN.MESSAGE.SUGGESTION": "พร้อม",
  "SM.LOGIN.MESSAGE.SEARCH_HISTORY_CLEAR": "ล้างประวัติ",
  "SM.LOGIN.TITLE.ICPCODE": "Jiangsu ICP Registration Number: {0}-{1}",
  "SM.LOGIN.LABEL.OPER_LOGINID": "ลงชื่อเข้าใช้บัญชี:",
  "SM.LOGIN.LABEL.NOTICE_ORG": "โครงสร้างองค์กร",
  "agentconsole.quickentrance.classdesc": "กรุณาเขียนบันทึกสำหรับสารบบนี้ ตัวอย่างเช่น จัดการทรัพยากรของผู้เช่า คุณสามารถดูการบำรุงรักษาทรัพยากรของผู้เช่าได้ที่นี่",
  "SM.LOGIN.LABEL.CHANGEPWD_ACCOUNT": "ชื่อบัญชี",
  "SM.LOGIN.TIPS.LOGINRESULT": "ผลการลงชื่อเข้าใช้:",
  "SM.LOGIN.TITLE.CHANGEPWD_ERROR": "ผิดพลาด",
  "SM.LOGIN.LABEL.AUTH_FAILED_ES": "เมืองปัจจุบันเข้าสู่สถานะฉุกเฉินแล้ว กรุณาลงชื่อเข้าใช้ระบบฉุกเฉิน",
  "agentconsole.custinfo.label.amount": "จำนวนชำระ",
  "agentconsole.sessionmanagement.label.startime": "เวลาเริ่ม",
  "SM.LOGIN.LABEL.FRAMEWORK_ONLINE_HELP": "ความช่วยเหลืออนไลน์",
  "agentconsole.sessionmanagement.label.accountNumber": "บัญชี",
  "SM.INDEX.LABEL.TASK": "งาน",
  "SM.LOGIN.LABEL.VCODE_INVALID": "รหัสยืนยันไม่ถูกต้อง",
  "agentconsole.dashboard.evaluation.unit": "จำนวน",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_CLOSE": "รายละเอียด>",
  "agentconsole.authmanage.message.renamenode": "เปลี่ยนชื่อการอนุญาต",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_GETCODE": "ขอรับรหัสยืนยัน",
  "agentconsole.index.label.querycase": "เคสการค้นหา",
  "agentconsole.workbench.talkNum": "จำนวนการโทรวันนี้:",
  "agentconsole.login.label.verifycode": "รหัสยืนยัน",
  "agentconsole.custinfo.label.dueDate": "วันครบกำหนด",
  "SM.INDEX.LABEL.CHATDEC": "ในระหว่างการสนทนาออนไลน์แบบมัลติมีเดียกับลูกค้าของคุณ คุณสามารถดูข้อมูลการติดต่อในอดีตที่ผ่านมาของลูกค้าเพื่อเพิ่มประสิทธิภาพการบริการของคุณ และมอบประสบการณ์การบริการ Omnichannel ให้กับลูกค้าของคุณ",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPFAIL": ", พนักงานล้มเหลว:",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_MSG": "หากคุณต้องการเข้าสู่ระบบของ BE จะต้องออกจากระบบนี้ คุณจำเป็นต้องกรอกชื่อผู้ใช้และรหัสผ่านเพื่อลงชื่อเข้าใจ ต้องการดำเนินการต่อหรือไม่",
  "agentconsole.dashboard.title.taskunhandle": "ยังไม่ได้ดำเนินการ",
  "SM.LOGIN.MESSAGE.CHANGEPWD_FAILED": "การเปลี่ยนรหัสผ่านล้มเหลว",
  "agentconsole.sessionmanagement.table.deleteConfimMessage": "คุณต้องการลบเซสชันนี้ใช่หรือไม่",
  "agentconsole.wordreminder.norecommend": "ไม่มีสคริปต์ที่แนะนำ",
  "agentconsole.login.indexforipcc.agent": "การรับรองเจ้าหน้าที่",
  "agentconsole.login.indexforipcc.transfer": "การโอนสาย",
  "agentconsole.custinfo.label.custName": "ชื่อลูกค้า",
  "SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB": "ป้อนอักขระอย่างน้อย {0} ตัว",
  "agentconsole.index.label.taskconsole": "พื้นที่ทำงานของงาน",
  "SM.LOGIN.MESSAGE.SENDMAIL_FAILED": "การส่งอีเมลล้มเหลว",
  "SM.LOGIN.LABEL.OPER_BE": "BE:",
  "agentconsole.index.label.taskdec": "ในระหว่างการดำเนินการ คุณสามารถจ่ายงานเพื่อทำงานร่วมกับหลายแผนกและบุคลากรได้ สามารถสร้างงานได้หลายงานสำหรับเคสหนึ่ง",
  "agentconsole.dashboard.title.casepriority": "รายการสิ่งที่ต้องทำ",
  "agentconsole.menumanage.message.notdelnoleafmenu": "ไม่อนุญาตให้ลบเมนูที่มีเมนูย่อย",
  "SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT": "กรุณาใส่ชื่อ",
  "agentconsole.header.title.messageCenter": "การจัดการข้อความภายใน",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_PROJECT": "สลับโครงการ",
  "agentconsole.login.label.email": "อีเมล",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CANCEL": "ยกเลิก",
  "SM.LOGIN.LABEL.FORGETPWD_SENDTYPE": "โหมดกู้คืน",
  "agentconsole.authmanage.message.authtype": "ไม่สามารถป้อนชื่อผู้มีอำนาจได้ อักขระพิเศษไม่ว่างเปล่าและจำนวน bytes ต้องน้อยกว่าหรือเท่ากับ 50",
  "SM.LOGIN.TITLE.PROJECT_DESC": "คำอธิบายโครงการ:",
  "agentconsole.dashboard.title.caseperday": "สถานะการดำเนินการรายวัน",
  "agentconsole.menumanage.message.createsubnodefail": "ระดับเมนูต้องไม่เกิน 3 ระดับ",
  "SM.LOGIN.TIPS.WELCOME": "ยินดีต้อนรับสู่การใช้ BES!",
  "SM.LOGIN.TITLE.LOGIN": "ลงชื่อเข้าใช้",
  "agentconsole.workbench.signInCallNum": "จํานวนสายที่ลงชื่อเข้าใช้ปัจจุบัน:",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_VCODE": "รหัสยืนยัน",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY": "ปรับเปลี่ยน",
  "SM.LOGIN.LABEL.OPER_EMAIL": "อีเมล:",
  "SM.LOGIN.MESSAGE.SUM": "ทั้งหมด:",
  "SM.LOGIN.LABEL.VCODE": "รหัสยืนยัน",
  "agentconsole.login.indexforipcc.recordcontrol": "ควบคุมการบันทึกและการเล่นย้อนหลัง",
  "SM.LOGIN.MESSAGE.MODIFY_PWD_SUCCESS": "การเปลี่ยนรหัสผ่านสำเร็จ",
  "SM.LOGIN.TITLE.PROJECT_CREATE_TIME": "เวลาสร้าง:",
  "agentconsole.header.label.searchcase": "ค้นหาในเคส",
  "SM.LOGIN.TITLE.NOTICE_EFF_TIME": "เวลาที่มีผล",
  "agentconsole.history.lastloginfailattempts": "ความพยายามในการเข้าสู่ระบบครั้งล่าสุดที่ล้มเหลว",
  "SM.LOGIN.LABEL.ERRORPAGE_RETURNBTN": "กลับไปที่หน้าลงชื่อเข้าใช้",
  "agentconsole.menu.extended.apps": "แอพเพิ่มเติม",
  "SM.LOGIN.MESSAGE.SWITCH": "คุณแน่ใจหรือไม่ว่าต้องการสลับไปใช้ระบบ ESOP?",
  "SM.LOGIN.RESETPWD.FAIL": "ไม่สามารถดำเนินการตามคำขอรีเซ็ตรหัสผ่าน ตรวจสอบบัญชีลงชื่อเข้าใช้และที่อยู่อีเมล",
  "agentconsole.history.loginip": "IP เข้าสู่ระบบ",
  "agentconsole.login.indexforipcc.call": "ควบคุมการโทรด้วยเสียง",
  "agentconsole.index.label.task": "งาน",
  "agentconsole.quickentrance.checkcharacterlength": "กรุณาป้อนอักขระไม่เกิน {0} ตัว",
  "agentconsole.index.label.entry": "รายการด่วน",
  "agentconsole.quickentrance.information": "ข้อมูล",
  "SM.LOGIN.LABEL.FRAME_SEARCH_EMPLOYEE": "การค้นหาของพนักงาน",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT": "บัญชีสำหรับลงชื่อเข้าใช้",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_EMPTY": "รหัสผ่านเก่าว่าง",
  "agentconsole.modifycustominfo.modifyfailed": "การปรับเปลี่ยนล้มเหลว",
  "agentconsole.modifycustominfo.modifyfailed.validate": "การแก้ไขล้มเหลวไม่สอดคล้องกับกฎสตริงอักขระหรือความยาว",
  "agentconsole.modifycustominfo.modifyfailed.validate.num": "การแก้ไขล้มเหลวไม่เป็นไปตามกฎจํานวนหรือความยาว",
  "agentconsole.modifycustominfo.modifyfailed.validate.date":"การแก้ไขล้มเหลว รูปแบบวันที่ไม่ถูกต้อง",
  "agentconsole.modifycustominfo.modifyfailed.validate.time":"การแก้ไขล้มเหลว รูปแบบวันที่และเวลาไม่ถูกต้อง",
  "provision.menuurl.protocol.check": "กรุณาทราบว่าโปรโตคอลเครือข่ายที่ใช้โดยเมนูที่อยู่ปัจจุบันไม่ใช่โปรโตคอลที่ปลอดภัย หากคุณเลือกใช้โปรโตคอลเครือข่ายอาจมีความเสี่ยงด้านความปลอดภัย",
  "agentconsole.dashboard.title.taskpriority": "รายการที่ต้องทำ",
  "agentconsole.authmanage.field.auth_name": "ชื่อที่อนุญาต",
  "agentconsole.inputplaceholder.enter_keyword": "กรอกคำสำคัญ",
  "agentconsole.login.indexforipcc.gateway": "เปิดเกตเวย์",
  "SM.LOGIN.TITLE.UNIFIED_CASHER": "Cache เชียร์แบบครบวงจร",
  "SM.LOGIN.TITLE.SHOPPING_CART": "ตะกร้าสินค้า",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME": "เวลาสร้าง",
  "SM.LOGIN.RESETPWD.EMPTY": "บัญชีสำหรับลงชื่อเข้าใช้หรือที่อยู่อีเมลว่างเปล่า",
  "agentconsole.header.label.searchhelp": "ค้นหาในศูนย์ช่วยเหลือ",
  "SM.LOGIN.LABEL.NOTICE_CHAN_TYPE": "ประเภทช่องทาง",
  "agentconsole.login.label.service_cloud_title": "ระบบบริการลูกค้าบนคราวด์",
  "agentconsole.custinfo.title.custinfo": "ข้อมูลลูกค้า",
  "SM.LOGIN.TITLE.OPERATOR": "ผู้ดำเนินการปัจจุบัน",
  "SM.LOGIN.TITLE.SEARCH_CONDITION": "เงื่อนไขการค้นหา",
  "agentconsole.history.passwordremainingdays": "จำนวนวันที่เหลือของรหัสผ่าน",
  "SM.LOGIN.TIPS.REQUIRED": "พารามิเตอร์นี้จำเป็น",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL": "อีเมล:",
  "SM.LOGIN.LABEL.ADD_FAST_MENU": "เพิ่มเมนูทางลัด",
  "agentconsole.menumanage.message.homepagesubnode": "ไม่สามารถเพิ่มเมนูย่อยในหน้าหลักได้",
  "agentconsole.login.restpwd.success": "รีเซ็ตรหัสผ่านสำเร็จ",
  "SM.LOGIN.TITLE.CHOOSE_SELECT_ORG": "เลือก OU",
  "SM.LOGIN.LABEL.HOME": "หน้าหลัก",
  "SM.LOGIN.TIPS.MODIFY": "ปรับเปลี่ยน",
  "agentconsole.modifycustominfo.modifyexternalfailed": "ขณะนี้ไม่สามารถแก้ไขข้อมูลของบุคคลที่สามได้",
  "agentconsole.authmanage.paneltitle.authmanage": "การจัดการสิทธิ์",
  "agentconsole.menumanage.label.newmenu": "เมนูใหม่",
  "SM.LOGIN.MESSAGE.HAVE_LOCK_USER": "ล็อกผู้ใช้แล้ว ปลดล็อกผู้ใช้หรือรอให้ระบบปลดล็อกผู้ใช้โดยอัตโนมัติ",
  "SM.LOGIN.LABEL.RECHARGE": "เติมเงิน",
  "SM.LOGIN.MESSAGE.UNLOCK": "แน่ใจหรือไม่ว่าต้องการล็อกระบบ?",
  "agentconsole.querycustominfo.msg.noData": "ไม่มีข้อมูล",
  "agentconsole.dashboard.title.taskdone": "จำนวนงานที่จัดการ",
  "agentconsole.dashboard.title.web": "หน้าเว็บ",
  "agentconsole.workbench.talkavg": "ระยะเวลาการโทรโดยเฉลี่ย:",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD": "รหัสผ่านใหม่:",
  "SM.LOGIN.TIPS.ENTER_PROJECT_NAME": "กรอกชื่อโครงการ",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NULL": "รหัสยืนยันว่างเปล่า",
  "agentconsole.index.label.querycasenew": "พื้นที่ทำงาน การค้นหาเคส (ใหม่)",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_PREFIX": "ธุรกิจนี้ได้ถูกสลับอยู่ในสถานะฉุกเฉิน กรุณาไปที่",
  "agentconsole.login.indexforipcc.thirdpartysystem": "ระบบบุคคลที่สามสามารถใช้งานได้ผ่านอินเทอร์เฟซแบบรวม:",
  "SM.LOGIN.TITLE.REGISTERCODE": "Jiangsu Public Security Website Registration Number: {0}",
  "SM.LOGIN.LABEL.LOCK": "ล็อกหน้าจอ",
  "agentconsole.workbench.label.callreason": "เหตุผลในการโทร",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_NEWPWD": "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเก่า",
  "SM.LOGIN.TITLE.NOTICE_MORE": "เพิ่มเติม",
  "SM.LOGIN.MESSAGE.CHANGE_PWD_RULE": "การเปลี่ยนรหัสผ่านล้มเหลวเนื่องจากรหัสผ่านใหม่ไม่ตรงตามกลุ่มกฎรหัสผ่านต่อไปนี้:",
  "SM.LOGIN.RESETPWD.SUCCESS": "คำขอรีเซ็ตรหัสผ่านได้รับการดำเนินการสำเร็จแล้ว กรุณาตรวจสอบอีเมล",
  "SM.LOGIN.LABEL.FRAMEWORK_NOTEPAD": "Notepad",
  "agentconsole.menumanage.message.notdelbasemenu": "ไม่อนุญาตให้ลบเมนูสาธารณะ",
  "agentconsole.quickentrance.newpop": "เพิ่มรายการ",
  "agentconsole.login.label.account": "ชื่อบัญชี",
  "SM.LOGIN.LABEL.NOTICE_TITLE": "หัวข้อกระดานข่าว",
  "SM.LOGIN.TITLE.FORGETPWD_FINDEMAILBYPWD": "กู้คืนรหัสผ่านทางอีเมล",
  "agentconsole.login.indexforipcc.advantage": "ข้อดีของผลิตภัณฑ์",
  "agentconsole.login.indexforipcc.characteristics": "ลักษณะธุรกิจ",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_PREFIX": "พื้นที่ปัจจุบันถูกสลับอยู่ในสถานะเป็นฉุกเฉิน ลงชื่อเข้าใช้ระบบฉุกเฉินและไปยัง",
  "agentconsole.Intelligent.robot": "หุ่นยนต์อัจฉริยะ",
  "agentconsole.header.label.servicecloud.home": "หน้าหลักเคส",
  "agentconsole.header.title.news": "ข่าวสาร",
  "SM.LOGIN.TIPS.FIRSTLOGINSYSTEM": "ลงชื่อเข้าใช้ระบบเป็นครั้งแรก",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.SESSION_INVALID": "เซสชันปัจจุบันหมดอายุแล้ว กรุณาลงชื่อเข้าใช้อีกครั้ง",
  "agentconsole.sessionmanagement.label.operation": "การดำเนินการ",
  "SM.LOGIN.TITLE.UPDATE_PWD": "เปลี่ยนรหัสผ่าน",
  "agentconsole.workbench.label.callsummary": "สรุปหลังการโทร",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_EXPIRE": "ไม่สามารถตรวจสอบรหัสยืนยัน",
  "agentconsole.login.label.remember": "จดจำชื่อผู้ใช้",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_SUCCESS": "ส่งรหัสยืนยันแล้ว กรุณาตรวจสอบ",
  "agentconsole.workbench.label.usermenu": "เมนูผู้ใช้",
  "agentconsole.quickentrance.addentrance": "เพิ่มรายการ",
  "agentconsole.history.lastloginsuccess": "การเข้าสู่ระบบครั้งล่าสุดที่สำเร็จ",
  "agentconsole.login.indexforipcc.description": "ในฐานะสะพานเชื่อมที่สำคัญระหว่างองค์กรและลูกค้า IPCC (IP Contact Center) ให้บริการการโทรด้วยเสียง บริการลูกค้าออนไลน์และช่องทางอื่น ๆ เพื่อให้มอบบริการสำหรับลูกค้าที่มีคำถาม คำแนะนำทางธุรกิจ และข้อร้องเรียน นอกจากนี้ยังรองรับคุณสมบัติใหม่ ๆ เช่นวิดีโอเจ้าหน้าที่ และการนำทางด้วยเสียงแบบบริการตนเองขึ้นอยู่กับสภาพแวดล้อมของเครือข่ายความเร็วสูงแบนด์วิธสูงในปัจจุบัน ในขณะที่มอบประสบการณ์การบริการใหม่ ๆ ให้กับลูกค้า เรายังพัฒนาโมเดลการสำรวจรูปแบบการตลาดใหม่ ๆ โดยใช้วิดีโอและปัญญาประดิษฐ์อีกด้วย",
  "SM.LOGIN.LABEL.OPER_ORG": "โครงสร้างองค์กร:",
  "SM.LOGIN.LABEL.USERNAME": "บัญชี",
  "agentconsole.login.label.resetpwd": "กดรีเซ็ต รหัสผ่านใหม่จะถูกส่งไปยังกล่องจดหมาย",
  "agentconsole.contacthistory.contactRecord": "บันทึกการติดต่อ",
  "agentconsole.wordreminder.greetings": "ทักทาย",
  "agentconsole.modifycustominfo.modifysuccess": "การปรับเปลี่ยนสำเร็จ",
  "SM.LOGIN.LABEL.FORGETPWD_FINDPWD": "กู้คืนรหัสผ่าน",
  "agentconsole.common.label.delete": "ลบ",
  "agentconsole.login.label.verifycode.error": "รหัสยืนยันไม่ถูกต้อง",
  "agentconsole.icontitle.delete": "ลบ",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_PREFIX": "ระบบตรวจพบว่าที่อยู่ระบบปัจจุบันไม่ใช่ที่อยู่ระบบ BE (",
  "SM.LOGIN.BUTTON.UPDATE_PWD_RULE": "ข้อบังคับรหัสผ่าน",
  "SM.LOGIN.TITLE.NOTICE_NUMBER": "เลขที่",
  "agentconsole.icontitle.create_child": "เพิ่มหมวดหมู่ย่อย",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_SWITCH_ES": "ระบบฉุกเฉินไม่เริ่มทำงาน เข้าไปที่เมนูการผลิตเพื่อดำเนินการทางธุรกิจ",
  "SM.LOGIN.TITLE.OPERATION_FAILED": "ปฏิบัติการล้มเหลว",
  "agentconsole.login.label.refresh_verify_code": "ไม่ชัดเจน เปลี่ยนใหม่อีกครั้ง",
  "agentconsole.login.indexforipcc.difstatus": "สถานะเจ้าหน้าที่หมายถึงสถานะต่าง ๆ ที่เจ้าหน้าที่ฝ่ายบริการลูกค้าอาจอยู่ในหลังจากเข้าสู่ระบบเจ้าหน้าที่ซึ่งรวมถึง \"สถานะว่าง\" \"สถานะพูดคุย\" \"สถานะสิ้นสุด\" \"แสดงสถานะไม่ว่าง\" และ \"สถานะพัก\" โดยการดำเนินการของเจ้าหน้าที่ฝ่ายบริการลูกค้าจะเป็นเพื่อรับสาย, ออก, การดำเนินการตามลำดับที่แสดงว่าไม่ว่าง เป็นต้น เพื่อเป็นไปตามสถานะของเจ้าหน้าที่ที่แสดงอยู่ ขึ้นอยู่กับสถานะของเจ้าหน้าที่ระบบจะพิจารณาว่าสามารถกำหนดสายเรียกเข้าให้กับเจ้าหน้าที่ฝ่ายบริการลูกค้าได้หรือไม่",
  "SM.LOGIN.LABEL.FORGETPWD_NUMBER": "หมายเลขโทรศัพท์เคลื่อนที่",
  "SM.LOGIN.TITLE.PORTAL_PROJECT_TITLE": "เลือกโครงการ",
  "SM.LOGIN.MESSAGE.SWITCHERROR": "การสลับล้มเหลว",
  "agentconsole.modifycustominfo.nomodifyauth": "ผู้ดำเนินการปัจจุบันไม่มีสิทธิ์ในการปรับเปลี่ยนข้อมูลที่กำหนดเอง",
  "SM.LOGIN.LABEL.RESETPWD.SUCCESS": "การรีเซ็ตรหัสผ่านสำเร็จ",
  "agentconsole.login.indexforipcc.qualitycheck": "ฟังก์ชันที่เกี่ยวข้องกับการตรวจสอบคุณภาพตามเวลาจริง เช่น การแทรก การดักฟัง การสังเกตการณ์ เป็นต้น",
  "agentconsole.authmanage.message.createsubnodefail": "ระดับการอนุญาตต้องไม่เกิน 3 ระดับ",
  "agentconsole.authmanage.tab.basic_information": "ข้อมูลพื้นฐาน",
  "SM.LOGIN.LABEL.NOTICE_ROLE": "บทบาทผู้รับ",
  "agentconsole.header.label.changelog": "เปลี่ยนแปลง Log",
  "agentconsole.Intelligent.labelname": "การบันทึกเสียงแบบอัจฉริยะ",
  "agentconsole.iolp.labelname": "คําขอทางธุรกิจ",
  "agentconsole.iolp.entrancename": "คําแนะนําการบริการ",
  "SM.LOGIN.BUTTON.CHANGEPWD_CANCEL": "ยกเลิก",
  "SM.LOGIN.LABEL.LANG": "ภาษา",
  "SM.LOGIN.LABEL.ACTIVITY": "กิจกรรมการตลาด",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_FAILED": "การยืนยันล้มเหลว กรุณาลองอีกครั้งในภายหลัง",
  "SM.LOGIN.LABEL.NOTICE_NOTE_TYPE": "ประเภทกระดานข่าว",
  "agentconsole.login.indexforipcc.idle": "ระบบสามารถมอบหมายสายเรียกเข้าให้กับเจ้าหน้าที่ที่มี \"สถานะว่าง\" เท่านั้น",
  "SM.LOGIN.TITLE.SELECTBE": "เลือก BE",
  "agentconsole.dashboard.enumeration.otherday": "วันที่อื่น",
  "SM.LOGIN.TITLE.SERACH_MENU": "กรอกชื่อเมนู",
  "agentconsole.dashboard.evaluation.high": "5 คะแนน",
  "SM.LOGIN.TITLE.CHOOSE_ORG_LIST": "กรุณาเลือกองค์กร",
  "agentconsole.login.indexforipcc.mute": "ปิดเสียง",
  "SM.LOGIN.LABEL.CUS_LOGIN_REDIRECT": "ลงชื่อเข้าใช้และข้ามไปที่",
  "SM.LOGIN.TITLE.SELECT_ACCOUNT_TYPE": "เลือกประเภทบัญชี",
  "SM.LOGIN.TITLE.PRINT_FREE_FILLING": "พิมพ์สลิป ACK",
  "agentconsole.sessionmanagement.label.search": "การค้นหา",
  "agentconsole.menumanage.message.menu_url": "ที่อยู่ของเมนูต้องไม่เป็นภาษาจีน",
  "SM.LOGIN.LABEL.CHANGEPWD_COMFIRMPWD": "ยืนยันรหัสผ่าน",
  "SM.LOGIN.LABEL.NOTICE_CREATOR": "ผู้สร้าง",
  "agentconsole.index.label.chat": "บริการลูกค้าออนไลน์",
  "agentconsole.login.indexforipcc.title": "ศูนย์บริการระบบคลาวด์",
  "agentconsole.authmanage.rule.tips": "กรุณาเลือกการอนุญาตที่คุณกำหนดเอง ไม่สามารถเลือกการอนุญาตที่กำหนดไว้ล่วงหน้าตามระบบได้",
  "agentconsole.dashboard.title.casedone": "จำนวนเคสที่ได้รับการจัดการ",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLISTEND": "]",
  "SM.LOGIN.BUTTON.FORGETPWD_EMAIL": "อีเมล",
  "agentconsole.login.indexforipcc.lightweight": "ไลท์เวท",
  "agentconsole.Intelligent.close": "ปิด",
  "agentconsole.dashboard.title.interactioncount": "จำนวนผู้ติดต่อทั้งหมดในวันปัจจุบัน:",
  "agentconsole.header.label.searchcommunity": "ค้นหาในชุมชน",
  "agentconsole.modifycustominfo.noqueryauth": "ผู้ดำเนินการปัจจุบันไม่มีสิทธิ์ในการค้นหาข้อมูลที่กำหนดเอง",
  "SM.LOGIN.TITLE.HELPCENTER": "ศูนย์ช่วยเหลือ",
  "agentconsole.common.tips.warning": "คำเตือน",
  "agentconsole.login.indexforipcc.hold": "พักสาย",
  "agentconsole.contacthistory.talking": "เซสชันเสียง",
  "agentconsole.menumanage.tab.basic_information": "ข้อมูลพื้นฐาน",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_MINTIME_ERROR": "สร้างรหัสยืนยันแล้ว",
  "agentconsole.contacthistory.readMore": "ดูเพิ่มเติม ...",
  "SM.LOGIN.RESETPWD.EMAILPATTERN_ERROR": "ที่อยู่อีเมลไม่ถูกต้อง",
  "agentconsole.quickentrance.deleteinfo": "คุณแน่ใจหรือไม่ว่าต้องการลบสารบบนี้",
  "agentconsole.authmanage.message.deleteauthfail": "การลบการอนุญาตล้มเหลว กรุณาตรวจสอบว่าการอนุญาตมีการอ้างอิงจากเมนูใด ๆ หรือได้รับบทบาทใด ๆ",
  "SM.LOGIN.TITLE.NONLOCAL_USER_CHANEPWD_ERROR": "ผู้ใช้ที่ไม่ใช่ในพื้นที่ไม่สามารถเปลี่ยนรหัสผ่านได้",
  "agentconsole.login.indexforipcc.onepoint": "เจ้าหน้าที่บริการลูกค้าสามารถตรวจสอบในแพลตฟอร์มรับสายเรียกเข้า โอนสาย ปิดเสียง สลับสถานะ หรือความช่วยเหลือได้",
  "agentconsole.login.indexforipcc.loginportal": "เข้าสู่ระบบ Customer Service Cloud Portal",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CLOSE": "ปิด",
  "agentconsole.common.button.cancel": "ยกเลิก",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CONTINUE": "ดำเนินการต่อ",
  "SM.LOGIN.TITLE.COPYRIGHT": "ลิขสิทธิ์ {0} {1} Huawei Software Technologies Co., Ltd สงวนลิขสิทธิ์.",
  "agentconsole.menumanage.message.createfailures": "การสร้างเมนูล้มเหลว",
  "SM.INDEX.LABEL.CASEDEC": "คุณสามารถสร้างเคสสำหรับคำขอรับบริการของลูกค้าแต่ละรายได้ และสามารถตรวจสอบขั้นตอนการดำเนินการของคำขอรับบริการของลูกค้าผ่านเคส เพื่อให้แน่ใจว่าคำขอของลูกค้าได้รับการดำเนินการแล้วเสร็จและปรับปรุงความพึงพอใจของลูกค้า",
  "agentconsole.menumanage.message.sysadmincannotaddmenu": "ผู้ดูแลระบบปัจจุบันไม่สามารถสร้างเมนูได้",
  "SM.LOGIN.MESSAGE.UNLOCK_FAILED": "ปลดล็อกบัญชีไม่ล้มเหลว กรุณากรอกรหัสผ่านที่ถูกต้อง",
  "agentconsole.authmanage.label.newauth": "การอนุญาตใหม่",
  "agentconsole.login.indexforipcc.difplat": "ง่ายต่อการรวมเข้ากับแพลตฟอร์มต่าง ๆ โดยไม่ต้องใช้หน้าหลักของพอร์ทัลและพื้นที่ทำงาน",
  "agentconsole.callmessage.calledNo": "เบอร์ปลายทาง",
  "agentconsole.sessionmanagement.input.validatorMessage": "ไม่อนุญาตให้ใช้อักขระพิเศษยกเว้นขีดล่าง (_) จุด (.) และเครื่องหมาย ({'@'})",
  "agentconsole.index.label.case": "เคส",
  "agentconsole.login.indexforipcc.auth": "การควบคุมการเข้าถึง",
  "agentconsole.dashboard.priority.medium": "ลำดับความสำคัญปานกลาง",
  "agentconsole.header.title.send": "ส่งข้อความภายใน",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES": "พื้นที่ปัจจุบันถูกสลับเป็นสถานะฉุกเฉิน ธุรกิจสามารถจัดการได้หลังจากระบบกู้คืนแล้วเท่านั้น ลงชื่อเข้าใช้ระบบฉุกเฉินเพื่อจัดการธุรกิจอื่น ๆ",
  "agentconsole.ccnotification.not.currently.on.call": "ยังไม่รับสาย",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CANCEL": "ยกเลิก",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CONFIRM": "ตกลง",
  "agentconsole.authmanage.message.selectanauth": "กรุณาเลือกการอนุญาต",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_CONTINUE": "ดำเนินการต่อเพื่อเข้าสู่ระบบปัจจุบัน",
  "SM.LOGIN.TITLE.INFO": "พร้อม",
  "agentconsole.workbench.label.remarks": "หมายเหตุ",
  "SM.LOGIN.BUTTON.CANCEL": "ยกเลิก",
  "agentconsole.sessionmanagement.table.fail.delete": "การลบล้มเหลว",
  "SM.LOGIN.TITLE.CHANGEPWD_RULE": "รหัสผ่านใหม่ต้องเป็นไปตามกฎต่อไปนี้:",
  "SM.LOGIN.BUTTON.FORGETPWD_SMS": "SMS",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.FORCE_CHANGE_PASSWORD": "เปลี่ยนรหัสผ่านหลังจากลงชื่อเข้าใช้",
  "SM.LOGIN.TITLE.CURRENT_SELECTED_PROJECT": "ที่เลือกไว้:",
  "SM.LOGIN.LABEL.FRAMEWORK_AD_DEMAND": "โฆษณาตามความต้องการ",
  "SM.LOGIN.LABEL.CHANGEPWD_NEWPWD": "รหัสผ่านใหม่",
  "SM.LOGIN.LABEL.NOTICE_GRADE_URGENT": "รีบเร่ง",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_BIND_INFO_NULL": "บัญชีการลงชื่อเข้าใช้หรือข้อมูลการผูกมัดว่างเปล่า",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_FAILED": "การส่งข้อความ SMS ล้มเหลว ตรวจสอบ backend log",
  "SM.LOGIN.MESSAGE.REPEAT_SUBMITED": "ส่งคำขอแล้ว กรุณารอสักครู่...",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_DATE_INVALID": "การตรวจสอบรหัสยืนยันล้มเหลว",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_CONFIRMPWD": "รหัสผ่านใหม่ต้องเหมือนกับรหัสผ่านยืนยัน",
  "agentconsole.dashboard.title.unassigned": "เลิกมอบหมายแล้ว",
  "SM.LOGIN.MESSAGE.NONLOCAL_USER_RETRIEVEPASSWORD_FAILED": "การกู้รหัสผ่านล้มเหลว ผู้ใช้ที่ไม่ใช่ในพื้นที่ไม่สามารถใช้ฟังก์ชันการกู้รหัสผ่านได้",
  "SM.LOGIN.TIPS.SAVE": "บันทึก",
  "agentconsole.dashboard.title.interactionperday": "ปริมาณการติดต่อรายวัน",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY_SUCCESS": "การปรับเปลี่ยนสำเร็จ",
  "SM.LOGIN.TITLE.PASSWORD_INIT": "คุณกำลังใช้รหัสผ่านเริ่มต้น เปลี่ยนรหัสผ่านทันที",
  "SM.PORTAL.LABEL.INPUT_CFM_PWD": "ยืนยันรหัสผ่านใหม่",
  "SM.LOGIN.TITLE.CHANGEPWD_SUCCESS": "พร้อม",
  "SM.LOGIN.LABEL.COPYRIGHT": "ลิขสิทธิ์© Huawei Technologies Co. , Ltd. 2018",
  "agentconsole.dashboard.title.taskcreate": "จำนวนงานที่สร้างขึ้น",
  "SM.LOGIN.TITLE.PASSWORD_WILLEXPIRED": "รหัสผ่านของคุณกำลังจะหมดอายุ กรุณาเปลี่ยนรหัสผ่าน",
  "SM.LOGIN.BUTTON.QUERY": "การค้นหา",
  "agentconsole.dashboard.title.task": "ภาพรวมงาน",
  "SM.LOGIN.LABEL.AUTH_FAILED": "บัญชีหรือรหัสผ่านไม่ถูกต้อง",
  "agentconsole.login.restpwd.fail": "การรีเซ็ตรหัสผ่านล้มเหลว",
  "agentconsole.login.label.reset": "รีเซ็ต",
  "SM.LOGIN.TIPS.LASTLOGINTIME": "เวลาลงชื่อเข้าใช้ครั้งสุดท้าย:",
  "SM.LOGIN.LABEL.SYSTEM_ERROR": "ข้อผิดพลาดของระบบ",
  "agentconsole.dashboard.priority.high": "ลำดับความสำคัญสูง",
  "agentconsole.login.label.username": "ชื่อผู้ใช้",
  "SM.LOGIN.TITLE.NOTICE_LIST": "รายการกระดานข่าว",
  "agentconsole.index.label.chatdec": "ในระหว่างการสนทนาออนไลน์แบบมัลติมีเดียกับลูกค้าของคุณ คุณสามารถดูข้อมูลการติดต่อในอดีตที่ผ่านมาของลูกค้าเพื่อเพิ่มประสิทธิภาพการบริการของคุณ และมอบประสบการณ์การบริการ Omnichannel ให้กับลูกค้าของคุณ",
  "agentconsole.ccnotification.has.been.sent": "ได้ถูกส่งไป",
  "SM.LOGIN.TITLE.UPDATE_PWD_MODIFY_FAILED": "การปรับเปลี่ยนล้มเหลว",
  "SM.LOGIN.TITLE.BE_CATALOG": "แคตตาล็อก BE:",
  "agentconsole.index.label.casedraft": "เคสร่างของฉัน",
  "agentconsole.login.label.forgot": "ลืมรหัสผ่าน",
  "agentconsole.login.indexforipcc.opengateway": "เปิดเกตเวย์",
  "SM.LOGIN.TITLE.SMS_RECONFIRM": "ยืนยัน SMS อีกครั้ง",
  "agentconsole.callmessage.title": "ข้อความเสียง",
  "agentconsole.sessionmanagement.table.success.delete": "การลบสำเร็จ",
  "agentconsole.quickentrance.deleteclass": "ลบสารบบนี้",
  "agentconsole.login.indexforipcc.thirdparty": "โทรสามทาง",
  "agentconsole.sessionmanagement.table.deleteconfim": "ยืนยันการลบ",
  "SM.LOGIN.LABEL.FAST_ENTER": "รายการด่วน",
  "SM.LOGIN.TITLE.UPDATE_PWD_LOGINID": "บัญชี:",
  "agentconsole.login.indexforipcc.sequence": "คิวการค้นหาข้อมูล",
  "agentconsole.menumanage.field.url": "URL เมนู",
  "agentconsole.login.indexforipcc.statecontrol": "การควบคุมสถานะการเชื่อมต่อ",
  "agentconsole.login.indexforipcc.signin": "เจ้าหน้าที่บริการลูกค้าที่ต้องจัดการการโทรด้วยเสียงจะต้องเช็คอินในแพลตฟอร์ม CTI",
  "agentconsole.login.indexforipcc.answer": "รับโทรศัพท์",
  "agentconsole.authmanage.message.notdelnoleafauth": "ไม่อนุญาตให้ลบการอนุญาตที่มีการอนุญาตย่อย",
  "SM.LOGIN.TITLE.APPEND_MENU_SEARCH": "เงื่อนไขการค้นหา:",
  "agentconsole.quickentrance.managemententrance": "การจัดการทางเข้า",
  "agentconsole.authmanage.label.authtype.operation": "การอนุญาตการดำเนินการ",
  "SM.LOGIN.TITLE.APPEND_MENU_TITLE": "เพิ่มเมนูลัด",
  "agentconsole.dashboard.title.todo": "รายการที่ต้องทำ",
  "SM.CHANGELANGAUGE.REFRESH.MESSAGE": "ไม่สามารถบันทึกการกําหนดค่าช่องสัญญาณได้ ตรวจสอบว่าที่อยู่พุชข้อความหรือใบรับรองถูกต้องหรือไม่",
  "agentconsole.callmessage.callskill": "คิวทักษะ",
  "agentconsole.sessionmanagement.tip.error": "ข้อผิดพลาด",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD": "รหัสผ่านเก่า:",
  "agentconsole.faqwordreminder.norecommend": "ไม่มีคำถามที่พบบ่อยที่แนะนำ",
  "SM.LOGIN.MESSAGE.SELECT_PROJECT": "เลือกโครงการ",
  "agentconsole.icontitle.create_sibling": "เพิ่มหมวดหมู่ระดับเดียวกัน",
  "SM.LOGIN.LABEL.PASSWORD": "รหัสผ่าน",
  "agentconsole.callmessage.faq.reminder": "คำถามที่พบบ่อยที่แนะนำ",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLIST": "การรีเซ็ตรหัสผ่านผู้เช่าเป็นชุด: [รายชื่อพนักงาน:",
  "SM.LOGIN.MESSAGE.CHANGEPWD_SUCCESS": "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
  "agentconsole.login.indexforipcc.opengatewayde": "Open Gateway มีอินเทอร์เฟซที่ใช้โปรโตคอล RESTful มาตรฐานสำหรับฟังก์ชันการโทรด้วยเสียงของระบบคลาวด์การบริการลูกค้าอัจฉริยะที่รวมระบบที่สามเข้าด้วยกัน ระบบของบุคคลที่สามสามารถติดตั้งคอลเซ็นเตอร์ในระบบของตนเองได้โดยการซื้อบริการโดยไม่ต้องซื้ออุปกรณ์คอลเซ็นเตอร์ของตนเอง ความสามารถ",
  "SM.LOGIN.MESSAGE.MENU_ACCESS_TIME_NOT_MATCH": "เวลาปัจจุบันไม่อยู่ในช่วงเวลาการเข้าถึงเมนูที่อนุญาต เวลาในการเข้าถึงเมนูมีดังนี้:",
  "SM.LOGIN.LABEL.SETTINGS": "การตั้งค่า",
  "agentconsole.menumanage.field.menu_name": "ชื่อเมนู",
  "agentconsole.common.button.save": "บันทึก",
  "agentconsole.login.restpwd.errorinfo": "ชื่อผู้ใช้หรือที่อยู่อีเมลไม่ถูกต้อง",
  "SM.LOGIN.BUTTON.OK": "ตกลง",
  "SM.LOGIN.TIPS.LOCK": "หน้าจอล็อก",
  "SM.LOGIN.LABEL.NOTICE_GRADE_EXTRA_URGENT": "ด่วน",
  "SM.INDEX.LABEL.TASKDEC": "ในระหว่างการประมวลผลกรณีคุณสามารถส่งงานไปทำงานร่วมกับหลายแผนกและบุคลากรได้ สามารถสร้างงานได้หลายงานสำหรับเคส",
  "agentconsole.common.tips.information": "เคล็ดลับ",
  "agentconsole.dashboard.title.taskperday": "สถานะการประมวลผลรายวัน",
  "agentconsole.authmanage.message.notdelbaseauth": "ไม่อนุญาตให้ลบการอนุญาตสาธารณะ",
  "agentconsole.ccnotification.not.currently.on.three": "ไม่สามารถเริ่มกระบวนการนี้ได้ในสถานะการโทรปัจจุบัน",
  "agentconsole.header.label.home": "หน้าหลัก",
  "agentconsole.custinfo.label.custId": "รหัสลูกค้า",
  "agentconsole.menumanage.message.deletemenufail": "ลบเมนูไม่สำเร็จ",
  "agentconsole.dashboard.enumeration.today": "วันนี้",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_OPEN": "รายละเอียด <",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORSTACK": "ข้อผิดพลาดการเรียงซ้อน:",
  "agentconsole.header.title.search": "ค้นหา",
  "agentconsole.dashboard.priority.low": "ลำดับความสำคัญต่ำ",
  "agentconsole.wordreminder.recommend": "สคริปต์ที่แนะนำ",
  "agentconsole.wordreminder.ai.recommend": "สคริปต์ที่แนะนําโดย AI",
  "agentconsole.quickentrance.complete": "เสร็จสิ้น",
  "SM.LOGIN.LABEL.FINISH": "เสร็จ",
  "SM.LOGIN.TIPS.LOGINIP": "ที่อยู่ IP:",
  "SM.LOGIN.MESSAGE.GRAFIC.VERIFY.CODE.REFRESH": "ไม่ชัดเจน เปลี่ยนเป็นอย่างอื่น",
  "SM.LOGIN.LABEL.RESET": "รีเซ็ต",
  "SM.LOGIN.LABEL.LOGOUT": "ลงชื่อออก",
  "agentconsole.authmanage.message.deleteconfirm": "คุณแน่ใจหรือว่าคุณต้องการที่จะลบการอนุญาต",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD_EMPTY": "รหัสผ่านยืนยันว่างเปล่า",
  "agentconsole.contacthistory.oneMinAgo": "นาทีที่ผ่านมา",
  "agentconsole.contacthistory.hourAgo": "ชั่วโมงที่ผ่านมา",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_ERROR": "รหัสผ่านเก่าไม่ถูกต้อง",
  "agentconsole.login.indexforipcc.useage": "ระบบคลาวด์การบริการลูกค้าอัจฉริยะมอบกรอบที่นั่งที่รองรับความสามารถหลายผู้เช่าทำให้เจ้าหน้าที่สามารถเปิดได้และรวมแพลตฟอร์ม CTI และ UAP ของ Huawei เพื่อให้ลูกค้ามีความสามารถในการบริการเสียง / วิดีโอบนคลาวด์ที่สมบูรณ์แบบ",
  "agentconsole.dashboard.title.caseunassigned": "เลิกมอบหมายแล้ว",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_CONTEXTDATA": "สลับ BE",
  "SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS": "อารี แซนเทอ ท",
  "agentconsole.ccnotification.max.recipients": "ผู้รับไม่เกิน 5 คน",
  "SM.LOGIN.LABEL.NOTICE_STATUS": "สถานะ",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CANCEL": "ยกเลิก",
  "SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER": "แต่งตั้งผู้จัดการ",
  "agentconsole.login.label.errorinfo": "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
  "agentconsole.ccnotification.recipient.cannot.empty": "ผู้รับไม่สามารถเว้นว่างได้",
  "SM.LOGIN.LABEL.LOCKED": "ล็อก",
  "agentconsole.dashboard.title.mobile": "ระบบอุปกรณ์เคลื่อนที่",
  "agentconsole.authmanage.message.sysauthsubnode": "ไม่สามารถเพิ่มการอนุญาตย่อยในการจัดการระบบได้",
  "SM.LOGIN.TITLE.SWITCH_ESOP": "คลิกเดียวเพื่อสลับเป็น ESOP",
  "agentconsole.quickentrance.all": "ทั้งหมด",
  "agentconsole.workbench.label.userinfo": "ข้อมูลผู้ใช้",
  "agentconsole.dashboard.enumeration.thressdays": "3 วันล่าสุด",
  "SM.LOGIN.LABEL.CANCEL": "ยกเลิก",
  "agentconsole.login.label.service_cloud_desc": "ล้าง UI สำหรับ Ultimate Service",
  "agentconsole.modifycustominfo.nodatamodify": "ไม่มีการเปลี่ยนแปลงข้อมูล",
  "SM.AUC.MESSAGE.PWDRULE_YES": "ใช้",
  "SM.LOGIN.TITLE.NOTICE_CONTENT": "เนื้อหา",
  "agentconsole.contacthistory.dayAgo": "วันที่ผ่านมา",
  "agentconsole.login.indexforipcc.agentstate": "การควบคุมสถานะเจ้าหน้าที่เช่นเจ้าหน้าที่ไม่ว่าง พัก พักผ่อน เป็นต้น",
  "SM.LOGIN.TIPS.LOGOUT": "ลงชื่อออกจากระบบ",
  "SM.LOGIN.MESSAGE.CHANGEPWD_OLDNEW": "รหัสผ่านใหม่ต้องแตกต่างจากรหัสผ่านเก่า",
  "SM.LOGIN.LABEL.OPER_NAME": "ชื่อผู้ใช้:",
  "SM.LOGIN.LABEL.UPDATE_PWD": "เปลี่ยนรหัสผ่าน",
  "SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM": "หลังจากการลบพนักงานแล้ว คุณจะไม่สามารถกู้คืนบัญชีพนักงานที่เกี่ยวข้องได้และจะทำการสร้างใหม่ได้เท่านั้น คุณต้องการลบหรือไม่",
  "SM.LOGIN.LABEL.NOTICE_GRADE": "ความสำคัญ",
  "agentconsole.header.title.case": "สร้างเคส",
  "SM.ORGSTAFF.TITLE.OPERATION": "การดำเนินการ",
  "SM.LOGIN.MESSAGE.SENDMAIL_SUCCESS": "รหัสผ่านใหม่ถูกส่งไปยังอีเมลของคุณแล้ว กรุณาใช้รหัสผ่านใหม่เพื่อลงชื่อเข้าใช้",
  "SM.LOGIN.TIPS.LOGINFAILED": "การลงชื่อเข้าใช้ล้มเหลว",
  "agentconsole.dashboard.enumeration.thirtydays": "30 วันที่แล้ว",
  "agentconsole.menumanage.message.deleteconfirm": "คุณแน่ใจหรือว่าคุณต้องการที่จะลบเมนู",
  "agentconsole.login.indexforipcc.easyuse": "ดำเนินการงานง่าย",
  "SM.LOGIN.TIPS.WELCOME_LABEL": "ข้อความต้อนรับ:",
  "agentconsole.dashboard.title.total": "รวม",
  "SM.LOGIN.LABEL.EMERGENCY_NOTICE": "ประกาศเหตุฉุกเฉิน",
  "agentconsole.login.indexforipcc.deal": "เจ้าหน้าที่บริการลูกค้าสามารถจัดการกับการโทรของลูกค้าได้ดังนี้",
  "agentconsole.login.label.cancel": "ยกเลิก",
  "agentconsole.menu.config.center": "ศูนย์กำหนดค่า",
  "SM.LOGIN.MESSAGE.SENDSMS_SUCCESS": "รหัสผ่านใหม่ถูกส่งไปยังโทรศัพท์เคลื่อนที่ของคุณแล้ว กรุณาใช้รหัสผ่านใหม่เพื่อลงชื่อเข้าใช้",
  "agentconsole.login.restpwd.emailpattern_error": "ที่อยู่อีเมลไม่ถูกต้อง",
  "message.center.message.messageManage": "การจัดการข้อความภายใน",
  "agentconsole.index.label.dashboard": "หน้ากระดาน",
  "agentconsole.menumanage.field.auth": "สิทธิ์ของเมนู",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_SUFFIX": "เพื่อดำเนินธุรกิจต่อไป",
  "agentconsole.login.indexforipcc.release": "ปล่อยสาย",
  "agentconsole.dashboard.title.chat": "ภาพรวมการแชทออนไลน์",
  "agentconsole.index.label.querytask": "งานค้นหา",
  "SM.LOGIN.TITLE.NOTICE_CRUM": "การจัดการระบบ> การบำรุงรักษาระบบ> การค้นหากระดานข่าวสาร",
  "agentconsole.sessionmanagement.tip.success": "สำเร็จ",
  "agentconsole.header.title.help": "ช่วยเหลือ",
  "agentconsole.header.title.send.email.notification": "ข้อความแจ้งเตือนจดหมายขาออก",
  "agentconsole.login.indexforipcc.calldata": "ค้นหาข้อมูลการโทร",
  "agentconsole.dashboard.enumeration.yesterday": "เมื่อวาน",
  "SM.LOGIN.LABEL.FRAMEWORK_CALENDAR": "ปฏิทิน",
  "SM.LOGIN.LABEL.FORGOTPASSWORD": "ลืมรหัสผ่าน",
  "SM.LOGIN.BUTTON.LOGIN": "ลงชื่อเข้าใช้",
  "agentconsole.contacthistory.nocontactinfo": "ไม่มีประวัติการติดต่อ",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_SUFFIX": "เมนูสำหรับจัดการธุรกิจ",
  "SM.LOGIN.MESSAGE.CONFIRM": "ยืนยัน",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.NO_LOGIN": "คุณยังไม่ได้ลงชื่อเข้าใช้ กรุณาลงชื่อเข้าใช้และลองอีกครั้ง",
  "SM.LOGIN.TITLE.COMMONTOOLS": "เครื่องมือทั่วไป",
  "agentconsole.login.label.verifycode.empty": "รหัสยืนยันว่างเปล่า",
  "agentconsole.dashboard.title.wechat": "WeChat",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_Y": "ใช่",
  "SM.LOGIN.LABEL.VCODE_EMPTY": "รหัสยืนยันว่างเปล่า",
  "SM.LOGIN.TITLE.DROPLIST_MENU": "เมนู",
  "agentconsole.contact.customerInfo": "ข้อมูลลูกค้า",
  "agentconsole.callmessage.setHandledNumber": "ตั้งค่าหมายเลขที่จับได้",
  "agentconsole.callmessage.setNumerSuccess": "การตั้งค่าหมายเลขการจัดการสําเร็จ",
  "agentconsole.callmessage.setNumerError": "ไม่สามารถตั้งค่าหมายเลขการจัดการได้",
  "agentconsole.callmessage.query.external.custom.info": "ล้มเหลวในการค้นหาข้อมูลที่กําหนดเองภายนอก ติดต่อเจ้าหน้าที่ O&M เพื่อดูบันทึก",
  "agentconsole.callmessage.setNumerLengthError": "หมายเลขการจัดการต้องไม่เกิน 24 ตัวอักษร",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_N": "เลขที่",
  "agentconsole.history.lastloginfail": "การเข้าสู่ระบบครั้งล่าสุดที่ล้มเหลว",
  "agentconsole.history.logintime": "วันที่",
  "agentconsole.menumanage.paneltitle.menumanage": "การจัดการเมนู",
  "agentconsole.dashboard.title.evaluation": "ความพึงพอใจของลูกค้า",
  "agentconsole.querycustominfo.msg.specialStrValidateFailed": "รองรับเฉพาะอักขระพิเศษต่อไปนี้: {'@'}.-",
  "SM.LOGIN.RESETPWD.ERRORINFO": "บัญชีสำหรับลงชื่อเข้าใช้หรือที่อยู่อีเมลไม่ถูกต้อง",
  "agentconsole.must.be.a.number":"ต้องเป็นตัวเลข",
  "WEB.VALIDATOR.RANGE":"ค่าป้อนอยู่นอกช่วง: {0}-{1}",
  "agentconsole.title.transcription" : "การถอดเสียง",
  "agentconsole.title.agent.overview" : "ภาพรวมของเจ้าหน้าที่",
  "agentconsole.agent.assistant.recomend.history" : "ประวัติแนะนํา",
  "agentconsole.agent.assistant.faq.history" : "FAQ ประวัติ",
  "agentconsole.agent.assistant.auto.create.case" : "สร้างเคสโดยอัตโนมัติ",
  "agentconsole.agent.assistant.recomend.action" : "การกระทําที่แนะนํา",
  "agentconsole.agent.assistant.summary" : "สร้างสรุป",
  "agentconsole.agent.assistant.case" : "สร้างกรณี",
  "agentconsole.agent.refine.simple" : "เรียบง่าย",
  "agentconsole.agent.refine.formal" : "เป็นทางการ",
  "agentconsole.agent.refine.friendly" : "เป็นมิตร",
  "agentconsole.agent.refine.humorous" : "ตลกขบขัน",
  "agentconsole.agent.refine" : "ปรับแต่ง",
  "agentconsole.agent.please.notice" : "ระวังคําพูดด้วย",
  "agentconsole.agent.knowledge.recommend" : "แนะนําความรู้",
  "agentconsole.agent.knowledge.map":"แผนที่",
  "agentconsole.agent.knowledge.product.recommend" : "ผลิตภัณฑ์แนะนำ",
  "agentconsole.agent.knowledge.product.popular" : "ผลิตภัณฑ์ยอดนิยม",
  "agentconsole.agent.knowledge.product.link" : "ค้นพบส่วนลด",
  "agentconsole.agent.complaint.risk" : "ความเสี่ยงในการร้องเรียน",
  "agentconsole.agent.call.context" : "บริบทการโทร",
  "agentconsole.agent.customer.info" : "ข้อมูลลูกค้า",
  "agentconsole.agent.session.monitor" : "การตรวจสอบเซสชัน",
  "agentconsole.agent.basic.info" : "ข้อมูลพื้นฐาน",
  "agentconsole.agent.customer.name" : "ชื่อลูกค้า",
  "agentconsole.agent.phone.number" : "หมายเลขโทรศัพท์มือถือ",
  "agentconsole.agent.service.duration" : "ระยะเวลาในการเข้าสู่เครือข่าย",
  "agentconsole.agent.customer.brand" : "แบรนด์ที่เกี่ยวข้อง",
  "agentconsole.agent.customer.status" : "สถานะของผู้ใช้",
  "agentconsole.agent.customer.address" : "สถานที่",
  "agentconsole.agent.bussiness.info" : "ข้อมูลธุรกิจ",
  "agentconsole.agent.order.status" : "การสั่งซื้อ",
  "agentconsole.agent.business.usage" : "การใช้งานทางธุรกิจ",
  "agentconsole.agent.terminal.profile" : "ข้อมูลเทอร์มินัล",
  "agentconsole.agent.terminal.vendor" : "ผู้ผลิตอุปกรณ์ปลายทาง",
  "agentconsole.agent.terminal.model" : "โมเดลเทอร์มินัล",
  "agentconsole.agent.network.standard" : "ระบบเครือข่าย",
  "agentconsole.agent.generate.summary": "สร้างข้อมูลสรุป",
  "agentconsole.agent.summary": "บทสรุป",
  "agentconsole.agent.submit.success": "ส่งสําเร็จแล้ว",
  "agentconsole.agent.intelligent.fill": "การเติม TT อัจฉริยะ",
  "agentconsole.agent.intelligent.click": "การเติมตั๋วด้วยคลิกเดียว",
}