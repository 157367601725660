import { defineStore } from 'pinia'

export const useIsalesTaskManagementStore = defineStore('isalesTaskManagement', {
    state: () => {
        return {
            batchEditTaskIds: []
        }
    },
    getters: {},
    actions: {
        setBatchEditTaskIds(taskIds) {
            this.batchEditTaskIds = taskIds
        }
    }
})

// 任务详情页面store
export const useIsalesTaskDetailStore = defineStore('isalesTaskDetail',{
    state: () => {
        return {
            taskInfo: {},
        };
    },
    getters: {

    },
    actions: {
        setTaskInfo(taskInfo) {
            this.taskInfo = taskInfo;
        },
    },
})