<template>
    <div v-if="isCreate || isBatchEdit || isCreateTemplate">
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.basicinfo.title.chooseCallNo')" width="616" class="aicc-dialog-limit-height"
                      :close-on-click-modal="false">
            <!--OP场景 多一个输入框-->
            <div v-if="cmsEnvironment==='cms'">
                <sweet-form label-width="auto" :model="queryForm" :rules="callerRule" ref="queryFormValid">
                    <sweet-row>
                        <sweet-col :span="12" style="text-align: left;font-weight: bold;font-size: large;">
                            <sweet-form-item prop="inputAccessCode">
                                <sweet-input v-model="queryForm.inputAccessCode"
                                             :placeholder="$t('isales.taskinfo.field.mutiCallNo')" width="200"/>
                            </sweet-form-item>
                        </sweet-col>
                        <sweet-col :span="12" style="text-align: right;">
                            <sweet-button @click="addInputAccessCode" type="primary">
                                {{$t('isales.appointment.title.add')}}
                            </sweet-button>
                        </sweet-col>
                    </sweet-row>
                </sweet-form>
            </div>
            <aicc-table v-if="cmsEnvironment==='cms'"
                        :tableData="showInputCallNoList"
                        :tableColumns="tableOpInputColumns"
                        :tableTools="{ showPagination: true, columnConfig: false }"
                        :paginationConfig="paginationConfigOp"
                        @handlePagination="handlePaginationOp" style="margin-top:15px"
                        class="height-auto"
                        :max-height="430">
                <template #operate="scope">
                    <sweet-link type="primary" @click="handleDeleteInput(scope.row)">
                        {{$t('isales.specialday.title.delete')}}
                    </sweet-link>
                </template>
                <template #priority="scope">
                    <sweet-select @change="priorityChange(scope.row)" v-model="scope.row.priority" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="(item, index) in this.selectTaskStatusItem" :key="item.value"
                                      :label="item.label"
                                      :value="item.value"/>
                    </sweet-select>
                </template>
            </aicc-table>
            <aicc-table
                    :tableData="accessCodes"
                    :tableColumns="tableColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination"
                    ref='refAiccTable'
                    class="height-auto"
                    :max-height="430">
                <template #operate="scope">
                </template>
                <template #priority="scope">
                    <sweet-select v-model="scope.row.priority" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="(item, index) in this.selectTaskStatusItem" :key="item.value"
                                      :label="item.label"
                                      :value="item.value"/>
                    </sweet-select>
                </template>
            </aicc-table>
            <template #footer>
              <span class="dialog-footer">
                <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
                <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
              </span>
            </template>
        </sweet-dialog>
    </div>

    <div v-if="isEdit || isEditTemplate">
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.basicinfo.title.chooseCallNo')" width="616" class="aicc-dialog-limit-height"
                      :close-on-click-modal="false">
            <aicc-advanced-search
                    :templateMetaData="templateMetaData"
                    @handleSearch='searchPageFirst'
                    @handleCommand="handleCommand">
            </aicc-advanced-search>
            <aicc-table
                    :tableData="accessCodes"
                    :tableColumns="tableEditColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    class="height-auto"
                    :max-height="430"
                    @handlePagination="handlePagination"
                    ref='refAiccTable'>
                <template #priority="scope">
                    <sweet-select v-model="scope.row.priority" :placeholder="$t('isales.taskinfo.placeholder.choose')"
                                  disabled="true" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="(item, index) in this.selectTaskStatusItem" :key="item.value"
                                      :label="item.label"
                                      :value="item.value"/>
                    </sweet-select>
                </template>
                <template #operate="scope">
                    <sweet-link type="primary" @click="handleDelete(scope.row)">
                        {{$t('isales.specialday.title.delete')}}
                    </sweet-link>
                </template>
            </aicc-table>
            <template #footer>
              <span class="dialog-footer">
                <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
                <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
              </span>
            </template>
        </sweet-dialog>
    </div>
    <isalesCallerNoCreate v-model:show="createCallerNoDialogVisible"
                          @isalesCallerNoCreateConfirm="isalesCallerNoCreateConfirm"
                          :taskId="taskId" :cmsEnvironment="cmsEnvironment"
                          :ctiVersion="ctiVersion"></isalesCallerNoCreate>
</template>

<script>
    import {
        delCaller,
        queryCallerAccessCode,
        queryCallerNo,
        queryLocalCtiVersion
    } from "@/views/isales/api/systemAutoCallTask.js";
    import {RETURN_CODE, TASK_PRIORITY_TYPES_ENUM} from "@/views/isales/views/taskManagement/enum.js";
    import isalesCallerNoCreate from "@/views/isales/views/systemAutoCallTask/isalesCallerNoCreate.vue";
    import {ElMessageBox} from "element-plus";
    import {deepClone} from "@vitest/utils";

    export default {
        name: "isalesCallerNoSelect",
        components: {
            isalesCallerNoCreate
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            taskId: '',
            cmsEnvironment: '',
            taskType: '',
            callernolist: [],
            type: {
                type: String,
                default: 'create'
            },
        },
        data() {
            return {
                queryType:'',
                accessCodes: [],
                inputCallNoList: [],
                showInputCallNoList: [],
                selectedAllCallerNoObjs: [],
                selectTaskStatusItem: TASK_PRIORITY_TYPES_ENUM,
                returnCodeItem: RETURN_CODE,
                createCallerNoDialogVisible: false,
                tableColumns: [
                    {
                        type: 'selection',
                        width: '50px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.taskinfo.field.callNo'),
                        prop: 'accessCode',
                    },
                    {
                        label: this.$t('isales.taskinfo.field.priority'),
                        slot: 'priority',
                        fixed: 'right',
                    }
                ],
                tableEditColumns: [
                    {
                        type: 'selection',
                        width: '50px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.taskinfo.field.callNo'),
                        prop: 'accessCode',
                    },
                    {
                        label: this.$t('isales.taskinfo.field.priority'),
                        slot: 'priority',
                    },
                    {
                        label: this.$t('isales.agent.label.operate'),
                        slot: 'operate',
                        fixed: 'right',
                    }
                ],
                tableOpInputColumns: [
                    {
                        label: this.$t('isales.taskinfo.field.callNo'),
                        prop: 'accessCode',
                    },
                    {
                        label: this.$t('isales.taskinfo.field.priority'),
                        slot: 'priority'
                    },
                    {
                        label: this.$t('isales.agent.label.operate'),
                        slot: 'operate',
                        fixed: 'right',
                    }
                ],
                paginationConfig: {
                    PageSize: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next" // 分页组件布局配置
                },
                paginationConfigOp: {
                    PageSize: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next" // 分页组件布局配置
                },
                templateMetaData: {
                    basicConfig: { // 基础搜索配置
                        basicSearch: [
                            {
                                searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
                                modelValue: 'accessCode', // v-model绑定的key值
                                modelDefault: '', // v-model绑定的默认值
                                placeholderValue: this.$t('isales.taskinfo.field.mutiCallNo'), // placeholder绑定的值 国际化之后的值
                                rules: [],
                                colConfig: {
                                    xl: 12,
                                    lg: 12
                                }
                            },
                        ],
                        otherOperation: [ // 右侧其他操作按钮
                            {
                                basicButton: true, // 基础按钮
                                buttonName: this.$t('isales.specialday.title.delete'), // 按钮名称
                                eventName: 'batchDelete' // 按钮事件名称
                            },
                            {
                                basicButton: true, // 基础按钮
                                buttonStyle: 'primary', // 按钮风格 primary; success; info; warning; danger
                                buttonName: this.$t('isales.appointment.title.add'), // 按钮名称
                                eventName: 'create' // 按钮事件名称
                            }

                        ]
                    }
                },
                queryForm: {
                    inputAccessCode: '',  // OP-新建外呼任务场景下 新增主叫号码
                },
                limit: 10,
                offset: 0,
                limitOp: 10,
                offsetOp: 0,
                CTIVERSION_V3R8: 'V3R8',
                ctiVersion: 'V3R8',
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            },
            isView() {
                return this.queryType === 'view'
            },
            isEdit() {
                return this.queryType === 'edit'
            },
            isCreate() {
                return this.queryType === 'create'
            },
            isBatchEdit() {
                return this.queryType === 'batchEdit'
            },
            isCreateTemplate() {// 新建外呼任务模型
                return this.queryType === 'createTemplate'
            },
            isEditTemplate() {// 编辑外呼任务模型
                return this.queryType === 'editTemplate'
            },
            callerRule() {
                return {
                    inputAccessCode: [
                        {validator: this.$Validate.checkCalledNo, trigger: ['change', 'blur']},
                        {maxLength: 24, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']}
                    ]
                }
            }
        },
        methods: {
            priorityChange(data){
              let index = this.inputCallNoList.findIndex(item => {
                return item.accessCode == data.accessCode
              })
              this.inputCallNoList[index].priority = data.priority
            },
            handleCommand(data) { // 触发事件处理，调用对应方法，方法和eventName同名
                const {eventName, params} = data
                this[eventName](params)
            },
          searchPageFirst(params){
            this.offset = 0;
            this.$refs.refAiccTable.setCurrentPage(1);
            this.queryCallerAccessCode(params);
          },
            async queryCallerAccessCode(params) {
                let queryCondition = {};
                if (params && params.params && params.params.accessCode) {
                    queryCondition['accessCode'] = params.params.accessCode;
                }
                queryCondition['taskId'] = this.taskId;
                queryCondition['limit'] = this.limit;
                queryCondition['offset'] = this.offset;
                queryCallerAccessCode(queryCondition).then(res => {
                    if (res && res.data) {
                        this.accessCodes = res.data.list;
                        this.paginationConfig.total = res.data.total;
                    }
                })
            },
            async queryCallerNo() {
                let queryCondition = {};
                queryCondition['limit'] = this.limit;
                queryCondition['offset'] = this.offset;
                queryCallerNo(queryCondition).then(res => {
                    if (res) {
                        this.accessCodes = res.accessCodes;
                        if (this.accessCodes !== null && this.accessCodes.length > 0) {
                            this.accessCodes.forEach(item => {
                                let localCaller = this.selectedAllCallerNoObjs.filter(caller => caller.accessCode === item.accessCode);
                                if (localCaller !== null && localCaller.length > 0 && localCaller[0].priority !== null) {
                                    item.priority = localCaller[0].priority;
                                } else {
                                    item.priority = "3";
                                }
                            });
                            this.accessCodes.sort((a, b) => a.priority - b.priority);
                        }
                        this.paginationConfig.total = res.count;
                    }
                })
            },
            handlePagination(params) {
                this.limit = params.limit
                this.offset = params.offset
                if (this.isEdit || this.isEditTemplate) {
                    this.queryCallerAccessCode(params);
                } else {
                    this.queryCallerNo();
                }
            },
            handlePaginationOp(params) {
                this.limitOp = params.limit
                this.offsetOp = params.offset
                this.queryShowInputCallerNums();
            },
            confirm() {
                this.dialogVisible = false
                if (this.isEdit || this.isEditTemplate || this.isView) {
                    this.$emit('isalesCallerNoSelectConfirm', null);
                } else {
                    // 获取所有数据
                    let selectedAllCallerNoObj = [];
                    const selectionRows = this.$refs.refAiccTable.table.getSelectionRows();
                    if (selectionRows.length === 0 && this.inputCallNoList.length === 0) {
                        this.$message({
                          type: 'error',
                          duration: 10000,
                          showClose: true,
                          offset: 56,
                          message: this.$t('isales.management.message.SelectNumber')
                        });
                        return;
                    }
                    const tempSelectAccessCodes = [];
                    if (selectionRows && selectionRows.length > 0) {
                        selectionRows.forEach(item => {
                            tempSelectAccessCodes.push(item.accessCode);
                        });
                    }

                    if (this.inputCallNoList.length !== 0) {
                        let noAddInputs = this.inputCallNoList.filter(item => !tempSelectAccessCodes.includes(item.accessCode));
                        noAddInputs.forEach(item => {
                            selectedAllCallerNoObj.push(item);
                        })
                    }
                    //手动外呼
                    if (this.tasktype === '4') {
                        let tempTask = {};
                        tempTask["accessCode"] = tempSelectAccessCodes[0];
                        selectedAllCallerNoObj.push(tempTask);
                    }
                    if (this.tasktype !== '4' && tempSelectAccessCodes !== null && tempSelectAccessCodes.length > 0) {
                        selectionRows.forEach(item => {
                            selectedAllCallerNoObj.push(item);
                        })
                    }
                    if (selectedAllCallerNoObj && selectedAllCallerNoObj.length > 100) {
                        this.$message({
                          type: 'error',
                          duration: 10000,
                          showClose: true,
                          offset: 56,
                          message: this.$t('isales.management.message.SelectNumberMax')
                        });
                        return;
                    }
                    this.$emit('isalesCallerNoSelectConfirm', selectedAllCallerNoObj);
                }
            },
            batchDelete() {
                ElMessageBox.confirm(this.$t('isales.taskmanagement.message.deleteConfirm'), this.$t('isales.notification.receiver.deletetips'),{
                  type: 'warning',
                  customClass: 'aicc-message-box-small'
                }).then(async () => {
                    const selectionRows = this.$refs.refAiccTable.table.getSelectionRows();
                    if (!selectionRows || selectionRows.length <= 0) {
                        this.$message({
                          type: 'error',
                          duration: 10000,
                          showClose: true,
                          offset: 56,
                          message: this.$t('isales.notification.receiver.delete.error')
                        });
                        return;
                    }
                    const selectedAllCallerNoObj = [];
                    selectionRows.forEach(item => {
                        let tempTask = {};
                        tempTask["accessCode"] = item.accessCode;
                        tempTask["priority"] = 3;
                        selectedAllCallerNoObj.push(tempTask);
                    })
                    const params = {"taskId": this.taskId, "callerNo": selectedAllCallerNoObj};
                    const result = await delCaller(params)
                    if (result.returnCode === this.returnCodeItem.SUCCESS) {
                        this.$message({
                            type: 'success',
                          duration: 5000,
                          offset: 56,
                          message: this.$t('isales.agent.calledroute.delsuccess')
                        })
                        await this.isalesCallerNoCreateConfirm();
                    } else if (result.returnCode === this.returnCodeItem.FAIL_EXIT) {
                        this.$message({
                            type: 'error',
                          duration: 10000,
                          offset: 56,
                          'show-close': true,
                          message: this.$t('isales.taskmanagement.title.deleteFailExitOne')
                        })
                        return
                    } else {
                        this.$message({
                            type: 'error',
                          duration: 10000,
                          offset: 56,
                          'show-close': true,
                          message: this.$t('isales.taskmanagement.title.deleteFailed')
                        })
                        return
                    }
                }).catch(() => {
                })
            },
            async handleDelete(row) { // 删除
                ElMessageBox.confirm(this.$t('isales.taskmanagement.message.deleteConfirm'), this.$t('isales.notification.receiver.deletetips'),{
                  type: 'warning',
                  customClass: 'aicc-message-box-small'
                }).then(async () => {
                    const selectedAllCallerNoObj = [];
                    let tempTask = {};
                    tempTask["accessCode"] = row.accessCode;
                    tempTask["priority"] = 3;
                    selectedAllCallerNoObj.push(tempTask);
                    const params = {"taskId": this.taskId, "callerNo": selectedAllCallerNoObj};
                    const result = await delCaller(params)
                    if (result.returnCode === this.returnCodeItem.SUCCESS) {
                        this.$message({
                            type: 'success',
                          duration: 5000,
                          offset: 56,
                          message: this.$t('isales.agent.calledroute.delsuccess')
                        });
                        await this.isalesCallerNoCreateConfirm();
                    } else if (result.returnCode === this.returnCodeItem.FAIL_EXIT) {
                        this.$message({
                            type: 'error',
                          duration: 10000,
                          offset: 56,
                          'show-close': true,
                          message: this.$t('isales.taskmanagement.title.deleteFailExitOne')
                        })
                        return
                    } else {
                        this.$message({
                            type: 'error',
                          duration: 10000,
                          offset: 56,
                          'show-close': true,
                          message: this.$t('isales.taskmanagement.title.deleteFailed')
                        })
                        return
                    }
                }).catch(() => {
                })
            },
            async handleDeleteInput(data) { // 删除
                ElMessageBox.confirm(this.$t('isales.taskmanagement.message.deleteConfirm'), this.$t('isales.notification.receiver.deletetips'),{
                  type: 'warning',
                  customClass: 'aicc-message-box-small'
                }).then(async () => {
                    let deleteIndex = this.inputCallNoList.findIndex(function (deleteItem) {
                        return deleteItem.accessCode === data.accessCode;
                    });
                    if (-1 !== deleteIndex) {
                        this.inputCallNoList.splice(deleteIndex, 1);
                    }
                    this.paginationConfigOp.total--;
                    this.queryShowInputCallerNums();
                }).catch(() => {
                })
            },
            create() {
                this.createCallerNoDialogVisible = true;
            },
            async isalesCallerNoCreateConfirm() {
                this.limit = 10;
                this.offset = 0;
                this.queryCallerAccessCode();
            },
            async queryCtiVersion() {
                this.ctiVersion = await queryLocalCtiVersion();
            },
            async addInputAccessCode() {
                if (this.queryForm.inputAccessCode === null || this.queryForm.inputAccessCode === '') {
                    this.$message({
                      type: 'error',
                      duration: 10000,
                      showClose: true,
                      offset: 56,
                      message: this.$t('isales.taskinfo.field.mutiCallNo')
                    });
                    return;
                }
                let sysAutoResult = await this.$refs.queryFormValid.validate().catch(err => err);
                if(sysAutoResult!==true){
                    return;
                }
                const index = this.inputCallNoList.findIndex(item => item.accessCode === this.queryForm.inputAccessCode);
                if (index >= 0) {
                    this.$message({
                      type: 'error',
                      duration: 10000,
                      showClose: true,
                      offset: 56,
                      message: this.$t('isales.taskinfo.callno.repeat')
                    });
                    return;
                }
                let inputCallerNoObj = {};
                // 业务结果策略数据标识当前最大值
                if (this.ctiVersion === this.CTIVERSION_V3R8 && this.taskType !== '4') {
                    inputCallerNoObj["priority"] = "3";
                }
                inputCallerNoObj["accessCode"] = this.queryForm.inputAccessCode;
                this.queryForm.inputAccessCode = null;
                this.inputCallNoList.push(inputCallerNoObj);
                this.paginationConfigOp.total++;
                if (this.ctiVersion === this.CTIVERSION_V3R8 && this.taskType !== '4') {
                    this.inputCallNoList.sort((a, b) => a.priority - b.priority);
                }
                this.queryShowInputCallerNums();
            },
            queryShowInputCallerNums() {
                if (this.inputCallNoList === null || this.inputCallNoList.length <= 0) {
                    this.showInputCallNoList = [];
                    return;
                }
                let tempInputCallNoList = deepClone(this.inputCallNoList);
                this.paginationConfigOp.total = tempInputCallNoList.length;
                this.showInputCallNoList = tempInputCallNoList.splice(this.offsetOp, this.limitOp);
            }
        },
        async created() {
            this.queryType = this.$route.query.type;
            if (this.isEdit || this.isEditTemplate) {
                await this.queryCallerAccessCode();
            } else {
                if (this.callernolist && this.callernolist.length > 0) {
                    this.selectedAllCallerNoObjs = this.callernolist;
                }
                await this.queryCallerNo();
            }
            await this.queryCtiVersion();
        },
    }
</script>

<style scoped>

</style>