<template>
  <!-- 站内信 -->
  <div class = "body">
    <sweet-radio-group v-if="auth=='admin' && !editType" v-model="notiScope" @change="notiScopeChange" class="notice-scope">
      <sweet-radio-button label="system">{{ $t('ccnotification.notifition.sendmessage.sysmessage') }}</sweet-radio-button>
      <sweet-radio-button label="internal">{{ $t('ccnotification.notifition.sendmessage.internalmessage') }}</sweet-radio-button>
    </sweet-radio-group>
  
    <sweet-form ref="formRef" label-width="auto" :model="formData" :rules="rules" label-position="left" class="form">
      <sweet-form-item v-if="!editType" :label="$t('ccivr.ivr.ivrflow.type')">
        <sweet-radio-group v-model="notiType" @change="notiTypeChange">
          <sweet-radio label="bulletin" v-if="supportBulletin">{{ $t('ccnotification.notifition.sendmessage.bulletin') }}</sweet-radio>
          <sweet-radio label="internalMesage" v-if="notiScope == 'internal'">{{ $t('ccnotification.notifition.sendmessage.internal') }}</sweet-radio>
          <sweet-radio label="shortMessage" v-if="auth != 'agent'">{{ $t('message.template.mediaType.shortmessage') }}</sweet-radio>
          <sweet-radio label="email" v-if="auth != 'agent'">{{ $t('message.template.mediaType.email') }}</sweet-radio>
        </sweet-radio-group>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.referenceTemplate')"  v-if="auth != 'agent'">
        <sweet-switch v-model="templateSwitchOn" @change="onTemplateSwitchChange"/>
        <div class="template-select">  
          <sweet-input v-model="selectedTemplateName" v-if="templateSwitchOn" @click="selectTemplate" clearable readonly/>
        </div>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.recipient')">
        <sweet-input :placeholder="$t('ccnotification.notifition.sendmessage.enterrecipient')" v-model="recipient" @click="selectUserRecipient()" readonly>
          <template #suffix>
            <img src="../../assets/img/icon-user.png">
          </template>
        </sweet-input>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.recipientGroup')">
        <sweet-input
          :placeholder="$t('ccnotification.notifition.sendmessage.enterRecipientGroup')"
          v-model="notifitionRecipientGroup" 
          @click="selectTenantRecipientGroup()" 
          class="aicc-input-dialog" readonly></sweet-input>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.messagetitle')" prop="notificationTitle">
        <sweet-input :placeholder="$t('ccnotification.notifition.sendmessage.notifitiontitle')" v-model="formData.notificationTitle" :disabled="isTemplateSlected()" />
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.priority')">
        <sweet-radio-group v-model="selectedPriority">
          <sweet-radio v-for="item in priorityItems" :label="item.priorityNameKey" :key="item.priorityNameKey">{{ item.priorityName }}</sweet-radio>
        </sweet-radio-group>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.delaysend')"  v-if="auth != 'agent'">
        <sweet-switch v-model="isDelaySend" />
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.sendtime')" v-if="isDelaySend">
        <div class="wrap-input">
          <sweet-date-picker v-model="sendTime" type="datetime" />
        </div>
      </sweet-form-item>

      <sweet-form-item v-if="templateSwitchOn" :label="$t('ccnotification.notifition.template.title.setting')">
        <aicc-table
        ref="variableRef"
        :tableData="formData.variablesResult"
        row-key="id" 
        :tableColumns="tableColumns"
        :tableTools="{ columnConfig: false, showPagination: false }"
        style="width: 100%" >
          <template #value="scope">
            <sweet-form-item :prop="'variablesResult.' + scope.$index + '.value'"  :rules="rules.variableRule" class="table-input-wrap">
              <sweet-input v-model="scope.row.value" />
            </sweet-form-item>
          </template>
        </aicc-table>
      </sweet-form-item>
    </sweet-form>

    <div v-if="templateSwitchOn && formData.variablesResult.length > 0" class="preview-button">
      <sweet-button type="primary" @click="previewVariable">{{ $t('ccnotification.notifition.sendmessage.preview') }}</sweet-button>
    </div>
    <div class="editor-wrap"><div id="sysInternalEditor"></div></div>

    <send-select-template-dialog v-if="showTemplateDialog"
     :title="$t('ccnotification.notifition.sendmessage.templateselect')"
     :auth="auth"
     type="1"
     mediatype="2"
     @onClose="onTemplateDialogClose"
     @selectTemplate="onSelectTemplate" />

    <select-user-dialog
      v-if="showUserDialog" 
      :title="$t('message.template.message.recipient')" 
      :allSelected="isSelectAllUsers"
      :selectedUsers="selectUsers" @onClose="onUserDialogClose" 
      @selectUser="onUserSelected"/>

    <select-group-dialog :propSelectedData="selectGroups" v-if="showGroupDialog" @onClose="onGroupDialogClose" @groupSelected="onGroupSelected" />
  </div>

  <div class="footer">
    <sweet-button @click="send(1)">{{ $t("message.template.message.save") }}</sweet-button>
    <sweet-button type="primary" @click="send(2)" :v-loading="isSending">{{ $t("ccnotification.notifition.sendmessage.send") }}</sweet-button>
  </div>
</template>

<script>
import tinymce from 'tinymce'
import { getTinymceLangs } from "@/utils"
import { ElMessageBox } from 'element-plus'
import AiccElMessage from '@/utils/el-message'
import { ArrowDown } from '@element-plus/icons-vue'
import cookie from "@/utils/cookie"
import { handleSendResult } from './common'
import { queryVariableByTemplateId, sendAgentInternalMessage, sendSysInternalMessage, sendTenantInternalMessage } from '../../api/index'
import selectUserDialog from './dialog/select-user-dialog.vue'
import selectGroupDialog from './dialog/select-group-dialog.vue'
import sendSelectTemplateDialog from './dialog/send-select-template-dialog.vue'
import { specialStrValidateMgs } from '@/views/ccnotification/utils/validator'
import { getTenantGroup } from '@/views/base/api'

export default {
  components: {
    selectUserDialog,
    selectGroupDialog,
    sendSelectTemplateDialog
  },
  data() {
    return {
      ArrowDown,
      notiScope: 'internal', // 系统通知，内部通知
      notiType: 'internalMesage', // 公告，站内信，短信，邮件
      templateSwitchOn: false,
      priorityItems: [
        {
          'priorityNameKey': '1',
          'priorityName': $t('ccnotification.notifition.sendmessage.priorityGeneral')
        },
        {
          'priorityNameKey': '2',
          'priorityName': $t('ccnotification.notifition.sendmessage.priorityUrgent')
        },
        {
          'priorityNameKey': '3',
          'priorityName': $t('ccnotification.notifition.sendmessage.priorityExtraUrgent')
        },
        {
          'priorityNameKey': '4',
          'priorityName': $t('ccnotification.notifition.sendmessage.priorityFault')
        }
      ],
      selectedPriority: '1',
      selectedTemplateName: '',
      selectedTemplateId: '',
      showTemplateDialog: false,
      recipient: '',
      showUserDialog: false,
      notifitionRecipientGroup: '',
      showGroupDialog: false,
      selectUsers: [],
      selectedUserIds: '',
      selectGroups: [],
      selectGroupIds: '',
      isSelectAllUsers: false,
      formData: {
        notificationTitle: '',
        variablesResult: []
      },
      rules:{
        notificationTitle: [{
          min: 0,
          max: 200,
          message: this.$t('SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB').replace("0", 200),
          trigger: ['change', 'blur']
        }],
        variableRule: [{
          validator: specialStrValidateMgs,
          trigger: ['change', 'blur']
        }, {
          min: 0,
          max: 500,
          message: this.$t('SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB').replace("0", 500),
          trigger: ['change', 'blur']
        }]
      },
      isDelaySend: false,
      sendTime: '',
      isSending: false,
      messageContent: '',
      tableColumns: [
        {
          label: $t('ccnotification.notifition.template.label.index'),
          prop: 'id'
        },
        {
          label: $t('ccnotification.notifition.variable.variablename'),
          prop: 'name'
        },
        {
          label: $t('ccnotification.notifition.template.label.des'),
          prop: 'description'
        },
        {
          width: 426,
          label: $t('ccnotification.notifition.template.label.value'),
          slot: 'value'
        }
      ],
      tenantGroups: []
    }
  },
  methods: {
    notiTypeChange(type) {
      this.$emit('notiTypeChange', type)
    },
    notiScopeChange(type) {
      this.$emit('notiScopeChange', type)
    },
    onTemplateSwitchChange(val) {
      if (!val) {
        tinymce.get('sysInternalEditor').getBody().setAttribute('contenteditable', true)
        tinymce.get('sysInternalEditor').setContent('')
        this.selectedTemplateName = ''
        this.selectedTemplateId = ''
        this.formData.variablesResult = []
        this.selectedUserIds = ''
        this.selectUsers = []
        this.recipient = ''
        this.isSelectAllUsers = false
        this.formData.notificationTitle = ''
      } else {
        this.selectTemplate()
      }
    },
    onTemplateDialogClose() {
      this.showTemplateDialog = false
      if (!this.selectedTemplateId) {
        this.templateSwitchOn = false
      }
    },
    selectTemplate() {
      this.showTemplateDialog = true
    },
    onSelectTemplate(val) {
      this.selectedTemplateName = val.name
      this.messageContent = val.content
      this.formData.notificationTitle = val.title
      this.selectedUserIds = val.receiverIds
      if (val.receiverIds) {
        this.selectUsers = val.receiverIds.split(";").filter(item => item != "" && item.trim() != "[]")
      } else {
        this.selectUsers = []
      }
      this.isSelectAllUsers = false
      this.recipient = val.receiverNames
      
      this.selectedTemplateId = val.id
      this.initVariables(val)
      tinymce.get('sysInternalEditor').setContent(val.content)
      tinymce.get('sysInternalEditor').getBody().setAttribute('contenteditable', false)
    },
    initVariables(val) {
      queryVariableByTemplateId(val.id).then(res => this.formData.variablesResult = res)
    },
    selectUserRecipient() {
      this.showUserDialog = true
    },
    onUserDialogClose() {
      this.showUserDialog = false
    },
    onUserSelected(val, isAll) {
      this.isSelectAllUsers = isAll
      this.selectUsers = val
      this.recipient = ''
      for (let i = 0; i < val.length; i++) {
        this.recipient += val[i].userName + ";"
      }
    },
    selectTenantRecipientGroup() {
      this.showGroupDialog = true
    },
    onGroupDialogClose() {
      this.showGroupDialog = false
    },
    onGroupSelected(val) {
      this.selectGroups = val
      this.notifitionRecipientGroup = ''
      this.selectGroupIds = ''
      for (let i = 0; i < val.length; i++) {
        this.notifitionRecipientGroup += val[i].groupName + ";"
        this.selectGroupIds += val[i].groupId + ";"
      }
    },
    replaceVariable() {
      let variableJson = {}
      this.formData.variablesResult.forEach(ele => {
        if (ele.value) {
          variableJson[ele.name] = ele.value
        } else {
          variableJson[ele.name] = ""
        }
      })
      let content = this.messageContent
      for (var k in variableJson) {
        let re = new RegExp("\\$\\{" + k + "\\}", "g")
        content = content.replace(re, variableJson[k])
      }
      return content
    },
    previewVariable() {
      tinymce.get('sysInternalEditor').setContent(this.replaceVariable())
    },
    initTinymce() {
      let language = cookie.getSystemLanguage() || 'zh_CN'
      tinymce.init({
        selector: '#sysInternalEditor',
        language,
        language_url: getTinymceLangs(language),
        width: '100%',
        plugins: 'image table',
        menubar: false,
        toolbar: 'undo redo | fontfamily | fontsize | bold italic underline strikethrough removeformat | data time | alignleft aligncenter alignright alignjustify | table',
        font_size_formats: '10px 11px 12px 14px 16px 18px 20px 24px 36px',
        font_family_formats: $t('ccmessaging.chat.tinyMCE.yaHei') + '=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;' + $t('ccmessaging.chat.tinyMCE.simsun') + '=simsun,serif;' + $t('ccmessaging.chat.tinyMCE.FangSong') + '=FangSong,serif;' + $t('ccmessaging.chat.tinyMCE.SimHei') + '=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Comic Sans MS=comic sans ms,sans-serif;Impact=impact,chicago;Times New Roman=times new roman,times;',
        readonly: false,
        branding: false,
        resize: false,
        statusbar: false,
        object_resizing: false,
        content_style: 'body{font-family:Microsoft YaHei}'
      }).then(() => {
        if (this.messageInfo && this.editType === 'edit') {
          tinymce.get('sysInternalEditor').setContent(this.messageInfo.content)
        }
        if (this.messageInfo && this.editType === 'reply') {
          const contentReply = `<p></p><p>----------------Re-----------------</p>${this.messageInfo.content}`
          tinymce.get('sysInternalEditor').setContent(contentReply)
        }
      })
    },
    init() {
      this.$nextTick(() => {
        if(this.messageInfo && this.editType === 'edit') {
            if(this.messageInfo.groupInfo.length != 0) {
              this.selectGroups = this.messageInfo.groupInfo
              this.notifitionRecipientGroup = this.setGroupNames(this.messageInfo.groupInfo)
              for(let i=0; i<this.notifitionRecipientGroup.split(";").length; i++) {
                this.selectGroups[i].groupName = this.notifitionRecipientGroup.split(";")[i]
              }
            }
            this.formData.notificationTitle = this.messageInfo.title
            this.recipient = this.messageInfo.receiverNames
            const receiverNames = this.messageInfo.receiverNames?.split(";").filter(item => item != "")
            this.selectUsers = this.messageInfo.receiverIds?.split(";").filter(item => item != "").map((id, index) => {
              return {
                userId: id,
                userName: receiverNames[index]
              }
            })
            this.selectedPriority = this.messageInfo.grade
        }
        if(this.messageInfo && this.editType === 'reply') {
          this.formData.notificationTitle = `Re: ${this.messageInfo.title}`
          this.recipient = this.messageInfo.senderName
          this.selectUsers = [{
            userId: this.messageInfo.senderId,
            userName: this.messageInfo.senderName
          }]
        }
        this.initTinymce()
      })
    },
    async send(status) {
      let valid = await this.$refs.formRef.validate().catch(e => {return false})
      if (!valid) {
        return
      }
      if (status != 1 && !this.recipient && !this.notifitionRecipientGroup) {
        AiccElMessage.info($t('ccnotification.notifition.sendmessage.selectGroupOrRecipient'))
        return
      }
      if (status != 1 && this.releaseTime) {
        if (new Date(this.releaseTime).getTime() < new Date().getTime()) {
          AiccElMessage.warning($t('ccnotification.notifition.sendmessage.sendtimebeforenow'))
          return
        }
        if (new Date(this.releaseTime).getTime() >= new Date('2038/1/19').getTime()) {
          AiccElMessage.warning($t('ccnotification.notifition.sendmessage.limitdate'))
          return
        }
      }
      const content = tinymce.get('sysInternalEditor').getBody().textContent
      if (content == null || content.trim() == '') {
        AiccElMessage.warning($t('ccnotification.common.message.emptyContent'))
        return
      }
      if (status != 1 && content.length >= 4096) {
        AiccElMessage.warning($t('ccnotification.common.message.maxLength'))
        return
      }
      this.isSending = true
      let requestParams
      if (this.templateSwitchOn && this.selectedTemplateName) {
        const contentAfterReplace = this.replaceVariable()
        if (!contentAfterReplace || contentAfterReplace == '<p></p>') {
          AiccElMessage.warning('ccnotification.common.message.emptyContent')
          this.isSending = false
          return
        }

        requestParams = {
          templateId: this.selectedTemplateId,
          variables: this.formData.variablesResult.map(item => {
            return {name: item.name, value: item.value}
          }),
          sendTime: new Date(this.sendTime).getTime()
        }
      } else {
        requestParams = {
          title: this.formData.notificationTitle,
          messageType: 1, // 内部通知
          mediaType: 2, // 站内信
          sendTime: new Date(this.sendTime).getTime(),
          content: tinymce.get('sysInternalEditor').getContent()
        }
      }
      if (this.selectGroupIds) {
        requestParams.groupIds = this.selectGroupIds
      }
      if(this.messageInfo && this.editType === 'edit') {
        requestParams.id = this.messageInfo.id
      }
      requestParams.status = status
      requestParams.grade = this.selectedPriority
      requestParams.receiverIds = this.selectUsers?.map(ele => {
        return ele ? typeof ele == "string" ? ele : ele.userId : ""
      }).filter(e => e != '')
      if(!requestParams.receiverIds) {
        requestParams.receiverIds = []
      }

      if (this.editType === 'edit') {
        requestParams.id = this.messageInfo.id
      }

      let request
      if (this.auth == 'admin') {
        request = sendSysInternalMessage(requestParams)
      } else if (this.auth == 'tenant') {
        request = sendTenantInternalMessage(requestParams)
      } else {
        request = sendAgentInternalMessage(requestParams)
      }
      request.then(res => {
        handleSendResult(res, status == 1)
        this.isSending = false
      }).catch(e => {
        AiccElMessage.error($t('ccnotification.notifition.sendmessage.sendexception'), $t('ccnotification.common.title.fail'), {
          type: 'error'
        })
        this.isSending = false
      })
    },
    isTemplateSlected() {
      if (this.templateSwitchOn && this.selectedTemplateId) {
        return true
      } else {
        return false
      }
    },
    /**
     * 组装接收群组显示名称
     * @param { Object } groupInfo 群组信息
     */
     setGroupNames(groupInfo) {
      let result = []
      groupInfo.forEach((item) => {
        result.push(
          this.tenantGroups.find((group) => {
            return group.groupId === item.groupId
          }).groupName
        )
      })
      return result.join(';')
    },
  },
  mounted() {
    getTenantGroup(this.cookieUtils.getCookie('tenant_space_id')).then((res) => {
      if (res.groups) {
        this.tenantGroups = res.groups
      }
      this.init()
    })
  },
  beforeUnmount() {
    tinymce.remove('#sysInternalEditor')
  },
  activated() {
    if (!tinymce.get('sysInternalEditor')) {
      this.initTinymce() 
    }
  },
  deactivated() {
    if (tinymce.get('sysInternalEditor')) {
      tinymce.get('sysInternalEditor').remove()
    }
  },
  props: {
    auth: String, // admin/tenant/agent 系统管理员/租户管理员/座席
    supportBulletin: {
      type: Boolean,
      default: true
    },
    messageInfo: {
      type: Object,
      default: null
    },
    editType: {
      type: String
    }
  }
}
</script>

<style scoped>
.body {
  flex-grow: 1;
  margin: 20px;
  background: white;
  border-radius: 8px;
  padding: 16px;
  padding-inline-end: 40px;
  overflow-y: scroll;
}

.footer {
  background: white;
  width: 100%;
  padding: 20px;
  padding-inline-end: 32px;
  display: flex;
  justify-content: flex-end;
}

.notice-scope {
  margin-inline-start: 20px;
  margin-top: 10px;
  margin-bottom: 18px;
}

.form {
  margin-inline-start: 10px;
  margin-top: 10px;
}

.template-select {
  width: 400px;
  margin-inline-start: 20px;
}

.release-time-picker {
  width: 400px;
  margin-inline-end: 20px;
}

.wrap-input {
  width: 400px;
}

.editor-wrap {
  margin-inline-start: 20px;
}

.preview-button {
  padding-bottom: 20px;
  text-align: end;
}
.is-error.table-input-wrap {
  padding-bottom: 16px;
}
</style>