<template>
  <el-dialog v-model="state.isShow"
             :title="$t('cms.report.field.selectOrg')"
             width="428px" @closed="handleClosed">
    <el-input v-model="filterText" :placeholder="$t('base.keywordFilter')" clearable/>

    <el-scrollbar height="396px">
      <el-tree ref="treeRef"
               class="filter-tree"
               :data="state.treeData"
               :props="defaultProps"
               default-expand-all
               :filter-node-method="handleFilterNode"
               :node-key="defaultProps.id"
               :expand-on-click-node="false"
               :highlight-current="true"
      />
    </el-scrollbar>
    <hr>
    <div class="inner-pattern-footer">
      <span class="inner-text">
      {{$t('base.noOrganization')}}
      </span>
      <el-switch v-model="state.noOrg"
                 id="no-org-pattern"
                 size="large">
      </el-switch>
    </div>


    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.isShow = false">
          {{$t('cms.systemmonitor.field.cancel')}}
        </el-button>
        <el-button type="primary" @click="handleConfirm">
          {{$t('base.confirm')}}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>


<script setup>
import {getCurrentInstance, nextTick, reactive, ref, watch, onMounted} from 'vue';
import {queryOrgList} from "@/views/base/api";

const currentInstance = getCurrentInstance()

let state = reactive({
  treeData: [],
  isShow: false,
  noOrg: true,
})

let filterText = ref()

let props = defineProps({
  currentKey: Number,
  currentNoOrg: {
    type: Boolean,
    default() {
      return true
    }
  },
  clearCurrentKey: {
    type: Boolean,
    default() {
      return false
    }
  }
});

let open = () => {
  callBackend()
  state.isShow = true;
  state.noOrg = props.currentNoOrg
}

onMounted(() => {
  callBackend()
})

const callBackend = () => {
  if (props.clearCurrentKey) {
    currentInstance.refs.treeRef?.setCurrentKey(null)
  }
  const data = {
    param: {}
  }
  queryOrgList(data).then(res => {
    let list = res.orgs;
    if (list && list.length > 0) {
      for (const org of list) {
        if (typeof (org.parentOrgId) === 'undefined' || org.parentOrgId === null || String(org.parentOrgId) === '') {
          org.parentOrgId = '1'
        }
      }
      let parentOrg = list.filter(org => org.parentOrgId === '1');
      for (const org of parentOrg) {
        org.id = org.orgId
        org.label = org.orgName
        org.children = getChildren(org, list)
      }
      state.treeData = parentOrg
    }
    
      nextTick(() => {
        // 适配接口慢导致的选中后再次回选的问题
        if ((currentInstance.refs.treeRef?.getCurrentKey() === props.currentKey || !currentInstance.refs.treeRef?.getCurrentKey()) && props.currentKey) {
          currentInstance.refs.treeRef?.setCurrentKey(props.currentKey)
        } else {
          currentInstance.refs.treeRef?.setCurrentKey(currentInstance.refs.treeRef?.getCurrentKey())
        }
      })
    
  })
}

const getChildren = (root, list) => {
  let children = list.filter(org => org.parentOrgId === root.orgId);
  for (const org of children) {
    org.id = org.orgId
    org.label = org.orgName
    org.children = getChildren(org, list)
  }
  return children
}

const defaultProps = reactive({
  id: 'id',
  children: 'children',
  label: 'label'
})

const handleFilterNode = (value, data) => {
  if (!value) {
    return true;
  }
  return data.label.indexOf(value) !== -1;
}

watch(
    filterText,
    (newValue) => {
      currentInstance.refs.treeRef.filter(newValue)
    },
)

let emits = defineEmits(['selectedOrg']);

const handleConfirm = () => {
  let value = ''
  if (currentInstance.refs.treeRef.getCurrentNode()) {
    value = currentInstance.refs.treeRef.getCurrentNode().label;
  }
  let selectNoOrg = false
  if (state.noOrg) {
    value = value ? value + ',' + $t('base.noOrganization') : $t('base.noOrganization')
    selectNoOrg = true
  }
  let data = {
    key: currentInstance.refs.treeRef.getCurrentKey(),
    value: value,
    selectNoOrg: selectNoOrg
  }
  emits('selectedOrg', data);
  state.isShow = false
}

const handleClosed = () => {
  currentInstance.refs.treeRef?.setCurrentKey(props.currentKey)
  state.noOrg = true
  filterText.value = ''
}


defineExpose({
  open
})

</script>



<style scoped>

.inner-pattern-footer {
  display: flex;
  justify-content: space-between;
}

.inner-text {
  margin: auto 0;
}

</style>