import request from "@/utils/request";

/**
 * 查询黑名单
 * @param data
 * @returns {*}
 */
export const queryObResultList = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/obResult/queryObResultList',
        method: 'post',
        data
    })
}

/**
 * 新增外呼数据
 * @param data
 * @returns {*}
 */
export const createObResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/obResult/createObResult',
        method: 'post',
        data
    })
}

export const queryObResultMapFailCode = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/obResult/queryObResultMapFailCode',
        method: 'post',
        data
    })
}

export const queryObResultDetail = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/obResult/queryObResultDetail',
        method: 'post',
        data
    })
}

export const updateObResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/obResult/updateObResult',
        method: 'post',
        data
    })
}

export const deleteObResultBatch = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/obResult/deleteObResultBatch',
        method: 'post',
        data
    })
}
