import { defineStore } from 'pinia'
import { queryConfigs } from '@oifde/request/api/config.js'

export const useOifdeUserStore = defineStore('oifdeUser', {
    state: () => {
        return {
            helpAddress: '',
            source: 'OIFDE',
            isSaasMode: false
        }
    },
    actions: {
        queryUserConfigs() {
            return new Promise((resolve, reject) => {
                const keyHelpAddress = 'oifde.support.url'
                const keyProductMode = 'oifde.business.productMode'
                queryConfigs([keyHelpAddress, keyProductMode]).then(config => {
                    if (!config || config.length === 0) {
                        reject('config is empty')
                    }
                    if (config[keyProductMode]) {
                        this.isSaasMode = config[keyProductMode] === 'saas'
                    }
                    this.helpAddress = config[keyHelpAddress]
                    resolve(config)
                })
            })
        }
    }
})