export default {
  "dy.agent.audio.tab.outgoingcall" : "مكالمة صادرة",
  "dy.agent.audio.tab.internalcall" : "مكالمة داخلية",
  "dy.agent.tab.over.number.hints" : "يمكن فتح ثماني علامات تبويب كحد أقصى. لا يمكنك فتح المزيد من علامات التبويب. يرجى إغلاق علامات التبويب الأخرى أولاً.",
  "dy.chat.multimedia.update" : "تحديث",
  "dy.chat.agent.logout.hints" : "لا يوجد تفاعلات، يرجى تسجيل الدخول لبدء عملك.",
  "dy.chat.label.contacts" : "جهات الاتصال",
  "dy.chat.label.call" : "مكالمة",
  "dy.chat.label.multimedia" : "الوسائط المتعددة",
  "dy.chat.label.history" : "التاريخ",
  "dy.chat.label.monitor" : "مراقب",
  "dy.agent.label.id" : "معرف:",
  "dy.agent.label.enter.exnum" : "أدخل الرقم الخارجي",
  "dy.chat.multimedia.required" : "مطلوب",
  "dy.chat.history.disconnect.tip" : "تم قطع الاتصال ، يمكنك إرسال رسالة غير متصل إلى العميل مرة أخرى",
  'dy.agent.session.list.timeout': 'انتهت مهلة النظام',
  'dy.session.transfer.button': 'حسناً',
  'dy.agent.message.tipsNotMonitor': 'أنت لست مفتشًا. لا يمكنك إجراء الفحص.',
  'dy.agent.message.selectInfo': 'انقر فوق الموظف أولاً لفحص الموظف.',
  'dy.agent.attachment.notPreview': 'نوع الملف لا يدعم المعاينة، الرجاء تنزيل الملف لعرض المحتوى.',
  "dy.chat.agent.novoEmail": "بريد إلكتروني جديد",
  "dy.chat.sendmessage.recipient" : "إلى",
  "dy.chat.chatcard.folded": "مطوية",
  "dy.chat.status.drafts": "المسودات",
}