export default {
  "ccd.agent.agentevent.AgentEvent_Auto_Answer": "Respuesta automática del agente",
  "ccd.agent.button.ok": "OK",
  "ccd.agent.webrtc.label.large": "grande",
  "ccd.agent.tips.success": "Éxito",
  "ccd.agent.resetphonepass.inputcur": "Contraseña de cuenta actual",
  "ccd.agent.button.threeParty": "Tres Partes",
  "ccd.agent.agentevent.AgentEvent_Call_Release": "El agente sale de la llamada",
  "ccd.agent.agentevent.AgentConf_SelfJoinResult": "Agente Unirse a una conferencia",
  "ccd.agent.agentevent.AgentOther_InService": "El agente inició sesión correctamente.",
  "ccd.agent.agentevent.AgentEvent_Call_Out_Fail": "El agente no puede iniciar una llamada externa.",
  "ccd.agent.openeye.deregister": "El número de softphone se desactiva correctamente.",
  "ccd.agent.webrtc.label.online": "Registrado",
  "ccd.agent.agentevent.AgentEvent_Customer_Alerting": "El teléfono suena.",
  "ccd.agent.webrtc.label.remote.name": "Otras partes",
  "ccd.agent.openeye.sipconfig.success": "El SIP se configura correctamente.",
  "ccd.agent.label.idle": "Disponible",
  "ccd.agent.webrtc.label.sippwd": "Contraseña del Softphone",
  "ccd.agent.tips.media.agentType.no.support.callout": "Los agentes multimedia no pueden realizar llamadas salientes",
  "ccd.agent.agentevent.info": "Sugerencias",
  "ccd.agent.button.innerHelp": "Contáctenos",
  "ccd.agent.agentevent.AgentEvent_Agent_Occupyed": "El agente ya ha sido suplantado",
  "ccd.agent.agentevent.AgentEvent_Inside_Call_Fail": "El agente no puede iniciar una llamada interna.",
  "ccd.agent.flash.calling": "Llamar",
  "ccd.agent.message.agentNoConfSkill": "El agente no está configurado con una cola de habilidades.",
  "ccd.agent.flash.message": "Mensajes",
  "ccd.agent.webrtc.label.busy.confirm": "Confirmar",
  "ccd.agent.agentevent.AgentState_Work": "El agente entra en el estado de cierre.",
  "ccd.agent.button.signOut": "Cerrar sesión",
  "ccd.agent.button.hangUp": "Cuelga",
  "ccd.agent.button.transfer": "Transferir",
  "ccd.agent.openeye.videowindowparam.fail": "Error al inicializar los parámetros de vídeo. Inicie sesión como agente de nuevo o inicie sesión manualmente en el OpenEye.",
  "ccd.agent.webrtc.label.hidelocalvideo": "Ocultar vídeo local",
  "ccd.agent.agentevent.feature.callin": "Llamada entrante común",
  "ccd.agent.agentevent.callType": "Tipo de llamada:",
  "ccd.agent.agentevent.mediaAblity.voiceandvideo": "audio y vídeo",
  "ccd.agent.agentevent.AgentState_CancelRest_Success": "El agente canceló el resto con éxito.",
  "ccd.agent.agentevent.AgentEvent_Consult_Fail": "Error de consultoría de agente",
  "ccd.agent.button.endMute": "Desactivar el silencio",
  "ccd.agent.label.offline": "Desconectado",
  "ccd.agent.agentevent.mediaAblity.text": "texto",
  "ccd.agent.button.mute": "Silenciar",
  "ccd.agent.button.sayBusy": "Ocupado",
  "ccd.agent.webrtc.label.logoutregistered": "Ya sea para cerrar sesión.",
  "ccd.agent.label.busy": "Ocupado/a",
  "ccd.agent.agentevent.AgentMediaEvent_PausePlayDone": "La reproducción de grabación se pausa con éxito.",
  "ccd.agent.agentevent.AgentMediaEvent_Play": "Se inicia la reproducción de grabación.",
  "ccd.agent.agentevent.AgentOther_PhoneUnknow": "Se desconoce el estado del teléfono del agente.",
  "ccd.agent.webrtc.label.button.no": "No",
  "ccd.agent.webrtc.label.connect": "Inscripción",
  "ccd.agent.openeye.switchshare.fail": "Error al habilitar la función de uso compartido de escritorio.",
  "ccd.agent.agentevent.AgentState_CancelNotReady_Success": "El agente canceló el ocupado correctamente.",
  "ccd.agent.openeye.poolsipconfig.fail": "Error al configurar la POOL. Inicie sesión como agente de nuevo o inicie sesión manualmente en el OpenEye.",
  "ccd.agent.openeye.videolayoutmode.success": "Se ha tenido éxito en la configuración del modo de disposición de imagen de la ventana de vídeo.",
  "ccd.agent.button.answer": "Respuestas",
  "ccd.agent.agentevent.warning": "Advertencia",
  "ccd.agent.agentevent.AgentOther_PhoneOffhook": "El agente se descolgó",
  "ccd.agent.openeye.deregister.fail": "Error al desactivar el número de softphone. Cierre la sesión en el OpenEye manualmente.",
  "ccd.agent.frontpage.callreason": "Motivo de la llamada",
  "ccd.agent.agentevent.AgentMediaEvent_ResumePlayDone": "La reproducción de grabación se reanuda correctamente.",
  "ccd.agent.button.screenShare": "Escritorio compartido",
  "ccd.agent.agentevent.AgentEvent_No_Answer.number": "Número de fiesta colgante:",
  "ccd.agent.agentevent.AgentState_Ready": "El agente se ha puesto inactivo con éxito.",
  "ccd.agent.button.notscreenShare": "Cancelar Compartir Escritorio",
  "ccd.agent.agentevent.AgentEvent_Preview": "El agente recibe la solicitud de vista previa correctamente.",
  "ccd.agent.openeye.getapplist.fail": "Error al obtener la lista de aplicaciones abiertas.",
  "ccd.agent.login.multimedia.failinfo": ". Como resultado, la llamada de voz y video, y las funciones de ayuda interna no están disponibles.",
  "ccd.agent.label.adjust": "Recapitulación",
  "ccd.agent.agentevent.AgentState_Busy": "El agente está trabajando",
  "ccd.agent.tips.confFail": "Error en la configuración.",
  "ccd.agent.webrtc.label.accept": "Respuestas",
  "ccd.agent.tips.error": "Error",
  "ccd.agent.agentevent.AgentState_SetWork_Success": "El agente entra en el estado de trabajo.",
  "ccd.agent.label.reply": "Reseñas",
  "ccd.agent.webrtc.label.videopop": "Ventana de vídeo",
  "ccd.agent.button.offlineSignIn": "En móvil",
  "ccd.agent.button.queryStatus": "Estado de la consulta",
  "ccd.agent.resetphonepass.inputagain": "Confirmar contraseña",
  "ccd.agent.openeye.poolsipconfig.success": "La POOL se ha configurado correctamente.",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.success": "Conmutación de audio/vídeo con éxito.",
  "ccd.agent.openeye.deamonready": "El OpenEye se inicia.",
  "ccd.agent.openeye.switchshare.success": "La función de uso compartido de escritorio se habilita correctamente.",
  "ccd.agent.message.phone": "Número de llamada",
  "ccd.agent.button.cancelRest": "Cancelar Resto",
  "ccd.agent.agentevent.mediaAblity": "Tipo de medios:",
  "ccd.agent.agentevent.AgentOther_PhoneRelease": "El agente colgó",
  "ccd.agent.openeye.sipconfig.fail": "Error al configurar el SIP. Inicie sesión como agente de nuevo o inicie sesión manualmente en el OpenEye.",
  "ccd.agent.openeye.deamonready.failinfo": "Error al iniciar el OpenEye.",
  "ccd.agent.agentevent.AgentEvent_Hold.desc": "El agente ya está en espera",
  "ccd.agent.agentevent.AgentMediaEvent_StopPlayDone": "La reproducción de grabación se detuvo.",
  "ccd.agent.agentevent.feature.caller": "Número de llamada:",
  "ccd.agent.button.restSkillQueue": "Reset Habilidades",
  "ccd.agent.openeye.register": "El número de softphone se ha registrado correctamente.",
  "ccd.agent.agentevent.type.main": "llamada principal",
  "ccd.agent.tips.info": "Sugerencias",
  "ccd.agent.agentevent.failed": "Error",
  "ccd.agent.button.rest": "Descanso",
  "ccd.agent.button.signIn": "Iniciar sesión",
  "ccd.agent.openeye.share": "Por favor seleccione...",
  "ccd.agent.agentevent.feature.called": "Número de llamada:",
  "ccd.agent.agentevent.type.assistant": "llamada de asistencia",
  "ccd.agent.openeye.already.register": "El número de softphone ha sido registrado.",
  "ccd.agent.agentevent.AgentEvent_Release_Preview": "La llamada de vista previa se libera correctamente.",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.controlid": "Vista previa del ID del bloque de control de llamadas salientes:",
  "ccd.agent.webrtc.label.localvideo": "Vídeo local",
  "ccd.agent.message.AgentIsSupend": "El agente actual se congela.",
  "ccd.agent.agentevent.AgentEvent_Customer_Release": "El cliente sale de la llamada.",
  "ccd.agent.query.sip.fail": "Error al consultar datos SIP. Inicie sesión como agente de nuevo o inicie sesión manualmente en el OpenEye.",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.dialeddigits": "Vista previa del número llamado saliente:",
  "ccd.agent.webrtc.label.offline": "No registrado",
  "ccd.agent.agentevent.AgentEvent_Ringing": "La llamada entrante del agente",
  "ccd.agent.button.callOut": "Llamar hacia fuera",
  "ccd.agent.webrtc.label.connetfailed": "Error",
  "ccd.agent.webrtc.label.buildfailed": "Error al configurar la conexión.",
  "ccd.agent.webrtc.label.unsupportbrower": "El navegador no admite esta función.",
  "ccd.agent.webrtc.label.sotpsendvideo": "Dejar de enviar vídeo",
  "ccd.agent.login.fail": "Error en el registro.",
  "ccd.agent.tips.welcome": "Bienvenido de nuevo. ¡Que tengas un buen día!",
  "ccd.agent.agentevent.mediaAblity.voice": "audio",
  "ccd.agent.agentevent.callId": "ID de llamada:",
  "ccd.agent.openeye.stopshare.fail": "Error al dejar de compartir.",
  "ccd.agent.agentevent.AgentOther_PhoneAlerting": "El agente está sonando",
  "ccd.agent.webrtc.label.missmic": "El micrófono no está disponible.",
  "ccd.agent.openeye.videodisplaymode.success": "Se ha logrado establecer el modo de recorte de la ventana de vídeo.",
  "ccd.agent.webrtc.label.misspeaker": "El altavoz no está disponible.",
  "ccd.agent.agentevent.otherPhone": "Número de teléfono/ID de trabajo de la parte de llamada:",
  "ccd.agent.openeye.videolayoutmode.fail": "Error al establecer el modo de disposición de imágenes de la ventana de vídeo.",
  "ccd.agent.openeye.videodisplaymode.fail": "Error al establecer el modo de recorte de la ventana de vídeo.",
  "ccd.agent.agentevent.mediaAblity.video": "Videos",
  "ccd.agent.agentevent.AgentEvent_Conference": "La llamada de tres partes tiene éxito.",
  "ccd.agent.webrtc.label.sippwd.placeholder": "Ingrese la contraseña del softphone.",
  "ccd.agent.webrtc.label.busy.tip": "Chamada",
  "ccd.agent.agentevent.AgentState_SetRest_Success": "El agente descansó con éxito.",
  "ccd.agent.button.mediaswitch": "Interruptor de audio/vídeo",
  "ccd.agent.webrtc.label.medium": "medio",
  "ccd.agent.webrtc.label.videosize": "Tamaño del video",
  "ccd.agent.callrelease.end": "Llamada finalizada",
  "ccd.agent.agentevent.AgentState_Force_SetNotReady": "El agente se puso ocupado con éxito.",
  "ccd.ccaction.title.shareapp": "Seleccionar programa compartido",
  "ccd.agent.webrtc.label.remotevideo": "Vídeo remoto",
  "ccd.agent.agentevent.feature.callout": "Llamada saliente común",
  "ccd.agent.button.close": "Cancelar",
  "ccd.agent.webrtc.label.passwordvalidator": "La longitud del carácter especificado excede el rango de valores. La longitud debe ser menor o igual a 50 caracteres.",
  "ccd.agent.openeye.autoanswer.fail": "Error al responder la llamada automáticamente.",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.failed": "Error de conmutación de audio/vídeo.",
  "ccd.agent.openeye.register.fail": "El número de softphone ya está registrado, u otras razones.",
  "ccd.agent.agentevent.AgentOther_All_Agent_Busy": "Todos los agentes están ocupados.",
  "ccd.agent.agentevent.AgentOther_Restskill_Queue": "Operação de fila de habilidades bem-sucedida.",
  "ccd.agent.agentevent.AgentEvent_ReturnFromIvr": "El agente se devuelve correctamente desde el IVR.",
  "ccd.agent.webrtc.label.reject": "Rechazar",
  "ccd.agent.webrtc.label.button.yes": "Sí",
  "ccd.agent.button.sayFree": "Establecer inactivo",
  "ccd.agent.login.failinfo": ". Como resultado, la función de ayuda interna no está disponible.",
  "ccd.agent.webrtc.label.busy.info": "Sugerencias",
  "ccd.agent.webrtc.label.local.name": "Agente",
  "ccd.agent.webrtc.label.small": "pequeño",
  "ccd.agent.message.AgentIsEmpty": "La cuenta actual no está vinculada a un agente.",
  "ccd.agent.button.hold": "En espera",
  "ccd.agent.button.offlineSignOut": "Salir del móvil",
  "ccd.agent.agentevent.AgentEvent_No_Answer": "El agente no responde a la llamada durante mucho tiempo.",
  "ccd.agent.agentevent.AgentMediaEvent_JumpPlayDone": "La grabación se avanza rápidamente o se rebobina con éxito.",
  "ccd.agent.agentevent.AgentEvent_Hold": "La llamada se mantiene correctamente.",
  "ccd.agent.webrtc.label.talk": "Llamar",
  "ccd.agent.label.rest": "Descanso",
  "ccd.agent.openeye.autoanswer": "La llamada se responde automáticamente.",
  "ccd.agent.agentevent.AgentOther_ShutdownService": "El agente cerró la sesión correctamente.",
  "ccd.agent.agentevent.AgentState_CancelWork_Success": "El agente salió del estado de trabajo correctamente.",
  "ccd.agent.openeye.setshareapp.fail": "Error al configurar el uso compartido de pantalla.",
  "ccd.agent.openeye.deamonready.fail": "Error al iniciar el OpenEye Inicie sesión en el OpenEye manualmente. no se puede utilizar la función de respuesta automática.",
  "ccd.agent.button.twostage": "Dialpad",
  "ccd.agent.agentevent.AgentEvent_Talking": "El agente entró en el estado Talking.",
  "ccd.agent.openeye.startshare.fail": "Error al iniciar el uso compartido.",
  "ccd.agent.agentevent.AgentEvent_Connect_Fail": "Error de conexión",
  "ccd.agent.openeye.videowindowparam": "Los parámetros de vídeo se inicializan correctamente.",
  "ccd.agent.label.talk": "Hablar",
  "ccd.agent.button.getHold": "Cancelar espera",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut": "La vista previa de la llamada saliente se realizó correctamente.",
  "ccd.agent.webrtc.label.changeVoice": "Transferencia a voz",
  "ccd.agent.agentevent.AgentEvent_Conference.partner": "Número de llamada:",
  "ccd.agent.agentevent.success": "Éxito",
  "ccd.agent.resetphonepass.input": "Contraseña",
  "ccd.agent.tips.confSuccess": "La configuración se realiza correctamente.",
  "ccd.agent.reset.success": "El descanso se ha programado correctamente",
  "ccd.agent.openeye.initsuccess": "El OpenEye se inicializa correctamente.",
  "ccd.agent.pagination.total": "En total:",
  "ccd.agent.title.consult": "Contáctenos",
  "ccd.message.title.audioIncomingCallNotify": "Notificación de llamadas entrantes de audio y vídeo",
  "ccd.message.title.mediaIncomingCallNotify": "Recordatorio multimedia de llamadas entrantes",
  "ccd.message.title.mediaNewMsgNotify": "Notificación de nuevo mensaje multimedia",
  "ccd.agent.warning.sharescreen": "Preste atención a la privacidad personal al compartir el escritorio",
  "ccd.agentMonitor.label.switchlistenorinsert": "En el estado de preinspección, un agente al que se le susurra no puede hacer clic en el botón Escuchar o Insertar.",
  "ccd.agent.agentevent.quality.calloutconnectfail": "No se puede realizar una llamada saliente porque el control de calidad está en curso.",
  "ccd.agent.agentevent.calloutconnectfail": "=El suscriptor está ocupado o el número no existe.",
  "ccd.agent.tips.maxCountQuery.info": "El volumen de datos actual es demasiado grande. El sistema muestra sólo los últimos {0} registros de datos",
  "ccd.agent.warning.notification.cancel": "Cancelar",
  "ccd.agent.warning.notification.setting": "Configuraciones",
  "ccd.agent.warning.notification.allowed": "Para garantizar la notificación de mensajes de sesión, el sitio web debe mostrar notificaciones.",
  "ccd.agent.msg.notification.allowed": "Aplicar para permitir que el navegador muestre notificaciones!",
  "ccd.agent.msg.notification.permission.denied": "El usuario rechaza la autorización y no puede mostrar la notificación. Modifique la configuración del navegador",
  "ccd.agent.msg.queryMsg.specialListQuery": "Notificación de lista especial",
  "ccd.agent.message.AgentIsProhibited": "El espacio de alquiler está prohibido.",
  "ccd.agent.message.repeatPhonenum": "El número de teléfono utilizado por el agente para iniciar sesión ha sido utilizado por otro agente para iniciar sesión. Verifique la configuración.",
  "ccd.agent.message.destinationAgentFailed": "Error al ocupar el agente de destino.",
  "ccd.agent.message.noAgent": "Ningún agente de servicio.",
  "ccd.agent.warning.waserror": "Error de recurso WAS inalcanzable o error interno.",
  "ccd.quality.message.cancelwhisper": "Deja de susurrar",
  "ccd.agent.videocall.stateError": "El estado de la llamada es incorrecto cuando se ajusta el modo de visualización para una videollamada de tres partes.",
  "ccd.cccbar.label.backspace": "Retroceso",
  "ccd.agent.videomode.triangular": "Triangular",
  "ccd.ccaction.message.holdError": "La llamada actual no se puede mantener.",
  "ccd.agent.status.hold": "En espera",
  "ccd.agent.message.selectTeams": "Selecciona una cuenta de equipo.",
  "ccd.agent.message.restInvalid": "El parámetro o la dirección transferida a través de la URL no cumple con la definición.",
  "ccd.transfer.message.inputIvrName": "Introduzca un ivr para la transferencia.",
  "ccd.agent.videomode.maximizeThreeparty": "Maximizar el tripartito",
  "ccd.agent.void.call": "Llamada saliente de voz",
  "ccd.ccaction.message.retCode": "Error Cancelar trabajo cuando se establece ocupado. Código de error:",
  "ccd.agent.videocall.paramError": "Error de parámetro al ajustar el modo de visualización de una videollamada de tres partes.",
  "ccd.transfer.label.queueSize": "Cantidad de llamada de cola actual",
  "ccd.transfer.label.queueSize.ccbar": "Espera en línea",
  "ccd.ccaction.message.isForceBusy": "Se ha mostrado que el agente está ocupado. No se muestre ocupado repetidamente.",
  "ccd.transfer.message.NoAgentInSkill": "No existe ningún agente en línea en la cola de habilidades actual.",
  "ccd.cccbar.message.syserrormessage": "Error del sistema.",
  "ccd.agent.status.help": "Contáctenos",
  "ccd.ccaction.message.logoutfailed": "Error de cierre de sesión. Por favor, compruebe el estado del agente.",
  "ccm.agent.message.selectIvr": "Seleccione un flujo de IVR.",
  "ccd.ccaction.message.search.restCauseDesc": "Ingrese la descripción del motivo de descanso.",
  "ccd.quality.message.insert": "Inserción",
  "ccd.outgoing.out.workbench": "Área de trabajo saliente",
  "ccd.transfer.label.skill": "Cola de habilidades",
  "ccd.transfer.button.refresh": "Refrescar",
  "ccd.agent.status.innerThree": "Tres partidas",
  "ccd.agent.message.threePartyNoCall": "No hay llamada para que inicies una llamada de tres partes.",
  "ccd.innerhelp.label.innerHelpToSkill": "Solicitar consulta desde la cola de habilidades",
  "ccd.record.message.stopPlay": "Se produce un error de estado cuando se detiene la reproducción.",
  "ccd.transfer.label.loggedOnAgents": "Cantidad de agente en línea",
  "ccd.transfer.label.loggedOnAgents.ccbar": "Agente disponible",
  "ccd.transfer.label.ivr": "IVR",
  "ccd.ccaction.message.abilityError": "No puede silenciar al usuario en la función multimedia actual.",
  "ccd.agent.message.agentNotLogin": "El agente no inicia sesión.",
  "ccd.agent.videocall.adjustvideo": "Ajuste del tamaño del video",
  "ccd.agent.status.askAnswer": "Esperar respuesta",
  "ccd.transfer.label.agent": "Agente",
  "ccd.cccbar.label.voice": "Audiencias",
  "ccd.agent.message.loginInvalidPara": "Error en el inicio de sesión. El parámetro de inicio de sesión está vacío o no es válido.",
  "ccd.innerhelp.label.innerHelpToAgent": "Solicitar Consulta del Agente",
  "ccd.quality.message.whisper": "Susurro",
  "ccd.agent.mediaswitch.unsupportedagenttype": "El agente actual no admite videollamadas.",
  "ccd.ccaction.message.unHold": "No hay llamada a retener en el estado actual.",
  "ccd.agent.title.warning": "Alarma",
  "ccd.cccbar.label.video": "Vídeos",
  "ccd.ccaction.message.errorRetCode": "Error! Código de error:",
  "ccd.agent.message.mediaSwitchNoCall": "No se realiza ninguna llamada durante la conmutación de audio/vídeo.",
  "ccd.agent.mediaswitch.switchaudiofailed": "Ya en una llamada de audio.",
  "ccd.ccaction.message.recording": "La voz solo se puede reproducir cuando el agente está en estado inactivo. Cambie el estado del agente.",
  "ccd.record.message.resumePlay": "Se produce un error de estado cuando continúa la reproducción.",
  "ccd.transfer.label.successTransfer": "Transferencia de éxito",
  "ccd.ccaction.message.confJoinFail": "El agente no inicia sesión y la llamada de tres partes falla.",
  "ccd.agent.message.noHoldCall": "La llamada no se realiza.",
  "ccd.agent.message.releaseNumberNoCall": "No hay llamada para liberar un número de teléfono especificado.",
  "ccd.cccbar.label.total": "En total:",
  "ccd.quality.message.forceexit": "Forzar la salida",
  "ccd.agent.videocall.noCall": "No se produce ningún error de llamada cuando se ajusta el modo de visualización de una videollamada de tres partes.",
  "ccd.innerhelp.label.twoPartyHelp": "Consulta de dos partes",
  "ccd.quality.message.interceptErr": "El agente a comprobar no está en una llamada y no puede ser interceptado.",
  "ccd.cccbar.label.callcenter": "Centro de llamadas",
  "ccd.quality.message.noAgentInfo": "No se encuentra información del agente durante el control de calidad.",
  "ccd.cccbar.message.pleaseAnswer": "Contesta la llamada.",
  "ccd.transfer.label.hangUpTransfer": "Transferencia colgante",
  "ccd.agent.message.holdCallStatusErr": "Se produce un error de estado de llamada cuando se mantiene la llamada.",
  "ccd.agent.message.mediaSwitchFailed": "Error de conmutación de audio/vídeo.",
  "ccd.record.message.jumpBack": "Se produce un error de estado durante el rebobinado.",
  "ccd.agent.message.endMuteNoCall": "No existe ninguna llamada cuando cancelas el silenciamiento.",
  "ccd.quality.message.forcebusy": "Fuerza Ocupado",
  "ccd.transfer.label.externalNumber": "Número externo",
  "ccd.agent.message.beginMuteNoCall": "No hay necesidad de silenciar.",
  "ccd.agent.chat.channle.choose": "Selección de canales",
  "ccd.quality.message.noProvider": "El error NOPROVIDER se produce durante la comprobación de calidad.",
  "ccd.transfer.label.statusUnknow": "Desconocido",
  "ccd.ccaction.message.repeateRest": "El agente es el descanso. No fuerces a mostrar ocupado.",
  "ccd.agent.message.logOutStatusErr": "Error de cierre de sesión. Usted puede tener un negocio pendiente que manejar.",
  "ccd.agent.videomode.maximizeUser": "Maximizar usuario",
  "ccd.agent.status.mute": "Silenciar",
  "ccd.agent.videomode.maximizeAgent": "Maximizar el agente",
  "ccd.agent.tips.restTooLong": "El resto se agota.",
  "ccd.agent.status.occupy": "Ocupado",
  "ccd.record.message.startRecord": "Se produce un error de estado al iniciar la grabación.",
  "ccd.agent.message.monitorSelf": "El agente y el inspector inspeccionados no pueden ser los mismos.",
  "ccd.login.message.logoutReason.sessionInvalid": "La sesión no es válida. Inicie sesión de nuevo por favor.",
  "ccd.transfer.message.microsoftTeamsLogin": "Iniciar sesión",
  "ccd.transfer.message.inputTeamsUser": "Ingrese la cuenta del equipo.",
  "ccd.transfer.label.skillName": "Cola de habilidades",
  "ccd.ccaction.message.retMessEnd": ".",
  "ccd.record.message.pauseRecord": "Se produce un error de estado al grabar pausas.",
  "ccd.agent.message.InvalidNumber": "Número no válido.",
  "ccd.agent.videocall.typeError": "El tipo de llamada es incorrecto cuando se ajusta el modo de visualización para una videollamada de tres partes.",
  "ccd.ccaction.message.recordFail": "El agente no inicia sesión y la reproducción de voz falla.",
  "ccd.transfer.label.transferToSkill": "Transferir a la cola de habilidades",
  "ccd.ccaction.message.confJoinErr": "No se realiza ninguna llamada. No se permite una llamada de tres partes.",
  "ccd.agent.message.insultNoCall": "No hay llamada para consultar.",
  "ccd.callmessage.workbench": "Escritorio",
  "ccd.agent.message.beginMuteStateErr": "Se produce un error de estado de llamada cuando se silencia.",
  "ccd.agent.status.threeParty": "Tres Partes",
  "ccd.agent.message.loginInvalidParam": "El número de teléfono no es válido o ha sido iniciado por otro agente.",
  "ccd.agent.message.checkAgentStatus": "Realice esta operación cuando el inspector esté inactivo.",
  "ccd.agent.message.loginInvalidPhone": "Número de teléfono no válido.",
  "ccd.quality.message.intercept": "Interceptar",
  "ccd.agent.mediaswitch.switchvideofailed": "Ya en una videollamada.",
  "ccd.agent.message.mediaSwitchSuccess": "Conmutación de audio/vídeo Éxito.",
  "ccd.quality.message.searchWorkno": "Ingrese el ID de agente que desea buscar.",
  "ccd.cccbar.label.yourself": "Yo mismo",
  "ccd.transfer.label.transferToAgent": "Traslado al Agente",
  "ccd.cccbar.label.cccbar": "Barra de conexión",
  "ccd.contact.message.timeerror": "La hora de inicio debe ser anterior a la hora de finalización.",
  "ccd.quality.message.qualityTitle": "Comprobación de calidad",
  "ccd.cccbar.label.empty": "Borrar todo",
  "ccd.contact.message.timerangeerror": "El intervalo de tiempo no puede exceder los {0} días.",
  "ccd.cccbar.label.send": "Enviar",
  "ccd.ccaction.message.error": "Error",
  "ccd.quality.message.stopListen": "Dejar de escuchar",
  "ccd.quality.message.switch": "Interruptor",
  "ccd.agent.status.innerCall": "Llamada interna",
  "ccd.quality.message.statusErr": "El estado es anormal durante el control de calidad.",
  "ccd.callout.message.numberFormat": "Un número de teléfono puede contener solo los dígitos, el signo numérico (#), y el asterisco (*), y contiene un máximo de 24 caracteres.",
  "ccd.ccaction.message.inputWorkNo": "El ID del agente está vacío. Ingrese el ID del agente.",
  "ccd.cccbar.message.calltip": "Hola, una nueva llamada está llegando!",
  "ccd.agent.message.getHoldCallStatusErr": "Se produce un error de estado de llamada cuando se detiene la llamada.",
  "ccd.ccaction.message.statusError": "No hay llamada a silenciar en el estado actual.",
  "ccd.innerhelp.label.helpType": "Tipo de consulta",
  "ccd.cccbar.label.nocall": "No hay llamada.",
  "ccd.callmessage.chatOnlineWorkbench": "Chat en línea",
  "ccd.cccbar.label.inputNumber": "Introduzca un número.",
  "ccd.record.message.startPlay": "Se produce un error de estado cuando se inicia la reproducción.",
  "ccd.innerhelp.message.inputExNumhelp": "Introduzca un número para Consultar",
  "ccd.agent.message.connectHoldNoCall": "No hay llamada para esperar.",
  "ccd.agent.message.restNoRight": "El agente no tiene permiso para invocar la interfaz. El agente pudo haber sido obligado a cerrar la sesión.",
  "ccd.agent.label.recordPlay": "Reproducción de voz",
  "ccd.ccaction.message.registerPhoneFailed": "El registro WebRTC falla. Actualmente, las funciones de ayuda interna no están disponibles.",
  "ccd.agentstate.notTalking": "El agente seleccionado no está en el estado de conversación.",
  "ccd.agent.chat.mutimedia.call": "Llamada saliente multimedia",
  "ccd.cccbar.label.cancel": "Cancelar",
  "ccd.quality.message.resourceUnable": "Se produce una excepción durante la inspección. Verifique los registros relevantes.",
  "ccd.agent.message.setStatErr": "Error al establecer el estado del agente.",
  "ccd.quality.message.inspectInspectorErr": "El agente inspeccionado no puede ser un inspector.",
  "ccd.transfer.button.sure": "OK",
  "ccd.quality.message.listen": "Escuchar",
  "ccd.cccbar.label.callout": "Llamar",
  "ccd.agent.message.endMuteStateErr": "Se produce un error de estado de llamada al cancelar el silenciamiento.",
  "ccd.transfer.label.releaseTransfer": "Transferencia de liberación",
  "ccd.contact.message.timeisempty": "El intervalo de tiempo no puede estar vacío.",
  "ccd.transfer.label.transferType": "Tipo de transferencia",
  "ccd.transfer.label.transferTypeColon": "Tipo de transferencia:",
  "ccd.transfer.label.transferTypeColon.ccbar": "Tipo:",
  "ccd.agent.status.talk": "Hablar",
  "ccd.agent.message.eventMethodErr": "El método para obtener el evento del agente es incorrecto.",
  "ccd.transfer.button.cancel": "Cancelar",
  "ccd.ccaction.message.retMessage": ". Mensaje de error:",
  "ccd.agent.mediaswitch.video": "Videollamada",
  "ccd.agent.chat.start": "Iniciar una sesión",
  "ccd.agent.message.AgentAlreadyLogout": "El agente se ha retirado.",
  "ccd.ccaction.message.repeateBusy": "El agente está ocupado ahora. No establezca ocupado repetidamente.",
  "ccd.agent.message.threePartyNoHoldCall": "No se realiza ninguna llamada para iniciar una llamada de tres partes.",
  "ccd.agent.message.invalidWasURL": "La ruta de enlace WAS configurada en el servidor es incorrecta.",
  "ccd.record.message.resumeRecord": "Se produce un error de estado al continuar la grabación.",
  "ccd.callinner.label.callinner": "Llamada interna",
  "ccd.agent.message.getHoldNoHoldCall": "No hay llamada a no sostener.",
  "ccd.ccaction.message.inputIvrName": "El nombre del flujo IVR está vacío. Ingrese el nombre del flujo IVR.",
  "ccd.agent.message.userOrPasswdInvalid": "Error en el inicio de sesión. La cuenta o la contraseña son incorrectas.",
  "ccd.transfer.label.talkingTransfer": "Transferencia de conversación",
  "ccd.agent.message.misconnection": "Error al invocar la interfaz de persistencia de sesión.",
  "ccd.transfer.message.inputExNumber": "El número a transferir",
  "ccd.agent.message.accoutnLocked": "La cuenta está bloqueada. Inicie sesión más tarde.",
  "ccd.quality.message.back": "Atrás",
  "ccd.transfer.label.callTransfer": "Llamada de transferencia",
  "ccd.agent.message.answerNoCall": "No hay llamada para responder.",
  "ccd.record.message.jumpForw": "Se produce un error de estado durante el reenvío.",
  "ccd.agent.message.releaseNumberNoNumber": "No hay llamada del número de teléfono para liberar.",
  "ccd.agent.message.AgentInnerError": "Error en la operación. La posible causa es que se produce un error interno, no hay recursos disponibles o el agente no está inactivo.",
  "ccd.agent.message.wait": "La llamada está siendo procesada. Por favor, inténtelo de nuevo más tarde.",
  "ccd.agent.message.reset": "Demasiados errores en el procesamiento de llamadas, al intentar sincronizar el estado del agente",
  "ccd.agent.previewcall.invalParam": "Los parámetros de llamada saliente de vista previa son incorrectos.",
  "ccd.agent.message.default": "Error interno. Compruebe el registro.",
  "ccd.agent.status.innerTwo": "Consulta",
  "ccd.ccaction.message.rest": "Descanso",
  "ccd.ccaction.message.seconds": "segundos",
  "ccd.ccaction.message.minutes": "minutos",
  "ccd.ccaction.message.hour": "Horas",
  "ccd.agent.message.callOutPhoneErr": "El número de llamada saliente no es válido.",
  "ccd.agent.message.holdNoCall": "No hay llamada para esperar.",
  "ccd.innerhelp.label.threePartyHelp": "Consultar a tres partes",
  "ccd.record.message.pausePlay": "Se produce un error de estado cuando la reproducción se detiene.",
  "ccd.agent.callout.notrest": "Cancele el descanso y, a continuación, realice una llamada saliente.",
  "ccd.agent.message.transferNoCall": "No hay llamada para transferir.",
  "ccd.ccaction.message.resignin": "La contraseña del asiento no es válida, ¡regístrese de nuevo!",
  "ccd.callmessage.whiteNumberExist": "El número no existe en la lista de confianza.",
  "ccd.agent.mediaswitch.audio": "Llamada de audio",
  "ccd.transfer.message.refreshTab": "No actualice la página repetidamente.",
  "ccd.quality.message.stopInsert": "Detener inserción",
  "ccd.ccaction.message.loginagent": "Inicie sesión en el agente.",
  "ccd.agent.videocall.displayError": "Se produce una excepción cuando se ajusta el modo de presentación de una videollamada de tres partes.",
  "ccd.transfer.label.transferToExNumber": "Transferencia a Número Externo",
  "ccd.agent.status.callout": "Llamadas salientes",
  "ccd.quality.message.forcerest": "Fuerza en vacío",
  "ccd.innerhelp.label.help": "Contáctenos",
  "ccd.transfer.message.NoIvrOnline": "No hay flujo IVR disponible.",
  "ccd.quality.message.noAuth": "No se produce ninguna excepción de permiso durante la comprobación de calidad.",
  "ccd.agent.message.releaseNoCall": "No hay llamada para colgar.",
  "ccd.skill.selectskill": "Selecciona al menos una cola de habilidades.",
  "ccd.agent.message.hangupnocall": "La llamada no se puede colgar. Si la llamada está en espera, cancele primero la espera.",
  "ccd.transfer.label.threeParthTransfer": "Transferencia a tres partes",
  "ccd.agent.message.needLogin": "Inicie sesión como agente e inténtelo de nuevo.",
  "ccd.ccaction.message.registerPhone": "Registre el softphone.",
  "ccd.agent.message.logOutInvalidParam": "Error en el inicio de sesión. El parámetro no es válido.",
  "ccd.agent.message.qc.restskill": "A fila de habilidades não pode ser reposta se um inspetor não atender uma chamada recebida.",
  "ccd.quality.message.qualityAgentTitle": "Monitor de agente",
  "ccd.agentMonitor.forceRelease.noAudio": "Ninguna llamada de audio o video puede ser liberada.",
  "ccd.agentMonitor.intercept.noAudio": "No se puede interceptar ninguna llamada de voz o videollamada.",
  "ccd.transfer.label.transferNumber": "Proceso de autoservicio",
  "ccd.agent.forcesignout.confirm": "Cerrar sesión afectará el servicio de conexión que está procesando el agente. ¿Estás seguro de que quieres cerrar sesión a la fuerza?",
  "ccd.ccagent.contract.preview": "Vista previa",
  "ccd.label.search": "Buscar",
  "ccd.label.org.all": "Todas las organizaciones accesibles",
  "ccd.title.call.three": "Llamadas a tres vías",
  "ccd.media.tips.new.online.customer": "Tienes un nuevo cliente en línea",
  'ccd.agent.message.calloutagent.restskill': 'El agente de llamada no contesta las llamadas entrantes y no puede restablecer las colas de habilidades.',
  "ccd.agent.message.videoLimit": "Los recursos del agente de vídeo son insuficientes y el agente no puede iniciar sesión en el servidor de medios de vídeo.",
  "ccd.agent.message.withoutskill": "Falta la cola de habilidades.",
  "ccdesktop.login.wertc.failed": "Falló el registro del teléfono blando webrtc",
  "ccd.agent.label.skillGroupOrSkill": "Grupo de habilidades/Cola de habilidades",
  "ccd.agentmonitor.span.insert": "Inserción",
  "ccd.agentmonitor.span.listening": "Ouvindo",
  "ccd.agentmonitor.span.whisper": "Susurrando",
  "ccd.transfer.placeholder.agentName": "Introduzca el nombre del agente",
  "ccd.agent.videocall.displayWarn": "El tamaño de la ventana de vídeo solo se puede ajustar cuando las tres partes están reproduciendo vídeos.",
  "ccd.ccaction.message.nosupport": "Esta operación no se admite en la página Web. Usa Open Eye para silenciar.",
  "ccd.ccbar.before.signin.tips":"Antes de utilizar la función de conexión, haga clic en para iniciar sesión.",
  "ccd.ccbar.calling.number":"Número de llamada",
  "ccd.ccbar.mediaSwitch.failed":"Error de conmutación de medios.",
  "ccd.ccbar.mediaSwitch.notsupport":"La conmutación de audio y vídeo no está permitida para llamadas internas en el modo de vídeo en la MCU.",
  "ccd.webrtc.message.bad":"Malicia",
  "ccd.webrtc.message.poor":"Más pobre",
  "ccd.webrtc.message.good":"Muy bueno",
  "ccd.webrtc.message.recive":"Recibir",
  "ccd.webrtc.message.Packetloss":"Pérdida de paquetes",
  "ccd.webrtc.message.delay":"Retraso",
  "ccd.webrtc.message.jitter":"Golpes",
  "ccd.webrtc.message.send":"Enviar",
  "ccd.webrtc.message.signal":"Señal",
  'ccd.webrtc.message.medium':'Bueno',
  'ccd.webrtc.login.success':'El inicio de sesión del softphone se ha realizado correctamente',
  'ccd.webrtc.login.fail':'Error de inicio de sesión en Softphone',
  'ccd.webrtc.login.fail.noPwd':'No hay ninguna contraseña inicial disponible. Restablezca la contraseña del softphone e inténtelo de nuevo.',
  'ccd.webrtc.login.fail.pop.tips':'Error al iniciar sesión automáticamente en el softphone. No se puede utilizar la función de voz. Iniciar sesión en el softphone de nuevo?',
  'ccd.webrtc.login.try':'Reinterpretar',
  'ccd.webrtc.login.ignore':'Ignorar',
  'ccd.webrtc.login.fail.top.tips':'El inicio de sesión del softphone es anormal y no se pueden usar funciones relacionadas, como la voz.',
  "ccd.ccbar.webrtc.network.signal.pool":"La señal de red actual es pobre, lo que puede afectar a la llamada.",
  "ccd.agentMonitor.label.selectAgentOne": "Finalice el agente que se está procesando y seleccione otra operación de agente.",
  "ccd.agentMonitor.label.switchwhisper": "No puede hacer clic en Susurrar para un agente en estado de preescucha o por inserción.",
  'ccd.agent.webrtc.register.tip':'Registrar softphone?',
  "ccd.consult.message.two.inputExNumber": "Por favor, introduzca el número para la consulta bipartita",
  "ccd.consult.message.three.inputExNumber": "Por favor ingrese el número para la consulta de tres partes",
  "ccd.ccbar.mediaSwitch.sfunotsupport":"La llamada actual no admite la conmutación de medios.",
  "ccd.agent.tips.welcome.agentlogout": "¡Bienvenido de nuevo!",
  "ccd.agent.message.continuously.online":"El servicio en línea se mantendrá después del check-in. Por favor, haga el check-in a tiempo después de que el trabajo se haya completado.",
  "ccd.agent.vrpserviceaddress.isempty" : "La dirección del servidor VRP está vacía. Póngase en contacto con el administrador del sistema para configurarlo.",
  "ccd.agent.initializeagentex.success" : "El agente se registra con el VRC correctamente. Su escritorio será monitoreado y su llamada será grabada en pantalla. Por favor, mantenga un buen estado de trabajo.",
  "ccd.agent.initializeagentex.passworderror" : "ID de agente o contraseña incorrectos. Error al registrarse con el VRC.",
  "ccd.agent.initializeagentex.alreadyregistered" : "El agente se ha registrado en el VRC.",
  "ccd.agent.vrpexe.closed" : "El software VRC se está apagando",
  "ccd.agent.startMonitorEx.uninitialize" : "La conexión vrc no es válida. Inicie sesión de nuevo en el agente actual.",
  "ccd.agent.startMonitorEx.qcnotexit" : "El objeto QC no existe. Compruebe si el asistente está conectado correctamente al VRC.",
  "ccd.agent.startMonitorEx.qctimelimitexceed" : "Error en la conexión del asistente al vrc. Inicie sesión de nuevo.",
  "ccd.agent.startMonitorEx.qcunsigned": "El agente no ha iniciado sesión. Comprueba si el agente ha iniciado sesión o si tiene que volver a iniciar sesión.",
  "ccd.agent.startMonitorEx.othererror": "Error al conectarse al servidor. Póngase en contacto con el personal de mantenimiento o inténtelo de nuevo más tarde.",
}