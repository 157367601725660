export default {
  "ccm.agent.callreason.need" : "يرجى تحديد سبب مكالمة واحد على الأقل.",
  "ccm.satisfactionconfig.message.enterflowname" : "الرجاء إدخال اسم العملية",
  "ccm.agent.label.waitTimeSuffix" : "، قائمة الانتظار مشغولة.",
  "ccm.note.config.policy.custom" : "التخصيص",
  "ccm.speciallist.msg.dateerror" : "تحتاج إلى إدخال الوقت الفعلي ووقت انتهاء الصلاحية.",
  "ccm.transtask.label.day" : "يوم",
  "ccm.agent.label.sipPwd" : "كلمة مرور Softphone",
  "ccm.agent.label.queueParameters" : "قوائم الانتظار",
  "ccm.agent.leavemessage.messageprocess" : "معالجة الرسالة",
  "ccm.satisfactionlevel.message.satistip" : "تأكد من أن مستويات الرضا المستخدمة في استبيان الرضا لكل قناة هي نفسها. مثل استبيان الرضا الصوتي\، واستطلاع رضا الرسائل القصيرة\، واستطلاع رضا الويب.",
  "ccm.satisfactionconfig.message.configstrategydata" : "تكوين بيانات النهج",
  "ccm.satisfactionlevel.title.templateupdate": "تحديث القالب",
  "ccm.satisfactionconfig.message.switchtip": "بعد تمكين هذه الوظيفة، يعرض النظام تلقائيًا صفحة استطلاع الرضا بعد اكتمال خدمة قناة الويب. بعد اكتمال خدمات قنوات الوسائط المتعددة الأخرى، يقوم النظام تلقائيًا بدفع محتوى قالب الرضا.",
  "ccm.satisfactionconfig.message.timelimittip":"إذا تجاوز وقت رد العميل وقت الرد الصالح، فلن يصبح الرد ساري المفعول",
  "ccm.satisfaction.leveltips":"ملاحظة: تستخدم جميع القنوات نفس اعدادات الرضا.",
  "ccm.satisfactiontemplate.tips": "ملاحظة: يستخدم النظام اللغة الافتراضية للمستأجر لإنشاء محتوى النموذج، ويستخدم نموذج رسالة استبيان الرضا لإرسال رسائل استبيان الرضا للعملاء في Instagram، Telegram، WhatsApp، LINE، Web Chat، Facebook، X (Twitter)، 5G RCS وقنوات SMS.",
  "ccm.speciallist.operlog.downloadData" : "تصدير بيانات القائمة الخاصة",
  "ccm.satisfactionconfig.message.channeltype" : "نوع القناة",
  "ccm.agent.message.weight.limit": "[100-1]",
  "ccm.agent.message.agentweight.limit": "[300-1]",
  "ccm.agent.button.coloumedit":"إعدادات الحقول",
  "ccm.satisfactionconfig.message.strategygroupname" : "اسم مجموعة النهج",
  "ccm.satisfactionconfig.title.strategygroup": "إنشاء مجموعة نهج",
  "ccm.agent.leavemessage.allStatus" : "كل حالة الرسالة",
  "ccmanagement.satisfaction.strategy.accepttime.accepttime" : "وقت المعالجة",
  "ccm.satisfactionconfig.msg.templateenpty": "حدد قالب SMS.",
  "ccm.satisfactionconfig.msg.timelimitenpty": "وقت الرد الصالح خالٍ.",
  "ccm.sysparam.config.mod" : "تحرير",
  "ccm.pageinterface.custTableConfig": "التكوين الأساسي للجدول",
  "ccm.contact.detail.sendmessage" : "إرسال",
  "ccm.urlconfig.modify.oauth.ak.tooshort" : "يجب أن يحتوي AK على 16 حرفًا على الأقل.",
  "ccm.custdata.operlog.addExportTaskSuccess" : "نجحت مهمة التصدير الجديدة",
  "ccm.speciallist.msg.applyTimeFrom" : "وقت بدء التطبيق",
  "ccm.transtask.label.contactrcd" : "تفريغ سجل الاتصال",
  "ccm.skillgroup.create.limit" : "يمكن إنشاء 2000 مجموعة مختصة كحد أقصى.",
  "iolp.workflow.node.label.associateJs" : "وظيفة JS المقترنة",
  "ccm.transtask.label.abandonedRingReport" : "تقرير موجز عن المكالمات المهجورة أثناء الرنين",
  "ccm.skillgroup.label.searchname" : "اسم المجموعة المختصة",
  "ccm.pageinterface.selectedAttributeNum" : "السمات المحددة للكائن",
  "ccm.speciallist.msg.reviewerror" : "فشل الموافقة",
  "ccm.custdata.label.dualCall.sucNum": "عدد السجلات الناجحة",
  "ccm.custdata.label.dualCall.failNum": "عدد السجلات الفاشلة",
  "ccm.callout.placeholder.caller" : "الرجاء إدخال الرقم المتصل للمكالمة الصادرة",
  "ccm.agent.label.description" : "الوصف",
  "ccm.agent.pageurlparam.urlParamName" : "معلمة URL المنبثقة للشاشة",
  "ccm.agent.label.keepVoiceCustom" : "تخصيص نغمة الاحتفاظ",
  "ccm.agent.calledroute.search.devicedesc" : "الرجاء إدخال وصف الجهاز.",
  "iolp.configur.servicelabel.delete.failed" : "فشل الحذف.",
  "ccm.recognition.label.cancelorderfail" : "فشل إلغاء الاشتراك.",
  "ccm.agent.ten.asrchannelnums" : "ASR الكمية",
  "ccm.ivr.button.traceInfo" : "انقر لعرض سجل التتبع الحالي.",
  "ccm.agent.contact.weChat" : "WeChat",
  "ccm.agent.contact.callershutdown" : "نهاية المكالمة\، إيقاف تشغيل المتصل",
  "ccm.agent.button.skill.synchronize" : "مزامنة",
  "ccm.agent.message.agentUpdateFail" : "فشل تحديث السجل.",
  "ccm.calledconfig.label.numbers" : "أرقام",
  "ccm.agent.button.refresh" : "تحديث",
  "ccm.agent.leavemessage.paramisempty" : "معلمة فارغة.",
  "ccm.agent.ten.ivrchannels" : "الصوت IVR قناة الكمية",
  "ccm.agent.message.addSkill": "إضافة قائمة انتظار مهارة",
  "ccm.satisfactiontemplate.message.confirm":"تأكيد ما إذا كان سيتم تحديث محتوى القالب استنادًا إلى مستوى الرضا.",
  "ccm.satisfactiontemplate.message.queryfail":"فشل الاستعلام عن قالب استطلاع الرضا.",
  "ccm.satisfactiontemplate.tipscontent":"يتم استخدام نموذج رسالة استبيان الرضا لإرسال رسائل استبيان الرضا إلى العملاء في قنوات Instagram و Telegram و WhatsApp و LINE و Web Chat و Facebook و X (Twitter) و 5G RCS و SMS.",
  "ccm.satisfaction.changetips":"ملاحظة: تم تغيير تكوين مستوى الرضا. قم بتعديل قالب استطلاع الرضا وفقًا لذلك.",
  "ccm.satisfactionconfig.message.question":"يتم استخدام نموذج رسالة استبيان الرضا لإرسال رسائل استبيان الرضا إلى العملاء في قنوات Instagram و Telegram و WhatsApp و LINE و Web Chat و Facebook و X (Twitter) و 5G RCS و SMS.",
  "ccm.satisfactionlevel.satislevel.config":"تكوين مستوى الرضا",
  "ccm.satisfactionlevel.satislevel.button":"الأزرار",
  "ccm.satisfactionlevel.satislevel.description":"الوصف",
  "ccm.satisfactionlevel.satislevel.operat":"العملية",
  "ccm.satisfactionlevel.satislevel.moveup":"لأعلى",
  "ccm.satisfactionlevel.satislevel.movedown":"لأسفل",
  "ccm.agent.label.promptParameters" : "نغمة موجه",
  "ccm.agent.contact.relCallBackFlag" : "تحرير علامة رد الاتصال",
  "ccm.satisfactionconfig.group.createsuccess": "تمت إضافة مجموعة النهج بنجاح",
  "ccm.satisfactionconfig.group.deletesuccess": "تم حذف مجموعة النهج بنجاح",
  "ccm.satisfactionconfig.strategy.createsuccess": "تمت إضافة سياسة رضا القناة بنجاح",
  "ccm.satisfactionconfig.strategy.updateuccess": "تم تعديل سياسة رضا القناة بنجاح",
  "ccm.satisfactionconfig.strategy.deletesuccess": "تم حذف نهج رضا القناة بنجاح",
  "ccm.satisfactionconfig.strategy.surveytyperequired": "لا يمكن أن يكون نوع الاستطلاع فارغًا",
  "ccm.satisfactiontemplate.title": "اعدادات محتوى نموذج استبيان الرضا",
  "ccm.ivr.label.processaccesscode" : "رمز التدفق",
  "ccm.satisfactionconfig.message.memberstrategyconfig" : "تكوين عضو مجموعة الاستراتيجية",
  "ccm.agent.message.modifySkillConfFail" : "فشل تعديل معلومات قائمة انتظار المهارة.",
  "ccm.agent.ten.mediaagents" : "عدد وكلاء الوسائط المتعددة",
  "ccm.verifyidentity.fail.VERIFYRESULTPARSEERROR" : "معلمة نتيجة مصادقة غير صحيحة.",
  "ccm.note.config.agent.ring.setUp" : "تكوين معلومات المطالبة",
  "ccm.agent.label.serviceno" : "ServiceNo",
  "ccm.custdata.msg.confirmWithdraw" : "هل أنت متأكد من رغبتك في سحبها؟",
  "ccm.agent.calledroute.createsuccess" : "تمت الإضافة بنجاح.",
  "ccm.ccagent.management.additions" : "إضافة",
  "ccm.calledroute.title.selectskill" : "تحديد قائمة انتظار المهارات",
  "ccm.custdata.operlog.operation" : "التشغيل",
  "ccmanagement.tenantspace.option.weeklist_tidtolast" : "الأخير ولكن اثنين",
  "ccm.speciallist.msg.levelName" : "المستوى",
  "ccm.agent.updateSoftPhone.accountPasswordFailed" : "فشل التحقق من كلمة مرور الحساب.",
  "ccm.agent.title.skillModify" : "تعديل قائمة انتظار المهارة",
  "ccm.agent.message.noReleaseFail" : "يمكن حذف البيانات غير المنشورة فقط.",
  "ccm.sysparam.error.validate.notmatchruleregex" : "لا تتطابق معلمة الإدخال مع التعبير العادي لقاعدة التحقق",
  "ccm.ivr.label.processdesc" : "وصف العملية",
  "ccm.agent.message.inputWeight" : "أدخل قيمة الوزن.",
  "ccm.agent.log.downloadVideoTerminal" : "قم بتنزيل محطة فيديو OpenEye",
  "ccm.calledconfig.message.deletemobileagentfail" : "فشل حذف اقتران تكوين موظف الجوال",
  "ccm.satisfactionconfig.message.updatefailed" : "فشل تحديث تكوين استطلاع الرضا",
  "ccm.agent.label.agentTel" : "رقم هاتف الموظف المحمول/الخط الثابت",
  "ccm.custdata.operlog.conditions" : "الشروط",
  "ccm.agent.label.busyJudging" : "تحويل المكالمات عند الازدحام أو الفائض",
  "ccm.idauth.create.error.limit" : "يمكن إضافة خمس عمليات مصادقة هوية كحد أقصى.",
  "ccm.agent.ten.maxVideoCalls" : "الحد الأقصى لمكالمات الفيديو المتزامنة",
  "ccm.agent.message.pageLayoutFail" : "لم يتم تحديد تخطيط الصفحة أو عدد الصفوف.",
  "ccm.agent.label.information" : "نصائح",
  "ccmanagement.satisfaction.strategy.acceptdate.startdate" : "تاريخ البدء",
  "ccm.satisfactionlevel.message.resetfail" : "فشل في إعادة تعيين مستوى الرضا.",
  "ccm.agent.message.export" : "تصدير",
  "ccm.agent.message.export.skill": "تصدير قائمة انتظار المهارات",
  "ccm.agent.info.message.export": "تصدير معلومات الموظف",
  "ccm.transserverparam.label.password" : "كلمة المرور",
  "ccm.note.config.choosenote" : "الرجاء تحديد ملف ملاحظة",
  "ccm.note.config.filepath" : "تحديد ملف الملاحظة",
  "ccm.agent.message.aiagentnummore" : "لا يمكن أن يتجاوز عدد الموظفين الذكيين الممكّنين عدد الموظفين المخصصين عند تمكين المستأجر.",
  "ccm.agent.ten.createDate" : "وقت الإنشاء",
  "ccm.custdata.msg.confirmReturn" : "الفرز غير مكتمل. هل تريد بالتأكيد العودة إلى الصفحة لعرض الجدول المخصص؟\"، \"ccm.custdata.msg.completeSort\": \"إكمال الفرز أولاً.",
  "ccm.agent.contact.reasonCode" : "رمز السبب",
  "ccm.callreason.case.check.error": "نوع التذكرة {0} غير موجود.",
  "ccm.speciallist.label.expirationDate" : "وقت انتهاء الصلاحية",
  "ccm.speciallist.msg.selectslreminder" : "حدد تذكير قائمة خاص.",
  "ccm.agent.label.keepVoiceDefault" : "نغمة الاحتفاظ الافتراضية",
  "ccm.agent.label.ivr" : "IVR",
  "ccm.anonymous.message.fail.close" : "فشل إغلاق العلامة. التحقق من تكوين CTI",
  "ccm.custdata.label.beginTime" : "وقت البدء",
  "ccm.agent.contact.nohaveevent" : "لا يوجد مثل هذا الحدث",
  "ccm.agent.contact.sameContactRecord" : "نفس سجل الاتصال",
  "ccm.agent.mobileagent.confirm" : "تأكيد",
  "ccm.agent.contact.playfail" : "فشل التشغيل.",
  "ccm.callreasonconfig.message.deletecallreason.success" : "تم الحذف بنجاح.",
  "iolp.configur.servicelabel.label.status" : "الحالة",
  "ccm.label.contactexport.curpage" : "الصفحة الحالية",
  "ccm.agent.label.inputstartworkno" : "الرجاء إدخال معرف موظف البدء",
  "ccm.note.config.agent.page.ring.audition" : "الاختبار",
  "ccm.agent.contact.multimedia.facebook" : "الوسائط المتعددة-فيسبوك",
  "iolp.workflow.executetype.common" : "مشترك",
  "iolp.configur.service.label.none.desc": "لا يتوفر تكوين فئة الخدمة. قم بإنشاء فئة خدمة أولاً.",
  "ccm.custdata.msg.tableExistInstData" : "يحتوي الجدول المخصص على بيانات مثيل ولا يمكن سحبه للتعديل.",
  "ccm.contact.customer.config.bold" : "جريئة",
  "ccm.speciallist.msg.empty" : "لا يمكن أن تكون رسالة التذكير خالية.",
  "ccm.agent.calledroute.delfail" : "فشل حذف البيانات المحلية.",
  "ccm.agent.softPhoneStatus.Registered" : "مسجل",
  "ccm.agent.message.accRepeatFail" : "معلمة الحساب مكررة.",
  "ccm.agent.contact.numhungup" : "التحويل إلى رقم خارجي",
  "ccm.agent.restReason.selectRestReasons" : "حدد سببًا للراحة.",
  "ccm.satisfactionconfig.message.surveymethod" : "طريقة الاستطلاع",
  "ccm.note.config.save" : "حفظ",
  "ccm.custdata.option.isMandatory.no" : "لا",
  "ccm.agent.calledroute.placeholder.skill" : "يرجى تحديد قائمة انتظار مهارة.",
  "ccm.custdata.option.status.draft" : "مشروع",
  "ccm.agent.contact.createbother" : "إنشاء سجل عناء",
  "ccm.agent.skill.skillExist" : "موجود بالفعل. أدخل اسماً آخر.",
  "ccm.custdata.option.maskStyle.maskIdCardNum" : "قناع رقم بطاقة الهوية",
  "ccm.calledconfig.label.inputaccesscode" : "الرجاء إدخال رمز الوصول",
  "ccm.transserver.label.cssaddr" : "عنوان خدمة CSS",
  "ccm.agent.clear.message.agentnotexist" : "فشل إلغاء التكوين لأن الموظف غير موجود.",
  "ccm.sysparam.error.validate.watermarkinfo.notmatchruleregex" : "تسمح المعلمة فقط بـ 0 إلى 20 حرفًا إنجليزيًا تحتوي على أحرف كبيرة وصغيرة\ أو أرقام أو -_/@.",
  "ccmanagement.satisfaction.strategy.accepttime.hour" : "ساعة",
  "ccm.agent.softPhoneStatusNode2" : "العقدة 2",
  "ccm.agent.label.deviceUsageThresholdPrefix" : "عندما يكون استخدام الجهاز أكبر من أو يساوي",
  "ccm.transtask.label.saturday" : "السبت",
  "ccm.agent.contact.agentPresent" : "رقم عرض اتصال الموظف",
  "ccm.agent.softPhoneStatusNode1" : "العقدة 1",
  "ccm.speciallevel.tip.stop" : "هل أنت متأكد من أنك تريد تعطيله؟",
  "ccm.agent.contact.allcalltype" : "جميع أنواع المكالمات",
  "ccm.agent.videoDownload.disabled" : "تم تعطيل وظيفة تنزيل الفيديو",
  "ccm.agent.contact.whatsapp": "WhatsApp",
  "ccm.agent.label.fixedValue" : "قيمة ثابتة",
  "ccmanagement.satisfaction.strategy.operation" : "العملية",
  "ccm.speciallist.msg.restoreerror" : "فشل استعادة القائمة الخاصة",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnull" : "لا يمكن أن يكون تاريخ البدء أو الانتهاء فارغًا.",
  "ccm.transtask.label.savefail" : "فشلت إضافة ملقم تفريغ بسبب السماح بحد أقصى {0} ملقمات تفريغ.",
  "ccm.agent.contact.authHandle" : "اتصل بموظفي الإدارة ذوي الصلة.",
  "ccm.agent.message.worknull" : "معرف الموظف فارغ.",
  "ccm.agent.calledroute.accesscodewarn" : "يمكن أن يحتوي رمز الوصول على أرقام فقط!",
  "ccm.transtask.label.pause" : "إيقاف مؤقت",
  "ccm.agent.Transfer.Intercept" : "اعتراض",
  "ccm.agent.leavemessage.lengthcodewarn" : "يمكن أن تحتوي الرسالة على 300 حرف كحد أقصى.",
  "ccm.idauth.create.error.format" : "يمكن أن يحتوي رمز وضع المصادقة على أرقام وحروف وتسطير سفلي فقط (_).",
  "ccm.agent.accesscode.description.containspatial" : "لا يمكن أن يحتوي وصف رمز الوصول على أحرف خاصة % ^ & * < > $ /",
  "ccm.custdata.operlog.exportTaskStatus" : "الحالة",
  "ccm.recognition.label.modifymobileagentsuccess" : "تم تعديل حالة موظف الجوال بنجاح!",
  "ccm.agent.calledroute.nmsdata.error" : "فشل مزامنة البيانات مع NMS. بيانات تكوين الطرف المطلوب لـ NMS غير متناسقة مع البيانات المحلية لـ CCM.",
  "ccm.agent.operation.vdnNotEnabled" : "لم يتم تمكين VDN.",
  "ccm.agent.label.weight" : "الوزن",
  "ccm.custdata.option.maskStyle.maskLast4" : "قناع آخر أربعة أرقام",
  "ccm.agent.ten.DSTOffset" : "إزاحة DST (دقيقة)",
  "ccm.basicdata.error.suffix" : ".",
  "ccm.agent.label.mediaagent" : "وكيل الوسائط المتعددة",
  "ccmanagement.satisfactionconfig.acceptdate.timeerror.laterThanEndTime" : "يجب أن يكون تاريخ البدء قبل تاريخ الانتهاء.",
  "ccm.pageurls.select.webApi" : "واجهة برمجة تطبيقات الويب",
  "ccm.agent.pageurls.success" : "ناجح",
  "ccm.agent.label.notreply" : "لا يمكن الرد",
  "ccm.transtask.label.taskid" : "معرف المهمة",
  "ccm.sysparam.config.select.null" : "يرجى تحديد معلمة.",
  "ccm.agent.label.groupUseTheSkill" : "الرجاء حذف تكوين قائمة انتظار المهارات في المجموعة المختصة أولاً.",
  "ccm.satisfactionconfig.message.smsgateway" : "بوابة الرسائل القصيرة",
  "ccm.transtask.label.vdnAccessCodeReport" : "تقرير حركة مرور VDN حسب رمز الوصول",
  "iolp.configur.service.label.shortcutButtonContainerId" : "معرف الحاوية العلوية في صفحة زر الاختصار",
  "ccm.agent.contact.gateway.exception" : "استثناء داخلي للعبّارة. يُرجى التحقق من تكوين البوابة.",
  "ccm.basicdata.msg.modifysuccess" : "تم تعديل البيانات الأساسية بنجاح.",
  "ccm.label.contactexport.selectdatarange" : "نطاق البيانات",
  "ccm.agent.pageurls.integrationType" : "نوع التكامل",
  "ccm.systemparam.error.notmatchrule" : "لا تتوافق المعلمة مع قاعدة التحقق.",
  "ccm.agent.contact.callerfailed" : "فشل المتصل الصادر",
  "ccm.skill.label.skilldesc" : "الوصف",
  "ccm.calledconfig.table.addnumbers" : "إضافة أرقام",
  "ccm.custdata.label.columnNum" : "الحقول",
  "iolp.tree.tips" : "لا يمكنك إضافة أي عقدة تحت عقدة طرفية.",
  "ccm.satisfactionconfig.message.failed" : "فشل",
  "ccm.speciallisthis.operlog.downloadData" : "تصدير تاريخ القائمة الخاصة",
  "ccm.agent.contact.searchName" : "اسم المستند",
  "ccm.agent.ccagent.qcmodify.fail" : "حدد نوع موظف الصوت.",
  "ccm.transtask.label.tasktype" : "النوع",
  "ccm.pageinterface.cancelAll" : "إلغاء الكل",
  "ccm.agent.label.playVoice" : "تشغيل نغمة الانتظار",
  "ccm.basicdata.error.syserror" : "خطأ في النظام. اتصل بموظف الصيانة",
  "ccm.speciallist.button.modify" : "تعديل",
  "ccm.levelMessage.title.modify": "تعديل إدارة المستوى",
  "ccm.speciallist.title.modify": "تعديل قائمة خاصة",
  "ccm.speciallistreminder.button.modify": "تعديل تذكير القائمة الخاصة",
  "ccm.satisfactionservice.noevaluation" : "لم يتم تقييمها",
  "ccm.verifyidentity.FAIL" : "فشل",
  "ccm.urlconfig.message.oauth.tip.warning" : "قد لا تتسبب مصادقة عنوان URL لرد الاتصال في مخاطر أمنية. هل تريد بالتأكيد استخدام وضع المصادقة هذا؟",
  "ccm.agent.title.agentinfo" : "معلومات الوكيل",
  "ccm.agent.label.usedMode" : "النوع",
  "ccm.custdata.option.all" : "الكل",
  "ccm.certificate.management.certificatefile" : "ملف الشهادة",
  "ccm.agent.addbother.msg.emailOrPhoneValidate" : "التنسيق لا يلبي المتطلبات",
  "ccm.satisfactionconfig.message.satisreport" : "تقرير استبيان الرضا",
  "ccm.agent.pageurls.movedown" : "إلى الأسفل",
  "ccm.accesscode.label.accesscode" : "رمز الوصول",
  "ccm.speciallist.msg.datecompareerror" : "لا يمكن أن يكون وقت انتهاء الصلاحية بعد وقت السريان.",
  "ccm.callout.message.isconfreset" : "هل أنت متأكد من رغبتك في إعادة تعيين استدعاء الاستدعاء؟",
  "ccm.custdata.operlog.exportiong" : "تصدير",
  "ccm.agent.message.longCharacters" : "يمكن أن يحتوي الرقم على 25 حرفًا كحد أقصى.",
  "ccm.agent.message.longCharacters.form": "يمكن أن يحتوي الرقم على 25 حرفًا كحد أقصى",
  "ccm.label.pageName" : "اسم الصفحة",
  "ccm.certificate.management.certificatefile.uploadcertfile" : "تحميل ملف الشهادة",
  "iolp.label.workflow" : "سير العمل",
  "ccm.worknobeforevoice.config.choosenote" : "حدد قيمة لمعرّف \"الصوت قبل الموظف\".",
  "casemanagement.user.title.name" : "اسم المستخدم",
  "ccm.agent.label.negativeEmotionNumber" : "سلبي",
  "ccm.taskrecord.label.reexecute" : "إعادة التنفيذ",
  "ccm.recognition.label.updatefail" : "فشل تحديث المستأجر.",
  "ccm.agent.page.pageParameter" : "معلمات الصفحة",
  "ccm.certificate.management.update.crlfile" : "تحديث ملف CRL",
  "ccm.agent.pageurls.confonlynaming" : ". تأكد من أن الاسم فريد من نوعه.",
  "ccm.agent.message.allExportAgent" : "هل تريد بالتأكيد تصدير كافة معلومات الموظف؟",
  "ccm.urlconfig.label.input.appId" : "AccessKey",
  "ccm.agent.pageurlparam.calldata" : "بيانات الاتصال",
  "ccm.verifyidentity.verifyResult" : "نتيجة المصادقة",
  "ccm.transserver.label.view" : "عرض",
  "iolp.workflow.executetype.SMS" : "SMS",
  "iolp.workflow.nodetype.andjoin" : "مزيج",
  "ccm.agent.calledroute.delete.skill.use" : "تمت الإشارة إلى تكوين الطرف المطلوب بواسطة قائمة انتظار مهارة ولا يمكن حذفه.",
  "ccm.custdata.msg.sortNoChange" : "الفرز لا يتغير.",
  "ccm.satisfactionconfig.message.updatesuccess" : "تم تحديث تكوين استطلاع الرضا بنجاح",
  "ccm.agent.label.on" : "تشغيل",
  "ccm.agent.message.quotingDataOperationFail" : "تمت الإشارة إلى البيانات بواسطة الشاشة المنبثقة. فشلت العملية.",
  "ccm.agent.label.waitVoice" : "المهارة WaitVoice",
  "ccm.custdata.option.maskStyle.maskBankAccountNum" : "قناع الحساب المصرفي",
  "ccm.agent.contact.faceBook" : "Facebook",
  "ccm.agent.message.pageNumFail" : "يُسمح بحد أقصى {0} صفحات.",
  "ccm.agent.label.skillWeight" : "عامل مهارة الوزن",
  "ccm.custdata.option.maskStyle.retainLast4" : "حجز آخر أربعة أرقام",
  "ccm.agent.pageurls.config" : "التكوين",
  "ccm.certificate.management.updatetime" : "تحديث الوقت",
  "ccm.contact.detail.talk.content.seach.remark" : "يمكن البحث فقط في محتوى الدردشة النصية المعروض في هذه الصفحة",
  "ccm.agent.contact.multimedia.line" : "الوسائط المتعددة-خط",
  "ccm.agent.mobileagent.notice" : "إشعار",
  "ccm.satisfactionlevel.message.savefail" : "فشل حفظ مستوى الرضا.",
  "SM.ORGSTAFF.LABEL.ORGANIZATION" : "وحدة التنظيم",
  "ccm.vcallcenter.config.popup.currentaccount.pwd" : "كلمة مرور الحساب الحالي",
  "ccm.custdata.msg.deletedItemNotExist" : "عنصر الحذف غير موجود. قم بتحديث الصفحة وحاول مرة أخرى.",
  "ccm.urlconfig.label.oauth.callback.alerttip" : "إذا تم نقل عنوان URL لرد الاتصال عند استدعاء API لإنشاء مكالمة ثنائية الاتجاه وتكوين معلومات المصادقة التالية، يتم استخدام هذه المعلومات أثناء رد عنوان URL عند إصدار مكالمة ثنائية الاتجاه. وإلا، لا يتم إجراء أي مصادقة أثناء معاودة الاتصال.",
  "ccm.agent.label.callDispatchModel.average" : "متوسط",
  "ccm.verifyidentity.fail.LIMITOVERLIMIT" : "تتجاوز قيمة معلمة استعلام ترقيم الصفحات الحد الأعلى.",
  "ccm.policy.label.updateFailed" : "فشل تحديث معلومات السياسة.",
  "ccm.agent.message.delete" : "حذف",
  "ccm.satisfactionconfig.message.success" : "النجاح",
  "ccm.agent.button.batchmodify" : "تعديل الحزمة",
  "ccm.agent.message.willExportAgent" : " رسائل. هل أنت متأكد من رغبتك في التصدير؟",
  "ccm.agent.contact.customername" : "اسم العميل",
  "ccm.urlconfig.label.relurl" : "عنوان URL لتعليق الاتصال",
  "ccm.transserver.label.cloudsearchserver.css" : "تكوين CSS",
  "ccm.agent.label.noaireconition" : "خطأ",
  "ccm.taskrecord.label.choosetask" : "تحديد المهمة",
  "ccm.satisfactionconfig.message.strategygroupmember" : "سياسة",
  "ccm.speciallist.button.history" : "نقل إلى التاريخ",
  "ccm.speciallevel.speciallist.restrictHours" : "المدة المقيدة (ساعة)",
  "ccm.contact.detail.sending.time" : "وقت الإرسال",
  "ccm.satisfactionconfig.message.flowaccesscode" : "رمز الوصول إلى العملية",
  "ccm.satisfactionconfig.message.version" : "رقم الإصدار",
  "ccm.sysparam.error.validate.inner" : "فشل التحقق من المعلمة",
  "ccm.custdata.operlog.popwindowmessage" : "الرجاء إدخال كلمة المرور المستخدمة لإنشاء الملف المضغوط. يجب أن تحتوي كلمة المرور المضغوطة على أحرف وأرقام ورموز خاصة باللغة الإنجليزية باللغة الإنجليزية... باللغة",
  "ccm.ucconfig.message.updatefailed" : "فشل حفظ تكوين تكامل UC.",
  "ccm.basicdata.error.databaseerror" : "بيانات الفهرس مكررة. ونتيجة لذلك\، فشل استيراد البيانات إلى قاعدة البيانات على دفعات",
  "ccm.agent.contact.talkCallbackFlag" : "التحدث إشارة رد الاتصال",
  "ccm.satisfactionconfig.message.audiofeedbacktype" : "صوت IVR",
  "ccmanagement.satisfaction.strategy.deleteselect" : "هل تريد بالتأكيد حذف النُهج المحددة؟",
  "ccmanagement.satisfaction.strategy.fail.calltime.startafterend" : "يجب أن يكون الحد الأدنى لمدة المكالمة أقل من الحد الأقصى لمدة المكالمة.",
  "ccm.contact.customer.config.datasource.callData" : "البيانات المرتبطة بالمكالمة",
  "ccm.agent.message.synToUapFailed" : "فشل في مزامنة وضع مصادقة الموظف مع UAP.",
  "ccm.agent.calledroute.fail" : "فشل",
  "ccm.transserver.label.cssServerPort" : "منفذ خدمة CSS",
  "ccm.custdata.operlog.failed" : "فشل",
  "ccm.certificate.management.certcode" : "اسم الشهادة",
  "ccm.custdata.label.columnName" : "اسم الحقل",
  "ccm.pageinterface.custTableName" : "اسم الجدول الأساسي",
  "ccm.pageinterface.interfaceType.internal" : "واجهة داخلية",
  "ccm.contactitemconfig.label.contact.recorddata" : "عنصر بيانات سجل جهة الاتصال",
  "ccm.agent.label.selfPhoneNotNull" : "عند تمكين ميزة إخفاء الهوية الخاصة برقم الموظف\، لا يمكن أن يكون رقم هاتف الخط الثابت أو رقم الهاتف المحمول الخاص بالعامل فارغًا.",
  "iolp.configur.service.label.customizedPageUrl" : "عنوان URL المخصص للصفحة",
  "ccm.custdata.msg.duplicateTableName" : "اسم جدول مكرر. إعادة تسميته.",
  "ccm.agent.label.waitTimePrefix" : "عندما تتجاوز مدة الانتظار المتوقعة",
  "ccm.agent.message.videoagentzero" : "عدد وكلاء الفيديو هو 0. لا يمكن تكوين قائمة انتظار مهارات النقر لطلب الفيديو.",
  "ccmanagement.satisfactionconfig.calltime.timeerror.laterThanEndTime" : "يجب أن يكون الحد الأدنى لمدة المكالمة أقل من الحد الأقصى لمدة المكالمة.",
  "ccm.contactitemconfig.message.itemValidate" : "يجب أن يكون الحرف الأول حرفا، تسطير (_), أو علامة الدولار ($). يمكن أن تكون الحروف الأخرى تسطير (_), علامة الدولار ($), رسائل، أو أرقام.",
  "ccm.pageinterface.interfaceType.externalExplain" : "يتم تكوين الواجهات الخارجية على صفحة إدارة API. اتصل بمسؤول النظام.",
  "ccm.agent.pageurl.httptip" : "(غير آمنة)",
  "ccm.page.select.tileLayout" : "تخطيط الشبكة",
  "ccm.agent.contact.itacall" : "مكالمة ITA ثنائية الاتجاه",
  "ccm.custdata.label.tableName" : "اسم الجدول",
  "ccm.custdata.title.edit": "تحرير البيانات",
  "ccm.custdata.title.coloum.add": "إنشاء عمود حقل",
  "ccm.custdata.title.coloum.edit": "تحرير عمود الحقل",
  "ccm.custdata.title.coloum.detail": "عرض تفاصيل الحقل",
  "ccm.transserver.delete.confirm" : "هل تريد بالتأكيد حذف الملقم؟",
  "ccm.agent.contact.voiceBot" : "صوت",
  "ccm.agent.operation.CANCELREST" : "إلغاء الراحة",
  "ccm.agent.message.aiagentclosed" : "تم تعطيل ميزة التعريف الذكي للمستأجر. اتصل بمسؤول النظام.",
  "ccm.agent.contact.preview" : "معاينة",
  "ccm.satisfactionconfig.message.calltypein" : "مكالمة واردة",
  "ccm.calledconfig.message.nonumbers" : "يرجى اختيار الأرقام",
  "ccm.agent.contact.resourceloading" : "جاري تحميل الموارد...",
  "ccm.satisfactionconfig.message.chooseivrflow" : "اختر تدفق IVR",
  "ccm.transtask.label.expirationDuration" : "فترة الاحتفاظ بالملفات",
  "ccmanagement.satisfaction.strategy.saveerror" : "فشل",
  "ccm.agent.label.queueTransferType" : "نقل إلى نوع الكائن",
  "ccm.satisfactionconfig.message.media" : "قنوات الوسائط المتعددة",
  "ccm.agent.contact.contactno" : "رقم الاتصال",
  "ccm.agent.leavemessage.processfail" : "فشل في معالجة الرسالة.",
  "ccm.custdata.msg.typeColumnNumValidateFailed" : "يتجاوز عدد الحقول الحد الأعلى: يُسمح بحقل فهرس واحد فقط\، وبحد أقصى 30 حقلاً غير مشفر و20 حقلاً مشفراً.",
  "ccm.agent.pageurls.updateinfo" : "تم التحديث بنجاح.",
  "ccm.agent.moveup.error": "فشل النقل لأعلى.",
  "ccm.agent.moveup.success": "تم التحرك لأعلى بنجاح.",
  "ccm.agent.movedown.error": "فشل النقل لأسفل.",
  "ccm.agent.movedown.success": "تم التحرك لأسفل بنجاح.",
  "ccm.contract.management.invalid.confirm" : "هل تريد بالتأكيد إبطال هذا المستند؟",
  "ccm.contact.detail.talk.content.seach.remark.emaile" : "يمكن البحث فقط عن الرسائل التي تم عرضها في صفحة البحث. بعد اكتمال البحث، يتم عرض الرسائل المتطابقة فقط.",
  "ccm.note.config.agent.page.ring.warn" : "يُنصح بإجراء اختبار مكالمة لمنع تداخل نغمة الرنين مع نغمة الرنين التي تم تكوينها على الهاتف.",
  "ccm.agent.message.searchAgentServiceNo" : "يرجى إدخال رقم خدمة الموظف",
  "iolp.workflow.executetype.IVR" : "عبر IVR",
  "ccm.agent.message.nulagentincti" : "لم يتم إنشاء الموظف المحدد حالياً في CTI\،تحتاج إلى تحديد معلومات الدور.",
  "ccm.agent.label.waitVoiceDefault" : "نغمة الانتظار الافتراضية",
  "ccm.skill.message.skillpara" : "لا يمكن تكوين المهارة نفسها",
  "iolp.configur.servicelabel.status.draft" : "مشروع",
  "ccm.agent.label.timeOut" : "مهلة المهارة",
  "ccm.custdata.operlog.downloadData" : "تنزيل بيانات الجدول الأساسية",
  "ccm.callout.lable.caller" : "متصل الشرح",
  "ccm.agent.leavemessage.status.inProcess" : "المعالجة",
  "ccm.agent.contact.acceptno" : "رقم تمت معالجته",
  "ccm.speciallist.label.operator" : "المشغل",
  "ccm.callreason.label.input" : "يُرجى إدخال",
  "ccm.agent.leavemessage.processsuccess" : "تمت معالجة الرسالة بنجاح.",
  "ccm.urlconfig.label.input.appKey" : "AppKey",
  "ccm.agent.resetskillrecord.skillbeforeadjust" : "قائمة انتظار المهارة قبل التعديل",
  "ccm.pageurls.select.postParameter" : "معلمة طلب POST لعنوان URL",
  "ccm.certificate.management.certificatefile.suffixerror.pre" : "يمكن أن تحتوي شهادات النوع فقط على الملفات ذات الامتداد",
  "ccm.speciallist.msg.operationdoing" : "المراجعون الآخرون يوافقون",
  "ccm.calledroute.title.selectivrflow" : "تحديد IVR",
  "ccm.agent.message.typenosame" : "يجب أن تكون أنواع قوائم انتظار المهارة هي نفسها.",
  "ccm.pageurls.select.embedding" : "تضمين",
  "ccm.label.chatRecord.channeltypeinnull" : "حدد أنواع القنوات.",
  "ccm.recognition.label.mobileagent" : "وكيل الجوال/مكالمة ثنائية الاتجاه بنقرة واحدة",
  "ccm.recognition.label.api.fabric.id" : "API نسيج معرف",
  "ccm.agent.label.agentWeight" : "وكيل الوزن",
  "ccm.agent.label.keepWaitType" : "الحفاظ على الطريقة",
  "ccmanagement.satisfaction.strategy.accepttime.minute" : "دقيقة",
  "ccm.urlconfig.message.tip.warning" : "تأكد من أنك قد اشتريت الخدمات المتعلقة بـ ITA. وإلا\، ستتأثر المكالمات.",
  "ccm.calledconfig.message.numbersinvalid" : "أكثر من 15 أو أقل من رقم واحد",
  "ccm.ucconfig.message.urlTitle" : "إعادة توجيه URI التي تم تكوينها إلى النظام الأساسي لـ Microsoft لقبول استجابات المصادقة:",
  "ccm.note.config.policy.systemdefault" : "افتراضي للنظام",
  "ccm.agent.contact.multi-media" : "الوسائط المتعددة",
  "casemanagement.casedraft.label.selectcasetype" : "حدد نوع الحالة",
  "ccm.agent.button.downloadvideoterminal" : "تحميل OpenEye",
  "ccm.agent.contact.QUERU_SFTP_FAIL_CODE" : "معلومات SFTP فارغة.",
  "ccm.agent.ten.systemdefault" : "المنطقة الزمنية الافتراضية",
  "ccm.callout.message.resetfail" : "فشل في إعادة تعيين المعلمات.",
  "ccm.custdata.operlog.addExportTask" : "مهمة تصدير جديدة",
  "ccm.note.config.error" : "خطأ",
  "ccm.agent.pageurls.adressval" : "يجب أن يبدأ عنوان الوصول بـ ' https://' أو ' http://'.",
  "ccm.agent.pageurls.adressval.noPoint" : "يجب أن يبدأ عنوان الوصول بـ ' https://' أو ' http://'",
  "ccm.satisfactionconfig.message.channelstrategyconfig" : "سياسة استبيان رضا القناة",
  "ccm.botherlist.label.comment" : "آراء",
  "ccm.speciallevel.speciallist.level" : "المستوى",
  "ccm.agent.label.busyRateRange" : "القيمة [0-100]",
  "ccm.custdata.label.endTime" : "وقت الانتهاء",
  "ccm.agent.pageurlparam.checkSpecialChar" : "أدخل الأرقام والحروف والشرط السفلي (_), والواصلات (-). يجب أن تكون النقطة (.) في المنتصف.",
  "ccm.agent.pageurlparam.checkSpecialChar.noPoint" : "أدخل الأرقام والحروف والشرط السفلي (_), والواصلات (-). يجب أن تكون النقطة (.) في المنتصف",
  "ccm.agent.pageurlparam.checkSpecialChar.name": "أدخل الأرقام والحروف والشرط السفلي (_), والواصلات (-)",
  "ccm.agent.ten.agentSipInfo" : "عنوان IP ورقم منفذ خادم تسجيل الموظف",
  "ccm.agent.resetskillrecord.adjustresult" : "ضبط النتيجة",
  "ccm.agent.message.pageNameValidateFail" : "لا يُسمح بالرموز الخاصة",
  "ccm.contactitemconfig.label.data.extended.set" : "مجموعة بيانات المصدر",
  "ccm.certificate.management.update.cancel.certfile" : "هل تريد بالتأكيد إلغاء تحديث الشهادة؟",
  "ccm.sysparam.config.item.catalog" : "نوع المعلمة",
  "ccm.speciallevel.msg.deleteyerror" : "فشل حذف مستوى القائمة الخاصة.",
  "ccm.contactitemconfig.label.data.extended.writeback.extended.field" : "حقل الكتابة الموسع",
  "iolp.workflow.node.label.autoNodeInfo" : "معرف العقدة التالية",
  "ccm.agent.contact.refreshAll" : "تحديث",
  "ccm.ucconfig.message.updatesuccess" : "تم حفظ تكوين تكامل UC بنجاح.",
  "ccm.agent.speciallist.endtime" : "وقت انتهاء الصلاحية",
  "ccm.recognition.label.openIvrAgentSuccess" : "تم تمكين مساعد الموظف الذكي بنجاح.",
  "ccm.agent.pageurlparam.relSysParamName" : "معلمة بيانات النظام المقترنة",
  "ccm.agent.message.skillweight.outlimit" : "يجب أن تتراوح قيمة الوزن من 1 إلى 100.",
  "ccm.pageinterface.interfaceType" : "نوع الواجهة",
  "ccm.speciallist.msg.willdelete" : "هل تريد بالتأكيد حذف هذه العناصر {limit}؟",
  "ccm.common.msg.willdelete": "هل تريد بالتأكيد حذف سجلات {limit} المحددة؟",
  "ccm.agent.message.createAgentAndSkillFail" : "فشل إنشاء اقتران قائمة انتظار مهارة الموظف.",
  "ccm.agent.contact.playdefault" : "مدة التسجيل فارغة. هل تريد استخدام المدة الافتراضية 60 دقيقة؟",
  "ccm.contact.customer.config.foregroundColor" : "لون المقدمة",
  "ccm.transtask.label.tasknameexits" : "فشل إنشاء المهمة لأن اسم المهمة موجود بالفعل.",
  "ccm.custdata.button.detail" : "التفاصيل",
  "iolp.workflow.node.label.executeContent" : "محتوى التنفيذ",
  "ccm.urlconfig.message.missing.appIdOrAppSecret" : "لم يتم تعيين مفتاح الوصول أو المفتاح السري في تكوين مكالمة ITA ثنائية الاتجاه.",
  "ccm.contact.tip.no.workname" : "لم يتم الحصول على العامل المطابق！",
  "ccm.recognition.label.policy.information" : "معلومات السياسة",
  "ccm.recognition.label.editPolicy.information": "تحرير معلومات النهج",
  "ccm.urlconfig.label.noramalOrITA.input" : "تكوين معلمة ITA",
  "ccm.custdata.operlog.inputzippwd" : "أدخل كلمة مرور الضغط",
  "ccm.agent.message.ctipasswdsimple" : "لا تفي كلمة المرور بمتطلبات تعقيد النظام الأساسي. اتصل بأفراد O M للتحقق من كلمة المرور.",
  "ccm.agent.button.cancelconfig" : "إلغاء التكوين",
  "ccm.agent.message.warning" : "تحذير",
  "ccm.agent.ten.recordtime" : "فترة الاحتفاظ بالتسجيل",
  "ccm.recognition.label.updatesucess" : "تحديث المستأجر fautl النجاح.",
  "ccm.label.contactexport.parainvalid" : "فشل التحقق من شروط التصدير.",
  "ccm.sysparam.config.itemdesc" : "الوصف",
  "ccm.agent.label.subtype" : "النوع الفرعي",
  "ccm.systemparam.success.reset" : "تمت إعادة تعيين المعلمة بنجاح.",
  "ccm.transtask.label.discarded" : "تم تجاهله",
  "ccm.ucconfig.message.userId" : "معرف الدليل (المستأجر)",
  "ccm.agent.Transfer.deviceDesc" : "جهاز الوجهة",
  "ccm.transtask.label.exportdata" : "حقل تصدير البيانات:",
  "ccm.transtask.label.vdnReport" : "تقرير حركة مرور VDN",
  "ccm.callreasonconfig.message.deletecallreason.failed" : "فشل الحذف.",
  "iolp.configur.servicelabel.confirm.deleteAll" : "هل تريد بالتأكيد حذف العقدة الحالية والعقد الفرعية الخاصة بها؟",
  "ccm.whatsapp.template.message.name" : "اسم الرسالة",
  "ccm.callreason.label.selectedreason" : "أسباب مختارة",
  "ccm.agent.label.serviceAccountName" : "اسم حساب سحابة الخدمة",
  "ccm.pageconfig.label.layoutType" : "نوع التخطيط",
  "ccm.speciallist.msg.size" : "حجم الملف المراد استيراده يتجاوز 10 ميغابايت",
  "ccm.asr.create.case.text.info" : "إنشاء حالة بنقرة واحدة غير معتمد لأنه لا يوجد محتوى نصي.",
  "ccm.agent.message.pageConfFail" : "فشلت العملية",
  "ccm.custdata.option.status.release" : "الافراج",
  "ccm.agent.resetskillrecord.adjustagentworkno" : "ضبط معرف الموظف",
  "ccm.agent.contact.QUERY_SERVER_FAIL_CODE" : "فشل استدعاء خدمة الاستعلام.",
  "ccm.agent.message.synToCcpFailed" : "فشل في مزامنة وضع مصادقة الموظف مع cc-provision.",
  "ccm.agent.label.airecog" : "التعرف الذكي",
  "ccm.certificate.management.status.confirmReuse" : "هل تريد بالتأكيد متابعة استخدام هذه الشهادة؟",
  "ccm.agent.message.deletefailed" : "فشل حذف الموظف",
  "ccm.agent.contact.signaturedate" : "تاريخ التوقيع",
  "ccm.custdata.operlog.exportBeginTime" : "وقت بدء التصدير",
  "ccm.agent.ten.phonenumber" : "رقم الجوال",
  "ccm.callback.url.callnotify" : "تكوين رد الاتصال بالإعلام الصوتي",
  "ccm.sysparam.config.opterate" : "العملية",
  "ccm.contactitemconfig.label.data.extended.dataset.mapping.field" : "حقل تعيين مجموعة البيانات",
  "ccmanagement.tenantspace.option.weekday_tue" : "الثلاثاء",
  "ccm.agent.label.ccRole" : "الدور",
  "ccm.agent.ten.timezone" : "المنطقة الزمنية",
  "ccmanagement.satisfaction.strategy.calltime.maxvalue" : "الحد الأقصى لمدة المكالمة (بالثانية)",
  "ccm.agent.contact.isExistTalkReason" : "تعيين سبب المكالمة أم لا",
  "ccm.certificate.management.search.scenename" : "يرجى إدخال مشهد الشهادة",
  "ccm.custdata.operlog.exportEndTime" : "وقت انتهاء التصدير",
  "ccm.custdata.operlog.confirm" : "تأكيد",
  "ccm.contactitemconfig.label.data.extended.Type.String" : "سلسلة",
  "ccm.agent.restReason.restCauseTime.limit" : "يجب أن تكون مدة الراحة أكبر من 0 ثانية وأقل من 24 ساعة.",
  "ccm.agent.pageurls.sernum" : "لا ..",
  "ccm.speciallist.msg.endtime" : "لا يمكن أن يكون تاريخ انتهاء الصلاحية بعد 19 يناير 2038",
  "ccm.satisfactionconfig.audio.and.video" : "استبيان رضا قناة الصوت أو الفيديو",
  "ccm.operlog.contactexport.download" : "تنزيل سجلات جهات الاتصال",
  "ccm.dual.call.record.unexpected.end" : "نهاية غير متوقعة",
  "ccm.urlconfig.message.deletefail" : "فشل في إعادة تعيين عناوين urls",
  "iolp.configur.servicelabel.common.tips.success" : "النجاح",
  "ccm.asr.create.case" : "إنشاء حالة",
  "ccm.agent.sysparamconfig.resttimewarn" : "يجب أن تكون القيمة عددًا صحيحًا.",
  "ccm.chatbot.pop.table.botname" : "اسم الروبوت",
  "ccm.agent.leavemessage.channelType" : "نوع القناة",
  "ccm.operlog.contactfile.download" : "تنزيل الملف",
  "ccm.agent.restReason.isusing.warning" : "رموز سبب الراحة سارية المفعول. قد يؤدي ذلك إلى عرض سبب الراحة بشكل غير صحيح في سجل الراحة.",
  "ccm.idauth.title.name" : "اسم وضع المصادقة",
  "ccm.custdata.button.exportTemplate" : "تصدير قالب",
  "ccm.agent.label.configAdmin" : "مسؤول التكوين",
  "ccm.agent.updateSoftPhone.updateOpertionSuccess" : "تحذير",
  "ccm.agent.contact.fiveg" : "5G RCS",
  "ccm.satisfactionconfig.audio.and.video.remark" : "عند تشغيل كل من مبدلي استبيان رضا الصوت والرسائل القصيرة IVR ويقوم العامل بإنهاء الاتصال أولاً، لن يتم إجراء أي استبيان رضا عن رسالة SMS إذا تم إجراء استطلاع رضا صوت IVR",
  "ccm.urlconfig.message.modifyfail" : "فشل تعديل عناوين urls",
  "ccm.custdata.msg.indexColumnValidateFailed" : "الحقل المستخدم كفهرس إلزامي ولا يمكن تشفيره.",
  "ccm.transserverparam.label.bucket" : "دلو البيانات",
  "ccm.agent.label.batchselect" : "تحديد الموظفين في دفعات",
  "ccm.contact.customer.config.label" : "الاتصال منطقة معلومات العملاء",
  "ccm.agent.leavemessage.status" : "الحالة",
  "ccm.agent.sysparamconfig.multimediawarn" : "يجب أن يتراوح عدد رسائل الوسائط المتعددة المتزامنة من 1 إلى 60.",
  "ccm.taskrecord.label.executeFail" : "فشل",
  "ccm.urlconfig.message.urlnotinwhite" : "فشلت العملية. اتصل بمسؤول النظام لإضافة URL إلى قائمة الثقة.",
  "ccm.calledroute.button.finish" : "مكتمل",
  "ccm.verifyidentity.verifyTime" : "وقت المصادقة",
  "ccm.calledroute.message.selectcalledroute" : "يرجى تحديد تكوين الطرف المطلوب.",
  "ccm.custdata.title.create" : "إنشاء جدول أساسي",
  "ccm.urlconfig.label.relurl.voicemsg.alerttip" : "إذا لم يتم نقل عنوان URL لرد الاتصال عند استدعاء API لإنشاء الإعلام الصوتي، فسيتم استدعاء عنوان URL هذا مرة أخرى عند اكتمال الإعلام الصوتي. خلاف ذلك ، يتم استدعاء عنوان URL المنقول مرة أخرى.",
  "ccm.agent.contact.assistant" : "مكالمة تعاونية",
  "ccm.recognition.resetSK.failed" : "فشل في إعادة تعيين SK.",
  "ccm.agent.addbother.success" : "نجح في إنشاء سجل عناء.",
  "ccm.pageinterface.saveSuccess" : "تم حفظ مجموعة واجهة الصفحة بنجاح.",
  "ccm.agent.calledroute.create.media.match" : "يختلف رمز الوصول إلى النظام الذي تم تكوينه للطرف المطلوب عن نوع الوسائط الذي تم تكوينه للطرف المطلوب.",
  "ccm.agent.calledroute.placeholder.extcode" : "الرجاء إدخال رمز الامتداد.",
  "ccm.agent.operation.RELEASE" : "الافراج",
  "ccm.satisfactionconfig.message.strategygroupmemchoose" : "حدد السياسات.",
  "ccm.agent.label.dualChannelRecFlag" : "تسجيل المسار المزدوج",
  "ccm.custdata.msg.confirmDelete" : "هل تريد بالتأكيد حذفه؟",
  "ccm.recognition.label.orderfail" : "فشل الاشتراك.",
  "ccm.recognition.label.closeVoiceNotificationSuccess" : "تم تعطيل ميزة الإعلام الصوتي بنجاح.",
  "ccm.certificate.management.certificatefile.typeerror" : "نوع الملف غير صحيح.",
  "ccm.agent.button.batchconfig" : "تكوين الحزمة",
  "ccm.speciallist.msg.transferout" : "وصل عدد السجلات التاريخية إلى {0}",
  "ccm.agent.restrecord.rest.beginTime" : "وقت بدء الراحة",
  "iolp.workflow.button.service" : "التعامل مع الأعمال",
  "ccm.agent.label.skillQueue" : "قائمة انتظار المهارات:",
  "ccm.agent.label.waitSkillWeight" : "أدخل وزن المهارة.",
  "ccm.dual.call.record.talkDuration" : "مدة المكالمة (بالثانية)",
  "ccm.agent.page.cancel.release" : "لم يتم إصدارها",
  "ccm.sysparam.config.checkmessage" : "يحتوي على أحرف خاصة. (\" < \"\, \" > \"\, \" / \", وما إلى ذلك)",
  "ccm.ucconfig.message.integration" : "تكامل Microsoft Teams",
  "ccm.ucconfig.message.info.alert": "الرجاء نسخ العنوان التالي إلى Microsoft لتسجيل التطبيق كـ URI لقبول إعادة توجيه المصادقة. انسخ نتيجة التسجيل واملأ معلومات طلب التسجيل التالية.",
  "ccm.ucconfig.message.copy": "نسخ",
  "ccm.ucconfig.message.register": "تسجيل معلومات التطبيق",
  "ccm.ucconfig.message.required.fields": "الحقول المطلوبة",
  "ccm.ucconfig.message.required.limit": "لا يمكن أن يتجاوز الطول 64 حرفًا.",
  "ccm.agent.contact.vidio" : "فيديو",
  "ccm.agent.message.skillweight.invalid" : "يجب أن يتراوح وزن المهارة من 1 إلى 100.",
  "ccm.agent.restrecord.reststatus" : "استراحة الدولة",
  "ccm.agent.contact.playRecording" : "تشغيل تسجيل",
  "ccm.agent.sysparamconfig.refreshAllSysParamSuccess" : "تم التحديث بنجاح.",
  "iolp.configur.service.label.serviceCode" : "رمز الخدمة",
  "iolp.workflow.executetype.subFlow" : "التدفق الفرعي",
  "ccm.login.record.noMatchingLoginRecordExists" : "لا يوجد سجل تسجيل دخول متاح.",
  "ccm.agent.button.clear" : "إعادة الضبط",
  "ccm.speciallist.msg.modify" : "تعديل",
  "ccm.agent.message.webRTCVerification" : "لا يمكن تكوين هذه المعلمة عند تمكين ميزة WebRTC.",
  "ccm.custdata.button.moveBottom" : "أسفل",
  "ccm.pageinterface.addObject" : "إضافة كائن",
  "ccm.satisfactionconfig.message.smsfeedbacktype" : "SMS",
  "ccm.transtask.label.reportData" : "تقرير البيانات",
  "ccm.recognition.label.appSecret.download" : "تحميل SK",
  "ccmanagement.tenantspace.option.weeklist_fthtolast" : "الأخير ولكن ثلاثة",
  "ccm.speciallist.button.finish" : "إنهاء",
  "ccm.recognition.label.voiceNotification" : "علامة الإخطار الصوتي",
  "ccm.callreason.label.disable" : "تعطيل",
  "ccm.agent.label.transfer" : "نقل",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputlength" : "يمكن أن يحتوي الحد الأدنى أو الحد الأقصى لمدة المكالمة على 12 رقمًا كحد أقصى.",
  "ccm.agent.button.unregister" : "إلغاء التسجيل",
  "ccm.custdata.label.importantTips" : "ملاحظة: يجب تشفير البيانات الشخصية أو البيانات الحساسة للتخزين وإخفاءها للعرض.",
  "ccm.transtask.label.taskedit" : "مهمة تفريغ الموارد",
  "ccm.agent.message.updateCtiAndDbSuccess" : "نجاح متزامن",
  "ccm.certificate.management.status.oper.reuse" : "إعادة الاستخدام",
  "ccm.skillgroup.title.update" : "تحديث المجموعة المختصة",
  "ccm.agent.label.busy" : "مهارة مشغول",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnumber" : "يجب أن تكون القيمة عددًا صحيحًا موجبًا.",
  "ccm.agent.label.restQueueRule.applyFirst" : "الوكيل الذي يطلب الراحة أولاً",
  "ccm.speciallist.msg.applyTime" : "وقت التطبيق",
  "ccm.agent.message.sysParamCofig.openVersatileAgentFail" : "تغيير نوع الموظف في صفحة إدارة الموظف. لا يمكن أن يكون عدد الموظفين متعددي الاستخدامات وموظفي الفيديو أكبر من الحد الأقصى لعدد موظفي الفيديو الذين يمكن للمستأجر الاشتراك بهم.",
  "ccm.agent.ten.ttschannelnums" : "TTS الكمية",
  "ccm.agent.calledroute.create.skill.none" : "قائمة انتظار المهارة المشار إليها بواسطة تكوين الطرف المطلوب غير موجودة.",
  "ccm.contact.detail.message.content" : "محتوى الرسالة",
  "ccm.transtask.label.datapath" : "مسار ملف التفريغ",
  "ccm.agent.label.numInRow" : "أدخل عددًا صحيحًا يتراوح من 2 إلى 4.",
  "ccm.custdata.option.isIndex.yes" : "نعم",
  "ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE" : "معلومات التسجيل فارغة.",
  "ccm.agent.label.maxWaitNum" : "الحد الأقصى للمكالمات في قائمة الانتظار",
  "iolp.configur.service.label.workflow" : "العملية الموجهة",
  "iolp.configur.service.label.directory.name": "اسم الفئة",
  "iolp.configur.service.add.parent.title": "اسم الفئة الأصلية",
  "ccm.certificate.management.status.oper.discard" : "تجاهل",
  "ccm.certificate.management.status.confirm.reuse": "تأكيد إعادة الاستخدام",
  "ccm.certificate.management.status.confirm.discard": "تأكيد التخلي",
  "ccm.agent.restReason.restCauseDesc.limit" : "يمكن أن يحتوي وصف سبب الراحة على 100 حرف كحد أقصى.",
  "ccm.agent.restReason.restCauseDesc.limit.form": "يمكن أن يحتوي وصف سبب الراحة على 100 حرف كحد أقصى",
  "ccm.agent.resetskillrecord.adjusttime" : "ضبط الوقت",
  "ccm.taskrecord.label.executeBeginTime" : "وقت البدء",
  "ccm.agent.contact.contactinfo" : "معلومات الاتصال",
  "ccm.satisfactionconfig.message.webfeedbacktype" : "ويب",
  "ccm.speciallist.msg.typeName" : "نوع قائمة خاص",
  "ccm.agent.label.queueTransferObj" : "نقل إلى نوع الكائن",
  "ccm.systemparam.error.save" : "فشل حفظ المعلمات.",
  "ccm.agent.leavemessage.process" : "عملية",
  "ccm.agent.pageurls.typenosupported" : "لا تدعم الوسائط المتعددة تكوين الصفحة الداخلي.",
  "ccm.agent.calledroute.refresh" : "تحديث",
  "ccm.agent.contact.SFTP_INCORRECT_FILE_CODE" : "معلومات خادم sftp غير صحيحة",
  "ccm.agent.calledroute.nmsconnect.fail" : "فشل الاتصال بـ NMS.",
  "ccm.agent.calledroute.accesscode" : "رمز الوصول",
  "ccm.contactitemconfig.label.data.item.name" : "اسم عنصر البيانات",
  "ccm.agent.label.thirtyonedays" : "31 يوم",
  "ccm.contactitemconfig.label.data.item.basicext" : "البيانات الموسعة الأساسية",
  "ccm.agent.message.agentConfSuccess" : "تم تكوين معلومات الموظف بنجاح.",
  "ccm.agent.operation.noMoreThan31Days" : "لا يمكن أن يتجاوز نطاق الوقت 31 يومًا.",
  "ccm.agent.message.createfailed" : "فشل إنشاء الموظف",
  "ccm.agent.calledroute.ccivrdata.error" : "فشل مزامنة البيانات مع NMS. بيانات عملية CC-IVR غير صحيحة.",
  "ccm.custdata.option.isEncrypt.yes" : "نعم",
  "ccm.satisfactionconfig.message.operate" : "العملية",
  "ccm.certificate.management.search.certcode" : "يرجى إدخال رمز الشهادة",
  "ccm.calledconfig.message.savemobileagentsuccess" : "تم حفظ تكوين الموظف المتنقل بنجاح",
  "ccm.agent.contact.callerPresent" : "رقم عرض الاتصال الخاص بالمستخدم",
  "ccm.agent.contact.resourcefail" : "<bpt i=\"1\" type=\"1\" />حدث خطأ أثناء تحميل التسجيل<ept i=\"1\" /><bpt i=\"2\" type=\"2\" />.<ept i=\"2\" />",
  "ccm.transtask.label.cancel" : "إلغاء",
  "casemanagement.user.button.ok" : "حسناً",
  "ccm.agent.message.sysParamCofig.closeVersatileAgentFail" : "تغيير نوع الموظف في صفحة إدارة الموظف. لا يمكن إقران موظف متعدد الاستخدامات بقائمة انتظار مهارات النقر لطلب الاتصال عبر الفيديو أو الفيديو.",
  "iolp.workflow.button.restart" : "إعادة تشغيل التدفق",
  "ccm.custdata.label.columnNo" : "لا",
  "ccm.agentparam.message.editfail" : "فشل التحرير.",
  "ccm.note.config.agent.page.ring.setUp" : "تكوين إعلام رسالة الصفحة",
  "ccm.transtask.label.chatrecordday" : "مدة تفريغ بيانات الدردشة (اليوم)",
  "ccm.transtask.label.intelligentInspection": "بيانات فحص الذكاء الاصطناعي",
  "ccm.callback.url.callback" : "تكوين رد الاتصال بالمكالمات ثنائية الاتجاه",
  "ccm.agent.ten.dstOff" : "معطل",
  "ccm.agent.restrecord.rest.endTime" : "وقت انتهاء الراحة",
  "ccm.agent.leavemessage.inputauditcomments" : "أدخل الاقتراحات.",
  "ccm.agent.label.mobileagentremark" : "ملاحظات",
  "ccm.transserver.label.obs" : "خادم OBS",
  "iolp.configur.servicelabel.confirm.deleteCurrent" : "هل تريد بالتأكيد حذف العقدة الحالية؟",
  "ccm.agent.contact.play" : "الصوت",
  "ccm.agent.contact.reset" : "إعادة الضبط",
  "ccm.agent.accesscode.n400number" : "رقم الخدمة",
  "ccm.agent.label.agentList" : "حساب الخدمة",
  "ccm.agent.tips.confFail" : "فشل التكوين",
  "ccm.agent.label.waitAgentWeight" : "أدخل وزن الموظف.",
  "ccm.contact.customer.config.type" : "بيانات منطقة معلومات الاتصال بالعميل",
  "ccm.satisfactionconfig.message.audiochannel" : "قناة الصوت أو الفيديو",
  "ccm.agent.operation.HOLD" : "انتظر",
  "ccm.transtask.label.reportedir" : "تفريغ تقرير",
  "ccmanagement.tenantspace.option.month_9" : "أيلول/سبتمبر",
  "ccmanagement.tenantspace.option.month_8" : "أغسطس",
  "casemanagement.user.title.account" : "الحساب",
  "ccmanagement.tenantspace.option.month_7" : "يوليو",
  "ccmanagement.tenantspace.option.month_6" : "يونيو",
  "ccm.agent.contact.download.frequently" : "حاولت تنزيله أيضا قد مرات. يُرجى إعادة المحاولة لاحقًا.",
  "ccmanagement.tenantspace.option.month_5" : "أيار/مايو",
  "ccmanagement.tenantspace.option.month_4" : "نيسان/أبريل",
  "iolp.configur.service.label.basicInfo" : "معلومات أساسية",
  "ccm.calledroute.button.cancel" : "إلغاء",
  "ccmanagement.tenantspace.option.month_3" : "مارس",
  "ccm.agent.calledroute.create.gateway.none" : "البرنامج النصي للتوجيه المشار إليه بواسطة تكوين الطرف المطلوب غير موجود.",
  "ccmanagement.tenantspace.option.month_2" : "فبراير",
  "ccm.urlconfig.label.xNumber" : "رقم X",
  "ccm.agent.pageurls.whitelisturl" : ". الرجاء الاتصال بمسؤول النظام لإضافة عنوان url إلى قائمة الثقة.",
  "ccmanagement.tenantspace.option.month_1" : "يناير",
  "ccm.transtask.label.thurs" : "الخميس",
  "ccm.agent.contact.oircall" : "الرقم المطلوب الأصلي",
  "ccm.agent.message.sysParamCofig" : "فشل تكوين معلمات النظام.",
  "ccm.satisfactionconfig.message.duplicateconfig" : "يجب أن تكون أولوية كل سياسة لنفس القناة فريدة من نوعها.",
  "ccm.custdata.button.withdraw" : "سحب",
  "ccm.callreason.label.mark" : "ملاحظات",
  "ccmanagement.satisfaction.strategy.accepttime.timeStart" : "وقت البدء",
  "ccm.agent.updateSoftPhone.updateFailed" : "فشل تغيير كلمة المرور",
  "ccm.contactitemconfig.label.data.extended.Type.DataDictionary" : "قاموس البيانات",
  "ccm.agent.label.skillgroup" : "المجموعة المختصة",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.yes" : "نعم",
  "ccm.create.case.confirm" : "نوع الحالة فارغ. عند إنشاء حالة\، يجب تحديد نوع الحالة يدويًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "ccm.agent.recall.agent.status.invalid" : "الموظف ليس في حالة خامل أو مشغول ولا يمكنه معاودة الاتصال.",
  "ccm.certificate.management.scenename" : "وصف الشهادة",
  "ccm.agent.operation.UNHOLD" : "إلغاء الحجز",
  "iolp.configur.servicelabel.save.successful" : "تم الحفظ بنجاح.",
  "ccm.agent.Transfer.tranfermode" : "وضع النقل",
  "ccm.agent.pageurls.edit" : "تحرير",
  "ccm.label.contactexport.selectall" : "الكل",
  "ccm.contactitemconfig.message.noBaseData" : "لا تتوفر بيانات أساسية",
  "ccm.agent.label.queuingCallsMaxNumRange" : "القيمة [0-10000]",
  "ccm.systemparam.success.refresh" : "تم تحديث المعلمة بنجاح.",
  "ccm.agent.contact.multimedia.twitter" : "الوسائط المتعددة-تويتر",
  "ccm.transtask.label.dumpintervalInitial" : "تفريغ المدة ابدأ",
  "ccm.agent.ten.DSTEndDate" : "تاريخ انتهاء DST",
  "ccm.agent.operation.CALLINNER" : "مكالمة داخلية",
  "ccm.ucconfig.message.failed" : "فشل",
  "ccm.agent.label.ccRoleId" : "دور المنصة",
  "ccm.agent.mobileagent.label.nodata" : "لم يتم العثور على بيانات مطابقة!",
  "iolp.workflow.executetype.case" : "إنشاء حالة",
  "iolp.configur.servicelabel.contact.administrator" : "فشل إنشاء رمز الخدمة. يرجى الاتصال بالمسؤول.",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkRepeat" : "هناك تكرار في القسم المحدد!",
  "ccm.speciallist.msg.view" : "عرض",
  "ccm.agent.label.signedinAgentsPercentage" : "النسبة المئوية للوكلاء المسجلين",
  "ccm.speciallist.button.add" : "إضافة",
  "ccm.levelMessage.title.add": "إنشاء إدارة المستوى",
  "ccm.speciallist.title.add": "إنشاء قائمة خاصة",
  "ccm.speciallistreminder.button.add": "إنشاء تذكير بقائمة خاصة",
  "ccm.certificate.management.certificatefile.fileempty" : "الملف فارغ.",
  "ccm.certificate.management.certificate.crlfile.crlfileinvalid" : "تنسيق ملف CRL غير صحيح.",
  "ccm.agent.label.ifBusyNoAnswerAgent" : "تعيين الموظفين الذين لا يجيبون لفترة طويلة إلى الحالة مشغول",
  "ccm.agent.label.asyncUserDispatchMode": "شرط استرداد الرسالة دون اتصال",
  "ccm.agent.label.asyncUserDispatchMode.zero": "حسب عدد الدُفعات",
  "ccm.agent.label.asyncUserDispatchMode.one": "حسب عدد الدُفعات والمهارة",
  "ccm.agent.label.asyncUserDispatchMode.two": "حسب عدد الدُفعات والحالة على الإنترنت",
  "ccm.agent.label.asyncUserDispatchMode.three": "حسب عدد الدُفعات والمهارة والحالة على الإنترنت",
  "ccm.agent.label.asyncUserDispatchMode.four": "حسب عدد الدفعات والوكيل والمهارة والحالة عبر الإنترنت",
  "ccm.skillgroup.message.groupBind" : "لا يمكن تحديد المهارة الحالية لنوع العامل المرتبط بالمجموعة المختصة.",
  "ccm.agent.message.selected" : "لقد حددت ",
  "ccm.satisfactionconfig.message.channelused" : "تمت الإشارة إلى مجموعة النهج بواسطة قناة ولا يمكن حذفها.",
  "iolp.configur.service.label.upperLevelServiceCode" : "رمز خدمة المستوى الأعلى",
  "ccm.agent.button.search" : "استعلام",
  "ccm.agent.message.updateCtiAndDbFail" : "فشل تحديث cti وDb.",
  "iolp.workflow.button.showFlowChart" : "إظهار المخطط الانسيابي",
  "ccm.agent.operation.searchcountid" : "حساب الخدمة",
  "ccm.satisfactionconfig.satisfaction.process" : "تدفق استبيان الرضا",
  "ccm.speciallist.button.cancel" : "إلغاء",
  "ccm.note.config.notename" : "الاسم",
  "ccm.contact.details.tab.title.audio" : "صوت",
  "ccm.agent.label.maxTalkTimeRange" : "القيمة [1-86400]",
  "ccm.pageinterface.inputInterfaceName" : "أدخل",
  "ccm.transtask.label.datapath.tips" : "مسار OBS",
  "ccm.transtask.label.datacondition" : "معايير تصفية البيانات",
  "ccm.agent.skill.nameInfo" : "اسم قائمة انتظار المهارة:",
  "ccm.agent.message.querycenterfailed" : "فشل الاستعلام عن الموظف الذكي لمركز المكالمات.",
  "ccm.dual.call.record.statistics" : "إحصائيات",
  "ccmanagement.satisfaction.strategy.hourvalidate" : "لا يمكن أن تكون الساعة أكبر من 24 ساعة.",
  "ccm.agent.operation.WHISPER" : "الهمس",
  "ccm.page.label.pageInput" : "أدخل اسم صفحة",
  "ccm.agent.label.keepVoiceConfig" : "حفظ التكوين وانتظاره",
  "ccm.agent.contact.multimedia.instagram" : "الوسائط المتعددة-Instagram",
  "ccm.botherlist.label.contactrecorddetail" : "تفاصيل الاتصال",
  "ccm.speciallist.msg.confirmdeletehis" : "هل تريد بالتأكيد حذف محفوظات القائمة الخاصة المحددة؟",
  "ccm.speciallist.msg.besureexport" : "هل تريد بالتأكيد تصدير كافة البيانات؟",
  "ccm.agent.contact.accountname" : "اسم الحساب",
  "ccm.calledroute.tip.selectivrexception" : "فشل الاستعلام عن تدفقات IVR في CTI.",
  "ccm.agent.calledroute.refreshsuccess" : "تم التحديث بنجاح.",
  "ccm.agent.contact.resetAll" : "الكل إعادة تعيين",
  "ccm.agent.label.versatileagent" : "وكيل متعدد الاستخدامات",
  "ccm.agent.calledroute.create.accesscode.repeat" : "يجب أن يكون الجمع بين رمز الوصول إلى النظام ورمز الإضافة للطرف المطلوب فريدًا.",
  "ccm.chatbot.label.assist" : "مساعد روبوت",
  "iolp.configur.service.label.service.title" : "الخدمة",
  "iolp.run.service.label.customizedPage": "معلومات التخصيص",
  "iolp.run.service.label.overview": "نظرة عامة على العملية",
  "iolp.run.service.label.nodeName": "اسم العقدة:",
  "ccm.satisfactionlevel.message.nopara" : "لا يمكن أن يكون الوصف فارغًا.",
  "ccm.speciallist.msg.exists" : "تم تضمين المستخدم المحدد بالفعل في القائمة الخاصة",
  "ccm.agent.sysparamconfig.resetAllParamsFail" : "ملف تكوين المعلمة مفقود. الرجاء التحقق.",
  "ccm.urlconfig.message.overlimit.appSecret" : "لا يمكن أن يتجاوز المفتاح السري الذي تم تكوينه لمكالمات ITA ثنائية الاتجاه 512 حرفًا.",
  "ccm.verifyidentity.noMoreThan30Days" : "لا يمكن أن يتجاوز نطاق الوقت 30 يومًا.",
  "ccm.callout.lable.default" : "القيمة الافتراضية",
  "ccm.agent.speciallist.validityPeriod": "الفترة الفعلية",
  "ccm.agent.speciallist.begintime" : "الوقت الفعلي",
  "ccm.agent.label.validTime" : "مدة صلاحية كلمة المرور (أيام)",
  "ccm.recognition.label.appSecret.reset" : "إعادة تعيين SK",
  "ccm.policy.label.updatesucess" : "تم تحديث معلومات النهج بنجاح.",
  "ccm.dual.call.record.normal.end" : "نهاية عادية",
  "ccm.agent.label.mobileagententer" : "أدخل",
  "ccm.sysparam.config.agent.title" : "معلمات الموظف",
  "ccm.agent.agentcontact.error" : "فشل الاستعلام",
  "ccm.speciallist.label.line" : "خط",
  "ccm.agent.message.addSkillFail" : "فشلت إضافة مهارة.",
  "ccm.agent.message.addSkillSuccess": "تمت إضافة قائمة انتظار المهارة بنجاح",
  "ccm.agent.message.maxSkillFail": "ى 100 عنصر كحد أقصى",
  "ccm.note.config.failed" : "فشل تكوين الملاحظة",
  "ccm.agent.label.answerType" : "نوع الإجابة",
  "ccm.agent.message.modifySkillConfSuccess" : "تم تعديل معلومات قائمة انتظار المهارة بنجاح.",
  "ccm.agent.operation.REST" : "طلب الراحة",
  "ccm.custdata.msg.operationFailed" : "فشلت العملية.",
  "ccm.agent.updateSoftPhone.newPasswordFailed" : "فشل التحقق من كلمة المرور الجديدة.",
  "ccm.speciallevel.speciallist.remark" : "ملاحظة",
  "ccm.agent.Transfer.devicetype" : "نوع الجهاز الوجهة",
  "ccm.sysparam.config.itemname.tips" : "أدخل اسم معلمة.",
  "ccm.speciallist.msg.operationsuccess" : "تمت العملية بنجاح",
  "ccmanagement.satisfaction.strategy.fail.calltime.overlimit" : "يمكن حفظ 100 سياسة مدة مكالمة كحد أقصى.",
  "ccmanagement.tenantspace.option.weekday_thu" : "الخميس",
  "ccm.agent.calledroute.pagename" : "اسم الصفحة",
  "ccm.custdata.option.status.abandon" : "تجاهل",
  "ccm.transserver.label.cssproxynotinwhite" : "فشلت العملية. اتصل بمسؤول النظام لإضافة الملقم الوكيل \,العنوان إلى قائمة ثقة الوكيل الخاصة بملقم CSS.",
  "ccm.speciallist.msg.selectonly" : "يمكن اختيار قائمة خاصة واحدة فقط للتعديل",
  "ccm.agent.calledroute.confdelete" : "تأكيد الحذف",
  "ccm.agent.operation.SAYBUSY" : "مشغول",
  "ccm.custdata.label.valueRange" : "نطاق القيمة",
  "ccm.anonymous.message.fail.delete" : "فشل حذف المسار المطلوب. يُرجى التحقق من تكوين CTI",
  "ccm.agent.message.agentandskillnotmatch" : "يجب أن يتطابق نوع المهارة مع نوع الموظف",
  "iolp.workflow.label.currentNodeName" : "اسم العقدة الحالية",
  "ccm.agent.contact.audio-visual" : "الصوت/الفيديو",
  "ccm.dual.call.record.option" : "العملية",
  "ccm.satisfactionconfig.message.smscloud" : "سحابة هواوي SMS",
  "ccm.pageurls.select.internalInterface" : "الصفحة الداخلية",
  "iolp.label.basicInfo" : "معلومات أساسية",
  "ccm.speciallist.msg.endtimeerror" : "يجب أن يكون وقت انتهاء الصلاحية بعد الوقت الحالي",
  "ccm.custdata.operlog.confirmMessage" : "هل تريد بالتأكيد إنشاء مهمة تصدير جديدة؟",
  "ccm.agent.label.threedays" : "3 أيام",
  "ccm.agent.message.description.limit" : "وصف قائمة انتظار المهارة طويل جداً",
  "ccm.agent.message.existAIAgent" : "لا يمكن تمكين التعرف الذكي والتسجيل ثنائي المسار في نفس الوقت\، في حين تم تمكين التعرف الذكي لبعض العوامل ليتم تعديلها على دفعات.",
  "ccm.agent.restReason.restCauseTime" : "مدة الراحة",
  "ccm.agent.calledroute.delsuccess" : "تم الحذف بنجاح.",
  "ccm.dual.call.record.callCompletionRate" : "معدل إتمام المكالمة",
  "ccm.common.label.yes" : "نعم",
  "ccm.satisfactionconfig.message.accepttime" : "وقت المعالجة",
  "ccm.agent.label.off" : "إيقاف التشغيل",
  "ccm.speciallist.msg.confirmdelete" : "هل تريد بالتأكيد حذف القائمة الخاصة المحددة؟",
  "ccm.agent.contact.multimedia.fiveG" : "Multimedia-5G RCS",
  "ccm.custdata.msg.fileNameValidateFail" : "اسم الملف غير صحيح. حدد ملفًا يحتوي اسمه على أحرف صينية فقط\، أو حروف صغيرة\، أو أرقام\، أو سفلة سفلية (_).",
  "ccm.agent.label.busyRateSuffix" : "، لا يمكن تحويل المكالمات إلى قائمة الانتظار.",
  "ccm.agent.leavemessage.status.unprocessed" : "غير المجهزة",
  "ccm.agent.mobileagent.syserror" : "فشل تمكين التعرف الذكي. اتصل بمسؤول النظام للتحقق من تكوينات البيئة.",
  "ccm.agent.ten.tenantId" : "TenantId",
  "ccm.contactitemconfig.label.data.item.category" : "نوع البيانات",
  "ccm.agent.sysparamconfig.checkWebhookValidityDays" : "يجب أن تكون شهادة Webhook صالحة بين 7 و 365 يومًا",
  "ccm.ccagent.management.advanced.search" : "البحث المتقدم",
  "ccm.agent.sysparamconfig.refreshAllSysParamFail" : "خطأ في النظام\، فشل التحديث.",
  "ccm.recognition.label.modifymobileagentclose" : "لم يتم تمكين ميزات وكيل الجوال والمكالمة ثنائية الاتجاه بنقرة واحدة لمساحة المستأجر!",
  "ccm.agent.operation.CONFERENCE" : "مؤتمر ثلاثي الأطراف",
  "ccm.vdn.label.index" : "أشهر",
  "iolp.configur.servicelabel.confirm.delete" : "حذف",
  "ccm.custdata.option.maskStyle.maskAll" : "قناع كل الأرقام",
  "ccm.callreason.label.createrelatecase" : "ربط الحالات",
  "ccm.satisfactionconfig.sms.switch.remark" : "بعد تشغيل هذا التبديل\، سيتم إرسال رسالة نصية لاستطلاع الرضا إلى العميل بعد انتهاء المكالمة",
  "ccm.contact.detail.talk.content.seachtips" : "نتائج البحث: {num}",
  "ccm.satisfactionconfig.message.creategroup" : "إضافة مجموعة نهج",
  "ccm.agent.ten.allowedTTSAndASRCallNum" : "عدد قنوات IVR الذكية",
  "ccm.agent.message.searchsoftphone" : "أدخل رقم الهاتف.",
  "ccm.skillgroup.title.add" : "إضافة مجموعة مختصة",
  "ccm.agent.message.agentnumcannotmuchthanmax" : "لا يمكن أن يتجاوز عدد نوع العامل الهدف الحد الأقصى",
  "ccm.skillgroup.update.fail" : "فشل تحديث المجموعة المختصة.",
  "ccm.recognition.label.openVoiceNotificationSuccess" : "تم تمكين ميزة الإعلام الصوتي بنجاح.",
  "ccm.agent.calledroute.extcode" : "رمز التمديد",
  "ccm.urlconfig.label.oauth.oauth2loginurl.illegal" : "عنوان URL لتسجيل الدخول غير صالح.",
  "ccm.agent.pageurls.updatecall" : "تعديل شاشة المكالمة الواردة المنبثقة",
  "ccm.agent.contact.assistantCallid" : "معرف مكالمة تعاونية",
  "ccm.transtask.label.cdrData" : "بيانات CDR",
  "ccm.transtask.delete.confirm" : "هل تريد بالتأكيد حذف مهمة التفريغ وسجلات المهام القديمة؟",
  "ccm.contact.detail.message.sending.record" : "سجل إرسال الرسائل",
  "ccm.sysparam.error.validate.notmatchruledigits" : "الرجاء إدخال عدد صحيح موجب صحيح",
  "ccm.contact.customer.config.underline" : "تحت خط",
  "ccm.agent.restReason.operation" : "العملية",
  "ccm.agent.contact.mediaType" : "نوع الوسائط",
  "ccm.agent.label.serverAddress" : "عنوان خادم التسجيل",
  "ccm.agent.contact.multimedia.web" : "الوسائط المتعددة-الويب",
  "ccm.agent.operation.ADJUSTSKILL" : "ضبط قائمة انتظار المهارات",
  "ccm.agent.message.willDeleteAgents" : "هل تريد بالتأكيد حذف الموظف المحدد؟",
  "ccm.agent.button.config" : "تكوين",
  "ccm.download.passwd.validator.specialStr" : "يُسمح فقط بالأحرف الخاصة التالية: _{'@'}{'%'}",
  "ccm.agent.message.selectMaxAgents" : "يمكنك معالجة 20 كحد أقصى في كل مرة.",
  "ccm.custdata.option.maskStyle.maskDate" : "قناع التاريخ",
  "ccm.agent.contact.preCallout" : "معاينة مكالمة صادرة",
  "ccm.agent.button.restores" : "استعادة",
  "ccm.agent.updateSoftPhone.reason" : "السبب",
  "ccm.agent.contact.audioclicktodial" : "صوت انقر للاتصال",
  "ccm.agent.tips.inputpassword" : "أدخل كلمة مرور حساب تسجيل الدخول الحالي.",
  "ccm.agent.leavemessage.status.solved" : "تمت المعالجة",
  "ccmanagement.satisfaction.strategy.fail.unknown" : "فشل الحفظ.",
  "ccm.agent.label.transferType" : "طريقة العملية",
  "ccm.verifyidentity.fail.USERIDPARSEERROR" : "لا يمكن أن يحتوي رقم العميل على أحرف خاصة.",
  "ccm.agent.label.restQueueRule" : "سياسة وضع قائمة انتظار الراحة",
  "ccm.agent.operation.failCode" : "رمز سبب الفشل",
  "ccm.agent.ten.DSTStartDate" : "DST تاريخ البدء",
  "ccm.agent.restrecord.reststatus.timeout.endrest" : "انتهت مهلة الراحة",
  "ccm.agent.label.audio" : "وكيل الصوت",
  "ccm.custdata.option.isIndex.no" : "لا",
  "ccm.transtask.label.enable" : "تمكين",
  "ccm.satisfactionlevel.message.satislevel" : "مستوى الرضا",
  "ccm.agent.message.searchSkillName" : "أدخل اسم قائمة انتظار المهارة.",
  "ccm.agent.restReason.partDeleteFailed" : "فشل الحذف الجزئي.",
  "ccm.agent.label.timeRemaining" : "انتهاء صلاحية كلمة المرور (يوم)",
  "ccm.skill.placeholder.skilldesc" : "الرجاء إدخال اسم قائمة انتظار مهارة.",
  "ccm.urlconfig.message.addfail" : "فشل إضافة عناوين urls",
  "ccm.certificate.management.certificatefile.parseerror" : "فشل تحليل الشهادة. تحقق مما إذا كان تنسيق الشهادة أو كلمة المرور صحيحين.",
  "ccm.agent.login.LOGIN" : "تسجيل الدخول",
  "ccm.custdata.operlog.downloadTemplate" : "تنزيل قالب الجدول الأساسي",
  "ccm.sysparam.error.validate.ruleregexinvalid" : "خطأ في بناء جملة القاعدة بالتحقق من صحتها",
  "ccm.contactitemconfig.label.data.item.basic" : "البيانات الأساسية",
  "ccm.speciallist.msg.selectspeciallist" : "يرجى اختيار قائمة خاصة",
  "ccm.speciallist.msg.selectspeciallisthis": "الرجاء تحديد محفوظات قائمة خاصة",
  "ccm.custdata.msg.inputTableName" : "أدخل اسم جدول.",
  "ccm.recognition.label.intellrecogn" : "التعرف الذكي",
  "ccm.contact.customer.config.preview" : "معاينة",
  "ccm.contact.placeholder.searchCustomerNo" : "رقم الاتصال",
  "iolp.configur.servicelabel.common.tips.error" : "خطأ",
  "ccm.botherlist.label.approveComment.agree" : "أوافق",
  "ccm.querycustdata.msg.inputIndexData" : "يُرجى الإدخال",
  "ccm.custdata.label.columnType" : "نوع الحقل",
  "ccm.satisfactionconfig.message.webchannel" : "قناة الوسائط المتعددة على شبكة الإنترنت",
  "ccm.agent.operation.FORCELOGOUT" : "خروج بالقوة",
  "ccm.agent.message.selectSkills" : "يرجى تحديد قائمة انتظار مهارة.",
  "ccm.agent.label.skillId" : "معرف قائمة انتظار المهارات",
  "ccm.agent.calledroute.editsuccess" : "تم التحرير بنجاح.",
  "ccm.speciallist.msg.specialchar" : "لا يمكن أن تحتوي رسالة التذكير على أحرف خاصة.",
  "ccm.agent.label.createWorkOrdeType" : "نوع الحالة المرتبطة",
  "ccm.idauth.title.idauthconfig" : "عملية مصادقة الهوية",
  "ccm.verifyidentity.fail.INPUTNULLERROR" : "لا يمكن أن تكون معلمات الاستعلام فارغة.",
  "ccm.skill.label.skillname" : "اسم قائمة انتظار المهارة",
  "ccm.certificate.management.update.certfile" : "تحديث ملف الشهادة",
  "ccm.certificate.management.add.title": "إضافة شهادة",
  "ccm.certificate.management.update.title": "تحديث الشهادات",
  "ccm.transtask.label.ivrAccessCodeReport" : "تقرير إحصاءات بيانات IVR حسب رمز الوصول",
  "ccm.callback.url.auth.tip" : "وضع مصادقة عنوان URL لإعادة الاتصال",
  "ccm.agent.operation.noMoreThan3Days" : "لا يمكن أن يتجاوز نطاق الوقت 3 أيام.",
  "ccm.contactitemconfig.label.data.item.extended" : "بيانات الامتداد",
  "ccm.note.config.agent.page.ring.voice" : "إشعار مكالمة الصوت والفيديو الواردة",
  "ccm.satisfactionconfig.message.strategygroupsrv" : "تكوين قناة سياسة استبيان الرضا",
  "ccm.nms.satisfactionservice.buttonNumber" : "عدد مفاتيح استطلاع الرضا لا يزيد عن 5 ولا يقل عن 3.",
  "ccm.callreasonconfig.message.deletecallreason.used" : "فشل الحذف\، تم استخدام سبب المكالمة.",
  "ccm.transtask.label.certTip.proxyCert" : "الرجاء تحديد شهادة خادم وكيل.",
  "ccm.agent.leavemessage.status.toBeAssigned" : "سيتم تخصيصها",
  "ccm.contactitemconfig.label.data.extended.dataset.name" : "اسم مجموعة البيانات",
  "ccm.satisfactionconfig.message.strategygroup" : "مجموعة سياسات استبيان الرضا",
  "ccm.note.config.configsuccess" : "نجاح تكوين الملاحظة.",
  "ccm.agent.contact.transfercontent" : "محتوى ASR",
  "ccm.agent.contact.callserialno" : "الرقم التسلسلي للاتصال",
  "ccm.agent.password.agentNull" : "معلومات الموظف فارغة.",
  "ccm.transserverparam.label.info" : "معلومات أساسية",
  "ccm.agent.label.selectSkillGroup" : "تحديد مجموعة المهارات",
  "ccm.ivr.label.processid" : "معرف العملية",
  "ccm.agent.operation.HELP" : "استشارة",
  "ccm.agent.leavemessage.cannotBeModified" : "لا يمكن تعديل الرسالة في الحالة الحالية.",
  "ccm.agent.mobileagent.label.deletecomplete" : "اكتمل حذف الموظف!",
  "ccm.custdata.msg.fileSizeValidateFail" : "حدد ملفًا أقل من 20 ميغابايت.",
  "ccm.satisfactionconfig.message.numberlimit" : "يُسمح بحد أقصى 100 تكوين لقناة استطلاع الرضا في مساحة المستأجر.",
  "ccm.agent.contact.voiceVidio" : "الصوت/الفيديو",
  "ccm.agent.label.preVoiceSelect" : "معرّف الصوت قبل الموظف",
  "iolp.configur.servicelabel.button.publish" : "تم النشر",
  "ccm.speciallevel.speciallist.use" : "متوفر",
  "ccm.custdata.label.maskStyle" : "نمط قناع",
  "ccmanagement.tenantspace.option.weeklist_tid" : "الثالث",
  "ccm.transtask.label.cantsave" : "لا يمكن تعديل الملقم لأنه تمت الإشارة إليه بواسطة مهمة تفريغ.",
  "ccm.vcallcenter.config.popup.authentication" : "المصادقة",
  "ccm.recognition.label.modifymobileagentfailed" : "فشل تعديل حالة موظف الجوال.!",
  "ccm.agent.message.agentConfFail" : "فشل تكوين معلومات الموظف.",
  "ccm.satisfactionlevel.message.leastcalltype" : "يرجى تحديد نوع مكالمة واحد على الأقل.",
  "ccm.transtask.label.cantDelete" : "لا يمكن حذف الملقم لأنه مشار إليه مسبقاً بواسطة مهمة تفريغ.",
  "ccm.agent.contact.subtype.none" : "لا شيء",
  "ccm.note.config.agent.page.ring.media" : "إعلام بالمكالمة الواردة عبر الوسائط المتعددة",
  "ccm.certificate.management.certificatefile.suffixerror.suffix" : "أو امتداد فارغ.",
  "ccm.agent.contact.clicktodial" : "انقر للاتصال",
  "ccm.agent.title.agentEdit" : "تكوين معلومات الموظف",
  "ccm.agent.label.waitVoiceCustom" : "تخصيص نغمة الانتظار",
  "ccm.recognition.label.appSecret.download.SK.only.once.error" : "إذا نسيت المفتاح\، فقم بإعادة تعيين المفتاح وتنزيله مرة أخرى.",
  "ccm.agent.label.addChildReason" : "يجب ألا تتجاوز إعدادات السبب المستوى 6",
  "ccmanagement.tenantspace.option.weekday_wed" : "الأربعاء",
  "ccm.agent.softPhoneStatus.UnRegistered" : "غير مسجل",
  "ccm.custdata.msg.tableBeUsed" : "تتم الإشارة إلى الجدول المخصص بواسطة كائن صفحة تكوين. تأكد من عدم الإشارة إلى الجدول قبل إجراء هذه العملية.",
  "ccm.agent.message.omsAgentTypeCheck" : "لا يتطابق الدور المحدد مع نوع الموظف لموظف متصل.",
  "ccm.label.contactexport.currentfield" : "حقل في القائمة الحالية",
  "ccm.skill.label.maxwaitnum" : "الحد الأقصى للمكالمات في قائمة الانتظار",
  "ccm.agent.label.isaireconition" : "صحيح",
  "ccmanagement.satisfaction.strategy.acceptdate.dateStart" : "تاريخ البدء",
  "iolp.workflow.executetype.auto" : "السيارات",
  "ccm.agent.ten.maxAgents" : "وكلاء الصوت",
  "ccm.dual.call.record.agentTalkingTime" : "وقت الرد على الطرف المتصل",
  "ccm.sysparam.config.value" : "قيمة المعلمة",
  "ccm.agent.message.videoNotEnough" : "عدد موارد الفيديو غير كافٍ لأن كلاً من وكلاء الفيديو الذين تم تكوينهم باستخدام قوائم انتظار مهارات الفيديو يشغلون موارد الفيديو. تخصيص موارد الفيديو بشكل صحيح.",
  "ccm.agent.ten.anonymousSipInfo" : "عنوان IP ورقم منفذ خادم المكالمات المجهولة",
  "ccm.agent.contact.begintime" : "وقت البدء",
  "ccm.agent.pageurls.successinfo" : "تم الإنشاء بنجاح.",
  "ccm.agent.pageurls.successinfo.noPoint" : "تم الإنشاء بنجاح",
  "ccm.calledconfig.table.devtype" : "الغرض نوع الجهاز",
  "ccm.agent.calledroute.createcalledroute" : "جديد",
  "ccm.agent.pageurlparam.relSysParamType" : "نوع معلمة بيانات النظام المقترنة",
  "ccm.urlconfig.label.oauth.sk" : "SK",
  "ccmanagement.satisfaction.strategy.fail.accepttime.overlimit" : "يمكن حفظ 100 سياسة وقت معالجة كحد أقصى.",
  "ccm.transtask.label.status" : "الحالة",
  "ccm.agent.label.authType" : "وضع المصادقة",
  "ccm.agent.button.restSoftPhonePwdError" : "ربط وكيل.",
  "ccm.agent.message.ctipasswdless" : "لا يمكن أن يكون طول كلمة المرور أقل من 8.",
  "ccm.agent.label.maxWaitTime" : "الحد الأقصى لوقت الانتظار (ث)",
  "ccm.certificate.management.certificate.crlfile.sizeerror" : "لا يمكن أن يتجاوز ملف CRL 2048 كيلوبايت.",
  "ccm.certificate.management.certificatefile.certcodeexist" : "رمز الشهادة موجود بالفعل.",
  "ccm.callreason.label.selectedcallreason" : "سبب المكالمة المحدد",
  "ccm.agent.contact.downloadvideo" : "تحميل الفيديو",
  "ccm.note.config.agent.page.ring" : "نغمات الرنين",
  "ccm.agent.label.oneday" : "يوم واحد",
  "ccm.calledconfig.label.querysoftnum" : "عرض أرقام الهاتف اللينة",
  "ccm.transserverparam.label.cssClusterPass" : "كلمة المرور",
  "ccm.agent.label.waitTimeRange" : "القيمة [0-9999]",
  "ccm.calledconfig.message.duplicatecalled" : "تكرار تم استدعاءه",
  "ccm.pageurls.select.independently" : "مفتوحة بشكل مستقل",
  "ccm.agent.operation.CANCELLISTEN" : "إلغاء الاستماع",
  "ccm.sysparam.error.validate.notmatchrulemaxlength" : "الحد الأقصى لطول المعلمة",
  "ccm.agent.operation.SWITCH" : "التبديل",
  "ccm.calledconfig.label.add" : "إضافة",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnull" : "لا يمكن أن يكون الحد الأدنى أو الحد الأقصى لمدة المكالمة فارغًا.",
  "ccm.agent.message.existDualchannelrecAgent" : "لا يمكن تمكين التعرف الذكي والتسجيل ثنائي المسار في نفس الوقت\، في حين تم تمكين التسجيل ثنائي المسار لبعض الوكلاء ليتم تعديلها على دفعات.",
  "ccm.transserverparam.label.cssClusterUser" : "CssClusterUser",
  "ccmanagement.tenantspace.option.weekday_mon" : "الاثنين",
  "ccm.dual.call.record.dualCallType" : "نوع المكالمة المزدوجة",
  "ccm.contactitemconfig.label.data.item.sequence.number" : "لا ..",
  "ccm.agent.contact.multimedia" : "الوسائط المتعددة",
  "ccm.custdata.label.sucNum" : "عدد السجلات الناجحة",
  "iolp.configur.servicelabel.save.failed" : "فشل الحفظ.",
  "ccm.certificate.management.certificatefile.certtypeerror" : "نوع الشهادة خطأ.",
  "ccm.accesscode.label.index" : "لا ..",
  "iolp.workflow.node.label.explain" : "الشرح",
  "ccm.agent.label.Account" : "الحساب",
  "ccm.custdata.msg.uploadSelect" : "حدد ملفًا.",
  "ccm.speciallist.msg.crateerrorreason" : "رقم المشترك موجود بالفعل",
  "ccm.urlconfig.message.overlimit.xNumber" : "لا يمكن أن يتجاوز الرقم X المكون لمكالمات ITA ثنائية الاتجاه 20 حرفًا.",
  "ccm.speciallist.label.msgContent" : "رسالة تذكير",
  "ccm.agent.message.configvalidatefailed" : "فشل التحقق من صحة عنصر تكوين العبّارة",
  "ccm.taskrecord.label.executeStatus" : "حالة المهمة",
  "ccm.agent.leavemessage.workNo" : "وكيل",
  "ccm.pageinterface.optionalAttribute" : "سمة اختيارية",
  "ccm.transserver.delete.fail" : "فشل حذف خادم التفريغ.",
  "ccm.dual.call.record.startTime" : "الوقت الذي تبدأ فيه المكالمة",
  "iolp.workflow.title.name" : "عملية الأعمال",
  "ccm.agent.operation.ID" : "رقم العملية",
  "ccm.custdata.option.maskStyle.maskEmail" : "قناع البريد الإلكتروني",
  "ccm.verifyidentity.userId" : "رقم العميل",
  "ccm.urlconfig.message.missing.appKeyOrDomainName" : "يجب تعيين مفتاح التطبيق واسم المجال في تكوين مكالمة ITA ثنائية الاتجاه.",
  "ccm.agent.button.restSoftPhonePwd" : "إعادة تعيين كلمة مرور الهاتف المبرمج",
  "ccm.agent.pageurlparam.channelassociateddata" : "البيانات المرتبطة بالقناة",
  "ccm.agent.leavemessage.overtime" : "انتهت مهلة المعالجة. يرجى فتح النافذة المنبثقة مرة أخرى.",
  "ccm.agent.contact.others-channels" : "قنوات أخرى",
  "ccm.agent.pageurls.confreset" : "تأكيد إعادة الضبط",
  "ccm.idauth.title.create" : "إضافة عملية مصادقة الهوية",
  "ccm.agent.contact.invalid" : "الإبطال",
  "ccm.agent.restReason.restCauseCode" : "رمز سبب الراحة",
  "ccm.agent.sysparamconfig.worknowarn" : "استخدم الفواصل (\,) لفصل معرفات الموظف.",
  "ccm.agent.message.updateagentandskillfail" : "فشل تحديث الاقتران بين الموظف وقائمة انتظار المهارة.",
  "ccm.sysparam.config.refresh" : "تحديث",
  "ccm.agent.operation.FORCEDROPCALL" : "الإفراج القسري",
  "ccm.transserver.label.cssnotinwhite" : "فشلت العملية. اتصل بمسؤول النظام لإضافة عنوان خدمة CSS إلى قائمة ثقة خادم CSS لتفريغ الموارد.",
  "ccm.vdn.label.defaultvoice" : "الافتراضي",
  "ccm.agent.leavemessage.assign" : "تعيين",
  "ccm.agent.leavemessage.title.assign": "تعيين الرسائل",
  "ccm.agent.calledroute.dnid" : "معرف التكوين",
  "ccm.agent.message.deleteSkillConfSuccess" : "تم حذف معلومات قائمة انتظار المهارة بنجاح.",
  "ccm.agent.label.maxAgentWrapupTime" : "القيمة [0–3600]",
  "ccm.agent.input.userNumber" : "رقم المستخدم",
  "ccm.urlconfig.label.oauth.label" : "معلومات ترخيص OAuth 2.0",
  "casemanagement.user.title.select" : "تحديد",
  "ccm.agent.button.cancel" : "إلغاء",
  "ccm.contact.customer.config.datasource.objName" : "حقل تخطيط كائن مصدر البيانات",
  "ccm.satisfactionconfig.message.memberstrategychoose" : "السياسات المتاحة",
  "ccm.agent.button.restSoftPhonePwd.tips" : "ستؤثر إعادة تعيين كلمة مرور الهاتف على الهاتف المستخدم\، مما يتطلب من الموظف تسجيل الدخول مرة أخرى. هل تريد بالتأكيد إعادة تعيين كلمة مرور الهاتف؟",
  "ccm.ucconfig.message.success" : "النجاح",
  "ccm.contact.customer.config.regular" : "عادي",
  "ccm.certificate.management.certificatefile.uploadcertcrlfile" : "تحميل ملف CRL",
  "ccm.agent.label.busyRatePrefix" : "عندما يتجاوز استخدام الجهاز ",
  "ccm.agent.label.phoneNumber" : "PhoneNumber",
  "ccm.agent.restrecord.reststatus.timeout.Forcible.endrest" : "راحة اضطر إلى إنهاء",
  "ccm.agent.label.answerMode" : "المهارة AnswerMode",
  "ccm.skill.label.defaultskill" : "قائمة انتظار المهارة الافتراضية",
  "ccm.recognition.label.orderfailwhilte" : "فشل الاشتراك. فشل مزامنة قائمة الثقة.",
  "ccm.satisfactionconfig.message.comfirm" : "هل تريد بالتأكيد حذف مجموعة النهج؟",
  "ccm.agent.operation.LISTEN" : "فحص جودة الاستماع",
  "ccm.verifyidentity.fail.WORKNOPARSEERROR" : "يجب أن يكون معرف الموظف رقمًا.",
  "ccm.agent.label.usedMode.phone" : "رقم الهاتف",
  "ccm.agent.contact.multimedia.telegram" : "الوسائط المتعددة - برقية",
  "iolp.workflow.line.title" : "نجح تعيين سمات السطر",
  "ccm.transtask.label.taskfail" : "فشل إنشاء المهمة بسبب السماح بحد أقصى {0} لمهام نوع سجل جهة الاتصال.",
  "ccm.custdata.option.columnType.string" : "سلسلة",
  "ccm.urlconfig.modify.authbothnull" : "لا يمكن أن تكون معلومات التخويل فارغة.",
  "ccm.sysparam.config.reset" : "إعادة تعيين",
  "ccm.agent.contact.callserno" : "اتصل بـ S/N",
  "ccm.agent.contact.minCallDuration": "بدء مدة المكالمة",
  "ccm.agent.contact.maxCallDuration": "انتهت مدة المكالمة",
  "ccm.agent.contact.lessThan": "أقل من",
  "ccm.agent.contact.callDurationError":"وقت بدء مدة المكالمة أقصر من وقت انتهاء مدة المكالمة",
  "ccm.agent.contact.talkDurationError":"لا يمكن أن تتجاوز مدة المكالمة 31 يومًا.",
  "ccm.agent.contact.fuzzyCallNoError":"لا يمكن أن يتجاوز نطاق رقم جهة الاتصال (البحث الغامض) 31 يومًا.",
  "ccm.agent.contact.fuzzyCallNo": "رقم الاتصال (الاستعلام الضبابي)",
  "ccm.transserver.label.objobs" : "OBS",
  "ccm.agent.accesscode.index" : "لا ..",
  "ccm.custdata.option.importDone" : "اكتملت",
  "iolp.workflow.node.label.associateBusiness" : "ارتباط معالجة الخدمة المقترن",
  "ccm.sysparam.config.select.prompt" : "تلميحات",
  "ccmanagement.satisfaction.strategy.calltime.minvalue" : "الحد الأدنى للمدة (الثانية) للمكالمة",
  "ccm.agent.message.numInRowFail" : "يجب أن تكون القيمة عددًا صحيحًا يتراوح من 2 إلى 4.",
  "ccm.certificate.management.status.value.inuse" : "قيد الاستخدام",
  "ccmanagement.satisfaction.strategy.acceptdate.dateEnd" : "تاريخ الانتهاء",
  "ccm.transtask.label.dumpinterval" : "مدة التفريغ",
  "ccm.custdata.operlog.displayMask" : "قناع العرض",
  "ccm.agent.sysparamconfig.emailMaxRecipientsNoCheck" : "يجب أن يتراوح الحد الأقصى لعدد المستلمين المعاد توجيههم بواسطة وكلاء البريد بين 1 و50",
  "ccm.sysparam.config.itemname" : "اسم المعلمة",
  "ccm.agent.title.serviceAccount" : "حساب سحابة الخدمة المرتبطة",
  "iolp.workflow.node.label.sms" : "قالب SMS",
  "ccm.agent.operation.FORCEIDEL" : "قوة الخمول",
  "ccm.agent.message.idIsEmpty" : "معرف قائمة انتظار المهارة فارغ.",
  "ccm.certificate.management.update.cancel.crlfile" : "هل تريد بالتأكيد إلغاء تحديث ملف crl؟",
  "ccm.verifyidentity.fail.TIMEPARAMNULLERROR" : "لا يمكن أن يكون نطاق وقت الاستعلام فارغًا.",
  "ccm.agent.label.expired" : "منتهية الصلاحية",
  "iolp.workflow.label.operation" : "العملية",
  "ccm.agent.contact.contactdetail" : "تفاصيل الاتصال",
  "ccm.agent.label.qualityChecker" : "مدقق الجودة",
  "ccm.agent.label.skillWeightTip" : "عندما يدخل عامل في قوائم انتظار متعددة\، تتم معالجة المكالمات في قائمة انتظار المهارة ذات الوزن الأعلى أولاً.",
  "ccm.callout.lable.number" : "قيمة المعلمة",
  "ccm.agent.calledroute.success" : "النجاح",
  "ccm.custdata.button.release" : "الافراج",
  "ccm.agent.speciallist.levelName" : "مستوى القائمة الخاصة",
  "ccm.agent.operation.noMoreThan7Days" : "لا يمكن أن يتجاوز النطاق الزمني 7 أيام.",
  "ccm.custdata.operlog.importdata" : "استيراد البيانات",
  "ccm.urlconfig.label.radio.radioType2" : "مكالمة ITA ثنائية الاتجاه",
  "ccm.speciallist.msg.operationerror" : "فشلت العملية",
  "ccm.urlconfig.label.radio.radioType1" : "مكالمة CEC ثنائية الاتجاه",
  "ccm.accesscode.placeholder.accessdesc" : "الرجاء إدخال وصف رمز الوصول.",
  "ccm.agent.pageurls.moveup" : "لأعلى",
  "ccm.agent.label.deleteCtiFailed" : "فشل حذف البيانات من منصة CTI.",
  "ccm.note.config.agent.page.ring.choose" : "حدد نغمة رنين.",
  "ccm.agent.contact.info" : "معلومات الوثيقة",
  "ccm.agent.updateSoftPhone.unErrorInfo" : "استثناء النظام.",
  "ccm.agent.message.skillHasbeenUsedByAgent" : "تم شغل قائمة انتظار المهارة من قبل موظف. احذف الموظف أولاً.",
  "ccm.contact.customer.config.datasource.objId" : "كائن مصدر البيانات",
  "ccm.transtask.label.notEnabled" : "معطل",
  "ccm.recognition.label.appSecret.download.appId.empty.error" : "لا يمكن أن يكون حقل معرف التطبيق فارغًا.",
  "ccm.agent.restReason.confOnlyCauseCode" : "فشلت العملية. رمز السبب موجود بالفعل.",
  "ccm.agent.leavemessage.serviceContactNumber" : "رقم الاتصال",
  "ccm.agent.accesscode.description.notempty" : "لا يمكن أن يكون وصف رمز الوصول فارغًا.",
  "ccmanagement.tenantspace.option.weekday_sun" : "الأحد",
  "ccm.agent.message.deleteAgentSkillFail" : "فشل حذف اقتران قائمة انتظار مهارة الموظف.",
  "ccm.transserver.delete.css.success" : "تم حذف تكوين CSS بنجاح.",
  "ccm.transserver.delete.success" : "تم حذف ملقم التفريغ بنجاح.",
  "ccm.certificate.management.warn.selectcerttype" : "يرجى تحديد نوع الشهادة.",
  "ccm.agent.message.omsAgentTypeBatchCheck" : "لا يتطابق الدور المحدد أو نوع الموظف المحدد عند تمكين الموظف (معرف الموظف [#*@$20}]) عبر الإنترنت.",
  "ccm.agent.ccagentinfo.unregister.fail" : "فشل إلغاء التسجيل",
  "ccm.anonymous.message.fail.notmatch" : "علامة الميزة لا تتطابق مع الحالة الحالية",
  "ccm.agent.message.willClearAgents" : "هل تريد بالتأكيد مسح الموظفين المحددين؟",
  "ccm.agent.message.createSkillConfSuccess" : "تم إنشاء معلومات قائمة انتظار المهارة بنجاح.",
  "ccm.agent.contact.clicktocall" : "انقر لطلب",
  "ccm.botherlist.label.approveComment.reject" : "رفض",
  "ccm.agent.label.maxTalkTime" : "الحد الأقصى لمدة المكالمة (بالثانية)",
  "ccm.note.config.agent.page.ring.title" : "ينطبق هذا التكوين فقط عندما تكون الصفحة مخفية\، كما هو الحال عندما يكون المستعرض مصغر\، أو عندما تكون الصفحة مبوبة في الخلفية.",
  "ccm.agent.label.param.index" : "لا ..",
  "ccm.custdata.option.importFail" : "فشل",
  "ccm.agent.label.notselectcallreason" : "الرجاء تحديد سبب المكالمة",
  "ccm.agent.updateSoftPhone.unFoundInfo" : "لم تتم مزامنة مركز المكالمات.",
  "ccm.custdata.option.columnType.dictionary" : "قاموس",
  "ccm.agent.message.modifymobileagentsuccess" : "تم تعديل المعلومات الخاصة برقم الهاتف الخاص بوكيل الجوال بنجاح.",
  "ccm.recognition.label.orderfailpd" : "فشل الاشتراك. حاول مرة أخرى لاحقًا.",
  "iolp.configur.service.label.customizedPageContainerId" : "معرف الحاوية العلوية في الصفحة المخصصة",
  "ccm.recognition.queryAK.not.exist" : "لا تحتوي مساحة المستأجر الحالية على مفتاح تطبيق.",
  "ccm.sysparam.error.validate.notmatchrulemin" : "معلمة الإدخال أقل من الحد الأدنى",
  "ccmanagement.tenantspace.option.month_12" : "كانون الأول/ديسمبر",
  "ccm.skillgroup.msg.deleteTips" : "تم إقران مجموعة المهارات الحالية بالعامل {0}. قم بإزالة الاقتران ثم احذفه.",
  "ccmanagement.tenantspace.option.month_11" : "نوفمبر",
  "ccm.vcallcenter.config.popup.authentication.failed" : "فشلت المصادقة.",
  "ccm.agent.resetskillrecord.adjustedbusinessaccount" : "حساب الخدمة المعدل",
  "ccmanagement.tenantspace.option.month_10" : "أكتوبر",
  "ccm.transtask.label.task.otheruniquene" : "يمكن إنشاء ملف صوتي واحد فقط أو بيانات فحص ذكية أو مهمة نوع بيانات CDR لنفس نوع بيانات التفريغ.",
  "ccm.satisfactionlevel.message.resetsuccess" : "تم إعادة تعيين مستوى الرضا بنجاح.إذا تم تكوين عملية رضا\، حررها مرة أخرى في إدارة التدفق. وإلا\، سيتم عرض نتيجة الرضا بشكل غير صحيح. سيستند استطلاع الرضا الجديد إلى نتائج هذا التكوين.",
  "ccm.ivrvoice.play.error" : "استثناء مورد التحميل\، الرجاء التحقق من التكوين ذي الصلة.",
  "ccmanagement.tenantspace.option.weeklist_firtolast" : "الأخير",
  "ccm.custdata.option.isMandatory.yes" : "مطلوب",
  "ccm.speciallist.msg.download" : "تنزيل قالب استيراد قائمة خاصة",
  "ccm.speciallist.msg.import.template": "قالب استيراد قائمة خاصة",
  "ccm.sysparam.config.search" : "الاستعلام",
  "ccm.urlconfig.label.relmethod" : "طريقة طلب HTTP",
  "ccm.agent.calledroute.exception" : "تحقق من حالة Redis أو تكوين CC-Gateway.",
  "ccm.agent.message.sysparamservicerr" : "خطأ داخلي في الخدمة. يرجى التحقق من السجل.",
  "ccm.agent.message.download.err": "فشل التنزيل. الاتصال بمهندسي التشغيل والصيانة للنظام.",
  "ccm.agent.label.paramValue" : "القيمة",
  "ccm.transtask.label.skillReport" : "تقرير حركة مرور قائمة انتظار المهارات",
  "ccm.speciallist.msg.applyTimeTo" : "وقت انتهاء التطبيق",
  "ccm.custdata.title.modify" : "تحرير جدول أساسي",
  "ccm.contactitemconfig.message.confOnlyDataItemName" : "فشلت العملية لأن اسم عنصر البيانات موجود بالفعل.",
  "ccm.agent.restReason.createFailed" : "فشلت الإضافة.",
  "ccm.sysparam.config.resetAbb" : "إعادة تعيين",
  "ccm.agent.button.submit" : "إرسال",
  "ccm.certificate.management.certificatefile.suffixerror" : "يمكن تحميل الملفات بتنسيق jks \، cer \، crt \، pem \، pfx \، p12 فقط.",
  "ccm.accesscode.label.accesscodedesc" : "وصف رمز الوصول",
  "ccm.accesscode.title.accesscodedesc": "تحرير وصف رمز الوصول",
  "ccm.speciallevel.speciallist.unuse" : "غير متوفر",
  "ccm.agent.restrecord.rest.reason" : "سبب الراحة",
  "iolp.workflow.nodetype.fork" : "فرع",
  "ccm.agent.pageurls.maxcallingnum" : ". تأكد من أن عدد الشاشات المنبثقة لا يتجاوز 5.",
  "ccm.agent.pageurls.fail" : "خطأ",
  "ccm.agent.label.callDispatchModel.incomingCall" : "تخصيص مهارة المكالمات الواردة قبل تخصيص مهارة المكالمات الصادرة",
  "ccm.agent.label.sevendays" : "7 أيام",
  "ccm.skillgroup.message.skillgroup" : "الرجاء إدخال اسم مجموعة مختصة.",
  "ccm.transtask.label.reports" : "تفريغ تقرير",
  "ccm.agent.label.thirtydays" : "30 يوم",
  "ccm.agent.pageurls.delete" : "حذف",
  "ccm.ivr.placeholder.ivrdesc" : "يرجى إدخال وصف العملية.",
  "ccm.urlconfig.message.overlimit.domainName" : "لا يمكن أن يتجاوز اسم المجال في تكوين مكالمة ITA ثنائية الاتجاه 128 حرفًا.",
  "ccm.agent.label.callDispatchModel.manySkills" : "وكيل مع معظم المهارات",
  "ccm.agent.calledroute.devicetype" : "نوع الجهاز",
  "ccm.botherlist.label.approveStatus.toApprove" : "سيتم الموافقة عليها",
  "ccm.certificate.management.passwd" : "كلمة مرور الشهادة",
  "ccm.speciallist.label.reason" : "سبب الإنضمام",
  "ccm.verifyidentity.workNo" : "معرف الموظف",
  "ccm.agent.message.worknostartbig" : "لا يمكن أن يكون معرف موظف البدء أكبر من معرف موظف النهاية.",
  "ccm.agent.operation.ANSWER" : "الجواب",
  "ccm.custdata.label.columnLength" : "طول الحقل",
  "ccm.transserverparam.label.login.cssClusterUser" : "اسم مستخدم تسجيل الدخول إلى المجموعة",
  "ccm.satisfactionconfig.message.surveystrategygroup" : "سياسة المسح",
  "ccm.satisfactionconfig.web.satisfaction.survey" : "استبيان رضا قناة الويب",
  "ccm.agent.contact.recordInfo" : "تفاصيل ملف التسجيل",
  "ccm.satisfactionconfig.message.groupcreate" : "أنيادير",
  "ccm.skill.label.maxwaittime" : "ماكس. مدة الانتظار",
  "ccm.agent.contact.multimedia.wechat" : "الوسائط المتعددة-WeChat",
  "ccm.agent.label.endworkno" : "معرف الموظف النهائي",
  "ccm.certificate.management.status.update.failed" : "فشل تغيير حالة الشهادة.",
  "ccm.calledconfig.message.willdeletemobileagent" : "هل تريد بالتأكيد حذف موظف الجوال المحدد؟",
  "ccm.pageinterface.interfaceType.external" : "واجهة خارجية",
  "ccm.ccagent.management.batch.operation" : "عملية الدفعات",
  "ccm.transserver.label.obsaddr" : "عنوان OBS",
  "ccm.agent.label.waitTime" : "مدة الانتظار (ثانية)",
  "ccm.satisfactionconfig.sms.satisfaction.survey" : "استبيان رضا SMS",
  "ccm.recognition.label.appSecret.download.querySK.error" : "فشل الاستعلام عن SK.",
  "ccm.speciallist.msg.modifyerror" : "فشل التعديل",
  "ccm.recognition.label.sucess" : "النجاح",
  "ccm.recognition.label.interconnection.parameters" : "معلمات الربط البيني",
  "ccm.ucconfig.message.clientId" : "معرف التطبيق (العميل)",
  "ccm.agent.pageurls.maxcalling" : "الحد الأقصى لعدد الشاشات المنبثقة للمكالمات الواردة هو {limit}.",
  "ccm.chatbot.invalid.empty" : "لا يمكن أن يكون مساعد الروبوت فارغًا.",
  "ccm.agent.leavemessage.assignSuccess" : "تم تخصيص الرسالة بنجاح",
  "ccm.transtask.label.skillSummaryReport" : "تقرير ملخص حركة المرور",
  "ccm.agent.pageurls.errorupdate" : "فشل التحديث.",
  "ccm.datatask.checkNum" : "القيمة هي عدد صحيح موجب.",
  "ccm.recognition.label.anonymous" : "علامة مكالمة صادرة مجهولة",
  "ccm.calledconfig.message.samename" : "نفس الاسم موجود بالفعل.",
  "ccm.transserver.label.edit" : "خادم تفريغ الموارد",
  "ccm.contact.customer.config.backgroundColor" : "لون الخلفية",
  "ccm.agent.label.workNo" : "معرف الموظف",
  "ccm.speciallist.msg.errorreason" : "سبب الفشل",
  "ccm.contact.detail.receiver" : "المتلقي",
  "ccm.custdata.option.maskStyle.retainFirst4" : "حجز أول أربعة أرقام",
  "ccm.agent.contact.playVideo" : "تشغيل فيديو",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnull" : "لا يمكن أن يكون وقت البدء أو الانتهاء فارغًا.",
  "ccm.contact.customer.config.italic" : "الإيطالية",
  "ccm.agent.sysparamconfig.refreshSuccess" : "نجحت إعادة الضبط بنقرة واحدة.",
  "ccm.custdata.msg.columnNoMatch" : "حقل الجدول غير متناسق مع الحقل الموجود في قاعدة البيانات. قم بتحديث الجدول وحاول مرة أخرى.",
  "ccm.certificate.management.certificatefile.notupload" : "يُرجى تحميل ملف الشهادة.",
  "ccm.agent.sysparamconfig.worknonumwarn" : "لا يمكن أن يتجاوز عدد معرفات الموظف 10.",
  "ccm.agent.label.scaleFactor" : "مقياس عامل",
  "ccmanagement.satisfaction.strategy.calltime.lengthlimit" : "يمكن أن يحتوي الحد الأدنى أو الحد الأقصى لمدة المكالمة على 12 رقمًا كحد أقصى.",
  "ccm.agent.updateSoftPhone.updateGatewayFailed" : "فشل تغيير كلمة مرور البوابة.",
  "ccm.transtask.label.contactRecord" : "سجل جهة الاتصال",
  "ccm.speciallist.msg.norecord" : "لا يمكن تصدير أي سجل",
  "ccm.agent.contact.download" : "تحميل",
  "ccm.agent.contact.web" : "Web",
  "ccm.calledconfig.message.callreasontips" : "يُرجى ملاحظة أن بطاقة المشكلة المرتبطة بالمستوى الحالي غير متوفرة. حدد سبب مكالمة مجموعة فرعية لتعيين بطاقة المشكلة المقترنة.",
  "ccm.basicdata.error.capacitylimit" : "وصلت سعة البيانات إلى الحد الأعلى {0}",
  "ccmanagement.satisfaction.strategy.minutevalidate" : "الدقيقة بتنسيق غير صحيح.",
  "ccm.contact.details.tab.title.video" : "فيديو",
  "ccm.custdata.label.isIndex" : "يُستخدم كمؤشر",
  "ccm.speciallist.msg.errorNum" : "{limit} فشل",
  "ccm.contact.customer.config.dataItemError" : "يجب أن يكون اسم عنصر البيانات أو معرفه فريدًا.",
  "ccm.agent.label.agentWeightTip" : "يتم تخصيص المكالمات بشكل تفضيلي للوكلاء ذوي الوزن الأعلى.",
  "ccm.callout.message.confreset" : "إعادة تعيين تأكيد",
  "ccm.note.config.agent.page.ring.tipTitle" : "في حالة تكوين مربع حوار\، يكون مربع الحوار صالحًا فقط بعد قيام الموظف بتكوين السماح لموقع الويب هذا باستخدام الإطارات المنبثقة على المستعرض أثناء تكامل الاتصال.",
  "ccm.custdata.option.isEncrypt.no" : "لا",
  "ccm.agent.label.skillChoice" : "تحديد المهارة",
  "ccm.transtask.label.View" : "عرض",
  "ccm.taskrecord.message.reexecutetranstaskerror" : "فشل إعادة التنفيذ.",
  "ccm.pageconfig.label.numInRow" : "عدد الصفوف",
  "ccm.transtask.label.agentReport" : "تقرير ملخص أداء الوكيل",
  "ccm.agent.label.serviceAccountId" : "معرف حساب سحابة الخدمة المرتبطة",
  "ccm.agent.contact.businesshungup" : "التحويل إلى الوكيل",
  "ccm.calledconfig.table.selectnumbers" : "تحديد أرقام",
  "ccm.agent.agentcontact.nosearch" : "لا يمكن الاستعلام عنها في معالجة غير تجارية.",
  "ccm.speciallist.msg.sltype" : "نوع القائمة الخاصة",
  "ccm.transtask.label.saveexit" : "فشلت إضافة ملقم تفريغ لأن اسم الملقم موجود بالفعل.",
  "ccm.contact.customer.config.style" : "نمط العرض",
  "ccm.agent.label.batchselectway" : "وضع تحديد الحزمة",
  "ccm.custdata.option.columnType.date" : "التاريخ",
  "ccm.speciallist.msg.approve" : "الموافقة",
  "ccm.speciallist.label.approve": "تعليقات الموافقة",
  "ccm.speciallist.poptitle.approve" : "الموافقة على سجلات التحرش",
  "ccm.speciallist.poptitle.view": "عرض سجلات التحرش",
  "ccm.speciallist.title.approve": "معلومات الموافقة",
  "ccm.agent.label.withinFifteenDays" : "في غضون 15 يوما",
  "ccm.whatsapp.select.whatsApp.channel" : "الرجاء تحديد قناة WhatsApp.",
  "ccm.agent.message.validatormsg" : "يجب أن تكون المدخلات المتعلقة بكلمة المرور فارغة أو غير فارغة على الإطلاق",
  "ccm.idauth.create.error.length" : "يتجاوز طول رمز وضع المصادقة الحد الأعلى.",
  "ccm.transserver.label.css.edit" : "تهيئة خادم البحث السحابي",
  "ccm.page.label.status" : "حالة الصفحة",
  "ccm.transtask.label.delete" : "تم حذف مهمة التفريغ بنجاح.",
  "ccm.agent.calledroute.create.accesscode.noexist" : "رمز الوصول المشار إليه بواسطة تكوين الطرف المطلوب غير موجود.",
  "ccm.custdata.label.failreaon" : "سبب الفشل",
  "ccm.contactitemconfig.label.data.extended.dictionary.value" : "قيمة قاموس البيانات",
  "ccm.agent.message.deleteSkillConfFail" : "فشل حذف معلومات قائمة انتظار المهارة.",
  "ccm.transtask.label.chatrecordinf" : "إعداد تفريغ بيانات دردشة الوسائط المتعددة",
  "ccm.speciallist.label.redlist" : "القائمة الحمراء",
  "ccm.skillgroup.message.nameValidateFail" : "لا يمكن أن تحتوي المجموعة المختصة على الأحرف الخاصة التالية: [<>\"()]&=;,",
  "ccm.skillgroup.message.namePrefixSuffix": "لا يمكن للمجموعة المختصة أن تبدأ أو تنتهي بمسافة",
  "ccm.agent.operation.mediaswitch" : "تبديل الصوت/الفيديو",
  "ccm.custdata.operlog.passValidateFailed" : "يجب أن يحتوي على حروف وأرقام وأحرف خاصة {'@'} {'%'}",
  "ccm.chatbot.query.list.fail" : "فشل الحصول على قائمة الروبوتات.",
  "ccm.speciallist.msg.necessary" : "رقم المستخدم ونوع القائمة الخاصة إلزاميان.",
  "ccm.transtask.label.save" : "حسناً",
  "ccm.label.contactexport.customfield" : "حقل مخصص",
  "ccm.agent.updateSoftPhone.modifyLocalPwd" : "فشل تغيير كلمة مرور الهاتف المحمول المحلية.",
  "ccm.sysparam.error.validate.notmatchrulealpha" : "يرجى إدخال الحروف الهجائية الرقمية أو الشرطة السفلية، وابدأ فقط بالحروف أو الشرطة السفلية",
  "ccm.agent.ten.sipInfo" : "عنوان ومنفذ SIP",
  "ccm.agent.title.mobileaagentCreate" : "جديد MobileAgent",
  "ccm.satisfactionconfig.message.number" : "عدد",
  "iolp.configur.servicelabel.button.saveDraft" : "حفظ كمسودة",
  "ccm.agent.message.agentCreateFail" : "فشل إنشاء موظف في CTI.",
  "ccm.agent.label.keepVoiceSelect" : "يُرجى تحديد نغمة حفظ",
  "ccm.satisfactionconfig.message.validatename" : "لا يمكن أن يكون اسم مجموعة النهج فارغًا أو مكررًا.",
  "ccm.agent.contact.callinandout" : "مكالمة مزدوجة",
  "ccm.verifyidentity.loginId" : "حساب الأعمال",
  "ccm.case.label.recommendedWorkOrder2" : "فئة TT الموصى بها 3",
  "ccm.agent.label.skillName" : "اسم قائمة انتظار المهارة",
  "ccm.case.label.recommendedWorkOrder1" : "فئة TT الموصى بها 2",
  "ccm.case.label.recommendedWorkOrder0" : "فئة TT الموصى بها 1",
  "ccm.speciallist.msg.warning" : "تحذير",
  "ccm.agent.login.FORCELOGIN" : "فرض تسجيل الدخول",
  "ccm.urlconfig.label.talkurl" : "عنوان URL لرد الاتصال للاتصال",
  "ccm.agent.label.queuingCallsMaxNum" : "الحد الأقصى لعدد مكالمات قائمة الانتظار",
  "ccm.satisfactionconfig.message.comfirmtitle" : "تأكيد",
  "ccm.agent.calledroute.device" : "حدد جهازًا.",
  "ccm.dual.call.record.reasonCode" : "سبب الافراج",
  "ccm.sysparam.error.validate.notmatchrulemax" : "تتجاوز معلمة الإدخال الحد الأقصى",
  "ccm.ucconfig.message.title" : "تسجيل التطبيقات مع Microsoft Identity Platform",
  "ccm.satisfactionconfig.message.satis" : "استبيان الرضا",
  "ccm.agent.restReason.restCauseTime.formate" : "يجب أن تكون مدة الراحة بتنسيق HH:MM:SS.",
  "ccm.recognition.label.closeanonymoussuccess" : "تم تعطيل إشارة مكالمة صادرة مجهولة بنجاح",
  "ccm.chatbot.invalid.bot" : "هذا الروبوت غير صالح",
  "ccm.agent.label.sensitiveWord" : "تم اكتشاف كلمات ممنوعة. انتبه إلى صياغتك.",
  "ccm.agent.pageurls.isenable" : "تمكين",
  "ccm.speciallist.msg.overlimit" : "يتجاوز طول رسالة التذكير 256 حرفًا.",
  "ccm.skillgroup.label.groupname" : "اسم المجموعة المختصة",
  "ccm.agent.label.ninetydays" : "90 يوم",
  "ccmanagement.satisfaction.strategy.calltime.second" : "s",
  "ccm.agent.ten.tenantInfor" : "معلومات المستأجر",
  "ccm.transserver.discard.confirm" : "هل تريد بالتأكيد تجاهل المهمة؟",
  "ccm.contact.detail.talk.content.not.support" : "لا يمكن أن يحتوي نص البحث على\/()",
  "ccm.custdata.msg.importDataFail" : "فشل استيراد البيانات.",
  "ccm.calledconfig.message.willdeleteassociation" : "هل تريد بالتأكيد حذف معلومات اقتران سبب المكالمة",
  "ccm.transserverparam.label.agentip" : "عنوان الخادم الوكيل",
  "ccm.agent.addbother.fail" : "فشلت الإضافة.",
  "ccm.recognition.label.openmobileagentsuccess" : "تم تمكين موظف الجوال بنجاح.!",
  "ccm.satisfactionconfig.message.audio" : "استبيان الرضا",
  "ccmanagement.tenantspace.option.weekday_fri" : "الجمعة",
  "ccm.callout.message.resetsuccess" : "نجحت إعادة تعيين المعلمة.",
  "ccm.agent.label.serviceAccountCode" : "الحساب",
  "ccm.agent.label.startworkno" : "معرّف الموظف البدء",
  "ccm.agent.message.agentNotFoundFail" : "فشل العثور على معلومات الموظف.",
  "ccm.agent.calledroute.extlengthcodewarn" : "لا يمكن أن يكون طول رمز التمديد أكبر من 12",
  "ccm.transtask.label.month" : "الشهر",
  "ccm.agent.operation.SAYFREE" : "الخمول",
  "ccm.custdata.operlog.zippwd" : "كلمة مرور مضغوطة",
  "ccm.idauth.title.edit" : "تعديل عملية مصادقة الهوية",
  "ccm.pageinterface.title" : "واجهة الصفحة",
  "ccm.callout.title.success" : "النجاح",
  "ccm.urlconfig.label.oauth.ak" : "AK",
  "ccm.contactitemconfig.message.DatasetName.inuse" : "لا يمكن حذف مجموعة البيانات لأنها مكونة بالفعل في عنصر بيانات سجل جهة الاتصال.",
  "ccm.recognition.label.modifyVoiceNotificationClose" : "لم يتم تمكين ميزة الإعلام الصوتي لمساحة المستأجر.",
  "ccm.basicdata.msg.queryfail" : "فشل الاستعلام عن البيانات الأساسية.",
  "ccm.agent.message.clear" : "مسح الكل",
  "ccm.agent.accesscode.description.require" : "لا يمكن أن يكون وصف رمز الوصول فارغًا.",
  "ccm.agent.calledroute.isconfdelete" : "هل تريد بالتأكيد حذف الطرف المطلوب المحدد؟",
  "ccm.agent.sysparamconfig.verifyTemplateIdLength" : "يجب أن يكون معرف قالب الرسالة الداخلي عددًا صحيحًا ولا يمكن أن يكون رقمًا سالبًا.",
  "ccm.agent.message.agentnotexist" : "الموظف غير موجود. قم بتحديث صفحة إدارة الموظف وحاول مرة أخرى.",
  "ccm.contact.customer.config.datasourceType" : "نوع مصدر البيانات",
  "ccm.agent.calledroute.gateway.fail" : "فشل مزامنة المعلومات الخاصة بالنظام الأساسي CTI. يُرجى التحقق من تكوين البوابة.",
  "ccm.agent.softPhoneStatus" : "حالة رقم الهاتف المحمول",
  "ccm.page.select.blockLayout" : "تخطيط الحظر",
  "ccm.agent.label.positiveEmotion" : "عمل جيد، استمر",
  "ccm.certificate.management.search.status" : "الرجاء اختيار حالة الشهادة",
  "ccm.sysparam.error.validate.notmatchrulenumber" : "الرجاء إدخال رقم",
  "ccm.sysparam.error.validate.notmatchruleoption" : "معلمة الإدخال ليست في نطاق التعداد",
  "ccm.recognition.label.appSecret.download.SK.not.exist" : "معلومات SK غير موجودة.",
  "ccm.pageinterface.pleaseSelectInterfaceType" : "حدد نوع واجهة.",
  "ccm.agent.message.audioagentzero" : "عدد وكلاء الصوت هو 0. لا يمكن تكوين قائمة انتظار مهارة النقر لطلب الصوت.",
  "ccm.idauth.title.code" : "رمز وضع المصادقة",
  "ccm.agent.contact.predictiveCall" : "مكالمة صادرة متوقعة",
  "ccm.agent.label.transparentDisplayFlag" : "رقم الموظف علامة إخفاء الهوية",
  "ccm.speciallist.msg.restoresuccess" : "نجحت الاستعادة",
  "ccm.contact.customer.config.referenced" : "تمت الإشارة إلى عنصر البيانات بواسطة منطقة معلومات العميل الخاصة بجهة الاتصال.",
  "ccm.contact.record.data.referenced" : "تمت الإشارة إلى عنصر البيانات بواسطة عنصر بيانات سجل جهات الاتصال.",
  "ccm.agent.restrecord.reststatus.Abnormal.endrest" : "انتهت الراحة بشكل غير طبيعي",
  "ccm.recognition.label.cancelordersucess" : "تم إلغاء الاشتراك بنجاح.",
  "ccm.custdata.button.abandon" : "تجاهل",
  "ccm.urlconfig.label.oauth.voicemsg.alerttip" : "إذا تم نقل عنوان URL لرد الاتصال عند استدعاء API لإنشاء الإعلام الصوتي وتكوين معلومات المصادقة التالية، يتم استخدام هذه المعلومات أثناء رد عنوان URL عند اكتمال الإعلام الصوتي. وإلا، لا يتم إجراء أي مصادقة أثناء معاودة الاتصال.",
  "ccm.agent.login.LOGOUT" : "تسجيل الخروج",
  "ccm.agent.pageurls.createcall" : "شاشة مكالمة واردة جديدة منبثقة",
  "ccm.agent.pageurls.config.screen.pop.up": "تكوين الشاشة المنبثقة",
  "ccm.agent.pageurls.unsaved.data.exists":"البيانات غير المحفوظة موجودة. يُرجى حفظه أولاً.",
  "ccm.note.config.searchnotename" : "يرجى إدخال الاسم",
  "ccm.agent.message.searchTimeRemaining" : "انتهاء صلاحية كلمة المرور",
  "ccm.sysparam.error.validate.notmatchrulerange" : "تتجاوز معلمة الإدخال الحد الأقصى للقيمة أو أقل من الحد الأدنى للقيمة",
  "ccm.certificate.management.type" : "نوع الشهادة",
  "ccm.agent.contact.calltype" : "نوع الاتصال",
  "ccm.callreason.label.name" : "الاسم",
  "ccm.callout.message.editsuccess" : "نجح تحرير متصل وسيلة الشرح",
  "ccm.custdata.option.maskStyle.maskFirst4" : "قناع أول أربعة أرقام",
  "iolp.workflow.button.hideFlowChart" : "إخفاء المخطط الانسيابي",
  "ccm.skillgroup.msg.deleteerror" : "الرجاء تحديد المجموعة المختصة المراد حذفها.",
  "ccm.agent.label.video" : "وكيل الفيديو",
  "ccm.custdata.option.columnType.number" : "عدد",
  "ccm.recognition.label.appSecret.download.SK.io.error" : "فشل تنزيل SK. استثناء الإدخال/الإخراج.",
  "ccm.agent.pageurls.isconfdelete" : "هل تريد بالتأكيد حذفه؟",
  "ccm.agent.message.pageLayout" : "تخطيط الصفحة",
  "ccm.agent.leavemessage.statusFailed" : "فشل تغيير حالة سجل الرسائل.",
  "ccm.agent.calledroute.create.accesscode.none" : "لم يتم تخصيص رمز وصول النظام الذي تم تكوينه للطرف المطلوب.",
  "ccm.recognition.label.save" : "حفظ",
  "ccm.custdata.button.moveTop" : "أعلى",
  "ccmanagement.agent.batchmodify.agentlis" : "حسب المقطع",
  "ccm.asr.create.case.info" : "يمكنك النقر فوق إنشاء حالة فقط أثناء المكالمة أو بعد انتهاء المكالمة.",
  "ccm.satisfactionconfig.message.createsuccess" : "تمت إضافة تكوين استطلاع الرضا بنجاح",
  "ccm.note.config.agent.page.ring.select" : "تحديد نغمة الرنين",
  "ccm.certificate.management.certificatefile.injectionerror" : "يحتوي الملف على مخاطر الحقن.",
  "ccm.custdata.msg.confirmRelease" : "هل أنت متأكد من أنك تريد الافراج عنه؟",
  "ccm.agent.message.agentweight.invalid" : "يجب أن يتراوح وزن الوكيل من 1 إلى 300.",
  "ccm.agent.button.createComplete" : "مكتمل",
  "ccm.transserver.delete.css.fail" : "فشل حذف معلومات تكوين CSS.",
  "ccm.calledconfig.title.numbersadded" : "الأرقام التي تمت إضافتها",
  "ccm.speciallist.operlog.downloadtemplate" : "تنزيل قالب",
  "ccm.note.config.agent.page.ring.newMedia" : "إعلام رسالة وسائط متعددة جديدة",
  "ccm.agent.leavemessage.auditdate" : "تحديث الوقت",
  "ccm.agent.ten.videoIvrchannels" : "فيديو IVR قناة الكمية",
  "ccm.agent.contact.skillhungup" : "التحويل إلى قائمة انتظار المهارات",
  "ccm.note.config.success" : "النجاح",
  "ccm.agent.operation.CANCELINSERT" : "إلغاء الإدراج",
  "ccm.agent.ten.DSTEndTime" : "وقت انتهاء DST",
  "ccm.satisfactionconfig.message.prioritytip" : "*تشير القيمة الأصغر إلى أولوية أعلى. تتم مطابقة السياسة ذات الأولوية الأعلى بشكل تفضيلي لتنفيذ الاستطلاع.",
  "ccm.common.label.error" : "خطأ",
  "ccm.agent.title.IVRTrace" : "تتبع IVR",
  "ccm.agent.title.batchagentEdit" : "تكوين معلومات موظف الحزمة",
  "ccm.agent.message.deleteSkill" : "حذف قائمة انتظار المهارة",
  "ccm.satisfactionconfig.message.acceptagent" : "وكيل المناولة",
  "ccmanagement.satisfactionconfig.acceptdata.timeVerification" : "حدد تاريخًا لاحقًا للوقت الحالي وأقدم من 2038-01-19.",
  "ccm.urlconfig.label.oauth.oauth2loginurl" : "OAuth 2.0 مصادقة تسجيل الدخول URL",
  "ccm.agent.leavemessage.notAgent" : "الحساب غير مرتبط بوكيل.",
  "ccm.satisfactionlevel.message.samedesc" : "يوجد وصف مكرر. يرجى تعديله.",
  "ccm.speciallist.msg.deleteerror" : "فشل حذف تذكير القائمة الخاصة.",
  "ccm.agent.resetskillrecord.adjustbusinessaccount" : "ضبط حساب الخدمة",
  "ccm.agent.restReason.edit" : "تحرير سبب الراحة",
  "iolp.configur.servicelabel.shortcutButtonPage" : "صفحة زر الاختصار",
  "ccm.agent.callreason.modify.success" : "تم تعديل سبب المكالمة بنجاح.",
  "ccm.agent.contact.startcallfailed" : "فشل بدء المكالمة",
  "ccm.agent.contact.twitter" : "X (Twitter)",
  "ccm.satisfactionconfig.message.cancel" : "إلغاء",
  "ccm.agent.message.agenttypebothempty" : "لم يتم تكوين نوع الموظف للموظف المحدد. تحتاج إلى تكوين نوع الموظف.",
  "ccm.agent.message.createMobileAgentSuccess" : "تم تكوين معلومات MobileAgent بنجاح.",
  "ccm.satisfactionconfig.message.calltypeout" : "مكالمة صادرة",
  "ccm.dual.call.record.talkingTime" : "وقت الرد الذي تم الاتصال به",
  "ccm.systemparam.error.refresh" : "فشل تحديث المعلمات.",
  "ccm.speciallist.msg.restores" : "استعادة",
  "ccm.agent.pageurls.isconfreset" : "هل تريد بالتأكيد إعادة تعيينه؟",
  "ccm.verifyidentity.ERROR" : "خطأ",
  "ccm.custdata.option.isMaskDisplay.no" : "لا",
  "ccm.urlconfig.message.notNumber.xNumber" : "الرقم X الذي تم تكوينه لمكالمات ITA ثنائية الاتجاه ليس رقمًا.",
  "ccm.agent.contact.norecording" : "لا تسجيل.",
  "ccm.satisfactionconfig.message.strategy" : "سياسة مسح الرضا",
  "ccm.botherlist.label.approveComment.toApprove" : "متفق عليه",
  "ccm.speciallist.msg.deleteyerror" : "فشل حذف القائمة الخاصة",
  "ccm.agent.contact.email" : "البريد الإلكتروني",
  "ccm.agent.contact.Email" : "Email",
  "title.contact.agent.name" : "اسم حساب الخدمة",
  "iolp.configur.servicelabel.label.description" : "الوصف",
  "ccm.contactitemconfig.message.init.basicdata.success" : "تمت تهيئة البيانات الأساسية بنجاح.",
  "ccm.transtask.label.monday" : "الاثنين",
  "ccm.satisfactionconfig.message.smstitle" : "SMS",
  "iolp.workflow.node.label.nodeId" : "معرف العقدة",
  "ccm.agent.label.answerType4" : "الإبلاغ عن هوية الموظف والأصوات المخصصة",
  "ccm.agent.label.answerType2" : "الإبلاغ عن عدم وجود صوت",
  "ccm.agent.label.theAgent" : "الوكيل:",
  "ccm.agent.label.deviceUsage" : "استخدام الجهاز (%)",
  "ccm.agent.label.deviceType" : "نوع الجهاز",
  "ccm.custdata.msg.missRequiredColumn" : "يمكن تحرير جدول مخصص فقط عندما يحتوي على حقل فهرس واحد على الأقل وحقول معلومات أخرى.",
  "ccm.agent.label.answerType0" : "معرّف موظف التقرير",
  "ccm.agent.label.maxWaitTimeSpan" : "القيمة [1–60000]",
  "ccm.agent.operation.RECORDPLAY" : "تشغيل التسجيل",
  "ccm.sysparam.config.reset.multiple.error" : "لا يمكن إعادة تعيين المعلمات التالية:",
  "ccm.agent.ccagent.modify.fail" : "لا يمكن تمكين التعرف الذكي والتسجيل ثنائي المسار في نفس الوقت.",
  "ccm.agent.calledroute.create.ivr.limit" : "إذا كان نوع الجهاز هو IVR\، فيمكن فقط أن يكون نوع وسائط رمز الوصول من النقر لإجراء اتصال\، أو طلب معاودة اتصال\، أو مكالمة عامة.",
  "iolp.workflow.label.customizeInfo" : "تخصيص المعلومات",
  "ccm.agent.contact.Voice" : "صوت",
  "ccm.agent.message.worknonoexit" : "معرف الموظف غير موجود.",
  "ccm.agent.title.agentpassword" : "كلمة مرور الحساب",
  "ccm.pageinterface.selectedAll" : "تحديد الكل",
  "ccm.agent.accesscode.description.limit" : "لا يمكن أن يحتوي وصف رمز الوصول على أكثر من 50 حرفًا.",
  "ccm.agent.contact.calltime" : "مدة المكالمة (بالثانية)",
  "ccm.sysparam.error.validate.notmatchruleemail" : "يرجى إدخال عنوان بريد إلكتروني صحيح",
  "ccm.agent.contact.otherexception" : "استثناءات أخرى",
  "ccm.agent.ten.timezoneOffset" : "إزاحة المنطقة الزمنية",
  "ccm.agent.label.querymobileagent" : "الاستعلام عن رقم الهاتف",
  "ccm.agent.label.registrationMessage" : "معلومات التسجيل",
  "ccm.agent.label.fixedLine" : "رقم الخط الثابت/الهاتف المحمول",
  "ccm.satisfactionservice.evaluationerror" : "فشل التقييم",
  "ccm.agent.message.searchSipAccount" : "أدخل رقم هاتف SIP.",
  "ccm.agent.label.release" : "الافراج",
  "ccm.custdata.msg.dictionaryValidateFailed" : "تنسيق غير صحيح لنطاق قيمة قاموس البيانات",
  "ccm.contactitemconfig.label.data.item.identification" : "معرف عنصر البيانات",
  "ccm.agent.sysparamconfig.resttimenumberwarn" : "يجب أن تتراوح مدة الراحة من 1 إلى 1440 (لا تحتوي على 1440)",
  "ccm.contact.tip.not.get.workname" : "لم يتم الحصول على اسم الحساب المطابق للموظف！",
  "ccm.label.contactexport.selectrange" : "تحديد نطاق",
  "ccm.agent.message.skillConfFail" : "فشل تكوين معلومات قائمة انتظار المهارة.",
  "ccm.agent.calledroute.updatecalledroute" : "تحرير",
  "ccm.agent.calledroute.title.create": "إنشاء تكوين الطرف المطلوب",
  "ccm.agent.calledroute.title.updatecalledroute": "تحرير تكوين الطرف المطلوب",
  "ccm.satisfactionconfig.ivr.voice.satisfaction.survey" : "استبيان الرضا الصوتي IVR",
  "ccm.certificate.management.certificate.crlfile.certhasexpired" : "انتهت صلاحية الشهادة.",
  "ccm.transtask.label.voiceFile" : "ملف صوتي",
  "ccm.speciallist.msg.maxlimit" : "الحد الأقصى لعدد السجلات يتجاوز {limit}",
  "ccm.speciallist.msg.tip" : "ملاحظة: يمكن استيراد 1000 سجل كحد أقصى في كل مرة",
  "ccmanagement.tenantspace.option.weeklist_fth" : "رابعاً",
  "ccm.custdata.label.status" : "الحالة",
  "ccm.agent.contact.talkReason" : "سبب المكالمة",
  "ccm.anonymous.message.fail.save" : "فشل حفظ المسار المطلوب. يُرجى التحقق من تكوين CTI",
  "ccm.agent.operation.INSERT" : "إدراج فحص الجودة",
  "ccm.agent.message.modifySkillFail" : "فشل تحديث المهارة.",
  "ccm.agent.calledroute.delete" : "حذف",
  "ccm.skillgroup.label.groupskills" : "ربط قوائم انتظار المهارات",
  "ccm.agent.operation.ADJUSTVIDEOMODE" : "ضبط حجم نافذة الفيديو",
  "ccm.callout.lable.index" : "الفهرس",
  "ccm.agent.label.groupBind" : "تم ربط قائمة انتظار المهارة بالمجموعة المختصة",
  "ccm.agent.ten.expiryDate" : "تاريخ انتهاء الصلاحية",
  "ccm.agent.label.scaleFactorSuffix" : "x عدد الموظفين الذين قاموا بتسجيل الدخول)، تجاوز في قائمة الانتظار.",
  "ccmanagement.satisfaction.strategy.save" : "حفظ",
  "ccm.agent.updateSoftPhone.updateSuccess" : "تم تغيير كلمة المرور بنجاح.",
  "ccm.contact.label.signed" : "توقيع",
  "ccm.urlconfig.label.noramalOrITA" : "حدد مكالمة CEC ثنائية الاتجاه أو نوع مكالمة ITA ثنائية الاتجاه.",
  "ccm.speciallist.msg.transfererror" : "فشل التحويل إلى المحفوظات",
  "ccm.transtask.label.task.chatrecord.uniqueness" : "توجد مهام لبعض أنواع القنوات المحددة.",
  "ccm.agent.label.basicInfo" : "معلومات أساسية",
  "ccm.agent.leavemessage.status.allocationFailed" : "فشل التخصيص",
  "ccm.recognition.label.intellagent" : "مساعد وكيل ذكي",
  "ccm.transserver.delete.time.fail" : "احذف المهمة بعد 10 دقائق من تجاهلها.",
  "ccm.agent.contact.agenthungup" : "الوكيل",
  "ccm.agent.recall.param.callid.empty" : "لا يتوفر رقم معاودة الاتصال.",
  "ccm.agent.sysparamconfig.positiveinteger" : "الرجاء إدخال integere موجب!",
  "ccm.agent.message.redisFailed" : "فشل الحصول على قفل Redis.",
  "ccm.urlconfig.message.addsuccess" : "النجاح في إضافة عناوين urls",
  "ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE" : "فشل تنزيل ملف التسجيل من خادم SFTP.",
  "ccm.transserverparam.label.sk" : "SK",
  "ccm.callreason.input.specialStrValidateFailed" : "لا يمكن أن يحتوي اسم سبب المكالمة على أحرف خاصة.",
  "ccm.agent.label.noAgents" : "معالجة المكالمات على Nobody On-Duty",
  "ccm.sysparam.url.safe.tip" : "HTTP غير آمن وقد يسبب مخاطر أمنية.",
  "ccm.contact.customer.config.value" : "قيمة البيانات",
  "ccm.agent.operation.CALLOUT" : "مكالمة خارجية",
  "ccm.speciallist.msg.crateerror" : "فشلت الإضافة",
  "ccm.agent.url.params.tips" : "لاحظ أن الطريقة التي يتم بها إجراء معلمة الحصول على طلب URL تعرض المعلمات الخاصة بك إلى عنوان مفتوح.",
  "ccm.skillgroup.message.nameExistence" : "اسم المجموعة المختصة موجود بالفعل.",
  "ccm.agent.label.agentType" : "نوع الوكيل",
  "ccm.agent.pageurls.accessaddr" : "عنوان الوصول",
  "ccm.agent.contact.robothungup" : "روبوت",
  "ccm.transtask.label.week" : "أسبوع",
  "ccm.verifyidentity.fail.CALLIDPARSEERROR" : "يجب أن يكون الرقم التسلسلي للمكالمة رقمًا.",
  "ccm.whatsapp.template.message.id" : "معرف رسالة القالب",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputparse" : "تاريخ البدء أو الانتهاء بتنسيق غير صحيح.",
  "ccm.transtask.label.agentOutBoundReport" : "تقرير ملخص مكالمة الموظف الصادرة",
  "ccm.agent.label.ivrChoice" : "تحديد IVR",
  "ccm.callout.message.nocallers" : "لم يتم اختيار أي متصلين للخروج",
  "ccm.agent.label.parameterType" : "طريقة قائمة الانتظار",
  "ccm.satisfactionconfig.select.ivr.process" : "يرجى تحديد تدفق استبيان الرضا IVR.",
  "ccm.custdata.operlog.uploadDataFile" : "تحميل ملف بيانات الجدول الأساسي",
  "ccm.ccagent.management.cannot.exceed.25" : "لا يمكن أن يتجاوز الرقم 25 حرفًا.",
  "ccm.urlconfig.modify.authtype3" : "لا شيء",
  "ccm.agent.leavemessage.notLoginIn" : "لم يقم الموظف بتسجيل الدخول.",
  "ccm.urlconfig.modify.authtype2" : "أووث 2.0",
  "ccm.agent.contact.customizes" : "التخصيص",
  "ccm.urlconfig.modify.authtype1" : "مفتاح مشترك",
  "iolp.workflow.nodetype.andsign" : "المهمة",
  "ccm.speciallist.msg.success" : "ناجح",
  "ccm.agent.label.withinSevenDays" : "في غضون 7 أيام",
  "ccm.agent.message.agentCTIFail" : "فشل الاستعلام عن الموظفين الذين تم تعديلهم في العبّارة.الرجاء التحقق من تكوين العبّارة.",
  "iolp.configur.servicelabel.label.serviceBeanId" : "معرّف خدمة bean الخاص بـ CustomizedService",
  "ccm.agent.button.export" : "تصدير",
  "ccm.satisfactionconfig.message.strategygroupdesc" : "وصف مجموعة النهج",
  "ccm.agent.label.authtype.uap" : "مصادقة UAP",
  "ccm.custdata.button.importData" : "استيراد البيانات",
  "ccm.skillgroup.label.groupid" : "معرف المجموعة المختصة",
  "ccm.custdata.button.importRecord" : "عرض نتيجة الاستيراد",
  "ccm.transserver.label.servername" : "اسم الخادم",
  "ccm.verifyidentity.label" : "مصادقة الهوية",
  "ccm.agent.label.waitVoiceConfig" : "تكوين قوائم الانتظار والانتظار",
  "ccm.agent.pageurls.create" : "جديد",
  "ccmanagement.tenantspace.option.weekday_sat" : "السبت",
  "ccm.urlconfig.modify.callnotify.ak.tooshort" : "يجب أن يحتوي المفتاح على 16 حرفًا على الأقل.",
  "ccm.agent.contact.noAuth" : "لا إذن.",
  "ccm.speciallevel.speciallist.levelName" : "اسم المستوى",
  "casemanagement.common.label.nodata" : "لا توجد بيانات.",
  "ccm.urlconfig.label.relurl.callback.alerttip" : "إذا لم يتم نقل عنوان URL لرد الاتصال عند استدعاء API لإنشاء مكالمة ثنائية الاتجاه، فسيتم استدعاء عنوان URL هذا مرة أخرى عند إصدار مكالمة ثنائية الاتجاه. خلاف ذلك ، يتم استدعاء عنوان URL المنقول مرة أخرى.",
  "ccm.speciallist.msg.userNo" : "رقم المشترك",
  "ccm.sysparam.error.validate.notmatchrulerangelength" : "تتجاوز معلمة الإدخال الحد الأقصى للطول أو أقل من الحد الأدنى للطول",
  "ccm.satisfactionconfig.message.grouppriority" : "الأولوية",
  "ccmanagement.tenantspace.option.weeklist_sectolast" : "أخيرًا ولكن واحد",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkLimit" : "لا يُسمح بأكثر من خمسة مقاعد.",
  "ccm.agent.restrecord.reststatus.normal.endrest" : "انتهت الراحة بشكل طبيعي",
  "ccm.satisfactionconfig.message.nofeedbacktype" : "لا يوجد استطلاع",
  "ccm.agent.pageurlparam.paramCountLimit" : "يمكن تكوين معلمات URL المنبثقة بحد أقصى {limit}.",
  "ccm.agent.accesscode.description" : "وصف رمز الوصول",
  "ccm.satisfactionconfig.message.strategygroupid" : "معرف مجموعة النهج",
  "ccm.contact.msg.dataverification" : "فشل التحقق من معلمة الطلب.",
  "ccm.satisfactionconfig.message.validtime" : "فترة الرد صالحة (دقيقة)",
  "ccm.querycustdata.msg.selectTable" : "تحديد اسم جدول",
  "ccm.urlconfig.message.modifysuccess" : "نجح تعديل عناوين urls",
  "ccm.contract.management.confirm" : "تحتوي الوثيقة على بيانات حساسة. هل أنت متأكد من رغبتك في المتابعة؟",
  "ccm.contract.management.confirmtitle": "تأكيد التشغيل",
  "ccm.transtask.label.dataname" : "اسم خادم التفريغ",
  "ccm.satisfactionconfig.message.createfailed" : "فشل تكوين استبيان الرضا الجديد",
  "ccm.agent.label.skillpara" : "تكوين معلمة المهارة",
  "ccm.custdata.button.moveUp" : "لأعلى",
  "ccm.note.config.agent.ring.back.tone" : "تكوين نغمة إعادة رنين الموظف",
  "ccm.agent.mobileagent.success" : "النجاح",
  "ccm.agent.message.deleteSkillAndAgentFail" : "فشل حذف المهارة والموظف.",
  "ccm.asr.create.case.chat.info" : "يمكنك النقر فوق إنشاء حالة فقط أثناء الدردشة أو بعد انتهاء الدردشة.",
  "ccm.agent.label.singlePhoneFlag" : "موظف الهاتف فقط",
  "ccm.pageinterface.objectList" : "قائمة الكائنات",
  "ccm.agent.message.refreshSkillException" : "تحديث استثناء المهارات.",
  "ccm.note.config.agent.page.windowTip" : "تذكير مربع الحوار المنبثق",
  "ccm.agent.contact.hungup" : "كيان قطع المكالمة",
  "ccm.agent.pageurls.pagename" : "اسم الصفحة",
  "ccm.agent.pageurls.operation" : "العملية",
  "ccm.agent.ten.maxCalls" : "الحد الأقصى للمكالمات الصوتية المتزامنة",
  "ccm.satisfactionconfig.message.strategychanneltitle" : "تكوين نهج القناة",
  "ccm.satisfactionconfig.add.strategychanneltitle": "إنشاء سياسة رضا القناة",
  "ccm.satisfactionconfig.update.strategychanneltitle": "تحرير سياسة رضا القناة",
  "ccm.custdata.msg.reachUpperTableNum" : "وصل عدد الجداول المخصصة إلى الحد الأعلى.",
  "ccm.agent.contact.sms" : "SMS",
  "ccm.agent.contact.Instagram": "Instagram",
  "ccm.agent.contact.Telegram": "Telegram",
  "ccm.agent.title.skillCreate" : "قائمة انتظار مهارات جديدة",
  "ccm.agent.label.scaleFactorRange" : "القيمة [0-10000]",
  "ccm.agent.ten.DSTStartTime" : "وقت بدء التوقيت الصيفي",
  "ccm.agent.restReason.delete" : "حذف",
  "ccm.agent.label.useTheSkill" : "تم ربط قائمة انتظار المهارات {0} بمعرف الموظف {1}. قم بحذف تكوين قائمة انتظار المهارة أسفل الموظف أولاً.",
  "ccm.urlconfig.label.numberAllocationMode2" : "محدد",
  "ccm.urlconfig.label.numberAllocationMode1" : "أوتوماتيكي",
  "ccm.custdata.msg.duplicateColumnName" : "اسم حقل مكرر. إعادة تسميته.",
  "ccm.speciallist.label.operTime" : "وقت الإنشاء",
  "ccm.agent.message.name.limit" : "الاسم طويل جدا.",
  "ccm.agent.message.name.grouplimit" : "لا يمكن أن يتجاوز الاسم 64.",
  "ccm.agent.login.ID" : "تسلسل العملية",
  "ccm.custdata.msg.notAllowOperation" : "هذه العملية غير مسموح بها. قم بتحديث الصفحة وحاول مرة أخرى.",
  "ccm.pageurls.select.urlParameter" : "معلمة الحصول على طلب URL",
  "ccm.recognition.queryAK.error" : "حدث خطأ عند الاستعلام عن معلومات مفتاح APP.",
  "ccm.agent.label.queuingCallsMaxNumSuffix" : "، تجاوز قائمة الانتظار.",
  "ccm.agent.operation.optype" : "نوع العملية",
  "ccm.agent.pageurls.confnaming" : ". تأكد من عدم وجود الاسم في النظام.",
  "ccm.sendmsg.whatsapp.withoutTemplate" : "لا يوجد محتوى. حدد قالبًا أولاً.",
  "ccm.agent.contact.multimedia.whatsapp" : "الوسائط المتعددة - WhatsApp",
  "ccm.skillgroup.message.nameLimit" : "اسم المجموعة المختصة طويل جداً.",
  "ccm.basicdata.error.firstlinenotmatch" : "السطر الأول في الملف الذي تم استيراده غير متناسق مع ذلك في القالب",
  "ccm.speciallevel.speciallist.restrictMode" : "وضع التقييد",
  "ccm.agent.ten.tenantName" : "اسم المستأجر",
  "ccm.transtask.label.task.chatrecordall.uniqueness" : "لا يمكنك إضافة كافة أنواع القنوات بسبب وجود مهام لبعض أنواع القنوات.",
  "ccm.agent.label.ordinaryAgent" : "الوكيل العام",
  "ccm.agent.label.restQueueRule.lessRest" : "وكيل مع أقل راحة في اليوم الحالي",
  "ccm.agent.label.operate" : "العملية",
  "ccm.title.template.select.sms" : "تحديد قالب SMS",
  "ccm.satisfactionconfig.message.update" : "تحرير",
  "ccm.basicpage.not.sk.download" : "لا يتوفر إذن تنزيل SK.",
  "ccm.speciallist.msg.userNum" : "رقم المستخدم",
  "ccm.agent.restReason.restCauseDesc" : "الوصف",
  "ccm.agent.message.searchAccount" : "أدخل معرف موظف.",
  "ccm.transtask.label.wednesday" : "الأربعاء",
  "ccm.agent.contact.CONNECT_FAIL_CODE" : "فشل الاتصال بخادم SFTP.",
  "ccm.satisfactionconfig.message.validatemaxnumber" : "يمكن إنشاء 100 مجموعة سياسة كحد أقصى.",
  "ccm.agent.label.modifymobileagent" : "تحرير رقم الهاتف",
  "ccm.sysparam.error.validate.notmatchrulerequired" : "معلمة الإدخال مطلوبة",
  "ccm.certificate.management.status.value.discard" : "تجاهل",
  "ccm.transtask.label.task.uniqueness" : "يمكن إنشاء مهمة بيانات تقرير واحدة فقط في نفس الفاصل الزمني للتفريغ لنفس نوع بيانات التفريغ.",
  "ccm.satisfactionconfig.message.sms" : "SMS",
  "ccm.common.label.no" : "لا",
  "ccm.recognition.label.openanonymoussuccess" : "نجح في تمكين إشارة المكالمة الصادرة المجهولة. الرجاء تحديد رمز وصول النقر للاتصال الذي تم تكوينه للمسار المطلوب.",
  "ccm.urlconfig.label.input.appSecret" : "SecretKey",
  "ccm.verifyidentity.fail.unknown" : "فشل الاستعلام",
  "ccm.contact.customer.config.writeBack" : "إعادة الكتابة إلى سجل جهات الاتصال",
  "ccm.satisfactionconfig.message.smstemplate" : "قالب SMS",
  "ccm.agent.Transfer.deviceAddr" : "عنوان جهاز الوجهة",
  "ccm.vcallcenter.config.popup.current.user.locked.error" : "تم قفل المستخدم الحالي وسيتم إلغاء قفله بعد 10 دقائق.",
  "ccm.agent.label.authtype.unified" : "المصادقة الموحدة",
  "ccm.speciallist.msg.selectonlyslreminder" : "يمكن تعديل تذكير قائمة خاص واحد فقط.",
  "ccm.satisfactionconfig.message.confirm" : "تأكيد",
  "ccm.agent.mobileagent.fail" : "فشل",
  "ccm.recognition.label.clicktocallaccesscode" : "رمز الوصول \"نقر للاتصال\"",
  "ccm.contactitemconfig.message.itemValidatefirst": "يجب أن يكون الحرف الأول حرفاً أو شرطة سفلية (_), أو علامة الدولار ($)",
  "ccm.contactitemconfig.message.itemValidateall": "إدخال شرط سفلي (_), علامة الدولار ($), حروف أو أرقام",
  "ccm.agent.message.itemNameValidateFail": "غير مسموح بالأحرف الخاصة التالية &<>\";%/[]=+{'@'}",
  "ccmanagement.satisfaction.strategy.deletefail" : "فشل الحذف.",
  "ccm.speciallist.msg.delete" : "حذف",
  "ccm.custdata.option.isMaskDisplay.yes" : "نعم",
  "ccm.satisfactionconfig.message.channelname" : "قناة",
  "ccm.urlconfig.message.deletesuccess" : "نجاح إعادة تعيين عناوين urls",
  "ccm.calledconfig.message.savemobileagentfail" : "فشل حفظ اقتران تكوين موظف الجوال",
  "ccm.agent.calledroute.recordexist" : "رمز الوصول مرتبط برمز الامتداد. الرجاء إدخال رمز ملحق جديد.",
  "ccm.calledconfig.message.nullparent" : "العقدة الأصلية غير موجودة.",
  "ccm.urlconfig.message.relurlnotinwhite" : "فشلت العملية. اتصل بمسؤول النظام لإضافة عنوان URL لرد الاتصال اليدوي إلى قائمة ثقة رد الاتصال.",
  "ccm.agent.operation.noMoreThan90Days" : "لا يمكن أن يتجاوز نطاق الوقت 90 يومًا.",
  "ccm.agent.calledroute.editfail" : "فشل تحرير البيانات المحلية.",
  "ccm.agent.contact.downloadFail" : "فشل التنزيل.",
  "ccm.agent.label.deviceUsageThresholdRange" : "القيمة [0-100]",
  "ccm.agent.page.release" : "الافراج",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnumber" : "تاريخ البدء أو الانتهاء بتنسيق غير صحيح.",
  "ccm.agent.label.CallOutAgent" : "موظف الاستدعاء",
  "ccm.agent.accesscode.accesscode" : "رمز الوصول",
  "ccm.agent.operation.opresult" : "النتيجة",
  "ccm.agent.contact.joinreason" : "سبب الإنضمام",
  "ccm.transserverparam.label.proxyUserName" : "اسم المستخدم",
  "ccm.urlconfig.message.warning" : "تأكد من تكوين محدد موقع المعلومات URL الخاص بـ CDR على ITA. خلاف ذلك\، لا يمكن لـ ITA دفع CDRs إلى CEC. مثال على دفع URL على النحو التالي:",
  "iolp.configur.service.label.name" : "الاسم",
  "ccmanagement.tenantspace.option.weeklist_sec" : "ثانياً",
  "ccm.label.contactexport.exceedlimit2" : ". تضييق نطاق وقت البيانات أو إضافة معايير تصفية البيانات.",
  "ccm.contact.detail.view.message.content" : "عرض محتوى الرسالة",
  "ccm.label.contactexport.exceedlimit1" : "يمكن تصدير {0} كحد أقصى سجلات البيانات",
  "ccm.agent.message.pageConfSuccess" : "تمت العملية بنجاح",
  "ccm.agent.Transfer.theAgent" : "الوكيل",
  "ccmanagement.satisfaction.strategy.acceptdate.enddate" : "تاريخ الانتهاء",
  "ccm.contactitemconfig.label.data.extended.Type" : "نوع البيانات",
  "ccm.recognition.label.breakDownVoice" : "انهيار صوت",
  "ccm.custdata.label.isMandatory" : "إلزامي",
  "ccm.calledconfig.message.overmaxnum" : "تم الوصول إلى الحد الأقصى لكمية {0}.",
  "ccm.agent.contact.calledfailed" : "فشل استدعاء الصادر",
  "ccm.agent.operation.optime" : "وقت العملية",
  "ccm.agent.export.agentinfo.tips" : "تحتوي البيانات المصدرة على بيانات شخصية يجب استخدامها وحمايتها بشكل صحيح.",
  "ccm.skillcreate.repeat.fail" : "اسم قائمة انتظار المهارة موجود بالفعل.",
  "ccm.agent.sysparamconfig.paramwarn" : "الرجاء تحديد قيمة معلمة صالحة.",
  "ccm.satisfactionconfig.satisfaction.switch" : "تبديل استبيان الرضا",
  "ccm.agent.message.isornoairecognition" : "الحالة الذكية",
  "ccm.agent.message.skillConfSuccess" : "تم تكوين معلومات قائمة انتظار المهارة بنجاح.",
  "ccm.custdata.msg.confirmAbandon" : "هل أنت متأكد من أنك تريد تجاهله؟",
  "ccm.speciallist.title.specialList" : "قائمة خاصة",
  "ccm.pageinterface.selectAttributeTitle" : "تحديد سمة الكائن",
  "ccm.pageinterface.selectedAttribute" : "السمة المحددة",
  "ccm.agent.contact.updatedate" : "تاريخ التحديث",
  "ccm.satisfactionconfig.message.staistip1" : "بعد تشغيل هذا التبديل\، سيتم نقل العميل إلى استطلاع الرضا بعد قطع الموظف الاتصال.",
  "ccm.callout.title.fail" : "فشل",
  "ccm.agent.contact.pictures" : "صور",
  "ccm.agent.contact.multimedia.email" : "الوسائط المتعددة-البريد الإلكتروني",
  "ccm.satisfactionconfig.message.strategygroupmemconfig" : "تكوين النهج",
  "ccm.agent.pageurlparam.paramIsConfiged" : "معلمة URL المنبثقة للشاشة أو معلمة بيانات النظام المقترنة موجودة بالفعل.",
  "ccm.agent.contact.workno" : "معرف الموظف",
  "ccm.agent.contact.preemptionCallout" : "مكالمة صادرة مُسبقة",
  "ccm.agent.button.delete" : "حذف",
  "ccm.satisfactionconfig.message.groupbatchdelete" : "حذف الحزمة",
  "ccm.certificate.management.expiretime" : "وقت انتهاء الصلاحية",
  "iolp.workflow.node.title.infoexplain" : "المعلومات والتفسير",
  "ccm.chatbot.query.name.fail" : "فشل الحصول على اسم الروبوت.",
  "ccm.agent.restReason.selectRestReasons.limit" : "يمكن حذف 10 أسباب للراحة كحد أقصى على دفعات.",
  "ccm.taskrecord.label.executeEndTime" : "وقت الانتهاء",
  "ccm.anonymous.message.fail.cti" : "فشل الاستعلام عن تدفق المكالمة الصادرة المجهولة على CTI",
  "ccm.speciallist.msg.typeidempty" : "لا يمكن أن يكون نوع القائمة الخاص فارغًا.",
  "ccm.agent.label.ifReleaseNoAnswerCall" : "لم يتم الرد على مكالمات الإصدار لفترة طويلة",
  "ccm.speciallist.label.startDate" : "الوقت الفعال",
  "ccm.agent.message.updateAgentAndSkillFail" : "فشل تحديث اقتران قائمة انتظار مهارة الموظف.",
  "ccm.sysparam.error.validate.ruleinvalid" : "قاعدة التحقق غير صالحة",
  "ccmanagement.tenantspace.option.weeklist_fir" : "أولاً",
  "ccm.callout.lable.reset" : "إعادة الضبط",
  "ccm.systemparam.success.save" : "تم حفظ المعلمة بنجاح.",
  "ccm.basicdata.msg.nodata" : "لا توجد بيانات",
  "ccm.agent.message.dataIntegrityFail" : "تم تكوين بيانات غير كاملة لتخطيط الصفحة أو مجموعة الواجهة. فشلت العملية.",
  "iolp.workflow.label.flowInfo" : "المعلومات والتفسير",
  "ccm.transtask.label.contactrcddata" : "الحقول في قائمة سجل جهات الاتصال",
  "ccm.contact.msg.inactivesuccess" : "نجح الإبطال.",
  "ccm.sysparam.error.save" : "أخطاء",
  "ccm.custdata.operlog.authFailed" : "فشل المصادقة",
  "ccm.agent.restrecord.reststatus.resting" : "يستريح",
  "ccm.agent.speciallist.addList" : "انضم إلى سجل التحرش",
  "ccm.agent.Transfer.CallForwarding" : "تحويل المكالمة",
  "ccm.recognition.label.closemobileagentsuccess" : "تم تعطيل موظف الجوال بنجاح.!",
  "ccm.custdata.msg.msgValidateFailed" : "لا يُسمح بالرموز الخاصة.",
  "ccm.agent.label.conditionOfBusyTransfer" : "إعادة التوجيه على حالة مشغول",
  "ccm.agent.message.cancelconfig" : "إلغاء التكوين",
  "ccm.contact.customer.config.visible" : "مرئي",
  "ccm.urlconfig.message.talkurlnotinwhite" : "فشلت العملية. اتصل بمسؤول النظام لإضافة URL الخاص برد الاتصال للاتصال إلى قائمة ثقة رد الاتصال.",
  "ccm.agent.login.logIP" : "عنوان IP لتسجيل الدخول",
  "ccm.agent.leavemessage.QUERU_LEAVEMESSAGE_FAIL_CODE" : "معلومات SFTP فارغة للاستعلام عن الرسائل.",
  "ccm.custdata.operlog.viewExportTask" : "تصدير عرض المهمة",
  "ccm.agent.ten.cobrowseMaxNumber" : "الحد الأقصى لعدد اتصالات التعاون في صفحة الويب",
  "ccm.agent.label.sipAccount" : "رقم الهاتف",
  "ccm.agent.label.calcOfMaxCallNum" : "الحد الأقصى لأسلوب حساب بيانات قائمة انتظار المكالمات",
  "ccm.agent.pageurls.errorsave" : "خطأ",
  "casemanagement.casedraft.label.casetype" : "نوع القضية",
  "iolp.workflow.executetype.email" : "البريد الإلكتروني",
  "ccm.certificate.management.certificatefile.certfile.sizeerror" : "لا يمكن أن يتجاوز حجم ملف الشهادة 20 كيلوبايت.",
  "ccm.satisfactionconfig.message.calltype" : "نوع الاتصال",
  "ccm.certificate.management.status.update.success" : "نجح تغيير حالة الشهادة.",
  "ccm.agent.title.specialListHis" : "تاريخ القائمة الخاصة",
  "ccm.custdata.label.relaBaseColumn" : "الحقل في الجدول السفلي",
  "ccm.transserverparam.label.isagent" : "تمكين الوكيل",
  "ccm.agent.contact.agent.recall" : "قام الموظف بسحب الرسالة",
  "ccm.transtask.label.sunday" : "الأحد",
  "ccm.agent.operation.AllOpType" : "جميع أنواع العمليات",
  "ccm.ivrflow.placeholder.ivrflowdesc" : "يرجى إدخال وصف تدفق IVR.",
  "ccm.agent.label.skills" : "قائمة انتظار المهارات",
  "ccm.agent.label.serviceCode": "نوع الخدمة",
  "ccm.agent.label.duration": "المدة الزمنية",
  "ccm.agent.label.queueTime": "مدة الانتظار",
  "ccm.agent.label.availabilityTime": "المدة المتاحة",
  "ccm.agent.label.mutetime": "مدة الصمت",
  "ccm.agent.label.calltime": "لا توجد مدة كتم للمكالمة",
  "ccm.agent.label.ringingTime": "مدة الرنين",
  "ccm.agent.label.serviceLevel": "النسبة المئوية لوقت الانتظار (%)",
  "ccm.agent.label.transferInformation": "نقل أم لا",
  "ccm.agent.label.transfer": "عدد عمليات التحويل",
  "ccm.agent.label.ivrUsage": "عدد المرات التي تم فيها إعادة توجيه المكالمات إلى IVR",
  "ccm.agent.label.originId": "معرف عمل المحول",
  "ccm.agent.label.queueName": "إعادة توجيه قائمة انتظار المهارات",
  "ccm.agent.label.wrapUpCode": "الحالة العامة",
  "ccm.agent.label.uuiInfo": "معلومات uui",
  "ccm.agent.label.overallState": "الحالة العامة",
  "ccm.agent.ten.dstOn" : "ممكّن",
  "ccm.urlconfig.message.overlimit.appKey" : "لا يمكن أن يتجاوز مفتاح التطبيق في تكوين مكالمة ITA ثنائية الاتجاه 128 حرفًا.",
  "ccm.satisfactionconfig.message.choosestrategy" : "*اختر استراتيجية استبيان الرضا",
  "ccm.transtask.label.tuesday" : "الثلاثاء",
  "ccm.botherlist.label.approveStatus.approved" : "تمت الموافقة",
  "ccm.speciallist.button.import" : "استيراد",
  "ccm.speciallist.title.import": "استيراد قائمة خاصة",
  "ccm.agent.label.scaleFactorPrefix" : "عندما يتجاوز عدد مكالمات قائمة الانتظار (",
  "ccm.callreason.label.callreason" : "سبب المكالمة",
  "ccm.callreason.title.callreason": "سبب المكالمة المقترن",
  "ccm.callreason.label.parentcallreason": "سبب المكالمة الأصلية",
  "ccm.callReason.title.createCallReason": "إنشاء سبب مكالمة",
  "ccm.callReason.title.editCallReason": "تحرير سبب المكالمة",
  "ccm.agent.operation.MUTE" : "كتم الصوت",
  "ccm.satisfactionconfig.message.smschannel" : "قناة SMS Push",
  "ccm.agent.label.inputendworkno" : "الرجاء إدخال معرف الموظف النهائي",
  "ccm.agent.contact.endtime" : "وقت الانتهاء",
  "ccm.agent.leavemessage.status.toBeProcessed" : "ليتم معالجتها",
  "ccm.speciallist.msg.maxCountQuery" : "حجم البيانات الحالي كبير جدًا. يعرض النظام أحدث {0} سجلات بيانات فقط.",
  "ccm.agent.login.FORCELOGOUT" : "فرض تسجيل الخروج",
  "ccm.speciallist.label.blocklist" : "قائمة الحظر",
  "ccm.speciallist.msg.userno.error" : "تنسيق رقم المشترك غير صحيح.",
  "ccm.agent.leavemessage.downloadLeaveMessage" : "تنزيل الرسالة",
  "ccm.transserver.label.obsnotinwhite" : "فشلت العملية. اتصل بمسؤول النظام لإضافة عنوان خدمة OBS إلى قائمة ثقة خادم OBS لتفريغ الموارد.",
  "ccm.agent.label.bindTheSkill" : "قبل استخدام قائمة انتظار المهارات\، قم بإلغاء ربط المسار المطلوب أولاً.",
  "ccm.agent.message.willDeleteSkills" : "هل تريد بالتأكيد حذف قوائم انتظار المهارات المحددة؟",
  "ccm.agent.message.willDeleteSkillGroups": "هل تريد بالتأكيد حذف المجموعات المختصة المحددة؟",
  "ccm.transtask.label.discard" : "تجاهل",
  "ccm.contact.customer.config.datasource.customData" : "البيانات المحلية",
  "ccm.contactitemconfig.label.data.extended.dataset.source.interface" : "واجهة مصدر مجموعة البيانات",
  "ccm.speciallevel.tip.start" : "هل تريد بالتأكيد تمكينه؟",
  "ccm.agent.contact.calledhangup" : "نهاية المكالمة\، يتم استدعاء قطع المكالمة",
  "iolp.configur.servicelabel.delete.successful" : "تم الحذف بنجاح.",
  "ccm.agent.sysparamconfig.outboundtimecheck" : "يجب أن يتراوح الحد الزمني للمعاينة التلقائية للمكالمة الصادرة من 1 ثانية إلى 3600 ثانية.",
  "ccm.agent.operation.UNMUTE" : "إلغاء كتم الصوت",
  "ccm.agent.title.mobileagentModify" : "تعديل MobileAgent",
  "ccm.common.ajax.searchErr" : "خطأ في البحث.",
  "ccm.speciallist.msg.successNum" : "{limit} نجح",
  "ccm.speciallist.msg.transferToHistory" : "نقل إلى التاريخ",
  "iolp.workflow.node.label.executeType" : "نوع التنفيذ",
  "ccm.certificate.management.certificatefile.maxrecord" : "الحد الأقصى لعدد الشهادات هو {0}.",
  "ccm.custdata.msg.selectItem" : "حدد عنصرًا قبل الفرز.",
  "ccm.agent.contact.userhungup" : "المستخدم",
  "ccm.basicdata.msg.deletesuccess" : "تم حذف البيانات الأساسية بنجاح.",
  "casemanagement.user.button.cancel" : "إلغاء",
  "ccm.agent.videoDownload.RSETimeout" : "انتهت مهلة طلب خدمة RSE",
  "ccm.agent.contact.playcomfirm" : "تأكيد التشغيل",
  "ccm.agent.label.usedMode.sipAccount" : "حساب Sip",
  "ccm.agent.message.modifyMobileAgentConfSuccess" : "تم تعديل معلومات MobileAgent بنجاح.",
  "ccm.agent.label.CommomAgent" : "الوكيل العام",
  "ccm.calledconfig.message.deletemobileagent" : "حذف وكيل الجوال",
  "ccmanagement.satisfaction.strategy.delete" : "حذف",
  "ccm.basicdata.error.datatypenotmatch" : "تنسيق البيانات غير متناسق مع تنسيق تعريف الحقل",
  "ccm.custdata.label.dataImportRecord" : "سجل استيراد البيانات",
  "ccm.botherlist.label.approveStatus.rejected" : "مرفوض",
  "ccm.agent.message.samePageNameFail" : "اسم صفحة مكرر.",
  "ccm.transtask.label.contactchoose" : "حقول مخصصة في سجل جهات الاتصال",
  "ccm.contact.customer.config.fonts" : "الخط",
  "iolp.configur.servicelabel.tips.info" : "معلومات",
  "ccm.agent.operation.CANCELWHISPER" : "إلغاء الهمس",
  "ccm.transtask.label.taskname" : "اسم المهمة",
  "ccm.urlconfig.label.input.domainName" : "اسم مجال ITA",
  "ccm.agent.message.agenttypecannotbenull" : "حدد نوع موظف إذا تم تحديد قائمة انتظار المهارة.",
  "ccm.transtask.label.updateexsit" : "فشلت العملية لأن اسم الملقم موجود بالفعل.",
  "ccm.agent.contact.callernook" : "المتصل الخارجي لا يوجد رد",
  "ccm.certificate.management.status.confirmDiscard" : "يُرجى التحقق مما إذا كانت الشهادة غير مستخدمة في أي سيناريو خدمة. وتجاهل هذه الشهادة؟",
  "ccm.agent.message.nocomments" : "لا تعليقات",
  "ccm.agent.resetskillrecord.skillafteradjust" : "قائمة انتظار المهارة بعد التعديل",
  "ccm.satisfactionconfig.message.calltime" : "مدة المكالمة",
  "ccm.agent.operation.noMoreThan1Days" : "لا يمكن أن يتجاوز نطاق الوقت يومًا واحدًا.",
  "ccm.agent.calledroute.delete.work.use" : "تمت الإشارة إلى تكوين الطرف المطلوب بواسطة الموظف ولا يمكن حذفه.",
  "ccm.custdata.label.failNum" : "عدد السجلات الفاشلة",
  "ccm.urlconfig.label.talkmethod" : "طريقة اتصال Callback",
  "ccm.transserver.delete.css.confirm" : "هل تريد بالتأكيد حذف تكوين CSS؟",
  "ccm.recognition.label.characteristics" : "ميزة",
  "ccm.sysparam.config.save" : "حفظ",
  "ccm.label.contactexport.exception" : "حدث خطأ عند إنشاء مهمة التصدير. تحقق من السجلات.",
  "ccm.skillgroup.label.orgname" : "المنظمة",
  "ccm.urlconfig.message.overlimit.appId" : "لا يمكن أن يتجاوز مفتاح الوصول الذي تم تكوينه لمكالمات ITA ثنائية الاتجاه 128 حرفًا.",
  "ccm.agent.contact.recordingfiles" : "ملف التسجيل",
  "ccm.note.config.policy" : "نغمة عودة رنين الموظف",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility" : "سجل الاتصال المرئي",
  "ccm.agent.restReason.updateFailed" : "فشل التعديل.",
  "ccm.label.contactexport.nocount" : "لا يوجد سجل جهة اتصال يفي بالشروط.",
  "ccm.label.dualcallexport.nocount": "لا توجد سجلات مكالمات مزدوجة مؤهلة.",
  "ccm.agent.label.skillType" : "نوع المهارة",
  "ccm.agent.message.willcancelconfig" : "إذا قمت بإلغاء التكوين\، فسيكون غير متاح للموظفين الذين يستخدمون التكوين. هل تريد بالتأكيد إلغاء التكوين؟",
  "ccm.agent.tips.confSuccess" : "تم التهيئة بنجاح.",
  "ccm.agent.tips.noagent": "لا يوجد موظف في مقطع الرقم المحدد.",
  "ccm.calledconfig.message.nocalled" : "الرجاء اختيار الاتصال",
  "ccm.contact.customer.config.datasource.msgDataset" : "مجموعة بيانات المعلومات",
  "ccm.agent.leavemessage.view" : "عرض الرسائل",
  "ccm.agent.resetskillrecord.adjustedagentworkno" : "معرف الموظف الذي تم تعديله",
  "ccm.agent.message.queryctiagentmessage" : "فشل العثور على معلومات الموظف في CTI.",
  "ccm.recognition.label.processaccesscode" : "أدخل رمز وصول تدفق.",
  "ccm.pageinterface.pleaseSelectAttribute" : "حدد سمة كائن.",
  "ccm.agent.contact.transferRecord" : "سجل النقل",
  "ccm.speciallist.msg.limit" : "عدد المحتويات المستوردة يتجاوز 1000",
  "ccm.callreason.label.enable" : "تمكين",
  "ccm.certificate.management.status" : "حالة الشهادة",
  "ccm.transtask.label.start" : "ابدأ",
  "ccm.custdata.label.description" : "الوصف",
  "ccm.agent.ten.contactInfor" : "طريقة الاتصال",
  "ccm.agent.ten.versatileagents" : "وكلاء متعددي الاستخدامات",
  "ccm.sysparam.config.title" : "معلمات المستأجر",
  "ccm.transserver.label.css" : "خادم CSS",
  "ccm.agent.contact.callout" : "مكالمة صادرة",
  "ccm.agent.leavemessage.contactNumber" : "رقم الاتصال",
  "ccm.satisfactionconfig.message.flowname" : "اسم العملية",
  "ccmanagement.satisfaction.strategy.success" : "النجاح",
  "ccm.agent.calledroute.create" : "جديد",
  "ccm.callreason.label.allreason" : "جميع الأسباب",
  "ccm.agent.message.modifyMobileAgentConfFail" : "فشل تعديل معلومات MobileAgent.",
  "ccm.taskrecord.label.executeSuccess" : "ناجح",
  "ccm.agent.label.queuingCallsMaxNumPrefix" : "عندما يتجاوز عدد مكالمات قائمة الانتظار",
  "ccm.agent.message.agentnummaxoperation" : "اختيار ما يصل إلى 100 وكيل.",
  "ccm.speciallist.tip.timeVerification" : "حدد وقتًا بعد الوقت الحالي وقبل 2038-01-19.",
  "ccm.skillgroup.message.noskill" : "لا يمكن أن تكون قائمة مهارات المجموعة المختصة فارغة.",
  "ccm.agent.leavemessage.channel" : "قناة",
  "iolp.workflow.node.title" : "نجح تعيين سمات العقدة",
  "ccm.agent.calledroute.skill" : "قائمة انتظار المهارات",
  "ccm.certificate.management.certificatefile.certcodeerror" : "رمز الشهادة هو خطأ.",
  "ccm.agent.label.parameter" : "المعلمة 1",
  "ccm.satisfactionlevel.message.savesuccess" : "تم حفظ مستوى الرضا بنجاح.إذا تم تكوين عملية الرضا\، حررها مرة أخرى في \"إدارة التدفق\". وإلا\، سيتم عرض نتيجة الرضا بشكل غير صحيح. سيستند استطلاع الرضا الجديد إلى نتائج هذا التكوين.",
  "ccm.transserverparam.label.agentport" : "ميناء",
  "ccm.dual.call.record.noMatchingDualCallRecordExists" : "لا يوجد سجل مكالمة مزدوجة مطابقة.",
  "ccm.custdata.operlog.success" : "النجاح",
  "ccm.agent.contact.status" : "حالة الوثيقة",
  "ccm.agent.message.addAIRecogFail" : "لا يمكن أن يتجاوز عدد الموظفين الذكيين الممكّنين عدد الموظفين المخصصين عند تمكين المستأجر.",
  "ccm.sysparam.error.validate.notmatchruleip" : "الرجاء إدخال عنوان IPV4 أو IPV6 صالح",
  "iolp.configur.servicelabel.select.node" : "حدد عقدة.",
  "ccm.recognition.label.closeIvrAgentSuccess" : "تم تعطيل مساعد العامل الذكي بنجاح.",
  "ccm.agent.restrecord.rest.duration" : "مدة الراحة",
  "ccm.note.config" : "تكوين نغمة الرنين الخلفي",
  "ccm.note.select": "تحديد نغمة رنين للخلف",
  "ccm.agent.label.callDispatchModel" : "وضع تخصيص المكالمات",
  "ccm.agent.mobileagent.label.deletehint" : "هل أنت متأكد من رغبتك في الحذف؟",
  "ccm.agent.message.specialCharacters" : "يحتوي الرقم على أحرف وأحرف خاصة.",
  "ccm.agent.calledroute.extcodewarn" : "يمكن أن يحتوي رمز الامتداد على أرقام فقط.",
  "ccm.transserverparam.label.ak" : "AK",
  "ccm.agent.contact.talkRemark" : "ملاحظات المكالمات",
  "ccm.agent.password.wrong" : "فشل التحقق من كلمة المرور.",
  "ccm.agent.label.scaleFactorRangeTwo" : "القيمة [1-10000]",
  "ccm.agent.contact.otherhungup" : "أخرى",
  "ccm.anonymous.message.fail.paranull" : "رمز الوصول في المعلمة فارغ",
  "ccm.recognition.label.fail" : "فشل",
  "ccm.agent.mobileagent.label.choosehint" : "الرجاء اختيار الوكلاء قبل حذفهم!",
  "ccm.basicdata.msg.error" : "خطأ",
  "ccm.agent.leavemessage.assignFailed" : "فشل في تخصيص الرسالة.",
  "ccm.common.label.success" : "ناجح",
  "iolp.workflow.node.label.associateKbs" : "المعارف ذات الصلة",
  "ccm.contact.label.inactive" : "منتهية الصلاحية",
  "ccm.satisfactionconfig.message.save" : "حفظ",
  "ccm.agent.label.queuingCallsMaxNumRangeTwo" : "القيمة [1-10000]",
  "ccm.speciallist.msg.level" : "مستوى قائمة خاص",
  "ccm.agent.message.agentnumreachmax" : "وصل عدد نوع الموظف المستهدف بالفعل إلى الحد الأقصى",
  "ccm.agent.message.queryCallCenterFail" : "فشل الاستعلام عن مركز المكالمات.",
  "ccm.whatsapp.select.whatsApp.message.template" : "الرجاء تحديد رسالة قالب WhatsApp.",
  "ccm.speciallist.msg.suffix" : "امتداد اسم الملف غير صحيح",
  "ccm.transtask.label.skillAccessCodeReport" : "تقرير حركة مرور قائمة انتظار المهارات بواسطة AccessCode",
  "ccm.agent.callreason.exceedmaxnum" : "يُسمح بخمسة أسباب اتصال كحد أقصى.",
  "ccm.agent.recall" : "رد الاتصال",
  "ccm.agent.message.inSpecialStrFail" : "لا يمكن أن يحتوي اسم الصفحة على أحرف خاصة.",
  "ccm.custdata.msg.importDataSuccess" : "جاري تصدير البيانات...عرض التفاصيل في نتيجة الاستيراد.",
  "ccm.pageinterface.objectName" : "اسم الكائن",
  "ccm.agent.contact.line" : "LINE",
  "ccm.basicdata.msg.deletefail" : "فشل حذف البيانات الأساسية.",
  "ccm.transtask.label.chatRecord" : "بيانات دردشة الوسائط المتعددة",
  "ccm.notifition.sendmessage.smsconfignull" : "تكوين الرسالة النصية فارغ. اختر مركز التكوين > تكوين الوصول > تكوين SMS لتكوين معلومات SMS.",
  "ccm.agent.calledroute.edit" : "تحرير",
  "ccm.label.contactexport.selectdata" : "تحديد البيانات",
  "ccm.agent.ten.companyName" : "الشركة",
  "ccm.contact.customer.config.writeBackField" : "حقل كتابة سجل جهات الاتصال الموسع",
  "ccm.agent.label.selectagent" : "الوكلاء المختارون",
  "ccm.agent.sysparamconfig.worknorangewarn" : "يجب أن يتراوح معرف الموظف من 101 إلى 50000.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.startafterend" : "يجب أن يكون وقت البدء قبل وقت الانتهاء.",
  "ccm.agent.conditionOfBusyTransfer.error" : "قم بتكوين معلمات شرط CFB.",
  "ccm.label.page.createPage" : "صفحة جديدة",
  "ccm.label.page.modifyPage" : "تعديل الصفحة",
  "ccm.dual.call.record.endTime" : "وقت انتهاء المكالمة",
  "ccm.recognition.label.cancelorderfailpd" : "فشل إلغاء الاشتراك. حاول مرة أخرى لاحقًا.",
  "ccm.transtask.label.ivrReport" : "تقرير بيانات IVR",
  "ccm.satisfactionconfig.message.strategychannelid" : "معرف سياسة القناة",
  "ccmanagement.agent.batchmodify.agentnum" : "حسب معرف الموظف",
  "ccm.agent.title.selectskill" : "تحديد قائمة انتظار المهارات",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD" : "الكلمة الأساسية",
  "ccm.satisfactionconfig.web.switch.remark" : "بعد تشغيل هذا التبديل، سيتم إرسال استبيان الرضا إلى العميل بعد اكتمال الخدمة.",
  "ccm.basicdata.msg.modifyfail" : "فشل تعديل البيانات الأساسية.",
  "ccm.querycustdata.msg.noData" : "لا توجد بيانات",
  "ccm.agent.operation.countid" : "رقم الحساب",
  "ccm.sysparam.config.select.count" : "يمكن اختيار 10 معلمات كحد أقصى.",
  "ccm.agent.pageurls.errorinfo" : "فشل الإنشاء.",
  "ccm.taskrecord.label.executing" : "التنفيذ",
  "ccm.agent.label.callDispatchModel.best" : "الأمثل",
  "ccm.agent.protocol.check" : "لاحظ أن بروتوكول الشبكة الذي يستخدمه عنوان url ليس بروتوكولًا آمنًا. إذا قررت استخدام هذا البروتوكول، فقد توجد مخاطر أمنية.",
  "ccm.custdata.operlog.download" : "تحميل",
  "ccm.agent.restReason.create" : "إضافة",
  "ccm.agent.calledroute.operation" : "العملية",
  "iolp.workflow.node.label.name" : "الاسم",
  "ccm.agent.ten.maxVideoAgents" : "كمية وكيل الفيديو",
  "ccm.calledconfig.table.skillormumbers" : "جهاز الغرض",
  "ccm.agent.contact.recordtime" : "مدة (مدة) التسجيل",
  "ccm.agent.operation.searchworkno" : "الرجاء إدخال معرف الموظف",
  "ccm.certificate.management.certificatecrlfile" : "ملف CRL",
  "ccm.agent.ccagentinfo.unregister.success" : "نجاح إلغاء التسجيل",
  "ccm.satisfactionconfig.message.surveytype" : "نوع الاستطلاع",
  "ccm.chatbot.pop.title" : "اختيار الروبوت",
  "ccm.ivr.label.processfilename" : "اسم الملف",
  "ccm.custdata.label.template" : "_قالب",
  "ccm.agent.restReason.createReason" : "إضافة سبب الراحة",
  "ccm.agent.leavemessage.auditAccount" : "حساب التدقيق",
  "ccm.label.contactexport.parainnull" : "حدد البيانات المراد تصديرها.",
  "ccm.agent.message.searchSkillQueueName" : "اسم قائمة انتظار المهارة",
  "ccm.callreason.label.status" : "الحالة",
  "ccm.contact.customer.config.writeBackFieldError" : "لا يمكن استخدام حقل إعادة كتابة سجل جهة الاتصال الموسّع.",
  "ccm.contact.customer.config.dataItemCodeError": "معرف عنصر البيانات غير صحيح.",
  "ccm.pageurls.select.externalInterface" : "الصفحة الخارجية",
  "ccm.agent.pageurls.independentlynosupported" : "تكوين الصفحة الداخلية غير معتمد للفتح المستقل.",
  "ccm.taskrecord.message.reexecutetranstasksuccess" : "تمت إعادة التنفيذ بنجاح.",
  "ccm.custdata.label.isMaskDisplay" : "ملثمين",
  "ccm.calledconfig.message.deletemobileagentsuccess" : "تم حذف تكوين الموظف المتنقل بنجاح",
  "ccm.agent.calledroute.ivr" : "الرجاء اختيار IVR",
  "ccm.certificate.management.certificatefile.certpwderror" : "كلمة مرور الشهادة خاطئة.",
  "ccm.transtask.label.isOpenCssServer" : "يحدد هل سيتم تمكين CSS.",
  "ccm.agent.message.redislocknull" : "فشل الحصول على قفل Redis.",
  "ccm.transtask.label.taskupload" : "ملقم التحميل",
  "ccm.custdata.msg.dictionaryInputFormat" : "تنسيق: مفتاح 1: القيمة 1 \، مفتاح 2: القيمة 2",
  "ccm.agent.label.busyRate" : "استخدام قائمة الانتظار (%)",
  "ccm.agent.message.allExport" : "تصدير الكل",
  "ccm.skillgroup.create.fail" : "فشل إنشاء المجموعة المختصة.",
  "ccm.satisfactionconfig.message.surveyconfig" : "تكوين الاستطلاع",
  "ccm.custdata.operlog.refresh" : "تحديث",
  "ccm.agent.label.adjustTime" : "المدة (المدة) في حالة الترتيب",
  "ccm.agent.contact.ivrhungup" : "إعادة التوجيه إلى IVR",
  "iolp.configur.service.label.imageUrl" : "صورة Url",
  "ccm.agent.button.edit" : "تحرير",
  "ccm.botherlist.label.approveComment.approved" : "رفض",
  "ccm.agent.verify.fail" : "فشل التحقق.",
  "ccm.callreason.label.setcallreason" : "تكوين سبب المكالمة",
  "ccm.agent.ten.vdnid" : "معرف VDN",
  "ccm.message.center.nocitiction.sending.records.status" : "حالة الإرسال",
  "ccmanagement.satisfaction.strategy.fail" : "فشل",
  "ccm.pageinterface.interfaceName" : "اسم الواجهة",
  "ccm.agent.button.save" : "حفظ",
  "ccm.certificate.management.certificate.tip" : "نصائح:",
  "ccm.sysparam.error.validate.notmatchruleurl" : "الرجاء إدخال عنوان URL صالح",
  "ccm.label.contactexport.selectdatafield" : "حقل التصدير",
  "ccm.speciallist.msg.alreadyexist" : "لديها بالفعل تذكير قائمة خاصة من نفس النوع والمستوى.",
  "ccm.verifyidentity.verifyName" : "وضع المصادقة",
  "ccm.title.org.all": "يرجى تحديد منظمة",
  "ccm.title.template.select.email" : "تحديد قالب البريد الإلكتروني",
  "ccm.agent.message.samepassword" : "لا يمكن أن تكون كلمة المرور الجديدة مماثلة لكلمة المرور الحالية",
  "ccm.agent.button.close" : "إغلاق",
  "ccm.agent.message.modifymobileagentfail" : "فشل تعديل المعلومات الخاصة برقم الهاتف المحمول الخاص بموظف الجوال.",
  "ccm.custdata.option.columnType.time" : "DateTime",
  "ccm.agent.pageurls.confdelete" : "تأكيد الحذف",
  "ccm.callout.lable.type" : "نوع المعلمة",
  "ccm.agent.videoDownload.noPermission" : "ليس لدى الموظف إذن بالتنزيل",
  "ccm.satisfactionconfig.message.callskill" : "قائمة انتظار المهارات",
  "ccm.verifyidentity.fail.VERIFYTIMERANGEERROR" : "نطاق وقت الاستعلام غير صحيح.",
  "ccm.skillgroup.msg.deleteFailed" : "فشل حذف المجموعة المختصة.",
  "ccm.custdata.msg.fileSuffixValidateFail" : "تنسيق ملف غير صحيح. حدد ملف CSV.",
  "ccm.agent.operation.FORCEBUSY" : "القوة مشغول",
  "ccm.agent.label.deleteAgentFailed" : "تم ربط قائمة انتظار المهارات بحساب أعمال. احذف تكوين قائمة انتظار المهارة للموظف أولاً.",
  "ccm.agent.label.afterVoiceSelect" : "صوت بعد معرف الموظف",
  "ccm.agent.label.sensitiveWordNumber" : "كلمات ممنوعة",
  "ccm.agent.message.selectAgents" : "يرجى تحديد موظف.",
  "ccm.agent.operation.INTERCEPT" : "اعتراض",
  "ccm.agent.message.airecognull" : "علامة التعرف الذكي فارغة.",
  "ccm.agent.leavemessage.auditcomments" : "تعليقات التدقيق",
  "ccm.agent.contact.downloadrecord" : "تنزيل التسجيل",
  "ccm.certificate.management.certificatefile.certsceneerror" : "مشهد الشهادة هو خطأ.",
  "ccm.agent.contact.playLeaveMessage" : "يتم تشغيل الرسالة التالية:",
  "ccm.transtask.label.friday" : "الجمعة",
  "ccm.pageinterface.viewAttribute" : "عرض سمة الكائن",
  "ccm.agent.contact.videoclicktodial" : "فيديو انقر للاتصال",
  "ccm.agent.title.specialListReminder" : "تذكير قائمة خاصة",
  "ccm.verifyidentity.SUCCESS" : "تمرير",
  "ccm.agent.pageurls.delfail" : "فشل الحذف.",
  "ccm.agent.label.deviceUsageThresholdSuffix" : "، قائمة الانتظار مشغولة.",
  "ccm.callout.message.editfail" : "فشل تحرير متصل وسيلة الشرح",
  "ccm.skillcreate.maxnumber.failed" : "لا يمكن أن يتجاوز عدد قوائم انتظار المهارات المستأجرة الحد الأعلى:",
  "ccm.agent.pageurls.delsuccess" : "تم الحذف بنجاح.",
  "ccm.whatsapp.whatsApp.channel" : "قناة WhatsApp",
  "ccm.agent.calledroute.placeholder.accesscode" : "يُرجى تحديد رمز الوصول.",
  "ccm.calledroute.title.selectaccesscode" : "تحديد رمز الوصول",
  "ccm.calledroute.tip.selectaccesscode": "يرجى تحديد رمز وصول أولاً.",
  "ccm.certificate.management.certificatecrlfile.suffixerror" : "يجب أن يكون ملف قائمة إبطال الشهادات بتنسيق in.crl.",
  "ccm.contactitemconfig.label.contact.extended.information.dataset" : "مجموعة بيانات معلومات جهات الاتصال الموسعة",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputparse" : "وقت البدء أو الانتهاء بتنسيق غير صحيح.",
  "ccm.agent.label.positiveEmotionNumber" : "إيجابية",
  "ccm.agent.message.ctipasswdrepeat" : "لا يمكن أن تكون كلمة المرور الجديدة مماثلة لآخر خمس كلمات مرور قديمة.",
  "ccm.contactitemconfig.message.confOnlyDataItemCode" : "فشلت العملية لأن معرف عنصر البيانات موجود بالفعل.",
  "ccmanagement.satisfaction.strategy.addsuccess" : "تم الحفظ بنجاح.",
  "ccm.skillgroup.update.failtip" : "فشل تحديث المجموعة المختصة. مزامنة قائمة انتظار المهارات وبيانات المجموعة المختصة أولاً",
  "ccmanagement.satisfaction.strategy.hourformatvalidate" : "الساعة بتنسيق غير صحيح.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputlength" : "لا يمكن أن يتجاوز طول وقت البدء أو الانتهاء الحد الأعلى.",
  "ccm.agent.restrecord.rest.currentstatus" : "الحالة الحالية",
  "ccm.certificate.management.certificatefile.status.error" : "تم تجاهل الشهادة ولا يمكن تعديلها.",
  "ccm.transserver.label.css.serveraddr" : "عنوان CSS",
  "ccm.contactitemconfig.message.confOnlyDatasetName" : "فشلت العملية لأن اسم مجموعة البيانات موجود بالفعل.",
  "ccm.agent.contact.callin" : "مكالمة واردة",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnumber" : "يجب أن يكون وقت البدء أو الانتهاء رقمًا.",
  "ccm.systemparam.error.reset" : "فشل في إعادة تعيين المعلمات.",
  "ccm.calledconfig.message.noskill" : "الرجاء اختيار قائمة انتظار المهارات",
  "ccm.agent.message.searchAccountName" : "أدخل حسابًا.",
  "ccm.transtask.label.restores" : "استعادة",
  "ccm.agent.contact.callid" : "معرف المكالمة",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.overlimit" : "يمكن حفظ 100 نهج تاريخ معالجة كحد أقصى.",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.no" : "لا",
  "ccm.agent.label.cti" : "cti:",
  "ccm.verifyidentity.NOTRETURN" : "لم يتم إرجاعها",
  "ccm.custdata.button.return" : "العودة",
  "ccm.agent.calledroute.require" : "أدخل جميع الحقول الإلزامية.",
  "ccm.agent.calcOfMaxCallNum.error" : "قم بتكوين الحد الأقصى لعدد مكالمات قائمة الانتظار.",
  "ccm.recognition.label.calledroutesuccess" : "تم تكوين المسار المطلوب بنجاح",
  "ccm.contact.msg.dataEmpty" : "بيانات العملية غير موجودة في AICC.",
  "iolp.workflow.node.label.nodeType" : "نوع العقدة",
  "ccm.label.downloadAttachment.confirm" : "هل تريد بالتأكيد تنزيل المرفق المحدد؟",
  "ccm.recognition.label.ordersucess" : "نجح الاشتراك.",
  "ccm.agent.calledroute.createfail" : "فشلت إضافة البيانات المحلية.",
  "ccm.case.title.selectWorkOrder" : "تحديد فئة طلب عمل",
  "ccm.urlconfig.pageurls.urlvalidate" : "يجب أن تبدأ عناوين URL بـ http:// أو https://",
  "ccm.skillgroup.create.failtip" : "فشل إنشاء المجموعة المختصة. مزامنة قائمة انتظار المهارات وبيانات المجموعة المختصة أولاً",
  "ccm.agent.button.create" : "جديد",
  "ccm.anonymous.message.fail.open" : "فشل فتح العلامة. التحقق من تكوين CTI",
  "ccm.idauth.create.error.name" : "وضع المصادقة موجود.",
  "ccm.sysparam.config.item.detail" : "معلومات المعلمة",
  "ccm.agent.callacceptno.set" : "تعيين",
  "ccm.transtask.label.agentOperationReport" : "تقرير عملية اتصال الموظف",
  "ccm.agent.label.paramsConfig" : "تكوين معلمة النظام",
  "ccm.agent.updateSoftPhone.queryNodeFailed" : "فشل الاستعلام عن معلومات عقدة المستأجر.",
  "ccm.transserver.label.servertype" : "النوع",
  "ccm.agent.recall.fail" : "فشل في معاودة الاتصال بالعميل.",
  "ccm.agent.contact.callednook" : "الصادر بدون إجابة",
  "ccm.custdata.label.isEncrypt" : "مشفرة",
  "ccm.agent.contact.satiscomment" : "تقييم الرضا",
  "ccm.agent.pageurlparam.paramLengthLimit" : "لا يمكن أن يتجاوز طول المعلمة 64 حرفًا",
  "ccm.calledconfig.label.selectcalled" : "تحديد تم الاتصال به",
  "ccm.satisfactionconfig.message.failtogetivrflow" : "يُرجى تشغيل استبيان الرضا",
  "ccm.transserver.label.serveraddr" : "العنوان",
  "ccm.agent.pageurls.configpage" : "تكوين الصفحة",
  "ccm.agent.operation.TWOSTAGEDIALING" : "الطلب على مرحلتين",
  "ccm.urlconfig.modify.oauth.sk.tooshort" : "يجب أن يحتوي SK على 16 حرفًا على الأقل.",
  "ccm.agent.restReason.pleaseRefresh" : "المزامنة والمحاولة مرة أخرى.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnagetive" : "لا يمكن أن يكون الحد الأدنى أو الحد الأقصى لمدة المكالمة أقل من 0.",
  "ccm.ucconfig.message.clientSecret" : "كلمة مرور التطبيق (العميل)",
  "ccm.custdata.option.importDoing" : "المعالجة",
  "ccm.agent.title.querysippwd" : "التحقق من كلمة مرور Softphone",
  "ccm.satisfactionconfig.message.strategychoosed" : "السياسات المحددة",
  "ccmanagement.satisfaction.strategy.deletesuccess" : "تم الحذف بنجاح.",
  "ccm.urlconfig.label.appsecret" : "مفتاح مشترك",
  "ccm.urlconfig.label.key": "المفتاح السري",
  "ccm.contact.customer.config.key" : "مفتاح البيانات",
  "ccm.whatsapp.whatsApp.message.template" : "رسالة قالب WhatsApp",
  "ccm.agent.message.validatordigit" : "يجب أن تكون كلمة مرور إعداد موظف الهاتف الواحد رقمية فقط",
  "ccm.speciallevel.speciallist.levelMessage" : "إدارة المستوى",
  "ccm.transtask.label.datatype" : "تفريغ نوع البيانات",
  "ccm.satisfactionconfig.message.delete" : "حذف",
  "ccm.label.page.delete.confirm" : "هل تريد بالتأكيد حذفه؟",
  "ccm.sysparam.success.save" : "النجاح",
  "ccm.speciallist.msg.fail" : "فشل",
  "ccm.agent.message.pageInterface" : "واجهة الصفحة",
  "iolp.workflow.label.information" : "معلومات",
  "ccm.calledconfig.label.editsoftnum" : "تحرير لينة الهاتف Nums",
  "ccm.anonymous.message.fail.feature" : "ميزة auth للمكالمة الصادرة المجهولة غير طبيعية",
  "ccm.contact.label.unsigned" : "غير موقّع",
  "ccm.agent.download.forbidden" : "لا يوجد إذن تنزيل.",
  "ccm.agent.ten.email" : "البريد الإلكتروني",
  "ccm.certificate.message.tip.warning" : "لا تفي خوارزمية التوقيع وطول مفتاح الشهادة بالمتطلبات\، وهو أمر محفوف بالمخاطر. هل أنت متأكد من رغبتك في المتابعة؟",
  "ccm.transserver.label.proxynotinwhite" : "فشلت العملية. اتصل بمسؤول النظام لإضافة عنوان الملقم الوكيل إلى قائمة ثقة الوكيل الخاصة بملقم OBS.",
  "ccm.agent.calledroute.devicedesc" : "اسم الجهاز",
  "ccm.satisfactionconfig.param.faile" : "معلمة غير صحيحة.",
  "ccm.agent.ten.resourceinfor" : "معلومات الموارد",
  "ccm.agent.pageurls.pagetype" : "نوع الصفحة",
  "ccm.basicdata.msg.nodatamodify" : "لا توجد بيانات تحتاج إلى تعديل.",
  "ccm.agent.calledroute.placeholder.ivr" : "يرجى اختيار IVR.",
  "ccm.agent.operation.noMoreThan30Days" : "لا يمكن أن يتجاوز نطاق الوقت 30 يومًا.",
  "ccm.agent.calledroute.errorsave" : "خطأ",
  "ccmanagement.satisfaction.strategy.accepttime.timeEnd" : "وقت الانتهاء",
  "ccm.agent.contact.QUERU_VCCINFO_FAIL_CODE" : "معلومات مركز المكالمات الظاهري فارغة.",
  "ccm.idauth.title.ivr" : "عملية المصادقة",
  "ccm.speciallist.msg.error" : "أخطاء",
  "ccm.dual.call.record.caller" : "رقم المتصل",
  "ccm.custdata.button.moveDown" : "إلى الأسفل",
  "ccm.transtask.config.pathvalidate" : "لا يمكن أن يحتوي مسار ملف التفريغ على './''.",
  "ccm.custdata.msg.updatedItemNotExist" : "الحقل المراد تحديثه غير موجود. قم بتحديث الصفحة وحاول مرة أخرى.",
  "ccm.agent.contact.user.recall" : "قام المستخدم بسحب الرسالة",
  "ccm.satisfactionconfig.message.acceptdate" : "تاريخ المعالجة",
  "ccm.recognition.resetSK.success" : "تمت إعادة تعيين SK بنجاح.",
  "ccm.sysparam.error.validate.notmatchrule" : "معلمة الإدخال لا تتطابق مع قاعدة التحقق",
  "ccm.agent.label.selectSkill" : "تحديد قائمة انتظار المهارات",
  "ccm.agent.contact.callinandcallout" : "المكالمات الواردة والصادرة",
  "ccm.satisfactionconfig.satisfaction.sending.channel" : "قناة إرسال الرسائل القصيرة",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.startafterend" : "يجب أن يكون تاريخ البدء قبل تاريخ الانتهاء.",
  "ccm.agent.contact.caller" : "رقم المتصل",
  "ccm.dual.call.record.called" : "الرقم المطلوب",
  "ccm.agent.contact.talkcontent" : "محتوى المحادثة",
  "ccm.urlconfig.message.httpurl.safetip" : "(غير آمنة)",
  "ccm.skillgroup.query.fail" : "فشل الحصول على إعدادات المجموعة المختصة.",
  "ccm.sysparam.error.validate.notmatchruleminlength" : "معلمة الإدخال أقل من الحد الأدنى للطول",
  "ccm.sysparam.error.validate.ccme.alarmThreshold": "شدة التنبيه غير متناسقة مع حد التنبيه. يجب أن تكون عتبة التنبيه ذات الخطورة المنخفضة أصغر من (مساوية) عتبة التنبيه ذات الخطورة العالية",
  "ccm.sysparam.error.validate.ccme.daysAlarmOverSync": "يجب أن تكون معلمة الإدخال أقل من الوقت الذي تم تكوينه (7 أيام بشكل افتراضي) لترحيل المستخدمين دون اتصال إلى جدول المحفوظات",
  "ccm.agent.button.ok" : "حسناً",
  "ccm.custdata.msg.operationSuccess" : "نجحت العملية.",
  "ccm.agent.callreason.modify.failed" : "فشل تعديل سبب المكالمة.",
  "ccm.agent.callreason.delete.confirm": "هل تريد بالتأكيد حذف سبب المكالمة؟",
  "ccm.agent.contact.gateway.fail" : "طلب استثناء البوابة. يُرجى التحقق من تكوين البوابة.",
  "ccm.agent.contact.called" : "الرقم المطلوب",
  "ccm.policy.label.updateVerificationFailed" : "فشل طلب التحقق من المعلمة.",
  "ccm.agentparam.message.editsuccess" : "تم التحرير بنجاح.",
  "ccm.agent.message.createSkillConfFail" : "فشل إنشاء معلومات قائمة انتظار المهارة.",
  "iolp.configur.servicelabel.label.serialNumber" : "الرقم التسلسلي",
  "ccm.agent.label.waitVoiceSelect" : "يُرجى تحديد نغمة انتظار",
  "ccm.systemparam.error.noparamselected" : "يرجى تحديد معلمة أولاً.",
  "ccm.custdata.operlog.addExportTaskError" : "فشل تصدير مهمة جديدة\، يُرجى التحقق من السجل",
  "iolp.workflow.node.label.hintTextInfo" : "معلومات",
  "ccm.recognition.label.modifymobileagentflowidnull" : "معرف التدفق فارغ!",
  "ccm.calledconfig.label.inputsoftnum" : "يرجى إدخال Soft Phone Nums",
  "ccm.worknoaftervoice.config.choosenote" : "حدد قيمة لمعرّف \"الصوت بعد الموظف\".",
  "ccm.speciallist.msg.restoreout" : "يمكن لمساحة المستأجر تخزين سجلات قائمة 10,000 الخاصة كحد أقصى",
  "ccm.agent.label.negativeEmotion" : "صياغة غير صحيحة. استخدم نصًا مناسبًا.",
  "ccm.agent.message.agenttypeinvalid" : "نوع الموظف غير صالح.",
  "ccm.speciallevel.msg.selectspecialLevel" : "يرجى اختيار مستوى خاص",
  "ccm.ccagent.management.default.options" : "خيارات افتراضية",
  "ccm.callout.message.choosecaller" : "يُرجى اختيار متصل وسيلة اتصال",
  "ccm.transtask.label.certTip.cssCert" : "يُرجى تحديد شهادة CSS.",
  "ccm.recognition.label.openBreakDown" : "انهيار صوت",
  "ccm.agent.label.deletecallreason" : "السبب الحالي يحتوي على سبب تابع لا يمكن حذفه",
  "ccm.agent.contact.name" : "اسم الوثيقة",
  "ccmanagement.satisfaction.strategy.calltime.interval" : "نطاق مدة المكالمة",
  "ccm.agent.recall.agent.calltype.invalid" : "لا يعتمد نوع الاتصال الحالي عملية معاودة الاتصال.",
  "ccm.agent.pageurls.openMode" : "وضع الفتح",
  "ccm.agent.label.deviceUsageThreshold" : "عتبة استخدام الجهاز (%)",
  "ccm.verifyidentity.callId" : "مكالمة SN",
  "iolp.workflow.button.kbs" : "قاعدة المعارف",
  "ccm.agent.message.batchExport" : "دفعة تصدير",
  "ccm.agent.calledroute.nmsfail" : "فشلت المزامنة مع NMS.",
  "ccm.operlog.dualcallexport.download" : "تنزيل سجلات المكالمات المزدوجة",
  "ccm.agent.calledroute.delete.skill.default" : "تمت الإشارة إلى تكوين الطرف المطلوب بواسطة قائمة انتظار المهارات الخاصة الافتراضية ولا يمكن حذفه.",
  "ccm.agent.ten.daylightSavingTime" : "DST",
  "agentconsole.ccnotification.recipient.validate.error": "يمكن أن يحتوي كل رقم على أرقام فقط وبحد أقصى 20 رقمًا",
  "iolp.workflow.label.graphZoomOut": "التصغير",
  "iolp.workflow.label.graphZoomIn": "تكبير/تصغير",
  "iolp.workflow.label.previous": "إلغاء",
  "iolp.workflow.label.next": "الاسترداد",
  "iolp.workflow.label.cleanGraph": "قماش واضح",
  "iolp.workflow.label.taskElement": "عنصر مخطط الوظيفة",
  "iolp.workflow.label.decisionElement": "عنصر مخطط الفرع",
  "iolp.workflow.label.unmatchedFlow": "لا يمكن مطابقة الخدمة المقابلة",
  "iolp.configur.service.label.search.tips": "الرجاء إدخال اسم",
  "iolp.configur.service.add": "جديد",
  "iolp.configur.service.label.workflow.name": "اسم العملية",
  "iolp.configur.service.operate.edit": "تحرير",
  "iolp.configur.service.operate.delete": "حذف",
  "iolp.configur.service.tips.customizedPageUrl": "على سبيل المثال: CustomerInfoDemo.html",
  "iolp.configur.service.add.select.title": "اسم الفئة المحددة",
  "iolp.configur.service.add.operate.title": "نوع جديد",
  "iolp.configur.service.dialog.add.title": "دليل خدمة جديد",
  "iolp.configur.service.dialog.modify.title": "تحرير دليل الخدمة",
  "iolp.configur.service.dialog.root.directory.title": "لا توجد فئة أصلية",
  "iolp.configur.servicelabel.release.successful": "تم النشر بنجاح",
  "iolp.configur.servicelabel.release.failed": "فشل النشر",
  "iolp.process.utilization.efficiency.analysis":"تحليل كفاءة استخدام العمليات",
  "iolp.flow.number.of.contacts":"عدد جهات الاتصال (قطع)",
  "iolp.flow.first.solution.rate":"معدل الحل الأول (٪)",
  "iolp.flow.please.select.time.before.today":"الفاصل الزمني هو يوم واحد أو 3 - 30 يوما.",
  "iolp.flow.the.interval.validate":"الفاصل الزمني هو يوم واحد أو 3 - 30 يوما.",
  "iolp.flow.analysis.date.time":"التاريخ/الوقت",
  "ccm.sendwhatsapp.content.too.long": "محتوى القالب طويل جداً",
  "ccm.sendwhatsapp.body.too.long": "محتوى الجسم طويل جدا",
  "ccm.notifition.sendmessage.largerthanlimit": "يتجاوز عدد المستلمين الحد الأقصى{n}.",
  "ccm.agent.callreason.must": "سبب المكالمة إلزامي.",
  "ccm.empty.template": "قالب وجود ندارد. قالب اطلاعیه را بررسی کنید.",
  "ccm.empty.variables": "متغیر قالب وجود ندارد. قالب اطلاعیه را بررسی کنید.",
  "ccm.no.meeting.variables": "متغیر قالب حاوی متغیر URL ملاقات نیست. قالب اخطار را بررسی کنید.",
  "ccm.agent.meeting.failed": "مامور کنفرانسی ارائه نمی کنه",
  "ccm.agent.link.failed": "عامل موفق تولید پیوند SMS نمی شود.",
  "ccm.agent.meeting.receiver.check.error": "تعداد گیرنده ها نمی تواند بیش از ۱ شود.",
  "ccm.agent.contact.timeouthungup": "وقت إضافي",
  "ccm.agent.contact.skilltimeouthungup": "تغيير المهارات",
  "iolp.workflow.efficiency.analysis":"تحليل الكفاءة",
  "iolp.configur.servicelabel.label.version": " إصدار الإصدار",
  "iolp.configur.servicelabel.label.version.number": "عدد الإصدارات",
  "iolp.configur.servicelabel.status.abandon": " غير صالح",
  "iolp.configur.servicelabel.status.publish": " الافراج",
  "iolp.configur.servicelabel.status.unpublish": " غير منشور",
  "iolp.configur.service.operate.copy": " النسخ المتماثل",
  "iolp.configur.service.operate.publish": " الافراج",
  "iolp.configur.service.operate.cancel": " إلغاء النشر",
  "iolp.configur.servicelabel.publish.successful": " تم النشر بنجاح",
  "iolp.configur.servicelabel.publish.failed": "فشل النشر",
  "iolp.configur.servicelabel.cancel.successful": " تم إلغاء التحرير بنجاح.",
  "iolp.configur.servicelabel.cancel.failed": "فشل الإلغاء",
  "iolp.configur.servicelabel.copy.successful": " تم النسخ بنجاح.",
  "iolp.configur.servicelabel.copy.failed": " فشل النسخ المتماثل",
  "iolp.configur.servicelabel.confirm.delete.flow": " هل تريد بالتأكيد حذف الإصدار الحالي؟",
  "iolp.configur.service.dialog.add.directory.title": "إنشاء فئة إرشادات الخدمة",
  "iolp.configur.service.dialog.modify.directory.title": "تحرير فئة إرشادات الخدمة",
  "iolp.configur.service.dialog.add.workflow.title": "إنشاء عملية توجيه الخدمة",
  "iolp.configur.service.dialog.modify.workflow.title": "تحرير عملية توجيه الخدمة",

  "ccm.transfer.number.pop.create.title": "إنشاء رقم خارجي تمت إعادة توجيهه",
  "ccm.transfer.number.pop.modify.title": "تحرير الرقم الخارجي المعاد توجيهه إلى",
  "ccm.transfer.number.pop.delete.title": "حذف",
  "ccm.transfer.number.pop.delete.message": "هل تريد بالتأكيد حذف الرقم الخارجي المحدد؟",
  "ccm.transfer.number.search.number": "الرجاء إدخال رقم",
  "ccm.transfer.number.search.name": "الرجاء إدخال اسم",
  "ccm.transfer.number.label.index": "الفهرس",
  "ccm.transfer.number.label.number": "الرقم",
  "ccm.transfer.number.label.name": "الاسم",
  "ccm.transfer.number.label.flag": "إعادة توجيه رقم المستخدم",
  "ccm.transfer.number.label.flag.yes": "نعم",
  "ccm.transfer.number.label.flag.no": "لا",

  "ccd.webrtc.message.bad":"سوء",
  "ccd.webrtc.message.poor":"الفقراء",
  "ccd.webrtc.message.good": "جيد",
  "ccd.webrtc.message.recive": "الاستلام",
  "ccd.webrtc.message.Packetloss": "فقد الحزمة",
  "ccd.webrtc.message.delay": "تأخير",
  "ccd.webrtc.message.jitter": "غضب",
  "ccd.webrtc.message.send": "إرسال",
  "ccd.webrtc.message.signal": "إشارة",
  'ccd.webrtc.message.medium': 'متوسط',
  'ccd.webrtc.login.success': 'تم تسجيل الدخول إلى Webrtc بنجاح',
  'ccd.webrtc.login.fail': 'فشل تسجيل الدخول إلى Webrtc',
  'ccd.webrtc.login.fail.pop.tips': 'فشل تسجيل الدخول التلقائي إلى Webrtc ، لن تتمكن من استخدام وظيفة الصوت. هل تريد تسجيل الدخول إلى Webrtc مرة أخرى؟',
  'ccd.webrtc.login.try': 'retry',
  'ccd.webrtc.login.ignore': 'ignore',
  'ccd.webrtc.login.fail.top.tips': 'فشل تسجيل الدخول إلى Webrtc، تعذر استخدام الصوت والوظائف الأخرى ذات الصلة',
  "ccd.ccbar.webrtc.network.signal.pool": "إشارة الشبكة الحالية ضعيفة، مما قد يؤثر على المكالمة.",
  "ccd.agentMonitor.label.switchwhisper": "لا يمكنك النقر فوق الهمس لموظف في حالة الاستماع المسبق أو لكل إدراج.",
  "ccm.agent.leavemessage.opinionempty": "تعليقات مراجعة الحسابات إلزامية.",
  "ccm.sms.meeting.link": "رابط المؤتمر SMS",
  "ccm.agent.sms.meeting.gateway.commonerror": "فشل إنشاء المؤتمر. رمز الخطأ: {n}",
  "ccm.agent.sms.meeting.gateway.error": "فشل إنشاء المؤتمر. رمز الخطأ: {n}. لا تقم بإنشاء المؤتمر في وضع كتم الصوت أو الانتظار أو متعدد الأطراف.",
  "ccm.contactRecordDetail.notSetTraceLogError": "لم يتم العثور على مسار التدفق. يُرجى إضافة إعدادات التتبع قبل عرض مسار التدفق المطابق لسجل جهة الاتصال",
  "ccm.agent.callreason.modify.exceeds.limit": "فشل تعديل سبب المكالمة. تجاوز الفاصل الزمني فترة صلاحية سبب المكالمة.",
  "ccm.label.appcube.switch":"\u0635\u0641\u062D\u0629 AppCube-Integrated",
  "ccm.label.appcube.app":"\u062A\u062D\u062F\u064A\u062F \u0627\u0644\u062A\u0637\u0628\u064A\u0642",
  "ccm.label.appcube.menu":"\u062A\u062D\u062F\u064A\u062F \u0627\u0644\u0642\u0627\u0626\u0645\u0629",
  "ccm.contactitemconfig.label.data.item.sequence.number.seq": "ترتيب الحقول",
  "ccm.contactitemconfig.label.data.item.add": "إنشاء حقل سجل جهة اتصال",
  "ccm.contactitemconfig.label.data.item.edit": "تحرير حقل سجل جهات الاتصال",
  "ccm.contactitemconfig.label.data.item.tips": "يجب تكوين واجهة مصدر مجموعة البيانات على صفحة إدارة الواجهة. اتصل بمسؤول تشغيل النظام.",
  "ccm.contactitemconfig.label.data.item.basic.item": "حقل أساسي موسع",
  "ccm.contactitemconfig.label.data.item.custom.item": "حقل موسع معرّف من قبل المستخدم",
  "ccm.contactitemconfig.label.dataInfo.item.add": "إنشاء عنصر بيانات",
  "ccm.contactitemconfig.label.dataInfo.item.edit": "تحرير عنصر بيانات",
  "ccm.custdata.label.data.table.template": "قالب جدول البيانات",
  "ccm.custdata.msg.uploadType": "طريقة استيراد البيانات المكررة",
  "ccm.custdata.msg.uploadTypeInfo": "إذا كانت هناك بيانات مكررة، وإذا اخترت الكتابة فوق البيانات الأصلية، فسيتم تحديث سجل البيانات. وإذا اخترت عدم الكتابة فوق البيانات الأصلية، فسيتم التعامل معها على أنها بيانات فاشلة.",
  "ccm.custdata.msg.uploadType.cover": "الكتابة فوق البيانات الأصلية",
  "ccm.custdata.msg.uploadType.notcover": "لا الكتابة فوق البيانات الأصلية",
  "ccm.pageconfig.label.page": "صفحة",
  "ccm.pageconfig.label.publishpage": "نشر الصفحة",
  "ccm.transtask.label.intelligentInspectionConfig":"إعداد تفريغ بيانات التفتيش AI",
  "ccm.validate.portLengthError":"يجب أن يكون نطاق المنفذ 1-65535",
  "ccm.resource.dump.server.add":"إنشاء ملقم تفريغ الموارد",
  "ccm.resource.dump.server.edit":"تحرير ملقم تفريغ الموارد",
  "ccm.resource.dump.server.view":"عرض خادم تفريغ الموارد",
  "ccm.resource.dump.css.add":"إنشاء خادم بحث سحابة",
  "ccm.resource.dump.css.edit":"تحرير خادم البحث السحابي",
  "ccm.resource.dump.css.view":"عرض خادم البحث السحابي",
  "ccm.resource.dump.task.add":"إنشاء مهمة تفريغ مورد",
  "ccm.resource.dump.task.edit":"تحرير مهمة تفريغ مورد",
  "ccm.resource.dump.task.view":"عرض مهمة تفريغ الموارد",
  "ccm.transtask.label.intelligentInspectionDay":"مدة تفريغ بيانات التفتيش AI",
  "ccm.transtask.css.server.num":"لا يمكن أن يتجاوز عدد ملقم بحث السحابة 1.",
  "ccm.contact.customer.config.num":"لا يمكن أن يتجاوز عدد عمود معلومات عميل جهة الاتصال 10.",
  "ccm.export.password.error.tips": "تحتوي كلمة مرور الملف المضغوط على 8 إلى 12 حرفًا، بما في ذلك الأحرف والأرقام والرموز الخاصة {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.export.password.error.message": "يجب أن تحتوي على أحرف وأرقام وأحرف خاصة {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.agent.ccagentinfo.modifyselfphone.success":"نجح تعديل رقم الهاتف الثابت/المحمول",
  "ccm.agent.ccagentinfo.modifyselfphone.fail":"فشل تعديل رقم الخط الثابت/الهاتف المحمول",
  "ccm.export.table.taskTime": "وقت المهمة",
  "ccm.export.table.taskStatus": "حالة المهمة",
  "ccm.agent.contact.multimedia.sms":"\u0627\u0644\u0648\u0633\u0627\u0626\u0637 \u0627\u0644\u0645\u062A\u0639\u062F\u062F\u0629-SMS",
  "ccm.agent.label.skill":"\u0627\u0644\u0645\u0647\u0627\u0631\u0629",
  "ccm.agent.button.randomRestSoftPhonePwd":"إعادة تعيين كلمة مرور الهاتف البسيط",
  "ccm.agent.label.skillGroup":"\u0645\u062C\u0645\u0648\u0639\u0629 \u0642\u0627\u0626\u0645\u0629 \u0627\u0646\u062A\u0638\u0627\u0631 \u0627\u0644\u0645\u0647\u0627\u0631\u0627\u062A",
  "ccm.agent.message.searchSkillGroupName":"اسم مجموعة قائمة انتظار المهارات",
  "ccm.skill.label.skillGroupName":"\u0627\u0633\u0645 \u0645\u062C\u0645\u0648\u0639\u0629 \u0642\u0627\u0626\u0645\u0629 \u0627\u0646\u062A\u0638\u0627\u0631 \u0627\u0644\u0645\u0647\u0627\u0631\u0627\u062A",
  "ccm.agent.button.restSoftPhonePwd.remark":"\u0625\u0630\u0627 \u0642\u0645\u062A \u0628\u0625\u0639\u0627\u062F\u0629 \u062A\u0639\u064A\u064A\u0646 \u0643\u0644\u0645\u0627\u062A \u0645\u0631\u0648\u0631 \u0645\u0648\u0638\u0641 \u0627\u0644\u0647\u0627\u062A\u0641 \u0627\u0644\u0631\u0642\u0645\u064A \u0625\u0644\u0649 \u0643\u0644\u0645\u0627\u062A \u0645\u0631\u0648\u0631 \u0639\u0634\u0648\u0627\u0626\u064A\u0629 \u0639\u0644\u0649 \u062F\u0641\u0639\u0627\u062A\u060C \u0641\u064A\u062C\u0628 \u0639\u0644\u0649 \u0627\u0644\u0648\u0643\u0644\u0627\u0621 \u0625\u0639\u0627\u062F\u0629 \u062A\u0639\u064A\u064A\u0646 \u0643\u0644\u0645\u0627\u062A \u0645\u0631\u0648\u0631 \u0627\u0644\u0647\u0627\u062A\u0641 \u0627\u0644\u0631\u0642\u0645\u064A \u0627\u0644\u062E\u0627\u0635\u0629 \u0628\u0647\u0645 \u0642\u0628\u0644 \u0627\u0633\u062A\u062E\u062F\u0627\u0645 \u0648\u0638\u064A\u0641\u0629 \u0627\u0644\u0647\u0627\u062A\u0641 \u0627\u0644\u0631\u0642\u0645\u064A. \u064A\u0645\u0643\u0646 \u0644\u0644\u0645\u0633\u0624\u0648\u0644 \u0623\u064A\u0636\u064B\u0627 \u0627\u0644\u0646\u0642\u0631 \u0641\u0648\u0642 Reset \u0627\u0644\u0647\u0627\u062A\u0641 \u0627\u0644\u0645\u0628\u0631\u0645\u062C Password \u0641\u064A \u0627\u0644\u0642\u0627\u0626\u0645\u0629 \u0644\u062A\u063A\u064A\u064A\u0631 \u0643\u0644\u0645\u0629 \u0645\u0631\u0648\u0631 \u0627\u0644\u0645\u0648\u0638\u0641 \u064A\u062F\u0648\u064A\u064B\u0627.",
  "ccm.agent.management.exporting.agent.information":"تصدير معلومات الموظف",
  "ccm.agent.clear.message.clearcommonerror":"\u0641\u0634\u0644 \u0625\u0644\u063A\u0627\u0621 \u0627\u0644\u0627\u0639\u062F\u0627\u062F\u0627\u062A. \u0627\u0644\u062E\u062F\u0645\u0629 \u063A\u064A\u0631 \u0645\u062A\u0648\u0641\u0631\u0629.",
  "ccm.contactitemconfig.label.dataInfo.item.name.exist": "اسم عنصر البيانات موجود بالفعل.",
  "ccm.agent.button.downloadvrc": "تنزيل VRC",
  "ccm.dual.call.callback.url.config":"تكوين عنوان URL لمعاودة الاتصال",
  "ccm.dual.call.callback.url.auth":"مصادقة استدعاء الواجهة لعنوان URL",
  "ccm.dual.call.callback.url.authType":"وضع مصادقة URL لمعاودة الاتصال",
  "ccm.dual.call.callback.url.ita":"تكوين معلمة ITA",
  "ccm.dual.call.callback.url.xNumberType":"وضع تخصيص الرقم X",
  "ccm.agent.management.select.agent":"تحديد الموظفين حسب معرف الموظف",
  'ccm.password.length.limit.min': 'كلمة المرور قصيرة جدًا. أدخل من 8 إلى 12 حرفًا',
  'ccm.password.length.limit.max': 'كلمة المرور طويلة جدًا. أدخل من 8 إلى 12 حرفًا',
  "ccm.agent.operation.SCREENMONITOR": "مراقبة الشاشة",
  'ccm.mobile.agent.front.add': 'إنشاء وكيل جوال',
  'ccm.mobile.agent.front.edit': 'تحرير موظف الجوال',
  'ccm.mobile.agent.front.checkNum': 'يرجى تحديد رقم واحد على الأقل.',
  'ccm.mobile.agent.front.maxNum': 'لا يمكن أن يتجاوز الرقم 15.',
  'ccm.contactitemconfig.label.contactData.add': 'إنشاء مجموعة بيانات سجل جهات الاتصال',
  'ccm.contactitemconfig.label.contactData.edit': 'تحرير مجموعة بيانات سجل جهات الاتصال',
  'ccm.agent.button.downloading':'جارٍ التنزيل...',
  'ccm.label.operationexport.nodata': 'لا يوجد سجل عملية مطابق للشروط.',
  'ccm.label.transferexport.nodata': 'لا يوجد سجل إعادة توجيه يفي بالشروط.',
  'ccm.label.leaveexport.nodata': 'لا يوجد سجل راحة يفي بالمتطلبات.',
  'ccm.label.send.whatsapp': 'أرسل واتساب',
  "ccm.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "لا يمكن أن يحتوي اسم مركز المكالمات على أحرف خاصة",
  'ccm.common.validation.numbers': 'يرجى إدخال أرقام فقط',
  'ccm.common.validation.click.number': 'يُرجى النقر فوق الرقم',
  'ccm.ccagent.agent.weight.tips': 'تشير القيمة الأكبر إلى أولوية أعلى. عند وجود العديد من الموظفين الخاملين، يفضل النظام تخصيص المكالمات الواردة للموظف ذي الوزن الأكبر.',
  'ccm.ccagent.skill.weight.tips': 'تشير القيمة الأكبر إلى أولوية أعلى. عند وجود مكالمات في قوائم انتظار متعددة، يقوم النظام بتخصيص المكالمات الموجودة في قائمة الانتظار ذات الوزن الأكبر للموظف الخامل.',
  "ccm.speciallist.tip.time.max": "يرجى تحديد وقت سابق لوقت انتهاء صلاحية المشروع 19-01-2038",
  "ccm.speciallist.tip.startTime.min": "يجب أن يكون الوقت الفعلي بعد الوقت الحالي.",
  'ccm.ccagent.batch.modify.special.character.verification': 'لا يمكن أن يحتوي على ’',
  'ccm.resource.dump.obs.cert.warn.tip': 'لطفاً برای وارد کردن گواهی اعتماد OBS به کتابخانه گواهی گواهی نامه با کارکنان & OBS تماس بگیرید.',
  "ccm.audiovideoworkbench.mood" : "العواطف",
  "ccm.audiovideoworkbench.intention" : "النية",
  "ccm.audiovideoworkbench.complain" : "الميل إلى الشكوى",
  "ccm.audiovideoworkbench.aiswitch" : "نموذج اللغة الكبيرة",
  "ccm.audiovideoworkbench.airecommendation" : "توصية ذكية",
}