export default {
  mainTitle: 'Customer Service Cloud',
  subTitle: 'Clear UI for Ultimate Service',
  account: 'Account',
  password: 'Password',
  signIn: 'Sign In',
  configCenter: 'Configuration Center',
  configFirstAgent: 'Agent Configuration',
  ConfigureFirstBot: 'Robot Configuration',
  howToUseTheCallCenter: 'User Guide',
  welcomeBack: 'welcome back',
  totalNumberOfCalls: 'Total calls',
  numberOfCallLosses: 'Abandoned calls',
  totalNumberOfPeopleInLine: 'Calls in queue',
  helpCenter: 'Help Center',
  knowledgeCenter: 'Knowledge Center',
  forum: 'Forum',
  virtualCallCenterTrendsOfTheDay: 'Virtual Call Center Trends of the Day',
  quickEntry: 'Quick Entry',
  myToDo: 'My To Do',
  notificationCenter: 'Notification Center',
  more: 'More',
  addStaff: 'Add Staff',
  setSkillQueue: 'Set Skill Queue',
  agentManagement: 'Agent Management',
  calledSettings: 'Called Settings',
  workbenchConfiguration: 'Workbench Configuration',
  outboundTask: 'Outbound Task',
  workOrder: 'Work Order',
  message: 'Message',
  name: 'Name',
  taskProgress: 'Task Progress',
  province: 'Province',
  urbanArea: 'Urban Area',
  address: 'Address',
  postCode: 'Post Code',
  homePage: 'Home Page',
  caseHome: 'Case Home',
  robotManagement: 'Robot Management',
  mailMessageManagement: 'Mail Message Management',
  changePassword: 'Change Password',
  resetSkillQueue: 'Reset Skill Queue',
  signOut: 'Sign Out',
  changePasswordSuccess:
    'The new password has been set successfully. Please remember your new password. The page will return in 3 seconds.',
  passwordInvalid:
    'The current password does not meet the password rules. Please reset it according to the rules.',
  passwordIsAboutToExpire: 'The password is about to expire. Change the password.',
  passwordForceChange:
    'The current password is the initial password. For security purposes, change the password immediately.',
  passwordFirstChange:
    'The current password is the initial password. To ensure security, change the password immediately.',
  passwordExpireTitle: 'Password Expiration Notification',
  passwordExpireMessage:
    'The password you set is about to expire. You cannot log in to the system after the password expires. Change the password now.',
  passwordFirstChangeTitle: 'Initial Password Reminder',
  passwordFirstChangeMessage:
    'The current password is the initial password, which is insecure. Do you want to change the password now?',
  systemError: 'System error',
  continueLogin: 'Continue login',
  verifyCode: 'Verification Code',
  pageInvalid: 'The page is invalid. Please reload the page.',
  confirm: 'Confirm',
  verifyCodeInvalid: 'The verification code is invalid.',
  passwordHasExpried: 'The password has expired',
  passWordRuleLength: 'The password contains [1] to [2] characters',
  passWordRuleMinLength: 'The password must contain at least [1] characters',
  passWordRuleMaxLength: 'The password can contain a maximum of [1] characters',
  passwordRuleForbidContinueCharacter:
    'The number of consecutive repeated characters cannot exceed [1]',
  passwordRuleForbidName: 'The name cannot be contained',
  passwordRuleForbidAccount: 'The account cannot be in positive or negative order',
  digit: 'Number',
  letter: 'Letter',
  uppercaseLetters: 'Uppercase letters',
  lowercaseLetters: 'Lowercase letters',
  specialCharacters: 'Special character',
  necessaryContains: 'Mandatory',
  specialCharacterCandidates: 'Special character candidate:',
  descriptionOfInvalidandUnqualifiedPasswords: 'Invalid and unqualified password description:',
  error: 'Error',
  copyrightInfomation:
    'Copyright © [1] Huawei Software Technologies Co., Ltd. All rights reserved.',
  accountEmpty: 'Enter an account.',
  oldPasswordEmpty: 'Enter a password.',
  newPasswordEmpty: 'Enter a new password.',
  passwordNotSame: 'Passwords are inconsistent.',
  verifyCodeEmpty: 'Enter a verification code.',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  ok: 'OK',
  cancel: 'Cancel',
  LogoutMessage: 'Are you sure you want to exit?',
  keywordFilter: 'Enter a keyword',
  noOrganization: 'Unaffiliated Organization',
  createCaseOrder: 'Create Case',
  configMenuSearchPlaceholder: 'Search menu keywords',
  changePasswordSuccessAndLogout: 'Password changed successfully! The system is about to exit automatically!',
  accountLocked: 'The user has been locked because the number of password change failures exceeds the threshold. The login page will be displayed in 3 seconds.',
  'queueNumbers': 'Number of current queues:',
  'callsToday': 'Calls Today:',
  'averageCallTime': 'Average talk time:',
  'entryIsEmpty': 'The shortcut menu entry is empty. Please setting.',
  'goToSettings': 'Go to Settings',
  'settingShortcutEntry': 'Setting shortcut entry',
  'frontDeskWork': 'Front desk work',
  'selectedShortcutMenu': 'Selected shortcut menu',
  'pendingMessages': 'Pending Messages ({n})',
  'messageManagement': 'Message Management',
  'messageTime': 'Message Time',
  'useCallFunction': 'How to Use the Call Function',
  'performingOutboundCallTask': 'Performing an Outbound Call Task',
  'notificationDetails': 'Notification Details',
  'sender': 'Sender',
  'receiveGroup': 'Receive group',
  'bulletinType': 'Bulletin Type',
  'internalMessage': 'Internal message',
  'announcements': 'Announcements',
  'systemBulletin': 'System Bulletin',
  'internalBulletin': 'Internal Bulletin',
  'sendNotification': 'Send a notification',
  'notificationReply': 'Are you sure delete this notification?',
  'notificationDeleteConfirm': 'Are you sure you want to delete this notification?',
  'alarmnote':'Quality Inspection Note',
  'alarmmessage':'Warning message',
  'alarmmessagedetail':'You have {0} new inspection alarms to view. Click to view details',
  'schedule':'WFM',
  'privacystatement':'AICC Privacy Statement',
  'id':'ID',
  'decs':'Description',
  'cause':'Reason',
  'action':'Repair Action',
  personalCenter: "Personal Center",
  'noagent':"The account is not bound to any agent. You can contact the administrator to configure the account",
  'nickName':"Session Nickname",
  'socailChatNum':"Number of clients served by a session at the same time",
  'avatar':"Conversation Head Portrait",
  'confirmClose':"Confirm Close",
  'importListView': "Viewing Import Tasks",
  'listView': "Viewing Tasks",
  'minorityLanguageTips': 'Sorry, the WFM system supports only Chinese and English. Please switch to another language.',
  'minorityLanguageTipsAgent': 'Sorry, the WFM system does not support Portuguese and Thai. Please switch to another language.',
  "sureCloseCurrentPage": "Are you sure you want to close the page?",
}
