export default {
    "ccd.agent.button.signIn": "ورود",
    "ccd.agent.button.signOut": "خروج از سیستم",
    "ccd.agent.button.sayBusy": "تنظیم Busy",
    "ccd.agent.button.sayFree": "تنظیم Idle",
    "ccd.agent.button.rest": "استراحت",
    "ccd.agent.button.cancelRest": "لغو استراحت",
    "ccd.agent.button.restSkillQueue": "بازنشانی صف مهارت",
    "ccd.agent.button.queryStatus": "وضعیت جستجو",
    "ccd.agent.button.transfer": "انتقال تماس",
    "ccd.agent.button.hold": "نگه داشتن تماس",
    "ccd.agent.button.getHold": "لغو نگه داشتن تماس",
    "ccd.agent.button.callOut": "تماس خروجی ",
    "ccd.agent.button.threeParty": "تماس سه طرفه",
    "ccd.agent.button.innerHelp": "مشورت کردن",
    "ccd.agent.button.mediaswitch": "سوئیچ صوتی/تصویری",
    "ccd.agent.button.mute": "حالت بی صدا",
    "ccd.agent.button.twostage": "صفحه شماره گیری",
    "ccd.agent.button.endMute": "باصدا کردن",
    "ccd.agent.button.answer": "پاسخ",
    "ccd.agent.button.hangUp": "قطع کردن تماس",
    "ccd.agent.button.screenShare": "به اشتراک گذاشتن دسکتاپ",
    "ccd.agent.button.notscreenShare": "اشتراک گذاری دسکتاپ را لغو کنید",
    "ccd.agent.label.offline": "آفلاین",
    "ccd.agent.label.idle": "بیکار",
    "ccd.agent.label.busy": "مشغول",
    "ccd.agent.label.rest": "استراحت",
    "ccd.agent.label.talk": "صحبت کردن",
    "ccd.agent.label.adjust": "بسته شدن",
    "ccd.agent.label.reply": "پاسخ",
    "ccd.agent.message.AgentIsEmpty": "حساب جاری به یک نماینده محدود نمی شود.",
    "ccd.agent.message.agentNoConfSkill": "نماینده با صف مهارت پیکربندی نشده است.",
    "ccd.agent.message.AgentIsSupend": "نماینده فعلی freeze شده است.",
    "ccd.agent.tips.confSuccess": "پیکربندی موفقیت آمیز است.",
    "ccd.agent.tips.confFail": "پیکربندی انجام نشد.",
    "ccd.agent.tips.media.agentType.no.support.callout": "نمایندگان چند رسانه ای نمی توانند تماس های خروجی برقرار کنند",
    "ccd.agent.tips.error": "خطا",
    "ccd.agent.tips.success": "موفقیت",
    "ccd.agent.tips.info": "نکات",
    "ccd.agent.tips.welcome": "خوش برگشتی. روز خوبی داشته باشید!",
    "ccd.agent.webrtc.label.offline": "ثبت نشده",
    "ccd.agent.webrtc.label.online": "ثبت شده است",
    "ccd.agent.webrtc.label.talk": "تماس گرفتن",
    "ccd.agent.webrtc.label.connetfailed": "ناموفق",
    "ccd.agent.webrtc.label.buildfailed": "اتصال برقرار نشد",
    "ccd.agent.webrtc.label.connect": "ثبت نام",
    "ccd.agent.webrtc.label.sippwd": "رمز تلفن نرم افزاری",
    "ccd.agent.webrtc.label.unsupportbrower": "مرورگر از این عملکرد پشتیبانی نمی کند.",
    "ccd.agent.webrtc.label.missmic": "میکروفون در دسترس نیست.",
    "ccd.agent.webrtc.label.misspeaker": "بلندگو در دسترس نیست.",
    "ccd.agent.webrtc.label.videopop": "پنجره ویدیو",
    "ccd.agent.webrtc.label.sotpsendvideo": "توقف ارسال ویدئو",
    "ccd.agent.webrtc.label.hidelocalvideo": "پنهان کردن ویدیوی محلی",
    "ccd.agent.webrtc.label.videosize": "اندازه ویدیو",
    "ccd.agent.webrtc.label.large": "بزرگ",
    "ccd.agent.webrtc.label.medium": "متوسط",
    "ccd.agent.webrtc.label.small": "کوچک",
    "ccd.agent.webrtc.label.localvideo": "ویدیوی محلی",
    "ccd.agent.webrtc.label.remotevideo": "ویدیو از راه دور",
    "ccd.agent.webrtc.label.accept": "پاسخ",
    "ccd.agent.webrtc.label.reject": "رد کردن",
    "ccd.agent.webrtc.label.logoutregistered": "خروج از سیستم.",
    "ccd.agent.webrtc.label.passwordvalidator": "طول نویسه وارد شده از محدوده مقرر شده بیشتر است. طول باید کمتر یا مساوی 50 کاراکتر باشد.",
    "ccd.agent.webrtc.label.busy.tip": "Chamada",
    "ccd.agent.webrtc.label.busy.info": "نکات",
    "ccd.agent.webrtc.label.busy.confirm": "تایید",
    "ccd.agent.webrtc.label.button.yes": "آره",
    "ccd.agent.webrtc.label.button.no": "خیر",
    "ccd.agent.webrtc.label.sippwd.placeholder": "رمز تلفن نرم افزاری را وارد کنید.",
    "ccd.agent.webrtc.label.remote.name": "طرف دیگر",
    "ccd.agent.webrtc.label.local.name": "نماینده",
    "ccd.agent.webrtc.label.changeVoice": "انتقال به صدا",
    "ccd.agent.agentevent.success": "موفق",
    "ccd.agent.agentevent.failed": "ناموفق",
    "ccd.agent.agentevent.warning": "هشدار",
    "ccd.agent.agentevent.info": "نکات",
    "ccd.agent.agentevent.AgentState_Force_SetNotReady": "نماینده با موفقیت مشغول شد.",
    "ccd.agent.agentevent.AgentState_CancelNotReady_Success": "مشغول بودن نماینده با موفقیت لغو شد.",
    "ccd.agent.agentevent.AgentState_Ready": "نماینده با موفقیت به وضعیت بیکار درآمد.",
    "ccd.agent.agentevent.AgentState_CancelWork_Success": "نماینده با موفقیت از حالت کار خارج شد.",
    "ccd.agent.agentevent.AgentState_SetRest_Success": "نماینده با موفقیت استراحت کرد.",
    "ccd.agent.agentevent.AgentState_CancelRest_Success": "نماینده با موفقیت وضعیت استراحت را لغو کرد.",
    "ccd.agent.agentevent.AgentState_SetWork_Success": "نماینده وارد حالت کار می شود.",
    "ccd.agent.agentevent.AgentState_Work": "نماینده وارد حالت wrap-up می شود.",
    "ccd.agent.agentevent.AgentState_Busy": "نماینده در حال کار است",
    "ccd.agent.agentevent.AgentEvent_Customer_Alerting": "تلفن زنگ می زند.",
    "ccd.agent.agentevent.AgentEvent_Ringing": "تماس ورودی نماینده",
    "ccd.agent.agentevent.AgentEvent_Hold": "تماس با موفقیت برگزار شد.",
    "ccd.agent.agentevent.AgentEvent_Hold.desc": "نماینده در حال حاضر در حالت تعلیق است",
    "ccd.agent.agentevent.AgentEvent_Talking": "نماینده وارد حالت صحبت می شود.",
    "ccd.agent.agentevent.AgentEvent_MediaSwith.success": "تعویض صوتی/تصویری با موفقیت انجام شد.",
    "ccd.agent.agentevent.AgentEvent_MediaSwith.failed": "تعویض صوتی/تصویری انجام نشد.",
    "ccd.agent.agentevent.AgentMediaEvent_Play": "پخش ضبط شروع می شود.",
    "ccd.agent.agentevent.AgentMediaEvent_PausePlayDone": "پخش ضبط با موفقیت متوقف شد.",
    "ccd.agent.agentevent.AgentMediaEvent_ResumePlayDone": "پخش ضبط با موفقیت از سر گرفته شد.",
    "ccd.agent.agentevent.AgentMediaEvent_JumpPlayDone": "ضبط با موفقیت به جلو یا عقب می رود.",
    "ccd.agent.agentevent.AgentMediaEvent_StopPlayDone": "پخش ضبط متوقف شد.",
    "ccd.agent.agentevent.AgentEvent_Conference": "تماس سه جانبه با موفقیت انجام شد.",
    "ccd.agent.agentevent.AgentEvent_Call_Out_Fail": "نماینده موفق به برقراری تماس خارجی نمی شود.",
    "ccd.agent.agentevent.AgentEvent_Inside_Call_Fail": "نماینده موفق به برقراری تماس داخلی نمی شود.",
    "ccd.agent.agentevent.AgentEvent_Call_Release": "نماینده از تماس خارج می شود",
  "ccd.agent.agentevent.AgentConf_SelfJoinResult": "عضو شدن یک کنفرانس",
    "ccd.agent.agentevent.AgentEvent_No_Answer": "نماینده برای مدت طولانی به تماس پاسخ نمی دهد.",
    "ccd.agent.agentevent.AgentEvent_Customer_Release": "مشتری از تماس خارج می شود.",
    "ccd.agent.agentevent.AgentEvent_Auto_Answer": "پاسخ خودکار نماینده",
    "ccd.agent.agentevent.AgentEvent_Connect_Fail": "شکست اتصال ",
    "ccd.agent.agentevent.AgentEvent_Consult_Fail": "شکست مشاوره نماینده",
    "ccd.agent.agentevent.AgentEvent_ReturnFromIvr": "نماینده با موفقیت از تلفن گویا بازگردانده شد.",
    "ccd.agent.agentevent.AgentEvent_Preview_CallOut": "پیش نمایش تماس خروجی با موفقیت انجام شد.",
    "ccd.agent.agentevent.AgentEvent_Preview": "نماینده درخواست پیش نمایش را با موفقیت دریافت می کند.",
    "ccd.agent.agentevent.AgentEvent_Agent_Occupyed": "نماینده از قبل پیش دستی کرده است.",
    "ccd.agent.agentevent.AgentEvent_Release_Preview": "تماس پیش نمایش با موفقیت آزاد شد.",
    "ccd.agent.agentevent.AgentOther_ShutdownService": "نماینده با موفقیت از سیستم خارج شد.",
    "ccd.agent.agentevent.AgentOther_InService": "نماینده با موفقیت وارد سیستم شد.",
    "ccd.agent.agentevent.AgentOther_PhoneAlerting": "نماینده زنگ می زند",
    "ccd.agent.agentevent.AgentOther_PhoneOffhook": "نماینده معاف شد",
    "ccd.agent.agentevent.AgentOther_PhoneRelease": "نماینده گوشی را قطع کرد",
    "ccd.agent.agentevent.AgentOther_PhoneUnknow": "وضعیت تلفن نماینده مشخص نیست.",
    "ccd.agent.agentevent.AgentOther_All_Agent_Busy": "همه نمایندگان مشغول هستند.",
    "ccd.agent.agentevent.AgentOther_Restskill_Queue": "عملیات صف مهارت با موفقیت انجام شد.",
    "ccd.agent.agentevent.callType": "نوع تماس:",
    "ccd.agent.agentevent.callId": "شناسه تماس:",
    "ccd.agent.agentevent.type.main": "تماس اصلی",
    "ccd.agent.agentevent.type.assistant": "تماس کمک",
    "ccd.agent.agentevent.otherPhone": "شماره تلفن / شناسه کاری طرف تماس:",
    "ccd.agent.agentevent.feature.caller": "شماره تماس گیرنده:",
    "ccd.agent.agentevent.feature.called": "شماره تماس گرفته شده:",
    "ccd.agent.agentevent.feature.callin": "تماس ورودی رایج",
    "ccd.agent.agentevent.feature.callout": "تماس خروجی رایج",
    "ccd.agent.agentevent.mediaAblity": "نوع رسانه:",
    "ccd.agent.agentevent.mediaAblity.voice": "سمعی",
    "ccd.agent.agentevent.mediaAblity.video": "ویدئو",
    "ccd.agent.agentevent.mediaAblity.voiceandvideo": "صوتی و تصویری",
    "ccd.agent.agentevent.mediaAblity.text": "متن",
    "ccd.agent.agentevent.AgentEvent_Conference.partner": "شماره تماس گیرنده:",
    "ccd.agent.agentevent.AgentEvent_No_Answer.number": "شماره طرف قطع کننده تماس:",
    "ccd.agent.agentevent.AgentEvent_Preview_CallOut.controlid": "پیش نمایش شناسه بلوک کنترلی تماس خروجی:",
    "ccd.agent.agentevent.AgentEvent_Preview_CallOut.dialeddigits": "پیش نمایش شماره تماس گرفته شده خروجی:",
    "ccd.agent.flash.calling": "تماس گرفتن",
    "ccd.agent.flash.message": "پیام",
    "ccd.agent.openeye.deamonready": "OpenEye شروع شده است.",
    "ccd.agent.openeye.deamonready.failinfo": "راه اندازی OpenEye ناموفق بود.",
    "ccd.agent.openeye.deamonready.fail": "راه اندازی OpenEye ناموفق بود. به صورت دستی وارد OpenEye شوید. عملکرد پاسخگویی خودکار قابل استفاده نیست.",
    "ccd.agent.query.sip.fail": "جستجوی داده های SIP انجام نشد. دوباره به عنوان نماینده وارد شوید یا به صورت دستی وارد OpenEye شوید.",
    "ccd.agent.openeye.sipconfig.success": "SIP با موفقیت پیکربندی شد.",
    "ccd.agent.openeye.poolsipconfig.success": "POOL با موفقیت پیکربندی شد.",
    "ccd.agent.openeye.sipconfig.fail": "SIP پیکربندی نشد. دوباره به عنوان نماینده وارد شوید یا به صورت دستی وارد OpenEye شوید.",
    "ccd.agent.openeye.poolsipconfig.fail": "پیکربندی POOL انجام نشد. دوباره به عنوان نماینده وارد شوید یا به صورت دستی وارد OpenEye شوید.",
    "ccd.agent.openeye.register": "شماره تلفن نرم افزاری با موفقیت ثبت شد.",
    "ccd.agent.openeye.already.register": "شماره تلفن نرم افزاری ثبت شده است.",
    "ccd.agent.openeye.register.fail": "شماره تلفن نرم افزاری قبلاً ثبت شده است یا دلایل دیگر.",
    "ccd.agent.openeye.deregister": "شماره تلفن نرم افزاری با موفقیت غیرفعال شد.",
    "ccd.agent.openeye.deregister.fail": "شماره تلفن نرم افزاری غیرفعال نشد. به صورت دستی از OpenEye خارج شوید.",
    "ccd.agent.openeye.autoanswer": "تماس به صورت خودکار پاسخ داده می شود.",
    "ccd.agent.openeye.autoanswer.fail": "پاسخگویی خودکار به تماس انجام نشد.",
    "ccd.agent.openeye.videowindowparam": "پارامترهای ویدیو با موفقیت راه اندازی شدند.",
    "ccd.agent.openeye.videowindowparam.fail": "مقداردهی اولیه پارامترهای ویدیو ناموفق بود. در نتیجه، عملکرد تماس ویدیویی غیرعادی است.",
    "ccd.agent.openeye.videolayoutmode.success": "تنظیم حالت چیدمان تصویر پنجره ویدیو موفق شد.",
    "ccd.agent.openeye.videolayoutmode.fail": "تنظیم حالت چیدمان تصویر پنجره ویدیو انجام نشد.",
    "ccd.agent.openeye.videodisplaymode.success": "تنظیم حالت برش پنجره ویدیو موفق شد.",
    "ccd.agent.openeye.videodisplaymode.fail": "تنظیم حالت برش پنجره ویدیو انجام نشد.",
    "ccd.agent.frontpage.callreason": "دلیل تماس",
    "ccd.agent.callrelease.end": "مکالمه تلفنی تمام شد",
    "ccd.agent.resetphonepass.inputcur": "رمز عبور حساب جاری",
    "ccd.agent.resetphonepass.input": "کلمه عبور",
    "ccd.agent.resetphonepass.inputagain": "رمز عبور را تایید کنید",
    "ccd.agent.openeye.share": "لطفا انتخاب کنید...",
    "ccd.ccaction.title.shareapp": "برنامه مشترک را انتخاب کنید",
    "ccd.agent.openeye.switchshare.success": "عملکرد اشتراک گذاری دسکتاپ با موفقیت فعال شد.",
    "ccd.agent.openeye.switchshare.fail": "عملکرد اشتراک گذاری دسکتاپ فعال نشد.",
    "ccd.agent.openeye.getapplist.fail": "دریافت لیست برنامه های باز شده انجام نشد.",
    "ccd.agent.openeye.setshareapp.fail": "اشتراک‌گذاری صفحه تنظیم نشد.",
    "ccd.agent.openeye.startshare.fail": "شروع به اشتراک گذاری ناموفق بود.",
    "ccd.agent.openeye.stopshare.fail": "توقف اشتراک‌گذاری انجام نشد.",
    "ccd.agent.button.offlineSignOut": "خروج از موبایل",
    "ccd.agent.button.offlineSignIn": "تغییر موبایل",
    "ccd.agent.button.ok": "خوب",
    "ccd.agent.button.close": "لغو کنید",
    "ccd.agent.message.phone": "شماره تماس گرفته شده",
    "ccd.agent.login.fail": "ثبت نام انجام نشد",
    "ccd.agent.login.failinfo": ". در نتیجه، عملکرد کمک داخلی در دسترس نیست.",
    "ccd.agent.login.multimedia.failinfo": ". در نتیجه، تماس صوتی و تصویری و عملکردهای راهنمای داخلی در دسترس نیستند.",
    "ccd.agent.reset.success": "رزرو استراحت با موفقیت انجام شد.",
    "ccd.agent.openeye.initsuccess": "OpenEye با موفقیت راه اندازی شد.",
    "ccd.agent.pagination.total": "جمع:",
    "ccd.agent.title.consult": "مشورت کردن",
    "ccd.agent.warning.sharescreen": "هنگام اشتراک گذاری دسکتاپ به حریم خصوصی شخصی توجه کنید",
    "ccd.agentmonitor.span.listening": "استماع",
    "ccd.agentmonitor.span.insert": "درج کردن",
    "ccd.agentmonitor.span.whisper": "زمزمه کردن",
    "ccd.message.title.audioIncomingCallNotify": "اعلان تماس ورودی صوتی و تصویری",
    "ccd.message.title.mediaIncomingCallNotify": "اعلان تماس ورودی چند رسانه ای",
    "ccd.message.title.mediaNewMsgNotify": "اعلان پیام چندرسانه ای جدید",
    "ccd.agentMonitor.label.switchlistenorinsert": "در حالت پیش از بازرسی، نماینده ای که زمزمه می شود نمی تواند دکمه Listen یا Insert را کلیک کند.",
    "ccd.agent.agentevent.quality.calloutconnectfail": "نمی توان تماس خروجی برقرار کرد زیرا QC در حال انجام است.",
    "ccd.agent.agentevent.calloutconnectfail": "مشترک مشغول است یا شماره وجود ندارد.",
    "ccd.agent.tips.maxCountQuery.info": "حجم داده فعلی خیلی زیاد است. سیستم فقط آخرین تاریخچه داده های {0} را نمایش می دهد.",
    "ccd.media.tips.new.online.customer": "شما یک مشتری آنلاین جدید دارید",
    "ccd.agent.warning.notification.cancel": "لغو کنید",
    "ccd.agent.warning.notification.setting": "تنظیمات",
    "ccd.agent.warning.notification.allowed": "برای اطمینان از هشدارهای پیام جلسه، وب سایت باید اجازه نمایش اعلان ها را داشته باشد!",
    "ccd.agent.msg.notification.allowed": "درخواست وب سایت AICC به مرورگر اجازه نمایش اعلان ها را بدهید!",
    "ccd.agent.msg.notification.permission.denied": "کاربر مجوز را رد می کند و نمی تواند اعلان را نمایش دهد. لطفا تنظیمات را در مرورگر تغییر دهید.",
    "ccd.agent.msg.queryMsg.specialListQuery": "یادآوری لیست ویژه",
    "ccd.agent.message.AgentIsProhibited": "اجاره ممنوع شده است.",
    "ccd.agent.message.repeatPhonenum": "شماره تلفنی که نماینده برای ورود به سیستم استفاده می کند توسط نماینده دیگری برای ورود به سیستم استفاده شده است. پیکربندی را بررسی کنید.",
    "ccd.agent.message.destinationAgentFailed": "نماینده مقصد اشغال نشد.",
    "ccd.agent.message.noAgent": "هیچ نماینده ای در حال انجام وظیفه یافت نشد.",
    "ccd.agent.warning.waserror": "خطای منبع WAS قابل دسترسی نیست یا یک خطای داخلی رخ داده است.",
    "ccd.agent.message.eventMethodErr": "روش به دست آوردن رویداد نماینده نادرست است.",
    "ccd.agent.message.restInvalid": "پارامتر یا آدرس منتقل شده از طریق URL با تعریف مطابقت ندارد.",
    "ccd.agent.message.restNoRight": "نماینده هیچ مجوزی برای فراخوانی رابط ندارد. ممکن است نماینده مجبور شده باشد از سیستم خارج شود.",
    "ccd.agent.message.invalidWasURL": "مسیر لینک WAS پیکربندی شده روی سرور نادرست است.",
    "ccd.agent.message.loginInvalidPara": "ورود به سیستم انجام نشد. پارامتر ورود به سیستم خالی یا نامعتبر است.",
    "ccd.agent.message.userOrPasswdInvalid": "ورود به سیستم انجام نشد. حساب کاربری یا رمز عبور نادرست است.",
    "ccd.agent.message.logOutInvalidParam": "ورود به سیستم انجام نشد. پارامتر نامعتبر است.",
    "ccd.agent.message.AgentAlreadyLogout": "نماینده از سیستم خارج شده است.",
    "ccd.agent.message.loginInvalidParam": "شماره تلفن نامعتبر است یا توسط نماینده دیگری وارد شده است.",
    "ccd.agent.message.AgentInnerError": "خرابی در عملیات. خطای درونی ممکن است رخ دهد ، یا منبعی در دسترس نباشد ، یا عامل بیکار نیست.",
    "ccd.agent.message.setStatErr": "وضعیت نماینده تنظیم نشد.",
    "ccd.agent.message.logOutStatusErr": "خروج از سیستم انجام نشد. ممکن است یک کار معلق برای رسیدگی داشته باشید.",
    "ccd.agent.message.callOutPhoneErr": "شماره تماس خروجی نامعتبر است.",
    "ccd.agent.message.answerNoCall": "تماسی برای پاسخگویی وجود ندارد.",
    "ccd.agent.message.beginMuteNoCall": "تماسی برای بی‌صدا کردن وجود ندارد.",
    "ccd.agent.message.beginMuteStateErr": "هنگامی که خود را بی صدا می کنید، خطای وضعیت تماس رخ می دهد.",
    "ccd.agent.message.connectHoldNoCall": "تماسی برای نگه داشتن وجود ندارد.",
    "ccd.agent.message.noHoldCall": "تماس برگزار نمی شود.",
    "ccd.agent.message.endMuteNoCall": "وقتی قطع صدا را لغو می کنید تماسی وجود ندارد.",
    "ccd.agent.message.endMuteStateErr": "هنگامی که قطع صدا را لغو می کنید، یک خطای وضعیت تماس رخ می دهد.",
    "ccd.agent.message.threePartyNoCall": "هیچ تماسی برای برقراری تماس سه جانبه وجود ندارد.",
    "ccd.agent.message.threePartyNoHoldCall": "هیچ تماسی برای برقراری تماس سه جانبه برقرار نمی شود.",
    "ccd.agent.message.transferNoCall": "بدون تماس برای انتقال",
    "ccd.agent.message.holdNoCall": "تماسی برای نگه داشتن وجود ندارد.",
    "ccd.agent.message.holdCallStatusErr": "هنگامی که تماس را نگه می‌دارید، خطای وضعیت تماس رخ می‌دهد.",
    "ccd.agent.message.getHoldNoHoldCall": "تماسی برای نگه نداشتن وجود ندارد.",
    "ccd.agent.message.getHoldCallStatusErr": "هنگامی که تماس را لغو می کنید، یک خطای وضعیت تماس رخ می دهد.",
    "ccd.agent.message.releaseNoCall": "تماسی برای قطع کردن وجود ندارد.",
    "ccd.agent.message.insultNoCall": "تماسی برای مشاوره وجود ندارد.",
    "ccd.agent.message.releaseNumberNoCall": "تماسی برای آزاد کردن یک شماره تلفن مشخص وجود ندارد.",
    "ccd.agent.message.mediaSwitchNoCall": "در هنگام سوئیچینگ صدا/تصویر تماسی برقرار نمی شود.",
    "ccd.agent.message.mediaSwitchSuccess": "سوئیچینگ صوتی/تصویری موفقیت آمیز",
    "ccd.agent.message.mediaSwitchFailed": "سوئیچینگ صوتی/تصویری انجام نشد.",
    "ccd.agent.message.releaseNumberNoNumber": "تماسی با شماره تلفن برای آزاد کردن نیست.",
    "ccd.agent.message.loginInvalidPhone": "شماره تلفن نامعتبر.",
    "ccd.agent.message.InvalidNumber": "عدد نامعتبر.",
    "ccd.agent.message.accoutnLocked": "حساب قفل شده است. بعدا وارد شوید.",
    "ccd.agent.message.agentNotLogin": "نماینده وارد سیستم نمی شود.",
    "ccd.agent.callout.notrest": "استراحت را لغو کنید و سپس یک تماس خروجی برقرار کنید.",
    "ccd.agent.message.monitorSelf": "نماینده بازرسی شده و بازرس نمی توانند یکسان باشند.",
    "ccd.agent.message.hangupnocall": "تماس را نمی توان قطع کرد. اگر تماس در حالت انتظار است، لطفاً ابتدا آن را لغو کنید.",
    "ccd.agent.message.default": "خطای داخلی. گزارش را بررسی کنید.",
    "ccd.agent.message.wait": "تماس در حال پردازش است. لطفاً بعداً دوباره امتحان کنید.",
  "ccd.agent.message.reset": "خيلي از تماس ها خراب شدن سعي در همگام کردن وضعيت عامل",
    "ccd.agent.videocall.paramError": "خطای پارامتر هنگام تنظیم حالت نمایش به تماس ویدیویی سه جانبه.",
    "ccd.agent.videocall.noCall": "هنگامی که حالت نمایش تماس ویدیویی سه جانبه تنظیم می شود، خطای تماس رخ نمی دهد.",
    "ccd.agent.videocall.typeError": "هنگامی که حالت نمایش برای تماس ویدیویی سه جانبه تنظیم می شود، نوع تماس نادرست است.",
    "ccd.agent.videocall.stateError": "هنگامی که حالت نمایش برای تماس ویدیویی سه جانبه تنظیم می شود، وضعیت تماس نادرست است.",
    "ccd.agent.videocall.displayError": "هنگامی که حالت ارائه یک تماس ویدیویی سه جانبه تنظیم می شود، یک استثنا رخ می دهد.",
    "ccd.agent.previewcall.invalParam": "پیش نمایش پارامترهای تماس خروجی نادرست است.",
    "ccd.agent.videocall.adjustvideo": "تنظیم اندازه ویدیو",
    "ccd.cccbar.label.inputNumber": "یک عدد وارد کنید",
    "ccd.cccbar.label.voice": "صوتی",
    "ccd.cccbar.label.video": "ویدئو",
    "ccd.cccbar.label.cancel": "لغو کنید",
    "ccd.cccbar.label.send": "ارسال",
    "ccd.cccbar.label.yourself": "خودم",
    "ccd.cccbar.label.nocall": "بدون تماس.",
    "ccd.cccbar.label.callout": "تماس",
    "ccd.cccbar.label.callcenter": "مرکز تماس",
    "ccd.cccbar.label.cccbar": "نوار اتصال",
    "ccd.cccbar.label.empty": "همه را پاک کن",
    "ccd.cccbar.label.backspace": "Backspace",
    "ccd.cccbar.label.total": "جمع:",
    "ccd.cccbar.message.calltip": "سلام، تماس جدیدی در راه است!",
    "ccd.cccbar.message.pleaseAnswer": "پاسخ به تماس.",
    "ccd.cccbar.message.syserrormessage": "خطای سیستم.",
    "ccd.transfer.label.callTransfer": "انتقال تماس",
    "ccd.transfer.label.skill": "صف مهارت",
    "ccd.transfer.label.transferToSkill": "انتقال به صف مهارت",
    "ccd.transfer.label.skillName": "صف مهارت",
    "ccd.transfer.label.queueSize": "در صف منتظر بمانید",
    "ccd.transfer.label.queueSize.ccbar": "در صف منتظر بمانید",
    "ccd.transfer.label.loggedOnAgents": "نماینده موجود",
    "ccd.transfer.label.loggedOnAgents.ccbar": "نماینده موجود",
    "ccd.transfer.label.transferType": "نوع انتقال",
    "ccd.transfer.label.transferTypeColon": "نوع انتقال:",
    "ccd.transfer.label.transferTypeColon.ccbar": "نوع انتقال:",
    "ccd.transfer.label.agent": "نماینده",
    "ccd.transfer.label.transferToAgent": "انتقال به نماینده",
    "ccd.transfer.label.statusUnknow": "ناشناخته",
    "ccd.transfer.label.externalNumber": "شماره خارجی",
    "ccd.transfer.label.transferToExNumber": "انتقال به شماره خارجی",
    "ccd.transfer.label.successTransfer": "انتقال موفق",
    "ccd.transfer.label.releaseTransfer": "انتقال آزاد",
    "ccd.transfer.label.talkingTransfer": "انتقال مکالمه",
    "ccd.transfer.label.hangUpTransfer": "انتقال قطعی",
    "ccd.transfer.label.threeParthTransfer": "انتقال سه نفره",
    "ccd.transfer.label.ivr": "تلفن گویا",
    "ccd.transfer.button.refresh": "بازخوانی",
    "ccd.transfer.button.cancel": "لغو کنید",
    "ccd.transfer.button.sure": "خوب",
    "ccd.transfer.message.NoAgentInSkill": "هیچ نماینده آنلاینی در صف مهارت فعلی وجود ندارد.",
    "ccd.transfer.message.NoIvrOnline": "هیچ جریان تلفن گویا در دسترس نیست.",
    "ccd.transfer.message.inputExNumber": "لطفا شماره انتقال را وارد کنید",
    "ccd.transfer.message.refreshTab": "صفحه را به طور مکرر بازخوانی نکنید.",
    "ccd.transfer.message.inputIvrName": "یک تلفن گویا برای انتقال وارد کنید.",
    "ccd.transfer.message.microsoftTeamsLogin": "ورود",
    "ccd.transfer.message.inputTeamsUser": "حساب تیم را وارد کنید.",
    "ccd.innerhelp.label.help": "مشورت کردن",
    "ccd.innerhelp.label.innerHelpToSkill": "درخواست مشاوره از صف مهارت",
    "ccd.innerhelp.label.innerHelpToAgent": "درخواست مشاوره از نماینده",
    "ccd.innerhelp.label.twoPartyHelp": "مشاوره دو طرفه",
    "ccd.innerhelp.label.threePartyHelp": "مشاوره سه جانبه",
    "ccd.innerhelp.label.helpType": "نوع مشورت ",
    "ccd.innerhelp.message.inputExNumhelp": "یک شماره تماس برای مشاوره وارد کنید",
    "ccd.callinner.label.callinner": "تماس داخلی",
    "ccd.agent.label.recordPlay": "پخش صدا",
    "ccd.agent.title.warning": "هشدار",
    "ccd.agent.message.misconnection": "فراخوانی رابط ماندگاری جلسه ناموفق بود.",
    "ccm.agent.message.selectIvr": "یک جریان تلفن گویا را انتخاب کنید.",
    "ccd.agent.status.occupy": "مشغول",
    "ccd.agent.status.askAnswer": "منتظر پاسخ باشید",
    "ccd.agent.status.talk": "صحبت کردن",
    "ccd.agent.status.hold": "نگه دارید",
    "ccd.agent.status.mute": "بی صدا",
    "ccd.agent.status.callout": "تماس خروجی",
    "ccd.agent.status.threeParty": "سه طرفه",
    "ccd.agent.status.help": "مشورت کردن",
    "ccd.agent.status.innerCall": "تماس داخلی",
    "ccd.agent.status.innerTwo": "مشورت کردن",
    "ccd.agent.status.innerThree": "سه طرفه",
    "ccd.agent.message.checkAgentStatus": "این عملیات را زمانی انجام دهید که بازرس بیکار است.",
    "ccd.agent.message.needLogin": "به عنوان نماینده وارد سیستم شوید و دوباره امتحان کنید.",
    "ccd.agent.tips.restTooLong": "زمان استراحت تمام می شود.",
    "ccd.callout.message.numberFormat": " یک شماره تلفن فقط می تواند شامل ارقام، علامت عددی (#) و ستاره (*) باشد و حداکثر شامل 24 کاراکتر باشد.",
    "ccd.quality.message.noProvider": "خطای NOPROVIDER در حین بررسی کیفیت رخ می دهد.",
    "ccd.quality.message.noAuth": "هیچ استثنای مجوزی در طول بررسی کیفیت رخ نمی دهد.",
    "ccd.quality.message.resourceUnable": "یک استثنا در حین بازرسی رخ می دهد. گزارش های مربوطه را بررسی کنید.",
    "ccd.quality.message.noAgentInfo": "هیچ اطلاعات نماینده ای در طول نظارت بر بررسی کیفیت یافت نشد.",
    "ccd.quality.message.statusErr": "وضعیت در حین بررسی کیفیت غیرعادی است.",
    "ccd.quality.message.inspectInspectorErr": "نماینده بازرسی شده نمی تواند بازرس باشد.",
    "ccd.record.message.jumpBack": "یک خطای حالت در حین چرخیدن به عقب رخ می دهد.",
    "ccd.record.message.jumpForw": "یک خطای حالت در حین ارسال رخ می دهد.",
    "ccd.record.message.pausePlay": "هنگام توقف پخش، یک خطای حالت رخ می دهد.",
    "ccd.record.message.pauseRecord": "هنگام مکث ضبط یک خطای حالت رخ می دهد.",
    "ccd.record.message.resumePlay": "هنگامی که پخش ادامه می یابد، یک خطای حالت رخ می دهد.",
    "ccd.record.message.resumeRecord": "هنگامی که ضبط ادامه می یابد، یک خطای حالت رخ می دهد.",
    "ccd.record.message.startPlay": "هنگام شروع پخش یک خطای حالت رخ می دهد.",
    "ccd.record.message.startRecord": "هنگام شروع ضبط، یک خطای حالت رخ می دهد.",
    "ccd.record.message.stopPlay": "هنگامی که پخش متوقف می شود، یک خطای حالت رخ می دهد.",
    "ccd.quality.message.qualityTitle": "بررسی کیفیت",
    "ccd.quality.message.listen": "استماع",
    "ccd.quality.message.stopListen": "توقف گوش کردن",
    "ccd.quality.message.insert": "درج کنید",
    "ccd.quality.message.stopInsert": "توقف درج",
    "ccd.quality.message.switch": "تعویض",
    "ccd.quality.message.intercept": "رهگیری",
    "ccd.quality.message.whisper": "نجوا",
    "ccd.quality.message.cancelwhisper": "زمزمه را متوقف کنید",
    "ccd.quality.message.forceexit": "خروج اجباری",
    "ccd.quality.message.forcerest": "بیکاری اجباری",
    "ccd.quality.message.forcebusy": "اشغال اجباری",
    "ccd.quality.message.back": "بازگشت",
    "ccd.quality.message.searchWorkno": "لطفا شناسه نماینده را برای جستجو وارد کنید.",
    "ccd.quality.message.interceptErr": "نماینده ای که باید بررسی شود در تماس نیست و نمی توان آن را رهگیری کرد.",
    "ccd.ccaction.message.retCode": "خطای لغو کار هنگامی که خود را مشغول تنظیم کردید. کد خطا:",
    "ccd.ccaction.message.retMessage": ". پیغام خطا:",
    "ccd.ccaction.message.errorRetCode": "خطا! کد خطا:",
    "ccd.ccaction.message.retMessEnd": ".",
    "ccd.ccaction.message.statusError": "در وضعیت فعلی تماسی برای بی‌صدا کردن وجود ندارد.",
    "ccd.ccaction.message.abilityError": "نمی توانید کاربر را در عملکرد رسانه فعلی بی صدا کنید.",
    "ccd.ccaction.message.unHold": "در وضعیت فعلی تماسی برای نگه داشتن جود ندارد.",
    "ccd.ccaction.message.holdError": "تماس فعلی قابل انجام نیست.",
    "ccd.ccaction.message.confJoinErr": "تماسی برقرار نمی شود. تماس سه نفره مجاز نیست.",
    "ccd.ccaction.message.confJoinFail": "نماینده وارد سیستم نمی شود و تماس سه طرفه با شکست مواجه می شود.",
    "ccd.ccaction.message.recording": "صدا فقط زمانی پخش می شود که نماینده در حالت بیکار باشد. لطفا وضعیت نماینده را تغییر دهید.",
    "ccd.ccaction.message.recordFail": "نماینده وارد سیستم نمی‌شود و پخش صدا انجام نمی‌شود.",
    "ccd.ccaction.message.repeateBusy": "نماینده الان مشغول است. مکرراً وضعیت را به مشغول تغییر ندهید.",
    "ccd.ccaction.message.repeateRest": "نماینده در حال استراحت است. به زور نشان ندهید که مشغول است.",
    "ccd.ccaction.message.isForceBusy": "نماینده مشغول نشان داده شده است. مکرراً وضعیت را به مشغول تغییر ندهید.",
    "ccd.ccaction.message.inputWorkNo": "شناسه نماینده خالی است. لطفا شناسه نماینده را وارد کنید.",
    "ccd.ccaction.message.inputIvrName": "نام جریان تلفن گویا خالی است. لطفاً نام جریان تلفن گویا را وارد کنید.",
    "ccd.ccaction.message.logoutfailed": "خروج ناموفق بود. لطفا وضعیت نماینده را بررسی کنید.",
    "ccd.ccaction.message.error": "خطا",
    "ccd.ccaction.message.resignin": "رمز عبور نامعتبر است، لطفاً دوباره بررسی کنید!",
    "ccd.ccaction.message.rest": "استراحت",
    "ccd.ccaction.message.seconds": "ثانیه",
    "ccd.ccaction.message.minutes": "دقایق",
    "ccd.ccaction.message.hour": "ساعتها",
    "ccd.ccaction.message.search.restCauseDesc": "توضیحات دلیل استراحت را وارد کنید.",
    "ccd.callmessage.workbench": "میز کار صوتی و تصویری",
    "ccd.callmessage.chatOnlineWorkbench": "میز کار چت آنلاین",
    "ccd.callmessage.whiteNumberExist": "این شماره در لیست اعتماد وجود ندارد.",
    "ccd.agentstate.notTalking": "نماینده انتخاب شده در حالت صحبت نیست.",
    "ccd.outgoing.out.workbench": "میز کار خروجی",
    "ccd.agent.videomode.triangular": "مثلثی",
    "ccd.agent.videomode.maximizeUser": "کاربر را به حداکثر برسانید",
    "ccd.agent.videomode.maximizeAgent": "نماینده را به حداکثر برسانید",
    "ccd.agent.videomode.maximizeThreeparty": "تماس سه طرفه را به حداکثر برسانید",
    "ccd.agent.mediaswitch.audio": "تماس صوتی",
    "ccd.agent.mediaswitch.video": "تماس تصویری",
    "ccd.agent.mediaswitch.switchaudiofailed": "در حال حاضر در تماس صوتی.",
    "ccd.agent.mediaswitch.switchvideofailed": "در حال حاضر در تماس تصویری.",
    "ccd.agent.mediaswitch.unsupportedagenttype": "نماینده فعلی از تماس های ویدیویی پشتیبانی نمی کند.",
    "ccd.agent.void.call": "تماس خروجی صوتی",
    "ccd.agent.chat.mutimedia.call": "تماس خروجی چند رسانه ای",
    "ccd.agent.chat.start": "شروع یک جلسه",
    "ccd.agent.chat.channle.choose": "انتخاب کانال",
    "ccd.login.message.logoutReason.sessionInvalid": "جلسه نامعتبر است لطفا دوباره وارد شوید",
    "ccd.ccaction.message.registerPhone": "تلفن نرم افزاری را ثبت کنید.",
    "ccd.ccaction.message.loginagent": "به عنوان نماینده وارد شوید.",
    "ccd.contact.message.timeerror": "زمان شروع باید زودتر از زمان پایان باشد.",
    "ccd.contact.message.timerangeerror": "محدوده زمانی نمی تواند از {0} روز تجاوز کند.",
    "ccd.contact.message.timeisempty": "محدوده زمانی نمی تواند خالی باشد.",
    "ccd.ccaction.message.registerPhoneFailed": "ثبت WebRTC ناموفق است. در حال حاضر، توابع کمک داخلی در دسترس نیستند.",
    "ccd.agent.message.selectTeams": "یک حساب تیمی را انتخاب کنید.",
    "ccd.skill.selectskill": "حداقل یک صف مهارت را انتخاب کنید.",
    "ccd.agent.message.qc.restskill": "اگر بازرس به تماس دریافتی پاسخ ندهد، صف مهارت قابل تنظیم مجدد نیست.",
    "ccd.agentMonitor.label.selectAgentOne": "لطفاً نماینده ای را که در حال پردازش است پایان دهید و عملیات دیگری را انتخاب کنید.",
    "ccd.quality.message.qualityAgentTitle": "مانیتور نماینده",
    "ccd.agentMonitor.forceRelease.noAudio": "هیچ تماس صوتی یا تصویری نمی تواند آزاد شود.",
    "ccd.agentMonitor.intercept.noAudio": "هیچ تماس صوتی یا تصویری قابل رهگیری نیست.",
    "ccd.transfer.label.transferNumber": "نشان می دهد که آیا فرآیند یک فرآیند سلف سرویس است یا خیر.",
    "ccd.agent.forcesignout.confirm": "خروج از سیستم بر سرویس اتصالی که نماینده در حال پردازش آن است تأثیر می گذارد. آیا مطمئنید که می خواهید به زور از سیستم خارج شوید؟",
    "ccd.ccagent.contract.preview": "پیش نمایش",
    "ccd.label.search": "جستجو کردن",
    "ccd.label.org.all": "همه سازمان های در دسترس",
    "ccd.title.call.three": "تماس سه طرفه",
    "ccd.agent.message.calloutagent.restskill": "نماینده callout به تماس‌های دریافتی پاسخ نمی‌دهد و نمی‌تواند صف‌های مهارت را بازنشانی کند.",
    "ccd.agent.message.videoLimit": "منابع ویدیویی نماینده  ناکافی است و نماینده وارد سرور رسانه ویدیو نمی شود.",
    "ccd.agent.message.withoutskill": "صف مهارت وجود ندارد.",
  "ccd.agent.videocall.displayWarn" : "اندازه پنجره ویدئو تنها زمانی می تواند تنظیم شود که هر سه طرف در حال پخش ویدئو هستند.",
  "ccd.ccaction.message.nosupport" : "این عملیات در صفحۀ وب پشتیبانی نمی شود. از چشم باز براي لال شدن استفاده کن",
  "ccd.ccbar.before.signin.tips":"قبل از استفاده از تابع اتصال ، برای ثبت نام فشار دهید.",
  "ccd.ccbar.calling.number":"شماره تماس",
  "ccd.ccbar.mediaSwitch.failed":"خرابی در سودهی رسانه.",
  "ccd.ccbar.mediaSwitch.notsupport":"برای تماس های داخلی در حالت ویدئوی MCU مجاز نیست.",
    "ccd.webrtc.message.bad":"سوء",
    "ccd.webrtc.message.poor":"الفقراء",
    "ccd.webrtc.message.good":"حَسَناً",
    "ccd.webrtc.message.recive":"استلم",
    "ccd.webrtc.message.Packetloss":"فقدان الحزمة",
    "ccd.webrtc.message.delay":"تأخر",
    "ccd.webrtc.message.jitter":"ارتجف",
    "ccd.webrtc.message.send":"البريدية",
    "ccd.webrtc.message.signal":"إشارة",
  "ccd.ccbar.webrtc.network.signal.pool":"سیگنال شبکه فعلی ضعیف است که ممکن است روی تماس تاثیر بگذارد.",
  "ccd.agentMonitor.label.switchwhisper": "نمی توانید در حالت قبل گوش دادن یا درج ، نجوا را فشار دهید.",
    'ccd.agent.webrtc.register.tip':'تلفن نرم و ضبط',
    'ccd.webrtc.message.medium':'متوسط',
    'ccd.webrtc.login.success':'تم تسجيل دخول الهاتف بنجاح',
    'ccd.webrtc.login.fail':'Softphone login failure',
  'ccd.webrtc.login.fail.noPwd':'گذرواژه ی آغازین موجود نیست. گذرواژه ی نرمی را تنظیم کنید و دوباره امتحان کنید.',
    'ccd.webrtc.login.fail.pop.tips':'فشل تسجيل الدخول تلقائيًا إلى الهاتف. لا يمكنك استخدام وظيفة الصوت. تسجيل الدخول إلى الهاتف مرة أخرى؟',
    'ccd.webrtc.login.try':'إعادة المحاولة',
    'ccd.webrtc.login.ignore':'تجاهل',
    'ccd.webrtc.login.fail.top.tips':'تسجيل الدخول إلى الهاتف غير طبيعي، ولا يمكن استخدام الوظائف ذات الصلة مثل الصوت.',
  "ccd.consult.message.two.inputExNumber": "لطفاً ، عدد مشورت دو بخش را وارد کن",
  "ccd.consult.message.three.inputExNumber": "لطفاً ، شماره مشاوره سه بخش را وارد کن",
  "ccd.ccbar.mediaSwitch.sfunotsupport":"تماس فعلی از سوئيچ رسانه ای پشتیبانی نمی کند.",
  "ccd.agent.tips.welcome.agentlogout": "مرحباً بعودتك",
  "ccd.agent.message.continuously.online":"سرویس آنلاین بعد از ورود نگهداری می شود. لطفا بعد از اتمام کار، به موقع اونجا رو چک کنید.",
  "ccd.agent.vrpserviceaddress.isempty" : "نشانی کارساز VRP خالی است. برای پیکربندی آن با سرپرست سیستم تماس بگیرید.",
  "ccd.agent.initializeagentex.success" : "مامور با موفقيت ثبت کرد رومیزی شما نظارت خواهد شد و تماس شما ضبط خواهد شد. لطفاً وضعیت کاری خوبی داشته باش",
  "ccd.agent.initializeagentex.passworderror" : "شناسه عامل نادرست یا اسم رمز. خرابی در ثبت با VRC.",
  "ccd.agent.initializeagentex.alreadyregistered" : "مامور با \"وي آر سی\" ثبت شده.",
  "ccd.agent.vrpexe.closed" : "نرم افزار VRC در حال خاموش شدنه",
  "ccd.agent.startMonitorEx.uninitialize" : "اتصال vrc نامعتبر است. دوباره اسم مامور فعلي رو ثبت کن",
  "ccd.agent.startMonitorEx.qcnotexit" : "شیء QC وجود ندارد. بررسی این که آیا سرپرست به طور صحیح به VRC متصل است.",
  "ccd.agent.startMonitorEx.qctimelimitexceed" : "خرابی در اتصال سرپرست با vrc. لطفا دوباره امضا کنيد",
  "ccd.agent.startMonitorEx.qcunsigned": "مامور ثبت نام نکرده بررسی کنید که آیا مامور وارد شده است یا نیاز است که دوباره وارد شود.",
  "ccd.agent.startMonitorEx.othererror": "خرابی در اتصال به کارساز. با کارکنان تعميرات تماس بگير يا بعداً دوباره سعي کن",

}