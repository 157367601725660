export default {
  'oifde.traceCause.000001': 'Le type de transfert n\'est pas configuré pour l\'élément de diagramme de transfert d\'appel.',
  'oifde.traceCause.000002': 'l\'interface est anormale parce que la valeur d\'entrée de l\'inaction est incorrecte.{0}',
  'oifde.traceCause.000003': 'l\'élément de diagramme composite n\'est pas trouvé.',
  'oifde.traceCause.000004': 'Le nœud de départ n\'est pas trouvé.',
  'oifde.traceCause.000005': 'Le nombre de recyclages internes dépasse la limite.',
  'oifde.traceCause.000006': 'Le traitement des éléments du diagramme est anormal.',
  'oifde.traceCause.000007': 'La branche exit est vide.',
  'oifde.traceCause.000008': 'Erreur interne du système.',
  'oifde.traceCause.000009': 'La branche n\'est pas trouvée. {0}.',
  'oifde.traceCause.000010': 'l\'élément de diagramme de transfert de flux ne peut pas être transféré au nœud suivant. {0}',
  'oifde.traceCause.000011': 'Le type d\'élément du diagramme est incorrect.',
  'oifde.traceCause.000012': 'Expression {0} erreur d\'exécution.',
  'oifde.traceCause.000013': 'Impossible d\'exécuter l\'interface tierce.',
  'oifde.traceCause.000014': 'Le modèle de réponse et la variable ne sont pas sélectionnés.',
  'oifde.traceCause.000015': 'Le modèle de SMS n\'est pas configuré pour l\'élément de diagramme d\'envoi de SMS.',
  'oifde.traceCause.000016': 'La langue du modèle de réponse est incompatible avec la langue définie. {0}',
  'oifde.traceCause.000017': 'La langue du modèle de SMS est différente de la langue définie dans l\'élément de diagramme d\'envoi de SMS.{0}',
  'oifde.traceCause.000018': 'Le flux cible et le noeud cible ne sont pas configurés pour l\'élément de diagramme de transfert de flux.',
  'oifde.traceCause.000019': 'Aucun locataire n\'est trouvé.',
  'oifde.traceCause.000020': 'Le locataire a été suspendu.',
  'oifde.traceCause.000021': 'Le module est initialisé plus d\'une fois.',
  'oifde.traceCause.000022': 'l\'élément de diagramme composite n\'a pas de nœud de départ.',
  'oifde.traceCause.000023': 'Erreur interne du système.',
  'oifde.traceCause.000024': 'l\'exécution du flux interne est anormale.',
  'oifde.traceCause.000025': 'Le raccrochage automatique est effectué si des exceptions internes se produisent plus de trois fois.',
  'oifde.traceCause.000026': 'Le flux n\'est pas initialisé.',
  'oifde.traceCause.000028': 'Le flux est terminé.',
  'oifde.traceCause.000029': 'Aucune sortie n\'est trouvée dans l\'élément de diagramme courant dont l\'ID de noeud est {0} et le nom de noeud es {1}.',
  'oifde.traceCause.000030': 'Les données de l\'élément de diagramme dont l\'ID de noeud est {0} et le nom de noeud est {1} sont anormales.',
  'oifde.traceCause.000031': 'Erreur interne du système.',
  'oifde.traceCause.000032': 'l\'exécution du flux interne est anormale.',
  'oifde.traceCause.000033': 'Les informations d\'instance d\'appel n\'existent pas.',
  'oifde.traceCause.000034': 'l\'élément de diagramme de fin d\'appel n\'existe pas.',
  'oifde.traceCause.000035': 'Une erreur s\'est produite lors de la vérification auth_token.',
  'oifde.traceCause.000036': 'Une erreur s\'est produite lors de la vérification auth_token.',
  'oifde.traceCause.000037': 'Une erreur s\'est produite lors de la vérification auth_token.',
  'oifde.traceCause.000038': 'Impossible de traiter le nœud suivant.',
  'oifde.traceCause.000039': 'Impossible d\'invoquer le TUC. {0}',
  'oifde.traceCause.000040': 'l\'erreur n\'est pas trouvée. {0}',
  'oifde.traceCause.000041': 'Erreur d\'exécution de la procédure stockée. {0}',
  'oifde.traceCause.000044': 'L\'URL (adresse IP et numéro de port) n\'est pas dans la liste de confiance.',
  'oifde.traceAdvice.000001': 'Configurez le type de transfert d\'appel pour l\'élément de diagramme de transfert d\'appel.',
  'oifde.traceAdvice.000002': 'Contactez l\'administrateur système pour modifier les paramètres d\'entrée de l\'interface.',
  'oifde.traceAdvice.000003': 'Vérifiez si l\'élément de diagramme composite existe dans le système.',
  'oifde.traceAdvice.000004': 'Vérifiez si le débit est libéré ou si le débit est normal.',
  'oifde.traceAdvice.000005': 'Contactez l\'administrateur système pour vérifier le système.',
  'oifde.traceAdvice.000006': 'Contactez l\'administrateur système pour vérifier si la logique de l\'élément de diagramme est correcte.',
  'oifde.traceAdvice.000007': 'Contactez l\'administrateur système pour vérifier si la logique de l\'élément de diagramme est correcte.',
  'oifde.traceAdvice.000008': 'Contactez l\'administrateur système pour vérifier le système.',
  'oifde.traceAdvice.000009': 'Vérifiez si la sortie de l\'élément de diagramme courant a des branches non traitées. Il est recommandé que tous les éléments du diagramme aient une branche incon,',
  'oifde.traceAdvice.000010': 'Vérifiez si l\'élément de diagramme de transfert de flux est correctement configuré.',
  'oifde.traceAdvice.000011': 'Contactez l\'administrateur système pour vérifier le système.',
  'oifde.traceAdvice.000012': 'Vérifiez si la syntaxe configurée pour l\'expression est correcte et si les variables de l\'expression ont des valeurs.',
  'oifde.traceAdvice.000013': 'Vérifiez si l\'interface tierce est normale.',
  'oifde.traceAdvice.000014': 'Vérifiez l\'élément de diagramme de réponse pour vous assurer qu\'au moins un des modèles de réponse ou une variable de réponse a une valeur.',
  'oifde.traceAdvice.000015': 'Vérifiez si le modèle de SMS est configuré pour l\'élément de diagramme d\'envoi de SMS.',
  'oifde.traceAdvice.000016': 'Vérifiez la configuration du contenu et de la langue dans la langue intégrée du modèle de ressource dans la variable globale et si une nouvelle valeur est attribstration de flux. La langue utilisée pour la lecture doit être configurée dans le modèle de ressource.',
  'oifde.traceAdvice.000017': 'Vérifiez la configuration du contenu et de la langue dans la langue intégrée du modèle de ressource dans la variable globale et si une nouvelle valeur est attribstration de flux. La langue utilisée pour la lecture doit être configurée dans le modèle de ressource.',
  'oifde.traceAdvice.000018': 'Vérifiez si l\'élément de diagramme de transfert de flux est correctement configuré.',
  'oifde.traceAdvice.000019': 'Vérifiez si le code d\'accès transféré par l\'interface de dialogue est normal et existe dans le système.',
  'oifde.traceAdvice.000020': 'Vérifiez si le statut du locataire est normal.',
  'oifde.traceAdvice.000021': 'Vérifiez si l\'application tierce ou l\'IVR envoie à plusieurs reprises une demande d\'initialisation avec le même callid<\/b>.',
  'oifde.traceAdvice.000022': 'Vérifiez si l\'élément de diagramme composite est normal et existe dans le système.',
  'oifde.traceAdvice.000023': 'Contactez l\'administrateur système pour vérifier le système.',
  'oifde.traceAdvice.000024': 'Vérifiez les informations d\'erreur de chaque élément du diagramme et corrigez les erreurs en conséquence.',
  'oifde.traceAdvice.000025': 'Contactez l\'administrateur système pour vérifier le système.',
  'oifde.traceAdvice.000026': 'Le flux d\'exécution est anormal. Vous devez d\'abord demander d\'invoquer l\'interface d\'initialisation.',
  'oifde.traceAdvice.000028': 'Vérifiez si le débit est normal.',
  'oifde.traceAdvice.000029': 'Vérifiez si une sortie de nœud est configurée pour l\'élément de diagramme.',
  'oifde.traceAdvice.000030': 'Contactez l\'administrateur système pour vérifier si la configuration de l\'élément de diagramme est correcte.',
  'oifde.traceAdvice.000031': 'Contactez l\'administrateur système pour vérifier le système.',
  'oifde.traceAdvice.000032': 'Vérifiez les informations d\'erreur de chaque élément du diagramme et corrigez les erreurs en conséquence.',
  'oifde.traceAdvice.000033': 'Vérifiez si l\'interface de la requête de raccrochage envoie l\'identifiant d\'appel incorrect<\/b>.',
  'oifde.traceAdvice.000034': 'Vérifiez si l\'élément de diagramme de fin d\'appel est correctement configuré pour le flux.',
  'oifde.traceAdvice.000035': 'Vérifiez si l\'entrée auth_token est correcte.',
  'oifde.traceAdvice.000036': 'Vérifiez si l\'entrée auth_token est correcte.',
  'oifde.traceAdvice.000037': 'Vérifiez si l\'entrée auth_token est correcte.',
  'oifde.traceAdvice.000038': 'Contactez l\'administrateur système pour vérifier le système.',
  'oifde.traceAdvice.000039': '1. Vérifiez si l\'adresse TUC et le port configurés dans l\'ODFS sont normaux. 2. Vérifiez si les services sur chaque nœud TUC sont normaux.',
  'oifde.traceAdvice.000040': 'Vérifiez si la procédure stockée est normale.',
  'oifde.traceAdvice.000041': 'Vérifiez si la procédure stockée est normale.',
  'oifde.traceAdvice.000044': 'Contactez l\'administrateur système pour l\'ajouter à la liste de confiance.'
}
