import request from '@/utils/request'

/**
 * 查询坐席工作统计
 * @param data
 * @returns {*}
 */
export const queryWork = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/agentWorkStatistics/queryWork',
        method: 'post',
        data
    })
}

/**
 * 查询外呼任务
 * @param data
 * @returns {*}
 */
export const queryTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/agentWorkStatistics/queryTask',
        method: 'post',
        data
    })
}

/**
 * 查询坐席
 * @param data
 * @returns {*}
 */
export const queryAgentInfoByCond = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/taskSample/queryAgent',
        method: 'post',
        data
    })
}

/**
 * 查询导出权限
 * @param data
 * @returns {*}
 */
export const queryExportPermission = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/agentWorkStatistics/queryExportPermission',
        method: 'post',
        data
    })
}
/**
 * 查询外呼业务结果
 * @param data
 * @returns {*}
 */

export const queryBusinessResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryAllCodeAndNameOfTenant',
        method: 'post',
        data
    })
}

/**
 * 导出坐席工作统计
 * @param data
 * @returns {*}
 */
export const agentWorkStatisticsExport = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/agentWorkStatistics/export',
        method: 'post',
        data
    })
}

/**
 * 查询坐席工作统计结果
 * @param data
 * @returns {*}
 */
export const queryTaskExportResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryExportResult',
        method: 'post',
        data
    })
}

/**
 * 查询坐席工作统计数量
 * @param data
 * @returns {*}
 */
export const queryTaskExportResultCount = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryExportResultCount',
        method: 'post',
        data
    })
}

/**
 * 坐席工作统计导出结果下载路径
 * @type {string}
 */
export const downloadAgentTaskDataUrl = '/service-cloud/u-route/isales/task/downloadDataFile'

/**
 * 根据任务id查询用户id
 * @param data
 * @returns {*}
 */
export const queryAgentsByTaskId = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/agentWorkStatistics/queryUserIdsByTaskId',
        method: 'post',
        data
    })
}
