export default {
  "gentconsole.menu.title":"Audio- und Video-Workbench",
  "casemanagement.casequery.button.create":"Fall erstellen",
  "agentconsole.callreason.tips.saved":"Anrufgrund gespeichert.",
  "iolp.workflow.title.name":"Geschäftsprozess",
  "agentconsole.title.onlycustomer":"Nur für Kunden",
  "agentconsole.title.allchat":"Alle Inhalte",
  "agentconsole.title.realtimetransfer":"Echtzeittransfer",
  'agentconsole.management.message.filterYes':"Ja",
  'agentconsole.management.message.filterNo':"Nein",
  "agentconsole.dashboard.enumeration.aweek":"Letzte Woche",
  "SM.LOGIN.BUTTON.CHANGEPWD_CONFIRM":"OK",
  "agentconsole.Intelligent.details":"Einzelheiten",
  "agentconsole.workbench.label.intelligentrecommendation":"Agentenassistent",
  "agentconsole.dashboard.title.case":"Fallübersicht",
  "agentconsole.header.label.searchtask":"In Aufgabe suchen",
  "SM.LOGIN.TITLE.NOTICE_EXP_TIME":"Ablaufzeit",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SUFFIX":"),-Bestätigung:",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME_TO":"An",
  "SM.LOGIN.LABEL.OPER_PHONE":"Mobiltelefonnummer:",
  "agentconsole.workbench.label.callinforeasons":"Grund der ersten Ebene / Grund der zweiten Ebene / Grund der dritten Ebene / Grund der vier Ebenen",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_OPEN_ES":"Das Notfallsystem wurde nicht gestartet. Bitte greifen Sie auf das Produktionsmenü zu, um Geschäftsvorgänge durchzuführen.",
  "agentconsole.dashboard.title.averageevalution":"Durchschnittliche Zufriedenheit",
  "agentconsole.header.title.send.sms.notification":"Ausgehende SMS-Benachrichtigung",
  "agentconsole.index.label.caseconsole":"Fall-Workbench",
  "agentconsole.login.label.empty":"Der Benutzername oder das Kennwort ist leer.",
  "agentconsole.authmanage.label.authtype.menu":"Menüberechtigung",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT_TIPS":"Geben Sie ein Anmeldekonto ein.",
  "SM.LOGIN.LABEL.CHANGEPWD_OLDPWD":"Altes Kennwort",
  "agentconsole.callmessage.setHandledNumber":"Bearbeitete Nummer festlegen",
  "agentconsole.login.label.password":"Kennwort",
  "agentconsole.header.title.send.whatsapp.notification":"Senden von WhatsApp-Benachrichtigungen",
  "agentconsole.login.label.signin":"Anmelden",
  "agentconsole.index.label.chatconsole":"Chat-Workbench",
  "SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE":"Das Kennwort entspricht nicht den Regeln.",
  "agentconsole.menumanage.message.paramvalidatefailed":"Das Menü konnte nicht erstellt werden. Überprüfen Sie die Parameter.",
  "SM.LOGIN.LABEL.OPER_REGION":"Heimatbereich:",
  "SM.LOGIN.TIPS.CHANGEPWD_COMPARE":"Das neue Kennwort muss mit dem bestätigten Kennwort übereinstimmen.",
  "SM.LOGIN.TITLE.UPDATE_PWD_VERIFYCODE_EMPTY":"Der Verifizierungscode ist leer.",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG":"Untergeordnete Organisationseinheit einschließen",
  "agentconsole.common.button.modify":"Ändern",
  "agentconsole.msg.noData":"Keine Daten",
  "SM.LOGIN.TITLE.NOTICE":"Bulletin",
  "SM.LOGIN.TIPS.LOGINSUCCESS":"Anmeldung erfolgreich.",
  "SM.LOGIN.TITLE.PORTAL_REGION_TITLE":"Region",
  "agentconsole.quickentrance.classname":"Bitte geben Sie dem Katalog einen Sortiernamen. Beispiel: Mandantenressourcenverwaltung",
  "SM.LOGIN.TITLE.PORTAL_TITLE":"BES-Portal",
  "SM.LOGIN.MESSAGE.LOGOUT":"Möchten Sie wirklich beenden?",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SWITCH":"Auf das Heimsystem zugreifen",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_EMPTY":"Das neue Kennwort ist leer.",
  "agentconsole.dashboard.title.taskunassigned":"Nicht zugewiesen",
  "SM.LOGIN.BUTTON.RESET":"Zurücksetzen",
  "agentconsole.ccnotification.inputRecipient":"Bitte geben Sie Empfänger ein, mehrere Empfänger werden durch Kommas getrennt",
  "agentconsole.ccnotification.inputOneRecipient":"Geben Sie den Empfänger ein",
  "agentconsole.ccnotification.recipient.validate.limit.one":"Die Anzahl der Empfänger darf 1 nicht überschreiten.",
  "agentconsole.callmessage.callerNo":"Anrufer",
  "SM.LOGIN.LABEL.SUM":"Systembenutzerverwaltung",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES":"Das Unternehmen wurde in den Notstand versetzt. Das Geschäft kann erst abgewickelt werden, nachdem das System wiederhergestellt ist.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NOT_EXIST":"Der Verifizierungscode existiert nicht.",
  "agentconsole.dashboard.evaluation.medium":"3 bis 4 Punkte",
  "agentconsole.header.title.logout":"Abmelden",
  "agentconsole.index.label.casedec":"Sie können einen Fall für jede Serviceanfrage Ihres Kunden erstellen und den gesamten Lebenszyklus der Verarbeitung der Kundenserviceanfrage durch den Fall verfolgen, um sicherzustellen, dass jede Kundenanfrage abgeschlossen wird, und die Kundenzufriedenheit zu verbessern.",
  "agentconsole.common.tips.error":"Fehler",
  "agentconsole.menumanage.title.selectauth":"Bitte wählen Sie eine Berechtigung aus",
  "SM.LOGIN.BUTTON.FORGETPWD_CANCEL":"Abbrechen",
  "agentconsole.Intelligent.noRecord":"Der Dienst wird nicht verarbeitet oder das Abfrageergebnis ist null.",
  "agentconsole.dashboard.evaluation.low":"1-2 Punkte",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_RULE":"Das neue Kennwort entspricht nicht der Kennwortregel.",
  "SM.LOGIN.MESSAGE.SENDSMS_FAILED":"Die SMS-Nachricht konnte nicht gesendet werden. Überprüfen Sie das Back-End-Protokoll.",
  "agentconsole.header.title.notificationcenter":"Benachrichtigungen anzeigen",
  "agentconsole.contacthistory.title":"Kontakte zur Geschichte",
  "agentconsole.login.indexforipcc.help":"Hilfe",
  "agentconsole.authmanage.field.auth_type":"Berechtigungstyp",
  "SM.PORTAL.LABEL.INPUT_OLD_PWD":"Geben Sie das alte Kennwort ein.",
  "SM.LOGIN.BUTTON.FORGETPWD_CONFIRM":"OK",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL_TIPS":"Geben Sie die E-Mail-Adresse ein.",
  "SM.AUC.MESSAGE.PWDRULE_NO":"Nein",
  "agentconsole.callmessage.faq.spread":"Erweitern",
  "agentconsole.querycustominfo.msg.inputHandleNumber":"Geben Sie eine bearbeitete Nummer ein",
  "agentconsole.login.indexforipcc.difbutton":"Je nach Berechtigung des Kundendienstpersonals können unterschiedliche Betriebssteuerungstasten angezeigt werden, um Fehlbedienungen und unnötige Störungen zu vermeiden und so die Serviceeffizienz des Kundendienstpersonals zu verbessern.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGIN_ID_NULL":"Das Anmeldekonto darf nicht leer sein.",
  "agentconsole.common.button.ok":"OK",
  "agentconsole.login.indexforipcc.agentcall":"Anrufsteuerung durch Agenten, z. B. Anrufannahme, Anrufweiterleitung, Drei-Wege-Anrufe, Hilfeanfragen usw.",
  "agentconsole.contacthistory.oneMin":"Vor 1 Minute",
  "SM.LOGIN.TITLE.PASSWORD_HASEXPIRED":"Ihr Passwort ist abgelaufen. Wenden Sie sich an den Administrator, um das Kennwort zurückzusetzen.",
  "SM.LOGIN.LABEL.FRAMEWORK_CALCULATOR":"Rechner",
  "SM.LOGIN.LABEL.NOTICE_GRADE_NORMAL":"Normal",
  "SM.LOGIN.LABEL.CUS_LOGIN":"Kundenanmeldung",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLOYEE":"Zurücksetzen von Mietbenutzerkennwörtern in Batches",
  "agentconsole.sessionmanagement.label.clientIp":"Client-IP",
  "agentconsole.header.label.searchbase":"Im Knowledge Center suchen",
  "agentconsole.login.resetpwd.empty":"Der Benutzername oder die E-Mail-Adresse ist leer.",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED":"Das Menü konnte nicht geöffnet werden.",
  "agentconsole.login.indexforipcc.cccbar":"Die Sitzleiste im Agenten-Frame stellt die wichtigsten Funktionen für die Sprachanrufverarbeitung und die Steuerung des Agentenstatus bereit.",
  "SM.LOGIN.TITLE.LOGIN_TITLE":"Anmeldeseite des BES-Portals",
  "SM.LOGIN.TIPS.UPDATE_PWD":"Kennwort ändern",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNT":"Konto",
  "SM.LOGIN.TITLE.CURRENT_BE":"Aktuelle Stadt",
  "agentconsole.callmessage.faq.folded":"Kollabieren",
  "agentconsole.workbench.queNum":"Anzahl der aktuellen Kunden in der Warteschlange:",
  "SM.LOGIN.TITLE.PROJECT_CREATER":"Ersteller:",
  "SM.LOGIN.MESSAGE.LOGIN.LABEL.EMPTY":"Der Benutzername oder das Kennwort ist leer.",
  "agentconsole.common.expiry.phone":"Service-Hotline",
  "agentconsole.menumanage.message.urlvalidatefailed":"Das Menü konnte nicht erstellt werden. Wenden Sie sich an den Systemadministrator, um die Vertrauensliste der IP-Adressen zu überprüfen.",
  "SM.LOGIN.TITLE.PASSWORD_INIT_SHOULD":"Sie verwenden das ursprüngliche Kennwort. Ändern Sie das Kennwort sofort.",
  "SM.LOGIN.LABEL.RESETPWD.FAIL":"Das Zurücksetzen des Kennworts ist fehlgeschlagen.",
  "agentconsole.login.indexforipcc.connectcontrol":"Verbindungszustandssteuerung",
  "agentconsole.dashboard.title.caseunhandle":"Unverarbeitet",
  "SM.LOGIN.TIPS.MODIFY_SUCCESS":"Geändert erfolgreich.",
  "SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS":"Einheit: Zweiter. Bereich: 1 bis 86400. Wenn dieser Parameter leer bleibt, wird der Standardwert 3600 verwendet.",
  "agentconsole.dashboard.title.casecreate":"Anzahl der erstellten Fälle",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGINID_NO_BIND_MSISDN":"Der Benutzer ist an keine Mobiltelefonnummer gebunden.",
  "SM.PORTAL.LABEL.INPUT_NEW_PWD":"Geben Sie ein neues Kennwort ein.",
  "agentconsole.menumanage.message.renamenode":"Der Menüname ist bereits vorhanden.",
  "agentconsole.contacthistory.accept":"Verarbeitete Nummer",
  "SM.LOGIN.LABEL.REMEMBER":"Benutzernamen merken",
  "agentconsole.login.indexforipcc.signinsuccess":"Nachdem sich der Agent erfolgreich angemeldet hat, kann er den Anruf des Kunden annehmen und sich um das Geschäft kümmern. Das System weist Kundenanrufe basierend auf den eingecheckten Agenten zu. Das Management-Personal führt das Anwesenheitsmanagement entsprechend der Check-in- und Check-out-Zeit des Agenten durch.",
  "agentconsole.login.indexforipcc.usenow":"Sofort verwenden",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD":"Kennwort bestätigen:",
  "agentconsole.authmanage.label.authtype.interface":"Schnittstellenberechtigung",
  "agentconsole.login.indexforipcc.callcontrol":"Sprachanrufsteuerung",
  "SM.LOGIN.LABEL.RESETPWD":"Klicken Sie auf Zurücksetzen. Das neue Kennwort wird an die Mailbox gesendet.",
  "agentconsole.header.title.send.notification":"Benachrichtigung senden ",
  "agentconsole.login.indexforipcc.difauth":"Verwalten Sie die Organisation, die Rollen, die Berechtigungen und die Mitarbeiter des Mandanten. Nachdem sich das Kundendienstpersonal über das Mitarbeiterkonto angemeldet hat, unterscheiden sich die Anrufsteuerungsschaltflächen und Seitenmenüs, die angezeigt werden können, je nach den verschiedenen Rechten. Nur wenn sich der Benutzer anmeldet, aber nicht eingecheckt ist, können die anrufbezogenen Vorgänge nicht ausgeführt werden.",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.IP_CHECK_FAILED":"IP-Adresse konnte nicht verifiziert werden. Die aktuelle IP-Adresse unterscheidet sich von der für die erste Anmeldung.",
  "agentconsole.menumanage.message.menuname":"Die Anzahl der Bytes muss kleiner oder gleich 50 sein.",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_OPEN_BE_ES":"Das aktuelle Gebiet wurde in den Notstand versetzt. Melden Sie sich beim Notfallsystem an, um das Geschäft abzuwickeln.",
  "agentconsole.login.indexforipcc.doublecontrol":"Die intelligente Kundenservice-Cloud verfügt über zwei Ebenen der Authentifizierungssteuerung für das Agentenanmeldesystem:",
  "SM.LOGIN.MESSAGE.SUGGESTION":"Aufforderung",
  "SM.LOGIN.MESSAGE.SEARCH_HISTORY_CLEAR":"Verlauf löschen",
  "agentconsole.common.expiry.prefix":"Ihr Mandant läuft am ab",
  "SM.LOGIN.TITLE.ICPCODE":"Registrierungsnummer für Jiangsu ICP: {0}-{1}",
  "SM.LOGIN.LABEL.OPER_LOGINID":"Anmeldekonto:",
  "SM.LOGIN.LABEL.NOTICE_ORG":"OU",
  "agentconsole.quickentrance.classdesc":"Bitte schreiben Sie eine Notiz für dieses Verzeichnis. Beispiel: Mandantenressourcen verwalten. Sie können die Wartung von Mandantenressourcen hier anzeigen.",
  "SM.LOGIN.LABEL.CHANGEPWD_ACCOUNT":"Konto",
  "SM.LOGIN.TIPS.LOGINRESULT":"Ergebnis der Anmeldung:",
  "SM.LOGIN.TITLE.CHANGEPWD_ERROR":"Fehler",
  "SM.LOGIN.LABEL.AUTH_FAILED_ES":"Die aktuelle Stadt ist in den Ausnahmezustand übergegangen. Bitte melden Sie sich im Notfallsystem an.",
  "agentconsole.custinfo.label.amount":"Zahlungsbetrag ",
  "agentconsole.sessionmanagement.label.startime":"Startzeit",
  "agentconsole.sessionmanagement.label.accountNumber":"Konto",
  "agentconsole.callmessage.setNumerError":"Handling-Nummer konnte nicht festgelegt werden.",
  "agentconsole.callmessage.query.external.custom.info":"Externe benutzerdefinierte Informationen konnten nicht abgefragt werden. Wenden Sie sich an das O&M-Personal, um Protokolle einzusehen.",
  "SM.LOGIN.LABEL.FRAMEWORK_ONLINE_HELP":"Online-Hilfe",
  "SM.INDEX.LABEL.TASK":"Aufgabe",
  "SM.LOGIN.LABEL.VCODE_INVALID":"Der Verifizierungscode ist falsch.",
  "agentconsole.dashboard.evaluation.unit":"Anzahl",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_CLOSE":"Details>",
  "agentconsole.workbench.talkNum":"Der heutige Anruf zählt ",
  "agentconsole.authmanage.message.renamenode":"Berechtigung umbenennen.",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_GETCODE":"Verifizierungscode abrufen",
  "agentconsole.index.label.querycase":"Fall abfragen",
  "agentconsole.custinfo.label.dueDate":"Fälligkeitsdatum",
  "agentconsole.login.label.verifycode":"Verifizierungscode",
  "SM.INDEX.LABEL.CHATDEC":"Während eines Multimedia-Online-Chats mit Ihrem Kunden können Sie die gesamten historischen Kontaktinformationen des Kunden anzeigen, um Ihre Serviceeffizienz zu maximieren und Ihrem Kunden ein Omnichannel-Serviceerlebnis zu bieten.",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPFAIL":", ausgefallene Mitarbeiter:",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_MSG":"Das System wird beendet, wenn Sie auf das System der BU zugreifen möchten. Sie müssen den Benutzernamen und das Passwort eingeben, um sich anzumelden. Möchten Sie wirklich fortfahren?",
  "agentconsole.wordreminder.norecommend":"Es ist kein empfohlenes Skript verfügbar.",
  "agentconsole.sessionmanagement.table.deleteConfimMessage":"Möchten Sie diese Sitzung wirklich löschen?",
  "agentconsole.login.indexforipcc.agent":"Agentenzertifizierung",
  "agentconsole.dashboard.title.taskunhandle":"Unverarbeitet",
  "SM.LOGIN.MESSAGE.CHANGEPWD_FAILED":"Das Kennwort konnte nicht geändert werden.",
  "agentconsole.login.indexforipcc.transfer":"Anrufweiterleitung",
  "agentconsole.custinfo.label.custName":"Kundenname",
  "SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB":"Geben Sie mindestens {0} Zeichen ein.",
  "agentconsole.index.label.taskconsole":"Aufgaben-Workbench",
  "agentconsole.authmanage.message.authlimit":"Wenn die Ausnahme durch unzureichende Berechtigungen oder andere Gründe verursacht wird, wenden Sie sich an den technischen Support von Huawei.",
  "SM.LOGIN.MESSAGE.SENDMAIL_FAILED":"Die E-Mail konnte nicht gesendet werden.",
  "SM.LOGIN.LABEL.OPER_BE":"BE:",
  "agentconsole.index.label.taskdec":"Während der Fallbearbeitung können Sie Aufgaben entsenden, um mit mehreren Abteilungen und Mitarbeitern zu arbeiten. Für einen Fall können mehrere Aufgaben erstellt werden.",
  "agentconsole.menumanage.message.notdelnoleafmenu":"Löschen des Menüs mit Untermenüs nicht zulassen.",
  "agentconsole.dashboard.title.casepriority":"Aufgabenliste",
  "SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT":"Bitte Namen eingeben",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_PROJECT":"Projekt wechseln",
  "agentconsole.header.title.messageCenter":"Interne Nachrichtenverwaltung",
  "agentconsole.login.label.email":"E-Mail-Adresse",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CANCEL":"Abbrechen",
  "SM.LOGIN.LABEL.FORGETPWD_SENDTYPE":"Abrufmodus",
  "agentconsole.authmanage.message.authtype":"Der Name der Behörde kann nicht eingegeben werden. Sonderzeichen sind nicht leer und die Anzahl der Bytes muss kleiner oder gleich 50 sein.",
  "SM.LOGIN.TITLE.PROJECT_DESC":"Projektbeschreibung:",
  "agentconsole.dashboard.title.caseperday":"Status der täglichen Verarbeitung",
  "agentconsole.menumanage.message.createsubnodefail":"Die Menüebene darf drei Ebenen nicht überschreiten.",
  "SM.LOGIN.TIPS.WELCOME":"Willkommen beim BES!",
  "SM.LOGIN.TITLE.LOGIN":"Anmelden",
  "agentconsole.workbench.signInCallNum":"Anzahl der aktuellen Anmeldeanrufe:",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_VCODE":"Verifizierungscode",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY":"Ändern",
  "SM.LOGIN.LABEL.OPER_EMAIL":"E-Mail-Adresse:",
  "SM.LOGIN.MESSAGE.SUM":"Gesamt:",
  "SM.LOGIN.LABEL.VCODE":"Verifizierungscode",
  "agentconsole.login.indexforipcc.recordcontrol":"Aufzeichnungs- und Wiedergabesteuerung",
  "SM.LOGIN.MESSAGE.MODIFY_PWD_SUCCESS":"Passwort erfolgreich geändert.",
  "SM.LOGIN.TITLE.PROJECT_CREATE_TIME":"Erstellungszeit:",
  "agentconsole.header.label.searchcase":"Suche im Fall",
  "SM.LOGIN.TITLE.NOTICE_EFF_TIME":"Effektive Zeit",
  "SM.LOGIN.SSO.IS.INVALID":"Die Seite ist abgelaufen. Klicken Sie hier, um die Seite zu aktualisieren.",
  "agentconsole.history.lastloginfailattempts":"Letzte fehlgeschlagene Anmeldeversuche",
  "SM.LOGIN.LABEL.ERRORPAGE_RETURNBTN":"Zurück zur Anmeldeseite",
  "agentconsole.menu.extended.apps":"Erweiterte Apps",
  "SM.LOGIN.MESSAGE.SWITCH":"Möchten Sie wirklich zum ESOP-System wechseln?",
  "SM.LOGIN.RESETPWD.FAIL":"Die Anforderung zum Zurücksetzen des Kennworts konnte nicht verarbeitet werden. Überprüfen Sie das Anmeldekonto und die E-Mail-Adresse.",
  "agentconsole.history.loginip":"Anmelde-IP",
  "agentconsole.login.indexforipcc.call":"Sprachanrufsteuerung",
  "agentconsole.index.label.task":"Aufgabe",
  "agentconsole.quickentrance.checkcharacterlength":"Geben Sie nicht mehr als {0} Zeichen ein.",
  "agentconsole.index.label.entry":"Schneller Einstieg",
  "agentconsole.quickentrance.information":"Informationen",
  "SM.LOGIN.LABEL.FRAME_SEARCH_EMPLOYEE":"Mitarbeiterabfrage",
  "agentconsole.querycustominfo.msg.specialStrValidateFailed":"Nur die folgenden Sonderzeichen sind supported: {'@'}.-",
  "agentconsole.modifycustominfo.modifyfailed":"Änderung fehlgeschlagen.",
  "agentconsole.modifycustominfo.modifyfailed.validate":"Änderung fehlgeschlagen, entspricht nicht der Zeichenfolgenregel oder -länge.",
  "agentconsole.modifycustominfo.modifyfailed.validate.num":"Änderung fehlgeschlagen, entspricht nicht den Zahlenregeln oder der Länge.",
  "agentconsole.modifycustominfo.modifyfailed.validate.date":"Änderung fehlgeschlagen. Das Datumsformat ist falsch.",
  "agentconsole.modifycustominfo.modifyfailed.validate.time":"Änderung fehlgeschlagen. Das Format von Datum und Uhrzeit ist falsch.",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_EMPTY":"Das alte Kennwort ist leer.",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT":"Anmeldekonto",
  "provision.menuurl.protocol.check":"Beachten Sie, dass die aktuelle Menüadresse ein nicht sicheres Netzwerkprotokoll verwendet. Wenn Sie sich für die Verwendung eines Netzwerkprotokolls entscheiden, besteht möglicherweise ein Sicherheitsrisiko.",
  "agentconsole.dashboard.title.taskpriority":"Aufgabenliste",
  "agentconsole.authmanage.field.auth_name":"Berechtigungsname",
  "agentconsole.inputplaceholder.enter_keyword":"Geben Sie ein Stichwort ein.",
  "agentconsole.login.indexforipcc.gateway":"Gateway öffnen",
  "SM.LOGIN.TITLE.UNIFIED_CASHER":"Einheitliche Kasse",
  "SM.LOGIN.TITLE.SHOPPING_CART":"Einkaufswagen",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME":"Erstellungszeit",
  "SM.LOGIN.RESETPWD.EMPTY":"Das Anmeldekonto oder die E-Mail-Adresse ist leer.",
  "agentconsole.header.label.searchhelp":"Im Hilfe-Center suchen",
  "SM.LOGIN.LABEL.NOTICE_CHAN_TYPE":"Kanaltyp",
  "agentconsole.login.label.service_cloud_title":"Kundendienst-Cloud",
  "agentconsole.custinfo.title.custinfo":"Kundeninformationen",
  "SM.LOGIN.TITLE.SEARCH_CONDITION":"Abfragebedingung",
  "SM.LOGIN.TITLE.OPERATOR":"Aktueller Betreiber",
  "agentconsole.history.passwordremainingdays":"Verbleibende Kennworttage",
  "SM.LOGIN.TIPS.REQUIRED":"Dieser Parameter ist obligatorisch",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL":"E-Mail-Adresse:",
  "agentconsole.menumanage.message.homepagesubnode":"Untermenü kann der Startseite nicht hinzugefügt werden.",
  "SM.LOGIN.LABEL.ADD_FAST_MENU":"Kontextmenü hinzufügen",
  "agentconsole.login.restpwd.success":"Passwort erfolgreich zurückgesetzt.",
  "SM.LOGIN.TITLE.CHOOSE_SELECT_ORG":"Organisationseinheit auswählen",
  "agentconsole.modifycustominfo.modifyexternalfailed":"Derzeit können Daten von Drittanbietern nicht geändert werden.",
  "SM.LOGIN.LABEL.HOME":"Startseite",
  "SM.LOGIN.TIPS.MODIFY":"Ändern",
  "agentconsole.authmanage.paneltitle.authmanage":"Berechtigungsverwaltung",
  "agentconsole.menumanage.label.newmenu":"Neues Menü",
  "SM.LOGIN.MESSAGE.HAVE_LOCK_USER":"Der Benutzer wurde gesperrt. Entsperren Sie den Benutzer oder warten Sie, bis das System den Benutzer automatisch entsperrt.",
  "SM.LOGIN.LABEL.RECHARGE":"Aufladen",
  "SM.LOGIN.MESSAGE.UNLOCK":"Möchten Sie das System wirklich sperren?",
  "agentconsole.querycustominfo.msg.noData":"Keine Daten",
  "agentconsole.callmessage.setNumerSuccess":"Das Festlegen der Bearbeitungsnummer war erfolgreich.",
  "agentconsole.workbench.talkavg":"Durchschnittliche Gesprächsdauer (s):",
  "agentconsole.dashboard.title.taskdone":"Anzahl der bearbeiteten Aufgaben",
  "agentconsole.dashboard.title.web":"Webseite",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD":"Neues Kennwort:",
  "SM.LOGIN.TIPS.ENTER_PROJECT_NAME":"Geben Sie den Projektnamen ein.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NULL":"Der Verifizierungscode ist leer.",
  "agentconsole.index.label.querycasenew":"Abfragefall-Workbench (neu)",
  "agentconsole.login.indexforipcc.thirdpartysystem":"Drittanbietersysteme können über eine integrierte Schnittstelle implementiert werden:",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_PREFIX":"Das aktuelle Geschäft wurde in den Notstand versetzt. Bitte besuchen Sie",
  "SM.LOGIN.TITLE.REGISTERCODE":"Registrierungsnummer der Jiangsu Public Security-Website: {0}",
  "SM.LOGIN.LABEL.LOCK":"Bildschirm sperren",
  "agentconsole.workbench.label.callreason":"Anrufgrund",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_NEWPWD":"Das neue Kennwort darf nicht mit dem alten Kennwort identisch sein.",
  "SM.LOGIN.TITLE.NOTICE_MORE":"Mehr",
  "SM.LOGIN.MESSAGE.CHANGE_PWD_RULE":"Das Kennwort konnte nicht geändert werden, da das neue Kennwort nicht der folgenden Kennwortregelgruppe entspricht:",
  "SM.LOGIN.RESETPWD.SUCCESS":"Die Anforderung zum Zurücksetzen des Kennworts wurde erfolgreich verarbeitet. Bitte überprüfen Sie die E-Mail.",
  "SM.LOGIN.LABEL.FRAMEWORK_NOTEPAD":"Notizblock",
  "agentconsole.menumanage.message.notdelbasemenu":"Löschen des öffentlichen Menüs nicht zulassen.",
  "agentconsole.quickentrance.newpop":"Eintrag hinzufügen",
  "agentconsole.login.label.account":"Konto",
  "SM.LOGIN.LABEL.NOTICE_TITLE":"Titel des Bulletins",
  "SM.LOGIN.TITLE.FORGETPWD_FINDEMAILBYPWD":"Passwörter über E-Mails abrufen",
  "agentconsole.login.indexforipcc.advantage":"Produktvorteile",
  "agentconsole.login.indexforipcc.characteristics":"Geschäftsmerkmale",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_PREFIX":"Das aktuelle Gebiet wurde in den Notstand versetzt. Melden Sie sich beim Notfallsystem an und greifen Sie auf das zu",
  "agentconsole.Intelligent.robot":"Intelligenter Roboter",
  "agentconsole.header.label.servicecloud.home":"Fall-Homepage",
  "agentconsole.header.title.news":"Neuigkeiten",
  "SM.LOGIN.TIPS.FIRSTLOGINSYSTEM":"Erstmalige Anmeldung beim System",
  "agentconsole.sessionmanagement.label.operation":"Betrieb",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.SESSION_INVALID":"Die aktuelle Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
  "SM.LOGIN.TITLE.UPDATE_PWD":"Kennwort ändern",
  "agentconsole.workbench.label.callsummary":"Zusammenfassung nach dem Anruf",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_EXPIRE":"Verifizierungscode konnte nicht verifiziert werden.",
  "agentconsole.login.label.remember":"Benutzernamen merken",
  "agentconsole.workbench.label.usermenu":"Benutzermenü",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_SUCCESS":"Der Verifizierungscode wurde gesendet. Bitte überprüfen Sie dies.",
  "agentconsole.quickentrance.addentrance":"Eintrag hinzufügen",
  "agentconsole.history.lastloginsuccess":"Letzte erfolgreiche Anmeldung",
  "agentconsole.login.indexforipcc.description":"Als wichtige Brücke zwischen Unternehmen und Kunden bietet IPCC (IP Contact Center) Sprachanrufe, Online-Kundendienst und andere Kanäle, um Kunden mit Fragen, Geschäftsberatung und Beschwerdebearbeitung zu versorgen. Basierend auf der aktuellen Netzwerkumgebung mit hoher Bandbreite und hoher Geschwindigkeit unterstützt es auch neue Funktionen wie Videoagenten und Self-Service-Sprachnavigation. Während es den Kunden ein neues Serviceerlebnis bietet, erforscht es neue Marketingmodelle, die auf Video und künstlicher Intelligenz basieren.",
  "SM.LOGIN.LABEL.OPER_ORG":"Organisationseinheit:",
  "SM.LOGIN.LABEL.USERNAME":"Konto",
  "agentconsole.contacthistory.contactRecord":"Kontaktdatensatz",
  "agentconsole.login.label.resetpwd":"Klicken Sie auf Zurücksetzen. Das neue Kennwort wird an die Mailbox gesendet.",
  "agentconsole.wordreminder.greetings":"Grüße",
  "agentconsole.common.expiry.email":"E-Mail-Adresse",
  "agentconsole.modifycustominfo.modifysuccess":"Geändert erfolgreich.",
  "SM.LOGIN.LABEL.FORGETPWD_FINDPWD":"Passwort abrufen",
  "agentconsole.common.label.delete":"Löschen",
  "agentconsole.login.label.verifycode.error":"Der Verifizierungscode ist falsch.",
  "agentconsole.icontitle.delete":"Löschen",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_PREFIX":"Das System erkennt, dass die aktuelle Systemadresse nicht die Adresse des Home-BE-Systems ist (",
  "SM.LOGIN.BUTTON.UPDATE_PWD_RULE":"Kennwortregel",
  "SM.LOGIN.TITLE.NOTICE_NUMBER":"Nein.",
  "agentconsole.icontitle.create_child":"Unterebene hinzufügen",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_SWITCH_ES":"Das System wird nicht in den Notzustand versetzt. Greifen Sie auf das Produktionsmenü zu, um Geschäftsvorgänge durchzuführen.",
  "SM.LOGIN.TITLE.OPERATION_FAILED":"Vorgang fehlgeschlagen",
  "agentconsole.login.label.refresh_verify_code":"»Unklar. Ändern Sie eine andere.",
  "agentconsole.login.indexforipcc.difstatus":"Der Agentenstatus bezieht sich auf verschiedene Status, in denen sich der Kundendienstmitarbeiter nach der Anmeldung beim Agenten befinden kann, einschließlich \Leerlaufstatus\", \"Gesprächsstatus\", \"Abschlussstatus\", \"Besetzt-Status anzeigen\" und \"Ruhestatus\". Indem Sie den Anruf durch einen Kundendienstmitarbeiter annehmen, verlassen Sie, die gezeigte Sequenzoperation belegt usw., um die Konversionsmittelzustände zu erreichen. Basierend auf dem Status des Agenten bestimmt das System, ob es möglich ist, einen eingehenden Anruf dem Kundendienstmitarbeiter zuzuweisen.",
  "SM.LOGIN.TITLE.PORTAL_PROJECT_TITLE":"Projekt auswählen",
  "SM.LOGIN.LABEL.FORGETPWD_NUMBER":"Mobiltelefonnummer",
  "agentconsole.modifycustominfo.nomodifyauth":"Der aktuelle Anwender ist nicht berechtigt, angepasste Daten zu ändern.",
  "SM.LOGIN.MESSAGE.SWITCHERROR":"Umschaltung fehlgeschlagen.",
  "SM.LOGIN.LABEL.RESETPWD.SUCCESS":"Passwort erfolgreich zurückgesetzt.",
  "agentconsole.authmanage.message.createsubnodefail":"Die Berechtigungsstufe darf drei Stufen nicht überschreiten.",
  "agentconsole.login.indexforipcc.qualitycheck":"Funktionen im Zusammenhang mit der Echtzeit-Qualitätsprüfung, z. B. Einfügen, Abfangen, Überwachen usw.",
  "agentconsole.authmanage.tab.basic_information":"Grundlegende Informationen",
  "SM.LOGIN.LABEL.NOTICE_ROLE":"Empfängerrolle",
  "agentconsole.Intelligent.labelname":"Intelligente Sprachaufzeichnung",
  "agentconsole.iolp.labelname":"Geschäftsanfrage",
  "agentconsole.iolp.entrancename":"Serviceanleitung",
  "agentconsole.header.label.changelog":"Änderungsprotokoll",
  "SM.LOGIN.BUTTON.CHANGEPWD_CANCEL":"Abbrechen",
  "SM.LOGIN.LABEL.LANG":"Sprache",
  "agentconsole.login.indexforipcc.idle":"Das System kann eingehende Anrufe nur Agenten im Leerlaufstatus zuweisen.",
  "SM.LOGIN.LABEL.ACTIVITY":"Marketingaktivität",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_FAILED":"Überprüfung fehlgeschlagen. Versuchen Sie es später erneut.",
  "SM.LOGIN.LABEL.NOTICE_NOTE_TYPE":"Bulletintyp",
  "SM.LOGIN.TITLE.SELECTBE":"GE auswählen",
  "agentconsole.dashboard.enumeration.otherday":"Anderes Datum",
  "SM.LOGIN.TITLE.SERACH_MENU":"Geben Sie den Menünamen ein.",
  "agentconsole.dashboard.evaluation.high":"5 Punkte",
  "SM.LOGIN.TITLE.CHOOSE_ORG_LIST":"Wählen Sie eine Organisation aus.",
  "agentconsole.login.indexforipcc.mute":"Stummschalten",
  "SM.LOGIN.LABEL.CUS_LOGIN_REDIRECT":"Melden Sie sich an und springen Sie zu",
  "SM.LOGIN.TITLE.SELECT_ACCOUNT_TYPE":"Wählen Sie einen Kontotyp aus.",
  "SM.LOGIN.TITLE.PRINT_FREE_FILLING":"ACK-Slip drucken",
  "agentconsole.sessionmanagement.label.search":"Abfrage",
  "agentconsole.menumanage.message.menu_url":"Die Menüadresse darf nicht chinesisch sein.",
  "SM.LOGIN.LABEL.NOTICE_CREATOR":"Ersteller",
  "SM.LOGIN.LABEL.CHANGEPWD_COMFIRMPWD":"Kennwort bestätigen",
  "agentconsole.index.label.chat":"Online-Kundendienst",
  "agentconsole.authmanage.rule.tips":"Bitte wählen Sie eine Berechtigung aus, die Sie angepasst haben. Die vom System vordefinierte Berechtigung kann nicht ausgewählt werden.",
  "agentconsole.login.indexforipcc.title":"Cloud-Callcenter",
  "agentconsole.dashboard.title.casedone":"Anzahl der bearbeiteten Fälle",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLISTEND":"]",
  "SM.LOGIN.BUTTON.FORGETPWD_EMAIL":"E-Mail-Adresse",
  "agentconsole.login.indexforipcc.lightweight":"Leicht",
  "agentconsole.Intelligent.close":"Schließen",
  "agentconsole.header.label.searchcommunity":"Suche in der Community",
  "agentconsole.dashboard.title.interactioncount":"Gesamte Kontaktmenge am aktuellen Tag:",
  "agentconsole.modifycustominfo.noqueryauth":"Der aktuelle Anwender ist nicht berechtigt, angepasste Daten abzufragen.",
  "agentconsole.common.tips.warning":"Warnung",
  "SM.LOGIN.TITLE.HELPCENTER":"Hilfezentrum",
  "agentconsole.contacthistory.talking":"Sprachsitzung",
  "agentconsole.login.indexforipcc.hold":"Anruf halten",
  "agentconsole.menumanage.tab.basic_information":"Grundlegende Informationen",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_MINTIME_ERROR":"Der Verifizierungscode wurde generiert.",
  "agentconsole.contacthistory.readMore":"Weitere Informationen",
  "agentconsole.authmanage.message.deleteauthfail":"Die Berechtigung konnte nicht gelöscht werden. Bitte überprüfen Sie, ob die Berechtigung von einem Menü referenziert oder eine Rolle zugewiesen wurde.",
  "agentconsole.quickentrance.deleteinfo":"Möchten Sie dieses Verzeichnis wirklich löschen",
  "SM.LOGIN.RESETPWD.EMAILPATTERN_ERROR":"Ungültige E-Mail-Adresse.",
  "SM.LOGIN.TITLE.NONLOCAL_USER_CHANEPWD_ERROR":"Ein nicht lokaler Benutzer kann das Kennwort nicht ändern.",
  "agentconsole.login.indexforipcc.onepoint":"Das Kundendienstpersonal kann die Plattform einchecken, eingehende Anrufe beantworten, Anrufe weiterleiten, stumm schalten, den Status wechseln oder Hilfe leisten.",
  "agentconsole.login.indexforipcc.loginportal":"Melden Sie sich beim Customer Service Cloud Portal an.",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CLOSE":"Schließen",
  "agentconsole.common.button.cancel":"Abbrechen",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CONTINUE":"Weiter",
  "SM.LOGIN.TITLE.COPYRIGHT":"Copyright {0} {1} Huawei Software Technologies Co., Ltd. Alle Rechte vorbehalten.",
  "agentconsole.menumanage.message.createfailures":"Menüerstellung fehlgeschlagen",
  "SM.INDEX.LABEL.CASEDEC":"Sie können einen Fall für jede Serviceanfrage Ihres Kunden erstellen und den gesamten Lebenszyklus der Bearbeitung der Kundenserviceanfrage durch den Fall verfolgen, um sicherzustellen, dass jede Kundenanfrage abgeschlossen wird, und die Kundenzufriedenheit zu verbessern.",
  "agentconsole.menumanage.message.sysadmincannotaddmenu":"Der aktuelle Systemadministrator kann kein Menü erstellen.",
  "SM.LOGIN.MESSAGE.UNLOCK_FAILED":"Das Konto konnte nicht entsperrt werden. Geben Sie das richtige Kennwort ein.",
  "agentconsole.authmanage.label.newauth":"Neue Berechtigung",
  "agentconsole.sessionmanagement.input.validatorMessage":"Sonderzeichen außer Unterstrichen (_), Punkten (.), und At-Zeichen ({'@'}) sind nicht zulässig.",
  "agentconsole.callmessage.calledNo":"Angerufen",
  "agentconsole.login.indexforipcc.difplat":"Einfach in verschiedene Plattformen zu integrieren, ohne die Hauptseiten des Portals und der Workbench zu belegen",
  "agentconsole.login.indexforipcc.auth":"Zugriffskontrolle",
  "agentconsole.index.label.case":"Fall",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES":"Das aktuelle Gebiet wurde in den Notstand versetzt. Das Geschäft kann erst abgewickelt werden, nachdem das System wiederhergestellt ist. Melden Sie sich beim Notfallsystem an, um andere Geschäfte abzuwickeln.",
  "agentconsole.header.title.send":"Interne Nachricht senden",
  "agentconsole.dashboard.priority.medium":"Prioritätsmedium",
  "agentconsole.ccnotification.not.currently.on.call":"Derzeit kein Anruf",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CANCEL":"Abbrechen",
  "agentconsole.authmanage.message.selectanauth":"Wählen Sie eine Berechtigung aus.",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CONFIRM":"OK",
  "agentconsole.workbench.label.remarks":"Anmerkungen",
  "SM.LOGIN.TITLE.INFO":"Aufforderung",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_CONTINUE":"Weiter auf das aktuelle System zugreifen",
  "agentconsole.sessionmanagement.table.fail.delete":"Löschen fehlgeschlagen",
  "SM.LOGIN.BUTTON.CANCEL":"Abbrechen",
  "SM.LOGIN.TITLE.CHANGEPWD_RULE":"Das neue Kennwort muss den folgenden Regeln entsprechen:",
  "SM.LOGIN.BUTTON.FORGETPWD_SMS":"SMS",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.FORCE_CHANGE_PASSWORD":"Ändern Sie das Kennwort nach der Anmeldung.",
  "SM.LOGIN.TITLE.CURRENT_SELECTED_PROJECT":"Ausgewählt:",
  "SM.LOGIN.LABEL.FRAMEWORK_AD_DEMAND":"Werbung auf Abruf",
  "SM.LOGIN.LABEL.NOTICE_GRADE_URGENT":"Ansturm",
  "SM.LOGIN.LABEL.CHANGEPWD_NEWPWD":"Neues Kennwort",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_BIND_INFO_NULL":"Das Anmeldekonto oder die Bindungsinformationen sind leer.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_FAILED":"Die SMS-Nachricht konnte nicht gesendet werden. Überprüfen Sie das Back-End-Protokoll.",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_CONFIRMPWD":"Das neue Kennwort muss mit dem bestätigten Kennwort übereinstimmen.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_DATE_INVALID":"Verifizierungscode konnte nicht verifiziert werden.",
  "SM.LOGIN.MESSAGE.REPEAT_SUBMITED":"Die Anfrage wurde gesendet. Bitte warten ...",
  "agentconsole.dashboard.title.unassigned":"Nicht zugewiesen",
  "SM.LOGIN.TIPS.SAVE":"Speichern",
  "SM.LOGIN.MESSAGE.NONLOCAL_USER_RETRIEVEPASSWORD_FAILED":"Das Kennwort konnte nicht abgerufen werden. Nicht-lokale Benutzer können die Funktion zum Abrufen von Kennwörtern nicht verwenden.",
  "SM.LOGIN.TITLE.PASSWORD_INIT":"Sie verwenden das ursprüngliche Kennwort. Ändern Sie das Kennwort sofort.",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY_SUCCESS":"Geändert erfolgreich.",
  "agentconsole.dashboard.title.interactionperday":"Anzahl der Kontakte pro Tag",
  "SM.PORTAL.LABEL.INPUT_CFM_PWD":"Bestätigen Sie das neue Kennwort.",
  "SM.LOGIN.TITLE.CHANGEPWD_SUCCESS":"Aufforderung",
  "SM.LOGIN.LABEL.COPYRIGHT":"Urheberrechtlich geschützt Huawei Technologies Co., Ltd. 2018.",
  "SM.LOGIN.TITLE.PASSWORD_WILLEXPIRED":"Ihr Passwort läuft bald ab. Bitte ändern Sie das Kennwort.",
  "agentconsole.dashboard.title.taskcreate":"Anzahl der erstellten Aufgaben",
  "agentconsole.dashboard.title.task":"Aufgabenübersicht",
  "SM.LOGIN.BUTTON.QUERY":"Abfrage",
  "agentconsole.login.restpwd.fail":"Das Zurücksetzen des Kennworts ist fehlgeschlagen.",
  "SM.LOGIN.LABEL.AUTH_FAILED":"Konto oder Passwort falsch.",
  "agentconsole.login.label.reset":"Zurücksetzen",
  "SM.LOGIN.TIPS.LASTLOGINTIME":"Letzte Anmeldung:",
  "SM.LOGIN.LABEL.SYSTEM_ERROR":"Systemfehler.",
  "agentconsole.dashboard.priority.high":"Priorität hoch",
  "SM.LOGIN.TITLE.NOTICE_LIST":"Bulletinliste",
  "agentconsole.login.label.username":"Benutzername",
  "agentconsole.index.label.chatdec":"Während eines Multimedia-Online-Chats mit Ihrem Kunden können Sie die gesamten historischen Kontaktinformationen des Kunden anzeigen, um Ihre Serviceeffizienz zu maximieren und Ihrem Kunden ein Omnichannel-Serviceerlebnis zu bieten.",
  "agentconsole.ccnotification.has.been.sent":"Wurde gesendet",
  "SM.LOGIN.TITLE.UPDATE_PWD_MODIFY_FAILED":"Änderung fehlgeschlagen",
  "SM.LOGIN.TITLE.BE_CATALOG":"BE-Katalog:",
  "agentconsole.index.label.casedraft":"Meine Fallentwürfe",
  "agentconsole.login.indexforipcc.opengateway":"Gateway öffnen",
  "agentconsole.login.label.forgot":"Passwort vergessen?",
  "agentconsole.sessionmanagement.table.success.delete":"Löschung erfolgreich",
  "agentconsole.callmessage.title":"Anrufnachricht",
  "SM.LOGIN.TITLE.SMS_RECONFIRM":"SMS-Erneute Bestätigung",
  "agentconsole.quickentrance.deleteclass":"Dieses Verzeichnis löschen",
  "agentconsole.login.indexforipcc.thirdparty":"Drei-Wege-Anruf",
  "agentconsole.sessionmanagement.table.deleteconfim":"Löschvorgang bestätigen",
  "agentconsole.login.indexforipcc.sequence":"Abfrage von Warteschlangeninformationen",
  "SM.LOGIN.TITLE.UPDATE_PWD_LOGINID":"Konto:",
  "SM.LOGIN.LABEL.FAST_ENTER":"Schneller Einstieg",
  "agentconsole.menumanage.field.url":"Menü-URL",
  "agentconsole.login.indexforipcc.statecontrol":"Verbindungszustandssteuerung",
  "agentconsole.common.expiry.suffix":"Bitte achten Sie darauf. Wenn Sie es weiterhin verwenden möchten, wenden Sie sich an das Betriebspersonal.",
  "agentconsole.login.indexforipcc.signin":"Kundendienstmitarbeiter, die Sprachanrufe bearbeiten müssen, melden sich bei der CTI-Plattform an.",
  "agentconsole.login.indexforipcc.answer":"Anruf beantworten",
  "agentconsole.authmanage.message.notdelnoleafauth":"Löschen der Berechtigung mit Sub-Berechtigungen nicht zulassen.",
  "agentconsole.quickentrance.managemententrance":"Eingangsverwaltung",
  "SM.LOGIN.TITLE.APPEND_MENU_SEARCH":"Suchkriterien:",
  "agentconsole.authmanage.label.authtype.operation":"Operationsberechtigung",
  "SM.LOGIN.TITLE.APPEND_MENU_TITLE":"Kontextmenü hinzufügen",
  "agentconsole.dashboard.title.todo":"Aufgabenliste",
  "SM.CHANGELANGAUGE.REFRESH.MESSAGE":"Die gesamte Seite wird neu geladen, nachdem Sie die Sprache gewechselt haben. Möchten Sie wirklich fortfahren?",
  "agentconsole.sessionmanagement.tip.error":"Fehler",
  "agentconsole.callmessage.callskill":"Skill-Warteschlange",
  "agentconsole.faqwordreminder.norecommend":"Keine empfohlenen FAQs verfügbar",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD":"Altes Kennwort:",
  "SM.LOGIN.MESSAGE.SELECT_PROJECT":"Wählen Sie ein Projekt aus.",
  "agentconsole.icontitle.create_sibling":"Peer-Ebene hinzufügen",
  "SM.LOGIN.LABEL.PASSWORD":"Kennwort",
  "agentconsole.callmessage.faq.reminder":"Empfohlene FAQs",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLIST":"Zurücksetzen von Mietbenutzerkennwörtern in Batches: [Mitarbeiterliste:",
  "SM.LOGIN.MESSAGE.CHANGEPWD_SUCCESS":"Das Kennwort wurde erfolgreich geändert.",
  "agentconsole.login.indexforipcc.opengatewayde":"Das Open Gateway bietet eine standardmäßige protokollbasierte RESTful-Schnittstelle für die Sprachanruffunktion der dritten systemintegrierten intelligenten Kundenservice-Cloud. Das Drittanbietersystem kann das Callcenter in seinem eigenen System implementieren, indem es den Service erwirbt, ohne seine eigene Callcenter-Ausstattung zu kaufen. Fähigkeit.",
  "SM.LOGIN.LABEL.SETTINGS":"Einstellungen",
  "SM.LOGIN.MESSAGE.MENU_ACCESS_TIME_NOT_MATCH":"Die aktuelle Zeit liegt nicht innerhalb der durch das Menü erlaubten Zugriffszeit. Die Zugriffszeit des Menüs ist wie folgt:",
  "agentconsole.menumanage.field.menu_name":"Menüname",
  "agentconsole.common.button.save":"Speichern",
  "agentconsole.login.restpwd.errorinfo":"Der Benutzername oder die E-Mail-Adresse ist falsch.",
  "SM.LOGIN.BUTTON.OK":"OK",
  "SM.LOGIN.TIPS.LOCK":"Bildschirm sperren",
  "SM.LOGIN.LABEL.NOTICE_GRADE_EXTRA_URGENT":"Dringend",
  "agentconsole.common.tips.information":"Tipps",
  "SM.INDEX.LABEL.TASKDEC":"Während der Fallbearbeitung können Sie Aufgaben entsenden, um mit mehreren Abteilungen und Mitarbeitern zu arbeiten. Für einen Fall können mehrere Aufgaben erstellt werden.",
  "agentconsole.dashboard.title.taskperday":"Status der täglichen Verarbeitung",
  "agentconsole.authmanage.message.notdelbaseauth":"Löschen der öffentlichen Berechtigung nicht zulassen.",
  "agentconsole.ccnotification.not.currently.on.three":"Der Prozess kann im aktuellen Anrufstatus nicht initiiert werden.",
  "agentconsole.custinfo.label.custId":"Kundennummer",
  "agentconsole.header.label.home":"Startseite",
  "agentconsole.menumanage.message.deletemenufail":"Das Menü konnte nicht gelöscht werden.",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORSTACK":"Fehlerstapel:",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_OPEN":"Einzelheiten<",
  "agentconsole.dashboard.enumeration.today":"Heute",
  "agentconsole.header.title.search":"Suche",
  "agentconsole.wordreminder.recommend":"Empfohlenes Skript",
  "agentconsole.dashboard.priority.low":"Priorität niedrig",
  "agentconsole.quickentrance.complete":"Abgeschlossen",
  "SM.LOGIN.TIPS.LOGINIP":"IP-Adresse:",
  "SM.LOGIN.LABEL.FINISH":"Fertig stellen",
  "SM.LOGIN.MESSAGE.GRAFIC.VERIFY.CODE.REFRESH":"»Unklar. Ändern Sie eine andere.",
  "SM.LOGIN.LABEL.RESET":"Zurücksetzen",
  "agentconsole.authmanage.message.deleteconfirm":"Möchten Sie die Berechtigung wirklich löschen?",
  "SM.LOGIN.LABEL.LOGOUT":"Abmelden",
  "agentconsole.contacthistory.oneMinAgo":"Vor Minuten",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD_EMPTY":"Das Bestätigungskennwort ist leer.",
  "agentconsole.contacthistory.hourAgo":"Vor Stunden",
  "agentconsole.login.indexforipcc.useage":"Die intelligente Kundenservice-Cloud bietet ein Sitz-Framework, das Mehrmandantenfähigkeiten unterstützt und die Eröffnung von Agenten ermöglicht, und die Kombination der CTI- und UAP-Plattformen von Huawei, um Kunden vollständige Sprach-/Videoservice-Funktionen in der Cloud zur Verfügung zu stellen.",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_ERROR":"Das alte Kennwort ist falsch.",
  "agentconsole.dashboard.title.caseunassigned":"Nicht zugewiesen",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_CONTEXTDATA":"BE wechseln",
  "SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS":"Möchten Sie die Organisation wirklich deaktivieren?",
  "agentconsole.ccnotification.max.recipients":"Nicht mehr als 5 Empfänger",
  "SM.LOGIN.LABEL.NOTICE_STATUS":"Status",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CANCEL":"Abbrechen",
  "SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER":"Manager zuweisen",
  "agentconsole.login.label.errorinfo":"Der Benutzername oder das Kennwort ist falsch.",
  "agentconsole.ccnotification.recipient.cannot.empty":"Empfänger darf nicht leer sein",
  "agentconsole.dashboard.title.mobile":"Mobiles Endgerät",
  "SM.LOGIN.LABEL.LOCKED":"Gesperrt",
  "agentconsole.authmanage.message.sysauthsubnode":"Es kann keine Sendeberechtigung zur Systemverwaltung hinzugefügt werden.",
  "SM.LOGIN.TITLE.SWITCH_ESOP":"Umschaltung auf ESOP mit einem Klick",
  "agentconsole.quickentrance.all":"Alle",
  "agentconsole.workbench.label.userinfo":"Benutzerinformationen",
  "agentconsole.dashboard.enumeration.thressdays":"Letzte 3 Tage",
  "agentconsole.modifycustominfo.nodatamodify":"Es werden keine Daten geändert.",
  "agentconsole.login.label.service_cloud_desc":"Benutzeroberfläche für Ultimate Service löschen",
  "SM.LOGIN.LABEL.CANCEL":"Abbrechen",
  "SM.AUC.MESSAGE.PWDRULE_YES":"Ja",
  "SM.LOGIN.TITLE.NOTICE_CONTENT":"Inhalt",
  "agentconsole.callmessage.setNumerLengthError":"Die Bearbeitungsnummer darf nicht länger als 24 Zeichen sein.",
  "agentconsole.contacthistory.dayAgo":"Vor Tagen",
  "agentconsole.login.indexforipcc.agentstate":"Agentenstatuskontrolle, z. B. Agent beschäftigt, Freizeit, Ruhezeit usw.",
  "SM.LOGIN.TIPS.LOGOUT":"Aus dem System abgemeldet.",
  "SM.LOGIN.LABEL.OPER_NAME":"Benutzername:",
  "SM.LOGIN.MESSAGE.CHANGEPWD_OLDNEW":"Das neue Kennwort muss sich vom alten Kennwort unterscheiden.",
  "SM.LOGIN.LABEL.UPDATE_PWD":"Kennwort ändern",
  "agentconsole.quickentrance.title.toomanymenus":"Die Anzahl der Kontextmenüs wurde überschritten. Bitte reduzieren Sie die Anzahl der Kontextmenüs und speichern Sie die Einstellungen erneut.",
  "SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM":"Nachdem ein Mitarbeiter gelöscht wurde, kann das entsprechende Konto des Mitarbeiters nicht wiederhergestellt, sondern nur neu erstellt werden. löschen oder nicht?",
  "agentconsole.header.title.case":"Fall erstellen",
  "SM.LOGIN.LABEL.NOTICE_GRADE":"Priorität",
  "SM.ORGSTAFF.TITLE.OPERATION":"Betrieb",
  "SM.LOGIN.MESSAGE.SENDMAIL_SUCCESS":"Das neue Passwort wurde an Ihre E-Mail-Adresse gesendet. Bitte verwenden Sie das neue Passwort, um sich anzumelden.",
  "SM.LOGIN.TIPS.LOGINFAILED":"Anmeldung fehlgeschlagen.",
  "agentconsole.menumanage.message.deleteconfirm":"Möchten Sie das Menü wirklich löschen?",
  "agentconsole.dashboard.enumeration.thirtydays":"Letzte 30 Tage",
  "agentconsole.login.indexforipcc.easyuse":"Einfache Bedienung",
  "agentconsole.dashboard.title.total":"Gesamt",
  "SM.LOGIN.TIPS.WELCOME_LABEL":"Gruß:",
  "SM.LOGIN.LABEL.EMERGENCY_NOTICE":"Notfallhinweis",
  "agentconsole.login.indexforipcc.deal":"Das Kundendienstpersonal kann den Kundenanruf wie folgt bearbeiten",
  "agentconsole.login.label.cancel":"Abbrechen",
  "agentconsole.menu.config.center":"Konfigurationscenter",
  "SM.LOGIN.MESSAGE.SENDSMS_SUCCESS":"Das neue Passwort wurde an Ihr Mobiltelefon gesendet. Bitte verwenden Sie das neue Passwort, um sich anzumelden.",
  "agentconsole.login.restpwd.emailpattern_error":"Ungültige E-Mail-Adresse.",
  "message.center.message.messageManage":"Interne Nachrichtenverwaltung",
  "agentconsole.index.label.dashboard":"Dashboard",
  "agentconsole.menumanage.field.auth":"Menüberechtigung",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_SUFFIX":"um das Geschäft weiter zu führen.",
  "agentconsole.login.indexforipcc.release":"Freigabe",
  "agentconsole.dashboard.title.chat":"Übersicht über den Online-Chat",
  "agentconsole.index.label.querytask":"Aufgabe abfragen",
  "agentconsole.sessionmanagement.tip.success":"Erfolg",
  "SM.LOGIN.TITLE.NOTICE_CRUM":"Systemverwaltung > Systemwartung > Bulletin-Abfrage",
  "agentconsole.header.title.help":"Hilfe",
  "agentconsole.header.title.send.email.notification":"Benachrichtigung über ausgehende Mails",
  "agentconsole.login.indexforipcc.calldata":"Anrufdatenabfrage",
  "agentconsole.dashboard.enumeration.yesterday":"Gestern",
  "SM.LOGIN.LABEL.FRAMEWORK_CALENDAR":"Kalender",
  "agentconsole.contacthistory.nocontactinfo":"Keine Interaktionshistorie",
  "SM.LOGIN.BUTTON.LOGIN":"Anmelden",
  "SM.LOGIN.LABEL.FORGOTPASSWORD":"Passwort vergessen?",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_SUFFIX":"Menü zur Abwicklung des Geschäfts.",
  "SM.LOGIN.MESSAGE.CONFIRM":"Bestätigen",
  "SM.LOGIN.TITLE.COMMONTOOLS":"Gemeinsame Werkzeuge",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.NO_LOGIN":"Sie haben sich nicht angemeldet. Bitte melden Sie sich an und versuchen Sie es erneut.",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_Y":"Ja",
  "agentconsole.dashboard.title.wechat":"WeChat",
  "agentconsole.login.label.verifycode.empty":"Der Verifizierungscode ist leer.",
  "SM.LOGIN.LABEL.VCODE_EMPTY":"Der Verifizierungscode ist leer.",
  "SM.LOGIN.TITLE.DROPLIST_MENU":"Menü",
  "agentconsole.contact.customerInfo":"Kundeninformationen",
  "agentconsole.history.lastloginfail":"Letzte fehlgeschlagene Anmeldung",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_N":"Nein",
  "agentconsole.history.logintime":"Datum",
  "agentconsole.menumanage.paneltitle.menumanage":"Menüverwaltung",
  "SM.LOGIN.RESETPWD.ERRORINFO":"Das Anmeldekonto oder die E-Mail-Adresse ist falsch.",
  "agentconsole.dashboard.title.evaluation":"Kundenzufriedenheit",
  "agentconsole.must.be.a.number":"muss eine Zahl sein",
  "WEB.VALIDATOR.RANGE": "Geben Sie einen Wert zwischen {0} und {1} ein.",
  "agentconsole.voicetransfer.user": "Kunde",
  "agentconsole.voicetransfer.agent": "Agent",
  "agentconsole.voicetransfer.chatbot": "ChatBot",
  "agentconsole.title.transcription": "Transkription",
  "agentconsole.title.agent.overview": "Agentenübersicht",
  "agentconsole.agent.assistant.recomend.history": "Empfehlungshistorie",
  "agentconsole.agent.assistant.faq.history": "FAQ-Verlauf",
  "agentconsole.agent.assistant.auto.create.case": "Fall automatisch erstellen",
  "agentconsole.agent.assistant.recomend.action": "Empfohlene Maßnahmen",
  "agentconsole.agent.assistant.summary": "Zusammenfassung generieren",
  "agentconsole.agent.assistant.case": "Fall generieren",
  "agentconsole.agent.refine.simple": "Einfach",
  "agentconsole.agent.refine.formal": "Formell",
  "agentconsole.agent.refine.friendly": "Freundlich",
  "agentconsole.agent.refine.humorous": "Humorvoll",
  "agentconsole.agent.refine": "Verfeinern",
  "agentconsole.agent.please.notice": "Bitte achten Sie auf die Wörter",
  "agentconsole.agent.knowledge.recommend": "Wissensempfehlung",
  "agentconsole.agent.knowledge.map": "Karte",
  "agentconsole.agent.knowledge.product.recommend": "Empfohlene Produkte",
  "agentconsole.agent.knowledge.product.popular": "Beliebte Produkte",
  "agentconsole.agent.knowledge.product.link": "Angebot entdecken",
  "agentconsole.agent.complaint.risk": "Beschwerderisiko",
  "agentconsole.agent.call.context": "Anrufkontext",
  "agentconsole.agent.customer.info": "Kundeninformationen",
  "agentconsole.agent.session.monitor": "Sitzungsüberwachung",
  "agentconsole.agent.basic.info": "Grundlegende Informationen",
  "agentconsole.agent.customer.name": "Kundenname",
  "agentconsole.agent.phone.number": "Mobiltelefonnummer",
  "agentconsole.agent.service.duration": "Servicedauer",
  "agentconsole.agent.customer.brand": "Marke",
  "agentconsole.agent.customer.status": "Benutzerstatus",
  "agentconsole.agent.customer.address": "Heimatbereich",
  "agentconsole.agent.bussiness.info": "Serviceinformationen",
  "agentconsole.agent.order.status": "Abonnementstatus",
  "agentconsole.agent.business.usage": "Dienstnutzung",
  "agentconsole.agent.terminal.profile": "Terminalinformationen",
  "agentconsole.agent.terminal.vendor": "Terminalanbieter",
  "agentconsole.agent.terminal.model": "Endgerätemodell",
  "agentconsole.agent.network.standard": "Netzwerkstandard",
  "agentconsole.agent.generate.summary": "Zusammenfassung generieren",
  "agentconsole.agent.summary": "Zusammenfassung",
  "agentconsole.agent.submit.success": "Senden erfolgreich",
  "agentconsole.agent.intelligent.fill": "Intelligente TT-Befüllung",
  "agentconsole.agent.intelligent.click": "Ausfüllen von Tickets mit einem Klick"
}