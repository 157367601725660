export default {
  // #now
  // #Tue Feb 07 11:45:04 CST 2023
  "provision.callcent.platformserver.ip": "Adresse IP du service",
  "provision.subscribedetail.success.save": "Sauvegard\u00E9 avec succ\u00E8s.",
  "provision.encryptinfo.historical.version": "Vers. Pr\u00E9c.",
  "provision.subscribedetail.label.createTime": "Appliqu\u00E9 \u00E0",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway": "Le code d'acc\u00E8s n'a pas \u00E9t\u00E9 supprim\u00E9 sur la plate-forme was",
  "provision.callcent.success.updateinfo": "Mis \u00E0 jour avec succ\u00E8s.",
  "provision.callcenterinstanceslist.vcallcenter.nameDiffListPromt": "Il existe un centre d'appel virtuel avec VccName qui n'est pas compatible avec VdnName dans WAS. Le VccId est",
  "provision.nodepolicy.sync.success": "Synchronisation r\u00E9ussie.",
  "provision.systemparam.url.safe.tip": "Des protocoles non s\u00E9curis\u00E9s sont utilis\u00E9s\, causant des risques de s\u00E9curit\u00E9",
  "provision.spinfo.addspinfosuccess": "Informations SP ajout\u00E9es avec succ\u00E8s.",
  "provision.tenant.chooseAC.first": "S\u00E9lectionnez un code d'acc\u00E8s.",
  "promotion.tenant.management.tenantedit.provisionerror": "Impossible de mettre \u00E0 jour l'espace locataire.",
  "provision.callcenterinstance.softPhone.length.Inconsistent": "Les longueurs du num\u00E9ro de d\u00E9but et du num\u00E9ro de fin sont incoh\u00E9rentes.",
  "provision.softPhone.label.uapaddress.node2": "Adresse IP UAP du n\u0153ud 2",
  "provision.callcenterinstanceslist.label.ttschannelUseRate": "Utilisation du TTS",
  "provision.tenantspace.table.chooseStatus": "Statut de l'espace locataire",
  "provision.softPhone.label.uapaddress.node1": "Adresse IP UAP du n\u0153ud 1",
  "provision.tenantspace.save.success": "Sauvegard\u00E9 avec succ\u00E8s.",
  "provision.callcenterinstanceslist.ccdis.ccdisUpdate": "\u00C9dition",
  "provision.tenantspace.popuppageurl.whiteListStyle": "Type d'adresse",
  "provision.callcenterinstanceslist.config.wnTo": "ID de l'employ\u00E9 final",
  "provision.tenantspace.gw.searchName": "Veuillez saisir le nom du serveur Gateway \u00E0 interroger.",
  "provision.softPhone.table.forcemodifyconfim": "Confirmer",
  "provision.callcenterinstanceslist.ccgateway.maxRegAgentNum": "Quantit\u00E9 d'agent simultan\u00E9",
  "provision.callcent.error.updateaccesscode.fail.ondatabase": "Impossible de modifier le code d'acc\u00E8s dans la base de donn\u00E9es",
  "provision.appsitesmanagement.sureDeleteData": "La suppression de ce site peut entra\u00EEner l'impossibilit\u00E9 pour les utilisateurs de se connecter \u00E0 l'application mobile. \u00CAtes-vous s\u00FBr de vouloir le supprimer ?",
  "provision.spinfo.issuerId": "ID de l'\u00E9metteur",
  "provision.appversionmanagement.pleaseSelect": "-S\u00E9lectionner-",
  "provision.tenant.management.pwd.checkmsg": "Les deux mots de passe sont diff\u00E9rents.",
  "provision.tenantspace.config.assignedworknonumber": "Identifiants d'agent allou\u00E9s",
  "provision.subscribedetail.error.delete": "La suppression a \u00E9chou\u00E9.",
  "provision.callcenter.ivr.alert": "La valeur doit \u00EAtre la m\u00EAme que la capacit\u00E9 simultan\u00E9e du RVI d\u00E9ploy\u00E9.",
  "provision.callcenterinstance.accesscode.validatebeginaccesscode": "Le num\u00E9ro de d\u00E9but du code d'acc\u00E8s ne peut pas \u00EAtre sup\u00E9rieur au num\u00E9ro de fin.",
  "provision.tenantspace.obsServer.datasource.id": "ID de la source de donn\u00E9es",
  "provision.tenantspace.management": "Gestion de TenantSpace",
  "provision.label.tenantexport.addExportTaskError": "Impossible d'ajouter la t\u00E2che d'exportation. Veuillez v\u00E9rifier le journal.",
  "provision.tenantspace.feature.agentSendLocalMediaFile.description": "Cette fonction permet d'envoyer des fichiers multim\u00E9dias locaux par des agents pour l'espace locataire.",
  "provision.callcent.error.deletesipserver.part.associationvcallcener": "Certains serveurs SIP s\u00E9lectionn\u00E9s sont associ\u00E9s \u00E0 des locataires et ne peuvent pas \u00EAtre supprim\u00E9s.",
  "ccprovision.recorddrive.config.sftp.identitychecktips": "Le compte ou le mot de passe du serveur SFTP est modifi\u00E9. Pour assurer la s\u00E9curit\u00E9 des informations\, entrez votre compte actuel et votre mot de passe.",
  "provision.recorddrive.error.firstletter.repeated": "La premi\u00E8re lettre de lecteur existe d\u00E9j\u00E0",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callPeriod.label": "P\u00E9riode d'appel (1s \u00E0 60s)",
  "provision.common.message.success": "Succ\u00E8s",
  "provision.callcenterinstanceslist.config.adminWnTo": "ID de fin d'employ\u00E9 de l'administrateur",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callNums.label": "Nombre de donn\u00E9es d'appel (1 \u00E0 10)",
  "provision.tenantspace.feature.outboundCallMarketingSupport.description": "Une fois la fonction d'appel sortant intelligent activ\u00E9e\, l'espace locataire prend en charge la fonction d'appel sortant intelligent.",
  "provision.systemparam.error.reset": "\u00C9chec de la r\u00E9initialisation des param\u00E8tres",
  "provision.callcenterinstanceslist.table.obssyncconfim": "Confirmer la synchronisation",
  "provision.tenantspace.feature.forceVideoSelfRecord.forcibleSelfRecord": "Auto-enregistrement forc\u00E9 de l'\u00E9cran VDN",
  "provision.tenantspace.table.stopTenantSpace.confirm": "Confirmer la suspension",
  "provision.tenantspace.config.isCreateAccount": "Cr\u00E9er automatiquement un compte pour le centre d'appels virtuel",
  "provision.sysparam.config.reset": "R\u00E9initialiser",
  "provision.callcent.platformserver.es": "Recherche de services (ElasticSearch)",
  "provision.callcent.platformserver.cobrowse": "Service de co-navigation (Co-navigation)",
  "provision.callcenterinstanceslist.nodepolicy.save": "Enregistrer",
  "provision.encryptinfo.title.encrypted.information": "Informations de chiffrement",
  "provision.callcenterinstanceslist.softPhone.newpassword": "Nouveau mot de passe du softphone",
  "provision.callcenterinstanceslist.softPhone.modifyPassword": "Changer le mot de passe",
  "provision.appsitesmanagement.batchDelete": "Suppression par lot",
  "provision.appversionmanagement.versionNumber": "Num\u00E9ro de version",
  "provision.label.tenantexport.exportiong": "Exportation",
  "provision.tenantspace.feature.knowledgeBaseSupport.description": "Une fois la fonction de base de connaissances activ\u00E9e\, l'espace locataire prend en charge la fonction de base de connaissances.",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithAms": "Le nombre maximum d'appels vocaux simultan\u00E9s ne peut \u00EAtre inf\u00E9rieur \u00E0 la somme des agents voix\, vid\u00E9o\, multim\u00E9dia et polyvalents\, et des canaux audio et vid\u00E9o IVR.",
  "provision.callcenterinstanceslist.label.maxcallnums": "Max. Appels vocaux simultan\u00E9s",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsStrValidateFailed": "Seuls les lettres et les chiffres sont autoris\u00E9s.",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber.placeholder": "1\u201350000",
  "provision.tenant.management.adminaccount": "Compte Administrateur",
  "provision.obs.success.sync": "Synchronisation r\u00E9ussie.",
  "provision.tenantspace.feature.moveAgentNum": "Nombre maximum d'agents",
  "provision.tenant.need.first": "S\u00E9lectionnez le centre d'appels\, le code d'acc\u00E8s et le nombre d'agents.",
  "provision.tenantspace.save.fail.078add": "Impossible de cr\u00E9er l'espace locataire lors de la configuration du syst\u00E8me d'authentification tiers.",
  "provision.appsitesmanagement.protocol.check": "Notez que le protocole r\u00E9seau utilis\u00E9 par l'adresse de site saisie n'est pas un protocole s\u00E9curis\u00E9. Si vous \u00EAtes s\u00FBr de l'utiliser\, des risques de s\u00E9curit\u00E9 peuvent exister.",
  "provision.tenantspace.config.tenant.webrtc.featurevalue.not.one": "La valeur de la fonction WebRTC attribu\u00E9e au locataire n'est pas 1.",
  "ccprovision.recorddrive.config.sftp.serverconfig": "Configuration du serveur SFTP",
  "ccprovision.vcallecenter.config.popup.tab.configurationinterface.modifypassword": "Modifier le mot de passe de configuration",
  "provision.systemparam.error.validate.notmatchrulealpha": "Veuillez entrer des caract\u00E8res alphanum\u00E9riques ou des traits de soulignement\, et ne commencer que par des lettres ou des traits de soulignement",
  "provision.tenantspace.feature.anonymous": "Appel sortant anonyme",
  "provision.spinfo.id": "ID",
  "provision.tenantspace.feature.VoiceNotificationSupport": "Notification vocale",
  "provision.tenantspace.config.success": "Espace locataire configur\u00E9 avec succ\u00E8s",
  "provision.tenantspace.cc.videoAgentUsage": "Utilisation de l'agent vid\u00E9o",
  "provision.callcenterinstanceslist.config.ccNameUpdateError": "Ne changez pas le nom du centre d'appels lors de l'\u00E9dition du centre d'appels",
  "ccprovision.IVRSprogress.config.button.async": "Synchroniser",
  "provision.callcenterinstanceslist.table.synchronize": "Synchroniser",
  "provision.callcent.error.createaccesscode.fail.ondatabase": "\u00C9chec de la cr\u00E9ation d'un code d'acc\u00E8s dans la base de donn\u00E9es",
  "provision.callcent.error.updateSoftNumPasswordInfo": "Erreur d'information de compte",
  "provision.callcenterinstance.accesscode.batchcreate.fail.repeated": "Tous les codes d'acc\u00E8s du nouveau segment de num\u00E9ro existent d\u00E9j\u00E0.",
  "provision.callcent.error.deleteccgateway.part.associationvcallcener": "Certains serveurs CC-Gateway s\u00E9lectionn\u00E9s sont associ\u00E9s \u00E0 des locataires et ne peuvent pas \u00EAtre supprim\u00E9s.",
  "provision.tenantspace.config.chooseDefaultPhoneNumber": "S\u00E9lectionnez le num\u00E9ro d'appel par d\u00E9faut",
  "provision.subscribedetail.label.createSource": "Source",
  "provision.tenantspace.timezone.config.error": "Impossible de modifier les informations de fuseau horaire.",
  "provision.callcenterinstanceslist.label.videoAgentUseRate": "Utilisation de l'agent vid\u00E9o",
  "provision.tenantspace.save.fail.104-011": "La suppression du fichier d'enregistrement a \u00E9chou\u00E9.",
  "provision.tenantspace.save.fail.104-010": "Le param\u00E8tre id du noeud d'enregistrement n'est pas configur\u00E9.",
  "provision.tenantspace.config.ttschannelnums": "Quantit\u00E9 TTS",
  "provision.callcenterinstanceslist.table.viewing": "visualisation",
  "provision.tenantspace.selectStastus.noncommercial": "Utilisation commerciale d'essai",
  "provision.callcenterccaccesscodeslist.table.singledeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer le code d'acc\u00E8s s\u00E9lectionn\u00E9 ? Assurez-vous que le code d'acc\u00E8s n'est pas associ\u00E9 \u00E0 un centre d'appels virtuel.",
  "provision.systemparam.error.validate.notmatchrulemin": "Le param\u00E8tre d'entr\u00E9e est inf\u00E9rieur au minimum",
  "provision.softphone.label.authtype.uap": "Authentification UAP",
  "provision.callcent.error.updatesoftphonenumber.noexist": "Le num\u00E9ro de t\u00E9l\u00E9phone logiciel n'existe pas.",
  "provision.tenantspace.detail.tenantspace.cannot.open.uploadfeature": "Activer ou non le vidage de ressources et la fonction de requ\u00EAte en ligne des ressources de vidage en m\u00EAme temps",
  "provision.tenantspace.sip.sipServerIP": "IP du serveur SIP",
  "provision.tenantspace.config.videocall.more.videoagent": "Le nombre maximal d'appels vid\u00E9o simultan\u00E9s est sup\u00E9rieur ou \u00E9gal au nombre d'agents vid\u00E9o.",
  "provision.subscribedetail.error.title": "Erreur",
  "provision.tenantspace.save.fail.104-009": "L'enregistrement de la conversion a \u00E9chou\u00E9.",
  "provision.nodepolicy.save.success": "Sauvegard\u00E9 avec succ\u00E8s.",
  "provision.callcenterinstanceslist.vcallcenter.OBSServer": "Serveur d'appel sortant OBS",
  "provision.tenantspace.save.fail.104-008": "Les param\u00E8tres du syst\u00E8me de format d'enregistrement ne sont pas configur\u00E9s.",
  "provision.tenantspace.save.fail.104-006": "Espace libre insuffisant pour l'\u00E9chec de la suppression d'enregistrement.",
  "provision.callcent.error.saveccgateway.nameinfo": "Cr\u00E9er un \u00E9chec\, veuillez confirmer si le nom CC-Gateway existe d\u00E9j\u00E0.",
  "provision.tenantspace.save.fail.104-007": "Le format de fichier d'enregistrement n'est pas pris en charge.",
  "provision.tenantspace.save.fail.104-005": "Le fichier d'enregistrement qui n'a pas r\u00E9ussi \u00E0 supprimer l'enregistrement n'existe pas.",
  "provision.tenantspace.save.fail.104-004": "La suppression de l'enregistrement a \u00E9chou\u00E9 sans correspondance de lettre de lecteur.",
  "provision.tenantspace.save.fail.104-003": "Le nom de fichier de l'\u00E9chec de la suppression de l'enregistrement n'est pas valide.",
  "provision.callcent.error.delete": "\u00C9chec de la suppression",
  "provision.tenantspace.save.fail.104-002": "L'utilisateur n'a pas d'autorisation d'enregistrement d'inspection de qualit\u00E9 / de lecture d'inspection de qualit\u00E9.",
  "provision.tenantspace.save.fail.104-001": "La fonction de t\u00E9l\u00E9chargement d'enregistrement n'est pas activ\u00E9e.",
  "provision.callcenterinstanceslist.softPhone.password": "Mot de passe",
  "provision.callcent.error.edit": "\u00C9chec de l'\u00E9dition.",
  "provision.authconfig.description.toolong.error": "description ne peut plus bronzer 200.",
  "provision.callcenterinstanceslist.vcallcenter.vccName": "Nom du VDN",
  "provision.tenant.management.tenantname.beginwithalpha": "doit commencer par une lettre.",
  "provision.callcenterinstanceslist.sipServer.sipServerIpAdd": "Ajouter",
  "provision.callcent.error.save": "Erreur",
  "provision.tenantspace.save.fail.120222": "Le n\u0153ud CTI r\u00E9f\u00E9renc\u00E9 par l'IVR n'existe pas.",
  "provision.tenantspace.save.fail.120221": "Le groupe de param\u00E8tres r\u00E9f\u00E9renc\u00E9 par IVR n'existe pas.",
  "provision.tenantspace.save.fail.120220": "IVR est r\u00E9f\u00E9renc\u00E9 par le groupe VDN et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.callcenterinstance.softPhone.batchcreate.fail.repeated": "Tous les num\u00E9ros du nouveau segment de num\u00E9ros existent d\u00E9j\u00E0.",
  "provision.callcenterinstanceslist.softPhone.batchdeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer tous les num\u00E9ros de softphone s\u00E9lectionn\u00E9s? Assurez-vous que le num\u00E9ro de t\u00E9l\u00E9phone logiciel n'est pas associ\u00E9 \u00E0 un centre d'appels virtuel.",
  "provision.label.tenantexport.operation": "Op\u00E9ration",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.availableWorkNo": "Aucun ID d'agent disponible dans le segment ID d'agent.",
  "provision.tenantspace.cc.maxVideoCallnums": "Max. Appels vid\u00E9o simultan\u00E9s",
  "provision.tenant.management.adminname": "Administrateur",
  "provision.tenantspace.cc.searchName": "Entrez le nom du centre d'appels \u00E0 interroger.",
  "provision.tenantspace.save.fail.120219": "IVR est r\u00E9f\u00E9renc\u00E9 par VDN et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.callcent.error.savesipserverdesinfo.regAgentNum": "Le nombre d'agents enregistr\u00E9s simultan\u00E9ment est diff\u00E9rent du nombre de si\u00E8ges enregistr\u00E9s restants.",
  "provision.tenantspace.save.fail.120217": "Le nombre maximum de RVI pour la m\u00EAme adresse IP est de 20.",
  "provision.tenantspace.config.param.error.videoUsage": "Veuillez saisir le nombre d'agents vid\u00E9o.",
  "provision.callcent.error.regaddrformat": "Le format de l'adresse d'enregistrement est incorrect.",
  "provision.tenantspace.save.fail.120216": "Ne peut pas \u00EAtre modifi\u00E9 entre IVR et CCIVR.",
  "provision.tenantspace.videoagentchannnels.limit": "Le nombre de canaux de l'agent vid\u00E9o doit \u00EAtre sup\u00E9rieur ou \u00E9gal au double du nombre d'agents vid\u00E9o.",
  "provision.callcenterinstanceslist.obsServer.obsServerIpUpdate": "Modifier",
  "ccprovision.IVRSprogress.config.button.edit": "Modifier",
  "provision.callcenterinstanceslist.vcallcenter.ServerNumber": "Num\u00E9ro de service",
  "provision.callcent.error.accesscode.deletefail.all.associationvcallcener": "Le code d'acc\u00E8s est associ\u00E9 au locataire et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.callcenterinstanceslist.table.nodeid": "ID de n\u0153ud",
  "provision.tenantspace.config.ivrchannels": "Quantit\u00E9 de canal IVR",
  "pprovision.tenantspace.feature.emailAgentCCAndBcc.description": "Activez la copie de l'agent de messagerie et la fonction Cci. L'espace lou\u00E9 prend en charge la copie de l'agent de messagerie et Bcc.",
  "provision.tenantspace.common.agent.explain": "Agent commun \: r\u00E9pond aux appels entrants\, contr\u00F4le les appels vocaux et vid\u00E9o\, et envoie et re\u00E7oit des messages multim\u00E9dias.",
  "provision.tenantspace.dataUpload.openResourceDump": "Fournit la fonction de vidange de ressources pour l'espace locataire. Remarque\: Actuellement\, seul HUAWEI CLOUD OBS est pris en charge. Pour utiliser la fonctionnalit\u00E9 de vidange de ressources\, les locataires doivent acheter ce service.",
  "provision.tenantspace.config.chooseAnonymousCallServerTitle": "S\u00E9lectionnez serveur d'appel anonyme.",
  "provision.tenantspace.save.fail.120205": "Le code d'acc\u00E8s au syst\u00E8me est r\u00E9f\u00E9renc\u00E9 par le groupe VDN et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.save.fail.120204": "Le code d'acc\u00E8s au syst\u00E8me est r\u00E9f\u00E9renc\u00E9 par VDN et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.selectStastus.stop": "Suspendu",
  "provision.tenantspace.save.fail.120203": "Le code d'acc\u00E8s au syst\u00E8me et le code d'acc\u00E8s \u00E0 l'appel r\u00E9seau NIRC sont dupliqu\u00E9s.",
  "provision.recorddrive.selectrecorddrive.batchdeleteconfim": "\u00CAtes-vous s\u00FBr de supprimer toutes les lettres de lecteur s\u00E9lectionn\u00E9es? Veuillez vous assurer que la lettre de lecteur n'est pas occup\u00E9e par le locataire",
  "provision.tenantspace.table.success": "Succ\u00E8s",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer": "Serveur multim\u00E9dia CCUCS",
  "provision.tenantspace.save.fail.120202": "Le code d'acc\u00E8s au syst\u00E8me chevauche le code d'acc\u00E8s sp\u00E9cial au routage.",
  "provision.systemparam.error.validate.notmatchrulerange": "Le param\u00E8tre d'entr\u00E9e d\u00E9passe la valeur maximale ou est inf\u00E9rieur \u00E0 la valeur minimale",
  "provision.tenantspace.table.stopTenantSpace": "\u00CAtes-vous s\u00FBr de vouloir suspendre le locataire ?",
  "provision.tenantspace.sip.maxRegAgentNum": "Quantit\u00E9 d'agent enregistr\u00E9 simultan\u00E9",
  "provision.tenantspace.save.fail.120201": "Le code d'acc\u00E8s au syst\u00E8me est dupliqu\u00E9.",
  "provision.tenantspace.config.chooseCcdis": "Veuillez s\u00E9lectionner un serveur CCDIS.",
  "provision.callcenterinstanceslist.label.videoAgentCurCapacity": "Quantit\u00E9 d'agent vid\u00E9o",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum.title": "Configuration du t\u00E9l\u00E9phone logiciel",
  "provision.tenant.management.tenantexport.exceedlimit2": "Affinez la plage de temps des donn\u00E9es ou ajoutez des conditions de filtrage des donn\u00E9es.",
  "provision.subscribedetail.label.emailStatus": "Envoi d'e-mail",
  "provision.tenant.management.tenantexport.exceedlimit1": "Un maximum de {0} enregistrements de donn\u00E9es peut \u00EAtre export\u00E9.",
  "provision.softphone.label.authtype.unified": "Authentification unifi\u00E9e",
  "provision.tenant.management.DSTEndDate": "Date de fin DST",
  "provision.callcent.error.updateinfo.maxchnsmall": "Le nombre maximum d'appels simultan\u00E9s ne peut pas \u00EAtre inf\u00E9rieur au nombre de canaux occup\u00E9s.",
  "provision.sysparam.config.mod": "Modifier",
  "provision.spinfo.logoutUrl": "URL de d\u00E9connexion",
  "provision.tenantspace.feature.appCubeSupport": "AppCube",
  "provision.tenantspace.save.fail.078delete": "Impossible de supprimer l'espace locataire lors de la configuration du syst\u00E8me d'authentification tiers.",
  "provision.whiteListAddress.PopMenu": "\u00C9cran pop-up",
  "provision.tenantspace.feature.webrtc": "Une fois la fonction WebRTC activ\u00E9e\, l'espace locataire prend en charge la fonction WebRTC.",
  "provision.ivr.error.sync": "\u00C9chec de la synchronisation",
  "provision.encryptinfo.encryptKey.length.error": "Longueur de cl\u00E9 requise \:",
  "provision.tenantspace.common.case.explain": "Lorsque le bo\u00EEtier est ouvert\, le locataire ouvrira la capacit\u00E9 de service en nuage de services.",
  "provision.tenantspace.common.newcase.explain": "La fonction Case 2.0 est activ\u00E9e pour permettre aux locataires de g\u00E9rer et de configurer les ordres de travail.",
  "provision.tenantspace.table.fail": "\u00C9chec",
  "provision.tenantspace.rechoose": "Randomiser",
  "provision.tenantspace.ivr.searchName": "Veuillez entrer l'ID de l'IVR \u00E0 interroger.",
  "provision.tenantspace.save.fail.120541": "Capacit\u00E9 de traitement simultan\u00E9 de l'agent \: 1-60.",
  "provision.tenantspace.save.fail.120540": "La valeur de la licence multim\u00E9dia est hors de port\u00E9e.",
  "provision.label.tenantexport.success": "Succ\u00E8s",
  "provision.tenantspace.ConversionNoncommercial": "Essai de conversion Utilisation commerciale",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageFactor": "Coefficient de pourcentage d'enregistrement d'\u00E9cran (%)",
  "provision.tenantspace.cc.ttschannelUsagePercent": "Utilisation du TTS",
  "provision.tenantspace.config.assignedworkno": "ID de travail allou\u00E9",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway.with.associationvcallcener": "Toutes les suppressions ont \u00E9chou\u00E9 parce qu'une partie du code d'acc\u00E8s est associ\u00E9e au locataire\, et certains codes d'acc\u00E8s sont supprim\u00E9s sur la plate-forme was.",
  "provision.tenant.management.tenantname": "Espace locataire",
  "provision.tenantspace.table.maxcallnumswithdesc": "Nombre max. d'appels en m\u00EAme temps (>= somme des agents et canaux RVI)",
  "provision.ccp.redis.feature.failed": "Impossible d'ex\u00E9cuter les donn\u00E9es des fonctionnalit\u00E9s du locataire.",
  "provision.tenantspace.cc.current": "Capacit\u00E9 actuelle",
  "provision.tenantspace.save.fail.120539": "Type de licence multim\u00E9dia non valide.",
  "provision.spinfo.serviceUrl": "URL de service",
  "provision.tenantspace.save.fail.120538": "Le nombre d'ID d'agents transf\u00E9rables est insuffisant.",
  "provision.tenantspace.save.fail.120537": "Les VDN disponibles ne peuvent pas contenir le VDN transf\u00E9r\u00E9.",
  "provision.tenantspace.save.fail.120536": "La longueur du VDN disponible ne peut pas \u00EAtre 0.",
  "provision.tenantspace.option.weeklist_fthtolast": "Dernier avant trois",
  "provision.tenantspace.save.fail.120535": "La plage d'ID de l'agent est en cours d'utilisation et ne peut pas \u00EAtre annul\u00E9e.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.save": "Enregistrer",
  "provision.tenantspace.save.fail.120534": "Le groupe de ressources CCS r\u00E9f\u00E9renc\u00E9 par le VDN n'existe pas.",
  "provision.tenantspace.config.worknoassign.filternumber": "Exclure les num\u00E9ros",
  "provision.tenantspace.save.fail.120532": "Le code d'acc\u00E8s original a \u00E9t\u00E9 r\u00E9f\u00E9renc\u00E9 par un num\u00E9ro appel\u00E9.",
  "provision.tenantspace.save.fail.120531": "Le code d'acc\u00E8s contenu dans les informations VDN de base n'est pas attribu\u00E9 au VDN.",
  "provision.tenantspace.save.fail.120533": "Dupliquer la plage d'ID de l'agent VDN.",
  "provision.tenantspace.save.fail.120530": "Le lecteur r\u00E9f\u00E9renc\u00E9 par le VDN n'existe pas.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.defaultCallerNum": "Num\u00E9ro d'appel par d\u00E9faut",
  "provision.tenant.management.contacts": "Nom du contact",
  "provision.tenant.config.maxAgents": "Max. Agents vocaux",
  "provision.callcenterinstanceslist.sipServer.sipanonymousCall": "Appel anonyme SIP",
  "provision.tenantspace.config.fail.savedata.tenant.feature.inDatabase": "La base de donn\u00E9es n'a pas r\u00E9ussi \u00E0 enregistrer les informations sur les fonctionnalit\u00E9s du locataire",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithoutAms": "Le nombre maximum d'appels vocaux simultan\u00E9s ne peut \u00EAtre inf\u00E9rieur \u00E0 la somme des agents vocaux\, vid\u00E9o et polyvalents\, et des canaux audio et vid\u00E9o IVR.",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayIp": "CC-Gateway IP",
  "provision.whiteListAddress.cardPop": "Adresse de redirection de message de la carte de page",
  "provision.callcenterinstanceslist.sipServer.description": "Descriptif",
  "provision.label.tenantexport.exportBeginTime": "Exporter l'heure de d\u00E9but",
  "provision.sysparam.config.select.count": "Un maximum de 10 param\u00E8tres peut \u00EAtre s\u00E9lectionn\u00E9.",
  "provision.tenantspace.save.fail.120529": "L'ID de l'administrateur VDN doit se trouver dans la plage d'ID de l'agent configur\u00E9.",
  "provision.tenantspace.save.fail.120528": "Lorsque le transfert de raccrochage de l'agent est activ\u00E9\, la tonalit\u00E9 d'invite est jou\u00E9e apr\u00E8s que l'appel est transf\u00E9r\u00E9 de nouveau \u00E0 l'agent. Le param\u00E8tre prompt tone doit \u00EAtre d\u00E9fini.",
  "provision.tenantspace.save.fail.120527": "Le VDN est r\u00E9f\u00E9renc\u00E9 par le client CCP local et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.save.fail.120526": "La source de donn\u00E9es IVR par d\u00E9faut n'est pas contenue dans les sources de donn\u00E9es VDN ou les sources de donn\u00E9es tierces qui sont attribu\u00E9es au VDN.",
  "provision.tenantspace.save.fail.120524": "Le code d'acc\u00E8s syst\u00E8me attribu\u00E9 au VDN a \u00E9t\u00E9 attribu\u00E9 \u00E0 un groupe VDN.",
  "provision.systemparam.error.validate.notmatchruleemail": "Veuillez entrer une adresse email valide",
  "provision.tenantspace.save.fail.120525": "Les sources de donn\u00E9es IVR ne sont pas contenues dans les sources de donn\u00E9es VDN ou les sources de donn\u00E9es tierces qui sont attribu\u00E9es au VDN.",
  "provision.tenantspace.save.fail.120523": "Le code d'acc\u00E8s syst\u00E8me attribu\u00E9 au VDN a \u00E9t\u00E9 attribu\u00E9 \u00E0 un autre VDN.",
  "provision.tenantspace.save.fail.120522": "Le VDN ne prend pas en charge le type d'appel web\, mais des codes d'acc\u00E8s syst\u00E8me du type d'appel web sont attribu\u00E9s au VDN.",
  "provision.tenantspace.save.fail.120521": "Le VDN ne prend pas en charge les types d'appels par courrier \u00E9lectronique et par t\u00E9l\u00E9copie\, mais des codes d'acc\u00E8s syst\u00E8me du type d'appel par courrier \u00E9lectronique ou par t\u00E9l\u00E9copie sont attribu\u00E9s au VDN.",
  "provision.callcenterinstanceslist.nodepolicy.VNDId": "ID VDN",
  "provision.tenantspace.save.fail.120520": "Le code d'acc\u00E8s syst\u00E8me r\u00E9f\u00E9renc\u00E9 par le VDN n'existe pas.",
  "provision.subscribedetail.error.save": "L'enregistrement a \u00E9chou\u00E9.",
  "provision.tenantspace.config.ivrserver.select.confirm": "confirmer la s\u00E9lection",
  "provision.tenantspace.detail.create.time": "Temps de cr\u00E9ation",
  "provision.systemparam.error.validate.notmatchruleminlength": "Le param\u00E8tre d'entr\u00E9e est inf\u00E9rieur \u00E0 la longueur minimale",
  "provision.tenantspace.statusRecordTime.6Month": "6 Mois",
  "provision.callcenter.gateway.agentnum.alert": "La valeur doit \u00EAtre la m\u00EAme que la capacit\u00E9 de configuration r\u00E9elle du cluster CC-Gateway.",
  "provision.tenantspace.ivr.ivrprogid": "ID de processus",
  "provision.sysparam.config.search": "Rechercher",
  "provision.tenantspace.agentinfo.message.singledeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer l&apos;agent s\u00E9lectionn\u00E9 ?",
  "provision.label.tenantexport.viewExportTask": "Affichage des t\u00E2ches d'exportation",
  "provision.systemparam.error.validate.notmatchrulemax": "Le param\u00E8tre d'entr\u00E9e d\u00E9passe le maximum",
  "provision.tenantspace.whiteNumber": "Configuration des num\u00E9ros dans la liste de confiance",
  "provision.callcent.error.saveccaccesscodeinfo": "Assurez-vous que le code d'acc\u00E8s n'existe pas.",
  "provision.tenantspace.save.fail.120519": "Le n\u0153ud distribu\u00E9 utilis\u00E9 pour l'auto-enregistrement vocal VDN forc\u00E9 n'est pas configur\u00E9 pour le VDN.",
  "provision.tenantspace.save.fail.120517": "Si aucun syst\u00E8me d'enregistrement vocal n'est sp\u00E9cifi\u00E9 dans la configuration d'informations RMS de base\, seul l'enregistrement vocal HUAWEI ACD peut \u00EAtre configur\u00E9 pour le VDN actuel.",
  "provision.tenant.management.accesscode": "Code d'acc\u00E8s",
  "provision.tenantspace.save.fail.120516": "L'IVR r\u00E9f\u00E9renc\u00E9 par le VDN n'existe pas.",
  "provision.tenantspace.save.fail.120514": "La ressource distribu\u00E9e r\u00E9f\u00E9renc\u00E9e par le VDN n'existe pas.",
  "provision.callcent.error.ipValidateFailed": "l'adresse IP est valid\u00E9e.",
  "provision.tenantspace.save.fail.120515": "La source de donn\u00E9es r\u00E9f\u00E9renc\u00E9e par le VDN n'existe pas.",
  "provision.tenantspace.save.fail.120513": "L'OBS r\u00E9f\u00E9renc\u00E9 par le VDN n'existe pas.",
  "provision.tenantspace.save.fail.120512": "Dupliquer la plage d'ID de l'agent VDN.",
  "provision.tenantspace.save.fail.120511": "Le nom VDN est en double avec le nom du groupe VDN.",
  "provision.appsitesmanagement.siteName": "Nom du site",
  "provision.tenantspace.feature.forceVideoSelfRecord": "Enregistrement d'\u00E9cran",
  "provision.tenantspace.save.fail.120510": "Le nom VDN est dupliqu\u00E9 avec un autre nom VDN.",
  "provision.tenantspace.config.chooseGatewayServer": "veuillez s\u00E9lectionner un serveur de passerelle",
  "provision.tenantspace.config.agentnum.limit": "Un maximum de {0} agents peuvent \u00EAtre souscrits \u00E0 la fois.",
  "provision.tenant.management.tenantcreate.failed": "Impossible de cr\u00E9er l'espace locataire. Assurez-vous que le nom du locataire ou le compte administrateur est unique.",
  "provision.callcenterinstanceslist.ucsServer.ucsServerPort": "Port serveur CCUCS",
  "provision.tenantspace.basic.information": "Informations de base sur le locataire",
  "provision.callcenterinstanceslist.ccdis.ccdisDeleteConfirm": "Voulez-vous vraiment supprimer le serveur CCDIS s\u00E9lectionn\u00E9 ?",
  "provision.callcenterinstanceslist.softPhone.used.pleaseselect": "S\u00E9lectionnez l'utilisation",
  "provision.tenantspace.save.fail.120509": "La restriction est activ\u00E9e. Par d\u00E9faut\, le num\u00E9ro appelant commence par le pr\u00E9fixe.",
  "provision.tenantspace.save.fail.120508": "La restriction est activ\u00E9e. Le pr\u00E9fixe du num\u00E9ro appelant ne peut pas \u00EAtre vide.",
  "provision.callcenterinstanceslist.vcallcenter.maxAmsCalls": "Appels anonymes simultan\u00E9s",
  "provision.tenantspace.save.fail.120506": "Le nombre maximum d'agents vid\u00E9o autoris\u00E9s \u00E0 se connecter simultan\u00E9ment au syst\u00E8me d\u00E9passe la limite sup\u00E9rieure.",
  "provision.tenantspace.save.fail.120507": "Le num\u00E9ro appelant ne peut contenir aucune relation d'inclusion.",
  "provision.tenantspace.save.fail.120505": "Le nombre de parties \u00E0 la conf\u00E9rence d\u00E9passe la limite sup\u00E9rieure.",
  "provision.tenantspace.save.fail.120504": "Le nombre maximal d'appels sortants simultan\u00E9s d\u00E9passe la limite sup\u00E9rieure.",
  "provision.spinfo.creator": "Cr\u00E9ateur",
  "provision.spinfo.issuerId.search": "Entrez un ID d'\u00E9metteur.",
  "provision.tenantspace.save.fail.120503": "Le nombre maximum d'appels web simultan\u00E9s d\u00E9passe la limite sup\u00E9rieure.",
  "provision.tenantspace.save.fail.120501": "Le nombre maximal d'agents autoris\u00E9s \u00E0 se connecter simultan\u00E9ment au syst\u00E8me d\u00E9passe la limite sup\u00E9rieure.",
  "provision.callcenterinstanceslist.config.odfsvalidate": "Toutes les configurations de service ODFS doivent \u00EAtre vides ou non vides.",
  "provision.tenantspace.save.fail.120502": "Le nombre maximal d'appels vocaux simultan\u00E9s d\u00E9passe la limite sup\u00E9rieure.",
  "provision.callcent.error.updatesoftphonenumber.ondatabase": "Impossible de modifier le num\u00E9ro de t\u00E9l\u00E9phone logiciel dans la base de donn\u00E9es.",
  "provision.tenantspace.save.successwithsamevccid": "L'\u00E9pargne a r\u00E9ussi. Un espace locataire dont l'ID VCC est le m\u00EAme que l'espace locataire actuel existe. Contactez l'administrateur.",
  "provision.tenantspace.config.configVirtualCallCenter": "Configurer le centre d'appel virtuel",
  "provision.tenantspace.agentinfo.message.media": "Multim\u00E9dia",
  "provision.subscribedetail.pageTitle": "D\u00E9tails de l'abonnement en un clic",
  "provision.accesscode.table.call": "Appels entrants et sortants",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceName": "Nom du locataire",
  "provision.tenantspace.config.call.more.agent.ivr": "Le nombre maximal d'appels vocaux simultan\u00E9s doit \u00EAtre sup\u00E9rieur ou \u00E9gal \u00E0 la somme du nombre d'agents vocaux et du nombre de canaux de RVI audio et vid\u00E9o.",
  "provision.accesscode.table.whetherSupportTrialUse_Y": "Oui",
  "ccprovision.IVRSprogress.config.number": "Non.",
  "provision.label.tenantexport.refresh": "Actualiser",
  "ccprovision.recorddrive.config.storageLimit": "Limite de stockage la plus basse (Mo)",
  "provision.callcent.error.accesscode.deletefail.accesscodenoexist": "La suppression a \u00E9chou\u00E9\, le code d'acc\u00E8s n'existe pas",
  "provision.tenantspace.config.amsNumber.unequal": "Le nombre d'appels anonymes simultan\u00E9s doit \u00EAtre le coh\u00E9rent.",
  "provision.authconfig.duplicate.description.error": "duplicate.description.error.",
  "provision.callcenterinstanceslist.whitelist.Title": "Adresse de rappel d'identification intelligente",
  "provision.label.tenantexport.addExportTaskSuccess": "La t\u00E2che d'exportation est ajout\u00E9e avec succ\u00E8s.",
  "promotion.tenant.management.tenantedit.registerserver.notavailable": "Le serveur d'enregistrement n'est pas disponible.",
  "provision.accesscode.table.whetherSupportTrialUse_N": "Non",
  "provision.tenantspace.config.chooseDrive": "Lecteur d'enregistrement vocal",
  "provision.tenantspace.config.chooseworkNo.tip": "Veuillez entrer le segment de travail de bon augureno en fonction du travail inactifPas d'information",
  "provision.tenantspace.create.tenantName": "Nom du locataire",
  "provision.tenantspace.cc.currentpercent": "Proportion de capacit\u00E9 actuelle",
  "provision.tenantspace.chooseauthenticationsystem": "Configurer un syst\u00E8me d'authentification tiers",
  "provision.callcenterinstanceslist.config.confirm": "Configurez les informations sur les n\u0153uds li\u00E9s \u00E0 la plate-forme WAS.",
  "provision.tenantspace.vitualCenterName": "Centre d'appel virtuel",
  "provision.tenantspace.list.search.date.error": "Date d'expiration du locataire non valide. Veuillez entrer une plage de dates correcte.",
  "provision.tenantspace.config.chooseOBSServer": "S\u00E9lectionnez un serveur OBS.",
  "provision.accesscode.message.invalid": "Caract\u00E8res non valides.",
  "provision.callcenterinstanceslist.config.nextStep": "Suivant",
  "provision.tenantspace.save.fail.101": "Erreur de synchronisation.",
  "provision.whiteListAddress.downlink.message.apifabric.push": "Adresse de tissu de l'API en aval des messages multim\u00E9dias",
  "provision.tenantspace.feature.cobrowse": "Co-navigation",
  "provision.appsitesmanagement.queryFailed": "Erreur de recherche.",
  "provision.encryptinfo.paddingScheme": "Mode de rembourrage",
  "provision.callcenterinstanceslist.whiteAddr.regAddrExample": "exemple\:https\://ip\:port/service-cloud/rest/cc-ai/v1/asrevent/asrresult",
  "provision.ivr.success.sync": "Synchronisation r\u00E9ussie",
  "provision.callcenterinstanceslist.config.uapId": "Cette valeur doit \u00EAtre la m\u00EAme que l'identifiant UAP sur le WAS.",
  "provision.label.tenantexport.popwindowmessage": "Entrez le mot de passe pour g\u00E9n\u00E9rer des fichiers compress\u00E9s. Le mot de passe doit contenir des lettres\, des chiffres et des caract\u00E8res sp\u00E9ciaux _ {'@'}%.",
  "provision.callcenterinstanceslist.table.uapId": "ID UAP",
  "provision.label.information": "Astuces",
  "ccprovision.IVRSprogress.config.button.cacel": "Annuler",
  "provision.subscribedetail.label.contactName": "Nom du contact",
  "ccprovision.vcallecenter.error.updateinfo.currentacountpasderror": "Le mot de passe du compte courant est incorrect.",
  "provision.tenantspace.config.agent.ivr.more.zero": "Le nombre d'agents vocaux\, d'agents vid\u00E9o\, d'agents multim\u00E9dias\, d'agents polyvalents\, de canaux audio IVR et de canaux vid\u00E9o IVR ne peut pas \u00EAtre \u00E9gal \u00E0 0.",
  "provision.tenant.management.batchcreatenumber": "Quantit\u00E9",
  "provision.callcenterinstanceslist.config.nmsservicePort": "Num\u00E9ro de port ODFS",
  "provision.callcenterinstanceslist.table.nodemodepool": "Mode pool",
  "provision.tenantspace.moveAgentUsage.iszero": "Nombre insuffisant de canaux audio IVR.",
  "provision.callcenterinstanceslist.config.previousStep": "Pr\u00E9c\u00E9dent",
  "provision.tenantspace.table.activeTenantSpace": "\u00CAtes-vous s\u00FBr de vouloir activer le locataire?",
  "provision.tenantspace.config.softphoneassign.softphonescope.extensiverange": "La plage de valeurs entre le nombre de d\u00E9but et le nombre de fin ne peut pas \u00EAtre sup\u00E9rieure \u00E0 1000.",
  "provision.accesscode.table.accessCode": "Code d'acc\u00E8s",
  "provision.appversionmanagement.regVersion": "Le num\u00E9ro de version se compose de lettres\, de chiffres et de points. Il ne peut pas commencer ou se terminer par un point.",
  "provision.callcent.success.deletesoftphonenumber.processed": "Suppression des donn\u00E9es de l'arri\u00E8re-plan... Veuillez v\u00E9rifier plus tard.",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber": "Nombre maximum de connexions de co-navigation",
  "provision.tenantspace.gw.maxRegAgentNum": "Quantit\u00E9 d'agent enregistr\u00E9 simultan\u00E9",
  "provision.authconfig.updateMessage": "\u00CAtes-vous s\u00FBr de vouloir mettre \u00E0 jour l'App SK?",
  "provision.callcent.error.accesscode.deletefail.part.associationvcallcener": "Certains codes d'acc\u00E8s sont associ\u00E9s aux locataires et ne peuvent pas \u00EAtre supprim\u00E9s.",
  "provision.whiteListAddress.TenantUnExist": "Le locataire n'existe pas.",
  "ccprovision.recorddrive.config.sftp.filestoragepathStrValidateFailed": "Le chemin de stockage du fichier ne peut pas contenir './'",
  "provision.callcent.error.deletesoftphonenumber.part.associationvcallcener": "Certains des num\u00E9ros de t\u00E9l\u00E9phone logiciel s\u00E9lectionn\u00E9s sont associ\u00E9s au locataire et ne peuvent pas \u00EAtre supprim\u00E9s.",
  "provision.tenantspace.timezone.save.success": "Les informations de fuseau horaire sont enregistr\u00E9es avec succ\u00E8s.",
  "provision.tenantspace.config.worknoassign.filternumber.explain2": "La banque de num\u00E9ros apr\u00E8s filtrage ne contiendra pas les num\u00E9ros des num\u00E9ros ci-dessus",
  "provision.tenantspace.config.chooseUCSServer": "S\u00E9lectionnez un serveur CCUCS.",
  "provision.callcenterinstanceslist.config.createsuccess": "Le centre d'appels est cr\u00E9\u00E9 avec succ\u00E8s.",
  "provision.tenantspace.config.worknoassign.filternumber.explain1": `Lorsque vous entrez plusieurs num\u00E9ros\, veuillez utiliser "\," pour s\u00E9parer. Entrez jusqu'\u00E0 5 nombres\, par exemple\, "4\,11\,13"`,
  "provision.callcenterinstanceslist.whitepageurl.httptip": "(Pas s\u00FBr)",
  "provision.callcenterinstanceslist.table.updateFault": "\u00CAtes-vous s\u00FBr de d\u00E9finir la voix de d\u00E9faut du centre cellulaire virtuel",
  "provision.tenantspace.tenantSpaceCount": "Nombre total de locataires",
  "provision.tenantspace.table.maxCalls": "Max. Appels vocaux simultan\u00E9s",
  "provision.tenant.management.tenantexport.confirm": "Remerciements",
  "promotion.tenant.management.tenantedit.vdnsync.failed": "Impossible de synchroniser les donn\u00E9es avec le centre d'appels virtuel.",
  "provision.callcenterinstanceslist.config.ttschannelnumsValidate": "Le nombre de canaux TTS est inf\u00E9rieur au nombre de canaux r\u00E9ellement attribu\u00E9s dans le centre d'appels.",
  "provision.tenantspace.prompt": "Informations",
  "provision.callcenterinstanceslist.sipServer.uapPort": "Port UAP",
  "provision.systemparam.error.notmatchrule": "Le param\u00E8tre ne r\u00E9pond pas \u00E0 la r\u00E8gle de contr\u00F4le",
  "provision.callcenterccaccesscodeslist.table.batchdeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer tous les codes d'acc\u00E8s s\u00E9lectionn\u00E9s ? Assurez-vous que le code d'acc\u00E8s n'est pas associ\u00E9 \u00E0 un centre d'appels virtuel.",
  "provision.tenantspace.cc.max": "Capacit\u00E9 max",
  "provision.callcenterinstanceslist.ccgatewayServer.singledeleteconfim": "Voulez -vous vraiment supprimer le serveur CC-Gateway s\u00E9lectionn\u00E9 ?",
  "provision.tenantspace.config.softphone.noavailable": "Aucun num\u00E9ro de softphone disponible.",
  "provision.tenantspace.deleteWhiteNumber": "\u00CAtes-vous s\u00FBr de vouloir supprimer le num\u00E9ro de la liste de confiance ?",
  "provision.tenantspace.select.start": "Commenc\u00E9",
  "provision.tenantspace.config.selectedsoftphone": "S\u00E9lectionn\u00E9s{0} Nombres{1}",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.placeholder": "D\u00E9finissez ce param\u00E8tre en fonction du nombre de sessions IVR concurrentes\, du nombre de sessions r\u00E9seau central concurrentes et du nombre de sessions de ligne concurrentes configur\u00E9es par le locataire.",
  "provision.tenantspace.config.error.softphone.noagentadd": "Aucun agent vocal\, vid\u00E9o\, multim\u00E9dia\, polyvalent ou mobile n'est ajout\u00E9.",
  "provision.tenantspace.common.ivr": "IVR conventionnel",
  "provision.callcent.label.openFaule": "Activer l'invite d'erreur",
  "provision.callcenterinstance.softPhone.validateSoftPhoneNumrange": "La gamme de num\u00E9ros nouvellement ajout\u00E9s ne peut pas d\u00E9passer 1000",
  "provision.label.tenantexport.zippwd": "Mot de passe compress\u00E9",
  "provision.callcenterinstanceslist.table.videoAgentChannels": "Quantit\u00E9 de canal d'agent vid\u00E9o",
  "provision.tenantspace.popuppageurl.deleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer la liste de confiance des adresses ?",
  "provision.callcenterinstanceslist.obsServer.obsId": "ID OBS",
  "provision.tenantspace.table.videoAgentChannels": "Quantit\u00E9 de canal de l'agent vid\u00E9o (> \= Deux fois la quantit\u00E9 de l'agent vid\u00E9o)",
  "provision.whiteListAddress.outboundcall.obs.fileserver": "Serveur de fichiers OBS d'appel sortant",
  "provision.callcenterwhitelist.regAddr": "Adresse de rappel",
  "provision.callcenterinstanceslist.table.nodename": "Nom du n\u0153ud",
  "provision.tenantspace.smartAgentUsage.iszero": "Configurez d'abord l'agent.",
  "provision.callcent.label.finish": "Compl\u00E8te",
  "ccprovision.recorddrive.config.sftp.port": "Port No.",
  "provision.tenant.management.partdbId": "ID de partition de base de donn\u00E9es",
  "provision.tenantspace.config.softphone.using": "le num\u00E9ro de softphone {0} est en cours d'utilisation.",
  "provision.accesscode.table.mediatypeId": "Type de m\u00E9dia",
  "provision.ccworknos.table.ccworkStartNo": "Commencer l'ID de l'employ\u00E9",
  "provision.callcenterinstanceslist.vcallcenter.ctiInfo": "ID du groupe de ressources CTI",
  "provision.tenantspace.feature.OneClickTwoCallSupport.description": "Cette fonctionnalit\u00E9 fournit des fonctions d'appel bidirectionnel en un clic pour l'espace locataire.",
  "provision.tenant.management.tenantcreate.timezoneInfo": "Informations sur le fuseau horaire",
  "provision.ccworknos.table.ccworkEndNo": "ID de l'employ\u00E9 final",
  "provision.whiteListAddress.CustomerMenu": "Personnalisation des menus",
  "provision.authconfig.authdescription": "Entrez la description de l'ID du d\u00E9veloppeur.",
  "provision.encryptinfo.strengthdecrease": "Diminuer la force diminue la s\u00E9curit\u00E9 de l'algorithme. Faites preuve de prudence lorsque vous s\u00E9lectionnez cette option.",
  "ccprovision.vcallecenter.config.popup.tab.administrator.modifypassword": "Modifier le mot de passe de l'administrateur",
  "provision.tenantspace.cc.videoCallnumUsagePercent": "Utilisation d'appels vid\u00E9o simultan\u00E9s",
  "provision.whiteListAddress.resourcedump.css": "Serveur CSS de vidage de ressources",
  "provision.tenantspace.cc.videoIvrchannels": "Quantit\u00E9 vid\u00E9o de canal d'IVR",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum": "Num\u00E9ro de t\u00E9l\u00E9phone logiciel",
  "provision.callcenterinstanceslist.vcallcenter.faultEnable": "Voix de d\u00E9faut",
  "provision.system.error": "Le syst\u00E8me est anormal. Veuillez r\u00E9essayer plus tard.",
  "ccprovision.IVRSprogress.config.operate": "Op\u00E9ration",
  "provision.callcenterinstanceslist.vcallcentersftp.passLengthValidateFailed": "Le mot de passe doit contenir de 8 \u00E0 20 caract\u00E8res",
  "provision.appversionmanagement.batchDelete": "Suppression par lot",
  "provision.callcenterinstanceslist.softPhone.passLengthValidateFailed": "Le mot de passe doit contenir de 8 \u00E0 16 caract\u00E8res.",
  "provision.tenantspace.passwordValidatefaild": "Impossible de v\u00E9rifier le mot de passe.",
  "provision.encryptinfo.update": "Updater",
  "provision.systemparam.success.save": "Param\u00E8tre enregistr\u00E9 avec succ\u00E8s",
  "provision.tenant.management.button.ok": "Compl\u00E8te",
  "provision.callcenterinstanceslist.vcallcenter.ccdis": "Serveur CCDIS",
  "provision.tenantspace.config.choose.agent.registration.server.type": "Type de serveur d'enregistrement de l'agent",
  "provision.tenantspace.config.error.chooseworkNo.noagent": "Pas de ressources d'agent s\u00E9lectionn\u00E9es\, pas besoin d'attribuer un num\u00E9ro de travail",
  "provision.callcent.error.updateerrorinfo": "Le code d'acc\u00E8s a \u00E9t\u00E9 associ\u00E9 \u00E0 un centre d'appels virtuel et ne peut pas \u00EAtre modifi\u00E9.",
  "provision.tenantspace.config.agentusage.vidoeusage.ivr": "Quantit\u00E9 d'agent vocal\, quantit\u00E9 d'agent vid\u00E9o\, quantit\u00E9 d'agent multim\u00E9dia\, quantit\u00E9 de canal audio IVR et quantit\u00E9 de canal vid\u00E9o IVR ne peut pas tous \u00EAtre z\u00E9ro",
  "provision.tenantspace.error.deleteProvision": "Impossible de supprimer l'espace locataire Provision.",
  "provision.accesscode.table.accessCode.selecttype": "S\u00E9lectionner un type",
  "provision.callcenterinstance.softPhone.validatebeginSoftPhoneNum": "Le num\u00E9ro de d\u00E9marrage du t\u00E9l\u00E9phone logiciel ne peut pas \u00EAtre sup\u00E9rieur au num\u00E9ro de fin",
  "provision.callcenterinstanceslist.ccdis.remark": "Description d'utilisation",
  "provision.callcent.error.deleteccgateway.associationvcallcener": "Le serveur CC-Gateway s\u00E9lectionn\u00E9 est associ\u00E9 au locataire et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.callcenterinstanceslist.vcallcenter.open": "ouvert",
  "provision.callcenterinstance.accesscode.validateaccesscode": "Saisissez un segment de nombre correct.",
  "provision.tenantspace.error.deleteDDS": "Impossible de supprimer l'espace locataire DDS.",
  "provision.callcenterinstanceslist.config.nodeIdCheckError": "Assurez-vous que l'ID\, l'uapId et le nom des informations de n\u0153ud CTI entr\u00E9s sont uniques.",
  "provision.encryptinfo.metadata": "Chiffrement et d\u00E9chiffrement des m\u00E9tadonn\u00E9es",
  "provision.tenantspace.config.softphoneassign.chooseworknoscope.paramserror": "L'information du num\u00E9ro est incorrecte.",
  "provision.callcenterinstanceslist.table.gwIp": "CC-Gateway IP",
  "provision.callcent.error.paramUnique": "Param unique a exist\u00E9.",
  "provision.callcenterinstanceslist.table.agentMaxCapacity": "Capacit\u00E9 du syst\u00E8me d'agent",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.addmaxlimit": "Cinq groupes de nombres ont \u00E9t\u00E9 ajout\u00E9s.",
  "provision.tenantspace.option.weekday_tue": "Mardi",
  "provision.appversionmanagement.downloadAddress": "T\u00E9l\u00E9charger l'adresse",
  "provision.tenant.management.DSTStartDate": "Date de d\u00E9but de l'heure d'\u00E9t\u00E9",
  "provision.callcenterinstanceslist.table.resourceinfo": "Infos sur les ressources",
  "provision.callcenterinstanceslist.softPhone.authtype": "Mode d'authentification",
  "provision.tenantspace.select.status": "S\u00E9lectionnez un \u00E9tat.",
  "provision.callcent.error.regaddrlength": "L'adresse d'inscription est trop longue.",
  "provision.appsitesmanagement.appcubeAvailable": "AppCube Disponible",
  "provision.callcent.selectsoftnumber": "S\u00E9lectionnez un num\u00E9ro de softphone.",
  "provision.callcenterinstanceslist.table.asrchannelnums": "Quantit\u00E9 ASR",
  "provision.tenantspace.config.select.record": "Veuillez s\u00E9lectionner un lecteur d'enregistrement.",
  "provision.tenantspace.agentinfo.deletefail.modifyvdn.onwas": "La connexion \u00E0 la plate-forme was n'a pas pu modifier le vdn",
  "provision.appsitesmanagement.creator": "Cr\u00E9ateur",
  "provision.tenantspace.config.maxCalls": "Quantit\u00E9 d'appels vocaux simultan\u00E9s",
  "provision.callcent.error.partdeleteinfo": "Le contenu associ\u00E9 \u00E0 un centre d'appels virtuel ne peut pas \u00EAtre supprim\u00E9.",
  "provision.callcenterinstanceslist.vcallcenter.videoAgentChannels": "Nombre de canaux d'agent vid\u00E9o",
  "provision.accesscode.table.acId": "Num\u00E9ro de code d'acc\u00E8s",
  "provision.tenantspace.config.chooseDriveTime": "P\u00E9riode de conservation de l'enregistrement",
  "provision.tenantspace.option.month_7": "Juillet",
  "provision.tenantspace.option.month_9": "Septembre",
  "provision.tenantspace.option.month_6": "Juin",
  "provision.tenantspace.option.month_4": "Avril",
  "provision.tenantspace.option.month_8": "Ao\u00FBt",
  "provision.tenantspace.option.month_5": "mai",
  "provision.tenantspace.option.month_3": "Mars",
  "provision.tenantspace.config.worknoassign.showchoosedworkno.explain": "Note\: Vous avez besoin d'un total de {0} nombres\, et {1} nombres ont \u00E9t\u00E9 s\u00E9lectionn\u00E9s. Le syst\u00E8me vous assignera des num\u00E9ros dans l'ordre.",
  "provision.tenantspace.option.month_2": "F\u00E9vrier",
  "provision.callcenterinstanceslist.softPhone.usedselect.mobileagent": "Num\u00E9ro du gestionnaire de client\u00E8le",
  "provision.tenantspace.refresh": "Actualiser",
  "provision.tenantspace.option.month_1": "Janvier",
  "provision.tenant.management.tenantexport.viewTask": "Interrogation des t\u00E2ches d'exportation",
  "provision.callcenterinstanceslist.softPhone.batchadd": "Ajout par lot",
  "provision.tenantspace.feature.intelligent.inspection": "Inspection intelligente",
  "provision.tenantspace.feature.OneClickTwoCallSupport": "Appel bidirectionnel en un clic",
  "provision.callcenterinstanceslist.whiteNumber": "Num\u00E9ros dans la liste de confiance",
  "provision.callcent.selectobsserver": "Veuillez s\u00E9lectionner un serveur Obs.",
  "provision.tenantspace.option.weeklist_sectolast": "Avant-dernier",
  "provision.tenantspace.gw.name": "Nom du serveur de la passerelle",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.workNocontain": "L'ID de travail a \u00E9t\u00E9 s\u00E9lectionn\u00E9. Veuillez en saisir un autre.",
  "provision.callcenter.sip.alert": "La valeur doit \u00EAtre la m\u00EAme que la capacit\u00E9 r\u00E9elle support\u00E9e par l'UAP et le SBC.",
  "provision.callcent.label.cancel": "Annuler",
  "provision.tenantspace.cc.ivrchannels": "Quantit\u00E9 audio de canal d'IVR",
  "provision.spinfo.beginlettervalidate": "S'il vous pla\u00EEt\, commencez par une lettre.",
  "provision.tenantspace.selectStastus.initialize": "Initialisation",
  "provision.callcenterinstanceslist.softPhone.usedselect.fixedagent": "Num\u00E9ro de l'agent",
  "provision.softphone.label.issyncuap.yes": "Oui",
  "provision.callcenterinstanceslist.ccdis.tenantSpaceName": "Nom du locataire",
  "provision.tenantspace.config.select.default.phone.in.selectAccesscodes": "Le num\u00E9ro appelant par d\u00E9faut doit se trouver dans le code d'acc\u00E8s s\u00E9lectionn\u00E9.",
  "provision.tenantspace.agentusage.plus.videousage": "AgentUsage et VideoUsage doivent \u00EAtre inf\u00E9rieurs \u00E0 10000",
  "provision.authconfig.updateConfim": "configuration de mise \u00E0 jour",
  "provision.systemparam.success.refresh": "Param\u00E8tre actualis\u00E9 avec succ\u00E8s",
  "provision.tenantspace.detail.tenantspace.cannot.close.uploadfeature": "Veuillez d'abord d\u00E9sactiver la fonction d'examen en ligne de la ressource de vidange",
  "provision.callcenterinstanceslist.table.search": "Requ\u00EAte",
  "provision.callcent.error.deletesoftphonenumber.associationvcallcener": "Le num\u00E9ro de t\u00E9l\u00E9phone logiciel s\u00E9lectionn\u00E9 est associ\u00E9 au locataire et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.callcenterinstanceslist.table.eMediaAgentCurCapacity": "Quantit\u00E9 d'agent multim\u00E9dia",
  "provision.callcenterinstanceslist.config.saveAndnextStep": "Enregistrer et Suivant",
  "promotion.tenant.management.tenantedit.exception": "Une exception se produit sur le serveur.",
  "provision.recorddrive.selectrecorddrive.singledeleteconfim": "\u00CAtes-vous s\u00FBr de supprimer la lettre de lecteur s\u00E9lectionn\u00E9e? Veuillez vous assurer que la lettre de lecteur n'est pas occup\u00E9e par le locataire",
  "provision.callcenterinstanceslist.resource.node.notexist": "Les informations de n\u0153ud n'existent pas.",
  "provision.appversionmanagement.versionName": "Nom de la version",
  "provision.callcenterinstanceslist.config.odfsappsercret": "AppSercret ODFS",
  "provision.callcent.error.errorinfo101": "\u00C9chec de la synchronisation ccm.",
  "provision.callcent.error.deleteobsserver.part.associationvcallcener": "Certains des serveurs obs s\u00E9lectionn\u00E9s sont associ\u00E9s au locataire et ne peuvent pas \u00EAtre supprim\u00E9s.",
  "provision.whiteListAddress.ivr.voiceupload.callback": "Rappel de t\u00E9l\u00E9chargement vocal IVR",
  "provision.tenantspace.ivr.ivrId": "IVR ID",
  "provision.callcenterinstanceslist.obsServer.progId": "ID prog",
  "provision.tenantspace.config.tenant.custdata.featurevalue.not.one": "La valeur de la fonction de personnalisation des donn\u00E9es attribu\u00E9e au locataire n'est pas 1.",
  "provision.tenant.management.pool.ctinode.id": "ID de n\u0153ud",
  "ccprovision.IVRSprogress.config.logincti.failed": "\u00C9chec de la plate-forme de connexion",
  "provision.tenant.management.tenantcreate.adminaccount.exist": "Le compte administrateur existe d\u00E9j\u00E0",
  "provision.tenant.management.tenantcreate.name.exist": "Le nom du locataire existe d\u00E9j\u00E0",
  "provision.tenantspace.config.applySource": "Appliquer une ressource",
  "provision.tenantspace.config.isAutoAssignWorkNo": "Attribuer automatiquement workNo au centre d'appels virtuel",
  "provision.appsitesmanagement.createTime": "Cr\u00E9er du temps",
  "provision.encryptinfo.updateInterval.error": "L'intervalle de mise \u00E0 jour de la cl\u00E9 ne peut pas \u00EAtre inf\u00E9rieur \u00E0 30 jours.",
  "provision.appsitesmanagement.modifyTime": "Modifier l'heure",
  "provision.appversionmanagement.updateContent": "Mettre \u00E0 jour le contenu",
  "provision.callcent.selectucsserver": "Veuillez s\u00E9lectionner le serveur CCUCS.",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.label": "Param\u00E8tres d'appel",
  "provision.tenantspace.create.applyAccount": "Inscrire un administrateur pour le locataire.",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentage": "Enregistrement par pourcentage",
  "provision.tenantspace.selectCC": "S\u00E9lectionnez un centre d'appels.",
  "provision.tenantspace.feature.outboundCallMarketingSupport": "Appel sortant intelligent",
  "provision.callcent.error.setinfo": "\u00C9chec de la d\u00E9finition.",
  "provision.whiteListAddress.mobileagenturl": "Rappel du service client mobile",
  "provision.callcenterinstanceslist.label.ivrchannelUseRate": "Utilisation audio de la Manche d'IVR",
  "provision.ccp.redis.feature.inprogress": "Les donn\u00E9es de caract\u00E9ristiques de locataire sont en cours d'ex\u00E9cution.",
  "provision.tenantspace.agentinfo.message.deletefail.InProgress": "Le locataire est en cours de traitement de l'entreprise\, veuillez r\u00E9essayer plus tard\!",
  "provision.tenantspace.config.chooseAgentRegistrationServer": "S\u00E9lectionnez un serveur d'enregistrement d'agent",
  "provision.callcenterinstances.agentWasPassLengthValidateFailed": "Le mot de passe doit contenir de 8 \u00E0 32 caract\u00E8res.",
  "provision.callcenterinstanceslist.table.maxVideoCallnums": "Max. Appels vid\u00E9o simultan\u00E9s",
  "provision.callcent.platformserver.ccbms": "CCBMS",
  "provision.callcenterinstanceslist.config.createfailed": "Impossible de cr\u00E9er un centre d'appels.",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callNums.label": "Nombre de donn\u00E9es d'appel (1 \u00E0 10)",
  "provision.callcent.success.save": "Succ\u00E8s",
  "provision.authconfig.update": "Updater",
  "ccprovision.vcallecenter.config.popup.enternewpassword": "Entrez un nouveau mot de passe",
  "provision.subscribedetail.createSource.value_1": "Site officiel de Software Cloud",
  "provision.callcenterinstanceslist.config.workAlreadyOccupied": "L'ID de l'employ\u00E9 est en cours d'utilisation",
  "ccprovision.recorddrive.config.sftp.ipadress": "Adresse IP SFTP",
  "provision.subscribedetail.createSource.value_0": "Site officiel de HUAWEI CLOUD",
  "provision.callcenterinstanceslist.config.serviceIP": "IP Nginx",
  "provision.tenantspace.config.param.error.agentCall": "Veuillez entrer le nombre d'appels vocaux simultan\u00E9s.",
  "ccprovision.recorddrive.config.recorddrivenameconfig": "Configuration du volume",
  "provision.callcenterinstanceslist.sipServer.uapIp": "UAP IP",
  "provision.callcenterinstanceslist.table.ccId": "ID du centre d'appels",
  "provision.callcenterinstanceslist.config.maxVideoCallnumsValidate": "Le nombre maximal d'appels vid\u00E9o simultan\u00E9s est inf\u00E9rieur au nombre d'appels vid\u00E9o effectivement allou\u00E9s par le centre d'appels.",
  "provision.callcent.error.updateucsserver.ondatabase": "La modification du serveur CCUCS dans la base de donn\u00E9es a \u00E9chou\u00E9.",
  "provision.tenantspace.config.error.chooseworkNo.noaddagent": "Aucune ressource d'agent ajout\u00E9e\, pas besoin d'attribuer un num\u00E9ro de travail",
  "provision.authconfig.appkey": "ID de l'application",
  "provision.tenantspace.statusRecordTime.24Month": "24 Mois",
  "provision.tenantspace.status.invalid.fail": "Impossible de r\u00E9voquer.",
  "provision.callcenter.resourceinfo.alert": "Les informations suivantes sur les ressources doivent \u00EAtre compatibles avec les informations de licence d'appareils tels que le PAN et le CTI.",
  "provision.tenant.management.please.select": "S\u00E9lectionner...",
  "provision.tenantspace.sip.searchName": "Veuillez saisir le nom du serveur SBC \u00E0 interroger.",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.label": "Param\u00E8tres d'appel",
  "provision.callcenterinstanceslist.softPhone.singledeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer les num\u00E9ros de softphone s\u00E9lectionn\u00E9s? Assurez-vous que le num\u00E9ro de t\u00E9l\u00E9phone logiciel n'est pas associ\u00E9 \u00E0 un centre d'appels virtuel.",
  "provision.tenantspace.intelligent.agent": "Agent intelligent",
  "provision.tenantspace.config.fail.modify.tenant.auth.use.sumInterface": "L'appel de l'interface SUM pour modifier les autorisations des locataires a \u00E9chou\u00E9",
  "provision.appversionmanagement.iOS": "IOS",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateLength": "La longueur du num\u00E9ro de la liste de confiance varie de 1 \u00E0 32.",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway.with.associationvcallcener": "La suppression partielle a \u00E9chou\u00E9 parce qu'une partie du code d'acc\u00E8s est associ\u00E9e au locataire et qu'une partie du code d'acc\u00E8s n'a pas \u00E9t\u00E9 supprim\u00E9e sur la plate-forme was.",
  "provision.callcent.label.submit": "Envoyer",
  "promotion.tenant.management.tenantedit.softnumber.insufficient": "Le nombre de softphones est insuffisant.",
  "provision.callcenterinstanceslist.sipServerUpdate.failed": "L'adresse IP ou le num\u00E9ro de port ne r\u00E9pondent pas \u00E0 la r\u00E8gle.",
  "provision.tenant.management.adminpasswordagain": "Confirmer le mot de passe",
  "provision.callcenterinstanceslist.vcallcenterDeatil.kafkaPwdLength": "La longueur du mot de passe doit \u00EAtre comprise entre 6 et 32",
  "provision.tenantspace.agentinfo.message.smartagent.iszero": "Il n'y a pas d'agent audio\, s'il vous pla\u00EEt d\u00E9sactiver la fonction d'agent intelligent manuellement",
  "provision.callcenterinstanceslist.table.curCapacityProportion": "Proportion de capacit\u00E9 r\u00E9elle",
  "provision.systemparam.error.validate.ruleinvalid": "La r\u00E8gle de v\u00E9rification n'est pas valide",
  "provision.callcenterinstanceslist.config.adminWorkAlreadyOccupied": "L'ID de l'employ\u00E9 administrateur est en cours d'utilisation",
  "provision.grayconfig.paramIsError": "Le param\u00E8tre d'entr\u00E9e est vide ou incorrect",
  "provision.tenantspace.language.zh": "Chinois",
  "provision.tenantspace.table.createStatus": "Nouvel \u00C9tat",
  "provision.tenantspace.config.tenant.intelligent.inspection.featurevalue.not.one": "La valeur de la fonction d'inspection intelligente attribu\u00E9e au locataire n'est pas 1.",
  "provision.softPhone.label.uapaddress": "Adresse UAP",
  "provision.callcent.error.repetenter": "Le num\u00E9ro a \u00E9t\u00E9 utilis\u00E9. Entrez \u00E0 nouveau un autre num\u00E9ro.",
  "provision.callcenterinstanceslist.sipServer.name": "Nom et pr\u00E9nom",
  "provision.tenantspace.agentinfo.agentstatus.online": "En ligne",
  "provision.callcenterinstanceslist.softPhone.stautus.unoccupied": "Non utilis\u00E9",
  "provision.tenantspace.config.worknoassign.chooseworkNo": "S\u00E9lectionner le num\u00E9ro",
  "provision.spinfo.deletespinfofailed": "La suppression des informations SP a \u00E9chou\u00E9.",
  "provision.callcenterinstanceslist.vcallcenter.videoIvrchannels": "Vid\u00E9oIVRchannels",
  "provision.tenantspace.chooseCommercial": "S\u00E9lection du statut commercial",
  "provision.accesscode.table.callType": "Type d'appel",
  "provision.callcenterinstanceslist.vcallcenter.agentNumErrorListPromt": "Il y a un centre d'appel virtuel dont le nombre de si\u00E8ges est incompatible avec le VDN dans le WAS. La VccId est",
  "provision.tenantspace.config.need.wertcsipserver": "Pour activer la fonctionnalit\u00E9 WebRTC\, s\u00E9lectionnez un serveur d'enregistrement d'agent WebRTC.",
  "provision.appsitesmanagement.sureSelectDataDeleted": "S\u00E9lectionnez les donn\u00E9es \u00E0 supprimer.",
  "provision.callcent.success.delete": "Succ\u00E8s de suppression",
  "provision.tenantspace.agentinfo.label.workNo": "Num\u00E9ro de l'agent",
  "provision.callcenterinstanceslist.softPhone.stautus.select": "S\u00E9lectionnez le statut",
  "provision.callcenterinstanceslist.vcallcenter.maxCalls": "Nombre d'appels vocaux simultan\u00E9s",
  "promotion.tenant.management.tenantedit.accesscode.noavailable": "Le code d'acc\u00E8s n'est pas disponible.",
  "provision.callcenterinstanceslist.config.resourceError": "Les informations de ressources modifi\u00E9es sont inf\u00E9rieures au nombre r\u00E9el de centres d'appels.",
  "provision.appversionmanagement.cannotEnterNumberSmaller": "La valeur doit \u00EAtre sup\u00E9rieure au num\u00E9ro de version maximum.",
  "provision.tenantspace.config.error.142": "La fonction appcube doit \u00EAtre disponible lorsque la fonction app call tas est disponible.",
  "provision.tenantspace.feature.transfer.onlinecheck.description": "Activer la fonction de requ\u00EAte en ligne des ressources de vidage\, et louer salle prend en charge la requ\u00EAte en ligne des ressources de vidage",
  "provision.callcent.selectccgatewayservers": "S\u00E9lectionnez un serveur CC-Gateway.",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment.description": "Cette fonctionnalit\u00E9 fournit la fonction d'envoi de pi\u00E8ces jointes locales par les agents de messagerie pour l'espace locataire.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.sipServerIpAndPort": "Adresse SBC",
  "provision.spinfo.willdeletespinfo": "\u00CAtes-vous s\u00FBr de vouloir supprimer les informations SP s\u00E9lectionn\u00E9es?",
  "provision.callcenterinstanceslist.obsServer.heartbeatPort": "Port de battement de coeur",
  "provision.callcenter.ucs.ip.alert": "Adresse d'\u00E9quilibrage de charge du cluster CCUCS",
  "provision.tenantspace.cc.maxcallnums": "Max. Appels vocaux simultan\u00E9s",
  "provision.callcenterinstanceslist.obsServer.obsName": "Nom OBS",
  "provision.speciallist.tip.timeVerification": "Veuillez s\u00E9lectionner une date sup\u00E9rieure \u00E0 aujourd'hui et inf\u00E9rieure \u00E0 2038-01-19",
  "provision.tenantspace.feature.softphonenounifiedauth.description": "Activez la fonction d'authentification unifi\u00E9e du num\u00E9ro de t\u00E9l\u00E9phone logiciel pour que la pi\u00E8ce locataire prenne en charge l'authentification unifi\u00E9e des num\u00E9ros de t\u00E9l\u00E9phone logiciel.",
  "provision.tenantspace.statusRecordTime.3Month": "3 Mois",
  "provision.sysparam.config.title": "Param\u00E8tres du syst\u00E8me",
  "provision.callcenterinstanceslist.softPhone.stautus.occupied": "En cours d'utilisation",
  "provision.callcent.selectsipserver.inconsistenttype": "Le type de serveur d'enregistrement de l'agent n'est pas coh\u00E9rent",
  "provision.callcenterinstanceslist.vcallcenter.ttschannelnums": "Nombre de TTS",
  "provision.subscribedetail.button.search": "Requ\u00EAte",
  "ccprovision.recorddrive.config.sftp.ipadressport": "Port du serveur SFTP",
  "provision.callcent.error.deleteinfo": "La suppression a \u00E9chou\u00E9.",
  "provision.tenantspace.table.config": "Configurer",
  "provision.appsitesmanagement.siteAddr": "Adresse du site",
  "provision.ccworknos.table.vCCInstId": "Centre d'appel virtuel",
  "provision.sysparam.config.item.catalog": "Type de param\u00E8tre",
  "provision.tenantspace.gw.idleRegAgentNum": "Quantit\u00E9 d'agent enregistr\u00E9 concurrent restante",
  "provision.vcallcenter.message.unknowcause": "Cause inconnue",
  "provision.callcenterinstanceslist.config.softUsage.more": "Le nombre de softphones est insuffisant. V\u00E9rifiez la configuration.",
  "provision.tenant.management.tenantmodify.cobrowse.akempty": "Les informations AK ne peuvent pas \u00EAtre vides car vous avez activ\u00E9 la fonctionnalit\u00E9 de co-navigation pour le locataire.",
  "provision.callcenterinstanceslist.recorddrive.Title": "Lettre de lecteur d'enregistrement",
  "provision.tenantspace.option.weeklist_firtolast": "Dernier",
  "provision.callcenterinstanceslist.config.videocallnumsError": "Le nombre maximal d'appels vid\u00E9o simultan\u00E9s doit \u00EAtre sup\u00E9rieur ou \u00E9gal au nombre de si\u00E8ges vid\u00E9o.",
  "provision.tenantspace.create": "Cr\u00E9er",
  "provision.subscribedetail.label.enableStatus": "Abonnement",
  "provision.callcenterinstanceslist.ccdis.poolNodeId2": "Noeud CTI auquel appartient le CCDIS de secours",
  "provision.tenantspace.chooseobsdatasource.callCenterDbSource": "Sources de donn\u00E9es du centre d'appels",
  "provision.tenant.management.tenantexport.addTaskError": "Impossible d'ajouter la t\u00E2che d'exportation. Veuillez v\u00E9rifier le journal.",
  "provision.tenant.management.DSTOffset": "D\u00E9calage DST (min)",
  "provision.tenant.management.tenantcreate.timezone.failed": "Impossible de v\u00E9rifier les informations de fuseau horaire.",
  "provision.tenantspace.resetpwdfaild.tenant.not.exist": "Impossible de changer le mot de passe parce que l'espace locataire n'existe pas.",
  "provision.accesscode.table.whetherSupportTrialUse": "Soutenir l'utilisation d'essai",
  "provision.tenantspace.cc.videoIvrchannelUsagePercent": "Utilisation vid\u00E9o de la Manche d'IVR",
  "provision.tenantspace.cc.asrchannelUsagePercent": "Utilisation de l'ASR",
  "provision.callcent.error.createaccesscode.n400Numbervalidate": "Le code d'acc\u00E8s et le num\u00E9ro de service ne peuvent pas exister de relation inclusive.",
  "provision.authconfig.connected.with.tenantspace": "cet ID APP est li\u00E9 \u00E0 un espace locataire\, ne peut pas supprimer.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.placeholder": "La valeur varie de 1 \u00E0 1000000000. La valeur 0 indique qu'aucune limite n'est sp\u00E9cifi\u00E9e.",
  "provision.tenantspace.config.ivrserver.select.confirm.message": "Les processus IVR s\u00E9lectionn\u00E9s sont d\u00E9ploy\u00E9s sur le m\u00EAme serveur\, ce qui peut provoquer un seul point de d\u00E9faillance. \u00CAtes-vous s\u00FBr de vouloir continuer ?",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageScope": "La valeur minimale est 0\, et la valeur maximale est 100. La valeur par d\u00E9faut est 100.",
  "provision.accesscode.table.n400Number": "Num\u00E9ro de service",
  "provision.callcent.error.savesipserverdesinfo": "Assurez-vous que le nom du serveur sbc n'existe pas.",
  "provision.callcenterinstance.config.maxcallnumsCompareValidate": "Le nombre maximal d'appels vocaux simultan\u00E9s doit \u00EAtre sup\u00E9rieur ou \u00E9gal \u00E0 la somme de l'agent vocal et des canaux RVI audio et vid\u00E9o.",
  "provision.tenantspace.option.weekday_thu": "Jeudi",
  "provision.appversionmanagement.queryFailed": "Erreur de recherche.",
  "provision.tenant.management.updatetenant.failed": "Impossible de modifier la configuration de l'espace locataire.",
  "provision.tenantspace.intelligent.ivr": "IVR intelligent",
  "provision.tenantspace.error.deleteSUM": "Impossible de supprimer l'espace locataire SUM.",
  "provision.tenantspace.save.fail.079": "Impossible d'analyser les informations utilisateur locales ou externes lors de la configuration du syst\u00E8me d'authentification tiers.",
  "provision.whiteListAddress.resourcedump.cssproxy": "Agent serveur CSS de vidage de ressources",
  "provision.tenant.management.tenantcreate.expiryDate": "Date d'expiration",
  "provision.tenantspace.feature.transfer.onlinecheck": "Voir les ressources de vidange en ligne",
  "provision.recorddrive.error.newrecord.repeated": "Impossible d'ajouter le m\u00EAme nom de lettre de lecteur sous le m\u00EAme centre d'appels",
  "provision.tenantspace.config.amsobjnum.unequal": "D\u00E9finissez ce param\u00E8tre \u00E0 la m\u00EAme valeur que celle du serveur d'appels anonymes.",
  "provision.tenantspace.authenticationsystem.enter.clientName": "Entrez le nom d'un syst\u00E8me d'authentification tiers.",
  "provision.tenantspace.popuppageurl.whiteListAdd": "Ajout d'une liste de confiance d'adresses",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer": "Serveur SBC",
  "provision.tenantspace.authenticationsystem.mappingName": "R\u00E8gle de mappage de f\u00E9d\u00E9ration",
  "provision.callcenterinstanceslist.vcallcenter.close": "Fermer",
  "provision.tenantspace.agentinfo.message.selectagent": "Veuillez s\u00E9lectionner un agent\!",
  "provision.callcenterinstanceslist.label.callnumUseRate": "Utilisation des appels vocaux simultan\u00E9s",
  "provision.whiteListAddress.AddressExist": "La liste d'approbation d'adresses existe d\u00E9j\u00E0.",
  "ccprovision.vcallecenter.sucess.updateinfo.adminpassword": "Modifiez le mot de passe avec succ\u00E8s.",
  "provision.callcenterinstanceslist.config.servicePort": "Port Nginx",
  "provision.callcenterinstanceslist.table.versatileAgentCurCapacity": "Agents polyvalents",
  "provision.tenantspace.feature.dualChannelRecSupport.description": "Cette fonction fournit des fonctions d'enregistrement double piste pour l'espace locataire.",
  "provision.callcenterinstanceslist.config.maxcallnumsValidate": "Le nombre maximal d'appels vocaux simultan\u00E9s est inf\u00E9rieur au nombre d'appels vocaux effectivement attribu\u00E9s par le centre d'appels.",
  "provision.tenantspace.save.fail.067": "Impossible de valider les donn\u00E9es du syst\u00E8me d'authentification tiers.",
  "provision.callcenterinstanceslist.obsServer.standbyObsDirectHeartbeatIp": "Sp\u00E9cifie l'adresse IP de battement de coeur de l'OBS de secours",
  "provision.callcenterinstanceslist.ccdis.poolNodeId": "Noeud CTI auquel appartient le CCDIS actif",
  "provision.tenantspace.authenticationsystem.clientURL": "URL du syst\u00E8me d'authentification tiers",
  "provision.systemparam.error.validate.notmatchruleip": "Veuillez entrer une adresse IPV4 ou IPV6 valide",
  "provision.encryptinfo.scenario": "Sc\u00E9nario d'application",
  "provision.callcenterinstanceslist.vcallcenter.newvccId": "VccInstId",
  "provision.tenantspace.save.fail.063": "Impossible de supprimer le processus IVR.",
  "provision.tenantspace.save.fail.062": "Le code d'acc\u00E8s multim\u00E9dia doit \u00EAtre configur\u00E9 lorsque le code d'acc\u00E8s poussin \u00E0 num\u00E9ro est configur\u00E9.",
  "provision.label.tenantexport.importdata": "Importation de donn\u00E9es",
  "provision.label.tenantexport.paracomplexity": "La complexit\u00E9 du mot de passe ne r\u00E9pond pas aux exigences.",
  "provision.callcenterinstanceslist.sipServer.maxRegAgentNum": "Nombre de demandes simultan\u00E9es",
  "provision.tenant.management.email": "Courriel",
  "provision.appversionmanagement.systemType": "Type de syst\u00E8me",
  "provision.callcenterinstanceslist.ucsServer.singledeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer le serveur CCUCS s\u00E9lectionn\u00E9 ?",
  "provision.ccworknos.table.ccworknos": "ID d'employ\u00E9",
  "provision.tenant.management.akinfo": "Informations AK",
  "provision.callcenterinstanceslist.vcallcenter.accessCode": "Code d'acc\u00E8s",
  "provision.callcenterinstanceslist.obsServer.singledeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer le serveur d'obs d'enregistrement s\u00E9lectionn\u00E9?",
  "provision.tenantspace.save.fail.058": "Impossible d'enregistrer les donn\u00E9es.",
  "provision.systemparam.success.reset": "La r\u00E9initialisation des param\u00E8tres a r\u00E9ussi",
  "provision.tenantspace.save.fail.056": "Informations de base incorrectes sur l'espace locataire.",
  "provision.tenantspace.sip.idleRegAgentNum": "Quantit\u00E9 d'agent enregistr\u00E9 concurrent restante",
  "provision.tenantspace.save.fail.057": "La ressource allou\u00E9e \u00E0 l'espace locataire est anormale.",
  "provision.tenantspace.agentinfo.agentstatus": "Statut de l'agent",
  "provision.tenantspace.save.fail.055": "Des t\u00E2ches en attente existent dans l'espace locataire.",
  "provision.tenantspace.save.fail.054": "Impossible d'allouer manuellement les ressources de num\u00E9ros de t\u00E9l\u00E9phone logiciel.",
  "provision.grayconfig.deleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer la configuration grise?",
  "provision.tenantspace.save.fail.052": "Le serveur OBS n'existe pas.",
  "provision.tenantspace.save.fail.053": "La source de donn\u00E9es OBS n'existe pas.",
  "provision.tenantspace.save.fail.051": "La source de donn\u00E9es OBS n'est pas attribu\u00E9e.",
  "provision.tenantspace.save.fail.050": "Le nombre d'ID d'agents s\u00E9lectionn\u00E9s est inf\u00E9rieur au nombre de nouveaux agents.",
  "provision.tenantspace.status.active.fail": "L'activation a \u00E9chou\u00E9.",
  "provision.callcenterinstanceslist.table.agentCurCapacity": "Quantit\u00E9 d'agent vocal",
  "provision.tenant.management.administratorinfo": "Informations sur l'administrateur locataire",
  "provision.callcenterinstanceslist.resource.associationvcallcener.updatenode.failed": "Les locataires sont assign\u00E9s\, les n\u0153uds ne peuvent pas \u00EAtre modifi\u00E9s.",
  "provision.callcent.platformserver.ccbcs": "CCBCS",
  "ccprovision.IVRSprogress.config.progressNo": "ID de processus",
  "provision.tenantspace.config.param.error.ttsNumber": "Veuillez saisir la quantit\u00E9 TTS.",
  "provision.tenantspace.selectStastus.edit.active": "Actif",
  "provision.callcenterinstanceslist.IVRSprogress": "Processus IVR",
  "provision.tenantspace.cc.asrchannelnums": "Quantit\u00E9 ASR",
  "provision.label.tenantexport.confirm": "Remerciements",
  "provision.tenantspace.save.fail.048": "Le segment ID d'agent qui est allou\u00E9 manuellement contient les ID d'agent allou\u00E9s.",
  "provision.tenantspace.save.fail.047": "Le serveur CC-Gateway ne dispose pas de ressources suffisantes.",
  "provision.nodepolicy.sync.failed": "\u00C9chec de la synchronisation.",
  "provision.tenantspace.save.fail.049": "Le num\u00E9ro exclu ne r\u00E9pond pas aux exigences.",
  "provision.tenantspace.save.fail.046": "La connexion \u00E0 la plate-forme was n'a pas permis de supprimer un agent.",
  "provision.callcenterinstanceslist.config.odfconfigerror": "Les informations de configuration du service ODFS sont incorrectes.",
  "provision.subscribedetail.error.query": "La requ\u00EAte a \u00E9chou\u00E9.",
  "provision.tenantspace.save.fail.045": "Les ressources en processus de RVI sont insuffisantes.",
  "provision.tenantspace.save.fail.044": "Veuillez configurer le code d'acc\u00E8s pour le type audio et vid\u00E9o.",
  "provision.tenantspace.save.fail.041": "La somme des agents voix\, vid\u00E9o\, multim\u00E9dia et polyvalents\, et des canaux audio et vid\u00E9o IVR doit \u00EAtre sup\u00E9rieure \u00E0 0.",
  "provision.tenantspace.save.fail.043": "Nombre d'applications d'agents multim\u00E9dia sup\u00E9rieur au nombre d'agents multim\u00E9dia restants.",
  "provision.tenantspace.save.fail.040": "Veuillez configurer le code d'acc\u00E8s pour le type multim\u00E9dia.",
  "provision.appsitesmanagement.videoAvailable": "Vid\u00E9o disponible",
  "provision.tenantspace.feature.smsMarketingSupport.description": "Cette fonctionnalit\u00E9 fournit des fonctions de marketing par SMS pour l'espace locataire.",
  "provision.callcent.selectvcallcent": "S\u00E9lectionnez un centre d'appels virtuel.",
  "provision.callcenterinstanceslist.softPhone.newpasswordconf": "R\u00E9p\u00E9ter le nouveau mot de passe",
  "provision.tenant.no.more.agentUsage": "Le nombre d'agents est trop important.",
  "provision.tenant.management.DSTEndTime": "Heure de fin DST",
  "provision.callcent.error.updatesipserver.noexist": "Le serveur SIP n'existe pas.",
  "provision.tenantspace.commercialStatus": "Qu'il s'agisse d'un site commercial",
  "provision.softPhone.label.isSyncUap": "Synchroniser UAP",
  "provision.tenantspace.common.ivr.explain": "IVR conventionnel \: Prend en charge les fonctions de base telles que la lecture\, la r\u00E9ception et le TTS. TTS doit configurer le nombre de canaux TTS.",
  "provision.encryptinfo.email": "Cryptage et d\u00E9cryptage du contenu des e-mails",
  "provision.callcenterinstanceslist.obsServer.activeObsDirectHeartbeatIp": "Sp\u00E9cifie l'adresse IP de battement de coeur de l'OBS actif",
  "provision.callcent.error.saveucsserverdesinfo": "\u00C9chec de la cr\u00E9ation\, Assurez-vous que le nom du serveur CCUCS n'existe pas.",
  "provision.tenantspace.table.ivrchannels": "Quantit\u00E9 audio de canal d'IVR",
  "provision.sysparam.config.checkmessage": "Contient des caract\u00E8res sp\u00E9ciaux. (" < "\, " > "\, " / "\, etc.)",
  "provision.tenantspace.save.fail.031": "R\u00E9duction de locataire s'il vous pla\u00EEt op\u00E9rer sur la page d'information de l'agent.",
  "ccprovision.vcallecenter.config.popup.authentication": "V\u00E9rifier l'identit\u00E9",
  "provision.tenantspace.save.fail.032": "AgentUsage et VideoUsage doivent \u00EAtre inf\u00E9rieurs \u00E0 10000.",
  "provision.callcenterinstanceslist.config.videoIvrchannelsValidate": "Le nombre de canaux IVR vid\u00E9o est inf\u00E9rieur au nombre de canaux r\u00E9ellement allou\u00E9s dans le centre d'appels.",
  "provision.tenantspace.gw.port": "Port de serveur de passerelle",
  "provision.tenantspace.save.fail.030": "La PhoneNumber douce ne suffit pas.",
  "provision.callcenterinstanceslist.sipServer.batchdeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer tous les serveurs d'enregistrement s\u00E9lectionn\u00E9s?",
  "provision.callcent.error.createaccesscode.validate.accessCodeContainAccesscode": "Le code d'acc\u00E8s ne peut pas contenir de relation d'inclusion avec d'autres codes d'acc\u00E8s sous le centre d'appels.",
  "provision.callcent.error.accesscode.deletefail.sqlexception": "La suppression a \u00E9chou\u00E9\, exception d'ex\u00E9cution SQL",
  "provision.ivr.error.cannotdelete.occupyvdn": "Le RVI attribu\u00E9 au locataire ne peut pas \u00EAtre supprim\u00E9.",
  "ccprovision.IVRSprogress.config.button.add": "Nouveau",
  "provision.callcenterinstanceslist.table.refresh": "Actualiser",
  "provision.systemparam.error.validate.notmatchruleurl": "Veuillez entrer une adresse URL valide",
  "provision.tenantspace.config.param.error.videoCall": "Veuillez saisir le nombre d'appels vid\u00E9o simultan\u00E9s.",
  "provision.systemparam.error.validate.userNotExists": "Entrez un compte utilisateur valide.",
  "provision.callcenterinstanceslist.label.eMediaAgentUseRate": "Utilisation de l'agent multim\u00E9dia",
  "provision.tenantspace.save.fail.027": "\u00C0 gauche de l'IVR Le nombre n'est pas suffisant.",
  "provision.tenantspace.save.fail.029": "A gauche de ASR Nombre n'est pas suffisant.",
  "provision.tenantspace.save.fail.028": "A gauche du nombre de TTS n'est pas suffisant.",
  "provision.tenantspace.save.fail.026": "Le nombre restant d'appels anonymes simultan\u00E9s est insuffisant.",
  "provision.tenantspace.save.fail.025": "A gauche de VoiceCallNumber ne suffit pas.",
  "provision.tenantspace.save.fail.024": "A gauche de VidoeUsage ne suffit pas.",
  "provision.tenantspace.option.weeklist_tid": "Troisi\u00E8me",
  "provision.spinfo.description": "Descriptif",
  "provision.spinfo.modifyspinfosuccess": "Informations SP modifi\u00E9es avec succ\u00E8s.",
  "provision.tenantspace.save.fail.023": "A gauche de AgentUsage ne suffit pas.",
  "provision.callcenterinstanceslist.table.wasip": "D\u00E9finir l'IP du serveur",
  "provision.tenantspace.save.fail.022": "AgentUsage et MaxVoiceCallNumbers ne peuvent pas \u00EAtre z\u00E9ro.",
  "provision.tenantspace.save.fail.021": "Impossible de mettre \u00E0 jour les donn\u00E9es de l'espace locataire.",
  "provision.callcent.error.abnormal": "Anormal",
  "provision.callcenterinstanceslist.table.ivrchannels": "Quantit\u00E9 audio de canal d'IVR",
  "provision.tenantspace.save.fail.020": "Impossible de supprimer les donn\u00E9es du centre d'appel virtuel.",
  "provision.tenantspace.ok": "OK",
  "provision.tenantspace.cc.maxAmsCallnums": "Max. Appels Anonymes Concurrents",
  "ccprovision.IVRSprogress.config.button.complete": "Compl\u00E8te",
  "provision.tenantspace.config.tenant.intelligent.ivr.featurevalue.not.one": "La valeur de fonctionnalit\u00E9 ivr intelligente attribu\u00E9e au locataire n'est pas 1",
  "provision.callcenterinstanceslist.sipServer.idleRegAgentNum": "Concurrence restante",
  "provision.tenantspace.obsServer.datasource.name": "Nom de la source de donn\u00E9es",
  "provision.tenantspace.feature.knowledgeBaseSupport": "Base de connaissances",
  "provision.callcenterinstanceslist.whiteList.IP": "Adresse de la liste de confiance",
  "provision.systemparam.error.noparamselected": "Veuillez d'abord s\u00E9lectionner un param\u00E8tre",
  "provision.tenantspace.save.fail.019": "La capacit\u00E9 restante du centre d'appel est inf\u00E9rieure \u00E0 celle des agents nouvellement attribu\u00E9s.",
  "provision.tenantspace.config.amsNumber.error": "Le nombre d'appels anonymes simultan\u00E9s ne peut pas \u00EAtre vide ou doit \u00EAtre 0 ou un entier positif.",
  "provision.tenantspace.save.fail.018": "Le centre d'appels est vide.",
  "provision.tenantspace.save.fail.016": "Impossible de mettre \u00E0 jour les donn\u00E9es du centre d'appels.",
  "provision.tenantspace.save.fail.017": "Le centre d'appel virtuel est vide.",
  "provision.tenantspace.save.fail.014": "Impossible de mettre \u00E0 jour les donn\u00E9es d'identification de l'employ\u00E9.",
  "provision.tenantspace.save.fail.015": "Impossible de mettre \u00E0 jour les donn\u00E9es du num\u00E9ro de t\u00E9l\u00E9phone logiciel.",
  "provision.tenantspace.save.fail.012": "Impossible de mettre \u00E0 jour les donn\u00E9es du centre d'appels virtuel.",
  "provision.tenantspace.sip.name": "Nom du serveur SBC",
  "provision.tenantspace.save.fail.010": "Le nombre de segments de nombre occup\u00E9s est inf\u00E9rieur au nombre d'agents appliqu\u00E9s. La valeur retourn\u00E9e est null.",
  "provision.tenantspace.save.fail.011": "Impossible d'enregistrer les donn\u00E9es du centre d'appel virtuel.",
  "provision.tenantspace.save.fail.013": "Impossible de mettre \u00E0 jour les donn\u00E9es du code d'acc\u00E8s.",
  "provision.callcenterinstanceslist.label.maxAmsCallNums": "Appels anonymes simultan\u00E9s",
  "provision.callcenterinstanceslist.table.nodepolicyconfirmmessage": "\u00CAtes-vous s\u00FBr de vouloir synchroniser les configurations de strat\u00E9gie de n\u0153ud?",
  "provision.tenant.management.pool.ctinode2": "N\u0153ud CTI 2",
  "provision.tenantspace.selectStastus.unallocated": "En attente",
  "provision.tenant.management.pool.ctinode1": "N\u0153ud CTI 1",
  "provision.speciallist.tip.userNoLength": "Le nombre d'abonn\u00E9s ne peut pas d\u00E9passer 100 caract\u00E8res",
  "provision.tenantspace.config.choosesoftphone": "S\u00E9lectionnez le num\u00E9ro de t\u00E9l\u00E9phone logiciel.",
  "provision.callcenterinstanceslist.table.obssyncConfimMessage": "\u00CAtes-vous s\u00FBr de synchroniser la configuration OBS?",
  "provision.callcenterinstanceslist.config.videoAgentChannelsValidate": "Le nombre de canaux d'agent vid\u00E9o est inf\u00E9rieur au nombre de canaux effectivement attribu\u00E9s dans le centre d'appels.",
  "provision.tenantspace.save.fail.009": "Le segment ID de l'employ\u00E9 est vide.",
  "provision.accesscode.table.enterpriseName": "Nom de l'entreprise",
  "provision.tenantspace.save.fail.008": "Le num\u00E9ro de t\u00E9l\u00E9phone logiciel est vide.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain2": "Un maximum de cinq groupes de nombres peuvent \u00EAtre ajout\u00E9s.",
  "provision.tenant.management.tenantexport.cancel": "annule",
  "provision.tenantspace.save.fail.007": "Le centre d'appels n'existe pas.",
  "provision.tenantspace.save.fail.006": "Le nombre d'agents sous le locataire ne peut pas \u00EAtre sup\u00E9rieur au nombre d'agents restants sur le serveur SBC.",
  "provision.callcenterinstanceslist.sipServer.sip": "SIP",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain1": "Aper\u00E7u de la biblioth\u00E8que de nombres",
  "provision.tenantspace.save.fail.004": "Le code d'acc\u00E8s est vide.",
  "provision.tenantspace.save.fail.005": "Le code d'acc\u00E8s est occup\u00E9.",
  "provision.tenantspace.save.fail.003": "Le nombre maximal d'appels vid\u00E9o simultan\u00E9s est sup\u00E9rieur ou \u00E9gal au nombre d'agents vid\u00E9o.",
  "provision.subscribedetail.label.emailAddr": "E-mail de contact",
  "provision.tenantspace.save.fail.001": "Le nombre d'agents vocaux\, d'agents vid\u00E9o\, d'agents multim\u00E9dias et de canaux IVR ne peut pas \u00EAtre \u00E9gal \u00E0 0.",
  "provision.tenantspace.feature.operation": "Les donn\u00E9es de caract\u00E9ristiques de locataire sont en cours de traitement au niveau de l'arri\u00E8re-plan. Affichez le r\u00E9sultat du traitement de la fonctionnalit\u00E9 sur la page courante deux minutes plus tard.",
  "provision.tenant.management.tenantspaceid": "ID de l'espace locataire",
  "provision.obs.error.sync": "\u00C9chec de la synchronisation.",
  "provision.callcent.platformserver.cms": "CMS",
  "ccprovision.vcallecenter.config.popup.modifyinfo": "Modifier le mot de passe",
  "provision.tenantspace.config.amsServer.idleregagentnum.insufficient.error": "Nombre insuffisant d'appels simultan\u00E9s restants sur le serveur d'appels anonymes.",
  "provision.systemparam.error.validate.notmatchruleregex": "Le param\u00E8tre d'entr\u00E9e ne correspond pas \u00E0 l'expression rationnelle de la r\u00E8gle de v\u00E9rification",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayPort": "Port CC-Gateway",
  "provision.tenantspace.save.fail.002": "Le nombre maximal d'appels vocaux simultan\u00E9s est sup\u00E9rieur ou \u00E9gal au nombre d'agents et de canaux IVR.",
  "provision.tenantspace.obs.searchName": "Entrez le nom du serveur OBS \u00E0 interroger.",
  "provision.authconfig.deleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer l'identifiant de l'application ?",
  "provision.tenant.management.tenantcreate.success": "L'espace locataire est cr\u00E9\u00E9 avec succ\u00E8s.",
  "provision.tenant.management.edit.agentusage.mustinc": "Le nombre de nouveaux agents ne peut \u00EAtre inf\u00E9rieur \u00E0 celui des agents originaux.",
  "provision.appsitesmanagement.sureBatchDeleteData": "La suppression de ces sites peut entra\u00EEner l'\u00E9chec de la connexion des utilisateurs \u00E0 l'application mobile. \u00CAtes-vous s\u00FBr de vouloir les supprimer ?",
  "provision.callcenterinstanceslist.table.delete": "Supp.",
  "provision.callcenterinstanceslist.softPhone.softPhoneAdd": "Nouveau num\u00E9ro de t\u00E9l\u00E9phone logiciel",
  "provision.callcenterinstance.config.ivrchannelsValidate": "La somme du nombre de canaux IVR audio et vid\u00E9o doit \u00EAtre sup\u00E9rieure ou \u00E9gale \u00E0 la somme du nombre de canaux TTS et du nombre de canaux ASR.",
  "provision.speciallist.tip.reasonLength": "La raison ne peut pas d\u00E9passer 1024 caract\u00E8res",
  "provision.callcenterinstanceslist.sipServer.sipServerIp": "Adresse IP du serveur SBC",
  "provision.tenantspace.config.select.default.phone": "Veuillez s\u00E9lectionner le num\u00E9ro d'appel par d\u00E9faut.",
  "provision.callcenterinstanceslist.label.videoAgentChannelUseRate": "Utilisation du canal de l'agent vid\u00E9o",
  "provision.tenantspace.agentinfo": "Informations sur l'agent",
  "ccprovision.IVRSprogress.config.leftovercurcallNo": "Quantit\u00E9 restante du canal d'appel simultan\u00E9",
  "provision.callcenterinstanceslist.config.asrchannelnumsValidate": "Le nombre de canaux ASR est inf\u00E9rieur au nombre de canaux r\u00E9ellement attribu\u00E9s dans le centre d'appels.",
  "provision.tenantspace.option.weekday_wed": "Mercredi",
  "provision.spinfo.createTime": "CreateTime",
  "provision.authconfig.authinfomation": "Informations sur le d\u00E9veloppeur",
  "provision.callcenterinstanceslist.vcallcenterDeatil.uapIpAndPort": "Adresse UAP",
  "provision.tenant.management.tenantexport.addTaskSuccess": "La t\u00E2che d'exportation est ajout\u00E9e avec succ\u00E8s.",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway": "Une partie du code d'acc\u00E8s n'a pas r\u00E9ussi \u00E0 \u00EAtre supprim\u00E9e sur la plate-forme was.",
  "provision.callcenterinstanceslist.config.sysAdminpassword": "Mot de passe administrateur CTI",
  "provision.callcenterinstanceslist.table.callcenterinstanceslist": "Liste des centres d'appels",
  "promotion.tenant.management.tenantedit.vccupdate.failed": "Impossible de mettre \u00E0 jour le centre virtuel.",
  "provision.callcenterinstanceslist.table.ttschannelnums": "Quantit\u00E9 TTS",
  "provision.tenantspace.table.expiryDate": "Date limite",
  "provision.callcenterinstanceslist.config.wnFromValidate": "La somme du segment de num\u00E9ro de d\u00E9part et de la capacit\u00E9 r\u00E9elle du syst\u00E8me ne peut pas \u00EAtre sup\u00E9rieure \u00E0 50000.",
  "provision.tenantspace.detail.apply.accout": "Compte",
  "provision.tenant.management.tenantedit": "Modifier les informations du locataire",
  "provision.whiteListAddress.resourcedump.obsproxy": "Agent serveur OBS de vidage de ressources",
  "provision.tenantspace.feature.contractdigitalsignature": "Signature num\u00E9rique du contrat",
  "provision.tenant.management.tenantcreate.inter.failed": "Impossible d'appeler le service interne pour cr\u00E9er l'espace locataire.",
  "ccprovision.recorddrive.config.sftp.filestoragepath": "Chemin du fichier",
  "provision.callcenter.gateway.address.alert": "Adresse d'\u00E9quilibrage de charge du cluster CC-Gateway",
  "provision.tenantspace.dstoffsettime.verification.fail": "La valeur est un entier allant de -1440 \u00E0 1440.",
  "provision.callcent.platformserver.isqm": "iSQM",
  "provision.tenantspace.table.maxVideoCalls": "Max. Appels vid\u00E9o simultan\u00E9s",
  "provision.tenantspace.cc.amsCallnumUsagePercent": "Utilisation simultan\u00E9e d'appels anonymes",
  "provision.callcenterinstanceslist.config.save": "Enregistrer",
  "provision.tenantspace.option.weekday_mon": "Lundi",
  "provision.tenantspace.config.chooseSIPServer": "S\u00E9lectionnez un serveur SBC.",
  "provision.callcent.error.updateccgateway.ondatabase": "\u00C9chec de la mise \u00E0 jour\,modifi\u00E9 le serveur CC-Gateway dans la base de donn\u00E9es a \u00E9chou\u00E9.",
  "provision.subscribedetail.validate.alphavalidate": "Des caract\u00E8res non valides existent. Seules les lettres\, les chiffres et les (_) de soulignement sont pris en charge.",
  "provision.tenantspace.obsServer.backup.datasource.name": "Nom de la source de donn\u00E9es de secours",
  "provision.sysparam.config.refresh": "Actualiser",
  "provision.tenant.management.pool.ctinode.name": "Nom du n\u0153ud",
  "provision.tenant.management.tenantcreate.companyName": "Entreprise",
  "provision.tenantspace.resetpwdsuccess": "Le mot de passe est r\u00E9initialis\u00E9 avec succ\u00E8s.",
  "provision.tenantspace.config.error.softphone.noaddagent": "Aucun agent audio\, vid\u00E9o\, multim\u00E9dia ou mobile n'est ajout\u00E9.",
  "provision.callcenterinstanceslist.config.workNoCheckError": "Le num\u00E9ro de d\u00E9but de l'ID de travail doit \u00EAtre plus petit que le num\u00E9ro de fin.",
  "provision.callcenterinstanceslist.table.deleteconfim": "Confirmer la suppression",
  "provision.tenantspace.agentinfo.message.searchSipAccount": "S'il vous pla\u00EEt entrer le num\u00E9ro de t\u00E9l\u00E9phone logiciel",
  "provision.tenantspace.config.softphoneassign.softphonescope.required": "Entrez le segment de num\u00E9ro de t\u00E9l\u00E9phone logiciel \u00E0 ajouter et cliquez sur OK.",
  "provision.callcenterinstanceslist.config.nmsserviceIP": "Adresse IP ODFS",
  "ccprovision.vcallecenter.config.popup.confirmnewpassword": "Confirmer le mot de passe",
  "ccprovision.recorddrive.config.sftp.identitycheck": "V\u00E9rification d'identit\u00E9",
  "provision.appversionmanagement.harmony": "HarmonyOS",
  "provision.callcenterinstanceslist.config.adminWorkNoError": "Le num\u00E9ro de d\u00E9part de l'ID de l'employ\u00E9 administrateur doit aller de 55001 \u00E0 59999.",
  "provision.tenantspace.feature.contractdigitalsignature.description": "Cette fonctionnalit\u00E9 permet la signature num\u00E9rique pour les contrats \u00E9lectroniques.",
  "provision.callcenterinstanceslist.sipServer.sipServerPort": "Num\u00E9ro de port SBC",
  "provision.callcenterinstanceslist.config.eMediaAgentCurCapacityValidate": "Le nombre d'agents multim\u00E9dia est inf\u00E9rieur au nombre de si\u00E8ges multim\u00E9dia effectivement attribu\u00E9s dans le centre d'appels.",
  "provision.callcent.success.deleteinfo": "Supprim\u00E9 avec succ\u00E8s.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber": "Envoi de la limite sup\u00E9rieure par jour",
  "provision.appversionmanagement.historicalVersion": "Version historique",
  "provision.tenantspace.choose.accesscode": "S\u00E9lectionnez le code d'acc\u00E8s",
  "provision.spinfo.certificate": "Contenu du certificat",
  "provision.callcent.label.reset": "R\u00E9initialiser",
  "provision.tenantspace.table.videoivrchannels": "Quantit\u00E9 vid\u00E9o de canal d'IVR",
  "provision.callcenterinstanceslist.table.wasport": "D\u00E9finissez le num\u00E9ro de port du serveur.",
  "provision.systemparam.error.validate.notmatchruledigits": "Veuillez entrer un entier positif valide",
  "provision.appsitesmanagement.pleaseEnter": "-S\u00E9lectionner-",
  "provision.callcenterinstanceslist.table.baseinfo": "Informations de base",
  "provision.callcenterinstanceslist.table.maxcallnums": "Max. Appels vocaux simultan\u00E9s",
  "provision.callcent.selectcallcent": "S\u00E9lectionnez un centre d'appels.",
  "provision.appversionmanagement.new": "Ajouter",
  "provision.callcenterinstanceslist.nodepolicy.tenantspacename": "Nom du VDN",
  "pprovision.tenantspace.feature.emailAgentForwarding.description": "Activez la fonction de redirection de l'agent de messagerie. L'espace lou\u00E9 prend en charge la fonction de transfert de courrier \u00E9lectronique de l'agent de messagerie.",
  "provision.tenantspace.save.fail.123053": "Le r\u00F4le de l'agent est le r\u00F4le par d\u00E9faut et ne peut pas \u00EAtre modifi\u00E9.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.error": "L'ID de l'agent final ne peut pas \u00EAtre inf\u00E9rieur \u00E0 l'ID de l'agent de d\u00E9part.",
  "provision.tenantspace.save.fail.123052": "Le r\u00F4le de l'agent est r\u00E9f\u00E9renc\u00E9 par l'agent et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.ccworknos.table.creataccessCode": "Nouveau",
  "provision.tenantspace.save.fail.123051": "Dupliquer le nom du r\u00F4le de l'agent.",
  "provision.callcent.platformserver.certificateChoose": "S\u00E9lectionnez Certificat",
  "provision.accesscode.table.audioAndvideo": "audio et vid\u00E9o",
  "provision.callcent.pool.select.different.sipserver.error": "Les serveurs d'enregistrement d'agent du m\u00EAme type doivent \u00EAtre s\u00E9lectionn\u00E9s pour diff\u00E9rents n\u0153uds.",
  "provision.callcenterinstanceslist.table.update": "\u00E9diter",
  "provision.callcenterinstanceslist.softPhone.used.select": "Utilisation",
  "provision.tenantspace.config.chooseAgentRegistrationServerTitle": "S\u00E9lectionnez le serveur d'enregistrement de l'agent.",
  "provision.tenant.management.tenantname.minlength": "Le nom de l'espace locataire ne peut pas contenir moins de 6 caract\u00E8res.",
  "provision.tenantspace.selectStastus.edit.invalid": "Invalide",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer": "Serveur CC-Gateway",
  "provision.tenantspace.create.onlineUserNumber": "Num\u00E9ro d'utilisateur en ligne",
  "provision.tenant.management.admininfo": "Inscription administrateur locataire",
  "provision.tenantspace.table.maxVideoAgents": "Quantit\u00E9 d'agent vid\u00E9o",
  "provision.tenant.management.batchcreate.success.createtask": "Ajout r\u00E9ussi d'une t\u00E2che pour cr\u00E9er des espaces locataires par lots.",
  "provision.tenantspace.option.month_12": "D\u00E9cembre",
  "provision.callcenterinstanceslist.table.operate": "Op\u00E9ration",
  "provision.tenant.management.phonenumber": "Num\u00E9ro de t\u00E9l\u00E9phone",
  "provision.tenantspace.option.month_11": "Novembre",
  "provision.callcenterinstanceslist.config": "Centre d'appels",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenter": "Centre d'appel virtuel",
  "provision.callcent.error.saveccgateway.regAgentNumError": "Le nombre de si\u00E8ges enregistr\u00E9s modifi\u00E9s est inf\u00E9rieur au nombre r\u00E9el de si\u00E8ges attribu\u00E9s.",
  "provision.tenantspace.option.month_10": "Octobre",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIp": "IP du serveur CCUCS",
  "provision.callcent.error.updateaccesscode.fail.syncccmanagement": "\u00C9chec de la modification synchrone des informations de la salle de location",
  "provision.tenantspace.config.chooseDPN": "S\u00E9lectionnez l'un des codes d'acc\u00E8s comme num\u00E9ro d'appel par d\u00E9faut.",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport.description": "Apr\u00E8s avoir activ\u00E9 cette fonctionnalit\u00E9\, vous pouvez personnaliser les num\u00E9ros \u00E0 afficher aux agents et aux abonn\u00E9s (selon que la ligne de num\u00E9ro est connect\u00E9e ou non). Si le code d'acc\u00E8s est vide\, la fonctionnalit\u00E9 est activ\u00E9e par d\u00E9faut.",
  "ccprovision.vcallecenter.error.updateinfo.adminpassword": "Erreur de modification du mot de passe.",
  "provision.encryptinfo.strength": "La force",
  "provision.tenantspace.save.fail.107-107": "ipccbase configproxy utiliser.",
  "provision.tenantspace.obsServer.datasource.databasename": "Nom de la base de donn\u00E9es",
  "provision.tenantspace.save.fail.078modify": "Impossible de modifier l'espace locataire lors de la configuration du syst\u00E8me d'authentification tiers.",
  "provision.tenantspace.common.anonymous.explain": "Activez la fonction d'appel sortant anonyme et activez la fonction d'appel sortant anonyme pour l'espace locataire.",
  "provision.tenantspace.table.update": "Modifier",
  "provision.tenantspace.table.tenantSpaceStatus": "Statut",
  "provision.tenantspace.config.select.commercial.status": "Veuillez s\u00E9lectionner un \u00C9tat commercial.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.DateValidateFailed": "Veuillez s\u00E9lectionner une date sup\u00E9rieure \u00E0 aujourd'hui et inf\u00E9rieure \u00E0 2038-1-19",
  "provision.callcent.success.deletesoftphonenumber.unfinishtask": "Une t\u00E2che de suppression est en cours de traitement. Veuillez r\u00E9essayer plus tard.",
  "provision.callcent.error.updatesoftphonenumber.fail.syncccmanagement": "La synchronisation du softphone pour modifier les informations de la salle de location a \u00E9chou\u00E9.",
  "provision.subscribedetail.emailStatus.value_1": "Soumettre avec succ\u00E8s",
  "provision.callcenterinstance.config.videoCallValidate": "Le nombre maximal d'appels vid\u00E9o simultan\u00E9s doit \u00EAtre sup\u00E9rieur ou \u00E9gal au nombre de si\u00E8ges vid\u00E9o.",
  "provision.tenantspace.option.weekday_sun": "Dimanche",
  "ccprovision.IVRSprogress.config.IPAddress": "Adresse IP",
  "provision.tenantspace.config.softphoneassign.softphonescope.contain": "Le num\u00E9ro de t\u00E9l\u00E9phone logiciel a \u00E9t\u00E9 s\u00E9lectionn\u00E9. Veuillez en saisir un autre.",
  "provision.subscribedetail.emailStatus.value_0": "L'envoi a \u00E9chou\u00E9",
  "provision.callcent.error.createaccesscode.fail.onccgateway": "L'ajout d'un code d'acc\u00E8s \u00E0 la plate-forme a \u00E9chou\u00E9.",
  "provision.label.tenantexport.passValidateFailed": "La valeur doit contenir des lettres\, des chiffres et des caract\u00E8res sp\u00E9ciaux _ {'@'}%.",
  "provision.tenantspace.config.worknoassign.filterworkNo": "Banque de num\u00E9ros de filtrage",
  "provision.tenantspace.ivr.maxChnNum": "Quantit\u00E9 d'appels simultan\u00E9s",
  "provision.callcent.selectcallcentccgateway": "Veuillez s\u00E9lectionner CC-Gateway.",
  "ccprovision.vcallecenter.config.popup.adminworkno": "ID de l'administrateur de la configuration",
  "provision.callcenterinstanceslist.label.maxVideoCallnums": "Max. Appels vid\u00E9o simultan\u00E9s",
  "provision.tenantexport.download": "T\u00E9l\u00E9chargement des informations sur les locataires",
  "provision.callcenterinstanceslist.table.download": "T\u00E9l\u00E9charger",
  "provision.whiteListAddress.outboundcall.sftp.fileserver": "Serveur de fichiers SFTP pour les appels sortants",
  "provision.label.tenantexport.unprocessed": "Non-trait\u00E9",
  "provision.callcenterinstanceslist.ccdis.syncconfim.msg": "Voulez-vous vraiment synchroniser les configurations CCDIS ?",
  "provision.tenantspace.language.cn": "Anglais",
  "ccp.version.versioncode": "Version",
  "provision.sysparam.config.itemname.tips": "Saisissez un nom de param\u00E8tre.",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer.title": "Configuration de la passerelle CC",
  "provision.tenantspace.feature.softphonenounifiedauth": "authentification unifi\u00E9e par num\u00E9ro de softphone",
  "provision.tenantspace.chooseFeature": "S\u00E9lection des fonctionnalit\u00E9s",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.paramserror": "L'information du num\u00E9ro est incorrecte.",
  "provision.appsitesmanagement.modifier": "Modificateur",
  "ccprovision.recorddrive.config.sftp.account": "Compte SFTP",
  "ccprovision.recorddrive.config.sftp.accountpassword": "Mot de passe du serveur SFTP",
  "provision.callcent.label.close": "Fermer",
  "provision.tenant.management.tenantid": "ID du locataire",
  "provision.spinfo.addspinfofailed": "Impossible d'ajouter les informations SP.",
  "provision.vcallcenter.message.unknowsolution": "Contactez les ing\u00E9nieurs de Huawei pour analyser et corriger la panne",
  "provision.callcent.error.updatesipserver.ipandport.alreadyexist": "L'adresse IP et le num\u00E9ro de port du serveur SBC existent d\u00E9j\u00E0 et ne peuvent pas \u00EAtre modifi\u00E9s.",
  "provision.tenantspace.save.fail.123004": "Les \u00E9l\u00E9ments de comp\u00E9tence sont r\u00E9f\u00E9renc\u00E9s par la file d'attente de comp\u00E9tences et le nom ne peut pas \u00EAtre modifi\u00E9.",
  "provision.tenantspace.save.fail.123003": "Les \u00E9l\u00E9ments de comp\u00E9tence sont r\u00E9f\u00E9renc\u00E9s par la file d'attente de comp\u00E9tences et ne peuvent pas \u00EAtre supprim\u00E9s.",
  "provision.tenantspace.feature.emailMarketingSupport": "Marketing par courriel",
  "provision.tenantspace.save.fail.123002": "L'\u00E9l\u00E9ment de comp\u00E9tence n'existe pas.",
  "provision.tenantspace.save.fail.123001": "Dupliquer le nom de l'\u00E9l\u00E9ment de comp\u00E9tence.",
  "provision.tenantspace.agentinfo.message.audio": "audio",
  "provision.tenantspace.intelligent.agent.description": "Agent intelligent \: prend en charge la communication de canal de base\, l'analyse s\u00E9mantique de texte et la recommandation intelligente. Configurez le nombre d'agents avant de configurer les agents intelligents. Configurez les ressources ASR pour les agents intelligents qui prennent en charge les sessions vocales.",
  "provision.tenantspace.reset": "R\u00E9initialiser",
  "provision.tenantspace.config.select.vitual.ccname": "Veuillez entrer le nom du centre d'appel virtuel.",
  "provision.softPhone.table.forcemodifyconfim.message": "Cette op\u00E9ration r\u00E9initialisera le mot de passe du softphone. Pour changer le mot de passe\, vous devez vous connecter \u00E0 l'espace locataire. \u00CAtes-vous s\u00FBr de vouloir continuer ?",
  "provision.tenantspace.option.weeklist_tidtolast": "Dernier avant deux",
  "provision.tenantspace.config.select.whiteRepeate": "Num\u00E9ro de liste de confiance en double.",
  "provision.callcenter.videoapplicationmodel.mcuAndsfuModel": "Vid\u00E9o MCU+SFU",
  "provision.title.select": "S\u00E9lectionner",
  "provision.tenantspace.feature.cobrowse.selectcobrowsevalue.invalid": "Le nombre maximum de connexions de co-navigation varie de 1 \u00E0 50000.",
  "provision.tenantspace.create.basicInfo": "Informations de base",
  "provision.tenantspace.feature.forceVideoSelfRecord.attendant": "Activ\u00E9 pour tous les pr\u00E9pos\u00E9s",
  "provision.tenantspace.table.maxAgents": "Max. Agents vocaux",
  "provision.tenantspace.create.tenantExpiryDate": "D\u00E9lai d'expiration du locataire",
  "provision.tenantspace.authenticationsystem.selectClientName": "S\u00E9lectionnez un syst\u00E8me d'authentification tiers.",
  "provision.callcenterinstanceslist.vcallcenter.nodepolicy": "Configuration de la politique de s\u00E9lection de noeud pour les ressources d'appel sortant",
  "provision.tenantspace.selectStastus.edit.stop": "Suspendre",
  "provision.tenantspace.config.chooseAnonymousCallServer": "S\u00E9lectionnez un serveur d'appel anonymea",
  "provision.spinfo.msg.certificateformatinfo": "Les identifiants de d\u00E9but et de fin -----BEGIN CERTIFICATION--- et ----- FIN CERTIFICATION--- ne peuvent pas \u00EAtre omis.",
  "provision.callcenterinstanceslist.sipServer.webRtcanonymousCall": "Appel anonyme WebRTC",
  "provision.tenantspace.popuppageurl.tableheadname": "Adresse",
  "provision.nodepolicy.save.failed": "L'enregistrement a \u00E9chou\u00E9.",
  "promotion.tenant.management.tenantedit.vcccapacity.insufficient": "La capacit\u00E9 du centre virtuel est insuffisante.",
  "provision.tenantspace.config.choosedatasource": "S\u00E9lectionnez une source de donn\u00E9es OBS.",
  "provision.callcenterinstanceslist.config.maxAmsCallNumsValidate": "Le nombre maximum d'appels anonymes simultan\u00E9s ne peut pas \u00EAtre inf\u00E9rieur au nombre d'appels anonymes allou\u00E9s par le centre d'appels.",
  "provision.tenantspace.ExpireTenantSpaceCount": "Nombre de locataires qui sont sur le point d'expirer dans les 30 jours",
  "provision.tenantspace.dataUpload.resourceDump": "Dump de ressources",
  "provision.tenantspace.selectStastus.resource.allocation.failed": "Impossible d'allouer la ressource",
  "provision.systemparam.error.validate.notmatchrulerequired": "Le param\u00E8tre d'entr\u00E9e est requis",
  "provision.encryptinfo.mode": "Mode",
  "provision.callcenterinstanceslist.ccdis.ccdisNetId": "ID NE actif",
  "provision.tenantspace.agentinfo.agentstatus.offline": "Hors ligne",
  "provision.tenantspace.chooseobsdatasource.error1": "S\u00E9lectionnez un serveur OBS.",
  "provision.tenant.management.updatetenant.success": "La configuration de l'espace locataire est modifi\u00E9e avec succ\u00E8s.",
  "provision.accesscode.table.endNumber": "Code d'acc\u00E8s de fin",
  "provision.tenantspace.config.agentusage.ivr": "Quantit\u00E9 d'agent vocal\, Quantit\u00E9 d'agent vid\u00E9o\, Quantit\u00E9 d'agent multim\u00E9dia\, Quantit\u00E9 d'agent polyvalent\, Quantit\u00E9 de canal audio IVR et la quantit\u00E9 de canal vid\u00E9o IVR ne peut pas tous \u00EAtre z\u00E9ro",
  "provision.callcenterinstanceslist.sipServer.sipServerType": "Type de serveur SBC",
  "provision.systemparam.error.validate.notmatchrulerangelength": "Le param\u00E8tre d'entr\u00E9e d\u00E9passe la longueur maximale ou est inf\u00E9rieur \u00E0 la longueur minimale",
  "provision.log.downloadSK": "t\u00E9l\u00E9charger SK",
  "provision.tenantspace.config.worknoassign.availableworknocount": "Num\u00E9ros disponibles",
  "provision.tenantspace.sip.port": "Port du serveur SIP",
  "provision.tenant.management.DSTStartTime": "Heure de d\u00E9but DST",
  "provision.callcenterinstanceslist.table.nodemoderesource": "Mode de ressource",
  "provision.tenantspace.feature.VoiceNotificationSupport.description": "Cette fonctionnalit\u00E9 fournit des fonctions de notification vocale pour l'espace locataire.",
  "ccprovision.IVRSprogress.config.button.delete": "Supprimer",
  "provision.callcent.error.accesscode.deletefail.ondatabase": "Impossible de supprimer le code d'acc\u00E8s dans la base de donn\u00E9es",
  "provision.tenantspace.config.worknoassign.add": "Ajouter",
  "provision.label.tenantexport.inputzippwd": "Entrez le mot de passe de compression.",
  "provision.tenantspace.config.softphoneassign": "Attribuer un num\u00E9ro de t\u00E9l\u00E9phone logiciel",
  "provision.subscribedetail.button.update": "Modifier",
  "provision.tenantspace.create.account": "Informations sur le compte",
  "provision.tenantspace.save.oldsuccess": "Les informations de fuseau horaire ne sont pas modifi\u00E9es.",
  "provision.tenantspace.timezone": "Fuseau horaire par d\u00E9faut",
  "provision.tenant.management.tenantcreate.mobileillegal": "Num\u00E9ro de mobile non valide.",
  "provision.spinfo.selecdeletetspinfo": "Veuillez s\u00E9lectionner les informations SP \u00E0 supprimer.",
  "provision.tenantspace.selectStastus.config": "\u00C0 configurer",
  "provision.tenantspace.chooseCC": "S\u00E9lectionnez un centre d'appels",
  "provision.tenantspace.ivr.ivrServer": "Adresse IP",
  "provision.callcent.error.savevccfirst": "L'agent n'est pas li\u00E9 au serveur d'enregistrement. Sauvegardez \u00E0 nouveau le VDN.",
  "provision.appsitesmanagement.details": "Voir",
  "provision.callcenterinstanceslist.softPhone.accountpassword": "Mot de passe du compte",
  "provision.tenantspace.feature.multiChannelSupport.description": "La fonction Prise en charge multicanal est activ\u00E9e. Un ajustement temporaire de la file d'attente peut \u00EAtre effectu\u00E9 pour les agents sur l'ensemble des canaux de service. Remarque \: Si un agent dispose de l'autorisation de canal multiservice\, il occupe les ressources vocales\, vid\u00E9o et multim\u00E9dia en m\u00EAme temps pendant la connexion. L'agent sous l'autorit\u00E9 d'un locataire peut ne pas ouvrir de session pour le service client en raison de la pr\u00E9emption des ressources.",
  "provision.callcenterinstanceslist.label.videoCallnumUseRate": "Utilisation d'appels vid\u00E9o simultan\u00E9s",
  "provision.systemparam.error.validate.inner": "\u00C9chec de la validation du param\u00E8tre",
  "provision.callcent.selectsipserver": "S\u00E9lectionnez un serveur d'enregistrement d'agent.",
  "provision.callcent.error.deleteucsserver.part.associationvcallcener": "Certains serveurs CCUCS s\u00E9lectionn\u00E9s sont associ\u00E9s \u00E0 des locataires et ne peuvent pas \u00EAtre supprim\u00E9s.",
  "provision.tenantspace.config.maxVideoCalls": "Quantit\u00E9 d'appels vid\u00E9o simultan\u00E9s",
  "provision.subscribedetail.error.query.maxDay_30": "L'intervalle d'interrogation ne peut pas d\u00E9passer 30 jours.",
  "provision.callcenterinstanceslist.config.updatefailed": "Impossible de modifier le centre d'appels.",
  "provision.sysparam.config.itemdesc": "Descriptif",
  "provision.subscribedetail.button.save": "Enregistrer",
  "provision.tenantspace.timezone.updateconfim": "Confirmer",
  "provision.callcenterinstanceslist.softPhone.tenantname": "Nom du locataire",
  "provision.callcenterinstanceslist.vcallcenter.invalidVdnListPromt": "Il y a des VDN dans le WAS o\u00F9 le centre d'appel virtuel est manquant dans l'AICC. Le VdnID est",
  "provision.tenantspace.feature.intelligent.inspection.description": "Activez la fonction d'inspection intelligente et activez la fonction d'inspection intelligente pour l'espace locataire.",
  "provision.tenantspace.table.deleteTenantSpace": "\u00CAtes-vous s\u00FBr de vouloir supprimer l'espace locataire sp\u00E9cifi\u00E9 ?",
  "provision.tenantspace.step6": "\u00C9tape 6",
  "provision.tenantspace.step5": "\u00C9tape 5",
  "provision.tenantspace.step7": "\u00C9tape 7",
  "provision.tenantspace.step3": "\u00C9tape 3",
  "provision.callcenterinstanceslist.obsServer.obsServerIpSync": "Synchroniser",
  "provision.tenantspace.step4": "\u00C9tape 4",
  "provision.tenantspace.step2": "\u00C9tape 2",
  "provision.tenantspace.cc.videoAgentchannels": "Quantit\u00E9 de canal d'agent vid\u00E9o",
  "provision.tenant.management.tenantcreate.contact": "M\u00E9thode de contact",
  "provision.tenantspace.step1": "\u00C9tape 1",
  "provision.tenant.chooseCC.first": "S\u00E9lectionnez un centre d'appels.",
  "provision.tenantspace.config.select.default.whiteNumber": "Le nombre de num\u00E9ros de confiance ne peut pas \u00EAtre sup\u00E9rieur \u00E0 5 par d\u00E9faut.",
  "provision.tenantspace.cc.percent": "Proportion de capacit\u00E9",
  "provision.whiteListAddress.resourcedump.obs": "Serveur OBS de vidage de ressources",
  "provision.callcenterinstanceslist.config.updatesuccess": "Le centre d'appels est modifi\u00E9 avec succ\u00E8s.",
  "provision.callcent.error.saveucsserveripinfo": "\u00C9chec de la cr\u00E9ation\, Assurez-vous que l'adresse IP CCUCS et le num\u00E9ro de port du serveur n'existent pas.",
  "provision.tenantspace.cc.ttschannelnums": "Quantit\u00E9 TTS",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passLengthValidateFailed": "Un mot de passe contient un minimum de huit caract\u00E8res.",
  "provision.callcenterinstanceslist.obsServer.activeObsServerIp": "Active Obs Server Ip",
  "provision.appsitesmanagement.enable": "Oui",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment": "Envoi local de pi\u00E8ces jointes par e-mail Agents",
  "provision.tenantspace.deleteWhiteNumber.confirm": "confirmer la suppression",
  "provision.callcenterinstanceslist.vcallcenter.InProgress": "En cours.",
  "provision.callcenterinstanceslist.table.create": "Nouveau",
  "provision.tenantspace.config.gw": "Serveur de passerelle",
  "provision.tenantspace.detail.tenantspace.status.not.change": "Le locataire est inactif et ne peut pas \u00EAtre modifi\u00E9.",
  "provision.systemparam.algorithm.unsafe": "Un algorithme non s\u00E9curis\u00E9 est utilis\u00E9\, ce qui pose des risques de s\u00E9curit\u00E9",
  "provision.tenantspace.modifyTimeZoneVerification": "Les informations de fuseau horaire peuvent \u00EAtre modifi\u00E9es uniquement pour un espace locataire \u00E0 l'\u00E9tat commercial ou commercial d'essai.",
  "provision.encryptinfo.modedecrease": "Le mode CBC est moins s\u00FBr que le mode GCM. Faites preuve de prudence lorsque vous s\u00E9lectionnez le mode CBC.",
  "provision.tenantspace.ucs.name": "Nom du serveur CCUCS",
  "provision.callcenterinstanceslist.label.amsCallNumUseRate": "Utilisation simultan\u00E9e d'appels anonymes",
  "provision.callcent.error.createaccesscode.validate.servicenumberContainServicenumber": "Le num\u00E9ro de service ne peut pas avoir de relation d'inclusion avec d'autres num\u00E9ros de service sous le centre d'appels.",
  "provision.tenantspace.table.asrchannelnums": "Quantit\u00E9 ASR",
  "provision.tenantspace.feature.emailAgentForwarding": "Transfert d'agent de courrier",
  "provision.recorddrive.label.isfirstletter.no": "Non",
  "provision.vcallcenter.message.unknowdesc": "Exception inconnue.",
  "provision.sysparam.config.item.detail": "Informations sur les param\u00E8tres",
  "provision.label.tenantexport.exception": "Une exception s'est produite lors de la g\u00E9n\u00E9ration de la t\u00E2che d'exportation. Veuillez v\u00E9rifier le journal.",
  "provision.callcenterinstanceslist.vcallcenter.accessCodeConfig": "Configuration de AccessCode",
  "provision.ivr.selectivrprogress.singledeleteconfim": "Voulez-vous vraiment supprimer le processus SVI s\u00E9lectionn\u00E9 ?",
  "provision.callcenterinstanceslist.vcallcenter.vdnName": "Nom du VDN",
  "provision.tenantspace.option.weekday_fri": "Vendredi",
  "provision.callcenterinstanceslist.vcallcenter.maxMediaAgents": "Nombre d'agents multim\u00E9dias",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer.title": "Configuration du serveur CCUCS",
  "provision.callcenterinstanceslist.config.title": "Configuration du centre d'appels",
  "provision.tenantspace.agentusage.plus.videousage.plus.maxVersatileAgents": "Le nombre total d'agents vocaux\, vid\u00E9o et polyvalents ne peut d\u00E9passer 10000.",
  "promotion.tenant.management.tenantedit.vcc.notexist": "Le centre d'appel virtuel n'existe pas.",
  "provision.callcent.error.updatesipserver.fail.syncccmanagement": "La synchronisation du serveur SBC pour modifier les informations vers la salle de location a \u00E9chou\u00E9.",
  "provision.tenantspace.table.maxAmsCalls": "Max. Appels Anonymes Concurrents",
  "provision.tenantspace.accesscode.searchName": "S'il vous pla\u00EEt entrer le code d'acc\u00E8s pour \u00EAtre interrog\u00E9.",
  "provision.callcenterinstanceslist.obsServer.config.explain": "La valeur doit \u00EAtre la m\u00EAme que la configuration OBS sur la plate-forme CTI.",
  "provision.tenant.management.agentnum": "Agents",
  "provision.tenantspace.feature.speechAnalysis": "analyse de la parole",
  "provision.systemparam.algorithm.suggest": "Attention\: \u00EAtre coh\u00E9rent avec UAP",
  "provision.callcent.error.savesipserverdesinfo.regAgentNumError": "Le nombre d'agents enregistr\u00E9s simultan\u00E9ment modifi\u00E9s est inf\u00E9rieur au nombre r\u00E9el de si\u00E8ges enregistr\u00E9s.",
  "provision.tenantspace.feature.open": "Activer",
  "provision.tenantspace.common.moveagent.explain": "Le syst\u00E8me attribue un num\u00E9ro de t\u00E9l\u00E9phone logiciel au locataire. Le syst\u00E8me du locataire utilise le num\u00E9ro de t\u00E9l\u00E9phone logiciel pour s'inscrire aupr\u00E8s du serveur du syst\u00E8me AICC. Le syst\u00E8me du locataire appelle l'interface ouverte fournie par le syst\u00E8me AICC pour mettre en \u0153uvre la fonction de rappel bidirectionnel. (Nombre maximum d'agents mobiles allocables \= quantit\u00E9 de canal ivr audio / 2\, arrondir).",
  "provision.tenantspace.feature.close": "D\u00E9sactiver",
  "provision.tenantspace.table.invalidTenantSpace.confirm": "Confirmer la r\u00E9vocation",
  "provision.ccp.redis.feature.success": "Les donn\u00E9es de caract\u00E9ristiques de locataire sont ex\u00E9cut\u00E9es avec succ\u00E8s.",
  "provision.callcenterinstanceslist.vcallcenter.maxAgents": "Nombre d'agents vocaux",
  "provision.tenantspace.config.select.record.time": "Veuillez s\u00E9lectionner une p\u00E9riode de conservation d'enregistrement.",
  "provision.callcenterinstanceslist.table.ccName": "Nom du centre d'appels",
  "provision.callcent.error.updateccgateway.regAgentNumError": "La mise \u00E0 jour \u00E9choue\, le nombre de si\u00E8ges enregistr\u00E9s modifi\u00E9s est inf\u00E9rieur au nombre r\u00E9el de si\u00E8ges attribu\u00E9s.",
  "provision.tenantspace.config.maxVideoAgents": "Quantit\u00E9 d'agent vid\u00E9o",
  "provision.tenantspace.save.fail.120004": "L'op\u00E9ration a \u00E9chou\u00E9 car le nombre de VDN activ\u00E9s atteint le maximum.",
  "provision.tenantspace.save.fail.120005": "\u00C9chec de la v\u00E9rification de l'\u00E9l\u00E9ment de configuration.",
  "provision.tenantspace.save.fail.120006": "Le nombre maximum de threads.",
  "provision.tenantspace.save.fail.120003": "La configuration n'existe pas.",
  "provision.tenantspace.save.fail.120002": "La configuration existe d\u00E9j\u00E0.",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport": "Anonymisation du num\u00E9ro d'appel",
  "provision.tenantspace.save.fail.120001": "Le nombre maximum de lots est de 500.",
  "provision.tenantspace.save.fail.120000": "Erreur d'interface.",
  "provision.tenantspace.selectStastus.invalid": "D\u00E9sactiv\u00E9",
  "provision.systemparam.error.validate.notmatchrule": "Le param\u00E8tre d'entr\u00E9e ne correspond pas \u00E0 la r\u00E8gle de v\u00E9rification",
  "provision.tenantspace.feature.multiChannelSupport": "Prise en charge de plusieurs canaux",
  "ccp.version.updateTime": "UpdateTime",
  "ccp.version.microservice": "Microservice",
  "provision.callcenterinstanceslist.obsServer.standbyObsServerIp": "Veille Obs Server Ip",
  "provision.callcenterinstanceslist.sipServer.tenantname": "Nom du locataire",
  "provision.spinfo.deletespinfosuccess": "Informations SP supprim\u00E9es avec succ\u00E8s.",
  "provision.tenantspace.error.deleteConfigTenantSpace": "Des ressources ont \u00E9t\u00E9 allou\u00E9es aux locaux locataires. Vous ne pouvez pas supprimer l'espace locataire.",
  "provision.callcenterinstanceslist.config.warning": "Avertissement",
  "provision.tenantspace.agentinfo.message.batchdeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer tous les agents s\u00E9lectionn\u00E9s ?",
  "provision.spinfo.validatehttp": "Le format de l'URL est incorrect.",
  "provision.tenantspace.modifyPassword": "R\u00E9initialiser le mot de passe",
  "provision.tenantspace.obsServer.datasource.type": "Type de source de donn\u00E9es",
  "provision.tenantspace.config.intelligent.agentNumber.not.exceed.agentNumber": "Le nombre d'agents intelligents attribu\u00E9s \u00E0 un locataire ne peut pas \u00EAtre sup\u00E9rieur au nombre total d'agents.",
  "provision.ccworknos.table.ccworkQuantity": "Nombre Quantit\u00E9 dans le segment de nombre",
  "provision.callcent.error.specialStrValidateFailed": "Le nom de l'obs ne peut pas contenir de caract\u00E8res sp\u00E9ciaux.",
  "provision.tenantspace.feature.emailMarketingSupport.description": "Cette fonctionnalit\u00E9 fournit des fonctions de marketing par e-mail pour l'espace locataire.",
  "provision.tenantspace.feature.whatsAppMarketingSupport.description": "Fournir des fonctionnalit\u00E9s de marketing WhatsApp pour les espaces locataires.",
  "provision.tenantspace.feature.whatsAppMarketingSupport": "Marketing par WhatsApp",
  "provision.tenantspace.config.param.error.ivrNumber": "Veuillez saisir le nombre de canaux IVR.",
  "provision.tenantspace.selectvideomodel.isnull": "S\u00E9lectionnez un mode d'application vid\u00E9o pour la ressource vid\u00E9o demand\u00E9e par le locataire.",
  "provision.callcent.error.updateccgateway.ccgatewayServernoexist": "\u00C9chec de la mise \u00E0 jour\, le serveur CC-Gateway n'existe pas.",
  "provision.tenantspace.feature.forceVideoSelfRecord.settingError": "Les param\u00E8tres d'enregistrement d'\u00E9cran sont mal configur\u00E9s.",
  "provision.tenant.management.positiveInteger.checkmsg": "Entrez un entier positif.",
  "provision.callcenterinstanceslist.table.gwPort": "Port CC-Gateway",
  "provision.callcenterinstanceslist.table.maxAmsCallNums": "Max. Appels Anonymes Concurrents",
  "provision.tenantspace.worknoValidatefaild": "La WorkNo est obligatoire.",
  "provision.tenantspace.agentinfo.message.versatile": "Polyvalent",
  "provision.accesscode.table.accessCode.clicktodial": "Cliquez pour composer",
  "provision.callcenterinstanceslist.config.videoAgentCurCapacityValidate": "Le nombre de si\u00E8ges vid\u00E9o est inf\u00E9rieur au nombre de si\u00E8ges vid\u00E9o effectivement attribu\u00E9s dans le centre d'appels.",
  "provision.callcent.error.updatesipserver.ondatabase": "La modification du serveur SBC dans la base de donn\u00E9es a \u00E9chou\u00E9.",
  "provision.tenantspace.option.weeklist_fth": "Quatri\u00E8me",
  "provision.tenantspace.search": "Requ\u00EAte",
  "provision.callcenterinstanceslist.vcallcenter.plateformServer": "Serveur de plate-forme",
  "provision.callcent.error.accesscode.deletefail": "La suppression a \u00E9chou\u00E9\, l'entr\u00E9e est vide",
  "provision.tenantspace.cc.videoAgentchannelUsagePercent": "Utilisation du canal de l'agent vid\u00E9o",
  "provision.accesscode.table.tenantname": "Locataire",
  "provision.tenantspace.accessCode.choose": "S\u00E9lectionnez un groupe de codes d'acc\u00E8s.",
  "provision.callcent.error.deletesipserver.associationvcallcener": "Le serveur SIP s\u00E9lectionn\u00E9 est associ\u00E9 au locataire et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.subscribedetail.enableStatus.value_1": "S'abonner avec succ\u00E8s",
  "provision.subscribedetail.enableStatus.value_0": "L'inscription a \u00E9chou\u00E9",
  "provision.callcenterinstanceslist.obsServer.batchdeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer tous les serveurs d'obs d'enregistrement s\u00E9lectionn\u00E9s?",
  "provision.label.tenantexport.failed": "\u00C9chec",
  "provision.callcenterinstanceslist.config.alreadyinuse": "Le segment d'identification de l'employ\u00E9 a \u00E9t\u00E9 utilis\u00E9.",
  "provision.tenant.management.batchcreate": "Les espaces locataires sont cr\u00E9\u00E9s par lots.",
  "provision.callcent.error.partSetinfo": "La s\u00E9lection du jeu de pi\u00E8ces a \u00E9chou\u00E9.",
  "provision.callcent.platformserver.account": "Compte de service",
  "provision.callcenterinstanceslist.sipServer.sipServerIpUpdate": "Configuration du serveur SIP",
  "provision.tenantspace.selectStastus.CommercialUse": "Utilisation commerciale",
  "provision.tenant.management.select.accesscode": "S\u00E9lectionnez le code d'acc\u00E8s",
  "provision.callcenterinstanceslist.sipServer.webRTC": "WebRTC",
  "provision.common.message.error": "Erreurs",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoCalls": "MaxVideoCalls",
  "provision.tenantspace.common.agent.number": "Quantit\u00E9 d'agent intelligent",
  "provision.spinfo.modifyspinfofailed": "La modification des informations SP a \u00E9chou\u00E9.",
  "provision.callcent.selectamsserver.inconsistenttype": "Le type de serveur d'appel anonyme n'est pas coh\u00E9rent",
  "provision.whitepopuppageurl.protocol.check": "Notez que le protocole r\u00E9seau utilis\u00E9 par l'adresse pop-up n'est pas un protocole s\u00E9curis\u00E9. Si vous d\u00E9cidez d'utiliser ce protocole\, des risques de s\u00E9curit\u00E9 peuvent exister.",
  "provision.subscribedetail.validate.laterThanEndTime": "L'heure de d\u00E9but ne peut pas \u00EAtre post\u00E9rieure \u00E0 l'heure de fin.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.wasPsdLengthRangeValidateFailed": "La longueur du mot de passe doit \u00EAtre comprise entre 16 et 32",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoAgents": "Nombre d'agents vid\u00E9o",
  "provision.callcent.error.savesipserveripinfo": "Assurez-vous que l'adresse IP et le num\u00E9ro de port du serveur n'existent pas.",
  "provision.tenantspace.config.softphone.Validatefaild": "Num\u00E9ro de softphone non allou\u00E9",
  "provision.tenantspace.feature.forceVideoSelfRecord.description": "Indique s'il faut enregistrer automatiquement l'\u00E9tat de fonctionnement de l'\u00E9cran lorsqu'un agent traite un appel.",
  "provision.tenantspace.cc.callnumUsagePercent": "Utilisation des appels vocaux simultan\u00E9s",
  "provision.sysparam.config.select.prompt": "Astuces",
  "provision.callcenter.videoapplicationmodel": "Mode d'application vid\u00E9o",
  "provision.tenantspace.table.activeTenantSpace.confirm": "Confirmer l'activation",
  "provision.tenant.management.tenantcreate": "Nouveau locataire",
  "provision.callcentaccesscodes.table.callcentaccesscodes": "Code d'acc\u00E8s",
  "provision.tenantspace.detail.expiry.time": "Temps d'expiration",
  "provision.callcenterinstanceslist.vcallcenterDeatil.saveerror": "L'enregistrement a \u00E9chou\u00E9.",
  "provision.callcenterinstanceslist.config.agentCurCapacityValidate": "Le nombre d'agents vocaux est inf\u00E9rieur au nombre de si\u00E8ges vocaux effectivement attribu\u00E9s par le centre d'appels.",
  "provision.tenantspace.resetpwdfaild.accountpassword.error": "Le mot de passe actuel du compte est incorrect.",
  "provision.callcent.selectaccesscode": "S\u00E9lectionnez un code d'acc\u00E8s.",
  "provision.tenantspace.tenantid": "ID de location actuel.",
  "provision.callcent.error.deleteucsserver.associationvcallcener": "Le serveur CCUCS s\u00E9lectionn\u00E9 est associ\u00E9 au locataire et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.subscribedetail.delete.confirm": "\u00CAtes-vous s\u00FBr de vouloir supprimer les d\u00E9tails de l'abonnement ?",
  "provision.tenantspace.save.fail.120663": "La configuration appel\u00E9e est r\u00E9f\u00E9renc\u00E9e par l'agent et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.appversionmanagement.sureDeleteData": "\u00CAtes-vous s\u00FBr de vouloir supprimer les donn\u00E9es ?",
  "provision.tenantspace.save.fail.120662": "La configuration appel\u00E9e est r\u00E9f\u00E9renc\u00E9e par la file d'attente de comp\u00E9tences et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.callcenterinstanceslist.config.odfsconfig": "Configuration du serveur ODFS",
  "provision.tenantspace.save.fail.120660": "Le code d'acc\u00E8s au syst\u00E8me configur\u00E9 par la partie appel\u00E9e n'est pas coh\u00E9rent avec le type de support du dispositif de configuration appel\u00E9.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.alert": "Le nombre maximum de messages pouvant \u00EAtre envoy\u00E9s chaque jour varie de 1 \u00E0 1000000000. La valeur 0 indique qu'aucune limite n'est sp\u00E9cifi\u00E9e.",
  "provision.tenantspace.save.fail.120661": "La configuration appel\u00E9e est r\u00E9f\u00E9renc\u00E9e par la file d'attente priv\u00E9e de comp\u00E9tences par d\u00E9faut et ne peut pas \u00EAtre supprim\u00E9e.",
  "ccprovision.recorddrive.config.sftp.accountconfirmpasd": "Serveur SFTP Confirmer le mot de passe",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.alert": "Le nombre maximum de messages pouvant \u00EAtre envoy\u00E9s chaque jour varie de 1 \u00E0 100000. La valeur 0 indique qu'aucune limite n'est sp\u00E9cifi\u00E9e.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.placeholder": "La valeur varie de 1 \u00E0 100000. La valeur 0 indique qu'aucune limite n'est sp\u00E9cifi\u00E9e.",
  "provision.tenantspace.gw.ServerIP": "IP du serveur de passerelle",
  "provision.subscribedetail.label.companySize": "Taille de la soci\u00E9t\u00E9",
  "provision.tenantspace.feature.agenttype": "Type d'agent",
  "provision.tenantspace.save.fail.120659": "Le script de routage r\u00E9f\u00E9renc\u00E9 par la configuration appel\u00E9e n'existe pas.",
  "provision.tenantspace.save.fail.120658": "Le type de p\u00E9riph\u00E9rique est IVR\, et le type de m\u00E9dia du code d'acc\u00E8s ne peut \u00EAtre que clic pour appeler\, demande de rappel et appel normal.",
  "provision.callcenterinstanceslist.config.ccIdUpdateError": "Ne changez pas l'ID du centre d'appels lors de la modification du centre d'appels",
  "provision.tenant.management.timezone": "Fuseau horaire",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer.title": "Configuration du serveur SBC",
  "provision.tenantspace.save.fail.120656": "La file d'attente de comp\u00E9tences r\u00E9f\u00E9renc\u00E9e par la configuration appel\u00E9e n'existe pas.",
  "provision.tenantspace.save.fail.120655": "Le code d'acc\u00E8s r\u00E9f\u00E9renc\u00E9 par la configuration appel\u00E9e n'existe pas.",
  "provision.tenantspace.save.fail.120652": "La combinaison du code d'acc\u00E8s au syst\u00E8me et des codes d'acc\u00E8s subs\u00E9quents configur\u00E9s par la partie appel\u00E9e ne peut pas \u00EAtre r\u00E9p\u00E9t\u00E9e.",
  "provision.tenantspace.intelligent.ivr.explain": "IVR intelligent \: En plus de la fonction traditionnelle d'IVR\, IVR de navigation intelligente soutenant la reconnaissance vocale et la compr\u00E9hension s\u00E9mantique.",
  "provision.tenantspace.config.chooseworkNo": "S\u00E9lectionnez WorkNo",
  "provision.encryptinfo.version": "Version",
  "provision.appversionmanagement.pleaseEnter": "-Entrez-",
  "provision.tenantspace.save.fail.120651": "Le code d'acc\u00E8s au syst\u00E8me configur\u00E9 par la partie appel\u00E9e n'est pas attribu\u00E9.",
  "provision.callcenterinstanceslist.table.hasaccesscodeAndivrConfim": "La suppression \u00E9choue. Il y a un code d'acc\u00E8s ou IVR dans le centre d'appels. Vous devez le supprimer manuellement.",
  "provision.tenantspace.feature.ivrtype": "Type d'IVR",
  "provision.subscribedetail.success.title": "Succ\u00E8s",
  "provision.callcenterinstanceslist.obsServer.obsServerIpAdd": "Ajouter",
  "provision.callcent.error.updateaccesscode.fail.accesscodenoexist": "V\u00E9rifiez si le code d'acc\u00E8s existe et est associ\u00E9 \u00E0 un centre d'appels virtuel.",
  "provision.callcent.error.savesipserverdesinfo.maxRegAgentNumError": "Le nombre d'agents enregistr\u00E9s simultan\u00E9ment modifi\u00E9s est inf\u00E9rieur au nombre r\u00E9el de si\u00E8ges enregistr\u00E9s.",
  "provision.callcenterinstanceslist.table.ctinodesec": "Informations sur le n\u0153ud CTI 2",
  "provision.tenant.management.tenantcreate.provision.failed": "Impossible de cr\u00E9er l'espace locataire local.",
  "provision.callcenterinstanceslist.callcenter": "Centre d'appels",
  "provision.grayconfig.tenantHasConfig": "L'espace locataire a \u00E9t\u00E9 configur\u00E9",
  "provision.callcent.error.addcallcenterdesinfo": "Assurez-vous que le centre d'appels n'existe pas.",
  "provision.log.downloadIdpMetadtaXml": "T\u00E9l\u00E9charger IDP Metadata Xml",
  "provision.subscribedetail.validate.beginwithalpha": "doit commencer par une lettre.",
  "provision.callcenterinstanceslist.ucsServer.batchdeleteconfim": "\u00CAtes-vous s\u00FBr de vouloir supprimer tous les serveurs CCUCS s\u00E9lectionn\u00E9s?",
  "provision.tenantspace.Conversioncommercial": "Conversion Commerciale",
  "provision.tenantspace.config.giveSource": "Allouer la ressource",
  "provision.tenantspace.option.weekday_sat": "Samedi",
  "provision.callcenter.videoapplicationmodel.mcumodel": "Vid\u00E9o MCU",
  "provision.tenantspace.sfuprompt": "La connexion d'int\u00E9gration du syst\u00E8me n'est pas adapt\u00E9e SFU vid\u00E9o\, Les clients peuvent s'adapter \u00E0 la vid\u00E9o en mode de d\u00E9veloppement secondaire.",
  "ccprovision.recorddrive.config.sftp.identitychecktips.placeholder": "Entrez votre compte actuel et votre mot de passe.",
  "provision.tenantspace.feature.cobrowse.description": "Cette fonction fournit la fonction de co-navigation pour l'espace locataire.",
  "provision.callcent.error.modifyParamUnique": "Des ObsServerIp actives existent.",
  "provision.callcent.error.savesipserverdesinfo.sipServernoexist": "Aucun serveur sbc n'existe",
  "provision.callcenter.videoapplicationmodel.sfumodel": "Vid\u00E9o SFU",
  "provision.tenantspace.feature.customData.description": "Une fois la fonction de personnalisation des donn\u00E9es activ\u00E9e\, l'espace locataire prend en charge la fonction de personnalisation des donn\u00E9es.",
  "provision.accesscode.table.callout": "Appel sortant",
  "provision.ivr.selectivrprogress.batchdeleteconfim": "Voulez-vous vraiment supprimer tous les processus SVI s\u00E9lectionn\u00E9s ?",
  "provision.tenantspace.intelligent.agent.explain": "Agent intelligent \: En plus de la fonction de commande vocale de base\, il prend en charge la voix-texte en temps r\u00E9el et la recommandation intelligente relative. Pour configurer les agents intelligents\, vous devez d'abord configurer les agents vocaux et les ressources ASR.",
  "provision.sysparam.config.select.null": "Veuillez s\u00E9lectionner un param\u00E8tre.",
  "provision.tenantspace.feature.case.open.confirmmsg": "La fonction AppCube est la base pour l'ex\u00E9cution de la fonction Case et sera activ\u00E9e en m\u00EAme temps.",
  "provision.tenantspace.feature.newcase.open.customcenter.confirmmsg": "La fonction AppCube et la fonction client sont la base de l'ex\u00E9cution de la fonction Case 2.0 et seront activ\u00E9es en m\u00EAme temps.",
  "provision.tenantspace.save.fail.107-087": "Le num\u00E9ro UAP radi\u00E9 n'est pas enregistr\u00E9.",
  "provision.recorddrive.error.cannotdelete.occupyvdn": "La lettre de lecteur attribu\u00E9e au locataire ne peut pas \u00EAtre supprim\u00E9e",
  "provision.tenantspace.common.agent": "Agent ordinaire",
  "provision.tenantspace.table.batchcreate": "Cr\u00E9ation par lot",
  "provision.tenantspace.save.fail.107-086": "Aucun pr\u00E9fixe d'appel n'est configur\u00E9 pour un num\u00E9ro sur l'UAP.",
  "provision.tenantspace.save.fail.107-084": "Le PAN ne parvient pas \u00E0 obtenir le type de produit.",
  "provision.tenantspace.save.fail.107-085": "Le type de produit UAP ne peut pas \u00EAtre identifi\u00E9.",
  "provision.tenantspace.table.maxVersatileAgents": "Agents polyvalents max",
  "provision.tenantspace.save.fail.107-083": "L'UAP \u00E9choue \u00E0 envoyer la commande MML.",
  "provision.tenantspace.ucs.searchName": "Veuillez entrer le nom du serveur CCUCS \u00E0 interroger.",
  "ccprovision.vcallecenter.config.popup.curcountpassword": "Mot de passe du compte actuel",
  "provision.tenantspace.save.fail.107-082": "Impossible de se connecter \u00E0 l'UAP ou d\u00E9connect\u00E9 de l'UAP.",
  "provision.callcent.platformserver.identitychecktips": "Vous avez chang\u00E9 le mot de passe du serveur. Pour assurer la s\u00E9curit\u00E9 des informations\, veuillez entrer votre compte actuel et votre mot de passe.",
  "provision.tenantspace.save.fail.107-080": "Le nombre de num\u00E9ros UAP d\u00E9passe la limite.",
  "provision.tenantspace.save.fail.107-081": "L'UAP ne v\u00E9rifie pas l'autorisation VDN car le VDN ne contient pas le num\u00E9ro d'utilisateur.",
  "provision.tenantspace.config.choose.agent.registration.server": "Serveur d'enregistrement de l'agent",
  "provision.callcenterinstanceslist.table.ctinodefir": "Informations de n\u0153ud CTI",
  "provision.tenantspace.table.delete": "Supprimer",
  "provision.grayconfig.tenantSpaceId": "locataireSpaceId",
  "provision.tenantspace.resetpwdfaild.newpassword.not.meet.passwordrule": "Le nouveau mot de passe ne r\u00E9pond pas \u00E0 la r\u00E8gle du mot de passe.",
  "provision.tenantspace.save.fail.120624": "Le mot de passe d'amarrage vdn modifi\u00E9 est le m\u00EAme que l'ancien mot de passe.",
  "provision.tenantspace.save.fail.120622": "Le repr\u00E9sentant de l'entreprise est le chef d'\u00E9quipe et ne peut pas effectuer des op\u00E9rations de changement de groupe.",
  "provision.tenantspace.save.fail.120621": "Le mot de passe doit contenir au moins trois des quatre types de caract\u00E8res suivants \: majuscules\, minuscules\, chiffres et caract\u00E8res non alphanum\u00E9riques\, et ils ne peuvent pas \u00EAtre les m\u00EAmes que le num\u00E9ro de compte ou l'ordre inverse du num\u00E9ro de compte.",
  "provision.callcenterinstanceslist.ccdis.ccdisId": "ID",
  "provision.systemparam.error.validate.notmatchrulenumber": "Veuillez entrer un num\u00E9ro",
  "provision.tenantspace.save.fail.120620": "La longueur du mot de passe de l'agent est inf\u00E9rieure \u00E0 la longueur minimale du mot de passe pour la configuration de s\u00E9curit\u00E9 \: {0}.",
  "provision.label.tenantexport.addExportTask": "Ajout d'une t\u00E2che d'exportation",
  "provision.systemparam.error.validate.notmatchruleoption": "Le param\u00E8tre d'entr\u00E9e n'est pas dans la plage d'\u00E9num\u00E9ration",
  "provision.callcent.error.portoutsiderange": "HeartbeatPort est en dehors de la plage de 1 \u00E0 65535.",
  "promotion.tenant.management.tenantedit.vdncreate.failed": "Impossible de cr\u00E9er ou de modifier les informations VDN.",
  "provision.tenantspace.save.fail.107-077": "Le type d'adresse IP UAP n'est pas pris en charge.",
  "provision.tenantspace.save.fail.107-078": "La longueur du num\u00E9ro de d\u00E9but et du num\u00E9ro de fin sur l'UAP est incoh\u00E9rente.",
  "provision.tenantspace.save.fail.107-076": "Le mot de passe UAP est incompatible avec le mot de passe de confirmation.",
  "provision.tenantspace.save.fail.107-079": "Le num\u00E9ro de fin de l'UAP est plus petit que le num\u00E9ro de d\u00E9but.",
  "provision.tenantspace.save.fail.107-074": "Le mot de passe UAP est nul",
  "provision.tenantspace.save.fail.107-075": "La longueur du mot de passe UAP est hors de port\u00E9e.",
  "provision.tenantspace.feature.emailAgentCCAndBcc": "Agent de messagerie CC Bcc",
  "provision.tenantspace.save.fail.107-073": "L'adresse IP UAP est null.",
  "provision.tenantspace.save.fail.107-072": "Num\u00E9ro d'utilisateur UAP non valide.",
  "provision.tenantspace.option.weeklist_sec": "Deuxi\u00E8me",
  "provision.tenantspace.save.fail.107-071": "Le num\u00E9ro d'utilisateur UAP est null.",
  "provision.tenantspace.save.fail.107-070": "Le syst\u00E8me UAP ne parvient pas \u00E0 \u00EAtre radi\u00E9.",
  "provision.tenantspace.save.fail.120618": "Le num\u00E9ro d'emploi du repr\u00E9sentant de l'entreprise et le num\u00E9ro d'emploi du chef d'escouade sont dupliqu\u00E9s.",
  "provision.callcenterinstanceslist.ccdis.ccdisIP": "Adresse IP principale",
  "provision.tenantspace.save.fail.120619": "L'identifiant du repr\u00E9sentant commercial ne peut pas \u00EAtre r\u00E9p\u00E9t\u00E9.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.required": "Entrez le segment d'ID de l'agent \u00E0 ajouter et cliquez sur OK.",
  "provision.tenantspace.save.fail.120617": "La lettre de lecteur r\u00E9f\u00E9renc\u00E9e par l'agent n'existe pas.",
  "provision.tenantspace.save.fail.120616": "La configuration appel\u00E9e r\u00E9f\u00E9renc\u00E9e par l'agent n'existe pas.",
  "provision.tenantspace.save.fail.120614": "L'autre repr\u00E9sentant commercial cit\u00E9 par le repr\u00E9sentant commercial n'existe pas.",
  "provision.tenantspace.save.fail.120613": "Le r\u00F4le d'agent r\u00E9f\u00E9renc\u00E9 par l'agent n'existe pas.",
  "provision.tenantspace.save.fail.120615": "Le proc\u00E9d\u00E9 r\u00E9f\u00E9renc\u00E9 par l'agent n'existe pas.",
  "provision.tenantspace.config.asrchannelnums": "Quantit\u00E9 ASR",
  "provision.tenantspace.save.fail.120612": "Le groupe de comp\u00E9tences r\u00E9f\u00E9renc\u00E9 par l'agent n'existe pas.",
  "provision.grayconfig.tenantSpaceName": "TenantSpaceName",
  "provision.tenantspace.save.fail.120611": "La file d'attente de comp\u00E9tences r\u00E9f\u00E9renc\u00E9e par l'agent n'existe pas.",
  "provision.tenantspace.save.fail.120972": "Le type de sous-m\u00E9dia r\u00E9f\u00E9renc\u00E9 par la file d'attente de comp\u00E9tences n'existe pas.",
  "provision.tenantspace.feature.moveAgents": "Agent mobile",
  "provision.tenantspace.save.fail.120610": "L'\u00E9quipe de demande d'agent n'existe pas.",
  "provision.tenantspace.save.fail.120971": "R\u00E9f\u00E9rence du groupe de comp\u00E9tences de file d'attente de comp\u00E9tences\, ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.tenantspace.save.fail.120970": "La file d'attente de comp\u00E9tences est r\u00E9f\u00E9renc\u00E9e par l'appareil partag\u00E9 et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.callcenterinstanceslist.config.error": "Erreur",
  "provision.tenantspace.save.fail.107-069": "L'UAP ne parvient pas \u00E0 interroger le statut de l'enregistrement.",
  "provision.tenantspace.save.fail.107-068": "Impossible de supprimer l'utilisateur multim\u00E9dia UAP.",
  "provision.tenantspace.save.fail.107-067": "L'utilisateur multim\u00E9dia UAP ne parvient pas \u00E0 modifier le mot de passe.",
  "provision.tenantspace.save.fail.107-066": "Exception de configuration UAP",
  "provision.appversionmanagement.forceUpdate": "Mise \u00E0 jour forc\u00E9e",
  "provision.tenantspace.save.fail.107-064": "Impossible d'ajouter l'utilisateur multim\u00E9dia UAP.",
  "provision.tenantspace.agentinfo.message.selectagenttype": "Veuillez s\u00E9lectionner un type d'agent",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callPeriod.label": "P\u00E9riode d'appel (1s \u00E0 60s)",
  "provision.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "Le nom du centre d'appel ne peut pas contenir de caract\u00E8res sp\u00E9ciaux.",
  "provision.tenantspace.agentinfo.message.searchworkno": "Veuillez entrer le num\u00E9ro de l'agent",
  "provision.tenantspace.save.fail.107-065": "Impossible d'interroger l'utilisateur multim\u00E9dia UAP.",
  "provision.tenantspace.save.fail.120609": "Les repr\u00E9sentants commerciaux sont r\u00E9f\u00E9renc\u00E9s par d'autres repr\u00E9sentants commerciaux et ne peuvent pas \u00EAtre supprim\u00E9s.",
  "provision.tenantspace.save.fail.120608": "L'agent est r\u00E9f\u00E9renc\u00E9 par la file d'attente de comp\u00E9tences et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.callcenterinstanceslist.config.ivrnumsError": "Le nombre de canaux IVR doit \u00EAtre sup\u00E9rieur \u00E0 la somme du nombre de canaux TTS et du nombre de canaux ASR.",
  "provision.tenantspace.save.fail.120969": "La file d'attente de comp\u00E9tences est r\u00E9f\u00E9renc\u00E9e par ACDDN et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.tenantspace.save.fail.120607": "L'agent est r\u00E9f\u00E9renc\u00E9 par la file d'attente priv\u00E9e de comp\u00E9tences par d\u00E9faut et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.save.fail.120606": "L'agent est r\u00E9f\u00E9renc\u00E9 par le processus et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.save.fail.120968": "Le taux de connexion minimum de la file d'attente de comp\u00E9tences ne peut pas d\u00E9passer le taux de connexion attendu.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsPasswordKeyLengthValidateFailed": "La longueur ne peut pas d\u00E9passer 50 caract\u00E8res.",
  "provision.tenantspace.save.fail.120967": "Le type de m\u00E9dia de la file d'attente de comp\u00E9tences est un appel fax\, et une bo\u00EEte aux lettres doit \u00EAtre configur\u00E9e.",
  "provision.callcenterinstanceslist.config.success": "Succ\u00E8s",
  "provision.tenantspace.save.fail.120605": "Le repr\u00E9sentant commercial est le chef d'\u00E9quipe et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.save.fail.120966": "La lettre de lecteur r\u00E9f\u00E9renc\u00E9e par la file d'attente de comp\u00E9tences n'existe pas.",
  "provision.tenantspace.save.fail.120604": "L'agent est l'administrateur VDN par d\u00E9faut et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.appsitesmanagement.siteDesc": "Description du site",
  "provision.label.tenantexport.parainnull": "Veuillez s\u00E9lectionner les donn\u00E9es \u00E0 exporter.",
  "provision.tenantspace.save.fail.120603": "L'administrateur par d\u00E9faut du VDN ne peut pas modifier le r\u00F4le.",
  "provision.tenantspace.save.fail.120965": "La file d'attente de comp\u00E9tences est r\u00E9f\u00E9renc\u00E9e par la configuration appel\u00E9e et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.callcent.error.updateinfo": "La mise \u00E0 jour a \u00E9chou\u00E9.",
  "ccprovision.IVRSprogress.config.curcallNo": "Quantit\u00E9 d'appels simultan\u00E9s",
  "provision.label.tenantexport.exportTaskStatus": "Exporter le statut de la t\u00E2che",
  "provision.tenantspace.save.fail.120964": "La file d'attente de comp\u00E9tences est r\u00E9f\u00E9renc\u00E9e par le processus et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.tenantspace.save.fail.120602": "L'administrateur par d\u00E9faut du VDN ne peut pas modifier le mot de passe.",
  "provision.subscribedetail.label.operate": "Op\u00E9rer",
  "provision.callcenterinstanceslist.config.voicecallnumsError": "Le nombre maximal d'appels vocaux simultan\u00E9s doit \u00EAtre sup\u00E9rieur ou \u00E9gal \u00E0 la somme du nombre d'agents vocaux et du nombre de canaux de RVI audio et vid\u00E9o.",
  "provision.callcent.platformserver.isales": "iSales",
  "provision.tenantspace.save.fail.120963": "Les files d'attente de comp\u00E9tences sont r\u00E9f\u00E9renc\u00E9es par les repr\u00E9sentants de l'entreprise et ne peuvent pas \u00EAtre supprim\u00E9es.",
  "provision.tenantspace.save.fail.120601": "L'administrateur par d\u00E9faut du VDN ne peut pas modifier le nom.",
  "provision.tenantspace.save.fail.120961": "La file d'attente de comp\u00E9tences est r\u00E9f\u00E9renc\u00E9e par CDN et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.tenantspace.save.fail.120962": "La file d'attente de comp\u00E9tences est r\u00E9f\u00E9renc\u00E9e par groupe de clients et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.tenantspace.save.fail.120960": "La file d'attente de comp\u00E9tences est r\u00E9f\u00E9renc\u00E9e par d'autres files d'attente de comp\u00E9tences et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.tenantspace.config.IVR": "Processus IVR",
  "provision.callcent.error.deleteobsserver.associationvcallcener": "Le num\u00E9ro de serveur obs s\u00E9lectionn\u00E9 est associ\u00E9 au locataire et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.config.SIP": "Serveur SBC",
  "provision.tenantspace.config.error": "\u00C9chec de la configuration. V\u00E9rifiez les configurations associ\u00E9es ou contactez l'administrateur.",
  "provision.tenantspace.save.fail.120959": "La file d'attente de comp\u00E9tences est r\u00E9f\u00E9renc\u00E9e par la file d'attente priv\u00E9e de comp\u00E9tences par d\u00E9faut et ne peut pas \u00EAtre supprim\u00E9e.",
  "provision.tenantspace.save.fail.120958": "La configuration appel\u00E9e r\u00E9f\u00E9renc\u00E9e par la file d'attente de comp\u00E9tences n'existe pas.",
  "provision.tenantspace.option.weeklist_fir": "D'abord",
  "provision.tenantspace.save.fail.120956": "L'agent r\u00E9f\u00E9renc\u00E9 par la file d'attente de comp\u00E9tences n'existe pas.",
  "provision.callcenterinstanceslist.softPhone.endNumber": "Num\u00E9ro de fin",
  "provision.tenantspace.save.fail.120957": "Le processus r\u00E9f\u00E9renc\u00E9 par la file d'attente de comp\u00E9tences n'existe pas.",
  "provision.tenantspace.save.fail.120955": "La file d'attente de comp\u00E9tences r\u00E9f\u00E9renc\u00E9e par la file d'attente de comp\u00E9tences n'existe pas.",
  "provision.tenantspace.status.invalid.success": "R\u00E9vocation r\u00E9ussie.",
  "provision.tenantspace.save.fail.120954": "Le type de service r\u00E9f\u00E9renc\u00E9 par la file d'attente de comp\u00E9tences n'existe pas.",
  "provision.callcent.platformserver.ccfs": "CCFS",
  "provision.tenantspace.save.fail.120953": "Les \u00E9l\u00E9ments de comp\u00E9tence dans la file d'attente de comp\u00E9tences sont dupliqu\u00E9s.",
  "provision.callcenterinstanceslist.config.ccIdError": "Le num\u00E9ro du centre d'appels a \u00E9t\u00E9 utilis\u00E9.",
  "provision.callcenterinstanceslist.ccdis.progId": "ProgID",
  "provision.appversionmanagement.updateTime": "Temps de mise \u00E0 jour",
  "provision.label.tenantexport.download": "T\u00E9l\u00E9chargement",
  "provision.callcenterinstanceslist.table.deleteConfimMessage": "\u00CAtes-vous s\u00FBr de vouloir supprimer le centre d'appels s\u00E9lectionn\u00E9 et la configuration associ\u00E9e ?",
  "provision.tenantspace.save.fail.107-044": "Exception d'\u00E9chec de suppression du syst\u00E8me VDN.",
  "provision.tenantspace.save.fail.107-045": "Le VDN syst\u00E8me supprime le code d'exception o\u00F9 VDNID et VDNName ne correspondent pas.",
  "provision.tenantspace.save.fail.107-043": "Exception d'\u00E9chec de requ\u00EAte syst\u00E8me VDN.",
  "provision.tenantspace.save.fail.107-042": "Exception d'\u00E9chec de modification du syst\u00E8me VDN.",
  "provision.tenantspace.save.fail.107-041": "Impossible d'ajouter un VDN syst\u00E8me.",
  "provision.tenantspace.table.tenantSpaceName": "Espace locataire",
  "provision.label.tenantexport.exportEndTime": "Exporter l'heure de fin",
  "provision.authconfig.description.empty.error": "la description ne peut pas \u00EAtre vide.",
  "provision.tenantspace.save.fail.107-040": "Exception d'\u00E9chec de suppression de processus de service IVR.",
  "provision.encryptinfo.updateTime": "Mis \u00E0 jour le",
  "provision.callcent.label.closeFaule": "D\u00E9sactiver l'invite d'erreur",
  "provision.tenantspace.config.fail.modifydata.tenant.feature.inDatabase": "La base de donn\u00E9es n'a pas r\u00E9ussi \u00E0 modifier les informations de fonction du locataire",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateFailed": "Seuls les chiffres sont autoris\u00E9s.",
  "provision.tenantspace.table.invalidTenantSpace": "Cette op\u00E9ration ne peut pas \u00EAtre cancled apr\u00E8s la r\u00E9vocation\, S'il vous pla\u00EEt confirmer si le locataire doit \u00EAtre r\u00E9voqu\u00E9\!",
  "provision.sysparam.config.save": "Enregistrer",
  "provision.tenantspace.cc.videoAgentCurCapacity": "Quantit\u00E9 d'agent vid\u00E9o",
  "provision.tenantspace.save.fail.107-039": "Exception d'\u00E9chec de requ\u00EAte de processus de service IVR.",
  "provision.spinfo.idpfile": "Fichier IDP",
  "provision.tenantspace.save.fail.107-038": "Exception d'\u00E9chec de modification de processus de service IVR.",
  "provision.tenantspace.save.fail.107-037": "Exception d'\u00E9chec d'augmentation du processus de service IVR.",
  "provision.tenantspace.save.fail.107-036": "Exception d'\u00E9chec de suppression de code d'acc\u00E8s au syst\u00E8me.",
  "provision.tenantspace.save.fail.107-035": "La requ\u00EAte de code d'acc\u00E8s syst\u00E8me \u00E9choue anormalement.",
  "provision.callcenterinstanceslist.label.asrchannelUseRate": "Utilisation de l'ASR",
  "provision.callcent.platformserver.port": "Num\u00E9ro de port de service",
  "provision.tenantspace.save.fail.107-034": "Exception d'\u00E9chec de modification du code d'acc\u00E8s au syst\u00E8me.",
  "provision.tenantspace.save.fail.107-033": "L'\u00E9chec de l'ajout de code d'acc\u00E8s au syst\u00E8me est anormal.",
  "provision.callcenterinstanceslist.table.videoAgentCurCapacity": "Quantit\u00E9 d'agent vid\u00E9o",
  "provision.tenantspace.save.fail.107-032": "Exception d'\u00E9chec d'\u00E9tablissement de liaison d'abonnement.",
  "provision.accesscode.table.multimedia": "Multim\u00E9dia",
  "provision.systemparam.error.save": "L'enregistrement des param\u00E8tres a \u00E9chou\u00E9",
  "provision.tenantspace.agentUsage.iszero": "Veuillez d'abord configurer l'agent vocal et les ressources ASR.",
  "provision.tenantspace.save.fail.107-031": "Exception d'\u00E9chec d'annulation d'abonnement.",
  "provision.callcent.error.errorinfo": "La cr\u00E9ation a \u00E9chou\u00E9.",
  "provision.tenantspace.save.fail.107-030": "Exception d'abonnement.",
  "ccprovision.recorddrive.config.recorddrivename": "Nom de la lettre du lecteur",
  "ccprovision.recorddrive.config.isfirstletter": "Indique si le lecteur est le premier",
  "provision.tenantspace.authenticationsystem.clientName": "Syst\u00E8me d'authentification tiers",
  "provision.tenantspace.config.softphoneassign.tip": "S'il vous pla\u00EEt entrer le segment de num\u00E9ro de t\u00E9l\u00E9phone logiciel.",
  "provision.tenantspace.save.fail.107-029": "Exception de d\u00E9lai d'attente de demande d'abonnement.",
  "provision.tenantspace.save.fail.107-028": "Exception d'\u00E9chec de demande d'abonnement.",
  "provision.callcenterinstanceslist.vcallcenter.maxVersatileAgents": "Agents polyvalents",
  "provision.tenantspace.save.fail.107-027": "Exception d'\u00E9chec d'enregistrement d'abonnement.",
  "provision.tenantspace.save.fail.107-026": "Exception d'\u00E9chec d'abonnement.",
  "provision.tenantspace.feature.selectAppCubeSupport.description": "Activez la fonction AppCube afin que l'espace locataire puisse int\u00E9grer la fonction AppCube.",
  "provision.tenantspace.save.fail.107-025": "Le nom du fichier de processus IVR est vide.",
  "provision.tenantspace.save.fail.107-024": "Exception d'\u00E9chec de requ\u00EAte de processus IVR.",
  "provision.callcent.error.deleteobsserverdesinfo": "Assurez-vous que le nom du serveur obs n'existe pas.",
  "provision.callcenterinstanceslist.table.ctiversion": "VERSION CTI",
  "provision.callcenterinstanceslist.vcallcenter.asrchannelnums": "Nombre de ASR",
  "provision.tenantspace.save.fail.107-023": "Exception d'\u00E9chec de requ\u00EAte VDN.",
  "provision.tenantspace.save.fail.107-022": "Exception d'\u00E9chec de modification de VDN.",
  "provision.tenantspace.save.fail.107-021": "L'exception de suppression appel\u00E9e a \u00E9chou\u00E9.",
  "provision.subscribedetail.success.delete": "Effacer avec succ\u00E8s.",
  "provision.tenantspace.save.fail.107-020": "La requ\u00EAte appel\u00E9e a \u00E9chou\u00E9 anormalement.",
  "provision.tenantspace.chooseobsdatasource.thirdPartyDbSource": "Sources de donn\u00E9es tierces",
  "provision.appversionmanagement.sureDeleteVersionNumber": "\u00CAtes-vous s\u00FBr de vouloir supprimer les informations de version?",
  "provision.tenantspace.cc.agentCurCapacity": "Quantit\u00E9 d'agent utilis\u00E9",
  "provision.tenantspace.config.amsNumber.positiveInteger": "Le nombre d'appels anonymes simultan\u00E9s ne peut pas \u00EAtre vide et doit \u00EAtre un entier positif.",
  "provision.tenantspace.agentinfo.message.video": "Vid\u00E9o",
  "provision.softphone.label.issyncuap.no": "Non",
  "provision.recorddrive.label.isfirstletter.yes": "Oui",
  "provision.tenantspace.save.fail.107-019": "La modification appel\u00E9e a \u00E9chou\u00E9 anormalement.",
  "provision.callcenterinstanceslist.config.interfacepassword": "Mot de passe ConfigInterface CTI",
  "provision.tenantspace.save.fail.107-017": "Exception d'\u00E9chec de suppression de r\u00F4le.",
  "provision.tenantspace.save.fail.107-018": "Exception appel\u00E9e d'\u00E9chec d'augmentation.",
  "provision.tenantspace.save.fail.107-016": "Exception d'\u00E9chec de la requ\u00EAte de r\u00F4le.",
  "provision.subscribedetail.button.delete": "Supprimer",
  "provision.tenantspace.save.fail.107-015": "Exception d'\u00E9chec de modification de r\u00F4le.",
  "provision.tenantspace.save.fail.107-014": "\u00C9chec de l'augmentation anormale du r\u00F4le.",
  "provision.tenantspace.save.fail.107-013": "Exception d'\u00E9chec de suppression de l'agent.",
  "provision.systemparam.error.validate.notmatchrulemaxlength": "Le param\u00E8tre d'entr\u00E9e d\u00E9passe la longueur maximale",
  "provision.subscribedetail.label.phoneNo": "Contact T\u00E9l\u00E9phone",
  "provision.tenantspace.save.fail.107-011": "Une modification d'agent ou d'agent batch a rencontr\u00E9 une exception.",
  "provision.tenantspace.save.fail.107-012": "La requ\u00EAte de l'agent est anormale.",
  "provision.tenantspace.save.fail.107-010": "L'ajout d'agents ou l'ajout d'agents dans des lots a rencontr\u00E9 une exception.",
  "provision.tenantspace.timezone.updatemessage": "La modification des informations de fuseau horaire peut affecter le traitement commercial pendant la p\u00E9riode de transition.",
  "provision.tenantspace.status.stop.fail": "Impossible de suspendre.",
  "provision.tenant.management.timezoneOffset": "D\u00E9calage du fuseau horaire",
  "provision.callcenterinstanceslist.vcallcenter.syncVccSuccess": "La synchronisation est termin\u00E9e.",
  "provision.tenant.management.batchcreate.error.accesscodenumber": "Un maximum de 100 codes d'acc\u00E8s sont autoris\u00E9s.",
  "provision.tenantspace.status.active.success": "Activation r\u00E9ussie.",
  "provision.tenant.management.adminpassword": "Mot de passe",
  "provision.systemparam.error.validate.ruleregexinvalid": "Valider une erreur de syntaxe de r\u00E8gle",
  "provision.tenantspace.feature.agentSendLocalMediaFile": "Envoi de fichiers multim\u00E9dias locaux par des agents",
  "provision.tenantspace.save.fail.107-007": "\u00C9chec de l'authentification avant la configuration de l'interface de configuration.",
  "provision.tenant.management.tenantexport.addTask": "Cr\u00E9ation d'une t\u00E2che d'exportation",
  "provision.tenantspace.save.fail.107-005": "\u00C9chec de la communication de l'interface de configuration.",
  "provision.tenantspace.save.fail.107-004": "Compte administrateur de configuration non valide.",
  "provision.tenantspace.config.vccName": "Nom du VCC",
  "provision.callcent.success.setinfo": "D\u00E9finir avec succ\u00E8s.",
  "provision.tenantspace.save.fail.107-003": "Mot de passe de l'administrateur de configuration incorrect\, verrouiller le compte apr\u00E8s avoir r\u00E9essay\u00E9 de nombreuses fois.",
  "provision.tenantspace.save.fail.107-002": "Le param\u00E8tre d'entr\u00E9e n'est pas valide\, le champ requis est vide.",
  "provision.obs.sync.needDelete": "L'OBS suivant a \u00E9t\u00E9 li\u00E9 aux locataires et doit \u00EAtre manuellement non li\u00E9 puis synchronis\u00E9\:",
  "provision.tenantspace.save.fail.107-000": "Les informations de connexion mises en cache ne sont pas valides\, vous devez vous connecter \u00E0 nouveau.",
  "provision.tenantspace.save.fail.107-001": "Exception lev\u00E9e lors de l'appel de l'interface de configuration.",
  "provision.tenantspace.selectStastus.active": "Activ\u00E9",
  "provision.tenantspace.feature.dualChannelRecSupport": "Enregistrement double piste",
  "provision.tenantspace.save.fail.120905": "La lettre de lecteur r\u00E9f\u00E9renc\u00E9e par le type d'entreprise n'existe pas.",
  "provision.accesscode.table.beginNumber": "D\u00E9marrer le code d'acc\u00E8s",
  "provision.tenantspace.save.fail.120904": "Le type d'entreprise est r\u00E9f\u00E9renc\u00E9 par le temps normal de travail et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.save.fail.120903": "Le type d'entreprise est r\u00E9f\u00E9renc\u00E9 par la file d'attente de comp\u00E9tences et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.save.fail.120902": "Le type d'entreprise est r\u00E9f\u00E9renc\u00E9 par le processus et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport": "T\u00E2che d'appel d'application",
  "provision.callcenterinstanceslist.config.workNoError": "L'ID de l'employ\u00E9 de d\u00E9part doit \u00EAtre de l'ordre de 101 \u00E0 50000.",
  "provision.callcent.success.saveinfo": "Cr\u00E9\u00E9 avec succ\u00E8s.",
  "provision.tenantspace.save.fail.120901": "Dupliquer la description du type d'entreprise.",
  "provision.accesscode.error.transfer.queryinterface": "\u00C9chec de la requ\u00EAte des donn\u00E9es de la plate-forme.",
  "provision.callcenterinstanceslist.sipServer.singledeleteconfim": "Voulez -vous vraiment supprimer le serveur d'enregistrement s\u00E9lectionn\u00E9 ?",
  "provision.tenant.management.tenantcreate.emailillegal": "Adresse e-mail non valide.",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.placeholder": "D\u00E9finissez ce param\u00E8tre en fonction du nombre de sessions IVR concurrentes\, du nombre de sessions r\u00E9seau central concurrentes et du nombre de sessions de ligne concurrentes configur\u00E9es par le locataire.",
  "provision.tenantspace.statusRecordTime.12Month": "12 Mois",
  "provision.tenantspace.save.fail": "L'enregistrement a \u00E9chou\u00E9.",
  "provision.callcenterinstanceslist.vcallcenter.ivrchannels": "AudioIVRcanaux",
  "provision.tenantspace.resetpwdfaild.user.is.locked": "Impossible de changer le mot de passe parce que l'utilisateur est verrouill\u00E9.",
  "provision.systemparam.error.refresh": "\u00C9chec de l'actualisation des param\u00E8tres",
  "provision.tenantspace.config.worknoassign.filternumber.error": "Le num\u00E9ro exclu ne r\u00E9pond pas aux exigences.",
  "provision.tenantspace.table.ttschannelnums": "Quantit\u00E9 TTS",
  "provision.tenant.management.tenantcreate.dds.failed": "Impossible de cr\u00E9er l'espace locataire dans le DDS.",
  "provision.tenant.management.tenantname.alphavalidateFailed": "Des caract\u00E8res non valides existent. Seules les lettres\, les chiffres et les (_) de soulignement sont pris en charge.",
  "provision.tenantspace.resetpwdfaild.user.not.exist": "Impossible de modifier le mot de passe parce que l'utilisateur n'existe pas.",
  "provision.tenantspace.config.choose.anonymous.call.server.type": "Type de serveur d'appel anonyme",
  "provision.tenant.management.tenantcreate.param.content.rule": "Le param\u00E8tre d'entr\u00E9e doit \u00EAtre compos\u00E9 d'au moins 2 caract\u00E8res alphanum\u00E9riques et doit commencer par une lettre.",
  "provision.tenant.management.tenantcreate.param.exceed": "La longueur du param\u00E8tre d'entr\u00E9e doit \u00EAtre inf\u00E9rieure \u00E0 30 caract\u00E8res.",
  "provision.tenantspace.chooseobsdatasource.platformDbSource": "Sources de donn\u00E9es de la plate-forme",
  "provision.tenant.management.tenantcreate.param.failed": "Le param\u00E8tre d'entr\u00E9e n'est pas valide.",
  "provision.callcent.error.paramNotavailable": "Le param\u00E8tre de requ\u00EAte est vide.",
  "provision.callcenterinstanceslist.vcallcenter.invalidVccListPromt": "Il y a des centres d'appel virtuels virtuels dans l'AICC o\u00F9 le VDN est manquant dans le WAS. La VccId est",
  "provision.tenantspace.config.tenant.featurevalue.not.digits": "Les informations de caract\u00E9ristiques attribu\u00E9es au locataire ne sont pas valides.",
  "ccprovision.IVRSprogress.config.tenantname": "Locataire",
  "provision.callcenterinstanceslist.IVRSprogress.Title": "Configuration du processus IVR",
  "provision.tenantspace.feature.smartivr.close.error": "D\u00E9sactivez la fonction de service client en ligne avant de d\u00E9sactiver la fonction IVR intelligente.",
  "provision.callcenterinstanceslist.ccgateway.idleRegAgentNum": "Quantit\u00E9 d'agent inactif",
  "ccprovision.vcallecenter.config.popup.adminpassword": "Modifier le mot de passe de l'administrateur de configuration",
  "provision.subscribedetail.label.companyName": "Soci\u00E9t\u00E9",
  "provision.tenant.management.mobile": "Num\u00E9ro de t\u00E9l\u00E9phone portable",
  "provision.recorddrive.selectneed.delete.recorddrive": "Veuillez s\u00E9lectionner la lettre de lecteur \u00E0 supprimer",
  "provision.tenant.management.batchcreate.error.accesscode.inadequacy": "Le nombre de codes d'acc\u00E8s ne peut \u00EAtre inf\u00E9rieur \u00E0 celui des espaces locataires.",
  "provision.callcenterinstanceslist.ccdis.ccdisbackupIP": "Adresse IP de secours",
  "provision.tenantspace.config.choose.anonymous.call.server": "Serveur d'appel anonyme",
  "provision.callcenterinstanceslist.config.systemAdmin": "ID administrateur CTI",
  "provision.tenantspace.config.param.error.agentUsage": "Veuillez entrer le nombre d'agents vocaux.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.noempty": "L'information du num\u00E9ro est obligatoire.",
  "provision.tenantspace.config.agentUsage": "Agents",
  "provision.callcenterinstanceslist.softPhone.usedselect.isnotoccupied": "Non utilis\u00E9",
  "provision.callcent.error.saveccgateway.ipportinfo": "\u00C9chec de cr\u00E9ation\, veuillez confirmer si l'IP et le port de la passerelle CC-Gateway existent d\u00E9j\u00E0.",
  "provision.tenantspace.ivr.idlechnNum": "Quantit\u00E9 d'appel concurrent restante",
  "provision.callcenterinstanceslist.table.hasVcallcenterConfim": "Le centre d'appels qui a \u00E9t\u00E9 configur\u00E9 avec le centre d'appels virtuel ne peut pas \u00EAtre supprim\u00E9.",
  "provision.common.title.tipsInfo": "Informations",
  "provision.tenantspace.input.agentnum": "D\u00E9finissez le nombre d'agents \u00E0 appliquer.",
  "provision.callcenterinstance.accesscode.batchcreate.fail.processingtask": "Traitement de la t\u00E2che de cr\u00E9ation de codes d'acc\u00E8s par lots",
  "provision.callcenterinstanceslist.table.nodemodesingle": "Mode unitaire",
  "provision.tenantspace.config.isAutoAssignSoftPhone": "Si le num\u00E9ro de t\u00E9l\u00E9phone logiciel est automatiquement attribu\u00E9 au VCC",
  "provision.tenantspace.open.uploadfeature": "Confirmer pour ouvrir",
  "provision.callcent.error.portValidateFailed": "HeartbeatPort n'est pas un nombre.",
  "provision.callcenterinstanceslist.config.adminWnFrom": "ID d'employ\u00E9 de d\u00E9marrage de l'administrateur",
  "provision.tenantspace.config.selectedworkNo": "ID de travail s\u00E9lectionn\u00E9 {0} {1}",
  "provision.callcent.pool.select.different.amsservice.error": "Diff\u00E9rents n\u0153uds doivent s\u00E9lectionner le m\u00EAme type de serveur d'appel anonyme.",
  "provision.label.tenantexport.parainvalid": "Impossible de v\u00E9rifier la condition d'exportation.",
  "provision.tenantspace.detail.apply.name": "Demandeur",
  "provision.tenantspace.resetpwdfaild": "Impossible de r\u00E9initialiser le mot de passe.",
  "provision.callcent.error.updateccgateway.fail.syncccmanagement": "\u00C9chec de mise \u00E0 jour\, la synchronisation de la passerelle CC pour modifier l'information \u00E0 la salle de location a \u00E9chou\u00E9.",
  "provision.tenantspace.dstEndDateVerification": "La date de fin de l'heure d'\u00E9t\u00E9 ne peut \u00EAtre que post\u00E9rieure \u00E0 la date de d\u00E9but de l'heure d'\u00E9t\u00E9.",
  "provision.callcenterinstanceslist.table.nodemodeselect": "S\u00E9lectionner le mode",
  "provision.tenant.management.daylightSavingTime": "DST",
  "provision.callcent.error.updateucsserver.ipandport.alreadyexist": "L'adresse IP et le num\u00E9ro de port du serveur CCUCS existent d\u00E9j\u00E0 et ne peuvent pas \u00EAtre modifi\u00E9s.",
  "provision.accesscode.table.callin": "Appel entrant",
  "provision.tenantspace.feature.speechAnalysis.description": "La fonction d'analyse de la parole est activ\u00E9e. L'espace locataire prend en charge l'analyse du texte et de la voix.",
  "provision.callcent.error.updateccgateway.ipandport.alreadyexist": "\u00C9chec de mise \u00E0 jour\, confirmez svp si l'IP et le port de la passerelle CC-Gateway existent d\u00E9j\u00E0.",
  "provision.tenantspace.feature.outboundCallMarketingSupport.error": "D\u00E9sactivez d'abord les fonctionnalit\u00E9s de marketing par SMS et par e-mail et par WhatsApp.",
  "provision.tenantspace.config.anonymous.call.number": "Nombre d'appels anonymes simultan\u00E9s",
  "provision.tenantspace.config.softphoneassign.softphonescope.inadequacy": "Le num\u00E9ro de t\u00E9l\u00E9phone logiciel disponible allou\u00E9 est insuffisant.",
  "provision.appversionmanagement.selectDataDeleted": "S\u00E9lectionnez les donn\u00E9es \u00E0 supprimer.",
  "provision.callcenterinstanceslist.config.wnFrom": "Commencer l'ID de l'employ\u00E9",
  "provision.tenantspace.feature.customData": "Personnalisation des donn\u00E9es",
  "provision.encryptinfo.encryptKey": "Cl\u00E9 de mise \u00E0 jour",
  "provision.callcenterinstanceslist.label.eMediaAgentCurCapacity": "Quantit\u00E9 d'agent multim\u00E9dia",
  "provision.tenantspace.ivr.id": "Non.",
  "provision.subscribedetail.label.remark": "Remarque",
  "provision.tenantspace.config.chooseIVR": "S\u00E9lectionnez un processus IVR.",
  "provision.tenantspace.cc.ivrchannelUsagePercent": "Utilisation audio de la Manche d'IVR",
  "provision.callcent.success.oj8k": "Compl\u00E8te",
  "provision.tenantspace.feature.smsMarketingSupport": "Marketing par SMS",
  "provision.tenantspace.input.searchName": "Entrez le nom du centre d'appels \u00E0 interroger.",
  "provision.subscribedetail.delete.title": "supprimer la confirmation",
  "provision.appsitesmanagement.new": "Ajouter",
  "provision.tenantspace.configureauthentication": "Configurer l'authentification",
  "provision.tenantspace.feature.cobrowse.needak": "Vous devez configurer les informations AK avant d'activer la fonction de co-navigation.",
  "provision.encryptinfo.validityPeriod": "P\u00E9riode de validit\u00E9 (mois)",
  "provision.ivr.selectivrprogress": "S\u00E9lectionnez le processus IVR que vous souhaitez supprimer.",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIpUpdate": "Modifier",
  "provision.appsitesmanagement.unable": "Non",
  "provision.appversionmanagement.android": "Android",
  "provision.appversionmanagement.androidH5": "Android H5",
  "provision.callcenterinstanceslist.table.videoIvrChannels": "Quantit\u00E9 vid\u00E9o de canal d'IVR",
  "provision.appversionmanagement.iOSH5": "IOS H5",
  "provision.callcenterinstanceslist.config.odfsappkey": "AppKey ODFS",
  "provision.subscribedetail.button.reset": "R\u00E9initialiser",
  "provision.callcenterinstanceslist.table.agentUseRate": "Utilisation de l'agent vocal",
  "promotion.tenant.management.tenantedit.cc.null": "L'ID du centre d'appel auquel appartient le centre d'appel virtuel est vide.",
  "provision.tenantspace.select.stop": "Arr\u00EAt\u00E9",
  "provision.sysparam.config.value": "Valeur de param\u00E8tre",
  "provision.label.tenantexport.conditions": "Crit\u00E8res de recherche",
  "provision.label.tenantexport.confirmMessage": "\u00CAtes-vous s\u00FBr de vouloir cr\u00E9er une t\u00E2che d'exportation ?",
  "provision.tenant.management.button.cancel": "Annuler",
  "provision.encryptinfo.algorithm": "Algorithme de chiffrement",
  "provision.callcenterinstanceslist.config.passwordConfirm": "Confirmer le mot de passe",
  "provision.web.validator.ip": "Adresse IP non valide. (Entrez une adresse IPv4 ou IPv6\, ou un nom de domaine.)",
  "provision.sysparam.config.itemname": "Nom du param\u00E8tre",
  "provision.callcenterinstanceslist.label.videoIvrChannelUseRate": "Utilisation vid\u00E9o de la Manche d'IVR",
  "provision.tenantspace.verification.fail": "Impossible de valider le format de l'heure.",
  "provision.callcenterinstanceslist.softPhone.beginNumber": "Num\u00E9ro de d\u00E9part",
  "provision.tenantspace.status.stop.success": "Le succ\u00E8s.",
  "provision.tenant.management.companyName": "Entreprise",
  "provision.tenantspace.config.agent.videoagent.ivr.more.zero": "Le nombre d'agents vocaux\, d'agents vid\u00E9o\, d'agents multim\u00E9dias\, de canaux audio IVR et de canaux vid\u00E9o IVR ne peut pas \u00EAtre \u00E9gal \u00E0 0.",
  "provision.sysparam.config.opterate": "Op\u00E9ration",
  "provision.callcenterinstanceslist.softPhone.passwordconf": "Confirmer le mot de passe",
  "provision.tenant.management.tenantcreate.sum.failed": "Impossible de cr\u00E9er l'espace locataire dans la SOMME.",
  "provision.callcenterinstanceslist.config.ivrchannelsValidate": "Le nombre de canaux IVR audio est inf\u00E9rieur au nombre de canaux r\u00E9ellement attribu\u00E9s dans le centre d'appels.",
  "provision.ccworknos.table.updateaccessCode": "Modifier",
  "provision.callcenterinstanceslist.config.ccNameUsed": "Le nom du centre d'appels a \u00E9t\u00E9 utilis\u00E9.",
  "provision.callcenterinstance.accesscode.validateaccesscoderange": "Un maximum de 100 codes d'acc\u00E8s peuvent \u00EAtre ajout\u00E9s par lots.",
  "provision.tenantspace.detail": "D\u00E9tails du locataire",
  "provision.tenant.management.languagetype": "Type de langue",
  "provision.callcenterinstanceslist.ccgatewayServer.batchdeleteconfim": "Voulez -vous vraiment supprimer tous les serveurs CC-Gateway s\u00E9lectionn\u00E9s ?",
  "provision.tenantspace.config.param.error.asrNumber": "Veuillez saisir la quantit\u00E9 ASR.",
  "provision.tenantspace.clear": "Effacer",
  // "#now",
  // "#Tue Feb 14 15:21:45 CST 2023",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": `Le mot de passe doit contenir au moins trois types de ce qui suit \: majuscules\, minuscules\, chiffres\, et les characters\: ~\! {'@'} \# $ % ^ & * () - sp\u00E9ciales suivantes _ \= +  [  ] ; \: " "\, <. > /?`,
  "provision.appversion.msg.uploadfile.type.fail": "Format de fichier incorrect.",
  "provision.appversion.msg.process.fail": "Impossible de traiter les informations de version de l'application.",
  "provision.callcent.platformserver.kafka.switch.panel": "Indique si le service doit \u00EAtre activ\u00E9.",
  "provision.appversion.msg.process.modify": "Modifier la version de l'application",
  "provision.appversionmanagement.protocol.check": "Notez que le protocole r\u00E9seau utilis\u00E9 par l'adresse de t\u00E9l\u00E9chargement saisie n'est pas s\u00E9curis\u00E9. Si vous \u00EAtes s\u00FBr de l'utiliser\, des risques de s\u00E9curit\u00E9 peuvent exister.",
  "provision.appversion.msg.uploadfile.strem.fail": "Impossible de g\u00E9n\u00E9rer le flux de fichiers.",
  "provision.callcenterinstanceslist.softPhone.passValidateFailed": "Doit contenir au moins 3 majuscules\, minuscules\, chiffres\, caract\u00E8res sp\u00E9ciaux ~{'@'}^*_+.?.",
  "provision.callcent.packagefile.saveinfo": "Cr\u00E9ation r\u00E9ussie. Le paquetage version est en cours de traitement. Attendez que la page actualise le r\u00E9sultat du traitement du paquet de version.",
  "provision.appversion.msg.uploadfile.4.fail": "Le dossier comporte des risques d'injection. Effectuer une analyse.",
  "provision.whiteListAddress.channel.push": "Adresse push de message de canal multim\u00E9dia",
  "provision.appversion.msg.uploadfile.2.fail": "\u00C9chec de la v\u00E9rification du format de fichier.",
  "provision.callcent.error.checkparam": `Contient des characters("<"\,">"\,""and invalides plus)`,
  "provision.appversionmanagement.appPackageTips": "Seul le fichier an.apk peut \u00EAtre t\u00E9l\u00E9charg\u00E9.",
  "provision.appversion.msg.process.add": "Ajouter une version d'application",
  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport.description": "Cette fonctionnalit\u00E9 permet aux agents du locataire de g\u00E9rer les t\u00E2ches d'appel sur les applications mobiles.",
  "provision.tenantspace.feature.knowledge.open.confirmmsg": "La fonction AppCube est la base pour ex\u00E9cuter la fonction de base de connaissances et sera activ\u00E9e en m\u00EAme temps.",
  "provision.appversionmanagement.cannotContainSpecialCharacters": "La valeur ne peut pas contenir les caract\u00E8res sp\u00E9ciaux suivants \: ~\!{'@'}\#$%&*()/_\=+{\:'<>? []",
  "provision.appversionmanagement.latestVersion": "Derni\u00E8re version",
  "provision.tenantspace.feature.onlineCustomerServiceAssets": "Actif de service \u00E0 la client\u00E8le en ligne",
  "provision.whiteListAddress.internal.message.notification": "Adresse IP et port pour l'appel du service d'application pour la notification de message interne",
  "provision.appversion.msg.uploadfile.data.fail": "Param\u00E8tres de fichier incorrects.",
  "provision.callcent.packagefile.updateinfo": "Mise \u00E0 jour r\u00E9ussie. Le paquet de version est en cours de traitement. Attendez que la page actualise le r\u00E9sultat du traitement du paquet de version.",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.open.confirmmsg": "Les fonctionnalit\u00E9s AppCube et intelligentes de SVI seront \u00E9galement activ\u00E9es car elles sont la base pour l'ex\u00E9cution de la fonctionnalit\u00E9 de service client en ligne.",
  "provision.ccp.redis.packagefile.inprogress": "Le paquetage de version est en cours de t\u00E9l\u00E9chargement.",
  "provision.tenantspace.feature.appcube.close.error": "Avant de d\u00E9sactiver la fonction AppCube\, assurez-vous que les fonctions {0} \u00E9t\u00E9 d\u00E9sactiv\u00E9es.",
  "provision.tenantspace.feature.appcubeIkbs.close.error": "D\u00E9sactivez la fonctionnalit\u00E9 de base de connaissances avant de d\u00E9sactiver la fonctionnalit\u00E9 AppCube.",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.description": "Cette fonctionnalit\u00E9 permet au locataire d'int\u00E9grer les clients de chat en ligne JS d\u00E9velopp\u00E9s dans AppCube dans des pages tierces pour impl\u00E9menter le chat en ligne.",
  "provision.tenantspace.config.datasource": "Source de donn\u00E9es OBS",
  "provision.tenantspace.passValidateFailed": `Devrait contenir des lettres majuscules\, minuscules\, chiffres et caract\u00E8res sp\u00E9ciaux ~\!{'@'}\#$%&*()-_\\=+[{}];\:""\,<.>/?`,
  "provision.appsitesmanagement.cannotContainSpecialCharacters": "La valeur ne peut pas contenir les caract\u00E8res sp\u00E9ciaux suivants \: ~\!{'@'}\#$%&*()/_\\=+{\:'<>? []",
  "provision.appversion.msg.uploadfile.3.fail": "La v\u00E9rification de la taille du fichier a \u00E9chou\u00E9. V\u00E9rifiez le param\u00E8tre syst\u00E8me Taille maximale d'un package d'installation d'une application mobile t\u00E9l\u00E9charg\u00E9e sur la page Param\u00E8tres > Configuration des param\u00E8tres syst\u00E8me.",
  "provision.appversion.msg.uploadfile.1.fail": "La v\u00E9rification du nom de fichier a \u00E9chou\u00E9.",
  "provision.ccp.redis.packagefile.failed": "Impossible de t\u00E9l\u00E9charger le paquet de version.",
  "provision.tenantspace.feature.dualCallAppSupport.open.confirmmsg": "La fonction AppCube sera \u00E9galement activ\u00E9e car c'est la base pour ex\u00E9cuter la fonction de t\u00E2che d'appel d'application.",
  "provision.tenantspace.resetpwdfaild.newPwd.information.is.wrong": "\u00C9chec de la modification\,Les informations newPwd sont erron\u00E9es",
  "provision.appversion.msg.uploadfile.9.fail": "La v\u00E9rification du fichier a \u00E9chou\u00E9.",
  "provision.callcenterinstanceslist.table.allowedTTSAndASRCallNum": "Nombre de canaux IVR intelligents",
  "provision.whiteListAddress.callout.push": "Adresse push du r\u00E9sultat de l'appel sortant",
  "provision.callcent.platformserver.kafka": "Intergiciel de messagerie (Kafka)",
  "provision.appversion.msg.throttling.fail": "Cr\u00E9ez ou modifiez la limite de trafic de l'interface toutes les deux minutes. R\u00E9essayez plus tard.",
  "provision.appversion.msg.uploadfile.0.success": "V\u00E9rification du fichier r\u00E9ussie.",
  "provision.appversionmanagement.appPackage": "Paquet de version",
  "provision.appversion.msg.uploadfile.fail": "Impossible de t\u00E9l\u00E9charger le fichier. Modifiez et t\u00E9l\u00E9chargez-le \u00E0 nouveau.",
  "provision.systemparam.error.validate.watermarkinfo.notmatchruleregex": "Le param\u00E8tre permet seulement de 0 \u00E0 20 caract\u00E8res contenant des lettres majuscules et minuscules\, des chiffres ou -_/{'@'}.",
  "provision.callcent.error.deleteccdis.associationvcallcener": "Le serveur CCDIS s\u00E9lectionn\u00E9 est associ\u00E9 au locataire et ne peut pas \u00EAtre supprim\u00E9.",
  "provision.callcent.error.updateccdis.associationvcallcener": "Le serveur CCDIS s\u00E9lectionn\u00E9 est associ\u00E9 au locataire et ne peut pas \u00EAtre mis \u00E0 jour.",
  "provision.ccp.redis.packagefile.success": "Le paquetage de version est t\u00E9l\u00E9charg\u00E9 avec succ\u00E8s.",
  "provision.target.selectDate": "Choisissez Une Date",

  "provision.callcent.error.deleteinfo.from.cms.failed": "Le CMS n'a pas r\u00E9ussi \u00E0 supprimer les informations pertinentes.",
  "provision.callcent.error.deleteinfo.from.cms.bad.input": "\u00C9chec de la v\u00E9rification de l'entr\u00E9e CMS.",
  "provision.callcent.error.deleteinfo.from.cms.unknown.error": "Une erreur inconnue s'est produite dans le CMS.",

  "chat.client.greeting.dear": "honorable",
  "chat.usefulexpressions.message.saveSuccess": "La phrase commune a \u00E9t\u00E9 sauvegard\u00E9e avec succ\u00E8s.",
  "chat.usefulexpressions.message.contentLength": "Assurez-vous que la longueur de la phrase commune ne d\u00E9passe pas 1000 caract\u00E8res.",
  "chat.agent.title.enterText": "Veuillez saisir",
  "chat.agent.title.transfer": "Transf\u00E9rer \u00E0",
  "chat.skill.label.inputName": "Nom",
  "chat.session.label.evaluation": "\u00C9valuation de la session",
  "provision.callcenterinstanceslist.config.SysAdminAccount": "Compte administrateur syst\u00E8me",
  "provision.callcenterinstanceslist.vcallcenter.comma": ";",
  "chat.session.label.exitQueueTime": "Temps de sortie de la file d'attente",
  "chat.session.endType.customerEnd": "Fin du client",
  "provision.callcent.error.entrynotexsit": "L'enregistrement de la liste blanche n'existe pas.",
  "chat.agent.label.send": "Envoyer",
  "chat.skill.label.skillDetail": "D\u00E9tails des comp\u00E9tences",
  "chat.skill.message.isExisted": "Le nom de la comp\u00E9tence existe d\u00E9j\u00E0.",
  "chat.thirdportal.chatWindow.label.userName": "Nom et pr\u00E9nom",
  "chat.usefulexpressions.message.selectExpressions": "Veuillez s\u00E9lectionner une phrase courante.",
  "chat.weChatConfig.message.skillExisted": "La file d'attente de comp\u00E9tences s\u00E9lectionn\u00E9e est li\u00E9e \u00E0 un autre compte public.",
  "chat.agentConsole.message.content.audio": "[Message vocal]",
  "chat.webconfig.label.greeting": "Accueil",
  "chat.skill.message.success": "Succ\u00E8s",
  "OPERLOGS.TENANTSPACE.SYNC": "Synchroniser l'espace locataire",
  "chat.skill.label.maxQueueTimeInTable": "Temps de file d'attente maximal",
  "chat.client.label.loginTitle": "Connexion",
  "chat.client.label.chatTitle": "CLIENTS",
  "chat.thirdportal.chatWindow.label.userEmail": "Bo\u00EEte aux lettres",
  "chat.weChatConfig.title.deleteTitle": "Suppression d'un \u00E9l\u00E9ment de configuration WeChat",
  "chat.skill.message.willDeleteSkill": "\u00CAtes - vous s\u00FBr de supprimer la comp\u00E9tence?",
  "chat.client.message.evaluationFail": "\u00C9chec de l'enqu\u00EAte de satisfaction.",
  "chat.client.label.order": "Mes commandes",
  "chat.weChatConfig.label.codeName": "Code d'acc\u00E8s au canal",
  "chat.weChatConfig.message.ensureDelete": "Confirmer supprimer l'\u00E9l\u00E9ment de configuration s\u00E9lectionn\u00E9?",
  "chat.webconfig.label.intergratedcode": "Code d'int\u00E9gration",
  "chat.session.channel.all": "Tous",
  "provision.callcent.error.ccInstidInvalid": "Instance de centre d'appels non valide.",
  "chat.usefulexpressions.message.contentNotNull": "La phrase commune ne peut pas \u00EAtre vide.",
  "chat.skill.label.reset": "R\u00E9initialiser",
  "chat.timerange.message.timeerror": "Le format de l'heure s\u00E9lectionn\u00E9e est incorrect.",
  "chat.agent.label.offline": "Hors ligne",
  "chat.session.label.allChannel": "Tous les canaux d'acc\u00E8s",
  "chat.weChatConfig.stepname.verify": "V\u00E9rification de la soumission",
  "provision.callcenterinstanceslist.softPhone.vcallcenterid": "ID du centre d'appels virtuel",
  "provision.systemparam.url.unsafe": "(Ins\u00E9curit\u00E9)",
  "chat.agent.label.chatBot": "Service \u00E0 la client\u00E8le intelligent",
  "provision.tenantspace.dataUpload.resourceType": "Type de vidage de ressources",
  "chat.weChatConfig.title": "Configuration de l'activation des m\u00E9dias sociaux",
  "chat.session.label.subChannel": "Sous-canal d'acc\u00E8s",
  "chat.weChatConfig.stepname.success": "Succ\u00E8s de l'acc\u00E8s",
  "chat.thirdportal.chatWindow.title": "Service \u00E0 la client\u00E8le en ligne",
  "provision.tenantspace.dataUpload.reportData": "Donn\u00E9es du rapport",
  "provision.callcenterinstanceslist.vcallcenter.vccId": "ID du centre d'appels virtuel",
  "chat.timerange.label.sevendays": "Les 7 derniers jours",
  "provision.callcent.error.redisfail": "Impossible de rafra\u00EEchir le CC-AI Redis.",
  "provision.callcenterinstanceslist.config.startworkno": "Num\u00E9ro de d\u00E9but de l'ID de l'employ\u00E9",
  "chat.weChatConfig.stepname.selectchannel": "S\u00E9lectionner un canal d'acc\u00E8s",
  "provision.tenantspace.config.agentcall.zero": "Le nombre d'appels vocaux simultan\u00E9s ne peut pas \u00EAtre 0.",
  "chat.weChatConfig.label.nextStep": "Prochaine \u00E9tape",
  "chat.thirdportal.chatWindow.label.userPhone": "Num\u00E9ro de t\u00E9l\u00E9phone",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccEnabled": "Indique s'il faut activer le VDN.",
  "chat.session.label.created_startTime": "Heure de d\u00E9but",
  "chat.timerange.message.timeempty": "L'heure s\u00E9lectionn\u00E9e ne peut pas \u00EAtre vide.",
  "provision.batchimport.message.uploaded.button": "T\u00E9l\u00E9chargement",
  "chat.skill.message.warning": "Avertissements",
  "chat.session.label.channel": "Canal d'acc\u00E8s \u00E0 la session",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccName": "Nom VDN",
  "provision.callcenterinstanceslist.config.agentUsage": "Nombre d'agents attribu\u00E9s",
  "chat.session.label.evaluationTime": "Temps d'\u00E9valuation de la session",
  "provision.callcenterinstanceslist.vcallcenterDeatil.ccInstId": "ID du centre d'appels",
  "chat.session.channel.facebook": "Facebook",
  "chat.skill.message.failDeleteSkill": "Impossible de supprimer la comp\u00E9tence.",
  "chat.agent.label.waiting": "Attendre",
  "chat.weChatConfig.message.unknown": "Statut d'authentification inconnu !",
  "provision.tenantspace.table.maxcallnums": "Nombre maximal d'appels vocaux simultan\u00E9s (> = somme du nombre d'agents vocaux et du nombre de canaux IVR audio et vid\u00E9o)",
  "provision.callcenterinstanceslist.vcallcenterDeatil.tenantSpaceId": "ID de l'espace locataire",
  "chat.weChatConfig.label.state": "\u00C9tat",
  "chat.webconfig.label.basesettings": "Configuration de base",
  "chat.client.evaluate.message": "S'il vous pla\u00EEt commenter mon service\, merci!",
  "chat.agentConsole.message.error.notification": "!!!Impossible de cr\u00E9er un nouveau rappel de message\, veuillez v\u00E9rifier la configuration de votre navigateur!!!",
  "chat.client.label.promotions": "Conseils",
  "chat.session.channel.twitter": "Tweet",
  "chat.session.channel.web": "Page Web",
  "chat.skill.label.name": "Nom d'utilisateur",
  "chat.agent.message.changeFailed": "Impossible de changer le statut de l'agent.",
  "chat.agentConsole.message.content.video": "[Message vid\u00E9o]",
  "chat.agentConsole.message.content.connectMessage": "Vous avez un nouveau message !",
  "provision.sysparam.config.reset.multiple.error": "Les param\u00E8tres suivants ne peuvent pas \u00EAtre r\u00E9initialis\u00E9s :",
  "chat.client.label.input": "Veuillez saisir",
  "chat.webconfig.label.intergratemsg": "Int\u00E9grez Live Chat \u00E0 votre site Web",
  "chat.session.label.endReason": "Motif de fin de session",
  "chat.session.label.createdTime": "Heure de cr\u00E9ation de la session",
  "provision.callcent.error.pagechangednowrefresh": "Le contenu de la page a \u00E9t\u00E9 modifi\u00E9. La page doit \u00EAtre rafra\u00EEchie.",
  "chat.session.label.sessionId": "ID de session",
  "chat.session.label.search": "Rechercher",
  "chat.skill.label.deleteSkills": "Suppression",
  "chat.usefulexpressions.message.saveFail": "Impossible d'enregistrer la phrase courante.",
  "chat.session.label.allEndType": "Tous les types de fin",
  "chat.usefulexpressions.message.failDeleteExpression": "Impossible de supprimer la phrase courante.",
  "chat.agent.title.createCase": "Cr\u00E9ation d'un cas",
  "chat.session.label.reset": "R\u00E9initialiser",
  "provision.tenantspace.dataUpload.choose": "Veuillez s\u00E9lectionner...",
  "chat.skill.label.priority": "Priorit\u00E9",
  "chat.session.channel.wechat": "WeChat",
  "chat.client.label.login": "Connexion",
  "chat.weChatConfig.label.qcode": "H\u00E9bergement par balayage de code",
  "chat.skill.label.ok": "Confirmation",
  "chat.weChatConfig.label.delete": "Suppression",
  "chat.webconfig.label.windowTitle.placeholder": "Saisissez le titre \u00E0 afficher dans la fen\u00EAtre de discussion du client.",
  "chat.skill.message.createSuccess": "Comp\u00E9tence cr\u00E9\u00E9e avec succ\u00E8s.",
  "chat.skill.message.delete": "Suppression",
  "chat.webconfig.message.saveFail": "Impossible d'enregistrer la configuration.",
  "chat.skill.message.priorityLength": "Assurez-vous que la priorit\u00E9 ne d\u00E9passe pas cinq chiffres.",
  "provision.callcenterinstanceslist.whiteList.whiteListAdd": "Ajout d'une liste blanche",
  "chat.session.endType.agentEnd": "Fin de l'agent",
  "chat.skill.label.skillId": "num\u00E9rotation",
  "provision.tenantspace.ccName": "Centre d'appels",
  "chat.weChatConfig.message.emptyDeleteArray": "Veuillez s\u00E9lectionner l'\u00E9l\u00E9ment de configuration \u00E0 supprimer.",
  "chat.session.label.enterQueueTime": "Temps de mise en file d'attente",
  "chat.skill.label.maxQueueTime": "Temps de file d'attente maximal (secondes)",
  "chat.session.title.basic": "Informations de base sur la session",
  "chat.client.label.yes": "Oui",
  "chat.client.button.submit": "Soumettre",
  "chat.usefulexpressions.message.willDeleteExpression": "\u00CAtes - vous s\u00FBr de supprimer cette expression commune?",
  "chat.skill.label.cancel": "Annuler",
  "chat.timerange.label.ok": "Confirmation",
  "provision.callcent.error.savewhiteaddripinfo": "Le format d'adresse de la liste blanche est incorrect.",
  "chat.session.label.endType": "Type de fin de session",
  "chat.skill.message.willDeleteAllSkills": "\u00CAtes - vous s\u00FBr de supprimer toutes les comp\u00E9tences?",
  "chat.thirdportal.chatWindow.button.startChat": "D\u00E9marrer la conversation",
  "provision.callcenterinstanceslist.vcallcenterDeatil.accessCode": "Code d'acc\u00E8s attribu\u00E9 au VDN.",
  "provision.tenantspace.dataUpload.voiceFile": "Fichier vocal",
  "chat.weChatConfig.label.secret": "H\u00E9bergement des cl\u00E9s",
  "provision.callcenterinstanceslist.vcallcenter.agentNum": "Nombre d'agents attribu\u00E9s",
  "chat.agentConsole.message.content.locate": "[Message de localisation]",
  "chat.client.label.accountNumber": "Num\u00E9ro de compte",
  "provision.callcenterinstanceslist.vcallcenter.period": "C'est \u00E7a.",
  "chat.client.message.hiMyWorkno": "Bonjour\, mon num\u00E9ro d'employ\u00E9 est",
  "chat.session.label.endTime": "Heure de fin de session",
  "chat.agent.label.historicalBill": "Factures historiques",
  "chat.session.title.detail": "D\u00E9tails de la session",
  "chat.agent.label.mybill": "Mes factures",
  "chat.weChatConfig.label.bindSkill": "Liaison d'une file d'attente de comp\u00E9tences",
  "chat.skill.label.submit": "Soumettre",
  "chat.client.message.evaluationSuccess": "Sondage de satisfaction r\u00E9ussi !",
  "chat.webconfig.message.configdirective": "Copiez et collez le code suivant dans l'onglet </body> de votre site Web. Sauvegardez et publiez le code pour acc\u00E9der rapidement au Cloud de service Huawei. Une fois le code publi\u00E9\, l'entr\u00E9e du Cloud de service Huawei s'affiche dans le coin inf\u00E9rieur droit. Touchez pour avoir une conversation en temps r\u00E9el avec le pupitre du service client.",
  "chat.session.label.remark": "Commentaire",
  "chat.weChatConfig.label.verify": "V\u00C9RIFICATION",
  "chat.usefulexpressions.message.createSuccess": "La phrase commune a \u00E9t\u00E9 cr\u00E9\u00E9e avec succ\u00E8s.",
  "provision.callcent.error.saveucsserverdesinfo.ucsServernoexist": "Le serveur CCUCS n'existe pas.",
  "chat.agent.title.userInformation": "Informations sur le client",
  "chat.common.message.delete": "Suppression",
  "provision.batchimport.message.import.button": "Importer",
  "chat.client.label.rateService": "Veuillez noter le service",
  "chat.skill.message.saveSuccess": "Comp\u00E9tence sauvegard\u00E9e avec succ\u00E8s.",
  "chat.weChatConfig.label.create": "Ajout\u00E9",
  "chat.webconfig.label.functionalsettings": "Param\u00E8tres de fonction",
  "chat.skill.label.createSkill": "Cr\u00E9ation",
  "chat.client.greeting.evening": "Bonsoir !",
  "provision.tenantspace.table.maxVideoCallnums": "Nombre maximal d'appels vid\u00E9o simultan\u00E9s (>= nombre d'agents vid\u00E9o)",
  "chat.webconfig.button.save": "Enregistrer",
  "provision.tenantspace.popuppageurl.modifyprompt": "Modifiez d'abord l'adresse de la liste blanche.",
  "chat.usefulexpressions.message.willDeleteExpressions": "\u00CAtes - vous s\u00FBr de supprimer les mots communs s\u00E9lectionn\u00E9s?",
  "chat.client.label.bill": "Mes factures",
  "chat.agent.label.billDetail": "D\u00E9tails de la facture",
  "chat.session.label.skillQueueId": "ID de file d'attente de comp\u00E9tences",
  "chat.webconfig.label.bandingskill": "Liaison d'une file d'attente de comp\u00E9tences",
  "provision.callcenterinstanceslist.whitelist.singledeleteconfim": "\u00CAtes - vous s\u00FBr de supprimer les adresses de liste blanche s\u00E9lectionn\u00E9es?",
  "chat.client.label.like": "Je suppose que tu aimes \u00E7a.",
  "chat.timerange.label.customizes": "Personnalis\u00E9",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccId": "ID du centre d'appels virtuel",
  "chat.agent.label.online": "EN LIGNE",
  "chat.client.greeting.info": "Que puis-je faire pour vous ?",
  "chat.agentConsole.message.error.emptyMessage": "[Le message ne contient aucun contenu \u00E0 afficher]",
  "chat.weChatConfig.label.verifycode": "Jeton",
  "chat.skill.message.saveFail": "\u00C9chec de l'enregistrement des comp\u00E9tences.",
  "provision.callcenterinstanceslist.obsServer.taskRefreshPeriod": "P\u00E9riode de rafra\u00EEchissement de la t\u00E2che",
  "chat.session.channel.mobile": "Terminaux mobiles",
  "chat.weChatConfig.label.facebook": "Facebook",
  "chat.client.label.header": "Telco+",
  "provision.tenantspace.gw.serverIP": "Adresse IP du serveur de passerelle",
  "chat.agent.label.customerSource": "Source du client : Application mobile \u2014 Centre personnel",
  "chat.agent.title.close": "Fermer",
  "chat.timerange.label.oneday": "Dernier jour",
  "chat.session.title.history": "Historique du chat",
  "chat.client.transfer.link": "Voulez-vous transf\u00E9rer l'appel au service manuel ?",
  "chat.skill.message.createFail": "\u00C9chec de la cr\u00E9ation de la comp\u00E9tence.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.maxCalls": "Nombre maximal d'appels simultan\u00E9s",
  "chat.skill.label.agents": "Agent",
  "chat.weChatConfig.message.codeNameExisted": "Le code d'acc\u00E8s au canal existe d\u00E9j\u00E0.",
  "chat.skill.label.edit": "\u00C9diteur",
  "chat.session.label.servedAgent": "Agent de service",
  "chat.skill.label.save": "Enregistrer",
  "chat.weChatConfig.stepname.selectmode": "S\u00E9lectionner la m\u00E9thode de configuration",
  "chat.webconfig.message.saveSuccess": "La configuration a \u00E9t\u00E9 sauvegard\u00E9e avec succ\u00E8s.",
  "chat.usefulexpressions.label.createExpressionTab": "Cr\u00E9ation d'une phrase commune",
  "chat.agent.label.money": "Montant",
  "chat.weChatConfig.label.configId": "ID de configuration",
  "provision.callcent.error.whiteaddrduplicate": "Dupliquer l'adresse de la liste blanche.",
  "chat.common.message.warning": "Avertissements",
  "chat.skill.label.createSkillTab": "Cr\u00E9er des comp\u00E9tences",
  "chat.skill.message.completeInputs": "Veuillez remplir toutes les entr\u00E9es.",
  "chat.agent.title.userMenu": "Menu client",
  "chat.client.label.privacyHintContent": "Afin d'am\u00E9liorer la qualit\u00E9 du service\, le contenu de votre conversation sera enregistr\u00E9 par le syst\u00E8me. \u00CAtes-vous d'accord et continuer la conversation ?",
  "provision.callcent.error.paramValidateFailed": "\u00C9chec de l'ajout. ObsId\, progId ou HeartbeatPort doivent \u00EAtre des chiffres.",
  "chat.client.label.evaluation": "Enqu\u00EAte de satisfaction",
  "chat.timerange.label.alldays": "Tous les temps de cr\u00E9ation",
  "chat.webconfig.label.transHuman": "Transfert au service \u00E0 la client\u00E8le manuel",
  "provision.tenantspace.obs.name": "Nom du serveur OBS",
  "provision.tenantspace.customerAndmobileAgent.conflict": "La fonction d'agent mobile ne peut pas \u00EAtre activ\u00E9e en m\u00EAme temps que la fonction d'agent mobile.",
  "provision.ccworknos.table.virtualcallcenterNumber": "ID du centre d'appels virtuel",
  "chat.weChatConfig.label.AppSecret": "Mot de passe du d\u00E9veloppeur",
  "chat.session.endType.overtimeEnd": "Fin du d\u00E9lai d'attente",
  "chat.skill.label.description": "DESCRIPTION",
  "chat.skill.message.error": "erreur",
  "chat.skill.label.delete": "Suppression",
  "chat.weChatConfig.message.error": "erreur",
  "provision.callcenterinstanceslist.vcallcenterDeatil.agentNum": "Nombre d'agents attribu\u00E9s",
  "provision.tenantspace.updateTenantSpaceStatus": "\u00CAtes-vous s\u00FBr de modifier le statut de l'espace locataire ?",
  "chat.usefulexpressions.label.expression": "expressions courantes",
  "chat.usefulexpressions.message.isExisted": "Le nom de la phrase courante existe d\u00E9j\u00E0.",
  "chat.weChatConfig.message.appIdExisted": "L'ID de d\u00E9veloppeur du compte public que vous avez saisi existe d\u00E9j\u00E0.",
  "chat.client.label.password": "Mot de passe",
  "chat.agent.label.balance": "Recettes et d\u00E9penses",
  "chat.weChatConfig.label.verifying": "V\u00E9rification",
  "chat.weChatConfig.label.appId": "ID du d\u00E9veloppeur",
  "chat.client.message.gladtoServe": "C'est un plaisir de vous servir !",
  "provision.tenantspace.dataUpload.selectType": "S\u00E9lectionnez un type de service de vidage de ressources.",
  "chat.thirdportal.title": "Portail Web du client",
  "chat.webconfig.title": "Activer le canal Web",
  "chat.agent.label.total": "Total",
  "chat.agent.label.goAway": "D\u00E9part",
  "chat.weChatConfig.label.tenantId": "ID du locataire",
  "chat.agentConsole.message.content.image": "[Message de l'image]",
  "provision.callcenterinstanceslist.config.differentPassword": "Les mots de passe saisis sont incoh\u00E9rents.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.password": "Configuration du mot de passe de l'administrateur",
  "provision.callcenterinstanceslist.whitelist.batchdeleteconfim": "\u00CAtes - vous s\u00FBr de supprimer toutes les adresses de liste blanche s\u00E9lectionn\u00E9es?",
  "chat.weChatConfig.label.save": "Enregistrer",
  "chat.client.label.privacyHint": "Conseils de confidentialit\u00E9",
  "chat.skill.message.selectSkills": "Veuillez s\u00E9lectionner une comp\u00E9tence.",
  "chat.session.label.skillQueueName": "Nom de la file d'attente de comp\u00E9tences",
  "chat.timerange.label.thirtydays": "Les 30 derniers jours",
  "provision.callcenterwhitelist.whiteList": "Adresse de la liste blanche",
  "provision.callcenterinstanceslist.whiteList.whiteNumberAdd": "Ajout d'un num\u00E9ro \u00E0 la liste blanche",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceId": "ID de l'espace locataire",
  "chat.common.message.success": "Succ\u00E8s",
  "chat.webconfig.label.windowTitle": "Titre de la page",
  "chat.client.greeting.afternoon": "Bon apr\u00E8s-midi !",
  "provision.tenantspace.config.agent.zero": "Le nombre d'agents vocaux ne peut pas \u00EAtre 0.",
  "provision.callcent.selectwhitelist": "Veuillez s\u00E9lectionner une adresse de liste blanche.",
  "chat.agent.label.consumption": "Ma consommation",
  "provision.tenantspace.dataUpload.contactRecord": "Enregistrement de contact",
  "chat.client.greeting.morning": "Bonjour !",
  "chat.session.label.created_endTime": "Heure de fin",
  "chat.common.message.error": "erreur",
  "chat.usefulexpressions.message.createFail": "\u00C9chec de la cr\u00E9ation de la phrase commune.",
  "chat.webconfig.label.greeting.placeholder": "Saisissez le contenu de l'accueil \u00E0 afficher dans la fen\u00EAtre de discussion du client.",
  "provision.tenantspace.updateTenantSpaceStatus.confirm": "Confirmation de la modification de l'\u00E9tat de l'espace locataire",
  "chat.weChatConfig.label.search": "Rechercher",
  "ccprovision.recorddrive.config.sftp.accountname": "Compte du serveur SFTP",
  "provision.tenantspace.feature.customeragenttype": "Chef de file un \u00E0 un",
  "provision.callcenterinstanceslist.vcallcenterDeatil.configWorkNo": "ID de l'employ\u00E9 de gestion de la configuration",
  "chat.skill.label.skillName": "Nom",
  "chat.session.label.alias": "Nom du client",
  "chat.weChatConfig.message.deleteError": "Impossible de supprimer l'\u00E9l\u00E9ment de configuration.",
  "chat.client.alert.message.invildtoken": "Votre session a expir\u00E9 ou vous \u00EAtes connect\u00E9 ailleurs. Veuillez fermer la fen\u00EAtre de discussion et r\u00E9essayer. Merci.",
  "chat.weChatConfig.message.savesuccess": "Connexion r\u00E9ussie.",
  "chat.weChatConfig.link.wechat": "Contexte WeChat",
  "chat.client.label.forgotPassword": "Mot de passe oubli\u00E9",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenterDeatil": "D\u00E9tails du centre d'appels virtuel",
  "chat.skill.message.willDeleteSkills": "\u00CAtes - vous s\u00FBr de supprimer la comp\u00E9tence s\u00E9lectionn\u00E9e?",
  "chat.client.label.more": "PLUS D'INFORMATIONS",
  "chat.skill.label.maxQueueNumber": "Nombre maximal de personnes en file d'attente",
  "chat.skill.label.account": "Comptes",
  "chat.timerange.label.threedays": "Les 3 derniers jours",
  "chat.agent.title.createSkill": "Cr\u00E9er des comp\u00E9tences",
  "chat.webconfig.label.language": "Langue par d\u00E9faut",
  "chat.weChatConfig.label.Domain": "Adresse du serveur",
  "chat.client.message.noAgent": "D\u00E9sol\u00E9\, le service \u00E0 la client\u00E8le est occup\u00E9. Veuillez r\u00E9essayer plus tard.",
  "provision.tenantspace.dataUpload.cdrData": "Donn\u00E9es de facturation",
  "chat.webconfig.message.demodirective": "Vous pouvez \u00E9galement ouvrir l'URL suivante dans la fen\u00EAtre furtive du navigateur Chrome pour acc\u00E9der \u00E0 la page de d\u00E9monstration fournie par le syst\u00E8me.",
  "chat.client.label.no": "NON",
  "chat.usefulexpressions.label.expressionDetail": "D\u00E9tails de la phrase commune",
  "chat.weChatConfig.label.wechat": "WeChat",
  "provision.tenantspace.feature.ivr.analysis": "Analyse RVI",
  "provision.tenantspace.feature.ivr.analysis.description": "La fonction d'analyse IVR est activ\u00E9e et le locataire prend en charge la fonction d'analyse IVR.",

  "provision.tenantspace.feature.customercenter.open.confirmmsg": "La fonctionnalit\u00E9 appcube, qui est la base de fonctionnement de la fonctionnalit\u00E9 du Centre client, sera activ\u00E9e simultan\u00E9ment",
  "provision.tenantspace.feature.customercenter": "Centre client",
  "provision.tenantspace.common.customercenter.explain": "Activer les fonctionnalit\u00E9s du Centre client, fournir une gestion locale des clients pour les locataires, peut enregistrer des informations sur l'historique des diff\u00E9rents canaux de contact pour le m\u00EAme client",
  "provision.tenantspace.feature.appcube.close.confirmmsg": "Apr\u00E8s la fermeture, les donn\u00E9es professionnelles dans appcube (par exemple, les donn\u00E9es des ordres de travail, les donn\u00E9es de contact, etc.) seront effac\u00E9es et les donn\u00E9es ne pourront pas \u00EAtre r\u00E9cup\u00E9r\u00E9es, veuillez confirmer si oui ou non",
  "provision.tenantspace.feature.customdata.open.customcenter.confirmmsg": "Ouvrez des propri\u00E9t\u00E9s de donn\u00E9es personnalis\u00E9es et les propri\u00E9t\u00E9s du Centre client seront automatiquement d\u00E9sactiv\u00E9es",
  "provision.tenantspace.feature.mobileAppH5": "Version de mobileapp H5",
  "provision.tenantspace.common.mobileAppH5.explain": "Activez la fonctionnalit\u00E9 mobileapp version H5 pour permettre aux locataires d'installer automatiquement les fonctionnalit\u00E9s li\u00E9es aux si\u00E8ges mobiles sur l'appcube, en prenant en charge l'acc\u00E8s par t\u00E9l\u00E9phone",
  "provision.tenantspace.feature.mobileAppH5.open.confirmmsg": "La fonctionnalit\u00E9 appcube est la base d\u2019ex\u00E9cution des fonctionnalit\u00E9s mobileapp et s\u2019ouvre simultan\u00E9ment",

  "provision.tenantspace.feature.customercenter.close.error ": " Avant de d\u00E9sactiver la fonction Customer Center\, assurez-vous que la fonction Case 2.0 est d\u00E9sactiv\u00E9e.",
  "provision.tenantspace.feature.whatsapp.cloudapi": "Whatsapp Cloud Api Plus",
  "provision.tenantspace.common.whatsapp.cloudapi.explain": "Une fois cette fonctionnalit\u00E9 activ\u00E9e\, les locataires peuvent configurer le canal pour se connecter directement \u00E0 l'API WhatsApp Cloud pour traiter les messages texte. Actuellement\, cette fonctionnalit\u00E9 ne prend en charge que la d\u00E9monstration et ne peut pas \u00EAtre utilis\u00E9e commercialement.",


  "provision.tenantspace.config.error.148": "Le syst\u00E8me d'authentification a \u00E9t\u00E9 associ\u00E9 \u00E0 l'utilisateur f\u00E9d\u00E9r\u00E9 et ne peut pas \u00EAtre modifi\u00E9.",
  "provision.tenantspace.vdn.id": "ID VDN",
  "provision.callcent.selectccgatewayserver": "Choisissez CC-Gateway Server.",
  "UEE.WEB.VALIDATOR.RANGE_LENGTH": "Entrez une cha\u00EEne compos\u00E9e de caract\u00E8res compris entre {0} et {1}.",
  "chat.skill.label.skill": "Capacit\u00E9",
  "chat.session.label.subChannelTime": "subChannelTime Canal",
  "provision.tenant.management.expiryDate": "ExpiryDate",

  "provision.log.config.agent.track.config.title": "Configuration du suivi de l'agent",
  "provision.log.config.agent.track.config.refresh": "Refresh",
  "provision.log.config.agent.track.config.tenantSpaceName": "Nom de l'espace locataire",
  "provision.log.config.agent.track.config.workNo": "ID de l'agent",
  "provision.log.config.agent.track.config.tenantSpaceName.error.hints": "Seuls les chiffres, les lettres et les soulignements sont autorisés.",
  "provision.log.config.agent.track.config.tenantSpaceName.length.error.hints": "Veuillez saisir un nom de 8 à 30 caractères commençant par une lettre et ne contenant que des chiffres et des soulignements.",
  "provision.log.config.agent.track.config.logLevel": "Niveau journal",
  "provision.log.config.agent.track.config.createTime": "Temps de création",
  "provision.log.config.agent.track.config.endTime": "Heure de fin de suivi",
  "provision.log.config.agent.track.config.operation": "Opération",
  "provision.log.config.agent.track.config.delete": "Supprimer",
  "provision.log.config.agent.track.config.batch.delete": "Suppression par lots",
  "provision.log.config.agent.track.config.add": "Nouveau",
  "provision.log.config.agent.track.config.batchDelete.hints.pleaseSelect": "Veuillez sélectionner les informations de configuration du journal de suivi de l'agent.",
  "provision.log.config.agent.track.config.batchDelete.hints.exceed.max.operate.num": "Un maximum de 100 enregistrements peuvent être supprimés à la fois.",
  "provision.log.config.agent.track.config.create.title": "Créer une configuration de suivi d'agent",
  "provision.log.config.agent.track.config.create.confirm": "Confirmer",
  "provision.log.config.agent.track.config.create.cancel": "Annuler",
  "provision.log.config.agent.track.config.create.endTime.placeholder": "Veuillez sélectionner l'heure de fin du suivi.",
  "provision.log.config.agent.track.config.create.must": "Obligatoire",
  "provision.log.config.agent.track.config.create.tenantSpaceName.min.length": "Le nom de l'espace locataire ne peut pas contenir moins de 8 caractères.",
  "provision.log.config.agent.track.config.create.workNol.check.hints": "Entrez un entier compris entre 101 et 50000.",
  "provision.log.config.agent.track.config.create.workNol.check.hints.1": "Entrez un entier inférieur à 50000.",
  "provision.log.config.agent.track.config.create.end.check.hints": "Veuillez sélectionner un point dans les trois prochains jours.",
  "provision.log.config.agent.track.config.success": "succès",
  "provision.log.config.agent.track.config.failed": "Échec",
  "provision.log.config.agent.track.config.delete.success": "Suppression réussie.",
  "provision.log.config.agent.track.config.delete.failed.11": "Échec de la suppression",
  "provision.log.config.agent.track.config.delete.failed.10": "Une erreur s'est produite lors de la suppression du paramètre d'opération.",
  "provision.log.config.agent.track.config.create.top.hints": "Une fois que le suivi des journaux de l'agent du locataire est activé, l'agent connecte l'appel et collecte les journaux de suivi générés dans CCManagement. Cette opération affecte les performances du système. Activez cette opération en fonction des besoins réels de traçage.",
  "provision.log.config.agent.track.config.create.success": "Création réussie",
  "provision.log.config.agent.track.config.create.failed": "Échec de la création",
  "provision.log.config.agent.track.config.create.failed.1": "La longueur du nom de l'interlocuteur n'est pas valide.",
  "provision.log.config.agent.track.config.create.failed.2": "Le nom de l'interlocuteur contient des caractères spéciaux.",
  "provision.log.config.agent.track.config.create.failed.3": "Échec de la vérification du nom de l'interlocuteur.",
  "provision.log.config.agent.track.config.create.failed.4": "Échec de la vérification de l'ID de travail.",
  "provision.log.config.agent.track.config.create.failed.5": "Échec de la vérification du niveau de journal.",
  "provision.log.config.agent.track.config.create.failed.6": "Échec de la vérification de l'heure de fin de suivi.",
  "provision.log.config.agent.track.config.create.failed.7": "Le nombre maximum est dépassé.",
  "provision.log.config.agent.track.config.create.failed.8": "La configuration de suivi du journal pour l'agent existe déjà.",
  "provision.log.config.agent.track.config.create.failed.9": "Échec de la création.",
  "provision.log.config.agent.track.config.max.length": "La longueur maximale est",

  "provision.tenant.management.tenantname.contact.blocklist.check": "Caractères non valides.Les caractères spéciaux ne sont pas autorisés : & < > \" ; % / \\ ^ [ ] = + @",
}
