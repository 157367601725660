export default {
  "sm.assign.employee": "มอบหมายพนักงาน",
  "SM.USERGROUP.SHOW.LIST": "กลุ่มผู้ใช้งาน",
  "common.baseui.LABEL.HEADING_BASEINFO": "ข้อมูลพื้นฐาน",
  "SM.USERGROUP.LABEL.NAME": "ชื่อกลุ่มผู้ใช้",
  "common.bremui.label.description": "คำอธิบาย",
  "SM.SERVLET.EMPLOYEE": "พนักงาน",
  "common.statemachineui.button.add": "เพิ่ม",
  "sm.add.usergroup": "การสร้างกลุ่มผู้ใช้",
  "sm.edit.usergroup": "แก้ไขกลุ่มผู้ใช้",
  "SM.ORGSTAFF.BUTTON.COMMON_OK": "ตกลง",
  "SM.COMMON.LABEL.SYSMENU_SUSPENDED": "ปิดการใช้งาน",
  "SM.ORGSTAFF.MESSAGE.OPERATESUCCESS": "ดำเนินการสำเร็จ",
  "SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_CONTBEADMIN_ERR": "ไม่สามารถใช้บัญชีผู้ดูแลระบบเป็นบัญชีต้นทาง",
  "SM.COMMON.BUTTON.BANKCARD_SEARCH": "ค้นหา",
  "SM.ORGSTAFF.MESSAGE.BE_DELETESUCCESS": "ลบสำเร็จ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_DELETE_FAILED": "ไม่สามารถลบผู้ใช้ได้",
  "SM.ORGSTAFF.TIPS.INVALID_IP_ADDRESS": "ที่อยู่ IP ไม่ถูกต้อง",
  "SM.ORGSTAFF.LABEL.ACCESS.ADD_FAIL": "การเพิ่มล้มเหลว",
  "SM.ORGSTAFF.TITLE.CONTACT_DESC": "รายละเอียด",
  "SM.COMMON.LABEL.AREALIST": "รายการพื้นที่",
  "SM.COMMON.LABEL.CURRENCY_DECIMALSYMBOL": "สัญลักษณ์ทศนิยม",
  "SM.COMMON.TIPS.CHARGECODE_CODEINPUT": "คุณสามารถป้อนได้เฉพาะตัวอักษรและตัวเลขเท่านั้น",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT": "Secondment",
  "SM.COMMON.LABEL.CURRENCY_STANDARD_MEASUREMENT": "ค่าสถานะหน่วยมาตรฐาน",
  "SM.COMMON.LABEL.HOLIDAY_THURSDAY": "วันพฤหัสบดี",
  "SM.COMMON.LABEL.SYSMENU_AUTHID": "ID การอนุญาตเมนู",
  "SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_USEPARENT": "หากมีการใช้ข้อมูลโหนดแม่ การกำหนดค่าเขตเวลาปัจจุบันจะถูกล้างคุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ",
  "SM.ORGSTAFF.TITLE.CONTACT_TYPE": "ประเภทการติดต่อ",
  "SM.ORGSTAFF.TITLE.BE_PRECISION": "ความถูกต้องแม่นยำของสกุลเงิน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SET_ROLE": "ตั้งค่าบทบาท",
  "SM.ORGSTAFF.MESSAGE.EMPLOYEE_SECONDMENT_CONFIRM": "คุณแน่ใจหรือไม่ว่าจะยกเลิก",
  "SM.ORGSTAFF.LABEL.ORG_BASICINFO": "ข้อมูลพื้นฐาน",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_OPERARION": "การดำเนินการ",
  "SM.ORGSTAFF.LABEL.BANK_TYPE": "ประเภทธนาคาร",
  "SM.COMMON.BUTTON.SURVEY_DELETE_ANSWER_OPTIONS": "ลบตัวเลือกคำตอบ",
  "SM.COMMON.MESSAGE.SYSMENU_DEACTIVATED_NOT_SUPPORT": "สถานะเป็นเมนูที่ถูกทิ้ง ไม่รองรับการจัดการสิทธิ์",
  "SM.ORGSTAFF.TIPS.RESTRICTIP": "ใส่ที่อยู่ IP ในรูปแบบ xx.* หรือ xx.xx.* หรือ xx.xx.xx.*.",
  "SM.ORGSTAFF.TITLE.CONTACT_EMAIL": "อีเมล",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_ENDDATE": "วันที่สิ้นสุด",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_BE": "BE",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_VIEWFILE": "ดู",
  "SM.ORGSTAFF.MESSAGE.ORG_PARENT_FORBID": "OU หลักถูกระงับ",
  "SM.COMMON.LABEL.ATTR_LANGUAGE": "ภาษา",
  "SM.ORGSTAFF.LABEL.TEAM_ASSIGN": "สามารถมอบหมายพนักงานได้สูงสุด 2,000 คน",
  "SM.COMMON.LABEL.ATTACHMENT_DEL_ACCESSORY": "ลบ",
  "SM.ORGSTAFF.LABEL.SEARCH_ALL": "ค้นหาพนักงานทั้งหมด",
  "SM.ORGSTAFF.LABEL.ADDR_DISPLAY_ORDER": "ลำดับการแสดงที่อยู่ไม่ถูกต้อง",
  "SM.COMMON.LABEL.MEASUREMENT_DESTMEAUNIT": "หน่วยวัดเป้าหมาย",
  "SM.ORGSTAFF.MESSAGE.CHOOSE_SELECTED_OU_FIRST": "โปรดเลือกโหนดในโครงสร้างความสัมพันธ์ขององค์กรก่อน",
  "SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_EQUALTOTARGET_ERROR": "บัญชีต้นทางไม่สามารถมีอยู่ในบัญชีปลายทางได้",
  "SM.ORGSTAFF.TITLE.ROLE_BASIC": "บทบาทพื้นฐาน",
  "SM.ORGSTAFF.LABEL.STAFF_SESSION_VALIDITY_PERIOD": "ระยะเวลาที่ถูกต้องของเซสชัน (ขั้นต่ำ)",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT": "หน่วยเงินตรา",
  "SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_EMPLOYEE": "ส่งออกทั้งหมด",
  "SM.ORGSTAFF.LABEL.DEPTMACRESTRICT": "ข้อจำกัดเกี่ยวกับที่อยู่ MAC",
  "SM.ORGSTAFF.LABEL.REGION_DAYLIGHTSAVINGTIME": "DST",
  "SM.ORGSTAFF.MESSAGE.BE_CANNOTDELETECURRENCY": "ไม่สามารถลบสกุลเงินเริ่มต้นได้",
  "SM.ORGSTAFF.TITLE.CONTACT_FULL_NAME": "ชื่อเต็ม",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_VST": "ประเภทการหมดเวลาเซสชัน",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_LIST": "หน่วยการวัดสกุลเงิน",
  "SM.COMMON.LABEL.MEASUREMENT_CONV_LIST": "รายการแปลงการวัด",
  "SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB": "ป้อนอักขระอย่างน้อย 0 ตัว",
  "SM.ORGSTAFF.LABEL.REGION_DESCRIPTION": "รายละเอียด",
  "SM.COMMON.LABEL.BANKCARD_ACCT_LENG_PARAM": "ความยาวหมายเลขบัตร",
  "SM.COMMON.LABEL.SURVEY_CLASS_SORT": "ลำดับการจำแนก",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_IP_TYPE": "ประเภทของ IP",
  "SM.COMMON.LABEL.CURRENCY_DISPFORMAT_DISPLAYTYPE": "ประเภทการแสดงผล",
  "SM.COMMON.LABEL.SYSMENU_CONFIGURESYSMENU": "กำหนดค่าเมนูระบบ",
  "SM.ORGSTAFF.LABEL.TEAM_ORG_EMPLOYEE": "จัดระเบียบพนักงาน",
  "SM.ORGSTAFF.LABEL.REGION_DST_ENDDATE": "วันสิ้นสุด DST",
  "SM.ORGSTAFF.LABEL.ORG_STOP_TIPS": "หลังจากการระงับแล้ว องค์กรหรือหน่วยงานและหน่วยงานในสังกัดจะไม่สามารถใช้งานได้ คุณยืนยันที่จะระงับหรือไม่?",
  "SM.ORGSTAFF.TITLE.OPPAUTH_TITLE_MUTEXAUTH_INFOLIST": "รายการสิทธิ์การเข้าใช้แบบพิเศษ",
  "SM.COMMON.LABEL.ATTACHMENT_DELETE_INFO": "คุณแน่ใจหรือไม่ว่าจะลบไฟล์ ?",
  "SM.ORGSTAFF.OPTION.STAFF_REMOVE_PERMISSION": "ลบสิทธิ์การเข้าใช้ของผู้ดำเนินการเป้าหมาย",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_SUCCESS": "ความสำเร็จ",
  "SM.ORGSTAFF.TITLE.CONTACT_TITLE": "หัวข้อ",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_CANCLE": "ยกเลิก",
  "SM.ORGSTAFF.LABEL.REGION_DST_SWITCHINTERVAL": "DST Offset",
  "SM.COMMON.LABEL.SYSMENU_AFFECTED_MENU": "เมนูที่ได้รับผลกระทบ",
  "SM.ORGSTAFF.MESSAGE.ENDEARLIERSTART": "วันที่เริ่มต้นต้องไม่เกินวันที่สิ้นสุด",
  "SM.COMMON.LABEL.MEASUREMENT_UPLOAD_FILE": "อัปโหลดไฟล์",
  "SM.COMMON.LABEL.SYSMENU_USEPARENT": "ใช้ข้อมูลหลัก",
  "SM.COMMON.LABEL.EDIT_CURRENCY_EXCHANGE_RATE": "แก้ไขอัตราแลกเปลี่ยน",
  "SM.COMMON.LABEL.HOLIDAY_FRIDAY": "วันศุกร์",
  "SM.COMMON.LABEL.CURRENCY_TYPE_SYMBOL": "สัญลักษณ์",
  "SM.COMMON.LABEL.SYSMENU_MENUNAME": "ชื่อเมนู",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MAX": "ไฟล์ที่อัปโหลดมีขนาดเกินขนาดสูงสุด 0 KB",
  "SM.COMMON.LABEL.SURVEY_ITEM_NAME": "ชื่อรายการ",
  "SM.COMMON.TIPS.POPUP_INFO_TITLE": "ข้อมูล",
  "SM.COMMON.LABEL.CHARGECODE_STATUS": "สถานะ",
  "SM.COMMOM.MESSAGE.SYSMENU_MENU_STATUS_NOT_CONSISTENT": "สถานะเมนูไม่สอดคล้องกัน",
  "SM.COMMON.MESSAGE.ERATE_NUMBER_INVALID": "หมายเลขไม่ถูกต้อง",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_HEAD": "หัวหน้าแบบสอบถาม",
  "SM.COMMON.LABEL.MODULE_SHORTNAME": "ชื่อสั้น",
  "SM.ORGSTAFF.LABEL.REGION_DST_MONTHDAY": "เดือน/วัน",
  "SM.TENANTSPACE.AUTHTYPE.TITLE": "ประเภทของการยืนยันตัวตน",
  "SM.ORGSTAFF.MESSAGE.DSTOFFSET.OUTRANAGE": "DST Offset ไม่ได้อยู่ในช่วง 1 ถึง 86400",
  "SM.ORGSTAFF.LABEL.WORKGROUP_CONTENTS": "เนื้อหาของ Workgroup Path",
  "SM.ORGSTAFF.MESSAGE.SELECT_EMPLOYEE": "กรุณาเลือกพนักงาน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD": "รหัสผ่านใหม่",
  "SM.COMMON.MESSAGE.EXPORT_VIEW": "โปรดไปที่เมนู \"การจัดการระบบ\" เพื่อปรับแต่งวิศวกรรม\" เพื่อดูผลลัพธ์การส่งออก",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMLEVER": "ระดับของทีม",
  "SM.ORGSTAFF.LABEL.ROLE_TYPE_PUB": "บทบาทสาธารณะ",
  "SM.ORGSTAFF.TITLE.BE_CURRENCY": "สกุลเงิน",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_QUERY_LIST": "รายชื่อพนักงาน",
  "SM.COMMON.BUTTON.MEASUREMENT_ADD": "ใหม่",
  "SM.COMMON.LABEL.SEARCH_NAME": "กรุณาใส่ชื่อ",
  "SM.COMMON.LABEL.HOLIDAY_HOLIDAYDAY": "วันหยุด",
  "SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT_NO_PERIOD": "เลือกพนักงาน",
  "SM.ORGSTAFF.TITLE.ASSIGNED_MANAGER": "ผู้จัดการที่ได้รับมอบหมาย",
  "SM.TENANTSPACE.LDAP_RDNPREFIX": "RDN Prefix",
  "SM.ORGSTAFF.MESSAGE.EXPORT_LIMIT": "ไม่แนะนำให้ทำการส่งออกมากกว่า 10,000 รายการ",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_BE": "BE",
  "SM.COMMON.LABEL.OPERLOG_ENDTIME": "เวลาสิ้นสุด",
  "SM.COMMON.LABEL.CURRENCY_TYPE_CODE": "รหัส",
  "SM.ORGSTAFF.BUTTON.DELETE": "ลบ",
  "SM.COMMON.BUTTON.OK": "ตกลง",
  "SM.ORGSTAFF.BUTTON.ADDR_ADD": "เพิ่ม",
  "SM.COMMON.LABEL.CHARGECODE_CODE": "รหัส",
  "SM.ORGSTAFF.BUTTON.ADDR_CREATE": "ใหม่",
  "SM.COMMON.LABEL.SURVEY_APPLICATION_SCOPE": "ขอบเขตการใช้งาน",
  "SM.COMMON.TITLE.SYSMENU_ERROR": "ผิดผลาด",
  "SM.COMMON.LABEL.SYSMENU_DEACTIVATED": "ทิ้ง",
  "SM.COMMON.LABEL.SURVEY_OPTION_NAME": "ชื่อตัวเลือก",
  "SM.ORGSTAFF.LABEL.ORG_SHORT_NAME": "ชื่อย่อของ OU",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_CLEAR": "ชัดเจน",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_CREATE": "แบบสอบถามใหม่",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_DESC": "รายละเอียด",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_VALID": "ไม่ถูกต้อง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR": "อีเมลไม่ถูกต้อง",
  "SM.COMMON.LABEL.CHARGECODE_TEST": "ทดสอบ",
  "SM.COMMON.LABEL.SURVEY_CREATED_TIME": "สร้างเมื่อ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNTRANGE": "ช่วงบัญชี",
  "SM.ORGSTAFF.LABEL.REGION_DST_MONTHWEEKDAY": "เดือน/สัปดาห์/วัน",
  "SM.ORGSTAFF.LABEL.TEAM_STATUS": "สถานะ",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_EXT_TITLE": "การอัปโหลดล้มเหลว อนุญาตให้อัปโหลดประเภทแฟ้ม: 0 ไม่อนุญาตให้อัปโหลดประเภทแฟ้ม: {1}",
  "SM.ORGSTAFF.MESSAGE.ERROR_QUERY": "การค้นหาข้อมูลล้มเหลว",
  "SM.COMMON.LABEL.BANKCARD_BANKTYPE_PARAM": "ประเภทธนาคาร",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR": "ชื่อผู้ใช้มีอักขระพิเศษที่ไม่ถูกต้อง",
  "SM.COMMON.LABEL.CHARGECODE": "รหัสการเรียกเก็บเงิน",
  "SM.ORGSTAFF.TITLE.ORG_ASSIGNED_EMP": "พนักงานที่ได้รับมอบหมาย",
  "SM.ORGSTAFF.MESSAGE.SAVE_SUGGESTION": "ข้อมูล",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_EDIT": "แก้ไขแบบสอบถาม",
  "SM.COMMON.MESSAGE.CHARGECODE_PERFORM_DELETION": "คุณแน่ใจหรือว่าต้องการลบ?",
  "SM.COMMON.BUTTON.CHARGECODE_ADD": "ใหม่",
  "SM.COMMON.MESSAGE.ERATE_CODE_COMPARE": "รหัสสกุลเงินต้นทางต้องไม่เหมือนกับรหัสสกุลเงินเป้าหมาย",
  "SM.ORGSTAFF.LABEL.BANK_LEVEL": "ระดับของธนาคาร",
  "SM.ORGSTAFF.LABEL.RESTRICTTYPE": "ประเภทของการจำกัดการลงชื่อเข้าใช้",
  "SM.COMMON.LABEL.HOLIDAY_WEDNESDAY": "วันพุธ",
  "SM.ORGSTAFF.TITLE.CONTACT_PHONE": "หมายเลขโทรศัพท์",
  "SM.ORGSTAFF.LABEL.BE_ADDRESS_FORMAT": "เทมเพลตรูปแบบที่อยู่",
  "SM.ORGSTAFF.LABEL.ORG_DISPLAYORDER": "ลำดับการแสดงผล",
  "SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE": "รหัสผ่านไม่เป็นไปตามกฎรหัสผ่าน",
  "SM.COMMON.LABEL.SYSMENU_LABEL": "เมนูระบบ",
  "SM.COMMON.MESSAGE.AREA_DELETE_INFO": "คุณแน่ใจหรือว่าต้องการลบ?",
  "SM.ORGSTAFF.TITLE.STAFF_ROLE_ASSIGN": "กำหนดบทบาท",
  "SM.ORGSTAFF.OPTION.WEEKDAY_FRI": "วันศุกร์",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_RESETPWD": "รีเซ็ตรหัสผ่าน",
  "SM.COMMON.LABEL.MODULE_MODULE": "โมดูล",
  "SM.ORGSTAFF.TITLE.STAFF_LOGIN_RESTRICT": "ข้อจำกัดในการลงชื่อเข้าใช้",
  "SM.COMMON.LABEL.MODULE_ADDRESS": "URL",
  "SM.ORGSTAFF.TITLE.CURRENCY_DISPLAY_FORMAT": "รูปแบบการแสดงสกุลเงิน",
  "SM.COMMON.LABEL.MEASUREMENT_IMPORT_RESULT": "นำเข้าผลลัพธ์",
  "SM.ORGSTAFF.OPTION.STAFF_IP_SEGMENT": "ส่วนของที่อยู่ IP",
  "SM.COMMON.TITLE.ACCESS.SELECT_STAFF": "เลือกพนักงานที่มีสิทธิ์เข้าถึง",
  "SM.COMMON.MESSAGE.SYSMENU_ACCESS_TIME_ERROR": "เวลาสิ้นสุดของเวลาในการเข้าถึงเมนูควรมากกว่าเวลาเริ่มต้น",
  "SM.ORGSTAFF.MESSAGE.CONTAINS_SUB_ORGS": "องค์กรประกอบด้วยองค์กรย่อย",
  "SM.ORGSTAFF.TITLE.ORG_ACCESS_EMP": "เข้าถึงพนักงานที่ได้รับมอบหมาย",
  "SM.COMMON.LABEL.BANKCARD_ACCT_LENG": "ความยาวหมายเลขบัตร",
  "SM.COMMON.LABEL.SYSMENU_VISITCOUNT": "นับการเยื่ยมชม",
  "SM.ORGSTAFF.BUTTON.OURELATIONSHIP_RELOAD": "รีเฟรช",
  "SM.COMMON.LABEL.OPERLOG_ACCOUNT": "ชื่อบัญชี",
  "SM.COMMON.LABEL.BANKCARD_SEARCH_CONDITION": "เงื่อนไขการค้นหา",
  "SM.COMMON.LABEL.ATTACHMENT_ADD_ACCESSORY": "ใหม่",
  "SM.COMMON.MESSAGE.CHARGECODE_TO_TEST": "แน่ใจไมว่าต้องการเปลี่ยนเป็นสถานะทดสอบ ?",
  "SM.COMMON.LABEL.SYSMENU_MENUURL": "URL เมนู",
  "SM.ORGSTAFF.AUTHTYPE.LABEL": "ประเภทการยืนยันตัวตน",
  "SM.COMMON.LABEL.ERATE_TODATE": "ถึง",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_TEAMINFO": "ข้อมูลของทีม",
  "SM.COMMON.TIPS.HOME_PHONE": "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้องเช่น (+) (0123-) 1234567890 (-0123)",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_TITLE": "หน่วยการวัดสกุลเงิน",
  "SM.COMMON.LABEL.SURVEY_CLASS_Code": "รหัสการจำแนกประเภท",
  "SM.COMMON.LABEL.SEARCHCONDITION": "เกณฑ์การค้นหา",
  "SM.ORGSTAFF.MESSAGE.SAVESUCCESS": "ดำเนินการสำเร็จ",
  "SM.TENANTSPACE.LDAP_DOMAIN": "โดเมน",
  "SM.ORGSTAFF.LABEL.ORGANIZATION": "องค์กร",
  "SM.COMMON.BUTTON.ADD_MENU_ACCESS_TIME": "เพิ่มเวลาในการเข้าถึงเมนู หากไม่ได้กำหนดค่าเมนูจะสามารถเข้าถึงได้ตลอดเวลา",
  "SM.AUC.LABEL.BANK_NAME_INPUT": "ใส่ชื่อธนาคาร",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_SUCCESS": "การเปลี่ยนรหัสผ่านพนักงานสําเร็จแล้ว ไม่สามารถเปลี่ยนแปลงสิ่งต่อไปนี้ได้:",
  "SM.ORGSTAFF.LABEL.ORGIMPORT_TIP": "ตรวจสอบให้แน่ใจว่าได้นำเข้าข้อมูลของ Carrier ถูกนำเข้าระบบแล้วและผู้ดำเนินการปัจจุบันเป็นผู้ดูแลระบบ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD": "ค่าของข้อมูลจะต้องเป็นตัวเลข, ตัวอักษร, ขีดเส้นใต้ (_), ยติภังค์ (-), มหัพภาค (.) และเครื่องหมายแอท ({'@'})",
  "SM.COMMON.LABEL.MENU_INFO_TITLE": "เมนูข้อมูลพื้นฐาน",
  "SM.COMMON.LABEL.HOLIDAY_YEAR": "ปี",
  "SM.COMMON.MESSAGE.DELETE_CONFIRM": "คุณแน่ใจหรือว่าต้องการลบ?",
  "SM.COMMON.LABEL.SYSMENU_ACTIVE_A": "เปิดใช้งาน",
  "SM.COMMON.LABEL.HOLIDAY_SATURDAY": "วันเสาร์",
  "SM.COMMON.BUTTON.SURVEY_ENABLE": "เปิดใช้งาน",
  "SM.ORGSTAFF.TITLE.OPERATION": "การดำเนินการ",
  "SM.ORGSTAFF.MESSAGE.FORBID_EXSIT_EMPLOYEE": "ล้มเหลวในการปิดการใช้งานแผนกอย่างถาวรเนื่องจากมีพนักงานในสังกัด",
  "SM.ORGSTAFF.LABEL.AUTHVIEW_AUTH_BENAME": "สิทธิ์การเข้าใช้หน้าหลักของ BE",
  "SM.COMMON.TIPS.MENU_REBUILD": "ข้อมูล",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SUSPEND": "ระงับ",
  "SM.ORGSTAFF.LABEL.REGION_SHORTNAME": "ชื่อย่อของภูมิภาค",
  "SM.ORGSTAFF.LABEL.STAFF_ADD_ROLE": "เพิ่มบทบาทความสัมพันธ์",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTID": "เอกสารแนบ",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_NOT_ALLOW_4A": "อนุญาตให้ลงชื่อเข้าใช้ผ่านทางหน้าเว็บโดยตรง",
  "SM.COMMON.LABEL.CURRENCY_TYPE_NAME": "ชื่อ",
  "SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_ALLOWED": "อนุญาต",
  "SM.COMMON.LABEL.OPERLOG_RESET": "รีเซ็ต",
  "SM.COMMON.LABEL.CHARGECODE_NAME": "ชื่อ",
  "SM.ORGSTAFF.LABEL.STAFF_DESC": "รายละเอียด",
  "SM.ORGSTAFF.LABEL.TEAM_DESCRIPTION": "รายละเอียด",
  "SM.COMMON.MESSAGE.DATADICT_DELETE_FAILED": "การลบล้มเหลว",
  "SM.COMMON.LABEL.BANKCARD_NAME": "ชื่อการ์ด",
  "SM.COMMON.BUTTON.CANCEL": "ยกเลิก",
  "SM.COMMON.LABEL.ATTACHMENT_DEL_SUCCESS": "ลบไฟล์อุปกรณ์เสริมสำเร็จแล้ว",
  "SM.ORGSTAFF.LABEL.TO_BE_ASSIGNED_ROLE": "บทบาทที่ได้รับมอบหมาย",
  "SM.COMMON.LABEL.SYSMENU_STATUS": "สถานะ",
  "SM.ORGSTAFF.OPTION.STAFF_RETAIN_PERMISSION": "รักษาสิทธิ์การเข้าใช้ของผู้ดำเนินการเป้าหมาย",
  "SM.COMMON.LABEL.MEASUREMENT_ENTITY_ID": "ID",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_SUNIT": "หน่วยมาตรฐาน",
  "SM.COMMON.MESSAGE.PROPERTY_SUCCESS_CREATE_INFO": "ดำเนินการสำเร็จ",
  "SM.ORGSTAFF.BUTTON.TEAM_OK": "ตกลง",
  "SM.COMMON.MESSAGE.CHARGECODE_DELETE_DATA": "แน่ใจไมว่าต้องการลบรหัสการเรียกเก็บเงิน ?",
  "SM.COMMON.MESSAGE.VALIDATE_RANGELENGTHB": "ป้อนค่า 0 ถึง 1 อักขระ",
  "SM.COMMON.LABEL.OPERLOG_OBJECT_TYPE": "ประเภทอ็อบเจกต์",
  "SM.COMMON.BUTTON.BANKCARD_CANCEL": "ยกเลิก",
  "SM.ORGSTAFF.TITLE.BE_ADDRESSSETTING": "ที่อยู่",
  "SM.ORGSTAFF.LABEL.ORG_EMPLOYEE_QUERY_INFO": "เงื่อนไขการค้นหา",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_MEA": "หาค่า",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLODATPERSON": "อัปโหลดโดย",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SEARCH": "ค้นหา",
  "SM.COMMON.BUTTON.MENUVSEARCH": "สร้างดัชนีเมนูใหม่",
  "SM.ORGSTAFF.TITLE.CONTACT_HOME_FAX": "เครื่องโทรสารที่บ้าน",
  "SM.COMMON.LABEL.CREATE_OPER": "สร้างโดย",
  "SM.COMMON.LABEL.ERATE_DCURRENCY_CODE": "รหัสสกุลเงินเป้าหมาย",
  "SM.COMMON.LABEL.CURRENCY_DISPLAY_POSTFIX": "แสดง Postfix",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_DESC": "รายละเอียด",
  "SM.ORGSTAFF.TITLE.ADDRESS_DISPLAY_FORMAT": "รูปแบบของการแสดงผลที่อยู่",
  "SM.ORGSTAFF.TITLE.ADDR_NAME": "ชื่อ",
  "SM.COMMON.LABEL.MEASUREMENT_ENTITY_CODE": "รหัสหน่วย",
  "SM.COMMON.MESSAGE.SYSMENU_DELSUCCESS": "ลบเรียบร้อยแล้ว",
  "SM.ORGSTAFF.LABEL.INPUT_ROLE_NAME": "กรุณาใส่ชื่อบทบาท",
  "SM.ORGSTAFF.TIPS.DESC": "ไม่ต้องป้อนอักขระพิเศษ<>",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD": "ตรวจสอบรหัสผ่านของพนักงานล้มเหลว",
  "SM.ORGSTAFF.TITLE.CONTACT_OFFICE_FAX": "โทรสารสำนักงาน",
  "SM.COMMON.LABEL.CURRENCY_FORMAT_DISPLAY": "ดูตัวอย่างรูปแบบ",
  "SM.ORGSTAFF.AUTHTYPE.LDAP": "LDAP",
  "SM.PROJECT.TITLE.IMPORT_ORG_PAR": "นำเข้า Par Package",
  "SM.ORGSTAFF.MESSAGE.DSTFLAG_WITHOUT_TIMEZONE": "ไม่มีเขตเวลาที่ไม่สามารถเปิดการปรับเวลาให้เร็วขึ้นได้",
  "SM.COMMON.LABEL.MEASUREMENT_CONV_EDIT": "แก้ไขการแปลงหน่วยการวัด",
  "SM.ORGSTAFF.BUTTON.BE_COPYPARENT": "สำเนาข้อมูลของโหนดแม่",
  "SM.ORGSTAFF.LABEL.ORG_FORBID": "ปิดการใช้งาน",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_TYPE": "ประเภทของหน้าที่",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_ID": "ID",
  "SM.COMMON.LABEL.OPERLOG_STARTTIME": "เวลาเริ่มต้น",
  "SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_TIMESAME": "เวลาเริ่มต้นและเวลาสิ้นสุดของเขตเวลาต้องไม่เหมือนกัน",
  "SM.COMMON.LABEL.HOLIDAY_SUNDAY": "วันอาทิตย์",
  "SM.COMMON.TIPS.ERATE_ERATE_TIPS": "ป้อนสตริงตัวเลขบวกสูงสุด 13 ตำแหน่งและทศนิยม 6 ตำแหน่งและค่าสุดท้ายต้องไม่เป็น 0",
  "SM.ORGSTAFF.LABEL.REGION_CODE": "รหัสภูมิภาค",
  "SM.ORGSTAFF.TITLE.REGION_ADMINREGION": "เขตการปกครอง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_NATIONALITY": "สัญชาติ",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_VALUE_TYPE": "ประเภทของค่า",
  "SM.COMMON.MESSAGE.OPERATESUCCESS": "ดำเนินการสำเร็จ",
  "SM.ORGSTAFF.TITLE.SUCCESS": "ข้อมูล",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_RATIO": "อัตราส่วนหน่วยการวัด",
  "SM.COMMON.TITLE.SURVEY_QUESTION_NAME_INFO": "ข้อมูลชื่อคำถาม",
  "SM.COMMON.BUTTON.RESET": "รีเซ็ต",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_PASSWORD": "รหัสผ่านปัจจุบัน",
  "SM.COMMON.BUTTON.ADD_ADD": "เพิ่ม",
  "SM.COMMON.LABEL.ATTACHMENT_SAVE_SUCCESS": "เพิ่มไฟล์แนบเรียบร้อยแล้ว",
  "SM.ORGSTAFF.TITLE.TEAM_NAMEEXIST": "มีชื่อทีมอยู่แล้ว",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNTRANGE": "ช่วงของบัญชี",
  "SM.COMMON.BUTTON.CHARGECODE_SAVE": "บันทึก",
  "SM.COMMON.LABEL.OPERLOG_EMPLOYEE_NAME_NORMAL": "ชื่อพนักงาน",
  "SM.COMMON.LABEL.BANKCARD_BANKCODE": "รหัสธนาคารที่ออกบัตร",
  "SM.COMMON.LABEL.SURVEY_INTERLOCUTION": "ถาม - ตอบ",
  "SM.COMMON.BUTTON.MEASUREMENT_DELETE": "ลบ",
  "SM.ORGSTAFF.LABEL.ORG_IMPORT": "นำเข้า",
  "SM.COMMON.MESSAGE.CHARGECODE_TO_RELEASE": "แน่ใจไมว่าต้องการเปลี่ยนสถานะเป็นการปล่อยสาย?",
  "SM.COMMON.LABEL.CHARGECODE_FORBIDEN_BE": "ต้องห้าม BE",
  "SM.COMMON.MESSAGE.OPERATE_FAIL": "การดำเนินการล้มเหลว",
  "SM.COMMON.MESSAGE.CURRENCY_MEASUREMNET_CONFIRMCOVERMSG": "ประเภทสกุลเงินปัจจุบันมีหน่วยสกุลเงินมาตรฐานหรือหน่วยสกุลเงินขั้นต่ำอยู่แล้ว แน่ใจหรือไม่ว่าต้องการดำเนินการต่อ?",
  "SM.ORGSTAFF.TITLE.RELATION_CONFIRM_RECOVER": "หลังจากกลับมาดำเนินการต่อ ความสัมพันธ์ระดับองค์กรและและความสัมพันธ์ของระดับที่รองลงมาจะพร้อมใช้งาน คุณยืนยันที่จะดำเนินการต่อหรือไม่",
  "SM.ORGSTAFF.LABEL.ACCESS.USER_REQUIRED": "โปรดเลือกพนักงาน",
  "SM.ORGSTAFF.TITLE.REGION_IPRANGE": "ส่วนของ IP",
  "SM.ORGSTAFF.BUTTON.REMOVE_OUT": "ลบออก",
  "SM.ORGSTAFF.TITLE.ADDR_DESCRIPTION": "รายละเอียด",
  "SM.ORGSTAFF.TIPS.LOGINID": "ห้ามใส่อักขระพิเศษ ยกเว้น@",
  "SM.COMMON.LABEL.SURVEY_CLASS_SORT_DESCRIPTION": "ช่วงการใส่คือ 1-100",
  "SM.ORGSTAFF.TITLE.COMMON_QUERYCONDITION_TITLE": "เงื่อนไขการค้นหา",
  "SM.ORGSTAFF.LABEL.ORG_SELECTREGION": "เลือกภูมิภาค",
  "SM.ORGSTAFF.MESSAGE.ERROR.SAVE": "ผิดพลาด",
  "SM.ORGSTAFF.MESSAGE.BE_SELECTREGION": "เลือกภูมิภาค",
  "SM.ORGSTAFF.MESSAGE.BE_MAKE_SURE_DELETE": "คุณแน่ใจหรือว่าต้องการลบสมาชิก",
  "SM.ORGSTAFF.MESSAGE.ADDR_DELETE_CONFIRM": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิก",
  "SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPEXIST": "ที่อยู่ IP ซ้ำซ้อน",
  "SM.COMMON.TITLE.SUCCESS": "ข้อมูล",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_IS_LOCKED": "ผู้ใช้ถูกล็อก",
  "SM.ORGSTAFF.TITLE.ASSIGNE_EMPLOYEE": "แต่งตั้งพนักงาน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PWDRULEGROUP": "กฎระเบียบของกลุ่มบัญชีและรหัสผ่าน",
  "SM.COMMON.BUTTON.MEASUREMENT_EXPORT": "นำออก",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_SELECTORG": "เลือก OU",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTDESC": "คำอธิบาย",
  "SM.ORGSTAFF.TITLE.IMPORT_MAC_TITLE": "นำเข้าข้อจำกัดในการลงชื่อเข้าใช้",
  "SM.ORGSTAFF.OPTION.WEEKLIST_FTH": "ลำดับที่ 4",
  "SM.COMMON.MESSAGE.SAVESUCCESS": "ดำเนินการสำเร็จ",
  "SM.ORGSTAFF.LABEL.TEAM_EMPLOYEE": "พนักงาน",
  "SM.ORGSTAFF.BUTTON.FORBID_FOREVER": "ปิดใช้งานอย่างถาวร",
  "SM.COMMON.LABEL.CURRENCY_GROUPSIZE": "ขนาดกลุ่ม",
  "SM.COMMON.LABEL.OPERLOG_OBJECT_ID": "แสดงตัววัตถุ",
  "SM.COMMON.LABEL.OPERLOG_RESULT": "ผลการดำเนินการ",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP": "แบบสอบถาม",
  "SM.COMMON.MESSAGE.OPERATEFAIL": "การดำเนินการล้มเหลว",
  "SM.ORGSTAFF.MESSAGE.BE_SUBBE": "BE ระดับล่าง, OU, ทีม หรือมีการกำหนดค่าบทบาทต่าง ๆ บน BE",
  "SM.ORGSTAFF.MESSAGE.STAFF_INFOAUTH_CONFIRM": "โปรดยืนยันว่าข้อมูลพนักงานที่ป้อนได้รับอนุญาตจากฉัน",
  "SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION": "ลบคำถาม",
  "SM.ORGSTAFF.TITLE.RELATION_CONFIRM_SUSPEND": "หลังจากทำการระงับแล้ว ความสัมพันธ์ขององค์กรและหน่วยงานในสังกัดจะไม่สามารถใช้งานได้ คุณยืนยันที่จะระงับหรือไม่",
  "SM.ORGSTAFF.LABEL.STAFF_LOCALE": "ภาษาเริ่มต้น",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_STATUS": "สถานะ",
  "SM.ORGSTAFF.MESSAGE.SRC_ACCOUNT": "ไม่มีบัญชีต้นทาง",
  "SM.ORGSTAFF.MESSAGE.CONTACT_ACCOUNT": "บัญชี",
  "SM.COMMON.LABEL.ACCESS_RELATION": "องค์กรที่สามารถเข้าถึงได้",
  "SM.COMMON.TIPS.SPECIAL_CHAR_AVAILABLE": "ค่าที่ป้อนต้องเป็นตัวเลขอัลฟาหรือ _",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_CREATE_FAILD": "การสร้างพนักงานล้มเหลว กรุณายืนยันข้อมูลบัญชี",
  "SM.ORGSTAFF.OPTION.WEEKDAY_TUE": "วันอังคาร",
  "SM.ORGSTAFF.BUTTON.CANCEL": "ยกเลิก",
  "SM.COMMON.BUTTON.BANKCARD_SAVE": "บันทึก",
  "SM.COMMON.TITLE.ERROR": "ผิดผลาด",
  "SM.ORGSTAFF.LABEL.REGION_DST_STARTDATE": "เวลาเริ่มต้นของ DST",
  "SM.COMMON.LABEL.AREANAME": "ชื่อพื้นที่",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMCODE": "รหัสทีม",
  "SM.COMMON.LABEL.MEASUREMENT_ENTITY_NAME": "ชื่อหน่วย",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_ORG": "องค์กรเคลื่อนที่",
  "SM.COMMON.LABEL.BANKCARD_BANKCODE_PARAM": "รหัสธนาคารที่ออกบัตร",
  "SM.ORGSTAFF.TITLE.CONTACT_MOBILE_PHONE": "หมายเลขโทรศัพท์เคลื่อนที่",
  "SM.ORGSTAFF.LABEL.ORG_CHANNEL_CLASS": "การจำแนกประเภทของช่องทาง",
  "SM.COMMON.BUTTON.TOP": "ไปด้านบน",
  "SM.AUC.LABEL.BANK_LIST": "รายชื่อธนาคาร",
  "SM.COMMON.TITLE.MEASUREMENT_STDLIST": "รายการระบบการวัด",
  "SM.COMMON.LABEL.ERATE_RANGEDATE": "ระยะเวลาที่ใช้ได้",
  "SM.COMMON.LABEL.BANKCARD_OPERATION": "การดำเนินการ",
  "SM.ORGSTAFF.TITLE.BE_INFORMATION": "ข้อมูล",
  "SM.ORGSTAFF.OPTION.MONTH_12": "เดือนธันวาคม",
  "SM.ORGSTAFF.OPTION.MONTH_11": "เดือนพฤศจิกายน",
  "SM.ORGSTAFF.MESSAGE.TIMEZONE_TIME_ERROR": "เวลาเริ่มต้นต้องไม่มากกว่าหรือเท่ากับเวลาสิ้นสุด",
  "SM.ORGSTAFF.LABEL.REGION_LAYER": "ระดับภูมิภาค",
  "SM.ORGSTAFF.OPTION.MONTH_10": "เดือนตุลาคม",
  "SM.ORGSTAFF.OPTION.WEEKDAY_SAT": "วันเสาร์",
  "SM.ORGSTAFF.LABEL.REGION_NAME": "ชื่อภูมิภาค",
  "SM.COMMON.MESSAGE.SYSMENU_CONFIRM_MODIFY_MENU_STATUS_TO": "คุณแน่ใจหรือว่าคุณต้องการ:",
  "SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG_PARAM": "ความยาวของรหัสธนาคารที่ออกบัตร",
  "SM.COMMON.BUTTON.CHARGECODE_EDIT": "ปรับเปลี่ยน",
  "SM.ORGSTAFF.LABEL.BE_ADDRESS_DISPLAYFORMAT": "เทมเพลตของรูปแบบการแสดงผลที่อยู่",
  "SM.COMMON.LABEL.HOLIDAY": "วันหยุด",
  "SM.ORGSTAFF.LABEL.ORG_CODE": "รหัส OU",
  "SM.ORGSTAFF.LABEL.USER_COUNT": "การนับจำนวนผู้ใช้",
  "SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_ERROR": "โปรดเลือกการวัดมาตรฐานที่ถูกต้อง",
  "SM.ORGSTAFF.TITLE.ADDR_LINE_NUMBER": "หมายเลขบรรทัด",
  "SM.COMMON.TIPS.DIGITS_ONLY": "กรุณาใส่ตัวเลข",
  "SM.COMMON.LABEL.BANKCARD_BANKNAME": "ชื่อธนาคารที่ออกบัตร",
  "SM.ORGSTAFF.MESSAGE.ADDR_DELETE_ERROR": "ความผิดพลาดในการลบ",
  "SM.ORGSTAFF.TITLE.REGION_TIMEZONE": "เขตเวลา",
  "SM.COMMON.LABEL.OPERLOG_FAILUER": "ล้มเหลว",
  "SM.COMMON.LABEL.HIGHRISKLOG": "การตรวจสอบการดำเนินงานที่มีความเสี่ยงสูง",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_NAME": "หน่วย",
  "SM.ORGSTAFF.TITLE.CONTACT": "ติดต่อ",
  "SM.ORGSTAFF.LABEL.BE_CODE": "รหัส BE",
  "SM.COMMON.LABEL.SURVEY_SINGAL_CHOOSE": "ทางเลือกเดียว",
  "SM.ORGSTAFF.LABEL.BE_SHORT_NAME": "ชื่อย่อของ BE",
  "SM.ORGSTAFF.TITLE.CONTACT_ID": "ID ผู้ติดต่อ",
  "SM.ORGSTAFF.LABEL.TEAM_CODE": "รหัส OU",
  "SM.COMMON.MESSAGE.MODULE_MODULEADDRESSNOTMATCH": "ที่อยู่โมดูลไม่ตรงกัน",
  "SM.COMMON.LABEL.CHARGECODE_RELEASE": "ปล่อยสาย",
  "SM.COMMON.TITLE.SYSMENU_MENUNAME": "ข้อมูลชื่อเมนู",
  "SM.COMMON.LABEL.SYSMENU_SELECTORGANIZATION": "เลือกสิทธิ์",
  "SM.ORGSTAFF.LABEL.TEAM_SEARCH": "ค้นหา",
  "SM.ORGSTAFF.TITLE.CHARGE_CODE": "รหัสค่าใช้จ่าย",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_INPUT": "กรุณาใส่ชื่อ OU",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT": "บัญชี",
  "SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_AND_SUB": "ส่งออกรายการที่เลือก",
  "SM.COMMON.BUTTON.SURVEY_NEW_QUESTION_TYPE": "ประเภทคำถามใหม่",
  "SM.ORGSTAFF.LABEL.BE_LANGUAGE": "ภาษา",
  "SM.COMMON.LABEL.HEADING_SEARCHCRITERIA": "เกณฑ์การค้นหา",
  "SM.COMMON.LABEL.SYSMENU_SEARCH_RESULT": "ผลการค้นหา",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_TIMERANGEERROR": "เวลาสิ้นสุดการลงชื่อเข้าใช้ต้องช้ากว่าเวลาเริ่มต้นการลงชื่อเข้าใช้ การผูกที่อยู่ MAC",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_FAILD": "การรีเซ็ตรหัสผ่านของพนักงานล้มเหลว",
  "SM.ORGSTAFF.TITLE.STAFF_INFO_ADD": "เพิ่มพนักงาน",
  "SM.ORGSTAFF.OPTION.EMPLOYEE_DELETE_ROLE": "ลบบทบาท",
  "SM.ORGSTAFF.TITLE.ADDRESS_FORMAT": "รูปแบบของที่อยู่",
  "SM.ORGSTAFF.LABEL.FUNCAUTH_OPPAUTHNAME": "สิทธิ์การเข้าใช้พิเศษร่วมกันทั้งสองฝ่าย",
  "SM.COMMON.LABEL.CURRENCY_DECIMALPOSITIONS": "ตำแหน่งทศนิยม",
  "SM.ORGSTAFF.BUTTON.INCREASE": "เพิ่ม",
  "SM.ORGSTAFF.LABEL.TEAM_PARENTORG": "OU หลัก",
  "SM.ORGSTAFF.TITLE.NEW_ORG": "การสร้างองค์กร",
  "SM.ORGSTAFF.TITLE.EDIT_ORG": "แก้ไของค์กร",
  "SM.ORGSTAFF.MESSAGE.INPUT_ORG_NAME": "โปรดป้อนชื่อองค์กร",
  "SM.ORGSTAFF.TITLE.NEW_ACCESS_USER": "เพิ่มการเข้าถึงพนักงาน",
  "SM.ORGSTAFF.TITLE.NEW_MANAGER": "เพิ่มผู้ดูแล",
  "SM.ORGSTAFF.TITLE.USER_ACCOUNT": "บัญชีพนักงาน",
  "SM.ORGSTAFF.MESSAGE.SEARCH_USERNAME": "ค้นหาชื่อพนักงาน",
  "SM.ORGSTAFF.TITLE.SELECT_ROLE": "Selecionar função",
  "SM.ORGSTAFF.MESSAGE.FOLLOW_TENANT": "ติดตามการกําหนดค่าผู้เช่า",
  "SM.ORGSTAFF.MESSAGE.SEARCH_ROLE_NAME": "ค้นหาชื่อบทบาท",
  "SM.ORGSTAFF.TIP.ACCESS_USER_DESC": "ไม่ใช่ขององค์กรนี้ แต่พนักงานที่ได้รับอนุญาตให้เข้าถึงและใช้ทรัพยากรขององค์กรนี้",
  "SM.COMMON.BUTTON.CHARGECODE_SUCCESS": "ข้อมูล",
  "SM.ORGSTAFF.LABEL.TEAM_MANAGER": "ผู้จัดการ",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_COUNT": "ปริมาณการนำออก",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTH": "วันเกิด",
  "SM.COMMON.LABEL.CREATE_TIME": "สร้างเมื่อ",
  "SM.ORGSTAFF.TITLE.COMMON_CONTACT_INFORMATION": "ข้อมูลติดต่อ",
  "SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_PATTERN": "โหมดทำสำเนา",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_IP": "ที่อยู่ IP",
  "SM.COMMON.LABEL.MEASUREMENT_NO": "ไม่",
  "SM.COMMON.TITLE.BANKCARD_ADD": "บัตรธนาคารใหม่",
  "SM.ORGSTAFF.MESSAGE.STAFF_RESUME_CONFIRM": "คุณแน่ใจหรือไม่ว่าต้องการเปิดใช้งานบัญชีพนักงานอีกครั้ง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_NAME": "พนักงาน",
  "SM.ORGSTAFF.MESSAGE.SELECT_REGION": "เลือกภูมิภาค",
  "SM.ORGSTAFF.LABEL.DEPTIPRESTRICT": "ข้อจำกัดของ IP",
  "SM.ORGSTAFF.LABEL.INPUT_REGION_NAME": "โปรดป้อนชื่อภูมิภาค",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_MIDDLENAME": "ชื่อกลาง",
  "SM.ORGSTAFF.TITLE.TEAM_OPERSUCCESS": "ดำเนินการสำเร็จ",
  "SM.COMMON.BUTTON.IMPORT": "นำเข้า",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMNAME": "ชื่อทีม",
  "SM.ORGSTAFF.MESSAGE.BE_COPYCURRENCY": "คุณแน่ใจหรือไม่ว่าต้องการทำสำเนาโหนดแม่",
  "SM.ORGSTAFF.TITLE.REGION_ADDRESS": "ที่อยู่",
  "SM.ORGSTAFF.LABEL.STAFFAUTH_AUTHSOURCE": "ที่มาของสิทธิ์การเข้าใช้",
  "SM.ORGSTAFF.LABEL.BE_DESCRIPTION": "รายละเอียด",
  "SM.ORGSTAFF.LABEL.ORG_DELETE_CONFIRM": "หลังจากลบองค์กรแล้ว บัญชีที่เกี่ยวข้องขององค์กรจะไม่สามารถกู้คืนได้และจะทำได้เพียงการสร้างขึ้นใหม่ได้เท่านั้น คุณต้องการลบหรือไม่",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_NAME_EXIST": "สร้างพนักงานไม่สำเร็จเนื่องจากมีบัญชีอยู่แล้วในระบบ",
  "SM.COMMON.LABEL.BANKCARD_BANKSIGN": "รหัสธนาคารที่ออกบัตร",
  "SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION_TYPE": "ลบประเภทคำถาม",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_RESET": "รีเซ็ต",
  "SM.COMMON.LABEL.NEW_CURRENCY_EXCHANGE_RATE": "อัตราแลกเปลี่ยนใหม่",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_TO": "ถึง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE": "บันทึก",
  "SM.COMMON.LABEL.OPERLOG_SUCCESS": "สำเร็จ",
  "SM.COMMON.MESSAGE.DATADICT_DELETE_SUCCESSFULLY": "ลบเรียบร้อยแล้ว",
  "SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS": "อารี แซนเทอ ท",
  "SM.COMMON.TIPS.AREA_DELETE_SUCCESS": "ลบเรียบร้อยแล้ว",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_FIRSTNAME": "ชื่อจริง",
  "SM.ORGSTAFF.TITLE.ERROR": "ผิดพลาด",
  "SM.ORGSTAFF.TITLE.REGION_ENDIP": "IP สิ้นสุด",
  "SM.ORGSTAFF.LABEL.ORG_NAME": "ชื่อ OU",
  "SM.ORGSTAFF.LABEL.ORG_IMPORTDATA": "นำเข้าข้อมูล",
  "SM.COMMON.LABEL.SURVEY_DRAFT": "ร่าง",
  "SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_NOTUSEPARENT": "หากข้อมูลโหนดแม่ถูกยกเลิก การกำหนดค่าโหนดแม่จะไม่ถูกใช้ คุณจะดำเนินการต่อหรือไม่",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ORG": "องค์กรที่เป็นเจ้าของ",
  "SM.ORGSTAFF.LABEL.BANK_CODE": "รหัสธนาคาร",
  "SM.COMMON.BUTTON.ADD": "ใหม่",
  "SM.ORGSTAFF.TITLE.CONTACT_OFFICE_PHONE": "โทรศัพท์สำนักงาน",
  "SM.COMMON.LABEL.THE_MENU": "?",
  "SM.ORGSTAFF.TIPS.UNLOCK.USER": "ป้อนรหัสผ่านของตัวดำเนินการปัจจุบันเพื่อยืนยัน:",
  "SM.COMMON.MESSAGE.CURRENCY_DECIMALNOZEROFLAG": "ลบ Last Zeros",
  "SM.COMMON.LABEL.ERATE_EFFDATE": "เวลาที่มีประสิทธิภาพ",
  "SM.COMMON.LABEL.HOLIDAY_IMPORTDATA": "นำเข้าข้อมูล",
  "SM.ORGSTAFF.LABEL.ONLINEOPERATORNUM": "ผู้ดำเนินการออนไลน์ทั้งหมด",
  "SM.ORGSTAFF.LABEL.ORG_PARENTORG": "OU หลัก",
  "SM.COMMON.LABEL.BANKCARD_NAME_PARAM": "ชื่อการ์ด",
  "SM.COMMON.LABEL.CHARGECODE_DRAFT": "ร่าง",
  "SM.COMMON.LABEL.OPERLOG_OPER_TYPE": "ประเภทการทำงาน",
  "SM.COMMON.TITLE.MEASUREMENT_INFO": "ข้อมูลพื้นฐาน",
  "SM.ORGSTAFF.TITLE.REGION_STARTIP": "IP เริ่มต้น",
  "SM.ORGSTAFF.LABEL.IMPORT_TEMPLATE": "เทมเพลตของไฟล์ที่นำเข้า",
  "SM.COMMON.LABEL.SURVEY_TITLE_NAME": "ชิ่อคำถาม",
  "SM.COMMON.LABEL.HOLIDAY_IMPORT": "นำเข้าวันหยุด",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_9": "กันยายน",
  "SM.TENANTSPACE.LDAP_BASICDN": "DN ขั้นพื้นฐาน",
  "SM.TENANTSPACE.LDAP_TITLE": "การยืนยันตัวตนผ่าน LDAP",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_8": "สิงหาคม",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_7": "กรกฎาคม",
  "SM.ORGSTAFF.LABEL.BE_NAME": "ชื่อ BE",
  "SM.COMMON.LABEL.CHARGECODE_SEARCH_CRITERIA": "เกณฑ์การค้นหา",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_6": "มิถุนายน",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_5": "พฤษภาคม",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_4": "เมษายน",
  "SM.ORGSTAFF.OPTION.WEEKLIST_SEC": "ลำดับที่ 2",
  "SM.ORGSTAFF.LABEL.WORKGROUP_NAME": "ชื่อกลุ่มทำงาน",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_3": "มีนาคม",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_2": "กุมภาพันธ์",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_1": "มกราคม",
  "SM.ORGSTAFF.LABEL.ORG_STOP": "ระงับ",
  "SM.COMMON.TIPS.CURRENCY_GROUPSIZE_TIPS": "แสดงจํานวนตัวเลขของแต่ละกลุ่มเมื่อแสดงการจัดกลุ่มจํานวนเงิน 0 หมายถึงไม่มีการจัดกลุ่ม",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL": "ปกติ",
  "SM.COMMON.LABEL.BANKCARD_BANKNAME_PARAM": "ชื่อธนาคารที่ออกบัตร",
  "SM.ORGSTAFF.TITLE.REGION_EX_ATTR": "คุณลักษณะเพิ่มเติม",
  "SM.COMMON.TITLE.SUCCESS_SUCCESS": "ดำเนินการสำเร็จ",
  "SM.COMMON.LABEL.MEASUREMENT_CONV_CREATE": "การแปลงการวัดใหม่",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMID": "Team ID",
  "SM.ORGSTAFF.BUTTON.ORG_EDIT": "ปรับเปลี่ยน",
  "SM.ORGSTAFF.TITLE.TEAM_ASSIGNEDEMPL": "พนักงานที่ได้รับมอบหมาย (ไม่สามารถรวมพนักงานได้มากกว่า 2000 คน)",
  "SM.ORGSTAFF.TITLE.STAFF_INFO_EXT": "ข้อมูลเพิ่มเติม",
  "SM.ORGSTAFF.TITLE.SELECT_ORG": "เลือกกลุ่มทำงาน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTHPLACE": "สถานที่เกิด",
  "SM.ORGSTAFF.MESSAGE.REFRESH_SUCCESS": "การรีเฟรชสำเร็จ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR": "ค่าของข้อมูลจะต้องเป็นตัวเลข, ตัวอักษร, ตัวอักษรจีน, ขีดเส้นใต้ (_), ยติภังค์ (-), มหัพภาค (.) และเครื่องหมายแอท (@)",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_IP_ADDR": "ที่อยู่ IP",
  "SM.ORGSTAFF.TITLE.TEAM_DELETEFSUCCESS": "ลบสำเร็จ",
  "SM.ORGSTAFF.LABEL.ORG_INFO": "ข้อมูล OU",
  "SM.COMMON.TIPS.CHARGECODE_NAME": "ค่าที่ป้อนต้องไม่มีอักขระพิเศษ",
  "SM.COMMON.MESSAGE.SYSMENU_AUTH_ASSIGNED_MUTIL_MENUS": "มีการอนุญาตในเมนูให้กับหลายเมนู หากคุณยกเลิกเมนูนี้สิทธิ์ทั้งหมดจะถูกเพิกถอน ดำเนินการต่อหรือไม่",
  "SM.COMMON.LABEL.MEASUREMENT_IGNORE_IMPORT": "ในระหว่างการนำเข้าแบตช์ไฟล์หากนำเข้าไฟล์ไม่สำเร็จการนำเข้าไฟล์ที่ตามมาจะดำเนินต่อไป",
  "SM.COMMON.TIPS.POPUP_LABEL_DELETE": "คุณแน่ใจหรือว่าต้องการลบ?",
  "SM.ORGSTAFF.AUTHTYPE.DEFAULT": "ค่าเริ่มต้น",
  "SM.ORGSTAFF.LABEL.BE_BIZCODE": "รหัสธุกิจ BE",
  "SM.COMMON.LABEL.SYSMENU_DIS_POSTION": "ตำแหน่งที่แสดง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID": "ระงับการใช้งาน",
  "SM.ORGSTAFF.LABEL.CREATESOURCE": "สร้างที่มา",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_FORBIDFOREVER": "ปิดใช้งานอย่างถาวร",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_CLIENT": "IP ลูกค้า",
  "SM.ORGSTAFF.TIPS.STAFF_LOCALE": "มีการใช้ภาษาล็อกอินเริ่มต้นของพนักงานเมื่อไม่ได้ทำการเลือกภาษาขณะเข้าสู่ระบบ",
  "SM.COMMON.TITLE.MEASUREMENT_UNIT": "ประเภทการหาค่า",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_EXT_TITLE": "ขนาดของไฟล์ที่จะอัปโหลดมีตั้งแต่ {1} KB ถึง 0 KB อนุญาต {2} ไฟล์ แต่ไม่อนุญาต {3} ไฟล์",
  "SM.SM.COMMON.TITLE.NO_CHARGECODE": "ไม่มีรหัสคิดเงิน",
  "SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_QUERY": "ค้นหา",
  "SM.ORGSTAFF.OPTION.WEEKLIST_FIV": "ลำดับที่ 5",
  "SM.ORGSTAFF.LABEL.ATTR_ATTRSETTING": "คุณลักษณะเพิ่มเติม",
  "SM.COMMON.LABEL.HIGHRISKLOG_SERVICENAME": "ชื่อบริการ",
  "SM.ORGSTAFF.MESSAGE.AUTHENTICATION": "ยืนยันตัวตน",
  "SM.COMMON.MESSAGE.DATADICT_SURE_DELETE": "คุณแน่ใจหรือว่าต้องการลบ?",
  "SM.ORGSTAFF.OPTION.WEEKLIST_FIR": "ลำดับแรก",
  "SM.COMMON.BUTTON.COMMON_ADD": "เพิ่ม",
  "SM.COMMON.LABEL.SYS_MEASUREMENT_METRIC": "แฟล็กระบบเมตริก",
  "SM.ORGSTAFF.TIPS.BE_LOGO": "เลือกไฟล์ภาพนามสกุล PNG, GIF หรือ JPG ที่มีขนาดไม่เกิน 50 KB และ 145*50 พิกเซล",
  "SM.COMMON.BUTTON.CHARGECODE_ADDMEMBER": "เพิ่มรหัสการเรียกเก็บเงิน",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_TAIL": "ท้ายแบบสอบถาม",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_MATCH_VALUE": "จับคู่ค่า",
  "SM.ORGSTAFF.LABEL.ORG_SUBTYPE": "ประเภทของ OU ย่อย",
  "SM.ORGSTAFF.MESSAGE.SELECT_ORG": "เลือก OU",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA": "เกณฑ์การค้นหา",
  "SM.COMMON.LABEL.SURVEY_ALLOW_INPUT": "อนุญาตให้ป้อนข้อมูลด้วยตนเอง",
  "SM.ORGSTAFF.LABEL.BE_ID": "BE ID",
  "SM.ORGSTAFF.LABEL.ROLE_TYPE_DUTY": "บทบาทหน้าที่",
  "SM.COMMON.LABEL.SURVEY_MODIFIED_ACCOUNT": "แก้ไขโดย",
  "SM.ORGSTAFF.LABEL.TEAM_TDISPLAYORDER": "ลำดับการแสดงผล",
  "SM.COMMON.LABEL.MENU_ACCESS_TIME": "เวลาเข้าถึงเมนู",
  "SM.COMMON.LABEL.ERATE_INFO_LIST": "รายการอัตราแลกเปลี่ยนเงินตรา",
  "SM.COMMON.LABEL.SYSMENU_LANGUAGE": "ภาษา",
  "SM.ORGSTAFF.BUTTON.IMPORT": "นำเข้า",
  "SM.COMMON.LABEL.MODULE_ID": "ID",
  "SM.COMMON.LABEL.OPERLOG_OPER_TIME": "เวลาทำการ",
  "SM.COMMOM.MESSAGE.BESEXCEPTION": "การดําเนินการปัจจุบันไม่สามารถดําเนินการเสร็จสิ้น โปรดติดต่อผู้ดูแลระบบ",
  "SM.COMMON.LABEL.SURVEY_PROVINCE": "ภูมิภาค",
  "SM.ORGSTAFF.LABEL.STAFF_ACCOUNT": "บัญชี",
  "SM.ORGSTAFF.BUTTON.RESET_TEAM": "รีเซ็ต",
  "SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID2": "ซึ่งมากกว่าจำนวนสูงสุด จำนวนสูงสุดคือ:",
  "SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID1": "จำนวนพนักงานที่มีบทบาทหน้าที่ในองค์กร",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTCODE": "ประเภทไฟล์แนบ",
  "SM.ORGSTAFF.LABEL.ORG_FORCEDELETE": "ลบอย่างถาวร",
  "SM.ORGSTAFF.TITLE.CURRENCY_TYPE": "ประเภทสกุลเงิน",
  "SM.COMMON.LABEL.HIGHRISKLOGList": "การตรวจสอบการดำเนินงานที่มีความเสี่ยงสูง",
  "SM.COMMON.LABEL.SURVEY_TITLE_SORT": "ลำดับคำถาม",
  "SM.ORGSTAFF.LABEL.BANK_NAME": "ชื่อธนาคาร",
  "SM.COMMON.LABEL.CURRENCY_CREATE_TITLE": "ข้อมูลพื้นฐานประเภทสกุลเงิน",
  "SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS": "หน่วย: วินาที ช่วง: 1 ถึง 86400 หากให้พารามิเตอร์นี้เว้นว่างไว้จะใช้ค่าเริ่มต้น 3600",
  "SM.COMMON.LABEL.SURVEY_MUTI_CHOOSE": "หลายทางเลือก",
  "SM.ORGSTAFF.OPTION.WEEKLIST_LST": "สุดท้าย",
  "SM.ORGSTAFF.TITLE.CURRENCY_EXCHANGE_RATE": "อัตราแลกเปลี่ยนเงินตรา",
  "SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG": "ความยาวของรหัสธนาคารที่ออกบัตร",
  "SM.COMMON.LABEL.CHARGE_CODE_GROUP": "กลุ่มรหัสการเรียกเก็บเงิน",
  "SM.COMMON.LABEL.CHARGECODE_GROUPTYPE": "ประเภทกลุ่ม",
  "SM.COMMON.BUTTON.PROPERTY_CREATE_GROUP": "กลุ่มแอตทริบิวต์เพิ่มเติมใหม่",
  "SM.COMMON.MESSAGE.DATADICT_SAVE_SUCCESSFULLY": "ดำเนินการสำเร็จ",
  "SM.ORGSTAFF.TITLE.ADDR_CFG_LIST": "รูปแบบการแสดงที่อยู่",
  "SM.ORGSTAFF.OPTION.WEEKDAY_THU": "วันพฤหัสบดี",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_SIGNTIME": "เวลาลงชื่อเข้าออก",
  "SM.COMMON.LABEL.HOLIDAY_MONDAY": "วันจันทร์",
  "SM.COMMON.LABEL.OPERLOG_MODULE": "โมดูล",
  "SM.COMMON.LABEL.SURVEY_OPTION_TYPE": "ประเภทตัวเลือก",
  "SM.ORGSTAFF.LABEL.ADDR_ADDRESS_NAME": "ชื่อของที่อยู่",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICT_MODE": "จำกัดโหมด",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_TEAM": "ทีมงาน",
  "SM.ORGSTAFF.TITLE.CONTACT_INFO": "ผู้จัดการ",
  "SM.COMMON.LABEL.OPERLOG_OBJECT_TYP_NAME": "ชื่อชนิดอ็อบเจกต์",
  "SM.COMMON.LABEL.SYSMENU_SELECT_MENUS": "เมนูที่เลือก",
  "SM.COMMON.BUTTON.SURVEY_NEW_QUESTION": "คำถามใหม่",
  "SM.COMMON.LABEL.MENU_ACCESS_END_TIME": "เวลาสิ้นสุด",
  "SM.ORGSTAFF.LABEL.AFFILIATED_BE": "BE ในเครือ",
  "SM.COMMON.LABEL.AREAINFO_MAINTAIN": "การจัดการรหัสพื้นที่",
  "SM.ORGSTAFF.LABEL.REGION_LEVEL": "ระดับภูมิภาค",
  "SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPFORMATERROR": "ที่อยู่ IP ไม่ถูกต้อง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_GENDER": "เพศ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_ALLSUCCESS": "การรีเซ็ทรหัสผ่านพนักงานสำเร็จ",
  "SM.ORGSTAFF.TIPS.ENTER_ORGNAME": "ใส่ชื่อกลุ่มทำงาน",
  "SM.COMMON.LABEL.SYSMENU_STATUSDATE": "สถานะวันที่",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_RATE": "ประเมิน",
  "SM.ORGSTAFF.TITLE.CONFIRM_DEL": "ยืนยัน",
  "SM.COMMON.LABEL.COLON": ":",
  "SM.COMMON.BUTTON.ACCESS_RELATION": "เพิ่มองค์กรการเข้าถึง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_MARITALSTATUS": "สถานะการสมรส",
  "SM.COMMON.BUTTON.PROPERTY_CREATE": "ใหม่",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE": "ปิดการใช้งาน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_STARTDATE": "วันที่เริ่มต้น",
  "SM.COMMON.BUTTON.ATTACHMENT_ADDACCESSORY": "บันทึก",
  "SM.COMMON.LABEL.MEASUREMENT_SOUMEASYS": "ระบบการวัดแหล่งที่มา",
  "SM.ORGSTAFF.MESSAGE.DELETE_TITLE": "ยืนยัน",
  "SM.COMMON.LABEL.ERATE_EXPDATE": "หมดเวลา",
  "SM.COMMON.TITLE.PRJ_EXPORT": "เริ่มต้นเพื่อนำออก",
  "SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_TARGETNO": "บัญชีเป้าหมาย",
  "SM.ORGSTAFF.TITLE.ERROR_TITLE": "ผิดพลาด",
  "SM.COMMON.MESSAGE.ERATE_TIME_COMPARE": "เวลาสิ้นสุดต้องช้ากว่าเวลาเริ่มต้น",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET": "รีเซ็ตรหัสผ่านพนักงาน",
  "SM.ORGSTAFF.LABEL.ADDR_DISP_DETAIL_LIST": "รายการของรายละเอียดรูปแบบ",
  "SM.ORGSTAFF.MESSAGE.TEAM_BLANK_VIEW": "จัดการทีมใน OU tree",
  "SM.COMMON.LABEL.BANKCARD_CREATETIME": "เพิ่มเวลา",
  "SM.ORGSTAFF.OPTION.EMPLOYEE_ADD_ROLE": "เพิ่มบทบาท",
  "SM.ORGSTAFF.LABEL.STAFF_INFO_BASIC": "ข้อมูลพื้นฐาน",
  "SM.ORGSTAFF.OPTION.STAFF_IP_SINGLE": "ที่อยู่ของ IP เดี่ยว",
  "SM.COMMON.TITLE.CONFIRM_DEL": "ยืนยัน",
  "SM.ORGSTAFF.OPTION.MONTH_9": "เดือนกันยายน",
  "SM.ORGSTAFF.OPTION.MONTH_8": "เดือนสิงหาคม",
  "SM.ORGSTAFF.OPTION.MONTH_7": "เดือนกรกฎาคม",
  "SM.COMMON.TITLE.SURVEY_CLASS_NAME_INFO": "ข้อมูลชื่อการจัดหมวดหมู่",
  "SM.COMMON.MESSAGE.SYSMENU_AUTHIDREQUIRE": "ใส่เมนูการอนุญาตID",
  "SM.ORGSTAFF.OPTION.MONTH_6": "เดือนมิถุนายน",
  "SM.ORGSTAFF.OPTION.MONTH_5": "เดือนพฤษภาคม",
  "SM.ORGSTAFF.OPTION.MONTH_4": "เดือนเมษายน",
  "SM.ORGSTAFF.TITLE.MEASUREMENT": "หน่วยวัด",
  "SM.ORGSTAFF.MESSAGE.ORG_NOT_EXSIT": "ไม่มี OU",
  "SM.ORGSTAFF.OPTION.MONTH_3": "เดือนมีนาคม",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME": "พนักงาน",
  "SM.ORGSTAFF.OPTION.MONTH_2": "เดือนกุมภาพันธ์",
  "SM.COMMON.LABEL.CURRENCY_TYPE_ID": "ID",
  "SM.ORGSTAFF.OPTION.MONTH_1": "เดือนมกราคม",
  "SM.COMMON.TIPS.BANKCARD_LENGTH": "ป้อนจำนวนเต็มที่มีตัวเลขไม่เกิน 2 หลัก",
  "SM.ORGSTAFF.TITLE.TEAM_SAVESUCCESS": "การดำเนินการสำเร็จ",
  "SM.ORGSTAFF.LABEL.BE_LOGO": "ตราสัญลักษณ์",
  "SM.ORGSTAFF.MESSAGE.MAX_TREE_LEVEL": "คุณสามารถสร้างองค์กรย่อยได้สูงสุด 6 ระดับเท่านั้น",
  "SM.PROJECT.TITLE.EXPORT_SELECTED_EMPLOYEE": "ส่งรายการที่เลือกออก",
  "SM.ORGSTAFF.LABEL.TEAM_INCLUDESUB": "รวม OU ย่อย",
  "SM.ORGSTAFF.LABEL.ORG_LEVEL": "ระดับของ OU",
  "SM.ORGSTAFF.TITLE.EMPTY": "ทั้งอีเมลและที่อยู่ติดต่อต้องไม่เว้นว่างไว้",
  "SM.ORGSTAFF.TITLE.COMMON_STAFF_INFO": "รายการบัญชีพนักงาน",
  "SM.COMMON.TITLE.ACCESS.SELECT_ALL_STAFF": "เลือกพนักงานทั้งหมดขององค์กร",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTNAME": "ชื่อไฟล์แนบ",
  "SM.COMMON.LABEL.SYSMENU_IDEL": "ปล่อยสาย",
  "SM.COMMON.LABEL.SYSMENU_MENUID": "IDเมนู",
  "SM.ORGSTAFF.LABEL.ROLETMPL_PERMISSION_NAME": "ชื่อของสิทธิ์การเข้าใช้ฟังก์ชั่น",
  "SM.COMMON.LABEL.CURRENCY_TYPE_INPUT_TIPS": "ใส่ชื่อประเภทสกุลเงิน",
  "SM.ORGSTAFF.LABEL.ADDR_ORDER": "ลำดับการแสดงผล",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP_LIST": "รายการแบบสอบถาม",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICTIONTYPE": "โหมดการประมวลผล",
  "SM.COMMON.LABEL.MEASUREMENT_SOUMEAUNIT": "หน่วยวัดแหล่งที่มา",
  "SM.ORGSTAFF.MESSAGE.ERROR.SAVEFAILED": "บันทึกไม่สำเร็จ",
  "SM.ORGSTAFF.LABEL.TEAM_ACCESS_EMPLOYEE": "เยี่ยมพนักงาน",
  "SM.ORGSTAFF.LABEL.NO": "ไม่ใช่",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_TERMINATE_SECONDMENT": "การสิ้นสุดการ Secondment",
  "SM.COMMON.LABEL.CHARGECODE_DESC": "คำอธิบาย",
  "SM.COMMON.LABEL.HOLIDAY_TUESDAY": "วันอังคาร",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_EXPORTALL": "ส่งออก",
  "SM.ORGSTAFF.OPTION.QUERYSUBDEPT": "ส่วนการค้นหาระดับล่าง",
  "SM.COMMON.LABEL.MEASUREMENT_DESTMEASYS": "ระบบการวัดเป้าหมาย",
  "SM.ORGSTAFF.MESSAGE.SELECTEDOU_CANNOT_SELECTED_AGAIN": "ไม่สามารถเลือกองค์กรที่เลือกไว้แล้วได้อีก",
  "SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM": "หลังจากการลบพนักงานแล้ว คุณจะไม่สามารถกู้คืนบัญชีพนักงานที่เกี่ยวข้องได้และจะทำการสร้างใหม่ได้เท่านั้น คุณต้องการลบหรือไม่",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_BE": "BE",
  "SM.ORGSTAFF.LABEL.REGION_DST_ENDTIME": "เวลาสิ้นสุดของ DST",
  "SM.TENANTSPACE.LDAP_SERVERURL": "URL ของเซิร์ฟเวอร์",
  "SM.ORGSTAFF.TITLE.BE_CURRENCYCODE": "รหัสสกุลเงิน",
  "SM.ORGSTAFF.TITLE.ATTR_ATTRSETTING": "คุณลักษณะเพิ่มเติม",
  "SM.COMMON.LABEL.BANKCARD_TYPE": "ประเภทบัตร",
  "SM.COMMON.MESSAGE.DELETE_SUCCESS": "ลบเรียบร้อยแล้ว.",
  "SM.COMMON.TIPS.NEED_TO_NEW_STD_MIN_CURRENCY_UNIT": "จำเป็นต้องกำหนดค่าหน่วยมาตรฐานของสกุลเงินและหน่วยที่เล็กที่สุด",
  "SM.COMMON.LABEL.OPERLOG_OPER_LIST": "รายการบันทึกการทำงาน",
  "SM.ORGSTAFF.LABEL.INH_BY_SUB": "อนุญาตให้มีการสืบทอดโดย OU ย่อย",
  "SM.COMMON.LABEL.MEASUREMENT_ERROR_DESCRIPTION": "คำอธิบายผิดผลาด",
  "SM.COMMON.LABEL.SYSMENU_SUSPENDED_S": "ปิดการใช้งาน",
  "SM.COMMON.LABEL.ERATE_DESCRIPTION": "คำอธิบาย",
  "SM.ORGSTAFF.TITLE.CHARGE_CODE_GROUP": "กลุ่มรหัสค่าใช้จ่าย",
  "SM.COMMON.TIPS.CURRENCY_DECIMALPOSITIONS_TIPS": "ค่า –1 ระบุว่าตำแหน่งทศนิยมจะแสดงขึ้นอยู่กับความแม่นยำในการจัดเก็บสกุลเงิน",
  "SM.COMMON.LABEL.OPERLOG_CONTENT": "รายละเอียดการดำเนินงาน",
  "SM.ORGSTAFF.LABEL.TEAM_MANAGERINFO": "ข้อมูลของผู้จัดการ",
  "SM.COMMON.MESSAGE.MODIFY_AREAID_TYPE": "ข้อมูลมีอยู่แล้ว เปลี่ยนรหัสพื้นที่หรือประเภท",
  "SM.ORGSTAFF.OPTION.YES": "ใช่",
  "SM.COMMON.LABEL.SYSMENU_ACTIVE": "เปิดใช้งาน",
  "SM.ORGSTAFF.LABEL.ORG_REGION": "ภูมิภาค",
  "SM.ORGSTAFF.BUTTON.ADD_IN": "เพิ่มเข้าไป",
  "SM.COMMON.LABEL.SYS_MEASUREMENT_DEFAULT": "การวัดค่าเริ่มต้น",
  "SM.ORGSTAFF.LABEL.STAFF_PASSWORD": "รหัสผ่านใหม่",
  "SM.ORGSTAFF.LABEL.TEAM_ORGANIZATION": "OU",
  "SM.COMMON.MESSAGE.CHARGECODE_TO_RETIRE": "แน่ใจไมว่าต้องการเปลี่ยนเป็นสถานะยกเลิก?",
  "SM.ORGSTAFF.LABEL.ACCESS.ORG_REQUIRED": "โปรดเลือกองค์กร",
  "SM.ORGSTAFF.TITLE.REGION_USEPARENT": "สืบทอดข้อมูลภูมิภาคที่สูงกว่า",
  "SM.ORGSTAFF.TITLE.CONTACT_MIDDLE_NAME": "ชื่อกลาง",
  "SM.ORGSTAFF.LABEL.STAFF_PASSWORDAGAIN": "ยืนยันรหัสผ่าน",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE": "เมนู",
  "SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER": "แต่งตั้งผู้จัดการ",
  "SM.COMMON.LABEL.HIGHRISKLOG_PERATOR": "ผู้ดำเนินการ",
  "SM.COMMON.BUTTON.MEASUREMENT_IMPORT": "นำเข้า",
  "SM.COMMON.LABEL.ATTACHMENT_ADD_TITLE": "เอกสารแนบใหม่",
  "SM.ORGSTAFF.MESSAGE.PWD.CHECKMSG": "รหัสผ่าน 2 รหัสแตกต่างกัน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_FORCE_DELETE": "บังคับลบ",
  "SM.ORGSTAFF.BUTTON.TEAM_CANCEL": "ยกเลิก",
  "SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT": "เลือกพนักงาน",
  "SM.COMMON.LABEL.SYSMENU_DEACTIVATED_D": "ทิ้ง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_RESUME": "ดำเนินการต่อ",
  "SM.ORGSTAFF.MESSAGE.STAFF_PWD_RESET_CONFIRM": "คุณแน่ใจหรือไม่ว่าจะรีเซ็ตรหัสผ่านใหม่",
  "SM.COMMON.LABEL.HOLIDAY_HOLIDAYDESCRIPTION": "คำอธิบาย",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_ADD": "เลือก OU",
  "SM.COMMON.LABEL.SYSMENU_CREATEDATE": "วันที่สร้าง",
  "SM.COMMOM.BUTTON.IMPORT": "นำเข้า",
  "SM.ORGSTAFF.TIPS.ENTER_EMPNAME": "กรุณาใส่ชื่อพนักงาน",
  "SM.ORGSTAFF.LABEL.ORG_ORGID": "OU ID",
  "SM.ORGSTAFF.MESSAGE.ADDR_USE_DELETE": "การลบล้มเหลวเนื่องจากมีการอ้างอิงที่อยู่",
  "SM.ORGSTAFF.MESSAGE.BLANK_VIEW": "จัดการทีมใน OU tree",
  "SM.ORGSTAFF.TITLE.CONTACT_EMPLOYEE_CODE": "พนักงานที่ติดต่อ",
  "SM.COMMON.MESSAGE.PROJECT_CODE": "รหัสโครงการคือ:",
  "SM.COMMON.LABEL.AREAID": "IDพื้นที่",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLOADTIME": "เวลาอัปโหลด",
  "SM.ORGSTAFF.LABEL.CHANNEL_ID": "Channel ID",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_INVALID": "ไม่ถูกต้อง",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "กรุณาใส่คีย์เวิร์ด",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_FILE_FORMAT": "รูปแบบไฟล์ที่นำออก",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_FAILURE": "ล้มเหลว",
  "SM.ORGSTAFF.MESSAGE.ORG_ROLE_DELETE": "หากคุณลบข้อมูลนี้ ความสัมพันธ์ระหว่างผู้ใช้และบทบาทจะถูกลบโดยอัตโนมัติ คุณจะดำเนินการต่อหรือไม่",
  "SM.COMMON.LABEL.SURVEY_DESC": "คำอธิบาย",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PVT": "ประเภทความถูกต้องของรหัสผ่าน",
  "SM.COMMON.LABEL.CHARGECODE_OPERATION": "การดำเนินการ",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTCODE": "ประเภทไฟล์แนบ",
  "SM.COMMON.LABEL.ERATE_SEARCH_CRITERIA": "เกณฑ์การค้นหา",
  "SM.COMMON.LABEL.MODULE_MODULETYPE": "หมวดหมู่",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_CREATEDATE": "วันที่สร้าง",
  "SM.COMMON.LABEL.OPERLOG_OBJECT_NAME": "ชื่ออ็อบเจกต์",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_LOGIONRESTRICTION": "ข้อจำกัดในการลงชื่อเข้าใช้",
  "SM.COMMON.LABEL.SYSMENU_PARENT": "เมนูไอดีการเข้าใช่งานบนสุด",
  "SM.COMMON.TIPS.PLZ_SAVE_CURRENCY_MEASUREMENT_UNIT": "กรุณาบันทึกหน่วยการวัดสกุลเงิน",
  "SM.COMMON.LABEL.CURRENCY_FORMAT_TITLE": "รูปแบบการแสดงสกุลเงิน",
  "SM.COMMON.LABEL.SYSMENU_GRANT_MENU_AUTH": "จัดการการอนุญาตเมนู",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST": "รายการไฟล์แนบ",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_MAC_ADDR": "ที่อยู่ MAC",
  "SM.COMMON.LABEL.MEASUREMENT_YES": "ใช่",
  "SM.ORGSTAFF.LABEL.ADDR_REGION_LAYER": "ระดับเขตการปกครอง",
  "SM.COMMON.TITLE.ACCESS.FILTER": "โปรดป้อนคำหลักเพื่อกรอง",
  "SM.ORGSTAFF.TITLE.TEAM_SUCCESS": "ข้อมูล",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_TITLE": "หัวข้อ",
  "SM.ORGSTAFF.OPTION.WEEKLIST_TID": "อันดับที่ 3",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_DETAIL": "รายละเอียด",
  "SM.ORGSTAFF.OPTION.WEEKDAY_WED": "วันพุธ",
  "SM.ORGSTAFF.LABEL.ORG_RECOVER": "ดำเนินการต่อ",
  "SM.ORGSTAFF.MESSAGE.DELETE_MSG": "แน่ใจไหมว่าต้องการลบ",
  "SM.ORGSTAFF.TITLE.BE_CURRENCYNAME": "ชื่อสกุลเงิน",
  "SM.COMMON.LABEL.CURRENCY_DISPLAY_PREFIX": "แสดงคำนำหน้า",
  "SM.COMMON.LABEL.ADD_EXCHANGE_RATE": "เพิ่ม / ปรับอัตราแลกเปลี่ยนของสกุลเงินที่เกี่ยวข้อง",
  "SM.COMMON.BUTTON.SAVE": "บันทึก",
  "SM.ORGSTAFF.LABEL.TEAM_REFRESH": "อัปเดต",
  "SM.COMMON.TITLE.ERATE_ERROR": "ผิดผลาด",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_NAME": "ชื่อบทบาท",
  "SM.ORGSTAFF.LABEL.TEAM_EMPLOYEENAME": "พนักงาน",
  "SM.COMMON.LABEL.SURVEY_TYPE_NAME": "ชื่อหมวดหมู๋",
  "SM.ORGSTAFF.LABEL.REGION_CONFIG_TIMEZONE": "ภูมิภาคไม่มีข้อมูลโซนเวลา กรุณากำหนดค่าเขตเวลาสำหรับภูมิภาคหรือภูมิภาคหลัก",
  "SM.ORGSTAFF.TITLE.IMPORT_EMPLOYEE_TITLE": "นำเข้าพนักงาน",
  "SM.COMMON.LABEL.HIGHRISKLOG_PARAMS": "พารามิเตอร์",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNT": "บัญชี",
  "SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_DISALLOWED": "ปฏิเสธ",
  "SM.ORGSTAFF.MESSAGE.BE_SELECT_DEFAULT_CURRENCY": "เลือกสกุลเงินเริ่มต้น",
  "SM.COMMON.BUTTON.CHARGECODE_CONFIRM": "ข้อมูล",
  "SM.ORGSTAFF.TITLE.CONTACT_FIRST_NAME": "ชื่อจริง",
  "SM.COMMOM.LABEL.SURVEY_INFO": "แบบสอบถามข้อมูล",
  "SM.ORGSTAFF.LABEL.BANK_CHILEBEUSED": "อนุญาตให้ BE ลูกใช้ได้",
  "SM.COMMON.LABEL.HEADING_BASEINFO": "ข้อมูลพื้นฐาน",
  "SM.ORGSTAFF.LABEL.TEAM_ADD": "ใหม่",
  "SM.ORGSTAFF.TITLE.TEAM_AVAILABLEEMPL": "พนักงานที่ใช้ได้",
  "SM.ORGSTAFF.LABEL.STAFF_INFO": "ข้อมูลพนักงาน",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_ID": "ID บทบาท",
  "SM.COMMON.LABEL.SYSMENU_VERSION": "เวอร์ชัน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_LASTNAME": "นามสกุล",
  "SM.ORGSTAFF.LABEL.TEAM_BUSINESSENTITY": "ชื่อ BE",
  "SM.COMMON.LABEL.SYSMENU_CURRENCYLANGUAGE": "ภาษาปัจจุบัน",
  "SM.ORGSTAFF.TITLE.CONTACT_HOME_PHONE": "โทรศัพท์บ้าน",
  "SM.ORGSTAFF.LABEL.CHANNEL_NAME": "ชื่อช่องทาง",
  "SM.COMMON.MESSAGE.CHARGECODE_DELETE_GROUP": "คุณแน่ใจหรือว่าต้องการลบกลุ่มรหัสการเรียกเก็บเงิน?",
  "SM.COMMON.LABEL.AREATYPE": "ประเภทพื้นที่",
  "SM.COMMON.LABEL.SYSMENU_IDEL_I": "ปล่อยสาย",
  "SM.COMMON.LABEL.MEASUREMENT_ENTITY_TYPE": "ประเภทหน่วย",
  "SM.COMMON.MESSAGE.CURRENCY_UNIT_TIPS": "กรุณาใส่หมายเลข",
  "SM.ORGSTAFF.LABEL.ORG_IMPORTSUCCESS": "นำเข้าข้อมูลสำเร็จ",
  "SM.COMMON.TIPS.MENU_REBUILD_INFO": "ข้อมูลการสร้างดัชนีเมนูใหม่ถูกส่งไปยังคิวข้อความแล้ว โปรดรอ.",
  "SM.ORGSTAFF.LABEL.SELECTED_ROLE": "บทบาทที่เลือก",
  "SM.COMMON.LABEL.MEASUREMENT_CONV_RATIO": "อัตราส่วนหน่วยการวัด",
  "SM.COMMON.LABEL.OPERLOG_SEARCH": "ค้นหา",
  "SM.COMMON.LABEL.SYSMENU_GRANT_AUTH": "จัดการการอนุญาต",
  "SM.ORGSTAFF.LABEL.BREGION_NAME": "ภูมิภาค",
  "SM.ORGSTAFF.TITLE.BE_DEFAULT": "สกุลเงินเริ่มต้น",
  "SM.COMMON.TITLE.MEASUREMENT_UNITLIST": "รายการหน่วยการวัด",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BE": "BE ID",
  "SM.COMMON.LABEL.HIGHRISKLOG_OPERATIONNAME": "ชื่อการดำเนินการ",
  "SM.COMMON.MESSAGE.DELETE_CURRENCY_STD_MIN_MEASUREMENT": "สกุลเงินต้องใช้หน่วยมาตรฐานและหน่วยขั้นต่ำคุณแน่ใจหรือไม่ว่าจะลบ ?",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_PATH": "Path",
  "SM.ORGSTAFF.TITLE.MSGBOX_INFO": "เคล็ดลับ",
  "SM.ORGSTAFF.OPTION.WEEKDAY_MON": "วันพุธ",
  "SM.COMMON.LABEL.CURRENCY_TYPE_FLAG": "เสมือน",
  "SM.COMMON.LABEL.OPERLOG_LOGIN_IP": "IP ที่ลงชื่อเข้าใช้",
  "SM.COMMON.LABEL.CHARGECODE_GROUPMEMBER": "รหัสการเรียกเก็บเงิน",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_NAME_LANG": "แบบสอบถามชื่อภาษา",
  "SM.COMMON.LABEL.CURRENCY_ROUNDMODE": "โหมดการปัดเศษ",
  "SM.ORGSTAFF.LABEL.ACCESS.ADD_SUCCESS": "การเพิ่มสําเร็จแล้ว",
  "SM.COMMON.LABEL.SYSMENU_MENU_AUTH_ROLE_LIST": "รายการบทบาทที่กำหนด",
  "SM.COMMON.TITLE.BANKCARD_CONFIRM": "ข้อมูล",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_12": "ธันวาคม",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_11": "พฤศจิยน",
  "SM.COMMON.LABEL.CHARGECODE_DATAGIRD_CATALOG": "แคตตาล็อก",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_10": "ตุลาคม",
  "SM.COMMON.TIPS.ONLYONE_UNDERLINE_FORBIDDEN": "ค่าที่ป้อนต้องไม่มีอักขระพิเศษ",
  "SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT": "กรุณาใส่ชื่อ",
  "SM.COMMOM.BUTTON.EDIT": "ปรับเปลี่ยน",
  "SM.ORGSTAFF.MESSAGE.SUCCESS.SAVE": "สำเร็จ",
  "SM.COMMON.LABEL.BANKCARD_BANKTYPE": "ประเภทธนาคาร",
  "SM.COMMON.LABEL.MEASUREMENT_NOTIFICATION_NAME": "ชื่อในการแจ้งเตือน",
  "SM.ORGSTAFF.BUTTON.ADDR_LINE": "สาย",
  "SM.ORGSTAFF.TITLE.TEAM_DELETE_CONFIRM": "ยืนยัน",
  "SM.COMMON.LABEL.CHARGECODE_RETIRE": "ยกเลิก",
  "SM.COMMON.TIPS.SPECIAL_CHAR_CANNOT_START_NUMBER": "ไม่อนุญาตให้ขึ้นต้นด้วยหมายเลข",
  "SM.COMMON.LABEL.CURRENCY_DISPLAY_FORMATE": "รูปแบบการแสดงผล",
  "SM.ORGSTAFF.LABEL.TEAM_CONTACTINFO": "ข้อมูลการติดต่อ",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTNAME": "ชื่อไฟล์แนบ",
  "SM.ORGSTAFF.LABEL.ADDR_ADDERSS": "ที่อยู่",
  "SM.ORGSTAFF.MESSAGE.SURE_TO_DO": "ดำเนินการต่อหรือไม่",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMSHORTNAME": "ชื่อย่อของทีม",
  "SM.ORGSTAFF.TITLE.BANK_TITLE": "ธนาคาร",
  "SM.COMMON.MESSAGE.CHARGECODE_OPERATION_SUCCESS": "ดำเนินการสำเร็จ",
  "SM.ORGSTAFF.MESSAGE.USERRESTRICT_CONFIRM_SAVE": "ส่วนเวลาลงชื่อเข้าใช้สามารถเก็บบันทึกได้เพียงรายการเดียวและส่วนเวลาลงชื่อเข้าใช้อื่น ๆ จะถูกลบเมื่อมีการเพิ่ม",
  "SM.COMMON.LABEL.BANKCARD_BANKSIGN_PARAM": "รหัสธนาคารที่ออกบัตร",
  "SM.COMMON.BUTTON.SURVEY_NEW_ANSWER_OPTIONS": "ตัวเลือกคำตอบใหม่",
  "SM.ORGSTAFF.TIPS.MAC_FORMAT_ERROR": "ความผิดพลาดของรูปแบบที่อยู่ MAC",
  "SM.COMMON.LABEL.MODULE_BASICINFO": "ข้อมูลพื้นฐาน",
  "SM.ORGSTAFF.LABEL.REGION_DST_MODE": "โหมด DST",
  "SM.COMMON.BUTTON.EDIT": "ปรับเปลี่ยน",
  "SM.COMMON.MESSAGE.ERATE_VLUEW_RANGE": "ค่ามีตั้งแต่ 0 ถึง {1}",
  "SM.COMMON.LABEL.PROVINCE": "จังหวัด",
  "SM.ORGSTAFF.LABEL.STAFF_OPRATION": "ผู้ดำเนินการ",
  "SM.COMMON.LABEL.ATTACHMENT_LIMIT_INFO": "ไม่มีข้อจำกัด",
  "SM.COMMON.LABEL.SYSMENU_DESCRIPTION": "เมนูคำอธิบาย",
  "SM.COMMON.MESSAGE.BANKCARD_DELETE_CONFIRM": "คุณแน่ใจหรือว่าต้องการลบ?",
  "SM.COMMON.MESSAGE.CURRENCY_UNIT_EXCHAGE_TIPS": "กรุณาใส่จำนวนเต็ม",
  "SM.ORGSTAFF.MESSAGE.CHOOSE_BE_FIRST": "กรุณาเลือก BE ก่อน",
  "SM.COMMON.LABEL.MODULE_NAME": "ชื่อ",
  "SM.ORGSTAFF.TITLE.MEASUREMENT_CONV": "การแปลงหน่วยวัด",
  "SM.COMMON.LABEL.SURVEY_REMARKS": "หมายเหตุ",
  "SM.ORGSTAFF.OPTION.ROLE_OPTION_MODE": "โหมดปฏิบัติการ",
  "SM.ORGSTAFF.LABEL.OU_HIERARCHY": "ลำดับขั้น OU",
  "SM.COMMON.BUTTON.VIEW": "ดู",
  "SM.COMMON.MESSAGE.CHARGECODE_CREATE_DATA": "แน่ใจหรือไม่ว่าต้องการเพิ่มรหัสค่าธรรมเนียมนี้",
  "SM.ORGSTAFF.BUTTON.ADD": "ใหม่",
  "SM.ORGSTAFF.MESSAGE.STAFF_SUSPEND_CONFIRM": "คุณแน่ใจหรือไม่ว่าต้องการระงับบัญชีพนักงาน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SVD": "ช่วงเวลาที่หมดเวลาเซสชัน",
  "SM.ORGSTAFF.MESSAGE.BANK_STATUS_ERROR": "การบันทึกล้มเหลวเนื่องจากข้อมูลธนาคารหมดอายุ",
  "SM.COMMON.LABEL.HOLIDAY_BASICINFORMATION": "ข้อมูลพื้นฐาน",
  "SM.ORGSTAFF.LABEL.ORG_ADDITIONINFO": "ข้อมูลเพิ่มเติม",
  "SM.ORGSTAFF.TITLE.BE_ADDRESS_CFG": "รูปแบบที่อยู่",
  "SM.ORGSTAFF.TITLE.STAFF_INFO_MDY": "ปรับเปลี่ยนพนักงาน",
  "SM.COMMON.TITLE.ACCESS.SELECT_ORG": "เลือกองค์กรการเข้าถึง",
  "SM.ORGSTAFF.MESSAGE.STAFF_FORBID_CONFIRM": "หลังจากที่พนักงานถูกปิดการใช้งานอย่างถาวร คุณจะสามารถสร้างบัญชีของพนักงานซ้ำได้อีกหลังจากที่ลบไปแล้วเท่านั้น คุณแน่ใจหรือไม่ว่าต้องการปิดการใช้งานพนักงานอย่างถาวร?",
  "SM.ORGSTAFF.MESSAGE.ERROR.SAVEACCESSLIMIT": "พนักงานที่สามารถเข้าถึงได้สามารถเพิ่มได้ถึง 100 ต่อชุดงาน",
  "SM.COMMON.LABEL.OU_HIERARCHY_NAME": "ชื่อของความสัมพันธ์ OU",
  "SM.COMMON.LABEL.BANKCARD_LIST": "รายการบัตรธนาคาร",
  "SM.COMMON.MESSAGE.MEASUREMENT_SAVE_CONFIRM": "เมื่อคุณบันทึกหน่วยวัดมาตรฐานจะแทนที่หน่วยมาตรฐานที่มีอยู่และแก้ไขหน่วยมาตรฐานของหน่วยวัดอื่น ๆ",
  "SM.COMMON.LABEL.SYSMENU_SEARCH_MENU": "เกณฑ์การค้นหา",
  "SM.ORGSTAFF.TITLE.ADDR_TEMPATE_ID": "เทมเพลต",
  "SM.COMMON.MESSAGE.SYSMENU_MENUNAMEREQUIRE": "ป้อนชื่อเมนู",
  "SM.ORGSTAFF.TITLE.ADDR_DISP_FORMAT": "รูปแบบการแสดงผล",
  "SM.ORGSTAFF.MESSAGE.STARTANDTEND_ISNULL": "วันเริ่มต้นและวันสิ้นสุดไม่สามารถเว้นว่างไว้พร้อมกันได้",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_TIMERANGE": "ช่วงเวลา",
  "SM.ORGSTAFF.LABEL.SELECT_EXCEL": "กรุณาเลือกไฟล์ excel",
  "SM.ORGSTAFF.TITLE.EMP_BELONG_DEPT": "แผนก",
  "SM.ORGSTAFF.LABEL.BUSINESSENTITY_DISPLAY_NO": "ลำดับการแสดงผล",
  "SM.COMMON.BUTTON.CHARGECODE_CANCEL": "ยกเลิก",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_ADDR": "ที่อยู่",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMTYPE": "ประเภทของทีม",
  "SM.COMMON.LABEL.SYSMENU_MENUCRUMB": "เส้นทาง",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_NAME": "ชื่อแบบสอบถาม",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD_CONFIRM": "การยืนยันรหัสผ่านใหม่",
  "SM.COMMON.LABEL.CURRENCY_TYPE": "ประเภทสกุลเงิน",
  "SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_RESET": "รีเซ็ต",
  "SM.COMMON.LABEL.MENU_ATTR_TITLE": "ขยายคุณสมบัติ",
  "SM.ORGSTAFF.LABEL.TEAM_REMOVE": "เอาออก",
  "SM.ORGSTAFF.BUTTON.SAVE": "บันทึก",
  "SM.COMMON.LABEL.CHOOSE_OU_ADD": "เลือก OU เพื่อทำการเพิ่ม",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_COPYPERMISSION": "การอนุญาตให้ทำสำเนา",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD41": "การล็อคเนื่องจากการล็อกอินล้มเหลวติดต่อกันภายในระยะเวลาที่กำหนด เป็นนาที",
  "SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPRANGEERROR": "มีการกำหนดค่าที่อยู่ IP อย่างไม่ถูกต้อง",
  "SM.ORGSTAFF.MESSAGE.ERROR.MAXLIMIT": "จำนวนสถานประกอบการภายใต้ผู้เช่าต้องไม่เกิน 999",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD40": "กฎที่กำหนดเอง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_ACCOUNT": "บัญชี",
  "SM.COMMON.LABEL.CREATE_AREAINFO_MAINTAIN": "ข้อมูลภูมิภาคใหม่",
  "SM.COMMON.TITLE.BANKCARD_MDF": "แก้ไขบัตรธนาคาร",
  "SM.ORGSTAFF.TIPS.RESTRICTMAC": "กรุณาใส่ที่อยู่ของ MAC ให้ถูกต้อง",
  "SM.ORGSTAFF.TITLE.QUERYFAIL_TITLE": "การค้นหาล้มเหลว",
  "SM.COMMON.BUTTON.CREATE": "ใหม่",
  "SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB": "อนุญาตให้ใช้อักขระสูงสุดได้ 0 ตัวอักษร",
  "SM.COMMON.LABEL.HOLIDAY_HOLIDAYTYPE": "ชนิด",
  "SM.COMMON.LABEL.SURVEY_MODIFIED_TIME": "เวลาปรับเปลี่ยน",
  "SM.COMMON.LABEL.OPERLOG_SUCCESS_MEW": "ประสบความสำเร็จ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD39": "รหัสผ่านต้องประกอบด้วยตัวเลขเท่านั้น",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD38": "อักขระที่ไม่สามารถอยู่ในรหัสผ่านได้",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD37": "รหัสผ่านต้องไม่เหมือนกับบัญชีหรือส่วนย่อยของบัญชี",
  "SM.COMMON.MESSAGE.CHARGECODE_TO_DRAFT": "แน่ใจหรือไม่ว่าต้องการเปลี่ยนเป็นสถานะแบบร่าง ?",
  "SM.COMMON.BUTTON.BANKCARD_RESET": "รีเซ็ต",
  "SM.COMMON.MESSAGE.SYSMENU_MAX_EDIT_LIMIT": "ขีดจำกัดการแก้ไขสูงสุด",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD36": "รายการรูปแบบรหัสผ่านที่ต้องห้าม เช่น ABCABC และ ABCDEF",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD35": "รหัสผ่านเริ่มต้น",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD34": "ระยะเวลาที่ใช้งานได้ของรหัสผ่านต่ำสุด เป็นชั่วโมง",
  "SM.COMMON.LABEL.MENU_ACCESS_STRART_TIME": "เวลาเริ่มต้น",
  "SM.COMMON.LABEL.MEASUREMENT_RESULT_LIST": "รายการผลลัพธ์",
  "SM.ORGSTAFF.MESSAGE.ADDR_DELETE_SUCCESS": "ลบสำเร็จ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD33": "รหัสผ่านไม่สามารถเป็นอักขระหรือตัวเลขต่อเนื่องกันได้ เช่น AB, 123, AA และ abc",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MIN": "ไฟล์ที่อัปโหลดมีขนาดเล็กกว่าขนาดขั้นต่ำ 0 KB",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD32": "จำนวนอักขระต่ำสุดของแต่ละประเภทที่ต้องมีในรหัสผ่าน",
  "SM.ORGSTAFF.LABEL.ORG_TYPE": "ประเภทของ OU",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD31": "ความยาวบัญชีต่ำสุด",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD30": "ความยาวบัญชีสูงสุด",
  "SM.COMMON.LABEL.OPERLOG_SERRCH_CRITERIA": "เกณฑ์การค้นหา",
  "SM.COMMON.MESSAGE.OPERLOG_TIME_LOG2": "วัน",
  "SM.COMMON.MESSAGE.OPERLOG_TIME_LOG1": "ช่วงเวลาต้องไม่เกิน",
  "SM.COMMON.LABEL.CURRENCY_BASIC_INFO": "ข้อมูลพื้นฐาน",
  "SM.ORGSTAFF.TITLE.RELATION_CONFIRM_DELETE": "หลังจากการปิดใช้งาน ความสัมพันธ์ขององค์กรและความสัมพันธ์ของผู้ใต้บังคับบัญชาจะถูกลบ คุณยืนยันที่จะลบหรือไม่?",
  "SM.COMMON.LABEL.ERATE_SCURRENCY_CODE": "รหัสสกุลเงินที่มา",
  "SM.COMMON.LABEL.SELECTED_OUS": "OU ที่ถูกเลือก",
  "SM.ORGSTAFF.LABEL.REGION_DST_STARTTIME": "เวลาเริ่มต้น DST",
  "SM.ORGSTAFF.TITLE.POPUP_NEW_PROPERTY": "คุณสมบัติใหม่",
  "SM.COMMON.LABEL.SURVEY_TITLE_TYPE": "ประเภทคำถาม",
  "SM.ORGSTAFF.MESSAGE.DELETE_CONFIRM": "คุณแน่ใจหรือไม่ว่าต้องการลบ",
  "SM.ORGSTAFF.OPTION.NO": "ไม่ใช่",
  "SM.ORGSTAFF.LABEL.BE_TYPE": "ชนิดของ BE",
  "SM.COMMON.LABEL.OPERLOG_SESSION_ID": "IDเซสชัน",
  "SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_MUSTEXIST": "ระบบการวัดต้องมีหน่วยการวัดมาตรฐาน",
  "SM.ORGSTAFF.OPTION.WEEKDAY_SUN": "วันอาทิตย์",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU": "OU ที่มีประสิทธิภาพ",
  "SM.ORGSTAFF.VALIDATOR.AFTER_TODAY": "เวลาที่ใส่ต้องเร็วกว่าหรือเท่ากับเวลา 00:00 ของวันนี้",
  "SM.SM.COMMON.TITLE.GROUP_NAME_EXIST": "ชื่อกลุ่มผู้ใช้มีอยู่แล้ว",
  "SM.ORGSTAFF.LABEL.ORG_RECOVER_TIPS": "หลังจากการกลับมาดำเนินการต่อ องค์กรหรือหน่วยงานและหน่วยงานในสังกัดจะพร้อมใช้งาน คุณยืนยันที่จะดำเนินการต่อหรือไม่?",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_LOGINTIMERANGE": "ส่วนของเวลาลงชื่อเข้าใช้",
  "SM.COMMON.LABEL.CURRENCY_MINIMUM_MEASUREMENT": "นาที. ค่าสถานะหน่วย",
  "SM.ORGSTAFF.TITLE.BE_ADDRESS_INFO": "ข้อมูลที่อยู่",
  "SM.ORGSTAFF.TIPS.ENTER_OUNAME": "ใส่ข้อมูลองค์กร",
  "SM.COMMON.LABEL.SURVEY_SECTION": "ภูมิภาค",
  "SM.COMMON.TIPS.SPECIAL_CHAR_FORBIDDEN": "ค่าที่ป้อนต้องไม่มีอักขระพิเศษ",
  "SM.COMMON.LABEL.SYS_MEASUREMENT_NAME": "ชื่อ",
  "SM.COMMON.LABEL.SURVEY_CREATED_ACCOUNT": "สร้างโดย",
  "SM.SM.COMMON.TITLE.NO_CHINAWORD": "ไม่อนุญาตให้ใช้อักขระพิเศษ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD19": "บังคับให้เปลี่ยนรหัสผ่านเมื่อล็อกอินครั้งแรกหรือหลังการรีเซ็ตรหัสผ่าน",
  "SM.COMMON.LABEL.MODULE_MODULELIST": "รายการโมดูล",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD18": "จำนวนวันล่วงหน้าที่ผู้ใช้จะได้รับแจ้งการหมดอายุของรหัสผ่าน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_HISTORY": "ประวัติ Secondment",
  "SM.COMMON.TITLE.SURVEY_ITEM_NAME_INFO": "ข้อมูลชื่อรายการ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD15": "จำนวนการลองป้อนรหัสผ่านอีกครั้งสูงสุด",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD14": "ระยะเวลาที่ใช้งานได้ของรหัสผ่านสูงสุด เป็นวัน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD13": "รหัสผ่านไม่สามารถมีชื่อได้",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD12": "รหัสผ่านต้องแตกต่างจากบัญชีหรือบัญชีแบบเรียงกลับ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD11": "รหัสผ่านไม่ถูกต้อง",
  "SM.COMMON.LABEL.SURVEY_NOT_INPUT": "ไม่อนุญาตให้ป้อนข้อมูลด้วยตนเอง",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD10": "อักขระพิเศษที่ถูกต้อง",
  "SM.COMMON.MESSAGE.SYSMENU_SAVESUCCESS": "ดำเนินการสำเร็จ",
  "SM.ORGSTAFF.TITLE.CONTACT_LAST_NAME": "นามสกุล",
  "SM.COMMON.LABEL.MEASUREMENT_CONV": "การแปลงการวัดผล",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS": "สถานะ",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_STANDARD": "ค่าสถานะหน่วยมาตรฐาน",
  "SM.ORGSTAFF.LABEL.REGION_DISPLAYORDER": "ลำดับการแสดงผล",
  "SM.ORGSTAFF.LABEL.TEAM_TO": "ถึง",
  "SM.COMMON.LABEL.BANKCARD_TYPE_PARAM": "ประเภทบัตร",
  "SM.COMMON.LABEL.SURVEY_CLASS_NAME": "ชื่อหมวดหมู่",
  "SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_SOURCENO": "บัญชีต้นทาง",
  "SM.COMMON.LABEL.CURRENCY_GROUPSYMBOL": "ตัวคั่น",
  "SM.ORGSTAFF.TITLE.STAFF_ROLE_BASIC": "บทบาท",
  "SM.ORGSTAFF.TITLE.ADDR_DISP_LIST": "รายการรูปแบบการแสดงผลที่อยู่",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD09": "จำนวนอักขระต่อเนื่องกันสูงสุดในรหัสผ่าน",
  "SM.ORGSTAFF.LABEL.TEAM_EXTENDPRO": "คุณลักษณะเพิ่มเติม",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD08": "รหัสผ่านต้องแตกต่างจากรหัสผ่านล่าสุด",
  "SM.COMMON.LABEL.DATA_OPRATION": "การดำเนินการ",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_ID": "IDแบบสอบถาม",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD07": "รหัสผ่านต้องมีอักขระพิเศษ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD06": "รหัสผ่านต้องมีตัวพิมพ์เล็ก",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD05": "รหัสผ่านต้องมีตัวพิมพ์ใหญ่",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_CODE": "รหัส",
  "SM.ORGSTAFF.MESSAGE.ADDR_LEVELS_HIGH": "ที่อยู่ไม่ได้เรียงตามลำดับ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD04": "รหัสผ่านต้องมีตัวอักษร",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD03": "รหัสผ่านต้องมีตัวเลข",
  "SM.ORGSTAFF.LABEL.PARENT_BE": "BE หลัก",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD02": "ความยาวรหัสผ่านต่ำสุด",
  "SM.ORGSTAFF.MESSAGE.SELECT_WG_INFO": "กรุณาเลือกกลุ่มทำงาน",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD01": "ความยาวรหัสผ่านสูงสุด",
  "SM.COMMON.BUTTON.PROPERTY_CONFIRM": "ข้อมูล",
  "SM.COMMON.LABEL.EDIT_AREAINFO_MAINTAIN": "แก้ไขข้อมูลส่วน",
  "SM.COMMON.TITLE.PROPERTY_SUCCESS": "ข้อมูล",
  "SM.ORGSTAFF.LABEL.ORG_SELECTORG": "เลือก OU",
  "SM.COMMON.BUTTON.SEARCH": "ค้นหา",
  "SM.COMMON.TITLE.ITEM_NOTE": "ดูภาพ",
  "SM.COMMON.LABEL.ERATE_EXCHANGE_RATE": "อัตราแลกเปลี่ยน",
  "SM.COMMON.BUTTON.DELETE": "ลบ",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_DELETE": "ลบ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_MATCH_MODE": "โหมดจับคู่",
  "SM.ORGSTAFF.TITLE.WORKGROUP": "กลุ่มทำงาน",
  "SM.COMMON.LABEL.PROPERTY_CREATE_PROPERTY": "คุณสมบัติใหม่",
  "SM.ORGSTAFF.MESSAGE.RECOVERORG": "ดำเนินการ OU ต่อ",
  "SM.AUC.LABEL.LOGINLOG_EMPLOYEE_NAME": "ชื่อพนักงาน",
  "SM.AUC.LABEL.LOGINLOG_LOGIN_IP_ADDRESS": "IP ที่ลงชื่อเข้าใช้",
  "SM.AUC.LABEL.LOGINLOG_OPER_CONTENT": "รายละเอียดการดำเนินงาน",
  "SM.AUC.LABEL.LOGINLOG_OPER_TIME": "เวลาทำการ",
  "common.bremui.title.operation": "การดำเนินการ",
  "SM.MENU.SEARCH.INPUT": "ชื่อเมนูค้นหา",
  "SM.MENU.ADD.CUSTOM.MENU": "เพิ่มเมนู",
  "SM.MENU.ADD.CUSTOM.PERM_REPEAT": "ชื่อการอนุญาตซ้ํา",
  "SM.AUC.MESSAGE.PWDR_VALIDATE": "กฏการตรวจสอบ",
  "SM.AUC.MESSAGE.PWDR_MANAGE": "กฎการจัดการ",
  "SM.AUC.MESSAGE.RULEGROUP": "กลุ่มกฏ",
  "SM.AUC.MESSAGE.RULEGROUPNAME": "ชื่อกลุ่มกฎ",
  "SM.AUC.MESSAGE.RULEGROUPList": "รายการกฎ",
  "SM.AUC.MESSAGE.RULEGROUPListEDIT": "แก้ไขกลุ่มกฎ",
  "SM.AUC.MESSAGE.RULEGROUPListADD": "เพิ่มกลุ่มกฎ",
  "SM.AUC.MESSAGE.LOGIN.IP": "เข้าสู่ระบบ IP",
  "SM.AUC.MESSAGE.PWDRULE_ACCOUNT": "กฏบัญชี",
  "SM.AUC.LABEL.PWD_PWDRULE": "ข้อบังคับรหัสผ่าน",
  "SM.AUC.LABEL.PWD_CUSTOMIZEDRULE": "กฏที่กำหนดเอง",
  "SM.AUC.LABEL.KIND": "หมวดหมู่",
  "SM.AUC.LABEL.VALUE": "ค่า",
  "SM.MENU.DELETE.CUSTOM.ROLE.FAIL": "การลบเมนูสําเร็จ การลบสิทธิ์ที่เชื่อมโยงกับเมนูล้มเหลว โปรดตรวจสอบว่าสิทธิ์ยังคงถูกอ้างอิงโดยเมนูหรือบทบาทอื่น",
  "SM.AUC.ACCOUNT.LOCK.SEARCH.ACCOUNT": "ค้นหาบัญชี",
  "SM.AUC.ACCOUNT.LOCK.USER.NOT.EXIT": "ไม่มีผู้ใช้อยู่",
  "SM.AUC.LABEL.LOGINSESSION_SESSION": "สถานะเซสชัน",
  "SM.AUC.LABEL.LOGINSESSION_LOGINSTATUS": "สถานะลงชื่อเข้าใช้",
  "SM.AUC.LABEL.USERLOGUI_LOGINIDACCOUNT": "บัญชีลงชื่อเข้าใช้",
  "SM.AUC.MESSAGE.OPERLOG_TIPS_LOGOUT": "บังคับให้ออก",
  "SM.AUC.LABEL.LOGINSESSION_LOCKSTATUS": "สถานะการล็อค",
  "SM.AUC.LABEL.LOGINSESSION_LOCKTIME": "เวลาที่ถูกล็อค",
  "SM.AUC.LABEL.LOGINSESSION_LOCK": "ล็อค",
  "SM.AUC.LABEL.LOGINSESSION_UNLOCK": "ปลดล็อก",
  "SM.AUC.LABEL.LOGINSESSION_SURE_LOCK": "ยืนยันการล็อกผู้ใช้นี้หรือไม่?",
  "SM.AUC.LABEL.LOGINSESSION_LOCKFLAG": "สถานะ",
  "SM.AUC.LABEL.LOGINSESSION_LOCKTYPE": "ประเภทการล็อค",
  "SM.AUC.LABEL.LOGINSESSION_LOCK_EMPLOYEE": "ล็อกโดย",
  "SM.AUC.LABEL.LOGINSESSION_LOCKBYHAND": "ล็อกด้วยตนเอง",
  "SM.AUC.LABEL.LOGINSESSION_UNLOCKBYHAND": "ปลดล็อกด้วยตนเอง",
  "SM.AUC.LABEL.LOGINSESSION_AUTHSTATUS": "สถานะการรับรอง",
  "SM.AUC.LABEL.LOGINSESSION_NEARESTPAWDAUTHTYPE": "สถานะการตรวจสอบรหัสผ่านล่าสุด",
  "SM.AUC.LABEL.LOGINSESSION_NEARESTPWDAUTHTIME": "เวลาตรวจสอบรหัสผ่านล่าสุด",
  "SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAILTIME": "เวลาในการตรวจสอบรหัสผ่านล้มเหลว",
  "SM.AUC.MESSAGE.LOGINSESSION_LOGININ_STATUS": "ลงชื่อเข้าใช้",
  "SM.AUC.MESSAGE.LOGINSESSION_LOGOUT_STATUS": "ไม่ได้ลงชื่อเข้าใช้",
  "SM.AUC.MESSAGE.LOGINSESSION_LOCKSTATUS": "ล็อก",
  "SM.AUC.MESSAGE.LOGINSESSION_UNLOCKSTATUS": "ไม่ได้ถูกล็อก",
  "SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAIL": "รหัสผ่านรับรองความถูกต้องล้มเหลว",
  "SM.USERGROUP.DELETEG_GROUP.TITLE": "ลบกลุ่มผู้ใช้ที่เลือก?",
  "SM.USERGROUP.DELETEG_GROUP.CONTENT": "หลังจากลบกลุ่มผู้ใช้แล้ว จะสามารถสร้างกลุ่มผู้ใช้ใหม่ได้เท่านั้น",
  "SM.USERGROUP.DELETEG_EMPLOYEE.TITLE": "ลบพนักงานที่เลือกออกจากกลุ่มผู้ใช้ปัจจุบันหรือไม่",
  "SM.USERGROUP.DELETEG_EMPLOYEE.CONTENT": "หลังจากที่พนักงานถูกลบออกจากกลุ่มผู้ใช้แล้ว คุณสามารถเพิ่มพนักงานคนนั้นเข้าไปในกลุ่มผู้ใช้ได้อีกครั้งหากคุณต้องการเรียกคืนพนักงานคนนั้น",
  "SM.FEDERATION.FEDERATIONAPP.Email": "กล่องอีเมล",
  "SM.AUTH.MESSAGE.FUNCAUTH_MENU": "เมนุ",
  "SM.AUTH.LABEL.ROLETMPL_OPERTIME": "เวลาทำการ",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD_INPUT": "ชื่อฟิลด์ของNAME:",
  "SM.AUTH.TITLE.ROLE_FUNCAUTH_FAIL": ".บันทึกสิทธิ์บางฟังก์ชันไม่สำเร็จ สิทธิ์เหล่านี้เป็นสิทธิ์เฉพาะตัวหรือไม่สามารถให้สิทธิ์แก่คุณได้",
  "SM.AUTH.MESSAGE.CHANGE_SCOPE_SUGGESTION": "หากโหนดพาเรนต์เปลี่ยนขอบเขตของบทบาทขอบเขตของโหนดลูกจะต้องถูกแก้ไขให้เหมือนกับโหนดพาเรนต์",
  "SM.AUTH.TITLE.ROLE_DISALLOWED_FUNCTION_PERMISSION": "การอนุญาตฟังก์ชันที่ไม่รวม",
  "SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE_INPUT": "แหล่งที่มาของการอนุญาต",
  "SM.FEDERATION.COMMON.DELETE.FAIL": "ล้มเหลวในการลบไฟล์",
  "SM.AUTH.TITLE.ERROR": "ผิดผลาด",
  "SM.AUTH.TITLE.SELECT_OPEN_LOGIN": "เลือกเข้าสู่ระบบเปิดเมนู",
  "SM.AUTH.LABEL.OPERATOR_ACCOUNT_INPUT": "บัญชี:",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST_INPUT": "IDรายการประเภทการดำเนินการ:",
  "SM.AUTH.LABEL.MODULE_INPUT": "โมดูล:",
  "SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEINHER": "วัตถุทั้งสองเป็นเอกสิทธิ์และไม่อนุญาตให้สืบทอด",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_NORMAL": "ปกติ",
  "SM.AUTH.LABEL.ROLE_AUTH_DATA": "การอนุญาติใช้ข้อมูล",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD": "ชื่อฟิลด์ PARENT_ID",
  "SM.AUTH.TITLE.ROLE_GRANTABLE_DATA_PERMISSION": "การอนุญาตข้อมูลที่อนุญาต",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION": "ชื่อการดำเนินการ",
  "SM.AUTH.LABEL.AUTH_CONF_DATA": "การตั้งค่าการอนุญาตข้อมูล",
  "SM.AUTH.LABEL.ROLE_GENERALROLELIST": "รายการบทบาททั่วไป",
  "SM.FEDERATION.FEDERATIONAPP.PHONE": "หมายเลขโทรศัพท์",
  "SM.AUTH.BUTTON.ADD": "ใหม่",
  "SM.AUTH.MESSAGE.ROLETMPL_SELECT_ONE_ROLE": "เลือกอย่างน้อยหนึ่งบทบาท",
  "SM.AUTH.MESSAGE.TIME_LOG2": "วัน",
  "SM.AUTH.MESSAGE.TIME_LOG1": "ช่วงเวลาต้องไม่เกิน",
  "SM.AUTH.TITLE.USERAUTHVIEW_USERHAVAEROLES": "บทบาทของผู้ใช้",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_field_INPUT": "ชื่อเขตข้อมูล:",
  "SM.AUTH.LABEL.ROLE_GRANTED_AUTH_TYPE": "ประเภทที่ได้รับ",
  "SM.AUTH.TITLE.INPUT_MENU_NAME": "Input a menu name",
  "SM.FEDERATION.COMON.REFRESH": "รีเฟรช",
  "SM.AUTH.LABEL.FUNCAUTH_STATUS": "สถานะ",
  "SM.AUTH.TITLE.USERAUTHVIEW_MENU_AUTHID": "IDการอนุญาต",
  "SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS": "มีความสัมพันธ์พิเศษซึ่งกันและกันอยู่แล้ว",
  "SM.AUTH.TITLE.ROLE_OPPAUTHNAME": "การอนุญาตเฉพาะร่วมกัน",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_LIST": "รายการบทบาท",
  "SM.AUTH.MESSAGE.BIZ_OBJ_POLICY_DUPLICATE": "มีนโยบายที่มีบทบาทและขอบเขตเดียวกัน",
  "SM.FEDERATION.FEDERATIONAPP.CLIENTNAME": "ชื่อระบบการตรวจสอบความถูกต้องของบุคคลที่สาม",
  "SM.AUTH.MESSAGE.ROLETMPL_CAN_NOT_DELETE": "ลบโหนดไม่สำเร็จเนื่องจากโหนดมีโหนดย่อย",
  "SM.AUTH.LABEL.ROLE_NORMALROLE_BENAME": "บทบาททั่วไปของหน้าจอหลัก BE",
  "SM.AUTH.TITLE.MENU_NAME": "ชื่อเมนู",
  "SM.AUTH.TIPS.ROLETMPL_HAS_OPP_AUTH": "การบันทึกล้มเหลวเนื่องจากมีการอนุญาตที่ใช้ร่วมกันไม่ได้",
  "SM.AUTH.TIPS.FUNCAUTH_NO": "ไม่",
  "SM.AUTH.TITLE.ROLE_LOGIN_MENU_UPON": "เมนูแสดงผลอัตโนมัติเมื่อลงชื่อเข้าใช้",
  "SM.AUTH.LABEL.ROLETMPL_ROLETMPL_CONFIGURATE": "กำหนดค่าเทมเพลตบทบาท",
  "SM.AUTH.LABEL.OPERATOR_ACCOUNT": "ชื่อบัญชี",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE_INPUT": "ชื่อบริการ:",
  "SM.FEDERATION.IMPORT.FILESIZEVALIDATEFAIL": "โปรดเลือกไฟล์ที่เล็กกว่า 10 เมกะไบต์",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE_INPUT": "ประเภทการทำงาน:",
  "SM.AUTH.LABEL.ROLE_PARENTROLE_NAME": "ชื่อบทบาททั่วไป",
  "SM.AUTH.LABEL.ROLETMPL_OPERATOR": "โอเปอเรเตอร์",
  "SM.AUTH.MESSAGE.ROLE_ERROR_BEEN_EXTENDED": "ลบบทบาทไม่สำเร็จเนื่องจากถูกสืบทอดหรือสืบทอดบทบาทอื่นมาแล้ว",
  "SM.AUTH.TITLE.USERAUTHVIEW_ATTR_DEPARTMENT": "สาขา",
  "SM.AUTH.MESSAGE.ROLE_THE_ROLES_PERMISSION_OPPED": "การบันทึกล้มเหลวเนื่องจากสิทธิ์ของฟังก์ชันพื้นฐานมีความสัมพันธ์พิเศษซึ่งกันและกัน",
  "SM.AUTH.LABEL.THIRD_DATASOURCE_NAME": "ชื่อแหล่งข้อมูล",
  "SM.AUTH.TITLE.PERMISSION_RECOVERY": "การอนุญาตการรีไซเคิล",
  "SM.AUTH.BUTTON.EXPORT_CURRENT_ROLE": "ส่งออกรายการที่เลือก",
  "SM.FEDERATION.COMMON.REFRESH": "รีเฟรช",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE": "ไม่สามารถใช้ได้สิทธิ์ของบทบาท",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW": "มุมมองสิทธิ์",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHID": "ID ที่ได้รับอนุญาติ",
  "SM.AUTH.MESSAGE.PERM_RECOVERY_SUCCESS": "เพิกถอนสิทธิ์เรียบร้อยแล้ว",
  "SM.AUTH.TITLE.USERAUTHVIEW_FUNCTIONLIST": "รายการสิทธิ์การใช้งานฟังก์ชัน",
  "SM.AUTH.LABEL.ROLE_EMPLOYEE_ACCOUNT": "ชื่อบัญชี",
  "SM.AUTH.MESSAGE.ROLETMPL_INFORMATION": "ลายละเอียด",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE": "ประเภทการทำงาน",
  "SM.AUTH.TITLE.EMPOLYEE_ROLESCOPE": "พนักงาน",
  "SM.AUTH.BUTTON.CLEAR": "ชัดเจน",
  "SM.AUTH.LABEL.ROLE_CODE_INPUT": "รหัสบทบาท:",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE": "การเข้าถึงฟิลด์",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE": "ประเภทสิทธิ์",
  "SM.FEDERATION.COMMON.DETAIL": "รายละเอียด",
  "SM.FEDERATION.COMMON.NEW": "ใหม่",
  "SM.FEDERATION.FEDERATIONAP.REMOTEIP": "ค่าคือฟิลด์การแม็ปที่สอดคล้องกับสมาชิกภายนอก",
  "SM.AUTH.LABEL.MENU_AUTHID_INPUT": "ID ที่ได้รับอนุญาติ:",
  "SM.AUTH.TITLE.USER_PERMISSION_COMPARE": "การเปรียบเทียบการอนุญาตของบุคลากร",
  "SM.AUTH.TIPS.DATA_AUTH_PARAM_INVALID": "ชื่อตารางชื่อคอลัมน์สามารถมีได้เฉพาะตัวอักษรตัวเลขและ '_' และสามารถใช้ขึ้นต้นได้เท่านั้น",
  "SM.AUTH.TITLE.ROLE_GRANTABLE_FUNCTION_PERMISSION": "สิทธิ์การใช้งานฟังก์ชันที่ได้รับอนุญาต",
  "SM.AUTH.TITLE.ROLE_PERMISSION_COMPARE": "การเปรียบเทียบสิทธิ์บทบาท",
  "SM.AUTH.TITLE.ROLE_BUSINESS_ENTITY": "ID BE",
  "SM.AUTH.LABEL.ROLE_DERIVE_PARENTROLE": "บทบาททั่วไปที่สืบทอดมา",
  "SM.AUTH.MESSAGE.ROLE_ADD_SUCCESSFUL": "เพิ่มเรียบร้อยแล้ว",
  "SM.AUTH.LABEL.ROLE_EMPLOYEE_NAME": "พนักงาน",
  "SM.AUTH.MESSAGE.FUNCAUTH_INTERFACE": "อินเตอร์เฟซ",
  "SM.AUTH.BUTTON.ROLETMPL_RESET": "รีเซ็ต",
  "SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_TYPE": "ประเภทวัตถุสิทธิ์",
  "SM.AUTH.TITLE.INPUT_AUTH_OBJ_NAME": "ใส่ชื่อวัตถุสิทธิ์",
  "SM.FEDERATION.MAPPINGRULE.LOCAL": "ข้อมูลผู้ใช้ในท้องถิ่น",
  "SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_FRONT": "เดือน (30 วัน) เพื่อสำรอง",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME": "ชื่อบทบาท",
  "SM.AUTH.LABEL.ROLE_ID_B_INPUT": "IDบทบาท (B):",
  "SM.AUTH.LABEL.PERMISSION_CLASS": "การจำแนกประเภทสิทธิ์",
  "SM.AUTH.LABEL.BIZ_OBJ_field_PRIVILEGE_SETTING": "การตั้งค่า",
  "SM.AUTH.BUTTON.OK": "ตกลง",
  "SM.AUTH.MESSAGE.ROLE_ERROR_HAS_EXCLUDED": "ลบบทบาทไม่สำเร็จเนื่องจากไม่รวมบทบาทอื่น",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF": "ค่าสถานะการกำหนดค่าแบบไดนามิก",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_FUNCTION": "ฟังก์ชัน",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION": "เกณฑ์การกรองข้อมูล",
  "SM.FEDERATION.COMMON.UPDATE.FAIL": "การแก้ไขล้มเหลว",
  "SM.FEDERATION.FEDERATIONAPP.VALDATEHTTP": "ไม่ปฏิบัติตามรูปแบบ URL",
  "SM.AUTH.LABEL.ROLETMPL_START_TIME": "เวลาเริ่มต้น",
  "SM.AUTH.LABEL.DATA_DICTABLE_ID": "รหัสพจนานุกรมข้อมูล",
  "SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF_INPUT": "ชื่อสิทธิ์:",
  "SM.AUTH.LABEL.ROLE_EXCLUDINGROLE": "ไม่รวมบทบาท",
  "SM.AUTH.MESSAGE.OPERATE_SUCCESS": "ดำเนินการสำเร็จ",
  "SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH": "สิทธิ์ในการเข้าใช้งานบนสุด",
  "SM.AUTH.LABEL.PERMISSION_INPUT": "ผู้มีอำนาจ:",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_MENU": "เมนู",
  "SM.AUTH.MESSAGE.ROLETMPL_QUERY_FAILED": "การค้นหาล้มเหลว",
  "SM.AUTH.LABEL.ROLE_NAME_INPUT": "ชื่อบทบาท:",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_FUNCTION": "สิทธิ์การใช้งานฟังก์ชันที่ได้รับอนุญาต",
  "SM.AUTH.LABEL.ROLE_NORMALROLE": "บทบาททั่วไป",
  "SM.AUTH.OPTION.DISALLOWED_FUNCTION_PERM": "การอนุญาตฟังก์ชันที่ไม่รวม",
  "SM.AUTH.MESSAGE.ROLETMPL_ROLETMPL_EXISTS": "มีชื่อเทมเพลตบทบาทอยู่แล้ว",
  "SM.AUTH.MESSAGE.DATAPER_NO_PERM": "คุณไม่มีสิทธิ์ดำเนินการ",
  "SM.AUTH.LABEL.ROLE_ROLE_ID": "IDบทบาท",
  "SM.AUTH.LABEL.OPERATION_TYPE": "ประเภทการทำงาน",
  "SM.AUTH.MESSAGE.FUNCAUTH_UPLOADINFO": "เลือกรูปแบบไฟล์แนบ:",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD": "ชื่อฟิลด์เรียงลําดับ",
  "SM.AUTH.LABEL.DATA": "การอนุญาติใช้ข้อมูล",
  "SM.AUTH.TITLE.ORG_SELECTION": "การเลือกองค์กร",
  "SM.AUTH.LABEL.ROLE": "บทบาท",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_PAUSE": "ระงับ",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLEVIEW": "มุมมองบทบาท",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE": "สิทธิ์อนุญาตของบทบาท",
  "SM.AUTH.LABEL.DATAPROVIDER_TYPE_INPUT": "ประเภทแหล่งที่มาของค่าข้อมูล:",
  "SM.AUTH.TITLE.USERAUTHVIEW_ATTR_BE": "BE",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLE_TYPE": "ประเภทบทบาท",
  "SM.FEDERATION.IMPORT.USER": "นําเข้าผู้ใช้สหพันธรัฐ",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD_INPUT": "ชื่อฟิลด์เรียงลําดับ:",
  "SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_END": "ข้อมูลเดือน (หนึ่งเดือนคือ 30 วัน)",
  "SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_SECRET": "เป็นความลับ",
  "SM.AUTH.TITLE.STAFFROLELOG_LIST": "รายการบันทึกบทบาทของพนักงาน",
  "SM.FEDERATION.IMPORT.FAIL": "Failed",
  "SM.AUTH.MESSAGE.ROLE_ERROR_HAS_USERS": "ลบบทบาทไม่สำเร็จเนื่องจากบทบาทผูกพันกับพนักงาน",
  "SM.AUTH.LABEL.ROLE_PARENTROLE_BENAME": "หน้าแรกบทบาททั่วไป BE",
  "SM.AUTH.LABEL.FUNCAUTH_STATUS_INPUT": "สถานะ:",
  "SM.AUTH.LABEL.CLEAR_POPUP_TITLE": "ลบข้อมูลที่หมดอายุ",
  "SM.AUTH.TITLE.ROLE_HORTCUT_MENU": "เมนูทางลัด",
  "SM.AUTH.LABEL.MODULE": "โมดูล",
  "SM.AUTH.LABEL.ROLETMPL_ASSINGED_PERMISSION": "ให้สิทธิ์สำหรับบทบาท",
  "SM.AUTH.TITLE.INPUT_ROLE_NAME": "ป้อนชื่อบทบาท",
  "SM.AUTH.LABEL.ROLE_NO_INFO": "จัดการบทบาทในแผนผังบทบาททางด้านซ้าย",
  "SM.AUTH.LABEL.BIZ_OBJ_LIST": "รายการวัตถุทางธุรกิจ",
  "SM.AUTH.LABEL.ROLE_SUPERID": "ID บทบาทหลัก",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_USERVIEW": "มุมมองพนักงาน",
  "SM.AUTH.LABEL.ROLE_CONFIG_INFO": "กำหนดค่าข้อมูลบทบาท",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_TIME": "เวลาทำการ",
  "SM.AUTH.LABEL.THIRD_DATASOURCE": "แหล่งข้อมูลที่สาม",
  "SM.AUTH.BUTTON.ADDAUTH": "เพิ่ิ่มเติม",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_DISCARDED": "ลบ",
  "SM.AUTH.TIPS.FUNCAUTH_YES": "ใช่",
  "SM.FEDERATION.COMMON.UPDATE.SUCCESS": "การแก้ไขสําเร็จ",
  "SM.AUTH.MESSAGE.FUNCAUTH_UPLOADSIZE": "สูงสุด ขนาดไฟล์แนบ (ไบต์):",
  "SM.AUTH.MESSAGE.DATAPER_OBJ_TREE_ROOT": "โครงสร้างวัตถุสิทธิ์",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_AUTHVIEW": "มุมมองสิทธิ์",
  "SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_CONFIDENTIAL": "เป็นความลับ",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION_INPUT": "เกณฑ์การกรองข้อมูล:",
  "SM.AUTH.LABEL.ROLEBUSINESS_ENTITY_ID": "บทบาทของID BE",
  "SM.AUTH.MESSAGE.FUNCAUTH_DISABLE": "ไม่พร้อมใช้งาน",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST": "รหัสรายการประเภทการดำเนินการ",
  "SM.AUTH.BUTTON.IMPORT_ROLE": "บทบาทนำเข้า",
  "SM.FEDERATION.IMPORT.STARTIME": "เวลาเริ่มต้น",
  "SM.FEDERATION.COMMON.RESET": "รีเซ็ต",
  "SM.FEDERATION.FEDERATIONAPP.MAPPINGRULE": "กฎการแม็ปผู้ใช้ของสหพันธรัฐ",
  "SM.AUTH.TITLE.USERAUTHVIEW_DATA_OBJECT_TREE": "โครงสร้างวัตถุสิทธิ์",
  "SM.AUTH.BUTTON.ROLE_COPY_ROLE_AND_CREATE": "คัดลอกฟังก์ชั่นการอนุญาตและการอนุญาตข้อมูลและสร้างบทบาท",
  "SM.AUTH.LABEL.ROLETMPL_SEARCH_CRITERIA": "เกณฑ์การค้นหา",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD_INPUT": "ชื่อฟิลด์ PARENT_ID:",
  "SM.AUTH.LABEL.ROLE_EXCLUDEDROLE": "บทบาทที่ยกเว้น",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_PARENT_ROLETYPE": "บทบาททั่วไป",
  "SM.FEDERATION.FEDERATIONAPP.EMAIL": "กล่องจดหมาย",
  "SM.AUTH.LABEL.ROLE_COPY_MODE_INPUT": "โหมดถ่ายเอกสาร",
  "SM.FEDERATION.COMMON.OPERATION": "ปฏิบัติการ",
  "SM.AUTH.LABEL.ROLEPERMISSION_LOGL_IST": "รายการบันทึกสิทธิ์บทบาท",
  "SM.AUTH.LABEL.FUNCAUTH_DEPENDENT": "ขึ้นอยู่กับสิทธิ์",
  "SM.AUTH.TITLE.ROLE_ASSIGNMENT_TIME": "เวลามอบหมายงาน",
  "SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT_INPUT": "ชื่อบัญชี:",
  "SM.AUTH.LABEL.BIZ_OBJ_field_PRIVACY": "ระดับความเป็นส่วนตัวของฟิลด์",
  "SM.AUTH.LABEL.FUNCAUTH_LOADMORE": "โหลดเพิ่มเติม",
  "SM.FEDERATION.IMPORT.FILENAMEVALIDATEFAIL": "ชชื่อไฟล์ไม่ถูกต้อง เลือกไฟล์ที่ประกอบด้วยอักขระ ตัวอักษร ตัวเลขหรือขีดเส้นใต้เท่านั้น (_)",
  "SM.AUTH.LABEL.BIZ_OBJ_field": "ฟิลด์",
  "SM.AUTH.TITLE.ROLE_NOT_HOLD": "รายการข้อมูลที่ไม่สามารถให้สิทธิ์ได้",
  "SM.AUTH.TITLE.ROLE_EXTENDED_PROPERTY": "แอตทริบิวต์เพิ่มเติม",
  "SM.AUTH.TITLE.USER_PERM_COMPARE": "การเปรียบเทียบสิทธิ์ของผู้ใช้",
  "SM.AUTH.LABEL.BIZ_OBJ_SCOPE": "ขอบเขต",
  "SM.FEDERATION.IMPORT.DOWLOADIMPORTRESULT": "ดาวน์โหลด",
  "SM.FEDERATION.IMPORT.UPLOADSELECT": "เลือกไฟล์",
  "SM.AUTH.TITLE.USERAUTHVIEW_OPERTYPE": "ประเภทการทำงาน",
  "SM.AUTH.LABEL.ROLE_TMPL_LIST": "รายการเทมเพลตบทบาท",
  "SM.AUTH.MESSAGE.DATAPER_CONFIRM_DEL": "คุณแน่ใจหรือไม่ว่าต้องการลบ?",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHNAME": "ชื่อสิทธิ์",
  "SM.AUTH.LABEL.ROLE_COPY_TARGETROLE": "บทบาทเป้าหมาย",
  "SM.AUTH.BUTTON.ROLE_DESELECT_ALL": "ยกเลิกการเลือกทั้งหมด",
  "SM.AUTH.BUTTON.ROLETMPL_SEARCH": "ค้นหา",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE_INPUT": "ตาราง:",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_FUNCTION": "การอนุญาตฟังก์ชั่นพื้นฐาน",
  "SM.AUTH.LABEL.ROLE_STAFF_LIST": "รายชื่อพนักงานที่ได้รับมอบหมาย",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLE_ID": "IDบทบาท",
  "SM.AUTH.MESSAGE.ROLETMPL_CANNOT_SELECT_MODULE": "ไม่สามารถเลือกโมดูลได้",
  "SM.FEDERATION.IMPORT.IPLODATIP": "กําลังนําเข้าข้อมูล ดูรายละเอียดในหน้าผลการนําเข้าผู้ใช้ Federated",
  "SM.AUTH.MESSAGE.ROLETMPL_TIME_CONDITION": "เวลาต้องช้ากว่าเวลาเริ่มต้น",
  "SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_NAME": "ชื่ออ็อบเจกต์สิทธิ์",
  "SM.AUTH.TITLE.CONFIRM_PERM_RECOVERY": "ยืนยัน",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVACY": "ระดับการแสดงผลของฟิลด์",
  "SM.AUTH.TITLE.ROLE_SHORTCUT_MENU_TOOLBAR": "เมนูทางลัด",
  "SM.AUTH.TITLE.USERAUTHVIEW_TITLE_AUTHNAME_CONF": "ชื่อการอนุญาต",
  "SM.AUTH.TITLE.SELECT_SHORT_CUT": "เลือกเมนูทางลัด",
  "SM.FEDERATION.FEDERATIONAPP.EDIT": "การแก้ไขแอป Federated",
  "SM.AUTH.LABEL.ROLE_ID_B": "IDบทบาท(บี)",
  "SM.AUTH.LABEL.ROLE_ID_A": "IDบทบาท(เอ)",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER_INPUT": "กรองโดยBE:",
  "SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_NAME": "ชื่ออ็อบเจกต์",
  "oms.operationparam.msg.unsafeinfo": "โปรดทราบว่าโปรโตคอลเครือข่ายที่ใช้โดยที่อยู่ปัจจุบันไม่ปลอดภัย หากใช้โปรโตคอลนี้ ความเสี่ยงด้านความปลอดภัยอาจมีอยู่",
  "SM.AUTH.LABEL.ROLE_TYPE_INPUT": "ชนิดบทบาท:",
  "SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE_INPUT": "บทบาทแหล่งที่มา:",
  "SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_41": "หากช่วงเวลาสั้นเกินไป ความปลอดภัยของรหัสผ่านจะได้รับผลกระทบ แน่ใจว่าจะเปลี่ยน?",
  "SM.AUTH.LABEL.DATA_DICTABLE_ID_INPUT": "รหัสพจนานุกรมข้อมูล:",
  "SM.FEDERATION.IMPORT.STARTTIME": "เวลาเริ่มต้น",
  "SM.AUTH.TITLE.USERAUTHVIEW_USER_INFORMATION": "ข้อมูลพนักงาน",
  "SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK2": "บทบาทจะถูกล้างด้วย:",
  "SM.AUTH.TIPS.ROLE_INPUTROLENAME": "ใส่ชื่อบทบาท",
  "SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK1": "ถ้าคุณลบการอนุญาตข้อมูลการอนุญาตของพนักงานที่เกี่ยวข้องและข้อมูลต่อไปนี้",
  "SM.AUTH.MESSAGE.ROLETMPL_CANT_COPY_PERMISSION": "คุณไม่สามารถคัดลอกสิทธิ์บางอย่างได้ ดำเนินการต่อหรือไม่ ?",
  "SM.AUTH.MESSAGE.FUNCAUTH_LANGUAGE": "ภาษา",
  "SM.AUTH.MESSAGE.PASSWORD_LENTH_LESS_THAN_8": "หากความยาวของรหัสผ่านสั้นเกินไป อาจมีความเสี่ยงด้านความปลอดภัย คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.INVALIDFIELD": "ฟิลด์ไม่ถูกต้อง",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.PARSEFAIL": "ล้มเหลวในการวิเคราะห์ข้อมูลผู้ใช้ภายนอก",
  "SM.AUTH.LABEL.ROLE_COPY_MODE": "โหมดคัดลอก",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE_DETAIL": "บทบาทที่สามารถให้สิทธิ์ฟังก์ชันแก่ผู้อื่น",
  "SM.AUTH.LABEL.OPERATION_TYPE_INPUT": "ประเภทการทำงาน:",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF_INPUT": "การกำหนดค่า",
  "SM.AUTH.LABEL.ROLE_TYPE": "ประเภทบทบาท",
  "SM.FEDERATION.IMPORT.FILESUFFIXVALIDATEFAIL": "รูปแบบไฟล์ไม่ถูกต้อง เลือกไฟล์ XLSX",
  "SM.AUTH.LABEL.ROLETMPL_END_TIME_INPUT": "เวลาสิ้นสุด:",
  "SM.FEDERATION.FEDERATIONAPP.NAME": "บัญชีผู้ใช้",
  "SM.FEDERATION.COMMON.DELETE.SUCCESS": "ลบเรียบร้อยแล้ว",
  "SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_39": "การเปิดกฎนี้จะส่งผลต่อความปลอดภัยของรหัสผ่าน โปรดยืนยันว่าคุณเปิดต่อไปหรือไม่",
  "SM.AUTH.TIPS.ROLETMPL_CREATE_AND_ASSIGN": "สร้างบทบาทและกำหนดสิทธิ์",
  "SM.AUTH.TITLE.INPUT_ROLE_TMPL_NAME": "ใส่้ชื่อเทมเพลตบทบาท",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_ASSOCIATION_LOG": "บันทึกการเชื่อมโยงบทบาท",
  "SM.AUTH.TITLE.INPUT_USER_LOGINID": "ป้อนบัญชีผู้ใช้",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.EXISTS": "ชื่อระบบการตรวจสอบความถูกต้อง URL หรือชื่อกฎการแม็ปของบุคคลที่สามมีอยู่แล้ว",
  "SM.AUTH.LABEL.EMPLOYEEINCLUDED": "รวมบุคลากรของOU ย่อย",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE_INPUT": "ประเภทการอนุญาต:",
  "SM.AUTH.BUTTON.SAVE": "บันทึก",
  "SM.AUTH.TITLE.PERMISSION_SELECTION": "เลือกสิทธิ์",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT_INPUT": "ผู้ดำเนินการ:",
  "SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME_INPUT": "ชื่อสิทธิ์การใช้งานฟังก์ชัน:",
  "SM.AUTH.LABEL.FUNCAUTH_MODULE_INPUT": "IDระบบย่อย:",
  "SM.FEDERATION.IMPORT.FAILCOUNT": "จํานวนบันทึกที่ล้มเหลว",
  "SM.AUTH.TITLE.ROLE_PERMISSION_TIPS": "ในการดำเนินการแบตช์บนโครงสร้างสิทธิ์ให้ใช้เมนูทางลัดบนโหนดสิทธิ์",
  "SM.AUTH.LABEL.ROLE_ID_INPUT": "IDบทบาท:",
  "SM.AUTH.LABEL.FUNCAUTH_BASICINFO": "ข้อมูลพื้นฐาน",
  "SM.AUTH.MESSAGE.ROLE_ERROR_BEED_EXCLUDED": "ลบบทบาทไม่สำเร็จเนื่องจากไม่รวมบทบาทอื่น",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLE_NAME": "ชื่อบทบาท",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_DATAAUTHLIST": "รายการวัตถุสิทธิ์",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD": "ชื่อฟิลด์ของNAME",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_field": "ชื่อของฟิลด์ใช้งานบนสุด_ID",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD": "ชื่อฟิลด์ ID",
  "SM.AUTH.OPTION.ROLE_AUTH_REMOVE": "ลบ",
  "SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_ID": "IDออบเจ็กต์สิทธิ์",
  "SM.AUTH.LABEL.PERMISSION": "การอนุญาต",
  "SM.AUTH.MESSAGE.ROLE_DELETE_SUCCESSFUL": "ลบเรียบร้อยแล้ว",
  "SM.AUTH.LABEL.ROLE_PARENTROLE_ID": "IDบทบาททั่วไป",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTH": "การอนุญาติใช้ฟังก์ชั่น",
  "SM.AUTH.LABEL.FUNCAUTH_ATTRIBUTE": "ขยายคุณสมบัติ",
  "SM.AUTH.BUTTON.USERAUTHVIEW_RESET": "รีเซ็ต",
  "SM.AUTH.TITLE.ASSIGN_EMPLOYEE": "มอบหมายพนักงาน",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE": "ตาราง",
  "SM.AUTH.MESSAGE.DATAPER_DEL_SUCCESS": "ลบเรียบร้อยแล้ว",
  "SM.AUTH.TITLE.USERAUTHVIEW_ACCOUNTLOGIN": "ชื่อบัญชี",
  "SM.FEDERATION.COMMON.CONFIRM": "Confirm",
  "SM.AUTH.TIPS.ROLETMPL_ASSIGN_PERMISSON": "กำหนดสิทธิ์",
  "SM.AUTH.TIPS.ROLETMPL_VIEW_DETAIL_PERMISSION": ".ดูรายละเอียดเกี่ยวกับสิทธิ์ที่คุณไม่สามารถคัดลอกในเทมเพลตบทบาท",
  "SM.AUTH.TITLE.ROLE_BASIC_FUNCTION_PERMISSION": "สิทธิ์ฟังก์ชั่นพื้นฐาน",
  "SM.AUTH.HEADVALUE.AUTHUSERVIEW_AUTH_DATA": "กรุณาเลือก ...",
  "SM.AUTH.MESSAGE.OPERATEFAIL": "การบันทึกล้มเหลว",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.PARAMNULL": "อาร์กิวเมนต์ที่ร้องขอมาว่างเปล่า",
  "SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME_INPUT": "ชื่อเทมเพลตบทบาท:",
  "SM.AUTH.TITLE.CUSTOMER_ROLESCOPE_PERMISSION": "เมื่อขอบเขตการใช้งานไม่ใช่พนักงานคุณจะไม่สามารถกำหนดบทบาทให้กับพนักงานได้",
  "SM.FEDERATION.COMMON.SUCCESS": "สําเร็จ",
  "SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_12": "การปิดใช้งานกฎนี้จะส่งผลต่อความปลอดภัยของรหัสผ่าน คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งาน",
  "SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE": "แหล่งที่มาของการอนุญาต",
  "SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_10": "อักขระพิเศษที่ถูกต้องจะส่งผลต่อความปลอดภัยของรหัสผ่าน คุณแน่ใจหรือไม่ว่าต้องการใช้",
  "SM.AUTH.LABEL.ROLETMPL_PERMISSION_ID": "รหัสสิทธิ์การใช้งานฟังก์ชัน",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE_DETAIL": "บทบาทที่สามารถใช้สิทธิ์ของฟังก์ชัน",
  "SM.AUTH.TITLE.ROLE_DELEVER_DATA_PERMISSION": "ให้สิทธิ์ข้อมูล",
  "SM.AUTH.LABEL.ROLE_PARENTS_TITLE": "ข้อมูลการสืบทอดบทบาท",
  "SM.FEDERATION.IMPORT.UNDO": "ไม่ได้ประมวลผล",
  "SM.FEDERATION.FEDERATIONAPP.ACCOUNT": "บัญชี",
  "SM.AUTH.LABEL.ROLE_COPY_TARGETROLE_INPUT": "บทบาทเป้าหมาย:",
  "SM.AUTH.BUTTON.USERAUTHVIEW_SEARCH": "ค้นหา",
  "SM.AUTH.MESSAGE.SELECTA_ROLE": "เลือกบทบาท",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE": "ชื่อบริการ",
  "SM.AUTH.LABEL.BIZ_OBJ_field_IS_WRITEABLE": "สามารถเขียนได้",
  "SM.AUTH.BUTTON.PERM_RECOVERY": "รีไซเคิล",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER": "กรองโดยBE",
  "SM.AUTH.MESSAGE.ROLE_SURE_TO_DELETE": "คุณแน่ใจหรือไม่ว่าต้องการลบ?",
  "SM.AUTH.LABEL.ROLE_ID_A_INPUT": "IDบทบาท (A):",
  "SM.AUTH.LABEL.DATAAUTHMGR_TAB_AUTH_ROLE_VIEW": "บทบาทที่เกี่ยวข้องกับการอนุญาตข้อมูล",
  "SM.AUTH.LABEL.ROLE_DERIVE_NORMALROLE": "บทบาทร่วมที่ได้รับ",
  "SM.AUTH.LABEL.ROLE_ASSIGN_STAFF": "พนักงานที่ได้รับมอบหมายแล้ว",
  "SM.AUTH.TITLE.ROLE_TMPLATE_ID": "IDเทมเพลตบทบาท",
  "SM.AUTH.LABEL.BIZ_OBJ_field_PRIVILEGE": "สิทธิ์ของฟิลด์",
  "SM.AUTH.LABEL.ATTR_LANGUAGE": "ภาษา",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE_SETTING": "ตั้งค่า",
  "SM.AUTH.BUTTON.EXPORT": "ส่งออก",
  "SM.AUTH.MESSAGE.OPERATE_WITH_ROLES": "สร้างสิทธิ์สำเร็จและกำหนดให้กับตัวดำเนินการปัจจุบันและบทบาทต่อไปนี้:",
  "SM.AUTH.MESSAGE.ROLETMPL_DELETE_SUCCESS": "ลบเรียบร้อยแล้ว",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID_INPUT": "บทบาทของBE:",
  "SM.AUTH.TIPS.FUNCAUTH_STOPUSE": "ระงับ",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_field_INPUT": "จัดเรียงฟิลด์:",
  "SM.AUTH.MESSAGE.OPERATE_WITHOUT_ROLES": "สร้างสิทธิ์สำเร็จแล้วและกำหนดให้กับตัวดำเนินการปัจจุบัน",
  "SM.AUTH.MESSAGE.ROLE_OPP_WITH_PARENT": "บทบาทที่เลือกตรงข้ามกับบทบาทที่สืบทอดมา:",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME_INPUT": "ชื่อบทบาท:",
  "SM.AUTH.TIPS.ENTER_AUTHNAME": "ป้อนชื่อสิทธิ์",
  "SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL_INPUT": "เทมเพลตบทบาทให้เลือก:",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTH_MODULE": "โมดูล",
  "SM.AUTH.LABEL.LOGINLOG_RESET": "รีเซ็ต",
  "SM.AUTH.MESSAGE.FUNCAUTH_PAUTHNAME": "ชื่อสิทธิ์",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHID_INPUT": "IDการอนุญาต:",
  "SM.AUTH.TITLE.ROLE_BASIC_DATA_PERMISSION": "การอนุญาตข้อมูลพื้นฐาน",
  "SM.AUTH.MESSAGE.ROLE_CAN_NOT_ADD_ITSELF": "ไม่สามารถสืบทอดบทบาทของตัวเองได้",
  "SM.FEDERATION.MAPPINGRULE.NAME": "ชื่อกฎการแม็ป",
  "SM.AUTH.MESSAGE.SAVE_DEPENDENT_SELF": "การอนุญาตขึ้นอยู่กับตัวมันเอง,สิ่งนี้ไม่อนุญาต",
  "SM.FEDERATIONAP.ERROR.PARAMNULL": "พารามิเตอร์คําขอ เป็นว่าง",
  "SM.FEDERATION.IMPORT.STATUS": "Status",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE_DETAIL": "บทบาทที่ไม่สามารถใช้สิทธิ์ของฟังก์ชันได้",
  "SM.AUTH.LABEL.ROLETMPL_HOME_ENTITY": "หน้าแรกของการอนุญาต BE",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTH_DATA": "การอนุญาติใช้ข้อมูล",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD_IS_WRITEABLE": "จะแก้ไขหรือไม่",
  "SM.AUTH.MESSAGE.FUNCAUTH_NORMAL": "ปกติ",
  "SM.AUTH.LABEL.USERAUTHVIEW_SEARCH": "การค้นหาข้อมูล",
  "SM.AUTH.MESSAGE.ROLE_NAME_SAME": "มีชื่อบทบาทนี้อยู่แล้ว",
  "SM.AUTH.LABEL.DATAPROVIDER_TYPE": "ประเภทแหล่งที่มาของค่าข้อมูล",
  "SM.AUTH.BUTTON.ROLE_COPY_ROLE": "คัดลอกข้อมูลบทบาท (เฉพาะการอนุญาตฟังก์ชันคัดลอกและการอนุญาตข้อมูล)",
  "SM.AUTH.TITLE.USERAUTHVIEW_USER_LIST": "รายชื่อพนักงาน",
  "SM.AUTH.LABEL.USER_ACCOUNTB": "ชื่อบัญชี(B)",
  "SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME": "ชื่อสิทธิ์การใช้งานฟังก์ชัน",
  "SM.AUTH.LABEL.USER_ACCOUNTA": "ชื่อบัญชี(A)",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_field": "จัดเรียงฟิลด์",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_field_INPUT": "ชื่อของฟิลด์ใช้งานบนสุด_ID",
  "SM.AUTH.LABEL.ROLE_NAME": "ชื่อบทบาท:",
  "SM.FEDERATION.COMMON.DOWLOADTEM": "ดาวน์โหลดแม่แบบ",
  "SM.AUTH.LABEL.ORGANIZATION_INPUT": "OU:",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_NAME": "ชื่อบทบาท:",
  "SM.AUTH.LABEL.ROLE_SCOPE": "ขอบเขตบทบาท",
  "SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_ID": "IDBEหน้าจอหลักของบทบาท",
  "SM.AUTH.MESSAGE.ROLE_NOT_EXSIT": "ไม่มีบทบาทนี้",
  "SM.AUTH.MESSAGE.DATAPER_TREE_ROOT": "โครงสร้างการอนุญาตข้อมูล",
  "SM.AUTH.BUTTON.AUTH_EDIT": "แก้ไข",
  "SM.AUTH.TITLE.SUCCESS": "ข้อมูล",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION_INPUT": "ชื่อการดำเนินการ:",
  "SM.AUTH.NO.SELECT": "ยังไม่ได้เลือกสิทธิ์ใด ๆ",
  "SM.AUTH.MESSAGE.AUTH_DATA_OBJECT_TREE": "โครงสร้างวัตถุการอนุญาต",
  "SM.FEDERATION.COMMON.CREATE.FAIL": "ล้มเหลวในการสร้างไฟล์",
  "SM.FEDERATION.FEDERATIONAPP.NEW": "กําลังสร้างแอป Federated",
  "SM.AUTH.BUTTON.ROLE_SELECT_ALL": "เลือกทั้งหมด",
  "SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH_INPUT": "สิทธิ์ในการเข้าใช้งานบนสุด",
  "SM.AUTH.LABEL.USER_ACCOUNTB_INPUT": "บัญชี(B):",
  "SM.FEDERATION.COMMON.QUERY.CONDITION": "เงื่อนไขการสอบถาม:",
  "SM.AUTH.BUTTON.CANCEL": "ยกเลิก",
  "SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_ID": "IDอ็อบเจกต์",
  "SM.AUTH.TITLE.USERAUTHVIEW_QUERYCONDITION_TITLE": "เกณฑ์การค้นหา",
  "SM.AUTH.LABEL.ROLE_ID": "IDบทบาท",
  "SM.FEDERATIONAP.VALDATEJSON": "รายการการตั้งค่า ต้องอยู่ในรูปแบบ JSON",
  "SM.AUTH.LABEL.DATAAUTHMGR_ATTRIBUTE": "ขยายคุณสมบัติ",
  "SM.FEDERATION.DOWNLOAD.RESULT": "ดาวน์โหลดผลลัพธ์การนําเข้าจากผู้ใช้แบบสหพันธรัฐ",
  "SM.FEDERATION.FEDERATIONAPP.CLIENTURL": "URL ของระบบการตรวจสอบความถูกต้องของบุคคลที่สาม",
  "SM.AUTH.LABEL.ROLETMPL_END_TIME": "เวลาสิ้นสุด",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID": "บทบาทของBE",
  "SM.AUTH.TITLE.ASSIGNED_EMPLOYEE": "พนักงานที่ได้รับมอบหมายแล้ว",
  "SM.AUTH.LABEL.ROLETMPL_DESCRITPION": "คำอธิบาย",
  "SM.AUTH.LABEL.ROLETMPL_BASIC_FUNCTION_PERMISSION": "สิทธิ์ฟังก์ชั่นพื้นฐาน",
  "SM.AUTH.TITLE.ADD_ROLE_FROM_ROLE_TMPL": "เลือกเทมเพลตบทบาท",
  "SM.AUTH.TITLE.USERAUTHVIEW_QUERY_AUTHTYPE": "ประเภทการอนุญาต",
  "SM.AUTH.LABEL.ROLE_CODE": "รหัสบทบาท",
  "SM.FEDERATION.IMPORT.SUCCESSCOUNT": "จํานวนบันทึกความสําเร็จ",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_DAUTH": "การอนุญาตข้อมูลพื้นฐาน",
  "SM.AUTH.TIPS.ROLETMPL_WARNING": "คำเตือน",
  "SM.AUTH.LABEL.ROLE_DERIVE": "การสืบทอดบทบาท",
  "SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT": "ชื่อบัญชี",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID": "IDบทบาท",
  "SM.AUTH.TITLE.CUSTOMER_ROLESCOPE": "ลูกค้า",
  "SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE": "บทบาทแหล่งที่มา",
  "SM.AUTH.LABEL.BIZ_OBJ_FULL_NAME": "ชื่อเต็มของอ็อบเจกต์",
  "SM.AUTH.MESSAGE.ROLE_SELECT_A_ROLE": "เลือกบทบาท",
  "SM.AUTH.LABEL.DETAIL_INFO": "เลือกโหนดสิทธิ์ในแผนผังการนำทางทางด้านซ้ายเพื่อดูข้อมูลเกี่ยวกับสิทธิ์",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.ISUSED": "ข้อมูลถูกใช้โดยผู้เช่าและไม่สามารถลบหรือแก้ไขได้",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF": "การกำหนดค่าโครงสร้าง เพิ่มเติม",
  "SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME": "ชื่อเทมเพลตบทบาท",
  "SM.AUTH.LABEL.FUNCAUTH_ISDY": "ค่าสถานะการกำหนดค่าแบบไดนามิก",
  "SM.AUTH.BUTTON.CONFIRM_DELETE": "ยืนยันการลบ",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW_DATAAUTHLIST": "รายการวัตถุสิทธิ์",
  "SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL": "เลือกเทมเพลตบทบาท",
  "SM.AUTH.LABEL.CLEAR_CONFIRM_CLEAR_DATA": "แน่ใจหรือไม่ว่าต้องการลบสิทธิ์ที่ไม่ได้ใช้เมื่อเร็ว ๆ นี้",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE_INPUT": "ทรีสตรัคเจอร์",
  "SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_PUBLIC": "สาธารณะ",
  "SM.AUTH.LABEL.PERMISSION_CLASS_INPUT": "การจำแนกประเภทการอนุญาต:",
  "SM.AUTH.LABEL.ROLE_AUTH_DATA_INPUT": "การอนุญาติใช้ข้อมูล:",
  "SM.FEDERATION.FEDERATIONAPP.REMOTETIP": "เติมค่าไปยังฟิลด์แผนที่ที่ตรงกับผู้ใช้ภายนอก",
  "SM.FEDERATION.MAPPINGRULE.DESC": "คําอธิบายกฎการแม็ป",
  "SM.AUTH.LABEL.ROLE_SUPERCODE_INPUT": "รหัสบทบาทใช้งานบนสุด",
  "SM.FEDERATION.IMPORT.RESULT": "ผลลัพธ์การนําเข้าผู้ใช้แบบสหพันธรัฐ",
  "SM.FEDERATION.FEDERATIONAP.DELETEIP": "คุณแน่ใจหรือไม่ว่าคุณต้องการลบข้อมูล",
  "SM.AUTH.LABEL.ROLE_SUPERCODE": "รหัสบทบาทใช้งานบนสุด",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_COMMON_ROLETYPE": "บทบาททั่วไป",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_ID": "IDบทบาท",
  "SM.AUTH.LABEL.CONFIRM_DELETE_DEPEND_AUTH": "ยืนยันที่จะลบขึ้นอยู่กับการอนุญาต?",
  "SM.AUTH.MESSAGE.ROLETMPL_SHOWNAME": "เทมเพลตบทบาท",
  "SM.AUTH.LABEL.COMPARE_RESULT": "ผลการเปรียบเทียบ",
  "SM.AUTH.TITLE.ROLE_MUTUALLY_EXCLUSIVE_PERMISSION": "รายการสิทธิ์พิเศษร่วมกัน",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE_INPUT": "รหัสอนุญาต:",
  "SM.FEDERATION.FEDERATIONAPP.DELETETIP": "คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE": "สิทธิ์การใช้งานที่มีอยู่ของบทบาท",
  "SM.AUTH.TITLE.BELONG_ROLE_TMPL": "เทมเพลตบทบาทของเจ้าของ",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTH_STATUS": "สถานะ",
  "SM.AUTH.LABEL.USER_ACCOUNTA_INPUT": "บัญชี (A):",
  "SM.FEDERATION.COMMON.FAIL": "ข้อผิดพลาด",
  "SM.FEDERATION.IMPORT.IMPORTRESULT": "ผลการนําเข้า",
  "SM.AUTH.OPTION.ROLE_REMOVE_INFO_OF_TARGET_ROLE": "ลบข้อมูลบทบาทเป้าหมาย",
  "SM.FEDERATION.IMPORT.UPLOADFILE": "โปรดเลือกไฟล์ที่จะอัปโหลด",
  "SM.AUTH.TITLE.AUTHNAMEINFO": "ข้อมูลชื่อสิทธิ์",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTHSOURCE": "แหล่งที่มาของการอนุญาต",
  "SM.AUTH.LABEL.LOGINLOG_SEARCH": "ค้นหา",
  "SM.AUTH.LABEL.ROLE_DESC": "คำอธิบาย",
  "SM.AUTH.TIPS.ROLE_PARENTROLE": "ชื่อบทบาท",
  "SM.AUTH.LABEL.FUNCAUTH_OPPAUTHNAME": "การอนุญาตเฉพาะร่วมกัน",
  "SM.FEDERATION.IMPORT.ENDTIME": "เวลาสิ้นสุด",
  "SM.FEDERATION.COMMON.OPERATION.DELETE": "ลบ",
  "SM.FEDERATION.FEDERATIONAPP.LIST": "รายการ APP ของสหพันธรัฐ",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_DAUTH": "การอนุญาตข้อมูลที่อนุญาต",
  "SM.AUTH.LABEL.ROLE_NORMALROLE_ID": "รหัสบทบาททั่วไป",
  "SM.AUTH.TITLE.ROLE_TMPL_ROLE_LIST": "รายการบทบาท",
  "SM.FEDERATION.COMMON.OPERATION.EDIT": "การแก้ไข",
  "SM.AUTH.LABEL.ROLE_ROLE_NAME": "ชื่อบทบาท",
  "SM.AUTH.MESSAGE.ROLETMPL_SELECT_NO_ENTITY": "เลือกอย่างน้อยหนึ่งบทบาทหรือ BE",
  "SM.AUTH.BUTTON.CLEAR_ALL_TIPS": "ล้างการอนุญาตที่ไม่พร้อมใช้งาน",
  "SM.AUTH.MESSAGE.OK": "ตกลง",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE": "ID ที่ได้รับอนุญาติ",
  "SM.AUTH.TITLE.CONFIRM_DEL": "ยืนยัน",
  "SM.AUTH.LABEL.FUNCAUTH_MODULE": "IDระบบย่อย",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_OPP_FUNCTION": "การอนุญาตฟังก์ชันที่ไม่รวม",
  "SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF": "ชื่อสิทธิ์",
  "SM.AUTH.LABEL.ROLE_INFO": "ข้อมูลบทบาท",
  "SM.AUTH.LABEL.MENU_AUTHID": "ID ที่ได้รับอนุญาติ",
  "SM.AUTH.MESSAGE.ROLETMPL_SELECT_NODE_INFO": "เลือกโหนดพาเรนต์ในแผนผังการนำทางทางด้านซ้ายและจัดการเทมเพลตบทบาทในทรีเทมเพลตบทบาท",
  "SM.AUTH.MESSAGE.FUNCAUTH_READONLY": "ไม่สามารถกำหนดได้",
  "SM.AUTH.TITLE.INFO": "ข้้อมูล",
  "SM.AUTH.LABEL.ROLETMPL_START_TIME_INPUT": "เวลาเริ่มต้น:",
  "SM.FEDERATION.IMPORT.FAILFILE": "ไฟล์ล้มเหลว",
  "SM.AUTH.LABEL.FUNCAUTH_DESC_INPUT": "คำอธิบาย:",
  "SM.AUTH.LABEL.BIZ_OBJ_NAME": "ชื่อ",
  "SM.AUTH.BUTTON.CLOSE": "ปิด",
  "SM.AUTH.BUTTON.DELETE": "ลบ",
  "SM.AUTH.LABEL.ORGANIZATION": "OU",
  "SM.AUTH.MESSAGE.PERM_RECOVERY": ".หากคุณคลิก \"ตกลง\" สิทธิ์ของบทบาทต่อไปนี้ทั้งหมดจะถูกเพิกถอน หากคุณคลิก 'ยกเลิก' จะไม่มีการดำเนินการใด ๆ",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD_INPUT": "ชื่อฟิลด์ ID:",
  "SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEEXCLUDING": "มีความสัมพันธ์ในการสืบทอดอยู่แล้วและไม่สามารถยกเว้นบทบาทได้",
  "SM.FEDERATION.IMPORT.FAILREASON": "สาเหตุล้มเหลว",
  "SM.AUTH.LABEL.FUNCAUTH_UPLOAD": "อัปโหลดไฟล์แนบ",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD": "ฟิลด์",
  "SM.AUTH.BUTTON.ROLE_DELETE_FOREVER": "ลบถาวร",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF_INPUT": "ค่าสถานะการกำหนดค่าแบบไดนามิก:",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_INPUT": "คำอธิบาย:",
  "SM.FEDERATION.COMMON.CREATE.SUCCESS": "สร้างเสร็จเรียบร้อยแล้ว",
  "SM.AUTH.LABEL.BE_BASIC_INFORMATION": "ข้อมูลพื้นฐาน",
  "SM.AUTH.MESSAGE.SAVE_SUCCESS": "ดำเนินการสำเร็จ",
  "SM.AUTH.TITLE.USERAUTHVIEW_NAME": "พนักงาน",
  "SM.AUTH.LABEL.ROLE_DESC_INPUT": "คำอธิบาย:",
  "SM.AUTH.LABEL.ROLETMPL_ROLETMPL_INFO": "ข้อมูลเทมเพลตบทบาท",
  "SM.AUTH.TITLE.USERAUTHVIEW_STAFF_BE_NAME": "หน้าหลักBEของพนักงาน",
  "SM.FEDERATION.IMPORT.SUCCESCOUNT": "จํานวนบันทึกที่ประสบความสําเร็จ",
  "SM.AUTH.TITLE.TO_ASSIGNED_EMPLOYEE": "พนักงานที่ได้รับมอบหมาย",
  "SM.AUTH.LABEL.ROLE_NORMALROLE_NAME": "ชื่อบทบาททั่วไป",
  "SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE": "กฎการเข้าถึง",
  "SM.AUTH.OPTION.GEANTABLE_FUNCTION_PERM": "สิทธิ์การใช้งานฟังก์ชันที่ได้รับอนุญาต",
  "SM.AUTH.LABEL.ROLETMPL_DESCRITPION_INPUT": "คำอธิบาย:",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLE_PARENTS_TITLE": "ข้อมูลการสืบทอดบทบาท",
  "SM.AUTH.TIPS.ENTER_ORGNAME": "ใส่ชื่อองค์กร",
  "SM.FEDERATION.MAPPINGRULE.REMOTE": "ข้อมูลผู้ใช้ภายนอก",
  "SM.AUTH.MESSAGE.FUNCAUTH_FUNCTION": "ฟังก์ชัน",
  "SM.FEDERATION.COMMON.QUERY": "สอบถาม",
  "SM.AUTH.LABEL.ROLETMPL_FUNCTION_PERMISSION": "สิทธิ์การใช้งานฟังก์ชันที่ได้รับอนุญาต",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT": "ผู้ดำเนินการ:",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_field": "ชื่อของชื่อฟิลด์",
  "SM.AUTH.MESSAGE.ROLE_ERROR_SUBROLES": "ไม่สามารถลบบทบาทได้เนื่องจากมี subroles",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_field": "ชื่อฟิลด์ ID",
  "SM.AUTH.LABEL.CLEAR_TIMEOUT_SUCCESS_MSG": "ลบสิทธิ์ที่ไม่สามารถใช้งานได้ในช่วงเวลาดังกล่าวสำเร็จแล้ว",
  "SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_NAME": "หน้าหลักBEของบทบาท",
  "SM.AUTH.TITLE.USERAUTHVIEW_USER_LOGINID": "บัญชี",
  "SM.AUTH.LABEL.BIZ_OBJ_IS_OWNER": "เป็นเจ้าของ",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_field_INPUT": "ชื่อรหัสฟิลด์ :",
  "SM.AUTH.OPTION.ROLE_RETAIN_INFO_OF_TARGET_ROLE": "เก็บข้อมูลบทบาทเป้าหมาย",
  "SM.AUTH.LABEL.FUNCAUTH_DESC": "คำอธิบาย:",
  "SM.AUTH.LABEL.PERMRECOVERY_ROLE_NAME": "บทบาทที่ได้รับอนุญาต",
  "SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE_ROLE_POLICY": "นโยบายการเข้าถึงบทบาท",
  "SM.AUTH.LABEL.DETAIL": "รายละเอียด",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC": "คำอธิบาย:",
  "SM.AUTH.TITLE.INPUT_AUTHID_AUTHNAME": "ป้อนชื่อหรือIDที่ได้รับอนุญาติ",
  "SM.AUTH.LABEL.TITLE_CLEAR_SUCCESS": "โดยทันที",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID_INPUT": "IDบทบาท",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHNAME_INPUT": "ชื่อสิทธิ์:",
  "SM.AUTH.LABEL.ROLE_OPERTYPE": "ประเภทการทำงาน",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_NAME_INPUT": "ชื่อบทบาท",
  "SM.AUTH.TITLE.USERAUTHVIEW_DETAIL_INFO": "เลือกโหนดสิทธิ์ที่มี URL",
  "SM.FEDERATION.FEDERATIONAPP.VALDATEJSON": "รายการปรับแต่งต้องมีรูปแบบ JSON!",
  "SM.AUTH.LABEL.FUNCAUTH_INFO": "ข้อมูลพื้นฐาน",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE": "โครงสร้างทรี",
  "SM.AUTH.TITLE.INPUT_PERMISSION_NAME": "ป้อนชื่อสิทธิ์",
  "SM.AUTH.MESSAGE.DELETE_CONFIRM": "คุณแน่ใจหรือไม่ว่าต้องการลบ?",
  "SM.AUTH.TITLE.THIRD_DATASOURCE": "ดำเนินการสำเร็จ (แหล่งข้อมูลที่สามถูกปิดเนื่องจากแหล่งข้อมูลว่างเปล่า)",
  "SM.AUTH.TITLE.ADD_ROLE_FROM_AUTH_TREE": "เลือกฟังก์ชั่นการอนุญาต",
  "SM.FEDERATION.COMMON.CANEL": "ยกเลิก",
  "SM.FEDERATION.COMMON.SUBMIT": "Submit",
  "SM.AUTH.OPTION.ROLE_AUTH_ADD": "เพิ่มเติม",
  "SM.AUTH.MESSAGE.ROLE_INHERITANCE_RELATIONSHIP_EXISTS": "มีการสืบทอดอยู่เเล้ว",
  "SM.AUTH.LABEL.ASSIGNEMPLOYEE": "จัดสรรพนักงาน",
  "SM.AUTH.LABEL.ASSIGNRIGHTS": "สิทธิ์ที่เพิ่มได้",
  "SM.AUTH.LABEL.OWNEDRIGHTS": "สิทธิ์ส่วนตัว",
  "SM.ORGSTAFF.LABEL.ALLORG": "องค์กรทั้งหมด",
  "SM.FEDERATION.USER.TEMPLATE": "ดาวน์โหลดเทมเพลตนําเข้าผู้ใช้ของรัฐบาลกลาง",
  "common.baseui.MESSAGE.VALIDATE_MAXLENGTHB": "อนุญาตให้ใช้อักขระสูงสุดได้ 0 ตัวอักษร",
  "SM.GADGET.VALUE.MENUPERM": "สิทธิ์ของเมนู",
  "SM.GADGET.VALUE.FUNCPERM": "การอนุญาตดำเนินการ",
  "SM.GADGET.VALUE.INTFPERM": "การอนุญาต API",
  "SM.ROLE.PARENT.ROLE": "บทบาทสูงสุด",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_SET_ROLE": "เพิ่มบทบาทเป็นกลุ่ม",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_DELETE_ROLE": "ลบบทบาทเป็นจํานวนมาก",
  "SM.USERPWDGROUP.RANGE.VALIDATE": "ช่วง % -%",
  "SM.ROLE.SELECT.BATCH": "โปรดเลือกบทบาท",
  "SM.ROLE.SELECT.ERRORMSG": "คําอธิบายผิดปกติ：",
  "SM.ROLE.SELECT.ERRORCAUSE": "สาเหตุที่ผิดปกติ：",
  "SM.ROLE.SELECT.ERRORSLOUTION": "คําแนะนําในการประมวลผล：",
  "SM.ROLE.MESSAGE.DELETE_CONFIRM": "หลังจากลบบทบาทแล้ว คุณจะต้องสร้างใหม่อีกครั้งเท่านั้น ลบหรือไม่?",
  "SM.ORG.DISABLE.FAILED.PARENT": "ปิดการใช้งานองค์กรเด็กทั้งหมดขององค์กรก่อนที่จะปิดการใช้งานองค์กรปัจจุบัน",
  "SM.ORG.DISABLE.FAILED.EMPLOYEE": "ลบพนักงานทุกคนในองค์กร แล้วปิดการทํางานขององค์กร",
  "SM.EMPLOYEE.SELECTED.EMPLOYEE": "พนักงานที่เลือก",
  "SM.EMPLOYEE.ALLOCATE.WORKNO": "กําหนดหมายเลขงาน",
  "SM.EMPLOYEE.SELECTED.WORKNO.NOT.ENOUGH": "การเลือกประเภทตัวแทนในปัจจุบันสามารถกําหนดหมายเลขตัวแทนได้ไม่เพียงพอ",
  "SM.EMPLOYEE.CHECKED-IN.PASSWORD": "ลงทะเบียนรหัสผ่าน",
  "SM.EMPLOYEE.SOFTCONSOLE.GROUP": "กลุ่มผู้ติดต่อ",
  "SM.EMPLOYEE.INSPECTION.GROUP": "กลุ่มตรวจสอบคุณภาพ",
  "SM.EMPLOYEE.PLATFORM.CONFIG": "ปรับแต่งแพลตฟอร์ม",
  "SM.EMPLOYEE.CONFIG.EMPLOYEE": "กําหนดค่าพนักงาน",
  "SM.EMPLOYEE.CANNOT.CHOOSE.WORKNO": "ไม่สามารถเลือกพนักงานที่มีหมายเลขงานที่กําหนดไว้ได้",
  "SM.EMPLOYEE.OPERATE.MAX.HUNDRED": "พนักงานสูงสุด 100 คนต่อครั้ง",
  "SM.EMPLOYEE.SKILL.INFO": "ข้อมูลทักษะ",
  "SM.EMPLOYEE.FEATURE.CONFLICT": "ไม่สามารถเปิดการจดจําและการบันทึกแบบคู่แทร็กได้ เปิดการเปิดใช้งานการยกเลิกการเลือกอื่น",
  "SM.EMPLOYEE.BATCH.CONFIG": "ปรับแต่งเป็นชุด",
  "SM.TENANT.LABEL": "ผู้เช่า",
  "SM.TENANT.INFO": "ข้อมูลผู้เช่า",
  "SM.TENANT.VIEW": "เรียกดูผู้เช่า",
  "SM.TENANT.EDIT": "แก้ไขผู้เช่า",
  "SM.TENANT.CREATE": "ผู้เช่ารายใหม่",
  "SM.TENANT.TENANTSPACENAME": "ชื่อสภาพแวดล้อมของผู้เช่า",
  "SM.TENANT.TENANTNAME": "ชื่อผู้เช่า",
  "SM.TENANT.ADMINACCOUNT": "บัญชีผู้ดูแลระบบ",
  "SM.TENANT.PHONE": "รายชื่อผู้ติดต่อ",
  "SM.TENANT.CREATETIME": "สร้างเวลา",
  "SM.TENANT.DELETE_CONFIRM": "ผู้เช่าจะไม่สามารถกู้คืนได้หลังจากลบแล้ว ลบหรือไม่",
  "SM.PWDRULE.PLEASE.INPUT": "โปรดป้อนชื่อกลุ่มกฎ",
  "SM.EMPLOYEE.CANNOT.CHOOSE.STATUS": "ไม่สามารถเลือกพนักงานที่อยู่ในสถานะปิดใช้งานได้",
  "SM.EMPLOYEE.CANCEL.CONFIG.CONFIRM": "ยืนยันการยกเลิกการปรับแต่งหรือไม่",
  "SM.EMPLOYEE.ROLE.EFFECT": " ข้อมูลบทบาทของพนักงานจะมีผล[]นาทีต่อมา",
  "SM.ROLE.AUTH.EFFECT": " ข้อมูลการอนุญาตของบทบาทจะมีผล[]นาทีต่อมา",
  "SM.ROLE.TEMPLATE": " แม่แบบบทบาท",
  "SM.LABEL.APPCUBE.SWITCH": "หน้ารวม AppCube",
  "SM.LABEL.APPCUBE.APP": "เลือกแอปพลิเคชัน",
  "SM.LABEL.APPCUBE.MENU": "เลือกเมนู",
  "SM.MENU.NAME.SPECIAL.CHAR": "ไม่สามารถป้อนอักขระพิเศษได้: !#{'$'}%&()￥+:\"{'{'}{'}'},\\/'[]=-^<>",
  "SM.COMMON.MESSAGE.DUTY.FORBIDDEN": "พนักงานที่เลือกมีพนักงานที่ถูกปิดการใช้งานแล้ว",
  "SM.LABEL.ACCOUNT": "โปรดกรอกบัญชี",
  "SM.LABEL.USERNAME": "โปรดป้อนชื่อผู้ใช้",
  "SM.LABEL.EMAIL": "โปรดกรอกอีเมล",
  "SM.LABEL.PHONE": "กรุณากรอกหมายเลขโทรศัพท์ติดต่อ",
  "SM.LABEL.PASSWORD": "โปรดป้อนรหัสผ่าน",
  "SM.LABEL.DESCRIPTION": "โปรดกรอกรายละเอียด",
  "SM.ORGSTAFF.BUTTON.SaveAndConfigure": "บันทึกและปรับแต่ง",
  "SM.ROLE.BUTTON.DELETE": "คุณแน่ใจหรือไม่ว่าต้องการลบพนักงานที่เลือก?",
  "SM.ROLE.ADD": "สร้างบทบาทใหม่",
  "SM.ROLE.ADD.NAME": "โปรดป้อนชื่อตัวละคร",
  "SM.ROLE.ADD.DECRI": "โปรดป้อนหมายเหตุ",
  "sumweb.export.confirm.exportall": "คุณไม่ได้เลือกบันทึกใด ๆ ส่งออกบันทึกทั้งหมดหรือไม่ นอกจากนี้ คุณยังสามารถคลิกเร็กคอร์ดที่ต้องการเพื่อส่งออกได้",
  "sumweb.export.export.select": "คุณได้เลือกเร็กคอร์ด {0} รายการ คุณแน่ใจหรือไม่ว่าต้องการดําเนินการต่อ",
  "sumweb.export.org": "กําลังส่งออกข้อมูลขององค์กร",
  "sumweb.export.employee": "กําลังส่งออกข้อมูลพนักงาน",
  "sumweb.view.import.list": "รายการนําเข้า",
  "sumweb.import.success": "สร้างงานนําเข้าแล้ว คุณแน่ใจหรือไม่ว่าต้องการดูรายการนําเข้า",
  "SM.ROLE.EDIT": "แก้ไขบทบาท",
  "SM.AUTH.BUTTON.CONFIRM.CLOSE": "ยืนยันการปิด",
  "sumweb.agent.config": "กำหนดที่นั่ง",
  "sumweb.employee.export.nodata": "ไม่มีข้อมูลพนักงานตรงตามข้อกําหนด",
  "sumweb.employee.view.config": "ดูการกำหนดค่า"
}