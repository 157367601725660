import request from '@/utils/request'
import cookieUtils from '@/utils/cookie'

export const queryallrole = () => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentgroup/queryallrole',
    method: 'post',
  })
}

export const queryAccessCodesByTenant = () => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryAccessCodesByTenant',
    method: 'post',
  })
}

export const queryuserorgauth = (orgId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/queryuserorgauth',
    method: 'post',
    data: {
      orgId: orgId
    }
  }
  return request(requestContent);
}

export const queryusertheme = (callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/queryusertheme',
    method: 'post'
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const queryuserthemebyid = (themeId, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/queryuserthemebyid',
    method: 'post',
    data: {
      id: themeId
    }
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const moveusertheme = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/moveusertheme',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const queryUserLayouts = (themeId, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/queryuserlayouts',
    method: 'post',
    data: {
      themeId: themeId
    }
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const queryFullscreenIndicator = (themeId, callCenterId, chartId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/queryuserindicator',
    method: 'post',
    data: {
      themeId: themeId
    }
  }
  if (chartId) {
    requestContent.data.id = chartId;
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }];
  }
  return request(requestContent);
}


export const addUserIndicator = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/adduserindicator',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const queryThresholds = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/querythresholds',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const queryuserrichtext = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/queryuserrichtext',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const deleteuserlayouts = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/deleteuserlayouts',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const updateuserlayouts = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/updateuserlayouts',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const addthresholdslist = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/addthresholdslist',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const updatethresholdslist = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/updatethresholdslist',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const updateFullscreenIndicator = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/updateuserindicator',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const updateuserrichtext = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/updateuserrichtext',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const addusertheme = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/addusertheme',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const updateusertheme = (data, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/updateusertheme',
    method: 'post',
    data
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const deleteusertheme = (id, callCenterId) => {
  let requestContent = {
    url: '/service-cloud/rest/cmsapp/v1/fullscreen/deleteusertheme',
    method: 'post',
    data: {
      id: id
    }
  }
  if (callCenterId) {
    requestContent.requestHeaders = [{
      'X-ccInstanceId': callCenterId
    }]
  }
  return request(requestContent);
}

export const queryCcgroupByGroupType = (groupType) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qualitycheck/queryCcgroupByGroupType',
    method: 'post',
    data: {
      groupType: groupType
    }
  })
}

export const queryCcgroupPage = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qualitycheck/queryCcgroupPage',
    method: 'post',
    data
  })
}

export const queryAgentGroupByGroupId = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentgroup/queryagentgroupbygroupid',
    method: 'post',
    data
  })
}

export const queryGroupMemberCount = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentgroup/querygroupmembercount',
    method: 'post',
    data
  })
}

export const queryQcrelation = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qualitycheck/queryQcrelationPage',
    method: 'get',
    data
  })
}

export const saveqcrelation = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qualitycheck/saveqcrelation',
    method: 'post',
    data
  })
}

// 添加质检关系配置
export const addQcrelations = data => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qualitycheck/addQcrelations',
    method: 'post',
    data
  })
}

// 删除质检关系配置
export const deleteQcrelations = data => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qualitycheck/deleteQcrelations',
    method: 'post',
    data
  })
}

export const queryqcrelationbycondition = data => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentgroup/queryqcrelationbycondition',
    method: 'post',
    data
  })
}

export const addusergroupinfo = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/usergroup/addusergroupinfo',
    method: 'post',
    data
  })
}

export const updateusergroup = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/usergroup/updateusergroup',
    method: 'post',
    data
  })
}

export const deleteusergroup = (groupId) => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/usergroup/deleteusergroup/${groupId}`,
    method: 'delete'
  })
}

export const queryallworkno = () => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/agentgroup/queryallworkno`,
    method: 'post'
  })
}

export const addgroupmemberbatch = (data) => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/agentgroup/addgroupmemberbatch`,
    method: 'post',
    data
  })
}

export const deleteAgentMember = (id) => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/agentgroup/${id}`,
    method: 'delete'
  })
}

export const deletegroupmemberbatch = (data) => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/agentgroup/deletegroupmemberbatch`,
    method: 'post',
    data
  })
}

export const recordingPlayback = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/voiceinspection/queryvoices',
    method: 'post',
    data
  })
}

export const getPlayRecordFilePath = (data) => {
  return request({
    url: '/service-cloud/u-route/cms/fileaction/getPlayRecordFilePath',
    method: 'post',
    data,
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

// 查询坐席信息支持传入url
export const queryAgentsInfo = (url, data) => {
  return request({
    url: url,
    method: 'post',
    data
  })
}

export const querycausecode = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/releasecause/queryreleasecause',
    method: 'post',
    data
  })
}

export const queryIncomingCallRecord = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/callinrelease/querycallinrelease',
    method: 'post',
    data
  })
}

export const queryRequiredCallback = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/requiredcallback/queryrequiredcallback',
    method: 'post',
    data
  })
}

// 根据 tenant_space_id，orgId查询座席信息
export function queryAgentsInfoFromSum(orgId, data) {
  const tenantId = cookieUtils.getCookie('tenant_space_id')
  const url = '/service-cloud/rest/sum/v2/tenantSpaces/' + tenantId + '/orgs/' + orgId + '/users'
  return request({
    url,
    method: 'get',
    data
  })
}

// 查询agentInfo
export function queryWorknosFromSum() {
  const url = '/service-cloud/rest/cmsapp/v1/agentinfo/queryworknos'
  return request({
    url,
    method: 'get'
  })
}

export const queryMediumCallCdrReport = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/report/queryMediumCallCdrReport',
    method: 'post',
    data
  })
}

export const addexporttask = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/exporttask/addexporttask',
    method: 'post',
    data
  })
}

export const queryExportTask = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/exporttask/queryexporttask',
    method: 'post',
    data
  })
}

export const exportMediumCallCdrReport = (data) => {
  return request({
    url: '/service-cloud/u-route/cms/report/exportMediumCallCdrReport',
    method: 'post',
    data,
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

export const isIndependentDeployment = () => {
  return request({
    url: '/service-cloud/u-route/cms/environment/isindependentdeployment',
    method: 'get'
  })
}

// 呼叫链路监控
export const querycalls = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/calllinkmonitor/querycalls',
    method: 'post',
    data
  })
}

// 呼叫链路监控
export const querycalltracedetail = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/calllinkmonitor/querycalltracedetail',
    method: 'post',
    data
  })
}

// 排队放弃监控
export const queryFailureCalls = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/failurecall/queryfailurecall',
    method: 'post',
    data
  })
}

// 设置当前选择的呼叫中心
export const setCcId = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/callcenterselect/setcallcenter',
    method: 'post',
    data
  })
}

//查询所有呼叫中心
export function querybackupdetails() {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/callcenterselect/querybackupdetails',
    method: 'post',
    data: {}
  })
}

//查询当前选择的呼叫中心信息
export function queryCallCenter(userId) {
  const url = `/service-cloud/rest/cmsapp/v1/callcenterselect/querycallcenter`
  return request({
    url,
    method: 'get',
    data: {userId}
  })
}

// 根据条件查询双呼详情
export const queryDbCallLogs = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/report/callout/queryDbCallLogs',
    method: 'post',
    data
  })
}

// 查询座席监控详细信息
export const queryAgentDetails = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v2/agentinfomonitor/querydetails',
    method: 'post',
    data
  })
}

// 查询座席监控座席数信息
export function queryAgentCountMonitor() {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfomonitor/querymonitor',
    method: 'get'
  })
}

// 查询质检员状态
export const queryAgentState = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/queryagentstatus',
    method: 'post',
    data
  })
}

// 强制示忙
export const qcForceBusy = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qcagentinfo/forcebusy',
    method: 'post',
    data
  })
}

// 强制示闲
export const qcForceIdle = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qcagentinfo/forceidle',
    method: 'post',
    data
  })
}

// 强制释放
export const qcForceRelease = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qcagentinfo/forcerelease',
    method: 'post',
    data
  })
}

// 强制签出
export const qcForceOut = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qcagentinfo/forcelogout',
    method: 'post',
    data
  })
}

// 监听
export const qcAgentListen = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/qcsupervise',
    method: 'post',
    data
  })
}

// 取消监听/插入
export const qcStopListen = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/qccancel',
    method: 'post',
    data
  })
}

// 插入
export const qcAgentInsert = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/qcinsert',
    method: 'post',
    data
  })
}

// 耳语
export const qcAgentWhisper = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/qcwhisper',
    method: 'post',
    data
  })
}

// 停止耳语
export const qcStopWhisper = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/qcstopwhisper',
    method: 'post',
    data
  })
}

// 拦截
export const qcAgentIntercept = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/qcintercept',
    method: 'post',
    data
  })
}

// 切换
export const qcAgentSwitch = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/qcswitch',
    method: 'post',
    data
  })
}

// 更新质检信息
export const updateMonitorState = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/updatemonitorstate',
    method: 'post',
    data
  })
}

// 查询用户权限
export const getUserPermissions = (tenantSpaceId, userId) => {
  return request({
    url: '/service-cloud/rest/sum/v2/tenantSpaces/' + tenantSpaceId + '/users/' + userId + '/perms',
    method: 'get'
  })
}

// 查询座席详情
export const queryMonitorDetail = (workNo) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/monitordetail/querymonitordetail',
    method: 'get',
    data: {workNo}
  })
}

// 查询技能队列
export const querySkills = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfomonitor/queryskill',
    method: 'post',
    data
  })
}

// 发送便签（集成）
export const sendNotesSaas = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfomonitor/sendinternal',
    method: 'post',
    data
  })
}

// 发送便签（独立）
export const sendNotesOp = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfomonitor/sendnotes',
    method: 'post',
    data
  })
}

// 调整技能队列
export const adjustSkills = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfomonitor/resetagentskills',
    method: 'post',
    data
  })
}

// 查询告警信息
export const queryAlarmDetail = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/monitordetail/queryemotionalarmdetail',
    method: 'post',
    data
  })
}

export const queryIntervalCallstatics = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfomonitor/queryintervalcallstatics',
    method: 'post',
    data
  })
}

//设置当前签入号码
export function setSipNum(sipAccount) {
  const url = `/service-cloud/rest/cmsapp/v1/agentinfomonitor/updateSipAccount`
  return request({
    url,
    method: 'get',
    data: {sipAccount}
  })
}

//修改质检员当前签入密码
export const restMoniotrPassword = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/qcagentinfo/modifypwd',
    method: 'post',
    data
  })
}

//查询质检员当前签入号码
export const queryPhoneNumber = () => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/agentinfomonitor/queryphonenum',
    method: 'get'
  })
}

// 技能队列信息
export const querySkillsByTenant = () => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/querySkillsByTenant',
    method: 'post'
  })
}

// 技能队列监控表格信息
export const queryUserDayMonitor = (data, requestHeaders) => {
  const url = `/service-cloud/rest/cmsapp/v1/indexmonitor/queryuserdaymonitor`
  return request({
    url,
    method: 'post',
    data: data,
    requestHeaders: requestHeaders || []
  })
}

// 查询用户指标
export const queryUserIndicator = (data, requestHeaders) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryuserindicator',
    method: 'post',
    data,
    requestHeaders: requestHeaders || []
  })
}

// 技能队列当日趋势
export function queryUserDayTrend(data, requestHeaders) {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryuserdaytrend',
    method: 'post',
    data,
    requestHeaders: requestHeaders || []
  })
}

// 更新用户指标
export function updateUserIndicator(data, requestHeaders) {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/updateuserindicator',
    method: 'post',
    data,
    requestHeaders: requestHeaders || []
  })
}

// 查询IVR统计数据环比
export function queryIVRRingRatio(data) {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryivrringratio',
    method: 'post',
    data
  })
}

// 从cms查询vdn列表
export function queryVDNFromCms(data) {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryVDN',
    method: 'post',
    data
  })
}

// 从ccp查询vdn列表
export function queryVDNFromCcp(data) {
  return request({
    url: '/service-cloud/rest/ipccprovision/v1/callcenterinstances/queryAllCallCenter',
    method: 'post',
    data
  })
}

// 根据id查询呼叫中心
export function queryByInstId(data) {
  return request({
    url: '/service-cloud/rest/ipccprovision/v1/vcallcenter/queryByInstId',
    method: 'post',
    data
  })
}

// 查询实时指标
export function queryCallStatusInfo(data) {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryCallStatusInfo',
    method: 'post',
    data
  })
}

// 查询指定虚拟呼叫中心的实时监控呼叫统计数据
export function queryCountEdallCallStats(data, requestHeaders) {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryCountEdallCallStats',
    method: 'post',
    data,
    requestHeaders: requestHeaders || []
  })
}

// 指标监控，实时概览数据查询
export const queryMultichannelStats = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryMultichannelStats',
    method: 'post',
    data
  })
}

// 查询当前租间指定媒体类型和子媒体类型（可选）下所有技能队列的服务水平数据
export const queryskillsla = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryskillsla',
    method: 'post',
    data
  })
}

// 技能队列服务水平（20秒内接通数）阈值  集成部署
export const queryTenantSysparam = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysparam/tenant/querySysparam',
    method: 'post',
    data
  })
}

// 技能队列服务水平（20秒内接通数）阈值  独立部署
export const querysysparamwithitemid = (data) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/sysparamconfig/querysysparamwithitemid',
    method: 'post',
    data
  })
}

// CCGATEWAY轮询接口
export function queryAgentEvent() {
  const url = '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/queryagentevent'
  return request({
    url,
    method: 'get'
  })
}

// 查询用户组信息
export function queryccAgent(data) {
  const url = '/service-cloud/rest/cmsapp/v1/agentgroup/queryccagent'
  return request({
    url,
    method: 'post',
    data
  })
}

// 独立部署 查询参数配置目录
export function querysysparamcatg() {
  const url = '/service-cloud/rest/cmsapp/v1/sysparamconfig/querysysparamcatg'
  return request({
    url,
    method: 'get'
  })
}

// 独立部署 查询参数
export function querysysparam(data) {
  const url = '/service-cloud/rest/cmsapp/v1/sysparamconfig/querysysparam'
  return request({
    url,
    method: 'post',
    data
  })
}

// 独立部署 更新参数
export function updatesysparam(data) {
  const url = '/service-cloud/rest/cmsapp/v1/sysparamconfig/updatesysparam'
  return request({
    url,
    method: 'post',
    data
  })
}

// 独立部署 刷新参数
export function refreshsysparam(data) {
  const url = '/service-cloud/rest/cmsapp/v1/sysparamconfig/refreshsysparam'
  return request({
    url,
    method: 'post',
    data
  })
}

// 独立部署 重置参数
export function resetsysparam(data) {
  const url = '/service-cloud/rest/cmsapp/v1/sysparamconfig/resetsysparam'
  return request({
    url,
    method: 'post',
    data
  })
}

// 查看CMS系统参数配置
export const querydvconfigparam = (selectedcallCenterId) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/sysparam/querydvconfigparam',
    method: 'post',
    requestHeaders: [
      {
        'X-ccInstanceId': selectedcallCenterId
      }
    ]
  })
}

// 更新CMS系统参数配置
export const updatedvconfigparam = (data, selectedcallCenterId) => {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/sysparam/updatedvconfigparam',
    method: 'post',
    data,
    requestHeaders: [
      {
        'X-ccInstanceId': selectedcallCenterId
      }
    ]
  })
}


// 查询报表订阅
export function queryreportsubscribe(data) {
  const url = '/service-cloud/rest/cmsapp/v1/reportsubscribe/queryreportsubscribe'
  return request({
    url,
    method: 'post',
    data
  })
}

// 更新报表订阅数据
export function updatereportsubscribe(data) {
  const url = '/service-cloud/rest/cmsapp/v1/reportsubscribe/updatereportsubscribe'
  return request({
    url,
    method: 'post',
    data
  })
}

// 新增报表订阅数据
export function addreportsubscribe(data) {
  const url = '/service-cloud/rest/cmsapp/v1/reportsubscribe/addreportsubscribe'
  return request({
    url,
    method: 'post',
    data
  })
}

// 删除报表订阅数据
export function deletereportsubscribe(data) {
  const url = '/service-cloud/rest/cmsapp/v1/reportsubscribe/deletereportsubscribe'
  return request({
    url,
    method: 'post',
    data
  })
}

// 查询报表订阅数目
export function queryreportsubscribecount(data) {
  const url = '/service-cloud/rest/cmsapp/v1/reportsubscribe/queryreportsubscribecount'
  return request({
    url,
    method: 'post',
    data
  })
}

// 查询报表订阅报表列表
export function queryreportsubscribereports(data) {
  const url = '/service-cloud/rest/cmsapp/v1/subscribecontent/querysubscribecontent'
  return request({
    url,
    method: 'post',
    data
  })
}

// 查询报表订阅报表模板
export function queryreportsubscribetemplates(data) {
  const url = '/service-cloud/rest/ccnotification/v1/messageTemplate/queryMessageTemplate'
  return request({
    url,
    method: 'post',
    data
  })
}

// 查询vrp本地ip
export function getVrpLocalIP(data) {
  const url = '/service-cloud/rest/cmsapp/v1/agentinfoqualitycontrol/getvrplocalip'
  return request({
    url,
    method: 'post',
    data
  })
}