export default {
  "cms.recorddrive.label.IPAddress_add": "IP Address",
  "cms.recorddrive.label.button_add": "New",
  "cms.recorddrive.label.button_cacel": "Cancel",
  "cms.recorddrive.label.button_complete": "Complete",
  "cms.recorddrive.label.button_edit": "Edit",
  "cms.recorddrive.field.number": "No.",
  "cms.agentMonitor.label.Idle_error": "The inspector is not in idle state.",
  "cms.agentMonitor.label.batchSelectAgent": "You can select only one agent.",
  "cms.agentMonitor.laber.hasbeenCheck": "The agent has been inspected.",
  "cms.agentMonitor.laber.hasbeenMediaCheck": "The agent has been monitored by a multimedia session.",
  "cms.agentMonitor.laber.stopMediaMonitor": "Multimedia session monitoring stopped.",
  "cms.agentMonitor.laber.notInTalking": "The agent is not in a call. Refresh the status and try again.",
  "cms.agentMonitor.label.beinbusy_error": "The inspected agent is busy.",
  "cms.agentMonitor.label.beinidle_error": "The inspected agent is idle.",
  "cms.agentMonitor.label.beinrest_error": "The inspected agent is in rest state.",
  "cms.agentMonitor.label.details": "Details",
  "cms.agentMonitor.label.error": "Error",
  "cms.agentMonitor.label.forceidle_repeat": "The agent has shown idle. Do not show idle again.",
  "cms.agentMonitor.label.forcebusy_repeat": "The agent has shown busy. Do not show busy again.",
  "cms.agentMonitor.label.forcebusy_stateerror": "The agent is taking a rest. Do not show busy.",
  "cms.agentMonitor.label.forcebusy_talking": "The agent is in a conversation. Do not show busy.",
  "cms.agentMonitor.label.forceidle_talking": "The agent is in a conversation. Do not show idle.",
  "cms.agentMonitor.message.operaFailedAgent": "Operation failed. The agent is being inspected.",
  "cms.agentMonitor.label.forcebusy_error": "Failed to forcibly set an agent to the busy state.",
  "cms.agentMonitor.label.forcebusy_success": "An agent is forcibly set to the busy state successfully.",
  "cms.agentMonitor.label.forcebusy_success_calling": "An agent is forcibly set to the busy state successfully. After the call ends, the agent enters the busy state.",
  "cms.agentMonitor.label.forceidle_success_calling": "An agent is forcibly set to the idle state successfully. After the call ends, the agent enters the idle state.",
  "cms.agentMonitor.label.forceidle_error": "Failed to forcibly set an agent to the idle state.",
  "cms.agentMonitor.label.forceidle_success": "An agent is forcibly set to the idle state successfully.",
  "cms.agentMonitor.label.forcerelease_error": "Failed to forcibly release a call.",
  "cms.agentMonitor.label.forcerelease_success": "A call is forcibly released successfully.",
  "cms.agentMonitor.label.forcesignout_error": "Failed to sign out as an agent.",
  "cms.agentMonitor.label.forcesignout_success": "An agent signs out successfully.",
  "cms.agentMonitor.label.gateway_error": "Failed to invoke the gateway interface.",
  "cms.agentMonitor.label.insets_error": "Insertion failed",
  "cms.agentMonitor.label.insets_success": "Insertion succeeded",
  "cms.agentMonitor.label.whisper_error": "Whispering failed",
  "cms.agentMonitor.label.whisper_success": "Whispering success",
  "cms.agentMonitor.label.cancelwhisper_error": "Failed to cancel the whisper.",
  "cms.agentMonitor.label.cancelwhisper_success": "Whisper canceled successfully.",
  "cms.agentMonitor.label.switch_error": "The switchover is failed.",
  "cms.agentMonitor.label.switch_success": "The switchover is successful.",
  "cms.agentMonitor.label.intercept_error": "Interception failed",
  "cms.agentMonitor.label.intercept_success": "Succeeded in intercepting.",
  "cms.agentMonitor.label.listen_error": "Listening fails",
  "cms.agentMonitor.label.listen_success": "The listening is successful.",
  "cms.agentMonitor.label.cancelInsert_error": "Failed to cancel the insertion.",
  "cms.agentMonitor.label.cancelInsert_success": "Insertion is canceled successfully.",
  "cms.agentMonitor.label.cancelListening_error": "Failed to cancel listening.",
  "cms.agentMonitor.label.cancelListening_success": "Listening is canceled successfully.",
  "cms.agentMonitor.label.been_inspected": "The agent is being inspected. Try again later.",
  "cms.agentmonitor.label.qcstate_error": "Canceling failed. No QC operation is performed on the selected agent.",
  "cms.agentMonitor.label.nosignin_error": "The inspected agent has not signed in.",
  "cms.agentMonitor.label.switchNotice": "Use this function when the current agent is listened or interrupted.",
  "cms.agentMonitor.message.operaFailed": "Operation failed. An inspection or call is in progress.",
  "cms.agentMonitor.label.interceptNotice": "Use this function when the current agent is listened, interrupted, or whispered.",
  "cms.agentMonitor.label.qcinspector_error": "The operation cannot be performed because you are not an inspector.",
  "cms.agentMonitor.label.permission_error": "You do not have the authority.",
  "cms.agentMonitor.label.reset": "Reset",
  "cms.agentMonitor.label.search": "Search",
  "cms.agentMonitor.label.selectAgent": "Please select an agent",
  "cms.agentMonitor.label.success": "Success",
  "cms.agentMonitor.label.talk_error": "The selected agent is not in the talking state.",
  "cms.agentMonitor.label.talkingerror": "The selected agent is not in a conversation or is in a multimedia conversation.",
  "cms.agentMonitor.label.talking_error": "The inspected agent is in the talking state.",
  "cms.agentMonitor.label.warning": "Warning",
  "cms.agentmonitor.label.account": "Service Account",
  "cms.agentmonitor.label.terminalStatus": "Terminal Registration Status",
  "cms.agentmonitor.label.terminalStatusRefresh": "Update Status",
  "cms.agentmonitor.label.terminalStatusFix": "Restore Status",
  "cms.agentmonitor.label.accountCode": "Enter a service account",
  "cms.agentmonitor.label.agent": "Agent",
  "cms.agentmonitor.label.agentId": "Agent ID",
  "cms.agentmonitor.label.agentName": "Agent Name",
  "cms.agentmonitor.label.agentStatus": "Agent Status",
  "cms.agentmonitor.label.answering": "Answering",
  "cms.agentmonitor.label.busy": "Force Busy",
  "cms.agentmonitor.label.busyState": "Busy",
  "cms.agentmonitor.label.enterID": "Enter an agent ID",
  "cms.agentmonitor.label.forceSignOut": "Force Out",
  "cms.agentmonitor.label.header": "Set Table Header",
  "cms.agentmonitor.label.idle": "Force Idle",
  "cms.agentmonitor.label.idleState": "Idle",
  "cms.agentmonitor.label.whisper": "Whisper",
  "cms.agentmonitor.label.cancelwhisper": "Cancel Whispering",
  "cms.agentmonitor.label.intercept": "intercept",
  "cms.agentmonitor.label.switch": "switch",
  "cms.agentmonitor.label.insets": "Insert",
  "cms.agentmonitor.label.adjust": "Wrap-up",
  "cms.agentmonitor.label.learnState": "Learning",
  "cms.agentmonitor.label.listening": "Listen",
  "cms.agentmonitor.label.occupationState": "Occupied",
  "cms.agentmonitor.label.offline": "Offline",
  "cms.agentmonitor.label.operation": "Operation",
  "cms.agentmonitor.label.possessingSkills": "Sign-in Queue",
  "cms.agentmonitor.label.preoccupationState": "preoccupied",
  "cms.agentmonitor.label.qualityInspection": "Quality inspection",
  "cms.agentmonitor.label.queueAdjustment": "Adjust Queues",
  "cms.agentmonitor.label.readjustmentState": "Adjusting",
  "cms.agentmonitor.label.release": "Force Release",
  "cms.agentmonitor.label.restState": "Rest",
  "cms.agentmonitor.label.sendNotes": "Send Notes",
  "cms.agentmonitor.label.signOut": "Offline",
  "cms.agentmonitor.label.statusDuration": "Agent Status Duration",
  "cms.agentmonitor.label.alarmState": "Monitor Alarm",
  "cms.agentmonitor.label.talkingState": "Talking",
  "cms.agentmonitor.label.total": "Total agents",
  "cms.agentmonitor.label.syncScreenMonitor": "Monitor Screen",
  "cms.agentMonitor.label.queryNum": "Set the sign-in number",
  "cms.agentMonitor.label.queryNumSuccess": "Set the sign-in number success",
  "cms.agentMonitor.label.queryNumFailed": "Set the sign-in number failed",
  "cms.agentMonitor.label.qcSipNum": "Setting Current User sign-in number",
  "cms.agentMonitor.label.updateSip_error": "The number is incorrectly written",
  "cms.agentMonitor.label.updateSip_repeat": "The number has been assigned",
  "cms.agentMonitor.label.querynum_error": "Failed to update the sign-in number",
  "cms.agentMonitor.label.querynum_success": "Phone Number:",
  "cms.agentmonitor.label.monitorState": "Inspection Status",
  "cms.agentmonitor.label.all": "All",
  "cms.agentmonitor.label.noListenedAndInserted": "Not inspected",
  "cms.agentmonitor.label.listenedAndInserted": "Inspected",
  "cms.agentmonitor.label.noAlarm": "No alarm",
  "cms.agentmonitor.label.haveAlarm": "Alarm",
  "cms.agentmonitor.label.alarmDetail": "Detail",
  "cms.agentmonitor.label.mutilMediaMonitor": "Multimedia Session Monitoring",
  "cms.agentmonitor.label.stopMutilMediaMonitor": "Stopping Session Monitoring",
  "cms.agentmonitor.label.showAlarmDetail": "Viewing Alarm Details",
  "cms.agentmonitor.label.showAlarmCallId": "Call ID",
  "cms.agentmonitor.label.showAlarmAgentNo": "Agent ID",
  "cms.agentmonitor.label.showAlarmTime": "Time",
  "cms.agentmonitor.label.showAlarmContent": "Dialog Content",
  "cms.agentmonitor.label.showAlarmSensitive": "Sensitive Word",
  "cms.agentmonitor.label.showAlarmEmotionType": "Emotional Type",
  "cms.agentmonitor.label.dayStatistic": "Today's Statistics",
  "cms.agentmonitor.label.countAndDuration": "Times/Duration",
  "cms.agentmonitor.label.idleStatistic": "Idle",
  "cms.agentmonitor.label.Performance": "Performance",
  "cms.agentmonitor.label.signInDetail": "SignIn Detail",
  "cms.agentmonitor.syncScreenMonitor.getVrpIpError": "The inspected agent has not signed in to the VRC client",
  "cms.agentmonitor.syncScreenMonitor.agentStatus": "Please sign in before monitoring",
  "cms.sendnote.field.cancel": "Cancel",
  "cms.sendnote.message.checkInput": "Invalid characters",
  "cms.sendnote.field.notecontent": "Content",
  "cms.sendnote.field.ok": "OK",
  "cms.sendnote.title.selectAgents": "Selected Agent",
  "cms.sendnote.title.tips": "Information",
  "cms.sendnote.message.tipsError": "Failed to send a note",
  "cms.sendnote.message.tipsNoteContent": "Enter the note information",
  "cms.sendnote.message.tipsSuccess": "The note is sent successfully",
  "cms.skilladjustment.field.inputskill": "Enter a skill queue name",
  "cms.skilladjustment.field.seach": "Search",
  "cms.skilladjustment.field.selectskill": "Select a Queue",
  "cms.skilladjustment.field.skillid": "Skill Queue ID",
  "cms.skilladjustment.field.skillname": "Skill Queue Name",
  "cms.skilladjustment.field.skilltype": "Skill Queue Type",
  "cms.skilladjustment.title.tips": "Information",
  "cms.skilladjustment.message.tipsError": "Failed to adjust the skill queue",
  "cms.skilladjustment.message.tipsOutSide": "A maximum of 10 agents can be adjusted at a time",
  "cms.skilladjustment.message.tipsNotMonitor": "Not a QA specialist. Failed to adjust the skill queue.",
  "cms.skilladjustment.message.notMatchAgent": "The adjusted skill queue type does not match the agent type",
  "cms.skilladjustment.message.tipsSelectSkill": "Select a skill queue",
  "cms.skilladjustment.message.tipsSuccess": "The skill queue is adjusted successfully",
  "cms.agentmonitor.label.detail": "Agent monitoring details",
  "cms.qualitycheck.label.create": "Create",
  "cms.qualitycheck.label.delete": "Delete",
  "cms.qualitycheck.label.edit": "Edit",
  "cms.qualitycheck.label.editMember": "Edit Group Member ",
  "cms.qualitycheck.label.view": "View",
  "cms.qualitycheck.field.selected": "Selected",
  "cms.qualitycheck.field.unselected": "UnSelected",
  "cms.qualitycheck.title.delete": "Deletion Failure",
  "cms.qualitycheck.message.deleteinfo": "Deletion failed",
  "cms.qualitycheck.message.error_errorinfo": "Save failed",
  "cms.qualitycheck.title.saveError": "Error",
  "cms.qualitycheck.label.cancel": "Cancel",
  "cms.qualitycheck.label.configQcInformation": "Config Quality Check Information",
  "cms.qualitycheck.label.createusergroup": "Create UserGroup",
  "cms.qualitycheck.label.editusergroup": "Edit UserGroup",
  "cms.qualitycheck.label.editusergroupmember": "Edit Group Member",
  "cms.qualitycheck.label.groupname": "GroupName",
  "cms.qualitycheck.label.input": "Please input",
  "cms.qualitycheck.label.save": "Save",
  "cms.qualitycheck.label.viewusergroup": "View UserGroup",
  "cms.qualitycheck.title.deleteSuccess": "Deletion Success",
  "cms.qualitycheck.message.deleteSuccess": "Deleted successfully",
  "cms.qualitycheck.title.saveSuccess": "Success",
  "cms.qualitycheck.message.saveSuccess": "Save successfully",
  "cms.qualitycheck.label.userGroup": "User Group",
  "cms.qualitycheck.label.userGroupMemberConfig": "Configure User Group Members",
  "cms.qualitycheck.label.qcRelationConfig": "Inspection Relationships",
  "cms.qualitycheck.label.queryQcRelation": "Query Inspection Relationships",
  "cms.qualitycheck.label.unselectedGroup": "Unselected User Groups",
  "cms.qualitycheck.label.selectedGroup": "Selected User Groups",
  "cms.qualitycheck.field.groupName": "User Group",
  "cms.qualitycheck.placeholder.userGroupName": "Enter a user group",

  "cms.qualitycheck.label.trafficGroup": "Traffic Group",
  "cms.qualitycheck.label.qualityInspectionGroup": "Quality Inspection Group",

  "cms.recorddrive.label.recorddrivename": "Drive Letter Name",
  "cms.recorddrive.title.recorddrivenameconfig": "Volume Configuration",
  "cms.recorddrive.field.configSftpAccount": "SFTP Account",
  "cms.recorddrive.field.configSftpAccountConfirmPwd": "SFTP Server Confirm Password",
  "cms.recorddrive.field.configSftpAccountPwd": "SFTP Server Password",
  "cms.recorddrive.field.configSftpPath": "File Path ",
  "cms.recorddrive.field.configSftpPathError": "The file storage path cannot contain './'",
  "cms.recorddrive.title.sftpIdentityCheck": "Identity Verification",
  "cms.recorddrive.message.sftpIdentityChecktips": "The account or password of the SFTP server is changed. To maintain information security, enter your current account and password.",
  "cms.recorddrive.placeholder.sftpIdentityChecktips": "Enter your current account and password.",
  "cms.recorddrive.field.sftpIpadress": "SFTP IP address",
  "cms.recorddrive.label.sftpPort": "SFTP Server Port",
  "cms.recorddrive.field.sftpPort": "Port No.",
  "cms.recorddrive.title.sftpServerConfig": "SFTP Server Configuration",
  "cms.recorddrive.field.storageLimit": "Lowest Storage Limit (MB)",
  "cms.vcallecenter.field.adminworkno": "Administrator ID",
  "cms.vcallecenter.title.authentication": "Verify Identity",
  "cms.vcallecenter.field.confirmnewpassword": "Confirm Password",
  "cms.vcallecenter.field.confirmwaspassword": "Confirm Password",
  "cms.vcallecenter.field.curcountpassword": "Current Account Password",
  "cms.vcallecenter.field.enternewpassword": "Enter a new password",
  "cms.vcallecenter.field.enterwaspassword": "Password",
  "cms.vcallecenter.title.modifyinfo": "Modify Password",
  "cms.vcallecenter.title.modifyinfo2": "Configure Password",
  "cms.vcallecenter.title.modifyadministratorpassword": "CTI Administrator Password",
  "cms.vcallecenter.title.modifybmspassword": "Modify BMS Password",
  "cms.callecenter.field.setbmspwd": "CCBMS Password",
  "cms.callecenter.field.confirmbmspwd": "Confirm CCBMS Password",
  "cms.vcallecenter.title.modifyconfigurationinterfacepassword": "Set CCBCS Interface Password in VDN",
  "cms.vcallecenter.message.configurationinterfacenopassword": "The Configuration Password is not configured.",
  "cms.vcallecenter.message.syncagentfail": "Failed to synchronize the agent",
  "cms.vcallecenter.message.syncrolefail": "Failed to synchronize the role",
  "cms.vcallecenter.message.syncskillfail": "Failed to synchronize the skills",
  "cms.vcallecenter.message.modifyadminpasswordfail": "Failed to change the password",
  "cms.vcallecenter.message.currentacountpasderror": "The password of the current account is incorrect",
  "cms.vcallecenter.message.modifyadminpasswordsuccess": "Modify password successfully",
  "cms.voiceinspection.field.batchDownload": "Batch Download",
  "cms.voiceinspection.field.reset": "Reset",
  "cms.voiceinspection.field.search": "Query",
  "cms.voiceinspection.message.downloadFailed": "Failed to download the recording",
  "cms.voiceinspection.message.downloadSucceed": "The recording is downloaded successfully",
  "cms.record-playback.voice.playing": "Play",
  "cms.record-playback.voice.pause": "Pause",
  "cms.voiceinspection.field.audioPlay": "Recording Audition",
  "cms.voiceinspection.field.videoPlay": "Video Playback",
  "cms.voiceinspection.field.rate": "Playback Rate",
  "cms.voiceinspection.field.download": "Download",
  "cms.voiceinspection.field.agentId": "Agent",
  "cms.voiceinspection.field.beginTime": "Recording Start Time",
  "cms.voiceinspection.field.queryBeginTime": "Start Time",
  "cms.voiceinspection.field.callId": "Recording SN",
  "cms.voiceinspection.field.callType": "Call Type",
  "cms.voiceinspection.field.calleeNo": "Called Number",
  "cms.voiceinspection.field.callerNo": "Caller Number",
  "cms.voiceinspection.field.currentSkillId": "Skill Queue",
  "cms.voiceinspection.field.endTime": "Recording End Time",
  "cms.voiceinspection.field.queryEndTime": "End Time",
  "cms.voiceinspection.field.operat": "Operation",
  "cms.voiceinspection.field.serviceId": "Business Type",
  "cms.record-playback.callType0": "Inbound Voice Call",
  "cms.record-playback.callType1": "Outbound Voice Call",
  "cms.record-playback.callType2": "IVR Outbound Call",
  "cms.record-playback.callType3": "OBS Outbound Call",
  "cms.voiceinspection.field.textChat": "Text Chat",
  "cms.voiceinspection.field.clickToCall": "Voice Click to Dial",
  "cms.voiceinspection.field.videoClickToCall": "Video Click to Dial",
  "cms.voiceinspection.field.callbackRequest": "Callback Request",
  "cms.voiceinspection.field.ordinaryVoiceCall": "Voice",
  "cms.voiceinspection.field.predictOutbound": "Predictive Outbound",
  "cms.voiceinspection.field.preViewOutbound": "Preview Outbound",
  "cms.voiceinspection.field.messageCall": "Message Call",
  "cms.voiceinspection.field.webphoneVideo": "Click-to-dial video call",
  "cms.voiceinspection.field.phoneVideo": "Common video call",
  "cms.voiceinspection.field.socialChat": "Multimedia",
  "cms.voiceinspection.field.nonRealTimeCall": "Non-real-time call",
  "cms.voiceinspection.field.video": "Video",
  "cms.voiceinspection.title.tips": "Information",
  "cms.voiceinspection.video.tolong.info": "Download the recorded video to play it because it is long",
  "cms.voiceinspection.message.selectVoice": "Select a recording",
  "cms.voiceinspection.title.error": "Error",
  "cms.voiceinspection.message.errorContent": "Failed to load the resource. Check the configuration.",
  "cms.voiceinspection.message.playsuccess": "The announcement is played successfully",
  "cms.voiceinspection.message.playfailure": "Failed to play the announcement",
  "cms.voiceinspection.message.errorTime": "Date query cannot cross months",
  "cms.voiceinspection.message.frequentOperat": "The operation is too frequent. Please try again later.",
  "cms.voiceinspection.message.frequentDownload": "The download is too frequent. Try again later.",
  "cms.voiceinspection.message.frequentPlay": "The playing is too frequent. Try again later.",
  "cms.voiceinspection.message.frequentExport": "Exporting too frequently. Please try again later.",
  "cms.voiceinspection.message.invalidParam": "Invalid input parameter",
  "cms.voiceinspection.message.invalidQcRelation": "The inspection relationship does not match",
  "cms.voiceinspection.message.noRecordFound": "No matching recording is found",
  "cms.voiceinspection.message.noSftpFound": "Failed to query SFTP server connection information",
  "cms.voiceinspection.message.downloadVoiceRecordFailed": "Failed to download the recording file from the SFTP server.",
  "cms.agent.message.duplicatepwd": "The new password is the same as the current one.",
  "cms.agent.message.modifypasswordfailed": "Failed to change the password",
  "cms.agent.message.modifypasswordhintdetail": "Enter the password",
  "cms.agent.message.modifypasswordsuccess": "The password is changed successfully",
  "cms.agent.title.modifypassword": "Change Sign-in Password",
  "cms.callcenter.message.configcallcenter": "Configure the call center",
  "cms.callcenter.field.deletefailure": "Deletion Failure",
  "cms.callcenter.message.deletefailed": "Deletion failed.",
  "cms.callcenter.message.createfailed": "Creation failed.",
  "cms.callcenter.message.updatefailed": "Updated failed",
  "cms.callcenter.message.sameletterovermaxnum": "A maximum of eight records can be added to the same drive letter.",
  "cms.callcenter.field.savefailed": "Error",
  "cms.callcenter.field.cancel": "Cancel",
  "cms.callcenter.field.finish": "Complete",
  "cms.callcenter.field.reset": "Reset",
  "cms.callcenter.message.selectcallcent": "Select a call center.",
  "cms.callcenter.message.selectccgatewayservers": "Select a CC-Gateway server.",
  "cms.callcenter.field.deletesuccess": "Deletion Success",
  "cms.callcenter.message.deletesuccess": "Deleted successfully.",
  "cms.callcenter.message.updatesuccess": "Updated successfully.",
  "cms.callcenter.field.savesuccess": "Success",
  "cms.callcenter.message.createsuccess": "Created successfully.",
  "cms.callcenterinstanceslist.title.callcenter": "Call Center",
  "cms.callcenterinstanceslist.message.ccgatewayServerBatchdeleteconfim": "Are you sure you want to delete all selected CC-Gateway servers?",
  "cms.callcenterinstanceslist.message.ccgatewayServerSingledeleteconfim": "Are you sure you want to delete the selected CC-Gateway server?",
  "cms.callcenterinstanceslist.field.ccGatewayIp": "CC-Gateway IP",
  "cms.callcenterinstanceslist.field.ccGatewayPort": "CC-Gateway Port",
  "cms.callcenterinstanceslist.message.adminWorkNoError": "The start number of the administrator employee ID must range from 55001 to 59999.",
  "cms.callcenterinstanceslist.message.alreadyInuse": "The employee ID segment has been used.",
  "cms.callcenterinstanceslist.message.ccIdError": "The call center number has been used.",
  "cms.callcenterinstanceslist.message.ccIdUpdateError": "Do not change the call center ID when editing the call center",
  "cms.callcenterinstanceslist.message.ccNameUpdateError": "Do not change the call center name when editing the call center",
  "cms.callcenterinstanceslist.message.ccNameUsed": "The call center name has been used.",
  "cms.callcenterinstanceslist.message.createSuccess": "The call center is created successfully.",
  "cms.callcenterinstanceslist.message.createFailed": "Failed to create the call center.",
  "cms.callcenterinstanceslist.message.error": "Error",
  "cms.callcenterinstanceslist.field.interfacePassword": "ConfigInterface Password(CCBCS)",
  "cms.callcenterinstanceslist.message.ivrNumsError": "The number of IVR channels must be greater than the sum of the number of TTS channels and the number of ASR channels.",
  "cms.callcenterinstanceslist.label.nextStep": "Next",
  "cms.callcenterinstanceslist.message.nodeIdCheckError": "Ensure that the entered CTI node information ID, uapId, and name are unique.",
  "cms.callcenterinstanceslist.message.odfConfigError": "ODFS service configuration information is incorrect.",
  "cms.callcenterinstanceslist.field.passwordConfirm": "Confirm Password",
  "cms.callcenterinstanceslist.message.resourceError": "The modified resource information is smaller than the actual number of call centers.",
  "cms.callcenterinstanceslist.title.success": "Success",
  "cms.callcenterinstanceslist.field.sysAdminPassword": "CTI Administrator Password",
  "cms.callcenterinstanceslist.field.systemAdmin": "CTI Administrator ID",
  "cms.callcenterinstanceslist.title.callCenter": "Call Center",
  "cms.callcenterinstanceslist.message.updateFailed": "Failed to modify the call center.",
  "cms.callcenterinstanceslist.message.updateSuccess": "The call center is modified successfully.",
  "cms.callcenterinstanceslist.message.videoCallNumsError": "The maximum number of concurrent video calls must be greater than or equal to the number of video seats.",
  "cms.callcenterinstanceslist.message.voiceCallNumsError": "The maximum number of concurrent voice calls must be greater than or equal to the sum of the number of voice seats and the number of IVR channels.",
  "cms.callcenterinstanceslist.title.warning": "Warning",
  "cms.callcenterinstanceslist.message.workNoCheckError": "The start number of the work ID must be smaller than the end number.",
  "cms.callcenterinstanceslist.message.workNoError": "The start employee ID must be in the range of 101 to 50000.",
  "cms.callcenterinstanceslist.label.callCenterRecordDrive": "Call Center Recording Drive Letter",
  "cms.callcenterinstanceslist.label.recordDrive": "Recording Drive Letter",
  "cms.callcenterinstanceslist.label.batchAdd": "Batch Add",
  "cms.callcenterinstanceslist.field.tenantName": "Tenant name",
  "cms.callcenterinstanceslist.title.baseInfo": "Basic Info",
  "cms.callcenterinstanceslist.field.ccBcsIp": "CCBCS LB IP Address",
  "cms.callcenterinstanceslist.field.ccBcsPort": "CCBCS LB Port",
  "cms.callcenterinstanceslist.field.ccBmsIp": "CCBMS LB IP Address",
  "cms.callcenterinstanceslist.field.ccBmsPort": "CCBMS LB Port",
  "cms.callcenterinstanceslist.field.ccId": "Call Center ID",
  "cms.callcenterinstanceslist.field.ccName": "Call Center Name",
  "cms.callcenterinstanceslist.field.cmsIp": "CMS LB IP Address",
  "cms.callcenterinstanceslist.field.cmsPort": "CMS LB Port",
  "cms.callcenterinstanceslist.label.create": "New",
  "cms.callcenterinstanceslist.label.delete": "Delete",
  "cms.callcenterinstanceslist.message.deleteConfirmMessage": "Are you sure you want to delete the selected call center and related configuration?",
  "cms.callcenterinstanceslist.title.deleteconfim": "Confirm Delete",
  "cms.callcenterinstanceslist.field.gwIp": "CC-Gateway IP",
  "cms.callcenterinstanceslist.field.gwPort": "CC-Gateway Port",
  "cms.callcenterinstanceslist.field.operate": "Operation",
  "cms.callcenterinstanceslist.label.refresh": "Refresh",
  "cms.callcenterinstanceslist.label.search": "Query",
  "cms.callcenterinstanceslist.label.synchronize": "Sync",
  "cms.callcenterinstanceslist.field.ctiversion": "CTI VERSION",
  "cms.callcenterinstanceslist.field.adminCount": "CTI Administrator ID",
  "cms.callcenterinstanceslist.field.tenantSpaceName": "Administrator Account",
  "cms.callcenterinstanceslist.label.vCallCenter": "Virtual Call Center",
  "cms.callcenterinstanceslist.field.vccId": "Virtual Call Center ID",
  "cms.callcenterinstanceslist.field.vdnName": "VDN Name",
  "cms.callcenterinstanceslist.message.passLengthValidateFailed": "A password contains a minimum of eight characters.",
  "cms.callcenterinstanceslist.message.passValidateFailed": "The password must contain at least three types of the following: uppercase letters, lowercase, digits, and following special characters: {'~ ! @ # $ % ^ & * () - _ = + | [ { } ] ; : \' \"  , < . > / ?'}",
  "cms.callcenterinstanceslist.message.passValidateContinuityFailed": "The password cannot contain three consecutive identical characters.",
  "cms.callcenterinstanceslist.message.syncPassValidateFailed": "The value must contain uppercase letters, lowercase letters, digits, and special characters `~!@#$%^&*()-_=+|[{}];: '\", <. > /?",
  "cms.callcenterinstanceslist.label.save": "Save",
  "cms.callcenterinstanceslist.message.specialStrValidateFailed": "The call center name cannot contain special characters.",
  "cms.callcenterinstanceslist.message.wasPsdLengthRangeValidateFailed": "Password length should be between 16 and 32",
  "cms.callcenterinstanceslist.message.uapPsdLengthRangeValidateFailed": "Password length should be between 6 and 32",
  "cms.callcenterinstanceslist.message.passWordLengthValidateFailed": "The password must contain 8 to 20 characters.",
  "cms.callcenterinstanceslist.label.assignDeveloper": "Associated Developer",
  "cms.callcenterinstanceslist.field.developer": "Associated Developer",
  "cms.callcenterinstanceslist.message.selectdeveloper": "Select a developer.",
  "cms.callcenterinstanceslist.title.nodemodeselect": "Select Mode",
  "cms.callcenterinstanceslist.field.nodemodesingle": "Single mode",
  "cms.callcenterinstanceslist.field.nodemodepool": "Pool mode",
  "cms.callcenterinstanceslist.field.open": "Enable",
  "cms.callcenterinstanceslist.field.close": "Disable",
  "cms.callcenterinstanceslist.title.chooseFeature": "Feature Selection",
  "cms.callcenterinstanceslist.field.ctiIndexMonitor": "CTI System Indicator Monitoring",
  "cms.callcenterinstanceslist.field.uapIndexMonitor": "UAP System Indicator Monitoring",
  "cms.callcenterinstanceslist.field.kafkaPushSwitch": "KAFKA Data Pushing",
  "cms.callcenterinstanceslist.field.ccStatus": "Call Center Status",
  "cms.callcenterinstanceslist.field.backupcc": "DR Call Center",
  "cms.callcenterinstanceslist.field.normalStatus": "Normal",
  "cms.callcenterinstanceslist.field.faultStatus": "Faulty",
  "cms.callcenterinstanceslist.message.maxNumber": "A maximum of 10 call centers can be added.",
  "cms.failurecall.field.accessCode": "Access Code",
  "cms.failurecall.field.callSequenceNumber": "Call S/N",
  "cms.failurecall.field.queueName": "Queue Name",
  "cms.failurecall.field.reset": "Reset",
  "cms.failurecall.field.search": "Search",
  "cms.failurecall.field.userPhone": "Service Number",
  "cms.failurecall.field.waitTime": "Waiting Duration",
  "cms.failurecall.message.currentFailureCall": "There are ",
  "cms.failurecall.message.person": " calls canceled during queuing.",
  "cms.failurecall.placeholder.accessCodeInput": "Enter an access code",
  "cms.failurecall.placeholder.userPhoneInput": "Enter a service number",
  "cms.vcallecenter.title.success": "Success",
  "cms.vcallecenter.message.syncSuccess": "The virtual call centers are synchronized successfully.",
  "cms.vcallecenter.message.syncLoading": "Operation in progress. Please try again later.",
  "cms.vcallecenter.title.failed": "Error",
  "cms.vcallecenter.message.syncFailed": "Synchronization failed",
  "cms.monitordetail.field.account": "Account",
  "cms.monitordetail.field.adjustCount": "Agent Enters the Arranging State",
  "cms.monitordetail.field.adjustDuration": "Arranging Duration",
  "cms.monitordetail.field.agentReleaseCount": "Agent Releases Calls",
  "cms.monitordetail.field.answerCount": "Answered Calls",
  "cms.monitordetail.field.audio": "Audio/Video",
  "cms.monitordetail.field.avgTalkingDuration": "Average Call Duration",
  "cms.monitordetail.field.busy": "Busy",
  "cms.monitordetail.field.busyCount": "Agent Enters the Busy State",
  "cms.monitordetail.field.busyDuration": "Busy Duration",
  "cms.monitordetail.field.busydurationavg": "Average Busy Duration",
  "cms.monitordetail.field.calling": "Talking",
  "cms.monitordetail.field.conferencecount": "Three-Party Calls",
  "cms.monitordetail.field.holdcount": "Held Calls",
  "cms.monitordetail.field.holdduration": "Holding Duration",
  "cms.monitordetail.field.holddurationavg": "Average Holding Duration",
  "cms.monitordetail.field.idle": "Idle",
  "cms.monitordetail.field.idlecount": "Agent Enters the Idle State",
  "cms.monitordetail.field.innerforward": "Internally Transferred Calls",
  "cms.monitordetail.field.intercallcount": "Internal Calls",
  "cms.monitordetail.field.media": "Multimedia",
  "cms.monitordetail.field.mediatype": "Call Media Type",
  "cms.monitordetail.field.noanswercount": "Calls Not Answered for a Long Period",
  "cms.monitordetail.field.outboundcount": "Successful Outbound Calls",
  "cms.monitordetail.field.outerforward": "Externally Transferred Calls",
  "cms.monitordetail.field.redirectcount": "Redirected Calls",
  "cms.monitordetail.field.rest": "Rest",
  "cms.monitordetail.field.restcount": "Rest",
  "cms.monitordetail.field.restduration": "Rest Duration",
  "cms.monitordetail.field.restoutcount": "Timeout Rests",
  "cms.monitordetail.field.restoutduration": "Rest Timeout Duration",
  "cms.monitordetail.field.role": "Agent Role",
  "cms.monitordetail.field.signinskill": "Sign-in Queue",
  "cms.monitordetail.field.sipNumber": "Sign-in Call",
  "cms.monitordetail.field.skill": "Skill Queue",
  "cms.monitordetail.field.totaltalkingcount": "Total Number of Calls",
  "cms.monitordetail.field.totaltalkingduration": "Total Call Duration",
  "cms.monitordetail.field.detail": "Agent Details",
  "cms.monitordetail.field.information": "Basic Information",
  "cms.monitordetail.field.duration": "Statistics on Duration",
  "cms.monitordetail.field.count": "Statistics on Number of Times",
  "cms.monitordetail.title.statistics": "Statistics",
  "cms.agentmonitor.label.autoRefresh": "Auto Refresh",
  "cms.agentmonitor.label.pageRefreshTime": "Data updated",
  "cms.qcrelationconf.field.accountCode": "Service Account",
  "cms.qcrelationconf.field.accountName": "Agent Name",
  "cms.qcrelationconf.field.addGroupMember": "Add Group Member",
  "cms.qcrelationconf.field.agentGroup": "Agent Group",
  "cms.qcrelationconf.field.cancel": "Cancel",
  "cms.qcrelationconf.field.delete": "Delete",
  "cms.qcrelationconf.field.deleteGroupMember": "delete Group Member",
  "cms.qcrelationconf.field.group": "Group",
  "cms.qcrelationconf.field.groupInfo": "User Group Basic Information",
  "cms.qcrelationconf.field.groupMemberInfo": "User Group Member Information",
  "cms.qcrelationconf.field.groupName": "User Group Name",
  "cms.qcrelationconf.field.groupType": "User Group Type",
  "cms.qcrelationconf.placeholder.groupType": "please select",
  "cms.qcrelationconf.title.confirm": "Confirm",
  "cms.qcrelationconf.field.ok": "Ok",
  "cms.qcrelationconf.field.operation": "Action",
  "cms.qcrelationconf.field.operator": "Common agent",
  "cms.qcrelationconf.field.configAdmin": "Configuration Administrator",
  "cms.qcrelationconf.field.outgoingAgent": "Callout agent",
  "cms.qcrelationconf.field.qcGroup": "Quality Check Group",
  "cms.qcrelationconf.field.qualityInspection": "Quality checker",
  "cms.qcrelationconf.field.remark": "Remark",
  "cms.qcrelationconf.field.reset": "Reset",
  "cms.qcrelationconf.field.role": "Role",
  "cms.qcrelationconf.field.search": "Query",
  "cms.qcrelationconf.field.workNo": "Agent ID",
  "cms.qcrelationconf.message.addSuccess": "Add Success",
  "cms.qcrelationconf.message.deleteSuccess": "Delete Success",
  "cms.qcrelationconf.message.isDeleteGroupInfo": "Please confirm whether to delete the user group",
  "cms.qcrelationconf.message.isDeleteGroupMember": "Please confirm whether to delete the group member",
  "cms.qcrelationconf.message.paramOutOfRange": "Input cannot exceed 100 characters",
  "cms.qcrelationconf.message.queryFailed": "Query failed",
  "cms.qcrelationconf.message.queryFailedLimit": "The number of records per page must be greater than 0 and less than 101.",
  "cms.qcrelationconf.message.queryParamNull": "Agent id and user group name cannot be empty at the same time",
  "cms.qcrelationconf.message.selectAgent": "Please select the agent to be deleted",
  "cms.qcrelationconf.message.selectGroup": "Please select the user group to be deleted.",
  "cms.qcrelationconf.message.selectGroupType": "Please select user group type first",
  "cms.qcrelationconf.message.specialChar": "Invalid char",
  "cms.qcrelationconf.message.updateSuccess": "Update Success",
  "cms.qcrelationconf.placeholder.pleaseInput": "Please input",
  "cms.qcrelationconf.placeholder.workNo": "Please input agent id",
  "cms.qcrelationconf.placeholder.groupName": "Group name",
  "cms.qcrelationconf.title.error": "Error",
  "cms.qcrelationconf.title.success": "Success",
  "cms.qcrelationconf.title.warning": "Warning",
  "cms.qcrelationconf.placeholder.workName": "Enter an agent name",
  "cms.qcrelationconf.placeholder.accountCode": "Enter a business account.",
  "cms.qcrelationconf.field.qcGroupName": "Inspector Group",
  "cms.qcrelationconf.field.qcWorkNo": "Inspector ID",
  "cms.qcrelationconf.field.qcAccountName": "Inspector Name",
  "cms.qcrelationconf.field.qcCcRoleName": "Inspector Role",
  "cms.qcrelationconf.field.agentGroupName": "Inspected Group",
  "cms.qcrelationconf.field.agentWorkNo": "Inspected Party ID",
  "cms.qcrelationconf.field.agentAccountName": "Inspected Party Name",
  "cms.qcrelationconf.field.agentCcRoleName": "Inspected Party Role",
  "cms.recorddrive.placeholder.deleteRecorddrive": "Please select the drive letter to delete",
  "cms.recorddrive.placeholder.batchdeleteconfim": "Are you sure to delete all selected drive letters?",
  "cms.recorddrive.placeholder.singledeleteconfim": "Are you sure to delete the selected drive letter?",
  "cms.tenant.message.adminpassword": "Password",
  "cms.tenant.message.adminpasswordagain": "Confirm Password",
  "cms.tenant.message.pwdCheckmsg": "The two passwords are different.",
  "cms.tenant.message.tenantspaceOk": "OK",
  "cms.indexmonitor.title.callCenterDayMonitor": "Today's Statistics on Call Center Indicators",
  "cms.indexmonitor.title.callCenterDayTrend": "Today's Trend of the Call Center",
  "cms.indexmonitor.title.vdnNowMonitor": "Real-Time Monitoring Statistics on the VDN During Running",
  "cms.indexmonitor.title.vdnDayMonitor": "Today's Statistics on VDN Indicators",
  "cms.indexmonitor.title.vdnDayTrend": "Today's Trend of the VDN",
  "cms.indexmonitor.title.filteringIndicator": "Filter Indicator",
  "cms.indexmonitor.title.error": "Error",
  "cms.indexmonitor.title.success": "Success",
  "cms.indexmonitor.field.confirm": "Confirm",
  "cms.indexmonitor.field.cancel": "Cancel",
  "cms.indexmonitor.message.warningFiltering": "Select at least one indicator",
  "cms.indexmonitor.message.updateSuccess": "Update Success",
  "cms.indexmonitor.message.updateFailed": "Update Failed",
  "cms.indexmonitor.field.chooseAll": "Select all",
  "cms.indexmonitor.field.totalCallNum": "Total calls",
  "cms.indexmonitor.message.totalCallNum": "Number of inbound calls + Number of outbound calls",
  "cms.indexmonitor.field.totalQueueCallNum": "Total queuing calls",
  "cms.indexmonitor.message.totalQueueCallNum": "Number of calls queuing in skill queues and waiting for agent services",
  "cms.indexmonitor.field.totalConnectCallNum": "Total connected calls",
  "cms.indexmonitor.field.totalAgentConnectCallNum": "Number Of Agent In Conversations State",
  "cms.indexmonitor.message.totalAgentCallNumDesc": "Total number of connected calls (statistical period: current sign-in)",
  "cms.indexmonitor.message.totalConnectCallNum": "Number of connected inbound calls + Number of connected outbound calls",
  "cms.indexmonitor.field.totalAbandonedCallNum": "Total lost calls",
  "cms.indexmonitor.message.totalAbandonedCallNum": "Total number of calls – Total number of connected calls",
  "cms.indexmonitor.field.inboundCallNum": "Inbound calls",
  "cms.indexmonitor.message.inboundCallNum": "Number of manual inbound calls + Number of IVR inbound calls",
  "cms.indexmonitor.field.inboundConnectNum": "Connected inbound calls",
  "cms.indexmonitor.message.inboundConnectNum": "Number of connected manual inbound calls + Number of connected IVR inbound calls",
  "cms.indexmonitor.field.outboundCallNum": "Outbound calls",
  "cms.indexmonitor.message.outboundCallNum": "Number of manual outbound calls + Number of IVR outbound calls",
  "cms.indexmonitor.field.outboundConnectNum": "Connected outbound calls",
  "cms.indexmonitor.field.outboundConnectNumDesc": "cms.report.field.outOccupyNumDesc=Number of times that an agent connects outgoing calls.",
  "cms.report.field.outOccupyNumDesc": "Number of times that an agent connects outgoing calls.",
  "cms.report.field.threePartyCallCountDesc": "Number of three-party calls of an agent.",
  "cms.indexmonitor.message.outboundConnectNum": "Number of connected manual outbound calls + Number of connected IVR outbound calls",
  "cms.indexmonitor.field.agentInboundNum": "Manual inbound calls",
  "cms.indexmonitor.message.agentInboundNum": "Number of inbound calls that are allocated to skill queues or agents",
  "cms.indexmonitor.field.agentInboundConnectNum": "Connected manual inbound calls",
  "cms.indexmonitor.message.agentInboundConnectNum": "Number of manual inbound calls that are answered by agents",
  "cms.indexmonitor.field.agentOutboundNum": "Manual outbound calls",
  "cms.indexmonitor.message.agentOutboundNum": "Number of manual outbound calls.",
  "cms.indexmonitor.field.agentOutboundConnectNum": "Connected manual outbound calls",
  "cms.indexmonitor.message.agentOutboundConnectNum": "Number of manual outbound calls that are answered by called parties.",
  "cms.indexmonitor.field.inboundConnectRate": "Connected inbound calls",
  "cms.indexmonitor.message.inboundConnectRate": "Percentage of the number of connected inbound calls in the number of inbound calls.",
  "cms.indexmonitor.field.inboundAbandonRate": "Abandoned inbound calls",
  "cms.indexmonitor.message.inboundAbandonRate": "The percentage of the number of incoming calls that were not connected to the number of incoming calls. If the number of incoming calls is 0, the indicator value is 0.",
  "cms.indexmonitor.field.outboundConnectRate": "Connected outbound calls",
  "cms.indexmonitor.field.outboundAbandonRate": "Abandoned outbound calls",
  "cms.indexmonitor.message.outboundConnectRate": "Percentage of the number of connected outbound calls in the number of outbound calls.",
  "cms.indexmonitor.message.outboundAbandonRate": "Percentage of the number of abandoned outbound calls in the number of outbound calls.",
  "cms.indexmonitor.field.agentBoundConnectRate": "Connected manual inbound calls",
  "cms.indexmonitor.message.agentBoundConnectRate": "Percentage of the number of calls connected manually in the number of manual inbound calls.",
  "cms.indexmonitor.field.agentBoundAbandonRate": "Abandoned manual inbound calls",
  "cms.indexmonitor.message.agentBoundAbandonRate": "100 – Rate of connected manual inbound calls",
  "cms.indexmonitor.field.abandonInThree": "Calls abandoned in 3s",
  "cms.indexmonitor.message.abandonInThree": "Percentage of the number of calls abandoned within 3s in the total number of abandoned calls.",
  "cms.indexmonitor.field.abandonInFive": "Calls abandoned in 5s",
  "cms.indexmonitor.message.abandonInFive": "Percentage of the number of calls abandoned within 5s in the total number of abandoned calls.",
  "cms.indexmonitor.field.abandonInTen": "Calls abandoned in 10s",
  "cms.indexmonitor.message.abandonInTen": "Percentage of the number of calls abandoned within 10s in the total number of abandoned calls.",
  "cms.indexmonitor.field.abandonInThirty": "Calls abandoned in 30s",
  "cms.indexmonitor.message.abandonInThirty": "Percentage of the number of calls abandoned within 30s in the total number of abandoned calls.",
  "cms.indexmonitor.field.abandonInSixty": "Calls abandoned in 60s",
  "cms.indexmonitor.message.abandonInSixty": "Percentage of the number of calls abandoned within 60s in the total number of abandoned calls.",
  "cms.indexmonitor.field.agentConnectInThree": "Calls manually connected in 3s",
  "cms.indexmonitor.message.agentConnectInThree": "Percentage of the number of calls manually connected within 3s in the total number of manually connected calls.",
  "cms.indexmonitor.field.agentConnectInFive": "Calls manually connected in 5s",
  "cms.indexmonitor.message.agentConnectInFive": "Percentage of the number of calls manually connected within 5s in the total number of manually connected calls.",
  "cms.indexmonitor.field.agentConnectInTen": "Calls manually connected in 10s",
  "cms.indexmonitor.message.agentConnectInTen": "Percentage of the number of calls manually connected within 10s in the total number of manually connected calls.",
  "cms.indexmonitor.field.agentConnectInThirty": "Calls manually connected in 30s",
  "cms.indexmonitor.message.agentConnectInThirty": "Percentage of the number of calls manually connected within 30s in the total number of manually connected calls.",
  "cms.indexmonitor.field.agentConnectInSixty": "Calls manually connected in 60s",
  "cms.indexmonitor.message.agentConnectInSixty": "Percentage of the number of calls manually connected within 60s in the total number of manually connected calls.",
  "cms.indexmonitor.field.agentConnectAfterInTen": "Calls manually connected after 10s",
  "cms.indexmonitor.message.agentConnectAfterInTen": "Percentage of the number of calls manually connected after 10s in the total number of manually connected calls.",
  "cms.indexmonitor.field.agentConnectAfterInSixty": "Calls manually connected after 60s",
  "cms.indexmonitor.message.agentConnectAfterInSixty": "Percentage of the number of calls manually connected after 60s in the total number of manually connected calls.",
  "cms.indexmonitor.field.durationAvgVdnInbound": "Average inbound call duration",
  "cms.indexmonitor.message.durationAvgVdnInbound": "Total duration that inbound calls occupy the VDN/Total number of inbound calls",
  "cms.indexmonitor.field.durationAvgVdnOutbound": "Average outbound call duration",
  "cms.indexmonitor.message.durationAvgVdnOutbound": "Total duration that outbound calls occupy the VDN/Total number of outbound calls",
  "cms.indexmonitor.field.durationAvgConnect": "Average call duration",
  "cms.indexmonitor.message.durationAvgConnect": "Total connected call duration/Total number of connected calls",
  "cms.indexmonitor.field.durationAvgWait": "Average inbound call waiting duration",
  "cms.indexmonitor.message.durationAvgWait": "Average waiting duration of inbound calls",
  "cms.indexmonitor.field.totalAgentNum": "Total agents",
  "cms.indexmonitor.message.totalAgentNum": "Total number of current agents",
  "cms.indexmonitor.field.totalTalkingNum": "Agents in call",
  "cms.indexmonitor.message.totalTalkingNum": "Number of agents who are processing calls",
  "cms.indexmonitor.field.totalLoginNum": "Login agents",
  "cms.indexmonitor.message.totalLoginNum": "Number of agents who have logged in to the call center",
  "cms.indexmonitor.message.totalLoginNumDesc": "Number of agents who have signed in to the call center and handle only inbound calls",
  "cms.indexmonitor.field.totalLogoutNum": "Logout agents",
  "cms.indexmonitor.message.totalLogoutNum": "Number of agents who have not logged in to the call center and are in offline state",
  "cms.indexmonitor.field.totalBusyNum": "Agents in busy state",
  "cms.indexmonitor.message.totalBusyNum": "Number of agents in busy state",
  "cms.indexmonitor.field.totalIdeaNum": "Agents in idle state",
  "cms.indexmonitor.message.totalIdeaNum": "Number of agents who are in idle state and can process calls immediately",
  "cms.indexmonitor.field.totalLearningNum": "Agents in learning state",
  "cms.indexmonitor.message.totalLearningNum": "Number of agents in learning state",
  "cms.indexmonitor.field.totalAwcNum": "Agents in wrap-up state",
  "cms.indexmonitor.message.totalAwcNum": "Number of agents in wrap-up state after business handling",
  "cms.indexmonitor.field.totalAnsweringNum": "Agents in answering state",
  "cms.indexmonitor.message.totalAnsweringNum": "Number of agents whose phones are ringing",
  "cms.indexmonitor.field.totalRestNum": "Agents in rest state",
  "cms.indexmonitor.message.totalRestNum": "Number of agents in rest state",
  "cms.indexmonitor.field.callNum": "Number of Calls",
  "cms.indexmonitor.field.realTimeCalls": "real-time calls",
  "cms.indexmonitor.field.callsOfTheDay": "Calls of the day",
  "cms.indexmonitor.field.callRate": "Call Rate",
  "cms.indexmonitor.field.duration": "Duration",
  "cms.indexmonitor.field.agentNum": "Number of Agents",
  "cms.indexmonitor.field.agentName": "Tenant Name",
  "cms.indexmonitor.field.pageDataUpdateTime": "Data updated:",
  "cms.indexmonitor.field.ccName": "Call Center Name",
  "cms.indexmonitor.title.selectCcName": "Select Call Center",
  "cms.indexmonitor.title.selectAgentName": "Select Tenant",
  "cms.indexmonitor.field.all": "All",
  "cms.indexmonitor.field.status": "Status",
  "cms.indexmonitor.label.reset": "Reset",
  "cms.indexmonitor.label.search": "Search",
  "cms.indexmonitor.label.previous": "Previous",
  "cms.indexmonitor.label.next": "Next",
  "cms.indexmonitor.field.indicatorName": "Indicator",
  "cms.indexmonitor.field.description": "Description",
  "cms.indexmonitor.label.CommercialUse": "Commercial Use",
  "cms.indexmonitor.label.noncommercial": "Trial Commercial Use",
  "cms.indexmonitor.label.callCenterNode": "Call Center Node",
  "cms.indexmonitor.field.ivrCallNum": "IVR inbound call requests",
  "cms.indexmonitor.message.ivrCallNum": "Number of calls for which automatic services are provided by IVR devices",
  "cms.indexmonitor.field.ivrTotalCallNum": "Total IVR inbound calls",
  "cms.indexmonitor.message.ivrTotalCallNum": " Number of calls that are allocated to the IVR and served by the IVR completely",
  "cms.indexmonitor.field.ivrInboundNum": "Connected IVR inbound calls",
  "cms.indexmonitor.message.ivrInboundNum": "Number of IVR inbound call requests whose call duration is greater than 0",
  "cms.indexmonitor.field.ivrOutboundNum": "Connected IVR outbound calls",
  "cms.indexmonitor.message.ivrOutboundNum": "Number of IVR outbound calls answered by the called party",
  "cms.indexmonitor.field.ivrInboundFlowOut": "Transferred IVR inbound calls",
  "cms.indexmonitor.message.ivrInboundFlowOut": "Number of calls transferred to another device after the IVR plays an announcement",
  "cms.indexmonitor.field.ivrInboundUserAbort": "Released IVR inbound calls",
  "cms.indexmonitor.message.ivrInboundUserAbort": "Number of calls proactively hung up by a user in the IVR",
  "cms.indexmonitor.field.ivrInboundConnectRate": "Connected IVR inbound calls",
  "cms.indexmonitor.message.ivrInboundConnectRate": "Percentage of the number of connected IVR inbound calls in the number of IVR inbound call requests",
  "cms.indexmonitor.field.ivrDurationAvgConnect": "Average IVR call duration",
  "cms.indexmonitor.message.ivrDurationAvgConnect": "Total duration of IVR calls/Number of IVR calls",
  "cms.indexmonitor.title.ivrDayMonitor": "Today's Statistics on IVR Indicators",
  "cms.indexmonitor.title.ivrDayTrend": "Today's Trend of the IVR",
  "cms.indexmonitor.message.mediaTypeMetion": "The media type filter item is valid only for current-day KPIs and current-day trend.",
  "cms.indexmonitor.title.ivrNowMonitor": "Real-time IVR running monitoring",
  "cms.indexmonitor.label.callOutIVR": "Number of IVR Online Outbound Calls",
  "cms.indexmonitor.label.callInIVR": "Number of IVR Online Inbound calls",
  "cms.indexmonitor.field.callOutIVR": "IVR Online Outbound Calls",
  "cms.indexmonitor.field.callInIVR": "IVR Online Inbound calls",
  "cms.indexmonitor.field.agentInCallCount": "Number of online agent inbound calls",
  "cms.indexmonitor.message.InCallAgentCount": "Number of Connected Incoming Calls",
  "cms.indexmonitor.field.agentOutCallCount": "Number of online agent outbound calls",
  "cms.indexmonitor.field.queueInCallCount": "Number of online queuing inbound calls",
  "cms.indexmonitor.field.queueOutCallCount": "Number of online queuing outbound calls",
  "cms.indexmonitor.field.totalInCallCount": "Online inbound calls",
  "cms.indexmonitor.message.agentInCallCount": "Number of Connected Incoming Calls.(Does not include multimedia sessions)",
  "cms.indexmonitor.message.agentConnectIn3Rate": "Percentage of the number of manually connected calls within 3 seconds (including 3 seconds) to the total number of calls.",
  "cms.indexmonitor.message.agentConnectIn5Rate": "Percentage of the number of manually connected calls within 5 seconds (including 5 seconds) to the total number of calls.",
  "cms.indexmonitor.message.agentConnectIn10Rate": "Percentage of the number of manually connected calls within 10 seconds (including 10 seconds) to the total number of calls.",
  "cms.indexmonitor.message.agentConnectIn30Rate": "Percentage of the number of manually connected calls within 30 seconds (including 30 seconds) to the total number of calls.",
  "cms.indexmonitor.message.agentConnectIn60Rate": "Percentage of the number of manually connected calls within 60 seconds (including 60 seconds) to the total number of calls.",
  "cms.indexmonitor.message.agentAbandonIn3Rate": "Percentage of abandoned calls within 3 seconds to total abandoned calls",
  "cms.indexmonitor.message.agentAbandonIn5Rate": "Percentage of abandoned calls within 5 seconds to total abandoned calls",
  "cms.indexmonitor.message.agentAbandonIn10Rate": "Percentage of abandoned calls within 10 seconds to total abandoned calls",
  "cms.indexmonitor.message.agentAbandonIn30Rate": "Percentage of abandoned calls within 30 seconds to total abandoned calls",
  "cms.indexmonitor.message.agentAbandonIn60Rate": "Percentage of abandoned calls within 60 seconds to total abandoned calls",
  "cms.indexmonitor.message.agentConnectedIn3NDesc": "Total number of calls whose answer duration is longer than or equal to 3 seconds",
  "cms.indexmonitor.message.agentConnectedIn5NDesc": "Total number of calls whose answer duration is longer than or equal to 5 seconds",
  "cms.indexmonitor.message.agentConnectedIn10NDesc": "Total number of calls whose answer duration is longer than or equal to 10 seconds",
  "cms.indexmonitor.message.agentConnectedIn30NDesc": "Total number of calls whose answer duration is longer than or equal to 30 seconds",
  "cms.indexmonitor.message.agentConnectedIn60NDesc": "Total number of calls whose answer duration is longer than or equal to 60 seconds",
  "cms.indexmonitor.message.agentAbandonIn3NDesc": "Total number of abandons with an answer duration longer than or equal to 3 seconds",
  "cms.indexmonitor.message.agentAbandonIn5NDesc": "Total number of abandons with an answer duration longer than or equal to 5 seconds",
  "cms.indexmonitor.message.agentAbandonIn10NDesc": "Total number of abandons with an answer duration longer than or equal to 10 seconds",
  "cms.indexmonitor.message.agentAbandonIn30NDesc": "Total number of abandons with an answer duration longer than or equal to 30 seconds",
  "cms.indexmonitor.message.agentAbandonIn60NDesc": "Total number of abandons with an answer duration longer than or equal to 60 seconds",
  "cms.indexmonitor.message.agentConnectedOut10NDesc": "Total number of connected calls whose answer duration is longer than 10 seconds.",
  "cms.indexmonitor.message.agentConnectedOut60NDesc": "Total number of connected calls whose answer duration is longer than 60 seconds.",
  "cms.indexmonitor.message.agentAbandonOut10NDesc": "Total number of abandoned calls whose answer duration is longer than 10 seconds.",
  "cms.indexmonitor.message.agentAbandonOut60NDesc": "Total number of abandoned calls whose answer duration is longer than 60 seconds.",
  "cms.indexmonitor.message.totalCallNumDesc": "Total number of calls that are connected by an agent.",
  "cms.indexmonitor.message.callOutSuccNum": "Number of times that an agent connects outgoing calls.",
  "cms.indexmonitor.message.agentOutCallCount": "Number of outbound calls made by the current agent.(Does not include multimedia sessions)",
  "cms.indexmonitor.message.queueInCallCount": "Number of inbound calls queuing in skill queues.(Does not include multimedia sessions)",
  "cms.indexmonitor.message.queueOutCallCount": "Number of outbound calls queuing in skill queues.(Does not include multimedia sessions)",
  "cms.indexmonitor.message.totalInCallCount": "Total number of inbound calls.(Does not include multimedia sessions)",
  "cms.indexmonitor.message.ivrInCallCount": "Number of inbound calls whose current service device is an IVR.",
  "cms.indexmonitor.message.ivrOutCallCount": "Number of outbound calls whose current service device is an IVR.",
  "cms.indexmonitor.field.skillOnlineInbondCall": "Number of online calls",
  "cms.indexmonitor.field.skillOnlineWaitCall": "Online queuing calls",
  "cms.indexmonitor.field.skillOnlineConnected": "Number of calls answered by agents in the current 5-minute block",
  "cms.indexmonitor.message.skillOnlineInbondCallDesc": "Number of calls that are being processed + Number of calls that are queuing",
  "cms.indexmonitor.message.skillOnlineWaitCallDesc": "Number of calls that are queuing.",
  "cms.indexmonitor.message.skillOnlineConnectedDesc": "Number of calls answered by agents in the current 5-minute block. 5-minute blocks run from 00:00 to 00:05, 00:05 to 00:10, and so on.",
  "cms.indexmonitor.field.allType": "All types",
  "cms.developerlist.label.create": "Add",
  "cms.developerlist.label.delete": "Delete",
  "cms.developerlist.label.refreshsk": "Update SK",
  "cms.developerlist.field.ak": "Developer AK",
  "cms.developerlist.field.description": "Description",
  "cms.developerlist.field.operate": "Operation",
  "cms.developerlist.title.create": "Add Developer",
  "cms.developerlist.title.deleteconfim": "Confirm",
  "cms.developerlist.message.deleteConfirmMessage": "Are you sure you want to delete the selected developers and related configurations?",
  "cms.developerlist.title.success": "Success",
  "cms.developerlist.message.deletesuccess": "Delete successfully.",
  "cms.developerlist.message.error": "Error",
  "cms.developerlist.message.deletefailed": "Deletion failed.",
  "cms.developerlist.title.refreshsk": "Update SK",
  "cms.developerlist.message.refreshConfirmMessage": "Are you sure you want to update the SK of the developer?",
  "cms.developerlist.message.refreshfailed": "Failed to update the SK.",
  "cms.developerlist.message.createfailed": "Failed to create the developer.",
  "cms.developerlist.message.checkInput": "The value cannot contain special characters.",
  "cms.developer.message.addDeveloper": "Add developer information",
  "cms.developer.message.modifyDeveloper": "Update developer information",
  "cms.developerlist.field.updateTime": "Modified at",
  "cms.developerlist.field.createTime": "Created at",
  "cms.developerlist.field.search": "Search",
  "cms.callcenterinstanceslist.field.developerDesc": "Description",
  "cms.indexmonitor.field.skillRequest": "Service requests",
  "cms.indexmonitor.field.skillConnected": "Connected call requests",
  "cms.indexmonitor.field.skillAbandon": "Lost call requests",
  "cms.indexmonitor.field.skillUserAbandon": "Abandoned calls",
  "cms.indexmonitor.field.skillAgentAbandon": "Calls hung up by agents",
  "cms.indexmonitor.field.skillQueueDisconnected": "Queue disconnected calls",
  "cms.indexmonitor.field.skillSystemLost": "Platform interruptions",
  "cms.indexmonitor.field.skillLostRing": "Unanswered calls",
  "cms.indexmonitor.field.skillTimeoutQueue": "Timeout calls to queues",
  "cms.indexmonitor.field.skillTimeoutAgent": "Timeout calls to agents",
  "cms.indexmonitor.field.skillOverQueue": "Overflow calls to queues",
  "cms.indexmonitor.field.skillOverAgent": "Overflow calls to agents",
  "cms.indexmonitor.field.skillConnectedIn3": "Calls connected in 3s",
  "cms.indexmonitor.field.skillConnectedIn5": "Calls connected in 5s",
  "cms.indexmonitor.field.skillConnectedIn10": "Calls connected in 10s",
  "cms.indexmonitor.field.skillConnectedIn20": "Calls connected in 20s",
  "cms.indexmonitor.field.skillConnectedIn30": "Calls connected in 30s",
  "cms.indexmonitor.field.skillConnectedIn60": "Calls connected in 60s",
  "cms.indexmonitor.field.skillAbandonIn3": "Calls abandoned in 3s",
  "cms.indexmonitor.field.skillAbandonIn5": "Calls abandoned in 5s",
  "cms.indexmonitor.field.skillAbandonIn10": "Calls abandoned in 10s",
  "cms.indexmonitor.field.skillAbandonIn20": "Calls abandoned in 20s",
  "cms.indexmonitor.field.skillAbandonIn30": "Calls abandoned in 30s",
  "cms.indexmonitor.field.skillAbandonIn60": "Calls abandoned in 60s",
  "cms.indexmonitor.field.skillConnectedAfter10": "Calls connected after 10s",
  "cms.indexmonitor.field.skillConnectedAfter60": "Calls connected after 60s",
  "cms.indexmonitor.field.skillAbandonAfter10": "Calls abandoned after 10s",
  "cms.indexmonitor.field.skillAbandonAfter60": "Calls abandoned after 60s",
  "cms.indexmonitor.field.skillConnectedRate": "Connected call requests",
  "cms.indexmonitor.field.SkillAbandonRate": "Lost call requests",
  "cms.indexmonitor.field.skillConnectedIn3Rate": "Calls connected in 3s",
  "cms.indexmonitor.field.skillConnectedIn5Rate": "Calls connected in 5s",
  "cms.indexmonitor.field.skillConnectedIn10Rate": "Calls connected in 10s",
  "cms.indexmonitor.field.skillConnectedIn30Rate": "Calls connected in 30s",
  "cms.indexmonitor.field.skillConnectedIn60Rate": "Calls connected in 60s",
  "cms.indexmonitor.field.skillAbandonIn3Rate": "Calls abandoned in 3s",
  "cms.indexmonitor.field.skillAbandonIn5Rate": "Calls abandoned in 5s",
  "cms.indexmonitor.field.skillAbandonIn10Rate": "Calls abandoned in 10s",
  "cms.indexmonitor.field.skillAbandonIn20Rate": "Calls abandoned in 20s",
  "cms.indexmonitor.field.skillAbandonIn30Rate": "Calls abandoned in 30s",
  "cms.indexmonitor.field.skillAbandonIn60Rate": "Calls abandoned in 60s",
  "cms.indexmonitor.field.skillAvgConnected": "Average call duration",
  "cms.indexmonitor.field.skillAvgQueueAck": "Average waiting duration",
  "cms.indexmonitor.field.skillAvgConnectedAck": "Average ring duration of connected calls",
  "cms.indexmonitor.field.skillAvgAbandonAck": "Average ring duration of lost calls",
  "cms.indexmonitor.field.skillAvgConnectedQueue": "Average queuing duration of connected calls",
  "cms.indexmonitor.field.skillAvgAbandonQueue": "Average queuing duration of lost calls",
  "cms.indexmonitor.field.skillMaxQueueAck": "Max waiting duration",
  "cms.indexmonitor.field.skillMinQueueAck": "Min waiting duration",
  "cms.indexmonitor.title.skillNowMonitor": "Real-Time Monitoring Statistics on the Skill Queue During Running",
  "cms.indexmonitor.title.skillDayMonitor": "Today's Statistics on Skill Queue Indicators",
  "cms.indexmonitor.title.skillDayTrend": "Today's Trend of the Skill Queue",
  "cms.indexmonitor.title.skillQueueStatistics": "Skill Queue Statistics on the current day",
  "cms.indexmonitor.title.agentNowMonitor": "Real-Time Monitoring Statistics on the Agent During Running",
  "cms.indexmonitor.title.agentDayMonitor": "Today's Statistics on Agent Indicators",
  "cms.indexmonitor.title.agentDayTrend": "Today's Trend of the Agent",
  "cms.indexmonitor.message.skillRequestDesc": "Number of inbound calls requesting user services",
  "cms.indexmonitor.message.skillConnectedDesc": "Number of calls that are connected to agents in the skill queue.",
  "cms.indexmonitor.message.skillAbandonDesc": "Number of lost manual inbound call requests = Number of manual inbound call requests – Number of connected manual inbound call requests",
  "cms.indexmonitor.message.skillUserAbandonDesc": "Number of calls proactively abandoned by users during queuing and ringing",
  "cms.indexmonitor.message.skillAgentAbandonDesc": "Number of calls proactively hung up by agents during ringing",
  "cms.indexmonitor.message.skillQueueDisconnectedDesc": "Number of times that calls are disconnected by the CTI platform when the calls are queuing",
  "cms.indexmonitor.message.skillSystemLostDesc": "Number of platform interruptions caused by reasons such as agent response timeout",
  "cms.indexmonitor.message.skillLostRingDesc": "Number of unanswered calls after being allocated to agents",
  "cms.indexmonitor.message.skillTimeoutQueueDesc": "Number of calls transferred from the original skill queue to other skill queues due to queuing timeout",
  "cms.indexmonitor.message.skillTimeoutAgentDesc": "Number of calls transferred from the original skill queue to other agents due to queuing timeout",
  "cms.indexmonitor.message.skillOverQueueDesc": "Number of calls transferred from the original skill queue to other skill queues due to overflow",
  "cms.indexmonitor.message.skillOverAgentDesc": "Number of calls transferred from the original skill queue to other agents due to overflow",
  "cms.indexmonitor.message.skillConnectedInNDesc": "Number of call requests manually connected in Ns. Currently, Ns can be 3s, 5s, 10s, 20s, 30s, or 60s.",
  "cms.indexmonitor.message.skillAbandonInNDesc": "Number of call requests manually abandoned in Ns. Currently, Ns can be 3s, 5s, 10s, 20s, 30s, or 60s.",
  "cms.indexmonitor.message.skillConnectedAfterNDesc": "Number of call requests manually connected after Ns. Currently, Ns can be 10s or 60s.",
  "cms.indexmonitor.message.skillAbandonAfterNDesc": "Number of call requests manually abandoned after Ns. Currently, Ns can be 10s or 60s.",
  "cms.indexmonitor.message.skillConnectedRateDesc": "Percentage of the number of connected manual inbound call requests in the number of manual inbound call requests",
  "cms.indexmonitor.message.SkillAbandonRateDesc": "Lost call rate = 100 – Connected call rate",
  "cms.indexmonitor.message.skillConnectedInNRate": "Percentage of the number of call requests manually connected in Ns in the total number of calls requesting user services",
  "cms.indexmonitor.message.skillAbandonInNRateDesc": "Percentage of the number of call requests manually abandoned in Ns in the total number of call requesting user services",
  "cms.indexmonitor.message.skillAvgConnectedDesc": "Average call duration. Average manual inbound call duration = Manual inbound call duartion/Number of manual connected inbound calls.",
  "cms.indexmonitor.message.skillAvgQueueAckDesc": "Average waiting duration of calls Average waiting duration (queuing and ring duration) of all calls.",
  "cms.indexmonitor.message.skillAvgConnectedAckDesc": "Average ring duration of all connected calls in a queue",
  "cms.indexmonitor.message.skillAvgAbandonAckDesc": "Average ring duration of all unanswered calls",
  "cms.indexmonitor.message.skillAvgConnectedQueueDesc": "Average queuing duration of all connected calls in a queue",
  "cms.indexmonitor.message.skillAvgAbandonQueueDesc": "Average queuing duration of all unanswered calls in a queue",
  "cms.indexmonitor.message.skillMaxQueueAckDesc": "Maximum waiting duration (queuing and ring duration) of all calls",
  "cms.indexmonitor.message.skillMinQueueAckDesc": "Minimum waiting duration (queuing and ring duration) of all calls",
  "cms.indexmonitor.message.errorInfo": "Please select at least one skill queue.",
  "cms.indexmonitor.message.errorAgentInfo": "Please select at least one agent.",
  "cms.indexmonitor.message.errorOrgInfo": "Please select an organization first.",
  "cms.indexmonitor.message.skillIdMaxSize": "A maximum of five skill queues can be selected.",
  "cms.qualitycheck.message.adjustskill": "Your skill queue has been temporarily adjusted to {skillList}. The original skill will be restored after you sign in again.",
  "cms.agentmonitor.label.cancelInsert": "Cancel Insertion",
  "cms.agentmonitor.label.cancellistening": "Cancel Listening",
  "cms.indexmonitor.field.currentSelectedSkill": "Selected skill queue:",
  "cms.indexmonitor.message.queryCallCenterError": "An exception occurred when querying the selected call center.",
  "cms.indexmonitor.message.skillLimit": "Select 1 to 10 skill queues.",
  "cms.report.title.error": "Error",
  "cms.report.label.reset": "Reset",
  "cms.report.label.search": "Search",
  "cms.report.field.startTime": "Start Date",
  "cms.report.field.endTime": "End Date",
  "cms.report.field.skillId": "Skill Queue ID",
  "cms.report.field.skillName": "Skill Queue Name",
  "cms.report.field.agentId": "Agent ID",
  "cms.report.field.inOccupyNum": "Service Requests",
  "cms.report.field.inCallSuccNum": "Number of connected manual calls",
  "cms.report.field.callSuccRate": "Request Connection Rate",
  "cms.report.field.inCallFailNum": "Lost Call Requests",
  "cms.report.field.call3Rate": "Manual Inbound Call Connection Rate in 15s",
  "cms.report.field.call4Rate": "Manual Inbound Call Connection Rate in 20s",
  "cms.report.field.call6Rate": "Manual Inbound Call Connection Rate in 30s",
  "cms.report.field.succWaitAnsRate": "Average Waiting Duration",
  "cms.report.field.failQueueWaitRate": "Average Ring Duration of Lost Calls",
  "cms.report.field.inCallRate": "Average Call Duration",
  "cms.report.field.waitAnsRate": "Average Ring Duration of Manual Inbound Calls",
  "cms.report.field.abortQueueWaitNum": "Abandoned Queuing Calls",
  "cms.report.field.noAckNum": "Unanswered Calls",
  "cms.report.field.inWaitAnsNum": "Manual Incoming Calls",
  "cms.report.field.inAnswerRate": "Inbound Call Connection Rate",
  "cms.report.field.callAbandonedRing": "Number of lost manual incoming calls",
  "cms.report.field.inAvgRingTime": "Average Ring Duration of Manual Inbound Calls",
  "cms.report.field.inAvgCallTime": "Average Call Duration",
  "cms.report.field.outWaitAnsNum": "Manual Outbound Calls",
  "cms.report.field.outCallSuccNum": "Connected Manual Outbound Calls",
  "cms.report.field.outAnswerRate": "Outbound Call Connection Rate",
  "cms.report.field.outAvgRingTime": "Average Ring Duration of Manual Outbound Calls",
  "cms.report.field.outAvgCallTime": "Average Manual Outbound Call Duration",
  "cms.report.field.acdCall": "Total Connected Calls",
  "cms.report.field.avgCallPerHour": "Average Manual Inbound Calls Per Hour",
  "cms.report.field.occupancyRate": "Work Duration Rate",
  "cms.report.field.utilizationRate": "Work State Duration Rate",
  "cms.report.field.workTime": "Total Work Duration",
  "cms.report.field.acdTime": "Manual Call Duration",
  "cms.report.field.acdTimeRate": "Manual Call Duration Rate",
  "cms.report.field.avgAcdTime": "Average Manual Call Duration",
  "cms.report.field.acwTime": "Total Wrap-Up Duration",
  "cms.report.field.acwTimeRate": "Wrap-Up Duration Rate",
  "cms.report.field.avgAcwTime": "Average Wrap-Up Duration",
  "cms.report.field.avgHandleTime": "Average Processing Duration",
  "cms.report.field.auxTime": "Total Rest Duration",
  "cms.report.field.auxTimeRate": "Rest Duration Rate",
  "cms.report.field.busyTime": "Total Busy Duration",
  "cms.report.field.busyTimeRate": "Busy Duration Rate",
  "cms.report.field.ringTime": "Total Ring Duration",
  "cms.report.field.availTime": "Idle Duration",
  "cms.report.field.availTimeRate": "Idle Rate",
  "cms.report.field.holdTime": "Hold Duration",
  "cms.report.field.avgHoldTime": "Average Hold Duration",
  "cms.report.field.outOccupyNum": "Outbound Calls",
  "cms.report.field.outCallRate": "Outbound Call Connection Rate",
  "cms.report.field.outAboundRing": "Unanswered Outbound Calls",
  "cms.report.field.outInvalidCall": "Invalid Outbound Calls",
  "cms.report.field.outFailNum": "Total Failed Outbound Calls",
  "cms.report.field.outCallTime": "Total Outbound Call Duration",
  "cms.report.field.outWaitAnsTime": "Total Ring Duration of Outbound Calls",
  "cms.report.field.outAvgAnsTime": "Average Ring Duration of Outbound Calls",
  "cms.report.field.acwCount": "Wrap-Up Times",
  "cms.report.field.acwOutTime": "Outbound Call Wrap-Up Duration",
  "cms.report.field.acwOutCount": "Outbound Call Wrap-Up Times",
  "cms.report.field.acwInCount": "Inbound Call Wrap-Up Times",
  "cms.report.field.acwInTime": "Inbound Call Wrap-Up Duration",
  "cms.report.field.auxCount": "Rest Times",
  "cms.report.field.holdCount": "Hold Times",
  "cms.report.field.muteCount": "Silence Times",
  "cms.report.field.muteTime": "Total Silence Duration",
  "cms.report.field.assistsCount": "Internal Help Requests",
  "cms.report.field.assistTime": "Internal Help Request Duration",
  "cms.report.field.busyCount": "Busy Times",
  "cms.report.field.playVoiceCount": "Voice Playing Times",
  "cms.report.field.playVoiceTime": "Voice Playing Duration",
  "cms.report.field.otherTime": "Total Duration of Other States",
  "cms.report.field.loginCount": "Sign-In Times",
  "cms.report.field.logoutCount": "Sign-Out Times",
  "cms.report.field.threePartyCallCount": "Three-Party Calls",
  "cms.report.field.internalTransferCount": "Internal Transfers",
  "cms.report.field.transferOutCount": "Manual Transfer-Out Times",
  "cms.report.field.transferIvrCount": "Manual-to-IVR Transfer Times",
  "cms.report.field.internalCallCount": "Internal Calls",
  "cms.report.field.internalCallTime": "Internal Call Duration",
  "cms.report.field.manToSkillNum": "Manual-to-Skill Queue Transfers",
  "cms.report.field.agentHangNumCti": "Agent Hang-Ups",
  "cms.report.field.custHangNumCti": "Customer Hang-Ups",
  "cms.report.field.otherHangNumCti": "System Hang-Ups",
  "cms.report.field.manToIvrNumCti": "Number of times that an agent is suspended to transfer to the IVR",
  "cms.report.field.manTransferOutNumCti": "Number of manual transfers to IVR and re-transfers out",
  "cms.report.field.calleeNo": "Access Code",
  "cms.report.field.systemInBound": "Total System Inbound Calls",
  "cms.report.field.systemInSucc": "Successful System Inbound Calls",
  "cms.report.field.sysSuccRate": "System Inbound Call Success Rate",
  "cms.report.field.sysLostCalls": "Failed System Inbound Calls",
  "cms.report.field.IVRInBound": "IVR Inbound Calls",
  "cms.report.field.IVRInSucc": "Connected IVR Inbound Calls",
  "cms.report.field.IVRSuccRate": "IVR Inbound Call Connection Rate",
  "cms.report.field.onlyIVRService": "Successful Pure-IVR Inbound Calls",
  "cms.report.field.IVRServiceRate": "Pure-IVR Inbound Call Success Rate",
  "cms.report.field.agentOffered": "Manual Inbound Calls",
  "cms.report.field.agentService": "Agent Answered Calls",
  "cms.report.field.awarnessRate": "Manual Inbound Call Success Rate",
  "cms.report.field.agentServiceRate": "Manual Success Rate in Total Inbound Calls",
  "cms.report.field.succAnsRate": "Request Connection Rate",
  "cms.report.field.avgAnsTime": "Average Queuing Duration of Connected Calls",
  "cms.report.field.ans20s": "Manual Call Connection Rate in 20s",
  "cms.report.field.avgCallInTime": "Average Call Duration",
  "cms.report.field.avgInRingTime": "Average Inbound Call Duration of Terminals",
  "cms.report.field.failAnsRate": "Request Loss Rate",
  "cms.report.field.failAvgTime": "Average Ring Duration of Lost Calls",
  "cms.report.field.maxQueueWaitTime": "Max Waiting Duration",
  "cms.report.field.outSuccAnsRate": "Outbound Call Connection Rate",
  "cms.report.field.acdCalls": "Total Connected Calls",
  "cms.report.field.avgPerHNum": "Average Successful Calls Per Hour",
  "cms.report.field.agentLoginNum": "Called Agents",
  "cms.report.field.perAgentNum": "Agent Calls",
  "cms.report.field.workRate": "Work Duration Rate",
  "cms.report.field.onlineRate": "Online Rate",
  "cms.report.field.otherTimeRate": "Other Duration Rate",
  "cms.report.field.inCallNum": "Inbound Calls",
  "cms.report.field.inCallSuccNumber": "Connected Inbound Calls",
  "cms.report.field.agentInBound": "Manual Inbound Calls",
  "cms.report.field.agentInSucc": "Connected Manual Inbound Calls",
  "cms.report.field.outCallNum": "Outbound Calls",
  "cms.report.field.outCallSuccNumber": "Connected Outbound Calls",
  "cms.report.field.IVROutCallNum": "Pure-IVR Outbound Calls",
  "cms.report.field.IVROutSuccNum": "Connected Pure-IVR Outbound Calls",
  "cms.report.field.agentOutBound": "Manual Outbound Calls",
  "cms.report.field.agentOutSucc": "Connected Manual Outbound Calls",
  "cms.report.field.inCallSuccRate": "Inbound Call Connection Rate",
  "cms.report.field.IVRInSuccRate": "IVR Inbound Call Connection Rate",
  "cms.report.field.agentInSuccRate": "Manual Inbound Call Connection Rate",
  "cms.report.field.outCallSuccRate": "Outbound Call Connection Rate",
  "cms.report.field.IVROutSuccRate": "Pure-IVR Outbound Call Connection Rate",
  "cms.report.field.agentOutSuccRate": "Manual Outbound Call Connection Rate",
  "cms.report.field.inVdnAvgTime": "Average Inbound Call Duration in VDN",
  "cms.report.field.outVdnAvgTime": "Average Outbound Call Duration in VDN",
  "cms.report.field.IVRCallOutNum": "IVR Outbound Calls",
  "cms.report.field.IVRCallOutSuccNum": "Connected IVR Outbound Calls",
  "cms.report.field.IVRCallOutSuccRate": "IVR Outbound Call Connection Rate",
  "cms.report.field.userBusyFailNum": "Lost Calls Due to Busy Lines",
  "cms.report.field.userRefusedFailNum": "Lost Calls Due to Call Rejection",
  "cms.report.field.userNoAnswerNum": "Lost Calls Due to Ringing Timeout",
  "cms.report.field.callLostOtherNum": "Other Lost Calls",
  "cms.report.field.minAgentInCount": "Min Concurrent Agent Inbound Calls",
  "cms.report.field.maxAgentInCount": "Max Concurrent Agent Inbound Calls",
  "cms.report.field.avgAgentInCount": "Avg Concurrent Agent Inbound Calls",
  "cms.report.field.minAgentOutCount": "Min Concurrent Agent Outbound Calls",
  "cms.report.field.maxAgentOutCount": "Max Concurrent Agent Outbound Calls",
  "cms.report.field.avgAgentOutCount": "Avg Concurrent Agent Outbound Calls",
  "cms.report.field.minAgentCount": "Min Concurrent Agent Calls",
  "cms.report.field.maxAgentCount": "Max Concurrent Agent Calls",
  "cms.report.field.avgAgentCount": "Avg Concurrent Agent Calls",
  "cms.report.field.minIVRInCount": "Min Concurrent IVR Inbound Calls",
  "cms.report.field.maxIVRInCount": "Max Concurrent IVR Inbound Calls",
  "cms.report.field.avgIVRInCount": "Avg Concurrent IVR Inbound Calls",
  "cms.report.field.minIVROutCount": "Min Concurrent IVR Outbound Calls",
  "cms.report.field.maxIVROutCount": "Max Concurrent IVR Outbound Calls",
  "cms.report.field.avgIVROutCount": "Avg Concurrent IVR Outbound Calls",
  "cms.report.field.minIVRCount": "Min Concurrent IVR Calls",
  "cms.report.field.maxIVRCount": "Max Concurrent IVR Calls",
  "cms.report.field.avgIVRCount": "Avg Concurrent IVR Calls",
  "cms.report.field.minQueueInCount": "Min Concurrent Skill Queue Inbound Calls",
  "cms.report.field.maxQueueInCount": "Max Concurrent Skill Queue Inbound Calls",
  "cms.report.field.avgQueueInCount": "Avg Concurrent Skill Queue Inbound Calls",
  "cms.report.field.minQueueOutCount": "Min Concurrent Skill Queue Outbound Calls",
  "cms.report.field.maxQueueOutCount": "Max Concurrent Skill Queue Outbound Calls",
  "cms.report.field.avgQueueOutCount": "Avg Concurrent Skill Queue Outbound Calls",
  "cms.report.field.minQueueCount": "Min Concurrent Skill Queue Calls",
  "cms.report.field.maxQueueCount": "Max Concurrent Skill Queue Calls",
  "cms.report.field.avgQueueCount": "Avg Concurrent Skill Queue Calls",
  "cms.report.field.voiceCallType": "Voice Call Type",
  "cms.report.field.oneClickDualCall": "One-click bidirectional call",
  "cms.report.field.voiceNotification": "Voice notification",
  "cms.report.field.intelligentOutCall": "Intelligent outbound call",
  "cms.report.field.reportPeriod": "Statistical Step",
  "cms.report.field.fifteenminutes": "15 minutes",
  "cms.report.field.thirtyminutes": "30 minutes",
  "cms.report.field.onehour": "1 hour",
  "cms.report.message.queryTimeCheck": "The time cannot be earlier than {0}.",
  "cms.report.message.nowTimeCheck": "The time exceeds the current time.",
  "cms.report.message.timeRangeCheck": "The time range cannot exceed 1 day.",
  "cms.report.message.timeRangeCheckSaas": "The time range cannot exceed 5 day.",
  "cms.report.message.monthRangeCheck": "The start time and end time must be in the same month.",
  "cms.report.message.maxGarpMonth": "Data of more than 12 months cannot be queried.",
  "cms.report.field.statInterval": "Time Period",
  "cms.report.field.queryBeginTime": "Start Time",
  "cms.report.field.queryEndTime": "End Time",
  "cms.report.title.selectReportTime": "Set Start Time",
  "cms.report.field.hourandminute": "Time",
  "cms.report.message.timeCheckError": "The time range cannot exceed seven days.",
  "cms.report.message.currentDateCheck": "The selected time must be earlier than the current day.",
  "cms.report.label.header": "Set Table Header",
  "cms.report.title.filteringIndicator": "Filter Indicator",
  "cms.report.field.indicatorName": "Indicator",
  "cms.report.field.description": "Description",
  "cms.report.field.confirm": "OK",
  "cms.report.field.cancel": "Cancel",
  "cms.report.title.saveError": "Error",
  "cms.report.message.errorInfo": "Failed to save the data. Select at least one indicator.",
  "cms.report.message.queryErrorInfo": "Query failed.",
  "cms.report.message.calleeNoLength": "Enter accessCode at least three digits.",
  "cms.report.field.reportType": "Report Type",
  "cms.report.field.byDay": "Daily report",
  "cms.report.field.byWeek": "Weekly report",
  "cms.report.field.byMonth": "Monthly report",
  "cms.report.field.week": "Week",
  "cms.report.field.month": "Month",
  "cms.report.field.year": "Year",
  "cms.report.field.startWeek": "Start Week",
  "cms.report.field.startMonth": "Start Month",
  "cms.report.field.startYear": "Start Year",
  "cms.report.field.endWeek": "End Week",
  "cms.report.field.endMonth": "End Month",
  "cms.report.field.endYear": "End Year",
  "cms.report.field.querySkill": "Skill Queue",
  "cms.report.field.startAgentId": "Start Agent ID",
  "cms.report.field.endAgentId": "End Agent ID",
  "cms.report.field.filetype": "Export File Format",
  "cms.report.field.userLevel": "User Level",
  "cms.report.field.unspecifiedUserLevel": "Unspecified",
  "cms.report.field.unspecifiedSkillId": "Unspecified",
  "cms.report.message.lessThanError": "The end agent ID cannot be less than the start agent ID.",
  "cms.report.message.dategt31days": "The date range cannot exceed 31 days.",
  "cms.report.message.dategt12weeks": "The week range cannot exceed 12 weeks.",
  "cms.report.message.dategt1year": "The month range cannot exceed 12 months.",
  "cms.report.message.weekLessThan": "The end year week cannot be less than the start year week.",
  "cms.report.message.monthLessThan": "The end year month cannot be earlier than the start year month.",
  "cms.report.field.ivrtrafficreport.statdate": "Date",
  "cms.report.field.ivrtrafficreport.ivrinbound": "IVR Occupy Num",
  "cms.report.field.ivrtrafficreport.ivrshortcalls": "IVR Short Calls Num(Within 3s)",
  "cms.report.field.ivrtrafficreport.totalivrservice": "Total IVR Service Time",
  "cms.report.field.ivrtrafficreport.avgivrservice": "Average IVR Inbound Call Duration",
  "cms.fullscreen.message.enterMessage": "Enter",
  "cms.fullscreen.message.selectMessage": "-Select-",
  "cms.fullscreen.field.chartName": "Chart Name",
  "cms.fullscreen.field.indexDimension": "Monitored Object",
  "cms.fullscreen.field.skillQueue": "Skill Queue",
  "cms.fullscreen.field.indexdMode": "Monitoring Mode",
  "cms.fullscreen.field.dayMonitor": "Today's Indicator Statistics",
  "cms.fullscreen.field.nowMonitor": "Real-time Monitoring During Running",
  "cms.fullscreen.field.dayTrend": "Today's Trend",
  "cms.fullscreen.field.monitorType": "Indicator Type",
  "cms.fullscreen.field.refreshPeriod": "Refresh Period",
  "cms.fullscreen.field.statisticPeriod": "Statistical Period",
  "cms.fullscreen.field.chartType": "Chart Type",
  "cms.fullscreen.field.fifteenMin": "15 minutes",
  "cms.fullscreen.field.halfHour": "30 minutes",
  "cms.fullscreen.field.oneHour": "1 hour",
  "cms.fullscreen.field.fiveSec": "5 Seconds",
  "cms.fullscreen.field.oneMin": "1 Minute",
  "cms.fullscreen.field.fiveMin": "5 Minutes",
  "cms.fullscreen.field.bar": "Bar Chart",
  "cms.fullscreen.field.line": "Line Chart",
  "cms.fullscreen.field.pie": "Pie Chart",
  "cms.fullscreen.field.table": "Table",
  "cms.fullscreen.field.richtext": "Rich Text",
  "cms.fullscreen.field.text": "text",
  "cms.fullscreen.field.add": "Create",
  "cms.fullscreen.field.edit": "Modify",
  "cms.fullscreen.field.delete": "Delete",
  "cms.fullscreen.field.movedown": "Down",
  "cms.fullscreen.field.moveup": "Up",
  "cms.fullscreen.field.addCharts": "Create Chart",
  "cms.fullscreen.field.saveTheme": "Save Layout",
  "cms.fullscreen.title.newChart": "Create Chart",
  "cms.fullscreen.title.editChart": "Modify Chart",
  "cms.fullscreen.title.newTheme": "Create Subject",
  "cms.fullscreen.title.editTheme": "Modify Subject",
  "cms.fullscreen.style.dark": "Dark",
  "cms.fullscreen.style.light": "Light",
  "cms.fullscreen.style.title": "Style",
  "cms.fullscreen.message.errorMonitorMethod": "Select a monitoring mode.",
  "cms.fullscreen.title.deleteconfim": "Confirm",
  "cms.fullscreen.message.deleteconfim": "Do you want to confirm the deletion of this topic?",
  "cms.fullscreen.message.deletechart": "Do you want to confirm the deletion of this chart?",
  "cms.fullscreen.field.virtualcallcenter": "VDN",
  "cms.fullscreen.field.screenreSolution": "Resolution",
  "cms.fullscreen.field.custom": "Customization:",
  "cms.fullscreen.message.themeDuplicate": "Duplicate name.",
  "cms.fullscreen.message.selectAgent": "The OU and agent cannot be empty.",
  "cms.fullscreen.message.selectAgentMax": "A maximum of 5 agents can be selected.",
  "cms.fullscreen.field.themeName": "Subject Name",
  "cms.fullscreen.field.preview": "Preview",
  "cms.fullscreen.message.screenreSolution": "The screen resolution cannot be empty.",
  "cms.fullscreen.message.numberFirst": "The maximum value is 7680.",
  "cms.fullscreen.message.numberSecond": "The maximum value is 4320.",
  "cms.fullscreen.message.maxThemeNum": "A maximum of 20 private and 20 public themes can be added.",
  "cms.fullscreen.message.numberFirstMin": "The minimum value is 1280",
  "cms.fullscreen.message.numberSecondMin": "The minimum value is 720",
  "cms.fullscreen.message.chartDataError": "Failed to load the chart.",
  "cms.fullscreen.field.fullscreen": "Full Screen",
  "cms.fullscreen.field.fonts": "Font",
  "cms.fullscreen.field.fontSize": "Size",
  "cms.fullscreen.field.weighted": "Font weight",
  "cms.fullscreen.field.color": "Color",
  "cms.fullscreen.field.normalBold": "Normal",
  "cms.fullscreen.field.bold": "Bold",
  "cms.fullscreen.field.indicatorData": "Indicator Data",
  "cms.fullscreen.field.editRichText": "Modify Rich Text",
  "cms.fullscreen.field.yaHei": "Microsoft YaHei",
  "cms.fullscreen.field.simsun": "SimSun",
  "cms.fullscreen.field.FangSong": "FangSong",
  "cms.fullscreen.field.SimHei": "SimHei",
  "cms.fullscreen.field.play": "Play",
  "cms.fullscreen.field.second": "(s)",
  "cms.fullscreen.field.percentage": "(%)",
  "cms.fullscreen.message.addTheme": "Add a subject.",
  "cms.fullscreen.message.addChart": "Add a chart.",
  "cms.fullscreen.message.chooseTheme": "The layout is not saved. Are you sure you want to change the subject?",
  "cms.fullscreen.message.addThemeError": "The layout is not saved. Are you sure you want to add a subject?",
  "cms.fullscreen.message.saveLayouts": "The layout is not saved. Save it first.",
  "cms.fullscreen.message.addLayout": "Failed to add the canvas because the canvas is full.",
  "cms.fullscreen.message.addVdnMost": "A maximum of 10 tenants can be selected.",
  "cms.fullscreen.message.addVdnLeast": "Select at least one tenant.",
  "cms.fullscreen.message.playTheme": "This subject has been played.",
  "cms.fullscreen.field.report0": "Daily Report (Relative Time)",
  "cms.fullscreen.field.report1": "Daily Report (Fixed Time)",
  "cms.fullscreen.field.reportType": "Report Type",
  "cms.fullscreen.field.reportTime": "Collection Period",
  "cms.fullscreen.field.hisMonitor": "Historical Indicator Statistics",
  "cms.fullscreen.field.reportDate": "1 day ago",
  "cms.fullscreen.field.reportDates": "{0} days ago",
  "cms.fullscreen.field.noanswerCountDesc": "Number of delayed answers by agents after call allocation.",
  "cms.fullscreen.field.totalCallTime": "Total Call Duration",
  "cms.fullscreen.field.totalCallTimeDesc": "Total duration of calls answered by agents.",
  "cms.fullscreen.field.avgCallTime": "Average call duration",
  "cms.fullscreen.field.avgCallTimeDesc": "Average duration of calls answered by agents.",
  "cms.fullscreen.field.acwCountTimeDesc": "Number of times agents are in wrap-up state.",
  "cms.fullscreen.field.auxCountTimeDesc": "Number of times agents are in rest state.",
  "cms.fullscreen.field.busyCountTimeDesc": "Number of times agents are in busy state.",
  "cms.fullscreen.field.idleCountTimeDesc": "Number of times agents are in idle state.",
  "cms.fullscreen.field.holdCountTimeDesc": "Number of times agents are in hold state.",
  "cms.fullscreen.field.avgCallInTime": "Average manual inbound call duration.",
  "cms.voiceinspection.field.callTime": "Recording Duration",
  "cms.report.field.inOccupyNumField": "Service requests",
  "cms.report.field.inCallSuccNumField": "Number of connected manual calls",
  "cms.report.field.callSuccRateField": "Request connection rate",
  "cms.report.field.inCallFailNumField": "Lost call requests",
  "cms.report.field.call3RateField": "Manual inbound call connection rate in 15s",
  "cms.report.field.call4RateField": "Manual inbound call connection rate in 20s",
  "cms.report.field.call6RateField": "Manual inbound call connection rate in 30s",
  "cms.report.field.succWaitAnsRateField": "Average waiting duration",
  "cms.report.field.failQueueWaitRateField": "Average ring duration of lost calls",
  "cms.report.field.inCallRateField": "Average call duration",
  "cms.report.field.waitAnsRateField": "Average ring duration of manual inbound calls",
  "cms.report.field.abortQueueWaitNumField": "Abandoned queuing calls",
  "cms.report.field.noAckNumField": "Unanswered calls",
  "cms.report.field.inWaitAnsNumField": "Manual Incoming Calls",
  "cms.report.field.inAnswerRateField": "Inbound call connection rate",
  "cms.report.field.callAbandonedRingField": "Number of lost manual incoming calls",
  "cms.report.field.inAvgRingTimeField": "Average ring duration of manual inbound calls",
  "cms.report.field.inAvgCallTimeField": "Average call duration",
  "cms.report.field.outWaitAnsNumField": "Manual outbound calls",
  "cms.report.field.outCallSuccNumField": "Connected manual outbound calls",
  "cms.report.field.outAnswerRateField": "Outbound call connection rate",
  "cms.report.field.outAvgRingTimeField": "Average Ring Duration of Manual Outbound Calls",
  "cms.report.field.outAvgCallTimeField": "Average Manual Outbound Call Duration",
  "cms.report.field.acdCallField": "Total connected calls",
  "cms.report.field.avgCallPerHourField": "Average manual inbound calls per hour",
  "cms.report.field.occupancyRateField": "Work duration rate",
  "cms.report.field.utilizationRateField": "Work state duration rate",
  "cms.report.field.workTimeField": "Total work duration",
  "cms.report.field.acdTimeField": "Manual call duration",
  "cms.report.field.acdTimeRateField": "Manual call duration rate",
  "cms.report.field.avgAcdTimeField": "Average manual call duration",
  "cms.report.field.acwTimeField": "Total wrap-up duration",
  "cms.report.field.acwTimeRateField": "Wrap-up duration rate",
  "cms.report.field.avgAcwTimeField": "Average wrap-up duration",
  "cms.report.field.avgHandleTimeField": "Average processing duration",
  "cms.report.field.auxTimeField": "Total rest duration",
  "cms.report.field.auxTimeRateField": "Rest duration rate",
  "cms.report.field.busyTimeField": "Total busy duration",
  "cms.report.field.busyTimeRateField": "Busy duration rate",
  "cms.report.field.ringTimeField": "Total ring duration",
  "cms.report.field.availTimeField": "Idle duration",
  "cms.report.field.availTimeRateField": "Idle Rate",
  "cms.report.field.holdTimeField": "Hold duration",
  "cms.report.field.avgHoldTimeField": "Average hold duration",
  "cms.report.field.outOccupyNumField": "Outbound calls",
  "cms.report.field.outCallRateField": "Outbound call connection rate",
  "cms.report.field.outAboundRingField": "Unanswered outbound calls",
  "cms.report.field.outInvalidCallField": "Invalid outbound calls",
  "cms.report.field.outFailNumField": "Total failed outbound calls",
  "cms.report.field.outCallTimeField": "Total outbound call duration",
  "cms.report.field.outWaitAnsTimeField": "Total ring duration of outbound calls",
  "cms.report.field.outAvgAnsTimeField": "Average ring duration of outbound calls",
  "cms.report.field.acwCountField": "Wrap-up times",
  "cms.report.field.acwOutTimeField": "Outbound call wrap-up duration",
  "cms.report.field.acwOutCountField": "Outbound call wrap-up times",
  "cms.report.field.acwInCountField": "Inbound call wrap-up times",
  "cms.report.field.acwInTimeField": "Inbound call wrap-up duration",
  "cms.report.field.auxCountField": "Rest times",
  "cms.report.field.holdCountField": "Hold times",
  "cms.report.field.muteCountField": "Silence times",
  "cms.report.field.muteTimeField": "Total silence duration",
  "cms.report.field.assistsCountField": "Internal help requests",
  "cms.report.field.assistTimeField": "Internal help request duration",
  "cms.report.field.busyCountField": "Busy times",
  "cms.report.field.playVoiceCountField": "Voice playing times",
  "cms.report.field.playVoiceTimeField": "Voice playing duration",
  "cms.report.field.otherTimeField": "Total duration of other states",
  "cms.report.field.loginCountField": "Sign-in times",
  "cms.report.field.logoutCountField": "Sign-out times",
  "cms.report.field.threePartyCallCountField": "Three-party calls",
  "cms.report.field.internalTransferCountField": "Internal transfers",
  "cms.report.field.transferOutCountField": "Manual transfer-out times",
  "cms.report.field.transferIvrCountField": "Manual-to-IVR transfer times",
  "cms.report.field.internalCallCountField": "Internal calls",
  "cms.report.field.internalCallTimeField": "Internal call duration",
  "cms.report.field.manToSkillNumField": "Manual-to-skill queue transfers",
  "cms.report.field.agentHangNumCtiField": "Agent hang-ups",
  "cms.report.field.custHangNumCtiField": "Customer hang-ups",
  "cms.report.field.otherHangNumCtiField": "System hang-ups",
  "cms.report.field.manToIvrNumCtiField": "Number of times that an agent is suspended to transfer to the IVR",
  "cms.report.field.manTransferOutNumCtiField": "Number of manual transfers to IVR and re-transfers out",
  "cms.report.field.systemInBoundField": "Total system inbound calls",
  "cms.report.field.systemInSuccField": "Successful system inbound calls",
  "cms.report.field.sysSuccRateField": "System inbound call success rate",
  "cms.report.field.sysLostCallsField": "Failed system inbound calls",
  "cms.report.field.IVRInBoundField": "IVR inbound calls",
  "cms.report.field.IVRInSuccField": "Connected IVR inbound calls",
  "cms.report.field.IVRSuccRateField": "IVR inbound call connection rate",
  "cms.report.field.onlyIVRServiceField": "Successful pure-IVR inbound calls",
  "cms.report.field.IVRServiceRateField": "Pure-IVR inbound call success rate",
  "cms.report.field.agentOfferedField": "Manual inbound calls",
  "cms.report.field.agentServiceField": "Agent answered calls",
  "cms.report.field.awarnessRateField": "Manual inbound call success rate",
  "cms.report.field.agentServiceRateField": "Manual success rate in total inbound calls",
  "cms.report.field.avgAnsTimeField": "Average queuing duration of connected calls",
  "cms.report.field.ans20sField": "Manual call connection rate in 20s",
  "cms.report.field.avgCallInTimeField": "Average call duration",
  "cms.report.field.avgInRingTimeField": "Average inbound call duration of terminals",
  "cms.report.field.failAnsRateField": "Request loss rate",
  "cms.report.field.failAvgTimeField": "Average ring duration of lost calls",
  "cms.report.field.maxQueueWaitTimeField": "Max waiting duration",
  "cms.report.field.outSuccAnsRateField": "Outbound call connection rate",
  "cms.report.field.acdCallsField": "Total connected calls",
  "cms.report.field.avgPerHNumField": "Average successful calls per hour",
  "cms.report.field.agentLoginNumField": "Called agents",
  "cms.report.field.perAgentNumField": "Agent calls",
  "cms.report.field.workRateField": "Work duration rate",
  "cms.report.field.onlineRateField": "Online rate",
  "cms.report.field.otherTimeRateField": "Other duration rate",
  "cms.report.field.inCallNumField": "Inbound calls",
  "cms.report.field.inCallSuccNumberField": "Connected inbound calls",
  "cms.report.field.agentInBoundField": "Manual inbound calls",
  "cms.report.field.agentInSuccField": "Connected manual inbound calls",
  "cms.report.field.outCallNumField": "Outbound calls",
  "cms.report.field.outCallSuccNumberField": "Connected outbound calls",
  "cms.report.field.IVROutCallNumField": "Pure-IVR outbound calls",
  "cms.report.field.IVROutSuccNumField": "Connected pure-IVR outbound calls",
  "cms.report.field.agentOutBoundField": "Manual outbound calls",
  "cms.report.field.agentOutSuccField": "Connected manual outbound calls",
  "cms.report.field.inCallSuccRateField": "Inbound call connection rate",
  "cms.report.field.IVRInSuccRateField": "IVR inbound call connection rate",
  "cms.report.field.agentInSuccRateField": "Manual inbound call connection rate",
  "cms.report.field.outCallSuccRateField": "Outbound call connection rate",
  "cms.report.field.IVROutSuccRateField": "Pure-IVR outbound call connection rate",
  "cms.report.field.agentOutSuccRateField": "Manual outbound call connection rate",
  "cms.report.field.inVdnAvgTimeField": "Average inbound call duration in VDN",
  "cms.report.field.outVdnAvgTimeField": "Average outbound call duration in VDN",
  "cms.report.field.IVRCallOutNumField": "IVR outbound calls",
  "cms.report.field.IVRCallOutSuccNumField": "Connected IVR outbound calls",
  "cms.report.field.IVRCallOutSuccRateField": "IVR outbound call connection rate",
  "cms.report.field.userBusyFailNumField": "Lost calls due to busy lines",
  "cms.report.field.userRefusedFailNumField": "Lost calls due to call rejection",
  "cms.report.field.userNoAnswerNumField": "Lost calls due to ringing timeout",
  "cms.report.field.callLostOtherNumField": "Other lost calls",
  "cms.report.field.minAgentInCountField": "Min concurrent agent inbound calls",
  "cms.report.field.maxAgentInCountField": "Max concurrent agent inbound calls",
  "cms.report.field.avgAgentInCountField": "Avg concurrent agent inbound calls",
  "cms.report.field.minAgentOutCountField": "Min concurrent agent outbound calls",
  "cms.report.field.maxAgentOutCountField": "Max concurrent agent outbound calls",
  "cms.report.field.avgAgentOutCountField": "Avg concurrent agent outbound calls",
  "cms.report.field.minAgentCountField": "Min concurrent agent calls",
  "cms.report.field.maxAgentCountField": "Max concurrent agent calls",
  "cms.report.field.avgAgentCountField": "Avg concurrent agent calls",
  "cms.report.field.minIVRInCountField": "Min concurrent IVR inbound calls",
  "cms.report.field.maxIVRInCountField": "Max concurrent IVR inbound calls",
  "cms.report.field.avgIVRInCountField": "Avg concurrent IVR inbound calls",
  "cms.report.field.minIVROutCountField": "Min concurrent IVR outbound calls",
  "cms.report.field.maxIVROutCountField": "Max concurrent IVR outbound calls",
  "cms.report.field.avgIVROutCountField": "Avg concurrent IVR outbound calls",
  "cms.report.field.minIVRCountField": "Min concurrent IVR calls",
  "cms.report.field.maxIVRCountField": "Max concurrent IVR calls",
  "cms.report.field.avgIVRCountField": "Avg concurrent IVR calls",
  "cms.report.field.minQueueInCountField": "Min concurrent skill queue inbound calls",
  "cms.report.field.maxQueueInCountField": "Max concurrent skill queue inbound calls",
  "cms.report.field.avgQueueInCountField": "Avg concurrent skill queue inbound calls",
  "cms.report.field.minQueueOutCountField": "Min concurrent skill queue outbound calls",
  "cms.report.field.maxQueueOutCountField": "Max concurrent skill queue outbound calls",
  "cms.report.field.avgQueueOutCountField": "Avg concurrent skill queue outbound calls",
  "cms.report.field.minQueueCountField": "Min concurrent skill queue calls",
  "cms.report.field.maxQueueCountField": "Max concurrent skill queue calls",
  "cms.report.field.avgQueueCountField": "Avg concurrent skill queue calls",
  "cms.report.message.inOccupyNumDesc": "Number of inbound calls requesting manual services.",
  "cms.report.message.inCallSuccNumDesc": "Agent Connected Incoming Calls.",
  "cms.report.message.callSuccRateDesc": "Percentage of the number of connected manual inbound call requests in the number of manual inbound calls requesting services.",
  "cms.report.message.inCallFailNumDesc": "Number of lost call requests = Number of service requests – Number of connected call requests",
  "cms.report.message.call3RateDesc": "Percentage of the number of call requests manually connected in 15 seconds in the total number of calls requesting manual services.",
  "cms.report.message.call4RateDesc": "Percentage of the number of call requests manually connected in 20 seconds in the total number of calls requesting manual services.",
  "cms.report.message.call6RateDesc": "Percentage of the number of call requests manually connected in 30 seconds in the total number of calls requesting manual services.",
  "cms.report.message.succWaitAnsRateDesc": "Average waiting duration of all connected calls in a queue.",
  "cms.report.message.failQueueWaitRateDesc": "Average ring duration of all unanswered calls.",
  "cms.report.message.inCallRateDesc": "Average call duration. Average duration of connected manual inbound calls = Duration of connected manual inbound calls/Number of connected manual inbound calls.",
  "cms.report.message.waitAnsRateDesc": "Average ringing duration of an agent's terminal. Average ring duration of manual inbound calls = Total ring duration of manual inbound calls/Number of answering times.",
  "cms.report.message.abortQueueWaitNumDesc": "Number of calls proactively abandoned by subscribers during queuing and ringing.",
  "cms.report.message.noAckNumDesc": "Number of unanswered calls after being allocated to agents.",
  "cms.report.message.inWaitAnsNumDesc": "Number of incoming call requests for manual service.",
  "cms.report.message.inAnswerRateDesc": "Percentage of the number of connected manual incoming calls to the number of manual incoming calls.",
  "cms.report.message.callAbandonedRingDesc": "Number of lost manual incoming calls = Number of manual incoming calls – Number of connected manual incoming calls",
  "cms.report.message.inAvgRingTimeDesc": "Average ringing duration of an agent's terminal. Average ring duration of manual inbound calls = Total ring duration of manual inbound calls/Number of answering times.",
  "cms.report.message.inAvgCallTimeDesc": "Average call duration. Average duration of connected manual inbound calls = Duration of connected manual inbound calls/Number of connected manual inbound calls.",
  "cms.report.message.outWaitAnsNumDesc": "Number of manual outbound calls, including the outbound calls initiated by agents and those transferred from the IVR to manual services.",
  "cms.report.message.outCallSuccNumDesc": "Number of connected manual outbound calls.",
  "cms.report.message.outAnswerRateDesc": "Percentage of the number of connected outbound calls in the number of outbound calls.",
  "cms.report.message.outAvgRingTimeDesc": "Average time for an agent to wait for customers to answer outbound calls.",
  "cms.report.message.outAvgCallTimeDesc": "Average outbound call duration between an agent and customers.",
  "cms.report.message.acdCallDesc": "Number of connected inbound and outbound calls.",
  "cms.report.message.avgCallPerHourDesc": "Average number of calls answered by an agent per hour in the selected working time segment.",
  "cms.report.message.occupancyRateDesc": "Work duration rate = (Total work duration – Rest duration)/Total work duration x 100",
  "cms.report.message.utilizationRateDesc": "Work state duration rate = (Total call duration + Total wrap-up duration)/(Total work duration – Rest duration) x 100",
  "cms.report.message.overNumReason": "Multimedia agents and all-round agents can process multi-channel calls at the same time.",
  "cms.report.message.workTimeDesc": "Total duration from the time when an agent signs in to the time when the agent signs out.",
  "cms.report.message.acdTimeDesc": "Total call duration of an agent, excluding the text chat duration.",
  "cms.report.message.acdTimeRateDesc": "Manual call duration rate = Manual call duration/Total work duration x 100",
  "cms.report.message.avgAcdTimeDesc": "Average call duration of an agent. Average manual call duration = Manual call duration/Number of successful manual calls",
  "cms.report.message.acwTimeDesc": "Total duration when an agent is in wrap-up state.",
  "cms.report.message.acwTimeRateDesc": "Wrap-up duration rate = (Total wrap-up duration/Total work duration) x 100",
  "cms.report.message.avgAcwTimeDesc": "Average duration when an agent is in wrap-up state. Average wrap-up duration = Total wrap-up duration/Number of wrap-up times",
  "cms.report.message.avgHandleTimeDesc": "Average processing duration = (Manual call duration + Total wrap-up duration)/Number of successful manual calls",
  "cms.report.message.auxTimeDesc": "Total leave duration of an agent or duration when the agent is in rest state.",
  "cms.report.message.auxTimeRateDesc": "Rest duration rate = (Total rest duration/Total work duration) x 100",
  "cms.report.message.busyTimeDesc": "Total duration when an agent is in busy state.",
  "cms.report.message.busyTimeRateDesc": "Busy duration rate = (Total busy duration/Total work duration) x 100",
  "cms.report.message.ringTimeDesc": "Total ringing duration of an agent, excluding the call duration.",
  "cms.report.message.availTimeDesc": "Total duration when an agent is in idle state.",
  "cms.report.message.availTimeRateDesc": "Percentage of an agent's total idle duration in the total work duration.",
  "cms.report.message.holdTimeDesc": "Total duration when an agent is in call hold state. The call duration of an agent includes the call hold time.",
  "cms.report.message.avgHoldTimeDesc": "Average call hold duration. Average hold duration = Hold duration/Number of hold times",
  "cms.report.message.outOccupyNumDesc": "Number of calls made by an agent to customers.",
  "cms.report.message.outCallRateDesc": "Percentage of the number of connected outbound calls in the number of outbound calls.",
  "cms.report.message.outAboundRingDesc": "Number of outbound calls that are rejected by customers or are not answered after ringing.",
  "cms.report.message.outInvalidCallDesc": "Number of outbound calls that cannot reach customers due to invalid numbers or power-off.",
  "cms.report.message.outFailNumDesc": "Total number of failed outbound calls.",
  "cms.report.message.outCallTimeDesc": "Total outbound call duration between an agent and customers.",
  "cms.report.message.outWaitAnsTimeDesc": "Total ring duration of outbound calls.",
  "cms.report.message.outAvgAnsTimeDesc": "Average ring duration of outbound calls = Total ring duration of outbound calls/Number of answering times",
  "cms.report.message.acwCountDesc": "Number of times that an agent enters the wrap-up state.",
  "cms.report.message.acwOutTimeDesc": "Wrap-up duration after outbound calls end.",
  "cms.report.message.acwOutCountDesc": "Number of times that an agent enters the wrap-up state after outbound calls end.",
  "cms.report.message.acwInCountDesc": "Number of times that an agent enters the wrap-up state after inbound calls end.",
  "cms.report.message.acwInTimeDesc": "Wrap-up duration after inbound calls end.",
  "cms.report.message.auxCountDesc": "Number of times that an agent enters the rest state.",
  "cms.report.message.holdCountDesc": "Number of hold times.",
  "cms.report.message.muteCountDesc": "Number of silence times.",
  "cms.report.message.muteTimeDesc": "Silence duration.",
  "cms.report.message.assistsCountDesc": "Number of internal help requests.",
  "cms.report.message.assistTimeDesc": "Duration of internal help requests.",
  "cms.report.message.busyCountDesc": "Number of times that an agent enters the busy state.",
  "cms.report.message.playVoiceCountDesc": "Number of voice playing times.",
  "cms.report.message.playVoiceTimeDesc": "Voice playing duration.",
  "cms.report.message.otherTimeDesc": "Total duration when an agent is in another state, or call duration of other skills when multiple skills exist.",
  "cms.report.message.loginCountDesc": "Number of sign-in times.",
  "cms.report.message.logoutCountDesc": "Number of sign-out times.",
  "cms.report.message.threePartyCallCountDesc": "Number of three-party calls.",
  "cms.report.message.internalTransferCountDesc": "Number of internal transfer times.",
  "cms.report.message.transferOutCountDesc": "Number of manual transfer-out times.",
  "cms.report.message.transferIvrCountDesc": "Number of manual-to-IVR transfer times.",
  "cms.report.message.internalCallCountDesc": "Number of internal calls.",
  "cms.report.message.internalCallTimeDesc": "Internal call duration.",
  "cms.report.message.manToSkillNumDesc": "Number of manual-to-skill queue transfers.",
  "cms.report.message.agentHangNumCtiDesc": "Number of agent hang-ups.",
  "cms.report.message.custHangNumCtiDesc": "Number of customer hang-ups.",
  "cms.report.message.otherHangNumCtiDesc": "Number of system hang-ups.",
  "cms.report.message.manToIvrNumCtiDesc": "Number of times that the agent suspends the call and transfers the call to the IVR after the call is connected.",
  "cms.report.message.manTransferOutNumCtiDesc": "Number of times that the agent transfers to the IVR and then transfers out.",
  "cms.report.message.systemInBoundDesc": "Total number of system inbound calls (excluding internal inbound calls).",
  "cms.report.message.systemInSuccDesc": "Number of successful system inbound calls, including the calls during IVR voice playing, queuing, ringing at agent terminals, and agent answering (excluding internal inbound calls).",
  "cms.report.message.sysSuccRateDesc": "Percentage of the number of successful system incoming calls in the total number of system inbound calls.",
  "cms.report.message.sysLostCallsDesc": "Number of failed system inbound calls = Total number of system inbound calls – Number of successful system inbound calls.",
  "cms.report.message.IVRInBoundDesc": "Total number of inbound calls made using the IVR.",
  "cms.report.message.IVRInSuccDesc": "Number of IVR inbound calls whose call duration is greater than 0.",
  "cms.report.message.IVRSuccRateDesc": "Percentage of the number of connected IVR inbound calls in the number of IVR inbound call requests.",
  "cms.report.message.onlyIVRServiceDesc": "Number of successful inbound calls processed only by the IVR, that is, number of successful inbound calls that are not processed by any agent.",
  "cms.report.message.IVRServiceRateDesc": "Percentage of the number of calls processed only by the IVR in the total number of inbound calls. Pure-IVR inbound call success rate = Successful pure-IVR inbound calls/(Successful pure-IVR inbound calls + Number of agent answered calls) x 100.",
  "cms.report.message.agentOfferedDesc": "Total number of inbound calls requesting agent services.",
  "cms.report.message.agentServiceDesc": "Number of calls successfully answered by agents.",
  "cms.report.message.awarnessRateDesc": "Percentage of the number of agent answered calls in the number of manual inbound calls.",
  "cms.report.message.agentServiceRateDesc": "Percentage of the number of agent answered calls in the total number of inbound calls. Manual success rate in total inbound calls = Number of agent answered calls/(Number of successful IVR inbound calls + Number of agent answered calls) x 100",
  "cms.report.message.avgAnsTimeDesc": "Average queueing duration of all connected calls in a queue.",
  "cms.report.message.ans20sDesc": "Percentage of the number of calls manually connected in 20s in the total number of manually connected calls.",
  "cms.report.message.avgCallInTimeDesc": "Average call duration. Average duration of connected manual inbound calls = Duration of connected manual inbound calls/Number of connected manual inbound calls.",
  "cms.report.message.avgInRingTimeDesc": "Average inbound call duration through agent terminals.",
  "cms.report.message.failAnsRateDesc": "Request loss rate = 100 – Call connection rate",
  "cms.report.message.failAvgTimeDesc": "Average ring duration of all unanswered calls.",
  "cms.report.message.maxQueueWaitTimeDesc": "Maximum waiting duration (queuing and ring duration) of all calls.",
  "cms.report.message.outSuccAnsRateDesc": "Percentage of the number of connected outbound calls in the number of outbound calls.",
  "cms.report.message.acdCallsDesc": "Number of connected inbound and outbound calls.",
  "cms.report.message.avgPerHNumDesc": "Average number of successful calls per hour in the selected working time segment.",
  "cms.report.message.agentLoginNumDesc": "Number of agents who receive inbound calls.",
  "cms.report.message.perAgentNumDesc": "The number of incoming calls assigned to each agent during the selected time period.",
  "cms.report.message.workRateDesc": "Work duration rate = (Total work duration – Rest duration)/Total work duration x 100",
  "cms.report.message.onlineRateDesc": "Online rate = (Manual call duration + Total wrap-up duration + Total duration of other states)/(Total work duration – Rest duration) x 100",
  "cms.report.message.otherTimeRateDesc": "Other duration rate = (Total duration of other states/Total work duration) x 100",
  "cms.report.message.inCallNumDesc": "Total number of inbound calls in the VDN. The value is the sum of manual inbound calls and inbound calls automatically made using the IVR.",
  "cms.report.message.inCallSuccNumberDesc": "Number of connected inbound calls. The value is the sum of connected manual inbound calls and inbound calls automatically connected using the IVR.",
  "cms.report.message.agentInBoundDesc": "Number of inbound calls assigned to skill queues or agents.",
  "cms.report.message.agentInSuccDesc": "Number of manual inbound calls connected to agents, which is the number of manual inbound calls whose call duration is greater than 0.",
  "cms.report.message.outCallNumDesc": "Number of manual and automatic outbound calls in the VDN. The value is the sum of manual outbound calls and outbound calls automatically made using the IVR.",
  "cms.report.message.outCallSuccNumberDesc": "Number of automatic and manual outbound calls that are answered by called parties. The value is the sum of connected manual outbound calls and outbound calls automatically connected using the IVR.",
  "cms.report.message.IVROutCallNumDesc": "Number of outbound calls made only by the IVR.",
  "cms.report.message.IVROutSuccNumDesc": "Number of connected IVR outbound calls whose IVR device occupation duration is greater than 0.",
  "cms.report.message.agentOutBoundDesc": "Number of manual outbound calls made by agents.",
  "cms.report.message.agentOutSuccDesc": "Number of manual outbound calls connected to users, which is the number of manual outbound calls whose call duration is greater than 0.",
  "cms.report.message.inCallSuccRateDesc": "Percentage of connected inbound calls to total inbound calls.",
  "cms.report.message.IVRInSuccRateDesc": "Percentage of connected IVR inbound calls to total IVR inbound calls.",
  "cms.report.message.agentInSuccRateDesc": "Percentage of connected manual inbound calls to total manual inbound calls.",
  "cms.report.message.outCallSuccRateDesc": "Percentage of connected outbound calls to total outbound calls.",
  "cms.report.message.IVROutSuccRateDesc": "Percentage of connected pure-IVR outbound calls to total Pure-IVR outbound calls.",
  "cms.report.message.agentOutSuccRateDesc": "Percentage of connected manual outbound calls to total manual outbound calls.",
  "cms.report.message.inVdnAvgTimeDesc": "Average inbound call duration in the VDN. The value is the total inbound call duration divided by the number of inbound calls.",
  "cms.report.message.outVdnAvgTimeDesc": "Average outbound call duration in the VDN. The value is the total outbound call duration divided by the number of outbound calls.",
  "cms.report.message.IVRCallOutNumDesc": "Total number of IVR outbound calls.",
  "cms.report.message.IVRCallOutSuccNumDesc": "Number of IVR outbound calls whose call duration is greater than 0.",
  "cms.report.message.IVRCallOutSuccRateDesc": "Percentage of connected IVR outbound calls to total IVR outbound call requests.",
  "cms.report.message.userBusyFailNumDesc": "Number of calls that are not answered due to busy lines.",
  "cms.report.message.userRefusedFailNumDesc": "Number of calls that are not answered due to call rejection.",
  "cms.report.message.userNoAnswerNumDesc": "Number of calls that are not answered due to ringing timeout.",
  "cms.report.message.callLostOtherNumDesc": "Number of calls that are not answered due to reasons other than busy lines, call rejection, and ringing timeout.",
  "cms.report.message.minAgentInCountDesc": "Minimum number of concurrent inbound calls whose service device type is agent.",
  "cms.report.message.maxAgentInCountDesc": "Maximum number of concurrent inbound calls whose service device type is agent.",
  "cms.report.message.avgAgentInCountDesc": "Average number of concurrent inbound calls whose service device type is agent.",
  "cms.report.message.minAgentOutCountDesc": "Minimum number of concurrent outbound calls whose service device type is agent.",
  "cms.report.message.maxAgentOutCountDesc": "Maximum number of concurrent outbound calls whose service device type is agent.",
  "cms.report.message.avgAgentOutCountDesc": "Average number of concurrent outbound calls whose service device type is agent.",
  "cms.report.message.minAgentCountDesc": "Minimum number of concurrent calls whose service device type is agent.",
  "cms.report.message.maxAgentCountDesc": "Maximum number of concurrent calls whose service device type is agent.",
  "cms.report.message.avgAgentCountDesc": "Average number of concurrent calls whose service device type is agent.",
  "cms.report.message.minIVRInCountDesc": "Minimum number of concurrent inbound calls whose service device type is IVR.",
  "cms.report.message.maxIVRInCountDesc": "Maximum number of concurrent inbound calls whose service device type is IVR.",
  "cms.report.message.avgIVRInCountDesc": "Average number of concurrent inbound calls whose service device type is IVR.",
  "cms.report.message.minIVROutCountDesc": "Minimum number of concurrent outbound calls whose service device type is IVR.",
  "cms.report.message.maxIVROutCountDesc": "Maximum number of concurrent outbound calls whose service device type is IVR.",
  "cms.report.message.avgIVROutCountDesc": "Average number of concurrent outbound calls whose service device type is IVR.",
  "cms.report.message.minIVRCountDesc": "Minimum number of concurrent calls whose service device type is IVR.",
  "cms.report.message.maxIVRCountDesc": "Maximum number of concurrent calls whose service device type is IVR.",
  "cms.report.message.avgIVRCountDesc": "Average number of concurrent calls whose service device type is IVR.",
  "cms.report.message.minQueueInCountDesc": "Minimum number of concurrent inbound calls whose service device type is skill queue.",
  "cms.report.message.maxQueueInCountDesc": "Maximum number of concurrent inbound calls whose service device type is skill queue.",
  "cms.report.message.avgQueueInCountDesc": "Average number of concurrent inbound calls whose service device type is skill queue.",
  "cms.report.message.minQueueOutCountDesc": "Minimum number of concurrent outbound calls whose service device type is skill queue.",
  "cms.report.message.maxQueueOutCountDesc": "Maximum number of concurrent outbound calls whose service device type is skill queue.",
  "cms.report.message.avgQueueOutCountDesc": "Average number of concurrent outbound calls whose service device type is skill queue.",
  "cms.report.message.minQueueCountDesc": "Minimum number of concurrent calls whose service device type is skill queue.",
  "cms.report.message.maxQueueCountDesc": "Maximum number of concurrent calls whose service device type is skill queue.",
  "cms.report.message.avgQueueCountDesc": "Average number of concurrent calls whose service device type is skill queue.",
  "cms.report.field.acwRate": "Wrap-Up Duration Rate",
  "cms.report.field.IVRShortCallsField": "IVR Short Calls Num(Within 3s)",
  "cms.report.field.totalLIVRServiceField": "Total IVR Service Time",
  "cms.report.field.avgIVRServiceField": "Average IVR Inbound Call Duration",
  "cms.report.message.IVRShortCallsDesc": "Number of calls whose IVR duration is greater than 0 and less than or equal to 3 seconds.",
  "cms.report.message.totalLIVRServiceDesc": "Total IVR call duration. If a call is connected to the IVR for multiple times, the IVR service duration is the total IVR call duration.",
  "cms.report.message.avgIVRServiceDesc": "Average IVR inbound call duration = IVR inbound call duration/Number of connected IVR inbound calls.",
  "cms.report.label.export": "Export",
  "cms.indexmonitor.field.accesscodeCalloutNum": "Number of outgoing calls",
  "cms.indexmonitor.message.accesscodeCalloutNum": "Number of outgoing calls made by calling numbers within one hour",
  "cms.report.message.exportSucceed": "Export successful.",
  "cms.report.message.exportFailed": "Export failed.",
  "cms.report.message.nomorethanerror": "The selected date cannot be earlier than {0}.",
  "cms.report.field.callAbandonedInRingField": "Calls Abandoned in Ring",
  "cms.synchronize.message.notConfigured": "Unconfigured",
  "cms.synchronize.message.configured": "Configured",
  "cms.synchronize.message.incorrectPassword": "Incorrect",
  "cms.synchronize.message.invalidAdminPwd": "Incorrect password. Change the administrator password.",
  "cms.synchronize.message.noConfAdminPwd": "The password is not configured. Configure the administrator password.",
  "cms.synchronize.message.invalidConfPwd": "Incorrect password. Change the configuration interface password.",
  "cms.synchronize.message.noConfConfPwd": "The password is not configured. Configure the interface password.",
  "cms.synchronize.message.adminPwdStatus": "CTI Administrator Password Status",
  "cms.synchronize.message.confPwdStatus": "CCBCS Interface Password Status",
  "cms.synchronize.message.syncStatus": "Synchronization Status",
  "cms.synchronize.message.syncStatusZero": "To be synchronized",
  "cms.synchronize.message.syncStatusOne": "Synchronizing",
  "cms.synchronize.message.syncStatusTwo": "Synchronization succeeded.",
  "cms.synchronize.message.syncStatusThree": "Synchronization failed.",
  "cms.report.message.noData": "No data available.",
  "cms.report.field.date": "Date",
  "cms.report.field.accessNo": "Access code:",
  "cms.report.field.skillQueue": "Skill queue:",
  "cms.report.field.agentNum": "Agent ID",
  "cms.indexmonitor.message.warningFilterMax": "A maximum of {0} indicators can be selected.",
  "cms.report.title.callCenterMonitor": "VDN Performance Monitoring",
  "cms.report.title.callCenterTrend": "VDN Performance Trend",
  "cms.report.title.callCenterAccessMonitor": "VDN Performance Monitoring by Access Code",
  "cms.report.title.callCenterAccessTrend": "VDN Performance Trend by Access Code",
  "cms.report.title.IVRMonitor": "IVR Data Monitoring",
  "cms.report.title.IVRTrend": "IVR Data Trend",
  "cms.report.title.IVRAccessMonitor": "Monitoring Statistics on IVR Data by Access Code",
  "cms.report.title.IVRAccessTrend": "Trend of IVR Data by Access Code",
  "cms.report.title.skillMonitor": "Skill Queue Performance Monitoring",
  "cms.report.title.skillTrend": "Skill Queue Performance Trend",
  "cms.report.title.skillAccessMonitor": "Monitoring Statistics on Skill Queue Performance by Access Code",
  "cms.report.title.skillAccessTrend": "Trend of Skill Queue Performance by Access Code",
  "cms.report.title.skillAllMonitor": "Skill Queue Summary Monitoring",
  "cms.report.title.skillAllTrend": "Skill Queue Summary Trend",
  "cms.report.title.agentMonitor": "Agent Performance Summary Monitoring",
  "cms.report.title.agentTrend": "Agent Performance Summary Trend",
  "cms.report.title.agentOutCallMonitor": "Agent Outbound Call Summary Monitoring",
  "cms.report.title.agentOutCallTrend": "Agent Outbound Call Summary Trend",
  "cms.report.title.agentOperationMonitor": "Agent Connection Operation Details Monitoring",
  "cms.report.title.agentOperationTrend": "Agent Connection Operation Details Trend",
  "cms.report.title.abandonRingMonitor": "Monitoring Summary of Abandoned Calls during Ringing",
  "cms.report.title.abandonRingTrend": "Trend of Abandoned Calls during Ringing",
  "cms.report.title.vdnTrafficPeriodMonitor": "VDN Traffic Period Monitoring",
  "cms.report.title.vdnTrafficPeriodTrend": "VDN Traffic Period Trend",
  "cms.report.title.ivrOutboundMonitor": "IVR Outbound Call Monitoring",
  "cms.report.title.ivrOutboundTrend": "IVR Outbound Call Trend",
  "cms.report.title.vdnSystemPerformanceMonitor": "System Performance Monitoring",
  "cms.report.title.vdnSystemPerformanceTrend": "System Performance Trend",
  "cms.report.title.changeTable": "Switch To Table",
  "cms.report.title.changeChart": "Switch To Chart",
  "cms.callcenterinstanceslist.message.updateCtiVersion": "Check whether the CTI data migration is complete. Exercise caution when performing this operation.",
  "cms.callcenterinstanceslist.message.updateCtiVersion2": "Check whether the CTI data migration is complete. Exercise caution when performing this operation. (After you confirm the operation, the automatic synchronization function is disabled.)",
  "cms.callcenterinstanceslist.message.jobSwitch": "Enable automatic synchronization",

  "cms.reportsubscribe.field.subscriptionList": "Subscription List",
  "cms.reportsubscribe.field.subscribeContent": "Subscribe Content",
  "cms.reportsubscribe.field.subscribeName": "Subscribe Name",
  "cms.reportsubscribe.field.templateName": "Email Template Name",
  "cms.reportsubscribe.field.sentTime": "Sent Time",
  "cms.reportsubscribe.field.sentPeriod": "Sent Period ",
  "cms.reportsubscribe.field.reportName": "Report Name",
  "cms.reportsubscribe.field.status": "Status",
  "cms.reportsubscribe.field.publish": "Publish",
  "cms.reportsubscribe.field.prohibit": "Prohibit",
  "cms.reportsubscribe.field.sentReport": "Sent Report",
  "cms.reportsubscribe.field.selectedReport": "Selected Report List",
  "cms.reportsubscribe.field.basicInfo": "Basic Info",
  "cms.reportsubscribe.field.enable": "Published",
  "cms.reportsubscribe.field.suspended": "Unpublished",
  "cms.reportsubscribe.field.monday": "Monday",
  "cms.reportsubscribe.field.tuesday": "Tuesday ",
  "cms.reportsubscribe.field.wednesday": "Wednesday",
  "cms.reportsubscribe.field.thursday": "Thursday",
  "cms.reportsubscribe.field.friday": "Friday",
  "cms.reportsubscribe.field.saturday": "Saturday",
  "cms.reportsubscribe.field.sunday": "Sunday",
  "cms.reportsubscribe.title.modifyConfim": "Modify confirm",
  "cms.reportsubscribe.title.tips": "Information",
  "cms.reportsubscribe.message.searchSubscribeName": "Please enter the subscribe name",
  "cms.reportsubscribe.message.searchTemplateName": "Please enter the template name",
  "cms.reportsubscribe.message.deleteConfim": "Are you sure to delete?",
  "cms.reportsubscribe.message.modifyStatus": "Are you sure to modify the status?",
  "cms.reportsubscribe.message.reportNumberError": "All reports have been added",
  "cms.report.title.callCenterReport": "VDN Traffic Report",
  "cms.report.title.callCenterAccessReport": "VDN Traffic Report by AccessCode",
  "cms.report.title.IVRReport": "IVR Traffic Report",
  "cms.report.title.IVRAccessReport": "IVR Traffic Report by Access Code",
  "cms.report.title.skillReport": "Skill Queue Traffic Report",
  "cms.report.title.skillAccessReport": "Skill Queue Traffic Report by AccessCode",
  "cms.report.title.skillAllReport": "Traffic Summary Report",
  "cms.report.title.agentReport": "Agent Traffic Report",
  "cms.report.title.agentOutCallReport": "Agent Outbound Call Report",
  "cms.report.title.agentOperationReport": "Agent Operation Report",
  "cms.report.title.abandonRingReport": "Abandoned Call In Ringing Report",
  "cms.report.title.vdnTrafficPeriodReport": "VDN Traffic Period Report",
  "cms.report.title.ivrOutboundReport": "IVR Outbound Call Report",
  "cms.report.title.vdnSystemPerformanceReport": "System Performance Monitoring Report",
  "cms.report.message.invalidParam": "Invalid param.",
  "cms.report.message.tooManyExport": "Too many {0} export in a short time.",
  "cms.report.message.queryFailed": "Query {0} failed.",
  "cms.report.message.dataOverError": "Query {0} data size over 10000.",
  "cms.report.message.validateFailed": "Failed to verify the report export.",
  "cms.report.message.callAbandonedInRingDesc": "Number of calls abandoned by the caller while ringing without being answered.",
  "cms.report.field.succAnsRateField": "Request connection rate",
  "cms.report.message.succAnsRateDesc": "Percentage of the number of connected manual inbound call requests in the number of manual inbound calls requesting services.",
  "cms.calllink.field.skillQueue": "Skill queue",
  "cms.calllink.field.businessRepresentative": "Agent",
  "cms.calllink.field.ivrServiceProcess": "IVR business flow",
  "cms.calllink.field.call": "Call",
  "cms.calllink.field.routingTemporaryDevice": "Temporary routing device",
  "cms.calllink.field.virtualDevice": "Virtual device",
  "cms.calllink.field.cdnDevice": "CDN device",
  "cms.calllink.field.MELCASDevice": "MELCAS device",
  "cms.calllink.field.PGR": "PGR (default CDN route controlled by the SCAI)",
  "cms.calllink.field.teleconference": "Conference call",
  "cms.calllink.field.commonAccessTransfer": "Common access or transfer.",
  "cms.calllink.field.overflowing": "A call is transferred due to overflow.",
  "cms.calllink.field.timeoutTransfer": "A call is transferred due to timeout.",
  "cms.calllink.field.noOnDutyTransfer": "A call is transferred because no one is on duty.",
  "cms.calllink.field.cancelQueuingTransfer": "A call is transferred when queuing is cancelled.",
  "cms.calllink.field.noAnswerTransfer": "A call is transferred because no reply is received from an agent for a long time.",
  "cms.calllink.field.suspendedAndResumed": "A suspended call resumes.",
  "cms.calllink.field.connectionHold": "A held call is connected.",
  "cms.calllink.field.threePartyHelp": "The call is used to request three-party help.",
  "cms.calllink.field.unhold": "A call is unhold.",
  "cms.calllink.field.threePartyCall": "The call is a three-party call.",
  "cms.calllink.field.helperRelease": "A call is disconnected by the party who sends the internal help request.",
  "cms.calllink.field.intercepts": "A call is intercepted.",
  "cms.calllink.field.pickup": "A call is picked up by others.",
  "cms.calllink.field.analysisNotConfig": "Called number analysis is not configured for a call.",
  "cms.calllink.field.activeRelease": "The subscriber disconnects a call.",
  "cms.calllink.field.agentRelease": "The agent disconnects a call.",
  "cms.calllink.field.transferOut": "A call is transferred out.",
  "cms.calllink.field.hold": "A call is held.",
  "cms.calllink.field.internalError": "Internal error.",
  "cms.calllink.field.calledBusy": "The called party is busy.",
  "cms.calllink.field.powerOff": "The phone is powered off.",
  "cms.calllink.field.outOfService": "The phone is out of service.",
  "cms.calllink.field.unavailableNumber": "The number is unallocated.",
  "cms.calllink.field.noResources": "No resource available on the ACD.",
  "cms.calllink.field.doNotAnswer": "The subscriber does not answer a call.",
  "cms.calllink.field.callRejected": "The called party rejects a call.",
  "cms.calllink.field.leaveReason28": "Successfully transferred to the queue.",
  "cms.calllink.field.leaveReason29": "Third-party transfer to queue.",
  "cms.calllink.field.leaveReason30": "Successful transfer to queue times out.",
  "cms.calllink.field.leaveReason31": "Third-party forwarding to queue times out.",
  "cms.calllink.field.leaveReason34": "Timeout/Overflow/Network call failure queuing on busy.",
  "cms.calllink.field.leaveReason35": "The network call is returned to the original call center and queued.",
  "cms.calllink.field.leaveReason36": "Queued call rerouting.",
  "cms.calllink.field.leaveReason37": "The call is routed to the CDN.",
  "cms.calllink.field.leaveReason38": "Log in to the CDN through agent consultation.",
  "cms.calllink.field.leaveReason39": "The CDN returns the routing result.",
  "cms.calllink.field.leaveReason40": "CDN routing timeout.",
  "cms.calllink.field.leaveReason41": "Agent cancellation or routing failure during consultation.",
  "cms.calllink.field.leaveReason42": "The CDN route times out and enters the PGR.",
  "cms.calllink.field.leaveReason43": "The CDN returns a normal route to the PGR.",
  "cms.calllink.field.leaveReason44": "Route incoming calls to PGR.",
  "cms.calllink.field.leaveReason45": "Entering PGR from agent consultation.",
  "cms.calllink.field.leaveReason46": "Agent unreachable, CDN rerouting",
  "cms.calllink.field.leaveReason47": "Call queued in multiple queues.",
  "cms.calllink.field.leaveReason48": "Answering a call in the primary queue in the secondary queue.",
  "cms.calllink.field.leaveReason50": "Agent rejects a call",
  "cms.calllink.field.leaveReason51": "A user calls to enter a conference call.",
  "cms.calllink.field.leaveReason52": "The chairperson proactively releases the conference, or the conference is released due to a CCS exception.",
  "cms.calllink.field.leaveReason53": "The participant is removed from the conference.",
  "cms.calllink.field.leaveReason54": "Call completion rate is lower than the threshold.",
  "cms.calllink.field.leaveReason88": "The user hangs up after the agent connects to the user.",
  "cms.calllink.field.leaveReason89": "After an agent invokes the answer interface, the agent does not connect to the user and the user hangs up.",
  "cms.mediaType1": "Text chat",
  "cms.mediaType2": "Click-to-dial",
  "cms.mediaType3": "Escorted browsing and form sharing",
  "cms.mediaType4": "Callback request call",
  "cms.mediaType5": "Common voice call",
  "cms.mediaType6": "Email call",
  "cms.mediaType7": "Fax call",
  "cms.mediaType8": "IP video call (H.323)",
  "cms.mediaType9": "Electronic whiteboard",
  "cms.mediaType10": "Application sharing",
  "cms.mediaType11": "File transfer",
  "cms.mediaType12": "2B+D ISDN video call",
  "cms.mediaType13": "6B+D ISDN video call",
  "cms.mediaType14": "OPS call",
  "cms.mediaType15": "Predicted outbound call",
  "cms.mediaType16": "Previewed outbound call",
  "cms.mediaType17": "Message media",
  "cms.mediaType18": "Click-to-dial video call",
  "cms.mediaType19": "Common video call",
  "cms.mediaType20": "Non-real-time call",
  "cms.mediaType53": "Multimedia",
  "cms.calllink.field.callType33": "Common inbound call",
  "cms.calllink.field.callType32": "Inbound toll call",
  "cms.calllink.field.callType31": "Internal call",
  "cms.calllink.field.callType30": "Common outbound call",
  "cms.calllink.field.callType29": "Manual outbound call",
  "cms.calllink.field.callType28": "Agent outbound call type",
  "cms.calllink.field.callType27": "PRI outbound call",
  "cms.calllink.field.callType26": "IVR outbound call",
  "cms.calllink.field.callType0": "Outbound call of a phone agent",
  "cms.calllink.field.callType1": "IVR PRI outbound call",
  "cms.calllink.field.callType2": "Inbound call of the network call center",
  "cms.calllink.field.callType3": "Outbound call of the network call center",
  "cms.calllink.field.callType4": "Inbound inspection call of the network call center",
  "cms.calllink.field.callType5": "Internal inbound network call to a virtual agent",
  "cms.calllink.field.callType6": "Internal outbound network call to a virtual agent",
  "cms.calllink.field.callType7": "Auxiliary call",
  "cms.calllink.field.callType8": "Inbound call (international agent)",
  "cms.calllink.field.callType9": "Relayed call",
  "cms.calllink.field.callType10": "Outbound call (international agent)",
  "cms.calllink.field.callType11": "Reserved outbound call",
  "cms.calllink.field.callType12": "Preoccupied outbound call",
  "cms.calllink.field.callType13": "Pre-connected outbound call",
  "cms.calllink.field.callType14": "Predicted outbound call",
  "cms.calllink.field.callType15": "Previewed outbound call",
  "cms.calllink.field.callType16": "Callback request",
  "cms.calllink.field.callType17": "International inbound toll call",
  "cms.calllink.field.callType18": "RBT business call",
  "cms.calllink.field.callType19": "Two-party help call",
  "cms.calllink.field.callType20": "Three-party help call",
  "cms.calllink.field.callType21": "Preoccupied PRI outbound call",
  "cms.calllink.field.callType22": "Pre-connected PRI outbound call",
  "cms.calllink.field.callType23": "Virtual PRI inbound and outbound calls",
  "cms.calllink.field.callType24": "Previewed PRI outbound call",
  "cms.calllink.field.callType25": "PRI callback request",
  "cms.calllink.title.callId": "Call ID",
  "cms.calllink.title.callType": "Call Type",
  "cms.calllink.title.caller": "Caller Number",
  "cms.calllink.title.callee": "Called Number",
  "cms.calllink.title.mediaType": "Media Type",
  "cms.calllink.title.deviceType": "Device Type",
  "cms.calllink.title.deviceNo": "Device ID",
  "cms.calllink.title.deviceDesc": "Device Description",
  "cms.calllink.title.leaveReason": "Reason for Leaving the Device",
  "cms.calllink.title.callTrace": "Call Link",
  "cms.calllink.title.beginTime": "Start Time of Statistics",
  "cms.calllink.title.endTime": "End Time of Statistics",
  "cms.calllink.title.logDate": "Statistics Time",
  "cms.calllink.title.callBeginTime": "Call Start Time",
  "cms.calllink.title.callEndTime": "Call End Time",
  "cms.calllink.title.agentId": "Connected Agent ID",
  "cms.calllink.title.skillQueue": "Connected Skill Queue",
  "cms.calllink.msg.numberInvalid": "Incorrect number format.",
  "cms.calldetail.msg.callIdInvalid": "The format is incorrect.",
  "cms.calllink.msg.callerPlaceHolder": "Enter the caller number",
  "cms.calllink.msg.calleePlaceHolder": "Enter the called number",
  "cms.calllink.msg.dateTooLate": "The selected date cannot be later than {0}.",
  "cms.calldetail.msg.timeTooLate": "The selected time cannot be later than {0}.",
  "cms.calllink.title.callTrack": "Call Track",
  "cms.calllind.field.deviceQueue": "Skill queue",
  "cms.calllind.field.deviceAgent": "Agent",
  "cms.calllind.field.deviceRoute": "Temporary routing device",
  "cms.calllind.field.deviceVnr": "Virtual device",
  "cms.uapmonitor.field.callSip": "Terminal device identifier",
  "cms.uapmonitor.field.callSize": "Terminal Type",
  "cms.uapmonitor.field.callUapIp": "UAP IP",
  "cms.uapmonitor.field.callStatus": "Registration Status",
  "cms.uapmonitor.field.callSocket": "Socket status",
  "cms.uapmonitor.field.callIpPort": "IP and port",
  "cms.uapmonitor.field.callIpSize": "IP Type",
  "cms.uapmonitor.field.callOperator": "Operation",
  "cms.uapmonitor.field.Logout": "Logout",
  "cms.uapmonitor.field.Search": "Search",
  "cms.uapmonitor.field.registered": "registered",
  "cms.uapmonitor.field.unregister": "not register",
  "cms.uapmonitor.field.socketFault": "fault",
  "cms.uapmonitor.field.socketNormal": "normal",
  "cms.uapmonitor.field.socketConnecting": "connecting",
  "cms.uapmonitor.field.socketUninstall": "uninstall",
  "cms.uapmonitor.label.error": "Error",
  "cms.uapmonitor.label.registererror": "Logout failed",
  "cms.uapmonitor.title.regist": "Terminal Registration Status",
  "cms.uapmonitor.title.deregis": "Deregistration Confirmation",
  "cms.uapmonitor.field.deregister": "Are you sure you want to deregister the device?",
  "cms.interfaceperflog.message.dvSwitch": "Enable data reporting",
  "cms.interfaceperflog.message.modifySuccess": "Modification successful",
  "cms.interfaceperflog.message.modifyFail": "Modification failed",
  "cms.fullscreen.field.themeType": "Theme Type",
  "cms.fullscreen.field.themeTypeY": "Public Themes",
  "cms.fullscreen.field.themeTypeN": "Private Themes",
  "cms.report.message.voicedataOverError": "The number of exported files exceeds 0. Please reselect search criteria.",
  "cms.fullscreen.message.publicTheme": "(Public)",
  "cms.fullscreen.field.setIndicatorThreshold": "Set Indicator Thresholds",
  "cms.fullscreen.field.minThreshold": "Min Threshold",
  "cms.fullscreen.field.maxThreshold": "Max Threshold",
  "cms.fullscreen.field.no": "No",
  "cms.fullscreen.field.yes": "Yes",
  "cms.fullscreen.field.IVR": "IVR",
  "cms.fullscreen.message.numberCheck": "The value must be 0, a positive integer, or a positive decimal with a maximum of two decimal places.",
  "cms.fullscreen.message.digitsCheck": "The value must be a positive integer",
  "cms.fullscreen.message.thresholdLessThan": "The value must be greater than the minimum value",
  "cms.fullscreen.message.warningSelectOne": "Select only one indicator for threshold setting.",
  "cms.export.field.addExportTask": "New export task",
  "cms.export.field.selectFileType": "Selecting the format of the export file",
  "cms.export.field.viewExportTask": "Export task view",
  "cms.export.field.refresh": "Refresh",
  "cms.export.field.download": "download",
  "cms.export.field.exportBeginTime": "Export start time",
  "cms.export.field.exportEndTime": "Export end time",
  "cms.export.field.exportTaskStatus": "Status",
  "cms.export.field.operation": "Operate",
  "cms.export.message.addExportTaskSuccess": "New export task succeeded",
  "cms.export.message.addExportTaskError": "New export task failed, please check the log",
  "cms.export.field.unprocessed": "Unprocessed",
  "cms.export.field.exportiong": "Exporting",
  "cms.export.field.success": "Success",
  "cms.export.field.failed": "Failed",
  "cms.export.message.passValidateFailed": "The value is a string of 8 to 12 characters and must contain letters, digits, and special characters (excluding |;&$-<>/\\`!#(){}, spaces, newline character, and tab characters).",
  "cms.export.field.zippwd": "Compressed password",
  "cms.export.message.popwindowmessage": "Password for generating a compressed file. The value is a string of 8 to 12 characters and must contain letters, digits, and special characters ( excluding {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, spaces, newline character, and tab characters ).",
  "cms.custdata.operlog.popwindowmessage": "Please enter the password used to generate the compressed file. The compressed password should contain letters, numbers, special characters _ @ %",
  "cms.export.message.exception": "An error occurred when generating the export task. Check the logs.",
  "cms.export.message.parainvalid": "Failed to verify the export conditions.",
  "cms.export.message.exceedlimit1": "A maximum of 0 data records can be exported. Narrow down the data time range or add data filter criteria.",
  "cms.export.message.exceedlimit2": ". Narrow down the data time range or add data filter criteria.",
  "cms.export.message.exceedNumberLimit": "Excessive data volume. Narrow down the data time range or add data filter criteria.",
  "cms.export.message.nocount": "No data that meets the conditions is found.",

  "cms.title.select": "Select",

  "cms.vcallecenter.title.resetpwd": "Reset Password",
  "cms.vcallecenter.label.resetpwd": "Reset Administrator Password",
  "cms.vcallecenter.label.tenantaccount": "Administrator Account",
  "cms.vcallecenter.label.inputnewpwd": "New Password",
  "cms.vcallecenter.label.renewpwd": "Confirm Password",
  "cms.vcallecenter.title.tips": "Information",
  "cms.vcallecenter.message.validatefailed": "Failed to verify the password.",
  "cms.vcallecenter.resetpwd.message.fail": "Failed to reset the password.",

  "cms.report.field.mediatype.vedio": "Video",
  "cms.report.field.mediatype.H5Vedio": "H5 Video",
  "cms.report.field.mediatype.clickvediocall": "Video click-to-dial",
  "cms.report.field.submediatype.web": "Web",
  "cms.report.field.submediatype.whatsapp": "WhatsApp",
  "cms.report.field.submediatype.line": "LINE",
  "cms.report.field.submediatype.wechat": "WeChat",
  "cms.report.field.submediatype.facebook": "Facebook",
  "cms.report.field.submediatype.twitter": "X (Twitter)",
  "cms.report.field.submediatype.5gmessage": "5G RCS",
  "cms.report.field.submediatype.email": "Email",
  "cms.report.field.submediatype.sms": "SMS",
  "cms.report.title.submediaType": "Multimedia Type",
  "cms.systemmonitor.label.search": "Search",
  "cms.systemmonitor.label.reset": "Reset",
  "cms.systemmonitor.field.selectCcName": "Select Call Center",
  "cms.systemmonitor.field.selectAgentName": "Select Tenant",
  "cms.systemmonitor.message.dategt31days": "The date range cannot exceed 31 days.",
  "cms.systemmonitor.message.addVdnMost": "A maximum of 100 tenants can be selected.",
  "cms.systemmonitor.field.cancel": "Cancel",
  "cms.systemmonitor.field.confirm": "Confirm",
  "cms.systemmonitor.field.time": "Statistics Collected",
  "cms.systemmonitor.field.ccName": "Call Center Name",
  "cms.systemmonitor.field.vdnName": "Tenant",
  "cms.systemmonitor.field.onlineAgentNums": "Online Agents",
  "cms.systemmonitor.field.ideaAgentNum": "Idle Agents",
  "cms.systemmonitor.field.talkingNums": "Talking Agents",
  "cms.systemmonitor.field.busyNums": "Busy Agents",
  "cms.systemmonitor.field.incallNums": "System Inbound Calls",
  "cms.systemmonitor.field.rateNum": "System Connected Calls",
  "cms.systemmonitor.field.callRate": "System Call Connection Rate",
  "cms.systemmonitor.field.uapIP": "UAP IP Address",
  "cms.systemmonitor.field.ofcNo": "Inbound No.",
  "cms.systemmonitor.field.systemInBound": "System Inbound Calls",
  "cms.systemmonitor.field.systemInSucc": "System Connected Calls",
  "cms.systemmonitor.field.systemCallRate": "System Call Connection Rate",
  "cms.systemmonitor.field.agentOnlineCount": "Registered Softphone Numbers",
  "cms.systemmonitor.field.queryTime": "Query Time",
  "cms.systemmonitor.label.thirtyminutes": "30 Minutes",
  "cms.systemmonitor.label.onehour": "1 Hour",
  "cms.systemmonitor.label.threehours": "3 Hours",
  "cms.systemmonitor.label.thirtyonedays": "31 Days",
  "cms.systemmonitor.label.customizes": "Customization",
  "cms.systemmonitor.message.timeempty": "* Start time or end time are not allowed to be empty.",
  "cms.systemmonitor.message.timeerror": "* The start time must be earlier than the end time.",
  "cms.systemmonitor.message.timelarge": "* The time range cannot be larger than 31 days.",
  "cms.systemmonitor.field.ok": "OK",
  "cms.systemmonitor.title.tips": "Information",
  "cms.systemmonitor.title.error": "Error",
  "cms.systemmonitor.message.queryErrorInfo": "Query failed.",
  "cms.systemmonitor.label.CommercialUse": "Commercial Use",
  "cms.systemmonitor.label.noncommercial": "Trial Commercial Use",
  "cms.vdnconfiguration.label.configure": "Set",
  "cms.vdnconfiguration.field.defaultCallCenter": "Default Call Center",
  "cms.vdnconfiguration.title.vdnConfigure": "Configure the Call Center Where VDN Runs",
  "cms.vdnconfiguration.field.cancel": "Cancel",
  "cms.vdnconfiguration.field.ok": "OK",
  "cms.vdnconfiguration.title.success": "Success",
  "cms.vdnconfiguration.title.error": "Error",
  "cms.vdnconfiguration.message.updatesuccess": "Update successful.",
  "cms.vdnconfiguration.message.updatefailed": "Update failed.",
  "cms.callcenterselect.title.selectCcName": "Select Call Center",

  "cms.report.field.selectAgent": "Select Agent",
  "cms.report.field.selectOrg": "Select OU",
  "cms.report.placeholder.workName": "Enter an agent name.",
  "cms.report.message.selectAgent": "Select at least one agent ID.",
  "cms.agentmonitor.label.cancellisteningOrInsert": "Cancel Listening or Insertion",
  "cms.agentmonitor.label.cancelsuccess": "Cancellation successful.",
  "cms.agentmonitor.label.cancelfail": "Cancellation failed.",
  "cms.agentmonitor.message.header": "Saving failed. Select at least one table header.",
  "cms.export.title.voiceDownload": "Recording Inspection",
  "cms.export.message.downloadSucceed": "The report is downloaded successfully.",
  "cms.export.message.downloadFailed": "Failed to download the report.",
  "cms.report.message.selectAgentMax": "A maximum of 100 agent IDs can be selected.",
  "cms.report.field.acwRateField": "Wrap-up duration rate",
  "cms.report.message.acwRateDesc": "Wrap-up duration rate = (Total wrap-up duration/Total work duration) x 100",

  "cms.callcenterinstanceslist.title.chooseMode": "Select Mode",
  "cms.callcenterinstanceslist.field.cmsAuthName": "CCDIS Authentication Account",
  "cms.callcenterinstanceslist.field.cmsAuthPassword": "CCDIS Password",
  "cms.callcenterinstanceslist.field.disPwdConfirm": "Confirm CCDIS Password",
  "cms.callcenterinstanceslist.field.single": "Single mode",
  "cms.callcenterinstanceslist.field.pool": "Pool mode",
  "cms.callcenterinstanceslist.field.dvDataReport": "DigitalView Data Reporting",
  "cms.callcenterinstanceslist.message.cleanUap": "Clear UAP Node Configuration",
  "cms.callcenterinstanceslist.message.cleanKafka": "Clear KAFKA Node Configuration",
  "cms.callcenterinstanceslist.message.uapNodeInfo": "UAP Node",
  "cms.callcenterinstanceslist.message.uapNode1Info": "UAP Node 1",
  "cms.callcenterinstanceslist.message.uapNode2Info": "UAP Node 2",
  "cms.callcenterinstanceslist.field.uapIp": "UAP NMU IP Address",
  "cms.callcenterinstanceslist.field.uapCmdPort": "UAP MML Command Port",
  "cms.callcenterinstanceslist.field.uapPerfPort": "UAP Performance Monitoring Port",
  "cms.callcenterinstanceslist.field.uapUserName": "UAP CDE Username",
  "cms.callcenterinstanceslist.field.uapPassword": "UAP CDE Password",
  "cms.callcenterinstanceslist.message.clearUapConfig": "Are you sure you want to delete UAP node configuration?",
  "cms.callcenterinstanceslist.message.clearKafkaConfig": "Are you sure you want to delete KAFKA node configuration?",
  "cms.callcenterinstanceslist.field.uapPasswordConfirm": "Confirm UAP CDE Password",

  "cms.callcenterinstanceslist.message.kafkaNodeInfo": "KAFKA Node",
  "cms.callcenterinstanceslist.message.needKafkaSSL": "OPEN SSL",
  "cms.callcenterinstanceslist.field.kafkaIp": "KAFKA IP Address",
  "cms.callcenterinstanceslist.field.kafkaPort": "KAFKA Port",
  "cms.callcenterinstanceslist.field.kafkaUserName": "KAFKA Username",
  "cms.callcenterinstanceslist.field.kafkaPassword": "KAFKA Password",
  "cms.callcenterinstanceslist.field.kafkaPasswordConfirm": "Confirm KAFKA Password",

  "cms.callcenterinstanceslist.message.datasourceInfo": "Complete the data source configuration.",
  "cms.callcenterinstanceslist.message.dsmodifyconfirm": "The data source configuration has been changed. Restart the environment for the modification to take effect. Are you sure you want to modify it?",

  "cms.datasource.label.create": "Add",
  "cms.datasource.label.delete": "Delete",
  "cms.datasource.label.edit": "Edit",
  "cms.datasource.message.dbNameInput": "Enter a data source name.",
  "cms.datasource.field.dbName": "Data Source Name",
  "cms.datasource.field.dbServiceType": "Business Type",
  "cms.datasource.field.dbType": "Data Source Type",
  "cms.datasource.field.dbUsername": "Username",
  "cms.datasource.field.isBackup": "Backup Database",
  "cms.datasource.field.backupDbName": "Backup Data Source",
  "cms.datasource.field.operation": "Operation",
  "cms.datasource.title.addDatasource": "Add Data Source",
  "cms.datasource.field.dbUrl": "Database Connection String",
  "cms.datasource.field.dbPwd": "Password",
  "cms.datasource.field.remark": "Usage Description",
  "cms.datasource.field.test": "Test Connection",
  "cms.datasource.field.cancel": "Cancel",
  "cms.datasource.field.ok": "OK",
  "cms.datasource.field.cmsDatabase": "CMS report database",
  "cms.datasource.field.uidbDatabase": "UIDB",
  "cms.datasource.field.no": "No",
  "cms.datasource.field.yes": "Yes",
  "cms.datasource.field.passwordConfirm": "Confirm Password",
  "cms.datasource.title.modifyDatasource": "Update Data Source",
  "cms.datasource.message.isDeleteDatasource": "Are you sure you want to delete the data source?",
  "cms.datasource.message.addsuccess": "Addition successful.",
  "cms.datasource.message.addfailed": "Addition failed.",
  "cms.datasource.message.updatesuccess": "Update successful.",
  "cms.datasource.message.updatefailed": "Update failed.",
  "cms.datasource.message.deletesuccess": "Deletion successful.",
  "cms.datasource.message.deletefailed": "Deletion failed.",
  "cms.callcenterinstanceslist.title.sourceconfiguration": "Data Source Settings",
  "cms.datasource.message.mysql5UrlTip": "Database connection string: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (IP address) port (port number) schema (mode)",
  "cms.datasource.message.mysql8UrlTip": "Database connection string: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (IP address) port (port number) schema (mode)",
  "cms.datasource.message.oracleUrlTip": "Database connection string: &#8220jdbc:oracle:thin:@ip:port:dbservicename&#8220&#10;ip (IP address) port (port number) dbservicename (database name)",
  "cms.datasource.message.gaussUrlTip": "Database connection string: &#8220jdbc:zenith:@ip:port&#8220&#10;ip (IP address) port (port number)",
  "cms.datasource.message.dmUrlTip": "Database connection string: &#8220jdbc:dm://ip:port/dbservicename&#8220&#10;ip (IP address) port (port number) dbservicename (database name)",
  "cms.datasource.message.passValidateFailed": "The value cannot contain \"eval\", \"exec\", and the following special characters: <=>/\"",
  "cms.datasource.message.already": "The data source name already exists.",
  "cms.datasource.message.testsuccess": "The connection is tested successfully.",
  "cms.datasource.message.testfailed": "Failed to test the connection.",
  "cms.datasource.message.ccDsRelationExist": "The data source has been referenced in the call center.",
  "cms.datasource.message.masterSalveRelationExist": "The data source has been used as the backup data source.",
  "cms.datasource.message.dsmodifyconfirm": "The data source has been referenced in the call center and takes effect only after the environment is restarted. Are you sure you want to modify it?",

  "cms.systemparam.success.save": "Parameter saved successfully",
  "cms.systemparam.error.save": "Parameter saving failed",
  "cms.systemparam.success.reset": "Parameter reset succeeded",
  "cms.systemparam.error.reset": "Parameter reset failed",
  "cms.systemparam.success.refresh": "Parameter refreshed successfully",
  "cms.systemparam.error.refresh": "Parameter refresh failed",
  "cms.systemparam.error.notmatchrule": "The parameter does not meet the check rule",
  "cms.systemparam.error.noparamselected": "Please select a parameter first",

  "cms.sysparam.config.search": "Search",
  "cms.sysparam.config.reset": "Reset",
  "cms.sysparam.config.refresh": "Refresh",
  "cms.sysparam.config.itemname": "Parameter Name",
  "cms.sysparam.config.value": "Parameter Value",
  "cms.sysparam.config.itemdesc": "Description",
  "cms.sysparam.config.opterate": "Operation",
  "cms.sysparam.config.save": "Save",
  "cms.sysparam.config.mod": "Edit",
  "cms.sysparam.config.checkmessage": "Contains special characters. (\"<\", \">\", \"/\", etc.)",
  "cms.sysparam.config.itemname.tips": "Enter a parameter name.",
  "cms.sysparam.config.select.prompt": "Hints",
  "cms.sysparam.config.select.null": "Please select a parameter.",
  "cms.sysparam.config.select.count": "A maximum of 10 parameters can be selected.",
  "cms.sysparam.config.item.detail": "Parameter information",
  "cms.sysparam.config.item.catalog": "Parameter Type",
  "cms.sysparam.config.title": "System parameters",
  "cms.sysparam.config.reset.multiple.error": "The following parameters cannot be reset:",
  "cms.systemparam.error.validate.inner": "Validate parameter failed",
  "cms.systemparam.error.validate.ruleinvalid": "The verification rule is invalid",
  "cms.systemparam.error.validate.ruleregexinvalid": "Validate rule syntax error",
  "cms.systemparam.error.validate.notmatchrule": "The input parameter does not match the verification rule",
  "cms.systemparam.error.validate.notmatchruleregex": "The input parameter does not match the regular expression of the verification rule",
  "cms.systemparam.error.validate.notmatchrulealpha": "Please enter alphanumerics or underscores, and only start with letters or underscores",
  "cms.systemparam.error.validate.notmatchruledigits": "Please enter a valid positive integer",
  "cms.systemparam.error.validate.notmatchruleemail": "Please enter a valid email address",
  "cms.systemparam.error.validate.notmatchruleip": "Please enter a valid IPV4 or IPV6 address",
  "cms.systemparam.error.validate.notmatchrulemaxlength": "parameter exceeds maximum length 0",
  "cms.systemparam.error.validate.notmatchrulemax": "The input parameter exceeds the maximum",
  "cms.systemparam.error.validate.notmatchruleminlength": "The input parameter is less than the minimum length",
  "cms.systemparam.error.validate.notmatchrulemin": "The input parameter is lower than the minimum",
  "cms.systemparam.error.validate.notmatchrulenumber": "Please enter a number",
  "cms.systemparam.error.validate.notmatchruleoption": "The input parameter is not in the enumeration range",
  "cms.systemparam.error.validate.notmatchrulerangelength": "The input parameter exceeds the maximum length or is below the minimum length",
  "cms.systemparam.error.validate.notmatchrulerange": "The input parameter exceeds the maximum value or is below the minimum value",
  "cms.systemparam.error.validate.notmatchrulerequired": "Required fields",
  "cms.systemparam.error.validate.notmatchruleurl": "Please enter a valid URL address",

  "cms.uapmonitor.labe.interval": "Monitoring Period",
  "cms.uapmonitor.labe.eid": "Terminal number segment",
  "cms.uapmonitor.labe.qcworkno": "QC Employee ID",
  "cms.uapmonitor.labe.inspectorcheckinnum": "QC inspector sign-in number",
  "cms.uapmonitor.labe.inspectorcheckinpwd": "QC inspector sign-in password",
  "cms.uapmonitor.labe.openmonitor": "Monitoring",
  "cms.uapmonitor.labe.uapconfing": "Terminal Status Monitoring and Restoration Configuration",
  "cms.uapmonitor.labe.eidmention": "You can enter a maximum of 10 number segments, which are separated by commas (,). Rule for each number segment: * and % match no character or multiple characters. For example, 6666* and 6666% match all character strings starting with 6666. The question mark (?) matches only one character. For example, 6666? matches only the five-character string starting with 6666. The combination of wildcards (*?%) is supported. For example, %3? matches all character strings whose last but one character is 3. Note: It is recommended that a maximum of 100 results are matched for each wildcard. Otherwise, businesses on the UAP are affected.",
  "cms.uapmonitor.message.intervalvalidate": "Enter a number ranging from 300 to 86400.",
  "cms.uapmonitor.message.eidvalidate": "Enter parameters as prompted.",
  "cms.uapmonitor.labe.mentiontitle": "Note:",
  "cms.uapmonitor.labe.mention": "1. After the monitoring function is enabled, agents whose terminal registration status is inconsistent with the agent sign-in status are forcibly logged out periodically.",
  "cms.uapmonitor.labe.mentionnotice": "2. Ensure that the virtual call center contains inspectors and the inspectors have configured the softphone number and sign-in password. Only agents in abnormal state can be forcibly logged out.",
  "cms.uapmonitor.labe.uapoverload": "The number of numbers queried by the terminal number segment exceeds the upper limit:",
  "cms.uapmonitor.labe.eidoverlength": "The number of terminal number segments exceeds the upper limit: 10.",
  "cms.errormessage.inputspecialchar": "The value contains invalid special characters.",

  "cms.calldetail.label.inputcallid": "Please enter the call sequence number",
  "cms.report.field.callIdField": "Call Sequence Number",
  "cms.report.field.callIdNumField": "CDR Sequence Number",
  "cms.report.field.callerNoField": "Caller Number",
  "cms.report.field.calleeNoField": "Called Number",
  "cms.report.field.waitBeginField": "Wait Start Time",
  "cms.report.field.ackBeginField": "Answer Start Time",
  "cms.report.field.callBeginField": "Call Start Time",
  "cms.report.field.callEndField": "Call End Time",
  "cms.report.field.deviceTypeField": "Device Type",
  "cms.report.field.deviceNoField": "Device No",
  "cms.report.field.callTypeField": "Call Type",
  "cms.report.field.mediaTypeField": "Media Type",
  "cms.report.field.enterReasonField": "Indicates The Reason For Entering The Device",
  "cms.report.field.billInfo1Field": "Voice Call Type",
  "cms.report.field.obsServiceIdField": "Outbound Campaign ID",
  "cms.report.field.currentSkillIdField": "Skill Queue ID",
  "cms.report.field.subMediaTypeField": "Submedia Type",
  "cms.report.field.waitEndField": "Waiting End Time",
  "cms.report.field.ackEndField": "Answer End Time",
  "cms.report.field.waitCauseField": "Waiting Reason",
  "cms.report.field.releaseCauseField": "Release Reason",
  "cms.report.field.uvidField": "User Access ID",
  "cms.report.field.userLevelField": "Customer Level",
  "cms.report.field.callInCauseField": "Call Entry Reason",
  "cms.report.field.leaveReasonField": "Reason For Leaving The Device",
  "cms.report.field.preDeviceTypeField": "Previous Device Type",
  "cms.report.field.preDeviceNoField": "Indicates The Device ID Of The Previous Device",
  "cms.report.field.skillIdField": "ID Of The Skill Queue To Which The Call Belongs",
  "cms.report.field.locationIdField": "Distributed Node ID",
  "cms.report.field.billInfo2Field": "Unique ID Of A Dual Call",
  "cms.report.field.uapIdField": "UAP ID",
  "cms.report.field.netEntIdField": "NE ID Of The Media Server",
  "cms.report.message.callIdDesc": "Call Sequence Number, Which Uniquely Identifies A Call.",
  "cms.report.message.callIdNumDesc": "Sequence Number Of Multiple CDRs Generated For A Call.",
  "cms.report.message.callerNoDesc": "Calling number.",
  "cms.report.message.calleeNoDesc": "Called number.",
  "cms.report.message.waitBeginDesc": "Call Waiting Start Time.",
  "cms.report.message.ackBeginDesc": "Call Answer Start Time.",
  "cms.report.message.callBeginDesc": "Call Start Time.",
  "cms.report.message.callEndDesc": "Call End Time.",
  "cms.report.message.deviceTypeDesc": "Type Of The Device That Processes The Call.",
  "cms.report.message.deviceNoDesc": "Indicates The Device Number Of The Device That Is Processing The Call.",
  "cms.report.message.callTypeDesc": "Call Type.",
  "cms.report.message.mediaTypeDesc": "Call Media Type.",
  "cms.report.message.enterReasonDesc": "Indicates The Reason For Entering The Device.",
  "cms.report.message.billInfo1Desc": "Voice Call Type.",
  "cms.report.message.obsServiceIdDesc": "ID of An Outbound Call Campaign.",
  "cms.report.message.currentSkillIdDesc": "ID Of The Skill Queue That Processes Calls.",
  "cms.report.message.subMediaTypeDesc": "Submedia Type.",
  "cms.report.message.waitEndDesc": "Indicates The End Time Of The Call Waiting On The Current Device.",
  "cms.report.message.ackEndDesc": "Call Answer End Time, Which Refers To The End Time Of The Call Answer On The Current Device.",
  "cms.report.message.waitCauseDesc": "Indicates The Reason Why A Call Waits On The Current Device.",
  "cms.report.message.releaseCauseDesc": "Call Release Reason.",
  "cms.report.message.uvidDesc": "Globally Unique ID Allocated By The CCS To Each User Who Accesses The Call Center.",
  "cms.report.message.userLevelDesc": "Customer Level Of A Subscriber In The Call Center.",
  "cms.report.message.callInCauseDesc": "Reason Why A Call Enters The Call Center (For Voice Calls).",
  "cms.report.message.leaveReasonDesc": "Reason For Leaving The Device.",
  "cms.report.message.preDeviceTypeDesc": "Device Type Of The Last Device That The Call Went Through.",
  "cms.report.message.preDeviceNoDesc": "Indicates The Device ID Of The Last Device That The Call Goes Through.",
  "cms.report.message.skillIdDesc": "ID Of The Skill Queue To Which The Call Belongs.",
  "cms.report.message.locationIdDesc": "ID Of The Distributed Node To Which The Call Belongs.",
  "cms.report.message.billInfo2Desc": "Unique ID Of A Dual Call.",
  "cms.report.message.uapIdDesc": "ID Of The UAP That Connects Calls To The Call Center Platform.",
  "cms.report.message.netEntIdDesc": "NE ID Of The Media Server That Generates The Current CDR.",
  "cms.report.title.calldetailoriginal": "Call Content Details",
  "cms.vcallcenter.message.unknowcause": "Unknown cause",
  "cms.vcallcenter.message.unknowdesc": "Unknown exception.",
  "cms.vcallcenter.message.unknowsolution": "Contact Huawei engineers to analyze and rectify the fault",

  "cms.report.message.startOrEndTimeNoExist": "If select a time segment for query, the start time and end time must exist at the same time.",
  "cms.report.message.startTimeLessThanEndTime": "The start time cannot be later than the end time.",
  "cms.report.field.stateField": "Connect State",
  "cms.report.field.connected": "Connected",
  "cms.report.field.notConnected": "Unconnected",
  "cms.report.field.hangUpModeField": "Hang Up Method",
  "cms.report.field.startTimeField": "call Start Time",
  "cms.report.field.endTimeField": "call End Time",
  "cms.report.field.accessCodeField": "Access Code",
  "cms.report.field.callNoPlaceholder": "Calling Or Called No",
  "cms.report.field.callStartTimeField": "Call Start Time",
  "cms.report.field.callEndTimeField": "Call End Time",
  "cms.report.field.alertingTimeField": "Call Alerting Time",
  "cms.report.field.talkingTimeField": "Call Talking Time",
  "cms.report.field.talkDurationField": "Talking Duration",
  "cms.report.field.agentReleaseTimeField": "Caller Release Time",
  "cms.report.field.agentAlertingTimeField": "Caller Alerting Time",
  "cms.report.field.agentTalkingTimeField": "Caller Talking Time",
  "cms.report.field.agentTalkDurationField": "Caller Talking Duration",
  "cms.report.field.createTimeField": "Create Time",
  "cms.report.field.agentAlertingDurationField": "Caller Alerting Duration",
  "cms.report.field.alertingDurationField": "Called Alerting Duration",
  "cms.report.field.callerHangUp": "Caller Hang Up",
  "cms.report.field.calledHangUp": "Called Hang Up",
  "cms.report.field.dualCallType": "One-click Two-call",
  "cms.report.field.exceptionHangUp": "Exception Hang Up",
  "cms.report.title.filteringHeader": "Filter Table Header",
  "cms.report.field.tableHeader": "Table Header",
  "cms.report.message.stateDesc": "Connect State, includes connected and unconnected.",
  "cms.report.message.hangUpModeDesc": "Hang up method.",
  "cms.report.message.accessCodeDesc": "Access code.",
  "cms.report.message.callStartTimeDesc": "Call start time.",
  "cms.report.message.callEndTimeDesc": "Call End Time.",
  "cms.report.message.alertingTimeDesc": "Call Alerting Time.",
  "cms.report.message.talkingTimeDesc": "Call Talking Time.",
  "cms.report.message.talkDurationDesc": "Talking Duration.",
  "cms.report.message.agentReleaseTimeDesc": "Caller Release Time.",
  "cms.report.message.agentAlertingTimeDesc": "Caller Alerting Time.",
  "cms.report.message.agentTalkingTimeDesc": "Caller Talking Time.",
  "cms.report.message.agentTalkDurationDesc": "Caller Talking Duration.",
  "cms.report.message.createTimeDesc": "Create Time.",
  "cms.report.message.agentAlertingDurationDesc": "Caller Alerting Duration.",
  "cms.report.message.alertingDurationDesc": "Called Alerting Duration.",
  "cms.report.title.callNo": "call Number",
  "cms.report.title.dualCallDetail": "Dual Call Detail",
  "cms.report.message.inputZipPassword": "Please enter the compression password",
  "cms.report.task.sucess.export": "An export task has been created. Are you sure you want to view it in the export list?",
  "cms.report.task.export.records": "Exporting Contact Records",
  "cms.report.field.length.limit": "The value must be a number with a maximum of 0 characters.",
  "cms.report.field.number": "Invalid number",
  "cms.report.field.view.export.task": "View Export Tasks ",
  "cms.report.field.enter.callin": "Please enter the serial number of the incoming call",
  "cms.agent.lable.alarm": "Alarm",
  "cms.agent.label.agentlist": "List of agents",
  "cms.agent.label.agentdetailInfo": "Agent Details",
  "cms.agent.label.enterdetail": "View Details",
  "cms.agent.label.statetime": "Status Duration",
  "cms.agent.label.duracount": "Duration Statistics",
  "cms.agent.label.numcount": "Count Statistics",
  "cms.agent.label.agentname": "Agent Name",
  "cms.agent.label.logintime": "Login Time",
  "cms.agent.label.idlenum": "Idle Time",
  "cms.agentmonitor.label.countAndTime": "Times/Duration",
  "cms.agent.label.arrangetime": "Arrange Time",
  "cms.agent.label.logouttime": "Offline Time",
  "cms.agent.label.alarmstate": "Alarm Status",
  "cms.input.format.invalid": "The input format is incorrect",
  "cms.calllind.field.deviceUnkown": "Unknown Device",
  "cms.normal.text.validate": "Only digits, letters, and -._{'@'} are allowed.",
  "cms.agent.label.signinphone": "Sign-in Number",
  "cms.agent.label.currentagentstate": "Current Status Of The Agent",
  "cms.agent.label.currentstatetime": "Current State Duration Of An Agent",
  "cms.agent.label.choosestatetime": "Select the minimum duration of the status.",
  "cms.agent.label.busyitem": "Busy",
  "cms.agent.label.talkitem": "Call",
  "cms.agent.label.online": "Online",
  "cms.agent.label.offline": "OffLine",
  "cms.agent.label.callinrate": "Incoming Call Completion Rate",
  "cms.agent.label.callintime": "Incoming Call Duration",
  "cms.agent.label.avgcallintime": "Average Incoming Call Duration",
  "cms.agent.label.avgwaittime": "Average Incoming Call Waiting Duration",
  "cms.agent.label.maxwaittime": "Max Waitting Time Of Incoming Call",
  "cms.agent.label.calloutsuccrate": "Outgoing Call Completion Rate",
  "cms.agent.label.callouttime": "Outgoing Call Duration",
  "cms.agent.label.avgcallouttime": "Average duration of outgoing calls",
  "cms.agent.label.choosesybmediatype": "Select a channel type",
  "cms.agent.label.busytime": "Busy Time",
  "cms.agent.label.avgbusytime": "Avg Busy Time",
  "cms.agent.label.acgtalktime": "Avg Talk Time",
  "cms.agent.label.outresttime": "Rest timeout duration",
  "cms.agent.label.totaltalktime": "Total call duration",
  "cms.agent.label.redirtnum": "Redirections",
  "cms.agent.label.totaltalknums": "Total number of calls",
  "cms.agent.tips.selectstatetime": "Select the status duration. You need to select the current status",
  "cms.agent.label.selectstatetime": ">= Status Duration (Minute)",
  "cms.agent.tips.sipaccountcheck": "Select a positive integer ranging from 1 to 32 digits as the sign-in number",
  "cms.indexmonitor.field.totalAgentConnectCallTime": "Call duration",
  "cms.indexmonitor.title.agentDate": "Agent data",
  "cms.agent.tips.notassined": "Not Assigned",
  "cms.indexmonitor.set.limitA": "Select",
  "cms.indexmonitor.set.limitB": "indicators for display",
  "cms.indexmonitor.titile.indicatorSetting": 'Indicator Setting',
  "cms.indexmonitor.realTime.indicator": "Real-time metrics",
  "cms.indexmonitor.index.multimediaCalls": "Multimedia",
  "cms.indexmonitor.index.callTrends": "Call Trends",
  "cms.indexmonitor.index.skillQueueServiceLevel": "Skill Queue Service Level Overview",
  "cms.indexmonitor.index.met": "Met",
  "cms.indexmonitor.index.missed": "Missed",
  "cms.incoming.record.not.sameday": "Cross-day query is not supported.",
  "cms.requiredcallback.message.allowTimeRange": "supports query of data within a maximum of seven days",
  "cms.incoming.record.field.releasecause": "Call Release Cause",
  "cms.incoming.record.field.releasecause.input": "Please enter the call release reason",
  "cms.incoming.record.field.releasecause.id": "Please enter the call release reason code",
  "cms.incoming.record.field.releasecause.causecode": "Please enter the reason mapping code",
  "cms.incoming.record.field.ifReqManual": "Has the manual service been requested",
  "cms.incoming.record.field.isReqManual": "Labor Requested",
  "cms.incoming.record.field.notReqManual": "Labor is not requested",
  "cms.incoming.record.field.ifAnswerCall": "Manual Answer",
  "cms.incoming.record.field.isAnswerCall": "Answered",
  "cms.incoming.record.field.notAnswerCall": "The agent does not answer the call",
  "cms.incoming.record.field.releasecause.choose": "Select Call Release Reason",
  "cms.incoming.record.field.release.id": "Call Release Cause Code",
  "cms.incoming.record.field.release.causecode": "Cause Mapping Code",
  "cms.incoming.releasecause.causecode.tip": "cause mapping code is out of range (0 to 45)",
  "cms.incoming.record.report.beginTime": "Query Start Time",
  "cms.incoming.record.report.endTime": "Query End Time",
  "cms.requiredcallback.message.timeRangeCheck": "The time range is the same day",
  "cms.failurecall.field.waitTime.export": "Waiting Duration (s)",
  "cms.failurecall.field.waitBegin": "Call Waiting Start Time",
  "cms.failurecall.field.ackBegin": "Call Answer Start Time",
  "cms.failurecall.field.waitAnsNum": "Waiting Responses",
  "cms.failurecall.field.waitAnsTime": "Waiting Response Duration (s)",
  "cms.failurecall.field.succWaitAnsTime": "Succeeded Call Answer Duration (s)",
  "cms.failurecall.field.noAckNum": "Answered No Call Times",
  "cms.failurecall.field.succQueueWaitTime": "Successful Call Queuing Duration (s)",
  "cms.failurecall.field.succQueueWaitNum": "Successful Call Queuing Times",
  "cms.failurecall.field.failQueueWaitNum": "Call Loss Waiting Duration (s)",
  "cms.failurecall.field.inOccupyNum": "Manual Requests",
  "cms.failurecall.field.inCallSuccNum": "Manual Call Times",
  "cms.failurecall.field.inCallTime": "Manual Call Duration (s)",
  "cms.failurecall.field.queOverToIvrNum": "queue to the IVR",
  "cms.failurecall.field.queOverToIvrTime": "queue transfer to IVR (second)",
  "cms.failurecall.field.hangUpNum": "Number of times that the agent transfers to the IVR",
  "cms.failurecall.field.hangUpTime": "Total time for forwarding manual calls to IVR",
  "cms.failurecall.field.hangUpReleaseNum": "Number of on-hook calls transferred from manual to IVR",
  "cms.indexmonitor.index.noTrafficData": "No traffic data",
  "cms.indexmonitor.index.SkillQueueTips": "The skill queue service level is measured based on the call completion rate within 20 seconds. The default expected service level is 80%. The tenant administrator can modify the expected service level by choosing Tenant Parameters > Call Center Monitoring > Indicator Monitoring > Expected Skill Queue Service Level on the Tenant Parameter Configuration page.",
  "cms.indexmonitor.index.SkillQueueTips2": "The skill queue service level is measured by the call completion rate within 20 seconds. The default expected service level is 80%. The system administrator can modify the expected service level by choosing System Parameters > Call Center Monitoring > Indicator Monitoring > Expected Service Level of Skill Queue.",
  "cms.indexmonitor.index.loadFail": "Loading failed",
  "cms.failurecall.queue.abandon": "Abandoned Call",
  "cms.failurecall.callback": "Inbound Call",
  "cms.faliurecall.incomingcall": "Incoming Call Record Query",
  "cms.vcallecenter.message.checkpasswordfailed": "Failed to verify the sign-in password rule",
  "cms.agent.field.admin": "Administrators",
  "cms.agent.field.commonagent": "Common agent",
  "cms.agent.field.qualitychecker": "Quality checker",
  "cms.failure.callerNo.maxLength.error": "A maximum of 64 characters are allowed.",
  "cms.indexmonitor.curDay.indicator": "Indicator of the day",
  "cms.indexmonitor.field.skillConnectedIn20Rate": "Call completion rate within 20 seconds",
  "cms.agent.label.agentDetail": "Agent Details",
  "cms.multimedia.monitor.field.callNum": "Number of IVR calls",
  "cms.multimedia.monitor.field.transNum": "Number of IVR-to-Manual Calls",
  "cms.multimedia.monitor.field.transRate": "IVR-to-Manual Rate",
  "cms.multimedia.monitor.field.connectedNum": "Number of connected IVR calls",
  "cms.multimedia.monitor.field.connectedRate": "IVR call completion rate",
  "cms.multimedia.monitor.field.durationAverage": "Average IVR call duration",
  "cms.multimedia.monitor.field.callNum.desc": "Number of calls that are currently automatically serviced by the IVR device",
  "cms.multimedia.monitor.field.transNum.desc": "Number of IVR-to-Manual Calls",
  "cms.multimedia.monitor.field.transRate.desc": "Percentage of IVR-to-Manual Calls to IVR Calls",
  "cms.multimedia.monitor.field.connectedNum.desc": "Number of IVR calls whose call duration is greater than 0",
  "cms.multimedia.monitor.field.connectedRate.desc": "Percentage of the number of connected IVR calls to the number of IVR calls",
  "cms.multimedia.monitor.field.durationAverage.desc": "Total IVR call duration/Number of connected IVR calls",
  "cms.report.field.submediatype.instagram": "Instagram",
  "cms.report.field.submediatype.telegram": "Telegram",
  "cms.qcrelationconf.field.attendantGroupName": "Agent Group",
  "cms.report.field.selectTrafficGroup": "Select Agent Group",
  "cms.report.placeholder.attendantGroupName": "Agent Group Name",
  "cms.qcrelationconf.field.attendantGroup": "Agent Group",
  "cms.report.message.selectTrafficGroupMax": "A maximum of 1000 Agent Group can be selected.",
  "cms.agentMonitor.message.operatorChecked": "Operation failed. The inspected object is an inspector and is performing the inspection.",
  "cms.export.message.required": "Mandatory Field",
  "cms.field.length.limit": "The length cannot exceed 0",
  "cms.sysparameter.batchreset": "Batch Reset",
  "cms.sysparameter.batchrefresh": "Batch Sync",
  "cms.report.subscribe.add": "New Report Subscription",
  "cms.report.subscribe.edit": "Editing a Report Subscription",
  "cms.qcrelationconf.tab.member": "Members",
  "cms.qcrelationconf.add.attendantGroup": "Add the inspected traffic group",
  "cms.qcrelationconf.message.isDeleteTrafficGroup": "Are you sure you want to delete the attendant group?",
  "cms.report.subscribe.checkreportnum": "Select at least one report.",
  "cms.sysparameter.edit.basic.information": "Edit Basic Information",
  "cms.sysparameter.edit.character": "Edit Properties",
  "cms.sysparameter.edit.validate.ip": "Enter a correct IP address.",
  "cms.message.choose": "Please select data.",
  "cms.subscribe.template.isused": "The email template has been used.",
  "cms.subscribe.template.statuserror": "The email template status is incorrect.",
  "cms.qcrelationconf.message.unauthorized": "Invalid agents exist in the saved data.",
  "cms.report.subscribe.editreport":"Editing a Report",
  "cms.report.subscribe.addreport":"Adding a Report",
  "cms.fullscreen.agent.auth": "You do not have the permission to view the current organization. Select another organization",
  "cms.agentMonitor.label.changestate_error":"Failed to switch the inspection status, Operation error.",
  "cms.agent.label.systempara":"Parameter Configuration",
  "cms.indexmonitor.field.skillConnectedQueue": "Total Call Queuing Duration",
  "cms.indexmonitor.field.skillAbandonQueue": "Total Queuing Duration Of Lost Calls",
  "cms.indexmonitor.message.skillConnectedQueue": "Total queuing duration of all connected calls in the queue.",
  "cms.indexmonitor.message.skillAbandonQueue": "Total duration of all unconnected calls in the queue.",
}