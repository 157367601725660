import request from '@/utils/request'

export const countAppoint = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/count',
        method: 'post',
        data
    })
}

export const getPlaintextAuth = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/getAppointAuth',
        method: 'post',
        data
    })
}


/**
 * 外呼工作台查询任务列表
 *
 * @param data 入参
 * @return result
 */
export const queryWorkbenchTaskList = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/queryTaskList',
        method: 'post',
        data
    })
}

export const preCallManualCalledNo = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/preCallManualCalledNo',
        method: 'post',
        data
    })
}

export const queryCallInfo = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/querycallinfo',
        method: 'post',
        data
    })
}

export const querySystemParamsByParaKey = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/querySystemParamsByParaKey',
        method: 'post',
        data
    })
}

export const queryAgentStatus = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/queryAgentStatus',
        method: 'post',
        data
    })
}

export const updateFailedCallResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/updateFailedCallResult',
        method: 'post',
        data
    })
}

export const updateManualCallResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/updateManualCallResult',
        method: 'post',
        data
    })
}

export const initPredictionContext = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/initpredictioncontext',
        method: 'post',
        data
    })
}

export const queryManualLockData = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/queryManualLockData',
        method: 'post',
        data
    })
}

export const queryCallNo = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/queryCallNo',
        method: 'post',
        data
    })
}

export const queryRedisTaskInfo = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/queryRedisTaskInfo',
        method: 'post',
        data
    })
}