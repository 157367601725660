// 流程画布配置
import {customNodeMap} from "@/views/ccmanagement/pages/iolp-diagram/IolpNodeConfig";

const graphOptionFree = {
  grid: {
    visible: true,
    size: 12
  },
  scroller: {
    enabled: true,
    pannable: true,
    modifiers: ['ctrl'],
    padding: 0,
    className: 'iolpGraphCustomScrollerClass'
  },
  mousewheel: {
    enabled: true,
    modifiers: ['ctrl', 'meta'],
  },
  // 节点和边是否可移动
  interacting: {
    nodeMovable: true,
    arrowheadMovable: true
  },
  // 点选/框选 rubberband: true
  selecting: {
    enabled: true,
    rubberband: true,
    movable: true,
    modifiers: []
  },
  // 剪贴板
  clipboard: {
    enabled: true
  },
  // 快捷键
  keyboard: {
    enabled: true,
    delete: ['delete', 'backspace']
  },
  // 小地图
  minimap: {
    enabled: true,
    width: 210,
    height: 120,
    cellView: {
      nodeView: {
        markup: {tagName: 'rect', selector: 'body'},
        attrs: {body: {refWidth: '100%', refHeight: '100%', fill: '#366BFC'}}
      },
      edgeView: {
        markup: {tagName: 'path', selector: 'wrap'},
        attrs: {wrap: {fill: 'transparent', connection: true, 'stroke-width': 10, stroke: '#000000'}}
      }
    },
    graphOptions: {
      connecting: {
        router: {
          name: 'manhattan',
          args: {
            padding: 10
          }
        }
      }
    }
  },
  // 撤销/重做
  history: true,
  // 高亮选项
  highlighting: {
    // 当连接桩可以被链接时,连接桩高亮
    magnetAdsorbed: {
      name: 'stroke',
      args: {
        padding: 4,
        attrs: {
          'stroke-width': 2,
          stroke: '#73f0e8'
        }
      }
    }
  },
  // 连线选项
  connecting: {
    connector: {
      name: 'rounded',
      args: {
        radius: 16,
      }
    },
    highlight: true, // 拖动边时，是否高亮显示所有可用的连接桩或节点，默认值为 false。
    allowNode: false, // 是否允许边链接到节点（非节点上的链接桩），默认为 true。
    router: {
      name: 'manhattan',
      args: {
        padding: 10
      }
    },
    // 当 snap 设置为 true 时连线的过程中距离节点或者连接桩 50px 时会触发自动吸附，可以通过配置 radius 属性自定义触发吸附的距离。当 snap 设置为 false 时不会触发自动吸附。默认值为 false。
    snap: {
      radius: 16
    },
    // 当停止拖动边的时候根据 validateEdge 返回值来判断边是否生效，如果返回 false, 该边会被清除。
    validateEdge: ({edge}) => {
      if (edge.target.cell && edge.source.cell) {
        return true;
      } else {
        return false;
      }
    }
  },
  freeze: {
    cellEditable : true,
    cellHighlightable : true,
  }
};

let [startTime, endTime] = [0, 0];

export const graphOptions = {
  graphConfig: graphOptionFree,
  // 自定义节点
  nodeConfig: {
    type: 'vue',
    attrs: {},
    nodeMap: customNodeMap,
    ports: {
      attrs: {
        circle: {
          strokeWidth: 1,
          r: 4,
        }
      },
      items: ['top', 'left', 'bottom', 'right']
    },
    highlight: {
      triggers: ['click'], // 除event以外触发高亮的方式
      type: 'selecting'
    }
  },
  edgeConfig: {
    // 对边的样式设置 or 注册新的边
    edgeMap: {
      edge: {
        dataEdit: true, // 如果边需要点击编辑设置label或者data，边的高亮触发方式不建议使用hover，hover增加的高亮工具层会导致事件无法穿透，若强行穿透则导致工具事件无法触发
        attrs: {
          line: {
            stroke: 'rgba(110, 129, 153, 1)',
            'stroke-width': 1,
            targetMarker: {
              name: 'classic',
            }
          }
        },
        data: {
          name: "branch",
          nodeType: "branch"
        }
      },
    },
    tools: [
      {
        name: 'vertices',
        args: {
          attrs: {
            r: 4,
            fill: '#ffffff',
            stroke: '#366BFC'
          }
        }
      },
      {
        name: 'source-arrowhead',
        args: {
          tagName: 'circle',
          attrs: {
            r: 4,
            stroke: '#366BFC',
            'stroke-width': 2,
            fill: 'white'
          }
        }
      },
      {
        name: 'target-arrowhead',
        args: {
          tagName: 'path',
          attrs: {
            d: 'M-10,6,2,0,-10,-6,-8,0',
            stroke: 'transparent',
            fill: '#366BFC'
          }
        }
      },
      {
        name: 'segments',
        args: {
          attrs: {
            width: 16,
            fill: '#366BFC'
          }
        }
      },
    ],
    labelConfig: {
      stickRightText: {
        markup: [
          {
            tagName: 'rect',
            selector: 'bg',
          },
          {
            tagName: 'text',
            selector: 'label'
          }
        ],
        attrs: {
          bg: {
            refWidth: '140%',
            refHeight: '140%',
            refX: '-20%',
            refY: '-20%',
            fill: '#ffffff',
            stroke: '#ffffff'
          },
          label: {
            fill: '#000000',
            fontSize: 12
          }
        },
        position: 0.5
      }
    },
    highlight: {
      triggers: ['click'],
      keepZIndex: true, // 默认false，即高亮时移动到最上层，取消高亮时移到最下层
      attrs: {
        line: {
          stroke: '#366bfc',
        }
      }
    }
  },
  lifeCycle: {
    graphInited() {
    },
    graphRenderStart() {
      startTime = Date.now();
    },
    graphRenderEnd() {
      endTime = Date.now();
    },
    cellCreated() {
    }
  },
  viewport:{
    type: 'center'
  }
};
