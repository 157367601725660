export default {
  'isales.specialday.title.speclistmanage.moveblacklists': 'Transferir lista negra',
  'isales.manual.task.survey.questiontotal': 'Total {0} preguntas',
  'isales.agent.button.search': 'Consultar',
  'isales.taskresult.title.chooseBeginTime': 'Por favor\, seleccione la hora de inicio',
  'isales.busi.result.title.description': 'Descripcion',
  'isales.manual.title.inputresult': 'Introduzca un resultado de llamada saliente',
  'isales.taskresult.title.retryTime': 'intentos de llamada',
  'isales.import.filter.message.busiResultIsNull': 'El resultado del negocio no puede estar vacío',
  'isales.appointment.title.task': 'Asociar tarea de llamada saliente',
  'isales.training.forwardNumber': 'Número de reenvío',
  'isales.file.message.SK.placeholder': 'Introduzca un SK',
  'isales.statistics.field.abandonmentRateDescription': 'Tarifa de abandono',
  'isales.tasktemplate.field.templateName': 'Nombre de la plantilla',
  'isales.taskstatistics.placeholder.calling': 'Llamando\: {0}',
  'isales.manualdata.title.call': 'Llamar',
  'isales.manual.title.result': 'Resultado',
  'isales.taskmanagement.title.createTask': 'Crear',
  'isales.agent.pageurls.success': 'Éxito',
  'isales.management.placeholder.calledNo': 'Ingrese un número llamado',
  'isales.datasourcemanagement.message.dbIp': 'El formato de la dirección IP de la base de datos es incorrecto',
  'isales.data.title.tableTitle': 'Lista de números',
  'isales.data.prop.anonymType.countError': 'Se puede definir un máximo de 10 atributos de tipo cifrado',
  'isales.blacklist.title.validityTime': 'Tiempo de validez (hora)',
  'isales.task.promotion.updateSuccess': 'La tarea de marketing multimedia se modifica con éxito',
  'isales.task.promotion.baseInfo': 'Informaci\u00f3n b\u00e1sica',
  'isales.file.title.rule.property.row': 'Columna de atributo',
  'isales.tasklogic.field.callturntype': 'Modo redondo',
  'isales.specialday.title.holidayName': 'Fecha especial Nombre',
  'isales.agent.label.maxWaitTimeSpan': 'El valor varía de 0 a 60000',
  'isales.appointment.message.adjustmentEmpty': 'No se selecciona ningún dato de llamada saliente reservado válido',
  'isales.tasklogic.field.callInterval': 'Intervalo (s) de llamada (s)',
  'isales.taskmanagement.field.answer': 'Texto de respuesta',
  'isales.import.message.failnum': 'Número de registros fallidos',
  'isales.task.message.startSuccess': 'La tarea de llamada saliente se inicia correctamente',
  'isales.appointment.title.createAppoint': 'Crear llamada saliente reservada',
  'isales.agent.message.agentMsgException': 'Cambie la contraseña inicial del agente y sincronice la contraseña con el agente',
  'isales.file.title.username': 'Nombre de usuario',
  'isales.file.message.rule.success': 'La regla se modifica correctamente',
  'isales.specialday.message.nameError': 'El nombre de fecha especial tiene un formato incorrecto',
  'isales.taskmanagement.title.batchStartTask': 'Inicio por lotes',
  'isales.taskmanagement.field.quotas': 'Límite superior',
  'isales.taskmanagement.field.Automatic': 'Llamada saliente automática',
  'isales.survey.querydetail': 'Resultado de la encuesta',
  'isales.import.message.repeatnum': 'Número de registros duplicados',
  'isales.file.title.delete.fail': 'Error al eliminar la información del servidor',
  'isales.agent.message.skillConfFail': 'Error al configurar la cola de habilidades',
  'isales.manual.field.callingtask': 'Tarea de llamada',
  'isales.task.promotion.promotionType': 'Tipo de medios',
  'isales.taskresult.title.choosebusiresult': 'Por favor\, seleccione el resultado del negocio',
  'isales.common.message.checkpositiveinteger': 'Introduzca un entero positivo en el formato correcto',
  'isales.datasourcemanagement.field.backdbpass': 'Volver DataSource de PassWord',
  'isales.taskresult.field.notCheckIn': 'El agente no ha iniciado sesión',
  'isales.data.prop.isPushAgent.countError': 'Se puede definir un máximo de 15 atributos que se enviarán a un agente',
  'isales.manual.message.lock.success': 'Los datos de la llamada saliente se bloquean correctamente',
  'isales.file.title.serverInformation': 'Información del servidor',
  'isales.taskresult.field.failsFailedRouteSkillQueue': 'Error al enrutar la cola de habilidades',
  'isales.work.message.dategt7days': 'El intervalo de tiempo no puede exceder de siete días',
  'isales.taskinfo.title.accesscodedesc': 'Descripción del código de acceso',
  'isales.management.message.taskIdEmpty': 'El indicador de tarea de llamada saliente está vacío',
  'isales.datasourcemanagement.field.backdbdriver': 'Controlador DataSource trasero',
  'isales.management.message.templateAnalyse': 'Por plantilla',
  'isales.file.message.AK.placeholder': 'Introduzca un AK',
  'isales.agent.title.skillModify': 'Modificar cola de habilidades',
  'isales.taskinfo.message.batchUpdate.paramsNull': 'Edite el parámetro que se va a modificar',
  'isales.specialday.field.Wednesday': 'Miércoles',
  'isales.taskmanagement.field.taskName': 'Nombre de tarea',
  'isales.taskmanagment.title.obs.viewstatistics': 'Monitoreo de datos obtenidos por OBS',
  'isales.business.result.searchChildrenName': 'Introduzca un nombre de resultado de subempresa',
  'isales.task.message.NoPauseTask': 'No hay ninguna tarea que cumpla las condiciones disponible',
  'isales.blacklist.placeholder.restrictTimes': 'Introduzca el número máximo de llamadas',
  'isales.agent.field.newpassword': 'Contraseña nueva',
  'isales.agent.message.refreshSkillException': 'Error al actualizar la cola de habilidades',
  'isales.task.promotion.sendLimit': 'Límite de envío diario',
  'isales.taskstatistics.message.Unit.Piece': 'Unidad\: número',
  'isales.taskresult.title.userAccount': 'Iniciado por',
  'isales.task.promotion.deleteSuccess': 'La tarea de marketing multimedia se elimina correctamente',
  'isales.specialday.message.weeklyRepeat': 'Duplicar el período de fechas especiales',
  'isales.file.title.bucket': 'Cucharón',
  'isales.taskstatistics.field.feedBackStatistics': 'Estadísticas de comentarios',
  'isales.taskinfo.message.input': 'Caracteres no válidos',
  'isales.management.title.setResultCode': 'Por favor\, establezca el código de resultado',
  'isales.agent.contact.clicktodial': 'haga clic para llamar',
  'isales.specialday.field.byDate': 'Por fecha',
  'isales.taskinfo.field.audioAgentType': 'Audiencias',
  'isales.taskinfo.field.videoAgentType': 'Vídeos',
  'isales.taskinfo.field.mediaAgentType': 'Multimedia',
  'isales.taskinfo.field.versatileAgentType': 'Versátil',
  'isales.taskresult.field.failsNotintheservicearea': 'El teléfono está fuera de servicio',
  'isales.file.title.editserver': 'Modificar servidor',
  'isales.agent.label.information': 'Información de contacto',
  'isales.import.message.template.dataNum': 'Se puede importar un máximo de {0} registros de datos a la vez',
  'isales.file.title.SK': 'SK',
  'isales.taskstatistics.field.notcompletedcalls': 'Pendiente',
  'isales.specialday.field.Sunday': 'Domingo',
  'isales.taskcalllogic.message.typeConflictWhenDataTypeSame': 'Existe una directiva cuyo tipo de número es el mismo que el de la directiva existente\, pero se configuran directivas diferentes en función del resultado',
  'isales.training.startTraining.exists': 'Entrenamiento en curso. Por favor\, inténtelo de nuevo después de completar el entrenamiento',
  'isales.result.displayResult': 'Causa de error de llamada saliente',
  'isales.tasklogic.field.isResultCallnoLogic': 'Si se debe configurar en función del resultado',
  'isales.training.callTimeSegment': 'Segmento de tiempo de llamada',
  'isales.tasklogic.field.numberorder': 'Número de secuencia',
  'isales.taskinfo.title.chooseSkill': 'Seleccionar cola de habilidades',
  'isales.work.title.reservedCalls': 'Llamadas reservadas',
  'isales.task.promotion.promotionPolicy': 'Política de comercialización',
  'isales.manual.message.lock.failed': 'Error al bloquear los datos de la llamada saliente',
  'isales.result.outgoingCallResult': 'Resultado de llamada saliente',
  'isales.import.filter.message.busiSubStatusSame': 'Los resultados de sub-negocios bajo el mismo resultado de negocio no pueden ser los mismos',
  'isales.agent.calledroute.fail': 'Fallo',
  'isales.task.title.callpolicy': 'Política de llamadas salientes',
  'isales.tasktype.field.predict': 'Llamada saliente predicha',
  'isales.blacklist.message.customer.notincall': 'El cliente no está en la llamada',
  'isales.data.title.edit': 'Editar',
  'isales.data.title.undoEdit': 'Deshacer Editar',
  'isales.skillqueue.agentrole.qc': 'Inspector de calidad',
  'isales.taskmanagement.title.batchPauseTask': 'Lote Suspender',
  'isales.taskmanagement.title.editLogic': 'Lógica de cambio',
  'isales.operlog.message.data.downloadfailfile': 'Descargar el archivo de datos fallido',
  'isales.taskresult.field.failsCalledpartybusy': 'La fiesta llamada está ocupada',
  'isales.taskmanagement.field.pickUpRuleLimit': 'Restricciones',
  'isales.taskresult.field.success': 'Exitoso',
  'isales.management.message.orderSequence': 'Orden ascendente',
  'isales.taskstatistics.field.callCompletionRate': 'Tasa de finalización de llamadas',
  'isales.outboundtime.title.set': 'Establecer el tiempo de llamada saliente',
  'isales.workbench.execution': 'Ejecución de llamadas salientes',
  'isales.management.title.adjustment.changeTime': 'Ajustado en',
  'isales.data.orderType.asc': 'Ascendente',
  'isales.file.message.rule.columnRepeat': 'La columna de ID de llamada saliente\, la columna de número\, la columna de categoría\, la columna de intervalo de llamada y la columna de atributo no pueden ser iguales',
  'isales.repeatfile.export.repeatline': 'línea de repetición',
  'isales.agent.contact.Voice': 'Voz',
  'isales.taskresult.title.failCode': 'La causa',
  'isales.datasourcemanagement.field.backdbpass2': 'Confirmar Volver DataSource de PassWord',
  'isales.taskinfo.message.callBackUrl': 'Introduzca una dirección de devolución de llamada',
  'isales.manual.message.call.success': 'La llamada saliente se realiza correctamente',
  'isales.task.message.numberNotcalled': 'Registros fallidos\: {0}',
  'isales.agent.label.busy': 'Cola ocupada o desbordamiento',
  'isales.task.promotion.basicInfo': 'Información básica',
  'isales.taskresult.field.failsCallrejected': 'La llamada se rechaza',
  'isales.agent.label.release': 'Lanzamiento',
  'isales.task.promotion.sendGateway': 'Pasarela',
  'isales.file.title.addserver': 'Agregar servidor',
  'isales.taskmanagement.field.calledNo': 'Número Llamado',
  'isales.import.message.result.tip': 'Error al exportar el resultado de la selección diferencial',
  'isales.task.promotion.isExp': 'La tarea de marketing multimedia ha caducado',
  'isales.taskresult.field.failsInvalidUser': 'Usuario no válido',
  'isales.file.title.addrule': 'Añadir regla',
  'isales.agent.label.skillpara': 'Configuración de parámetros',
  'isales.task.promotion.invalidation': 'Inválido',
  'isales.calledroute.title.selectivrflow': 'Elige un robot inteligente',
  'isales.taskmanagment.message.chartDataError': 'Error al cargar el gráfico',
  'isales.taskmanagement.message.timeerror': 'La hora de inicio no puede ser posterior a la hora de finalización',
  'isales.taskcalllogic.field.notServiceArea': 'El cliente no está en el área de servicio',
  'isales.specialday.title.createspecialday': 'Crear fecha especial',
  'isales.taskmanagement.message.queryTime': 'Introduzca un tiempo completo',
  'isales.taskmanagement.title.batchEditCallPolicy': 'Política de modificación de lote de llamadas salientes',
  'isales.file.title.import.file.invalid': 'Error en la verificación del archivo',
  'isales.import.message.numberTooMany': 'Se puede cargar un máximo de 50000 números',
  'isales.specialday.message.enterworkno': 'Ingrese un ID de agente',
  'isales.management.placeholder.commonIvrFlowAccessCode': 'Introduzca un código de acceso de flujo IVR común',
  'isales.training.surveyScore': 'Puntuación total de la encuesta',
  'isales.tasklogic.field.callturntype.numbertype': 'Por tipo de número',
  'isales.taskresult.field.failsModemorfax': 'Se detecta un módem o fax',
  'isales.taskresult.field.failsResulttimesoutafterpicksup': 'El resultado de la detección de la llamada de salida después de la desconexión del ACD se agota',
  'isales.notification.receiver.number': 'Número de Destinatario',
  'isales.data.prop.mandatory': 'Obligatorio',
  'isales.common.title.dataReset': 'Restablecer',
  'isales.taskinfo.title.chooseAgent': 'Seleccionar agente',
  'isales.manualData.message.noPickCondition': 'Por favor\, establezca condiciones',
  'isales.taskstatistics.field.failCount': 'Número de llamadas fallidas',
  'isales.work.title.serviceAccount': 'Cuenta de Negocio',
  'isales.data.title.importResult': 'Importar resultados',
  'isales.management.message.filter': 'Filtrar datos',
  'isales.work.title.callTimeTotal': 'Duración total de la llamada (s)',
  'isales.agent.calledroute.extcodewarn': 'Los códigos de extensión solo pueden contener números',
  'isales.taskresult.field.no': 'No',
  'isales.blacklist.message.confirmOpt': 'Asegúrese de operar el número de lista especial seleccionado',
  'isales.task.promotion.all': 'Todo',
  'isales.taskpolicy.field.minChangeTime': 'Mín. Duración de la estancia',
  'isales.management.title.adjustment.destAccountName': 'Nuevo Ejecutor',
  'isales.manutask.title.viewresult': 'Resultado de llamada saliente',
  'isales.common.title.edit': 'Editar',
  'isales.taskcalllogic.message.maxPolicyNo': 'Se permite un máximo de cinco tipos de números no predeterminados',
  'isales.common.title.save': 'Guardar',
  'isales.management.title.editLogic': 'Lógica de cambio',
  'isales.taskresult.field.failsSsit': 'Tono para enviar información dedicada',
  'isales.manualdata.title.order': 'Reservar llamadas salientes',
  'isales.taskmanagement.title.endtime': 'Hora de finalización',
  'isales.data.prop.isPushAgent.anonymTypeError': 'Los atributos de tipo de cifrado no se pueden enviar a un agente',
  'isales.task.field.callFlagProgress': 'Ejecutando',
  'isales.training.Questionnaire': 'Cuestionario de capacitación',
  'isales.appointment.message.notInWhiteList': 'El número no está en la lista de confianza y no se puede llamar',
  'isales.file.title.rule.basic': 'Información básica',
  'isales.agent.calledroute.success': 'éxito',
  'isales.taskinfo.message.batchEditCallLogic.differentCallModeExist': 'Las lógicas de llamadas salientes no se pueden modificar en lotes para las tareas con modos de llamada diferentes',
  'isales.work.title.outboundCallTask': 'Tarea de llamada saliente',
  'isales.work.title.multimediaManage': 'Gestión de marketing multimedia',
  'isales.manual.message.task.isnorunning': 'La tarea de llamada saliente actual no está en el estado de ejecución',
  'isales.management.message.blackCalledNo': 'El número llamado está en la lista de bloqueo y no se puede agregar',
  'isales.business.result.children': 'Subestatus',
  'isales.taskresult.field.failsUnknownError': 'Error desconocido',
  'isales.marketing.notification': 'Marketing Multimedia',
  'isales.agent.contact.audioclicktodial': 'Clic para marcar por voz',
  'isales.outboundtime.title.endTime': 'Hora de finalización',
  'isales.taskresult.field.failsFailedAgentIsBusy': 'El agente no está en estado inactivo',
  'isales.file.title.rule.data': 'Número de datos',
  'isales.taskpolicy.field.connRateMethod': 'Algoritmo para la tasa de conexión de llamadas salientes',
  'isales.work.title.completeNum': 'Encuestas completadas',
  'isales.busi.result.message.lastrecord': 'Pleanse establece primero el resultado de negocio del último saliente',
  'isales.manual.title.viewPredictionResult': 'Resultado de llamada saliente predicho-',
  'isales.calledroute.button.finish': 'Finalizar',
  'isales.taskmanagement.title.deleteSuccess': 'La tarea se elimina correctamente',
  'isales.management.message.sessionIdFormatIncorrect': 'El identificador de sesión solo puede contener dígitos',
  'isales.agent.label.operate': 'Operación',
  'isales.manual.field.canBeCallCount': 'Llamadas disponibles',
  'isales.task.title.complete': 'Finalizar',
  'isales.file.title.port.placeholder': 'Introduzca el número de puerto del servidor',
  'isales.enum.workSpace': 'Área de trabajo saliente',
  'isales.manual.message.data.checklock.error': 'Los datos de llamada saliente actuales no están bloqueados o han sido bloqueados por otro agente',
  'isales.task.promotion.isSendNow': 'Enviar inmediatamente',
  'isales.management.message.createSuccess': 'Los datos de la llamada saliente se crean correctamente',
  'isales.taskresult.field.failsSittone': 'Tono de información especial',
  'isales.blacklist.title.deleteSuccess': 'Éxito',
  'isales.taskresult.field.failsUserrejection': 'La llamada se rechaza',
  'isales.management.message.filterNo': 'No',
  'isales.task.promotion.endTime': 'Hora de finalización',
  'isales.manual.message.releaseData': 'Seleccione los datos de llamadas salientes que desea liberar',
  'isales.notification.receiver.address.placeholder': 'Introduzca la dirección del destinatario',
  'isales.audio.title.audiotime': 'Duración (HH\:mm\:ss)',
  'isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist': 'Las lógicas de llamadas salientes no se pueden modificar en lotes para las tareas con diferentes tipos de directivas',
  'isales.agent.field.curcountpassword': 'Contraseña actual',
  'isales.sendStatus.field.success': 'Exitoso',
  'isales.task.promotion.receiver': 'Destinatario',
  'isales.agent.label.skillQueue': 'Cola de habilidades\:',
  'isales.agent.label.accountName': 'Nombre del agente',
  'isales.taskresult.message.details': 'Detalles',
  'isales.notification.receiver.delete.variable': 'Configure el atributo',
  'isales.training.sampleTotal': 'Tamaño total de la muestra',
  'isales.manual.field.callednumber': 'Número Llamado',
  'isales.agent.calledroute.create.media.match': 'El código de acceso al sistema configurado por la parte llamada es inconsistente con el tipo de medios del dispositivo configurado por la parte llamada',
  'isales.task.promotion.expectedTime': 'Tiempo de envío esperado',
  'isales.skill.label.skilldesc': 'Descripcion',
  'isales.agent.label.agentWeight': 'Peso del agente',
  'isales.taskmanagement.field.intention': 'Intención reconocida',
  'isales.blacklist.message.customer.empty': 'No hay cliente en la llamada',
  'isales.agent.calledroute.skill': 'cola de habilidades',
  'isales.taskcalllogic.message.dataTypeExist': 'DataType ya existe',
  'isales.agent.message.syncPwdFailed': 'Error al sincronizar la contraseña del agente',
  'isales.taskresult.field.failsFailedRouteCallToAttendant': 'Error al transferir la llamada al agente',
  'isales.tasksample.message.agentEmpty': 'No se ha seleccionado ningún objeto de entrenamiento',
  'isales.agent.label.playVoice': 'Jugar el tono de espera',
  'isales.tasklogic.field.turn': 'Redondo',
  'isales.datasourcemanagement.field.backDbdriver': 'Controlador DataSource trasero',
  'isales.datasourcemanagement.field.backdbType': 'Tipo de DataSource trasero',
  'isales.agent.message.saveSuccess': 'La configuración del agente se guarda correctamente',
  'isales.file.title.rule.desc.placeHolder': 'Introduzca una descripción de regla',
  'isales.management.message.createTaskstatusError': 'No se pueden crear datos para la tarea de llamada saliente porque no está en estado borrador o suspendido',
  'isales.data.prop.propCodeExists': 'El código de atributo ya existe',
  'isales.tasklogic.message.busiresultpolicyIncomplete': 'La información de la política de resultados de negocio está incompleta',
  'isales.management.title.import': 'Importar datos de llamadas salientes',
  'isales.business.result.delete.error': 'No se selecciona ningún resultado de negocio',
  'isales.file.title.rule.validator.server': 'La configuración del servidor es incorrecta',
  'isales.specialday.field.Friday': 'Viernes',
  'isales.data.prop.status.published': 'Publicado',
  'isales.manual.message.title': 'Introduzca un título',
  'isales.speclist.blacklist.message.moveSuccess': 'Número transferido a la lista negra correctamente',
  'isales.taskresult.field.failsRecordcanceled': 'Cuando el OBS cambia al estado de espera\, se cancelan los registros en curso',
  'isales.datasourcemanagement.message.frequentOperation': 'Operaciones frecuentes. Por favor espere',
  'isales.management.message.notification.download': 'Descargar plantilla de datos de marketing',
  'isales.datasourcemanagement.field.backdbUserName': 'Volver DataSource de UserName',
  'isales.speclistmanage.title.calledNo': 'Número de teléfono',
  'isales.business.result.search': 'Buscar',
  'isales.taskpolicy.message.saveSuccess': 'La directiva de llamadas salientes se configura correctamente',
  'isales.busi.result.item.unset': 'No establecido',
  'isales.import.message.upload.fail': 'Error al cargar el archivo',
  'isales.work.title.callTimeAvg': 'Duración (s) promedio de llamadas',
  'isales.data.prop.status': 'Estado',
  'isales.manual.message.call.update.error': 'Error al actualizar el resultado de la llamada saliente',
  'isales.common.title.operation': 'Operación',
  'isales.business.result.edit': 'Modificar resultado de negocio',
  'isales.specialday.title.editSpecialday': 'Editar fecha especial',
  'isales.agent.label.waitVoice': 'Tono de espera',
  'isales.speclistmanage.title.operation': 'En funcionamiento',
  'isales.appointment.message.callFail': 'Error',
  'isales.taskresult.field.failsAgentdevicetimesout': 'El tiempo de ocupación del agente se agotó',
  'isales.task.message.feedBackRate': 'Tasa de comentarios',
  'isales.management.message.calledNoOneCategory': 'Categoría de primer número llamado',
  'isales.taskresult.field.failsNoanswer': 'La parte llamada no responde a la llamada',
  'isales.data.message.addNumberUpperLimit': 'Se puede añadir un máximo de 10 números',
  'isales.file.title.rule.name': 'Nombre de regla',
  'isales.marketing.OutgoingCall': 'Telemarketing',
  'isales.tasktemplate.message.saveFail': 'Error al configurar la plantilla de llamada saliente',
  'isales.data.prop.propType': 'Tipo de atributo',
  'isales.taskresult.title.inputSessionId': 'Ingrese el número de sesión',
  'isales.task.promotion.pauseFaied': 'Error al suspender la tarea de marketing multimedia',
  'isales.calledroute.button.cancel': 'Cancelar',
  'isales.tasktype.field.intelligence': 'Llamada saliente inteligente',
  'isales.business.result.code': 'Código de resultado de negocio',
  'isales.taskresultexport.message.downloadExportdatafailinfo': 'Error en la verificación. No se pueden encontrar los datos del archivo exportado',
  'isales.taskmanagement.title.start': 'Iniciar',
  'isales.data.prop.createDataPropFail': 'Error al crear el atributo',
  'isales.management.title.adjustment.orgAccountName': 'Antiguo Ejecutor',
  'isales.datasourcemanagement.field.addDataSource': 'Añadir DataSource',
  'isales.taskstatistics.field.incomplete': 'Clientes en espera de llamadas',
  'isales.management.message.callNoInBlackList': 'No se puede llamar al número porque está bloqueado',
  'isales.callNo.field.type': 'Tipo de número',
  'isales.tasktype.field.auto': 'Llamada saliente IVR común',
  'isales.task.promotion.messageTemplate': 'Plantilla de notificación',
  'isales.tasklogic.field.substate': 'Subestado',
  'isales.specialday.title.deleteSuccess': 'Exitoso',
  'isales.agent.message.deleteSkillConfFail': 'Error al eliminar la información de la cola de habilidades',
  'isales.operlog.message.data.downloadtemplate': 'Descargar plantilla de importación de datos',
  'isales.agent.button.batchmodify': 'Cambio de lote',
  'isales.management.message.deleteTaskstatusError': 'No se puede eliminar la tarea de llamada saliente porque no está en el estado borrador o en el estado de pausa',
  'isales.task.promotion.selectSendTemplate': 'Seleccione una plantilla',
  'isales.taskresult.field.failsCpp': 'Llamado reenvío por enganche',
  'isales.taskresult.field.failsAns5': 'Teléfono de texto (ANS5)',
  'isales.taskresult.field.failsAns4': 'Teléfono de texto (ANS4)',
  'isales.taskresult.field.failsAns3': 'Teléfono de texto (ANS3)',
  'isales.taskresult.field.failsAns2': 'Teléfono de texto (ANS2)',
  'isales.taskresult.field.failsAns1': 'Teléfono de texto (ANS1)',
  'isales.taskinfo.message.batchEditCallLogic.selectEmpty': 'La operación no es válida porque no hay ninguna región seleccionada. Seleccione una región',
  'isales.operlog.message.downloadSurveyExportFile': 'Exportación de preguntas de capacitación',
  'isales.taskinfo.field.description': 'Descripción de la tarea',
  'isales.task.promotion.startSuccess': 'La tarea de marketing multimedia se publica con éxito',
  'isales.datasourcemanagement.field.vdnId': 'VDNID',
  'isales.agent.message.modifyPwdFailed': 'Error al cambiar la contraseña del agente',
  'isales.agent.message.queryCallCenterFail': 'Error al consultar los datos del centro de llamadas',
  'isales.taskmanagement.title.clicktorefresh': 'Haga clic para actualizar',
  'isales.taskstatistics.message.no.busiResult': 'No se marca ningún resultado comercial',
  'isales.blacklist.message.updateSuccess': 'Modificación correcta de la observación',
  'isales.data.filed.noResult': 'No',
  'isales.management.title.callbot': 'Chatbot de llamadas salientes',
  'isales.blacklist.message.paramEmpty': 'Seleccione un número de la lista de bloqueos',
  'isales.task.statistics.obs.endTime': 'La diferencia entre la hora de inicio y la hora de finalización no puede exceder de media hora',
  'isales.taskmanagement.title.pauseCount': 'Se puede suspender un máximo de 30 tareas de llamadas salientes a la vez',
  'isales.task.promotion.saveFailed': 'Error al guardar la tarea de marketing multimedia',
  'isales.taskpolicy.message.input.minAvgCallTime': 'La duración media mínima de la llamada no puede ser mayor que la máxima. Duración promedio de llamadas',
  'isales.manual.field.callflag.return': 'Atrás',
  'isales.operlog.message.data.downloadrepeatfile': 'Descargar archivo de datos duplicados',
  'isales.manual.message.release.failed': 'Error al liberar los datos de la llamada saliente',
  'isales.taskmanagement.field.zip.input.password': 'Ingrese al menos ocho caracteres y un máximo de doce caracteres que contengan dígitos\,  letras y caracteres especiales\, pero que no contengan /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'isales.file.title.SK.again': 'Confirmar SK',
  'isales.management.message.black.download': 'Descargar una plantilla de lista de bloques',
  'isales.taskInfo.message.skillAlreadyExists': 'La cola de habilidades ya existe',
  'isales.agent.label.maxAgentWrapupTime': 'El valor varía de 0 a 3600',
  'isales.file.title.AK': 'AK',
  'isales.notification.receiver.tips': 'El destinatario debe ser único',
  'isales.agent.addblocklist.calldatatype': 'Tipo de cliente',
  'isales.taskpolicy.message.rangeAutoCallTime': 'El valor debe oscilar entre {0} y {1}',
  'isales.manual.field.result.success': 'Exitoso',
  'isales.taskinfo.message.endTimeError': 'La hora de finalización debe ser posterior a la hora actual',
  'isales.work.message.agentOrTaskChange': 'Seleccione al menos una tarea de llamada saliente o un ID de agente',
  'isales.task.promotion.selectSendGateway': 'Seleccione una puerta de enlace',
  'isales.import.filter.button.readyCall': 'Filtrar datos de llamadas no iniciadas',
  'isales.taskresult.field.failsAgentDisconnection': 'El agente está desconectado',
  'isales.taskresult.field.failsInvalidDevice': 'Dispositivo no válido',
  'isales.repeatfile.export.repeatCalledNo': 'repeticiónLlamadoNo',
  'isales.taskmanagment.title.callMonitor.tips': 'Actualizado automáticamente cada 15 minutos',
  'isales.callquality.statistics.message.afterNow': 'No puede seleccionar una hora anterior a la hora de inicio de la tarea o posterior al día actual\!',
  'isales.taskinfo.title.ivrFlow': 'Chatbot inteligente',
  'isales.appointment.title.appointTime': 'Tiempo de llamada reservado',
  'isales.manual.message.release.success': 'Los datos de la llamada saliente se liberan correctamente',
  'isales.taskcalllogic.message.choosePolicy': 'Seleccione al menos una estrategia de procesamiento de resultados',
  'isales.business.result.message.add.tips': 'El resultado del negocio no está definido',
  'isales.taskresult.field.failsCalledOnHook': 'El agente se engancha en una llamada',
  'isales.taskresult.field.sipAccount': 'Número de Softphone',
  'isales.data.prop.editDataPropFail': 'Error al modificar el atributo',
  'isales.taskinfo.field.callNo': 'Número de llamada',
  'isales.notification.receiver': 'Destinatario',
  'isales.agent.button.restAgentPwd': 'Cambiar la contraseña del agente',
  'isales.work.title.totalOutgoingCalls': 'Llamadas salientes',
  'isales.management.message.SelectNumber': 'Seleccione un número',
  'isales.datasourcemanagement.message.noObsINFO': 'La llamada saliente DataSource no está configurada o incorrectamente configurada',
  'isales.taskresult.field.failsFsk': 'Teléfono de texto (FSK)',
  'isales.blacklist.message.deleteSuccess': 'El número se elimina correctamente de la lista de bloqueo',
  'isales.manual.task.survey.previousquestion': 'Pregunta anterior',
  'isales.agent.label.answerType': 'Modo de respuesta del agente',
  'isales.management.title.adjustment.remark': 'Comentarios',
  'isales.taskresult.field.failsModemdetected': 'Se detecta un módem',
  'isales.taskresult.field.failedtopause': 'No se puede suspender la tarea porque ya está en estado suspendido',
  'isales.import.message.closeExtProp': 'Los atributos personalizados deben estar deshabilitados para importar por plantilla. Haga clic en el botón Configurar atributo situado junto a Importar para deshabilitar y habilitar atributos personalizados',
  'isales.task.message.startFail': 'Error al iniciar la tarea de llamada saliente',
  'isales.tasktemplate.message.releasesuccess': 'Publicación exitosa',
  'isales.taskstatistics.field.totalNumberOfUsers': 'Número total de usuarios',
  'isales.tasklogic.field.default': 'Predeterminado',
  'isales.taskmanagement.field.outboundType': 'Tipo de llamada',
  'isales.agent.placeholder.agentdesc': 'Introduzca un nombre de agente',
  'isales.audio.content.calltype': 'Llamada saliente de voz',
  'isales.result.outgoingCall.failCode': 'Código de resultado',
  'isales.training.callTime': 'Segmento de tiempo de llamada',
  'isales.taskmanagement.title.startCount': 'Se puede iniciar un máximo de 30 tareas de llamada saliente a la vez',
  'isales.import.filter.button.notification.isPushCom': 'Filtrar los datos que se envían',
  'isales.task.promotion.remark': 'Causa de falla',
  'isales.agent.calledroute.createsuccess': 'añadido correctamente',
  'isales.taskcalllogic.busiresultpolicy.callnext': 'Llame al siguiente número',
  'isales.agent.message.warning': 'Advertencia',
  'isales.import.title.statusExecuting': 'Ejecutando',
  'isales.agent.message.modifySkillConfSuccess': 'La cola de habilidades se modifica correctamente',
  'isales.taskcalllogic.field.other': 'Otros',
  'isales.taskresultexport.title.downloadConfirm': 'OK',
  'isales.task.title.basicInfo': 'Información básica',
  'isales.task.field.callFail': 'Fallo',
  'isales.blacklist.title.restrictTimes': 'Limitar tiempos',
  'isales.taskresult.title.chooseCallFlag': 'Por favor\, seleccione el estado',
  'isales.agent.message.redislocknull': 'Error al adquirir el bloqueo de Redis',
  'isales.file.message.isDeleteFile': 'Eliminar archivo de origen',
  'isales.taskstatistics.field.calling': 'Llamar',
  'isales.calledroute.title.selectskill': 'Seleccionar cola de habilidades',
  'isales.manual.title.fail.reason': 'Causa de falla',
  'isales.specialday.field.byYear': 'Repetir por año',
  'isales.taskresult.field.failsDisconnection': 'Fallo de llamada',
  'isales.management.message.calledNoTwoCategory': 'Segunda categoría de número llamado',
  'isales.taskcalllogic.field.shutdown': 'Cierre del cliente',
  'isales.task.promotion.promotionStatus': 'Estado de la comercialización',
  'isales.specialday.title.holidayType': 'Tipo de fecha especial',
  'isales.taskcalllogic.busiresultpolicy.stopcall': 'Detener la llamada',
  'isales.taskmanagement.message.password.not.same': 'Las dos contraseñas son inconsistentes',
  'isales.failfile.export.failReason': 'FalloCausa',
  'isales.tasktemplate.message.pauseFail': 'La suspensión falló',
  'isales.task.promotion.retryTimes': 'Tiempos de reintento',
  'isales.taskmanagement.title.batchEditCallLogic': 'Lógica de llamada saliente de modificación por lotes',
  'isales.management.message.import.fileName': 'Nombre de archivo',
  'isales.file.message.deleteruletips': '¿Está seguro de que desea eliminar la regla de asignación de importación?',
  'isales.appointment.message.tasknotrun': 'No se inicia la tarea de llamada saliente actual. La llamada está prohibida',
  'isales.timeImport.message.fail': 'Error al agregar la configuración de importación de tiempo',
  'isales.agent.message.deleteSkill': 'Eliminar cola de habilidades',
  'isales.management.title.notification.import': 'Importar datos de marketing',
  'isales.specialday.field.beginDate': 'Fecha de inicio',
  'isales.task.message.timeIncompletes': 'El segmento de tiempo de una sola llamada saliente no está completo',
  'isales.taskresult.field.failsDtmf': 'Teléfono de texto (DTMF)',
  'isales.taskresultexport.message.endtime': 'Hora de finalización',
  'isales.taskcalllogic.message.resultTips': 'Solo puede haber una estrategia para un resultado',
  'isales.tasklogic.field.callbyorder': 'En secuencia',
  'isales.datasourcemanagement.field.dbdriver': 'Controlador de DataSource',
  'isales.taskresult.field.failsUserhangsupinthequeue': 'Todos los agentes están ocupados\, y el usuario cuelga proactivamente',
  'isales.taskmanagement.message.afterNow': 'La hora de inicio no puede ser posterior a la hora actual',
  'isales.data.prop.message.confirmDelete': '¿Está seguro de que desea eliminar el atributo seleccionado?',
  'isales.taskpolicy.field.multiSkill': 'Cola de habilidades híbridas',
  'isales.taskresult.field.failsWaitresulttimesout': 'Esperar a que los resultados de las llamadas salientes se agoten en la plataforma',
  'isales.training.surveydetails': 'Cuestionario Estadísticas Detalles',
  'isales.taskmanagement.title.edit': 'Editar',
  'isales.specialday.title.search': 'Consultar',
  'isales.taskresult.field.failsSubscriberbusy': 'La fiesta llamada está ocupada',
  'isales.survey.createTrainingTask': 'Creación de una tarea de formación',
  'isales.taskcalllogic.field.callFailed': 'Error de llamada',
  'isales.task.promotion.view': 'Ver Tarea de Marketing Multimedia',
  'isales.management.message.orderReverse': 'Orden descendente',
  'isales.specialday.placeholder.searchname': 'Nombre de fecha especial',
  'isales.taskresult.field.yes': 'Sí',
  'isales.outboundtime.title.period': 'Segmento de tiempo de llamada saliente',
  'isales.taskmanagment.title.callMonitor': 'Monitoreo de llamadas',
  'isales.training.answeringRecord': 'Registro de respuesta',
  'isales.blacklist.message.requestEmpty': 'Error al validar el parámetro para agregar el número a la lista de bloques',
  'isales.taskmanagment.callquality.maxConnectedRate': 'Velocidad máxima de conexión',
  'isales.task.message.pauseFail': 'Error al suspender la tarea de llamada saliente',
  'isales.taskresultexport.title.download': 'Descargar',
  'isales.task.field.callFlagExecuted': 'Para ser ejecutado',
  'isales.data.prop.createDataPropSuccess': 'El atributo se crea correctamente',
  'isales.file.title.rule.number': 'Columna de número',
  'isales.taskresult.field.failsAcdtimeout': 'El ACD se agotó',
  'isales.taskinfo.title.skillName': 'Cola de habilidades',
  'isales.label.appraise.call.quality.agent': 'agente',
  'isales.specialday.message.deleteSuccess': 'La fecha especial se elimina correctamente',
  'isales.manualdata.title.release': 'Lanzamiento',
  'isales.work.title.surveyAnswerNum': 'Número de respuestas',
  'isales.blacklist.title.day': 'Todos los días',
  'isales.agent.calledroute.nmsdata.error': 'La sincronización del ODFS falla\, y los datos de configuración de la parte llamada ODFS son inconsistentes con los datos locales del CCM',
  'isales.taskstatus.field.waiting': 'Esperando',
  'isales.task.message.saveFail': 'Error al configurar la tarea de llamada saliente',
  'isales.exportdata.title.fail': 'Error',
  'isales.tasklogic.field.enablebusiresultpolicy': 'Habilitar la política de resultados de negocio',
  'isales.taskresult.field.failsNoresponse': 'La parte llamada no responde',
  'isales.statistics.field.addHisYes': 'Sí',
  'isales.file.title.editrule': 'Modificar regla',
  'isales.failfile.export.failline': 'Línea de falla',
  'isales.taskinfo.message.agentsEmpty': 'No se ha seleccionado ningún agente',
  'isales.blacklist.title.remark': 'Comentarios',
  'isales.manual.message.error': 'Se ha producido un error al actualizar los datos',
  'isales.tasklogic.title.addCallnoPolicy': 'Añadir política de resultados',
  'isales.management.message.deleteFailed': 'Error al eliminar los datos de la llamada saliente',
  'isales.manual.title.viewPreviewResult': 'Vista previa Resultado de llamada saliente-',
  'isales.basicinfo.title.chooseCallNo': 'Seleccione el número de llamadas',
  'isales.common.title.cancel': 'Cancelar',
  'isales.speclistmanage.title.reason': 'Motivo',
  'isales.calledroute.title.selectagent': 'Seleccionar agente',
  'isales.agent.label.description': 'Descripcion',
  'isales.business.description': 'Descripcion',
  'isales.management.title.editPolicy': 'Modificar directiva',
  'isales.tasklogic.message.busiresultpolicyNoadded': 'No se agrega ninguna política de resultados de negocio',
  'isales.taskmanagement.title.addManual': 'Crear llamada saliente de agente',
  'isales.taskresult.field.failsUnallocatedOrIncorrectNumber': 'Número inalcanzable o incorrecto',
  'isales.notification.configVariableTe': 'Configurar variable de plantilla',
  'isales.notification.config': 'Configuración',
  'isales.manual.field.callflag.finish': 'Finalizado',
  'isales.taskmanagement.title.batchEditTaskInfo': 'Información básica de modificación por lotes',
  'isales.taskcalllogic.field.callTimeout': 'Tiempo de espera de llamada',
  'isales.data.title.importData': 'Importar',
  'isales.tasklogic.field.busiresultpolicy': 'Política de resultados comerciales',
  'isales.management.message.deleteCount': 'Se puede eliminar un máximo de 100 registros de llamadas salientes a la vez',
  'isales.callNo.field.add': 'Añadir',
  'isales.skillcreate.maxnumber.failed': 'El número de colas de habilidades del inquilino no puede exceder el límite superior',
  'isales.busi.result.item.uncertain': 'Incierto',
  'isales.business.result.deleteChildrenTips': '¿Está seguro de que desea eliminar el resultado de sub-negocio?',
  'isales.task.title.nextStep': 'Siguiente',
  'isales.task.promotion.sender': 'Remitente',
  'isales.agent.label.parameterType': 'Modo de cola',
  'isales.blacklist.message.name.reg.empty': 'La regla regular del nombre está vacía. Póngase en contacto con el administrador para agregarlo',
  'isales.taskmanagement.field.taskTime': 'Duración',
  'isales.file.title.password.again': 'Confirmar contraseña',
  'isales.file.title.rightpath': 'Introduzca una ruta válida\, por ejemplo\, /isales/upload',
  'isales.training.tip2': 'La pregunta está siendo entrenada. No haga clic con frecuencia',
  'isales.training.tip1': 'El número de respuestas ha alcanzado el límite superior',
  'isales.taskresult.field.userisunleashed': 'Se libera la llamada en el servidor de medios en el que el agente inicia sesión',
  'isales.management.placeholder.accesscodeDesc': 'Por favor\, introduzca un código de acceso descripción',
  'isales.agent.addblocklist.numbertype': 'Tipo de número',
  'isales.agent.calledroute.search.devicedesc': 'Introduzca un nombre de dispositivo',
  'isales.file.server.message.notChinese': 'Los caracteres chinos no están permitidos',
  'isales.taskresult.field.failsNoreason': 'Transferencia de DTE incondicional',
  'isales.training.queryQuestionnaire': 'Consulta de Cuestionario',
  'isales.management.title.selectProp': 'Seleccionar atributo',
  'isales.calledroute.message.selectcalledroute': 'Seleccione la configuración llamada',
  'isales.manualData.message.pickUpFailed': 'No hay datos que extraer',
  'isales.specialday.title.speclistmanage.misjudgment': 'Juzgar mal',
  'isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate': 'Después de cambiar el número de directivas numéricas no predeterminadas en la directiva de resultados de llamadas salientes\, se excede el máximo de cinco',
  'isales.taskresult.field.failsUnreachable': 'Abonado móvil inalcanzable',
  'isales.taskmanagement.title.callRecord': 'Registro de llamadas salientes',
  'isales.outboundtime.field.hour': 'Horario',
  'isales.tasktype.field.automatic': 'Llamada saliente automática',
  'isales.taskinfo.message.batchEditCallLogic.BusiResultNotification': 'Las nuevas políticas sobrescribirán las existentes',
  'isales.taskpolicy.field.failInterval': 'Intervalos de llamadas salientes',
  'isales.agent.calledroute.createfail': 'Error al agregar datos locales',
  'isales.taskcalllogic.field.globalPolicy': 'Política global',
  'isales.taskmanagement.title.tipsBatchStartTask': 'Seleccione la tarea que desea iniciar',
  'isales.specialday.title.edit': 'Editar',
  'isales.taskresultexport.message.downloadFail': 'Error de descarga',
  'isales.file.message.isDeleteFileTips': 'Eliminar archivos de la OBS',
  'isales.agent.label.agentList': 'ID de agente',
  'isales.taskcalllogic.busiresultpolicy.callcurrent': 'Llamar al número actual',
  'isales.task.promotion.createTime': 'Creado en',
  'isales.result.create': 'Causa de error de creación de llamada saliente',
  'isales.label.appraise.call.quality.customerInfo': 'Información del cliente',
  'isales.appointment.message.adjustmentSuccess': 'La llamada saliente reservada se ajusta correctamente',
  'isales.note.config.success': 'éxito',
  'isales.manual.task.survey.answered': 'Respuestas',
  'isales.management.message.deleteSuccess': 'Los datos de la llamada saliente se eliminan correctamente',
  'isales.management.message.calledNo': 'Número Llamado',
  'isales.agent.calledroute.accesscodewarn': 'Los códigos de acceso solo pueden contener números',
  'isales.management.message.sessionId': 'Número de sesión',
  'isales.agent.query.emptyError': 'No se selecciona ningún dato de agente válido',
  'isales.management.title.editTime': 'Hora de cambio',
  'isales.skill.label.maxwaitnum': 'Máx. llamadas en cola',
  'isales.data.propConfig.message.isExistBackDb': 'Volver DataSource',
  'isales.callNo.field.delayTime': 'Tiempo de retardo de fallo de llamada (s)',
  'isales.common.title.fail': 'Error',
  'isales.specialday.title.timePeriod': 'Fecha especial Hora de llamada saliente',
  'isales.taskresult.field.failsCallwaiting': 'Llamada en espera',
  'isales.blacklist.placeholder.searchname': 'Nombre',
  'isales.training.voiceAverageScore': 'Puntuación de voz promedio',
  'isales.blacklist.title.hour': 'Cada hora',
  'isales.tasktemplate.title.saveAsTemplate': 'Guardar como plantilla',
  'isales.tasktemplate.title.addAutoTemplate': 'Crear plantilla de llamada saliente automática',
  'isales.taskmanagement.title.inputTaskId': 'Introduzca un ID de tarea',
  'isales.result.deletetips': '¿Está seguro de que desea eliminar la causa del error de la llamada saliente?',
  'isales.taskmanagement.title.selectTemplate': 'Seleccionar plantilla',
  'isales.blacklist.message.calledno.error': 'El número contiene caracteres no válidos',
  'isales.management.message.upload': 'Cargar',
  'isales.taskmanagement.field.prediction': 'Llamada saliente predicha',
  'isales.operlog.message.downloadAgentWorkExportFile': 'Descargar archivo de exportación de carga de trabajo del agente',
  'isales.taskresultexport.message.downloadFilesuffixfailinfo': 'Error en la descarga. La información del archivo exportado no contiene la extensión de nombre de archivo',
  'isales.data.prop.propCode': 'Código de atributo',
  'isales.skillqueue.button.add': 'Añadir cola de habilidades',
  'isales.specialday.message.typeError': 'Tipo de fecha especial incorrecto',
  'isales.data.prop.stringType.countError': 'Se puede definir un máximo de 30 atributos de tipo cadena de caracteres',
  'isales.taskresult.title.endTime': 'Hora de finalización',
  'isales.specialday.message.updateFail': 'Error al modificar la fecha especial',
  'isales.manual.task.survey.nextquestion': 'Siguiente pregunta',
  'isales.manual.task.survey.submit': 'Enviar',
  'isales.task.promotion.templateType': 'Tipo de plantilla',
  'isales.tasklogic.message.need.orderPolicy': 'Agregue al menos una regla de orden personalizada',
  'isales.manual.message.call.error': 'Error al procesar el resultado de la llamada saliente',
  'isales.taskmanagment.title.open.callMonitor': 'Monitoreo de llamadas',
  'isales.taskresult.field.failsCallednumbernotinwhitelist': 'El número no está en la lista de confianza de llamadas salientes',
  'isales.specialday.title.reset': 'Restablecer',
  'isales.specialday.message.dateParseError': 'Error al convertir el tipo de hora de la fecha especial',
  'isales.manual.task.survey.answerSuccess': '¡Respondido con éxito\!',
  'isales.management.message.calledNoEmpty': 'El número al que se llama está vacío',
  'isales.common.title.close': 'Cerrar',
  'isales.import.title.statusReady': 'Para ser ejecutado',
  'isales.taskresult.field.failsNoacdresource': 'No hay recursos disponibles en el ACD',
  'isales.management.message.createFailed': 'Error al crear datos de llamadas salientes',
  'isales.busi.result.message.recordsucc': 'El resultado del negocio se registra correctamente',
  'isales.file.title.create': 'Añadir',
  'isales.blacklist.title.restrictPeriod': 'Período de restricción',
  'isales.agent.message.modifyPwdSuccess': 'La contraseña del agente se ha cambiado correctamente',
  'isales.taskpolicy.field.pickupRateMethod': 'Algoritmo para velocidad de descolgado',
  'isales.datasourcemanagement.message.syncSuccess': 'DataSource de sincronización de éxito',
  'isales.import.message.uploadedfileNameformatvalidate': 'Nombre de archivo incorrecto. Seleccione un archivo cuyo nombre solo contenga caracteres chinos\, letras minúsculas\, dígitos o guiones bajos (_)',
  'isales.agent.calledroute.isconfdelete': '¿Está seguro de borrar?',
  'isales.taskmanagement.title.batchEditTaskInfo.manual': 'La llamada saliente automática y la llamada saliente manual no se pueden modificar al mismo tiempo',
  'isales.taskresult.title.busidesc': 'Descripción del negocio',
  'isales.agent.calledroute.placeholder.skill': 'Seleccione una cola de habilidades',
  'isales.taskpolicy.field.obOverTime': 'Max. Duración de la respuesta',
  'isales.tasktype.field.intelligent': 'Llamada saliente de robot inteligente',
  'isales.management.message.import.analyseMode': 'Modo de análisis',
  'isales.agent.calledroute.gateway.fail': 'Error al sincronizar los datos con la plataforma CTI. Compruebe la configuración de la puerta de enlace',
  'isales.management.message.ruleAnalyse': 'Por regla',
  'isales.taskmanagement.field.statisticsDate': 'Fecha de recopilación de estadísticas',
  'isales.taskinfo.title.chooseSurvey': 'Seleccionar Cuestionario',
  'isales.file.message.rule.dataIsNull': 'Los datos numéricos no pueden estar vacíos',
  'isales.appointment.message.agentNotCalling': 'No se puede agregar una llamada saliente reservada porque el agente no está en una llamada',
  'isales.management.message.addPropTips': 'Los datos personales o los datos confidenciales deben estar cifrados\, almacenados y enmascarados',
  'isales.taskmanagement.title.tipsBatchPauseTask': 'Seleccione la tarea que desea suspender',
  'isales.manual.message.empty': '{0} está vacío',
  'isales.training.MaxAnswerNumber': 'Número máximo de respuestas al cuestionario',
  'isales.specialday.message.dateFormatError': 'Formato de fecha especial incorrecto',
  'isales.file.message.endPoint.placeholder': 'Introduce una dirección',
  'isales.appointment.title.failCode': 'Causa de falla',
  'isales.datasourcemanagement.field.dsType.gauss': 'GAUSS',
  'isales.taskresult.field.failsCalldatatimeout': 'Tiempos de espera de los datos de llamadas',
  'isales.taskpolicy.field.utilRateMethod': 'Algoritmo para el uso del agente',
  'isales.taskpolicy.field.experienceMethod': 'Algoritmo para la predicción empírica',
  'isales.taskinfo.field.questionNumber': 'Número de preguntas',
  'isales.agent.calledroute.create.gateway.none': 'El script de enrutamiento al que hace referencia la configuración llamada no existe',
  'isales.blacklist.title.calledNo': 'Número Llamado',
  'isales.business.result.addchildrentips': 'Se permite un máximo de 50 resultados sub-negocios en un resultado de negocio',
  'isales.specialday.title.timeSet': 'Establecer fecha especial Hora de llamada saliente',
  'isales.sendStatus.field.all': 'Todo',
  'isales.taskstatus.field.callfinish': 'Finalizado',
  'isales.taskmanagment.title.viewstatistics': 'Ver estadísticas',
  'isales.audio.title.endtime': 'Hora de finalización',
  'isales.appointment.message.callTimeTooEarly': 'El tiempo de llamada es anterior al tiempo reservado. ¡La llamada está prohibida\!',
  'isales.agent.message.deleteSkillConfSuccess': 'La cola de habilidades se elimina correctamente',
  'isales.agent.label.answerMode': 'Modo de respuesta del agente',
  'isales.import.message.pleaseuploadfile': 'Seleccione un archivo',
  'isales.tasklogic.message.busiresultpolicyExists': 'Ya existe una política de resultados de negocio con el mismo resultado de negocio y subestado',
  'isales.management.message.filterYes': 'Sí',
  'isales.taskresult.field.agentRole': 'Nombre de rol de agente',
  'isales.blacklist.message.name.error': 'El nombre contiene caracteres no válidos',
  'isales.taskmanagement.title.editTime': 'Hora de cambio',
  'isales.taskresult.field.other': 'otros',
  'isales.taskresult.title.export': 'Exportación',
  'isales.import.filter.button.notification.noisPush': 'Filtrar los datos no enviados',
  'isales.black.filed.expTime': 'Duración de validez (opcional\, en horas)',
  'isales.task.promotion.message.dndTimeError': 'El período de no molestar es incompleto',
  'isales.manualdata.title.lock': 'Bloqueo',
  'isales.data.message.empty': '¡El número llamado no puede estar vacío\!',
  'isales.datasourcemanagement.field.backDbCnUrl': 'url de DataSource de atrás',
  'isales.business.code': 'Código',
  'isales.specialday.title.scoreDetail': 'Detalle de Puntuación',
  'isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist': 'Las lógicas de llamadas salientes no se pueden modificar en lotes para las tareas con diferentes modos de ronda de llamadas',
  'isales.manualdata.title.pickUp': 'Extracto',
  'isales.workbench.appointment.task': 'Tarea de reserva',
  'isales.management.title.updateData': 'Modificar datos de llamadas salientes',
  'isales.taskresult.field.operation': 'Operación',
  'isales.import.title.statusComplete': 'Finalizado',
  'isales.result.edit': 'Causa de error de modificación de la llamada saliente',
  'isales.tasklogic.field.callOrderMethod': 'Regla de pedido',
  'isales.taskmanagement.field.zip.password': 'Contraseña del paquete comprimido',
  'isales.tasktemplate.title.viewTemplate': 'Ver plantilla de llamada saliente',
  'isales.statistics.field.callCompletionRateDescription': 'Velocidad de conexión',
  'isales.specialday.title.warning': 'Advertencia',
  'isales.taskcalllogic.message.roundPolicyLengthTooLong': 'Después de cambiar el número de rondas en la política de llamada\, se excede la longitud máxima',
  'isales.appointment.message.updateFail': 'Error al modificar la llamada saliente reservada',
  'isales.specialday.title.chooseDate': 'Seleccionar fecha',
  'isales.taskmanagement.title.pause': 'Suspender',
  'isales.blacklist.title.createblacklist': 'Agregar número a la lista de bloqueo',
  'isales.taskresult.field.failsFak': 'La fiesta llamada es un fax',
  'isales.notification.configVariable': 'Establecer variable',
  'isales.import.filter.button.notification.isPush': 'Filtrar los datos enviados últimamente',
  'isales.taskresult.field.failsRecordswrittenback': 'Los registros fallidos se vuelven a escribir cuando se cierra la OBS (o se elimina una tarea)',
  'isales.tasktype.field.preview': 'Vista previa Llamada saliente',
  'isales.taskcalllogic.field.customerRefused': 'El cliente se negó',
  'isales.import.message.success': 'Importación de datos. Haga clic en Actualizar más tarde para obtener los datos más recientes.',
  'isales.management.placeholder.commonIvrName': 'Introduzca el nombre del proceso IVR común',
  'isales.taskinfo.field.priority': 'Prioridad',
  'isales.agent.message.addSkillFail': 'Error al agregar la cola de habilidades',
  'isales.manual.task.survey.unanswered': 'Sin respuesta',
  'isales.exportdata.title.success': 'Exitoso',
  'isales.tasktemplate.field.released': 'Publicado',
  'isales.taskmanagement.field.pickUpCondition': 'Condiciones Generales',
  'isales.notification.receiver.number.tips': 'El valor debe consistir en dígitos',
  'isales.blacklist.message.calledno.empty': 'No se introduce ningún número',
  'isales.data.prop.inputPropName': 'Introduzca un nombre de atributo',
  'isales.agent.label.maxWaitTime': 'Tiempo máximo de cola',
  'isales.business.result.message.specialStr': 'No soportado',
  'isales.management.message.calledNoOneInterval': 'Intervalo de primer número llamado',
  'isales.file.title.filepath.placeholder': 'Ingrese una ruta de archivo\, por ejemplo\, /home',
  'isales.management.message.importOrder': 'Secuencia de importación',
  'isales.tasktemplate.message.deleteConfirm': '¿Está seguro de que desea eliminar la plantilla de llamada saliente seleccionada?',
  'isales.taskresult.field.failsAutomaticansweringmachine': 'La parte llamada es un contestador automático',
  'isales.result.obResultIsNull': 'El resultado de la llamada saliente de la causa de error no puede estar vacío',
  'isales.taskresult.field.failsTasknotexist': 'No hay tarea de llamada saliente disponible',
  'isales.taskresult.field.failsTransfernoreply': 'La llamada está siendo reenviada sin respuesta',
  'isales.blacklist.message.createFail': 'Error al agregar el número a la lista de bloqueo',
  'isales.workbench.number.execution': 'Ejecute',
  'isales.taskInfo.message.saveSuccess': 'La información se modifica correctamente',
  'isales.tasklogic.field.addDataType': 'Añadir DataType',
  'isales.agent.message.createSkillConfSuccess': 'La cola de habilidades se crea correctamente',
  'isales.outboundtime.message.empty': 'No se establece ningún tiempo de llamada saliente',
  'isales.tasklogic.message.busiresultpolicySame': 'Los resultados de negocio y los subestados de varias políticas de resultados de negocio son los mismos',
  'isales.management.message.uploadSelect': 'Seleccione un archivo',
  'isales.task.message.numberSuccessful': 'Registros de éxito',
  'isales.file.title.rule.type': 'Tipo Columna',
  'isales.common.title.confirm': 'OK',
  'isales.appointment.message.createSuccess': 'La llamada saliente reservada se crea correctamente',
  'isales.taskmanagement.title.createDirectly': 'Crear directamente',
  'isales.taskresult.field.failsPhonenumberisnull': 'El número de salida está vacío',
  'isales.taskmanagement.field.usersaying': 'Solicitar texto',
  'isales.task.promotion.nameRepeat': 'El nombre de la tarea de marketing multimedia ya existe',
  'isales.agent.label.transferType': 'Modo de manejo',
  'isales.task.promotion.sendTime': 'Enviado el',
  'isales.manual.message.release.DataNotWaitExists': 'Los datos de llamadas salientes que se están llamando o completando existen',
  'isales.taskpolicy.field.pickupRate': 'Tasa de Offhook',
  'isales.business.result.message.checkCode': 'Solo se permiten dígitos\, letras y guiones (-)',
  'isales.task.promotion.sms': 'SMS',
  'isales.skill.label.skillname': 'Nombre de la cola de habilidades',
  'isales.taskresult.field.failsInsufficientresources': 'Recursos insuficientes',
  'isales.appointmanagement.title.inputRemark': 'Ingrese comentarios',
  'isales.result.search.displayResult': 'Resultados externos',
  'isales.appointment.title.timeout': 'El tiempo de reserva ha pasado',
  'isales.tasklogic.field.exist.numbertype': 'Tipo de número existente',
  'isales.import.filter.message.selectRuleTips': 'Seleccione al menos una regla de filtro',
  'isales.manual.title.recipient': 'Destinatario',
  'isales.tasklogic.field.or': '¿O',
  'isales.blacklist.title.operateAgent': 'Agente añadido',
  'isales.notification.receiver.info': 'Información del Destinatario',
  'isales.datasourcemanagement.message.deleteDataSourceSuccess': 'Eliminar DataSource correctamente',
  'isales.taskstatistics.field.callResultStatistics': 'Estadísticas de resultados de llamadas',
  'isales.taskresult.field.failsNumberreacheslimit': 'El número de llamadas OBS alcanza el límite superior',
  'isales.specialday.title.holiday': 'Fecha especial',
  'isales.manutask.title.viewdata': 'Datos de llamadas salientes',
  'isales.blacklist.message.remark.error': 'Las observaciones contienen caracteres no válidos',
  'isales.management.message.importType': 'Import (método)',
  'isales.taskcalllogic.title.resultSetPolicy': 'Configurar la directiva en función de los resultados',
  'isales.taskInfo.message.saveFail': 'Error al modificar la información',
  'isales.taskresult.title.calledNo': 'Número de salida',
  'isales.agent.label.transfer': 'Traslados',
  'isales.tasklogic.field.addturn': 'Añadir Ronda',
  'isales.taskstatus.field.suspend': 'Suspendido',
  'isales.taskmanagement.field.taskId': 'ID de tarea',
  'isales.datasourcemanagement.field.dbPort': 'Puerto de DataSource',
  'isales.task.promotion.message.limitGtTenantLimit': 'El límite de envío diario excede el máximo permitido para el inquilino',
  'isales.manual.title.inputfailcode': 'Introduzca una causa de error',
  'isales.appointment.message.adjustmentFail': 'Error al ajustar la llamada saliente reservada',
  'isales.data.message.confirmSubmit': 'La secuencia numérica actual es la secuencia de llamada. Por favor\, compruebe los datos de nuevo',
  'isales.tasklogic.field.connectsameagent': 'Conectarse al mismo agente',
  'isales.taskinfo.message.beginTimeError': 'La hora de inicio no puede ser posterior a la hora de finalización',
  'isales.taskresult.field.workNo': 'WorkNo',
  'isales.manualData.message.reachMax': 'No se pueden extraer más datos de llamadas salientes porque se ha alcanzado el número máximo de llamadas en la tarea',
  'isales.training.scoreDetails': 'Detalles de Puntuación',
  'isales.taskinfo.field.survey': 'Cuestionario',
  'isales.agent.title.agentEdit': 'Modificar la información del agente',
  'isales.tasktemplate.title.addTemplate': 'Crear plantilla de llamada saliente',
  'isales.task.field.callSuccess': 'Exitoso',
  'isales.taskinfo.field.surveyAnswerNum': 'Número de respuestas a la encuesta',
  'isales.taskinfo.title.accesscode': 'código de acceso',
  'isales.taskresult.field.failsPoweredoff': 'El teléfono está apagado',
  'isales.agent.contact.voiceVidio': 'Audio y vídeo',
  'isales.agent.message.idIsEmpty': 'El ID de la cola de habilidades no puede estar vacío',
  'isales.taskresult.field.failsCalledmodem': 'La parte llamada es un módem',
  'isales.import.message.taskstatuswrong': 'No se pueden importar datos a la tarea de llamada saliente porque no está en estado borrador o suspendido',
  'isales.file.title.filepath': 'Ruta de acceso predeterminada',
  'isales.taskcalllogic.message.alreadyLastPolicy': 'Última política',
  'isales.appointment.title.remark': 'Comentarios',
  'isales.work.title.successNum': 'Llamadas salientes exitosas',
  'isales.taskresult.field.failsCallednumberincorrect': 'Número de llamada incorrecto en la vista previa',
  'isales.appointment.title.add': 'Añadir',
  'isales.taskcalllogic.field.customerMissed': 'Cliente perdido',
  'isales.agent.calledroute.create.accesscode.noexist': 'El código de acceso al que hace referencia la configuración llamada no existe',
  'isales.notification.propo.name': 'Nombre del atributo',
  'isales.tasklogic.field.lt': '<',
  'isales.agent.message.selectSkills': 'Seleccione una cola de habilidades',
  'isales.specialday.title.choose': 'Seleccionar fecha especial',
  'isales.result.outgoingCall.Description': 'Descripcion',
  'isales.data.orderType.desc': 'Descendente',
  'isales.taskcalllogic.title.resultPolicySet': 'Estrategia de procesamiento de resultados',
  'isales.specialday.field.Tuesday': 'Martes',
  'isales.tasklogic.field.result': 'Resultado',
  'isales.taskpolicy.message.input.digits': 'Escriba un número entero',
  'isales.taskinfo.message.batchEditCallLogic.manualExist': 'Las lógicas manuales de llamadas salientes no se pueden modificar',
  'isales.manual.message.data.query.error': 'Error al consultar datos de llamadas salientes',
  'isales.specreason.field.all': 'por favor elija',
  'isales.taskpolicy.field.notSupportMultiSkill': 'No se admiten agentes híbridos para llamadas entrantes y salientes',
  'isales.task.promotion.message.variableNotConfig': 'Algunas variables no están configuradas',
  'isales.management.message.fileImport': 'Usando archivo',
  'isales.management.placeholder.ivrName': 'Introduzca el nombre del proceso del robot inteligente',
  'isales.taskmanagement.message.queryDateError': 'Fecha incorrecta. Selecciona otro',
  'isales.tasklogic.field.moveUp': 'Arriba',
  'isales.taskinfo.title.versionNo': 'Versión del cuestionario',
  'isales.task.field.callFlagCompletion': 'Finalizado',
  'isales.tasklogic.field.numbertype': 'Tipo de número',
  'isales.taskresult.title.callFlag': 'Estado',
  'isales.taskstatus.field.all': 'Todo',
  'isales.taskmanagement.title.batchEditCallPolicy.sameTaskType': 'Las directivas de llamadas salientes solo se pueden modificar en lotes para tareas del mismo tipo',
  'isales.taskinfo.field.surveyScore': 'Puntuación total de la encuesta',
  'isales.data.prop.status.unpublished': 'No publicado',
  'isales.taskmanagement.field.isAutoPickUp': 'Habilitar regla de extracción automática',
  'isales.taskmanagement.message.deleteConfirm': '¿Está seguro de que desea eliminarlo?',
  'isales.management.message.batchEditCallLogic.statusError': 'Las lógicas de llamadas salientes solo se pueden modificar en lotes para tareas en estado de borrador\, ejecución o suspensión',
  'isales.task.promotion.message.selectTemplateType': 'Seleccione un tipo de plantilla',
  'isales.busi.result.message.nodatarecord': 'No se encuentra información de llamada. Seleccione el registro de llamadas salientes cuyo resultado comercial necesita ser establecido',
  'isales.datasourcemanagement.field.dbCnUrl': 'url de DataSource',
  'isales.taskresult.field.failsMusicdetected': 'Se detecta música',
  'isales.datasourcemanagement.title.inputDbschema': 'Ingrese el nombre de DataSource',
  'isales.task.promotion.beginTime': 'Hora de inicio',
  'isales.tasktemplate.message.saveSuccess': 'La plantilla de llamada saliente se configura correctamente',
  'isales.timeImport.message.success': 'La configuración de importación de tiempo se crea correctamente',
  'isales.taskmanagement.title.chooseEndtime': 'Hora de finalización',
  'isales.task.promotion.templateCode': 'Código de plantilla',
  'isales.manual.field.previewinformation': 'Información de vista previa',
  'isales.tasklogic.field.noRetryLogic': 'Agregar código de resultado de no reintento',
  'isales.tasklogic.field.retryNumber': 'Tiempos de reintento',
  'isales.data.propConfig.title.prop': 'Atributo',
  'isales.operlog.message.data.downloadexportfile': 'Descargar el archivo de resultados de exportación de la tarea {0}',
  'isales.taskmanagement.title.newFromTemplate': 'Crear usando una plantilla',
  'isales.taskstatistics.field.callstatistics': 'Estadísticas de llamadas',
  'isales.agent.contact.multimedia': 'Multimedia',
  'isales.taskresult.title.beginTime': 'Hora de inicio',
  'isales.appointment.message.timeout': '{0} llamadas salientes designadas habían pasado la hora de la cita\, por favor haga llamadas a tiempo',
  'isales.specialday.message.deleteTips': 'No se puede eliminar la fecha especial porque está asociada a una tarea de llamada saliente',
  'isales.data.title.timeImport': 'Importación programada',
  'isales.management.title.deleteInfo': 'Seleccione los datos de la llamada saliente',
  'isales.taskstatistics.field.inProgress': 'Clientes que llaman',
  'isales.tasklogic.field.callpolicy': 'Política de llamadas',
  'isales.appointment.title.callTime': 'Segmento de tiempo de llamada',
  'isales.manual.button.send': 'Enviar',
  'isales.agent.message.batchSaveSuccess': 'Los agentes se modifican en lotes con éxito',
  'isales.training.resultAnalysis': 'Análisis de resultados',
  'isales.taskmanagement.title.appoint': 'Reservar llamadas salientes',
  'isales.task.message.statistics': 'Estadísticas',
  'isales.skill.label.maxwaittime': 'Tiempo máximo de cola',
  'isales.appointment.message.confirmDelete': '¿Está seguro de que desea eliminar las llamadas salientes reservadas?',
  'isales.export.message.noSelectData': 'Seleccione los datos que desea exportar',
  'isales.export.message.noSelectTrainingData': 'Seleccione los datos que desea entrenar',
  'isales.manual.title.title': 'Titulo',
  'isales.taskresult.title.inputCallerNo': 'Introduzca un número de salida',
  'isales.taskmanagement.title.speclistmanage.reason': 'la razón',
  'isales.taskmanagement.title.editPolicy': 'Editar directiva',
  'isales.management.message.remarkLengthError': 'Se permite un máximo de 1000 caracteres',
  'isales.appointment.placeholder.remark': 'Por favor\, introduzca comentarios',
  'isales.taskresult.field.failsSwitchingdevicebusy': 'El interruptor está ocupado',
  'isales.task.promotion.message.selectPromotionType': 'Seleccione un tipo de medio',
  'isales.management.message.deletedataIdsError': 'Indicador incorrecto de los datos de la llamada saliente que se van a eliminar',
  'isales.manual.message.query.agentstatus.error': 'Error al consultar el estado del agente',
  'isales.taskstatistics.placeholder.notcalled': 'Pendiente\: {0}',
  'isales.taskresult.title.busiresultLog': 'Registro de resultados',
  'isales.taskmanagement.field.manual': 'Llamada saliente del agente',
  'isales.file.title.rule.property': 'Atributo de servicio',
  'isales.tasklogic.field.gt': '>',
  'isales.agent.label.adjustTime': 'Duración (es) de cierre',
  'isales.tasktype.field.preemption': 'Llamada saliente preferida',
  'isales.taskmanagement.title.editCountOutOfBounds': 'Se puede modificar un máximo de 30 tareas de llamada saliente a la vez',
  'isales.management.title.black.import': 'Importar lista de bloques',
  'isales.taskresult.field.failsNoValidVDN': 'No hay VDN válido disponible',
  'isales.training.questionScoreDetails': 'Detalles de Puntuación de Emisión',
  'isales.agent.message.updateCtiAndDbSuccess': 'La cola de habilidades se actualiza correctamente',
  'isales.import.title.result': 'Ver Resultado de Importación',
  'isales.callpolicy.title.add': 'Añadir',
  'isales.manualData.message.reachQuatos': 'No se pueden extraer más datos de llamadas salientes porque el número de llamadas en la tarea ha alcanzado el límite superior de extracción',
  'isales.audio.title.callId': 'Grabación SN',
  'isales.taskresult.title.operateEndTime': 'endTime',
  'isales.agent.label.timeOut': 'Tiempo de espera de cola',
  'isales.management.message.download': 'Descargar plantilla de datos de llamadas salientes',
  'isales.task.message.specialdayEmpty': 'No se ha seleccionado ninguna fecha especial',
  'isales.agent.calledroute.create.skill.none': 'La cola de habilidades a la que hace referencia la configuración llamada no existe',
  'isales.blacklist.placeholder.searchremark': 'Comentarios',
  'isales.taskresult.title.chooseUserAccount': 'Seleccione iniciado por',
  'isales.taskresult.title.resultDetail': 'Detalles del resultado de llamadas',
  'isales.operation.success': 'La operación tuvo éxito',
  'isales.operation.training.success': 'La operación se realizó correctamente. El entrenamiento se llevó a Lodas para ver el progreso del entrenamiento',
  'isales.taskresult.field.failsQueuingtimesout': 'El tiempo de espera de la cola y la plataforma libera la llamada',
  'isales.skillqueue.agentrole.agent': 'Agente',
  'isales.task.promotion.message.dndEndTimeltBeginTime': 'El final del período de no molestar es anterior al inicio',
  'isales.tasktemplate.title.addManualTemplate': 'Crear una plantilla de llamada saliente manual',
  'isales.taskresult.field.failsFailedRouteAgent': 'Error al enrutar la llamada al agente',
  'isales.taskpolicy.field.predictMethod': 'Algoritmo para llamadas salientes predichas',
  'isales.manual.title.viewManualData': 'Datos de llamadas salientes del agente-',
  'isales.manual.message.agentstatus.isnofree': 'El agente actual no está en estado inactivo',
  'isales.statistics.field.addHis': 'Recopilación de datos archivados',
  'isales.outboundtime.field.minute': 'Minutos',
  'isales.agent.label.skillChoice': 'Cola de habilidades',
  'isales.manual.message.lockData': 'Seleccione los datos de llamadas salientes que desea bloquear',
  'isales.specialday.message.updateSuccess': 'La fecha especial se modifica correctamente',
  'isales.specialday.title.confirm': 'Confirmar',
  'isales.task.message.saveSuccess': 'La tarea de llamada saliente se configura correctamente',
  'isales.sendStatus.field.prepare': 'Preparación',
  'isales.taskpolicy.field.utilRate': 'Uso del agente',
  'isales.manual.title.inputstatus': 'Introduzca el estado de una llamada saliente',
  'isales.task.promotion.gatewwayError': 'La configuración de la puerta de enlace no coincide con el tipo de medio',
  'isales.task.message.play': 'Reproducir',
  'isales.task.promotion.deleteFailed': 'Error al eliminar la tarea de marketing multimedia',
  'isales.task.promotion.email': 'Correo Electrónico',
  'isales.taskmanagement.message.queryLimit': 'El número de registros en cada página debe ser un entero positivo menor que 101',
  'isales.taskresult.title.exportResult': 'Ver Resultado de Exportación',
  'isales.taskresult.field.failsFaxdetected': 'Se detecta un fax',
  'isales.taskmanagement.title.viewAppointList': 'Reservas',
  'isales.taskmanagement.title.viewAppointListDetail': 'Detalles de la reserva',
  'isales.manual.title.status': 'Estado',
  'isales.datasourcemanagement.field.dbType': 'Tipo de DataSource',
  'isales.taskresult.field.failsRingingtimesout': 'El timbre se agota y la plataforma libera la llamada',
  'isales.taskmanagment.callquality.minConnectedRate': 'Velocidad mínima de conexión',
  'isales.taskpolicy.field.connRate': 'Tasa de conexión de llamadas salientes',
  'isales.agent.message.willDeleteSkills': 'Esta operación puede provocar que la llamada de tarea saliente asociada a la cola de habilidades falle. Por favor\, opere con precaución. ¿Necesitas eliminarlo?',
  'isales.task.promotion.promotionEffTime': 'Tiempo efectivo',
  'isales.taskpolicy.field.calculationFactor': 'Factor de cálculo',
  'isales.appointment.message.callNotAllowed': 'No se puede realizar una llamada saliente reservada porque el agente no está en estado inactivo o ocupado',
  'isales.management.message.import': 'Importar datos adjuntos',
  'isales.agent.calledroute.refreshsuccess': 'La actualización se realizó correctamente',
  'isales.taskinfo.message.require': 'Establezca todos los campos obligatorios',
  'isales.training.specialday.callTime': 'Segmento de hora de llamada de fecha especial',
  'isales.tasklogic.field.addOrderPolicy': 'Añadir Prop',
  'isales.taskmanagement.title.outbound': 'Chatbot de llamadas salientes',
  'isales.agent.calledroute.create.accesscode.none': 'El código de acceso al sistema configurado por la parte llamada no está asignado',
  'isales.management.title.details': 'Detalles',
  'isales.data.propConfig.message.isUseProp': 'Habilitar atributo personalizado',
  'isales.file.title.rule.name.placeHolder': 'Introduzca un nombre de regla',
  'isales.file.title.server.info': 'La información del servidor se modifica correctamente',
  'isales.task.title.calllogic': 'Lógica de llamadas salientes',
  'isales.tasklogic.field.callturntype.numberorder': 'Por número de secuencia',
  'isales.agent.label.deviceType': 'Tipo de dispositivo',
  'isales.tasktemplate.message.pauseSuccess': 'Suspensión exitosa',
  'isales.taskresult.title.chooseResult': 'Seleccione el resultado de la llamada',
  'isales.import.message.succnum': 'Número de registros exitosos',
  'isales.file.title.rule.validator.maxdata': 'Se puede añadir un máximo de 40 registros de configuración',
  'isales.taskmanagement.title.status': 'Estado',
  'isales.taskresult.field.failsUndefinedsignaltone': 'Tono de señal indefinido',
  'isales.tasklogic.field.addbusiresultpolicy': 'Añadir política de resultados de negocio',
  'isales.management.title.detailData': 'Detalles de datos de llamadas salientes',
  'isales.taskresult.title.userName': 'Nombre de usuario',
  'isales.taskcalllogic.field.customerbusy': 'Cliente ocupado',
  'isales.import.message.selectServer.tips': 'Defina el servidor de archivos de llamadas salientes',
  'isales.management.title.refresh': 'Refrescar',
  'isales.taskinfo.field.endDate': 'Hora de finalización',
  'isales.management.title.calledNo': 'Número Llamado',
  'isales.tasklogic.field.like': 'Incluir',
  'isales.tasklogic.field.callOrderMethod.bySelf': 'Por regla personalizada',
  'isales.taskmanagement.title.configData': 'Datos de llamadas salientes',
  'isales.exportdata.message.successinfo': 'Exportando datos...Ver detalles en Resultado de exportación',
  'isales.task.message.numberInvalFeedback': 'Número de llamadas de retroalimentación no válidas',
  'isales.data.prop.numberType.countError': 'Se puede definir un máximo de 10 atributos de tipo dígito',
  'isales.specialday.message.deletePartiallyFail': 'Algunas fechas especiales no se eliminaron',
  'isales.taskresult.field.failsResourceOverload': 'Recursos insuficientes',
  'isales.task.promotion.message.noprop': 'Agregue un atributo mediante el menú Definición de atributo de datos de llamada saliente',
  'isales.import.message.errMsg': 'Causa de excepción',
  'isales.tasktemplate.message.releasefailed': 'Error al publicar',
  'isales.agent.message.passLengthValidateFailed': 'La contraseña debe contener 8 caracteres',
  'isales.tasklogic.label.subCondition': 'Subcondición',
  'isales.agent.calledroute.placeholder.ivr': 'Por favor seleccione IVR',
  'isales.manual.message.update.fail': 'Error al actualizar el estado de los datos de la llamada saliente',
  'isales.agent.message.searchSkillName': 'Introduzca el nombre de la cola de habilidades',
  'isales.import.filter.button.noRepeat': 'Filtrado de datos duplicados por lotes',
  'isales.taskresult.field.failsAgentHangsUp': 'El agente cuelga',
  'isales.file.title.sk.same': 'Los dos SK son diferentes',
  'isales.taskinfo.field.chooseOrInputCallNo': 'Puede introducir o seleccionar la llamadaNo al mismo tiempo',
  'isales.management.message.calledNoLengthError': 'La longitud del número llamado excede el límite',
  'isales.blacklist.title.name': 'Nombre',
  'isales.tasklogic.field.callfilter': 'Filtrado de llamadas',
  'isales.data.filed.isResult': 'Sí',
  'isales.taskmanagement.title.editInfo': 'Cambiar información',
  'isales.agent.message.passValidateFailed': 'El valor debe contener al menos tres tipos de los siguientes caracteres\: letras mayúsculas\, minúsculas\, dígitos y caracteres especiales (`-\!{\'@\'}\#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?)',
  'isales.management.message.updateSuccess': 'Los datos de la llamada saliente se modifican correctamente',
  'isales.taskpolicy.field.supportMultiSkill': 'Se admiten agentes híbridos para llamadas entrantes y salientes',
  'isales.tasklogic.field.addSubCondition': 'Añadir subcondición',
  'isales.data.prop.createDataProp': 'Crear atributo',
  'isales.taskpolicy.message.obOverTime': 'Introduzca primero la duración del tiempo de espera de vista previa',
  'isales.survey.details': 'Detalles del cuestionario',
  'isales.task.message.download': 'Descargar Grabación',
  'isales.work.title.selectTask': 'Seleccionar tarea de llamada saliente',
  'isales.appointment.title.userName': 'Iniciado por',
  'isales.taskresult.field.failsReleaseswhencall': 'Cuando se configura un canal de voz\, un agente libera una llamada usando el control de barra de agente',
  'isales.taskresult.title.queryArchivedData': 'Consultar datos archivados',
  'isales.agent.calledroute.editsuccess': 'Se ha editado correctamente',
  'isales.agent.message.createSkillConfFail': 'Error al crear la cola de habilidades',
  'isales.taskmanagement.field.pickUpRule': 'Regla de extracción automática',
  'isales.tasklogic.field.callmethod': 'Modo de llamada',
  'isales.taskresult.title.failedDetail': 'Detalles de falla de llamada',
  'isales.data.prop.propNameExists': 'El nombre del atributo ya existe',
  'isales.management.message.deletedataIdsEmpty': 'El indicador de los datos de llamada saliente que se van a eliminar está vacío',
  'isales.outboundtime.message.crossover': 'Los segmentos de tiempo de llamada saliente se superponen',
  'isales.notification.configVariableValue': 'Establecer valor de variable',
  'isales.specialday.field.byWeek': 'Por Semana',
  'isales.appointment.message.queryTimeError': 'La hora de inicio debe ser anterior a la hora de finalización',
  'isales.taskresult.field.failsRingbacktone': 'Tono de llamada',
  'isales.agent.field.skill': 'SkillName',
  'isales.agent.message.chooseAnAgent': 'Seleccione un agente',
  'isales.audio.title.starttime': 'Hora de inicio',
  'isales.task.message.numberFailed': 'Registros fallidos',
  'isales.taskmanagement.field.dailyStatistics': 'Estadísticas diarias',
  'isales.import.message.template.show': 'Agregue datos de clientes por fila y agregue varios números de llamada por columna',
  'isales.tasklogic.title.addPolicy': 'Añadir política',
  'isales.taskmanagement.title.chooseBegintime': 'Hora de inicio',
  'isales.tasklogic.field.policyType': 'PolicyType',
  'isales.tasklogic.field.addCondition': 'Añadir condición',
  'isales.notification.variable.value': 'Valor de la variable',
  'isales.taskinfo.field.beginDate': 'Hora de inicio',
  'isales.outboundtime.title.beginTime': 'Hora de inicio',
  'isales.management.message.importMode': 'Modo de importación',
  'isales.tasklogic.message.exist.orderNo': 'La secuencia numérica ya existe. Introduce otro',
  'isales.taskresultexport.message.type': 'Tipo de exportación',
  'isales.agent.calledroute.placeholder.extcode': 'Por favor\, introduzca el código de extensión',
  'isales.agent.contact.vidio': 'Vídeos',
  'isales.agent.calledroute.accesscode': 'código de acceso',
  'isales.data.prop.editDataPropSuccess': 'El atributo se modifica correctamente',
  'isales.taskresult.field.failsHangsupwhencall': 'Cuando un agente responde a una llamada utilizando el control de la barra del agente\, el teléfono del agente se desconecta',
  'isales.blacklist.message.periodAndTime': 'El período de restricción y el número máximo de llamadas deben establecerse o dejarse vacíos al mismo tiempo',
  'isales.agent.calledroute.create.ivr.limit': 'El tipo de dispositivo es IVR\, y el tipo de medio del código de acceso solo puede ser clic para llamar\, solicitud de devolución de llamada o llamada normal',
  'isales.data.message.preview.addNumberUpperLimit': 'Se puede agregar un máximo de {count} números para las llamadas salientes previsualizadas',
  'isales.operation.failed': 'Error en la operación',
  'isales.taskinfo.title.surveyName': 'Nombre del cuestionario',
  'isales.taskinfo.callno.repeat': 'Número de llamada es Repetir',
  'isales.tasklogic.field.callOrderType.isOrder': 'Llamada solicitada',
  'isales.data.propConfig.button.configProp': 'Configurar atributos',
  'isales.agent.accesscode.description.limit': 'La descripción del código de acceso puede contener un máximo de 50 caracteres',
  'isales.taskinfo.field.mutiCallNo': 'Ingrese el número de llamada',
  'isales.taskresultexport.message.begintime': 'Hora de inicio',
  'isales.taskresult.field.failsCallResourcesInsufficient': 'Recursos de llamadas insuficientes',
  'isales.training.averageDuration': 'Duración media',
  'isales.management.message.calledNoOne': 'Primer número llamado',
  'isales.taskresult.field.failsNoAnsweringDeviceAvailable': 'No hay contestador disponible',
  'isales.tasktype.field.prediction': 'Llamada saliente predicha',
  'isales.data.prop.publish': 'Publicación',
  'isales.import.filter.title.popup': 'Reglas de filtro',
  'isales.callNo.title.no': 'No',
  'isales.management.message.import.server': 'Servidores',
  'isales.datasourcemanagement.field.sync': 'Sincronizar DataSource',
  'isales.specialday.title.create': 'Crear',
  'isales.file.title.password.same': 'Las dos contraseñas son diferentes',
  'isales.taskinfo.title.smsTemplate': 'Plantilla de SMS',
  'isales.management.placeholder.skillName': 'Introduzca un nombre de cola de habilidades',
  'isales.blacklist.message.Description': 'Número máximo de llamadas cada hora o día dentro del período de validez',
  'isales.datasourcemanagement.field.backdbport': 'Puerto DataSource trasero',
  'isales.taskpolicy.field.talkingRate': 'Velocidad de llamada saliente',
  'isales.manual.message.precall.success': 'Se está iniciando una llamada saliente',
  'isales.taskmanagement.title.inputTaskName': 'Escriba un nombre de tarea',
  'isales.agent.message.modifySkillConfFail': 'Error al modificar la cola de habilidades',
  'isales.data.import.task.status.executing': 'Importación',
  'isales.tasktemplate.title.inputTemplateName': 'Nombre de la plantilla',
  'isales.management.title.edit': 'Modificar',
  'isales.file.message.rule.fail': 'Error al modificar la regla',
  'isales.file.message.deletefiletips': '¿Está seguro de que desea eliminar el servidor de archivos saliente?',
  'isales.taskLogic.message.saveSuccess': 'La lógica de llamada saliente se modifica correctamente',
  'isales.datasourcemanagement.field.add': 'Añadir DataSource',
  'isales.agent.contact.mediaType': 'Tipo de medios',
  'isales.appointment.placeholder.appointTime': 'Seleccione la hora de llamada reservada',
  'isales.busi.result.item.succ': 'Éxito',
  'isales.file.title.rule.validator.row': 'Introduzca un número de secuencia de la columna A a EZ',
  'isales.taskmanagement.title.export': 'Exportar registro de llamada',
  'isales.datasourcemanagement.title.inputDbId': 'Introduzca el Id. de origen de datos',
  'isales.specialday.message.weeklyError': 'Periodo de fecha especial incorrecto',
  'isales.business.result.delete': 'Borrar',
  'isales.file.message.bucket.placeholder': 'Entra en un depósito',
  'isales.taskmanagement.title.batchEditCallTime.manualError': 'El tiempo de llamada saliente manual no se puede cambiar',
  'isales.taskresult.field.failsAgentCancelingCall': 'El agente cancela la llamada',
  'isales.callrecordexport.message.downloadFilenopemission': 'No tiene derecho a exportar registros de comunicación de llamadas salientes inteligentes',
  'isales.data.message.duplicateExist': 'Existen números duplicados',
  'isales.agent.label.answerType2': 'ID de agente de voz que no reporta',
  'isales.file.title.password': 'Contraseña',
  'isales.training.questionNumber': 'Número de preguntas',
  'isales.agent.label.answerType0': 'Id. de agente de informe',
  'isales.taskresult.field.failedtooccupytheagent': 'Error al ocupar un agente',
  'isales.import.message.allow.task.statue': '\,Una tarea sólo se puede importar cuando está en el estado Borrador o En pausa',
  'isales.agent.message.batchSaveFail': 'Error al modificar los agentes en lotes',
  'isales.training.surveyDetail': 'Detalles del cuestionario',
  'isales.validate.message.holidayDateComparisonError': 'La fecha de finalización no puede ser anterior a la fecha de inicio',
  'isales.management.title.timeImport.execTime': 'Ejecutado',
  'isales.taskresultexport.title.fail': 'Error',
  'isales.taskmanagement.title.queryTimeTip': 'Información de contacto',
  'isales.agent.label.workNo': 'ID de agente',
  'isales.common.title.view': 'Vistas',
  'isales.tasklogic.field.existingDataType': 'Seleccionar un tipo de datos existente',
  'isales.management.message.appendMode': 'Añadir',
  'isales.file.title.rule.index': 'Número de secuencia',
  'isales.tasklogic.field.callbyturn': 'Por ronda',
  'isales.import.message.fail': 'Error de importación',
  'isales.import.filter.button.calling': 'Filtrar datos de llamadas no completadas',
  'isales.busi.result.message.recordfail': 'El resultado del negocio se registra fallado',
  'isales.taskmanagement.title.addAuto': 'Crear Sistema de llamadas salientes automáticas',
  'isales.file.title.rule.validator.taskId': 'La información de la tarea no existe',
  'isales.blacklist.message.deletePartiallyFail': 'Error al eliminar algunos números de la lista de bloqueo',
  'isales.taskresult.field.failedtoresume': 'No se puede recuperar la tarea porque la tarea no está suspendida',
  'isales.taskpolicy.field.previewAutoCallTime': 'Duración (es) de llamada saliente previsualizada',
  'isales.taskLogic.message.saveFail': 'Error al modificar la lógica de llamada saliente',
  'isales.agent.calledroute.device': 'Por favor\, seleccione un dispositivo',
  'isales.blacklist.title.modifyRemark': 'Modificar',
  'isales.taskstatistics.placeholder.statistics': 'Estadísticas',
  'isales.import.button.configProp': 'Configurar regla de filtro',
  'isales.management.title.createNew': 'Crear datos de llamadas salientes',
  'isales.taskpolicy.field.minAvgCallTime': 'Mín. Duración promedio de llamadas',
  'isales.specist.message.speclistmanage': 'Seleccione el número de lista especial que se va a operar',
  'isales.busi.result.message.lastappointrecord': 'Pleanse establece primero el resultado del negocio de la última llamada saliente reservada',
  'isales.file.title.password.placeholder': 'Por favor\, ingrese contraseña',
  'isales.skillqueue.agentrole.administrator': 'Gerente de habitación',
  'isales.manual.message.call.failed': 'Error al realizar la llamada saliente',
  'isales.taskresult.field.failsHangsupbeforeanswering': 'La parte llamada cuelga antes de que se responda a la llamada',
  'isales.manual.field.handlednumber': 'Número de sesión',
  'isales.tasklogic.message.repeatProp': 'No se pueden seleccionar atributos duplicados',
  'isales.management.title.selectResultCode': 'Seleccionar código de resultado',
  'isales.tasklogic.field.interval': 'Intervalo (Segundo)',
  'isales.taskmanagement.field.calltime': 'Iniciado en',
  'isales.agent.contact.videoclicktodial': 'Video clic para marcar',
  'isales.taskresult.message.queryTime': 'Introduzca un tiempo completo',
  'isales.taskmanagement.title.query': 'Consultar',
  'isales.agent.label.cti': 'cti',
  'isales.import.filter.title.notification.isPushDay': 'Días empujados',
  'isales.data.prop.propParamsError': 'Error al verificar el parámetro de atributo',
  'isales.file.title.rule.validator.prop': 'El atributo definido por el usuario de la tarea es incompatible con el atributo configurado en la regla',
  'isales.taskmanagement.field.statisticsType': 'Tipo de estadísticas',
  'isales.specialday.message.idError': 'Indicador de fecha especial incorrecto',
  'isales.validate.message.formatError': 'Introduzca una fecha en formato MM/DD',
  'isales.taskresult.title.choosebusisubstatus': 'Seleccione el resultado del negocio antes de seleccionar el subestado',
  'isales.tasklogic.field.moveDown': 'Abajo',
  'isales.taskresult.field.failsEdt': 'Teléfono de texto (EDT)',
  'isales.specialday.title.addSpecialTime': 'Añadir fecha especial',
  'isales.business.result.message.must': 'Este parámetro es obligatorio',
  'isales.taskinfo.message.autoCallTimeValueError': 'La duración de la llamada previsualizada debe ser más corta que la duración del tiempo de espera de previsualización y la diferencia debe ser mayor o igual a 3 segundos',
  'isales.business.result.deletetips': '¿Está seguro de que desea eliminar el resultado del negocio?',
  'isales.agent.calledroute.confdelete': 'eliminar confirmación',
  'isales.manual.field.result.fail': 'Error',
  'isales.taskstatus.field.draft': 'Borrador',
  'isales.data.prop.editDataProp': 'Modificar atributo',
  'isales.blacklist.placeholder.searchcalledno': 'Número de teléfono',
  'isales.black.filed.number': 'Número (obligatorio)',
  'isales.timeImport.message.rulePropsError': 'Los apoyos de regla no son la tarea definida',
  'isales.survey.statistic': 'Estadísticas de encuestados',
  'isales.taskpolicy.message.input.maxAvgCallTime': 'La duración media máxima de la llamada no puede ser inferior a la mínima. Duración promedio de llamadas',
  'isales.taskcalllogic.field.emptyNumber': 'Número fuera de servicio',
  'isales.business.result.searchname': 'Introduzca el nombre del resultado comercial',
  'isales.calledNo.message.input': 'Solo se permiten dígitos',
  'isales.taskmanagement.title.begintime': 'Hora de inicio',
  'isales.callno.message.delayTime': 'Tiempo de retardo de fallo de llamada\: Intervalo entre el número actual y el número anterior',
  'isales.manual.message.data.isalready.called': 'El cliente ha realizado una llamada y no puede realizar otra llamada',
  'isales.data.import.task.status.complete': 'Finalizado',
  'isales.task.promotion.hwsms': 'HUAWEI CLOUD SMS',
  'isales.taskresult.field.failsSubscriberhangsup': 'La fiesta llamada cuelga',
  'isales.agent.calledroute.delsuccess': 'borrado correctamente',
  'isales.agent.field.skillWeight': 'Peso de la habilidad',
  'isales.specialday.message.deleteSpecialday': 'Seleccione una fecha especial',
  'isales.outboundtime.title.timePeriod': 'Segmento de tiempo de llamada saliente',
  'isales.management.title.extendedField': 'Campo extendido',
  'isales.blacklist.title.expirationTime': 'Tiempo de caducidad',
  'isales.skill.placeholder.skilldesc': 'Introduzca el nombre de la cola de habilidades',
  'isales.input.message.small': 'El valor de entrada es demasiado pequeño',
  'isales.sendStatus.field.sending': 'Enviando',
  'isales.datasourcemanagement.field.dbIp': 'Fuente de datos IP o alias',
  'isales.management.message.calledNoFormatIncorrect': 'El número de llamada saliente solo puede contener dígitos',
  'isales.taskcalllogic.message.dataTypeDefaultNoCreate': 'Tipo de número predeterminado',
  'isales.task.promotion.edit': 'Modificar tarea de marketing multimedia',
  'isales.outboundtime.title.clear': 'Limpiar',
  'isales.management.title.toblocklist': 'Añadir a la lista de bloqueos',
  'isales.result.delete.error': 'Seleccione una causa de error de llamada saliente',
  'isales.agent.title.skillCreate': 'Crear cola de habilidades',
  'isales.datasourcemanagement.field.dbId': 'Número de identificación de DataSource',
  'isales.specialday.field.endDate': 'Fecha de finalización',
  'isales.management.message.batchEditCallLogic.notSupportBusiresult': 'Las directivas de resultados empresariales no se pueden configurar para llamadas salientes automáticas o inteligentes. ¿Está seguro de que desea realizar la modificación por lotes?',
  'isales.agent.label.useTheSkill': 'Elimine la relación de enlace entre el agente y la cola de habilidades en Configurar agente',
  'isales.taskmanagement.title.speclistmanage.calledno': 'Número de salida',
  'isales.taskmanagement.title.editSample': 'Gestión de objetos',
  'isales.import.filter.button.recentCall': 'Filtrar los datos de las llamadas realizadas últimamente',
  'isales.datasourcemanagement.message.dbpass': 'Por favor\, introduzca la misma contraseña',
  'isales.file.title.server.fail': 'Error al modificar la información del servidor',
  'isales.taskresult.field.failsNoresource': 'No hay recursos disponibles',
  'isales.taskresult.field.failsObsexits': 'Cuando el OBS sale\, los registros en curso sin respuesta se cancelan y se escriben de nuevo',
  'isales.agent.calledroute.create.accesscode.repeat': 'La combinación de código de acceso al sistema y código de extensión configurados por la parte llamada no se puede repetir',
  'isales.management.title.warning': 'Advertencia',
  'isales.task.promotion.create': 'Crear tarea de marketing multimedia',
  'isales.training.startTraining': 'Comienza a entrenar',
  'isales.training.taskdetails': 'Detalles de la tarea',
  'isales.taskinfo.field.skillName': 'Cola de habilidades',
  'isales.specialday.message.nameExists': 'El nombre de fecha especial ya existe',
  'isales.data.import.task.status.error': 'Error',
  'isales.specialday.message.weeklyEmpty': 'El período de fecha especial está vacío',
  'isales.taskresult.field.failsReleasesbeforecall': 'Antes de configurar un canal de voz\, un agente libera una llamada usando el control de barra de agente',
  'isales.taskmanagement.message.queryFailed': 'Error de consulta',
  'isales.audio.message.errortip1': 'Error al consultar la información de grabación. Póngase en contacto con el administrador',
  'isales.agent.field.newpasswordconf': 'Confirmar contraseña',
  'isales.task.title.preStep': 'Anterior',
  'isales.taskresult.field.failsInvalidstatus': 'Estado DTE no válido',
  'isales.agent.label.ccRoleName': 'Nombre del rol',
  'isales.appointmanagement.field.remark': 'Comentarios',
  'isales.blacklist.message.createSuccess': 'El número se agrega correctamente a la lista de bloques',
  'isales.taskinfo.callno.length': 'La longitud de un solo número de llamada supera los 32 caracteres',
  'isales.taskmanagement.title.batchStart.finishedStatus': 'No se pueden iniciar las tareas completadas',
  'isales.business.result.view': 'Ver el resultado del negocio',
  'isales.taskresultexport.message.downloadFilenopemission': 'No tiene permiso para exportar archivos',
  'isales.taskmanagment.title.callquality.viewstatistics': 'Estadísticas de calidad de llamadas',
  'isales.notification.viewResult.button': 'Ver resultado',
  'isales.tasklogic.field.dataType': 'Tipo de datos',
  'isales.data.prop.stringType': 'Cuerda',
  'isales.task.statistics.obs.startTime': 'La hora de inicio no puede ser anterior a hace siete días',
  'isales.datasourcemanagement.message.alreadyExistDs': 'El dataSource de VDN ya existe',
  'isales.agent.button.edit': 'Editar',
  'isales.taskresult.field.failsNoreply': 'La parte llamada no responde a la llamada',
  'isales.common.title.tipsInfo': 'Información de contacto',
  'isales.taskresult.field.failsNumberchange': 'Se cambia el número',
  'isales.tasklogic.field.retryDay': 'Días máximos para intentos de llamada antes de la conexión',
  'isales.task.promotion.message.variableSelected': 'Seleccione un atributo',
  'isales.taskresult.title.operateBeginTime': 'InicioTiempo',
  'isales.taskresultexport.message.downloadConfirmInfo': '¿Está seguro de que desea descargar el archivo?',
  'isales.notification.receiver.address': 'Dirección de Destinatario',
  'isales.taskresult.title.busisubstatus': 'SubStatus de negocios',
  'isales.task.promotion.promotionName': 'Tarea de marketing',
  'isales.task.message.numbersTotal': 'Total\: {0}',
  'isales.notification.prop.tips': 'El atributo debe ser único',
  'isales.training.remainingSamples': 'Cantidad restante',
  'isales.taskstatistics.field.touchRate': 'Tasa táctil',
  'isales.specialday.title.delete': 'Borrar',
  'isales.data.message.confirmaddblock.calldatatype': 'El tipo actual son los datos de llamada. Confirme de nuevo',
  'isales.specialday.title.deleteError': 'Error',
  'isales.taskpolicy.field.utilRateCalculateMethod': 'Método para calcular el uso del agente',
  'isales.agent.label.ivrChoice': 'IVR',
  'isales.taskresult.title.result': 'resultado',
  'isales.datasourcemanagement.field.dsType.all': 'Todo',
  'isales.specreason.field.emptynumber': 'El número no está asignado',
  'isales.taskinfo.field.callBackUrl': 'Dirección de devolución de llamada',
  'isales.taskcalllogic.message.mostPolicys': 'Se puede configurar un máximo de 10 directivas de resultados para un número',
  'isales.taskstatus.field.execute': 'Ejecutando',
  'isales.manualdata.title.updateCalledNo': 'Cambiar el número de salida',
  'isales.taskresult.title.busiresultupdate': 'Anexar Busiresult',
  'isales.taskresult.field.failsFaxormodem': 'La parte llamada es un fax o módem',
  'isales.task.title.callTime': 'Duración de la llamada saliente',
  'isales.datasourcemanagement.field.dbschema': 'Nombre de DataSource',
  'isales.busi.result.title.result': 'Resultado',
  'isales.taskresult.field.failsUnallocatednumber': 'Número no asignado',
  'isales.taskpolicy.field.waitTime': 'Tiempo de espera de la fiesta llamada ',
  'isales.specialday.message.idEmpty': 'La bandera de fecha especial está vacía',
  'isales.taskmanagement.message.beforeNow': 'La hora de inicio no puede ser anterior a la hora actual',
  'isales.specialday.message.isEmpty': 'No se establece ninguna hora de llamada saliente de la fecha especial',
  'isales.business.result.name': 'Nombre del resultado del negocio',
  'isales.taskresult.field.failsTransferbeingalerted': 'La llamada está siendo reenviada durante el timbre',
  'isales.export.message.input.error': 'La contraseña no está en el formato correcto',
  'isales.manual.message.data.checklock.inblack': 'El número de llamada saliente está en la lista de bloqueo',
  'isales.file.title.port': 'El puerto',
  'isales.taskresult.field.failsInvalidSkillQueue': 'Cola de habilidades no válida',
  'isales.task.message.numberCalled': 'Llamado\: {0}',
  'isales.taskresult.field.failsCallRouting': 'Error al enrutar la llamada',
  'isales.agent.message.configAgent': 'Configurar o sincronizar un agente',
  'isales.taskmanagement.title.pauseConfirm': '¿Está seguro de que desea suspender la tarea de llamada saliente seleccionada?',
  'isales.taskresult.field.failsAgentfailswithuser': 'Error al configurar un canal de voz entre un agente y un suscriptor',
  'isales.notification.configVariableRel': 'Configurar la relación entre variable y atributo',
  'isales.failfile.export.failCalledNo': 'FalloLlamadoNo',
  'isales.manual.field.noDataCanbeCallTips': 'Actualmente\, no se pueden realizar llamadas. Compruebe si la configuración de atributo cumple con los criterios de filtro',
  'isales.black.filed.remark': 'Observaciones (opcional)',
  'isales.task.promotion.sendTemplate': 'Plantilla de notificación de marketing',
  'isales.taskresult.field.failsNoagentavailable': 'Cuando el OBS cambia al estado de espera\, si no hay ningún agente disponible para llamadas salientes de salida seguras o preocupadas\, se cancelan los registros con errores',
  'isales.tasklogic.field.add.numbertype': 'Nuevo tipo de número',
  'isales.taskresult.field.failsDestinationunreachable': 'El destino es inalcanzable',
  'isales.appointment.message.afterNow': 'La hora reservada no puede ser anterior a la hora actual',
  'isales.specialday.title.add': 'Añadir fecha especial',
  'isales.agent.label.skillId': 'ID de cola de habilidades',
  'isales.taskresult.field.invalidCall': 'Llamada no válida',
  'isales.specreason.field.systemtone': 'Tono de aviso del sistema',
  'isales.taskresult.field.failsAbnormal': 'Error',
  'isales.taskresult.field.failsPlayingvoices': 'La señalización detecta que el conmutador par está reproduciendo voces',
  'isales.task.promotion.promotionExpTime': 'Tiempo de caducidad',
  'isales.training.fullScoreNumber': 'Número de puntuaciones completas',
  'isales.agent.label.ivr': 'IVR',
  'isales.management.message.import.filePath': 'Dirección de archivo',
  'isales.taskresult.field.failsEmptyorwrong': 'Número inalcanzable o incorrecto',
  'isales.appointment.message.soontimeout': '{0} llamadas salientes designadas llegarán en los próximos 5 minutos\, por favor prepárese para hacer llamadas',
  'isales.specialday.message.createSuccess': 'La fecha especial se crea correctamente',
  'isales.management.title.busiresult': 'Resultado comercial',
  'isales.taskinfo.message.callintervalrange': 'Ingrese un número entero comprendido entre 1 y 3600',
  'isales.import.filter.button.callResult': 'Filtrar datos de resultados de servicio',
  'isales.management.message.calledNoExists': 'El número llamado ya existe',
  'isales.taskmanagement.field.zip.makesure.password': 'Vuelva a introducir la contraseña del paquete comprimido',
  'isales.management.message.calledNoTwoInterval': 'Segundo intervalo de número llamado',
  'isales.workbench.task.management': 'Gestión de llamadas salientes',
  'isales.tasklogic.field.retryTimes': 'Número máximo de intentos de llamadas diarias',
  'isales.data.prop.quantity': 'Cantidad',
  'isales.taskmanagement.field.pickUpNum': 'Llamadas extraídas cada vez',
  'isales.taskmanagement.title.view.callrecord': 'Registro de llamadas',
  'isales.data.prop.orderType': 'Tipo de pedido',
  'isales.data.prop.anonym': 'Anonimizado',
  'isales.file.title.ip': 'Dirección IP',
  'isales.tasklogic.field.callOrderMethod.byOrderNo': 'Por No',
  'isales.specialday.message.crossover': 'Los segmentos de tiempo de las llamadas salientes en fechas especiales se superponen',
  'isales.taskresult.field.failsCallFailure': 'La llamada falló',
  'isales.import.filter.button.lineRepeat': 'Filtrado de datos duplicados de fila',
  'isales.taskstatistics.field.completedcalls': 'Llamado',
  'isales.import.message.selectRule.tips': 'Defina la regla de asignación que se va a importar',
  'isales.taskstatistics.field.abandonRate': 'Tarifa de abandono',
  'isales.task.promotion.pauseSuccess': 'La tarea de marketing multimedia se suspende correctamente',
  'isales.import.message.uploadedfiletoolarge': 'Seleccione un archivo de menos de 20 MB',
  'isales.taskmanagement.field.pickUpRuleBusiResult': 'Resultado de Negocio',
  'isales.blacklist.message.deleteBlacklist': 'Seleccione el número de la lista de bloqueos que desea eliminar',
  'isales.agent.message.name.limit': 'El nombre de la cola de habilidades supera la longitud máxima.',
  'isales.common.title.success': 'Exitoso',
  'isales.task.title.taskSample': 'Gestión de objetos',
  'isales.taskresult.field.failsWithoutringing': 'La llamada está siendo reenviada en respuesta inmediata antes de sonar',
  'isales.task.promotion.prefix': 'Código de país',
  'isales.taskinfo.field.flowName': 'Flujo IVR',
  'isales.work.title.busiSuccess': 'Negocios exitosos',
  'isales.notification.import.button': 'Importar datos',
  'isales.taskresult.field.skillqueue': 'Cola de habilidades',
  'isales.task.promotion.startFailed': 'Error al publicar la tarea de marketing multimedia',
  'isales.taskstatistics.field.callRatePie': 'Tasa de finalización de llamadas',
  'isales.tasktemplate.title.editTemplate': 'Modificar plantilla de llamada saliente',
  'isales.exportdata.message.failinfo': 'Error al exportar datos',
  'isales.taskresult.field.failsTransferonbusy': 'La llamada está siendo reenviada en ocupado',
  'isales.datasourcemanagement.message.deleteDataSourceFail': 'Error al eliminar DataSource',
  'isales.taskstatistics.field.Completed': 'Clientes Completados Llamadas',
  'isales.taskresult.field.failsCallqueueoverflow': 'Una cola de llamadas se desborda',
  'isales.taskmanagement.title.out.callresult': 'Resultado de llamada saliente',
  'isales.manual.message.confirmLockData': '¿Está seguro de que desea bloquear los datos de la llamada saliente?',
  'isales.manual.task.survey.currentprogress': 'Avances actuales',
  'isales.task.promotion.smsgw': 'Pasarela SMS',
  'isales.taskmanagement.title.toBeCallCount': 'Llamadas pendientes',
  'isales.speclist.blacklist.message.movePartiallyFail': 'Error en la transferencia de números a la lista negra',
  'isales.task.promotion.inputPromotionName': 'Por favor ingrese un nombre comercial',
  'isales.operlog.message.data.import': 'Importar datos para la tarea {0}',
  'isales.tasklogic.message.need.turns': 'Agregue al menos un dato de ronda',
  'isales.agent.message.deleteSkillAndAgentFail': 'Error al eliminar la asociación de cola de habilidades',
  'isales.file.title.rule.validator.ruleId': 'La regla de asignación no existe',
  'isales.taskcalllogic.field.fax': 'Fax\, centralita\, etc',
  'isales.blacklist.title.deleteError': 'Error',
  'isales.task.promotion.gateway': 'Puerta de enlace de envío',
  'isales.training.trainingRate': 'Tasa de formación',
  'isales.taskresult.field.failsCallattemptfails': 'Error al invocar la interfaz de llamada saliente',
  'isales.blacklist.message.confirmDelete': '¿Está seguro de que desea eliminar los números seleccionados de la lista de bloqueo?',
  'isales.tasktemplate.title.manualTemplate': 'Plantilla de llamada saliente manual',
  'isales.management.title.adjustment.log': 'Registro de ajuste',
  'isales.business.result.create': 'Crear resultado de negocio',
  'isales.datasourcemanagement.message.confirDelete': 'Eliminar el dataSource es una operación de alto riesgo. Tenga cuidado al realizar esta operación',
  'isales.tasklogic.message.confirmBatchUpdate': 'La modificación por lotes sólo se aplica a las regiones seleccionadas. ¿Estás seguro de que quieres continuar?',
  'isales.datasourcemanagement.field.backdbIp': 'IP o alias de la base de datos en espera',
  'isales.task.promotion.tobesent': 'Mensajes que se enviarán',
  'isales.taskpolicy.message.obOverTimeRange': 'La duración del tiempo de espera de vista previa está fuera del rango\: 9 a 120. Cambie primero la duración del tiempo de espera de vista previa',
  'isales.work.title.busiFail': 'Negocio fallido',
  'isales.tasktemplate.message.nameDuplicated': 'Ya existe una plantilla de llamada saliente con el mismo nombre',
  'isales.taskresult.title.trainingQuestion': 'Preguntas de prueba de entrenamiento',
  'isales.tasklogic.message.busiresultpolicyNotsaved': 'Algunas directivas de resultados de negocio no se guardan',
  'isales.statistics.field.addHisDetail': 'No\: Se recopilan datos de {0} meses. Sí\: Se recopilan datos de {1} meses',
  'isales.file.title.username.placeholder': 'Por favor ingrese el nombre de usuario',
  'isales.datasourcemanagement.field.backdbId': 'Volver Id de DataSource',
  'isales.taskinfo.message.nameExists': 'El nombre de la tarea de llamada saliente debe ser único',
  'isales.management.title.timeImport': 'Configurar importación programada',
  'isales.common.message.choose': 'Por favor\, seleccione los datos.',
  'isales.task.promotion.message.monthGtThree': 'Solo se pueden consultar los resultados de tres meses',
  'isales.agent.label.deleteCtiFailed': 'Error al eliminar los datos',
  'isales.import.filter.title.callDay': 'Actividad reciente (días)',
  'isales.manual.field.callflag.inprocess': 'Llamar',
  'isales.exportdata.title.exportresult': 'Resultado de exportación',
  'isales.result.resultCode': 'Código de causa',
  'isales.file.title.ip.placeholder': 'Introduzca la dirección IP del servidor',
  'isales.taskmanagment.title.open.obsMonitor': 'Monitoreo de datos OBS',
  'isales.tasktemplate.field.release': 'Publicación',
  'isales.file.title.userNameOrAK': 'Username/AK',
  'isales.data.prop.inputPropCode': 'Introduzca un código de atributo',
  'isales.notification.receiver.delete.error': 'No se selecciona ningún dato',
  'isales.tasklogic.field.callOrderType.noOrder': 'Llamada no ordenada',
  'isales.datasourcemanagement.field.dbPass2': 'Confirma el PassWord',
  'isales.datasourcemanagement.field.dbPass': 'DataSource PassWord',
  'isales.task.promotion.sendStatus': 'Estado de envío',
  'isales.taskpolicy.message.saveFail': 'Error al configurar la directiva de llamadas salientes',
  'isales.agent.button.agent.association': 'Configurar agente',
  'isales.business.result.addchildren': 'Crear subestado',
  'ccivr.ivr.ivrflow.type': 'Tipo',
  'isales.tasklogic.label.primaryCondition': 'Condición principal',
  'isales.tasklogic.field.policy': 'Política de privacidad',
  'isales.task.message.startTips': 'La tarea ha caducado. No se puede iniciar la tarea de llamada saliente',
  'isales.taskresultexport.message.result': 'Resultado',
  'isales.specist.message.updateFail': 'Error en la modificación',
  'isales.specialday.message.dateEmpty': 'La fecha especial está vacía',
  'isales.agent.calledroute.exception': 'Compruebe el estado de Redis o la configuración de CC-Gateway',
  'isales.file.title.rule.interval': 'Columna de intervalo de llamada',
  'isales.taskmanagement.title.viewTaskResult': 'Resultado de la tarea',
  'isales.taskinfo.message.batchEditCallLogic.CallPolicyNotification': 'Las rondas existentes permanecen sin cambios\, y se añadirán nuevas rondas junto a las existentes',
  'isales.task.promotion.dndTime': 'Período de no molestar',
  'isales.taskresult.field.failsBeingchanged': 'La parte llamada está cambiando y la llamada es reenviada',
  'isales.agent.calledroute.editfail': 'Error al editar datos locales',
  'isales.taskcalllogic.title.retryPolicySet': 'Política de llamada de prueba',
  'isales.manualData.message.pickUpSuccess': 'Extracción exitosa',
  'isales.agent.message.curcountpasswordWrong': 'La contraseña de la cuenta actual es incorrecta',
  'isales.specist.title.misjudgment': 'Juzgar mal',
  'isales.agent.button.cancel': 'Cancelar',
  'isales.busi.result.message.systemparams.fail': 'Error al consultar los parámetros del sistema',
  'isales.agent.calledroute.require': 'Establezca todos los campos obligatorios',
  'isales.tasklogic.field.selectProp': 'Añadir atributo',
  'isales.data.import.task.status.ready': 'Preparación',
  'isales.specialday.title.date': 'Fecha especial',
  'isales.taskresult.field.failsInvalidRoutingDevice': 'Dispositivo de enrutamiento no válido',
  'isales.agent.label.maxWaitNum': 'Máx. llamadas en cola',
  'isales.taskresult.title.chooseEndTime': 'Por favor\, seleccione la hora de finalización',
  'isales.manual.message.not.call': 'No se realiza ninguna llamada saliente',
  'isales.taskpolicy.field.maxAvgCallTime': 'Máx. Duración promedio de llamadas',
  'isales.tasklogic.field.value': 'Valor',
  'isales.agent.calledroute.nmsconnect.fail': 'Error al conectarse a ODFS',
  'isales.datasourcemanagement.field.dsType.mysql': 'Iniciar sesión',
  'isales.exportdata.message.nodata': '¡No se puede exportar ningún dato que cumpla con las condiciones\!',
  'isales.label.appraise.call.quality.customer': 'cliente',
  'isales.management.message.batchEditCallLogic.notSupportBusiresultInCMS': 'Las directivas de resultados empresariales no se pueden configurar para las llamadas salientes automáticas. ¿Está seguro de que desea realizar la modificación por lotes?',
  'isales.taskresult.field.failsAutomaticdevicedetected': 'Se detectan dispositivos automáticos',
  'isales.taskTime.message.saveSuccess': 'El tiempo de llamada saliente se cambia correctamente',
  'isales.taskinfo.title.chooseSmsTemplateQuery': 'Seleccionar plantilla SMS',
  'isales.agent.calledroute.ccivrdata.error': 'Error al sincronizar ODFS\, los datos del proceso CC-IVR son incorrectos',
  'isales.agent.pageurls.errorsave': 'Error',
  'isales.datasourcemanagement.message.addDataSourceFail': 'Error al crear DataSource',
  'isales.agent.calledroute.placeholder.accesscode': 'Por favor seleccione un código de acceso',
  'isales.file.title.rule.desc': 'Descripcion',
  'isales.tasklogic.field.orderPolicy': 'Clasificación de llamadas',
  'isales.taskinfo.field.callTimes': 'Intentos de llamada',
  'isales.management.title.adjustment.modifier': 'Ajustado por',
  'isales.specialday.message.confirmDelete': '¿Está seguro de que desea eliminar los datos?',
  'isales.common.title.dataSearch': 'Buscar',
  'isales.taskmanagement.title.startConfirm': '¿Está seguro de que desea iniciar la tarea de llamada saliente seleccionada?',
  'isales.datasourcemanagement.field.dsType.oracle': 'ORÁCLEO',
  'isales.appointment.title.soontimeout': 'La hora de la reserva está a punto de llegar',
  'isales.manual.message.taskstatus.error': 'La llamada saliente no está en el estado de ejecución',
  'isales.manual.title.sendEmail': 'Enviar correo electrónico',
  'isales.tasklogic.message.no.numbertype': 'El número de serie está vacío',
  'isales.business.result.delete.data.error': 'Se puede eliminar un máximo de 100 registros a la vez',
  'isales.taskmanagement.title.batchEditCallPolicy.manualError': 'No se pueden modificar las directivas de llamadas salientes manuales',
  'isales.taskmanagement.message.period.seven': 'El intervalo no puede ser superior a 7 días',
  'isales.appointment.title.editAppoint': 'Modificar llamada saliente reservada',
  'isales.result.outgoingCall.input.description': 'Introduzca el código o la descripción del resultado de la llamada saliente',
  'isales.taskmanagement.title.batchEditTaskInfo.errStatus': 'Seleccione una tarea en estado de borrador\, suspendido o en ejecución',
  'isales.validate.message.notSpecial': 'El nombre no puede contener caracteres especiales',
  'isales.taskmanagement.field.basicTaskInfo': 'Información básica',
  'isales.taskinfo.field.smsTemplate': 'Plantilla SMS para llamada saliente fallida',
  'isales.taskmanagement.title.batchEditCallTime': 'Cambio de lote Tiempo de llamada saliente',
  'isales.taskresult.title.callResult': 'Resultado',
  'isales.task.promotion.message.variableIsNull': 'La lista de configuración de atributos está vacía',
  'isales.specialday.field.Monday': 'Lunes',
  'isales.task.message.startWarning': 'Exportar datos...No se puede iniciar la tarea de llamada saliente',
  'isales.taskstatistics.field.specialCall': 'Lista especial',
  'isales.taskmanagement.field.taskStatus': 'Estado de la tarea',
  'isales.taskmanagment.title.open.agentMonitor': 'Supervisión de agentes',
  'isales.notification.variable.name': 'Nombre de variable',
  'isales.taskresult.title.operationTime': 'Tiempo de operación',
  'isales.appointment.message.callSuccess': 'Exitoso',
  'isales.agent.toblocklist.addType': 'Agregar tipo blockList',
  'isales.task.message.pauseSuccess': 'La tarea de llamada saliente se suspende correctamente',
  'isales.taskresultexport.message.downloadRedischeckfailinfo': 'Un archivo se puede descargar de nuevo solo cuando se haya completado la descarga anterior',
  'isales.agent.label.bindTheSkill': 'La configuración de la parte llamada se ha enlazado. Desvincularlo primero',
  'isales.agent.label.theAgent': 'Agente',
  'isales.calledroute.title.selectaccesscode': 'Elegir un código de acceso',
  'isales.task.promotion.isRetry': 'Reintento al fallar',
  'isales.manual.message.confirmReleaseData': '¿Está seguro de que desea liberar los datos de la llamada saliente?',
  'isales.taskresultexport.title.success': 'Exitoso',
  'isales.common.message.checknonnegativeinteger': 'Introduzca un entero no negativo en el formato correcto',
  'isales.blacklist.message.error': 'Se produce un error cuando se agrega el número a la lista de bloques',
  'isales.taskmanagement.title.deleteFailed': 'Error al eliminar la tarea',
  'isales.agent.message.modifySkillFail': 'Error al actualizar la cola de habilidades',
  'isales.manual.task.survey.noAnswer': 'No se requiere respuesta',
  'isales.task.promotion.statusStart': 'La tarea de marketing multimedia se está ejecutando',
  'isales.agent.calledroute.devicetype': 'Tipo de equipo',
  'isales.manual.field.callinginformation': 'Información de llamadas',
  'isales.training.completeSamples': 'Tamaño de muestra completado',
  'isales.appointment.message.updateSuccess': 'La llamada saliente reservada se modifica correctamente',
  'isales.taskresult.field.failsCallTimeout': 'El tiempo de llamada se agotó',
  'isales.taskresult.field.failsDnd': 'La llamada se rechaza temporalmente',
  'isales.taskmanagement.title.batchPause.statusError': 'Las tareas en estado borrador o caducado no se pueden suspender',
  'isales.specialday.message.deleteFail': 'Error al eliminar la fecha especial',
  'isales.agent.calledroute.extlengthcodewarn': 'La longitud del código de extensión no puede exceder de 12',
  'isales.taskstatistics.field.connectedCallsPie': 'Número de llamadas conectadas',
  'isales.input.message.large': 'El valor de entrada es demasiado grande',
  'isales.datasourcemanagement.field.editDataSource': 'Editar DataSource',
  'isales.taskresult.field.failsSwitchRestart': 'El switch se reinicia',
  'isales.manual.title.viewManualResult': 'Resultado de la llamada saliente del agente-',
  'isales.marketing.mode': 'Modo de marketing',
  'isales.datasourcemanagement.message.addDataSourceSuccess': 'Crear éxito de DataSource',
  'isales.blacklist.message.calledno.exist': 'El número existe en la lista de bloqueo',
  'isales.taskinfo.title.chooseCommonIvrFlow': 'Seleccione un IVR común',
  'isales.taskinfo.placeholder.choose': '--Seleccionar--',
  'isales.taskresult.field.failsTransferunconditionally': 'La llamada está siendo reenviada incondicionalmente',
  'isales.taskmanagement.field.intelligence': 'Llamada saliente inteligente',
  'isales.taskresult.field.failsTimeout': 'El tiempo de espera del sistema',
  'isales.notification.variable.defvalue': 'Valor predeterminado',
  'isales.import.message.endtime': 'Hora de finalización',
  'isales.datasourcemanagement.title.inputVdnId': 'Introduzca un VDNID',
  'isales.import.message.begintime': 'Hora de inicio',
  'isales.specialday.message.createFail': 'Error al crear la fecha especial',
  'isales.task.message.numberEffFeedback': 'Número de llamadas de retroalimentación válidas',
  'isales.agent.label.skills': 'Cola de habilidades',
  'isales.specialday.field.Saturday': 'Sábado',
  'isales.datasourcemanagement.field.viewDataSource': 'Ver DataSource',
  'isales.taskpolicy.field.obCallCount': 'Número de llamadas salientes a la vez',
  'isales.tasklogic.field.prop': 'EMPRESA',
  'isales.agent.label.noAgents': 'Ausencia de agente de servicio',
  'isales.taskmanagement.title.reset': 'Restablecer',
  'isales.task.promotion.tempalteError': 'La plantilla de notificación de marketing no coincide con el tipo de plantilla',
  'isales.data.prop.numberType': 'Dígitos',
  'isales.taskmanagement.field.callCompletedNum': 'Llamadas completadas',
  'isales.management.message.coverMode': 'Sobrescribir',
  'isales.data.prop.propName': 'Nombre del atributo',
  'isales.management.placeholder.surveyName': 'Nombre del cuestionario',
  'isales.taskstatistics.field.numberOfUnconnectedCalls': 'Llamadas no conectadas',
  'isales.tasklogic.field.and': 'Y',
  'isales.management.title.adjustment': 'Ajustar',
  'isales.taskresult.field.failsBusytone': 'Tono ocupado',
  'isales.agent.calledroute.create': 'Agregar configuración llamada',
  'isales.export.message.selectToomach': 'Se puede seleccionar un máximo de 10 registros',
  'isales.file.server.message.checkObsPath': 'Solo se permiten dígitos\, letras\, guiones (-) y puntos (.)',
  'isales.data.prop.isPushAgent': 'Empuje al agente',
  'isales.training.AssociatedSurvey': 'Cuestionario asociado',
  'isales.business.result.description': 'Descripción del resultado del negocio',
  'isales.management.message.import.mapRule': 'Regla de asignación',
  'isales.agent.Transfer.devicetype': 'Tipo de dispositivo de destino',
  'isales.training.answerNumber': 'Número de respuestas',
  'isales.appointment.message.deleteSuccess': 'Las llamadas salientes reservadas se eliminan correctamente',
  'isales.manual.title.lock': 'Bloqueo',
  'isales.data.prop.anonymType': 'Encriptación',
  'isales.task.promotion.message.dndTimeltCrossExpectedTime': 'El tiempo de envío esperado no puede estar en el período de no molestar',
  'isales.common.title.ready': 'Listo',
  'isales.taskinfo.title.chooseIvrFlow': 'Seleccionar flujo IVR',
  'isales.management.message.updateFail': 'Error al modificar los datos de la llamada saliente',
  'isales.file.message.rule.receiver.columnRepeat': 'La columna de destinatario no puede ser la misma que la columna de atributo',
  'isales.result.addOutgoingCallResult': 'Añadir resultado',
  'isales.task.promotion.updateFailed': 'Error al modificar la tarea de marketing multimedia',
  'isales.tasklogic.field.addCallnoLogic': 'Añadir Política de Contacto',
  'isales.taskTime.message.saveFail': 'Error al cambiar el tiempo de llamada saliente',
  'isales.notification.receiver.deletetips': '¿Está seguro de que desea eliminar los datos seleccionados?',
  'isales.taskresult.title.queryTime': 'Información de contacto',
  'isales.manual.message.afterNow': 'La hora de finalización no puede ser anterior a la hora actual',
  'isales.datasourcemanagement.field.backdbSchema': 'Esquema DataSource posterior',
  'isales.agent.label.skillName': 'Nombre de la cola de habilidades',
  'isales.sendStatus.field.fail': 'Error',
  'isales.taskstatus.field.finish': 'Caducado',
  'isales.statistics.field.touchRateDescription': 'Tasa de alcance',
  'isales.taskinfo.message.batchEditCallLogic.CallResultNotification': 'Las políticas existentes se pueden modificar y se añadirán nuevas políticas junto a las existentes',
  'isales.data.title.create': 'Nuevo',
  'isales.file.title.rule.property.name': 'Nombre del atributo',
  'isales.busi.result.item.fail': 'Fallo',
  'isales.task.promotion.saveSuccess': 'La tarea de marketing multimedia se guarda correctamente',
  'isales.import.message.status': 'Estado',
  'isales.taskpolicy.field.lossRate': 'Tarifa mínima de pérdida de llamadas',
  'isales.taskinfo.field.taskType': 'Tipo de tarea',
  'isales.taskresultexport.title.exported': 'Para ser exportado',
  'isales.notification.receiver.number.placeholder': 'Ingrese el número de destinatario',
  'isales.taskcalllogic.message.callNoPolicyEmpty': 'Agregue al menos una política de números',
  'isales.specialday.message.weeklyFormatError': 'El formato de período de fecha especial es incorrecto',
  'isales.training.averageScore': 'Puntuación media',
  'isales.result.view': 'Ver causa de error de llamada saliente',
  'isales.tasklogic.field.callresultpolicy': 'Política de resultados de llamadas salientes',
  'isales.taskpolicy.field.previewTimeoutInterval': 'Pre-escucha Timeout Interval (s)',
  'isales.data.propConfig.title.isPushAgent': 'Empuje al agente',
  'isales.management.message.deleteConfirm': '¿Está seguro de que desea eliminar los datos de llamadas salientes seleccionados?',
  'isales.agent.calledroute.delfail': 'Error en la eliminación de datos locales',
  'isales.work.title.calledNum': 'Llamadas procesadas',
  'isales.audio.title.calltype': 'Tipo de llamada',
  'isales.datasourcemanagement.field.dbUserName': 'Nombre de DataSource',
  'isales.agent.calledroute.extcode': 'código de ensanchamiento',
  'isales.manual.message.recipient': 'Introduzca un destinatario',
  'isales.appointment.message.deleteFail': 'Error al eliminar las llamadas salientes reservadas',
  'isales.taskstatistics.field.numberOfConnectedCalls': 'Llamadas conectadas',
  'isales.taskcalllogic.field.callnoPolicy': 'Política de contacto',
  'isales.taskinfo.title.commonIvrFlow': 'IVR común',
  'isales.specialday.field.Thursday': 'Jueves',
  'isales.management.message.calledNoTwo': 'Segundo número llamado',
  'isales.appointment.title.call': 'Llamar',
  'isales.taskinfo.field.noSpecifiedAgent': 'Cola de habilidades completa',
  'isales.taskinfo.field.specifiedAgent': 'Agente especificado',
  'isales.manual.title.calledNo': 'Número de salida',
  'isales.task.message.completionRate': 'Tasa de finalización de tareas',
  'isales.agent.button.createComplete': 'Finalizar',
  'isales.manual.message.dataId.Empty': 'dataId no puede estar vacío',
  'isales.tasklogic.message.busiresultpolicyNotSaved': 'Algunas directivas de resultados de negocio no se guardan',
  'isales.taskresultexport.title.progress': 'Exportación',
  'isales.task.message.NoStartTask': 'No hay ninguna tarea que cumpla las condiciones disponible',
  'isales.taskresult.field.failsResultnotobtained': 'Los datos de llamadas salientes se escriben cuando no se obtiene ningún resultado después del tiempo de espera de la llamada',
  'isales.taskstatistics.placeholder.called': 'Llamado\: {0}',
  'isales.audio.title.audiorate': 'Tasa de reproducción',
  'isales.agent.message.pwdRepeat': 'La contraseña enviada es la misma que la contraseña cambiada recientemente',
  'isales.result.addAgentTips': 'Se puede añadir un máximo de 20000 agentes',
  'isales.agent.calledroute.ivr': 'Por favor seleccione IVR',
  'isales.busi.result.message.agentstatus.fail': 'Error al consultar el estado del agente',
  'isales.import.message.uploadedfileformatvalidate': 'Formato de archivo incorrecto. Seleccione un archivo CSV',
  'isales.agent.calledroute.recordexist': 'El código de acceso y el código de extensión ya están enlazados\, ingrese un nuevo código de extensión',
  'isales.taskinfo.placeholder.input': '--Entrar--',
  'isales.appointment.message.createFail': 'Error al crear la llamada saliente reservada',
  'isales.taskstatistics.field.failCode': 'Causa de falla',
  'isales.tasklogic.field.logic': 'Operador',
  'isales.management.message.serverImport': 'Uso del servidor',
  'isales.agent.calledroute.updatecalledroute': 'Editar',
  'isales.agent.query.error': 'El agente de destino no puede ser el mismo que el ejecutor del registro seleccionado',
  'isales.audio.title.recording': 'Grabación de vista previa',
  'isales.taskmanagement.title.tip.batchEditTask': 'Seleccione la tarea que desea modificar',
  'isales.task.message.successRate': 'Tasa de éxito',
  'isales.task.title.callBack': 'Rechazar',
  'isales.tasktemplate.title.autoTemplate': 'Plantilla de llamada saliente automática',
  'isales.taskresult.title.exportQuestion': 'Exportar pregunta',
  'isales.business.name': 'Nombre',
  'isales.datasourcemanagement.message.passValidateFailed': 'El valor debe contener al menos tres tipos de los siguientes caracteres\: letras mayúsculas\, minúsculas\, dígitos y caracteres especiales (`-\!{\'@\'}\#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?). ',
  'isales.result.addResultMaptips': 'Se puede añadir un máximo de 50 resultados de llamadas salientes',
  'isales.warning.data.export': 'The exported data contains personal information, Exercise caution when performing this operation',
  'isales.statistics.business.taskId': 'ID de tarea',
  'isales.statistics.business.reportType': 'Tipo de informe',
  'isales.statistics.business.beginDate': 'Fecha de inicio',
  'isales.statistics.business.endDate': 'Fecha de finalización',
  'isales.statistics.business.startYear': 'Año de inicio',
  'isales.statistics.business.startWeek': 'Número de semanas de inicio',
  'isales.statistics.business.startMonth': 'Mes de inicio',
  'isales.statistics.business.endYear': 'Año final',
  'isales.statistics.business.endWeek': 'Semanas finales',
  'isales.statistics.business.endMonth': 'Fin del mes',
  'isales.statistics.business.search': 'Consulta',
  'isales.statistics.business.reset': 'Restablecer',
  'isales.statistics.business.date': 'Fecha',
  'isales.statistics.business.week': 'Número de semanas',
  'isales.statistics.business.month': 'Mes',
  'isales.statistics.business.byDay': 'Periódico diario',
  'isales.statistics.business.byWeek': 'Informe semanal',
  'isales.statistics.business.byMonth': 'Informe mensual',
  'isales.statistics.business.title': 'Informe de servicio de llamadas salientes',
  'isales.statistics.business.trend.title': 'Informe de tendencia del servicio de llamadas salientes',
  'isales.statistics.business.nomorethanerror': 'La fecha seleccionada no puede ser anterior a {0}',
  'isales.statistics.business.currentDateCheck': 'La fecha seleccionada debe ser anterior a la fecha actual',
  'isales.statistics.business.dategt31days': 'El número de días de consulta no puede superar los 31 días',
  'isales.statistics.business.dategt12weeks': 'La semana no puede exceder de 12 semanas',
  'isales.statistics.business.dategt6month': 'El rango de meses no puede exceder de 6 meses',
  'isales.statistics.business.weekLessThan': 'El número de semanas del año final no puede ser menor que el número de semanas del año inicial',
  'isales.statistics.business.monthLessThan': 'El mes final del año no puede ser anterior al mes inicial del año',
  'isales.statistics.business.yearLessThan': 'El año final no puede ser anterior al año inicial',
  'isales.statistics.business.noData': 'Sin datos',
  'isales.statistics.business.noMoreThanEight': 'El número de elementos seleccionados no puede exceder de 8',
  'isales.statistics.business.noMoreThanFive': 'Se puede seleccionar un máximo de 5',
  'isales.statistics.business.busiResult': 'Resultados del negocio',
  'isales.statistics.business.subBusiResult': 'Resultado del subservicio',
  'isales.manual.title.viewPreemptionResult': 'Resultado de llamada saliente predicho-',
  'isales.statistics.business.filteringIndicator': 'Métricas de filtrado',
  'isales.statistics.business.indicatorName': 'Nombre del indicador',
  'isales.statistics.business.description': 'DESCRIPCIÓN',
  'isales.statistics.business.cancel': 'Cancelación',
  'isales.statistics.business.confirm': 'Confirmar',
  'isales.statistics.business.saveError': 'error',
  'isales.statistics.business.errorInfo': 'Error al guardar. Seleccione al menos un indicador',
  'isales.statistics.business.error': 'error',
  'isales.statistics.business.warningFilterMax': 'Se puede seleccionar un máximo de {0} indicadores',
  'isales.agent.message.description.limit': 'La descripción de la cola de habilidades no puede superar los 50 caracteres',
  'isales.agent.message.updateCtiAndDbFail': 'Error al actualizar',
  'isales.taskinfo.message.agent': 'La información del agente es anormal. Compruebe si hay agentes que no estén en la cola de habilidades actual',
  'isales.language.label.language': 'Por favor, seleccione un idioma',
  'isales.language.label.zh_CN': 'Chino',
  'isales.language.label.en_US': 'Inglés',
  'isales.language.label.es_ES': 'Español',
  'isales.taskmanagement.title.batchOpt': 'Operaciones por lotes',
  'isales.audio.title.beginTime': 'hora de inicio de grabación',
  'isales.audio.title.endTime': 'hora de finalización de la grabación',
  'isales.audio.title.asrTransResult': 'Resultados de transcripción de voz',
  'isales.audio.title.confirmedResult': 'Resultados del reconocimiento del tono de llamada entrante',
  'isales.audio.title.confirmStatus': 'Registro de ajustes',
  'isales.taskstatus.field.confirming': 'para ser confirmado',
  'isales.taskstatus.field.confirmed': 'confirmado',
  'isales.rbtresult.field.other': 'otro',
  'isales.rbtresult.field.emptynumber': 'Número vacío',
  'isales.rbtresult.field.cusclose': 'El usuario llamado apaga el teléfono',
  'isales.rbtresult.field.interbusy': 'red ocupada',
  'isales.rbtresult.field.callershutdown': 'Suspensión de llamadas atrasadas / suspensión unidireccional',
  'isales.rbtresult.field.calledshutdown': 'Llamado a parar',
  'isales.rbtresult.field.calledbusy': 'La parte llamada está ocupada y la parte llamada ha registrado la función de llamada en espera',
  'isales.rbtresult.field.calledbusycalling': 'La parte llamada está ocupada y la parte llamada no ha registrado la función de llamada en espera',
  'isales.rbtresult.field.calledoutarea': 'La parte llamada no está en el área de servicio',
  'isales.rbtresult.field.transfer': 'Reenvío de llamadas',
  'isales.rbtresult.field.tollcaller': 'Restricciones de llamadas internacionales',
  'isales.rbtresult.field.longtimemissed': 'mucho tiempo sin llamar',
  'isales.rbtresult.field.calledrestriction': 'El usuario llamado ha establecido restricciones de llamadas entrantes',
  'isales.rbtresult.field.linefaulty': 'Fallo de línea en dirección a otros operadores',
  'isales.rbtresult.field.calledunconnected': 'La persona a la que llama no está disponible y el recordatorio de llamada entrante está habilitado',
  'isales.rbtresult.emptyparam.message': 'La hora de inicio y finalización no puede estar vacía',
  'isales.asrtransresult.field.undefine': 'indefinido',
  'isales.taskmanagement.message.crossmonth': 'La hora de inicio y la hora de finalización no pueden abarcar meses',
  'isales.taskinfo.url.not.whitelist': 'La dirección no está en la lista blanca',
  'isales.whitepopuppageurl.protocol.check': 'El uso de protocolos inseguros conlleva riesgos de seguridad',
  'isales.import.message.ruleExtProp': 'Se han habilitado los atributos personalizados. Configure primero la regla de asignación de importación y\, a continuación\, haga clic en Importar por regla',
  'isales.datasourcemanagement.field.load': 'carga',
  'isales.datasourcemanagement.message.loadSuccess': 'La fuente de datos se cargó correctamente',
  'isales.datasourcemanagement.message.loadfail': 'No se pudo cargar la fuente de datos',
  'isales.datasourcemanagement.message.confirLoad': 'La carga de la configuración de la fuente de datos es una operación de alto riesgo \, proceda con precaución',
  'isales.task.promotion.whatsApp': 'WhatsApp',
  'isales.task.promotion.whatsAppChannel': 'canal WhatsApp',
  'isales.task.promotion.whatsApp.message.template': 'mensaje de plantilla',
  'isales.task.promotion.whatsApp.message.template.select': 'Seleccione un mensaje de plantilla de WhatsApp',
  'isales.task.promotion.whatsApp.channel.select': 'Seleccione un canal de WhatsApp',
  'isales.task.promotion.whatsApp.message.id': 'emplate ID del mensaje',
  'isales.task.promotion.whatsApp.message.name': 'Nombre del mensaje de la plantilla',
  'isales.task.promotion.whatsApp.variable.description': 'Parameter Descripción',
  'isales.task.promotion.whatsApp.variable.content': 'Contenido del parámetro',
  'isales.management.message.SelectNumberMax': 'Seleccione hasta 100 números de llamada a la vez',
  'isales.basicinfo.title.addCallNo': 'Añadir número de llamada',
  'isales.taskmanagement.title.deleteFailExitOne': 'Configure al menos un número de llamada para la tarea de llamada saliente',
  'isales.failInterval.check': 'Puede haber riesgos cuando el intervalo es inferior a 10 s',
  'isales.taskmanagement.title.agentStatistics': 'Estadísticas de trabajo del agente',
  'isales.work.message.daterequest': 'La hora de inicio y finalización debe pasar al mismo tiempo',
  'isales.work.title.selectAgent': 'Seleccionar agente',
  'isales.work.title.agent.workno': 'ID de agente',
  'isales.message.promptForAgent': 'Ingrese el ID del agente',
  'isales.button.search': 'Consultas',
  'isales.button.reset': 'Restablecer',
  'isales.title.agent.workno': 'ID de agente',
  'isales.label.agent.accountcode': 'Cuenta de servicio',
  'isales.agent.work.statistics.AgentName': 'Nombre del agente',
  'isales.button.cancel': 'Cancelar',
  'isales.button.confirm': 'Confirmar',
  'isales.file.message.FilePathTips': 'Este parámetro es opcional. Los archivos de llamadas salientes se almacenan en la ruta predeterminada. Si los archivos de llamadas salientes se almacenan en una carpeta en la ruta predeterminada\, debe establecer la ruta del archivo',
  'isales.blacklist.message.timeserror': 'Error de límite de tiempos',
  'isales.blacklist.message.perioderror': 'Error de período de restricción',
  'isales.datasourcemanagement.message.updateDataSourceSuccess': 'El origen de datos se ha modificado correctamente',
  'isales.datasourcemanagement.message.updateDataSourceFail': 'Error al modificar el origen de datos',
  'isales.taskpolicy.field.utilRateCalculateOne': 'Uso del agente = Duración total de la llamada/Duración total del inicio de sesión',
  'isales.taskpolicy.field.utilRateCalculateTwo': 'Uso del agente = (Duración total de la llamada + Duración del estado de trabajo)/Duración total del inicio de sesión',
  'isales.tasklogic.field.eq': '=',
  'isales.tasklogic.field.neq': '!=',
  'isales.tasklogic.field.egt': '>=',
  'isales.tasklogic.field.elt': '<=',
  'isales.taskmanagement.title.batchEdit': 'Modificación por lotes',
  'isales.task.status.running': 'En curso',
  'isales.task.status.pause': 'Pausa',
  'isales.task.finished': 'Completado',
  'isales.task.notFinished': 'No completado',
  'isales.task.info': 'Información de la tarea',
  'isales.task.benginEndTime': 'Hora de inicio y finalización',
  'isales.task.detail': 'Detalles de la tarea de llamada saliente',
  'isales.taskmanagement.startTask': 'Habilitar tarea',
  'isales.step.taskinfo': 'información de la tarea',
  'isales.step.callpolicy': 'estrategia de salida',
  'isales.step.outboundcalldata': 'datos de la tarea',
  'isales.calltime.title.default.datetime': 'período de tiempo de fecha regular',
  'isales.calltime.title.spec.date': 'fecha especial',
  'isales.calltime.title.add.date': 'añadir fecha especial',
  'isales.calltime.title.add.time.period': 'añadir período de tiempo',
  'isales.calltime.title.holidayName': 'nombre de fecha especial',
  'isales.calltime.title.holidayType': 'tipo',
  'isales.calltime.title.holiday': 'tiempo especial',
  'isales.calltime.title.spec.datetime.period': 'período de tiempo especial',
  'isales.calltime.task.template': 'plantilla de tarea',
  'isales.calltime.task.template.select.placeholder': 'Las tareas se pueden crear a partir de plantillas',
  'isales.calltime.task.callerno.select.placeholder': 'Por favor, seleccione el número de llamada',
  'isales.calltime.task.begin.end.date': 'Hora de inicio y finalización de la tarea',
  'isales.calldata.prop.filter': 'Filtrado de atributos de datos de llamadas salientes',
  'isales.taskinfo.header.policy': 'estrategia de tarea',
  'isales.taskinfo.call.header.policy': 'estrategia de llamadas',
  'isales.taskmanagement.title.sysAuto': 'El sistema llama automáticamente',
  'isales.work.message.dateRangeRequest': 'Seleccione el intervalo de tiempo de consulta',
  'isales.work.message.taskNameLengthLimit': 'El nombre de la tarea no puede superar los 50 caracteres',
  'isales.work.message.workNoLengthLimit': 'La longitud del ID de agente no puede exceder de 5',
  'isales.work.message.workNoTypeLimit': 'El valor debe ser 0 o un entero positivo',
  'isales.label.agent.agentType': 'Tipo de agente',
  'isales.label.agent.role': 'Papel',
  'isales.calleeNo.length.max32': 'La longitud máxima del número de llamada saliente es de 32 caracteres',
  'isales.label.calldata.order': 'secuencia de llamada',
  'isales.tasklogic.field.callOrderMethod.byData': 'Por atributo de datos',
  'isales.tasklogic.field.callOrderMethod.orderType': 'Ordenar por',
  'isales.label.multiple.callno.round': 'Rondas de llamadas de clientes de varios números',
  'isales.task.result.export.query': 'Ir a la vista',
  'isales.task.result.export.skip': 'Skip',
  'isales.task.result.export.message': 'Se ha creado una tarea de exportación. ¿Está seguro de que desea ver la tarea en la lista de exportación?',
  'isales.rbtrecognition.remark.length': 'La longitud de la cadena de caracteres excede el rango de valores. La longitud debe ser menor o igual a 200 caracteres',
  'isales.task.field.callFlag0': 'Para ser ejecutado',
  'isales.task.field.callFlag1': 'Ejecutando',
  'isales.task.field.callFlag2': 'Finalizado',
  'isales.taskinfo.field.agentType4': 'Audiencias',
  'isales.taskinfo.field.agentType11': 'Vídeos',
  'isales.taskinfo.field.agentType5': 'Multimedia',
  'isales.taskinfo.field.agentType99': 'Versátil',
  'isales.appointment.title.appointState': 'Estado de la reserva',
  'isales.task.field.appointState1': 'Reservado',
  'isales.task.field.appointState2': 'Prepárese para la devolución de llamada',
  'isales.task.field.appointState3': 'Devolución de llamada',
  'isales.task.field.appointState4': 'Completado',
  'isales.taskinfo.title.adjustAgent': 'Ajustar agente',
  'isales.taskinfo.check.empty': 'el parámetro no puede estar vacío',
  'isales.tasktype.field.robot.create': 'Nueva llamada saliente de robot',
  'isales.tasktype.field.auto.info': 'IVR común',
  'isales.tasktype.field.intelligent.info': 'robot inteligente',
  'isales.task.result.callrecord.conversation': 'Registro de conversación',
  'isales.taskmanagement.title.pauseTask.askConfirm': '¿Está seguro de que desea suspender la tarea de llamada saliente? Si la tarea se pone en pausa por la fuerza, las llamadas en curso pueden verse afectadas',
  'isales.tasktype.field.auto.robot.create': 'Creación de un IVR común',
  'isales.tasktype.field.intelligent.robot.create': 'Crear un nuevo robot inteligente',
  'isales.management.title.callbot.auto': 'Configure el botón para llamar al robot',
  'isales.taskmanagement.pauseTask': 'Pause Task',
  'isales.taskmanagement.title.taskDataVolume': 'Volumen de datos de tareas',
  'isales.taskmanagement.title.callCompletionRate': 'Tasa de finalización de llamadas',
  'isales.taskmanagement.title.importData.template': 'Plantilla',
  'isales.taskmanagement.title.importData.upload': 'Cargar',
  'isales.taskmanagement.title.importData.selectFile': 'Seleccionar archivo',
  'isales.taskInfo.spec.date.title.quotasTips': 'Esta configuración indica la fecha y el período de tiempo especiales que requieren llamadas salientes',
  'isales.calltime.title.spec.date.empty.warn': 'Fecha especial no configurada!',
  'isales.tasklogic.message.empty.orderNo': 'Hay un número de serie vacío, vuelva a ingresar',
  'isales.taskmanagement.exportResult': 'Resultado de la tarea de exportación',
  'isales.outboundBusiness.statistic.di': 'Semanas',
  'isales.outboundBusiness.statistic.week': ' ',
  'isales.rbtrecognition.remark.tip': 'No puede contener caracteres especiales:',
  'isales.business.callInterval.message.must': 'se requiere intervalo',
  'isales.tasktype.field.robot': 'robot de salida',
  'isales.taskInfo.title.taskTemplate': 'Plantilla de tarea',
  'isales.taskmanagement.title.taskTime': 'Tiempo de tarea',
  'isales.taskmanagement.title.setAgent': 'Asignación de Agentes',
  'isales.taskInfo.title.quotas': 'Cumplimiento',
  'isales.taskInfo.title.quotasTips': 'Cuando el número de llamadas marcado por el agente como resultado del servicio anterior excede el valor de este parámetro, el sistema no recupera automáticamente las llamadas',
  'isales.taskInfo.title.callCompletedNumTips': 'Cuando el número de llamadas completadas excede el valor de este parámetro, el sistema no recupera automáticamente las llamadas',
  'isales.taskInfo.title.pickUpNum': 'Cantidad reclamada de una sola vez',
  'isales.taskInfo.title.pickUpCondition': 'Condiciones de extracción de datos',
  'isales.taskInfo.title.filterCondition': 'Estado de la condición',
  'isales.taskInfo.title.callCompletedNum': 'Llamadas completadas',
  'isales.taskmanagement.title.sysAuto.template': 'Plantilla de llamada saliente automática del sistema',
  'isales.taskmanagement.title.sysAuto.templateAdd': 'Creación de una plantilla de llamada saliente automática del sistema',
  'isales.tasktype.field.robot.template': 'Plantilla de llamada saliente de robot',
  'isales.tasktype.field.robot.templateAdd': 'Creación de una plantilla de llamada saliente de robot',
  'isales.template.title.addManual': 'Plantilla de llamada saliente manual',
  'isales.manual.field.callingRecord': 'Registro de llamadas salientes',
  'isales.call.count': 'Número de llamadas',
  'isales.task.promotion.createBeginTime': 'Hora de inicio de la creación',
  'isales.task.promotion.createEndTime': 'Hora de finalización de la creación',
  'isales.task.promotion.timeerror': 'El tiempo efectivo debe ser anterior al tiempo de caducidad',
  'isales.busiresult.description.length': 'La longitud de la cadena de caracteres excede el rango de valores. La longitud deberá ser inferior o igual a 500 caracteres',
  'isales.import.coverMode.tips': 'La importación de sobrescritura eliminará todos los datos que se van a llamar, lo que lleva mucho tiempo. Tenga cuidado al seleccionar este parámetro',
  'isales.taskmanagement.title.taskContent': 'Contenido de la tarea',
  'isales.taskmanagement.title.saveAndImportData': 'Guardar e importar datos',
  'isales.task.promotion.numberOnly': 'El valor debe ser un entero no negativo',
  'isales.data.lock': 'Algunos datos de llamadas salientes no se procesan. Por ejemplo, está importando, agregando o eliminando datos de llamadas salientes. Por favor, inténtelo de nuevo más tarde',
  'isales.survey.type': 'tipo de cuestionario',
  'ccsurvey.label.scenarioType.manual': 'cuestionario manual',
  'ccsurvey.label.scenarioType.manualIvr': 'Manual vs. Cuestionario IVR',
  'isales.tasktype.field.manualIvr.info': 'Juega el proceso del cuestionario',
  'ccsurvey.info.tip36': 'Se requiere la pregunta',
  'ccsurvey.transfer.tip36': 'Suspender turno a cuestionario de reproducción IVR',
  'isales.taskinfo.message.callintervalrangeMax': 'Introduzca un número entero positivo entre 1 y 86400.',
  'isales.notification.receiver.whatsapp.placeholder': 'Introduzca el número de destinatario con el código de área internacional',
  'isales.data.title.more': 'Más información',
  'isales.manual.exit.preview': 'Realizar una llamada saliente de vista previa',
  'isales.manual.exit.preview.tips': 'Si se agota el tiempo de vista previa o se cierra la ventana, el sistema le llamará automáticamente',
  'isales.result.search.displayResultOrCode': 'Por favor ingrese código o descripción',
  'isales.management.message.dbIp': 'El formato de IP es incorrecto',
  'isales.agent.message.modifyPwdVdnFailed': '¡No está permitido modificar la contraseña del administrador de configuración!',
  'isales.taskmanagement.title.addSysAuto': 'Nuevo sistema de llamada saliente automática',
  'isales.datasourcemanagement.field.dsType.dm': 'Damang',
  'isales.operlog.message.exportSurveyData': 'Exportar preguntas de prueba de formación práctica',
  'isales.appointment.message.plaintext': 'Los usuarios no pueden iniciar llamadas sin permisos de texto claro. Habilite los permisos de texto claro.',
  'isales.taskmanagement.title.batchEditTaskInfo.errTaskType': 'No se pueden modificar diferentes tipos de tareas en lotes',
  'isales.business.result.message.checkCodePoint': 'Sólo se pueden introducir números, letras, -,.',
  'isales.import.calldata.selecttime': 'Seleccione el tiempo de ejecución',
  'isales.statistics.message.chooseCondition': 'Please select query conditions first',
  'isales.taskpolicy.field.time': '(Segundo)',
  'isales.taskInfo.title.filterSubCondition': 'Filtrar subcondiciones',
  'isales.agent.work.statistics.tableTips': 'Seleccione la tarea de llamada saliente o el agente que desea ver.',
  'isales.file.message.rule.add.success': 'La regla se ha creado correctamente.',
  'isales.file.title.server.add.success': 'La información del servidor se ha creado correctamente.',
  'isales.management.title.selectResult':'Seleccionar resultado de la llamada saliente',
  'isales.specialday.placeholder.year.beginTime': 'El formato es MM-DD, por ejemplo, 12-01',
  'isales.management.message.appointTime': 'La hora de llamada reservada no puede ser anterior a la hora actual del sistema',
  'isales.management.message.intelligence.taskName': 'La longitud del nombre de tarea del tipo de llamada saliente del robot inteligente no puede exceder de 36 bytes. Una letra o número ocupa un byte, y un carácter chino ocupa tres bytes',
}
