<template>
  <sweet-dialog :title="title" v-model="dialogVisible" width="616px" class="aicc-dialog-limit-height" :close-on-click-modal="false">
    <sweet-form ref="dataFormForCreate" :rules="rules" :model="ruleBaseInfo" label-position="left" label-width="auto">
      <sweet-form-item :label="$t('oifde.rule.update.ruleName')" prop="ruleName" showErrorTips="true">
        <sweet-input v-model="ruleBaseInfo.ruleName" :disabled="isOnlyRead" :placeholder="$t('aicc.input')" style="width: 400px"/>
      </sweet-form-item>
      <sweet-form-item :label="$t('oifde.rule.update.beginNum')" prop="beginNum" showErrorTips="true">
        <sweet-input v-model="ruleBaseInfo.beginNum" :disabled="isOnlyRead" :placeholder="$t('aicc.input')" style="width: 400px"/>
      </sweet-form-item>
      <sweet-form-item :label="$t('oifde.rule.update.endNum')" prop="endNum" showErrorTips="true">
        <sweet-input v-model="ruleBaseInfo.endNum" :disabled="isOnlyRead" :placeholder="$t('aicc.input')" style="width: 400px"/>
      </sweet-form-item>
    </sweet-form>
    <template #footer>
      <span class="dialog-footer" v-if="!isOnlyRead">
        <sweet-button @click="dialogVisible = false">{{ $t('oifde.table.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="createOrUpdateData">{{ $t('oifde.table.save') }}</sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script>
import {createRuleInfo, updateRuleInfo} from "@/views/oifde/request/api/rule";
import {ElMessage} from "element-plus";

export default {
  name: 'addEditRule',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: String,
    ruleBaseInfo: {
      ruleName: String,
      beginNum: String,
      endNum: String
    },
    isOnlyRead: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  computed: {
    rules() {
      const beginNumLengthValidator = (rule, value, callback) => {
        if (
            this.ruleBaseInfo.beginNum >
            this.ruleBaseInfo.endNum
        ) {
          callback(new Error(this.$t('oifde.rule.rules.beginNumLengthValidator')))
        } else {
          callback()
        }
      }
      return {
        ruleName: [
          {
            required: true,
            message: this.$t('oifde.rule.update.newRuleName'),
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[\u4e00-\u9fa5\u0e00-\u0e7f\u0080-\u00ff\sa-zA-Z0-9_'-]+$/,
            message: this.$t('oifde.common.validation.openNameCharReg'),
            trigger: ['blur', 'change']
          },
          {
            maxLength: 1024,
            validator: this.$Validate.maxLengthValidator,
            trigger: ['blur', 'change']
          }
        ],
        beginNum: [
          {
            required: true,
            message: this.$t('oifde.rule.update.newBeginNum'),
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[A-Za-z0-9\s@._'-:\/]*$/,
            message: this.$t('oifde.rule.rules.beginNumValidator'),
            trigger: ['blur', 'change']
          },
          { validator: beginNumLengthValidator, trigger: ['blur', 'change'] },
          {
            maxLength: 64,
            validator: this.$Validate.maxLengthValidator,
            trigger: ['blur', 'change']
          }
        ],
        endNum: [
          {
            required: true,
            message: this.$t('oifde.rule.update.newEndNum'),
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[A-Za-z0-9\s@._'-:\/]*$/,
            message: this.$t('oifde.rule.rules.endNumValidator'),
            trigger: ['blur', 'change']
          },
          { validator: beginNumLengthValidator, trigger: ['blur'] },
          {
            maxLength: 64,
            validator: this.$Validate.maxLengthValidator,
            trigger: ['blur', 'change']
          }
        ]
      }
    },
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods: {
    async createOrUpdateData() {
      this.$refs.dataFormForCreate.validate(async valid => {
        if (valid) {
          const ruleData = Object.assign({}, this.ruleBaseInfo)
          if (this.title === this.$t('oifde.title.add.rule')) {
            await createRuleInfo(ruleData).then(res => {
              if (res.ruleId) {
                ElMessage({
                  type: 'success',
                  duration: 5000,
                  offset: 56,
                  message: this.$t(`oifde.rule.actions.addSuccess`)
                })
                this.$emit('addEditRuleConfirm')
                this.dialogVisible = false
              }
            }).catch(e => {
              if (e.response.data.errorCode) {
                ElMessage({
                  type: 'error',
                  duration: 10000,
                  showClose: true,
                  offset: 56,
                  message: e.response.data.errorMessage
                })
              }
            })
          }
          if (this.title === this.$t('oifde.title.edit.rule')) {
            await updateRuleInfo(ruleData).then(() => {
              ElMessage({
                type: 'success',
                duration: 5000,
                offset: 56,
                message: this.$t(`oifde.rule.actions.updateSuccess`)
              })
              this.$emit('addEditRuleConfirm')
              this.dialogVisible = false
            }).catch(e => {
              if (e.response.data.errorCode) {
                ElMessage({
                  type: 'error',
                  duration: 10000,
                  showClose: true,
                  offset: 56,
                  message: e.response.data.errorMessage
                })
              }
            })
          }
        }
      })
    },
  }
}
</script>

<style scoped>
:deep(.el-form-item__error span){
  white-space: normal!important;
}
</style>
