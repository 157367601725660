<template>
  <sweet-col ref="bodyContent" class="ccma-contactdetail-body-content">
    <sweet-row>
    <ccm-mock-header v-if="!isSink" :headerTitle="headerTitle" :routeName="routeName" />
    <teleport v-if="teleportShow" :to="teleportId">
      <div class="right-operation-content">
        <sweet-tooltip v-if="callIdIVR && traceInfoAuth && !isFromWorkbench" :content="i18n('ccm.ivr.button.traceInfo')" effect="light">
          <div @click="queryInfo">
            <img class="icon-button show" src="@/assets/img/flow/ic_process_track.png"/>
          </div>
        </sweet-tooltip>
        <sweet-tooltip v-if="isSink" :content="i18n('aicc.previous')"  effect="light">
          <div class="icon-button" @click="previousRecord">
            <img :class="{show: hasPrevious, disabled: !hasPrevious}" src="@/assets/img/arrow/ic_arrow_left.png"/>
          </div>
        </sweet-tooltip>
        <sweet-tooltip v-if="isSink" :content="i18n('aicc.next')" effect="light">
          <div class="icon-button" @click="nextRecord">
            <img :class="{show: hasNext, disabled: !hasNext}" src="@/assets/img/arrow/ic_arrow_right.png" />
          </div>
        </sweet-tooltip>
        <sweet-tooltip :content="i18n('aicc.more')" disabled  effect="light">
          <div class="icon-button" style="display: none">
            <img src="@/assets/img/arrow/ic_more.png" />
          </div>
        </sweet-tooltip>
      </div>
    </teleport>
    </sweet-row>
    <sweet-row style="height: 100%">
      <div ref="leftArea" class="left-area aicc-p-around--none">
        <div v-if="playAuth" ref="mediaArea" class="left-area--media">
          <!--音视频区域-->
            <audio-video-tab-list ref="audioVideoTabListRef" :callSerialNo="callSerialNo" :isFromWorkbench="isFromWorkbench" :resp="respCache" @client-height="getHeight"></audio-video-tab-list>
        </div>
        <div class="left-area--detail">
          <sweet-tabs id="aicc-ccma-contactdetail-tab" v-model="leftActiveTab">
            <sweet-tab-pane v-if="isShowMedia && isMultimedia" :label="i18n('ccm.agent.contact.talkcontent')" name="multimedia">
              <!--多媒体信息区域-->
              <Suspense>
                <template #default>
                  <TalkContent :beginTime="beginTime" :callSerialNo="callSerialNo" :isFromWorkbench='isFromWorkbench' :resp="respCache"></TalkContent>
                </template>
              </Suspense>
            </sweet-tab-pane>
            <sweet-tab-pane :label="i18n('ccm.agent.contact.contactinfo')" class="aicc-p-left--medium-contact" name="contactInfo">
              <div ref="contactInfoTabPane">
                <!--接触记录信息-->
                <contact-info-panel v-if="isInitSuccess" :audio-video-area-height="computedAudioPlayHeight" :columns="columns" :contact-items="contactItems" :has-content="hasContent" :if-from-workbench="isFromWorkbench"/>
              </div>
            </sweet-tab-pane>
            <sweet-tab-pane v-if="isShowMedia && !isMultimedia && intelligentShowList?.length > 0" :label="i18n('ccm.agent.contact.transfercontent')" name="transcription">
              <!--转写内容区-->
              <div ref="contactInfoTabPane">
                <RealTimeTransfer v-if="isInitSuccess" :audio-video-height="computedAudioPlayHeight" :callSerialNo="callSerialNo" :intelligentList="intelligentShowList" :resp="respCache"/>
              </div>
            </sweet-tab-pane>
          </sweet-tabs>
        </div>
      </div>
      <div ref="rightArea" class="right-area">
        <sweet-tabs v-model="rightActiveTab" class="right-area-tabs" type="card">
          <sweet-tab-pane id="talkReason" :label="i18n('ccm.agent.contact.talkReason')" name="contactReason">
            <talk-reason-panel v-if="isInitSuccess" :is-from-menu="isSink && !isFromWorkbench" :talk-reason="talkReason" :talk-remark="talkRemark" />
          </sweet-tab-pane>
          <sweet-tab-pane :label="i18n('ccm.agent.contact.sameContactRecord')" name="sametimeRecords">
            <same-record v-if="isInitSuccess" :call-serialno="callSerialNo"/>
          </sweet-tab-pane>
          <sweet-tab-pane :label="i18n('ccm.agent.contact.transferRecord')" name="transferRecords">
            <transfer-records :call-Serialno="callSerialNo" :contact-id="contactId"/>
          </sweet-tab-pane>
          <sweet-tab-pane v-if="isInitSuccess && !isMultimedia" :label="i18n('ccm.contact.detail.message.sending.record')" name="messageRecords">
            <contact-message-sending-record :call-serial-no="callSerialNo" :call-type="callType" :count-id="countId" :msgData="messageData"/>
          </sweet-tab-pane>
          <sweet-tab-pane v-if="isInitSuccess" :label="i18n('ccm.agent.contact.info')" name="documentInfos">
            <contact-contract-view :contact-info="contactInfo" :download-auth="downloadAuth" :preview-auth="previewAuth"></contact-contract-view>
          </sweet-tab-pane>
        </sweet-tabs>
      </div>
    </sweet-row>
  </sweet-col>

</template>

<script lang="ts" setup>
import {
  computed,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  provide,
  reactive,
  readonly,
  ref,
  toRefs,
  watch
} from "vue";
import {useRoute} from "vue-router";
import {
  initContactItemConfig,
  queryAgentContactRecord,
  queryCallReasonInfo,
  queryContactExtRecord
} from "@/views/ccmanagement/ccma-api/index.js";
import cookieUtils from "@/utils/cookie.js";
import timeUtils from "@/utils/timeUtils";
import {deepClone} from "@vitest/utils";
import TalkReasonPanel from "@/views/ccmanagement/pages/contract-record-detail/talk-reason-panel.vue";
import SameRecord from "@/views/ccmanagement/pages/contract-record-detail/same-record.vue";
import {formatFlowName} from "@/views/oifde/utils/common";
import {
  callTypeMap,
  mediaAbilityMap,
  MULTIMEDIA_MESSAGE_LIMIT,
  reasonCodeTypeMap,
  staffHangupMap
} from "@/views/ccmanagement/common/constants";
import ContactInfoPanel from "@/views/ccmanagement/pages/contract-record-detail/contact-info-panel.vue";
import AudioVideoTabList from "@/views/ccmanagement/pages/contract-record-detail/audio-video-tab-list.vue";
import CcmMockHeader from "@/views/ccmanagement/pages/ccm-mock-header/ccm-mock-header.vue";
import {CallSerialInfo, useContactInfoPageStore} from "@/views/ccmanagement/stores/contact-detail-store";
import RealTimeTransfer from "@/views/ccmanagement/pages/contract-record-detail/realtime-transfer.vue";
import ContactMessageSendingRecord
  from "@/views/ccmanagement/pages/contract-record-detail/contact-message-sending-record.vue";
import ContactContractView from "@/views/ccmanagement/pages/contract-record-detail/contact-contract-view.vue";

import dayjs from "dayjs";
import AiccElMessage from '@/utils/el-message';
import TransferRecords from "@/views/ccmanagement/pages/contract-record-detail/transfer-records.vue";
import {querySessionLog} from "@/views/oifde/request/api/traceLog";

// 异步加载多媒体会话内容
const TalkContent = defineAsyncComponent(() => import('@/views/ccmanagement/pages/contract-record-detail/TalkContent.vue'));
const props = defineProps({
  callSerialno: {
    required: true,
    type: [String, Number]
  },
  originMenuId: {
    required: true,
    type: [String, Number]
  },
  beginTime: {
    required: true,
    type: [String, Number, Date]
  },
  callId: {
    required: false,
    type: [String, Number],
  },
  moduleType: {
    required: false,
    type: String,
  },
  isAgentOrigin: {
    required: false,
    type: [String, Boolean],
    default: false,
  },
  teleportTo: {
    required: false,
    type: String,
  }
})
/**
 * 接触详情路由参数接口
 */
declare interface ContactDetailRouteParam {
  callSerialno: String|Number,
  beginTime: String|Number|Date|dayjs.Dayjs,
  originMenuId?: String|Number, // 原跳转菜单id标识
  callId?: String|Number, // 呼叫id，非接触记录列表页面跳转时，必传
  moduleType?: String,
  isAgentOrigin?: Boolean, // 是否需要agent相关权限，用于确定具体的查询详情接口
}

const { appContext: { config: { globalProperties } } } = getCurrentInstance() as any;
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args);
};
const routeQuery = computed(() => route.query);
const bodyContent = ref();
const leftArea = ref();
const rightArea = ref();
const mediaArea = ref();
const contactInfoTabPane = ref(); // 接触信息滚动条对象
const leftActiveTab = ref<string>("contactInfo");
const rightActiveTab = ref<string>('contactReason');
const route = useRoute();
const currRouteName = ref();  // 当前路由名称，取页面初始化时的值
const routeParams = reactive<ContactDetailRouteParam>({
  callSerialno: "",
  beginTime: "",
  originMenuId: "",
});
// 判断是否来源于工作台
const isFromWorkbench = computed(() => {
  return routeParams.isAgentOrigin || originMenuId.value == "6006" || originMenuId.value == "6005";
});
const teleportId = computed(() => {
  const parentElement = isSink.value ? "#right-content-" : "#mock-right-content-";
  return parentElement + currRouteName.value?.toString();
});
const teleportShow = ref<boolean>(false); // 用于是否展示传送内容
const callSerialNo = ref<any>(String(routeQuery.value.callSerialno)); // 呼叫流水号
const beginTime = ref<any>(); // 开始时间
const originMenuId = ref<String | Number>();
const routeName = computed(() => route.name);
const contactInfo = ref<any>(); // 接触详情
const playAuth = ref<Boolean>();
const contactItems = ref<Array<any>>(); // 条目信息
const columns = ref<Array<any>>(); // 列信息
const recordId = ref<string>(); // 呼叫id
const userId = ref<string | number>();
const callId = ref<string | number>(); // 呼叫id
const contactId = ref<string | number>(); // 接触id
const callIdIVR = ref<string>(); // 呼叫ivr
const traceInfoAuth = ref<Boolean>();
const associateCall = ref<string>();
const talkingNotifyUrl = ref<string>(); // 接通回调URL
const releaseNotifyUrl = ref<string>(); // 挂机回调URL
const isExist = ref<boolean>(false);    // 双呼类型是否有录音
const downloadAuth = ref<boolean>(false); // 是否存在下载权限
const previewAuth = ref<boolean>(false); // 是否存在预览权限
const contactModel = ref<any>({});
const isMultimedia = ref<boolean>(false); // 是否是多媒体
const talkReason = ref(); // 来电原因
const talkRemark = ref();
const talkReasonObj = ref<any>();
const isInitSuccess = ref<boolean>(false); // 数据是否加载完成，完成后供子组件渲染
const isShowMedia = ref<Boolean>(false); // 是否展示媒体区域
const pageInfoStore = useContactInfoPageStore(); // 翻页信息
const hasPrevious = ref<Boolean>(false);
const hasNext = ref<Boolean>(false);
const headerTitle = computed<String|Number>(() => routeParams.callSerialno); // 展示标题
// 是否为下沉页
const isSink = computed(() => {
  const name = route.name?.toString() || '';
  return ['ccmaContactDetailAgentWorkbenchSink', 'ccmaContactDetailCaseWorkbenchSink', 'ccmaContactDetailSideMenuSink'].includes(name)
});
const canShowMsgSndRecordTab = ref<boolean>(false)
const countId = ref<string>()
const callType = ref<string | number>();
const mediaTypeId = ref<String>()
const messageData = ref();

const intelligentShowList = ref<Array<any>>([]); // asrcontent
const hasContent = ref<Boolean>(false);

watch(() => routeQuery.value, () => {
  // 指定路由name，才触发更新逻辑
  if (routeName.value === currRouteName.value && routeParams.callSerialno !== routeQuery.value.callSerialno) {
    initPage();
  }
})

const previousRecord = () => {
  if (!hasPrevious.value) {
    return;
  }
  const info = pageInfoStore.getPreviousInfo(originMenuId.value || "");
  if (info) {
    globalProperties.$event.addPageToTab({
      title: info.callSerialno,
      name: routeName.value,
      query: getRecordQueryParam(info)
    });
  }
};

const getRecordQueryParam = (info :CallSerialInfo) => {
  const queryParam: any = {
    callSerialno: info.callSerialno,
    beginTime: timeUtils.getUTCTimestamp(info.beginTime?.toString()),
    originMenuId: originMenuId.value,
    isSink: true
  };
  if (routeParams.moduleType) {
    queryParam.moduleType = routeParams.moduleType;
  }
  if (routeParams.isAgentOrigin !== undefined && routeParams.isAgentOrigin !== null) {
    queryParam.isAgentOrigin = routeParams.isAgentOrigin;
  }
  return queryParam;
}

const nextRecord = () => {
  if (!hasNext.value) {
    return;
  }
  const info = pageInfoStore.getNextInfo(originMenuId.value || "");
  if (info) {
    globalProperties.$event.addPageToTab({
      title: info.callSerialno,
      name: routeName.value,
      query: getRecordQueryParam(info)
    });
  }
};

/**
 * 初始化表头信息
 */
const initContactBasicDataConfig = () => {
  initContactItemConfig().then((resp: any) => {
    if (resp && resp["returnCode"] == 0) {
      // 查询接触记录数据配置项
      queryContactDetail();
    }
  });
};
const respCache = ref();
const queryContactDetail = () => {
  const param : any = {
    callSerialno: callSerialNo.value,
    beginTime: beginTime.value,
    locale: cookieUtils.getCookie("u-locale"),
    limit: MULTIMEDIA_MESSAGE_LIMIT,
    offset: 0
  };
  if (isFromWorkbench.value) {
    param.moduleType = routeParams.moduleType || '';
  }
  const queryMethod = isFromWorkbench.value ? queryAgentContactRecord : queryContactExtRecord;
   queryMethod(param).then((resp: any) => responseHandler(resp))
     .finally(() => isInitSuccess.value = true);
};

const responseHandler = (resp: any) => {
  // 针对工作台跳转，需要座席处于工作态
  if (isFromWorkbench.value && !resp) {
    AiccElMessage.error(i18n('ccm.agent.agentcontact.nosearch'))
    columns.value = void 0;
    contactItems.value = void 0;
    respCache.value = {};
    return;
  }
  if (!resp.column || resp.column.length < 1) {
    initContactBasicDataConfig();
    return;
  }
  if (resp.skillId == -1) {
    resp.skillId = null;
  }
  respCache.value = deepClone(resp);
  playAuth.value = resp.playAuth;
  if (!resp) {
    return;
  }
  dataHandle(resp);
  contactModel.value.mediaAbility = mediaAbilityMap.get(String(resp.mediaAbility));
  if (associateCall.value) {
    contactModel.value.reasonCode = reasonCodeTypeMap.get(String(resp.reasonCode));
  } else if(resp.mediaAbility == 5){
    let i = contactItems.value.indexOf("mediaAbility") + 1
    contactItems.value.splice(i, 0, "mediaType")
    columns.value.splice(i, 0, i18n('ccm.satisfactionconfig.message.media'))
    isShowMedia.value =true
  } else if (resp.intelligentList?.length > 0) {
    isShowMedia.value =true
    const intelligentList : Array<any> = deepClone<Array<any>>(resp.intelligentList);
    for (let i = 0; i < intelligentList.length; i++) {
      intelligentList[i].asrContent  = JSON.parse(intelligentList[i].asrContent);
      const content = (intelligentList[i].asrContent || {}).content
      if (content && content.length > 0) {
        try {
          if (intelligentList[i].asrContent.content.indexOf(';') != -1) {
            intelligentList[i].asrContent.content = (content.substring(content.indexOf('ult ') + 4, content.indexOf('>}'))).replace(';', "");
          } else {
            intelligentList[i].asrContent.content = (content.substring(content.indexOf('ult ') + 4, content.indexOf('>}')));
          }
          if (hasChinese(content)) {
            intelligentList[i].asrContent.content  = intelligentList[i].asrContent.content.replace(/\s/g, "");
          }
        } catch (error) {
          intelligentList[i].asrContent.content = content + 'error';
        }
        intelligentList[i].asrContent["contentDisplay"] = intelligentList[i].asrContent.content;
      }
    }
    intelligentShowList.value = intelligentList;
  }
  if (resp.talkReason) {
    talkReason.value = resp.talkReason;
    talkRemark.value = resp.talkRemark;
    queryCallReason(resp.talkReason);
  }
  leftActiveTab.value = isShowMedia.value && isMultimedia.value ? 'multimedia' : 'contactInfo';
  rightActiveTab.value = 'contactReason';
  hasContent.value = true;
};
const hasChinese = (testWord: any) => {
  var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
  return reg.test(testWord);
};
const dataHandle = (resp: any) => {
  contactInfo.value = resp;
  columns.value = resp.column;
  contactItems.value = resp.contactItem;
  if (resp.beginTime) {
    resp.beginTime = timeUtils.transTime(resp.beginTime);
  }
  if (resp.endTime) {
    resp.endTime = timeUtils.transTime(resp.endTime);
  }
  callType.value = resp.calltype;
  resp.calltype = callTypeMap.get(String(resp.calltype));
  resp.staffHangup = staffHangupMap.get(String(resp.staffHangup));
  recordId.value = resp.recordId;
  userId.value = resp.acceptNo;
  callId.value = resp.callId;
  contactId.value = resp.contactId;
  callIdIVR.value = resp.ivrCallId;
  traceInfoAuth.value = resp.flowMgmtAuth;
  associateCall.value = resp.associateCall;
  talkingNotifyUrl.value = resp.talkingNotifyUrl;
  releaseNotifyUrl.value = resp.releaseNotifyUrl;
  talkReasonObj.value = {
    talkRemark: resp.talkRemark,
    talkReason: resp.talkReason
  };
  isExist.value = resp.isExist;
  downloadAuth.value = resp.download;
  previewAuth.value = resp.preview;
  contractInfoProcess(resp.contractInfo);

  const mediaAbility: number = resp.mediaAbility;

  // 是否多媒体类型
  isMultimedia.value = mediaAbility == 5;
  playAuth.value = mediaAbility == 5 ? false : playAuth.value

  // 消息发送记录权限
  canShowMsgSndRecordTab.value= mediaAbility == 5;
  countId.value = resp.countId;
  mediaTypeId.value = resp.mediaTypeId;
  messageData.value = toRefs(resp)
};

const contractInfoProcess = (contact: any): void => {
  if (!contact) {
    return;
  }
  contactInfo.value = contact;
  for (const item of contactInfo.value) {
    for (const proto in item) {
      if (proto == "create_time" || proto == "update_time") {
        item[proto] = timeUtils.transTime(item[proto]);
      }
    }
  }
};
const queryCallReason = (talkReason: string) => {
  queryCallReasonInfo().then((resp: any) => {
    if (!resp || !talkReason) {
      return;
    }
    const set: Set<string> = new Set<string>(talkReason.split(","));
    const talkReasonNameToRootList: Array<any> = [];
    Object.values(resp).forEach((item: any) => {
      if (set.has(String(item.id))) {
        talkReasonNameToRootList.push({key: item.id, name: buildTalkReasonNameToRoot(item.id, resp)})
      }
    })
    talkReasonObj.value.talkReasonNameToRootList = talkReasonNameToRootList;
  })
}

const buildTalkReasonNameToRoot = (id: string|number, data: any): string => {
  const talkReasonNames : Array<any> = [];
  findParentToRoot(id, data, talkReasonNames);
  let talkReasonNameToRoot: string = '';
  if (talkReasonNames.length > 0) {
    for (let i = talkReasonNames.length - 1; i >= 0 ; i--) {
      talkReasonNameToRoot += talkReasonNames[i];
      if (i > 0) {
        talkReasonNameToRoot += ' >';
      }
    }
  }
  return talkReasonNameToRoot;
}

const findParentToRoot = (id: string|number, data: any, talkReasonNameList: Array<any>) => {
  const currentData = data.find((item: any) => item.id == id);
  if (currentData) {
    talkReasonNameList.push(currentData.name);
    if (currentData.parentId != '0') {
      findParentToRoot(currentData.parentId, data, talkReasonNameList);
    }
  }
}

/**
 * 查看IVR流程信息
 */
const queryInfo = () => {
  const queryParam = {
    callId: callIdIVR.value,
    pageNum: 1,
    pageSize: 5
  }
  querySessionLog(queryParam).then(response => {
    if (response.total == 0) {
      AiccElMessage({
        message: i18n('ccm.contactRecordDetail.notSetTraceLogError'),
        type: 'warning'
      });
      return;
    }
    const sessionLog = response.sessionLogList[0];
    let diagramSource = sessionLog.source === "IVR" ? "ccivr" : "oifde"
    window.showTab({
      title: `${i18n('oifde.mxgraph.view')}-${formatFlowName(sessionLog.flowName)}`,
      name: "oifdeDiagram",
      id: `oifdeDiagram_viewtrace_${sessionLog.callId}`,
      query: {
        title: `${i18n('oifde.mxgraph.view')}-${formatFlowName(sessionLog.flowName)}`,
        name: "oifdeDiagram",
        routerId: `oifdeDiagram_viewtrace_${sessionLog.callId}`,
        source: diagramSource,
        operationType: "view",
        isTraceLog: true,
        flowId: sessionLog.flowId,
        callId: sessionLog.callId,
        flowName: sessionLog.flowName
      },
    });
  })
};

// 依赖注入：提供依赖项——呼叫流水号
provide("callSerialNo", readonly(callSerialNo));
provide("resp", computed(() => respCache.value));
provide('isFromWorkbench', computed(() => isFromWorkbench.value || false))

const initPage = () => {
  isInitSuccess.value = false;
  callSerialNo.value = routeQuery.value.callSerialno;
  beginTime.value = routeQuery.value.beginTime;
  originMenuId.value = routeQuery.value.originMenuId?.toString() || "";
  hasPrevious.value = pageInfoStore.hasPrevious(originMenuId.value);
  hasNext.value = pageInfoStore.hasNext(originMenuId.value);
  routeParams.callSerialno = callSerialNo.value;
  routeParams.beginTime = beginTime.value;
  routeParams.originMenuId = route.query.originMenuId?.toString() || "";
  if (route.query.callId || route.query.callid) {
    routeParams.callId = route.query.callId?.toString() || route.query.callid?.toString();
  }
  if (routeQuery.value.moduleType) {
    routeParams.moduleType = routeQuery.value.moduleType.toString();
  }
  if (routeQuery.value.isAgentOrigin != null) {
    routeParams.isAgentOrigin = routeQuery.value.isAgentOrigin == 'true';
  }
  hasContent.value = false;
  talkReason.value = void 0;
  talkRemark.value = void 0;
  queryContactDetail();
  // 延迟展示传送内容，防止出现内容未渲染的问题
  setTimeout(() => teleportShow.value = routeName.value === currRouteName.value, 100)
}

onMounted(() => {
  currRouteName.value = routeName.value
  initPage();
});

const audioVideoTabListRef = ref(); // 音视频页面区域ref
const audioVideoAreaHeight = ref<number>(0); // 音视频组件高度
/**
 * 获取音视频组件高度
 * @param height 音视频组件高度，单位px
 */
const getHeight = (height: number) => audioVideoAreaHeight.value = height;
const computedAudioPlayHeight = computed<number>(() => playAuth.value ? audioVideoAreaHeight.value : 0)
</script>

<style lang="less" scoped>
 .left-area--detail :deep(.el-tabs__header){
   padding: 0 20px !important;
 }
 .aicc-p-left--medium-contact{

 }
.right-operation-content {
  display: flex;
  flex-direction: row;

  .disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .show {
    cursor: pointer;
  }
  .icon-button {
    width: 24px;
    height: 24px;
    position: relative;
    align-self: flex-end;
    margin-inline-start: 12px;
  }

  .process-track-btn {
    position: relative;
    height: 24px;
    width: 24px;
    margin-left: 12px;
    display: inherit;
    background: url(@/assets/img/flow/ic_process_track.png);

    &::after {
      position: absolute;
      content: '';
      cursor: pointer;
      width: inherit;
      height: inherit;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .previous-record-btn {
    position: relative;
    height: 24px;
    width: 24px;
    margin-left: 12px;
    display: inherit;

    &::after {
      position: absolute;
      content: '';
      background: url(@/assets/img/arrow/ic_arrow_left.png);
      cursor: pointer;
      width: inherit;
      height: inherit;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .next-record-btn {
    position: relative;
    height: 24px;
    width: 24px;
    margin-left: 12px;
    display: inherit;

    &::after {
      position: absolute;
      content: '';
      background: url(@/assets/img/arrow/ic_arrow_right.png);
      cursor: pointer;
      width: inherit;
      height: inherit;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .more-filled-content-btn {
    position: relative;
    height: 24px;
    width: 24px;
    margin-left: 12px;
    display: inherit;

    &::after {
      position: absolute;
      content: '';
      background: url(@/assets/img/arrow/ic_more.png);
      cursor: pointer;
      width: inherit;
      height: inherit;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.ccma-contactdetail-body-content {
  width: 100%;
  background: #FFFFFF;
  height: calc(100vh - 88px - 56px);
  font-size: var(--14px-to-rem);
}

.ccma-contactdetail-body-content .left-area {
  display: flex;
  flex-direction: column;
  width: 39.75%;
  height: inherit;
  justify-content: flex-start;
}


.ccma-contactdetail-body-content .left-area .left-area--media .video-plugin-area--single {
  height: 30.9%;
}

.ccma-contactdetail-body-content .left-area .left-area--media .video-plugin-area--mutil {
  height: 35.3%;
}

.ccma-contactdetail-body-content .left-area .left-area--media .audio-plugin-area--single {
  height: 17.6%;
}

.ccma-contactdetail-body-content .left-area .left-area--media .audio-plugin-area--mutil {
  height: 21.2%;
}

.ccma-contactdetail-body-content .left-area .left-area--detail {
  flex: 1;
  overflow-y: hidden;
  background-color: rgba(243, 243, 243, 1);
}

.ccma-contactdetail-body-content .left-area .left-area--detail > el-tabs__header {
  border-bottom: 1px;
}

.ccma-contactdetail-body-content .left-area .left-area--detail .contact-info {
  height: inherit;
}

.ccma-contactdetail-body-content .left-area .left-area--detail .contact-info .first-row-style {
  padding-top: 20px;
}

.ccma-contactdetail-body-content .left-area .left-area--detail .contact-info .row-style {
  padding-bottom: 22px;
}

.ccma-contactdetail-body-content .left-area .left-area--detail .contact-info .row-style .col-name {
  color: #707070;
  font-weight: 400;
}

.ccma-contactdetail-body-content .left-area .left-area--detail .contact-info .row-style .col-value {
  color: #1C1C1C;
  font-weight: 500;
  height: 14px
}

.ccma-contactdetail-body-content .left-area .left-area--detail .contact-info .row-style .col-value > .el-rate {
  height: 14px;
}


.ccma-contactdetail-body-content .right-area {
  width: 60.16%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  overflow-y: auto;
  justify-content: flex-end;
}

.ccma-contactdetail-body-content .right-area .tabs {
  line-height: 24px;
  font-weight: 400;
}

.ccma-contactdetail-body-content .right-area :deep(.el-tabs__header .el-tabs__item) {
  padding-left: 16px;
  padding-right: 16px;
  margin-left: unset;
  margin-right: unset;
  margin-inline-end: 4px;
}

 .ccma-contactdetail-body-content .right-area .right-area-tabs {
   padding: 21px 28px 30px 20px;
 }

 body.RTL .ccma-contactdetail-body-content .right-area .right-area-tabs {
   padding: 21px 20px 30px 28px;
 }

.ccma-contactdetail-body-content .right-area .right-area-tabs .more-tab .el-icon{
  vertical-align: middle;
}
.ccma-contactdetail-body-content .right-area .right-area-tabs .more-tab span{
  vertical-align: middle;
}

#aicc-ccma-contactdetail-tab {
  :deep(.el-tabs__item) {
    margin-left: unset;
    margin-right: unset;
    margin-inline-end: var(--20px-to-rem);
  }
}
</style>
