<template>
  <div class="org-tree-select">
    <sweet-select
        class="name-input"
        :placeholder="placeholder"
        v-model="selectedOrgs"
        @visible-change="onFocus"
        popper-class="not-visible"
        :clearable="clearable"
        readonly
        :disabled="disabled"
        @clear="onClear"
    >
    </sweet-select>
    <div class="filter-tree" ref="filterTreeRef" v-show="isTreeShow">
      <div class="mask" @click="onConfirm"></div>
      <div
          class="main"
          :style="'top:' + top + 'px;' + 'left:' + left + 'px;' + 'right:' + right + 'px;'"
      >
        <div class="search-input">
          <sweet-input
              prefix-icon="search"
              v-model="query"
              :placeholder="searchPlaceholder"
              @input="onQueryChanged"
              clearable
          />
        </div>

        <el-tree-v2
            v-if="data"
            ref="treeRef"
            :data="data"
            :props="props"
            :filter-method="filterMethod"
            :multiple="multiple"
            :show-checkbox="multiple"
            :highlight-current="!multiple"
            :height="treeHeight"
            :check-strictly="true"
            @check-change="setData"
            @check="setData"
            @current-change="setData"
        >
          <template #default="{ node, data }">
            <div class="tree-item tree-node-slot">
              <aicc-tooltip :open-delay="800" placement="top" overflowTargetSelector=".org-name">
                <template #content>
                  <div style="max-width: 500px">{{ data.orgName }}</div>
                </template>
                <div class="org-name" :class="{disabled: data.disabled}">{{ data.orgName }}</div>
              </aicc-tooltip>
            </div>
          </template>
        </el-tree-v2>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang'

const $t = i18n.global.t
export default {
  name: 'org-tree-select',
  props: {
    data: {
      type: Object
    },
    multiple: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: () => {
        return $t('SM.AUTH.TITLE.ORG_SELECTION')
      }
    },
    searchPlaceholder: {
      type: String,
      default: () => {
        return $t('SM.AUTH.TIPS.ENTER_ORGNAME')
      }
    },
    clearable: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    isChangeImmdiate: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isRequired: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },

  data() {
    return {
      props: {
        value: 'orgId',
        label: 'orgName',
        children: 'children',
        disabled: 'disabled'
      },
      query: '',
      isTreeShow: false,
      selectedOrgs: '',
      treeHeight: 0,
      left: 0,
      top: 0,
      right: 0,
      defaultValue: '',
      isDefaultSet: false,
      isValueDisabled: false
    }
  },
  watch: {
    data: {
      handler() {
        if (this.defaultValue) {
          this.processDefaultSet()
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    onQueryChanged(query) {
      this.$refs.treeRef.filter(query)
    },
    filterMethod(query, node) {
      return node.orgName?.includes(query)
    },
    onFocus() {
      this.isTreeShow = true
      this.$nextTick(() => {
        const element = this.$refs.filterTreeRef
        const rect = element.getBoundingClientRect()
        const top = rect.top
        const left = rect.left
        const right = rect.right
        const clientHeight = document.documentElement.clientHeight
        const clientWidth = document.documentElement.clientWidth
        this.treeHeight = clientHeight * 0.3
        if (clientHeight - top > this.treeHeight + 35) {
          // 下方高度足够，展示在下方
          this.top = top
        } else {
          // 展示在上方
          this.top = top - this.treeHeight - 105
        }
        this.left = left
        this.right = clientWidth - right
      })
    },
    setData(data) {
      if (data && data.disabled) {
        this.isValueDisabled = true
        return
      }
      this.isValueDisabled = false
      let keysSelected
      if (this.multiple) {
        keysSelected = this.$refs.treeRef?.getCheckedKeys()
        const names = this.$refs.treeRef?.getCheckedNodes()?.map((item) => item.orgName) || []
        const total = names.length
        if (names.length === 0) {
          this.selectedOrgs = `${names.join(';')}`
        } else {
          this.selectedOrgs = `+${total} | ${names.join(';')}`
        }
      } else {
        keysSelected = this.$refs.treeRef?.getCurrentKey()
        this.selectedOrgs = this.$refs.treeRef?.getCurrentNode()?.orgName
      }
      if (this.isChangeImmdiate) {
        this.$emit('onSelectChange', keysSelected)
      }
      if (!this.multiple && this.isChangeImmdiate) {
        this.isTreeShow = false
      }
      return keysSelected
    },
    onConfirm() {
      if(this.isRequired && !this.selectedOrgs) {
        return
      }
      this.isTreeShow = false
      if(this.isValueDisabled) {
        return
      }
      const keysSelected = this.setData()
      if (!this.isChangeImmdiate) {
        this.$emit('onSelectChange', keysSelected)
      }
    },
    onClear() {
      if (this.multiple) {
        this.$refs.treeRef.setCheckedKeys([])
        this.$refs.treeRef.setCurrentKey('')
      } else {
        this.$refs.treeRef.setCurrentKey('')
      }
      this.selectedOrgs = ''
      this.$emit('onClear')
    },
    // 设置默认值，父组件可通过ref进行调用 单选：'xxx', 多选：['xxx', 'xxx']
    setDefault(data) {
      this.defaultValue = data
      if (!this.isDefaultSet) {
        this.processDefaultSet()
      }
    },
    processDefaultSet() {
      setTimeout(() => {
        if (this.defaultValue) {
          if (this.multiple) {
            this.$refs.treeRef.setCheckedKeys(this.defaultValue)
          } else {
            this.$refs.treeRef.setCurrentKey(this.defaultValue)
          }
        }
        this.setData()
        this.isDefaultSet = true
      }, 200)
    }
  }
}
</script>

<style lang="less" scoped>
.org-tree-select {
  background-color: #fff;
  width: 100%;

  :deep(.el-virtual-scrollbar) {
    display: none !important;
  }
}

.name-input {
  margin-bottom: 2px;
}

.filter-tree {
  position: relative;

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1400;
    background-color: transparent;
  }

  .main {
    padding-top: 6px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1401;
    background-color: #fff;
    box-shadow: var(--swt-shadow2);
    border: var(--swt-border-width-independent) solid var(--el-border-color-lighter) !important;

    .search-input {
      margin: 8px 12px 0;
    }

    :deep(
        div.el-input.el-input--prefix.el-input--suffix
          div.el-input__wrapper:has(input.el-input__inner)
      ) {
      box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset !important;
    }

    :deep(
        div.el-input.el-input--prefix.el-input--suffix
          div.el-input__wrapper:has(input.el-input__inner):hover
      ) {
      box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset !important;
    }

    :deep(
        div.el-input.el-input--prefix.el-input--suffix
          div.el-input__wrapper:has(input.el-input__inner):focus
      ) {
      box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset !important;
    }
  }

  .tree-item {
    flex: 1;
    overflow: hidden;

    .org-name {
      overflow: hidden;
      text-overflow: ellipsis;
       &.disabled {
         color: var(--swt-color-gray5);
         cursor: not-allowed;
       }
    }
  }
}
</style>
<style lang="less">
.not-visible {
  display: none;
}
</style>