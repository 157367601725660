import request from "@/utils/request";

/**
 * 查询营销任务列表
 * @param data
 * @returns {*}
 */
export const queryPromotionList = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/queryPromotion',
    method: 'post',
    data
  })
}

export const queryTobeSendCount = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/queryTobeSentCountById',
    method: 'post',
    data
  })
}

export const queryPublicVariableAndTemplateVariable = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/queryPublicVariableAndTemplateVariable',
    method: 'post',
    data
  })
}

export const queryWhatsAppTemplateById = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/queryWhatsAppTemplateById',
    method: 'post',
    data
  })
}

export const batchDeleteReceiverInfo = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/batchDeleteReceiverInfo',
    method: 'post',
    data
  })
}

export const editReceiverInfo = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/editReceiverInfo',
    method: 'post',
    data
  })
}

export const queryReceiverInfoList = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/queryReceiverInfoList',
    method: 'post',
    data
  })
}

export const queryPromotionById = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/queryPromotionById',
    method: 'post',
    data
  })
}

export const queryReceiverInfoById = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/queryReceiverInfoById',
    method: 'post',
    data
  })
}

export const queryDataPropListByIds = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/dataProp/queryDataPropListByIds',
    method: 'post',
    data
  })
}

export const queryTobeSentCountById = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/queryTobeSentCountById',
    method: 'post',
    data
  })
}

export const startPromotion = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/startPromotion',
    method: 'post',
    data
  })
}

export const pausePromotion = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/pausePromotion',
    method: 'post',
    data
  })
}

export const queryPromotion = data => {
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/queryPromotion',
    method: 'post',
    data
  })
}

export const deletePromotion =data =>{
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/deletePromotion',
    method: 'post',
    data
  })
}

export const queryTenantFeatures =data =>{
  return request({
    url: '/service-cloud/rest/isales/v1/task/queryTenantFeatures',
    method: 'post',
    data
  })
}

export const querySysMessageTemplate =data =>{
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/querySysMessageTemplate',
    method: 'post',
    data
  })
}

export const queryDataDictionary =data =>{
  return request({
    url: '/service-cloud/u-route/dictionary/queryDataDictionary',
    method: 'get',
    data
  })
}

export const queryPropList =data =>{
  return request({
    url: '/service-cloud/rest/isales/v1/dataProp/queryDataPropList',
    method: 'post',
    data
  })
}

export const createPromotion =data =>{
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/createPromotion',
    method: 'post',
    data
  })
}

export const updatePromotion =data =>{
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/updatePromotion',
    method: 'post',
    data
  })
}

export const querySendGateway =data =>{
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/querySendGateway',
    method: 'post',
    data
  })
}

export const queryWhatsAppChannelList =data =>{
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/CardInfo/queryWhatsAppChannelList',
    method: 'get',
    data
  })
}

export const getWhatsAppTemplateMsgByChannelId =data =>{
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/whatsapptemplatemsg/getWhatsAppTemplateMsgByChannelId',
    method: 'post',
    data
  })
}

export const viewPromotionResult =data =>{
  return request({
    url: '/service-cloud/rest/isales/v1/promotion/viewPromotionResult',
    method: 'post',
    data
  })
}

export const downloadDataImportFileURL = '/service-cloud/u-route/isales/data/downloadDataImportFile';

export const downloadFailFileURL = '/service-cloud/u-route/isales/data/downloadFailDataImportFile';