export default {
  "ccmessaging.chat.chat.closeDialog": "¿Está seguro de que desea cerrar?",
  "ccmessaging.chat.silentagent.config.add.error": "Error al crear la configuración del agente silencioso de voz",
  "ccmessaging.chat.silentagent.config.add.success": "La configuración del agente silencioso de voz se ha creado correctamente",
  "ccmessaging.chat.fiveGCard.add": "Adición de una tarjeta 5G",
  "ccmessaging.chat.fiveGCard.edit": "Edición de una tarjeta 5G",
  "ccmessaging.chat.fiveGCarouselCard.add": "Adición de una tarjeta de carrusel 5G",
  "ccmessaging.chat.fiveGCarouselCard.edit": "Edición de una tarjeta de carrusel 5G",
  "ccmessaging.chat.webCard.add": "Agregar una tarjeta web",
  "ccmessaging.chat.webCard.edit": "Editar tarjeta web",
  "ccmessaging.chat.coBrowseSite.add": "Adición de una dirección de colaboración de página web",
  "ccmessaging.chat.coBrowseSite.edit": "Editar dirección de colaboración de página web",
  "ccmessaging.chat.richTextPage.add": "Agregar texto enriquecido",
  "ccmessaging.chat.richTextPage.edit": "Editar texto enriquecido",
  "ccmessaging.chat.chatPhrase.add": "Añadir frase común",
  "ccmessaging.chat.chatPhrase.edit": "Editar frases comunes",
  "ccmessaging.chat.audioLibrary.add": "Añadir voz",
  "ccmessaging.chat.audioLibrary.edit": "Editar voz",
  "ccmessaging.chat.videoLibrary.add": "Agregar un vídeo",
  "ccmessaging.chat.videoLibrary.edit": "Editar vídeo",
  "ccmessaging.chat.imgLibrary.add": "Agregar una imagen",
  "ccmessaging.chat.imgLibrary.edit": "Editar imagen",
  "ccmessaging.chat.emoLibrary.add": "Añadir emoticonos personalizados",
  "ccmessaging.chat.emoLibrary.edit": "Editar emoticonos personalizados",
  "ccmessaging.chat.docLibrary.add": "Agregar un documento",
  "ccmessaging.chat.docLibrary.edit": "Editar documento",
  "ccmessaging.chat.locate.add": "Añadir dirección",
  "ccmessaging.chat.locate.edit": "Editar dirección",
  "ccmesaging.chat.agentconsole.commom.label": "Etiquetas",
  "ccmesaging.chat.agentconsole.label.callReason": "Motivo de la llamada",
  "ccmesaging.chat.agentconsole.label.commonreply": "Respuestas comunes",
  "ccmesaging.chat.agentconsole.label.expression": "Emoticones",
  "ccmesaging.chat.agentconsole.label.fontstyle": "Estilo de fuente",
  "ccmesaging.chat.agentconsole.label.historicalcontact": "Contacto Histórico",
  "ccmesaging.chat.agentconsole.label.incallmessage": "Mensaje de llamada entrante",
  "ccmesaging.chat.agentconsole.label.Realtimetransfer": "Traducción en tiempo real",
  "ccmesaging.chat.agentconsole.label.robotChat": "Diálogo con Chatbot",
  "ccmesaging.chat.agentconsole.label.screenshot": "Capturas de pantalla",
  "ccmesaging.chat.agentconsole.label.seeMore": "Ver más",
  "ccmesaging.chat.agentconsole.label.service": "Servicio",
  "ccmesaging.chat.agentconsole.label.uploadattachment": "Cargar archivo adjunto",
  "ccmesaging.chat.agentconsole.label.uploadimage": "Subir imagen",
  "ccmesaging.chat.agentconsole.title.release": "Lanzamiento",
  "ccmesaging.chat.cobrowse.accept": "Acepto",
  "ccmesaging.chat.cobrowse.agentMax": "El agente está colaborando actualmente en la página web, intente nuevamente",
  "ccmesaging.chat.cobrowse.isAcceptWeb": "¿Estás seguro de que quieres aceptar la invitación de co-navegación?",
  "ccmesaging.chat.cobrowse.isAcceptWebMax": "Se ha superado el número máximo de conexiones de Co-navegación permitidas por el tenant actual.",
  "ccmesaging.chat.cobrowse.noWebSiteAuth": "El agente no tiene el permiso de Co-browsing.",
  "ccmesaging.chat.cobrowse.refused": "Rechazar",
  "ccmesaging.chat.cobrowse.title": "Invitación de Co-navegación",
  "ccmesaging.chat.cobrowse.webSite": "URL de navegación conjunta:",
  "ccmesaging.chat.cobrowse.webSiteButton": "Navegación conjunta",
  "ccmesaging.chat.cobrowse.webSitePlaceholder": "Ingrese la URL de uso compartido.",
  "ccmessaging.address.protocol.check": "El protocolo de red utilizado por la dirección no es seguro. El uso de este protocolo puede conllevar riesgos de seguridad.",
  "ccmessaging.channelconfig.algorithm": "Interconexión con Algoritmos de Cifrado",
  "ccmessaging.channelconfig.email.freeTimeText": "Respuesta automática durante las horas no laborables",
  "ccmessaging.channelconfig.email.freeTimeText.tips": "Responder a los correos electrónicos recibidos de los clientes durante las horas no laborables después de que se active la función",
  "ccmessaging.channelconfig.email.reply.each.eamil": "Respuesta automática a cada mensaje",
  "ccmessaging.channelconfig.email.selectAutoReply": "Plantilla de correo electrónico",
  "ccmessaging.channelconfig.email.selectFreeTimeText": "Plantilla de correo electrónico",
  "ccmessaging.channelconfig.email.worktime.autoReply": "Respuesta automática durante el tiempo de trabajo",
  "ccmessaging.channelconfig.email.worktime.autoReply.tips": "Responder a los correos electrónicos recibidos de los clientes durante las horas de trabajo después de la apertura",
  "ccmessaging.channelconfig.sms.autoReply": "Respuesta automática",
  "ccmessaging.channelconfig.sms.autoReply.tips": "Se envía una respuesta automática después de que el agente se haya conectado correctamente.",
  "ccmessaging.channelconfig.sms.freeTimeText": "Respuesta del Día No Laboral",
  "ccmessaging.channelconfig.sms.selectAutoReply": "Plantilla de respuesta automática de SMS",
  "ccmessaging.channelconfig.sms.selectFreeTimeText": "Plantilla de respuesta SMS para el día laboral",
  "ccmessaging.char.agentstatus.after.callends": "La prioridad de la configuraci\u00F3n del estado del agente entra en vigor despu\u00E9s de que finaliza la llamada es la siguiente: nivel de organizaci\u00F3n > nivel de inquilino. Si este par\u00E1metro se deja en blanco, la configuraci\u00F3n no tiene efecto y se utiliza la configuraci\u00F3n de nivel de inquilino.",
  "ccmessaging.char.call.Idle.state": "Estado inactivo",
  "ccmessaging.char.call.work.state": "estado de trabajo",
  "ccmessaging.char.channelconfig.duplicateCodeName": "El nombre del canal ya existe.",
  "ccmessaging.chat.agent.organizational.structure": "Organización",
  "ccmessaging.chat.agent.refreshFail": "No actualice la página repetidamente",
  "ccmessaging.chat.agent.transferSessionFail": "Error al transferir la sesión",
  "ccmessaging.chat.agentconsole.accesschannel": "Canal de acceso",
  "ccmessaging.chat.agentconsole.agentStatus.error": "No se puede iniciar una llamada porque el agente no está en línea o hablando.",
  "ccmessaging.chat.agentconsole.allResults": "Todos los resultados",
  "ccmessaging.chat.agentconsole.asyncTimeBeyond": "Error en el envío porque se ha superado el intervalo de respuesta no permitido.",
  "ccmessaging.chat.agentconsole.beyondLimitNum": "El número de mensajes enviados excede el límite superior.",
  "ccmessaging.chat.agentconsole.browserecord": "Historial de navegación",
  "ccmessaging.chat.agentconsole.chat.close": "La sesión actual terminó",
  "ccmessaging.chat.agentconsole.chat.userId": "Identificación de usuario",
  "ccmessaging.chat.agentconsole.chat.userSettings": "Configuración de usuario",
  "ccmessaging.chat.agentconsole.chatPhrase": "Frases comunes",
  "ccmessaging.chat.agentconsole.cobrowse": "Navegación conjunta",
  "ccmessaging.chat.agentconsole.contract.pdfUploadMaxSizeTip": "El tamaño del contrato no puede exceder {n}M.",
  "ccmessaging.chat.agentconsole.contract.statisticSigns": "Estadísticas de firmas",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns": "Estadísticas",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.failTip": "Error al recopilar estadísticas sobre los elementos de firma del contrato.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noSigns.failTip": "No se ha encontrado el icono de ubicación de la firma.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noStaticSigns.failTip": "Por favor, cuente los contratos con firmas primero.",
  "ccmessaging.chat.agentconsole.contract.upload.failTip": "Error al cargar el contrato.",
  "ccmessaging.chat.agentconsole.contract.upload.noSelectSigns.failTip": "El elemento de firma no está seleccionado para el contrato.",
  "ccmessaging.chat.agentconsole.contract.upload.sameContract.failTip": "El suscriptor tiene un contrato no vencido.",
  "ccmessaging.chat.agentconsole.current": "Actual",
  "ccmessaging.chat.agentconsole.customerinformation": "Información del cliente",
  "ccmessaging.chat.agentconsole.defaultOrgName": "Sin afiliación",
  "ccmessaging.chat.agentconsole.document": "Documentos",
  "ccmessaging.chat.agentconsole.emailNotSupport": "El canal de correo electrónico no admite mensajes multimedia.",
  "ccmessaging.chat.agentconsole.enter.total.number": "Introduzca el número total de firmas",
  "ccmessaging.chat.agentconsole.filterByCondition": "Criterios de filtro",
  "ccmessaging.chat.agentconsole.fivegNotSupport": "Los canales 5G no admiten la mensajería multimedia",
  "ccmessaging.chat.agentconsole.hisconversation": "Conversación histórica",
  "ccmessaging.chat.agentconsole.history": "Historia",
  "ccmessaging.chat.agentconsole.image": "Imágenes",
  "ccmessaging.chat.agentconsole.information": "Información de contacto",
  "ccmessaging.chat.agentconsole.intention": "intención",
  "ccmessaging.chat.agentconsole.IpAddress": "Dirección IP",
  "ccmessaging.chat.agentconsole.label.defaultwelcome": "¡Bienvenido al sistema de atención al cliente en línea!",
  "ccmessaging.chat.agentconsole.label.endsession": "¿Ha de finalizar la sesión?",
  "ccmessaging.chat.agentconsole.label.filesFacebookNotsupport": "Facebook Messenger actualmente no admite recibir información de ubicación",
  "ccmessaging.chat.agentconsole.label.filesTwitterNotsupport": "Twitter no es compatible con audio, localizar, o video más de 30 segundos!",
  "ccmessaging.chat.agentconsole.label.filesWechatNotsupport": "WeChat no admite audio que dura más de 60 segundos.",
  "ccmessaging.chat.agentconsole.label.messageRejected": "El mensaje fue rechazado por Twitter porque se ve como spam, por favor use sinónimo o inténtelo de nuevo más tarde:",
  "ccmessaging.chat.agentconsole.label.NoAudioDuration": "Error al obtener la duración del audio. Por favor, súbelo de nuevo.",
  "ccmessaging.chat.agentconsole.label.NoVideoDuration": "Error al obtener la duración del video, ¡Por favor, cargue de nuevo!",
  "ccmessaging.chat.agentconsole.label.ongoingsession": "¡Actualmente no hay sesión en curso!",
  "ccmessaging.chat.agentconsole.label.ongoingwx": "¡El canal actual no puede enviar información de ubicación!",
  "ccmessaging.chat.agentconsole.label.skillNoAgent": "El agente en la cola de habilidades est�� sin conexi��n.",
  "ccmessaging.chat.agentconsole.loading": "Cargar más",
  "ccmessaging.chat.agentconsole.locate": "Localización",
  "ccmessaging.chat.agentconsole.mailDraft": "Redactar correo electrónico",
  "ccmessaging.chat.agentconsole.message.notworktime": " El agente no está en tiempo de trabajo.",
  "ccmessaging.chat.agentconsole.messageloading": "carga",
  "ccmessaging.chat.agentconsole.multimedia": "Multimedia",
  "ccmessaging.chat.agentconsole.multimediaid": "ID multimedia",
  "ccmessaging.chat.agentconsole.multimedianame": "Nombre multimedia",
  "ccmessaging.chat.agentconsole.myconversation": "Mi conversación",
  "ccmessaging.chat.agentconsole.nocontact": "No hay contactos en este momento",
  "ccmessaging.chat.agentconsole.noHiscontact": "Sin sesión histórica",
  "ccmessaging.chat.agentconsole.notSupport": "El canal no admite este tipo de mensaje multimedia.",
  "ccmessaging.chat.agentconsole.noUserArrive": "¡Ningún usuario está disponible ahora!",
  "ccmessaging.chat.agentconsole.novoEmail": "Nuevo correo electrónico",
  "ccmessaging.chat.agentconsole.nowconversation": "Conversación actual",
  "ccmessaging.chat.agentconsole.only.pdf.supported": "Solo se admiten archivos PDF",
  "ccmessaging.chat.agentconsole.photo": "Fotos",
  "ccmessaging.chat.agentconsole.promptConetnt.cannot.rollback": "El mensaje no se puede retirar",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentLengthExceeds": "¡El contenido de texto más largo que el límite no está permitido!",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentNull": "¡EmptyContent no está permitido!",
  "ccmessaging.chat.agentconsole.promptConetnt.delectMessageRecord": "Ha eliminado un registro",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyFiveG": "Los mensajes de la tarjeta 5G solo admiten el canal 5G!",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyWebCard": "Los mensajes de la tarjeta web solo admiten el canal web!",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFail": "Error al realizar operaciones en mensajes que no se envían correctamente",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFailWithChannel": "Solo los mensajes web pueden ser operados",
  "ccmessaging.chat.agentconsole.promptConetnt.operateRollBackFail": "Los mensajes enviados durante más de dos minutos no se pueden retirar",
  "ccmessaging.chat.agentconsole.promptConetnt.rollBackMessage": "Has retirado un mensaje",
  "ccmessaging.chat.agentconsole.promptConetnt.userAlreadyInSession": "Error al enviar porque el suscriptor está en una llamada.",
  "ccmessaging.chat.agentconsole.promptConetnt.userBusy": "Espere hasta que el agente esté inactivo",
  "ccmessaging.chat.agentconsole.promptConetnt.userInfoNull": "UserInfomation obtenido no es correcto, la sesión se desconectará!",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftFailed": "Error al enviar el correo electrónico porque el suscriptor está en una llamada. El correo electrónico no se guarda como borrador.",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftSuccess": "Error al enviar el correo electrónico porque el suscriptor está en una llamada. El correo electrónico se guarda como borrador.",
  "ccmessaging.chat.agentconsole.promptConetnt.userNoArrive": "¡No hay ningún usuario conectado, o ningún usuario ha sido seleccionado!",
  "ccmessaging.chat.agentconsole.promptConetnt.userOffline": "El usuario actual está fuera de línea!",
  "ccmessaging.chat.agentconsole.queuing": "Cola",
  "ccmessaging.chat.agentconsole.quoteReply": "Citar esta respuesta",
  "ccmessaging.chat.agentconsole.recommendedCaseType": "Categoría de orden de trabajo recomendada",
  "ccmessaging.chat.agentconsole.replyEmail": "Correo electrónico de respuesta",
  "ccmessaging.chat.agentconsole.robotAssistant": "Asistente de robots",
  "ccmessaging.chat.agentconsole.select.contract.uploaded": "Seleccione el documento a cargar",
  "ccmessaging.chat.agentconsole.select.mandatory.signature": "Seleccione una firma obligatoria",
  "ccmessaging.chat.agentconsole.selectCaseType": "Selección de una categoría de orden de trabajo",
  "ccmessaging.chat.agentconsole.send.contract": "Enviar contrato",
  "ccmessaging.chat.agentconsole.send.repeat": "¡No haga clic repetidamente!",
  "ccmessaging.chat.agentconsole.sendEmail": "Enviar correo electrónico",
  "ccmessaging.chat.agentconsole.sendLocalFile": "Enviar archivos locales",
  "ccmessaging.chat.agentconsole.sendmessage": "Enviar mensaje",
  "ccmessaging.chat.agentconsole.setHandledNumber": "Establecer número manejado",
  "ccmessaging.chat.agentconsole.signature.variable": "Firma{n}",
  "ccmessaging.chat.agentconsole.signInCallNum": "Cantidad de llamadas después de iniciar sesión:",
  "ccmessaging.chat.agentconsole.smsTemplate": "Plantilla de SMS",
  "ccmessaging.chat.agentconsole.software": "Entorno de software",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.not.finished": "No todos tus errores tipográficos han sido corregidos. ¿Terminar el cheque?",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.cancel.confirmation": "El contenido corregido se descartará. ¿Confirma que desea cancelar la verificación?",
  "ccmessaging.chat.agentconsole.spellcheck.correctly.writing": "Correctamente Escrito",
  "ccmessaging.chat.agentconsole.spellcheck.failed": "Error en la comprobación ortográfica.",
  "ccmessaging.chat.agentconsole.spellcheck.language": "Idioma",
  "ccmessaging.chat.agentconsole.spellcheck.next": "Siguiente",
  "ccmessaging.chat.agentconsole.spellcheck.previous": "Anterior",
  "ccmessaging.chat.agentconsole.spellcheck.replace": "Reemplazar",
  "ccmessaging.chat.agentconsole.spellcheck.replaceall": "Reemplazar todo",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed": "La longitud del texto que se va a comprobar supera el límite de 1024 caracteres",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed.front": "La longitud del texto que se va a comprobar supera el límite de 1024 caracteres",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.context": "No hay texto en el cuadro de texto. No es necesario revisar la ortografía.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.mistake": "Sin errores de ortografía",
  "ccmessaging.chat.agentconsole.spellcheck.title": "Prueba de hechizos",
  "ccmessaging.chat.agentconsole.spellcheck.wrongly.writing": "Escrito incorrectamente",
  "ccmessaging.chat.agentconsole.talkavg": "Duración promedio de llamada:",
  "ccmessaging.chat.agentconsole.talkNum": "Cuenta de número de lllamadas (hoy):",
  "ccmessaging.chat.agentconsole.thumbnail": "Imágenes",
  "ccmessaging.chat.agentconsole.total.number.signatures": "Número total de firmas",
  "ccmessaging.chat.agentconsole.upload": "Carga de archivos",
  "ccmessaging.chat.agentconsole.uploadfail": "Error al cargar el archivo",
  "ccmessaging.chat.agentconsole.userInputStatus": "Previsualice el estado de entrada del usuario.",
  "ccmessaging.chat.agentconsole.video": "Vídeos",
  "ccmessaging.chat.agentconsole.voice": "Voz",
  "ccmessaging.chat.agentconsole.waittime": "Tiempo de espera",
  "ccmessaging.chat.async.asyncMsgNum": "Número de mensajes sin conexión",
  "ccmessaging.chat.async.asyncMsgTrending": "Tendencia de mensajes sin conexión",
  "ccmessaging.chat.async.asyncUserNum": "Número de usuarios sin conexión",
  "ccmessaging.chat.async.asyncUserTrending": "Tendencia de usuario sin conexión",
  "ccmessaging.chat.async.channelSelect": "Filtrado de canales",
  "ccmessaging.chat.async.requestError": "Error en la solicitud.",
  "ccmessaging.chat.async.statistics": "Supervisión de mensajes sin conexión",
  "ccmessaging.chat.async.statistics.asynChannelTotal": "Número total de canales fuera de línea",
  "ccmessaging.chat.async.statistics.asynMsgTotal": "Número total de mensajes sin conexión",
  "ccmessaging.chat.async.statistics.asynUserTotal": "Número total de usuarios sin conexión",
  "ccmessaging.chat.async.statistics.updateTime": "Tiempo de actualización de datos",
  "ccmessaging.chat.call.end.state": "Estado de finalización de llamada",
  "ccmessaging.chat.card": "Tarjeta de crédito",
  "ccmessaging.chat.edit.card": "Editar Tarjeta de crédito",
  "ccmessaging.chat.multimedia.selectTemplate": "Elegir Plantilla de",
  "ccmessaging.chat.card.template": "Plantilla de WhatsApp",
  "ccmessaging.chat.channelconfig.accessToken": "Clave de Token",
  "ccmessaging.chat.channelconfig.accessTokenSecret": "Acceso a la clave del Token",
  "ccmessaging.chat.channelconfig.accountKeyRepeat": "La accountKey se repite en el canal de whatsApp",
  "ccmessaging.chat.channelconfig.add": "Nuevo",
  "ccmessaging.chat.channelconfig.addressValidate": "El valor debe tener el formato sip:número{'@'}botplatform.rcs.chinamobile.com y debe contener menos de 100 caracteres.",
  "ccmessaging.chat.channelconfig.afterhalf": ", se puede hacer clic para eliminar el navegador para interceptar)",
  "ccmessaging.chat.channelconfig.agentTimeout": "Personalizar el intervalo de tiempo de espera para no responder al agente y solicitar la reasignación del agente",
  "ccmessaging.chat.channelconfig.agentTimeout.emailTimeOutTips": "Personalice el intervalo de tiempo de espera para que no haya respuesta de agente",
  "ccmessaging.chat.channelconfig.agentWorkTime": "Tiempo de trabajo del agente",
  "ccmessaging.chat.channelconfig.alphavalidateFailed": "Existen caracteres no válidos. El valor de entrada sólo puede contener letras, dígitos y guiones bajos (_). El primer carácter debe ser una letra o un guion bajo",
  "ccmessaging.chat.channelconfig.apifabric.ak": "Clave APP",
  "ccmessaging.chat.channelconfig.apifabric.sk": "Secreto APP",
  "ccmessaging.chat.channelconfig.apiKey": "Clave API",
  "ccmessaging.chat.channelconfig.apiKeySecret": "Confidencialidad de claves API",
  "ccmessaging.chat.channelconfig.appId": "App Id",
  "ccmessaging.chat.channelconfig.appSecret": "Clave App",
  "ccmessaging.chat.channelconfig.assertionSigningKey": "Afirmar la clave de firma",
  "ccmessaging.chat.channelconfig.assistantAccessCode": "Código de acceso de asistente",
  "ccmessaging.chat.channelconfig.assistantSet": "Configuración del Asistente de Robot",
  "ccmessaging.chat.channelconfig.asyncFlag": "Mensajes sin conexión",
  "ccmessaging.chat.channelconfig.asyncTip": "Si esta función está deshabilitada, se perderán los mensajes sin conexión existentes.",
  "ccmessaging.chat.channelconfig.asyncTime": "Límite de tiempo de enrutamiento de mensajes sin conexión",
  "ccmessaging.chat.channelconfig.auth.duplicateAuth": "La cuenta oficial ha sido autorizada y no es necesario volver a configurarla.",
  "ccmessaging.chat.channelconfig.auth.relation.timeout": "La autorización puede haber caducado. Escanee el código QR WeChat para la reautorización.",
  "ccmessaging.chat.channelconfig.beforhalf": `Puede hacer clic en el botón "Pruébalo" para ver el efecto de integración. (Si la URL es correcta y aparece un indicador de interceptación en la esquina superior derecha del navegador`,
  "ccmessaging.chat.channelconfig.bsp": "Proveedor de soluciones empresariales",
  "ccmessaging.chat.channelconfig.cert.empty": "El certificado no puede estar vacío cuando la dirección push utiliza el protocolo HTTPS.",
  "ccmessaging.chat.channelconfig.Certification": "Método de autenticação",
  "ccmessaging.chat.channelconfig.changeLogo": "Cambiar avatar",
  "ccmessaging.chat.channelconfig.channelID": "ID del canal",
  "ccmessaging.chat.channelconfig.channelSecret": "Clave del canal",
  "ccmessaging.chat.channelconfig.chatbotAddress": "Dirección del robot de chat",
  "ccmessaging.chat.channelconfig.checkAgentTimeOutInterval": "El intervalo de tiempo de espera para ninguna respuesta del agente no puede ser mayor que el intervalo de solicitud para ninguna respuesta.",
  "ccmessaging.chat.channelconfig.checkEncodingAESKey": "La longitud de la llave es 43 y solo puede contener A-Z, a-z, 0-9",
  "ccmessaging.chat.channelconfig.checkmessage": `No puede contener los siguientes caracteres especiales <>'"/\\`,
  "ccmessaging.chat.channelconfig.checkNickName": `No puede contener los siguientes caracteres especiales +~!{'@'}#$%&*()/=+{'{'}{'}'};:'\\",<.>?`,
  "ccmessaging.chat.channelconfig.checkName": "El nombre no puede ser el sistema de palabras, etc",
  "ccmessaging.chat.channelconfig.checkNumber": "El valor no es válido.",
  "ccmessaging.chat.channelconfig.checkSocialChatNum": "No puede contener decimales",
  "ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl": "¡La URL no es válida!",
  "ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum": "Número de móvil no válido",
  "ccmessaging.chat.channelconfig.clicktocall.empty": "¡La cola de habilidades o el código de acceso IVR no pueden estar vacíos!",
  "ccmessaging.chat.channelconfig.clickToCallSkillList": "Haga clic para llamar a la cola de habilidades",
  "ccmessaging.chat.channelconfig.close": "Cerrar",
  "ccmessaging.chat.channelconfig.codeName": "Código de acceso al canal",
  "ccmessaging.chat.channelconfig.defaultReply": "Respuesta predeterminada",
  "ccmessaging.chat.channelconfig.delete": "Borrar",
  "ccmessaging.chat.channelconfig.delete.exist.asyncMsg": "Error al eliminar el canal [{n}]. Se recomienda asignar mensajes sin conexión y volver a intentarlo",
  "ccmessaging.chat.channelconfig.delete.exist.asyncUser": "Error al eliminar el canal [{n}]. Se recomienda asignar el canal a un suscriptor sin conexión y volver a intentarlo",
  "ccmessaging.chat.channelconfig.delete.exist.session": "Error al eliminar el canal [{n}]. Se recomienda volver a intentarlo después de que finalice la sesión",
  "ccmessaging.chat.channelconfig.edit": "Operación",
  "ccmessaging.chat.channelconfig.email.bccLimit": "Máx de destinatarios en copia oculta",
  "ccmessaging.chat.channelconfig.email.ccLimit": "Máx de destinatarios en copia",
  "ccmessaging.chat.channelconfig.email.number": "Configuración de cantidad de dirección de correo electrónico",
  "ccmessaging.chat.channelconfig.email.personal": "Responder con correo electrónico personal",
  "ccmessaging.chat.channelconfig.email.placeholder": "1 - 100, opcional",
  "ccmessaging.chat.channelconfig.email.range": "Introducir un entero positivo comprendido entre 1 y 100",
  "ccmessaging.chat.channelconfig.email.address.limit": "un",
  "ccmessaging.chat.channelconfig.email.receiverLimit": "Máx de destinatarios",
  "ccmessaging.chat.channelconfig.email.selectSignature": "Firma de correo electrónico",
  "ccmessaging.chat.channelconfig.email.signature": "Firma de correo electrónico",
  "ccmessaging.chat.channelconfig.email.signature.desc": "Descripcion",
  "ccmessaging.chat.channelconfig.email.signature.name": "Nombre de la firma de correo electrónico",
  "ccmessaging.chat.channelconfig.email.totalEmailNumber": "El número total de direcciones de correo electrónico del destinatario, del destinatario de la copia de carbono y del destinatario secreto no puede exceder de 100",
  "ccmessaging.chat.channelconfig.emailRepeate": "Se ha utilizado la puerta de enlace de recepción de correo electrónico. Vaya a la página de configuración de enrutamiento de mensajes para ver la puerta de enlace de recepción de correo electrónico.",
  "ccmessaging.chat.channelconfig.endchat": "¿Ha de finalizar la sesión?",
  "ccmessaging.chat.channelconfig.endOfSession": "Término da sessão devido à falta de resposta do cliente",
  "ccmessaging.chat.channelconfig.envName": "Etiqueta de entorno de desarrollo",
  "ccmessaging.chat.channelconfig.envNameValidate": "El valor debe ser una cadena de menos o igual a 24 caracteres y solo puede contener letras y dígitos.",
  "ccmessaging.chat.channelconfig.facebookBackground": "Facebook",
  "ccmessaging.chat.channelconfig.fiveGBackground": "Mensaje 5G CSP",
  "ccmessaging.chat.channelconfig.gender": "Sexo",
  "ccmessaging.chat.channelconfig.guestTimeout": "Período de tiempo de espera personalizado para mensaje de fin de sesión y falta de respuesta del cliente. Si esta función está deshabilitada, el período de tiempo de espera predeterminado es de 20 minutos.",
  "ccmessaging.chat.channelconfig.guestTimeout.timeOutTips": "Personalizar el intervalo de tiempo de espera para ninguna respuesta del cliente (Si este interruptor está deshabilitado, el intervalo de tiempo de espera predeterminado es de {n} minutos.)",
  "ccmessaging.chat.channelconfig.hostingMethod": "Método de alojamiento",
  "ccmessaging.chat.channelconfig.hour": "Horario",
  "ccmessaging.chat.channelconfig.identityCertificate": "Certificado de identidad",
  "ccmessaging.chat.channelconfig.importAvatarSize": "El tamaño de la imagen avatar supera los 6 MB.",
  "ccmessaging.chat.channelconfig.importAvatartype": "Tipo de imagen de avatar incorrecto.",
  "ccmessaging.chat.channelconfig.infobipPassword": "Contraseña",
  "ccmessaging.chat.channelconfig.infobipUserName": "Nombre de usuario",
  "ccmessaging.chat.channelconfig.infoConfiguration": "Configuración de información",
  "ccmessaging.chat.channelconfig.keyEscrow": "Llave",
  "ccmessaging.chat.channelconfig.KeyValidate": "Solo se permiten letras, dígitos y 11 caracteres especiales _~!{'@'}#$%^&*- cualquier composición",
  "ccmessaging.chat.channelconfig.whatsappNameValidate": "Solo se permiten letras, dígitos y 12 caracteres especiales _~!{'@'}#$%^&*-. cualquier composición",
  "ccmessaging.chat.channelconfig.lastagent.time": "Rango de tiempo",
  "ccmessaging.chat.channelconfig.lastModifyTime": "Última hora de modificación",
  "ccmessaging.chat.channelconfig.logo": "Avatar",
  "ccmessaging.chat.channelconfig.messagePushUrl.not.configure.trustList": "La dirección no se encuentra en la lista de confianza. Póngase en contacto con el administrador del sistema.",
  "ccmessaging.chat.channelconfig.hrefUrl.not.configure.trustList": "La dirección del hipervínculo no está en la lista de confianza. Póngase en contacto con el administrador del sistema.",
  "ccmessaging.chat.channelconfig.minute": "Minutos",
  "ccmessaging.chat.channelconfig.name": "Nombre",
  "ccmessaging.chat.channelconfig.nochatonline": "¡Actualmente no hay sesión en curso!",
  "ccmessaging.chat.channelconfig.noLimit": "No especificado",
  "ccmessaging.chat.channelconfig.NonworkingTimeReminder": "Notificación de tiempo no laboral",
  "ccmessaging.chat.channelconfig.nonWorkTimeDay": "Día no laboral",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime": "Período de tiempo de espera de respuesta del cliente llamado",
  "ccmessaging.chat.channelconfig.noReplyIntervalTimeValidate": "El Intervalo para cliente sin respuesta no puede ser mayor que el Intervalo para cliente sin notificación de respuesta.",
  "ccmessaging.chat.channelconfig.noReplyTime": "Límite de tiempo de llamadas salientes del agente",
  "ccmessaging.chat.channelconfig.offBeginTime": "Desde la hora de inicio hasta la hora actual",
  "ccmessaging.chat.channelconfig.offLastAgentType": "Último tipo de agente",
  "ccmessaging.chat.channelconfig.offTimeRange": "Dentro del rango de tiempo",
  "ccmessaging.chat.channelconfig.offToday": "En el día actual",
  "ccmessaging.chat.channelconfig.onlyContainSubType": "Mostrar sólo las colas de habilidades del tipo de canal cuando un agente transfiere una sesión.",
  "ccmessaging.chat.channelconfig.orgConfiguration": "Configuración de OU",
  "ccmessaging.chat.channelconfig.pageId": "ID de página",
  "ccmessaging.chat.channelconfig.personalSetting": "Configuración personalizada",
  "ccmessaging.chat.channelconfig.phoneNumber": "Número de Teléfono",
  "ccmessaging.chat.channelconfig.pleaseSelect": "Por favor seleccione...",
  "ccmessaging.chat.channelconfig.PreferredLastAgent": "Modo de último agente",
  "ccmessaging.chat.channelconfig.Previous": "Anterior",
  "ccmessaging.chat.channelconfig.privateKeyValidate": "El valor de clave debe estar en formato JSON. El valor de clave en el archivo JSON sólo puede contener letras, dígitos y 11 caracteres especiales _~!{'@'}#$%^&*- cualquier composición",
  "ccmessaging.chat.channelconfig.publicConfiguration": "Configuración común",
  "ccmessaging.chat.channelconfig.pushMessageUrlValid": "Error al guardar la configuración del canal. Verifique que la dirección o el certificado de envío del mensaje sean válidos.",
  "ccmessaging.chat.channelconfig.qryaddressconfigfailed": "Error al consultar la regla de verificación de chatbotAddress del canal 5G. La regla de verificación no está configurada.",
  "ccmessaging.chat.channelconfig.queryType": "Modo de selección",
  "ccmessaging.chat.channelconfig.queueReminder": "Recordatorio de cola",
  "ccmessaging.chat.channelconfig.queueReminderContent": "Contenido de recordatorio de cola",
  "ccmessaging.chat.channelconfig.queueReminderInterval": "Intervalo de recordatorio de cola",
  "ccmessaging.chat.channelconfig.reCreateScenario": "Generar",
  "ccmessaging.chat.channelconfig.refreshAccessToken ": " Error al actualizar el token de acceso.",
  "ccmessaging.chat.channelconfig.robotAccessCode": "Código de acceso de Chatbot",
  "ccmessaging.chat.channelconfig.robotApplicationKey": "Clave de aplicación de Chatbot",
  "ccmessaging.chat.channelconfig.robotSet": "Configuración del Chatbot",
  "ccmessaging.chat.channelconfig.routeType": "Modo de enrutamiento",
  "ccmessaging.chat.channelconfig.routeTypeSkill": "Enrutamiento de colas de habilidades",
  "ccmessaging.chat.channelconfig.saveAndToNext": "Guarde y continúe con el siguiente paso",
  "ccmessaging.chat.channelconfig.savechannelconfigfailed": "Error al guardar la configuración del canal!",
  "ccmessaging.chat.channelconfig.scanCodeHosting": "Código QR",
  "ccmessaging.chat.channelconfig.scenario.status.existent": "clave de escenario:Sí",
  "ccmessaging.chat.channelconfig.scenario.status.noExistent": "clave de escenario:NO",
  "ccmessaging.chat.channelconfig.select.algorithm": "Seleccione un Algoritmo de Cifrado",
  "ccmessaging.chat.channelconfig.select.algorithm.configtips": "Asegúrese de que el algoritmo de cifrado configurado para el canal es el mismo que el de la plataforma",
  "ccmessaging.chat.channelconfig.select.algorithm.safetips": "Este algoritmo no es lo suficientemente fuerte. Si la plataforma admite el algoritmo de cifrado, se recomienda un algoritmo de cifrado más fuerte",
  "ccmessaging.chat.channelconfig.selectAccessChannel": "Seleccionar canal de acceso",
  "ccmessaging.chat.channelconfig.selectConfigurationMethod": "Elija el método de configuración",
  "ccmessaging.chat.channelconfig.selectRobot": "Seleccionar un robot",
  "ccmessaging.chat.channelconfig.selectRobotAssistant": "Seleccionar asistente de robot",
  "ccmessaging.chat.channelconfig.sessionTransferConfig": "Transferencia de Sesión",
  "ccmessaging.chat.channelconfig.silentAgentDelete": "¿Está seguro de que desea eliminar la configuración del agente de silencio de voz seleccionada?",
  "ccmessaging.chat.channelconfig.silentAgentSelect": "Seleccione la configuración del agente de silencio de voz que desea eliminar.",
  "ccmessaging.chat.channelconfig.silentAgentSkillList": "Cola de habilidades de agente silencioso",
  "ccmessaging.chat.channelconfig.skill": "Lista de habilidades (código de acceso+código de acceso)",
  "ccmessaging.chat.channelconfig.skillList": "Cola de habilidades",
  "ccmessaging.chat.channelconfig.smsRepeate": "Se ha utilizado la puerta de enlace sms. Vaya a la p%e1gina de configuraci%f3n de enrutamiento de mensajes",
  "ccmessaging.chat.channelconfig.startTime": "Hora de inicio",
  "ccmessaging.chat.channelconfig.sub": "Copie y pegue el siguiente código en la etiqueta de su sitio web, guarde y publique, puede completar el acceso rápido de ServiceCloud de Huawei. Después del lanzamiento, el portal de Huawei Service Cloud aparecerá en la esquina inferior derecha de su sitio web. Haga clic para ir al banco de trabajo de servicio al cliente. Conversación en tiempo real.",
  "ccmessaging.chat.channelconfig.submit": "Sumarse",
  "ccmessaging.chat.channelconfig.submitVerification": "Instrucciones de integración",
  "ccmessaging.chat.channelconfig.successfulAccess": "Acceso exitoso",
  "ccmessaging.chat.channelconfig.thirdPartySecretKey": "Clave de autenticación de terceros",
  "ccmessaging.chat.channelconfig.time": "Rango de tiempo (min)",
  "ccmessaging.chat.channelconfig.timeoutReply": "Tiempo de espera de respuesta",
  "ccmessaging.chat.channelconfig.timerange": "Rango de tiempo",
  "ccmessaging.chat.channelconfig.timeZone": "Zona horaria",
  "ccmessaging.chat.channelconfig.today": "Día actual",
  "ccmessaging.chat.channelconfig.triggerText": "Mensaje sin conexión",
  "ccmessaging.chat.channelconfig.trustCertificate": "Certificado de confianza",
  "ccmessaging.chat.channelconfig.trybutton": "Prueba",
  "ccmessaging.chat.channelconfig.turnAI": "Conectarse a Chatbot",
  "ccmessaging.chat.channelconfig.turnArtificial": "Palabra clave para transferir al agente",
  "ccmessaging.chat.channelconfig.turnArtificial.tip": "separado por punto y coma en chino o inglés",
  "ccmessaging.chat.channelconfig.turnAssistant": "Conectarse al asistente",
  "ccmessaging.chat.channelconfig.turnManualPrompt": "Solicitar la transferencia al agente",
  "ccmessaging.chat.channelconfig.twitterBackground": "Servidor de Twitter",
  "ccmessaging.chat.channelconfig.twittertips": "Puede acceder a los siguientes sitios web. Haz clic en Enviar para la autenticación de Twitter.",
  "ccmessaging.chat.channelconfig.update": "Actualizacion",
  "ccmessaging.chat.channelconfig.updatecontactfailed": "Error al actualizar el contacto!",
  "ccmessaging.chat.channelconfig.voiceAccess": "IVR llamado",
  "ccmessaging.chat.channelconfig.voiceAgentSkill": "Cola de habilidades de agente",
  "ccmessaging.chat.channelconfig.webhookConfirmPassword": "Webhook Confirmar Contraseña",
  "ccmessaging.chat.channelconfig.webHookException": "Error al obtener la hora válida para la contraseña de webHook",
  "ccmessaging.chat.channelconfig.webhookPassword": "Contraseña de Webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.newPassword": "Webhook nueva contraseña",
  "ccmessaging.chat.channelconfig.webhookPassword.noValidPassword": "No hay contraseña disponible",
  "ccmessaging.chat.channelconfig.webhookPassword.oldPassword": "Contraseña de periodo de bloqueo Webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod": "Válido hasta:",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.fifthDayTip": "Válido por menos de 15 días",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.oneDayTip": "Válido por menos de un día. ¡Modifique!",
  "ccmessaging.chat.channelconfig.wechat.encryption.tip": "La desactivación del cifrado de mensajes puede ocasionar riesgos de seguridad. La configuración debe ser coherente con la de la Plataforma de Cuentas Oficiales de la WeChat.",
  "ccmessaging.chat.channelconfig.wechat.qr.label": "Escanear código QR",
  "ccmessaging.chat.channelconfig.wechat.qr.label2": "Reautorizar",
  "ccmessaging.chat.channelconfig.wechat.qr.label3": "Autorización exitosa",
  "ccmessaging.chat.channelconfig.weChatBackground": "Servidor WeChat",
  "ccmessaging.chat.channelconfig.weChatMessageEncryptionSwitch": "Conmutador de cifrado de mensajes WeChat",
  "ccmessaging.chat.channelconfig.welcomeMessage": "Saludos",
  "ccmessaging.chat.channelconfig.whatsAppBackground": "Servidor WhatsApp",
  "ccmessaging.chat.channelconfig.workTimeDay": "Día de trabajo",
  "ccmessaging.chat.channelconfig.worktimeerror": "Por favor, introduzca el período de tiempo normal!",
  "ccmessaging.chat.chatcard.audio": "AUDIO",
  "ccmessaging.chat.chatcard.button.addtabs": "Añadir opciones de tarjeta",
  "ccmessaging.chat.chatcard.cancel": "Cancelar",
  "ccmessaging.chat.chatcard.cardId": "ID de plantilla",
  "ccmessaging.chat.chatcard.cardName": "Nombre",
  "ccmessaging.chat.chatcard.cardNamePlaceholder": "Introduzca el nombre de la plantilla de tarjeta.",
  "ccmessaging.chat.chatcard.cardTitle": "Título",
  "ccmessaging.chat.chatcard.cardTitlePlaceholder": "Introduzca el título de la tarjeta.",
  "ccmessaging.chat.chatcard.create": "Guardar",
  "ccmessaging.chat.chatcard.delete": "Borrar",
  "ccmessaging.chat.chatcard.deleteFail": "Error de eliminación",
  "ccmessaging.chat.chatcard.deleteLimit": "Se ha hecho referencia a la plantilla y no se puede eliminar.",
  "ccmessaging.chat.chatcard.deleteSuccess": "Eliminar éxito",
  "ccmessaging.chat.chatcard.deleteTips": "Error en la verificación de parámetros.",
  "ccmessaging.chat.chatcard.deleteWhatsAppTemplate.Fail": "Error en la eliminación porque un mensaje de plantilla de WhatsApp ya hace referencia a la plantilla.",
  "ccmessaging.chat.chatcard.description": "Descripcion",
  "ccmessaging.chat.chatcard.descriptionPlaceholder": "Introduzca la descripción de la tarjeta.",
  "ccmessaging.chat.chatcard.details": "Detalles de la tarjeta",
  "ccmessaging.chat.chatcard.dial": "marcar",
  "ccmessaging.chat.chatcard.down": "Desplazarse hacia abajo",
  "ccmessaging.chat.chatcard.edit": "Editar",
  "ccmessaging.chat.chatcard.fail": "Fallo",
  "ccmessaging.chat.chatcard.failTip": "Error al agregar El número de opciones añadidas alcanza el máximo.",
  "ccmessaging.chat.chatcard.folded": "doblado",
  "ccmessaging.chat.chatcard.id": "Introduzca un ID de plantilla.",
  "ccmessaging.chat.chatcard.image": "IMÁGENES",
  "ccmessaging.chat.chatcard.input": "Escriba un nombre de plantilla.",

  "ccmessaging.chat.chatcard.invalidUrl": "La dirección del enlace no comienza con http://,https://",
  "ccmessaging.chat.chatcard.linking": "enlace",
  "ccmessaging.chat.chatcard.mediaFileType ": " Tipo de archivo multimedia",
  "ccmessaging.chat.chatcard.mediaHeight": "Altura del medio",
  "ccmessaging.chat.chatcard.mediaType": "Tipo de medios",
  "ccmessaging.chat.chatcard.mediumHeight": "Altura media",
  "ccmessaging.chat.chatcard.messageTemplateCreate": "Creación de una plantilla de tarjeta",
  "ccmessaging.chat.chatcard.messageTemplateDelete": "Eliminar una plantilla",
  "ccmessaging.chat.chatcard.messageTemplateModify": "Modificación de una plantilla",
  "ccmessaging.chat.chatcard.multimediaid": "ID multimedia",
  "ccmessaging.chat.chatcard.multiMediaId": "Recursos multimedia",
  "ccmessaging.chat.chatcard.multimedianame": "Nombre de archivo multimedia",
  "ccmessaging.chat.chatcard.name": "Ingrese su nombre.",
  "ccmessaging.chat.chatcard.NoneTitle": "sin título",
  "ccmessaging.chat.chatcard.operate": "Operación",
  "ccmessaging.chat.chatcard.queryTemplateFail": "Fallo de la plantilla de consulta",
  "ccmessaging.chat.chatcard.replay": "reproducción",
  "ccmessaging.chat.chatcard.saveFail": "Guardar falla",
  "ccmessaging.chat.chatcard.saveSuccess": "Guardar éxito",
  "ccmessaging.chat.chatcard.selectMultiMediaId": "Selección de recursos multimedia.",
  "ccmessaging.chat.chatcard.selectMultiMediaTips": "Seleccione recursos multimedia.",
  "ccmessaging.chat.chatcard.closeDialog": "¿Está seguro de cerrar este cuadro de diálogo?",
  "ccmessaging.chat.chatcard.shortHeight": "Altura corta",
  "ccmessaging.chat.chatcard.strcontentTip": "Las solicitudes HTTP tienen riesgos de seguridad.",
  "ccmessaging.chat.chatcard.success": "Éxito",
  "ccmessaging.chat.chatcard.tabs": "Opciones de tarjeta",
  "ccmessaging.chat.chatcard.tabs.displaytext": "Texto de visualización de la tarjeta",
  "ccmessaging.chat.chatcard.tabs.selectcontent": "seleccionar contenido",
  "ccmessaging.chat.chatcard.tabs.selectType": "Tipo de opción",
  "ccmessaging.chat.chatcard.tabsname": "Nombre de la opción de tarjeta",
  "ccmessaging.chat.chatcard.tallHeight": "Altura alta",
  "ccmessaging.chat.chatcard.up": "Subir",
  "ccmessaging.chat.chatcard.video": "VÍDEO",
  "ccmessaging.chat.chatcard.viewTips": "La plantilla de tarjeta se ha eliminado y no se puede previsualizar.",
  "ccmessaging.chat.chatcard.whatsappTemplateCreate": "Crear una plantilla de WhatsApp",
  "ccmessaging.chat.chatcard.willdeletechatcard": "¿Está seguro de que desea eliminar esta tarjeta?",
  "ccmessaging.chat.chatcard.willdeletechatTemplate": "¿Está seguro de que desea eliminar la plantilla WhatsApp?",
  "ccmessaging.chat.chatcard.nameExist": "El nombre ya existe. Por favor, ingrese otro.",
  "ccmessaging.chat.chatconfig.add": "Nuevo",
  "ccmessaging.chat.chatconfig.channelAccessCode": "Código de acceso al canal",
  "ccmessaging.chat.chatconfig.channelOccupied": "El canal ya está ocupado.",
  "ccmessaging.chat.chatconfig.channelType": "Tipo de canal",
  "ccmessaging.chat.chatconfig.configurationId": "ID de configuración",
  "ccmessaging.chat.chatconfig.delete": "Borrar",
  "ccmessaging.chat.chatconfig.deletechannelAccessCode": "Eliminar configuración de canal",
  "ccmessaging.chat.chatconfig.deletefail": "Error en la eliminación.",
  "ccmessaging.chat.chatconfig.deletesuccess": "Se ha eliminado correctamente.",
  "ccmessaging.chat.chatconfig.idisnull": "El ID está vacío.",
  "ccmessaging.chat.chatconfig.inquire": "Buscar",
  "ccmessaging.chat.chatconfig.modify": "Modificar",
  "ccmessaging.chat.chatconfig.operate": "Operación",
  "ccmessaging.chat.chatconfig.refresh": "Refrescar",
  "ccmessaging.chat.chatconfig.reset": "Restablecer",
  "ccmessaging.chat.chatconfig.rollBack": "Retirar",
  "ccmessaging.chat.chatconfig.selectchannelAccessCode": "Seleccione la configuración del canal.",
  "ccmessaging.chat.chatconfig.skillId": "Cola de habilidades de enlace",
  "ccmessaging.chat.chatconfig.willdeletechannelAccessCode": "¿Está seguro de que desea eliminar la configuración de canal seleccionada?",
  "ccmessaging.chat.chatemail.cancelhandled": "Cancelación procesada",
  "ccmessaging.chat.chatemail.delete": "Borrar",
  "ccmessaging.chat.chatemail.details": "Ver",
  "ccmessaging.chat.chatemail.forwarded": "Reenviado",
  "ccmessaging.chat.chatemail.forwardedTime": "Reenviado en",
  "ccmessaging.chat.chatemail.handled": "Procesado",
  "ccmessaging.chat.chatemail.maxNumberImages": "El número de imágenes locales subidas ha alcanzado el máximo de 20.",
  "ccmessaging.chat.chatemail.saveDraft": "Guardar como borrador",
  "ccmessaging.chat.chatemail.sender": "Remitente:",
  "ccmessaging.chat.chatholiday.calendar.preview": "Vista previa del calendario",
  "ccmessaging.chat.chatholiday.checkHolidaySize": "Hay demasiadas entradas, por favor elimine las entradas adicionales primero",
  "ccmessaging.chat.chatholiday.date": "Fecha",
  "ccmessaging.chat.chatholiday.dateConfiguration": "Configuración de fecha",
  "ccmessaging.chat.chatholiday.delete": "eliminar",
  "ccmessaging.chat.chatholiday.description": "Descripcion",
  "ccmessaging.chat.chatholiday.designation.alert": "La fecha de finalización debe ser posterior a la fecha de inicio",
  "ccmessaging.chat.chatholiday.designation.cancel": "Cancelar",
  "ccmessaging.chat.chatholiday.designation.complete": "Completo",
  "ccmessaging.chat.chatholiday.designation.days": "Varios días",
  "ccmessaging.chat.chatholiday.designation.end": "Fecha de finalización",
  "ccmessaging.chat.chatholiday.designation.norepeating": "No repetido",
  "ccmessaging.chat.chatholiday.designation.oneday": "1 Día",
  "ccmessaging.chat.chatholiday.designation.repeating": "Se repite cada año",
  "ccmessaging.chat.chatholiday.designation.selectrule": "La fecha seleccionada no puede ser la misma que la fecha seleccionada",
  "ccmessaging.chat.chatholiday.designation.show": "Cada año",
  "ccmessaging.chat.chatholiday.designation.start": "Fecha de inicio",
  "ccmessaging.chat.chatholiday.designation.type": "Tipo de fecha",
  "ccmessaging.chat.chatholiday.friday": "Viernes",
  "ccmessaging.chat.chatholiday.holidays.explanation": "Puede especificar ciertos días como días no laborables, por ejemplo, el 13 de septiembre de 2019 (viernes, festival chino de mediados de otoño).",
  "ccmessaging.chat.chatholiday.isDelete.otherdays": "¿Está seguro de que desea eliminar esta fecha de excepción?",
  "ccmessaging.chat.chatholiday.isDelete.specified": "¿Está seguro de que desea eliminar esta fecha especificada?",
  "ccmessaging.chat.chatholiday.monday": "Lunes",
  "ccmessaging.chat.chatholiday.new": "Nuevo",
  "ccmessaging.chat.chatholiday.nonWorkbench": "Configuración no laboral",
  "ccmessaging.chat.chatholiday.noworking": "Día no laboral",
  "ccmessaging.chat.chatholiday.operate": "operar",
  "ccmessaging.chat.chatholiday.other.prompt": "La fecha seleccionada es la misma que una fecha existente",
  "ccmessaging.chat.chatholiday.otherdays.exception": "Fecha de excepción",
  "ccmessaging.chat.chatholiday.otherdays.explanation": "Puede seleccionar ciertos días como días laborables. El intervalo de tiempo máximo es de un año. Por ejemplo: puedes seleccionar el 13 de julio de 2019 (sábado, se requieren horas extraordinarias temporales) como día laborable.",
  "ccmessaging.chat.chatholiday.repeat": "Reglas de repetición",
  "ccmessaging.chat.chatholiday.saturday": "Sábado",
  "ccmessaging.chat.chatholiday.savechatholidayfailed": "Error al guardar la configuración de vacaciones",
  "ccmessaging.chat.chatholiday.specified.date": "Fecha especificada",
  "ccmessaging.chat.chatholiday.sunday": "Domingo",
  "ccmessaging.chat.chatholiday.thursday": "Jueves",
  "ccmessaging.chat.chatholiday.tuesday": "Martes",
  "ccmessaging.chat.chatholiday.wednesday": "Miércoles",
  "ccmessaging.chat.chatholiday.weekend.explanation": "Puede establecer ciertos días de una semana como días no laborables, por ejemplo, sábado y domingo.",
  "ccmessaging.chat.chatholiday.weekly": "Semanalmente",
  "ccmessaging.chat.chatholiday.workbench": "Configuración del banco de trabajo",
  "ccmessaging.chat.chatholiday.working.day": "Día de trabajo",
  "ccmessaging.chat.chatholiday.tip.choosedate": "Por favor seleccione una fecha",
  "ccmessaging.chat.chatholiday.tip.descinvalid": "La descripción no puede superar los 20 caracteres",
  "ccmessaging.chat.chatmanage.agentIntervalTime": "Período de tiempo de espera de respuesta del agente",
  "ccmessaging.chat.chatmanage.agentSessionEndText": "Aviso de reasignación de agente",
  "ccmessaging.chat.chatmanage.autoEndSession": "Si el invitado no responde dentro del tiempo especificado, el cuadro de diálogo finaliza automáticamente.",
  "ccmessaging.chat.chatmanage.autoEndSessionMorethan": "La descripción del intervalo en el que el cliente no tiene respuesta no puede exceder de 100 caracteres.",
  "ccmessaging.chat.chatmanage.autoEndSessionnotnull": "La descripción del intervalo de no respuesta del cliente no puede estar vacía",
  "ccmessaging.chat.chatmanage.autoEndSessionNum": "El intervalo de notificación de no respuesta del cliente debe ser un número entero",
  "ccmessaging.chat.chatmanage.autoEndSessionRange": "El intervalo de notificación del cliente no responde debe estar entre 0 y 60",
  "ccmessaging.chat.chatmanage.cancel": "Cancelar",
  "ccmessaging.chat.chatmanage.edit": "Editar",
  "ccmessaging.chat.chatmanage.endTime": "Hora de finalización",
  "ccmessaging.chat.chatmanage.endTimeNum": "La hora de finalización debe ser solo un número",
  "ccmessaging.chat.chatmanage.endTimeRange": "El rango numérico de hora de finalización debe estar entre 0 y 24",
  "ccmessaging.chat.chatmanage.fail": "Fallo",
  "ccmessaging.chat.chatmanage.freeTimeText": "Notificación de tiempo no laboral",
  "ccmessaging.chat.chatmanage.freeTimeTextMorethan": "La descripción de la notificación de tiempo no laborable no puede contener más de 100 caracteres.",
  "ccmessaging.chat.chatmanage.freeTimeTextnotnull": "La descripción del recordatorio de tiempo no laborable no puede estar vacía",
  "ccmessaging.chat.chatmanage.intervalTime": "Período de tiempo de espera de respuesta del cliente",
  "ccmessaging.chat.chatmanage.save": "Guardar",
  "ccmessaging.chat.chatmanage.savefailed": "Error al guardar.",
  "ccmessaging.chat.chatmanage.savesuccess": "Se ha guardado correctamente.",
  "ccmessaging.chat.chatmanage.sessionEndText": "Conclusión",
  "ccmessaging.chat.chatmanage.sessionEndTextMorethan": "La descripción de conclusión del cuadro de diálogo no puede contener más de 100 caracteres.",
  "ccmessaging.chat.chatmanage.sessionEndTextnotnull": "La descripción del final de la sesión no puede estar vacía",
  "ccmessaging.chat.chatmanage.specialCharacters": "Contiene caracteres especiales. Modifique los caracteres",
  "ccmessaging.chat.chatmanage.startTime": "Hora de inicio",
  "ccmessaging.chat.chatmanage.startTimeNum": "La hora de inicio debe ser solo un número",
  "ccmessaging.chat.chatmanage.startTimeRange": "El rango numérico de hora de inicio debe estar entre 0 y 24",
  "ccmessaging.chat.chatmanage.success": "Éxito",
  "ccmessaging.chat.chatmanage.useRobot": "Conectarse a Chatbot",
  "ccmessaging.chat.chatmanage.workTime": "Tiempo de trabajo del agente (en formato de 24 horas)",
  "ccmessaging.chat.chatmanage.workTimeMorethan": "La descripción del tiempo de trabajo no puede contener más de 100 caracteres.",
  "ccmessaging.chat.chatpersonalization.add": "Añadir",
  "ccmessaging.chat.chatpersonalization.avatarerror": "Seleccione una imagen.!",
  "ccmessaging.chat.chatpersonalization.cancel": "Cancelar",
  "ccmessaging.chat.chatpersonalization.changeAvatar": "Cambiar avatar",
  "ccmessaging.chat.chatpersonalization.concurrentAgentsNumber": "Número de clientes multimedia de servicio simultáneo del agente",
  "ccmessaging.chat.chatpersonalization.concurrentNumberPriority": "Prioridad efectiva de la configuración del número de clientes multimedia simultáneos del agente: nivel de agente > nivel de organización > nivel de inquilino. El valor 0 indica que la configuración de este nivel no tiene efecto y se utilizarán otras configuraciones",
  "ccmessaging.chat.chatpersonalization.delete": "Borrar",
  "ccmessaging.chat.chatpersonalization.edit": "Editar",
  "ccmessaging.chat.chatpersonalization.hour": "Horario",
  "ccmessaging.chat.chatpersonalization.MaximumConcurrent": "Máximo clientes de servicio simultáneo",
  "ccmessaging.chat.chatpersonalization.nickname": "Apellido",
  "ccmessaging.chat.chatpersonalization.operating": "Operación",
  "ccmessaging.chat.chatpersonalization.prompt": "Iniciar sesión",
  "ccmessaging.chat.chatpersonalization.save": "Guardar",
  "ccmessaging.chat.chatpersonalization.savefailed": "Error al guardar.",
  "ccmessaging.chat.chatpersonalization.saveSuccess": "Se ha guardado correctamente.",
  "ccmessaging.chat.chatpersonalization.specialStrValidateFailed": "El valor no puede contener caracteres especiales.",
  "ccmessaging.chat.chatpersonalization.tips1": "No se ha verificado la configuración enviada!",
  "ccmessaging.chat.chatpersonalization.welcome": "Saludos",
  "ccmessaging.chat.chatpersonalization.welcomeLimit": "Se pueden configurar un máximo de cuatro saludos personalizados.",
  "ccmessaging.chat.chatpersonalization.welcomeText": "Saludos Personalizados",
  "ccmessaging.chat.chatpersonalization.welcometime": "Rango de tiempo",
  "ccmessaging.chat.chatpersonalization.welcometimeerror": "¡Introduce un segmento de tiempo normal!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.nolist": "Por favor, establezca un saludo personalizado!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.startGreaterThanEnd": "El tiempo de inicio del saludo personalizado no puede ser mayor o igual al tiempo de fin!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.timeOverlap": "El período de tiempo de los saludos personalizados no puede superponerse!",
  "ccmessaging.chat.chinese.separator": ":",
  "ccmessaging.chat.clickCallClient.denial": "El enlace ha caducado. Si es necesario póngase en contacto de nuevo con el agente para enviar el enlace.",
  "ccmessaging.chat.close": "segundos después de cerrar la página automáticamente",
  "ccmessaging.chat.connnect.offlineuser.failed": "Error al iniciar una llamada sin conexión como agente.",
  "ccmessaging.chat.connnect.offlineuser.userId.beyondMaxLength": "UserId es más de 128",
  "ccmessaging.chat.contact.callin": "Llamada entrante",
  "ccmessaging.chat.contact.callinandcallout": "Llamada entrante y saliente",
  "ccmessaging.chat.contact.callout": "Llamada saliente",
  "ccmessaging.chat.email.AgentAccountName": "Cuenta de agente",
  "ccmessaging.chat.email.agentNumber": "ID de agente",
  "ccmessaging.chat.email.autosave": "Sus ediciones se guardaron automáticamente en un borrador.",
  "ccmessaging.chat.email.button.advancedSearch": "Búsqueda avanzada",
  "ccmessaging.chat.email.button.batchAssignment": "Asignación por lotes",
  "ccmessaging.chat.email.button.bulkMove": "Movimiento masivo",
  "ccmessaging.chat.email.card.reply": "Responder",
  "ccmessaging.chat.email.channel.attendeeError": "El número de destinatarios de Cc excede el límite.",
  "ccmessaging.chat.email.channel.BccSenderError": "El número de destinatarios de BCC excede el límite.",
  "ccmessaging.chat.email.channel.ccandbcc.containchannelemail": "El remitente de CC y el remitente de CC no pueden ser la dirección de correo electrónico pública del canal",
  "ccmessaging.chat.email.channel.confdeletedraft": "¿Está seguro de que desea eliminar el borrador de correo electrónico?",
  "ccmessaging.chat.email.channel.confdmodifydraft": "¿Está seguro de que ha procesado el correo electrónico?",
  "ccmessaging.chat.email.channel.deletedraft": "Confirmar",
  "ccmessaging.chat.email.channel.deletedraftfail": "Error al eliminar el borrador del correo electrónico.",
  "ccmessaging.chat.email.channel.deletedraftsuccess": "El borrador de correo electrónico se elimina correctamente.",
  "ccmessaging.chat.email.channel.draftOverMax": "El número de borradores de correo electrónico excede el máximo.",
  "ccmessaging.chat.email.channel.emailaddressinvalid": "La dirección de correo electrónico no es válida.",
  "ccmessaging.chat.email.channel.modifydraft": "Confirmar",
  "ccmessaging.chat.email.channel.modifydraftfail": "Error en la modificación.",
  "ccmessaging.chat.email.channel.modifydraftsuccess": "La modificación se realizó correctamente.",
  "ccmessaging.chat.email.channel.paramisempty": "El parámetro no puede estar vacío.",
  "ccmessaging.chat.email.channel.paramiserror": "El parámetro no es válido.",
  "ccmessaging.chat.email.channel.receiveGateway": "Puerta de enlace de recepción de correo electrónico",
  "ccmessaging.chat.email.channel.receivererror": "El destinatario no contiene al usuario seleccionado.",
  "ccmessaging.chat.email.channel.savedraftfail": "Error al guardar el correo electrónico como borrador.",
  "ccmessaging.chat.email.channel.savedraftsuccess": "El correo electrónico se guarda como borrador correctamente.",
  "ccmessaging.chat.email.channel.sendGateway": "Pasarela de envío de correo electrónico",
  "ccmessaging.chat.email.channel.title.reply": "Respuesta:",
  "ccmessaging.chat.email.check.containGatewayEmail": "No envíe correos electrónicos a canales ni reciba direcciones de correo electrónico.",
  "ccmessaging.chat.email.check.contentOverMax": "El cuerpo del mensaje supera el límite de tamaño",
  "ccmessaging.chat.email.check.localImageNumberOverMax": "El número de imágenes locales cargadas en el cuerpo del correo electrónico excede el máximo.",
  "ccmessaging.chat.email.check.localImageOverMax": "El tamaño de las imágenes locales cargadas en el cuerpo del correo electrónico excede el máximo.",
  "ccmessaging.chat.email.check.localImageType": "No se admite el formato de imagen.",
  "ccmessaging.chat.email.check.uploadATTACHMENTPermissionAndFeature": "No hay permiso para cargar archivos adjuntos o esta función no está habilitada.",
  "ccmessaging.chat.email.check.uploadImagePermissionAndFeature": "No hay permiso para cargar imágenes locales o esta función no está habilitada.",
  "ccmessaging.chat.email.forward": "Reenviar",
  "ccmessaging.chat.email.forward.prefix": "Hacia delante:",
  "ccmessaging.chat.email.localImage.confirm": "Confirmar",
  "ccmessaging.chat.email.localImage.height": "Altura",
  "ccmessaging.chat.email.localImage.lable": "Archivo",
  "ccmessaging.chat.email.localImage.width": "Anchura",
  "ccmessaging.chat.email.param.empty": "El título o el contenido del correo electrónico no pueden estar vacíos.",
  "ccmessaging.chat.email.reply": "Reseñas",
  "ccmessaging.chat.email.reply.separator": ":",
  "ccmessaging.chat.email.tips.addFailDuplicateName": "Error al agregar: nombre duplicado.",
  "ccmessaging.chat.email.tips.addFailOtherErrors": "Error al agregar: Otros errores.",
  "ccmessaging.chat.email.tips.addMaximumLimit": "Un máximo de 20 subdirectorios en el mismo nivel.",
  "ccmessaging.chat.email.tips.addSuccess": "Añadiendo éxito.",
  "ccmessaging.chat.email.tips.amendFailDuplicateName": "Error en la modificación: nombre duplicado.",
  "ccmessaging.chat.email.tips.amendFailOtherErrors": "Error en la modificación: Otros errores.",
  "ccmessaging.chat.email.tips.amendSuccess": "La modificación se realizó correctamente.",
  "ccmessaging.chat.email.tips.deleteFailExistEmail": "Error en la eliminación: existen correos en el directorio actual.",
  "ccmessaging.chat.email.tips.deleteFailExistSub": "Error de eliminación: el directorio actual tiene subdirectorios.",
  "ccmessaging.chat.email.tips.deleteFailOtherErrors": "Error en la eliminación: Otros errores.",
  "ccmessaging.chat.email.tips.deleteSuccess": "Eliminado correctamente.",
  "ccmessaging.chat.email.tips.isDeleteDirectory": "¿Desea eliminar el directorio actual?",
  "ccmessaging.chat.emailmessage.abandoned": "Abandonado",
  "ccmessaging.chat.emailmessage.agent": "Agente",
  "ccmessaging.chat.emailmessage.all": "Todo",
  "ccmessaging.chat.emailmessage.assigned": "Asignado",
  "ccmessaging.chat.emailmessage.assignEmail.inputAgentName": "Introduzca el nombre de la cuenta del agente.",
  "ccmessaging.chat.emailmessage.assignEmail.queryAgentName": "Introduzca una cuenta de servicio",
  "ccmessaging.chat.emailmessage.assignEmailFailed": "Error al asignar el correo electrónico.",
  "ccmessaging.chat.emailmessage.assignEmailPartialSuccess": "Error al asignar algunos correos electrónicos.",
  "ccmessaging.chat.emailmessage.assignEmailSuccess": "El correo electrónico se asigna correctamente.",
  "ccmessaging.chat.emailmessage.assigning": "Asignar",
  "ccmessaging.chat.emailmessage.assignOtherEmailFailed": "El correo electrónico del remitente no se puede asignar porque se está manejando.",
  "ccmessaging.chat.emailmessage.assignStatus": "Estado de la asignación",
  "ccmessaging.chat.emailmessage.channel": "Respuesta automática de canal",
  "ccmessaging.chat.emailmessage.channelAccessCode": "Código de acceso al canal de correo electrónico",
  "ccmessaging.chat.emailmessage.disAssigned": "No asignado",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.assigning.tip": "Los mensajes descartados no se pueden asignar.",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.moving.tip": "Los mensajes eliminados no se pueden mover.",
  "ccmessaging.chat.emailmessage.fail.assign.UnAssignedEmail.tip": "Los mensajes no asignados no se pueden asignar.",
  "ccmessaging.chat.emailmessage.handler": "Controlador",
  "ccmessaging.chat.emailmessage.handlerType": "Tipo de controlador",
  "ccmessaging.chat.emailmessage.handleStatus": "Estado de manejo",
  "ccmessaging.chat.emailmessage.moving": "Desplazarse",
  "ccmessaging.chat.emailmessage.operation.noMoreThanOneDay": "El intervalo de tiempo de consulta no puede exceder de un día.",
  "ccmessaging.chat.emailmessage.operation.noMoreThanSevenDay": "El intervalo de tiempo de consulta no puede exceder de siete días.",
  "ccmessaging.chat.emailmessage.receiver": "Destinatario",
  "ccmessaging.chat.emailmessage.robot": "Robot",
  "ccmessaging.chat.emailmessage.sender": "Remitente",
  "ccmessaging.chat.emailmessage.sendStatus": "Envío de resultados",
  "ccmessaging.chat.emailmessage.sendTime": "Enviado el",
  "ccmessaging.chat.emailmessage.skillQueue": "Cola de habilidades",
  "ccmessaging.chat.emailmessage.title": "Título del correo electrónico",
  "ccmessaging.chat.emailmessage.unassigned": "No asignado",
  "ccmessaging.chat.EMOTICONS.beyondFileSize": "El número de archivos multimedia cargados es de error o supera el máximo. {n} KB!",
  "ccmessaging.chat.facebookpageconfig.add": "Añadir página de inicio de Facebook",
  "ccmessaging.chat.facebookpageconfig.config": "Configuraciones",
  "ccmessaging.chat.facebookpageconfig.deleteError": "Error al eliminar la configuración de la página de inicio de Facebook.",
  "ccmessaging.chat.facebookpageconfig.deleteSuccess": "La configuración de la página de inicio de Facebook se elimina correctamente.",
  "ccmessaging.chat.facebookpageconfig.facebookconfig": "Configuración de la página de inicio de Facebook",
  "ccmessaging.chat.facebookpageconfig.idNotExist": "Los datos no existen.",
  "ccmessaging.chat.facebookpageconfig.isDeleteFacebookPage": "¿Está seguro de que desea eliminar la configuración de la página de inicio de Facebook?",
  "ccmessaging.chat.facebookpageconfig.modify": "Editar página de Facebook",
  "ccmessaging.chat.facebookpageconfig.modifyError": "Error al modificar la página de inicio de Facebook.",
  "ccmessaging.chat.facebookpageconfig.modifySuccess": "La página de inicio de Facebook se ha modificado correctamente.",
  "ccmessaging.chat.facebookpageconfig.numoversize": "El número de páginas de inicio de Facebook excede el límite superior de este canal.",
  "ccmessaging.chat.facebookpageconfig.pageId": "ID de página",
  "ccmessaging.chat.facebookpageconfig.pageidexist": "El ID de la página de inicio de Facebook ya existe.",
  "ccmessaging.chat.facebookpageconfig.saveError": "Error al agregar la página de inicio de Facebook.",
  "ccmessaging.chat.facebookpageconfig.saveSuccess": "La página de inicio de Facebook se ha añadido correctamente.",
  "ccmessaging.chat.facebookpageconfig.tokenSecret": "Token de acceso",
  "ccmessaging.chat.fivegCard": "Tarjeta 5G",
  "ccmessaging.chat.ifame.param": "Parámetro",
  "ccmessaging.chat.iframeagentconsole.mail": "Correo electrónico",
  "ccmessaging.chat.iframeagentconsole.mailCollapse": "acolchado",
  "ccmessaging.chat.iframeagentconsole.mailExpand": "Detalles",
  "ccmessaging.chat.label.accessCode": "Código de acceso",
  "ccmessaging.chat.label.extCode": "Código de extensión",
  "ccmessaging.chat.label.skill": "Cola de habilidades",
  "ccmessaging.chat.localFile.authFail": "Sin permiso de envío de archivos locales",
  "ccmessaging.chat.multi.card": "Tarjeta giratoria 5G",
  "ccmessaging.chat.multi.card.add": "Seleccione la tarjeta",
  "ccmessaging.chat.multi.card.id": "Identificación de la tarjeta",
  "ccmessaging.chat.multi.card.num.error": "Añade de 1 a 5 cartas individuales.",
  "ccmessaging.chat.multi.chatcard.alignment": "Modo de alineación de imagen",
  "ccmessaging.chat.multi.chatcard.alignment.left": "Alineación izquierda",
  "ccmessaging.chat.multi.chatcard.alignment.right": "Alineación a la derecha",
  "ccmessaging.chat.multi.chatcard.choose": "Selección de cartas",
  "ccmessaging.chat.multi.chatcard.delete": "Se ha hecho referencia a la plantilla y no se puede eliminar. La tarjeta NVOD a la que se hace referencia es:",
  "ccmessaging.chat.multi.chatcard.sort": "Dirección de disposición de la tarjeta",
  "ccmessaging.chat.multi.chatcard.sort.flow": "flotadores",
  "ccmessaging.chat.multi.chatcard.sort.horizontal": "nivelación",
  "ccmessaging.chat.multi.chatcard.sort.vertical": "perpendicular",
  "ccmessaging.chat.multi.chatcard.width": "Anchura de la tarjeta",
  "ccmessaging.chat.multi.chatcard.width.medium": "Ancho medio",
  "ccmessaging.chat.multi.chatcard.width.small": "Ancho más pequeño",
  "ccmessaging.chat.multi.media.resource.invalid": "Tarjetas que hacen referencia a recursos multimedia no válidos. Nombre de la tarjeta:",
  "ccmessaging.chat.multimedia.addCategory": "Añadir categoría",
  "ccmessaging.chat.multimedia.audioManagement": "Biblioteca de audio",
  "ccmessaging.chat.multimedia.audioUploadTipFormat": "El archivo de audio que se cargará debe tener formato MP3, AAC o M4A, y el tamaño del archivo no puede ser mayor que {n} MB.",
  "ccmessaging.chat.multimedia.beyondFileSize": "El tamaño del archivo multimedia que se va a cargar supera el límite superior : {n} MB!",
  "ccmessaging.chat.multimedia.cancel": "Cancelar",
  "ccmessaging.chat.multimedia.cardManagement": "Plantilla de tarjeta",
  "ccmessaging.chat.multimedia.categoryName": "Nombre de la categoría",
  "ccmessaging.chat.multimedia.chatPhraseManagement": "Biblioteca de ChatPhrase",
  "ccmessaging.chat.multimedia.cobrowseSite": "Sitio de Cobrowse",
  "ccmessaging.chat.multimedia.createRichText": "Crear texto enriquecido",
  "ccmessaging.chat.multimedia.delete": "Borrar",
  "ccmessaging.chat.multimedia.deleteLimit": "La plantilla de tarjeta {0} hace referencia al recurso y no se puede eliminar.",
  "ccmessaging.chat.multimedia.deleteLimit.usedbyODFS": "El recurso ha sido referenciado por el IVR inteligente y no se puede eliminar",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.templatemsg": "No se puede eliminar el recurso porque ya está referenciado por un mensaje de plantilla de WhatsApp. Mensaje de plantilla:",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfo": "Eliminar estos datos multimedia",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoError": "Error al eliminar los datos multimedia!",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoSuccess": "¡Elimine con éxito los datos multimedia!",
  "ccmessaging.chat.multimedia.deleteMultiMediaType": "Eliminar esta categoría multimedia",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError": "Error al eliminar la categoría de biblioteca multimedia!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.usedbyODFS": "Error al eliminar el tipo de biblioteca multimedia.El recurso al que hace referencia el IVR inteligente existe.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byCard": "No se pudo eliminar la categoría multimedia porque las tarjetas ya hacen referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byWhatsAppTemplateMsg": "Error al eliminar la categoría multimedia porque un mensaje de plantilla de WhatsApp ya hace referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeSuccess": "Eliminar con éxito la categoría multimedia!",
  "ccmessaging.chat.multimedia.description": "Descripcion",
  "ccmessaging.chat.multimedia.descriptionContainsSpecialCharacters": "La descripción contiene caracteres especiales.",
  "ccmessaging.chat.multimedia.descriptionSuperLong": "La longitud de la descripción excede el máximo.",
  "ccmessaging.chat.multimedia.documentManagement": "Biblioteca de documentos",
  "ccmessaging.chat.multimedia.documentUploadTipFormat": "Los archivos se pueden cargar en formato word,pdf,ppt,excel o zip. El archivo zip admite los formatos word,pdf,ppt y excel. El tamaño del archivo no puede exceder {n} MB.",
  "ccmessaging.chat.multimedia.download": "Descargar",
  "ccmessaging.chat.multimedia.edit": "Editar",
  "ccmessaging.chat.multimedia.emoticonsManagement": "Emoticonos",
  "ccmessaging.chat.multimedia.emoticonsUploadTipFormat": "El archivo emoticono personalizado cargado puede estar en formato JPG, JPEG, PNG o GIF y el tamaño del archivo no puede exceder los {n} KB.",
  "ccmessaging.chat.multimedia.exceedFileSize": "El número de archivos multimedia cargados es de error o excede el máximo. {n}!",
  "ccmessaging.chat.multimedia.fileName": "Nombre de archivo",
  "ccmessaging.chat.multimedia.fileSize": "Tamaño del archivo",
  "ccmessaging.chat.multiMedia.fileUploadError": "El archivo cargado tiene el tamaño o formato incorrectos",
  "ccmessaging.chat.multiMedia.fileUploadInjectionRisk": "La carga tiene riesgos de inyección. Compruebe el contenido e inténtelo de nuevo",
  "ccmessaging.chat.multimedia.fileUploadLabel": "Por favor, selecciona un archivo para cargar",
  "ccmessaging.chat.multiMedia.fileUploadNameError": "El formato de nombre del archivo cargado es incorrecto",
  "ccmessaging.chat.multiMedia.fileUploadTypeError": "El formato del archivo cargado es incorrecto",
  "ccmessaging.chat.multimedia.finish": "Finalizar",
  "ccmessaging.chat.multimedia.getRichTextInfoError": "Error al obtener datos de texto enriquecido.",
  "ccmessaging.chat.multimedia.id": "Identificación",
  "ccmessaging.chat.multimedia.imageManagement": "Biblioteca de imágenes",
  "ccmessaging.chat.multimedia.imageUploadTipFormat": "El archivo de imagen cargado actualmente solo admite formatos jpg, png, jpeg, y el tamaño del archivo no debe exceder {n}M.",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaInfo": "¿Está seguro de que desea eliminar estos datos multimedia?",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaType": "¿Está seguro de que desea eliminar el tipo multimedia y sus datos multimedia?",
  "ccmessaging.chat.multimedia.latitude": "Latitud",
  "ccmessaging.chat.multimedia.latitudeValidateFailed": "La parte entera de latitud es -90~90, y la parte decimal es de 0 a 6 dígitos!",
  "ccmessaging.chat.multimedia.locationManagement": "Biblioteca de direcciones",
  "ccmessaging.chat.multimedia.longitude": "Longitud",
  "ccmessaging.chat.multimedia.longitudeValidateFailed": "La parte entera de longitud es -180~180, y la parte decimal es de 0 a 6 dígitos!",
  "ccmessaging.chat.multimedia.mediaFileType": "Medios de comunicación FileType",
  "ccmessaging.chat.multimedia.modifyCategory": "Modificar categoría",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfo": "¡Modifica con éxito los datos multimedia!",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfoError": "Error al modificar los datos multimedia!",
  "ccmessaging.chat.multimedia.modifyMultiMediaType": "¡Modifica con éxito la categoría multimedia!",
  "ccmessaging.chat.multimedia.modifyMultiMediaTypeError": "Error al modificar la categoría de biblioteca multimedia!",
  "ccmessaging.chat.multimedia.modifyRichText": "Edición de texto enriquecido",
  "ccmessaging.chat.multimedia.multiMediaInfoInputEmpty": "¡Los datos de entrada multimedia están vacíos!",
  "ccmessaging.chat.multimedia.multiMediaInfoInputInvalid": "¡Los datos de entrada multimedia no son válidos!",
  "ccmessaging.chat.multimedia.multiMediaManagement": "Configuración de la biblioteca multimedia",
  "ccmessaging.chat.multimedia.multiMediaNameExist": "¡El nombre de los datos multimedia ya existe en esta categoría!",
  "ccmessaging.chat.multimedia.multiMediaTypeIdNotExist": "La categoría de biblioteca multimedia seleccionada no existe, ¡seleccione la categoría de biblioteca multimedia!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputEmpty": "¡Los datos de entrada de categoría multimedia están vacíos!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputInvalid": "¡Los datos de entrada de categoría multimedia no son válidos!",
  "ccmessaging.chat.multimedia.multiMediaTypeNameExist": "¡El nombre de la categoría multimedia ya existe!",
  "ccmessaging.chat.multimedia.name": "Nombre",
  "ccmessaging.chat.multimedia.nameContainsSpecialCharacters": "El nombre contiene caracteres especiales.",
  "ccmessaging.chat.multimedia.nameSuperLong": "La longitud del nombre excede el máximo.",
  "ccmessaging.chat.multimedia.operating": "En funcionamiento",
  "ccmessaging.chat.multimedia.play": "Reproducir",
  "ccmessaging.chat.multimedia.preview": "Vista previa",
  "ccmessaging.chat.multimedia.richTextContentEmpty": "El contenido de texto enriquecido está vacío.",
  "ccmessaging.chat.multimedia.richTextContentLongLimit": "El contenido de texto enriquecido puede contener un máximo de 5000 caracteres.",
  "ccmessaging.chat.multimedia.richTextContentSuperLong": "El texto enriquecido puede contener un máximo de 5000 caracteres.",
  "ccmessaging.chat.multimedia.richTextManagement": "Texto enriquecido",
  "ccmessaging.chat.multimedia.richTextTypeEmpty": "La categoría de texto enriquecido no existe.",
  "ccmessaging.chat.multimedia.categorizeEmpty": "Seleccione o cree una categoría primero.",
  "ccmessaging.chat.multimedia.saveMultiMediaInfo": "¡Los datos multimedia guardados con éxito!",
  "ccmessaging.chat.multimedia.saveMultiMediaInfoError": "Error al guardar los datos multimedia!",
  "ccmessaging.chat.multimedia.saveMultiMediaType": "Categoría multimedia guardada con éxito!",
  "ccmessaging.chat.multimedia.saveMultiMediaTypeError": "Error al guardar la categoría de biblioteca multimedia!",
  "ccmessaging.chat.multimedia.selectFile": "Seleccionar archivo",
  "ccmessaging.chat.multimedia.selectImage": "Seleccionar imagen",
  "ccmessaging.chat.multimedia.upload": "Cargar",
  "ccmessaging.chat.multimedia.uploadTime": "Tiempo de carga",
  "ccmessaging.chat.multimedia.videoManagement": "Videoteca",
  "ccmessaging.chat.multimedia.videoUploadTipFormat": "El archivo de vídeo cargado actualmente solo es compatible con el formato mp4, y el tamaño del archivo no debe exceder {n}M.",
  "ccmessaging.chat.multimedia.viewRichText": "Ver texto enriquecido",
  "ccmessaging.chat.multimedia.whatsAppTemplate": "Plantilla de WhatsApp",
  "ccmessaging.chat.multimediaasccociation.approvalstatus": "Estado de revisión",
  "ccmessaging.chat.multimediaasccociation.checkmediainfofailed": "¡Los datos multimedia no han sido liberados al canal 5G asociado o no han sido aprobados!",
  "ccmessaging.chat.multimediaasccociation.deleteassociation": "Eliminar resultado de liberación",
  "ccmessaging.chat.multimediaasccociation.deleteassociationinfo": "¿Está seguro de que desea eliminar el resultado de la liberación de los datos multimedia y el canal 5G asociado?",
  "ccmessaging.chat.multimediaasccociation.deleteError": "Error al eliminar el resultado de la liberación!",
  "ccmessaging.chat.multimediaasccociation.deleteSuccess": "¡El resultado de la liberación se ha eliminado correctamente!",
  "ccmessaging.chat.multimediaasccociation.examinationfailed": "Error",
  "ccmessaging.chat.multimediaasccociation.examinationpassed": "Aprobado",
  "ccmessaging.chat.multimediaasccociation.expirationtime": "Caducado en",
  "ccmessaging.chat.multimediaasccociation.isrelease": "¿Está seguro de que desea liberar los datos multimedia al canal 5G?",
  "ccmessaging.chat.multimediaasccociation.refresh": "Refrescar",
  "ccmessaging.chat.multimediaasccociation.refreshfailed": "Error al actualizar los datos!",
  "ccmessaging.chat.multimediaasccociation.refreshsuccess": "¡Los datos se actualizan correctamente!",
  "ccmessaging.chat.multimediaasccociation.release": "Lanzamiento",
  "ccmessaging.chat.multimediaasccociation.releaseerror": "Error al liberar los datos multimedia!",
  "ccmessaging.chat.multimediaasccociation.releaseresult": "Resultado de la liberación",
  "ccmessaging.chat.multimediaasccociation.releasesuccess": "¡Los datos multimedia se liberan con éxito!",
  "ccmessaging.chat.multimediaasccociation.releasetitle": "Lanzamiento de datos multimedia",
  "ccmessaging.chat.multimediaasccociation.unpublishconfigempty": "El canal 5G en el que se van a liberar los datos multimedia no está configurado.",
  "ccmessaging.chat.multimediaasccociation.unreviewed": "No revisado",
  "ccmessaging.chat.offlineCall.channelType": "Tipo de canal",
  "ccmessaging.chat.offlineCall.select.noChannel": "El canal no existe.",
  "ccmessaging.chat.offlineCall.userId": "ID de usuario",
  "ccmessaging.chat.offlineSwitch.closed": "Error al enviar porque la función de mensaje sin conexión está deshabilitada para el canal.",
  "ccmessaging.chat.phrase.add": "Añadir",
  "ccmessaging.chat.phrase.addPhraseError": "Error al crear frase común",
  "ccmessaging.chat.phrase.addPhraseSuccess": "Crear frase común con éxito",
  "ccmessaging.chat.phrase.addType": "Agregar una categoría",
  "ccmessaging.chat.phrase.AllCount": "Total de palabras",
  "ccmessaging.chat.phrase.cancel": "Cancelar",
  "ccmessaging.chat.phrase.currentCount": "Número actual de palabras",
  "ccmessaging.chat.phrase.delete": "Borrar",
  "ccmessaging.chat.phrase.deleteType": "Eliminar tipo",
  "ccmessaging.chat.phrase.delPhraseError": "Error al eliminar la frase común",
  "ccmessaging.chat.phrase.delPhraseSucess": "Eliminar la frase común correctamente",
  "ccmessaging.chat.phrase.delPhraseTypeError": "Error al eliminar el tipo de frase común",
  "ccmessaging.chat.phrase.delPhraseTypeSuccess": "Eliminar el tipo de frase común correctamente",
  "ccmessaging.chat.phrase.edit": "Editar",
  "ccmessaging.chat.phrase.editType": "Editar tipo",
  "ccmessaging.chat.phrase.enter_keyword": "Ingrese una palabra clave.",
  "ccmessaging.chat.phrase.enterPhrase": "Por favor, introduzca una frase común",
  "ccmessaging.chat.phrase.enterPhraseType": "Introduzca un tipo de frase común",
  "ccmessaging.chat.phrase.error": "Error",
  "ccmessaging.chat.phrase.isDeletePhrase": "¿Está seguro de que desea eliminar esta frase",
  "ccmessaging.chat.phrase.isDeletePhraseType": "¿Está seguro de que desea eliminar el tipo de frase común y sus frases comunes?",
  "ccmessaging.chat.phrase.isSavePhrase": "¿Está seguro de que desea guardar la frase común?",
  "ccmessaging.chat.phrase.isSavePhraseType": "¿Está seguro de que desea guardar el tipo de frase común?",
  "ccmessaging.chat.phrase.phrase": "Frases",
  "ccmessaging.chat.phrase.phraseList": "Lista de frases",
  "ccmessaging.chat.phrase.phraseRepeat": "Nombre común duplicado",
  "ccmessaging.chat.phrase.phraseType": "Tipo de Pharas",
  "ccmessaging.chat.phrase.phraseTypeEmpty": "El tipo de frase común que se va a eliminar no existe. Seleccione un tipo de frase común.",
  "ccmessaging.chat.phrase.phraseTypeRepeat": "La categoría de frase común se duplica",
  "ccmessaging.chat.phrase.phraseTypeLimit": "El número de tipos de frases comunes alcanza el límite superior 100.",
  "ccmessaging.chat.phrase.prompt": "Iniciar sesión",
  "ccmessaging.chat.phrase.save": "Guardar",
  "ccmessaging.chat.phrase.saveError": "Error al guardar",
  "ccmessaging.chat.phrase.saveType": "Guardar tipo",
  "ccmessaging.chat.phrase.saveTypeSuccess": "Guardar correctamente la categoría de idioma común",
  "ccmessaging.chat.phrase.shortcut": "Teclas de acceso directo",
  "ccmessaging.chat.phrase.success": "Éxito",
  "ccmessaging.chat.phrase.updatePhraseTypeError": "Error al actualizar el tipo de frase común.",
  "ccmessaging.chat.phrase.updatePhraseTypeSucess": "Se ha logrado actualizar el tipo de frase común.",
  "ccmessaging.chat.phrase.updateError": "Error de actualización",
  "ccmessaging.chat.phrase.updatePhraseError": "Error al actualizar el lenguaje común",
  "ccmessaging.chat.phrase.updatePhraseSucess": "Actualizar el lenguaje común correctamente",
  "ccmessaging.chat.phrase.updateSuccess": "Actualizaciones exitosas",
  "ccmessaging.chat.portraitchange.customize": "Personalizado",
  "ccmessaging.chat.portraitchange.systemAvatar": "Avatar del sistema",
  "ccmessaging.chat.querymessage": "Consulta de Mensajes Históricos Multimedia",
  "ccmessaging.chat.refresh.fail": "Error al actualizar",
  "ccmessaging.chat.repeatClick": "?No haga clic repetidamente!",
  "ccmessaging.chat.richText.add": "Crear",
  "ccmessaging.chat.richText.cancel": "Cancelar",
  "ccmessaging.chat.richText.idInput": "Ingrese un ID.",
  "ccmessaging.chat.richText.nameInput": "Escriba un nombre.",
  "ccmessaging.chat.richText.openInNewWindow": "Abrir en una ventana nueva",
  "ccmessaging.chat.richText.save": "Guardar",
  "ccmessaging.chat.selectchannel.vaildate1": "El código de acceso al canal debe ser único.",
  "ccmessaging.chat.sessiontransfer.tips": "Solo los agentes inactivos y los agentes cuyas llamadas simultáneas no alcanzan el límite superior pueden ser transferidos.",
  "ccmessaging.chat.silentagent.aceesscode.empty": "¡El código de acceso no puede ser nulo!",
  "ccmessaging.chat.silentagent.config.accesscode.same": "La configuración del agente silencioso del código de configuración de ruta de audio IVR ya existe en la concesión actual.",
  "ccmessaging.chat.silentagent.config.del.error": "Error al eliminar la configuración del agente de voz silenciosa.",
  "ccmessaging.chat.silentagent.config.del.success": "Se ha eliminado correctamente la configuración del agente de voz silenciosa.",
  "ccmessaging.chat.silentagent.config.willdel": "¿Está seguro de que desea eliminar la configuración del agente de voz silenciosa seleccionada?",
  "ccmessaging.chat.silentagent.DeleteFail": "Error al eliminar la configuración del agente silencioso.",
  "ccmessaging.chat.silentagent.DeleteSuccess": "Se ha eliminado correctamente la configuración del agente silencioso.",
  "ccmessaging.chat.silentagent.sendContent.noOIAPNode": "¡El agente silencioso no puede obtener información del nodo actual!",
  "ccmessaging.chat.silentagent.sendContent.supervise": "Agente silencioso no puede enviar el mensaje int el modo de trabajo de supervisión!",
  "ccmessaging.chat.silentagent.silent.skill.empty": "¡La cola de habilidades del agente silencioso no puede estar vacía",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.fail": "El modo de trabajo de cambio de agente silencioso falla",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.prompt": "Sugerencias",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.success": "¡Éxito del workMode de cambio de agente silencioso!",
  "ccmessaging.chat.silentAgent.workMode.common": "Frecuentes",
  "ccmessaging.chat.silentAgent.workMode.insert": "inserción",
  "ccmessaging.chat.silentAgent.workMode.supervise": "supervisar",
  "ccmessaging.chat.silentAgent.workMode.transfer": "transferencia",
  "ccmessaging.chat.single.media.resource.invalid": "Tarjetas que hacen referencia a recursos multimedia no válidos.",
  "ccmessaging.chat.sms.channel.gateway": "Puerta de enlace SMS",
  "ccmessaging.chat.social.media.enterprise.default.username": "Usuario anónimo",
  "ccmessaging.chat.social.media.enterprise.manage": "Procesamiento de mensajes de cuenta de empresa",
  "ccmessaging.chat.social.media.enterprise.post.add.failed": "Error al agregar",
  "ccmessaging.chat.social.media.enterprise.post.add.success": "Se ha añadido correctamente",
  "ccmessaging.chat.social.media.enterprise.post.all.reply": "Responder a todos",
  "ccmessaging.chat.social.media.enterprise.post.batch.reply": "Respuesta por lotes",
  "ccmessaging.chat.social.media.enterprise.post.cancel": "Cancelación",
  "ccmessaging.chat.social.media.enterprise.post.comment": "Comentarios",
  "ccmessaging.chat.social.media.enterprise.post.comment.tips": "Añadir un comentario...",
  "ccmessaging.chat.social.media.enterprise.post.reply": "Responder",
  "ccmessaging.chat.social.media.enterprise.post.reply.cancel": "Cancelación",
  "ccmessaging.chat.social.media.enterprise.post.reply.tips": "Añadir respuesta......",
  "ccmessaging.chat.social.media.enterprise.post.title": "Nombre de la publicación",
  "ccmessaging.chat.social.media.enterprise.post.topcomment.cancel": "Cancelar comentario",
  "ccmessaging.chat.social.media.enterprise.tips.close": "plegado",
  "ccmessaging.chat.social.media.enterprise.tips.imgnum": "Se puede añadir un máximo de 1 imagen",
  "ccmessaging.chat.social.media.enterprise.tips.open": "Expandir",
  "ccmessaging.chat.social.media.multimedia.manage": "Procesamiento de mensajes multimedia",
  "ccmessaging.chat.speciallist.channels": "Canales restringidos",
  "ccmessaging.chat.speciallist.channelsType": "Tipo de canal restringido",
  "ccmessaging.chat.speciallist.choosechannel": "Seleccionar canal",
  "ccmessaging.chat.speciallist.confirmdelete": "¿Está seguro de borrar?",
  "ccmessaging.chat.speciallist.delete": "obsoleto",
  "ccmessaging.chat.speciallist.deletelevel": "(obsoleto)",
  "ccmessaging.chat.speciallist.emailchannels": "Correo Electrónico",
  "ccmessaging.chat.speciallist.haverestrictall": "Todos los canales de este tipo de canal están restringidos. No es necesario restringir este canal.",
  "ccmessaging.chat.speciallist.haverestrictchannel": "Este canal está restringido. No hay necesidad de restringirlo de nuevo.",
  "ccmessaging.chat.speciallist.level": "Nivel de lista especial",
  "ccmessaging.chat.speciallist.leveName": "Tipo de lista especial Nombre",
  "ccmessaging.chat.speciallist.msgchoosechannel": "Seleccione un tipo de canal primero.",
  "ccmessaging.chat.speciallist.remark": "Descripcion",
  "ccmessaging.chat.speciallist.repeatlevel": "El nivel de lista especial ya existe. No lo vuelvas a crear.",
  "ccmessaging.chat.speciallist.restrictHours": "Duración restringida",
  "ccmessaging.chat.speciallist.restrictUnit": "hora",
  "ccmessaging.chat.speciallist.restrictMode": "Modo de restricción",
  "ccmessaging.chat.speciallist.restrictStyle": "Modo de aplicación",
  "ccmessaging.chat.speciallist.selectchannel": "Canal designado",
  "ccmessaging.chat.speciallist.smschannels": "SMS",
  "ccmessaging.chat.speciallist.unuse": "No disponible",
  "ccmessaging.chat.speciallist.unuselevel": "(No disponible)",
  "ccmessaging.chat.speciallist.use": "Disponible",
  "ccmessaging.chat.successaccess": "Acceso exitoso",
  "ccmessaging.chat.sumbitverification.Appld": "ID de la aplicación",
  "ccmessaging.chat.sumbitverification.AppSecret": "Llave",
  "ccmessaging.chat.sumbitverification.facebook.tips": "Ve a la página de desarrolladores de Facebook Messenger para configurar el sitio web y el código de verificación.",
  "ccmessaging.chat.sumbitverification.fiveG.tips": "Vaya a la página Configuración de la plataforma de mensajes 5G para configurar la dirección del sitio web.",
  "ccmessaging.chat.sumbitverification.line.tips": "Configure la URL de Webhook en el Administrador de cuentas oficial de la línea.",
  "ccmessaging.chat.sumbitverification.tips": "Vaya a la información de configuración de la interfaz de plataforma pública de WeChat para la URL de configuración y el código de verificación",
  "ccmessaging.chat.sumbitverification.tips.qr": "En este modo de autorización, no se requiere más configuración. Vaya al siguiente paso.",
  "ccmessaging.chat.sumbitverification.Url": "Dirección URL",
  "ccmessaging.chat.sumbitverification.Url.infobipMessAgeUrl": "url utilizada para enviar un mensaje al agente",
  "ccmessaging.chat.sumbitverification.Url.infobipReportUrl": "url utilizada para enviar el informe al agente",
  "ccmessaging.chat.sumbitverification.VerityCode": "Código de verificación",
  "ccmessaging.chat.sumbitverification.whatsApp.fail": "Generar fallas",
  "ccmessaging.chat.sumbitverification.whatsApp.prompt": "Sugerencias",
  "ccmessaging.chat.sumbitverification.whatsApp.success": "Generar éxito",
  "ccmessaging.chat.sumbitverification.whatsApp.tips": "URL utilizada por el proveedor de servicios WhatsApp para procesar mensajes",
  "ccmessaging.chat.system.error": "El sistema está ocupado. Por favor, inténtelo de nuevo más tarde.",
  "ccmessaging.chat.template.buttonSend": "Enviar",
  "ccmessaging.chat.template.butttonName": "Elegir",
  "ccmessaging.chat.template.card": "Mensaje de plantilla de WhatsApp",
  "ccmessaging.chat.template.Description": "Descripcion",
  "ccmessaging.chat.template.languageCode": "Idioma",
  "ccmessaging.chat.template.name": "Nombre del mensaje de plantilla",
  "ccmessaging.chat.template.namePlaceholder": "Escriba un nombre de mensaje.",
  "ccmessaging.chat.template.table.button": "Botón",
  "ccmessaging.chat.template.table.buttonPlaceholder": "Respuesta rápida del botón.",
  "ccmessaging.chat.template.table.no": "No",
  "ccmessaging.chat.template.table.parameterContent": "Contenido del parámetro",
  "ccmessaging.chat.template.table.parameterDescription": "Parámetro Descripción",
  "ccmessaging.chat.template.table.parameterPlaceholder": "Introduzca un parámetro.",
  "ccmessaging.chat.template.whatsAppChannel": "Canal",
  "ccmessaging.chat.template.whatsAppTemplateName": "Nombre de la plantilla",
  "ccmessaging.chat.templateMsg.chooseMultimedia": "Seleccione un recurso multimedia.",
  "ccmessaging.chat.templateMsg.sendTemplateMsg": "Enviar",
  "ccmessaging.chat.templateMsg.sendTemplateMsgTips": "Enviar mensaje de plantilla",
  "ccmessaging.chat.tinymce.button.clear": "Claro",
  "ccmessaging.chat.tinyMCE.FangSong": "FangSong",
  "ccmessaging.chat.tinyMCE.SimHei": "SimHei",
  "ccmessaging.chat.tinyMCE.simsun": "simsun",
  "ccmessaging.chat.tinyMCE.yaHei": "YaHei de Microsoft",
  "ccmessaging.chat.uploadLocalFile": "Cargar archivo local",
  "ccmessaging.chat.warning.sharescreen": "Preste atención a la privacidad personal al compartir el escritorio",
  "ccmessaging.chat.web.agent.assist": "Asistencia de agente",
  "ccmessaging.chat.web.agent.prohibitedmess": "¡Idioma prohibido!Por favor, preste atención a las palabras.",
  "ccmessaging.chat.web.agent.wrogmess": "¡La redacción es inapropiada!Por favor, tenga en cuenta el uso de palabras apropiadas.",
  "ccmessaging.chat.web.channel.called.config": "Configuración de la parte llamada de CTD",
  "ccmessaging.chat.web.channel.clickivr": "Haga clic para obtener el código de acceso IVR.",
  "ccmessaging.chat.webCard": "Tarjeta Web",
  "ccmessaging.chat.webCard.copyUrl": "Copiar enlace",
  "ccmessaging.chat.webCard.copyUrl.success": "El enlace se ha copiado correctamente",
  "ccmessaging.chat.webcard.pop.confirm.outside": "Si saltar a enlaces de terceros?",
  "ccmessaging.chat.webcard.pop.confirm.prefix": "El mensaje será redirigido a la dirección externa, el dominio del sitio web es",
  "ccmessaging.chat.webcard.pop.confirm.suffix": "¿Estás seguro de que quieres continuar?",
  "ccmessaging.chat.webcard.pop.error.prefix": "El dominio del sitio web",
  "ccmessaging.chat.webcard.pop.error.suffix": "no está en la lista blanca de direcciones. Póngase en contacto con el personal de operaciones para agregarlo a la lista blanca.",
  "ccmessaging.chat.webclient.chatapi.evaluation": "Por favor, califique este servicio, gracias!",
  "ccmessaging.chat.webclient.chatapi.failTransToAgent": "Error de transformación en agente, inténtelo de nuevo o póngase en contacto con el administrador",
  "ccmessaging.chat.webclient.chathtml.customer": "Clientes",
  "ccmessaging.chat.webclient.chatjs.inpuStatus": "La otra parte está escribiendo.",
  "ccmessaging.chat.whatsapp.body": "Área de texto",
  "ccmessaging.chat.whatsapp.buttons": "Área de botones",
  "ccmessaging.chat.whatsapp.footer": "Área de pie de página",
  "ccmessaging.chat.whatsapp.header": "Área de encabezado",
  "ccmessaging.chat.whatsapp.languageAF": "Holandés, Sudáfrica",
  "ccmessaging.chat.whatsapp.languageAR": "Español",
  "ccmessaging.chat.whatsapp.languageAZ": "Azerbaiyano",
  "ccmessaging.chat.whatsapp.languageBG": "Búlgaro",
  "ccmessaging.chat.whatsapp.languageBN": "Bengalí",
  "ccmessaging.chat.whatsapp.languageCA": "Catalán",
  "ccmessaging.chat.whatsapp.languageCS": "Español",
  "ccmessaging.chat.whatsapp.languageDA": "Danés",
  "ccmessaging.chat.whatsapp.languageDE": "Español",
  "ccmessaging.chat.whatsapp.languageEL": "Griego",
  "ccmessaging.chat.whatsapp.languageEN": "Español",
  "ccmessaging.chat.whatsapp.languageENGB": "Inglés (Reino Unido)",
  "ccmessaging.chat.whatsapp.languageES": "Español",
  "ccmessaging.chat.whatsapp.languageESAR": "Español (Argentina)",
  "ccmessaging.chat.whatsapp.languageESES": "Español (España)",
  "ccmessaging.chat.whatsapp.languageESMX": "Español (México)",
  "ccmessaging.chat.whatsapp.languageET": "Esloveno",
  "ccmessaging.chat.whatsapp.languageFA": "Persa",
  "ccmessaging.chat.whatsapp.languageFI": "Español",
  "ccmessaging.chat.whatsapp.languageFIL": "Español",
  "ccmessaging.chat.whatsapp.languageFR": "Francés",
  "ccmessaging.chat.whatsapp.languageGA": "Irlandés",
  "ccmessaging.chat.whatsapp.languageGU": "Gujarat",
  "ccmessaging.chat.whatsapp.languageHA": "Hausa",
  "ccmessaging.chat.whatsapp.languageHE": "Hebreo",
  "ccmessaging.chat.whatsapp.languageHI": "Hindú",
  "ccmessaging.chat.whatsapp.languageHR": "Croata",
  "ccmessaging.chat.whatsapp.languageHU": "Húngaro",
  "ccmessaging.chat.whatsapp.languageID": "Indonesio",
  "ccmessaging.chat.whatsapp.languageIT": "Español",
  "ccmessaging.chat.whatsapp.languageJA": "Japonés",
  "ccmessaging.chat.whatsapp.languageKK": "kazajo",
  "ccmessaging.chat.whatsapp.languageKN": "Kanadawen",
  "ccmessaging.chat.whatsapp.languageKO": "Español",
  "ccmessaging.chat.whatsapp.languageLO": "Laosiano",
  "ccmessaging.chat.whatsapp.languageLT": "Lituano",
  "ccmessaging.chat.whatsapp.languageLV": "Letón",
  "ccmessaging.chat.whatsapp.languageMK": "macedonio septentrional",
  "ccmessaging.chat.whatsapp.languageML": "Malayalam",
  "ccmessaging.chat.whatsapp.languageMR": "Marathi",
  "ccmessaging.chat.whatsapp.languageMS": "Español",
  "ccmessaging.chat.whatsapp.languageNB": "Noruego",
  "ccmessaging.chat.whatsapp.languageNL": "Holandés",
  "ccmessaging.chat.whatsapp.languagePA": "Punjabi",
  "ccmessaging.chat.whatsapp.languagePL": "Polaco",
  "ccmessaging.chat.whatsapp.languagePTBR": "Portugués (Brasil)",
  "ccmessaging.chat.whatsapp.languagePTPT": "Portugués (Portugal)",
  "ccmessaging.chat.whatsapp.languageRO": "Rumano",
  "ccmessaging.chat.whatsapp.languageRU": "Ruso",
  "ccmessaging.chat.whatsapp.languageSK": "Eslovaco",
  "ccmessaging.chat.whatsapp.languageSL": "Esloveno",
  "ccmessaging.chat.whatsapp.languageSQ": "Albanés",
  "ccmessaging.chat.whatsapp.languageSR": "Serbio",
  "ccmessaging.chat.whatsapp.languageSV": "Sueco",
  "ccmessaging.chat.whatsapp.languageSW": "Suajili",
  "ccmessaging.chat.whatsapp.languageTA": "Tamil",
  "ccmessaging.chat.whatsapp.languageTE": "Telugu",
  "ccmessaging.chat.whatsapp.languageTH": "Tailandesas",
  "ccmessaging.chat.whatsapp.languageTR": "Turco",
  "ccmessaging.chat.whatsapp.languageUK": "Ucraniano",
  "ccmessaging.chat.whatsapp.languageUR": "Urdu",
  "ccmessaging.chat.whatsapp.languageUS": "Inglés (Estados Unidos)",
  "ccmessaging.chat.whatsapp.languageUZ": "uzbeko",
  "ccmessaging.chat.whatsapp.languageVI": "Español",
  "ccmessaging.chat.whatsapp.languageZHCN": "Chino simplificado",
  "ccmessaging.chat.whatsapp.languageZHHK": "China chino tradicional (Hong Kong)",
  "ccmessaging.chat.whatsapp.languageZHTW": "China chino tradicional (Taiwán)",
  "ccmessaging.chat.whatsapp.languageZU": "Zulú",
  "ccmessaging.chat.whatsapp.only.send.template": "Cundo um novo usurio do WhatsApp ou o intervalo de contato exceder 24 horas, somente mensagens modelo podem ser enviada.",
  "ccmessaging.chat.whatsapp.selectTemplateOrgPlaceHolder": "Seleccione una organización",
  "ccmessaging.chat.whatsapp.template.dailyLimit": "Número de plantillas disponibles por día",
  "ccmessaging.chat.whatsapp.template.dailyLimitDescription": "Introduzca el número de veces que la plantilla está disponible por día",
  "ccmessaging.chat.whatsapp.template.no": "No",
  "ccmessaging.chat.whatsapp.template.updateTime": "Actualizado",
  "ccmessaging.chat.whatsapp.templateAddButton": "Crear",
  "ccmessaging.chat.whatsapp.templateBodyParamDesc": "Parámetro Descripción",
  "ccmessaging.chat.whatsapp.templateBodyParamDescPlaceholder": "Introduzca la descripción del parámetro.",
  "ccmessaging.chat.whatsapp.templateBodyText": "Contenido de texto del cuerpo",
  "ccmessaging.chat.whatsapp.templateBodyTextPlaceholder": "Introduzca el contenido del texto principal, el contenido de {'{{}}'} se puede editar en el cuerpo de la plantilla.",
  "ccmessaging.chat.whatsapp.templateButton.clicktoAction": "Acción",
  "ccmessaging.chat.whatsapp.templateButton.none": "N/A",
  "ccmessaging.chat.whatsapp.templateButton.qickReply": "Respuesta rápida",
  "ccmessaging.chat.whatsapp.templateButtonContent": "Contenido del botón de plantilla",
  "ccmessaging.chat.whatsapp.templateButtonContentPlaceholder": "Ingrese el contenido del botón.",
  "ccmessaging.chat.whatsapp.templateButtonName": "Nombre del botón de plantilla",
  "ccmessaging.chat.whatsapp.templateButtonNamePlaceholder": "Introduzca un nombre de botón.",
  "ccmessaging.chat.whatsapp.templateButtonType": "Tipo de botón de plantilla",
  "ccmessaging.chat.whatsapp.templateChannel": "Canal de plantilla",
  "ccmessaging.chat.whatsapp.templateContent": "Contenido de la plantilla",
  "ccmessaging.chat.whatsapp.templateCreate": "Crear una plantilla de WhatsApp",
  "ccmessaging.chat.whatsapp.templateDescription": "Descripción de la plantilla",
  "ccmessaging.chat.whatsapp.templateDescriptionPlaceholder": "Introduzca la descripción de la plantilla.",
  "ccmessaging.chat.whatsapp.templateFooterText": "Contenido de texto de pie de página",
  "ccmessaging.chat.whatsapp.templateFooterTextPlaceholder": "Ingrese el contenido de texto del pie de página.",
  "ccmessaging.chat.whatsapp.templateHeaderText": "Contenido de texto de encabezado",
  "ccmessaging.chat.whatsapp.templateHeaderTextPlaceholder": "Introduzca el contenido del texto del encabezado.",
  "ccmessaging.chat.whatsapp.templateHeaderType": "Encabezado",
  "ccmessaging.chat.whatsapp.templateHeaderType.image": "Imágenes",
  "ccmessaging.chat.whatsapp.templateHeaderType.locate": "Localización",
  "ccmessaging.chat.whatsapp.templateHeaderType.none": "N/A",
  "ccmessaging.chat.whatsapp.templateHeaderType.text": "Texto",
  "ccmessaging.chat.whatsapp.templateHeaderType.video": "Vídeos",
  "ccmessaging.chat.whatsapp.templateLanguage": "Idioma",
  "ccmessaging.chat.whatsapp.templateName": "Nombre de la plantilla",
  "ccmessaging.chat.whatsapp.templateNamePlaceholder": "Escriba un nombre de plantilla.",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsg": "Seleccione el mensaje de plantilla de WhatsApp correspondiente",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgId": "ID de mensaje de plantilla de WhatsApp",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgName": "Nombre del mensaje de la plantilla WhatsApp",
  "ccmessaging.chat.workbench.body.iolp": "Orientación de servicio",
  "ccmessaging.chat.workbench.body.transfer": "Llamada de transferencia",
  "ccmessaging.chat.workbench.transfer.agent": "Agent",
  "ccmessaging.chat.workbench.transfer.noagents": "No existe ningún agente en línea en la cola de habilidades actual.",
  "ccmessaging.chat.workbench.transfer.skill": "Cola de habilidades",
  "ccmessaging.chat.workbench.transfer.skilllogin": "Agente disponible",
  "ccmessaging.chat.workbench.transfer.skillname": "Nombre de la cola de habilidades",
  "ccmessaging.chat.workbench.transfer.skillqueue": "Espera en cola",
  "ccmessaging.chat.workbench.transfer.skillsearch": "Introduzca un nombre de cola de habilidades",
  "ccmessaging.chatbot.button.avatarImport": "Cargar",
  "ccmessaging.chatbot.button.avatarImportLimit": "El tamaño del archivo que se va a cargar no puede exceder los 6 MB y el archivo puede estar en formato JPG o PNG.",
  "ccmessaging.chatbot.button.cancel": "Cancelar",
  "ccmessaging.chatbot.button.edit": "Editar",
  "ccmessaging.chatbot.label.avatar": "Avatar",
  "ccmessaging.chatbot.label.avatarImport": "Subir avatar",
  "ccmessaging.chatbot.label.avatarImportFailed": "No se puede cargar la imagen!",
  "ccmessaging.chatbot.label.avatarImportSuccess": "Se ha subido la imagen con éxito!",
  "ccmessaging.chatbot.label.avatarModifing": "Cambiar avatar",
  "ccmessaging.chatbot.label.basicInformation": "Información básica",
  "ccmessaging.chatbot.label.commonLanguageSetting": "Configuración de frase común",
  "ccmessaging.chatbot.label.defaultReply": "Respuesta predeterminada",
  "ccmessaging.chatbot.label.gender": "Género",
  "ccmessaging.chatbot.label.name": "Apellido",
  "ccmessaging.chatbot.label.save": "Guardar",
  "ccmessaging.chatbot.label.timeoutReply": "Tiempo de espera de respuesta",
  "ccmessaging.chatbot.label.turnManualPrompt": "Solicitar la transferencia al agente",
  "ccmessaging.chatbot.label.welcomeMessage": "Saludos",
  "ccmessaging.chatbot.message.error": "Fallo",
  "ccmessaging.chatbot.message.modifyFailure": "Error en la modificación.",
  "ccmessaging.chatbot.message.modifySuccess": "Modificado con éxito.",
  "ccmessaging.chatbot.message.specialStrValidateFailed": "El contenido introducido no puede contener los siguientes characters: & < > /.",
  "ccmessaging.chatbot.message.success": "Éxito",
  "ccmessaging.chatofflineCall.channelName": "Nombre del canal",
  "ccmessaging.chatofflineCall.invalidUserIdTip": "El ID de abonado no es válido.",
  "ccmessaging.chatofflineCall.noSelectChanelTip": "No se ha seleccionado ningún canal.",
  "ccmessaging.chatofflineCall.noSupportText": "¡La llamada es una llamada de extraños!",
  "ccmessaging.chatofflineCall.strangeOfflineTip": "No se encuentra ninguna sesión histórica.",
  "ccmessaging.cobrowsesite.protocol.check": "El uso de protocolos inseguros conlleva riesgos de seguridad.",
  "ccmessaging.cobrowsesite.mediaurl.format.error": "El formato del sitio cobro es incorrecto.",
  "ccmessaging.email.attachment.fileType.tip1": "Los archivos adjuntos de correo electrónico admiten los siguientes formatos de archivo:",
  "ccmessaging.email.attachment.fileType.tip2": ",",
  "ccmessaging.email.attachment.fileType.tip3": "El tamaño del archivo no puede exceder",
  "ccmessaging.email.attachment.fileType.tip4": "M.",
  "ccmessaging.emailcatalog.add": "Crear directorio",
  "ccmessaging.emailcatalog.canNotAdd": "Se permite un máximo de dos niveles de directorios.",
  "ccmessaging.emailcatalog.deleteTopCatalog": "No se puede eliminar el directorio predeterminado.",
  "ccmessaging.emailcatalog.duplicateName": "El nombre ya existe. Usa otro nombre.",
  "ccmessaging.emailcatalog.existEmail": "Los correos electrónicos existen en el directorio.",
  "ccmessaging.emailcatalog.existSubCatalog": "El directorio contiene subdirectorios.",
  "ccmessaging.emailcatalog.modify": "Modificar directorio",
  "ccmessaging.emailcatalog.modifyTopCatalog": "No se puede modificar el directorio predeterminado.",
  "ccmessaging.emailcatalog.moveCatalogFailed": "Error al mover un directorio.",
  "ccmessaging.emailcatalog.moveCatalogPartialSuccess": "Error al mover algunos directorios de correo electrónico.",
  "ccmessaging.emailcatalog.moveCatalogSuccess": "El directorio se ha movido correctamente.",
  "ccmessaging.emailcatalog.moveEmailQuantityExceedsMaxValue": "El número de mensajes movidos supera los 30 o la longitud del parámetro supera el máximo",
  "ccmessaging.emailcatalog.moveOtherCatalogFailed": "Los correos electrónicos de otros agentes no se pueden mover.",
  "ccmessaging.emailcatalog.catalogname": "Nombre del catÃ¡logo",
  "ccmessaging.emailcatalog.parentName": "Nombre del directorio principal",
  "ccmessaging.emailcatalog.pleaseSelectCatalog": "Seleccione primero un directorio.",
  "ccmessaging.emailcatalog.selectcatalog": "Seleccionar directorio",
  "ccmessaging.emailMessageManage.forward": "Registro de reenvío",
  "ccmessaging.flowControl.addFail": "Error al agregar la configuración de control de flujo.",
  "ccmessaging.flowControl.addSuccess": "La configuración de control de flujo se agrega correctamente.",
  "ccmessaging.flowControl.alarmInterval": "Intervalo de alarma",
  "ccmessaging.flowControl.alarmInterval.unit": "min",
  "ccmessaging.flowControl.alarmThreshold": "Umbral de alarma",
  "ccmessaging.flowControl.channelType": "Tipo de canal",
  "ccmessaging.flowControl.count.beyondMaxLimit": "El número de reglas de control de flujo en el espacio del inquilino excede el límite superior.",
  "ccmessaging.flowControl.createTime": "Creado en",
  "ccmessaging.flowControl.deleteFail": "Error al eliminar la configuración del control de flujo.",
  "ccmessaging.flowControl.deleteSelect": "¿Está seguro de que desea eliminar la regla seleccionada?",
  "ccmessaging.flowControl.deleteSuccess": "La configuración de control de flujo se elimina correctamente.",
  "ccmessaging.flowControl.description": "Descripcion",
  "ccmessaging.flowControl.enable": "Activar",
  "ccmessaging.flowControl.limitNum": "Máx. Mensajes",
  "ccmessaging.flowControl.name": "Nombre de control de flujo",
  "ccmessaging.flowControl.operate": "Operación",
  "ccmessaging.flowControl.periodType": "Rango de periodos",
  "ccmessaging.flowControl.periodType.day": "Todos los días",
  "ccmessaging.flowControl.periodType.month": "Mensual",
  "ccmessaging.flowControl.periodType.time": "Personalización",
  "ccmessaging.flowControl.periodType.week": "Semanalmente",
  "ccmessaging.flowControl.periodValue": "Día",
  "ccmessaging.flowControl.period.select": "Selección de periodo",
  "ccmessaging.flowControl.selectStop": "Deshabilite primero la configuración de control de flujo.",
  "ccmessaging.flowControl.selectRule": "Seleccione la configuración de control de flujo.",
  "ccmessaging.flowControl.selectStartTime": "Seleccione la hora de inicio.",
  "ccmessaging.flowcontrol.target.deleted": "Se ha eliminado el objeto de control de flujo. Eliminar la regla de control de flujo correspondiente.",
  "ccmessaging.flowControl.targetId": "Objeto de control de flujo",
  "ccmessaging.flowControl.tipText": "Mensaje de solicitud",
  "ccmessaging.flowControl.tipTextInterval": "Intervalo de solicitud",
  "ccmessaging.flowControl.updateFail": "Error al actualizar la configuración del control de flujo.",
  "ccmessaging.flowControl.updateSuccess": "La configuración de control de flujo se actualiza correctamente.",
  "ccmessaging.flowControl.usage": "Uso",
  "ccmessaging.flowControl.title.create": "Crear control de flujo",
  "ccmessaging.flowControl.title.modify": "Editar control de flujo de mensajes",
  "ccmessaging.flowControl.title.view": "Ver control de flujo",
  "ccmessaging.webCollaboration.ruleName": "Nombre de regla",
  "ccmessaging.webCollaboration.pageMatchType": "Tipo de coincidencia de página",
  "ccmessaging.webCollaboration.pageMatchConfiguration": "Configuración de coincidencia de página",
  "ccmessaging.webCollaboration.pageElementSelector": "Selector de elementos de página",
  "ccmessaging.webCollaboration.createTime": "Tiempo de creación",
  "ccmessaging.webCollaboration.operation": "Acciones",
  "cobrowse.sensitiveinfo.pagematchtype.commonmatch": "Coincidencia genérica",
  "cobrowse.sensitiveinfo.pagematchtype.urlmatch": "Coincidencia basada en la ruta de URL",
  "cobrowse.sensitiveinfo.pagematchtype.pageidmatch": "Coincidencia basada en ID de página",
  "ccmessaging.message.location.latitude": "Latitud:",
  "ccmessaging.message.location.longitude": "Longitud:",
  "ccmessaging.message.selectEmail.count": "Se puede seleccionar un máximo de 30 correos electrónicos a la vez.",
  "ccmessaging.message.selectEmail.null": "Selecciona un correo electrónico.",
  "ccmessaging.message.sendstatus.deliverable": "Enviado",
  "ccmessaging.message.sendstatus.pending": "Esperando",
  "ccmessaging.message.sendstatus.read": "Leer",
  "ccmessaging.message.sendstatus.undeliverable": "Error",
  "ccmessaging.message.sendstatus.unread": "No leído",
  "ccmessaging.message.timeerror.laterThanCurTime": "La hora de inicio no puede ser posterior a la hora actual.",
  "ccmessaging.messagecontroll.timeerror.beyondMaxLimit": "¡La hora de finalización no puede ser posterior a la hora de inicio 90 días!",
  "ccmessaging.multimedialibrary.cobrowsesite.card.target": "Explorar destino:",
  "ccmessaging.multimedialibrary.cobrowsesite.card.title": "El agente solicita una exploración colaborativa con el usuario.",
  "ccmessaging.multimedialibrary.tips": "Esta dirección se utiliza para que los agentes envíen a los usuarios para la colaboración de páginas web. Establezca este parámetro correctamente",
  "ccmessaging.operlogs.emailAttachment.download": "Descargar archivos adjuntos de correo electrónico",
  "ccmessaging.operlogs.multimedialibrary.create.richtext": "Agregar texto enriquecido",
  "ccmessaging.operlogs.multimedialibrary.modify.richtext": "Modificar texto enriquecido",
  "ccmessaging.operlogs.multimedialibrary.save": "Guardar configuración de datos de biblioteca multimedia",
  "ccmessaging.operlogs.multimedialibrary.save.audio": "Guarde la configuración de datos en la biblioteca multimedia y cargue los datos del archivo de tipo audio.",
  "ccmessaging.operlogs.multimedialibrary.save.emoticons": "Guarde la configuración de datos en la biblioteca multimedia y cargue los datos del archivo de tipo emoticon.",
  "ccmessaging.operlogs.multimedialibrary.save.image": "Guarde la configuración de datos en la biblioteca multimedia y cargue los datos del archivo de tipo imagen.",
  "ccmessaging.operlogs.multimedialibrary.save.video": "Guarde la configuración de datos en la biblioteca multimedia y cargue los datos del archivo de tipo de vídeo.",
  "ccmessaging.operlogs.preview.contract": "Vista previa del contrato, usuario:",
  "ccmessaging.operlogs.signature.submit": "Enviar contrato para firmar",
  "ccmessaging.operlogs.upload.contract": "Contrato de carga ",
  "ccmessaging.pop.org.window.noorg": "Organización sin atribución",
  "ccmessaging.pop.org.window.org": "Organización atribuible",
  "ccmessaging.ruleconfig.add": "Añadir",
  "ccmessaging.ruleconfig.all": "Todos los canales",
  "ccmessaging.ruleconfig.applicationChannel": "Canal de aplicación",
  "ccmessaging.ruleconfig.content": "Contenido",
  "ccmessaging.ruleconfig.contentNotNull": "El contenido no puede estar vacío.",
  "ccmessaging.ruleconfig.createdTime": "Tiempo de creación",
  "ccmessaging.ruleconfig.dateEnd": "Hora de finalización",
  "ccmessaging.ruleconfig.dateStart": "Hora de inicio",
  "ccmessaging.ruleconfig.deleteFail": "Error al eliminar la regla.",
  "ccmessaging.ruleconfig.deleteSelectRule": "¿Está seguro de que desea eliminar la regla seleccionada?",
  "ccmessaging.ruleconfig.deleteSuccess": "Regla eliminada correctamente.",
  "ccmessaging.ruleconfig.duplicateName": "El nombre de la regla es duplicado.",
  "ccmessaging.ruleconfig.effective": "Eficaz",
  "ccmessaging.ruleconfig.emailaddress.error": "Se prohíbe el envío de las siguientes direcciones:",
  "ccmessaging.ruleconfig.emailAddressExists": "La dirección de correo electrónico ya existe",
  "ccmessaging.ruleconfig.emailAdress": "Dirección de correo electrónico",
  "ccmessaging.ruleconfig.emailChannel": "Canal de correo electrónico",
  "ccmessaging.ruleconfig.ID": "Identificación",
  "ccmessaging.ruleconfig.invalidation": "Invalidación",
  "ccmessaging.ruleconfig.invalidEmailAddress": "Dirección de correo electrónico no válida",
  "ccmessaging.ruleconfig.invalidSMSNumber": "Número de teléfono móvil no válido.",
  "ccmessaging.ruleconfig.maxEmailAddressCount": "Se puede introducir un máximo de 10 direcciones de correo electrónico en el contenido",
  "ccmessaging.ruleconfig.maxSMSNumberCount": "Se puede introducir un máximo de 10 números móviles en el contenido",
  "ccmessaging.ruleconfig.maxValidityPeriod": "El período de validez se establece en el máximo.",
  "ccmessaging.ruleconfig.ruleContentKeyword": "Palabra clave de contenido de regla",
  "ccmessaging.ruleconfig.ruleDescribe": "Descripción de la regla",
  "ccmessaging.ruleconfig.ruleName": "Nombre de regla",
  "ccmessaging.ruleconfig.selectRule": "Seleccione una regla",
  "ccmessaging.ruleconfig.SMSChannel": "Canal de SMS",
  "ccmessaging.ruleconfig.SMSNumber": "Número de teléfono móvil",
  "ccmessaging.ruleconfig.SMSNumberExists": "El número de móvil ya existe",
  "ccmessaging.ruleconfig.SMStips1": `Puede introducir comodines en el número de móvil. "\\*" indica que se coinciden 0 o más caracteres. "\\?" Indica que se coinciden de 0 a 1 caracteres.`,
  "ccmessaging.ruleconfig.SMStips2": `Por ejemplo. "\\*9988" indica que todos los mensajes SMS enviados desde números móviles que terminan con "9988" son rechazados.`,
  "ccmessaging.ruleconfig.SMStips3": `Por ejemplo, "132\\*" indica que todos los mensajes SMS enviados desde números móviles que comienzan con "132" son rechazados.`,
  "ccmessaging.ruleconfig.SMStips4": `"\\*" bloqueará todos los mensajes SMS enviados por números móviles. Tenga cuidado al usar este comando.`,
  "ccmessaging.ruleconfig.timeerror.dateValidateFailed": "Seleccione una fecha posterior a la hora actual y anterior a 2038-1-19",
  "ccmessaging.ruleconfig.timeerror.empty": "* La hora de inicio y la hora de finalización no pueden estar vacías.",
  "ccmessaging.ruleconfig.timeerror.endTime": "* La hora de finalización no puede ser anterior a la hora actual.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime": "* La hora de inicio no puede ser posterior a la hora de finalización.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime2": "La hora de inicio no puede ser posterior a la hora de finalización.",
  "ccmessaging.ruleconfig.timeerror.startTime": "* La hora de inicio no puede ser anterior a la hora actual.",
  "ccmessaging.ruleconfig.tips1": `Puede introducir comodines en la dirección de correo electrónico. "\\*" indica que se coinciden 0 o más caracteres. "\\?" Indica que se coinciden de 0 a 1 caracteres.`,
  "ccmessaging.ruleconfig.tips2": `Por ejemplo, "\\*{'@'}huawei.com" indica que todos los correos electrónicos cuyo nombre de dominio es "huawei.com" son rechazados.`,
  "ccmessaging.ruleconfig.tips3": `Cuadro de texto de contenido excepto "\\*" "\\?" Se deben cumplir las reglas de dirección de correo electrónico.`,
  "ccmessaging.ruleconfig.tips4": `"\\*{'@'}\\*.com" bloqueará todos los correos con el nombre de dominio ".com". Tenga cuidado al usar este comando.`,
  "ccmessaging.ruleconfig.validityPeriod": "Período de validez",
  "ccmessaging.ruleconfig.validityPeriodDay": "Access Token Período de validez (días)",
  "ccmessaging.ruleconfig.duplicateEmailAddress": "Dirección de correo electrónico duplicada",
  "ccmessaging.ruleconfig.duplicateSmsAddress": "Número de móvil duplicado",
  "ccmessaging.socialPostOperation.button.approve": "Aprobar",
  "ccmessaging.socialPostOperation.button.batchDelete": "Eliminación masiva",
  "ccmessaging.socialPostOperation.button.cancel": "Retirada",
  "ccmessaging.socialPostOperation.button.channelConfig": "Configuración del canal",
  "ccmessaging.socialPostOperation.button.creatPost": "Publicar",
  "ccmessaging.socialPostOperation.button.edit": "Editar",
  "ccmessaging.socialPostOperation.button.delete": "Borrar",
  "ccmessaging.socialPostOperation.button.saveDraft": "Guardar como borrador",
  "ccmessaging.socialPostOperation.button.upload": "Subir",
  "ccmessaging.socialPostOperation.ceratePageTitle": "Operación de la cuenta/Publicación",
  "ccmessaging.socialPostOperation.edit.approveInformation": "Información de aprobación",
  "ccmessaging.socialPostOperation.edit.basicInformation": "Información básica",
  "ccmessaging.socialPostOperation.edit.must": "Requerido",
  "ccmessaging.chat.channelconfig.checkConfirmTokenEqualToToken": "Debes ingresar el mismo valor",
  "ccmessaging.socialPostOperation.edit.placeholder": "Por favor ingrese",
  "ccmessaging.socialPostOperation.edit.publishInformation": "Información de publicación",
  "ccmessaging.socialPostOperation.facebook.publishAt.tips": "La publicación programada debe publicarse entre 20 minutos y 75 días después de la creación",
  "ccmessaging.socialPostOperation.facebook.tips.emptyPost": "Facebook no puede publicar publicaciones en blanco. Seleccione al menos un elemento (contenido, imagen o vídeo)",
  "ccmessaging.socialPostOperation.field.applicationType": "Tipo de aplicación",
  "ccmessaging.socialPostOperation.field.applicationType.create": "Publicar",
  "ccmessaging.socialPostOperation.field.approveComment": "Comentarios de aprobación",
  "ccmessaging.socialPostOperation.field.approverName": "Aprobado por",
  "ccmessaging.socialPostOperation.field.approveStatus": "Resultado de la aprobación",
  "ccmessaging.socialPostOperation.field.approveStatus.pass": "a través",
  "ccmessaging.socialPostOperation.field.approveStatus.rejected": "Rechazado",
  "ccmessaging.socialPostOperation.field.channelName": "Nombre del canal",
  "ccmessaging.socialPostOperation.field.content": "Contenido",
  "ccmessaging.socialPostOperation.field.createUserName": "Solicitante",
  "ccmessaging.socialPostOperation.field.image": "Imágenes",
  "ccmessaging.socialPostOperation.field.operation": "Acción",
  "ccmessaging.socialPostOperation.field.postConfigInfo": "Información del canal",
  "ccmessaging.socialPostOperation.field.postType": "Canal de publicación",
  "ccmessaging.socialPostOperation.field.publishAt": "Tiempo de publicación",
  "ccmessaging.socialPostOperation.field.publishChannel": "Publicar un canal",
  "ccmessaging.socialPostOperation.field.publishStatus": "Estado de publicación",
  "ccmessaging.socialPostOperation.field.publishStatus.published": "Publicado",
  "ccmessaging.socialPostOperation.field.publishStatus.unpublished": "No publicado",
  "ccmessaging.socialPostOperation.field.publishTime": "Tiempo estimado de publicación",
  "ccmessaging.socialPostOperation.field.publishTimeSet": "Configuración de tiempo de publicación",
  "ccmessaging.socialPostOperation.field.publishTyle.now": "Publicación en tiempo real",
  "ccmessaging.socialPostOperation.field.publishTyle.time": "Publicación programada",
  "ccmessaging.socialPostOperation.field.publishType": "Tipo de publicación",
  "ccmessaging.socialPostOperation.field.remark": "Comentarios",
  "ccmessaging.socialPostOperation.field.status": "Estado",
  "ccmessaging.socialPostOperation.field.status.approved": "Hecho",
  "ccmessaging.socialPostOperation.field.status.draft": "Draft",
  "ccmessaging.socialPostOperation.field.status.toApproved": "Pendiente de aprobación",
  "ccmessaging.socialPostOperation.field.title": "Asunto",
  "ccmessaging.socialPostOperation.field.video": "video",
  "ccmessaging.socialPostOperation.res.recall": "Retirada exitosa",
  "ccmessaging.socialPostOperation.search.searchTitle": "Temas",
  "ccmessaging.socialPostOperation.select.pleaseChoose": "Por favor seleccione",
  "ccmessaging.socialPostOperation.tab.history": "Historial de aprobación",
  "ccmessaging.socialPostOperation.tips.approveSet": "Compruebe el elemento de configuración del aprobador",
  "ccmessaging.socialPostOperation.tips.approveSuccess": "La aprobación se ha realizado correctamente",
  "ccmessaging.socialPostOperation.tips.approvingDelete": "Los datos en el estado Pendiente de Aprobación no se pueden eliminar",
  "ccmessaging.socialPostOperation.tips.cancelError": "Los datos en estado no pendiente no se pueden retirar",
  "ccmessaging.socialPostOperation.tips.cannotDel": "No se pueden eliminar los mensajes pendientes de aprobación",
  "ccmessaging.socialPostOperation.tips.delConfirm": "¿Está seguro de que desea eliminar los datos seleccionados?",
  "ccmessaging.socialPostOperation.tips.deleteApproveSuccess": "Eliminación enviada correctamente, pendiente de aprobación",
  "ccmessaging.socialPostOperation.tips.deleteNoApprove": "El aprobador está vacío. El aprobador se eliminará después de enviarlo. ¿Está seguro de que desea enviarlo?",
  "ccmessaging.socialPostOperation.tips.fileTip": "El tamaño del archivo excede el límite, máximo {n} MB",
  "ccmessaging.socialPostOperation.tips.imageNumTip": "Se puede cargar un máximo de nueve imágenes",
  "ccmessaging.socialPostOperation.tips.imageType": "Soporta formatos png, jpg, gif, bmp y tiff",
  "ccmessaging.socialPostOperation.tips.imgAndVideoChoose": "Las imágenes y los vídeos no se pueden cargar al mismo tiempo",
  "ccmessaging.socialPostOperation.tips.postTypesChoose": "Seleccione al menos un canal de publicación",
  "ccmessaging.socialPostOperation.tips.publishFailed": "Error en la publicación",
  "ccmessaging.socialPostOperation.tips.saveApproveSuccess": "Enviado correctamente, pendiente de aprobación",
  "ccmessaging.socialPostOperation.tips.submit": "El aprobador está vacío. Después de enviarlo, se publicará directamente. ¿Está seguro de que desea enviarlo?",
  "ccmessaging.socialPostOperation.tips.submitSuccess": "Enviado correctamente",
  "ccmessaging.socialPostOperation.tips.titleError": "No puede contener < y >",
  "ccmessaging.socialPostOperation.tips.uploadVideo": "Por favor, cargue un archivo de vídeo",
  "ccmessaging.socialPostOperation.tips.videoType": "Soporta formato mp4",
  "ccmessaging.socialPostOperation.tips.youTubeFileChoose": "El canal YouTube solo admite la carga de vídeos",
  "ccmessaging.socialPostOperation.youTube.addLabel": "Añadir etiqueta",
  "ccmessaging.socialPostOperation.youTube.audience": "Audiencia",
  "ccmessaging.socialPostOperation.youTube.audienceRule": "Según lo requerido por la ley, usted debe cumplir con la Ley de Protección de la Privacidad de los Niños en Línea (COPPA) y/u otras leyes, dondequiera que se encuentre.Debe indicar si su vídeo está orientado a los niños",
  "ccmessaging.socialPostOperation.youTube.category": "Categorías",
  "ccmessaging.socialPostOperation.youTube.category.car": "Motor",
  "ccmessaging.socialPostOperation.youTube.category.Comedy": "Comedia",
  "ccmessaging.socialPostOperation.youTube.category.education": "Formación",
  "ccmessaging.socialPostOperation.youTube.category.entertainment": "Entretenimiento",
  "ccmessaging.socialPostOperation.youTube.category.film": "Cine y animación",
  "ccmessaging.socialPostOperation.youTube.category.gaming": "Videojuegos",
  "ccmessaging.socialPostOperation.youTube.category.howto": "Consejos y estilo",
  "ccmessaging.socialPostOperation.youTube.category.music": "Música",
  "ccmessaging.socialPostOperation.youTube.category.news": "Noticias y política",
  "ccmessaging.socialPostOperation.youTube.category.nonprofits": "ONGs y activismo",
  "ccmessaging.socialPostOperation.youTube.category.people": "Gente y blogs",
  "ccmessaging.socialPostOperation.youTube.category.pets": "Mascotas y animales",
  "ccmessaging.socialPostOperation.youTube.category.science": "Ciencia y tecnología",
  "ccmessaging.socialPostOperation.youTube.category.shortMovies": "Cortometrajes",
  "ccmessaging.socialPostOperation.youTube.category.sport": "Deportes",
  "ccmessaging.socialPostOperation.youTube.category.travel": "Viajes y eventos",
  "ccmessaging.socialPostOperation.youTube.categoryDetail": "Añade tu vista a una categoría para que los espectadores la encuentren fácilmente",
  "ccmessaging.socialPostOperation.youTube.disclosureScope": "Alcance público",
  "ccmessaging.socialPostOperation.youTube.disclosureScopeDetail": "Elige quién puede ver tu video",
  "ccmessaging.socialPostOperation.youTube.embedding": "Permitir la incrustación",
  "ccmessaging.socialPostOperation.youTube.embedding.tips": "Permitir que otros inserten tus vídeos en sus sitios web",
  "ccmessaging.socialPostOperation.youTube.knowledgePermission": " Creative Commons - Atribución",
  "ccmessaging.socialPostOperation.youTube.labels": "etiquetas",
  "ccmessaging.socialPostOperation.youTube.labelsDetail": "Las etiquetas pueden ayudar a los espectadores a encontrar tu video si hay palabras mal escritas en el contenido del video.Aparte de eso, las etiquetas no hacen mucho para encontrar videos",
  "ccmessaging.socialPostOperation.youTube.noKids": "No es para niños",
  "ccmessaging.socialPostOperation.youTube.normalPermission": "Licencia estándar de YouTube",
  "ccmessaging.socialPostOperation.youTube.permissions": "Licencias",
  "ccmessaging.socialPostOperation.youTube.permissionsKnow": "Comprender los tipos de licencia",
  "ccmessaging.socialPostOperation.youTube.scopePrivate": "Private",
  "ccmessaging.socialPostOperation.youTube.scopePublic": "Public",
  "ccmessaging.socialPostOperation.youTube.scopeUnlisted": "No en la lista pública",
  "ccmessaging.socialPostOperation.youTube.tags.tips": "Ingrese una coma después de cada etiqueta",
  "ccmessaging.socialPostOperation.youTube.videoToKids": "¿Este vídeo es contenido para niños?",
  "ccmessaging.socialPostOperation.youTube.view.commentNum": "14 Comentarios",
  "ccmessaging.socialPostOperation.youTube.view.dataResolve": "Análisis de datos",
  "ccmessaging.socialPostOperation.youTube.view.editVideo": "Editar vídeo",
  "ccmessaging.socialPostOperation.youTube.view.lookNum": "38 Visto 2 días hace",
  "ccmessaging.socialPostOperation.youTube.view.orderType": "Ordenar por",
  "ccmessaging.socialPostOperation.youTube.view.share": "Compartir",
  "ccmessaging.socialPostOperation.youTube.view.subscriber": "1 suscriptor",
  "ccmessaging.socialPostOperation.youTube.view.unfolding": "Expandir",
  "ccmessaging.socialPostOperation.youTube.yesKids": "Sí, el contenido está orientado a los niños",
  "ccmessaging.socialPostOperation.youTube.publishAtTips": "Esta configuración solo está disponible si el ámbito público está establecido en Privado",
  "ccmessaging.socialPostOperation.facebook.threeDay": "3 días",
  "ccmessaging.socialPostOperation.facebook.awful": "Comentarios",
  "ccmessaging.socialPostOperation.facebook.comment": "",
  "ccmessgaing.chat.web.channel.Agent": "Agente de audio y vídeo",
  "ccmessgaing.chat.web.channel.ivr": "IVR",
  "ccmessgaing.chat.web.channel.webrtc.share": "El uso compartido de escritorio implica la visualización de privacidad. Confirme.",
  "ccmessgaing.chatbot.message.female": "Femenino",
  "ccmessgaing.chatbot.message.male": "Masculino",
  "ccmessgaing.postChannelConfig.accessToken": "Credenciales de acceso",
  "ccmessgaing.postChannelConfig.add": "Agregar un canal",
  "ccmessgaing.postChannelConfig.apiFabricAK": "ApiFabric Identificación",
  "ccmessgaing.postChannelConfig.apiFabricSK": "ApiFabricClave secreta",
  "ccmessgaing.postChannelConfig.apiKey": "Clave de API",
  "ccmessgaing.postChannelConfig.appId": "ID de cliente",
  "ccmessgaing.postChannelConfig.appKey": "Clave de cliente",
  "ccmessgaing.postChannelConfig.cancel": "Cancelación",
  "ccmessgaing.postChannelConfig.channelConfig": "Configurar canal",
  "ccmessgaing.postChannelConfig.channelCreate": "Creación de un canal",
  "ccmessgaing.postChannelConfig.channelEdit": "Editar configuración de canal",
  "ccmessgaing.postChannelConfig.channelType": "Tipo de canal",
  "ccmessgaing.postChannelConfig.confirm": "Confirmar",
  "ccmessgaing.postChannelConfig.addUsers": "Añadir",
  "ccmessgaing.postChannelConfig.createFailed": "Error al crear. Compruebe los parámetros del formulario y la conexión de red",
  "ccmessgaing.postChannelConfig.createSuccess": "La información de configuración del canal social multimedia se ha creado correctamente",
  "ccmessgaing.postChannelConfig.delete": "Suprimir",
  "ccmessgaing.postChannelConfig.deleteFailed": "Impossible de supprimer les informations de configuration du canal multimédia social.",
  "ccmessgaing.postChannelConfig.deleteFailed.db": "Error al eliminar la base de datos. Error al operar la base de datos",
  "ccmessgaing.postChannelConfig.deleteFailed.permission": "Error al eliminar. Error en la comprobación de permisos",
  "ccmessgaing.postChannelConfig.deleteFailed.inUse": "Error al eliminar el canal porque existe información de liberación",
  "ccmessgaing.postChannelConfig.deleteSuccess": "La información de configuración del canal social multimedia se elimina correctamente",
  "ccmessgaing.postChannelConfig.edit": "Editar",
  "ccmessgaing.postChannelConfig.editFailed": "Error al actualizar. Compruebe los parámetros del formulario y la conexión de red",
  "ccmessgaing.postChannelConfig.editSuccess": "La información de configuración del canal social multimedia se actualiza correctamente",
  "ccmessgaing.postChannelConfig.failed": "Fallo",
  "ccmessgaing.postChannelConfig.maxLength": "La longitud máxima es {n} caracteres",
  "ccmessgaing.postChannelConfig.operation": "Acciones",
  "ccmessgaing.postChannelConfig.operation.person": "Personal de operaciones",
  "ccmessgaing.postChannelConfig.placeSelectChannelType": "Seleccione un tipo de canal",
  "ccmessgaing.postChannelConfig.postChannelName": "Nombre del canal",
  "ccmessgaing.postChannelConfig.refreshToken": "Actualizar cupones",
  "ccmessgaing.postChannelConfig.users.overSize": "El número de personal de operaciones supera los 10",
  "ccmessgaing.postChannelConfig.repeatedly": "El canal ya existe",
  "ccmessgaing.postChannelConfig.selectByChannelName": "Nombre del canal",
  "ccmessgaing.postChannelConfig.subChannelId": "ID de canal",
  "ccmessgaing.postChannelConfig.success": "Éxito",
  "ccmessgaing.postChannelConfig.unauthorized": "Operación no certificada",
  "chat.agent.chatOnlineWorkbench": "Banco de trabajo de chat en línea",
  "chat.agent.label.balance": "Ingresos y Gastos",
  "chat.agent.label.billDetail": "Detalles de la lista",
  "chat.agent.label.chatBot": "Chatbot",
  "chat.agent.label.consumption": "Mi Consumo",
  "chat.agent.label.customerSource": "Fuente del cliente: App móvil - Centro personal",
  "chat.agent.label.downagainfile": "Descargar de nuevo",
  "chat.agent.label.goAway": "Lejos",
  "chat.agent.label.historicalBill": "Proyecto de ley histórico",
  "chat.agent.label.money": "Importe",
  "chat.agent.label.mybill": "Mis facturas",
  "chat.agent.label.offline": "Desconectado",
  "chat.agent.label.online": "En línea",
  "chat.agent.label.recivefile": "Recibir",
  "chat.agent.label.refusefile": "Rechazar",
  "chat.agent.label.send": "Enviar",
  "chat.agent.label.total": "En total",
  "chat.agent.label.waiting": "Espera",
  "chat.agent.message.changeFailed": "Error al cambiar el estado del agente.",
  "chat.agent.title.changeWorkMode": "cambiar modo de trabajo",
  "chat.agent.title.close": "Cerrar",
  "chat.agent.title.createCase": "Crear caso",
  "chat.agent.title.createSkill": "Crear Habilidad",
  "chat.agent.title.enterText": "Por favor ingrese",
  "chat.agent.title.OfflineCall": "Llamada sin conexión",
  "chat.agent.title.transfer": "Traslado a",
  "chat.agent.title.userInformation": "Información del cliente",
  "chat.agent.title.userMenu": "Menú del cliente",
  "chat.agent.title.workMode": "modo de trabajo",
  "chat.agentConsole.message.content.audio": "[Mensaje de voz]",
  "chat.agentConsole.message.content.connectMessage": "¡Un nuevo mensaje!",
  "chat.agentConsole.message.content.image": "[Mensaje de imagen]",
  "chat.agentConsole.message.content.locate": "[Mensaje de ubicación]",
  "chat.agentConsole.message.content.video": "[Mensaje de vídeo]",
  "chat.agentConsole.message.error.emptyMessage": "[El mensaje no contiene contenido que se pueda mostrar.]",
  "chat.agentConsole.message.error.notification": "No se puede crear la nueva notificación de mensaje. Por favor, compruebe la configuración del navegador!",
  "chat.client.alert.message.invildtoken": "El diálogo se agotó el tiempo de espera o ha iniciado sesión en otro lugar. Cierre la ventana de chat e inténtelo de nuevo. ¡Gracias!",
  "chat.client.button.submit": "Enviar",
  "chat.client.evaluate.message": "Por favor, comente mi servicio. ¡Gracias!",
  "chat.client.greeting.afternoon": "¡Buenas tardes!",
  "chat.client.greeting.dear": "Estimado",
  "chat.client.greeting.evening": "¡Buenas noches!",
  "chat.client.greeting.info": "¿Qué puedo hacer por ti?",
  "chat.client.greeting.morning": "¡Buenos días!",
  "chat.client.label.accountNumber": "Cuenta",
  "chat.client.label.bill": "Mis facturas",
  "chat.client.label.chatTitle": "Clientes",
  "chat.client.label.download": "Descargar",
  "chat.client.label.evaluation": "Encuesta de satisfacción",
  "chat.client.label.forgotPassword": "Olvidé mi contraseña",
  "chat.client.label.header": "Teléfono +",
  "chat.client.label.input": "Por favor ingrese",
  "chat.client.label.like": "Te puede gustar",
  "chat.client.label.login": "Iniciar sesión",
  "chat.client.label.loginTitle": "Iniciar sesión",
  "chat.client.label.more": "Más información",
  "chat.client.label.no": "No",
  "chat.client.label.order": "Mis Pedidos",
  "chat.client.label.password": "Contraseña",
  "chat.client.label.privacyHint": "Advertencia para la protección de la privacidad",
  "chat.client.label.privacyHintContent": "Para mejorar la calidad del servicio, su conversación será grabada por el sistema. ¿Estás seguro de que quieres seguir hablando?",
  "chat.client.label.promotions": "Iniciar sesión",
  "chat.client.label.rateService": "Servicio de Puntuación",
  "chat.client.label.system": "El sistema está ocupado. Por favor, inténtelo de nuevo más tarde.",
  "chat.client.label.yes": "Sí",
  "chat.client.message.evaluationFail": "La encuesta de satisfacción falla.",
  "chat.client.message.evaluationSuccess": "La encuesta de satisfacción tiene éxito.",
  "chat.client.message.gladtoServe": ". ¿Qué puedo hacer por ti?",
  "chat.client.message.hiMyWorkno": "¡Hola! Esto es",
  "chat.client.message.noAgent": "Lo siento, el agente está ocupado. Inténtalo de nuevo más tarde.",
  "chat.client.transfer.link": "¿Está seguro de que desea transferir la llamada a un agente?",
  "chat.common.message.cancel": "Cancelar",
  "chat.common.message.create": "Crear",
  "chat.common.message.dateEnd": "Hora de finalización",
  "chat.common.message.dateStart": "Hora de inicio",
  "chat.common.message.delete": "Borrar",
  "chat.common.message.error": "Error",
  "chat.common.message.fail": "Fallo",
  "chat.common.message.finish": "OK",
  "chat.common.message.modify": "Modificar",
  "chat.common.message.promotions": "Información de contacto",
  "chat.common.message.success": "Éxito",
  "chat.common.message.warning": "Advertencia",
  "chat.confirm.no": "No",
  "chat.confirm.ok": "Confirmar",
  "chat.confirm.yes": "Sí",
  "chat.session.channel.all": "Todo",
  "chat.session.channel.facebook": "Facebook",
  "chat.session.channel.mobile": "Terminal móvil",
  "chat.session.channel.twitter": "Twitter",
  "chat.session.channel.web": "Página web",
  "chat.session.channel.wechat": "WeChat",
  "chat.session.endType.agentEnd": "Fin por agente",
  "chat.session.endType.customerEnd": "Fin por cliente",
  "chat.session.endType.overtimeEnd": "Fin debido al tiempo de espera",
  "chat.session.label.alias": "Nombre del cliente",
  "chat.session.label.allChannel": "Todos los tipos de canales",
  "chat.session.label.allEndType": "Todos los tipos finales",
  "chat.session.label.channel": "Canal de acceso de diálogo",
  "chat.session.label.created_endTime": "Hora de finalización",
  "chat.session.label.created_startTime": "Hora de inicio",
  "chat.session.label.createdTime": "Hora de creación de diálogo",
  "chat.session.label.endReason": "Causa final del cuadro de diálogo",
  "chat.session.label.endTime": "Hora de finalización de diálogo",
  "chat.session.label.endType": "Tipo de fin de diálogo",
  "chat.session.label.enterQueueTime": "Tiempo de cola",
  "chat.session.label.evaluation": "Evaluación de diálogos",
  "chat.session.label.evaluationTime": "Tiempo de evaluación de diálogo",
  "chat.session.label.exitQueueTime": "Tiempo de espera",
  "chat.session.label.remark": "Comentario",
  "chat.session.label.reset": "Restablecer",
  "chat.session.label.search": "Buscar",
  "chat.session.label.servedAgent": "Agente de servicio",
  "chat.session.label.sessionId": "ID de diálogo",
  "chat.session.label.skillQueueId": "ID de cola de habilidades",
  "chat.session.label.skillQueueName": "Nombre de la cola de habilidades",
  "chat.session.label.subChannel": "Canal de acceso secundario",
  "chat.session.title.basic": "Información de diálogo básico",
  "chat.session.title.detail": "Detalles del cuadro de diálogo",
  "chat.session.title.history": "Registros históricos del chat",
  "chat.skill.label.account": "Cuenta",
  "chat.skill.label.agents": "Agente",
  "chat.skill.label.cancel": "Cancelar",
  "chat.skill.label.createSkill": "Crear",
  "chat.skill.label.createSkillTab": "Crear Habilidad",
  "chat.skill.label.delete": "Borrar",
  "chat.skill.label.deleteSkills": "Borrar",
  "chat.skill.label.description": "Descripcion",
  "chat.skill.label.edit": "Editar",
  "chat.skill.label.inputName": "Nombre",
  "chat.skill.label.maxQueueNumber": "Número máximo de cola",
  "chat.skill.label.maxQueueTime": "Tiempo (s) de cola máximo (s)",
  "chat.skill.label.maxQueueTimeInTable": "Tiempo máximo de cola",
  "chat.skill.label.name": "Nombre de usuario",
  "chat.skill.label.ok": "OK",
  "chat.skill.label.priority": "Prioridad",
  "chat.skill.label.reset": "Restablecer",
  "chat.skill.label.save": "Guardar",
  "chat.skill.label.skill": "Habilidad-",
  "chat.skill.label.skillDetail": "Detalles de Habilidad",
  "chat.skill.label.skillId": "No",
  "chat.skill.label.skillName": "Nombre",
  "chat.skill.label.submit": "Enviar",
  "chat.skill.message.completeInputs": "Por favor complete todas las entradas.",
  "chat.skill.message.createFail": "Error al crear la habilidad.",
  "chat.skill.message.createSuccess": "Creamos la habilidad con éxito.",
  "chat.skill.message.delete": "Borrar",
  "chat.skill.message.error": "Error",
  "chat.skill.message.failDeleteSkill": "Error al eliminar la habilidad.",
  "chat.skill.message.isExisted": "La cola de habilidades ya existe.",
  "chat.skill.message.priorityLength": "Asegúrese de que la prioridad es menor o igual a cinco dígitos.",
  "chat.skill.message.saveFail": "Error al guardar la habilidad.",
  "chat.skill.message.saveSuccess": "Guardado la habilidad con éxito.",
  "chat.skill.message.selectSkills": "Selecciona una habilidad.",
  "chat.skill.message.success": "Éxito",
  "chat.skill.message.warning": "Advertencia",
  "chat.skill.message.willDeleteAllSkills": "¿Está seguro de que desea eliminar todas las habilidades?",
  "chat.skill.message.willDeleteSkill": "¿Está seguro de que desea eliminar la habilidad?",
  "chat.skill.message.willDeleteSkills": "¿Está seguro de que desea eliminar las habilidades seleccionadas?",
  "chat.thirdportal.chatWindow.button.startChat": "Iniciar chat",
  "chat.thirdportal.chatWindow.label.userEmail": "Correo Electrónico",
  "chat.thirdportal.chatWindow.label.userName": "Nombre",
  "chat.thirdportal.chatWindow.label.userPhone": "Número de teléfono",
  "chat.thirdportal.title": "Portal Web de Clientes",
  "chat.timerange.label.alldays": "Todo el tiempo de creación",
  "chat.timerange.label.confirmSocailChatNum": "El número máximo de clientes de servicio simultáneo es 0 usará la configuración global",
  "chat.timerange.label.customizes": "Modificado para requisitos particulares",
  "chat.timerange.label.ok": "OK",
  "chat.timerange.label.oneday": "Último día",
  "chat.timerange.label.sevendays": "Últimos 7 días",
  "chat.timerange.label.thirtydays": "Últimos 30 días",
  "chat.timerange.label.threedays": "Últimos 3 días",
  "chat.timerange.message.timeempty": "El tiempo no puede estar vacío.",
  "chat.timerange.message.timeerror": "El formato de la hora es incorrecto.",
  "chat.usefulexpressions.label.createExpressionTab": "Crear frase común",
  "chat.usefulexpressions.label.expression": "Frases comunes",
  "chat.usefulexpressions.label.expressionDetail": "Detalles de la frase común",
  "chat.usefulexpressions.message.contentLength": "Asegúrese de que la longitud de la frase común es menor o igual a 1000 caracteres.",
  "chat.usefulexpressions.message.contentNotNull": "La frase común no puede estar vacía.",
  "chat.usefulexpressions.message.createFail": "Error al crear la frase común.",
  "chat.usefulexpressions.message.createSuccess": "Se ha creado la frase común con éxito.",
  "chat.usefulexpressions.message.failDeleteExpression": "Error al eliminar la frase común.",
  "chat.usefulexpressions.message.isExisted": "El nombre de la frase común ya existe.",
  "chat.usefulexpressions.message.saveFail": "Error al guardar la frase común.",
  "chat.usefulexpressions.message.saveSuccess": "Se guardó la frase común con éxito.",
  "chat.usefulexpressions.message.selectExpressions": "Seleccione una frase común.",
  "chat.usefulexpressions.message.willDeleteExpression": "¿Está seguro de que desea eliminar la frase común?",
  "chat.usefulexpressions.message.willDeleteExpressions": "¿Está seguro de que desea eliminar la frase común seleccionada?",
  "chat.web.evaluation.star1": "Muy insatisfecho",
  "chat.web.evaluation.star2": "Insatisfecho",
  "chat.web.evaluation.star3": "Información general",
  "chat.web.evaluation.star4": "Satisfecho",
  "chat.web.evaluation.star5": "Muy satisfecho",
  "chat.webconfig.button.save": "Guardar",
  "chat.webconfig.label.bandingskill": "Cola de habilidades de enlace",
  "chat.webconfig.label.basesettings": "Configuración básica",
  "chat.webconfig.label.functionalsettings": "Configuración de la función",
  "chat.webconfig.label.greeting": "Saludos",
  "chat.webconfig.label.greeting.placeholder": "Por favor, introduzca el contenido del saludo que se muestra en la ventana de chat del cliente.",
  "chat.webconfig.label.intergratedcode": "Código integrado",
  "chat.webconfig.label.intergratemsg": "Integre el chat en vivo en su sitio web",
  "chat.webconfig.label.language": "Idioma predeterminado",
  "chat.webconfig.label.language.en": "Ingles",
  "chat.webconfig.label.language.zh": "Español",
  "chat.webconfig.label.language.pt": "Portugués",
  "chat.webconfig.label.transHuman": "Traslado al Agente",
  "chat.webconfig.label.windowTitle": "Título de la ventana de chat",
  "chat.webconfig.label.windowTitle.placeholder": "Por favor, introduzca el título que se muestra en la ventana de chat del cliente.",
  "chat.webconfig.message.configdirective": "Copie el siguiente código y péguelo antes de la etiqueta de su sitio web </body>. Guarde y libere el código para completar el acceso rápido a Huawei Service Cloud. Después del lanzamiento, la entrada de Huawei Service Cloud se muestra en la esquina inferior derecha de su sitio web. Puede hacer clic en él para hablar con el banco de trabajo de servicio al cliente en tiempo real.",
  "chat.webconfig.message.demodirective": "También puede abrir la siguiente dirección en la ventana oculta del navegador Chrome para acceder a la página de presentación proporcionada por el sistema:",
  "chat.webconfig.message.saveFail": "Error al guardar la configuración.",
  "chat.webconfig.message.saveSuccess": "Se ha guardado la configuración correctamente.",
  "chat.webconfig.title": "Habilitar Canal Web",
  "chat.weChatConfig.label.appId": "ID de desarrollador",
  "chat.weChatConfig.label.AppSecret": "Contraseña del desarrollador",
  "chat.weChatConfig.label.bindSkill": "Cola de habilidades de enlace",
  "chat.weChatConfig.label.codeName": "Código de acceso al canal",
  "chat.weChatConfig.label.configId": "ID de configuración",
  "chat.weChatConfig.label.create": "Nuevo",
  "chat.weChatConfig.label.delete": "Borrar",
  "chat.weChatConfig.label.Domain": "Dirección del servidor",
  "chat.weChatConfig.label.facebook": "Facebook",
  "chat.weChatConfig.label.nextStep": "Siguiente",
  "chat.weChatConfig.label.qcode": "Código QR",
  "chat.weChatConfig.label.save": "Guardar",
  "chat.weChatConfig.label.search": "Buscar",
  "chat.weChatConfig.label.secret": "Llave",
  "chat.weChatConfig.label.state": "Estado",
  "chat.weChatConfig.label.tenantId": "ID de inquilino",
  "chat.weChatConfig.label.verify": "Comprobación",
  "chat.weChatConfig.label.verifycode": "Términos y condiciones",
  "chat.weChatConfig.label.verifying": "Comprobación",
  "chat.weChatConfig.label.wechat": "WeChat",
  "chat.weChatConfig.link.wechat": "Servidor WeChat",
  "chat.weChatConfig.message.appIdExisted": "El ID de desarrollador de cuenta pública introducido ya existe.",
  "chat.weChatConfig.message.codeNameExisted": "El código de acceso al canal ya existe.",
  "chat.weChatConfig.message.deleteError": "Error al eliminar el elemento de configuración.",
  "chat.weChatConfig.message.emptyDeleteArray": "Seleccione el elemento de configuración que desea eliminar.",
  "chat.weChatConfig.message.ensureDelete": "¿Está seguro de que desea eliminar el elemento de configuración seleccionado?",
  "chat.weChatConfig.message.error": "Error",
  "chat.weChatConfig.message.savesuccess": "Se ha accedido correctamente.",
  "chat.weChatConfig.message.skillExisted": "La cola de habilidades seleccionada se ha vinculado a otra cuenta pública.",
  "chat.weChatConfig.message.unknown": "Se desconoce el estado de verificación.",
  "chat.weChatConfig.stepname.selectchannel": "Seleccionar canal de acceso",
  "chat.weChatConfig.stepname.selectmode": "Seleccionar método de configuración",
  "chat.weChatConfig.stepname.success": "Éxito de acceso",
  "chat.weChatConfig.stepname.verify": "Enviar para verificación",
  "chat.weChatConfig.title": "Habilitación de la configuración de redes sociales",
  "chat.weChatConfig.title.deleteTitle": "Eliminar elementos de configuración de WeChat",
  "cmessaging.chat.answerUser.error": "Error al crear una llamada de retorno de usuario",
  "cmessaging.chat.answerUser.Tips": "Crear una llamada de retorno de usuario",
  "cmessaging.chat.channelconfig.isPushWebMessage": "Mensaje Push",
  "cmessaging.chat.channelconfig.pushWebMessageKey": "Llave",
  "cmessaging.chat.channelconfig.pushWebMessageUrl": "Dirección de envío de mensajes",
  "cmessaging.chat.channelconfig.pushWebCertTypeSignature": "Autenticación de firmas",
  "cmessaging.chat.channelconfig.pushWebCertTypeOAuth": "Autenticación OAuth",
  "cmessaging.chat.channelconfig.setAgentTimeout": "Transferencia de sesión debido a que no hay respuesta del agente",
  "cmessaging.chat.multimedia.numOverMaxSum": "La cantidad de datos multimedia que ha guardado supera el límite",
  "cmessaging.chat.multimediatype.numOverMaxSum": "El número de clasificaciones guardadas supera el límite",
  "ccmessaging.socialPostOperation.search.searchContent": "Error al cargar el archivo adjunto",
  "ccmessaging.socialPostOperation.link": "Acceso al texto original",
  "ccmessaging.chat.social.media.enterprise.post.load.failed": "Error al cargar el archivo adjunto",
  "ccmessaging.chat.multimedia.imageUploadChannelFormat": "Los canales WhatsApp, web, Telegram, LINE, WeChat, Facebook y Twitter admiten los formatos JPG, PNG y JPEG.",
  "ccmessaging.chat.multimedia.audioUploadChannelFormat": "El canal WhatsApp admite el formato ACC.\nEl canal web admite los formatos MP3 y AAC.\nLos canales Instagram y LINE admiten el formato M4A.\nLos canales WeChat y Facebook admiten el formato MP3.\nEl canal Telegram admite los formatos MP3 y M4A.",
  "ccmessaging.chat.multimedia.documentUploadChannelFormat": "El canal WhatsApp admite los formatos DOC, DOCX, PDF, PPT, PPTX, XLSX y XLS.\nEl canal web admite los formatos DOC, DOCX, PDF y ZIP.\nEl canal Telegram admite los formatos PDF y ZIP.",
  "ccmessaging.chat.channelconfig.searchChannelName": "Nombre del canal de búsqueda",
  "ccmessaging.chat.channelconfig.createChannel": "Nuevo canal",
  "ccmessaging.chat.channelconfig.modifyChannel": "Modificar canal",
  "ccmessaging.chat.channelconfig.channelIntegrate": "Integración de canales",
  "ccmessaging.chat.channelconfig.baseConfig": "Configuración básica",
  "ccmessaging.chat.channelconfig.robotConfig": "Configuración del bot",
  "ccmessaging.chat.channelconfig.chooseChannel": "Selección de canal",
  "ccmessaging.chat.channelconfig.range.placeholder": "{0}-{1}",
  "ccmessaging.chat.channelconfig.advanceConfig": "Más información",
  "ccmessaging.chat.channelconfig.web.clickToCall": "Haga clic para llamar",
  "ccmessaging.chat.channelconfig.time.timeNoRange": "En Período {n}, la hora de inicio debe ser anterior a la hora de finalización.",
  "ccmessaging.chat.channelconfig.time.timeCross": "El período {0} se superpone con el período {1}.",
  "ccmessaging.chat.channelconfig.time.timePeriod0": "Por favor, complete Periodo {n}",
  "ccmessaging.chat.channelconfig.time.timePeriod": "Por favor, complete o elimine Periodo {n}",
  "ccmessaging.silentagent.button.create": "Nuevo",
  "ccmessaging.silentagent.dialog.title.create": "Nuevo Agente silencioso",
  "ccmessaging.silentagent.dialog.title.edit": "Editar Agente silencioso",
  "ccmessaging.silentagent.dialog.title.view": "Vistas Agente silencioso",
  "ccmessaging.silentagent.delete.selectone": "Seleccione al menos un registro.",
  "ccmessaging.silentagent.delete.select.morethan.100": "Se puede eliminar un máximo de 100 registros de datos a la vez.",
  "ccmessaging.silentagent.placeholder.selectaudioskill": "Seleccione una cola de habilidades de voz.",
  'ccmessaging.silentagent.btn.add.period': 'añadir período de tiempo',
  'ccmessaging.silentagent.agent.workday.worktime.error': 'La configuración del tiempo de trabajo del agente es incorrecta. Los segmentos de tiempo se superponen.',
  'ccmessaging.silentagent.agent.holiday.worktime.error': 'La configuración de tiempo no laborable del agente es incorrecta. Los segmentos de tiempo se superponen.',
  'ccmessaging.silentagent.agent.workday.worktime.empty': 'La configuración de tiempo de trabajo del agente tiene una configuración vacía.',
  'ccmessaging.silentagent.agent.holiday.worktime.empty': 'La configuración de tiempo no laborable del agente tiene una configuración vacía.',
  "ccmessaging.silentagent.voiceaccess.empty": "La configuración de la ruta llamada IVR de voz no puede estar vacía",
  "ccmessaging.chat.channelconfig.searchCertCode": "Buscar un código de certificado",
  "ccmessaging.chat.channelconfig.cloudApi.accessToken.tips": "El token de acceso es el token de acceso generado por la plataforma para desarrolladores para acceder a la API en la nube. El período de validez de este parámetro se reduce en dos días. Por ejemplo\n, si el período de validez es de 60 días\n, se recomienda establecer este parámetro en 58 días. Si este parámetro se deja vacío\n, el token es un token permanente. Tenga cuidado al usar este parámetro",
  "ccmessaging.chat.channelconfig.cloudApi.bsp.tips": "Actualmente, la API de Whatsapp Cloud solo se conecta a mensajes de texto",
  "ccmessaging.chat.channelconfig.checkWhatsAppAccessTokenDuration": "El período de validez del token de acceso debe ser un entero positivo entre 1 y 60",
  "ccmessaging.chat.channelconfig.copy": "Copiar",
  "ccmessaging.chat.channelconfig.copySuccess": "La replicación se realizó correctamente",
  "ccmessaging.chat.sumbitverification.whatsApp.cloudapi.tips": "Configure la URL y el código de verificación en la aplicación Whatsapp de Facebook Developer Platform",
  "ccmessaging.chatbot.label.useIntelligentRobot": "Acceso a un robot inteligente",
  "ccmessaging.chatbot.label.useRobotAssistants": "Asistente del robot de acceso",
  "ccmessaging.chatbot.label.update": "Actualización",
  "ccmessaging.chatbot.label.pleaseSelect": "Seleccione",
  "ccmessaging.chatbot.label.pleaseInput": "Por favor ingrese",
  "ccmessaging.chatbot.label.intelligentRobotAccessCode": "Código de acceso del robot inteligente",
  "ccmessaging.chatbot.label.selectIntelligentRobot": "Seleccionar un robot inteligente",
  "ccmessaging.chatbot.label.selectRobotAssistants": "Seleccionar un asistente de robot",
  "ccmessaging.chatbot.check.systeam": "sistema",
  "ccmessaging.chatbot.label.robotName": "Nombre del robot",
  "ccmessaging.chatbot.label.selectRobotAvatar": "Seleccionar un avatar de robot",
  "ccmessaging.fiveG.label.chatRobotAddress": "Dirección del chatbot",
  "ccmessaging.fiveG.label.appId": "Identidad de la aplicación",
  "ccmessaging.fiveG.label.appSecret": "Secreto de aplicaciones",
  "ccmessaging.fiveG.label.certCode": "Archivo de certificado",
  "ccmessaging.email.label.selectGateway": "Seleccione una puerta de enlace",
  "ccmessaging.email.label.gatewayName": "Nombre de la puerta de enlace",
  "ccmessaging.email.label.InputGatewayName": "Ingrese el nombre de la puerta de enlace",
  "ccmessaging.email.label.defaultReceiveGateway": "Buzones de recepción predeterminados",
  "ccmessaging.email.label.defaultSendGateway": "Correo electrónico de envío predeterminado",
  "ccmessaging.sms.label.defaultSendGateway": "Número de remitente predeterminado",
  "ccmessaging.sms.label.defaultReceiveGateway": "Número de destinatario predeterminado",
  "ccmessaging.email.valid.signatureSearch": "Solo se permiten letras o dígitos de menos de 10 dígitos",
  "ccmessaging.service.time.label": "Configuración del calendario de trabajo",
  "ccmessaging.service.time.choose.time": "Configuración del calendario de trabajo",
  "ccmessaging.service.time.select.holiday": "Configuración del calendario de trabajo",
  "ccmessaging.service.time.select.weekday": "Configuración del calendario de trabajo",
  "ccmessaging.multimedia.tip.not.exist": "El recurso multimedia no existe",
  "ccmessaging.multimedia.tip.modified": "El recurso multimedia ha sido modificado. Vuelva a enviarlo",
  "ccmessaging.service.time.close.alert": "Cerrar borrará todos los datos de la tabla. ¿Está seguro de que desea cerrar",
  "ccmessaging.chat.phrase.create": "Crear Frases Comunes",
  "ccmessaging.chat.phrase.update": "Editar Frases Comunes",
  "ccmessaging.chat.specialChannel.create": "Creación de una regla de lista especial",
  "ccmessaging.chat.specialChannel.update": "Editar regla de lista especial",
  "ccmessaging.emailAdress.contentNotNull": "La dirección de correo electrónico no puede estar vacía",
  "ccmessaging.smsNumber.contentNotNull": "El número de móvil no puede estar vacío",
  "ccmessaging.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": "La contraseña debe contener al menos tres tipos de: letras mayúsculas, minúsculas, dígitos y characters: {n}",
  "ccmessaging.chat.shortcut.shortcutRepeat": "Las teclas de método abreviado ya están en uso",
  "ccmessaging.chat.multimedia.agentHangupSessionResult": "La sesión finaliza correctamente. Cierre la orden de trabajo de CRM",
  "ccmessaging.chat.multimedia.agentAutoTransferResult": "La transferencia de llamada se ha realizado correctamente. Cierre la orden de trabajo de CRM",
  "ccmessaging.chat.multimedia.agentTimeOutTransferResult": "Transferir colas de habilidades debido al tiempo de espera de la sesión. Cierre la orden de trabajo de CRM",
  "ccmessaging.chat.multimedia.userHangupSessionResult": "El usuario finaliza la sesión. Cierre la orden de trabajo de CRM",
  "ccmessaging.chat.multimedia.userTimeOutHangupResult": "Se agotó el tiempo de espera de la sesión. Cierre la orden de trabajo de CRM",
  "cmessaging.chat.multimedia.agentLogoutHangupResult": "El agente cierra la sesión por la fuerza. La sesión finaliza correctamente. Cierre la orden de trabajo de CRM",
  "ccmessaging.chat.dy.reconnect": "reconexión",
  "ccmessaging.chat.dy.uploadLocalFile": 'Finalización de la sesión',
  "ccmessaging.chat.richtext.httpTip": "El enlace insertado utiliza un protocolo inseguro, lo que plantea riesgos de seguridad. ¿Está seguro de que desea enviarlo",
  "ccmessaging.chat.riskTip": "Aviso de riesgo",
  "ccmessaging.chat.chatcard.location": "Dirección",
  "ccmessaging.chat.chatcard.alignment": "estilo de presentación de tarjeta",
  "ccmessaging.chat.chatcard.templateType": "tipo de plantilla",
  "ccmessaging.chat.chatcard.templateTypeList0": "Tipo común",
  "ccmessaging.chat.chatcard.templateTypeList1": "Opciones de tarjeta",
  "ccmessaging.chat.chatcard.tabMediaResource": "Recursos de medios de imagen",
  "ccmessaging.chat.voice.record.send": "Enviar voz",
  "ccmessaging.chat.voice.record.start": "Haga clic para grabar",
  "ccmessaging.chat.voice.record.tip": "La duración de la grabación no supera los 10 minutos",
  "ccmessaging.chat.voice.record.stop": "Detener la grabación",
  "ccmessaging.chat.selectorgs.maxlimit.warn": "El número de organizaciones seleccionadas no puede exceder de 100",
  "ccmessaging.chat.chatconfig.deleteFacebookPage": "Eliminar la página de inicio de Facebook",
  "ccmessaging.chat.search.name": "Buscar Nombre",
  "ccmessaging.chat.search.phraseName": "Frases comunes",
  "ccmessaging.chat.chatcard.tabnamevalidate": "redirectUrl es el valor predeterminado del sistema y no se permite su uso.",
  "ccmessaging.chat.chatcard.transverse": "horizontal",
  "ccmessaging.chat.chatcard.longitudinal": "retrato",
  "ccmessaging.chat.channelconfig.asyncTime.tip": "Se o período após o cenário off-line ser disparado exceder esse valor, as mensagens off-line do cliente serão armazenadas diretamente e não serão mais roteadas para os agentes.",
  "ccmessaging.chat.channelconfig.noReplyTime.tip": "Si el período posterior a la última vez que un cliente se puso en contacto con un agente supera este valor, el agente no podrá realizar una llamada saliente al cliente.",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime.tip": "En el escenario de llamada saliente, si un cliente no responde dentro de este período, la sesión finaliza automáticamente.",
  "ccmessaging.chat.email.attachment.tips.maxOnceUpload": "El número de archivos adjuntos cargados no puede exceder de 5",
  "ccmessaging.chat.email.attachment.tips.maxTotalUpload": "El número de archivos adjuntos cargados es superior a 10.",
  "ccmessaging.chat.voice.record": "Registro",
  "ccmessaging.chat.offline.userIsInService": "Usuario de servicio de agente existente",
  "ccmessaging.chat.channelconfig.search.by.robotNameKeyword": "Buscar el nombre del bot o el código de acceso",
  "ccmessaging.chat.channelconfig.searchSkillQueueName": "Nombre de la cola de habilidades de búsqueda",
  "ccmessaging.chat.user.online": "El usuario está en línea.",
  "ccmessaging.chat.async.echarts.user": "Número de personas (veces)",
  "ccmessaging.chat.async.echarts.message": "Mensajes (número)",
  "cobrowse.sessionrecord.roomId": "Número de habitación",
  "cobrowse.sessionrecord.searchroomId": "Por favor, introduzca el número de habitación",
  "cobrowse.sessionrecord.workNo": "Número de agente",
  "cobrowse.sessionrecord.searchworkNo": "Por favor, introduzca el número de habitación",
  "cobrowse.sessionrecord.caller": "Caller",
  "cobrowse.sessionrecord.searchcaller": "Por favor, introduzca la información de la persona que llama",
  "cobrowse.sessionrecord.startAt": "Hora de inicio",
  "cobrowse.sessionrecord.endAt": "Hora final",
  "cobrowse.sessionrecord.duration": "Duración",
  "cobrowse.sessionrecord.positiveInteger.checkmsg": "Ingrese un entero positivo",
  "cobrowse.sensitiveinfo.pageMatchValue": "Configuración de coincidencia de página",

  "cobrowse.sensitiveinfo.rulenamecheck": "El nombre de la regla solo puede contener _. alfanuméricos y un máximo de 128 caracteres",
  "cobrowse.sensitiveinfo.url.check": "La ruta de acceso de URL configurada para la coincidencia de páginas tiene un formato incorrecto",
  "cobrowse.sensitiveinfo.cssselectorcheck": "Error de sintaxis del selector CSS",
  "cobrowse.sensitiveinfo.pageIdCheck": "El ID de página solo puede contener letras, dígitos, _-:. y un máximo de 1024 caracteres",
  "cobrowse.sensitiveinfo.create": "Crear una nueva regla de información confidencial",
  "cobrowse.sensitiveinfo.edit": "Editar una nueva regla de información confidencial",
  "cobrowse.sensitiveinfo.createnameexist": "nombre ya existe",
  "cobrowse.sensitiveinfo.exceedmaxcount": "Se pueden configurar hasta 100 reglas sensibles por tenant",
  "cobrowse.sensitiveinfo.delete": "Regla de eliminación de información confidencial",
  "cobrowse.sensitiveinfo.willdeletetips": "Está seguro de que desea eliminar la regla de información confidencial seleccionada?",
  "cobrowse.sensitiveinfo.deletenum.outstripping": "Se puede eliminar un máximo de 100 registros a la vez.",
  "cobrowse.common.tips.selectempty": "Por favor, seleccione los datos a operar primero",
  "cobrowse.sessionrecord.workNo.rules": "Necesita estar en el rango de 1 - 65535",
  "ccmessaging.chat.chatconfig.batch.delete": "Eliminación por lotes",
  "ccmessaging.chat.chatconfig.create": "Nuevo",
  "ccmessaging.channelconfig.export.datascope": "Alcance de los datos",
  "ccmessaging.channelconfig.export.allscope": "Todo",
  "ccmessaging.channelconfig.export.curPage": "Página actual",
  "ccmessaging.channelconfig.export.customField": "Campo personalizado",
  "ccmessaging.channelconfig.export.selectedField": "Campo de exportación",
  "ccmessaging.channelconfig.export.selectAll": "Todo",
  "ccmessaging.channelconfig.export.zipPwd": "Contraseña comprimida",
  "ccmessaging.channelconfig.export.zipPwdHint": "Por favor, introduzca la contraseña de compresión",
  "ccmessaging.channelconfig.export.pwdMsg": "Contraseña para generar un archivo comprimido. El valor es una cadena de 8 a 12 caracteres y debe contener letras, dígitos y caracteres especiales (excluyendo espacios de {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, y caracteres de tabulación).",
  'ccmessaging.channelconfig.export.pwdMsgError': "El valor es una cadena de 8 a 12 caracteres y debe contener letras, dígitos y caracteres especiales (excluyendo espacios de {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, y caracteres de tabulación).",
  "ccmessaging.channelconfig.export": "Exportación",
  "ccmessaging.channelconfig.viewExportTask": "Exportar vista de tareas",
  "ccmessaging.channelconfig.export.title": "Exportar configuración de canal",
  'ccmessaging.channelconfig.export.exportAll': 'No ha seleccionado registros específicos. ¿Exportar todos los registros? También puede cancelar la operación y seleccionar un registro específico para exportar.',
  'ccmessaging.channelconfig.export.exportAllTitle': "Exportar todas las configuraciones",
  "ccmessaging.channelconfig.export.allExportConfirm": "Todas las Exportaciones",
  'ccmessaging.channelconfig.export.exportSelect': "Ha seleccionado {0} registros. ¿Estás seguro de que quieres continuar?",
  "ccmessaging.channelconfig.export.exportBeginTime": "Exportar hora de inicio",
  "ccmessaging.channelconfig.export.exportEndTime": "Hora de finalización de exportación",
  "ccmessaging.channelconfig.export.exportTaskStatus": "Estado",
  "ccmessaging.channelconfig.export.exporting": "Exportación",
  "ccmessaging.channelconfig.export.unprocessed": "Sin procesar",
  'ccmessaging.channelconfig.export.resultMsg': 'Se ha creado una tarea de exportación. ¿Está seguro de que desea ver la tarea en la lista de exportación?',
  "ccmessaging.channelconfig.export.resultSuccess": "Creado correctamente.",
  "ccmessaging.channelconfig.export.exception": "Error al generar la tarea de exportación. Revisa los registros.",
  "ccmessaging.channelconfig.export.error": "Error en la nueva tarea de exportación, compruebe el registro",
  "ccmessaging.channelconfig.export.paramInvalid": "Error al verificar las condiciones de exportación.",
  "ccmessaging.channelconfig.export.exceedlimit": "Se puede exportar un máximo de {0} registros de datos",
  "ccmessaging.channelconfig.export.nodata": "Ninguna configuración de canal cumple las condiciones.",
  "ccmessaging.chat.holiday.deleteWorkDay": "Eliminar una fecha de trabajo especificada",
  "ccmessaging.chat.holiday.deleteAllWorkDay": "Eliminar todas las fechas de trabajo especificadas",
  "ccmessaging.chat.holiday.deleteHoliday": "Eliminar una fecha de descanso especificada",
  "ccmessaging.chat.holiday.deleteAllHoliday": "Eliminar todas las fechas de descanso especificadas",
  "ccmessaging.multimedia.leftMenu.category": 'Categoría',
  "ccmessaging.multimedia.leftMenu.createCategory": 'Crear Categoría',
  "ccmessaging.multimedia.leftMenu.editCategory": 'Editar Categoría',
  "ccmessaging.multimedia.audio.create": 'Crear Voz',
  "ccmessaging.multimedia.audio.edit": 'Editar Voz',
  "ccmessaging.multimedia.video.create": 'Crear Video',
  "ccmessaging.multimedia.video.edit": 'Editar Vídeo',
  "ccmessaging.multimedia.image.create": 'Crear Imagen',
  "ccmessaging.multimedia.image.edit": 'Editar Imagen',
  "ccmessaging.multimedia.emoticon.create": 'Crear Emoticonos',
  "ccmessaging.multimedia.emoticon.edit": 'Editar Emoticonos',
  "ccmessaging.multimedia.document.create": 'Crear Documento',
  "ccmessaging.multimedia.document.edit": 'Editar Documento',
  "ccmessaging.multimedia.richText.create": 'Crear Texto Enriquecido',
  "ccmessaging.multimedia.richText.edit": 'Editar Texto Enriquecido',
  "ccmessaging.multimedia.local.create": 'Crear Dirección',
  "ccmessaging.multimedia.local.edit": 'Editar Dirección',
  'ccmessaging.multimedia.whatsAppTemplate.create': 'Crear Plantilla WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplate.edit': 'Editar Plantilla WhatsApp',
  "ccmessaging.multimedia.siteAddr.create": 'Crear Sitio Cobrowse',
  "ccmessaging.multimedia.siteAddr.edit": 'Editar Sitio Cobrowse',
  "ccmessaging.multimedia.cardFiveG.create": 'Crear Tarjeta 5G',
  "ccmessaging.multimedia.cardFiveG.edit": 'Editar Tarjeta 5G',
  "ccmessaging.multimedia.carouselCardFiveG.create": 'Crear Tarjeta Carrusel 5G',
  "ccmessaging.multimedia.carouselCardFiveG.edit": 'Editar Tarjeta Carrusel 5G',
  'ccmessaging.multimedia.whatsAppTemplateMsg.create': 'Crear Mensaje Plantilla WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplateMsg.edit': 'Editar Mensaje Plantilla WhatsApp',
  "ccmessaging.multimedia.cardWeb.create": 'Crear Tarjeta Web',
  "ccmessaging.multimedia.cardWeb.edit": 'Editar Tarjeta Web',
  "chat.agent.qualityCheckWorkbench.title": "Inspección de calidad",
  "chat.agent.qualityCheckWorkbench.currentAgent": "Asientos de monitoreo actuales",
  "chat.agent.qualityCheckWorkbench.subscribeSessionFailed": "Falló la sesión del agente de suscripción",
  "chat.agent.qualityCheckWorkbench.cancelSubscribeSessionFailed": "Falló la cancelación de la sesión del agente de suscripción",
  "chat.agent.qualityCheckWorkbench.pollMsgFailed": "Falló la obtención de la información del asiento",
  "chat.agent.qualityCheckWorkbench.closeConfirm": "Confirme si la verificación de calidad de la sesión multimedia para el asiento actual ha terminado.",
  "chat.agent.qualityCheckWorkbench.listen": "Cancelar la inserción",
  "chat.agent.qualityCheckWorkbench.insert": "Insertar",
  "chat.agent.qualityCheckWorkbench.intercept": "Interceptación",
  "chat.agent.qualityCheckWorkbench.changeQualityStatus.fail": "Falló la modificación del Estado de inspección de calidad",
  "chat.agent.msg.redis.connection.exception.description": "El sistema actual está defectuoso. Espere",
  "ccmessaging.chat.phrase.editPhraseTypeFail": "Error al editar el tipo de frase común.",
  "ccmessaging.chat.phrase.editPhraseTypeSucess": "Editar el tipo de frase común correctamente.",
  "ccmessaging.chat.phrase.editPhraseFail": "Error en la edición de frase común",
  "ccmessaging.chat.phrase.editPhraseSucess": "Editar frase común con éxito",
  "ccmessaging.chat.multimedia.editMultiMediaInfo": "Editar datos multimedia correctamente",
  "ccmessaging.chat.multimedia.editMultiMediaInfoError": "Error al editar datos multimedia",
  "ccmessaging.chat.multimedia.editMultiMediaType": "Editar el tipo multimedia correctamente",
  "ccmessaging.chat.multimedia.editMultiMediaTypeError": "Error al editar el tipo multimedia",
  "ccmessaging.chat.multimedia.createMultiMediaInfo": "Crear datos multimedia correctamente",
  "ccmessaging.chat.multimedia.createMultiMediaInfoError": "Error al crear datos multimedia",
  "ccmessaging.chat.multimedia.createMultiMediaType": "Crear el tipo multimedia correctamente",
  "ccmessaging.chat.multimedia.createMultiMediaTypeError": "Error al crear tipo multimedia",
  "ccmessaging.chat.phrase.createTypeSuccess": "Crear un tipo de frase común correctamente",

  "ccmessaging.chat.multimedia.createVideoInfo": "Crear datos de vídeo correctamente",
  "ccmessaging.chat.multimedia.createVideoInfoError": "Error al crear datos de vídeo",
  "ccmessaging.chat.multimedia.createVideoType": "Crear el tipo de vídeo correctamente",
  "ccmessaging.chat.multimedia.createVideoTypeError": "Error al crear el tipo de vídeo",
  "ccmessaging.chat.multimedia.editVideoInfo": "Editar datos de vídeo correctamente",
  "ccmessaging.chat.multimedia.editVideoInfoError": "Error al editar datos de vídeo",
  "ccmessaging.chat.multimedia.editVideoType": "Editar el tipo de vídeo correctamente",
  "ccmessaging.chat.multimedia.editVideoTypeError": "Error al editar el tipo de vídeo",
  "ccmessaging.chat.multimedia.deleteVideoInfo": "Eliminar datos de vídeo correctamente",
  "ccmessaging.chat.multimedia.deleteVideoInfoError": "Error al eliminar datos de vídeo",
  "ccmessaging.chat.multimedia.deleteVideoType": "Eliminar el tipo de vídeo correctamente",
  "ccmessaging.chat.multimedia.deleteVideoTypeError": "Error al eliminar el tipo de vídeo",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.usedbyODFS": "Error al eliminar el tipo de vídeo. Existen los recursos referenciados por el IVR inteligente.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byCard": "Error al eliminar el tipo de vídeo porque las tarjetas ya hacen referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byWhatsAppTemplateMsg": "Error al eliminar el tipo de vídeo porque un mensaje de plantilla de WhatsApp ya hace referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.createAudioInfo": "Crear datos de audio correctamente",
  "ccmessaging.chat.multimedia.createAudioInfoError": "Error al crear datos de audio",
  "ccmessaging.chat.multimedia.createAudioType": "Crear el tipo de audio correctamente",
  "ccmessaging.chat.multimedia.createAudioTypeError": "Error al crear el tipo de audio",
  "ccmessaging.chat.multimedia.editAudioInfo": "Editar datos de audio correctamente",
  "ccmessaging.chat.multimedia.editAudioInfoError": "Error al editar datos de audio",
  "ccmessaging.chat.multimedia.editAudioType": "Editar el tipo de audio correctamente",
  "ccmessaging.chat.multimedia.editAudioTypeError": "Error al editar el tipo de audio",
  "ccmessaging.chat.multimedia.deleteAudioInfo": "Eliminar datos de audio correctamente",
  "ccmessaging.chat.multimedia.deleteAudioInfoError": "Error al eliminar datos de audio",
  "ccmessaging.chat.multimedia.deleteAudioType": "Eliminar el tipo de audio correctamente",
  "ccmessaging.chat.multimedia.deleteAudioTypeError": "Error al eliminar el tipo de audio",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.usedbyODFS": "Error al eliminar el tipo de audio. Existen los recursos referenciados por el IVR inteligente.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byCard": "Error al eliminar el tipo de audio porque las tarjetas ya hacen referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byWhatsAppTemplateMsg": "Error al eliminar el tipo de audio porque un mensaje de plantilla de WhatsApp ya hace referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.createImageInfo": "Crear datos de imagen correctamente",
  "ccmessaging.chat.multimedia.createImageInfoError": "Error al crear datos de imagen",
  "ccmessaging.chat.multimedia.createImageType": "Crear el tipo de imagen correctamente",
  "ccmessaging.chat.multimedia.createImageTypeError": "Error al crear el tipo de imagen",
  "ccmessaging.chat.multimedia.editImageInfo": "Editar datos de imagen correctamente",
  "ccmessaging.chat.multimedia.editImageInfoError": "Error al editar datos de imagen",
  "ccmessaging.chat.multimedia.editImageType": "Editar el tipo de imagen correctamente",
  "ccmessaging.chat.multimedia.editImageTypeError": "Error al editar el tipo de imagen",
  "ccmessaging.chat.multimedia.deleteImageInfo": "Eliminar datos de imagen correctamente",
  "ccmessaging.chat.multimedia.deleteImageInfoError": "Error al eliminar datos de imagen",
  "ccmessaging.chat.multimedia.deleteImageType": "Eliminar el tipo de imagen correctamente",
  "ccmessaging.chat.multimedia.deleteImageTypeError": "Error al eliminar el tipo de imagen",
  "ccmessaging.chat.multimedia.deleteImageTypeError.usedbyODFS": "Error al eliminar el tipo de imagen. Existen los recursos referenciados por el IVR inteligente.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byCard": "Error al eliminar el tipo de imagen porque las tarjetas ya hacen referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byWhatsAppTemplateMsg": "Error al eliminar el tipo de imagen, porque un mensaje de plantilla de WhatsApp ya hace referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.createDocumentInfo": "Crear datos de documento correctamente",
  "ccmessaging.chat.multimedia.createDocumentInfoError": "Error al crear datos de documento",
  "ccmessaging.chat.multimedia.createDocumentType": "Crear el tipo de documento correctamente",
  "ccmessaging.chat.multimedia.createDocumentTypeError": "Error al crear el tipo de documento",
  "ccmessaging.chat.multimedia.editDocumentInfo": "Editar datos de documento correctamente",
  "ccmessaging.chat.multimedia.editDocumentInfoError": "Error al editar los datos del documento",
  "ccmessaging.chat.multimedia.editDocumentType": "Editar el tipo de documento correctamente",
  "ccmessaging.chat.multimedia.editDocumentTypeError": "Error al editar el tipo de documento",
  "ccmessaging.chat.multimedia.deleteDocumentInfo": "Eliminar datos de documento correctamente",
  "ccmessaging.chat.multimedia.deleteDocumentInfoError": "Error al eliminar datos del documento",
  "ccmessaging.chat.multimedia.deleteDocumentType": "Eliminar el tipo de documento correctamente",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError": "Error al eliminar el tipo de documento",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.usedbyODFS": "Error al eliminar el tipo de documento. Existen los recursos referenciados por el IVR inteligente.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byCard": "Error al eliminar el tipo de documento porque las tarjetas ya hacen referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byWhatsAppTemplateMsg": "Error al eliminar el tipo de documento porque un mensaje de plantilla de WhatsApp ya hace referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.createLocateInfo": "Crear datos de ubicación correctamente",
  "ccmessaging.chat.multimedia.createLocateInfoError": "Error al crear datos de ubicación",
  "ccmessaging.chat.multimedia.createLocateType": "Crear el tipo de ubicación correctamente",
  "ccmessaging.chat.multimedia.createLocateTypeError": "Error al crear el tipo de ubicación",
  "ccmessaging.chat.multimedia.editLocateInfo": "Editar datos de ubicación correctamente",
  "ccmessaging.chat.multimedia.editLocateInfoError": "Error al editar datos de ubicación",
  "ccmessaging.chat.multimedia.editLocateType": "Editar el tipo de ubicación correctamente",
  "ccmessaging.chat.multimedia.editLocateTypeError": "Error al editar el tipo de ubicación",
  "ccmessaging.chat.multimedia.deleteLocateInfo": "Eliminar datos de ubicación correctamente",
  "ccmessaging.chat.multimedia.deleteLocateInfoError": "Error al eliminar datos de ubicación",
  "ccmessaging.chat.multimedia.deleteLocateType": "Eliminar el tipo de ubicación correctamente",
  "ccmessaging.chat.multimedia.deleteLocateTypeError": "Error al eliminar el tipo de ubicación",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.usedbyODFS": "Error al eliminar el tipo de ubicación. Existen los recursos referenciados por el IVR inteligente.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byCard": "Error al eliminar el tipo de ubicación porque las tarjetas ya hacen referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byWhatsAppTemplateMsg": "Error al eliminar el tipo de ubicación porque un mensaje de plantilla de WhatsApp ya hace referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.createEmotionInfo": "Crear datos de emoticonos correctamente",
  "ccmessaging.chat.multimedia.createEmotionInfoError": "Error al crear datos de emoticonos",
  "ccmessaging.chat.multimedia.createEmotionType": "Crear el tipo de emoticono correctamente",
  "ccmessaging.chat.multimedia.createEmotionTypeError": "Error al crear el tipo de emoticono",
  "ccmessaging.chat.multimedia.editEmotionInfo": "Editar datos de emoticonos correctamente",
  "ccmessaging.chat.multimedia.editEmotionInfoError": "Error al editar los datos del emoticono",
  "ccmessaging.chat.multimedia.editEmotionType": "Editar el tipo de emoticono correctamente",
  "ccmessaging.chat.multimedia.editEmotionTypeError": "Error al editar el tipo de emoticono",
  "ccmessaging.chat.multimedia.deleteEmotionInfo": "Eliminar datos de emoticonos correctamente",
  "ccmessaging.chat.multimedia.deleteEmotionInfoError": "Error al eliminar los datos del emoticono",
  "ccmessaging.chat.multimedia.deleteEmotionType": "Eliminar el tipo de emoticono correctamente",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError": "Error al eliminar el tipo de emoticono",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.usedbyODFS": "Error al eliminar el tipo de emoticono. Existen los recursos referenciados por el IVR inteligente.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byCard": "Error al eliminar el tipo de emoticono, porque algunos recursos ya están referenciados por las tarjetas.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byWhatsAppTemplateMsg": "Error al eliminar el tipo de emoticono, porque un mensaje de plantilla de WhatsApp ya hace referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.createRichTextInfo": "Crear datos de texto enriquecido correctamente",
  "ccmessaging.chat.multimedia.createRichTextInfoError": "Error al crear datos de texto enriquecido",
  "ccmessaging.chat.multimedia.createRichTextType": "Crear el tipo de texto enriquecido correctamente",
  "ccmessaging.chat.multimedia.createRichTextTypeError": "Error al crear tipo de texto enriquecido",
  "ccmessaging.chat.multimedia.editRichTextInfo": "Editar correctamente los datos de texto enriquecido",
  "ccmessaging.chat.multimedia.editRichTextInfoError": "Error al editar datos de texto enriquecido",
  "ccmessaging.chat.multimedia.editRichTextType": "Editar el tipo de texto enriquecido correctamente",
  "ccmessaging.chat.multimedia.editRichTextTypeError": "Error al editar el tipo de texto enriquecido",
  "ccmessaging.chat.multimedia.deleteRichTextInfo": "Eliminar datos de texto enriquecido correctamente",
  "ccmessaging.chat.multimedia.deleteRichTextInfoError": "Error al eliminar datos de texto enriquecido",
  "ccmessaging.chat.multimedia.deleteRichTextType": "Eliminar el tipo de texto enriquecido correctamente",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError": "Error al eliminar el tipo de texto enriquecido",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.usedbyODFS": "Error al eliminar el tipo de texto enriquecido. Existen los recursos referenciados por el IVR inteligente.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byCard": "Error al eliminar el tipo de texto enriquecido porque las tarjetas ya hacen referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byWhatsAppTemplateMsg": "Error al eliminar el tipo de texto enriquecido porque un mensaje de plantilla de WhatsApp ya hace referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.createSiteAddrInfo": "Crear correctamente los datos de dirección de colaboración de página Web",
  "ccmessaging.chat.multimedia.createSiteAddrInfoError": "Error al crear datos de dirección de colaboración de página Web",
  "ccmessaging.chat.multimedia.createSiteAddrType": "Crear correctamente el tipo de dirección de colaboración de página Web",
  "ccmessaging.chat.multimedia.createSiteAddrTypeError": "Error al crear el tipo de dirección de colaboración de página Web",
  "ccmessaging.chat.multimedia.editSiteAddrInfo": "Editar correctamente los datos de dirección de colaboración de página Web",
  "ccmessaging.chat.multimedia.editSiteAddrInfoError": "Error al editar los datos de la dirección de colaboración de página Web",
  "ccmessaging.chat.multimedia.editSiteAddrType": "Editar correctamente el tipo de dirección de colaboración de página Web",
  "ccmessaging.chat.multimedia.editSiteAddrTypeError": "Error al editar el tipo de dirección de colaboración de página Web",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfo": "Eliminar correctamente los datos de la dirección de colaboración de página web",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfoError": "Error al eliminar los datos de la dirección de colaboración de página Web",
  "ccmessaging.chat.multimedia.deleteSiteAddrType": "Eliminar correctamente el tipo de dirección de colaboración de página web",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError": "Eliminar correctamente el tipo de dirección de colaboración de página web",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.usedbyODFS": "Error al eliminar el tipo de dirección de colaboración de página Web. Existen los recursos referenciados por el IVR inteligente.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byCard": "Error al eliminar el tipo de dirección de colaboración de página web, porque las tarjetas ya hacen referencia a algunos recursos.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byWhatsAppTemplateMsg": "Error al eliminar el tipo de dirección de colaboración de página web, porque un mensaje de plantilla de WhatsApp ya hace referencia a algunos recursos.",
  "ccmessaging.chat.chatcard.createFiveGCardFail": "Error al crear la tarjeta 5G",
  "ccmessaging.chat.chatcard.createFiveGCardSuccess": "Crear tarjeta 5G con éxito",
  "ccmessaging.chat.chatcard.editFiveGCardFail": "Error al editar la tarjeta 5G",
  "ccmessaging.chat.chatcard.editFiveGCardSuccess": "Editar la tarjeta 5G correctamente",
  "ccmessaging.chat.chatcard.deleteFiveGCardFail": "Error al eliminar la tarjeta 5G",
  "ccmessaging.chat.chatcard.deleteFiveGCardSuccess": "Eliminar la tarjeta 5G correctamente",
  "ccmessaging.chat.chatcard.createFiveGCarouselFail": "Error al crear 5G Carouse",
  "ccmessaging.chat.chatcard.createFiveGCarouselSuccess": "Crear 5G Carouse con éxito",
  "ccmessaging.chat.chatcard.editFiveGCarouselFail": "Error al editar 5G Carouse",
  "ccmessaging.chat.chatcard.editFiveGCarouselSuccess": "Editar 5G Carouse con éxito",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselFail": "Error al eliminar 5G Carouse",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselSuccess": "Eliminar 5G Carouse correctamente",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeFail": "Error al crear WhatsApp Meaasge de plantilla",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeSuccess": "Crear Meaasge de Plantilla de WhatsApp con éxito",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeFail": "Error al editar el Measge de la plantilla de WhatsApp",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeSuccess": "Editar el Measge de la plantilla de WhatsApp con éxito",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeFail": "Error al eliminar WhatsApp Meaasge de plantilla",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeSuccess": "Eliminar WhatsApp plantilla Meaasge correctamente",
  "ccmessaging.chat.chatcard.createWebCardFail": "Error al crear WebCard",
  "ccmessaging.chat.chatcard.createWebCardSuccess": "Crear WebCard correctamente",
  "ccmessaging.chat.chatcard.editWebCardFail": "Error al editar WebCard",
  "ccmessaging.chat.chatcard.editWebCardSuccess": "Editar WebCard correctamente",
  "ccmessaging.chat.chatcard.deleteWebCardFail": "Error al eliminar WebCard",
  "ccmessaging.chat.chatcard.deleteWebCardSuccess": "Eliminar WebCard correctamente",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateSuccess": "Crear una plantilla de WhatsApp correctamente",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateFail": "Error al crear plantilla de WhatsApp",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateSuccess": "Editar correctamente la plantilla de WhatsApp",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateFail": "Error al editar la plantilla de WhatsApp",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateSuccess": "Eliminar plantilla de WhatsApp correctamente",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFail": "Error al eliminar la plantilla de WhatsApp",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFailMsg": "Error al eliminar la plantilla de WhatsApp Se hace referencia a la plantilla mediante un mensaje de plantilla.",
  "ccmessaging.multimedia.download.office.risktips":"Está a punto de descargar documentos de Office, que pueden suponer riesgos para la seguridad. Se recomienda realizar operaciones antivirus y habilitar la configuración de seguridad del software de Office.",
  "ccmessaging.chat.multimedia.documentUploadRiskTips":"Este documento es utilizado por los agentes para enviarlo a los usuarios. No cargue archivos que contengan riesgos de seguridad.",
  "ccmessaging.config.names.channels.web": "Web",
  "ccmessaging.config.names.channels.instagram": "Instagram",
  "ccmessaging.config.names.channels.telegram": "Telegram",
  "ccmessaging.config.names.channels.whatsapp": "WhatsApp",
  "ccmessaging.config.names.channels.line": "LINE",
  "ccmessaging.config.names.channels.wechat": "WeChat",
  "ccmessaging.config.names.channels.facebook": "Facebook",
  "ccmessaging.config.names.channels.twitter": "X (Twitter)",
  "ccmessaging.config.names.channels.rcs": "5G RCS",
  "ccmessaging.config.names.channels.email": "Email",
  "ccmessaging.config.names.channels.sms": "SMS",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg":" WhatsApp mensaje interactivo",
  "ccmessaging.chat.whatsapp.interactiveMsgNamePlaceholder":"Ingrese el nombre del mensaje interactivo",
  "ccmessaging.chat.whatsapp.interactiveMsgName":"Nombre del mensaje",
  "ccmessaging.chat.whatsapp.interactiveMsgTitle":"Titulo",
  "ccmessaging.chat.whatsapp.interactiveMsgDescription":"Descripción del mensaje",
  "ccmessaging.chat.whatsapp.interactiveMsgDescriptionPlaceholder":"Ingrese la descripción del mensaje interactivo",
  "ccmessaging.chat.whatsapp.interactiveMsgType":"Tipo de mensaje",
  "ccmessaging.chat.whatsapp.orgName":"Nombre de la organización",
  "ccmessaging.chat.chatcard.queryInteractiveMsg":"Error al consultar mensajes interactivos",
  "ccmessaging.chat.whatsapp.interactiveType.buttons":"Mensaje de botón",
  "ccmessaging.chat.whatsapp.interactiveType.list":"Lista de mensajes",
  "ccmessaging.chat.whatsapp.delete.interactiveMsg":"Eliminar un mensaje interactivo",
  "ccmessaging.chat.whatsapp.willdeleteinteractiveMsg":"¿Está seguro de que desea eliminar este mensaje interactivo?",
  "ccmessaging.chat.whatsapp.interactiveTypes":"Tipo de mensaje interactivo",
  "ccmessaging.chat.whatsapp.interactiveMsgTitlePlaceholder":"Ingrese el contenido del título interactivo",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContent":"Contenido del cuerpo",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContentPlaceholder":"Ingrese el contenido del cuerpo",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContent":"Contenido del pie de página",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContentPlaceholder":"Ingrese el contenido del pie de página",
  "ccmessaging.chat.whatsapp.interactiveMsg.pleaseChoose":"Por favor seleccione",
  "ccmessaging.chat.whatsapp.interactiveMsg.attachments":"Attachments",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.create":"Nuevo mensaje interactivo",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.update":"Modificar mensajes interactivos",
  "ccmessaging.chat.whatsapp.interactiveType.list.title":"Lista",
  "ccmessaging.chat.whatsapp.interactiveType.list.titlePlaceholder":"Por favor, introduzca el título de la lista",
  "ccmessaging.chat.whatsapp.interactiveMsg.openRow.info":"El título del grupo está vacío y no se puede establecer ninguna opción",
  "ccmessaging.chat.whatsapp.interactiveType.section.titlePlaceholder":"Ingrese el título del grupo",
  "ccmessaging.chat.whatsapp.interactiveType.row.titlePlaceholder":"Por favor, introduzca el título de la opción",
  "ccmessaging.chat.whatsapp.interactiveType.row.descPlaceholder":"Por favor, introduzca la descripción de la opción",
  "ccmessaging.chat.whatsapp.interactiveMsg.sectionTitle":"Ingrese el título de la lista",
  "ccmessaging.chat.whatsapp.interactiveMsg.queryInteractiveError":"Error al consultar mensajes interactivos",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError":"No se pudo guardar el mensaje interactivo. Después de agrupar la lista, la opción de agrupación no puede estar vacía",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.duplicateName":"Error al guardar. El nombre del mensaje interactivo ya existe",
  "ccmessaging.chat.whatsapp.interactiveMsg.noHeader":"Ninguno",
  "ccmessaging.chat.whatsapp.interactiveMsg.headerContent":"Contenido del título",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.buttons":"Error al guardar el mensaje interactivo. No hay botón establecido",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.lists":"Error al guardar el mensaje interactivo. No se establece ninguna lista",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.interactiveMsg":"Este recurso ha sido referenciado por un mensaje interactivo de WhatsApp y no se puede eliminar. Nombre del mensaje interactivo:",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.multiMedia.notExist":"Error al guardar. El recurso multimedia no existe",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError.repeateButtonContent":"No se pudo guardar. El contenido del botón debe ser único",
  "ccmessaging.chat.case.title":"Título del caso",
  "ccmessaging.chat.case.type":"Tipo de caso",
  "ccmessaging.chat.case.callReason":"Motivo de la llamada",
  "ccmessaging.chat.case.puzzled":"perplejo",
  "ccmessaging.chat.case.calm":"calma",
  "ccmessaging.chat.case.happy":"feliz",
  "ccmessaging.chat.case.upset":"disgustado",
  "ccmessaging.chat.case.surprise":"sorpresa",
  "ccmessaging.chat.case.pleasant":"agradable",
  "ccmessaging.chat.case.angry":"enfurecido",
  "ccmessaging.chat.case.little.angry":"un poco de ira",
  "ccmessaging.chat.case.very.angry":"muy loco",
  "ccmessaging.chat.case.very.knowledgeId":"1228840300949016576",
  "ccmessaging.chat.case.complaint.yes":"Sí",
  "ccmessaging.chat.case.complaint.shi":"Sí",
  "ccmessaging.chat.degree.view":"Cliente 360 grados",
}
