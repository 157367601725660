<template>
  <div class="oifde-page-with-tab card-type">
    <div class="oifde-page-with-tab-body" :style="initStyle">
      <div class="oifde-page-with-tab-header">
        <slot name="header"></slot>
      </div>
      <div class="oifde-page-with-tab-main" ref="screenMain">
        <slot name="main"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'oifde-page-with-tab',
  data() {
    return {}
  },
  computed: {
    initStyle() {
      // 48-头部title；40-tab；72-分页； 56-返回头  32-选中信息；3 边距
      let subtractHeight = 48 + 40 + 3 + 56 + 3 + 32
      if(this.isApiBuilderDeployment){
        subtractHeight -= 40
      }
      let styleText = ''
      return `height: calc(100vh - ${subtractHeight}px);${styleText}`;
    }
  }
}
</script>

<style lang="less" scoped>
.oifde-page-with-tab {
  display: flex;
  flex-direction: column;

  &.card-type {
    padding: 16px;
    height: unset;
  }

  &-body {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  }

  &-main {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
<style lang="less">
.oifde-page-with-tab {
  .oifde-tab-wrap {
    .el-tabs {
      border-radius: 8px 8px 0 0 !important;
    }
    .el-tabs__item {
      height: 48px !important;
      line-height: 48px !important;
    }
  }
}
</style>
