export default {
  'isales.specialday.title.speclistmanage.moveblacklists': 'Transfert liste noire',
  'isales.manual.task.survey.questiontotal': 'Total {0} questions',
  'isales.agent.button.search': 'Requête',
  'isales.taskresult.title.chooseBeginTime': 'Veuillez sélectionner l\'heure de début',
  'isales.busi.result.title.description': 'Descriptif',
  'isales.manual.title.inputresult': 'Saisissez un résultat d\'appel sortant',
  'isales.taskresult.title.retryTime': 'tentatives d\'appel',
  'isales.import.filter.message.busiResultIsNull': 'Le résultat commercial ne peut pas être vide',
  'isales.appointment.title.task': 'Associer une tâche d\'appel sortant',
  'isales.training.forwardNumber': 'Numéro de transfert',
  'isales.file.message.SK.placeholder': 'Entrez un SK',
  'isales.statistics.field.abandonmentRateDescription': 'Taux d\'abandon',
  'isales.tasktemplate.field.templateName': 'Nom du modèle',
  'isales.taskstatistics.placeholder.calling': 'Appel \: {0}',
  'isales.manualdata.title.call': 'Appeler',
  'isales.manual.title.result': 'Résultat',
  'isales.taskmanagement.title.createTask': 'Créer',
  'isales.agent.pageurls.success': 'Succès',
  'isales.management.placeholder.calledNo': 'Entrez un numéro appelé',
  'isales.datasourcemanagement.message.dbIp': 'Le format de l\'adresse IP de la base de données est incorrect',
  'isales.data.title.tableTitle': 'Liste de numéros',
  'isales.data.prop.anonymType.countError': 'Un maximum de 10 attributs de type de chiffrement peuvent être définis',
  'isales.blacklist.title.validityTime': 'Durée de validité (heure)',
  'isales.task.promotion.updateSuccess': 'La tâche de marketing multimédia est modifiée avec succès',
  'isales.task.promotion.baseInfo': 'Informations de base',
  'isales.file.title.rule.property.row': 'Colonne d\'attribut',
  'isales.tasklogic.field.callturntype': 'Mode rond',
  'isales.specialday.title.holidayName': 'Date spéciale Nom',
  'isales.agent.label.maxWaitTimeSpan': 'La valeur varie de 0 à 60000',
  'isales.appointment.message.adjustmentEmpty': 'Aucune donnée d\'appel sortant réservée valide n\'est sélectionnée',
  'isales.tasklogic.field.callInterval': 'Intervalle(s) d\'appel',
  'isales.taskmanagement.field.answer': 'Texte de réponse',
  'isales.import.message.failnum': 'Nombre d\'enregistrements ayant échoué',
  'isales.task.message.startSuccess': 'La tâche d\'appel sortant est démarrée avec succès',
  'isales.appointment.title.createAppoint': 'Créer un appel sortant réservé',
  'isales.agent.message.agentMsgException': 'Modifiez le mot de passe initial de l\'agent et synchronisez le mot de passe avec l\'agent',
  'isales.file.title.username': 'Nom d\'utilisateur',
  'isales.file.message.rule.success': 'La règle est modifiée avec succès',
  'isales.specialday.message.nameError': 'Le nom de la date spéciale est dans un format incorrect',
  'isales.taskmanagement.title.batchStartTask': 'Début du lot',
  'isales.taskmanagement.field.quotas': 'Limite supérieure',
  'isales.taskmanagement.field.Automatic': 'Appel sortant automatique',
  'isales.survey.querydetail': 'Résult. sond',
  'isales.import.message.repeatnum': 'Nombre d\'enregistrements en double',
  'isales.file.title.delete.fail': 'Impossible de supprimer les informations du serveur',
  'isales.agent.message.skillConfFail': 'Impossible de configurer la file d\'attente de compétences',
  'isales.manual.field.callingtask': 'Tâche d\'appel',
  'isales.task.promotion.promotionType': 'Type de média',
  'isales.taskresult.title.choosebusiresult': 'Veuillez sélectionner le résultat de l\'entreprise',
  'isales.common.message.checkpositiveinteger': 'Saisissez un entier positif au format correct',
  'isales.datasourcemanagement.field.backdbpass': 'Arrière-DataSource-PassWord',
  'isales.taskresult.field.notCheckIn': 'L\'agent n\'a pas signé',
  'isales.data.prop.isPushAgent.countError': 'Un maximum de 15 attributs peuvent être définis pour être poussés vers un agent',
  'isales.manual.message.lock.success': 'Les données d\'appel sortant sont verrouillées avec succès',
  'isales.file.title.serverInformation': 'Informations sur le serveur',
  'isales.taskresult.field.failsFailedRouteSkillQueue': 'Impossible d\'acheminer la file d\'attente de compétences',
  'isales.work.message.dategt7days': 'L\'intervalle de temps ne peut excéder sept jours',
  'isales.taskinfo.title.accesscodedesc': 'Description du code d\'accès',
  'isales.management.message.taskIdEmpty': 'L\'indicateur de tâche d\'appel sortant est vide',
  'isales.datasourcemanagement.field.backdbdriver': 'Retour DataSource Pilote',
  'isales.management.message.templateAnalyse': 'Par modèle',
  'isales.file.message.AK.placeholder': 'Entrez un AK',
  'isales.agent.title.skillModify': 'Modifier la file d\'attente de compétences',
  'isales.taskinfo.message.batchUpdate.paramsNull': 'Modifiez le paramètre à modifier',
  'isales.specialday.field.Wednesday': 'Mercredi',
  'isales.taskmanagement.field.taskName': 'Nom de la tâche',
  'isales.taskmanagment.title.obs.viewstatistics': 'Données de surveillance obtenues par OBs',
  'isales.business.result.searchChildrenName': 'Entrez un nom de résultat de sous-entreprise',
  'isales.task.message.NoPauseTask': 'Aucune tâche répondant aux conditions n\'est disponible',
  'isales.blacklist.placeholder.restrictTimes': 'Entrez le nombre maximum d\'appels',
  'isales.agent.field.newpassword': 'Nouveau mot de passe',
  'isales.agent.message.refreshSkillException': 'Une erreur s\'est produite lors de la mise à jour de la file d\'attente de compétences',
  'isales.task.promotion.sendLimit': 'Limite d\'envoi quotidienne',
  'isales.taskstatistics.message.Unit.Piece': 'Unité\: numéro',
  'isales.taskresult.title.userAccount': 'Initié par',
  'isales.task.promotion.deleteSuccess': 'La tâche de marketing multimédia est supprimée avec succès',
  'isales.specialday.message.weeklyRepeat': 'Dupliquer la période de date spéciale',
  'isales.file.title.bucket': 'Seau',
  'isales.taskstatistics.field.feedBackStatistics': 'Statistiques de rétroaction',
  'isales.taskinfo.message.input': 'Caractères non valides',
  'isales.management.title.setResultCode': 'Veuillez définir le code de résultat',
  'isales.agent.contact.clicktodial': 'cliquez pour appeler',
  'isales.specialday.field.byDate': 'Par date',
  'isales.rbtresult.field.longtimemissed': 'long temps aucun appel',
  'isales.taskinfo.field.audioAgentType': 'audio',
  'isales.taskinfo.field.videoAgentType': 'Vidéo',
  'isales.taskinfo.field.mediaAgentType': 'Multimédia',
  'isales.taskinfo.field.versatileAgentType': 'Polyvalent',
  'isales.taskresult.field.failsNotintheservicearea': 'Le téléphone est hors service',
  'isales.file.title.editserver': 'Modifier le serveur',
  'isales.agent.label.information': 'Informations',
  'isales.import.message.template.dataNum': 'Un maximum de {0} enregistrements de données peut être importé à la fois',
  'isales.file.title.SK': 'SK',
  'isales.taskstatistics.field.notcompletedcalls': 'En attente',
  'isales.specialday.field.Sunday': 'Dimanche',
  'isales.taskcalllogic.message.typeConflictWhenDataTypeSame': 'Une stratégie dont le type de numéro est le même que celui de la stratégie existante existe\, mais différentes stratégies sont configurées en fonction du résultat',
  'isales.training.startTraining.exists': 'Formation en cours. Veuillez réessayer une fois la formation terminée',
  'isales.result.displayResult': 'Résultat Explicite',
  'isales.tasklogic.field.isResultCallnoLogic': 'S\'il faut configurer en fonction du résultat',
  'isales.training.callTimeSegment': 'Segment de temps d\'appel',
  'isales.tasklogic.field.numberorder': 'Numéro de séquence',
  'isales.taskinfo.title.chooseSkill': 'Sélectionner la file d\'attente de compétences',
  'isales.work.title.reservedCalls': 'Appels réservés',
  'isales.task.promotion.promotionPolicy': 'Politique de marketing',
  'isales.manual.message.lock.failed': 'Impossible de verrouiller les données d\'appel sortant',
  'isales.result.outgoingCallResult': 'Résultat de l\'appel sortant',
  'isales.import.filter.message.busiSubStatusSame': 'Les résultats d\'une sous-entreprise pour le même résultat d\'entreprise ne peuvent pas être les mêmes',
  'isales.agent.calledroute.fail': 'Échec',
  'isales.task.title.callpolicy': 'Politique d\'appel sortant',
  'isales.tasktype.field.predict': 'Appel sortant prévu',
  'isales.blacklist.message.customer.notincall': 'Le client n\'est pas en appel',
  'isales.data.title.edit': 'Modifier',
  'isales.data.title.undoEdit': 'Deshacer Editar',
  'isales.skillqueue.agentrole.qc': 'Inspecteur qualité',
  'isales.taskmanagement.title.batchPauseTask': 'Suspendre le lot',
  'isales.taskmanagement.title.editLogic': 'Changer la logique',
  'isales.operlog.message.data.downloadfailfile': 'Le téléchargement du fichier de données a échoué',
  'isales.taskresult.field.failsCalledpartybusy': 'La fête appelée est occupée',
  'isales.taskmanagement.field.pickUpRuleLimit': 'Restrictions',
  'isales.taskresult.field.success': 'Succès',
  'isales.management.message.orderSequence': 'Ordre croissant',
  'isales.taskstatistics.field.callCompletionRate': 'Taux d\'achèvement des appels',
  'isales.outboundtime.title.set': 'Définir le temps d\'appel sortant',
  'isales.workbench.execution': 'Exécution d\'appel sortant',
  'isales.management.title.adjustment.changeTime': 'Ajusté sur',
  'isales.data.orderType.asc': 'Ascendant',
  'isales.file.message.rule.columnRepeat': 'La colonne ID d\'appel sortant\, la colonne numéro\, la colonne catégorie\, la colonne intervalle d\'appel et la colonne attribut ne peuvent pas être les mêmes',
  'isales.repeatfile.export.repeatline': 'ligne de répétition',
  'isales.agent.contact.Voice': 'Voix',
  'isales.taskresult.title.failCode': 'Cause',
  'isales.datasourcemanagement.field.backdbpass2': 'Confirmer le retour DataSource',
  'isales.taskinfo.message.callBackUrl': 'Entrez une adresse de rappel',
  'isales.manual.message.call.success': 'L\'appel sortant est passé avec succès',
  'isales.task.message.numberNotcalled': 'Enregistrements échoués \: {0}',
  'isales.agent.label.busy': 'File d\'attente occupée ou débordement',
  'isales.task.promotion.basicInfo': 'Informations de base',
  'isales.taskresult.field.failsCallrejected': 'l\'appel est rejeté',
  'isales.taskmanagement.message.crossmonth': 'l\'heure de début et l\'heure de fin ne peuvent pas s\'étendre sur plusieurs mois',
  'isales.agent.label.release': 'libération',
  'isales.task.promotion.sendGateway': 'Passerelle',
  'isales.file.title.addserver': 'Ajouter un serveur',
  'isales.taskmanagement.field.calledNo': 'Numéro appelé',
  'isales.import.message.result.tip': 'Impossible d\'exporter le résultat de la sélection différentielle',
  'isales.task.promotion.isExp': 'La tâche de marketing multimédia a expiré',
  'isales.taskresult.field.failsInvalidUser': 'Utilisateur non valide',
  'isales.file.title.addrule': 'Ajouter une règle',
  'isales.agent.label.skillpara': 'Paramètres',
  'isales.task.promotion.invalidation': 'Invalide',
  'isales.calledroute.title.selectivrflow': 'Choisissez un robot intelligent',
  'isales.taskmanagment.message.chartDataError': 'Impossible de charger le graphique',
  'isales.taskmanagement.message.timeerror': 'l\'heure de début ne peut pas être postérieure à l\'heure de fin',
  'isales.rbtresult.field.cusclose': 'l\'utilisateur appelé éteint le téléphone',
  'isales.statistics.business.beginDate': 'Date de début',
  'isales.taskcalllogic.field.notServiceArea': 'Le client n\'est pas dans la zone de service',
  'isales.specialday.title.createspecialday': 'Créer une date spéciale',
  'isales.taskmanagement.message.queryTime': 'Entrez une heure complète',
  'isales.taskmanagement.title.batchEditCallPolicy': 'Stratégie de modification des appels sortants par lots',
  'isales.manual.title.viewPreemptionResult': 'Résultat de l\'appel sortant préempté-',
  'isales.file.title.import.file.invalid': 'Impossible de vérifier le fichier',
  'isales.import.message.numberTooMany': 'Un maximum de 50000 numéros peuvent être téléchargés',
  'isales.specialday.message.enterworkno': 'Entrez un ID d\'agent',
  'isales.management.placeholder.commonIvrFlowAccessCode': 'Entrez un code d\'accès de flux IVR commun',
  'isales.training.surveyScore': 'Score total de l\'enquête',
  'isales.tasklogic.field.callturntype.numbertype': 'Par type de numéro',
  'isales.statistics.business.dategt12weeks': 'La plage hebdomadaire ne peut pas dépasser 12 semaines',
  'isales.taskresult.field.failsModemorfax': 'Un modem ou un fax est détecté',
  'isales.taskresult.field.failsResulttimesoutafterpicksup': 'Le résultat de la détection d\'appels sortants après l\'expiration des temps de décrochage de l\'ACD',
  'isales.notification.receiver.number': 'Numéro du destinataire',
  'isales.data.prop.mandatory': 'Obligatoire',
  'isales.common.title.dataReset': 'Réinitialiser',
  'isales.taskinfo.title.chooseAgent': 'Sélectionner un agent',
  'isales.manualData.message.noPickCondition': 'Veuillez définir les conditions',
  'isales.taskstatistics.field.failCount': 'Nombre d\'appels échoués',
  'isales.work.title.serviceAccount': 'Compte de service',
  'isales.data.title.importResult': 'Exporter les Résultats',
  'isales.management.message.filter': 'Filtrer les données',
  'isales.work.title.callTimeTotal': 'Durée totale de l\'appel (s)',
  'isales.agent.calledroute.extcodewarn': 'Les codes d\'extension ne peuvent contenir que des nombres',
  'isales.taskresult.field.no': 'Non',
  'isales.blacklist.message.confirmOpt': 'Êtes-vous sûr de vouloir effectuer des opérations sur le numéro de liste spéciale sélectionné ?',
  'isales.task.promotion.all': 'Tous',
  'isales.taskpolicy.field.minChangeTime': 'Durée de séjour min',
  'isales.management.title.adjustment.destAccountName': 'Nouvel exécuteur',
  'isales.manutask.title.viewresult': 'Résultat de l\'appel sortant',
  'isales.common.title.edit': 'Modifier',
  'isales.taskcalllogic.message.maxPolicyNo': 'Un maximum de cinq types de nombres autres que par défaut sont autorisés',
  'isales.common.title.save': 'Enregistrer',
  'isales.management.title.editLogic': 'Changer la logique',
  'isales.taskresult.field.failsSsit': 'Tone pour l\'envoi d\'informations dédiées',
  'isales.manualdata.title.order': 'Réserver un appel sortant',
  'isales.taskmanagement.title.endtime': 'Heure de fin',
  'isales.data.prop.isPushAgent.anonymTypeError': 'Les attributs de type de chiffrement ne peuvent pas être poussés vers un agent',
  'isales.task.field.callFlagProgress': 'Exécution',
  'isales.training.Questionnaire': 'Questionnaire de formation',
  'isales.appointment.message.notInWhiteList': 'Le numéro ne figure pas dans la liste de confiance et ne peut pas être appelé',
  'isales.statistics.business.byDay': 'Rapport quotidien',
  'isales.file.title.rule.basic': 'Informations de base',
  'isales.agent.calledroute.success': 'Succès',
  'isales.taskinfo.message.batchEditCallLogic.differentCallModeExist': 'Les logiques d\'appel sortant ne peuvent pas être modifiées par lots pour les tâches avec différents modes d\'appel',
  'isales.work.title.outboundCallTask': 'Tâche d\'appel sortant',
  'isales.work.title.multimediaManage': 'Gestion du marketing multimédia',
  'isales.manual.message.task.isnorunning': 'La tâche d\'appel sortant en cours n\'est pas dans l\'état Exécution',
  'isales.management.message.blackCalledNo': 'Le numéro appelé est dans la liste de blocage et ne peut pas être ajouté',
  'isales.business.result.children': 'Sous-statut',
  'isales.taskresult.field.failsUnknownError': 'Erreur inconnue',
  'isales.statistics.business.confirm': 'Remerciements',
  'isales.marketing.notification': 'Marketing multimédia',
  'isales.agent.contact.audioclicktodial': 'Clic vocal pour composer',
  'isales.outboundtime.title.endTime': 'Heure de fin',
  'isales.statistics.business.search': 'Rechercher',
  'isales.taskresult.field.failsFailedAgentIsBusy': 'l\'agent n\'est pas dans l\'état inactif',
  'isales.file.title.rule.data': 'Nombre de données',
  'isales.taskpolicy.field.connRateMethod': 'Algorithme pour le taux de connexion des appels sortants',
  'isales.work.title.completeNum': 'Enquêtes complétées',
  'isales.busi.result.message.lastrecord': 'Pleanse a défini le résultat commercial du dernier sortant en premier',
  'isales.manual.title.viewPredictionResult': 'Résultat d\'appel sortant prévu-',
  'isales.calledroute.button.finish': 'Finition',
  'isales.taskmanagement.title.deleteSuccess': 'La tâche est supprimée avec succès',
  'isales.management.message.sessionIdFormatIncorrect': 'l\'identifiant de session ne peut contenir que des chiffres',
  'isales.agent.label.operate': 'Opération',
  'isales.manual.field.canBeCallCount': 'Appels disponibles',
  'isales.language.label.language': 'Veuillez sélectionner une langue',
  'isales.task.title.complete': 'Finition',
  'isales.file.title.port.placeholder': 'Entrez le numéro de port du serveur',
  'isales.enum.workSpace': 'Établi de travail sortant',
  'isales.manual.message.data.checklock.error': 'Les données d\'appel sortant en cours ne sont pas verrouillées ou ont été verrouillées par un autre agent',
  'isales.task.promotion.isSendNow': 'Envoyer immédiatement',
  'isales.management.message.createSuccess': 'Les données d\'appel sortant sont créées avec succès',
  'isales.taskresult.field.failsSittone': 'Ton d\'information spéciale',
  'isales.blacklist.title.deleteSuccess': 'Succès',
  'isales.rbtresult.field.calledoutarea': 'l\'appelé n\'est pas dans la zone de service',
  'isales.taskresult.field.failsUserrejection': 'l\'appel est rejeté',
  'isales.management.message.filterNo': 'Non',
  'isales.task.promotion.endTime': 'Heure de fin',
  'isales.manual.message.releaseData': 'Sélectionnez les données d\'appel sortant à publier',
  'isales.notification.receiver.address.placeholder': 'Entrez l\'adresse du destinataire',
  'isales.audio.title.audiotime': 'Durée (HH\:mm\:ss)',
  'isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist': 'Les logiques d\'appel sortant ne peuvent pas être modifiées par lots pour les tâches avec différents types de stratégie',
  'isales.statistics.business.date': 'Date',
  'isales.agent.field.curcountpassword': 'Mot de passe actuel',
  'isales.sendStatus.field.success': 'Succès',
  'isales.task.promotion.receiver': 'Destinataire',
  'isales.agent.label.skillQueue': 'File d\'attente de compétences \:',
  'isales.agent.label.accountName': 'Nom de l\'agent',
  'isales.taskresult.message.details': 'Détails',
  'isales.notification.receiver.delete.variable': 'Configurez l\'attribut',
  'isales.training.sampleTotal': 'Taille totale de l\'échantillon',
  'isales.manual.field.callednumber': 'Numéro appelé',
  'isales.agent.calledroute.create.media.match': 'Le code d\'accès au système configuré par la partie appelée est incompatible avec le type de support du dispositif configuré par la partie appelée',
  'isales.task.promotion.expectedTime': 'Temps d\'envoi prévu',
  'isales.skill.label.skilldesc': 'Descriptif',
  'isales.agent.label.agentWeight': 'Poids de l\'agent',
  'isales.taskmanagement.field.intention': 'Intention reconnue',
  'isales.blacklist.message.customer.empty': 'Aucun client en appel',
  'isales.agent.calledroute.skill': 'File d\'attente de compétences',
  'isales.taskcalllogic.message.dataTypeExist': 'DataType existe déjà',
  'isales.agent.message.syncPwdFailed': 'Impossible de synchroniser le mot de passe de l\'agent',
  'isales.taskresult.field.failsFailedRouteCallToAttendant': 'Impossible de transférer l\'appel à l\'agent',
  'isales.statistics.business.description': 'Descriptif',
  'isales.tasksample.message.agentEmpty': 'Aucun objet d\'entraînement n\'est sélectionné',
  'isales.agent.label.playVoice': 'Jouer la tonalité d\'attente',
  'isales.tasklogic.field.turn': 'Rond',
  'isales.datasourcemanagement.field.backDbdriver': 'BackUp Nom du DataSource',
  'isales.datasourcemanagement.field.backdbType': 'Type de DataSource arrière',
  'isales.agent.message.saveSuccess': 'La configuration de l\'agent est enregistrée avec succès',
  'isales.file.title.rule.desc.placeHolder': 'Entrez une description de règle',
  'isales.management.message.createTaskstatusError': 'Impossible de créer des données pour la tâche d\'appel sortant car elle n\'est pas dans l\'état brouillon ou suspendu',
  'isales.data.prop.propCodeExists': 'Le code d\'attribut existe déjà',
  'isales.tasklogic.message.busiresultpolicyIncomplete': 'Les informations de politique de résultat d\'entreprise sont incomplètes',
  'isales.management.title.import': 'Importer des données d\'appel sortant',
  'isales.business.result.delete.error': 'Aucun résultat commercial n\'est sélectionné',
  'isales.file.title.rule.validator.server': 'La configuration du serveur est incorrecte',
  'isales.specialday.field.Friday': 'Vendredi',
  'isales.data.prop.status.published': 'Publié',
  'isales.manual.message.title': 'Entrez un titre',
  'isales.speclist.blacklist.message.moveSuccess': 'Numéro transféré sur la liste noire avec succès',
  'isales.taskresult.field.failsRecordcanceled': 'Lorsque l\'OBS passe à l\'état de veille\, les enregistrements en cours sont annulés',
  'isales.datasourcemanagement.message.frequentOperation': 'Opérations fréquentes. Veuillez patienter',
  'isales.management.message.notification.download': 'Télécharger le modèle de données marketing',
  'isales.datasourcemanagement.field.backdbUserName': 'Arrière-DataSource-UserName',
  'isales.speclistmanage.title.calledNo': 'Numéro',
  'isales.business.result.search': 'Rechercher',
  'isales.taskpolicy.message.saveSuccess': 'La politique d\'appel sortant est configurée avec succès',
  'isales.import.message.ruleExtProp': 'Les attributs personnalisés ont été activés. Configurez d\'abord la règle de mappage d\'importation\, puis cliquez sur Importer par règle',
  'isales.busi.result.item.unset': 'Non défini',
  'isales.import.message.upload.fail': 'Impossible de télécharger le fichier',
  'isales.work.title.callTimeAvg': 'Durée moyenne d\'appel (s)',
  'isales.data.prop.status': 'Statut',
  'isales.manual.message.call.update.error': 'Impossible de mettre à jour le résultat de l\'appel sortant',
  'isales.common.title.operation': 'Opération',
  'isales.statistics.business.warningFilterMax': 'Un maximum de {0} indicateurs peut être sélectionné',
  'isales.business.result.edit': 'Modifier le résultat de l\'entreprise',
  'isales.specialday.title.editSpecialday': 'Modifier la date spéciale',
  'isales.agent.label.waitVoice': 'Ton d\'attente',
  'isales.speclistmanage.title.operation': 'Fonctionnement',
  'isales.appointment.message.callFail': 'Échec',
  'isales.taskresult.field.failsAgentdevicetimesout': 'l\'occupation de l\'agent se termine',
  'isales.task.message.feedBackRate': 'Taux de rétroaction',
  'isales.management.message.calledNoOneCategory': 'Catégorie du premier numéro appelé',
  'isales.taskresult.field.failsNoanswer': 'l\'appelé ne répond pas à l\'appel',
  'isales.data.message.addNumberUpperLimit': 'Un maximum de 10 numéros peut être ajouté',
  'isales.file.title.rule.name': 'Nom de la règle',
  'isales.marketing.OutgoingCall': 'Télémarketing',
  'isales.audio.title.confirmedResult': 'Résultats de reconnaissance des tonalités Ringback',
  'isales.tasktemplate.message.saveFail': 'Impossible de configurer le modèle d\'appel sortant',
  'isales.data.prop.propType': 'Type d\'attribut',
  'isales.taskresult.title.inputSessionId': 'Entrez le numéro de session',
  'isales.task.promotion.pauseFaied': 'Impossible de suspendre la tâche de marketing multimédia',
  'isales.calledroute.button.cancel': 'Annuler',
  'isales.tasktype.field.intelligence': 'Appel sortant intelligent',
  'isales.business.result.code': 'Code de résultat d\'entreprise',
  'isales.taskresultexport.message.downloadExportdatafailinfo': 'La vérification a échoué. Impossible de trouver les données du fichier exporté',
  'isales.taskmanagement.title.start': 'Démarrer',
  'isales.data.prop.createDataPropFail': 'Impossible de créer l\'attribut',
  'isales.management.title.adjustment.orgAccountName': 'Ancien exécuteur',
  'isales.datasourcemanagement.field.addDataSource': 'Ajouter un DataSource',
  'isales.taskstatistics.field.incomplete': 'Clients en attente d\'appels',
  'isales.management.message.callNoInBlackList': 'Impossible d\'appeler le numéro car il est bloqué',
  'isales.callNo.field.type': 'Type de numéro',
  'isales.tasktype.field.auto': 'IVR Appel sortant IVR commun',
  'isales.task.promotion.messageTemplate': 'Modèle de notification',
  'isales.tasklogic.field.substate': 'Sous-état',
  'isales.specialday.title.deleteSuccess': 'Succès',
  'isales.agent.message.deleteSkillConfFail': 'Impossible de supprimer les informations de la file d\'attente de compétences',
  'isales.statistics.business.busiResult': 'Résultats de l\'entreprise',
  'isales.operlog.message.data.downloadtemplate': 'Télécharger le modèle d\'importation de données',
  'isales.agent.button.batchmodify': 'Changement de lot',
  'isales.management.message.deleteTaskstatusError': 'Impossible de supprimer la tâche d\'appel sortant car elle n\'est pas dans l\'état de brouillon ou dans l\'état de pause',
  'isales.task.promotion.selectSendTemplate': 'Sélectionnez un modèle',
  'isales.taskresult.field.failsCpp': 'Appelé renvoi par accrochage',
  'isales.taskresult.field.failsAns5': 'Téléphone texte (ANS5)',
  'isales.taskresult.field.failsAns4': 'Téléphone texte (ANS4)',
  'isales.taskresult.field.failsAns3': 'Téléphone texte (ANS3)',
  'isales.taskresult.field.failsAns2': 'Téléphone texte (ANS2)',
  'isales.taskresult.field.failsAns1': 'Téléphone texte (ANS1)',
  'isales.taskinfo.message.batchEditCallLogic.selectEmpty': 'l\'opération n\'est pas valide car aucune région n\'est sélectionnée. Sélectionnez une région',
  'isales.operlog.message.downloadSurveyExportFile': 'Exportation des questions de formation',
  'isales.taskinfo.field.description': 'Description de la tâche',
  'isales.task.promotion.startSuccess': 'La tâche de marketing multimédia est publiée avec succès',
  'isales.datasourcemanagement.field.vdnId': 'VDNId',
  'isales.agent.message.modifyPwdFailed': 'Impossible de modifier le mot de passe de l\'agent',
  'isales.agent.message.queryCallCenterFail': 'Impossible d\'interroger les données du centre d\'appels',
  'isales.taskmanagement.title.clicktorefresh': 'Cliquez pour actualiser',
  'isales.taskstatistics.message.no.busiResult': 'Aucun résultat commercial n\'est marqué',
  'isales.blacklist.message.updateSuccess': 'Modifier la remarque Succès',
  'isales.data.filed.noResult': 'Non',
  'isales.management.title.callbot': 'Chatbot d\'appel sortant',
  'isales.blacklist.message.paramEmpty': 'Sélectionnez un numéro de liste bloquée',
  'isales.task.statistics.obs.endTime': 'La différence entre l\'heure de début et l\'heure de fin ne peut dépasser une demi-heure',
  'isales.taskmanagement.title.pauseCount': 'Un maximum de 30 tâches d\'appel sortant peuvent être suspendues à la fois',
  'isales.task.promotion.saveFailed': 'Impossible d\'enregistrer la tâche de marketing multimédia',
  'isales.taskpolicy.message.input.minAvgCallTime': 'La durée moyenne minimale d\'appel ne peut pas être supérieure à la durée maximale. Durée moyenne des appels',
  'isales.manual.field.callflag.return': 'Retour',
  'isales.operlog.message.data.downloadrepeatfile': 'Téléchargez le fichier de données en double',
  'isales.manual.message.release.failed': 'Impossible de libérer les données d\'appel sortant',
  'isales.taskmanagement.field.zip.input.password': 'Entrez au moins huit caractères et un maximum de douze caractères qui contiennent des chiffres\, des lettres et des caractères spéciaux\, mais ne contiennent pas de /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'isales.file.title.SK.again': 'Confirmer SK',
  'isales.management.message.black.download': 'Téléchargement d\'un modèle de liste de blocage',
  'isales.taskInfo.message.skillAlreadyExists': 'La file d\'attente de compétences existe déjà',
  'isales.agent.label.maxAgentWrapupTime': 'La valeur varie de 0 à 3600',
  'isales.file.title.AK': 'AK',
  'isales.notification.receiver.tips': 'Le destinataire doit être unique',
  'isales.agent.addblocklist.calldatatype': 'Type de client',
  'isales.audio.title.asrTransResult': 'Résultats de la transcription de la parole',
  'isales.taskpolicy.message.rangeAutoCallTime': 'La valeur doit être comprise entre {0} et {1}',
  'isales.manual.field.result.success': 'Succès',
  'isales.taskinfo.message.endTimeError': 'l\'heure de fin doit être postérieure à l\'heure actuelle',
  'isales.work.message.agentOrTaskChange': 'Sélectionnez au moins une tâche d\'appel sortant ou un ID d\'agent',
  'isales.task.promotion.selectSendGateway': 'Sélectionnez une passerelle',
  'isales.import.filter.button.readyCall': 'Filtrer les données des appels non initiés',
  'isales.statistics.business.monthLessThan': 'Le mois de fin d\'année ne peut pas être antérieur au mois de début d\'année',
  'isales.taskresult.field.failsAgentDisconnection': 'l\'agent est déconnecté',
  'isales.taskresult.field.failsInvalidDevice': 'Périphérique non valide',
  'isales.repeatfile.export.repeatCalledNo': 'répétitionCalledNon',
  'isales.taskmanagment.title.callMonitor.tips': 'Mise à jour automatique toutes les 15 minutes',
  'isales.callquality.statistics.message.afterNow': 'Vous ne pouvez pas sélectionner une heure antérieure à l\'heure de début de la tâche ou postérieure à la journée courante \!',
  'isales.taskinfo.title.ivrFlow': 'Chatbot intelligent',
  'isales.appointment.title.appointTime': 'Heure d\'appel réservée',
  'isales.manual.message.release.success': 'Les données d\'appel sortant sont libérées avec succès',
  'isales.taskcalllogic.message.choosePolicy': 'Veuillez sélectionner au moins une stratégie de traitement des résultats',
  'isales.business.result.message.add.tips': 'Le résultat de l\'entreprise n\'est pas défini',
  'isales.taskresult.field.failsCalledOnHook': 'l\'agent s\'accroche à un appel',
  'isales.taskresult.field.sipAccount': 'Numéro de téléphone logiciel',
  'isales.data.prop.editDataPropFail': 'Impossible de modifier l\'attribut',
  'isales.taskinfo.field.callNo': 'Numéro de l\'appelant',
  'isales.notification.receiver': 'Destinataire',
  'isales.agent.button.restAgentPwd': 'Changer le mot de passe de l\'agent',
  'isales.work.title.totalOutgoingCalls': 'Appels sortants',
  'isales.management.message.SelectNumber': 'Sélectionnez un numéro',
  'isales.datasourcemanagement.message.noObsINFO': 'l\'DataSource d\'appel sortant n\'est pas configurée ou mal configurée',
  'isales.taskresult.field.failsFsk': 'Téléphone texte (FSK)',
  'isales.blacklist.message.deleteSuccess': 'Le numéro est supprimé avec succès de la liste de blocage',
  'isales.manual.task.survey.previousquestion': 'Question précédente',
  'isales.agent.label.answerType': 'Mode de réponse de l\'agent',
  'isales.management.title.adjustment.remark': 'Remarques',
  'isales.taskresult.field.failsModemdetected': 'Un modem est détecté',
  'isales.taskresult.field.failedtopause': 'Impossible de suspendre la tâche car elle est déjà dans l\'état suspendu',
  'isales.import.message.closeExtProp': 'Les attributs personnalisés doivent être désactivés pour l\'importation par modèle. Cliquez sur le bouton Configurer l\'attribut à côté de Importer pour désactiver et activer les attributs personnalisés',
  'isales.task.message.startFail': 'Impossible de démarrer la tâche d\'appel sortant',
  'isales.tasktemplate.message.releasesuccess': 'Publication réussie',
  'isales.taskstatistics.field.totalNumberOfUsers': 'Nombre total d\'utilisateurs',
  'isales.tasklogic.field.default': 'default',
  'isales.taskmanagement.field.outboundType': 'Type d\'appel',
  'isales.agent.placeholder.agentdesc': 'Saisissez un nom d\'agent',
  'isales.audio.content.calltype': 'Appel vocal sortant',
  'isales.result.outgoingCall.failCode': 'Code de résultat',
  'isales.training.callTime': 'Segment de temps d\'appel',
  'isales.taskmanagement.title.startCount': 'Un maximum de 30 tâches d\'appel sortant peuvent être lancées à la fois',
  'isales.import.filter.button.notification.isPushCom': 'Filtrer les données en cours de poussée',
  'isales.task.promotion.remark': 'Cause d\'échec',
  'isales.agent.calledroute.createsuccess': 'ajouté avec succès',
  'isales.taskcalllogic.busiresultpolicy.callnext': 'Appelez le prochain numéro',
  'isales.agent.message.warning': 'Avertissement',
  'isales.import.title.statusExecuting': 'Exécution',
  'isales.agent.message.modifySkillConfSuccess': 'La file d\'attente de compétences est modifiée avec succès',
  'isales.taskcalllogic.field.other': 'Autre',
  'isales.taskresultexport.title.downloadConfirm': 'OK',
  'isales.task.title.basicInfo': 'Informations de base',
  'isales.task.field.callFail': 'Échec',
  'isales.rbtresult.field.emptynumber': 'Numéro vide',
  'isales.blacklist.title.restrictTimes': 'Nombre maximum d\'appels',
  'isales.taskresult.title.chooseCallFlag': 'Veuillez sélectionner le statut',
  'isales.agent.message.redislocknull': 'Impossible d\'acquérir le verrouillage Redis',
  'isales.file.message.isDeleteFile': 'Supprimer le fichier source',
  'isales.taskstatistics.field.calling': 'Appeler',
  'isales.calledroute.title.selectskill': 'Sélectionner la file d\'attente de compétences',
  'isales.manual.title.fail.reason': 'Cause d\'échec',
  'isales.specialday.field.byYear': 'Répéter par année',
  'isales.rbtresult.field.tollcaller': 'Restrictions relatives aux appels internationaux',
  'isales.taskresult.field.failsDisconnection': 'Échec de l\'appel',
  'isales.management.message.calledNoTwoCategory': 'Deuxième catégorie de numéro d\'appel',
  'isales.taskcalllogic.field.shutdown': 'Arrêt client',
  'isales.task.promotion.promotionStatus': 'Statut de commercialisation',
  'isales.specialday.title.holidayType': 'Type de date spéciale',
  'isales.taskcalllogic.busiresultpolicy.stopcall': 'Arrêter l\'appel',
  'isales.taskmanagement.message.password.not.same': 'Les deux mots de passe sont incohérents',
  'isales.failfile.export.failReason': 'failRaison',
  'isales.tasktemplate.message.pauseFail': 'La suspension a échoué',
  'isales.task.promotion.retryTimes': 'Temps de réessais',
  'isales.taskmanagement.title.batchEditCallLogic': 'Lot Modifier la logique d\'appel sortant',
  'isales.management.message.import.fileName': 'Nom du fichier',
  'isales.file.message.deleteruletips': 'Êtes-vous sûr de vouloir supprimer la règle de mappage d\'importation ?',
  'isales.appointment.message.tasknotrun': 'La tâche d\'appel sortant en cours n\'est pas démarrée. l\'appel est interdit',
  'isales.timeImport.message.fail': 'Impossible d\'ajouter la configuration d\'importation planifiée',
  'isales.agent.message.deleteSkill': 'Supprimer la file d\'attente de compétences',
  'isales.management.title.notification.import': 'Importer des données marketing',
  'isales.specialday.field.beginDate': 'Date de début',
  'isales.task.message.timeIncompletes': 'Le segment temporel d\'un seul appel sortant n\'est pas complet',
  'isales.taskresult.field.failsDtmf': 'Téléphone texte (DTMF)',
  'isales.taskresultexport.message.endtime': 'Heure de fin',
  'isales.taskcalllogic.message.resultTips': 'Il ne peut y avoir qu\'une seule stratégie pour un seul résultat',
  'isales.tasklogic.field.callbyorder': 'En séquence',
  'isales.datasourcemanagement.field.dbdriver': 'Pilote DataSource',
  'isales.taskresult.field.failsUserhangsupinthequeue': 'Tous les agents sont occupés\, et l\'utilisateur raccroche de manière proactive',
  'isales.taskmanagement.message.afterNow': 'l\'heure de début ne peut pas être postérieure à l\'heure actuelle',
  'isales.data.prop.message.confirmDelete': 'Voulez -vous vraiment supprimer l\'attribut sélectionné ?',
  'isales.taskpolicy.field.multiSkill': 'File d\'attente de compétences hybride',
  'isales.taskresult.field.failsWaitresulttimesout': 'En attente des résultats des appels sortants sur la plate-forme',
  'isales.training.surveydetails': 'Questionnaire Statistiques Détails',
  'isales.taskmanagement.title.edit': 'Modifier',
  'isales.specialday.title.search': 'Requête',
  'isales.taskresult.field.failsSubscriberbusy': 'La fête appelée est occupée',
  'isales.survey.createTrainingTask': 'Création d\'une tâche de formation',
  'isales.taskcalllogic.field.callFailed': 'Échec de l\'appel',
  'isales.task.promotion.view': 'Voir la tâche de marketing multimédia',
  'isales.management.message.orderReverse': 'Ordre décroissant',
  'isales.specialday.placeholder.searchname': 'Saisissez un nom de date spécial',
  'isales.taskresult.field.yes': 'Oui',
  'isales.outboundtime.title.period': 'Segment de temps d\'appel sortant',
  'isales.taskmanagment.title.callMonitor': 'Surveillance des appels',
  'isales.training.answeringRecord': 'Enregistrement de réponse',
  'isales.blacklist.message.requestEmpty': 'Impossible de valider le paramètre permettant d\'ajouter le numéro à la liste de blocage',
  'isales.taskmanagment.callquality.maxConnectedRate': 'Taux de connexion maximum ',
  'isales.task.message.pauseFail': 'Impossible de suspendre la tâche d\'appel sortant',
  'isales.taskresultexport.title.download': 'Téléchargement',
  'isales.task.field.callFlagExecuted': 'À exécuter',
  'isales.data.prop.createDataPropSuccess': 'l\'attribut est créé avec succès',
  'isales.file.title.rule.number': 'Colonne de nombre',
  'isales.taskresult.field.failsAcdtimeout': 'l\'ACD time out',
  'isales.taskinfo.title.skillName': 'File d\'attente de compétences',
  'isales.label.appraise.call.quality.agent': 'Agent',
  'isales.specialday.message.deleteSuccess': 'La date spéciale est supprimée avec succès',
  'isales.manualdata.title.release': 'libération',
  'isales.work.title.surveyAnswerNum': 'Nombre de réponses',
  'isales.blacklist.title.day': 'Tous les jours',
  'isales.statistics.business.cancel': 'annule',
  'isales.audio.title.beginTime': 'Heure de début de l\'enregistrement',
  'isales.agent.calledroute.nmsdata.error': 'La synchronisation de l\'ODFS échoue\, et les données de configuration de la partie appelée ODFS sont incompatibles avec les données locales du CCM',
  'isales.taskstatus.field.waiting': 'Attente',
  'isales.task.message.saveFail': 'Impossible de configurer la tâche d\'appel sortant',
  'isales.exportdata.title.fail': 'Échec',
  'isales.tasklogic.field.enablebusiresultpolicy': 'Activer la stratégie de résultat de l\'entreprise',
  'isales.taskresult.field.failsNoresponse': 'La partie appelée ne répond pas',
  'isales.statistics.field.addHisYes': 'Oui',
  'isales.file.title.editrule': 'Modifier la règle',
  'isales.language.label.es_Es': 'Espagnol',
  'isales.failfile.export.failline': 'failLine',
  'isales.taskinfo.message.agentsEmpty': 'Aucun agent n\'est sélectionné',
  'isales.blacklist.title.remark': 'Remarques',
  'isales.manual.message.error': 'Une erreur s\'est produite lors de la mise à jour des données',
  'isales.tasklogic.title.addCallnoPolicy': 'Ajouter une stratégie de résultat',
  'isales.management.message.deleteFailed': 'Impossible de supprimer les données d\'appel sortant',
  'isales.manual.title.viewPreviewResult': 'Prévisualiser le résultat de l\'appel sortant-',
  'isales.basicinfo.title.chooseCallNo': 'Sélectionnez le numéro d\'appel',
  'isales.common.title.cancel': 'Annuler',
  'isales.speclistmanage.title.reason': 'Raison',
  'isales.calledroute.title.selectagent': 'Sélectionner un agent',
  'isales.agent.label.description': 'Descriptif',
  'isales.business.description': 'Descriptif',
  'isales.management.title.editPolicy': 'Modifier la politique',
  'isales.tasklogic.message.busiresultpolicyNoadded': 'Aucune politique de résultat d\'entreprise n\'est ajoutée',
  'isales.taskmanagement.title.addManual': 'Créer un appel sortant d\'agent',
  'isales.taskresult.field.failsUnallocatedOrIncorrectNumber': 'Inaccessible ou mauvais numéro',
  'isales.notification.configVariableTe': 'Configurer la variable de modèle',
  'isales.notification.config': 'Configuration',
  'isales.manual.field.callflag.finish': 'Terminé',
  'isales.taskmanagement.title.batchEditTaskInfo': 'Lot Modifier les informations de base',
  'isales.taskcalllogic.field.callTimeout': 'Délai d\'attente de l\'appel',
  'isales.data.title.importData': 'Importation',
  'isales.tasklogic.field.busiresultpolicy': 'Politique de résultat d\'entreprise',
  'isales.statistics.business.error': 'Erreurs',
  'isales.management.message.deleteCount': 'Un maximum de 100 enregistrements d\'appels sortants peuvent être supprimés à la fois',
  'isales.callNo.field.add': 'Ajouter',
  'isales.skillcreate.maxnumber.failed': 'Le nombre de files d\'attente de compétences locataires ne peut pas dépasser la limite supérieure',
  'isales.busi.result.item.uncertain': 'Incertain',
  'isales.business.result.deleteChildrenTips': 'Êtes-vous sûr de vouloir supprimer le résultat de la sous-entreprise?',
  'isales.task.title.nextStep': 'Suivant',
  'isales.task.promotion.sender': 'Expéditeur',
  'isales.agent.label.parameterType': 'Mode de mise en file d\'attente',
  'isales.blacklist.message.name.reg.empty': 'La règle régulière du nom est vide. Contactez l\'administrateur pour l\'ajouter',
  'isales.taskmanagement.field.taskTime': 'Durée',
  'isales.file.title.password.again': 'Confirmer le mot de passe',
  'isales.file.title.rightpath': 'Entrez un chemin d\'accès valide\, par exemple /isales/upload',
  'isales.training.tip2': 'La question est en train d\'être posée. Ne cliquez pas fréquemment',
  'isales.training.tip1': 'Le nombre de réponses a atteint la limite supérieure',
  'isales.taskresult.field.userisunleashed': 'l\'appel sur le serveur multimédia auquel l\'agent se connecte est libéré',
  'isales.management.placeholder.accesscodeDesc': 'Veuillez saisir une description du code d\'accès',
  'isales.rbtresult.field.callershutdown': 'Suspension d\'arriérés de l\'appelant / suspension unidirectionnelle',
  'isales.agent.addblocklist.numbertype': 'Type de numéro',
  'isales.agent.calledroute.search.devicedesc': 'Veuillez entrer un nom d\'appareil',
  'isales.file.server.message.notChinese': 'Les caractères chinois ne sont pas autorisés',
  'isales.taskresult.field.failsNoreason': 'Transfert DTE inconditionnel',
  'isales.training.queryQuestionnaire': 'Questionnaire',
  'isales.management.title.selectProp': 'Sélectionner un attribut',
  'isales.calledroute.message.selectcalledroute': 'Veuillez sélectionner la configuration appelée',
  'isales.manualData.message.pickUpFailed': 'Aucune donnée à extraire',
  'isales.specialday.title.speclistmanage.misjudgment': 'Erreur de jugement',
  'isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate': 'Une fois que le nombre de stratégies de numéro non par défaut dans la stratégie de résultat d\'appel sortant a été modifié\, le maximum de cinq est dépassé',
  'isales.taskresult.field.failsUnreachable': 'Abonné mobile inaccessible',
  'isales.taskmanagement.title.callRecord': 'Enregistrement d\'appel sortant',
  'isales.outboundtime.field.hour': 'Heure',
  'isales.tasktype.field.automatic': 'Appel sortant automatique',
  'isales.taskinfo.message.batchEditCallLogic.BusiResultNotification': 'Les nouvelles politiques remplaceront les politiques existantes',
  'isales.taskpolicy.field.failInterval': 'Intervalle d\'appel sortant',
  'isales.agent.calledroute.createfail': 'Impossible d\'ajouter des données locales',
  'isales.taskcalllogic.field.globalPolicy': 'Politique globale',
  'isales.taskmanagement.title.tipsBatchStartTask': 'Sélectionnez la tâche à démarrer',
  'isales.specialday.title.edit': 'Modifier',
  'isales.taskresultexport.message.downloadFail': 'Échec du téléchargement',
  'isales.file.message.isDeleteFileTips': 'Supprimer les fichiers de l\'OBS',
  'isales.agent.label.agentList': 'ID de l\'agent',
  'isales.taskcalllogic.busiresultpolicy.callcurrent': 'Appelez le numéro actuel',
  'isales.task.promotion.createTime': 'Créé le',
  'isales.result.create': 'Créer une cause d\'échec d\'appel sortant',
  'isales.label.appraise.call.quality.customerInfo': 'Informations sur les clients',
  'isales.appointment.message.adjustmentSuccess': 'l\'appel sortant réservé est ajusté avec succès',
  'isales.note.config.success': 'Succès',
  'isales.manual.task.survey.answered': 'Réponses',
  'isales.management.message.deleteSuccess': 'Les données d\'appel sortant sont supprimées avec succès',
  'isales.management.message.calledNo': 'Numéro appelé',
  'isales.agent.calledroute.accesscodewarn': 'Les codes d\'accès ne peuvent contenir que des nombres',
  'isales.management.message.sessionId': 'Numéro de session',
  'isales.agent.query.emptyError': 'Aucune donnée d\'agent valide n\'est sélectionnée',
  'isales.management.title.editTime': 'Changer l\'heure',
  'isales.skill.label.maxwaitnum': 'Max. Appels en file d\'attente',
  'isales.data.propConfig.message.isExistBackDb': 'Retour DataSource',
  'isales.callNo.field.delayTime': 'Délai(s) d\'échec d\'appel',
  'isales.common.title.fail': 'Échec',
  'isales.specialday.title.timePeriod': 'Date spéciale Heure d\'appel sortant',
  'isales.taskresult.field.failsCallwaiting': 'Appel en attente',
  'isales.blacklist.placeholder.searchname': 'Nom et prénom',
  'isales.training.voiceAverageScore': 'Score vocal moyen',
  'isales.blacklist.title.hour': 'Toutes les heures',
  'isales.tasktemplate.title.saveAsTemplate': 'Enregistrer comme modèle',
  'isales.tasktemplate.title.addAutoTemplate': 'Créer un modèle d\'appel sortant automatique',
  'isales.taskmanagement.title.inputTaskId': 'Saisissez un ID de tâche',
  'isales.result.deletetips': 'Êtes-vous sûr de vouloir supprimer la cause de l\'échec de l\'appel sortant ?',
  'isales.taskmanagement.title.selectTemplate': 'Sélectionner un modèle',
  'isales.blacklist.message.calledno.error': 'Le numéro contient des caractères non valides',
  'isales.management.message.upload': 'Téléchargement',
  'isales.taskmanagement.field.prediction': 'Appel sortant prévu',
  'isales.operlog.message.downloadAgentWorkExportFile': 'Télécharger le fichier d\'exportation de la charge de travail de l\'agent',
  'isales.taskresultexport.message.downloadFilesuffixfailinfo': 'Le téléchargement a échoué. Les informations du fichier exporté ne contiennent pas l\'extension du nom de fichier',
  'isales.data.prop.propCode': 'Code d\'attribut',
  'isales.skillqueue.button.add': 'Ajouter une file d\'attente de compétences',
  'isales.specialday.message.typeError': 'Type de date spéciale incorrect',
  'isales.data.prop.stringType.countError': 'Un maximum de 30 attributs de type chaîne de caractères peut être défini',
  'isales.statistics.business.endDate': 'Date de fin',
  'isales.taskresult.title.endTime': 'Heure de fin',
  'isales.specialday.message.updateFail': 'Impossible de modifier la date spéciale',
  'isales.manual.task.survey.nextquestion': 'Question suivante',
  'isales.manual.task.survey.submit': 'Soumettre',
  'isales.task.promotion.templateType': 'Type de modèle',
  'isales.tasklogic.message.need.orderPolicy': 'Ajoutez au moins une règle de commande personnalisée',
  'isales.manual.message.call.error': 'Une erreur s\'est produite lors du traitement du résultat de l\'appel sortant',
  'isales.taskmanagment.title.open.callMonitor': 'Surveillance des appels',
  'isales.taskresult.field.failsCallednumbernotinwhitelist': 'Le numéro n\'est pas dans la liste de confiance des appels sortants',
  'isales.specialday.title.reset': 'Réinitialiser',
  'isales.taskstatus.field.confirming': 'À confirmer',
  'isales.specialday.message.dateParseError': 'Erreur dans la conversion du type d\'heure de la date spéciale',
  'isales.manual.task.survey.answerSuccess': 'Réponse réussie\!',
  'isales.management.message.calledNoEmpty': 'Le numéro appelé est vide',
  'isales.common.title.close': 'Fermer',
  'isales.import.title.statusReady': 'À exécuter',
  'isales.taskresult.field.failsNoacdresource': 'Aucune ressource disponible sur le RVA',
  'isales.management.message.createFailed': 'Impossible de créer des données d\'appel sortant',
  'isales.busi.result.message.recordsucc': 'Le résultat commercial est enregistré avec succès',
  'isales.file.title.create': 'Ajouter',
  'isales.blacklist.title.restrictPeriod': 'Période de restriction',
  'isales.agent.message.modifyPwdSuccess': 'Le mot de passe de l\'agent est modifié avec succès',
  'isales.taskpolicy.field.pickupRateMethod': 'Algorithme pour le taux de décrochage',
  'isales.datasourcemanagement.message.syncSuccess': 'Synchroniser DataSource Réussite',
  'isales.import.message.uploadedfileNameformatvalidate': 'Nom de fichier incorrect. Sélectionnez un fichier dont le nom ne contient que des caractères chinois\, des lettres minuscules\, des chiffres ou des traits de soulignement (_)',
  'isales.agent.calledroute.isconfdelete': 'Êtes-vous sûr de supprimer?',
  'isales.taskmanagement.title.batchEditTaskInfo.manual': 'l\'appel sortant automatique et l\'appel sortant manuel ne peuvent pas être modifiés en même temps',
  'isales.taskresult.title.busidesc': 'Description de l\'entreprise',
  'isales.agent.calledroute.placeholder.skill': 'Veuillez sélectionner une file d\'attente de compétences',
  'isales.taskpolicy.field.obOverTime': 'Durée max. de réponse ',
  'isales.tasktype.field.intelligent': 'Appel sortant robot intelligent',
  'isales.management.message.import.analyseMode': 'Mode d\'analyse',
  'isales.agent.calledroute.gateway.fail': 'Impossible de synchroniser les données avec la plate-forme CTI. Vérifiez la configuration de la passerelle',
  'isales.management.message.ruleAnalyse': 'Par règle',
  'isales.taskmanagement.field.statisticsDate': 'Date de collecte des statistiques',
  'isales.taskinfo.title.chooseSurvey': 'Sélectionner le questionnaire',
  'isales.file.message.rule.dataIsNull': 'Les données numériques ne peuvent pas être vides',
  'isales.appointment.message.agentNotCalling': 'Impossible d\'ajouter un appel sortant réservé car l\'agent n\'est pas dans un appel',
  'isales.management.message.addPropTips': 'Les données personnelles ou les données sensibles doivent être cryptées\, stockées et masquées',
  'isales.taskmanagement.title.tipsBatchPauseTask': 'Sélectionnez la tâche à suspendre',
  'isales.manual.message.empty': '{0} est vide',
  'isales.training.MaxAnswerNumber': 'Nombre maximal de réponses au questionnaire',
  'isales.specialday.message.dateFormatError': 'Format de date spécial incorrect',
  'isales.file.message.endPoint.placeholder': 'Entrez une adresse',
  'isales.appointment.title.failCode': 'Cause d\'échec',
  'isales.datasourcemanagement.field.dsType.gauss': 'GAUSDB',
  'isales.taskresult.field.failsCalldatatimeout': 'Délai d\'expiration des données d\'appel',
  'isales.taskpolicy.field.utilRateMethod': 'Algorithme pour l\'utilisation de l\'agent',
  'isales.taskpolicy.field.experienceMethod': 'Algorithme de prédiction empirique',
  'isales.taskinfo.field.questionNumber': 'Nombre de questions',
  'isales.agent.calledroute.create.gateway.none': 'Le script de routage référencé par la configuration appelée n\'existe pas',
  'isales.blacklist.title.calledNo': 'Numéro appelé',
  'isales.business.result.addchildrentips': 'Un maximum de 50 résultats de sous-entreprise est autorisé dans un résultat d\'entreprise',
  'isales.specialday.title.timeSet': 'Définir l\'heure d\'appel sortant de date spéciale',
  'isales.sendStatus.field.all': 'Tous',
  'isales.taskstatus.field.callfinish': 'Terminé',
  'isales.taskmanagment.title.viewstatistics': 'Voir les statistiques',
  'isales.audio.title.endtime': 'Heure de fin',
  'isales.appointment.message.callTimeTooEarly': 'l\'heure d\'appel est antérieure à l\'heure réservée. l\'appel est interdit \!',
  'isales.agent.message.deleteSkillConfSuccess': 'La file d\'attente de compétences est supprimée avec succès',
  'isales.agent.label.answerMode': 'Mode de réponse de l\'agent',
  'isales.import.message.pleaseuploadfile': 'Sélectionnez un fichier',
  'isales.tasklogic.message.busiresultpolicyExists': 'Une politique de résultat commercial avec le même résultat commercial et le même sous-état existe déjà',
  'isales.management.message.filterYes': 'Oui',
  'isales.taskresult.field.agentRole': 'Nom du rôle de l\'agent',
  'isales.blacklist.message.name.error': 'Le nom contient des caractères non valides',
  'isales.taskmanagement.title.editTime': 'Changer l\'heure',
  'isales.taskresult.field.other': 'Autre',
  'isales.taskresult.title.export': 'Exporter',
  'isales.import.filter.button.notification.noisPush': 'Filtrer les données non poussées',
  'isales.black.filed.expTime': 'Durée de validité (facultatif\, en heures)',
  'isales.taskstatus.field.confirmed': 'Confirmé',
  'isales.task.promotion.message.dndTimeError': 'La période à ne pas déranger est incomplète',
  'isales.manualdata.title.lock': 'Verrouiller',
  'isales.data.message.empty': 'Le numéro appelé ne peut pas être vide\!',
  'isales.datasourcemanagement.field.backDbCnUrl': 'Retour URL DataSource',
  'isales.business.code': 'Code',
  'isales.specialday.title.scoreDetail': 'Détail du score',
  'isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist': 'Les logiques d\'appel sortant ne peuvent pas être modifiées par lots pour les tâches avec différents modes de tour d\'appel',
  'isales.manualdata.title.pickUp': 'Extraire',
  'isales.workbench.appointment.task': 'Tâche de réservation',
  'isales.management.title.updateData': 'Modifier les données d\'appel sortant',
  'isales.taskresult.field.operation': 'Opération',
  'isales.import.title.statusComplete': 'Terminé',
  'isales.result.edit': 'Modifier la cause d\'échec de l\'appel sortant',
  'isales.tasklogic.field.callOrderMethod': 'Règle de commande',
  'isales.taskmanagement.field.zip.password': 'Mot de passe de paquet compressé',
  'isales.tasktemplate.title.viewTemplate': 'Afficher le modèle d\'appel sortant',
  'isales.statistics.field.callCompletionRateDescription': 'Taux de connexion',
  'isales.specialday.title.warning': 'Avertissement',
  'isales.taskcalllogic.message.roundPolicyLengthTooLong': 'Une fois que le nombre de tours dans la politique d\'appel est modifié\, la longueur maximale est dépassée',
  'isales.appointment.message.updateFail': 'Impossible de modifier l\'appel sortant réservé',
  'isales.specialday.title.chooseDate': 'Sélectionner une date',
  'isales.statistics.business.startWeek': 'Semaine de début',
  'isales.taskmanagement.title.pause': 'Suspendre',
  'isales.blacklist.title.createblacklist': 'Ajouter un numéro à la liste de blocage',
  'isales.taskresult.field.failsFak': 'La partie appelée est un fax',
  'isales.notification.configVariable': 'Définir la variable',
  'isales.import.filter.button.notification.isPush': 'Filtrer les données repoussées dernièrement',
  'isales.taskresult.field.failsRecordswrittenback': 'Les enregistrements ayant échoué sont réécrits lorsque l\'OBS quitte (ou qu\'une tâche est supprimée)',
  'isales.tasktype.field.preview': 'Aperçu de l\'appel sortant',
  'isales.taskcalllogic.field.customerRefused': 'Client refusé',
  'isales.import.message.success': 'Importation de données en cours. Cliquez sur Actualiser plus tard pour obtenir les données les plus récentes.',
  'isales.management.placeholder.commonIvrName': 'Saisissez un nom de flux IVR commun',
  'isales.taskinfo.field.priority': 'Priorité',
  'isales.agent.message.addSkillFail': 'Impossible d\'ajouter la file d\'attente de compétences',
  'isales.manual.task.survey.unanswered': 'Sans réponse',
  'isales.exportdata.title.success': 'Succès',
  'isales.tasktemplate.field.released': 'Publié',
  'isales.taskmanagement.field.pickUpCondition': 'Les conditions',
  'isales.notification.receiver.number.tips': 'La valeur doit être constituée de chiffres',
  'isales.blacklist.message.calledno.empty': 'Aucun numéro n\'est entré',
  'isales.data.prop.inputPropName': 'Saisissez un nom d\'attribut',
  'isales.agent.label.maxWaitTime': 'Max. Temps de mise en file d\'attente',
  'isales.business.result.message.specialStr': 'Non pris en charge',
  'isales.management.message.calledNoOneInterval': 'Intervalle du premier numéro appelé',
  'isales.file.title.filepath.placeholder': 'Entrez un chemin de fichier\, par exemple /home',
  'isales.management.message.importOrder': 'Importer la séquence',
  'isales.tasktemplate.message.deleteConfirm': 'Êtes-vous sûr de vouloir supprimer le modèle d\'appel sortant sélectionné ?',
  'isales.taskresult.field.failsAutomaticansweringmachine': 'l\'appelé est un répondeur automatique',
  'isales.result.obResultIsNull': 'Le résultat de l\'appel sortant de la cause de l\'échec ne peut pas être vide',
  'isales.taskresult.field.failsTasknotexist': 'Aucune tâche d\'appel sortant disponible',
  'isales.taskresult.field.failsTransfernoreply': 'l\'appel est transmis sans réponse',
  'isales.blacklist.message.createFail': 'Impossible d\'ajouter le numéro à la liste de blocage',
  'isales.workbench.number.execution': 'Exécuter',
  'isales.taskInfo.message.saveSuccess': 'Les informations sont modifiées avec succès',
  'isales.tasklogic.field.addDataType': 'Ajouter un DataType',
  'isales.agent.message.createSkillConfSuccess': 'La file d\'attente de compétences est créée avec succès',
  'isales.outboundtime.message.empty': 'Aucune heure d\'appel sortant n\'est définie',
  'isales.tasklogic.message.busiresultpolicySame': 'Les résultats commerciaux et les sous-états de plusieurs politiques de résultats commerciaux sont les mêmes',
  'isales.management.message.uploadSelect': 'Sélectionnez un fichier',
  'isales.task.message.numberSuccessful': 'Le succès compte',
  'isales.file.title.rule.type': 'Type Colonne',
  'isales.common.title.confirm': 'OK',
  'isales.appointment.message.createSuccess': 'l\'appel sortant réservé est créé avec succès',
  'isales.taskmanagement.title.createDirectly': 'Créer directement',
  'isales.taskresult.field.failsPhonenumberisnull': 'Le numéro sortant est vide',
  'isales.taskmanagement.field.usersaying': 'Demande de texte',
  'isales.task.promotion.nameRepeat': 'Le nom de la tâche de marketing multimédia existe déjà',
  'isales.agent.label.transferType': 'Mode de gestion',
  'isales.task.promotion.sendTime': 'Envoyé le',
  'isales.manual.message.release.DataNotWaitExists': 'Les données d\'appel sortant qui sont appelées ou complétées existent',
  'isales.taskpolicy.field.pickupRate': 'Taux de décrochage',
  'isales.business.result.message.checkCode': 'Seuls les chiffres\, les lettres et les traits d\'union (-) sont autorisés',
  'isales.task.promotion.sms': 'SMs',
  'isales.skill.label.skillname': 'Nom de file d\'attente de compétence',
  'isales.taskresult.field.failsInsufficientresources': 'Ressources insuffisantes',
  'isales.appointmanagement.title.inputRemark': 'Entrez des remarques',
  'isales.result.search.displayResult': 'Entrez s\'il vous plait',
  'isales.appointment.title.timeout': 'Le temps de réservation est passé',
  'isales.statistics.business.noData': 'Pas de données disponibles',
  'isales.tasklogic.field.exist.numbertype': 'Type de numéro existant',
  'isales.import.filter.message.selectRuleTips': 'Sélectionnez au moins une règle de filtre',
  'isales.manual.title.recipient': 'Destinataire',
  'isales.tasklogic.field.or': 'Ou',
  'isales.blacklist.title.operateAgent': 'Agent ajouté',
  'isales.notification.receiver.info': 'Informations sur le destinataire',
  'isales.datasourcemanagement.message.deleteDataSourceSuccess': 'Supprimer DataSource Succès',
  'isales.rbtresult.field.interbusy': 'réseau occupé',
  'isales.taskstatistics.field.callResultStatistics': 'Statistiques des résultats des appels',
  'isales.taskresult.field.failsNumberreacheslimit': 'Le nombre d\'appels OBS atteint la limite supérieure',
  'isales.specialday.title.holiday': 'Date spéciale',
  'isales.manutask.title.viewdata': 'Données d\'appel sortant',
  'isales.blacklist.message.remark.error': 'Les remarques contiennent des caractères non valides',
  'isales.management.message.importType': 'Méthode d\'importation',
  'isales.taskcalllogic.title.resultSetPolicy': 'Configurer la stratégie en fonction des résultats',
  'isales.taskInfo.message.saveFail': 'Impossible de modifier les informations',
  'isales.taskresult.title.calledNo': 'Numéro sortant',
  'isales.agent.label.transfer': 'Transfert',
  'isales.tasklogic.field.addturn': 'Ajouter un rond',
  'isales.taskstatus.field.suspend': 'Suspendu',
  'isales.taskmanagement.field.taskId': 'ID de la tâche',
  'isales.datasourcemanagement.field.dbPort': 'Port de DataSource',
  'isales.task.promotion.message.limitGtTenantLimit': 'La limite d\'envoi journalier dépasse le maximum autorisé pour le locataire',
  'isales.manual.title.inputfailcode': 'Saisissez une cause d\'échec',
  'isales.appointment.message.adjustmentFail': 'Impossible d\'ajuster l\'appel sortant réservé',
  'isales.data.message.confirmSubmit': 'La séquence de numéros courante est la séquence d\'appel. Veuillez vérifier à nouveau les données',
  'isales.tasklogic.field.connectsameagent': 'Se connecter au même agent',
  'isales.taskinfo.message.beginTimeError': 'l\'heure de début ne peut pas être postérieure à l\'heure de fin',
  'isales.taskresult.field.workNo': 'WorkNo',
  'isales.manualData.message.reachMax': 'Plus aucune donnée d\'appel sortant ne peut être extraite car le nombre maximum d\'appels a été atteint dans la tâche',
  'isales.training.scoreDetails': 'Détails du score',
  'isales.taskinfo.field.survey': 'Questionnaire',
  'isales.agent.title.agentEdit': 'Modifier les informations de l\'agent',
  'isales.tasktemplate.title.addTemplate': 'Créer un modèle d\'appel sortant',
  'isales.task.field.callSuccess': 'Succès',
  'isales.taskinfo.field.surveyAnswerNum': 'Nombre de réponses au sondage',
  'isales.taskinfo.title.accesscode': 'Code d\'accès',
  'isales.taskresult.field.failsPoweredoff': 'Le téléphone est éteint',
  'isales.agent.contact.voiceVidio': 'audio et vidéo',
  'isales.agent.message.idIsEmpty': 'l\'ID de la file d\'attente de compétences ne peut pas être vide',
  'isales.taskresult.field.failsCalledmodem': 'Le parti appelé est un modem',
  'isales.import.message.taskstatuswrong': 'Impossible d\'importer des données dans la tâche d\'appel sortant car elle n\'est pas dans l\'état brouillon ou suspendu',
  'isales.file.title.filepath': 'Chemin par défaut',
  'isales.taskcalllogic.message.alreadyLastPolicy': 'Déjà dernière politique',
  'isales.appointment.title.remark': 'Remarques',
  'isales.work.title.successNum': 'Appels sortants réussis',
  'isales.taskresult.field.failsCallednumberincorrect': 'Numéro d\'appel incorrect dans l\'aperçu',
  'isales.appointment.title.add': 'Ajouter',
  'isales.taskcalllogic.field.customerMissed': 'Client manqué',
  'isales.agent.calledroute.create.accesscode.noexist': 'Le code d\'accès référencé par la configuration appelée n\'existe pas',
  'isales.notification.propo.name': 'Nom de l\'attribut',
  'isales.tasklogic.field.lt': '<',
  'isales.agent.message.selectSkills': 'Sélectionnez une file d\'attente de compétences',
  'isales.specialday.title.choose': 'Sélectionner une date spéciale',
  'isales.result.outgoingCall.Description': 'Descriptif',
  'isales.data.orderType.desc': 'Descendant',
  'isales.rbtresult.field.calledshutdown': 'Appelé à s\'arrêter',
  'isales.taskcalllogic.title.resultPolicySet': 'Stratégie de traitement des résultats',
  'isales.specialday.field.Tuesday': 'Mardi',
  'isales.tasklogic.field.result': 'Résultat',
  'isales.taskpolicy.message.input.digits': 'Entrez un entier',
  'isales.taskinfo.message.batchEditCallLogic.manualExist': 'Les logiques d\'appel sortant manuelles ne peuvent pas être modifiées',
  'isales.manual.message.data.query.error': 'Une erreur s\'est produite lors de l\'interrogation des données d\'appel sortant',
  'isales.specreason.field.all': 'veuillez choisir',
  'isales.taskpolicy.field.notSupportMultiSkill': 'Les agents hybrides pour les appels entrants et sortants ne sont pas pris en charge',
  'isales.rbtresult.emptyparam.message': 'l\'heure de début et de fin ne peut pas être vide',
  'isales.task.promotion.message.variableNotConfig': 'Certaines variables ne sont pas configurées',
  'isales.management.message.fileImport': 'Utilisation du fichier',
  'isales.management.placeholder.ivrName': 'Entrez le nom du processus de robot intelligent',
  'isales.taskmanagement.message.queryDateError': 'Date incorrecte. Sélectionnez-en un autre',
  'isales.tasklogic.field.moveUp': 'Vers le haut',
  'isales.taskinfo.title.versionNo': 'Version du questionnaire',
  'isales.task.field.callFlagCompletion': 'Terminé',
  'isales.tasklogic.field.numbertype': 'Type de numéro',
  'isales.whitepopuppageurl.protocol.check': 'Des protocoles non sécurisés sont utilisés\, ce qui pose des risques pour la sécurité',
  'isales.taskresult.title.callFlag': 'Statut',
  'isales.taskstatus.field.all': 'Tous',
  'isales.taskmanagement.title.batchEditCallPolicy.sameTaskType': 'Les stratégies d\'appels sortants peuvent être modifiées par lots uniquement pour des tâches du même type',
  'isales.taskinfo.field.surveyScore': 'Score total du sondage',
  'isales.data.prop.status.unpublished': 'Non publié',
  'isales.taskmanagement.field.isAutoPickUp': 'Activer la règle d\'extraction automatique',
  'isales.taskmanagement.message.deleteConfirm': 'Êtes-vous sûr de vouloir le supprimer ?',
  'isales.management.message.batchEditCallLogic.statusError': 'Les logiques d\'appel sortant peuvent être modifiées par lots uniquement pour les tâches à l\'état de brouillon\, d\'exécution ou de suspension',
  'isales.task.promotion.message.selectTemplateType': 'Sélectionnez un type de modèle',
  'isales.busi.result.message.nodatarecord': 'Aucune information d\'appel n\'est trouvée. Veuillez sélectionner l\'enregistrement d\'appel sortant dont le résultat commercial doit être défini',
  'isales.datasourcemanagement.field.dbCnUrl': 'URL DataSource',
  'isales.taskresult.field.failsMusicdetected': 'De la musique est détectée',
  'isales.datasourcemanagement.title.inputDbschema': 'Entrez le nom du DataSource',
  'isales.task.promotion.beginTime': 'Heure de début',
  'isales.tasktemplate.message.saveSuccess': 'Le modèle d\'appel sortant est configuré avec succès',
  'isales.timeImport.message.success': 'La configuration d\'importation planifiée est ajoutée avec succès',
  'isales.taskmanagement.title.chooseEndtime': 'Heure de fin',
  'isales.task.promotion.templateCode': 'Code de modèle',
  'isales.manual.field.previewinformation': 'Informations de prévisualisation',
  'isales.tasklogic.field.noRetryLogic': 'Ajouter le code de résultat de non-recommencer',
  'isales.tasklogic.field.retryNumber': 'Temps de réessais',
  'isales.data.propConfig.title.prop': 'Attribut',
  'isales.operlog.message.data.downloadexportfile': 'Téléchargez le fichier de résultat d\'exportation de la tâche {0}',
  'isales.taskmanagement.title.newFromTemplate': 'Créer à l\'aide d\'un modèle',
  'isales.taskstatistics.field.callstatistics': 'Statistiques des appels',
  'isales.agent.contact.multimedia': 'Multimédia',
  'isales.taskresult.title.beginTime': 'Heure de début',
  'isales.appointment.message.timeout': '{0} les appels sortants nommés avaient dépassé l\'heure de rendez-vous\, veuillez faire des appels à temps',
  'isales.specialday.message.deleteTips': 'Impossible de supprimer la date spéciale car elle est associée à une tâche d\'appel sortant',
  'isales.data.title.timeImport': 'Importation programmée',
  'isales.management.title.deleteInfo': 'Sélectionnez les données d\'appel sortant',
  'isales.taskstatistics.field.inProgress': 'Clients appelant',
  'isales.tasklogic.field.callpolicy': 'Politique d\'appel',
  'isales.appointment.title.callTime': 'Segment de temps d\'appel',
  'isales.manual.button.send': 'Envoyer',
  'isales.agent.message.batchSaveSuccess': 'Les agents sont modifiés par lots avec succès',
  'isales.training.resultAnalysis': 'Analyse des résultats',
  'isales.taskmanagement.title.appoint': 'Réserver un appel sortant',
  'isales.task.message.statistics': 'Statistiques',
  'isales.skill.label.maxwaittime': 'Max. Temps de mise en file d\'attente',
  'isales.appointment.message.confirmDelete': 'Êtes-vous sûr de vouloir supprimer les appels sortants réservés ?',
  'isales.export.message.noSelectData': 'Veuillez sélectionner les données à exporter',
  'isales.manual.title.title': 'Titre',
  'isales.taskresult.title.inputCallerNo': 'Entrez un numéro sortant',
  'isales.taskmanagement.title.speclistmanage.reason': 'la raison',
  'isales.taskmanagement.title.editPolicy': 'Modifier la politique',
  'isales.management.message.remarkLengthError': 'Un maximum de 1000 caractères est autorisé',
  'isales.appointment.placeholder.remark': 'Veuillez entrer des remarques',
  'isales.taskresult.field.failsSwitchingdevicebusy': 'l\'interrupteur est occupé',
  'isales.task.promotion.message.selectPromotionType': 'Sélectionnez un type de média',
  'isales.management.message.deletedataIdsError': 'Indicateur incorrect des données d\'appel sortant à supprimer',
  'isales.manual.message.query.agentstatus.error': 'Une erreur s\'est produite lors de l\'interrogation de l\'état de l\'agent',
  'isales.taskstatistics.placeholder.notcalled': 'En attente \: {0}',
  'isales.taskresult.title.busiresultLog': 'Journal des résultats',
  'isales.taskmanagement.field.manual': 'Appel sortant de l\'agent',
  'isales.file.title.rule.property': 'Attribut de service',
  'isales.tasklogic.field.gt': '>',
  'isales.agent.label.adjustTime': 'Durée (s) de récapitulation',
  'isales.export.message.noSelectTrainingData': 'Veuillez sélectionner les données à former',
  'isales.tasktype.field.preemption': 'Appel sortant préempté',
  'isales.taskmanagement.title.editCountOutOfBounds': 'Un maximum de 30 tâches d\'appel sortant peuvent être modifiées à la fois',
  'isales.management.title.black.import': 'Importer la liste de blocage',
  'isales.taskresult.field.failsNoValidVDn': 'Aucun VDN valide disponible',
  'isales.training.questionScoreDetails': 'Détails du score de l\'émission',
  'isales.agent.message.updateCtiAndDbSuccess': 'La file d\'attente de compétences est mise à jour avec succès',
  'isales.import.title.result': 'Afficher le résultat d\'importation',
  'isales.callpolicy.title.add': 'Ajouter',
  'isales.manualData.message.reachQuatos': 'Plus aucune donnée d\'appel sortant ne peut être extraite car le nombre d\'appels dans la tâche a atteint la limite supérieure d\'extraction',
  'isales.audio.title.callId': 'Enregistrement Sn',
  'isales.taskresult.title.operateEndTime': 'endTime',
  'isales.agent.label.timeOut': 'Délai de mise en file d\'attente',
  'isales.management.message.download': 'Télécharger le modèle de données d\'appel sortant',
  'isales.task.message.specialdayEmpty': 'Aucune date spéciale n\'est sélectionnée',
  'isales.agent.calledroute.create.skill.none': 'La file d\'attente de compétences référencée par la configuration appelée n\'existe pas',
  'isales.blacklist.placeholder.searchremark': 'Remarques',
  'isales.taskresult.title.chooseUserAccount': 'Veuillez sélectionner initié par',
  'isales.taskresult.title.resultDetail': 'Détails du résultat de l\'appel',
  'isales.operation.success': 'l\'opération a réussi',
  'isales.taskresult.field.failsQueuingtimesout': 'La file d\'attente expire et la plate-forme libère l\'appel',
  'isales.skillqueue.agentrole.agent': 'Agent',
  'isales.task.promotion.message.dndEndTimeltBeginTime': 'La fin de la période de ne pas déranger est plus tôt que le début',
  'isales.tasktemplate.title.addManualTemplate': 'Créer un modèle d\'appel sortant manuel',
  'isales.taskresult.field.failsFailedRouteAgent': 'Impossible d\'acheminer l\'appel vers l\'agent',
  'isales.taskpolicy.field.predictMethod': 'Algorithme pour les appels sortants prévus',
  'isales.manual.title.viewManualData': 'Données d\'appel sortant de l\'agent-',
  'isales.manual.message.agentstatus.isnofree': 'l\'agent courant n\'est pas dans l\'état inactif',
  'isales.statistics.field.addHis': 'Collecte de données archivées',
  'isales.outboundtime.field.minute': 'Minute',
  'isales.agent.label.skillChoice': 'File d\'attente de compétences',
  'isales.manual.message.lockData': 'Sélectionnez les données d\'appel sortant à verrouiller',
  'isales.specialday.message.updateSuccess': 'La date spéciale est modifiée avec succès',
  'isales.specialday.title.confirm': 'Confirmer',
  'isales.task.message.saveSuccess': 'La tâche d\'appel sortant est configurée avec succès',
  'isales.sendStatus.field.prepare': 'Préparation',
  'isales.taskpolicy.field.utilRate': 'Utilisation de l\'agent',
  'isales.manual.title.inputstatus': 'Entrez le statut d\'un appel sortant',
  'isales.task.promotion.gatewwayError': 'La configuration de la passerelle ne correspond pas au type de média',
  'isales.task.message.play': 'Jouer',
  'isales.task.promotion.deleteFailed': 'Impossible de supprimer la tâche de marketing multimédia',
  'isales.task.promotion.email': 'Courriel',
  'isales.taskmanagement.message.queryLimit': 'Le nombre d\'enregistrements sur chaque page doit être un nombre entier positif inférieur à 101',
  'isales.taskresult.title.exportResult': 'Afficher le résultat d\'exportation',
  'isales.taskresult.field.failsFaxdetected': 'Un fax est détecté',
  'isales.taskmanagement.title.viewAppointList': 'Réservation',
  'isales.taskmanagement.title.viewAppointListDetail': 'Détails de réservation',
  'isales.manual.title.status': 'Statut',
  'isales.datasourcemanagement.field.dbType': 'Type de DataSource',
  'isales.taskresult.field.failsRingingtimesout': 'Les temps de sonnerie et la plate-forme libère l\'appel',
  'isales.taskmanagment.callquality.minConnectedRate': 'Taux de connexion minimum ',
  'isales.taskpolicy.field.connRate': 'Taux de connexion des appels sortants',
  'isales.agent.message.willDeleteSkills': 'Cette opération peut provoquer l\'échec de l\'appel de tâche sortant associé à la file d\'attente de compétences. Veuillez opérer avec prudence. Avez-vous besoin de le supprimer?',
  'isales.task.promotion.promotionEffTime': 'Temps effectif',
  'isales.statistics.business.endYear': 'Fin de l\'année',
  'isales.taskpolicy.field.calculationFactor': 'Facteur de calcul',
  'isales.appointment.message.callNotAllowed': 'Impossible d\'effectuer un appel sortant réservé car l\'agent n\'est pas en état inactif ou occupé',
  'isales.management.message.import': 'Importer les données ajoutées',
  'isales.agent.calledroute.refreshsuccess': 'Refresh a réussi',
  'isales.taskinfo.message.require': 'Définissez tous les champs obligatoires',
  'isales.training.specialday.callTime': 'Date spéciale Heure d\'appel Segment',
  'isales.tasklogic.field.addOrderPolicy': 'Ajouter Prop',
  'isales.taskmanagement.title.outbound': 'Chatbot d\'appel sortant',
  'isales.agent.calledroute.create.accesscode.none': 'Le code d\'accès au système configuré par l\'appelé n\'est pas attribué',
  'isales.management.title.details': 'Détails',
  'isales.data.propConfig.message.isUseProp': 'Activer l\'attribut personnalisé',
  'isales.file.title.rule.name.placeHolder': 'Saisissez un nom de règle',
  'isales.file.title.server.info': 'Les informations du serveur sont modifiées avec succès',
  'isales.task.title.calllogic': 'Logique d\'appel sortant',
  'isales.tasklogic.field.callturntype.numberorder': 'Par numéro de séquence',
  'isales.agent.label.deviceType': 'Type d\'appareil',
  'isales.tasktemplate.message.pauseSuccess': 'Suspension réussie',
  'isales.taskresult.title.chooseResult': 'Veuillez sélectionner le résultat de l\'appel',
  'isales.import.message.succnum': 'Nombre d\'enregistrements réussis',
  'isales.file.title.rule.validator.maxdata': 'Un maximum de 40 enregistrements de configuration de nombres peuvent être ajoutés',
  'isales.taskmanagement.title.status': 'Statut',
  'isales.taskresult.field.failsUndefinedsignaltone': 'Tonalité de signal indéfinie',
  'isales.tasklogic.field.addbusiresultpolicy': 'Ajouter une stratégie de résultat d\'entreprise',
  'isales.management.title.detailData': 'Détails des données d\'appel sortant',
  'isales.taskresult.title.userName': 'Nom d\'utilisateur',
  'isales.taskcalllogic.field.customerbusy': 'Client occupé',
  'isales.import.message.selectServer.tips': 'Définissez le serveur de fichiers d\'appel sortant',
  'isales.management.title.refresh': 'Actualiser',
  'isales.taskinfo.field.endDate': 'Heure de fin',
  'isales.management.title.calledNo': 'Numéro appelé',
  'isales.tasklogic.field.like': 'inclure',
  'isales.tasklogic.field.callOrderMethod.bySelf': 'Par règle personnalisée',
  'isales.taskmanagement.title.configData': 'Données d\'appel sortant',
  'isales.exportdata.message.successinfo': 'Exportation des données...Voir les détails dans Exporter le résultat',
  'isales.task.message.numberInvalFeedback': 'Nombre d\'appels de rétroaction non valides',
  'isales.data.prop.numberType.countError': 'Un maximum de 10 attributs de type chiffres peut être défini',
  'isales.specialday.message.deletePartiallyFail': 'Certaines dates spéciales n\'ont pas été supprimées',
  'isales.taskresult.field.failsResourceOverload': 'Ressources insuffisantes',
  'isales.task.promotion.message.noprop': 'Ajoutez un attribut à l\'aide du menu Définition d\'attribut de données d\'appel sortant',
  'isales.import.message.errMsg': 'Cause de l\'exception',
  'isales.statistics.business.startMonth': 'Mois de début',
  'isales.statistics.business.dategt31days': 'La plage de dates ne peut excéder 31 jours',
  'isales.tasktemplate.message.releasefailed': 'Échec de la publication',
  'isales.agent.message.passLengthValidateFailed': 'Le mot de passe doit contenir 8 caractères',
  'isales.tasklogic.label.subCondition': 'Sous-condition',
  'isales.agent.calledroute.placeholder.ivr': 'Veuillez sélectionner IVR',
  'isales.manual.message.update.fail': 'Impossible de mettre à jour l\'état des données d\'appel sortant',
  'isales.agent.message.searchSkillName': 'Saisissez le nom de la file d\'attente des compétences',
  'isales.import.filter.button.noRepeat': 'Filtrer les données dupliquées par lots',
  'isales.taskresult.field.failsAgentHangsUp': 'l\'agent raccroche',
  'isales.file.title.sk.same': 'Les deux SK sont différents',
  'isales.taskinfo.field.chooseOrInputCallNo': 'Vous pouvez saisir ou sélectionner le callNo en même temps',
  'isales.management.message.calledNoLengthError': 'La longueur du nombre appelé dépasse la limite',
  'isales.blacklist.title.name': 'Nom et prénom',
  'isales.tasklogic.field.callfilter': 'Filtrage des appels',
  'isales.data.filed.isResult': 'Oui',
  'isales.taskmanagement.title.editInfo': 'Changer les informations',
  'isales.agent.message.passValidateFailed': 'La valeur doit contenir au moins trois types des caractères suivants \: majuscules\, minuscules\, chiffres et caractères spéciaux (`-\!{\'@\'}\#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?)',
  'isales.management.message.updateSuccess': 'Les données d\'appel sortant sont modifiées avec succès',
  'isales.taskpolicy.field.supportMultiSkill': 'Les agents hybrides pour les appels entrants et sortants sont pris en charge',
  'isales.tasklogic.field.addSubCondition': 'Ajouter une sous-condition',
  'isales.data.prop.createDataProp': 'Créer un attribut',
  'isales.taskpolicy.message.obOverTime': 'Saisissez d\'abord la durée du délai d\'attente de l\'aperçu',
  'isales.survey.details': 'Détails du questionnaire',
  'isales.task.message.download': 'Télécharger l\'enregistrement',
  'isales.work.title.selectTask': 'Sélectionnez la tâche d\'appel sortant',
  'isales.appointment.title.userName': 'Initié par',
  'isales.statistics.business.weekLessThan': 'La semaine de fin d\'année ne peut pas être inférieure à la semaine de début d\'année',
  'isales.asrtransresult.field.undefine': 'non défini',
  'isales.taskresult.field.failsReleaseswhencall': 'Lorsqu\'un canal vocal est établi\, un agent lance un appel à l\'aide de la commande de barre d\'agent',
  'isales.taskresult.title.queryArchivedData': 'Interroger les données archivées',
  'isales.agent.calledroute.editsuccess': 'Modifié avec succès',
  'isales.agent.message.createSkillConfFail': 'Impossible de créer la file d\'attente de compétences',
  'isales.taskmanagement.field.pickUpRule': 'Règle d\'extraction automatique',
  'isales.tasklogic.field.callmethod': 'Mode d\'appel',
  'isales.taskresult.title.failedDetail': 'Détails de l\'échec de l\'appel',
  'isales.data.prop.propNameExists': 'Le nom de l\'attribut existe déjà',
  'isales.management.message.deletedataIdsEmpty': 'Le drapeau des données d\'appel sortant à supprimer est vide',
  'isales.outboundtime.message.crossover': 'Les segments de temps d\'appel sortant se chevauchent',
  'isales.notification.configVariableValue': 'Définir la valeur de la variable',
  'isales.specialday.field.byWeek': 'Par semaine',
  'isales.appointment.message.queryTimeError': 'l\'heure de début doit être antérieure à l\'heure de fin',
  'isales.taskresult.field.failsRingbacktone': 'Ringback tonalité',
  'isales.agent.field.skill': 'SkillName',
  'isales.agent.message.chooseAnAgent': 'Sélectionnez un agent',
  'isales.audio.title.starttime': 'Heure de début',
  'isales.task.message.numberFailed': 'Enregistrements échoués',
  'isales.taskmanagement.field.dailyStatistics': 'Statistiques quotidiennes',
  'isales.import.message.template.show': 'Ajoutez les données des clients par ligne et ajoutez plusieurs numéros d\'appel par colonne',
  'isales.tasklogic.title.addPolicy': 'Ajouter une politique',
  'isales.taskmanagement.title.chooseBegintime': 'Heure de début',
  'isales.tasklogic.field.policyType': 'PolicyType',
  'isales.tasklogic.field.addCondition': 'Ajouter une condition',
  'isales.notification.variable.value': 'Valeur variable',
  'isales.taskinfo.field.beginDate': 'Heure de début',
  'isales.outboundtime.title.beginTime': 'Heure de début',
  'isales.management.message.importMode': 'Mode d\'importation',
  'isales.tasklogic.message.exist.orderNo': 'La séquence de nombres existe déjà. Entrez-en un autre',
  'isales.taskresultexport.message.type': 'Type d\'exportation',
  'isales.agent.calledroute.placeholder.extcode': 'Veuillez entrer le code d\'extension',
  'isales.agent.contact.vidio': 'Vidéo',
  'isales.agent.calledroute.accesscode': 'Code d\'accès',
  'isales.data.prop.editDataPropSuccess': 'l\'attribut est modifié avec succès',
  'isales.taskresult.field.failsHangsupwhencall': 'Lorsqu\'un agent répond à un appel en utilisant la commande de barre d\'agent\, le téléphone de l\'agent est déconnecté',
  'isales.operation.training.success': 'Opération réussie. La formation dure environ plusieurs heures. Connectez-vous à Lodas pour voir les progrès de la formation',
  'isales.blacklist.message.periodAndTime': 'La période de restriction et le nombre maximal d\'appels doivent être fixés ou laissés vides en même temps',
  'isales.agent.calledroute.create.ivr.limit': 'Le type de périphérique est IVR\, et le type de média du code d\'accès ne peut être qu\'un appel par clic\, une demande de rappel ou un appel normal',
  'isales.data.message.preview.addNumberUpperLimit': 'Un maximum de {count} numéros peut être ajouté pour les appels sortants prévisualisés',
  'isales.operation.failed': 'l\'opération a échoué',
  'isales.audio.title.confirmStatus': 'Historique ajustements',
  'isales.taskinfo.title.surveyName': 'Nom du questionnaire',
  'isales.taskinfo.callno.repeat': 'Numéro de l\'appelant est Répéter',
  'isales.tasklogic.field.callOrderType.isOrder': 'Appel ordonné',
  'isales.data.propConfig.button.configProp': 'Définir les attributs',
  'isales.agent.accesscode.description.limit': 'La description du code d\'accès peut contenir un maximum de 50 caractères',
  'isales.taskinfo.field.mutiCallNo': 'Entrez le numéro d\'appel',
  'isales.taskresultexport.message.begintime': 'Heure de début',
  'isales.taskresult.field.failsCallResourcesInsufficient': 'Ressources d\'appel insuffisantes',
  'isales.training.averageDuration': 'Durée moyenne',
  'isales.management.message.calledNoOne': 'Premier numéro appelé',
  'isales.taskresult.field.failsNoAnsweringDeviceAvailable': 'Aucun répondeur disponible',
  'isales.tasktype.field.prediction': 'Appel sortant prévu',
  'isales.data.prop.publish': 'Publier',
  'isales.import.filter.title.popup': 'Règles de filtrage',
  'isales.callNo.title.no': 'Non',
  'isales.management.message.import.server': 'Serveur',
  'isales.datasourcemanagement.field.sync': 'Synchroniser',
  'isales.specialday.title.create': 'Créer',
  'isales.file.title.password.same': 'Les deux mots de passe sont différents',
  'isales.language.label.en_Us': 'Anglais',
  'isales.taskinfo.title.smsTemplate': 'Modèle SMs',
  'isales.management.placeholder.skillName': 'Saisissez un nom de file d\'attente de compétences',
  'isales.blacklist.message.Description': 'Nombre maximum d\'appels toutes les heures ou tous les jours pendant la période de validité',
  'isales.datasourcemanagement.field.backdbport': 'Arrière Port DataSource',
  'isales.taskpolicy.field.talkingRate': 'Vitesse d\'appel sortant',
  'isales.manual.message.precall.success': 'Un appel sortant est lancé',
  'isales.taskmanagement.title.inputTaskName': 'Saisissez un nom de tâche',
  'isales.agent.message.modifySkillConfFail': 'Impossible de modifier la file d\'attente de compétences',
  'isales.data.import.task.status.executing': 'Importation',
  'isales.tasktemplate.title.inputTemplateName': 'Saisissez un nom de modèle',
  'isales.management.title.edit': 'Modifier',
  'isales.file.message.rule.fail': 'Impossible de modifier la règle',
  'isales.file.message.deletefiletips': 'Êtes-vous sûr de vouloir supprimer le serveur de fichiers sortant ?',
  'isales.taskLogic.message.saveSuccess': 'La logique d\'appel sortant est modifiée avec succès',
  'isales.datasourcemanagement.field.add': 'Ajouter un DataSource',
  'isales.agent.contact.mediaType': 'Type de média',
  'isales.appointment.placeholder.appointTime': 'Sélectionnez l\'heure d\'appel réservée',
  'isales.busi.result.item.succ': 'Succès',
  'isales.file.title.rule.validator.row': 'Veuillez entrer l\'un des A-EZ',
  'isales.taskmanagement.title.export': 'Exporter l\'enregistrement d\'appel',
  'isales.datasourcemanagement.title.inputDbId': 'Entrez l\'identifiant de la source de données',
  'isales.specialday.message.weeklyError': 'Période de date spéciale incorrecte',
  'isales.business.result.delete': 'Supprimer',
  'isales.file.message.bucket.placeholder': 'Entrez dans un seau',
  'isales.taskmanagement.title.batchEditCallTime.manualError': 'l\'heure d\'appel sortant manuel ne peut pas être modifiée',
  'isales.taskresult.field.failsAgentCancelingCall': 'l\'agent annule l\'appel',
  'isales.callrecordexport.message.downloadFilenopemission': 'Vous n\'avez pas le droit d\'exporter les enregistrements de communication d\'appels sortants intelligents',
  'isales.data.message.duplicateExist': 'Des numéros dupliqués existent',
  'isales.agent.label.answerType2': 'Identifiant de l\'agent vocal ne rapportant pas',
  'isales.file.title.password': 'Mot de passe',
  'isales.training.questionNumber': 'Nombre de questions',
  'isales.agent.label.answerType0': 'Identifiant de l\'agent de rapport',
  'isales.taskresult.field.failedtooccupytheagent': 'Impossible d\'occuper un agent',
  'isales.import.message.allow.task.statue': '\,Une tâche ne peut être importée que lorsqu\'elle est dans l\'état Draft ou Paused',
  'isales.agent.message.batchSaveFail': 'Impossible de modifier les agents par lots',
  'isales.training.surveyDetail': 'Détails du questionnaire',
  'isales.validate.message.holidayDateComparisonError': 'La date de fin ne peut pas être antérieure à la date de début',
  'isales.management.title.timeImport.execTime': 'Exécuté',
  'isales.statistics.business.reportType': 'Type de rapport',
  'isales.taskresultexport.title.fail': 'Échec',
  'isales.taskmanagement.title.queryTimeTip': 'Informations',
  'isales.agent.label.workNo': 'ID de l\'agent',
  'isales.common.title.view': 'voir',
  'isales.tasklogic.field.existingDataType': 'Sélectionnez un type de données existant',
  'isales.management.message.appendMode': 'Ajouter',
  'isales.file.title.rule.index': 'Numéro de séquence',
  'isales.tasklogic.field.callbyturn': 'Par rond',
  'isales.import.message.fail': 'Impossible d\'importer les données',
  'isales.import.filter.button.calling': 'Filtrer les données des appels non terminés',
  'isales.busi.result.message.recordfail': 'Le résultat de l\'entreprise est enregistré a échoué',
  'isales.taskmanagement.title.addAuto': 'Appel sortant automatique du nouveau système',
  'isales.file.title.rule.validator.taskId': 'Les informations sur la tâche n\'existent pas',
  'isales.blacklist.message.deletePartiallyFail': 'Impossible de supprimer certains numéros de la liste de blocage',
  'isales.taskresult.field.failedtoresume': 'Impossible de récupérer la tâche car la tâche n\'est pas dans l\'état suspendu',
  'isales.taskpolicy.field.previewAutoCallTime': 'Durée de l\'appel sortant prévisualisé',
  'isales.taskLogic.message.saveFail': 'Impossible de modifier la logique d\'appel sortant',
  'isales.agent.calledroute.device': 'Veuillez sélectionner un appareil',
  'isales.blacklist.title.modifyRemark': 'Modifier la remarque',
  'isales.taskstatistics.placeholder.statistics': 'Statistiques',
  'isales.import.button.configProp': 'Configurer la règle de filtre',
  'isales.management.title.createNew': 'Créer des données d\'appel sortant',
  'isales.taskpolicy.field.minAvgCallTime': 'Durée moyenne min. d\'appel',
  'isales.specist.message.speclistmanage': 'Sélectionnez le numéro de la liste spéciale à utiliser',
  'isales.busi.result.message.lastappointrecord': 'Pleanse définit le résultat commercial du dernier appel sortant réservé en premier',
  'isales.file.title.password.placeholder': 'Veuillez entrer le mot de passe',
  'isales.skillqueue.agentrole.administrator': 'Responsable de salle',
  'isales.manual.message.call.failed': 'Impossible de faire l\'appel sortant',
  'isales.taskresult.field.failsHangsupbeforeanswering': 'l\'appelé raccroche avant qu\'on ne réponde à l\'appel',
  'isales.manual.field.handlednumber': 'Numéro de session',
  'isales.tasklogic.message.repeatProp': 'Les attributs en double ne peuvent pas être sélectionnés',
  'isales.management.title.selectResultCode': 'Sélectionnez le code de résultat',
  'isales.tasklogic.field.interval': 'Intervalle (deuxième)',
  'isales.taskmanagement.field.calltime': 'Initié à',
  'isales.agent.contact.videoclicktodial': 'Vidéo click-to-dial',
  'isales.taskresult.message.queryTime': 'Entrez une heure complète',
  'isales.taskmanagement.title.query': 'Requête',
  'isales.agent.label.cti': 'cti',
  'isales.import.filter.title.notification.isPushDay': 'Jours poussés',
  'isales.data.prop.propParamsError': 'Impossible de vérifier le paramètre d\'attribut',
  'isales.file.title.rule.validator.prop': 'l\'attribut défini par l\'utilisateur de la tâche est incompatible avec l\'attribut configuré dans la règle',
  'isales.taskmanagement.field.statisticsType': 'Type de statistiques',
  'isales.specialday.message.idError': 'Indicateur de date spéciale incorrect',
  'isales.validate.message.formatError': 'Saisissez une date au format MM/DD',
  'isales.taskresult.title.choosebusisubstatus': 'Veuillez sélectionner le résultat de l\'entreprise avant de sélectionner le sous-statut',
  'isales.tasklogic.field.moveDown': 'En bas',
  'isales.taskresult.field.failsEdt': 'Téléphone texte (EDT)',
  'isales.specialday.title.addSpecialTime': 'Ajouter une date spéciale',
  'isales.business.result.message.must': 'Ce paramètre est obligatoire',
  'isales.taskinfo.message.autoCallTimeValueError': 'La durée de l\'appel sortant prévisualisé doit être plus courte que la durée du délai d\'attente de l\'aperçu',
  'isales.business.result.deletetips': 'Êtes-vous sûr de vouloir supprimer le résultat de l\'entreprise?',
  'isales.agent.calledroute.confdelete': 'supprimer la confirmation',
  'isales.manual.field.result.fail': 'Échec',
  'isales.taskstatus.field.draft': 'Ébauche',
  'isales.data.prop.editDataProp': 'Modifier l\'attribut',
  'isales.blacklist.placeholder.searchcalledno': 'entrer un nombre',
  'isales.black.filed.number': 'Numéro (obligatoire)',
  'isales.timeImport.message.rulePropsError': 'l\'attribut de service de la règle d\'importation planifiée doit être cohérent avec l\'attribut défini dans la tâche courante',
  'isales.survey.statistic': 'Réponses Statistiques',
  'isales.taskpolicy.message.input.maxAvgCallTime': 'La durée moyenne maximale d\'appel ne peut pas être inférieure à la valeur Min. Durée moyenne des appels',
  'isales.business.result.searchname': 'Entrez le nom du résultat de l\'entreprise',
  'isales.taskcalllogic.field.emptyNumber': 'Numéro hors service',
  'isales.calledNo.message.input': 'Seuls les chiffres sont autorisés',
  'isales.taskmanagement.title.begintime': 'Heure de début',
  'isales.callno.message.delayTime': 'Délai d\'échec d\'appel \: Intervalle entre le numéro actuel et le numéro précédent',
  'isales.data.import.task.status.complete': 'Terminé',
  'isales.manual.message.data.isalready.called': 'Le client a passé un appel et ne peut pas passer un autre appel',
  'isales.agent.field.skillWeight': 'Compétence Poids',
  'isales.taskresult.field.failsSubscriberhangsup': 'La partie appelée raccroche',
  'isales.task.promotion.hwsms': 'HUAWEI CLOUD SMs',
  'isales.agent.calledroute.delsuccess': 'supprimé avec succès',
  'isales.specialday.message.deleteSpecialday': 'Sélectionnez une date spéciale',
  'isales.outboundtime.title.timePeriod': 'Segment de temps d\'appel sortant',
  'isales.blacklist.title.expirationTime': 'Temps d\'expiration',
  'isales.management.title.extendedField': 'Champ étendu',
  'isales.input.message.small': 'La valeur d\'entrée est trop petite',
  'isales.skill.placeholder.skilldesc': 'Saisissez le nom de la file d\'attente des compétences',
  'isales.sendStatus.field.sending': 'Envoi',
  'isales.management.message.calledNoFormatIncorrect': 'Le numéro d\'appel sortant ne peut contenir que des chiffres',
  'isales.datasourcemanagement.field.dbIp': 'IP ou alias de la source de données',
  'isales.taskcalllogic.message.dataTypeDefaultNoCreate': 'Type de numéro par défaut',
  'isales.outboundtime.title.clear': 'Effacer',
  'isales.task.promotion.edit': 'Modifier la tâche de marketing multimédia',
  'isales.management.title.toblocklist': 'Ajouter à la liste de blocage',
  'isales.result.delete.error': 'Sélectionnez une cause d\'échec d\'appel sortant',
  'isales.agent.title.skillCreate': 'Créer une file d\'attente de compétences',
  'isales.specialday.field.endDate': 'Date de fin',
  'isales.datasourcemanagement.field.dbId': 'Id DataSource',
  'isales.management.message.batchEditCallLogic.notSupportBusiresult': 'Les stratégies de résultats métier ne peuvent pas être configurées pour les appels sortants automatiques ou intelligents. Êtes-vous sûr de vouloir effectuer la modification par lot ?',
  'isales.agent.label.useTheSkill': 'Supprime la relation de liaison entre l\'agent et la file d\'attente de compétences dans Configurer l\'agent',
  'isales.taskmanagement.title.editSample': 'Gestion des objets',
  'isales.taskmanagement.title.speclistmanage.calledno': 'Numéro sortant',
  'isales.import.filter.button.recentCall': 'Filtrer les données des appels terminés dernièrement',
  'isales.datasourcemanagement.message.dbpass': 'Veuillez saisir le même mot de passe',
  'isales.file.title.server.fail': 'Impossible de modifier les informations du serveur',
  'isales.taskresult.field.failsNoresource': 'Aucune ressource disponible',
  'isales.statistics.business.endMonth': 'Fin du mois',
  'isales.taskresult.field.failsObsexits': 'Lorsque l\'OBS quitte\, les enregistrements continus sans réponse sont annulés et réécrits',
  'isales.agent.calledroute.create.accesscode.repeat': 'La combinaison du code d\'accès système et du code d\'extension configuré par la partie appelée ne peut pas être répétée',
  'isales.management.title.warning': 'Avertissement',
  'isales.task.promotion.create': 'Créer une tâche de marketing multimédia',
  'isales.training.startTraining': 'Commencer la formation',
  'isales.training.taskdetails': 'Détails de la tâche',
  'isales.taskmanagement.title.batchOpt': 'Opération par lots',
  'isales.statistics.business.week': 'Semaine',
  'isales.taskinfo.field.skillName': 'File d\'attente de compétences',
  'isales.specialday.message.nameExists': 'Le nom de la date spéciale existe déjà',
  'isales.data.import.task.status.error': 'Erreur',
  'isales.specialday.message.weeklyEmpty': 'La période de date spéciale est vide',
  'isales.taskresult.field.failsReleasesbeforecall': 'Avant l\'établissement d\'un canal vocal\, un agent lance un appel à l\'aide de la commande de barre d\'agent',
  'isales.taskmanagement.message.queryFailed': 'La requête a échoué',
  'isales.audio.message.errortip1': 'Impossible d\'interroger les informations d\'enregistrement. Contactez l\'administrateur',
  'isales.agent.field.newpasswordconf': 'Confirmer le mot de passe',
  'isales.task.title.preStep': 'Précédent',
  'isales.taskresult.field.failsInvalidstatus': 'Statut DTE non valide',
  'isales.agent.label.ccRoleName': 'Nom du rôle',
  'isales.appointmanagement.field.remark': 'Remarques',
  'isales.blacklist.message.createSuccess': 'Le numéro est ajouté avec succès à la liste de blocage',
  'isales.taskinfo.callno.length': 'La longueur d\'un seul numéro appelant dépasse 32 caractères',
  'isales.taskmanagement.title.batchStart.finishedStatus': 'Les tâches terminées ne peuvent pas être démarrées',
  'isales.business.result.view': 'Voir le résultat de l\'entreprise',
  'isales.taskresultexport.message.downloadFilenopemission': 'Vous n\'avez pas la permission d\'exporter des fichiers',
  'isales.taskmanagment.title.callquality.viewstatistics': 'Statistiques sur la qualité des appels',
  'isales.notification.viewResult.button': 'Résultat marketing',
  'isales.tasklogic.field.dataType': 'Type de données',
  'isales.data.prop.stringType': 'Chaîne',
  'isales.task.statistics.obs.startTime': 'l\'heure de début ne peut pas être antérieure à sept jours',
  'isales.datasourcemanagement.message.alreadyExistDs': 'La source de données de VDN existe déjà',
  'isales.agent.button.edit': 'Modifier',
  'isales.taskresult.field.failsNoreply': 'l\'appelé ne répond pas à l\'appel',
  'isales.common.title.tipsInfo': 'Informations',
  'isales.taskresult.field.failsNumberchange': 'Le numéro est changé',
  'isales.tasklogic.field.retryDay': 'Max. Jours pour les tentatives d\'appel avant la connexion',
  'isales.task.promotion.message.variableSelected': 'Sélectionnez un attribut',
  'isales.taskresult.title.operateBeginTime': 'startTime',
  'isales.rbtresult.field.calledunconnected': 'l\'appelé n\'est pas disponible\, et le rappel d\'appel entrant est activé',
  'isales.taskresultexport.message.downloadConfirmInfo': 'Êtes-vous sûr de vouloir télécharger le fichier?',
  'isales.notification.receiver.address': 'Adresse du destinataire',
  'isales.taskresult.title.busisubstatus': 'SubStatus d\'affaires',
  'isales.task.promotion.promotionName': 'Tâche de marketing',
  'isales.task.message.numbersTotal': 'Total\: {0}',
  'isales.notification.prop.tips': 'l\'attribut doit être unique',
  'isales.training.remainingSamples': 'Quantité restante',
  'isales.taskstatistics.field.touchRate': 'Taux de contact',
  'isales.specialday.title.delete': 'Supprimer',
  'isales.data.message.confirmaddblock.calldatatype': 'Le type actuel est les données d\'appel. Veuillez confirmer à nouveau',
  'isales.specialday.title.deleteError': 'Erreur',
  'isales.taskpolicy.field.utilRateCalculateMethod': 'Méthode de calcul de l\'utilisation de l\'agent',
  'isales.agent.label.ivrChoice': 'IVR',
  'isales.taskresult.title.result': 'Résultat',
  'isales.datasourcemanagement.field.dsType.all': 'Tous',
  'isales.specreason.field.emptynumber': 'Le numéro n\'est pas attribué',
  'isales.taskinfo.field.callBackUrl': 'Adresse de rappel',
  'isales.taskcalllogic.message.mostPolicys': 'Un maximum de 10 stratégies de résultat peuvent être configurées pour un nombre donné',
  'isales.taskstatus.field.execute': 'Exécution',
  'isales.manualdata.title.updateCalledNo': 'Changer le numéro sortant',
  'isales.taskresult.title.busiresultupdate': 'Ajouter Busiresult',
  'isales.taskresult.field.failsFaxormodem': 'l\'appelé est un fax ou un modem',
  'isales.task.title.callTime': 'Durée de l\'appel sortant',
  'isales.datasourcemanagement.field.dbschema': 'Nom du DataSource',
  'isales.busi.result.title.result': 'Résultat',
  'isales.taskresult.field.failsUnallocatednumber': 'Numéro non alloué',
  'isales.taskpolicy.field.waitTime': 'Temps d\'attente de la partie appelée ',
  'isales.specialday.message.idEmpty': 'Le drapeau de date spécial est vide',
  'isales.taskmanagement.message.beforeNow': 'l\'heure de début ne peut pas être antérieure à l\'heure actuelle',
  'isales.specialday.message.isEmpty': 'Aucune heure d\'appel sortant de la date spéciale n\'est définie',
  'isales.business.result.name': 'Nom du résultat de l\'entreprise',
  'isales.taskresult.field.failsTransferbeingalerted': 'l\'appel est transféré pendant la sonnerie',
  'isales.export.message.input.error': 'Le mot de passe n\'est pas dans le format correct',
  'isales.taskinfo.url.not.whitelist': 'l\'adresse n\'est pas dans la liste de confiance',
  'isales.manual.message.data.checklock.inblack': 'Le numéro d\'appel sortant est dans la liste de blocage',
  'isales.file.title.port': 'Port',
  'isales.taskresult.field.failsInvalidSkillQueue': 'File d\'attente de compétences non valide',
  'isales.task.message.numberCalled': 'Appelé \: {0}',
  'isales.taskresult.field.failsCallRouting': 'Impossible d\'acheminer l\'appel',
  'isales.agent.message.configAgent': 'Configurer ou synchroniser un agent',
  'isales.taskmanagement.title.pauseConfirm': 'Êtes-vous sûr de vouloir suspendre la tâche d\'appel sortant sélectionnée ?',
  'isales.taskresult.field.failsAgentfailswithuser': 'Impossible de configurer un canal vocal entre un agent et un abonné',
  'isales.notification.configVariableRel': 'Configurer la relation entre la variable et l\'attribut',
  'isales.failfile.export.failCalledNo': 'failCalledNon',
  'isales.manual.field.noDataCanbeCallTips': 'Actuellement\, aucun appel ne peut être effectué. Vérifiez si la configuration de l\'attribut répond aux critères du filtre',
  'isales.black.filed.remark': 'Remarques (facultatif)',
  'isales.task.promotion.sendTemplate': 'Modèle de notification marketing',
  'isales.taskresult.field.failsNoagentavailable': 'Lorsque l\'OBS passe à l\'état de veille\, si aucun agent n\'est disponible pour la sortie sécurisée ou les appels sortants préoccupés\, les enregistrements défaillants sont annulés',
  'isales.tasklogic.field.add.numbertype': 'Nouveau type de numéro',
  'isales.taskresult.field.failsDestinationunreachable': 'La destination est inaccessible',
  'isales.appointment.message.afterNow': 'l\'heure réservée ne peut pas être antérieure à l\'heure actuelle',
  'isales.specialday.title.add': 'Ajouter une date spéciale',
  'isales.agent.label.skillId': 'ID de file d\'attente de compétence',
  'isales.taskresult.field.invalidCall': 'Appel non valide',
  'isales.specreason.field.systemtone': 'Tonalité d\'invite du système',
  'isales.taskresult.field.failsAbnormal': 'Erreur',
  'isales.taskresult.field.failsPlayingvoices': 'La signalisation détecte que le commutateur homologue joue des voix',
  'isales.task.promotion.promotionExpTime': 'Temps d\'expiration',
  'isales.training.fullScoreNumber': 'Nombre de scores complets',
  'isales.agent.label.ivr': 'IVR',
  'isales.management.message.import.filePath': 'Adresse du fichier',
  'isales.taskresult.field.failsEmptyorwrong': 'Inaccessible ou mauvais numéro',
  'isales.appointment.message.soontimeout': '{0} les appels sortants nommés arriveront dans les cinq prochaines minutes\, s\'il vous plaît préparez-vous à faire des appels',
  'isales.specialday.message.createSuccess': 'La date spéciale est créée avec succès',
  'isales.management.title.busiresult': 'Résultat de l\'activité',
  'isales.taskinfo.message.callintervalrange': 'Veuillez saisir un nombre entier allant de 1 à 3600',
  'isales.import.filter.button.callResult': 'Filtrer les données de résultat de l\'entreprise',
  'isales.management.message.calledNoExists': 'Le numéro appelé existe déjà',
  'isales.taskmanagement.field.zip.makesure.password': 'Saisissez à nouveau le mot de passe du paquet compressé',
  'isales.management.message.calledNoTwoInterval': 'Intervalle du deuxième numéro appelé',
  'isales.workbench.task.management': 'Gestion des appels sortants',
  'isales.statistics.business.saveError': 'Erreurs',
  'isales.tasklogic.field.retryTimes': 'Nombre maximal de tentatives quotidiennes d\'appels',
  'isales.data.prop.quantity': 'Quantité',
  'isales.taskmanagement.field.pickUpNum': 'Appels extraits à chaque fois',
  'isales.taskmanagement.title.view.callrecord': 'Enregistrement d\'appel',
  'isales.data.prop.orderType': 'Type de commande',
  'isales.data.prop.anonym': 'Anonymisé',
  'isales.file.title.ip': 'Adresse IP',
  'isales.tasklogic.field.callOrderMethod.byOrderNo': 'Par No',
  'isales.specialday.message.crossover': 'Les segments horaires des appels sortants à des dates spéciales se chevauchent',
  'isales.taskresult.field.failsCallFailure': 'l\'appel a échoué',
  'isales.import.filter.button.lineRepeat': 'Filtrer les données dupliquées de ligne',
  'isales.taskstatistics.field.completedcalls': 'Appelé',
  'isales.import.message.selectRule.tips': 'Définissez la règle de mappage à importer',
  'isales.taskstatistics.field.abandonRate': 'Taux d\'abandon',
  'isales.task.promotion.pauseSuccess': 'La tâche de marketing multimédia est suspendue avec succès',
  'isales.import.message.uploadedfiletoolarge': 'Sélectionnez un fichier de moins de 20 Mo',
  'isales.taskmanagement.field.pickUpRuleBusiResult': 'Résultat de l\'entreprise',
  'isales.statistics.business.reset': 'Réinitialiser',
  'isales.blacklist.message.deleteBlacklist': 'Sélectionnez le numéro de liste bloquée à supprimer',
  'isales.agent.message.name.limit': 'Nom de file d\'attente de compétence au - delà de la longueur maximale.',
  'isales.common.title.success': 'Succès',
  'isales.task.title.taskSample': 'Gestion des objets',
  'isales.taskresult.field.failsWithoutringing': 'l\'appel est transmis en réponse immédiate avant de sonner',
  'isales.task.promotion.prefix': 'Code de pays',
  'isales.rbtresult.field.other': 'Autre',
  'isales.taskinfo.field.flowName': 'Flux IVR',
  'isales.work.title.busiSuccess': 'Entreprise réussie',
  'isales.notification.import.button': 'Importer données',
  'isales.taskresult.field.skillqueue': 'File d\'attente de compétences',
  'isales.task.promotion.startFailed': 'Impossible de publier la tâche de marketing multimédia',
  'isales.rbtresult.field.calledrestriction': 'l\'utilisateur appelé a défini des restrictions d\'appel entrant',
  'isales.taskstatistics.field.callRatePie': 'Taux d\'achèvement des appels',
  'isales.audio.title.endTime': 'Heure de fin d\'enregistrement',
  'isales.tasktemplate.title.editTemplate': 'Modifier le modèle d\'appel sortant',
  'isales.exportdata.message.failinfo': 'Impossible d\'exporter les données',
  'isales.taskresult.field.failsTransferonbusy': 'l\'appel est transféré sur occupé',
  'isales.datasourcemanagement.message.deleteDataSourceFail': 'Échec de la suppression de DataSource',
  'isales.taskstatistics.field.Completed': 'Clients Appels complétés',
  'isales.taskresult.field.failsCallqueueoverflow': 'Une file d\'attente d\'appels déborde',
  'isales.taskmanagement.title.out.callresult': 'Résultat de l\'appel sortant',
  'isales.manual.message.confirmLockData': 'Êtes-vous sûr de vouloir verrouiller les données d\'appel sortant ?',
  'isales.manual.task.survey.currentprogress': 'Progrès actuels',
  'isales.task.promotion.smsgw': 'Passerelle SMs',
  'isales.taskmanagement.title.toBeCallCount': 'Appels en attente',
  'isales.speclist.blacklist.message.movePartiallyFail': 'Échec du transfert des numéros vers la liste noire',
  'isales.task.promotion.inputPromotionName': 'Veuillez saisir un nom marketing',
  'isales.operlog.message.data.import': 'Importer des données pour la tâche {0}',
  'isales.tasklogic.message.need.turns': 'Ajoutez au moins une donnée ronde',
  'isales.agent.message.deleteSkillAndAgentFail': 'Impossible de supprimer l\'association de la file d\'attente de compétences',
  'isales.file.title.rule.validator.ruleId': 'La règle de mappage n\'existe pas',
  'isales.taskcalllogic.field.fax': 'Fax\, standard téléphonique\, etc',
  'isales.blacklist.title.deleteError': 'Erreur',
  'isales.task.promotion.gateway': 'Passerelle d\'envoi',
  'isales.training.trainingRate': 'Taux de formation',
  'isales.taskresult.field.failsCallattemptfails': 'Impossible d\'appeler l\'interface d\'appel sortant',
  'isales.blacklist.message.confirmDelete': 'Êtes-vous sûr de vouloir supprimer les numéros sélectionnés de la liste de blocage ?',
  'isales.tasktemplate.title.manualTemplate': 'Modèle d\'appel sortant manuel',
  'isales.management.title.adjustment.log': 'Enregistrement d\'ajuster',
  'isales.business.result.create': 'Créer un résultat d\'entreprise',
  'isales.datasourcemanagement.message.confirDelete': 'La suppression de la source de données est une opération à haut risque. Faites preuve de prudence lorsque vous effectuez cette opération',
  'isales.tasklogic.message.confirmBatchUpdate': 'La modification par lots ne s\'applique qu\'aux régions sélectionnées. Êtes-vous sûr de vouloir continuer ?',
  'isales.datasourcemanagement.field.backdbIp': 'IP ou alias de la base de données de secours',
  'isales.statistics.business.month': 'Mois',
  'isales.task.promotion.tobesent': 'Messages à envoyer',
  'isales.taskpolicy.message.obOverTimeRange': 'La durée du délai d\'attente de l\'aperçu est hors de la plage\: 9 à 120. Veuillez d\'abord modifier la durée du délai d\'attente de l\'aperçu',
  'isales.work.title.busiFail': 'Entreprise ayant échoué',
  'isales.tasktemplate.message.nameDuplicated': 'Un modèle d\'appel sortant portant le même nom existe déjà',
  'isales.taskresult.title.trainingQuestion': 'Questions de test de formation',
  'isales.tasklogic.message.busiresultpolicyNotsaved': 'Certaines stratégies de résultats métier ne sont pas sauvegardées',
  'isales.statistics.field.addHisDetail': 'Non\: Les données de {0} mois sont collectées. Oui \: Les données de {1} mois sont collectées',
  'isales.file.title.username.placeholder': 'Veuillez entrer le nom d\'utilisateur',
  'isales.datasourcemanagement.field.backdbId': 'Précédent Id DataSource',
  'isales.taskinfo.message.nameExists': 'Le nom de la tâche d\'appel sortant doit être unique',
  'isales.management.title.timeImport': 'Configurer l\'importation planifiée',
  'isales.common.message.choose': 'Veuillez sélectionner les données.',
  'isales.task.promotion.message.monthGtThree': 'Seuls les résultats de trois mois peuvent être interrogés',
  'isales.agent.label.deleteCtiFailed': 'Impossible de supprimer les données',
  'isales.import.filter.title.callDay': 'Activité récente (journées)',
  'isales.manual.field.callflag.inprocess': 'Appeler',
  'isales.exportdata.title.exportresult': 'Exporter le résultat',
  'isales.result.resultCode': 'Code de cause',
  'isales.file.title.ip.placeholder': 'Entrez l\'adresse IP du serveur',
  'isales.language.label.zh_Cn': 'Chinois',
  'isales.taskmanagment.title.open.obsMonitor': 'Surveillance des données OBS',
  'isales.tasktemplate.field.release': 'Publier',
  'isales.file.title.userNameOrAK': 'Nom d\'utilisateur/AK',
  'isales.data.prop.inputPropCode': 'Entrez un code d\'attribut',
  'isales.notification.receiver.delete.error': 'Aucune donnée n\'est sélectionnée',
  'isales.tasklogic.field.callOrderType.noOrder': 'Appel non ordonné',
  'isales.datasourcemanagement.field.dbPass2': 'Confirmer le PassWord',
  'isales.datasourcemanagement.field.dbPass': 'DataSource PassWord',
  'isales.task.promotion.sendStatus': 'Statut d\'envoi',
  'isales.statistics.business.byWeek': 'Rapport hebdomadaire',
  'isales.taskpolicy.message.saveFail': 'Impossible de configurer la stratégie d\'appel sortant',
  'isales.agent.button.agent.association': 'Configurer l\'agent',
  'isales.business.result.addchildren': 'Créer un sous-statut',
  'ccivr.ivr.ivrflow.type': 'Type',
  'isales.tasklogic.label.primaryCondition': 'Condition principale',
  'isales.tasklogic.field.policy': 'Politique',
  'isales.task.message.startTips': 'La tâche est expirée. Impossible de démarrer la tâche d\'appel sortant',
  'isales.taskresultexport.message.result': 'Résultat',
  'isales.specist.message.updateFail': 'Échec de la modification',
  'isales.specialday.message.dateEmpty': 'La date spéciale est vide',
  'isales.agent.calledroute.exception': 'Veuillez vérifier l\'état de Redis ou la configuration de la passerelle CC',
  'isales.file.title.rule.interval': 'Colonne d\'intervalle d\'appel',
  'isales.taskmanagement.title.viewTaskResult': 'Résultat de la tâche',
  'isales.taskinfo.message.batchEditCallLogic.CallPolicyNotification': 'Les cycles existants restent inchangés\, et de nouveaux cycles seront ajoutés à côté des cycles existants',
  'isales.task.promotion.dndTime': 'Période à ne pas dissoudre',
  'isales.taskresult.field.failsBeingchanged': 'l\'appelé change et l\'appel est transféré',
  'isales.agent.calledroute.editfail': 'Échec de l\'édition locale des données',
  'isales.taskcalllogic.title.retryPolicySet': 'Politique d\'appel d\'essai',
  'isales.manualData.message.pickUpSuccess': 'Extraction réussie',
  'isales.agent.message.curcountpasswordWrong': 'Le mot de passe actuel du compte est incorrect',
  'isales.specist.title.misjudgment': 'Erreur de jugement',
  'isales.agent.button.cancel': 'Annuler',
  'isales.busi.result.message.systemparams.fail': 'Impossible d\'interroger les paramètres du système',
  'isales.agent.calledroute.require': 'Définissez tous les champs obligatoires',
  'isales.tasklogic.field.selectProp': 'Ajouter un attribut',
  'isales.data.import.task.status.ready': 'Préparation',
  'isales.specialday.title.date': 'Date spéciale',
  'isales.statistics.business.nomorethanerror': 'La date sélectionnée ne peut pas être antérieure à {0}',
  'isales.rbtresult.field.calledbusy': 'l\'appelé est occupé\, et l\'appelé a enregistré la fonction d\'appel en attente',
  'isales.taskresult.field.failsInvalidRoutingDevice': 'Périphérique de routage non valide',
  'isales.agent.label.maxWaitNum': 'Max. Appels en file d\'attente',
  'isales.taskresult.title.chooseEndTime': 'Veuillez sélectionner l\'heure de fin',
  'isales.manual.message.not.call': 'Aucun appel sortant n\'est effectué',
  'isales.taskpolicy.field.maxAvgCallTime': 'Durée moyenne max. d\'appel',
  'isales.tasklogic.field.value': 'Valeur',
  'isales.agent.calledroute.nmsconnect.fail': 'Impossible de se connecter à ODFs',
  'isales.datasourcemanagement.field.dsType.mysql': 'MYSQL',
  'isales.exportdata.message.nodata': 'Aucune donnée répondant aux conditions ne peut être exportée \!',
  'isales.label.appraise.call.quality.customer': 'client',
  'isales.management.message.batchEditCallLogic.notSupportBusiresultInCMs': 'Les stratégies de résultats métier ne peuvent pas être configurées pour les appels sortants automatiques. Êtes-vous sûr de vouloir effectuer la modification par lot ?',
  'isales.taskresult.field.failsAutomaticdevicedetected': 'Les appareils automatiques sont détectés',
  'isales.taskTime.message.saveSuccess': 'l\'heure d\'appel sortant est modifiée avec succès',
  'isales.taskinfo.title.chooseSmsTemplateQuery': 'Sélectionner un modèle SMs',
  'isales.agent.calledroute.ccivrdata.error': 'Impossible de synchroniser ODFS\, les données de processus CC-IVR sont erronées',
  'isales.agent.pageurls.errorsave': 'Erreur',
  'isales.datasourcemanagement.message.addDataSourceFail': 'Échec de la création de DataSource',
  'isales.agent.calledroute.placeholder.accesscode': 'Veuillez sélectionner un code d\'accès',
  'isales.file.title.rule.desc': 'Descriptif',
  'isales.tasklogic.field.orderPolicy': 'Tri d\'appels',
  'isales.taskinfo.field.callTimes': 'tentatives d\'appel',
  'isales.management.title.adjustment.modifier': 'Ajusté par',
  'isales.specialday.message.confirmDelete': 'Êtes-vous sûr de vouloir supprimer les données ?',
  'isales.common.title.dataSearch': 'Rechercher',
  'isales.taskmanagement.title.startConfirm': 'Êtes-vous sûr de vouloir lancer la tâche d\'appel sortant sélectionnée ?',
  'isales.datasourcemanagement.field.dsType.oracle': 'ORACLE',
  'isales.appointment.title.soontimeout': 'l\'heure de réservation est sur le point d\'arriver',
  'isales.manual.message.taskstatus.error': 'l\'appel sortant n\'est pas dans l\'état Exécution',
  'isales.manual.title.sendEmail': 'Envoyer un e-mail',
  'isales.rbtresult.field.transfer': 'Transfert d\'appel',
  'isales.tasklogic.message.no.numbertype': 'Le numéro de série est vide.',
  'isales.business.result.delete.data.error': 'Un maximum de 100 enregistrements peuvent être supprimés à la fois',
  'isales.taskmanagement.title.batchEditCallPolicy.manualError': 'Les stratégies d\'appel sortant manuelles ne peuvent pas être modifiées',
  'isales.taskmanagement.message.period.seven': 'l\'intervalle ne peut être supérieur à 7 jours',
  'isales.appointment.title.editAppoint': 'Modifier l\'appel sortant réservé',
  'isales.result.outgoingCall.input.description': 'Entrez le code ou la description du résultat de l\'appel sortant',
  'isales.taskmanagement.title.batchEditTaskInfo.errStatus': 'Sélectionnez une tâche dans l\'état brouillon\, suspendu ou d\'exécution',
  'isales.validate.message.notSpecial': 'Le nom ne peut pas contenir de caractères spéciaux',
  'isales.taskmanagement.field.basicTaskInfo': 'Informations de base',
  'isales.taskinfo.field.smsTemplate': 'Modèle de SMS pour l\'échec d\'un appel sortant',
  'isales.taskmanagement.title.batchEditCallTime': 'Temps d\'appel sortant de changement de lot',
  'isales.taskresult.title.callResult': 'Résultat',
  'isales.task.promotion.message.variableIsNull': 'La liste de configuration des attributs est vide',
  'isales.specialday.field.Monday': 'Lundi',
  'isales.task.message.startWarning': 'Exportation des données...Impossible de démarrer la tâche d\'appel sortant',
  'isales.taskstatistics.field.specialCall': 'Liste spéciale',
  'isales.taskmanagement.field.taskStatus': 'Statut de la tâche',
  'isales.taskmanagment.title.open.agentMonitor': 'Surveillance des agents',
  'isales.notification.variable.name': 'Nom de la variable',
  'isales.taskresult.title.operationTime': 'Actionnez le temps',
  'isales.appointment.message.callSuccess': 'Succès',
  'isales.agent.toblocklist.addType': 'Ajouter un type blockList',
  'isales.task.message.pauseSuccess': 'La tâche d\'appel sortant est suspendue avec succès',
  'isales.taskresultexport.message.downloadRedischeckfailinfo': 'Un fichier ne peut être téléchargé à nouveau que lorsque le téléchargement précédent est terminé',
  'isales.agent.label.bindTheSkill': 'La configuration de la partie appelée a été liée. Détachez-le d\'abord',
  'isales.agent.label.theAgent': 'Agent',
  'isales.calledroute.title.selectaccesscode': 'Choisissez un code d\'accès',
  'isales.task.promotion.isRetry': 'Réessayer en cas d\'échec',
  'isales.rbtresult.field.linefaulty': 'Défaillance de la ligne dans la direction d\'autres opérateurs',
  'isales.manual.message.confirmReleaseData': 'Êtes-vous sûr de vouloir libérer les données d\'appel sortant?',
  'isales.taskresultexport.title.success': 'Succès',
  'isales.common.message.checknonnegativeinteger': 'Saisissez un entier non négatif au format correct',
  'isales.blacklist.message.error': 'Une erreur se produit lorsque le numéro est ajouté à la liste de blocage',
  'isales.taskmanagement.title.deleteFailed': 'Impossible de supprimer la tâche',
  'isales.agent.message.modifySkillFail': 'Impossible de mettre à jour la file d\'attente de compétences',
  'isales.manual.task.survey.noAnswer': 'Aucune réponse requise',
  'isales.statistics.business.byMonth': 'Rapport mensuel',
  'isales.task.promotion.statusStart': 'La tâche de marketing multimédia est en cours d\'exécution',
  'isales.agent.calledroute.devicetype': 'Type d\'équipement',
  'isales.manual.field.callinginformation': 'Renseignements sur l\'appel',
  'isales.training.completeSamples': 'Taille de l\'échantillon terminée',
  'isales.appointment.message.updateSuccess': 'l\'appel sortant réservé est modifié avec succès',
  'isales.taskresult.field.failsCallTimeout': 'The call time out',
  'isales.taskresult.field.failsDnd': 'l\'appel est temporairement rejeté',
  'isales.taskmanagement.title.batchPause.statusError': 'Les tâches dans l\'état brouillon ou expiré ne peuvent pas être suspendues',
  'isales.specialday.message.deleteFail': 'Impossible de supprimer la date spéciale',
  'isales.agent.calledroute.extlengthcodewarn': 'La longueur du code d\'extension ne peut pas dépasser 12',
  'isales.taskstatistics.field.connectedCallsPie': 'Nombre d\'appels connectés',
  'isales.input.message.large': 'La valeur d\'entrée est trop grande',
  'isales.datasourcemanagement.field.editDataSource': 'Modifier les DataSource',
  'isales.taskresult.field.failsSwitchRestart': 'Le commutateur a redémarré',
  'isales.manual.title.viewManualResult': 'Résultat de l\'appel sortant de l\'agent-',
  'isales.marketing.mode': 'Mode de commercialisation',
  'isales.datasourcemanagement.message.addDataSourceSuccess': 'Créer un succès DataSource',
  'isales.blacklist.message.calledno.exist': 'Le numéro existe dans la liste de blocage',
  'isales.taskinfo.title.chooseCommonIvrFlow': 'Sélection d\'une RVI commune',
  'isales.taskinfo.placeholder.choose': '--Sélectionner--',
  'isales.taskresult.field.failsTransferunconditionally': 'l\'appel est transmis sans condition',
  'isales.taskmanagement.field.intelligence': 'Appel sortant intelligent',
  'isales.taskresult.field.failsTimeout': 'The system time out',
  'isales.notification.variable.defvalue': 'Valeur par défaut',
  'isales.import.message.endtime': 'Heure de fin',
  'isales.datasourcemanagement.title.inputVdnId': 'Entrez un VDNID',
  'isales.import.message.begintime': 'Heure de début',
  'isales.specialday.message.createFail': 'Impossible de créer la date spéciale',
  'isales.task.message.numberEffFeedback': 'Nombre d\'appels de rétroaction valides',
  'isales.agent.label.skills': 'File d\'attente de compétences',
  'isales.specialday.field.Saturday': 'Samedi',
  'isales.datasourcemanagement.field.viewDataSource': 'Voir DataSource',
  'isales.taskpolicy.field.obCallCount': 'Nombre d\'appels sortants à la fois',
  'isales.tasklogic.field.prop': 'prop',
  'isales.agent.label.noAgents': 'Absence d\'un agent de service',
  'isales.taskmanagement.title.reset': 'Réinitialiser',
  'isales.task.promotion.tempalteError': 'Le modèle de notification marketing ne correspond pas au type de modèle',
  'isales.data.prop.numberType': 'Chiffre',
  'isales.taskmanagement.field.callCompletedNum': 'Appels complétés',
  'isales.management.message.coverMode': 'Remplacer',
  'isales.data.prop.propName': 'Nom de l\'attribut',
  'isales.management.placeholder.surveyName': 'Nom du questionnaire',
  'isales.taskstatistics.field.numberOfUnconnectedCalls': 'Appels non connectés',
  'isales.tasklogic.field.and': 'et',
  'isales.management.title.adjustment': 'Ajuster',
  'isales.taskresult.field.failsBusytone': 'Ton occupé',
  'isales.agent.calledroute.create': 'Ajouter une configuration appelée',
  'isales.export.message.selectToomach': 'Un maximum de 10 enregistrements peuvent être sélectionnés',
  'isales.file.server.message.checkObsPath': 'Seuls les chiffres\, les lettres\, les traits d\'union (-) et les (.) de points sont autorisés',
  'isales.data.prop.isPushAgent': 'Pousser vers l\'agent',
  'isales.training.AssociatedSurvey': 'Questionnaire associé',
  'isales.business.result.description': 'Description des résultats de l\'entreprise',
  'isales.management.message.import.mapRule': 'Règle de mappage',
  'isales.agent.Transfer.devicetype': 'Type de périphérique cible',
  'isales.training.answerNumber': 'Nombre de réponses',
  'isales.appointment.message.deleteSuccess': 'Les appels sortants réservés sont supprimés avec succès',
  'isales.manual.title.lock': 'Verrouiller',
  'isales.data.prop.anonymType': 'Chiffrement',
  'isales.task.promotion.message.dndTimeltCrossExpectedTime': 'Le temps d\'envoi prévu ne peut pas être dans la période à ne pas déranger',
  'isales.common.title.ready': 'Prêt',
  'isales.taskinfo.title.chooseIvrFlow': 'Sélectionner le flux IVR',
  'isales.management.message.updateFail': 'Impossible de modifier les données de l\'appel sortant',
  'isales.file.message.rule.receiver.columnRepeat': 'La colonne destinataire ne peut pas être identique à la colonne attributaire',
  'isales.result.addOutgoingCallResult': 'Ajouter un résultat',
  'isales.task.promotion.updateFailed': 'Impossible de modifier la tâche de marketing multimédia',
  'isales.tasklogic.field.addCallnoLogic': 'Ajouter une politique de contact',
  'isales.taskTime.message.saveFail': 'Impossible de modifier l\'heure d\'appel sortant',
  'isales.notification.receiver.deletetips': 'Êtes-vous sûr de vouloir supprimer les données sélectionnées ?',
  'isales.taskresult.title.queryTime': 'Informations',
  'isales.manual.message.afterNow': 'l\'heure de fin ne peut pas être antérieure à l\'heure actuelle',
  'isales.datasourcemanagement.field.backdbSchema': 'Retour Schéma DataSource',
  'isales.agent.label.skillName': 'Nom de file d\'attente de compétence',
  'isales.sendStatus.field.fail': 'Échec',
  'isales.taskstatus.field.finish': 'Expiré',
  'isales.statistics.field.touchRateDescription': 'Taux d\'atteinte',
  'isales.taskinfo.message.batchEditCallLogic.CallResultNotification': 'Les politiques existantes peuvent être modifiées\, et de nouvelles politiques seront ajoutées à côté des politiques existantes',
  'isales.data.title.create': 'Nouveau',
  'isales.file.title.rule.property.name': 'Nom de l\'attribut',
  'isales.busi.result.item.fail': 'Échec',
  'isales.task.promotion.saveSuccess': 'La tâche de marketing multimédia est enregistrée avec succès',
  'isales.import.message.status': 'Statut',
  'isales.taskpolicy.field.lossRate': 'Taux min. de perte d\'appels',
  'isales.rbtresult.field.calledbusycalling': 'l\'appelé est occupé et l\'appelé n\'a pas enregistré la fonction d\'appel en attente',
  'isales.taskinfo.field.taskType': 'Type de tâche',
  'isales.taskresultexport.title.exported': 'À exporter',
  'isales.notification.receiver.number.placeholder': 'Entrez le numéro du destinataire',
  'isales.taskcalllogic.message.callNoPolicyEmpty': 'Ajoutez au moins une politique de numéro',
  'isales.specialday.message.weeklyFormatError': 'Le format de la période de date spéciale est incorrect',
  'isales.training.averageScore': 'Score moyen',
  'isales.result.view': 'Afficher la cause de l\'échec de l\'appel sortant',
  'isales.tasklogic.field.callresultpolicy': 'Politique de résultat des appels sortants',
  'isales.taskpolicy.field.previewTimeoutInterval': 'Prévisualiser l\'intervalle de délai (s)',
  'isales.data.propConfig.title.isPushAgent': 'Pousser vers l\'agent',
  'isales.management.message.deleteConfirm': 'Êtes-vous sûr de vouloir supprimer les données d\'appel sortant sélectionnées ?',
  'isales.agent.calledroute.delfail': 'Échec de la suppression des données locales',
  'isales.work.title.calledNum': 'Appels traités',
  'isales.audio.title.calltype': 'Type d\'appel',
  'isales.datasourcemanagement.field.dbUserName': 'Nom du DataSource',
  'isales.agent.calledroute.extcode': 'code d\'étalement',
  'isales.manual.message.recipient': 'Saisissez un destinataire',
  'isales.appointment.message.deleteFail': 'Impossible de supprimer les appels sortants réservés',
  'isales.taskstatistics.field.numberOfConnectedCalls': 'Appels connectés',
  'isales.taskcalllogic.field.callnoPolicy': 'Politique de contact',
  'isales.taskinfo.title.commonIvrFlow': 'IVR commune',
  'isales.specialday.field.Thursday': 'Jeudi',
  'isales.management.message.calledNoTwo': 'Deuxième numéro appelé',
  'isales.appointment.title.call': 'Appeler',
  'isales.taskinfo.field.noSpecifiedAgent': 'File d\'attente de compétences entière',
  'isales.taskinfo.field.specifiedAgent': 'Agent spécifié',
  'isales.manual.title.calledNo': 'Numéro sortant',
  'isales.task.message.completionRate': 'Taux d\'achèvement des tâches',
  'isales.agent.button.createComplete': 'Finition',
  'isales.manual.message.dataId.Empty': 'dataId ne peut pas être vide',
  'isales.tasklogic.message.busiresultpolicyNotSaved': 'Sauvegardez d\'abord la politique de résultat d\'entreprise existante\!',
  'isales.taskresultexport.title.progress': 'Exportation',
  'isales.task.message.NoStartTask': 'Aucune tâche répondant aux conditions n\'est disponible',
  'isales.taskresult.field.failsResultnotobtained': 'Les données d\'appel sortant sont réécrites lorsqu\'aucun résultat n\'est obtenu après l\'expiration de l\'appel',
  'isales.taskstatistics.placeholder.called': 'Appelé \: {0}',
  'isales.audio.title.audiorate': 'Taux de lecture',
  'isales.agent.message.pwdRepeat': 'Le mot de passe soumis est le même que le mot de passe récemment modifié',
  'isales.result.addAgentTips': 'Un maximum de 20000 agents peuvent être ajoutés',
  'isales.agent.calledroute.ivr': 'Veuillez sélectionner IVR',
  'isales.busi.result.message.agentstatus.fail': 'Impossible d\'interroger le statut de l\'agent',
  'isales.import.message.uploadedfileformatvalidate': 'Format de fichier incorrect. Sélectionnez un fichier CSV',
  'isales.agent.calledroute.recordexist': 'Le code d\'accès et le code d\'extension sont déjà liés\, veuillez entrer un nouveau code d\'extension',
  'isales.taskinfo.placeholder.input': '--Entrez--',
  'isales.appointment.message.createFail': 'Impossible de créer l\'appel sortant réservé',
  'isales.taskstatistics.field.failCode': 'Cause d\'échec',
  'isales.tasklogic.field.logic': 'Opérateur',
  'isales.management.message.serverImport': 'Utilisation du serveur',
  'isales.agent.calledroute.updatecalledroute': 'Modifier',
  'isales.agent.query.error': 'l\'agent cible ne peut pas être le même que l\'exécuteur de l\'enregistrement sélectionné',
  'isales.audio.title.recording': 'Enregistrement de prévisualisation',
  'isales.taskmanagement.title.tip.batchEditTask': 'Sélectionnez la tâche à modifier',
  'isales.task.message.successRate': 'Taux de réussite',
  'isales.task.title.callBack': 'Rejeter',
  'isales.tasktemplate.title.autoTemplate': 'Modèle d\'appel sortant automatique',
  'isales.taskresult.title.exportQuestion': 'Question d\'exportation',
  'isales.business.name': 'Nom et prénom',
  'isales.datasourcemanagement.message.passValidateFailed': 'La valeur doit contenir au moins trois types des caractères suivants \: majuscules\, minuscules\, chiffres et caractères spéciaux (`-\!{\'@\'}\#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?). ',
  'isales.result.addResultMaptips': 'Un maximum de 50 résultats d\'appels sortants peuvent être ajoutés',
  'isales.datasourcemanagement.field.load': 'charger',
  'isales.datasourcemanagement.message.loadSuccess': 'Source de données chargée avec succès',
  'isales.datasourcemanagement.message.loadfail': 'Échec du chargement de la source de données',
  'isales.datasourcemanagement.message.confirLoad': 'Le chargement de la configuration de la source de données est une opération à haut risque \, veuillez procéder avec prudence.#now',
  'isales.statistics.business.endWeek': 'Fin de la semaine',
  'isales.statistics.business.trend.title': 'Rapport sur les tendances commerciales des appels sortants',
  'isales.statistics.business.title': 'Rapport commercial d\'appels sortants',
  'isales.appointment.message.plaintext': 'l\'utilisateur n\'a pas l\'autorisation de texte clair et ne peut pas lancer d\'appels. Veuillez activer l\'autorisation en texte brut',
  'isales.statistics.business.currentDateCheck': 'La date sélectionnée doit être antérieure à la date actuelle',
  'isales.statistics.business.startYear': 'Année de début',
  'isales.agent.message.description.limit': 'La description de la file d\'attente de compétences ne peut pas dépasser 50 caractères',
  'isales.statistics.business.filteringIndicator': 'Indicateurs de filtrage',
  'isales.statistics.business.subBusiResult': 'Résultat de la sous-entreprise',
  'isales.statistics.business.dategt6month': 'La fourchette de mois ne peut pas dépasser 6 mois',
  'isales.operlog.message.exportSurveyData': 'Exporter des questions de formation',
  'isales.taskinfo.message.agent': 'Les informations de l\'agent sont anormales. Vérifiez s\'il existe d\'autres agents que la file d\'attente de compétences actuelle',
  'isales.warning.data.export': 'Les données exportées contiennent des informations personnelles. Faites preuve de prudence lorsque vous effectuez cette opération',
  'isales.datasourcemanagement.field.dsType.dm': 'DM',
  'isales.statistics.business.noMoreThanFive': 'Le nombre d\'éléments sélectionnés ne peut pas dépasser 5',
  'isales.statistics.business.yearLessThan': 'l\'année de fin ne peut pas être antérieure à l\'année de début',
  'isales.statistics.business.errorInfo': 'l\'enregistrement a échoué. Sélectionnez au moins un indicateur',
  'isales.statistics.business.indicatorName': 'Nom de l\'indicateur',
  'isales.statistics.business.noMoreThanEight': 'Le nombre d\'éléments sélectionnés ne peut dépasser 8',
  'isales.statistics.business.taskId': 'ID de tâche',
  'isales.agent.message.updateCtiAndDbFail': 'Échec du rafraîchissement',
  'isales.management.message.SelectNumberMax': 'Sélectionnez jusqu\'à 100 numéros d\'appel à la fois',
  'isales.basicinfo.title.addCallNo': 'Ajouter un numéro d\'appel',
  'isales.taskmanagement.title.deleteFailExitOne': 'Configurer au moins un numéro appelant pour la tâche d\'appel sortant',
  'isales.failInterval.check': 'Il peut y avoir des risques lorsque l\'intervalle est inférieur à 10 s',
  'isales.taskmanagement.title.agentStatistics': 'Statistiques de travail des agents',
  'isales.work.message.daterequest': 'l\'heure de début et de fin doit être passée en même temps',
  'isales.task.promotion.whatsApp': 'WhatsApp',
  'isales.task.promotion.whatsAppChannel': 'Chaîne WhatsApp',
  'isales.task.promotion.whatsApp.message.template': 'Modèle de message',
  'isales.task.promotion.whatsApp.message.template.select': 'Veuillez sélectionner un modèle de message WhatsApp',
  'isales.task.promotion.whatsApp.channel.select': 'Sélectionnez un canal WhatsApp',
  'isales.task.promotion.whatsApp.message.id': 'ID de message de modèle',
  'isales.task.promotion.whatsApp.message.name': 'Nom du message du modèle',
  'isales.task.promotion.whatsApp.variable.description': 'Model Message Name',
  'isales.task.promotion.whatsApp.variable.content': 'Modèle Nom du message',
  'isales.work.title.selectAgent': 'Sélectionner un agent',
  'isales.work.title.agent.workno': 'ID de l\'agent',
  'isales.message.promptForAgent': 'Veuillez entrer l\'ID de l\'agent',
  'isales.button.search': 'Demandes de renseignements',
  'isales.button.reset': 'Réinitialiser',
  'isales.title.agent.workno': 'ID de l\'agent',
  'isales.label.agent.accountcode': 'Compte de service',
  'isales.agent.work.statistics.AgentName': 'Nom de l\'agent',
  'isales.button.cancel': 'Annuler',
  'isales.button.confirm': 'Confirmer',
  'isales.file.message.FilePathTips': 'Ce paramètre est optionnel. Le chemin par défaut du fichier d\'appel sortant est optionnel. Si le fichier d\'appel sortant est stocké dans un dossier dans le chemin par défaut\, le chemin du fichier doit être défini',
  'isales.blacklist.message.timeserror': 'Erreur de limite de temps',
  'isales.blacklist.message.perioderror': 'Erreur de période de restriction',
  'isales.datasourcemanagement.message.updateDataSourceSuccess': 'La source de données a été modifiée avec succès',
  'isales.datasourcemanagement.message.updateDataSourceFail': 'Impossible de modifier la source de données',
  'isales.taskpolicy.field.utilRateCalculateOne': 'Utilisation de l\'agent = Durée totale de l\'appel/Durée totale de connexion',
  'isales.taskpolicy.field.utilRateCalculateTwo': 'Utilisation de l\'agent = (Durée totale d\'appel + Durée de l\'état de travail)/Durée totale de connexion',
  'isales.tasklogic.field.eq': '=',
  'isales.tasklogic.field.neq': '!=',
  'isales.tasklogic.field.egt': '>=',
  'isales.tasklogic.field.elt': '<=',
  'isales.taskmanagement.title.batchEdit': 'Modification par lots',
  'isales.task.status.running': 'En cours',
  'isales.task.status.pause': 'Suspendu',
  'isales.task.finished': 'Terminé',
  'isales.task.notFinished': 'Incomplet',
  'isales.task.info': 'Informations sur la tâche',
  'isales.task.benginEndTime': 'Heure de début et de fin',
  'isales.task.detail': 'Détails de la tâche de l\'appel sortant',
  'isales.taskmanagement.startTask': 'Démarrer une tâche',
  'isales.step.taskinfo': 'informations sur la tâche',
  'isales.step.callpolicy': 'stratégie sortante',
  'isales.step.outboundcalldata': 'données de tâche',
  'isales.calltime.title.default.datetime': 'période de date régulière',
  'isales.calltime.title.spec.date': 'date spéciale',
  'isales.calltime.title.add.date': 'ajouter une date spéciale',
  'isales.calltime.title.add.time.period': 'ajouter une période',
  'isales.calltime.title.holidayName': 'nom de date spéciale',
  'isales.calltime.title.holidayType': 'taper',
  'isales.calltime.title.holiday': 'temps spécial',
  'isales.calltime.title.spec.datetime.period': 'période spéciale',
  'isales.calltime.task.template': 'modèle de tâche',
  'isales.calltime.task.template.select.placeholder': 'Possibilité de créer des tâches sortantes à partir de modèles',
  'isales.calltime.task.callerno.select.placeholder': 'Veuillez sélectionner le numéro d\'appel',
  'isales.calltime.task.begin.end.date': 'Heure de début et de fin de la tâche',
  'isales.calldata.prop.filter': 'Filtrage des attributs de données des appels sortants',
  'isales.taskinfo.header.policy': 'stratégie de tâche',
  'isales.taskinfo.call.header.policy': 'stratégie d\'appel',
  'isales.taskmanagement.title.sysAuto': 'Le système appelle automatiquement',
  'isales.work.message.dateRangeRequest': 'Veuillez sélectionner la plage de temps de la requête',
  'isales.work.message.taskNameLengthLimit': 'Le nom de la tâche ne peut pas dépasser 50 caractères',
  'isales.work.message.workNoLengthLimit': 'La longueur de l\'ID de l\'agent ne peut pas dépasser 5 caractères',
  'isales.work.message.workNoTypeLimit': 'Doit être 0 ou un entier positif',
  'isales.label.agent.agentType': 'Type d\'agent',
  'isales.label.agent.role': 'Rôle',
  'isales.calleeNo.length.max32': 'La longueur maximale du numéro d\'appel sortant est de 32 caractères',
  'isales.label.calldata.order': 'séquence d\'appel',
  'isales.tasklogic.field.callOrderMethod.byData': 'Par attribut de données',
  'isales.tasklogic.field.callOrderMethod.orderType': 'Trier par',
  'isales.label.multiple.callno.round': 'Tournées d\'appels clients multi-numéros',
  'isales.task.result.export.query': 'Aller à la vue',
  'isales.task.result.export.skip': 'Skip',
  'isales.task.result.export.message': 'La tâche d\'exportation a été créée avec succès. Voulez-vous la voir dans la liste d\'exportation ?',
  'isales.rbtrecognition.remark.length': 'La longueur de la chaîne dépasse la plage de valeurs. La longueur doit être inférieure ou égale à 200 caractères',
  'isales.task.field.callFlag0': 'À exécuter',
  'isales.task.field.callFlag1': 'Exécution',
  'isales.task.field.callFlag2': 'Terminé',
  'isales.taskinfo.field.agentType4': 'audio',
  'isales.taskinfo.field.agentType11': 'Vidéo',
  'isales.taskinfo.field.agentType5': 'Multimédia',
  'isales.taskinfo.field.agentType99': 'Polyvalent',
  'isales.appointment.title.appointState': 'Statut de réservation',
  'isales.task.field.appointState1': 'Réservé',
  'isales.task.field.appointState2': 'Préparer le rappel',
  'isales.task.field.appointState3': 'Rappelable',
  'isales.task.field.appointState4': 'Terminé',
  'isales.taskinfo.title.adjustAgent': 'Ajustement de l\'agent',
  'isales.taskinfo.check.empty': 'le paramètre ne peut pas être vide',
  'isales.tasktype.field.robot.create': 'Nouvel appel sortant du robot',
  'isales.tasktype.field.auto.info': 'IVR commune',
  'isales.tasktype.field.intelligent.info': 'robot intelligent',
  'isales.task.result.callrecord.conversation': 'Enregistrement de conversation',
  'isales.taskmanagement.title.batchEditTaskInfo.errTaskType': 'Les tâches de différents types ne peuvent pas être modifiées par lots',
  'isales.taskInfo.title.callCompletedNumTips': 'Lorsque le nombre d\'appels terminés dépasse la valeur de ce paramètre, le système ne récupère pas automatiquement les appels',
  'isales.taskmanagement.title.pauseTask.askConfirm': 'Êtes-vous sûr de vouloir suspendre la tâche d\'appel sortant ? Si la tâche est interrompue de force, les appels en cours peuvent être affectés',
  'isales.tasktype.field.auto.robot.create': 'Création d\'une RVI commune',
  'isales.tasktype.field.intelligent.robot.create': 'Créer un nouveau robot intelligent',
  'isales.management.title.callbot.auto': 'Configurez le bouton pour appeler le robot',
  'isales.taskmanagement.title.taskDataVolume': 'Volume de données de tâche',
  'isales.taskmanagement.title.callCompletionRate': 'Taux d\'achèvement des appels',
  'isales.taskmanagement.title.importData.template': 'Modelo',
  'isales.taskmanagement.title.importData.upload': 'Téléchargement',
  'isales.taskmanagement.title.importData.selectFile': 'Sélectionner un fichier',
  'isales.taskmanagement.pauseTask': 'Tâche de pause',
  'isales.taskInfo.spec.date.title.quotasTips': 'Cette configuration indique la date et la période spéciales qui nécessitent des appels sortants',
  'isales.calltime.title.spec.date.empty.warn': 'Date spéciale non configurée !',
  'isales.tasklogic.message.empty.orderNo': 'Il y a un numéro de série vide, veuillez le saisir à nouveau',
  'isales.taskmanagement.exportResult': 'Exporter le résultat de la tâche',
  'isales.outboundBusiness.statistic.di': 'Semaine',
  'isales.outboundBusiness.statistic.week': ' ',
  'isales.rbtrecognition.remark.tip': 'Caractères spéciaux qui ne peuvent pas être contenus:',
  'isales.business.callInterval.message.must': 'un intervalle est requis',
  'isales.tasktype.field.robot': 'robot sortant',
  'isales.taskmanagement.title.setAgent': 'Affectation d\'agents',
  'isales.taskInfo.title.taskTemplate': 'Modèle de tâche',
  'isales.taskmanagement.title.taskTime': 'Temps de la tâche',
  'isales.taskInfo.title.quotas': 'Exécution',
  'isales.taskInfo.title.quotasTips': 'Lorsque le nombre d\'appels marqués par l\'agent comme le résultat du service précédent dépasse la valeur de ce paramètre, le système ne récupère pas automatiquement les appels',
  'isales.taskInfo.title.pickUpNum': 'Quantité réclamée en une seule fois',
  'isales.taskInfo.title.pickUpCondition': 'Conditions d\'extraction de données',
  'isales.taskInfo.title.filterCondition': 'État',
  'isales.taskInfo.title.callCompletedNum': 'Appels complétés',
  'isales.taskmanagement.title.sysAuto.template': 'Modèle d\'appel sortant automatique du système',
  'isales.taskmanagement.title.sysAuto.templateAdd': 'Création d\'un modèle d\'appel sortant automatique du système',
  'isales.tasktype.field.robot.template': 'Modèle d\'appel sortant de robot',
  'isales.tasktype.field.robot.templateAdd': 'Création d\'un modèle d\'appel sortant pour un robot',
  'isales.template.title.addManual': 'Modèle d\'appel sortant manuel',
  'isales.manual.field.callingRecord': 'Enregistrement d\'appel sortant',
  'isales.call.count': 'Nombre d\'appels',
  'isales.task.promotion.createBeginTime': 'Heure de début de la création',
  'isales.task.promotion.createEndTime': 'Heure de fin de création',
  'isales.task.promotion.timeerror': 'L\'heure d\'entrée en vigueur doit être antérieure à l\'heure d\'expiration',
  'isales.busiresult.description.length': 'La longueur de la chaîne de caractères dépasse la plage de valeurs. La longueur doit être inférieure ou égale à 500 caractères',
  'isales.import.coverMode.tips': 'L\'importation d\'écrasement supprimera toutes les données à appeler. L\'exécution prend beaucoup de temps. Faites preuve de prudence lors de l\'importation d\'écrasement',
  'isales.taskmanagement.title.taskContent': 'Contenu de la tâche',
  'isales.taskmanagement.title.saveAndImportData': 'Enregistrer et importer des données',
  'isales.task.promotion.numberOnly': 'ne peut être qu\'un entier non négatif',
  'isales.data.lock': 'Certaines données d\'appel sortant ne sont pas traitées. Par exemple, vous importez, ajoutez ou supprimez des données d\'appel sortant. Veuillez réessayer plus tard',
  'isales.survey.type': 'Type de questionnaire',
  'ccsurvey.label.scenarioType.manual': 'questionnaire manuel',
  'ccsurvey.label.scenarioType.manualIvr': 'Manuel vs Questionnaire IVR',
  'isales.tasktype.field.manualIvr.info': 'Jouer le processus du questionnaire',
  'ccsurvey.info.tip36': 'La question est requise',
  'ccsurvey.transfer.tip36': 'Suspendre le passage au questionnaire de lecture IVR',
  'isales.taskinfo.message.callintervalrangeMax': 'Veuillez saisir un nombre entier positif compris entre 1 et 86 400.',
  'isales.notification.receiver.whatsapp.placeholder': 'Entrez le numéro du destinataire avec l\'indicatif régional international',
  'isales.data.title.more': 'Plus d\'infos',
  'isales.manual.exit.preview': 'Exécution d\'un appel sortant de prévisualisation',
  'isales.manual.exit.preview.tips': 'Si l\'aperçu expire ou si la fenêtre est fermée, le système vous appellera automatiquement.',
  'isales.result.search.displayResultOrCode': 'Veuillez entrer le code ou la description',
  'isales.management.message.dbIp': 'Le format IP est incorrect',
  'isales.agent.message.modifyPwdVdnFailed': 'La modification du mot de passe de l\'administrateur de configuration n\'est pas autorisée !',
  'isales.taskmanagement.title.addSysAuto': 'Créer un nouveau système pour appeler automatiquement',
  'isales.taskresult.field.failsNoValidVDN': 'Aucun VDN valide',
  'isales.language.label.en_US': 'Anglais',
  'isales.management.message.batchEditCallLogic.notSupportBusiresultInCMS': 'Les appels sortants automatiques ne prennent pas en charge la configuration de la stratégie de résultats métier. Voulez-vous vraiment la modifier par lots ?',
  'isales.language.label.zh_CN': 'Chinois',
  'isales.language.label.es_ES': 'Espagnol',
  'isales.business.result.message.checkCodePoint': 'Seuls des chiffres, des lettres, -,... peuvent être saisis.',
  'isales.import.calldata.selecttime': 'Veuillez sélectionner l\'heure d\'exécution',
  'isales.statistics.message.chooseCondition': 'Veuillez d\'abord sélectionner les conditions de la requête',
  'isales.taskpolicy.field.time': '(Deuxième)',
  'isales.taskInfo.title.filterSubCondition': 'Filtrer les sous-conditions',
  'isales.agent.work.statistics.tableTips': 'Sélectionnez d\'abord la tâche d\'appel sortant ou l\'agent que vous souhaitez afficher.',
  'isales.file.message.rule.add.success':'Règle créée avec succès.',
  'isales.file.title.server.add.success':'Les informations du serveur ont été créées avec succès.',
  'isales.management.title.selectResult':'Sélectionner le résultat de l\'appel sortant',
  'isales.specialday.placeholder.year.beginTime': 'Format : MM-DD, par exemple : 12-01',
  'isales.management.message.appointTime': 'L\'heure d\'appel réservée ne peut pas être antérieure à l\'heure système actuelle',
  'isales.management.message.intelligence.taskName': 'La longueur du nom de la tâche du type d\'appel sortant du robot intelligent ne peut pas dépasser 36 octets. Une lettre ou un chiffre occupe un octet, et un caractère chinois occupe trois octets.',
}
