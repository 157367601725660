<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.tasklogic.field.addturn')" width="616" :close-on-click-modal="false">
            <sweet-form :label-position="labelPosition" label-width="150px" ref="callTurn"  :model="callTurn" :rules="callTurnRule">
                <sweet-form-item label=" ">
                    <sweet-radio-group style="margin-left:10px"
                                       v-model="addCallNoType">
                        <sweet-radio label="0">{{ $t('isales.tasklogic.field.exist.numbertype')}}</sweet-radio>
                        <sweet-radio label="1">{{ $t('isales.tasklogic.field.add.numbertype')}}</sweet-radio>
                    </sweet-radio-group>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.tasklogic.field.numbertype')" prop="dataTypeExit" v-if="addCallNoType==='0'">
                <sweet-select v-model="this.callTurn.dataTypeExit" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                    <sweet-option v-for="(item, index) in this.callNoTypes" :key="item"
                                  :label="item"
                                  :value="item"/>
                </sweet-select>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.tasklogic.field.numbertype')" prop="dataTypeAdd" v-if="addCallNoType==='1'">
                <sweet-input v-model="this.callTurn.dataTypeAdd" :placeholder="$t('isales.taskinfo.placeholder.input')"/>
                </sweet-form-item>
            </sweet-form>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="closeDialog">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
    import {queryAllCallNoType} from "@/views/isales/api/systemAutoCallTask.js";

    export default {
        name: "iSalesCallTurnSelect",
        props: {
            show: {
                type: Boolean,
                default: false
            },
          newNumberTypeList:{
            type: Array,
            default: []
          }
        },
        data() {
            return {
                regEnSpecialChar : /[`~!@#$%^&*()+=|{}'":;\[\]/?.]/,
                regCnSpecialChar : /[~！@#￥%……&*（）——+|{}【】‘；：”“’,、？]/,
                addCallNoType: '1',
                callNoTypes: [],
                callTurn: {
                    dataTypeAdd: '',
                    dataTypeExit: '',
                },
              callTurnRule: {
                dataTypeExit: [
                  {required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']},
                ],
                dataTypeAdd: [
                  {required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']},
                  {validator: this.checkCallNoType, trigger: ['change', 'blur']},
                  {validator: this.checkDuplicate, trigger: ['blur', 'change']},
                  {validator: this.checkDefaultType, trigger: ['blur', 'change']}
                ],
              },
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            }
        },
        methods: {
            closeDialog(){
              this.callTurn.dataTypeAdd = null
              this.callTurn.dataTypeExit = null
              this.dialogVisible = false
            },
            checkCallNoType(rule, value, callback) {
                if (value && value.length > 20) {
                    callback(new Error(this.$t('aicc.lengthLimit', {limit: 20})));
                }
                if(this.regEnSpecialChar.test(value) || this.regCnSpecialChar.test(value)){
                    callback(new Error(this.$t('isales.business.result.message.specialStr')+ "`~!@#$%^&*()+=|{}'\":;\\[\\]/?.~！@#￥%……&*（）——+|{}【】‘；：”“’,、？"));
                }else{
                  callback()
                }
            },
          checkDuplicate(rule, value, callback) {
            let exist = -1;
            if (this.addCallNoType === '1') {
              exist = this.callNoTypes.indexOf(value)
              if (exist < 0) {
                let index = this.newNumberTypeList.indexOf(value)
                if (index >= 0) {
                  callback(new Error(this.$t('isales.taskcalllogic.message.dataTypeExist')));
                }else{
                  callback()
                }
              } else {
                callback(new Error(this.$t('isales.taskcalllogic.message.dataTypeExist')));
              }
            }else{
              callback()
            }
          },
          checkDefaultType(rule, value, callback) {
            if (value === '9D9T9') {
              callback(new Error(this.$t('isales.taskcalllogic.message.dataTypeDefaultNoCreate')));
            } else {
              callback()
            }
          },
            async queryAllCallNoType() {
                let queryCondition = {};
                queryAllCallNoType(queryCondition).then(res => {
                    if (res) {
                        this.callNoTypes = res;
                    }
                })
            },
            confirm() {
              this.$refs['callTurn'].validate(valid => {
                if (valid) {
                  this.dialogVisible = false
                  let selectCallNoType
                  if (this.addCallNoType === '1') {
                    this.newNumberTypeList.push(this.callTurn.dataTypeAdd)
                    selectCallNoType = this.callTurn.dataTypeAdd
                  }else{
                    selectCallNoType = this.callTurn.dataTypeExit
                  }
                  this.callTurn.dataTypeAdd = null
                  this.callTurn.dataTypeExit = null
                  this.$emit('confirmChooseCallTurn', selectCallNoType);
                }
              })
            }
        },
        async created() {
            await this.queryAllCallNoType();
        },
    }
</script>

<style scoped>

</style>