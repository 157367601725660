import { ElMessage } from 'element-plus'

function AiccElMessage(params) {
  if (typeof params == 'object') {
    if (params.type === 'warning' || params.type === 'error') {
      params.showClose = true
      params['show-close'] = true
      params.duration = 10000
      params.offset = 56
    } else {
      params.showClose = true
      params['show-close'] = true
      params.duration = 5000
      params.offset = 56
    }
    ElMessage(params)
  } else {
    const option = {
      message: params
    }
    option.type = 'info'
    option.showClose = true
    option['show-close'] = true
    option.duration = 5000
    option.offset = 56
    ElMessage(option)
  }
}

AiccElMessage.success = function (message) {
  const params = {
    message
  }
  params.type = 'success'
  params.showClose = true
  params['show-close'] = true
  params.duration = 5000
  params.offset = 56
  ElMessage(params)
}

AiccElMessage.info = function (message) {
  const params = {
    message
  }
  params.type = 'info'
  params.showClose = true
  params['show-close'] = true
  params.duration = 5000
  params.offset = 56
  ElMessage(params)
}

AiccElMessage.error = function (message) {
  const params = {
    message
  }
  params.type = 'error'
  params.showClose = true
  params['show-close'] = true
  params.duration = 10000
  params.offset = 56
  ElMessage(params)
}

AiccElMessage.warning = function (message) {
  const params = {
    message
  }
  params.type = 'warning'
  params.showClose = true
  params['show-close'] = true
  params.duration = 10000
  params.offset = 56
  ElMessage(params)
}

export default AiccElMessage
