import cookieUtils from '@/utils/cookie'

// 如果为阿拉伯语或波斯语，进行RTL翻转，传入当前html的document
export function setDirection(selfDocument) {
  if (
    cookieUtils.getCookie('u-locale') === 'fa_IR' ||
    cookieUtils.getCookie('u-locale') === 'ar_SA' ||
    (document !== selfDocument && top.window.isRTL == true)
  ) {
    selfDocument.body.dir = 'rtl'
    selfDocument.body.style.direction = 'rtl'
    selfDocument.body.classList.add('RTL')
    if (document !== selfDocument) {
      const link = selfDocument.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = 'resource.root/bes/omni/base/component/skins/arab/assets/styles/hlds.css'
      const head = selfDocument.getElementsByTagName('head')[0]
      head.appendChild(link)
    }
    return true
  } else {
    return false
  }
}
