export default {
  "message.center.label.delete" : "\u5220\u9664",
  "message.center.label.reply" : "\u56DE\u590D",
  "message.center.label.allSelect" : "\u5168\u9009",
  "message.center.label.messageCenter" : "\u901A\u77E5\u4E2D\u5FC3",
  "message.center.label.modify" : "\u4FEE\u6539",
  "message.center.label.sendTime" : "\u53D1\u9001\u65F6\u95F4",
  "message.center.label.senderName" : "\u53D1\u9001\u4EBA",
  "message.center.label.bulletinType" : "\u516C\u544A\u7C7B\u578B",
  "message.center.message.selectMessages" : "\u8BF7\u9009\u62E9\u901A\u77E5!",
  "message.center.message.willDeleteMessages" : "\u662F\u5426\u5220\u9664\u6240\u9009\u7684\u6D88\u606F?",
  "message.center.message.delete" : "\u5220\u9664",
  "message.center.label.expireTime" : "\u5931\u6548\u65F6\u95F4",
  "message.center.label.refresh" : "\u5237\u65B0",
  "message.center.label.isRead" : "\u8BBE\u7F6E\u5DF2\u8BFB",
  "message.center.button.query" : "\u67E5\u8BE2",
  "message.center.label.search" : "\u641C\u7D22",
  "message.center.label.select" : "\u7B5B\u9009",
  "message.center.label.deleteisread" : "\u5220\u9664\u6240\u6709\u5DF2\u8BFB",
  "message.center.button.clear" : "\u91CD\u7F6E",
  "message.center.label.newMessage" : "\u53D1\u9001\u901A\u77E5",
  "message.center.label.receiverGroup" : "\u63A5\u6536\u7FA4\u7EC4",
  "message.center.label.receiver" : "\u6536\u4EF6\u4EBA",
  "message.center.nocitiction.system" : "\u7CFB\u7EDF\u516C\u544A",
  "message.center.nocitiction.interal" : "\u5185\u90E8\u516C\u544A",
  "message.center.label.download" : "\u4E0B\u8F7D",
  "message.center.nocitiction.email" : "\u90AE\u4EF6",
  "message.center.nocitiction.outbox" : "\u53D1\u4EF6\u7BB1",
  "message.center.nocitiction.ineermessage" : "\u7AD9\u5185\u4FE1",
  "message.center.nocitiction.inbox" : "\u6536\u4EF6\u7BB1",
  "message.center.nocitiction.bulletin" : "\u516C\u544A",
  "message.center.nocitiction.message" : "\u77ED\u4FE1",
  "message.center.nocitiction.callback.record" : "\u56DE\u8C03\u8BB0\u5F55",
  "message.center.nocitiction.draftBox" : "\u8349\u7A3F\u7BB1",
  "message.center.nocitiction.sending.records" : "\u53D1\u9001\u8BB0\u5F55",
  "message.center.nocitiction.modifyBulletin" : "\u4FEE\u6539\u516C\u544A",
  "message.center.nocitiction.modifyInternalMessage" : "\u4FEE\u6539\u7AD9\u5185\u4FE1",
  "message.center.nocitiction.sending.records.smsType" : "\u77ED\u4FE1\u65B9\u5F0F",
  "message.center.nocitiction.sending.records.status" : "\u53D1\u9001\u72B6\u6001",
  "message.center.nocitiction.callback.attachment" : "\u9644\u4EF6",
  "message.center.nocitiction.callback.status" : "\u56DE\u8C03\u72B6\u6001",
  "message.center.nocitiction.callback.receivetime" : "\u63A5\u6536\u65F6\u95F4",
  "message.center.nocitiction.sender" : "\u53D1\u4EF6\u4EBA",
  "message.center.nocitiction.sending.records.sended" : "\u5DF2\u53D1\u9001",
  "message.center.nocitiction.sending.records.unsend" : "\u672A\u53D1\u9001",
  "message.template.type.system" : "\u7CFB\u7EDF\u901A\u77E5",
  "message.template.type.internal" : "\u5185\u90E8\u901A\u77E5",
  "message.template.mediaType.email" : "\u90AE\u4EF6",
  "message.template.type.out" : "\u5916\u90E8\u901A\u77E5",
  "message.template.mediaType.shortmessage" : "\u77ED\u4FE1",
  "message.template.mediaType.internal" : "\u7AD9\u5185\u4FE1",
  "message.template.message.type" : "\u901A\u77E5\u7C7B\u578B",
  "message.template.message.mediaType" : "\u6D88\u606F\u53D1\u9001\u65B9\u5F0F",
  "message.template.message.messageTemplateId" : "\u6A21\u677FID",
  "message.template.message.createTime" : "\u521B\u5EFA\u65F6\u95F4",
  "message.template.message.messageTemplateName" : "\u6A21\u677F\u540D\u79F0",
  "message.template.message.searchMessageTemplateName" : "\u8BF7\u8F93\u5165\u6A21\u677F\u540D\u79F0",
  "message.template.message.messageTemplateCreate" : "\u521B\u5EFA\u901A\u77E5\u6A21\u677F",
  "message.template.message.operate" : "\u64CD\u4F5C",
  "message.template.button.view" : "\u67E5\u770B",
  "message.template.button.edit" : "\u7F16\u8F91",
  "message.template.button.delete" : "\u5220\u9664",
  "message.template.message.basicInformation" : "\u57FA\u672C\u4FE1\u606F",
  "message.template.message.notificationContent" : "\u901A\u77E5\u5185\u5BB9",
  "message.template.message.recipient" : "\u6536\u4EF6\u4EBA",
  "message.template.message.selectRecipient" : "\u8BF7\u9009\u62E9\u6536\u4EF6\u4EBA",
  "message.template.message.inputTemplateName" : "\u8BF7\u8F93\u5165\u6A21\u677F\u540D\u79F0",
  "ccnotification.notifition.sendmessage.selectGroupOrRecipient" : "\u6536\u4EF6\u4EBA\u548C\u63A5\u6536\u7FA4\u7EC4\u5FC5\u987B\u4E8C\u9009\u4E00",
  "message.template.message.inputRecipient" : "\u8BF7\u8F93\u5165\u6536\u4EF6\u4EBA",
  "message.template.message.inputTitle" : "\u8BF7\u8F93\u5165\u901A\u77E5\u6807\u9898",
  "message.template.message.generate" : "\u751F\u6210\u6A21\u677F",
  "message.template.message.cancel" : "\u53D6\u6D88",
  "message.template.message.recipientError" : "\u6709\u6536\u4EF6\u4EBA\u4E0D\u5B58\u5728\uFF0C\u8BF7\u91CD\u65B0\u9009\u62E9\u6536\u4EF6\u4EBA",
  "message.template.message.ccOrBccRecipientError" : "\u53D1\u9001\u6210\u529F\uFF0C\u6709\u6284\u9001\u4EBA\u6216\u5BC6\u9001\u4EBA\u4E0D\u5B58\u5728",
  "message.template.message.agentRecipientError" : "\u6709\u6536\u4EF6\u4EBA\u672A\u7ED1\u5B9A\u5EA7\u5E2D\uFF0C\u8BF7\u91CD\u65B0\u9009\u62E9\u6536\u4EF6\u4EBA",
  "message.template.message.queryagentbebindedfailed" : "\u67E5\u8BE2\u5DF2\u7ED1\u5B9A\u5EA7\u5E2D\u7684\u7528\u6237\u5931\u8D25",
  "message.template.message.agentbebinded" : "\u9009\u62E9\u7684\u6536\u4EF6\u4EBA\u672A\u88AB\u5EA7\u5E2D\u7ED1\u5B9A",
  "message.template.message.nameError" : "\u6A21\u677F\u540D\u79F0\u4E0D\u80FD\u4E3A\u7A7A\u6216\u8D85\u8FC7200\u4E2A\u5B57\u7B26",
  "message.template.message.createSuccess" : "\u521B\u5EFA\u901A\u77E5\u6A21\u677F\u6210\u529F",
  "message.template.message.titleError" : "\u6A21\u677F\u6807\u9898\u4E0D\u80FD\u8D85\u8FC7200\u4E2A\u5B57\u7B26",
  "message.template.message.typeError" : "\u901A\u77E5\u7C7B\u578B\u9519\u8BEF",
  "message.template.message.nameLengthError" : "\u540D\u79F0\u4E0D\u80FD\u4E3A\u7A7A\u4E14\u4E0D\u80FD\u5927\u4E8E200\u5B57\u7B26\u6216\u5305\u542B\u7279\u6B8A\u5B57\u7B26",
  "message.template.message.contentLengthError" : "\u5185\u5BB9\u4E0D\u80FD\u4E3A\u7A7A\u4E14\u4E0D\u80FD\u8D85\u957F",
  "message.template.message.mediaTypeError" : "\u6D88\u606F\u53D1\u9001\u65B9\u5F0F\u9519\u8BEF",
  "message.template.message.titleLengthError" : "\u6807\u9898\u4E0D\u80FD\u5927\u4E8E200\u5B57\u7B26\u6216\u5305\u542B\u7279\u6B8A\u5B57\u7B26",
  "message.template.message.recipientLengthError" : "\u6536\u4EF6\u4EBA\u6570\u91CF\u8D85\u51FA\u9650\u5236",
  "message.template.message.descriptionLengthError" : "\u63CF\u8FF0\u4FE1\u606F\u8D85\u957F",
  "message.template.message.createTemplateFail" : "\u521B\u5EFA\u901A\u77E5\u6A21\u677F\u5931\u8D25",
  "message.template.message.contentError" : "\u5185\u5BB9\u4E0D\u80FD\u4E3A\u7A7A",
  "message.template.message.frequently" : "\u53D1\u9001\u91CF\u5DF2\u8D85\u8FC7\u6BCF\u5929\u9608\u503C",
  "message.template.message.sendEmailError" : "\u53D1\u4EF6\u4EBA\u90AE\u7BB1\u4E3A\u7A7A",
  "message.template.message.phoneError" : "\u7535\u8BDD\u4E0D\u5B58\u5728",
  "message.template.message.nameDuplicationError" : "\u6A21\u677F\u540D\u79F0\u91CD\u590D",
  "message.template.message.modifySuccess" : "\u4FEE\u6539\u901A\u77E5\u6A21\u677F\u6210\u529F",
  "message.template.message.deleteSuccess" : "\u5220\u9664\u901A\u77E5\u6A21\u677F\u6210\u529F",
  "message.template.message.messageTemplateModify" : "\u4FEE\u6539\u6A21\u677F",
  "message.template.message.deleteTemplateFail" : "\u5220\u9664\u901A\u77E5\u6A21\u677F\u5931\u8D25",
  "message.template.message.save" : "\u4FDD\u5B58",
  "message.template.message.modifyTemplateFail" : "\u4FEE\u6539\u901A\u77E5\u6A21\u677F\u5931\u8D25",
  "message.template.message.willDeleteTemplate" : "\u5220\u9664\u6A21\u677F\u53EF\u80FD\u5BFC\u81F4\u5173\u8054\u5F15\u7528\u7684\u529F\u80FD\u4E0D\u53EF\u7528\uFF0C\u662F\u5426\u786E\u8BA4\u5220\u9664\uFF1F",
  "message.template.message.noneTemplate" : "\u6A21\u677F\u4E0D\u5B58\u5728",
  "message.template.message.deleteTemplate" : "\u5220\u9664\u901A\u77E5\u6A21\u677F",
  "ccnotification.notifition.sendmessage.sysmessage" : "\u7CFB\u7EDF\u901A\u77E5",
  "ccnotification.notifition.sendmessage.internalmessage" : "\u5185\u90E8\u901A\u77E5",
  "ccnotification.notifition.sendmessage.internal" : "\u7AD9\u5185\u4FE1",
  "ccnotification.notifition.sendmessage.bulletinType" : "\u516C\u544A\u7C7B\u578B",
  "ccnotification.notifition.sendmessage.priority" : "\u4F18\u5148\u7EA7",
  "ccnotification.notifition.sendmessage.enterBulletinType" : "\u8BF7\u9009\u62E9\u516C\u544A\u7C7B\u578B",
  "ccnotification.notifition.sendmessage.priorityUrgent" : "\u7D27\u6025",
  "ccnotification.notifition.sendmessage.priorityGeneral" : "\u4E00\u822C",
  "ccnotification.notifition.sendmessage.bulletin" : "\u516C\u544A",
  "ccnotification.notifition.sendmessage.expireTime" : "\u5931\u6548\u65F6\u95F4",
  "ccnotification.notifition.sendmessage.priorityFault" : "\u6545\u969C",
  "ccnotification.notifition.sendmessage.priorityExtraUrgent" : "\u7279\u6025",
  "ccnotification.notifition.sendmessage.bulletinTypeName" : "\u516C\u544A\u7C7B\u578B\u540D\u79F0",
  "ccnotification.notifition.sendmessage.inputBulletinType" : "\u8BF7\u8F93\u5165\u516C\u544A\u7C7B\u578B\u540D\u79F0",
  "ccn.smshuawei.message.table.label" : "\u534E\u4E3A\u4E91\u77ED\u4FE1\u914D\u7F6E",
  "ccn.smsnoconfig.table.label" : "\u77ED\u4FE1\u53F7\u7801\u914D\u7F6E",
  "ccn.smsnoconfig.table.header.countrycode" : "\u53D1\u9001\u65B9\u56FD\u5BB6\u7801",
  "ccnotification.notifition.sendmessage.invalidSect" : "\u5931\u6548\u65F6\u95F4\u6BB5",
  "ccn.smsnoconfig.table.header.countrycode.desc" : "\u56FD\u5BB6",
  "ccn.smsnoconfig.pop.title.createtitle" : "\u65B0\u589E\u77ED\u4FE1\u53F7\u7801",
  "ccn.smsnoconfig.pop.check.duplicatecode" : "\u8BE5\u53F7\u7801\u5DF2\u5B58\u5728",
  "ccn.smsnoconfig.pop.search.reminder" : "\u8BF7\u8F93\u5165\u53F7\u7801\u641C\u7D22",
  "ccn.smsnoconfig.pop.check.selectwarning" : "\u8BF7\u9009\u62E9\u9700\u8981\u5220\u9664\u7684\u8BB0\u5F55",
  "ccn.smsnoconfig.pop.title.edittitle" : "\u7F16\u8F91\u77ED\u4FE1\u53F7\u7801",
  "ccn.smsnoconfig.pop.check.deletewarning" : "\u786E\u5B9A\u5220\u9664\u6240\u9009\u7684\u6570\u636E\u5417",
  "ccn.smsnoconfig.pop.check.deletesuccess" : "\u5220\u9664\u6210\u529F",
  "ccn.smsnoconfig.pop.check.deletefailed" : "\u5220\u9664\u5931\u8D25",
  "ccnotification.notifition.sendmessage.entersender" : "\u8BF7\u9009\u62E9\u53D1\u9001\u53F7\u7801",
  "ccnotification.notifition.sendmessage.recipient" : "\u6536\u4EF6\u4EBA",
  "ccnotification.notifition.sendmessage.sender" : "\u53D1\u9001\u53F7\u7801",
  "ccnotification.notifition.sendmessage.enterrecipient" : "\u8BF7\u9009\u62E9\u6536\u4EF6\u4EBA",
  "ccnotification.notifition.sendmessage.recipientGroup" : "\u63A5\u6536\u7FA4\u7EC4",
  "ccnotification.notifition.sendmessage.send" : "\u53D1\u9001",
  "ccnotification.notifition.sendmessage.enterRecipientGroup" : "\u8BF7\u9009\u62E9\u63A5\u6536\u7FA4\u7EC4",
  "ccnotification.notifition.sendmessage.enterCCRecipient" : "\u8BF7\u9009\u62E9\u6284\u9001\u4EBA",
  "ccnotification.notifition.sendmessage.ccRecipient" : "\u6284\u9001\u4EBA",
  "ccnotification.notifition.sendmessage.bccRecipient" : "\u5BC6\u9001\u4EBA",
  "ccnotification.notifition.sendmessage.enterBCCRecipient" : "\u8BF7\u9009\u62E9\u5BC6\u9001\u4EBA",
  "ccnotification.notifition.sendmessage.notifitiontitle" : "\u8BF7\u8F93\u5165\u6807\u9898",
  "ccnotification.notifition.sendmessage.selecttenant" : "\u8BF7\u9009\u62E9\u79DF\u6237!",
  "ccnotification.notifition.sendmessage.maxselectcount" : "\u6700\u591A\u9009\u62E9100\u4F4D!",
  "ccnotification.notifition.sendmessage.entertenantname" : "\u8BF7\u8F93\u5165\u79DF\u6237\u540D\u79F0",
  "ccnotification.notifition.sendmessage.entercompanyName" : "\u8BF7\u8F93\u5165\u516C\u53F8\u540D\u79F0",
  "ccnotification.notifition.sendmessage.selectuser" : "\u8BF7\u9009\u62E9\u5458\u5DE5!",
  "ccnotification.notifition.sendmessage.enteraccount" : "\u8BF7\u8F93\u5165\u4E1A\u52A1\u5E10\u53F7",
  "ccnotification.notifition.sendmessage.cancelSelectall" : "\u53D6\u6D88\u5168\u90E8",
  "ccnotification.notifition.sendmessage.enterusername" : "\u8BF7\u8F93\u5165\u540D\u79F0",
  "ccnotification.notifition.sendmessage.sendsuccess" : "\u53D1\u9001\u6210\u529F",
  "ccnotification.notifition.sendmessage.selectall" : "\u9009\u62E9\u5168\u90E8",
  "ccnotification.notifition.sendmessage.savesuccess" : "\u4FDD\u5B58\u6210\u529F",
  "ccnotification.notifition.sendmessage.paramvalidatefailed" : "\u53C2\u6570\u6821\u9A8C\u5931\u8D25",
  "ccnotification.notifition.sendmessage.receiverPhoneIsNull" : "\u63A5\u6536\u8005\u6216\u63A5\u6536\u8005\u53F7\u7801\u4E3A\u7A7A",
  "ccnotification.notifition.sendmessage.sendTimeFailed" : "\u53D1\u9001\u65F6\u95F4\u5C0F\u4E8E\u5F53\u524D\u65F6\u95F4",
  "ccnotification.notifition.sendmessage.sendexception" : "\u53D1\u9001\u5F02\u5E38",
  "ccnotification.notifition.sendmessage.sendfailed" : "\u53D1\u9001\u5931\u8D25",
  "ccnotification.notifition.sendmessage.selectmode" : "\u9009\u62E9\u65B9\u5F0F",
  "ccnotification.notifition.sendmessage.messagetitle" : "\u6807\u9898",
  "ccnotification.notifition.sendmessage.selectbyworkno" : "\u6309\u5DE5\u53F7\u9009\u62E9",
  "ccnotification.notifition.sendmessage.selectbyskill" : "\u6309\u6280\u80FD\u961F\u5217\u9009\u62E9",
  "ccnotification.notifition.sendmessage.selectbyorg" : "\u6309\u7EC4\u7EC7\u90E8\u95E8\u9009\u62E9",
  "ccnotification.notifition.sendmessage.senddate" : "\u53D1\u9001\u65E5\u671F",
  "ccnotification.notifition.sendmessage.delaysend" : "\u5B9A\u65F6\u53D1\u9001",
  "ccnotification.notifition.sendmessage.delaynotice" : "\u672C\u901A\u77E5\u5C06\u4E8E",
  "ccnotification.notifition.sendmessage.sendtimebeforenow" : "\u5B9A\u65F6\u53D1\u9001\u65F6\u95F4\u9700\u8981\u665A\u4E8E\u5F53\u524D\u65F6\u95F45\u5206\u949F\u4E4B\u540E",
  "ccnotification.notifition.sendmessage.limitdate" : "\u8BF7\u9009\u62E9\u5C0F\u4E8E2038-1-19\u7684\u65E5\u671F",
  "ccnotification.notifition.sendmessage.preview" : "\u9884\u89C8",
  "ccnotification.notifition.sendmessage.sendtime" : "\u53D1\u9001\u65F6\u95F4",
  "message.center.message.messageQueryResultIsEmpty" : "\u6D88\u606F\u67E5\u8BE2\u7ED3\u679C\u4E3A\u7A7A",
  "ccnotification.center.message.deleteAllRead" : "\u662F\u5426\u5220\u9664\u6240\u6709\u5DF2\u8BFB\u6D88\u606F",
  "ccnotification.center.message.setIsRead" : "\u8BBE\u7F6E\u6210\u5DF2\u8BFB",
  "ccnotification.notifition.receivermessage.unread" : "\u672A\u8BFB",
  "ccnotification.center.message.toIsRead" : "\u662F\u5426\u5C06\u9009\u62E9\u7684\u6D88\u606F\u8BBE\u7F6E\u6210\u5DF2\u8BFB",
  "ccnotification.notifition.receivermessage.isread" : "\u5DF2\u8BFB",
  "ccnotification.notifition.receivermessage.receiverSuccess" : "\u5DF2\u63A5\u6536",
  "ccnotification.notifition.receivermessage.all" : "\u5168\u90E8",
  "ccnotification.notifition.sendmessage.isSendall" : "\u5DF2\u53D1\u9001",
  "ccnotification.notifition.sendmessage.isSendallFail" : "\u5DF2\u53D1\u9001\uFF08\u5931\u8D25\uFF09",
  "ccnotification.notifition.sendmessage.referenceTemplate" : "\u5F15\u7528\u6A21\u677F",
  "ccnotification.notifition.sendmessage.templateselected" : "\u672C\u901A\u77E5\u5DF2\u5F15\u7528",
  "ccnotification.notifition.sendmessage.smsconfignull" : "\u77ED\u4FE1\u914D\u7F6E\u4E3A\u7A7A\u3002\u8BF7\u5148\u5728\u8FDB\u5165\u201C\u8BBE\u7F6E-\u77ED\u4FE1\u8BBE\u7F6E\u201D\u914D\u7F6E\u77ED\u4FE1\u4FE1\u606F",
  "ccnotification.notifition.sendmessage.templateselect" : "\u9009\u62E9\u6A21\u677F",
  "ccnotification.notifition.variable.searchVariableName" : "\u8BF7\u8F93\u5165\u53D8\u91CF\u540D\u79F0",
  "ccnotification.notifition.sendmessage.isSendallSuccess" : "\u5DF2\u53D1\u9001\uFF08\u6210\u529F\uFF09",
  "ccnotification.notifition.variable.variableType" : "\u8BF7\u9009\u62E9\u53D8\u91CF\u7C7B\u578B",
  "ccnotification.notifition.variable.searchTempdateName" : "\u8BF7\u8F93\u5165\u6A21\u677F\u540D\u79F0",
  "ccnotification.notification.variable.variablevalue" : "\u53D8\u91CF\u503C",
  "ccnotification.notification.variable.publicvariable" : "\u516C\u5171\u53D8\u91CF",
  "ccnotification.notification.variable.templatevariable" : "\u6A21\u677F\u53D8\u91CF",
  "ccnotification.notification.variable.templateselect" : "\u8BF7\u9009\u62E9\u6A21\u677F",
  "ccnotification.notification.variable.templatetype" : "\u6A21\u677F\u7C7B\u578B",
  "ccnotification.notification.variable.nameexist" : "\u8BE5\u53D8\u91CF\u540D\u5DF2\u5B58\u5728",
  "ccnotification.notifition.sendmessage.unSend" : "\u5F85\u53D1\u9001",
  "ccnotification.notifition.variable.templatename" : "\u6A21\u677F\u540D\u79F0",
  "ccnotification.notification.variable.editfailed" : "\u4FEE\u6539\u5931\u8D25",
  "ccnotification.notification.variable.template" : "\u6A21\u677F",
  "ccnotification.notifition.variable.variablename" : "\u53D8\u91CF\u540D\u79F0",
  "ccnotification.notifition.variable.selectvariable" : "\u9009\u62E9\u53D8\u91CF",
  "ccnotification.notifition.variable.insertvariable" : "\u63D2\u5165\u53D8\u91CF",
  "ccnotification.notifition.variable.syntax.error" : "\u8BED\u6CD5\u9519\u8BEF",
  "ccnotification.notifition.variable.been.used" : "\u88AB\u6A21\u677F\u5F15\u7528\u4E86\uFF0C\u4E0D\u80FD\u5220\u9664",
  "ccnotification.notifition.variable.createvariable" : "\u521B\u5EFA",
  "ccnotification.notifition.variable.close.syntax.error1" : "\u6A21\u677F\u5185\u5BB9\u5728",
  "ccnotification.notifition.variable.not.exist" : "\u4E0D\u5B58\u5728",
  "ccnotification.notifition.variable.variable" : "\u53D8\u91CF",
  "ccnotification.notifition.variable.close.syntax.error2" : "\u9644\u8FD1\u6709\u8BED\u6CD5\u9519\u8BEF\uFF0C\u8BF7\u68C0\u67E5${}\u662F\u5426\u95ED\u5408",
  "ccnotification.notifition.variable.namerule" : "\u53D8\u91CF\u540D\u79F0\u53EA\u80FD\u5305\u542B\u82F1\u6587\uFF0C\u6570\u5B57\uFF0C_\u548C.",
  "ccnotification.notifition.template.label.templateid" : "\u6A21\u677FID",
  "ccnotification.notifition.template.label.hwtemplateid" : "\u534E\u4E3A\u4E91\u6A21\u677FID",
  "ccnotification.notifition.template.label.signatureName" : "\u7B7E\u540D\u540D\u79F0",
  "ccnotification.notifition.template.label.templatetype" : "\u6A21\u677F\u7C7B\u578B",
  "ccnotification.notifition.template.label.varnumber" : "\u53D8\u91CF\u4E2A\u6570",
  "ccnotification.notifition.template.label.mspcontent" : "\u77ED\u4FE1\u6A21\u677F\u5185\u5BB9",
  "ccnotification.notifition.template.button.addvar" : "+\u65B0\u589E\u53D8\u91CF",
  "ccnotification.notifition.template.title.setting" : "\u53D8\u91CF\u8BBE\u7F6E",
  "ccnotification.notifition.template.label.index" : "\u5E8F\u53F7",
  "ccnotification.notifition.template.label.des" : "\u63CF\u8FF0",
  "ccnotification.notifition.template.label.type" : "\u7C7B\u578B",
  "ccnotification.notifition.template.label.value" : "\u9ED8\u8BA4\u503C",
  "ccnotification.notifition.template.label.operation" : "\u64CD\u4F5C",
  "ccnotification.notifition.template.message.money" : "-\u652F\u63011-20\u4F4D\u4EC5\u652F\u6301\u4F20\u5165\u80FD\u591F\u6B63\u5E38\u8868\u8FBE\u91D1\u989D\u7684\u6570\u5B57\u3001\u5C0F\u6570\u70B9",
  "ccnotification.notifition.template.button.up" : "\u4E0A\u79FB",
  "ccnotification.notifition.template.message.dateTime" : "\u65E5\u671F\uFF1Ayyyy-MM-dd\u3001\u65F6\u95F4\uFF1AHH:mm:ss",
  "ccnotification.notifition.template.message.text" : "-\u652F\u63011-20\u4F4D\u5B57\u7B26 \u4E0D\u5305\u542B 000",
  "ccnotification.notifition.template.message.phone" : "-\u652F\u63011-15\u4F4D\u6570\u5B57",
  "ccnotification.notifition.template.message.charDigit" : "-\u652F\u63011-20\u4F4D\u6570\u5B57\u5B57\u6BCD",
  "ccnotification.notifition.template.message.error" : "\u8F93\u5165\u4E0D\u7B26\u5408\u8981\u6C42",
  "ccnotification.notifition.template.button.down" : "\u4E0B\u79FB",
  "ccnotification.notifition.template.placeholder.hwtemplateid" : "\u8BF7\u8F93\u5165\u534E\u4E3A\u4E91\u6A21\u677FID",
  "ccnotification.notifition.template.placeholder.varnumber" : "\u8BF7\u8F93\u5165\u53D8\u91CF\u4E2A\u6570",
  "ccnotification.notifition.template.placeholder.signatureName" : "\u8BF7\u8F93\u5165\u7B7E\u540D\u540D\u79F0",
  "ccnotification.notifition.template.placeholder.var" : "\u8BF7\u8F93\u5165\u7B26\u5408\u8981\u6C42\u7684\u503C",
  "ccnotification.notifition.template.placeholder.des" : "\u8BF7\u8F93\u5165\u53D8\u91CF\u63CF\u8FF0",
  "ccnotification.notifition.template.message.hwsms" : "\u534E\u4E3A\u4E91\u77ED\u4FE1",
  "ccnotification.notifition.template.message.mspsms" : "\u77ED\u4FE1\u7F51\u5173",
  "ccnotification.smshuawei.message.updatesuccess" : "\u4FEE\u6539\u77ED\u4FE1\u5BF9\u63A5\u6E20\u9053\u914D\u7F6E\u6210\u529F",
  "ccnotification.smshuawei.message.updatefail" : "\u4FEE\u6539\u77ED\u4FE1\u5BF9\u63A5\u6E20\u9053\u914D\u7F6E\u5931\u8D25",
  "ccnotification.smshuawei.message.deletesuccess" : "\u5220\u9664\u77ED\u4FE1\u5BF9\u63A5\u6E20\u9053\u914D\u7F6E\u6210\u529F",
  "ccnotification.smshuawei.message.paramisempty" : "\u53C2\u6570\u4E3A\u7A7A",
  "ccnotification.smshuawei.message.deletefail" : "\u5220\u9664\u77ED\u4FE1\u5BF9\u63A5\u6E20\u9053\u914D\u7F6E\u5931\u8D25",
  "ccnotification.smshuawei.message.fail" : "\u5931\u8D25",
  "ccnotification.smshuawei.message.invalidappadress" : "\u65E0\u6548app\u63A5\u5165\u5730\u5740",
  "ccnotification.smshuawei.label.certTip.withoutProxy" : "\u8BF7\u4E0A\u4F20\u534E\u4E3A\u4E91\u670D\u52A1\u8BC1\u4E66",
  "ccnotification.smshuawei.label.certTip.withProxy" : "\u8BF7\u4E0A\u4F20\u4EE3\u7406\u670D\u52A1\u5668\u8BC1\u4E66",
  "ccnotification.smshuawei.label.appKey" : "app\u6807\u8BC6",
  "ccnotification.smshuawei.label.appSecret" : "app\u79D8\u94A5",
  "ccnotification.smshuawei.label.appAdress" : "app\u63A5\u5165\u5730\u5740",
  "ccnotification.smshuawei.label.certVerifyEnabled" : "\u662F\u5426\u542F\u7528\u8BC1\u4E66\u6821\u9A8C",
  "ccnotification.smshuawei.label.signatureChannelId" : "\u7B7E\u540D\u901A\u9053\u53F7",
  "ccnotification.smshuawei.label.config.explain" : "\u6CE8\uFF1A\u914D\u7F6E\u6570\u636E\u9700\u4E0E\u534E\u4E3A\u4E91\u77ED\u4FE1\u914D\u7F6E\u6570\u636E\u4E00\u81F4",
  "ccnotification.smshuawei.message.securityInfo" : "\u82E5\u5173\u95ED\u8BC1\u4E66\u6821\u9A8C\uFF0C\u5B58\u5728\u5BF9\u7AEF\u8EAB\u4EFD\u4EFF\u5192\u98CE\u9669\u3002\u63A8\u8350\u542F\u7528\u8BC1\u4E66\u6821\u9A8C\u3002",
  "ccnotification.smshuawei.message.specialCharacters" : "\u53C2\u6570\u53EA\u5141\u8BB8\u5305\u542B\u6570\u5B57\u6216\u5B57\u6BCD",
  "ccnotification.notifition.template.label.signatureChannelId" : "\u8BF7\u8F93\u5165\u7B7E\u540D\u901A\u9053\u53F7",
  "ccnotification.smshuawei.label.signatureChannelId.length" : "\u7B7E\u540D\u901A\u9053\u53F7\u957F\u5EA6\u8D85\u51FA\u53D6\u503C\u8303\u56F4",
  "ccnotification.smstemplate.message.var" : "\u53D8\u91CF\u7C7B\u578B\u3001\u4E2A\u6570\u3001\u63CF\u8FF0\u6216\u9ED8\u8BA4\u503C\u8F93\u5165\u4E0D\u7B26\u5408\u8981\u6C42",
  "ccnotification.smstemplate.message.smstemplateid" : "\u534E\u4E3A\u4E91\u6A21\u677Fid\u4E0D\u80FD\u4E3A\u7A7A\u4E14\u4E0D\u80FD\u8D85\u957F\u6216\u4E0D\u7B26\u5408\u89C4\u8303",
  "ccnotification.smstemplate.message.fail" : "\u521B\u5EFA\u534E\u4E3A\u4E91\u6A21\u677F\u5931\u8D25",
  "ccnotification.smstemplate.message.signatureName" : "\u7B7E\u540D\u540D\u79F0\u4E0D\u80FD\u4E3A\u7A7A\u4E14\u4E0D\u80FD\u8D85\u957F\u6216\u4E0D\u7B26\u5408\u89C4\u8303",
  "ccnotification.smstemplate.message.smstemplate" : "\u534E\u4E3A\u4E91\u6A21\u677F\u4FE1\u606F\u4E3A\u7A7A",
  "ccnotification.smstemplate.message.number" : "\u53EA\u80FD\u8F93\u5165\u6570\u5B57\u6700\u592710",
  "ccnotification.smstemplate.message.var.number" : "\u8F93\u5165\u7684\u53D8\u91CF\u4E2A\u6570\u548C\u6DFB\u52A0\u7684\u53D8\u91CF\u4E2A\u6570\u4E0D\u7B26",
  "ccnotification.common.spec.message" : "\u4E0D\u80FD\u8F93\u5165\u7279\u6B8A\u5B57\u7B26",
  "ccnotification.center.message.var.type" : "\u53D8\u91CF\u7C7B\u578B\uFF1A",
  "ccnotification.smstemplate.message.receiverPhone" : "\u6536\u4EF6\u4EBA\u4E0D\u5141\u8BB8\u91CD\u590D",
  "ccnotification.template.message.delete" : "\u6B64\u6A21\u677F\u5DF2\u88AB\u5F15\u7528\u4E0D\u5141\u8BB8\u5220\u9664\u3002",
  "ccnotification.center.message.var.des" : "\u53D8\u91CF\u63CF\u8FF0\uFF1A",
  "ccnotification.notifition.gatewaycontact.stop" : "\u8FDE\u63A5\u505C\u6B62",
  "ccnotification.notifition.gatewaycontact.success" : "\u8FDE\u63A5\u6210\u529F",
  "ccnotification.gatewayconfig.label.inputgatewayname" : "\u8BF7\u8F93\u5165\u7F51\u5173\u540D\u79F0",
  "ccnotification.center.message.var.value" : "\u53D8\u91CF\u503C\uFF1A",
  "ccnotification.gatewayconfig.label.gatewayname" : "\u7F51\u5173\u540D\u79F0",
  "ccnotification.gatewayconfig.label.lastmodifytime" : "\u6700\u540E\u4FEE\u6539\u65F6\u95F4",
  "ccnotification.gatewayconfig.label.protocol" : "\u534F\u8BAE\u7C7B\u578B",
  "ccnotification.gatewayconfig.label.configType" : "\u914D\u7F6E\u7C7B\u578B",
  "ccnotification.notifition.gatewaycontact.failed" : "\u8FDE\u63A5\u5931\u8D25",
  "ccnotification.gatewayconfig.label.defaultemailaddr" : "\u9ED8\u8BA4\u90AE\u4EF6\u5730\u5740",
  "ccnotification.gatewayconfig.label.userid" : "\u7528\u6237\u6807\u8BC6",
  "ccnotification.gatewayconfig.label.defaultsendname" : "\u9ED8\u8BA4\u7528\u6237\u540D\u79F0",
  "ccnotification.gatewayconfig.label.mailPassword" : "\u7528\u6237\u5BC6\u7801",
  "ccnotification.gatewayconfig.label.auth" : "\u8BA4\u8BC1\u6807\u5FD7",
  "ccnotification.gatewayconfig.label.code" : "\u7F16\u7801\u65B9\u5F0F",
  "ccnotification.gatewayconfig.label.mailPort" : "\u7AEF\u53E3",
  "ccnotification.gatewayconfig.label.mailHost" : "\u4E3B\u673A",
  "ccnotification.gatewayconfig.label.SSLEncryptionMode" : "\u52A0\u5BC6\u65B9\u5F0F",
  "ccnotification.gatewayconfig.label.inputCertCode" : "\u8BF7\u8F93\u5165\u8BC1\u4E66\u7F16\u7801",
  "ccnotification.gatewayconfig.label.selectCert" : "\u9009\u62E9\u8BC1\u4E66",
  "ccnotification.gatewayconfig.label.certScene" : "\u8BC1\u4E66\u4F7F\u7528\u573A\u666F",
  "ccnotification.gatewayconfig.label.certFile" : "\u8BC1\u4E66\u6587\u4EF6",
  "ccnotification.gatewayconfig.label.certType" : "\u8BC1\u4E66\u7C7B\u578B",
  "ccnotification.common.warning.message" : "\u8BF7\u9009\u62E9\u4E00\u6761\u6570\u636E",
  "ccnotification.gatewayconfig.label.sourceAddrTon" : "\u6E90\u5730\u5740\u7F16\u7801\u7C7B\u578B",
  "ccnotification.gatewayconfig.label.certCode" : "\u8BC1\u4E66\u7F16\u7801",
  "ccnotification.gatewayconfig.label.serviceType" : "\u670D\u52A1\u7C7B\u578B",
  "ccnotification.gatewayconfig.label.sourceAddrNpi" : "\u6E90\u5730\u5740\u7F16\u7801\u65B9\u6848",
  "ccnotification.gatewayconfig.label.destAddrNpi" : "\u76EE\u7684\u5730\u5740\u7F16\u7801\u65B9\u6848",
  "ccnotification.gatewayconfig.label.destAddrTon" : "\u76EE\u7684\u5730\u5740\u7F16\u7801\u7C7B\u578B",
  "ccnotification.gatewayconfig.label.dataCoding" : "\u4FE1\u606F\u683C\u5F0F",
  "ccnotification.gatewayconfig.label.esmClass" : "\u4FE1\u606F\u7C7B\u578B",
  "ccnotification.gatewayconfig.label.protocolId" : "\u534F\u8BAE\u6807\u8BC6",
  "ccnotification.gatewayconfig.label.registeredDelivery" : "\u8FD4\u56DE\u72B6\u6001\u786E\u8BA4\u62A5\u544A",
  "ccnotification.gatewayconfig.label.defaultsendno" : "\u9ED8\u8BA4\u53D1\u9001\u53F7\u7801",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlag" : "\u66FF\u6362\u6807\u8BC6",
  "ccnotification.gatewayconfig.label.defaultreceinfo" : "\u9ED8\u8BA4\u63A5\u6536\u53F7\u7801",
  "ccnotification.gatewayconfig.label.smDefaultMsgId" : "\u9ED8\u8BA4\u6D88\u606FID",
  "ccnotification.gatewayconfig.label.smppPduType" : "SMPP PDU \u7C7B\u578B",
  "ccnotification.gatewayconfig.label.recFlowController" : "\u4E0A\u884C\u6D41\u91CF\u63A7\u5236\u5668\u540D\u79F0",
  "ccnotification.gatewayconfig.label.smHost" : "SMG\u5730\u5740",
  "ccnotification.gatewayconfig.label.RcvAndSendMode" : "\u6536\u53D1\u6A21\u5F0F",
  "ccnotification.gatewayconfig.label.systemId" : "\u767B\u5F55\u540D",
  "ccnotification.gatewayconfig.label.smPassword" : "\u767B\u5F55\u53E3\u4EE4",
  "ccnotification.gatewayconfig.label.interfaceVersion" : "\u767B\u5F55\u6D88\u606F\u7248\u672C\u53F7",
  "ccnotification.gatewayconfig.label.smPort" : "SMG\u7AEF\u53E3",
  "ccnotification.gatewayconfig.label.systemType" : "ESME\u7C7B\u578B",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCount" : "\u5FC3\u8DF3\u65E0\u54CD\u5E94\u6700\u5927\u8FDE\u63A5\u6B21\u6570",
  "ccnotification.gatewayconfig.label.localPort" : "\u5BA2\u6237\u7AEF\u7AEF\u53E3",
  "ccnotification.gatewayconfig.label.heartbeatInterval" : "\u5FC3\u8DF3\u95F4\u9694\uFF08\u6BEB\u79D2\uFF09",
  "ccnotification.gatewayconfig.label.resendWaitTime" : "\u91CD\u53D1\u7B49\u5F85\u65F6\u95F4\uFF08\u6BEB\u79D2\uFF09",
  "ccnotification.gatewayconfig.label.resendNumber" : "\u91CD\u53D1\u6B21\u6570",
  "ccnotification.gatewayconfig.label.readTimeout" : "\u63A5\u6536\u8D85\u65F6\u65F6\u95F4\uFF08\u6BEB\u79D2\uFF09",
  "ccnotification.gatewayconfig.label.addrTon" : "ESME\u5730\u5740\u7C7B\u578B\u7F16\u53F7",
  "ccnotification.gatewayconfig.label.maxRcvError" : "\u63A5\u6536\u6700\u5927\u9519\u8BEF\u5305\u6570",
  "ccnotification.gatewayconfig.label.addrNpi" : "ESME\u5730\u5740\u7F16\u7801\u65B9\u5F0F",
  "ccnotification.gatewayconfig.label.transactionTimeout" : "\u4E8B\u52A1\u8D85\u65F6\u65F6\u95F4\uFF08\u6BEB\u79D2\uFF09",
  "ccnotification.gatewayconfig.label.addressRange" : "ESME\u5730\u5740\u8303\u56F4",
  "ccnotification.gatewayconfig.label.maxMessageLength" : "\u6700\u5927\u6D88\u606F\u957F\u5EA6\uFF08\u5B57\u8282\uFF09",
  "ccnotification.gatewayconfig.label.serverSocketPort" : "\u4E0A\u884C\u6D88\u606F\u63A5\u6536\u7AEF\u53E3",
  "ccnotification.gatewayconfig.label.deviceTypeDescript" : "\u8BBE\u5907\u7C7B\u578B\u63CF\u8FF0",
  "ccnotification.gatewayconfig.label.splitType" : "\u8D85\u957F\u77ED\u4FE1\u62C6\u5206\u65B9\u5F0F",
  "ccnotification.gatewayconfig.label.maxContentLength" : "\u6700\u5927\u5185\u5BB9\u957F\u5EA6\uFF08\u5B57\u8282\uFF09",
  "ccnotification.gatewayconfig.label.bindMode" : "\u6536\u53D1\u6A21\u5F0F",
  "ccnotification.gatewayconfig.label.deviceDescript" : "\u8BBE\u5907\u63CF\u8FF0",
  "ccnotification.gatewayconfig.label.senderPoolMinCount" : "\u53D1\u9001\u7EBF\u7A0B\u6C60\u6700\u5C0F\u6570",
  "ccnotification.gatewayconfig.label.receiverPoolMinCount" : "\u63A5\u6536\u7EBF\u7A0B\u6C60\u6700\u5C0F\u6570",
  "ccnotification.gatewayconfig.label.senderPoolMaxCount" : "\u53D1\u9001\u7EBF\u7A0B\u6C60\u6700\u5927\u6570",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacity" : "\u53D1\u9001\u7EBF\u7A0B\u6C60\u961F\u5217\u957F\u5EA6",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCount" : "\u63A5\u6536\u7EBF\u7A0B\u6C60\u6700\u5927\u6570",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacity" : "\u63A5\u6536\u7EBF\u7A0B\u6C60\u961F\u5217\u957F\u5EA6",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSend" : "\u6700\u5927\u53D1\u9001\u901F\u7387",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecv" : "\u6700\u5927\u63A5\u6536\u901F\u7387",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicy" : "\u8D85\u6D41\u91CF\u5904\u7406\u7B56\u7565",
  "ccnotification.gatewayconfig.label.splitMsgConcatMode" : "\u957F\u77ED\u4FE1\u5408\u5E76\u6A21\u5F0F",
  "ccnotification.gatewayconfig.label.addgatewayconfig" : "\u589E\u52A0\u7F51\u5173\u914D\u7F6E",
  "ccnotification.gatewayconfig.label.gatewayipaddrconfig" : "\u7F51\u5173\u5730\u5740\u5BF9\u63A5\u914D\u7F6E",
  "ccnotification.gatewayconfig.label.messageprotocol" : "\u6D88\u606F\u534F\u8BAE\u53C2\u6570\u914D\u7F6E",
  "ccnotification.gatewayconfig.label.addgateway" : "\u65B0\u5EFA\u7F51\u5173",
  "ccnotification.gatewayconfig.label.modifygateway" : "\u4FEE\u6539\u7F51\u5173",
  "ccnotification.gatewayconfig.label.mailHosttitle" : "\u4E3B\u673A\u5730\u5740\u5982: 127.0.0.1\u6216www.mail.com",
  "ccnotification.gatewayconfig.label.viewgateway" : "\u67E5\u770B\u7F51\u5173",
  "ccnotification.gatewayconfig.label.authtitle" : "\u8BA4\u8BC1\u6807\u5FD7\, true: \u8BA4\u8BC1 false: \u4E0D\u8BA4\u8BC1",
  "ccnotification.gatewayconfig.label.mailPorttitle" : "\u7AEF\u53E3\u8303\u56F4: 0~65535\u7684\u6574\u6570.SMTP\u7AEF\u53E3\u9ED8\u8BA4: 25",
  "ccnotification.gatewayconfig.label.SSLEncryptionModetitle" : "\u52A0\u5BC6\u65B9\u5F0F\, NONE:\u660E\u6587\u4F20\u8F93(\u9ED8\u8BA4\u7AEF\u53E3\u662F25) TLS:\u4F7F\u7528TLS\u534F\u8BAE\u52A0\u5BC6\u4F20\u8F93(\u9ED8\u8BA4\u7AEF\u53E3\u662F465) STARTTLS:\u4F7F\u7528STARTTLS\u547D\u4EE4\u52A0\u5BC6\u4F20\u8F93(\u9ED8\u8BA4\u7AEF\u53E3\u662F587)",
  "ccnotification.gatewayconfig.label.sourceAddrNpititle" : "\u6E90\u5730\u5740\u7F16\u7801\u65B9\u6848\u4E3A\u6570\u5B57",
  "ccnotification.gatewayconfig.label.sourceAddrTontitle" : "\u6E90\u5730\u5740\u7F16\u7801\u7C7B\u578B\u4E3A\u6570\u5B57",
  "ccnotification.gatewayconfig.label.destAddrTontitle" : "\u76EE\u7684\u5730\u5740\u7F16\u7801\u7C7B\u578B\u4E3A\u6570\u5B57",
  "ccnotification.gatewayconfig.label.destAddrNpititle" : "\u76EE\u7684\u5730\u5740\u7F16\u7801\u65B9\u6848\u4E3A\u6570\u5B57",
  "ccnotification.gatewayconfig.label.dataCodingtitle" : "0:ASCII\u4E32 4:\u4E8C\u8FDB\u5236\u4FE1\u606F 8:UCS2\u7F16\u7801",
  "ccnotification.gatewayconfig.label.registeredDeliverytitle" : "\u8FD4\u56DE\u72B6\u6001\u786E\u8BA4\u62A5\u544A\u4E3A\u6570\u5B57",
  "ccnotification.gatewayconfig.label.defaultsendnotitle" : "发送号码前缀规则：例如配置130则匹配130开头的号码，多个号码以英文分号分隔",
  "ccnotification.gatewayconfig.label.slipWindowSize" : "\u6ED1\u7A97\u5927\u5C0F",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlagtitle" : "0:\u66FF\u6362 1:\u4E0D\u66FF\u6362 2~255:\u4FDD\u7559",
  "ccnotification.gatewayconfig.label.defaultreceinfotitle" : "接收号码前缀规则：例如配置130则匹配130开头的号码，多个号码以英文分号分隔",
  "ccnotification.gatewayconfig.label.recFlowControllertitle" : "\u8BE5\u6761\u94FE\u8DEF\u53C2\u4E0E\u7684\u63A7\u5236\u4E0A\u884C\u6D88\u606F\u901F\u7387\u7684\u6D41\u91CF\u63A7\u5236\u5668\u540D\u79F0",
  "ccnotification.gatewayconfig.label.smppPduTypetitle" : "1:submit_sm  2:data_sm",
  "ccnotification.gatewayconfig.label.smPorttitle" : "SMG\u7AEF\u53E3\u8303\u56F4: 0~65535\u7684\u6574\u6570",
  "ccnotification.gatewayconfig.label.interfaceVersiontitle" : "\u767B\u5F55\u6D88\u606F\u7248\u672C\u53F7\u4E3A\u6570\u5B57",
  "ccnotification.gatewayconfig.label.heartbeatIntervaltitle" : "\u5FC3\u8DF3\u95F4\u9694\uFF08\u6BEB\u79D2\uFF09\u4E3A\u6570\u5B57\,\u5927\u5C0F\u8981\u5408\u9002",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCounttitle" : "\u5FC3\u8DF3\u65E0\u54CD\u5E94\u6700\u5927\u8FDE\u63A5\u6B21\u6570\u4E3A\u6570\u5B57\,\u5927\u5C0F\u8981\u5408\u9002",
  "ccnotification.gatewayconfig.label.resendNumbertitle" : "\u91CD\u53D1\u6B21\u6570\u4E3A\u6570\u5B57\,\u5927\u5C0F\u8981\u5408\u9002",
  "ccnotification.gatewayconfig.label.resendWaitTimetitle" : "\u91CD\u53D1\u7B49\u5F85\u65F6\u95F4\uFF08\u6BEB\u79D2\uFF09\u4E3A\u6570\u5B57\,\u5927\u5C0F\u8981\u5408\u9002",
  "ccnotification.gatewayconfig.label.readTimeouttitle" : "\u63A5\u6536\u8D85\u65F6\u65F6\u95F4\uFF08\u6BEB\u79D2\uFF09\u4E3A\u6570\u5B57\,\u5927\u5C0F\u8981\u5408\u9002\,0\u6807\u8BC6\u6C38\u4E0D\u8D85\u65F6",
  "ccnotification.gatewayconfig.label.maxRcvErrortitle" : "\u63A5\u6536\u6700\u5927\u9519\u8BEF\u5305\u6570\u4E3A\u6570\u5B57\,\u5927\u5C0F\u8981\u5408\u9002",
  "ccnotification.gatewayconfig.label.transactionTimeouttitle" : "\u4E8B\u52A1\u8D85\u65F6\u65F6\u95F4\uFF08\u6BEB\u79D2\uFF09\u4E3A\u6570\u5B57\,\u5927\u5C0F\u8981\u5408\u9002",
  "ccnotification.gatewayconfig.label.slipWindowSizetitle" : "\u6ED1\u7A97\u5927\u5C0F\u4E3A\u6570\u5B57\,\u5927\u5C0F\u8981\u5408\u9002",
  "ccnotification.gatewayconfig.label.splitTypetitle" : "\u8D85\u957F\u77ED\u4FE1\u62C6\u5206\u65B9\u5F0F\,0:\u7981\u6B62\u62C6\u5206 1:\u4E0D\u62C6\u5206 2:\u7269\u7406\u62C6\u5206 3:\u8D85\u957F\u62C6\u5206.",
  "ccnotification.gatewayconfig.label.maxContentLengthtitle" : "\u6700\u5927\u5185\u5BB9\u957F\u5EA6\uFF08\u5B57\u8282\uFF09\u4E3A\u6570\u5B57\,\u4EE5\u6B64\u4E3A\u5339\u914D\u5206\u62C6\u4F9D\u636E\,\u5982:140",
  "ccnotification.gatewayconfig.label.maxMessageLengthtitle" : "\u6700\u5927\u6D88\u606F\u957F\u5EA6\uFF08\u5B57\u8282\uFF09\u4E3A\u6570\u5B57\,\u6839\u636E\u534F\u8BAE\u89C4\u5B9A\,\u5982:2048",
  "ccnotification.gatewayconfig.label.bindModetitle" : "\u6536\u53D1\u6A21\u5F0F\,1:\u63A5\u6536\u6A21\u5F0F 2:\u53D1\u9001\u6A21\u5F0F 9:\u53D1\u9001\u63A5\u6536\u6A21\u5F0F",
  "ccnotification.gatewayconfig.label.senderPoolMaxCounttitle" : "\u53D1\u9001\u7EBF\u7A0B\u6C60\u6700\u5927\u6570\,\u5982:200",
  "ccnotification.gatewayconfig.label.senderPoolMinCounttitle" : "\u53D1\u9001\u7EBF\u7A0B\u6C60\u6700\u5C0F\u6570\u4E3A\u6570\u5B57\,\u5982:20",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacitytitle" : "\u53D1\u9001\u7EBF\u7A0B\u6C60\u961F\u5217\u957F\u5EA6\,\u5982:2000",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCounttitle" : "\u63A5\u6536\u7EBF\u7A0B\u6C60\u6700\u5927\u6570\,\u5982:200",
  "ccnotification.gatewayconfig.label.receiverPoolMinCounttitle" : "\u63A5\u6536\u7EBF\u7A0B\u6C60\u6700\u5C0F\u6570\,\u5982:20",
  "ccnotification.gatewayconfig.label.smHosttitle" : "SMG\u5730\u5740\u5982: 127.0.0.1",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSendtitle" : "\u6700\u5927\u53D1\u9001\u901F\u7387\u4E3A\u6570\u5B57",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecvtitle" : "\u6700\u5927\u63A5\u6536\u901F\u7387\u4E3A\u6570\u5B57",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacitytitle" : "\u63A5\u6536\u7EBF\u7A0B\u6C60\u961F\u5217\u957F\u5EA6\,\u5982:2000",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicytitle" : "\u76EE\u524D\u4EC5\u652F\u6301Ignore",
  "ccnotification.gatewayconfig.label.splitMsgConcatModetitle" : "\u957F\u77ED\u4FE1\u5408\u5E76\u6A21\u5F0F\,0:GSM\u7528\u6237\u6570\u636E\u5934 1:SMPP\u53C2\u6570",
  "ccnotification.gatewayconfig.label.addgatewaysuccess" : "\u7F51\u5173\u914D\u7F6E\u65B0\u589E\u6210\u529F",
  "ccnotification.gatewayconfig.label.modifygatewayfailed" : "\u7F51\u5173\u914D\u7F6E\u4FEE\u6539\u5931\u8D25",
  "ccnotification.gatewayconfig.label.modifygatewaysuccess" : "\u7F51\u5173\u914D\u7F6E\u4FEE\u6539\u6210\u529F",
  "ccnotification.gatewayconfig.label.deletegatewaysuccess" : "\u7F51\u5173\u914D\u7F6E\u5220\u9664\u6210\u529F",
  "ccnotification.gatewayconfig.label.deletegatewayfailed" : "\u7F51\u5173\u914D\u7F6E\u5220\u9664\u5931\u8D25",
  "ccnotification.gatewayconfig.label.configNameIsError" : "\u914D\u7F6E\u540D\u79F0\u53EA\u5141\u8BB8\u957F\u5EA610\u4EE5\u5185\u7684\u6570\u5B57\u6216\u5B57\u6BCD",
  "ccnotification.gatewayconfig.label.addgatewayfailed" : "\u7F51\u5173\u914D\u7F6E\u65B0\u589E\u5931\u8D25",
  "ccnotification.gatewayconfig.label.mailProtocolIsError" : "\u90AE\u4EF6\u534F\u8BAE\u7C7B\u578B\u5FC5\u987B\u4E3ASMTP\u3001POP3",
  "ccnotification.gatewayconfig.label.requestParamIsNull" : "\u5165\u53C2\u4E3A\u7A7A",
  "ccnotification.gatewayconfig.label.configNameAlreadyExists" : "\u914D\u7F6E\u540D\u79F0\u5DF2\u5B58\u5728",
  "ccnotification.gatewayconfig.label.configTypeIsError" : "\u914D\u7F6E\u7C7B\u578B\u9519\u8BEF\uFF0C\u53C2\u6570\u5185\u90E8\u914D\u7F6E\u7C7B\u578B\u4E0D\u4E00\u81F4",
  "ccnotification.gatewayconfig.label.paramIsNotInRange" : "\u53C2\u6570\u8303\u56F4\u9519\u8BEF",
  "ccnotification.gatewayconfig.label.sslencryptionmodeIsError" : "\u90AE\u4EF6\u52A0\u5BC6\u65B9\u5F0F\u53EA\u5141\u8BB8\uFF1ANONE\u3001STARTTLS\u6216TLS",
  "ccnotification.gatewayconfig.label.hostIsError" : "\u90AE\u4EF6\u4E3B\u673A\u53EA\u5141\u8BB8\u4F8B\u5982\uFF1A127.0.0.1\u6216www.mail.com\u683C\u5F0F",
  "ccnotification.gatewayconfig.label.authIsError" : "\u8BA4\u8BC1\u6807\u8BC6\u53EA\u5141\u8BB8true\u6216false",
  "ccnotification.gatewayconfig.label.dataCodingIsError" : "\u77ED\u4FE1\u4FE1\u606F\u683C\u5F0F\u53EA\u5141\u8BB8 0:ASCII\u4E32\uFF1B4:2\u8FDB\u5236\u4FE1\u606F\uFF1B8:UCS2\u7F16\u7801",
  "ccnotification.gatewayconfig.label.defaultsendnoIsError" : "\u9ED8\u8BA4\u90AE\u4EF6\u5730\u5740\u683C\u5F0F\u9519\u8BEF",
  "ccnotification.gatewayconfig.label.smppPduTypeIsError" : "\u77ED\u4FE1SMPP PDU \u7C7B\u578B\u53EA\u5141\u8BB8 1:submit_sm;2:data_sm",
  "ccnotification.gatewayconfig.label.splitTypeIsError" : "\u8D85\u957F\u77ED\u4FE1\u62C6\u5206\u65B9\u5F0F\u53EA\u5141\u8BB8:0:\u7981\u6B62\u62C6\u5206;1:\u4E0D\u62C6\u5206;2:\u7269\u7406\u62C6\u5206;3:\u8D85\u957F\u62C6\u5206",
  "ccnotification.gatewayconfig.label.paramContainsSpecialChar" : "\u53C2\u6570\u542B\u6709\u7279\u6B8A\u5B57\u7B26",
  "ccnotification.gatewayconfig.label.splitMsgConcatModeIsError" : "\u957F\u77ED\u4FE1\u5408\u5E76\u6A21\u5F0F--0:GSM\u7528\u6237\u6570\u636E\u5934\uFF1B1:SMPP\u53C2\u6570",
  "ccnotification.gatewayconfig.label.paramIsNotNumber" : "\u53C2\u6570\u53EA\u5141\u8BB8\u662F\u6570\u5B57",
  "ccnotification.gatewayconfig.label.bindModeIsError" : "\u77ED\u4FE1\u6536\u53D1\u6A21\u5F0F--1:\u63A5\u6536\u6A21\u5F0F\uFF1B2:\u53D1\u9001\u6A21\u5F0F\uFF1B9:\u53D1\u9001\u63A5\u6536\u6A21\u5F0F",
  "ccnotification.gatewayconfig.label.paramIsNegative" : "\u6570\u5B57\u4E0D\u80FD\u4E3A\u8D1F\u6570",
  "ccnotification.gatewayconfig.label.paramMaxLengthIsError" : "\u53C2\u6570\u6700\u5927\u957F\u5EA6\u8981\u5C0F\u4E8E500",
  "ccnotification.gatewayconfig.label.maxsmGatewayIsError" : "\u540C\u4E00\u79DF\u95F4\u4E0B\u7684\u77ED\u4FE1\u7F51\u5173\u914D\u7F6E\u6700\u591A\u53EA\u80FD\u670910\u6761",
  "ccnotification.gatewayconfig.label.maxEmailGatewayIsError" : "\u540C\u4E00\u79DF\u95F4\u4E0B\u7684\u6536\u90AE\u4EF6\u6216\u53D1\u90AE\u4EF6\u7F51\u5173\u914D\u7F6E\u6700\u591A\u53EA\u80FD\u67095\u6761",
  "ccnotification.gatewayconfig.label.selectdeletegatewayconfig" : "\u8BF7\u9009\u62E9\u9700\u8981\u5220\u9664\u7684\u7F51\u5173\u914D\u7F6E!",
  "ccnotification.gatewayconfig.label.checkConfigName" : "\u6570\u5B57\u548C\u5B57\u6BCD\u4E14\u5C0F\u4E8E10\u4F4D",
  "ccnotification.gatewayconfig.label.specialStrValidate" : "\u53ea\u5141\u8bb8\u4f7f\u7528\u5927\u5199\u5b57\u6bcd\u3001\u5c0f\u5199\u5b57\u6bcd\u548c\u6570\u5b57\u3002",
  "ccnotification.gatewayconfig.label.errorstylenumber" : "\u6570\u5B57\u6837\u5F0F\u9519\u8BEF",
  "ccnotification.sms.huawei" : "\u534E\u4E3A\u4E91\u77ED\u4FE1",
  "ccnotification.gatewayconfig.label.positive" : "\u6570\u5B57\u4E0D\u80FD\u4E3A\u8D1F\u6570",
  "ccnotification.notifition.recmessagerouterconfig.index" : "\u7F16\u53F7",
  "ccnotification.sms.gateway" : "\u77ED\u4FE1\u7F51\u5173\u77ED\u4FE1",
  "ccnotification.notification.recmessagerouterconfig.satisfaction" : "\u6EE1\u610F\u5EA6\u8C03\u67E5",
  "ccnotification.gatewayconfig.label.willDeletegateway" : "\u786E\u5B9A\u5220\u9664\u6240\u9009\u7684\u7F51\u5173\u914D\u7F6E\u5417\uFF1F",
  "ccnotification.notification.recmessagerouterconfig.intelligentOrder" : "\u667A\u80FD\u521B\u5EFA\u5DE5\u5355",
  "ccnotification.notification.recmessagerouterconfig.inbox" : "\u6536\u4EF6\u7BB1",
  "ccnotification.notification.recmessagerouterconfig.caseserver" : "\u670D\u52A1\u8C03\u7528",
  "ccnotification.notification.recmessagerouterconfig.emailOnlineChat" : "\u90AE\u4EF6\u6E20\u9053\u5728\u7EBF\u4EA4\u8C08",
  "ccnotification.notification.recmessagerouterconfig.serviceType" : "\u5B9E\u73B0\u65B9\u5F0F",
  "ccnotification.notification.recmessagerouterconfig.serviceName" : "\u670D\u52A1\u540D\u79F0",
  "ccnotification.notification.recmessagerouterconfig.mediaType" : "\u5A92\u4F53\u7C7B\u578B",
  "ccnotification.notification.recmessagerouterconfig.retryTimes" : "\u91CD\u8BD5\u6B21\u6570",
  "ccnotification.notification.recmessagerouterconfig.placeholder.receiver" : "\u8BF7\u8F93\u5165\u6536\u4EF6\u5730\u5740",
  "ccnotification.notification.recmessagerouterconfig.receiver" : "\u6536\u4EF6\u4EBA",
  "ccnotification.notification.recmessagerouterconfig.receiveAddress" : "\u6536\u4EF6\u5730\u5740",
  "ccnotification.notification.recmessagerouterconfig.deletenum.outstripping" : "\u4E00\u6B21\u81F3\u591A\u53EF\u4EE5\u5220\u9664\u4E00\u767E\u6761\u6570\u636E",
  "ccnotification.notification.recmessagerouterconfig.willdeleteselectconfig" : "\u786E\u5B9A\u8981\u5220\u9664\u6240\u9009\u914D\u7F6E\u5417\uFF1F",
  "ccnotification.notification.recmessagerouterconfig.selectconfig" : "\u8BF7\u9009\u62E9\u914D\u7F6E",
  "ccnotification.notification.recmessagerouterconfig.selectitem" : "\u8BF7\u9009\u62E9\u4E00\u6761\u6570\u636E",
  "ccnotification.notification.recmessagerouterconfig.gateway" : "\u7F51\u5173",
  "ccnotification.notification.recmessagerouterconfig.selectgateway" : "\u9009\u62E9\u7F51\u5173",
  "ccnotification.notification.recmessagerouterconfig.mobilerule" : "\u8BF7\u586B\u5199\u624B\u673A\u53F7\uFF0C\u591A\u4E2A\u624B\u673A\u53F7\u4EE5\u82F1\u6587\u5206\u53F7\u5206\u9694",
  "ccnotification.notification.recmessagerouterconfig.gatewaynotexist" : "\u6240\u9009\u7F51\u5173\u4E0D\u5B58\u5728",
  "ccnotification.notification.recmessagerouterconfig.defaultreceiveemail" : "\u9ED8\u8BA4\u63A5\u6536\u90AE\u7BB1",
  "ccnotification.notification.recmessagerouterconfig.receiveaddressexist" : "\u6536\u4EF6\u5730\u5740\u5DF2\u5B58\u5728",
  "ccnotification.notification.recmessagerouterconfig.receivernotexist" : "\u6240\u9009\u6536\u4EF6\u4EBA\u4E0D\u5B58\u5728",
  "ccnotification.notification.recmessagerouterconfig.defaultreceivenumber" : "\u9ED8\u8BA4\u63A5\u6536\u53F7\u7801",
  "ccnotification.notification.recmessagerouterconfig.defaultsendnumber" : "\u9ED8\u8BA4\u53D1\u9001\u53F7\u7801",
  "ccnotification.notification.label.upload" : "\u4E0A\u4F20",
  "ccnotification.notification.label.attachment" : "\u9644\u4EF6",
  "ccnotification.notification.label.downloadAttachment" : "\u4E0B\u8F7D\u9644\u4EF6",
  "ccnotification.notification.recmessagerouterconfig.defaultsendemail" : "\u9ED8\u8BA4\u53D1\u9001\u90AE\u7BB1",
  "ccnotification.notification.label.uploadAttachmentSuccess" : "\u4E0A\u4F20\u9644\u4EF6\u6210\u529F",
  "ccnotification.notification.label.hasUplaodtAttachment" : "\u5DF2\u4E0A\u4F20\u9644\u4EF6\u5217\u8868",
  "ccnotification.notification.fileUploadRiskWarning" : "\u60A8\u4E0A\u4F20\u7684\u6587\u4EF6\u6709\u5B89\u5168\u98CE\u9669\uFF0C\u8BF7\u786E\u8BA4\u662F\u5426\u7EE7\u7EED\u4E0A\u4F20\uFF1F",
  "ccnotification.notification.label.uploadAttachmentFailed" : "\u4E0A\u4F20\u9644\u4EF6\u5931\u8D25",
  "ccnotification.notification.label.fileUploadFileNameError" : "\u60A8\u4E0A\u4F20\u7684\u6587\u4EF6\u540D\u79F0\u4E0D\u6EE1\u8DB3\u8981\u6C42",
  "ccnotification.notification.label.fileUploadFileSizeError" : "\u60A8\u4E0A\u4F20\u7684\u6587\u4EF6\u5927\u5C0F\u8D85\u8FC7\u4E86\u9650\u5236",
  "ccnotification.notification.label.fileUploadFileTypeError" : "\u60A8\u4E0A\u4F20\u7684\u6587\u4EF6\u7C7B\u578B\u4E0D\u6EE1\u8DB3\u8981\u6C42",
  "ccnotification.notification.label.fileUploadFileIOError" : "\u60A8\u4E0A\u4F20\u7684\u6587\u4EF6\u53D1\u751F\u4E86\u89E3\u6790\u9519\u8BEF",
  "ccnotification.notification.label.deleteAttachmentSuccess" : "\u5220\u9664\u9644\u4EF6\u6210\u529F",
  "ccnotification.notification.label.isUploadAttachment" : "\u662F\u5426\u4E0A\u4F20\u9644\u4EF6",
  "ccnotification.notification.label.deleteAttachmentFailed" : "\u5220\u9664\u9644\u4EF6\u5931\u8D25",
  "ccnotification.notification.label.selectAttachment" : "\u8BF7\u9009\u62E9\u90AE\u4EF6\u9644\u4EF6",
  "ccnotification.notification.label.isDeleteAttachment" : "\u662F\u5426\u5220\u9664\u9644\u4EF6",
  "ccnotification.notification.label.isDownloadAttachment" : "若您下载的附件格式为Office文档格式，请做好安全防护",
  "ccnotification.notification.label.attachmentIsEmpty" : "\u9644\u4EF6\u4E3A\u7A7A",
  "ccnotification.notification.label.attachmentExceed10" : "\u4E0A\u4F20\u6570\u91CF\u8D85\u8FC710\u4E2A",
  "ccnotification.notification.label.createTemFileFailed" : "\u521B\u5EFA\u4E34\u65F6\u6587\u4EF6\u5931\u8D25",
  "ccnotification.notification.label.serverIsNotobs" : "\u9644\u4EF6\u53EA\u80FD\u4ECEOBS\u6587\u4EF6\u670D\u52A1\u5668\u4E0B\u8F7D,\u5176\u4ED6\u4E0D\u652F\u6301",
  "ccnotification.notification.label.downloadFromObsFailed" : "\u4ECEobs\u4E0B\u8F7D\u6587\u4EF6\u5931\u8D25   ",
  "ccnotification.notification.label.queryAttachmentFailed" : "\u67E5\u8BE2\u9644\u4EF6\u5931\u8D25",
  "ccnotification.notification.label.compressZipFileFailed" : "\u538B\u7F29zip\u6587\u4EF6\u5931\u8D25",
  "ccnotification.notification.message.noAttachement" : "\u60A8\u8FD8\u672A\u4E0A\u4F20\u9644\u4EF6",
  "ccnotification.notification.label.attachmentSizeError" : "\u90AE\u4EF6\u9644\u4EF6\u5927\u5C0F\u8D85\u8FC710M\u9650\u5236",
  "ccnotification.notification.label.nameContainSpecialChar" : "\u90AE\u4EF6\u9644\u4EF6\u540D\u79F0\u5305\u542B\u7279\u6B8A\u5B57\u7B26",
  "ccnotification.notification.label.incorrectAttachmentType" : "\u90AE\u4EF6\u9644\u4EF6\u7C7B\u578B\u9519\u8BEF",
  "ccnotification.notification.label.uploadPermissionFailed" : "\u90AE\u4EF6\u9644\u4EF6\u4E0A\u4F20\u6743\u9650\u6821\u9A8C\u5931\u8D25",
  "ccnotification.notification.label.fileInjectionRiskException" : "\u90AE\u4EF6\u9644\u4EF6\u6CE8\u5165\u98CE\u9669\u5F02\u5E38",
  "ccnotification.notification.label.downPermissionFailed" : "\u90AE\u4EF6\u9644\u4EF6\u4E0B\u8F7D\u6743\u9650\u6821\u9A8C\u5931\u8D25",
  "ccnotification.notification.label.checkAttachNameLegnth" : "\u90AE\u4EF6\u9644\u4EF6\u540D\u79F0\u957F\u5EA6\u4E0D\u80FD\u8D85\u8FC7100 ",
  "ccnotification.notification.systemmonitor.mspId" : "\u8282\u70B9\u6807\u8BC6",
  "ccnotification.notification.systemmonitor.modifyTime" : "\u4FEE\u6539\u65F6\u95F4",
  "ccnotification.notification.systemmonitor.rebuilding" : "\u91CD\u5EFA",
  "ccnotification.notification.systemmonitor.status" : "\u72B6\u6001",
  "ccnotification.notification.label.rebuildSuccess" : "\u91CD\u5EFA\u6210\u529F",
  "ccnotification.notification.label.rebuildFailed" : "\u91CD\u5EFA\u5931\u8D25",
  "ccnotification.notification.systemmonitor.gatewayName" : "\u7F51\u5173\u540D\u79F0",
  "ccnotification.notification.label.discounnectedSuccess" : "\u65AD\u5F00\u6210\u529F",
  "ccnotification.notification.label.rebuildContainsErrorId" : "\u91CD\u5EFA\u5931\u8D25\uFF0C\u8BF7\u70B9\u51FB\u67E5\u8BE2\u6309\u94AE\u66F4\u65B0\u5217\u8868\u540E\u91CD\u8BD5\u3002",
  "ccnotification.notification.label.discounnectedFailed" : "\u65AD\u5F00\u5931\u8D25",
  "ccnotification.gatewayconfig.label.selectredisconnectedgatewayconfig" : "\u8BF7\u9009\u62E9\u9700\u8981\u65AD\u5F00\u7684\u7F51\u5173\u914D\u7F6E!",
  "ccnotification.gatewayconfig.label.selectrebuildgatewayconfig" : "\u8BF7\u9009\u62E9\u9700\u8981\u91CD\u5EFA\u7684\u7F51\u5173\u914D\u7F6E!",
  "ccnotification.notification.systemmonitor.disconnected" : "\u65AD\u5F00",
  "ccnotification.gatewayconfig.label.selectredgatewayconfig" : "\u5DF2\u9009\u62E9\u7684\u7F51\u5173\u76D1\u63A7:",
  "ccnotification.gatewayconfig.label.INIT" : "\u521D\u59CB\u5316",
  "ccnotification.caseserver.fail.titleAndContentNull" : "\u6807\u9898\u548C\u5185\u5BB9\u4E3A\u7A7A",
  "ccnotification.caseserver.fail.serviceException" : "\u670D\u52A1\u5F02\u5E38",
  "ccnotification.gatewayconfig.label.statusisnoterror" : "\u72B6\u6001\u4E0D\u662F\u8FDE\u63A5\u5931\u8D25\uFF01",
  "ccnotification.caseserver.fail.duplicateContent" : "\u5185\u5BB9\u91CD\u590D",
  "ccnotification.flowcontrol.setFlowType" : "\u6D41\u91CF\u9650\u5236\u7C7B\u578B",
  "ccnotification.flowcontrol.getwayset" : "\u8BBE\u7F6E",
  "ccnotification.flowcontrol.title" : "\u901A\u77E5\u914D\u7F6E\u8BBE\u7F6E",
  "ccnotification.flowcontrol.eachThreshold" : "\u6BCF\u5929\u5355\u5A92\u4F53\u53D1\u9001\u9608\u503C",
  "ccnotification.caseserver.fail.categoryNotExist" : "\u5DE5\u5355\u5206\u7C7B\u7C7B\u522B\u4E0D\u5B58\u5728",
  "ccnotification.flowcontrol.messageThreshold" : "\u6BCF\u5929\u77ED\u4FE1\u53D1\u9001\u9608\u503C",
  "ccnotification.flowcontrol.modifySuccess" : "\u901A\u77E5\u914D\u7F6E\u4FEE\u6539\u6210\u529F",
  "ccnotification.flowcontrol.modifyFailed" : "\u901A\u77E5\u914D\u7F6E\u4FEE\u6539\u5931\u8D25\uFF0C\u8BF7\u6392\u67E5\u5165\u53C2\u6216\u8005\u7F51\u7EDC",
  "ccnotification.flowcontrol.emailThreshold" : "\u6BCF\u5929\u90AE\u4EF6\u53D1\u9001\u9608\u503C",
  "ccnotification.flowcontrol.getFailed" : "\u901A\u77E5\u914D\u7F6E\u83B7\u53D6\u5931\u8D25\uFF0C\u8BF7\u6392\u67E5\u7F51\u7EDC",
  "ccnotification.flowcontrol.countryCode" : "\u56FD\u5BB6\u7801",
  "ccnotification.flowcontrol.setThreshold" : "\u8BBE\u7F6E\u6D41\u91CF\u9608\u503C",
  "ccnotification.flowcontrol.recipient.countrycode" : "\u63A5\u6536\u65B9\u56FD\u5BB6\u7801",
  "ccnotification.flowcontrol.shortMsgCode" : "\u77ED\u4FE1\u53F7\u7801",
  "ccnotification.flowcontrol.setShortMsgCode" : "\u8BBE\u7F6E\u77ED\u4FE1\u53F7\u7801",
  "ccnotification.title.flowcontrol.receiveSmsFlag" : "\u662F\u5426\u5F00\u542F\u63A5\u6536\u4E0A\u884C\u77ED\u4FE1\u529F\u80FD",
  "ccnotification.flowcontrol.totalThreshold" : "\u6BCF\u5929\u603B\u53D1\u9001\u9608\u503C",
  "ccnotification.common.title.warning" : "\u8B66\u544A",
  "ccnotification.common.button.finish" : "\u5B8C\u6210",
  "ccnotification.common.button.ok" : "\u786E\u8BA4",
  "ccnotification.common.title.fail" : "\u5931\u8D25",
  "ccnotification.common.title.success" : "\u6210\u529F",
  "ccnotification.agent.contact.downloadFail" : "\u4E0B\u8F7D\u5931\u8D25",
  "ccnotification.agent.operation.searchworkno" : "\u8BF7\u8F93\u5165\u5DE5\u53F7",
  "ccnotification.common.title.delete" : "\u5220\u9664",
  "ccnotification.common.title.tips" : "\u63D0\u793A",
  "ccnotification.common.title.errorsave" : "\u9519\u8BEF",
  "ccnotification.common.title.confirm" : "\u8BF7\u786E\u8BA4",
  "ccnotification.common.title.confreset" : "\u91CD\u7F6E\u786E\u8BA4",
  "ccnotification.agent.pageurls.isconfreset" : "\u662F\u5426\u786E\u8BA4\u91CD\u7F6E",
  "ccnotification.common.button.delete" : "\u5220\u9664",
  "ccnotification.agent.message.updateCtiAndDbFail" : "\u540C\u6B65cti\u6216\u6570\u636E\u5E93\u5931\u8D25",
  "ccnotification.agent.label.account" : "\u4E1A\u52A1\u5E10\u53F7",
  "ccnotification.common.label.name" : "\u540D\u79F0",
  "ccnotification.common.button.create" : "\u521B\u5EFA",
  "ccnotification.agent.contact.workno" : "\u5DE5\u53F7",
  "ccnotification.urlconfig.pageurls.urlvalidate" : "url\u9700\u8981\u4EE5'https://'\u5F00\u5934",
  "ccnotification.common.operation.noMoreThan7Days" : "\u8303\u56F4\u4E0D\u80FD\u8D85\u8FC77\u5929",
  "ccnotification.common.message.maxLength" : "\u4E0D\u5141\u8BB8\u53D1\u9001\u957F\u5EA6\u8D85\u8FC74096\u7684\u6587\u672C\u5185\u5BB9",
  "ccnotification.common.button.new" : "\u65B0\u5EFA",
  "ccnotification.common.message.emptyContent" : "\u4E0D\u5141\u8BB8\u7A7A\u7684\u5185\u5BB9",
  "ccnotification.common.message.maxExpireTime" : "\u6240\u9009\u5931\u6548\u65F6\u95F4\u8D85\u51FA\u8303\u56F4\uFF0C\u6700\u5927\u5931\u6548\u65F6\u95F4\u4E0D\u80FD\u8D85\u8FC7{0}",
  "ccnotification.chatserver.fail.assign" : "\u5206\u914D\u5EA7\u5E2D\u5931\u8D25",
  "ccnotification.bulletinType.title.bulletinTypeName" : "\u516C\u544A\u7C7B\u578B\u540D\u79F0",
  "ccnotification.bulletinType.title.bulletintypeInvalidSect" : "默认失效时间",
  "ccnotification.bulletinType.title.bulletinTypeDesc" : "\u63CF\u8FF0",
  "ccnotification.bulletinType.title.editBulletinType" : "\u7F16\u8F91\u516C\u544A\u7C7B\u578B",
  "ccnotification.bulletinType.message.createBulletinTypeSuccessful" : "\u521B\u5EFA\u516C\u544A\u7C7B\u578B\u6210\u529F",
  "ccnotification.chatserver.fail.barred" : "\u7981\u6B62\u5206\u914D\u5EA7\u5E2D",
  "ccnotification.bulletinType.title.createBulletinType" : "\u65B0\u5EFA\u516C\u544A\u7C7B\u578B",
  "ccnotification.bulletinType.message.editBulletinTypeSuccessful" : "\u7F16\u8F91\u516C\u544A\u7C7B\u578B\u6210\u529F",
  "ccnotification.bulletinType.message.deleteBulletinTypeSuccessful" : "\u5220\u9664\u516C\u544A\u7C7B\u578B\u6210\u529F",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeSuccessful" : "\u6279\u91CF\u5220\u9664\u516C\u544A\u7C7B\u578B\u6210\u529F",
  "ccnotification.bulletinType.message.searchPlaceHolder" : "\u516C\u544A\u7C7B\u578B\u540D\u79F0",
  "ccnotification.bulletinType.message.createBulletinTypeFailed" : "\u521B\u5EFA\u516C\u544A\u7C7B\u578B\u5931\u8D25",
  "ccnotification.bulletinType.message.deleteBulletinTypeFailed" : "\u5220\u9664\u516C\u544A\u7C7B\u578B\u5931\u8D25",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeFailed" : "\u6279\u91CF\u5220\u9664\u516C\u544A\u7C7B\u578B\u5931\u8D25",
  "ccnotification.bulletinType.message.invalidSectCheck" : "\u9ED8\u8BA4\u5931\u6548\u65F6\u95F4\u5E94\u57281-2000\u4E4B\u95F4",
  "ccnotification.bulletinType.message.nameContainSpecialChar" : "\u540D\u79F0\u5305\u542B\u7279\u6B8A\u5B57\u7B26",
  "ccnotification.bulletinType.message.decimealsCheck" : "\u9ED8\u8BA4\u5931\u6548\u65F6\u95F4\u4E0D\u80FD\u4E3A\u5C0F\u6570",
  "ccnotification.bulletinType.message.editBulletinTypeFailed" : "\u7F16\u8F91\u516C\u544A\u7C7B\u578B\u5931\u8D25",
  "ccnotification.bulletinType.message.nameIsNotUnique" : "\u516C\u544A\u7C7B\u578B\u540D\u79F0\u5DF2\u5B58\u5728",
  "ccnotification.bulletinType.message.willDelete" : "\u786E\u5B9A\u5220\u9664\u6240\u9009\u516C\u544A\u7C7B\u578B\u5417\uFF1F",
  "ccnotification.bulletinType.message.periodicallySend" : "\u5468\u671F\u53D1\u9001",
  "ccnotification.bulletinType.message.selectItem" : "\u8BF7\u52FE\u9009\u516C\u544A\u7C7B\u578B",
  "ccnotification.bulletinType.message.everyDay" : "\u6BCF\u5929",
  "ccnotification.bulletinType.message.sendingFrequency" : "\u53D1\u9001\u9891\u7387",
  "ccnotification.bulletinType.message.perMonth" : "\u6BCF\u6708",
  "ccnotification.bulletinType.message.releaseTime" : "\u53D1\u5E03\u65F6\u95F4",
  "ccnotification.bulletinType.message.Monday" : "\u5468\u4E00",
  "ccnotification.bulletinType.message.Tuesday" : "\u5468\u4E8C",
  "ccnotification.bulletinType.message.Wednesday" : "\u5468\u4E09",
  "ccnotification.bulletinType.message.Friday" : "\u5468\u4E94",
  "ccnotification.bulletinType.message.Thursday" : "\u5468\u56DB",
  "ccnotification.bulletinType.message.weekly" : "\u6BCF\u5468",
  "ccnotification.bulletinType.message.Saturday" : "\u5468\u516D",
  "ccnotification.bulletinType.message.daysOfMonth" : "0\u65E5",
  "ccnotification.bulletinType.message.sendTimeParamsError1" : "\u53D1\u5E03\u65F6\u95F4\u4E0D\u80FD\u65E9\u4E8E\u5F53\u524D\u65F6\u95F4",
  "ccnotification.bulletinType.message.Sunday" : "\u5468\u65E5",
  "ccnotification.bulletinType.message.sendTimeParamsError2" : "\u53D1\u5E03\u65F6\u95F4\u4E0D\u80FD\u665A\u4E8E\u5931\u6548\u65F6\u95F4",
  "ccnotification.bulletinType.title.Urgent" : "\u7D27\u6025",
  "ccnotification.bulletinType.title.General" : "\u4E00\u822C",
  "ccnotification.bulletinType.title.Faults" : "\u6545\u969C",
  "ccnotification.notifition.template.message.phoneLabel" : "Phone number",
  "ccnotification.notifition.template.message.dateTimeLabel" : "Date and time",
  "ccnotification.notifition.template.message.charDigitLabel" : "Digit and letter",
  "ccnotification.bulletinType.title.Rushse" : "\u7279\u6025",
  "ccnotification.notifition.template.message.moneyLabel" : "Amount",
  "ccnotification.common.message.sendIntervalShort" : "\u53D1\u9001\u9891\u7E41\uFF0C\u53D1\u9001\u65F6\u95F4\u95F4\u9694\u4E3A{n}\u79D2",
  "ccnotification.notifition.gatewaycontact.connecting" : "\u8FDE\u63A5\u4E2D",
  "ccnotification.notifition.sendmessage.selectOutbox" : "\u8BF7\u9009\u62E9\u53D1\u4EF6\u7BB1",
  "ccnotification.notification.emailsignature.placeholder.name" : "\u8BF7\u8F93\u5165\u7B7E\u540D\u540D\u79F0",
  "ccnotification.gatewayconfig.message.securityInfo" : "NONE\u8868\u793A\u660E\u6587\u4F20\u8F93\uFF0C\u5B58\u5728\u4FE1\u606F\u6CC4\u9732\u7684\u98CE\u9669\u3002STARTTLS\u662F\u4E0D\u5B89\u5168\u7684\u534F\u8BAE\uFF0C\u4F7F\u7528\u4E0D\u5B89\u5168\u534F\u8BAE\u4F1A\u6709\u4E00\u5B9A\u7684\u5B89\u5168\u98CE\u9669\u3002\u63A8\u8350\u4F7F\u7528TLS\u534F\u8BAE\u3002",
  "ccnotification.notification.emailsignature.description" : "\u7B7E\u540D\u63CF\u8FF0",
  "ccnotification.notifition.template.message.textLabel" : "Text",
  "ccnotification.notification.emailsignature.name" : "\u7B7E\u540D\u540D\u79F0",
  "ccnotification.notification.emailsignature.content" : "\u7B7E\u540D\u5185\u5BB9",
  "ccnotification.notification.emailsignature.noitemselect" : "\u6CA1\u6709\u9009\u62E9\u4EFB\u4F55\u6570\u636E",
  "ccnotification.notification.emailsignature.willdeleteselectitem" : "\u786E\u5B9A\u8981\u5220\u9664\u6240\u9009\u6570\u636E\u5417\uFF1F",
  "ccnotification.notification.emailsignature.nameexist" : "\u7B7E\u540D\u540D\u79F0\u5DF2\u5B58\u5728",
  "ccnotification.notification.emailsignature.reachmaxcount" : "\u90AE\u4EF6\u7B7E\u540D\u6570\u91CF\u8D85\u51FA\u6700\u5927\u9650\u5236",
  "ccnotification.notification.special.charactor.check" : '输入的内容不能包含< = > / \\ " eval和exec',
  "ccnotification.notification.emailsignature.selection.username" : "\u7528\u6237\u59D3\u540D",
  "ccnotification.notification.emailsignature.content.length.exceed" : "\u7B7E\u540D\u5185\u5BB9\u957F\u5EA6\u4E0D\u80FD\u8D85\u8FC74096",
  "ccnotification.notification.label.uploadAttachmentTips" : "\u90AE\u4EF6\u9644\u4EF6\u652F\u6301\u5982\u4E0B\u6587\u4EF6\u683C\u5F0F\uFF1Adoc\uFF0Cdocx\uFF0Cxls\uFF0Cxlsx\uFF0Ctxt\uFF0Cpng\uFF0Cjpg\uFF0Cxml\uFF0Cjson\uFF0Ccsv\uFF0Cbmp\uFF0Czip\uFF0Cppt\uFF0Cpptx\uFF0Cpdf\uFF0C\u6587\u4EF6\u5927\u5C0F\u4E0D\u8D85\u8FC710m；且压缩包内文件，也需要符合以上格式。",
  "ccnotification.notification.uploadfail.duplicateFileName" : "\u6587\u4EF6\u540D\u91CD\u590D",
  "ccnotification.notification.recmessagerouterconfig.updatenum.outstripping" : "\u4E00\u6B21\u81F3\u591A\u53EF\u4EE5\u66F4\u65B0\u4E00\u767E\u6761\u6570\u636E",
  "ccnotification.sms.rcvAndSendMode.receiveSms" : "\u63A5\u6536\u77ED\u4FE1",
  "ccnotification.gatewayconfig.message.authInfo" : "\u4F7F\u7528flase\u5B58\u5728\u5B89\u5168\u98CE\u9669\uFF0C\u5EFA\u8BAE\u4F7F\u7528true\uFF0C\u5F00\u542F\u5BC6\u7801\u8BA4\u8BC1\u3002",
  "ccnotification.sms.rcvAndSendMode.receiveAndSendSms" : "\u6536\u53D1\u77ED\u4FE1",
  "ccnotification.sms.rcvAndSendMode.sendSms" : "\u53D1\u9001\u77ED\u4FE1",
  "ccnotification.sms.smppPduTye.data.sm" : "data_sm",
  "ccnotification.sms.smppPduTye.submit.sm" : "submit_sm",
  "ccnotification.sms.gms.user.data.head" : "GSM\u7528\u6237\u6570\u636E\u5934",
  "ccnotification.sms.smpp.parameters" : "SMPP\u53C2\u6570",
  "ccnotification.sms.smpp.replaceIfPresentFlag.replace" : "\u66FF\u6362",
  "ccnotification.sms.smpp.replaceIfPresentFlag.not.replace" : "\u4E0D\u66FF\u6362",
  "ccnotification.sms.smpp.ESME.Connection.Configuration" : "ESME\u8FDE\u63A5\u914D\u7F6E",
  "ccnotification.gatewayconfig.label.splitType.noSplit" : "\u4E0D\u62C6\u5206",
  "ccnotification.gatewayconfig.label.splitType.disabled" : "\u7981\u6B62\u62C6\u5206",
  "ccnotification.sms.smpp.stow.away" : "\u6536\u8D77",
  "ccnotification.gatewayconfig.label.splitType.physical" : "\u7269\u7406\u62C6\u5206",
  "ccnotification.gatewayconfig.label.splitType.ultraLong" : "\u8D85\u957F\u62C6\u5206",
  "ccnotification.gatewayconfig.label.dataCoding.Binary" : "\u4E8C\u8FDB\u5236\u4FE1\u606F",
  "ccnotification.gatewayconfig.label.dataCoding.ASCII" : "ASCII\u4E32",
  "ccnotification.gatewayconfig.label.dataCoding.UCS2" : "UCS2\u7F16\u7801",
  "ccnotification.gatewayconfig.label.basic.configuration" : "\u57FA\u7840\u914D\u7F6E",
  "ccnotification.gatewayconfig.label.advancedconfig" : "\u9AD8\u7EA7\u914D\u7F6E",
  "ccnotification.template.message.organizationStructure" : "组织机构",
  "ccnotification.template.message.alignment" : "卡片选项条数不能超过",
  "ccnotification.email.signature.message.uploadImgTypeError" : "\u53EA\u652F\u6301\u4E0A\u4F20\u683C\u5F0F\u4E3Ajpg\u3001jpeg\u3001png\u7684\u56FE\u7247",
  "ccnotification.email.signature.knowledge.message.uploadImgSizeError" : "\u4E0A\u4F20\u56FE\u7247\u7684\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7{}",
  "ccnotification.email.signature.knowledge.message.uploadError" : "\u90AE\u4EF6\u7B7E\u540D\u5185\u5BB9\u4E2D\u5B58\u5728\u4E0D\u5408\u7406\u5927\u5C0F\u6216\u4E0D\u5408\u7406\u683C\u5F0F\u7684\u56FE\u7247\uFF0C\u8BF7\u5220\u9664\u540E\u518D\u64CD\u4F5C",
  "ccn.flow.grayrule.editsuccess" : "\u4FEE\u6539\u6210\u529F",
  "ccn.voiceedit.addsuccess" : "\u65B0\u589E\u6210\u529F",
  "ccn.voiceedit.addfail" : "\u65B0\u589E\u5931\u8D25",
  "ccnotification.notification.emailsignature.selection.userEmail" : "\u7528\u6237\u90AE\u7BB1",
  "ccnotification.notification.emailsignature.selection.userPosition" : "\u7528\u6237\u804C\u4F4D",
  "ccn.voiceedit.deletesuccess" : "\u5220\u9664\u6210\u529F",
  "ccnotification.notifition.template.create" : "新建通知模板",
  "ccnotification.template.message.channelType" : "渠道类型",
  "pop.org.window.org" : "归属组织",
  "ccnotification.template.message.add.variable": "添加",
  "ccnotification.template.message.content": "模板内容",
  "ccnotification.template.message.enterDescription": "请输入描述",
  "ccnotification.notification.systemmonitor.batchrebuild":"批量重建",
  "ccnotification.notification.systemmonitor.batchdisconnect":"批量断开",
  "ccnotification.gatewayconfig.label.send.prefix":"发送号码前缀",
  "ccnotification.gatewayconfig.label.receive.prefix":"接收号码前缀",
  "ccnotification.signature.add":"新建邮件签名",
  "ccnotification.signature.update":"编辑邮件签名",
  "ccnotification.signature.view":"查看邮件签名",
  "ccnotification.gatewayconfig.label.select.sendNumber.title":"选择发送号码",
  "ccnotification.gatewayconfig.label.add.sendNumber.title":"添加发送号码",
  "ccn.whitelist.urlvalidatefailed": "\u8BF7\u8F93\u5165ip\u6216\u57DF\u540D",
  "ccnotification.notification.notificationRoute.recipientAddress" : "收件地址",
  "ccnotification.notification.notificationRoute.implementationMode" : "实现方式",
  "ccnotification.notification.notificationRoute.edit" : "编辑",
  "ccnotification.notification.notificationRoute.newNotificationRoute" : "新建通知路由",
  "ccnotification.notification.notificationRoute.editNotificationRoute" : "编辑通知路由",
  "ccnotification.notification.notificationRoute.inbox" : "收件箱",
  "ccnotification.notification.notificationRoute.serviceInvoking" : "服务调用",
  "ccnotification.notification.notificationRoute.selectconfig" : "请选择配置",
  "ccnotification.notification.notificationRoute.willdeleteselectconfig" : "确定要删除所选配置吗？",
  "ccnotification.notification.notificationRoute.deletenum.outstripping" : "一次至多可以删除一百条数据",
  "ccnotification.notification.notificationRoute.delSuccess" : "删除成功",
  "ccnotification.notification.notificationRoute.delFail" : "删除失败",
  "ccnotification.notification.notificationRoute.gateway" : "网关",
  "ccnotification.notification.notificationRoute.gatewaynotexist" : "所选网关不存在",
  "ccnotification.notification.notificationRoute.receivernotexist" : "所选收件人不存在",
  "ccnotification.notification.notificationRoute.receiveaddressexist" : "收件地址已存在",
  "ccnotification.notification.notificationRoute.intelligentOrder" : "智能创建工单",
  "ccnotification.notification.notificationRoute.mobilerule" : "请填写手机号，多个手机号以英文分号分隔",
  "ccnotification.notification.notificationRoute.inputPhoneNum" : "请输入手机号",
  "ccnotification.notification.notificationRoute.receiveAddrLen" : "长度不能超过1024",
  "ccnotification.notification.notificationRoute.retryNumLimitRule" : "输入值超出范围:0-3",
  "ccn.variable.willdeleteselectvariable": "确定删除所选的变量吗？",
  "ccn.variable.deleteselectvariable": "删除变量",
  "ccn.variable.variableisreferenced": "变量被流程引用",
  "ccn.variable.view.references": "查看引用",
  "ccn.variable.view.references.none": "变量当前暂未被引用",
  "ccn.variable.selectvariable": "请选择变量！",
  "ccn.variable.field": "通知变量",
  "ccn.unit.bytes": "字节",
  'ccn.unit.millisecond': '毫秒',
  "ccn.message.fullscreen": "最大化",
  "ccn.sms.config.batch.delete": "批量删除",
  "ccn.sms.config.receiver.uplinkMess": "接收上行短信",
  "ccn.sms.config.create.smsConfig": "新建短信配置",
  "ccn.sms.config.modify.smsConfig": "修改短信配置",
  "ccnotification.smstemplate.message.edit.fail": "修改华为云短信模板失败",
  "ccnotification.message.choose": "请选择数据",
  "ccnotification.message.add.attachement": "添加附件",
  "ccnotification.message.defaultsenderid.error": "默认发送号码不在允许范围内，请选择正确的号码",
  "ccnotification.template.message.emailAddressEmpty": "存在收件人邮件未配置：",
  "ccn.countrycode.type": "国家码前缀类型",
  "ccn.countrycode.type.with.plus": "以 + 作前缀",
  "ccn.countrycode.type.without.plus": "不以 + 作前缀"
}
