export default {
  "ccm.agent.label.promptParameters": "Tone Prompt",
  "ccm.agent.label.queueParameters": "Enfileiramento",
  "ccm.agent.label.queueTransferType": "Tipo de Objeto Transferido para",
  "ccm.agent.label.queueTransferObj": "Tipo de Objeto Transferido para",
  "ccm.agent.label.busyRatePrefix": "Quando o uso do dispositivo excede",
  "ccm.agent.label.busyRateSuffix": "Chamadas instantâneas não podem ser transferidas para a fila.",
  "ccm.agent.label.busyJudging": "Transferência de chamada em ocupado ou estouro",
  "ccm.agent.label.deviceUsageThresholdPrefix": "Quando o uso do dispositivo for maior ou igual a",
  "ccm.agent.label.deviceUsageThresholdSuffix": "A fila está ocupada.",
  "ccm.agent.label.waitTimePrefix": "Quando a duração de espera prevista exceder",
  "ccm.agent.label.waitTimeSuffix": "A fila está ocupada.",
  "ccm.agent.label.queuingCallsMaxNumPrefix": "Quando o número de chamadas de enfileiramento exceder",
  "ccm.agent.label.queuingCallsMaxNumSuffix": "A fila transborda.",
  "ccm.agent.label.scaleFactorPrefix": "Quando o número de chamadas de enfileiramento exceder (",
  "ccm.agent.label.scaleFactorSuffix": "x Número de agentes conectados), a fila transborda.",
  "ccm.agent.button.coloumedit":"Configuração do Campo",

  "ccm.transfer.number.pop.create.title": "Criando um Número Externo Encaminhado para",
  "ccm.transfer.number.pop.modify.title": "Editar Número Externo Encaminhado para",
  "ccm.transfer.number.pop.delete.title": "Excluir",
  "ccm.transfer.number.pop.delete.message": "Tem certeza de que deseja excluir o número externo selecionado?",
  "ccm.transfer.number.search.number": "Digite o número",
  "ccm.transfer.number.search.name": "Digite um nome",
  "ccm.transfer.number.label.index": "Índice",
  "ccm.transfer.number.label.number": "Número",
  "ccm.agent.message.addSkill": "Adicionando Fila de Habilidades",
  "ccm.satisfactionconfig.msg.templateenpty": "Selecione um modelo de SMS.",
  "ccm.satisfactionconfig.msg.timelimitenpty": "O tempo de resposta válido é nulo.",
  "ccm.satisfactiontemplate.message.confirm":"Confirme se deseja atualizar o conteúdo do modelo com base no nível de satisfação.",
  "ccm.satisfactiontemplate.message.queryfail":"Falha ao consultar o modelo de pesquisa de satisfação.",
  "ccm.satisfactiontemplate.tipscontent":"O modelo de mensagem de pesquisa de satisfação é usado para enviar mensagens de pesquisa de satisfação para clientes nos canais Instagram, Telegram, WhatsApp, LINE, Web Chat, Face Book, X (Twitter), 5G RCS e SMS.",
  "ccm.satisfaction.changetips":"Nota: A configuração do nível de satisfação é alterada. Modifique o modelo de pesquisa de satisfação de acordo.",
  "ccm.satisfactionconfig.message.question":"O modelo de mensagem de pesquisa de satisfação é usado para enviar mensagens de pesquisa de satisfação para clientes nos canais Instagram, Telegram, WhatsApp, LINE, Web Chat, Face Book, X (Twitter), 5G RCS e SMS.",
  "ccm.satisfactionlevel.satislevel.config":"Configuração do Nível de Satisfação",
  "ccm.satisfactionlevel.satislevel.button":"Botões",
  "ccm.satisfactionlevel.satislevel.description":"Descrição",
  "ccm.satisfactionlevel.satislevel.operat":"Operação",
  "ccm.satisfactionlevel.satislevel.moveup":"Para cima",
  "ccm.satisfactionlevel.satislevel.movedown":"Para baixo",
  "ccm.transfer.number.label.name": "Nome",
  "ccm.transfer.number.label.flag": "Encaminhamento de Número de Usuário",
  "ccm.transfer.number.label.flag.yes": "Sim",
  "ccm.transfer.number.label.flag.no": "Não",
  "ccm.pageinterface.custTableConfig": "Configuração básica da tabela",
  "ccm.satisfactionconfig.group.createsuccess": "O grupo de políticas foi adicionado com êxito.",
  "ccm.satisfactionconfig.group.deletesuccess": "O grupo de políticas foi eliminado com êxito.",
  "ccm.satisfactionconfig.strategy.createsuccess": "Conseguiu adicionar a política de satisfação do canal.",
  "ccm.satisfactionconfig.strategy.updateuccess": "Conseguiu modificar a política de satisfação do canal.",
  "ccm.satisfactionconfig.strategy.deletesuccess": "Conseguiu excluir a política de satisfação do canal.",
  "ccm.satisfactionconfig.strategy.surveytyperequired": "O tipo de pesquisa não pode estar vazio.",

  "ccm.transtask.label.friday": "sexta-feira",
  "ccm.ccagent.management.additions":"Adições",
  "ccm.agent.contact.preview": "Pré-visualização",
  "ccm.agent.label.waitVoiceSelect": "Selecione um tom de espera",
  "ccm.agent.label.noAgents": "Processamento de chamadas em ninguém de plantão",
  "ccm.custdata.operlog.refresh": "Atualizar",
  "ccm.callreason.case.check.error": "A classe contábil {0} não existe.",
  "ccm.agent.label.waitTimeRange": "Valor [0-9999]",
  "ccm.agent.message.weight.limit": "[1-100]",
  "ccm.agent.message.agentweight.limit": "[1-300]",
  "ccm.skillgroup.message.skillgroup": "Insira um nome de grupo de habilidades.",
  "ccm.skillgroup.label.searchname": "Habilidades Grupo Nome",
  "ccm.skillgroup.label.groupid": "Números",
  "ccm.skillgroup.label.groupname": "Nome",
  "ccm.skillgroup.label.orgname": "Organização",
  "ccm.agent.label.skillgroup": "Grupo de Habilidades",
  "ccm.skillgroup.label.groupskills": "Associar uma fila de habilidades",
  "ccm.skillgroup.title.add": "Adicionar um grupo de habilidades",
  "ccm.skillgroup.title.update": "Atualizar grupos de habilidades",
  "ccm.agent.contact.isExistTalkReason": "Conjunto de Motivo da Chamada ou Não",
  "ccm.agent.contact.QUERU_SFTP_FAIL_CODE": "As informações SFTP estão vazias.",
  "ccm.note.config.notename": "Nome",
  "ccm.certificate.management.update.certfile": "Atualizar arquivo de certificado",
  "ccm.certificate.management.add.title": "Adicionando um certificado",
  "ccm.certificate.management.update.title": "Atualização de Certificados",
  "ccm.transtask.label.reportData": "Dados de relatório",
  "ccm.agent.sysparamconfig.emailMaxRecipientsNoCheck": "O número máximo de destinatários encaminhados por agentes de correio deve estar entre 1 e 50",
  "ccm.urlconfig.label.oauth.callback.alerttip": "O URL de retorno de chamada é passado quando a interface de criação de chamada dupla é chamada. Se as seguintes informações de autenticação estiverem configuradas, as seguintes informações de autenticação serão usadas quando o URL de retorno de chamada for chamado quando a chamada dupla terminar. Caso contrário, o retorno de chamada não será autenticado.",
  "ccm.certificate.management.certificate.crlfile.certhasexpired": "O certificado expirou.",
  "ccm.ivr.placeholder.ivrdesc": "Insira a descrição do processo.",
  "ccm.calledconfig.message.nonumbers": "Escolha números",
  "ccm.callout.lable.default": "Valor padrão",
  "ccm.verifyidentity.fail.USERIDPARSEERROR": "O número de autenticação não pode conter caracteres especiais",
  "ccm.pageinterface.saveSuccess": "O conjunto de interface de página foi salvo com sucesso.",
  "ccm.speciallist.msg.selectonly": "Apenas uma lista especial pode ser selecionada para modificação.",
  "ccm.custdata.button.exportTemplate": "Exportar modelo",
  "ccm.chatbot.query.list.fail": "Falha ao obter a lista de bots",
  "ccm.ivr.button.traceInfo": "Clique para exibir o registro de rastreamento atual.",
  "ccm.verifyidentity.callId": "ID da chamada",
  "ccm.verifyidentity.verifyName": "Verificar nome",
  "ccm.contactitemconfig.label.data.item.identification": "ID do item de dados",
  "ccm.chatbot.query.name.fail": "Falha ao obter o nome do bot",
  "ccm.agent.message.pageLayoutFail": "O layout da página ou o número de linhas não está selecionado.",
  "ccm.speciallist.msg.suffix": "Sufixo incorreto para o nome do arquivo de importação",
  "ccm.agent.leavemessage.downloadLeaveMessage": "Baixar a mensagem",
  "ccm.custdata.operlog.exportEndTime": "Hora final da exportação",
  "ccm.agent.restrecord.rest.currentstatus": "Status atual",
  "ccm.agent.ccagentinfo.unregister.success": "Cancelar registro de sucesso",
  "ccm.speciallist.msg.tip": "Um máximo de 1000 registros podem ser importados por vez",
  "ccm.agent.message.createAgentAndSkillFail": "Falha ao criar a associação da fila de habilidades do agente.",
  "ccm.recognition.label.openBreakDown": "Voz em caso de pane",
  "ccm.contact.detail.receiver": "Destinatário",
  "ccm.agent.button.close": "Fechar",
  "ccm.certificate.management.type": "Tipo de certificado",
  "ccm.speciallist.msg.confirmdeletehis": "Tem certeza de que deseja excluir o histórico da lista especial selecionada?",
  "ccm.sysparam.success.save": "Sucesso",
  "ccm.satisfactionconfig.message.accepttime": "Tempo de processamento",
  "ccm.agent.label.callDispatchModel.incomingCall": "Alocação de habilidades de chamada recebida antes da alocação de habilidades de chamada de saída",
  "ccm.custdata.label.status": "Status",
  "ccm.recognition.label.breakDownVoice": "Voz em caso de pane",
  "ccm.calledroute.title.selectskill": "Selecionar fila de habilidades",
  "ccm.recognition.label.openIvrAgentSuccess": "O assistente de agente inteligente está habilitado com sucesso.",
  "ccmanagement.satisfaction.strategy.accepttime.accepttime": "Tempo de Manipulação",
  "ccm.transtask.label.dataname": "Nome do servidor de dump",
  "ccm.agent.message.longCharacters": "O número pode conter um máximo de 24 caracteres.",
  "ccm.agent.message.longCharacters.form": "O número pode conter um máximo de 24 caracteres",
  "ccm.speciallist.msg.partexport": "Tem certeza de que deseja exportar os registros selecionados?",
  "ccm.sysparam.config.itemname.tips": "Insira um nome de parâmetro.",
  "ccm.satisfactionconfig.message.chooseivrflow": "Selecionar fluxo de IVR",
  "ccm.accesscode.label.accesscodedesc": "Descrição do código de acesso",
  "ccm.accesscode.title.accesscodedesc": "Editar Descrição do Código de Acesso",
  "ccm.custdata.label.endTime": "Hora de término",
  "ccm.agent.calledroute.success": "Sucesso",
  "ccm.botherlist.label.approveComment.toApprove": "Concordado",
  "ccm.agent.contact.assistantCallid": "ID de chamada colaborativa",
  "ccm.contact.customer.config.referenced": "O item de dados foi referenciado pela área de informações do cliente de contato.",
  "ccm.contact.record.data.referenced": "O item de dados foi referenciado pelo item de dados de registro de contato.",
  "ccm.agent.label.description": "Descrição",
  "ccm.agent.label.endworkno": "ID do funcionário final",
  "ccm.contactitemconfig.label.data.extended.dataset.source.interface": "Interface de origem do conjunto de dados",
  "ccm.transtask.label.day": "Dia",
  "ccm.agent.message.updateCtiAndDbFail": "Falha na atualização cti e Db.",
  "ccm.transserver.label.obsnotinwhite": "Operação falhou. Contacte o administrador do sistema para adicionar o endereço do serviço OBS à lista fidedigna do servidor OBS de despejo de recursos.",
  "ccm.agent.recall.param.callid.empty": "Nenhum número de chamada de volta está disponível.",
  "ccm.label.contactexport.customfield": "Campo personalizado",
  "ccm.agent.calledroute.placeholder.accesscode": "Selecione um código de acesso.",
  "ccm.systemparam.success.refresh": "Parâmetros atualizados com sucesso.",
  "ccm.speciallist.msg.errorreason": "Causa da falha",
  "ccm.contactitemconfig.label.data.item.sequence.number": "Número",
  "ccm.agent.pageurls.accessaddr": "Endereço de acesso",
  "ccm.custdata.operlog.confirmMessage": "Tem certeza de que deseja criar uma nova tarefa de exportação?",
  "ccm.recognition.label.orderfailpd": "Falha na assinatura. Tente novamente mais tarde.",
  "ccm.verifyidentity.NOTRETURN": "Não retornar",
  "ccm.urlconfig.label.xNumber": "Número X",
  "ccm.satisfactionconfig.message.cancel": "Cancelar",
  "ccm.agent.calledroute.editsuccess": "Editado com sucesso.",
  "ccm.speciallist.msg.transfererror": "Falha ao transferir para o histórico",
  "ccm.agent.leavemessage.status.unprocessed": "Não processadas",
  "ccm.satisfactionconfig.message.comfirm": "Tem certeza de que deseja excluir o grupo de políticas?",
  "ccm.agent.skill.skillExist": "O nome especificado já existe. Use um nome diferente.",
  "ccm.agent.contact.audio-visual": "Áudio/vídeo",
  "ccm.transtask.label.taskfail": "Falha ao criar a tarefa falhou porque um máximo de {0} tarefas do tipo de registro de contato são permitidas.",
  "ccm.ivr.label.processid": "ID do processo",
  "ccm.agent.label.parameter": "Parâmetro 1",
  "ccm.agent.title.agentEdit": "Configurações de Informações do agente",
  "ccm.speciallist.msg.modifyerror": "A modificação falhou",
  "ccm.label.contactexport.selectdatafield": "Campo Exportar",
  "ccm.agent.contact.preCallout": "Visualizar chamada de saída",
  "ccm.agent.pageurls.pagetype": "Tipo de página",
  "ccm.agent.calledroute.create.accesscode.none": "O código de acesso do sistema configurado para a parte chamada não está alocado.",
  "ccm.transtask.label.vdnReport": "Relatório de Desempenho VDN",
  "ccm.agent.tips.inputpassword": "Insira a senha da conta de login atual.",
  "ccm.basicdata.msg.nodata": "Sem dados",
  "ccm.contact.customer.config.writeBackFieldError": "O campo de writeback de registro de contato estendido não pode estar em uso.",
  "ccm.contact.customer.config.dataItemCodeError": "A ID do item de dados está incorreta.",
  "ccm.agent.restReason.restCauseTime.formate": "A duração de repouso deve estar no formato HH:MM:SS.",
  "ccm.agent.Transfer.theAgent": "Agente",
  "ccm.transtask.label.updateexsit": "Falha na operação porque o nome do servidor já existe.",
  "ccm.speciallist.msg.endtimeerror": "O tempo de expiração deve ser posterior ao tempo atual",
  "ccm.custdata.button.moveDown": "Baixar",
  "ccm.custdata.option.isMaskDisplay.yes": "Sim",
  "ccm.contactitemconfig.label.data.extended.dictionary.value": "Valor do dicionário de dados",
  "ccm.verifyidentity.fail.WORKNOPARSEERROR": "O número do trabalho deve ser numérico",
  "ccm.transtask.label.monday": "segunda-feira",
  "ccm.satisfactionconfig.message.validatename": "O nome do grupo de políticas não pode estar vazio ou duplicado.",
  "ccm.agent.ten.videoIvrchannels": "Quantidade de Canais IVR de vídeo",
  "ccm.satisfactionconfig.message.calltime": "Duração da chamada",
  "ccm.calledconfig.message.savemobileagentsuccess": "A configuração do agente móvel foi salva com sucesso",
  "ccm.satisfaçãoconfig.message.smsgateway": "SMS Gateway",
  "ccm.agent.contact.callin": "Chamada recebida",
  "ccm.custdata.msg.updatedItemNotExist": "O campo a ser atualizado não existe. Atualize a página e tente novamente.",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.no": "Não",
  "ccm.agent.contact.vidio": "Vídeo",
  "ccm.satisfactionconfig.message.failed": "Falha",
  "ccm.transtask.label.skillAccessCodeReport": "Relatório de tráfego da fila de habilidades por código de acesso",
  "ccm.agent.contact.callid": "ID Chamada",
  "ccm.agent.contact.contactdetail": "Detalhes do contato",
  "ccm.custdata.operlog.exportTaskStatus": "Status",
  "ccm.verifyidentity.ERROR": "Erro",
  "ccm.agent.label.positiveEmotionNumber": "positiva",
  "ccm.speciallist.msg.view": "Ver",
  "ccm.agent.label.waitVoiceCustom": "Personalizando o tom de espera",
  "ccm.agent.button.edit": "Editar",
  "ccm.agent.accesscode.description.limit": "A descrição do código de acesso não pode conter mais de 50 caracteres.",
  "ccm.agent.calledroute.isconfdelete": "Tem certeza de que deseja excluir o número chamado selecionado?",
  "ccm.custdata.operlog.exportBeginTime": "Hora de início da exportação",
  "ccm.custdata.option.maskStyle.retainLast4": "Reservar últimos quatro dígitos",
  "ccm.agent.pageurls.updateinfo": "Atualizado com sucesso.",
  "ccm.agent.moveup.error": "Mover para cima falhou.",
  "ccm.agent.moveup.success": "Mover para cima com sucesso.",
  "ccm.agent.movedown.error": "Mover para baixo falhou.",
  "ccm.agent.movedown.success": "Mover para baixo com sucesso.",
  "ccm.custdata.label.tableName": "Nome da tabela",
  "ccm.custdata.title.edit": "Editar Dados",
  "ccm.custdata.title.coloum.add": "Criando uma coluna de campo",
  "ccm.custdata.title.coloum.edit": "Editar coluna de campo",
  "ccm.custdata.title.coloum.detail": "Exibindo Detalhes do Campo",
  "ccm.urlconfig.label.input.appKey": "AppKey",
  "ccm.satisfactionconfig.message.numberlimit": "Um máximo de 100 configurações de canais de pesquisa de satisfação são permitidas em um espaço de locatário.",
  "ccm.contactitemconfig.label.data.extended.Type": "Tipo de dados",
  "ccm.pageinterface.interfaceType.externalExplain": "As interfaces externas são configuradas na página Gerenciamento de API. Entre em contato com o administrador do sistema.",
  "ccm.speciallist.button.modify": "Modifica-se",
  "ccm.levelMessage.title.modify": "Modificar gerenciamento de níveis",
  "ccm.speciallist.title.modify": "Modificar lista especial",
  "ccm.speciallistreminder.button.modify": "Modificar lembrete de lista especial",
  "ccm.agent.contact.multimedia.web": "Multimídia-WEB",
  "ccm.autoanswer.message.editfail": "Failed to edit the agent answer mode.",
  "ccm.autoanswer.message.editsuccess": "O modo de atendimento ao agente foi modificado com êxito.",
  "ccm.pageurls.select.embedding": "Incorporação",
  "ccm.agent.contact.talkcontent": "Registros de conversa",
  "ccm.agent.callreason.modify.failed": "Falha ao modificar o motivo da chamada.",
  "ccm.agent.callreason.delete.confirm": "Tem certeza de que deseja excluir o motivo da chamada?",
  "ccm.speciallist.msg.reviewerror": "Falha na aprovação",
  "ccm.speciallist.msg.sltype.illegal": "Tipo de lista especial ilegal",
  "ccm.agent.contact.play": "Reproduzir",
  "ccm.custdata.msg.selectItem": "Selecione um item antes de classificar.",
  "ccm.speciallevel.msg.deleteyerror": "Falha ao excluir o nível da lista especial.",
  "ccm.speciallist.button.finish": "Completo",
  "ccm.agent.message.allExport": "Exportar tudo",
  "ccm.agent.updateSoftPhone.modifyLocalPwd": "Falha ao alterar a senha local do softphone.",
  "ccm.custdata.msg.dictionaryValidateFailed": "Formato incorreto da faixa de valores do dicionário de dados",
  "ccm.contactitemconfig.label.data.item.basic": "Dados Básicos",
  "ccm.contract.management.invalid.confirm": "Tem certeza de que deseja invalidar este documento?",
  "ccm.agent.message.deleteSkillAndAgentFail": "Falha ao desassociar a fila de habilidades do agente.",
  "ccm.create.case.confirm": "O tipo de caso está vazio. Ao criar o caso, o tipo de caso precisa ser selecionado manualmente. Tem certeza de que deseja continuar?",
  "ccm.agent.label.audio": "Agente de voz",
  "ccm.agent.label.on": "Ativar",
  "ccm.agent.login.ID": "Seqüência de operação",
  "ccm.satisfactionconfig.web.satisfaction.survey": "Pesquisa de Satisfação do Canal Web",
  "ccm.agent.calledroute.require": "Informe todos os campos obrigatórios.",
  "ccm.agent.label.afterVoiceSelect": "Depois que o ID do agente é relatado",
  "ccm.agent.message.delete": "Excluir",
  "ccm.agent.message.pageInterface": "Interface de página",
  "ccm.recognition.resetSK.failed": "Falha ao reinicializar a SK.",
  "ccm.agent.ten.anonymousSipInfo": "Endereço IP e número da porta do servidor de chamadas anônimas",
  "ccm.agent.contact.endtime": "Hora de término",
  "ccm.custdata.msg.duplicateTableName": "Este nome de tabela já existe.",
  "ccm.calledconfig.message.callreasontips": "Por favor, note: O TT associado no nível atual não está disponível. Selecione um motivo de chamada de subconjunto para definir o TT associado.",
  "ccm.agent.tips.confFail": "Falha",
  "ccm.agent.pageurls.configpage": "Página de configuração",
  "ccm.agent.contact.called": "Número chamado",
  "ccm.speciallist.operlog.downloadData": "Exportar dados da lista especial",
  "ccm.systemparam.error.noparamselected": "Selecione um parâmetro primeiro.",
  "ccm.satisfactionconfig.message.webchannel": "Canal multimídia da Web",
  "ccm.agent.leavemessage.auditdate": "Tempo de atualização",
  "ccm.agent.message.videoagentzero": "A contagem de agentes de vídeo é 0. A fila de habilidades de clique para discagem de vídeo não pode ser configurada.",
  "ccmanagement.satisfaction.strategy.accepttime.timeEnd": "Tempo final",
  "ccm.calledconfig.table.devtype": "Tipo de dispositivo de finalidade",
  "ccm.agent.label.deviceUsageThresholdRange": "Valor [0-100]",
  "ccm.agent.message.cancelconfig": "Cancelar configuração",
  "ccm.agent.contact.preemptionCallout": "Chamada de saída antecipada",
  "ccm.urlconfig.message.urlnotinwhite": "Falha na operação. Entre em contato com o administrador do sistema para adicionar o URL à lista de confiança.",
  "ccm.agent.title.querysippwd": "Verificar senha do softphone",
  "ccm.policy.label.updateVerificationFailed": "Falha ao verificar o parâmetro de solicitação.",
  "ccm.agent.label.scaleFactorRangeTwo": "Valor [1-10000]",
  "ccm.custdata.operlog.zippwd": "Senha do arquivo",
  "ccm.dual.call.record.talkingTime": "Tempo de Atendimento da Parte Chamada",
  "ccm.note.config.agent.ring.setUp": "Configuração da mensagem de prompt",
  "ccm.dual.call.record.dualCallType": "Tipo de chamada bidirecional",
  "ccm.agent.leavemessage.processsuccess": "A mensagem foi processada com sucesso.",
  "ccm.agent.contact.caller": "Número chamador",
  "ccm.agent.calledroute.delete.skill.default": "A configuração da parte chamada é referenciada pela fila de habilidade privada padrão e não pode ser excluída.",
  "ccm.agent.label.transferType": "Método de processo",
  "ccm.agent.restrecord.reststatus": "Estado de repouso",
  "ccm.contact.detail.view.message.content": "Verificar",
  "ccmanagement.tenantspace.option.weeklist_firtolast": "Último",
  "ccm.agent.message.updateagentandskillfail": "Falha ao atualizar a associação entre o agente e a fila de habilidades.",
  "ccm.agent.pageurls.delfail": "Falha na exclusão.",
  "ccm.agent.resetskillrecord.adjusttime": "Ajustar o tempo",
  "ccm.contact.customer.config.value": "Valor dos dados",
  "ccm.satisfactionconfig.message.comfirmtitle": "Confirm",
  "ccm.agent.operation.CONFERENCE": "Conferência de terceiro",
  "ccm.calledroute.button.cancel": "Cancelar",
  "ccm.urlconfig.label.input.domainName": "Nome de domínio ITA",
  "ccm.agent.label.transfer": "Transferência",
  "ccm.taskrecord.message.reexecutetranstaskerror": "Falha de re-execução.",
  "ccm.agent.message.allExportAgent": "Tem certeza de que quer exportar todas as informações do agente?",
  "ccm.custdata.operlog.inputzippwd": "Inserir senha de compactação",
  "ccm.satisfactionlevel.message.samedesc": "Existe uma descrição duplicada. Favor modificar",
  "ccm.ucconfig.message.clientSecret": "Senha do aplicativo (cliente)",
  "ccm.agent.operation.countid": "Número da conta",
  "ccm.satisfactionconfig.message.switchtip": "Depois que o recurso é ativado, o sistema exibe automaticamente a página de pesquisa de satisfação após o término do serviço de canal da Web. Depois que os outros serviços do canal de mídia são concluídos, o sistema automaticamente empurra o conteúdo do modelo de satisfação.",
  "ccm.satisfactionconfig.message.timelimittip": "Se o tempo de resposta do cliente exceder o tempo de resposta válido, a resposta não será mais válida.",
  "ccm.note.config.agent.page.ring.select": "Selecionar toque",
  "ccm.agent.restReason.updateFailed": "Falha na modificação.",
  "ccm.agent.label.qualityChecker": "Verificador de qualidade",
  "ccm.agent.contact.playRecording": "reproduzir uma gravação",
  "ccm.agent.label.callDispatchModel.manySkills": "Alocação preferencial de competências com mais competências",
  "ccm.custdata.msg.sortNoChange": "A classificação não muda.",
  "ccm.agent.contact.downloadFail": "Falha no download.",
  "ccm.policy.label.updateFailed": "Failed to update the policy information.",
  "casemanagement.user.button.ok": "OK",
  "ccm.agent.button.search": "Consulta",
  "ccm.agent.label.serviceAccountName": "Nome da conta da nuvem de serviço",
  "ccm.contactitemconfig.label.contact.extended.information.dataset": "Conjunto de dados de informações de contato estendido",
  "ccm.agent.export.agentinfo.tips": "Os dados exportados contêm dados pessoais, que devem ser usados e protegidos corretamente.",
  "ccm.speciallist.msg.userNum": "Número do usuário",
  "ccm.transserver.delete.css.success": "Configuração CSS excluída com sucesso.",
  "ccm.agent.contact.calledfailed": "Falha da chamada de saída",
  "ccm.agent.updateSoftPhone.updateOpertionSuccess": "Aviso",
  "ccm.idauth.title.edit": "Modificando o Processo de Autenticação",
  "ccm.urlconfig.message.deletesuccess": "Sucesso para reinicializar as urls",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnumber": "O valor deve ser um inteiro positivo.",
  "ccm.ivr.label.processaccesscode": "Código de fluxo",
  "ccm.agent.pageurlparam.channelassociateddata": "Dados associados ao canal",
  "ccm.agent.operation.AllOpType": "Todos os tipos de operação",
  "ccm.agent.Transfer.tranfermode": "Modo de transferência",
  "ccm.calledconfig.table.selectnumbers": "Selecionar números",
  "ccm.agent.protocol.check": "Observe que o protocolo de rede usado pelo endereço url não é um protocolo seguro. Se você decidir usar este protocolo, podem existir riscos de segurança.",
  "ccm.common.label.success": "Bem-sucedido",
  "ccm.certificate.management.certificatefile.notupload": "Carregue o arquivo de certificado.",
  "ccm.speciallist.msg.selectspeciallist": "Selecione uma lista especial",
  "ccm.speciallist.msg.selectspeciallisthis": "Selecione um histórico de lista especial",
  "ccm.agent.ten.companyName": "Empresa",
  "ccm.agent.label.calcOfMaxCallNum": "Método de cálculo dos dados máximos de enfileiramento de chamadas",
  "ccm.speciallevel.speciallist.level": "Nível",
  "ccm.agent.label.serverAddress": "Endereço do servidor de registro",
  "ccm.verifyidentity.fail.CALLIDPARSEERROR": "O ID da chamada deve ser numérico",
  "ccm.agent.pageurls.openMode": "Modo Aberto",
  "ccm.agent.operation.noMoreThan1Days": "O intervalo de tempo não pode exceder 1 dias.",
  "ccm.pageinterface.selectedAll": "Selecionar tudo",
  "ccm.calledconfig.message.deletemobileagentsuccess": "A configuração do agente móvel foi excluída com sucesso",
  "ccm.calledconfig.label.selectcalled": "Selecionar número destino",
  "ccm.note.config.agent.page.ring.warn": "Recomenda-se o teste de chamada para evitar sobreposições com toques configurados no telefone",
  "ccmanagement.satisfaction.strategy.addsuccess": "Salvando bem-sucedido.",
  "ccm.transtask.label.vdnAccessCodeReport": "Relatório de tráfego VDN por código de acesso",
  "ccm.calledroute.title.selectaccesscode": "Selecionar Código de Acesso",
  "ccm.calledroute.tip.selectaccesscode": "Selecione um código de acesso primeiro.",
  "ccm.botherlist.label.contactrecorddetail": "Detalhes de Contato",
  "ccm.transserver.delete.fail": "Falha ao excluir o servidor de dump.",
  "ccm.message.center.nocitiction.sending.records.status": "Status",
  "ccm.agent.label.startworkno": "Iniciar ID do Funcionário",
  "ccm.agent.updateSoftPhone.queryNodeFailed": "Falha ao consultar informações sobre o nó do Tenant.",
  "ccm.agent.message.pageConfSuccess": "Operação bem-sucedida.",
  "ccm.dual.call.record.caller": "Número do chamador",
  "ccm.agent.password.wrong": "Falha ao verificar a senha.",
  "ccm.satisfactionconfig.message.validtime": "Período de resposta válido",
  "ccm.agent.label.negativeEmotionNumber": "negativa",
  "ccm.custdata.option.isMandatory.no": "Não",
  "ccm.certificate.management.updatetime": "Tempo de atualização",
  "ccm.agent.calledroute.ivr": "Escolha IVR.",
  "ccm.speciallist.button.add": "Adicionar",
  "ccm.levelMessage.title.add": "Criar gerenciamento de níveis",
  "ccm.speciallist.title.add": "Criar lista especial",
  "ccm.speciallistreminder.button.add": "Criar lembrete de lista especial",
  "title.contact.agent.name": "Nome da conta de serviço",
  "ccm.agent.title.IVRTrace": "Rastreamento IVR",
  "ccm.agent.videoDownload.RSETimeout": "Solicitando o serviço RSE expirou o tempo limite",
  "ccm.agent.label.cti": "CTI:",
  "ccm.custdata.option.columnType.string": "Cadeia de caracteres",
  "ccm.speciallist.msg.crateerrorreason": "O número de assinante já existe",
  "ccm.agent.contact.multimedia.instagram": "Multimedia-Instagram",
  "ccm.agent.message.agentConfSuccess": "As informações do agente foram configuradas com sucesso.",
  "ccm.speciallevel.tip.stop": "Confirmar a desativação?",
  "ccm.agent.label.skillChoice": "Selecionar habilidade",
  "ccm.agent.message.agentnumreachmax": "Número de tipo de agente alvo já atingido o máximo",
  "ccm.agent.operation.ANSWER": "Responder",
  "ccm.speciallist.msg.overlimit": "O comprimento da mensagem de lembrete excede 256 caracteres.",
  "ccm.transtask.label.abandonedRingReport": "Relatório Sumário sobre chamadas abandonadas durante o toque",
  "ccm.contact.detail.message.sending.record": "Registro de envio de mensagem",
  "ccm.chatbot.invalid.empty": "O assistente do robô não pode estar vazio",
  "ccm.idauth.title.code": "Codificação do método de autenticação",
  "ccm.custdata.msg.reachUpperTableNum": "O número de tabelas personalizadas atingiu o limite superior.",
  "ccm.agent.page.cancel.release": "Não publicada",
  "ccm.recognition.label.cancelorderfail": "Falha ao cancelar a assinatura.",
  "ccm.agent.calledroute.extcode": "Código de extensão",
  "ccm.agent.pageurls.maxcallingnum": ". Certifique-se de que o número de telas pop-up não exceda 5.",
  "ccm.sysparam.error.validate.notmatchruleoption": "O parâmetro de entrada não está no intervalo de enumeração",
  "ccm.agent.calledroute.device": "Selecione um dispositivo.",
  "ccm.agent.operation.WHISPER": "Sussurrar",
  "ccm.agent.contact.predictiveCall": "Chamada de saída prevista",
  "ccm.taskrecord.label.reexecute": "Re-executar",
  "ccm.agent.contact.others-channels": "Outros canais",
  "ccm.agent.contact.skillhungup": "Transferir para a fila de habilidades",
  "ccm.callout.lable.caller": "Chamar chamador",
  "ccm.agent.sysparamconfig.positiveinteger": "Insira um inteiro positivo!",
  "ccm.certificate.management.scenename": "Cena de certificado",
  "ccm.agent.restrecord.reststatus.Abnormal.endrest": "Descanso terminado de forma anormal",
  "ccm.taskrecord.label.choosetask": "Selecionar tarefa",
  "ccm.speciallist.msg.restores": "Restaurações",
  "ccm.agent.calledroute.devicedesc": "Nome do dispositivo",
  "ccm.agent.recall.fail": "Falha ao voltar a chamar o cliente.",
  "ccm.transtask.label.isOpenCssServer": "Especifica se o CSS deve ser ativado.",
  "ccm.agent.mobileagent.label.deletehint": "Deseja realmente excluir?",
  "ccm.agent.label.isaireconition": "Verdadeiro",
  "ccm.calledconfig.message.nullparent": "O nó pai não existe.",
  "ccm.speciallist.msg.applyTimeTo": "Hora de término do aplicativo",
  "ccm.ucconfig.message.updatesuccess": "Configuração de integração de UC salva com sucesso",
  "ccm.agent.restReason.isusing.warning": "Os códigos da razão de repouso estão em vigor. Isso pode fazer com que o motivo de repouso seja exibido incorretamente no registro de repouso.",
  "ccm.agent.contact.callerPresent": "Número do usuário",
  "ccm.custdata.label.maskStyle": "Estilo de máscara",
  "ccm.agent.button.restSoftPhonePwdError": "Um agente deve ser vinculado antes que a senha do softphone possa ser redefinida.",
  "ccm.speciallist.msg.transferToHistory": "Transferir para a História",
  "ccm.dual.call.record.normal.end": "Fim normal",
  "ccm.note.config.agent.page.ring.media": "Lembrete multimídia de chamadas recebidas",
  "ccmanagement.tenantspace.option.month_9": "Setembro",
  "ccm.speciallist.msg.deleteerror": "Falha ao excluir o lembrete da lista especial.",
  "ccm.agent.restReason.restCauseDesc.limit": "A descrição do motivo restante pode conter um máximo de 100 caracteres.",
  "ccm.agent.restReason.restCauseDesc.limit.form": "A descrição do motivo restante pode conter um máximo de 100 caracteres",
  "ccm.agent.pageurls.successinfo": "Criação com sucesso.",
  "ccm.agent.pageurls.successinfo.noPoint": "Criação com sucesso",
  "ccmanagement.tenantspace.option.month_7": "Julho",
  "ccmanagement.tenantspace.option.month_8": "Agosto",
  "ccmanagement.tenantspace.option.month_5": "Maio",
  "ccmanagement.tenantspace.option.month_6": "Junho",
  "ccmanagement.tenantspace.option.month_3": "Março",
  "ccmanagement.tenantspace.option.month_4": "Abril",
  "ccmanagement.tenantspace.option.month_1": "Janeiro",
  "ccm.agent.ten.versatileagents": "Agentes versáteis",
  "ccmanagement.tenantspace.option.month_2": "Fevereiro",
  "ccm.satisfactionconfig.message.calltypein": "Chamada de entrada",
  "ccm.contact.customer.config.type": "Contactar dados da área de informação do cliente",
  "ccm.transtask.label.voiceFile": "Arquivo de voz",
  "ccm.anonymous.message.fail.paranull": "O código de acesso no parâmetro está vazio",
  "ccm.certificate.management.certificatefile.certcodeerror": "O código do cert é erro.",
  "ccm.agent.label.maxWaitNum": "Máx. Chamadas na Fila",
  "ccm.satisfactionconfig.message.enterflowname": "Insira o nome do processo",
  "ccm.systemparam.error.refresh": "Falha ao atualizar parâmetros.",
  "ccm.note.config.agent.page.ring.newMedia": "Notificações de novas mensagens multimídia",
  "ccm.agent.message.pageConfFail": "operação falhada",
  "ccm.transtask.label.certTip.proxyCert": "Selecione o certificado do servidor proxy",
  "ccm.agent.pageurls.integrationType": "Tipo de integração",
  "ccm.agent.message.sysParamCofig.openVersatileAgentFail": "Altere o tipo de agente na página Gerenciamento de agentes. O número de agentes versáteis e agentes de vídeo não pode ser maior que o número máximo de agentes de vídeo aos quais um Tenant pode se inscrever.",
  "ccm.recognition.label.updatesucess": "Atualizar o sucesso do Tenant falha.",
  "ccm.agent.calledroute.delfail": "Falha ao excluir os dados locais.",
  "ccm.custdata.button.importRecord": "Exibição de resultado de importação",
  "ccm.custdata.msg.confirmWithdraw": "Tem certeza de que deseja retirar esta tabela?",
  "ccm.satisfactionconfig.message.operate": "Operação",
  "ccm.agent.message.validatormsg": "As entradas relacionadas à senha devem estar em branco ou não em branco",
  "ccm.agent.message.skillConfFail": "Falha ao configurar as informações da fila de habilidades.",
  "ccm.recognition.label.sucess": "Sucesso",
  "ccm.agent.calledroute.exception": "Verifique o status do Redis ou a configuração do CC-Gateway.",
  "ccm.satisfactionconfig.message.strategygroupmember": "Policy",
  "ccm.speciallisthis.operlog.downloadData": "Exportar histórico de listas especiais",
  "ccm.agent.message.skillHasbeenUsedByAgent": "A fila de habilidades foi ocupada por um agente. Exclua o agente primeiro.",
  "ccm.agent.leavemessage.notLoginIn": "O agente não entrou.",
  "ccm.custdata.label.columnType": "Tipo de campo",
  "ccm.agent.label.workNo": "ID do funcionário",
  "ccm.satisfactionconfig.message.strategy": "Política de Pesquisa de Satisfação",
  "ccm.urlconfig.pageurls.urlvalidate": "As urls devem começar com http:// ou https://",
  "ccm.callout.lable.number": "Valor do parâmetro",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkRepeat": "Há duplicação na seção selecionada!",
  "ccm.contact.msg.dataEmpty": "Os dados de operação não existem no AICC.",
  "ccm.contact.msg.dataverification": "Falha ao verificar o parâmetro de solicitação.",
  "ccm.speciallist.msg.enable.fail": "Falha na ativação",
  "ccm.agent.message.updateCtiAndDbSuccess": "Sincronização bem-sucedida.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnumber": "A hora inicial ou final deve ser um número.",
  "ccm.agent.message.batchExport": "Exportação de lote",
  "ccm.agent.contact.clicktodial": "Clique para discar",
  "ccm.custdata.label.dataImportRecord": "Registro de importação de dados",
  "ccm.custdata.option.maskStyle.maskFirst4": "Máscara primeiros quatro dígitos",
  "ccm.label.contactexport.exceedlimit2": ". Reduza o intervalo de tempo de dados ou adicione critérios de filtro de dados.",
  "ccm.transserver.label.edit": "Servidor de recursos de dump",
  "ccm.transtask.label.exportdata": "Campo de dados de exportação:",
  "ccm.agent.accesscode.index": "Não",
  "ccm.satisfactionconfig.message.save": "Salvar",
  "ccm.certificate.management.certcode": "Código do certificado",
  "ccm.agent.message.ctipasswdsimple": "A senha não atende aos requisitos de complexidade da plataforma. Entre em contato com o pessoal da O M para verificar a senha.",
  "ccm.agent.calledroute.fail": "Falha",
  "ccm.agent.message.agentNotFoundFail": "Falha ao localizar informações do agente.",
  "ccm.custdata.label.isEncrypt": "Criptografado",
  "ccm.agent.message.existDualchannelrecAgent": "O reconhecimento inteligente e a gravação de duas trilhas não podem ser ativados ao mesmo tempo, enquanto a gravação de duas trilhas foi ativada para alguns dos agentes a serem modificados em lotes.",
  "ccm.transtask.label.contactchoose": "Campos personalizados no registro de contato",
  "ccm.satisfactionconfig.message.grouppriority": "Prioridade",
  "ccm.agent.label.sipPwd": "Senha do software",
  "ccm.recognition.label.modifymobileagentsuccess": "Modificado com sucesso o status do agente móvel!",
  "ccm.agent.operation.RECORDPLAY": "Reproduzir gravação",
  "ccm.label.contactexport.curpage": "Página atual",
  "ccm.transserver.label.servertype": "Tipo",
  "ccm.label.contactexport.exceedlimit1": "No máximo {0} registros de dados podem ser exportados",
  "ccm.agent.contact.pictures": "imagens",
  "ccm.agent.operation.CANCELINSERT": "Cancelar inserção",
  "ccm.agent.label.waitSkillWeight": "Insira um peso de habilidade.",
  "ccm.agent.page.release": "Liberação",
  "ccm.satisfactionconfig.audio.and.video": "Pesquisa de Satisfação de Canal de Voz ou Vídeo",
  "ccm.agent.pageurls.isconfreset": "Deseja realmente redefinir?",
  "ccm.agent.page.pageParameter": "Parâmetros de página",
  "ccm.agent.operation.optype": "Tipo de operação",
  "ccm.agent.label.skillWeight": "Peso da habilidade do agente",
  "ccm.case.label.recommendedWorkOrder0": "Classificação de ticket recomendada 1",
  "ccm.botherlist.label.approveStatus.approved": "Aprovado",
  "ccm.agent.Transfer.devicetype": "Tipo de dispositivo de destino",
  "ccm.agent.leavemessage.QUERU_LEAVEMESSAGE_FAIL_CODE": "Esvaziar informações SFTP para consultar mensagens.",
  "ccm.agent.pageurls.confreset": "Confirmar reinicialização",
  "ccm.agent.operation.noMoreThan3Days": "O intervalo de tempo não pode exceder 3 dias.",
  "ccm.case.label.recommendedWorkOrder2": "Classificação de ticket recomendada 3",
  "ccm.case.label.recommendedWorkOrder1": "Classificação de ticket recomendada 2",
  "ccm.agent.sysparamconfig.outboundtimecheck": "O limite de tempo para visualização automática de chamadas de saída deve variar de 1s a 3600s.",
  "ccm.agent.resetskillrecord.adjustresult": "Ajustar resultado",
  "ccmanagement.satisfaction.strategy.calltime.second": "s",
  "ccm.speciallist.msg.restoreout": "Um espaço de locatário pode armazenar um máximo de {0} registros de lista especial.",
  "ccm.custdata.option.importDoing": "Processamento",
  "ccm.agent.contact.CONNECT_FAIL_CODE": "Falha ao conectar ao servidor SFTP.",
  "ccm.basicdata.msg.modifysuccess": "Os dados básicos são modificados com sucesso.",
  "ccm.sysparam.error.validate.notmatchruleminlength": "O parâmetro de entrada é menor que o comprimento mínimo",
  "ccm.contactitemconfig.message.itemValidate": "A primeira letra deve ser uma letra, (_), sublinhado ou cifrão ($). outras letras podem ser sublinhados como cifrão ($), letras ou números.",
  "ccm.agent.contact.callednook": "Chamada de saída sem resposta",
  "ccm.urlconfig.modify.callnotify.ak.tooshort": "Comprimento da chave não inferior a 16 caracteres",
  "ccm.recognition.label.appSecret.reset": "redefinir SK",
  "ccm.recognition.msg.unsubscribeConfirm": "Desativar o reconhecimento inteligente também desativará o Assistente de agente inteligente.",
  "ccm.agent.contact.acceptno": "Número manipulado",
  "ccm.satisfactionconfig.message.updatefailed": "Falha na atualização da configuração da pesquisa de satisfação",
  "ccm.speciallist.msg.applyTimeFrom": "Hora de início do aplicativo",
  "ccm.agent.contact.resetAll": "Redefinir tudo",
  "ccm.transtask.label.cancel": "Cancelar",
  "ccm.anonymous.message.fail.open": "Falha ao abrir a bandeira. Verificar a configuração CTI",
  "ccm.idauth.create.error.length": "A codificação do método de autenticação excedeu o comprimento máximo.",
  "ccm.asr.create.case.chat.info": "Clique em Criar caso durante ou após o bate-papo.",
  "ccm.agent.label.subtype": "Subtipo",
  "ccm.agent.message.aiagentnummore": "O número de agentes inteligentes habilitados não pode exceder o número de agentes alocados quando o tenant está habilitado.",
  "ccm.taskrecord.label.executeFail": "Falha",
  "casemanagement.user.title.account": "Conta",
  "ccm.agent.message.modifymobileagentsuccess": "Informações modificadas com sucesso sobre o número de softphone do agente móvel.",
  "ccm.certificate.management.certificatefile.suffixerror.pre": "certificados de tipo podem conter apenas arquivos com a extensão",
  "ccm.transtask.label.task.uniqueness": "Solo se puede crear una tarea de datos de reporte para el mismo tipo de datos de volcado y el mismo intervalo de volcado.",
  "ccm.operlog.contactfile.download": "Baixar arquivo",
  "ccm.agent.message.agentnumcannotmuchthanmax": "Número do tipo de agente alvo não pode muito do que o máximo",
  "ccm.callout.lable.index": "Índice",
  "ccmanagement.satisfaction.strategy.minutevalidate": "O minuto está em um formato incorreto.",
  "ccm.satisfactionconfig.message.failtogetivrflow": "Ative a pesquisa de satisfação",
  "ccm.agent.restReason.selectRestReasons": "Selecione um motivo de repouso.",
  "ccm.agent.clear.message.agentnotexist": "Falha ao cancelar a configuração porque o agente não existe.",
  "ccmanagement.satisfaction.strategy.deletesuccess": "Exclusão bem-sucedida.",
  "ccm.callreason.label.selectedreason": "Motivos selecionados",
  "ccm.certificate.management.certificatefile.certtypeerror": "O tipo de cert é erro.",
  "ccm.agent.message.searchAccount": "Insira uma ID do agente.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnull": "A hora inicial ou final não pode estar vazia.",
  "ccm.agent.contact.callinandcallout": "Chamadas recebidas e efetuadas",
  "ccm.speciallist.msg.restoreerror": "Falha ao restaurar a lista especial",
  "ccmanagement.satisfaction.strategy.calltime.lengthlimit": "A duração mínima ou máxima da chamada pode conter um máximo de 12 dígitos.",
  "ccm.agent.calledroute.create.ivr.limit": "Se o tipo de dispositivo for IVR, o tipo de mídia do código de acesso só pode ser clique a discar, solicitação de retorno de chamada ou chamada comum.",
  "ccm.agent.contact.playcomfirm": "Repr.",
  "ccm.sysparam.error.validate.notmatchrulerangelength": "O parâmetro de entrada excede o comprimento máximo ou está abaixo do comprimento mínimo",
  "ccm.contactitemconfig.label.data.extended.dataset.mapping.field": "Campo de mapeamento do conjunto de dados",
  "ccm.accesscode.label.index": "Não",
  "ccm.transserverparam.label.proxyUserName": "Nome do usuário",
  "ccm.common.ajax.searchErr": "Erro de pesquisa",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnumber": "A data inicial ou final está em um formato incorreto.",
  "ccm.agent.label.weight": "Peso",
  "ccm.vdn.label.defaultvoice": "Padrão",
  "ccm.transtask.label.dumpinterval": "Duração do dump",
  "ccm.agent.operation.INSERT": "Inserir verificação de qualidade",
  "ccm.contact.customer.config.foregroundColor": "Cor do primeiro plano",
  "ccm.satisfactionconfig.message.strategychannelid": "ID da política do canal",
  "ccm.agent.label.batchselectway": "Modo de seleção em lote",
  "ccm.calledroute.title.selectivrflow": "Selecionar IVR",
  "ccm.agent.Transfer.deviceAddr": "Endereço do dispositivo de destino",
  "ccm.agent.label.agentWeightTip": "As chamadas são alocadas preferencialmente a agentes com o peso mais alto.",
  "ccm.contact.customer.config.datasource.objName": "Campo Mapeamento de Objeto de Origem de Dados",
  "ccm.note.config.choosenote": "Selecione um arquivo de notas",
  "ccm.agent.softPhoneStatus.Registered": "registrado",
  "ccm.satisfactionlevel.message.satislevel": "Nível de satisfação",
  "ccm.note.config.agent.ring.back.tone": "Configuração do tom de retorno do Agente",
  "ccm.pageinterface.addObject": "Adicionar objeto",
  "ccm.transtask.label.discarded": "Descartado",
  "ccm.agent.label.inputstartworkno": "Insira a ID do funcionário inicial",
  "ccm.sysparam.error.validate.notmatchruleemail": "Por favor, digite um endereço de e-mail válido",
  "ccm.satisfactionconfig.message.audio": "Pesquisa de Satisfação",
  "ccm.agent.ten.DSTOffset": "Deslocamento do fuso horário (min)",
  "ccm.agent.button.export": "Exportar",
  "ccm.contactitemconfig.label.data.extended.Type.String": "Cadeia de caracteres",
  "ccm.contact.msg.inactivesuccess": "Invalidação bem-sucedida.",
  "ccm.agent.contact.calltype": "Tipo de chamada",
  "ccm.agent.message.createfailed": "Falha na criação do agente",
  "ccm.dual.call.record.noMatchingDualCallRecordExists": "Nenhum registro de chamada bidirecional atende aos critérios.",
  "ccm.agent.calledroute.updatecalledroute": "Editar",
  "ccm.agent.calledroute.title.create": "Criando uma Configuração de Parte Chamada",
  "ccm.agent.calledroute.title.updatecalledroute": "Editar Configuração da Parte Chamada",
  "ccm.transtask.label.cdrData": "Dados CDR",
  "ccm.agent.leavemessage.auditcomments": "Comentários da auditoria",
  "ccmanagement.satisfaction.strategy.operation": "Operation",
  "ccm.speciallist.msg.typeName": "Tipo de lista especial",
  "ccm.satisfactionconfig.message.strategychanneltitle": "Configurar política de canal",
  "ccm.satisfactionconfig.add.strategychanneltitle": "Criando uma Política de Satisfação do Canal",
  "ccm.satisfactionconfig.update.strategychanneltitle": "Editar Estratégia de Satisfação do Canal",
  "ccm.transtask.label.contactrcd": "Dump de registro de contato",
  "ccm.agent.calledroute.accesscodewarn": "O código de acesso pode conter apenas dígitos!",
  "ccm.whatsapp.template.message.name": "Nome da Mensagem",
  "ccm.speciallist.msg.delete.fail": "Falha ao excluir",
  "ccm.agent.contact.clicktocall": "Clicar para discar",
  "ccm.agent.ten.asrchannelnums": "Instâncias do ASR",
  "ccm.agent.label.scaleFactor": "Fator de escala",
  "ccm.custdata.msg.confirmDelete": "Tem certeza de que deseja excluir?",
  "ccm.speciallist.msg.errorNum": "{limit} falharam ",
  "ccm.certificate.management.passwd": "Senha do certificado",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnull": "A duração mínima ou máxima da chamada não pode estar vazia.",
  "ccm.transtask.label.dumpintervalInitial": "Início da duração do dump",
  "ccm.agent.message.willDeleteSkills": "Êtes-vous sûr de vouloir supprimer les files d'attente de compétences {0} ?",
  "ccm.agent.message.willDeleteSkillGroups": "Tem certeza de que deseja excluir os grupos de habilidades selecionados?",
  "ccm.custdata.operlog.confirm": "Confirmar",
  "ccm.agent.contact.multi-media": "Multimídia",
  "ccm.contactitemconfig.message.itemValidatefirst": "A primeira letra deve ser uma letra, (_), de sublinhado ou cifrão ($)",
  "ccm.pageinterface.pleaseSelectAttribute": "Selecione um atributo de objeto.",
  "ccm.urlconfig.message.httpurl.safetip": "(Inseguro)",
  "ccm.agent.message.agentConfFail": "Falha ao configurar informações do agente.",
  "ccm.satisfactionconfig.message.flowname": "Nome do processo",
  "ccm.custdata.label.importantTips": "Observação: os dados pessoais ou confidenciais devem ser criptografados para armazenamento e mascarados para exibição.",
  "ccm.recognition.label.appSecret.download.querySK.error": "Falha ao consultar a SK.",
  "ccm.agent.contact.weChat": "WeChat",
  "ccm.agent.contact.oircall": "Número chamado original",
  "ccm.agent.label.scaleFactorRange": "Valor [0-10000]",
  "ccm.agent.restrecord.reststatus.timeout.Forcible.endrest": "Repouso forçado a terminar",
  "ccmanagement.agent.batchmodify.agentnum": "Por ID de funcionário",
  "ccm.speciallist.msg.necessary": "O número do usuário é obrigatório",
  "ccm.recognition.label.closeanonymoussuccess": "Desativado com sucesso o sinalizador de chamada originada anônima",
  "ccm.transserver.label.servername": "Nome do servidor",
  "ccm.agent.message.numInRowFail": "O valor deve ser um inteiro que varia de 2 a 4.",
  "ccm.transtask.label.status": "Status",
  "ccm.agent.label.callDispatchModel": "Modo de distribuição de chamadas",
  "ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE": "As informações de gravação estão vazias.",
  "ccm.recognition.label.cancelordersucess": "A assinatura foi cancelada com sucesso.",
  "ccm.agent.leavemessage.statusFailed": "Falha ao alterar o status do registro da mensagem.",
  "ccm.agent.message.sysParamCofig.closeVersatileAgentFail": "Altere o tipo de agente na página Gerenciamento de agentes. Um agente versátil não pode ser associado a uma fila de habilidades de vídeo ou clicar para discar por vídeo.",
  "ccm.skillcreate.repeat.fail": "O nome da fila de habilidades já existe",
  "ccm.speciallist.msg.selectonlyslreminder": "Pode modificar apenas um lembrete de lista especial.",
  "ccm.skill.label.skillname": "Nome da habilidade",
  "ccm.agent.title.skillCreate": "Nova fila de habilidades",
  "ccm.custdata.option.maskStyle.maskEmail": "Máscara de e-mail",
  "ccm.agent.contact.QUERY_SERVER_FAIL_CODE": "Falha ao chamar o serviço de consulta.",
  "ccm.satisfactionconfig.message.strategygroupname": "Nome do Grupo de Políticas",
  "ccm.satisfactionconfig.title.strategygroup": "Criando um Grupo de Políticas",
  "ccm.sysparam.config.itemdesc": "descrição",
  "ccm.agent.label.keepVoiceCustom": "Personalizando o Keeping Tone",
  "ccm.agent.label.numInRow": "Insira um número inteiro que varia de 2 a 4.",
  "ccm.transserver.label.view": "Exibir",
  "ccm.custdata.label.columnNo": "Não",
  "ccm.custdata.label.isMaskDisplay": "Mascarado",
  "ccm.urlconfig.label.oauth.oauth2loginurl": "Insira o URL de Login de Autenticação do OAuth 2.0",
  "ccm.agent.button.batchconfig": "Configuração do lote",
  "ccm.agent.pageurls.errorsave": "Erro",
  "ccm.certificate.management.update.cancel.certfile": "Tem certeza de que deseja cancelar a atualização do certificado?",
  "ccm.common.label.error": "Erro",
  "ccm.agent.operation.noMoreThan31Days": "O intervalo de tempo não pode exceder 31 dias.",
  "ccm.agent.label.validTime": "Período de validade da senha em dias",
  "ccm.agent.operation.UNHOLD": "Retire",
  "ccm.speciallist.msg.endtime": "A data de expiração não pode ser posterior a 19 de janeiro de 2038",
  "ccm.speciallist.label.line": "linhas",
  "ccm.agent.operation.CANCELREST": "Cancelar repouso",
  "ccm.custdata.option.importDone": "Concluído",
  "ccm.certificate.management.expiretime": "Horário de expiração",
  "ccm.label.contactexport.exception": "Ocorreu um erro ao gerar a tarefa de exportação. Verifique os registos.",
  "ccm.satisfactionconfig.message.validatemaxnumber": "Um máximo de 100 grupos de políticas podem ser criados.",
  "ccm.certificate.management.certificatecrlfile": "Arquivo CRL",
  "ccm.pageinterface.objectList": "Lista de objetos",
  "ccm.agent.label.information": "Dicas",
  "ccm.agent.label.selectagent": "Agentes selecionados",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "Palavra-chave",
  "ccm.satisfactionconfig.message.surveytype": "Tipo de Inquérito",
  "ccm.verifyidentity.FAIL": "Falha",
  "ccm.basicdata.error.datatypenotmatch": "O formato de dados é inconsistente com o formato de definição de campo",
  "ccm.agent.ten.timezone": "Fuso horário",
  "ccm.agent.recall": "Retorno de chamada",
  "ccm.pageinterface.interfaceType.external": "Interface externa",
  "ccm.dual.call.record.talkDuration": "Duração da chamada (s)",
  "ccm.custdata.label.columnLength": "Comprimento do campo",
  "ccm.agent.title.selectskill": "Selecionar fila de habilidades",
  "ccm.pageurls.select.externalInterface": "Página externa",
  "ccm.agent.contact.itacall": "Chamada bidirecional ITA",
  "ccm.agent.message.specialCharacters": "O número contém caracteres especiais e letras.",
  "ccm.custdata.msg.completeSort": "Termine de alterar a ordem primeiro.",
  "ccm.transserver.delete.css.confirm": "Tem certeza de que deseja excluir a configuração CSS?",
  "ccm.agent.restReason.delete": "Excluir",
  "ccm.agent.contact.accountname": "Nome da conta",
  "ccm.contact.customer.config.datasource.msgDataset": "Conjunto de dados de informação",
  "ccm.agent.message.ctipasswdrepeat": "A nova senha não pode ser a mesma que as últimas cinco senhas antigas.",
  "ccm.speciallist.msg.operationerror": "A operação falhou",
  "ccm.agent.ten.dstOn": "Ativado",
  "ccmanagement.tenantspace.option.weekday_mon": "Segunda",
  "ccm.agent.contact.relCallBackFlag": "Liberar Bandeira",
  "ccm.agent.label.batchselect": "Selecionar agentes em lotes",
  "ccm.ucconfig.message.title": "Registrar aplicativos com a plataforma de identidade da Microsoft",
  "ccm.callreason.label.enable": "Ativar",
  "ccm.agent.message.agenttypeinvalid": "O tipo de agente é inválido.",
  "ccm.contactitemconfig.label.data.extended.set": "Conjunto de dados de origem",
  "ccm.agent.message.worknonoexit": "A ID do funcionário não existe.",
  "ccm.agent.message.worknull": "A ID do funcionário está vazia.",
  "ccm.satisfactionconfig.message.update": "Edit",
  "ccm.agent.label.busy": "Habilidade Ocupada",
  "ccm.speciallist.msg.exists": "O usuário selecionado já está incluído em uma lista especial",
  "ccm.satisfactionconfig.message.surveystrategygroup": "Política da Pesquisa",
  "ccm.recognition.label.modifymobileagentflowidnull": "A ID do fluxo está vazia!",
  "ccm.agent.message.configvalidatefailed": "Falha na validação do item de configuração de gateway",
  "ccm.agent.label.agentList": "Conta de serviço",
  "ccm.sysparam.error.validate.notmatchrulerange": "O parâmetro de entrada excede o valor máximo ou está abaixo do valor mínimo",
  "ccm.calledconfig.message.willdeleteassociation": "Tem certeza de que deseja excluir as informações de associação do motivo da chamada",
  "ccm.agent.label.ninetydays": "90 dias",
  "ccm.agent.url.params.tips": "Observe que a forma como a URL GET REQUEST Parameter é feita expõe seus parâmetros a um endereço aberto.",
  "ccm.contact.customer.config.visible": "Visível",
  "ccm.agent.updateSoftPhone.accountPasswordFailed": "Falha ao verificar a senha da conta.",
  "ccm.contact.customer.config.preview": "Pré-visualização",
  "ccm.agent.message.sysParamCofig": "Falha ao configurar parâmetros do sistema.",
  "ccm.custdata.operlog.success": "Sucesso",
  "ccm.agent.leavemessage.inputauditcomments": "Insira sugestões.",
  "ccm.agent.mobileagent.label.nodata": "Nenhum dado correspondente encontrado!",
  "ccm.agent.operation.SAYBUSY": "Ocupado",
  "ccm.asr.create.case.text.info": "O caso não pode ser criado porque não há conteúdo de texto.",
  "ccm.note.config.agent.page.ring": "A campainha",
  "ccm.agent.pageurls.moveup": "Mover para cima",
  "ccm.custdata.operlog.downloadTemplate": "Baixando o Modelo de Tabela Básica",
  "ccm.agent.leavemessage.notAgent": "A conta não está vinculada a um agente.",
  "ccm.agent.pageurls.confdelete": "Confirmar exclusão",
  "ccm.agent.password.agentNull": "As informações do agente estão vazias.",
  "ccm.agent.operation.TWOSTAGEDIALING": "Discagem em dois estágios",
  "ccm.label.contactexport.nocount": "Nenhum registro de contato cumpre as condições.",
  "ccm.label.dualcallexport.nocount": "Não há registros de chamadas duplas elegíveis.",
  "ccm.recognition.label.processaccesscode": "inserir um código de acesso ao fluxo.",
  "ccm.transtask.label.saturday": "Sábado",
  "ccm.custdata.label.columnNum": "Campos",
  "ccm.agent.leavemessage.workNo": "Agente",
  "ccm.agent.contact.callinandout": "Chamada dupla",
  "ccm.agent.contact.user.recall": "O usuário retirou a mensagem",
  "ccm.agent.mobileagent.label.deletecomplete": "Excluir agente completo!",
  "ccm.agent.calledroute.refresh": "Atualizar",
  "ccm.verifyidentity.fail.TIMEPARAMNULLERROR": "O tempo de consulta não pode estar vazio",
  "ccm.contactitemconfig.label.data.extended.dataset.name": "Nome do conjunto de dados",
  "ccm.agent.label.adjustTime": "Duração (s) no Estado Organizador",
  "ccm.agent.contact.gateway.exception": "Exceção interna do gateway. Verifique a configuração do gateway.",
  "ccm.speciallist.msg.success": "bem-sucedido",
  "ccm.contact.detail.message.content": "Conteúdo da mensagem",
  "ccm.custdata.msg.fileSizeValidateFail": "Selecione um arquivo com menos de 20 MB.",
  "ccm.speciallist.label.reason": "Razão para a adesão",
  "ccm.callout.message.editfail": "Falha ao editar o chamador de chamada de chamada",
  "ccm.note.config.agent.page.ring.voice": "Lembrete de chamadas recebidas em áudio e vídeo",
  "ccm.agent.restrecord.rest.beginTime": "Hora de início de repouso",
  "ccm.agent.speciallist.validityPeriod": "Período de Efetividade",
  "ccm.agent.speciallist.begintime": "Tempo efetivo",
  "ccm.transserverparam.label.bucket": "Período de dados",
  "ccm.agent.calledroute.editfail": "Falha ao editar os dados locais.",
  "ccm.agent.contact.resourcefail": "Falha ao carregar a gravação.",
  "ccm.satisfactionconfig.message.creategroup": "Adicionar Grupo de Políticas",
  "ccm.agent.contact.calledhangup": "Chamada encerrada pela parte chamada",
  "ccm.agent.calledroute.skill": "Fila de habilidades",
  "ccm.sysparam.config.checkmessage": "Contém caracteres especiais (\"<\", \">\", \"/\",, etc.)",
  "ccm.contact.customer.config.regular": "RegularRegularidade",
  "ccm.basicpage.not.sk.download": "Nenhuma permissão de download da SK está disponível.",
  "ccm.satisfactionconfig.sms.satisfaction.survey": "SMS Satisfaction Survey",
  "ccm.agent.label.restQueueRule.lessRest": "Aqueles com uma pequena porcentagem de tempo de descanso no dia atual têm prioridade",
  "ccm.recognition.label.interconnection.parameters": "Parâmetros de interconexão",
  "ccm.agent.label.transparentDisplayFlag": "Anonimização de Agente",
  "ccm.calledconfig.message.numbersinvalid": "Mais de 15 ou menos de 1 números",
  "ccm.agent.operation.noMoreThan7Days": "O intervalo de tempo não pode exceder 7 dias.",
  "ccm.agent.callreason.modify.success": "Modificando o motivo da chamada com sucesso.",
  "ccm.speciallist.msg.specialchar": "A mensagem de lembrete não pode conter caracteres especiais.",
  "ccm.speciallevel.speciallist.use": "Disponível",
  "ccm.custdata.option.status.draft": "Rascunho",
  "ccm.agent.contact.transferRecord": "Transferir registro",
  "ccm.pageinterface.viewAttribute": "Visualizar atributo do objeto",
  "ccm.urlconfig.message.addfail": "Falha ao adicionar as urls",
  "ccm.skill.message.skillpara": "Não é possível configurar uma fila de habilidades.",
  "ccm.agent.label.callDispatchModel.average": "Distribuído uniformemente",
  "ccm.idauth.title.name": "Nome do modo de autenticação",
  "ccm.agent.label.serviceAccountCode": "Conta",
  "ccm.agent.label.thirtyonedays": "31 dias",
  "ccm.page.label.pageInput": "inserir um nome de página",
  "ccm.recognition.label.appSecret.download.appId.empty.error": "O campo appId não pode estar vazio.",
  "ccm.agent.contact.line": "LINE",
  "ccm.transtask.label.delete": "A tarefa de dump foi excluída com sucesso.",
  "ccm.certificate.management.certificatefile.certpwderror": "A senha do cert é inválida.",
  "ccm.agent.label.sensitiveWord": "Linguagem proibida! Por favor, preste atenção ao texto.",
  "ccm.idauth.title.create": "Criar Processo de Autenticação",
  "ccm.speciallist.msg.typeidempty": "tipo de lista especial não pode estar vazio.",
  "ccm.agent.label.deviceType": "Tipo de dispositivo",
  "ccm.agent.label.ivr": "IVR",
  "ccm.agent.leavemessage.assign": "Atribuir",
  "ccm.agent.leavemessage.title.assign": "Atribuindo Mensagens",
  "ccm.custdata.operlog.popwindowmessage": "Insira a senha usada para gerar o arquivo compactado. A senha compactada deve conter letras, números, caracteres especiais _ {'@'} {'%'}",
  "ccm.agent.label.bindTheSkill": "Desvincule a rota chamada antes de usar a fila de habilidades.",
  "ccm.agent.label.groupUseTheSkill": ". Por favor, exclua a configuração da fila de habilidades no grupo de habilidades primeiro.",
  "ccm.agent.label.groupBind": "A fila de habilidades foi vinculada ao grupo de habilidades ",
  "ccm.transtask.label.month": "Mês",
  "ccm.agent.contact.gateway.fail": "Solicitação para a Exceção do gateway. Verifique a configuração do gateway.",
  "ccm.recognition.label.mobileagent": "Agente móvel",
  "ccm.urlconfig.label.relmethod": "Método de solicitação HTTP",
  "ccm.agent.contact.robothungup": "Robô",
  "ccm.agent.pageurls.maxcalling": "O número máximo de pop-up de chamadas recebidas é {limit}.",
  "ccm.contact.customer.config.underline": "Sublinhado",
  "ccm.contactitemconfig.message.confOnlyDataItemCode": "A operação falhou porque a ID do item de dados já existe.",
  "ccm.agent.leavemessage.channel": "Canal",
  "ccm.vdn.label.index": "meses",
  "ccm.agent.message.updateAgentAndSkillFail": "Falha ao atualizar a associação da fila de habilidades do agente.",
  "ccm.pageinterface.selectedAttribute": "Atributo selecionado",
  "ccm.agent.calledroute.devicetype": "Tipo de dispositivo",
  "ccm.verifyidentity.SUCCESS": "Aprovado",
  "ccm.contact.customer.config.backgroundColor": "Cor de fundo",
  "ccm.transserverparam.label.login.cssClusterUser": "Nome de Usuário de Login do Cluster",
  "ccm.agent.label.parameterType": "Método de enfileiramento",
  "ccm.transtask.label.thurs": "Quinta-feira",
  "ccm.agent.contact.talkRemark": "Observações da chamada",
  "ccm.pageinterface.cancelAll": "Cancelar todos",
  "SM.ORGSTAFF.LABEL.ORGANIZATION": "Unidade da Organização",
  "ccm.agent.message.searchSkillName": "inserir o nome da fila de habilidades.",
  "ccm.recognition.label.intellagent": "Assistente de Agente Inteligente",
  "ccm.label.pageName": "Nome da página",
  "ccm.agent.ccagentinfo.unregister.fail": "Falha ao cancelar o registro",
  "ccm.agent.operation.CALLOUT": "Chamada efetuada",
  "ccm.agent.calledroute.nmsfail": "Falha na sincronização com o NMS.",
  "ccm.sysparam.error.validate.notmatchruledigits": "Por favor, insira um número inteiro positivo válido",
  "ccm.agent.message.agentCTIFail": "Agentes de consulta modificados no gateway falharam.Verifique a configuração do gateway.",
  "ccm.transtask.label.notEnabled": "Desabilitado",
  "ccm.agent.button.cancel": "Cancelar",
  "ccm.custdata.label.description": "Descrição",
  "ccm.agent.addbother.msg.emailOrPhoneValidate": "Formato incorreto.",
  "ccm.basicdata.error.firstlinenotmatch": "A primeira linha no arquivo importado é inconsistente com a no modelo",
  "ccm.agent.ten.dstOff": "Desabilitado",
  "ccm.agent.message.dataIntegrityFail": "Dados incompletos configurados para o layout de página ou conjunto de interface. Falha na operação.",
  "ccm.agent.ten.systemdefault": "Fuso horário padrão",
  "ccm.contract.management.confirm": "O documento contém dados sensíveis. Tem certeza de que deseja continuar?",
  "ccm.contract.management.confirmtitle": "Confirmar operação",
  "ccm.speciallist.msg.restoresuccess": "A restauração foi bem sucedida",
  "ccm.agent.callreason.need": "Selecione pelo menos um motivo de chamada.",
  "ccm.pageinterface.title": "Interface de página",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputlength": "A duração mínima ou máxima da chamada pode conter um máximo de 12 dígitos.",
  "ccm.satisfactionconfig.message.version": "Número da versão",
  "ccm.agent.sysparamconfig.resttimenumberwarn": "A duração de repouso deve variar de 1 a 1440 (não contém 1440)",
  "ccm.agent.pageurls.edit": "Editar",
  "ccm.speciallist.tip.time.max": "Selecione uma hora menor que a expiração do projeto (2038-01-19)",
  "ccm.agent.message.addAIRecogFail": "O número de agentes inteligentes habilitados não pode exceder o número de agentes alocados quando o tenant está habilitado.",
  "ccm.agent.label.singlePhoneFlag": "Agente apenas por telefone",
  "ccm.transtask.label.enable": "Ativar",
  "ccm.agent.message.searchSipAccount": "Insira um número de softphone SIP.",
  "ccm.agent.restrecord.reststatus.timeout.endrest": "Tempo limite de repouso",
  "ccm.agent.operation.FORCEIDEL": "Forçar disponível",
  "ccm.agent.updateSoftPhone.newPasswordFailed": "Falha ao verificar a nova senha.",
  "ccm.agent.button.refresh": "Atualizar",
  "ccm.agent.message.omsAgentTypeCheck": "A função selecionada não corresponde ao tipo de agente de um agente online.",
  "ccm.agent.restReason.restCauseDesc": "Descrição",
  "ccm.agent.message.videoNotEnough": "O número de recursos de vídeo é insuficiente porque tanto os agentes de vídeo como os agentes configurados com filas de habilidade de vídeo ocupam recursos de vídeo. Aloque recursos de vídeo corretamente.",
  "ccm.satisfactionconfig.message.smstitle": "SMS",
  "ccm.agent.ten.resourceinfor": "Informações sobre recursos",
  "ccm.agent.label.deviceUsage": "Utilização do dispositivo (%)",
  "ccm.speciallist.msg.warning": "Advertências",
  "ccm.custdata.option.maskStyle.maskBankAccountNum": "Máscara de conta bancária",
  "ccm.basicdata.msg.queryfail": "Falha ao consultar os dados básicos.",
  "ccm.agent.message.agenttypebothempty": "Um tipo de agente não está configurado para o agente selecionado. Você precisa configurar o tipo de agente.",
  "ccm.agent.contact.multimedia": "Multimídia",
  "ccm.agent.message.querycenterfailed": "Falha ao consultar o agente inteligente do call center.",
  "ccm.ucconfig.message.integration": "Integração com o Microsoft Teams",
  "ccm.ucconfig.message.info.alert": "Copie o seguinte endereço para a Microsoft para registro de aplicativo como o URI de redirecionamento de autenticação de aceitação. Copie o resultado do cadastro e preencha as seguintes informações do cadastro.",
  "ccm.ucconfig.message.copy": "Cópia",
  "ccm.ucconfig.message.register": "Registrando Informações do Aplicativo",
  "ccm.ucconfig.message.required.fields": "Campos obrigatórios",
  "ccm.ucconfig.message.required.limit": "O comprimento não pode exceder 64 caracteres.",
  "ccm.verifyidentity.userId": "ID do usuário",
  "ccm.agent.pageurlparam.calldata": "Dados da chamada",
  "ccm.custdata.option.status.abandon": "Descartar",
  "ccm.agent.label.release": "Liberação",
  "ccm.agent.message.synToCcpFailed": "Falha ao sincronizar o modo de autenticação do agente com o cc-provision",
  "ccm.agent.pageurls.adressval": "O endereço de acesso deve começar com 'https://' ou 'http://'.",
  "ccm.agent.pageurls.adressval.noPoint": "O endereço de acesso deve começar com 'https://' ou 'http://'",
  "ccmanagement.satisfaction.strategy.acceptdate.dateStart": "Data de início",
  "ccm.calledconfig.label.querysoftnum": "Ver números do Softphone",
  "ccm.agent.ten.tenantName": "Nome do tenant",
  "ccm.callreason.label.createrelatecase": "Casos Relacionados",
  "ccmanagement.satisfaction.strategy.fail.accepttime.startafterend": "O horário de início deve ser anterior ao horário de término.",
  "ccm.agent.updateSoftPhone.updateSuccess": "Senha alterada com sucesso",
  "ccm.agent.ten.DSTEndTime": "Hora término Horário de Verão",
  "ccm.agent.input.userNumber": "Número do usuário",
  "ccmanagement.satisfaction.strategy.fail.calltime.startafterend": "A duração mínima da chamada deve ser menor que a duração máxima da chamada.",
  "ccm.skill.placeholder.skilldesc": "Insira um nome de fila de habilidades.",
  "ccm.sysparam.error.validate.notmatchruleip": "Por favor, digite um endereço IPV4 ou IPV6 válido",
  "ccm.contact.label.signed": "Assinado",
  "ccm.ucconfig.message.failed": "Falhou",
  "ccm.pageurls.select.internalInterface": "Página interna",
  "ccm.agent.calledroute.refreshsuccess": "Atualização bem-sucedida.",
  "ccm.transtask.label.ivrAccessCodeReport": "Relatório de Estatísticas de Dados IVR por Código de Acesso",
  "ccm.contact.customer.config.writeBackField": "Campo de gravação de registro de contato estendido",
  "ccm.verifyidentity.label": "Autenticação de identidade",
  "ccm.agent.calledroute.ccivrdata.error": "Falha ao sincronizar dados com o NMS. Os dados do processo CC-IVR estão incorretos.",
  "ccm.certificate.management.search.certcode": "Insira o Código do Certificado",
  "ccm.systemparam.error.reset": "Falha ao redefinir os parâmetros.",
  "ccm.sysparam.config.item.detail": "Informação do parâmetro",
  "ccm.agent.contact.multimedia.facebook": "Multimídia-Facebook ",
  "ccm.certificate.management.certificate.tip": "Dicas:",
  "ccm.agent.pageurls.sernum": "Não",
  "ccm.whatsapp.whatsApp.message.template": "Modelo do WhatsApp",
  "ccm.agent.message.aiagentclosed": "O recurso de identificação inteligente do locatário está desativado. Entre em contato com o administrador do sistema.",
  "ccm.agent.callreason.exceedmaxnum": "Um máximo de cinco motivos de chamada são permitidos.",
  "ccm.agent.label.configAdmin": "Administrador de configuração",
  "ccm.satisfactionconfig.message.satisreport": "Relatório de pesquisa de satisfação",
  "ccm.agent.label.maxTalkTimeRange": "Valor [1-86400]",
  "ccm.certificate.management.certificatefile.typeerror": "Tipo de arquivo incorreto.",
  "ccm.agent.button.save": "Salvar",
  "ccm.agent.restReason.restCauseCode": "Código do Motivo de repouso",
  "ccm.agent.message.agentandskillnotmatch": "O tipo de habilidade deve corresponder ao tipo de agente",
  "ccm.custdata.operlog.addExportTaskSuccess": "Nova tarefa de exportação bem-sucedida",
  "ccm.label.page.delete.confirm": "Tem certeza de que deseja excluir?",
  "ccm.calledconfig.message.overmaxnum": "Máximo {0} alcançado",
  "ccm.custdata.option.maskStyle.maskIdCardNum": "Máscara de número de cartão de identificação",
  "ccmanagement.tenantspace.option.weeklist_fir": "Primeiro",
  "ccm.systemparam.success.reset": "Os parâmetros foram redefinidos com sucesso.",
  "ccm.agent.updateSoftPhone.unFoundInfo": "O call center não está sincronizado.",
  "ccm.sysparam.error.validate.notmatchrulerequired": "Preenchimento obrigatório",
  "ccm.custdata.operlog.uploadDataFile": "Carregando o Arquivo de Dados de Tabela Básica",
  "ccm.whatsapp.select.whatsApp.channel": "Selecione um canal do WhatsApp.",
  "ccm.transserver.label.proxynotinwhite": "Operação falhou. Entre em contato com o administrador do sistema para adicionar o endereço do servidor proxy à lista confiável de proxy do servidor OBS.",
  "ccm.agent.leavemessage.cannotBeModified": "A mensagem não pode ser modificada no estado atual.",
  "ccm.dual.call.record.endTime": "Hora de Fim da Chamada",
  "ccm.transtask.label.tasknameexits": "Falha ao criar a tarefa porque o nome da tarefa já existe.",
  "ccm.recognition.label.anonymous": "Sinalizador de chamada originada anônima",
  "ccm.custdata.option.status.release": "Liberação",
  "ccm.contact.customer.config.key": "Chave de dados",
  "ccm.custdata.msg.tableExistInstData": "A tabela personalizada contém dados de instância e não pode ser retirada para modificação.",
  "ccm.transtask.config.pathvalidate": "O caminho do arquivo de dump não pode conter \"./\".",
  "ccm.custdata.operlog.downloadData": "Baixando Dados de Tabela Básica",
  "ccm.agent.label.queuingCallsMaxNumRangeTwo": "Valor [1-10000]",
  "ccm.speciallist.msg.sltype": "Tipo de lista especial",
  "ccm.satisfactionlevel.message.resetsuccess": "Os níveis de satisfação e os modelos de pesquisa de satisfação são redefinidos com êxito.",
  "ccm.speciallist.msg.userno.error": "O formato do número do usuário está incorreto",
  "ccm.systemparam.error.notmatchrule": "O parâmetro não atende à regra de verificação.",
  "ccm.agent.leavemessage.process": "Processo",
  "ccm.agent.label.skillQueue": "Fila de habilidades:",
  "ccm.certificate.management.certificatefile.maxrecord": "O número máximo de certificados é {0}.",
  "ccm.contact.detail.sending.time": "Horário de envio",
  "ccm.agent.message.searchAgentServiceNo": "Insira o número do serviço do agente",
  "ccm.agent.calledroute.create.gateway.none": "O script de roteamento referenciado pela configuração da parte chamada não existe.",
  "ccm.agent.label.sipAccount": "Número do Softphone",
  "ccm.agent.updateSoftPhone.updateGatewayFailed": "Falha ao alterar a senha do gateway.",
  "ccm.agent.sysparamconfig.verifyTemplateIdLength": "A ID do modelo de mensagem interna deve ser um número inteiro e não pode ser um número negativo.",
  "ccm.agent.message.validatordigit": "A senha de configuração do agente de telefone único deve ser apenas numérica",
  "ccm.agent.leavemessage.messageprocess": "Mensagem de Processar",
  "ccm.agent.label.agentType": "Tipo de Agente",
  "ccmanagement.tenantspace.option.weeklist_sec": "Segundo",
  "ccm.agent.message.deleteSkill": "Excluir fila de habilidades",
  "ccm.agent.label.notselectcallreason": "Selecione o motivo da chamada.",
  "ccm.recognition.label.orderfailwhilte": "Falha na assinatura. Falha ao sincronizar a lista de confiança.",
  "ccm.urlconfig.label.relurl": "URL de desligamento",
  "ccm.agent.message.modifySkillFail": "Falha na habilidade de atualização.",
  "ccm.transtask.label.contactRecord": "Registro de contato",
  "ccm.transserver.discard.confirm": "¿Estás seguro de que quieres abandonar la tarea?",
  "ccm.satisfactionconfig.message.media": "Canais multimídia",
  "ccm.label.page.modifyPage": "Página Modificar",
  "ccm.speciallist.msg.limit": "O número de conteúdos importados excede 1000",
  "ccm.satisfactionconfig.message.groupbatchdelete": "Exclusão do Lote",
  "ccm.custdata.title.modify": "Editando uma Tabela Base",
  "ccm.satisfactionconfig.web.switch.remark": "Depois que esse interruptor for ativado, a pesquisa de satisfação será enviada para o cliente após a conclusão do serviço.",
  "ccm.agent.label.authtype.uap": "Autenticação UAP",
  "ccm.idauth.create.error.limit": "O processo de autenticação suporta a criação de apenas cinco.",
  "ccm.whatsapp.template.message.id": "ID da Mensagem do Modelo",
  "ccm.agent.contact.talkReason": "Motivo da chamada",
  "ccm.satisfactionconfig.message.createsuccess": "Adicionada com sucesso a configuração da pesquisa de satisfação",
  "ccmanagement.satisfactionconfig.calltime.timeerror.laterThanEndTime": "A duração mínima da chamada deve ser menor que a duração máxima da chamada.",
  "ccm.transtask.label.restores": "Restaurar",
  "ccm.pageinterface.inputInterfaceName": "Inserir",
  "ccm.agent.contact.startcallfailed": "Falha ao iniciar chamada",
  "ccm.agent.message.deleteSkillConfSuccess": "As informações da fila de habilidades são excluídas com sucesso.",
  "ccm.transtask.label.taskname": "Nome da tarefa",
  "ccm.custdata.operlog.conditions": "Condições",
  "ccm.label.contactexport.selectdata": "Selecionar dados",
  "ccm.agent.message.agenttypecannotbenull": "Selecione um tipo de agente se a fila de habilidades estiver selecionada.",
  "ccm.agent.pageurlparam.relSysParamType": "Tipo de Parâmetro de Dados do Sistema Associados",
  "ccm.agent.label.inputendworkno": "Insira a ID do funcionário final",
  "ccm.agent.message.pageNameValidateFail": "Caracteres especiais não são permitidos",
  "ccm.agent.message.itemNameValidateFail": "Os seguintes caracteres especiais não são permitidos &<>\";%/[]=+{'@'}",
  "ccm.calledconfig.message.willdeletemobileagent": "Tem certeza de que deseja excluir o agente móvel selecionado?",
  "ccm.label.page.createPage": "Nova Página",
  "ccm.agent.sysparamconfig.refreshAllSysParamSuccess": "Atualização bem-sucedida.",
  "ccm.recognition.label.openmobileagentsuccess": "Habilitado com sucesso o agente móvel!",
  "ccm.callreasonconfig.message.deletecallreason.failed": "Falha ao excluir.",
  "ccm.speciallist.msg.dateerror": "Necessário inserir a hora efetiva e a hora de expiração",
  "ccm.agent.contact.assistant": "Chamada colaborativa",
  "ccm.custdata.operlog.authFailed": "Falha de autenticação",
  "ccm.agent.label.useTheSkill": "A fila de habilidades {0} foi vinculada ao ID do agente {1}. Exclua primeiro a configuração da fila de habilidades sob o agente.",
  "ccm.agent.sysparamconfig.worknowarn": "Use (,) vírgulas para separar IDs do agente.",
  "ccm.agent.title.agentinfo": "Informações do agente",
  "ccm.agent.ten.agentSipInfo": "Endereço IP e número da porta do servidor de registro do agente",
  "ccmanagement.satisfaction.strategy.acceptdate.startdate": "Data de início",
  "ccm.satisfactionconfig.message.calltype": "Tipo de chamada",
  "ccm.dual.call.record.option": "Operação",
  "ccm.botherlist.label.comment": "Opiniões",
  "ccm.agent.contact.playVideo": "reproduzir um vídeo",
  "OPERLOGS.TRANSSERVERCONF.ADD": "Adicionar servidor de dump de recursos",
  "ccm.callout.message.confreset": "Confirmação de reinicialização",
  "ccm.custdata.label.isMandatory": "Obrigatório",
  "ccm.note.config.agent.page.ring.title": "Essa configuração só se aplica quando a página está oculta , como quando o navegador é minimizado ou a página está em uma guia em segundo plano.",
  "ccm.calledconfig.table.skillormumbers": "Dispositivo final",
  "ccm.agent.restrecord.reststatus.normal.endrest": "Descanso terminado normalmente",
  "ccm.agent.label.playVoice": "Tocar o tom de espera",
  "ccm.agent.calledroute.confdelete": "Confirmar exclusão",
  "ccm.contact.label.unsigned": "Não assinado",
  "ccm.satisfactionlevel.message.savefail": "Falha ao salvar o nível de satisfação.",
  "ccm.agent.label.createWorkOrdeType": "Tipo de caso associado ",
  "ccm.transtask.label.datacondition": "Critérios de Filtro de Dados",
  "ccm.transtask.label.agentOutBoundReport": "Relatório de resumo de chamadas de saída do agente",
  "ccm.agent.message.warning": "Aviso",
  "ccm.agent.sysparamconfig.multimediawarn": "O número de mensagens multimídia simultâneas deve variar de 1 a 60.",
  "ccm.systemparam.success.save": "Parâmetros salvos com sucesso.",
  "ccm.custdata.operlog.exportiong": "Exportando",
  "ccm.agent.calledroute.errorsave": "Erro",
  "ccm.agent.message.searchsoftphone": "inserir o número de softphone.",
  "ccm.agent.contact.calltime": "Duração da chamada (s)",
  "ccm.agent.button.downloadvideoterminal": "Baixar OpenEye",
  "ccm.calledconfig.label.numbers": "Números",
  "ccm.custdata.label.isIndex": "Usado como índice",
  "ccm.agent.message.synToUapFailed": "Falha ao sincronizar o modo de autenticação do agente com o UAP",
  "ccm.sysparam.error.validate.notmatchrulemaxlength": "Comprimento máximo do parâmetro",
  "ccm.urlconfig.label.talkmethod": "Método de chamada de conexão",
  "ccm.custdata.label.columnName": "Nome do campo",
  "ccm.agent.message.nocomments": "Sem comentários",
  "ccm.agent.message.willDeleteAgents": "Tem certeza de que deseja excluir o agente selecionado?",
  "ccm.custdata.msg.columnNoMatch": "O campo da tabela é inconsistente com o do banco de dados. Atualize a tabela e tente novamente.",
  "ccm.transserverparam.label.info": "Informações Básicas",
  "ccm.agent.contact.recordingfiles": "Arquivo de gravação",
  "ccm.agent.message.selectMaxAgents": "Você pode processar um máximo de 20 por vez.",
  "ccmanagement.satisfaction.strategy.fail": "Failure",
  "ccm.agent.message.existAIAgent": "O reconhecimento inteligente e a gravação de duas trilhas não podem ser ativados ao mesmo tempo, enquanto o reconhecimento inteligente foi ativado para alguns dos agentes a serem modificados em lotes.",
  "ccm.agent.label.fixedValue": "Valor fixo",
  "ccm.satisfactionconfig.message.delete": "Delete",
  "ccm.agent.operation.FORCELOGOUT": "Forçar saída",
  "ccm.agent.mobileagent.notice": "Aviso",
  "ccm.agent.operation.optime": "Tempo de operação",
  "ccm.certificate.management.certificate.crlfile.sizeerror": "O arquivo CRL não pode exceder 2048 KB.",
  "ccm.agent.title.specialListReminder": "Lembrete da lista especial",
  "ccm.recognition.label.fail": "Falha",
  "ccm.certificate.management.certificatefile.certsceneerror": "A cena do cert é erro.",
  "ccm.agent.calledroute.pagename": "Nome da página",
  "ccm.agent.leavemessage.channelType": "Tipo de canal",
  "ccm.satisfactionconfig.message.strategygroupsrv": "Configuração do Canal de Política de Pesquisa de Satisfação",
  "ccm.transserverparam.label.agentport": "Porta",
  "ccm.transserverparam.label.agentip": "Endereço do servidor proxy",
  "ccm.custdata.operlog.operation": "Operar",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.overlimit": "Um máximo de 100 políticas de data de tratamento podem ser salvas.",
  "ccm.agent.operation.HOLD": "Em Espera",
  "ccm.custdata.option.columnType.time": "Data e hora",
  "ccm.satisfactionconfig.message.duplicateconfig": "A prioridade de cada política para o mesmo canal deve ser exclusiva.",
  "ccm.botherlist.label.approveComment.approved": "Rejeitar",
  "ccm.satisfactionconfig.message.callskill": "Fila de Habilidades",
  "ccm.callreason.label.setcallreason": "Configuração do Motivo da Chamada",
  "ccm.satisfactionconfig.message.audiofeedbacktype": "IVR voice",
  "ccm.callout.placeholder.caller": "Insira o número de chamada da chamada de saída",
  "ccm.speciallist.msg.level": "Nível da lista especial",
  "ccm.basicdata.error.syserror": "Erro do sistema. Entre em contato com o pessoal de manutenção",
  "ccm.certificate.management.certificatefile.uploadcertcrlfile": "Carregar arquivo CRL",
  "ccm.satisfactionconfig.message.acceptagent": "Agente de manipulação",
  "ccm.satisfactionconfig.message.channelstrategyconfig": "Política de Pesquisa de Satisfação do Canal",
  "ccm.agent.resetskillrecord.adjustagentworkno": "Ajustando ID do agente",
  "ccm.speciallist.msg.alreadyexist": "já tem uma notificação de lista especial do mesmo tipo e nível.",
  "ccm.contactitemconfig.label.data.item.basicext": "Dados estendidos básicos",
  "ccm.callout.title.success": "Sucesso",
  "ccmanagement.tenantspace.option.weeklist_fth": "Quarto",
  "ccm.querycustdata.msg.selectTable": "Selecione um nome de tabela",
  "ccm.custdata.label.template": "_Modelo",
  "ccm.agent.label.registrationMessage": "Informações de registro",
  "ccm.transserver.label.serveraddr": "Endereço",
  "ccm.agent.button.submit": "Enviar",
  "ccm.agent.calledroute.create.accesscode.noexist": "O código de acesso referenciado pela configuração da parte chamada não existe.",
  "ccm.custdata.option.isMandatory.yes": "Preenchimento obrigatório",
  "ccm.note.config.filepath": "Selecionar arquivo de notas",
  "ccm.agent.contact.multimedia.telegram": "Multimedia-Telegram",
  "ccm.verifyidentity.verifyTime": "Verificar hora",
  "ccm.agent.contact.playLeaveMessage": "A seguinte mensagem é reproduzida:",
  "ccm.datatask.checkNum": "O valor de entrada é um inteiro positivo",
  "ccm.agent.Transfer.Intercept": "Interceptar",
  "ccm.satisfactionconfig.message.smschannel": "Canal Push SMS",
  "ccmanagement.satisfaction.strategy.calltime.maxvalue": "Duração(s) Máxima(s) da Chamada",
  "ccm.agent.conditionOfBusyTransfer.error": "Por favor, configure os parâmetros de condição de transferência de ocupado.",
  "ccm.agent.resetskillrecord.skillbeforeadjust": "Cola de habilidades antes del ajuste",
  "ccm.agent.label.mobileagententer": "Inserir",
  "ccm.agent.label.expired": "Expirado",
  "ccm.agent.pageurls.createcall": "Nova tela de chamada recebida pop-up",
  "ccm.agent.pageurls.config.screen.pop.up": "Pop-up da tela de configuração",
  "ccm.agent.pageurls.unsaved.data.exists":"Existem dados não guardados. Por favor, salve-o primeiro.",
  "ccm.agent.label.preVoiceSelect": "Anúncio antes de reportar a ID do agente",
  "ccm.accesscode.label.accesscode": "Código de acesso",
  "ccm.speciallevel.speciallist.levelMessage": "Gestão de Nível",
  "casemanagement.user.title.name": "Nome do usuário",
  "ccm.satisfactionconfig.message.confirm": "Confirmar",
  "ccm.note.config.agent.page.ring.tipTitle": "Se você configurar um lembrete pop-up, quando a conexão é integrada, você precisa configurar manualmente \"Permitir que este site use janelas pop-up\" no navegador para que o pop-up seja válido",
  "ccm.speciallist.tip.startTime.min": "A hora efetiva deve ser maior que a hora atual",
  "ccm.agent.label.usedMode": "Tipo",
  "ccm.agent.calledroute.operation": "Operação",
  "ccm.agent.title.batchagentEdit": "Configuração de informações do agente em lote",
  "ccm.contactitemconfig.label.data.item.category": "Tipo de dados",
  "ccm.agent.contact.allcalltype": "Todos os tipos de chamada",
  "ccm.basicdata.msg.nodatamodify": "Nenhum dado precisa ser modificado.",
  "ccm.basicdata.msg.modifyfail": "Falha ao modificar os dados básicos.",
  "ccm.speciallist.msg.approve": "Aprovação",
  "ccm.speciallist.label.approve": "Comentários de aprovação",
  "ccm.speciallist.poptitle.approve": "Aprovação de Registros de Assédio",
  "ccm.speciallist.poptitle.view": "Exibição de Registros de Assédio",
  "ccm.speciallist.title.approve": "Informações de Aprovação",
  "ccm.agent.button.clear": "Redefinir",
  "ccm.basicdata.error.databaseerror": "Os dados do índice são duplicados. Como resultado, os dados não podem ser importados para o banco de dados em lotes",
  "ccm.recognition.label.openanonymoussuccess": "Habilitado com sucesso o sinalizador de chamada de saída anônima. Selecione um código de acesso clique para chamada configurado para a rota chamada.",
  "ccm.satisfactionconfig.message.channeltype": "Tipo de Canal",
  "ccm.sysparam.error.validate.notmatchrulemin": "O parâmetro de entrada é inferior ao mínimo",
  "ccm.satisfactionconfig.param.faile": "Parâmetro incorreto.",
  "ccm.agent.updateSoftPhone.updateFailed": "Falha ao alterar a senha",
  "ccm.agent.calcOfMaxCallNum.error": "Por favor, configure o número máximo de chamadas em fila.",
  "ccm.contact.tip.not.get.workname": "O nome da conta correspondente ao agente não é obtido！",
  "ccm.contactitemconfig.message.DatasetName.inuse": "O conjunto de dados não pode ser excluído porque já está configurado no item de dados do registro de contato.",
  "ccm.sysparam.url.safe.tip": "O protocolo HTTP não é seguro, o que representa um risco de segurança",
  "ccm.agent.label.keepWaitType": "Mantendo o método",
  "ccm.transtask.label.tasktype": "Tipo",
  "casemanagement.common.label.nodata": "Sem dados.",
  "ccm.agent.label.Account": "Conta",
  "ccm.callout.lable.type": "Tipo de parâmetro",
  "ccm.custdata.option.columnType.date": "Data",
  "ccm.agent.calledroute.create.media.match": "O código de acesso do sistema configurado para a parte chamada é diferente do tipo de mídia configurado para a parte chamada.",
  "ccm.agent.label.noaireconition": "Falso",
  "ccm.agent.label.maxWaitTime": "Tempo máximo de espera (s)",
  "ccm.transserver.label.cssnotinwhite": "Operação falhou. Contacte o administrador do sistema para adicionar o endereço doserviço CSS à lista fidedigna do servidor CSS de despejo de recursos.",
  "ccm.chatbot.label.assist": "Assistente do robô",
  "ccm.verifyidentity.loginId": "Conta Comercial",
  "ccm.custdata.button.moveTop": "Topo",
  "ccm.agent.message.agentUpdateFail": "Log de atualização falhou.",
  "ccm.transtask.label.task.chatrecordall.uniqueness": "Um tipo omni-channel não pode ser adicionado a um tipo de canal existente.",
  "ccm.satisfactionconfig.message.strategygroupdesc": "Descrição do Grupo de Políticas",
  "ccm.agent.message.clear": "Limpar tudo",
  "ccm.agent.restrecord.rest.reason": "Motivo de descanso",
  "ccm.skill.label.skilldesc": "Descrição",
  "ccm.pageinterface.interfaceType.internal": "Interface interna",
  "ccm.calledroute.button.finish": "Concluído",
  "ccm.agent.label.operate": "Operação",
  "ccm.transtask.label.ivrReport": "Relatório de Dados IVR",
  "ccm.agent.contact.contactno": "Número de contato",
  "ccm.agent.resetskillrecord.adjustedbusinessaccount": "Conta de serviço ajustada",
  "ccm.agent.title.agentpassword": "Senha da conta",
  "ccmanagement.tenantspace.option.weeklist_sectolast": "Penultimo",
  "ccm.agent.title.serviceAccount": "Conta no Service Cloud associada",
  "ccm.urlconfig.label.oauth.ak": "AK",
  "ccm.agent.button.delete": "Excluir",
  "ccm.vcallcenter.config.popup.authentication": "Autenticar",
  "ccm.note.config.searchnotename": "Insira o nome",
  "ccm.custdata.operlog.addExportTaskError": "Nova tarefa de exportação falhou, verifique o registro",
  "ccm.sysparam.error.validate.notmatchruleurl": "Por favor, insira um endereço URL válido",
  "ccm.agent.restReason.create": "Adicionar",
  "ccm.agent.softPhoneStatus": "status do número de telefone softphone",
  "ccm.agent.ten.DSTStartDate": "Data início Horário de Verão",
  "ccm.anonymous.message.fail.save": "Falha ao salvar a rota chamada. Verifique a configuração CTI",
  "ccm.agent.label.video": "Agente de vídeo",
  "ccm.sysparam.error.validate.notmatchrulemax": "O parâmetro de entrada excede o máximo",
  "ccmanagement.tenantspace.option.weeklist_fthtolast": "Faltando 4",
  "ccm.agent.title.specialListHis": "Histórico da Lista Especial",
  "ccm.custdata.operlog.specCharValidate": "Não pode conter caracteres especiais, exceto _ @ %",
  "ccm.agent.message.selectAgents": "Selecione um agente.",
  "ccm.agent.contact.joinreason": "Razão para Ingressar",
  "ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE": "Falha ao baixar o arquivo de gravação do servidor SFTP.",
  "ccm.agent.message.redislocknull": "Falha ao obter o bloqueio Redis.",
  "ccm.sysparam.error.validate.inner": "Validar parâmetro falhou",
  "ccm.sysparam.config.reset": "Reiniciar",
  "ccm.urlconfig.message.deletefail": "Falha ao reinicializar as urls",
  "ccm.agent.label.waitAgentWeight": "Insira um peso do agente.",
  "ccm.agent.contact.info": "Informações do documento",
  "ccm.calledroute.message.selectcalledroute": "Selecione a configuração da parte chamada.",
  "ccm.agent.login.LOGIN": "Iniciar sessão",
  "ccm.custdata.msg.operationSuccess": "Operação bem-sucedida.",
  "ccm.agent.contact.recordInfo": "Detalhes do arquivo de gravação",
  "ccm.agent.operation.CANCELLISTEN": "Cancelar escuta",
  "ccm.agent.mobileagent.success": "Sucesso",
  "ccm.agent.contact.SFTP_INCORRECT_FILE_CODE": "As informações do servidor sftp estão incorretas",
  "ccm.agent.pageurls.whitelisturl": ". Entre em contato com o administrador do sistema para adicionar a URL à lista de confiança.",
  "ccm.agent.ten.cobrowseMaxNumber": "Máximo de Conexões de Colaboração na Web",
  "ccm.idauth.title.ivr": "Processo de Verificação",
  "ccm.speciallist.msg.selectslreminder": "Selecione um lembrete de lista especial.",
  "ccm.agent.contact.email": "Email",
  "ccm.agent.contact.Email": "Email",
  "ccm.pageurls.select.webApi": "API da Web",
  "ccm.callreason.label.name": "Nome",
  "ccm.agent.calledroute.createcalledroute": "Novo",
  "ccm.taskrecord.label.executing": "Executando",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.yes": "Sim",
  "ccm.skill.label.maxwaittime": "Duração máxima do enfileiramento",
  "ccm.recognition.label.appSecret.download.SK.not.exist": "As informações SK não existem.",
  "ccm.agent.label.skillpara": "Configuração do parâmetro de habilidade",
  "ccm.agent.contact.subtype.none": "nenhum",
  "ccm.agent.leavemessage.lengthcodewarn": "Uma mensagem pode conter no máximo 299 caracteres.",
  "ccm.agent.mobileagent.confirm": "Confirmar",
  "ccm.recognition.label.save": "Salvar",
  "ccm.agent.updateSoftPhone.unErrorInfo": "Exceção do Sistema.",
  "ccm.basicdata.msg.error": "Erro",
  "ccm.custdata.operlog.passValidateFailed": "Deve conter letras, dígitos e caracteres especiais _ {'@'} {'%'}",
  "ccm.agent.message.createSkillConfSuccess": "As informações da fila de habilidades são criadas com sucesso.",
  "ccm.dual.call.record.statistics": "Estatísticas",
  "ccm.agent.ccagent.qcmodify.fail": "Seleccione un tipo de agente de audio.",
  "ccm.agent.contact.norecording": "Sem gravação.",
  "ccm.speciallist.msg.delete": "Eliminar",
  "ccm.satisfactionconfig.message.strategychoosed": "Políticas Selecionadas",
  "ccm.pageinterface.selectAttributeTitle": "Selecionar atributo do objeto",
  "ccm.agent.pageurls.movedown": "Baixar",
  "ccm.agent.label.maxWaitTimeSpan": "Valor [1–60000]",
  "ccm.custdata.msg.dictionaryInputFormat": "Formato: Chave 1: Valor 1, Chave 2: Valor 2",
  "ccm.speciallist.msg.empty": "A mensagem de lembrete não pode ser null.",
  "ccm.contactitemconfig.message.confOnlyDataItemName": "A operação falhou porque o nome do item de dados já existe.",
  "ccm.agent.pageurls.confnaming": ". Certifique-se de que o nome não existe no sistema.",
  "ccm.agent.login.FORCELOGIN": "Forçar login",
  "ccm.transtask.label.pause": "Pausa",
  "ccm.agent.pageurlparam.urlParamName": "Parâmetro de URL de pop-up de tela",
  "ccm.satisfactionlevel.title.templateupdate": "Atualizar por modelo",
  "ccm.agent.accesscode.n400number": "Número do serviço",
  "ccm.custdata.button.withdraw": "Retirar",
  "ccm.ucconfig.message.success": "Sucesso.",
  "ccm.satisfactionconfig.satisfaction.switch": "Comutador",
  "ccm.agent.label.off": "Fechar",
  "ccm.calledconfig.message.casetype": "Classe Contábil",
  "ccm.satisfactionconfig.message.configstrategydata": "Configurar Dados da Política",
  "ccm.speciallist.label.blocklist": "Lista de bloqueio",
  "ccm.agent.contact.customername": "Nome do Cliente",
  "CCM.AGENT.CONTACT.ORG": "Organização do agente",
  "ccm.agent.contact.agent.recall": "O agente retirou a mensagem",
  "ccm.agent.calledroute.placeholder.ivr": "Selecione um IVR.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputparse": "A data inicial ou final está em um formato incorreto.",
  "ccm.agent.operation.ID": "Operação No.",
  "ccm.agent.operation.HELP": "Consulta",
  "ccm.speciallist.button.cancel": "Cancelar",
  "ccm.certificate.management.certificate.crlfile.crlfileinvalid": "Formato de arquivo CRL incorreto.",
  "ccm.agent.leavemessage.status.inProcess": "Processamento",
  "ccm.agent.button.ok": "OK",
  "ccmanagement.satisfaction.strategy.success": "Success",
  "ccm.custdata.button.release": "Liberação",
  "ccm.agent.message.samePageNameFail": "Nome da página duplicada.",
  "ccm.agent.ten.daylightSavingTime": "Horário de Verão",
  "ccm.satisfactionlevel.message.savesuccess": "O nível de satisfação e o modelo de pesquisa de satisfação são salvos com êxito.",
  "ccm.custdata.option.importFail": "Falha",
  "ccm.satisfactionconfig.message.staistip1": "Depois que esse interruptor for ativado, o cliente será transferido para a pesquisa de satisfação após o agente desligar.",
  "ccmanagement.tenantspace.option.weekday_wed": "Quarta",
  "ccm.agent.mobileagent.fail": "Falha",
  "ccm.label.contactexport.parainnull": "Selecione os dados a serem exportados.",
  "ccm.agent.restReason.operation": "Operação",
  "ccm.agent.videoDownload.noPermission": "O agente não tem permissão para fazer o download.",
  "ccm.custdata.option.isEncrypt.no": "Não",
  "ccm.satisfactionconfig.message.success": "Sucesso",
  "ccm.speciallist.operlog.downloadtemplate": "Baixar modelo",
  "ccm.satisfactionconfig.message.strategygroupmemchoose": "Selecionar políticas.",
  "ccm.custdata.msg.indexColumnValidateFailed": "Um campo usado como índice é obrigatório e não pode ser criptografado.",
  "ccm.custdata.operlog.viewExportTask": "Exibição de tarefa de exportação",
  "ccm.satisfactionconfig.message.surveyconfig": "Configuração da pesquisa",
  "ccm.agent.message.modifymobileagentfail": "Falha ao modificar informações sobre o número de softphone do agente móvel.",
  "ccm.ivr.label.processfilename": "Nome do arquivo",
  "ccm.custdata.msg.tableBeUsed": "A tabela personalizada é referenciada por um objeto de página de configuração. Certifique-se de que a tabela não seja referenciada antes de executar esta operação.",
  "ccm.agent.contact.agenthungup": "Agente",
  "cccm.agent.sysparamconfig.verifyTemplateIdLength": "O ID do modelo de mensagem interna deve ser um número inteiro positivo.",
  "ccm.agent.message.typenosame": "Os tipos de fila de habilidades devem ser os mesmos.",
  "ccm.agent.contact.contactinfo": "Informações de contato",
  "ccm.agent.leavemessage.paramisempty": "Insira um parâmetro.",
  "ccm.agent.message.queryctiagentmessage": "Falha ao localizar informações do agente no CTI.",
  "ccm.agent.calledroute.delsuccess": "Excluído com sucesso",
  "ccm.satisfactionconfig.message.webfeedbacktype": "Web",
  "ccm.agent.operation.SAYFREE": "disponível",
  "ccm.callout.message.choosecaller": "Escolha um chamador de chamada",
  "ccm.contactitemconfig.message.init.basicdata.success": "Os dados básicos são inicializados com sucesso.",
  "ccm.agent.ccagent.modify.fail": "O reconhecimento inteligente e a grava??o de duas faixas n?o podem ser ativados ao mesmo tempo.",
  "ccm.agent.label.mediaagent": "Agente multimídia",
  "ccm.anonymous.message.fail.cti": "Falha ao consultar o fluxo de chamadas de saída anônima em CTI",
  "ccm.custdata.label.relaBaseColumn": "Campo na tabela subjacente",
  "ccm.agent.contact.invalid": "invalidação",
  "casemanagement.user.title.select": "Selecionar",
  "ccm.custdata.msg.notAllowOperation": "Esta operação não é permitida. Atualize a página e tente novamente.",
  "ccm.basicdata.error.suffix": "{0} lógica de diálogo",
  "ccm.agent.label.paramsConfig": "Configuração de parâmetros do sistema",
  "ccm.agent.operation.noMoreThan90Days": "O intervalo de tempo não pode exceder 90 dias.",
  "ccm.agent.label.answerType": "Tipo de resposta",
  "ccm.agent.contact.workno": "ID do funcionário",
  "ccm.agent.verify.fail": "Falha na verificação.",
  "ccm.note.config": "Configuração de tom de retorno de toque",
  "ccm.note.select": "Selecione um anel de volta tom",
  "ccm.agent.restReason.restCauseTime.limit": "A duração de repouso deve ser superior a 0 segundos e inferior a 24 horas.",
  "ccm.skillcreate.maxnumber.failed": "O número de filas de habilidades de tenant não pode exceder o limite superior: ",
  "ccm.taskrecord.label.executeStatus": "Status da tarefa",
  "ccm.pageinterface.interfaceName": "Nome da interface",
  "ccm.verifyidentity.fail.VERIFYTIMERANGEERROR": "Intervalo de tempo incorreto para consulta",
  "ccm.page.label.status": "Status da página",
  "ccm.agent.operation.UNMUTE": "Ativar microfone",
  "ccm.sysparam.error.save": "erro",
  "ccm.ivrflow.placeholder.ivrflowdesc": "Digite uma descrição de fluxo ivr.",
  "ccm.agent.message.redisFailed": "Falha ao obter o bloqueio do Redis",
  "ccm.calledconfig.message.deletemobileagentfail": "Falha ao excluir a associação da configuração do agente móvel",
  "ccm.recognition.label.clicktocallaccesscode": "Click-to-call Código de acesso",
  "ccm.whatsapp.select.whatsApp.message.template": "Selecione uma mensagem de modelo de WhatsApp.",
  "ccmanagement.tenantspace.option.weekday_sat": "Sábado",
  "ccm.sysparam.error.validate.notmatchrulenumber": "Por favor, digite um número",
  "ccmanagement.satisfaction.strategy.save": "Save",
  "ccm.agent.pageurls.errorinfo": "Falha na criação.",
  "ccm.agent.operation.SWITCH": "Alternar",
  "ccm.transserver.delete.time.fail": "Exclua a tarefa dez minutos depois que ela for descartada.",
  "ccm.label.contactexport.selectrange": "Selecionar intervalo",
  "ccm.transserver.label.css": "Servidor CSS",
  "ccm.agent.label.serviceAccountId": "ID de conta do Service Cloud associado",
  "ccm.agent.message.deleteAgentSkillFail": "Falha ao excluir a associação da fila de habilidades do agente.",
  "ccmanagement.satisfaction.strategy.hourformatvalidate": "A hora está em um formato incorreto.",
  "ccm.agent.message.isornoairecognition": "Status Inteligente",
  "ccm.agent.ten.phonenumber": "Telefone",
  "ccm.page.select.tileLayout": "Layout da grade",
  "ccm.agent.label.sensitiveWordNumber": "linguagem proibida",
  "ccm.agent.sysparamconfig.worknorangewarn": "A ID do agente deve variar de 101 a 50000.",
  "ccm.satisfactionconfig.message.strategygroupid": "ID do Grupo de Políticas",
  "ccm.satisfactionconfig.ivr.voice.satisfaction.survey": "Pesquisa de Satisfação por Voz IVR",
  "ccm.agent.ten.recordtime": "Período de retenção de gravação",
  "ccm.operlog.contactexport.download": "Baixar registros de contato",
  "ccm.agent.operation.FORCEDROPCALL": "Libertação forçada",
  "ccm.agent.agentcontact.nosearch": "A consulta não está disponível quando o agente não está em uma sessão com um usuário.",
  "ccm.agent.recall.agent.calltype.invalid": "O tipo de chamada atual não suporta a operação de retorno de chamada.",
  "ccm.agent.ten.vdnid": "ID VDN",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkLimit": "Não são permitidos mais de cinco lugares.",
  "ccm.contactitemconfig.message.itemValidateall": "Insira sublinhado (_), sinal de dólar ($), letras ou dígitos",
  "ccm.note.config.policy": "Tone de volta do anel do agente",
  "ccmanagement.tenantspace.option.weeklist_tid": "Terceiro",
  "ccm.speciallist.msg.disable.fail": "Falha na desativação",
  "ccm.agent.label.ordinaryAgent": "Agente comum",
  "ccm.agent.label.paramValue": "Valor",
  "ccm.transserverparam.label.cssClusterPass": "Palavra-passe",
  "ccm.satisfactionlevel.message.resetfail": "Falha ao reinicializar o nível de satisfação.",
  "ccm.agent.label.agentWeight": "Peso do agente",
  "ccm.agent.calledroute.recordexist": "O código de acesso está vinculado ao código de extensão. Insira um novo código de extensão.",
  "ccm.agent.contact.authHandle": "Entre em contato com o administrador.",
  "ccm.custdata.button.return": "Retorno",
  "ccm.agent.label.busyRate": "Utilização da Fila (%)",
  "ccm.urlconfig.message.talkurlnotinwhite": "Operação falhou. Entre em contato com o administrador do sistema para adicionar o URL de retorno de chamada à lista confiável de retorno de chamada.",
  "ccm.agent.calledroute.edit": "Editar",
  "ccm.satisfactionconfig.message.prioritytip": "*Um valor menor indica uma prioridade mais alta. A política com uma prioridade mais alta é preferencialmente combinada para executar a pesquisa.",
  "casemanagement.user.button.cancel": "Cancelar",
  "ccm.custdata.option.isEncrypt.yes": "Sim",
  "ccm.custdata.label.failNum": "Número de registros falhados",
  "ccm.custdata.label.dualCall.sucNum": "Número de Sucessos",
  "iolp.configur.service.label.none.desc": "Nenhuma configuração de categoria de serviço está disponível. Crie uma categoria de serviço primeiro.",
  "ccm.custdata.label.dualCall.failNum": "Número de falhas",
  "ccm.speciallist.msg.deleteyerror": "Falha ao excluir a lista especial",
  "ccm.contact.customer.config.italic": "Itálico",
  "ccm.agent.calledroute.delete.skill.use": "A configuração da parte chamada é referenciada por uma fila de habilidades e não pode ser excluída.",
  "ccm.transtask.label.sunday": "Domingo",
  "ccm.agent.label.waitVoiceDefault": "Tom de espera padrão",
  "ccmanagement.satisfaction.strategy.calltime.interval": "Intervalo de duração da chamada",
  "ccm.urlconfig.message.relurlnotinwhite": "Operação falhou. Entre em contato com o administrador do sistema para adicionar o URL de retorno de chamada à lista confiável de retorno de chamada.",
  "ccm.speciallevel.speciallist.restrictHours": "Duração Restrita (Hora)",
  "ccm.agent.contact.multimedia.twitter": "Multimídia-X (Twitter)",
  "ccm.satisfactionconfig.select.ivr.process": "Selecione o fluxo de pesquisa de satisfação IVR",
  "ccm.urlconfig.label.oauth.sk": "SK",
  "ccm.agent.message.sysparamservicerr": "Erro de serviço interno. Por favor, verifique o registro.",
  "ccm.agent.message.download.err": "Falha no download. Entre em contato com os engenheiros de O&M do sistema.",
  "ccm.custdata.option.isIndex.no": "Não",
  "ccm.agent.accesscode.description.containspatial": "A descrição do código de acesso não pode conter os seguintes caracteres especiais: % _ ^ & * < > $ /.",
  "ccm.custdata.msg.confirmAbandon": "Tem certeza de que deseja descartar esta tabela?",
  "ccm.agent.calledroute.delete": "Excluir",
  "ccm.agent.restReason.restCauseTime": "Duração de repouso",
  "ccmanagement.satisfactionconfig.acceptdata.timeVerification": "Selecione uma data posterior à hora atual e anterior a 2038-01-19.",
  "ccm.transserverparam.label.sk": "SK",
  "ccm.vcallcenter.config.popup.current.user.locked.error": "O usuário atual está bloqueado e será desbloqueado 10 minutos mais tarde.",
  "ccm.certificate.management.certificatefile": "Arquivo do certificado",
  "ccm.sysparam.config.select.null": "Selecione um parâmetro.",
  "ccm.note.config.configsuccess": "O tom de prompt foi configurado com sucesso.",
  "ccm.certificate.management.warn.selectcerttype": "Selecione o tipo de certificado.",
  "ccm.note.config.agent.page.ring.audition": "Audição",
  "ccm.transtask.label.reports": "Relatório de Dump",
  "ccm.agent.calledroute.nmsconnect.fail": "Falha ao conectar ao NMS.",
  "ccm.chatbot.pop.title": "Selecionar bot",
  "ccm.recognition.label.calledroutesuccess": "Configurado com sucesso A rota chamada",
  "ccm.agent.contact.web": "Web",
  "ccm.agent.message.omsAgentTypeBatchCheck": "A função ou tipo de agente selecionado não corresponde quando o agente (ID do funcionário [{0}]) está habilitado on-line.",
  "ccm.speciallist.label.msgContent": "Mensagem de lembrete",
  "ccm.agent.message.willExportAgent": "\u0020mensagens. Tem certeza de que deseja exportar?",
  "ccm.calledconfig.message.deletemobileagent": "Excluir agente móvel",
  "ccm.agent.label.deviceUsageThreshold": "Limite de utilização do dispositivo (%)",
  "ccm.agent.button.restSoftPhonePwd.tips": "Redefinir a senha do softphone afetará o softphone em uso, exigindo que o agente faça login novamente. Tem certeza de que deseja redefinir a senha do softphone?",
  "ccm.transtask.label.tuesday": "terça-feira",
  "ccm.custdata.msg.importDataFail": "Falha ao importar dados.",
  "ccm.agent.operation.MUTE": "silêncio",
  "ccm.agent.mobileagent.label.choosehint": "Escolha agentes antes de excluí-los!",
  "ccm.skill.label.maxwaitnum": "Máx. Chamadas na Fila",
  "ccm.satisfaçãoconfig.message.smscloud": "Huawei Cloud SMS",
  "ccm.recognition.label.closemobileagentsuccess": "Desativado com sucesso o agente móvel!",
  "ccm.anonymous.message.fail.delete": "Falha ao excluir a rota chamada. Verifique a configuração CTI",
  "ccm.sysparam.config.save": "Salvar",
  "ccm.transserver.label.css.serveraddr": "Endereço CSS",
  "ccm.verifyidentity.fail.unknown": "A consulta falhou",
  "ccm.agent.restrecord.rest.duration": "Duração de repouso",
  "ccm.agent.operation.vdnNotEnabled": "O VDN não está ativado.",
  "ccm.sysparam.config.opterate": "Operação",
  "ccm.callout.title.fail": "Falha",
  "ccm.satisfactionconfig.message.surveymethod": "Método de pesquisa",
  "ccm.recognition.queryAK.error": "Ocorreu um erro ao consultar a chave do aplicativo.",
  "ccmanagement.tenantspace.option.weekday_fri": "Sexta",
  "ccm.agent.label.theAgent": "Agente:",
  "ccm.operlog.dualcallexport.download": "Baixar registros de chamadas bidirecionais",
  "ccm.callout.message.nocallers": "Nenhum chamador de chamada foi escolhido",
  "ccm.agent.callacceptno.set": "Definir",
  "ccm.agent.leavemessage.status.solved": "Processado",
  "ccm.recognition.queryAK.not.exist": "O espaço atual do tenant não tem uma chave de aplicativo.",
  "ccm.agent.login.LOGOUT": "Fazer sessão",
  "ccm.contactitemconfig.message.confOnlyDatasetName": "A operação falhou porque o nome do conjunto de dados já existe.",
  "ccm.transserver.delete.success": "O servidor de dump foi excluído com sucesso.",
  "ccm.agent.addbother.fail": "Falha na adição.",
  "ccm.verifyidentity.workNo": "Número do agente",
  "ccm.agent.contact.resourceloading": "Carregando recursos...",
  "ccm.agent.message.searchTimeRemaining": "Expiração da senha",
  "ccm.transtask.label.cantDelete": "O servidor não pode ser excluído porque ele já está referenciado por uma tarefa de dump.",
  "ccm.agent.contact.reset": "Redefinir",
  "ccm.agent.operation.failCode": "Código do motivo da falha",
  "ccm.custdata.option.isIndex.yes": "Sim",
  "ccm.agent.label.versatileagent": "Agente versátil",
  "ccm.speciallevel.tip.start": "Tem certeza de que deseja ativar?",
  "ccm.ucconfig.message.urlTitle": "Redirecionar URI para configurar a plataforma Microsoft para aceitar respostas de autenticação:",
  "ccm.calledconfig.table.addnumbers": "Adicionar números",
  "ccm.agent.message.inSpecialStrFail": "O nome da página não pode conter caracteres especiais.",
  "ccm.agent.ten.maxVideoAgents": "Quantidade de Agentes de vídeo",
  "ccm.agent.message.ctipasswdless": "O comprimento da senha não pode ser inferior a 8.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnagetive": "A duração mínima ou máxima da chamada não pode ser inferior a 0.",
  "ccm.chatbot.invalid.bot": "Este bot é inválido",
  "ccm.urlconfig.label.relurl.voicemsg.alerttip": "Se o URL de retorno de chamada não for passado ao chamar a interface de criação de notificação de voz, o URL de retorno de chamada será chamado quando a notificação de voz terminar, caso contrário, o URL de entrada será chamado de retorno de chamada",
  "ccm.recognition.label.appSecret.download.SK.only.once.error": "Se você esquecer a chave, reinicie a chave e baixe-a novamente.",
  "ccm.sysparam.config.mod": "editar",
  "ccm.speciallist.msg.size": "O tamanho do arquivo a ser importado excede 10 MB",
  "ccm.agent.contact.signaturedate": "Data da assinatura",
  "ccm.recognition.label.closeIvrAgentSuccess": "O assistente de agente inteligente foi desativado com sucesso.",
  "ccm.transserver.label.css.edit": "Configuração do servidor de pesquisa na nuvem",
  "ccm.agent.speciallist.addList": "Adicionar histórico de assédio",
  "ccm.speciallist.label.expirationDate": "Tempo de expiração",
  "ccm.agent.label.queuingCallsMaxNum": "Número máximo de chamadas em fila",
  "ccm.transtask.label.datapath": "Caminho do arquivo de dump",
  "ccm.transtask.label.reportedir": "Relatório de Dump",
  "ccm.agent.label.timeOut": "Tempo limite de habilidade",
  "ccm.sysparam.config.item.catalog": "Tipo de Parâmetro",
  "ccm.agent.leavemessage.contactNumber": "Número de contato",
  "ccm.agent.resetskillrecord.adjustedagentworkno": "ID do agente ajustado",
  "ccm.agent.contact.twitter": "X (Twitter)",
  "ccm.agent.operation.searchworkno": "Insira a ID do funcionário",
  "ccm.agent.ten.sipInfo": "Endereço SIP e porta",
  "ccm.certificate.management.certificatefile.parseerror": "Falha ao analisar o certificado. Verifique se o formato do certificado ou a senha estão corretos.",
  "ccm.agent.login.logIP": "Endereço IP de login",
  "ccm.transtask.label.task.otheruniquene": "Apenas um arquivo de voz, dados de inspeção inteligente ou tarefa de tipo de dados CDR podem ser criados para o mesmo tipo de dados de despejo.",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility": "Registro de contato visível",
  "ccm.botherlist.label.approveComment.agree": "Concordo",
  "ccmanagement.satisfaction.strategy.acceptdate.dateEnd": "Data final",
  "ccm.recognition.label.updatefail": "Falha de atualização do tenant.",
  "ccm.custdata.msg.confirmRelease": "Tem certeza de que deseja publicar esta tabela?",
  "ccm.agent.contact.audioclicktodial": "Clique de voz para discar",
  "ccm.agent.ten.maxVideoCalls": "Máx. chamadas de vídeo simultâneas",
  "ccm.callout.message.editsuccess": "Sucesso ao editar o chamador de chamada de detalhe",
  "ccm.agent.contact.customizes": "Personalização",
  "ccmanagement.satisfaction.strategy.calltime.minvalue": "Duração (s) mínima da chamada",
  "ccm.satisfactionconfig.message.strategygroup": "Grupo de Políticas de Pesquisa de Satisfação",
  "ccmanagement.satisfaction.strategy.delete": "Delete",
  "ccm.note.config.failed": "Falha ao configurar o tom de prompt.",
  "ccm.taskrecord.label.executeEndTime": "Hora final",
  "ccm.agent.contact.status": "Status do Documento",
  "ccm.speciallevel.speciallist.unuse": "Indisponível",
  "ccm.custdata.msg.fileNameValidateFail": "O nome do arquivo pode conter somente caracteres chineses, letras minúsculas, dígitos ou sublinhados (_)",
  "ccm.callreason.label.callreason": "Motivo da chamada",
  "ccm.callreason.title.callreason": "Motivo da Chamada Associada",
  "ccm.callreason.label.parentcallreason": "Motivo da chamada do pai",
  "ccm.callReason.title.createCallReason": "Criar motivo da chamada",
  "ccm.callReason.title.editCallReason": "Editar motivo da chamada",
  "ccm.agent.label.authtype.unified": "Certificação unificada",
  "ccm.agent.calledroute.dnid": "ID de configuração",
  "ccm.agent.label.answerType4": "ID do agente definido pelo usuário",
  "ccm.agent.restReason.partDeleteFailed": "Falha na exclusão parcial.",
  "ccm.agent.contact.videoclicktodial": "Clique em vídeo para discar",
  "ccm.agent.contact.multimedia.whatsapp": "Multimídia-Whatsapp",
  "ccm.satisfactionconfig.message.createfailed": "Falha na nova configuração da pesquisa de satisfação",
  "ccm.agent.label.waitVoiceConfig": "Configuração de enfileiramento e espera",
  "ccm.agent.operation.opresult": "Resultado",
  "ccm.agent.mobileagent.syserror": "Falha ao ativar o reconhecimento inteligente. Entre em contato com o administrador do sistema para verificar a configuração do ambiente.",
  "ccm.agent.pageurlparam.paramIsConfiged": "O parâmetro de URL pop-up screen ou o parâmetro de dados do sistema associado já existe.",
  "ccm.agent.label.answerType2": "Relatório de nenhuma voz",
  "ccm.satisfactionconfig.message.flowaccesscode": "Código de acesso do processo",
  "ccm.agent.label.answerType0": "Relatório da ID do funcionário",
  "ccm.agent.label.skills": "Fila de habilidades",
  "ccm.agent.label.serviceCode": "Tipo de serviço",
  "ccm.agent.label.duration": "Duração",
  "ccm.agent.label.queueTime": "Duração da fila",
  "ccm.agent.label.availabilityTime": "Duração disponível",
  "ccm.agent.label.mutetime": "Duração do silêncio",
  "ccm.agent.label.calltime": "Nenhuma duração de chamada silenciada",
  "ccm.agent.label.ringingTime": "Duração do toque",
  "ccm.agent.label.serviceLevel": "Temps d'attente ( %)",
  "ccm.agent.label.transferInformation": "Transferir ou não",
  "ccm.agent.label.transfer": "Número de transferências",
  "ccm.agent.label.ivrUsage": "Número de transferências ivr",
  "ccm.agent.label.originId": "ID de trabalho do transferidor",
  "ccm.agent.label.queueName": "Encaminhando a Fila de Habilidades",
  "ccm.agent.label.wrapUpCode": "Estado Integral",
  "ccm.agent.label.uuiInfo": "Informação uui",
  "ccm.agent.label.overallState": "Estado geral",
  "ccm.note.config.agent.page.ring.setUp": "Configuração de lembrete de mensagem de página",
  "ccm.agent.ten.maxAgents": "Agentes de voz",
  "ccm.agent.operation.FORCEBUSY": "Forçar ocupado",
  "ccm.note.config.success": "Sucesso",
  "ccm.agent.message.audioagentzero": "A contagem de agentes de áudio é 0. A fila de habilidades de clique para discagem de áudio não pode ser configurada.",
  "ccm.agent.contact.callserialno": "No de série da chamada",
  "ccm.agent.ten.ivrchannels": "Quantidade de Canais IVR de áudio",
  "ccm.anonymous.message.fail.feature": "O recurso de autenticidade de chamadas de saída anônima é anormal.",
  "ccm.label.contactexport.selectdatarange": "Escopo de dados",
  "ccm.agent.sysparamconfig.paramwarn": "Selecione um valor de parâmetro válido.",
  "ccm.agent.pageurls.pagename": "Nome da Página",
  "ccm.policy.label.updatesucess": "Informações da política atualizadas com sucesso.",
  "ccm.agent.label.notreply": "Não consigo responder",
  "ccm.callreasonconfig.message.deletecallreason.used": "Falha ao excluir o motivo da chamada. O motivo da chamada está em uso.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.startafterend": "A data de início deve ser anterior à data de término.",
  "ccm.custdata.button.importData": "Importar dados",
  "ccm.callreason.input.specialStrValidateFailed": "O nome do motivo da chamada não pode conter caracteres especiais.",
  "ccm.chatbot.pop.table.botname": "Nome do robô",
  "ccm.agent.calledroute.placeholder.extcode": "Insira um código de extensão.",
  "ccm.asr.create.case.info": "Durante ou após o término da chamada, clique em Criar caso.",
  "ccm.agent.speciallist.endtime": "Tempo de revogação",
  "ccm.agent.leavemessage.status.allocationFailed": "Falha na alocação",
  "ccm.worknoaftervoice.config.choosenote": "Selecione Voz após identificação do funcionário",
  "ccm.custdata.option.all": "Todos",
  "ccm.agent.pageurls.create": "Novo",
  "ccm.agent.label.modifymobileagent": "Editar o número do softphone",
  "ccm.transserver.label.cssaddr": "Endereço do serviço CSS",
  "ccm.agent.calledroute.extcodewarn": "O código de extensão pode conter apenas dígitos.",
  "ccm.recognition.label.api.fabric.id": "ID de fábrica da API",
  "ccm.agent.label.deletecallreason": "O motivo atual contém um motivo filho e não pode ser excluído.",
  "ccm.agent.login.FORCELOGOUT": "Forçar saída da sessão",
  "ccm.agent.label.signedinAgentsPercentage": "Por Proporção de Agentes de Check-in",
  "ccm.recognition.label.ordersucess": "Assinatura bem-sucedida.",
  "ccm.sysparam.config.select.count": "Um máximo de 10 parâmetros podem ser selecionados.",
  "ccmanagement.satisfaction.strategy.hourvalidate": "A hora não pode ser maior que 24.",
  "ccm.recognition.resetSK.success": "SK reinicialização com sucesso.",
  "ccm.custdata.option.maskStyle.maskAll": "Mascarar todos os dígitos",
  "ccm.transserver.delete.confirm": "Tem certeza de que deseja excluí-lo?",
  "ccm.pageinterface.selectedAttributeNum": "Atributos de objeto selecionados",
  "ccm.agent.pageurls.typenosupported": "A multimídia não suporta a configuração interna da página.",
  "ccm.calledconfig.message.nocalled": "Escolha chamada",
  "ccm.agent.message.idIsEmpty": "A ID da fila de habilidades está vazia.",
  "ccm.agent.pageurlparam.paramCountLimit": "Podem ser  configurados no máximo {limit} parâmetros de URL pop-up.",
  "ccm.agent.message.webRTCVerification": "Este parâmetro não pode ser configurado quando o recurso WebRTC está ativado.",
  "ccm.agent.contact.callserno": "Chamada S / N",
  "ccm.agent.contact.minCallDuration": "Início da duração da chamada",
  "ccm.agent.contact.maxCallDuration": "Fim da duração da chamada",
  "ccm.agent.contact.lessThan": "menor que",
  "ccm.agent.contact.callDurationError":"O tempo de início da duração da chamada é menor que o tempo de término da duração da chamada",
  "ccm.agent.contact.talkDurationError":"A duração da chamada não pode exceder 31 dias.",
  "ccm.agent.contact.fuzzyCallNoError":"O intervalo de números de contato (pesquisa difusa) não pode exceder 31 dias.",
  "ccm.agent.contact.fuzzyCallNo": "Número de contacto (consulta difusa)",
  "ccm.agent.leavemessage.status": "Status",
  "ccm.speciallist.tip.endTime.min": "A hora de expiração deve ser maior que a hora atual",
  "ccm.agent.message.createfalhado": "Falha na criação do agente",
  "ccm.agent.sysparamconfig.resttimewarn": "O valor deve ser um inteiro.",
  "ccm.speciallist.msg.levelName": "Nível",
  "ccm.callreason.label.selectedcallreason": "Motivo da chamada selecionada",
  "ccm.custdata.button.moveUp": "Mover para cima",
  "ccm.agent.label.querymobileagent": "Consultar o número do softphone",
  "ccm.agent.label.addChildReason": "As configurações de motivo da chamada não podem exceder 6 níveis.",
  "ccm.ucconfig.message.updatefailed": "Falha ao salvar a configuração de integração de UC",
  "ccm.pageinterface.custTableName": "Nome da tabela subjacente",
  "ccmanagement.satisfaction.strategy.fail.accepttime.overlimit": "Um máximo de 100 políticas de tempo de tratamento podem ser salvas.",
  "ccm.agent.pageurls.updatecall": "Editar tela pop-up de chamada recebida",
  "ccmanagement.satisfaction.strategy.fail.unknown": "Falha no salvamento.",
  "ccm.agent.ten.DSTStartTime": "Hora início Horário de Verão",
  "ccm.agent.ten.contactInfor": "Método de contato",
  "ccm.verifyidentity.fail.LIMITOVERLIMIT": "O parâmetro de paginação da consulta excede o limite",
  "ccm.agent.contact.otherexception": "Outra exceção",
  "ccm.speciallist.msg.operationdoing": "Outros revisores estão aprovando",
  "ccm.contactitemconfig.label.data.item.name": "Nome do item de dados",
  "ccm.certificate.management.certificatefile.certcodeexist": "O código do certificado já existe.",
  "ccm.certificate.management.search.status": "Selecione o status do certificado",
  "ccm.certificate.management.status": "Status do certificado",
  "ccm.certificate.management.status.value.inuse": "Em uso",
  "ccm.certificate.management.status.value.discard": "Abandonado",
  "ccm.certificate.management.status.oper.reuse": "Reutilização",
  "ccm.certificate.management.status.oper.discard": "Abandonado",
  "ccm.certificate.management.status.confirm.reuse": "Confirmação de Reutilização",
  "ccm.certificate.management.status.confirm.discard": "Confirmação de Abandono",
  "ccm.certificate.management.status.confirmReuse": "Tem certeza de que deseja continuar usando este certificado?",
  "ccm.certificate.management.status.confirmDiscard": "Confirme se não há mais cenários de negócios usando este certificado e descartando-o?",
  "ccm.certificate.management.status.update.success": "Alterar o status do certificado com sucesso.",
  "ccm.certificate.management.status.update.failed": "Falha ao modificar o status do certificado",
  "ccm.certificate.management.certificatefile.status.error": "O certificado está obsoleto e não pode ser modificado",
  "ccm.callout.message.resetsuccess": "Foi bem-sucedida ao reinicializar o parâmetro.",
  "ccm.agent.message.deleteSkillConfFail": "Falha ao excluir as informações da fila de habilidades.",
  "ccm.agent.message.deleteSkillConfFailID": "Falha ao excluir {0}.",
  "ccm.agent.message.modifySkillConfSuccess": "As informações da fila de habilidades são modificadas com sucesso.",
  "ccm.agent.calledroute.placeholder.skill": "Selecione uma fila de habilidades.",
  "ccmanagement.tenantspace.option.weekday_sun": "Domingo",
  "ccm.pageurls.select.independently": "Aberto independentemente",
  "ccm.custdata.label.failreaon": "Causa de falha",
  "ccm.agent.label.agentTel": "Número de telefone móvel/fixo do agente",
  "ccm.transtask.label.taskedit": "Tarefa de recurso dump",
  "ccm.custdata.msg.confirmReturn": "A classificação não está completa. Tem certeza de que deseja voltar à página para visualizar a tabela personalizada?",
  "ccm.querycustdata.msg.inputIndexData": "Insira por favor",
  "ccm.agent.calledroute.extlengthcodewarn": "O comprimento do código de extensão não pode ser maior que 12",
  "ccm.agent.contact.numhungup": "Transferir para o Número Externo",
  "ccm.agent.label.restQueueRule": "Política de enfileiramento de descanso",
  "ccm.agent.contact.multimedia.fiveG": "Multimídia-5G RCS",
  "ccm.transtask.label.chatrecordinf": "Configurando dados de bate-papo multimídia Dumping",
  "ccm.agent.leavemessage.allStatus": "Todos status de mensagem",
  "ccm.satisfactionlevel.message.nopara": "A descrição não pode estar vazia.",
  "ccm.speciallist.label.startDate": "Tempo de validade",
  "ccm.transserver.label.cssproxynotinwhite": "Operação falhou. Entre em contato com o administrador do sistema para adicionar o endereço do servidor proxy à lista confiável de proxy do servidor CSS.",
  "ccm.agent.restReason.pleaseRefresh": "Sincronize e tente novamente.",
  "ccm.whatsapp.whatsApp.channel": "canal WhatsApp",
  "ccm.callreason.label.input": "por favor, entre",
  "ccm.agent.ten.DSTEndDate": "Data término Horário de Verão",
  "ccm.transtask.label.saveexit": "Falha ao adicionar um servidor de dump porque o nome do servidor já existe.",
  "ccm.custdata.msg.typeColumnNumValidateFailed": "O número de campos excede o limite superior: Apenas um campo de índice, e um máximo de 30 campos não criptografados e 20 campos criptografados são permitidos.",
  "ccm.agent.contact.reasonCode": "Código do motivo",
  "ccm.satisfactionconfig.message.groupcreate": "Adicionar",
  "ccm.urlconfig.label.oauth.voicemsg.alerttip": "O URL de retorno de chamada é passado quando a interface de criação de notificação de voz é chamada. Se as informações de autenticação a seguir estiverem configuradas, as informações de autenticação a seguir serão usadas quando a notificação de voz terminar de chamar o URL de retorno de chamada. Caso contrário, o retorno de chamada não será autenticado.",
  "ccmanagement.satisfaction.strategy.accepttime.timeStart": "Hora de início",
  "ccm.sysparam.config.search": "Consulta",
  "ccm.certificate.management.certificatecrlfile.suffixerror": "O arquivo Lista de Revogação de Certificados deve ser no formato.crl.",
  "ccm.agent.ten.createDate": "Horário de criação",
  "ccm.custdata.button.abandon": "Descartar",
  "ccm.label.contactexport.parainvalid": "Falha ao verificar as condições de exportação.",
  "ccm.agent.restrecord.reststatus.resting": "Descanso",
  "ccm.note.config.agent.page.ring.choose": "Por favor selecione um toque",
  "ccm.agent.contact.playfail": "Falha na reprodução.",
  "ccm.transtask.label.start": "Iniciar",
  "ccm.agent.ten.allowedTTSAndASRCallNum": "Número de canais IVR inteligentes",
  "ccm.agent.contact.sameContactRecord": "Registro do mesmo contato de chamada",
  "ccm.agent.sysparamconfig.worknonumwarn": "O número de IDs de agente não pode exceder 10.",
  "ccm.agent.calledroute.nmsdata.error": "Falha ao sincronizar dados com o NMS. Os dados de configuração da parte chamada do NMS são inconsistentes com os dados locais do CCM.",
  "ccm.agent.operation.LISTEN": "Verificação de qualidade de escuta",
  "ccm.speciallevel.speciallist.remark": "Observação",
  "ccm.speciallist.msg.willdelete": "Tem certeza de que deseja excluir essas {limit} listas?",
  "ccm.common.msg.willdelete": "Tem certeza de que deseja excluir os {limit} registros selecionados?",
  "ccm.calledconfig.title.numbersadded": "Números adicionados",
  "ccm.ivr.label.processdesc": "Descrição do processo",
  "ccm.custdata.operlog.failed": "Falha",
  "ccm.pageurls.select.urlParameter": "Parâmetro GET REQUEST da URL",
  "ccm.agent.message.willClearAgents": "Tem certeza de que quer limpar os agentes selecionados?",
  "ccm.note.config.error": "Erro",
  "ccm.agent.operation.mediaswitch": "Seletor de áudio/vídeo",
  "ccm.sysparam.error.validate.notmatchruleregex": "O parâmetro de entrada não corresponde à expressão regular da regra de verificação",
  "ccm.contact.customer.config.fonts": "Fonte",
  "ccm.sysparam.config.agent.title": "Parâmetros do agente",
  "ccm.accesscode.placeholder.accessdesc": "Insira uma descrição do código de acesso.",
  "ccm.agent.ten.expiryDate": "Data de expiração",
  "ccm.agent.sysparamconfig.refreshAllSysParamFail": "Erro do sistema, Falha na atualização.",
  "ccm.transserverparam.label.ak": "AK",
  "ccm.agent.softPhoneStatusNode1": "Nó 1",
  "ccm.agent.softPhoneStatusNode2": "Nó 2",
  "ccm.speciallevel.speciallist.restrictMode": "Modo de restrição",
  "ccm.agent.contact.nohaveevent": "Esse evento não existe",
  "ccm.agent.label.authType": "Método de autenticação",
  "ccm.common.label.no": "Não",
  "ccm.speciallist.msg.modify": "Modificar",
  "ccm.satisfactionconfig.message.sms": "SMS",
  "ccm.taskrecord.message.reexecutetranstasksuccess": "Re-execução com sucesso.",
  "ccm.agent.leavemessage.processfail": "Falha ao processar a mensagem.",
  "ccm.agent.button.unregister": "Cancelar registro",
  "ccm.agent.contact.talkCallbackFlag": "Falar Bandeira",
  "ccm.agent.contact.begintime": "Hora de início",
  "ccm.agent.calledroute.createsuccess": "Adicionado com sucesso.",
  "ccm.agent.button.cancelconfig": "Cancelar configuração",
  "ccm.agent.pageurls.errorupdate": "Falha na atualização.",
  "ccm.custdata.msg.operationFailed": "Falha na operação.",
  "ccm.satisfactionconfig.message.smstemplate": "Modelo SMS",
  "ccm.agent.restReason.createReason": "Adicionar motivo de descanso",
  "ccm.urlconfig.message.modifyfail": "Falha ao modificar as urls",
  "ccm.agent.leavemessage.assignSuccess": "A mensagem foi alocada com sucesso",
  "ccm.agent.contact.businesshungup": "Transferir para agente",
  "ccm.agent.label.param.index": "Não",
  "ccm.callout.message.isconfreset": "Tem certeza de que deseja redefinir o número?",
  "ccm.verifyidentity.fail.VERIFYRESULTPARSEERROR": "O parâmetro do resultado da verificação está incorreto",
  "ccm.agent.calledroute.create.skill.none": "A fila de habilidades referenciada pela configuração da parte chamada não existe.",
  "ccm.agent.button.create": "Novo",
  "ccm.transtask.label.certTip.cssCert": "Selecione um certificado CSS.",
  "ccm.agent.label.keepVoiceSelect": "Selecione um tom de manutenção",
  "ccm.transtask.label.wednesday": "quarta-feira",
  "ccm.agent.message.pageLayout": "Layout da página",
  "ccm.agent.message.name.limit": "O nome é longo demais.",
  "ccm.agent.message.name.grouplimit": "O nome não pode exceder 64.",
  "ccm.certificate.management.certificatefile.certfile.sizeerror": "O tamanho do arquivo de certificado não pode exceder 20 KB.",
  "ccm.agent.message.agentCreateFail": "Falha ao criar agente no CTI.",
  "ccm.agent.ten.maxCalls": "Máx. chamadas de voz simultâneas",
  "ccm.agent.contact.whatsapp": "WhatsApp",
  "ccm.pageconfig.label.layoutType": "Tipo de layout",
  "ccm.agent.contact.agentPresent": "Número do agente",
  "ccm.calledconfig.message.duplicatecalled": "Chamada duplicada",
  "ccm.label.chatRecord.channeltypeinnull": "O tipo de canal não está selecionado.",
  "ccm.satisfactionconfig.satisfaction.process": "Fluxo de Pesquisa de Satisfação",
  "ccm.calledroute.tip.selectivrexception": "Falha ao consultar Fluxos de IVR em CTI.",
  "ccm.agent.softPhoneStatus.UnRegistered": "Não registrado",
  "ccm.agent.accesscode.description.notempty": "A descrição do Código de Acesso não pode estar vazia.",
  "ccm.agent.contact.sms": "SMS",
  "ccm.agent.contact.Instagram": "Instagram",
  "ccm.agent.contact.Telegram": "Telegram",
  "ccm.transtask.label.chatRecord": "Dados de bate-papo multimídia",
  "ccm.transtask.label.intelligentInspection": "Dados de inspeção AI",
  "ccm.satisfactionconfig.message.acceptdate": "Data de processamento",
  "ccm.agent.pageurlparam.relSysParamName": "Parâmetro de dados do sistema associado",
  "ccm.urlconfig.modify.authtype3": "Nenhum",
  "ccm.urlconfig.modify.authtype1": "Chave compartilhada",
  "ccm.agent.message.agentnummaxoperation": "Selecione até 100 agentes.",
  "ccm.agent.label.basicInfo": "Informações básicas",
  "ccm.speciallist.msg.datecompareerror": "O tempo de expiração deve ser maior que o tempo de efetivação.",
  "ccm.transtask.label.task.chatrecord.uniqueness": "Algumas das tarefas de tipo de canal selecionadas já existem.",
  "ccm.basicdata.msg.deletefail": "Falha ao excluir os dados básicos.",
  "ccm.satisfactionconfig.audio.and.video.remark": "Quando os switches de pesquisa de satisfação de voz e SMS da IVR estão ativados e o agente desliga primeiro, nenhuma pesquisa de satisfação de SMS será conduzida se uma pesquisa de satisfação de voz da IVR tiver sido conduzida",
  "ccm.sysparam.config.value": "Parâmetro Valor",
  "ccm.agent.contact.voiceVidio": "Áudio/vídeo",
  "ccm.custdata.option.maskStyle.maskDate": "Máscara de data",
  "ccm.recognition.label.appSecret.download": "Baixar chave secreta",
  "ccm.agent.operation.REST": "Pedido de descanso",
  "ccm.calledconfig.message.savemobileagentfail": "Falha ao salvar a associação da configuração do agente móvel",
  "ccm.certificate.management.certificatefile.uploadcertfile": "Carregar arquivo de certificado",
  "ccm.contact.customer.config.label": "Contacto Área de Informação do Cliente",
  "ccm.verifyidentity.verifyResult": "Verificar resultado",
  "ccm.urlconfig.message.modifysuccess": "Sucesso para modificar as urls",
  "ccm.callreason.label.mark": "Observações",
  "ccm.satisfactionconfig.message.channelused": "O grupo de políticas é referenciado por um canal e não pode ser excluído.",
  "ccm.transtask.label.datapath.tips": "Caminho do OBS",
  "ccm.custdata.msg.importDataSuccess": "Exportando dados... Exibir detalhes em Importar Resultado.",
  "ccm.agent.label.keepVoiceDefault": "Tone de manutenção padrão",
  "ccm.agent.label.selectSkill": "Selecionar fila de habilidades",
  "ccm.transserver.label.cssServerPort": "Porta de serviço CSS",
  "ccm.agent.label.keepVoiceConfig": "Tom de espera de chamada em HOLD",
  "ccm.transtask.label.agentOperationReport": "Relatório de Operação de Conexão do Agente",
  "ccm.agent.contact.createbother": "Criar Registro de Incomodação",
  "ccm.custdata.option.columnType.dictionary": "Dicionário",
  "ccmanagement.satisfaction.strategy.accepttime.minute": "minute",
  "ccm.agent.calledroute.search.devicedesc": "Insira uma descrição do dispositivo.",
  "ccm.agent.pageurls.isconfdelete": "Tem certeza de que deseja excluir?",
  "ccm.agent.contact.updatedate": "Data da Atualização",
  "ccm.agent.log.downloadVideoTerminal": "Baixar o terminal de vídeo OpenEye",
  "ccm.contact.detail.sendmessage": "Enviar",
  "ccm.agent.skill.nameInfo": "Nome da fila de habilidades:",
  "ccm.agent.Transfer.CallForwarding": "Transferir chamada",
  "ccm.satisfactionconfig.message.strategygroupmemconfig": "Configuração da Política",
  "ccm.pageurls.select.postParameter": "Parâmetro POST REQUEST da URL",
  "ccm.agent.message.modifySkillConfFail": "Falha ao modificar as informações da fila de habilidades.",
  "ccm.agent.calledroute.gateway.fail": "Falha ao sincronizar as informações da plataforma CTI. Verifique a configuração do gateway.",
  "ccm.agent.restrecord.rest.endTime": "Tempo de fim de repouso",
  "ccm.pageinterface.optionalAttribute": "Atributo opcional",
  "ccm.contact.customer.config.datasource.customData": "Dados locais",
  "ccmanagement.satisfaction.strategy.deleteselect": "Tem certeza de que deseja excluir as políticas selecionadas?",
  "ccm.agent.contact.callerfailed": "Falha ao chamador de saída",
  "ccm.agent.resetskillrecord.skillafteradjust": "Fila de habilidades após ajuste",
  "ccm.agent.message.selectSkills": "Selecione uma fila de habilidades.",
  "ccm.sysparam.config.resetAbb": "Reiniciar",
  "ccm.agent.label.airecog": "Reconhecimento inteligente",
  "ccm.certificate.management.certificatefile.suffixerror": "Somente arquivos em formato jks, cer, crt, pem, pfx, p12 podem ser carregados.",
  "ccm.agent.message.inputWeight": "Informe um Valor de Peso.",
  "ccm.agent.leavemessage.assignFailed": "Falha ao alocar a mensagem.",
  "ccm.agent.restReason.selectRestReasons.limit": "Podem ser excluídos no máximo 10 motivos de descanso  de uma vez.",
  "ccmanagement.satisfaction.strategy.deletefail": "Falha na exclusão.",
  "ccm.vcallcenter.config.popup.authentication.failed": "Falha na autenticação.",
  "ccm.satisfactionconfig.message.smsfeedbacktype": "SMS",
  "ccm.agent.contact.voiceBot": "Voz",
  "ccm.custdata.msg.msgValidateFailed": "Caracteres especiais não são permitidos.",
  "ccm.agent.label.deleteAgentFailed": "Uma fila de habilidades foi vinculada a uma conta de serviço. Exclua a configuração da fila de habilidades sob o agente.",
  "ccm.transtask.label.discard": "Descartar",
  "ccm.agent.Transfer.deviceDesc": "Dispositivo de destino",
  "ccm.basicdata.error.capacitylimit": "A capacidade de dados atingiu o limite superior {0}",
  "ccm.dual.call.record.callCompletionRate": "Taxa de conclusão de chamadas",
  "ccm.agent.ten.ttschannelnums": "Quantidade TTS",
  "ccm.speciallist.button.import": "Importação",
  "ccm.speciallist.title.import": "Importar lista especial",
  "ccm.recognition.label.modifymobileagentclose": "O recurso agente móvel não está ativado para o espaço do tenant!",
  "ccm.contact.customer.config.style": "Estilo de exibição",
  "ccm.dual.call.record.unexpected.end": "Fim inesperado",
  "ccm.agent.contact.playdefault": "A duração da gravação está vazia. Deseja usar a duração padrão 60 minutos?",
  "ccm.satisfactionconfig.sms.switch.remark": "Depois que essa opção for ativada, uma mensagem SMS de pesquisa de satisfação será enviada ao cliente após o término da chamada",
  "ccm.satisfactionconfig.satisfaction.sending.channel": "Canal de envio de SMS",
  "ccm.agent.message.refreshSkillException": "Atualizar exceção de habilidade.",
  "ccm.recognition.label.policy.information": "Informações sobre a política",
  "ccm.recognition.label.editPolicy.information": "Editar informações da política",
  "ccm.agent.leavemessage.status.toBeAssigned": "A ser alocado",
  "ccm.agent.leavemessage.status.toBeProcessed": "Para ser processado",
  "ccm.speciallist.msg.applyTime": "Hora da aplicação",
  "ccm.agent.contact.callershutdown": "Chamada encerrada pelo chamador",
  "ccm.satisfactionlevel.message.satistip": "Certifique-se de que os níveis de satisfação utilizados na pesquisa de satisfação de cada canal sejam os mesmos. como pesquisa de satisfação de voz, pesquisa de satisfação SMS e pesquisa de satisfação da Web.",
  "ccm.anonymous.message.fail.close": "Falha ao fechar a bandeira. verificar a configuração CTI",
  "ccm.taskrecord.label.executeBeginTime": "Hora de início",
  "ccm.agent.label.usedMode.sipAccount": "Conta Sip",
  "ccm.callback.url.callnotify": "Configuração de Callback de Notificação de Voz",
  "ccm.callreason.label.allreason": "Todos os motivos",
  "ccmanagement.satisfactionconfig.acceptdate.timeerror.laterThanEndTime": "A data de início deve ser anterior à data de término.",
  "ccm.agent.ten.mediaagents": "Número de Agentes Multimídia",
  "ccm.agent.contact.callernook": "Chamada de saída sem resposta",
  "ccm.satisfactionconfig.message.nofeedbacktype": "Sem pesquisa",
  "ccm.contact.customer.config.datasource.callData": "Dados associados a chamadas",
  "ccm.custdata.button.detail": "Detalhes",
  "ccm.label.contactexport.currentfield": "Campo na lista atual",
  "ccm.speciallist.msg.confirmdelete": "Tem certeza de que deseja excluir a lista especial selecionada?",
  "ccmanagement.tenantspace.option.month_12": "Dezembro",
  "ccmanagement.tenantspace.option.month_11": "Novembro",
  "ccmanagement.tenantspace.option.month_10": "Outubro",
  "ccm.agent.ten.tenantId": "ID do espaço do inquilino",
  "ccmanagement.satisfaction.strategy.saveerror": "Failure",
  "ccm.custdata.msg.inputTableName": "Insira um nome de tabela.",
  "ccm.agent.label.ifBusyNoAnswerAgent": "Se mostrar ocupado quando um agente não atende uma chamada por um longo tempo",
  "ccm.agent.label.asyncUserDispatchMode": "Condição de Recuperação de Mensagem Offline",
  "ccm.agent.label.asyncUserDispatchMode.zero": "por contagem de lotes",
  "ccm.agent.label.asyncUserDispatchMode.one": "por contagem de lote e habilidade",
  "ccm.agent.label.asyncUserDispatchMode.two": "por contagem de lotes e status on-line",
  "ccm.agent.label.asyncUserDispatchMode.three": "por contagem de lote, habilidade e status on-line",
  "ccm.agent.label.asyncUserDispatchMode.four": "por contagem de lote, agente, habilidade e status on-line",
  "ccm.transserverparam.label.password": "Senha",
  "ccm.agent.videoDownload.disabled": "A função de download de vídeo está desativada",
  "ccm.common.label.yes": "Sim",
  "ccm.basicdata.msg.deletesuccess": "Os dados básicos são excluídos com sucesso.",
  "ccm.callreason.label.status": "Status",
  "ccm.contact.customer.config.writeBack": "Escreva de volta registro de contato",
  "ccm.agent.label.ccRoleId": "Função da plataforma",
  "ccm.case.title.selectWorkOrder": "Selecionando Classes da Ordem de Serviço",
  "ccm.agent.title.skillModify": "Modificar fila de habilidades",
  "ccm.pageinterface.pleaseSelectInterfaceType": "Selecione um tipo de interface.",
  "ccm.speciallist.msg.withoutauth": "Você não tem a permissão de operação.",
  "ccm.agent.label.ifReleaseNoAnswerCall": "Se liberar a chamada se o agente não atender por muito tempo",
  "ccm.speciallist.msg.fail": "falharam",
  "ccm.agent.pageurl.httptip": "(Inseguro)",
  "ccm.certificate.management.update.cancel.crlfile": "Tem certeza de que deseja cancelar a atualização do arquivo CRL?",
  "ccm.agent.label.withinSevenDays": "Dentro de 7 dias",
  "ccm.speciallist.label.operator": "Operador",
  "ccm.agent.label.waitTime": "Tempo de espera (s)",
  "ccm.agent.contact.userhungup": "Usuário",
  "ccm.agent.label.ivrChoice": "Selecionar IVR",
  "ccm.agent.message.noReleaseFail": "Somente dados não divulgados podem ser excluídos.",
  "ccm.calledconfig.label.add": "Adicionar",
  "ccm.sysparam.error.validate.notmatchrule": "O parâmetro de entrada não corresponde à regra de verificação",
  "ccm.custdata.msg.missRequiredColumn": "Uma tabela personalizada só pode ser liberada quando contiver pelo menos um campo de índice e outros campos de informações.",
  "ccm.agent.operation.RELEASE": "Liberação",
  "ccm.sysparam.error.validate.watermarkinfo.notmatchruleregex": "O parâmetro só permite de 0 a 20 caracteres em inglês contendo letras maiúsculas e minúsculas, números ou -_/@.",
  "ccm.agent.ten.timezoneOffset": "Deslocamento do fuso horário",
  "ccm.systemparam.error.save": "Falha ao salvar parâmetros.",
  "ccm.dual.call.record.called": "Número Chamado",
  "ccm.agent.addbother.success": "Conseguiu criar o registro do incómodo.",
  "ccm.transserver.label.obsaddr": "Endereço OBS",
  "ccm.callreasonconfig.message.deletecallreason.success": "Excluído com sucesso",
  "ccm.agent.operation.CALLINNER": "Chamada interna",
  "ccm.agent.pageurlparam.paramLengthLimit": "O comprimento do parâmetro não pode exceder 64 caracteres",
  "ccmanagement.tenantspace.option.weeklist_tidtolast": "Faltando 3",
  "ccm.agent.contact.multimedia.wechat": "Multimídia-WeChat",
  "ccm.recognition.label.orderfail": "Falha na assinatura.",
  "ccm.transtask.label.savefail": "Falha ao adicionar um servidor de dump porque um máximo de {0} servidores de dump são permitidos.",
  "ccm.custdata.label.valueRange": "Faixa de valores",
  "ccm.agent.operation.ADJUSTSKILL": "Ajustar fila de qualificação",
  "ccm.agent.leavemessage.view": "Exibir mensagens",
  "ccm.speciallist.msg.norecord": "Nenhum registro pode ser exportado",
  "ccm.urlconfig.message.addsuccess": "Sucesso para adicionar as urls",
  "ccm.recognition.label.appSecret.download.SK.io.error": "Falha ao baixar a SK. Exceção de I/O.",
  "ccm.contactitemconfig.label.contact.recorddata": "Item de dados de registro de contato",
  "ccm.speciallist.msg.error": "Erros",
  "ccm.agent.pageurls.config": "Configuração",
  "ccm.custdata.button.moveBottom": "Inferior",
  "ccm.custdata.msg.deletedItemNotExist": "O item de exclusão não existe. Atualize a página e tente novamente.",
  "ccm.agent.calledroute.create.accesscode.repeat": "A combinação do código de acesso do sistema e do código de extensão para a parte chamada deve ser única.",
  "ccm.custdata.option.isMaskDisplay.no": "Não",
  "ccm.transtask.label.taskupload": "Servidor de Upload",
  "ccm.transtask.label.expirationDuration": "Período de retenção do arquivo de despejo",
  "ccm.agent.calledroute.accesscode": "Código de acesso",
  "ccm.transtask.label.View": "Exibir",
  "ccm.dual.call.record.startTime": "Hora de início da chamada",
  "ccm.contact.tip.no.workname": "O agente correspondente não é obtido！",
  "ccm.ucconfig.message.userId": "ID do diretório (locatário)",
  "ccm.speciallist.msg.transferout": "O número de registros históricos chegou a {0}",
  "ccm.satisfactionconfig.message.channelname": "Canal",
  "ccm.urlconfig.label.input.appSecret": "SecretKey",
  "ccm.agent.updateSoftPhone.reason": "A razão",
  "ccm.recognition.label.": "chamadoroutessuccess=Configurado com sucesso A rota chamada",
  "ccm.agent.contact.download.frequently": "Você tentou baixá-lo também muitas vezes. Tente novamente mais tarde.",
  "ccm.recognition.label.modifymobileagentfailed": "Falha ao modificar o status do agente móvel.!",
  "ccmanagement.satisfaction.strategy.fail.calltime.overlimit": "Um máximo de 100 políticas de duração de chamada podem ser salvas.",
  "ccm.agent.contact.hungup": "Festa de desligar",
  "ccm.agent.label.skillId": "ID da fila de habilidades",
  "ccm.note.config.save": "Salvar",
  "ccm.agent.pageurlparam.checkSpecialChar": "Introduza dígitos, letras, (_), de sublinhados e hífens. O ponto (.) deve estar no meio.",
  "ccm.agent.pageurlparam.checkSpecialChar.noPoint": "Introduza dígitos, letras, (_), de sublinhados e hífens. O ponto (.) deve estar no meio",
  "ccm.agent.pageurlparam.checkSpecialChar.name": "Digite dígitos, letras, sublinhados (_), e hífens (-)",
  "ccm.agent.contact.otherhungup": "Outros",
  "ccm.sysparam.error.validate.ruleregexinvalid": "Erro de sintaxe de regra de validação",
  "ccm.agent.contact.multimedia.line": "Multimídia-Line",
  "ccm.agent.pageurls.confonlynaming": ". Certifique-se de que o nome seja exclusivo.",
  "ccm.agent.ten.email": "Email",
  "ccm.speciallist.button.history": "Transferir para a História",
  "ccm.agent.message.description.limit": "A descrição da fila de habilidades é muito longa",
  "ccm.page.select.blockLayout": "Layout de bloco",
  "ccm.agent.sysparamconfig.resetAllParamsFail": "O arquivo de configuração do parâmetro está ausente. Verifique.",
  "ccm.agent.accesscode.description.require": "A descrição do código de acesso não pode estar vazia.",
  "ccm.sysparam.url.unsafe": "(inseguro)",
  "ccm.agent.sysparamconfig.checkWebhookValidityDays": "A certificação Webhook deve ser válida entre 7 e 365 dias",
  "ccm.asr.create.case": "Criar caso",
  "ccm.transserver.delete.css.fail": "Falha ao excluir as informações de configuração CSS.",
  "ccm.speciallist.msg.besureexport": "Tem certeza de que deseja exportar os dados?",
  "ccm.speciallevel.msg.selectspecialLevel": "Selecione um nível especial",
  "ccm.agent.label.skillName": "Nome da habilidade",
  "ccm.agent.contact.name": "Nome do Documento",
  "ccm.agent.contact.searchName": "Nome do Documento",
  "ccm.agent.agentcontact.error": "Falha na consulta",
  "ccm.agent.label.positiveEmotion": "Atitude foi ótimo! Por favor, mantenha-se atualizado.",
  "ccm.agent.restReason.edit": "Editar motivo de descanso",
  "ccm.satisfactionconfig.message.memberstrategychoose": "Políticas disponíveis",
  "ccm.urlconfig.label.oauth.oauth2loginurl.illegal": "URL de login inválido.",
  "ccm.agent.label.deleteCtiFailed": "Falha ao excluir os dados da plataforma CTI.",
  "ccm.worknobeforevoice.config.choosenote": "Selecione a voz antes da identificação do funcionário",
  "ccm.agent.button.restSoftPhonePwd": "Redefinir a senha do software",
  "ccm.agent.message.export": "Exportar",
  "ccm.agent.message.export.skill": "Fila de habilidade de exportação",
  "ccm.agent.info.message.export": "Exportando informações do agente",
  "ccm.verifyidentity.noMoreThan30Days": "O intervalo de tempo da consulta não pode exceder 30 dias.",
  "ccm.speciallist.msg.download": "Baixando o modelo de importação de lista especial",
  "ccm.speciallist.msg.import.template": "Modelo de Importação de Lista Especial",
  "ccm.agent.button.config": "Configurar",
  "ccm.custdata.operlog.displayMask": "Exibir máscara",
  "ccm.agent.message.agentnotexist": "O agente não existe. Atualize a página Gerenciamento de agentes e tente novamente.",
  "ccm.certificate.management.search.scenename": "Insira a cena do certificado",
  "ccm.agent.message.createSkillConfFail": "Falha ao criar as informações da fila de habilidades.",
  "ccm.satisfactionconfig.message.audiochannel": "Canal de voz ou vídeo",
  "ccm.agent.leavemessage.auditAccount": "Conta de auditoria",
  "ccm.satisfactionconfig.message.calltypeout": "Chamada de saída",
  "ccm.pageinterface.interfaceType": "Tipo de interface",
  "ccm.agent.contact.ivrhungup": "Encaminhar para IVR",
  "ccmanagement.tenantspace.option.weekday_thu": "Quinta",
  "ccm.agent.operation.CANCELWHISPER": "Cancelar sussurro",
  "ccm.contact.customer.config.datasource.objId": "Objeto de fonte de dados",
  "ccm.recognition.label.intellrecogn": "Reconhecimento inteligente",
  "ccm.agent.label.negativeEmotion": "Palavras inapropriadas! Tenha o cuidado de usar as palavras apropriadas.",
  "ccm.verifyidentity.fail.INPUTNULLERROR": "O parâmetro de consulta não pode estar vazio",
  "ccm.contact.label.inactive": "Expirado",
  "ccm.sysparam.error.validate.notmatchrulealpha": "Por favor, insira alfanuméricos ou sublinhados, e apenas comece com letras ou sublinhados",
  "ccm.agent.label.CommomAgent": "Agente comum",
  "ccm.callout.lable.reset": "Redefinir",
  "ccm.agent.label.timeRemaining": "Expiração da senha (Dia)",
  "ccm.transtask.label.week": "Semana",
  "ccm.speciallist.msg.maxCountQuery": "O volume de dados atual é muito grande . Somente os últimos {0} dados serão exibidos",
  "ccm.agent.contact.downloadvideo": "Baixar vídeo",
  "ccm.agent.pageurls.delete": "Excluir",
  "ccm.speciallist.msg.operationsuccess": "Operação bem sucedida",
  "ccm.recognition.label.characteristics": "Recurso",
  "ccm.satisfactionconfig.message.updatesuccess": "Atualizado com sucesso a configuração da pesquisa de satisfação",
  "ccm.botherlist.label.approveStatus.rejected": "Rejeitado",
  "ccm.certificate.management.certificatefile.suffixerror.suffix": "ou extensão vazia.",
  "ccm.pageinterface.objectName": "Nome do objeto",
  "ccm.agent.sysparamconfig.refreshSuccess": "Redefinição com um clique bem-sucedida.",
  "ccm.urlconfig.label.talkurl": "Falar Url",
  "ccm.agent.label.fixedLine": "Número de linha fixa / móvel",
  "ccm.agent.operation.ADJUSTVIDEOMODE": "Ajustar tamanho da janela de vídeo",
  "ccm.calledconfig.message.samename": "Já existe o mesmo nome",
  "ccm.satisfactionconfig.message.satis": "Pesquisa de Satisfação",
  "ccm.agent.pageurls.independentlynosupported": "A configuração interna da página não é suportada para abertura independente.",
  "ccm.querycustdata.msg.noData": "Sem dados",
  "ccm.agent.message.airecognull": "O sinalizador de reconhecimento inteligente está vazio.",
  "ccm.note.config.policy.systemdefault": "Padrão do sistema",
  "ccm.custdata.label.sucNum": "Número de registros bem-sucedidos",
  "ccm.transtask.label.cantsave": "Não pode ser modificado porque foi referenciada por uma tarefa de dump.",
  "ccm.agent.contact.noAuth": "Sem permissão.",
  "ccm.agent.ten.tenantInfor": "Informações sobre tenant",
  "ccm.agent.contact.download": "Baixar",
  "ccm.agent.leavemessage.serviceContactNumber": "Número de contato",
  "ccm.agent.label.usedMode.phone": "Número de telefone",
  "ccm.agent.message.addSkillFail": "Falha ao adicionar a fila de habilidades.",
  "ccm.agent.message.addSkillSuccess": "Fila de habilidades adicionada com sucesso.",
  "ccm.agent.message.maxSkillFail": "A política de fila de habilidades pode conter até 100 itens.",
  "ccm.agent.restReason.confOnlyCauseCode": "Falha na operação. O código do motivo já existe.",
  "ccm.agent.message.skillConfSuccess": "As informações da fila de habilidades foram configuradas com sucesso.",
  "ccm.taskrecord.label.executeSuccess": "Sucesso",
  "ccm.speciallist.msg.crateerror": "A adição falhou",
  "ccm.urlconfig.label.relurl.callback.alerttip": "Se o URL de retorno de chamada não for passado ao chamar a interface de criação de chamada dupla, este URL é chamado de volta quando a chamada dupla termina, caso contrário, o URL de entrada é chamado de volta",
  "ccm.agent.contact.recordtime": "Duração(s) da gravação",
  "ccm.transtask.label.skillReport": "Relatório de Desempenho da Fila de Habilidades",
  "ccm.custdata.operlog.addExportTask": "Nova tarefa de exportação",
  "ccm.agent.contact.QUERU_VCCINFO_FAIL_CODE": "As informações do call center virtual estão vazias.",
  "ccm.transtask.label.skillSummaryReport": "Relatório resumido de desempenho da fila de habilidades",
  "ccm.label.contactexport.selectall": "Todos",
  "ccm.speciallist.label.redlist": "Lista vermelha",
  "ccm.agent.pageurls.success": "Bem-sucedido",
  "ccm.transserver.label.obs": "Servidor OBS",
  "ccm.agent.label.queuingCallsMaxNumRange": "Valor [0-10000]",
  "ccm.note.config.policy.custom": "Personalização",
  "ccm.transtask.label.agentReport": "Relatório de Resumo de Desempenho do Agente",
  "ccm.vcallcenter.config.popup.currentaccount.pwd": "Senha atual da conta",
  "ccm.transtask.label.contactrcddata": "Campos na lista de registros de contatos",
  "ccm.agent.accesscode.description": "Descrição do código de acesso",
  "ccm.idauth.title.idauthconfig": "Configuração do Processo de Autenticação",
  "ccm.agent.label.callDispatchModel.best": "Óptima",
  "ccm.agent.label.restQueueRule.applyFirst": "Primeiro Candidate-se ao Descanso Prioritário",
  "ccm.custdata.msg.duplicateColumnName": "Nome do campo duplicado. Renomear.",
  "ccm.agent.calledroute.create": "Novo",
  "ccm.contactitemconfig.label.data.extended.Type.DataDictionary": "Dicionário de dados",
  "ccm.agent.operation.searchcountid": "Conta de serviço",
  "ccm.callreason.label.disable": "Desativar",
  "ccm.transserver.label.objobs": "OBS",
  "ccm.urlconfig.label.appsecret": "Chave compartilhada",
  "ccm.urlconfig.label.key": "Chave secreta",
  "ccm.agent.resetskillrecord.adjustbusinessaccount": "Ajustar conta de serviço",
  "ccm.transtask.label.save": "OK",
  "ccm.callout.message.resetfail": "Falha ao reinicializar parâmetros.",
  "ccm.speciallist.msg.successNum": "{limit} bem-sucedido",
  "ccm.agent.message.pageNumFail": "O número de configurações de página não pode exceder {0}.",
  "ccm.agent.recall.agent.status.invalid": "O agente não está em estado disponível ou ocupado e não pode voltar a chamar.",
  "ccm.transtask.label.taskid": "ID da tarefa",
  "ccm.contact.customer.config.dataItemError": "O nome ou ID do item de dados deve ser exclusivo.",
  "ccm.agent.button.batchmodify": "Modificar em lote",
  "ccm.custdata.operlog.importdata": "Importar dados",
  "ccm.urlconfig.label.input.appId": "AccessKey",
  "ccm.agent.button.restores": "Restaurações",
  "ccm.agent.label.skillWeightTip": "Quando um agente entra em várias filas, as chamadas na fila de habilidades com o maior peso são processadas primeiro.",
  "ccm.agent.restReason.createFailed": "Falha ao adicionar.",
  "ccm.agent.label.maxTalkTime": "Duração máxima da chamada (s)",
  "ccm.custdata.option.maskStyle.retainFirst4": "Reservar primeiros quatro dígitos",
  "ccm.transserverparam.label.isagent": "Ativar proxy",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputlength": "A duração da hora inicial ou final não pode exceder o limite superior.",
  "ccm.agent.tips.confSuccess": "Sucesso",
  "ccm.agent.tips.noagent": "Não existe nenhum agente no segmento de número selecionado.",
  "ccm.speciallist.label.operTime": "Tempo de criação",
  "ccm.agent.operation.noMoreThan30Days": "O intervalo de tempo não pode exceder 30 dias.",
  "ccm.agent.contact.fiveg": "5G RCS",
  "ccm.sysparam.config.reset.multiple.error": "Os seguintes parâmetros não podem ser redefinidos:",
  "ccm.agent.message.quotingDataOperationFail": "Os dados são referenciados pela tela pop-up. Falha na operação.",
  "ccm.agent.contact.refreshAll": "Atualizar",
  "ccm.agent.pageurls.fail": "Erro",
  "ccm.custdata.operlog.download": "Baixar",
  "ccm.recognition.label.cancelorderfailpd": "Falha ao cancelar a assinatura. Tente novamente mais tarde.",
  "ccmanagement.satisfaction.strategy.accepttime.hour": "hour",
  "ccm.transserver.label.cloudsearchserver.css": "Configuração de CSS do Cloud Search Service",
  "ccm.agent.message.queryCallCenterFail": "Falha na consulta do call center.",
  "ccm.speciallevel.speciallist.levelName": "Nome do Nível",
  "ccm.agent.calledroute.createfail": "Falha ao adicionar dados locais.",
  "ccm.speciallist.tip.timeVerification": "Selecione uma hora posterior à hora atual e anterior a 2038-01-19.",
  "ccm.sysparam.error.validate.ruleinvalid": "A regra de verificação é inválida",
  "ccm.sysparam.error.validate.ccme.alarmThreshold": "A severidade do alarme é inconsistente com o limite do alarme. O limiar de alarme de baixa severidade deve ser menor que (igual a) o limiar de alarme de alta severidade",
  "ccm.sysparam.error.validate.ccme.daysAlarmOverSync": "O parâmetro de entrada deve ser menor que o tempo configurado (7 dias por padrão) para migrar usuários off-line para a tabela de histórico",
  "ccm.agent.message.nulagentincti": "O agente selecionado atualmente não é criado em CTI, Você precisa selecionar as informações de função.",
  "ccm.agent.contact.satiscomment": "Avaliação da Satisfação",
  "ccm.note.config.agent.page.windowTip": "Lembrete pop-up",
  "ccm.agent.label.maxAgentWrapupTime": "Valor [0-3600]",
  "ccmanagement.agent.batchmodify.agentlis": "Por segmento",
  "ccm.agent.message.deletefailed": "Falha ao excluir agente",
  "ccm.sysparam.config.select.prompt": "dicas",
  "ccm.agent.contact.Voice": "Voz",
  "ccm.agent.accesscode.accesscode": "Código de acesso",
  "ccm.custdata.msg.fileSuffixValidateFail": "Formato de arquivo incorreto. Selecione um arquivo CSV.",
  "ccm.agent.message.accRepeatFail": "O parâmetro Conta é duplicado.",
  "ccm.dual.call.record.agentTalkingTime": "Tempo de resposta da parte chamadora",
  "ccm.anonymous.message.fail.notmatch": "O sinalizador de operação não corresponde ao status atual",
  "ccm.transtask.delete.confirm": "Tem certeza de que deseja excluir a tarefa de dump e os registros históricos da tarefa?",
  "ccm.sysparam.config.itemname": "Nome do Parâmetro",
  "ccm.agent.label.conditionOfBusyTransfer": "Condição de transferência ocupada",
  "ccm.speciallist.title.specialList": "Lista Especial",
  "ccm.transtask.label.chatrecordday": "Número de dias antes dos dados do chat serem despejados",
  "ccm.agent.label.ccRole": "Função",
  "ccm.agent.label.withinFifteenDays": "Dentro de 15 dias",
  "ccm.agent.contact.downloadrecord": "Baixar gravação",
  "ccm.agent.message.willcancelconfig": "Se você cancelar a configuração, ela não estará disponível para os agentes que estão usando a configuração. Tem certeza de que deseja cancelar a configuração?",
  "ccm.contact.customer.config.bold": "Negrito",
  "ccm.botherlist.label.approveStatus.toApprove": "Aprovação pendente",
  "ccm.dual.call.record.reasonCode": "Motivo da Liberação",
  "ccm.speciallist.msg.userNo": "Número do assinante",
  "ccm.agent.message.selected": "Você selecionou\u0020",
  "ccm.speciallist.msg.maxlimit": "O número máximo de registros excede {limit}",
  "ccm.agent.pageurls.isenable": "Ativar",
  "ccm.agent.button.createComplete": "Concluído",
  "ccm.agent.pageurls.delsuccess": "Excluído com sucesso.",
  "ccm.sysparam.config.refresh": "Atualizar",
  "ccm.contact.customer.config.datasourceType": "Tipo de Fonte de Dados",
  "ccm.transtask.label.datatype": "Tipo de Dados de Dump",
  "ccm.custdata.option.maskStyle.maskLast4": "Máscara dos últimos quatro dígitos",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnull": "A data inicial ou final não pode estar vazia.",
  "ccm.certificate.management.certificatefile.injectionerror": "O arquivo tem riscos de injeção.",
  "ccm.calledconfig.message.noskill": "Escolha a fila de habilidades.",
  "ccm.pageconfig.label.numInRow": "Número de linhas",
  "ccm.agent.label.CallOutAgent": "Agente de chamadas originadas",
  "ccm.satisfactionconfig.message.number": "Número",
  "ccm.agent.message.searchAccountName": "Informe uma Conta.",
  "ccm.agentparam.message.editsuccess": "Editado com sucesso.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputparse": "A hora inicial ou final está em um formato incorreto.",
  "ccm.calledconfig.message.selectcasetype": "Selecionar Classes Contábeis",
  "ccm.agent.calledroute.delete.work.use": "A configuração da parte chamada é referenciada pelo agente e não pode ser excluída.",
  "ccm.contactitemconfig.label.data.extended.writeback.extended.field": "Campo de Gravação Estendida",
  "ccm.ucconfig.message.clientId": "ID do aplicativo (cliente)",
  "ccm.agent.speciallist.levelName": "Nível da lista especial",
  "ccm.certificate.management.update.crlfile": "Atualizar arquivo CRL",
  "ccm.certificate.management.certificatefile.fileempty": "O arquivo está vazio.",
  "ccm.custdata.title.create": "Criando uma Tabela Base",
  "ccm.agent.contact.multimedia.email": "Multimídia-Email",
  "ccm.idauth.create.error.format": "A codificação do método de autenticação permite apenas números, letras e sublinhados.",
  "ccmanagement.satisfaction.strategy.acceptdate.enddate": "Data de fim",
  "ccm.custdata.option.columnType.number": "Número",
  "ccm.idauth.create.error.name": "O nome do modo de autenticação já existe.",
  "ccm.agentparam.message.editfail": "Falha na edição.",
  "ccm.agent.label.mobileagentremark": "Observações",
  "ccm.agent.operation.INTERCEPT": "Interceptar",
  "ccm.agent.contact.faceBook": "Facebook",
  "ccm.agent.message.worknostartbig": "A ID do funcionário inicial não pode ser maior que a ID do funcionário final.",
  "ccmanagement.tenantspace.option.weekday_tue": "Terça",
  "ccm.agent.label.busyRateRange": "Valor [0-100]",
  "ccm.agent.pageurls.operation": "Operação",
  "ccm.contactitemconfig.label.data.item.extended": "Dados estendidos",
  "ccm.sysparam.config.title": "Parâmetros do Tenant",
  "ccm.custdata.label.beginTime": "Hora de início",
  "ccm.custdata.msg.uploadSelect": "Selecione um arquivo",
  "ccm.agent.contact.callout": "Chamada enviada",
  "ccm.agent.contact.mediaType": "Tipo de mídia",
  "ccm.botherlist.label.approveComment.reject": "Rejeitar",
  "ccm.ivrvoice.play.error":"Carregando exce\u00E7\u00E3o de recurso\, verifique a configura\u00E7\u00E3o relacionada.",
  "ccm.contact.details.tab.title.audio":'Voz',
  "ccm.contact.details.tab.title.video":'Vídeo',
  'ccm.contact.placeholder.searchCustomerNo': 'Número de contacto',
  "ccm.agent.contact.transfercontent": 'Conteúdo ASR',
  "ccm.agent.label.oneday": '1 dia',
  "ccm.agent.label.threedays": '3 dias',
  "ccm.agent.label.sevendays": '7 dias',
  "ccm.agent.label.thirtydays": '30 dias',
  "ccm.contactitemconfig.message.noBaseData":"Não há dados básicos disponíveis",
  "ccm.label.downloadAttachment.confirm":"Tem certeza de que deseja baixar o anexo selecionado?",
  "ccm.contact.detail.talk.content.seachtips":"Resultados da pesquisa: {num}",
  "ccm.contact.detail.talk.content.not.support":"O texto de pesquisa não pode conter\"/()",
  "ccm.skillgroup.message.groupBind": "O tipo de agente vinculado ao grupo de habilidades não pode selecionar a habilidade atual",
  "ccm.contact.detail.talk.content.seach.remark":"Somente o conteúdo do bate-papo de texto exibido nesta página pode ser pesquisado",
  "ccm.contact.detail.talk.content.seach.remark.emaile":"Somente as mensagens que foram exibidas na página de pesquisa podem ser pesquisadas. Após a conclusão da pesquisa, somente as mensagens correspondentes são exibidas.",
  "ccm.download.passwd.validator.specialStr": "Apenas os seguintes caracteres especiais são permitidos: _ {'@'} {'%'}",
  "ccm.agent.label.selfPhoneNotNull":"Para ativar a Anonimização de Agente é necessário preencher o número de telefone do agente",
  "ccm.login.record.noMatchingLoginRecordExists": 'Nenhum registro de check-in qualificado',
  "ccm.agent.message.searchSkillQueueName": "Nome da Fila de Habilidades",
  "ccm.agent.button.skill.synchronize": "Sincronizar",
  "ccm.skillgroup.msg.deleteerror": "Selecione o grupo de habilidades a ser excluído.",
  "ccm.skillgroup.create.fail": "Falha ao criar o grupo de habilidades.",
  "ccm.agent.download.forbidden": "Sem permissão de download.",
  "ccm.title.org.all": "Selecione uma organização",
  'ccm.title.template.select.email': 'Selecionar modelo de e-mail',
  'ccm.title.template.select.sms': 'Selecionar modelo de SMS',
  "ccm.skillgroup.message.nameValidateFail": "O grupo de habilidades não pode conter os seguintes caracteres especiais: [<>\"()]&=;,",
  "ccm.skillgroup.message.namePrefixSuffix": "O grupo de habilidades não pode começar ou terminar com um espaço",
  "ccm.skillgroup.message.nameLimit": "Nome do grupo de habilidades muito longo",
  "ccm.skillgroup.message.nameExistence": "O nome do grupo de habilidades já existe",
  "ccm.skillgroup.update.fail": "Falha ao atualizar grupo de habilidades",
  "ccm.skillgroup.query.fail": "Falha ao obter a configuração do grupo de habilidades.",
  "ccm.skillgroup.create.limit": "Um máximo de 2000 grupos de habilidades podem ser criados.",
  "ccm.skillgroup.update.failtip": "Falha ao atualizar o grupo de habilidades. Sincronize a fila de habilidades e os dados do grupo de habilidades primeiro.",
  "ccm.skillgroup.create.failtip": "Falha ao criar o grupo de habilidades. Sincronize as informações do grupo de habilidades primeiro",
  "ccm.skillgroup.msg.deleteTips": "O grupo de habilidades atual foi associado ao agente {0}. Remova a associação e, em seguida, exclua-a.",
  "ccm.sendmsg.whatsapp.withoutTemplate": "Sem conteúdo. Selecione um modelo primeiro.",
  "ccm.skillgroup.msg.deleteFailed": "Falha na exclusão do grupo de habilidades",
  "ccm.agent.label.skillType":"Tipo de Habilidade",
  "ccm.agent.label.selectSkillGroup":"Selecionar grupo de habilidades",
  "ccm.skill.label.defaultskill":"Fila de Habilidades Padrão",
  "ccm.ccagent.management.default.options": "Opções padrão",
  "ccm.agent.leavemessage.overtime":"Tempo limite de processamento. Por favor, abra a janela pop-up novamente.",
  "ccm.agent.label.dualChannelRecFlag": "Gravação Dual Track",
  "ccm.agent.message.samepassword": "A nova senha não pode ser igual à senha atual",
  "ccm.callback.url.auth.tip": "Modo de autenticação de URL de retorno de chamada",
  "ccm.callback.url.callback": "Configuração de retorno de chamada bidirecional",
  "ccm.certificate.message.tip.warning": "O algoritmo de assinatura e o comprimento da chave do certificado não atendem aos requisitos, o que é arriscado. Tem certeza de que deseja continuar?",
  "ccm.nms.satisfactionservice.buttonNumber": "O número de chaves de pesquisa de satisfação não é superior a 5 e não inferior a 3.",
  "ccm.recognition.label.closeVoiceNotificationSuccess": "O recurso de notificação por voz foi desativado com sucesso.",
  "ccm.recognition.label.modifyVoiceNotificationClose": "O recurso de notificação de voz não está habilitado para o espaço do locatário.",
  "ccm.recognition.label.openVoiceNotificationSuccess": "O recurso de notificação de voz foi habilitado com sucesso.",
  "ccm.recognition.label.voiceNotification": "Sinalizador de notificação por voz",
  "ccm.satisfaction.message.resetconfirm": "Tem certeza de que deseja redefinir o nível de satisfação e o modelo de pesquisa de satisfação? Cada canal precisa usar o nível de satisfação de redefinição e o conteúdo do modelo.",
  "ccm.satisfactionconfig.message.choosestrategy": "*Selecionar a estratégia de pesquisa de satisfação",
  "ccm.satisfactionconfig.message.memberstrategyconfig": "Configuração do membro do grupo de estratégia",
  "ccm.satisfactionconfig.message.smscloud": "SMS em nuvem da Huawei",
  "ccm.satisfactionconfig.message.smsgateway": "Gateway SMS",
  "ccm.satisfactionlevel.message.leastcalltype": "Selecione pelo menos um tipo de chamada.",
  "ccm.satisfactionservice.evaluationerror": "A avaliação falhou",
  "ccm.satisfactionservice.noevaluation": "Não avaliado",
  "ccm.satisfactiontemplate.message.resetfail": "Falha ao redefinir o conteúdo do modelo de pesquisa de satisfação.",
  "ccm.satisfactiontemplate.message.savefail": "O modelo de mensagem de pesquisa de satisfação é usado para enviar mensagens de pesquisa de satisfação para clientes nos canais Instagram, Telegram, Whatsapp, Line, Web Chat, Face Book, X (Twitter), 5G RCS e SMS.",
  "ccm.urlconfig.label.noramalOrITA": "Selecione o tipo de chamada bidirecional CEC ou ITA bidirecional.",
  "ccm.urlconfig.label.noramalOrITA.input": "Configuração de Parâmetro ITA",
  "ccm.urlconfig.label.numberAllocationMode1": "Automático",
  "ccm.urlconfig.label.numberAllocationMode2": "Especificado",
  "ccm.urlconfig.label.oauth.label": "Informações de autorização do OAuth 2.0",
  "ccm.urlconfig.label.radio.radioType1": "Chamada bidirecional CEC",
  "ccm.urlconfig.label.radio.radioType2": "Chamada bidirecional ITA",
  "ccm.urlconfig.message.missing.appIdOrAppSecret": "A chave de acesso ou a chave secreta não são definidas na configuração de chamada bidirecional ITA.",
  "ccm.urlconfig.message.missing.appKeyOrDomainName": "A chave do aplicativo e o nome de domínio na configuração de chamada bidirecional do ITA devem ser definidos.",
  "ccm.urlconfig.message.notNumber.xNumber": "O número X configurado para chamadas bidirecionais ITA não é número.",
  "ccm.urlconfig.message.oauth.tip.warning": "Nenhuma autenticação de URL de retorno de chamada pode causar riscos de segurança. Tem certeza de que deseja usar este modo de autenticação?",
  "ccm.urlconfig.message.overlimit.appId": "A chave de acesso configurada para chamadas bidirecionais ITA não pode exceder 128 caracteres.",
  "ccm.urlconfig.message.overlimit.appKey": "A chave do aplicativo na configuração de chamada bidirecional do ITA não pode exceder 128 caracteres.",
  "ccm.urlconfig.message.overlimit.appSecret": "A chave secreta configurada para chamadas bidirecionais ITA não pode exceder 512 caracteres.",
  "ccm.urlconfig.message.overlimit.domainName": "O nome de domínio na configuração de chamada bidirecional ITA não pode exceder 128 caracteres.",
  "ccm.urlconfig.message.overlimit.xNumber": "O número X configurado para chamadas bidirecionais ITA não pode exceder 20 caracteres.",
  "ccm.urlconfig.message.tip.warning": "Certifique-se de ter adquirido serviços relacionados ao ITA. Caso contrário, as chamadas serão afetadas.",
  "ccm.urlconfig.message.warning": "Assegure-se de que o URL push de CDR esteja configurado no ITA. Caso contrário, o ITA não pode enviar CDRs para o CEC. Um exemplo de URL de push é o seguinte:",
  "ccm.urlconfig.modify.authbothnull": "As informações de autorização não podem estar vazias.",
  "ccm.urlconfig.modify.authtype2": "OAuth 2.0",
  "ccm.urlconfig.modify.oauth.ak.tooshort": "O AK deve conter pelo menos 16 caracteres.",
  "ccm.urlconfig.modify.oauth.sk.tooshort": "O SK deve conter pelo menos 16 caracteres.",
  "ccm.notifition.sendmessage.smsconfignull":"A configuração da mensagem SMS está vazia. Centro de Configuração > Centro de Configuração > Configuração de SMS para configurar a informação SMS.",

  "iolp.label.basicInfo":"Informações básicas",
  "iolp.label.workflow":"Fluxo de trabalho",
  "iolp.workflow.node.title":"As propriedades do nó foram definidas com sucesso",
  "iolp.workflow.line.title":"Atributos de conexão definidos com sucesso",
  "iolp.workflow.node.label.name":"Nome",
  "iolp.workflow.node.label.nodeType":"Tipo de nó",
  "iolp.workflow.node.label.nodeId":"id do nó",
  "iolp.workflow.node.label.executeType":"Tipo de execução",
  "iolp.workflow.node.label.autoNodeInfo":"Número correspondente do resultado do nó automático",
  "iolp.workflow.node.label.executeContent":"Executar conteúdo",
  "iolp.workflow.node.label.explain":"Catálogo de serviço",
  "iolp.workflow.node.label.associateKbs":"Conhecimento associado",
  "iolp.workflow.node.label.associateBusiness":"Link de tratamento de serviços associado",
  "iolp.workflow.node.label.hintTextInfo":"Informações de descrição",
  "iolp.workflow.node.label.sms":"Modelo de SMS",
  "iolp.workflow.node.label.associateJs":"Funções JS relacionadas",
  "iolp.workflow.nodetype.andsign":"nó da tarefa",
  "iolp.workflow.nodetype.fork":"nó de decisão",
  "iolp.workflow.nodetype.andjoin":"Composição",
  "iolp.workflow.executetype.common":"tipo normal",
  "iolp.workflow.executetype.SMS":"Envio de SMS",
  "iolp.workflow.executetype.email":"E-mail enviado",
  "iolp.workflow.executetype.IVR":"Voltar para o processo automático IVR",
  "iolp.workflow.executetype.auto":"Tarefa automática",
  "iolp.workflow.executetype.subFlow":"Encaminhar para outros subprocessos de negócios",
  "iolp.workflow.executetype.case":"Criar tíquete",
  "iolp.configur.service.label.basicInfo":"Informações básicas",
  "iolp.configur.service.label.workflow":"Processo guiado",
  "iolp.configur.service.label.name":"Nome",
  "iolp.configur.service.label.workflow.name": "Nome do Processo",
  "iolp.configur.service.label.directory.name": "Nome da categoria",
  "iolp.configur.service.label.serviceCode":"Código de serviço",
  "iolp.configur.service.label.upperLevelServiceCode":"Código de serviço de nível superior",
  "iolp.configur.service.label.imageUrl":"Endereço da imagem",
  "iolp.configur.service.label.customizedPageUrl":"Endereço da página personalizada",
  "iolp.configur.service.label.customizedPageContainerId":"Identificação personalizada do contêiner da página",
  "iolp.configur.servicelabel.shortcutButtonPage":"Endereço da página do botão de atalho",
  "iolp.configur.service.label.shortcutButtonContainerId":"Identidade do contêiner da página do botão de atalho",
  "iolp.configur.service.label.search.tips": "Insira um nome",
  "iolp.configur.service.add": "Novo",
  "iolp.configur.service.operate.edit": "Editar",
  "iolp.configur.service.operate.delete": "Apagar",
  "iolp.configur.service.tips.customizedPageUrl": "exemplo: CustomerInfoDemo.html",
  "iolp.configur.service.add.select.title": "Nome da categoria selecionada",
  "iolp.configur.service.add.parent.title": "Nome da categoria pai",
  "iolp.configur.service.dialog.root.directory.title": "Sem Categoria Pai",
  "iolp.configur.service.add.operate.title": "Novo tipo",
  "iolp.configur.service.dialog.add.directory.title": "Nova categoria de orientação de negócios",
  "iolp.configur.service.dialog.modify.directory.title": "Editar categoria de orientação de negócios",
  "iolp.configur.service.dialog.add.workflow.title": "Novo processo de orientação de negócios",
  "iolp.configur.service.dialog.modify.workflow.title": "Editar Processo de Orientação de Negócios",
  "iolp.configur.servicelabel.label.serialNumber":"Número de classificação",
  "iolp.configur.servicelabel.label.status":"Estado",
  "iolp.configur.servicelabel.label.description":"Informações de descrição",
  "iolp.configur.servicelabel.button.saveDraft":"Salvar como rascunho",
  "iolp.configur.servicelabel.status.draft":"Rascunho",
  "iolp.configur.servicelabel.button.publish":"Publicado",
  "iolp.configur.servicelabel.common.tips.error":"Erro",
  "iolp.configur.servicelabel.common.tips.success":"Sucesso",
  "iolp.tree.tips":"Você não pode adicionar nenhum nó abaixo do nó folha.",
  "iolp.configur.servicelabel.save.successful":"Salvado com sucesso",
  "iolp.configur.servicelabel.release.successful": "Publicação bem-sucedida.",
  "iolp.configur.servicelabel.save.failed":"Falha ao salvar",
  "iolp.configur.servicelabel.release.failed": "Falha ao publicar",
  "iolp.configur.servicelabel.contact.administrator":"A geração do código de serviço falhou. Entre em contato com o administrador",
  "iolp.configur.servicelabel.select.node":"Selecione um nó",
  "iolp.configur.servicelabel.confirm.delete":"Remover",
  "iolp.configur.servicelabel.confirm.deleteAll":"Tem certeza de que deseja excluir o nó atual e seus filhos?",
  "iolp.configur.servicelabel.confirm.deleteCurrent":"Tem certeza de que deseja excluir o nó atual?",
  "iolp.configur.servicelabel.delete.successful":"Excluído com sucesso",
  "iolp.configur.servicelabel.delete.failed":"Falha ao excluir",
  "iolp.configur.servicelabel.tips.info":"dica",
  "iolp.workflow.label.graphZoomOut": "Diminuir o zoom",
  "iolp.workflow.label.graphZoomIn": "Ampliar",
  "iolp.workflow.label.previous": "Cancelar",
  "iolp.workflow.label.next": "Recuperação",
  "iolp.workflow.label.cleanGraph": "Limpar tela",
  "iolp.workflow.label.taskElement": "Elemento do diagrama de função",
  "iolp.workflow.label.decisionElement": "Elemento do diagrama de ramificação",
  "iolp.workflow.label.operation":"operação",
  "iolp.workflow.label.information":"Informações relacionadas",
  "iolp.workflow.label.unmatchedFlow": "O serviço correspondente não pode ser correspondido",
  "iolp.workflow.button.restart":"Reiniciar processo",
  "iolp.workflow.button.hideFlowChart":"Ocultar fluxograma",
  "iolp.workflow.button.showFlowChart":"Exibir fluxograma",
  "iolp.workflow.label.currentNodeName":"Nome do nó atual",
  "iolp.workflow.label.customizeInfo":"Informações personalizadas",
  "iolp.workflow.label.flowInfo":"Informações do processo de negócios",
  "iolp.workflow.button.kbs":"base de conhecimento",
  "iolp.workflow.button.service":"Tratamento de negócios",
  "iolp.workflow.title.name":"Processo de Negócio",
  "iolp.configur.service.label.service.title": "Categoria de negócio",
  "iolp.run.service.label.customizedPage": "Informações de personalização",
  "iolp.run.service.label.overview": "Visão geral do processo",
  "iolp.run.service.label.nodeName": "Nome do nó:",
  "agentconsole.ccnotification.recipient.validate.error": "Cada número pode conter apenas dígitos e um máximo de 20 dígitos.",
  "ccm.sendwhatsapp.content.too.long": "O conteúdo do modelo é muito longo.",
  "ccm.sendwhatsapp.body.too.long": "O conteúdo do corpo é muito longo.",
  "ccm.notifition.sendmessage.largerthanlimit":"O número de destinatários excede o máximo {n}.",
  "ccm.ccagent.management.batch.operation":"Operação em lote",
  "ccm.skillgroup.message.noskill": "A lista de habilidades do grupo de habilidades não pode ficar vazia.",
  "ccm.ccagent.management.advanced.search": "Pesquisa avançada",
  "ccm.satisfactiontemplate.tips": "Nota: O sistema usa o idioma padrão do inquilino para gerar o conteúdo do modelo, o modelo de mensagem de pesquisa de satisfação é usado para enviar mensagens de pesquisa de satisfação para clientes no Instagram, Telegram, Whatsapp, Line, Web Chat, Face Book, X (Twitter), 5G RCS, e canais de SMS.",
  "ccm.satisfactionconfig.message.textmsg": "Mensagem de texto",
  "ccm.agent.message.agentweight.invalid": "O peso do agente deve variar de 1 a 300.",
  "ccm.agent.message.skillweight.outlimit": "O valor do peso deve variar de 1 a 100.",
  "ccm.ccagent.management.cannot.exceed.25":"O número não pode exceder 25 caracteres.",
  "ccm.satisfactiontemplate.title":"Configuração de Conteúdo do Modelo de Pesquisa de Satisfação",
  "ccm.satisfactiontemplate.icontitle": "Atualizar o conteúdo do modelo por nível de satisfação",
  "ccm.agent.message.skillweight.invalid": "O peso da habilidade deve variar de 1 a 100.",
  "ccm.satisfaction.leveltips":"Nota: Todos os canais usam a mesma configuração de satisfação.",
  "ccm.agent.callreason.must": "O motivo da chamada é obrigatório.",
  "iolp.workflow.efficiency.analysis":"Análise de Eficiência",
  "iolp.configur.servicelabel.label.version": " Versão de lançamento",
  "iolp.configur.servicelabel.label.version.number": "Número de versões",
  "iolp.configur.servicelabel.status.abandon": "Inválido",
  "iolp.configur.servicelabel.status.publish": " Liberação",
  "iolp.configur.servicelabel.status.unpublish": " Não publicado",
  "iolp.configur.service.operate.copy": "Replicação",
  "iolp.configur.service.operate.publish": " Liberação",
  "iolp.configur.service.operate.cancel": " Cancelar publicação",
  "iolp.configur.servicelabel.publish.successful": " publicado com sucesso",
  "iolp.configur.servicelabel.publish.failed": "Falha ao publicar",
  "iolp.configur.servicelabel.cancel.successful": " lançamento cancelado com sucesso.",
  "iolp.configur.servicelabel.cancel.failed": "Falha ao cancelar",
  "iolp.configur.servicelabel.copy.successful": " copiado com sucesso.",
  "iolp.configur.servicelabel.copy.failed": " Falha na replicação",
  "iolp.configur.servicelabel.confirm.delete.flow": " Tem certeza de que deseja excluir a versão atual?",
  "iolp.process.utilization.efficiency.analysis":"Análise da eficiência de utilização do processo",
  "iolp.flow.number.of.contacts":"Número de contatos (peças)",
  "iolp.flow.first.solution.rate":"Taxa de primeira solução (%)",
  "iolp.flow.please.select.time.before.today":"Por favor, selecione um horário antes de hoje",
  "iolp.flow.the.interval.validate":"O intervalo é de 1 dia ou de 3 a 30 dias.",
  "iolp.flow.analysis.date.time":"Data/Hora",
  "ccm.empty.template": "O modelo não existe. Verifique o modelo de notificação.",
  "ccm.empty.variables": "A variável de modelo não existe. Verifique o modelo de notificação.",
  "ccm.no.meeting.variables": "A variável de modelo não contém a variável Meeting_URL. Verifique o modelo de notificação.",
  "ccm.agent.meeting.failed": "El agente no puede crear una conferencia.",
  "ccm.agent.link.failed": "O agente não consegue gerar o link SMS.",
  "ccm.agent.meeting.receiver.check.error": "El número de destinatarios no puede exceder de 1.",
  "ccm.agent.contact.timeouthungup": "Fechamento do tempo limite",
  "ccm.agent.contact.skilltimeouthungup": "Tempo limite para a fila de habilidades",
  "ccm.agent.leavemessage.opinionempty": "Comentários de auditoria são obrigatórios.",
  "ccm.sms.meeting.link": "SMS de ligação da conferência",
  "ccm.agent.sms.meeting.gateway.commonerror": "Falha ao criar a conferência. Código de erro: {n}",
  "ccm.agent.sms.meeting.gateway.error": "Falha ao criar a conferência. Código de erro: {n}. Não crie a conferência no modo mudo, em espera ou com vários participantes.",
  "ccm.contactRecordDetail.notSetTraceLogError": "Nenhuma trilha de processo encontrada, adicione as configurações de rastreamento e, em seguida, verifique a trilha de processo do registro de contato correspondente",
  "ccm.agent.callreason.modify.exceeds.limit": "Falha ao modificar o motivo da chamada. O intervalo excede o período de validade do motivo da chamada.",
  "ccm.label.appcube.switch": "P\u00E1gina Integrada ao AppCube",
  "ccm.label.appcube.app": "Selecionar aplica\u00E7\u00E3o",
  "ccm.label.appcube.menu": "Selecionar menu",
  "ccm.contactitemconfig.label.data.item.sequence.number.seq": "Ordem do Campo",
  "ccm.contactitemconfig.label.data.item.add": "Criar campo de registro de contato",
  "ccm.contactitemconfig.label.data.item.edit": "Editar campo de registro de contato",
  "ccm.contactitemconfig.label.data.item.tips": "A interface de origem do conjunto de dados deve ser configurada na página de gerenciamento da interface. Entre em contato com o administrador da operação do sistema.",
  "ccm.contactitemconfig.label.data.item.basic.item": "Campo estendido básico",
  "ccm.contactitemconfig.label.data.item.custom.item": "Campo estendido definido pelo usuário",
  "ccm.contactitemconfig.label.dataInfo.item.add": "Criar Item de Dados",
  "ccm.contactitemconfig.label.dataInfo.item.edit": "Editar item de dados",
  "ccm.custdata.label.data.table.template": "Modelo de tabela de dados",
  "ccm.custdata.msg.uploadType": "Duplicar método de importação de dados",
  "ccm.custdata.msg.uploadTypeInfo": "Se houver dados duplicados\, se você optar por substituir os dados originais\, o registro de dados será atualizado\, se você optar por não substituir os dados originais\, eles serão considerados dados com falha",
  "ccm.custdata.msg.uploadType.cover": "sobrescrever os dados originais",
  "ccm.custdata.msg.uploadType.notcover": "Não substitua os dados originais",
  "ccm.pageconfig.label.page": "Página",
  "ccm.pageconfig.label.publishpage": "Publicar página",
  "ccm.transtask.label.intelligentInspectionConfig":"Configuração de despejo de dados de inspeção AI",
  "ccm.validate.portLengthError":"A escala da porta deve ser 1-65535",
  "ccm.resource.dump.server.add":"Criar um servidor de despejo de recursos",
  "ccm.resource.dump.server.edit":"Editar servidor de despejo de recursos",
  "ccm.resource.dump.server.view":"Exibir Servidores de Despejo de Recursos",
  "ccm.resource.dump.css.add":"Criar um Cloud Search Server",
  "ccm.resource.dump.css.edit":"Editar servidor de pesquisa na nuvem",
  "ccm.resource.dump.css.view":"Ver servidores do Cloud Search",
  "ccm.resource.dump.task.add":"Criar uma tarefa de despejo de recursos",
  "ccm.resource.dump.task.edit":"Editar uma tarefa de despejo de recursos",
  "ccm.resource.dump.task.view":"Exibir uma Tarefa de Despejo de Recursos",
  "ccm.transtask.label.intelligentInspectionDay":"AI Inspeção Dados Dump Duração",
  "ccm.transtask.css.server.num":"O número de Cloud Search Server não pode exceder 1.",
  "ccm.contact.customer.config.num":"A coluna número de informações do cliente de contato não pode exceder 10.",
  "ccm.export.password.error.tips": "A senha do arquivo compactado contém de 8 a 12 caracteres, incluindo letras, dígitos e caracteres especiais {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.export.password.error.message": "Deve conter letras, números e caracteres especiais {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.agent.ccagentinfo.modifyselfphone.success":"Numero de telefone fixo/móvel modificado com sucesso",
  "ccm.agent.ccagentinfo.modifyselfphone.fail":"Fallou a modificação do número de telefone fixo/móvel",
  "ccm.export.table.taskTime": "Tempo da tarefa",
  "ccm.export.table.taskStatus": "Status da tarefa",
  "ccm.agent.contact.multimedia.sms":"Multimedia-SMS",
  "ccm.agent.label.skill":"Habilidades",
  "ccm.agent.button.randomRestSoftPhonePwd":"Redefinir a senha do softphone",
  "ccm.agent.label.skillGroup":"Grupo de Fila de Habilidades",
  "ccm.agent.message.searchSkillGroupName":"Nome",
  "ccm.skill.label.skillGroupName":"Nome do grupo de filas de habilidades",
  "ccm.agent.button.restSoftPhonePwd.remark":"Se voc\u00EA redefinir as senhas do softphone do agente para senhas aleat\u00F3rias em lotes\, os agentes devem redefinir suas pr\u00F3prias senhas do softphone antes de usar a fun\u00E7\u00E3o softphone. O administrador tamb\u00E9m pode clicar em Redefinir senha do Softphone na lista para alterar manualmente a senha do agente.",
  "ccm.agent.management.exporting.agent.information":"Exportando informações do agente",
  "ccm.agent.clear.message.clearcommonerror":"Falha ao cancelar a configura\u00E7\u00E3o. O servi\u00E7o n\u00E3o est\u00E1 dispon\u00EDvel.",
  "ccm.contactitemconfig.label.dataInfo.item.name.exist": "Nome do Item de Dados já existe.",
  "ccm.agent.button.downloadvrc": "Baixar VRC",
  "ccm.dual.call.callback.url.config":"Configuração de URL de retorno de chamada",
  "ccm.dual.call.callback.url.auth":"Interface Invocando Autenticação de URL",
  "ccm.dual.call.callback.url.authType":"Modo de autenticação de URL de retorno de chamada",
  "ccm.dual.call.callback.url.ita":"Configuração de Parâmetro ITA",
  "ccm.dual.call.callback.url.xNumberType":"Modo de Alocação de Número X",
  "ccm.agent.management.select.agent":"Selecionando Agentes por ID de Funcionário",
  'ccm.password.length.limit.min': 'A senha é muito curta. Insira {min}-{max} caracteres',
  'ccm.password.length.limit.max': 'A senha é muito longa. Insira {min}-{max} caracteres',
  "ccm.agent.operation.SCREENMONITOR": "Monitoramento de tela",
  'ccm.mobile.agent.front.add': 'Criando um agente móvel',
  'ccm.mobile.agent.front.edit': 'Editar agente móvel',
  'ccm.mobile.agent.front.checkNum': 'Selecione pelo menos um número.',
  'ccm.mobile.agent.front.maxNum': 'O número não pode exceder 15.',
  'ccm.contactitemconfig.label.contactData.add': 'Criando um Conjunto de Dados de Registro de Contato',
  'ccm.contactitemconfig.label.contactData.edit': 'Editando um Conjunto de Dados de Registro de Contato',
  'ccm.agent.button.downloading':'Baixando...',
  'ccm.label.operationexport.nodata': 'Nenhum registro de operação atende às condições.',
  'ccm.label.transferexport.nodata': 'Nenhum registro de encaminhamento atende às condições.',
  'ccm.label.leaveexport.nodata': 'Nenhum registro de descanso atende aos requisitos.',
  'ccm.label.send.whatsapp': 'Enviar Whatsapp',
  "ccm.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "O nome do call center não pode conter caracteres especiais",
  'ccm.common.validation.numbers': 'Insira apenas dígitos',
  'ccm.common.validation.click.number': 'Por favor, clique no número',
  'ccm.ccagent.agent.weight.tips': 'Um valor maior indica uma prioridade mais alta. Quando há vários agentes ociosos\, o sistema aloca preferencialmente as chamadas de entrada para o agente com um peso maior.',
  'ccm.ccagent.skill.weight.tips': 'Um valor maior indica uma prioridade mais alta. Quando há chamadas em várias filas\, o sistema aloca as chamadas na fila com um peso maior para o agente ocioso.',
  'ccm.ccagent.batch.modify.special.character.verification': 'não pode conter’',
  'ccm.resource.dump.obs.cert.warn.tip': 'Entre em contato com a equipe de O&M para importar o certificado de confiança do OBS para a biblioteca de certificados.',
  "ccm.audiovideoworkbench.mood" : "Humor",
  "ccm.audiovideoworkbench.intention" : "Intenção",
  "ccm.audiovideoworkbench.complain" : "Propensão a reclamar",
  "ccm.audiovideoworkbench.aiswitch" : "Modelo de linguagem grande",
  "ccm.audiovideoworkbench.airecommendation" : "Recomendações inteligentes",
};