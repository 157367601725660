import i18n from "@/lang";

const $t = i18n.global.t

const agentconsoleRoutes = [
  {
    path: 'audio-video-workbench',
    name: '6006',
    title: $t('agentconsole.menu.title'),
    component: () => import(/* webpackChunkName: "aicc-agentconsole" */'@/views/agentconsole/pages/audio-video-workbench/AudioAndVideoWorkbench.vue'),
    children: []
  },
]
export default agentconsoleRoutes
