const sumRouter = {
  pageWithSideNav: [
    {
      path: '/org-staff',
      name: '8001001',
      title: '组织结构',
      component: () => import('./components/org-staff/org-staff.vue')
    },
    {
      path: '/permissions',
      name: '8011012',
      title: '权限管理',
      component: () => import('./components/permission/permissions.vue')
    },
    {
      path: '/menus',
      name: '8011013',
      title: '菜单管理',
      component: () => import('./components/menu/menus.vue')
    },
    {
      path: '/usergroup',
      name: '8001006',
      title: '用户群组',
      component: () => import('./components/user-group/user-group.vue')
    },
    {
      path: '/loginlog',
      name: '8011016',
      title: '登录日志',
      component: () => import('./components/login-log/login-log.vue')
    },
    {
      path: '/user',
      name: '8001002',
      title: 'user',
      component: () => import('./components/user/index.vue')
    },
    {
      path: '/user-lock',
      name: '8001008',
      title: '帐号锁管理',
      component: () => import('./components/user-lock/user-lock.vue')
    },
    {
      path: '/seesion-manage',
      name: '8001009',
      title: '会话管理',
      component: () => import('./components/session-manage/session-manage.vue')
    },
    {
      path: '/tenant',
      name: '8001013',
      title: '租户管理',
      component: () => import('./components/api-build/tenantManage/index.vue')
    },
    {
      path: '/opera-log',
      name: '8011015',
      title: '操作日志',
      component: () => import('./components/opera-log/opera-log.vue')
    },
    {
      path: '/userpwd-grouprule',
      name: '8001007',
      title: '帐号和密码规则',
      component: () => import('./components/userpwd-grouprule/userpwd-grouprule.vue')
    },
    {
      path: '/role',
      name: '8001003',
      title: '角色',
      component: () => import('./components/role/role.vue')
    }
  ],
  pageWithBackHeader: [
    {
      path: '/user/employee-detail',
      name: 'employee-detail',
      title: '查看员工',
      component: () => import('./components/user/employee-detail.vue')
    },
    {
      path: '/user/employee-config',
      name: 'employee-config',
      title: '配置员工',
      component: () => import('./components/user/employee-config-page.vue')
    },
    {
      path: '/user/employee-batchConfig',
      name: 'employee-batchConfig',
      title: '批量配置员工',
      component: () => import('./components/user/employee-batchConfig.vue')
    },
    {
      path: '/tenant/tenantSpaceInfo',
      name: 'tenantSpaceInfo',
      title: '查看租间',
      component: () => import('./components/api-build/tenantManage/tenantSpaceInfo.vue')
    }
  ],
  pageWithSelfTab: [
    {
      path: '/user/employee-add',
      name: 'employee-add',
      title: '新增员工',
      component: () => import('./components/user/employee-add.vue')
    }
  ]
}

export default sumRouter
