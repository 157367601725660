export default {
  "ccd.agent.button.signIn": "Sign In",
  "ccd.agent.button.signOut": "Sign Out",
  "ccd.agent.button.sayBusy": "Set Busy",
  "ccd.agent.button.sayFree": "Set Idle",
  "ccd.agent.button.rest": "Rest",
  "ccd.agent.button.cancelRest": "Cancel Rest",
  "ccd.agent.button.restSkillQueue": "Reset Skills",
  "ccd.agent.button.queryStatus": "Query Status",
  "ccd.agent.button.transfer": "Transfer",
  "ccd.agent.button.hold": "Hold",
  "ccd.agent.button.getHold": "Cancel Hold",
  "ccd.agent.button.callOut": "Call Out",
  "ccd.agent.button.threeParty": "Three-Party",
  "ccd.agent.button.innerHelp": "Consult",
  "ccd.agent.button.mediaswitch": "Audio/Video Switch",
  "ccd.agent.button.mute": "Mute",
  "ccd.agent.button.twostage": "Dialpad",
  "ccd.agent.button.endMute": "Unmute",
  "ccd.agent.button.answer": "Answer",
  "ccd.agent.button.hangUp": "Close",
  "ccd.agent.button.screenShare": "Desktop sharing",
  "ccd.agent.button.notscreenShare": "Cancel Desktop Sharing",
  "ccd.agent.label.offline": "Offline",
  "ccd.agent.label.idle": "Idle",
  "ccd.agent.label.busy": "Busy",
  "ccd.agent.label.rest": "Rest",
  "ccd.agent.label.talk": "Talking",
  "ccd.agent.label.adjust": "Wrap-up",
  "ccd.agent.label.reply": "Reply",
  "ccd.agent.message.AgentIsEmpty": "The current account is not bound to an agent.",
  "ccd.agent.message.agentNoConfSkill": "The agent is not configured with a skill queue.",
  "ccd.agent.message.AgentIsSupend": "The current agent is frozen.",
  "ccd.agent.tips.confSuccess": "The configuration is successful.",
  "ccd.agent.tips.confFail": "Configuration failed.",
  "ccd.agent.tips.media.agentType.no.support.callout": "Multimedia agents cannot make outbound calls",
  "ccd.agent.tips.error": "Error",
  "ccd.agent.tips.success": "Success",
  "ccd.agent.tips.info": "Tips",
  "ccd.agent.tips.welcome": "Welcome back. Have a nice day!",
  "ccd.agent.webrtc.label.offline": "Unregistered",
  "ccd.agent.webrtc.label.online": "Registered",
  "ccd.agent.webrtc.label.talk": "Calling",
  "ccd.agent.webrtc.label.connetfailed": "Failed",
  "ccd.agent.webrtc.label.buildfailed": "Failed to set up the connection",
  "ccd.agent.webrtc.label.connect": "Register",
  "ccd.agent.webrtc.label.sippwd": "Softphone password",
  "ccd.agent.webrtc.label.unsupportbrower": "The browser does not support this function.",
  "ccd.agent.webrtc.label.missmic": "The microphone is unavailable.",
  "ccd.agent.webrtc.label.misspeaker": "The speaker is unavailable.",
  "ccd.agent.webrtc.label.videopop": "Video window",
  "ccd.agent.webrtc.label.sotpsendvideo": "Stop sending video",
  "ccd.agent.webrtc.label.hidelocalvideo": "Hide Local Video",
  "ccd.agent.webrtc.label.videosize": "Video size",
  "ccd.agent.webrtc.label.large": "large",
  "ccd.agent.webrtc.label.medium": "medium",
  "ccd.agent.webrtc.label.small": "small",
  "ccd.agent.webrtc.label.localvideo": "Local video",
  "ccd.agent.webrtc.label.remotevideo": "Remote video",
  "ccd.agent.webrtc.label.accept": "Answer",
  "ccd.agent.webrtc.label.reject": "Reject",
  "ccd.agent.webrtc.label.logoutregistered": "Whether to log out.",
  "ccd.agent.webrtc.label.passwordvalidator": "The length of the entered character exceeds the value range. The length must be less than or equal to 50 characters.",
  "ccd.agent.webrtc.label.busy.tip": "Chamada",
  "ccd.agent.webrtc.label.busy.info": "Hints",
  "ccd.agent.webrtc.label.busy.confirm": "Confirm",
  "ccd.agent.webrtc.label.button.yes": "Yes",
  "ccd.agent.webrtc.label.button.no": "No",
  "ccd.agent.webrtc.label.sippwd.placeholder": "Enter the softphone password.",
  "ccd.agent.webrtc.label.remote.name": "Other party",
  "ccd.agent.webrtc.label.local.name": "Agent",
  "ccd.agent.webrtc.label.changeVoice": "Transfer to voice",
  "ccd.agent.agentevent.success": "Success",
  "ccd.agent.agentevent.failed": "Failed",
  "ccd.agent.agentevent.warning": "Warning",
  "ccd.agent.agentevent.info": "Tips",
  "ccd.agent.agentevent.AgentState_Force_SetNotReady": "The agent set busy successfully.",
  "ccd.agent.agentevent.AgentState_CancelNotReady_Success": "The agent canceled busy successfully.",
  "ccd.agent.agentevent.AgentState_Ready": "The agent set idle successfully.",
  "ccd.agent.agentevent.AgentState_CancelWork_Success": "The agent exited the working state successfully.",
  "ccd.agent.agentevent.AgentState_SetRest_Success": "The agent rested successfully.",
  "ccd.agent.agentevent.AgentState_CancelRest_Success": "The agent canceled the rest successfully.",
  "ccd.agent.agentevent.AgentState_SetWork_Success": "The agent enters the working state.",
  "ccd.agent.agentevent.AgentState_Work": "The agent enters the wrap-up state.",
  "ccd.agent.agentevent.AgentState_Busy": "The agent is working",
  "ccd.agent.agentevent.AgentEvent_Customer_Alerting": "The phone rings.",
  "ccd.agent.agentevent.AgentEvent_Ringing": "The agent incoming call",
  "ccd.agent.agentevent.AgentEvent_Hold": "The call is held successfully.",
  "ccd.agent.agentevent.AgentEvent_Hold.desc": "The agent is already on hold",
  "ccd.agent.agentevent.AgentEvent_Talking": "The agent entered the Talking state.",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.success": "Audio/video switchover successfully.",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.failed": "Audio/video switchover failed.",
  "ccd.agent.agentevent.AgentMediaEvent_Play": "Recording playback starts.",
  "ccd.agent.agentevent.AgentMediaEvent_PausePlayDone": "The recording playback is paused successfully.",
  "ccd.agent.agentevent.AgentMediaEvent_ResumePlayDone": "The recording playback is resumed successfully.",
  "ccd.agent.agentevent.AgentMediaEvent_JumpPlayDone": "The recording is fast-forwarded or rewinded successfully.",
  "ccd.agent.agentevent.AgentMediaEvent_StopPlayDone": "Recording playback stopped.",
  "ccd.agent.agentevent.AgentEvent_Conference": "The three-party call is successful.",
  "ccd.agent.agentevent.AgentEvent_Call_Out_Fail": "The agent fails to initiate an external call.",
  "ccd.agent.agentevent.AgentEvent_Inside_Call_Fail": "The agent fails to initiate an internal call.",
  "ccd.agent.agentevent.AgentEvent_Call_Release": "Agent exits the call",
  "ccd.agent.agentevent.AgentConf_SelfJoinResult": "Agent Joining a Conference",
  "ccd.agent.agentevent.AgentEvent_No_Answer": "The agent does not answer the call for a long time.",
  "ccd.agent.agentevent.AgentEvent_Customer_Release": "The customer exits the call.",
  "ccd.agent.agentevent.AgentEvent_Auto_Answer": "Agent auto answer",
  "ccd.agent.agentevent.AgentEvent_Connect_Fail": "Connection Failure",
  "ccd.agent.agentevent.AgentEvent_Consult_Fail": "Agent consulting Failure",
  "ccd.agent.agentevent.AgentEvent_ReturnFromIvr": "The agent is successfully returned from the IVR.",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut": "Previewing the outgoing call succeeded.",
  "ccd.agent.agentevent.AgentEvent_Preview": "The agent receives the preview request successfully.",
  "ccd.agent.agentevent.AgentEvent_Agent_Occupyed": "The agent has already been preempted",
  "ccd.agent.agentevent.AgentEvent_Release_Preview": "The preview call is released successfully.",
  "ccd.agent.agentevent.AgentOther_ShutdownService": "The agent signed out successfully.",
  "ccd.agent.agentevent.AgentOther_InService": "The agent signed in successfully.",
  "ccd.agent.agentevent.AgentOther_PhoneAlerting": "The agent is ringing",
  "ccd.agent.agentevent.AgentOther_PhoneOffhook": "The agent went off-hook",
  "ccd.agent.agentevent.AgentOther_PhoneRelease": "The agent hung up",
  "ccd.agent.agentevent.AgentOther_PhoneUnknow": "The phone status of agent is unknown.",
  "ccd.agent.agentevent.AgentOther_All_Agent_Busy": "All agents are busy.",
  "ccd.agent.agentevent.AgentOther_Restskill_Queue": "Skill queue operation succeeded.",
  "ccd.agent.agentevent.callType": "Call type:",
  "ccd.agent.agentevent.callId": "Call ID:",
  "ccd.agent.agentevent.type.main": "main call",
  "ccd.agent.agentevent.type.assistant": "assist call",
  "ccd.agent.agentevent.otherPhone": "Phone Number/Work ID of the Ringing Party:",
  "ccd.agent.agentevent.feature.caller": "Caller Number:",
  "ccd.agent.agentevent.feature.called": "Called Number:",
  "ccd.agent.agentevent.feature.callin": "Common incoming call",
  "ccd.agent.agentevent.feature.callout": "Common outgoing call",
  "ccd.agent.agentevent.mediaAblity": "Media Type:",
  "ccd.agent.agentevent.mediaAblity.voice": "audio",
  "ccd.agent.agentevent.mediaAblity.video": "video",
  "ccd.agent.agentevent.mediaAblity.voiceandvideo": "audio and video",
  "ccd.agent.agentevent.mediaAblity.text": "text",
  "ccd.agent.agentevent.AgentEvent_Conference.partner": "Caller number:",
  "ccd.agent.agentevent.AgentEvent_No_Answer.number": "Hang-up party number:",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.controlid": "Preview outgoing call control block ID:",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.dialeddigits": "Preview outgoing called number:",
  "ccd.agent.flash.calling": "Calling",
  "ccd.agent.flash.message": "Message",
  "ccd.agent.openeye.deamonready": "The OpenEye is started.",
  "ccd.agent.openeye.deamonready.failinfo": "Failed to start the OpenEye.",
  "ccd.agent.openeye.deamonready.fail": "Failed to start the OpenEye. Manually log in to the OpenEye. the automatic answering function cannot be used.",
  "ccd.agent.query.sip.fail": "Failed to query SIP data. Sign in as an agent again or manually log in to the OpenEye.",
  "ccd.agent.openeye.sipconfig.success": "The SIP is configured successfully.",
  "ccd.agent.openeye.poolsipconfig.success": "The POOL is configured successfully.",
  "ccd.agent.openeye.sipconfig.fail": "Failed to configure the SIP. Sign in as an agent again or manually log in to the OpenEye.",
  "ccd.agent.openeye.poolsipconfig.fail": "Failed to configure the POOL. Sign in as an agent again or manually log in to the OpenEye.",
  "ccd.agent.openeye.register": "The softphone number is registered successfully.",
  "ccd.agent.openeye.already.register": "The softphone number has been registered.",
  "ccd.agent.openeye.register.fail": "The softphone number is already registered, or other reasons.",
  "ccd.agent.openeye.deregister": "The softphone number is deactivated successfully.",
  "ccd.agent.openeye.deregister.fail": "Failed to deactivate the softphone number. Manually log out of the OpenEye.",
  "ccd.agent.openeye.autoanswer": "The call is answered automatically.",
  "ccd.agent.openeye.autoanswer.fail": "Failed to answer the call automatically.",
  "ccd.agent.openeye.videowindowparam": "The video parameters are initialized successfully.",
  "ccd.agent.openeye.videowindowparam.fail": "Failed to initialize video parameters. As a result, the video call function is abnormal.",
  "ccd.agent.openeye.videolayoutmode.success": "Succeeded in setting the image arrangement mode of the video window.",
  "ccd.agent.openeye.videolayoutmode.fail": "Failed to set the image arrangement mode of the video window.",
  "ccd.agent.openeye.videodisplaymode.success": "Succeeded in setting the cropping mode of the video window.",
  "ccd.agent.openeye.videodisplaymode.fail": "Failed to set the cropping mode of the video window.",
  "ccd.agent.frontpage.callreason": "Call Reason",
  "ccd.agent.callrelease.end": "Call Ended",
  "ccd.agent.resetphonepass.inputcur": "Current account password",
  "ccd.agent.resetphonepass.input": "Password",
  "ccd.agent.resetphonepass.inputagain": "Confirm password",
  "ccd.agent.openeye.share": "Please select...",
  "ccd.ccaction.title.shareapp": "Select Shared Program",
  "ccd.agent.openeye.switchshare.success": "The desktop sharing function is enabled successfully.",
  "ccd.agent.openeye.switchshare.fail": "Failed to enable the desktop sharing function.",
  "ccd.agent.openeye.getapplist.fail": "Failed to obtain the opened application list.",
  "ccd.agent.openeye.setshareapp.fail": "Failed to set screen sharing.",
  "ccd.agent.openeye.startshare.fail": "Failed to start sharing.",
  "ccd.agent.openeye.stopshare.fail": "Failed to stop sharing.",
  "ccd.agent.button.offlineSignOut": "Exit Mobile",
  "ccd.agent.button.offlineSignIn": "Shift Mobile",
  "ccd.agent.button.ok": "OK",
  "ccd.agent.button.close": "Cancel",
  "ccd.agent.message.phone": "Called-to number",
  "ccd.agent.login.fail": "registration failed.",
  "ccd.agent.login.failinfo": ". As a result, the internal help function is unavailable.",
  "ccd.agent.login.multimedia.failinfo": ". As a result, the voice and video call, and internal help functions are unavailable.",
  "ccd.agent.reset.success": "Rest reservation succeeded.",
  "ccd.agent.openeye.initsuccess": "The OpenEye is initialized successfully.",
  "ccd.agent.pagination.total": "Total:",
  "ccd.agent.title.consult": "Consult",
  "ccd.agent.warning.sharescreen": "Pay attention to personal privacy when sharing the desktop",
  "ccd.agentmonitor.span.listening": "Listening",
  "ccd.agentmonitor.span.insert": "Inserting",
  "ccd.agentmonitor.span.whisper": "Whispering",
  "ccd.message.title.audioIncomingCallNotify": "Audio and video incoming call notification",
  "ccd.message.title.mediaIncomingCallNotify": "Multimedia incoming call notification",
  "ccd.message.title.mediaNewMsgNotify": "New multimedia message notification",
  "ccd.agentMonitor.label.switchlistenorinsert": "In the pre-inspection state, an agent who is whispered cannot click the Listen or Insert button.",
  "ccd.agent.agentevent.quality.calloutconnectfail": "Cannot make an outgoing call because the QC is in progress.",
  "ccd.agent.agentevent.calloutconnectfail": "The subscriber is busy or the number does not exist.",
  "ccd.agent.tips.maxCountQuery.info": "The current data volume is too large. The system displays only the latest {0} data records.",
  "ccd.media.tips.new.online.customer": "You have a new online customer",
  "ccd.agent.warning.notification.cancel": "Cancel",
  "ccd.agent.warning.notification.setting": "Settings",
  "ccd.agent.warning.notification.allowed": "To ensure session message alerts, the website needs to be allowed to display notifications!",
  "ccd.agent.msg.notification.allowed": "AICC Website Request Allow Browser to Display Notifications!",
  "ccd.agent.msg.notification.permission.denied": "user rejects the authorization and cannot display the notification. Please modify the settings in the browser.",
  "ccd.agent.msg.queryMsg.specialListQuery": "Special List Reminder",
  "ccd.agent.message.AgentIsProhibited": "The rental has been banned.",
  "ccd.agent.message.repeatPhonenum": "The phone number used by the agent to sign in has been used by another agent to sign in. Check the configuration.",
  "ccd.agent.message.destinationAgentFailed": "Failed to occupy the destination agent.",
  "ccd.agent.message.noAgent": "No agent on duty.",
  "ccd.agent.warning.waserror": "WAS resource error cannot be accessed or an internal error occurs.",
  "ccd.agent.message.eventMethodErr": "The method for obtaining the agent event is incorrect.",
  "ccd.agent.message.restInvalid": "The parameter or address transferred through the URL does not comply with the definition.",
  "ccd.agent.message.restNoRight": "The agent has no permission to invoke the interface. The agent may have been forced to sign out.",
  "ccd.agent.message.invalidWasURL": "The WAS link path configured on the server is incorrect.",
  "ccd.agent.message.loginInvalidPara": "Sign-in failed. The sign-in parameter is empty or invalid.",
  "ccd.agent.message.userOrPasswdInvalid": "Sign-in failed. The account or password is incorrect.",
  "ccd.agent.message.logOutInvalidParam": "Sign-in failed. The parameter is invalid.",
  "ccd.agent.message.AgentAlreadyLogout": "The agent has signed out.",
  "ccd.agent.message.loginInvalidParam": "The phone number is invalid or has been signed in by another agent.",
  "ccd.agent.message.AgentInnerError": "Operation failed. An internal error may occur, or no resource is available, or the agent is not idle.",
  "ccd.agent.message.setStatErr": "Failed to set agent status.",
  "ccd.agent.message.logOutStatusErr": "Sign-out failed. You may have a pending business to handle.",
  "ccd.agent.message.callOutPhoneErr": "The outgoing call number is invalid.",
  "ccd.agent.message.answerNoCall": "No call to answer.",
  "ccd.agent.message.beginMuteNoCall": "No call for muting.",
  "ccd.agent.message.beginMuteStateErr": "A call state error occurs when you mute yourself.",
  "ccd.agent.message.connectHoldNoCall": "No call to hold.",
  "ccd.agent.message.noHoldCall": "The call is not held.",
  "ccd.agent.message.endMuteNoCall": "No call exists when you cancel muting.",
  "ccd.agent.message.endMuteStateErr": "A call state error occurs when you cancel muting.",
  "ccd.agent.message.threePartyNoCall": "No call for you to initiate a three-party call.",
  "ccd.agent.message.threePartyNoHoldCall": "No call is held to initiate a three-party call.",
  "ccd.agent.message.transferNoCall": "No call to transfer.",
  "ccd.agent.message.holdNoCall": "No call to hold.",
  "ccd.agent.message.holdCallStatusErr": "A call state error occurs when you hold the call.",
  "ccd.agent.message.getHoldNoHoldCall": "No call to unhold.",
  "ccd.agent.message.getHoldCallStatusErr": "A call state error occurs when you unhold the call.",
  "ccd.agent.message.releaseNoCall": "No call to hang up.",
  "ccd.agent.message.insultNoCall": "No call for Consult.",
  "ccd.agent.message.releaseNumberNoCall": "No call to release a specified phone number.",
  "ccd.agent.message.mediaSwitchNoCall": "No call is made during audio/video switching.",
  "ccd.agent.message.mediaSwitchSuccess": "Audio/video switching Success.",
  "ccd.agent.message.mediaSwitchFailed": "Audio/video switching Failed.",
  "ccd.agent.message.releaseNumberNoNumber": "No call of the phone number to release.",
  "ccd.agent.message.loginInvalidPhone": "Invalid phone number.",
  "ccd.agent.message.InvalidNumber": "Invalid number.",
  "ccd.agent.message.accoutnLocked": "The account is locked. Sign in later.",
  "ccd.agent.message.agentNotLogin": "The agent does not sign in.",
  "ccd.agent.callout.notrest": "Cancel the break and then make an outbound call.",
  "ccd.agent.message.monitorSelf": "The inspected agent and inspector cannot be the same.",
  "ccd.agent.message.hangupnocall": "The call cannot be hung up. If the call is on hold, Please cancel hold first.",
  "ccd.agent.message.default": "Internal error. Check the log.",
  "ccd.agent.message.wait": "The call is being processed. Please try again later.",
  "ccd.agent.message.reset": "Too many call processing failures, trying to synchronize agent status",
  "ccd.agent.videocall.paramError": "Parameter error when adjusting the display mode of a three-party video call.",
  "ccd.agent.videocall.noCall": "No call error occurs when the display mode of a three-party video call is adjusted.",
  "ccd.agent.videocall.typeError": "The call type is incorrect when the display mode is adjusted for a three-party video call.",
  "ccd.agent.videocall.stateError": "The call status is incorrect when the display mode is adjusted for a three-party video call.",
  "ccd.agent.videocall.displayError": "An exception occurs when the presentation mode of a three-party video call is adjusted.",
  "ccd.agent.previewcall.invalParam": "The preview outgoing call parameters are incorrect.",
  "ccd.agent.videocall.adjustvideo": "Adjusting Video Size",
  "ccd.cccbar.label.inputNumber": "Enter a number.",
  "ccd.cccbar.label.voice": "Audio",
  "ccd.cccbar.label.video": "Video",
  "ccd.cccbar.label.cancel": "Cancel",
  "ccd.cccbar.label.send": "Send",
  "ccd.cccbar.label.yourself": "Myself",
  "ccd.cccbar.label.nocall": "No call.",
  "ccd.cccbar.label.callout": "Call",
  "ccd.cccbar.label.callcenter": "Call Center",
  "ccd.cccbar.label.cccbar": "Connection Bar",
  "ccd.cccbar.label.empty": "Clear All",
  "ccd.cccbar.label.backspace": "Backspace",
  "ccd.cccbar.label.total": "Total:",
  "ccd.cccbar.message.calltip": "Hi, a new call is coming!",
  "ccd.cccbar.message.pleaseAnswer": "Answer the call.",
  "ccd.cccbar.message.syserrormessage": "System error.",
  "ccd.transfer.label.callTransfer": "Transfer Call",
  "ccd.transfer.label.skill": "Skill Queue",
  "ccd.transfer.label.transferToSkill": "Transfer to Skill Queue",
  "ccd.transfer.label.skillName": "Skill Queue",
  "ccd.transfer.label.queueSize": "Wait In Line",
  "ccd.transfer.label.queueSize.ccbar": "Wait In Line",
  "ccd.transfer.label.loggedOnAgents": "Available Agent",
  "ccd.transfer.label.loggedOnAgents.ccbar": "Available Agent",
  "ccd.transfer.label.transferType": "Transfer Type",
  "ccd.transfer.label.transferTypeColon": "Transfer Type:",
  "ccd.transfer.label.transferTypeColon.ccbar": "Transfer Type:",
  "ccd.transfer.label.agent": "Agent",
  "ccd.transfer.label.transferToAgent": "Transfer to Agent",
  "ccd.transfer.label.statusUnknow": "Unknown",
  "ccd.transfer.label.externalNumber": "External",
  "ccd.transfer.label.transferToExNumber": "Transfer to External Number",
  "ccd.transfer.label.successTransfer": "Success Transfer",
  "ccd.transfer.label.releaseTransfer": "Release Transfer",
  "ccd.transfer.label.talkingTransfer": "Conversation Transfer",
  "ccd.transfer.label.hangUpTransfer": "Hang-up Transfer",
  "ccd.transfer.label.threeParthTransfer": "Three-Party Transfer",
  "ccd.transfer.label.ivr": "IVR",
  "ccd.transfer.button.refresh": "Refresh",
  "ccd.transfer.button.cancel": "Cancel",
  "ccd.transfer.button.sure": "OK",
  "ccd.transfer.message.NoAgentInSkill": "No online agent exists in the current skill queue.",
  "ccd.transfer.message.NoIvrOnline": "No IVR flow is available.",
  "ccd.transfer.message.inputExNumber": "The number to be transferred",
  "ccd.transfer.message.refreshTab": "Do not repeatedly refresh the page.",
  "ccd.transfer.message.inputIvrName": "Enter an ivr for transfer.",
  "ccd.transfer.message.microsoftTeamsLogin": "Sign In",
  "ccd.transfer.message.inputTeamsUser": "Enter the team account.",
  "ccd.innerhelp.label.help": "Consult",
  "ccd.innerhelp.label.innerHelpToSkill": "Request Consult from Skill Queue",
  "ccd.innerhelp.label.innerHelpToAgent": "Request Consult from Agent",
  "ccd.innerhelp.label.twoPartyHelp": "Two-Party Consult",
  "ccd.innerhelp.label.threePartyHelp": "Three-Party Consult",
  "ccd.innerhelp.label.helpType": "Consult Type",
  "ccd.innerhelp.message.inputExNumhelp": "Enter a number for Consult",
  "ccd.callinner.label.callinner": "Internal Call",
  "ccd.agent.label.recordPlay": "Voice Playback",
  "ccd.agent.title.warning": "Alarm",
  "ccd.agent.message.misconnection": "Failed to invoke the session persistence interface.",
  "ccm.agent.message.selectIvr": "Select an IVR flow.",
  "ccd.agent.status.occupy": "Occupied",
  "ccd.agent.status.askAnswer": "Wait for Response",
  "ccd.agent.status.talk": "Talking",
  "ccd.agent.status.hold": "Hold",
  "ccd.agent.status.mute": "Mute",
  "ccd.agent.status.callout": "Outgoing Calling",
  "ccd.agent.status.threeParty": "Three-Party",
  "ccd.agent.status.help": "Consult",
  "ccd.agent.status.innerCall": "Internal Call",
  "ccd.agent.status.innerTwo": "Consult",
  "ccd.agent.status.innerThree": "Three-Party",
  "ccd.agent.message.checkAgentStatus": "Perform this operation when the inspector is idle.",
  "ccd.agent.message.needLogin": "Sign in as an agent and try again.",
  "ccd.agent.tips.restTooLong": "The rest times out.",
  "ccd.callout.message.numberFormat": "A phone number can contain only the digits, number sign (#), and asterisk (*), and contains a maximum of 24 characters.",
  "ccd.quality.message.noProvider": "The NOPROVIDER error occurs during quality check.",
  "ccd.quality.message.noAuth": "No permission exception occurs during quality check.",
  "ccd.quality.message.resourceUnable": "An exception occurs during the inspection. Check the relevant logs.",
  "ccd.quality.message.noAgentInfo": "No agent information is found during quality check monitoring.",
  "ccd.quality.message.statusErr": "The status is abnormal during quality check.",
  "ccd.quality.message.inspectInspectorErr": "The inspected agent cannot be an inspector.",
  "ccd.record.message.jumpBack": "A state error occurs during rewinding.",
  "ccd.record.message.jumpForw": "A state error occurs during forwarding.",
  "ccd.record.message.pausePlay": "A state error occurs when playback pauses.",
  "ccd.record.message.pauseRecord": "A state error occurs when recording pauses.",
  "ccd.record.message.resumePlay": "A state error occurs when playback continues.",
  "ccd.record.message.resumeRecord": "A state error occurs when recording continues.",
  "ccd.record.message.startPlay": "A state error occurs when playback starts.",
  "ccd.record.message.startRecord": "A state error occurs when recording starts.",
  "ccd.record.message.stopPlay": "A state error occurs when playback stops.",
  "ccd.quality.message.qualityTitle": "Quality Check",
  "ccd.quality.message.listen": "Listening",
  "ccd.quality.message.stopListen": "Stop Listen",
  "ccd.quality.message.insert": "Insert",
  "ccd.quality.message.stopInsert": "Stop Insert",
  "ccd.quality.message.switch": "Switch",
  "ccd.quality.message.intercept": "Intercept",
  "ccd.quality.message.whisper": "Whisper",
  "ccd.quality.message.cancelwhisper": "Stop Whisper",
  "ccd.quality.message.forceexit": "Force Exit",
  "ccd.quality.message.forcerest": "Force Idle",
  "ccd.quality.message.forcebusy": "Force Busy",
  "ccd.quality.message.back": "Back",
  "ccd.quality.message.searchWorkno": "Please enter the agent ID to search.",
  "ccd.quality.message.interceptErr": "The agent to check is not in a call and cannot be intercepted.",
  "ccd.ccaction.message.retCode": "Error Cancel work when you set busy. Error code:",
  "ccd.ccaction.message.retMessage": ". Error message:",
  "ccd.ccaction.message.errorRetCode": "Error! Error code:",
  "ccd.ccaction.message.retMessEnd": ".",
  "ccd.ccaction.message.statusError": "No call to mute in the current state.",
  "ccd.ccaction.message.abilityError": "You cannot mute the user in the current media function.",
  "ccd.ccaction.message.unHold": "No call to hold in the current state.",
  "ccd.ccaction.message.holdError": "The current call cannot be held.",
  "ccd.ccaction.message.confJoinErr": "No call is held. A three-party call is not allowed.",
  "ccd.ccaction.message.confJoinFail": "The agent does not sign in, and the three-party call fails.",
  "ccd.ccaction.message.recording": "The voice can be played only when the agent is in idle state. Please change the agent status.",
  "ccd.ccaction.message.recordFail": "The agent does not sign in, and the voice playback fails.",
  "ccd.ccaction.message.repeateBusy": "The agent is busy now. Do not repeatedly set busy.",
  "ccd.ccaction.message.repeateRest": "The agent is rest. Do not force show busy.",
  "ccd.ccaction.message.isForceBusy": "The agent has been shown busy.Do not repeatedly show busy.",
  "ccd.ccaction.message.inputWorkNo": "The agent ID is empty. Please enter the agent ID.",
  "ccd.ccaction.message.inputIvrName": "The IVR flow name is empty. Please enter the IVR flow name.",
  "ccd.ccaction.message.logoutfailed": "Logout failed. Please check the agent status.",
  "ccd.ccaction.message.error": "Error",
  "ccd.ccaction.message.resignin": "Seat password is invalid, please check in again!",
  "ccd.ccaction.message.rest": "Rest",
  "ccd.ccaction.message.seconds": "seconds",
  "ccd.ccaction.message.minutes": "minutes",
  "ccd.ccaction.message.hour": "hours",
  "ccd.ccaction.message.search.restCauseDesc": "Enter the rest reason description.",
  "ccd.callmessage.workbench": "Audio and Video Workbench",
  "ccd.callmessage.chatOnlineWorkbench": "Online Chat Workbench",
  "ccd.callmessage.whiteNumberExist": "The number does not exist in the trustlist.",
  "ccd.agentstate.notTalking": "The selected agent is not in the talking state.",
  "ccd.outgoing.out.workbench": "Outbound Workbench",
  "ccd.agent.videomode.triangular": "Triangular",
  "ccd.agent.videomode.maximizeUser": "Maximize User",
  "ccd.agent.videomode.maximizeAgent": "Maximize Agent",
  "ccd.agent.videomode.maximizeThreeparty": "Maximize Threeparty",
  "ccd.agent.mediaswitch.audio": "Audio Call",
  "ccd.agent.mediaswitch.video": "Video Call",
  "ccd.agent.mediaswitch.switchaudiofailed": "Already in an audio call.",
  "ccd.agent.mediaswitch.switchvideofailed": "Already in a video call.",
  "ccd.agent.mediaswitch.unsupportedagenttype": "The current agent does not support video calls.",
  "ccd.agent.void.call": "Voice outbound call",
  "ccd.agent.chat.mutimedia.call": "Multimedia outbound call",
  "ccd.agent.chat.start": "Starting a session",
  "ccd.agent.chat.channle.choose": "Channel Selection",
  "ccd.login.message.logoutReason.sessionInvalid": "The session is invalid. Sign in again please.",
  "ccd.ccaction.message.registerPhone": "Register the softphone.",
  "ccd.ccaction.message.loginagent": "Sign in as an agent.",
  "ccd.contact.message.timeerror": "The start time must be earlier than the end time.",
  "ccd.contact.message.timerangeerror": "The time range cannot exceed {0} days.",
  "ccd.contact.message.timeisempty": "The time range cannot be empty.",
  "ccd.ccaction.message.registerPhoneFailed": "WebRTC registration fails. Currently, internal help functions are unavailable.",
  "ccd.agent.message.selectTeams": "Select a team account.",
  "ccd.skill.selectskill": "Select at least one skill queue.",
  "ccd.agent.message.qc.restskill": "The skill queue cannot be reset if an inspector does not answer an incoming call.",
  "ccd.agentMonitor.label.selectAgentOne": "Please end the agent that is being processed and select another agent operation.",
  "ccd.quality.message.qualityAgentTitle": "Agent Monitor",
  "ccd.agentMonitor.forceRelease.noAudio": "No audio or video call can be released.",
  "ccd.agentMonitor.intercept.noAudio": "No voice or video call can be intercepted.",
  "ccd.transfer.label.transferNumber": "Indicates whether the process is a self-service process.",
  "ccd.agent.forcesignout.confirm": "Signing out will affect the connection service that the agent is processing. Are you sure you want to forcibly sign out?",
  "ccd.ccagent.contract.preview": "Preview",
  "ccd.label.search": "Search",
  "ccd.label.org.all": "All accessible organizations",
  "ccd.title.call.three": "Three-way calling",
  'ccd.agent.message.calloutagent.restskill': 'The callout agent do not answer incoming calls and cannot reset skill queues.',
  "ccd.agent.message.videoLimit": "The video agent resources are insufficient, and the agent fails to sign in to the video media server.",
  "ccd.agent.message.withoutskill": "The skill queue is missing.",
  "ccdesktop.login.wertc.failed": "Webrtc soft phone registration failed",
  "ccd.agent.label.skillGroupOrSkill": "Skill Group/Skill Queue",
  "ccd.transfer.placeholder.agentName": "Enter agent name",
  "ccd.agent.videocall.displayWarn": "The video window size can be adjusted only when all three parties are playing videos.",
  "ccd.ccaction.message.nosupport": "This operation is not supported on the web page. Use Open Eye to mute.",
  "ccd.ccbar.before.signin.tips":"Before using the connection function, click to sign in.",
  "ccd.ccbar.calling.number":"Calling Number",
  "ccd.ccbar.mediaSwitch.failed":"Media switchover failed.",
  "ccd.ccbar.mediaSwitch.notsupport":"Audio and video switching is not allowed for internal calls in video mode on the MCU.",
  "ccd.webrtc.message.bad":"Badness",
  "ccd.webrtc.message.poor":"Poor",
  "ccd.webrtc.message.good":"Good",
  "ccd.webrtc.message.recive":"Receive",
  "ccd.webrtc.message.Packetloss":"Packet loss",
  "ccd.webrtc.message.delay":"Delay",
  "ccd.webrtc.message.jitter":"Jitter",
  "ccd.webrtc.message.send":"Send",
  "ccd.webrtc.message.signal":"Signal",
  'ccd.webrtc.message.medium':'Medium',
  'ccd.webrtc.login.success':'The softphone login is successful',
  'ccd.webrtc.login.fail':'Softphone login failure',
  'ccd.webrtc.login.fail.noPwd':'No initial password is available. Reset the softphone password and try again.',
  'ccd.webrtc.login.fail.pop.tips':'Failed to automatically log in to the softphone. You cannot use the voice function. Log in to the softphone again?',
  'ccd.webrtc.login.try':'Retry',
  'ccd.webrtc.login.ignore':'Ignore',
  'ccd.webrtc.login.fail.top.tips':'The softphone login is abnormal, and related functions such as voice cannot be used.',
  "ccd.ccbar.webrtc.network.signal.pool":"The current network signal is poor, which may affect the call.",
  "ccd.agentMonitor.label.switchwhisper": "You cannot click Whisper for an agent in pre-listening or per-insertion state.",
  'ccd.agent.webrtc.register.tip':'Register softphone?',
  "ccd.consult.message.two.inputExNumber": "Please enter the number for two-party consultation",
  "ccd.consult.message.three.inputExNumber": "Please enter the number for three-party consultation",
  "ccd.ccbar.mediaSwitch.sfunotsupport":"The current call does not support media switchover.",
  "ccd.agent.tips.welcome.agentlogout": "Welcome!",
  "ccd.agent.message.continuously.online":"The online service will be maintained after check-in. Please check out in time after work is completed.",
  "ccd.agent.vrpserviceaddress.isempty" : "The VRP server address is empty. Contact the system administrator to configure it.",
  "ccd.agent.initializeagentex.success" : "The agent registers with the VRC successfully. Your desktop will be monitored and your call will be screen-recorded. Please maintain a good working status.",
  "ccd.agent.initializeagentex.passworderror" : "Incorrect agent ID or password. Failed to register with the VRC.",
  "ccd.agent.initializeagentex.alreadyregistered" : "The agent has registered with the VRC.",
  "ccd.agent.vrpexe.closed" : "The VRC software is being shut down.",
  "ccd.agent.startMonitorEx.uninitialize" : "The vrc connection is invalid. Sign in to the current agent again.",
  "ccd.agent.startMonitorEx.qcnotexit" : "The QC object does not exist. Check whether the attendant is properly connected to the VRC.",
  "ccd.agent.startMonitorEx.qctimelimitexceed" : "The attendant connection to the vrc failed. Please sign in again.",
  "ccd.agent.startMonitorEx.qcunsigned": "The agent has not signed in. Check whether the agent has signed in or needs to sign in again.",
  "ccd.agent.startMonitorEx.othererror": "Failed to connect to the server. Contact maintenance personnel or try again later.",
}