import request from '@/utils/request'
import i18n from '@/lang/index'
import cookieUtils from "@/utils/cookie";

const $t = i18n.global.t

export const ALARM_INDEX_MAP = {
    '1': $t('oifde.ivrJourney.directRate'),
    '2': $t('oifde.ivrJourney.nonDirectRate'),
    '3': $t('oifde.ivrJourney.hangupRate'),
    '4': $t('oifde.ivrJourney.failRate'),
    '5': $t('oifde.ivrJourney.repeatRate'),
    '6': $t('oifde.ivrJourney.transfManualRate')
}

export const ALARM_COND_TYPE_MAP = {
    0: $t('oifde.ivrJourney.absoluteValue'),
    1: $t('oifde.ivrJourney.yoyValue')
}

export const ALARM_OP_TYPE_MAP = {
    1: $t('oifde.ivrJourney.highThan'),
    0: $t('oifde.ivrJourney.equal'),
    "-1": $t('oifde.ivrJourney.lessThen')
}

export const REMIND_TYPE_MAP = {
    0: $t('oifde.ivrJourney.sms'),
    1: $t('oifde.ivrJourney.email'),
    2: $t('oifde.ivrJourney.smsAndEmail')
}

export function queryIvrJourneyNum(params) {
    return request({
        url: '/oifde/rest/queryIvrJourneyNum',
        method: 'post',
        data: params
    })
}
export function queryIvrSuccessRate(params) {
    return request({
        url: '/oifde/rest/queryIvrSuccessRate',
        method: 'post',
        data: params
    })
}
export function queryIvrFailedRate(params) {
    return request({
        url: '/oifde/rest/queryIvrFailedRate',
        method: 'post',
        data: params
    })
}
export function queryIvrHangUpRate(params) {
    return request({
        url: '/oifde/rest/queryIvrHangUpRate',
        method: 'post',
        data: params
    })
}
export function queryIvrFlows(params) {
    return request({
        url: '/oifde/rest/queryflows',
        method: 'post',
        data: params
    })
}
export function queryTrafficIntervalOverView(params) {
    return request({
        url: '/oifde/rest/queryTrafficIntervalOverView',
        method: 'post',
        data: params
    })
}
export function queryTrafficDetailReport(params) {
    return request({
        url: '/oifde/rest/queryTrafficDetailReport',
        method: 'post',
        data: params
    })
}

/**
 * 查询节点趋势统计
 * @param data
 * @returns {*}
 */
export const queryNodeTrendAnalysis = data => {
    return request({
        url: '/oifde/rest/queryNodeTrendAnalysis',
        method: 'post',
        data
    })
}

export function queryRepeatRateNodeCompare(params) {
    return request({
        url: '/oifde/rest/queryNodeComparisonRepeatCallRate',
        method: 'post',
        data: params
    })
}

export function queryNodeComparisonHangupNum(params) {
    return request({
        url: '/oifde/rest/queryNodeComparisonHangupNum',
        method: 'post',
        data: params
    })
}

export function queryAllAccessFlow(params) {
    return request({
        url: '/oifde/rest/queryAllAccessFlow',
        method: 'post',
        data: params
    })
}

export function queryProcessAnalysisData(params) {
    return request({
        url: '/oifde/rest/queryProcessAnalysisData',
        method: 'post',
        data: params
    })
}

export function exportIndicators(params) {
    return request({
        url: '/oifde/rest/exportIndicators',
        method: 'post',
        data: params,
        responseType: 'blob'
    })
}
export function queryAlarmLog(params) {
    return request({
        url: '/oifde/rest/queryAlarmLog',
        method: 'post',
        data: params
    })
}

export function queryRepeatCallsByCallDstIdAndRange(params) {
    return request({
        url: '/oifde/rest/queryRepeatCallsByCallDstIdAndRange',
        method: 'post',
        data: params
    })
}

export function queryPlayContentByNodeId(params) {
    return request({
        url: '/oifde/rest/queryPlayContentByNodeId',
        method: 'post',
        data: params
    })
}
export function queryFlowIdByNodeId(params) {
    return request({
        url: '/oifde/rest/queryFlowIdByNodeId',
        method: 'post',
        data: params
    })
}

export function queryNodeSequential(params) {
    return request({
        url: '/oifde/rest/queryNodeSequential',
        method: 'post',
        data: params
    })
}

export function queryKeyStatistics(params) {
    return request({
        url: '/oifde/rest/queryKeyStatistics',
        method: 'post',
        data: params
    })
}

export function queryAlarmConfig(params) {
    return request({
        url: '/oifde/rest/queryAlarmConfig',
        method: 'post',
        data: params
    })
}

export function addAlarmConfig(params) {
    return request({
        url: '/oifde/rest/addAlarmConfig',
        method: 'post',
        data: params
    })
}

export function editAlarmConfig(params) {
    return request({
        url: '/oifde/rest/editAlarmConfig',
        method: 'post',
        data: params
    })
}

export function batchDeleteAlarmConfig(params) {
    return request({
        url: '/oifde/rest/batchDeleteAlarmConfig',
        method: 'post',
        data: params
    })
}

export function queryFlowsByflowIdList(params) {
    return request({
        url: '/oifde/rest/queryFlowsByflowIdList',
        method: 'post',
        data: params
    })
}

// 根据 tenant_space_id，orgId查询座席信息
export function queryAgentsInfoFromSum() {
    const tenantId = cookieUtils.getCookie('tenant_space_id')
    const url = '/service-cloud/rest/sum/v2/tenantSpaces/' + tenantId + '/users';
    return request({
        url,
        method: 'get'
    })
}

export function queryGatewayConfigByCondition(params) {
    return request({
        url: '/service-cloud/rest/ccnotification/v1/gatewayConfig/queryGatewayConfigByCondition',
        method: 'post',
        data: params
    })
}
