export default {
  "ikbs.constableTmp.config" :"Konfigurieren",
  "ikbs.constableTmp.status.draft" :"Entwurf",
  "ikbs.cntTmp.import.rowNumOver" :"Es können maximal 10 Datensätze importiert werden.",
  "ikbs.column.unlock.message.warning" :"Informationen",
  "ikbs.knowledge.label.abstract" :"Zusammenfassung",
  "ikbs.properties.add.information" :"Erweitertes Attribut hinzufügen",
  "ikbs.contTmp.exception.maxCountPerTenant" :"Ein Mandant kann maximal 500 Vorlagen haben.",
  "ikbs.knowledge.properties.operation.upMove" :"Auf",
  "ikbs.column.delete.selectNoColumnKngToDelete" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.tips.canNotAddColumnKngOnHistoryColumn" :"Wissen kann der Verlaufskategorie nicht hinzugefügt werden.",
  "ikbs.knowledge.properties.label.inputWay" :"Eingabetyp",
  "ikbs.contentTmp.exception.tmpContentAndEditStandard" :"Der Vorlageninhalt und die Bearbeitungsspezifikationen dürfen nicht gleichzeitig leer sein.",
  "ikbs.knowledge.label.uploadSingleFile" :"Es kann jeweils nur ein Anhang hochgeladen werden.",
  "ikbs.knowledge.tip.operateSuccess" :"Vorgang erfolgreich.",
  "ikbs.constableTmp.operator.lt" :"kleiner als",
  "ikbs.enter.search.condition" :"Bitte geben Sie die Suchbedingung ein",
  "ikbs.search.label.sortByDefault" :"Standardsortierung",
  "ikbs.knowledge.recycle.coluKngType" :"Typ",
  "ikbs.consult.table" :"Konsultationstisch",
  "ikbs.knowledge.label.path" :"Pfad",
  "ikbs.column.release.message.fail" :"Veröffentlichung fehlgeschlagen.",
  "ikbs.column.knowledge.columnInformation" :"Kategorie hinzufügen",
  "ikbs.constableTmp.operator.le" :"kleiner oder gleich",
  "ikbs.knowledge.service.common" :"Allgemein",
  "ikbs.knowledge.recycle.select.knowledgeType" :"Wissenstyp",
  "ikbs.tips.propertyDictValueAtLeastOne.warning" :"Das erweiterte Attribut muss mindestens einen Wörterbuchwert haben.",
  "ikbs.column.displayflag.hide" :"Nein",
  "ikbs.column.knowledge.button.add" :"Neu",
  "ikbs.column.knowledge.modify.properties" :"Attribute im Stapel ändern",
  "ikbs.knowledge.release.public" :"Direkt veröffentlichen",
  "ikbs.knowledge.label.selectProperty" :"Auswählen",
  "ikbs.contentTmp.status.draft" :"Entwurf",
  "ikbs.knowledge.label.serviceStatus" :"Status des Unternehmens",
  "ikbs.knowledge.service.update" :"Aktualisiertes Geschäft",
  "ikbs.column.serviceStatus.newService" :"Neues Geschäft",
  "ikbs.knowledge.label.answer" :"Antworten",
  "ikbs.constableTmp.reset" :"Zurücksetzen",
  "ikbs.knowledge.label.modifyTime" :"Letzte Aktualisierung am",
  "ikbs.column.type.historyMark" :"Geschichte",
  "ikbs.kngCnt.cntTmp.icon.tip" :"Inhaltsvorlage",
  "ikbs.knowledge.label.currentVersion" :"Aktuelle Version",
  "ikbs.columntype.column" :"Kategorie",
  "ikbs.enter.search.keywords" :"Geben Sie einen Suchbegriff ein",
  "ikbs.contentTmp.delete.fail" :"Löschen fehlgeschlagen",
  "ikbs.column.label.coluKngMaintaining" :"Wartungsgruppe",
  "ikbs.knowledge.label.kngAttachmentDownload" :"Wissenskategorie-Anhang herunterladen",
  "ikbs.search.label.updateEndTime" :"Endzeit der Aktualisierung",
  "ikbs.knowledgeaudit.label.pass" :"Genehmigen",
  "ikbs.knowledge.tip.selectColumn" :"Wählen Sie eine Kategorie aus.",
  "ikbs.constableTmp.searchFlag" :"Suchkriterium oder nicht",
  "ikbs.properties.add.cancel" :"Abbrechen",
  "ikbs.column.needAudit.yes" :"Ja",
  "ikbs.knowledge.properties.operation.enable" :"Aktivieren",
  "ikbs.column.type.normal" :"Normale Kategorie",
  "ikbs.knowledge.label.passProcecWay" :"Nach Ablauf",
  "ikbs.column.knowledge.MoveType" :"Position der Justage",
  "ikbs.confirm.delete.by.select" :"Möchten Sie den ausgewählten Datensatz wirklich löschen?",
  "ikbs.knowledge.label.relaKngs" :"Wissensverband",
  "ikbs.constableTmp.fieldName" :"Feld",
  "ikbs.column.copy.message.warning" :"Informationen",
  "ikbs.column.deletePermanently.message.comfirm" :"Bestätigen",
  "ikbs.column.moveto.front" :"Vorderseite von",
  "ikbs.kbshome.button.orderByTime" :"Zeit",
  "ikbs.constableTmp.moveDown" :"Unten",
  "ikbs.tips.canNotAddKngOnRootColumn" :"Wissen kann nicht zur Stammkategorie hinzugefügt werden.",
  "ikbs.column.knowledge.button.up" :"Auf",
  "ikbs.constableTmp.modify" :"Ändern",
  "ikbs.constableTmp.operator.gt" :"größer",
  "ikbs.constableTmp.operator.include" :"einschließen",
  "ikbs.knghisverman.label.revert" :"Wiederherstellen",
  "ikbs.constableTmp.plsSelectModifyBeginTime" :"Startzeit der Änderung festlegen",
  "ikbs.knowledgeaudit.label.submitter" :"Gesendet von",
  "ikbs.column.label.kngExtended" :"Erweitertes Attribut",
  "ikbs.constableTmp.operator.ge" :"größer oder gleich",
  "ikbs.knowledge.label.listProp" :"Dropdown-Liste",
  "ikbs.knowledgeaudit.title.auditor" :"Geprüft von",
  "ikbs.knowledge.recycle.pleaseSelect" :"-Auswahl-",
  "ikbs.constableTmp.tab.addTitle" :"Vorlage wird hinzugefügt",
  "ikbs.knghisverman.tab.title.confirm" :"Bestätigen",
  "ikbs.column.knowledge.button.move" :"Verschieben",
  "ikbs.favorite.msg.plsSelectDir" :"Wählen Sie das zu löschende Verzeichnis aus.",
  "ikbs.knowledge.tip.fileNameError" :"Dateiname konnte nicht verifiziert werden.",
  "ikbs.knowledge.message.uploadImgSizeError" :"Die Image-Größe darf 10 MB nicht überschreiten.",
  "ikbs.knowledge.display.no" :"Nein",
  "ikbs.properties.inputway.select" :"Dropdown-Liste",
  "ikbs.favorite.title.afterModifiedKngName" :"Nach der Änderung",
  "ikbs.contentTmp.status" :"Vorlagenstatus",
  "ikbs.column.topMove.message.warning" :"Informationen",
  "ikbs.constableManage.fieldOprator" :"Bediener",
  "ikbs.constableTmp.modifyEndTime" :"Endzeit der Änderung",
  "ikbs.cntTmp.import.noData" :"Die hochgeladene Datei enthält keine zu importierenden Daten.",
  "ikbs.addtype.label.knowledge" :"Wissen hinzufügen",
  "ikbs.contentTemp.export.tempname" :"Vorlagenname",
  "ikbs.constableTmp.operator.eq" :"gleich",
  "ikbs.knowledge.tip.success" :"Erfolg",
  "ikbs.knowledge.label.radioProp" :"Radiobox",
  "ikbs.intelligent.search" :"Intelligente Suche",
  "ikbs.contentTmp.contentName" :"Vorlagenname",
  "ikbs.kbshome.tab.home" :"Startseite der Knowledge Base",
  "ikbs.column.passProcessWay.mark" :"Markieren",
  "ikbs.column.modify.modifyValidTimeCheckError" :"Die Startzeit des Gültigkeitszeitraums darf nicht nach der Endzeit des Gültigkeitszeitraums liegen.",
  "ikbs.constableTmp.delete.selectTmp.confirm" :"Möchten Sie wirklich löschen?",
  "ikbs.knowledge.recycle.beyondColuPath" :"Verzeichnis",
  "ikbs.favorite.msg.canNotSelectRoot" :"Das Stammverzeichnis kann nicht gelöscht werden. Wählen Sie einen anderen aus.",
  "ikbs.column.knowledge.button.modify" :"Ändern",
  "ikbs.knowledgeaudit.message.plsSelectBeginTime" :"Wählen Sie die Startzeit der Aufgabe aus.",
  "ikbs.contentTmp.tab.addTitle" :"Vorlage wird hinzugefügt",
  "ikbs.column.clearRecycle.comfirm" :"Möchten Sie den Papierkorb wirklich leeren?",
  "ikbs.knowledgeaudit.message.plsSelectEndTime" :"Wählen Sie die Endzeit der Aufgabe aus.",
  "ikbs.contTmp.importTmp.popup.title" :"Vorlagenimport",
  "ikbs.knowledge.label.uploadFileTypeError" :"Der Anhang kann nur im DOC-, DOCX-, PPT-, PPTX-, XLS-, XLSX-, PDF-, PNG-, JPG- oder GIF-Format erstellt werden.",
  "ikbs.column.modify.selectModifyColumnKngNumError" :"Es kann nur ein Wissenselement zur Änderung ausgewählt werden.",
  "ikbs.column.knowledge.create" :"Neu",
  "ikbs.confirm.delete" :"Möchten Sie diesen Datensatz wirklich löschen",
  "ikbs.column.operation.message.warning" :"Informationen",
  "ikbs.contentTmp.copy" :"Kopieren",
  "ikbs.contentTmp.modifyEndTime" :"Geändertes Ende",
  "ikbs.column.label.passProcessWay" :"Nach Ablauf",
  "ikbs.knowledgeaudit.label.auditStatus" :"Status",
  "ikbs.favorite.tab.message.isDeleteFavoriteKng" :"Möchten Sie das Wissenselement wirklich aus den Favoriten löschen?",
  "ikbs.select.consultation.form" :"Bitte wählen Sie eine Konsultationstabelle aus",
  "ikbs.knowledge.label.sendEmail" :"E-Mail senden",
  "ikbs.tips.operation.success" :"Vorgang erfolgreich.",
  "ikbs.column.knowledge.KngStatus" :"Status",
  "ikbs.contentTmp.add" :"Hinzufügen",
  "ikbs.properties.edit.information" :"Erweitertes Attribut bearbeiten",
  "ikbs.column.knowledge.title.moveTo" :"Anpassen an",
  "ikbs.column.knowledge.button.more" :"Mehr",
  "ikbs.knowledge.properties.operation.downMove" :"Unten",
  "ikbs.kbshome.label.kngBase" :"Wissensdatenbank",
  "ikbs.confirm.delete.by.condition" :"Möchten Sie Datensätze wirklich auf Grundlage von Bedingungen löschen?",
  "ikbs.column.serviceStatus.normal" :"Allgemein",
  "ikbs.constableTmp.comfirm" :"Bestätigen",
  "ikbs.tips.moveColumnKnowledgeOperation.warning" :"Es wurde keine Kategorie ausgewählt.",
  "ikbs.column.serviceStatus.updateService" :"Aktualisiertes Geschäft",
  "ikbs.knowledge.tip.cannotCompare" :"Es können nur zwei gängige Wissenselemente für den Vergleich ausgewählt werden",
  "ikbs.cntTmp.import.importSuccess" :"Import erfolgreich.",
  "ikbs.column.label.coluType" :"Kategorietyp",
  "ikbs.knowledge.label.submit" :"Senden",
  "ikbs.knowledge.message.uploadFileSizeError" :"Die Dateigröße darf 10 MB nicht überschreiten.",
  "ikbs.knowledge.label.deleteProblem" :"Fragen und Antworten löschen",
  "ikbs.knowledge.recycle.select.columnType" :"Kategorietyp",
  "ikbs.column.delete.property.comfirm" :"Möchten Sie das Attribut wirklich löschen?",
  "ikbs.knowledge.placeholder.keyword" :"Geben Sie Schlüsselwörter ein, die durch Semikolons getrennt sind (;).",
  "ikbs.kngCnt.cntTmp.popup.title" :"Inhaltsvorlage auswählen",
  "ikbs.knowledge.properties.operation.delete" :"Löschen",
  "ikbs.column.knowledge.compareAdd" :"Neu",
  "ikbs.knowledgeaudit.label.waitForAudit" :"Überprüfung ausstehend",
  "ikbs.constableTmp.cnstTblTmpDesc" :"Vorlagenbeschreibung",
  "ikbs.column.label.colukngname" :"Kategoriename",
  "ikbs.knowledgeaudit.label.batchAuditReject" :"Batch-Ablehnung",
  "ikbs.constableTmp.display.no" :"Nein",
  "ikbs.title.tips" :"Informationen",
  "ikbs.column.delete.selectNoColumn" :"Es wurde keine Kategorie ausgewählt.",
  "ikbs.column.knowledge.title.move" :"Verschieben",
  "ikbs.column.isInheritProp.no" :"Nein",
  "ikbs.column.message.plsSelectModifyEndTime" :"Wählen Sie eine Endzeit aus.",
  "ikbs.column.label.servicesVaildStartTime" :"Beginn des Geschäftsgültigkeitszeitraums",
  "ikbs.knowledge.properties.operation.edit" :"Bearbeiten",
  "ikbs.knowledge.pass.mark" :"Markieren",
  "ikbs.column.modify.modifyServiceTimeCheckError" :"Der Beginn der Gültigkeitsdauer des Geschäfts darf nicht vor dessen Ende liegen.",
  "ikbs.column.modify.properties.batch.message.warning" :"Informationen",
  "ikbs.cntTmp.import.error.oversize" :"Die Dateigröße darf 20 MB nicht überschreiten.",
  "ikbs.knowledgeaudit.label.cancel" :"Abbrechen",
  "ikbs.search.label.keyword" :"Schlüsselwort",
  "ikbs.knghisverman.tab.message.deleteSuccess" :"Löschung erfolgreich.",
  "ikbs.search.label.sortByClick" :"Klicken",
  "ikbs.knowledge.tip.fileInjectionError" :"Dateiinhalt konnte nicht verifiziert werden.",
  "ikbs.knowledge.label.correlative" :"Zuordnen",
  "ikbs.column.needAudit.no" :"Nein",
  "ikbs.column.unlock.message.success" :"Entsperren erfolgreich.",
  "ikbs.konwledgetype.question" :"Fragen und Antworten",
  "ikbs.knowledge.service.timeout" :"Abgelaufen",
  "ikbs.column.topMove.message.success" :"Erfolgreich gepinnt.",
  "ikbs.contentTmp.Modifier" :"Geändert von",
  "ikbs.knowledgeaudit.label.error" :"Fehler",
  "ikbs.knowledge.exception.AnswerNum.error" :"Auf eine Frage können nicht mehr als 10 Antworten gegeben werden.",
  "ikbs.knowledgeaudit.title.submitTime" :"Gesendet am",
  "ikbs.column.label.colukngpath" :"Kategoriepfad",
  "ikbs.knowledge.label.saveAndNext" :"Save-and-NextStep",
  "ikbs.constableTmp.save" :"Speichern",
  "ikbs.knghisverman.label.operation" :"Betrieb",
  "ikbs.column.label.summaryInfo" :"Zusammenfassung",
  "ikbs.knowledge.recycle.button.reset" :"Zurücksetzen",
  "ikbs.knowledge.recycle.button.delete" :"Löschen",
  "ikbs.constableTmp.status.invalid" :"Verworfen",
  "ikbs.cntTmp.import.contTmpNameEditStandEmpty" :"Der Vorlageninhalt und die Bearbeitungsspezifikationen können nicht gleichzeitig leer sein.",
  "ikbs.column.knowledge.button.copy" :"Kopieren",
  "ikbs.knowledge.properties.properties.inputWay" :"Eingabetyp",
  "ikbs.column.knowledge.compareModified" :"Unterschiedlich",
  "ikbs.constableTmp.innerFieldFlag" :"Integriertes Feld",
  "ikbs.column.passProcessWay.move" :"Weiter zur Geschichte",
  "ikbs.knowledge.msg.fileUpload" :"Datei in Wissen hochladen ",
  "ikbs.column.unlock.message.failed" :"Entsperren fehlgeschlagen.",
  "ikbs.cntTmp.import.editStandardOversize" :"Die Bearbeitungsspezifikationen dürfen nicht länger als 400 Zeichen sein.",
  "ikbs.contentTmp.save" :"Speichern",
  "ikbs.knowledge.check.kngName" :"Der Wissens- oder Spaltenname darf nicht die folgenden speziellen characters: /\\:*?\"<>|",
  "ikbs.contentTmp.tab.copyTitle" :"Duplizierung",
  "ikbs.column.downMove.message.fail" :"Kann nicht herunterfahren.",
  "ikbs.column.knowledge.compareDel" :"Nicht verfügbar",
  "ikbs.knowledge.service.hot" :"Heiß",
  "ikbs.contentTmp.modify.fail" :"Änderung fehlgeschlagen",
  "ikbs.knowledge.label.servicesStartTime" :"Beginn des Geschäftsgültigkeitszeitraums",
  "ikbs.constableTmp.modifyTime" :"Geändert am",
  "ikbs.knowledge.index.no" :"Nein",
  "ikbs.kbshome.label.ClickList" :"Die meisten Klicks",
  "ikbs.knowledge.properties.properties.status" :"Status",
  "ikbs.knowledge.properties.dictValue" :"Wörterbuchwert",
  "ikbs.column.delete.message.warning" :"Informationen",
  "ikbs.column.delete.message.fail" :"Löschen fehlgeschlagen.",
  "ikbs.constableTmp.cnstTblName" :"Konsultationstisch",
  "ikbs.cntTmp.import.error.importType" :"Wählen Sie einen Importmodus.",
  "ikbs.contTmp.import.popup.title" :"Vorlagen importieren",
  "ikbs.column.title.selectUser" :"Benutzer auswählen",
  "ikbs.column.recycle.noSelectRecycles" :"Wählen Sie einen Datensatz aus.",
  "ikbs.knowledge.label.servicesEndTime" :"Ende des Geschäftsgültigkeitszeitraums",
  "ikbs.knghisverman.label.modifier" :"Geändert von",
  "ikbs.cntTmp.import.result" :"Import der Inhaltsvorlagen abgeschlossen. Gesamtzahl {0}, Erfolgszahl {1}, Fehlerzahl {2}.",
  "ikbs.cntTmp.export.crosspage.info" :"Der seitenübergreifende Export wird nicht unterstützt. Nur die ausgewählten Daten auf der aktuellen Seite können exportiert werden.",
  "ikbs.column.knowledge.expired" :"Abgelaufen",
  "ikbs.knowledgeaudit.title.auditTime" :"Geprüft am",
  "ikbs.knowledge.tip.uploadFailed" :"Hochladen fehlgeschlagen.",
  "ikbs.column.knowledge.CurVersion" :"Quellversion",
  "ikbs.content.upload.file.Frequent" :"Zu viele API-Anfragen. Bitte versuchen Sie es später noch einmal.",
  "ikbs.knowledge.check.keyword" :"Die Schlüsselwörter dürfen keine Sonderzeichen enthalten.",
  "ikbs.knowledge.tip.fileTypeError" :"Dateityp konnte nicht verifiziert werden.",
  "ikbs.column.upMove.message.warning" :"Informationen",
  "ikbs.contentTmp.modifyStartTime" :"Geänderter Start",
  "ikbs.knowledgeaudit.label.taskEndTime" :"Endzeit der Aufgabe",
  "ikbs.constableTmp.modifyBeginTime" :"Startzeit der Änderung",
  "ikbs.knowledge.lable.copy" :"Duplizieren",
  "ikbs.knowledgeaudit.label.batchAuditPass" :"Stapelgenehmigung",
  "ikbs.contentTmp.delete" :"Löschen",
  "ikbs.knowledge.tab.title" :"Wissenszusammenstellung",
  "ikbs.knowledge.recycle.label.knowledgeType" :"Typ",
  "ikbs.column.knowledge.Type" :"Typ",
  "ikbs.kbshome.button.thirtyDayList" :"30 Tage",
  "ikbs.cntTmp.import.queryContTempErr" :"Die Inhaltsvorlage konnte nicht abgefragt werden.",
  "ikbs.favorite.table.delete" :"Löschen",
  "ikbs.column.move.message.warning" :"Informationen",
  "ikbs.knowledge.title.propTitle" :"Name",
  "ikbs.constableTmp.modify.selectedOne" :"Es kann nur eine Vorlage ausgewählt werden",
  "ikbs.knowledge.label.uploadAttachment" :"Anhang hochladen",
  "ikbs.knowledge.pass.move" :"Weiter zur Geschichte",
  "ikbs.favorite.title.warning" :"Informationen",
  "ikbs.kbshome.label.favorite" :"Kurzbefehle",
  "ikbs.knowledge.label.deleteProperty" :"Löschen",
  "ikbs.column.knowledge.button.publish" :"Veröffentlichen",
  "ikbs.column.resumeRecycle.comfirm" :"Möchten Sie sie wirklich wiederherstellen?",
  "ikbs.constableTmp.status.valid" :"Aktiviert",
  "ikbs.knowledgeaudit.label.reject" :"Ablehnen",
  "ikbs.contentTmp.export" :"Exportieren",
  "ikbs.properties.operation.message.fail" :"Fehler",
  "ikbs.knowledge.label.validEndTime" :"Ende der Gültigkeitsdauer",
  "ikbs.knowledge.label.preStep" :"Vorstufe",
  "ikbs.properties.add.save" :"OK",
  "ikbs.knowledgeaudit.label.query" :"Abfrage",
  "ikbs.knowledge.tip.keywordCountError" :"Es werden maximal {count} Schlüsselwörter unterstützt.",
  "ikbs.favorite.table.edit" :"Bearbeiten",
  "ikbs.knowledge.label.addKngPoint" :"Frage hinzufügen",
  "ikbs.knowledge.label.creatorName" :"Vorbereitet von",
  "ikbs.knowledge.tip.operateFailed" :"Vorgang fehlgeschlagen.",
  "ikbs.knghisverman.tab.message.isDeleteHisVer" :"Möchten Sie diese Version wirklich aus dem Verlauf löschen?",
  "ikbs.favorite.title.addDirectory" :"Verzeichnis hinzufügen",
  "ikbs.knowledge.label.type" :"Typ",
  "ikbs.column.Move.message.success" :"Umzug erfolgreich.",
  "ikbs.column.knowledge.update" :"Aktualisiert",
  "ikbs.search.label.sortByTime" :"Zeit",
  "ikbs.knowledge.label.summaryInfo" :"Zusammenfassung",
  "ikbs.properties.detail.information" :"Erweiterte Attributdetails",
  "ikbs.column.release.message.success" :"Veröffentlichung erfolgreich.",
  "ikbs.knowledge.label.ok" :"OK",
  "ikbs.knowledge.label.upload" :"Hochladen",
  "ikbs.topMove.selectTopMoveColumnKngNumError" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.constableTmp.display.yes" :"ja",
  "ikbs.column.modify.properties.batch.message.error" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.cntTmp.import.failReason" :"Datensatz {0} konnte nicht hochgeladen werden; Fehlerursache: {1}",
  "ikbs.knowledge.properties.properties.title" :"Name",
  "ikbs.knowledgeaudit.label.auditor" :"Geprüft von",
  "ikbs.cntTmp.export.exportNum.blank" :"Wählen Sie die Inhaltsvorlagen aus, die exportiert werden sollen.",
  "ikbs.contentTmp.status.valid" :"Gültig",
  "ikbs.column.modify.selectModifyColumnKngNumZERO" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.column.tip.success" :"Informationen",
  "ikbs.knowledge.tip.relaKngCountError" :"Es werden maximal {count} zugeordnete Wissenselemente unterstützt.",
  "ikbs.contentTemp.export.tempcontent" :"Vorlageninhalt",
  "ikbs.contentTmp.query" :"Abfrage",
  "ikbs.properties.status.draft" :"Entwurf",
  "ikbs.column.knowledge.ModifyTime" :"Geändert am",
  "ikbs.knowledgeaudit.title.status" :"Status",
  "ikbs.column.knowledge.compare" :"Vergleichen",
  "ikbs.knowledge.label.uploadFileSizeError" :"Der Anhang darf nicht größer als 10 MB sein.",
  "ikbs.cntTmp.import.invalidData" :"Ungültige Daten.",
  "ikbs.column.delete.message.success" :"Löschung erfolgreich.",
  "ikbs.favorite.tree.favoriteRootName" :"Favoriten",
  "ikbs.column.moveTo.selectColumnKngNumError" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.cntTmp.export.error.syserr" :"Die Inhaltsvorlagendatei konnte nicht exportiert werden.",
  "ikbs.column.button.confirm" :"Senden",
  "ikbs.constableTmp.delete.noSelectTmp" :"Es wurde keine Vorlage ausgewählt",
  "ikbs.content.transfer.html.success" :"Inhalt konnte nicht in HTML konvertiert werden.",
  "ikbs.column.modify.modifyValidServiceEndTimeCheckError" :"Das Ende des Gültigkeitszeitraums des Geschäfts darf nicht nach dem Ende des Gültigkeitszeitraums liegen.",
  "ikbs.knowledge.properties.label.propertiesTitle" :"Name",
  "ikbs.knowledge.label.addAnswer" :"Antwort hinzufügen",
  "ikbs.column.delete.message.comfirm" :"Bestätigen",
  "ikbs.column.button.search" :"Abfrage",
  "ikbs.knghisverman.label.modifyTime" :"Geändert am",
  "ikbs.select.record.to.delete" :"Wählen Sie den zu löschenden Datensatz aus",
  "ikbs.knowledgeaudit.label.submitterOrAuditor" :"Gesendet von/Geprüft von",
  "ikbs.constableManage.fieldValue" :"Wert",
  "ikbs.column.history.down.empty" :"Die Spalte enthält keine Spalten oder Wissen. Die Spalte kann nicht geöffnet werden.",
  "ikbs.constableTmp.name" :"Vorlagenname",
  "ikbs.knowledgeaudit.label.pleaseSelect" :"-Auswahl-",
  "ikbs.cntTmp.import.syserr" :"Die Inhaltsvorlage konnte nicht importiert werden.",
  "ikbs.column.copy.selectCopyColumnKngNumError" :"Es kann nur ein Wissenselement zum Kopieren ausgewählt werden.",
  "ikbs.contentTmp.status.invalid" :"Ungültig",
  "ikbs.column.message.ids.max.size" :"Die Anzahl der Elemente für den Stapelbetrieb darf 100 nicht überschreiten.",
  "ikbs.knowledge.properties.label.query" :"Abfrage",
  "ikbs.kngCnt.cntTmp.importType.new" :"Direkt erstellen",
  "ikbs.content.transfer.html.fail" :"Der Inhalt wurde erfolgreich in HTML konvertiert.",
  "ikbs.column.upMove.message.success" :"Erfolgreich aufgestiegen.",
  "ikbs.knghisverman.title.kngHisVerMan" :"Wissenshistorie verwalten",
  "ikbs.favorite.field.directoryName" :"Verzeichnisname",
  "ikbs.column.Move.message.fail" :"Verschieben fehlgeschlagen.",
  "ikbs.column.deletePermanently.message.fail" :"Permanentes Löschen fehlgeschlagen.",
  "ikbs.columntype.knowledge" :"Wissen",
  "ikbs.knowledge.type.common" :"Allgemeinwissen",
  "ikbs.knowledgeaudit.label.ok" :"OK",
  "ikbs.contentTmp.exception.contTmpName" :"Unzulässige Eingabe",
  "ikbs.kngCnt.cntTmp.use.cntReplaceConfirm" :"Möchten Sie den aktuellen Wissensinhalt wirklich ersetzen?",
  "ikbs.search.button.search" :"Suche",
  "ikbs.constableTmp.delete.success" :"Löschung erfolgreich",
  "ikbs.knowledge.recycle.coluKngName" :"Name",
  "ikbs.constableTmp.add.success" :"Erfolgreiches Hinzufügen",
  "ikbs.kbshome.button.orderByName" :"Nach Namen",
  "ikbs.knowledge.tip.uploadSuccess" :"Hochladen erfolgreich.",
  "ikbs.constableManage.incorrect" :"Unzulässiger Zustand",
  "ikbs.column.modify.properties.batch.message.success" :"Die Attribute wurden erfolgreich stapelweise geändert.",
  "ikbs.properties.inputway.text" :"Texteingabe",
  "ikbs.column.move.selectMoveColumnKngNumError" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.knowledgeaudit.title.auditRemark" :"Anmerkungen",
  "ikbs.knowledge.label.indexNeedUpdate" :"Suchindex aktualisieren",
  "ikbs.knowledge.recycle.status" :"Status",
  "ikbs.kbshome.button.orderByClicks" :"Klicken",
  "ikbs.favorite.table.name" :"Name",
  "ikbs.column.knowledge.RelVersion" :"Zielversion",
  "ikbs.kbshome.button.dayList" :"1 Tag",
  "ikbs.column.modify.modifyValidTimeServiceTimeSame" :"Ändern Sie sowohl den Beginn und das Ende des Gültigkeitszeitraums als auch des Geschäftsgültigkeitszeitraums.",
  "ikbs.column.label.servicesVaildEndTime" :"Ende des Geschäftsgültigkeitszeitraums",
  "ikbs.knowledgeaudit.label.submitAudit" :"Ausstehend",
  "ikbs.column.release.selectreleaseColumnKngTypeError" :"Nur Wissen kann veröffentlicht werden.",
  "ikbs.knowledge.type.points" :"Fragen und Antworten",
  "ikbs.column.knowledge.modifyStartTime" :"Geänderter Start",
  "ikbs.favorite.title.preModifiedKngName" :"Vor der Veränderung",
  "ikbs.column.deleteRecycle.comfirm" :"Möchten Sie sie wirklich löschen?",
  "ikbs.recycle.title" :"Papierkorb",
  "ikbs.column.tree.rootName" :"Kategoriewissen",
  "ikbs.column.downMove.message.warning" :"Informationen",
  "ikbs.knghisverman.tab.title.kngBaseInfo" :"Grundlegende Informationen",
  "ikbs.addtype.label.column" :"Kategorie hinzufügen",
  "ikbs.knowledge.msg.updateKngPath" :"Wissensdetails statisch machen",
  "ikbs.kbshome.label.recentViews" :"Meine Geschichte",
  "ikbs.column.knowledge.publish" :"Veröffentlicht",
  "ikbs.contentTemp.export.tempdesc" :"Vorlagenbeschreibung",
  "ikbs.column..topMove.message.fail" :"Fehler beim Anheften an die Spitze.",
  "ikbs.knowledge.recycle.button.clearAll" :"Papierkorb leeren",
  "ikbs.constableTmp.status" :"Vorlagenstatus",
  "ikbs.contentTmp.contentDesc" :"Vorlagenbeschreibung",
  "ikbs.knghisverman.label.version" :"Version",
  "ikbs.column.knowledge.button.unLock" :"Entsperren",
  "ikbs.knowledge.error.property" :"Wählen Sie den Wert des Attributs [{}].",
  "ikbs.knowledgeaudit.label.mySubmitAudit" :"Meine Bewerbung",
  "ikbs.column.label.kngReleaseWay" :"Wissen in Kategorie überprüfen und veröffentlichen",
  "ikbs.contentTmp.add.fail" :"Hinzufügen fehlgeschlagen",
  "ikbs.constableTmp.moveUp" :"Auf",
  "ikbs.constableManage.condition.limit" :"Die Anzahl der Bedingungen darf 10 nicht überschreiten",
  "ikbs.column.downMove.selectDowmMoveColumnKngNumError" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.column.modify.modifyzeropropertieserror" :"Es wird kein Attribut geändert.",
  "ikbs.column.label.isInheritProp" :"Attribut der übergeordneten Kategorie übernehmen",
  "ikbs.column.knowledge.reject" :"Abgelehnt",
  "ikbs.column.deletePermanently.columnkng" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.constableManage.modifyConstable" :"Konsultationstabellendaten ändern",
  "ikbs.contTmp.importTmp.title" :"Importvorlage herunterladen",
  "ikbs.constableTmp.fieldViewName" :"Anzeigename",
  "ikbs.cntTmp.import.contTmpDesc" :"Falsche Vorlagenbeschreibung.",
  "ikbs.constableManage.addConstable" :"Konsultationstabellendaten hinzufügen",
  "ikbs.contentTmp.operate" :"Betrieb",
  "ikbs.column.upMove.message.fail" :"Aufrücken fehlgeschlagen.",
  "ikbs.constableTmp.query" :"Abfrage",
  "ikbs.column.recycle.message.comfirm" :"Bestätigen",
  "ikbs.kbshome.button.advancedSearch" :"Erweiterte Suche",
  "ikbs.constableTmp.modify.success" :"Änderung erfolgreich",
  "ikbs.kbshome.tip.refresh" :"Aktualisieren",
  "ikbs.constableTmp.delete.fail" :"Löschfehler",
  "ikbs.properties.operation.message.success" :"Erfolg",
  "ikbs.column.knowledge.clear" :"Löschen",
  "ikbs.knowledge.label.auditor" :"Geprüft von",
  "ikbs.column.tip.error" :"Fehler",
  "ikbs.column.deletePermanently.columnkng.comfirm" :"Möchten Sie die Kategorie Wissen wirklich endgültig löschen?",
  "ikbs.favorite.tab.message.isDeleteFavoriteDir" :"Möchten Sie das Favoritenverzeichnis und das Wissen im Verzeichnis wirklich löschen?",
  "ikbs.column.tips.modifyColumnOperation.error" :"Kategoriewissen konnte nicht geändert werden.",
  "ikbs.column.button.reset" :"Zurücksetzen",
  "ikbs.knowledgeaudit.message.auditSuccess" :"Der Antrag wurde erfolgreich genehmigt.",
  "ikbs.column.knowledge.Modifier" :"Geändert von",
  "ikbs.cntTmp.import.error.contTmpNameExist" :"Der Vorlagenname ist bereits vorhanden.",
  "ikbs.cntTmp.export.info.success" :"Export erfolgreich.",
  "ikbs.knowledge.label.kngAttachmentUpload" :"Wissenskategorie-Anhang hochladen",
  "ikbs.knowledge.tip.propCountLimited" :"Es können maximal 20 Attribute zugeordnet werden.",
  "ikbs.column.knowledge.button.deletePermanently" :"Permanent löschen",
  "ikbs.knowledge.recycle.label.operateTimeEnd" :"Endzeit des Löschvorgangs",
  "ikbs.knowledge.label.display" :"Auf der Vorderseite anzeigen",
  "ikbs.cntTmp.export.result" :"Export der Inhaltsvorlagen abgeschlossen. Gesamtzahl {0}, Erfolgszahl {1}, Fehlerzahl {2}.",
  "ikbs.knowledge.label.deleteAnswer" :"Antwort löschen",
  "ikbs.knowledge.properties.operation.detail" :"Einzelheiten",
  "ikbs.column.knowledge.modifyEndTime" :"Geändertes Ende",
  "ikbs.knowledgeaudit.title.name" :"Name",
  "ikbs.knowledge.label.modifierName" :"Letzte Aktualisierung von",
  "ikbs.column.knowledge.button.moveTo" :"Anpassen an",
  "ikbs.knowledge.title.knowledgeContent" :"Inhalt",
  "ikbs.knowledge.tip.fileSizeError" :"Die Dateigröße konnte nicht überprüft werden.",
  "ikbs.constableTmp.delete" :"Löschen",
  "ikbs.tips.copy.onlyKngCanCopy" :"Nur Wissen kann kopiert werden.",
  "ikbs.column.passProcessWay.delete" :"Löschen",
  "ikbs.column.label.serviceStatus" :"Status des Unternehmens",
  "ikbs.column.button.cancel" :"Schließen",
  "ikbs.knowledge.label.clickNum" :"Ansichten",
  "ikbs.Column.MoveTo.message.success" :"Vorgang erfolgreich.",
  "ikbs.knowledge.index.yes" :"Ja",
  "ikbs.knowledge.tip.fileIOError" :"Datei konnte nicht hochgeladen werden.",
  "ikbs.column.modify.properties.message.warning" :"Informationen",
  "ikbs.constableTmp.operate" :"Betrieb",
  "ikbs.knowledgeaudit.title.path" :"Pfad",
  "ikbs.column.serviceStatus.expired" :"Abgelaufen",
  "ikbs.column.knowledge.audit" :"Geprüft",
  "ikbs.contTmp.import.tip" :"1. Es kann nur eine.xlsx-Datei hochgeladen werden. 2. Die maximale Dateigröße beträgt 20 MB. 3. Es können maximal 10 Datensätze importiert werden.",
  "ikbs.knowledge.label.save" :"Speichern",
  "ikbs.knowledge.title.knowledgeExtended" :"Erweiterte Informationen",
  "ikbs.column.knowledge.button.modifyPropBatch" :"Attribut ändern",
  "ikbs.column.type.history" :"Geschichte",
  "ikbs.cntTmp.import.contTmpNameError" :"Falscher Vorlagenname.",
  "ikbs.knowledge.tip.error" :"Fehler",
  "ikbs.contentTmp.delete.success" :"Löschung erfolgreich",
  "ikbs.knowledgeaudit.label.success" :"Erfolg",
  "ikbs.contentTmp.editStandard" :"Spezifikationen bearbeiten",
  "ikbs.favorite.title.editFavoriteKngName" :"Namen ändern",
  "ikbs.column.knowledge.button.down" :"Unten",
  "ikbs.column.modify.message.error" :"Änderung fehlgeschlagen.",
  "ikbs.column.knowledge.compareKng" :"Wissen vergleichen",
  "ikbs.knowledge.check.inputText" :"Sonderzeichen sind nicht zulässig.",
  "ikbs.column.knowledge.button.delete" :"Löschen",
  "ikbs.knowledge.service.new" :"Neues Geschäft",
  "ikbs.knowledge.properties.operation.disable" :"Deaktivieren",
  "ikbs.column.serviceStatus.hot" :"Heiß",
  "ikbs.contentTmp.add.success" :"Erfolgreiches Hinzufügen",
  "ikbs.favorite.msg.modifyNodeNameSuccess" :"Vorgang erfolgreich.",
  "ikbs.column.deletePermanently.message.warning" :"Informationen",
  "ikbs.contentTmp.modify" :"Ändern",
  "ikbs.kbshome.button.sevenDayList" :"7 Tage",
  "ikbs.column.knowledge.button.turnToHistory" :"Weiter zur Geschichte",
  "ikbs.constableTmp.cancle" :"Abbrechen",
  "ikbs.knowledge.recycle.button.resume" :"Wiederherstellen",
  "ikbs.knowledgeaudit.label.auditing" :"Wird geprüft",
  "ikbs.knowledge.release.audit" :"Überprüfen und dann veröffentlichen",
  "ikbs.constableTmp.yes" :"Ja",
  "ikbs.column.label.displayFlag" :"Auf der Vorderseite anzeigen",
  "ikbs.column.moveto.back" :"Nächstes an",
  "ikbs.column.modify.message.warning" :"Informationen",
  "ikbs.knowledge.label.nextStep" :"Nächster Schritt",
  "ikbs.column.moveTo.message.warning" :"Informationen",
  "ikbs.column.isInheritProp.yes" :"Ja",
  "ikbs.knowledge.title.knowledgeBase" :"Grundlegende Informationen",
  "ikbs.knowledge.label.keyword" :"Schlüsselwörter",
  "ikbs.knowledgeaudit.label.reset" :"Zurücksetzen",
  "ikbs.knowledge.message.uploadImgTypeError" :"Das Bildformat ist falsch.",
  "ikbs.knowledge.label.moveUp" :"Auf",
  "ikbs.properties.inputway.checkbox" :"Kontrollkästchen",
  "ikbs.knowledgeaudit.label.auditReject" :"Abgelehnt",
  "ikbs.contentTmp.ModifyTime" :"Geändert am",
  "ikbs.knowledge.label.checkboxProp" :"Kontrollkästchen",
  "ikbs.knowledge.label.kngMsg" :"Wissensinformationen",
  "ikbs.column.knowledge.compareResult" :"Vergleichsergebnisse",
  "ikbs.cntTmp.import.invalidFile" :"Ungültige Datei.",
  "ikbs.kbshome.button.orderByOrderId" :"Durch Nein.",
  "ikbs.column.knowledge.modifyColumnInformation" :"Kategorie ändern",
  "ikbs.knowledgeaudit.placeholder.pleaseSelect" :"-Auswahl-",
  "ikbs.constableTmp.tab.modifyTitle" :"Vorlage wird geändert",
  "ikbs.knowledge.label.modifyRemark" :"Anmerkungen",
  "ikbs.column.release.selectreleaseColumnKngNumError" :"Es wurde kein Wissen ausgewählt.",
  "ikbs.column.knowledge.button.order" :"Sortieren",
  "ikbs.contentTmp.import" :"Importieren",
  "ikbs.column.label.validEndTime" :"Ende der Gültigkeitsdauer",
  "ikbs.properties.status.enable" :"Aktivieren",
  "ikbs.column.modify.modifyValidServiceStartTimeCheckError" :"Der Beginn der Gültigkeitsdauer des Geschäfts darf nicht vor dem Beginn der Gültigkeitsdauer liegen.",
  "ikbs.knowledge.error.contentEmpty" :"Der Wissensinhalt darf nicht leer sein.",
  "ikbs.column.upMove.selectUpMoveColumnKngNumError" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.constableTmp.add" :"Hinzufügen",
  "ikbs.constableTmp.operator.exclude" :"Ausschließen",
  "ikbs.column.knowledge.LockedFlat" :"Gesperrt durch",
  "ikbs.knowledge.label.cancelCollection" :"Aus Favoriten entfernen",
  "ikbs.contentTmp.reset" :"Zurücksetzen",
  "ikbs.knowledge.label.textProp" :"Texteingabe",
  "ikbs.knowledge.title.propType" :"Eingabetyp",
  "ikbs.cntTmp.export.exportNum.oversize" :"Es können maximal 10 Inhaltsvorlagen exportiert werden.",
  "ikbs.properties.status.disable" :"Deaktivieren",
  "ikbs.knowledgeaudit.label.knowledgeName" :"Wissensname",
  "ikbs.constableManage.fieldName" :"Spaltenname",
  "ikbs.contentTmp.close" :"Schließen",
  "ikbs.knowledge.properties.label.add" :"Neu",
  "ikbs.knowledgeaudit.label.auditPass" :"Genehmigt",
  "ikbs.common.search" :"Allgemeine Suche",
  "ikbs.contentTmp.modify.success" :"Änderung erfolgreich",
  "ikbs.column.downMove.message.success" :"Erfolgreich heruntergefahren.",
  "ikbs.knowledge.label.draft" :"Entwurf",
  "ikbs.knowledge.label.creationTime" :"Erstellt am",
  "ikbs.column.modify.properties.batch.message.fail" :"Die Attribute konnten nicht stapelweise geändert werden.",
  "ikbs.column.message.plsSelectModifyStartTime" :"Wählen Sie eine Startzeit aus.",
  "ikbs.knowledge.label.problem" :"Frage",
  "ikbs.constableTmp.displayFlag" :"Angezeigt oder nicht",
  "ikbs.Column.MoveTo.message.fail" :"Vorgang fehlgeschlagen.",
  "ikbs.column.knowledge.columnKnowledgeName" :"Kategoriewissensname",
  "ikbs.column.knowledge.button.top" :"Pin",
  "ikbs.tips.addColumnOperation.error" :"Die Kategorie konnte nicht hinzugefügt werden.",
  "ikbs.search.label.sortByName" :"Nach Namen",
  "ikbs.column.delete.selectdeletecolumn.comfirm" :"Möchten Sie die Kategorie und alle untergeordneten Kategorien und das Wissen wirklich löschen?",
  "ikbs.constableTmp.modifierName" :"Geändert von",
  "ikbs.knowledge.label.attachment" :"Anhang",
  "ikbs.column.unlock.selectUnlockColumnKngNumError" :"Es wurde kein Kategoriewissen ausgewählt.",
  "ikbs.contentTmp.tmpContent" :"Vorlageninhalt",
  "ikbs.column.label.coluKngBrowsing" :"Gruppe durchsuchen",
  "ikbs.kbshome.button.totalList" :"Alle Zeit",
  "ikbs.search.label.resultDes" :"Keine Daten",
  "ikbs.knowledge.recycle.button.query" :"Abfrage",
  "ikbs.knowledge.properties.label.reset" :"Zurücksetzen",
  "ikbs.constableTmp.warning" :"Informationen",
  "ikbs.knowledge.label.kngReleaseWay" :"Veröffentlichungsmodus",
  "ikbs.properties.inputway.radio" :"Radiobox",
  "ikbs.knowledge.recycle.operationTime" :"Gelöscht am",
  "ikbs.constableTmp.plsSelectModifyEndTime" :"Endzeit der Änderung festlegen",
  "ikbs.column.knowledge.button.turnToNormal" :"Zur Normalität übergehen",
  "ikbs.knowledge.label.validStartTime" :"Beginn der Gültigkeitsdauer",
  "ikbs.knowledge.pass.delete" :"Löschen",
  "ikbs.column.displayflag.show" :"Ja",
  "ikbs.knowledge.recycle.select.knowledgeStatus" :"Wissensstatus",
  "ikbs.column.deletePermanently.message.success" :"Permanente Löschung erfolgreich.",
  "ikbs.time.lessThan.currentTime" :"Das Ende kann nicht vor der aktuellen Zeit liegen.",
  "ikbs.knowledge.recycle.label.operateTimeStart" :"Startzeit der Löschung",
  "ikbs.knowledge.recycle.label.knowledgeName" :"Kategoriewissensname",
  "ikbs.knghisverman.label.delete" :"Löschen",
  "ikbs.column.knowledge.Name" :"Name",
  "ikbs.knowledgeaudit.placeholder.pleaseInput" :"Geben Sie einen Namen ein.",
  "ikbs.column.label.validStartTime" :"Beginn der Gültigkeitsdauer",
  "ikbs.knowledge.display.yes" :"Ja",
  "ikbs.knowledge.label.servicesTime" :"Gültigkeitsdauer",
  "ikbs.search.label.updateStartTime" :"Startzeit der Aktualisierung",
  "ikbs.favorite.msg.addDirectorySuccess" :"Das Verzeichnis wurde erfolgreich hinzugefügt.",
  "ikbs.search.label.result" :"Suchergebnisse",
  "ikbs.constableTmp.modify.fail" :"Änderungsfehler",
  "ikbs.constableTmp.add.fail" :"Hinzufügungsfehler",
  "ikbs.column.knowledge.modifierId" :"Geändert von",
  "ikbs.tips.propertyDictValueNumExceedMaxValue.warning" :"Die Anzahl der Attributwörterbuchwerte überschreitet das Maximum.",
  "ikbs.constableTmp.no" :"Nein",
  "ikbs.knowledge.label.close" :"Schließen",
  "ikbs.favorite.table.operate" :"Betrieb",
  "ikbs.kngCnt.cntTmp.editStandard.title" :"Spezifikationen bearbeiten",
  "ikbs.constableTmp.anonymizationFlag" :"Anonymisiert oder nicht",
  "ikbs.knowledge.tip.fileCountError" :"Es werden maximal {count} Anhänge unterstützt.",
  "ikbs.column.copy.selectCopyColumnKngNumZERO" :"Es wurde kein Wissen zum Kopieren ausgewählt.",
  "ikbs.constableTmp.delete.byCondition" :"Nach Bedingung löschen",
  "ikbs.column.delete.selectdeletecolumnkng.comfirm" :"Möchten Sie die Kategorien oder das Wissen wirklich löschen?",
  "ikbs.knowledge.label.name" :"Wissensname",
  "ikbs.knowledge.check.textArea" :"Die folgenden Sonderzeichen sind keine allowed: <>/",
  "ikbs.knowledge.label.addToCollection" :"Zu Favoriten hinzufügen",
  "ikbs.knowledge.recycle.operator" :"Bediener",
  "ikbs.konwledgetype.knowledge" :"Allgemeinwissen",
  "ikbs.knowledgeaudit.label.taskBeginTime" :"Startzeit der Aufgabe",
  "ikbs.kngCnt.cntTmp.importType.sameNameCover" :"Vorlagen mit gleichen Namen überschreiben",
  "ikbs.column.knowledge.Path" :"Pfad",
  "ikbs.kbshome.label.plsInputKeyWord" :"Geben Sie ein Stichwort ein.",
  "ikbs.knowledge.label.addProperty" :"Hinzufügen",
  "ikbs.knowledgeaudit.title.submitter" :"Gesendet von",
  "ikbs.knowledge.properties.properties.operation" :"Betrieb",
  "ikbs.knowledge.label.moveDown" :"Unten"

}