export default {
  'oifde.index.title': 'ODFS-Servicio de flujo de diálogo en línea',
  'oifde.common.lang': 'Idioma',
  'oifde.common.noData': 'Sin datos',
  'oifde.common.validation.required': 'Obligatorio',
  'oifde.common.validation.nameRange': 'No soportado: <>!{\'@\'}#{\'$\'}%^&*?;"~/\\',
  'oifde.common.validation.nameNoSpace': 'El principio y el final no pueden ser espacios.',
  'oifde.common.validation.nameCharRegNotChinese': 'El valor solo puede contener letras, dígitos, guiones bajos y guiones.',
  'oifde.common.validation.nameCharReg': 'El valor solo puede contener letras, dígitos, chino, guiones bajos y guiones.',
  'oifde.common.validation.charRegWithDot': 'El valor solo puede contener letras, dígitos, punto, guiones bajos y guiones.',
  'oifde.common.validation.numberRange': 'Rango de valores ({0}-{1})',
  'oifde.common.validation.urlValidate': 'Error de formato de URL!',
  'oifde.common.validation.positiveInt': 'Solo permite enteros positivos',
  'oifde.common.validation.digits': 'El valor de entrada debe ser 0 o un entero positivo',
  'oifde.common.validation.numbers': 'Por favor, introduzca solo números.',
  'oifde.common.validation.validateFalse': 'Datos Inválidos.',
  'oifde.common.validation.mrcpIdValueFalse': 'Escriba un número entero positivo comprendido entre 0 y 255',
  'oifde.common.validation.extendFaqRange': 'Solo caracteres chinos, letras inglesas, dígitos, espacios y los siguientes caracteres especiales están permitidos: () [] {\'@\'} {\'{\'}{\'}\'} ，,。.{\'|\'} \'_ -',
  'oifde.common.validation.cellNameRange': 'No soportado: < > {\'@\'} # {\'$\'}% ^ *; "~/\\',
  'oifde.shortMessage.validation.text': 'Non pris en charge:`~{\'@\'}^#%&*/=+{\'{\'}{\'}\'};"<.>?[]',
  'oifde.common.validation.openNameCharReg': 'Solo caracteres chinos, letras inglesas, dígitos, espacios, puntos (_), hyphens (-) y marcas de cotización única (\') están permitidos.',
  'oifde.common.validation.kgNameCharReg': 'Solo caracteres chinos, letras inglesas, espacios y los siguientes caracteres especiales están permitidos: _ - , \' ;. {\'@\'}',
  'oifde.common.validation.schemaNameCharReg': 'Solo caracteres chinos, letras inglesas, dígitos, espacios, puntos (_), y marcas de cotización únicas (\') están permitidos.',
  'oifde.common.validation.schemaRelationNameCharReg': 'Solo caracteres chinos, letras inglesas, dígitos, espacios y marcas de cotización única (\') están permitidos.',
  'oifde.common.validation.int': 'El valor debe ser un entero de 0 a 2147483647.',
  'oifde.common.validation.min': 'El valor no puede ser menos que {0}.',
  'oifde.common.validation.max': 'El valor no puede ser más grande que {0}.',
  'oifde.common.validation.collectTimeoutRule': 'Se admiten las variables FLOW y GLOBAL. El valor también puede ser un número entero comprendido entre 1 y 120. Si el valor no cumple con los requisitos, se utiliza el valor predeterminado 5.',
  'oifde.common.validation.sessionDTRange': 'El intervalo de tiempo de espera de flujo de diálogo varía de {0} a {1} segundos.',
  'oifde.common.validation.slientHelpRange': 'El tiempo para esperar una respuesta después de un agente es silencioso de {0} a {1} segundos.',
  'oifde.common.validation.confidenceRange': 'Los rangos de umbral de confianza de {0} a {1}.',
  'oifde.common.validation.flowSaveIntervalRange': 'El intervalo para guardar la orquestación del proceso IVR es un entero positivo, que no puede ser inferior a {0} minutos ni superior a {1} minutos',
  'oifde.common.validation.appSecretLenCheck': 'La clave compartida debe contener de 8 a 20 caracteres.',
  'oifde.common.validation.nodeNameCharReg': 'El valor puede contener solo caracteres chinos, cartas inglesas, dígitos, puntos de puntuación (_) e hyphens (-).',
  'oifde.common.dialog.confirm.title': 'Aviso',
  'oifde.common.dialog.confirm.content': 'Esta operación eliminará los datos de forma permanente. ¿Confirma que desea continuar?',
  'oifde.common.dialog.confirm.buttonSure': 'Sí',
  'oifde.common.dialog.confirm.buttonCancel': 'No',
  'oifde.common.dialog.confirm.successInfo': 'Eliminación exitosa!',
  'oifde.common.dialog.confirm.cancelInfo': 'Eliminación cancelada!',
  'oifde.common.dialog.confirm.operationSucc': 'Operación realizada con éxito!',
  'oifde.common.dialog.confirm.operation_failed': 'Error al realizar la operación!',
  'oifde.common.actions.add': 'Agregar',
  'oifde.common.actions.edit': 'Editar',
  'oifde.common.actions.delete': 'Eliminar',
  'oifde.common.actions.download': 'Descargar',
  'oifde.common.language.chinese': 'Chino',
  'oifde.common.language.english': 'Inglés',
  'oifde.common.time.startDate': 'Fecha de inicio',
  'oifde.common.time.endDate': 'Fecha de finalización',
  'oifde.common.selectPlaceHolder': 'Introduzca una palabra clave.',
  'oifde.common.browserSupporteMsg': 'El navegador no admite esta función.',
  'oifde.common.help': 'Ver información de ayuda.',
  'oifde.common.select': '-Seleccionar-',
  'oifde.common.detail': 'Detalles',
  'oifde.serviceInterface.oifdeInterface': 'Interfaz inteligente del robot',
  'oifde.serviceInterface.ccivrInterface': 'Interfaz IVR común',
  'oifde.serviceInterface.interfaceName': 'Nombre de la interfaz',
  'oifde.serviceInterface.bizCode': 'Código comercial',
  'oifde.serviceInterface.description': 'Descripción',
  'oifde.serviceInterface.updatetime': 'Fecha y hora de actualización',
  'oifde.serviceInterface.operation': 'Operación',
  'oifde.serviceInterface.addServiceInterface': 'Agregar configuración de interfaz comercial',
  'oifde.serviceInterface.modifyServiceInterface': 'Editar configuración de interfaz comercial',
  'oifde.serviceInterface.baseInfo': 'Información básica',
  'oifde.serviceInterface.reqURL': 'URL de solicitud',
  'oifde.serviceInterface.busiCode': 'Código de interfaz',
  'oifde.serviceInterface.interfaceType': 'Método de solicitud',
  'oifde.serviceInterface.required': 'Obligatorio',
  'oifde.serviceInterface.query_param': 'Parámetro de consulta',
  'oifde.serviceInterface.pathParamValue': 'Valor',
  'oifde.serviceInterface.outParamPath': 'Ruta',
  'oifde.serviceInterface.in_param': 'Parámetro de entrada',
  'oifde.serviceInterface.out_param': 'Parámetro de salida',
  'oifde.serviceInterface.name': 'Nombre',
  'oifde.serviceInterface.dataType': 'Tipo de dato',
  'oifde.serviceInterface.dataTypes.INT': 'Entero',
  'oifde.serviceInterface.dataTypes.STRING': 'Carácter',
  'oifde.serviceInterface.dataTypes.FLOAT': 'Número flotante',
  'oifde.serviceInterface.dataTypes.LONG': 'Entero largo',
  'oifde.serviceInterface.dataTypes.LIST': 'Lista',
  'oifde.serviceInterface.dataTypes.OBJECT': 'Objeto',
  'oifde.serviceInterface.msgHeaders': 'Encabezado del mensaje',
  'oifde.serviceInterface.msgBody': 'Cuerpo del mensaje',
  'oifde.serviceInterface.headerKey': 'Clave',
  'oifde.serviceInterface.headerValue': 'Valor',
  'oifde.serviceInterface.deleteConfirm': 'La interfaz comercial se eliminará de forma permanente. ¿Desea continuar?',
  'oifde.serviceInterface.auto_msgBody': 'Cuerpo del mensaje generado automáticamente',
  'oifde.serviceInterface.manual_msgBody': 'Cuerpo del mensaje ingresado manualmente',
  'oifde.serviceInterface.promptMessage': 'Ingresar el cuerpo del mensaje.',
  'oifde.serviceInterface.encryptFlag': 'Encriptado o no',
  'oifde.serviceInterface.ok': 'Está bien',
  'oifde.serviceInterface.miss_ifs_msgBody': 'El siguiente campo obligatorio está vacío: Cuerpo del mensaje.',
  'oifde.serviceInterface.signDescription': 'Si se debe verificar la firma de un tercero cuando la firma se transfiere al tercero como parámetro de interfaz.',
  'oifde.serviceInterface.miss_ifs_out_parameter_type': 'El siguiente campo obligatorio está vacío: Parámetros de salida > Tipo de datos.',
  'oifde.serviceInterface.prompt': 'Confirmar',
  'oifde.serviceInterface.safeWarning': 'Se especifica un protocolo de red inseguro para la interfaz.',
  'oifde.serviceInterface.whiteListCheckWarning': 'La dirección URL (dirección IP y número de puerto) no está en la lista de confianza y solo se puede utilizar después de ponerse en contacto con el administrador del sistema para agregarla a la lista de confianza.',
  'oifde.serviceInterface.actions.manual_savePrompt': 'Esta operación guardará el "cuerpo del mensaje manual" como la configuración de la interfaz de servicio. ¿Estás seguro de que quieres continuar?',
  'oifde.serviceInterface.actions.confirm': 'Sí',
  'oifde.serviceInterface.actions.auto_savePrompt': 'Esta operación guardará el "cuerpo automático del mensaje" como la configuración de la interfaz de servicio. ¿Estás seguro de que quieres continuar?',
  'oifde.serviceInterface.actions.urlNotInWhitelist': 'La URL (dirección IP y número de puerto) no está en la lista de confianza. Solo se puede utilizar después de ponerse en contacto con el administrador del sistema para agregar la URL a la lista de confianza?',
  'oifde.serviceInterface.actions.cancelSuccess': 'Se canceló el ahorro!',
  'oifde.serviceInterface.actions.cancel': 'No, no',
  'oifde.serviceInterface.actions.prompt': 'Confirmar',
  'oifde.serviceInterface.appSecret': 'Clave compartida',
  'oifde.serviceInterface.signBody': 'Firmar',
  'oifde.serviceInterface.selfDefinedInterface': 'Interfaz autodefinida',
  'oifde.serviceInterface.jsonValidate': 'El cuerpo del mensaje no cumple con el formato JSON estándar.',
  'oifde.serviceInterface.serviceInterfaceDetails': 'Detalles de configuración de la interfaz empresarial',
  'oifde.serviceInterface.miss_ifs_name': 'El siguiente campo obligatorio está vacío: Información básica > Nombre de interfaz.',
  'oifde.serviceInterface.miss_ifs_header_key': 'El siguiente campo obligatorio está vacío: Encabezado del mensaje > Clave.',
  'oifde.serviceInterface.inputAppSecret': 'Introduzca la clave compartida.',
  'oifde.serviceInterface.certCode': 'Código',
  'oifde.serviceInterface.miss_ifs_request_url': 'El siguiente campo obligatorio está vacío: Información básica > URL de solicitud.',
  'oifde.serviceInterface.miss_ifs_in_parameter_name': 'El siguiente campo obligatorio está vacío: Parámetro de entrada > Nombre.',
  'oifde.serviceInterface.signBodyDetail.no': 'No, no',
  'oifde.serviceInterface.signBodyDetail.yes': 'Sí',
  'oifde.serviceInterface.certUpdateTime': 'Actualizado',
  'oifde.serviceInterface.jsonChek': 'Comprobar JSON',
  'oifde.serviceInterface.expireTime': 'Caducado',
  'oifde.serviceInterface.innerInterfaceDetails': 'Detalles de la interfaz interna',
  'oifde.serviceInterface.miss_ifs_out_parameter_name': 'El siguiente campo obligatorio está vacío: Parámetros de salida > Nombre.',
  'oifde.serviceInterface.innerInterface': 'Interfaz interna',
  'oifde.serviceInterface.interfaceCert': 'Certificado de interfaz',
  'oifde.serviceInterface.jsonValidateSuccess': 'El formato es correcto.',
  'oifde.serviceInterface.certScene': 'Escenario',
  'oifde.sysMgr.sysCfg': 'Ajustes del sistema',
  'oifde.sysMgr.apiKeyCfg': 'Configuración de clave API',
  'oifde.sysMgr.configuration': 'Configurar',
  'oifde.sysMgr.apiKey': 'Clave API',
  'oifde.sysMgr.inputKey': 'Ingrese una clave.',
  'oifde.sysMgr.cancel': 'Cancelar',
  'oifde.sysMgr.save': 'Guardar',
  'oifde.sysMgr.success_set_key': 'Clave API configurada con éxito!',
  'oifde.sysMgr.requiredAPIKey': 'La clave API es obligatoria.',
  'oifde.sysMgr.APIKeyLenCheck': 'La clave API debe contener al menos 16 caracteres y no más de 64.',
  'oifde.sysMgr.dataClean.setTime': 'Sets the data clearing duration',
  'oifde.sysMgr.dataClean.serviceName': 'Nombre del parámetro',
  'oifde.sysMgr.dataClean.paramValue': 'Valor',
  'oifde.sysMgr.dataClean.description': 'Descripción',
  'oifde.sysMgr.dataClean.tcaContent': 'Lista de dominios enlazados con análisis de texto',
  'oifde.sysMgr.dataClean.editTcaFlag': 'Editar lista de dominios enlazados de análisis de texto',
  'oifde.sysMgr.dataClean.descriptionContent': 'Configura la duración de la eliminación de datos.',
  'oifde.sysMgr.dataClean.operation': 'Operation',
  'oifde.sysMgr.dataClean.editTime': 'Modificar duración de eliminación de datos',
  'oifde.sysMgr.dataClean.day': 'Días',
  'oifde.sysMgr.dataClean.sessionDTDesc': 'Intervalo de tiempo de espera para los flujos de diálogo.',
  'oifde.sysMgr.dataClean.flagDescription': 'Si se debe habilitar la grabación en un flujo de llamadas.',
  'oifde.sysMgr.dataClean.close': 'Desactivar',
  'oifde.sysMgr.dataClean.editFlowSaveInterval': 'Edite el intervalo de almacenamiento programado para la orquestación del flujo IVR',
  'oifde.sysMgr.dataClean.open': 'Activar',
  'oifde.sysMgr.dataClean.flowIvrSaveFlagDesc': 'Indica si se debe habilitar el almacenamiento programado para la orquestación de procesos IVR',
  'oifde.sysMgr.dataClean.hour': 'hora (s)',
  'oifde.sysMgr.dataClean.minute': 'minutos (s)',
  'oifde.sysMgr.dataClean.second': 'segundos',
  'oifde.sysMgr.dataClean.slientHelpDesc': 'Tiempo para esperar una respuesta después de que un agente esté en silencio.',
  'oifde.sysMgr.dataClean.editSessionDT': 'Intervalo de tiempo de espera de flujo de diálogo Modificar',
  'oifde.sysMgr.dataClean.editSlientHelp': 'Modificar el tiempo de espera para la respuesta después del silencio del agente',
  'oifde.sysMgr.dataClean.editFlag': 'Modificar grabación en flujo de llamadas',
  'oifde.sysMgr.dataClean.confidenceDesc': 'Umbral de confianza para que un agente silencioso reconozca la búsqueda de ayuda individual de un chatbot.',
  'oifde.sysMgr.dataClean.flowIvrSaveIntervalDesc': 'Intervalo de almacenamiento programado para la orquestación de flujo IVR',
  'oifde.sysMgr.dataClean.record': 'Grabación',
  'oifde.sysMgr.dataClean.editFlowSaveFlag': 'Edite el indicador de almacenamiento programado de orquestación de flujo IVR',
  'oifde.sysMgr.dataClean.noRecord': 'No grabar',
  'oifde.sysMgr.dataClean.confidence': 'Modificar la confianza',
  'oifde.sysMgr.dataClean.traceLogAutoCloseDesc': 'Tiempo de desactivación automática para el seguimiento.',
  'oifde.sysMgr.dataClean.reportFlagTitle': 'Modificar grabación de casos de prueba',
  'oifde.sysMgr.dataClean.reportFlagDesc': 'Si se deben registrar los casos de prueba en un informe.',
  'oifde.sysMgr.dataClean.traceLogAutoClose': 'Editar el tiempo de desactivación automática para el seguimiento',
  'oifde.sysMgr.dataClean.paramValueTca': 'Valor del parámetro',
  'oifde.sysMgr.randomKey': 'Generar clave aleatoria',
  'oifde.sysMgr.apiKeyComfirm': 'Confirmación de clave API',
  'oifde.sysMgr.apiKeyNotSame': 'Las dos claves API son diferentes.',
  'oifde.sysMgr.encryptSecretCfg': 'Configuraciones clave',
  'oifde.sysMgr.encryptSecret': 'Clave secreta',
  'oifde.sysMgr.encryptSecretComfirm': 'Confirmación clave',
  'oifde.sysMgr.encryptSecretNotSame': 'Las claves ingresadas dos veces son inconsistentes',
  'oifde.sysMgr.inputEncryptSecret': 'Por favor ingrese la clave',
  'oifde.sysMgr.success_set_encryptSecret': 'La clave se configuró correctamente.',
  'oifde.sysMgr.encryptSecretRuleRange': 'La clave secreta no cumple con las especificaciones. Debe ser dos o más números, letras mayúsculas, letras minúsculas o caracteres especiales. El rango de caracteres especiales es ~`!{\'@\'}#{\'$\'}%^*()-+_={\'|\'},./<>?;\':"[]{\'{\'}{\'}\'}&',
  'oifde.sysMgr.encryptSecretNoSame': 'La clave secreta no puede ser la misma que la ID de arrendamiento',
  'oifde.sysMgr.encryptSecretLenCheck': 'La longitud de la clave es de 16 caracteres.',
  'oifde.sysMgr.parameterSetting': 'Configuración inteligente de parámetros del motor',
  'oifde.sysMgr.rpaCfg': 'Configuración de RPA',
  'oifde.sysMgr.rpaSecretKeyCfg': 'Llave secreta de RPA',
  'oifde.sysMgr.inputRpaUserName': 'Ingrese el nombre de usuario de RPA.',
  'oifde.sysMgr.requiredRpaUserName': 'Se requiere la cuenta de usuario de RPA.',
  'oifde.sysMgr.requiredRpaSecretKey': 'Se requiere la clave secreta de RPA.',
  'oifde.sysMgr.inputRpaSecretKey': 'Ingrese la clave secreta de RPA.',
  'oifde.sysMgr.success_set_rpa': 'La RPA ACCESS INFO se establece correctamente!',
  'oifde.sysMgr.rpaSecretKeyLenCheck': 'La clave secreta de RPA no puede contener más de 128 caracteres.',
  'oifde.sysMgr.rpaUserNameCfg': 'Cuenta de usuario de RPA',
  'oifde.sysMgr.rpaUserNameLenCheck': 'La cuenta de usuario de RPA no puede contener más de 64 caracteres.',
  'oifde.sysMgr.variableSecretNotExists': 'La clave es obligatoria cuando se utiliza una variable cifrada. Seleccione Administración del sistema > Configuración del sistema y haga clic en Configuración de clave para establecer la clave.',
  'oifde.sysMgr.apiNoSame': 'La contraseña no puede ser la misma que el ID del espacio del tenant.',
  'oifde.sysMgr.apiRuleRange': 'La contraseña debe contener al menos dos tipos de los siguientes: letras mayúsculas, minúsculas, dígitos y caracteres especiales ~ `! {\'@\'} # {\'$\'} % ^ * ( ) - +',
  'oifde.route.flow': 'Orquestación',
  'oifde.route.rule': 'Regla gris',
  'oifde.route.trace': 'Trayectoria',
  'oifde.route.flowAnalysis': 'Análisis de flujo',
  'oifde.route.ivrOverview': 'Visión general de IVR',
  'oifde.route.indicatorAnalysis': 'Análisis de indicadores',
  'oifde.route.versionComparisonAndAnalysis': 'Comparación y análisis de versiones',
  'oifde.route.optimizationSuggestions': 'Sugerencias de optimización',
  'oifde.route.alarmEvent': 'Evento de alarma',
  'oifde.route.addDiagram': 'Agregar flujo',
  'oifde.route.editCellDiagram': 'Editar Composite Canvas',
  'oifde.route.edit': 'Editar {0}',
  'oifde.route.view': 'Ver {0}',
  'oifde.route.editDiagram': 'Editar flujo',
  'oifde.route.viewDiagram': 'Ver flujo',
  'oifde.route.viewTraceDiagram': 'Ver seguimiento de flujos',
  'oifde.route.viewCellTraceDiagram': 'Ver seguimiento de elemento de diagrama compuesto',
  'oifde.route.voiceTemplateTitle': 'Plantillas',
  'oifde.route.staticCheck': 'Comprobar',
  'oifde.route.toc.sysadmin': 'Comprensión semántica',
  'oifde.route.toc.user': 'Comprensión semántica',
  'oifde.route.tuc.template.index': 'Gestión del conocimiento',
  'oifde.route.tuc.template.domain': 'Gestión de dominios',
  'oifde.route.tuc.template.intent': 'Gestión de intenciones',
  'oifde.route.tuc.template.entity': 'Gestión de entidades',
  'oifde.route.tuc.template.faq': 'Gestión de FAQ',
  'oifde.route.tuc.template.kg': 'Gráfico de conocimiento',
  'oifde.route.tuc.template.config': 'Configuración del sistema',
  'oifde.route.tuc.template.bwlist': 'Lista negra/Lista blanca',
  'oifde.route.tuc.history.index': 'Historial de diálogos',
  'oifde.route.tuc.history.dialog': 'Revisión del historial de mensajes',
  'oifde.route.tuc.history.mining': 'Tarea de clusterización de preguntas',
  'oifde.route.tuc.ops.index': 'Verificar entrenamiento',
  'oifde.route.tuc.ops.test': 'Prueba de diálogo',
  'oifde.route.tuc.ops.perf': 'Estadísticas de rendimiento',
  'oifde.route.tuc.ops.node': 'Mantenimiento',
  'oifde.route.tuc.ops.log': 'Registro operaciones',
  'oifde.route.tuc.ops.corpustest': 'Prueba de corpus',
  'oifde.route.tuc.ops.modelMgr': 'Gestión de modelos',
  'oifde.route.tuc.ops.tucTest': 'Prueba de conocimiento',
  'oifde.route.tuc.ops.server': 'Servidores',
  'oifde.route.tuc.ops.kgrule': 'Regla gris del conocimiento',
  'oifde.route.tuc.ops.license': 'Permisos',
  'oifde.route.tuc.ops.qiAsrServer': 'Inspección ASR',
  'oifde.route.tuc.ops.asr': 'TTS/ASR',
  'oifde.route.tuc.ops.avatar': 'Humano virtual 2D',
  'oifde.route.tuc.ops.sysparam': 'Parámetro inteligente del motor',
  'oifde.route.keyPressRobot': 'IVR común',
  'oifde.route.RobotManage': 'Chatbot inteligente',
  'oifde.route.accessCodeManage': 'Código de acceso',
  'oifde.route.sysMgr.sysMgr': 'Sistema',
  'oifde.route.sysMgr.sysCfg': 'Configuración',
  'oifde.route.sysMgr.whiteList': 'Lista blanca',
  'oifde.route.sysMgr.recordHistory': 'Llamada histórica',
  'oifde.route.navigation': 'Proceso',
  'oifde.route.resources': 'Recursos',
  'oifde.route.serviceInterface': 'Interfaz comercial',
  'oifde.route.variable': 'Variable',
  'oifde.route.cell': 'Célula compleja',
  'oifde.route.procedure': 'Procedimiento',
  'oifde.route.dataTable': 'Hoja de datos',
  'oifde.route.unusedResource.template': 'Ver plantilla sin usar',
  'oifde.route.unusedResource.globalvars': 'Ver variable global sin usar',
  'oifde.route.unusedResource.flowvars': 'Ver variable de flujo sin usar',
  'oifde.route.unusedResource.procedure': 'Ver procedimientos almacenados no utilizados',
  'oifde.route.unusedResource.rule': 'Ver reglas grises no utilizadas',
  'oifde.route.unusedResource.complexcell': 'Ver elementos de diagrama compuesto no utilizados',
  'oifde.route.unusedResource.interface': 'Ver interfaces no utilizadas',
  'oifde.route.knowledgegraph.knowledgegraph': 'Gráfico de conocimiento',
  'oifde.route.knowledgegraph.kgModel': 'Modelado de conocimiento',
  'oifde.route.knowledgegraph.editSchema': 'Detalles de esquema-{0}',
  'oifde.route.knowledgegraph.dicmgr': 'Diccionario gráfico',
  'oifde.route.knowledgegraph.entitymgr': 'Gestión de entidades',
  'oifde.route.knowledgegraph.entityAttribute': 'Atributo de entidad',
  'oifde.route.knowledgegraph.relationmgr': 'Gestión de relaciones',
  'oifde.route.knowledgegraph.relationAttribute': 'Atributo de relación',
  'oifde.route.knowledgegraph.kgView': 'Visualización de gráficos',
  'oifde.route.knowledgegraph.kgExport': 'Exportación gráficos',
  'oifde.route.knowledgegraph.importExcel': 'Importación gráficos',
  'oifde.route.knowledgegraph.kgFAQ': 'FAQ de gráficos',
  'oifde.route.knowledgegraph.kgSynonyms': 'Sinónimo',
  'oifde.route.knowledgegraph.extendQuestion': 'Pregunta ampliada',
  'oifde.flow.code': 'Código',
  'oifde.flow.name': 'Nombre',
  'oifde.flow.version': 'Versión',
  'oifde.flow.scenarioType': 'Tipo de escenario',
  'oifde.flow.status': 'Estado',
  'oifde.flow.rule': 'Regla gris',
  'oifde.flow.createTime': 'Fecha y hora de creación',
  'oifde.flow.updateTime': 'Fecha y hora de modificación',
  'oifde.flow.grayFlow': 'Gray',
  'oifde.flow.noRule': 'No hay regla configurada.',
  'oifde.flow.defaultFlow': 'Flujo predeterminado',
  'oifde.flow.actions.publish': 'Lanzar',
  'oifde.flow.actions.delete': 'Eliminar',
  'oifde.flow.actions.edit': 'Editar',
  'oifde.flow.actions.invalid': 'Invalidar',
  'oifde.flow.actions.upgrade': 'Actualizar',
  'oifde.flow.actions.copy': 'Copiar flujo',
  'oifde.flow.actions.copySuccess': 'Flujo copiado con éxito.',
  'oifde.flow.actions.copyFailed': 'Error al copiar el flujo.',
  'oifde.flow.actions.deleteConfirm': 'Esta operación eliminará el flujo de forma permanente. ¿Confirma que desea continuar?',
  'oifde.flow.actions.deleteCancel': 'Eliminación cancelada.',
  'oifde.flow.actions.deleteOk': 'Eliminación exitosa.',
  'oifde.flow.actions.invalidConfirm': 'Esta operación hará que el flujo no sea liberado. ¿Confirma que desea continuar?',
  'oifde.flow.actions.invalidOk': 'El flujo no ha sido publicado.',
  'oifde.flow.actions.invalidCancel': 'Invalidación cancelada.',
  'oifde.flow.actions.createOk': 'Nueva versión de flujo creada con éxito.',
  'oifde.flow.actions.settingOk': 'Process configuration succeeded.',
  'oifde.flow.actions.publishOk': 'Lanzado con éxito.',
  'oifde.flow.actions.confirm': 'Aceptar',
  'oifde.flow.actions.prompt': 'Sugerencias',
  'oifde.flow.actions.cancel': 'Cancelar',
  'oifde.flow.actions.staticcheck': 'Verific estática',
  'oifde.flow.actions.errorCode': 'Código de error',
  'oifde.flow.actions.error': 'Error en la operación.',
  'oifde.flow.actions.errorMessage': 'Mensaje de error',
  'oifde.flow.actions.setting': 'Conjunto',
  'oifde.flow.rules.ruleId': 'La regla es obligatoria.',
  'oifde.flow.rules.upgradeMode': 'El modo de lanzamiento es obligatorio.',
  'oifde.flow.rules.validationMode': 'Validación estricta obligatoria',
  'oifde.flow.rules.newVersion': 'La versión nueva es obligatoria.',
  'oifde.flow.rules.newVersionDuplicated': 'La versión nueva debe ser única.',
  'oifde.flow.rules.newVersionLength': 'La versión nueva no puede contener más de 64 caracteres.',
  'oifde.flow.rules.newFlowName': 'El nombre del flujo nuevo es obligatorio.',
  'oifde.flow.rules.logSwitchOn': 'Seleccione si desea habilitar la función de registro.',
  'oifde.flow.rules.workMode': 'Seleccione un modo de trabajo.',
  'oifde.flow.enums.scenarioType.mainFlow': 'Flujo principal',
  'oifde.flow.enums.scenarioType.errorFlow': 'Flujo de excepción',
  'oifde.flow.enums.scenarioType.subFlow': 'Subflujo',
  'oifde.flow.enums.status.init': 'Borrador',
  'oifde.flow.enums.status.valid': 'Lanzar',
  'oifde.flow.enums.status.invalid': 'Invalidar',
  'oifde.flow.enums.tabsValue.all': 'Exportar todo',
  'oifde.flow.enums.tabsValue.none': 'Sin exportar',
  'oifde.flow.enums.tabsValue.depend': 'Exportación de dependencia',
  'oifde.flow.publish.flowName': 'Nombre',
  'oifde.flow.publish.flowVersion': 'Versión',
  'oifde.flow.publish.upgradeMode': 'Modo de lanzamiento',
  'oifde.flow.publish.upgrade': 'Upgrade y lanzar',
  'oifde.flow.publish.gray': 'Versión intermedia',
  'oifde.flow.publish.ruleName': 'Regla de escala de grises',
  'oifde.flow.publish.selectPlaceholder': 'Seleccionar',
  'oifde.flow.publish.insert': 'Inserción',
  'oifde.flow.publish.monitor': 'Monitoreo',
  'oifde.flow.publish.notConfig': 'No configurado',
  'oifde.flow.publish.publishMode': 'Modo de trabajo',
  'oifde.flow.publish.normal': 'Frecuentes',
  'oifde.flow.copy.flowName': 'Nombre de flujo original',
  'oifde.flow.copy.newFlowName': 'Nombre de flujo nuevo',
  'oifde.flow.setting.title': 'Ajustes',
  'oifde.flow.setting.validationMode': 'Validación estricta',
  'oifde.flow.setting.validationModeOn': 'Habilitada',
  'oifde.flow.setting.validationModeOff': 'Deshabilitada',
  'oifde.flow.setting.logSwitchOff': 'Desactivar',
  'oifde.flow.setting.logSwitchOn': 'Activar',
  'oifde.flow.setting.logSwitch': 'Función de registro',
  'oifde.flow.upgrade.currentVersions': 'Lista de versiones actuales',
  'oifde.flow.upgrade.newVersion': 'Versión nueva',
  'oifde.flow.exportFlowList.accessCode': 'Código de acceso',
  'oifde.flow.exportFlowList.rule': 'Regla',
  'oifde.flow.exportFlowList.template': 'Plantilla',
  'oifde.flow.exportFlowList.interface': 'Interfaz',
  'oifde.flow.exportFlowList.variable': 'Variable',
  'oifde.flow.exportFlowList.title': 'No ha seleccionado registros específicos. ¿Exportar todos los registros? También puede cancelar la operación y seleccionar un registro específico para exportar.',
  'oifde.flow.exportFlowList.askExportAll': 'No ha seleccionado ningún registro. ¿Desea exportar todos los registros?También puede seleccionar un registro específico para la exportación después de cancelar el registro.',
  'oifde.flow.exportFlowList.prompt': 'Aviso',
  'oifde.flow.exportFlowList.promptMessage': 'Seleccione al menos un registro.',
  'oifde.flow.exportFlowList.export': 'Exportar',
  'oifde.flow.exportFlowList.exportAll': 'Exportar todo',
  'oifde.flow.exportFlowList.procedure': 'Procedimiento almacenado',
  'oifde.flow.exportFlowList.complexCell': 'Elemento de diagrama compuesto',
  'oifde.flow.importFlow.title': 'Importar flujo',
  'oifde.flow.importFlow.select': 'Seleccionar archivo',
  'oifde.flow.importFlow.upload': 'Cargar',
  'oifde.flow.importFlow.prompt': 'Se puede importar un archivo ZIP.',
  'oifde.flow.importFlow.cover': 'Permitir nombres duplicados',
  'oifde.flow.importFlow.name': 'Nombre',
  'oifde.flow.importFlow.type': 'Tipo',
  'oifde.flow.importFlow.result': 'Resultado',
  'oifde.flow.importFlow.message': 'Seleccione el archivo que desea cargar.',
  'oifde.flow.importFlow.fileType': 'El formato del archivo que desea cargar debe ser ZIP.',
  'oifde.flow.importFlow.import': 'Importar',
  'oifde.flow.importFlow.success': 'Operation successful.',
  'oifde.flow.importFlow.bigFile': 'El archivo que desea cargar es demasiado grande.',
  'oifde.flow.importFlow.uploadFail': 'Error al cargar.',
  'oifde.flow.importFlow.bigFilePrompt': 'El tamaño del archivo que desea cargar supera los 20 MB.',
  'oifde.flow.importFlow.error': 'Iniciar sesión',
  'oifde.flow.importFlow.resultTitle': 'Importar resultados',
  'oifde.flow.importFlow.timeout': 'Importando datos... Por favor, consulte los datos más recientes más adelante.',
  'oifde.flow.usage.usageStatus': 'Estado de estadísticas:',
  'oifde.flow.usage.lasttime': 'Última realización:',
  'oifde.flow.usage.check': 'Verificar',
  'oifde.flow.usage.showUnused': 'Ver recursos sin usar',
  'oifde.flow.usage.templateUsageTitle': 'Estadísticas de uso de plantilla',
  'oifde.flow.usage.globalVarsUsageTitle': 'Estadísticas de uso de variables globales',
  'oifde.flow.usage.flowVarsUsageTitle': 'Estadísticas de uso de variables de flujos',
  'oifde.flow.usage.inuse': 'En uso',
  'oifde.flow.usage.unuse': 'Sin usar',
  'oifde.flow.usage.status.norecord': 'Sin registros',
  'oifde.flow.usage.status.init': 'Por ejecutar',
  'oifde.flow.usage.status.running': 'En ejecución',
  'oifde.flow.usage.status.finish': 'Finalizado',
  'oifde.flow.usage.status.fail': 'Error',
  'oifde.flow.usage.name': 'Nombre',
  'oifde.flow.usage.type': 'Tipo',
  'oifde.flow.usage.desc': 'Descripción',
  'oifde.flow.usage.resourceType.template': 'Plantilla',
  'oifde.flow.usage.resourceType.globalvars': 'Variable global',
  'oifde.flow.usage.resourceType.flowvars': 'Variable de flujo',
  'oifde.flow.usage.resourceType.complexcell': 'Elemento de diagrama compuesto',
  'oifde.flow.usage.resourceType.interface': 'Interfaz',
  'oifde.flow.usage.resourceType.rule': 'Regla gris',
  'oifde.flow.usage.resourceType.procedure': 'Procedimiento almacenado',
  'oifde.flow.usage.procedureUsageTitle': 'Uso del procedimiento almacenado',
  'oifde.flow.usage.ruleUsageTitle': 'Uso de reglas grises',
  'oifde.flow.usage.complexcellUsageTitle': 'Uso del elemento de diagrama compuesto',
  'oifde.flow.usage.interfaceUsageTitle': 'Uso de la interfaz',
  'oifde.flow.flowTypeSelect': 'Seleccionar nueva plantilla de flujo',
  'oifde.flow.add.exceptionFlow': 'Flujo anormal',
  'oifde.flow.add.callInFlow': 'Flujo de llamadas entrantes',
  'oifde.flow.add.blankFlow': 'Flujo en blanco',
  'oifde.flow.add.outBoundFlow': 'Flujo de llamadas salientes',
  'oifde.flow.flowTemplate': 'Plantilla de flujo',
  'oifde.template.template': 'Administración de plantillas de recursos',
  'oifde.template.channelTypeMgr': 'Gestión de tipo de canal',
  'oifde.template.responseStyleMgr': 'Manejo de estilo',
  'oifde.template.channelTypeName': 'Nombre del canal',
  'oifde.template.createChannelType': 'Nuevo canal',
  'oifde.template.editChannelType': 'Editar canal',
  'oifde.template.responseStyleName': 'Nombre de estilo',
  'oifde.template.createResponseStyle': 'Nuevo estilo',
  'oifde.template.editResponseStyle': 'Estilo de edición',
  'oifde.template.selectChannelType': 'Selección de canales',
  'oifde.template.selectResponseStyle': 'Selección de estilo',
  'oifde.template.selectLang': 'Selección de idioma',
  'oifde.template.editContent': 'Agregar contenido de plantilla',
  'oifde.template.setDefault': 'Establecer como predeterminado',
  'oifde.template.name': 'Nombre',
  'oifde.template.type': 'Tipo de plantilla',
  'oifde.template.desc': 'Descripción',
  'oifde.template.content': 'Contenido de la plantilla',
  'oifde.template.createTime': 'Fecha y hora de creación',
  'oifde.template.updateTime': 'Fecha y hora de modificación',
  'oifde.template.detail': 'Detalles del contenido de la plantilla',
  'oifde.template.select': 'Tipo de plantilla',
  'oifde.template.contentName': 'Contenido',
  'oifde.template.deleteContent': 'Eliminar',
  'oifde.template.addContent': 'Agregar contenido de plantilla',
  'oifde.template.tipContent': 'Ingrese el contenido del texto aquí.',
  'oifde.template.tipForVoice': 'Ingrese la ruta completa del archivo de voz.',
  'oifde.template.tipForVideo': 'Ingrese la ruta completa del archivo de video.',
  'oifde.template.addLimit': 'Se permiten tres registros de contenido como máximo.',
  'oifde.template.deleteLimit': 'Se requiere al menos un registro de contenido.',
  'oifde.template.addNewIntent': 'Agregar intención',
  'oifde.template.setIntentTemplate': 'Configuración de plantilla de intenciones',
  'oifde.template.addTopNode': 'Agregar nodo superior',
  'oifde.template.selectTemplate': 'Selección de plantilla',
  'oifde.template.selectAction': 'Seleccione',
  'oifde.template.actions.create': 'Agregar',
  'oifde.template.actions.delete': 'Eliminar',
  'oifde.template.actions.edit': 'Editar',
  'oifde.template.actions.editbaseInfo': 'Editar plantilla de recurso',
  'oifde.template.actions.addbaseInfo': 'Agregar plantilla de recurso',
  'oifde.template.actions.success': 'Con éxito',
  'oifde.template.actions.fail': 'Failure',
  'oifde.template.actions.createSuccess': 'Creación exitosa.',
  'oifde.template.actions.updateSuccess': 'Modificación exitosa.',
  'oifde.template.actions.deleteSuccess': 'Eliminación exitosa.',
  'oifde.template.actions.cancelDelete': 'Eliminación cancelada.',
  'oifde.template.actions.deletePrompt': 'Esta operación eliminará la plantilla de forma permanente. ¿Confirma que desea continuar?',
  'oifde.template.actions.deleteChannelType': 'Esta operación eliminará la plantilla de Canal. ¿Confirma que desea continuar?',
  'oifde.template.actions.deleteResponseStyle': 'Esta operación eliminará la plantilla de forma Estilo. ¿Confirma que desea continuar?',
  'oifde.template.actions.prompt': 'Aviso',
  'oifde.template.actions.confirm': 'Aceptar',
  'oifde.template.actions.cancel': 'Cancelar',
  'oifde.template.actions.append': 'Agregar',
  'oifde.template.actions.saveSuccess': 'La plantilla se guarda correctamente.',
  'oifde.template.update.templateName': 'Nombre de la plantilla',
  'oifde.template.update.templateType': 'Tipo de plantilla',
  'oifde.template.update.templateDesc': 'Descripción',
  'oifde.template.update.intents': 'Lista de intenciones',
  'oifde.template.typeDetail.tts': 'TTS',
  'oifde.template.typeDetail.sms': 'SMS',
  'oifde.template.typeDetail.staticvoice': 'Voz estática',
  'oifde.template.typeDetail.videovoice': 'Archivo de video',
  'oifde.template.typeDetail.multiMedia': 'Multimedia',
  'oifde.template.typeDetail.cardTemplate': 'Plantilla de tarjeta',
  'oifde.template.language.chinese': 'Chino',
  'oifde.template.language.english': 'Inglés',
  'oifde.template.language.spanish': 'Español',
  'oifde.template.validate.templateName': 'El nombre de la plantilla es obligatorio.',
  'oifde.template.validate.templateType': 'El tipo de plantilla es obligatorio.',
  'oifde.template.validate.doubleNameCheck': 'El nombre de la plantilla ya existe.',
  'oifde.template.validate.intentDsp': 'La descripción de la intención es obligatoria.',
  'oifde.template.validate.intentcode': 'El código de intención es obligatorio.',
  'oifde.template.validate.nameDup': 'Nombre ya existe',
  'oifde.template.validate.templateNameLength': 'El nombre de la plantilla no puede contener más de 256 caracteres.',
  'oifde.template.validate.templateContentLength': 'El contenido de la plantilla no puede contener más de 2048 caracteres.',
  'oifde.template.validate.content': 'El contenido de la plantilla es obligatorio.',
  'oifde.template.validate.templateDescLength': 'La descripción de la plantilla no puede contener más de 1024 caracteres.',
  'oifde.template.validate.contentLengthError': 'El contenido de la plantilla debe contener de 1 a 4096 caracteres.',
  'oifde.template.intentionInfo.addIntent': 'Agregar intención',
  'oifde.template.intentionInfo.intentDsp': 'Descripción de la intención',
  'oifde.template.intentionInfo.intentcode': 'Código de intención',
  'oifde.template.intentionInfo.intentList': 'Lista de intenciones',
  'oifde.template.message.intentListLength': 'La lista de intenciones no puede contener más de 4096 caracteres.',
  'oifde.template.message.intentNameDuplicate': 'El nombre de la intención está duplicado.',
  'oifde.template.message.validateResult': 'Aviso',
  'oifde.template.voicename.cn_kefuman_common': 'Elegante',
  'oifde.template.voicename.cn_chengshuxing_common': 'Maduras',
  'oifde.template.voicename.cn_tianmeiru_common': 'Dulce',
  'oifde.template.voicename.cn_ziranzhen_common': 'Naturales',
  'oifde.template.voicename.cn_roumeiqian_common': 'Suave',
  'oifde.template.defaultValueValidate.ttsPitchValueDesc': 'Rango de valores : [-500,500]',
  'oifde.template.defaultValueValidate.ttsVolumeValueDesc': 'Rango de valores : [0,100]',
  'oifde.template.defaultValueValidate.ttsSpeedValueDesc': 'Rango de valores : [-500,500]',
  'oifde.template.aiccTemplateNullTips': 'El tipo de plantilla no puede estar vacío.',
  'oifde.template.setInputFlag': 'Introduzca el contenido manualmente',
  'oifde.template.sysErrorTemplateTitle': 'Plantilla de recursos anormales',
  'oifde.template.sysErrorTemplate': 'Editar plantilla de recursos anormales',
  'oifde.template.selectLanguage': 'Seleccione un idioma. Si desea eliminar un idioma, haga clic en el icono de cerrar.',
  'oifde.template.contentDetail': 'Detalles',
  'oifde.template.content.exist': 'Se ha agregado el contenido de la plantilla seleccionada actualmente',
  'oifde.template.selectResource': 'Seleccionar recurso',
  'oifde.template.message.searchResourceName': 'Nombre del recurso de búsqueda',
  'oifde.template.title.ResourceName': 'Nombre del recurso',
  'oifde.template.title.ResourceType': 'Tipo de recurso',
  'oifde.template.multipart.type.location': 'Dirección',
  'oifde.rule.flowRule': 'Procesar reglas de escala de grises',
  'oifde.rule.kgRule': 'Conocimiento gris reglas',
  'oifde.rule.name': 'Nombre de la regla',
  'oifde.rule.beginNum': 'Número de inicio',
  'oifde.rule.endNum': 'Número de fin',
  'oifde.rule.language': 'Idioma',
  'oifde.rule.select': 'Seleccionar',
  'oifde.rule.number': 'Cantidad',
  'oifde.rule.manage': 'Gestionar',
  'oifde.rule.actions.create': 'Agregar',
  'oifde.rule.actions.delete': 'Eliminar',
  'oifde.rule.actions.edit': 'Editar',
  'oifde.rule.actions.success': 'Con éxito',
  'oifde.rule.actions.addSuccess': 'Agregado con éxito.',
  'oifde.rule.actions.updateSuccess': 'Actualizado con éxito.',
  'oifde.rule.actions.deleteSuccess': 'Eliminado con éxito.',
  'oifde.rule.actions.editbaseInfo': 'Edit Basic Info',
  'oifde.rule.actions.addbaseInfo': 'Crear información básica',
  'oifde.rule.actions.confirm': 'Sugerencias',
  'oifde.rule.actions.sure': 'Aceptar',
  'oifde.rule.actions.cancel': 'Cancelar',
  'oifde.rule.actions.cancelDelete': 'Eliminación cancelada.',
  'oifde.rule.actions.deleteConfirm': 'Esta operación eliminará la regla de forma permanente. ¿Confirma que desea continuar?',
  'oifde.rule.actions.deleteFailed': 'Deletion failed. Check whether the rule is associated with a release process. Cancel the release first.',
  'oifde.rule.actions.errorMessage': 'Mensaje de error',
  'oifde.rule.actions.error': 'Se ha producido un error al invocar la interfaz.',
  'oifde.rule.actions.errorCode': 'Código de error',
  'oifde.rule.actions.viewBoundFlow': 'Visualización de procesos enlazados',
  'oifde.rule.rules.ruleNameValidator': 'El nombre de la regla ya existe.',
  'oifde.rule.rules.beginNumValidator': 'Se permite solo un número.',
  'oifde.rule.rules.endNumValidator': 'Se permite solo un número.',
  'oifde.rule.rules.beginNumLengthValidator': 'El número de fin debe ser mayor que el número de inicio.',
  'oifde.rule.update.ruleName': 'Nombre de la regla',
  'oifde.rule.update.beginNum': 'Número de inicio',
  'oifde.rule.update.newRuleName': 'El nombre de la regla es obligatorio.',
  'oifde.rule.update.newBeginNum': 'El número de inicio es obligatorio.',
  'oifde.rule.update.newEndNum': 'El número de fin es obligatorio.',
  'oifde.rule.update.endNum': 'Número de fin',
  'oifde.rule.message.add_name_list': 'Agregar lista blanca/lista negra',
  'oifde.rule.message.name_list_info': 'Información de lista blanca/lista negra',
  'oifde.rule.message.succeed_save': 'La lista blanca/lista negra se guardó correctamente.',
  'oifde.rule.message.fail_save': 'Error al guardar la lista blanca/lista negra.',
  'oifde.rule.message.failed_query': 'Error al consultar.',
  'oifde.rule.message.select_prompt': 'Seleccionar al menos un registro.',
  'oifde.rule.message.confirm_name_list': '¿Confirma que desea eliminar las listas blancas/listas negras?',
  'oifde.rule.message.succeed_delete': 'Las listas blancas/listas negras se eliminaron correctamente.',
  'oifde.rule.message.fail_delete': 'No se pudieron eliminar las listas blancas/listas negras.',
  'oifde.rule.message.mgr_name_list': 'Gestionar listas blancas/listas negras',
  'oifde.rule.message.add_user_ids': 'Agregar número de abonado',
  'oifde.rule.message.batch_input_prompt': 'Cada vez que presione Intro, se agregará un registro.',
  'oifde.rule.message.user_number': 'Número de usuario',
  'oifde.rule.message.user_ids_number': 'Lista de números de usuario en blanco y negro hasta 5000',
  'oifde.rule.message.user_ids_number_once': 'El número máximo de números de usuario de la lista en blanco y negro es 100 a la vez.',
  'oifde.rule.message.user_id_not_exceed': 'Un número de abonado puede contener hasta 64 caracteres.',
  'oifde.rule.message.save_user_ids_success': 'Los números de abonado se guardaron correctamente.',
  'oifde.rule.message.save_user_ids_fail': 'Error al guardar los números de abonado.',
  'oifde.rule.message.confirm_delete_user_ids': '¿Confirma que desea eliminar los números de abonado?',
  'oifde.rule.message.succeed_delete_user_ids': 'Los números de abonado se eliminaron correctamente.',
  'oifde.rule.message.failed_delete_user_ids': 'Error al eliminar los números de abonado.',
  'oifde.rule.message.import_user_ids': 'Importar número de abonado',
  'oifde.rule.message.import_words_hint_text': 'Se pueden importar archivos de texto (*.txt). El formato de codificación de archivos es UTF-8.',
  'oifde.rule.message.overwrite_import': 'Sobrescribir configuración existente',
  'oifde.rule.message.success_import': 'Importación realizada.',
  'oifde.rule.message.failed_import_file': 'Error al importar el archivo.',
  'oifde.accesscode.accessCode': 'Código de acceso del robot',
  'oifde.accesscode.accessCodeDesc': 'Descripción',
  'oifde.accesscode.accessCodeSearch': 'Nombre del Chatbot o código de acceso',
  'oifde.accesscode.description': 'Descripción',
  'oifde.accesscode.flowCode': 'Código de flujo',
  'oifde.accesscode.flowName': 'Nombre del flujo',
  'oifde.accesscode.domainId': 'ID de dominio',
  'oifde.accesscode.dialogType': 'Tipo de diálogo',
  'oifde.accesscode.callingnumber': 'Número que llama',
  'oifde.accesscode.callednumber': 'Número llamado:',
  'oifde.accesscode.processTimes': 'Número de llamadas procesadas solo por el agente',
  'oifde.accesscode.startcall': 'Iniciar llamada',
  'oifde.accesscode.sendprompt': 'Presione Enter',
  'oifde.accesscode.callingnumberinput': 'Ingrese el número que llama',
  'oifde.accesscode.actions.edit': 'Editar',
  'oifde.accesscode.actions.testCalledNum': 'Prueba',
  'oifde.accesscode.actions.delete': 'Eliminar',
  'oifde.accesscode.actions.editbaseInfo': 'Editar código de acceso',
  'oifde.accesscode.actions.addbaseInfo': 'Agregar código de acceso',
  'oifde.accesscode.actions.success': 'Con éxito',
  'oifde.accesscode.actions.createSuccess': 'Creado con éxito.',
  'oifde.accesscode.actions.updateSuccess': 'Modificado con éxito.',
  'oifde.accesscode.actions.deleteSuccess': 'Eliminado con éxito.',
  'oifde.accesscode.actions.cancelDelete': 'Eliminación cancelada',
  'oifde.accesscode.actions.deletePrompt': 'Este código de acceso se eliminará de forma permanente. ¿Desea continuar?',
  'oifde.accesscode.actions.prompt': 'Aviso',
  'oifde.accesscode.actions.confirm': 'Aceptar',
  'oifde.accesscode.actions.cancel': 'Cancelar',
  'oifde.accesscode.actions.flowChatbot': 'Chatbot de prueba de flujo',
  'oifde.accesscode.actions.callforwarding': 'Transferencia de llamada',
  'oifde.accesscode.actions.voicefile': 'Archivo de voz:',
  'oifde.accesscode.actions.videofile': 'Archivo de video',
  'oifde.accesscode.actions.fail': 'Fallo',
  'oifde.accesscode.dialogTypeDetail.voiceNavigation': 'Navegación por voz',
  'oifde.accesscode.dialogTypeDetail.smartOutboundCall': 'Llamada saliente inteligente',
  'oifde.accesscode.dialogTypeDetail.chatbot': 'Chatbot',
  'oifde.accesscode.dialogTypeDetail.ivrProcess': 'Flujo IVR',
  'oifde.accesscode.validate.accessCode': 'El código de acceso al flujo es obligatorio.',
  'oifde.accesscode.validate.accessCodeCharReg': 'Solo se permiten letras y dígitos.',
  'oifde.accesscode.validate.doubleAccessCodeCheck': 'El código de acceso ya existe.',
  'oifde.accesscode.validate.dialogType': 'El tipo de diálogo es obligatorio.',
  'oifde.accesscode.validate.flowCode': 'El flujo es obligatorio.',
  'oifde.accesscode.validate.domainIdMax': 'El ID de dominio es mayor que a 2147483647.',
  'oifde.accesscode.validate.domainIdCheck': 'El ID de dominio no es un número válido.',
  'oifde.accesscode.validate.accessCodeLength': 'El código de acceso puede contener un máximo de 20 caracteres.',
  'oifde.accesscode.validate.descriptionLengthValidator': 'La descripción del código de acceso puede contener un máximo de 512 caracteres.',
  'oifde.accesscode.validate.flowCodeLength': 'El código de flujo puede contener un máximo de 64 caracteres.',
  'oifde.accesscode.robotName': 'Nombre del Chatbot',
  'oifde.accesscode.bindDomain': 'Dominio de enlace',
  'oifde.accesscode.traceClose': 'Desactivar',
  'oifde.accesscode.recordState.enable': 'Activar',
  'oifde.accesscode.accessCodeSubscribed': 'Se ha utilizado el código de acceso del robot',
  'oifde.accesscode.bindScene': 'Ver referencias',
  'oifde.accesscode.chatBot.search': 'Buscar',
  'oifde.accesscode.chatBot.reset': 'Restablecer',
  'oifde.accesscode.searchScene': 'Seleccione un escenario.',
  'oifde.accesscode.sceneName': 'Cenários de uso',
  'oifde.accesscode.subName': 'Nombre de referencia',
  'oifde.accesscode.createTime': 'Hora da referência',
  'oifde.accesscode.chatBot.chatChanel': 'Canal multimedia',
  'oifde.accesscode.chatBot.agentAssistant': 'Asistente de agente',
  'oifde.accesscode.chatBot.intelligentOB': 'Llamada saliente inteligente',
  'oifde.accesscode.chatBot.intelligentTrain': 'Formación práctica inteligente',
  'oifde.accesscode.recordState.disable': 'Desactivar',
  'oifde.accesscode.knowledgeGraph': 'Gráfico de Conocimiento',
  'oifde.accesscode.setTraceSuccess': 'La configuración se aplica correctamente.',
  'oifde.accesscode.callingNumberTrace': 'Rastreo del número de llamadas:',
  'oifde.accesscode.hideTraceSet': 'Contraer',
  'oifde.accesscode.bindKg': 'Gráfico de Conocimiento Vincular',
  'oifde.accesscode.isCheckHelp': 'Los errores de ortografía en las declaraciones de los clientes se corrigen automáticamente antes de que se reconozca la intención.',
  'oifde.accesscode.faqGroup': 'Grupo de Preguntas Frecuentes',
  'oifde.accesscode.domain': 'Dominio',
  'oifde.accesscode.traceOpen': 'Activar',
  'oifde.accesscode.bindFaqGroup': 'Grupo de preguntas frecuentes de enlace',
  'oifde.accesscode.isCheck': 'Comprobar ortografía',
  'oifde.accesscode.trl': 'Escriba de derecha a izquierda',
  'oifde.accesscode.usingRecord': 'Captura de paquetes',
  'oifde.accesscode.setTrace': 'Configuración de seguimiento',
  'oifde.accesscode.descPlaceholder': 'Este Descripción se utiliza como la descripción del flujo cuando el flujo se selecciona como la configuración de la parte llamada. El valor debe ser único a nivel mundial.',
  'oifde.accesscode.messageTips': 'Por favor, tómese un momento para proporcionar comentarios sobre las mejoras que le gustaría ver.',
  'oifde.accesscode.more': 'Más',
  'oifde.accesscode.sendContent': 'Envíanos',
  'oifde.toc.statusCode.NEED_LOGIN': 'Usted no ha iniciado sesión o no ha realizado operaciones durante un período prolongado. Vuelva a iniciar sesión.',
  'oifde.toc.statusCode.ACCOUNT_INCONSISTENT': 'Ha iniciado sesión en el equipo local con otra cuenta. Para continuar con el servicio, vuelva a iniciar sesión.',
  'oifde.toc.statusCode.ACCOUNT_SWITCHED': 'La cuenta de inicio de sesión se modificó. La cuenta de inicio de sesión más reciente se mostrará en la página actual.',
  'oifde.toc.statusCode.INCORRECT_VERIFYCODE': 'El código de verificación es incorrecto. Ingrese un código correcto.',
  'oifde.toc.statusCode.INVALID_JSON': 'La solicitud no está en formato JSON.',
  'oifde.toc.statusCode.HTTPS_REQUIRED': 'Utilice HTTPS para tener acceso.',
  'oifde.toc.statusCode.EMPTY_ACCOUNT': 'El número de cuenta está vacío.',
  'oifde.toc.statusCode.INVALID_ACCOUNT': 'Error de autenticación. Vuelva a iniciar sesión.',
  'oifde.toc.statusCode.INSUFFICIENT_PERMISSION': 'Permisos insuficientes. Comuníquese con el administrador para solicitar permisos.',
  'oifde.toc.statusCode.VERIFYCODE_EXPIRED': 'El código de verificación expiró. Vuelva a intentarlo más tarde.',
  'oifde.toc.statusCode.ACCOUNT_LOCKED': 'La cuenta se bloqueó temporalmente. Vuelva a intentarlo más tarde o comuníquese con el administrador para desbloquearla.',
  'oifde.toc.statusCode.ACCOUNT_CHANGED': 'La cuenta se cambió. Vuelva a iniciar sesión.',
  'oifde.toc.statusCode.ACCOUNT_DISABLED': 'La cuenta está suspendida. Comuníquese con el administrador.',
  'oifde.toc.statusCode.UNLOCK_REQUIRED': 'La cuenta se ha bloqueado temporalmente. Primero desbloquee la cuenta.',
  'oifde.toc.statusCode.ADMIN_DISABLED': 'Se deshabilitó la función de gestión. Utilice una cuenta común para iniciar sesión en el sistema.',
  'oifde.toc.statusCode.SESSION_OVERFLOW': 'Se ha alcanzado la cantidad máxima de usuarios en línea. Inténtelo otra vez más tarde.',
  'oifde.toc.statusCode.SESSION_EXPIRED': 'El inicio de sesión actual no es válido. Vuelva a iniciar sesión.',
  'oifde.toc.statusCode.SESSION_DUPLICATED': 'Usted ya inició sesión. Si quiere volver a iniciar sesión, cierre la sesión en curso.',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_ID': 'ID de espacio de tenant incorrecto.',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_NAME': 'Nombre de espacio de tenant incorrecto.',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT': 'Cuenta incorrecta.',
  'oifde.toc.statusCode.INCORRECT_PASSWORD': 'La contraseña no cumple con los requerimientos de reglas de contraseña.',
  'oifde.toc.statusCode.INCORRECT_GENDER': 'Sexo incorrecto.',
  'oifde.toc.statusCode.INCORRECT_OWNER': 'Propietario incorrecto.',
  'oifde.toc.statusCode.INCORRECT_TITLE': 'La longitud del cargo no puede superar los 8 caracteres.',
  'oifde.toc.statusCode.INCORRECT_TELEPHONE': 'Número de teléfono incorrecto.',
  'oifde.toc.statusCode.INCORRECT_EMAIL': 'Dirección de correo incorrecta.',
  'oifde.toc.statusCode.INCORRECT_ROLE_NAME': 'Nombre de rol incorrecto.',
  'oifde.toc.statusCode.EMPTY_TENANT_SPACE': 'El espacio de tenant está vacío.',
  'oifde.toc.statusCode.ACCOUNT_DUPLICATED': 'La cuenta ya existe.',
  'oifde.toc.statusCode.ROLE_DUPLICATED': 'El rol ya existe.',
  'oifde.toc.statusCode.TENANT_SPACE_DUPLICATED': 'El espacio de tenant ya existe.',
  'oifde.toc.statusCode.ACCOUNT_NOT_FOUND': 'La cuenta no existe.',
  'oifde.toc.statusCode.ROLE_NOT_FOUND': 'El rol no existe.',
  'oifde.toc.statusCode.TENANT_SPACE_NOT_FOUND': 'El espacio del tenant no existe.',
  'oifde.toc.statusCode.ACCOUNT_FORBIDDEN': 'No realice operaciones en la cuenta.',
  'oifde.toc.statusCode.ROLE_FORBIDDEN': 'No realice operaciones en el rol.',
  'oifde.toc.statusCode.TENANT_SPACE_FORBIDDEN': 'No realice operaciones en el espacio de tenant.',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT_INPUT': 'La cuenta debe incluir de 6 a 32 caracteres, incluidos letras, dígitos y los siguientes caracteres especiales: . {\'@\'} _ -',
  'oifde.toc.statusCode.INCORRECT_PASSWORD_UPDATE': 'La cantidad de caracteres repetidos consecutivos en la contraseña no puede ser superior a 3. Además, la contraseña nueva de un usuario existente no puede ser igual a ninguna de las últimas tres contraseñas. La contraseña debe contener entre 8 y 20 caracteres y debe contener dígitos, letras minúsculas, letras mayúsculas y alguno de los siguientes caracteres especiales: ~`!{\'@\'}#{\'$\'}%^*()-+_={\'|\'},./<>?;\':"[]{\'{\'}{\'}\'}\\&',
  'oifde.toc.statusCode.COMMON_ERROR': 'Error del servidor.',
  'oifde.toc.statusCode.SERVICE_UNAVAILABLE': 'Servicio no disponible',
  'oifde.toc.statusCode.AUTHORIZE_FAILED': 'Error de validación',
  'oifde.toc.statusCode.TRANSIT_FAILED': 'Finalizó el tiempo de espera para el reenvío de solicitudes.',
  'oifde.toc.statusCode.TRANSIT_ERROR': 'La solicitud se reenvió correctamente, pero el extremo opuesto devuelve un error.',
  'oifde.toc.statusCode.RESPONSE_FAILED': 'El servidor no puede procesar la respuesta.',
  'oifde.toc.statusCode.REFRESH_TIMEOUT': 'No se realizó ninguna operación durante mucho tiempo. Vuelva a iniciar sesión.',
  'oifde.toc.statusCode.SERVER_BUSY': 'El sistema está ocupado. Inténtelo más tarde.',
  'oifde.logparam.logParamMgr': 'Configuración de parámetros de registros Flume',
  'oifde.logparam.logParam': 'Configuración de parámetros dinámicos',
  'oifde.logparam.logName': 'Nombre del parámetro',
  'oifde.logparam.source': 'Fuente del parámetro',
  'oifde.logparam.sourceName': 'Nombre de fuente de parámetro',
  'oifde.logparam.transFlag': 'Convertir valor',
  'oifde.logparam.sourceValue': 'Valor de origen',
  'oifde.logparam.targetValue': 'Valor de destino',
  'oifde.logparam.actions.edit': 'Editar',
  'oifde.logparam.actions.transDetail': 'Convertir valor',
  'oifde.logparam.actions.delete': 'Eliminar',
  'oifde.logparam.actions.editLogParamBaseInfo': 'Editar parámetro dinámico',
  'oifde.logparam.actions.addLogParamBaseInfo': 'Agregar parámetro dinámico',
  'oifde.logparam.actions.success': 'Con éxito',
  'oifde.logparam.actions.createSuccess': 'Creado con éxito.',
  'oifde.logparam.actions.updateSuccess': 'Modificado con éxito.',
  'oifde.logparam.actions.deleteSuccess': 'Eliminado con éxito.',
  'oifde.logparam.actions.cancelDelete': 'Eliminación cancelada.',
  'oifde.logparam.actions.deletePrompt': 'Este parámetro dinámico se eliminará de forma permanente. ¿Desea continuar?',
  'oifde.logparam.actions.prompt': 'Aviso',
  'oifde.logparam.actions.confirm': 'Aceptar',
  'oifde.logparam.actions.cancel': 'Cancelar',
  'oifde.logparam.actions.add': 'Agregar',
  'oifde.logparam.sourceDetail.IVRRequestInformation': 'Solicitud de IVR',
  'oifde.logparam.sourceDetail.IVRResponseInformation': 'Respuesta de IVR',
  'oifde.logparam.sourceDetail.TOCRequestInformation': 'Solicitud de TOC',
  'oifde.logparam.sourceDetail.TOCResponseInformation': 'Respuesta de TOC',
  'oifde.logparam.sourceDetail.InterfaceRequestInformation': 'Solicitud de interfaz',
  'oifde.logparam.sourceDetail.InterfaceResponseInformation': 'Respuesta de interfaz',
  'oifde.logparam.transFlagDetail.YES': 'Sí',
  'oifde.logparam.transFlagDetail.NO': 'No',
  'oifde.logparam.validate.logName': 'El nombre del parámetro es obligatorio.',
  'oifde.logparam.validate.source': 'El origen del parámetro es obligatorio.',
  'oifde.logparam.validate.sourceName': 'El nombre de origen del parámetro es obligatorio.',
  'oifde.logparam.validate.sourceValue': 'El valor de origen es obligatorio.',
  'oifde.logparam.validate.targetValue': 'El valor de destino es obligatorio.',
  'oifde.logparam.validate.doublelogNameCheck': 'El nombre del parámetro dinámico ya existe.',
  'oifde.logparam.validate.logNameLength': 'El nombre del parámetro dinámico contiene más de 64 caracteres.',
  'oifde.logparam.validate.sourceNameLength': 'El nombre de origen del parámetro contiene más de 64 caracteres.',
  'oifde.logparam.validate.sourceValueLength': 'Longitud máxima:64',
  'oifde.logparam.validate.targetValueLength': 'Longitud máxima:64',
  'oifde.logparam.message.logParamNotNull': 'No se ingresó la información de conversión del parámetro.',
  'oifde.logparam.message.validateFailed': 'Verification failed.',
  'oifde.logparam.message.validateResult': 'Aviso',
  'oifde.tenantinfo.tenanInfoMgr': 'Configuración de parámetros estáticos',
  'oifde.tenantinfo.serviceName': 'Nombre',
  'oifde.tenantinfo.serviceValue': 'Valor',
  'oifde.tenantinfo.actions.edit': 'Editar',
  'oifde.tenantinfo.actions.editTenantBaseInfo': 'Editar parámetro estático',
  'oifde.tenantinfo.actions.success': 'Con éxito',
  'oifde.tenantinfo.actions.updateSuccess': 'Modificado con éxito.',
  'oifde.tenantinfo.validate.serviceValue': 'El valor es obligatorio.',
  'oifde.tenantinfo.validate.serviceValueLength': 'El valor contiene más de 64 caracteres.',
  'oifde.variable.innerTitle': 'Variable integrada',
  'oifde.variable.customTitle': 'Variable personalizada',
  'oifde.variable.variableName': 'Nombre de la variable',
  'oifde.variable.variableDesc': 'Descripción',
  'oifde.variable.variableType': 'Tipo de dato',
  'oifde.variable.scope': 'Alcance de la función',
  'oifde.variable.defaultValue': 'Valor predeterminado',
  'oifde.variable.encryptType': 'Tipo de cifrado',
  'oifde.variable.timezoneDesc': 'Configure este parámetro con la zona horaria del tenant. El valor debe oscilar entre -12 y +12. Por ejemplo, configure este parámetro en 8 para GMT+8.',
  'oifde.variable.channeltypeDesc': 'Se puede establecer como el canal correspondiente del inquilino.',
  'oifde.variable.responsestyleDesc': 'Se puede configurar al estilo correspondiente del inquilino.',
  'oifde.variable.langDesc': 'Puede configurar este parámetro con el idioma del tenant.',
  'oifde.variable.variableTypeDetail.int': 'Entero',
  'oifde.variable.variableTypeDetail.string': 'Carácter',
  'oifde.variable.variableTypeDetail.long': 'Entero largo',
  'oifde.variable.variableTypeDetail.float': 'Número flotante',
  'oifde.variable.variableTypeDetail.list': 'Lista',
  'oifde.variable.variableTypeDetail.object': 'Objeto',
  'oifde.variable.scopes.input': 'Parámetro de entrada',
  'oifde.variable.scopes.output': 'Parámetro de salida',
  'oifde.variable.scopes.local': 'Local',
  'oifde.variable.actions.edit': 'Editar',
  'oifde.variable.actions.delete': 'Eliminar',
  'oifde.variable.actions.editVariableBaseInfo': 'Editar variable',
  'oifde.variable.actions.addVariableBaseInfo': 'Agregar variable',
  'oifde.variable.actions.success': 'Con éxito',
  'oifde.variable.actions.createSuccess': 'Creado con éxito',
  'oifde.variable.actions.updateSuccess': 'Modificado con éxito',
  'oifde.variable.actions.deleteSuccess': 'Eliminado con éxito',
  'oifde.variable.actions.cancelDelete': 'Eliminación cancelada',
  'oifde.variable.actions.deletePrompt': 'Esta variable se eliminará de forma permanente. ¿Desea continuar?',
  'oifde.variable.actions.prompt': 'Aviso',
  'oifde.variable.actions.confirm': 'Aceptar',
  'oifde.variable.actions.cancel': 'Cancelar',
  'oifde.variable.actions.add': 'Agregar',
  'oifde.variable.validate.variableName': 'El nombre de la variable es obligatorio.',
  'oifde.variable.validate.variableNameRange': 'Solo se permiten letras, dígitos y guiones bajos.',
  'oifde.variable.validate.variableType': 'El tipo de variable es obligatorio.',
  'oifde.variable.validate.scope': 'El alcance de la función es obligatorio.',
  'oifde.variable.validate.doubleVariableNameCheck': 'El nombre de la variable ya existe.',
  'oifde.variable.validate.intValueCheck': 'El valor predeterminado de los números enteros es incorrecto. Los números enteros varían entre -2 147 483 648 y +2 147 483 647.',
  'oifde.variable.validate.longValueCheck': 'El valor predeterminado de los números enteros largos es incorrecto. Rango de datos entre - 9223372036854775808 y 9223372036854775807',
  'oifde.variable.validate.floatValueCheck': 'El valor predeterminado de los datos de punto flotante tiene un formato incorrecto. El valor debe estar en formato 100 o 99.99',
  'oifde.variable.validate.variableDescLength': 'La descripción de la variable contiene más de 1000 caracteres.',
  'oifde.variable.validate.listValueCheck': 'El formato del valor de datos de lista predeterminado es incorrecto. El valor debe estar en formato [1,2,3] o ["a"",b"",c"], y el valor puede ser [].',
  'oifde.variable.validate.variableNameLength': 'El nombre de la variable contiene más de 1000 caracteres.',
  'oifde.variable.validate.defaultValueLength': 'El valor predeterminado contiene más de 1024 caracteres.',
  'oifde.variable.validate.objectValueCheck': 'El formato del valor de datos de objeto predeterminado es incorrecto. El valor debe estar en {\'{\'}"key1":"value1","key2":"value2"{\'}\'}, y el valor puede ser {\'{\'}{\'}\'}.',
  'oifde.variable.encryptFlag.zero': 'Sin cifrado',
  'oifde.variable.encryptFlag.one': 'Cifrado guardar, descifrado cuando se adquiere',
  'oifde.variable.encryptFlag.two': 'Cifrado, no descifrado cuando se adquiere',
  'oifde.variable.ttsVolumeDesc': 'Utilice esta variable para establecer el volumen TTS del tenant.',
  'oifde.variable.ttsSpeedDesc': 'Utilice esta variable para establecer la velocidad de voz TTS del tenant.',
  'oifde.variable.voiceNameDesc': 'Utilice esta variable para establecer el altavoz TTS del tenant.',
  'oifde.variable.ttsPitchDesc': 'Utilice esta variable para establecer la frecuencia fundamental TTS del tenant.',
  'oifde.diagram.NMS_PleaseSelect': 'Seleccionar',
  'oifde.diagram.NMS_ParamName': 'Parámetro',
  'oifde.diagram.NMS_ParamValue': 'Valor',
  'oifde.diagram.NMS_DiagramProperties': 'Atributo de flujo',
  'oifde.diagram.NMS_BasicProperties': 'Atributo básico',
  'oifde.diagram.NMS_DiagramCode': 'Código de flujo',
  'oifde.diagram.NMS_DiagramName': 'Nombre del flujo',
  'oifde.diagram.NMS_ScenarioType': 'Tipo de escenario',
  'oifde.diagram.NMS_Desciption': 'Descripción',
  'oifde.diagram.NMS_NodeCode': 'Código de nodo',
  'oifde.diagram.NMS_NodeName': 'Nombre del nodo',
  'oifde.diagram.NMS_AdvanceProperties': 'Atributo avanzado',
  'oifde.diagram.NMS_ServiceName': 'Nombre servicio',
  'oifde.diagram.NMS_ServiceParams': 'Parámetro de servicio',
  'oifde.diagram.NMS_ServiceDesciption': 'Descripción del servicio',
  'oifde.diagram.add': 'Agregar',
  'oifde.diagram.remove': 'Eliminar',
  'oifde.diagram.NMS_BranchProperties': 'Atributo de derivación',
  'oifde.diagram.NMS_BranchCondition': 'Condición de derivación',
  'oifde.diagram.NMS_BranchService': 'Sucursal de servicio',
  'oifde.diagram.NMS_NodeProperties': 'Atributo del nodo',
  'oifde.diagram.NMS_FlowVariable': 'Variable de flujo',
  'oifde.diagram.NMS_FlowVariableName': 'Nombre de la variable',
  'oifde.diagram.NMS_FlowVariableDesc': 'Descripción',
  'oifde.diagram.NMS_FlowVariableType': 'Tipo de dato',
  'oifde.diagram.NMS_FlowVariableDefaultValue': 'Valor predeterminado',
  'oifde.diagram.NMS_FlowVariableEncryptTypeWarn': '* Tenga en cuenta que esta variable se almacena en texto plano cuando el tipo de cifrado no está cifrado. No asigne datos confidenciales a esta variable.',
  'oifde.diagram.NMS_FlowVariableEncryptTypeTips': '* Tenga en cuenta que cuando el tipo de cifrado es cifrado, se recomienda asignar un valor a la variable para evitar fugas de información.',
  'oifde.diagram.NMS_FlowVariableEncryptType': 'Tipo de cifrado',
  'oifde.diagram.NMS_FlowVariableOption': 'Operación',
  'oifde.diagram.NMS_AddVariable': 'Agregar variable',
  'oifde.diagram.NMS_ModifyVariable': 'Modificar variable',
  'oifde.diagram.error.NMS_flowNameError': 'El nombre del flujo es obligatorio y no puede contener más de 1000 caracteres.',
  'oifde.diagram.error.NMS_scenarioTypeError': 'El tipo de escenario es obligatorio.',
  'oifde.diagram.error.NMS_NodeNameError': 'El nombre del nodo es obligatorio y no puede contener más de 1000 caracteres.',
  'oifde.diagram.error.NMS_actionParamNameError': 'El nombre del parámetro no puede contener más de {0} caracteres.',
  'oifde.diagram.error.NMS_actionParamValueEmptyError': 'El valor del parámetro es obligatorio.',
  'oifde.diagram.error.NMS_branchError': 'La rama debe tener el nodo inicial y el nodo final.',
  'oifde.diagram.error.NMS_flowDescError': 'La descripción de flujo puede contener un máximo de 1000 caracteres.',
  'oifde.diagram.error.NMS_endNodeNotFind': 'No se encuentra el nodo final.',
  'oifde.diagram.error.NMS_NodeDescError': 'La descripción del nodo puede contener un máximo de 1000 caracteres.',
  'oifde.diagram.error.NMS_checkDefaultNextNodeForView': 'El elemento de diagrama del tipo de vista no puede tener más de una rama.',
  'oifde.diagram.error.NMS_startNodeNotBranchError': 'El nodo de inicio solo puede tener una rama.',
  'oifde.diagram.error.NMS_nmsFlowError': 'Error al analizar el flujo porque no se puede encontrar el nodo nmsFlow.',
  'oifde.diagram.error.NMS_actionParamValueError': 'El valor del parámetro puede contener un máximo de 4096 caracteres.',
  'oifde.diagram.error.NMS_actionParamDescError': 'La descripción del parámetro puede contener un máximo de 500 caracteres.',
  'oifde.diagram.error.NMS_startNodeNotUnique': 'El nodo de inicio no es único.',
  'oifde.diagram.error.NMS_VariableDescError': 'La descripción de la variable puede contener un máximo de 1000 caracteres.',
  'oifde.diagram.error.NMS_VariableNameError': 'El nombre de la variable puede contener un máximo de 1000 caracteres.',
  'oifde.diagram.error.NMS_nullBranchNotNuique': 'Un nodo solo puede tener una rama predeterminada.',
  'oifde.diagram.error.NMS_flowCodeError': 'El código de flujo no puede estar vacío ni exceder los 64 caracteres.',
  'oifde.diagram.error.NMS_startNodeNotFind': 'No se encuentra el nodo de inicio.',
  'oifde.diagram.error.NMS_NodeCodeError': 'El código de nodo no puede estar vacío ni exceder los 64 caracteres.',
  'oifde.diagram.error.NMS_endNodetransitToError': 'Error al saltar desde el nodo final a otro nodo.',
  'oifde.diagram.error.NMS_VariableDefaultValueError': 'El valor predeterminado de la variable puede contener un máximo de 1024 caracteres.',
  'oifde.diagram.error.NMS_analiseXmlFailed': 'Error al analizar el lienzo de flujo.',
  'oifde.diagram.error.NMS_rootError': 'Error al analizar el flujo porque no se puede encontrar el nodo raíz.',
  'oifde.diagram.error.NMS_transitToStartNodeError': 'Error al saltar al nodo de inicio.',
  'oifde.diagram.complexCell.name': 'Nombre del elemento de diagrama',
  'oifde.diagram.complexCell.variable': 'Variable del elemento de diagrama',
  'oifde.diagram.speechRecognition.recognitionParam': 'Parámetros de reconocimiento',
  'oifde.diagram.speechRecognition.resultParam': 'Procesamiento de resultados',
  'oifde.diagram.fastByVar': 'Buscar variable',
  'oifde.diagram.NMS_VoiceTemplate': 'Plantilla de respuesta',
  'oifde.diagram.usedCell': 'Elemento de diagrama',
  'oifde.diagram.varType': 'Tipo de variable',
  'oifde.diagram.variableTypeDetail.float': 'Número de punto flotante',
  'oifde.diagram.variableTypeDetail.object': 'Objeto',
  'oifde.diagram.variableTypeDetail.int': 'Entero',
  'oifde.diagram.variableTypeDetail.list': 'Cuadros',
  'oifde.diagram.variableTypeDetail.string': 'Carácter',
  'oifde.diagram.variableTypeDetail.long': 'Entero largo',
  'oifde.diagram.aimCell': 'Elemento de diagrama de destino',
  'oifde.diagram.saveAndPublish': 'Guardar y publicar',
  'oifde.diagram.usedVar': 'Variable usada',
  'oifde.diagram.fastGetCell': 'Buscar elemento de diagrama',
  'oifde.navbar.tenantId': 'ID de tenant de inicio de sesión actual',
  'oifde.navbar.current': 'Fecha/hora de inicio de sesión actual',
  'oifde.navbar.success': 'Fecha/hora de último inicio de sesión exitoso',
  'oifde.navbar.failed': 'Fecha/hora de último inicio de sesión fallido',
  'oifde.navbar.logOut': 'Cerrar sesión',
  'oifde.navbar.screenfull': 'Pantalla completa',
  'oifde.navbar.switchLang': 'Changing the language',
  'oifde.navbar.menuOpen': 'Expandir menú',
  'oifde.navbar.menuClose': 'Minimizar menú',
  'oifde.navbar.changePwd': 'Cambiar contraseña',
  'oifde.navbar.required': 'Obligatorio',
  'oifde.navbar.action.message': 'Aviso',
  'oifde.navbar.action.success': 'Con éxito',
  'oifde.navbar.action.changePasswordSuccess': 'Contraseña modificada.',
  'oifde.navbar.action.successMessage': 'El usuario se cambia correctamente.',
  'oifde.navbar.password.oldPassword': 'Contraseña vieja',
  'oifde.navbar.password.newPassword': 'Contraseña nueva',
  'oifde.navbar.password.confirmNewPassword': 'Confirmar contraseña',
  'oifde.navbar.password.passwordExpired': 'Su contraseña ha expirado. Cámbiela.',
  'oifde.navbar.password.firstTimeLogin': 'Está utilizando la contraseña inicial. Cámbiela.',
  'oifde.navbar.password.passwordWillExpire': 'Su contraseña está por expirar. Cámbiela.',
  'oifde.navbar.password.passwordNotSame': 'Las dos contraseñas son diferentes.',
  'oifde.navbar.password.logOut': 'Cerrar sesión',
  'oifde.navbar.tenantName': 'tenant actual',
  'oifde.navbar.switchUser': 'Cambiar usuario',
  'oifde.journeylogmgr.flowAnalysisMrg': 'Configuración de análisis de procesos',
  'oifde.journeylogmgr.accessCodeSetting': 'Configuración del código de acceso',
  'oifde.trackmgr.track': 'Configuración del rastreo',
  'oifde.trackmgr.accessCodeTrack': 'Rastrear código de acceso',
  'oifde.trackmgr.callingNumberTrack': 'Rastrear número que llama',
  'oifde.trackmgr.callingNumber': 'Número que llama',
  'oifde.trackmgr.accessCode': 'Código de acceso',
  'oifde.trackmgr.choose': 'Seleccionar',
  'oifde.trackmgr.validate.callingNumber': 'El número que llama es obligatorio.',
  'oifde.trackmgr.validate.callingNumberLength': 'El número que llama contiene más de 64 caracteres.',
  'oifde.trackmgr.validate.callingNumberDub': 'El número que llama está repetido.',
  'oifde.trackmgr.validate.pattern': 'Ingrese dígitos o letras.',
  'oifde.trackmgr.validate.accessCode': 'Seleccione el código de acceso.',
  'oifde.trackmgr.validate.needNumber': 'Introduzca un número.',
  'oifde.trackmgr.actions.delete': 'Eliminar',
  'oifde.trackmgr.actions.success': 'Con éxito',
  'oifde.trackmgr.actions.updateSuccess': 'Modificado con éxito.',
  'oifde.trackmgr.actions.add': 'Agregar',
  'oifde.trackmgr.actions.deleteSuccess': 'Eliminado con éxito.',
  'oifde.trackmgr.actions.cancelDelete': 'Eliminación cancelada.',
  'oifde.trackmgr.actions.deletePrompt': 'Estos datos de rastreo se eliminarán de forma permanente. ¿Desea continuar?',
  'oifde.trackmgr.actions.prompt': 'Aviso',
  'oifde.trackmgr.actions.confirm': 'Aceptar',
  'oifde.trackmgr.actions.cancel': 'Cancelar',
  'oifde.trackmgr.actions.updatePrompt': 'Esta configuración se deshabilitará automáticamente {0} hour (s) más tarde, lo que afectará al rendimiento del sistema. ¿Estás seguro de que quieres continuar?',
  'oifde.trackmgr.actions.cancelUpdate': 'Modificación cancelada.',
  'oifde.trace.traceLog': 'Consulta de seguimiento de registro',
  'oifde.trace.beginTime': 'Fecha/hora de inicio',
  'oifde.trace.endTime': 'Fecha/hora de fin',
  'oifde.trace.callingNumber': 'Número que llama',
  'oifde.trace.calledNumber': 'Número llamado',
  'oifde.trace.createTime': 'Fecha y hora de creación',
  'oifde.trace.flowId': 'ID de flujo',
  'oifde.trace.flowName': 'Nombre del flujo',
  'oifde.trace.logInfo': 'Información de registro',
  'oifde.trace.errorInfo': 'Información de error',
  'oifde.trace.nodeId': 'ID del nodo',
  'oifde.trace.nodeName': 'Nombre del nodo',
  'oifde.trace.actionContent': 'Nombre de la instrucción',
  'oifde.trace.nodeIndex': 'N.º de nodo de ejecución',
  'oifde.trace.actionParams': 'Información de parámetro de nodo de ejecución',
  'oifde.trace.processResult': 'Resultado de la ejecución',
  'oifde.trace.transitionOn': 'Derivación de resultado de la ejecución',
  'oifde.trace.reset': 'Reiniciar',
  'oifde.trace.validate.needNumber': 'Ingrese dígitos o letras.',
  'oifde.trace.validate.callingNumber': 'El número que llama es obligatorio.',
  'oifde.trace.validate.callingNumberLength': 'El número que llama contiene más de 64 caracteres.',
  'oifde.trace.clickToDetail': 'Haga clic para ver más.',
  'oifde.trace.clickToHideDetail': 'Haga clic para ver menos.',
  'oifde.trace.currentNodeInfo': 'Información del nodo actual',
  'oifde.trace.clickToFlow': 'Visualización de pistas',
  'oifde.trace.nodeTrace': 'Info de registro de nodo',
  'oifde.trace.nodeParams': 'Parámetros de entrada y salida del nodo',
  'oifde.trace.callId': 'ID de instancia de llamada',
  'oifde.trace.reasonAdvice': 'Causa y Sugerencia',
  'oifde.trace.sessionLog': 'Consulta de registro de diálogo',
  'oifde.trace.ivrRequest': 'Solicitud de IVR',
  'oifde.trace.traceReason': 'Causa de error',
  'oifde.trace.traceAdvice': 'Soluciones',
  'oifde.expression.epxr_input_head': 'Longitud máxima:',
  'oifde.expression.expr_type_illegal': 'El formato de expresión no es válido.',
  'oifde.expression.expr_key_repeats': 'Nombre de condición duplicado',
  'oifde.expression.expr_var': 'Ingrese una variable. La variable global comienza con GLOBAL, la variable de flujo comienza con FLOW, la variable de sistema comienza con SYS, y la variable de TUC comienza con TOC.',
  'oifde.expression.expr_var_fag': 'Ingrese una variable. La variable global comienza con GLOBAL, la variable de flujo comienza con FLOW.',
  'oifde.expression.expr_var_string': 'Ingrese una variable de tipo cadena. La variable global comienza con GLOBAL, la variable de flujo comienza con FLOW, la variable de sistema comienza con SYS, y la variable de TUC comienza con TOC.',
  'oifde.expression.expr_number': 'Ingrese un valor de tipo numérico. Los operadores disponibles entre los valores son los siguientes: + - * /',
  'oifde.expression.expr_number_noAction': 'Ingrese un valor de tipo numérico.',
  'oifde.expression.expr_var_list': 'Introduzca una variable de lista.',
  'oifde.expression.expr_var_number': 'Introduzca una variable int.',
  'oifde.expression.allVar': 'La variable global comienza con GLOBAL, la variable de flujo comienza con FLOW, la variable de sistema comienza con SYS, y la variable de TUC comienza con TOC.',
  'oifde.expression.flowAndGlobalVar': 'La variable global comienza con GLOBAL, la variable de flujo comienza con FLOW.',
  'oifde.expression.expr_string': 'Ingrese un valor de tipo de cadena. Use comillas simples o comillas inglesas antes y después de la cadena de caracteres. El operador de la cadena de caracteres es el signo más (+).',
  'oifde.expression.expr_simple_string': 'Ingrese una variable de cadena o cualquier cadena de caracteres. No utilice comillas antes y después de la cadena de caracteres.',
  'oifde.expression.expr_boolean': 'Enter a value of the Boolean type. The comparison operator between numeric values or character strings supports > < >= <= !=. The string method supports startsWith. The Boolean value operator supports && || == !=.',
  'oifde.expression.varFlow': 'Variable de flujo',
  'oifde.expression.varGlobal': 'Variable global',
  'oifde.expression.varToc': 'Variable de intención',
  'oifde.expression.varSys': 'Variable de sistema',
  'oifde.expression.varIvrRequest': 'Variable de solicitud de IVR',
  'oifde.expression.expr_var_paramName': 'Ingrese una variable. Las variables globales comienzan con GLOBAL y las de flujo comienzan con FLOW.',
  'oifde.procedure.procedureMgr': 'Gestión de procedimientos almacenados',
  'oifde.procedure.procedureDBMgr': 'Gestión de base de datos de procedimientos almacenados',
  'oifde.procedure.procedureName': 'Procedimiento de almacenamiento',
  'oifde.procedure.procedureDesc': 'Descripción',
  'oifde.procedure.procedureValue': 'Valor',
  'oifde.procedure.operation': 'Operación',
  'oifde.procedure.baseInfo': 'Información básica',
  'oifde.procedure.in_param': 'Parámetro de entrada',
  'oifde.procedure.paramName': 'Parámetro',
  'oifde.procedure.paramDesc': 'Descripción',
  'oifde.procedure.dataType': 'Tipo de dato',
  'oifde.procedure.out_param': 'Parámetro de salida',
  'oifde.procedure.addProcedureInterface': 'Agregar configuración de procedimientos almacenados',
  'oifde.procedure.modifyProcedureInterface': 'Modificar configuración de procedimientos almacenados',
  'oifde.procedure.addProcedureDB': 'Agregar configuración de base de datos de procedimientos almacenados',
  'oifde.procedure.modifyProcedureDB': 'Editar configuración de base de datos de procedimientos almacenados',
  'oifde.procedure.required': 'Obligatorio',
  'oifde.procedure.urlRule': 'La base de datos URL comienzan con "jdbc:mysql" or "jdbc:zenith".',
  'oifde.procedure.actions.editList': 'Editar tipo de datos en Lista',
  'oifde.procedure.actions.edit': 'Editar',
  'oifde.procedure.actions.delete': 'Eliminar',
  'oifde.procedure.actions.success': 'Con éxito',
  'oifde.procedure.actions.createSuccess': 'Creación exitosa.',
  'oifde.procedure.actions.updateSuccess': 'Modificación exitosa.',
  'oifde.procedure.actions.deleteSuccess': 'Eliminación exitosa.',
  'oifde.procedure.actions.cancelDelete': 'Eliminación cancelada.',
  'oifde.procedure.actions.deletePrompt': 'Esta operación eliminará el procedimiento almacenado de forma permanente. ¿Confirma que desea continuar?',
  'oifde.procedure.actions.prompt': 'Aviso',
  'oifde.procedure.actions.confirm': 'Sí',
  'oifde.procedure.actions.cancel': 'No',
  'oifde.procedure.actions.ok': 'Aceptar',
  'oifde.procedure.actions.miss_pro_name': 'Faltan los campos obligatorios: Información básica -> Nombre de procedimiento almacenado.',
  'oifde.procedure.actions.miss_pro_value': 'Faltan los campos obligatorios: Información básica -> Valor de procedimiento almacenado.',
  'oifde.procedure.actions.miss_pro_in_parameter_name': 'Faltan los campos obligatorios: Parámetro de entrada > Nombre del parámetro.',
  'oifde.procedure.actions.miss_pro_out_parameter_name': 'Faltan los campos obligatorios: Parámetro de salida > Nombre del parámetro.',
  'oifde.procedure.actions.miss_pro_dbId': 'Falta el campo obligatorio: Conexión a base de datos.',
  'oifde.procedure.dataTypes.INT': 'Entero',
  'oifde.procedure.dataTypes.STRING': 'Carácter',
  'oifde.procedure.dataTypes.FLOAT': 'Número flotante',
  'oifde.procedure.dataTypes.LONG': 'Entero largo',
  'oifde.procedure.dataTypes.LIST': 'Lista',
  'oifde.procedure.db.dbType': 'Tipo de base de datos',
  'oifde.procedure.db.dbDriver': 'Controlador de base de datos',
  'oifde.procedure.db.dbUrl': 'URL de base de datos',
  'oifde.procedure.db.dbUser': 'Usuario de base de datos',
  'oifde.procedure.db.dbPassword': 'Contraseña de base de datos',
  'oifde.procedure.dbTypes.MYSQL': 'MYSQL',
  'oifde.procedure.dbTypes.GAUSS': 'GAUSS',
  'oifde.procedure.dbTypes.ORACLE': 'ORACLE',
  'oifde.procedure.ruleMysql': 'La base de datos MySQL debe comenzar con jdbc:mysql://.',
  'oifde.procedure.ruleOracle': 'El nombre de la base de datos Oracle debe comenzar con jdbc:oracle:thin:{\'@\'}.',
  'oifde.procedure.driverStart': 'El valor no puede comenzar con un (.) de puntos o un (-) de guion.',
  'oifde.procedure.ruleLength': 'Introduzca la dirección IP y el número de puerto en la URL.',
  'oifde.procedure.dbUrlWarning': '*Tenga en cuenta que la dirección IP, el número de puerto o el nombre de dominio en la URL de la base de datos no está en la lista blanca. Póngase en contacto con el administrador del sistema para agregar la dirección IP, el número de puerto o el nombre de dominio para invocar la interfaz',
  'oifde.procedure.ruleGauss': 'La GaussDB debe comenzar con jdbc:zenith:{\'@\'}.',
  'oifde.procedure.driverEnd': 'El valor no puede terminar con un (.) de puntos o un (-) de guion.',
  'oifde.procedure.driverRule': 'El valor puede contener solo letras, dígitos, puntos (.), y guiones (-).',
  'oifde.procedure.ruleRange': 'La URL solo puede contener letras, dígitos y los siguientes caracteres especiales:.?',
  'oifde.procedure.oracleRuleRange': 'La URL solo puede contener letras, dígitos y los siguientes caracteres especiales:.? {\'@\'}',
  'oifde.complexcell.cellTitle': 'Elemento de diagrama compuesto',
  'oifde.complexcell.catalogTitle': 'Catálogo de elementos de diagrama compuesto',
  'oifde.complexcell.catalog.name': 'Nombre del catálogo',
  'oifde.complexcell.catalog.desc': 'Descripción del catálogo',
  'oifde.complexcell.catalog.tips.sizeLimit': 'Se permite un máximo de 100 catálogos.',
  'oifde.complexcell.cell.name': 'Nombre de celda',
  'oifde.complexcell.cell.desc': 'Descripción',
  'oifde.complexcell.cell.height': 'Altura',
  'oifde.complexcell.cell.width': 'Ancho',
  'oifde.complexcell.cell.icon': 'Símbolo',
  'oifde.complexcell.cell.catalog': 'Catálogo',
  'oifde.complexcell.cell.actions.editInfo': 'Modificar información básica',
  'oifde.complexcell.cell.actions.editDiagram': 'Editar Canvas',
  'oifde.complexcell.cell.actions.copy': 'Replicar',
  'oifde.complexcell.cell.validation.bigFile': 'El archivo cargado no puede superar los {0}.',
  'oifde.complexcell.cell.viewReference':'Ver referencias',
  'oifde.complexcell.cell.actions.copySuccess':'La replicación se realizó correctamente',
  'oifde.table.search': 'Buscar',
  'oifde.table.add': 'Añadir',
  'oifde.table.actions': 'Acciones',
  'oifde.table.cancel': 'Cancelar',
  'oifde.table.confirm': 'Confirmar',
  'oifde.table.reset': 'Restablecer',
  'oifde.table.create': 'Crear',
  'oifde.table.filter': 'Filtrar',
  'oifde.table.save': 'Ahorra',
  'oifde.table.delete': 'Borrar',
  'oifde.table.manage': 'Gestionar',
  'oifde.tagsView.refresh': 'Actualizar',
  'oifde.tagsView.close': 'Cerrar',
  'oifde.tagsView.closeOthers': 'Cerrar otros',
  'oifde.tagsView.closeAll': 'Cerrar todos',
  'oifde.mxgraph.cancel': 'Cancelar',
  'oifde.mxgraph.close': 'Cerrar',
  'oifde.mxgraph.error': 'Error',
  'oifde.mxgraph.errorSavingFile': 'Error al guardar el archivo.',
  'oifde.mxgraph.ok': 'Aceptar',
  'oifde.mxgraph.about': 'Información',
  'oifde.mxgraph.align': 'Alinear',
  'oifde.mxgraph.arrange': 'Ordenar',
  'oifde.mxgraph.arrow': 'Flecha',
  'oifde.mxgraph.autosize': 'Ajuste automático',
  'oifde.mxgraph.blockquote': 'Blockquote',
  'oifde.mxgraph.bottomAlign': 'Alinear abajo',
  'oifde.mxgraph.center': 'Centrar',
  'oifde.mxgraph.circle': 'Hacer círculo',
  'oifde.mxgraph.clearWaypoints': 'Borrar puntos de ref',
  'oifde.mxgraph.collapse': 'Minimizar',
  'oifde.mxgraph.collapseExpand': 'Minimizar/Expandir',
  'oifde.mxgraph.collapsible': 'Minimizable',
  'oifde.mxgraph.connectionPoints': 'Puntos de conexión',
  'oifde.mxgraph.connectionArrows': 'Flechas de conexión',
  'oifde.mxgraph.copy': 'Copiar',
  'oifde.mxgraph.copyConnect': 'Copiar conexión',
  'oifde.mxgraph.custom': 'Personalizar',
  'oifde.mxgraph.cut': 'Cortar',
  'oifde.mxgraph.delete': 'Eliminar',
  'oifde.mxgraph.direction': 'Dirección',
  'oifde.mxgraph.distribute': 'Distribuir',
  'oifde.mxgraph.drawingTooLarge': 'Diagrama demasiado grande.',
  'oifde.mxgraph.duplicate': 'Duplicar',
  'oifde.mxgraph.edit': 'Editar',
  'oifde.mxgraph.editData': 'Editar datos',
  'oifde.mxgraph.editDiagram': 'Origen',
  'oifde.mxgraph.editLink': 'Editar enlace',
  'oifde.mxgraph.editStyle': 'Editar estilo',
  'oifde.mxgraph.editTooltip': 'Editar ayuda visual',
  'oifde.mxgraph.enterGroup': 'Entrar al grupo',
  'oifde.mxgraph.exitGroup': 'Salir del grupo',
  'oifde.mxgraph.expand': 'Expandir',
  'oifde.mxgraph.export': 'Exportar',
  'oifde.mxgraph.extras': 'Extras',
  'oifde.mxgraph.file': 'Archivo',
  'oifde.mxgraph.filename': 'Nombre del archivo',
  'oifde.mxgraph.fitWindow': 'Ajustar la ventana',
  'oifde.mxgraph.flipH': 'Voltear horiz',
  'oifde.mxgraph.flipV': 'Voltear vertic',
  'oifde.mxgraph.fontFamily': 'Familia de letras',
  'oifde.mxgraph.fontSize': 'Tamaño de letra',
  'oifde.mxgraph.formatPanel': 'Panel de formato',
  'oifde.mxgraph.formatted': 'Formateado',
  'oifde.mxgraph.grid': 'Cuadrícula',
  'oifde.mxgraph.group': 'Agrupar',
  'oifde.mxgraph.guides': 'Guías',
  'oifde.mxgraph.heading': 'Título',
  'oifde.mxgraph.help': 'Ayuda',
  'oifde.mxgraph.home': 'Inicio',
  'oifde.mxgraph.horizontal': 'Horizontal',
  'oifde.mxgraph.horizontalFlow': 'Flujo horizontal',
  'oifde.mxgraph.horizontalTree': 'Árbol horizontal',
  'oifde.mxgraph.import': 'Importar',
  'oifde.mxgraph.insert': 'Insertar',
  'oifde.mxgraph.insertImage': 'Insertar imagen',
  'oifde.mxgraph.insertLink': 'Insertar enlace',
  'oifde.mxgraph.invalidOrMissingFile': 'Archivo no válido o ausente',
  'oifde.mxgraph.layers': 'Capas',
  'oifde.mxgraph.landscape': 'Paisaje',
  'oifde.mxgraph.leftAlign': 'Alinear a la izq',
  'oifde.mxgraph.lockUnlock': 'Bloquear/Desbloquear',
  'oifde.mxgraph.middle': 'Medio',
  'oifde.mxgraph.navigation': 'Navegación',
  'oifde.mxgraph.normal': 'Normal',
  'oifde.mxgraph.open': 'Abrir',
  'oifde.mxgraph.organic': 'Orgánico',
  'oifde.mxgraph.outline': 'Esquema',
  'oifde.mxgraph.pageView': 'Vista de página',
  'oifde.mxgraph.pageScale': 'Escala de página',
  'oifde.mxgraph.pageSetup': 'Ajustes de página',
  'oifde.mxgraph.panTooltip': 'Espacio+Arrastrar para avanzar',
  'oifde.mxgraph.paste': 'Pegar',
  'oifde.mxgraph.print': 'Imprimir',
  'oifde.mxgraph.radialTree': 'Árbol radial',
  'oifde.mxgraph.redo': 'Rehacer',
  'oifde.mxgraph.reset': 'Restablecer',
  'oifde.mxgraph.resetView': 'Restablecer vista',
  'oifde.mxgraph.rightAlign': 'Alinear a la derecha',
  'oifde.mxgraph.rotation': 'Rotación',
  'oifde.mxgraph.save': 'Guardar',
  'oifde.mxgraph.saveAs': 'Guardar como',
  'oifde.mxgraph.scrollbars': 'Barras de desplazam',
  'oifde.mxgraph.selectAll': 'Seleccionar todo',
  'oifde.mxgraph.selectEdges': 'Seleccionar bordes',
  'oifde.mxgraph.selectNone': 'No seleccionar nada',
  'oifde.mxgraph.toBack': 'Hacia atrás',
  'oifde.mxgraph.toFront': 'Hacia el frente',
  'oifde.mxgraph.tooltips': 'Ayudas visuales',
  'oifde.mxgraph.topAlign': 'Alinear arriba',
  'oifde.mxgraph.turn': 'Rotar 90°',
  'oifde.mxgraph.undo': 'Deshacer',
  'oifde.mxgraph.ungroup': 'Desagrupar',
  'oifde.mxgraph.vertical': 'Vertical',
  'oifde.mxgraph.verticalFlow': 'Flujo vertical',
  'oifde.mxgraph.verticalTree': 'Árbol vertical',
  'oifde.mxgraph.view': 'Ver',
  'oifde.mxgraph.zoom': 'Zoom',
  'oifde.mxgraph.zoomIn': 'Acercar',
  'oifde.mxgraph.zoomOut': 'Alejar',
  'oifde.mxgraph.success': 'Sin errores',
  'oifde.mxgraph.prompt': 'Aviso',
  'oifde.mxgraph.confirm': 'Aceptar',
  'oifde.mxgraph.NMS_FlowSaveSuccess': 'El flujo se guardó correctamente.',
  'oifde.mxgraph.NMS_FlowSaveFail': 'Error al guardar el flujo.',
  'oifde.mxgraph.NMS_FlowOpenFail': 'Error al abrir el flujo.',
  'oifde.mxgraph.NMS_ActionTypesGetFail': 'Error al obtener información sobre los elementos del diagrama.',
  'oifde.mxgraph.NMS_ActionParamsGetFail': 'Error al obtener parámetros de los elementos del diagrama.',
  'oifde.mxgraph.NMS_FlowsGetFail': 'Error al obtener la lista de flujos.',
  'oifde.mxgraph.NMS_NodesGetFail': 'Error al obtener la lista de nodos del flujo {0}.',
  'oifde.mxgraph.NMS_InterfacesGetFail': 'Error al obtener la lista de interfaces de acceso.',
  'oifde.mxgraph.NMS_ContentTemplateGetFail': 'Error al obtener la lista de plantillas de contenido.',
  'oifde.mxgraph.NMS_EditorModelLoadFail': 'Error al cargar la configuración del modelo de editor.',
  'oifde.mxgraph.NMS_RegionInfoLoadFail': 'Error al cargar la información de la región.',
  'oifde.mxgraph.NMS_IntentCodeLoadFail': 'Error al cargar el código de intención.',
  'oifde.mxgraph.NMS_flow': 'Flujo',
  'oifde.mxgraph.NMS_ScenarioType_main': 'Flujo principal',
  'oifde.mxgraph.NMS_ScenarioType_sub': 'Subflujo',
  'oifde.mxgraph.NMS_ScenarioType_error': 'Escenario de excepción',
  'oifde.mxgraph.NMS_flowCodeError': 'El código del flujo es obligatorio y no puede contener más de 64 caracteres.',
  'oifde.mxgraph.NMS_flowNameError': 'El nombre del flujo es obligatorio y no puede contener más de 1000 caracteres.',
  'oifde.mxgraph.NMS_flowDescError': 'La descripción del flujo no puede contener más de 1000 caracteres.',
  'oifde.mxgraph.NMS_nmsFlowError': 'Error al analizar el flujo. No se encontró el nodo nmsFlow.',
  'oifde.mxgraph.NMS_rootError': 'Error al analizar el flujo. No se encontró el nodo raíz.',
  'oifde.mxgraph.NMS_VariableNameError': 'El nombre de la variable no puede contener más de 1000 caracteres.',
  'oifde.mxgraph.NMS_VariableDescError': 'La descripción de la variable no puede contener más de 1000 caracteres.',
  'oifde.mxgraph.NMS_VariableDefaultValueError': 'El valor predeterminado de la variable no puede contener más de 1024 caracteres.',
  'oifde.mxgraph.NMS_NodeCodeError': 'El código del nodo es obligatorio y no puede contener más de 64 caracteres.',
  'oifde.mxgraph.NMS_NodeNameError': 'El nombre del nodo es obligatorio y no puede contener más de 1000 caracteres.',
  'oifde.mxgraph.NMS_NodeDescError': 'La descripción del nodo no puede contener más de 1000 caracteres.',
  'oifde.mxgraph.NMS_actionParamNameError': 'El nombre del parámetro no puede contener más de 64 caracteres.',
  'oifde.mxgraph.NMS_actionParamValueEmptyError': 'El valor es obligatorio.',
  'oifde.mxgraph.NMS_actionParamValueError': 'El valor del parámetro no puede contener más de 4096 caracteres.',
  'oifde.mxgraph.NMS_actionParamDescError': 'La descripción del parámetro no puede contener más de 500 caracteres.',
  'oifde.mxgraph.NMS_transitToStartNodeError': 'Error al saltar al nodo de inicio.',
  'oifde.mxgraph.NMS_endNodetransitToError': 'No se puede saltar del nodo final a otro nodo.',
  'oifde.mxgraph.NMS_startNodeNotFind': 'No se encontró el nodo de inicio.',
  'oifde.mxgraph.NMS_startNodeNotUnique': 'El nodo de inicio no es único.',
  'oifde.mxgraph.NMS_startNodeNotBranchError': 'El nodo de inicio no tiene ramificaciones o tiene más de una ramificación.',
  'oifde.mxgraph.NMS_endNodeNotFind': 'No se encontró el nodo final.',
  'oifde.mxgraph.NMS_branchError': 'La ramificación no tiene nodo de inicio o nodo final.',
  'oifde.mxgraph.NMS_nullBranchNotNuique': 'Cada nodo puede tener solo una ramificación predeterminada.',
  'oifde.mxgraph.NMS_nodeRefError': 'El nodo no se puede eliminar porque hay otro flujo que hace referencia a él.',
  'oifde.mxgraph.NMS_ValidateError': 'Error de validación! Consulte la información en rojo.',
  'oifde.mxgraph.NMS_SpaceValidate': 'El valor no puede comenzar con un espacio.',
  'oifde.mxgraph.NMS_KeyRepeats': 'Nombre de condición duplicado',
  'oifde.mxgraph.rotate': 'Girar',
  'oifde.mxgraph.doubleClickOrientation': 'Haga doble clic para cambiar la orientación',
  'oifde.mxgraph.changeOrientation': 'Cambiar orientación',
  'oifde.mxgraph.table': 'Cuadros',
  'oifde.mxgraph.NMS_checkDefaultNextNodeForView': 'El elemento de diagrama del tipo de vista no puede tener más de una rama.',
  'oifde.mxgraph.entityRelation': 'Relación de entidad',
  'oifde.mxgraph.selectVertices': 'Seleccionar vértices',
  'oifde.mxgraph.borderWidth': 'Ancho de borde',
  'oifde.mxgraph.add': 'Añadir',
  'oifde.mxgraph.alreadyConnected': 'Nodos ya conectados',
  'oifde.mxgraph.subscript': 'Subíndice',
  'oifde.mxgraph.formatPdf': 'EN _ FR _ DE _ RU',
  'oifde.mxgraph.bulletedList': 'Lista de viñetas',
  'oifde.mxgraph.placeholders': 'Marcador de posición',
  'oifde.mxgraph.shadow': 'Sombra',
  'oifde.mxgraph.noResultsFor': ' No hay resultados para \'{0}\'',
  'oifde.mxgraph.leftToRight': 'De izquierda a derecha',
  'oifde.mxgraph.publishFailed': 'Error al guardar y publicar el flujo.',
  'oifde.mxgraph.NMS_ParamDescription': 'Descripcion',
  'oifde.mxgraph.searchShapes': 'Nodos de búsqueda',
  'oifde.mxgraph.sharp': 'Afilado',
  'oifde.mxgraph.angle': 'Ángulo',
  'oifde.mxgraph.cannotOpenFile': 'No se puede abrir el archivo.',
  'oifde.mxgraph.comic': 'Cómic',
  'oifde.mxgraph.clipart': 'Imágenes',
  'oifde.mxgraph.noFiles': 'No hay archivos.',
  'oifde.mxgraph.dotted': 'Puntos',
  'oifde.mxgraph.clearDefaultStyle': 'Borrar el estilo por defecto',
  'oifde.mxgraph.hide': 'Ocultar',
  'oifde.mxgraph.shape': 'Forma',
  'oifde.mxgraph.gradient': 'Gradiente',
  'oifde.mxgraph.straight': 'Recto',
  'oifde.mxgraph.opacity': 'Opacidad',
  'oifde.mxgraph.insertRowBefore': 'Insertar fila arriba',
  'oifde.mxgraph.NMS_ParamValue': 'El valor',
  'oifde.mxgraph.increaseIndent': 'Aumentar sangría',
  'oifde.mxgraph.insertColumnBefore': 'Insertar columna a la izquierda',
  'oifde.mxgraph.gradientColor': 'Colores',
  'oifde.mxgraph.NMS_TargetNode': 'Nodo de destino',
  'oifde.mxgraph.NMS_Region': 'Región de la Región',
  'oifde.mxgraph.NMS_analiseXmlFailed': 'Error al analizar el lienzo de flujo.',
  'oifde.mxgraph.allChangesLost': 'Todos los cambios se perderán.',
  'oifde.mxgraph.fitTwoPages': 'Dos páginas',
  'oifde.mxgraph.italic': 'En cursiva',
  'oifde.mxgraph.oval': 'Ovalado',
  'oifde.mxgraph.insertColumnAfter': 'Insertar columna a la derecha',
  'oifde.mxgraph.NMS_AdvanceProperties': 'Atributo avanzado',
  'oifde.mxgraph.size': 'Talla',
  'oifde.mxgraph.global': 'Globales',
  'oifde.mxgraph.insertHorizontalRule': 'Insertar regla horizontal',
  'oifde.mxgraph.noMoreResults': 'No más resultados.',
  'oifde.mxgraph.connection': 'Conexiones',
  'oifde.mxgraph.diamond': 'Diamante',
  'oifde.mxgraph.gap': 'Gafas',
  'oifde.mxgraph.link': 'Enlaces',
  'oifde.mxgraph.textAlignment': 'Alineación de texto',
  'oifde.mxgraph.line': 'Línea',
  'oifde.mxgraph.fontColor': 'Color de fuente',
  'oifde.mxgraph.underline': 'Subrayado',
  'oifde.mxgraph.NMS_Valid': 'Válido',
  'oifde.mxgraph.writingDirection': 'Dirección de escritura',
  'oifde.mxgraph.deleteColumn': 'Eliminar columna',
  'oifde.mxgraph.simple': 'Sencillo',
  'oifde.mxgraph.style': 'Estilo',
  'oifde.mxgraph.NMS_ServiceDesciption': 'Descripción del servicio',
  'oifde.mxgraph.classic': 'Clásico',
  'oifde.mxgraph.saveAndPublish': 'Guardar y publicar',
  'oifde.mxgraph.rightToLeft': 'De derecha a izquierda',
  'oifde.mxgraph.updatingSelection': 'Actualizando la selección. Por favor espere...',
  'oifde.mxgraph.openArrow': 'Flecha abierta',
  'oifde.mxgraph.diamondThin': 'Diamante (delgado)',
  'oifde.mxgraph.autosave': 'Guardado automático',
  'oifde.mxgraph.documentProperties': 'Propiedades del documento',
  'oifde.mxgraph.NMS_DiagramName': 'Nombre del proceso',
  'oifde.mxgraph.numberedList': 'Lista numerada',
  'oifde.mxgraph.drawing': 'Dibujo{0}',
  'oifde.mxgraph.removeFromGroup': 'Eliminar del grupo',
  'oifde.mxgraph.failInfo': 'Error al consultar los datos.',
  'oifde.mxgraph.automatic': 'Automático',
  'oifde.mxgraph.isometric': 'Isométrico',
  'oifde.mxgraph.NMS_encryptValidate': 'La variable cifrada IVRREQUEST, la variable FLOW y la variable GLOBAL se pueden asignar solo a variables cifradas FLOW y variables GLOBAL.',
  'oifde.mxgraph.untitledLayer': 'Capa sin título',
  'oifde.mxgraph.paperSize': 'Tamaño de papel',
  'oifde.mxgraph.targetSpacing': 'Espaciado de objetivos',
  'oifde.mxgraph.lineend': 'Final de línea',
  'oifde.mxgraph.wordWrap': 'Envoltura de palabras',
  'oifde.mxgraph.background': 'Antecedentes',
  'oifde.mxgraph.right': 'Correcto',
  'oifde.mxgraph.pattern': 'Patrón',
  'oifde.mxgraph.divider': 'Divisores',
  'oifde.mxgraph.perimeter': 'Perímetro',
  'oifde.mxgraph.backgroundImage': 'Imagen de fondo',
  'oifde.mxgraph.NMS_NodeName': 'Nombre del nodo',
  'oifde.mxgraph.block': 'Bloquear',
  'oifde.mxgraph.bold': 'En negrita',
  'oifde.mxgraph.NMS_Invalid': 'Inválido',
  'oifde.mxgraph.moreResults': 'Más resultados',
  'oifde.mxgraph.options': 'Opciones',
  'oifde.mxgraph.sidebarTooltip': 'Haga clic para expandir o contraer el panel de nodos.',
  'oifde.mxgraph.manual': 'Instrucciones',
  'oifde.mxgraph.height': 'Altura',
  'oifde.mxgraph.addLayer': 'Añadir capa',
  'oifde.mxgraph.pages': 'PÁGINAS',
  'oifde.mxgraph.posterPrint': 'Impresión de carteles',
  'oifde.mxgraph.fill': 'Rellenar',
  'oifde.mxgraph.decreaseIndent': 'Disminuir sangría',
  'oifde.mxgraph.saveAndNopublish': 'El flujo se guarda correctamente pero no se ha podido publicar.',
  'oifde.mxgraph.misc': 'Miscelánea',
  'oifde.mxgraph.rotateTooltip': 'Haga clic y arrastre para girar, y haga clic para girar 90 grados',
  'oifde.mxgraph.linewidth': 'Ancho de línea',
  'oifde.mxgraph.north': 'Norte',
  'oifde.mxgraph.removeWaypoint': 'Eliminar Waypoint',
  'oifde.mxgraph.NMS_BranchProperties': 'Atributo de rama',
  'oifde.mxgraph.topRight': 'Arriba a la derecha',
  'oifde.mxgraph.formatXml': 'Código XML',
  'oifde.mxgraph.NMS_ServiceParams': 'Parámetro de servicio',
  'oifde.mxgraph.fitPage': 'Una página',
  'oifde.mxgraph.enterPropertyName': 'Introducir nombre de propiedad',
  'oifde.mxgraph.position': 'Posición',
  'oifde.mxgraph.flip': 'Voltear',
  'oifde.mxgraph.preview': 'Vista previa',
  'oifde.mxgraph.bottomLeft': 'Inferior izquierdo',
  'oifde.mxgraph.rename': 'Renombrar',
  'oifde.mxgraph.connect': 'Conectar',
  'oifde.mxgraph.general': 'Información general',
  'oifde.mxgraph.font': 'Tipo de letra',
  'oifde.mxgraph.left': 'Izquierdo',
  'oifde.mxgraph.replace': 'Reemplazar',
  'oifde.mxgraph.width': 'Anchura',
  'oifde.mxgraph.constrainProportions': 'Restringir proporciones',
  'oifde.mxgraph.move': 'Desplazarse',
  'oifde.mxgraph.openSupported': 'Sólo se admiten los archivos XML exportados desde este sistema.',
  'oifde.mxgraph.plusTooltip': 'Haga clic para conectar y clonar (ctrl+clic para clonar, Mayús+clic para conectar). Arrastre para conectar (ctrl+arrastre para clonar).',
  'oifde.mxgraph.saved': 'Guardado',
  'oifde.mxgraph.east': 'Este',
  'oifde.mxgraph.NMS_TargetFlow': 'Flujo de destino',
  'oifde.mxgraph.html': 'EN _ DE _ FR _ ES',
  'oifde.mxgraph.more': 'Más información',
  'oifde.mxgraph.diagram': 'Diagrama',
  'oifde.mxgraph.updatingDocument': 'Actualizando el documento. Por favor espere...',
  'oifde.mxgraph.deleteRow': 'Eliminar fila',
  'oifde.mxgraph.linestart': 'Inicio de línea',
  'oifde.mxgraph.removeFormat': 'Borrar formato',
  'oifde.mxgraph.actualSize': 'Tamaño real',
  'oifde.mxgraph.replaceIt': '{0} ya existe. ¿Quieres reemplazarlo?',
  'oifde.mxgraph.openLink': 'Abrir enlace',
  'oifde.mxgraph.duplicateIt': 'Duplicar {0}',
  'oifde.mxgraph.reverse': 'Invertir',
  'oifde.mxgraph.collapse-expand': 'Contraer/Expandir',
  'oifde.mxgraph.NMS_BasicProperties': 'Atributo básico',
  'oifde.mxgraph.strokeColor': 'Color de línea',
  'oifde.mxgraph.waypoints': 'Puntos de ruta',
  'oifde.mxgraph.url': 'Dirección URL',
  'oifde.mxgraph.NMS_TemplateCode': 'Código de plantilla',
  'oifde.mxgraph.create': 'Crear',
  'oifde.mxgraph.loading': 'Cargando',
  'oifde.mxgraph.fillColor': 'Color de relleno',
  'oifde.mxgraph.rounded': 'Redondeado',
  'oifde.mxgraph.NMS_ServiceName': 'Nombre del servicio',
  'oifde.mxgraph.dashed': 'Distintos',
  'oifde.mxgraph.west': 'Occidente',
  'oifde.mxgraph.NMS_DiagramCode': 'Código de proceso',
  'oifde.mxgraph.uml': 'UML',
  'oifde.mxgraph.NMS_Desciption': 'Descripcion',
  'oifde.mxgraph.sourceSpacing': 'Espaciado de fuente',
  'oifde.mxgraph.NMS_timeSelectLengthValidate': 'Se debe configurar al menos un registro para el período de servicio.',
  'oifde.mxgraph.NMS_PleaseSelectTemplate': 'Seleccione una plantilla.',
  'oifde.mxgraph.addWaypoint': 'Añadir Waypoint',
  'oifde.mxgraph.pasteHere': 'Pegar aquí',
  'oifde.mxgraph.advanced': 'Avanzado',
  'oifde.mxgraph.setAsDefaultStyle': 'Establecer como estilo por defecto',
  'oifde.mxgraph.spacing': 'Espaciado',
  'oifde.mxgraph.text': 'Texto',
  'oifde.mxgraph.topLeft': 'Arriba a la izquierda',
  'oifde.mxgraph.basic': 'Básico',
  'oifde.mxgraph.backgroundColor': 'Color de fondo',
  'oifde.mxgraph.change': 'Cambio',
  'oifde.mxgraph.layout': 'Diseño',
  'oifde.mxgraph.enterName': 'Introducir nombre',
  'oifde.mxgraph.NMS_NodeCode': 'Código de nodo',
  'oifde.mxgraph.formattedText': 'Texto con formato',
  'oifde.mxgraph.enterValue': 'Introducir valor',
  'oifde.mxgraph.laneColor': 'Color de la calle',
  'oifde.mxgraph.addProperty': 'Add (propiedad)',
  'oifde.mxgraph.gridSize': 'Tamaño de rejilla',
  'oifde.mxgraph.default': 'Predeterminado',
  'oifde.mxgraph.editImage': 'Editar imagen',
  'oifde.mxgraph.lineJumps': 'Saltos de línea',
  'oifde.mxgraph.moveSelectionTo': 'Mover selección a {0}',
  'oifde.mxgraph.images': 'Imágenes',
  'oifde.mxgraph.formatJpg': 'EN _ FR _ DE _ RU',
  'oifde.mxgraph.NMS_DiagramID': 'ID de proceso',
  'oifde.mxgraph.bottom': 'Parte inferior',
  'oifde.mxgraph.solid': 'Sólido',
  'oifde.mxgraph.selectFont': 'Seleccionar fuente',
  'oifde.mxgraph.formatGif': 'GIF',
  'oifde.mxgraph.removeIt': 'Eliminar {0}',
  'oifde.mxgraph.openFile': 'Abrir archivo',
  'oifde.mxgraph.NMS_DiagramProperties': 'Atributo de proceso',
  'oifde.mxgraph.borderColor': 'Color del borde',
  'oifde.mxgraph.renameIt': 'Renombrar {0}',
  'oifde.mxgraph.publishSuccess': 'El flujo se guarda y se publica correctamente.',
  'oifde.mxgraph.apply': 'Aplica',
  'oifde.mxgraph.NMS_Status': 'Estado',
  'oifde.mxgraph.NMS_DefaultBranch': 'Sucursal por defecto',
  'oifde.mxgraph.openInNewWindow': 'Abrir en una ventana nueva',
  'oifde.mxgraph.format': 'Formato',
  'oifde.mxgraph.fitPageWidth': 'Ancho de página',
  'oifde.mxgraph.fileNotFound': 'Archivo no encontrado',
  'oifde.mxgraph.NMS_ScenarioType': 'Tipo de escenario',
  'oifde.mxgraph.textOpacity': 'Opacidad del texto',
  'oifde.mxgraph.lineheight': 'Altura de línea',
  'oifde.mxgraph.south': 'Sur',
  'oifde.mxgraph.arc': 'Arco',
  'oifde.mxgraph.transparent': 'Transparente',
  'oifde.mxgraph.none': 'No hay',
  'oifde.mxgraph.image': 'Imágenes',
  'oifde.mxgraph.done': 'Hecho',
  'oifde.mxgraph.simpleArrow': 'Flecha simple',
  'oifde.mxgraph.hideIt': 'Ocultar {0}',
  'oifde.mxgraph.portrait': 'Retrato',
  'oifde.mxgraph.formatSvg': 'SVG EN',
  'oifde.mxgraph.remindSave': 'No ha guardado datos durante mucho tiempo. Guarde los datos con prontitud.',
  'oifde.mxgraph.glass': 'Vidrio',
  'oifde.mxgraph.curved': 'Curvado',
  'oifde.mxgraph.noColor': 'Sin color.',
  'oifde.mxgraph.drawingEmpty': 'El dibujo está vacío.',
  'oifde.mxgraph.orthogonal': 'Ortogonal',
  'oifde.mxgraph.new': 'Novedades',
  'oifde.mxgraph.arrows': 'Flechas',
  'oifde.mxgraph.openInThisWindow': 'Abrir en esta ventana',
  'oifde.mxgraph.formatPng': 'PNG EN',
  'oifde.mxgraph.NMS_NodeProperties': 'Atributo de nodo',
  'oifde.mxgraph.hidden': 'Oculto',
  'oifde.mxgraph.NMS_PleaseSelect': 'Por favor seleccione',
  'oifde.mxgraph.replaceExistingDrawing': 'Reemplazar el dibujo existente',
  'oifde.mxgraph.invalidName': 'Nombre no válido',
  'oifde.mxgraph.superscript': 'Superíndice',
  'oifde.mxgraph.containsValidationErrors': 'Error de validación encontrado',
  'oifde.mxgraph.bottomRight': 'Abajo Derecha',
  'oifde.mxgraph.addToExistingDrawing': 'Añadir al dibujo existente',
  'oifde.mxgraph.insertRowAfter': 'Insertar fila debajo',
  'oifde.mxgraph.top': 'Arriba',
  'oifde.mxgraph.search': 'Buscar',
  'oifde.mxgraph.alignment': 'Alineación',
  'oifde.mxgraph.NMS_ParamName': 'Parámetro',
  'oifde.mxgraph.NMS_BranchCondition': 'Condición de la rama',
  'oifde.knowledgegraph.actions.edit': 'Editar',
  'oifde.knowledgegraph.actions.view': 'Ver',
  'oifde.knowledgegraph.actions.delete': 'Eliminar',
  'oifde.knowledgegraph.actions.clean': 'Borrar',
  'oifde.knowledgegraph.actions.prompt': 'Información',
  'oifde.knowledgegraph.actions.confirm': 'Confirmar',
  'oifde.knowledgegraph.actions.nameRequiredTip': 'El nombre es obligatorio.',
  'oifde.knowledgegraph.actions.selectTip': '---Seleccionar---',
  'oifde.knowledgegraph.actions.cancel': 'Cancelar',
  'oifde.knowledgegraph.actions.cancelDelete': 'Eliminación cancelada.',
  'oifde.knowledgegraph.actions.success': 'Sin errores',
  'oifde.knowledgegraph.actions.updateSuccess': 'Modificación exitosa.',
  'oifde.knowledgegraph.actions.deleteSuccess': 'Eliminación exitosa.',
  'oifde.knowledgegraph.actions.createSuccess': 'Se agregó correctamente.',
  'oifde.knowledgegraph.actions.createFailed': 'Error al agregar.',
  'oifde.knowledgegraph.actions.modifyFailed': 'Error al modificar.',
  'oifde.knowledgegraph.actions.deleteFailed': 'Error al eliminar.',
  'oifde.knowledgegraph.actions.saveSuccess': 'Se guardó.',
  'oifde.knowledgegraph.actions.saveFailed': 'Error al guardar.',
  'oifde.knowledgegraph.actions.addSchema': 'Nuevo esquema',
  'oifde.knowledgegraph.actions.editSchema': 'Editar esquema',
  'oifde.knowledgegraph.actions.addDic': 'Nuevo diccionario gráfico',
  'oifde.knowledgegraph.actions.editDic': 'Editar diccionario gráfico',
  'oifde.knowledgegraph.actions.addEntity': 'Nueva entidad',
  'oifde.knowledgegraph.actions.editEntity': 'Editar entidad',
  'oifde.knowledgegraph.actions.addRelation': 'Nueva relación',
  'oifde.knowledgegraph.actions.editRelation': 'Editar relación',
  'oifde.knowledgegraph.actions.addAttribute': 'Nuevo atributo',
  'oifde.knowledgegraph.actions.editAttribute': 'Editar atributo',
  'oifde.knowledgegraph.actions.addSynonyms': 'Nuevo sinónimo',
  'oifde.knowledgegraph.actions.editSynonyms': 'Editar sinónimo',
  'oifde.knowledgegraph.actions.addExtQuestion': 'Nueva pregunta ampliada',
  'oifde.knowledgegraph.actions.editExtQuestion': 'Editar pregunta ampliada',
  'oifde.knowledgegraph.actions.viewSchema': 'Ver esquema',
  'oifde.knowledgegraph.actions.graphicPreview': 'Vista previa de gráfico',
  'oifde.knowledgegraph.actions.addSchemaEntity': 'Nuevo concepto',
  'oifde.knowledgegraph.actions.editSchemaEntity': 'Editar concepto',
  'oifde.knowledgegraph.model.schemaName': 'Nombre de esquema',
  'oifde.knowledgegraph.model.schemaCode': 'Código de esquema',
  'oifde.knowledgegraph.model.schemaDescription': 'Descripción',
  'oifde.knowledgegraph.model.modifyTime': 'Modificación:',
  'oifde.knowledgegraph.model.operation': 'Operación',
  'oifde.knowledgegraph.model.createUser': 'Creador',
  'oifde.knowledgegraph.model.beforeDeletePrompt': 'El esquema no se puede eliminar porque está asociado a un diccionario gráfico. Diccionario gráfico asociado:',
  'oifde.knowledgegraph.model.deletePrompt': 'Esta operación borrará el esquema permanentemente y los conceptos, las relaciones y las atributos contenidos en el esquema. ¿Confirma que desea continuar?',
  'oifde.knowledgegraph.model.beforeEditPrompt': 'El esquema actual no se puede modificar porque está asociado con el siguiente diccionario de gráficos:',
  'oifde.knowledgegraph.editSchema.entity': 'Concepto',
  'oifde.knowledgegraph.editSchema.entityName': 'Nombre de concepto',
  'oifde.knowledgegraph.editSchema.relation': 'Relación',
  'oifde.knowledgegraph.editSchema.relationName': 'Nombre de relación',
  'oifde.knowledgegraph.editSchema.headEntity': 'Concepto inicial',
  'oifde.knowledgegraph.editSchema.tailEntity': 'Concepto final',
  'oifde.knowledgegraph.editSchema.selectTip': '---Seleccionar---',
  'oifde.knowledgegraph.editSchema.objectName': 'Concepto/Relación',
  'oifde.knowledgegraph.editSchema.attributeName': 'Nombre de atributo',
  'oifde.knowledgegraph.editSchema.attributeType': 'Tipo de atributo',
  'oifde.knowledgegraph.editSchema.unit': 'Unidad',
  'oifde.knowledgegraph.editSchema.type': 'Tipo',
  'oifde.knowledgegraph.editSchema.schemaNoDataTip': 'Agregue datos de relación y concepto en el panel izquierdo y haga clic en el botón ver, ubicado a la derecha del concepto o la relación correspondientes para ver el contenido del atributo de los datos correspondientes.',
  'oifde.knowledgegraph.editSchema.deleteEntityPrompt': 'Esta operación borrará permanentemente el concepto y sus atributos. ¿Confirma que desea continuar?',
  'oifde.knowledgegraph.editSchema.deleteRelationPrompt': 'Esta operación borrará permanentemente la relación y sus atributos. ¿Confirma que desea continuar?',
  'oifde.knowledgegraph.editSchema.deleteAttributePrompt': 'Esta operación borrará permanentemente el atributo. ¿Confirma que desea continuar con la operación?',
  'oifde.knowledgegraph.editSchema.dataType.int': 'Entero',
  'oifde.knowledgegraph.editSchema.dataType.float': 'Coma flotante',
  'oifde.knowledgegraph.editSchema.dataType.boolean': 'Booleano',
  'oifde.knowledgegraph.editSchema.dataType.string': 'Carácter',
  'oifde.knowledgegraph.editSchema.dataType.date': 'Fecha',
  'oifde.knowledgegraph.dicmgr.dicName': 'Nombre gráfico',
  'oifde.knowledgegraph.dicmgr.dicCode': 'Código gráfico',
  'oifde.knowledgegraph.dicmgr.dicFunction': 'Uso de gráfico',
  'oifde.knowledgegraph.dicmgr.modifyTime': 'Modificación:',
  'oifde.knowledgegraph.dicmgr.schemaName': 'Esquema asociado',
  'oifde.knowledgegraph.dicmgr.kgState': 'Estado de gráfico',
  'oifde.knowledgegraph.dicmgr.operation': 'Operación',
  'oifde.knowledgegraph.dicmgr.moreOperation': 'Más',
  'oifde.knowledgegraph.dicmgr.entityMgr': 'Entidad de gestión',
  'oifde.knowledgegraph.dicmgr.relationMgr': 'Gestión de relaciones',
  'oifde.knowledgegraph.dicmgr.kgView': 'Visualización de gráficos',
  'oifde.knowledgegraph.dicmgr.kgFAQ': 'FAQ de gráficos',
  'oifde.knowledgegraph.dicmgr.active': 'Activar',
  'oifde.knowledgegraph.dicmgr.inactive': 'Desactivar',
  'oifde.knowledgegraph.dicmgr.trainAll': 'Entrenar todo',
  'oifde.knowledgegraph.dicmgr.trainSuccess': 'Entrenamiento realizado.',
  'oifde.knowledgegraph.dicmgr.trainFail': 'Error de entrenamiento.',
  'oifde.knowledgegraph.dicmgr.changeState': 'Modificar estado',
  'oifde.knowledgegraph.dicmgr.synonyms': 'Sinónimo',
  'oifde.knowledgegraph.dicmgr.extendQuestion': 'Pregunta ampliada',
  'oifde.knowledgegraph.dicmgr.deletePrompt': 'Esta operación borrará permanentemente el diccionario y las entidades, las relaciones y los atributos incluidos en el diccionario. ¿Confirma que desea continuar?',
  'oifde.knowledgegraph.dicmgr.creating': 'Creando',
  'oifde.knowledgegraph.entitymgr.entityName': 'Nombre de entidad',
  'oifde.knowledgegraph.entitymgr.schemaEntityName': 'Concepto de esquema asociado',
  'oifde.knowledgegraph.entitymgr.beforeDeletePrompt': 'Borre la relación de la entidad y después borre la entidad.',
  'oifde.knowledgegraph.entitymgr.deletePrompt': 'Esta operación borrará permanentemente la entidad. ¿Confirma que desea continuar?',
  'oifde.knowledgegraph.entitymgr.entityAttributeMgr.deletePrompt': 'Esta operación borrará permanentemente el atributo de la entidad. ¿Confirma que desea continuar?',
  'oifde.knowledgegraph.relationmgr.headEntity': 'Entidad inicial',
  'oifde.knowledgegraph.relationmgr.tailEntity': 'Entidad final',
  'oifde.knowledgegraph.relationmgr.relation': 'Relación',
  'oifde.knowledgegraph.relationmgr.doubleRelation': 'Relación bidireccional',
  'oifde.knowledgegraph.relationmgr.doubleRelationTrue': 'Sí',
  'oifde.knowledgegraph.relationmgr.doubleRelationFalse': 'No',
  'oifde.knowledgegraph.relationmgr.doublePrompt': 'La entidad inicial es igual que la entidad final y no se puede establecer la relación. Seleccione otra entidad inicial o final.',
  'oifde.knowledgegraph.relationmgr.deletePrompt': 'Esta operación borrará permanentemente la relación y los atributos incluidos en la relación. ¿Confirma que desea continuar?',
  'oifde.knowledgegraph.attributemgr.attributeName': 'Nombre de atributo',
  'oifde.knowledgegraph.attributemgr.attribute': 'Valor de atributo',
  'oifde.knowledgegraph.attributemgr.description': 'Descripción de atributo',
  'oifde.knowledgegraph.attributemgr.deletePrompt': 'Esta operación borrará permanentemente el atributo. ¿Confirma que desea continuar con la operación?',
  'oifde.knowledgegraph.kgFAQ.title': 'FAQ de gráficos',
  'oifde.knowledgegraph.kgFAQ.name': 'Diccionario gráfico:',
  'oifde.knowledgegraph.kgFAQ.welcomeContent': '¡Hola! Soy un asistente inteligente. Es un placer ayudarlo.',
  'oifde.knowledgegraph.kgSynonyms.keyword': 'Nombre',
  'oifde.knowledgegraph.kgSynonyms.synonyms': 'Sinónimo',
  'oifde.knowledgegraph.kgSynonyms.number': 'Número',
  'oifde.knowledgegraph.kgSynonyms.synonymsDetail': 'Detalles del sinónimo',
  'oifde.knowledgegraph.kgSynonyms.name': 'Nombre',
  'oifde.knowledgegraph.kgSynonyms.deletePrompt': 'Esta operación borrará permanentemente la entrada y todos sus sinónimos. ¿Confirma que desea continuar?',
  'oifde.knowledgegraph.kgSynonyms.deleteSynonymsPrompt': 'Esta operación borrará permanentemente el sinónimo. ¿Confirma que desea continuar?',
  'oifde.knowledgegraph.kgSynonyms.type.type': 'Tipo',
  'oifde.knowledgegraph.kgSynonyms.type.entity': 'Entidad',
  'oifde.knowledgegraph.kgSynonyms.type.relation': 'Relación',
  'oifde.knowledgegraph.kgSynonyms.type.property': 'Atributo',
  'oifde.knowledgegraph.kgView.queryType': 'Tipo consulta',
  'oifde.knowledgegraph.kgView.entityName': 'Nombre de entidad',
  'oifde.knowledgegraph.kgView.displayType': 'Modo visualización',
  'oifde.knowledgegraph.kgView.queryByName': 'Consultar por nombre entidad',
  'oifde.knowledgegraph.validation.schemaName': 'El nombre del esquema es obligatorio.',
  'oifde.knowledgegraph.validation.dicName': 'El nombre del diccionario es obligatorio.',
  'oifde.knowledgegraph.validation.selectRequired': 'Obligatorio',
  'oifde.knowledgegraph.validation.attrValue': 'El valor del atributo es obligatorio.',
  'oifde.knowledgegraph.validation.required': 'El valor es obligatorio.',
  'oifde.knowledgegraph.errorCode.100010000003': 'El nombre debe ser único.',
  'oifde.knowledgegraph.errorCode.100010000002': 'Error de formato.',
  'oifde.knowledgegraph.errorCode.100051001013': 'El esquema está en uso.',
  'oifde.knowledgegraph.errorCode.100051001012': 'Se ha configurado un sinónimo para el nombre.',
  'oifde.knowledgegraph.errorCode.100010000001': 'Error de la base de datos.',
  'oifde.knowledgegraph.errorCode.100061000005': 'El gráfico de conocimiento ha sido vinculado',
  'oifde.knowledgegraph.errorCode.100010000005': 'El parámetro de entrada es incorrecto.',
  'oifde.knowledgegraph.errorCode.100010000000': 'Error del sistema.',
  'oifde.knowledgegraph.errorCode.100061000006': 'Error al conectarse al ODFS.',
  'oifde.knowledgegraph.errorCode.100051000114': 'Error al guardar la pregunta extendida para el gráfico FAQ.',
  'oifde.knowledgegraph.errorCode.100051000113': 'La URL configurada para el servicio GES es incorrecta.',
  'oifde.knowledgegraph.errorCode.100051000112': 'Preparando el gráfico...Por favor espere.',
  'oifde.knowledgegraph.errorCode.100051000111': 'El número ha alcanzado el límite superior de 190.',
  'oifde.knowledgegraph.errorCode.100051000110': 'El número ha alcanzado el límite superior de 10.',
  'oifde.knowledgegraph.extendFaq.updateTemplateSuccess': 'La plantilla de preguntas frecuentes se modifica correctamente.',
  'oifde.knowledgegraph.extendFaq.updateSuccess': 'El estado se ha cambiado correctamente.',
  'oifde.knowledgegraph.extendFaq.operation': 'Operación',
  'oifde.knowledgegraph.extendFaq.reply': 'Reseñas',
  'oifde.knowledgegraph.extendFaq.export': 'Exportar todo',
  'oifde.knowledgegraph.extendFaq.extendFaq': 'Lista de plantillas de preguntas frecuentes',
  'oifde.knowledgegraph.extendFaq.setting': 'Establecer pregunta extendida',
  'oifde.knowledgegraph.extendFaq.use': 'Uso/No uso',
  'oifde.knowledgegraph.extendFaq.similarQuestion': 'Pregunta similar',
  'oifde.knowledgegraph.extendFaq.updateTemplateFailed': 'Error al modificar la plantilla de preguntas frecuentes.',
  'oifde.knowledgegraph.extendFaq.status': 'Estado',
  'oifde.knowledgegraph.extendFaq.updateFailed': 'Error al cambiar el estado.',
  'oifde.knowledgegraph.extendFaq.updateTime': 'Actualizado',
  'oifde.knowledgegraph.extendFaq.setTemplate': 'Establecer pregunta extendida',
  'oifde.knowledgegraph.extendFaq.notUse': 'No está en uso',
  'oifde.knowledgegraph.extendFaq.question': 'Preguntas',
  'oifde.knowledgegraph.extendFaq.description': 'Descripcion',
  'oifde.knowledgegraph.extendFaq.similarReply': 'Respuesta similar',
  'oifde.knowledgegraph.extendFaq.inUse': 'En uso',
  'oifde.knowledgegraph.extendFaq.import': 'Importación',
  'oifde.knowledgegraph.extendFaq.edit': 'Modificar -',
  'oifde.knowledgegraph.extendFaq.faqTemplate': 'Plantilla de Preguntas Frecuentes',
  'oifde.operationlog.logging_module': 'Módulo',
  'oifde.operationlog.operation_log': 'Log de operaciones',
  'oifde.operationlog.operator': 'Cuenta',
  'oifde.operationlog.operation_obj_type': 'Tipo de objeto de operación',
  'oifde.operationlog.startTime': 'Inicio:',
  'oifde.operationlog.endTime': 'Fin:',
  'oifde.operationlog.operationType': 'Tipo de operación',
  'oifde.operationlog.targetObjType': 'Tipo de objeto',
  'oifde.operationlog.targetObjId': 'ID de objeto',
  'oifde.operationlog.targetObjName': 'Objeto',
  'oifde.operationlog.result': 'Resultado',
  'oifde.operationlog.detail': 'Detalles',
  'oifde.operationlog.operationTime': 'Fecha/hora de operación',
  'oifde.operationlog.loginIp': 'Dirección IP de inicio de sesión',
  'oifde.operationlog.check': 'Validar',
  'oifde.operationlog.query': 'Consultar',
  'oifde.operationlog.create': 'Crear',
  'oifde.operationlog.copy': 'Copiar',
  'oifde.operationlog.paste': 'Pegar',
  'oifde.operationlog.clone': 'Replicar',
  'oifde.operationlog.import': 'Importar',
  'oifde.operationlog.export': 'Exportar',
  'oifde.operationlog.update': 'Actualizar',
  'oifde.operationlog.delete': 'Eliminar',
  'oifde.operationlog.enable': 'Activar',
  'oifde.operationlog.disable': 'Desactivar',
  'oifde.operationlog.set_test': 'Config como prueba',
  'oifde.operationlog.hang_up': 'Colgar',
  'oifde.operationlog.release': 'Lanzar',
  'oifde.operationlog.invalidate': 'Invalidar',
  'oifde.operationlog.upgrade': 'Actualizar',
  'oifde.operationlog.set_white_list': 'Establecer reglas de escala de grises',
  'oifde.operationlog.intent_template': 'Plantilla de intención',
  'oifde.operationlog.user_entity': 'Entidad de usuario',
  'oifde.operationlog.system_entity': 'Entidad del sistema',
  'oifde.operationlog.voice_content': 'Archivo de voz',
  'oifde.operationlog.system_parameters': 'Parámetros del sistema',
  'oifde.operationlog.intent_parameters': 'Parámetros de intención',
  'oifde.operationlog.call_session': 'Diálogo de llamada',
  'oifde.operationlog.cluster_task': 'Tarea de clusterización',
  'oifde.operationlog.history_dialogue': 'Historial de diálogos',
  'oifde.operationlog.test_case_group': 'Grupo de casos de prueba',
  'oifde.operationlog.test_case': 'Caso de prueba',
  'oifde.operationlog.faq': 'Grupo de FAQ',
  'oifde.operationlog.faq_element': 'Preguntas frecuentes',
  'oifde.operationlog.log_parameters': 'Parámetro registro',
  'oifde.operationlog.tps_interface': 'Interfaz de servicios',
  'oifde.operationlog.nms_interface': 'Interfaz de flujo de diálogos',
  'oifde.operationlog.white_list': 'Conocimiento gris reglas',
  'oifde.operationlog.white_list_users': 'Cantidad de abonados en la Conocimiento gris reglas',
  'oifde.operationlog.sensitive_words': 'Palabras sensibles',
  'oifde.operationlog.stop_words': 'Palabra irrelevante',
  'oifde.operationlog.navigation_info': 'Información de navegación',
  'oifde.operationlog.command': 'Comando',
  'oifde.operationlog.api_key': 'Clave API',
  'oifde.operationlog.trace_log': 'Configuración de reg de seguimiento',
  'oifde.operationlog.succeed': 'Sin errores',
  'oifde.operationlog.failed': 'Error',
  'oifde.operationlog.domain': 'Dominio',
  'oifde.operationlog.service_node': 'Nodo de servicio',
  'oifde.operationlog.online': 'En línea',
  'oifde.operationlog.offline': 'Sin conexión',
  'oifde.operationlog.flow': 'Procesar',
  'oifde.operationlog.performance_data': 'Datos de rendimiento',
  'oifde.operationlog.login': 'Inicio sesión',
  'oifde.operationlog.logout': 'Cerrar sesión',
  'oifde.operationlog.change_password': 'Cambiar contraseña',
  'oifde.operationlog.reset_password': 'Restablecer contraseña',
  'oifde.operationlog.authorize': 'Certificación',
  'oifde.operationlog.tenant_space': 'Espacio de tenants',
  'oifde.operationlog.account': 'Cuenta',
  'oifde.operationlog.role': 'Rol',
  'oifde.operationlog.password_rule': 'Regla de contraseñas',
  'oifde.operationlog.password_rule_group': 'Grupo de reglas de contraseñas',
  'oifde.operationlog.user_account': 'Cuenta',
  'oifde.operationlog.user_role': 'Rol',
  'oifde.operationlog.knowledge_graph': 'Gráfico de conocimiento',
  'oifde.operationlog.knowledge_graph_synonym': 'Sinónimo de gráfico de conocimiento',
  'oifde.operationlog.acess_code': 'Código de acceso',
  'oifde.operationlog.gray_rule': 'Regla de escala de grises',
  'oifde.operationlog.resource_template': 'Plantilla de recursos',
  'oifde.operationlog.business_interface': 'Interfaz de negocios',
  'oifde.operationlog.variable_manage': 'Gestión de variables',
  'oifde.operationlog.flume_log_cfg': 'Configuración de registro de Flume',
  'oifde.operationlog.procedure_db': 'Base de datos de procedimientos almacenados',
  'oifde.operationlog.procedure_interface': 'Procedimiento almacenado',
  'oifde.operationlog.complexcellcatalog': 'Catálogo de elementos del diagrama compuesto',
  'oifde.operationlog.complexcell': 'Elemento de diagrama compuesto',
  'oifde.operationlog.lock_account': 'Bloquear cuenta',
  'oifde.operationlog.unlock_account': 'Desbloquear cuenta',
  'oifde.operationlog.kick_account': 'Borrar sesiones',
  'oifde.operationlog.start': 'Iniciar',
  'oifde.operationlog.stop': 'Detener',
  'oifde.operationlog.log_open_task': 'Tarea de habilitación de logs',
  'oifde.operationlog.activate': 'Activar',
  'oifde.operationlog.deactivate': 'Pausar',
  'oifde.operationlog.illeagal_status': 'Estado ilegal',
  'oifde.operationlog.file': 'Archivar',
  'oifde.operationlog.trace_log_query': 'Rastreo y consulta registros',
  'oifde.operationlog.staticcheck': 'Inspección estática',
  'oifde.operationlog.unkonw': 'Se desconoce',
  'oifde.operationlog.interface_white_list': 'Lista blanca',
  'oifde.operationlog.license': 'Licencia',
  'oifde.operationlog.test_corpus': 'Prueba de corpus',
  'oifde.operationlog.test': 'Prueba',
  'oifde.operationlog.refresh': 'Actualizar',
  'oifde.operationlog.train': 'Entrenamiento',
  'oifde.operationlog.trainAll': 'Entrenamiento a todos',
  'oifde.operationlog.lable_corpus': 'Etiquetado corpus',
  'oifde.operationlog.knowledge_graph_schema': 'Modelado de conocimiento',
  'oifde.operationlog.knowledge_graph_entity': 'Entidad del diccionario gráfico',
  'oifde.operationlog.knowledge_graph_relation': 'Relación del diccionario gráfico',
  'oifde.operationlog.knowledge_graph_object_attribute': 'Propiedades del diccionario gráfico',
  'oifde.operationlog.knowledge_graph_schema_entity': 'Conceptos de modelado de conocimiento',
  'oifde.operationlog.knowledge_graph_schema_relation': 'Relación de modelado de conocimiento',
  'oifde.operationlog.knowledge_graph_schema_attribute': 'Propiedades de modelado de conocimiento',
  'oifde.operationlog.encrypt_Secret': 'Clave de cifrado',
  'oifde.operationlog.flow_trace_log': 'Configuración de seguimiento de prueba de proceso',
  'oifde.operationlog.channel_type': 'Canal',
  'oifde.operationlog.response_style': 'Estilo',
  'oifde.operationlog.resource_template_exce': 'Plantilla de recurso de excepción',
  'oifde.operationlog.setting': 'Configurar',
  'oifde.operationlog.quick_release': 'Publicación con un clic',
  'oifde.operationlog.download': 'Descargar',
  'oifde.operationlog.binding_kg': 'Gráfico de chatbot',
  'oifde.operationlog.insert': 'Inserción',
  'oifde.operationlog.sentiment_analysis': 'Modelo de análisis de sentimientos',
  'oifde.operationlog.knowledge_graph_object_entity': 'Atributo de entidad de diccionario de gráficos',
  'oifde.operationlog.binding': 'Encuadernación',
  'oifde.operationlog.recordHistoryLog': 'Registro de diálogos',
  'oifde.operationlog.knowledge_graph_ask_template_element': 'Plantilla de preguntas frecuentes para preguntas extendidas de gráficos',
  'oifde.operationlog.MRCPServerManagement': 'Administración de servidores',
  'oifde.operationlog.sensitive_group': 'Grupo de palabras sensibles',
  'oifde.operationlog.unused': 'No utilizado',
  'oifde.operationlog.binding_faqgroup': 'Grupo de preguntas frecuentes de chatbot',
  'oifde.operationlog.binding_domain': 'Dominio de Chatbot',
  'oifde.operationlog.upload': 'Cargar',
  'oifde.operationlog.stop_auto_generate': 'Generación interrumpida de casos',
  'oifde.operationlog.guideflow': 'Flujo del asistente de creación',
  'oifde.operationlog.generate_test_case': 'Generación automática de cajas',
  'oifde.operationlog.knowledge_graph_ask_template_group': 'Pregunta extendida gráfica',
  'oifde.operationlog.exclude_entity': 'Excluir entidades',
  'oifde.operationlog.tenantAsrName': 'Configuración de ASR',
  'oifde.operationlog.knowledge_graph_ask_template': 'Pregunta extendida',
  'oifde.operationlog.use': 'Usado',
  'oifde.operationlog.tenantAsrTtsName': 'Gestión de TTS/ASR',
  'oifde.sysConfig.sysParam': 'Parámetros del sistema',
  'oifde.sysConfig.intent_params': 'Parámetros de intención',
  'oifde.sysConfig.code': 'Código de variable',
  'oifde.sysConfig.description': 'Descripción de variable',
  'oifde.sysConfig.value': 'Valor de variable',
  'oifde.sysConfig.update': 'Aplicar',
  'oifde.sysConfig.abort': 'Cancelar',
  'oifde.sysConfig.updateAll': 'Aplicar todos los parámetros',
  'oifde.sysConfig.restore_defaults': 'Restaurar valores predeterminados',
  'oifde.sysConfig.successful_operation': 'Se realizó la operación.',
  'oifde.sysConfig.confirm_apply_intent_param': 'La modificación de los parámetros de intención afectará en gran medida al reconocimiento de la intención. ¿Confirma que desea aplicar la modificación?',
  'oifde.sysConfig.succeed_apply_intent_param': 'Los parámetros de intención se aplicaron correctamente.',
  'oifde.sysConfig.failed_apply_intent_param': 'Error al cambiar el valor del parámetro de intención.',
  'oifde.sysConfig.confirm_restore_defaults': '¿Confirma que desea restaurar los valores predeterminados?',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.NAME': 'Módulo de preprocesamiento chino',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.NAME': 'Usar pinyin',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.DESC': 'Indica si se debe convertir el idioma chino a pinyin.',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.TRUE': 'Habilitar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.FALSE': 'Deshabilitar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.NAME': 'Usar tono',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.DESC': 'Indica si se debe utilizar el tono en pinyin.',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.TRUE': 'Habilitar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.FALSE': 'Deshabilitar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.MULTI': 'Múltiples veces',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.ONCE': 'Una vez',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.TRUE': 'Habilitar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.FALSE': 'Deshabilitar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.TRUE': 'Habilitar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.FALSE': 'Deshabilitar',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NAME': 'Módulo de extracción de características n-grama',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.NAME': 'Mín. valor de N',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.DESC': 'Mínimo valor de N en el algoritmo n-grama.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.NAME': 'Máx. valor de N',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.DESC': 'Máximo valor de N en el algoritmo n-grama.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.NAME': 'Umbral mínimo de peso de la palabra',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.DESC': 'Cuando el peso de la palabra es menor que el umbral, ignórelo.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.NAME': 'Recopilar una vez o más veces las mismas palabras',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.DESC': 'Cuando se muestran múltiples veces las mismas palabras de una oración, las estadísticas se recopilan una o más veces.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.NAME': 'Algoritmo TSF',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.DESC': 'Indica si se debe habilitar el algoritmo TSF.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.NAME': 'Extensión del borde de la oración',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.DESC': 'Indica si se deben agregar etiquetas antes y después de una oración cuando se extrae la característica n-grama.',
  'oifde.sysConfig.ENERGY_FILTER.NAME': 'Módulo de control de cantidad de intenciones',
  'oifde.sysConfig.ENERGY_FILTER.MAX.NAME': 'Cantidad máxima de intenciones',
  'oifde.sysConfig.ENERGY_FILTER.MAX.DESC': 'Cantidad máxima de intenciones devueltas en la decisión final.',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.NAME': 'Módulo desconocido de filtrado de intenciones',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.NAME': 'Umbral de energía',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.DESC': 'Cuando la suma de todas las energías de intenciones detectadas sea inferior o igual al umbral, las intenciones serán intenciones desconocidas.',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.NAME': 'Módulo de clasificación de intenciones',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.NAME': 'μ',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.DESC': 'Valor μ de la función de logística del clasificador de intenciones',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.NAME': 'γ',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.DESC': 'Valor γ de la función de logística del clasificador de intenciones',
  'oifde.sysConfig.INTENT_DECISION_MAKER.NAME': 'Módulo de toma de decisiones de intenciones',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.NAME': 'Límite superior',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.DESC': 'Límite superior del responsable de la toma de decisiones de intenciones. Si se supera el límite superior, la intención es una intención conocida.',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.NAME': 'Límite inferior',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.DESC': 'Límite inferior del responsable de toma de decisiones de intenciones. Si el valor es inferior al límite inferior, la intención es una intención desconocida.',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NAME': 'Módulo de marcado de ranuras CRF',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.NAME': 'Tasa de aprendizaje',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.DESC': 'Tasa de aprendizaje del modelo CRF.',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.NAME': 'Cantidad de nodos de árbol',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.DESC': 'Cantidad de nodos en cada árbol en el algoritmo GBRT.',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.NAME': 'Cantidad de árboles',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.DESC': 'Cantidad de árboles del algoritmo GBRT.',
  'oifde.errorCode.100010000000': 'Error del sistema.',
  'oifde.errorCode.100010000002': 'Formato incorrecto.',
  'oifde.errorCode.100051005001': 'Error al guardar la lista negra y la lista blanca.',
  'oifde.errorCode.100070000001': 'Los datos que desea importar son iguales al recurso existente, lo que provoca una falla de importación.',
  'oifde.errorCode.100051005002': 'Error al eliminar la lista negra o la lista blanca.',
  'oifde.errorCode.100051000006': 'Error al importar. El formato de archivo es incorrecto.',
  'oifde.errorCode.100051005005': 'Error al importar. El archivo es demasiado grande.',
  'oifde.errorCode.100051005006': 'El número de servicio no puede contener más de 64 caracteres.',
  'oifde.errorCode.100051005015': 'El número de líneas de archivo no puede exceder las 5,000 líneas.',
  'oifde.errorCode.100051005003': 'Error al importar. No se encontró el archivo.',
  'oifde.errorCode.100051005004': 'Error al importar el archivo. Se produce un error cuando el sistema lee el archivo.',
  'oifde.errorCode.100051006013': 'Error al importar.',
  'oifde.errorCode.100051005025': 'El número total no puede exceder 5,000.',
  'oifde.errorCode.100051005011': 'Error al agregar el número de servicio.',
  'oifde.hisdialog.direction.UPLOAD': 'Introduzca el texto de la solicitud.',
  'oifde.hisdialog.direction.DOWNLOAD': 'Texto de respuesta',
  'oifde.hisdialog.startTime_must_earlier_endTime': 'La hora de inicio debe ser anterior a la hora de finalización. Restablecer los criterios de búsqueda.',
  'oifde.fileTask.IMPORT_SCHEMA_TOO_BIG': 'Se permite un máximo de entidades de concepto de 10,000 en un esquema de conocimiento.',
  'oifde.fileTask.DOWNLOAD_EXCEPTION': 'Se interrumpió la descarga.',
  'oifde.fileTask.TOO_MANY_ENTRIES': 'El paquete comprimido contiene demasiados archivos.',
  'oifde.fileTask.UNZIP_FAILED': 'Error al descomprimir el paquete.',
  'oifde.fileTask.FILE_TOO_LARGE': 'El paquete comprimido contiene archivos demasiado grandes.',
  'oifde.fileTask.UNZIPPED_TOO_LARGE': 'El tamaño del paquete descomprimido es demasiado grande.',
  'oifde.fileTask.SERVER_BUSY': 'El sistema está actualmente ocupado.',
  'oifde.fileTask.INVALID_TASK_ARGS': 'Falta el parámetro de tarea.',
  'oifde.fileTask.INVALID_SALT': 'La solicitud no es válida.',
  'oifde.fileTask.UNKNOWN_COMMAND': 'El comando de operación no está identificado.',
  'oifde.fileTask.INVALID_TASK_CONTENT': 'Los datos de la tarea no son válidos.',
  'oifde.fileTask.INVALID_ENTRY_EXTENSION': 'La extensión del nombre de archivo en el paquete comprimido no es válida.',
  'oifde.fileTask.IMPORT_SCHEMA_RELATION_TOO_BIG': 'Se permite un máximo de relaciones de 10,000 en un esquema de conocimiento.',
  'oifde.fileTask.INCORRECT_PARAMETERS': 'El parámetro no es válido.',
  'oifde.fileTask.UPLOAD_EXCEPTION': 'La carga se interrumpió.',
  'oifde.fileTask.INVALID_FILE_PATH': 'La ruta de acceso del archivo es incorrecta.',
  'oifde.fileTask.EMPTY_COMMAND': 'El comando Operation no puede estar vacío.',
  'oifde.fileTask.INVALID_FILE_EXTENSION': 'La extensión del nombre de archivo no es válida.',
  'oifde.fileTask.INVALID_REQUEST': 'La solicitud no es válida.',
  'oifde.fileTask.ENTRY_TOO_LARGE': 'El paquete comprimido contiene archivos de gran tamaño.',
  'oifde.fileTask.KG_NO_SCHEMA': 'El gráfico no está asociado con un esquema de conocimiento.',
  'oifde.fileTask.INVALID_ITEM_ENCODING': 'El modo de codificación no es válido.',
  'oifde.fileTask.READ_FILE_FAILED': 'Error al leer el archivo.',
  'oifde.fileTask.TASK_NOT_FOUND': 'No se encuentra la tarea.',
  'oifde.fileTask.IMPORT_KG_RELATION_TOO_BIG': 'Se permite un máximo de 100 millones de relaciones gráficas.',
  'oifde.fileTask.FILE_NOT_EXIST': 'El archivo no existe.',
  'oifde.fileTask.INVALID_ENTRY_PATH': 'La ruta del archivo en el paquete comprimido no es válida.',
  'oifde.fileTask.EMPTY_TASK_ID': 'El ID de tarea no puede estar vacío.',
  'oifde.fileTask.IMPORT_KG_ENTITY_TOO_BIG': 'Se permite un máximo de 1 millón de entidades gráficas',
  'oifde.fileTask.WRITE_FILE_FAILED': 'Error al guardar el archivo.',
  'oifde.fileTask.GRAPH_IS_ON': 'No se puede importar un gráfico activo.',
  'oifde.fileTask.UNDEFINED_ERROR': 'Se ha producido un error durante la ejecución de la tarea.',
  'oifde.fileTask.SERVICE_UNAVAILABLE': 'El servicio no está disponible actualmente.',
  'oifde.rate.hourOption': 'Por hora',
  'oifde.rate.callStaffConsultCount': 'Llamadas transferidas desde Chatbot al Agente',
  'oifde.rate.callStaffNoConsultCount': 'Llamadas procesadas sólo por el agente',
  'oifde.rate.interfaceSuccessRate': 'Tasa de éxito de invocación',
  'oifde.rate.dayconditionTips': 'Falta el código de acceso en los criterios de búsqueda.',
  'oifde.rate.hourConditionTips': 'Falta la fecha o el código de acceso en los criterios de búsqueda.',
  'oifde.rate.interfaceTimes': 'Tiempos de invocación de la interfaz',
  'oifde.rate.interfaceSuccessCount': 'Invocaciones exitosas',
  'oifde.rate.time': 'Fecha y hora',
  'oifde.rate.resolveRate': 'Tasa de resolución de autoservicio neto',
  'oifde.rate.interactCount': 'Rondas de diálogo',
  'oifde.rate.dayOption': 'Por día',
  'oifde.rate.dateSelect': 'Seleccione una fecha.',
  'oifde.rate.callCountBoard': 'Panel de estadísticas de llamadas',
  'oifde.rate.accessNum': 'Llamadas conectadas',
  'oifde.rate.dataDimension': 'Seleccione la dimensión de datos.',
  'oifde.rate.index': 'No, no.',
  'oifde.rate.interfaceRequestCount': 'Invocaciones de interfaz',
  'oifde.rate.monthOption': 'Últimos 30 días',
  'oifde.rate.rateForCharts': 'Tasa neta de resolución de autoservicio',
  'oifde.rate.averageInteractPerCall': 'Rondas de diálogo Promedio',
  'oifde.rate.autonomyRate': 'Tasa de resolución autoservicio',
  'oifde.rate.interfaceSuccessTimes': 'Interfaces invocadas con éxito',
  'oifde.rate.exitNoConsultCount': 'Llamadas directamente desconectadas',
  'oifde.rate.accessAmount': 'Llamadas conectadas',
  'oifde.rate.exitConsultingCount': 'Llamadas procesadas solo por Chatbot',
  'oifde.rate.accessCode': 'Seleccione un código de acceso del chatbot.',
  'oifde.rate.successRate': 'Interfaz que invoca la tasa de éxito',
  'oifde.testMgr.succeed_save_case': 'El caso de prueba se guarda correctamente.',
  'oifde.testMgr.faqGroup': 'Grupo de Preguntas Frecuentes',
  'oifde.testMgr.testMgr': 'Prueba de conocimiento',
  'oifde.testMgr.noSelection': 'No se pueden generar casos de prueba porque no se ha seleccionado ningún dominio o grupo de preguntas frecuentes.',
  'oifde.testMgr.allFaqGroup': 'Seleccionar todos los grupos de preguntas frecuentes',
  'oifde.testMgr.requestFailed': 'Error al invocar la interfaz para generar automáticamente casos de prueba.',
  'oifde.testMgr.failed_query': 'Error de consulta.',
  'oifde.testMgr.import_test_cases_hint': 'Se pueden importar un máximo de 200 casos de prueba en formato TXT.',
  'oifde.testMgr.userId': 'ID de suscriptor',
  'oifde.testMgr.fail_save_data': 'Error al guardar el grupo de casos de prueba.',
  'oifde.testMgr.confirm_delete_case': '¿Está seguro de que desea eliminar el caso de prueba?',
  'oifde.testMgr.succeed_delete_case': 'El caso de prueba se elimina correctamente.',
  'oifde.testMgr.succeed_save_group': 'El grupo de casos de prueba se guarda correctamente.',
  'oifde.testMgr.manage': 'Gestionar',
  'oifde.testMgr.succeed_delete_group': 'El grupo de casos de prueba se elimina correctamente.',
  'oifde.testMgr.failed_import_file': 'Error al importar el archivo.',
  'oifde.testMgr.import_test_cases': 'Caso de prueba de importación',
  'oifde.testMgr.domain': 'Dominio',
  'oifde.testMgr.getStatusWarn': 'Los casos de prueba no se pueden generar actualmente porque un proceso existente ha solicitado generar un caso de prueba. Por favor espere...',
  'oifde.testMgr.failed_delete_case': 'Error al eliminar el caso de prueba.',
  'oifde.testMgr.case_passed': 'Aprobado',
  'oifde.testMgr.add_test_case': 'Añadir caso de prueba',
  'oifde.testMgr.descriptionLen': 'La descripción puede contener un máximo de 1024 caracteres.',
  'oifde.testMgr.test_time': 'Probado',
  'oifde.testMgr.abortSuccess': 'Interrupción exitosa.',
  'oifde.testMgr.getCorpusTestCase': 'Generar caso',
  'oifde.testMgr.expected_output_with_memo': 'Palabras clave de salida esperada o nombres de intención (separe varias entradas con comas)',
  'oifde.testMgr.case_tested': 'Cantidad probada',
  'oifde.testMgr.allDomain': 'Seleccionar todos los dominios',
  'oifde.testMgr.failed_delete_group': 'Error al eliminar el grupo de casos de prueba.',
  'oifde.testMgr.actual_output_title': 'Salida real (Nombre de intención en corchetes)',
  'oifde.testMgr.testCase': 'Caso de prueba',
  'oifde.testMgr.select_prompt': 'Seleccione al menos un registro.',
  'oifde.testMgr.test_case_details': 'Detalles',
  'oifde.testMgr.getCorpusTestCaseConfig': 'Generar caso',
  'oifde.testMgr.note_test': 'No probado',
  'oifde.testMgr.execute_test': 'Ejecutar',
  'oifde.testMgr.noDomain': 'No se ha seleccionado ningún dominio.',
  'oifde.testMgr.natureColon': ':',
  'oifde.testMgr.abortCorpusTestCase': 'Interrupción',
  'oifde.testMgr.event': 'Evento',
  'oifde.testMgr.case_failed': 'Error',
  'oifde.testMgr.actual_output': 'Salida real',
  'oifde.testMgr.case_id': 'Identificación del caso',
  'oifde.testMgr.abortFailed': 'Falló la interrupción.',
  'oifde.testMgr.expected_output': 'Nombre de la palabra clave o de la intención de salida esperada',
  'oifde.testMgr.number': 'Solo se permiten dígitos.',
  'oifde.testMgr.overwrite_import': 'Sobrescribir todos los mismos parámetros',
  'oifde.testMgr.chatBotTestCaseMgr': 'Prueba de diálogo',
  'oifde.testMgr.execute_all': 'Ejecutar todo',
  'oifde.testMgr.batchExec': 'Ejecute',
  'oifde.testMgr.succeed_test_case': 'El caso de prueba se ejecuta con éxito.',
  'oifde.testMgr.success_import': 'El archivo se importa correctamente.',
  'oifde.testMgr.auto_testing_template': 'Descargar plantilla',
  'oifde.testMgr.test_case_group_details': 'Detalles',
  'oifde.testMgr.case_passed_rate': 'Tarifa de pases',
  'oifde.testMgr.botTestCaseMgr': 'Administrar caso de prueba',
  'oifde.testMgr.add_test_case_group': 'Agregar grupo de casos de prueba',
  'oifde.testMgr.case_group': 'Grupos de casos de prueba',
  'oifde.testMgr.noFaqGroup': 'No se ha seleccionado ningún grupo de preguntas frecuentes.',
  'oifde.testMgr.confirm_delete_case_group': '¿Está seguro de que desea eliminar el grupo de casos de prueba?',
  'oifde.testMgr.case_amount': 'Casos de prueba',
  'oifde.whitelist.tenantId': 'ID del espacio del tenant',
  'oifde.whitelist.tenantSpace': 'Espacio para tenants',
  'oifde.whitelist.whiteIpLen': 'La dirección IP de la lista de confianza o el nombre de dominio puede contener un máximo de 128 caracteres.',
  'oifde.whitelist.deletePrompt': 'Esta operación eliminará permanentemente la lista de confianza seleccionada. ¿Estás seguro de que quieres continuar?',
  'oifde.whitelist.description': 'Descripcion',
  'oifde.whitelist.ipRange': 'La dirección IP puede contener solo letras, dígitos, puntos (.), guiones bajos (_), y guiones (-) No puede comenzar ni terminar con un guion.',
  'oifde.whitelist.whitePort': 'Puerto de lista de confianza',
  'oifde.whitelist.number': 'El valor debe ser dígitos.',
  'oifde.whitelist.whiteIp': 'Lista de confianza Dirección IP o nombre de dominio',
  'oifde.whitelist.whiteType': 'Tipo de lista de confianza',
  'oifde.whitelist.addWhiteList': 'Añadir lista de confianza',
  'oifde.whitelist.whiteIpListNotNull': 'Seleccione al menos una lista de confianza que desea eliminar.',
  'oifde.whitelist.editWhiteList': 'Editar lista de confianza',
  'oifde.whitelist.whiteTypes.INTERFACE': 'Interfaz',
  'oifde.whitelist.whiteTypes.PROCEDURE': 'Procedimiento almacenado',
  'oifde.whitelist.whitePortLen': 'El número de puerto de lista de confianza puede contener un máximo de 20 caracteres.',
  'oifde.whitelist.descriptionLen': 'La descripción puede contener un máximo de 1024 caracteres.',
  'oifde.chatBotTest.testTime': 'Probado',
  'oifde.chatBotTest.editTestCaseGroup': 'Modificar grupo de casos de prueba',
  'oifde.chatBotTest.testCaseGroup': 'Grupos de casos de prueba',
  'oifde.chatBotTest.totalNumber': 'Casos de prueba',
  'oifde.chatBotTest.inputTestCaseGroupName': 'Grupo de casos de prueba',
  'oifde.chatBotTest.abortChatBotTestCase': 'Generación de casos de diálogo de interrupción',
  'oifde.chatBotTest.failTimes': 'Error',
  'oifde.chatBotTest.doTest': 'Ejecute',
  'oifde.chatBotTest.allAccessCode': 'Seleccionar todos los códigos de acceso del chatbot',
  'oifde.chatBotTest.callingNum': 'Número de llamada',
  'oifde.chatBotTest.deletePrompt': 'Esta operación eliminará permanentemente el grupo de casos de prueba. ¿Estás seguro de que quieres continuar?',
  'oifde.chatBotTest.deleteTestCase': 'Esta operación eliminará permanentemente los casos de prueba seleccionados. ¿Estás seguro de que quieres continuar?',
  'oifde.chatBotTest.executeSuccess': 'Ejecución exitosa.',
  'oifde.chatBotTest.botAccessCode': 'Código de acceso de Chatbot',
  'oifde.chatBotTest.getChatBotTestCaseConfig': 'Generar caso de diálogo',
  'oifde.chatBotTest.downAllReport': 'Descargar todos los informes',
  'oifde.chatBotTest.caseTimeRange': 'Rango de tiempo',
  'oifde.chatBotTest.passRate': 'Tarifa de pases',
  'oifde.chatBotTest.executeFailed': 'Error en la ejecución.',
  'oifde.chatBotTest.addTestCaseGroup': 'Agregar grupo de casos de prueba',
  'oifde.chatBotTest.description': 'Descripcion',
  'oifde.chatBotTest.status': 'Estado',
  'oifde.chatBotTest.status.initial': 'Inicialización',
  'oifde.chatBotTest.status.waiting': 'Esperando',
  'oifde.chatBotTest.status.running': 'En funcionamiento',
  'oifde.chatBotTest.status.finish': 'Completado',
  'oifde.chatBotTest.status.failed': 'Fallo',
  'oifde.chatBotTest.status.success': 'Éxito',
  'oifde.chatBotTest.downTestReport': 'Descargar informe de prueba',
  'oifde.chatBotTest.cancleExcute': 'La ejecución se cancela.',
  'oifde.chatBotTest.prompt': 'Información de contacto',
  'oifde.chatBotTest.robotName': 'Nombre del Chatbot',
  'oifde.chatBotTest.passTimes': 'Aprobado',
  'oifde.chatBotTest.testTimes': 'Cantidad probada',
  'oifde.chatBotTest.getChatBotTestCase': 'Generar caso de diálogo',
  'oifde.chatBotTest.promptMessage': 'Seleccione al menos un registro.',
  'oifde.chatBotTest.downloadMessage': 'Ha seleccionado {0} registros. ¿Estás seguro de que quieres continuar?',
  'oifde.chatBotTest.downloadAllMessage': 'No ha seleccionado registros específicos. ¿Exportar todos los registros? También puede cancelar la operación y seleccionar un registro específico para exportar.',
  'oifde.chatBotTest.checkPrompt': 'La ejecución de múltiples casos de prueba simultáneamente puede ralentizar el sistema. ¿Estás seguro de que quieres continuar?',
  'oifde.chatBotTest.testCaseGroupName': 'Nombre del grupo de casos',
  'oifde.chatBotTest.callingId': 'Número de llamada',
  'oifde.chatBotTest.rangeFilter': 'Filtrar registros seleccionados',
  'oifde.license.failed_set_license': 'Error al configurar la licencia.',
  'oifde.license.queryCurrentLicense': 'Consultar simultaneidad y acumulación actuales',
  'oifde.license.LIN8OIAPVA02': 'Número acumulado de llamadas de agentes virtuales',
  'oifde.license.LIN8OIAPVA01': 'Número de agentes virtuales simultáneos',
  'oifde.license.sys': 'Sistemas',
  'oifde.license.success_set_license': 'La licencia se ha configurado correctamente.',
  'oifde.license.import_template_need_dat': 'El archivo que se va a cargar debe estar en formato.dat.',
  'oifde.license.PERMANENT': 'Permanente',
  'oifde.license.valateDate': 'Fecha de caducidad',
  'oifde.license.maxHisInfo': 'Valor máximo histórico',
  'oifde.license.processTime': 'Procesado',
  'oifde.license.totalLicense': 'Cantidad de licencia',
  'oifde.license.detailHisInfo': 'Detalles Históricos',
  'oifde.license.license': 'Gestión de licencias',
  'oifde.license.setlicense': 'Asignar licencia',
  'oifde.license.queryHistoryLicense': 'Consultar simultaneidad histórica',
  'oifde.license.LIN8OIAPAA01': 'Número de asistentes de agentes simultáneos',
  'oifde.license.at_least_one_serviceValue': 'Establezca al menos un parámetro.',
  'oifde.license.import': 'Cargar/Actualizar',
  'oifde.license.import_template_hint_dat': 'Solo se pueden cargar archivos en formato.dat.',
  'oifde.asrMgr.selectOne': 'Seleccione al menos uno de los siguientes: proveedor de servidor ASR, servidor ASR y servidor TTS.',
  'oifde.asrMgr.slotName': 'Ranura ASR',
  'oifde.asrMgr.mrcpid': 'ID de grupo ASR',
  'oifde.asrMgr.addAsr': 'Añadir TTS/ASR',
  'oifde.asrMgr.TENANT_SPACE_NOT_FOUND': 'El espacio del tenant no se encuentra.',
  'oifde.asrMgr.asr': 'Proveedor de servidor ASR',
  'oifde.asrMgr.editAsr': 'Modificar TTS/ASR',
  'oifde.asrMgr.groupID': 'ID de grupo TTS',
  'oifde.asrMgr.path': 'Ruta del archivo de gramática ASR',
  'oifde.asrMgr.tenantSapce': 'Espacio para tenants',
  'oifde.asrMgr.tenantId': 'ID del espacio del tenant',
  'oifde.asrMgr.languageTips': 'default indica que no se distinguen los idiomas',
  'oifde.dashBoard.chartView.title': 'Respuesta Tasa de éxito',
  'oifde.dashBoard.chartView.countTile': 'Total de respuestas',
  'oifde.dashBoard.chartView.leftTile': 'Total de respuestas',
  'oifde.dashBoard.chartView.rateTile': 'Tasa de éxito de respuesta',
  'oifde.dashBoard.chartView.rightTile': 'Tasa de éxito de respuesta',
  'oifde.dashBoard.service.consultCount': 'Total de respuestas',
  'oifde.dashBoard.service.feedbackCount': 'Comentarios totales',
  'oifde.dashBoard.service.positiveFeedbackRate': 'Tasa de satisfacción',
  'oifde.dashBoard.service.unknowCount': 'Respuestas desconocidas',
  'oifde.dashBoard.service.responseSuccessCount': 'Respuestas exitosas',
  'oifde.dashBoard.service.positiveFeedbackCount': 'Comentarios satisfechos',
  'oifde.dashBoard.service.responseRate': 'Respuesta Tasa de éxito',
  'oifde.dashBoard.pie.talk': 'Chatear',
  'oifde.dashBoard.pie.title': 'Hit Distribución de Conocimientos',
  'oifde.dashBoard.pie.talkTitle': 'Chatear',
  'oifde.dashBoard.pie.kg': 'Gráfica',
  'oifde.dashBoard.pie.intentTitle': 'Intención',
  'oifde.dashBoard.pie.faqTitle': 'Preguntas Frecuentes',
  'oifde.dashBoard.pie.kgTitle': 'Gráfica',
  'oifde.dashBoard.pie.intent': 'Intención',
  'oifde.dashBoard.pie.faq': 'Preguntas Frecuentes',
  'oifde.dashBoard.serviceCapabilityDashBoard': 'Panel de control de capacidad de servicio',
  'oifde.dashBoard.knowledge.accessCode': 'Código de acceso',
  'oifde.dashBoard.knowledge.hitCount': 'Golpes',
  'oifde.dashBoard.knowledge.faq': 'Preguntas Frecuentes',
  'oifde.dashBoard.knowledge.name': 'Nombre de usuario',
  'oifde.dashBoard.knowledge.intent': 'Intención',
  'oifde.dashBoard.knowledge.callCount': 'Visitas',
  'oifde.dashBoard.knowledge.topTenknowledge': 'Top 10 Entidades de Conocimiento Útiles',
  'oifde.dashBoard.knowledge.topTenChatBot': 'Top 10 Códigos de Acceso Chatbot',
  'oifde.dashBoard.knowledge.dateConditionTips': 'Seleccione una fecha.',
  'oifde.dashBoard.graph.hitRate': 'Tasa de éxito',
  'oifde.dashBoard.graph.hit': 'Hits',
  'oifde.dashBoard.graph.countTitle': 'En total',
  'oifde.dashBoard.graph.amount': 'Cantidad',
  'oifde.dashBoard.graph.percent': 'Porcentaje',
  'oifde.dashBoard.graph.hitTitle': 'Golpes',
  'oifde.dashBoard.graph.hitRateTitle': 'Tasa de éxito',
  'oifde.dashBoard.graph.talk': 'Chatear',
  'oifde.dashBoard.graph.count': 'En total',
  'oifde.dashBoard.graph.kg': 'Gráfica',
  'oifde.dashBoard.graph.intent': 'Intención',
  'oifde.dashBoard.graph.title': 'Estadísticas de conocimiento válidas',
  'oifde.dashBoard.graph.faq': 'Preguntas Frecuentes',
  'oifde.dashBoard.knowledgeDashBoard': 'Panel de Conocimiento',
  'oifde.dashBoard.noData': '- (No se pueden mostrar los datos eliminados.)',
  'oifde.recordHistory.silentSeat': 'Agente silencioso',
  'oifde.recordHistory.callNumber': 'Número de suscriptor',
  'oifde.recordHistory.reqTime': 'Solicitado',
  'oifde.recordHistory.callNumberLength': 'El número de abonado no puede contener más de 64 caracteres.',
  'oifde.recordHistory.callStatus': 'Monitoreo de llamadas entrantes',
  'oifde.recordHistory.recordHistory': 'Historial de llamadas',
  'oifde.recordHistory.systemReply': 'Texto de respuesta',
  'oifde.recordHistory.oneCondition': 'Se requiere la fecha o el número de teléfono móvil.',
  'oifde.recordHistory.respSource': 'Fuente de la respuesta',
  'oifde.recordHistory.recordDetail': 'Detalles de la llamada',
  'oifde.recordHistory.userSaying': 'Solicitar texto',
  'oifde.recordHistory.respTime': 'Respondido',
  'oifde.recordHistory.kgStatus': 'Monitoreo de Conocimientos',
  'oifde.recordHistory.bot': 'Chatbot',
  'oifde.recordHistory.customer': 'Clientes',
  'oifde.testCase.detail.realResponse': 'Texto de respuesta real',
  'oifde.testCase.detail.channelAssociatedData': 'Datos asociados',
  'oifde.testCase.detail.reqText': 'Solicitar texto',
  'oifde.testCase.detail.close': 'Acerca de',
  'oifde.testCase.detail.expectedReturn': 'Texto de respuesta esperada',
  'oifde.testCase.detail.operation': 'Operación',
  'oifde.testCase.caseDetail': 'Detalles del caso',
  'oifde.testCase.caseName': 'Nombre del caso',
  'oifde.testCase.executeStatus': 'Estado',
  'oifde.testCase.caseDetailMgr': 'Gestionar detalles del caso',
  'oifde.testCase.caseID': 'Identificación del caso',
  'oifde.testCase.status': 'Estado',
  'oifde.testCase.editTestCase': 'Modificar caso de prueba',
  'oifde.testCase.traceLog': 'Pista de flujo',
  'oifde.testCase.description': 'Descripcion',
  'oifde.testCase.callDetail': 'Detalles',
  'oifde.testCase.name': 'Nombre del caso de prueba',
  'oifde.testCase.detailDialog': 'Detalles del caso',
  'oifde.testCase.doAll': 'Ejecutar todo',
  'oifde.testCase.addTestCase': 'Añadir caso de prueba',
  'oifde.serverMgr.confirmDelete': '¿Está seguro de que desea eliminar los datos?',
  'oifde.serverMgr.serverType': 'Tipo de servidor',
  'oifde.serverMgr.addServer': 'Agregar servidor',
  'oifde.serverMgr.asrServer': 'Servidor ASR',
  'oifde.serverMgr.mrcpId': 'ID de grupo',
  'oifde.serverMgr.editServer': 'Modificar servidor',
  'oifde.serverMgr.ttsServer': 'Servidor TTS',
  'oifde.qiAsrServerMgr.asrVendor': 'Fabricante',
  'oifde.qiAsrServerMgr.asrServerName': 'Nombre del servidor',
  'oifde.qiAsrServerMgr.asrServerAddress': 'Dirección del servidor',
  'oifde.qiAsrServerMgr.asrAppKey': 'Nombre de usuario/AK',
  'oifde.qiAsrServerMgr.fileServerAddress': 'Dirección del servidor de archivos',
  'oifde.qiAsrServerMgr.folder': 'Carpeta predeterminada',
  'oifde.qiAsrServerMgr.description': 'Descripción',
  'oifde.qiAsrServerMgr.addQiAsrServer': 'Añadir información del servidor ASR de control de calidad',
  'oifde.qiAsrServerMgr.editQiAsrServer': 'Editar la información del servidor ASR de control de calidad',
  'oifde.qiAsrServerMgr.asrServerInfo': 'Información del servidor ASR',
  'oifde.qiAsrServerMgr.name': 'Nombre',
  'oifde.qiAsrServerMgr.asrServerIP': 'Dirección del servidor ASR',
  'oifde.qiAsrServerMgr.asrServerPort': 'Puerto del servidor ASR',
  'oifde.qiAsrServerMgr.asrServerUser': 'Usuario del servidor ASR',
  'oifde.qiAsrServerMgr.asrServerPW': 'Contraseña del servidor ASR',
  'oifde.qiAsrServerMgr.asrServerPWConform': 'Confirmar contraseña del servidor ASR',
  'oifde.qiAsrServerMgr.nextStep': 'Siguiente paso',
  'oifde.qiAsrServerMgr.preStep': 'Anterior',
  'oifde.qiAsrServerMgr.fileServerInfo': 'Información del servidor de archivos',
  'oifde.qiAsrServerMgr.fileServerType': 'Tipo de servidor de archivos',
  'oifde.qiAsrServerMgr.fileServerIP': 'Dirección del servidor de archivos',
  'oifde.qiAsrServerMgr.fileServerPort': 'Puerto del servidor de archivos',
  'oifde.qiAsrServerMgr.fileServerUser': 'Nombre de usuario del servidor de archivos',
  'oifde.qiAsrServerMgr.fileServerPW': 'Contraseña del servidor de archivos',
  'oifde.qiAsrServerMgr.fileServerPWConfirm': 'Confirmar contraseña del servidor de archivos',
  'oifde.qiAsrServerMgr.voiceUploadFolder': 'Ruta de carga del archivo de grabación',
  'oifde.qiAsrServerMgr.qiResultFolder': 'Ruta de transferencia de resultados',
  'oifde.qiAsrServerMgr.sftp': 'SFTP',
  'oifde.qiAsrServerMgr.passwordError': 'Las contraseñas introducidas dos veces son inconsistentes.',
  'oifde.qiAsrServerMgr.ipOrDomainNameError': 'La dirección IP o el nombre de dominio introducidos son incorrectos.',
  'oifde.qiAsrServerMgr.ipError': 'La dirección IP es incorrecta.',
  'oifde.qiAsrServerMgr.filePathError': 'El formato de ruta es incorrecto.',
  'oifde.qiAsrServerMgr.portTips': 'Si la dirección del servidor ASR es un nombre de dominio, el número de puerto es opcional.',
  'oifde.qiAsrServerMgr.asrServerNameError': 'Este parámetro es el mismo que el nombre predeterminado del servidor ASR.',
  'oifde.importFile.knowledge_graph_ask_template': 'Sobrescribir todos los parámetros existentes',
  'oifde.importFile.white_list': 'Sobrescribir todos los parámetros existentes',
  'oifde.importFile.test_case_group': 'Sobrescribir todos los parámetros existentes',
  'oifde.importFile.knowledge_graph': 'Sobrescribir nombres duplicados',
  'oifde.chatbot.errorCode.100051000109': 'El número de casos de prueba supera los 200.',
  'oifde.chatbot.errorCode.100023000004': 'Error al verificar el ID de entrada.',
  'oifde.chatbot.errorCode.100051000032': 'Error al eliminar casos de prueba.',
  'oifde.chatbot.errorCode.100051000031': 'El número de ID de entrada excede el límite superior.',
  'oifde.chatbot.errorCode.100051000028': 'El resultado de la prueba no se encuentra.',
  'oifde.chatbot.errorCode.100051000026': 'La prueba no se ejecuta porque el número de grupos de prueba seleccionados es demasiado grande.',
  'oifde.chatbot.errorCode.100051000023': 'Error al eliminar el grupo de casos.',
  'oifde.chatbot.errorCode.100051000022': 'Error al guardar el caso de prueba.',
  'oifde.chatbot.errorCode.100010000005': 'El parámetro de entrada no es válido.',
  'oifde.chatbot.errorCode.100010000003': 'El nombre debe ser único.',
  'oifde.chatbot.errorCode.100010000002': 'El formato es incorrecto.',
  'oifde.chatbot.errorCode.100010000001': 'Error de base de datos.',
  'oifde.chatbot.errorCode.100010000000': 'Error del sistema.',
  'oifde.error.messageTail': 'Compruebe si la red y el servidor funcionan correctamente.',
  'oifde.error.messageHead': 'Interfaz Frontend -',
  'oifde.error.messageBody': 'Error de solicitud. Código de error:',
  'oifde.qarecord.interface.name': 'Record Q&A',
  'oifde.qarecord.interface.desc': 'Registre el ID de la pregunta, el título de la pregunta, la intención de respuesta del usuario y la respuesta original del usuario.',
  'oifde.apiToken.interface.name':'Obtener el token APIFabric',
  'oifde.apiToken.interface.desc': 'Interfaz utilizada para obtener el token API Fabric',
  'oifde.apiToken.applyType.desc': 'Prefijo de token',
  'oifde.apiToken.accessToken.desc': 'Contenido del token',
  'oifde.apiToken.appKey.desc': 'Clave de aplicación',
  'oifde.apiToken.appSecret.desc': 'App secret',
  'oifde.qarecord.inputparam.seriesId': 'ID de pregunta y respuesta',
  'oifde.qarecord.inputparam.questionTitle': 'Título de la pregunta',
  'oifde.qarecord.inputparam.questionType': 'Tipo de pregunta',
  'oifde.qarecord.inputparam.answerIntent': 'Intención de respuesta',
  'oifde.qarecord.inputparam.originalAnswer': 'Respuesta original',
  'oifde.request.timeout': 'Tiempo de espera de solicitud de interfaz',
  'oifde.analysis.validate.time': 'Fecha requerida',
  'oifde.analysis.validate.accessCode': 'Código de acceso del robot requerido',
  'oifde.analysis.validate.timeInterval': 'El intervalo no es más de 7 días.',
  'oifde.analysis.chart.title': 'Estadísticas de rama de nodo IVR',
  'oifde.analysis.chart.timeOther': 'Tiempo de espera o pulse otra tecla',
  'oifde.analysis.chart.button': 'Presión de teclas ',
  'oifde.analysis.chart.default': 'Rama predeterminada del sistema',
  'oifde.analysis.childrenView': 'Ver subnodos',
  'oifde.service.invoke.breakMinTime.desc': 'Unidad: segundos;',
  'oifde.ivrJourney.nodeOverview': 'Descripción general del nodo',
  'oifde.ivrJourney.trendAnalysis': 'Análisis de tendencia',
  'oifde.ivrJourney.keyDistribution': 'Distribución de claves',
  'oifde.ivrJourney.nodeVersionComparison': 'Comparación de versiones de nodo',
  'oifde.ivrJourney.businessRanking': 'Ranking de servicios',
  'oifde.ivrJourney.no': 'Ranking',
  'oifde.ivrJourney.nodeName': 'NodeName',
  'oifde.ivrJourney.visitsTotal': 'Visitas',
  'oifde.ivrJourney.yoyValue': 'Anual',
  'oifde.ivrJourney.increaseValue': 'Arriba y caída',
  'oifde.ivrJourney.visitorTotal': 'Visitas',
  'oifde.ivrJourney.successRate': 'Tasa de éxito',
  'oifde.ivrJourney.failedRate': 'Tasa de fallas',
  'oifde.ivrJourney.hangUpRate': 'Tasa de suspensión',
  'oifde.ivrJourney.journeyNumTrend': 'Distribución de Tendencias de Valor',
  'oifde.ivrJourney.efficiencyTrend': 'Distribución de Tendencias de Eficiencia',
  'oifde.ivrJourney.complexityTrend': 'Distribución de Tendencias de Complejidad',
  'oifde.ivrJourney.repeatNum': 'Ensayar',
  'oifde.ivrJourney.directCalls': 'Directo',
  'oifde.ivrJourney.nonDirectCalls': 'Non Direct',
  'oifde.ivrJourney.journeyNum': 'Visitas',
  'oifde.ivrJourney.hangupNum': 'Hang Up',
  'oifde.ivrJourney.successCalls': 'Número de éxitos',
  'oifde.ivrJourney.failCalls': 'Número de fallos',
  'oifde.ivrJourney.directRate': 'Direct Rate',
  'oifde.ivrJourney.nonDirectRate': 'Non Direct Rate',
  'oifde.ivrJourney.hangupRate': 'Hang Up Rate',
  'oifde.ivrJourney.failRate': 'Tasa de errores',
  'oifde.ivrJourney.repeatCalls': 'Ensayar',
  'oifde.ivrJourney.repeatOnceCalls': 'Ensayar una vez',
  'oifde.ivrJourney.repeatTwiceCalls': 'Ensayar dos veces',
  'oifde.ivrJourney.repeatRate': 'Velocidad de ensayo',
  'oifde.ivrJourney.averageTime': 'Duración media del servicio',
  'oifde.ivrJourney.averagePathLength': 'Longitud de ruta media',
  'oifde.ivrJourney.timeRangeCheck30': 'El intervalo de tiempo no puede exceder de 30 días',
  'oifde.ivrJourney.dateTimrRange': 'Rango de tiempo de datos:',
  'oifde.ivrJourney.total': 'Total',
  'oifde.ivrJourney.inflowDistribution': 'Distribución de entrada',
  'oifde.ivrJourney.outflowDistribution': 'Distribución de salida',
  'oifde.ivrJourney.totalDistribution': 'Distribución general',
  'oifde.ivrJourney.detailDistribution': 'Distribución detallada',
  'oifde.ivrJourney.outflowDetailDistribution': 'Distribución detallada del flujo de salida',
  'oifde.ivrJourney.outflowDetailDistributionAmount': 'Importe detallado de la distribución de salida',
  'oifde.ivrJourney.outflowDetailDistributionPercentage': 'Porcentaje de distribución detallada de salida',
  'oifde.ivrJourney.nodeTrafficChart': 'Gráfico de tráfico de nodos (número de contadores)',
  'oifde.ivrJourney.indicatorQuantityStatistics': 'Estadísticas de contadores',
  'oifde.ivrJourney.indicateName': 'Nombre del indicador',
  'oifde.ivrJourney.alarmContent': 'Contenido de preaviso',
  'oifde.ivrJourney.alarmTime': 'Tiempo de preaviso',
  'oifde.ivrJourney.createTime': 'Tiempo de los datos previos a la advertencia',
  'oifde.ivrJourney.alarmLevel': 'Nivel de preaviso',
  'oifde.ivrJourney.alarmIndicator': 'Indicadores de preaviso',
  'oifde.ivrJourney.remindType': 'Modo recordatorio',
  'oifde.ivrJourney.receivers': 'Destinatario',
  'oifde.ivrJourney.sendResult': 'Envío de resultados',
  'oifde.ivrJourney.low': 'Bajo',
  'oifde.ivrJourney.middle': 'Medio',
  'oifde.ivrJourney.high': 'Alta',
  'oifde.ivrJourney.noNeedToSend': 'No hay necesidad de enviar',
  'oifde.ivrJourney.sendFail': 'Error al enviar',
  'oifde.ivrJourney.sendSuccess': 'Enviado con éxito',
  'oifde.ivrJourney.sms': 'SMS',
  'oifde.ivrJourney.email': 'Correo Electrónico',
  'oifde.ivrJourney.smsAndEmail': 'SMS; Correo electrónico',
  'oifde.ivrJourney.other': 'Otros',
  'oifde.ivrJourney.nowData': 'Valor actual',
  'oifde.ivrJourney.label': 'Identificación',
  'oifde.ivrJourney.transToManualCalls': 'Transferir cantidad de mano de obra',
  'oifde.ivrJourney.transToNextLevelManuCalls': 'Cantidad de menú de nivel inferior',
  'oifde.ivrJourney.timeOutOrPressAnotherKey': 'Tiempo de espera o presionar otra tecla',
  'oifde.ivrJourney.buttons': 'Tiempo de espera o presionar otra tecla',
  'oifde.ivrJourney.defaultBranch': 'Rama predeterminada',
  'oifde.ivrJourney.count': 'Número de veces',
  'oifde.ivrJourney.versionName': 'Nombre de la versión',
  'oifde.ivrJourney.compareRange': 'Rango de comparación',
  'oifde.ivrJourney.pleaseSelectAccessCode': 'Por favor, seleccione un robot',
  'oifde.ivrJourney.pleaseSelectAccessCodeFirst': 'Seleccione primero un robot',
  'oifde.ivrJourney.oneDay': '1 día',
  'oifde.ivrJourney.threeDay': '3 días',
  'oifde.ivrJourney.halfMonth': 'Medio mes',
  'oifde.ivrJourney.oneMonth': 'Un mes',
  'oifde.ivrJourney.chooseAccessCode': 'Seleccionar un robot',
  'oifde.ivrJourney.chooseVersion': 'Seleccionar versión',
  'oifde.ivrJourney.releaseDate': 'Fecha de lanzamiento',
  'oifde.ivrJourney.nodeVersionCompareStatistics': 'Estadísticas de comparación de versiones de nodos',
  'oifde.ivrJourney.validIVRService': 'Servicio IVR válido',
  'oifde.ivrJourney.transToManualCallsSucc': 'Transferido a Manual Exitoso',
  'oifde.ivrJourney.selfService': 'Autoservicio',
  'oifde.ivrJourney.repeatIncomingCalls': 'Llamadas entrantes repetidas',
  'oifde.ivrJourney.returnKeysByErrors': 'Retorno de error de clave',
  'oifde.ivrJourney.pleaseSelectVersion': 'Seleccione una versión de proceso',
  'oifde.ivrJourney.pleaseSelectCompareRange': 'Por favor, seleccione la gama de comparación',
  'oifde.ivrJourney.logTime': 'Fecha',
  'oifde.ivrJourney.validIVRCalls': 'Cantidad de servicio IVR válida',
  'oifde.ivrJourney.IVRCalls': 'Volumen de autoservicio',
  'oifde.ivrJourney.IVRTransManualRate': 'Proporción de tráfico manual (%)',
  'oifde.ivrJourney.IVRRate': 'Tasa de servicio IVR puro (%)',
  'oifde.ivrJourney.IVRRateNoSymbol': 'Tasa de servicio IVR puro',
  'oifde.ivrJourney.transManualSuccCalls': 'Número de transferencias manuales exitosas',
  'oifde.ivrJourney.transManualSuccRate': 'Tasa de éxito de transferencia manual (%)',
  'oifde.ivrJourney.avgIVRTime': 'Duración media de la llamada  (segundos)',
  'oifde.ivrJourney.avgIVRTimeNoSymbol': 'Duración media de la llamada',
  'oifde.ivrJourney.IVRErrorRate': 'Tasa de error del sistema IVR (%)',
  'oifde.ivrJourney.IVRErrorRateNoSymbol': 'Tasa de error del sistema IVR',
  'oifde.ivrJourney.IVRTotalCalls': 'Volumen de llamadas entrantes IVR',
  'oifde.ivrJourney.IVRSrvTime': 'Duración total del servicio (segundos)',
  'oifde.ivrJourney.wrongKeyCalls': 'Cantidad de retorno de error de clave',
  'oifde.ivrJourney.distinctCustomers': 'Número de clientes',
  'oifde.ivrJourney.IVRSrvCustomers': 'Número de clientes IVR puros',
  'oifde.ivrJourney.callNum': 'Volumen de llamadas',
  'oifde.ivrJourney.ivrRepeatCallNum': 'Repetición de llamadas IVR',
  'oifde.ivrJourney.resolveRate': 'Tasa de resolución única',
  'oifde.ivrJourney.serviceRate': 'Tasa de Servicio Efectiva',
  'oifde.ivrJourney.node': 'Nodo ',
  'oifde.ivrJourney.indicatorDesc': 'Indicator Description',
  'oifde.ivrJourney.validIVRServiceDesc': 'Número de llamadas IVR en las que el manejo del servicio o consulta es exitoso, la voz de consulta de servicio se reproduce más de la mitad, o el SMS, MMS, WAP Push o correo electrónico se activan con éxito.',
  'oifde.ivrJourney.transToManualCallsDesc': 'Número de llamadas de IVR que intentan ser reenviadas al servicio manual',
  'oifde.ivrJourney.transToManualCallsSuccDesc': 'Número de llamadas de IVR que se transfieren correctamente a un agente.',
  'oifde.ivrJourney.selfServiceDesc': 'Número de llamadas de IVR que no se reenvían al servicio manual.',
  'oifde.ivrJourney.repeatIncomingCallsDesc': 'Número de clientes que utilizan el mismo número de llamada para llamar a la línea directa de atención al cliente repetidamente dentro de dos horas.',
  'oifde.ivrJourney.returnKeysByErrorsDesc': 'En el nodo de navegación, después de presionar incorrectamente una tecla, el número devuelto por la tecla asterisco (*) se selecciona como se indica.',
  'oifde.ivrJourney.indicatorSetting': 'Configuración del indicador',
  'oifde.ivrJourney.indicatorSettingLengthError': 'Seleccione al menos {count} indicadores.',
  'oifde.ivrJourney.extendedMetrics': 'Métricas extendidas',
  'oifde.ivrJourney.optimizeVoiceSequence': 'Optimización de la secuencia de reproducción de voz',
  'oifde.ivrJourney.optimizeVoiceDefinition': 'Optimización de la definición de voz',
  'oifde.ivrJourney.pleaseSelectDateRange': 'Seleccione un intervalo de tiempo',
  'oifde.ivrJourney.reverseFlow': 'Dirección inversa del flujo',
  'oifde.ivrJourney.currentVoicePlayContent': 'Contenido de reproducción de voz actual',
  'oifde.ivrJourney.repeatCallsNum': 'Número de repeticiones',
  'oifde.ivrJourney.optimizeSuggestion': 'Sugerencias de optimización',
  'oifde.ivrJourney.repeatCallOptimizeSuggestion': 'El número de repeticiones en el nodo actual es demasiado alto. Compruebe si el contenido de voz no está claro o es difícil de entender. Se recomienda optimizar el contenido de voz.',
  'oifde.ivrJourney.operationStatus': 'Estado de operación',
  'oifde.ivrJourney.poor': 'Pobres',
  'oifde.ivrJourney.general': 'Información general',
  'oifde.ivrJourney.good': 'Muy bueno',
  'oifde.ivrJourney.fluctuationService': 'Servicio de fluctuación',
  'oifde.ivrJourney.IVRIncomingCallVolumeTrend': 'Tendencia del volumen de llamadas entrantes de IVR',
  'oifde.ivrJourney.efficiencyOverview': 'Descripción general de la eficiencia',
  'oifde.ivrJourney.repeatedCallRate': 'Velocidad de marcación repetida',
  'oifde.ivrJourney.valueOverview': 'Visión general del valor',
  'oifde.ivrJourney.pureIVRServiceVolume': 'Volumen de servicio de IVR puro',
  'oifde.ivrJourney.complexityOverview': 'Descripción general de la complejidad',
  'oifde.ivrJourney.personTime': 'cantidad',
  'oifde.ivrJourney.times': 'veces',
  'oifde.ivrJourney.noData': 'Ninguno',
  'oifde.ivrJourney.usageStatistics': 'Estadísticas de uso de claves de reproducción de voz actuales',
  'oifde.ivrJourney.playingOrder': 'Secuencia de reproducción de voz recomendada',
  'oifde.ivrJourney.journeySum': 'Tiempos de uso',
  'oifde.ivrJourney.forever': 'Válido de forma permanente',
  'oifde.ivrJourney.slectTimeForever': 'Especifica el período de validez.',
  'oifde.ivrJourney.alarmIndex': 'Indicadores estadísticos',
  'oifde.ivrJourney.alarmCondition': 'Período de validez de preaviso',
  'oifde.ivrJourney.alarmValidity': 'Período de validez de preaviso',
  'oifde.ivrJourney.selectAlarmCondition': 'Seleccione la condición de alerta que desea eliminar.',
  'oifde.ivrJourney.remindObject': 'Objeto Recordatorio',
  'oifde.ivrJourney.transfManualRate': 'Tarifa de transferencia a manual',
  'oifde.ivrJourney.absoluteValue': 'valor absoluto',
  'oifde.ivrJourney.highThan': 'Más alto que',
  'oifde.ivrJourney.equal': 'Es igual a',
  'oifde.ivrJourney.lessThen': 'Menos de',
  'oifde.ivrJourney.createAlarmConfig': 'Creación de una condición de prealarma de servicio',
  'oifde.ivrJourney.editAlarmConfig': 'Edición de una condición de prealarma de servicio',
  'oifde.ivrJourney.selectAccesscodeAndFlow': 'Seleccione una versión de robot y proceso.',
  'oifde.ivrJourney.selectAlarmIndex': 'Seleccione un indicador estadístico.',
  'oifde.ivrJourney.fillAlarmCondition': 'Por favor complete las condiciones de pre-alarma.',
  'oifde.ivrJourney.selectAlarmLevel': 'Seleccione un nivel de advertencia.',
  'oifde.ivrJourney.selectTime': 'Seleccione una hora válida.',
  'oifde.ivrJourney.selectAlramRemindType': 'Seleccione al menos un modo de recordatorio.',
  'oifde.ivrJourney.selectRemindObject': 'Seleccione un objeto recordatorio.',
  'oifde.ivrJourney.remindObjectMaxNum': 'Se puede seleccionar un máximo de 20 caracteres para el objeto de notificación.',
  'oifde.ivrJourney.batchDetele': 'Borrar lote',
  'oifde.ivrJourney.selectSmsGateway': 'Seleccione una puerta de enlace SMS.',
  'oifde.ivrJourney.selectMailGateway': 'Seleccione una puerta de enlace de correo electrónico.',
  'oifde.ivrJourney.indicatorComparisonBarChart': 'Gráfico de barras de comparación de indicadores',
  'oifde.ivrJourney.indicatorComparisonStatistics': 'Estadísticas de comparación de indicadores',
  'oifde.ivrJourney.beforeRelease': 'Antes del lanzamiento',
  'oifde.ivrJourney.afterRelease': 'Después de la publicación',
  'oifde.ivrJourney.beforeReleaseRepeatRate': 'Tasa de repetición antes del lanzamiento',
  'oifde.ivrJourney.afterReleaseRepeatRate': 'Tasa de repetición después de la liberación',
  'oifde.ivrJourney.beforeReleaseHangupNum': 'Cantidad de llamadas colgadas antes de la liberación',
  'oifde.ivrJourney.afterReleaseHangupNum': 'Número de llamadas colgadas después de la liberación',
  'oifde.headerTitle.flowManagement': 'Gestión de procesos',
  'oifde.headerTitle.flowConfiguration': 'Configuración del proceso',
  'oifde.trackmgr.robotsTrack': 'Robot de seguimiento',
  'oifde.trackmgr.robotsSource': 'Origen',
  'oifde.maintain.odfsLogOpen': 'Habilitar la función de registro ODFS',
  'oifde.maintenance.level': 'Nivel',
  'oifde.maintenance.startTime': 'Tiempo efectivo',
  'oifde.maintenance.duration': 'Duración (minutos)',
  'oifde.maintenance.startLog': 'Activación de registro',
  'oifde.maintenance.stop_success': 'El registro se detuvo correctamente',
  'oifde.maintenance.start_success': 'La función de registro se ha habilitado correctamente',
  'oifde.route.transferRecord': 'Registro de transferencia',
  'oifde.route.audioVideoResource': 'Recurso de audio y video',
  'oifde.serviceInterface.thirdAPILog': 'Registro de invocación de interfaz',
  'oifde.serviceInterface.statusCode': 'Código de estado',
  'oifde.serviceInterface.invokingTime': 'Tiempo de llamada',
  'oifde.serviceInterface.callId': 'ID de llamada',
  'oifde.serviceInterface.invokingDetails': 'Detalles de la invocación',
  'oifde.title.chooseFlow': 'Elegir Flujo',
  'oifde.tip.inputFlowName': 'Introduzca un nombre de flujo',
  'oifde.error.createfail': 'Error al agregar datos locales!',
  'oifde.error.gateway.fail': 'Error al sincronizar la información de la plataforma CTI! Compruebe la configuración de la puerta de enlace.',
  'oifde.error.NMS.fail': 'Error en la sincronización con el NMS!',
  'oifde.flowname.maxlength': 'El nombre del robot es demasiado largo.',
  'oifde.asrMgr.qiAsrServerName': 'Servidor ASR de control de calidad',
  'oifde.audioVideoResource.setLanguage': 'Idioma Editar',
  'oifde.audioVideoResource.preview': 'Preview',
  'oifde.audioVideoResource.viewReferences': 'Ver referencias',
  'oifde.audioVideoResource.cancelUpdate': 'Cancelar actualización',
  'oifde.audioVideoResource.updateBefore': 'Before update',
  'oifde.audioVideoResource.updateafter': 'Después de la actualización',
  'oifde.audioVideoResource.templateName': "Ingrese un nombre de plantilla",
  'oifde.audio.speedRange': 'Value range: [- 200, 500]',
  'oifde.route.sysAudio': 'Gestión de voz',
  'oifde.param.maxLength': 'La longitud del parámetro no puede exceder ',
  'oifde.tip.oldNode': 'No se recomiendan los elementos de diagrama obsoletos',
  'oifde.diagram.element.expired': 'Elemento caducado',
  'oifde.diagram.element.basic': 'Elemento básico',
  'oifde.diagram.element.logic': 'Elemento lógico',
  'oifde.diagram.element.business': 'Elemento de servicio',
  'oifde.diagram.element.composite': 'Elemento compuesto',
  'oifde.diagram.node.type': 'Tipo de nodo',
  'oifde.title.chooseGrayRule': 'Elegir regla gris',
  'oifde.title.chatbot': 'Bot de chat',
  'oifde.audioVideoResource.cancelUpdate.confirm': '¿Está seguro de que desea cancelar la actualización?',
  'oifde.audioVideoResource.cancelUpdate.success': 'La actualización se cancela correctamente',
  'oifde.audioVideoResource.cancelUpdate.fail': 'Error al cancelar la actualización',
  'oifde.audioVideoResource.upload.tips': 'Sugerencia:Cargue el mismo archivo repetidamente\, cambie el nombre del archivo o cambie la carga del archivo.',
  'oifde.robotReply.displayName': 'Respuesta del robot',
  'oifde.robotReply.replyWay': 'Manera de la respuesta',
  'oifde.robotReply.replyTemplate': 'Plantilla de respuesta',
  'oifde.robotReply.selectReplyTemplate': 'Seleccionar plantilla',
  'oifde.robotReply.replyVariable': 'Variable de respuesta',
  'oifde.robotReply.replyResource': 'Recurso de respuesta',
  'oifde.robotReply.selectReplyResource': 'Seleccionar recurso',
  'oifde.robotReply.replMode': 'Modo de respuesta',
  'oifde.robotReply.replyWayBreve': 'Camino',
  'oifde.robotReply.replyResourceBreve': 'Recurso',
  'oifde.robotReply.replyVariableBreve': 'Variable',
  'oifde.robotReply.replyTemplateBreve': 'Plantilla',
  'oifde.robotReply.replModeBreve': 'Modo',
  'oifde.validation.repeats.varName': 'nombre de variable duplicado.',
  'oifde.procedure.mysqlUrlRule': 'El formato de la URL es incorrecto. Para obtener más información sobre el formato de la URL, consulte jdbc:mysql://ip:puerto/db_name?prop1=valor1&prop2=valor2',
  'oifde.procedure.oracleUrlRule': 'Formato de URL incorrecto. Para obtener más información sobre el formato de URL, consulte jdbc:oracle:thin:{\'@\'}ip:port:SID o jdbc:oracle:thin:{\'@\'}//ip:port/service',
  'oifde.procedure.gaussUrlRule': 'El formato de la URL es incorrecto. Para obtener más información sobre el formato de la URL, consulte jdbc:zenith:{\'@\'}ip:port[?key=value[&key=value]..]',
  'oifde.chatBotTest.testCaseGroupName.maxLen': 'El nombre del grupo de mayúsculas y minúsculas no puede exceder de 128 caracteres.',
  'oifde.chatBotTest.testCaseName.maxLen': 'El nombre de mayúsculas y minúsculas no puede superar los 128 caracteres.',
  'oifde.chatBotTest.createCase.maxLen': 'El número de casos de prueba seleccionados no puede exceder de 100.',
  'oifde.procedure.selectProcedure': 'Seleccionar un procedimiento Almacenado',
  'oifde.procedure.resultProcess': 'Procesamiento de resultados',
  'oifde.robotReply.title.chooseTemplate': 'Seleccionar plantilla de respuesta',
  'oifde.robotReply.title.chooseResource': 'Seleccionar recurso de respuesta',
  'oifde.element.varAssign': 'Asignación de variables',
  'oifde.promptType.title': 'Tipo de solicitud',
  'oifde.interfaceInvoke.header': 'Encabezado del mensaje de interfaz',
  'oifde.interfaceInvoke.body': 'Parámetro de entrada de interfaz',
  'oifde.interfaceInvoke.queryParam': 'Parámetro de consulta de interfaz',
  'oifde.interfaceInvoke.method.return': 'Valor de retorno del método',
  'oifde.unit.millisecond': 'milisegundos',
  'oifde.title.transData': 'Obtención de datos asociados',
  'oifde.robotReply.title.addResource': 'Agregar recurso de respuesta',
  'oifde.mxgraph.saveDraft': 'Guardar borrador',
  'ccivr.nms.conditionService.expression.placeholder':'Introduzca una expresión de condición',
  'oifde.diagram.error.basicEnd.length': 'No puede superar los 4096 dígitos.',
  'oifde.tableData.template': 'Descargar plantilla de datos',
  'oifde.tableData.download.template': 'Descargar plantilla',
  'oifde.tableData.import.max': 'Se puede importar un máximo de 10.000 registros de datos.',
  'oifde.tableData.import.tableName': 'Nombre de la tabla de datos',
  'oifde.tableData.diagram.elementName': 'Tabla de datos',
  'oifde.tableData.diagram.searchCriteria': 'Criterios de búsqueda',
  'oifde.tableData.diagram.searchPage': 'Página de búsqueda',
  'oifde.tableData.diagram.searchResult': 'Resultado de la búsqueda',
  'oifde.tableData.diagram.returnRecords': 'Devolución de registros',
  'oifde.tableData.diagram.total': 'En total',
  'oifde.procedure.db.dbName': 'Nombre de la base de datos',
  'oifde.procedure.procedureDbName': 'Nombre de la base de datos',
  'oifde.procedure.dbNameWarning': 'El nombre de la base de datos solo puede contener letras\, dígitos, caracteres chinos\, espacios\, guiones bajos (_) y comillas simples.',
  'oifde.procedure.dbNameError':'El nombre de la base de datos del procedimiento almacenado no puede ser una cadena vacía o contener un máximo de 64 caracteres.',
  'oifde.procedure.actions.deleteOnlyOneDb':'Error al eliminar toda la información de conexión a la base de datos.',
  'oifde.serviceInterface.actions.deleteDbInfo':'Esta operación eliminará la información de la base de datos seleccionada. ¿Está seguro de que desea continuar?',
  'oifde.procedure.actions.deleteDBFailed':'Error al eliminar la conexión de base de datos. El procedimiento almacenado {0} hace referencia a la conexión de base de datos y no se puede eliminar. Compruebe.',
  'oifde.procedure.actions.deleteDBFailed2': 'Error al eliminar la conexión a la base de datos. La conexión a la base de datos ha sido referenciada por {1} procedimientos almacenados como {0}. No se puede eliminar. Compruebe.',
  'oifde.procedure.actions.deleteFailed': 'Error al eliminar.',
  'oifde.procedure.actions.createDBFailed': 'Error al crear la base de datos porque el número de bases de datos ha alcanzado el límite superior.',
  'oifde.procedure.databaseConnection': 'Conexão de banco de dados',
  'oifde.workday.workDayName': 'Nombre del calendario de trabajo',
  'oifde.workday.workDayDesc': 'Descripción',
  'oifde.workday.workWeekDay': 'Selección de Semanas',
  'oifde.workday.servicePeriod': 'Periodo de servicio',
  'oifde.workday.specificNonServiceDay': 'Fecha especificada de no servicio',
  'oifde.workday.specificServiceDay': 'Fecha de servicio especificada',
  'oifde.workday.servicePeriodDesc': 'Período de servicio. Configure de 1 a 10 registros.',
  'oifde.workday.specificNonServiceTip': 'Si se configura una fecha no de servicio especificada, la fecha no de servicio se considera como un día no laborable con la prioridad más alta.',
  'oifde.workday.specificServiceTip': 'Si una fecha de servicio especificada configurada no está disponible en la selección de la semana, la fecha de servicio se considera un día laborable.',
  'oifde.workday.name': 'Nombre de usuario',
  'oifde.workday.time': 'Tiempo',
  'oifde.workday.date': 'Fecha',
  'oifde.workday.addWorkDayTitle': 'Añadir calendario de trabajo',
  'oifde.workday.editWorkDayTitle': 'Modificar calendario de trabajo',
  'oifde.workday.required': 'Obligatorio',
  'oifde.workday.updateTime': 'Modificación',
  'oifde.workday.operation': 'Operación',
  'oifde.workday.actions.edit': 'Editar',
  'oifde.workday.actions.delete': 'Eliminar',
  'oifde.workday.actions.prompt': 'Aviso',
  'oifde.workday.actions.confirm': 'Sí',
  'oifde.workday.actions.cancel': 'No',
  'oifde.workday.actions.ok': 'Aceptar',
  'oifde.workday.actions.deletePrompt': 'Esta operación eliminará permanentemente el calendario de trabajo. ¿Estás seguro de que quieres continuar?',
  'oifde.workday.actions.cancelDelete': 'Eliminación cancelada.',
  'oifde.workday.actions.deleteSuccess': 'Eliminación exitosa.',
  'oifde.workday.actions.updateSuccess': 'Modificación exitosa.',
  'oifde.workday.actions.createSuccess': 'Creación exitosa.',
  'oifde.workday.actions.missWorkDayName': 'El nombre del calendario de trabajo no puede estar vacío.',
  'oifde.workday.actions.missWorkWeekDay': 'La selección de la semana no puede estar vacía.',
  'oifde.workday.actions.minServicePeriod': 'Se debe configurar al menos un registro para el período de servicio.',
  'oifde.workday.actions.missPeriodName': 'El nombre del período de servicio no puede estar vacío.',
  'oifde.workday.actions.missPeriodTime': 'Período de servicio El tiempo no puede estar vacío.',
  'oifde.workday.actions.missNonServiceName': 'El nombre de la fecha de no servicio especificada no puede estar vacío.',
  'oifde.workday.actions.missNonServiceDate': 'La fecha de no servicio especificada no puede estar vacía.',
  'oifde.workday.actions.missServiceName': 'El nombre de la fecha de servicio especificada no puede estar vacío.',
  'oifde.workday.actions.missServiceDate': 'La fecha de servicio especificada no puede estar vacía.',
  'oifde.workday.actions.nameError': 'Todos los nombres están permitidos solo caracteres chinos, letras inglesas, dígitos, espacios, guiones bajos (_), guiones (-), y comillas simples (\').',
  'oifde.workday.tip.overServicePeriod': 'El número de registros de periodo de servicio no puede exceder de 10.',
  'oifde.workday.tip.overNonServiceDate': 'El número de registros de fecha no de servicio especificada no puede exceder de 50.',
  'oifde.workday.tip.overServiceDate': 'El número de registros de fecha de servicio especificada no puede exceder de 50.',
  'oifde.workday.tip.overWorkDayCount': 'El número de registros del calendario de trabajo no puede exceder de 1000.',
  'oifde.tableData.diagram.addCriteria': 'Añadir grupo',
  'oifde.tableData.diagram.logic': 'lógica',
  'oifde.diagram.message.recoverData': 'Restaurar datos',
  'oifde.diagram.message.recoverDataDesc': '¿Desea renderizar el lienzo con los últimos datos de proceso guardados temporalmente?',
  'oifde.button.openVariable': 'Variable ouverte >',
  'oifde.button.closeVariable': 'Variable de cierre <',
  'oifde.varGlobal.builtIn': 'globales(incorporadas)',
  'oifde.varGlobal.custom': 'globales',
  'oifde.route.workDay': 'Calendario de trabajo',
  'oifde.dataTable.tableName': 'Nombre de la tabla',
  'oifde.dataTable.tableId': 'ID de tabla',
  'oifde.dataTable.tableField': 'Campos de tabla',
  'oifde.dataTable.fieldId': 'ID de campo',
  'oifde.dataTable.fieldName': 'Nombre del campo',
  'oifde.dataTable.fieldType': 'Tipo de campo',
  'oifde.dataTable.fieldLength': 'Longitud del campo',
  'oifde.dataTable.defaultValue': 'Valor predeterminado',
  'oifde.dataTable.deleteTips': 'Esta operación eliminará permanentemente la tabla de datos y los datos de la tabla de datos. ¿Está seguro de que desea continuar?',
  'oifde.dataTable.deleteBatchTips': 'No se ha seleccionado ninguna tabla de datos que se va a eliminar',
  'oifde.dataTable.editDataTable': 'Editar tabla de datos',
  'oifde.dataTable.addDataTable': 'Adición de una tabla de datos',
  'oifde.dataTable.editDataTableField': 'Editar campos de tabla',
  'oifde.dataTable.addDataTableField': 'Agregar un campo de tabla',
  'oifde.dataTable.validation.tableId': 'Solo se permiten letras, dígitos y guiones bajos (_). No puede comenzar con un dígito',
  'oifde.dataTable.validation.presetField': 'ID, TENANTID, CREATETIME y MODIFYTIME son campos preestablecidos que no distinguen entre mayúsculas y minúsculas',
  'oifde.dataTable.fieldsMaxSize': 'El número de campos de tabla no puede exceder de 20',
  'oifde.dataTable.fieldsNotEmpty': 'El campo de tabla no puede estar vacío',
  'oifde.dataTable.exceedMaxSize': 'El número de tablas de datos no puede exceder de 40',
  'oifde.dataTable.saveAndInsert': 'Guardar y agregar datos',
  'oifde.service.table.dialog.title': 'Datos',
  'oifde.service.table.dialog.selectField': 'Seleccionar campo',
  'oifde.service.table.dialog.inputValue': 'Valor de campo de entrada',
  'oifde.service.table.dialog.addRecord': 'Añadir datos',
  'oifde.service.table.dialog.editRecord': 'Editar datos',
  'oifde.service.table.dialog.integer': 'Solo se permiten números enteros.',
  'oifde.service.table.dialog.string': 'La longitud máxima de un valor del tipo de cadena es 10000.',
  'oifde.service.table.dialog.oneday': 'Último 1 día',
  'oifde.service.table.dialog.threeday': 'Últimos 3 días',
  'oifde.service.table.dialog.sevenday': 'Últimos 7 días',
  'oifde.service.table.dialog.custom': 'Personalizado',
  'oifde.service.table.dialog.batchdelete': 'Confirmar eliminación por lotes',
  'oifde.service.table.dialog.delete': 'Confirmar eliminación',
  'oifde.dataTable.criteria': 'Criterios',
  'oifde.dataTable.diagram.fieldName': 'Nombre del campo',
  'oifde.dataTable.diagram.fieldValue': 'Valor de campo',
  'oifde.service.table.import.max.data': 'El número total de datos importados supera los 10000.',
  'oifde.service.table.import.file.empty': 'El contenido del archivo leído está vacío.',
  'oifde.service.table.import.file.read': 'No se pudo analizar el archivo.',
  'oifde.service.table.import.excute.max': 'Ya existe una tarea de importación en ejecución. Espere hasta que se complete la última importación.',
  'oifde.service.table.import.field.error': 'El campo de la tabla no existe.',
  'oifde.service.table.import.string.max': 'La longitud total de los valores del tipo de cadena supera los 10000.',
  'oifde.service.table.import.fieldType.error': 'El tipo de campo y el valor no coinciden.',
  'oifde.title.chooseGreyRule': 'Elegir regla gris',
  'oifde.conditionService.param.tips': 'Cuando una expresión de condición contiene una constante de cadena de caracteres, se deben agregar comillas (") para su uso normal',
  'oifde.common.btn.batch.operation': 'Operación lotes',
  'oifde.char.invalid': 'Caracteres no válidos',
  'oifde.service.table.dialog.nodatedelete': 'No se han seleccionado datos que se van a eliminar',
  'oifde.service.table.dialog.exceedMaxSize': 'Se puede añadir un máximo de 10000 registros',
  'oifde.validate.validChar': 'Rango de caracteres permitido: ',
  'oifde.variable.rightList.tips': 'Las variables mostradas a la derecha se pueden arrastrar al cuadro de texto de la variable.',
  'oifde.service.table.import.field.length.error': 'La longitud del campo excede el límite superior.',
  'oifde.screenfull.cancel': 'Salir de pantalla completa',
  'oifde.common.validation.flowMaxElementsRange': 'El número máximo de elementos de diagrama admitidos por el proceso IVR es un entero positivo. El número de elementos de diagrama no puede ser inferior a 1 ni superior a 200',
  'oifde.variable.sys.callMediaType': 'Tipo de medio de llamada',
  'oifde.variable.sys.systemDate': 'Fecha del sistema(YYYYMMDD)',
  'oifde.variable.sys.systemMonth': 'Mes del año del sistema(YYYYMM)',
  'oifde.variable.sys.currentTime': 'Hora actual del sistema(yyyyMMddHHmmss)',
  'oifde.variable.sys.systemTime': 'Hora actual del sistema(HHmmss)',
  'oifde.variable.sys.timeStamp': 'Marca de tiempo, que es una cadena de caracteres.01 de enero de 1970 00:00:00 GMT(08:00:00, 1 de enero de 1970, hora de Beijing)Número total de segundos desde el momento actual.',
  'oifde.variable.sys.createTime': 'Hora de inicio de la llamada',
  'oifde.variable.sys.callingNumber': 'Número de cliente, que indica el número de llamada para una llamada entrante al centro de contacto en la nube y el número llamado para una llamada saliente al centro de contacto en la nube.Tipo de cadena.El número se transfiere desde el proceso IVR.',
  'oifde.variable.sys.calledNumber': 'Código de acceso al proceso del sistema.Tipo de cadena.Este número es un código de acceso al flujo que se transfiere desde el flujo IVR. Puede iniciar sesión en la consola de configuración WAS de la plataforma CTI para ver el número.',
  'oifde.variable.sys.realCalledNumber': 'Código de acceso, que es el código de acceso configurado en la configuración de la parte llamada en el proceso, es decir, el número de línea directa marcado en el proceso.',
  'oifde.variable.sys.callId': 'ID de sesión ODFS, que es una cadena de caracteres.IVRREQUEST.call_id es transferido por el proceso IVR.',
  'oifde.variable.sys.ctiCallid': 'ID de llamada de la plataforma CTI transferida por el proceso IVR, es decir, el parámetro IVRREQUEST.cti_callid en la solicitud IVR.',
  'oifde.variable.sys.tenantId': 'ID de inquilino actual',
  'oifde.variable.sys.language': 'Idioma del cliente actual',
  'oifde.variable.ivrRequest.ctiCallid': 'Indica el ID de llamada de la plataforma CTI que se transfiere desde el IVR de Huawei. En otros escenarios, este parámetro se deja vacío.No más de 32 dígitos.',
  'oifde.variable.ivrRequest.input': 'Resultado de la interacción del diálogo.',
  'oifde.variable.ivrRequest.IACmd': 'Complete los campos dedicados para la coincidencia inteligente y el ticket inteligente.',
  'oifde.variable.ivrRequest.TextSource': 'Fuente del texto de la solicitud.',
  'oifde.variable.ivrRequest.transinData': 'Parámetros de datos asociados al canal acordados entre la parte de acceso y el OIAP, por ejemplo, parámetros de paquetes de datos transferidos por el IVR.',
  'oifde.variable.ivrRequest.beginPlay': 'Hora de inicio de la reproducción del anuncio.',
  'oifde.variable.ivrRequest.endPlay': 'Hora de finalización de la reproducción del anuncio.',
  'oifde.variable.ivrRequest.callId': 'ID de llamada único, que es el mismo que el valor de userid. Este parámetro es utilizado por el proceso.',
  'oifde.variable.ivrRequest.interIdx': 'Número de interacciones con el usuario, que se registra en el ODFS.',
  'oifde.variable.ivrRequest.feedback': 'Satisfacción de la retroalimentación: 1 (satisfacción)0 (insatisfecho).Tipo entero.',
  'oifde.variable.ivrRequest.feedbackContent': 'Motivo de la insatisfacción de la retroalimentación (vacío si la retroalimentación es satisfactoria).',
  'oifde.variable.ivrRequest.channelType': 'Tipo de canal de acceso del cliente. Por ejemplo, WEB, INSTAGRAM, TELEGRAM, WECHAT, FACEBOOK, TWITTER, EMAIL, LINE, WHATSAPP, 5G, SMS',
  'oifde.variable.ivrRequest.ieValue': 'Título del correo electrónico recibido durante el acceso a través del canal de correo electrónico',
  'oifde.variable.toc.ChatBot': 'Parámetros de ranura configurados en la intención',
  'oifde.variable.toc.ChatBotIntentCode': 'Código de intención devuelto después de la coincidencia de reconocimiento de voz',
  'oifde.variable.toc.ChatBotRespContent': 'Respuesta devuelta por la intención coincidente del reconocimiento de voz, incluida la respuesta configurada en la ranura y la respuesta.',
  'oifde.variable.toc.ChatBotScenarioName': 'Nombre de la plantilla de intención coincidente después de la ejecución del reconocimiento de voz.',
  'oifde.variable.toc.ChatBotResponse': 'Identificar todo el contenido de la interfaz',
  'oifde.datatable.open.type': 'Abra el archivo en formato UTF-8 después de la exportación.',
  'oifde.datatable.dateValue.tip': 'Marca de tiempo, en milisegundos. Introduzca un entero largo o un valor de carácter o una variable.',
  'oifde.datatable.intList.tip': 'Ejemplo: [1, 2, 3].',
  'oifde.datatable.stringList.tip': 'Ejemplo: ["a"",b"",c"].',
  'oifde.expression.expr_number_varOrValue': 'Introduzca un valor o variable del tipo numérico.',
  'oifde.expression.expr_list_varOrValue': 'Introduzca un valor o variable del tipo de lista.',
  'oifde.service.table.import.index': 'Línea {0}',
  'oifde.flow.rule.noRule': 'Error al encontrar la regla gris. Compruebe si se elimina la regla.',
  'oifde.cell.viewRefrenceInfo': 'Información de referencia',
  'oifde.cell.viewRefrenceInfo.flow': 'Referencia de proceso',
  'oifde.cell.viewRefrenceInfo.complexcell': 'Referencia de proceso',
  'oifde.accessCode.select.max': 'Se han seleccionado {0} registros. Se puede seleccionar un máximo de {1} registros.',
  'oifde.mxgraph.deleteDraft': 'Borrar borrador',
  'oifde.mxgraph.deleteDraft.confirm': '¿Está seguro de que desea eliminar el borrador?',
  'oifde.mxgraph.deleteDraft.noDraftExist': 'No existen datos de borrador',
  'oifde.resource.needApproval': 'Los recursos de respuesta se crean correctamente y solo se pueden usar después de haber sido aprobados por el administrador del sistema.',
  'oifde.common.validation.accessCode.description': 'No se permiten saltos de línea ni los siguientes caracteres especiales: "[]\'&()=;<>,',
  'oifde.tableData.import.tableNameBreve': 'Nombre de tabla',
  'oifde.flow.actions.publish.existDraft': 'El proceso actual tiene datos borradores. Edite y suelte los datos o libere los datos sin borradores.',
  'oifde.export.finish.success': 'Se ha exportado correctamente.',
  'oifde.input.tips.varAndConst': 'Introduzca una variable o constante. Use comillas antes y después de la cadena.',
  'oifde.export.keyPressRobot.title': 'Exportación de registros IVR comunes',
  'oifde.export.password': 'Contraseña de compresión',
  'oifde.export.password.placeholder': 'Introduzca la contraseña de compresión',
  'oifde.export.password.error.tips': 'Introduzca una contraseña de 8 a 12 caracteres que contenga dígitos, letras y caracteres especiales excepto /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.password.error.message': 'El valor debe contener dígitos, letras y caracteres especiales excepto /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.task.status': 'Estado de la tarea',
  'oifde.export.task.fail': 'Error en la exportación',
  'oifde.export.businessInterface.title': 'Exportación de registros de interfaz personalizados',
  'oifde.export.variable.title': 'Exportación de registros de variables personalizados',
  'oifde.export.complexcell.title': 'Exportar registros de elementos de diagrama compuesto',
  'oifde.export.accessCode.title': 'Exportación de registros de robots inteligentes',
  'oifde.export.intelligentIVRFlow.title': 'Exportación de registros de proceso',
  'oifde.export.intelligentIVRFlow.button': 'Flujo de exportación',
  'oifde.route.2dDigitalHumanImg': 'Humano virtual',
  'oifde.route.add2dDigitalHumanImg': 'Crear humano virtual',
  'oifde.digitalHuman.outputSetting': 'Ajuste de salida',
  'oifde.digitalHuman.standingPosition': 'Posicionamiento',
  'oifde.digitalHuman.stationTemplate': 'Plantilla de posicionamiento',
  'oifde.digitalHuman.preciseAdjustment': 'Ajuste con precisión',
  'oifde.digitalHuman.left': 'Izquierdo',
  'oifde.digitalHuman.center': 'Centro',
  'oifde.digitalHuman.right': 'Correcto',
  'oifde.digitalHuman.imageProportion': 'Relación de imagen',
  'oifde.exportdata.message.overData': 'El número de registros exportados supera los 10000. Seleccione los criterios de búsqueda de nuevo.',
  'oifde.flow.title.publishFlow': 'Flujo de liberación',
  'oifde.flow.title.upgrade': 'Flujo de actualización',
  'oifde.flow.action.editInfo': 'Editar información',
  'oifde.flow.title.editInfo': 'Edición de información básica de flujo',
  'oifde.robot.title.editInfo': 'Edición de información básica del Chatbot',
  'oifde.input.placeholder.searchName': 'Nombre de la búsqueda',
  'oifde.avatar.tenantId':'ID del inquilino',
  'oifde.avatar.tenantSpace':'Espacio inquilino',
  'oifde.avatar.avatarName':'Nombre del rol',
  'oifde.avatar.avatarCode':'Código de función',
  'oifde.avatar.headerImageUrl':'Image',
  'oifde.avatar.serverAddress':'Dirección del servidor',
  'oifde.avatar.avatarAppKey':'Nombre de usuario del servidor/AK',
  'oifde.avatar.create':'Crear servicio humano virtual',
  'oifde.avatar.update':'Editar servicio humano virtual',
  'oifde.avatar.headerImage': 'Foto de perfil',
  'oifde.avatar.poseImage': 'Pose imagen',
  'oifde.avatar.actionList':'Lista de acciones',
  'oifde.avatar.form.message': 'Información humana virtual 2D',
  'oifde.avatar.service.message':'Información del servidor',
  'oifde.avatar.serverPort':'Puerto del servidor',
  'oifde.avatar.userName':'Nombre de usuario del servidor',
  'oifde.avatar.pwd':'Contraseña del servidor',
  'oifde.avatar.checkPwd':'Confirmar contraseña del servidor',
  'oifde.avatar.resolution': 'Resolución',
  'oifde.avatar.imgRule': 'Solo se admiten los formatos JPG, PNG y JPEG. El tamaño del archivo no puede exceder los 2 MB.',
  'oifde.avatar.action_1': 'Saludos',
  'oifde.avatar.action_2': 'Manos adelante',
  'oifde.avatar.action_3': 'Atención',
  'oifde.avatar.action_4': 'Manos abrochadas',
  'oifde.avatar.action_5': 'Extiende tu mano izquierda',
  'oifde.avatar.action_6': 'Extiende tu mano derecha',
  'oifde.avatar.selectTitle': 'Seleccione una imagen de avatar',
  'oifde.digitalHuman.imgLimit': 'Solo se admiten archivos de imagen JPG, PNG y JPEG. El tamaño del archivo no puede superar los 2 MB.',
  'oifde.digitalHuman.imageProportion0-5': '0.5',
  'oifde.digitalHuman.imageProportion0-75': '0.75',
  'oifde.digitalHuman.imageProportion1': '1',
  'oifde.digitalHuman.canvasNoSupport': 'El navegador actual no soporta canvas. Actualice o cambie el navegador primero.',
  'oifde.digitalHuman.noRoleData': 'No hay datos de rol. Póngase en contacto con el personal de operación para agregar los datos de rol.',
  'oifde.digitalHuman.sizeError': 'Resolución de imagen incorrecta. Sube una imagen de fondo en el formato de {0} x {1}.',
  'oifde.title.add.complexCell': 'Nuevo elemento compuesto',
  'oifde.title.add.complexCellCatalog': 'Creación de un catálogo de elementos compuestos',
  'oifde.title.edit.complexCellCatalog': 'Edición de un catálogo de elementos compuestos',
  'oifde.title.add.rule': 'Nueva regla',
  'oifde.title.edit.rule': 'Editar regla',
  'oifde.ivrJourney.pleaseSelectData': 'Seleccione los datos',
  'oifde.digitalHuman.needRole': 'Seleccione un rol.',
  'oifde.digitalHuman.actions.deletePrompt': 'Esta operación eliminará permanentemente el humano virtual. ¿Continúa?',
  'oifde.avatar.validation.ttsSoundSpeed': 'Introduzca un decimal entre 0.5 y 1.5.',
  'oifde.avatar.validation.scale': 'Introduzca un decimal entre 0.5 y 1.0.',
  'oifde.avatar.pleaseSelectData': 'Los datos modificados no se guardarán después del cierre. ¿Estás seguro de que quieres continuar?',
  'oifde.digitalHuman.actions.savePrompt': 'Esta operación actualizará el humano virtual. ¿Continúa?',
  'oifde.digitalHuman.coordinate': 'Coordinar',
  'oifde.digitalHuman.dimensions': 'Dimensiones',
  'oifde.digitalHuman.imgTypeError': 'Tipo incorrecto. Solo se admiten los formatos JPG, PNG y JPEG.',
  'oifde.expression.expr_var_flow': 'Introduzca una variable de proceso que comience por FLOW',
  'oifde.expression.expr_string_or_var': 'Introduzca un valor del tipo de variable o cadena de caracteres. Utilice comillas dobles antes y después de la cadena.',
  'oifde.common.actions.deleteConfig': 'Esta operación eliminará permanentemente la configuración. ¿Estás seguro de que quieres continuar?',
  'oifde.expression.numberCharStartWith0': 'Utilice comillas dobles antes y después de una cadena numérica que comience por 0. Por ejemplo, 05 debe escribirse como "05". De lo contrario, el valor se considera como 5.',
}
