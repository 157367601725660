<template>
  <div class="reportExportNew">
    <sweet-dialog
      v-model="isExportShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      style="width: 616px"
      class="aicc-dialog-limit-height"
      @close="closeDialog"
    >
      <!-- 导出文件格式 -->
      <sweet-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="rules">
        <div class="form-item" v-show="isShowFileType">
          <div class="form-item-label" :style="{ width: labelWidth }">
            {{ $t('cms.report.field.filetype') }}
          </div>
          <aicc-select
            style="width: 150px !important"
            v-model="fileType"
            :placeholder="$t('cms.export.field.selectFileType')"
            :valueShowTooltips="true"
            :options="options"
          ></aicc-select>
        </div>
        <!-- 压缩密码 -->
        <div class="form-item password-outer">
          <div class="form-item-label" :style="{ width: labelWidth, marginTop: '-8px' }">
            {{ $t('cms.export.field.zippwd') }}
          </div>

          <sweet-form-item prop="zipPwd">
            <sweet-input
              class="password-input"
              v-model="ruleForm.zipPwd"
              :placeholder="$t('cms.report.message.inputZipPassword')"
              type="password"
              autocomplete="off"
            />
          </sweet-form-item>
        </div>
        <div class="popwindowmessageStyle" :style="{ marginLeft: popwindowmessageLeft }">
          {{ $t('cms.export.message.popwindowmessage') }}
        </div>
      </sweet-form>
      <template #footer>
        <div style="margin-top: -5px">
          <el-button @click="cancleExportShow">{{ $t('cms.indexmonitor.field.cancel') }}</el-button>
          <el-button
            style="background-color: #366bfc; color: #fff; border: none"
            @click="saveExportShow()"
            >{{ $t('aicc.ok') }}</el-button
          >
        </div>
      </template>
    </sweet-dialog>
  </div>
</template>

<script>
import i18n from '@/lang'

const $t = i18n.global.t

export default {
  name: 'reportExportNew',
  data() {
    return {
      labelWidth: '80px',
      popwindowmessageLeft: '80px',
      rules: {
        zipPwd: [
          {
            required: true,
            message: this.$t('cms.export.message.required'),
            trigger: 'blur'
          },
          {
            validator: this.checkPassword,
            message: this.$t('cms.calldetail.msg.callIdInvalid'),
            trigger: ['change', 'blur']
          }
        ]
      },
      isExportShow: false,
      isIndependent: window.localStorage.getItem('isIndependentDeployment'),
      ruleForm: {
        zipPwd: ''
      },
      fileType: '',
      options: [
        {
          value: '0',
          label: '.csv'
        },
        {
          value: '1',
          label: '.pdf'
        }
      ],
      isShowFileType: false
    }
  },
  props: {
    isFileType: {
      type: Boolean
    },
    title: {
      type: String,
      required: false,
      default: $t('cms.report.label.export')
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (JSON.stringify(this.isFileType) != '{}' && this.isFileType) {
        this.isShowFileType = true
        this.labelWidth = '108px'
        this.popwindowmessageLeft = '108px'
      }
      this.fileType = this.options[0].value
    })
  },
  methods: {
    async saveExportShow() {
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          const params = { zipPwd: this.ruleForm.zipPwd, fileType: this.fileType }
          if (this.isIndependent === 'true') {
            // op独立部署环境
            this.$emit('addExportTaskOp', params)
          } else {
            // 集成环境
            this.$emit('addExportTask', params)
          }
          this.isExportShow = false
          this.ruleForm.zipPwd = ''
        }
      })
    },
    cancleExportShow() {
      this.isExportShow = false
      this.ruleForm.zipPwd = ''
      this.$refs.ruleFormRef.resetFields()
    },
    open() {
      this.isExportShow = true
      this.$refs.ruleFormRef.resetFields()
    },
    closeDialog() {
      this.ruleForm.zipPwd = ''
      this.$refs.ruleFormRef.resetFields()
    },
    checkPassword(rule, value, callback) {
      // 校验是否输入合法
      let reg = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\W_]).{8,12}$/
      let invalid_char_reg = new RegExp('[/\\-#(){};&|<>$`\\\\!\\n\\s\\t\\r]')
      if (!reg.test(value) || invalid_char_reg.test(value)) {
        callback('')
      }
      callback()
    }
  }
}
</script>

<style lang="less" scoped>
.password-input {
  :deep(.el-input__inner) {
    color: #707070;
  }

  :deep(.el-input__inner::placeholder) {
    color: #949494;
  }
}

:deep(.el-overlay-dialog) {
  top: 20%;
}

.password-outer {
  color: darkgrey;
  margin-top: 10px;
  margin-bottom: 6px;

  :deep(.password-input) {
    width: 400px;
    height: 34px;
    border-radius: 6px;
    margin-top: 10px;
  }
}

.form-item {
  display: flex;
  align-items: center;

  &-label {
    padding-right: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 0;
    color: darkgrey;
  }
}

.popwindowmessageStyle {
  margin-top: -8px;
  line-height: 18px;
  color: darkgrey;
}
</style>
