export default {
  'oifde.index.title': 'ODFS — Online Dialog Flow Service',
  'oifde.common.lang': 'Language',
  'oifde.common.language.chinese': 'Chinese',
  'oifde.common.language.english': 'English',
  'oifde.common.time.startDate': 'Start Date',
  'oifde.common.time.endDate': 'End Date',
  'oifde.common.selectPlaceHolder': 'Enter a keyword.',
  'oifde.common.browserSupporteMsg': 'The browser does not support this function.',
  'oifde.common.validation.required': 'This field is mandatory',
  'oifde.common.validation.extendFaqRange': 'Only Chinese characters, English letters, digits, spaces, and the following special characters are allowed: ( ) [ ] {\'@\'} {\'{\'} {\'}\'} ， , 。 . {\'|\'} \' _ -',
  'oifde.common.validation.nameRange': 'Unsupported: < > ! {\'@\'} # {\'$\'} % ^ & * ? ; " ~ / \\',
  'oifde.common.validation.cellNameRange': 'Unsupported: < > {\'@\'} # {\'$\'} % ^ * ; " ~ / \\',
  'oifde.shortMessage.validation.text': 'Unsupported::`~{\'@\'}^#%&*/=+{\'{\'}{\'}\'};"<.>?[]',
  'oifde.common.validation.nameNoSpace': 'The value cannot start or end with a space.',
  'oifde.common.validation.openNameCharReg': 'Only Chinese characters, English letters, digits, spaces, underscores (_), hyphens (-), and single quotation marks (\') are allowed.',
  'oifde.common.validation.kgNameCharReg': 'Only Chinese characters, English letters, spaces, and the following special characters are allowed: _ - , \' ; . {\'@\'}',
  'oifde.common.validation.nameCharRegNotChinese': 'The value can contain only English letters, digits, underscores (_), and hyphens (-).',
  'oifde.common.validation.nameCharReg': 'The value can contain only Chinese characters, English letters, digits, underscores (_), and hyphens (-).',
  'oifde.common.validation.schemaNameCharReg': 'Only Chinese characters, English letters, digits, spaces, underscores (_), and single quotation marks (\') are allowed.',
  'oifde.common.validation.schemaRelationNameCharReg': 'Only Chinese characters, English letters, digits, spaces, and single quotation marks (\') are allowed.',
  'oifde.common.validation.charRegWithDot': 'The value can contain only Chinese characters, English letters, digits, dots (.), underscores (_), and hyphens (-).',
  'oifde.common.validation.int': 'The value must be an integer from 0 to 2147483647.',
  'oifde.common.validation.min': 'The value cannot be less than {0}.',
  'oifde.common.validation.max': 'The value cannot be greater than {0}.',
  'oifde.common.validation.collectTimeoutRule': 'The FLOW and GLOBAL variables are supported. The value can also be an integer ranging from 1 to 120. If the value does not meet the requirements, the default value 5 is used.',
  'oifde.common.validation.numberRange': 'The value range is from {0} to {1}.',
  'oifde.common.validation.sessionDTRange': 'The dialog flow timeout interval ranges from {0} to {1} seconds.',
  'oifde.common.validation.slientHelpRange': 'The time to wait for a reply after an agent is silent ranges from {0} to {1} seconds.',
  'oifde.common.validation.confidenceRange': 'The confidence threshold ranges from {0} to {1}.',
  'oifde.common.validation.flowSaveIntervalRange': 'The interval for saving IVR flow orchestration data must be a positive integer, which cannot be less than {0} minute(s) and cannot be greater than {1} minute(s)',
  'oifde.common.validation.urlValidate': 'The URL format is incorrect.',
  'oifde.common.validation.appSecretLenCheck': 'The shared key must contain 8 to 20 characters.',
  'oifde.common.validation.positiveInt': 'The value must be a positive integer.',
  'oifde.common.validation.digits': 'The value must be 0 or a positive integer',
  'oifde.common.validation.numbers': 'Please enter only digits.',
  'oifde.common.validation.nodeNameCharReg': 'The value can contain only Chinese characters, English letters, digits, underscores (_), and hyphens (-).',
  'oifde.common.validation.validateFalse': 'The value cannot contain the following special characters: ~ ` ! # {\'$\'} % ^ & * ( ) + = [ ] {\'{\'} {\'}\'} {\'|\'} \' " : < > / ?',
  'oifde.common.validation.mrcpIdValueFalse': 'Enter a positive integer ranging from 0 to 255.',
  'oifde.common.validation.flowMaxElementsRange': 'The IVR Flow nodes interval ranges from {0} to {1}.',
  'oifde.common.dialog.confirm.title': 'Confirm',
  'oifde.common.dialog.confirm.content': 'This operation will permanently delete the data. Are you sure you want to continue?',
  'oifde.common.dialog.confirm.buttonSure': 'Yes',
  'oifde.common.dialog.confirm.buttonCancel': 'No',
  'oifde.common.dialog.confirm.successInfo': 'The data is deleted successfully!',
  'oifde.common.dialog.confirm.cancelInfo': 'The deletion is canceled.',
  'oifde.common.dialog.confirm.operationSucc': 'The operation is performed successfully!',
  'oifde.common.dialog.confirm.operation_failed': 'Failed to perform the operation!',
  'oifde.common.actions.add': 'Add',
  'oifde.common.actions.edit': 'Edit',
  'oifde.common.actions.delete': 'Delete',
  'oifde.common.actions.download': 'Download',
  'oifde.common.help': 'View help information.',
  'oifde.common.select': '-Select-',
  'oifde.common.detail': 'Details',
    'oifde.common.noData': 'No Data',
  'oifde.serviceInterface.oifdeInterface': 'Intelligent Robot Interface',
  'oifde.serviceInterface.ccivrInterface': 'Common IVR Interface',
  'oifde.serviceInterface.selfDefinedInterface': 'Self-defined Interface',
  'oifde.serviceInterface.innerInterface': 'Inner Interface',
  'oifde.serviceInterface.jsonValidateSuccess': 'The format is correct.',
  'oifde.serviceInterface.jsonChek': 'Check JSON',
  'oifde.serviceInterface.interfaceCert': 'Interface Certificate',
  'oifde.serviceInterface.certCode': 'Code',
  'oifde.serviceInterface.certScene': 'Scenario',
  'oifde.serviceInterface.expireTime': 'Expired',
  'oifde.serviceInterface.certUpdateTime': 'Updated',
  'oifde.serviceInterface.jsonValidate': 'The message body does not comply with the standard JSON format.',
  'oifde.serviceInterface.safeWarning': 'An insecure network protocol is specified for the interface.',
  'oifde.serviceInterface.whiteListCheckWarning': 'The URL (IP address and port number) is not in the trustlist and can be used only after you contact the system administrator to add it to the trustlist. ',
  'oifde.serviceInterface.interfaceName': 'Interface Name',
  'oifde.serviceInterface.bizCode': 'Business Code',
  'oifde.serviceInterface.description': 'Description',
  'oifde.serviceInterface.updatetime': 'Updated',
  'oifde.serviceInterface.operation': 'Operation',
  'oifde.serviceInterface.addServiceInterface': 'Add Business Interface Configuration',
  'oifde.serviceInterface.modifyServiceInterface': 'Edit Business Interface Configuration',
  'oifde.serviceInterface.serviceInterfaceDetails': 'Business Interface Configuration Details',
  'oifde.serviceInterface.innerInterfaceDetails': 'Inner Interface Details',
  'oifde.serviceInterface.baseInfo': 'Basic Information',
  'oifde.serviceInterface.reqURL': 'Request URL',
  'oifde.serviceInterface.signBody': 'Sign',
  'oifde.serviceInterface.signBodyDetail.no': 'No',
  'oifde.serviceInterface.signBodyDetail.yes': 'Yes',
  'oifde.serviceInterface.signDescription': 'Whether to verify the signature for a third party when the signature is transferred to the third party as an interface parameter.',
  'oifde.serviceInterface.appSecret': 'Shared Key',
  'oifde.serviceInterface.inputAppSecret': 'Enter the shared key.',
  'oifde.serviceInterface.busiCode': 'Interface Code',
  'oifde.serviceInterface.interfaceType': 'Request Method',
  'oifde.serviceInterface.required': 'This field is mandatory.',
  'oifde.serviceInterface.query_param': 'Query Parameter',
  'oifde.serviceInterface.pathParamValue': 'Value',
  'oifde.serviceInterface.outParamPath': 'Path',
  'oifde.serviceInterface.in_param': 'Input Parameter',
  'oifde.serviceInterface.out_param': 'Output Parameter',
  'oifde.serviceInterface.name': 'Name',
  'oifde.serviceInterface.dataType': 'Data Type',
  'oifde.serviceInterface.encryptFlag': 'Encrypted',
  'oifde.serviceInterface.dataTypes.INT': 'Integer',
  'oifde.serviceInterface.dataTypes.STRING': 'Character',
  'oifde.serviceInterface.dataTypes.FLOAT': 'Floating-point number',
  'oifde.serviceInterface.dataTypes.LONG': 'Long integer',
  'oifde.serviceInterface.dataTypes.LIST': 'List',
  'oifde.serviceInterface.dataTypes.OBJECT': 'Object',
  'oifde.serviceInterface.msgHeaders': 'Message Header',
  'oifde.serviceInterface.msgBody': 'Message Body',
  'oifde.serviceInterface.headerKey': 'Key',
  'oifde.serviceInterface.headerValue': 'Value',
  'oifde.serviceInterface.deleteConfirm': 'This operation will permanently delete the business interface. Are you sure you want to continue?',
  'oifde.serviceInterface.miss_ifs_name': 'The following mandatory field is empty: Basic Information > Interface Name.',
  'oifde.serviceInterface.prompt': 'Confirm',
  'oifde.serviceInterface.ok': 'OK',
  'oifde.serviceInterface.miss_ifs_request_url': 'The following mandatory field is empty: Basic Information > Request URL.',
  'oifde.serviceInterface.miss_ifs_in_parameter_name': 'The following mandatory field is empty: Input Parameter > Name.',
  'oifde.serviceInterface.miss_ifs_out_parameter_name': 'The following mandatory field is empty: Output Parameter > Name.',
  'oifde.serviceInterface.miss_ifs_out_parameter_type': 'The following mandatory field is empty: Output Parameter > Data Type.',
  'oifde.serviceInterface.miss_ifs_header_key': 'The following mandatory field is empty: Message Header > Key.',
  'oifde.serviceInterface.miss_ifs_msgBody': 'The following mandatory field is empty: Message Body.',
  'oifde.serviceInterface.auto_msgBody': 'Automatic Message Body',
  'oifde.serviceInterface.manual_msgBody': 'Manual Message Body',
  'oifde.serviceInterface.promptMessage': 'Enter a message body.',
  'oifde.serviceInterface.actions.cancelSuccess': 'Saving canceled!',
  'oifde.serviceInterface.actions.auto_savePrompt': 'This operation will save the 「automatic message body」 as the service interface configuration. Are you sure you want to continue?',
  'oifde.serviceInterface.actions.urlNotInWhitelist': 'The URL (IP address and port number) is not in the trustlist and can be used only after you contact the system administrator to add it to the trustlist. Continue?',
  'oifde.serviceInterface.actions.manual_savePrompt': 'This operation will save the 「manual message body」 as the service interface configuration. Are you sure you want to continue?',
  'oifde.serviceInterface.actions.prompt': 'Confirm',
  'oifde.serviceInterface.actions.confirm': 'Yes',
  'oifde.serviceInterface.actions.cancel': 'No',
  'oifde.qarecord.interface.name': 'Record Q&A',
  'oifde.qarecord.interface.desc': 'Records the questionnaire ID, question title, user reply intention, and user original reply.',
  'oifde.apiToken.interface.name':'Obtain the APIFabric token',
  'oifde.apiToken.interface.desc':'Interface used to obtain the API Fabric token',
  'oifde.apiToken.applyType.desc': 'Token prefix',
  'oifde.apiToken.accessToken.desc': 'Content of the token',
  'oifde.apiToken.appKey.desc': 'App Key',
  'oifde.apiToken.appSecret.desc': 'App secret',
  'oifde.qarecord.inputparam.seriesId': 'Question and Answer ID',
  'oifde.qarecord.inputparam.questionTitle': 'Question Title',
  'oifde.qarecord.inputparam.questionType': 'Question Type',
  'oifde.qarecord.inputparam.answerIntent': 'Answer Intent',
  'oifde.qarecord.inputparam.originalAnswer': 'Original answer',
  'oifde.sysMgr.sysCfg': 'System Settings',
  'oifde.sysMgr.apiKeyCfg': 'API Password Settings',
  'oifde.sysMgr.configuration': 'Set',
  'oifde.sysMgr.apiKey': 'API Password',
  'oifde.sysMgr.apiKeyComfirm': 'Confirm API Password',
  'oifde.sysMgr.apiKeyNotSame': 'The API passwords do not match.',
  'oifde.sysMgr.inputKey': 'Enter an API password.',
  'oifde.sysMgr.cancel': 'Cancel',
  'oifde.sysMgr.save': 'Save',
  'oifde.sysMgr.apiRuleRange': 'The password must contain at least two types of the following: uppercase letters, lowercase letters, digits, and special characters ~ ` ! {\'@\'} # {\'$\'} % ^ * ( ) - + _ = {\'|\'} , . / < > ? ; \' : " [ ] {\'{\'} {\'}\'} &.',
  'oifde.sysMgr.success_set_key': 'The API password is set successfully!',
  'oifde.sysMgr.requiredAPIKey': 'Enter an API password.',
  'oifde.sysMgr.APIKeyLenCheck': 'The API password must contain 16 to 64 characters.',
  'oifde.sysMgr.success_set_rpa': 'The RPA ACCESS INFO is set successfully!',
  'oifde.sysMgr.requiredRpaUserName': 'The RPA User Account is required.',
  'oifde.sysMgr.requiredRpaSecretKey': 'The RPA Secret Key is required.',
  'oifde.sysMgr.rpaUserNameLenCheck': 'The RPA User Account cannot contain more than 64 characters.',
  'oifde.sysMgr.rpaSecretKeyLenCheck': 'The RPA Secret Key cannot contain more than 128 characters.',
  'oifde.sysMgr.inputRpaUserName': 'Enter RPA User Name.',
  'oifde.sysMgr.inputRpaSecretKey': 'Enter RPA Secret Key.',
  'oifde.sysMgr.dataClean.setTime': 'Sets the data clearing duration.',
  'oifde.sysMgr.dataClean.serviceName': 'Parameter Name',
  'oifde.sysMgr.dataClean.paramValue': 'Value',
  'oifde.sysMgr.dataClean.description': 'Description',
  'oifde.sysMgr.dataClean.descriptionContent': 'Interval at which data cleanup is performed.',
  'oifde.sysMgr.dataClean.tcaContent': 'List of domains bound to text analysis.',
  'oifde.sysMgr.dataClean.editTcaFlag': 'Editing a List of Domains Bound to Text Analytics',
  'oifde.sysMgr.dataClean.operation': 'Operation',
  'oifde.sysMgr.dataClean.editTime': 'Modify Data Cleanup Interval',
  'oifde.sysMgr.dataClean.day': 'days',
  'oifde.sysMgr.dataClean.hour': 'h',
  'oifde.sysMgr.dataClean.minute': 'min',
  'oifde.sysMgr.dataClean.flagDescription': 'Whether to enable recording in a intelligent IVR call flow.',
  'oifde.sysMgr.dataClean.editFlag': 'Modify Recording in Intelligent IVR Call Flow',
  'oifde.sysMgr.dataClean.recordThirdApi': 'Whether to enable third-party interface invocation logging.',
  'oifde.sysMgr.dataClean.editInterfaceFlag': 'Edit Third-Party Interface Invocation Logging',
  'oifde.sysMgr.dataClean.ccvirrecordFlag': 'Whether to enable recording in common IVR call flows.',
  'oifde.sysMgr.dataClean.editCcvirrecordFlag': 'Edit Recording in Common IVR Call Flows',
  'oifde.sysMgr.dataClean.open': 'Enable',
  'oifde.sysMgr.dataClean.close': 'Disable',
  'oifde.sysMgr.dataClean.record': 'Record',
  'oifde.sysMgr.dataClean.noRecord': 'Do not record',
  'oifde.sysMgr.dataClean.second': 'seconds',
  'oifde.sysMgr.dataClean.reportFlagDesc': 'Whether to record test cases to a report.',
  'oifde.sysMgr.dataClean.reportFlagTitle': 'Modify Test Case Recording',
  'oifde.sysMgr.dataClean.sessionDTDesc': 'Timeout interval for dialog flows.',
  'oifde.sysMgr.dataClean.editSessionDT': 'Modify Dialog Flow Timeout Interval',
  'oifde.sysMgr.dataClean.slientHelpDesc': 'Time to wait for a reply after an agent is silent.',
  'oifde.sysMgr.dataClean.confidenceDesc': 'Confidence threshold for a silent agent to recognize single help-seeking from a chatbot.',
  'oifde.sysMgr.dataClean.traceLogAutoCloseDesc': 'Automatic disabling time for tracking.',
  'oifde.sysMgr.dataClean.editSlientHelp': 'Modify Time to Wait for Reply After Agent Silence',
  'oifde.sysMgr.dataClean.confidence': 'Modify Confidence',
  'oifde.sysMgr.dataClean.traceLogAutoClose': 'Edit Automatic Disabling Time for Tracking',
  'oifde.sysMgr.dataClean.flowIvrSaveFlagDesc': 'Indicates whether to enable the scheduled saving function for IVR process orchestration.',
  'oifde.sysMgr.dataClean.editFlowSaveFlag': 'Editing the Scheduled Saving Flag of IVR Process Orchestration',
  'oifde.sysMgr.dataClean.flowIvrSaveIntervalDesc': 'Interval for saving IVR flow orchestration data.',
  'oifde.sysMgr.dataClean.editFlowSaveInterval': 'Editing the Interval for Saving IVR Flow Orchestration Data',
  'oifde.sysMgr.dataClean.flowMaxElementsDesc': 'Max. diagram elements supported by an IVR flow.',
  'oifde.sysMgr.dataClean.editFlowMaxElements': 'Edit Max. Diagram Elements Supported by an IVR Flow',
  'oifde.sysMgr.dataClean.paramValueTca': 'Parameter value',
  'oifde.sysMgr.encryptSecretCfg': 'Key Settings',
  'oifde.sysMgr.encryptSecret': 'Key',
  'oifde.sysMgr.encryptSecretComfirm': 'Confirm Key',
  'oifde.sysMgr.encryptSecretNotSame': 'The keys do not match.',
  'oifde.sysMgr.inputEncryptSecret': 'Enter a key.',
  'oifde.sysMgr.success_set_encryptSecret': 'The key is set successfully!',
  'oifde.sysMgr.encryptSecretRuleRange': 'The Encrypt Secret does not meet requirements. It must contain at least two types of the following: uppercase letters, lowercase letters, digits, and special characters ~ ` ! {\'@\'} # {\'$\'} % ^ * ( ) - + _ = {\'|\'} , . / < > ? ; \' : " [ ] {\'{\'} {\'}\'} &.',
  'oifde.sysMgr.encryptSecretNoSame': 'The key cannot be the same as the tenant space ID.',
  'oifde.sysMgr.encryptSecretLenCheck': 'The key must contain 16 characters.',
  'oifde.sysMgr.parameterSetting': 'Intelligent Engine Parameter Settings',
  'oifde.sysMgr.apiNoSame': 'The password cannot be the same as the tenant space ID.',
  'oifde.sysMgr.variableSecretNotExists': 'The key is mandatory when you use an encrypted variable. Choose System Management > System Configuration and click Key Settings to set the key.',
  'oifde.sysMgr.rpaCfg': 'RPA Settings',
  'oifde.sysMgr.rpaUserNameCfg': 'RPA User Account',
  'oifde.sysMgr.rpaSecretKeyCfg': 'RPA Secret Key',
  'oifde.route.flow': 'Orchestration',
  'oifde.route.rule': 'Flow Gray Rule',
  'oifde.route.trace': 'Flow Tracking',
  'oifde.route.flowAnalysis': 'Flow Analysis',
  'oifde.route.ivrOverview': 'IVR Overview',
  'oifde.route.indicatorAnalysis': 'Indicator Analysis',
  'oifde.route.versionComparisonAndAnalysis': 'Version Comparison And Analysis',
  'oifde.route.optimizationSuggestions': 'Optimization Suggestions',
  'oifde.route.alarmEvent': 'Alarm Event',
  'oifde.route.addDiagram': 'Add Flow',
  'oifde.route.editCellDiagram': 'Edit Composite Canvas',
  'oifde.route.edit': 'Edit {0}',
  'oifde.route.view': 'View {0}',
  'oifde.route.editDiagram': 'Edit Flow',
  'oifde.route.viewDiagram': 'View Flow',
  'oifde.route.viewTraceDiagram': 'View Flow Tracking',
  'oifde.route.viewCellTraceDiagram': 'View Composite Diagram Element Tracking',
  'oifde.route.voiceTemplateTitle': 'Resource Template',
  'oifde.route.staticCheck': 'Flow Check',
  'oifde.route.toc.sysadmin': 'Semantic Understanding',
  'oifde.route.toc.user': 'Semantic Understanding',
  'oifde.route.tuc.template.index': 'Knowledge Management',
  'oifde.route.tuc.template.domain': 'Domain Management',
  'oifde.route.tuc.template.intent': 'Intention Management',
  'oifde.route.tuc.template.entity': 'Entity Management',
  'oifde.route.tuc.template.faq': 'FAQ Management',
  'oifde.route.tuc.template.kg': 'Graph Management',
  'oifde.route.tuc.template.config': 'System Configuration',
  'oifde.route.tuc.template.bwlist': 'Blocklist and Trustlist',
  'oifde.route.tuc.history.index': 'Historical Dialog',
  'oifde.route.tuc.history.dialog': 'Historical Message Review',
  'oifde.route.tuc.history.mining': 'Question Clustering Task',
  'oifde.route.tuc.ops.index': 'Training Check',
  'oifde.route.tuc.ops.test': 'Dialog Test',
  'oifde.route.tuc.ops.tucTest': 'Knowledge Test',
  'oifde.route.tuc.ops.perf': 'Performance Statistics',
  'oifde.route.tuc.ops.node': 'Maintaining',
  'oifde.route.tuc.ops.log': 'Operation Log',
  'oifde.route.tuc.ops.corpustest': 'Corpus Test',
  'oifde.route.tuc.ops.server': 'Server',
  'oifde.route.tuc.ops.qiAsrServer': 'Quality Inspection',
  'oifde.route.tuc.ops.asr': 'TTS/ASR',
  'oifde.route.tuc.ops.avatar': '2D Virtual Human',
  'oifde.route.tuc.ops.modelMgr': 'Model Management',
  'oifde.route.tuc.ops.sysparam': 'Intelligent Engine Parameter',
  'oifde.route.tuc.ops.kgrule': 'Knowledge Gray Rule',
  'oifde.route.tuc.ops.license': 'License',
  'oifde.route.keyPressRobot': 'Common IVR',
  'oifde.route.RobotManage': 'Intelligent Chatbot',
  'oifde.route.accessCodeManage': 'Access Code',
  'oifde.route.sysMgr.sysMgr': 'System',
  'oifde.route.sysMgr.sysCfg': 'System Settings',
  'oifde.route.sysMgr.whiteList': 'Whitelist',
  'oifde.route.sysMgr.recordHistory': 'Historical Call',
  'oifde.route.navigation': 'Flow',
  'oifde.route.resources': 'Resource',
  'oifde.route.serviceInterface': 'Business Interface',
  'oifde.route.variable': 'Variable',
  'oifde.route.cell': 'Complex Cell',
  'oifde.route.procedure': 'Stored Procedure',
  'oifde.route.dataTable': 'Data Table',
  'oifde.route.unusedResource.template': 'View Unused Templates',
  'oifde.route.unusedResource.globalvars': 'View Unused Global Variables',
  'oifde.route.unusedResource.flowvars': 'View Unused Flow Variables',
  'oifde.route.unusedResource.complexcell': 'View Unused Composite Diagram Elements',
  'oifde.route.unusedResource.interface': 'View Unused Interfaces',
  'oifde.route.unusedResource.procedure': 'View Unused Stored Procedures',
  'oifde.route.unusedResource.rule': 'View Unused Gray Rules',
  'oifde.route.knowledgegraph.knowledgegraph': 'Knowledge Graph',
  'oifde.route.knowledgegraph.kgModel': 'Knowledge Schema',
  'oifde.route.knowledgegraph.editSchema': 'Details of Schema {0}',
  'oifde.route.knowledgegraph.dicmgr': 'Graph Dictionary',
  'oifde.route.knowledgegraph.entitymgr': 'Entity Management for {0}',
  'oifde.route.knowledgegraph.entityAttribute': 'Entity Attribute for {0}',
  'oifde.route.knowledgegraph.relationmgr': 'Relationship Management for {0}',
  'oifde.route.knowledgegraph.relationAttribute': 'Relationship Attribute for {0}',
  'oifde.route.knowledgegraph.kgView': 'Graph Visualization for {0}',
  'oifde.route.knowledgegraph.kgExport': 'Graph Export',
  'oifde.route.knowledgegraph.importExcel': 'Graph Import',
  'oifde.route.knowledgegraph.kgFAQ': 'Graph FAQ for {0}',
  'oifde.route.knowledgegraph.kgSynonyms': 'Synonym for {0}',
  'oifde.route.knowledgegraph.extendQuestion': 'Extended Question',
  'oifde.flow.code': 'Code',
  'oifde.flow.name': 'Name',
  'oifde.flow.version': 'Version',
  'oifde.flow.scenarioType': 'Scenario Type',
  'oifde.flow.status': 'Status',
  'oifde.flow.rule': 'Gray Rule',
  'oifde.flow.createTime': 'Created',
  'oifde.flow.updateTime': 'Modified',
  'oifde.flow.grayFlow': 'Gray',
  'oifde.flow.noRule': 'No rule configured',
  'oifde.flow.defaultFlow': 'Default flow',
  'oifde.flow.actions.publish': 'Publish',
  'oifde.flow.actions.delete': 'Delete',
  'oifde.flow.actions.edit': 'Edit',
  'oifde.flow.actions.invalid': 'Invalidate',
  'oifde.flow.actions.upgrade': 'Upgrade',
  'oifde.flow.actions.setting': 'Set',
  'oifde.flow.actions.copy': 'Copy Flow',
  'oifde.flow.actions.copySuccess': 'The flow is copied successfully!',
  'oifde.flow.actions.copyFailed': 'Failed to copy the flow!',
  'oifde.flow.actions.deleteConfirm': 'This operation will permanently delete the flow. Are you sure you want to continue?',
  'oifde.flow.actions.deleteCancel': 'The deletion is canceled.',
  'oifde.flow.actions.deleteOk': 'The flow is deleted successfully!',
  'oifde.flow.actions.invalidConfirm': 'This operation will bring the flow unreleased. Are you sure you want to continue?',
  'oifde.flow.actions.invalidOk': 'The flow has unpublished.',
  'oifde.flow.actions.invalidCancel': 'The invalidation is canceled.',
  'oifde.flow.actions.createOk': 'A new version of the flow is created successfully!',
  'oifde.flow.actions.settingOk': 'The flow is configured successfully!',
  'oifde.flow.actions.publishOk': 'The flow is published successfully!',
  'oifde.flow.actions.confirm': 'Yes',
  'oifde.flow.actions.prompt': 'Confirm',
  'oifde.flow.actions.cancel': 'No',
  'oifde.flow.actions.error': 'Operation failed!',
  'oifde.flow.actions.errorCode': 'Error Code',
  'oifde.flow.actions.errorMessage': 'Error Message',
  'oifde.flow.actions.staticcheck': 'Static Check',
  'oifde.flow.rules.ruleId': 'Select a rule.',
  'oifde.flow.rules.upgradeMode': 'Select a publish mode.',
  'oifde.flow.rules.logSwitchOn': 'Select whether to enable the log function.',
  'oifde.flow.rules.validationMode': 'Select whether to enable strict validation.',
  'oifde.flow.rules.newVersion': 'Specify a new version.',
  'oifde.flow.rules.newVersionDuplicated': 'The new version must be unique.',
  'oifde.flow.rules.newVersionLength': 'The new version cannot contain more than 64 characters.',
  'oifde.flow.rules.newFlowName': 'Specify a new flow name.',
  'oifde.flow.rules.workMode': 'Select a work mode.',
  'oifde.flow.enums.scenarioType.mainFlow': 'Main flow',
  'oifde.flow.enums.scenarioType.errorFlow': 'Exception flow',
  'oifde.flow.enums.scenarioType.subFlow': 'Subflow',
  'oifde.flow.enums.status.init': 'Draft',
  'oifde.flow.enums.status.valid': 'Published',
  'oifde.flow.enums.status.invalid': 'Invalidated',
  'oifde.flow.enums.tabsValue.all': 'Export all',
  'oifde.flow.enums.tabsValue.none': 'Do not export',
  'oifde.flow.enums.tabsValue.depend': 'Export dependencies',
  'oifde.flow.publish.flowName': 'Name',
  'oifde.flow.publish.flowVersion': 'Version',
  'oifde.flow.publish.upgradeMode': 'Publish Mode',
  'oifde.flow.publish.upgrade': 'Upgrade and publish',
  'oifde.flow.publish.gray': 'Gray release',
  'oifde.flow.publish.ruleName': 'Gray Rule',
  'oifde.flow.publish.selectPlaceholder': '-Select-',
  'oifde.flow.publish.publishMode': 'Work Mode',
  'oifde.flow.publish.normal': 'Common',
  'oifde.flow.publish.monitor': 'Monitor',
  'oifde.flow.publish.insert': 'Insert',
  'oifde.flow.publish.notConfig': 'Not configured',
  'oifde.flow.copy.flowName': 'Original Flow Name',
  'oifde.flow.copy.newFlowName': 'New Flow Name',
  'oifde.flow.setting.logSwitch': 'Log Function',
  'oifde.flow.setting.logSwitchOn': 'Enable',
  'oifde.flow.setting.logSwitchOff': 'Disable',
  'oifde.flow.setting.title': 'Modify',
  'oifde.flow.setting.validationMode': 'Set Validation Mode',
  'oifde.flow.setting.validationModeOn': 'Enable',
  'oifde.flow.setting.validationModeOff': 'Disable',
  'oifde.flow.upgrade.currentVersions': 'Current Versions',
  'oifde.flow.upgrade.newVersion': 'New Version',
  'oifde.flow.exportFlowList.accessCode': 'Access Code',
  'oifde.flow.exportFlowList.rule': 'Rule',
  'oifde.flow.exportFlowList.template': 'Template',
  'oifde.flow.exportFlowList.interface': 'Interface',
  'oifde.flow.exportFlowList.variable': 'Variable',
  'oifde.flow.exportFlowList.title': 'Export',
  'oifde.flow.exportFlowList.askExportAll': 'You have not selected specific records. Export all records? You can also cancel the operation and select a specific record to export.',
  'oifde.flow.exportFlowList.prompt': 'Information',
  'oifde.flow.exportFlowList.promptMessage': 'Select at least one record.',
  'oifde.flow.exportFlowList.export': 'Export',
  'oifde.flow.exportFlowList.exportAll': 'Export All',
  'oifde.flow.exportFlowList.procedure': 'Stored Procedure',
  'oifde.flow.exportFlowList.complexCell': 'Composite Diagram Element',
  'oifde.flow.importFlow.title': 'Import Flow',
  'oifde.flow.importFlow.select': 'Select File',
  'oifde.flow.importFlow.upload': 'Upload',
  'oifde.flow.importFlow.prompt': 'The file must be a ZIP file.',
  'oifde.flow.importFlow.cover': 'Allow duplicate names',
  'oifde.flow.importFlow.resultTitle': 'Import Results',
  'oifde.flow.importFlow.name': 'Name',
  'oifde.flow.importFlow.type': 'Type',
  'oifde.flow.importFlow.result': 'Result',
  'oifde.flow.importFlow.error': 'Prompt',
  'oifde.flow.importFlow.message': 'Select the file you want to upload.',
  'oifde.flow.importFlow.fileType': 'The file must be a ZIP file.',
  'oifde.flow.importFlow.import': 'Import',
  'oifde.flow.importFlow.success': 'The operation is successful.',
  'oifde.flow.importFlow.bigFile': 'The size of the file is too large.',
  'oifde.flow.importFlow.uploadFail': 'Failed to upload the file!',
  'oifde.flow.importFlow.bigFilePrompt': 'The size of the file cannot exceed 20 MB.',
  'oifde.flow.importFlow.timeout': 'Importing data... Please query the latest data later.',
  'oifde.flow.usage.usageStatus': 'Statistics Status:',
  'oifde.flow.usage.lasttime': 'Last Completed:',
  'oifde.flow.usage.check': 'Check',
  'oifde.flow.usage.showUnused': 'View Unused Resources',
  'oifde.flow.usage.templateUsageTitle': 'Template Usage',
  'oifde.flow.usage.globalVarsUsageTitle': 'Global Variable Usage',
  'oifde.flow.usage.flowVarsUsageTitle': 'Flow Variable Usage',
  'oifde.flow.usage.inuse': 'In Use',
  'oifde.flow.usage.unuse': 'Never Used',
  'oifde.flow.usage.status.norecord': 'No data',
  'oifde.flow.usage.status.init': 'Pending Execution',
  'oifde.flow.usage.status.running': 'Executing',
  'oifde.flow.usage.status.finish': 'Completed',
  'oifde.flow.usage.status.fail': 'Failed',
  'oifde.flow.usage.name': 'Name',
  'oifde.flow.usage.type': 'Type',
  'oifde.flow.usage.desc': 'Description',
  'oifde.flow.usage.resourceType.template': 'Template',
  'oifde.flow.usage.resourceType.globalvars': 'Global Variable',
  'oifde.flow.usage.resourceType.flowvars': 'Flow Variable',
  'oifde.flow.usage.resourceType.complexcell': 'Composite Diagram Element',
  'oifde.flow.usage.resourceType.interface': 'Interface',
  'oifde.flow.usage.resourceType.procedure': 'Stored Procedure',
  'oifde.flow.usage.resourceType.rule': 'Gray rule',
  'oifde.flow.usage.complexcellUsageTitle': 'Composite Diagram Element Usage',
  'oifde.flow.usage.interfaceUsageTitle': 'Interface Usage',
  'oifde.flow.usage.procedureUsageTitle': 'Stored Procedure Usage',
  'oifde.flow.usage.ruleUsageTitle': 'Gray Rule Usage',
  'oifde.flow.flowTypeSelect': 'Select New Flow Template',
  'oifde.flow.flowTemplate': 'Flow Template',
  'oifde.flow.add.blankFlow': 'Blank Flow',
  'oifde.flow.add.callInFlow': 'Incoming Call Flow',
  'oifde.flow.add.outBoundFlow': 'Outgoing Call Flow',
  'oifde.flow.add.exceptionFlow': 'Abnormal Flow',
  'oifde.template.setInputFlag': 'Manually enter the content.',
  'oifde.template.template': 'Resource Template Management',
  'oifde.template.channelTypeMgr': 'Channel Type Management',
  'oifde.template.responseStyleMgr': 'Style Management',
  'oifde.template.channelTypeName': 'Name',
  'oifde.template.createChannelType': 'Add Channel',
  'oifde.template.editChannelType': 'Edit Channel',
  'oifde.template.responseStyleName': 'Name',
  'oifde.template.createResponseStyle': 'Add Style',
  'oifde.template.editResponseStyle': 'Edit Style',
  'oifde.template.selectChannelType': 'Select a channel.',
  'oifde.template.selectResponseStyle': 'Select a style.',
  'oifde.template.selectLang': 'Select a language.',
  'oifde.template.editContent': 'Add Template Content',
  'oifde.template.setDefault': 'Set as Default',
  'oifde.template.name': 'Name',
  'oifde.template.type': 'Type',
  'oifde.template.desc': 'Description',
  'oifde.template.content': 'Content',
  'oifde.template.createTime': 'Created',
  'oifde.template.updateTime': 'Modified',
  'oifde.template.detail': 'Content Details',
  'oifde.template.select': 'Type',
  'oifde.template.contentName': 'Content',
  'oifde.template.deleteContent': 'Delete',
  'oifde.template.addContent': 'Add Template Content',
  'oifde.template.tipContent': 'Enter template content.',
  'oifde.template.tipForVoice': 'Enter the absolute path to the voice file.',
  'oifde.template.tipForVideo': 'Enter the absolute path to the video file.',
  'oifde.template.addLimit': 'A maximum of three content records are allowed.',
  'oifde.template.deleteLimit': 'At least one content record is required.',
  'oifde.template.selectLanguage': 'Select a language. If you want to delete a language, click the close icon.',
  'oifde.template.addNewIntent': 'Add Intention',
  'oifde.template.setIntentTemplate': 'Configure Intention Template',
  'oifde.template.addTopNode': 'Add Top Node',
  'oifde.template.selectTemplate': 'Select Template',
  'oifde.template.selectAction': 'Select',
  'oifde.template.actions.create': 'Add',
  'oifde.template.actions.delete': 'Delete',
  'oifde.template.actions.edit': 'Edit',
  'oifde.template.actions.editbaseInfo': 'Edit Resource Template',
  'oifde.template.actions.addbaseInfo': 'Add Resource Template',
  'oifde.template.actions.success': 'Success',
  'oifde.template.actions.fail': 'Failure',
  'oifde.template.actions.createSuccess': 'The template is created successfully!',
  'oifde.template.actions.saveSuccess': 'The template is saved successfully!',
  'oifde.template.actions.updateSuccess': 'The template is modified successfully!',
  'oifde.template.actions.deleteSuccess': 'The template is deleted successfully!',
  'oifde.template.actions.cancelDelete': 'The deletion is canceled.',
  'oifde.template.actions.deletePrompt': 'This operation will permanently delete the template. Are you sure you want to continue?',
  'oifde.template.actions.deleteChannelType': 'This operation will permanently delete the channel. Are you sure you want to continue?',
  'oifde.template.actions.deleteResponseStyle': 'This operation will permanently delete the style. Are you sure you want to continue?',
  'oifde.template.actions.prompt': 'Confirm',
  'oifde.template.actions.confirm': 'Yes',
  'oifde.template.actions.cancel': 'No',
  'oifde.template.actions.append': 'Add',
  'oifde.template.update.templateName': 'Name',
  'oifde.template.update.templateType': 'Type',
  'oifde.template.update.templateDesc': 'Description',
  'oifde.template.update.intents': 'Intention List',
  'oifde.template.typeDetail.tts': 'TTS',
  'oifde.template.typeDetail.sms': 'SMS',
  'oifde.template.typeDetail.staticvoice': 'Static voice',
  'oifde.template.typeDetail.videovoice': 'Video file',
  'oifde.template.typeDetail.multiMedia': 'Multimedia',
  'oifde.template.typeDetail.cardTemplate': 'Card template',
  'oifde.template.language.chinese': 'Chinese',
  'oifde.template.language.english': 'English',
  'oifde.template.language.spanish': 'Spanish',
  'oifde.template.voicename.cn_kefuman_common': 'Graceful',
  'oifde.template.voicename.cn_chengshuxing_common': 'Mature',
  'oifde.template.voicename.cn_roumeiqian_common': 'Soft',
  'oifde.template.voicename.cn_tianmeiru_common': 'Sweet',
  'oifde.template.voicename.cn_ziranzhen_common': 'Natural',
  'oifde.template.defaultValueValidate.ttsSpeedValueDesc': 'value range : [-500,500]',
  'oifde.template.defaultValueValidate.ttsVolumeValueDesc': 'value range : [0,100]',
  'oifde.template.defaultValueValidate.ttsPitchValueDesc': 'value range : [-500,500]',
  'oifde.template.validate.content': 'The template content is mandatory.',
  'oifde.template.validate.templateName': 'Enter a template name.',
  'oifde.template.validate.templateType': 'Select a template type.',
  'oifde.template.validate.templateNameLength': 'The template name cannot contain more than 256 characters.',
  'oifde.template.validate.templateDescLength': 'The template description cannot contain more than 1024 characters.',
  'oifde.template.validate.templateContentLength': 'The template content cannot contain more than 2048 characters.',
  'oifde.template.validate.doubleNameCheck': 'The template name already exists.',
  'oifde.template.validate.intentDsp': 'Enter a template description.',
  'oifde.template.validate.intentcode': 'Enter an intention code.',
  'oifde.template.validate.nameDup': 'The name exists.',
  'oifde.template.validate.contentLengthError': 'The template content must contain 1 to 4096 characters.',
  'oifde.template.intentionInfo.addIntent': 'Add Intention',
  'oifde.template.intentionInfo.intentDsp': 'Description',
  'oifde.template.intentionInfo.intentcode': 'Code',
  'oifde.template.intentionInfo.intentList': 'List',
  'oifde.template.message.intentListLength': 'The intention list can contain a maximum of 4096 characters.',
  'oifde.template.message.intentNameDuplicate': 'The intention name already exists.',
  'oifde.template.message.validateResult': 'Information',
  'oifde.template.sysErrorTemplateTitle': 'Abnormal Resource Template',
  'oifde.template.sysErrorTemplate': 'Edit Abnormal Resource Template',
  'oifde.template.contentDetail': 'Details',
  'oifde.template.aiccTemplateNullTips': 'The template type cannot be empty.',
  'oifde.template.content.exist': 'The currently selected template content has been added',
  'oifde.template.selectResource': 'Select Resources',
  'oifde.template.message.searchResourceName': 'Searching for Resource Names',
  'oifde.template.title.ResourceName': 'Resource Name',
  'oifde.template.title.ResourceType': 'Resource Type',
  'oifde.template.multipart.type.location': 'Address',
  'oifde.rule.flowRule': 'Flow Gray Rule',
  'oifde.rule.kgRule': 'Knowledge Gray Rule',
  'oifde.rule.name': 'Name',
  'oifde.rule.beginNum': 'Start Number',
  'oifde.rule.endNum': 'End Number',
  'oifde.rule.language': 'Language',
  'oifde.rule.select': 'Select',
  'oifde.rule.number': 'Quantity',
  'oifde.rule.manage': 'Manage',
  'oifde.rule.actions.error': 'An error occurred during interface invoking.',
  'oifde.rule.actions.errorCode': 'Error Code',
  'oifde.rule.actions.errorMessage': 'Error Message',
  'oifde.rule.actions.create': 'Add',
  'oifde.rule.actions.delete': 'Delete',
  'oifde.rule.actions.edit': 'Edit',
  'oifde.rule.actions.success': 'Success',
  'oifde.rule.actions.addSuccess': 'The rule is added successfully!',
  'oifde.rule.actions.updateSuccess': 'The rule is modified successfully!',
  'oifde.rule.actions.deleteSuccess': 'The rule is deleted successfully!',
  'oifde.rule.actions.editbaseInfo': 'Edit Basic Info',
  'oifde.rule.actions.addbaseInfo': 'Create Basic Information',
  'oifde.rule.actions.confirm': 'Confirm',
  'oifde.rule.actions.sure': 'Yes',
  'oifde.rule.actions.cancel': 'No',
  'oifde.rule.actions.cancelDelete': 'The deletion is canceled.',
  'oifde.rule.actions.deleteConfirm': 'This operation will permanently delete the rule. Are you sure you want to continue?',
  'oifde.rule.actions.deleteFailed': 'Deletion failed! Check whether the rule is associated with a release flow. Cancel the release first.',
  'oifde.rule.actions.viewBoundFlow': 'View Bound Processes',
  'oifde.rule.rules.ruleNameValidator': 'The rule name already exists.',
  'oifde.rule.rules.beginNumValidator': 'The start number can contain only letters, digits, spaces, and the following special characters: _ - \' : . {\'@\'} /.',
  'oifde.rule.rules.endNumValidator': 'The end number can contain only letters, digits, spaces, and the following special characters: _ - \' : . {\'@\'} /.',
  'oifde.rule.rules.beginNumLengthValidator': 'The end number must be greater than or equal to the start number.',
  'oifde.rule.update.ruleName': 'Rule Name',
  'oifde.rule.update.beginNum': 'Start Number',
  'oifde.rule.update.newRuleName': 'Enter a rule name.',
  'oifde.rule.update.newBeginNum': 'Enter a start number.',
  'oifde.rule.update.newEndNum': 'Enter an end number.',
  'oifde.rule.update.endNum': 'End Number',
  'oifde.rule.message.add_name_list': 'Add Blocklist/Trustlist',
  'oifde.rule.message.name_list_info': 'Blocklist/Trustlist Details',
  'oifde.rule.message.succeed_save': 'The blocklist/trustlist is saved successfully!',
  'oifde.rule.message.fail_save': 'Failed to save the blocklist/trustlist!',
  'oifde.rule.message.failed_query': 'Failed to perform the query!',
  'oifde.rule.message.select_prompt': 'Select at least one record.',
  'oifde.rule.message.confirm_name_list': 'Are you sure you want to delete the blocklist/trustlists?',
  'oifde.rule.message.succeed_delete': 'The blocklist/trustlist is deleted successfully!',
  'oifde.rule.message.fail_delete': 'Failed to delete the blocklist/trustlist!',
  'oifde.rule.message.mgr_name_list': 'Manage Blocklist/Trustlist — ',
  'oifde.rule.message.add_user_ids': 'Add Subscriber Number',
  'oifde.rule.message.batch_input_prompt': 'Press Enter after each record to add it. Each record can contain 4 to 64 characters.',
  'oifde.rule.message.user_number': 'Subscriber Number',
  'oifde.rule.message.user_ids_number': 'A maximum of 5000 subscriber numbers can be added to the blocklist/trustlist.',
  'oifde.rule.message.user_ids_number_once': 'A maximum of 100 subscriber numbers can be added to the blocklist/trustlist in a batch.',
  'oifde.rule.message.user_id_not_exceed': 'Each subscriber number can contain 4 to 64 characters.',
  'oifde.rule.message.save_user_ids_success': 'The subscriber numbers are saved successfully!',
  'oifde.rule.message.save_user_ids_fail': 'Failed to save the subscriber numbers!',
  'oifde.rule.message.confirm_delete_user_ids': 'Are you sure you want to delete the subscriber numbers?',
  'oifde.rule.message.succeed_delete_user_ids': 'The subscriber numbers are deleted successfully!',
  'oifde.rule.message.failed_delete_user_ids': 'Failed to delete the subscriber numbers!',
  'oifde.rule.message.import_user_ids': 'Import Subscriber Number',
  'oifde.rule.message.import_words_hint_text': 'Text files can be imported(*.txt). The file encoding format is UTF-8.',
  'oifde.rule.message.overwrite_import': 'Overwrite Existing Configuration',
  'oifde.rule.message.success_import': 'The import is successful.',
  'oifde.rule.message.failed_import_file': 'Failed to import the file!',
  'oifde.accesscode.usingRecord': 'Packet Capture',
  'oifde.accesscode.messageTips': 'Please take a moment to provide feedback about what improvements you would like to see.',
  'oifde.accesscode.accessCode': 'Chatbot Access Code',
  'oifde.accesscode.accessCodeSearch': 'Chatbot Name Or Access Code',
  'oifde.accesscode.accessCodeDesc': 'Description',
  'oifde.accesscode.description': 'Access Code ID',
  'oifde.accesscode.descPlaceholder': 'This description is used as the flow description when the flow is selected as the called party configuration. The value must be globally unique.',
  'oifde.accesscode.flowCode': 'Flow Code',
  'oifde.accesscode.flowName': 'Flow Name',
  'oifde.accesscode.domainId': 'Domain ID',
  'oifde.accesscode.dialogType': 'Dialog Type',
  'oifde.accesscode.callingnumber': 'Calling Number',
  'oifde.accesscode.callednumber': 'Called Number:',
  'oifde.accesscode.processTimes': 'Number of Calls Processed Only by Agent',
  'oifde.accesscode.startcall': 'Start Call',
  'oifde.accesscode.sendprompt': 'Press Enter to send your message.',
  'oifde.accesscode.callingnumberinput': 'Enter the calling number.',
  'oifde.accesscode.isCheck': 'Check Spelling',
  'oifde.accesscode.isCheckHelp': 'Spelling mistakes in customer statements are corrected automatically before the intention is recognized.',
  'oifde.accesscode.sendContent': 'Send',
  'oifde.accesscode.trl': 'Type from Right to Left',
  'oifde.accesscode.actions.edit': 'Edit',
  'oifde.accesscode.actions.testCalledNum': 'Test Call',
  'oifde.accesscode.actions.delete': 'Delete',
  'oifde.accesscode.actions.editbaseInfo': 'Modify Chatbot',
  'oifde.accesscode.actions.addbaseInfo': 'Add Chatbot',
  'oifde.accesscode.actions.success': 'Success',
  'oifde.accesscode.actions.fail': 'Failure',
  'oifde.accesscode.actions.createSuccess': 'Creation successful.',
  'oifde.accesscode.actions.updateSuccess': 'Modification successful.',
  'oifde.accesscode.actions.deleteSuccess': 'Deletion successful.',
  'oifde.accesscode.actions.cancelDelete': 'The deletion is canceled.',
  'oifde.accesscode.actions.deletePrompt': 'This operation will permanently delete the chatbot. Are you sure you want to continue?',
  'oifde.accesscode.actions.prompt': 'Confirm',
  'oifde.accesscode.actions.confirm': 'Yes',
  'oifde.accesscode.actions.cancel': 'No',
  'oifde.accesscode.actions.flowChatbot': 'Flow Test Chatbot',
  'oifde.accesscode.actions.callforwarding': 'Call Transfer',
  'oifde.accesscode.actions.voicefile': 'Voice file:',
  'oifde.accesscode.actions.videofile': 'Video file:',
  'oifde.accesscode.dialogTypeDetail.voiceNavigation': 'Voice navigation',
  'oifde.accesscode.dialogTypeDetail.smartOutboundCall': 'Intelligent outgoing call',
  'oifde.accesscode.dialogTypeDetail.chatbot': 'Chatbot',
  'oifde.accesscode.dialogTypeDetail.ivrProcess': 'IVR flow',
  'oifde.accesscode.validate.accessCode': 'Enter a flow access code.',
  'oifde.accesscode.validate.accessCodeCharReg': 'Only letters and digits are allowed.',
  'oifde.accesscode.validate.doubleAccessCodeCheck': 'The access code already exists.',
  'oifde.accesscode.validate.dialogType': 'Select a dialog type.',
  'oifde.accesscode.validate.flowCode': 'Select a flow code.',
  'oifde.accesscode.validate.accessCodeLength': 'The access code can contain a maximum of 20 characters.',
  'oifde.accesscode.validate.flowCodeLength': 'The flow code can contain a maximum of 64 characters.',
  'oifde.accesscode.validate.descriptionLengthValidator': 'The access code description can contain a maximum of 512 characters.',
  'oifde.accesscode.validate.domainIdMax': 'The domain ID cannot exceed 2147483647.',
  'oifde.accesscode.validate.domainIdCheck': 'The domain ID is not a valid number.',
  'oifde.accesscode.more': 'More',
  'oifde.accesscode.bindDomain': 'Bind Domain',
  'oifde.accesscode.bindFaqGroup': 'Bind FAQ Group',
  'oifde.accesscode.bindKg': 'Bind Knowledge Graph',
  'oifde.accesscode.robotName': 'Chatbot Name',
  'oifde.accesscode.domain': 'Domain',
  'oifde.accesscode.faqGroup': 'FAQ Group',
  'oifde.accesscode.knowledgeGraph': 'Knowledge Graph',
  'oifde.accesscode.setTrace': 'Trace Settings',
  'oifde.accesscode.callingNumberTrace': 'Calling Number Tracing:',
  'oifde.accesscode.traceOpen': 'Enable',
  'oifde.accesscode.traceClose': 'Disable',
  'oifde.accesscode.hideTraceSet': 'Collapse',
  'oifde.accesscode.setTraceSuccess': 'The settings are applied successfully!',
  'oifde.accesscode.recordState.disable': 'Disable',
  'oifde.accesscode.recordState.enable': 'Enable',
  'oifde.accesscode.accessCodeSubscribed': 'The chatbot access code is in use.',
  'oifde.accesscode.bindScene': 'View References',
  'oifde.accesscode.chatBot.search': 'Search',
  'oifde.accesscode.chatBot.reset': 'Reset',
  'oifde.accesscode.searchScene': 'Select a scenario.',
  'oifde.accesscode.sceneName': 'Scenario',
  'oifde.accesscode.subName': 'Reference Name',
  'oifde.accesscode.createTime': 'Reference Time',
  'oifde.accesscode.chatBot.chatChanel': 'Multimedia channel',
  'oifde.accesscode.chatBot.agentAssistant': 'Agent assistant',
  'oifde.accesscode.chatBot.intelligentOB': 'IntelligentOB',
  'oifde.accesscode.chatBot.intelligentTrain': 'Intelligent train',
  'oifde.toc.statusCode.SERVER_BUSY': 'The system is currently busy. Try later.',
  'oifde.toc.statusCode.NEED_LOGIN': 'You have not signed in or performed operations for a long time. Sign in again.',
  'oifde.toc.statusCode.ACCOUNT_INCONSISTENT': 'You have signed in to the local computer using another account. To continue using this service, sign in again.',
  'oifde.toc.statusCode.ACCOUNT_SWITCHED': 'The sign-in account has been changed. This page will be updated to display the latest sign-in account.',
  'oifde.toc.statusCode.INCORRECT_VERIFYCODE': 'The verification code is incorrect. Enter the correct one.',
  'oifde.toc.statusCode.INVALID_JSON': 'The request is not in JSON format.',
  'oifde.toc.statusCode.HTTPS_REQUIRED': 'Only HTTPS is supported.',
  'oifde.toc.statusCode.EMPTY_ACCOUNT': 'The account number is empty.',
  'oifde.toc.statusCode.INVALID_ACCOUNT': 'Authentication failed! Sign in again.',
  'oifde.toc.statusCode.INSUFFICIENT_PERMISSION': 'You do not have the required permission. Contact the administrator.',
  'oifde.toc.statusCode.VERIFYCODE_EXPIRED': 'The verification code has expired. Try again later.',
  'oifde.toc.statusCode.ACCOUNT_LOCKED': 'The account has been locked temporarily. Try again later or contact the administrator to unlock the account.',
  'oifde.toc.statusCode.ACCOUNT_CHANGED': 'The account has been changed. Sign in again.',
  'oifde.toc.statusCode.ACCOUNT_DISABLED': 'The account has been suspended. Contact the administrator.',
  'oifde.toc.statusCode.UNLOCK_REQUIRED': 'The account has been locked temporarily. Unlock the account and try again.',
  'oifde.toc.statusCode.ADMIN_DISABLED': 'The management function has been disabled. Use a common account to sign in to the system.',
  'oifde.toc.statusCode.SESSION_OVERFLOW': 'The number of online users has reached the upper limit. Try again later.',
  'oifde.toc.statusCode.SESSION_EXPIRED': 'The current sign-in is invalid. Sign in again.',
  'oifde.toc.statusCode.SESSION_DUPLICATED': 'You are already signed in. To sign in again, you must first sign out.',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_ID': 'The tenant space ID is incorrect.',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_NAME': 'The tenant space name is incorrect.',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT': 'The account is incorrect.',
  'oifde.toc.statusCode.INCORRECT_PASSWORD': 'The password does not meet requirements.',
  'oifde.toc.statusCode.INCORRECT_GENDER': 'The gender is incorrect.',
  'oifde.toc.statusCode.INCORRECT_OWNER': 'The owner is incorrect.',
  'oifde.toc.statusCode.INCORRECT_TITLE': 'The length cannot exceed 8 characters.',
  'oifde.toc.statusCode.INCORRECT_TELEPHONE': 'The telephone number is incorrect.',
  'oifde.toc.statusCode.INCORRECT_EMAIL': 'The email address is incorrect.',
  'oifde.toc.statusCode.INCORRECT_ROLE_NAME': 'The role name is incorrect.',
  'oifde.toc.statusCode.EMPTY_TENANT_SPACE': 'The tenant space cannot be empty.',
  'oifde.toc.statusCode.ACCOUNT_DUPLICATED': 'The account already exists.',
  'oifde.toc.statusCode.ROLE_DUPLICATED': 'The role already exists.',
  'oifde.toc.statusCode.TENANT_SPACE_DUPLICATED': 'The tenant space already exists.',
  'oifde.toc.statusCode.ACCOUNT_NOT_FOUND': 'The account does not exist.',
  'oifde.toc.statusCode.ROLE_NOT_FOUND': 'The role does not exist.',
  'oifde.toc.statusCode.TENANT_SPACE_NOT_FOUND': 'The tenant space does not exist.',
  'oifde.toc.statusCode.ACCOUNT_FORBIDDEN': 'Operations on this account are forbidden.',
  'oifde.toc.statusCode.ROLE_FORBIDDEN': 'Operations on this role are forbidden.',
  'oifde.toc.statusCode.TENANT_SPACE_FORBIDDEN': 'Operations on this tenant space are forbidden.',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT_INPUT': 'The account can contain 6 to 32 characters comprised of letters, digits, and the following special characters: . {\'@\'} _ -',
  'oifde.toc.statusCode.INCORRECT_PASSWORD_UPDATE': 'The password cannot contain the same character repeated more than 3 consecutive times. In addition, the last 3 historical passwords cannot be reused. The password can contain 8 to 20 characters comprised of digits, lowercase letters, uppercase letters, and the following special characters: ~ ` ! {\'@\'} # {\'$\'} % ^ * ( ) - + _ {\'|\'} , . / < > ? ; \' : " [ ] {\'{\'} {\'}\'} &',
  'oifde.toc.statusCode.COMMON_ERROR': 'Server error.',
  'oifde.toc.statusCode.SERVICE_UNAVAILABLE': 'The service is currently unavailable.',
  'oifde.toc.statusCode.AUTHORIZE_FAILED': 'Validation failed!',
  'oifde.toc.statusCode.TRANSIT_FAILED': 'The request timed out.',
  'oifde.toc.statusCode.TRANSIT_ERROR': 'The request was sent successfully but the peer returned an error.',
  'oifde.toc.statusCode.RESPONSE_FAILED': 'The server failed to process the response!',
  'oifde.toc.statusCode.REFRESH_TIMEOUT': 'No operation has been performed for a long time. Sign in again.',
  'oifde.logparam.logParamMgr': 'Flume Log Parameter Settings',
  'oifde.logparam.logParam': 'Dynamic Parameters',
  'oifde.logparam.logName': 'Parameter Name',
  'oifde.logparam.source': 'Parameter Source',
  'oifde.logparam.sourceName': 'Parameter Source Name',
  'oifde.logparam.transFlag': 'Convert Value',
  'oifde.logparam.sourceValue': 'Source Value',
  'oifde.logparam.targetValue': 'Target Value',
  'oifde.logparam.actions.edit': 'Edit',
  'oifde.logparam.actions.transDetail': 'Convert Value',
  'oifde.logparam.actions.delete': 'Delete',
  'oifde.logparam.actions.editLogParamBaseInfo': 'Edit Dynamic Parameter',
  'oifde.logparam.actions.addLogParamBaseInfo': 'Add Dynamic Parameter',
  'oifde.logparam.actions.success': 'Success',
  'oifde.logparam.actions.createSuccess': 'Creation successful.',
  'oifde.logparam.actions.updateSuccess': 'Modification successful.',
  'oifde.logparam.actions.deleteSuccess': 'Deletion successful.',
  'oifde.logparam.actions.cancelDelete': 'The deletion is canceled.',
  'oifde.logparam.actions.deletePrompt': 'This operation will permanently delete the dynamic parameter. Are you sure you want to continue?',
  'oifde.logparam.actions.prompt': 'Confirm',
  'oifde.logparam.actions.confirm': 'Yes',
  'oifde.logparam.actions.cancel': 'No',
  'oifde.logparam.actions.add': 'Add',
  'oifde.logparam.sourceDetail.IVRRequestInformation': 'IVR request',
  'oifde.logparam.sourceDetail.IVRResponseInformation': 'IVR response',
  'oifde.logparam.sourceDetail.TOCRequestInformation': 'TOC request',
  'oifde.logparam.sourceDetail.TOCResponseInformation': 'TOC response',
  'oifde.logparam.sourceDetail.InterfaceRequestInformation': 'Interface request',
  'oifde.logparam.sourceDetail.InterfaceResponseInformation': 'Interface response',
  'oifde.logparam.transFlagDetail.YES': 'Yes',
  'oifde.logparam.transFlagDetail.NO': 'No',
  'oifde.logparam.validate.logName': 'Enter a parameter name.',
  'oifde.logparam.validate.source': 'Select a parameter source.',
  'oifde.logparam.validate.sourceName': 'Enter a parameter source name.',
  'oifde.logparam.validate.sourceValue': 'Enter a source value.',
  'oifde.logparam.validate.targetValue': 'Enter a target value.',
  'oifde.logparam.validate.doublelogNameCheck': 'The dynamic parameter name already exists.',
  'oifde.logparam.validate.logNameLength': 'The dynamic parameter name can contain a maximum of 64 characters.',
  'oifde.logparam.validate.sourceNameLength': 'The parameter source name can contain a maximum of 64 characters.',
  'oifde.logparam.validate.sourceValueLength': 'Maximum length:64',
  'oifde.logparam.validate.targetValueLength': 'Maximum length:64',
  'oifde.logparam.message.logParamNotNull': 'Select whether to convert the value.',
  'oifde.logparam.message.validateFailed': 'Validation failed!',
  'oifde.logparam.message.validateResult': 'Information',
  'oifde.tenantinfo.tenanInfoMgr': 'Static Parameters',
  'oifde.tenantinfo.serviceName': 'Name',
  'oifde.tenantinfo.serviceValue': 'Value',
  'oifde.tenantinfo.actions.edit': 'Edit',
  'oifde.tenantinfo.actions.editTenantBaseInfo': 'Edit Static Parameter',
  'oifde.tenantinfo.actions.success': 'Success',
  'oifde.tenantinfo.actions.updateSuccess': 'Modification successful.',
  'oifde.tenantinfo.validate.serviceValue': 'Enter a value.',
  'oifde.tenantinfo.validate.serviceValueLength': 'The value can contain a maximum of 64 characters.',
  'oifde.variable.innerTitle': 'Built-in Variable',
  'oifde.variable.customTitle': 'Custom Variable',
  'oifde.variable.variableName': 'Variable Name',
  'oifde.variable.variableDesc': 'Description',
  'oifde.variable.variableType': 'Data Type',
  'oifde.variable.scope': 'Scope',
  'oifde.variable.defaultValue': 'Default Value',
  'oifde.variable.encryptType': 'Encryption Type',
  'oifde.variable.timezoneDesc': 'Use this variable to set the offset of the time zone in which the tenant resides. The value ranges from –12 to +12.',
  'oifde.variable.channeltypeDesc': 'Use this variable to set the channel of the tenant.',
  'oifde.variable.responsestyleDesc': 'Use this variable to set the style of the tenant.',
  'oifde.variable.langDesc': 'Use this variable to set the language of the tenant.',
  'oifde.variable.ttsSpeedDesc': 'Use this variable to set the TTS speech speed of the tenant.',
  'oifde.variable.ttsVolumeDesc': 'Use this variable to set the TTS volume of the tenant.',
  'oifde.variable.ttsPitchDesc': 'Use this variable to set the TTS fundamental frequency of the tenant.',
  'oifde.variable.voiceNameDesc': 'Use this variable to set the TTS speaker of the tenant.',
  'oifde.variable.variableTypeDetail.int': 'Integer',
  'oifde.variable.variableTypeDetail.string': 'Character',
  'oifde.variable.variableTypeDetail.long': 'Long integer',
  'oifde.variable.variableTypeDetail.float': 'Floating-point number',
  'oifde.variable.variableTypeDetail.list': 'List',
  'oifde.variable.variableTypeDetail.object': 'Object',
  'oifde.variable.scopes.input': 'Input parameter',
  'oifde.variable.scopes.output': 'Output parameter',
  'oifde.variable.scopes.local': 'Local',
  'oifde.variable.actions.edit': 'Edit',
  'oifde.variable.actions.delete': 'Delete',
  'oifde.variable.actions.editVariableBaseInfo': 'Edit Variable',
  'oifde.variable.actions.addVariableBaseInfo': 'Add Variable',
  'oifde.variable.actions.success': 'Success',
  'oifde.variable.actions.createSuccess': 'Creation successful!',
  'oifde.variable.actions.updateSuccess': 'Modification successful!',
  'oifde.variable.actions.deleteSuccess': 'Deletion successful!',
  'oifde.variable.actions.cancelDelete': 'The deletion is canceled',
  'oifde.variable.actions.deletePrompt': 'This operation will permanently delete the variable. Are you sure you want to continue?',
  'oifde.variable.actions.prompt': 'Confirm',
  'oifde.variable.actions.confirm': 'Yes',
  'oifde.variable.actions.cancel': 'No',
  'oifde.variable.actions.add': 'Add',
  'oifde.variable.validate.variableName': 'Enter a variable name.',
  'oifde.variable.validate.variableNameRange': 'Only letters, digits, and underscores (_) are allowed.',
  'oifde.variable.validate.variableType': 'Select a variable type.',
  'oifde.variable.validate.scope': 'Select the scope.',
  'oifde.variable.validate.doubleVariableNameCheck': 'The variable name already exists.',
  'oifde.variable.validate.intValueCheck': 'The default value for integer data is incorrect. It ranges from –2147483648 to +2147483647.',
  'oifde.variable.validate.longValueCheck': 'The default value for long integer data is invalid. It ranges from -9223372036854775808 to 9223372036854775807',
  'oifde.variable.validate.floatValueCheck': 'The default value for floating-point data is in an incorrect format. The value must be in 100 or 99.99 format',
  'oifde.variable.validate.listValueCheck': 'The format of the default list data value is incorrect. The value must be in [1,2,3] or ["a","b","c"] format, and the value can be [].',
  'oifde.variable.validate.objectValueCheck': 'The format of the default object data value is incorrect. The value must be in {\'{\'}"key1":"value1","key2":"value2"{\'}\'}, and the value can be {\'{\'}{\'}\'}.',
  'oifde.variable.validate.variableNameLength': 'The variable name contains more than 1000 characters.',
  'oifde.variable.validate.variableDescLength': 'The variable description contains more than 1000 characters.',
  'oifde.variable.validate.defaultValueLength': 'The default value contains more than 1024 characters.',
  'oifde.variable.encryptFlag.zero': 'No encryption',
  'oifde.variable.encryptFlag.one': 'Encrypted for storage and decrypted upon retrieval',
  'oifde.variable.encryptFlag.two': 'Encrypted for storage and retrieval',
  'oifde.diagram.NMS_PleaseSelect': '-Select-',
  'oifde.diagram.NMS_ParamName': 'Parameter',
  'oifde.diagram.NMS_ParamValue': 'Value',
  'oifde.diagram.NMS_DiagramProperties': 'Flow Attribute',
  'oifde.diagram.NMS_BasicProperties': 'Basic Attribute',
  'oifde.diagram.NMS_DiagramCode': 'Flow Code',
  'oifde.diagram.NMS_DiagramName': 'Flow Name',
  'oifde.diagram.NMS_ScenarioType': 'Scenario Type',
  'oifde.diagram.NMS_Desciption': 'Description',
  'oifde.diagram.NMS_NodeCode': 'Node Code',
  'oifde.diagram.NMS_NodeName': 'Node Name',
  'oifde.diagram.NMS_AdvanceProperties': 'Advanced Attribute',
  'oifde.diagram.NMS_ServiceName': 'Service Name',
  'oifde.diagram.NMS_ServiceParams': 'Service Parameter',
  'oifde.diagram.NMS_ServiceDesciption': 'Service Description',
  'oifde.diagram.add': 'Add',
  'oifde.diagram.remove': 'Delete',
  'oifde.diagram.NMS_BranchProperties': 'Branch Attribute',
  'oifde.diagram.NMS_BranchCondition': 'Branch Condition',
  'oifde.diagram.NMS_BranchService': 'Service Branch',
  'oifde.diagram.NMS_NodeProperties': 'Node Attribute',
  'oifde.diagram.NMS_FlowVariable': 'Flow Variable',
  'oifde.diagram.NMS_FlowVariableName': 'Variable Name',
  'oifde.diagram.NMS_FlowVariableDesc': 'Description',
  'oifde.diagram.NMS_FlowVariableType': 'Data Type',
  'oifde.diagram.NMS_FlowVariableDefaultValue': 'Default Value',
  'oifde.diagram.NMS_FlowVariableEncryptType': 'Encryption Type',
  'oifde.diagram.NMS_FlowVariableEncryptTypeWarn': '* Note that this variable is stored in plaintext when the encryption type is not encrypted. Do not assign sensitive data to this variable.',
  'oifde.diagram.NMS_FlowVariableEncryptTypeTips': '* Note that when the encryption type is encryption, you are advised to assign a value to a variable to prevent information leakage.',
  'oifde.diagram.NMS_FlowVariableOption': 'Operation',
  'oifde.diagram.NMS_AddVariable': 'Add Variable',
  'oifde.diagram.NMS_ModifyVariable': 'Modify Variable',
  'oifde.diagram.variableTypeDetail.int': 'Integer',
  'oifde.diagram.variableTypeDetail.string': 'Character',
  'oifde.diagram.variableTypeDetail.long': 'Long integer',
  'oifde.diagram.variableTypeDetail.float': 'Floating-point number',
  'oifde.diagram.variableTypeDetail.list': 'Table',
  'oifde.diagram.variableTypeDetail.object': 'Object',
  'oifde.diagram.error.NMS_flowCodeError': 'The flow code cannot be empty or exceed 64 characters.',
  'oifde.diagram.error.NMS_flowNameError': 'The flow name cannot be empty or exceed 1000 characters.',
  'oifde.diagram.error.NMS_scenarioTypeError': 'This scenario type cannot be empty.',
  'oifde.diagram.error.NMS_flowDescError': 'The flow description can contain a maximum of 1000 characters.',
  'oifde.diagram.error.NMS_VariableNameError': 'The variable name can contain a maximum of 1000 characters.',
  'oifde.diagram.error.NMS_VariableDescError': 'The variable description can contain a maximum of 1000 characters.',
  'oifde.diagram.error.NMS_VariableDefaultValueError': 'The default value of the variable can contain a maximum of 1024 characters.',
  'oifde.diagram.error.NMS_NodeCodeError': 'The node code cannot be empty or exceed 64 characters.',
  'oifde.diagram.error.NMS_NodeNameError': 'The node name cannot be empty or exceed 1000 characters.',
  'oifde.diagram.error.NMS_NodeDescError': 'The node description can contain a maximum of 1000 characters.',
  'oifde.diagram.error.NMS_actionParamNameError': 'The parameter name can contain a maximum of {0} characters.',
  'oifde.diagram.error.NMS_actionParamValueError': 'The parameter value can contain a maximum of 4096 characters.',
  'oifde.diagram.error.NMS_actionParamDescError': 'The parameter description can contain a maximum of 500 characters.',
  'oifde.diagram.error.NMS_transitToStartNodeError': 'Failed to jump to the start node!',
  'oifde.diagram.error.NMS_endNodetransitToError': 'Failed to jump from the end node to another node!',
  'oifde.diagram.error.NMS_startNodeNotFind': 'The start node is not found.',
  'oifde.diagram.error.NMS_startNodeNotUnique': 'The start node is not unique.',
  'oifde.diagram.error.NMS_startNodeNotBranchError': 'The start node can have only one branch.',
  'oifde.diagram.error.NMS_endNodeNotFind': 'The end node is not found.',
  'oifde.diagram.error.NMS_branchError': 'The branch must have the start node and end node.',
  'oifde.diagram.error.NMS_nullBranchNotNuique': 'A node can have only one default branch.',
  'oifde.diagram.error.NMS_checkDefaultNextNodeForView': 'The diagram element of the view type cannot have more than one branch.',
  'oifde.diagram.error.NMS_actionParamValueEmptyError': 'The parameter value cannot be empty.',
  'oifde.diagram.error.NMS_nmsFlowError': 'Failed to parse the flow because the nmsFlow node cannot be found!',
  'oifde.diagram.error.NMS_rootError': 'Failed to parse the flow because the root node cannot be found!',
  'oifde.diagram.error.NMS_analiseXmlFailed': 'Failed to parse the flow canvas!',
  'oifde.diagram.complexCell.name': 'Diagram Element Name',
  'oifde.diagram.complexCell.variable': 'Diagram Element Variable',
  'oifde.diagram.speechRecognition.recognitionParam': 'Recognition Parameters',
  'oifde.diagram.speechRecognition.resultParam': 'Result Processing',
  'oifde.diagram.varType': 'Variable Type',
  'oifde.diagram.usedVar': 'Used Variable',
  'oifde.diagram.usedCell': 'Diagram Element',
  'oifde.diagram.fastGetCell': 'Find Diagram Element',
  'oifde.diagram.fastByVar': 'Find Variable',
  'oifde.diagram.aimCell': 'Target Diagram Element',
  'oifde.diagram.saveAndPublish': 'Save and Publish',
  'oifde.diagram.NMS_VoiceTemplate': 'Response Template',
  'oifde.navbar.tenantId': 'Current tenant ID',
  'oifde.navbar.tenantName': 'Current tenant',
  'oifde.navbar.current': 'Current sign-in',
  'oifde.navbar.success': 'Last successful sign-in',
  'oifde.navbar.failed': 'Last failed sign-in',
  'oifde.navbar.logOut': 'Sign Out',
  'oifde.navbar.switchUser': 'Switch User',
  'oifde.navbar.screenfull': 'Full Screen',
  'oifde.navbar.switchLang': 'Changing Language',
  'oifde.navbar.menuOpen': 'Show Menu',
  'oifde.navbar.menuClose': 'Hide Menu',
  'oifde.navbar.changePwd': 'Change Password',
  'oifde.navbar.required': 'This field is mandatory.',
  'oifde.navbar.action.message': 'Prompt',
  'oifde.navbar.action.success': 'Success',
  'oifde.navbar.action.successMessage': 'The user is switched successfully!',
  'oifde.navbar.action.changePasswordSuccess': 'The password is changed successfully!',
  'oifde.navbar.password.logOut': 'Sign Out',
  'oifde.navbar.password.oldPassword': 'Old Password',
  'oifde.navbar.password.newPassword': 'New Password',
  'oifde.navbar.password.confirmNewPassword': 'Confirm Password',
  'oifde.navbar.password.passwordExpired': 'Your password has expired. Change it.',
  'oifde.navbar.password.firstTimeLogin': 'You are using the initial password. Change it.',
  'oifde.navbar.password.passwordWillExpire': 'Your password is about to expire. Change it.',
  'oifde.navbar.password.passwordNotSame': 'The two passwords are different.',
  'oifde.journeylogmgr.flowAnalysisMrg': 'Flow Analysis Settings',
  'oifde.journeylogmgr.accessCodeSetting': 'Access Code Settings',
  'oifde.trackmgr.track': 'Track Settings',
  'oifde.trackmgr.accessCodeTrack': 'Track Access Code',
  'oifde.trackmgr.callingNumberTrack': 'Track Calling Number',
  'oifde.trackmgr.callingNumber': 'Calling Number',
  'oifde.trackmgr.accessCode': 'Access Code',
  'oifde.trackmgr.choose': '-Select-',
  'oifde.trackmgr.validate.accessCode': 'Select the access code.',
  'oifde.trackmgr.validate.callingNumber': 'Enter a calling number.',
  'oifde.trackmgr.validate.needNumber': 'Enter a number.',
  'oifde.trackmgr.validate.callingNumberLength': 'The calling number can contain a maximum of 64 characters.',
  'oifde.trackmgr.validate.callingNumberDub': 'The calling number must be unique.',
  'oifde.trackmgr.validate.pattern': 'Only letters, digits, spaces, and the following special characters are allowed: . _ - \' : {\'@\'} /',
  'oifde.trackmgr.actions.delete': 'Delete',
  'oifde.trackmgr.actions.success': 'Success',
  'oifde.trackmgr.actions.updateSuccess': 'Modification successful.',
  'oifde.trackmgr.actions.add': 'Add',
  'oifde.trackmgr.actions.deleteSuccess': 'Deletion successful.',
  'oifde.trackmgr.actions.cancelDelete': 'The deletion is canceled.',
  'oifde.trackmgr.actions.cancelUpdate': 'Modification canceled.',
  'oifde.trackmgr.actions.deletePrompt': 'This operation will permanently delete the track data. Are you sure you want to continue?',
  'oifde.trackmgr.actions.updatePrompt': 'This operation will affect system performance, and the setting will be automatically deleted {0} h later. Are you sure you want to continue?',
  'oifde.trackmgr.actions.prompt': 'Confirm',
  'oifde.trackmgr.actions.confirm': 'Yes',
  'oifde.trackmgr.actions.cancel': 'No',
  'oifde.trace.sessionLog': 'Dialog Log Query',
  'oifde.trace.traceLog': 'Log Track Query',
  'oifde.trace.beginTime': 'Start Time',
  'oifde.trace.endTime': 'End Time',
  'oifde.trace.callingNumber': 'Calling Number',
  'oifde.trace.calledNumber': 'Called Number',
  'oifde.trace.createTime': 'Created',
  'oifde.trace.flowId': 'Flow ID',
  'oifde.trace.flowName': 'Flow Name',
  'oifde.trace.logInfo': 'Log Information',
  'oifde.trace.errorInfo': 'Error Information',
  'oifde.trace.callId': 'Call Instance ID',
  'oifde.trace.nodeId': 'Node ID',
  'oifde.trace.nodeName': 'Node Name',
  'oifde.trace.actionContent': 'Instruction Name',
  'oifde.trace.nodeIndex': 'Execution Node No.',
  'oifde.trace.ivrRequest': 'IVR Request',
  'oifde.trace.actionParams': 'Execution Node Parameter Information',
  'oifde.trace.processResult': 'Execution Result',
  'oifde.trace.transitionOn': 'Execution Result Branch',
  'oifde.trace.reset': 'Reset',
  'oifde.trace.validate.needNumber': 'Enter digits or letters.',
  'oifde.trace.validate.callingNumber': 'Enter a calling number.',
  'oifde.trace.validate.callingNumberLength': 'The calling number can contain a maximum of 64 characters.',
  'oifde.trace.clickToDetail': 'Click to show more.',
  'oifde.trace.clickToHideDetail': 'Click to show less.',
  'oifde.trace.currentNodeInfo': 'Current Node Information',
  'oifde.trace.clickToFlow': 'View Track',
  'oifde.trace.nodeTrace': 'Node Log Information',
  'oifde.trace.nodeParams': 'Node Input and Output Parameters',
  'oifde.trace.traceReason': 'Error Cause',
  'oifde.trace.traceAdvice': 'Solution',
  'oifde.trace.reasonAdvice': 'Cause and Suggestion',
  'oifde.expression.epxr_input_head': 'Maximum length:',
  'oifde.expression.expr_type_illegal': 'The expression format is invalid.',
  'oifde.expression.expr_key_repeats': 'Duplicate condition name.',
  'oifde.expression.expr_var': 'Enter a variable. Start the value with GLOBAL (for a global variable), FLOW (for a flow variable), SYS (for a system variable), or TOC (for a TUC variable), or IVRREQUEST (for a IVR request variable).',
  'oifde.expression.expr_var_fag': 'Enter a variable. Start the value with GLOBAL (for a global variable), FLOW (for a flow variable).',
  'oifde.expression.expr_var_string': 'Enter a variable of the string type. Start the value with GLOBAL (for a global variable), FLOW (for a flow variable), SYS (for a system variable), or TOC (for a TUC variable), or IVRREQUEST (for a IVR request variable).',
  'oifde.expression.expr_number': 'Enter a value of the numeric type. The available operators between values are +, –, *, and /.',
  'oifde.expression.expr_number_noAction': 'Enter a value of the numeric type.',
  'oifde.expression.expr_var_list': 'Enter a list variable.',
  'oifde.expression.expr_var_number': 'Enter a int variable.',
  'oifde.expression.allVar': 'Start the value with GLOBAL (for a global variable), FLOW (for a flow variable), SYS (for a system variable), or TOC (for a TUC variable), or IVRREQUEST (for a IVR request variable).',
  'oifde.expression.flowAndGlobalVar': 'Enter a variable. Start the value with GLOBAL (for a global variable), FLOW (for a flow variable).',
  'oifde.expression.expr_string': 'Enter a value of the string type. Enclose the value in single or double quotation marks (\' or "). The character string operator is the plus sign (+).',
  'oifde.expression.expr_simple_string': 'Enter a string variable or any character string. Do not enclose the character string in single or double quotation marks (\' or ").',
  'oifde.expression.expr_boolean': 'Enter a value of the Boolean type. The available comparison operators between numeric values or character strings are >, <, >=, <=, and !=. The Boolean operators are &&, ||, ==, and !=. The string method supports startsWith.',
  'oifde.expression.varFlow': 'Flow variable',
  'oifde.expression.varGlobal': 'Global variable',
  'oifde.expression.varToc': 'Intent variable',
  'oifde.expression.varSys': 'System variable',
  'oifde.expression.varIvrRequest': 'IVR request variable',
  'oifde.expression.expr_var_paramName': 'Enter a variable. Start the value with GLOBAL (for a global variable) or FLOW (for a flow variable).',
  'oifde.procedure.procedureMgr': 'Stored Procedures',
  'oifde.procedure.procedureDBMgr': 'Stored Procedure Databases',
  'oifde.procedure.procedureName': 'Stored Procedure',
  'oifde.procedure.procedureDesc': 'Description',
  'oifde.procedure.procedureValue': 'Value',
  'oifde.procedure.operation': 'Operation',
  'oifde.procedure.baseInfo': 'Basic Information',
  'oifde.procedure.in_param': 'Input Parameter',
  'oifde.procedure.paramName': 'Parameter',
  'oifde.procedure.paramDesc': 'Description',
  'oifde.procedure.dataType': 'Data Type',
  'oifde.procedure.out_param': 'Output Parameter',
  'oifde.procedure.addProcedureInterface': 'Add Stored Procedure',
  'oifde.procedure.modifyProcedureInterface': 'Modify Stored Procedure',
  'oifde.procedure.addProcedureDB': 'Add Stored Procedure Database',
  'oifde.procedure.modifyProcedureDB': 'Edit Stored Procedure Database',
  'oifde.procedure.required': 'This field is mandatory.',
  'oifde.procedure.urlRule': 'Url should start with "jdbc:mysql" or "jdbc:zenith".',
  'oifde.procedure.actions.editList': 'Edit Data Type in List',
  'oifde.procedure.actions.edit': 'Edit',
  'oifde.procedure.actions.delete': 'Delete',
  'oifde.procedure.actions.success': 'Success',
  'oifde.procedure.actions.createSuccess': 'Creation successful.',
  'oifde.procedure.actions.updateSuccess': 'Modification successful.',
  'oifde.procedure.actions.deleteSuccess': 'Deletion successful.',
  'oifde.procedure.actions.cancelDelete': 'The deletion is canceled.',
  'oifde.procedure.actions.deletePrompt': 'This operation will permanently delete the stored procedure. Are you sure you want to continue?',
  'oifde.procedure.actions.prompt': 'Confirm',
  'oifde.procedure.actions.confirm': 'Yes',
  'oifde.procedure.actions.cancel': 'No',
  'oifde.procedure.actions.ok': 'OK',
  'oifde.procedure.actions.miss_pro_name': 'The following mandatory field is empty: Basic Information > Stored Procedure Name.',
  'oifde.procedure.actions.miss_pro_value': 'The following mandatory field is empty: Basic Information > Stored Procedure Value.',
  'oifde.procedure.actions.miss_pro_in_parameter_name': 'The following mandatory field is empty: Input Parameter > Parameter Name.',
  'oifde.procedure.actions.miss_pro_out_parameter_name': 'The following mandatory field is empty: Output Parameter > Parameter Name.',
  'oifde.procedure.actions.miss_pro_dbId': 'The following mandatory field is empty: Basic Information > Database Connection.',
  'oifde.procedure.dataTypes.INT': 'Integer',
  'oifde.procedure.dataTypes.STRING': 'Character',
  'oifde.procedure.dataTypes.FLOAT': 'Floating-point number',
  'oifde.procedure.dataTypes.LONG': 'Long integer',
  'oifde.procedure.dataTypes.LIST': 'List',
  'oifde.procedure.db.dbType': 'Database Type',
  'oifde.procedure.db.dbDriver': 'Database Driver',
  'oifde.procedure.db.dbUrl': 'Database URL',
  'oifde.procedure.db.dbUser': 'Database User',
  'oifde.procedure.db.dbPassword': 'Database Password',
  'oifde.procedure.dbTypes.MYSQL': 'MySQL',
  'oifde.procedure.dbTypes.GAUSS': 'GaussDB',
  'oifde.procedure.dbTypes.ORACLE': 'Oracle',
  'oifde.procedure.ruleMysql': 'The MySQL database must start with jdbc:mysql://.',
  'oifde.procedure.ruleGauss': 'The GaussDB must start with jdbc:zenith:{\'@\'}.',
  'oifde.procedure.ruleOracle': 'The Oracle database name must start with jdbc:oracle:thin:{\'@\'}.',
  'oifde.procedure.ruleRange': 'The URL can contain only letters, digits, and the following special characters: . ? = : - _ & /. It can end only with a letter, digit, or slash (/).',
  'oifde.procedure.oracleRuleRange': 'The URL can contain only letters, digits, and the following special characters: . ? {\'@\'} = : - _ & /. It can end only with a letter, digit, or slash (/).',
  'oifde.procedure.driverRule': 'The value can contain only letters, digits, dots (.), and hyphens (-).',
  'oifde.procedure.driverStart': 'The value cannot start with a dot (.) or hyphen (-).',
  'oifde.procedure.driverEnd': 'The value cannot end with a dot (.) or hyphen (-).',
  'oifde.procedure.ruleLength': 'Enter the IP address and port number in the URL.',
  'oifde.procedure.dbUrlWarning': '*Note that the IP address, port number, or domain name in the database URL is not in the trustlist. Contact the system administrator to add the IP address, port number, or domain name.',
  'oifde.complexcell.cellTitle': 'Composite Diagram Element',
  'oifde.complexcell.catalogTitle': 'Composite Diagram Element Catalog',
  'oifde.complexcell.catalog.name': 'Catalog Name',
  'oifde.complexcell.catalog.desc': 'Catalog Description',
  'oifde.complexcell.catalog.tips.sizeLimit': 'A maximum of 100 catalogs are allowed.',
  'oifde.complexcell.cell.name': 'Diagram Element Name',
  'oifde.complexcell.cell.desc': 'Description',
  'oifde.complexcell.cell.height': 'Height',
  'oifde.complexcell.cell.width': 'Width',
  'oifde.complexcell.cell.icon': 'Symbol',
  'oifde.complexcell.cell.catalog': 'Catalog',
  'oifde.complexcell.cell.actions.editInfo': 'Modify Basic Info',
  'oifde.complexcell.cell.actions.editDiagram': 'Edit Canvas',
  'oifde.complexcell.cell.actions.copy': 'Replicate',
  'oifde.complexcell.cell.validation.bigFile': 'The size of the file cannot exceed {0}.',
  'oifde.complexcell.cell.viewReference': 'View References',
  'oifde.complexcell.cell.actions.copySuccess': 'Copying succeeded.',
  'oifde.table.search': 'Search',
  'oifde.table.add': 'Add',
  'oifde.table.actions': 'Actions',
  'oifde.table.cancel': 'Cancel',
  'oifde.table.confirm': 'Confirm',
  'oifde.table.create': 'Create',
  'oifde.table.delete': 'Delete',
  'oifde.table.manage': 'Manage',
  'oifde.table.save': 'Save',
  'oifde.table.reset': 'Reset',
  'oifde.table.filter': 'Filter',
  'oifde.tagsView.refresh': 'Refresh',
  'oifde.tagsView.close': 'Close',
  'oifde.tagsView.closeOthers': 'Close Others',
  'oifde.tagsView.closeAll': 'Close All',
  'oifde.mxgraph.failInfo': 'Failed to query the data!',
  'oifde.mxgraph.alreadyConnected': 'Nodes already connected',
  'oifde.mxgraph.cancel': 'Cancel',
  'oifde.mxgraph.close': 'Close',
  'oifde.mxgraph.collapse-expand': 'Collapse/Expand',
  'oifde.mxgraph.containsValidationErrors': 'Validation error found',
  'oifde.mxgraph.done': 'Done',
  'oifde.mxgraph.doubleClickOrientation': 'Double-click to Change Orientation',
  'oifde.mxgraph.error': 'Error',
  'oifde.mxgraph.errorSavingFile': 'Failed to save the file!',
  'oifde.mxgraph.ok': 'OK',
  'oifde.mxgraph.updatingDocument': 'Updating the document. Please wait...',
  'oifde.mxgraph.updatingSelection': 'Updating the selection. Please wait...',
  'oifde.mxgraph.about': 'About',
  'oifde.mxgraph.actualSize': 'Actual Size',
  'oifde.mxgraph.add': 'Add',
  'oifde.mxgraph.addLayer': 'Add Layer',
  'oifde.mxgraph.addProperty': 'Add Property',
  'oifde.mxgraph.addToExistingDrawing': 'Add to Existing Drawing',
  'oifde.mxgraph.addWaypoint': 'Add Waypoint',
  'oifde.mxgraph.advanced': 'Advanced',
  'oifde.mxgraph.align': 'Align',
  'oifde.mxgraph.alignment': 'Alignment',
  'oifde.mxgraph.allChangesLost': 'All changes will be lost.',
  'oifde.mxgraph.angle': 'Angle',
  'oifde.mxgraph.apply': 'Apply',
  'oifde.mxgraph.arc': 'Arc',
  'oifde.mxgraph.arrange': 'Arrange',
  'oifde.mxgraph.arrow': 'Arrow',
  'oifde.mxgraph.arrows': 'Arrows',
  'oifde.mxgraph.automatic': 'Automatic',
  'oifde.mxgraph.autosave': 'Autosave',
  'oifde.mxgraph.autosize': 'Autosize',
  'oifde.mxgraph.background': 'Background',
  'oifde.mxgraph.backgroundColor': 'Background Color',
  'oifde.mxgraph.backgroundImage': 'Background Image',
  'oifde.mxgraph.basic': 'Basic',
  'oifde.mxgraph.block': 'Block',
  'oifde.mxgraph.blockquote': 'Blockquote',
  'oifde.mxgraph.bold': 'Bold',
  'oifde.mxgraph.borderWidth': 'Borderwidth',
  'oifde.mxgraph.borderColor': 'Border Color',
  'oifde.mxgraph.bottom': 'Bottom',
  'oifde.mxgraph.bottomAlign': 'Bottom Align',
  'oifde.mxgraph.bottomLeft': 'Bottom Left',
  'oifde.mxgraph.bottomRight': 'Bottom Right',
  'oifde.mxgraph.bulletedList': 'Bulleted List',
  'oifde.mxgraph.cannotOpenFile': 'Cannot open the file.',
  'oifde.mxgraph.center': 'Vertical Alignment',
  'oifde.mxgraph.change': 'Change',
  'oifde.mxgraph.changeOrientation': 'Change Orientation',
  'oifde.mxgraph.circle': 'Circle',
  'oifde.mxgraph.classic': 'Classic',
  'oifde.mxgraph.clearDefaultStyle': 'Clear Default Style',
  'oifde.mxgraph.clearWaypoints': 'Clear Waypoints',
  'oifde.mxgraph.clipart': 'Clipart',
  'oifde.mxgraph.collapse': 'Collapse',
  'oifde.mxgraph.collapseExpand': 'Collapse/Expand',
  'oifde.mxgraph.collapsible': 'Collapsible',
  'oifde.mxgraph.comic': 'Comic',
  'oifde.mxgraph.connect': 'Connect',
  'oifde.mxgraph.connection': 'Connection',
  'oifde.mxgraph.connectionPoints': 'Connection points',
  'oifde.mxgraph.connectionArrows': 'Connection arrows',
  'oifde.mxgraph.constrainProportions': 'Constrain Proportions',
  'oifde.mxgraph.copy': 'Copy',
  'oifde.mxgraph.copyConnect': 'Copy Connection',
  'oifde.mxgraph.create': 'Create',
  'oifde.mxgraph.curved': 'Curved',
  'oifde.mxgraph.custom': 'Custom',
  'oifde.mxgraph.cut': 'Cut',
  'oifde.mxgraph.dashed': 'Dashed',
  'oifde.mxgraph.decreaseIndent': 'Decrease Indent',
  'oifde.mxgraph.default': 'Default',
  'oifde.mxgraph.delete': 'Delete',
  'oifde.mxgraph.deleteColumn': 'Delete Column',
  'oifde.mxgraph.deleteRow': 'Delete Row',
  'oifde.mxgraph.diagram': 'Diagram',
  'oifde.mxgraph.diamond': 'Diamond',
  'oifde.mxgraph.diamondThin': 'Diamond (thin)',
  'oifde.mxgraph.direction': 'Direction',
  'oifde.mxgraph.distribute': 'Distribute',
  'oifde.mxgraph.divider': 'Divider',
  'oifde.mxgraph.documentProperties': 'Document Properties',
  'oifde.mxgraph.dotted': 'Dotted',
  'oifde.mxgraph.drawing': 'Drawing{0}',
  'oifde.mxgraph.drawingEmpty': 'Drawing is empty.',
  'oifde.mxgraph.drawingTooLarge': 'The diagram is too large.',
  'oifde.mxgraph.duplicate': 'Duplicate',
  'oifde.mxgraph.duplicateIt': 'Duplicate {0}',
  'oifde.mxgraph.east': 'East',
  'oifde.mxgraph.edit': 'Edit',
  'oifde.mxgraph.editData': 'Edit Data',
  'oifde.mxgraph.editDiagram': 'Source',
  'oifde.mxgraph.editImage': 'Edit Image',
  'oifde.mxgraph.editLink': 'Edit Link',
  'oifde.mxgraph.editStyle': 'Edit Style',
  'oifde.mxgraph.editTooltip': 'Edit Tooltip',
  'oifde.mxgraph.enterGroup': 'Enter Group',
  'oifde.mxgraph.enterValue': 'Enter Value',
  'oifde.mxgraph.enterName': 'Enter Name',
  'oifde.mxgraph.enterPropertyName': 'Enter Property Name',
  'oifde.mxgraph.entityRelation': 'Entity Relationship',
  'oifde.mxgraph.exitGroup': 'Exit Group',
  'oifde.mxgraph.expand': 'Expand',
  'oifde.mxgraph.export': 'Export',
  'oifde.mxgraph.extras': 'Extras',
  'oifde.mxgraph.file': 'File',
  'oifde.mxgraph.fileNotFound': 'File not found',
  'oifde.mxgraph.filename': 'Filename',
  'oifde.mxgraph.fill': 'Fill',
  'oifde.mxgraph.fillColor': 'Fill Color',
  'oifde.mxgraph.fitPage': 'One Page',
  'oifde.mxgraph.fitPageWidth': 'Page Width',
  'oifde.mxgraph.fitTwoPages': 'Two Pages',
  'oifde.mxgraph.fitWindow': 'Fit Window',
  'oifde.mxgraph.flip': 'Flip',
  'oifde.mxgraph.flipH': 'Flip Horizontal',
  'oifde.mxgraph.flipV': 'Flip Vertical',
  'oifde.mxgraph.font': 'Font',
  'oifde.mxgraph.fontFamily': 'Font Family',
  'oifde.mxgraph.fontColor': 'Font Color',
  'oifde.mxgraph.fontSize': 'Font Size',
  'oifde.mxgraph.format': 'Format',
  'oifde.mxgraph.formatPanel': 'Format Panel',
  'oifde.mxgraph.formatPdf': 'PDF',
  'oifde.mxgraph.formatPng': 'PNG',
  'oifde.mxgraph.formatGif': 'GIF',
  'oifde.mxgraph.formatJpg': 'JPEG',
  'oifde.mxgraph.formatSvg': 'SVG',
  'oifde.mxgraph.formatXml': 'XML',
  'oifde.mxgraph.formatted': 'Formatted',
  'oifde.mxgraph.formattedText': 'Formatted Text',
  'oifde.mxgraph.gap': 'Gap',
  'oifde.mxgraph.general': 'General',
  'oifde.mxgraph.glass': 'Glass',
  'oifde.mxgraph.global': 'Global',
  'oifde.mxgraph.gradient': 'Gradient',
  'oifde.mxgraph.gradientColor': 'Color',
  'oifde.mxgraph.grid': 'Grid',
  'oifde.mxgraph.gridSize': 'Grid Size',
  'oifde.mxgraph.group': 'Group',
  'oifde.mxgraph.guides': 'Guides',
  'oifde.mxgraph.heading': 'Heading',
  'oifde.mxgraph.height': 'Height',
  'oifde.mxgraph.help': 'Help',
  'oifde.mxgraph.hide': 'Hide',
  'oifde.mxgraph.hideIt': 'Hide {0}',
  'oifde.mxgraph.hidden': 'Hidden',
  'oifde.mxgraph.home': 'Home',
  'oifde.mxgraph.horizontal': 'Horizontal',
  'oifde.mxgraph.horizontalFlow': 'Horizontal Flow',
  'oifde.mxgraph.horizontalTree': 'Horizontal Tree',
  'oifde.mxgraph.html': 'HTML',
  'oifde.mxgraph.image': 'Image',
  'oifde.mxgraph.images': 'Images',
  'oifde.mxgraph.import': 'Import',
  'oifde.mxgraph.increaseIndent': 'Increase Indent',
  'oifde.mxgraph.insert': 'Insert',
  'oifde.mxgraph.insertColumnBefore': 'Insert Column Left',
  'oifde.mxgraph.insertColumnAfter': 'Insert Column Right',
  'oifde.mxgraph.insertHorizontalRule': 'Insert Horizontal Rule',
  'oifde.mxgraph.insertImage': 'Insert Image',
  'oifde.mxgraph.insertLink': 'Insert Link',
  'oifde.mxgraph.insertRowBefore': 'Insert Row Above',
  'oifde.mxgraph.insertRowAfter': 'Insert Row Below',
  'oifde.mxgraph.invalidName': 'Invalid name',
  'oifde.mxgraph.invalidOrMissingFile': 'The file is missing or invalid.',
  'oifde.mxgraph.isometric': 'Isometric',
  'oifde.mxgraph.italic': 'Italic',
  'oifde.mxgraph.layers': 'Layers',
  'oifde.mxgraph.landscape': 'Landscape',
  'oifde.mxgraph.laneColor': 'Lanecolor',
  'oifde.mxgraph.layout': 'Layout',
  'oifde.mxgraph.left': 'Left',
  'oifde.mxgraph.leftAlign': 'Left Align',
  'oifde.mxgraph.leftToRight': 'Left to Right',
  'oifde.mxgraph.line': 'Line',
  'oifde.mxgraph.link': 'Link',
  'oifde.mxgraph.lineJumps': 'Line jumps',
  'oifde.mxgraph.lineend': 'Line End',
  'oifde.mxgraph.lineheight': 'Line Height',
  'oifde.mxgraph.linestart': 'Line Start',
  'oifde.mxgraph.linewidth': 'Linewidth',
  'oifde.mxgraph.loading': 'Loading',
  'oifde.mxgraph.lockUnlock': 'Lock/Unlock',
  'oifde.mxgraph.manual': 'Manual',
  'oifde.mxgraph.middle': 'Horizontal Alignment',
  'oifde.mxgraph.misc': 'Misc',
  'oifde.mxgraph.more': 'More',
  'oifde.mxgraph.moreResults': 'More Results',
  'oifde.mxgraph.move': 'Move',
  'oifde.mxgraph.moveSelectionTo': 'Move Selection to {0}',
  'oifde.mxgraph.navigation': 'Navigation',
  'oifde.mxgraph.new': 'New',
  'oifde.mxgraph.noColor': 'No Color.',
  'oifde.mxgraph.noFiles': 'No files.',
  'oifde.mxgraph.noMoreResults': 'No more results.',
  'oifde.mxgraph.none': 'None',
  'oifde.mxgraph.noResultsFor': 'No results for \'{0}\'',
  'oifde.mxgraph.normal': 'Normal',
  'oifde.mxgraph.north': 'North',
  'oifde.mxgraph.numberedList': 'Numbered List',
  'oifde.mxgraph.opacity': 'Opacity',
  'oifde.mxgraph.open': 'Open',
  'oifde.mxgraph.openArrow': 'Open Arrow',
  'oifde.mxgraph.openFile': 'Open File',
  'oifde.mxgraph.openLink': 'Open Link',
  'oifde.mxgraph.openSupported': 'Only XML files exported from this system are supported.',
  'oifde.mxgraph.openInNewWindow': 'Open in New Window',
  'oifde.mxgraph.openInThisWindow': 'Open in this Window',
  'oifde.mxgraph.options': 'Options',
  'oifde.mxgraph.organic': 'Organic',
  'oifde.mxgraph.orthogonal': 'Orthogonal',
  'oifde.mxgraph.outline': 'Outline',
  'oifde.mxgraph.oval': 'Oval',
  'oifde.mxgraph.pages': 'Pages',
  'oifde.mxgraph.pageView': 'Page View',
  'oifde.mxgraph.pageScale': 'Page Scale',
  'oifde.mxgraph.pageSetup': 'Page Setup',
  'oifde.mxgraph.panTooltip': 'Space+Drag to Scroll',
  'oifde.mxgraph.paperSize': 'Paper Size',
  'oifde.mxgraph.paste': 'Paste',
  'oifde.mxgraph.pasteHere': 'Paste Here',
  'oifde.mxgraph.pattern': 'Pattern',
  'oifde.mxgraph.perimeter': 'Perimeter',
  'oifde.mxgraph.placeholders': 'Placeholder',
  'oifde.mxgraph.plusTooltip': 'Click to connect and clone (ctrl+click to clone, shift+click to connect). Drag to connect (ctrl+drag to clone).',
  'oifde.mxgraph.portrait': 'Portrait',
  'oifde.mxgraph.position': 'Position',
  'oifde.mxgraph.posterPrint': 'Poster Print',
  'oifde.mxgraph.preview': 'Preview',
  'oifde.mxgraph.print': 'Print',
  'oifde.mxgraph.radialTree': 'Radial Tree',
  'oifde.mxgraph.redo': 'Redo',
  'oifde.mxgraph.remindSave': 'You have not saved data for a long time. Save the data promptly.',
  'oifde.mxgraph.removeFormat': 'Clear Formatting',
  'oifde.mxgraph.removeFromGroup': 'Remove from Group',
  'oifde.mxgraph.removeIt': 'Remove {0}',
  'oifde.mxgraph.removeWaypoint': 'Remove Waypoint',
  'oifde.mxgraph.rename': 'Rename',
  'oifde.mxgraph.renameIt': 'Rename {0}',
  'oifde.mxgraph.replace': 'Replace',
  'oifde.mxgraph.replaceIt': '{0} already exists. Do you want to replace it?',
  'oifde.mxgraph.replaceExistingDrawing': 'Replace existing drawing',
  'oifde.mxgraph.reset': 'Reset',
  'oifde.mxgraph.resetView': 'Reset View',
  'oifde.mxgraph.reverse': 'Reverse',
  'oifde.mxgraph.right': 'Right',
  'oifde.mxgraph.rightAlign': 'Right Align',
  'oifde.mxgraph.rightToLeft': 'Right to Left',
  'oifde.mxgraph.rotate': 'Rotate',
  'oifde.mxgraph.rotateTooltip': 'Click and drag to rotate, and click to turn by 90 degrees',
  'oifde.mxgraph.rotation': 'Rotation',
  'oifde.mxgraph.rounded': 'Rounded',
  'oifde.mxgraph.save': 'Save',
  'oifde.mxgraph.saveAs': 'Save as',
  'oifde.mxgraph.saved': 'Saved',
  'oifde.mxgraph.scrollbars': 'Scrollbars',
  'oifde.mxgraph.search': 'Search',
  'oifde.mxgraph.searchShapes': 'Search Nodes',
  'oifde.mxgraph.selectAll': 'Select All',
  'oifde.mxgraph.selectEdges': 'Select Edges',
  'oifde.mxgraph.selectFont': 'Select Font',
  'oifde.mxgraph.selectNone': 'Select None',
  'oifde.mxgraph.selectVertices': 'Select Vertices',
  'oifde.mxgraph.setAsDefaultStyle': 'Set as Default Style',
  'oifde.mxgraph.shadow': 'Shadow',
  'oifde.mxgraph.shape': 'Shape',
  'oifde.mxgraph.sharp': 'Sharp',
  'oifde.mxgraph.sidebarTooltip': 'Click to expand or collapse the node panel.',
  'oifde.mxgraph.simple': 'Simple',
  'oifde.mxgraph.simpleArrow': 'Simple Arrow',
  'oifde.mxgraph.size': 'Size',
  'oifde.mxgraph.solid': 'Solid',
  'oifde.mxgraph.sourceSpacing': 'Source Spacing',
  'oifde.mxgraph.south': 'South',
  'oifde.mxgraph.spacing': 'Spacing',
  'oifde.mxgraph.straight': 'Straight',
  'oifde.mxgraph.strokeColor': 'Line Color',
  'oifde.mxgraph.style': 'Style',
  'oifde.mxgraph.subscript': 'Subscript',
  'oifde.mxgraph.superscript': 'Superscript',
  'oifde.mxgraph.table': 'Table',
  'oifde.mxgraph.targetSpacing': 'Target Spacing',
  'oifde.mxgraph.text': 'Text',
  'oifde.mxgraph.textAlignment': 'Text Alignment',
  'oifde.mxgraph.textOpacity': 'Text Opacity',
  'oifde.mxgraph.toBack': 'To Back',
  'oifde.mxgraph.toFront': 'To Front',
  'oifde.mxgraph.tooltips': 'Tooltips',
  'oifde.mxgraph.top': 'Top',
  'oifde.mxgraph.topAlign': 'Top Align',
  'oifde.mxgraph.topLeft': 'Top Left',
  'oifde.mxgraph.topRight': 'Top Right',
  'oifde.mxgraph.transparent': 'Transparent',
  'oifde.mxgraph.turn': 'Rotate 90 Degrees',
  'oifde.mxgraph.uml': 'UML',
  'oifde.mxgraph.underline': 'Underline',
  'oifde.mxgraph.undo': 'Undo',
  'oifde.mxgraph.ungroup': 'Ungroup',
  'oifde.mxgraph.url': 'URL',
  'oifde.mxgraph.untitledLayer': 'Untitled Layer',
  'oifde.mxgraph.vertical': 'Vertical',
  'oifde.mxgraph.verticalFlow': 'Vertical Flow',
  'oifde.mxgraph.verticalTree': 'Vertical Tree',
  'oifde.mxgraph.view': 'View',
  'oifde.mxgraph.waypoints': 'Waypoints',
  'oifde.mxgraph.west': 'West',
  'oifde.mxgraph.width': 'Width',
  'oifde.mxgraph.wordWrap': 'Word Wrap',
  'oifde.mxgraph.writingDirection': 'Writing Direction',
  'oifde.mxgraph.zoom': 'Zoom',
  'oifde.mxgraph.zoomIn': 'Zoom In',
  'oifde.mxgraph.zoomOut': 'Zoom Out',
  'oifde.mxgraph.success': 'Success',
  'oifde.mxgraph.prompt': 'Prompt',
  'oifde.mxgraph.confirm': 'OK',
  'oifde.mxgraph.NMS_DiagramProperties': 'Process Attribute',
  'oifde.mxgraph.NMS_BasicProperties': 'Basic Attribute',
  'oifde.mxgraph.NMS_DiagramID': 'Process ID',
  'oifde.mxgraph.NMS_Status': 'Status',
  'oifde.mxgraph.NMS_DiagramCode': 'Process Code',
  'oifde.mxgraph.NMS_Valid': 'Valid',
  'oifde.mxgraph.NMS_Invalid': 'Invalid',
  'oifde.mxgraph.NMS_DiagramName': 'Process Name',
  'oifde.mxgraph.NMS_Region': 'Region',
  'oifde.mxgraph.NMS_ScenarioType': 'Scenario Type',
  'oifde.mxgraph.NMS_Desciption': 'Description',
  'oifde.mxgraph.NMS_NodeProperties': 'Node Attribute',
  'oifde.mxgraph.NMS_NodeCode': 'Node Code',
  'oifde.mxgraph.NMS_NodeName': 'Node Name',
  'oifde.mxgraph.NMS_BranchProperties': 'Branch Attribute',
  'oifde.mxgraph.NMS_BranchCondition': 'Branch Condition',
  'oifde.mxgraph.NMS_DefaultBranch': 'Default Branch',
  'oifde.mxgraph.NMS_AdvanceProperties': 'Advanced Attribute',
  'oifde.mxgraph.NMS_ServiceName': 'Service Name',
  'oifde.mxgraph.NMS_ServiceParams': 'Service Parameter',
  'oifde.mxgraph.NMS_ServiceDesciption': 'Service Description',
  'oifde.mxgraph.NMS_PleaseSelect': 'Please select',
  'oifde.mxgraph.NMS_ParamName': 'Parameter',
  'oifde.mxgraph.NMS_ParamValue': 'Value',
  'oifde.mxgraph.NMS_ParamDescription': 'Description',
  'oifde.mxgraph.NMS_FlowSaveSuccess': 'The flow is saved successfully!',
  'oifde.mxgraph.NMS_FlowSaveFail': 'Failed to save the flow!',
  'oifde.mxgraph.NMS_FlowOpenFail': 'Failed to open the flow!',
  'oifde.mxgraph.NMS_ActionTypesGetFail': 'Failed to obtain the diagram element information!',
  'oifde.mxgraph.NMS_ActionParamsGetFail': 'Failed to obtain diagram element parameters!',
  'oifde.mxgraph.NMS_FlowsGetFail': 'Failed to obtain the flow list!',
  'oifde.mxgraph.NMS_NodesGetFail': 'Failed to obtain the node list of flow {0}!',
  'oifde.mxgraph.NMS_InterfacesGetFail': 'Failed to obtain the access interface list!',
  'oifde.mxgraph.NMS_ContentTemplateGetFail': 'Failed to obtain the content template list!',
  'oifde.mxgraph.NMS_EditorModelLoadFail': 'Failed to load the editor model configuration!',
  'oifde.mxgraph.NMS_RegionInfoLoadFail': 'Failed to load region information!',
  'oifde.mxgraph.NMS_IntentCodeLoadFail': 'Failed to load the intention code!',
  'oifde.mxgraph.NMS_PleaseSelectTemplate': 'Select a template.',
  'oifde.mxgraph.NMS_TargetFlow': 'Target Flow',
  'oifde.mxgraph.NMS_TargetNode': 'Target Node',
  'oifde.mxgraph.NMS_TemplateCode': 'Template Code',
  'oifde.mxgraph.NMS_flow': 'Flow',
  'oifde.mxgraph.NMS_ScenarioType_main': 'Main flow',
  'oifde.mxgraph.NMS_ScenarioType_sub': 'Sub flow',
  'oifde.mxgraph.NMS_ScenarioType_error': 'Exception scenario',
  'oifde.mxgraph.NMS_flowCodeError': 'The flow code cannot be empty or exceed 64 characters.',
  'oifde.mxgraph.NMS_flowNameError': 'The flow name cannot be empty or exceed 1000 characters.',
  'oifde.mxgraph.NMS_flowDescError': 'The flow description can contain a maximum of 1000 characters.',
  'oifde.mxgraph.NMS_nmsFlowError': 'Flow parsing failed! The nmsFlow node is not found.',
  'oifde.mxgraph.NMS_rootError': 'Flow parsing failed! The root node is not found.',
  'oifde.mxgraph.NMS_VariableNameError': 'The variable name cannot contain more than 1000 characters.',
  'oifde.mxgraph.NMS_VariableDescError': 'The variable description cannot contain more than 1000 characters.',
  'oifde.mxgraph.NMS_VariableDefaultValueError': 'The default value of the variable cannot contain more than 1024 characters.',
  'oifde.mxgraph.NMS_NodeCodeError': 'The node code cannot be empty or exceed 64 characters.',
  'oifde.mxgraph.NMS_NodeNameError': 'The node name cannot be empty or exceed 1000 characters.',
  'oifde.mxgraph.NMS_NodeDescError': 'The node description can contain a maximum of 1000 characters.',
  'oifde.mxgraph.NMS_actionParamNameError': 'The parameter name can contain a maximum of 64 characters.',
  'oifde.mxgraph.NMS_actionParamValueEmptyError': 'The value cannot be empty.',
  'oifde.mxgraph.NMS_actionParamValueError': 'The parameter value can contain a maximum of 4096 characters.',
  'oifde.mxgraph.NMS_actionParamDescError': 'The parameter description cannot contain more than 500 characters.',
  'oifde.mxgraph.NMS_transitToStartNodeError': 'Failed to jump to the start node!',
  'oifde.mxgraph.NMS_endNodetransitToError': 'The end node cannot jump to another node.',
  'oifde.mxgraph.NMS_startNodeNotFind': 'The start node is not found.',
  'oifde.mxgraph.NMS_startNodeNotUnique': 'The start node is not unique.',
  'oifde.mxgraph.NMS_startNodeNotBranchError': 'The start node can have only one branch.',
  'oifde.mxgraph.NMS_endNodeNotFind': 'The end node is not found.',
  'oifde.mxgraph.NMS_analiseXmlFailed': 'Failed to parse the flow canvas!',
  'oifde.mxgraph.NMS_branchError': 'The branch must have the start node and end node.',
  'oifde.mxgraph.NMS_nullBranchNotNuique': 'A node can have only one default branch.',
  'oifde.mxgraph.NMS_checkDefaultNextNodeForView': 'The diagram element of the view type cannot have more than one branch.',
  'oifde.mxgraph.NMS_nodeRefError': 'The node is referenced by another flow and cannot be deleted.',
  'oifde.mxgraph.NMS_ValidateError': 'Validation failed! Check the parts marked in red.',
  'oifde.mxgraph.saveAndNopublish': 'The flow is saved successfully but failed to be published!',
  'oifde.mxgraph.publishSuccess': 'The flow is saved and published successfully!',
  'oifde.mxgraph.publishFailed': 'Failed to save and publish the flow!',
  'oifde.mxgraph.saveAndPublish': 'Save and Publish',
  'oifde.mxgraph.NMS_SpaceValidate': 'The value cannot start with a space.',
  'oifde.mxgraph.NMS_KeyRepeats': 'Duplicate condition name.',
  'oifde.mxgraph.NMS_encryptValidate': 'The encrypted IVRREQUEST variable, FLOW variable, and GLOBAL variable can be assigned only to encrypted FLOW variables and GLOBAL variables.',
  'oifde.mxgraph.NMS_timeSelectLengthValidate': 'At least one record must be configured for the service period.',
  'oifde.knowledgegraph.actions.edit': 'Edit',
  'oifde.knowledgegraph.actions.view': 'View',
  'oifde.knowledgegraph.actions.delete': 'Delete',
  'oifde.knowledgegraph.actions.clean': 'Clear',
  'oifde.knowledgegraph.actions.prompt': 'Information',
  'oifde.knowledgegraph.actions.confirm': 'OK',
  'oifde.knowledgegraph.actions.nameRequiredTip': 'Enter a name.',
  'oifde.knowledgegraph.actions.selectTip': '-Select-',
  'oifde.knowledgegraph.actions.cancel': 'Cancel',
  'oifde.knowledgegraph.actions.cancelDelete': 'The deletion is canceled.',
  'oifde.knowledgegraph.actions.success': 'Success',
  'oifde.knowledgegraph.actions.updateSuccess': 'Modification successful!',
  'oifde.knowledgegraph.actions.deleteSuccess': 'Deletion successful!',
  'oifde.knowledgegraph.actions.createSuccess': 'Adding successful!',
  'oifde.knowledgegraph.actions.createFailed': 'Adding failed!',
  'oifde.knowledgegraph.actions.modifyFailed': 'Modification failed!',
  'oifde.knowledgegraph.actions.deleteFailed': 'Deletion failed!',
  'oifde.knowledgegraph.actions.saveSuccess': 'Saving successful!',
  'oifde.knowledgegraph.actions.saveFailed': 'Saving failed!',
  'oifde.knowledgegraph.actions.addSchema': 'Add Schema',
  'oifde.knowledgegraph.actions.editSchema': 'Edit Schema',
  'oifde.knowledgegraph.actions.addDic': 'Add Graph Dictionary',
  'oifde.knowledgegraph.actions.editDic': 'Edit Graph Dictionary',
  'oifde.knowledgegraph.actions.addEntity': 'Add Entity',
  'oifde.knowledgegraph.actions.editEntity': 'Edit Entity',
  'oifde.knowledgegraph.actions.addRelation': 'Add Relationship',
  'oifde.knowledgegraph.actions.editRelation': 'Edit Relationship',
  'oifde.knowledgegraph.actions.addAttribute': 'Add Attribute',
  'oifde.knowledgegraph.actions.editAttribute': 'Edit Attribute',
  'oifde.knowledgegraph.actions.addSynonyms': 'Add Synonym',
  'oifde.knowledgegraph.actions.editSynonyms': 'Edit Synonym',
  'oifde.knowledgegraph.actions.addExtQuestion': 'New Extended Question',
  'oifde.knowledgegraph.actions.editExtQuestion': 'Edit Extended Question',
  'oifde.knowledgegraph.actions.viewSchema': 'View Schema',
  'oifde.knowledgegraph.actions.graphicPreview': 'View Graph',
  'oifde.knowledgegraph.actions.addSchemaEntity': 'Add Concept',
  'oifde.knowledgegraph.actions.editSchemaEntity': 'Edit Concept',
  'oifde.knowledgegraph.model.schemaName': 'Schema Name',
  'oifde.knowledgegraph.model.schemaCode': 'Schema Code',
  'oifde.knowledgegraph.model.schemaDescription': 'Description',
  'oifde.knowledgegraph.model.modifyTime': 'Modified',
  'oifde.knowledgegraph.model.operation': 'Operation',
  'oifde.knowledgegraph.model.createUser': 'Created By',
  'oifde.knowledgegraph.model.beforeDeletePrompt': 'The schema cannot be deleted because it is associated with the following graph dictionary:',
  'oifde.knowledgegraph.model.beforeEditPrompt': 'The current schema cannot be modified because it is associated with the following graph dictionary:',
  'oifde.knowledgegraph.model.deletePrompt': 'This operation will permanently delete the schema and its related concepts, relationships, and attributes. Are you sure you want to continue?',
  'oifde.knowledgegraph.editSchema.entity': 'Concept',
  'oifde.knowledgegraph.editSchema.entityName': 'Concept Name',
  'oifde.knowledgegraph.editSchema.relation': 'Relationship',
  'oifde.knowledgegraph.editSchema.relationName': 'Relationship Name',
  'oifde.knowledgegraph.editSchema.headEntity': 'Head Concept',
  'oifde.knowledgegraph.editSchema.tailEntity': 'Tail Concept',
  'oifde.knowledgegraph.editSchema.selectTip': '---Select---',
  'oifde.knowledgegraph.editSchema.objectName': 'Concept/Relationship',
  'oifde.knowledgegraph.editSchema.attributeName': 'Attribute Name',
  'oifde.knowledgegraph.editSchema.attributeType': 'Attribute Type',
  'oifde.knowledgegraph.editSchema.unit': 'Unit',
  'oifde.knowledgegraph.editSchema.type': 'Type',
  'oifde.knowledgegraph.editSchema.schemaNoDataTip': 'Add concept and relationship data in the left pane. To view its attribute content, click View on the right of the corresponding concept or relationship.',
  'oifde.knowledgegraph.editSchema.deleteEntityPrompt': 'This operation will permanently delete the concept and its attributes. Are you sure you want to continue?',
  'oifde.knowledgegraph.editSchema.deleteRelationPrompt': 'This operation will permanently delete the relationship and its attributes. Are you sure you want to continue?',
  'oifde.knowledgegraph.editSchema.deleteAttributePrompt': 'This operation will permanently delete the attribute. Are you sure you want to continue the operation?',
  'oifde.knowledgegraph.editSchema.dataType.int': 'Integer',
  'oifde.knowledgegraph.editSchema.dataType.float': 'Floating-point number',
  'oifde.knowledgegraph.editSchema.dataType.boolean': 'Boolean',
  'oifde.knowledgegraph.editSchema.dataType.string': 'Character',
  'oifde.knowledgegraph.editSchema.dataType.date': 'Date',
  'oifde.knowledgegraph.dicmgr.dicName': 'Graph Name',
  'oifde.knowledgegraph.dicmgr.dicCode': 'Graph Code',
  'oifde.knowledgegraph.dicmgr.dicFunction': 'Graph Usage',
  'oifde.knowledgegraph.dicmgr.modifyTime': 'Modified',
  'oifde.knowledgegraph.dicmgr.schemaName': 'Associated Schema',
  'oifde.knowledgegraph.dicmgr.kgState': 'Status',
  'oifde.knowledgegraph.dicmgr.operation': 'Operation',
  'oifde.knowledgegraph.dicmgr.moreOperation': 'More',
  'oifde.knowledgegraph.dicmgr.entityMgr': 'Manage Entity',
  'oifde.knowledgegraph.dicmgr.relationMgr': 'Relationship Management',
  'oifde.knowledgegraph.dicmgr.kgView': 'Graph Visualization',
  'oifde.knowledgegraph.dicmgr.kgFAQ': 'Graph FAQ',
  'oifde.knowledgegraph.dicmgr.active': 'Active',
  'oifde.knowledgegraph.dicmgr.inactive': 'Inactive',
  'oifde.knowledgegraph.dicmgr.creating': 'Creating',
  'oifde.knowledgegraph.dicmgr.trainAll': 'Train All',
  'oifde.knowledgegraph.dicmgr.trainSuccess': 'The training is successful!',
  'oifde.knowledgegraph.dicmgr.trainFail': 'Training failed!',
  'oifde.knowledgegraph.dicmgr.changeState': 'Modify Status',
  'oifde.knowledgegraph.dicmgr.synonyms': 'Synonym',
  'oifde.knowledgegraph.dicmgr.extendQuestion': 'Extended Question',
  'oifde.knowledgegraph.dicmgr.deletePrompt': 'This operation will permanently delete the dictionary and its related entities, relationships, and attributes. Are you sure you want to continue?',
  'oifde.knowledgegraph.entitymgr.entityName': 'Entity Name',
  'oifde.knowledgegraph.entitymgr.schemaEntityName': 'Associated Schema Concept',
  'oifde.knowledgegraph.entitymgr.beforeDeletePrompt': 'Delete the relationship of the entity and then delete the entity.',
  'oifde.knowledgegraph.entitymgr.deletePrompt': 'This operation will permanently delete the entity. Are you sure you want to continue?',
  'oifde.knowledgegraph.entitymgr.entityAttributeMgr.deletePrompt': 'This operation will permanently delete the entity attribute. Are you sure you want to continue?',
  'oifde.knowledgegraph.relationmgr.headEntity': 'Head Entity',
  'oifde.knowledgegraph.relationmgr.tailEntity': 'Tail Entity',
  'oifde.knowledgegraph.relationmgr.relation': 'Relationship',
  'oifde.knowledgegraph.relationmgr.doubleRelation': 'Two-Way Relationship',
  'oifde.knowledgegraph.relationmgr.doubleRelationTrue': 'Yes',
  'oifde.knowledgegraph.relationmgr.doubleRelationFalse': 'No',
  'oifde.knowledgegraph.relationmgr.doublePrompt': 'The relationship cannot be established because the head and tail entities are the same. Select another head or tail entity.',
  'oifde.knowledgegraph.relationmgr.deletePrompt': 'This operation will permanently delete the relationship and its related attributes. Are you sure you want to continue?',
  'oifde.knowledgegraph.attributemgr.attributeName': 'Attribute Name',
  'oifde.knowledgegraph.attributemgr.attribute': 'Attribute Value',
  'oifde.knowledgegraph.attributemgr.description': 'Attribute Description',
  'oifde.knowledgegraph.attributemgr.deletePrompt': 'This operation will permanently delete the attribute. Are you sure you want to continue?',
  'oifde.knowledgegraph.kgFAQ.title': 'Graph FAQ',
  'oifde.knowledgegraph.kgFAQ.name': 'Graph Dictionary:',
  'oifde.knowledgegraph.kgFAQ.welcomeContent': 'Hi! How can I help you today?',
  'oifde.knowledgegraph.kgSynonyms.keyword': 'Name',
  'oifde.knowledgegraph.kgSynonyms.synonyms': 'Synonym',
  'oifde.knowledgegraph.kgSynonyms.number': 'No.',
  'oifde.knowledgegraph.kgSynonyms.synonymsDetail': 'Synonym Details',
  'oifde.knowledgegraph.kgSynonyms.name': 'Name',
  'oifde.knowledgegraph.kgSynonyms.deletePrompt': 'This operation will permanently delete the entry and all its synonyms. Are you sure you want to continue?',
  'oifde.knowledgegraph.kgSynonyms.deleteSynonymsPrompt': 'This operation will permanently delete the synonym. Are you sure you want to continue?',
  'oifde.knowledgegraph.kgSynonyms.type.type': 'Type',
  'oifde.knowledgegraph.kgSynonyms.type.entity': 'Entity',
  'oifde.knowledgegraph.kgSynonyms.type.relation': 'Relationship',
  'oifde.knowledgegraph.kgSynonyms.type.property': 'Attribute',
  'oifde.knowledgegraph.kgView.queryType': 'Query Type',
  'oifde.knowledgegraph.kgView.entityName': 'Entity Name',
  'oifde.knowledgegraph.kgView.displayType': 'Display Mode',
  'oifde.knowledgegraph.kgView.queryByName': 'By entity name',
  'oifde.knowledgegraph.validation.schemaName': 'Enter a schema name.',
  'oifde.knowledgegraph.validation.dicName': 'Enter a dictionary name.',
  'oifde.knowledgegraph.validation.selectRequired': 'This field is mandatory.',
  'oifde.knowledgegraph.validation.attrValue': 'Enter an attribute value.',
  'oifde.knowledgegraph.validation.required': 'The value is mandatory.',
  'oifde.knowledgegraph.errorCode.100010000000': 'System error.',
  'oifde.knowledgegraph.errorCode.100010000003': 'The name must be unique.',
  'oifde.knowledgegraph.errorCode.100010000002': 'Format error.',
  'oifde.knowledgegraph.errorCode.100051001013': 'This schema is currently in use.',
  'oifde.knowledgegraph.errorCode.100051001012': 'A synonym has already been configured for the name.',
  'oifde.knowledgegraph.errorCode.100010000001': 'Database error.',
  'oifde.knowledgegraph.errorCode.100051000110': 'The number has reached the upper limit of 10.',
  'oifde.knowledgegraph.errorCode.100051000111': 'The number has reached the upper limit of 190.',
  'oifde.knowledgegraph.errorCode.100051000113': 'The URL configured for the GES service is incorrect.',
  'oifde.knowledgegraph.errorCode.100051000112': 'Preparing the graph...Please wait.',
  'oifde.knowledgegraph.errorCode.100061000005': 'The knowledge graph is bound successfully!',
  'oifde.knowledgegraph.errorCode.100010000005': 'The input parameter is incorrect.',
  'oifde.knowledgegraph.errorCode.100051000114': 'Failed to save the extended question for the graph FAQ!',
  'oifde.knowledgegraph.errorCode.100061000006': 'Failed to connect to the ODFS!',
  'oifde.knowledgegraph.extendFaq.setting': 'Set Extended Question',
  'oifde.knowledgegraph.extendFaq.import': 'Import',
  'oifde.knowledgegraph.extendFaq.export': 'Export All',
  'oifde.knowledgegraph.extendFaq.extendFaq': 'FAQ Template List',
  'oifde.knowledgegraph.extendFaq.faqTemplate': 'FAQ Template',
  'oifde.knowledgegraph.extendFaq.description': 'Description',
  'oifde.knowledgegraph.extendFaq.updateTime': 'Updated',
  'oifde.knowledgegraph.extendFaq.status': 'Status',
  'oifde.knowledgegraph.extendFaq.setTemplate': 'Set Extended Question',
  'oifde.knowledgegraph.extendFaq.use': 'Use/Not Use',
  'oifde.knowledgegraph.extendFaq.question': 'Question',
  'oifde.knowledgegraph.extendFaq.reply': 'Reply',
  'oifde.knowledgegraph.extendFaq.similarQuestion': 'Similar Question',
  'oifde.knowledgegraph.extendFaq.similarReply': 'Similar Reply',
  'oifde.knowledgegraph.extendFaq.edit': 'Modify -',
  'oifde.knowledgegraph.extendFaq.inUse': 'In use',
  'oifde.knowledgegraph.extendFaq.notUse': 'Not in use',
  'oifde.knowledgegraph.extendFaq.operation': 'Operation',
  'oifde.knowledgegraph.extendFaq.updateSuccess': 'The status is changed successfully!',
  'oifde.knowledgegraph.extendFaq.updateFailed': 'Failed to change the status!',
  'oifde.knowledgegraph.extendFaq.updateTemplateSuccess': 'The FAQ template is modified successfully!',
  'oifde.knowledgegraph.extendFaq.updateTemplateFailed': 'Failed to modify the FAQ template!',
  'oifde.whitelist.tenantId': 'Tenant Space ID',
  'oifde.whitelist.tenantSpace': 'Tenant Space',
  'oifde.whitelist.whiteIp': 'Trustlist IP Address or domain name',
  'oifde.whitelist.whitePort': 'Trustlist Port',
  'oifde.whitelist.whiteType': 'Trustlist Type',
  'oifde.whitelist.description': 'Description',
  'oifde.whitelist.whiteTypes.INTERFACE': 'Interface',
  'oifde.whitelist.whiteTypes.PROCEDURE': 'Stored Procedure',
  'oifde.whitelist.addWhiteList': 'Add Trustlist',
  'oifde.whitelist.editWhiteList': 'Edit Trustlist',
  'oifde.whitelist.whiteIpLen': 'The trustlist IP address or domain name  can contain a maximum of 128 characters.',
  'oifde.whitelist.whitePortLen': 'The trustlist port number can contain a maximum of 20 characters.',
  'oifde.whitelist.descriptionLen': 'The description can contain a maximum of 1024 characters.',
  'oifde.whitelist.number': 'The value must be digits.',
  'oifde.whitelist.whiteIpListNotNull': 'Select at least one trustlist to be deleted.',
  'oifde.whitelist.deletePrompt': 'This operation will permanently delete the selected trustlist. Are you sure you want to continue?',
  'oifde.whitelist.ipRange': 'The IP address can contain only letters, digits, dots (.), underscores (_), and hyphens (-). It cannot start or end with a hyphen.',
  'oifde.operationlog.generate_test_case': 'Automatic case generation',
  'oifde.operationlog.stop_auto_generate': 'Interrupted case generation',
  'oifde.operationlog.logging_module': 'Module',
  'oifde.operationlog.operation_log': 'Operation Log',
  'oifde.operationlog.operator': 'Account',
  'oifde.operationlog.operation_obj_type': 'Operation Object Type',
  'oifde.operationlog.startTime': 'Start Time',
  'oifde.operationlog.endTime': 'End Time',
  'oifde.operationlog.operationType': 'Operation Type',
  'oifde.operationlog.targetObjType': 'Object Type',
  'oifde.operationlog.targetObjId': 'Object ID',
  'oifde.operationlog.targetObjName': 'Object',
  'oifde.operationlog.result': 'Result',
  'oifde.operationlog.detail': 'Details',
  'oifde.operationlog.operationTime': 'Last Operation',
  'oifde.operationlog.loginIp': 'Sign-In IP Address',
  'oifde.operationlog.check': 'Validate',
  'oifde.operationlog.query': 'Query',
  'oifde.operationlog.create': 'Create',
  'oifde.operationlog.copy': 'Copy',
  'oifde.operationlog.paste': 'Paste',
  'oifde.operationlog.clone': 'Clone',
  'oifde.operationlog.import': 'Import',
  'oifde.operationlog.export': 'Export',
  'oifde.operationlog.update': 'Update',
  'oifde.operationlog.delete': 'Delete',
  'oifde.operationlog.enable': 'Activate',
  'oifde.operationlog.disable': 'Deactivate',
  'oifde.operationlog.set_test': 'Set to Test',
  'oifde.operationlog.hang_up': 'Hang Up',
  'oifde.operationlog.release': 'Publish',
  'oifde.operationlog.invalidate': 'Invalidate',
  'oifde.operationlog.upgrade': 'Upgrade',
  'oifde.operationlog.set_white_list': 'Set gray rules',
  'oifde.operationlog.intent_template': 'Intention template',
  'oifde.operationlog.user_entity': 'User entity',
  'oifde.operationlog.system_entity': 'System entity',
  'oifde.operationlog.voice_content': 'Voice file',
  'oifde.operationlog.system_parameters': 'System parameter',
  'oifde.operationlog.intent_parameters': 'Intention parameter',
  'oifde.operationlog.call_session': 'Call dialog',
  'oifde.operationlog.cluster_task': 'Clustering task',
  'oifde.operationlog.history_dialogue': 'Historical dialog',
  'oifde.operationlog.test_case_group': 'Test case group',
  'oifde.operationlog.test_case': 'Test case',
  'oifde.operationlog.faq': 'FAQ group',
  'oifde.operationlog.faq_element': 'Q&A',
  'oifde.operationlog.log_parameters': 'Log parameter',
  'oifde.operationlog.tps_interface': 'Service interface',
  'oifde.operationlog.nms_interface': 'Dialog process interface',
  'oifde.operationlog.white_list': 'Knowledge gray rule',
  'oifde.operationlog.white_list_users': 'Subscriber number in knowledge gray rule',
  'oifde.operationlog.sensitive_words': 'Sensitive word',
  'oifde.operationlog.stop_words': 'Stop word',
  'oifde.operationlog.navigation_info': 'Navigation information',
  'oifde.operationlog.command': 'Command',
  'oifde.operationlog.api_key': 'API key',
  'oifde.operationlog.trace_log': 'Track log settings',
  'oifde.operationlog.succeed': 'Success',
  'oifde.operationlog.failed': 'Failure',
  'oifde.operationlog.domain': 'Domain',
  'oifde.operationlog.service_node': 'Service node',
  'oifde.operationlog.online': 'Online',
  'oifde.operationlog.offline': 'Offline',
  'oifde.operationlog.flow': 'Flow',
  'oifde.operationlog.performance_data': 'Performance data',
  'oifde.operationlog.login': 'Sign-in',
  'oifde.operationlog.logout': 'Sign-out',
  'oifde.operationlog.change_password': 'Change password',
  'oifde.operationlog.reset_password': 'Reset password',
  'oifde.operationlog.authorize': 'Certification',
  'oifde.operationlog.tenant_space': 'Tenant Space',
  'oifde.operationlog.account': 'Account',
  'oifde.operationlog.role': 'Role',
  'oifde.operationlog.password_rule': 'Password rule',
  'oifde.operationlog.password_rule_group': 'Password rule group',
  'oifde.operationlog.user_account': 'Account',
  'oifde.operationlog.user_role': 'Role',
  'oifde.operationlog.knowledge_graph': 'Knowledge graph',
  'oifde.operationlog.knowledge_graph_synonym': 'Knowledge graph synonym',
  'oifde.operationlog.acess_code': 'Access code',
  'oifde.operationlog.gray_rule': 'Gray rule',
  'oifde.operationlog.resource_template': 'Resource template',
  'oifde.operationlog.business_interface': 'Business interface',
  'oifde.operationlog.variable_manage': 'Variable management',
  'oifde.operationlog.flume_log_cfg': 'Flume log configuration',
  'oifde.operationlog.procedure_db': 'Stored procedure database',
  'oifde.operationlog.procedure_interface': 'Stored procedure',
  'oifde.operationlog.complexcellcatalog': 'Composite diagram element catalog',
  'oifde.operationlog.complexcell': 'Composite diagram element',
  'oifde.operationlog.lock_account': 'Lock account',
  'oifde.operationlog.unlock_account': 'Unlock account',
  'oifde.operationlog.kick_account': 'Clear sessions',
  'oifde.operationlog.start': 'Start',
  'oifde.operationlog.stop': 'Stop',
  'oifde.operationlog.log_open_task': 'Log enablement task',
  'oifde.operationlog.activate': 'Activate',
  'oifde.operationlog.deactivate': 'Pause',
  'oifde.operationlog.illeagal_status': 'Illegal status',
  'oifde.operationlog.file': 'File',
  'oifde.operationlog.trace_log_query': 'Log tracing and query',
  'oifde.operationlog.staticcheck': 'Static check',
  'oifde.operationlog.unkonw': 'Unknown',
  'oifde.operationlog.interface_white_list': 'Trustlist',
  'oifde.operationlog.license': 'License',
  'oifde.operationlog.test_corpus': 'Corpus test',
  'oifde.operationlog.test': 'Test',
  'oifde.operationlog.refresh': 'Refresh',
  'oifde.operationlog.train': 'Train',
  'oifde.operationlog.trainAll': 'Train all',
  'oifde.operationlog.lable_corpus': 'Tagged corpus',
  'oifde.operationlog.knowledge_graph_schema': 'Knowledge schema',
  'oifde.operationlog.knowledge_graph_ask_template': 'Extended question',
  'oifde.operationlog.knowledge_graph_entity': 'Graph dictionary entity',
  'oifde.operationlog.knowledge_graph_relation': 'Graph dictionary relation',
  'oifde.operationlog.knowledge_graph_object_attribute': 'Graph dictionary relationship attribute',
  'oifde.operationlog.knowledge_graph_object_entity': 'Graph dictionary entity attribute',
  'oifde.operationlog.knowledge_graph_schema_entity': 'Knowledge schema entity',
  'oifde.operationlog.knowledge_graph_schema_relation': 'Knowledge schema relation',
  'oifde.operationlog.knowledge_graph_schema_attribute': 'Knowledge schema attribute',
  'oifde.operationlog.knowledge_graph_ask_template_group': 'Graph extended question',
  'oifde.operationlog.knowledge_graph_ask_template_element': 'FAQ template for graph extended questions',
  'oifde.operationlog.encrypt_Secret': 'Encrypt secret',
  'oifde.operationlog.flow_trace_log': 'Process test tracking configuration',
  'oifde.operationlog.channel_type': 'Channel',
  'oifde.operationlog.response_style': 'Style',
  'oifde.operationlog.resource_template_exce': 'Exception resource template',
  'oifde.operationlog.setting': 'Set up',
  'oifde.operationlog.quick_release': 'One-click publishing',
  'oifde.operationlog.download': 'Download',
  'oifde.operationlog.upload': 'Upload',
  'oifde.operationlog.binding_domain': 'Chatbot domain',
  'oifde.operationlog.binding_faqgroup': 'Chatbot FAQ group',
  'oifde.operationlog.binding_kg': 'Chatbot graph',
  'oifde.operationlog.binding': 'Bind',
  'oifde.operationlog.guideflow': 'Creation wizard flow',
  'oifde.operationlog.sensitive_group': 'Sensitive word group',
  'oifde.operationlog.recordHistoryLog': 'Dialog log',
  'oifde.operationlog.sentiment_analysis': 'Sentiment analysis model',
  'oifde.operationlog.use': 'Used',
  'oifde.operationlog.unused': 'Not used',
  'oifde.operationlog.tenantAsrName': 'ASR configuration',
  'oifde.operationlog.tenantAsrTtsName': 'TTS/ASR management',
  'oifde.operationlog.MRCPServerManagement': 'Server management',
  'oifde.operationlog.insert': 'Insert',
  'oifde.operationlog.exclude_entity': 'Exclude Entities',
  'oifde.sysConfig.sysParam': 'System Parameters',
  'oifde.sysConfig.intent_params': 'Intention Parameters',
  'oifde.sysConfig.code': 'Variable Code',
  'oifde.sysConfig.description': 'Variable Description',
  'oifde.sysConfig.value': 'Variable Value',
  'oifde.sysConfig.update': 'Apply',
  'oifde.sysConfig.abort': 'Cancel',
  'oifde.sysConfig.updateAll': 'Apply All Parameters',
  'oifde.sysConfig.restore_defaults': 'Restore Default Values',
  'oifde.sysConfig.successful_operation': 'Operation successful.',
  'oifde.sysConfig.confirm_apply_intent_param': 'Modifying the intention parameters will significantly affect the intention recognition. Are you sure you want to continue?',
  'oifde.sysConfig.succeed_apply_intent_param': 'The intention parameters are modified successfully!',
  'oifde.sysConfig.failed_apply_intent_param': 'Failed to modify the intention parameter!',
  'oifde.sysConfig.confirm_restore_defaults': 'Are you sure you want to restore the default values?',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.NAME': 'Chinese Pre-processing Module',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.NAME': 'Use Pinyin',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.DESC': 'Whether to convert Chinese to pinyin.',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.TRUE': 'Yes',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.FALSE': 'No',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.NAME': 'Use Tone',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.DESC': 'Whether to use tone in pinyin.',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.TRUE': 'Yes',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.FALSE': 'No',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.MULTI': 'Each occurrence',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.ONCE': 'Only one occurrence',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.TRUE': 'Enable',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.FALSE': 'Disable',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.TRUE': 'Enable',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.FALSE': 'Disable',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NAME': 'N-Gram Feature Extraction Module',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.NAME': 'Min. N Value',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.DESC': 'Minimum N value in the N-Gram algorithm.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.NAME': 'Max. N Value',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.DESC': 'Maximum N value in the N-Gram algorithm.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.NAME': 'Min. Word Weight Threshold',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.DESC': 'A word is ignored when its weight is below this threshold.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.NAME': 'Statistics Collection on Repeated Words',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.DESC': 'Whether to collect statistics for each or only one occurrence of a word repeated within a sentence.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.NAME': 'TSF Algorithm',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.DESC': 'Whether to enable the TSF algorithm.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.NAME': 'Sentence Border Extension',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.DESC': 'Whether to add tags before and after a sentence when the N-Gram feature is extracted.',
  'oifde.sysConfig.ENERGY_FILTER.NAME': 'Intention Quantity Control Module',
  'oifde.sysConfig.ENERGY_FILTER.MAX.NAME': 'Max. Intentions',
  'oifde.sysConfig.ENERGY_FILTER.MAX.DESC': 'Maximum number of intentions returned in the final decision.',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.NAME': 'Unknown Intention Filtering Module',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.NAME': 'Energy Threshold',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.DESC': 'The intentions are considered unknown ones if the sum of all detected intention energies is less than or equal to the threshold.',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.NAME': 'Intention Classification Module',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.NAME': 'μ',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.DESC': 'μ value of the intention classifier\'s logistic function.',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.NAME': 'γ',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.DESC': 'γ value of the intention classifier\'s logistic function.',
  'oifde.sysConfig.INTENT_DECISION_MAKER.NAME': 'Intention Decision-Making Module',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.NAME': 'Upper Limit',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.DESC': 'The intention is considered a known one if it exceeds the upper limit of the intention decision maker.',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.NAME': 'Lower Limit',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.DESC': 'The intention is considered an unknown one if it falls below the lower limit of the intention decision maker.',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NAME': 'CRF Slot Marking Module',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.NAME': 'Learning Rate',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.DESC': 'Rate at which CRF model learning is performed.',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.NAME': 'Tree Node Quantity',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.DESC': 'Number of nodes contained within each tree in the GBRT algorithm.',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.NAME': 'Tree Quantity',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.DESC': 'Number of trees in the GBRT algorithm.',
  'oifde.errorCode.100010000000': 'System error.',
  'oifde.errorCode.100010000002': 'The format is incorrect.',
  'oifde.errorCode.100051005001': 'Failed to save the blocklist and trustlist!',
  'oifde.errorCode.100070000001': 'Failed to import the data because it is the same as an existing resource!',
  'oifde.errorCode.100051005002': 'Failed to delete the blocklist or trustlist!',
  'oifde.errorCode.100051000006': 'Failed to import the file because the format is invalid!',
  'oifde.errorCode.100051005005': 'Failed to import the file because it is too large!',
  'oifde.errorCode.100051005006': 'The service number can contain a maximum of 64 characters!',
  'oifde.errorCode.100051005015': 'The file can contain a maximum of 5000 lines!',
  'oifde.errorCode.100051005003': 'Failed to import the file because it cannot be found!',
  'oifde.errorCode.100051005004': 'Failed to import the file because the system cannot read the file!',
  'oifde.errorCode.100051006013': 'Failed to import the file!',
  'oifde.errorCode.100051005025': 'The total number cannot exceed 5000!',
  'oifde.errorCode.100051005011': 'Failed to add the service number!',
  'oifde.fileTask.EMPTY_COMMAND': 'The operation command cannot be empty!',
  'oifde.fileTask.UNKNOWN_COMMAND': 'The operation command is unidentified!',
  'oifde.fileTask.INVALID_SALT': 'The request is invalid.',
  'oifde.fileTask.INVALID_REQUEST': 'The request is invalid.',
  'oifde.fileTask.EMPTY_TASK_ID': 'The task ID cannot be empty.',
  'oifde.fileTask.TASK_NOT_FOUND': 'The task is not found.',
  'oifde.fileTask.SERVER_BUSY': 'The system is currently busy.',
  'oifde.fileTask.SERVICE_UNAVAILABLE': 'The service is currently unavailable.',
  'oifde.fileTask.UPLOAD_EXCEPTION': 'The upload was interrupted.',
  'oifde.fileTask.INVALID_ITEM_ENCODING': 'The encoding mode is invalid.',
  'oifde.fileTask.INVALID_FILE_EXTENSION': 'The file name extension is invalid.',
  'oifde.fileTask.WRITE_FILE_FAILED': 'Failed to save the file.',
  'oifde.fileTask.READ_FILE_FAILED': 'Failed to read the file.',
  'oifde.fileTask.INVALID_FILE_PATH': 'The file path is incorrect.',
  'oifde.fileTask.FILE_NOT_EXIST': 'The file does not exist.',
  'oifde.fileTask.FILE_TOO_LARGE': 'The compressed package contains files that are too large.',
  'oifde.fileTask.ENTRY_TOO_LARGE': 'The compressed package contains oversized files.',
  'oifde.fileTask.TOO_MANY_ENTRIES': 'The compressed package contains too many files.',
  'oifde.fileTask.INVALID_ENTRY_PATH': 'The file path in the compressed package is invalid.',
  'oifde.fileTask.INVALID_ENTRY_EXTENSION': 'The file name extension in the compressed package is invalid.',
  'oifde.fileTask.UNZIPPED_TOO_LARGE': 'The size of the decompressed package is too large.',
  'oifde.fileTask.UNZIP_FAILED': 'Failed to decompress the package.',
  'oifde.fileTask.DOWNLOAD_EXCEPTION': 'The download was interrupted.',
  'oifde.fileTask.UNDEFINED_ERROR': 'An error occurred during task execution.',
  'oifde.fileTask.INVALID_TASK_ARGS': 'The task parameter is missing.',
  'oifde.fileTask.INVALID_TASK_CONTENT': 'The task data is invalid.',
  'oifde.fileTask.GRAPH_IS_ON': 'An active graph cannot be imported.',
  'oifde.fileTask.INCORRECT_PARAMETERS': 'The parameter is invalid.',
  'oifde.fileTask.KG_NO_SCHEMA': 'The graph is not associated with a knowledge schema.',
  'oifde.fileTask.IMPORT_KG_ENTITY_TOO_BIG': 'A maximum of 1 million graph entities are allowed',
  'oifde.fileTask.IMPORT_KG_RELATION_TOO_BIG': 'A maximum of 100 million graph relationships are allowed.',
  'oifde.fileTask.IMPORT_SCHEMA_TOO_BIG': 'A maximum of 10,000 concept entities are allowed in a knowledge schema.',
  'oifde.fileTask.IMPORT_SCHEMA_RELATION_TOO_BIG': 'A maximum of 10,000 relationships are allowed in a knowledge schema.',
  'oifde.importFile.white_list': ' Overwrite all existing settings',
  'oifde.importFile.test_case_group': ' Overwrite all existing settings',
  'oifde.importFile.knowledge_graph': 'Overwrite duplicate names',
  'oifde.importFile.knowledge_graph_ask_template': ' Overwrite all existing settings',
  'oifde.recordHistory.recordHistory': 'Call History',
  'oifde.recordHistory.recordDetail': 'Call Details',
  'oifde.recordHistory.userSaying': 'Request Text',
  'oifde.recordHistory.systemReply': 'Response Text',
  'oifde.recordHistory.reqTime': 'Requested',
  'oifde.recordHistory.respTime': 'Responded',
  'oifde.recordHistory.callNumber': 'Subscriber Number',
  'oifde.recordHistory.callStatus': 'Inbound Call Monitoring',
  'oifde.recordHistory.kgStatus': 'Knowledge Monitoring',
  'oifde.recordHistory.callNumberLength': 'The subscriber number cannot contain more than 64 characters.',
  'oifde.recordHistory.oneCondition': 'Either the date or mobile number is required.',
  'oifde.recordHistory.respSource': 'Reply Source',
  'oifde.recordHistory.silentSeat': 'Silent agent',
  'oifde.recordHistory.bot': 'Chatbot',
  'oifde.recordHistory.customer': 'Customer',
  'oifde.error.messageHead': 'Frontend interface -',
  'oifde.error.messageBody': 'Request error. Error code:',
  'oifde.error.messageTail': 'Check whether the network and server are working correctly.',
  'oifde.testMgr.noSelection': 'Test cases cannot be generated because no domain or FAQ group is selected.',
  'oifde.testMgr.abortSuccess': 'Interruption successful!',
  'oifde.testMgr.abortFailed': 'Interruption failed!',
  'oifde.testMgr.getStatusWarn': 'Test cases cannot currently be generated because an existing process has requested to generate a test case. Please wait...',
  'oifde.testMgr.noDomain': 'No domain is selected.',
  'oifde.testMgr.noFaqGroup': 'No FAQ group is selected.',
  'oifde.testMgr.requestFailed': 'Failed to invoke the interface for automatically generating test cases!',
  'oifde.testMgr.getCorpusTestCase': 'Generate Case',
  'oifde.testMgr.abortCorpusTestCase': 'Interrupt',
  'oifde.testMgr.domain': 'Domain',
  'oifde.testMgr.faqGroup': 'FAQ Group',
  'oifde.testMgr.getCorpusTestCaseConfig': 'Generate Case',
  'oifde.testMgr.allDomain': 'Select all domains',
  'oifde.testMgr.allFaqGroup': 'Select all FAQ groups',
  'oifde.testMgr.testMgr': 'Knowledge Test',
  'oifde.testMgr.chatBotTestCaseMgr': 'Dialog Test',
  'oifde.testMgr.botTestCaseMgr': 'Manage Test Case',
  'oifde.testMgr.testCase': 'Test Case',
  'oifde.testMgr.case_group': 'Test Case Groups',
  'oifde.testMgr.case_amount': 'Test Cases',
  'oifde.testMgr.case_tested': 'Quantity Tested',
  'oifde.testMgr.case_passed': 'Passed',
  'oifde.testMgr.case_failed': 'Failed',
  'oifde.testMgr.case_passed_rate': 'Pass Rate',
  'oifde.testMgr.test_time': 'Tested',
  'oifde.testMgr.add_test_case_group': 'Add Test Case Group',
  'oifde.testMgr.test_case_group_details': 'Details',
  'oifde.testMgr.descriptionLen': 'The description can contain a maximum of 1024 characters.',
  'oifde.testMgr.number': 'Only digits are allowed.',
  'oifde.testMgr.auto_testing_template': 'Download Template',
  'oifde.testMgr.execute_test': 'Execute',
  'oifde.testMgr.manage': 'Manage',
  'oifde.testMgr.case_id': 'Case ID',
  'oifde.testMgr.userId': 'Subscriber ID',
  'oifde.testMgr.expected_output': 'Expected Output Keyword or Intention Name',
  'oifde.testMgr.actual_output': 'Actual Output',
  'oifde.testMgr.actual_output_title': 'Actual Output (Intention Name in Brackets)',
  'oifde.testMgr.event': 'Event',
  'oifde.testMgr.note_test': 'Not tested',
  'oifde.testMgr.expected_output_with_memo': 'Expected Output Keywords or Intention Names (separate multiple entries with commas)',
  'oifde.testMgr.add_test_case': 'Add Test Case',
  'oifde.testMgr.test_case_details': 'Details',
  'oifde.testMgr.execute_all': 'Execute All',
  'oifde.testMgr.batchExec': 'Execute',
  'oifde.testMgr.succeed_save_case': 'The test case is saved successfully!',
  'oifde.testMgr.succeed_test_case': 'The test case is executed successfully!',
  'oifde.testMgr.natureColon': ':',
  'oifde.testMgr.failed_query': 'Query failed!',
  'oifde.testMgr.succeed_save_group': 'The test case group is saved successfully!',
  'oifde.testMgr.fail_save_data': 'Failed to save the test case group!',
  'oifde.testMgr.select_prompt': 'Select at least one record.',
  'oifde.testMgr.confirm_delete_case_group': 'Are you sure you want to delete the test case group?',
  'oifde.testMgr.failed_delete_group': 'Failed to delete the test case group.',
  'oifde.testMgr.succeed_delete_group': 'The test case group is deleted successfully!',
  'oifde.testMgr.confirm_delete_case': 'Are you sure you want to delete the test case?',
  'oifde.testMgr.succeed_delete_case': 'The test case is deleted successfully!',
  'oifde.testMgr.failed_delete_case': 'Failed to delete the test case!',
  'oifde.testMgr.import_test_cases': 'Import Test Case',
  'oifde.testMgr.import_test_cases_hint': 'A maximum of 200 test cases in TXT format can be imported.',
  'oifde.testMgr.overwrite_import': 'Overwrite all same settings',
  'oifde.testMgr.failed_import_file': 'Failed to import the file!',
  'oifde.testMgr.success_import': 'The file is imported successfully!',
  'oifde.hisdialog.startTime_must_earlier_endTime': 'The start time must be earlier than the end time. Reset the search criteria.',
  'oifde.hisdialog.direction.UPLOAD': 'Enter the request text.',
  'oifde.hisdialog.direction.DOWNLOAD': 'Response text',
  'oifde.chatBotTest.rangeFilter': 'Filter Selected Records',
  'oifde.chatBotTest.botAccessCode': 'Chatbot Access Code',
  'oifde.chatBotTest.callingNum': 'Calling Number',
  'oifde.chatBotTest.caseTimeRange': 'Time Range',
  'oifde.chatBotTest.getChatBotTestCase': 'Generate Dialog Case',
  'oifde.chatBotTest.getChatBotTestCaseConfig': 'Generate Dialog Case',
  'oifde.chatBotTest.abortChatBotTestCase': 'Interrupt Dialog Case Generation',
  'oifde.chatBotTest.allAccessCode': 'Select all chatbot access codes',
  'oifde.chatBotTest.deletePrompt': 'This operation will permanently delete the test case group. Are you sure you want to continue?',
  'oifde.chatBotTest.deleteTestCase': 'This operation will permanently delete the selected test cases. Are you sure you want to continue?',
  'oifde.chatBotTest.checkPrompt': 'Running multiple test cases concurrently may slow down the system. Are you sure you want to continue?',
  'oifde.chatBotTest.cancleExcute': 'The execution is canceled.',
  'oifde.chatBotTest.inputTestCaseGroupName': 'Test Case Group Name',
  'oifde.chatBotTest.doTest': 'Execute',
  'oifde.chatBotTest.executeSuccess': 'Execution successful.',
  'oifde.chatBotTest.executeFailed': 'Execution failed!',
  'oifde.chatBotTest.downTestReport': 'Download Test Report',
  'oifde.chatBotTest.downAllReport': 'Download All Reports',
  'oifde.chatBotTest.testCaseGroup': 'Test Case Groups',
  'oifde.chatBotTest.totalNumber': 'Test Cases',
  'oifde.chatBotTest.testTimes': 'Quantity Tested',
  'oifde.chatBotTest.passTimes': 'Passed',
  'oifde.chatBotTest.failTimes': 'Failed',
  'oifde.chatBotTest.passRate': 'Pass Rate',
  'oifde.chatBotTest.testTime': 'Tested',
  'oifde.chatBotTest.status': 'Status',
  'oifde.chatBotTest.status.initial': 'Initialize',
  'oifde.chatBotTest.status.waiting': 'Waiting',
  'oifde.chatBotTest.status.running': 'Running',
  'oifde.chatBotTest.status.finish': 'Finish',
  'oifde.chatBotTest.status.failed': 'Fail',
  'oifde.chatBotTest.status.success': 'Success',
  'oifde.chatBotTest.description': 'Description',
  'oifde.chatBotTest.addTestCaseGroup': 'Add Test Case Group',
  'oifde.chatBotTest.editTestCaseGroup': 'Modify Test Case Group',
  'oifde.chatBotTest.testCaseGroupName': 'Case Group Name',
  'oifde.chatBotTest.robotName': 'Chatbot Name',
  'oifde.chatBotTest.callingId': 'Calling Number',
  'oifde.chatBotTest.prompt': 'Information',
  'oifde.chatBotTest.promptMessage': 'Select at least one record.',
  'oifde.chatBotTest.downloadMessage': 'You have selected {0} records. Are you sure you want to continue?',
  'oifde.chatBotTest.downloadAllMessage': 'You have not selected specific records. Export all records? You can also cancel the operation and select a specific record to export.',
  'oifde.testCase.name': 'Test Case Name',
  'oifde.testCase.status': 'Status',
  'oifde.testCase.caseID': 'Case ID',
  'oifde.testCase.doAll': 'Execute All',
  'oifde.testCase.caseName': 'Case Name',
  'oifde.testCase.executeStatus': 'Status',
  'oifde.testCase.description': 'Description',
  'oifde.testCase.caseDetail': 'Case Details',
  'oifde.testCase.traceLog': 'Flow Track',
  'oifde.testCase.callDetail': 'Details',
  'oifde.testCase.addTestCase': 'Add Test Case',
  'oifde.testCase.editTestCase': 'Modify Test Case',
  'oifde.testCase.caseDetailMgr': 'Manage Case Details',
  'oifde.testCase.detailDialog': 'Case Details',
  'oifde.testCase.detail.reqText': 'Request Text',
  'oifde.testCase.detail.expectedReturn': 'Expected Response Text',
  'oifde.testCase.detail.channelAssociatedData': 'Associated Data',
  'oifde.testCase.detail.operation': 'Operation',
  'oifde.testCase.detail.realResponse': 'Actual Response Text',
  'oifde.testCase.detail.close': 'Close',
  'oifde.rate.accessCode': 'Select a chatbot access code.',
  'oifde.rate.callCountBoard': 'Call Statistics Dashboard',
  'oifde.rate.resolveRate': 'Net Self-Service Resolution Rate',
  'oifde.rate.dayOption': 'By day',
  'oifde.rate.hourOption': 'By hour',
  'oifde.rate.monthOption': 'Last 30 days',
  'oifde.rate.dateSelect': 'Select a date.',
  'oifde.rate.index': 'No.',
  'oifde.rate.time': 'Date and Time',
  'oifde.rate.dataDimension': 'Select the data dimension.',
  'oifde.rate.accessAmount': 'Connected Calls',
  'oifde.rate.exitNoConsultCount': 'Calls Directly Disconnected',
  'oifde.rate.callStaffNoConsultCount': 'Calls Processed Only by Agent',
  'oifde.rate.exitConsultingCount': 'Calls Processed Only by Chatbot',
  'oifde.rate.callStaffConsultCount': 'Calls Transferred from Chatbot to Agent',
  'oifde.rate.interfaceTimes': 'Interface Invoking Times',
  'oifde.rate.interfaceSuccessTimes': 'Successfully Invoked Interfaces',
  'oifde.rate.successRate': 'Interface Invoking Success Rate',
  'oifde.rate.autonomyRate': 'Net Self-Service Resolution Rate',
  'oifde.rate.interactCount': 'Dialog Rounds',
  'oifde.rate.averageInteractPerCall': 'Average Dialog Rounds',
  'oifde.rate.accessNum': 'Connected calls',
  'oifde.rate.dayconditionTips': 'The access code is missing in the search criteria.',
  'oifde.rate.hourConditionTips': 'The date or access code is missing in the search criteria.',
  'oifde.rate.rateForCharts': 'Net self-service resolution rate',
  'oifde.rate.interfaceRequestCount': 'Interface Invocations',
  'oifde.rate.interfaceSuccessCount': 'Successful Invocations',
  'oifde.rate.interfaceSuccessRate': 'Invocation Success Rate',
  'oifde.dashBoard.noData': '- (Deleted data cannot be displayed.)',
  'oifde.dashBoard.serviceCapabilityDashBoard': 'Service Capability Dashboard',
  'oifde.dashBoard.knowledgeDashBoard': 'Knowledge Dashboard',
  'oifde.dashBoard.service.consultCount': 'Total Replies',
  'oifde.dashBoard.service.responseSuccessCount': 'Successful Replies',
  'oifde.dashBoard.service.unknowCount': 'Unknown Replies',
  'oifde.dashBoard.service.responseRate': 'Reply Success Rate',
  'oifde.dashBoard.service.feedbackCount': 'Total Feedback',
  'oifde.dashBoard.service.positiveFeedbackCount': 'Satisfied Feedback',
  'oifde.dashBoard.service.positiveFeedbackRate': 'Satisfaction Rate',
  'oifde.dashBoard.knowledge.dateConditionTips': 'Select a date.',
  'oifde.dashBoard.knowledge.topTenChatBot': 'Top 10 Chatbot Access Codes',
  'oifde.dashBoard.knowledge.accessCode': 'Access Code',
  'oifde.dashBoard.knowledge.name': 'Name',
  'oifde.dashBoard.knowledge.callCount': 'Visits',
  'oifde.dashBoard.knowledge.topTenknowledge': 'Top 10 Useful Knowledge Entities',
  'oifde.dashBoard.knowledge.intent': 'Intention',
  'oifde.dashBoard.knowledge.faq': 'FAQ',
  'oifde.dashBoard.knowledge.hitCount': 'Hits',
  'oifde.dashBoard.chartView.title': 'Reply Success Rate',
  'oifde.dashBoard.chartView.countTile': 'Total replies',
  'oifde.dashBoard.chartView.rateTile': 'Reply success rate',
  'oifde.dashBoard.chartView.leftTile': 'Total replies',
  'oifde.dashBoard.chartView.rightTile': 'Reply success rate',
  'oifde.dashBoard.graph.title': 'Valid Knowledge Statistics',
  'oifde.dashBoard.graph.faq': 'FAQ',
  'oifde.dashBoard.graph.intent': 'Intention',
  'oifde.dashBoard.graph.kg': 'Graph',
  'oifde.dashBoard.graph.talk': 'Chat',
  'oifde.dashBoard.graph.countTitle': 'Total',
  'oifde.dashBoard.graph.hitTitle': 'Hits',
  'oifde.dashBoard.graph.hitRateTitle': 'Hit Rate',
  'oifde.dashBoard.graph.amount': 'Quantity',
  'oifde.dashBoard.graph.percent': 'Percentage',
  'oifde.dashBoard.graph.count': 'Total',
  'oifde.dashBoard.graph.hit': 'Hits',
  'oifde.dashBoard.graph.hitRate': 'Hit Rate',
  'oifde.dashBoard.pie.title': 'Hit Knowledge Distribution',
  'oifde.dashBoard.pie.intentTitle': 'Intention',
  'oifde.dashBoard.pie.kgTitle': 'Graph',
  'oifde.dashBoard.pie.faqTitle': 'FAQ',
  'oifde.dashBoard.pie.talkTitle': 'Chat',
  'oifde.dashBoard.pie.intent': 'Intention',
  'oifde.dashBoard.pie.kg': 'Graph',
  'oifde.dashBoard.pie.faq': 'FAQ',
  'oifde.dashBoard.pie.talk': 'Chat',
  'oifde.chatbot.errorCode.100010000002': 'The format is incorrect.',
  'oifde.chatbot.errorCode.100051000031': 'The number of input IDs exceeds the upper limit.',
  'oifde.chatbot.errorCode.100051000023': 'Failed to delete the case group!',
  'oifde.chatbot.errorCode.100010000000': 'System error.',
  'oifde.chatbot.errorCode.100010000001': 'Database error.',
  'oifde.chatbot.errorCode.100010000003': 'The name must be unique.',
  'oifde.chatbot.errorCode.100010000005': 'The input parameter is invalid.',
  'oifde.chatbot.errorCode.100023000004': 'Failed to verify the input ID.',
  'oifde.chatbot.errorCode.100051000022': 'Failed to save test case.',
  'oifde.chatbot.errorCode.100051000032': 'Failed to delete test cases.',
  'oifde.chatbot.errorCode.100051000026': 'The test fails to be executed because the number of selected test groups is too large.',
  'oifde.chatbot.errorCode.100051000028': 'The test result is not found.',
  'oifde.chatbot.errorCode.100051000109': 'The number of test cases exceeds 200.',
  'oifde.serverMgr.serverType': 'Server Type',
  'oifde.serverMgr.mrcpId': 'Group ID',
  'oifde.serverMgr.asrServer': 'ASR server',
  'oifde.serverMgr.ttsServer': 'TTS server',
  'oifde.serverMgr.confirmDelete': 'Are you sure you want to delete the data?',
  'oifde.serverMgr.addServer': 'Add Server',
  'oifde.serverMgr.editServer': 'Modify Server',
  'oifde.qiAsrServerMgr.asrVendor': 'Vendor',
  'oifde.qiAsrServerMgr.asrServerName': 'Server Name',
  'oifde.qiAsrServerMgr.asrServerAddress': 'Server Address',
  'oifde.qiAsrServerMgr.asrAppKey': 'User Name/AK',
  'oifde.qiAsrServerMgr.fileServerAddress': 'File Server Address',
  'oifde.qiAsrServerMgr.folder': 'Default Folder',
  'oifde.qiAsrServerMgr.description': 'Description',
  'oifde.qiAsrServerMgr.addQiAsrServer': 'Add QC ASR Server Information',
  'oifde.qiAsrServerMgr.editQiAsrServer': 'Edit QC ASR Server Information',
  'oifde.qiAsrServerMgr.asrServerInfo': 'ASR Server Information',
  'oifde.qiAsrServerMgr.name': 'Name',
  'oifde.qiAsrServerMgr.asrServerIP': 'ASR Server IP Address',
  'oifde.qiAsrServerMgr.asrServerPort': 'ASR Server Port',
  'oifde.qiAsrServerMgr.asrServerUser': 'ASR Server User',
  'oifde.qiAsrServerMgr.asrServerPW': 'ASR Server Password',
  'oifde.qiAsrServerMgr.asrServerPWConform': 'Confirm ASR Server Password',
  'oifde.qiAsrServerMgr.nextStep': 'Next',
  'oifde.qiAsrServerMgr.preStep': 'Previous',
  'oifde.qiAsrServerMgr.fileServerInfo': 'File Server Information',
  'oifde.qiAsrServerMgr.fileServerType': 'File Server Type',
  'oifde.qiAsrServerMgr.fileServerIP': 'File Server IP Address',
  'oifde.qiAsrServerMgr.fileServerPort': 'File Server Port',
  'oifde.qiAsrServerMgr.fileServerUser': 'File Server User Name',
  'oifde.qiAsrServerMgr.fileServerPW': 'File Server Password',
  'oifde.qiAsrServerMgr.fileServerPWConfirm': 'Confirm File Server Password',
  'oifde.qiAsrServerMgr.voiceUploadFolder': 'Path For Uploading Recording Files',
  'oifde.qiAsrServerMgr.qiResultFolder': 'Translation Result Path',
  'oifde.qiAsrServerMgr.sftp': 'SFTP',
  'oifde.qiAsrServerMgr.passwordError': 'The passwords entered twice are inconsistent.',
  'oifde.qiAsrServerMgr.ipOrDomainNameError': 'Incorrect IP address or domain name.',
  'oifde.qiAsrServerMgr.ipError': 'Incorrect IP address.',
  'oifde.qiAsrServerMgr.filePathError': 'The path format is incorrect.',
  'oifde.qiAsrServerMgr.portTips': 'If the ASR server address is a domain name, the port number is optional.',
  'oifde.qiAsrServerMgr.asrServerNameError': 'The ASR server name is the same as the default ASR server name.',
  'oifde.asrMgr.tenantId': 'Tenant Space ID',
  'oifde.asrMgr.tenantSapce': 'Tenant Space',
  'oifde.asrMgr.asr': 'ASR Server Supplier',
  'oifde.asrMgr.qiAsrServerName': 'Inspection ASR Server',
  'oifde.asrMgr.mrcpid': 'ASR Group ID',
  'oifde.asrMgr.path': 'ASR Grammar File Path',
  'oifde.asrMgr.slotName': 'ASR Slot',
  'oifde.asrMgr.groupID': 'TTS Group ID',
  'oifde.asrMgr.addAsr': 'Add TTS/ASR',
  'oifde.asrMgr.editAsr': 'Modify TTS/ASR',
  'oifde.asrMgr.selectOne': 'Select at least one of the following: ASR server supplier, ASR server, and TTS server.',
  'oifde.asrMgr.TENANT_SPACE_NOT_FOUND': 'The tenant space is not found.',
  'oifde.asrMgr.languageTips': 'default: indicates that the language is not differentiated.',
  'oifde.license.license': 'License Management',
  'oifde.license.import': 'Load/Update',
  'oifde.license.setlicense': 'Assign License',
  'oifde.license.queryCurrentLicense': 'Query Current Concurrency and Accumulation',
  'oifde.license.queryHistoryLicense': 'Query Historical Concurrency',
  'oifde.license.valateDate': 'Expiration Date',
  'oifde.license.totalLicense': 'License quantity',
  'oifde.license.LIN8OIAPAA01': 'Number of concurrent agent assistants',
  'oifde.license.LIN8OIAPVA01': 'Number of concurrent virtual agents',
  'oifde.license.LIN8OIAPVA02': 'Accumulated number of calls of virtual agents',
  'oifde.license.PERMANENT': 'Permanent',
  'oifde.license.processTime': 'Processed',
  'oifde.license.at_least_one_serviceValue': 'Set at least one parameter.',
  'oifde.license.failed_set_license': 'Failed to configure the license!',
  'oifde.license.success_set_license': 'The license is configured successfully!',
  'oifde.license.import_template_hint_dat': 'Only files in .dat format can be uploaded.',
  'oifde.license.import_template_need_dat': 'The file to be uploaded must be in .dat format.',
  'oifde.license.maxHisInfo': 'Historical Maximum Value',
  'oifde.license.detailHisInfo': 'Historical Details',
  'oifde.license.sys': 'System',
  'oifde.request.timeout': 'Interface request time out',
  'oifde.analysis.validate.time': 'Date Required',
  'oifde.analysis.validate.accessCode': 'Robot access code Required',
  'oifde.analysis.validate.timeInterval': 'The interval is no more than 7 days.',
  'oifde.analysis.chart.title': 'IVR node branch statistics',
  'oifde.analysis.chart.timeOther': 'Time out or press another key',
  'oifde.analysis.chart.button': 'Key press ',
  'oifde.analysis.chart.default': 'System default branch',
  'oifde.analysis.childrenView': 'View subnodes',
  'oifde.service.invoke.breakMinTime.desc': 'Unit: seconds;',
  'oifde.ivrJourney.nodeOverview': 'Node Overview',
  'oifde.ivrJourney.trendAnalysis': 'Trend Analysis',
  'oifde.ivrJourney.keyDistribution': 'Key Distribution',
  'oifde.ivrJourney.nodeVersionComparison': 'Node Version Comparison',
  'oifde.ivrJourney.businessRanking': 'Business Rankings',
  'oifde.ivrJourney.no': 'Ranking',
  'oifde.ivrJourney.nodeName': 'Node Name',
  'oifde.ivrJourney.visitsTotal': 'Visits',
  'oifde.ivrJourney.yoyValue': 'MoM Value',
  'oifde.ivrJourney.increaseValue': 'MoM Rate',
  'oifde.ivrJourney.visitorTotal': 'Visits',
  'oifde.ivrJourney.successRate': 'Success rate',
  'oifde.ivrJourney.failedRate': 'Failure Rate',
  'oifde.ivrJourney.hangUpRate': 'Hold Rate',
  'oifde.ivrJourney.journeyNumTrend': 'Value Trend Distribution',
  'oifde.ivrJourney.efficiencyTrend': 'Efficiency Trend Distribution',
  'oifde.ivrJourney.complexityTrend': 'Complexity Trend Distribution',
  'oifde.ivrJourney.repeatNum': 'Rehear',
  'oifde.ivrJourney.directCalls': 'Direct',
  'oifde.ivrJourney.nonDirectCalls': 'Non Direct',
  'oifde.ivrJourney.journeyNum': 'Visits',
  'oifde.ivrJourney.hangupNum': 'Hang Up',
  'oifde.ivrJourney.successCalls': 'Number of Successes',
  'oifde.ivrJourney.failCalls': 'Number of Failures',
  'oifde.ivrJourney.directRate': 'Direct Rate',
  'oifde.ivrJourney.nonDirectRate': 'Non Direct Rate',
  'oifde.ivrJourney.hangupRate': 'Hang Up Rate',
  'oifde.ivrJourney.failRate': 'Failure Rate',
  'oifde.ivrJourney.repeatCalls': 'Rehear',
  'oifde.ivrJourney.repeatOnceCalls': 'Rehear Once',
  'oifde.ivrJourney.repeatTwiceCalls': 'Rehear Twice',
  'oifde.ivrJourney.repeatRate': 'Rehear Rate',
  'oifde.ivrJourney.node': 'Node ',
  'oifde.ivrJourney.averageTime': 'Average Service Duration',
  'oifde.ivrJourney.averagePathLength': 'Average Path Length',
  'oifde.ivrJourney.timeRangeCheck30': 'The interval cannot exceed 30 days.',
  'oifde.ivrJourney.dateTimrRange': 'Data Time Range:',
  'oifde.ivrJourney.total': 'Total',
  'oifde.ivrJourney.inflowDistribution': 'Inflow distribution',
  'oifde.ivrJourney.outflowDistribution': 'Outflow distribution',
  'oifde.ivrJourney.totalDistribution': 'Overall distribution',
  'oifde.ivrJourney.detailDistribution': 'Detailed Distribution',
  'oifde.ivrJourney.outflowDetailDistribution': 'Outflow Detailed Distribution',
  'oifde.ivrJourney.outflowDetailDistributionAmount': 'Outflow detailed distribution amount',
  'oifde.ivrJourney.outflowDetailDistributionPercentage': 'Outflow detailed distribution percentage',
  'oifde.ivrJourney.nodeTrafficChart': 'Node Traffic Chart (Number of Indicators)',
  'oifde.ivrJourney.indicatorQuantityStatistics': 'Indicator quantity statistics',
  'oifde.ivrJourney.indicateName': 'Indicator Name',
  'oifde.ivrJourney.alarmContent': 'Pre-warning Content',
  'oifde.ivrJourney.alarmTime': 'Pre-warning Time',
  'oifde.ivrJourney.createTime': 'Pre-warning data time',
  'oifde.ivrJourney.alarmLevel': 'Pre-warning Level',
  'oifde.ivrJourney.alarmIndicator': 'Pre-warning Indicators',
  'oifde.ivrJourney.remindType': 'Reminder Mode',
  'oifde.ivrJourney.receivers': 'Recipient',
  'oifde.ivrJourney.sendResult': 'Sending Result',
  'oifde.ivrJourney.low': 'Low',
  'oifde.ivrJourney.middle': 'Middle',
  'oifde.ivrJourney.high': 'High',
  'oifde.ivrJourney.noNeedToSend': 'No need to send',
  'oifde.ivrJourney.sendFail': 'Sending failed',
  'oifde.ivrJourney.sendSuccess': 'Sent successfully',
  'oifde.ivrJourney.sms': 'SMS',
  'oifde.ivrJourney.email': 'Email',
  'oifde.ivrJourney.smsAndEmail': 'SMS; Email',
  'oifde.ivrJourney.other': 'Other',
  'oifde.ivrJourney.nowData': 'Current Value',
  'oifde.ivrJourney.label': 'Identifier',
  'oifde.ivrJourney.transToManualCalls': 'Number of transferred-to-manual calls',
  'oifde.ivrJourney.transToNextLevelManuCalls': 'Flowing into lower-level menus',
  'oifde.ivrJourney.timeOutOrPressAnotherKey': 'Time out or press another key',
  'oifde.ivrJourney.buttons': 'Button',
  'oifde.ivrJourney.defaultBranch': 'Default Branch',
  'oifde.ivrJourney.count': 'Times',
  'oifde.ivrJourney.versionName': 'Version Name',
  'oifde.ivrJourney.compareRange': 'Comparison Scope',
  'oifde.ivrJourney.pleaseSelectAccessCode': 'Please select a robot',
  'oifde.ivrJourney.pleaseSelectAccessCodeFirst': 'Please select a robot first',
  'oifde.ivrJourney.oneDay': '1 day',
  'oifde.ivrJourney.threeDay': '3 days',
  'oifde.ivrJourney.halfMonth': 'Half a month',
  'oifde.ivrJourney.oneMonth': 'One month',
  'oifde.ivrJourney.chooseAccessCode': 'Select a robot',
  'oifde.ivrJourney.chooseVersion': 'Select Flow Version',
  'oifde.ivrJourney.releaseDate': 'Release Time',
  'oifde.ivrJourney.nodeVersionCompareStatistics': 'Node Version Comparison Statistics',
  'oifde.ivrJourney.validIVRService': 'Valid IVR Service',
  'oifde.ivrJourney.transToManualCallsSucc': 'Transferred to Manual Successful',
  'oifde.ivrJourney.selfService': 'Self-service',
  'oifde.ivrJourney.repeatIncomingCalls': 'Repeated Incoming Calls',
  'oifde.ivrJourney.returnKeysByErrors': 'Key Error Return',
  'oifde.ivrJourney.pleaseSelectVersion': 'Please select a flow version.',
  'oifde.ivrJourney.pleaseSelectCompareRange': 'Please select compare range',
  'oifde.ivrJourney.logTime': 'LogTime',
  'oifde.ivrJourney.validIVRCalls': 'Valid IVR service volume',
  'oifde.ivrJourney.IVRCalls': 'Self-service volume',
  'oifde.ivrJourney.IVRTransManualRate': 'Proportion of manual traffic (%)',
  'oifde.ivrJourney.IVRRate': 'Pure IVR service rate (%)',
  'oifde.ivrJourney.IVRRateNoSymbol': 'Pure IVR service rate',
  'oifde.ivrJourney.transManualSuccCalls': 'Number of successful calls transferred to manual services',
  'oifde.ivrJourney.transManualSuccRate': 'Transfer to manual service success rate (%)',
  'oifde.ivrJourney.avgIVRTime': 'Average call duration (s)',
  'oifde.ivrJourney.avgIVRTimeNoSymbol': 'Average call duration',
  'oifde.ivrJourney.IVRErrorRate': 'IVR system error rate (%)',
  'oifde.ivrJourney.IVRErrorRateNoSymbol': 'IVR system error rate',
  'oifde.ivrJourney.IVRTotalCalls': 'Number of IVR incoming calls',
  'oifde.ivrJourney.IVRSrvTime': 'Total IVR service duration (s)',
  'oifde.ivrJourney.wrongKeyCalls': 'Number of returned keys due to key errors',
  'oifde.ivrJourney.distinctCustomers': 'Number of customers',
  'oifde.ivrJourney.IVRSrvCustomers': 'Number of pure IVR customers',
  'oifde.ivrJourney.callNum': 'Calls Volume',
  'oifde.ivrJourney.ivrRepeatCallNum': 'IVR Repeat Calls',
  'oifde.ivrJourney.resolveRate': 'One-time Resolution Rate',
  'oifde.ivrJourney.serviceRate': 'Effective Service Rate',
  'oifde.ivrJourney.indicatorDesc': 'Indicator Description',
  'oifde.ivrJourney.validIVRServiceDesc': 'Number of IVR calls in which the service handling or query is successful, the service consultation voice is played more than half, or the SMS, MMS, WAP Push, or email are successfully triggered',
  'oifde.ivrJourney.transToManualCallsDesc': 'Number of IVR calls that attempt to be forwarded to the manual service',
  'oifde.ivrJourney.transToManualCallsSuccDesc': 'Number of IVR calls that are successfully transferred to an agent',
  'oifde.ivrJourney.selfServiceDesc': 'Number of IVR calls that are not forwarded to the manual service',
  'oifde.ivrJourney.repeatIncomingCallsDesc': 'Number of customers who use the same calling number to call the customer service hotline repeatedly within two hours',
  'oifde.ivrJourney.returnKeysByErrorsDesc': 'In the navigation node, after a key is pressed incorrectly, the number returned by the asterisk (*) key is selected as prompted',
  'oifde.ivrJourney.indicatorSetting': 'Indicator Setting',
  'oifde.ivrJourney.indicatorSettingLengthError': 'Select at least {count} indicators.',
  'oifde.ivrJourney.extendedMetrics': 'Extended Metrics',
  'oifde.ivrJourney.optimizeVoiceSequence': 'Voice broadcast sequence optimization',
  'oifde.ivrJourney.optimizeVoiceDefinition': 'Voice definition optimization',
  'oifde.ivrJourney.pleaseSelectDateRange': 'Please select a time range.',
  'oifde.ivrJourney.reverseFlow': 'Reverse flow direction',
  'oifde.ivrJourney.currentVoicePlayContent': 'Current voice broadcast content',
  'oifde.ivrJourney.repeatCallsNum': 'Repeated Listening Times',
  'oifde.ivrJourney.optimizeSuggestion': 'Optimization Suggestions',
  'oifde.ivrJourney.repeatCallOptimizeSuggestion': 'The number of replay times of the current node is too high. Check whether the voice content is unclear or difficult to understand. You are advised to optimize the voice content.',
  'oifde.ivrJourney.operationStatus': 'Operation Status',
  'oifde.ivrJourney.poor': 'Poor',
  'oifde.ivrJourney.general': 'General',
  'oifde.ivrJourney.good': 'Good',
  'oifde.ivrJourney.fluctuationService': 'Fluctuation Service',
  'oifde.ivrJourney.IVRIncomingCallVolumeTrend': 'IVR Incoming Call Volume Trend',
  'oifde.ivrJourney.efficiencyOverview': 'Efficiency Overview',
  'oifde.ivrJourney.repeatedCallRate': 'Repeated Call Rate',
  'oifde.ivrJourney.valueOverview': 'Value Overview',
  'oifde.ivrJourney.pureIVRServiceVolume': 'Pure IVR Service Volume',
  'oifde.ivrJourney.complexityOverview': 'Complexity Overview',
  'oifde.ivrJourney.personTime': 'times',
  'oifde.ivrJourney.times': 'times',
  'oifde.ivrJourney.noData': 'None',
  'oifde.ivrJourney.usageStatistics': 'Current Voice Playback Key Usage Statistics',
  'oifde.ivrJourney.playingOrder': 'Recommended voice broadcast sequence',
  'oifde.ivrJourney.journeySum': 'Used Times',
  'oifde.ivrJourney.forever': 'Permanently valid',
  'oifde.ivrJourney.slectTimeForever': 'Specifies the validity period.',
  'oifde.ivrJourney.alarmIndex': 'Statistical Indicators',
  'oifde.ivrJourney.alarmCondition': 'Pre-warning condition',
  'oifde.ivrJourney.alarmValidity': 'Pre-warning Validity Period',
  'oifde.ivrJourney.selectAlarmCondition': 'Please select the alert condition to be deleted.',
  'oifde.ivrJourney.remindObject': 'Reminder object',
  'oifde.ivrJourney.transfManualRate': 'Transfer-to-manual rate',
  'oifde.ivrJourney.absoluteValue': 'absolute value',
  'oifde.ivrJourney.highThan': 'Higher than',
  'oifde.ivrJourney.equal': 'Is equal to',
  'oifde.ivrJourney.lessThen': 'Less than',
  'oifde.ivrJourney.createAlarmConfig': 'Creating a Service Pre-alarm Condition',
  'oifde.ivrJourney.editAlarmConfig': 'Editing a Service Pre-alarm Condition',
  'oifde.ivrJourney.selectAccesscodeAndFlow': 'Select a robot and process version.',
  'oifde.ivrJourney.selectAlarmIndex': 'Please select a statistical indicator.',
  'oifde.ivrJourney.fillAlarmCondition': 'Please complete the pre-alarm conditions.',
  'oifde.ivrJourney.selectAlarmLevel': 'Please select a warning level.',
  'oifde.ivrJourney.selectTime': 'Please select a valid time.',
  'oifde.ivrJourney.selectAlramRemindType': 'Select at least one reminder mode.',
  'oifde.ivrJourney.selectRemindObject': 'Please select a reminder object.',
  'oifde.ivrJourney.remindObjectMaxNum': 'A maximum of 20 characters can be selected for the notification object.',
  'oifde.ivrJourney.batchDetele': 'Batch Delete',
  'oifde.ivrJourney.selectSmsGateway': 'Please select an SMS gateway.',
  'oifde.ivrJourney.selectMailGateway': 'Please select an email gateway.',
  'oifde.ivrJourney.indicatorComparisonBarChart': 'Counter Comparison Bar Chart',
  'oifde.ivrJourney.indicatorComparisonStatistics': 'Counter Comparison Statistics',
  'oifde.ivrJourney.beforeRelease': 'Before Release',
  'oifde.ivrJourney.afterRelease': 'After Release',
  'oifde.ivrJourney.beforeReleaseRepeatRate': 'Replay Rate Before Release',
  'oifde.ivrJourney.afterReleaseRepeatRate': 'Replay Rate After Release',
  'oifde.ivrJourney.beforeReleaseHangupNum': 'Number of Hang-ups Before Release',
  'oifde.ivrJourney.afterReleaseHangupNum': 'Number of Hang-ups After Release',
  'oifde.headerTitle.flowManagement': 'Flow Management',
  'oifde.headerTitle.flowConfiguration': 'Flow Configuration',
  'oifde.trackmgr.robotsTrack': 'Tracking Robots',
  'oifde.trackmgr.robotsSource': 'Source',
  'oifde.maintain.odfsLogOpen': 'Enabling the ODFS Log Function',
  'oifde.maintenance.level': 'Level',
  'oifde.maintenance.startTime': 'Effective Time',
  'oifde.maintenance.duration': 'Duration (minutes)',
  'oifde.maintenance.startLog': 'Log Enable',
  'oifde.maintenance.stop_success': 'Log Enable',
  'oifde.maintenance.start_success': 'The log function is enabled successfully!',
  'oifde.route.transferRecord': 'Process Transfer Record',
  'oifde.route.audioVideoResource': 'Audio and Video Resource',
  'oifde.serviceInterface.thirdAPILog': 'Interface Invoking Records',
  'oifde.serviceInterface.statusCode': 'Status Code',
  'oifde.serviceInterface.invokingTime': 'Invoking time',
  'oifde.serviceInterface.callId': 'Call ID',
  'oifde.serviceInterface.invokingDetails': 'Calling Details',
  'oifde.title.chooseFlow': 'Choose Flow',
  'oifde.tip.inputFlowName': 'Enter a flow name',
  'oifde.error.createfail': 'Failed to add local data!',
  'oifde.error.gateway.fail': 'Failed to synchronize the information the CTI platform! Please check the gateway configuration.',
  'oifde.error.NMS.fail': 'Synchronization to the NMS failed!',
  'oifde.flowname.maxlength': 'The robot name is too long.',
  'oifde.audioVideoResource.setLanguage': 'Language Setting',
  'oifde.audioVideoResource.preview': 'Preview',
  'oifde.audioVideoResource.viewReferences': 'View References',
  'oifde.audioVideoResource.cancelUpdate': 'Cancel Update',
  'oifde.audioVideoResource.updateBefore': 'Before update',
  'oifde.audioVideoResource.updateAfter': 'After update',
  'oifde.audioVideoResource.templateName': "Enter a template name.",
  'oifde.audio.speedRange':'Value range: [- 200, 500]',
  'oifde.route.sysAudio': 'Voice Management',
  'oifde.title.chooseGrayRule': 'Choose Gray Rule',
  'oifde.title.chatbot': 'Chat bot',
  'oifde.audioVideoResource.cancelUpdate.confirm': 'Are you sure you want to cancel the update?',
  'oifde.audioVideoResource.cancelUpdate.success': 'Update canceled successfully!',
  'oifde.audioVideoResource.cancelUpdate.fail': 'Failed to cancel the update!',
  'oifde.audioVideoResource.upload.tips': 'Tip:Upload the same file repeatedly\, change the file name\, or switch the file upload.',
  'oifde.robotReply.displayName': 'Robot Reply',
  'oifde.robotReply.replyWay': 'Reply Way',
  'oifde.robotReply.replyTemplate': 'Reply Template',
  'oifde.robotReply.selectReplyTemplate': 'Select template',
  'oifde.robotReply.replyVariable': 'Reply Variable',
  'oifde.robotReply.replyResource': 'Reply Resource',
  'oifde.robotReply.selectReplyResource': 'Select resource',
  'oifde.robotReply.replMode': 'Reply Mode',
  'oifde.robotReply.replyWayBreve': 'Way',
  'oifde.robotReply.replyResourceBreve': 'Resource',
  'oifde.robotReply.replyVariableBreve': 'Variable',
  'oifde.robotReply.replyTemplateBreve': 'Template',
  'oifde.robotReply.replModeBreve': 'Mode',
  'oifde.param.maxLength': 'The parameter length cannot exceed ',
  'oifde.tip.oldNode': 'Deprecated diagram elements are not recommended',
  'oifde.diagram.element.expired': 'Expired element',
  'oifde.diagram.element.basic': 'Basic element',
  'oifde.diagram.element.logic': 'Logical element',
  'oifde.diagram.element.business': 'Service element',
  'oifde.diagram.element.composite': 'Composite element',
  'oifde.diagram.node.type': 'Node Type',
  'oifde.validation.repeats.varName': 'Duplicate variable name.',
  'oifde.procedure.mysqlUrlRule': 'Incorrect URL format. For details about the URL format, see jdbc:mysql://ip:port/db_name?prop1=value1&prop2=value2.',
  'oifde.procedure.oracleUrlRule': 'Incorrect URL format. The URL format is jdbc:oracle:thin:{\'@\'}ip:port:SID or jdbc:oracle:thin:{\'@\'}//ip:port/service.',
  'oifde.procedure.gaussUrlRule': 'Incorrect URL format. For details about the URL format, see jdbc:zenith:{\'@\'}ip:port[?key=value[&key=value]..].',
  'oifde.chatBotTest.testCaseGroupName.maxLen': 'The case group name cannot exceed 128 characters.',
  'oifde.chatBotTest.testCaseName.maxLen': 'The case name cannot exceed 128 characters.',
  'oifde.chatBotTest.createCase.maxLen': 'The number of selected test cases cannot exceed 100.',
  'oifde.procedure.selectProcedure': 'Select a Stored procedure',
  'oifde.procedure.resultProcess': 'Result processing',
  'oifde.robotReply.title.chooseTemplate': 'Select Reply Template',
  'oifde.robotReply.title.chooseResource': 'Select Reply Resource',
  'oifde.element.varAssign': 'Variable assignment',
  'oifde.promptType.title': 'Prompt type',
  'oifde.interfaceInvoke.header': 'Interface Message Meader',
  'oifde.interfaceInvoke.body': 'Interface Input Parameter',
  'oifde.interfaceInvoke.queryParam': 'Interface Query Parameter',
  'oifde.interfaceInvoke.method.return': 'Method return value',
  'oifde.unit.millisecond': 'millisecond',
  'oifde.title.transData': 'Obtaining Associated Data',
  'oifde.robotReply.title.addResource': 'Add Reply Resource',
  'oifde.mxgraph.saveDraft': 'Save Draft',
  'ccivr.nms.conditionService.expression.placeholder':'Enter a condition expression.',
  'oifde.diagram.error.basicEnd.length': 'The value cannot exceed 4096 characters.',
  'oifde.tableData.template': 'Download data template',
  'oifde.tableData.download.template': 'Download Template',
  'oifde.tableData.import.max': 'A maximum of 10000 data records can be imported.',
  'oifde.tableData.import.tableName': 'Data Table Name',
  'oifde.tableData.diagram.elementName': 'Data Table',
  'oifde.tableData.diagram.searchCriteria': 'Search Criteria',
  'oifde.tableData.diagram.searchPage': 'Search Page',
  'oifde.tableData.diagram.searchResult': 'Search Result',
  'oifde.tableData.diagram.returnRecords': 'Return Records',
  'oifde.tableData.diagram.total': 'Total',
  'oifde.procedure.db.dbName':'Database name',
  'oifde.procedure.procedureDbName':'Stored procedure database name',
  'oifde.procedure.dbNameWarning':'The database name can contain only letters\, digits, spaces\, underscores\, and single quotation marks.',
  'oifde.procedure.dbNameError':'The stored procedure database name cannot be empty or contains a maximum of 64 characters.',
  'oifde.procedure.actions.deleteOnlyOneDb':'Deletion failed! You cannot delete all database connection information.',
  'oifde.serviceInterface.actions.deleteDbInfo':'This operation will delete the selected database information. Are you sure you want to continue?',
  'oifde.procedure.actions.deleteDBFailed':'Delete failed! The database connection is referenced by the stored procedure {0} and cannot be deleted. Please check.',
  'oifde.procedure.actions.deleteDBFailed2': 'Delete failed! The database connection has been referenced by {1} stored procedures, such as {0}. Please check.',
  'oifde.procedure.actions.deleteFailed': 'Deleting failed!',
  'oifde.procedure.actions.createDBFailed': 'Creating failed! The number of databases reaches the upper limit.',
  'oifde.procedure.databaseConnection': 'Database connection',
  'oifde.workday.workDayName': 'Work Calendar Name',
  'oifde.workday.workDayDesc': 'Description',
  'oifde.workday.workWeekDay': 'Week Selection',
  'oifde.workday.servicePeriod': 'Service Period',
  'oifde.workday.specificNonServiceDay': 'Specified Non-service Date',
  'oifde.workday.specificServiceDay': 'Specified Service Date',
  'oifde.workday.servicePeriodDesc': 'Service Period. Configure 1 to 10 records',
  'oifde.workday.specificNonServiceTip': 'If a specified non-service date is configured, the non-service date is considered as a non-workday with the highest priority.',
  'oifde.workday.specificServiceTip': 'If a configured specified service date is not available in week selection, the service date is considered as a workday.',
  'oifde.workday.name': 'Name',
  'oifde.workday.time': 'Time',
  'oifde.workday.date': 'Date',
  'oifde.workday.addWorkDayTitle': 'Add Work Calendar',
  'oifde.workday.editWorkDayTitle': 'Modify Work Calendar',
  'oifde.workday.required': 'This field is mandatory.',
  'oifde.workday.updateTime': 'Modified',
  'oifde.workday.operation': 'Operation',
  'oifde.workday.actions.edit': 'Edit',
  'oifde.workday.actions.delete': 'Delete',
  'oifde.workday.actions.prompt': 'Confirm',
  'oifde.workday.actions.confirm': 'Yes',
  'oifde.workday.actions.cancel': 'No',
  'oifde.workday.actions.ok': 'OK',
  'oifde.workday.actions.deletePrompt': 'This operation will permanently delete the work calendar. Are you sure you want to continue?',
  'oifde.workday.actions.cancelDelete': 'The deletion is canceled.',
  'oifde.workday.actions.deleteSuccess': 'Deletion successful.',
  'oifde.workday.actions.updateSuccess': 'Modification successful.',
  'oifde.workday.actions.createSuccess': 'Creation successful.',
  'oifde.workday.actions.missWorkDayName': 'Work Calendar Name cannot be empty.',
  'oifde.workday.actions.missWorkWeekDay': 'Week Selection cannot be empty.',
  'oifde.workday.actions.minServicePeriod': 'At least one record must be configured for the Service Period.',
  'oifde.workday.actions.missPeriodName': 'Service Period Name cannot be empty.',
  'oifde.workday.actions.missPeriodTime': 'Service Period Time cannot be empty.',
  'oifde.workday.actions.missNonServiceName': 'The name of Specified Non-service Date cannot be empty.',
  'oifde.workday.actions.missNonServiceDate': 'Specified Non-service Date cannot be empty.',
  'oifde.workday.actions.missServiceName': 'The name of Specified Service Date cannot be empty.',
  'oifde.workday.actions.missServiceDate': 'Specified Service Date cannot be empty.',
  'oifde.workday.tip.overServicePeriod': 'The number of Service Period records cannot exceed 10.',
  'oifde.workday.tip.overNonServiceDate': 'The number of Specified Non-service Date records cannot exceed 50.',
  'oifde.workday.tip.overServiceDate': 'The number of Specified Service Date records cannot exceed 50.',
  'oifde.workday.tip.overWorkDayCount': 'The number of Work Calendar records cannot exceed 1000.',
  'oifde.workday.actions.nameError': 'All names are allowed only Chinese characters, English letters, digits, spaces, underscores (_), hyphens (-), and single quotation marks (\').',
  'oifde.tableData.diagram.addCriteria': 'Add Group',
  'oifde.tableData.diagram.logic': 'Logic',
  'oifde.diagram.message.recoverData': 'Restoring Data',
  'oifde.diagram.message.recoverDataDesc': 'Do you want to render the canvas using the last temporarily saved process data?',
  'oifde.button.openVariable': 'Open Variable >',
  'oifde.button.closeVariable': 'Collapse variables <',
  'oifde.varGlobal.builtIn': 'Global(built-in)',
  'oifde.varGlobal.custom': 'Global',
  'oifde.route.workDay': 'Work Calendar',
  'oifde.dataTable.tableName': 'Table Name',
  'oifde.dataTable.tableId': 'Table ID',
  'oifde.dataTable.tableField': 'Table Field',
  'oifde.dataTable.fieldId': 'Field ID',
  'oifde.dataTable.fieldName': 'Field Name',
  'oifde.dataTable.fieldType': 'Field Type',
  'oifde.dataTable.fieldLength': 'Field Length',
  'oifde.dataTable.defaultValue': 'Default Value',
  'oifde.dataTable.deleteTips': 'This operation will permanently delete the data table and the data in the data table. Are you sure you want to continue?',
  'oifde.dataTable.deleteBatchTips': 'No data table to be deleted is selected.',
  'oifde.dataTable.editDataTable': 'Edit Data Table',
  'oifde.dataTable.addDataTable': 'Adding a Data Table',
  'oifde.dataTable.editDataTableField': 'Editing a Table Field',
  'oifde.dataTable.addDataTableField': 'Adding a Table Field',
  'oifde.dataTable.validation.tableId': 'Only letters, digits, and underscores are allowed. The value cannot start with a digit.',
  'oifde.dataTable.validation.presetField': 'ID, TENANTID, CREATETIME, and REMOVETIME are preset fields and are case insensitive. Do not use them.',
  'oifde.dataTable.fieldsMaxSize': 'The number of table fields cannot exceed 20.',
  'oifde.dataTable.fieldsNotEmpty': 'The table field cannot be empty.',
  'oifde.dataTable.exceedMaxSize': 'The number of data tables cannot exceed 40.',
  'oifde.dataTable.saveAndInsert': 'Save & Add Data',
  'oifde.service.table.dialog.title': 'Data',
  'oifde.service.table.dialog.selectField': 'Select Field',
  'oifde.service.table.dialog.inputValue': 'Input field value',
  'oifde.service.table.dialog.addRecord': 'Add Data',
  'oifde.service.table.dialog.editRecord': 'Edit Data',
  'oifde.service.table.dialog.integer': 'Only integers are allowed.',
  'oifde.service.table.dialog.string': 'The maximum length of a value of the string type is 10000',
  'oifde.service.table.dialog.oneday': 'Last 1 day',
  'oifde.service.table.dialog.threeday': 'Last 3 days',
  'oifde.service.table.dialog.sevenday': 'Last 7 Days',
  'oifde.service.table.dialog.custom': 'Custom',
  'oifde.service.table.dialog.batchdelete': 'Confirm Batch Deletion',
  'oifde.service.table.dialog.delete': 'Confirm deletion',
  'oifde.dataTable.criteria': 'Criteria',
  'oifde.dataTable.diagram.fieldName': 'Field Name',
  'oifde.dataTable.diagram.fieldValue': 'Field Value',
  'oifde.service.table.import.max.data': 'The total amount of imported data exceeds 10000',
  'oifde.service.table.import.file.empty': 'The read file content is empty',
  'oifde.service.table.import.file.read': 'Failed to parse the file!',
  'oifde.service.table.import.excute.max': 'An import task is being executed. Wait until the last import is complete',
  'oifde.service.table.import.field.error': 'The table field does not exist.',
  'oifde.service.table.import.string.max': 'The sum of the values of the string type exceeds 10000 .',
  'oifde.service.table.import.fieldType.error': 'The field type and value do not match.',
  'oifde.title.chooseGreyRule': 'Choose Grey Rule',
  'oifde.conditionService.param.tips': 'When a conditional expression contains string constants, quotation marks must be added to the expression.',
  'oifde.common.btn.batch.operation': 'Batch operation',
  'oifde.char.invalid': 'Invalid characters',
  'oifde.validate.validChar': 'Allowed character range: ',
  'oifde.service.table.dialog.nodatedelete': 'No data to be deleted is selected',
  'oifde.service.table.dialog.exceedMaxSize': 'A maximum of 10000 records can be added',
  'oifde.variable.rightList.tips': 'The variables displayed on the right can be dragged to the variable text box.',
  'oifde.service.table.import.field.length.error': 'The field length exceeds the limit.',
  'oifde.screenfull.cancel': 'Exit Full Screen',
  'oifde.variable.sys.callMediaType': 'Call media type.',
  'oifde.variable.sys.systemDate': 'System Date(YYYYMMDD)',
  'oifde.variable.sys.systemMonth': 'System year and month(YYYYMM)',
  'oifde.variable.sys.currentTime': 'Current system time(yyyyMMddHHmmss)',
  'oifde.variable.sys.systemTime': 'Current system time(HHmmss)',
  'oifde.variable.sys.timeStamp': 'Indicates the timestamp. The value is a character string. January 01, 1970 00:00:00 GMT (Beijing time, 01-01-1970 08:00:00) Total number of seconds since.',
  'oifde.variable.sys.createTime': 'Call Start Time',
  'oifde.variable.sys.callingNumber': 'Customer number, which is the calling number when a call is made to the cloud contact center and the called number when a call is made to the cloud contact center. Character string type. The number is transferred by the IVR process.',
  'oifde.variable.sys.calledNumber': 'Access code of a system flow. Character string type. The number is transferred by the IVR process and is the process access code. You can log in to the WAS configuration console of the CTI platform to view the number.',
  'oifde.variable.sys.realCalledNumber': 'Access code, which is configured in the called party configuration of the flow, that is, the hotline number that is dialed to call the flow.',
  'oifde.variable.sys.callId': 'ODFS session ID. Character string type. The ID is transferred by the IVR process, that is, IVRREQUEST.call_id.',
  'oifde.variable.sys.ctiCallid': 'CTI call ID transferred by the IVR process, that is, IVRREQUEST.cti_callid in the IVR request.',
  'oifde.variable.sys.tenantId': 'Indicates the current tenant ID.',
  'oifde.variable.sys.language': 'Current Customer Language',
  'oifde.variable.ivrRequest.ctiCallid': 'CTI call ID transferred by Huawei IVR. In other scenarios, this parameter is left blank. The value contains a maximum of 32 characters.',
  'oifde.variable.ivrRequest.input': 'Dialog interaction result.',
  'oifde.variable.ivrRequest.IACmd': 'Special fields for intelligent matching and intelligent work order filling.',
  'oifde.variable.ivrRequest.TextSource': 'Source of the request text.',
  'oifde.variable.ivrRequest.transinData': 'Channel-associated data parameters agreed between the access party and the OIAP, for example, data packet parameters transferred by the IVR.',
  'oifde.variable.ivrRequest.beginPlay': 'Start time of announcement playing.',
  'oifde.variable.ivrRequest.endPlay': 'End time of voice playing.',
  'oifde.variable.ivrRequest.callId': 'Unique ID of a call. The value is the same as the value of userid and is used by the flow.',
  'oifde.variable.ivrRequest.interIdx': 'Number of consecutive interactions with a user, which is recorded in the ODFS.',
  'oifde.variable.ivrRequest.feedback': 'Satisfaction: 1 (satisfactory) 0 (unsatisfied). The value is an integer.',
  'oifde.variable.ivrRequest.feedbackContent': 'Indicates the reason for dissatisfaction. If the feedback is satisfactory, the value is null.',
  'oifde.variable.ivrRequest.channelType': 'Customer access channel type. For example, WEB, INSTAGRAM, TELEGRAM, WECHAT, FACEBOOK, TWITTER, EMAIL, LINE, WHATSAPP, 5G, SMS',
  'oifde.variable.ivrRequest.ieValue': 'Title of the received email when the email channel is accessed.',
  'oifde.variable.toc.ChatBot': 'Slot parameters configured on the intent',
  'oifde.variable.toc.ChatBotIntentCode': 'The intention code is returned for the matched intention in speech recognition.',
  'oifde.variable.toc.ChatBotRespContent': 'Reply information returned by the matched intention, including the slot ID and reply configured in the response.',
  'oifde.variable.toc.ChatBotScenarioName': 'Name of the intention template that is matched after speech recognition.',
  'oifde.variable.toc.ChatBotResponse': 'Identify all content in the interface.',
  'oifde.datatable.open.type':'Open the exported file in UTF-8 format.',
  'oifde.datatable.dateValue.tip': 'Timestamp, in milliseconds. Enter a long integer or character value or variable.',
  'oifde.datatable.intList.tip': 'Example: [1, 2, 3].',
  'oifde.datatable.stringList.tip': 'Example: ["a","b","c"].',
  'oifde.expression.expr_number_varOrValue': 'Enter a value or variable of the numeric type.',
  'oifde.expression.expr_list_varOrValue': 'Enter a value or variable of the list type.',
  'oifde.service.table.import.index': 'Line {0}',
  'oifde.flow.rule.noRule': 'Failed to find the gray rule! Check whether the rule is deleted.',
  'oifde.cell.viewRefrenceInfo': 'Reference Information',
  'oifde.cell.viewRefrenceInfo.flow': 'Process Reference',
  'oifde.cell.viewRefrenceInfo.complexcell': 'Complex Cell Reference',
  'oifde.accessCode.select.max': '{0} records have been selected. A maximum of {1} records can be selected.',
  'oifde.mxgraph.deleteDraft': 'Delete Draft',
  'oifde.mxgraph.deleteDraft.confirm': 'Are you sure you want to delete the draft?',
  'oifde.mxgraph.deleteDraft.noDraftExist': 'No draft data exists.',
  'oifde.resource.needApproval': 'Reply resources are created successfully and can be used only after being approved by the system administrator.',
  'oifde.common.validation.accessCode.description': 'Line breaks and the following special characters are not allowed: "[]\'&()=;<>,',
  'oifde.tableData.import.tableNameBreve': 'Table Name',
  'oifde.flow.actions.publish.existDraft': 'The current process contains draft data. Edit and release it, or ignore the draft and release it directly.',
  'oifde.export.finish.success': 'Exported successfully!',
  'oifde.input.tips.varAndConst': 'Enter a variable or constant. Use quotation marks before and after the string.',
  'oifde.export.keyPressRobot.title': 'Exporting Common IVR Records',
  'oifde.export.password': 'Compress Password',
  'oifde.export.password.placeholder': 'Please enter the compress password.',
  'oifde.export.password.error.tips': 'Enter a password of 8 to 12 characters for generating compressed files. The password must contain digits, letters, and special characters except /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r.',
  'oifde.export.password.error.message': 'The value must contain digits, letters, and special characters except /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.task.status': 'Task Status',
  'oifde.export.task.fail': 'Export failed!',
  'oifde.export.businessInterface.title': 'Exporting Custom Interface Records',
  'oifde.export.variable.title': 'Exporting Custom Variable Records',
  'oifde.export.complexcell.title': 'Export composite diagram element records',
  'oifde.export.accessCode.title': 'Exporting Intelligent Robot Records',
  'oifde.export.intelligentIVRFlow.title': 'Exporting Process Records',
  'oifde.export.intelligentIVRFlow.button': 'Export flow',
  'oifde.route.2dDigitalHumanImg': 'Virtual Human',
  'oifde.route.add2dDigitalHumanImg': 'Create Virtual Human',
  'oifde.digitalHuman.outputSetting': 'Output Settings',
  'oifde.digitalHuman.standingPosition': 'Positioning',
  'oifde.digitalHuman.stationTemplate': 'Position template',
  'oifde.digitalHuman.preciseAdjustment': 'Precisely adjust',
  'oifde.digitalHuman.left': 'Left',
  'oifde.digitalHuman.center': 'Center',
  'oifde.digitalHuman.right': 'Right',
  'oifde.digitalHuman.imageProportion': 'Image Ratio',
  'oifde.exportdata.message.overData': 'The number of exported records exceeds 10000. Please select another query condition.',
  'oifde.flow.title.publishFlow': 'Release Flow',
  'oifde.flow.title.upgrade': 'Upgrade Flow',
  'oifde.flow.action.editInfo': 'Edit Information',
  'oifde.flow.title.editInfo': 'Editing Basic Flow Information',
  'oifde.robot.title.editInfo': 'Editing Basic Chatbot Information',
  'oifde.input.placeholder.searchName': 'Search Name',
  'oifde.avatar.tenantId':'Tenant ID',
  'oifde.avatar.tenantSpace':'Tenant Space',
  'oifde.avatar.avatarName':'Role Name',
  'oifde.avatar.avatarCode':'Role Code',
  'oifde.avatar.headerImageUrl':'Image',
  'oifde.avatar.serverAddress':'Server Address',
  'oifde.avatar.avatarAppKey':'Server User Name/AK',
  'oifde.avatar.create':'Create Virtual Human Service',
  'oifde.avatar.update':'Edit Virtual Human Service',
  'oifde.avatar.headerImage': 'Profile Picture',
  'oifde.avatar.poseImage': 'Pose Picture',
  'oifde.avatar.actionList':'Action List',
  'oifde.avatar.form.message': '2D Virtual Human Information',
  'oifde.avatar.service.message':'Server Information',
  'oifde.avatar.serverPort':'Server Port',
  'oifde.avatar.userName':'Server User Name',
  'oifde.avatar.pwd':'Server Password',
  'oifde.avatar.checkPwd':'Confirm Server Password',
  'oifde.avatar.resolution': 'Resolution',
  'oifde.avatar.imgRule': 'Only the JPG, PNG, and JPEG formats are supported. The file size cannot exceed 2 MB.',
  'oifde.avatar.action_1': 'Greetings',
  'oifde.avatar.action_2': 'Hands Forward',
  'oifde.avatar.action_3': 'Nod',
  'oifde.avatar.action_4': 'Hands Clasped',
  'oifde.avatar.action_5': 'Spread Left Hand',
  'oifde.avatar.action_6': 'Spread Right Hand',
  'oifde.avatar.selectTitle': 'Select a Avatar Image',
  'oifde.digitalHuman.imgLimit': 'Only JPG, PNG, and JPEG image files are supported. The file size cannot exceed 2 MB.',
  'oifde.digitalHuman.imageProportion0-5': '0.5',
  'oifde.digitalHuman.imageProportion0-75': '0.75',
  'oifde.digitalHuman.imageProportion1': '1',
  'oifde.digitalHuman.canvasNoSupport': 'The current browser does not support canvas. Upgrade or change the browser first.',
  'oifde.digitalHuman.noRoleData': 'No role data. Contact the operation personnel to add the role data.',
  'oifde.digitalHuman.sizeError': 'Incorrect image resolution. Upload a background image in the format of {0} x {1}.',
  'oifde.title.add.complexCell': 'New Composite Element',
  'oifde.title.add.complexCellCatalog': 'Creating a composite diagram element directory',
  'oifde.title.edit.complexCellCatalog': 'Edit Composite Element Directory',
  'oifde.title.add.rule': 'New Rule',
  'oifde.title.edit.rule': 'Edit Rule',
  'oifde.ivrJourney.pleaseSelectData': 'Please select data',
  'oifde.digitalHuman.needRole': 'Select a role.',
  'oifde.digitalHuman.actions.deletePrompt': 'This operation will permanently delete the virtual human. Continue?',
  'oifde.avatar.pleaseSelectData': 'The modified data will not be saved after closing. Are you sure you want to continue?',
  'oifde.avatar.validation.ttsSoundSpeed': 'Enter one decimal place between 0.5 and 1.5.',
  'oifde.avatar.validation.scale': 'Enter one decimal place between 0.5 and 1.0.',
  'oifde.digitalHuman.actions.savePrompt': 'This operation will update the virtual human. Continue?',
  'oifde.digitalHuman.coordinate': 'Coordinate',
  'oifde.digitalHuman.dimensions': 'Dimensions',
  'oifde.digitalHuman.imgTypeError': 'Incorrect type. Only JPG, PNG, and JPEG formats are supported.',
  'oifde.expression.expr_var_flow': 'Enter a process variable starting with FLOW',
  'oifde.expression.expr_string_or_var': 'Enter a value of the variable or character string type. Use double quotation marks before and after the string.',
  'oifde.common.actions.deleteConfig': 'This operation will permanently delete the configuration. Are you sure you want to continue?',
  'oifde.expression.numberCharStartWith0': 'Use double quotation marks before and after a numeric string starting with 0. For example, 05 must be entered as "05". Otherwise, the value is regarded as 5.',
}
