<template>
  <div class="view-export-list">
    <el-dialog
      v-model="isShow"
      :title="$t('cms.export.field.viewExportTask')"
      width="896"
      class="aicc-dialog-limit-height user-allocate-page"
    >
      <sweet-row justify="space-between" align="middle">
        <sweet-col :span="12" :order="1"> </sweet-col>
        <sweet-col :span="12" :order="2" style="text-align: right">
          <el-button class="aicc-button-icon" @click="handleRefresh">
            <img class="refresh-icon-img" src="@/assets/img/search/search_refresh.png" alt="" />
          </el-button>
        </sweet-col>
      </sweet-row>

      <aicc-table
        :tableData="tableData"
        row-key="id"
        @handlePagination="handlePagination"
        :tableColumns="tableColumns"
        :paginationConfig="paginationConfig"
        :tableTools="{ columnConfig: false, showPagination: true }"
      >
        <template #operation="scope">
          <el-button
            link
            type="primary"
            size="default"
            @click="handleDownload(scope)"
            v-if="scope.row.result === '2'"
          >
            {{ $t('cms.export.field.download') }}
          </el-button>
        </template>
      </aicc-table>
    </el-dialog>
  </div>
</template>

<script>
import i18n from '@/lang'
import {queryExportTask} from '@/views/cms/api'
import {transTime} from '@/views/cms/utils/timeUtils'
import {aiccDownload} from '@/utils/aiccUploadAndDownload'
import AiccElMessage from '@/utils/el-message'
import cookie from "@/utils/cookie";

const $t = i18n.global.t

export default {
  name: 'view-export-list',
  data() {
    return {
      locale: '',
      isDownLoad: true,
      tableData: [],
      isShow: false,
      limit: 10,
      offset: 0,
      currentPage: 1,
      paginationConfig: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        layout: 'total, prev, pager, next'
      }
    }
  },
  mounted() {
    this.locale = cookie.getCookie('u-locale');
  },
  props: {
    serviceType: {
      type: String,
      required: true
    }
  },
  methods: {
    open() {
      this.isShow = true
      this.callBackendQueryExportTask()
    },
    handlePagination(val) {
      this.limit = val.limit
      this.offset = val.offset
      this.callBackendQueryExportTask()
    },
    handleDownload({ row }) {
      if (this.isDownLoad) {
        this.isDownLoad = false
        setTimeout(() => {
          this.isDownLoad = true
        }, 5000)

        const params = {
          exportReq: {
            id: row.id
          }
        }
        aiccDownload(params, '/service-cloud/u-route/cms/exporttask/downloadexporttask')
      } else {
        AiccElMessage.info($t('cms.voiceinspection.message.frequentDownload'))
      }
    },
    handleRefresh() {
      this.tableData = []
      this.paginationConfig.total = 0
      this.offset = 0
      this.currentPage = 1
      this.callBackendQueryExportTask()
    },
    callBackendQueryExportTask() {
      const params = {
        limit: this.limit,
        offset: this.offset,
        serviceType: this.serviceType
      }
      queryExportTask(params).then((res) => {
        if (!res.data) {
          this.tableData = []
          this.paginationConfig.total = 0
          return
        }
        for (const data of res.data) {
          data.beginTime = transTime(data.beginTime)
          data.endTime = transTime(data.endTime)
          if (data.result === '1') {
            // 导出中不应该有结束时间
            delete data.endTime
          }
          data.status = this.taskMap.get(data.result)
        }
        this.tableData = res.data
        this.paginationConfig.total = res.total
      })
    }
  },
  computed: {
    // 任务状态
    taskMap() {
      let taskMap = new Map()
      taskMap.set('0', $t('cms.export.field.unprocessed'))
      taskMap.set('1', $t('cms.export.field.exportiong'))
      taskMap.set('2', $t('cms.export.field.success'))
      taskMap.set('3', $t('cms.export.field.failed'))
      return taskMap
    },
    operateWidth() {
      if (top.window.innerWidth < 1920) {
        if (this.locale === 'zh_CN') {
          return '59px';
        } else if (this.locale === 'fr_FR') {
          return '128px';
        } else {
          return '98px';
        }
      } else {
        if (this.locale === 'zh_CN') {
          return '63px';
        } else if (this.locale === 'fr_FR') {
          return '145px';
        } else {
          return '105px';
        }
      }
    },
    tableColumns() {
      return [
        {
          label: this.$t('cms.export.field.exportBeginTime'),
          'min-width': '250px',
          prop: 'beginTime'
        },
        {
          label: this.$t('cms.export.field.exportEndTime'),
          'min-width': '250px',
          prop: 'endTime'
        },
        {
          label: this.$t('cms.export.field.exportTaskStatus'),
          prop: 'status'
        },
        {
          label: this.$t('cms.export.field.operation'),
          width: this.operateWidth,
          'show-overflow-tooltip': false,
          fixed: 'right',
          slot: 'operation'
        }
      ]
    },
  }
}
</script>

<style scoped>
.aicc-button-icon {
  float: right;
  margin-bottom: 10px;
}

img {
  width: 16px;
  position: relative;
  top: 1px;
}
body.RTL .aicc-button-icon {
  float: left;
}
</style>
