<template>
  <sweet-dialog v-model="SMSDialogVisible" draggable :before-close="handleClose" :close-on-click-modal="false"
                width="616px">
    <template #header>
      <div class="cust_header">
        <i class="sweetui-icon-left return_icon" v-if="!showForm" @click="closeOtherNav"></i>
        <span class="dialog_title" v-if="showForm">{{
            $t('ccnotification.sms.rcvAndSendMode.sendSms')
          }}</span>
        <span class="dialog_title" v-else-if="showTemplate && !showOrgWindow">{{
            $t('ccnotification.notifition.sendmessage.templateselect')
          }}</span>
        <span class="dialog_title" v-else-if="showOrgWindow">{{
            $t('cms.report.field.selectOrg')
          }}</span>
        <span class="dialog_title" v-else>{{
            $t('ccnotification.notifition.sendmessage.sender')
          }}</span>
      </div>
    </template>
    <sweet-form
        ref="ruleFormRef"
        :model="smsForm"
        :label-position="direction"
        label-width="auto"
        :rules="rules"
        v-if="showForm"
    >
      <sweet-form-item :label="$t('SM.AUC.LABEL.KIND')">
        <sweet-radio-group v-model="radio" @change="changeSMSType()" class="sms-type-radio-group">
          <sweet-radio label="0">{{ $t('ccm.satisfactionconfig.message.smscloud') }}</sweet-radio>
          <sweet-radio label="1">{{ $t('ccnotification.sms.gateway') }}</sweet-radio>
          <sweet-radio v-if="callInfo.fromConsole" label="2">{{ $t('ccm.sms.meeting.link') }}</sweet-radio>
        </sweet-radio-group>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.sender')" prop="senderNo"
                       v-if="radio === '1' || radio === '2'">
        <div class="sender-select">
          <sweet-select
            v-model="smsForm.senderNo"
            :placeholder="$t('ccnotification.notifition.sendmessage.entersender')"
            readonly
            :suffix-icon="SelectMore"
            class="sender-select"
            @click="selectCaller()"
          />
        </div>
      </sweet-form-item>

      <!-- 模板选择框 -->
      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.referenceTemplate')"
                       prop="name" v-if="radio ==='0'">
        <div class="switch-group">
          <sweet-select v-model="smsForm.name"
                       :placeholder="$t('ccnotification.notification.variable.templateselect')"
                       readonly
                       :suffix-icon="SelectMore"
                       @click="selectCloudSMSTemplate()"
          ></sweet-select>
        </div>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.referenceTemplate')" v-if="radio === '1'">
        <div class="switch-group">
          <sweet-switch v-model="smsForm.smsGW.templateSwitch" @change="templateSwitch()"
                        class="switch-group-switch-margin"/>
          <div class="input-with-tips" style="width:346px">
              <sweet-select v-if=" (radio === '1' && smsForm.smsGW.templateSwitch)" id="gwTemplate"
                           v-model="smsForm.name"
                           :placeholder="$t('ccnotification.notification.variable.templateselect')"
                           readonly
                           :suffix-icon="SelectMore"
                           @click="selectCloudSMSTemplate()"
              ></sweet-select>
            <span v-if="smsForm.smsGW.templateSwitch && smsForm.smsGW.checkValue" class="error-text">
              {{ $t('ccnotification.notification.variable.templateselect') }}
            </span>
          </div>
        </div>
      </sweet-form-item>
      <!-- 模板选择框 结束-->

      <sweet-form-item
          :label="$t('ccnotification.notifition.sendmessage.recipient')"
          class="smsForm_recipient"
          prop="audioVideoCaller"
      >
        <sweet-input :placeholder="receiverPlaceHolder" v-model="smsForm.audioVideoCaller" style="width: 400px;"/>
      </sweet-form-item>

      <!-- params setting template -->
      <div v-if="smsForm.params.length > 0 ">
        <sweet-form-item :label="$t('ccnotification.notifition.template.title.setting')">
          <sweet-table :data="smsForm.params" border style="width: 100%" show-overflow-tooltip="true" max-height="260">
            <sweet-table-column prop="index" :label="$t('ccmessaging.chat.template.table.no')" width="60px"/>
            <sweet-table-column v-if="radio === '0'" prop="type" :label="$t('ccm.agent.label.usedMode')"
                                show-overflow-tooltip="true"
                                width="100px"></sweet-table-column>
            <sweet-table-column v-if="radio === '1'" prop="name" show-overflow-tooltip="true"
                                :label="$t('ccnotification.notifition.variable.variablename')"
                                min-width="90px"></sweet-table-column>
            <sweet-table-column prop="des" :label="$t('ccm.agent.label.description')"
                                show-overflow-tooltip="true"></sweet-table-column>
            <sweet-table-column :label="$t('ccm.callout.lable.default')" min-width="300px">
              <template v-slot="scope">
                <div class="input-with-tips">
                  <div class="input-with-icon">
                    <sweet-input v-model="scope.row.value" :id="'paramsIndex'+scope.row.index "
                                 @keyup="paramsValueChange(scope.row)" maxlength="500"
                                 clearable @clear="paramsValueChange(scope.row)"/>
                    <div class="form-item-label-icon" v-if="radio==='0'">
                      <sweet-tooltip effect="light" placement="top"
                                     :content="scope.row.tips">
                        <i class="sweetui-icon-tip-l icon-button"></i>
                      </sweet-tooltip>
                    </div>
                  </div>
                  <span v-if="!scope.row.validateValue" class="error-text">{{ scope.row.error }}</span>
                </div>
              </template>
            </sweet-table-column>
          </sweet-table>
        </sweet-form-item>
      </div>

      <sweet-form-item prop="messageTextarea">
        <sweet-input v-if="radio === '1'" v-model="smsForm.messageTextarea" rows="10"
                     :readonly="smsForm.smsGW.templateSwitch"
                     show-word-limit type="textarea"/>
      </sweet-form-item>

    </sweet-form>

    <!-- 模板选择框 -->
    <div v-if="showTemplate" v-show="showTemplateWindow" id="smsTemplateWindow">
      <div class="search-nav">
        <div class="show-template-search">
          <org-tree-select  class="aicc-size--1-of-1" ref="orgsRef" :data="orgsData" multiple @onSelectChange="onOrgsSelected" @onClear="onOrgsClear" :placeholder="$t('ccd.label.org.all')"></org-tree-select>
        </div>
        <aicc-advanced-search ref='templateSearchRef' :templateMetaData="templateMetaData"
                              @treeSelectChange="handleSearch" @handleRefresh="handleSearch"
                              @handleSearch="handleSearch" style="display: inline-block; width: 400px !important;">
        </aicc-advanced-search>
      </div>
      <aicc-table
          :tableData="tableMetaData.tableData"
          :tableColumns="tableMetaData.tableColumns"
          :tableTools="{ showPagination: false, columnConfig: false }"
          @radio-change="handleSelected"
          :is-not-reverse-column="true"
          ref='selectTemplateRef'>
      </aicc-table>
      <sweet-form inline>
        <sweet-form-item>
          <sweet-pagination
              v-model:current-page="currPage"
              :page-size="templateLimit"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, total, prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </sweet-form-item>
      </sweet-form>
    </div>



    <!-- 号码选择框 -->
    <div v-if="showSendNoSelectWindow" class="assign-window">
      <div class="assign-input">
        <sweet-input class="item-end" v-model="senderNoSearchParam" v-number-input
          :placeholder="$t('ccn.smsnoconfig.pop.search.reminder')"
          clearable @clear="search" width="240px" @keyup.enter="search">
          <template #prefix>
            <sweet-icon style="cursor: pointer;" @click="search"><Search /></sweet-icon>
          </template>
        </sweet-input>
        <div class="new-refresh-icon"
             @click="refresh">
          <img class="img-icon refresh-icon-img" src="@/assets/img/search/search_refresh.png"
               alt=""/>
        </div>
      </div>
      <aicc-table
          :tableData="senderTableMetaData.tableData"
          :tableColumns="senderTableMetaData.tableColumns"
          :tableTools="{ showPagination: false, columnConfig: false }"
          @radio-change="handleSenderNoSelected"
          :is-not-reverse-column="true"
          ref='selectSenderNoRef'>
      </aicc-table>
      <sweet-form inline>
        <sweet-form-item>
          <sweet-pagination
              v-model:current-page="senderNoCurrPage"
              :page-size="senderLimit"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, total, prev, pager, next"
              :total="senderNoTotal"
              @size-change="handleSenderSizeChange"
              @current-change="handleSenderCurrentChange"
          />
        </sweet-form-item>
      </sweet-form>
    </div>

    <template #footer>
    <span class="dialog-footer">
        <sweet-button @click="handleCancel"> {{ $t('ccm.agent.button.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="advanceSubmit"> {{
            $t('SM.COMMON.BUTTON.OK')
          }}
        </sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script>
export default {
  name: 'SendSMSNotificationDialog',
}
</script>

<script setup>
import {Search} from '@element-plus/icons-vue';
import {getUserAccess} from "@/utils/organization";
const currentInstance = getCurrentInstance()
import {computed, reactive, onMounted, ref, toRefs, watch, getCurrentInstance} from "vue";
import AiccTable from '@/components/aicc-table/index.vue'
import {
  queryDataDictionary,
  queryMessageTemplate,
  querySmsNumberConfigList,
  queryVariableByTemplateId
} from "@/views/ccmanagement/ccma-api/otherProjectsRequest";
import {audioVideoSendHuaSms, audioVideoSendMspSms, audioVideoSendTemplateSms} from "@/views/ccmanagement/ccma-api";
import cookie from '@/utils/cookie'
import {queryorgsbyid} from "@/views/ccmessaging/api/online-chat-workbench";
import AiccElMessage from '@/utils/el-message';
import validate from "@/utils/validate";
import SelectMore from '@/views/ccmanagement/components/select-more/SelectMore.vue';

const total = ref(0); // 总条数
const currPage = ref(1); // 当前页
const senderLimit = ref(10); // 每页条数
const templateLimit = ref(10); // 每页条数
const offset = computed(() => (currPage.value - 1) * 10); // 偏移量
const selectedItem = ref(); // 被选择的数据
const props = defineProps(['SMSDialogVisible', 'callInfo']) // 组件属性
const {SMSDialogVisible, callInfo} = toRefs(props); // 处理弹框
const emit = defineEmits(['handleSMSClose'])
const showForm = ref(true);
const showTemplate = ref(false);
const showTemplateWindow = ref(true);
const radio = ref('0');
const direction = computed(() => window.isRTL ? 'right' : 'left');
const templateSearchRef = ref(null);
const ruleFormRef = ref();
const intoSendingMeeting = ref(false);
const receiverPlaceHolder = ref('');
let accessOrgCache =  ref([])
const orgsData = ref([])
const selectedOrgs = ref([])
const selectTemplateRef = ref();
const validRecipient = (rule, value, callback) => {
  //初次检查为空，直接返回
  if (!value?.trim()) {
    return
  }

  const recipients = value.split(",");
  if (radio.value === '2' && recipients && recipients.length > 1) {
    return callback(new Error($t('agentconsole.ccnotification.recipient.validate.limit.one')));
  }
  for (const recipient of recipients) {
    if (!recipient) {
      return callback(new Error($t('agentconsole.ccnotification.recipient.validate.error')));
    }
    if (recipient.length > 20) {
      return callback(new Error($t('agentconsole.ccnotification.recipient.validate.error')));
    }
    if (!/^\d+$/.test(recipient)) {
      return callback(new Error($t('agentconsole.ccnotification.recipient.validate.error')));
    }
  }
  const set = new Set(recipients);
  if (recipients.length !== set.size) {
    return callback(new Error($t('agentconsole.ccnotification.recipient.validate.error')));
  }
  return callback()
}

const rules = reactive({
  senderNo: [{
    required: true,
    message: $t('ccnotification.notifition.sendmessage.entersender'),
    trigger: 'blur'
  }],
  audioVideoCaller: [{
    required: true,
    message: $t('agentconsole.ccnotification.recipient.cannot.empty'),
    trigger: 'blur'
  }, {validator: validRecipient, trigger: ['blur','change']}],
  name: [{
    required: true,
    message: $t('ccnotification.notification.variable.templateselect'),
    trigger: 'blur'
  }],
  messageTextarea: [{maxLength: 4096, validator: validate.maxLengthValidator, trigger: ['blur', 'change']}]
})

let textTip = $t('ccnotification.notifition.template.message.text')

const tipsType = {
  money: $t('ccnotification.notifition.template.message.money'),
  dateTime: $t('ccnotification.notifition.template.message.dateTime'),
  text:  textTip.replace("000", "`~@^#%&*/=+{};''<.>?[]"),
  charDigit: $t('ccnotification.notifition.template.message.charDigit'),
  phone: $t('ccnotification.notifition.template.message.phone')
}

const regType = {
  money: /^(\.|[0-9]){1,20}$/,
  charDigit: /^([a-z]|[A-Z]|[0-9]){1,20}$/,
  phone: /^\d{1,15}$/
}

const resultCodeMsgMapping = {
  "0": $t('agentconsole.ccnotification.has.been.sent'),
  "1": $t('ccnotification.notifition.sendmessage.sendfailed'),
  "2": $t('ccnotification.notifition.sendmessage.paramvalidatefailed'),
  "4": $t('ccnotification.notifition.sendmessage.sendTimeFailed'),
  "5": $t('ccnotification.notifition.sendmessage.receiverPhoneIsNull'),
  "6": $t('message.template.message.contentError'),
  "8": $t('ccm.notifition.sendmessage.smsconfignull'),
  "9": $t('message.template.message.recipientError'),
  "10": $t('message.template.message.frequently'),
  "13": $t('message.template.message.ccOrBccRecipientError'),
  "14": $t('ccnotification.common.message.sendIntervalShort'),
  "15": $t('ccm.notifition.sendmessage.largerthanlimit'),
  "01":$t('ccm.empty.template'),
  "02":$t('ccm.empty.variables'),
  "03":$t('ccm.no.meeting.variables'),
  "04":$t('ccm.agent.meeting.failed'),
  "05":$t('ccm.agent.meeting.failed'),
  "06":$t('ccm.agent.meeting.failed'),
  "07":$t('ccm.agent.meeting.failed'),
  "08":$t('ccm.agent.link.failed'),
  "09":$t('ccm.notifition.sendmessage.largerthanlimit'),
}

/** 组织机构相关参数定义 **/

const showOrgWindow = ref(false);
const orgSearchInfo = reactive({
  orgId: "",
  orgIdList: [],
  noOrgFlag: false,
  orgFlag: false,
  inputValue: ''
})

const defaultOrgInfo = {
  orgId: "",
  orgIdList: accessOrgCache.value,
  noOrgFlag: true,
  orgFlag: true,
  inputValue: ''
}

const defaultProps = reactive({
  id: 'id',
  children: 'children',
  label: 'label'
})
const state = reactive({
  treeData: [],
  isShow: false,
  noOrg: true,
})
let filterText = ref()

/** 组织机构相关参数定义结束 **/


/** 发送号码弹窗变量定义 **/

const showSendNoSelectWindow = ref(false);
const senderNoTotal = ref(0); // 总条数
const senderNoCurrPage = ref(1); // 当前页
const senderNoOffset = computed(() => (senderNoCurrPage.value - 1) * 10); // 偏移量
const selectedSenderNoItem = ref([]); // 被选择的数据
const senderNoSearchParam = ref('');
const selectSenderNoRef = ref();
const regions = ref({});

const senderTableMetaData = reactive(
    {
      tableData: [],
      tableColumns: window.isRTL? [
        {
          label: $t('ccn.smsnoconfig.table.header.countrycode.desc'),
          prop: 'countryName',
          "show-overflow-tooltip": true
        },{
          label: $t('ccnotification.flowcontrol.shortMsgCode'),
          prop: 'shortMsgCode',
          "show-overflow-tooltip": true
        },
        {
          label: $t('ccn.smsnoconfig.table.header.countrycode'),
          prop: 'countryCode',
          'min-width': 150,
          "show-overflow-tooltip": true
        },
        {
          type: 'radio',
          radioKey: 'id', // 单选绑定的字段名
          width: '70px',
          'show-overflow-tooltip': false
        }
      ]:[
        {
          type: 'radio',
          radioKey: 'id', // 单选绑定的字段名
          width: '70px',
          'show-overflow-tooltip': false
        }, {
          label: $t('ccn.smsnoconfig.table.header.countrycode'),
          prop: 'countryCode',
          'min-width': 150,
          "show-overflow-tooltip": true
        }, {
          label: $t('ccnotification.flowcontrol.shortMsgCode'),
          prop: 'shortMsgCode',
          "show-overflow-tooltip": true
        }, {
          label: $t('ccn.smsnoconfig.table.header.countrycode.desc'),
          prop: 'countryName',
          "show-overflow-tooltip": true
        }
      ]
    }
)

/** 发送号码弹窗变量定义 结束 **/

/** 发送号码选择窗函数 **/

const search = (params = null) => {
  let tempParams;
  if (params && params.limit) {
    tempParams = params;
  } else {
    senderNoCurrPage.value = 1;
    tempParams = {
      limit: 10,
      offset: senderNoOffset.value,
    }
    if (senderNoSearchParam.value) {
      tempParams.shortMsgCode = senderNoSearchParam.value;
    }
  }
  senderTableMetaData.tableData = [];
  querySmsNumberConfigList(tempParams).then(res => {
    if (res && res.returnCode === '0') {
      senderNoTotal.value = res.data.total ? res.data.total : 0;
      let tempResultList = res.data.list;
      if (tempResultList && tempResultList.length > 0) {
        tempResultList.forEach(item => {
          item.countryName = regions.value[item.countryCode]
        })
      }
      senderTableMetaData.tableData = tempResultList;
      let tempData = senderTableMetaData.tableData.find(data => data.id === smsForm.senderNo);
      if (tempData) {
        selectSenderNoRef.value.setRadioCurrentValue(tempData.id);
      }
    }
  })
}

const handleSenderCurrentChange = (currentPage) => {
  senderNoCurrPage.value = currentPage;
  queryParams = {
    limit: senderLimit.value,
    offset: senderNoOffset.value,
  }
  search(queryParams);
};

const handleSenderSizeChange = () => {
  senderNoCurrPage.value = 1;
  queryParams = {
    limit: senderLimit.value,
    offset: senderNoOffset.value,
  }
  search(queryParams);
};

const refresh = () => {
  senderNoCurrPage.value = 1;
  queryParams = {
    limit: 10,
    offset: senderNoOffset.value,
  }
  search(queryParams);
}

const handleSenderNoSelected = (current) => {
  selectedSenderNoItem.value = [current.tableRadio];
}

/** 发送号码选择窗函数 结束**/

const smsForm = reactive({
  audioVideoCaller: callInfo.value.audioVideoCaller,
  name: '',
  params: [],
  messageTextarea: '',
  messageTextareaModel: '',
  smsGW: {
    templateSwitch: false,
    checkValue: false
  },
  templateId: '',
  senderNo: ''
});

let queryParams = {}

const tableMetaData = reactive(
    {
      tableData: [],
      tableColumns: window.isRTL ? [
        {
          label: $t('ccmessaging.chat.whatsapp.templateName'),
          prop: 'name',
          'min-width': 150,
          "show-overflow-tooltip": true
        },
        {
          type: 'radio',
          radioKey: 'id', // 单选绑定的字段名
          width: '70px',
          'show-overflow-tooltip': false
        },
      ] : [
        {
          type: 'radio',
          radioKey: 'id', // 单选绑定的字段名
          width: '70px',
          'show-overflow-tooltip': false
        }, {
          label: $t('ccmessaging.chat.whatsapp.templateName'),
          prop: 'name',
          'min-width': 150,
          "show-overflow-tooltip": true
        }
      ]
    }
)

/**
 * 选择组织
 *
 * @param orgInfo 组织信息
 */
const handleSelectedOrg = () => {
  const tempOrgs = [];
  for (const orgId of selectedOrgs.value) {
    if ("0" !== orgId && "1" !== orgId) {
      tempOrgs.push(orgId);
    }
  }
  if(tempOrgs.length > 100){
    AiccElMessage({showClose: true, message: globalProperties.$t('ccmessaging.chat.selectorgs.maxlimit.warn'), type: 'warning'});
    return;
  }
  orgSearchInfo.noOrgFlag = selectedOrgs.value.includes("0");
  orgSearchInfo.orgFlag =  selectedOrgs.value.includes("1");
  orgSearchInfo.orgIdList = tempOrgs.length === 0 ? accessOrgCache.value : tempOrgs;
  if (!orgSearchInfo.noOrgFlag && !orgSearchInfo.orgFlag && tempOrgs.length == 0) {
    orgSearchInfo.noOrgFlag = true;
    orgSearchInfo.orgFlag = true;
  }
  //只查询无归属
  if (orgSearchInfo.noOrgFlag && !orgSearchInfo.orgFlag && tempOrgs.length === 0) {
    orgSearchInfo.orgIdList = [];
  }
  handleSearch();
}

const getCurrentOrg = (id) => {
  const orgId = id || cookie.getCookie('org_id')
  if (!orgId) {
    return
  }
  queryorgsbyid({params: {orgId}}).then((resp) => {
    if (!resp || resp.returnCode !== '0') {
      return
    }
    let tempOrgName = resp.result.orgName
        ? `${$t('ccmessaging.chat.agent.organizational.structure')}(${
            resp.result.orgName
        }),${$t('ccmessaging.pop.org.window.noorg')}`
        : $t('ccmessaging.pop.org.window.noorg');

    defaultOrgInfo.orgId = resp.result.orgId
    defaultOrgInfo.noOrgFlag = true;
    defaultOrgInfo.orgFlag = !!resp.result.orgId;
    defaultOrgInfo.inputValue = tempOrgName;
  })
}

// 组织树选择变化调用事件
const onOrgsSelected = (result) => {
  selectedOrgs.value = result
  handleSelectedOrg()
}

const onOrgsClear = () => {
  selectedOrgs.value = []
  handleSelectedOrg()
}

/**
 * 基础搜索配置
 */
const templateMetaData = reactive({
  basicConfig: {
    basicSearch: [
      {
        searchType: 'primary',
        modelValue: 'templateName',
        modelDefault: '',
        placeholderValue: $t('message.template.message.messageTemplateName'),
        rules: [],
        colConfig: {
          lg: 8,
          xl: 8
        },
      }
    ]
  }
})

const queryOrgInfo = () => {
  getUserAccess().then((data) => {
    handleOrgsData(data);
    orgsData.value = data;
  })
}
const handleOrgsData = (orgs)=> {
  for (const org of orgs) {
      org.disabled = org.orgId !== '0' && !org.isAccess;
    if(org.isAccess && org.orgId !== '0' && org.orgId !== '1'){
      accessOrgCache.value.push(org.orgId)
    }
    if (org.children) {
      handleOrgsData(org.children);
    }
  }
}

const handleSelected = (current) => {
  selectedItem.value = [current.tableRadio];
}

const queryTemplateByCondition = () => {
  if (templateSearchRef.value && templateSearchRef.value.getFormatParams()) {
    let basicSearchParams = templateSearchRef.value.getFormatParams();
    queryParams.name = basicSearchParams.templateName;
  }
  queryParams.noOrgFlag = orgSearchInfo.noOrgFlag;
  queryParams.orgFlag = orgSearchInfo.orgFlag;
  queryParams.orgIdList = orgSearchInfo.orgIdList;
  queryMessageTemplate(queryParams).then((res) => {
    total.value = res.total ? res.total : 0;
    tableMetaData.tableData = res.result;
    let item = tableMetaData.tableData.find(item => item.id === smsForm.templateId);
    if (item) {
      selectTemplateRef.value.setRadioCurrentValue(item.id);
    }
  });
}
const templateSwitch = () => {
  if (!smsForm.smsGW.templateSwitch) {
    smsForm.messageTextarea = "";
    smsForm.params = [];
    smsForm.name = "";
    smsForm.smsGW.checkValue = false;
  }
}

const checkRowValueOk = (row) => {
  if (radio.value === '1') {
    if (!row.value) {
      return true;
    }
    const regEn = /^=|["\/<>\\]|exec|eval/gi;
    const regCn = /[]/gi;
    if (regEn.test(row.value) || regCn.test(row.value)) {
      row.error = $t("ccnotification.notification.special.charactor.check")
      return false;
    }
    return true;
  }
  if (!row.value) {
    row.error = $t("oifde.common.validation.required")
    return false;
  }
  row.error = $t("ccnotification.notifition.template.message.error")
  let reg = regType[row.type];
  if (reg) {
    return reg.test(row.value)
  }
  if (row.type === 'text') {
    reg = /[`~@^#%&*/=+{};'"<.>?\[\]]/;
    return !reg.test(row.value)
  }
  if (row.type === 'dateTime') {
    const regA = /^\d{4}\-\d{2}\-\d{2}$/;
    const regB = /^\d{2}\:\d{2}\:\d{2}$/;
    return regA.test(row.value) || regB.test(row.value)
  }
  return true;
}

const paramsValueChange = (row) => {
  const parentDiv = document.querySelector('#paramsIndex' + row.index).parentNode;
  if (checkRowValueOk(row)) {
    row.validateValue = true;
    parentDiv.style.removeProperty('box-shadow');
  } else {
    parentDiv.style.boxShadow = 'rgb(255,52, 55) 0px 0px 0px 1px inset';
    row.validateValue = false;
  }
  if (smsForm.smsGW.templateSwitch && radio.value === '1') {
    let tempMsg = smsForm.messageTextareaModel;
    smsForm.params.forEach(item => {
      if (item.value) {
        tempMsg = tempMsg.replaceAll('${' + item.name + '}', item.value)
      }
    })
    smsForm.messageTextarea = tempMsg;
  }
}

const handleCurrentChange = (currentPage) => {
  currPage.value = currentPage;
  queryParams = {
    limit: templateLimit.value,
    offset: offset.value,
    smsType: radio.value,
    type: "2",
    mediaType: "1"
  }
  queryTemplateByCondition();
};

const handleSizeChange = ()=>{
  currPage.value = 1;
  queryParams = {
    limit: templateLimit.value,
    offset: offset.value,
    smsType: radio.value,
    type: "2",
    mediaType: "1"
  }
  queryTemplateByCondition();
}

const handleSearch = () => {
  currPage.value = 1;
  queryParams = {
    limit: 10,
    offset: offset.value,
    smsType: radio.value,
    type: "2",
    mediaType: "1"
  }
  queryTemplateByCondition();
}

const selectCaller = () => {
  senderNoSearchParam.value = '';
  refresh();
  showSendNoSelectWindow.value = true;
  showForm.value = false;
}

const selectCloudSMSTemplate = () => {
  showForm.value = false;
  showTemplate.value = true;
  orgSearchInfo.orgIdList = defaultOrgInfo.orgIdList
  orgSearchInfo.orgFlag = defaultOrgInfo.orgFlag
  orgSearchInfo.noOrgFlag = defaultOrgInfo.noOrgFlag
  orgSearchInfo.inputValue = ''
  handleSearch();
}

const closeOtherNav = () => {
  if (showSendNoSelectWindow.value) {
    showSendNoSelectWindow.value = false;
    showForm.value = true;
    return;
  }
  if (showOrgWindow.value) {
    showOrgWindow.value = false;
    showTemplateWindow.value = true;
  } else {
    showForm.value = true;
    showTemplate.value = false;
  }
}

const handleCancel = () => {
  if (showForm.value) {
    closeHandleWindow();
    return;
  }
  closeOtherNav();
}

const closeHandleWindow = () => {
  emit('handleSMSClose');
}

const handleClose = () => {
  closeHandleWindow();
};

const changeSMSType = () => {
  smsForm.name = '';
  smsForm.params = [];
  smsForm.messageTextarea = '';
  smsForm.templateId = '';
  smsForm.senderNo = '';
  smsForm.smsGW.templateSwitch = false;
  tableMetaData.tableData = [];
  selectedItem.value = '';
  smsForm.audioVideoCaller = radio.value === '2' ? '' : callInfo.value.audioVideoCaller;
  receiverPlaceHolder.value = radio.value === '2' ? $t('agentconsole.ccnotification.inputOneRecipient') : $t('agentconsole.ccnotification.inputRecipient');
  smsForm.smsGW.checkValue = false;
}

const templateSelectConfirm = () => {
  const tempData = tableMetaData.tableData.find(data => data.id === selectedItem.value[0]);
  if (!tempData) {
    AiccElMessage.info($t('ccnotification.message.choose'));
    return;
  }
  templateSelect(tempData);
}

/**
 * 选择模板
 */
const templateSelect = (tempData) => {
  smsForm.templateId = tempData.id;
  if (radio.value === '0') {
    if (tempData) {
      smsForm.params = tempData.content && tempData.content !== 'none' ? JSON.parse(tempData.content) : [];
      smsForm.params.forEach(item => {
        item.tips = tipsType[item.type]
        item.validateValue = true;
      })
      smsForm.name = tempData.name
    }
    showTemplate.value = false;
    showForm.value = true;
    return;
  }
  smsForm.messageTextarea = tempData.content;
  smsForm.messageTextareaModel = tempData.content;
  smsForm.name = tempData.name
  smsForm.smsGW.checkValue = false;
  let tempParams = {
    templateId: tempData.id
  }
  queryVariableByTemplateId(tempParams).then((res) => {
    if (res) {
      smsForm.params = [];
      const length = res.length;
      for (let i = 0; i < length; i++) {
        smsForm.params.push({
          index: i + 1,
          des: res[i].description,
          ...res[i]
        })
      }
    }
    showTemplate.value = false;
    showForm.value = true;
  });
}

/**
 * 选择发送号码
 */
const confirmSender = () => {
  if (selectedSenderNoItem.value.length == 0) {
    AiccElMessage.info($t('ccnotification.message.choose'));
    return;
  }
  const tempData = senderTableMetaData.tableData.find(data => data.id === selectedSenderNoItem.value[0]);
  if (tempData) {
    smsForm.senderNo = tempData.shortMsgCode;
  } else {
    AiccElMessage.info($t('ccnotification.message.choose'));
    return;
  }
  showSendNoSelectWindow.value = false;
  showForm.value = true;
}

const handleResult = (res) => {
  if (res) {
    let msg = resultCodeMsgMapping[res.returnCode] ? resultCodeMsgMapping[res.returnCode] : $t('ccnotification.notifition.sendmessage.sendfailed');
    if (res.returnCode === '0') {
      AiccElMessage.success(msg);
      closeHandleWindow();
      return;
    }
    if (res.returnCode === '14') {
      msg = $t('ccnotification.common.message.sendIntervalShort', {n: res.interval});
    }
    if (res.returnCode === '15') {
      msg = $t('ccm.notifition.sendmessage.largerthanlimit', {n: res.limit});
    }
    if (res.resultCode === '07' && res.content) {
      const gatewayResp = res.content;
      if (gatewayResp.content && gatewayResp.content.cause) {
        msg = $t('ccm.agent.sms.meeting.gateway.commonerror', {n: gatewayResp.content.cause});
        if (gatewayResp.content.cause === 21234) {
          msg = $t('ccm.agent.sms.meeting.gateway.error', {n: gatewayResp.content.cause});
        }
      }
      AiccElMessage.error(msg);
      return;
    }
    if (res.resultCode) {
      msg = resultCodeMsgMapping[res.resultCode] ? resultCodeMsgMapping[res.resultCode] : $t('ccnotification.notifition.sendmessage.sendfailed');
    }
    AiccElMessage.error(msg);
  } else {
    AiccElMessage.error($t('ccnotification.notifition.sendmessage.sendfailed'));
  }
}

/**
 * 发送短信
 */
const sendSMS = () => {
  ruleFormRef.value.validate(valid => {
    if (!valid) {
      return;
    }
    if (!callInfo.value.audioVideoCallId) {
      AiccElMessage.warning($t('agentconsole.ccnotification.not.currently.on.call'));
      return;
    }
    let paramValues = [];
    let paramsLength = smsForm.params.length;
    let receivers = [smsForm.audioVideoCaller];
    if (smsForm.audioVideoCaller && smsForm.audioVideoCaller.indexOf(',') > -1) {
      receivers = smsForm.audioVideoCaller.split(",");
    }
    if (radio.value === '0') {
      for (let i = 0; i < paramsLength; i++) {
        if (!smsForm.params[i].validateValue) {
          return;
        }
        paramValues.push(smsForm.params[i].value);
      }
      let tempParam = {
        callId: callInfo.value.audioVideoCallId,
        receiverIds: receivers,
        templateId: smsForm.templateId,
        variables: paramValues,
        callSerialNo : callInfo.value.callSerialNo
      }
      audioVideoSendHuaSms(tempParam).then((res) => {
        handleResult(res)
      });
      return;
    }
    if(!smsForm.messageTextarea || smsForm.messageTextarea.length < 1 ){
      AiccElMessage.error($t('ccnotification.common.message.emptyContent'));
      return;
    }
    let tempParam;
    if (smsForm.smsGW.templateSwitch) {
      if(!smsForm.name){
        smsForm.smsGW.checkValue = true;
        const parentDiv = document.querySelector('#gwTemplate').parentNode;
        parentDiv.style.boxShadow = 'rgb(255,52, 55) 0px 0px 0px 1px inset';
        return;
      }
      for (let i = 0; i < paramsLength; i++) {
        if (smsForm.params[i].validateValue === false) {
          return;
        }
        paramValues.push({
          name: smsForm.params[i].name,
          value: smsForm.params[i].value
        });
      }
      tempParam = {
        callId: callInfo.value.audioVideoCallId,
        receiverAddress: receivers,
        templateId: smsForm.templateId,
        variables: paramValues,
        senderNo: smsForm.senderNo
      }
    } else {
      tempParam = {
        callId: callInfo.value.audioVideoCallId,
        receiverAddress: receivers,
        content: smsForm.messageTextarea,
        mediaType: "1",
        messageType: "2",
        smsType: 1,
        senderNo: smsForm.senderNo
      }
    }
    tempParam.callSerialNo = callInfo.value.callSerialNo;
    audioVideoSendMspSms(tempParam).then((res) => {
      handleResult(res)
    });
  })
}

/**
 * 发送会议链接短信
 */
const sendMeetingLink = () => {
  if (intoSendingMeeting.value) {
    return;
  }

  ruleFormRef.value.validate(valid => {
    if (!valid) {
      return;
    }
    let receivers = [smsForm.audioVideoCaller];
    if (smsForm.audioVideoCaller && smsForm.audioVideoCaller.indexOf(',') > -1) {
      receivers = smsForm.audioVideoCaller.split(",");
    }
    const tempParam = {
      callId: callInfo.value.audioVideoCallId,
      receiverAddress: receivers,
      mediaType: "1",
      messageType: "2",
      smsType: 1,
      senderNo: smsForm.senderNo,
      url: window.location.protocol + '//' + window.location.host
    }
    intoSendingMeeting.value = true;
    audioVideoSendTemplateSms(tempParam).then((res) => {
      intoSendingMeeting.value = false;
      handleResult(res)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).catch((ignore) => {
      intoSendingMeeting.value = false;
    });
  })
}

/**
 * 页面上的确定按键
 */
const advanceSubmit = () => {
  if (showOrgWindow.value) {
    showOrgWindow.value = false;
    showTemplateWindow.value = true;
    return;
  }
  if (showTemplate.value) {
    templateSelectConfirm();
    return;
  }
  if (showSendNoSelectWindow.value) {
    confirmSender();
    return;
  }
  if (radio.value === '2'){
    sendMeetingLink();
    return;
  }
  sendSMS();
}

const getChildren = (root, list) => {
  let children = list.filter(org => org.parentOrgId === root.orgId);
  for (const org of children) {
    org.id = org.orgId
    org.label = org.orgName
    org.children = getChildren(org, list)
  }
  return children
}


watch(
    filterText,
    (newValue) => {
      currentInstance.refs.treeRef.filter(newValue)
    },
)

/**
 * 初始化模板数据
 */
const initTemplate = () => {
  const templateId = callInfo.value.templateId;
  if (!templateId) {
    return;
  }
  const params = {"limit": 1, "offset": 0, "id": templateId};
  queryMessageTemplate(params).then((data) => {
    if (data?.result?.length === 1) {
      const initTemplateObj = data.result[0];
      changeSMSType();
      if (initTemplateObj.smsType == "1") {
        radio.value = "1";
        smsForm.smsGW.templateSwitch = true;
      } else {
        radio.value = "0";
      }
      templateSelect(initTemplateObj);
    }
  })
}

onMounted(() => {
  queryOrgInfo();
  getCurrentOrg(undefined);
  const params = {
    "dictCode": 'CCN.COUNTRY_CODE'
  };
  queryDataDictionary(params).then(result => {
    regions.value = result;
  })
  initTemplate();
})

</script>
<style scoped lang="less">

.return_icon {
  cursor: pointer;
  line-height: 24px;
}

.cust_header {
  --swt-color-gray10: var(--color-text--h1);
  display: flex;
  justify-content: flex-start;
}

.dialog_title {
  margin-inline-start: 10px;
  line-height: var(--swt-font-line-height-normal1);
  font-weight: var(--swt-font-weight-bold);
  font-size: var(--swt-font-size-normal1);
  color: var(--swt-color-gray10);
}

.sms-type-radio-group {
  :deep(.el-radio__label) {
    color: var(--color-text--h1);
  }
}

.inner-pattern-footer {
  display: flex;
  justify-content: space-between;
}

.inner-text {
  margin: auto 0;
}

.switch-group {
  display: flex;
  width: 400px
}

.switch-group-switch-margin {
  margin-inline-end: 12px
}

.sender-select {
  width: 400px;
}

.assign-window {
  display: flex;
  flex-direction: column;

  .assign-input {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .item-end {
      width: 29.4%;
      margin-inline-end: 12px;
    }
  }
}

.img-icon {
  padding-top: 8px;
  width: 15px;
}

.input-with-icon {
  display: flex;
  align-items: center;

  .form-item-label-icon {
    flex-shrink: 0;
    margin-inline-start: 5px;
  }
}

.error-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 2px;
  display: inline-block;
  max-width: 100%;
  color: var(--el-color-danger);
  font-size: 12px;
  line-height: 1;
  padding-top: 2px
}

.input-with-tips {
  display:flex;
  flex-direction: column
}

.dialog-footer :deep(.el-button) {
  margin-inline-start: var(--el-dialog-padding-primary);
}

.dialog-footer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

body.RTL {
  .return_icon {
    cursor: pointer;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    line-height: 24px;
  }
}
#smsTemplateWindow{
:deep(.el-form-item){
  width: 260px!important;
}
}
.search-nav {
  display: flex;
}
.show-template-search {
  display: inline-block;
  width: 240px;
  margin-inline-end: 12px;
  margin-top: 2px;
}
</style>