<template>
  <div class="audio-video-tab-list-div" v-if="showTabs" ref="audioVideoTabRef">
    <sweet-tabs id="ccma-audio-video-tab" v-model="ActiveTab">
      <div v-for="(item, index) in tabList" :key="index">
        <sweet-tab-pane :label="item.tabTitle" :name="item.tabTitle">
          <div v-if="item.mediaAbility == 1" class="div-audio">
            <aicc-audio
              v-if="ActiveTab == item.tabTitle"
              ref="aiccAudio"
              :src="item.src"
              :canDownLoad="false"
              :canClose="false"
              @downloadHandle="downloadHandle"
            />
          </div>
          <div v-else>
            <video
              :src="item.src"
              controls="controls"
              controlslist="nodownload"
              disablepictureinpicture
              class="video-width"
            ></video>
          </div>
        </sweet-tab-pane>
      </div>
    </sweet-tabs>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, onMounted, reactive, ref, toRefs, watch, onUpdated, nextTick } from "vue";
import {
  queryRecordInfo,
  recordVoiceUrl,
  associateCallRecordVoiceUrl,
  recordVideoUrl
} from '@/views/ccmanagement/ccma-api/index.js'
import { sumGetPerms } from '@/views/ccmanagement/ccma-api/otherProjectsRequest.js'
import cookieUtils from '@/utils/cookie.js'
import { useAudioVideoHeightStore } from "@/views/ccmanagement/stores/contact-detail-store";
import {computed} from "vue";

const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance()
const props = defineProps({
  resp: Object, // 列信息
  isFromWorkbench: Boolean,
  callSerialNo: String
})
const { resp, isFromWorkbench, callSerialNo } = toRefs(props)
let ActiveTab = ref('')
let audioIndex = ref(0)
let videoIndex = ref(0)
let showTabs = ref(false)
const playAllAuth = ref(false)
const isSelfFlag = ref(false)
const audioVideoHeightStore = useAudioVideoHeightStore()
onMounted(() => {
  initData()
})

watch([resp], () => initData())

let tabList = reactive([])

const setTabList = (index) => {
  showTabs.value = true
  audioVideoHeightStore.setValue(true)
  if (index.mediaAbility == '1') {
    audioIndex.value++
    tabList.push({
      mediaAbility: index.mediaAbility,
      tabTitle: globalProperties.$t('ccm.contact.details.tab.title.audio') + audioIndex.value,
      src: recordVoiceUrl + index.recordId
    })
  } else if (index.mediaAbility == 'VRC') {
    videoIndex.value++
    tabList.push({
      mediaAbility: '2',
      tabTitle: index.tabTitle,
      src: index.src
    })
  } else {
    videoIndex.value++
    tabList.push({
      mediaAbility: index.mediaAbility,
      tabTitle: globalProperties.$t('ccm.contact.details.tab.title.video') + videoIndex.value,
      src: recordVideoUrl + index.recordId
    })
  }
}
const downloadHandle = () => {}

const getRecordInfo = (recordId, vrcRecordId) => {
  let recordIdArr = recordId.split(',')
  let params = { recordIds: recordIdArr }
  queryRecordInfo(params).then((result) => {
    if (!result) {
      return
    }
    for (let index of result) {
      if (
        index['mediaAbility'] == 2 &&
        index['recordfileaddr'] &&
        index['recordfileaddr'].indexOf(':/') != -1
      ) {
        // 视频播放,并且是宇高的展示播放按钮
        setTabList(index)
        continue
      }

      if (index.mediaAbility != 2) {
        setTabList(index)
      }
    }
    if (vrcRecordId) {
      // VRC播放
      const videoSrc =`${location.protocol}//${location.host}/service-cloud/u-route/ccmanagement/recordplay/video?vrcRecordId=${vrcRecordId}`;
      console
      let params = { src: videoSrc, mediaAbility: 'VRC', tabTitle: 'VRC'}
      setTabList(params)
    }
    if (tabList.length > 0) {
      ActiveTab.value = tabList[0].tabTitle
    }
  })
}
const initData = async () => {
  tabList = []
  ActiveTab.value = ''
  audioIndex.value = 0
  videoIndex.value = 0
  showTabs.value = false
  audioVideoHeightStore.setValue(false)
  isSelfFlag.value = false
  playAllAuth.value = false
  // 工作台跳转，需权限校验
  if (isFromWorkbench.value) {
    if (resp?.value.workNo == window.ccAction.ccAgentId) {
      isSelfFlag.value = true
    }

    const tenantSpacesId = cookieUtils.getCookie('tenant_space_id')
    const userId = cookieUtils.getCookie('user_id')
    const sumGetPermsResult = await sumGetPerms(tenantSpacesId, userId)
    if (sumGetPermsResult['permCodes'].indexOf('80031001002001') >= 0) {
      playAllAuth.value = true
    }

    if (!playAllAuth.value && !isSelfFlag.value) {
      return
    }
  }

  const associateCall = resp?.value.associateCall
  const isExist = resp?.value.associateCall
  const recordId = resp?.value.recordId
  const vrcRecordId = resp?.value.vrcRecordId
  //  双呼类型没有recordId，需要根据isExist字段判断是否有录音
  if (associateCall && isExist) {
    showTabs.value = true
    audioVideoHeightStore.setValue(true)
    audioIndex.value++
    tabList.push({
      mediaAbility: 1,
      tabTitle: globalProperties.$t('ccm.contact.details.tab.title.audio') + audioIndex.value,
      src: associateCallRecordVoiceUrl + associateCall
    })
    ActiveTab.value = globalProperties.$t('ccm.contact.details.tab.title.audio') + audioIndex.value
  }
  if (recordId) {
    getRecordInfo(recordId, vrcRecordId)
  }
}

const emits = defineEmits(['clientHeight'])
const audioVideoTabRef = ref();
onUpdated(() => {
  nextTick(() => {
    const clientHeight = audioVideoTabRef.value?.clientHeight || 0;
    emits("clientHeight", clientHeight);
  })
})
</script>

<style lang="less" scoped>
.audio-video-tab-list-div :deep(.el-tabs__content) {
    overflow: visible;
}
.audio-video-tab-list-div {
  padding: 20px;
  .div-audio {
    margin: 24px 6px 2px 6px;
  }
  .aicc-audio {
    height: 36px;
  }
  .video-width {
    padding-top: 20px;
    width: 100%;
    height: 100%;
  }
}
</style>