<template>
  <sweet-dialog :modelValue="isShow" :title="title" width="616px" :close-on-click-modal="false">
    <sweet-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="formRules"
      label-width="auto"
      :label-position="labelPosition"
      @submit.prevent
    >
      <!-- 数据范围 -->
      <sweet-form-item :label="$t('ccm.label.contactexport.selectdatarange')" prop="typeId">
        <sweet-radio-group v-model="radio1" @change="radio1Change">
          <sweet-radio label="1">
            <span>{{ i18n('ccm.custdata.option.all') }}</span>
          </sweet-radio>
          <sweet-radio label="2">
            <span>{{ i18n('ccm.label.contactexport.curpage') }}</span>
          </sweet-radio>
        </sweet-radio-group>
      </sweet-form-item>
      <!-- 导出字段 -->
      <sweet-form-item :label="i18n('ccm.label.contactexport.selectdatafield')" prop="userNo">
        <sweet-radio-group v-model="radio2" @change="radio2Change">
          <sweet-radio label="1">
            <span>{{ i18n('ccm.label.contactexport.currentfield') }}</span>
          </sweet-radio>
          <sweet-radio label="2">
            <span>{{ i18n('ccm.label.contactexport.customfield') }}</span>
          </sweet-radio>
        </sweet-radio-group>
      </sweet-form-item>
      <sweet-form-item>
        <!-- 选择数据 -->
        <sweet-row v-if="selectedMode == 1">
          <sweet-checkbox-group v-model="visibleItemsLis">
            <sweet-row>
              <!-- 呼叫流水号 -->
              <sweet-col :span="5" :offset="1">
                <sweet-checkbox :label="'callSerialno'" disabled :checked="true">
                  <div class="tooLong100px" :title="i18n('ccm.agent.contact.callserno')">{{ i18n('ccm.agent.contact.callserno') }}</div>
                </sweet-checkbox>
              </sweet-col>
              <!-- 循环展示接触记录字段 -->
              <sweet-col v-for="(item, index) in itemResult" :key="index" :span="5" :offset="1">
                <sweet-checkbox :label="item.dataItemCode" disabled>
                  <div class="tooLong100px" :title="item.dataItemName">{{ item.dataItemName }}</div>
                </sweet-checkbox>
              </sweet-col>
            </sweet-row>
          </sweet-checkbox-group>
        </sweet-row>
        <sweet-row v-if="selectedMode == 2">
        <sweet-col size="12" :span="23" :offset="1">
          <sweet-checkbox v-model="allSelected" @change="selectAll">
            {{ i18n('ccm.label.contactexport.selectall') }}
          </sweet-checkbox>
        </sweet-col>
        <sweet-checkbox-group v-model="selectedItemsList">
          <sweet-row>
            <sweet-col :span="5" :offset="1">
              <sweet-checkbox :label="'callSerialno'" :checked="true" :key="'callSerialno'">
                <div class="tooLong100px" :title="i18n('ccm.agent.contact.callserno')">{{ i18n('ccm.agent.contact.callserno') }}</div>
              </sweet-checkbox>
            </sweet-col>
            <sweet-col v-for="(item, index) in itemResult" :key="index" :span="5" :offset="1">
              <sweet-checkbox :label="item.dataItemCode" :key="item.dataItemCode" :checked="true">
                <div class="tooLong100px" :title="item.dataItemName">{{ item.dataItemName }}</div>
              </sweet-checkbox>
            </sweet-col>
          </sweet-row>
        </sweet-checkbox-group>
      </sweet-row>
      </sweet-form-item>
      <!-- 压缩密码 -->
      <sweet-form-item
        :label="$t('ccm.custdata.operlog.zippwd')"
        prop="zipPwd"
        v-if="$props.showZipPwd"
      >
        <template #label>
          <span class="input-required">
            {{ $t("ccm.custdata.operlog.zippwd") }}
          </span>
        </template>
        <sweet-input
          autocomplete="off"
          id="confirmAccountPassword"
          type="password"
          minlength="8"
          v-model="ruleForm.zipPwd"
          :placeholder="i18n('cms.report.message.inputZipPassword')"
          class="aicc-form-input-width"
        >
        </sweet-input>
      </sweet-form-item>
      <sweet-form-item prop="tips" :label="' '" class="footer--padding" v-if="$props.showZipPwd">
        <span class="footer--tips">{{ i18n('ccm.export.password.error.tips') }}</span>
      </sweet-form-item>
    </sweet-form>
    <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="change">{{
          i18n('ccm.satisfactionconfig.message.cancel')
        }}</sweet-button>
        <sweet-button type="primary" @click="confirmSubmit">
          {{ i18n('aicc.ok') }}
        </sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script setup lang="ts">
import { defineProps, toRefs, ref, reactive, onMounted, defineEmits, getCurrentInstance, computed } from "vue";
import { ElMessageBox, FormRules } from 'element-plus'
import { generateExportTask, queryContactItemConfig } from '@/views/ccmanagement/ccma-api/index.js'
import cookieUtils from '@/utils/cookie.js'
import type {
  InternalRuleItem,
  SyncValidateResult,
  Value
} from 'async-validator/dist-types/interface'
import {validZipPwdExtendedRule} from "@/views/ccmanagement/common/validRules";
import AiccElMessage from "@/utils/el-message";

const ruleFormRef = ref()
const props = defineProps({
  title: String,
  isShow: Boolean,
  searchObject: Object,
  /** 是否需要设置压缩密码 */
  showZipPwd: {
    type: Boolean,
    default: () => true
  }
})
// 国际化封装
const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance() as any
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args)
}

const ruleForm = reactive({ zipPwd: '' })
const labelPosition = computed<'left'|'right'|'top'>(() => window.isRTL ? 'right' : 'left')
onMounted(() => {
  let param: any = {}
  param['locale'] = cookieUtils.getCookie('u-locale')
  queryContactItemConfig(param).then((resp: any) => {
    if (resp) {
      let totalItemNum = resp.length
      for (let i = 0; i < totalItemNum; i++) {
        // 不需要导出录音文件操作字段
        if (resp[i].dataItemCode == 'recordingfiles') {
          continue
        }
        itemResult.value.push(resp[i])
        selectedItems[resp[i].dataItemCode] = true
        if (resp[i].visibleInList == 1) {
          visibleItemsLis.value?.push(resp[i].dataItemCode)
          visibleItemResult.value?.push(resp[i])
          selectedVisibleItems[resp[i].dataItemCode] = true
        }
      }
      selectAll()
    }
  })
})

const { title, isShow, searchObject } = toRefs(props)
const radio1 = ref('1')
const radio2 = ref('1')
const zipPwd = ref('')

const selectedRange = ref<String>('1') // 选择范围，1为全部；2为当前页
const selectedMode = ref<String>('1') // 选择字段，1为接触记录查询展示的列表字段；2为接触记录所有字段可选
const allSelected = ref<Boolean>(true) // 是否全部选中
const itemResult = ref<Array<any>>([]) // 查询的接触记录数据项结果
const selectedItems = reactive<any>({ callSerialno: true }) // 可选的导出项
const selectedItemsList = ref<Array<String>>([]) // 选择导出项的字段列表
const visibleItemResult = ref<Array<String>>([]) // 查询的接触记录数据项(可见)结果
const selectedVisibleItems = ref<any>({ callSerialno: true }) // 选中的导出项(可见)
const visibleItemsLis = ref<Array<String>>([]) //可见字段
const createTaskResult = ref<boolean>(false) // 导出任务创建结果

const emit = defineEmits(['change'])

const change = () => {
  emit('change', createTaskResult.value)
}

const radio1Change = () => {
  selectedRange.value = radio1.value
}

const radio2Change = (data: any) => {
  selectedMode.value = radio2.value
  if (selectedMode.value == '2') {
    allSelected.value = true
  }
}

const confirmSubmit = () => {
  if (!ruleFormRef.value) return false
  ruleFormRef.value.validate((valid: any) => {
    if (valid) {
      return confirmExport()
    } else {
      return false
    }
  })
}

const selectAll = () => {
  if (allSelected.value) {
    // 全部选中
    for (let key in selectedItems) {
      if (selectedItems.hasOwnProperty(key)) {
        selectedItems[key] = true
      }
    }
    selectedItemsList.value = Object.keys(selectedItems)
  } else {
    // 取消全选
    for (let key in selectedItems) {
      if (selectedItems.hasOwnProperty(key)) {
        selectedItems[key] = false
      }
    }
    selectedItemsList.value = []
  }
}

/**
 * 密码有效性校验
 * @param rule 校验规则
 * @param value 要校验的值
 * @param callback 回调函数
 */
const passwordValidate = (
  rule: InternalRuleItem,
  value: Value,
  callback: (error?: string | Error) => void
): SyncValidateResult | void => {
  return validZipPwdExtendedRule(rule, value, callback, 8, 12);
}

const confirmExport = () => {
  if (selectedMode.value == '2' && selectedItemsList.value.length == 0) {
    AiccElMessage.info(i18n('ccm.label.contactexport.parainnull'))
    return
  }

  let param: any = {}
  param['zipPwd'] = ruleForm.zipPwd
  if (selectedMode.value == '1') {
    param['selectedItemsList'] = visibleItemsLis.value
  } else {
    param['selectedItemsList'] = selectedItemsList.value
  }
  param['selectedRange'] = selectedRange.value
  param['searchObject'] = searchObject?.value
  param['locale'] = cookieUtils.getCookie('u-locale')

  generateExportTask(param).then((resp: any) => {
    if (resp['returnCode'] == '0') {
      createTaskResult.value = true
      change()
    } else if (resp['returnCode'] == '1') {
      // 遇到异常
      AiccElMessage.error(i18n('ccm.label.contactexport.exception'))
    } else if (resp['returnCode'] == '2') {
      // 入参不过
      AiccElMessage.error(i18n('ccm.label.contactexport.parainvalid'))
    } else if (resp['returnCode'] == '3') {
      // 生成任务失败
      AiccElMessage.error(i18n('ccm.custdata.operlog.addExportTaskError'))
    } else if (resp['returnCode'] == '4') {
      // 数量超出上限
      AiccElMessage.error(
        i18n('ccm.label.contactexport.exceedlimit1', resp['maxCount']) +
          i18n('ccm.label.contactexport.exceedlimit2')
      )
    } else if (resp['returnCode'] == '5') {
      // 数量为0
      AiccElMessage.error(i18n('ccm.label.contactexport.nocount'))
    } else {
      AiccElMessage.error(i18n('ccm.custdata.operlog.addExportTaskError'))
    }
  })
}
const formRules = reactive<FormRules>({
  zipPwd: [
    { validator: passwordValidate, trigger: ['blur','change'] }
  ]
})
</script>

<style lang="less" scoped>
.footer--tips {
  color: #707070;
  line-height: 16px;
  font-weight: 400;
}
.footer--padding {
  padding-top: 0;
}
.dialog-footer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.tooLong100px {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  min-height: 24px;
  overflow: hidden;
  display: inline-block;
  margin-bottom: -10px;
}
.input-required {
  padding-block-start: 0;
  &::before {
    content: "*";
    color: var(--el-color-danger);
    margin-inline-end: 4px;
  }
}

</style>