export default {
  'oifde.index.title': 'ODFS – Serviço de fluxo de diálogo on-line',
  'oifde.common.help': 'Exibir informações da ajuda.',
  'oifde.common.noData': 'Sem dados',
  'oifde.common.select': '-Selecione-',
  'oifde.common.lang': 'Idioma',
  'oifde.common.language.chinese': 'Chinês',
  'oifde.common.language.english': 'Inglês',
  'oifde.common.time.startDate': 'Data de início',
  'oifde.common.time.endDate': 'Data Final',
  'oifde.common.selectPlaceHolder': 'Inserir uma palavra-chave.',
  'oifde.common.browserSupporteMsg': 'O navegador não suporta esta função.',
  'oifde.common.validation.required': 'Campo obrigatório',
  'oifde.common.validation.extendFaqRange': 'É permitido usar somente caracteres chineses, letras, dígitos, espaços e os seguintes caracteres especiais: ( ) [ ] {\'@\'} \u007b \u007d ， , 。 . | \' _ -',
  'oifde.common.validation.nameRange': 'Não suportado: < > ! {\'@\'} # $ % ^ & * ? ; " ~ / \\',
  'oifde.common.validation.cellNameRange': 'Não suportado: < > {\'@\'} # $ % ^ * ; " ~ / \\',
  'oifde.shortMessage.validation.text': 'Não suportado: `~{\'@\'}^#%&*/=+{\'{\'}{\'}\'};"<.>?[]',
  'oifde.common.validation.nameNoSpace': 'O valor não pode começar ou terminar com um espaço.',
  'oifde.common.validation.nodeNameCharReg': 'O valor só pode conter caracteres chineses, letras, dígitos, sublinhados (_) e hifens (-).',
  'oifde.common.validation.nameCharRegNotChinese': 'O valor pode conter somente letras, dígitos, sublinhados (_) e hifens (-).',
  'oifde.common.validation.nameCharReg': 'O valor só pode conter caracteres chineses, letras, dígitos, sublinhados (_) e hifens (-).',
  'oifde.common.validation.schemaNameCharReg': 'São permitidos somente caracteres chineses, letras, dígitos, espaços, sublinhados (_) e aspas simples (\').',
  'oifde.common.validation.schemaRelationNameCharReg': 'São permitidos somente caracteres chineses, letras, dígitos, espaços e aspas simples (\').',
  'oifde.common.validation.openNameCharReg': 'São permitidos somente caracteres chineses, letras, dígitos, espaços, sublinhados (_), hifens (-) e aspas simples (\').',
  'oifde.common.validation.kgNameCharReg': 'É permitido usar somente caracteres chineses, letras, espaços e os seguintes caracteres especiais: _ - , \' ; . {\'@\'}',
  'oifde.common.validation.charRegWithDot': 'O valor só pode conter caracteres chineses, letras, dígitos, pontos (.) sublinhados (_) e hifens (-).',
  'oifde.common.validation.int': 'O valor deve ser um número inteiro de 1 a 2147483647.',
  'oifde.common.validation.min': 'O valor não pode ser inferior a {0}.',
  'oifde.common.validation.max': 'O valor não pode superior a {0}.',
  'oifde.common.validation.numberRange': 'Os valores variam de {0} a {1}.',
  'oifde.common.validation.sessionDTRange': 'O intervalo de tempo limite do fluxo de diálogo varia de {0} a {1} segundos.',
  'oifde.common.validation.slientHelpRange': 'O tempo de espera para uma resposta depois que um agente está em silêncio varia de {0} a {1} segundos.',
  'oifde.common.validation.confidenceRange': 'O limite de confiança varia de {0} a {1}.',
  'oifde.common.validation.urlValidate': 'O formato de URL está incorreto.',
  'oifde.common.validation.positiveInt': 'O valor deve ser um número inteiro positivo.',
  'oifde.common.validation.digits': 'O valor deve ser 0 ou um número inteiro positivo',
  'oifde.common.validation.numbers': 'Insira apenas dígitos.',
  'oifde.common.validation.validateFalse': 'O valor não pode conter o seguinte characters: ~ ` ! # {\'$\'} % ^ & * ( ) + = [ ] {\'{\'}{\'}\'} \\ {\'|\'} \' " : < > / ?',
  'oifde.common.validation.mrcpIdValueFalse': 'Insira um número inteiro positivo entre 0 e 255.',
  'oifde.common.dialog.confirm.title': 'Confirmar',
  'oifde.common.dialog.confirm.content': 'Esta operação excluirá os dados permanentemente. Tem certeza de que deseja continuar?',
  'oifde.common.dialog.confirm.buttonSure': 'Sim',
  'oifde.common.dialog.confirm.buttonCancel': 'Não',
  'oifde.common.dialog.confirm.successInfo': 'Os dados foram excluídos com sucesso.',
  'oifde.common.dialog.confirm.cancelInfo': 'A exclusão foi cancelada.',
  'oifde.common.dialog.confirm.operationSucc': 'A operação foi realizada com sucesso.',
  'oifde.common.dialog.confirm.operation_failed': 'Falha ao executar a operação.',
  'oifde.common.actions.add': 'Adic.',
  'oifde.common.actions.edit': 'Editar',
  'oifde.common.actions.delete': 'Excluir',
  'oifde.common.actions.download': 'Downld',
  'oifde.common.detail': 'Detalhes',
  'oifde.serviceInterface.selfDefinedInterface': 'Interface Auto-Definida',
  'oifde.serviceInterface.innerInterface': 'Interface interna',
  'oifde.serviceInterface.innerInterfaceDetails': 'Detalhes da interface interna',
  'oifde.serviceInterface.oifdeInterface': 'Interface Inteligente do Robô',
  'oifde.serviceInterface.ccivrInterface': 'Interface IVR comum',
  'oifde.serviceInterface.jsonValidateSuccess': 'O formato está correto.',
  'oifde.serviceInterface.jsonChek': 'Verificar JSON',
  'oifde.serviceInterface.interfaceCert': 'Certificado de interface',
  'oifde.serviceInterface.certCode': 'Código',
  'oifde.serviceInterface.certScene': 'Cenário',
  'oifde.serviceInterface.expireTime': 'Expirado',
  'oifde.serviceInterface.certUpdateTime': 'Atual.',
  'oifde.serviceInterface.jsonValidate': 'O corpo da mensagem não está de acordo com o formato JSON padrão.',
  'oifde.serviceInterface.safeWarning': 'Um protocolo de rede inseguro é especificado para a interface.',
  'oifde.serviceInterface.whiteListCheckWarning': 'O URL (endereço IP e número de porta) não está na lista de confiança e pode ser usado somente depois que você entrar em contato com o administrador do sistema para adicioná-lo à lista de confiança.',
  'oifde.serviceInterface.interfaceName': 'Nome de interface',
  'oifde.serviceInterface.bizCode': 'Código da empresa',
  'oifde.serviceInterface.description': 'Descrição',
  'oifde.serviceInterface.updatetime': 'Atual.',
  'oifde.serviceInterface.operation': 'Operação',
  'oifde.serviceInterface.addServiceInterface': 'Adicionar configuração de interface comercial',
  'oifde.serviceInterface.modifyServiceInterface': 'Editar configuração da interface comercial',
  'oifde.serviceInterface.baseInfo': 'Información básicas',
  'oifde.serviceInterface.reqURL': 'Solicitar URL',
  'oifde.serviceInterface.signBody': 'Assinar',
  'oifde.serviceInterface.signBodyDetail.no': 'Não',
  'oifde.serviceInterface.signBodyDetail.yes': 'Sim',
  'oifde.serviceInterface.signDescription': 'Se deve verificar a assinatura de um terceiro quando a assinatura for transferida para o terceiro como um parâmetro de interface.',
  'oifde.serviceInterface.appSecret': 'Chave compartilhada',
  'oifde.serviceInterface.inputAppSecret': 'Insira a chave compartilhada.',
  'oifde.serviceInterface.busiCode': 'Código de interface',
  'oifde.serviceInterface.interfaceType': 'Método de solicitação',
  'oifde.serviceInterface.required': 'Campo obrigatório.',
  'oifde.serviceInterface.query_param': 'Parâmetro de consulta',
  'oifde.serviceInterface.pathParamValue': 'Valor',
  'oifde.serviceInterface.outParamPath': 'Cam.',
  'oifde.serviceInterface.in_param': 'Parâmetro de entrada',
  'oifde.serviceInterface.out_param': 'Parâmetro de saída',
  'oifde.serviceInterface.name': 'Nome',
  'oifde.serviceInterface.dataType': 'Tipo de dado',
  'oifde.serviceInterface.encryptFlag': 'Criptografado',
  'oifde.serviceInterface.dataTypes.INT': 'Inteiro',
  'oifde.serviceInterface.dataTypes.STRING': 'Caractere',
  'oifde.serviceInterface.dataTypes.FLOAT': 'Número de ponto flutuante',
  'oifde.serviceInterface.dataTypes.LONG': 'Inteiro longo',
  'oifde.serviceInterface.dataTypes.LIST': 'Lista',
  'oifde.serviceInterface.dataTypes.OBJECT': 'Objeto',
  'oifde.serviceInterface.msgHeaders': 'Cabeçalho da mensagem',
  'oifde.serviceInterface.msgBody': 'Corpo da mensagem',
  'oifde.serviceInterface.headerKey': 'Chave',
  'oifde.serviceInterface.headerValue': 'Valor',
  'oifde.serviceInterface.deleteConfirm': 'Esta operação excluirá permanentemente a interface comercial. Tem certeza de que deseja continuar?',
  'oifde.serviceInterface.miss_ifs_name': 'O seguinte campo obrigatório está vazio: Informações básicas > Nome da interface.',
  'oifde.serviceInterface.prompt': 'Confirmar',
  'oifde.serviceInterface.ok': 'OK',
  'oifde.serviceInterface.miss_ifs_request_url': 'O seguinte campo obrigatório está vazio: Informações básicas > Solicitar URL.',
  'oifde.serviceInterface.miss_ifs_in_parameter_name': 'O seguinte campo obrigatório está vazio: Parâmetro de entrada > Nome.',
  'oifde.serviceInterface.miss_ifs_out_parameter_name': 'O seguinte campo obrigatório está vazio: Parâmetro de saída > Nome.',
  'oifde.serviceInterface.miss_ifs_out_parameter_type': 'O seguinte campo obrigatório está vazio: Parâmetro de saída > Tipo de dados.',
  'oifde.serviceInterface.miss_ifs_header_key': 'O seguinte campo obrigatório está vazio: Cabeçalho da mensagem > Chave.',
  'oifde.serviceInterface.miss_ifs_msgBody': 'O seguinte campo obrigatório está vazio: Corpo da mensagem.',
  'oifde.serviceInterface.auto_msgBody': 'Corpo da mensagem automática',
  'oifde.serviceInterface.manual_msgBody': 'Corpo da mensagem manual',
  'oifde.serviceInterface.promptMessage': 'Insira um corpo de mensagem.',
  'oifde.serviceInterface.actions.cancelSuccess': 'Salvamento cancelado!',
  'oifde.serviceInterface.actions.auto_savePrompt': 'Esta operação salvará o 「corpo automático da mensagem」 como a configuração da interface do serviço. Tem certeza de que deseja continuar?',
  'oifde.serviceInterface.actions.manual_savePrompt': 'Esta operação salvará o 「corpo da mensagem manual」 como a configuração da interface de serviço. Tem certeza de que deseja continuar?',
  'oifde.serviceInterface.actions.urlNotInWhitelist': 'O URL (endereço IP e número da porta) não está na lista de confiança. Só pode ser usado depois de contactar o administrador do sistema para adicionar o URL à lista de confiança. Continuar?',
  'oifde.serviceInterface.actions.prompt': 'Confirmar',
  'oifde.serviceInterface.actions.confirm': 'Sim',
  'oifde.serviceInterface.actions.cancel': 'Não',
  'oifde.sysMgr.sysCfg': 'Configurações do sistema',
  'oifde.sysMgr.apiKeyCfg': 'Configurações de senha de API',
  'oifde.sysMgr.configuration': 'Defi',
  'oifde.sysMgr.apiKey': 'Senha API',
  'oifde.sysMgr.apiKeyComfirm': 'Confirmar senha de API',
  'oifde.sysMgr.apiKeyNotSame': 'As senhas de API não coincidem.',
  'oifde.sysMgr.inputKey': 'Insira uma senha de API.',
  'oifde.sysMgr.cancel': 'Cancelar',
  'oifde.sysMgr.save': 'Salvar',
  'oifde.sysMgr.apiRuleRange': 'Uma senha deve conter pelo menos dois dos seguintes itens: letras minúsculas, letras maiúsculas, dígitos e caracteres especiais ~ ` ! {\'@\'} {\'$\'} {\'|\'}{ \'{\'} {\'}\'} #  % ^ * ( ) - + _ =  , . / < > ? ; \' : " [ ] &.',
  'oifde.sysMgr.apiNoSame': 'A senha não pode ser igual ao ID do espaço do locatário.',
  'oifde.sysMgr.success_set_key': 'A senha de API foi definida com êxito!',
  'oifde.sysMgr.requiredAPIKey': 'Insira uma senha de API.',
  'oifde.sysMgr.APIKeyLenCheck': 'A senha de API deve conter entre 16 e 64 caracteres.',
  'oifde.sysMgr.success_set_rpa': 'O RPA ACCESS INFO foi definido com sucesso!',
  'oifde.sysMgr.requiredRpaUserName': 'A conta de usuário RPA é obrigatória.',
  'oifde.sysMgr.requiredRpaSecretKey': 'A chave secreta RPA é necessária.',
  'oifde.sysMgr.rpaUserNameLenCheck': 'A conta de usuário RPA não pode conter mais de 64 caracteres.',
  'oifde.sysMgr.rpaSecretKeyLenCheck': 'A chave secreta RPA não pode conter mais de 128 caracteres.',
  'oifde.sysMgr.inputRpaUserName': 'Digite o nome de usuário do RPA.',
  'oifde.sysMgr.inputRpaSecretKey': 'Insira a chave secreta RPA.',
  'oifde.sysMgr.dataClean.setTime': 'Definição de tempo de limpeza de dados ',
  'oifde.sysMgr.dataClean.hour': 'hora',
  'oifde.sysMgr.dataClean.minute': 'minutos',
  'oifde.sysMgr.dataClean.operation': 'operação ',
  'oifde.sysMgr.dataClean.recordThirdApi': 'Se deve habilitar a chamada de log de interface de terceiros',
  'oifde.sysMgr.dataClean.editInterfaceFlag': 'Editar ID de log de chamada de interface de terceiros ',
  'oifde.sysMgr.dataClean.ccvirrecordFlag': 'Sinalizador para ativar ou não a gravação de chamadas IVR',
  'oifde.sysMgr.dataClean.editCcvirrecordFlag': 'Editar ID de Gravação do Processo de Chamada do IVR ',
  'oifde.sysMgr.dataClean.traceLogAutoCloseDesc': 'Definir o tempo de desativação automática do rastreamento ',
  'oifde.sysMgr.dataClean.traceLogAutoClose': 'Editar a hora de fechamento automático do rastreamento',
  'oifde.sysMgr.dataClean.flowIvrSaveFlagDesc': 'Sinalizador para ativar a gravação periódica na orquestração do processo IVR',
  'oifde.sysMgr.dataClean.editFlowSaveFlag': 'Editar ID para salvar o temporizador da orquestração do processo IVR',
  'oifde.sysMgr.dataClean.flowIvrSaveIntervalDesc': 'Intervalo de armazenamento do temporizador de orquestração do processo IVR',
  'oifde.sysMgr.dataClean.editFlowSaveInterval': 'Editar Intervalo de Armazenamento do Tempo de Ordenação do Processo IVR',
  'oifde.sysMgr.dataClean.flowMaxElementsDesc': 'Número máximo de elementos suportados pelo processo IVR',
  'oifde.sysMgr.dataClean.editFlowMaxElements': 'Editar o número máximo de elementos suportados por um processo de IVR ',
  'oifde.sysMgr.dataClean.serviceName': 'Nome do parâmetro',
  'oifde.sysMgr.dataClean.paramValue': 'Valor',
  'oifde.sysMgr.dataClean.description': 'Descrição',
  'oifde.sysMgr.dataClean.descriptionContent': 'Intervalo no qual a limpeza de dados é executada.',
  'oifde.sysMgr.dataClean.editTime': 'Modificar intervalo de Limpeza de dados',
  'oifde.sysMgr.dataClean.day': 'dias',
  'oifde.sysMgr.dataClean.flagDescription': 'Se deseja ativar a gravação em um fluxo de chamada.',
  'oifde.sysMgr.dataClean.editFlag': 'Modificar gravação no fluxo de chamadas',
  'oifde.sysMgr.dataClean.open': 'Ativar',
  'oifde.sysMgr.dataClean.close': 'Desativar',
  'oifde.sysMgr.dataClean.record': 'Registrar',
  'oifde.sysMgr.dataClean.noRecord': 'Não registrar',
  'oifde.sysMgr.dataClean.second': 'seg',
  'oifde.sysMgr.dataClean.reportFlagDesc': 'Se os casos de teste devem ser registrados em um relatório.',
  'oifde.sysMgr.dataClean.reportFlagTitle': 'Modificar registro de caso de teste',
  'oifde.sysMgr.dataClean.sessionDTDesc': 'Intervalo de tempo limite para fluxos de diálogo.',
  'oifde.sysMgr.dataClean.editSessionDT': 'Modificar intervalo de tempo limite do fluxo de diálogo',
  'oifde.sysMgr.dataClean.slientHelpDesc': 'Tempo para aguardar uma resposta após um agente ficar silencioso.',
  'oifde.sysMgr.dataClean.editSlientHelp': 'Modificar tempo para aguardar resposta após Silenciamento de agente',
  'oifde.sysMgr.dataClean.confidenceDesc': 'Limite de confiança para que um agente silencioso reconheça a busca de ajuda única de um chatbot.',
  'oifde.sysMgr.dataClean.confidence': 'Modificar a confiança',
  'oifde.sysMgr.dataClean.tcaContent': 'Lista de realms vinculados à análise de texto',
  'oifde.sysMgr.dataClean.editTcaFlag': 'Editar a lista de realms vinculados à análise de texto',
  'oifde.sysMgr.dataClean.paramValueTca': 'Valor do parâmetro',
  'oifde.sysMgr.encryptSecretCfg': 'Configurações chave',
  'oifde.sysMgr.encryptSecret': 'Chave',
  'oifde.sysMgr.encryptSecretComfirm': 'Confirmar chave',
  'oifde.sysMgr.encryptSecretNotSame': 'As chaves não coincidem.',
  'oifde.sysMgr.inputEncryptSecret': 'Insira uma chave.',
  'oifde.sysMgr.success_set_encryptSecret': 'A chave foi definida com êxito.',
  'oifde.sysMgr.encryptSecretRuleRange': 'A Chave de criptografia não atende os requisitos. A chave deve conter pelo menos dois dos seguintes tipos de caracteres: letras maiúsculas, letras minúsculas, dígitos ou caracteres especiais ~ ` ! {\'@\'} # $ % ^ * ( ) - + _ = | , . / < > ? ; \' : " [ ] \u007b \u007d &.',
  'oifde.sysMgr.encryptSecretNoSame': 'A chave não pode ser igual ao ID do espaço do locatário.',
  'oifde.sysMgr.encryptSecretLenCheck': 'A chave deve conter 16 caracteres.',
  'oifde.sysMgr.parameterSetting': 'Configurações inteligentes de parâmetros do motor',
  'oifde.sysMgr.variableSecretNotExists': 'A chave é obrigatória quando você usa uma variável criptografada. Escolha Gestão do Sistema > Configuração do Sistema e clique em Configurações chave para definir a chave.',
  'oifde.sysMgr.rpaCfg': 'Configurações de RPA',
  'oifde.sysMgr.rpaUserNameCfg': 'Conta de Usuário RPA',
  'oifde.sysMgr.rpaSecretKeyCfg': 'Chave Secreta RPA',
  'oifde.route.flow': 'Orquestração',
  'oifde.route.rule': 'Regra cinza de fluxo',
  'oifde.route.trace': 'Rastream fluxo',
  'oifde.route.flowAnalysis': 'Análise de fluxo',
  'oifde.route.ivrOverview': 'Visão geral do IVR',
  'oifde.route.indicatorAnalysis': 'Análise de Indicadores',
  'oifde.route.versionComparisonAndAnalysis': 'Comparação e análise de versões',
  'oifde.route.optimizationSuggestions': 'Sugestões de Otimização',
  'oifde.route.alarmEvent': 'Evento de alarme',
  'oifde.route.addDiagram': 'Adicionar fluxo',
  'oifde.route.editCellDiagram': 'Editar quadro composto',
  'oifde.route.edit': 'Editar {0}',
  'oifde.route.view': 'Exibir {0}',
  'oifde.route.editDiagram': 'Editar fluxo',
  'oifde.route.viewDiagram': 'Exibir fluxo',
  'oifde.route.viewTraceDiagram': 'Exibir rastream fluxo',
  'oifde.route.viewCellTraceDiagram': 'Exibir rastreamento de elementos de diagrama composto',
  'oifde.route.voiceTemplateTitle': 'Modelo de recurso',
  'oifde.route.staticCheck': 'Verific. fluxo',
  'oifde.route.unusedResource.complexcell': 'Exibir elementos de diagrama composto não utilizados',
  'oifde.route.unusedResource.interface': 'Exibir interfaces não utilizadas',
  'oifde.route.unusedResource.procedure': 'Exibir proced. armazenados não utilizados',
  'oifde.route.unusedResource.rule': 'Exibir regras cinza não utilizadas',
  'oifde.route.unusedResource.template': 'Exibir modelos não utilizados',
  'oifde.route.unusedResource.globalvars': 'Exibir variáveis globais não utilizadas',
  'oifde.route.unusedResource.flowvars': 'Exibir variáveis de fluxo não utilizadas',
  'oifde.route.tuc.template.index': 'Gerenciamento de Conhecimento',
  'oifde.route.tuc.template.domain': 'Gerenciamento de domínio',
  'oifde.route.tuc.template.intent': 'Gerenciamento de intenção',
  'oifde.route.tuc.template.entity': 'Gerenciamento de entidades',
  'oifde.route.tuc.template.faq': 'Gerenciamento do FAQ',
  'oifde.route.tuc.template.kg': 'Gerenciamento de gráficos',
  'oifde.route.tuc.template.config': 'Config. do sistema',
  'oifde.route.tuc.history.dialog': 'Revisão do histórico de mensagens',
  'oifde.route.tuc.history.mining': 'Tar. de organiz. p/ clusters de perg.',
  'oifde.route.tuc.ops.index': 'Verificação treinamento',
  'oifde.route.tuc.ops.test': 'Teste de diálogo',
  'oifde.route.tuc.ops.tucTest': 'Teste de conhecimento',
  'oifde.route.tuc.ops.perf': 'Estatística de Desempenho',
  'oifde.route.tuc.ops.node': 'Manutenção',
  'oifde.route.tuc.ops.log': 'Registro da operação',
  'oifde.route.tuc.ops.corpustest': 'Teste de corpus',
  'oifde.route.tuc.ops.server': 'Servidores',
  'oifde.route.tuc.ops.qiAsrServer': 'Inspeção ASR',
  'oifde.route.tuc.ops.asr': 'TTS/ASR',
  'oifde.route.tuc.ops.avatar': '2D Humano Virtual',
  'oifde.route.tuc.ops.modelMgr': 'Modelo de análise de sentimentos',
  'oifde.route.tuc.ops.sysparam': 'Parâmetro Inteligente do Motor',
  'oifde.route.tuc.ops.kgrule': 'Regra cinza de conhecimento',
  'oifde.route.tuc.ops.license': 'Licenciamento',
  'oifde.route.keyPressRobot': 'IVR comum',
  'oifde.route.RobotManage': 'Chatbot Inteligente',
  'oifde.route.sysMgr.sysMgr': 'O sistema',
  'oifde.route.sysMgr.sysCfg': 'Config. do sistema',
  'oifde.route.sysMgr.whiteList': 'Lista branca',
  'oifde.route.sysMgr.recordHistory': 'Chamada do histórico',
  'oifde.route.navigation': 'Processos',
  'oifde.route.resources': 'Recursos',
  'oifde.route.serviceInterface': 'Interface',
  'oifde.route.variable': 'Variable',
  'oifde.route.cell': 'Célula Complexa',
  'oifde.route.procedure': 'Procedimento',
  'oifde.route.dataTable': 'Folha de dados',
  'oifde.route.knowledgegraph.knowledgegraph': 'Gráfico de conhecimento',
  'oifde.route.knowledgegraph.kgModel': 'Esquema de conhecimento',
  'oifde.route.knowledgegraph.editSchema': 'Detalhes do esquema {0}',
  'oifde.route.knowledgegraph.dicmgr': 'Dicionário gráfico',
  'oifde.route.knowledgegraph.entitymgr': 'Gerenciamento de entidades para {0}',
  'oifde.route.knowledgegraph.entityAttribute': 'Atributo de entidade para {0}',
  'oifde.route.knowledgegraph.relationmgr': 'Gerenciamento de relacionamento para {0}',
  'oifde.route.knowledgegraph.relationAttribute': 'Atributo de relacionamento para {0}',
  'oifde.route.knowledgegraph.kgView': 'Visualização de gráfico para {0}',
  'oifde.route.knowledgegraph.kgFAQ': 'FAQ do gráfico {0}',
  'oifde.route.knowledgegraph.kgSynonyms': 'Sinônimo de {0}',
  'oifde.route.toc.sysadmin': 'Compreensão Semântica',
  'oifde.route.toc.user': 'Compreensão Semântica',
  'oifde.flow.flowTypeSelect': 'Selecione Novo Modelo de fluxo',
  'oifde.flow.flowTemplate': 'Modelo de fluxo',
  'oifde.flow.code': 'Código',
  'oifde.flow.name': 'Nome',
  'oifde.flow.version': 'Versão',
  'oifde.flow.scenarioType': 'Tipo de cenário',
  'oifde.flow.status': 'Status',
  'oifde.flow.rule': 'Regra cinza',
  'oifde.flow.createTime': 'Criado',
  'oifde.flow.updateTime': 'Modificado',
  'oifde.flow.grayFlow': 'Cinza',
  'oifde.flow.noRule': 'Nenhuma regra configurada',
  'oifde.flow.defaultFlow': 'Fluxo padrão',
  'oifde.flow.actions.publish': 'Publicar',
  'oifde.flow.actions.delete': 'Excluir',
  'oifde.flow.actions.edit': 'Editar',
  'oifde.flow.actions.invalid': 'Invalidar',
  'oifde.flow.actions.upgrade': 'Atualizar',
  'oifde.flow.actions.copy': 'Fluxo de cópia',
  'oifde.flow.actions.copySuccess': 'O fluxo foi copiado com êxito.',
  'oifde.flow.actions.copyFailed': 'Falha ao copiar o fluxo.',
  'oifde.flow.actions.deleteConfirm': 'Esta operação excluirá permanentemente o fluxo. Tem certeza de que deseja continuar?',
  'oifde.flow.actions.deleteCancel': 'A exclusão foi cancelada.',
  'oifde.flow.actions.deleteOk': 'O fluxo foi excluído com sucesso.',
  'oifde.flow.actions.invalidConfirm': 'Esta operação trará o fluxo inédito. Tem certeza de que deseja continuar?',
  'oifde.flow.actions.invalidOk': 'O fluxo tem inédito.',
  'oifde.flow.actions.invalidCancel': 'A invalidação foi cancelada.',
  'oifde.flow.actions.createOk': 'Uma nova versão do fluxo foi criada com êxito.',
  'oifde.flow.actions.settingOk': 'O fluxo foi configurado com sucesso.',
  'oifde.flow.actions.publishOk': 'O fluxo foi publicado com êxito.',
  'oifde.flow.actions.confirm': 'Sim',
  'oifde.flow.actions.prompt': 'Confirmar',
  'oifde.flow.actions.cancel': 'Não',
  'oifde.flow.rules.ruleId': 'Selecione uma regra.',
  'oifde.flow.rules.upgradeMode': 'Selecione um modo de publicação.',
  'oifde.flow.rules.logSwitchOn': 'Selecione se deseja ativar a função de registro.',
  'oifde.flow.rules.validationMode': 'Selecione se deseja ativar a validação estrita.',
  'oifde.flow.rules.newVersion': 'Especifique uma nova versão.',
  'oifde.flow.rules.newVersionDuplicated': 'A nova versão deve ser única.',
  'oifde.flow.rules.newVersionLength': 'A nova versão não pode ter mais de 64 caracteres.',
  'oifde.flow.rules.newFlowName': 'Especifique um novo nome de fluxo.',
  'oifde.flow.rules.workMode': 'Selecione um modo de trabalho.',
  'oifde.flow.enums.scenarioType.mainFlow': 'Fluxo principal',
  'oifde.flow.enums.scenarioType.errorFlow': 'Fluxo de exceção',
  'oifde.flow.enums.scenarioType.subFlow': 'Subfluxo',
  'oifde.flow.enums.status.init': 'Rascunho',
  'oifde.flow.enums.status.valid': 'Publicada',
  'oifde.flow.enums.status.invalid': 'Inválido',
  'oifde.flow.enums.tabsValue.all': 'Exp. tudo',
  'oifde.flow.enums.tabsValue.none': 'Não exportar',
  'oifde.flow.enums.tabsValue.depend': 'Exportar dependências',
  'oifde.flow.publish.flowName': 'Nome',
  'oifde.flow.publish.flowVersion': 'Versão',
  'oifde.flow.publish.upgradeMode': 'Modo de publicação',
  'oifde.flow.publish.upgrade': 'Atualizar e publicar',
  'oifde.flow.publish.gray': 'Lançamento Gray',
  'oifde.flow.publish.ruleName': 'Regra cinza',
  'oifde.flow.publish.selectPlaceholder': '-Selec.-',
  'oifde.flow.publish.publishMode': 'Modo operação',
  'oifde.flow.publish.normal': 'Comum',
  'oifde.flow.publish.monitor': 'Monitor',
  'oifde.flow.publish.insert': 'Inserir',
  'oifde.flow.publish.notConfig': 'Não configurado',
  'oifde.flow.copy.flowName': 'Nome de fluxo original',
  'oifde.flow.copy.newFlowName': 'Novo nome de fluxo',
  'oifde.flow.setting.title': 'Modif.',
  'oifde.flow.setting.validationMode': 'Definir Modo de validação',
  'oifde.flow.setting.validationModeOn': 'Ativar',
  'oifde.flow.setting.validationModeOff': 'Desativar',
  'oifde.flow.upgrade.currentVersions': 'Versões atuais',
  'oifde.flow.upgrade.newVersion': 'Nova versão',
  'oifde.flow.exportFlowList.accessCode': 'Código de acesso',
  'oifde.flow.exportFlowList.rule': 'Regra',
  'oifde.flow.exportFlowList.template': 'Modelo',
  'oifde.flow.exportFlowList.interface': 'Interface',
  'oifde.flow.exportFlowList.variable': 'Variável',
  'oifde.flow.exportFlowList.title': 'Você não selecionou registros específicos. Exportar todos os registros? Você também pode cancelar a operação e selecionar um registro específico para exportar.',
  'oifde.flow.exportFlowList.askExportAll': 'Não seleccionou nenhum registo específico. Deseja exportar todos os registos? Você também pode cancelar e selecionar registros específicos para exportação.',
  'oifde.flow.exportFlowList.prompt': 'Informação',
  'oifde.flow.exportFlowList.promptMessage': 'Selecione pelo menos um registro.',
  'oifde.flow.exportFlowList.export': 'Exportar',
  'oifde.flow.exportFlowList.exportAll': 'Exp. tudo',
  'oifde.flow.exportFlowList.procedure': 'Procedimento armazenado',
  'oifde.flow.exportFlowList.complexCell': 'Elemento de diagrama composto',
  'oifde.flow.importFlow.title': 'Importar fluxo',
  'oifde.flow.importFlow.select': 'Selecionar arquivo',
  'oifde.flow.importFlow.upload': 'Upload',
  'oifde.flow.importFlow.prompt': 'O arquivo deve ser um ZIP.',
  'oifde.flow.importFlow.cover': 'Permitir nomes duplicados',
  'oifde.flow.importFlow.resultTitle': 'Importar resultados',
  'oifde.flow.importFlow.name': 'Nome',
  'oifde.flow.importFlow.type': 'Tipo',
  'oifde.flow.importFlow.result': 'Result',
  'oifde.flow.importFlow.message': 'Selecione o arquivo que deseja carregar.',
  'oifde.flow.importFlow.fileType': 'O arquivo deve ser um ZIP.',
  'oifde.flow.importFlow.import': 'Importar',
  'oifde.flow.importFlow.success': 'A operação foi realizada com sucesso.',
  'oifde.flow.importFlow.bigFile': 'O arquivo é muito grande.',
  'oifde.flow.importFlow.uploadFail': 'Falha ao carregar o arquivo.',
  'oifde.flow.importFlow.bigFilePrompt': 'O arquivo não pode exceder 20 MB.',
  'oifde.flow.importFlow.timeout': 'Importando dados... Consulte os dados mais recentes mais tarde.',
  'oifde.flow.usage.usageStatus': 'Status das estatísticas:',
  'oifde.flow.usage.lasttime': 'Última conclusão:',
  'oifde.flow.usage.check': 'Marcar',
  'oifde.flow.usage.showUnused': 'Exibir recursos não utilizados',
  'oifde.flow.usage.complexcellUsageTitle': 'Uso de elementos de diagrama composto',
  'oifde.flow.usage.interfaceUsageTitle': 'Uso da interface',
  'oifde.flow.usage.procedureUsageTitle': 'Uso de procedimento armazenado',
  'oifde.flow.usage.ruleUsageTitle': 'Uso da regra cinza',
  'oifde.flow.usage.templateUsageTitle': 'Uso do modelo',
  'oifde.flow.usage.globalVarsUsageTitle': 'Uso de variável global',
  'oifde.flow.usage.flowVarsUsageTitle': 'Uso de variável de fluxo',
  'oifde.flow.usage.inuse': 'Em uso',
  'oifde.flow.usage.unuse': 'Nunca usado',
  'oifde.flow.usage.status.norecord': 'Nenhum dado',
  'oifde.flow.usage.status.init': 'Execução pendente',
  'oifde.flow.usage.status.running': 'Executando',
  'oifde.flow.usage.status.finish': 'Concluído',
  'oifde.flow.usage.status.fail': 'Falhou',
  'oifde.flow.usage.name': 'Nome',
  'oifde.flow.usage.type': 'Tipo',
  'oifde.flow.usage.desc': 'Descrição',
  'oifde.flow.usage.resourceType.complexcell': 'Elemento de diagrama composto',
  'oifde.flow.usage.resourceType.interface': 'Interface',
  'oifde.flow.usage.resourceType.procedure': 'Procedimento armazenado',
  'oifde.flow.usage.resourceType.rule': 'Regra cinza',
  'oifde.flow.usage.resourceType.template': 'Modelo',
  'oifde.flow.usage.resourceType.globalvars': 'Variável global',
  'oifde.flow.usage.resourceType.flowvars': 'Variável de fluxo',
  'oifde.flow.add.blankFlow': 'Fluxo em branco',
  'oifde.flow.add.callInFlow': 'Fluxo de chamadas recebidas',
  'oifde.flow.add.outBoundFlow': 'Fluxo de chamadas efetuadas',
  'oifde.flow.add.exceptionFlow': 'Fluxo anormal',
  'oifde.template.setInputFlag': 'Inserir conteúdo manualmente',
  'oifde.template.sysErrorTemplateTitle': 'Modelo de recurso anormal',
  'oifde.template.sysErrorTemplate': 'Editar Modelo de recurso anormal',
  'oifde.template.contentDetail': 'Detalhes',
  'oifde.template.aiccTemplateNullTips': 'O tipo do modelo não pode ficar em branco.',
  'oifde.template.template': 'Gerenciamento de modelos de recursos',
  'oifde.template.channelTypeMgr': 'Gerenciamento do tipo de canal',
  'oifde.template.responseStyleMgr': 'Gerenciamento de estilo',
  'oifde.template.channelTypeName': 'Nome',
  'oifde.template.createChannelType': 'Adicionar canal',
  'oifde.template.editChannelType': 'Editar canal',
  'oifde.template.responseStyleName': 'Nome',
  'oifde.template.createResponseStyle': 'Adic. estilo',
  'oifde.template.editResponseStyle': 'Editar estilo',
  'oifde.template.selectChannelType': 'Selecione um canal.',
  'oifde.template.selectResponseStyle': 'Selecione um estilo.',
  'oifde.template.selectLang': 'Selecione um idioma.',
  'oifde.template.editContent': 'Adicionar conteúdo do modelo',
  'oifde.template.setDefault': 'Definir como padrão',
  'oifde.template.name': 'Nome',
  'oifde.template.type': 'Tipo',
  'oifde.template.desc': 'Descrição',
  'oifde.template.content': 'Conteúdo',
  'oifde.template.createTime': 'Criado',
  'oifde.template.updateTime': 'Modificado',
  'oifde.template.detail': 'Detalhes do conteúdo',
  'oifde.template.select': 'Tipo',
  'oifde.template.contentName': 'Conteúdo',
  'oifde.template.deleteContent': 'Excluir',
  'oifde.template.addContent': 'Adicionar conteúdo do modelo',
  'oifde.template.tipContent': 'Insira o conteúdo do modelo.',
  'oifde.template.tipForVoice': 'Insira o caminho absoluto para o arquivo de voz.',
  'oifde.template.tipForVideo': 'Insira o caminho absoluto para o arquivo de vídeo.',
  'oifde.template.addLimit': 'Um máximo de três registros de conteúdo é permitido.',
  'oifde.template.deleteLimit': 'É obrigatório pelo menos um registro de conteúdo.',
  'oifde.template.addNewIntent': 'Adicionar intenção',
  'oifde.template.setIntentTemplate': 'Configurar modelo de intenção',
  'oifde.template.addTopNode': 'Adic. nó principal',
  'oifde.template.selectTemplate': 'Selecionar modelo',
  'oifde.template.selectAction': 'Selec',
  'oifde.template.actions.delete': 'Excluir',
  'oifde.template.actions.edit': 'Editar',
  'oifde.template.actions.editbaseInfo': 'Editar modelo de recurso',
  'oifde.template.actions.addbaseInfo': 'Adicionar modelo de recurso',
  'oifde.template.actions.success': 'Sucesso',
  'oifde.template.actions.createSuccess': 'Modelo criado com êxito.',
  'oifde.template.actions.saveSuccess': 'O modelo foi salvo com êxito.',
  'oifde.template.actions.updateSuccess': 'O modelo foi modificado com êxito.',
  'oifde.template.actions.deleteSuccess': 'O modelo foi excluído com sucesso.',
  'oifde.template.actions.cancelDelete': 'A exclusão foi cancelada.',
  'oifde.template.actions.deletePrompt': 'Esta operação excluirá permanentemente o modelo. Tem certeza de que deseja continuar?',
  'oifde.template.actions.deleteChannelType': 'Esta operação excluirá permanentemente o canal. Tem certeza de que deseja continuar?',
  'oifde.template.actions.deleteResponseStyle': 'Esta operação excluirá permanentemente o estilo. Tem certeza de que deseja continuar?',
  'oifde.template.actions.prompt': 'Confirmar',
  'oifde.template.actions.confirm': 'Sim',
  'oifde.template.actions.cancel': 'Não',
  'oifde.template.actions.append': 'Adic.',
  'oifde.template.update.templateName': 'Nome',
  'oifde.template.update.templateType': 'Tipo',
  'oifde.template.update.templateDesc': 'Descrição',
  'oifde.template.update.intents': 'Lista de intenções',
  'oifde.template.typeDetail.tts': 'TTS',
  'oifde.template.typeDetail.sms': 'SMS',
  'oifde.template.typeDetail.staticvoice': 'Voz estática',
  'oifde.template.typeDetail.videovoice': 'Arquivo vídeo',
  'oifde.template.typeDetail.multiMedia': 'Multimídia',
  'oifde.template.typeDetail.cardTemplate': 'Modelo de cartão',
  'oifde.template.language.chinese': 'Chinês',
  'oifde.template.language.english': 'Inglês',
  'oifde.template.language.spanish': 'Espanhol',
  'oifde.template.validate.templateName': 'Inserir nome de modelo',
  'oifde.template.validate.templateType': 'Selecione um tipo de modelo.',
  'oifde.template.validate.doubleNameCheck': 'O nome do modelo já existe.',
  'oifde.template.validate.intentDsp': 'Insira uma descrição de modelo.',
  'oifde.template.validate.intentcode': 'Insira um código de intenção.',
  'oifde.template.validate.contentLengthError': 'O conteúdo do modelo deve conter 1 a 4096 caracteres.',
  'oifde.template.intentionInfo.addIntent': 'Adicionar intenção',
  'oifde.template.intentionInfo.intentDsp': 'Descrição',
  'oifde.template.intentionInfo.intentcode': 'Código',
  'oifde.template.intentionInfo.intentList': 'Lista',
  'oifde.template.message.intentListLength': 'A lista de intenção pode conter no máximo 4096 caracteres.',
  'oifde.template.message.intentNameDuplicate': 'O nome da intenção já existe.',
  'oifde.template.message.validateResult': 'Informação',
  'oifde.template.content.exist': 'O conteúdo do modelo atualmente selecionado foi adicionado',
  'oifde.template.selectResource': 'Selecionar recursos',
  'oifde.template.message.searchResourceName': 'Pesquisar nomes de recursos',
  'oifde.template.title.ResourceName': 'Nome do recurso',
  'oifde.template.title.ResourceType': 'Tipo de recurso',
  'oifde.template.multipart.type.location': 'Endereço',
  'oifde.rule.flowRule': 'Regra cinza de fluxo',
  'oifde.rule.kgRule': 'Regra cinza de conhecimento',
  'oifde.rule.name': 'Nome',
  'oifde.rule.beginNum': 'Número inicial',
  'oifde.rule.endNum': 'Número final',
  'oifde.rule.language': 'Idioma',
  'oifde.rule.number': 'Quantidade',
  'oifde.rule.manage': 'Gerenciar',
  'oifde.rule.actions.delete': 'Excluir',
  'oifde.rule.actions.edit': 'Editar',
  'oifde.rule.actions.success': 'Sucesso',
  'oifde.rule.actions.addSuccess': 'A regra foi adicionada com êxito.',
  'oifde.rule.actions.updateSuccess': 'A regra foi modificada com êxito.',
  'oifde.rule.actions.deleteSuccess': 'A regra foi excluída com êxito.',
  'oifde.rule.actions.addbaseInfo': 'Criar informações básicas',
  'oifde.rule.actions.confirm': 'Confirmar',
  'oifde.rule.actions.sure': 'Sim',
  'oifde.rule.actions.cancel': 'Não',
  'oifde.rule.actions.cancelDelete': 'A exclusão foi cancelada.',
  'oifde.rule.actions.deleteConfirm': 'Esta operação excluirá permanentemente a regra. Tem certeza de que deseja continuar?',
  'oifde.rule.actions.viewBoundFlow': 'Exibindo Processos Vinculados',
  'oifde.rule.rules.ruleNameValidator': 'O nome da regra já existe.',
  'oifde.rule.rules.beginNumValidator': 'O número inicial pode conter somente letras, dígitos, espaços e os seguintes caracteres especiais: _ - \' : . {\'@\'} /.',
  'oifde.rule.rules.endNumValidator': 'O número final pode conter somente letras, dígitos, espaços e os seguintes caracteres especiais: _ - \' : . {\'@\'} /.',
  'oifde.rule.rules.beginNumLengthValidator': 'O número final deve ser maior ou igual ao número inicial.',
  'oifde.rule.update.ruleName': 'Nome da regra',
  'oifde.rule.update.beginNum': 'Número inicial',
  'oifde.rule.update.newRuleName': 'Insira um nome para a regra.',
  'oifde.rule.update.newBeginNum': 'Insira um número inicial.',
  'oifde.rule.update.newEndNum': 'Insira um número final.',
  'oifde.rule.update.endNum': 'Número final',
  'oifde.rule.message.add_name_list': 'Adic. lista de bloqueios/permissões',
  'oifde.rule.message.name_list_info': 'Detal. da lista de bloqueios/permissões',
  'oifde.rule.message.succeed_save': 'A lista de bloqueios/lista de permissões foi salva com êxito.',
  'oifde.rule.message.fail_save': 'Falha ao salvar a lista de bloqueios e a lista de permissões.',
  'oifde.rule.message.failed_query': 'Falha ao executar a consulta.',
  'oifde.rule.message.select_prompt': 'Selecione pelo menos um registro.',
  'oifde.rule.message.confirm_name_list': 'Deseja realmente excluir a lista de bloqueios/lista de permissões?',
  'oifde.rule.message.succeed_delete': 'A lista de bloqueios/lista de permissões foi excluída com êxito.',
  'oifde.rule.message.fail_delete': 'Falha ao excluir a lista de bloqueios ou a lista de permissões.',
  'oifde.rule.message.mgr_name_list': 'Gerenciar lista de bloqueios/permissões –',
  'oifde.rule.message.add_user_ids': 'Adicionar número do assinante',
  'oifde.rule.message.batch_input_prompt': 'Pressione Enter após cada registro para adicioná-lo. O nome do registro pode conter de 4 e 64 caracteres.',
  'oifde.rule.message.user_number': 'Número do assinante',
  'oifde.rule.message.user_ids_number': 'Um máximo de 5000 números de assinantes podem ser adicionados à lista de bloqueios/lista de permissões.',
  'oifde.rule.message.user_ids_number_once': 'Um máximo de 100 números de assinantes podem ser adicionados à lista de bloqueios/lista de permissões em um lote.',
  'oifde.rule.message.user_id_not_exceed': 'Cada número de assinante pode conter de 4 a 64 caracteres.',
  'oifde.rule.message.save_user_ids_success': 'Os números de assinante foram salvos com êxito.',
  'oifde.rule.message.save_user_ids_fail': 'Falha ao salvar os números de assinante.',
  'oifde.rule.message.confirm_delete_user_ids': 'Deseja realmente excluir os números de assinante?',
  'oifde.rule.message.succeed_delete_user_ids': 'Os números de assinante foram excluídos com êxito.',
  'oifde.rule.message.failed_delete_user_ids': 'Falha ao excluir os números de assinante.',
  'oifde.accesscode.usingRecord': 'Captura de pacotes',
  'oifde.accesscode.messageTips': 'Reserve um momento para fornecer feedback sobre as melhorias que você gostaria de ver.',
  'oifde.accesscode.more': 'Mais',
  'oifde.accesscode.bindDomain': 'Vincular domínio',
  'oifde.accesscode.bindFaqGroup': 'Vincular grupo FAQ',
  'oifde.accesscode.bindKg': 'Vincular gráfico de conhecimento',
  'oifde.accesscode.robotName': 'Nome do chatbot',
  'oifde.accesscode.domain': 'Domín.',
  'oifde.accesscode.faqGroup': 'Grupo FAQ',
  'oifde.accesscode.knowledgeGraph': 'Gráfico de conhecimento',
  'oifde.accesscode.setTrace': 'Config. de rastreamento',
  'oifde.accesscode.callingNumberTrace': 'Rastreamento do número de chamada:',
  'oifde.accesscode.traceOpen': 'Ativar',
  'oifde.accesscode.traceClose': 'Desativar',
  'oifde.accesscode.hideTraceSet': 'Recolher',
  'oifde.accesscode.setTraceSuccess': 'As configurações foram aplicadas com êxito.',
  'oifde.accesscode.accessCode': 'Código de acesso de chatbot',
  'oifde.accesscode.accessCodeSearch': 'Nome do Chatbot ou Código de Acesso',
  'oifde.accesscode.accessCodeDesc': 'Descrição',
  'oifde.accesscode.description': 'ID do código de acesso',
  'oifde.accesscode.descPlaceholder': 'Esta descrição é usada como a descrição do fluxo quando o fluxo é selecionado como a configuração da parte chamada. O valor deve ser único.',
  'oifde.accesscode.flowCode': 'Código do fluxo',
  'oifde.accesscode.flowName': 'Nome do fluxo',
  'oifde.accesscode.domainId': 'ID de domínio',
  'oifde.accesscode.dialogType': 'Tipo de diálogo',
  'oifde.accesscode.callingnumber': 'Número de chamada',
  'oifde.accesscode.callednumber': 'Número chamado:',
  'oifde.accesscode.processTimes': 'Número de chamadas processadas somente pelo agente',
  'oifde.accesscode.startcall': 'Iniciar chamada',
  'oifde.accesscode.sendprompt': 'Pressione Enter para enviar sua mensagem.',
  'oifde.accesscode.callingnumberinput': 'Insira um número de chamada.',
  'oifde.accesscode.isCheck': 'Verificar ortografia',
  'oifde.accesscode.isCheckHelp': 'Erros de ortografia nas declarações do cliente são corrigidos automaticamente antes que a intenção seja reconhecida.',
  'oifde.accesscode.sendContent': 'Envi.',
  'oifde.accesscode.trl': 'Digitar da direita para a esquerda',
  'oifde.accesscode.actions.edit': 'Editar',
  'oifde.accesscode.actions.testCalledNum': 'Chamada teste',
  'oifde.accesscode.actions.delete': 'Excluir',
  'oifde.accesscode.actions.editbaseInfo': 'Modificar chatbot',
  'oifde.accesscode.actions.addbaseInfo': 'Adic. chatbot',
  'oifde.accesscode.actions.success': 'Sucesso',
  'oifde.accesscode.actions.fail': 'Falha',
  'oifde.accesscode.actions.createSuccess': 'Criação bem-sucedida.',
  'oifde.accesscode.actions.updateSuccess': 'Modificação bem-sucedida.',
  'oifde.accesscode.actions.deleteSuccess': 'Exclusão bem-sucedida.',
  'oifde.accesscode.actions.cancelDelete': 'A exclusão foi cancelada.',
  'oifde.accesscode.actions.deletePrompt': 'Esta operação excluirá permanentemente o chatbot. Tem certeza de que deseja continuar?',
  'oifde.accesscode.actions.prompt': 'Confirmar',
  'oifde.accesscode.actions.confirm': 'Sim',
  'oifde.accesscode.actions.cancel': 'Não',
  'oifde.accesscode.actions.flowChatbot': 'Chatbot de teste de fluxo',
  'oifde.accesscode.actions.callforwarding': 'Transf. de chamada',
  'oifde.accesscode.actions.voicefile': 'Arquivo de voz:',
  'oifde.accesscode.actions.videofile': 'Arquivo de vídeo:',
  'oifde.accesscode.dialogTypeDetail.voiceNavigation': 'Navegação por voz',
  'oifde.accesscode.dialogTypeDetail.smartOutboundCall': 'Chamada efetuada inteligente',
  'oifde.accesscode.dialogTypeDetail.chatbot': 'Chatbot',
  'oifde.accesscode.dialogTypeDetail.ivrProcess': 'Fluxo de IVR',
  'oifde.accesscode.validate.accessCode': 'Insira um código de acesso de fluxo.',
  'oifde.accesscode.validate.accessCodeCharReg': 'É permitido usar apenas letras e dígitos.',
  'oifde.accesscode.validate.doubleAccessCodeCheck': 'O código de acesso já existe.',
  'oifde.accesscode.validate.dialogType': 'Selecione um tipo de diálogo.',
  'oifde.accesscode.validate.flowCode': 'Selecione um código de fluxo.',
  'oifde.accesscode.validate.accessCodeLength': 'O código de acesso pode conter, no máximo, 20 caracteres.',
  'oifde.accesscode.validate.flowCodeLength': 'O código de fluxo pode conter, no máximo, 64 caracteres.',
  'oifde.accesscode.validate.descriptionLengthValidator': 'A descrição do código de acesso pode conter até 512 caracteres.',
  'oifde.accesscode.validate.domainIdMax': 'O ID de domínio não pode exceder 2147483647.',
  'oifde.accesscode.validate.domainIdCheck': 'O ID do domínio não é um número válido.',
  'oifde.accesscode.recordState.disable': 'Desativar',
  'oifde.accesscode.recordState.enable': 'Ativar',
  'oifde.accesscode.accessCodeSubscribed': 'O código de acesso do chatbot está em uso.',
  'oifde.accesscode.bindScene': 'Visualizar referências',
  'oifde.accesscode.chatBot.search': 'Pesquisar',
  'oifde.accesscode.chatBot.reset': 'Redefinir',
  'oifde.accesscode.searchScene': 'Selecione um cenário.',
  'oifde.accesscode.sceneName': 'Cenário',
  'oifde.accesscode.subName': 'Nome da referência',
  'oifde.accesscode.createTime': 'Tempo de referência',
  'oifde.accesscode.chatBot.chatChanel': 'Canal multimídia',
  'oifde.accesscode.chatBot.agentAssistant': 'Agente assistente',
  'oifde.accesscode.chatBot.intelligentOB': 'Chamada de saída inteligente',
  'oifde.accesscode.chatBot.intelligentTrain': 'Treinamento Inteligente',
  'oifde.toc.statusCode.SERVER_BUSY': 'Sistema ocupado no momento. Tente mais tarde.',
  'oifde.toc.statusCode.NEED_LOGIN': 'Você não fez login ou executou operações por um longo período. Faça login novamente.',
  'oifde.toc.statusCode.ACCOUNT_INCONSISTENT': 'Você se conectou ao computador local usando outra conta. Para continuar usando este serviço, faça login novamente.',
  'oifde.toc.statusCode.ACCOUNT_SWITCHED': 'A conta de login foi alterada. Esta página será atualizada para exibir a conta de login mais recente.',
  'oifde.toc.statusCode.INCORRECT_VERIFYCODE': 'O código de verificação é incorreto. Insira o código correto.',
  'oifde.toc.statusCode.INVALID_JSON': 'A solicitação não está no formato JSON.',
  'oifde.toc.statusCode.HTTPS_REQUIRED': 'Somente HTTPS é compatível.',
  'oifde.toc.statusCode.EMPTY_ACCOUNT': 'O número da conta está vazio.',
  'oifde.toc.statusCode.INVALID_ACCOUNT': 'Falha na autenticação. Faça login novamente.',
  'oifde.toc.statusCode.INSUFFICIENT_PERMISSION': 'Você não tem a permissão necessária. Entre em contato com o administrador.',
  'oifde.toc.statusCode.VERIFYCODE_EXPIRED': 'O código de verificação expirou. Tente novamente mais tarde.',
  'oifde.toc.statusCode.ACCOUNT_LOCKED': 'A conta foi bloqueada temporariamente. Tente novamente mais tarde ou entre em contato com o administrador para desbloquear a conta.',
  'oifde.toc.statusCode.ACCOUNT_CHANGED': 'A conta foi modificada. Faça login novamente.',
  'oifde.toc.statusCode.ACCOUNT_DISABLED': 'A conta foi suspensa. Entre em contato com o administrador.',
  'oifde.toc.statusCode.UNLOCK_REQUIRED': 'A conta foi bloqueada temporariamente. Desbloqueie conta e tente novamente.',
  'oifde.toc.statusCode.ADMIN_DISABLED': 'A função de gerenciamento foi desativada. Use uma conta comum para fazer login no sistema.',
  'oifde.toc.statusCode.SESSION_OVERFLOW': 'O número de usuários on-line atingiu o limite máximo. Tente novamente mais tarde.',
  'oifde.toc.statusCode.SESSION_EXPIRED': 'O login atual é inválido. Faça login novamente.',
  'oifde.toc.statusCode.SESSION_DUPLICATED': 'Você já está conectado. Para entrar novamente, você deve primeiro sair.',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_ID': 'O ID do espaço do locatário está incorreto.',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_NAME': 'O nome do espaço do locatário está incorreto.',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT': 'A conta está incorreta.',
  'oifde.toc.statusCode.INCORRECT_PASSWORD': 'A senha não atende aos requisitos.',
  'oifde.toc.statusCode.INCORRECT_GENDER': 'O gênero está incorreto.',
  'oifde.toc.statusCode.INCORRECT_OWNER': 'O proprietário está incorreto.',
  'oifde.toc.statusCode.INCORRECT_TITLE': 'Comprim. não pode exceder 8 caracteres.',
  'oifde.toc.statusCode.INCORRECT_TELEPHONE': 'O número de telefone inserido está incorreto.',
  'oifde.toc.statusCode.INCORRECT_EMAIL': 'O endereço de e-mail está incorreto.',
  'oifde.toc.statusCode.INCORRECT_ROLE_NAME': 'O nome da regra está incorreto.',
  'oifde.toc.statusCode.EMPTY_TENANT_SPACE': 'O espaço do locatário não pode ficar vazio.',
  'oifde.toc.statusCode.ACCOUNT_DUPLICATED': 'A conta já existe.',
  'oifde.toc.statusCode.ROLE_DUPLICATED': 'A função já existe.',
  'oifde.toc.statusCode.TENANT_SPACE_DUPLICATED': 'O espaço do locatário já existe.',
  'oifde.toc.statusCode.ACCOUNT_NOT_FOUND': 'A conta não existe.',
  'oifde.toc.statusCode.ROLE_NOT_FOUND': 'A função não existe.',
  'oifde.toc.statusCode.TENANT_SPACE_NOT_FOUND': 'A política de locatário não existe.',
  'oifde.toc.statusCode.ACCOUNT_FORBIDDEN': 'As operações nesta conta são proibidas.',
  'oifde.toc.statusCode.ROLE_FORBIDDEN': 'As operações nesta função são proibidas.',
  'oifde.toc.statusCode.TENANT_SPACE_FORBIDDEN': 'As operações neste espaço do locatário são proibidas.',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT_INPUT': 'A conta pode conter de 6 a 32 caracteres compostos de letras, dígitos e os seguintes caracteres especiais: . {\'@\'} _ -',
  'oifde.toc.statusCode.INCORRECT_PASSWORD_UPDATE': 'A senha não pode conter o mesmo caractere repetido mais de 3 vezes consecutivas. Além disso, as últimas 3 senhas históricas não podem ser reutilizadas. A senha pode conter de 8 a 20 caracteres compostos por dígitos, letras minúsculas, letras maiúsculas e um dos seguintes caracteres especiais: ~ ` ! {\'@\'} # $ % ^ * ( ) - + _ | , . / < > ? ; \' : " [ ] \u007b \u007d &',
  'oifde.toc.statusCode.COMMON_ERROR': 'Erro do servidor',
  'oifde.toc.statusCode.SERVICE_UNAVAILABLE': 'O serviço está atualmente indisponível.',
  'oifde.toc.statusCode.AUTHORIZE_FAILED': 'Falha na validação.',
  'oifde.toc.statusCode.TRANSIT_FAILED': 'Tempo limite da solicitação esgotado.',
  'oifde.toc.statusCode.TRANSIT_ERROR': 'A solicitação foi enviada com êxito, mas o par retornou um erro.',
  'oifde.toc.statusCode.RESPONSE_FAILED': 'O servidor não conseguiu processar a resposta.',
  'oifde.toc.statusCode.REFRESH_TIMEOUT': 'Nenhuma operação foi realizada por um longo período. Faça login novamente.',
  'oifde.logparam.logParamMgr': 'Configurações de parâmetros do Flume Log',
  'oifde.logparam.logParam': 'Parâmetros dinâmicos',
  'oifde.logparam.logName': 'Nome do parâmetro',
  'oifde.logparam.source': 'Origem do parâmetro',
  'oifde.logparam.sourceName': 'Nome de origem de parâmetros',
  'oifde.logparam.transFlag': 'Converter valor',
  'oifde.logparam.sourceValue': 'Valor de origem',
  'oifde.logparam.targetValue': 'Valor de destino',
  'oifde.logparam.actions.edit': 'Editar',
  'oifde.logparam.actions.transDetail': 'Converter valor',
  'oifde.logparam.actions.delete': 'Excluir',
  'oifde.logparam.actions.editLogParamBaseInfo': 'Editar parâmetro dinâmico',
  'oifde.logparam.actions.addLogParamBaseInfo': 'Adicionar parâmetro dinâmico',
  'oifde.logparam.actions.success': 'Sucesso',
  'oifde.logparam.actions.createSuccess': 'Criação bem-sucedida.',
  'oifde.logparam.actions.updateSuccess': 'Modificação bem-sucedida.',
  'oifde.logparam.actions.deleteSuccess': 'Exclusão bem-sucedida.',
  'oifde.logparam.actions.cancelDelete': 'A exclusão foi cancelada.',
  'oifde.logparam.actions.deletePrompt': 'Esta operação excluirá permanentemente o parâmetro dinâmico. Tem certeza de que deseja continuar?',
  'oifde.logparam.actions.prompt': 'Confirmar',
  'oifde.logparam.actions.confirm': 'Sim',
  'oifde.logparam.actions.cancel': 'Não',
  'oifde.logparam.actions.add': 'Adic.',
  'oifde.logparam.sourceDetail.IVRRequestInformation': 'Solicitação IVR',
  'oifde.logparam.sourceDetail.IVRResponseInformation': 'Resposta IVR',
  'oifde.logparam.sourceDetail.TOCRequestInformation': 'Solicitação TOC',
  'oifde.logparam.sourceDetail.TOCResponseInformation': 'Resposta TOC',
  'oifde.logparam.sourceDetail.InterfaceRequestInformation': 'Solicitação de interface',
  'oifde.logparam.sourceDetail.InterfaceResponseInformation': 'Resposta da interface',
  'oifde.logparam.transFlagDetail.YES': 'Sim',
  'oifde.logparam.transFlagDetail.NO': 'Não',
  'oifde.logparam.validate.logName': 'Insira um nome de parâmetro.',
  'oifde.logparam.validate.source': 'Selecione uma origem do parâmetro.',
  'oifde.logparam.validate.sourceName': 'Insira um nome de origem do parâmetro.',
  'oifde.logparam.validate.sourceValue': 'Digite um valor de origem.',
  'oifde.logparam.validate.targetValue': 'Digite um valor de destino.',
  'oifde.logparam.validate.doublelogNameCheck': 'O nome do parâmetro dinâmico já existe.',
  'oifde.logparam.validate.logNameLength': 'O nome do parâmetro dinâmico pode conter até 64 caracteres.',
  'oifde.logparam.validate.sourceNameLength': 'O nome de origem do parâmetro pode conter até 64 caracteres.',
  'oifde.logparam.validate.sourceValueLength': 'Longitud máxima:64',
  'oifde.logparam.validate.targetValueLength': 'Longitud máxima:64',
  'oifde.logparam.message.logParamNotNull': 'Selecione se deseja converter o valor.',
  'oifde.logparam.message.validateFailed': 'Falha na validação.',
  'oifde.logparam.message.validateResult': 'Informação',
  'oifde.tenantinfo.tenanInfoMgr': 'Parâmetros estáticos',
  'oifde.tenantinfo.serviceName': 'Nome',
  'oifde.tenantinfo.serviceValue': 'Valor',
  'oifde.tenantinfo.actions.edit': 'Editar',
  'oifde.tenantinfo.actions.editTenantBaseInfo': 'Editar parâmetro estático',
  'oifde.tenantinfo.actions.success': 'Sucesso',
  'oifde.tenantinfo.actions.updateSuccess': 'Modificação bem-sucedida.',
  'oifde.tenantinfo.validate.serviceValue': 'Insira um valor.',
  'oifde.tenantinfo.validate.serviceValueLength': 'O valor pode conter no máximo 64 caracteres.',
  'oifde.variable.innerTitle': 'Variável integrada',
  'oifde.variable.customTitle': 'Variável personalizada',
  'oifde.variable.variableName': 'Nome da variável',
  'oifde.variable.variableDesc': 'Descrição',
  'oifde.variable.variableType': 'Tipo de dado',
  'oifde.variable.scope': 'Escopo',
  'oifde.variable.defaultValue': 'Valor padrão',
  'oifde.variable.encryptType': 'Tipo de criptografia',
  'oifde.variable.timezoneDesc': 'Use essa variável para definir o deslocamento do fuso horário no qual o locatário reside. O valor varia de –12 a +12.',
  'oifde.variable.channeltypeDesc': 'Use essa variável para definir o canal do locatário.',
  'oifde.variable.responsestyleDesc': 'Use essa variável para definir o estilo do locatário.',
  'oifde.variable.langDesc': 'Use essa variável para definir o idioma do locatário.',
  'oifde.variable.variableTypeDetail.int': 'Inteiro',
  'oifde.variable.variableTypeDetail.string': 'Caractere',
  'oifde.variable.variableTypeDetail.long': 'Inteiro longo',
  'oifde.variable.variableTypeDetail.float': 'Número de ponto flutuante',
  'oifde.variable.variableTypeDetail.list': 'Lista',
  'oifde.variable.variableTypeDetail.object': 'Objeto',
  'oifde.variable.scopes.input': 'Parâmetro de entrada',
  'oifde.variable.scopes.output': 'Parâmetro de saída',
  'oifde.variable.scopes.local': 'Local',
  'oifde.variable.actions.edit': 'Editar',
  'oifde.variable.actions.delete': 'Excluir',
  'oifde.variable.actions.editVariableBaseInfo': 'Editar variável',
  'oifde.variable.actions.addVariableBaseInfo': 'Adicionar variável',
  'oifde.variable.actions.success': 'Sucesso',
  'oifde.variable.actions.createSuccess': 'Criação bem-sucedida',
  'oifde.variable.actions.updateSuccess': 'Modificação bem-sucedida',
  'oifde.variable.actions.deleteSuccess': 'Exclusão bem-sucedida',
  'oifde.variable.actions.cancelDelete': 'A exclusão foi cancelada',
  'oifde.variable.actions.deletePrompt': 'Esta operação excluirá permanentemente a variável Tem certeza de que deseja continuar?',
  'oifde.variable.actions.prompt': 'Confirmar',
  'oifde.variable.actions.confirm': 'Sim',
  'oifde.variable.actions.cancel': 'Não',
  'oifde.variable.validate.variableName': 'Insira um nome de variável.',
  'oifde.variable.validate.variableNameRange': 'É permitido usar somente letras, dígitos e sublinhados (_).',
  'oifde.variable.validate.variableType': 'Selecionar um tipo de variável.',
  'oifde.variable.validate.scope': 'Selecione o escopo.',
  'oifde.variable.validate.doubleVariableNameCheck': 'O nome da variável já existe.',
  'oifde.variable.validate.intValueCheck': 'O valor padrão para dados inteiros está incorreto. Ele varia entre -2147483648 e +2147483647.',
  'oifde.variable.validate.longValueCheck': 'O valor padrão para dados inteiros longos é inválido. Ele varia entre -9223372036854775808 e +9223372036854775807.',
  'oifde.variable.validate.floatValueCheck': 'O valor padrão para dados de ponto flutuante está em um formato incorreto. O valor deve estar no formato 100 ou 99.99',
  'oifde.variable.encryptFlag.zero': 'Sem criptografia',
  'oifde.variable.encryptFlag.one': 'Criptografado para armazenamento e descriptografado na recuperação',
  'oifde.variable.encryptFlag.two': 'Criptografado para armazenamento e recuperação',
  'oifde.diagram.varType': 'Tipo de variável',
  'oifde.diagram.usedVar': 'Variável usada',
  'oifde.diagram.usedCell': 'Elemento do diagrama',
  'oifde.diagram.fastGetCell': 'Localizar elemento de diagrama',
  'oifde.diagram.fastByVar': 'Localizar variável',
  'oifde.diagram.aimCell': 'Elemento de diagrama de destino',
  'oifde.diagram.saveAndPublish': 'Salvar e publicar',
  'oifde.diagram.NMS_PleaseSelect': '-Selec.-',
  'oifde.diagram.NMS_DiagramProperties': 'Atributo de fluxo',
  'oifde.diagram.NMS_BasicProperties': 'Atributo Básico',
  'oifde.diagram.NMS_DiagramCode': 'Código do fluxo',
  'oifde.diagram.NMS_DiagramName': 'Nome do fluxo',
  'oifde.diagram.NMS_ScenarioType': 'Tipo de cenário',
  'oifde.diagram.NMS_Desciption': 'Descrição',
  'oifde.diagram.NMS_NodeCode': 'Código do nó',
  'oifde.diagram.NMS_NodeName': 'Nome do nó',
  'oifde.diagram.NMS_AdvanceProperties': 'Atributo avançado',
  'oifde.diagram.NMS_ServiceName': 'Nome do serviço',
  'oifde.diagram.NMS_ServiceParams': 'Parâmetro de serviço',
  'oifde.diagram.NMS_ServiceDesciption': 'Descrição do serviço',
  'oifde.diagram.add': 'Adic.',
  'oifde.diagram.remove': 'Excluir',
  'oifde.diagram.NMS_BranchProperties': 'Atributo da ramificação',
  'oifde.diagram.NMS_BranchCondition': 'Condição da ramificação',
  'oifde.diagram.NMS_BranchService': 'Ramificação de serviço',
  'oifde.diagram.NMS_NodeProperties': 'Atributo do nó',
  'oifde.diagram.NMS_FlowVariable': 'Variável de fluxo',
  'oifde.diagram.NMS_FlowVariableName': 'Nome da variável',
  'oifde.diagram.NMS_FlowVariableDesc': 'Descrição',
  'oifde.diagram.NMS_FlowVariableType': 'Tipo de dado',
  'oifde.diagram.NMS_FlowVariableDefaultValue': 'Valor padrão',
  'oifde.diagram.NMS_FlowVariableEncryptType': 'Tipo de criptografia',
  'oifde.diagram.NMS_FlowVariableEncryptTypeWarn': '* Observe que essa variável é armazenada em texto sem formatação quando o tipo de criptografia não é criptografado. Não atribua dados sensíveis a esta variável.',
  'oifde.diagram.NMS_FlowVariableEncryptTypeTips': '* Observe que , quando o tipo de criptografia é criptografado , é recomendável que você atribua um valor usando uma variável para evitar a divulgação de informações.',
  'oifde.diagram.NMS_FlowVariableOption': 'Operação',
  'oifde.diagram.NMS_AddVariable': 'Adicionar variável',
  'oifde.diagram.NMS_ModifyVariable': 'Modificar variável',
  'oifde.diagram.variableTypeDetail.int': 'Inteiro',
  'oifde.diagram.variableTypeDetail.string': 'Caractere',
  'oifde.diagram.variableTypeDetail.long': 'Inteiro longo',
  'oifde.diagram.variableTypeDetail.float': 'Número de ponto flutuante',
  'oifde.diagram.variableTypeDetail.list': 'Tabela',
  'oifde.diagram.variableTypeDetail.object': 'Objeto',
  'oifde.diagram.error.NMS_flowCodeError': 'O código de fluxo não pode estar em branco ou exceder 64 caracteres.',
  'oifde.diagram.error.NMS_flowNameError': 'O nome do fluxo não pode estar em branco ou exceder 1000 caracteres.',
  'oifde.diagram.error.NMS_scenarioTypeError': 'O tipo de cenário deve ser informado.',
  'oifde.diagram.error.NMS_flowDescError': 'A descrição do fluxo pode conter até 1000 caracteres.',
  'oifde.diagram.error.NMS_nmsFlowError': 'Falha ao analisar o fluxo porque o nó nmsFlow não foi encontrado.',
  'oifde.diagram.error.NMS_rootError': 'Falha ao analisar o fluxo porque o nó raiz não pode ser encontrado.',
  'oifde.diagram.error.NMS_VariableNameError': 'O nome da variável pode conter até 1000 caracteres.',
  'oifde.diagram.error.NMS_VariableDescError': 'A descrição da variável pode conter até 1000 caracteres.',
  'oifde.diagram.error.NMS_VariableDefaultValueError': 'O valor padrão da variável pode ter no máximo 1024 caracteres.',
  'oifde.diagram.error.NMS_NodeCodeError': 'O código do nó não pode estar em branco ou exceder 64 caracteres.',
  'oifde.diagram.error.NMS_NodeNameError': 'O nome do nó não pode estar em branco ou exceder 1000 caracteres.',
  'oifde.diagram.error.NMS_NodeDescError': 'A descrição do nó pode conter até 1000 caracteres.',
  'oifde.diagram.error.NMS_actionParamNameError': 'O nome do parâmetro pode conter até {0} caracteres.',
  'oifde.diagram.error.NMS_actionParamValueError': 'O valor do parâmetro pode conter até 4096 caracteres.',
  'oifde.diagram.error.NMS_actionParamDescError': 'A descrição do parâmetro pode conter até 500 caracteres.',
  'oifde.diagram.error.NMS_transitToStartNodeError': 'Falha ao pular para o nó inicial.',
  'oifde.diagram.error.NMS_endNodetransitToError': 'Falha ao pular do nó final para outro nó.',
  'oifde.diagram.error.NMS_startNodeNotFind': 'O nó inicial não foi encontrado.',
  'oifde.diagram.error.NMS_startNodeNotUnique': 'O nó inicial não é único.',
  'oifde.diagram.error.NMS_startNodeNotBranchError': 'O nó inicial pode ter somente uma ramificação.',
  'oifde.diagram.error.NMS_endNodeNotFind': 'O nó final não foi encontrado.',
  'oifde.diagram.error.NMS_analiseXmlFailed': 'Falha ao analisar a tela do fluxo.',
  'oifde.diagram.error.NMS_branchError': 'A ramificação deve ter um nó inicial e um nó final.',
  'oifde.diagram.error.NMS_nullBranchNotNuique': 'Um nó pode ter apenas uma ramificação padrão.',
  'oifde.diagram.error.NMS_checkDefaultNextNodeForView': 'O elemento do diagrama do tipo de visualização não pode ter mais de uma ramificação.',
  'oifde.diagram.error.NMS_actionParamValueEmptyError': 'O valor de parâmetro não pode ficar em branco.',
  'oifde.diagram.complexCell.name': 'Nome do elemento de diagrama',
  'oifde.diagram.complexCell.variable': 'Variável de elemento de diagrama',
  'oifde.diagram.speechRecognition.recognitionParam': 'Parâmetros de Reconhecimento',
  'oifde.diagram.speechRecognition.resultParam': 'Processamento de resultados',
  'oifde.navbar.tenantId': 'ID de locatário atual',
  'oifde.navbar.current': 'Login atual',
  'oifde.navbar.success': 'Último login bem-sucedido',
  'oifde.navbar.failed': 'Último login com falha',
  'oifde.navbar.logOut': 'Sair',
  'oifde.navbar.switchUser': 'Trocar usuário',
  'oifde.navbar.screenfull': 'Tela cheia',
  'oifde.navbar.menuOpen': 'Mostrar menu',
  'oifde.navbar.menuClose': 'Ocultar menu',
  'oifde.navbar.changePwd': 'Alterar a senha',
  'oifde.navbar.required': 'Campo obrig.',
  'oifde.navbar.action.success': 'Sucesso',
  'oifde.navbar.action.successMessage': 'O usuário foi alterado com êxito.',
  'oifde.navbar.action.changePasswordSuccess': 'A senha foi alterada com sucesso.',
  'oifde.navbar.password.logOut': 'Sair',
  'oifde.navbar.password.oldPassword': 'Senha antiga',
  'oifde.navbar.password.newPassword': 'Nova senha',
  'oifde.navbar.password.confirmNewPassword': 'Confirmar senha',
  'oifde.navbar.password.passwordExpired': 'Sua senha expirou. Altere-a.',
  'oifde.navbar.password.firstTimeLogin': 'Você está usando a senha inicial. Altere-a.',
  'oifde.navbar.password.passwordWillExpire': 'Sua senha está prestes a expirar. Altere-a.',
  'oifde.navbar.password.passwordNotSame': 'As senhas não são iguais.',
  'oifde.journeylogmgr.flowAnalysisMrg': 'Configurações de análise de processo',
  'oifde.journeylogmgr.accessCodeSetting': 'Configurações de código de acesso',
  'oifde.trackmgr.track': 'Configurações de rastreamento',
  'oifde.trackmgr.accessCodeTrack': 'Código acesso de rastreamento',
  'oifde.trackmgr.callingNumberTrack': 'Selec. chamada de rastreamento',
  'oifde.trackmgr.callingNumber': 'Número chamador',
  'oifde.trackmgr.accessCode': 'Código de acesso',
  'oifde.trackmgr.choose': '-Selec.-',
  'oifde.trackmgr.validate.accessCode': 'Selecione o código de acesso.',
  'oifde.trackmgr.validate.callingNumber': 'Insira um número de chamada.',
  'oifde.trackmgr.validate.needNumber': 'Insira um número.',
  'oifde.trackmgr.validate.callingNumberLength': 'O número de chamada pode conter até 64 caracteres.',
  'oifde.trackmgr.validate.callingNumberDub': 'O número de chamada deve ser único.',
  'oifde.trackmgr.validate.pattern': 'Use somente letras, dígitos, espaços e os seguintes caracteres especiais: . _ - \' : {\'@\'} /',
  'oifde.trackmgr.actions.delete': 'Excluir',
  'oifde.trackmgr.actions.success': 'Sucesso',
  'oifde.trackmgr.actions.updateSuccess': 'Modificação bem-sucedida.',
  'oifde.trackmgr.actions.add': 'Adic.',
  'oifde.trackmgr.actions.deleteSuccess': 'Exclusão bem-sucedida.',
  'oifde.trackmgr.actions.cancelDelete': 'A exclusão foi cancelada.',
  'oifde.trackmgr.actions.cancelUpdate': 'Modificação cancelada',
  'oifde.trackmgr.actions.deletePrompt': 'Esta operação excluirá permanentemente os dados de rastreamento. Tem certeza de que deseja continuar?',
  'oifde.trackmgr.actions.updatePrompt': 'Esta operação afetará o desempenho do sistema. Esta configuração será automaticamente excluída em {0} horas. Continuar?',
  'oifde.trackmgr.actions.prompt': 'Confirmar',
  'oifde.trackmgr.actions.confirm': 'Sim',
  'oifde.trackmgr.actions.cancel': 'Não',
  'oifde.trace.traceReason': 'Causa do erro',
  'oifde.trace.traceAdvice': 'Solução',
  'oifde.trace.traceLog': 'Consulta rastr. registro',
  'oifde.trace.beginTime': 'Hr início',
  'oifde.trace.endTime': 'Hr término',
  'oifde.trace.callingNumber': 'Número chamador',
  'oifde.trace.calledNumber': 'Número chamado',
  'oifde.trace.createTime': 'Criado',
  'oifde.trace.flowId': 'ID de fluxo',
  'oifde.trace.flowName': 'Nome do fluxo',
  'oifde.trace.logInfo': 'Informações de registro',
  'oifde.trace.errorInfo': 'Informações de erro',
  'oifde.trace.callId': 'ID instância da chamada',
  'oifde.trace.nodeId': 'ID do nó',
  'oifde.trace.nodeName': 'Nome do nó',
  'oifde.trace.actionContent': 'Nome da instrução',
  'oifde.trace.nodeIndex': 'Número do nó de execução',
  'oifde.trace.actionParams': 'Informações de parâmetro do nó de execução',
  'oifde.trace.processResult': 'Resultado da execução',
  'oifde.trace.transitionOn': 'Ramificação do result. da execução',
  'oifde.trace.reset': 'Redefinir',
  'oifde.trace.validate.callingNumber': 'Insira um número de chamada.',
  'oifde.trace.validate.callingNumberLength': 'O número de chamada pode conter até 64 caracteres.',
  'oifde.trace.clickToDetail': 'Clique para mostrar mais.',
  'oifde.trace.clickToHideDetail': 'Clique para mostrar menos.',
  'oifde.trace.currentNodeInfo': 'Informações do nó atual',
  'oifde.trace.clickToFlow': 'Exibir trilha',
  'oifde.trace.nodeTrace': 'Info. registro do nó',
  'oifde.trace.nodeParams': 'Parâmetros de entrada e saída do nó',
  'oifde.expression.epxr_input_head': 'Comprimento máximo:',
  'oifde.expression.expr_type_illegal': 'O formato da expressão é inválido.',
  'oifde.expression.expr_key_repeats': 'Nome de condição duplicado',
  'oifde.expression.expr_var': 'Insira uma variável. Inicie o valor com GLOBAL (para uma variável global), FLOW (para uma variável de fluxo), SYS (para uma variável de sistema) ou TOC (para uma variável TUC).',
  'oifde.expression.expr_var_fag': 'Insira uma variável. Inicie o valor com GLOBAL (para uma variável global), FLOW (para uma variável de fluxo).',
  'oifde.expression.expr_var_paramName': 'Insira uma variável. Inicie o valor com GLOBAL (para uma variável global) ou FLUXO (para uma variável de fluxo).',
  'oifde.expression.expr_var_string': 'Insira uma variável do tipo de sequência de caracteres. Inicie o valor com GLOBAL (para uma variável global), FLOW (para uma variável de fluxo), SYS (para uma variável de sistema) ou TOC (para uma variável TUC).',
  'oifde.expression.expr_number': 'Insira um valor do tipo numérico. Os operadores disponíveis entre os valores são +, –, *, and /.',
  'oifde.expression.expr_number_noAction': 'Insira um valor do tipo numérico.',
  'oifde.expression.expr_var_list': 'Insira uma variável de lista.',
  'oifde.expression.expr_var_number': 'Insira uma variável int.',
  'oifde.expression.allVar': 'Inicie o valor com GLOBAL (para uma variável global), FLOW (para uma variável de fluxo), SYS (para uma variável de sistema) ou TOC (para uma variável TUC).',
  'oifde.expression.flowAndGlobalVar': 'Inicie o valor com GLOBAL (para uma variável global), FLOW (para uma variável de fluxo).',
  'oifde.expression.expr_string': 'Insira um valor do tipo de sequência de caracteres. Coloque o valor entre aspas simples ou duplas (\' ou "). O operador de sequência de caracteres é o sinal de adição (+).',
  'oifde.expression.expr_simple_string': 'Insira uma variável de sequência de caracteres ou qualquer sequência de caracteres. Não coloque a sequência de caracteres entre aspas simples ou duplas (\' ou ").',
  'oifde.expression.expr_boolean': 'Insira um valor do tipo Booleano. Os operadores de comparação disponíveis entre valores numéricos ou sequência de caracteres são >, <, >=, <= e !=. Os operadores booleanos são &&, ||, ==, and !=. O método de sequência de caracteres suporta startsCom.',
  'oifde.expression.varFlow': 'Variável de fluxo',
  'oifde.expression.varGlobal': 'Variável global',
  'oifde.expression.varToc': 'Variável de intenção',
  'oifde.expression.varSys': 'Variável de sistema',
  'oifde.expression.varIvrRequest': 'Variável de solicitação de IVR',
  'oifde.procedure.procedureMgr': 'Procedimentos armazenados',
  'oifde.procedure.procedureDBMgr': 'Bancos de dados do proced. armaz.',
  'oifde.procedure.procedureName': 'Procedimento armazenado',
  'oifde.procedure.procedureDesc': 'Descrição',
  'oifde.procedure.procedureValue': 'Valor',
  'oifde.procedure.operation': 'Operação',
  'oifde.procedure.baseInfo': 'Inform. básicas',
  'oifde.procedure.in_param': 'Parâmetro de entrada',
  'oifde.procedure.paramName': 'Parâmetro',
  'oifde.procedure.paramDesc': 'Descrição',
  'oifde.procedure.dataType': 'Tipo de dado',
  'oifde.procedure.out_param': 'Parâmetro de saída',
  'oifde.procedure.addProcedureInterface': 'Adicionar procedimento armazenado',
  'oifde.procedure.modifyProcedureInterface': 'Modificar procedimento armazenado',
  'oifde.procedure.addProcedureDB': 'Adic Bancos de dados do proced. armaz.',
  'oifde.procedure.modifyProcedureDB': 'Editar Bancos de dados do proced. armaz.',
  'oifde.procedure.required': 'Campo obrig.',
  'oifde.procedure.ruleMysql': 'O banco de dados MySQL deve iniciar com jdbc:mysql://.',
  'oifde.procedure.ruleGauss': 'O GaussDB deve começar com jdbc:zenith:{\'@\'}.',
  'oifde.procedure.ruleOracle': 'O nome do banco de dados Oracle deve começar com jdbc:oracle:thin:{\'@\'}.',
  'oifde.procedure.ruleRange': 'O URL pode conter somente letras, dígitos e os seguintes caracteres especiais: . ? = : - _ & /. Ele só pode terminar com uma letra, dígito ou barra (/).',
  'oifde.procedure.oracleRuleRange': 'O URL pode conter somente letras, dígitos e os seguintes caracteres especiais: . ? {\'@\'} = : - _ & /. Ele só pode terminar com uma letra, dígito ou barra (/).',
  'oifde.procedure.driverRule': 'O valor pode conter somente letras, dígitos, pontos (.) e hifens (-)',
  'oifde.procedure.driverStart': 'O valor não pode começar com um ponto (.) ou hífen (-).',
  'oifde.procedure.driverEnd': 'O valor não pode terminar com um ponto (.) ou hífen (-).',
  'oifde.procedure.ruleLength': 'Insira o endereço IP e o número da porta no URL.',
  'oifde.procedure.dbUrlWarning': '*Observe que o IP, a porta ou o nome de domínio no URL do banco de dados não estão na lista branca. Você precisa entrar em contato com o administrador do sistema para adicioná-lo',
  'oifde.procedure.actions.editList': 'Editar tipo de dados na lista',
  'oifde.procedure.actions.edit': 'Editar',
  'oifde.procedure.actions.delete': 'Excluir',
  'oifde.procedure.actions.success': 'Sucesso',
  'oifde.procedure.actions.updateSuccess': 'Criação bem-sucedida.',
  'oifde.procedure.actions.deleteSuccess': 'Modificação bem-sucedida.',
  'oifde.procedure.actions.createSuccess': 'Exclusão bem-sucedida.',
  'oifde.procedure.actions.cancelDelete': 'A exclusão foi cancelada.',
  'oifde.procedure.actions.deletePrompt': 'Esta operação excluirá permanentemente o procedimento armazenado. Tem certeza de que deseja continuar?',
  'oifde.procedure.actions.prompt': 'Confirmar',
  'oifde.procedure.actions.confirm': 'Sim',
  'oifde.procedure.actions.cancel': 'Não',
  'oifde.procedure.actions.ok': 'OK',
  'oifde.procedure.actions.miss_pro_name': 'O seguinte campo obrigatório está vazio: Informações básicas > Nome do procedimento armazenado.',
  'oifde.procedure.actions.miss_pro_value': 'O seguinte campo obrigatório está vazio: Informações básicas > Valor do procedimento armazenado.',
  'oifde.procedure.actions.miss_pro_in_parameter_name': 'O seguinte campo obrigatório está vazio: Parâmetro de entrada > Nome do parâmetro.',
  'oifde.procedure.actions.miss_pro_out_parameter_name': 'O seguinte campo obrigatório está vazio: Parâmetro de saída > Nome do parâmetro.',
  'oifde.procedure.actions.miss_pro_dbId': 'Falta um campo obrigatório: Conexão de banco de dados.',
  'oifde.procedure.dataTypes.INT': 'Inteiro',
  'oifde.procedure.dataTypes.STRING': 'Caractere',
  'oifde.procedure.dataTypes.FLOAT': 'Número de ponto flutuante',
  'oifde.procedure.dataTypes.LONG': 'Inteiro longo',
  'oifde.procedure.dataTypes.LIST': 'Lista',
  'oifde.procedure.db.dbType': 'Tipo banco de dados',
  'oifde.procedure.db.dbDriver': 'Unidade do banco de dados',
  'oifde.procedure.db.dbUrl': 'URL banco de dados',
  'oifde.procedure.db.dbUser': 'Usuário banco dados',
  'oifde.procedure.db.dbPassword': 'Senha do banco de dados',
  'oifde.procedure.dbTypes.MYSQL': 'MySQL',
  'oifde.procedure.dbTypes.GAUSS': 'GaussDB',
  'oifde.procedure.dbTypes.ORACLE': 'Oracle',
  'oifde.complexcell.cellTitle': 'Elemento de diagrama composto',
  'oifde.complexcell.catalogTitle': 'Catálogo de elementos de diagrama composto',
  'oifde.complexcell.catalog.name': 'Nome do catálogo',
  'oifde.complexcell.catalog.desc': 'Descrição do catálogo',
  'oifde.complexcell.catalog.tips.sizeLimit': 'São permitidos no máximo 100 catálogos.',
  'oifde.complexcell.cell.name': 'Nome da célula',
  'oifde.complexcell.cell.desc': 'Descrição',
  'oifde.complexcell.cell.height': 'Altura',
  'oifde.complexcell.cell.width': 'Largura',
  'oifde.complexcell.cell.icon': 'Símbolo',
  'oifde.complexcell.cell.catalog': 'Catálogo',
  'oifde.complexcell.cell.actions.editInfo': 'Modificar informações básicas',
  'oifde.complexcell.cell.actions.editDiagram': 'Editar quadro',
  'oifde.complexcell.cell.actions.copy': 'Replicar',
  'oifde.complexcell.cell.validation.bigFile': 'O arquivo não pode exceder {0}.',
  'oifde.complexcell.cell.viewReference':'Exibir referências',
  'oifde.complexcell.cell.actions.copySuccess':'Cópia bem-sucedida',
  'oifde.table.search': 'Pesquisar',
  'oifde.table.add': 'Adic.',
  'oifde.table.actions': 'Ações',
  'oifde.table.cancel': 'Cancelar',
  'oifde.table.confirm': 'Confirmar',
  'oifde.table.create': 'Criar',
  'oifde.table.delete': 'Excluir',
  'oifde.table.manage': 'Gerenciar',
  'oifde.table.save': 'Salvar',
  'oifde.table.reset': 'Redefinir',
  'oifde.table.filter': 'Filtrar',
  'oifde.tagsView.refresh': 'Atual.',
  'oifde.tagsView.close': 'Fech.',
  'oifde.tagsView.closeOthers': 'Fechar os outros',
  'oifde.tagsView.closeAll': 'Fechar tudo',
  'oifde.mxgraph.saveAndNopublish': 'O fluxo foi salvo com êxito, mas não foi publicado.',
  'oifde.mxgraph.publishSuccess': 'O fluxo foi salvo e publicado com êxito.',
  'oifde.mxgraph.publishFailed': 'Falha ao salvar e publicar o fluxo.',
  'oifde.mxgraph.saveAndPublish': 'Salvar e publicar',
  'oifde.mxgraph.failInfo': 'Falha ao consultar os dados.',
  'oifde.mxgraph.close': 'Fech.',
  'oifde.mxgraph.error': 'Erro',
  'oifde.mxgraph.errorSavingFile': 'Falha ao salvar o arquivo.',
  'oifde.mxgraph.center': 'Alinhamento vertical',
  'oifde.mxgraph.collapseExpand': 'Recolher/expandir',
  'oifde.mxgraph.custom': 'Personal.',
  'oifde.mxgraph.delete': 'Excluir',
  'oifde.mxgraph.drawingTooLarge': 'O diagrama é grande demais',
  'oifde.mxgraph.editDiagram': 'Origem',
  'oifde.mxgraph.fitPageWidth': 'Largura página',
  'oifde.mxgraph.formatPanel': 'Painel formato',
  'oifde.mxgraph.invalidOrMissingFile': 'O arquivo está vazio ou é inválido.',
  'oifde.mxgraph.image': 'Imagem',
  'oifde.mxgraph.images': 'Imagens',
  'oifde.mxgraph.middle': 'Alinhamento horizontal',
  'oifde.mxgraph.outline': 'Contorno',
  'oifde.mxgraph.redo': 'Refazer',
  'oifde.mxgraph.resetView': 'Redef. exibição',
  'oifde.mxgraph.save': 'Salvar',
  'oifde.mxgraph.sidebarTooltip': 'Clique para expandir ou recolher o painel de nós.',
  'oifde.mxgraph.text': 'Texto',
  'oifde.mxgraph.undo': 'Desf.',
  'oifde.mxgraph.view': 'Exibir',
  'oifde.mxgraph.zoom': 'Zoom',
  'oifde.mxgraph.zoomIn': 'Aumentar zoom',
  'oifde.mxgraph.zoomOut': 'Diminuir zoom',
  'oifde.mxgraph.success': 'Sucesso',
  'oifde.mxgraph.NMS_NodeName': 'Nome do nó',
  'oifde.mxgraph.NMS_FlowSaveSuccess': 'O fluxo foi salvo com êxito.',
  'oifde.mxgraph.NMS_FlowSaveFail': 'Falha ao salvar o fluxo.',
  'oifde.mxgraph.NMS_FlowOpenFail': 'Falha ao abrir o fluxo.',
  'oifde.mxgraph.NMS_ActionParamsGetFail': 'Falha ao obter parâmetros dos elementos do diagrama.',
  'oifde.mxgraph.NMS_flow': 'Fluxo',
  'oifde.mxgraph.NMS_flowCodeError': 'O código de fluxo não pode estar em branco ou exceder 64 caracteres.',
  'oifde.mxgraph.NMS_flowNameError': 'O nome do fluxo não pode estar em branco ou exceder 1000 caracteres.',
  'oifde.mxgraph.NMS_flowDescError': 'A descrição do fluxo pode conter até 1000 caracteres.',
  'oifde.mxgraph.NMS_NodeCodeError': 'O código do nó não pode estar em branco ou exceder 64 caracteres.',
  'oifde.mxgraph.NMS_NodeNameError': 'O nome do nó não pode estar em branco ou exceder 1000 caracteres.',
  'oifde.mxgraph.NMS_NodeDescError': 'A descrição do nó pode conter até 1000 caracteres.',
  'oifde.mxgraph.NMS_actionParamNameError': 'O nome do parâmetro pode conter até 64 caracteres.',
  'oifde.mxgraph.NMS_actionParamValueEmptyError': 'O valor não pode ficar vazio.',
  'oifde.mxgraph.NMS_actionParamValueError': 'O valor do parâmetro pode conter até 4096 caracteres.',
  'oifde.mxgraph.NMS_startNodeNotFind': 'O nó inicial não foi encontrado.',
  'oifde.mxgraph.NMS_startNodeNotUnique': 'O nó inicial não é único.',
  'oifde.mxgraph.NMS_startNodeNotBranchError': 'O nó inicial pode ter somente uma ramificação.',
  'oifde.mxgraph.NMS_branchError': 'A ramificação deve ter um nó inicial e um nó final.',
  'oifde.mxgraph.NMS_nullBranchNotNuique': 'Um nó pode ter apenas uma ramificação padrão.',
  'oifde.mxgraph.NMS_nodeRefError': 'O nó é referenciado por outro fluxo e não pode ser excluído.',
  'oifde.mxgraph.NMS_ValidateError': 'Falha na validação! Verifique as peças marcadas em vermelho.',
  'oifde.mxgraph.NMS_SpaceValidate': 'O valor não pode começar com um espaço.',
  'oifde.mxgraph.NMS_KeyRepeats': 'Nome de condição duplicado',
  'oifde.mxgraph.NMS_encryptValidate': 'A variável criptografada IVRREQUEST, a variável FLOW e a variável GLOBAL podem ser atribuídas apenas pelas variáveis criptografadas FLOW e GLOBAL.',
  'oifde.mxgraph.NMS_timeSelectLengthValidate': 'Pelo menos um registro deve ser configurado para o período de serviço.',
  'oifde.mxgraph.NMS_ServiceName': 'Nome do serviço',
  'oifde.mxgraph.NMS_ServiceParams': 'Parâmetros de serviço',
  'oifde.mxgraph.NMS_ServiceDesciption': 'Descrição do serviço',
  'oifde.knowledgegraph.actions.edit': 'Editar',
  'oifde.knowledgegraph.actions.view': 'Exibir',
  'oifde.knowledgegraph.actions.delete': 'Excluir',
  'oifde.knowledgegraph.actions.prompt': 'Informação',
  'oifde.knowledgegraph.actions.confirm': 'OK',
  'oifde.knowledgegraph.actions.nameRequiredTip': 'Insira um nome.',
  'oifde.knowledgegraph.actions.selectTip': '-Selec.-',
  'oifde.knowledgegraph.actions.cancel': 'Cancelar',
  'oifde.knowledgegraph.actions.cancelDelete': 'A exclusão foi cancelada.',
  'oifde.knowledgegraph.actions.success': 'Sucesso',
  'oifde.knowledgegraph.actions.updateSuccess': 'Modificação bem-sucedida.',
  'oifde.knowledgegraph.actions.deleteSuccess': 'Exclusão bem-sucedida.',
  'oifde.knowledgegraph.actions.createSuccess': 'Adição bem-sucedida.',
  'oifde.knowledgegraph.actions.createFailed': 'Falha ao adicionar.',
  'oifde.knowledgegraph.actions.modifyFailed': 'Falha na modificação.',
  'oifde.knowledgegraph.actions.deleteFailed': 'Falha na exclusão.',
  'oifde.knowledgegraph.actions.saveSuccess': 'Salvo com êxito.',
  'oifde.knowledgegraph.actions.saveFailed': 'Falha ao salvar.',
  'oifde.knowledgegraph.actions.addSchema': 'Adic. esquema',
  'oifde.knowledgegraph.actions.editSchema': 'Editar esquema',
  'oifde.knowledgegraph.actions.addDic': 'Adicionar dicionário gráfico',
  'oifde.knowledgegraph.actions.editDic': 'Editar dicionário gráfico',
  'oifde.knowledgegraph.actions.addEntity': 'Adic. entidade',
  'oifde.knowledgegraph.actions.editEntity': 'Editar entidade',
  'oifde.knowledgegraph.actions.addRelation': 'Adicionar relacionamento',
  'oifde.knowledgegraph.actions.editRelation': 'Editar relacionamento',
  'oifde.knowledgegraph.actions.addAttribute': 'Adicionar atributo',
  'oifde.knowledgegraph.actions.editAttribute': 'Editar atributo',
  'oifde.knowledgegraph.actions.addSynonyms': 'Adicionar sinônimo',
  'oifde.knowledgegraph.actions.editSynonyms': 'Editar sinônimo',
  'oifde.knowledgegraph.actions.viewSchema': 'Exibir esquema',
  'oifde.knowledgegraph.actions.graphicPreview': 'Exibir gráfico',
  'oifde.knowledgegraph.actions.addSchemaEntity': 'Adicionar conceito',
  'oifde.knowledgegraph.actions.editSchemaEntity': 'Editar conceito',
  'oifde.knowledgegraph.model.schemaName': 'Nome do esquema',
  'oifde.knowledgegraph.model.schemaDescription': 'Descrição',
  'oifde.knowledgegraph.model.modifyTime': 'Modificado',
  'oifde.knowledgegraph.model.createUser': 'Criado por',
  'oifde.knowledgegraph.model.beforeDeletePrompt': 'O esquema não pode ser excluído porque está associado ao seguinte dicionário gráfico:',
  'oifde.knowledgegraph.model.beforeEditPrompt': 'O esquema atual não pode ser modificado porque está associado ao seguinte dicionário gráfico:',
  'oifde.knowledgegraph.model.deletePrompt': 'Esta operação excluirá permanentemente o esquema e seus conceitos, relacionamentos e atributos relacionados. Tem certeza de que deseja continuar?',
  'oifde.knowledgegraph.editSchema.entity': 'Conceito',
  'oifde.knowledgegraph.editSchema.entityName': 'Nome do conceito',
  'oifde.knowledgegraph.editSchema.relation': 'Relacionamento',
  'oifde.knowledgegraph.editSchema.relationName': 'Nome do relacionamento',
  'oifde.knowledgegraph.editSchema.headEntity': 'Conceito principal',
  'oifde.knowledgegraph.editSchema.tailEntity': 'Conceito final',
  'oifde.knowledgegraph.editSchema.attributeName': 'Nome do atributo',
  'oifde.knowledgegraph.editSchema.attributeType': 'Tipo de atributo',
  'oifde.knowledgegraph.editSchema.unit': 'Unidade',
  'oifde.knowledgegraph.editSchema.type': 'Tipo',
  'oifde.knowledgegraph.editSchema.schemaNoDataTip': 'Adicione dados de conceito e relacionamento no painel esquerdo. Para exibir o conteúdo do atributo, clique em Exibir à direita do conceito ou relacionamento correspondente.',
  'oifde.knowledgegraph.editSchema.deleteEntityPrompt': 'Esta operação excluirá permanentemente o conceito e seus atributos. Tem certeza de que deseja continuar?',
  'oifde.knowledgegraph.editSchema.deleteRelationPrompt': 'Esta operação excluirá permanentemente o relacionamento e seus atributos. Tem certeza de que deseja continuar?',
  'oifde.knowledgegraph.editSchema.deleteAttributePrompt': 'Esta operação excluirá permanentemente o atributo. Tem certeza de que deseja continuar com esta operação?',
  'oifde.knowledgegraph.editSchema.dataType.int': 'Inteiro',
  'oifde.knowledgegraph.editSchema.dataType.float': 'Número de ponto flutuante',
  'oifde.knowledgegraph.editSchema.dataType.boolean': 'Booleano',
  'oifde.knowledgegraph.editSchema.dataType.string': 'Caractere',
  'oifde.knowledgegraph.editSchema.dataType.date': 'Data',
  'oifde.knowledgegraph.dicmgr.dicName': 'Nome do gráfico',
  'oifde.knowledgegraph.dicmgr.dicFunction': 'Uso do gráfico',
  'oifde.knowledgegraph.dicmgr.modifyTime': 'Modificado',
  'oifde.knowledgegraph.dicmgr.schemaName': 'Esquema associado',
  'oifde.knowledgegraph.dicmgr.kgState': 'Status',
  'oifde.knowledgegraph.dicmgr.moreOperation': 'Mais',
  'oifde.knowledgegraph.dicmgr.entityMgr': 'Gerenciar entidade',
  'oifde.knowledgegraph.dicmgr.relationMgr': 'Gerenciamento de relacionamento',
  'oifde.knowledgegraph.dicmgr.kgView': 'Visualização de gráficos',
  'oifde.knowledgegraph.dicmgr.kgFAQ': 'FAQ sobre gráficos',
  'oifde.knowledgegraph.dicmgr.active': 'Ativar',
  'oifde.knowledgegraph.dicmgr.inactive': 'Inativo',
  'oifde.knowledgegraph.dicmgr.creating': 'Criando',
  'oifde.knowledgegraph.dicmgr.trainAll': 'Treinar todos',
  'oifde.knowledgegraph.dicmgr.trainSuccess': 'O treinamento foi bem-sucedido.',
  'oifde.knowledgegraph.dicmgr.trainFail': 'Falha no treinamento.',
  'oifde.knowledgegraph.dicmgr.changeState': 'Modificar status',
  'oifde.knowledgegraph.dicmgr.synonyms': 'Sinônimo',
  'oifde.knowledgegraph.dicmgr.deletePrompt': 'Esta operação excluirá permanentemente o dicionário e suas entidades, relacionamentos e atributos relacionados. Tem certeza de que deseja continuar?',
  'oifde.knowledgegraph.entitymgr.entityName': 'Nome da entidade',
  'oifde.knowledgegraph.entitymgr.schemaEntityName': 'Conceito de esquema associado',
  'oifde.knowledgegraph.entitymgr.deletePrompt': 'Esta operação excluirá permanentemente a entidade. Tem certeza de que deseja continuar?',
  'oifde.knowledgegraph.entitymgr.entityAttributeMgr.deletePrompt': 'Esta operação excluirá permanentemente o atributo da entidade. Tem certeza de que deseja continuar?',
  'oifde.knowledgegraph.relationmgr.headEntity': 'Entidade principal',
  'oifde.knowledgegraph.relationmgr.tailEntity': 'Entidade final',
  'oifde.knowledgegraph.relationmgr.relation': 'Relacionamento',
  'oifde.knowledgegraph.relationmgr.doubleRelation': 'Relacionamento bidirecional',
  'oifde.knowledgegraph.relationmgr.doubleRelationTrue': 'Sim',
  'oifde.knowledgegraph.relationmgr.doubleRelationFalse': 'Não',
  'oifde.knowledgegraph.relationmgr.doublePrompt': 'O relacionamento não pode ser estabelecido porque as entidades principal e final são as mesmas. Selecione outra entidade principal ou final.',
  'oifde.knowledgegraph.relationmgr.deletePrompt': 'Esta operação excluirá permanentemente o relacionamento e seus atributos relacionados. Tem certeza de que deseja continuar?',
  'oifde.knowledgegraph.attributemgr.attributeName': 'Nome do atributo',
  'oifde.knowledgegraph.attributemgr.attribute': 'Valor do Atributo',
  'oifde.knowledgegraph.attributemgr.deletePrompt': 'Esta operação excluirá permanentemente o atributo. Tem certeza de que deseja continuar?',
  'oifde.knowledgegraph.kgFAQ.title': 'FAQ sobre gráficos',
  'oifde.knowledgegraph.kgFAQ.name': 'Dicionário gráfico:',
  'oifde.knowledgegraph.kgFAQ.welcomeContent': 'Olá! Como posso ajudar você hoje?',
  'oifde.knowledgegraph.kgSynonyms.keyword': 'Nome',
  'oifde.knowledgegraph.kgSynonyms.synonyms': 'Sinônimo',
  'oifde.knowledgegraph.kgSynonyms.number': 'Nº.',
  'oifde.knowledgegraph.kgSynonyms.synonymsDetail': 'Detalhes do sinônimo',
  'oifde.knowledgegraph.kgSynonyms.deletePrompt': 'Esta operação excluirá permanentemente a entrada e todos os seus sinônimos. Tem certeza de que deseja continuar?',
  'oifde.knowledgegraph.kgSynonyms.type.type': 'Tipo',
  'oifde.knowledgegraph.kgSynonyms.type.entity': 'Entidade',
  'oifde.knowledgegraph.kgSynonyms.type.relation': 'Relacionamento',
  'oifde.knowledgegraph.kgSynonyms.type.property': 'Atributo',
  'oifde.knowledgegraph.kgView.queryType': 'Tipo consulta',
  'oifde.knowledgegraph.kgView.entityName': 'Nome da entidade',
  'oifde.knowledgegraph.kgView.displayType': 'Modo Exibição',
  'oifde.knowledgegraph.kgView.queryByName': 'Por nome da entidade',
  'oifde.knowledgegraph.validation.schemaName': 'Insira um nome de esquema.',
  'oifde.knowledgegraph.validation.dicName': 'Insira um nome de dicionário.',
  'oifde.knowledgegraph.validation.selectRequired': 'Campo obrig.',
  'oifde.knowledgegraph.validation.attrValue': 'Digite um valor de atributo.',
  'oifde.knowledgegraph.errorCode.100010000000': 'Erro no sistema.',
  'oifde.knowledgegraph.errorCode.100010000003': 'O nome deve ser exclusivo.',
  'oifde.knowledgegraph.errorCode.100010000002': 'Erro de formato.',
  'oifde.knowledgegraph.errorCode.100051001013': 'O esquema está em uso no momento.',
  'oifde.knowledgegraph.errorCode.100051001012': 'Um sinônimo já foi configurado para o nome.',
  'oifde.knowledgegraph.errorCode.100010000001': 'Erro de banco de dados.',
  'oifde.knowledgegraph.errorCode.100051000110': 'O número atingiu o limite máximo de 10.',
  'oifde.knowledgegraph.errorCode.100051000111': 'O número atingiu o limite máximo de 190.',
  'oifde.knowledgegraph.errorCode.100051000113': 'O URL configurado para o serviço GES está incorreto.',
  'oifde.knowledgegraph.errorCode.100051000112': 'Preparando o gráfico... Aguarde.',
  'oifde.knowledgegraph.errorCode.100061000005': 'O gráfico de conhecimento foi vinculado com êxito.',
  'oifde.knowledgegraph.errorCode.100010000005': 'O parâmetro de entrada está incorreto.',
  'oifde.knowledgegraph.errorCode.100051000114': 'Falha ao salvar a pergunta estendida para as perguntas frequentes do gráfico.',
  'oifde.knowledgegraph.errorCode.100061000006': 'Falha ao conectar ao ODFS.',
  'oifde.knowledgegraph.extendFaq.setting': 'Definir pergunta estendida',
  'oifde.knowledgegraph.extendFaq.import': 'Importar',
  'oifde.knowledgegraph.extendFaq.export': 'Exp. tudo',
  'oifde.knowledgegraph.extendFaq.extendFaq': 'Lista de Modelos FAQ',
  'oifde.knowledgegraph.extendFaq.faqTemplate': 'Modelo FAQ',
  'oifde.knowledgegraph.extendFaq.description': 'Descrição',
  'oifde.knowledgegraph.extendFaq.updateTime': 'Atual.',
  'oifde.knowledgegraph.extendFaq.status': 'Status',
  'oifde.knowledgegraph.extendFaq.setTemplate': 'Definir pergunta estendida',
  'oifde.knowledgegraph.extendFaq.use': 'Usar/não usar',
  'oifde.knowledgegraph.extendFaq.question': 'Pergunta',
  'oifde.knowledgegraph.extendFaq.reply': 'Respon',
  'oifde.knowledgegraph.extendFaq.similarQuestion': 'Pergunta semelhante',
  'oifde.knowledgegraph.extendFaq.similarReply': 'Resposta semelhante',
  'oifde.knowledgegraph.extendFaq.edit': 'Modificar -',
  'oifde.knowledgegraph.extendFaq.inUse': 'Em uso',
  'oifde.knowledgegraph.extendFaq.notUse': 'Não está em uso',
  'oifde.knowledgegraph.extendFaq.operation': 'Operação',
  'oifde.knowledgegraph.extendFaq.updateSuccess': 'O status foi alterado com sucesso.',
  'oifde.knowledgegraph.extendFaq.updateFailed': 'Falha ao alterar o status.',
  'oifde.knowledgegraph.extendFaq.updateTemplateSuccess': 'Modelo FAQ modificado com êxito.',
  'oifde.knowledgegraph.extendFaq.updateTemplateFailed': 'Falha ao modificar o modelo FAQ.',
  'oifde.whitelist.tenantId': 'ID do espaço do locatário',
  'oifde.whitelist.tenantSpace': 'Espaço do locatário',
  'oifde.whitelist.whiteIp': 'Endereço IP ou nome de domínio da lista confiável',
  'oifde.whitelist.whitePort': 'Porta lista de permissões',
  'oifde.whitelist.whiteType': 'Tipo lista de permissões',
  'oifde.whitelist.description': 'Descrição',
  'oifde.whitelist.whiteTypes.INTERFACE': 'Interface',
  'oifde.whitelist.whiteTypes.PROCEDURE': 'Procedimento armazenado',
  'oifde.whitelist.addWhiteList': 'Adic. lista permis.',
  'oifde.whitelist.editWhiteList': 'Editar lista permissões',
  'oifde.whitelist.whiteIpLen': 'O endereço IP da lista de confiança ou o nome de domínio pode conter no máximo 128 caracteres.',
  'oifde.whitelist.descriptionLen': 'A descrição pode conter até 1024 caracteres.',
  'oifde.whitelist.whiteIpListNotNull': 'Selecione pelo menos uma da lista de permissões para exclusão.',
  'oifde.whitelist.deletePrompt': 'Esta operação excluirá permanentemente a lista de permissões selecionada. Tem certeza de que deseja continuar?',
  'oifde.whitelist.ipRange': 'O endereço IP pode conter apenas letras, números, pontos (.), sublinhados (_) e hifens (-). Não pode começar nem terminar com hífen.',
  'oifde.operationlog.generate_test_case': 'Geração automática de teste',
  'oifde.operationlog.stop_auto_generate': 'Geração de teste interrompida',
  'oifde.operationlog.logging_module': 'Módulo',
  'oifde.operationlog.operator': 'Conta',
  'oifde.operationlog.startTime': 'Hr início',
  'oifde.operationlog.endTime': 'Hr término',
  'oifde.operationlog.operationType': 'Tipo operação',
  'oifde.operationlog.targetObjType': 'Tipo objeto',
  'oifde.operationlog.targetObjId': 'ID do Objeto',
  'oifde.operationlog.targetObjName': 'Objeto',
  'oifde.operationlog.result': 'Result',
  'oifde.operationlog.operationTime': 'Última operação',
  'oifde.operationlog.loginIp': 'Endereço IP de login',
  'oifde.operationlog.check': 'Validar',
  'oifde.operationlog.query': 'Cons.',
  'oifde.operationlog.create': 'Criar',
  'oifde.operationlog.copy': 'Cop.',
  'oifde.operationlog.paste': 'Colar',
  'oifde.operationlog.clone': 'Clonar',
  'oifde.operationlog.import': 'Importar',
  'oifde.operationlog.export': 'Exportar',
  'oifde.operationlog.update': 'Atualizar',
  'oifde.operationlog.delete': 'Excluir',
  'oifde.operationlog.enable': 'Ativar',
  'oifde.operationlog.disable': 'Desativar',
  'oifde.operationlog.set_test': 'Definir como teste',
  'oifde.operationlog.hang_up': 'Encerrar cham.',
  'oifde.operationlog.release': 'Publicar',
  'oifde.operationlog.invalidate': 'Invalidar',
  'oifde.operationlog.upgrade': 'Atualizar',
  'oifde.operationlog.set_white_list': 'Def. regras cinza',
  'oifde.operationlog.intent_template': 'Modelo de intenção',
  'oifde.operationlog.user_entity': 'Entidade do usuário',
  'oifde.operationlog.system_entity': 'Entidade do sistema',
  'oifde.operationlog.voice_content': 'Arquivo de voz',
  'oifde.operationlog.system_parameters': 'Parâmetro do sistema',
  'oifde.operationlog.intent_parameters': 'Parâmetro de intenção',
  'oifde.operationlog.call_session': 'Caixa diál. Chamada',
  'oifde.operationlog.cluster_task': 'Tarefa de agrupamento',
  'oifde.operationlog.history_dialogue': 'Caixa diál. Histórico',
  'oifde.operationlog.test_case_group': 'Grupo de caso de teste',
  'oifde.operationlog.test_case': 'Caso de teste',
  'oifde.operationlog.faq': 'Grupo FAQ',
  'oifde.operationlog.faq_element': 'Q&A',
  'oifde.operationlog.log_parameters': 'Parâmetro de log',
  'oifde.operationlog.tps_interface': 'Interface de serviço',
  'oifde.operationlog.nms_interface': 'Interface de processo de diálogo',
  'oifde.operationlog.white_list': 'Regra cinza de conhecimento',
  'oifde.operationlog.white_list_users': 'Número de assinante na regra cinza de conhecimento',
  'oifde.operationlog.sensitive_words': 'Palavra confidencial',
  'oifde.operationlog.stop_words': 'Palavra parada',
  'oifde.operationlog.navigation_info': 'Informações de navegação',
  'oifde.operationlog.command': 'Comando',
  'oifde.operationlog.api_key': 'Chave de API',
  'oifde.operationlog.trace_log': 'Config. do registro de rastreamento',
  'oifde.operationlog.succeed': 'Sucesso',
  'oifde.operationlog.failed': 'Falha',
  'oifde.operationlog.domain': 'Domín.',
  'oifde.operationlog.service_node': 'Nós de serviço',
  'oifde.operationlog.online': 'On-line',
  'oifde.operationlog.offline': 'Off-line',
  'oifde.operationlog.flow': 'Fluxo',
  'oifde.operationlog.performance_data': 'Dados de desempenho',
  'oifde.operationlog.login': 'Login',
  'oifde.operationlog.logout': 'Sair',
  'oifde.operationlog.change_password': 'Alterar senha',
  'oifde.operationlog.reset_password': 'Redefinir senha',
  'oifde.operationlog.authorize': 'Certificação',
  'oifde.operationlog.tenant_space': 'Espaço do locatário',
  'oifde.operationlog.account': 'Conta',
  'oifde.operationlog.role': 'Funç',
  'oifde.operationlog.password_rule': 'Regra de senha',
  'oifde.operationlog.password_rule_group': 'Grupo de regras de senha',
  'oifde.operationlog.user_account': 'Conta',
  'oifde.operationlog.user_role': 'Funç',
  'oifde.operationlog.knowledge_graph': 'Gráfico de conhecimento',
  'oifde.operationlog.knowledge_graph_synonym': 'Sinônimo do gráfico de conhecimento',
  'oifde.operationlog.acess_code': 'Código de acesso',
  'oifde.operationlog.gray_rule': 'Regra cinza',
  'oifde.operationlog.resource_template': 'Modelo de recurso',
  'oifde.operationlog.business_interface': 'Interface comercial',
  'oifde.operationlog.variable_manage': 'Gerenciamento de variáveis',
  'oifde.operationlog.flume_log_cfg': 'Configuração do log do Flume',
  'oifde.operationlog.procedure_db': 'Banco de dados do proced. armaz.',
  'oifde.operationlog.procedure_interface': 'Procedimento armazenado',
  'oifde.operationlog.complexcellcatalog': 'Catálogo de elementos de diagrama composto',
  'oifde.operationlog.complexcell': 'Elemento de diagrama composto',
  'oifde.operationlog.lock_account': 'Bloquear conta',
  'oifde.operationlog.unlock_account': 'Desbloquear conta',
  'oifde.operationlog.kick_account': 'Limpar sessões',
  'oifde.operationlog.start': 'Início',
  'oifde.operationlog.stop': 'Parar',
  'oifde.operationlog.log_open_task': 'Tarefa de ativação de registro',
  'oifde.operationlog.activate': 'Ativar',
  'oifde.operationlog.deactivate': 'Paus.',
  'oifde.operationlog.illeagal_status': 'Status ilegal',
  'oifde.operationlog.file': 'Arqu.',
  'oifde.operationlog.trace_log_query': 'Rastreamento e consulta de log',
  'oifde.operationlog.staticcheck': 'Verificação estática',
  'oifde.operationlog.unkonw': 'Desconh.',
  'oifde.operationlog.interface_white_list': 'Lista permiss.',
  'oifde.operationlog.license': 'Licença',
  'oifde.operationlog.test_corpus': 'Teste de Corpus',
  'oifde.operationlog.test': 'Teste',
  'oifde.operationlog.refresh': 'Atual.',
  'oifde.operationlog.train': 'Trem',
  'oifde.operationlog.trainAll': 'Treinar todos',
  'oifde.operationlog.lable_corpus': 'Corpus marcado',
  'oifde.operationlog.knowledge_graph_schema': 'Esquema de conhecimento',
  'oifde.operationlog.knowledge_graph_ask_template': 'Pergunta estendida',
  'oifde.operationlog.knowledge_graph_entity': 'Entidade de dicionário do gráfico',
  'oifde.operationlog.knowledge_graph_relation': 'Relacion do dicionário do gráfico',
  'oifde.operationlog.knowledge_graph_object_attribute': 'Relacion de atributo do dicionário gráfico',
  'oifde.operationlog.knowledge_graph_object_entity': 'Atributo da entidade de dicionário do gráfico',
  'oifde.operationlog.knowledge_graph_schema_entity': 'Entidade do esquema de conhecimento',
  'oifde.operationlog.knowledge_graph_schema_relation': 'Relacion do esquema de conhecimento',
  'oifde.operationlog.knowledge_graph_schema_attribute': 'Atributo do esquema de conhecimento',
  'oifde.operationlog.knowledge_graph_ask_template_group': 'Pergunta estendida do gráfico',
  'oifde.operationlog.knowledge_graph_ask_template_element': 'Modelo FAQ para perguntas com gráfico estendido',
  'oifde.operationlog.encrypt_Secret': 'Chave de criptografia',
  'oifde.operationlog.flow_trace_log': 'Configuração de rastreamento de teste de processo',
  'oifde.operationlog.channel_type': 'Canal',
  'oifde.operationlog.response_style': 'Estilo',
  'oifde.operationlog.resource_template_exce': 'Modelo de recurso de exceção',
  'oifde.operationlog.setting': 'Defi',
  'oifde.operationlog.quick_release': 'Publicação com um clique',
  'oifde.operationlog.upload': 'Downld',
  'oifde.operationlog.binding_domain': 'Upload',
  'oifde.operationlog.binding_faqgroup': 'Domínio chatbot',
  'oifde.operationlog.binding_kg': 'Grupo perg. frequ. do chatbot',
  'oifde.operationlog.binding': 'Gráfico do chatbot',
  'oifde.operationlog.download': 'Vincular',
  'oifde.operationlog.guideflow': 'Fluxo do assistente de criação',
  'oifde.operationlog.sensitive_group': 'Grupo palavras confidenciais',
  'oifde.operationlog.recordHistoryLog': 'Log caixa diál.',
  'oifde.operationlog.sentiment_analysis': 'Modelo de análise de sentimentos',
  'oifde.operationlog.use': 'Usado',
  'oifde.operationlog.unused': 'Não usado',
  'oifde.operationlog.tenantAsrName': 'Configuração ASR',
  'oifde.operationlog.tenantAsrTtsName': 'Gerenciamento de TTS/ASR',
  'oifde.operationlog.MRCPServerManagement': 'Gerenciamento do servidor',
  'oifde.operationlog.insert': 'Inserir',
  'oifde.operationlog.exclude_entity': 'Excluir Entidades',
  'oifde.sysConfig.sysParam': 'Parâmetros do sistema',
  'oifde.sysConfig.intent_params': 'Parâmetros de intenção',
  'oifde.sysConfig.code': 'Código da variável',
  'oifde.sysConfig.description': 'Descrição da variável',
  'oifde.sysConfig.value': 'Valor variável',
  'oifde.sysConfig.update': 'Aplicar',
  'oifde.sysConfig.abort': 'Cancelar',
  'oifde.sysConfig.updateAll': 'Aplicar todos os parâmetros',
  'oifde.sysConfig.restore_defaults': 'Restaurar valores padrão',
  'oifde.sysConfig.successful_operation': 'Operação bem-sucedida.',
  'oifde.sysConfig.confirm_apply_intent_param': 'Modificar os parâmetros de intenção afetará significativamente o reconhecimento de intenção. Tem certeza de que deseja continuar?',
  'oifde.sysConfig.succeed_apply_intent_param': 'Os parâmetros de intenção foram modificados com êxito.',
  'oifde.sysConfig.failed_apply_intent_param': 'Falha ao modificar o parâmetro da intenção.',
  'oifde.sysConfig.confirm_restore_defaults': 'Tem certeza de que deseja restaurar os valores padrão?',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.NAME': 'Módulo de pré-processamento chinês',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.NAME': 'Use Pinyin',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.DESC': 'Para converter chinês em pinyin.',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.TRUE': 'Sim',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.FALSE': 'Não',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.NAME': 'Usar tom',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.DESC': 'Se o tom deve ser usado no pinyin.',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.TRUE': 'Sim',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.FALSE': 'Não',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.MULTI': 'Cada ocorrência',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.ONCE': 'Apenas 1 ocorr.',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.TRUE': 'Ativar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.FALSE': 'Desativar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.TRUE': 'Ativar',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.FALSE': 'Desativar',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NAME': 'Módulo de extração de recursos N-Gram',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.NAME': 'Valor N mín.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.DESC': 'Valor N mínimo no algoritmo N-Gram.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.NAME': 'Valor N máx',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.DESC': 'Valor N máximo no algoritmo N-Gram.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.NAME': 'Limite mín. de peso da palavra',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.DESC': 'Uma palavra é ignorada quando seu peso está abaixo desse limite.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.NAME': 'Coleta de estatísticas em palavras repetidas',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.DESC': 'Se coletar estatísticas para cada ou apenas uma ocorrência de uma palavra repetida dentro de uma frase.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.NAME': 'Algoritmo do TSF',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.DESC': 'Se o algoritmo TSF deve ser ativado.',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.NAME': 'Extensão da borda da sentença',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.DESC': 'Se as tags devem ser adicionadas antes e depois de uma frase quando o recurso N-Gram é extraído.',
  'oifde.sysConfig.ENERGY_FILTER.NAME': 'Módulo de controle de quantidade de intenção',
  'oifde.sysConfig.ENERGY_FILTER.MAX.NAME': 'Máx. de intenções',
  'oifde.sysConfig.ENERGY_FILTER.MAX.DESC': 'Número máximo de intenções retornadas na decisão final.',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.NAME': 'Módulo de filtragem de intenção desconhecida',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.NAME': 'Limite de energia',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.DESC': 'As intenções serão consideradas desconhecidas se a soma de todas as energias de intenção detectadas for menor ou igual ao limite.',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.NAME': 'Módulo de Classificação de intenção',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.NAME': 'μ',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.DESC': 'μ valor da função logística do classificador de intenção.',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.NAME': 'γ',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.DESC': 'γ valor da função logística do classificador de intenção.',
  'oifde.sysConfig.INTENT_DECISION_MAKER.NAME': 'Módulo de tomada de decisão de intenção',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.NAME': 'Limite máximo',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.DESC': 'A intenção é considerada conhecida quando excede o limite máximo de seu tomador de decisão.',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.NAME': 'Limite mínimo',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.DESC': 'A intenção é considerada desconhecida quando fica abaixo do limite mínimo de seu tomador de decisão.',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NAME': 'Módulo de marcação de slot CRF',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.NAME': 'Taxa de aprendizado',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.DESC': 'Taxa na qual o aprendizado do modelo CRF é realizado.',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.NAME': 'Quantidade do nó da árvore',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.DESC': 'Número de nós contidos em cada árvore no algoritmo GBRT.',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.NAME': 'Quantidade da árvore',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.DESC': 'Número de árvores no algoritmo GBRT.',
  'oifde.errorCode.100010000000': 'Erro no sistema.',
  'oifde.errorCode.100010000002': 'O formato está incorreto.',
  'oifde.errorCode.100051005001': 'Falha ao salvar a lista de bloqueios e a lista de permissões.',
  'oifde.errorCode.100070000001': 'Falha ao importar os dados porque são iguais a um recurso existente.',
  'oifde.errorCode.100051005002': 'Falha ao excluir a lista de bloqueios e a lista de permissões.',
  'oifde.errorCode.100051000006': 'Falha ao importar o arquivo porque o formato é invalido.',
  'oifde.errorCode.100051005005': 'Falha ao importar o arquivo porque ele é muito grande.',
  'oifde.errorCode.100051005006': 'O número de serviço pode conter no máximo 64 caracteres.',
  'oifde.errorCode.100051005015': 'O arquivo pode ter no máximo 5000 linhas.',
  'oifde.errorCode.100051005003': 'Falha ao importar o arquivo porque ele não foi encontrado.',
  'oifde.errorCode.100051005004': 'Falha ao importar o arquivo porque o sistema não pode ler o arquivo.',
  'oifde.errorCode.100051006013': 'Falha ao importar o arquivo.',
  'oifde.errorCode.100051005025': 'O número total não pode exceder 5000.',
  'oifde.errorCode.100051005011': 'Falha ao adicionar o número de serviço.',
  'oifde.fileTask.EMPTY_COMMAND': 'O comando da operação não pode ficar em branco.',
  'oifde.fileTask.UNKNOWN_COMMAND': 'O comando da operação não está identificado.',
  'oifde.fileTask.INVALID_SALT': 'A solicitação é inválida.',
  'oifde.fileTask.INVALID_REQUEST': 'A solicitação é inválida.',
  'oifde.fileTask.EMPTY_TASK_ID': 'O ID da tarefa não pode ficar vazio.',
  'oifde.fileTask.TASK_NOT_FOUND': 'A tarefa não foi encontrada.',
  'oifde.fileTask.SERVER_BUSY': 'Sistema ocupado no momento.',
  'oifde.fileTask.SERVICE_UNAVAILABLE': 'O serviço está atualmente indisponível.',
  'oifde.fileTask.UPLOAD_EXCEPTION': 'O carregamento foi interrompido.',
  'oifde.fileTask.INVALID_ITEM_ENCODING': 'O modo de codificação é inválido.',
  'oifde.fileTask.INVALID_FILE_EXTENSION': 'A extensão do nome do arquivo é inválida.',
  'oifde.fileTask.WRITE_FILE_FAILED': 'Falha ao salvar o arquivo.',
  'oifde.fileTask.READ_FILE_FAILED': 'Falha ao ler o arquivo.',
  'oifde.fileTask.INVALID_FILE_PATH': 'O caminho de arquivo está incorreto.',
  'oifde.fileTask.FILE_NOT_EXIST': 'O arquivo não existe.',
  'oifde.fileTask.FILE_TOO_LARGE': 'O arquivo é muito grande.',
  'oifde.fileTask.ENTRY_TOO_LARGE': 'O pacote compactado contém arquivos muito grandes.',
  'oifde.fileTask.TOO_MANY_ENTRIES': 'O pacote compactado contém muitos arquivos.',
  'oifde.fileTask.INVALID_ENTRY_PATH': 'O caminho do arquivo no pacote compactado é inválido.',
  'oifde.fileTask.INVALID_ENTRY_EXTENSION': 'A extensão do nome do arquivo no pacote compactado é inválida.',
  'oifde.fileTask.UNZIPPED_TOO_LARGE': 'O pacote descompactado é muito grande.',
  'oifde.fileTask.UNZIP_FAILED': 'Falha ao descompactar o pacote.',
  'oifde.fileTask.DOWNLOAD_EXCEPTION': 'O download foi interrompido.',
  'oifde.fileTask.UNDEFINED_ERROR': 'Ocorreu um erro durante a execução da tarefa.',
  'oifde.fileTask.INVALID_TASK_ARGS': 'Parâmetro de tarefa ausente.',
  'oifde.fileTask.INVALID_TASK_CONTENT': 'Os dados da tarefa são inválidos.',
  'oifde.fileTask.GRAPH_IS_ON': 'Um gráfico ativo não pode ser importado.',
  'oifde.fileTask.INCORRECT_PARAMETERS': 'O parâmetro é inválido.',
  'oifde.fileTask.KG_NO_SCHEMA': 'O gráfico não está associado ao esquema de conhecimento.',
  'oifde.fileTask.IMPORT_KG_ENTITY_TOO_BIG': 'Um máximo de 1 milhão de entidades de gráfico é permitido.',
  'oifde.fileTask.IMPORT_KG_RELATION_TOO_BIG': 'Um máximo de 100 milhões de relacionamentos gráficos é permitido.',
  'oifde.fileTask.IMPORT_SCHEMA_TOO_BIG': 'São permitidas no máximo 10.000 entidades de conceito em um esquema de conhecimento.',
  'oifde.fileTask.IMPORT_SCHEMA_RELATION_TOO_BIG': 'São permitidas no máximo 10.000 relações em um esquema de conhecimento.',
  'oifde.importFile.white_list': 'Sobrescrever todas as configurações existentes',
  'oifde.importFile.test_case_group': 'Sobrescrever todas as configurações existentes',
  'oifde.importFile.knowledge_graph': 'Sobrescrever nomes duplicados',
  'oifde.importFile.knowledge_graph_ask_template': 'Sobrescrever todas as configurações existentes',
  'oifde.recordHistory.recordHistory': 'Histórico chamadas',
  'oifde.recordHistory.recordDetail': 'Detalhes da chamada',
  'oifde.recordHistory.userSaying': 'Solicitar texto',
  'oifde.recordHistory.systemReply': 'Texto de resposta',
  'oifde.recordHistory.reqTime': 'Solicitado',
  'oifde.recordHistory.respTime': 'Respondido',
  'oifde.recordHistory.callNumber': 'Número do assinante',
  'oifde.recordHistory.oneCondition': 'A data ou o número do celular é obrigatório.',
  'oifde.recordHistory.respSource': 'Origem da resposta',
  'oifde.recordHistory.silentSeat': 'Agente silencioso',
  'oifde.recordHistory.bot': 'Chatbot',
  'oifde.recordHistory.customer': 'Clientes',
  'oifde.error.messageHead': 'Interface front-end -',
  'oifde.error.messageBody': 'Erro de solicitação. Código de erro:',
  'oifde.error.messageTail': 'Verifique se a rede e o servidor estão funcionando corretamente.',
  'oifde.testMgr.noSelection': 'Os casos de teste não podem ser gerados porque nenhum domínio ou grupo FAQ está selecionado.',
  'oifde.testMgr.abortSuccess': 'Interrupção bem-sucedida.',
  'oifde.testMgr.abortFailed': 'Falha na interrupção.',
  'oifde.testMgr.getStatusWarn': 'Os casos de teste não podem ser gerados no momento porque um processo existente solicitou a geração de um caso de teste. Aguarde...',
  'oifde.testMgr.requestFailed': 'Falha ao chamar a interface para gerar automaticamente casos de teste.',
  'oifde.testMgr.getCorpusTestCase': 'Gerar caso',
  'oifde.testMgr.abortCorpusTestCase': 'Interrupção',
  'oifde.testMgr.domain': 'Domín.',
  'oifde.testMgr.faqGroup': 'Grupo FAQ',
  'oifde.testMgr.getCorpusTestCaseConfig': 'Gerar caso',
  'oifde.testMgr.allDomain': 'Selecionar todos os domínios',
  'oifde.testMgr.allFaqGroup': 'Selecione todos os grupos FAQ',
  'oifde.testMgr.testMgr': 'Teste de conhecimento',
  'oifde.testMgr.chatBotTestCaseMgr': 'Teste de diálogo',
  'oifde.testMgr.botTestCaseMgr': 'Gerenciar caso de teste',
  'oifde.testMgr.case_group': 'Grupos de caso de teste',
  'oifde.testMgr.case_amount': 'Casos de teste',
  'oifde.testMgr.case_tested': 'Quant. test.',
  'oifde.testMgr.case_passed': 'Aprovado',
  'oifde.testMgr.case_failed': 'Falhou',
  'oifde.testMgr.case_passed_rate': 'Taxa aprovação',
  'oifde.testMgr.test_time': 'Testado',
  'oifde.testMgr.add_test_case_group': 'Adic grupo de caso de teste',
  'oifde.testMgr.test_case_group_details': 'Detalhes',
  'oifde.testMgr.auto_testing_template': 'Baixar modelo',
  'oifde.testMgr.execute_test': 'Executar',
  'oifde.testMgr.manage': 'Gerenciar',
  'oifde.testMgr.case_id': 'ID do caso',
  'oifde.testMgr.userId': 'ID do assinante',
  'oifde.testMgr.expected_output': 'Palavra-chave de saída esperada ou Nome da intenção',
  'oifde.testMgr.actual_output': 'Saída real',
  'oifde.testMgr.actual_output_title': 'Saída real (nome da intenção entre parênteses)',
  'oifde.testMgr.event': 'Evento',
  'oifde.testMgr.note_test': 'Não testado',
  'oifde.testMgr.expected_output_with_memo': 'Palavras-chave de saída esperadas ou Nomes de intenção (separe várias entradas com vírgulas)',
  'oifde.testMgr.add_test_case': 'Adic. caso de teste',
  'oifde.testMgr.test_case_details': 'Detalhes',
  'oifde.testMgr.execute_all': 'Executar tudo',
  'oifde.testMgr.batchExec': 'Executar',
  'oifde.testMgr.succeed_save_case': 'O caso de teste foi salvo com êxito.',
  'oifde.testMgr.succeed_test_case': 'O caso de teste foi executado com êxito.',
  'oifde.testMgr.natureColon': ':',
  'oifde.testMgr.failed_query': 'Falha na consulta.',
  'oifde.testMgr.succeed_save_group': 'O grupo de caso de teste foi salvo com êxito.',
  'oifde.testMgr.fail_save_data': 'Falha ao salvar o grupo de caso de teste.',
  'oifde.testMgr.select_prompt': 'Selecione pelo menos um registro.',
  'oifde.testMgr.confirm_delete_case_group': 'Tem certeza de que deseja excluir o grupo de caso de teste?',
  'oifde.testMgr.failed_delete_group': 'Falha ao excluir o grupo de caso de teste.',
  'oifde.testMgr.succeed_delete_group': 'O grupo de caso de teste foi excluído com êxito.',
  'oifde.testMgr.confirm_delete_case': 'Tem certeza de que deseja excluir o caso de teste?',
  'oifde.testMgr.succeed_delete_case': 'Caso de teste excluído com sucesso.',
  'oifde.testMgr.failed_delete_case': 'Falha ao excluir o caso de teste.',
  'oifde.hisdialog.startTime_must_earlier_endTime': 'O horário de início precisa ser anter. ao de término. Redefina os critérios de pesquisa.',
  'oifde.hisdialog.direction.UPLOAD': 'Insira texto solic.',
  'oifde.chatBotTest.rangeFilter': 'Filtrar registros selecionados',
  'oifde.chatBotTest.botAccessCode': 'Código de acesso de chatbot',
  'oifde.chatBotTest.callingNum': 'Número chamador',
  'oifde.chatBotTest.caseTimeRange': 'Período',
  'oifde.chatBotTest.getChatBotTestCase': 'Gerar caso de diálogo',
  'oifde.chatBotTest.getChatBotTestCaseConfig': 'Gerar caso de diálogo',
  'oifde.chatBotTest.abortChatBotTestCase': 'Geração de caso de diálogo interrompida',
  'oifde.chatBotTest.deletePrompt': 'Esta operação excluirá permanentemente o grupo de caso de teste. Tem certeza de que deseja continuar?',
  'oifde.chatBotTest.deleteTestCase': 'Esta operação excluirá permanentemente os casos de teste selecionados. Tem certeza de que deseja continuar?',
  'oifde.chatBotTest.checkPrompt': 'A execução simultânea de vários casos de teste pode tornar o sistema mais lento. Tem certeza de que deseja continuar?',
  'oifde.chatBotTest.cancleExcute': 'A execução foi cancelada.',
  'oifde.chatBotTest.inputTestCaseGroupName': 'Grupo de casos de teste',
  'oifde.chatBotTest.doTest': 'Executar',
  'oifde.chatBotTest.executeSuccess': 'Execução bem-sucedida.',
  'oifde.chatBotTest.downTestReport': 'Baixe o relatório de teste',
  'oifde.chatBotTest.downAllReport': 'Baixar todos os relatórios',
  'oifde.chatBotTest.testCaseGroup': 'Grupos de caso de teste',
  'oifde.chatBotTest.totalNumber': 'Casos de teste',
  'oifde.chatBotTest.testTimes': 'Quant. test.',
  'oifde.chatBotTest.passTimes': 'Aprovado',
  'oifde.chatBotTest.failTimes': 'Falhou',
  'oifde.chatBotTest.passRate': 'Taxa aprovação',
  'oifde.chatBotTest.testTime': 'Testado',
  'oifde.chatBotTest.status': 'Status',
  'oifde.chatBotTest.status.initial': 'Inicializar',
  'oifde.chatBotTest.status.waiting': 'Esperando',
  'oifde.chatBotTest.status.running': 'Em execução',
  'oifde.chatBotTest.status.finish': 'Concluído',
  'oifde.chatBotTest.status.failed': 'Falhou',
  'oifde.chatBotTest.status.success': 'Sucesso',
  'oifde.chatBotTest.description': 'Descrição',
  'oifde.chatBotTest.addTestCaseGroup': 'Adic grupo de caso de teste',
  'oifde.chatBotTest.editTestCaseGroup': 'Modif grupo de caso de teste',
  'oifde.chatBotTest.testCaseGroupName': 'Nome do grupo de caso',
  'oifde.chatBotTest.robotName': 'Nome do chatbot',
  'oifde.chatBotTest.callingId': 'Número chamador',
  'oifde.chatBotTest.prompt': 'Informação',
  'oifde.chatBotTest.promptMessage': 'Selecione pelo menos um registro.',
  'oifde.chatBotTest.downloadMessage': 'Você selecionou {0} registros. Tem certeza de que deseja continuar?',
  'oifde.chatBotTest.downloadAllMessage': 'Você não selecionou registros específicos. Exportar todos os registros? Você também pode cancelar a operação e selecionar um registro específico para exportar.',
  'oifde.testCase.name': 'Nome de caso de teste',
  'oifde.testCase.doAll': 'Executar tudo',
  'oifde.testCase.caseName': 'Nome do caso',
  'oifde.testCase.executeStatus': 'Status',
  'oifde.testCase.description': 'Descrição',
  'oifde.testCase.caseDetail': 'Detalhes do caso',
  'oifde.testCase.traceLog': 'Rastream fluxo',
  'oifde.testCase.callDetail': 'Detalhes',
  'oifde.testCase.addTestCase': 'Adic. caso de teste',
  'oifde.testCase.editTestCase': 'Modificar caso de teste',
  'oifde.testCase.caseDetailMgr': 'Gerenciar detalhes do caso',
  'oifde.testCase.detail.reqText': 'Solicitar texto',
  'oifde.testCase.detail.expectedReturn': 'Texto de resposta esperada',
  'oifde.testCase.detail.channelAssociatedData': 'Dados associados',
  'oifde.testCase.detail.operation': 'Operação',
  'oifde.testCase.detail.realResponse': 'Atual texto de resposta',
  'oifde.testCase.detail.close': 'Fech.',
  'oifde.rate.accessCode': 'Selecione um código de acesso do chatbot.',
  'oifde.rate.callCountBoard': 'Painel de estatísticas de chamadas',
  'oifde.rate.resolveRate': 'Taxa líquida de resolução de autoatendimento',
  'oifde.rate.dayOption': 'Por dia',
  'oifde.rate.hourOption': 'Por hora',
  'oifde.rate.monthOption': 'Últimos 30 dias',
  'oifde.rate.dateSelect': 'Selecione uma data.',
  'oifde.rate.index': 'Nº.',
  'oifde.rate.time': 'Data e hora',
  'oifde.rate.accessAmount': 'Chamadas conectadas',
  'oifde.rate.exitNoConsultCount': 'Chamadas diretamente desconectadas',
  'oifde.rate.callStaffNoConsultCount': 'Chamadas processadas somente pelo Agente',
  'oifde.rate.exitConsultingCount': 'Chamadas processadas somente pelo Chatbot',
  'oifde.rate.callStaffConsultCount': 'Chamadas transferidas do Chatbot para o Agente',
  'oifde.rate.autonomyRate': 'Taxa de resolução de autoajuda',
  'oifde.rate.interactCount': 'Rodada caixa diálogo',
  'oifde.rate.averageInteractPerCall': 'Média de rodadas caixa de diálogo',
  'oifde.rate.accessNum': 'Chamadas conectadas',
  'oifde.rate.dayconditionTips': 'O código de acesso não está nos critérios de pesquisa.',
  'oifde.rate.hourConditionTips': 'A data ou o código de acesso não aparece nos critérios de pesquisa.',
  'oifde.rate.rateForCharts': 'Taxa líquida de resolução do autoatendimento',
  'oifde.rate.interfaceRequestCount': 'Chamadas de interface',
  'oifde.rate.interfaceSuccessCount': 'Chamadas bem-sucedidas',
  'oifde.rate.interfaceSuccessRate': 'Taxa de sucesso em invocações',
  'oifde.dashBoard.noData': '- (Dados excluídos não podem ser exibidos.)',
  'oifde.dashBoard.serviceCapabilityDashBoard': 'Painel de capacidade de serviço',
  'oifde.dashBoard.knowledgeDashBoard': 'Painel de conhecimento',
  'oifde.dashBoard.service.consultCount': 'Total de respostas',
  'oifde.dashBoard.service.responseSuccessCount': 'Respostas bem-sucedidas',
  'oifde.dashBoard.service.unknowCount': 'Respostas desconhecidas',
  'oifde.dashBoard.service.responseRate': 'Taxa de sucesso da resposta',
  'oifde.dashBoard.service.feedbackCount': 'Feedback total',
  'oifde.dashBoard.service.positiveFeedbackCount': 'Feedback satisfeito',
  'oifde.dashBoard.service.positiveFeedbackRate': 'Taxa de satisfação',
  'oifde.dashBoard.knowledge.dateConditionTips': 'Selecione uma data.',
  'oifde.dashBoard.knowledge.topTenChatBot': 'Dez principais códigos acesso do Chatbot',
  'oifde.dashBoard.knowledge.accessCode': 'Código de acesso',
  'oifde.dashBoard.knowledge.name': 'Nome',
  'oifde.dashBoard.knowledge.callCount': 'Visitas',
  'oifde.dashBoard.knowledge.topTenknowledge': '10 princip. entidades conhec. úteis',
  'oifde.dashBoard.knowledge.intent': 'Intenção',
  'oifde.dashBoard.knowledge.faq': 'FAQ',
  'oifde.dashBoard.knowledge.hitCount': 'Acertos',
  'oifde.dashBoard.chartView.title': 'Taxa de sucesso da resposta',
  'oifde.dashBoard.chartView.countTile': 'Total de respostas',
  'oifde.dashBoard.chartView.rateTile': 'Taxa de sucesso de resposta',
  'oifde.dashBoard.chartView.leftTile': 'Total de respostas',
  'oifde.dashBoard.chartView.rightTile': 'Taxa de sucesso de resposta',
  'oifde.dashBoard.graph.title': 'Estatísticas de conhecimento válidas',
  'oifde.dashBoard.graph.faq': 'FAQ',
  'oifde.dashBoard.graph.intent': 'Intenção',
  'oifde.dashBoard.graph.kg': 'Gráfico',
  'oifde.dashBoard.graph.talk': 'Chat',
  'oifde.dashBoard.graph.countTitle': 'Total',
  'oifde.dashBoard.graph.hitTitle': 'Acertos',
  'oifde.dashBoard.graph.hitRateTitle': 'Taxa de acertos',
  'oifde.dashBoard.graph.amount': 'Quantidade',
  'oifde.dashBoard.graph.percent': 'Porcentagem',
  'oifde.dashBoard.graph.count': 'Total',
  'oifde.dashBoard.graph.hit': 'Acertos',
  'oifde.dashBoard.graph.hitRate': 'Taxa de acertos',
  'oifde.dashBoard.pie.title': 'Clique em Distribuição de conhecimento',
  'oifde.dashBoard.pie.intentTitle': 'Intenção',
  'oifde.dashBoard.pie.kgTitle': 'Gráfico',
  'oifde.dashBoard.pie.faqTitle': 'FAQ',
  'oifde.dashBoard.pie.talkTitle': 'Chat',
  'oifde.dashBoard.pie.intent': 'Intenção',
  'oifde.dashBoard.pie.kg': 'Gráfico',
  'oifde.dashBoard.pie.faq': 'FAQ',
  'oifde.dashBoard.pie.talk': 'Chat',
  'oifde.chatbot.errorCode.100010000002': 'O formato está incorreto.',
  'oifde.chatbot.errorCode.100051000031': 'O número de IDs de entrada excede o limite máximo.',
  'oifde.chatbot.errorCode.100051000023': 'Falha ao excluir o grupo de casos.',
  'oifde.chatbot.errorCode.100010000000': 'Erro no sistema.',
  'oifde.chatbot.errorCode.100010000001': 'Erro de banco de dados.',
  'oifde.chatbot.errorCode.100010000003': 'O nome deve ser exclusivo.',
  'oifde.chatbot.errorCode.100010000005': 'O parâmetro de entrada é inválido.',
  'oifde.chatbot.errorCode.100023000004': 'Falha ao verificar o ID de entrada.',
  'oifde.chatbot.errorCode.100051000022': 'Falha ao salvar o caso de teste.',
  'oifde.chatbot.errorCode.100051000032': 'Falha ao excluir os casos de teste.',
  'oifde.chatbot.errorCode.100051000026': 'Falha ao executar o teste porque o número de grupos de teste selecionados é muito grande.',
  'oifde.chatbot.errorCode.100051000028': 'O resultado do teste não foi encontrado.',
  'oifde.chatbot.errorCode.100051000109': 'O número de casos de teste excede 200.',
  'oifde.serverMgr.serverType': 'Tipo de servidor',
  'oifde.serverMgr.mrcpId': 'ID do grupo',
  'oifde.serverMgr.asrServer': 'servidor ASR',
  'oifde.serverMgr.ttsServer': 'servidor TTS',
  'oifde.serverMgr.confirmDelete': 'Deseja realmente excluir os dados?',
  'oifde.serverMgr.addServer': 'Adic. servidor',
  'oifde.serverMgr.editServer': 'Modif. serv.',
  'oifde.qiAsrServerMgr.asrVendor': 'Fabricante',
  'oifde.qiAsrServerMgr.asrServerName': 'Nome do servidor ',
  'oifde.qiAsrServerMgr.asrServerAddress': 'Endereço do servidor',
  'oifde.qiAsrServerMgr.asrAppKey': 'Nome de usuário/AK',
  'oifde.qiAsrServerMgr.fileServerAddress': 'Endereço do servidor de arquivos',
  'oifde.qiAsrServerMgr.folder': 'Pasta padrão',
  'oifde.qiAsrServerMgr.description': 'Descrição',
  'oifde.qiAsrServerMgr.addQiAsrServer': 'Adicionar informações do servidor ASR ',
  'oifde.qiAsrServerMgr.editQiAsrServer': 'Editar informações do servidor ASR ',
  'oifde.qiAsrServerMgr.asrServerInfo': 'Informações do servidor ASR',
  'oifde.qiAsrServerMgr.name': 'Nome ',
  'oifde.qiAsrServerMgr.asrServerIP': 'Endereço do servidor ASR',
  'oifde.qiAsrServerMgr.asrServerPort': 'Porta do servidor ASR',
  'oifde.qiAsrServerMgr.asrServerUser': 'Usuário do servidor ASR',
  'oifde.qiAsrServerMgr.asrServerPW': 'Senha do servidor ASR',
  'oifde.qiAsrServerMgr.asrServerPWConform': 'Confirmar senha do servidor ASR',
  'oifde.qiAsrServerMgr.nextStep': 'Próximo',
  'oifde.qiAsrServerMgr.preStep': 'Anterior',
  'oifde.qiAsrServerMgr.fileServerInfo': 'Informações do servidor de arquivos ',
  'oifde.qiAsrServerMgr.fileServerType': 'Tipo de servidor de arquivos',
  'oifde.qiAsrServerMgr.fileServerIP': 'Endereço do servidor de arquivos',
  'oifde.qiAsrServerMgr.fileServerPort': 'Porta do servidor de arquivos',
  'oifde.qiAsrServerMgr.fileServerUser': 'Nome de usuário do servidor de arquivos',
  'oifde.qiAsrServerMgr.fileServerPW': 'Senha do servidor de arquivos',
  'oifde.qiAsrServerMgr.fileServerPWConfirm': 'Confirmar senha do servidor de arquivos',
  'oifde.qiAsrServerMgr.voiceUploadFolder': 'Caminho de upload do arquivo de gravação',
  'oifde.qiAsrServerMgr.qiResultFolder': 'Caminho do resultado da transposição',
  'oifde.qiAsrServerMgr.sftp': 'SFTP',
  'oifde.qiAsrServerMgr.passwordError': 'As senhas digitadas duas vezes são inconsistentes.',
  'oifde.qiAsrServerMgr.asrServerNameError': 'Duplicar o nome do servidor ASR predefinido do sistema.',
  'oifde.asrMgr.tenantId': 'ID do espaço do locatário',
  'oifde.asrMgr.tenantSapce': 'Espaço do locatário',
  'oifde.asrMgr.asr': 'Fornecedor de servidor ASR',
  'oifde.asrMgr.mrcpid': 'ID do grupo do ASR',
  'oifde.asrMgr.path': 'Caminho do arquivo gramatical ASR',
  'oifde.asrMgr.slotName': 'Slot ASR',
  'oifde.asrMgr.groupID': 'ID do grupo do TTS',
  'oifde.asrMgr.addAsr': 'Adicionar TTS/ASR',
  'oifde.asrMgr.editAsr': 'Modificar TTS/ASR',
  'oifde.asrMgr.selectOne': 'Selecione pelo menos uma das seguintes opções: fornecedor do servidor ASR, servidor ASR e servidor TTS.',
  'oifde.asrMgr.TENANT_SPACE_NOT_FOUND': 'O espaço do locatário não foi encontrado.',
  'oifde.asrMgr.languageTips': 'default indique que les langues ne sont pas différenciées',
  'oifde.license.license': 'Gerenc. licença',
  'oifde.license.import': 'Carregar/Atualizar',
  'oifde.license.setlicense': 'Atribuir licença',
  'oifde.license.queryCurrentLicense': 'Consultar a moeda e o acúmulo atual',
  'oifde.license.queryHistoryLicense': 'Consultar simultaneidade do histórico',
  'oifde.license.valateDate': 'Data de vencimento',
  'oifde.license.totalLicense': 'Quantidade de licenças',
  'oifde.license.LIN8OIAPAA01': 'Número de assistentes de agentes simultâneos',
  'oifde.license.LIN8OIAPVA01': 'Número de agentes virtuais simultâneos',
  'oifde.license.LIN8OIAPVA02': 'Número acumulado de chamadas de agentes virtuais',
  'oifde.license.PERMANENT': 'Perman.',
  'oifde.license.processTime': 'Processada',
  'oifde.license.at_least_one_serviceValue': 'Defina pelo menos um parâmetro.',
  'oifde.license.failed_set_license': 'Falha ao configurar a licença.',
  'oifde.license.success_set_license': 'A licença foi configurada com sucesso.',
  'oifde.license.import_template_hint_dat': 'Somente arquivos no formato .dat podem ser carregados.',
  'oifde.license.import_template_need_dat': 'O arquivo a ser carregado deve estar no formato .dat.',
  'oifde.license.maxHisInfo': 'Valor máximo do histórico',
  'oifde.license.detailHisInfo': 'Detalhes do histórico',
  'oifde.license.sys': 'Sist.',
  'oifde.request.timeout': 'Tempo limite de resposta excedido (timeout)',
  'oifde.analysis.validate.time': 'Data Necessária',
  'oifde.analysis.validate.accessCode': 'Código de acesso do robô Necessário',
  'oifde.analysis.validate.timeInterval': 'O intervalo não é superior a 7 dias.',
  'oifde.analysis.chart.title': 'Estatísticas de ramificação de nó IVR',
  'oifde.analysis.chart.timeOther': 'Tempo limite ou pressione outra tecla',
  'oifde.analysis.chart.button': 'Pressione a tecla ',
  'oifde.analysis.chart.default': 'Ramificação padrão do sistema',
  'oifde.analysis.childrenView': 'Exibir subnós',
  'oifde.service.invoke.breakMinTime.desc': 'Unidade: segundos;',
  'oifde.ivrJourney.nodeOverview': 'Visión general del nodo',
  'oifde.ivrJourney.trendAnalysis': 'Análisis de tendencias',
  'oifde.ivrJourney.keyDistribution': 'Distribución de teclas',
  'oifde.ivrJourney.nodeVersionComparison': 'Comparación de versiones de nodos',
  'oifde.ivrJourney.businessRanking': 'Ranking de servicios',
  'oifde.ivrJourney.no': 'Ranking',
  'oifde.ivrJourney.nodeName': 'NodeName',
  'oifde.ivrJourney.visitsTotal': 'Visitas',
  'oifde.ivrJourney.yoyValue': 'Anual',
  'oifde.ivrJourney.increaseValue': 'Arriba y caída',
  'oifde.ivrJourney.visitorTotal': 'Visitas',
  'oifde.ivrJourney.successRate': 'Tasa de éxito',
  'oifde.ivrJourney.failedRate': 'Tasa de fallas',
  'oifde.ivrJourney.hangUpRate': 'Tasa de suspensión',
  'oifde.ivrJourney.journeyNumTrend': 'Distribuição de tendência de valor',
  'oifde.ivrJourney.efficiencyTrend': 'Distribuição de Tendências de Eficiência',
  'oifde.ivrJourney.complexityTrend': 'Distribuição de Tendências de Complexidade',
  'oifde.ivrJourney.repeatNum': 'Ensaio',
  'oifde.ivrJourney.directCalls': 'Direto',
  'oifde.ivrJourney.nonDirectCalls': 'Não Direto',
  'oifde.ivrJourney.journeyNum': 'Visitas',
  'oifde.ivrJourney.hangupNum': 'Pendurar',
  'oifde.ivrJourney.successCalls': 'Número de Sucessos',
  'oifde.ivrJourney.failCalls': 'Número de Falhas',
  'oifde.ivrJourney.directRate': 'Taxa direta',
  'oifde.ivrJourney.nonDirectRate': 'Taxa Não Direta',
  'oifde.ivrJourney.hangupRate': 'Hang Up Rate',
  'oifde.ivrJourney.failRate': 'Taxa de Falhas',
  'oifde.ivrJourney.repeatCalls': 'Ouvir',
  'oifde.ivrJourney.repeatOnceCalls': 'Ouvir Uma Vez',
  'oifde.ivrJourney.repeatTwiceCalls': 'Ensaio Duas Vezes',
  'oifde.ivrJourney.repeatRate': 'Taxa de Ensaio',
  'oifde.ivrJourney.averageTime': 'Duração média do serviço',
  'oifde.ivrJourney.averagePathLength': 'Comprimento médio do caminho',
  'oifde.ivrJourney.timeRangeCheck30': 'O intervalo não pode exceder 30 dias',
  'oifde.ivrJourney.dateTimrRange': 'Intervalo de tempo dos dados:',
  'oifde.ivrJourney.total': 'Total',
  'oifde.ivrJourney.inflowDistribution': 'Distribuição de entrada',
  'oifde.ivrJourney.outflowDistribution': 'Distribuição do fluxo de saída',
  'oifde.ivrJourney.totalDistribution': 'Distribuição do fluxo de saída',
  'oifde.ivrJourney.detailDistribution': 'Distribuição Detalhada',
  'oifde.ivrJourney.outflowDetailDistribution': 'Distribuição Detalhada do Fluxo de Saída',
  'oifde.ivrJourney.outflowDetailDistributionAmount': 'Quantia de distribuição detalhada do fluxo de saída',
  'oifde.ivrJourney.outflowDetailDistributionPercentage': 'Percentagem de distribuição detalhada do fluxo de saída',
  'oifde.ivrJourney.nodeTrafficChart': 'Gráfico de tráfego do nó (número de indicadores)',
  'oifde.ivrJourney.indicatorQuantityStatistics': 'Estatísticas de Indicadores',
  'oifde.ivrJourney.indicateName': 'Nome do indicador',
  'oifde.ivrJourney.alarmContent': 'Conteúdo de pré-aviso',
  'oifde.ivrJourney.alarmTime': 'Tempo de pré-aviso',
  'oifde.ivrJourney.createTime': 'Tempo de pré-aviso dos dados',
  'oifde.ivrJourney.alarmLevel': 'Nível de pré-aviso',
  'oifde.ivrJourney.alarmIndicator': 'Indicadores de pré-aviso',
  'oifde.ivrJourney.remindType': 'Modo lembrete',
  'oifde.ivrJourney.receivers': 'Destinatário',
  'oifde.ivrJourney.sendResult': 'Enviando resultado',
  'oifde.ivrJourney.low': 'Baixo',
  'oifde.ivrJourney.middle': 'Médio',
  'oifde.ivrJourney.high': 'Alto',
  'oifde.ivrJourney.noNeedToSend': 'Não há necessidade de enviar',
  'oifde.ivrJourney.sendFail': 'Falha ao enviar',
  'oifde.ivrJourney.sendSuccess': 'Enviado com sucesso',
  'oifde.ivrJourney.sms': 'SMS',
  'oifde.ivrJourney.email': 'E-mail',
  'oifde.ivrJourney.smsAndEmail': 'SMS; E-mail',
  'oifde.ivrJourney.other': 'Outros',
  'oifde.ivrJourney.nowData': 'Valor atual',
  'oifde.ivrJourney.label': 'Identificação',
  'oifde.ivrJourney.transToManualCalls': 'Quantidade de mão-de-obra',
  'oifde.ivrJourney.transToNextLevelManuCalls': 'Quantidade de Fluxo para Menu Inferior',
  'oifde.ivrJourney.timeOutOrPressAnotherKey': 'Tempo limite ou pressione outra tecla',
  'oifde.ivrJourney.buttons': 'Pressione a tecla',
  'oifde.ivrJourney.defaultBranch': 'Ramificação padrão',
  'oifde.ivrJourney.count': 'Número de vezes',
  'oifde.ivrJourney.versionName': 'Nome da versão',
  'oifde.ivrJourney.compareRange': 'Intervalo de comparação',
  'oifde.ivrJourney.pleaseSelectAccessCode': 'Por favor, selecione um robô',
  'oifde.ivrJourney.pleaseSelectAccessCodeFirst': 'Selecione um robô primeiro',
  'oifde.ivrJourney.oneDay': '1 dia',
  'oifde.ivrJourney.threeDay': 'Três dias',
  'oifde.ivrJourney.halfMonth': 'Meio mês',
  'oifde.ivrJourney.oneMonth': 'Um mês',
  'oifde.ivrJourney.chooseAccessCode': 'Selecionar bot',
  'oifde.ivrJourney.chooseVersion': 'Selecionar versão',
  'oifde.ivrJourney.releaseDate': 'Tempo de publicação',
  'oifde.ivrJourney.nodeVersionCompareStatistics': 'Estatísticas de Comparação de Versões de Nó',
  'oifde.ivrJourney.validIVRService': 'Serviço IVR válido',
  'oifde.ivrJourney.transToManualCallsSucc': 'Transferido para o Manual com Sucesso',
  'oifde.ivrJourney.selfService': 'Auto-serviço',
  'oifde.ivrJourney.repeatIncomingCalls': 'Chamadas Recebidas Repetidas',
  'oifde.ivrJourney.returnKeysByErrors': 'Retorno de erro de chave',
  'oifde.ivrJourney.pleaseSelectVersion': 'Selecione a versão do processo',
  'oifde.ivrJourney.pleaseSelectCompareRange': 'Selecione o intervalo de comparação',
  'oifde.ivrJourney.logTime': 'Datas',
  'oifde.ivrJourney.validIVRCalls': 'Volume de serviços efetivos de IVR',
  'oifde.ivrJourney.IVRCalls': 'Volume de autoatendimento',
  'oifde.ivrJourney.IVRTransManualRate': 'Proporción de tráfico manual(%)',
  'oifde.ivrJourney.IVRRate': 'Taxa de serviço de URA pura (%)',
  'oifde.ivrJourney.IVRRateNoSymbol': 'Taxa de serviço de URA pura',
  'oifde.ivrJourney.transManualSuccCalls': 'Transferências bem-sucedidas',
  'oifde.ivrJourney.transManualSuccRate': 'Taxa de Sucesso de Transferência (%)',
  'oifde.ivrJourney.avgIVRTime': 'Duração média da chamada (segundos)',
  'oifde.ivrJourney.avgIVRTimeNoSymbol': 'Duração média da chamada',
  'oifde.ivrJourney.IVRErrorRate': 'Taxa de erro do sistema IVR (%)',
  'oifde.ivrJourney.IVRErrorRateNoSymbol': 'Taxa de erro do sistema IVR',
  'oifde.ivrJourney.IVRTotalCalls': 'Volume de chamadas recebidas por IVR',
  'oifde.ivrJourney.IVRSrvTime': 'Duração total do serviço (segundos)',
  'oifde.ivrJourney.wrongKeyCalls': 'Volume de retorno de erro de tecla',
  'oifde.ivrJourney.distinctCustomers': 'Número de clientes',
  'oifde.ivrJourney.IVRSrvCustomers': 'Clientes URA puros',
  'oifde.ivrJourney.callNum': 'Volume de Chamadas',
  'oifde.ivrJourney.ivrRepeatCallNum': 'Chamadas repetidas IVR',
  'oifde.ivrJourney.resolveRate': 'Taxa de resolução única',
  'oifde.ivrJourney.serviceRate': 'Taxa de Serviço Efetiva',
  'oifde.ivrJourney.node': 'Nódulo ',
  'oifde.ivrJourney.indicatorDesc': 'Indicator Description',
  'oifde.ivrJourney.validIVRServiceDesc': 'Número de chamadas IVR em que o tratamento de serviço ou consulta é bem sucedido, a voz de consulta de serviço é reproduzida mais da metade, ou o SMS, MMS, WAP Push ou e-mail são acionados com sucesso.',
  'oifde.ivrJourney.transToManualCallsDesc': 'Número de chamadas IVR que tentam ser encaminhadas para o serviço manual',
  'oifde.ivrJourney.transToManualCallsSuccDesc': 'Número de chamadas de IVR que são transferidas com sucesso para um agente.',
  'oifde.ivrJourney.selfServiceDesc': 'Número de chamadas IVR que não são encaminhadas para o serviço manual.',
  'oifde.ivrJourney.repeatIncomingCallsDesc': 'Número de clientes que usam o mesmo número de chamada para ligar para a linha de atendimento ao cliente repetidamente dentro de duas horas.',
  'oifde.ivrJourney.returnKeysByErrorsDesc': 'No nó de navegação, depois que uma tecla é pressionada incorretamente, o número retornado pela tecla asterisco (*) é selecionado conforme solicitado.',
  'oifde.ivrJourney.indicatorSetting': 'Configuração do Indicador',
  'oifde.ivrJourney.indicatorSettingLengthError': 'Selecione pelo menos {count} indicadores.',
  'oifde.ivrJourney.extendedMetrics': 'Expansão do indicador',
  'oifde.ivrJourney.optimizeVoiceSequence': 'Otimização da ordem de transmissão de voz',
  'oifde.ivrJourney.optimizeVoiceDefinition': 'Otimização de inteligibilidade de voz',
  'oifde.ivrJourney.pleaseSelectDateRange': 'Selecione um intervalo de tempo',
  'oifde.ivrJourney.reverseFlow': 'Direção do fluxo inverso',
  'oifde.ivrJourney.currentVoicePlayContent': 'Conteúdo atual da transmissão de voz',
  'oifde.ivrJourney.repeatCallsNum': 'Número de repetições',
  'oifde.ivrJourney.optimizeSuggestion': 'Recomendações de otimização',
  'oifde.ivrJourney.repeatCallOptimizeSuggestion': 'O número de repetições de escuta do nó atual é muito alto. Por favor, verifique se o conteúdo de voz não está claro ou é difícil de entender. Recomenda-se otimizar o conteúdo de transmissão de voz.',
  'oifde.ivrJourney.operationStatus': 'Status da operação',
  'oifde.ivrJourney.poor': 'Pobres',
  'oifde.ivrJourney.general': 'Geral',
  'oifde.ivrJourney.good': 'Bom',
  'oifde.ivrJourney.fluctuationService': 'Serviço de flutuação',
  'oifde.ivrJourney.IVRIncomingCallVolumeTrend': 'Tendência do volume de chamadas recebidas IVR',
  'oifde.ivrJourney.efficiencyOverview': 'Visão geral da eficiência',
  'oifde.ivrJourney.repeatedCallRate': 'Taxa de discagem repetida',
  'oifde.ivrJourney.valueOverview': 'Visão geral do valor',
  'oifde.ivrJourney.pureIVRServiceVolume': 'Volume de serviço puro do IVR',
  'oifde.ivrJourney.complexityOverview': 'Visão geral da complexidade',
  'oifde.ivrJourney.personTime': 'quantidade',
  'oifde.ivrJourney.times': 'vezes',
  'oifde.ivrJourney.noData': 'Nenhum',
  'oifde.ivrJourney.usageStatistics': 'Estatísticas de utilização das teclas de transmissão de voz actuais',
  'oifde.ivrJourney.playingOrder': 'Sugerir ordem de transmissão de voz',
  'oifde.ivrJourney.journeySum': 'Utilização',
  'oifde.ivrJourney.forever': 'Permanentemente válido',
  'oifde.ivrJourney.slectTimeForever': 'Especifica o período de validade.',
  'oifde.ivrJourney.alarmIndex': 'Indicadores Estatísticos',
  'oifde.ivrJourney.alarmCondition': 'Condição de pré-aviso',
  'oifde.ivrJourney.alarmValidity': 'Período de Validade Pré-aviso',
  'oifde.ivrJourney.selectAlarmCondition': 'Selecione a condição de alerta a ser excluída.',
  'oifde.ivrJourney.remindObject': 'Taxa de transferência para manual',
  'oifde.ivrJourney.transfManualRate': 'Taxa de transferência para manual',
  'oifde.ivrJourney.absoluteValue': 'valor absoluto',
  'oifde.ivrJourney.highThan': 'Maior do que',
  'oifde.ivrJourney.equal': 'É igual a',
  'oifde.ivrJourney.lessThen': 'Menos do que',
  'oifde.ivrJourney.createAlarmConfig': 'Criando uma condição de pré-alarme de serviço',
  'oifde.ivrJourney.editAlarmConfig': 'Editando uma condição de pré-alarme de serviço',
  'oifde.ivrJourney.selectAccesscodeAndFlow': 'Selecione um robô e processe a versão.',
  'oifde.ivrJourney.selectAlarmIndex': 'Selecione um indicador estatístico.',
  'oifde.ivrJourney.fillAlarmCondition': 'Por favor, complete as condições de pré-alarme.',
  'oifde.ivrJourney.selectAlarmLevel': 'Selecione um nível de aviso.',
  'oifde.ivrJourney.selectTime': 'Selecione um horário válido.',
  'oifde.ivrJourney.selectAlramRemindType': 'Selecione pelo menos um modo de lembrete.',
  'oifde.ivrJourney.selectRemindObject': 'Selecione um objeto de lembrete.',
  'oifde.ivrJourney.remindObjectMaxNum': 'Um máximo de 20 caracteres podem ser selecionados para o objeto de notificação.',
  'oifde.ivrJourney.batchDetele': 'Eliminação em lote',
  'oifde.ivrJourney.selectSmsGateway': 'Selecione um gateway de SMS.',
  'oifde.ivrJourney.selectMailGateway': 'Selecione um gateway de e-mail.',
  'oifde.ivrJourney.indicatorComparisonBarChart': 'Número de llamadas colgadas después de la liberación',
  'oifde.ivrJourney.indicatorComparisonStatistics': 'Estatísticas de comparação de indicadores',
  'oifde.ivrJourney.beforeRelease': 'Antes da publicação',
  'oifde.ivrJourney.afterRelease': 'Após a publicação',
  'oifde.ivrJourney.beforeReleaseRepeatRate': 'Taxa de reprovação de pré-publicação',
  'oifde.ivrJourney.afterReleaseRepeatRate': 'Taxa de reprovação pós-publicação',
  'oifde.ivrJourney.beforeReleaseHangupNum': 'Desligamentos antes da publicação',
  'oifde.ivrJourney.afterReleaseHangupNum': 'Número de desligamentos após a publicação',
  'oifde.headerTitle.flowManagement': 'Gestão de Processos',
  'oifde.headerTitle.flowConfiguration': 'Configuração do processo',
  'oifde.trackmgr.robotsTrack': 'Robô de rastreamento',
  'oifde.trackmgr.robotsSource': 'Fontes',
  'oifde.maintain.odfsLogOpen': 'Log ODFS ativado',
  'oifde.maintenance.level': 'Nível',
  'oifde.maintenance.startTime': 'Tempo de validade',
  'oifde.maintenance.duration': 'Duração (minutos)',
  'oifde.maintenance.startLog': 'Ativar registro',
  'oifde.maintenance.stop_success': 'Interromper o registro com sucesso',
  'oifde.maintenance.start_success': 'Log ativado com sucesso',
  'oifde.route.transferRecord': 'Registros de transferência',
  'oifde.route.audioVideoResource': 'Recurso de Áudio e Vídeo',
  'oifde.serviceInterface.thirdAPILog': 'Registro de chamada de interface',
  'oifde.serviceInterface.statusCode': 'Código de status',
  'oifde.serviceInterface.invokingTime': 'Tempo de chamada',
  'oifde.serviceInterface.callId': 'ID da chamada',
  'oifde.serviceInterface.invokingDetails ': 'Detalhes da chamada',
  'oifde.title.chooseFlow': 'Escolher fluxo',
  'oifde.tip.inputFlowName': 'Insira um nome de fluxo',
  'oifde.error.createfail': 'Falha ao adicionar dados locais!',
  'oifde.error.gateway.fail': 'Falha ao sincronizar as informações da plataforma CTI! Verifique a configuração do gateway.',
  'oifde.error.NMS.fail': 'Falha na sincronização com o NMS!',
  'oifde.flowname.maxlength': 'O nome do robô é muito longo.',
  'oifde.qiAsrServerMgr.ipOrDomainNameError':'ip ou nome de domínio incorreto',
  'oifde.qiAsrServerMgr.ipError':' Erro de entrada do IP',
  'oifde.qiAsrServerMgr.filePathError':' Formato de caminho incorreto',
  'oifde.qiAsrServerMgr.portTips':' Quando o endereço do servidor ASR é um nome de domínio, a porta não pode ser preenchida.',
  'oifde.asrMgr.qiAsrServerName': 'Servidor ASR de inspeção de qualidade',
  'oifde.mxgraph.new': 'Novo',
  'oifde.audioVideoResource.setLanguage': 'Idioma Editar',
  'oifde.audioVideoResource.preview': 'Pré-visualização',
  'oifde.audioVideoResource.viewReferences': 'Ver referências ',
  'oifde.audioVideoResource.cancelUpdate': 'Cancelar atualização ',
  'oifde.audioVideoResource.updateBefore': 'Antes da atualização ',
  'oifde.audioVideoResource.updateAfter': 'Após a atualização ',
  'oifde.audioVideoResource.templateName': 'Digite o nome do modelo',
  'oifde.audio.speedRange': 'Faixa de valores [- 200, 500]',
  'oifde.route.sysAudio': 'Gerenciamento de voz',
  'oifde.param.maxLength': 'O comprimento do parâmetro não pode exceder ',
  'oifde.tip.oldNode': 'Elementos de diagrama obsoletos não são recomendados',
  'oifde.diagram.element.expired': 'Elemento expirado',
  'oifde.diagram.element.basic': 'Elemento básico',
  'oifde.diagram.element.logic': 'Elemento lógico',
  'oifde.diagram.element.business': 'Elemento de serviço',
  'oifde.diagram.element.composite': 'Elemento composto',
  'oifde.diagram.node.type': 'Tipo de nó',
  'oifde.title.chooseGrayRule': 'Escolher regra cinza',
  'oifde.title.chatbot': 'Bate-papo bot',
  'oifde.audioVideoResource.cancelUpdate.confirm': 'Tem certeza de que deseja cancelar a atualização?',
  'oifde.audioVideoResource.cancelUpdate.success': 'Atualização cancelada com sucesso',
  'oifde.audioVideoResource.cancelUpdate.fail': 'Falha ao cancelar a atualização',
  'oifde.audioVideoResource.upload.tips': 'Dica:Carregue o mesmo arquivo repetidamente\, altere o nome do arquivo ou altere o upload do arquivo.',
  'oifde.robotReply.displayName': 'Resposta do Robô',
  'oifde.robotReply.replyWay': 'Caminho de Resposta',
  'oifde.robotReply.replyWayBreve': 'Caminho',
  'oifde.robotReply.replyResourceBreve': 'Recurso',
  'oifde.robotReply.replyVariableBreve': 'Variável',
  'oifde.robotReply.replyTemplateBreve': 'Modelo',
  'oifde.robotReply.replModeBreve': 'Modo',
  'oifde.robotReply.replyTemplate': 'Modelo de resposta',
  'oifde.robotReply.selectReplyTemplate': 'Selecionar modelo',
  'oifde.robotReply.replyVariable': 'Variável de resposta',
  'oifde.robotReply.replyResource': 'Recurso Responder',
  'oifde.robotReply.selectReplyResource': 'Selecionar recurso',
  'oifde.robotReply.replMode': 'Modo de resposta',
  'oifde.validation.repeats.varName': 'nome da variável duplicada.',
  'oifde.procedure.mysqlUrlRule': 'O formato do URL está incorreto. O formato do URL é jdbc:mysql://ip:port/db_name? prop1=valor1&prop2=valor2',
  'oifde.mxgraph.search': 'Pesquisar',
  'oifde.procedure.oracleUrlRule': 'O formato do URL está incorreto. O formato do URL é jdbc:oracle:thin:{\'@\'}ip:port:SID ou jdbc:oracle:thin:{\'@\'}/ip:port/service',
  'oifde.procedure.gaussUrlRule': 'O formato do URL está incorreto. O formato do URL é jdbc:zenith:{\'@\'}ip:port[? chave=valor[&chave=valor]..]',
  'oifde.chatBotTest.testCaseGroupName.maxLen': 'O nome do grupo de maiúsculas e minúsculas não pode exceder 128 caracteres.',
  'oifde.chatBotTest.testCaseName.maxLen': 'O nome de maiúsculas e minúsculas não pode exceder 128 caracteres.',
  'oifde.chatBotTest.createCase.maxLen': 'O número de casos de teste selecionados não pode exceder 100.',
  'oifde.procedure.selectProcedure': 'Selecione um procedimento armazenado',
  'oifde.procedure.resultProcess': 'Processamento do resultado',
  'oifde.robotReply.title.chooseTemplate': 'Selecionar modelo de resposta',
  'oifde.robotReply.title.chooseResource': 'Selecionar recurso de resposta',
  'oifde.element.varAssign': 'Atribuição de variáveis',
  'oifde.promptType.title': 'Tipo de alerta',
  'oifde.interfaceInvoke.header': 'Cabeçalho da Interface',
  'oifde.interfaceInvoke.body': 'Parâmetro de entrada da interface',
  'oifde.interfaceInvoke.queryParam': 'Parâmetro de consulta da interface',
  'oifde.interfaceInvoke.method.return': 'Valor de retorno do método',
  'oifde.unit.millisecond': 'milissegundos',
  'oifde.title.transData': 'Obtenção de Dados Associados',
  'oifde.robotReply.title.addResource': 'Adicionar recurso de resposta',
  'oifde.mxgraph.NMS_Desciption': 'Descrição',
  'oifde.mxgraph.saveDraft': 'Salvar rascunho',
  'ccivr.nms.conditionService.expression.placeholder':'Insira uma expressão condicional',
  'oifde.common.validation.appSecretLenCheck': 'O comprimento da chave compartilhada deve estar entre 8 e 20',
  'oifde.diagram.error.basicEnd.length': 'Não pode ser maior que 4096!',
  'oifde.template.defaultValueValidate.ttsVolumeValueDesc': 'Intervalo de valores [0, 100]',
  'oifde.tableData.template': 'Baixar modelo de dado ',
  'oifde.tableData.download.template': 'Baixar modelo',
  'oifde.tableData.import.max': 'Importar até 10.000 dados',
  'oifde.tableData.import.tableName': 'Nome da tabela de dados',
  'oifde.tableData.diagram.elementName': 'Tabela de Dados',
  'oifde.tableData.diagram.searchCriteria': 'Critérios de pesquisa',
  'oifde.tableData.diagram.searchPage': 'Página Pesquisar',
  'oifde.tableData.diagram.searchResult': 'Resultado da pesquisa',
  'oifde.tableData.diagram.returnRecords': 'Retornar Registros',
  'oifde.tableData.diagram.total': 'Total',
  'oifde.procedure.db.dbName':'Nome do banco de dados',
  'oifde.procedure.procedureDbName':'Nome do banco de dados',
  'oifde.procedure.dbNameWarning':'O nome do banco de dados suporta apenas letras\, números\, caracteres chineses\, espaços\, sublinhados e aspas simples',
  'oifde.procedure.dbNameError':'O nome do banco de dados de procedimento armazenado não pode ser uma string vazia ou ter mais de 64 bits',
  'oifde.procedure.actions.deleteOnlyOneDb':'Falha ao excluir. Não é possível excluir todas as informações de conexão do banco de dados',
  'oifde.serviceInterface.actions.deleteDbInfo':'Esta operação excluirá as informações do banco de dados selecionado. Deseja continuar?',
  'oifde.procedure.actions.deleteDBFailed':'Falha ao excluir. A conexão de banco de dados foi referenciada pelo procedimento armazenado {0} e não pode ser excluída. Por favor, verifique',
  'oifde.procedure.actions.deleteDBFailed2': 'Falha ao excluir. A conexão de banco de dados foi referenciada por um total de {1} procedimentos armazenados, como {0}, e não pode ser excluída. Por favor, verifique.',
  'oifde.procedure.actions.deleteFailed': 'Falha ao excluir',
  'oifde.procedure.actions.createDBFailed': 'Falha ao criar. O banco de dados atingiu o limite máximo',
  'oifde.procedure.databaseConnection': ' Conexão de banco de dados',
  'oifde.workday.workDayName': 'Nome do Calendário de Trabalho',
  'oifde.workday.workDayDesc': 'Descrição',
  'oifde.workday.workWeekDay': 'Selecção da Semana',
  'oifde.workday.servicePeriod': 'Período de serviço',
  'oifde.workday.specificNonServiceDay': 'Data de Não Serviço Especificada',
  'oifde.workday.specificServiceDay': 'Data Especificada do Serviço',
  'oifde.workday.servicePeriodDesc': 'Período de serviço. Configure 1 a 10 registros.',
  'oifde.workday.specificNonServiceTip': 'Se uma data de não-serviço especificada for configurada, a data de não-serviço será considerada como um dia de não-trabalho com a prioridade mais alta.',
  'oifde.workday.specificServiceTip': 'Se uma data de serviço especificada configurada não estiver disponível na seleção da semana, a data de serviço será considerada como um dia útil.',
  'oifde.workday.name': 'Nome',
  'oifde.workday.time': 'Tempo',
  'oifde.workday.date': 'Data',
  'oifde.workday.addWorkDayTitle': 'Adicionar calendário de trabalho',
  'oifde.workday.editWorkDayTitle': 'Modificar Calendário de Trabalho',
  'oifde.workday.required': 'Campo obrig.',
  'oifde.workday.updateTime': 'Modificado',
  'oifde.workday.operation': 'Operação',
  'oifde.workday.actions.edit': 'Editar',
  'oifde.workday.actions.delete': 'Excluir',
  'oifde.workday.actions.prompt': 'Confirmar',
  'oifde.workday.actions.confirm': 'Sim',
  'oifde.workday.actions.cancel': 'Não',
  'oifde.workday.actions.ok': 'OK',
  'oifde.workday.actions.deletePrompt': 'Esta operação excluirá permanentemente o calendário de trabalho. Tem certeza de que deseja continuar?',
  'oifde.workday.actions.cancelDelete': 'A exclusão foi cancelada.',
  'oifde.workday.actions.deleteSuccess': 'Exclusão bem-sucedida.',
  'oifde.workday.actions.updateSuccess': 'Modificação bem-sucedida.',
  'oifde.workday.actions.createSuccess': 'Criação bem-sucedida.',
  'oifde.workday.actions.missWorkDayName': 'O Nome do Calendário de Trabalho não pode ficar vazio.',
  'oifde.workday.actions.missWorkWeekDay': 'A seleção da semana não pode estar vazia.',
  'oifde.workday.actions.minServicePeriod': 'Pelo menos um registro deve ser configurado para o Período de Serviço.',
  'oifde.workday.actions.missPeriodName': 'O Nome do Período de Serviço não pode ficar vazio.',
  'oifde.workday.actions.missPeriodTime': 'O Tempo do Período de Serviço não pode estar vazio.',
  'oifde.workday.actions.missNonServiceName': 'Nome de data não-serviço especificado não pode estar vazio.',
  'oifde.workday.actions.missNonServiceDate': 'Data de Não Serviço Especificada não pode estar vazia.',
  'oifde.workday.actions.missServiceName': 'Nome de data de serviço especificado não pode estar vazio.',
  'oifde.workday.actions.missServiceDate': 'Data de serviço especificada não pode estar vazia.',
  'oifde.workday.tip.overServicePeriod': 'O número de registros do Período de Serviço não pode exceder 10.',
  'oifde.workday.tip.overNonServiceDate': 'O número de registros de Data de Não Serviço Especificada não pode exceder 50.',
  'oifde.workday.tip.overServiceDate': 'O número de registros de Data de Serviço Especificada não pode exceder 50.',
  'oifde.workday.tip.overWorkDayCount': 'O número de registros do Calendário de Trabalho não pode exceder 1000.',
  'oifde.workday.actions.nameError': 'Todos os nomes são permitidos apenas caracteres chineses, letras inglesas, dígitos, espaços, sublinhados (_), hífens (-) e aspas simples (\').',
  'oifde.tableData.diagram.addCriteria': 'Adicionar grupo',
  'oifde.tableData.diagram.logic': 'lógica',
  'oifde.diagram.message.recoverData': 'Recuperar dados',
  'oifde.diagram.message.recoverDataDesc': 'Deseja renderizar a tela com os dados do processo salvos temporariamente pela última vez?',
  'oifde.button.openVariable': 'Abrir Variável >',
  'oifde.button.closeVariable': 'Recolher Variável <',
  'oifde.varGlobal.builtIn': 'Globais(incorporadas)',
  'oifde.varGlobal.custom': 'Globais',
  'oifde.route.workDay': 'Calendário de trabalho',
  'oifde.dataTable.tableName': 'Nome da tabela',
  'oifde.dataTable.tableId': 'ID da tabela',
  'oifde.dataTable.tableField': 'Campos da Tabela',
  'oifde.dataTable.fieldId': 'ID do campo',
  'oifde.dataTable.fieldName': 'Nome do campo',
  'oifde.dataTable.fieldType': 'Tipo de campo',
  'oifde.dataTable.fieldLength': 'Comprimento do campo',
  'oifde.dataTable.defaultValue': 'Padrão',
  'oifde.dataTable.deleteTips': 'Esta operação excluirá permanentemente esta tabela de dados e seus dados. Continuar?',
  'oifde.dataTable.deleteBatchTips': 'Nenhuma tabela de dados a ser excluída está selecionada no momento',
  'oifde.dataTable.editDataTable': 'Editar tabela de dados',
  'oifde.dataTable.addDataTable': 'Adicionar uma tabela de dados',
  'oifde.dataTable.editDataTableField': 'Editar Campos da Tabela',
  'oifde.dataTable.addDataTableField': 'Novo Campo da Tabela',
  'oifde.dataTable.validation.tableId': 'Somente letras, números e sublinhados são permitidos. Não pode começar com um número',
  'oifde.dataTable.validation.presetField': 'ID, TENANTID, CREATETIME e MODIFYTIME são campos predefinidos e não fazem distinção entre maiúsculas e minúsculas. Não os utilize',
  'oifde.dataTable.fieldsMaxSize': 'O número de campos da tabela não pode exceder 20',
  'oifde.dataTable.fieldsNotEmpty': 'O campo da tabela não pode estar vazio',
  'oifde.dataTable.exceedMaxSize': 'O número de folhas de dados não pode exceder 40',
  'oifde.dataTable.saveAndInsert': 'Salvar e adicionar dados',
  'oifde.mxgraph.NMS_TargetFlow': 'Processo de destino',
  'oifde.mxgraph.NMS_TargetNode': 'Nó de destino',
  'oifde.mxgraph.pattern': 'Padrão',
  'oifde.service.table.dialog.title': 'Dados',
  'oifde.service.table.dialog.selectField': 'Selecionar campo',
  'oifde.service.table.dialog.inputValue': 'Valor do campo de entrada',
  'oifde.service.table.dialog.addRecord': 'Adicionar dados',
  'oifde.service.table.dialog.editRecord': 'Editar dados',
  'oifde.service.table.dialog.integer': 'Apenas inteiros são permitidos.',
  'oifde.service.table.dialog.string': 'O comprimento máximo de um valor do tipo string é 10000.',
  'oifde.service.table.dialog.oneday': 'Último 1 dia',
  'oifde.service.table.dialog.threeday': 'Últimos 3 dias',
  'oifde.service.table.dialog.sevenday': 'Últimos 7 dias',
  'oifde.service.table.dialog.custom': 'Personalizado',
  'oifde.service.table.dialog.batchdelete': 'Confirmar exclusão de lote',
  'oifde.service.table.dialog.delete': 'Confirmar exclusão',
  'oifde.dataTable.criteria': 'Critérios',
  'oifde.dataTable.diagram.fieldName': 'Nome do campo',
  'oifde.dataTable.diagram.fieldValue': 'Valor do Campo',
  'oifde.service.table.import.max.data': 'A quantidade total de dados importados excede 10.000',
  'oifde.service.table.import.file.empty': 'O conteúdo do arquivo lido está vazio',
  'oifde.service.table.import.file.read': 'Falha ao analisar o arquivo',
  'oifde.service.table.import.excute.max': 'Já existe uma tarefa de importação em execução. Por favor, aguarde até que a última importação seja concluída!',
  'oifde.service.table.import.field.error': 'O campo da tabela não existe',
  'oifde.service.table.import.string.max': 'O comprimento do valor do tipo string é maior que 10000',
  'oifde.service.table.import.fieldType.error': 'O tipo e o valor do campo não correspondem',
  'oifde.common.validation.flowSaveIntervalRange': 'O intervalo de temporização da orquestração do processo IVR deve ser um número inteiro positivo. Não pode ser inferior a {0} minutos e não pode ser superior a {1} minutos',
  'oifde.title.chooseGreyRule': 'Escolher regra cinza',
  'oifde.conditionService.param.tips': 'Quando uma expressão condicional contém uma constante de string, é necessário adicionar aspas para funcionar corretamente',
  'oifde.common.btn.batch.operation': 'Operação lote',
  'oifde.char.invalid': 'Caracteres inválidos',
  'oifde.mxgraph.NMS_PleaseSelect': 'Por favor selecione',
  'oifde.service.table.dialog.nodatedelete': 'Nenhum dado selecionado a ser excluído',
  'oifde.service.table.dialog.exceedMaxSize': 'Um máximo de 10000 registros podem ser adicionados',
  'oifde.validate.validChar': 'Intervalo de caracteres permitido: ',
  'oifde.variable.rightList.tips': 'As variáveis exibidas à direita podem ser arrastadas para a caixa de texto da variável.',
  'oifde.service.table.import.field.length.error': 'O comprimento do campo excede o limite',
  'oifde.screenfull.cancel': 'Sair da tela cheia',
  'oifde.variable.ttsSpeedDesc': 'Establezca este parámetro a la velocidad de habla tts de un inquilino para ajustar la velocidad de habla.',
  'oifde.variable.ttsVolumeDesc': 'Puede establecer el volumen tts correspondiente al inquilino y ajustar el volumen.',
  'oifde.variable.ttsPitchDesc': 'Establezca este parámetro a la frecuencia base tts correspondiente del inquilino y ajuste el tono.',
  'oifde.variable.voiceNameDesc': 'Establezca este parámetro al pronunciador TTS del inquilino.',
  'oifde.template.voicename.cn_kefuman_common': 'Servicio al cliente',
  'oifde.common.validation.flowMaxElementsRange': 'O número máximo de elementos suportados pelo processo de IVR é um número inteiro positivo, que não pode ser inferior a 1 e não pode ser superior a 200',
  'oifde.variable.sys.callMediaType': 'Tipo de mídia da chamada',
  'oifde.variable.sys.systemDate': 'Data do Sistema(YYYYMMDD)',
  'oifde.variable.sys.systemMonth': 'Mês do sistema(YYYYMM)',
  'oifde.variable.sys.currentTime': 'Hora atual do sistema(yyyyMMddHHmmss)',
  'oifde.variable.sys.systemTime': 'Hora atual do sistema(HHmmss)',
  'oifde.variable.sys.timeStamp': 'Timestamp, tipo string. Número total de segundos a partir das 00:00:00 GMT de 1 de janeiro de 1970 (08:00:00 GMT de 1 de janeiro de 1970) até o presente.',
  'oifde.variable.sys.createTime': 'Hora de início da chamada',
  'oifde.variable.sys.callingNumber': 'Número do cliente. O número do chamador é indicado ao entrar no contact center da nuvem e o número do cliente é indicado ao sair do contact center da nuvem. Tipo de string. O número é passado pelo processo IVR.',
  'oifde.variable.sys.calledNumber': 'Código de acesso ao processo do sistema. Tipo de string. Este número é passado pelo fluxo de IVR, é o código de acesso do fluxo, você pode fazer login no console de configuração de WAS da plataforma de CTI para ver.',
  'oifde.variable.sys.realCalledNumber': 'Código de acesso, o código de acesso configurado na configuração da parte chamada do fluxo, ou seja, o número da linha direta discada para o fluxo.',
  'oifde.variable.sys.callId': 'Identificador da sessão ODFS, tipo string. Esse identificador é passado pelo processo IVR, ou seja, IVRREQUEST.call_id.',
  'oifde.variable.sys.ctiCallid': 'O callid do CTI da chamada real de entrada do fluxo IVR, ou seja, o parâmetro de solicitação IVRREQUEST.cti_callid do lado IVR.',
  'oifde.variable.sys.tenantId': 'ID do inquilino atual',
  'oifde.variable.sys.language': 'Idioma atual do cliente',
  'oifde.variable.ivrRequest.ctiCallid': 'O callid do CTI da chamada de entrada do Huawei IVR. Em outros cenários, este valor é nulo. Não mais do que 32 bits.',
  'oifde.variable.ivrRequest.input': 'Resultado da interação do diálogo.',
  'oifde.variable.ivrRequest.IACmd': 'Campos dedicados para correspondência inteligente e preenchimento de tíquete inteligente.',
  'oifde.variable.ivrRequest.TextSource': 'Campos dedicados para correspondência inteligente e preenchimento de tíquete inteligente.',
  'oifde.variable.ivrRequest.transinData': 'Parâmetros de dados associados acordados entre a parte de acesso e o OIAP, por exemplo, parâmetros de pacotes de dados recebidos pelo IVR.',
  'oifde.variable.ivrRequest.beginPlay': 'Hora de início da reprodução.',
  'oifde.variable.ivrRequest.endPlay': 'Hora de fim da reprodução.',
  'oifde.variable.ivrRequest.callId': 'Sinalizador exclusivo de chamada, que tem o mesmo valor que userid e é usado pelo processo.',
  'oifde.variable.ivrRequest.interIdx': 'Número de interações, o número de interações consecutivas registradas pelo ODFS com o usuário.',
  'oifde.variable.ivrRequest.feedback': 'Satisfação do feedback: 1 (satisfação); 0 (insatisfação). Tipo inteiro.',
  'oifde.variable.ivrRequest.feedbackContent': 'Motivo de insatisfação do feedback (vazio se o feedback for satisfatório).',
  'oifde.variable.ivrRequest.channelType': 'Tipo de canal de acesso do cliente. Por exemplo, WEB, INSTAGRAM, TELEGRAM, WECHAT, FACEBOOK, TWITTER, EMAIL, LINE, WHATSAPP, 5G, SMS',
  'oifde.variable.ivrRequest.ieValue': 'O assunto dos e-mails recebidos durante o acesso ao canal de e-mail',
  'oifde.variable.toc.ChatBot': 'Parâmetros de slot configurados na intenção',
  'oifde.variable.toc.ChatBotIntentCode': 'Intenções correspondentes ao reconhecimento de voz retornam um código de intenção',
  'oifde.variable.toc.ChatBotRespContent': 'Informações sobre a resposta retornada pela intenção correspondente ao reconhecimento de voz, incluindo o slot e a resposta configurada na resposta.',
  'oifde.variable.toc.ChatBotScenarioName': 'O nome do modelo de intenção que foi correspondido após a execução do reconhecimento de voz.',
  'oifde.variable.toc.ChatBotResponse': 'Identificar tudo na interface',
  'oifde.datatable.open.type': 'Abrir em UTF-8 após a exportação.',
  'oifde.datatable.dateValue.tip': 'Timestamp, em milissegundos. Insira um valor inteiro longo ou de caractere ou variável.',
  'oifde.datatable.intList.tip': 'Exemplo: [1, 2, 3].',
  'oifde.datatable.stringList.tip': 'Exemplo: ["a","b","c"].',
  'oifde.expression.expr_number_varOrValue': 'Insira um valor ou variável do tipo numérico.',
  'oifde.expression.expr_list_varOrValue': 'Insira um valor ou variável do tipo de lista.',
  'oifde.service.table.import.index': 'Linha {0}',
  'oifde.flow.rule.noRule': 'Falha ao localizar a regra de cinza. Verifique se a regra foi excluída.',
  'oifde.cell.viewRefrenceInfo': 'Informações de referência',
  'oifde.cell.viewRefrenceInfo.flow': 'Referência do processo',
  'oifde.cell.viewRefrenceInfo.complexcell': 'Referência do processo',
  'oifde.accessCode.select.max': '{0} registros foram selecionados. Um máximo de {1} registros pode ser selecionado.',
  'oifde.mxgraph.deleteDraft': 'Excluir rascunho',
  'oifde.mxgraph.deleteDraft.confirm': 'Tem certeza de que deseja excluir o rascunho?',
  'oifde.mxgraph.deleteDraft.noDraftExist': 'Não existem dados de rascunho',
  'oifde.resource.needApproval': 'Os recursos de resposta são criados com sucesso e podem ser usados somente após serem aprovados pelo administrador do sistema.',
  'oifde.common.validation.accessCode.description': 'Quebras de linha e os seguintes caracteres especiais não são permitidos: "[]\'&()=;<>,',
  'oifde.tableData.import.tableNameBreve': 'Nome da Tabela',
  'oifde.flow.actions.publish.existDraft': 'O processo atual tem dados de rascunho, edite e publique-os ou ignore o rascunho.',
  'oifde.export.finish.success': 'Exportado com sucesso.',
  'oifde.input.tips.varAndConst': 'Insira uma variável ou constante. Use aspas antes e depois da string.',
  'oifde.export.keyPressRobot.title': 'Exportar Registros de IVR Comuns',
  'oifde.export.password': 'Senha de compactação',
  'oifde.export.password.placeholder': 'Digite a senha de compactação',
  'oifde.export.password.error.tips': 'Digite a senha de 8 a 12 dígitos usada para gerar o arquivo compactado. Deve conter números, letras e caracteres especiais, exceto /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.password.error.message': 'Deve conter números, letras e caracteres especiais, exceto /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.task.status': 'Status da tarefa',
  'oifde.export.task.fail': 'Falha na exportação',
  'oifde.export.businessInterface.title': 'Exportação de Registros de Interface Personalizada',
  'oifde.export.variable.title': 'Exportação de registros variáveis personalizados',
  'oifde.export.complexcell.title': 'Exportar registros de elementos do diagrama composto',
  'oifde.export.accessCode.title': 'Exportação de registros de robôs inteligentes',
  'oifde.export.intelligentIVRFlow.title': 'Exportação de Registros do Processo',
  'oifde.export.intelligentIVRFlow.button': 'Fluxo de exportação',
  'oifde.route.2dDigitalHumanImg': 'Humano Virtual',
  'oifde.route.add2dDigitalHumanImg': 'Criar Humano Virtual',
  'oifde.digitalHuman.outputSetting': 'Configuração de saída',
  'oifde.digitalHuman.standingPosition': 'Posicionamento',
  'oifde.digitalHuman.stationTemplate': 'Modelo de posicionamento',
  'oifde.digitalHuman.preciseAdjustment': 'Ajuste precisamente',
  'oifde.digitalHuman.left': 'Esquerda',
  'oifde.digitalHuman.center': 'Centro',
  'oifde.digitalHuman.right': 'Direita',
  'oifde.digitalHuman.imageProportion': 'Relação da imagem',
  'oifde.exportdata.message.overData': 'A exportação excedeu o limite máximo de 10.000. Por favor, selecione novamente os critérios de pesquisa.',
  'oifde.flow.title.publishFlow': 'Fluxo de liberação',
  'oifde.flow.title.upgrade': 'Fluxo de atualização',
  'oifde.flow.action.editInfo': 'Editar informações',
  'oifde.flow.title.editInfo': 'Editando Informações Básicas de Fluxo',
  'oifde.robot.title.editInfo': 'Editando Informações Básicas do Chatbot',
  'oifde.input.placeholder.searchName': 'Pesquisar nome',
  'oifde.avatar.tenantId': 'ID do locatário',
  'oifde.avatar.tenantSpace':'Espaço do inquilino',
  'oifde.avatar.avatarName':'Nome da função',
  'oifde.avatar.avatarCode':'Código da função',
  'oifde.avatar.headerImageUrl':'Image',
  'oifde.avatar.serverAddress':'Endereço do servidor',
  'oifde.avatar.avatarAppKey':'Nome de usuário/AK do servidor',
  'oifde.avatar.create':'Criar serviço humano virtual',
  'oifde.avatar.update':'Editar serviço humano virtual',
  'oifde.avatar.headerImage': 'Imagem do perfil',
  'oifde.avatar.poseImage': 'Pose imagem',
  'oifde.avatar.actionList':'Lista de ação',
  'oifde.avatar.form.message': 'Informação humana virtual 2D',
  'oifde.avatar.service.message':'Informações do servidor',
  'oifde.avatar.serverPort':'Porta do servidor',
  'oifde.avatar.userName':'Nome de usuário do servidor',
  'oifde.avatar.pwd':'Senha do servidor',
  'oifde.avatar.checkPwd':'Confirmar senha do servidor',
  'oifde.avatar.resolution': 'Resolução',
  'oifde.avatar.imgRule': 'Somente os formatos JPG, PNG e JPEG são suportados. O tamanho do arquivo não pode exceder 2 MB.',
  'oifde.avatar.action_1': 'Saudações',
  'oifde.avatar.action_2': 'Mãos para a frente',
  'oifde.avatar.action_3': 'Nod',
  'oifde.avatar.action_4': 'Mãos entrelaçadas',
  'oifde.avatar.action_5': 'Espalhe sua mão esquerda',
  'oifde.avatar.action_6': 'Espalhe sua mão direita',
  'oifde.avatar.selectTitle': 'Selecione uma imagem de avatar',
  'oifde.digitalHuman.imgLimit': 'Somente arquivos de imagem JPG, PNG e JPEG são suportados. O tamanho do arquivo não pode exceder 2 MB.',
  'oifde.digitalHuman.imageProportion0-5': '0.5',
  'oifde.digitalHuman.imageProportion0-75': '0.75',
  'oifde.digitalHuman.imageProportion1': '1',
  'oifde.digitalHuman.canvasNoSupport': 'O navegador atual não suporta canvas. Atualize ou mude o navegador primeiro.',
  'oifde.digitalHuman.noRoleData': 'Nenhum dado de função. Entre em contato com o pessoal da operação para adicionar os dados da função.',
  'oifde.digitalHuman.sizeError': 'Resolução de imagem incorreta. Carregue uma imagem de fundo no formato de {0} x {1}.',
  'oifde.title.add.complexCell': 'Novo elemento composto',
  'oifde.title.add.complexCellCatalog': 'Novo Catálogo de Elementos Compostos',
  'oifde.title.edit.complexCellCatalog': 'Editar catálogo de elementos compostos',
  'oifde.title.add.rule': 'Nova regra',
  'oifde.title.edit.rule': 'Editar regra',
  'oifde.ivrJourney.pleaseSelectData': 'Selecione os dados',
  'oifde.digitalHuman.needRole': 'Selecione uma função.',
  'oifde.digitalHuman.actions.deletePrompt': 'Esta operação apagará permanentemente o humano virtual. Continuar?',
  'oifde.avatar.pleaseSelectData': 'Os dados modificados não serão salvos após o fechamento. Tem certeza de que deseja continuar?',
  'oifde.digitalHuman.actions.savePrompt': 'Esta operação irá atualizar o humano virtual. Continuar?',
  'oifde.avatar.validation.ttsSoundSpeed': 'Insira uma casa decimal entre 0,5 e 1,5.',
  'oifde.avatar.validation.scale': 'Insira uma casa decimal entre 0,5 e 1,0.',
  'oifde.digitalHuman.coordinate': 'Coordenar',
  'oifde.digitalHuman.dimensions': 'Dimensões',
  'oifde.digitalHuman.imgTypeError': 'Tipo incorreto. Somente os formatos JPG, PNG e JPEG são suportados.',
  'oifde.mxgraph.background': 'Fundo',
  'oifde.expression.expr_var_flow': 'Insira uma variável de processo começando com FLOW',
  'oifde.expression.expr_string_or_var': 'Insira um valor do tipo de variável ou cadeia de caracteres. Use aspas duplas antes e depois da string.',
  'oifde.common.actions.deleteConfig': 'Esta operação excluirá permanentemente a configuração. Tem certeza de que deseja continuar?',
  'oifde.expression.numberCharStartWith0': 'Use aspas duplas antes e depois de uma cadeia numérica começando com 0. Por exemplo, 05 deve ser inserido como "05". Caso contrário, o valor é considerado como 5.',
}
