import { defineStore } from 'pinia'
import { allRouterMap } from '@oifde/router'
import { router } from '@/router'

/**
 * 判断是否有权限
 * @param subMenus
 * @param route
 */
function hasPermission(subMenus, route) {
  if (route.meta && route.meta.menuId) {
    return subMenus.includes(route.meta.menuId)
  } else {
    return true
  }
}

/**
 * 获取菜单id扁平化列表
 */
function getOifdeMenus(menus) {
  const oifdeMenus = menus

  function collectSubMenus(subMenus, menus) {
    menus.forEach(menu => {
      if (menu.children) {
        collectSubMenus(subMenus, menu.children)
      } else {
        subMenus.push(menu.id)
      }
    })
  }

  const subMenus = []
  if (oifdeMenus) {
    collectSubMenus(subMenus, oifdeMenus)
  }

  return subMenus
}

function getFirstChild(route, oifdeMenus) {
  if (!route.children) {
    // 如果route为子节点，返回undefined
    return undefined
  }

  return route.children.find(child => {
    return hasPermission(oifdeMenus, child) && !child.hidden
  })
}

// 直接import('路径')会导致动态获取异常
function getViews(path) {
  // 先全部获取动态路由的组件地址
  let modules = import.meta.glob('../views/**/*.vue')
  return modules[`../views/${path}.vue`]
}

function filterAsyncRouter(routes, oifdeMenus, menus) {
  const res = []

  routes.forEach(route => {
    // saas模式，流程转接记录&音视频资源的菜单不放在odfs菜单中
    const isSaas = localStorage.getItem("isIndependentDeployment") === 'false'
    if (isSaas && (route.name === 'oifdeTransferRecord' || route.name === 'oifdeAudioVideoResource')) {
      return
    }
    const tmp = { ...route }
    if (hasPermission(oifdeMenus, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRouter(tmp.children, oifdeMenus, menus)
        const hasAuthMenu = tmp.children.some(route => {
          return !route.hidden
        })
        if (hasAuthMenu) {
          const firstChild = getFirstChild(tmp, oifdeMenus)
          if (tmp.redirect && firstChild) {
            tmp.redirect = firstChild.path
          }
          res.push(tmp)
        }
      } else {
        res.push(tmp)
      }
    }
  })

  return res
}

export const useOifdePermissionStore = defineStore('oifdePermission', {
  state: () => {
    return {
      routes: [],
      menuOpeneds: [], // 提供给oifde-menu，用于打开默认菜单sub
      firstRoute: {}, // 第一个菜单页面，也是进入oifde后默认打开的页面，同时提供给oifde-menu，用于菜单默认选中高亮
    }
  },
  actions: {
    generateRoutes(menus, redirectRoutePath = '') {
      return new Promise((resolve) => {
        const oifdeMenus = getOifdeMenus(menus)
        const accessedRouters = filterAsyncRouter(allRouterMap, oifdeMenus, menus)
        this.routes = accessedRouters
        this.routes.forEach(item => {
          router.addRoute('oifde', item)
        })
        if (redirectRoutePath) { // 如果传入了需要重定向的路由，则优先使用
          this.firstRoute = { path: redirectRoutePath }
          this.menuOpeneds = [redirectRoutePath]
        } else if (this.routes && this.routes.length) { // 否则默认使用打开第一个路由
          this.firstRoute = this.routes[0].children[0]
          this.menuOpeneds = [this.routes[0].path]
        }
        resolve(this.firstRoute)
      })
    }
  }
})