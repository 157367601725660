export default {
  "ikbs.constableTmp.config" : "Configurer",
  "ikbs.constableTmp.status.draft" : "Ébauche",
  "ikbs.cntTmp.import.rowNumOver" : "Un maximum de 10 enregistrements peuvent être importés.",
  "ikbs.column.unlock.message.warning" : "Informations",
  "ikbs.knowledge.label.abstract" : "Abstrait",
  "ikbs.properties.add.information" : "Ajouter un attribut étendu",
  "ikbs.contTmp.exception.maxCountPerTenant" : "Un locataire peut avoir un maximum de 500 modèles.",
  "ikbs.knowledge.properties.operation.upMove" : "Vers le haut",
  "ikbs.column.delete.selectNoColumnKngToDelete" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.tips.canNotAddColumnKngOnHistoryColumn" : "Les connaissances ne peuvent pas être ajoutées à la catégorie histoire.",
  "ikbs.knowledge.properties.label.inputWay" : "Type d'entrée",
  "ikbs.contentTmp.exception.tmpContentAndEditStandard" : "Le contenu du modèle et les spécifications d'édition ne peuvent pas être vides en même temps",
  "ikbs.knowledge.label.uploadSingleFile" : "Une seule pièce jointe peut être téléchargée à la fois.",
  "ikbs.knowledge.tip.operateSuccess" : "Opération réussie.",
  "ikbs.constableTmp.operator.lt" : "moins de",
  "ikbs.enter.search.condition" : "Veuillez entrer la condition de recherche",
  "ikbs.search.label.sortByDefault" : "Tri par défaut",
  "ikbs.knowledge.recycle.coluKngType" : "Type",
  "ikbs.consult.table" : "Table de consultation",
  "ikbs.knowledge.label.path" : "Chemin",
  "ikbs.column.release.message.fail" : "La publication a échoué.",
  "ikbs.column.knowledge.columnInformation" : "Ajouter une catégorie",
  "ikbs.constableTmp.operator.le" : "inférieur ou égal à",
  "ikbs.knowledge.service.common" : "Fréquent",
  "ikbs.knowledge.recycle.select.knowledgeType" : "Type de connaissance",
  "ikbs.tips.propertyDictValueAtLeastOne.warning" : "L'attribut étendu doit avoir au moins une valeur de dictionnaire.",
  "ikbs.column.displayflag.hide" : "Non",
  "ikbs.column.knowledge.button.add" : "Nouveau",
  "ikbs.column.knowledge.modify.properties" : "Attributs de modification par lots",
  "ikbs.knowledge.release.public" : "Publier directement",
  "ikbs.knowledge.label.selectProperty" : "Sélectionner",
  "ikbs.contentTmp.status.draft" : "Ébauche",
  "ikbs.knowledge.label.serviceStatus" : "Statut de l'entreprise",
  "ikbs.knowledge.service.update" : "Actualisation des affaires",
  "ikbs.column.serviceStatus.newService" : "Nouvelles affaires",
  "ikbs.knowledge.label.answer" : "Réponse",
  "ikbs.constableTmp.reset" : "Réinitialiser",
  "ikbs.knowledge.label.modifyTime" : "Dernière mise à jour le",
  "ikbs.column.type.historyMark" : "Histoire",
  "ikbs.kngCnt.cntTmp.icon.tip" : "Modèle de contenu",
  "ikbs.knowledge.label.currentVersion" : "Version actuelle",
  "ikbs.columntype.column" : "Catégorie",
  "ikbs.enter.search.keywords" : "Entrez un mot-clé de recherche",
  "ikbs.contentTmp.delete.fail" : "Échec de la suppression",
  "ikbs.column.label.coluKngMaintaining" : "groupe de maintenance",
  "ikbs.knowledge.label.kngAttachmentDownload" : "Télécharger la pièce jointe de la catégorie de connaissances",
  "ikbs.search.label.updateEndTime" : "Heure de fin de mise à jour",
  "ikbs.knowledgeaudit.label.pass" : "Approuver",
  "ikbs.knowledge.tip.selectColumn" : "Sélectionnez une catégorie.",
  "ikbs.constableTmp.searchFlag" : "Critère de recherche ou non",
  "ikbs.properties.add.cancel" : "Annuler",
  "ikbs.column.needAudit.yes" : "Oui",
  "ikbs.knowledge.properties.operation.enable" : "Activer",
  "ikbs.column.type.normal" : "Catégorie normale",
  "ikbs.knowledge.label.passProcecWay" : "À l'expiration",
  "ikbs.column.knowledge.MoveType" : "Emplacement d'ajustement",
  "ikbs.confirm.delete.by.select" : "Êtes-vous sûr de vouloir supprimer l'enregistrement sélectionné",
  "ikbs.knowledge.label.relaKngs" : "Association du savoir",
  "ikbs.constableTmp.fieldName" : "Champ",
  "ikbs.column.copy.message.warning" : "Informations",
  "ikbs.column.deletePermanently.message.comfirm" : "Confirmer",
  "ikbs.column.moveto.front" : "Avant de",
  "ikbs.kbshome.button.orderByTime" : "Temps",
  "ikbs.constableTmp.moveDown" : "En bas",
  "ikbs.tips.canNotAddKngOnRootColumn" : "La connaissance ne peut pas être ajoutée à la catégorie racine.",
  "ikbs.column.knowledge.button.up" : "Vers le haut",
  "ikbs.constableTmp.modify" : "Modifier",
  "ikbs.constableTmp.operator.gt" : "plus grande",
  "ikbs.constableTmp.operator.include" : "inclure",
  "ikbs.knghisverman.label.revert" : "Restaurer",
  "ikbs.constableTmp.plsSelectModifyBeginTime" : "Définir l'heure de début de la modification",
  "ikbs.knowledgeaudit.label.submitter" : "Soumis par",
  "ikbs.column.label.kngExtended" : "Attribut étendu",
  "ikbs.constableTmp.operator.ge" : "supérieur ou égal à",
  "ikbs.knowledge.label.listProp" : "Liste déroulante",
  "ikbs.knowledgeaudit.title.auditor" : "Revu par",
  "ikbs.knowledge.recycle.pleaseSelect" : "-Sélectionner-",
  "ikbs.constableTmp.tab.addTitle" : "Ajout de modèle",
  "ikbs.knghisverman.tab.title.confirm" : "Confirmer",
  "ikbs.column.knowledge.button.move" : "Déplacer",
  "ikbs.favorite.msg.plsSelectDir" : "Sélectionnez le répertoire à supprimer.",
  "ikbs.knowledge.tip.fileNameError" : "Impossible de vérifier le nom du fichier.",
  "ikbs.knowledge.message.uploadImgSizeError" : "La taille de l'image ne peut pas dépasser 10 Mo.",
  "ikbs.knowledge.display.no" : "Non",
  "ikbs.properties.inputway.select" : "Liste déroulante",
  "ikbs.favorite.title.afterModifiedKngName" : "Après le changement",
  "ikbs.contentTmp.status" : "Statut du modèle",
  "ikbs.column.topMove.message.warning" : "Informations",
  "ikbs.constableManage.fieldOprator" : "Opérateur",
  "ikbs.constableTmp.modifyEndTime" : "Heure de fin de modification",
  "ikbs.cntTmp.import.noData" : "Le fichier téléchargé ne contient pas de données à importer.",
  "ikbs.addtype.label.knowledge" : "Ajouter des connaissances",
  "ikbs.contentTemp.export.tempname" : "Nom du modèle",
  "ikbs.constableTmp.operator.eq" : "égale à",
  "ikbs.knowledge.tip.success" : "Succès",
  "ikbs.knowledge.label.radioProp" : "Boîtier radio",
  "ikbs.intelligent.search" : "Recherche intelligente",
  "ikbs.contentTmp.contentName" : "Nom du modèle",
  "ikbs.kbshome.tab.home" : "Page d'accueil de la base de connaissances",
  "ikbs.column.passProcessWay.mark" : "Marquer",
  "ikbs.column.modify.modifyValidTimeCheckError" : "L'heure de début de la période de validité ne peut être postérieure à l'heure de fin de la période de validité",
  "ikbs.constableTmp.delete.selectTmp.confirm" : "Êtes-vous sûr de vouloir supprimer ?",
  "ikbs.knowledge.recycle.beyondColuPath" : "Annuaire",
  "ikbs.favorite.msg.canNotSelectRoot" : "Le répertoire racine ne peut pas être supprimé. Sélectionnez-en un autre.",
  "ikbs.column.knowledge.button.modify" : "Modifier",
  "ikbs.knowledgeaudit.message.plsSelectBeginTime" : "Sélectionnez l'heure de début de la tâche.",
  "ikbs.contentTmp.tab.addTitle" : "Ajout de modèle",
  "ikbs.column.clearRecycle.comfirm" : "Êtes-vous sûr de vouloir vider la corbeille?",
  "ikbs.knowledgeaudit.message.plsSelectEndTime" : "Sélectionnez l'heure de fin de tâche.",
  "ikbs.contTmp.importTmp.popup.title" : "Importation de modèles",
  "ikbs.knowledge.label.uploadFileTypeError" : "La pièce jointe ne peut être qu'au format DOC, DOCX, PPT, PPTX, XLS, XLSX, PDF, PNG, JPG ou GIF.",
  "ikbs.column.modify.selectModifyColumnKngNumError" : "Un seul élément de connaissance peut être sélectionné pour modification.",
  "ikbs.column.knowledge.create" : "Nouveau",
  "ikbs.confirm.delete" : "Êtes-vous sûr de vouloir supprimer cet enregistrement",
  "ikbs.column.operation.message.warning" : "Informations",
  "ikbs.contentTmp.copy" : "Copier",
  "ikbs.contentTmp.modifyEndTime" : "Fin modifiée",
  "ikbs.column.label.passProcessWay" : "À l'expiration",
  "ikbs.knowledgeaudit.label.auditStatus" : "Statut",
  "ikbs.favorite.tab.message.isDeleteFavoriteKng" : "Êtes-vous sûr de vouloir supprimer l'élément de connaissance des favoris?",
  "ikbs.select.consultation.form" : "Veuillez sélectionner une table de consultation",
  "ikbs.knowledge.label.sendEmail" : "Envoyer un e-mail",
  "ikbs.tips.operation.success" : "Opération réussie.",
  "ikbs.column.knowledge.KngStatus" : "Statut",
  "ikbs.contentTmp.add" : "Ajouter",
  "ikbs.properties.edit.information" : "Modifier l'attribut étendu",
  "ikbs.column.knowledge.title.moveTo" : "Ajuster à",
  "ikbs.column.knowledge.button.more" : "Plus",
  "ikbs.knowledge.properties.operation.downMove" : "En bas",
  "ikbs.kbshome.label.kngBase" : "Base de connaissances",
  "ikbs.confirm.delete.by.condition" : "Êtes-vous sûr de vouloir supprimer des enregistrements basés sur des conditions",
  "ikbs.column.serviceStatus.normal" : "Fréquent",
  "ikbs.constableTmp.comfirm" : "Confirmer",
  "ikbs.tips.moveColumnKnowledgeOperation.warning" : "Aucune catégorie n'est sélectionnée.",
  "ikbs.column.serviceStatus.updateService" : "Actualisation des affaires",
  "ikbs.knowledge.tip.cannotCompare" : "Seuls deux éléments de connaissances communes peuvent être sélectionnés à des fins de comparaison",
  "ikbs.cntTmp.import.importSuccess" : "Succès d'importation.",
  "ikbs.column.label.coluType" : "Catégorie Type",
  "ikbs.knowledge.label.submit" : "Envoyer",
  "ikbs.knowledge.message.uploadFileSizeError" : "La taille du fichier ne peut pas dépasser 10 Mo.",
  "ikbs.knowledge.label.deleteProblem" : "Supprimer les questions et réponses",
  "ikbs.knowledge.recycle.select.columnType" : "Catégorie Type",
  "ikbs.column.delete.property.comfirm" : "Êtes-vous sûr de vouloir supprimer l'attribut ?",
  "ikbs.knowledge.placeholder.keyword" : "Entrez les mots-clés qui sont séparés par des points-virgules (;).",
  "ikbs.kngCnt.cntTmp.popup.title" : "Sélectionner le modèle de contenu",
  "ikbs.knowledge.properties.operation.delete" : "Supprimer",
  "ikbs.column.knowledge.compareAdd" : "Nouveau",
  "ikbs.knowledgeaudit.label.waitForAudit" : "Examen en attente",
  "ikbs.constableTmp.cnstTblTmpDesc" : "Description du modèle",
  "ikbs.column.label.colukngname" : "Nom de la catégorie",
  "ikbs.knowledgeaudit.label.batchAuditReject" : "Rejet par lot",
  "ikbs.constableTmp.display.no" : "Non",
  "ikbs.title.tips" : "Informations",
  "ikbs.column.delete.selectNoColumn" : "Aucune catégorie n'est sélectionnée.",
  "ikbs.column.knowledge.title.move" : "Déplacer",
  "ikbs.column.isInheritProp.no" : "Non",
  "ikbs.column.message.plsSelectModifyEndTime" : "Sélectionnez une heure de fin.",
  "ikbs.column.label.servicesVaildStartTime" : "Début de la période de validité de l'entreprise",
  "ikbs.knowledge.properties.operation.edit" : "Modifier",
  "ikbs.knowledge.pass.mark" : "Marquer",
  "ikbs.column.modify.modifyServiceTimeCheckError" : "Le début de la période de validité de l'entreprise ne peut être antérieur à sa fin.",
  "ikbs.column.modify.properties.batch.message.warning" : "Informations",
  "ikbs.cntTmp.import.error.oversize" : "La taille du fichier ne peut pas dépasser 20 Mo.",
  "ikbs.knowledgeaudit.label.cancel" : "Annuler",
  "ikbs.search.label.keyword" : "Mot-clé",
  "ikbs.knghisverman.tab.message.deleteSuccess" : "Suppression réussie.",
  "ikbs.search.label.sortByClick" : "Clics",
  "ikbs.knowledge.tip.fileInjectionError" : "Impossible de vérifier le contenu du fichier.",
  "ikbs.knowledge.label.correlative" : "Associée",
  "ikbs.column.needAudit.no" : "Non",
  "ikbs.column.unlock.message.success" : "Déverrouiller avec succès.",
  "ikbs.konwledgetype.question" : "Connaissance des questions et réponses",
  "ikbs.knowledge.service.timeout" : "Expiré",
  "ikbs.column.topMove.message.success" : "Épinglé avec succès.",
  "ikbs.contentTmp.Modifier" : "Modifié par",
  "ikbs.knowledgeaudit.label.error" : "Erreur",
  "ikbs.knowledge.exception.AnswerNum.error" : "Une question ne peut pas avoir plus de 10 réponses.",
  "ikbs.knowledgeaudit.title.submitTime" : "Soumis le",
  "ikbs.column.label.colukngpath" : "Catégorie Chemin",
  "ikbs.knowledge.label.saveAndNext" : "Sauvegarder et NextStep",
  "ikbs.constableTmp.save" : "Enregistrer",
  "ikbs.knghisverman.label.operation" : "Opération",
  "ikbs.column.label.summaryInfo" : "Résumé",
  "ikbs.knowledge.recycle.button.reset" : "Réinitialiser",
  "ikbs.knowledge.recycle.button.delete" : "Supprimer",
  "ikbs.constableTmp.status.invalid" : "Écarté",
  "ikbs.cntTmp.import.contTmpNameEditStandEmpty" : "Le contenu du modèle et les spécifications d'édition ne peuvent pas être vides en même temps.",
  "ikbs.column.knowledge.button.copy" : "Copier",
  "ikbs.knowledge.properties.properties.inputWay" : "Type d'entrée",
  "ikbs.column.knowledge.compareModified" : "Différents",
  "ikbs.constableTmp.innerFieldFlag" : "Champ intégré",
  "ikbs.column.passProcessWay.move" : "Passer à l'historique",
  "ikbs.knowledge.msg.fileUpload" : "Télécharger le fichier à la connaissance",
  "ikbs.column.unlock.message.failed" : "Échec du déverrouillage.",
  "ikbs.cntTmp.import.editStandardOversize" : "Les spécifications d'édition ne peuvent pas dépasser 400 caractères.",
  "ikbs.contentTmp.save" : "Enregistrer",
  "ikbs.knowledge.check.kngName" : "Le nom de la connaissance ou le nom de la colonne ne peut pas contenir les characters: /\\:*?<>| spéciales suivantes",
  "ikbs.contentTmp.tab.copyTitle" : "Duplication",
  "ikbs.column.downMove.message.fail" : "Impossible de descendre.",
  "ikbs.column.knowledge.compareDel" : "Indisponible",
  "ikbs.knowledge.service.hot" : "Chaud",
  "ikbs.contentTmp.modify.fail" : "Échec de la modification",
  "ikbs.knowledge.label.servicesStartTime" : "Début de la période de validité de l'entreprise",
  "ikbs.constableTmp.modifyTime" : "Modifié à",
  "ikbs.knowledge.index.no" : "Non",
  "ikbs.kbshome.label.ClickList" : "La plupart des clics",
  "ikbs.knowledge.properties.properties.status" : "Statut",
  "ikbs.knowledge.properties.dictValue" : "Valeur du dictionnaire",
  "ikbs.column.delete.message.warning" : "Informations",
  "ikbs.column.delete.message.fail" : "La suppression a échoué.",
  "ikbs.constableTmp.cnstTblName" : "Table de consultation",
  "ikbs.cntTmp.import.error.importType" : "Sélectionnez un mode d'importation.",
  "ikbs.contTmp.import.popup.title" : "Importer des modèles",
  "ikbs.column.title.selectUser" : "Sélectionner un utilisateur",
  "ikbs.column.recycle.noSelectRecycles" : "Sélectionnez un enregistrement.",
  "ikbs.knowledge.label.servicesEndTime" : "Fin de la période de validité de l'entreprise",
  "ikbs.knghisverman.label.modifier" : "Modifié par",
  "ikbs.cntTmp.import.result" : "Importation des modèles de contenu terminée. Nombre total {0}, nombre de réussites {1}, nombre d'échecs {2}.",
  "ikbs.cntTmp.export.crosspage.info" : "L'exportation de pages croisées n'est pas prise en charge. Seules les données sélectionnées sur la page courante peuvent être exportées.",
  "ikbs.column.knowledge.expired" : "Expiré",
  "ikbs.knowledgeaudit.title.auditTime" : "Évalué le",
  "ikbs.knowledge.tip.uploadFailed" : "Le téléchargement a échoué.",
  "ikbs.column.knowledge.CurVersion" : "Version source",
  "ikbs.content.upload.file.Frequent" : "Trop de demandes d'API, veuillez réessayer plus tard.",
  "ikbs.knowledge.check.keyword" : "Les mots-clés ne peuvent pas contenir de caractères spéciaux.",
  "ikbs.knowledge.tip.fileTypeError" : "Impossible de vérifier le type de fichier.",
  "ikbs.column.upMove.message.warning" : "Informations",
  "ikbs.contentTmp.modifyStartTime" : "Début modifié",
  "ikbs.knowledgeaudit.label.taskEndTime" : "Heure de fin de tâche",
  "ikbs.constableTmp.modifyBeginTime" : "Heure de début de la modification",
  "ikbs.knowledge.lable.copy" : "Dupliquer",
  "ikbs.knowledgeaudit.label.batchAuditPass" : "Approuver par lot",
  "ikbs.contentTmp.delete" : "Supprimer",
  "ikbs.knowledge.tab.title" : "Compilation de connaissances",
  "ikbs.knowledge.recycle.label.knowledgeType" : "Type",
  "ikbs.column.knowledge.Type" : "Type",
  "ikbs.kbshome.button.thirtyDayList" : "30 Jours",
  "ikbs.cntTmp.import.queryContTempErr" : "Impossible d'interroger le modèle de contenu.",
  "ikbs.favorite.table.delete" : "Supprimer",
  "ikbs.column.move.message.warning" : "Informations",
  "ikbs.knowledge.title.propTitle" : "Nom et prénom",
  "ikbs.constableTmp.modify.selectedOne" : "Un seul modèle peut être sélectionné",
  "ikbs.knowledge.label.uploadAttachment" : "Télécharger la pièce jointe",
  "ikbs.knowledge.pass.move" : "Passer à l'historique",
  "ikbs.favorite.title.warning" : "Informations",
  "ikbs.kbshome.label.favorite" : "Raccourcis",
  "ikbs.knowledge.label.deleteProperty" : "Supprimer",
  "ikbs.column.knowledge.button.publish" : "Publier",
  "ikbs.column.resumeRecycle.comfirm" : "Êtes-vous sûr de vouloir le restaurer ?",
  "ikbs.constableTmp.status.valid" : "Activé",
  "ikbs.knowledgeaudit.label.reject" : "Rejeter",
  "ikbs.contentTmp.export" : "Exporter",
  "ikbs.properties.operation.message.fail" : "Échec",
  "ikbs.knowledge.label.validEndTime" : "Fin de la période de validité",
  "ikbs.knowledge.label.preStep" : "Pré-étape",
  "ikbs.properties.add.save" : "OK",
  "ikbs.knowledgeaudit.label.query" : "Requête",
  "ikbs.knowledge.tip.keywordCountError" : "Un maximum de mots-clés {count} sont pris en charge.",
  "ikbs.favorite.table.edit" : "Modifier",
  "ikbs.knowledge.label.addKngPoint" : "ajouter une question",
  "ikbs.knowledge.label.creatorName" : "Préparé par",
  "ikbs.knowledge.tip.operateFailed" : "L'opération a échoué.",
  "ikbs.knghisverman.tab.message.isDeleteHisVer" : "Êtes-vous sûr de vouloir supprimer cette version historique ?",
  "ikbs.favorite.title.addDirectory" : "Ajouter un répertoire",
  "ikbs.knowledge.label.type" : "Type",
  "ikbs.column.Move.message.success" : "Déplacement réussi.",
  "ikbs.column.knowledge.update" : "Mise à jour",
  "ikbs.search.label.sortByTime" : "Temps",
  "ikbs.knowledge.label.summaryInfo" : "Résumé",
  "ikbs.properties.detail.information" : "Détails de l'attribut étendu",
  "ikbs.column.release.message.success" : "Publication réussie.",
  "ikbs.knowledge.label.ok" : "OK",
  "ikbs.knowledge.label.upload" : "Téléchargement",
  "ikbs.topMove.selectTopMoveColumnKngNumError" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.constableTmp.display.yes" : "Oui",
  "ikbs.column.modify.properties.batch.message.error" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.cntTmp.import.failReason" : "Impossible de télécharger l'enregistrement {0} ; cause de l'échec : {1}",
  "ikbs.knowledge.properties.properties.title" : "Nom et prénom",
  "ikbs.knowledgeaudit.label.auditor" : "Revu par",
  "ikbs.cntTmp.export.exportNum.blank" : "Sélectionnez les modèles de contenu à exporter.",
  "ikbs.contentTmp.status.valid" : "Valide",
  "ikbs.column.modify.selectModifyColumnKngNumZERO" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.column.tip.success" : "Informations",
  "ikbs.knowledge.tip.relaKngCountError" : "Un maximum de {count} éléments de connaissance associés sont pris en charge.",
  "ikbs.contentTemp.export.tempcontent" : "Contenu du modèle",
  "ikbs.contentTmp.query" : "Requête",
  "ikbs.properties.status.draft" : "Ébauche",
  "ikbs.column.knowledge.ModifyTime" : "Modifié le",
  "ikbs.knowledgeaudit.title.status" : "Statut",
  "ikbs.column.knowledge.compare" : "Comparer",
  "ikbs.knowledge.label.uploadFileSizeError" : "La pièce jointe ne peut pas dépasser 10 Mo.",
  "ikbs.cntTmp.import.invalidData" : "Données non valides.",
  "ikbs.column.delete.message.success" : "Suppression réussie.",
  "ikbs.favorite.tree.favoriteRootName" : "Favoris",
  "ikbs.column.moveTo.selectColumnKngNumError" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.cntTmp.export.error.syserr" : "Impossible d'exporter le fichier de modèle de contenu.",
  "ikbs.column.button.confirm" : "Envoyer",
  "ikbs.constableTmp.delete.noSelectTmp" : "Aucun modèle n'est sélectionné",
  "ikbs.content.transfer.html.success" : "Impossible de convertir le contenu en HTML.",
  "ikbs.column.modify.modifyValidServiceEndTimeCheckError" : "La fin de la période de validité de l'entreprise ne peut être postérieure à la fin de la période de validité.",
  "ikbs.knowledge.properties.label.propertiesTitle" : "Nom et prénom",
  "ikbs.knowledge.label.addAnswer" : "Ajouter une réponse",
  "ikbs.column.delete.message.comfirm" : "Confirmer",
  "ikbs.column.button.search" : "Requête",
  "ikbs.knghisverman.label.modifyTime" : "Modifié le",
  "ikbs.select.record.to.delete" : "Veuillez sélectionner l'enregistrement à supprimer",
  "ikbs.knowledgeaudit.label.submitterOrAuditor" : "Soumis par/Évalué par",
  "ikbs.constableManage.fieldValue" : "Valeur",
  "ikbs.column.history.down.empty" : "Impossible d'ouvrir la colonne parce qu'elle n'a pas de colonne ou de connaissances",
  "ikbs.constableTmp.name" : "Nom du modèle",
  "ikbs.knowledgeaudit.label.pleaseSelect" : "-Sélectionner-",
  "ikbs.cntTmp.import.syserr" : "Impossible d'importer le modèle de contenu.",
  "ikbs.column.copy.selectCopyColumnKngNumError" : "Un seul élément de connaissance peut être sélectionné pour la copie.",
  "ikbs.contentTmp.status.invalid" : "Invalide",
  "ikbs.column.message.ids.max.size" : "Le nombre d'éléments pour l'opération par lots ne peut pas dépasser 100.",
  "ikbs.knowledge.properties.label.query" : "Requête",
  "ikbs.kngCnt.cntTmp.importType.new" : "Créer directement",
  "ikbs.column.upMove.message.success" : "A déménagé avec succès.",
  "ikbs.content.transfer.html.fail" : "Le contenu est converti avec succès en HTML.",
  "ikbs.knghisverman.title.kngHisVerMan" : "Gérer l'historique des connaissances",
  "ikbs.favorite.field.directoryName" : "Nom du répertoire",
  "ikbs.column.Move.message.fail" : "Le déplacement a échoué.",
  "ikbs.column.deletePermanently.message.fail" : "La suppression permanente a échoué.",
  "ikbs.columntype.knowledge" : "Connaissance",
  "ikbs.knowledge.type.common" : "Connaissances communes",
  "ikbs.knowledgeaudit.label.ok" : "OK",
  "ikbs.contentTmp.exception.contTmpName" : "Entrée illégale",
  "ikbs.kngCnt.cntTmp.use.cntReplaceConfirm" : "Êtes-vous sûr de vouloir remplacer le contenu actuel des connaissances ?",
  "ikbs.search.button.search" : "Rechercher",
  "ikbs.constableTmp.delete.success" : "Succès de suppression",
  "ikbs.knowledge.recycle.coluKngName" : "Nom et prénom",
  "ikbs.constableTmp.add.success" : "Succès de l'addition",
  "ikbs.kbshome.button.orderByName" : "Par nom",
  "ikbs.knowledge.tip.uploadSuccess" : "Téléchargement réussi.",
  "ikbs.constableManage.incorrect" : "Condition incorrecte",
  "ikbs.column.modify.properties.batch.message.success" : "Les attributs sont modifiés par lots avec succès.",
  "ikbs.properties.inputway.text" : "Saisie de texte",
  "ikbs.column.move.selectMoveColumnKngNumError" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.knowledgeaudit.title.auditRemark" : "Remarques",
  "ikbs.knowledge.label.indexNeedUpdate" : "Mettre à jour l'index de recherche",
  "ikbs.knowledge.recycle.status" : "Statut",
  "ikbs.kbshome.button.orderByClicks" : "Clics",
  "ikbs.favorite.table.name" : "Nom et prénom",
  "ikbs.column.knowledge.RelVersion" : "Version cible",
  "ikbs.kbshome.button.dayList" : "1 Jour",
  "ikbs.column.modify.modifyValidTimeServiceTimeSame" : "Changez le début et la fin de la période de validité et ceux de la période de validité de l'entreprise.",
  "ikbs.column.label.servicesVaildEndTime" : "Fin de la période de validité de l'entreprise",
  "ikbs.knowledgeaudit.label.submitAudit" : "En attente",
  "ikbs.column.release.selectreleaseColumnKngTypeError" : "Seule la connaissance peut être publiée.",
  "ikbs.knowledge.type.points" : "Connaissance des questions et réponses",
  "ikbs.column.knowledge.modifyStartTime" : "Début modifié",
  "ikbs.favorite.title.preModifiedKngName" : "Avant le changement",
  "ikbs.column.deleteRecycle.comfirm" : "Êtes-vous sûr de vouloir le supprimer ?",
  "ikbs.recycle.title" : "Corbeille",
  "ikbs.column.tree.rootName" : "Catégorie Connaissances",
  "ikbs.column.downMove.message.warning" : "Informations",
  "ikbs.knghisverman.tab.title.kngBaseInfo" : "Informations de base",
  "ikbs.addtype.label.column" : "Ajouter une catégorie",
  "ikbs.knowledge.msg.updateKngPath" : "Staticiser les détails des connaissances",
  "ikbs.kbshome.label.recentViews" : "Mon Histoire",
  "ikbs.column.knowledge.publish" : "Publié",
  "ikbs.contentTemp.export.tempdesc" : "Description du modèle",
  "ikbs.column..topMove.message.fail" : "Impossible d'épingler vers le haut.",
  "ikbs.knowledge.recycle.button.clearAll" : "Corbeille vide",
  "ikbs.constableTmp.status" : "Statut du modèle",
  "ikbs.contentTmp.contentDesc" : "Description du modèle",
  "ikbs.knghisverman.label.version" : "Version",
  "ikbs.column.knowledge.button.unLock" : "Déverrouiller",
  "ikbs.knowledge.error.property" : "Sélectionnez la valeur de l'attribut [{}].",
  "ikbs.knowledgeaudit.label.mySubmitAudit" : "Ma demande",
  "ikbs.column.label.kngReleaseWay" : "Réviser et publier les connaissances dans la catégorie",
  "ikbs.contentTmp.add.fail" : "L'ajout a échoué",
  "ikbs.constableTmp.moveUp" : "Vers le haut",
  "ikbs.constableManage.condition.limit" : "Le nombre de conditions ne peut pas dépasser 10",
  "ikbs.column.downMove.selectDowmMoveColumnKngNumError" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.column.modify.modifyzeropropertieserror" : "Aucun attribut n'est modifié.",
  "ikbs.column.label.isInheritProp" : "Inherit Parent Catégorie Attribut",
  "ikbs.column.knowledge.reject" : "Rejeté",
  "ikbs.column.deletePermanently.columnkng" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.constableManage.modifyConstable" : "Modifier les données du tableau de consultation",
  "ikbs.contTmp.importTmp.title" : "Télécharger le modèle d'importation",
  "ikbs.constableTmp.fieldViewName" : "Afficher le nom",
  "ikbs.cntTmp.import.contTmpDesc" : "Description du modèle incorrecte.",
  "ikbs.constableManage.addConstable" : "Ajouter les données du tableau de consultation",
  "ikbs.contentTmp.operate" : "Opérer",
  "ikbs.column.upMove.message.fail" : "Impossible d'avancer vers le haut.",
  "ikbs.constableTmp.query" : "Requête",
  "ikbs.column.recycle.message.comfirm" : "Confirmer",
  "ikbs.kbshome.button.advancedSearch" : "Recherche avancée",
  "ikbs.constableTmp.modify.success" : "Succès de la modification",
  "ikbs.kbshome.tip.refresh" : "Actualiser",
  "ikbs.constableTmp.delete.fail" : "Échec de la suppression",
  "ikbs.properties.operation.message.success" : "Succès",
  "ikbs.column.knowledge.clear" : "Effacer",
  "ikbs.knowledge.label.auditor" : "Revu par",
  "ikbs.column.tip.error" : "Erreur",
  "ikbs.column.deletePermanently.columnkng.comfirm" : "Êtes-vous sûr de vouloir supprimer définitivement la connaissance de la catégorie?",
  "ikbs.favorite.tab.message.isDeleteFavoriteDir" : "Êtes-vous sûr de vouloir supprimer le répertoire des favoris et les connaissances dans le répertoire?",
  "ikbs.column.tips.modifyColumnOperation.error" : "Impossible de modifier la connaissance de la catégorie.",
  "ikbs.column.button.reset" : "Réinitialiser",
  "ikbs.knowledgeaudit.message.auditSuccess" : "La demande est approuvée avec succès.",
  "ikbs.column.knowledge.Modifier" : "Modifié par",
  "ikbs.cntTmp.import.error.contTmpNameExist" : "Le nom du modèle existe.",
  "ikbs.cntTmp.export.info.success" : "Succès d'exportation.",
  "ikbs.knowledge.label.kngAttachmentUpload" : "Télécharger la pièce jointe de la catégorie de connaissances",
  "ikbs.knowledge.tip.propCountLimited" : "Un maximum de 20 attributs peuvent être associés.",
  "ikbs.column.knowledge.button.deletePermanently" : "Supprimer définitivement",
  "ikbs.knowledge.recycle.label.operateTimeEnd" : "Heure de fin de suppression",
  "ikbs.knowledge.label.display" : "Afficher sur la première page",
  "ikbs.cntTmp.export.result" : "Exportation des modèles de contenu terminée. Nombre total {0}, nombre de réussites {1}, nombre d'échecs {2}.",
  "ikbs.knowledge.label.deleteAnswer" : "Supprimer la réponse",
  "ikbs.knowledge.properties.operation.detail" : "Détails",
  "ikbs.column.knowledge.modifyEndTime" : "Fin modifiée",
  "ikbs.knowledgeaudit.title.name" : "Nom et prénom",
  "ikbs.knowledge.label.modifierName" : "Dernière mise à jour par",
  "ikbs.column.knowledge.button.moveTo" : "Ajuster à",
  "ikbs.knowledge.title.knowledgeContent" : "Contenu",
  "ikbs.knowledge.tip.fileSizeError" : "Impossible de vérifier la taille du fichier.",
  "ikbs.constableTmp.delete" : "Supprimer",
  "ikbs.tips.copy.onlyKngCanCopy" : "Seule la connaissance peut être copiée.",
  "ikbs.column.passProcessWay.delete" : "Supprimer",
  "ikbs.column.label.serviceStatus" : "Statut de l'entreprise",
  "ikbs.column.button.cancel" : "Fermer",
  "ikbs.knowledge.label.clickNum" : "vues",
  "ikbs.Column.MoveTo.message.success" : "Opération réussie.",
  "ikbs.knowledge.index.yes" : "Oui",
  "ikbs.knowledge.tip.fileIOError" : "Impossible de télécharger le fichier.",
  "ikbs.column.modify.properties.message.warning" : "Informations",
  "ikbs.constableTmp.operate" : "Opération",
  "ikbs.knowledgeaudit.title.path" : "Chemin",
  "ikbs.column.serviceStatus.expired" : "Expiré",
  "ikbs.column.knowledge.audit" : "Évalué",
  "ikbs.contTmp.import.tip" : "1. Seul un fichier.xlsx peut être téléchargé. 2. La taille maximale du fichier est de 20 Mo. 3. Un maximum de 10 enregistrements peuvent être importés.",
  "ikbs.knowledge.label.save" : "Enregistrer",
  "ikbs.knowledge.title.knowledgeExtended" : "Informations étendues",
  "ikbs.column.knowledge.button.modifyPropBatch" : "Modifier l'attribut",
  "ikbs.column.type.history" : "Histoire",
  "ikbs.cntTmp.import.contTmpNameError" : "Nom du modèle incorrect.",
  "ikbs.knowledge.tip.error" : "Erreur",
  "ikbs.contentTmp.delete.success" : "Succès de suppression",
  "ikbs.knowledgeaudit.label.success" : "Succès",
  "ikbs.contentTmp.editStandard" : "Modification des spécifications",
  "ikbs.favorite.title.editFavoriteKngName" : "Changer le nom",
  "ikbs.column.knowledge.button.down" : "En bas",
  "ikbs.column.modify.message.error" : "Échec de la modification.",
  "ikbs.column.knowledge.compareKng" : "Comparer les connaissances",
  "ikbs.knowledge.check.inputText" : "Les caractères spéciaux ne sont pas autorisés.",
  "ikbs.column.knowledge.button.delete" : "Supprimer",
  "ikbs.knowledge.service.new" : "Nouvelles affaires",
  "ikbs.knowledge.properties.operation.disable" : "Désactiver",
  "ikbs.column.serviceStatus.hot" : "Chaud",
  "ikbs.contentTmp.add.success" : "Succès de l'addition",
  "ikbs.favorite.msg.modifyNodeNameSuccess" : "Opération réussie.",
  "ikbs.column.deletePermanently.message.warning" : "Informations",
  "ikbs.contentTmp.modify" : "Modifier",
  "ikbs.kbshome.button.sevenDayList" : "7 Jours",
  "ikbs.column.knowledge.button.turnToHistory" : "Passer à l'historique",
  "ikbs.constableTmp.cancle" : "Annuler",
  "ikbs.knowledge.recycle.button.resume" : "Restaurer",
  "ikbs.knowledgeaudit.label.auditing" : "En cours d'examen",
  "ikbs.knowledge.release.audit" : "Revue puis publication",
  "ikbs.constableTmp.yes" : "Oui",
  "ikbs.column.label.displayFlag" : "Afficher sur la première page",
  "ikbs.column.moveto.back" : "À côté de",
  "ikbs.column.modify.message.warning" : "Informations",
  "ikbs.knowledge.label.nextStep" : "Étape suivante",
  "ikbs.column.moveTo.message.warning" : "Informations",
  "ikbs.column.isInheritProp.yes" : "Oui",
  "ikbs.knowledge.title.knowledgeBase" : "Informations de base",
  "ikbs.knowledge.label.keyword" : "Mots-clés",
  "ikbs.knowledgeaudit.label.reset" : "Réinitialiser",
  "ikbs.knowledge.message.uploadImgTypeError" : "Le format de l'image est incorrect.",
  "ikbs.knowledge.label.moveUp" : "Vers le haut",
  "ikbs.properties.inputway.checkbox" : "Case à cocher",
  "ikbs.knowledgeaudit.label.auditReject" : "Rejeté",
  "ikbs.contentTmp.ModifyTime" : "Modifié le",
  "ikbs.knowledge.label.checkboxProp" : "Case à cocher",
  "ikbs.knowledge.label.kngMsg" : "Informations sur les connaissances",
  "ikbs.column.knowledge.compareResult" : "Résultats de la comparaison",
  "ikbs.cntTmp.import.invalidFile" : "Fichier non valide.",
  "ikbs.kbshome.button.orderByOrderId" : "Par No.",
  "ikbs.column.knowledge.modifyColumnInformation" : "Modifier la catégorie",
  "ikbs.knowledgeaudit.placeholder.pleaseSelect" : "-Sélectionner-",
  "ikbs.constableTmp.tab.modifyTitle" : "Modification du modèle",
  "ikbs.knowledge.label.modifyRemark" : "Remarques",
  "ikbs.column.release.selectreleaseColumnKngNumError" : "Aucune connaissance n'est sélectionnée.",
  "ikbs.column.knowledge.button.order" : "Trier",
  "ikbs.contentTmp.import" : "Importer",
  "ikbs.column.label.validEndTime" : "Fin de la période de validité",
  "ikbs.properties.status.enable" : "Activer",
  "ikbs.column.modify.modifyValidServiceStartTimeCheckError" : "Le début de la période de validité de l'entreprise ne peut être antérieur au début de la période de validité.",
  "ikbs.knowledge.error.contentEmpty" : "Le contenu des connaissances ne peut pas être vide.",
  "ikbs.column.upMove.selectUpMoveColumnKngNumError" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.constableTmp.add" : "Ajouter",
  "ikbs.constableTmp.operator.exclude" : "exclure",
  "ikbs.column.knowledge.LockedFlat" : "Verrouillé par",
  "ikbs.knowledge.label.cancelCollection" : "Supprimer des favoris",
  "ikbs.contentTmp.reset" : "Réinitialiser",
  "ikbs.knowledge.label.textProp" : "Saisie de texte",
  "ikbs.knowledge.title.propType" : "Type d'entrée",
  "ikbs.cntTmp.export.exportNum.oversize" : "Un maximum de 10 modèles de contenu peuvent être exportés.",
  "ikbs.properties.status.disable" : "Désactiver",
  "ikbs.knowledgeaudit.label.knowledgeName" : "Nom de la connaissance",
  "ikbs.constableManage.fieldName" : "Nom de la colonne",
  "ikbs.contentTmp.close" : "Fermer",
  "ikbs.knowledge.properties.label.add" : "Nouveau",
  "ikbs.knowledgeaudit.label.auditPass" : "Approuvé",
  "ikbs.common.search" : "Recherche commune",
  "ikbs.contentTmp.modify.success" : "Succès de la modification",
  "ikbs.column.downMove.message.success" : "Déplacé vers le bas avec succès.",
  "ikbs.knowledge.label.draft" : "Ébauche",
  "ikbs.knowledge.label.creationTime" : "Créé le",
  "ikbs.column.modify.properties.batch.message.fail" : "Impossible de modifier les attributs dans les lots.",
  "ikbs.column.message.plsSelectModifyStartTime" : "Sélectionnez une heure de début.",
  "ikbs.knowledge.label.problem" : "Question",
  "ikbs.constableTmp.displayFlag" : "Affiché ou non",
  "ikbs.Column.MoveTo.message.fail" : "L'opération a échoué.",
  "ikbs.column.knowledge.columnKnowledgeName" : "Catégorie Connaissance Nom",
  "ikbs.column.knowledge.button.top" : "Pin",
  "ikbs.tips.addColumnOperation.error" : "Impossible d'ajouter la catégorie.",
  "ikbs.search.label.sortByName" : "Par nom",
  "ikbs.column.delete.selectdeletecolumn.comfirm" : "Êtes-vous sûr de vouloir supprimer la catégorie et toutes ses catégories et connaissances enfant?",
  "ikbs.constableTmp.modifierName" : "Modifié par",
  "ikbs.knowledge.label.attachment" : "Pièce jointe",
  "ikbs.column.unlock.selectUnlockColumnKngNumError" : "Aucune connaissance de catégorie n'est sélectionnée.",
  "ikbs.contentTmp.tmpContent" : "Contenu du modèle",
  "ikbs.column.label.coluKngBrowsing" : "parcourir le groupe",
  "ikbs.kbshome.button.totalList" : "Tous les temps",
  "ikbs.search.label.resultDes" : "Pas de données",
  "ikbs.knowledge.recycle.button.query" : "Requête",
  "ikbs.knowledge.properties.label.reset" : "Réinitialiser",
  "ikbs.constableTmp.warning" : "Informations",
  "ikbs.knowledge.label.kngReleaseWay" : "Mode Publier",
  "ikbs.properties.inputway.radio" : "Boîtier radio",
  "ikbs.knowledge.recycle.operationTime" : "Supprimé le",
  "ikbs.constableTmp.plsSelectModifyEndTime" : "Définir l'heure de fin de modification",
  "ikbs.column.knowledge.button.turnToNormal" : "Passer à la normale",
  "ikbs.knowledge.label.validStartTime" : "Période de validité Début",
  "ikbs.knowledge.pass.delete" : "Supprimer",
  "ikbs.column.displayflag.show" : "Oui",
  "ikbs.knowledge.recycle.select.knowledgeStatus" : "État des connaissances",
  "ikbs.column.deletePermanently.message.success" : "Suppression permanente réussie.",
  "ikbs.time.lessThan.currentTime" : "La fin ne peut pas être antérieure à l'heure actuelle.",
  "ikbs.knowledge.recycle.label.operateTimeStart" : "Heure de début de la suppression",
  "ikbs.knowledge.recycle.label.knowledgeName" : "Catégorie Connaissance Nom",
  "ikbs.knghisverman.label.delete" : "Supprimer",
  "ikbs.column.knowledge.Name" : "Nom et prénom",
  "ikbs.knowledgeaudit.placeholder.pleaseInput" : "Entrez un nom.",
  "ikbs.column.label.validStartTime" : "Période de validité Début",
  "ikbs.knowledge.display.yes" : "Oui",
  "ikbs.knowledge.label.servicesTime" : "Période de validité",
  "ikbs.search.label.updateStartTime" : "Mettre à jour l'heure de début",
  "ikbs.favorite.msg.addDirectorySuccess" : "Le répertoire est ajouté avec succès.",
  "ikbs.search.label.result" : "Résultats de la recherche",
  "ikbs.constableTmp.modify.fail" : "Échec de la modification",
  "ikbs.constableTmp.add.fail" : "Échec de l'addition",
  "ikbs.column.knowledge.modifierId" : "Modifié par",
  "ikbs.tips.propertyDictValueNumExceedMaxValue.warning" : "Le nombre de valeurs du dictionnaire d'attributs dépasse le maximum.",
  "ikbs.constableTmp.no" : "Non",
  "ikbs.knowledge.label.close" : "Fermer",
  "ikbs.favorite.table.operate" : "Opération",
  "ikbs.kngCnt.cntTmp.editStandard.title" : "Modification des spécifications",
  "ikbs.constableTmp.anonymizationFlag" : "Anonymisé ou non",
  "ikbs.knowledge.tip.fileCountError" : "Un maximum de pièces jointes {count} sont supportées.",
  "ikbs.column.copy.selectCopyColumnKngNumZERO" : "Aucune connaissance n'est sélectionnée pour la copie.",
  "ikbs.constableTmp.delete.byCondition" : "Supprimer par condition",
  "ikbs.column.delete.selectdeletecolumnkng.comfirm" : "Êtes-vous sûr de vouloir supprimer les catégories ou les connaissances?",
  "ikbs.knowledge.label.name" : "Nom de la connaissance",
  "ikbs.knowledge.check.textArea" : "Les caractères spéciaux suivants ne sont pas autorisés:<>/",
  "ikbs.knowledge.label.addToCollection" : "Ajouter aux Favoris",
  "ikbs.knowledge.recycle.operator" : "Opérateur",
  "ikbs.konwledgetype.knowledge" : "Connaissances communes",
  "ikbs.knowledgeaudit.label.taskBeginTime" : "Heure de début de la tâche",
  "ikbs.kngCnt.cntTmp.importType.sameNameCover" : "Écraser des modèles avec des noms identiques",
  "ikbs.column.knowledge.Path" : "Chemin",
  "ikbs.kbshome.label.plsInputKeyWord" : "Saisissez un mot-clé.",
  "ikbs.knowledge.label.addProperty" : "Ajouter",
  "ikbs.knowledgeaudit.title.submitter" : "Soumis par",
  "ikbs.knowledge.properties.properties.operation" : "Opération",
  "ikbs.knowledge.label.moveDown" : "En bas"
}