export default {
  "webclient.body.message.bot.recommend": "猜您想问",
  "webclient.body.message.card.url": "复制链接",
  "webclient.body.message.cobrowse.agree": "同意",
  "webclient.body.message.cobrowse.cancel": "取消",
  "webclient.body.message.cobrowse.target": "浏览目标：",
  "webclient.body.message.cobrowse.title": "座席请求与您进行协同浏览",
  "webclient.body.message.file.download": "下载",
  "webclient.body.message.file.download.again": "重新下载",
  "webclient.body.message.locate.latitude": "纬度",
  "webclient.body.message.locate.longitude": "经度",
  "webclient.body.message.recall": "撤回了一条消息",
  "webclient.body.name.assistant": "智能助手",
  "webclient.body.tip.leave.click": "点击这里留言",
  "webclient.body.tip.leave.message": "如有需要请",
  "webclient.body.tip.leave.message.finish": "您的留言已发送成功，您可以再次留言",
  "webclient.body.tip.privacy": "为了提升服务质量，我们会保留您和客服代表、机器人的聊天记录，我们将采取加密、控制访问权限等方式来确保数据安全。",
  "webclient.body.tip.queue.cancel": "取消排队",
  "webclient.body.tip.queue.cancel.done": "取消排队成功，重新排队请继续发送消息",
  "webclient.body.tip.queue.cancel.robot": "取消排队成功，正在连接机器人...",
  "webclient.body.tip.queue.part1": "您当前排在第",
  "webclient.body.tip.queue.part2": "位，预计还要等待",
  "webclient.body.tip.queue.part3": "秒。",
  "webclient.body.tip.queue.timeout": "排队已超时",
  "webclient.body.tip.session.end": "会话已结束",
  "webclient.body.tip.session.reconnect": "重新发起会话",
  "webclient.body.tip.session.timeout": "您未在指定时间内回复，系统已自动结束会话。您可以",
  "webclient.body.tip.token.invalid": "token失效或者已在其他地方登陆",
  "webclient.body.title.call.disconnect": "呼叫已断开",
  "webclient.body.title.call.end": "通话结束",
  "webclient.body.title.call.fail": "呼叫失败",
  "webclient.body.title.call.queue": "排队中",
  "webclient.body.title.call.start": "呼叫中",
  "webclient.body.title.call.timeout": "呼叫排队超时",
  "webclient.body.title.call.transfer": "呼叫转移中",
  "webclient.box.tip.click.cannot.link": "不能连接到WebRTC网关",
  "webclient.box.tip.click.check.device.fail": "检查是否支持点击通话失败",
  "webclient.box.tip.click.no.audio.input": "音频输入设备不可用",
  "webclient.box.tip.click.no.audio.output": "音频输出设备不可用",
  "webclient.box.tip.click.no.video.input": "视频输入设备不可用",
  "webclient.box.tip.click.not.support.browser": "当前浏览器不支持WebRTC",
  "webclient.box.tip.click.not.support.channel": "当前渠道不支持点击通话",
  "webclient.box.tip.click.not.support.tenant": "当前租间不支持WebRTC",
  "webclient.box.tip.cobrowse.no.auth": "座席没有网页协同权限",
  "webclient.chat.nickname.system": "系统",
  "webclient.dialog.evaluate.placeholder": "请输入您的评价",
  "webclient.dialog.evaluate.title": "请您对客服的服务进行评价!",
  "webclient.dialog.leave.format.content": "留言内容不能为空，且不能包含特殊字符",
  "webclient.dialog.leave.format.phone": "号码不能为空，且只能包含数字、-或+",
  "webclient.dialog.leave.phone": "请输入手机号码",
  "webclient.dialog.leave.success": "留言成功",
  "webclient.dialog.leave.text": "请留言",
  "webclient.dialog.leave.title": "留言",
  "webclient.dialog.selectArea.title": "请选择需遮挡区域",
  "webclient.dialog.video.default": "默认",
  "webclient.dialog.video.hide": "隐藏本地视频",
  "webclient.dialog.video.large": "大尺寸",
  "webclient.dialog.video.medium": "中等尺寸",
  "webclient.dialog.video.pause": "暂停视频",
  "webclient.dialog.video.share.off": "停止共享",
  "webclient.dialog.video.share.on": "桌面共享",
  "webclient.dialog.video.size": "视频大小调整",
  "webclient.dialog.video.small": "小尺寸",
  "webclient.dialog.video.start": "启动视频",
  "webclient.footer.label.send.message": "发送消息",
  "webclient.footer.label.share.direct": "直接共享",
  "webclient.footer.label.share.sensitive": "选择敏感区域",
  "webclient.footer.label.stop.direct": "停止共享",
  "webclient.footer.placeholder.richtext": "请输入您要咨询的问题",
  "webclient.footer.send.fail.content.big": "发送内容超出最大长度(500字符)",
  "webclient.footer.send.fail.file.format": "文件格式不符合要求",
  "webclient.footer.send.fail.file.other": "发送文件失败",
  "webclient.footer.send.fail.file.screen": "发送截屏失败",
  "webclient.footer.send.fail.file.size.audio": "音频大小不能超过",
  "webclient.footer.send.fail.file.size.common": "文件大小不能超过",
  "webclient.footer.send.fail.file.size.video": "视频大小不能超过",
  "webclient.footer.send.fail.no.file": "请选择文件",
  "webclient.footer.send.fail.picture.with.text": "暂不支持发送图片与文字或表情混排的消息",
  "webclient.footer.tip.cobrowse.share": "当前操作会共享您的浏览器页面，可能会包含您的个人数据，是否继续？",
  "webclient.footer.tip.connect.fail": "连接失败，请重试或联系管理员",
  "webclient.footer.tip.download": "您下载的聊天内容为HTML格式，聊天内容中的多媒体内容具有时效性的。建议您在下载后立即将其转换为PDF文件。将HTML转换为PDF的步骤：1.在浏览器中打开要转换的HTML文件；2.按Ctrl+P (Windows)或Command+P (Mac)打开打印对话框；3.在打印对话框中，选择“目标打印机”为“另存为PDF”；4.单击“保存”按钮将HTML页面转换为PDF格式并保存到本地计算机。",
  "webclient.footer.tooltip.audio": "语音",
  "webclient.footer.tooltip.cobrowse": "共享",
  "webclient.footer.tooltip.download": "下载对话",
  "webclient.footer.tooltip.emotion": "表情",
  "webclient.footer.tooltip.evaluation": "评价",
  "webclient.footer.tooltip.file": "上传文件",
  "webclient.footer.tooltip.more": "更多",
  "webclient.footer.tooltip.send": "发送",
  "webclient.footer.tooltip.send.way": "发送方式",
  "webclient.footer.tooltip.video": "视频",
  "webclient.header.title.input": "对方正在输入",
  "webclient.header.title.service": "AICC 在线客服",
  "webclient.header.tooltip.close": "关闭",
  "webclient.header.tooltip.transfer.agent": "转人工",
  "webclient.header.tooltip.transfer.robot": "转机器人",
}