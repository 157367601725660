export function transTime(time, connector) {
    if (!time && time !== "0") return undefined;
    let con = (connector || '-'); // 日期分割符默认调整为'-'
    // 判断入参是否全部是数字，如果全部是数字，则表示是时间戳转换的字符串，需要转换回数字，否则new Date会报错。
    let date;
    if (time) {
        date = isNumber(time) ? new Date(parseInt(time)) : new Date(time);
    } else {
        date = new Date();
    }
    let Y = `${date.getFullYear()}${con}`;
    let M = `${
        date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
    }${con}`;
    let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
    let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
}

function isNumber(num) {
    let reg = /^[0-9]+.?[0-9]*$/;
    return reg.test(num);
}

export function getUTCTimestamp (time) {
    // 判断入参是否全部是数字，如果全部是数字，则表示是时间戳转换的字符串，需要转换回数字，否则new Date会报错。
    let date;
    if (time) {
        date = isNumber(time) ? new Date(parseInt(time)) : new Date(time);
    } else {
        date = new Date();
    }

    /**
     if ($UEE.$Tz.dstsupport) { // 如果服务端设置了时区，则按照服务端设置的时区返回时间戳
        let serverTime =  date.getTime() + $UEE.$Tz.stz.rawOffset + (new Date()).getTimezoneOffset() * 60000;
        date = new Date(serverTime);
    }**/

    let resultTime;
    resultTime = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    );
    return resultTime;
}

//时间类型转换 YYYY-MM-DD HH:MM:SS 
export function formatTime(date) {
    let Y = date.getFullYear()
    let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    let D = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate();
    let h = date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours();
    let m = date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes();
    let s = date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds();
    return Y + "-" + M + "-" + D + ' ' + h + ":" + m + ":" + s;
}

//时间类型转换 YYYYMMDD 
export function formatTimeYMD(date) {
  let Y = date.getFullYear()
  let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  let D = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate();
  return Y + M + D;
}