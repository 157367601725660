import request from '@/utils/request'
import { getRestService } from '@/views/ccmessaging/api/online-chat-workbench/utils'

export const queryCalledRouteByMedia = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/queryCalledRouteByMedia')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const querySocialMedia = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/querySocialMedia')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryChannelConfigs = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/list')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const countChannelConfigs = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/count')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryChannelConfigByid = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/queryChannelConfigByid')
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

export const queryWechatUrl = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/queryIp')
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}
export const getWechatQrUrl = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/getWechatQrUrl')
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

export const getWechatAppId = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/getWechatAppId')
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

export const queryCertFile = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/queryCertFile')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}
export const deleteChannelConfig = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/delete')
  return request({
    url: serviceUrl,
    method: 'delete',
    data
  })
}

export const queryRobotInfo = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/queryRobotInfo')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryEmailReceiveConfigNodes = (data) => {
  let serviceUrl = getRestService(
    '/ccnotification/v1/gatewayConfig',
    null,
    '/queryEmailReceiveConfigNodes'
  )
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryEmailSendConfigNodes = (data) => {
  let serviceUrl = getRestService(
    '/ccnotification/v1/gatewayConfig',
    null,
    '/queryEmailSendConfigNodes'
  )
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryEmailSignature = (data) => {
  let serviceUrl = getRestService('/ccnotification/v1/signature', null, '/queryEmailSignature')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryGatewayConfigByCondition = (data) => {
  let serviceUrl = getRestService(
    '/ccnotification/v1/gatewayConfig',
    null,
    '/queryGatewayConfigByCondition'
  )
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryEmailSignatureInfo = (data) => {
  let serviceUrl = getRestService('/ccnotification/v1/signature', null, '/queryEmailSignatureInfo')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const saveChannelConfig = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/save')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const updateChannelConfig = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/update')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryChannelConfigByIdOnWeb = (data) => {
  let serviceUrl = getRestService(
    '/ccmessaging/v1/channelconfig',
    null,
    '/queryChannelConfigByIdOnWeb'
  )
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

export const getReq2TwitterAdaptor = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/getReq2TwitterAdaptor')
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

export const updateRouteIdById = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/updateRouteIdById')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryChatbotAddress = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/queryChatbotAddress')
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

export const queryRobotInfoByAccessCode = (data) => {
  let serviceUrl = getRestService(
    '/ccmessaging/v1/channelconfig',
    null,
    '/queryRobotInfoByAccessCode'
  )
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryAuthorization = (data) => {
  let serviceUrl = getRestService(
    '/ccmessaging/v1/authorization',
    null,
    '/queryAuthorizationByChannelId'
  )
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const getAllExportFields = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/channelconfig', null, '/getAllExportFields')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const exportChannelConfig = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/export', null, '/exportChannelConfig')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryExportTaskList = (data) => {
  let serviceUrl = getRestService('/ccmessaging/v1/export', null, '/queryExportTaskList')
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}
