<template>
  <sweet-dialog
    class="aicc-ccmanagement-compress-pop"
    v-model="isShow"
    :title="title"
    @close="closeDialog"
    destroy-on-close
    :close-on-click-modal="false"
    width="616px"
  >
    <sweet-form
      class="compress-pop-form"
      ref="downloadFileForm"
      :model="form"
      :rules="formRules"
      label-width="auto"
      :label-position="labelPosition"
      v-on:submit.prevent
    >
      <sweet-form-item
        :label="$t('ccm.custdata.operlog.zippwd')"
        prop="zipPwd"
      >
        <template #label>
          <span class="input-required">
            {{ $t("ccm.custdata.operlog.zippwd") }}
          </span>
        </template>
        <div class="input-style">
          <sweet-input
            id="confirmAccountPassword"
            v-model="form.zipPwd"
            autocomplete="off"
            type="password"
            :placeholder= "$t('SM.LABEL.PASSWORD')"
            class="aicc-form-input-width"
            clearable
          />
        </div>
      </sweet-form-item>
      <sweet-form-item label="   ">
        <div class="footer--tips">{{ i18nFootTips }}</div>
      </sweet-form-item>
    </sweet-form>
    <template #footer>
      <div class="dialog-footer">
        <sweet-button @click="downloadCancel" id="aicc-ccmanagement-download-file--cancel">
          {{ $t('ccm.agent.button.cancel') }}
        </sweet-button>
        <sweet-button type="primary" @click="save" id="aicc-ccmanagement-download-file--confirm">
          {{ $t('aicc.ok') }}
        </sweet-button>
      </div>
    </template>
  </sweet-dialog>
</template>

<script setup lang="ts">
/** 压缩设置弹窗公共组件，不包含下载等具体操作 */
import { computed, reactive, ref } from "vue";
import { FormInstance, FormRules } from 'element-plus'
import type {
  InternalRuleItem,
  SyncValidateResult,
  Value
} from 'async-validator/dist-types/interface'
import { validZipPwdExtendedRule, validZipPwdRule } from "@/views/ccmanagement/common/validRules";
import i18n, { getLanguage } from "@/lang";

/** 下载文件表格参数接口类型定义 */
interface DownloadFileFormParam {
  /** 压缩密码 */
  zipPwd: String
}

const props = defineProps({
  title: {
    type: String,
    required: true,
    validator: (value: String): boolean => !!value
  },
  // 密码最小长度，默认为8字符
  minPwdLength: {
    type: Number,
    default: 8,
    required: true,
  },
  // 密码最大长度，默认为12字符
  maxPwdLength: {
    type: Number,
    default: 12,
    required: true,
  },

  /** 使用拓展的特殊字符校验规则 */
  useExtendedValidator: {
    type: Boolean,
    default: () => false
  }
})
const emits = defineEmits<{
  /** 取消创建时，执行的回调 */
  (event: 'cancel'): void,
  /** 确认创建任务时，执行的回调函数 */
  (event: 'confirm', zipPwd: String): void
}>()
const isShow = ref<boolean>(false)
const downloadFileForm = ref<FormInstance>()
const labelPosition = computed<'left'|'right'|'top'>(() => window.isRTL ? 'right' : 'left')
const i18nFootTips = computed(() => {
  const i18nKey = props.useExtendedValidator ? "ccm.export.password.error.tips" : "ccm.custdata.operlog.popwindowmessage"
  return i18n.global.t(i18nKey)
})

/**
 * 密码有效性校验
 * @param rule 校验规则
 * @param value 要校验的值
 * @param callback 回调函数
 */
const passwordValidate = (
  rule: InternalRuleItem,
  value: Value,
  callback: (error?: string | Error) => void
): SyncValidateResult | void => {
  return props.useExtendedValidator
    ? validZipPwdExtendedRule(rule, value, callback, props.minPwdLength, props.maxPwdLength)
    : validZipPwdRule(rule, value, callback, props.minPwdLength, props.maxPwdLength);
}
/** 表格对象定义 */
const form = reactive<DownloadFileFormParam>({ zipPwd: '' })
/** 表格校验规则 */
const formRules = reactive<FormRules>({
  zipPwd: [{ validator: passwordValidate, trigger: ['blur','change'] }]
})

/** 控制弹窗是否展示 */
const handleDialog = (param?: boolean) => {
  isShow.value = param || false
}

/** 关闭弹窗时，执行的回调 */
const closeDialog = () => {
  isShow.value = false
  form.zipPwd = ''
  downloadFileForm.value?.resetFields()
}

/**
 * 取消下载任务
 */
const downloadCancel = () => {
  emits("cancel");
  closeDialog()
}

/**
 * 确认并保存
 */
const save = () => {
  downloadFileForm.value?.validate(async (valid: any) => {
    if (valid) {
      // 压缩密码透传给父组件
      emits("confirm", form.zipPwd)
      closeDialog()
    }
  })
}

/**
 * 获取标签宽度
 * @description 根据不同国际化语言，返回不同的表单标签宽度
 * @return {String} 表单宽度
 */
const getLabelWidth = (): String => {
  const lang: String = getLanguage()
  switch (lang) {
    case 'zh_CN':
      return 'auto'
    case 'th_TH':
      return 'auto'
    case 'pt_BR':
      return 'auto'
    case 'fr_FR':
      return 'auto'
    case 'en_US':
    case 'es_ES':
      return '200px'
    default:
      return 'auto'
  }
}

defineExpose({ handleDialog })
</script>


<style lang="less">
.aicc-ccmanagement-compress-pop {
  width: 616px;

  .compress-pop-form {
    width: inherit;

    .input-style {
      width: calc(100% - 20px);

      :deep(.el-input) {
        width: 100%;
      }
    }

    .label-style {
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .footer--tips {
      color: #707070;
      line-height: 16px;
      font-weight: 400;
      word-break: break-word;
    }
  }
}
.aicc-ccmanagement-compress-pop > .el-dialog__body {
  padding-bottom: 0;

  .el-form-item__label {
    padding-inline-start: 0 !important;
    padding-left: unset !important;
  }
}

.input-required {
  padding-block-start: 0;
  &::before {
    content: "*";
    color: var(--el-color-danger);
    margin-inline-end: 4px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>