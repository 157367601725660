<template>
  <sweet-select
    class="aicc-paging-load-select"
    v-bind="$attrs"
    :placeholder="$t('aicc.select')"
    popper-class="aicc-paging-load-select_dropdown"
    :filter-method="handleFilter"
    @blur="handleBlur"
  >
    <div v-infinite-scroll="pagingLoad" :infinite-scroll-disabled="loading" style="overflow:hidden">
      <slot></slot>
      <div class="aicc-align--absolute-center" v-if="loading">
        {{ loadingText || $t('aicc.loading') }}
        <el-icon class="is-loading"><loading /></el-icon>
      </div>
    </div>
  </sweet-select>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String
    },
    page: {
      type: Number,
      required: true
    },
    filterValue: {
      type: String
    },
    initMethod: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      filterFlag: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('update:page', 1)
      this.$emit('update:filterValue', '')
      this.initMethod()
    },
    pagingLoad() {
      this.$emit('update:page', this.page + 1)
      this.$emit('pagingLoad')
    },
    handleFilter(val) {
      this.filterFlag = true
      this.$emit('update:page', 1)
      this.$emit('update:filterValue', val)
      this.initMethod()
    },
    // 如果执行过filter，input失焦时要重置选项列表
    handleBlur() {
      if (this.filterFlag) {
        this.filterFlag = false
        this.$emit('update:page', 1)
        this.$emit('update:filterValue', '')
        this.initMethod()
      }
    }
  }
}
</script>