<template>
  <img class="icon-width" src="@/assets/img/input_dialog.png" alt="" />
</template>

<script>
export default {
  name: "SelectMore"
}
</script>

<style lang="less" scoped>
.icon-width{
  width: 16px;
}
</style>

<style>
.select-more .el-input .el-select__caret.is-reverse{
  transform: none !important;
}
</style>