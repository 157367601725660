import odfsRequest from '@oifde/utils/odfsRequest'
import { useOifdeUserStore } from '../../stores/user'
import cookieUtils from "@/utils/cookie"
import request from '@/utils/request'

// 查询绑定领域
export function queryBindingDomain(accessCode) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    accessCode: accessCode
  }
  return odfsRequest({
    url: '/oifde/rest/querybindingdomain',
    method: 'post',
    data: data
  })
}

// 查询绑定问答组
export function queryBindingFaqGroup(accessCode) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    accessCode: accessCode
  }
  return odfsRequest({
    url: '/oifde/rest/querybindingfaqgroup',
    method: 'post',
    data: data
  })
}

export function queryAllDomain() {
  return odfsRequest({
    url: '/chatbot/rest/tuc/v1/web/domains',
    method: 'get'
  })
}

export function queryTucEnable() {
  return odfsRequest({
    url: '/oifde/rest/queryTucEnable',
    method: 'get'
  })
}

export function queryFaqGroup(data) {
  let url = '/chatbot/rest/tuc/v1/web/faqGroups'
  return odfsRequest({
    url: url,
    method: 'get',
    data
  })
}

export function bindingDomain(query) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id')
  }
  for (let prop in query) {
    if (typeof query[prop] === undefined) {
      continue
    }
    if (typeof query[prop] === 'string' && query[prop] === '') {
      continue
    }
    data[prop] = query[prop]
  }
  return odfsRequest({
    url: '/oifde/rest/bindingdomain',
    method: 'post',
    data: data
  })
}

export function bindingFaqGroup(query) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id')
  }
  for (let prop in query) {
    if (typeof query[prop] === undefined) {
      continue
    }
    if (typeof query[prop] === 'string' && query[prop] === '') {
      continue
    }
    data[prop] = query[prop]
  }
  return odfsRequest({
    url: '/oifde/rest/bindingfaqgroup',
    method: 'post',
    data: data
  })
}

export function queryAccessCodeList(query) {
  const userStore = useOifdeUserStore()
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: userStore.source
  }
  for (let prop in query) {
    if (typeof query[prop] === undefined) {
      continue
    }
    if (typeof query[prop] === 'string' && query[prop] === '') {
      continue
    }
    data[prop] = query[prop]
  }
  return odfsRequest({
    url: '/oifde/rest/queryaccesscodes',
    method: 'post',
    data: data
  })
}


export function queryCallingNumberTrace(query) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id')
  }
  for (let prop in query) {
    if (typeof query[prop] === undefined) {
      continue
    }
    if (typeof query[prop] === 'string' && query[prop] === '') {
      continue
    }
    data[prop] = query[prop]
  }
  return odfsRequest({
    url: '/oifde/rest/querycallingnumbertrace',
    method: 'post',
    data: data
  })
}

export function setCallingNumberTrace(query) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    callingNumber: query.callingNumber,
    traceFlag: query.traceFlag
  }
  return odfsRequest({
    url: '/oifde/rest/setcallingnumbertrace',
    method: 'post',
    data: data
  })
}

export function createAccessCode(accessCodeInfo) {
  const userStore = useOifdeUserStore()
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: userStore.source
  }
  for (let prop in accessCodeInfo) {
    if (typeof accessCodeInfo[prop] === undefined) {
      continue
    }
    if (typeof accessCodeInfo[prop] === 'string' && accessCodeInfo[prop] === '') {
      continue
    }
    data[prop] = accessCodeInfo[prop]
  }
  // 区分人机接口
  data['isWeb'] = true

  return odfsRequest({
    url: '/oifde/rest/createaccesscode',
    method: 'post',
    data
  })
}

export function updateAccessCode(accessCodeInfo) {
  const userStore = useOifdeUserStore()
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: userStore.source
  }
  for (let prop in accessCodeInfo) {
    if (typeof accessCodeInfo[prop] === undefined) {
      continue
    }
    if (typeof accessCodeInfo[prop] === 'string' && accessCodeInfo[prop] === '') {
      continue
    }
    data[prop] = accessCodeInfo[prop]
  }
  // 区分人机接口
  data['isWeb'] = true

  return odfsRequest({
    url: '/oifde/rest/updateaccesscode',
    method: 'post',
    data
  })
}

export function deleteAccessCode(accessIdList) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    accessIdList: accessIdList
  }

  return odfsRequest({
    url: '/oifde/rest/deleteaccesscode',
    method: 'post',
    data
  })
}

// 判断是否需要启用录制
export function querySpecialRecordConfig(data) {
  return odfsRequest({
    url: '/oifde/rest/queryspecialrecordconfig',
    method: 'post',
    data
  })
}

// 查询引用关系
export function queryBindingScene(data) {
  return odfsRequest({
    url: '/oifde/rest/accesscode/queryResourceSubInfo',
    method: 'post',
    data
  })
}


/**
 * 导出智能机器人
 * @param data
 * @returns {*}
 */
export function accessCodeExportByCondition(data) {
  return request({
    url: '/oifde/rest/accessCodeExportByCondition',
    method: 'post',
    data
  })
}
