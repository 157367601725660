export default {
  "webclient.body.message.bot.recommend": "Guess you want to ask",
  "webclient.body.message.card.url": "Copy link",
  "webclient.body.message.cobrowse.agree": "Agree",
  "webclient.body.message.cobrowse.cancel": "Cancel",
  "webclient.body.message.cobrowse.target": "Browse Targets:",
  "webclient.body.message.cobrowse.title": "Agent requests co-browsing with you",
  "webclient.body.message.file.download": "Download",
  "webclient.body.message.file.download.again": "Download Again",
  "webclient.body.message.locate.latitude": "Latitude",
  "webclient.body.message.locate.longitude": "Longitude",
  "webclient.body.message.recall": "withdrew a message",
  "webclient.body.name.assistant": "Intelligent assistant",
  "webclient.body.tip.leave.click": "Click here to leave a message",
  "webclient.body.tip.leave.message": "If necessary please",
  "webclient.body.tip.leave.message.finish": "Your message is sent successfully. You can leave another message.",
  "webclient.body.tip.privacy": "To improve service quality, we will retain the chat history between you and the customer service representative or the robot chat history. We will ensure data security by encryption or access right control.",
  "webclient.body.tip.queue.cancel": "Canceling queuing",
  "webclient.body.tip.queue.cancel.done": "Successfully canceled queuing, please re-queue, please continue to send messages",
  "webclient.body.tip.queue.cancel.robot": "Successfully canceled queuing, connecting robot...",
  "webclient.body.tip.queue.part1": "You are No.",
  "webclient.body.tip.queue.part2": " in the queue and need to wait ",
  "webclient.body.tip.queue.part3": " seconds. ",
  "webclient.body.tip.queue.timeout": "The queue has timed out",
  "webclient.body.tip.session.end": "Session ended",
  "webclient.body.tip.session.reconnect": "restart session",
  "webclient.body.tip.session.timeout": "You did not reply within the specified time, the system has automatically ended the session. You can",
  "webclient.body.tip.token.invalid": "Token expired or has been logged in differently",
  "webclient.body.title.call.disconnect": "Call disconnected",
  "webclient.body.title.call.end": "Call ended",
  "webclient.body.title.call.fail": "Call failed",
  "webclient.body.title.call.queue": "Call in queue",
  "webclient.body.title.call.start": "Calling",
  "webclient.body.title.call.timeout": "Call queuing timeout",
  "webclient.body.title.call.transfer": "Call transferring",
  "webclient.box.tip.click.cannot.link": "Can not connect to WebRTC gateway",
  "webclient.box.tip.click.check.device.fail": "Failed to check whether Click-to-Call is supported",
  "webclient.box.tip.click.no.audio.input": "Audio input devices not available",
  "webclient.box.tip.click.no.audio.output": "Audio output devices not available",
  "webclient.box.tip.click.no.video.input": "Video input devices not available",
  "webclient.box.tip.click.not.support.browser": "The browser does not support WebRTC",
  "webclient.box.tip.click.not.support.channel": "WebRTC not supported by the tenant",
  "webclient.box.tip.click.not.support.tenant": "Click-to-Call not supported by the channel",
  "webclient.box.tip.cobrowse.no.auth": "The agent does not have the Co-browsing permission",
  "webclient.chat.nickname.system": "System",
  "webclient.dialog.evaluate.placeholder": "Please enter your review",
  "webclient.dialog.evaluate.title": "Please evaluate the service of customer service!",
  "webclient.dialog.leave.format.content": "The message content cannot be empty and cannot contain special characters",
  "webclient.dialog.leave.format.phone": "The number cannot be empty and can contain only digits, - or +",
  "webclient.dialog.leave.phone": "Enter a mobile number",
  "webclient.dialog.leave.success": "Message left successfully",
  "webclient.dialog.leave.text": "Please leave a message",
  "webclient.dialog.leave.title": "Leave Message",
  "webclient.dialog.selectArea.title": "Select the area to be blocked",
  "webclient.dialog.video.default": "Default",
  "webclient.dialog.video.hide": "Hide local video",
  "webclient.dialog.video.large": "Large",
  "webclient.dialog.video.medium": "Medium",
  "webclient.dialog.video.pause": "Mute Video",
  "webclient.dialog.video.share.off": "Cancel Sharing",
  "webclient.dialog.video.share.on": "Desktop sharing",
  "webclient.dialog.video.size": "Video size",
  "webclient.dialog.video.small": "Small",
  "webclient.dialog.video.start": "Unmute video",
  "webclient.footer.label.send.message": "Send Message",
  "webclient.footer.label.share.direct": "Direct sharing",
  "webclient.footer.label.share.sensitive": "Selecting a sensitive area",
  "webclient.footer.label.stop.direct": "Stop sharing",
  "webclient.footer.placeholder.richtext": "Please enter the question you want to ask",
  "webclient.footer.send.fail.content.big": "The sent content exceeds the maximum length (500 characters)",
  "webclient.footer.send.fail.file.format": "The file format does not meet the requirements",
  "webclient.footer.send.fail.file.other": "Failed to send the file",
  "webclient.footer.send.fail.file.screen": "Failed to send the screen-shot file",
  "webclient.footer.send.fail.file.size.audio": "The Audio file size cannot exceed ",
  "webclient.footer.send.fail.file.size.common": "The file size cannot exceed ",
  "webclient.footer.send.fail.file.size.video": "The Video file size cannot exceed ",
  "webclient.footer.send.fail.no.file": "Please Select Files",
  "webclient.footer.send.fail.picture.with.text": "Currently, images cannot be sent together with texts or emoticons",
  "webclient.footer.tip.cobrowse.share": "This operation will share your browser page, which may contain your personal data. Are you sure you want to continue?",
  "webclient.footer.tip.connect.fail": "Connection failed, please try again or contact administrator",
  "webclient.footer.tip.download": "The chat content you download is in HTML format, and the multimedia content in the chat content is time-sensitive. It is recommended that you convert it to a PDF file immediately after downloading. Steps to convert HTML to PDF: 1. Open the HTML file to be converted in a browser; 2. Press Ctrl+P (Windows) or Command+P (Mac) to open the print dialog box; 3. In the print dialog box, select \"Destination Printer\" as \"Save As PDF\"; 4. Click the \"Save\" button to convert the HTML page to PDF format and save it to the local computer.",
  "webclient.footer.tooltip.audio": "Audio",
  "webclient.footer.tooltip.cobrowse": "Share",
  "webclient.footer.tooltip.download": "Download conversations",
  "webclient.footer.tooltip.emotion": "Emotion",
  "webclient.footer.tooltip.evaluation": "Evaluate",
  "webclient.footer.tooltip.file": "Upload Files",
  "webclient.footer.tooltip.more": "More",
  "webclient.footer.tooltip.send": "Send",
  "webclient.footer.tooltip.send.way": "Sending mode",
  "webclient.footer.tooltip.video": "Video",
  "webclient.header.title.input": "The other party is typing",
  "webclient.header.title.service": "Customer Service",
  "webclient.header.tooltip.close": "Close",
  "webclient.header.tooltip.transfer.agent": "Transfer to agent",
  "webclient.header.tooltip.transfer.robot": "Transfer to robot",
}