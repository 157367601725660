<template>
  <div class="oifde-empty-tip-wrap">
    <div class="oifde-empty-tip-text">{{ tip || $t('oifde.common.noData') }}</div>
  </div>
</template>

<script>
export default {
  name: 'EmptyTip',
  props: {
    tip: {
      type: String
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.oifde-empty-tip-wrap {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.oifde-empty-tip-text {
  color:#bfbfbf;
  font-size:14px;
}
</style>