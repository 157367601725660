<template>
  <div class="">
    <sweet-dialog
      :title="title"
      v-model="editVisible"
      :close-on-click-modal="false"
      width="616px"
      class="aicc-dialog-limit-height"
      align-center
      @close="onDialogClose"
    >
      <sweet-input
        class="user-allocate-search-input"
        v-model="searchVal"
        @keydown.enter="searchByName"
        @clear="searchByName"
        clearable="true"
        :placeholder="$t('cms.monitordetail.field.account')"
      >
        <template #prefix>
          <sweet-icon
            class="input-icon"
            @click="searchByName"
            v-model="searchVal"
            :title="$t('message.center.label.search')"
            :alt="$t('message.center.label.search')"
          >
            <search />
          </sweet-icon>
        </template>
      </sweet-input>
      <sweet-checkbox v-model="selectAllChecked" :label="$t('message.center.label.allSelect')" @change="selectAll" class="select-all"/>
      <aicc-table
        row-key="userId"
        :tableData="tableData"
        :tableColumns="tableColumns"
        :tableTools="{ showPagination: true, columnConfig: false }"
        :paginationConfig="paginationConfig"
        @handlePagination="handlePagination"
        @select="handleSelect"
        @select-all="handleSelectAll"
        height="430"
        ref="refAiccTable"
        class="employee-table"
      >
      </aicc-table>

      <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="resetForm">{{ $t("message.template.message.cancel") }}</sweet-button>
        <sweet-button type="primary" @click="confirmSelect">{{ $t("ccnotification.common.button.finish") }}</sweet-button>
      </span>
    </template>
    </sweet-dialog>
  </div>
</template>
  
<script>
import { getAllUser, queryAllUsers } from '../../../api/index'
export default {
  data() {
    return {
      limit: 10,
      offset: 0,
      initialSelected: [],
      selectAllChecked: this.allSelected,
      tableColumns: [
        {
          type: 'selection',
          radioKey: 'userId',
          width: 60,
          'reserve-selection': true
        },
        {
          label: this.$t('ccnotification.agent.label.account'),
          prop: 'userAccount',
          'show-overflow-tooltip': true
        },
        {
          label: this.$t('ccnotification.common.label.name'),
          prop: 'userName',
          'show-overflow-tooltip': true
        }
      ],
      tableData: [],
      selectedData: [],
      searchVal: '',
      editVisible: true,
      paginationConfig: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        layout: "sizes, total, prev, pager, next"
      },
      currentUserId: '',
      allUsers: []
    }
  },
  methods: {
    onDialogClose() {
      this.$emit('onClose')
    },
    clearSelection() {
      this.$refs.refAiccTable?.table.clearSelection()
      this.selectedData = []
    },
    searchByName() {
      this.$refs.refAiccTable.setCurrentPage(1)
      this.offset = 0
      this.searchUsers()
    },
    searchUsers(action) {
      let data = {
        offset: this.offset,
        limit: this.limit,
        sort: 'createdAt,userAccount'
      }
      if (this.searchVal) {
        data.userAccount = this.searchVal
      }
      queryAllUsers(this.cookieUtils.getCookie('tenant_space_id'), data).then((res) => {
        if (res && res.users) {
          this.tableData = res.users
          this.paginationConfig.total = res.total
          if (action) {
            action()
          }
          this.$nextTick(() => {
            this.toggleSelectedRow()
          })
        }
      })
    },
    handlePagination(val) {
      this.limit = val.limit
      this.offset = val.offset
      this.searchUsers()
    },
    resetForm() {
      this.editVisible = false
    },
    selectAll(checked) {
      if (checked) {
        this.selectedData = this.allUsers
        this.toggleSelectedRow()
      } else {
        this.clearSelection()
      }
    },
    handleSelect(selection, row) {
      this.selectAllChecked = false
      let item = this.selectedData.find(item => item.userId == row.userId)
      if (item) {
        this.selectedData = this.selectedData.filter(item => item.userId != row.userId)
      } else {
        this.selectedData.push(row)
      }
    },
    handleSelectAll(selection) {
      this.selectAllChecked = false
      //判断当前操作是选择当前页还是取消选择
      let isSelect = selection.some(item => item.userId == this.tableData[0].userId)
      if (isSelect) {
        let selectedIds = this.selectedData.map(item => item.userId)
        // 之前没有选择的行，添加到已选择
        this.tableData.filter(item => {
          return undefined == selectedIds.find(id => id == item.userId)
        }).forEach(item => {
          this.selectedData.push(item)
        })
      } else {
        let cancelIds = this.tableData.map(item => item.userId)
        // 表格页取消全选时，已选择数据去掉当前展示的数据
        this.selectedData = this.selectedData.filter(item => {
          return undefined == cancelIds.find(id => id == item.userId)
        })
      }
    },
    confirmSelect() {
      const allSelectUserInfo = this.selectedData.map((item) => {
        return {
          userAccount: item.userAccount,
          userId: item.userId,
          userName: item.userName
        }
      })
      this.$emit('selectUser', allSelectUserInfo, this.selectAllChecked)
      this.editVisible = false
    },
    toggleSelectedRow() {
      this.tableData.forEach(item => {
        for (let i = 0; i < this.selectedData.length; i++) {
          if (this.selectedData[i].userId == item.userId) {
            this.$refs.refAiccTable.table.toggleRowSelection(item, true)
          }
        }
      })
    },
    findUser(userId) {
      for (let index = 0; index < this.allUsers.length; index++) {
        const element = this.allUsers[index]
        if (element.userId == userId) {
          return {
            userAccount: element.userAccount,
            userId: element.userId,
            userName: element.userName
          }
        }
      }
    },
    init() {
      getAllUser().then(res => {
        if (res && res.users) {
          this.allUsers = res.users
          this.searchUsers(() => {
            if (this.selectedUsers) {
              this.selectedData = this.selectedUsers.map(user => {
                if (typeof user == 'string') {
                  return this.findUser(user)
                } else {
                  return user
                }
              })
            }
          })
        }
      })
    }
  },
  mounted() {
    this.init()
  },
  props: {
    title: {
      type: String
    },
    selectedUsers: {
      type: Array
    },
    allSelected: {
      type: Boolean,
      default: false
    }
  }
}
</script>
  
<style lang="less" scoped>
.user-allocate-search-input {
  width: 240px;
  height: 32px;
  :deep(.el-input__wrapper) {
    border-radius: 8px !important;
  }
}
.user-allocate-search-button {
  display: flex;
  justify-content: end;
}
.employee-table {
  margin-top: 20px;
}
.select-all {
  float: inline-end;
}
body.RTL {
  .user-allocate-search-button {
    justify-content: right;
    margin-top: 20px;
    :deep(button.el-button.el-button--primary) {
      margin-left: 0px !important;
      margin-right: 12px;
    }
  }
}
</style>

  