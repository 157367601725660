<template>
  <sweet-select :filterable="filterable" :placeholder="$t('aicc.select')">
    <sweet-option
      v-for="(item, index) in sortedData"
      :key="index"
      :label="item[defaultLabelKey]"
      :value="defaultValueKey === 'itemSelf' ? item : item[defaultValueKey]"
      :class="itemClass"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </sweet-option>
  </sweet-select>
</template>

<script>
export default {
  name: 'aicc-select',
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    sortBy: {
      type: String,
      default() {
        return 'asc'
      }
    },
    filterable: {
      type: Boolean,
      default() {
        return true
      }
    },
    defaultLabelKey: {
      type: String,
      default() {
        return 'label'
      }
    },
    defaultValueKey: {
      type: String,
      default() {
        return 'value'
      }
    },
    itemClass: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      sortedData: []
    }
  },
  watch: {
    options: {
      handler(newVal) {
        if (newVal) {
          try {
            newVal = JSON.parse(JSON.stringify(newVal))
          } catch (error) {
            console.log('parse select data to json failed')
          }
          this.sortedData = this.sortSelectData(newVal)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    sortSelectData(options) {
      let lessFlag
      let largerFlag
      if (this.sortBy === 'asc') {
        lessFlag = -1
        largerFlag = 1
      } else if (this.sortBy === 'desc') {
        lessFlag = 1
        largerFlag = -1
      } else {
        return
      }
      options.sort((a, b) => {
        if (a.label < b.label) {
          return lessFlag
        }
        if (a.label > b.label) {
          return largerFlag
        }
        return 0
      })
      return options
    }
  }
}
</script>
