<template>
  <div id="oifde-menus">
    <sweet-menu router :default-openeds="permissionStore.menuOpeneds" :default-active="permissionStore.firstRoute.path">
      <template v-for="item in menus" :key="item.path">
        <!--不需要展开子菜单-->
        <sweet-menu-item v-if="hasOneShowingChild(item.children)" :index="item.children[0].path">
          <aicc-tooltip
              :content="$t(getOnlyOneShowingChild(item.children).meta.title)"
              placement="right"
              :open-delay="800"
              effect="light"
              overflowTargetSelector=".oifde-menus-name"
          >
            <span class="oifde-menus-name">{{ $t(getOnlyOneShowingChild(item.children).meta.title) }}</span>
          </aicc-tooltip>
        </sweet-menu-item>
        <!--需要展开子菜单-->
        <sweet-submenu v-else :index="item.path">
          <template #title>
            <aicc-tooltip
                :content="$t(item.meta.title)"
                placement="right"
                :open-delay="800"
                effect="light"
                overflowTargetSelector=".oifde-menus-name"
            >
                <span class="oifde-menus-name">{{ $t(item.meta.title) }}</span>
            </aicc-tooltip>
          </template>
          <template v-for="child in item.children">
            <sweet-menu-item v-if="!child.hidden" :index="child.path">
              <template #title>
                <aicc-tooltip
                    :content="$t(child.meta.title)"
                    placement="right"
                    :open-delay="800"
                    effect="light"
                    overflowTargetSelector=".oifde-menus-name"
                >
                  <span class="oifde-menus-name">{{ $t(child.meta.title) }}</span>
                </aicc-tooltip>
              </template>
            </sweet-menu-item>
          </template>
        </sweet-submenu>
      </template>
    </sweet-menu>
  </div>
</template>
<script>
import { getAssetURL } from '@/utils'
import { useOifdePermissionStore } from '@oifde/stores/permission'
import interact from 'interactjs'

export default {
  name: 'oifde-menu',
  setup() {
    const permissionStore = useOifdePermissionStore()
    return {
      permissionStore
    }
  },
  props: {
    menus: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
    }
  },
  created() {
    this.initInteract()
  },
  methods: {
    getAssetURL,
    hasOneShowingChild(childrenItem = []) {
      const showingChildrenItem = childrenItem.filter(subItem => {
        if (subItem.hidden) {
          return false
        } else {
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildrenItem.length === 1) {
        return true
      }

      // Show parentItem if there are no child router to display
      if (showingChildrenItem.length === 0) {
        return true
      }

      return false
    },
    getOnlyOneShowingChild(childrenItem) {
      return childrenItem.filter(subItem => {
        if (subItem.hidden) {
          return false
        } else {
          return true
        }
      })[0]
    },
    initInteract() {
      interact('.aicc-oifde-menu').resizable({
        edges: window.isRTL ? { left: true } : { right: true },
        listeners: {
          move: function (event) {
            let { x, y } = event.target.dataset

            x = (parseFloat(x) || 0) + event.deltaRect.left
            y = (parseFloat(y) || 0) + event.deltaRect.top

            Object.assign(
                event.target.style,
                window.isRTL
                    ? {
                      width: `${event.rect.width}px`,
                      height: `${event.rect.height}px`
                    }
                    : {
                      width: `${event.rect.width}px`,
                      height: `${event.rect.height}px`,
                      transform: `translate(${x}px, ${y}px)`
                    }
            )

            Object.assign(event.target.dataset, { x, y })
          }
        },
        modifiers: [
          interact.modifiers.restrictSize({
            min: { width: 60 }
          })
        ]
      })
    },
  }
}
</script>

<style lang="less" scoped>
#oifde-menus {
  width: 200px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  height: calc(100vh - 145px);
}

// 不展示滚动条
#oifde-menus::-webkit-scrollbar {
  display: none;
}

.oifde-menus-name {
  margin-right: 5px;
  font-size: var(--14px-to-rem);
  line-height: 1.93;
  color: #1c1c1c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  font-weight: 500;
}

:deep(.el-menu) {
  background: #fff !important;
}

:deep(.el-menu--vertical .el-menu-item.is-active) {
  border: none !important;
}
</style>
