export default {
  'aicc.loading': 'Loading',
  'aicc.select': 'Select ', //加一个空格解决英语下显示中文的问题
  'aicc.numberOnly': 'Can contain only digits',
  'aicc.unorganized': 'Unorganized',
  'aicc.organized': 'Organized',
  'aicc.columnConfig': 'Column Configuration',
  'aicc.selectAll': 'Selected all',
  'aicc.selectedColumns': 'Selected columns',
  'aicc.selectedColumnsTip': '(can be dragged to arrange)',
  'aicc.columnSettings': 'Columns settings',
  'aicc.selectedInfo': ' {n} selected',
  'aicc.advancedSearch': 'Advanced Search',
  'aicc.lengthLimit': 'Length cannot exceed {limit} characters',
  'aicc.ok': 'OK',
  'aicc.error': 'Error',
  'aicc.search': 'Search',
  'aicc.reset': 'Reset',
  'aicc.skip': 'Skip',
  'aicc.goto': 'Go to check out',
  'aicc.more': 'More',
  'aicc.intRangeLimit': 'Enter an integer ranging from {min} to {max}',
  'aicc.MaxLength': 'Input a string consisting of a maximum of 0 characters',
  'aicc.previous': 'Previous',
  'aicc.next': 'Next',
  'aicc.noData': 'No data available',
  'aicc.lengthMinLimit': 'Must contain at least {0} characters',
  'aicc.traceId.error':
    'TraceId:0. Send the TraceId information to O&M personnel for fault locating.',
  'aicc.apibuilder.dev': 'Interface Development',
  'aicc.tenantSpace.management': 'Tenant Management',
  'aicc.timeout.desc':'Session Expiration',
  'aicc.timeout.solution':'Your login timed out. You need to log in again.',
  'aicc.input': 'Please enter',
}
