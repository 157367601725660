export default {
  "provision.callcenterinstanceslist.table.ccName":"Name des Callcenters",
  "provision.callcenterinstanceslist.table.callcenterinstanceslist":"Callcenter-Liste",
  "provision.common.title.tipsInfo":"Informationen",
  "provision.callcenterinstanceslist.table.gwIp":"IP des CC-Gateways",
  "provision.callcenterinstanceslist.table.gwPort":"CC-Gateway-Anschluss",
  "provision.callcenterinstanceslist.table.wasip":"Server-IP festlegen",
  "provision.callcenterinstanceslist.table.ccId":"Callcenter-ID",

  "provision.callcenterinstanceslist.table.agentMaxCapacity":"Kapazität des Agentensystems",
  "provision.callcenterinstanceslist.table.operate":"Betrieb",
  "provision.callcenterinstanceslist.table.wasport":"Legen Sie die Portnummer des Servers fest.",
  "provision.callcenterinstanceslist.table.curCapacityProportion":"Ist-Kapazitätsanteil",
  "provision.callcenterinstanceslist.table.create":"Neu",
  "provision.callcenterinstanceslist.table.agentUseRate":"Nutzung des Sprachagenten",
  "provision.callcenterinstanceslist.table.update":"Bearbeiten",
  "provision.callcenterinstanceslist.table.search":"Abfrage",
  "provision.callcenterinstanceslist.table.delete":"Löschen",
  "provision.callcenterinstanceslist.table.refresh":"Aktualisieren",
  "provision.callcenterinstanceslist.table.deleteConfimMessage":"Möchten Sie das ausgewählte Callcenter und die zugehörige Konfiguration wirklich löschen?",
  "provision.callcenterinstanceslist.table.synchronize":"Synchronisierung",
  "provision.callcenterinstanceslist.table.deleteconfim":"Löschvorgang bestätigen",
  "provision.callcenterinstanceslist.table.updateFault":"Sind Sie sicher, dass Sie die Fehlerstimme des virtuellen Zellenzentrums eingestellt haben",
  "provision.callcenterinstanceslist.table.obssyncConfimMessage":"Möchten Sie die OBS-Konfiguration wirklich synchronisieren?",
  "provision.callcenterinstanceslist.table.hasVcallcenterConfim":"Das Callcenter, das mit dem virtuellen Callcenter konfiguriert wurde, kann nicht gelöscht werden.",
  "provision.callcenterinstanceslist.table.hasaccesscodeAndivrConfim":"Das Löschen schlägt fehl. Es gibt einen Zugangscode oder IVR im Callcenter. Sie müssen sie manuell löschen.",
  "provision.callcenterinstanceslist.table.obssyncconfim":"Synchronisierung bestätigen",
  "provision.callcenterinstanceslist.table.resourceinfo":"Ressourceninformationen",
  "provision.callcenterinstanceslist.table.baseinfo":"Grundlegende Informationen",
  "provision.callcenterinstanceslist.table.agentCurCapacity":"Anzahl der Sprachagenten",
  "provision.callcenterinstanceslist.table.videoAgentCurCapacity":"Anzahl der Videoagenten",
  "provision.callcenterinstanceslist.table.maxVideoCallnums":"Max. gleichzeitige Videoanrufe",
  "provision.callcenterinstanceslist.table.maxcallnums":"Max. gleichzeitige Sprachanrufe",
  "provision.callcenterinstanceslist.table.videoIvrChannels":"Anzahl der Video-IVR-Kanäle",
  "provision.callcenterinstanceslist.table.videoAgentChannels":"Anzahl der Videoagentenkanäle",
  "provision.callcenterinstanceslist.table.ivrchannels":"Anzahl der Audio-IVR-Kanäle",
  "provision.callcenterinstanceslist.table.eMediaAgentCurCapacity":"Anzahl der Multimedia-Agenten",
  "provision.callcenterinstanceslist.table.ttschannelnums":"TTS-Anzahl",
  "provision.callcenterinstanceslist.table.ctinodesec":"Informationen zu CTI-Knoten 2,",
  "provision.callcenterinstanceslist.table.asrchannelnums":"ASR-Menge",
  "provision.callcenterinstanceslist.table.nodemodeselect":"Auswahlmodus",
  "provision.callcenterinstanceslist.table.nodemodesingle":"Einzelmodus",
  "provision.callcenterinstanceslist.table.nodeid":"Knoten-ID",
  "provision.callcenterinstanceslist.table.nodename":"Knotenname",
  "provision.callcenterinstanceslist.table.ctinodefir":"CTI-Knoteninformationen",
  "provision.callcenterinstanceslist.table.nodemoderesource":"Ressourcenmodus",
  "provision.callcenterinstanceslist.table.uapId":"UAP-ID",
  "provision.callcenterinstanceslist.table.nodemodepool":"Pool-Modus",
  "provision.callcenterinstanceslist.table.maxAmsCallNums":"Max. gleichzeitige anonyme Anrufe",
  "provision.callcenterinstanceslist.table.allowedTTSAndASRCallNum":"Anzahl der intelligenten IVR-Kanäle",
  "provision.callcenterinstanceslist.table.nodepolicyconfirmmessage":"Möchten Sie die Knotenrichtlinienkonfigurationen wirklich synchronisieren?",
  "provision.callcenterinstanceslist.table.versatileAgentCurCapacity":"Vielseitige Agenten",

  "provision.callcenterinstanceslist.nodepolicy.VNDId":"VDN-ID",
  "provision.callcenterinstanceslist.nodepolicy.tenantspacename":"VDN-Name",
  "provision.callcenterinstanceslist.nodepolicy.save":"Speichern",
  "provision.nodepolicy.sync.success":"Synchronisierung erfolgreich.",
  "provision.nodepolicy.sync.failed":"Synchronisierung fehlgeschlagen.",
  "provision.nodepolicy.save.success":"Erfolgreich gespeichert.",

  "provision.nodepolicy.save.failed":"Speichern fehlgeschlagen.",
  "provision.callcenterinstanceslist.label.maxcallnums":"Max. gleichzeitige Sprachanrufe",
  "provision.callcenterinstanceslist.label.callnumUseRate":"Nutzung gleichzeitiger Sprachanrufe",
  "provision.callcenterinstanceslist.label.videoAgentCurCapacity":"Anzahl der Videoagenten",
  "provision.callcenterinstanceslist.label.eMediaAgentCurCapacity":"Anzahl der Multimedia-Agenten",
  "provision.callcenterinstanceslist.label.videoAgentUseRate":"Nutzung des Videoagenten",
  "provision.callcenterinstanceslist.label.eMediaAgentUseRate":"Nutzung des Multimedia-Agenten",
  "provision.callcenterinstanceslist.label.videoIvrChannelUseRate":"Nutzung des Video-IVR-Kanals",
  "provision.callcenterinstanceslist.label.maxVideoCallnums":"Max. gleichzeitige Videoanrufe",
  "provision.callcenterinstanceslist.label.videoCallnumUseRate":"Nutzung gleichzeitiger Videoanrufe",
  "provision.callcenterinstanceslist.label.videoAgentChannelUseRate":"Kanalnutzung des Videoagenten",
  "provision.callcenterinstanceslist.label.amsCallNumUseRate":"Nutzung gleichzeitiger anonymer Anrufe",
  "provision.callcenterinstanceslist.label.ivrchannelUseRate":"Nutzung des Audio-IVR-Kanals",
  "provision.callcenterinstanceslist.label.maxAmsCallNums":"Gleichzeitige anonyme Anrufe",
  "provision.callcenterinstanceslist.label.ttschannelUseRate":"TTS-Nutzung",
  "provision.callcenterinstanceslist.label.asrchannelUseRate":"ASR-Nutzung",

  "provision.callcenterinstanceslist.callcenter":"Anrufzentrale",
  "provision.callcenterinstanceslist.config.title":"Callcenter-Konfiguration",
  "provision.callcenterinstanceslist.config.warning":"Warnung",
  "provision.callcenterinstanceslist.config.serviceIP":"Nginx IP",
  "provision.callcenterinstanceslist.config.servicePort":"Nginx-Anschluss",
  "provision.callcenterinstanceslist.config.odfsconfig":"ODFS-Serverkonfiguration",
  "provision.callcenterinstanceslist.config.nmsserviceIP":"ODFS-IP-Adresse",
  "provision.callcenterinstanceslist.config":"Anrufzentrale",
  "provision.callcenterinstanceslist.config.nmsservicePort":"ODFS-Portnummer",
  "provision.callcenterinstanceslist.config.odfsappkey":"ODFS-AppKey",
  "provision.callcenterinstanceslist.config.odfsappsercret":"ODFS-AppSercret",
  "provision.callcenterinstanceslist.config.odfsvalidate":"Alle ODFS-Servicekonfigurationen müssen leer sein oder dürfen nicht leer sein.",
  "provision.callcenterinstanceslist.config.wnFrom":"Mitarbeiter-ID starten",
  "provision.callcenterinstanceslist.config.odfconfigerror":"Die Konfigurationsinformationen des ODF-Dienstes sind falsch.",
  "provision.callcenterinstanceslist.config.adminWnTo":"Administrator-Endmitarbeiter-ID",
  "provision.callcenterinstanceslist.config.adminWnFrom":"Administrator-Startmitarbeiter-ID",
  "provision.callcenterinstanceslist.config.sysAdminpassword":"CTI-Administratorkennwort",
  "provision.callcenterinstanceslist.config.interfacepassword":"CTI-ConfigInterface-Kennwort",
  "provision.callcenterinstanceslist.config.passwordConfirm":"Kennwort bestätigen",
  "provision.callcenterinstanceslist.config.nextStep":"Weiter",
  "provision.callcenterinstanceslist.config.wnTo":"Ende Mitarbeiter-ID",
  "provision.callcenterinstanceslist.config.systemAdmin":"CTI-Administrator-ID",
  "provision.callcenterinstanceslist.config.previousStep":"Zurück",

  "provision.callcenterinstanceslist.config.saveAndnextStep":"Speichern und Weiter",
  "provision.callcenterinstanceslist.config.createsuccess":"Das Callcenter wurde erfolgreich erstellt.",
  "provision.callcenterinstanceslist.config.success":"Erfolg",
  "provision.callcenterinstanceslist.config.updatesuccess":"Das Callcenter wurde erfolgreich geändert.",
  "provision.callcenterinstanceslist.config.updatefailed":"Callcenter konnte nicht geändert werden.",
  "provision.callcenterinstanceslist.config.createfailed":"Callcenter konnte nicht erstellt werden.",
  "provision.callcenterinstanceslist.config.alreadyinuse":"Das Mitarbeiter-ID-Segment wurde verwendet.",
  "provision.callcenterinstanceslist.config.wnFromValidate":"Die Summe aus dem Startnummernsegment und der tatsächlichen Systemkapazität darf nicht größer als 50000 sein.",
  "provision.callcenterinstanceslist.config.agentCurCapacityValidate":"Die Anzahl der Sprachagenten ist geringer als die Anzahl der Sprachplätze, die tatsächlich vom Callcenter zugewiesen wurden.",
  "provision.callcenterinstanceslist.config.error":"Fehler",
  "provision.callcenterinstanceslist.config.ccNameUsed":"Der Name des Callcenters wurde verwendet.",
  "provision.callcenterinstanceslist.config.videoAgentCurCapacityValidate":"Die Anzahl der Videoplätze ist geringer als die Anzahl der Videoplätze, die im Callcenter tatsächlich zugewiesen wurden.",
  "provision.callcenterinstanceslist.config.maxVideoCallnumsValidate":"Die maximale Anzahl gleichzeitiger Videoanrufe ist geringer als die Anzahl der Videoanrufe, die tatsächlich vom Call Center zugeteilt wurden.",
  "provision.callcenterinstanceslist.config.ivrchannelsValidate":"Die Anzahl der Audio-IVR-Kanäle ist kleiner als die Anzahl der Kanäle, die im Callcenter tatsächlich zugewiesen sind.",
  "provision.callcenterinstanceslist.config.ttschannelnumsValidate":"Die Anzahl der TTS-Kanäle ist kleiner als die Anzahl der tatsächlich im Callcenter zugewiesenen Kanäle.",
  "provision.callcenterinstanceslist.config.asrchannelnumsValidate":"Die Anzahl der ASR-Kanäle ist kleiner als die Anzahl der Kanäle, die im Callcenter tatsächlich zugewiesen sind.",
  "provision.callcenterinstanceslist.config.videoIvrchannelsValidate":"Die Anzahl der Video-IVR-Kanäle ist kleiner als die Anzahl der Kanäle, die im Callcenter tatsächlich zugewiesen sind.",
  "provision.callcenterinstanceslist.config.eMediaAgentCurCapacityValidate":"Die Anzahl der Multimedia-Agenten ist geringer als die Anzahl der tatsächlich im Callcenter zugewiesenen Multimedia-Plätze.",
  "provision.callcenterinstanceslist.config.resourceError":"Die geänderten Ressourceninformationen sind kleiner als die tatsächliche Anzahl der Callcenter.",
  "provision.callcenterinstanceslist.config.maxcallnumsValidate":"Die maximale Anzahl gleichzeitiger Sprachanrufe ist geringer als die Anzahl der Sprachanrufe, die tatsächlich vom Callcenter zugewiesen wurden.",
  "provision.callcenterinstanceslist.config.ccIdError":"Die Callcenter-Nummer wurde verwendet.",
  "provision.callcenterinstanceslist.config.videocallnumsError":"Die maximale Anzahl gleichzeitiger Videoanrufe muss größer oder gleich der Anzahl der Videoplätze sein.",
  "provision.callcenterinstanceslist.config.videoAgentChannelsValidate":"Die Anzahl der Videoagentenkanäle ist kleiner als die Anzahl der Kanäle, die im Callcenter tatsächlich zugewiesen sind.",
  "provision.callcenterinstanceslist.config.ccNameUpdateError":"Ändern Sie den Callcenter-Namen nicht, wenn Sie das Callcenter bearbeiten",
  "provision.callcenterinstanceslist.config.ccIdUpdateError":"Die Callcenter-ID beim Bearbeiten des Callcenters nicht ändern",
  "provision.callcenterinstanceslist.config.ivrnumsError":"Die Anzahl der IVR-Kanäle muss größer sein als die Summe der Anzahl der TTS-Kanäle und der Anzahl der ASR-Kanäle.",
  "provision.callcenterinstanceslist.config.voicecallnumsError":"Die maximale Anzahl gleichzeitiger Sprachanrufe muss größer oder gleich der Summe aus Sprachagenten\, Sprach-IVR-Kanälen\ und Video-IVR-Kanälen sein.",
  "provision.callcenterinstanceslist.config.adminWorkNoError":"Die Startnummer der Administrator-Mitarbeiter-ID muss zwischen 55001 und 59999 liegen.",
  "provision.callcenterinstanceslist.config.workAlreadyOccupied":"Die Mitarbeiter-ID wird verwendet",
  "provision.callcenterinstanceslist.config.workNoError":"Die Anfangsmitarbeiter-ID muss zwischen 101 und 50000 liegen.",
  "provision.callcenterinstanceslist.config.workNoCheckError":"Die Startnummer der Arbeits-ID muss kleiner als die Endnummer sein.",
  "provision.callcenterinstanceslist.config.nodeIdCheckError":"Stellen Sie sicher, dass die eingegebenen CTI-Knoteninformationen ID\, uapId\ und Name eindeutig sind.",
  "provision.callcenterinstanceslist.config.adminWorkAlreadyOccupied":"Die Mitarbeiter-ID des Administrators wird verwendet",
  "provision.callcenterinstanceslist.config.confirm":"Konfigurieren Sie die Informationen über die Knoten, die mit der WAS-Plattform verbunden sind.",
  "provision.callcenterinstanceslist.config.maxAmsCallNumsValidate":"Die maximale Anzahl gleichzeitiger anonymer Anrufe darf nicht geringer sein als die Anzahl der vom Callcenter zugewiesenen anonymen Anrufe.",
  "provision.callcenterinstanceslist.config.uapId":"Dieser Wert muss mit der UAP-ID auf dem WAS übereinstimmen.",

  "provision.callcenterinstance.config.maxcallnumsCompareValidate":"Die maximale Anzahl gleichzeitiger Sprachanrufe muss größer oder gleich der Summe aus Sprachagenten\, Sprach-IVR-Kanälen\ und Video-IVR-Kanälen sein.",
  "provision.callcenterinstance.config.ivrchannelsValidate":"Die Summe der Anzahl der Audio- und Video-IVR-Kanäle muss größer oder gleich der Summe der Anzahl der TTS-Kanäle und der Anzahl der ASR-Kanäle sein.",
  "provision.callcenterinstance.config.videoCallValidate":"Die maximale Anzahl gleichzeitiger Videoanrufe muss größer oder gleich der Anzahl der Videoplätze sein.",
  "provision.callcenterinstanceslist.vcallcenter.accessCode":"Zugangscode",
  "provision.callcenterinstanceslist.vcallcenter.vccName":"VDN-Name",
  "provision.callcenterinstanceslist.vcallcenter.nodepolicy":"Konfigurieren der Knotenauswahlrichtlinie für abgehende Anrufressourcen",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer":"SBC-Server",
  "provision.callcenterinstanceslist.vcallcenter.accessCodeConfig":"Konfiguration von AccessCode",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer.title":"SBC-Serverkonfiguration",
  "provision.callcenterinstanceslist.vcallcenter.ctiInfo ":"CTI-Ressourcengruppen-ID",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenter":"Virtuelles Callcenter",
  "provision.callcenterinstanceslist.vcallcenterDeatil.defaultCallerNum":"Standardanrufnummer",
  "provision.callcenterinstanceslist.vcallcenterDeatil.sipServerIpAndPort":"SBC-Adresse",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed":"Das Kennwort muss mindestens drei der folgenden Typen enthalten: Großbuchstaben\, Kleinbuchstaben\, Ziffern\ und folgende spezielle characters: ~! {'@'} # $ % ^ & * () -",
"provision.callcenterinstanceslist.vcallcenterDeatil.save":"Speichern",
"provision.callcenterinstanceslist.vcallcenterDeatil.saveerror":"Speichern fehlgeschlagen.",
"provision.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed":"Der Name des Callcenters darf keine Sonderzeichen enthalten.",
"provision.callcenterinstanceslist.vcallcenterDeatil.passLengthValidateFailed":"Ein Kennwort muss mindestens acht Zeichen enthalten.",
"provision.callcenterinstanceslist.vcallcenterDeatil.odfsStrValidateFailed":"Es sind nur Buchstaben und Ziffern zulässig.",
"provision.callcenterinstanceslist.vcallcenterDeatil.odfsPasswordKeyLengthValidateFailed":"Die Länge darf 50 Zeichen nicht überschreiten.",
"provision.callcenterinstanceslist.vcallcenter.OBSServer":"OBS-Server für ausgehende Anrufe",
"provision.callcenterinstanceslist.softPhone.softPhoneNum.title":"Softphone-Konfiguration",
"provision.callcenterinstanceslist.softPhone.softPhoneNum":"Softphone-Nummer",
"provision.callcenterinstanceslist.softPhone.softPhoneAdd":"Neue Softphone-Nummer",
"provision.callcenterinstanceslist.softPhone.newpassword":"Neues Softphone-Passwort",
"provision.callcenterinstanceslist.softPhone.passwordconf":"Kennwort bestätigen",
"provision.callcenterinstanceslist.softPhone.password":"Kennwort",
"provision.callcenterinstanceslist.softPhone.modifyPassword":"Kennwort ändern",
"provision.callcenterinstanceslist.softPhone.tenantname":"Name des Mieters",
"provision.callcenterinstanceslist.softPhone.newpasswordconf":"Neues Kennwort wiederholen",
"provision.callcenterinstanceslist.softPhone.singledeleteconfim":"Möchten Sie die ausgewählten Softphone-Nummern wirklich löschen? Stellen Sie sicher, dass die Softphone-Nummer keinem virtuellen Callcenter zugeordnet ist.",
"provision.callcenterinstanceslist.softPhone.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten Softphone-Nummern löschen? Stellen Sie sicher, dass die Softphone-Nummer keinem virtuellen Callcenter zugeordnet ist.",
"provision.callcenterinstanceslist.softPhone.accountpassword":"Kontokennwort",
"provision.callcenterinstanceslist.softPhone.stautus.unoccupied":"Nicht verwendet",
"provision.callcenterinstanceslist.softPhone.stautus.occupied":"Wird verwendet",
"provision.callcenterinstanceslist.softPhone.passLengthValidateFailed":"Das Kennwort muss 8 bis 16 Zeichen enthalten.",
"provision.callcenterinstanceslist.softPhone.stautus.select":"Wählen Sie den Status aus",
"provision.callcenterinstanceslist.softPhone.passValidateFailed":"Muss mindestens 3 Großbuchstaben\, Kleinbuchstaben\, Zahlen\, Sonderzeichen ~{'@'}^*_+.?.",
"provision.callcenterinstanceslist.softPhone.beginNumber":"Startnummer",
"provision.callcenterinstanceslist.softPhone.batchadd":"Batch hinzufügen",
"provision.callcenterinstanceslist.softPhone.endNumber":"Endnummer",
"provision.callcenterinstances.agentWasPassLengthValidateFailed":"Das Kennwort muss 8 bis 32 Zeichen enthalten.",
"provision.callcenterinstance.softPhone.validateSoftPhoneNumrange":"Der Bereich neu hinzugefügter Nummern darf 1000 nicht überschreiten",
"provision.callcenterinstance.softPhone.batchcreate.fail.repeated":"Alle Nummern im neuen Nummernsegment sind bereits vorhanden.",
"provision.callcenterinstance.softPhone.length.Inconsistent":"Die Längen der Start- und Endnummer sind inkonsistent.",
"provision.callcenterinstanceslist.sipServer.sipServerIp":"IP-Adresse des SBC-Servers",
"provision.callcenterinstance.softPhone.validatebeginSoftPhoneNum":"Softphone-Startnummer darf nicht größer als Endnummer sein",
"provision.callcenterinstanceslist.sipServer.sipServerIpAdd":"Hinzufügen",
"provision.callcenterinstanceslist.sipServer.sipServerIpUpdate":"SIP-Serverkonfiguration",
"provision.callcenterinstanceslist.sipServer.sipServerType":"SBC-Servertyp",
"provision.callcenterinstanceslist.sipServer.idleRegAgentNum":"Verbleibende Parallelität",
"provision.callcenterinstanceslist.sipServer.name":"Name",
"provision.callcenterinstanceslist.sipServer.sipServerPort":"SBC-Portnummer",
"provision.callcenterinstanceslist.sipServer.tenantname":"Name des Mieters",
"provision.callcenterinstanceslist.sipServer.description":"Beschreibung",
"provision.callcenterinstanceslist.sipServer.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten Registrierungsserver löschen?",
"provision.callcenterinstanceslist.sipServer.singledeleteconfim":"Möchten Sie den ausgewählten Registrierungsserver wirklich löschen?",
"provision.ccworknos.table.ccworknos":"Mitarbeiter-ID",
"provision.ccworknos.table.ccworkStartNo":"Mitarbeiter-ID starten",
"provision.callcenterinstanceslist.sipServer.maxRegAgentNum":"Anzahl gleichzeitiger Anforderungen",
"provision.ccworknos.table.ccworkQuantity":"Zahlenmenge im Zahlensegment",
"provision.callcenterinstanceslist.obsServer.obsServerIpUpdate":"Ändern",
"provision.ccworknos.table.ccworkEndNo":"Ende Mitarbeiter-ID",
"provision.callcenterinstanceslist.obsServer.obsServerIpAdd":"Hinzufügen",
"provision.callcenterinstanceslist.obsServer.obsServerIpSync":"Synchronisierung",
"provision.callcenterinstanceslist.obsServer.progId":"Programm-ID",
"provision.callcenterinstanceslist.obsServer.obsId":"OBS-ID",
"provision.callcenterinstanceslist.obsServer.obsName":"OBS-Name",
"provision.callcenterinstanceslist.obsServer.activeObsServerIp":"IP des aktiven Obs-Servers",
"provision.callcenterinstanceslist.obsServer.activeObsDirectHeartbeatIp":"Gibt die Heartbeat-IP-Adresse des aktiven OBS an",
"provision.callcenterinstanceslist.obsServer.standbyObsDirectHeartbeatIp":"Gibt die Heartbeat-IP-Adresse des Standby-OBS an",
"provision.callcenterinstanceslist.obsServer.heartbeatPort":"Heartbeat-Anschluss",
"provision.callcenterinstanceslist.obsServer.standbyObsServerIp":"Standby-Obs-Server-IP",
"provision.callcenterinstanceslist.obsServer.config.explain":"Der Wert muss mit der OBS-Konfiguration auf der CTI-Plattform übereinstimmen.",
"provision.callcenterinstanceslist.obsServer.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten Registrierungsserver löschen?",
"provision.callcenterinstanceslist.obsServer.singledeleteconfim":"Möchten Sie den ausgewählten Registrierungsserver wirklich löschen?",
"provision.callcent.error.deleteobsserver.associationvcallcener":"Die ausgewählte Obs-Servernummer ist dem Mandanten zugeordnet und kann nicht gelöscht werden.",
"provision.callcent.error.deleteobsserverdesinfo":"Stellen Sie sicher, dass der Obs-Servername nicht existiert.",
"provision.callcent.error.deleteobsserver.part.associationvcallcener":"Einige der ausgewählten Obs-Server sind mit dem Mandanten verknüpft und können nicht gelöscht werden.",
"provision.callcent.selectobsserver":"Wählen Sie einen Obs-Server aus.",
"provision.callcent.error.ipValidateFailed":"IP-Adresse wurde bestätigt.",

"provision.callcent.error.addcallcenterdesinfo":"Stellen Sie sicher, dass das Callcenter nicht existiert.",
"provision.callcent.error.portoutsiderange":"HeartbeatPort liegt außerhalb des Bereichs von 1 bis 65535.",
"provision.callcent.error.paramUnique":"Einzigartiges Param existiert.",
"provision.callcent.error.specialStrValidateFailed":"Der Obs-Name darf keine Sonderzeichen enthalten.",

"provision.callcent.error.portValidateFailed":"HeartbeatPort bedeutet keine Zahl.",
"provision.callcent.error.paramNotavailable":"Anforderungsparameter ist leer.",
"provision.accesscode.table.accessCode":"Zugangscode",
"provision.callcent.error.modifyParamUnique":"Aktive ObsServerIp ist bereits vorhanden.",
"provision.accesscode.table.tenantname":"Mieter",
"provision.accesscode.table.n400Number":"Servicenummer",
"provision.ccworknos.table.vCCInstId":"Virtuelles Callcenter",
"provision.accesscode.table.mediatypeId":"Medientyp",
"provision.accesscode.table.acId":"Zugangscodenummer",
"provision.accesscode.table.multimedia":"Multimedia",
"provision.callcentaccesscodes.table.callcentaccesscodes":"Zugangscode",
"provision.accesscode.table.audioAndvideo":"Audio und Video",
"provision.accesscode.table.accessCode.selecttype":"Wählen Sie einen Typ aus",
"provision.accesscode.table.accessCode.clicktodial":"Klicken Sie zum Wählen",
"provision.accesscode.table.whetherSupportTrialUse":"Support Testnutzung",
"provision.accesscode.table.endNumber":"Endzugriffscode",
"provision.accesscode.table.whetherSupportTrialUse_Y":"Ja",
"provision.accesscode.table.beginNumber":"Zugangscode starten",
"provision.callcenterinstance.accesscode.validateaccesscode":"Geben Sie ein korrektes Nummernsegment ein.",
"provision.callcenterinstance.accesscode.validateaccesscoderange":"Es können maximal 100 Zugriffscodes stapelweise hinzugefügt werden.",
"provision.callcenterinstance.accesscode.validatebeginaccesscode":"Die Startnummer des Zugangscodes darf nicht größer als die Endnummer sein.",
"provision.callcenterinstance.accesscode.batchcreate.fail.repeated":"Alle Zugangscodes im neuen Nummernsegment sind bereits vorhanden.",
"provision.accesscode.table.whetherSupportTrialUse_N":"Nein",
"provision.callcenterinstance.accesscode.batchcreate.fail.processingtask":"Abarbeitung der Aufgabe der stapelweisen Erstellung von Zugangscodes",
"provision.callcent.selectvcallcent":"Wählen Sie ein virtuelles Callcenter aus.",

"provision.callcent.selectcallcent":"Wählen Sie ein Callcenter aus.",
"provision.callcent.selectaccesscode":"Wählen Sie einen Zugangscode.",
"provision.callcent.selectamsserver.inconsistenttype":"Servertyp für anonyme Anrufe ist nicht konsistent",
"provision.callcent.selectsipserver.inconsistenttype":"Agentenregistrierungsservertyp ist nicht konsistent",
"provision.callcent.selectsoftnumber":"Wählen Sie eine Softphone-Nummer aus.",
"provision.callcent.selectsipserver":"Wählen Sie einen Agentenregistrierungsserver aus.",
"provision.callcent.label.openFaule":"OpenFault",
"provision.callcent.label.closeFaule":"CloseFault",
"provision.callcent.label.cancel":"Abbrechen",
"provision.callcent.label.close":"Schließen",
"provision.callcent.label.submit":"Senden",
"provision.callcent.label.finish":"Abgeschlossen",
"provision.callcent.label.reset":"Zurücksetzen",
"provision.callcent.success.save":"Erfolg",
"provision.callcent.success.saveinfo":"Erstellt erfolgreich.",
"provision.callcent.error.errorinfo101":"ccm-Synchronisierung fehlgeschlagen.",
"provision.ccworknos.table.creataccessCode":"Neu",
"provision.callcent.error.errorinfo":"Erstellung fehlgeschlagen.",
"provision.callcent.error.save":"Fehler",
"provision.callcent.success.updateinfo":"Aktualisiert erfolgreich.",
"provision.callcent.success.delete":"Löschung erfolgreich",
"provision.callcent.error.updateinfo":"Aktualisierung fehlgeschlagen.",
"provision.ccworknos.table.updateaccessCode":"Bearbeiten",
"provision.callcent.error.setinfo":"Seting fehlgeschlagen.",
"provision.callcent.success.deleteinfo":"Erfolgreich gelöscht.",
"provision.callcent.error.partSetinfo":"Auswahl des Teilesatzes fehlgeschlagen.",
"provision.callcent.error.delete":"Löschfehler",
"provision.callcent.error.deleteinfo":"Löschen fehlgeschlagen.",
"provision.callcent.error.partdeleteinfo":"Der Inhalt, der mit einem virtuellen Callcenter verknüpft ist, kann nicht gelöscht werden.",
"provision.callcenterccaccesscodeslist.table.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten Zugangscodes löschen? Stellen Sie sicher, dass der Zugangscode keinem virtuellen Callcenter zugeordnet ist.",
"provision.callcenterccaccesscodeslist.table.singledeleteconfim":"Möchten Sie den ausgewählten Zugangscode wirklich löschen? Stellen Sie sicher, dass der Zugangscode keinem virtuellen Callcenter zugeordnet ist.",
"provision.callcent.success.setinfo":"Erfolgreich festgelegt.",
"provision.callcent.error.saveccaccesscodeinfo":". Stellen Sie sicher, dass der Zugangscode nicht existiert.",
"provision.callcent.error.savesipserverdesinfo":". Stellen Sie sicher, dass der sbc-Servername nicht existiert.",
"provision.callcent.error.savesipserverdesinfo.regAgentNum":". Die Anzahl der gleichzeitig registrierten Agenten unterscheidet sich von der Anzahl der verbleibenden registrierten Arbeitsplätze.",
"provision.callcent.error.savesipserverdesinfo.maxRegAgentNumError":". Die Anzahl der modifizierten gleichzeitig registrierten Agenten ist kleiner als die tatsächliche Anzahl der registrierten Arbeitsplätze.",
"provision.callcent.success.oj8k":"Abgeschlossen",
"provision.callcent.error.savesipserverdesinfo.regAgentNumError":"Die Anzahl der modifizierten gleichzeitig registrierten Agenten ist kleiner als die tatsächliche Anzahl der registrierten Arbeitsplätze.",
"provision.callcent.error.savesipserverdesinfo.sipServernoexist":"\,-SBC-Server existiert nicht",
"provision.callcent.error.savesipserveripinfo":". Stellen Sie sicher, dass die IP-Adresse und die Portnummer des Servers nicht vorhanden sind.",
"provision.callcent.error.createaccesscode.fail.onccgateway":"\, Das Hinzufügen eines Zugriffscodes zur Plattform ist fehlgeschlagen.",
"provision.callcent.error.updateSoftNumPasswordInfo":"Kontoinformationsfehler",
"provision.callcent.error.repetenter":"Die Nummer wurde verwendet. Geben Sie erneut eine andere Nummer ein.",
"provision.callcent.error.createaccesscode.validate.servicenumberContainServicenumber":"Die Servicenummer darf keine Integrationsbeziehung zu anderen Servicenummern im Callcenter haben.",
"provision.callcent.error.createaccesscode.n400Numbervalidate":"Zugangscode und Servicenummer können nicht in einer umfassenden Beziehung stehen.",
"provision.callcent.error.createaccesscode.validate.accessCodeContainAccesscode":"Der Zugangscode darf keine Inklusionsbeziehung zu anderen Zugangscodes im Callcenter enthalten.",
"provision.callcent.error.createaccesscode.fail.ondatabase":"\, Erstellen eines Zugriffscodes in der Datenbank fehlgeschlagen",
"provision.callcent.error.edit":"Bearbeitung fehlgeschlagen.",

"provision.callcent.error.updateerrorinfo":"Der Zugangscode wurde einem virtuellen Callcenter zugeordnet und kann nicht bearbeitet werden.",
"provision.callcent.error.updateaccesscode.fail.accesscodenoexist":"\, Überprüfen Sie, ob der Zugangscode existiert und einem virtuellen Callcenter zugeordnet ist.",
"provision.callcent.error.updateaccesscode.fail.syncccmanagement":"\, synchrone Änderung der Daten zum Mietzimmer fehlgeschlagen",
"provision.callcent.error.updateaccesscode.fail.ondatabase":"\, konnte den Zugriffscode in der Datenbank nicht ändern",
"provision.callcent.error.accesscode.deletefail.sqlexception":"Löschen fehlgeschlagen\, SQL-Ausführungsausnahme",
"provision.callcent.error.accesscode.deletefail":"Löschen fehlgeschlagen\, der Eintrag ist leer",
"provision.callcent.error.accesscode.deletefail.accesscodenoexist":"Löschen fehlgeschlagen\, Zugriffscode existiert nicht",
"provision.callcent.error.accesscode.deletefail.ondatabase":"Zugriffscode in Datenbank konnte nicht gelöscht werden",
"provision.callcent.error.accesscode.deletefail.all.onccgateway":"Zugriffscode konnte auf der Plattform nicht gelöscht werden",
"provision.callcent.error.accesscode.deletefail.all.onccgateway.with.associationvcallcener":"Alle Löschvorgänge sind fehlgeschlagen, da ein Teil des Zugangscodes mit dem Mandanten verknüpft ist\ und einige Zugangscodes auf der Plattform gelöscht wurden.",
"provision.callcent.error.accesscode.deletefail.part.associationvcallcener":"Einige Zugriffscodes sind Mandanten zugeordnet und können nicht gelöscht werden.",
"provision.callcent.error.accesscode.deletefail.part.onccgateway":"Ein Teil des Zugangscodes konnte auf der Plattform nicht gelöscht werden.",
"provision.callcent.error.accesscode.deletefail.all.associationvcallcener":"Der Zugriffscode ist dem Mandanten zugeordnet und kann nicht gelöscht werden.",
"provision.callcent.error.accesscode.deletefail.part.onccgateway.with.associationvcallcener":"Teilweises Löschen fehlgeschlagen, da ein Teil des Zugriffscodes mit dem Mandanten verknüpft ist\ und ein Teil des Zugriffscodes auf der Plattform nicht gelöscht werden konnte.",
"provision.callcent.error.deletesoftphonenumber.part.associationvcallcener":"Einige der ausgewählten Softphone-Nummern sind dem Mandanten zugeordnet und können nicht gelöscht werden.",
"provision.accesscode.error.transfer.queryinterface":"Abfrage der Plattformdaten fehlgeschlagen.",
"provision.callcent.error.deletesoftphonenumber.associationvcallcener":"Die ausgewählte Softphone-Nummer ist dem Mandanten zugeordnet und kann nicht gelöscht werden.",
"provision.callcent.error.updatesoftphonenumber.ondatabase":"Softphone-Nummer in Datenbank konnte nicht geändert werden.",
"provision.callcent.success.deletesoftphonenumber.processed":"Daten werden aus dem Hintergrund gelöscht ... Bitte überprüfen Sie dies später.",
"provision.callcent.success.deletesoftphonenumber.unfinishtask":"Eine Löschaufgabe wird verarbeitet. Bitte versuchen Sie es später erneut.",
"provision.callcent.error.updatesoftphonenumber.fail.syncccmanagement":"Synchronisieren des Softphones zum Ändern der Informationen zum Mietzimmer fehlgeschlagen.",
"provision.callcent.error.updatesoftphonenumber.noexist":"Soft-Telefonnummer existiert nicht.",
"provision.callcent.error.updatesipserver.ondatabase":"Das Ändern des SBC-Servers in der Datenbank ist fehlgeschlagen.",
"provision.callcent.error.updatesipserver.ipandport.alreadyexist":"Die IP-Adresse und die Portnummer des SBC-Servers sind bereits vorhanden und können nicht geändert werden.",
"provision.callcent.error.updatesipserver.fail.syncccmanagement":"Synchronisieren des SBC-Servers zum Ändern der Informationen zum Mietraum fehlgeschlagen.",
"provision.callcent.error.updatesipserver.noexist":"SIP-Server existiert nicht.",
"provision.callcent.error.deletesipserver.associationvcallcener":"Der ausgewählte SIP-Server ist dem Mandanten zugeordnet und kann nicht gelöscht werden.",
"provision.callcent.error.deletesipserver.part.associationvcallcener":"Einige der ausgewählten SIP-Server sind Mandanten zugeordnet und können nicht gelöscht werden.",
"provision.tenantspace.table.tenantSpaceName":"Tenant Space",
"provision.callcent.error.checkparam":"Enthält ungültige characters(\<\\\,\>\\\,\\\\and mehr)",

"provision.tenantspace.table.chooseStatus":"Status des Tenant-Bereichs",
"provision.tenantspace.table.tenantSpaceStatus":"Status",
"provision.tenantspace.vitualCenterName":"Virtuelles Callcenter",
"provision.tenantspace.reset":"Zurücksetzen",
"provision.tenantspace.search":"Abfrage",
"provision.tenantspace.config.agentUsage":"Agenten",
"provision.tenant.management.adminaccount":"Administratorkonto",
"provision.tenant.management.tenantname":"Tenant Space",
"provision.tenant.management.email":"E-Mail-Adresse",
"provision.tenant.management.adminpasswordagain":"Kennwort bestätigen",
"provision.tenant.management.phonenumber":"Telefonnummer",
"provision.tenant.management.tenantspaceid":"Mieter-Raum-ID",
"provision.tenant.management.adminpassword":"Kennwort",
"provision.tenant.management.partdbId":"Datenbankpartitions-ID",
"provision.tenant.management.mobile":"Mobiltelefonnummer",
"provision.tenant.management.adminname":"Verwaltungsrat",
"provision.tenant.management.agentnum":"Agenten",
"provision.tenant.management.tenantid":"Mieter-ID",
"provision.tenant.management.tenantcreate":"Neuer Mieter",
"provision.tenant.management.accesscode":"Zugangscode",
"provision.tenant.management.administratorinfo":"Tenant-Administratorinformationen",
"provision.tenant.management.tenantedit":"Mieterinformationen bearbeiten",
"provision.tenant.management.admininfo":"Mandantenadministrator registrieren",
"provision.tenant.management.languagetype":"Sprachtyp",
"provision.tenant.management.pwd.checkmsg":"Die beiden Passwörter sind unterschiedlich.",
"provision.tenant.management.button.ok":"Abgeschlossen",
"provision.tenant.management.tenantname.minlength":"Der Name des Mandantenbereichs darf nicht weniger als 6 Zeichen enthalten.",
"provision.tenant.management.tenantname.alphavalidateFailed":"Es gibt ungültige Zeichen. Es werden nur Buchstaben\, Ziffern\ und Unterstriche (_) unterstützt.",
"provision.tenant.management.select.accesscode":"Zugangscode auswählen",
"provision.tenant.management.tenantname.beginwithalpha":"muss mit einem Buchstaben beginnen.",
"provision.tenant.management.updatetenant.success":"Die Mandantenbereichskonfiguration wurde erfolgreich geändert.",
"provision.tenant.management.edit.agentusage.mustinc":"Die Anzahl der neuen Agenten darf nicht geringer sein als die der ursprünglichen Agenten.",

"provision.tenant.management.updatetenant.failed":"Die Konfiguration des Mandantenbereichs konnte nicht geändert werden.",
"provision.tenant.management.tenantcreate.emailillegal":"Ungültige E-Mail-Adresse.",
"provision.tenant.management.tenantcreate.mobileillegal":"Ungültige Mobiltelefonnummer.",
"promotion.tenant.management.tenantedit.exception":"Auf dem Server ist eine Ausnahme aufgetreten.",
"provision.tenant.management.tenantcreate.failed":"Der Mieterraum konnte nicht erstellt werden. Stellen Sie sicher, dass der Mandantenname oder das Administratorkonto eindeutig ist.",
"promotion.tenant.management.tenantedit.provisionerror":"Der Mandantenbereich konnte nicht aktualisiert werden.",
"provision.tenant.management.tenantcreate.success":"Der Mieterraum wurde erfolgreich erstellt.",
"promotion.tenant.management.tenantedit.registerserver.notavailable":"Der Registrierungsserver ist nicht verfügbar.",
"promotion.tenant.management.tenantedit.vdncreate.failed":"Die VDN-Informationen konnten nicht erstellt oder geändert werden.",
"promotion.tenant.management.tenantedit.softnumber.insufficient":"Die Anzahl der Softphones ist unzureichend.",
"promotion.tenant.management.tenantedit.vdnsync.failed":"Daten konnten nicht mit dem virtuellen Callcenter synchronisiert werden.",
"promotion.tenant.management.tenantedit.vcccapacity.insufficient":"Die Kapazität des virtuellen Zentrums ist unzureichend.",
"promotion.tenant.management.tenantedit.vccupdate.failed":"Das Virtual Center konnte nicht aktualisiert werden.",
"promotion.tenant.management.tenantedit.accesscode.noavailable":"Der Zugangscode ist nicht verfügbar.",
"promotion.tenant.management.tenantedit.cc.null":"Die ID des Callcenters, zu dem das virtuelle Callcenter gehört, ist leer.",

"provision.tenantspace.table.update":"Bearbeiten",
"provision.tenantspace.table.delete":"Löschen",
"promotion.tenant.management.tenantedit.vcc.notexist":"Das virtuelle Callcenter existiert nicht.",
"provision.tenantspace.refresh":"Aktualisieren",
"provision.tenantspace.table.config":"Konfigurieren",
"provision.tenantspace.chooseCC":"Wählen Sie ein Callcenter aus",
"provision.tenantspace.table.deleteTenantSpace":"Möchten Sie den angegebenen Mandantenbereich wirklich löschen?",
"provision.tenantspace.ok":"OK",
"provision.tenantspace.create":"Erstellen",
"provision.tenantspace.input.searchName":"Geben Sie den Namen des Callcenters ein, das abgefragt werden soll.",

"provision.tenantspace.selectCC":"Wählen Sie ein Callcenter aus.",
"provision.tenantspace.input.agentnum":"Legen Sie die Anzahl der Agenten fest, für die ein Antrag gestellt werden soll.",
"provision.tenantspace.cc.percent":"Kapazitätsanteil",
"provision.tenantspace.cc.current":"Aktuelle Kapazität",
"provision.tenantspace.cc.max":"Maximale Kapazität",
"provision.tenantspace.cc.currentpercent":"Aktueller Kapazitätsanteil",
"provision.tenantspace.cc.searchName":"Geben Sie den Namen des Callcenters ein, das abgefragt werden soll.",
"provision.tenantspace.choose.accesscode":"Zugangscode auswählen",
"provision.tenantspace.accessCode.choose":"Wählen Sie eine Gruppe von Zugangscodes aus.",
"provision.tenantspace.save.success":"Erfolgreich gespeichert.",
"provision.tenantspace.clear":"Löschen",
"provision.tenantspace.rechoose":"Randomisieren",
"provision.tenantspace.select.start":"Gestartet",
"provision.tenantspace.save.fail":"Speichern fehlgeschlagen.",

"provision.tenantspace.table.success":"Erfolg",
"provision.tenantspace.table.createStatus":"Neuer Status",
"provision.tenantspace.select.stop":"Angehalten",
"provision.tenant.chooseCC.first":"Wählen Sie ein Callcenter aus.",
"provision.tenant.chooseAC.first":"Wählen Sie einen Zugangscode.",
"provision.tenant.need.first":"Wählen Sie das Callcenter\, den Zugangscode\ und die Anzahl der Agenten aus.",

"provision.tenantspace.table.fail":"Fehler",
"provision.tenant.no.more.agentUsage":"Die Anzahl der Agenten ist zu groß.",
"provision.tenantspace.error.deleteSUM":"Der SUM-Mandantenbereich konnte nicht gelöscht werden.",
"provision.callcenterinstanceslist.config.softUsage.more":"Die Anzahl der Softphones ist unzureichend. Überprüfen Sie die Konfiguration.",

"provision.tenantspace.error.deleteDDS":"Der DDS-Mandantenbereich konnte nicht gelöscht werden.",
"provision.tenantspace.config.error":"Konfiguration fehlgeschlagen. Überprüfen Sie die zugehörigen Konfigurationen, oder wenden Sie sich an den Administrator.",
"provision.tenantspace.select.status":"Wählen Sie ein Bundesland aus.",
"provision.tenantspace.table.maxVideoAgents":"Anzahl der Videoagenten",
"provision.tenantspace.table.maxCalls":"Max. gleichzeitige Sprachanrufe",
"provision.tenantspace.table.maxAgents":"Max. Sprachagenten",
"provision.tenantspace.table.maxVideoCalls":"Max. gleichzeitige Videoanrufe",
"provision.tenantspace.table.videoAgentChannels":"Videoagent-Kanalanzahl (>= Doppelte Anzahl Videoagent)",
"provision.tenantspace.table.ttschannelnums":"TTS-Anzahl",
"provision.tenantspace.table.videoivrchannels":"Anzahl der Video-IVR-Kanäle",
"provision.tenantspace.table.asrchannelnums":"ASR-Menge",
"provision.tenantspace.table.maxAmsCalls":"Max. gleichzeitige anonyme Anrufe",
"provision.tenantspace.table.ivrchannels":"Anzahl der Audio-IVR-Kanäle",
"provision.tenantspace.table.maxVersatileAgents":"Maximal vielseitige Agenten",
"provision.tenantspace.cc.callnumUsagePercent":"Nutzung gleichzeitiger Sprachanrufe",
"provision.tenantspace.cc.maxcallnums":"Max. gleichzeitige Sprachanrufe",
"provision.tenantspace.cc.videoAgentCurCapacity":"Anzahl der Videoagenten",
"provision.tenantspace.cc.agentCurCapacity":"Anzahl der verwendeten Agenten",
"provision.tenantspace.cc.maxVideoCallnums":"Max. gleichzeitige Videoanrufe",
"provision.tenantspace.cc.maxAmsCallnums":"Max. gleichzeitige anonyme Anrufe",
"provision.tenantspace.cc.videoCallnumUsagePercent":"Nutzung gleichzeitiger Videoanrufe",
"provision.tenantspace.cc.amsCallnumUsagePercent":"Nutzung gleichzeitiger anonymer Anrufe",
"provision.tenantspace.cc.videoAgentUsage":"Nutzung des Videoagenten",
"provision.tenantspace.cc.videoAgentchannels":"Anzahl der Videoagentenkanäle",
"provision.tenantspace.cc.ivrchannelUsagePercent":"Nutzung des Audio-IVR-Kanals",
"provision.tenantspace.cc.videoAgentchannelUsagePercent":"Kanalnutzung des Videoagenten",
"provision.tenantspace.cc.ivrchannels":"Anzahl der Audio-IVR-Kanäle",
"provision.tenantspace.cc.videoIvrchannels":"Anzahl der Video-IVR-Kanäle",
"provision.tenantspace.cc.videoIvrchannelUsagePercent":"Nutzung des Video-IVR-Kanals",
"provision.tenantspace.cc.ttschannelUsagePercent":"TTS-Nutzung",
"provision.tenantspace.cc.ttschannelnums":"TTS-Anzahl",
"provision.tenantspace.cc.asrchannelnums":"ASR-Menge",
"provision.tenantspace.ivr.id":"Nein.",
"provision.tenantspace.cc.asrchannelUsagePercent":"ASR-Nutzung",
"provision.tenantspace.ivr.ivrServer":"IP-Adresse",
"provision.tenantspace.ivr.ivrprogid":"Prozess-ID",
"provision.tenantspace.ivr.maxChnNum":"Anzahl gleichzeitiger Anrufe",
"provision.tenantspace.ivr.idlechnNum":"Anzahl der verbleibenden gleichzeitigen Anrufe",
"provision.tenantspace.ivr.ivrId":"IVR-ID",

"provision.tenantspace.gw.ServerIP":"IP des Gatewayservers",
"provision.tenantspace.gw.port":"Gatewayserverport",
"provision.tenantspace.gw.idleRegAgentNum":"Verbleibende Menge gleichzeitig registrierter Agenten",
"provision.tenantspace.gw.name":"Name des Gatewayservers",
"provision.tenantspace.gw.maxRegAgentNum":"Menge der gleichzeitig registrierten Agenten",

"provision.tenantspace.sip.sipServerIP":"IP-Adresse des SIP-Servers",
"provision.tenantspace.sip.port":"SIP-Serverport",
"provision.tenantspace.sip.maxRegAgentNum":"Menge der gleichzeitig registrierten Agenten",
"provision.tenantspace.sip.name":"SBC-Servername",

"provision.tenantspace.ucs.name":"CCUCS-Servername",
"provision.tenantspace.sip.idleRegAgentNum":"Verbleibende Menge gleichzeitig registrierter Agenten",
"provision.tenant.management.tenantcreate.expiryDate":"Ablaufdatum",
"provision.tenant.management.contacts":"Name der Kontaktperson",
"provision.tenant.management.tenantcreate.companyName":"Unternehmen",

"provision.tenant.management.button.cancel":"Abbrechen",
"provision.tenantspace.detail":"Angaben zum Mieter",
"provision.tenant.management.companyName":"Unternehmen",

"provision.tenant.config.maxAgents":"Max. Sprachagenten",
"provision.tenantspace.config.maxVideoAgents":"Anzahl der Videoagenten",
"provision.tenantspace.config.maxCalls":"Anzahl gleichzeitiger Sprachanrufe",
"provision.tenantspace.config.maxVideoCalls":"Anzahl gleichzeitiger Videoanrufe",
"provision.tenantspace.config.ivrchannels":"Anzahl der IVR-Kanäle",

"provision.tenantspace.config.ttschannelnums":"TTS-Anzahl",
"provision.tenantspace.config.chooseDefaultPhoneNumber":"Wählen Sie die Standardanrufnummer aus",
"provision.tenantspace.config.chooseDriveTime":"Aufbewahrungszeitraum der Aufzeichnung",
"provision.tenantspace.config.chooseGatewayServer":"Wählen Sie einen Gateway-Server aus",
"provision.tenantspace.config.asrchannelnums":"ASR-Menge",
"provision.tenantspace.config.chooseDrive":"Stimmaufnahmelaufwerk",
"provision.tenantspace.config.chooseUCSServer":"Wählen Sie einen CCUCS-Server aus.",
"provision.tenantspace.config.isCreateAccount":"Konto für virtuelles Callcenter automatisch erstellen",
"provision.tenantspace.config.configVirtualCallCenter":"Virtuelles Callcenter konfigurieren",

"provision.tenantspace.config.chooseSIPServer":"Wählen Sie einen SBC-Server aus.",
"provision.tenantspace.selectStastus.config":"Zu konfigurieren",
"provision.tenantspace.selectStastus.stop":"Angehalten",
"provision.tenantspace.selectStastus.active":"Aktiviert",
"provision.tenantspace.selectStastus.initialize":"Initialisierung",
"provision.tenantspace.selectStastus.resource.allocation.failed":"Ressource konnte nicht zugewiesen werden",
"provision.tenantspace.selectStastus.invalid":"Deaktiviert",
"provision.tenantspace.selectStastus.unallocated":"Ausstehend",
"provision.tenantspace.language.zh":"Chinesisch",
"provision.tenantspace.language.cn":"Englisch",

"provision.tenantspace.config.giveSource":"Ressource zuweisen",

"provision.tenantspace.config.applySource":"Ressource anwenden",
"provision.tenantspace.config.chooseDPN":"Wählen Sie einen der Zugangscodes als Standardanrufnummer aus.",
"provision.tenantspace.config.SIP":"SBC-Server",
"provision.tenantspace.config.chooseIVR":"Wählen Sie einen IVR-Prozess aus.",
"provision.tenantspace.config.gw":"Gatewayserver",
"provision.tenantspace.config.vccName":"VCC-Name",
"provision.callcenterinstanceslist.vcallcenter.ServerNumber":"Servicenummer",

"provision.tenantspace.config.IVR":"IVR-Prozess",
"provision.callcenterinstanceslist.IVRSprogress":"IVR-Verfahren",
"provision.callcenterinstanceslist.IVRSprogress.Title":"IVR-Prozesskonfiguration",
"ccprovision.IVRSprogress.config.button.add":"Neu",
"ccprovision.IVRSprogress.config.button.async":"Synchronisierung",
"ccprovision.IVRSprogress.config.button.edit":"Bearbeiten",
"ccprovision.IVRSprogress.config.button.delete":"Löschen",
"ccprovision.IVRSprogress.config.progressNo":"Prozess-ID",
"ccprovision.IVRSprogress.config.IPAddress":"IP-Adresse",
"ccprovision.IVRSprogress.config.number":"Nein.",
"ccprovision.IVRSprogress.config.curcallNo":"Anzahl gleichzeitiger Anrufe",
"ccprovision.IVRSprogress.config.leftovercurcallNo":"Anzahl der verbleibenden gleichzeitigen Anrufkanäle",
"ccprovision.IVRSprogress.config.operate":"Betrieb",
"ccprovision.IVRSprogress.config.button.cacel":"Abbrechen",
"ccprovision.IVRSprogress.config.button.complete":"Abgeschlossen",
"provision.ivr.selectivrprogress":"Wählen Sie den IVR-Prozess aus, den Sie löschen möchten.",
"provision.ivr.selectivrprogress.singledeleteconfim":"Möchten Sie den ausgewählten IVR-Prozess wirklich löschen?",
"provision.ivr.selectivrprogress.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten IVR-Prozesse löschen?",
"ccprovision.IVRSprogress.config.tenantname":"Mieter",
"provision.callcent.error.updateinfo.maxchnsmall":"Die maximale Anzahl gleichzeitiger Anrufe darf nicht geringer sein als die Anzahl der belegten Kanäle.",
"provision.ivr.success.sync":"Synchronisation erfolgreich ",
"provision.ivr.error.cannotdelete.occupyvdn":"Der dem Mandanten zugewiesene IVR kann nicht gelöscht werden.",
"provision.ivr.error.sync":"Synchronisierung fehlgeschlagen",
"provision.obs.success.sync":"Synchronisierung erfolgreich.",
"provision.obs.sync.needDelete":"Das folgende OBS wurde an Mandanten gebunden und muss manuell ungebunden und dann synchronisiert werden:",
"provision.callcenterinstanceslist.recorddrive.Title":"Laufwerksbuchstabe wird aufgenommen",
"provision.callcenterinstanceslist.vcallcenter.vdnName":"VDN-Name",
"provision.obs.error.sync":"Synchronisierung fehlgeschlagen.",
"provision.callcenterinstanceslist.vcallcenter.newvccId":"VccInstId",
"provision.callcenterinstanceslist.vcallcenter.maxAgents":"MaxAgents",
"provision.callcenterinstanceslist.vcallcenter.tenantSpaceName":"TenantSpaceName",
"provision.callcenterinstanceslist.vcallcenter.maxCalls":"MaxCalls",
"provision.callcenterinstanceslist.vcallcenter.maxVideoAgents":"MaxVideoAgents",
"provision.callcenterinstanceslist.vcallcenter.videoAgentChannels":"VideoAgentchannels",
"provision.callcenterinstanceslist.vcallcenter.ivrchannels":"AudioIVR-Kanäle",
"provision.callcenterinstanceslist.vcallcenter.videoIvrchannels":"VideoIVR-Kanäle",
"provision.callcenterinstanceslist.vcallcenter.ttschannelnums":"TTSchannelnums",
"provision.callcenterinstanceslist.vcallcenter.maxVideoCalls":"MaxVideoCalls",
"provision.callcenterinstanceslist.vcallcenter.asrchannelnums":"ASRchannelnums",
"provision.callcenterinstanceslist.vcallcenter.open":"Öffnen",
"provision.callcenterinstanceslist.vcallcenter.maxMediaAgents":"MaxMediaAgents",
"provision.callcenterinstanceslist.vcallcenter.maxAmsCalls":"Gleichzeitige anonyme Anrufe",
"provision.callcenterinstanceslist.vcallcenter.close":"Schließen",
"provision.callcenterinstanceslist.vcallcenter.faultEnable":"Stimme des Fehlers",
"provision.tenantspace.config.success":"Tenant-Bereich erfolgreich konfiguriert",
"provision.callcenterinstanceslist.vcallcenter.maxVersatileAgents":"Vielseitige Agenten",


"provision.tenantspace.statusRecordTime.3Month":"3 Monate",
"provision.tenantspace.statusRecordTime.6Month":"6 Monate",
"provision.tenantspace.statusRecordTime.12Month":"12 Monate",
"provision.tenantspace.create.account":"Kontoinformationen",

"provision.tenantspace.create.applyAccount":"Registrieren Sie einen Administrator für den Mandanten.",
"provision.tenantspace.create.tenantName":"Name des Mieters",
"provision.tenantspace.create.tenantExpiryDate":"Tenant-Ablaufzeit",
"provision.tenantspace.create.basicInfo":"Grundlegende Informationen",
"provision.tenantspace.save.fail.001":"Die Anzahl der Sprachagenten\, Videoagenten\, Multimediaagenten\ und IVR-Kanäle darf nicht 0 sein.",
"provision.tenantspace.save.fail.002":"Die maximale Anzahl gleichzeitiger Sprachanrufe ist größer oder gleich der Anzahl der Agenten und IVR-Kanäle.",
"provision.tenantspace.save.fail.004":"Der Zugangscode ist leer.",
"provision.tenantspace.save.fail.003":"Die maximale Anzahl gleichzeitiger Videoanrufe ist größer oder gleich der Anzahl der Videoagenten.",

"provision.tenantspace.save.fail.005":"Der Zugangscode ist belegt.",
"provision.tenantspace.save.fail.006":"Die Anzahl der Agenten unter dem Mandanten darf nicht größer sein als die Anzahl der verbleibenden Agenten auf dem SBC-Server.",
"provision.tenantspace.save.fail.008":"Die Softphone-Nummer ist leer.",
"provision.tenantspace.save.fail.007":"Das Callcenter existiert nicht.",
"provision.tenantspace.save.fail.010":"Die Anzahl der belegten Zahlensegmente ist geringer als die Anzahl der angewendeten Agenten. Der zurückgegebene Wert ist Null.",
"provision.tenantspace.save.fail.009":"Das Mitarbeiter-ID-Segment ist leer.",
"provision.tenantspace.save.fail.011":"Die Daten des virtuellen Callcenters konnten nicht gespeichert werden.",
"provision.tenantspace.save.fail.013":"Die Zugangscodedaten konnten nicht aktualisiert werden.",
"provision.tenantspace.save.fail.012":"Die Daten des virtuellen Callcenters konnten nicht aktualisiert werden.",
"provision.tenantspace.save.fail.015":"Die Daten der Softphone-Nummer konnten nicht aktualisiert werden.",
"provision.tenantspace.save.fail.014":"Die Mitarbeiter-ID-Daten konnten nicht aktualisiert werden.",
"provision.tenantspace.save.fail.016":"Die Callcenter-Daten konnten nicht aktualisiert werden.",
"provision.tenantspace.save.fail.018":"Das Callcenter ist leer.",
"provision.tenantspace.save.fail.017":"Das virtuelle Callcenter ist leer.",
"provision.tenantspace.save.fail.019":"Die verbleibende Kapazität des Callcenters ist geringer als die der neu zugewiesenen Agenten.",
"provision.tenantspace.save.fail.022":"AgentUsage und MaxVoiceCallNumbers können nicht Null sein.",
"provision.tenantspace.save.fail.020":"Die Daten des virtuellen Callcenters konnten nicht gelöscht werden.",
"provision.tenantspace.save.fail.023":"Links von AgentUsage reicht nicht aus.",
"provision.tenantspace.save.fail.021":"Die Raumdaten des Mieters konnten nicht aktualisiert werden.",
"provision.tenantspace.save.fail.026":"Die verbleibende Anzahl gleichzeitiger anonymer Anrufe ist nicht ausreichend.",
"provision.tenantspace.save.fail.024":"Links von VidoeUsage reicht nicht aus.",
"provision.tenantspace.save.fail.025":"Links von VoiceCallNumber reicht nicht aus.",
"provision.tenantspace.save.fail.028":"Links von der TTS-Nummer ist nicht genug.",
"provision.tenantspace.save.fail.027":"Die linke IVR-Nummer reicht nicht aus.",
"provision.tenantspace.save.fail.030":"Weiches PhoneNumber reicht nicht aus.",
"provision.tenantspace.save.fail.029":"Die ASR-Nummer links reicht nicht aus.",
"provision.tenantspace.save.fail.031":"Tenant-Reduzierung bitte auf der Agenten-Informationsseite ausführen.",
"provision.tenantspace.save.fail.041":"Die Summe der Sprach-, Video-, Multimedia- und vielseitigen Agenten sowie der Audio- und Video-IVR-Kanäle muss größer als 0 sein.",
"provision.tenantspace.save.fail.032":"Der Wert von AgentUsage und VideoUsage muss kleiner als 10000 sein.",
"provision.tenantspace.save.fail.040":"Bitte konfigurieren Sie den Zugriffscode für den Multimediatyp.",
"provision.tenantspace.save.fail.044":"Bitte konfigurieren Sie den Zugangscode für den Audio- und Videotyp.",
"provision.tenantspace.save.fail.043":"Die Anzahl der Anwendungen für Multimedia-Agenten ist größer als die Anzahl der verbleibenden Multimedia-Agenten.",
"provision.tenantspace.save.fail.046":"Die Verbindung mit der Plattform konnte keinen Agenten löschen.",
"provision.tenantspace.save.fail.045":"Die Ressourcen des IVR-Prozesses reichen nicht aus.",
"provision.tenantspace.save.fail.047":"CC-Gateway-Server verfügt nicht über ausreichende Ressourcen.",
"provision.tenantspace.save.fail.048":"Das manuell zugewiesene Agenten-ID-Segment enthält die zugewiesenen Agenten-IDs.",
"provision.tenantspace.save.fail.050":"Die Anzahl der ausgewählten Agenten-IDs ist geringer als die Anzahl der neuen Agenten.",
"provision.tenantspace.save.fail.051":"Die OBS-Datenquelle ist nicht zugeordnet.",
"provision.tenantspace.save.fail.049":"Die ausgeschlossene Nummer entspricht nicht den Anforderungen.",
"provision.tenantspace.save.fail.053":"Die OBS-Datenquelle existiert nicht.",
"provision.tenantspace.save.fail.054":"Softphone-Nummernressourcen konnten nicht manuell zugewiesen werden.",
"provision.tenantspace.save.fail.052":"Der OBS-Server existiert nicht.",
"provision.tenantspace.save.fail.056":"Unzulässige grundlegende Mieterplatzinformationen.",
"provision.tenantspace.save.fail.062":"Der Multimedia-Zugriffscode muss konfiguriert werden, wenn der Chick-to-Dial-Zugriffscode konfiguriert wird.",
"provision.tenantspace.save.fail.058":"Daten konnten nicht gespeichert werden.",
"provision.tenantspace.save.fail.055":"Im Mieterbereich gibt es ausstehende Aufgaben.",
"provision.tenantspace.save.fail.057":"Die dem Mandantenbereich zugewiesene Ressource ist anormal.",
"provision.tenantspace.save.fail.067":"Die Daten des Authentifizierungssystems des Drittanbieters konnten nicht validiert werden.",
"provision.tenantspace.save.fail.063":"Der IVR-Prozess konnte nicht gelöscht werden.",
"provision.tenantspace.save.fail.078add":"Der Mandantenbereich konnte beim Konfigurieren des Authentifizierungssystems des Drittanbieters nicht erstellt werden.",
"provision.tenantspace.save.fail.078delete":"Der Mandantenbereich konnte beim Konfigurieren des Authentifizierungssystems des Drittanbieters nicht gelöscht werden.",
"provision.tenantspace.save.fail.079":"Bei der Konfiguration des Authentifizierungssystems des Drittanbieters konnten lokale oder externe Benutzerinformationen nicht analysiert werden.",
"provision.tenantspace.save.fail.078modify":"Der Mandantenbereich konnte bei der Konfiguration des Authentifizierungssystems des Drittanbieters nicht geändert werden.",
"provision.tenantspace.save.fail.104-001":"Die Aufnahme-Download-Funktion ist nicht eingeschaltet.",
"provision.tenantspace.save.fail.101":"Synchronisierungsfehler.",
"provision.tenantspace.save.fail.104-002":"Der Benutzer hat keine Berechtigung zur Aufzeichnung/Wiedergabe der Qualitätsprüfung.",

"provision.tenantspace.save.fail.104-003":"Der Dateiname der fehlgeschlagenen Aufzeichnungslöschung ist ungültig.",
"provision.tenantspace.save.fail.104-005":"Die Aufnahmedatei, die nicht gelöscht werden konnte, existiert nicht.",
"provision.tenantspace.save.fail.104-004":"Das Löschen der Aufzeichnung ist ohne Zuordnung des Laufwerkbuchstabens fehlgeschlagen.",
"provision.tenantspace.save.fail.104-006":"Unzureichender freier Speicherplatz für die Aufzeichnung des Löschfehlers.",
"provision.tenantspace.save.fail.104-008":"Die Systemparameter des Aufzeichnungsformats sind nicht konfiguriert.",
"provision.tenantspace.save.fail.104-009":"Aufzeichnungskonvertierung fehlgeschlagen.",
"provision.tenantspace.save.fail.104-010":"Aufzeichnungsknoten-ID-Parameter ist nicht konfiguriert.",
"provision.tenantspace.save.fail.104-011":"Das Löschen der Aufzeichnungsdatei ist fehlgeschlagen.",
"provision.tenantspace.save.fail.107-000":"Die zwischengespeicherten Anmeldeinformationen sind ungültig\, Sie müssen sich erneut anmelden.",
"provision.tenantspace.save.fail.107-003":"Falsches Konfigurationsadministratorkennwort\, Konto nach mehreren Versuchen sperren.",
"provision.tenantspace.save.fail.107-001":"Beim Aufrufen der Konfigurationsschnittstelle wurde eine Ausnahme ausgelöst.",
"provision.tenantspace.save.fail.107-005":"Kommunikation der Konfigurationsschnittstelle fehlgeschlagen.",
"provision.tenantspace.save.fail.107-002":"Eingabeparameter ist ungültig\, erforderliches Feld ist leer.",
"provision.tenantspace.save.fail.107-007":"Authentifizierung vor Konfiguration der Konfigurationsschnittstelle fehlgeschlagen.",
"provision.tenantspace.save.fail.107-004":"Ungültiges Konfigurationsadministratorkonto.",
"provision.tenantspace.save.fail.107-010":"Beim Hinzufügen von Agenten oder beim Hinzufügen von Agenten in Stapeln ist eine Ausnahme aufgetreten.",
"provision.tenantspace.save.fail.104-007":"Das Aufzeichnungsdateiformat wird nicht unterstützt.",
"provision.tenantspace.save.fail.107-011":"Bei der Änderung des Agenten oder der Stapelverarbeitung des Agenten ist eine Ausnahme aufgetreten.",
"provision.tenantspace.save.fail.107-012":"Agentenabfrage ist anormal.",
"provision.tenantspace.save.fail.107-015":"Ausnahmefehler bei Rollenänderung.",
"provision.tenantspace.save.fail.107-014":"Anormales Rollenerhöhungsversagen.",
"provision.tenantspace.save.fail.107-018":"Erhöhte Fehlerausnahme aufgerufen.",
"provision.tenantspace.save.fail.107-019":"Die aufgerufene Modifikation ist ungewöhnlicherweise fehlgeschlagen.",
"provision.tenantspace.save.fail.107-016":"Ausnahmefehler bei Rollenabfrage.",
"provision.tenantspace.save.fail.107-013":"Ausnahmefehler beim Löschen des Agenten.",
"provision.tenantspace.save.fail.107-022":"Ausnahmefehler bei der VDN-Modifikation.",
"provision.tenantspace.save.fail.107-023":"Ausnahmefehler bei VDN-Abfrage.",
"provision.tenantspace.save.fail.107-017":"Ausnahmefehler beim Löschen der Rolle.",
"provision.tenantspace.save.fail.107-020":"Die aufgerufene Abfrage ist ungewöhnlich fehlgeschlagen.",
"provision.tenantspace.save.fail.107-024":"Ausnahmefehler bei IVR-Prozessabfrage.",
"provision.tenantspace.save.fail.107-021":"Ausnahmefehler beim Löschen aufgerufen.",
"provision.tenantspace.save.fail.107-026":"Ausnahme bei Abonnementfehler.",
"provision.tenantspace.save.fail.107-027":"Ausnahme bei Abonnementregistrierung fehlgeschlagen.",
"provision.tenantspace.save.fail.107-028":"Ausnahme bei Abonnementanfrage fehlgeschlagen.",
"provision.tenantspace.save.fail.107-025":"Der Name der IVR-Prozessdatei ist leer.",
"provision.tenantspace.save.fail.107-029":"Timeout-Ausnahme bei Abonnementanfrage.",
"provision.tenantspace.save.fail.107-031":"Ausnahmefehler bei Abonnementskündigung.",
"provision.tenantspace.save.fail.107-032":"Abonnement-Handshake-Fehlerausnahme.",
"provision.tenantspace.save.fail.107-030":"Abonnementausnahme.",
"provision.tenantspace.save.fail.107-033":"Der Fehler beim Hinzufügen des Systemzugriffscodes ist anormal.",
"provision.tenantspace.save.fail.107-036":"Ausnahmefehler beim Löschen des Systemzugriffscodes.",
"provision.tenantspace.save.fail.107-034":"Ausnahmefehler beim Ändern des Systemzugriffscodes.",
"provision.tenantspace.save.fail.107-037":"Der IVR-Serviceprozess erhöht die Fehlerausnahme.",
"provision.tenantspace.save.fail.107-038":"Ausnahmefehler bei Änderung des IVR-Dienstprozesses.",
"provision.tenantspace.save.fail.107-035":"Die Abfrage des Systemzugriffscodes schlägt ungewöhnlich fehl.",
"provision.tenantspace.save.fail.107-040":"Ausnahmefehler beim Löschen des IVR-Dienstprozesses.",
"provision.tenantspace.save.fail.107-041":"System-VDN konnte nicht hinzugefügt werden.",
"provision.tenantspace.save.fail.107-039":"Ausnahmefehler bei IVR-Dienstprozessabfrage.",
"provision.tenantspace.save.fail.107-044":"Ausnahmefehler beim Löschen des System-VDN.",
"provision.tenantspace.save.fail.107-042":"Ausnahmefehler bei Änderung des System-VDN.",
"provision.tenantspace.save.fail.107-043":"Ausnahmefehler bei System-VDN-Abfrage.",
"provision.tenantspace.save.fail.107-045":"Der System-VDN löscht den Ausnahmecode, wenn VDNID und VDNName nicht übereinstimmen.",
"provision.tenantspace.save.fail.107-065":"Der UAP-Multimediabenutzer konnte nicht abgefragt werden.",
"provision.tenantspace.save.fail.107-066":"UAP-Konfigurationsausnahme",
"provision.tenantspace.save.fail.107-064":"Der UAP-Multimediabenutzer konnte nicht hinzugefügt werden.",
"provision.tenantspace.save.fail.107-067":"Der UAP-Multimediabenutzer kann das Kennwort nicht ändern.",
"provision.tenantspace.save.fail.107-068":"Der UAP-Multimediabenutzer konnte nicht gelöscht werden.",
"provision.tenantspace.save.fail.107-069":"Das UAP kann den Registrierungsstatus nicht abfragen.",
"provision.tenantspace.save.fail.107-070":"Das System-UAP konnte nicht abgemeldet werden.",
"provision.tenantspace.save.fail.107-071":"Die UAP-Benutzernummer ist null.",
"provision.tenantspace.save.fail.107-073":"Die UAP-IP-Adresse ist null.",
"provision.tenantspace.save.fail.107-072":"Ungültige UAP-Benutzernummer.",
"provision.tenantspace.save.fail.107-076":"Das UAP-Kennwort stimmt nicht mit dem Bestätigungskennwort überein.",
"provision.tenantspace.save.fail.107-074":"Das UAP-Passwort ist null",
"provision.tenantspace.save.fail.107-075":"Die Länge des UAP-Passworts liegt außerhalb des zulässigen Bereichs.",
"provision.tenantspace.save.fail.107-077":"Der UAP-IP-Adresstyp wird nicht unterstützt.",
"provision.tenantspace.save.fail.107-081":"Das UAP kann die VDN-Berechtigung nicht überprüfen, da der VDN die Benutzernummer nicht enthält.",
"provision.tenantspace.save.fail.107-079":"Die Endnummer des UAP ist kleiner als die Startnummer.",
"provision.tenantspace.save.fail.107-078":"Die Länge der Start- und Endnummer auf dem UAP ist inkonsistent.",
"provision.tenantspace.save.fail.107-083":"Das UAP kann den MML-Befehl nicht senden.",
"provision.tenantspace.save.fail.107-082":"Anmeldung beim UAP fehlgeschlagen oder Verbindung zum UAP getrennt.",
"provision.tenantspace.save.fail.107-080":"Die Anzahl der UAP-Nummern überschreitet den Grenzwert.",
"provision.tenantspace.save.fail.107-086":"Für eine Nummer auf dem UAP ist keine Anrufvorwahl konfiguriert.",
"provision.tenantspace.save.fail.107-087":"Die abgemeldete UAP-Nummer ist nicht registriert.",
"provision.tenantspace.save.fail.107-084":"Das UAP kann den Produkttyp nicht abrufen.",
"provision.tenantspace.save.fail.107-085":"Der UAP-Produkttyp kann nicht identifiziert werden.",

"provision.tenantspace.save.fail.120000":"Schnittstellenfehler.",
"provision.tenantspace.save.fail.120001":"Die maximale Anzahl von Stapeln beträgt 500.",
"provision.tenantspace.save.fail.107-107":"ipccbase configproxy verwenden.",
"provision.tenantspace.save.fail.120003":"Konfiguration existiert nicht.",
"provision.tenantspace.save.fail.120004":"Vorgang fehlgeschlagen, da die Anzahl der aktivierten VDNs das Maximum erreicht.",
"provision.tenantspace.save.fail.120005":"Die Verifizierung des Konfigurationselements ist fehlgeschlagen.",

"provision.tenantspace.save.fail.120002":"Die Konfiguration ist bereits vorhanden.",
"provision.tenantspace.save.fail.120006":"Die maximale Anzahl von Threads.",
"provision.tenantspace.save.fail.120601":"Der VDN-Standardadministrator kann den Namen nicht ändern.",
"provision.tenantspace.save.fail.120602":"Der VDN-Standardadministrator kann das Kennwort nicht ändern.",
"provision.tenantspace.save.fail.120605":"Der Vertriebsmitarbeiter ist der Teamleiter und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120603":"Der VDN-Standardadministrator kann die Rolle nicht ändern.",
"provision.tenantspace.save.fail.120604":"Der Agent ist der Standard-VDN-Administrator und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120607":"Der Agent wird von der standardmäßigen privaten Skill-Warteschlange referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120608":"Der Agent wird von der Skill-Warteschlange referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120610":"Das Agentenanwendungsteam existiert nicht.",
"provision.tenantspace.save.fail.120606":"Der Agent wird vom Prozess referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120609":"Unternehmensvertreter werden von anderen Unternehmensvertretern referenziert und können nicht gelöscht werden.",
"provision.tenantspace.save.fail.120611":"Die vom Agenten referenzierte Skill-Warteschlange existiert nicht.",
"provision.tenantspace.save.fail.120613":"Die Agentenrolle, auf die der Agent verweist, existiert nicht.",
"provision.tenantspace.save.fail.120615":"Der Prozess, auf den der Agent verweist, existiert nicht.",
"provision.tenantspace.save.fail.120612":"Die Skill-Gruppe, auf die der Agent verweist, existiert nicht.",
"provision.tenantspace.save.fail.120614":"Der andere Unternehmensvertreter, den der Unternehmensvertreter angeführt hat, existiert nicht.",
"provision.tenantspace.save.fail.120616":"Die angerufene Konfiguration, auf die der Agent verweist, existiert nicht.",
"provision.tenantspace.save.fail.120618":"Die Jobnummer des Unternehmensvertreters und die Jobnummer des Teamleiters sind doppelt vorhanden.",
"provision.tenantspace.save.fail.120619":"Die ID des Vertriebsmitarbeiters darf nicht wiederholt werden.",
"provision.tenantspace.save.fail.120617":"Der Laufwerkbuchstabe, auf den der Agent verweist, existiert nicht.",
"provision.tenantspace.save.fail.120620":"Die Länge des Agentenkennworts ist geringer als die Mindestkennwortlänge für die Sicherheitskonfiguration: {0}.",
"provision.tenantspace.save.fail.120621":"Das Kennwort muss mindestens drei der folgenden vier Zeichen enthalten: englische Großbuchstaben\, englische Kleinbuchstaben\, Zahlen\ und nicht alphanumerische Zeichen\, und sie dürfen nicht mit der Kontonummer oder in umgekehrter Reihenfolge der Kontonummer identisch sein.",

"provision.tenantspace.save.fail.120622":"Der Unternehmensvertreter ist der Teamleiter und kann keine Gruppenänderungsvorgänge durchführen.",
"provision.tenantspace.save.fail.123053":"Die Agentenrolle ist die Standardrolle und kann nicht geändert werden.",
"provision.tenantspace.save.fail.123052":"Die Rolle des Agenten wird vom Agenten referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120955":"Die Skill-Warteschlange, auf die die Skill-Warteschlange verweist, existiert nicht.",

"provision.tenantspace.save.fail.123051":"Doppelter Name der Agentenrolle.",
"provision.tenantspace.save.fail.120953":"Skill-Elemente in der Skill-Warteschlange werden dupliziert.",
"provision.tenantspace.save.fail.120956":"Der Agent, auf den die Skill-Warteschlange verweist, existiert nicht.",
"provision.tenantspace.save.fail.120954":"Der Servicetyp, auf den die Skill-Warteschlange verweist, existiert nicht.",
"provision.tenantspace.save.fail.120958":"Die angerufene Konfiguration, auf die die Skill-Warteschlange verweist, existiert nicht.",
"provision.tenantspace.save.fail.120959":"Die Skill-Warteschlange wird von der standardmäßigen privaten Skill-Warteschlange referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120961":"Die Skill-Warteschlange wird vom CDN referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120957":"Der Prozess, auf den die Skill-Warteschlange verweist, existiert nicht.",
"provision.tenantspace.save.fail.120960":"Die Skill-Warteschlange wird von anderen Skill-Warteschlangen referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120963":"Skill-Warteschlangen werden von Geschäftsmitarbeitern referenziert und können nicht gelöscht werden.",
"provision.tenantspace.save.fail.120962":"Die Skill-Warteschlange wird von der Kundengruppe referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120964":"Die Skill-Warteschlange wird vom Prozess referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120965":"Die Skill-Warteschlange wird von der angerufenen Konfiguration referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120967":"Der Medientyp der Skill-Warteschlange ist ein Faxanruf\, und es muss eine Mailbox konfiguriert werden.",
"provision.tenantspace.save.fail.120968":"Die minimale Verbindungsrate der Skill-Warteschlange darf die erwartete Verbindungsrate nicht überschreiten.",
"provision.tenantspace.save.fail.120966":"Der Laufwerkbuchstabe, auf den die Skill-Warteschlange verweist, existiert nicht.",
"provision.tenantspace.save.fail.120970":"Die Skill-Warteschlange wird von einem gemeinsam genutzten Gerät referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120972":"Der Untermedientyp, auf den die Skill-Warteschlange verweist, existiert nicht.",
"provision.tenantspace.save.fail.120969":"Die Skill-Warteschlange wird von ACDDN referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.123001":"Doppelter Name des Skill-Elements.",
"provision.tenantspace.save.fail.120971":"Skill-Warteschlangen-Skillgruppenreferenz\, kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.123003":"Skill-Elemente werden von der Skill-Warteschlange referenziert und können nicht gelöscht werden.",
"provision.tenantspace.save.fail.123002":"Kompetenzelement existiert nicht.",
"provision.tenantspace.save.fail.123004":"Skill-Elemente werden von der Skill-Warteschlange referenziert und der Name kann nicht geändert werden.",
"provision.tenantspace.save.fail.120902":"Der Geschäftstyp wird vom Prozess referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120903":"Der Geschäftstyp wird von der Skill-Warteschlange referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120904":"Der Unternehmenstyp wird durch die Standardarbeitszeit referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120905":"Der Laufwerkbuchstabe, auf den der Geschäftstyp verweist, existiert nicht.",

"provision.tenantspace.save.fail.120901":"Doppelte Beschreibung des Geschäftstyps.",
"provision.tenantspace.save.fail.120651":"Der vom angerufenen Teilnehmer konfigurierte Systemzugriffscode ist nicht zugewiesen.",
"provision.tenantspace.save.fail.120652":"Die Kombination des Systemzugangscodes und nachfolgender Zugangscodes, die vom angerufenen Teilnehmer konfiguriert wurden, kann nicht wiederholt werden.",
"provision.tenantspace.save.fail.120656":"Die Skill-Warteschlange, auf die die angerufene Konfiguration verweist, existiert nicht.",
"provision.tenantspace.save.fail.120658":"Der Gerätetyp ist IVR\ und der Medientyp des Zugangscodes kann nur zum Anrufen\, zur Rückrufanforderung\ und zum normalen Anruf angeklickt werden.",
"provision.tenantspace.save.fail.120659":"Das Routing-Skript, auf das die aufgerufene Konfiguration verweist, ist nicht vorhanden.",
"provision.tenantspace.save.fail.120655":"Der Zugriffscode, auf den die aufgerufene Konfiguration verweist, existiert nicht.",
"provision.tenantspace.save.fail.120660":"Der vom angerufenen Teilnehmer konfigurierte Systemzugriffscode stimmt nicht mit dem Medientyp des angerufenen Konfigurationsgeräts überein.",
"provision.tenantspace.save.fail.120661":"Die angerufene Konfiguration wird von der standardmäßigen privaten Skill-Warteschlange referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120662":"Die angerufene Konfiguration wird von der Skill-Warteschlange referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120663":"Die aufgerufene Konfiguration wird vom Agenten referenziert und kann nicht gelöscht werden.",

"provision.tenantspace.save.fail.120216":"Kann nicht zwischen IVR und CCIVR geändert werden.",
"provision.tenantspace.save.fail.120217":"Die maximale Anzahl von IVRs für dieselbe IP-Adresse beträgt 20.",
"provision.tenantspace.save.fail.120221":"Parametergruppe, auf die IVR verweist, existiert nicht.",
"provision.tenantspace.save.fail.120220":"Das IVR wird von der VDN-Gruppe referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120222":"Der CTI-Knoten, auf den der IVR verweist, existiert nicht.",
"provision.tenantspace.save.fail.120219":"IVR wird vom VDN referenziert und kann nicht gelöscht werden.",

"provision.tenantspace.save.fail.120502":"Die maximale Anzahl gleichzeitiger Sprachanrufe überschreitet die Obergrenze.",
"provision.tenantspace.save.fail.120501":"Die maximale Anzahl der Agenten, die sich gleichzeitig im System anmelden können, überschreitet die Obergrenze ",
"provision.tenantspace.save.fail.120505":"Die Anzahl der Konferenzteilnehmer überschreitet die Obergrenze.",
"provision.tenantspace.save.fail.120503":"Die maximale Anzahl gleichzeitiger Webanrufe überschreitet die Obergrenze.",
"provision.tenantspace.save.fail.120504":"Die maximale Anzahl gleichzeitiger ausgehender Anrufe überschreitet die Obergrenze.",
"provision.tenantspace.save.fail.120508":"Die Einschränkung ist aktiviert. Die Anrufnummernvorwahl darf nicht leer sein.",
"provision.tenantspace.save.fail.120506":"Die maximale Anzahl der Videoagenten, die sich gleichzeitig im System anmelden können, überschreitet die Obergrenze.",
"provision.tenantspace.save.fail.120510":"Der VDN-Name ist ein Duplikat eines anderen VDN-Namens.",
"provision.tenantspace.save.fail.120507":"Die anrufende Nummer darf keine Inklusionsbeziehung enthalten.",
"provision.tenantspace.save.fail.120509":"Die Einschränkung ist aktiviert. Standardmäßig\ beginnt die anrufende Nummer mit der Vorwahl.",
"provision.tenantspace.save.fail.120513":"Das OBS, auf das der VDN verweist, existiert nicht.",
"provision.tenantspace.save.fail.120511":"Der VDN-Name ist ein Duplikat des Namens der VDN-Gruppe.",
"provision.tenantspace.save.fail.120514":"Die verteilte Ressource, auf die der VDN verweist, existiert nicht.",
"provision.tenantspace.save.fail.120515":"Die Datenquelle, auf die der VDN verweist, existiert nicht.",
"provision.tenantspace.save.fail.120516":"Das IVR, auf das der VDN verweist, existiert nicht.",
"provision.tenantspace.save.fail.120517":"Wenn in der grundlegenden RMS-Informationskonfiguration kein Sprachaufzeichnungssystem angegeben ist\, kann nur die HUAWEI ACD-Sprachaufzeichnung für den aktuellen VDN konfiguriert werden.",
"provision.tenantspace.save.fail.120520":"Der Systemzugriffscode, auf den der VDN verweist, existiert nicht.",
"provision.tenantspace.save.fail.120519":"Der verteilte Knoten, der für die erzwungene automatische VDN-Sprachaufzeichnung verwendet wird, ist nicht für den VDN konfiguriert.",
"provision.tenantspace.save.fail.120521":"Der VDN unterstützt die Anrufarten E-Mail und Fax nicht\, aber dem VDN werden Systemzugriffscodes der Anrufart E-Mail oder Fax zugewiesen.",
"provision.tenantspace.save.fail.120522":"Der VDN unterstützt den Webanruftyp nicht\, aber dem VDN werden Systemzugriffscodes des Webanruftyps zugewiesen.",
"provision.tenantspace.save.fail.120523":"Der dem VDN zugewiesene Systemzugangscode wurde einem anderen VDN zugewiesen.",
"provision.tenantspace.save.fail.120524":"Der dem VDN zugewiesene Systemzugangscode wurde einer VDN-Gruppe zugewiesen.",
"provision.tenantspace.save.fail.120525":"Die IVR-Datenquellen sind nicht in den VDN-Datenquellen oder Datenquellen Dritter enthalten, die dem VDN zugeordnet sind.",
"provision.tenantspace.save.fail.120526":"Die Standard-IVR-Datenquelle ist nicht in den VDN-Datenquellen oder Datenquellen von Drittanbietern enthalten, die dem VDN zugewiesen sind.",
"provision.tenantspace.save.fail.120512":"Doppelter VDN-Agenten-ID-Bereich.",
"provision.tenantspace.save.fail.120527":"Der lokale CCP-Client verweist auf den VDN und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120528":"Wenn die Weiterleitung des Agenten-Auflegens aktiviert ist\, wird der Aufforderungston abgespielt, nachdem der Anruf an den Agenten zurückgeleitet wurde. Der Parameter für den Aufforderungston muss festgelegt werden.",
"provision.tenantspace.save.fail.120530":"Das Laufwerk, auf das der VDN verweist, existiert nicht.",
"provision.tenantspace.save.fail.120529":"Die VDN-Administrator-ID muss innerhalb des konfigurierten Agenten-ID-Bereichs liegen.",
"provision.tenantspace.save.fail.120531":"Der in den Basis-VDN-Informationen enthaltene Zugangscode wird dem VDN nicht zugewiesen.",
"provision.tenantspace.save.fail.120533":"Doppelter VDN-Agenten-ID-Bereich.",
"provision.tenantspace.save.fail.120534":"Die CCS-Ressourcengruppe, auf die der VDN verweist, existiert nicht.",
"provision.tenantspace.save.fail.120536":"Die Länge des verfügbaren VDN darf nicht 0 sein.",
"provision.tenantspace.save.fail.120535":"Der Agenten-ID-Bereich wird verwendet und kann nicht abgebrochen werden.",
"provision.tenantspace.save.fail.120538":"Die Anzahl der übertragbaren Agenten-IDs ist nicht ausreichend.",
"provision.tenantspace.save.fail.120537":"Die verfügbaren VDNs können den übertragenen VDN nicht enthalten.",
"provision.tenantspace.save.fail.120540":"Der Wert der Multimedializenz liegt außerhalb des zulässigen Bereichs.",
"provision.tenantspace.save.fail.120539":"Ungültiger Multimedializenztyp.",
"provision.tenantspace.save.fail.120541":"Fähigkeit zur gleichzeitigen Verarbeitung von Agenten: 1-60.",
"provision.tenantspace.save.fail.120624":"Das geänderte vdn-Dockingkennwort stimmt mit dem alten Kennwort überein.",

"provision.tenantspace.save.fail.120202":"Der Systemzugangscode überschneidet sich mit dem speziellen Routing-Zugangscode.",
"provision.tenantspace.save.fail.120203":"Der Systemzugangscode und der NIRC-Netzwerkanruf-Zugangscode sind doppelt vorhanden.",
"provision.tenantspace.save.fail.120201":"Systemzugangscode ist doppelt.",
"provision.tenantspace.save.fail.120204":"Der Systemzugriffscode wird vom VDN referenziert und kann nicht gelöscht werden.",
"provision.tenantspace.save.fail.120205":"Der Systemzugriffscode wird von der VDN-Gruppe referenziert und kann nicht gelöscht werden.",
"ccprovision.vcallecenter.config.popup.modifyinfo":"Kennwort ändern",

"ccprovision.vcallecenter.config.popup.tab.administrator.modifypassword":"Administratorkennwort ändern",
"ccprovision.vcallecenter.config.popup.tab.configurationinterface.modifypassword":"Konfigurationskennwort ändern",
"ccprovision.vcallecenter.config.popup.enternewpassword":"Geben Sie ein neues Kennwort ein",
"ccprovision.vcallecenter.config.popup.adminworkno":"Konfigurationsadministrator-ID",
"ccprovision.vcallecenter.config.popup.curcountpassword":"Aktuelles Kontokennwort",
"ccprovision.vcallecenter.config.popup.adminpassword":"Konfigurationsadministratorkennwort ändern",
"ccprovision.vcallecenter.config.popup.confirmnewpassword":"Kennwort bestätigen",
"ccprovision.vcallecenter.config.popup.authentication":"Identität überprüfen",
"ccprovision.vcallecenter.error.updateinfo.currentacountpasderror":"Das Kennwort des aktuellen Kontos ist falsch.",
"ccprovision.vcallecenter.error.updateinfo.adminpassword":"Fehler beim Ändern des Kennworts.",
"ccprovision.vcallecenter.sucess.updateinfo.adminpassword":"Passwort erfolgreich geändert.",
"ccprovision.recorddrive.config.recorddrivename":"Name des Laufwerksbuchstabens",
"ccprovision.recorddrive.config.recorddrivenameconfig":"Volumekonfiguration",
"provision.recorddrive.selectneed.delete.recorddrive":"Wählen Sie den zu löschenden Laufwerksbuchstaben aus",
"provision.recorddrive.selectrecorddrive.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten Laufwerksbuchstaben löschen? Bitte stellen Sie sicher, dass der Laufwerksbuchstabe nicht vom Mandanten belegt ist.",
"provision.recorddrive.selectrecorddrive.singledeleteconfim":"Möchten Sie den ausgewählten Laufwerksbuchstaben wirklich löschen? Bitte stellen Sie sicher, dass der Laufwerksbuchstabe nicht vom Mandanten belegt ist.",
"provision.recorddrive.error.newrecord.repeated":"Derselbe Laufwerksbuchstabe kann nicht unter demselben Callcenter hinzugefügt werden.",
"ccprovision.recorddrive.config.storageLimit":"Niedrigste Speichergrenze (MB)",
"provision.recorddrive.error.cannotdelete.occupyvdn":"Der dem Mandanten zugewiesene Laufwerkbuchstabe kann nicht gelöscht werden",
"provision.recorddrive.label.isfirstletter.no":"Nein",
"provision.recorddrive.label.isfirstletter.yes":"ja",
"provision.recorddrive.error.firstletter.repeated":"Der erste Laufwerkbuchstabe existiert bereits",
"ccprovision.recorddrive.config.sftp.ipadress":"SFTP-IP-Adresse",
"ccprovision.recorddrive.config.isfirstletter":"Zeigt an, ob es sich um das erste Laufwerk handelt",
"ccprovision.recorddrive.config.sftp.ipadressport":"SFTP-Serverport",
"ccprovision.recorddrive.config.sftp.serverconfig":"SFTP-Serverkonfiguration",
"ccprovision.recorddrive.config.sftp.port":"Anschlussnummer.",
"ccprovision.recorddrive.config.sftp.account":"SFTP-Konto",
"ccprovision.recorddrive.config.sftp.filestoragepath":"Dateipfad-",
"ccprovision.recorddrive.config.sftp.filestoragepathStrValidateFailed":"Der Dateispeicherpfad darf nicht die Zeichenfolge './'",
"ccprovision.recorddrive.config.sftp.accountpassword":"Kennwort des SFTP-Servers",

"ccprovision.recorddrive.config.sftp.accountconfirmpasd":"Kennwort bestätigen für den SFTP-Server",
"provision.callcenterinstanceslist.vcallcentersftp.passLengthValidateFailed":"Das Kennwort muss 8 bis 20 Zeichen enthalten",
"ccprovision.recorddrive.config.sftp.identitycheck":"Identitätsprüfung",
"ccprovision.recorddrive.config.sftp.identitychecktips":"Das Konto oder Kennwort des SFTP-Servers wurde geändert. Um die Informationssicherheit zu gewährleisten\, geben Sie Ihr aktuelles Konto und Kennwort ein.",
"ccprovision.recorddrive.config.sftp.identitychecktips.placeholder":"Geben Sie Ihr aktuelles Konto und Kennwort ein.",

"provision.tenantspace.status.active.success":"Aktivierung erfolgreich.",

"provision.tenantspace.status.active.fail":"Aktivierung fehlgeschlagen.",
"provision.tenantspace.status.stop.fail":"Anhalten fehlgeschlagen.",
"provision.tenantspace.status.invalid.success":"Widerruf erfolgreich.",
"provision.tenantspace.status.invalid.fail":"Widerruf fehlgeschlagen.",

"provision.tenantspace.status.stop.success":"Erfolg.",
"provision.tenant.management.tenantcreate.provision.failed":"Der lokale Mieterraum konnte nicht erstellt werden.",
"provision.tenant.management.tenantcreate.sum.failed":"Der Mandantenbereich in der SUM konnte nicht erstellt werden.",
"provision.tenantspace.sip.searchName":"Geben Sie den Namen des SBC-Servers ein, der abgefragt werden soll.",
"provision.tenant.management.tenantcreate.dds.failed":"Der Mandantenbereich konnte im DDS nicht erstellt werden.",
"provision.tenantspace.ucs.searchName":"Geben Sie den Namen des CCUCS-Servers ein, der abgefragt werden soll.",
"provision.tenantspace.ivr.searchName":"Geben Sie die ID des IVR ein, der abgefragt werden soll.",
"provision.tenantspace.gw.searchName":"Geben Sie den Namen des abzufragenden Gateway-Servers ein.",
"provision.tenantspace.error.deleteConfigTenantSpace":"Der Mieterfläche wurden Ressourcen zugewiesen. Sie können den Mandantenbereich nicht löschen.",
"provision.tenantspace.error.deleteProvision":"Der Mandantenbereich der Bereitstellung konnte nicht gelöscht werden.",
"provision.tenantspace.selectStastus.edit.invalid":"Ungültig",

"provision.tenantspace.selectStastus.edit.stop":"Aussetzen",
"provision.tenantspace.selectStastus.edit.active":"aktiv",
"provision.tenant.management.tenantcreate.inter.failed":"Der interne Dienst zum Erstellen des Mandantenbereichs konnte nicht aufgerufen werden.",
"provision.tenant.management.tenantcreate.contact":"Kontaktmethode",
"provision.tenantspace.detail.apply.name":"Antragsteller",
"provision.tenantspace.detail.create.time":"Erstellungszeit",
"provision.tenantspace.detail.expiry.time":"Ablaufzeit",
"provision.tenantspace.detail.apply.accout":"Konto",
"provision.tenant.management.please.select":"Auswählen\.\.\.",

"provision.tenantspace.config.agent.ivr.more.zero":"Anzahl der Sprachagenten\, Videoagenten\, Multimediaagenten\, vielseitigen Agenten\, Audio-IVR-Kanäle und Video-IVR-Kanäle kann nicht 0 sein.",
"provision.tenantspace.config.call.more.agent.ivr":"Die maximale Anzahl gleichzeitiger Sprachanrufe muss größer oder gleich der Summe aus Sprachagenten\, Sprach-IVR-Kanälen\ und Video-IVR-Kanälen sein.",
"provision.tenantspace.config.voicecall.more.agent.ivr.whithAms":"Die maximale Anzahl gleichzeitiger Sprachanrufe darf nicht geringer sein als die Summe aus Sprach-, Video-, Multimedia- und vielseitigen Agenten sowie Audio- und Video-IVR-Kanälen.",
"provision.tenantspace.config.videocall.more.videoagent":"Die maximale Anzahl gleichzeitiger Videoanrufe ist größer oder gleich der Anzahl der Videoagenten.",
"provision.tenantspace.config.agent.videoagent.ivr.more.zero":"Anzahl der Sprachagenten\, Videoagenten\, Multimediaagenten\, Audio-IVR-Kanäle und Video-IVR-Kanäle darf nicht 0 sein.",
"provision.tenantspace.config.voicecall.more.agent.ivr.whithoutAms":"Die maximale Anzahl gleichzeitiger Sprachanrufe darf nicht geringer sein als die Summe aus Sprach-, Video- und vielseitigen Agenten sowie Audio- und Video-IVR-Kanälen.",
"provision.tenantspace.config.select.default.phone.in.selectAccesscodes":"Die Standardanrufnummer muss innerhalb des ausgewählten Zugangscodes liegen.",
"provision.tenantspace.config.select.default.phone":"Wählen Sie die Standardanrufnummer aus.",
"provision.tenantspace.config.select.record.time":"Wählen Sie einen Aufbewahrungszeitraum für die Aufzeichnung aus.",
"provision.tenantspace.config.select.vitual.ccname":"Bitte geben Sie den Namen des virtuellen Callcenters ein.",

"provision.tenantspace.config.select.record":"Wählen Sie ein Aufnahmelaufwerk aus.",
"provision.tenantspace.config.param.error.agentUsage":"Bitte geben Sie die Anzahl der Sprachagenten ein.",
"provision.tenantspace.config.param.error.agentCall":"Bitte geben Sie die Anzahl der gleichzeitigen Sprachanrufe ein.",
"provision.tenantspace.config.param.error.videoCall":"Bitte geben Sie die Anzahl der gleichzeitigen Videoanrufe ein.",
"provision.tenantspace.config.param.error.videoUsage":"Bitte geben Sie die Anzahl der Videoagenten ein.",
"provision.tenantspace.config.param.error.ivrNumber":"Bitte geben Sie die Anzahl der IVR-Kanäle ein.",
"provision.tenantspace.config.param.error.ttsNumber":"Bitte geben Sie die TTS-Menge ein.",

"provision.tenantspace.config.param.error.asrNumber":"Bitte geben Sie die ASR-Menge ein.",
"provision.tenantspace.table.invalidTenantSpace":"Dieser Vorgang kann nach dem Widerruf nicht mehr rückgängig gemacht werden\, Bitte bestätigen Sie, ob der Mieter widerrufen werden muss!",
"provision.tenantspace.table.activeTenantSpace":"Möchten Sie den Mandanten wirklich aktivieren?",
"provision.tenantspace.table.invalidTenantSpace.confirm":"Widerruf bestätigen",
"provision.tenantspace.table.stopTenantSpace":"Sind Sie sicher, dass Sie den Mieter suspendieren möchten?",
"provision.tenantspace.table.activeTenantSpace.confirm":"Aktivierung bestätigen",

"provision.tenantspace.table.stopTenantSpace.confirm":"Aufhebung bestätigen",
"provision.tenantspace.detail.tenantspace.status.not.change":"Der Mandant befindet sich im inaktiven Status und kann nicht geändert werden.",
"provision.callcent.error.abnormal":"Anormal",
"ccprovision.IVRSprogress.config.logincti.failed":"Verbindungsplattform fehlgeschlagen",
"provision.tenantspace.selectvideomodel.isnull":"Wählen Sie einen Videoanwendungsmodus für die Videoressource aus, die vom Mandanten beantragt wurde.",
"provision.tenantspace.agentusage.plus.videousage":"AgentUsage und VideoUsage müssen kleiner als 10000 sein",
"provision.tenantspace.agentusage.plus.videousage.plus.maxVersatileAgents":"Die Gesamtzahl der Sprach-\, Video-\ und vielseitigen Agenten darf 10000 nicht überschreiten.",
"provision.tenantspace.table.maxcallnumswithdesc":"Max. gleichzeitige Sprachanrufe (>= Summe der Agenten und IVR-Kanäle)",
"provision.tenantspace.accesscode.searchName":"Geben Sie den abzufragenden Zugangscode ein.",
"provision.tenantspace.config.agentusage.vidoeusage.ivr":"Anzahl der Sprachagenten\, Anzahl der Videoagenten\, Anzahl der Multimediaagenten\, Anzahl der Audio-IVR-Kanäle und Anzahl der Video-IVR-Kanäle können nicht alle Null sein",
"provision.tenantspace.passValidateFailed":"Sollte Großbuchstaben\, Kleinbuchstaben\, Ziffern\ und Sonderzeichen enthalten ~!{'@'}#$%&*()-_=+[{}];:\\,<.>/?",
"provision.tenantspace.config.agentusage.ivr":"Anzahl der Sprachagenten\, Anzahl der Videoagenten\, Anzahl der Multimediaagenten\, Anzahl der vielseitigen Agenten\, Anzahl der Audio-IVR-Kanäle und Anzahl der Video-IVR-Kanäle können nicht alle Null sein",
"provision.tenantspace.videoagentchannnels.limit":"Die Anzahl der Videoagentenkanäle muss größer oder gleich der doppelten Anzahl der Videoagenten sein.",
"provision.tenantspace.statusRecordTime.24Month":"24 Monate",
"provision.tenantspace.create.onlineUserNumber":"Online-Benutzernummer",
"provision.tenant.management.tenantcreate.name.exist":"Der Name des Mandanten ist bereits vorhanden",
"provision.tenant.management.tenantcreate.adminaccount.exist":"Das Administratorkonto ist bereits vorhanden",
"provision.callcenterinstanceslist.vcallcenter.syncVccSuccess":"Die Synchronisierung ist abgeschlossen.",
"provision.tenantspace.management":"TenantSpace-Verwaltung",
"provision.callcenterinstanceslist.vcallcenter.agentNumErrorListPromt":"Es gibt ein virtuelles Callcenter, dessen Sitzanzahl nicht mit dem VDN im WAS übereinstimmt. Der VccId",
"provision.callcenterinstanceslist.vcallcenter.nameDiffListPromt":"Es gibt ein virtuelles Callcenter mit VccName, das nicht mit VdnName in WAS übereinstimmt. Die VccID ist",
"provision.callcenterinstanceslist.vcallcenter.invalidVccListPromt":"Es gibt virtuelle virtuelle Callcenter im AICC, bei denen der VDN im WAS fehlt. Der VccId",
"provision.callcenterinstanceslist.vcallcenter.InProgress":"Wird ausgeführt.",
"provision.callcenterinstanceslist.vcallcenter.invalidVdnListPromt":"Es gibt VDNs im WAS, während das virtuelle Callcenter im AICC fehlt. Die VdnID ist",
"provision.tenantspace.smartAgentUsage.iszero":"Konfigurieren Sie zuerst den Agenten.",
"provision.tenantspace.agentUsage.iszero":"Bitte konfigurieren Sie zuerst den Sprachagenten und die ASR-Ressourcen.",
"provision.tenantspace.prompt":"Informationen",
"provision.tenantspace.moveAgentUsage.iszero":"Unzureichende Anzahl von Audio-IVR-Kanälen.",
"provision.tenantspace.common.anonymous.explain":"Aktivieren Sie die Funktion für anonyme ausgehende Anrufe und die Funktion für anonyme ausgehende Anrufe für den Mandantenbereich.",
"provision.tenantspace.chooseFeature":"Funktionsauswahl",
"provision.tenantspace.feature.agenttype":"Agententyp",
"provision.tenantspace.feature.anonymous":"Anonymer ausgehender Anruf",
"provision.tenantspace.common.ivr":"Herkömmliches IVR",
"provision.tenantspace.intelligent.ivr":"Intelligentes IVR",
"provision.tenantspace.feature.ivrtype":"Typ IVR",
"provision.tenantspace.common.agent":"Normaler Agent",
"provision.tenantspace.feature.moveAgents":"Mobilagent",
"provision.tenantspace.intelligent.agent":"Intelligenter Agent",
"provision.tenantspace.feature.webrtc":"Nach der Aktivierung der WebRTC-Funktion\ unterstützt der Mandantenbereich die WebRTC-Funktion.",
"provision.tenantspace.feature.close":"Deaktivieren",
"provision.tenantspace.feature.forceVideoSelfRecord":"Bildschirmaufzeichnung",
"provision.tenantspace.feature.forceVideoSelfRecord.description":"Zeigt an, ob der Bildschirmbetriebsstatus automatisch aufgezeichnet werden soll, wenn ein Agent einen Anruf bearbeitet.",
"provision.tenantspace.feature.open":"Aktivieren",
"provision.tenantspace.feature.forceVideoSelfRecord.forcibleSelfRecord":"Erzwungene Selbstaufzeichnung des VDN-Bildschirms",
"provision.tenantspace.feature.forceVideoSelfRecord.percentageFactor":"Prozentkoeffizient der Bildschirmaufzeichnung (%)",
"provision.tenantspace.feature.forceVideoSelfRecord.percentage":"Aufzeichnung nach Prozentsatz",
"provision.tenantspace.feature.forceVideoSelfRecord.attendant":"Für alle Teilnehmer aktiviert",
"provision.tenantspace.feature.forceVideoSelfRecord.percentageScope":"Der Mindestwert ist 0 und der Höchstwert ist 100. Der Standardwert ist 100.",
"provision.tenantspace.intelligent.ivr.explain":"Intelligentes IVR: Zusätzlich zur traditionellen IVR-Funktion\ unterstützt intelligentes Navigations-IVR die Spracherkennung und das semantische Verständnis.",
"provision.tenantspace.feature.forceVideoSelfRecord.settingError":"Die Bildschirmaufzeichnungsparameter sind falsch konfiguriert.",
"provision.tenantspace.common.ivr.explain":"Konventionelles IVR: Unterstützt grundlegende Funktionen wie Wiedergabe\, Empfang\ und TTS. TTS muss die Anzahl der TTS-Kanäle konfigurieren.",
"provision.tenantspace.intelligent.agent.explain":"Intelligenter Agent: Zusätzlich zur grundlegenden Sprachsteuerungsfunktion\ unterstützt er Echtzeit-Voice-to-Text und damit verbundene intelligente Empfehlungen. Für die Konfiguration von Smart Agents\ müssen Sie zuerst Sprachagenten und ASR-Ressourcen konfigurieren.",
"provision.tenantspace.intelligent.agent.description":"Intelligenter Agent: unterstützt grundlegende Kanalkommunikation\, semantische Textanalyse\ und intelligente Empfehlungen. Konfigurieren Sie die Anzahl der Agenten, bevor Sie intelligente Agenten konfigurieren. Konfigurieren Sie ASR-Ressourcen für intelligente Agenten, die Sprachsitzungen unterstützen.",
"provision.tenantspace.common.moveagent.explain":"Das System weist dem Mieter eine Softphone-Nummer zu. Das System des Mandanten verwendet die Softphone-Nummer, um sich beim Server des AICC-Systems zu registrieren. Das System des Mandanten ruft die offene Schnittstelle des AICC-Systems auf, um die bidirektionale Rückruffunktion zu implementieren. (Maximale Anzahl der zuweisbaren mobilen Agenten = Anzahl der Audio-IVR-Kanäle / 2\, abgerundet).",
"provision.tenantspace.common.agent.explain":"Gemeinsamer Agent: beantwortet eingehende Anrufe\, steuert Sprach- und Videoanrufe\ und sendet und empfängt Multimedianachrichten.",
"provision.tenantspace.common.agent.number":"Intelligente Agentenmenge",
"provision.tenantspace.config.tenant.featurevalue.not.digits":"Die dem Mandanten zugewiesenen Objektinformationen sind ungültig.",
"provision.tenantspace.config.intelligent.agentNumber.not.exceed.agentNumber":"Die Anzahl der intelligenten Agenten, die einem Mandanten zugewiesen werden, darf nicht größer sein als die Gesamtzahl der Agenten.",
"provision.tenantspace.config.fail.savedata.tenant.feature.inDatabase":"Die Datenbank konnte die Mandanten-Feature-Informationen nicht speichern",
"provision.tenantspace.config.tenant.intelligent.ivr.featurevalue.not.one":"Der dem Mandanten zugewiesene intelligente IVR-Feature-Wert ist nicht 1",
"provision.tenantspace.config.fail.modify.tenant.auth.use.sumInterface":"Das Aufrufen der SUM-Schnittstelle zum Ändern der Mandantenberechtigungen ist fehlgeschlagen",
"provision.tenantspace.feature.moveAgentNum":"Maximale Anzahl von Agenten",
"provision.tenantspace.config.fail.modifydata.tenant.feature.inDatabase":"Datenbank konnte Mandanten-Feature-Informationen nicht ändern",
"provision.callcenterinstanceslist.vcallcenter.UCSServer.title":"CCUCS-Serverkonfiguration",
"provision.callcenterinstanceslist.ucsServer.ucsServerIp":"IP des CUCS-Servers",
"provision.callcenterinstanceslist.vcallcenter.UCSServer":"CCUCS-Multimediaserver",
"provision.callcenterinstanceslist.ucsServer.ucsServerIpUpdate":"Bearbeiten",
"provision.callcenterinstanceslist.ucsServer.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten CCUCS-Server löschen?",
"provision.callcent.selectucsserver":"Wählen Sie den CCUCS-Server aus.",
"provision.callcenterinstanceslist.ucsServer.ucsServerPort":"CCUCS-Serverport",
"provision.callcenterinstanceslist.ucsServer.singledeleteconfim":"Möchten Sie den ausgewählten CCUCS-Server wirklich löschen?",
"provision.callcent.error.deleteucsserver.part.associationvcallcener":"Einige der ausgewählten CCUCS-Server sind Mandanten zugeordnet und können nicht gelöscht werden.",
"provision.callcent.error.saveucsserveripinfo":"Erstellung fehlgeschlagen\. Stellen Sie sicher, dass die CCUCS-IP-Adresse und die Portnummer des Servers nicht vorhanden sind.",
"provision.callcent.error.deleteucsserver.associationvcallcener":"Der ausgewählte CCUCS-Server ist dem Mandanten zugeordnet und kann nicht gelöscht werden.",

"provision.callcent.error.saveucsserverdesinfo":"Erstellung fehlgeschlagen\,Stellen Sie sicher, dass der CCUCS-Servername nicht existiert.",
"provision.callcent.error.updateucsserver.ondatabase":"Das Ändern des CCUCS-Servers in der Datenbank ist fehlgeschlagen.",
"provision.callcent.error.updateucsserver.ipandport.alreadyexist":"Die IP-Adresse und die Portnummer des CCUCS-Servers sind bereits vorhanden und können nicht geändert werden.",

"provision.callcenterinstanceslist.whitelist.Title":"Rückrufadresse für die intelligente Identifizierung",
"provision.callcenterinstanceslist.whiteList.IP":"Adresse der Vertrauensliste",
"provision.callcent.error.regaddrformat":"Das Format der Registrierungsadresse ist falsch.",
"provision.callcenterwhitelist.regAddr":"Rückrufadresse",
"provision.callcenterinstanceslist.config.save":"Speichern",
"provision.callcenterinstanceslist.whiteAddr.regAddrExample":"Beispiel:https://ip:port/service-cloud/rest/cc-ai/v1/asrevent/asresult",

"provision.callcent.error.regaddrlength":"Die Anmeldeadresse ist zu lang.",
"provision.callcenterinstanceslist.softPhone.used.pleaseselect":"Wählen Sie die Verwendung aus",


"provision.callcenterinstanceslist.softPhone.used.select":"Nutzung",
"provision.callcenterinstanceslist.softPhone.usedselect.isnotoccupied":"Nicht verwendet",
"provision.callcenterinstanceslist.softPhone.usedselect.fixedagent":"Agentennummer",
"provision.callcenterinstanceslist.softPhone.usedselect.mobileagent":"Nummer des Kundenmanagers",
"provision.callcenterinstanceslist.vcallcenterDeatil.DateValidateFailed":"Bitte wählen Sie ein Datum aus, das später als heute und später als 2038-1-19 ist",
"provision.tenantspace.popuppageurl.whiteListAdd":"Hinzufügen einer Adressvertrauensliste",
"provision.tenantspace.popuppageurl.deleteconfim":"Möchten Sie die Adressvertrauensliste wirklich löschen?",

"provision.tenantspace.popuppageurl.tableheadname":"Adresse",
"provision.tenantspace.popuppageurl.whiteListStyle":"Adresstyp",
"provision.tenantspace.agentinfo":"Agenteninformationen",
"provision.tenantspace.agentinfo.label.workNo":"Agentennummer",
"provision.tenantspace.agentinfo.message.searchworkno":"Geben Sie die Agentennummer ein",
"provision.tenantspace.agentinfo.message.searchSipAccount":"Bitte geben Sie die Softphone-Nummer ein",
"provision.tenantspace.agentinfo.agentstatus":"Agentenstatus",

"provision.tenantspace.agentinfo.message.selectagenttype":"Wählen Sie einen Agententyp aus",
"provision.tenantspace.agentinfo.agentstatus.offline":"Offline",
"provision.tenantspace.agentinfo.message.audio":"Audio",
"provision.tenantspace.agentinfo.agentstatus.online":"Online",
"provision.tenantspace.agentinfo.message.versatile":"Vielseitig einsetzbar",
"provision.tenantspace.agentinfo.message.video":"Video",
"provision.tenantspace.agentinfo.message.selectagent":"Wählen Sie einen Agenten aus!",
"provision.tenantspace.agentinfo.message.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten Agenten löschen?",
"provision.tenantspace.agentinfo.message.singledeleteconfim":"Möchten Sie den ausgewählten Agenten wirklich löschen?",
"provision.tenantspace.agentinfo.message.deletefail.InProgress":"Tenant bearbeitet derzeit das Geschäft\, bitte versuchen Sie es später noch einmal!",
"provision.tenantspace.agentinfo.message.media":"Multimedia",
"provision.tenantspace.agentinfo.deletefail.modifyvdn.onwas":"Die Verbindung mit der Plattform konnte den vdn nicht ändern.",
"provision.tenantspace.agentinfo.message.smartagent.iszero":"\, Es gibt keinen Audioagenten\, bitte schalten Sie die intelligente Agentenfunktion manuell aus",
"provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateFailed":"Es sind nur Ziffern zulässig.",
"provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateLength":"Die Länge der Vertrauenslistennummer reicht von 1 bis 32.",
"provision.tenantspace.commercialStatus":"Ob eine kommerzielle Website",
"provision.tenantspace.selectStastus.CommercialUse":"Gewerbsmäßige Nutzung",
"provision.tenantspace.selectStastus.noncommercial":"Probeweise kommerzielle Nutzung",
"provision.tenantspace.Conversioncommercial":"Umwandlung Kommerzielle ",
"provision.tenantspace.ConversionNoncommercial":"Kommerzielle Nutzung der Conversion-Testversion",
"provision.tenantspace.config.select.commercial.status":"Bitte wählen Sie ein Geschäftsland.",
"provision.tenantspace.config.select.default.whiteNumber":"Die Anzahl der Nummern in der Vertrauensliste darf standardmäßig nicht größer als 5 sein.",
"provision.tenantspace.chooseCommercial":"Auswahl des kommerziellen Status",
"provision.callcenterinstanceslist.whiteNumber":"Nummern in der Vertrauensliste",
"provision.tenantspace.config.select.whiteRepeate":"Doppelte Vertrauenslistennummer.",
"provision.tenantspace.deleteWhiteNumber":"Möchten Sie die Nummer wirklich aus der Vertrauensliste löschen?",
"provision.tenantspace.deleteWhiteNumber.confirm":"Löschvorgang bestätigen",
"provision.tenantspace.step1":"Schritt 1",
"provision.tenantspace.step2":"Schritt 2",
"provision.tenantspace.step4":"Schritt 4",
"provision.tenantspace.step3":"Schritt 3",
"provision.tenantspace.step5":"Schritt 5",
"provision.tenantspace.whiteNumber":"Konfigurieren von Nummern in der Vertrauensliste",
"provision.tenantspace.modifyPassword":"Passwort zurücksetzen",

"provision.tenantspace.table.expiryDate":"Termin",
"provision.tenantspace.resetpwdsuccess":"Das Kennwort wurde erfolgreich zurückgesetzt.",
"provision.tenantspace.resetpwdfaild":"Passwort konnte nicht zurückgesetzt werden.",
"provision.tenantspace.passwordValidatefaild":"Kennwort konnte nicht bestätigt werden.",

"provision.callcenterinstanceslist.vcallcenter.CcgatewayServer":"CC-Gateway-Server",
"provision.callcenterinstanceslist.vcallcenter.CcgatewayServer.title":"CC-Gateway-Konfiguration",
"provision.callcenterinstanceslist.ccgatewayserver.ccGatewayIp":"IP des CC-Gateways",
"provision.callcenterinstanceslist.ccgatewayserver.ccGatewayPort":"CC-Gateway-Anschluss",
"provision.callcenterinstanceslist.ccgateway.idleRegAgentNum":"Anzahl der Agenten im Leerlauf",
"provision.callcenterinstanceslist.ccgateway.maxRegAgentNum":"Menge der gleichzeitigen Agenten",
"provision.callcenterinstanceslist.ccgatewayServer.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten CC-Gateway-Server löschen?",
"provision.callcent.selectccgatewayservers":"Wählen Sie einen CC-Gateway-Server aus.",
"provision.callcenterinstanceslist.ccgatewayServer.singledeleteconfim":"Möchten Sie den ausgewählten CC-Gateway-Server wirklich löschen?",
"provision.callcent.error.deleteccgateway.associationvcallcener":"Der ausgewählte CC-Gateway-Server ist dem Mandanten zugeordnet und kann nicht gelöscht werden.",
"provision.callcent.error.deleteccgateway.part.associationvcallcener":"Einige der ausgewählten CC-Gateway-Server sind Mandanten zugeordnet und können nicht gelöscht werden.",
"provision.tenantspace.tenantid":"Aktuelle Miet-ID.",
"provision.callcent.error.saveccgateway.nameinfo":"Erstellung fehlgeschlagen\,bitte bestätigen Sie, ob der CC-Gateway-Name bereits vorhanden ist.",
"provision.callcent.error.updateccgateway.regAgentNumError":"Aktualisierung fehlgeschlagen\, die Anzahl der geänderten registrierten Sitze ist geringer als die tatsächliche Anzahl der zugewiesenen Sitze.",
"provision.callcent.error.saveccgateway.ipportinfo":"Erstellung fehlgeschlagen\, bitte bestätigen Sie, ob die IP-Adresse und der Port des CC-Gateways bereits vorhanden sind.",
"provision.callcent.error.saveccgateway.regAgentNumError":"Die Anzahl der geänderten registrierten Sitze ist geringer als die tatsächliche Anzahl der zugewiesenen Sitze.",
"provision.callcent.error.updateccgateway.ccgatewayServernoexist":"Update fehlgeschlagen\,CC-Gateway-Server existiert nicht.",
"provision.callcent.error.updateccgateway.ondatabase":"Aktualisierung fehlgeschlagen\,Änderung des CC-Gateway-Servers in der Datenbank fehlgeschlagen.",
"provision.callcent.error.updateccgateway.ipandport.alreadyexist":"Update fehlgeschlagen\, bitte bestätigen Sie, ob die IP-Adresse und der Port des CC-Gateways bereits vorhanden sind.",
"provision.callcent.error.updateccgateway.fail.syncccmanagement":"Aktualisierung fehlgeschlagen\,Synchronisierung des CC-Gateways zum Ändern der Informationen zum Mietraum fehlgeschlagen.",

"provision.callcenterinstanceslist.vcallcenterDeatil.wasPsdLengthRangeValidateFailed":"Das Kennwort sollte zwischen 16 und 32 Zeichen lang sein",
"provision.callcent.selectcallcentccgateway":"Bitte wählen Sie CC-Gateway.",
"provision.callcenterinstanceslist.vcallcenterDeatil.kafkaPwdLength":"Das Kennwort sollte zwischen 6 und 32 Zeichen lang sein",
"provision.callcenterinstanceslist.resource.associationvcallcener.updatenode.failed":"Tenanten werden zugewiesen\, Knoten können nicht geändert werden.",
"provision.callcenterinstanceslist.resource.node.notexist":"Knoteninformationen existieren nicht.",


"provision.callcenterinstanceslist.whitepageurl.httptip":"(Nicht sicher)",
"provision.tenantspace.common.case.explain":"Wenn der Fall geöffnet wird\, öffnet der Mandant die Service-Cloud-Service-Fähigkeit.",
"provision.tenantspace.ExpireTenantSpaceCount":"Anzahl der Mieter, die in Kürze innerhalb von 30 Tagen ablaufen",
"provision.tenantspace.tenantSpaceCount":"Gesamtzahl der Mieter",
"provision.whiteListAddress.outboundcall.obs.fileserver":"OBS-Dateiserver für ausgehende Anrufe",
"provision.whiteListAddress.PopMenu":"Popup-Bildschirm",
"provision.whiteListAddress.outboundcall.sftp.fileserver":"SFTP-Dateiserver für ausgehende Anrufe",
"provision.whiteListAddress.ivr.voiceupload.callback":"Rückruf beim IVR-Sprachupload",
"provision.whiteListAddress.CustomerMenu":"Menüs anpassen",
"provision.whiteListAddress.mobileagenturl":"Rückruf beim Mobilfunk-Kundendienst",
"provision.tenantspace.common.newcase.explain":"Aktivieren Sie die Funktion Fall 2.0, um Mandanten die Funktionen Fallverwaltung und Konfiguration zur Verfügung zu stellen.",
"provision.whiteListAddress.resourcedump.obs":"Ressourcenabbild-OBS-Server",
"provision.whiteListAddress.resourcedump.obsproxy":"Ressourcenabbild des OBS-Server-Agenten",
"provision.whiteListAddress.AddressExist":"Die Adressvertrauensliste ist bereits vorhanden.",
"provision.whiteListAddress.TenantUnExist":"Der Mieter existiert nicht.",
"provision.whiteListAddress.cardPop":"Umleitungsadresse für Pagecard-Nachrichten",
"provision.tenantspace.config.isAutoAssignWorkNo":"Arbeitsnummer für virtuelles Callcenter automatisch zuweisen",

"provision.tenantspace.config.chooseworkNo":"WorkNo auswählen",
"provision.tenantspace.config.chooseworkNo.tip":"Bitte geben Sie das vielversprechende Workno-Segment entsprechend der Leerlauf-WorkNo-Information ein",
"provision.tenantspace.config.error.chooseworkNo.noaddagent":"Keine Agentenressource hinzugefügt\, Arbeitsnummer muss nicht zugewiesen werden",
"provision.tenantspace.config.error.chooseworkNo.noagent":"Keine Agentenressourcen ausgewählt\, Arbeitsnummer muss nicht zugewiesen werden",
"provision.tenantspace.config.error.142":"Die App-Cube-Funktion muss verfügbar sein, wenn die App-Call-Aufgabenfunktion verfügbar ist.",
"provision.tenantspace.config.worknoassign.filterworkNo":"Screening-Nummernbank",
"provision.tenantspace.config.worknoassign.filternumber":"Nummern ausschließen",
"provision.tenantspace.config.worknoassign.filternumber.explain1":"Wenn Sie mehrere Zahlen eingeben\, verwenden Sie bitte \",\ zum Trennen. Geben Sie bis zu 5 Zahlen ein\, zum Beispiel\, 4,11,13",
"provision.tenantspace.config.worknoassign.chooseworkNo":"Wählen Sie eine Nummer aus",
"provision.tenantspace.config.worknoassign.filternumber.explain2":"Die Nummernbank enthält nach dem Screening nicht die Nummern der oben genannten Nummern",
"provision.tenantspace.config.worknoassign.chooseworknoscope.explain1":"Vorschau der Zahlenbibliothek",
"provision.tenantspace.config.worknoassign.filternumber.error":"Die ausgeschlossene Nummer entspricht nicht den Anforderungen.",
"provision.tenantspace.config.worknoassign.chooseworknoscope.error":"Die Endagenten-ID darf nicht kleiner als die Startagenten-ID sein.",
"provision.tenantspace.config.worknoassign.chooseworknoscope.explain2":"Es können maximal fünf Zahlengruppen hinzugefügt werden.",
"provision.tenantspace.config.worknoassign.chooseworknoscope.addmaxlimit":"Fünf Nummerngruppen wurden hinzugefügt.",
"provision.tenantspace.config.worknoassign.chooseworknoscope.paramserror":"Die Nummerninformationen sind falsch.",
"provision.tenantspace.config.worknoassign.chooseworknoscope.required":"Geben Sie das hinzuzufügende Agenten-ID-Segment ein, und klicken Sie auf OK.",
"provision.tenantspace.config.worknoassign.chooseworknoscope.workNocontain":"Die Arbeits-ID wurde ausgewählt. Bitte geben Sie eine andere ein.",
"provision.tenantspace.config.worknoassign.chooseworknoscope.availableWorkNo":"Keine Agenten-ID im Agenten-ID-Segment verfügbar.",
"provision.tenantspace.config.worknoassign.chooseworknoscope.noempty":"Die Nummernangabe ist obligatorisch.",
"provision.tenantspace.config.worknoassign.availableworknocount":"Verfügbare Nummern",
"provision.tenantspace.config.worknoassign.showchoosedworkno.explain":"Hinweis: Sie benötigen insgesamt \{0} Nummern\, und es wurden \{1} Nummern ausgewählt. Das System weist Ihnen der Reihe nach Nummern zu.",
"provision.tenantspace.config.assignedworkno":"ID der zugewiesenen Arbeit",
"provision.tenantspace.config.assignedworknonumber":"Zugewiesene Agenten-IDs",
"provision.tenantspace.config.selectedworkNo":"Ausgewählte \{0} Arbeits-IDs \{1}",
"provision.tenantspace.worknoValidatefaild":"Der WorkNo wird benötigt.",

"provision.tenantspace.config.worknoassign.add":"Hinzufügen",
"provision.tenantspace.obsServer.datasource.name":"Datenquellenname",
"provision.tenantspace.obsServer.datasource.databasename":"Datenbankname",
"provision.tenantspace.obsServer.datasource.id":"Datenquellen-ID",
"provision.tenantspace.obsServer.datasource.type":"Datenquellentyp",
"provision.tenantspace.obs.searchName":"Geben Sie den Namen des OBS-Servers ein, der abgefragt werden soll.",
"provision.tenantspace.config.chooseOBSServer":"Wählen Sie einen OBS-Server aus.",
"provision.tenantspace.config.choosedatasource":"Wählen Sie eine OBS-Datenquelle aus.",

"provision.tenantspace.chooseobsdatasource.error1":"Wählen Sie einen OBS-Server aus.",
"provision.tenantspace.chooseobsdatasource.platformDbSource":"Plattformdatenquellen",
"provision.tenantspace.chooseobsdatasource.callCenterDbSource":"Call Center-Datenquellen",
"provision.tenantspace.config.datasource":"OBS-Datenquelle",
"provision.tenantspace.chooseobsdatasource.thirdPartyDbSource":"Datenquellen von Drittanbietern",
"provision.tenantspace.config.isAutoAssignSoftPhone":"Ob die Softphone-Nummer automatisch der VCC zugewiesen wird",
"provision.tenantspace.config.choosesoftphone":"Wählen Sie die Softphone-Nummer aus.",
"provision.tenantspace.config.softphoneassign":"Softphone-Nummer zuweisen",

"provision.tenantspace.config.softphoneassign.tip":"Bitte geben Sie das Softphone-Nummernsegment ein.",
"provision.tenantspace.config.softphone.Validatefaild":"Nicht zugewiesene Softphone-Nummer",
"provision.tenantspace.config.softphone.noavailable":"Keine Softphone-Nummer verfügbar.",
"provision.tenantspace.config.error.softphone.noagentadd":"Es wurde kein Sprach-\, Video-\, Multimedia-\, vielseitiger\ oder mobiler Agent hinzugefügt.",
"provision.tenantspace.config.softphone.using":"Softphone-Nummer \{0} wird verwendet.",
"provision.tenantspace.config.error.softphone.noaddagent":"Es wurde kein Audio-\, Video-\, Multimedia-\ oder Mobilagent hinzugefügt.",
"provision.tenantspace.config.softphoneassign.softphonescope.required":"Geben Sie das Softphone-Nummernsegment ein, das hinzugefügt werden soll, und klicken Sie auf OK.",
"provision.tenantspace.config.softphoneassign.softphonescope.contain":"Die Softphone-Nummer wurde ausgewählt. Bitte geben Sie eine andere ein.",
"provision.tenantspace.config.softphoneassign.softphonescope.extensiverange":"Der Wertebereich von der Startnummer bis zur Endnummer darf nicht größer als 1000 sein.",
"provision.tenantspace.config.softphoneassign.chooseworknoscope.paramserror":"Die Nummerninformationen sind falsch.",
"provision.tenantspace.config.softphoneassign.softphonescope.inadequacy":"Die zugewiesene verfügbare Softphone-Nummer reicht nicht aus.",
"provision.tenantspace.config.selectedsoftphone":"Ausgewählte\{0} Zahlen\{1}",

"provision.callcent.platformserver.cms":"CMS",
"provision.callcent.platformserver.isales":"iSales",
"provision.callcent.platformserver.ccbcs":"CCBCS",
"provision.callcent.platformserver.isqm":"iSQM",
"provision.callcent.platformserver.ccbms":"CCBMS",
"provision.callcent.platformserver.ccfs":"CCFS",
"provision.callcenterinstanceslist.vcallcenter.plateformServer":"Plattformserver",
"provision.callcent.platformserver.port":"Dienstportnummer",
"provision.callcent.platformserver.identitychecktips":"Sie haben das Kennwort des Servers geändert. Um die Informationssicherheit zu gewährleisten\, geben Sie bitte Ihr aktuelles Konto und Kennwort ein.",
"provision.callcent.platformserver.ip":"IP-Adresse des Dienstes",
"provision.tenantspace.save.fail.120532":"Auf den ursprünglichen Zugangscode wurde durch eine angerufene Nummer verwiesen.",
"provision.tenantspace.table.batchcreate":"Stapelerstellung",

"provision.tenant.management.batchcreate":"Tenant-Räume werden in Batches erstellt.",
"provision.tenant.management.batchcreatenumber":"Anzahl",

"provision.tenant.management.batchcreate.error.accesscodenumber":"Es sind maximal 100 Zugriffscodes zulässig.",
"provision.tenant.management.batchcreate.success.createtask":"Eine Aufgabe zum Erstellen von Mieterflächen in Batches wurde erfolgreich hinzugefügt.",
"provision.tenant.management.pool.ctinode2":"CTI-Knoten 2",
"provision.tenant.management.batchcreate.error.accesscode.inadequacy":"Die Anzahl der Zugangscodes darf nicht geringer sein als die der Mieterflächen.",
"provision.tenant.management.akinfo":"AK-Informationen",
"provision.tenant.management.pool.ctinode1":"CTI-Knoten 1",
"provision.tenant.management.pool.ctinode.id":"Knoten-ID",
"provision.callcenterinstanceslist.table.download":"Herunterladen",
"provision.callcenterinstanceslist.table.viewing":"Anzeigen",
"provision.authconfig.appkey":"APP-ID",
"provision.tenant.management.pool.ctinode.name":"Knotenname",
"provision.authconfig.updateMessage":"Möchten Sie die App SK wirklich aktualisieren?",
"provision.authconfig.deleteconfim":"Möchten Sie die App-ID wirklich löschen?",
"provision.authconfig.connected.with.tenantspace":"Diese APP-ID ist an einen Mandantenbereich gebunden\, kann nicht gelöscht werden.",
"provision.authconfig.update":"Aktualisierung",
"provision.authconfig.updateConfim":"Konfigurationsdatei aktualisieren",
"provision.authconfig.duplicate.description.error":"duplikat.beschreibung.fehler.",
"provision.authconfig.description.toolong.error":"Beschreibung kann nicht länger als 200 tan.",
"provision.authconfig.authdescription":"Geben Sie die Beschreibung der Entwickler-ID ein.",
"provision.authconfig.description.empty.error":"Die Beschreibung darf nicht leer sein.",
"provision.authconfig.authinfomation":"Informationen für Entwickler",
"provision.grayconfig.tenantSpaceId":"TenantSpaceId",
"provision.grayconfig.paramIsError":"Der Eingabeparameter ist leer oder falsch",
"provision.grayconfig.deleteconfim":"Möchten Sie die graue Konfiguration wirklich löschen?",

"provision.grayconfig.tenantHasConfig":"Der Mandantenbereich wurde konfiguriert",
"provision.grayconfig.tenantSpaceName":"TenantSpaceName",

"provision.tenantspace.config.ivrserver.select.confirm":"Auswahl bestätigen",
"provision.tenantspace.config.ivrserver.select.confirm.message":"Die ausgewählten IVR-Prozesse werden auf demselben Server bereitgestellt\, was zu einem Single Point of Failure führen kann. Möchten Sie wirklich fortfahren?",
"provision.tenantspace.resetpwdfaild.accountpassword.error":"Das aktuelle Kontokennwort ist falsch.",
"provision.tenantspace.resetpwdfaild.tenant.not.exist":"Das Kennwort konnte nicht geändert werden, da der Mandantenbereich nicht vorhanden ist.",
"provision.tenantspace.resetpwdfaild.user.not.exist":"Das Kennwort konnte nicht geändert werden, da der Benutzer nicht existiert.",
"provision.tenantspace.resetpwdfaild.newpassword.not.meet.passwordrule":"Das neue Kennwort entspricht nicht der Kennwortregel.",

"provision.tenantspace.resetpwdfaild.user.is.locked":"Das Kennwort konnte nicht geändert werden, da der Benutzer gesperrt ist.",
"provision.tenantspace.resetpwdfaild.newPwd.information.is.wrong":"Änderung fehlgeschlagen,Die neuen Kennwortinformationen sind falsch",

"provision.callcenterinstanceslist.sipServer.sip":"SIP",
"provision.callcenterinstanceslist.sipServer.sipanonymousCall":"Anonymer SIP-Anruf",
"provision.callcenterinstanceslist.sipServer.webRtcanonymousCall":"Anonymer WebRTC-Anruf",
"provision.callcenterinstanceslist.vcallcenterDeatil.uapIpAndPort":"UAP-Adresse",
"provision.callcenterinstanceslist.sipServer.uapIp":"UAP-IP",

"provision.callcenterinstanceslist.sipServer.webRTC":"WebRTC",
"provision.tenantspace.config.choose.agent.registration.server":"Agentenregistrierungsserver",
"provision.tenantspace.config.choose.agent.registration.server.type":"Agentenregistrierungsservertyp",
"provision.tenantspace.config.choose.anonymous.call.server.type":"Servertyp für anonyme Anrufe",
"provision.tenantspace.config.choose.anonymous.call.server":"Anonymer Anrufserver",
"provision.callcenterinstanceslist.sipServer.uapPort":"UAP-Port",
"provision.tenantspace.config.chooseAgentRegistrationServer":"Wählen Sie einen Agentenregistrierungsserver aus",
"provision.tenantspace.config.chooseAnonymousCallServer":"Wählen Sie einen anonymen Anrufserver aus",
"provision.tenantspace.config.chooseAnonymousCallServerTitle":"Anonymen Anrufserver auswählen.",
"provision.tenantspace.config.anonymous.call.number":"Anzahl gleichzeitiger anonymer Anrufe",
"provision.tenantspace.config.need.wertcsipserver":"Um die WebRTC-Funktion zu aktivieren, müssen Sie einen WebRTC-Agent-Registrierungsserver auswählen.",
"provision.callcent.pool.select.different.sipserver.error":"Agentenregistrierungsserver desselben Typs müssen für verschiedene Knoten ausgewählt werden.",
"provision.callcent.pool.select.different.amsservice.error":"Verschiedene Knoten sollten den gleichen Typ von Server für anonyme Anrufe auswählen.",
"provision.tenantspace.config.chooseAgentRegistrationServerTitle":"Wählen Sie den Agentenregistrierungsserver aus.",
"provision.tenantspace.config.amsNumber.error":"Die Anzahl der gleichzeitigen anonymen Anrufe darf nicht leer sein oder muss 0 oder eine positive ganze Zahl sein.",
"provision.tenantspace.config.amsServer.idleregagentnum.insufficient.error":"Unzureichende Anzahl der verbleibenden gleichzeitigen Anrufe auf dem Server für anonyme Anrufe.",
"provision.tenantspace.config.tenant.webrtc.featurevalue.not.one":"Der Wert der WebRTC-Funktion, die dem Mandanten zugewiesen ist, ist nicht 1.",

"provision.callcenterinstanceslist.sipServerUpdate.failed":"Die IP-Adresse oder Portnummer erfüllt die Regel nicht.",
"provision.whitepopuppageurl.protocol.check":"Beachten Sie, dass das von der Popup-Adresse verwendete Netzwerkprotokoll kein sicheres Protokoll ist. Wenn Sie sich für die Verwendung dieses Protokolls entscheiden\, können Sicherheitsrisiken bestehen.",

"provision.tenantspace.feature.intelligent.inspection":"intelligente Inspektion",
"provision.tenantspace.feature.intelligent.inspection.description":"intelligente Inspektion",
"provision.tenantspace.config.tenant.intelligent.inspection.featurevalue.not.one":"intelligente Inspektion",

"provision.tenantspace.feature.speechAnalysis":"Sprachanalyse",
"provision.tenantspace.feature.speechAnalysis.description":"Die Sprachanalysefunktion ist aktiviert. Der Mieterbereich unterstützt die Text- und Sprachanalyse.",

"provision.softphone.label.issyncuap.yes":"JA",

"provision.softphone.label.issyncuap.no":"NEIN",
"provision.softPhone.label.uapaddress":"UAP-Adresse",
"provision.softPhone.label.uapaddress.node1":"UAP-IP-Adresse von Knoten 1",
"provision.softPhone.label.isSyncUap":"UAP synchronisieren",
"provision.softPhone.table.forcemodifyconfim.message":"Dieser Vorgang setzt das Softphone-Passwort zurück. Um das Kennwort zu ändern\, müssen Sie sich beim Mandantenbereich anmelden. Möchten Sie wirklich fortfahren?",
"provision.softPhone.table.forcemodifyconfim":"Bestätigen",
"provision.softPhone.label.uapaddress.node2":"UAP-IP-Adresse von Knoten 2",

"provision.tenantspace.feature.customData":"Datenanpassung",
"provision.tenantspace.feature.customData.description":"Nach der Aktivierung der Datenanpassungsfunktion\ unterstützt der Mandantenbereich die Datenanpassungsfunktion.",
"provision.callcenter.resourceinfo.alert":"Die folgenden Ressourceninformationen müssen mit den Lizenzinformationen von Geräten wie UAP und CTI übereinstimmen.",
"provision.tenantspace.config.tenant.custdata.featurevalue.not.one":"Der Wert der dem Mandanten zugewiesenen Datenanpassungsfunktion ist nicht 1.",
"provision.callcenter.ivr.alert":"Der Wert muss der gleiche sein wie die gleichzeitige Fähigkeit des bereitgestellten IVR.",
"provision.callcenter.sip.alert":"Der Wert muss mit der tatsächlichen Fähigkeit übereinstimmen, die vom UAP und SBC unterstützt wird.",
"provision.callcenter.gateway.agentnum.alert":"Der Wert muss mit der tatsächlichen Konfigurationskapazität des CC-Gateway-Clusters übereinstimmen.",

"provision.callcenter.gateway.address.alert":"Lastenausgleichsadresse des CC-Gateway-Clusters",

"provision.callcenter.ucs.ip.alert":"CCUCS-Cluster-Lastenausgleichsadresse",
"provision.callcenter.videoapplicationmodel.mcuAndsfuModel":"MCU+SFU-Video",
"provision.callcenter.videoapplicationmodel.mcumodel":"MCU-Video",
"provision.callcenter.videoapplicationmodel":"Videoanwendungsmodus",
"provision.tenantspace.sfuprompt":"Das System passt sich nicht an das SFU-Video an. Kunden können sich während der sekundären Entwicklung an das SFU-Video anpassen.",

"provision.callcenter.videoapplicationmodel.sfumodel":"SFU-Video",
"provision.tenantspace.feature.multiChannelSupport":"Mehrkanalunterstützung",
"provision.tenantspace.feature.multiChannelSupport.description":"Die Multi-Channel-Support-Funktion ist aktiviert. Vorübergehende Warteschlangenanpassungen können für Service Desk-Mitarbeiter über alle Servicekanäle hinweg durchgeführt werden. Hinweis: Wenn ein Agent über die dienstübergreifende Kanalberechtigung verfügt\, belegt der Agent während der Anmeldung gleichzeitig Sprach-\, Video-\ und Multimediaressourcen. Der Agent unter einem Mandanten kann sich aufgrund von Ressourcenbeschränkungen nicht für den Kundenservice anmelden.",
"provision.tenantspace.feature.outboundCallMarketingSupport.description":"Nach der Aktivierung der intelligenten Funktion für ausgehende Anrufe\ unterstützt der Mandantenbereich die intelligente Funktion für ausgehende Anrufe.",
"provision.tenantspace.feature.outboundCallMarketingSupport":"Intelligenter ausgehender Anruf",
"provision.tenantspace.feature.knowledgeBaseSupport.description":"Nach der Aktivierung der Knowledge Base-Funktion\ unterstützt der Mieterbereich die Knowledge Base-Funktion.",

"provision.tenantspace.feature.knowledgeBaseSupport":"Wissensdatenbank",

"provision.tenantspace.feature.smsMarketingSupport":"SMS-Marketing",
"provision.tenantspace.feature.smsMarketingSupport.description":"Diese Funktion bietet SMS-Marketingfunktionen für den Mieterbereich.",
"provision.tenantspace.feature.emailMarketingSupport.description":"Diese Funktion bietet E-Mail-Marketingfunktionen für den Mieterbereich.",
"provision.tenantspace.feature.emailMarketingSupport":"E-Mail-Marketing",
"provision.tenantspace.feature.whatsAppMarketingSupport":"WhatsApp-Marketing",
"provision.tenantspace.feature.whatsAppMarketingSupport.description":"Diese Funktion bietet WhatsApp Marketingfunktionen für die Mietfläche.",
"provision.tenantspace.feature.outboundCallMarketingSupport.error":"Deaktivieren Sie zuerst die SMS-, E-Mail- und die WhatsApp-Marketingfunktionen.",
"provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber":"Versand-Obergrenze pro Tag",
"provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.placeholder":"Der Wert liegt zwischen 1 und 1.000.000. Der Wert 0 gibt an, dass kein Grenzwert angegeben ist.",
"provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.alert":"Die maximale Anzahl von Nachrichten, die pro Tag gesendet werden können, liegt zwischen 1 und 1.000.000. Der Wert 0 gibt an, dass kein Grenzwert angegeben ist.",

"provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.placeholder":"Der Wert reicht von 1 bis 100000. Der Wert 0 gibt an, dass kein Grenzwert angegeben ist.",
"provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.alert":"Die maximale Anzahl von Nachrichten, die pro Tag gesendet werden können, reicht von 1 bis 100000. Der Wert 0 gibt an, dass kein Grenzwert angegeben ist.",
"provision.system.error":"Das System ist anormal. Bitte versuchen Sie es später erneut.",
"provision.tenantspace.config.amsNumber.unequal":"Die Anzahl der gleichzeitigen anonymen Anrufe muss konsistent sein.",
"provision.tenantspace.config.amsNumber.positiveInteger":"Die Anzahl der gleichzeitigen anonymen Anrufe darf nicht leer sein und muss eine positive ganze Zahl sein.",


"provision.tenant.management.positiveInteger.checkmsg":"Geben Sie eine positive Ganzzahl ein.",
"provision.callcenterinstanceslist.table.ctiversion":"CTI-Version",
"provision.tenantspace.feature.OneClickTwoCallSupport":"Bidirektionaler Anruf mit einem Klick",
"provision.tenantspace.feature.OneClickTwoCallSupport.description":"Diese Funktion bietet bidirektionale Anruffunktionen für den Mandantenbereich mit einem Klick.",
"provision.tenantspace.feature.VoiceNotificationSupport":"Stimmenbenachrichtigung",

"provision.tenantspace.feature.VoiceNotificationSupport.description":"Diese Funktion bietet Sprachbenachrichtigungsfunktionen für den Mieterbereich.",
"provision.tenantspace.feature.dualCallTransparentDisplaySupport.description":"Nach der Aktivierung dieser Funktion\ können Sie die Nummern anpassen, die Agenten und Abonnenten angezeigt werden (je nachdem, ob der Nummernstrang verbunden ist). Wenn der Zugangscode leer ist\, ist die Funktion standardmäßig aktiviert.",
"provision.tenantspace.feature.dualCallTransparentDisplaySupport":"Anonymisierung der Rufnummer",

"provision.tenantspace.step6":"Schritt 6",

"provision.tenantspace.step7":"Schritt 7",
"provision.tenantspace.chooseauthenticationsystem":"Authentifizierungssystem von Drittanbietern konfigurieren",
"provision.tenantspace.authenticationsystem.selectClientName":"Wählen Sie ein Authentifizierungssystem eines Drittanbieters aus.",
"provision.tenantspace.authenticationsystem.enter.clientName":"Geben Sie den Namen eines Authentifizierungssystems eines Drittanbieters ein.",
"provision.tenantspace.authenticationsystem.mappingName":"Verbandszuordnungsregel",
"provision.tenantspace.authenticationsystem.clientName":"Authentifizierungssystem von Drittanbietern",
"provision.tenantspace.authenticationsystem.clientURL":"URL des Authentifizierungssystems eines Drittanbieters",
"provision.encryptinfo.scenario":"Anwendungsszenario",
"provision.encryptinfo.algorithm":"Verschlüsselungsalgorithmus",
"provision.encryptinfo.strength":"Stärke",
"provision.encryptinfo.paddingScheme":"Auffüllmodus",
"provision.encryptinfo.mode":"Modus",
"provision.encryptinfo.encryptKey":"Schlüssel aktualisieren",

"provision.encryptinfo.version":"Version",
"provision.encryptinfo.validityPeriod":"Gültigkeitsdauer (Monat)",
"provision.encryptinfo.metadata":"Verschlüsselung und Entschlüsselung von Metadaten",
"provision.encryptinfo.email":"Verschlüsselung und Entschlüsselung von E-Mail-Inhalten",
"provision.encryptinfo.updateTime":"Aktualisiert am",
"provision.encryptinfo.title.encrypted.information":"Verschlüsselungsinformationen",
"provision.encryptinfo.historical.version":"Frühere Version",
"provision.encryptinfo.encryptKey.length.error":"Erforderliche Schlüssellänge:",
"provision.encryptinfo.updateInterval.error":"Das Intervall zum Aktualisieren des Schlüssels darf nicht weniger als 30 Tage betragen.",
"provision.encryptinfo.modedecrease":"Der CBC-Modus ist weniger sicher als der GCM-Modus. Seien Sie bei der Auswahl des CBC-Modus vorsichtig.",
"provision.encryptinfo.strengthdecrease":"Eine Verringerung der Stärke verringert die Sicherheit des Algorithmus. Seien Sie bei der Auswahl dieser Option vorsichtig.",
"provision.tenantspace.dataUpload.resourceDump":"Ressourcenabbild",
"provision.tenantspace.dataUpload.openResourceDump":"Bietet die Ressourcenabbildfunktion für den Mandantenbereich. Hinweis: Derzeit\ wird nur HUAWEI CLOUD OBS unterstützt. Um die Ressourcenabbildfunktion nutzen zu können\, müssen Mandanten diesen Service erwerben.",
"provision.encryptinfo.update":"Aktualisieren",

"provision.tenantspace.obsServer.backup.datasource.name":"Name der Standby-Datenquelle",
"provision.tenantspace.feature.dualChannelRecSupport":"Aufzeichnung mit zwei Spuren",

"provision.tenantspace.feature.dualChannelRecSupport.description":"Diese Funktion bietet Dual-Track-Aufnahmefunktionen für den Mieterbereich.",

"provision.tenantspace.dstEndDateVerification":"Das Enddatum der Sommerzeit kann nur nach dem Startdatum der Sommerzeit liegen.",
"provision.tenantspace.basic.information":"Grundlegende Mieterinformationen",
"provision.tenant.management.tenantcreate.timezoneInfo":"Zeitzoneninformationen",
"provision.tenant.management.timezone":"Zeitzone",
"provision.tenant.management.timezoneOffset":"Zeitzonenverschiebung",
"provision.tenant.management.DSTOffset":"DST-Versatz (min)",
"provision.tenant.management.daylightSavingTime":"Sommerzeit",
"provision.tenant.management.DSTStartDate":"Startdatum Sommerzeit",
"provision.tenant.management.DSTStartTime":"Startzeit Sommerzeit",
"provision.tenant.management.DSTEndDate":"Enddatum der Sommerzeit",
"provision.tenantspace.option.month_1":"Januar",
"provision.tenant.management.DSTEndTime":"Endzeit der Sommerzeit",
"provision.tenantspace.option.month_3":"Märsche",
"provision.tenantspace.option.month_2":"Februar",
"provision.tenantspace.option.month_4":"April",
"provision.tenantspace.option.month_6":"Juni",
"provision.tenantspace.option.month_5":"Mai",
"provision.tenantspace.option.month_8":"August",
"provision.tenantspace.option.month_9":"September",
"provision.tenantspace.option.month_11":"November",
"provision.tenantspace.option.month_10":"Oktober",
"provision.tenantspace.option.month_12":"Dezember",
"provision.tenantspace.option.weeklist_fir":"Erste",
"provision.tenantspace.option.month_7":"Juli",
"provision.tenantspace.option.weeklist_tid":"Dritte",
"provision.tenantspace.option.weeklist_sec":"Zweite",
"provision.tenantspace.option.weeklist_fthtolast":"Letzte drei",
"provision.tenantspace.option.weeklist_tidtolast":"Letzte zwei",
"provision.tenantspace.option.weeklist_sectolast":"Vorletzter",
"provision.tenantspace.option.weekday_sun":"Sonntag",
"provision.tenantspace.option.weeklist_firtolast":"Letztes",
"provision.tenantspace.option.weeklist_fth":"Vierter",
"provision.tenantspace.option.weekday_tue":"Dienstag",
"provision.tenantspace.option.weekday_wed":"Mittwoch",
"provision.tenantspace.option.weekday_thu":"Donnerstag",
"provision.tenantspace.option.weekday_fri":"Freitag",
"provision.tenantspace.option.weekday_sat":"Samstag",
"provision.tenantspace.timezone":"Standardzeitzone",
"provision.tenantspace.modifyTimeZoneVerification":"Zeitzoneninformationen können nur für einen Mieterbereich im kommerziellen oder Test-kommerziellen Status geändert werden.",
"provision.tenantspace.timezone.updateconfim":"Bestätigen",
"provision.tenantspace.timezone.updatemessage":"Die Änderung der Zeitzoneninformationen kann sich während des Übergangszeitraums auf die Geschäftsabwicklung auswirken.",
"provision.tenantspace.save.oldsuccess":"Die Zeitzoneninformationen werden nicht geändert.",
"provision.tenantspace.option.weekday_mon":"Montag",
"provision.tenantspace.verification.fail":"Das Zeitformat konnte nicht validiert werden.",

"provision.title.select":"Auswählen",
"provision.tenantspace.timezone.save.success":"Die Zeitzoneninformationen wurden erfolgreich gespeichert.",
"provision.tenant.management.tenantcreate.timezone.failed":"Die Zeitzoneninformationen konnten nicht verifiziert werden.",

"provision.tenantspace.timezone.config.error":"Die Zeitzoneninformationen konnten nicht geändert werden.",
"provision.callcent.error.savevccfirst":"Der Agent ist nicht an den Registrierungsserver gebunden. Speichern Sie den VDN erneut.",

"ccp.version.versioncode":"Version",
"ccp.version.microservice":"Microservice",
"provision.tenantspace.config.amsobjnum.unequal":"Setzen Sie diesen Parameter auf denselben Wert wie den des anonymen Anrufservers.",
"provision.tenantspace.config.agentnum.limit":"Es können maximal {0} Agenten gleichzeitig abonniert werden.",
"ccp.version.updateTime":"UpdateTime",
"provision.tenantspace.dstoffsettime.verification.fail":"Der Wert ist eine Ganzzahl zwischen -1440 und 1440.",

"provision.web.validator.ip":"Ungültige IP-Adresse. (Geben Sie eine IPv4- oder IPv6-Adresse\ oder einen Domänennamen ein.)",
"provision.systemparam.success.save":"Parameter erfolgreich gespeichert",

"provision.systemparam.error.save":"Speichern der Parameter fehlgeschlagen",
"provision.systemparam.success.reset":"Parameter wurde erfolgreich zurückgesetzt",
"provision.systemparam.error.reset":"Zurücksetzen des Parameters fehlgeschlagen",

"provision.systemparam.error.notmatchrule":"Der Parameter erfüllt die Prüfregel nicht",
"provision.systemparam.error.refresh":"Parameteraktualisierung fehlgeschlagen",
"provision.systemparam.success.refresh":"Parameter erfolgreich aktualisiert",
"provision.systemparam.error.noparamselected":"Bitte wählen Sie zuerst einen Parameter aus",

"provision.sysparam.config.reset":"Zurücksetzen",
"provision.sysparam.config.search":"Suche",
"provision.sysparam.config.refresh":"Aktualisieren",
"provision.sysparam.config.value":"Parameterwert",
"provision.sysparam.config.itemname":"Parametername",
"provision.sysparam.config.opterate":"Betrieb",
"provision.sysparam.config.itemdesc":"Beschreibung",
"provision.sysparam.config.checkmessage":"Enthält Sonderzeichen.(" < "\, " > "\, " / ", etc.)",
"provision.sysparam.config.save":"Speichern",
"provision.sysparam.config.mod":"Bearbeiten",
"provision.sysparam.config.itemname.tips":"Geben Sie einen Parameternamen ein.",
"provision.sysparam.config.select.null":"Wählen Sie einen Parameter aus.",
"provision.sysparam.config.select.count":"Es können maximal 10 Parameter ausgewählt werden.",
"provision.sysparam.config.item.detail":"Parameterinformationen",
"provision.sysparam.config.select.prompt":"Tipps",
"provision.sysparam.config.item.catalog":"Parametertyp",
"provision.systemparam.error.validate.inner":"Parameterüberprüfung fehlgeschlagen",
"provision.systemparam.error.validate.ruleregexinvalid":"Syntaxfehler der Validierungsregel",
"provision.sysparam.config.title":"Systemparameter",
"provision.systemparam.error.validate.ruleinvalid":"Die Überprüfungsregel ist ungültig",
"provision.systemparam.error.validate.notmatchruleregex":"Der Eingabeparameter stimmt nicht mit dem regulären Ausdruck der Überprüfungsregel überein",
"provision.systemparam.error.validate.watermarkinfo.notmatchruleregex":"Der Parameter erlaubt nur 0 bis 20 Zeichen mit Groß- und Kleinbuchstaben\, Zahlen oder -_/{'@'}.",
"provision.systemparam.error.validate.notmatchrulealpha":"Bitte geben Sie alphanumerische Zeichen oder Unterstriche ein und beginnen Sie nur mit Buchstaben oder Unterstrichen",
"provision.systemparam.error.validate.notmatchrule":"Der Eingabeparameter stimmt nicht mit der Überprüfungsregel überein",
"provision.systemparam.error.validate.notmatchruleemail":"Geben Sie eine gültige E-Mail-Adresse ein",
"provision.systemparam.error.validate.notmatchruleip":"Geben Sie eine gültige IPv4- oder IPv6-Adresse ein",
"provision.systemparam.error.validate.notmatchrulemaxlength":"Der Eingabeparameter überschreitet die maximale Länge",
"provision.systemparam.error.validate.notmatchrulemax":"Der Eingabeparameter überschreitet das Maximum",
"provision.systemparam.error.validate.notmatchruledigits":"Bitte geben Sie eine gültige positive Ganzzahl ein",
"provision.systemparam.error.validate.notmatchruleminlength":"Der Eingabeparameter ist kleiner als die Mindestlänge",
"provision.systemparam.error.validate.notmatchrulemin":"Der Eingabeparameter ist niedriger als das Minimum",
"provision.systemparam.error.validate.notmatchruleoption":"Der Eingabeparameter befindet sich nicht im Aufzählungsbereich",
"provision.systemparam.error.validate.notmatchrulenumber":"Bitte geben Sie eine Zahl ein",
"provision.systemparam.error.validate.notmatchrulerangelength":"Der Eingabeparameter überschreitet die maximale Länge oder unterschreitet die minimale Länge",
"provision.systemparam.error.validate.notmatchrulerange":"Der Eingabeparameter überschreitet den Maximalwert oder unterschreitet den Minimalwert",
"provision.systemparam.error.validate.notmatchruleurl":"Geben Sie eine gültige URL-Adresse ein",
"provision.systemparam.error.validate.notmatchrulerequired":"Der Eingabeparameter ist erforderlich",
"provision.log.downloadSK":"SK herunterladen",
"provision.systemparam.error.validate.userNotExists":"Bitte geben Sie ein gültiges Benutzerkonto ein",
"provision.tenantspace.feature.operation":"Die Daten der Mandantenfunktion werden im Backend verarbeitet. Zeigen Sie das Ergebnis der Feature-Verarbeitung 2 Minuten später auf der aktuellen Seite an.",
"provision.ccp.redis.feature.success":"Die Mandantenfunktionsdaten wurden erfolgreich ausgeführt.",
"provision.ccp.redis.feature.inprogress":"Die Mandanten-Featuredaten werden ausgeführt.",
"provision.tenantspace.feature.agentSendLocalMediaFile":"Versenden lokaler Multimediadateien durch Agenten",
"provision.tenantspace.feature.emailAgentSendLocalAttachment":"Versenden lokaler Anhänge durch E-Mail-Agenten",
"provision.tenantspace.feature.agentSendLocalMediaFile.description":"Diese Funktion bietet die Funktion, lokale Multimediadateien durch Agenten für den Mandantenbereich zu senden.",
"provision.tenantspace.feature.emailAgentSendLocalAttachment.description":"Diese Funktion bietet die Funktion, lokale Anhänge durch E-Mail-Agenten für den Mandantenbereich zu senden.",
"provision.ccp.redis.feature.failed":"Mandanten-Featuredaten konnten nicht ausgeführt werden.",
"pprovision.tenantspace.feature.emailAgentCCAndBcc.description":"Aktivieren Sie die Kopier- und Bcc-Funktion des E-Mail-Agenten. Der gemietete Speicherplatz unterstützt das Kopieren von E-Mail-Agenten und Bcc.",
"provision.tenantspace.feature.emailAgentCCAndBcc":"E-Mail-Agent CC Bcc",
"provision.tenantspace.feature.emailAgentForwarding":"Mail-Agent-Weiterleitung",
"pprovision.tenantspace.feature.emailAgentForwarding.description":"Aktivieren Sie die Weiterleitungsfunktion des E-Mail-Agenten. Der gemietete Platz unterstützt die E-Mail-Weiterleitungsfunktion des E-Mail-Agenten.",

"provision.speciallist.tip.userNoLength":"Die Teilnehmernummer darf nicht länger als 100 Zeichen sein",

"provision.speciallist.tip.reasonLength":"Der Grund darf 1024 Zeichen nicht überschreiten",

"provision.speciallist.tip.timeVerification":"Bitte wählen Sie ein Datum aus, das später als heute und später als der 19.01.2038 ist",
"provision.tenantspace.feature.cobrowse.description":"Diese Funktion bietet die Co-Browsing-Funktion für den Mieterbereich.",
"provision.tenantspace.feature.cobrowse":"Ko-Browsen",
"provision.callcent.platformserver.cobrowse":"Ko-Browsing-Dienst (Ko-Browsen)",
"provision.tenantspace.feature.cobrowse.cobrowseMaxNumber.placeholder":"1\u201350000",
"provision.tenantspace.feature.cobrowse.cobrowseMaxNumber":"Maximale Anzahl von Co-Browsing-Verbindungen",
"provision.tenantspace.feature.cobrowse.selectcobrowsevalue.invalid":"Die maximale Anzahl von Co-Browsing-Verbindungen reicht von 1 bis 50000.",
"provision.tenant.management.tenantmodify.cobrowse.akempty":"Die AK-Informationen dürfen nicht leer sein, da Sie die Co-Browsing-Funktion für den Mandanten aktiviert haben.",
"provision.tenantspace.feature.cobrowse.needak":"Sie müssen AK-Informationen konfigurieren, bevor Sie die Co-Browsing-Funktion aktivieren.",
"provision.tenantspace.configureauthentication":"Authentifizierung konfigurieren",

"provision.spinfo.id":"Kennung",
"provision.spinfo.issuerId":"Aussteller-ID",
"provision.spinfo.serviceUrl":"Dienst-URL",
"provision.spinfo.creator":"Ersteller",
"provision.spinfo.logoutUrl":"Abmelde-URL",
"provision.spinfo.issuerId.search":"Geben Sie eine Aussteller-ID ein.",
"provision.spinfo.description":"Beschreibung",
"provision.spinfo.certificate":"Zertifikatsinhalt",
"provision.spinfo.createTime":"CreateTime",
"provision.spinfo.validatehttp":"Das URL-Format ist ungültig.",
"provision.spinfo.idpfile":"IDP-Datei",
"provision.spinfo.willdeletespinfo":"Möchten Sie die ausgewählten SP-Informationen wirklich löschen?",
"provision.spinfo.selecdeletetspinfo":"Wählen Sie die SP-Informationen aus, die gelöscht werden sollen.",
"provision.spinfo.modifyspinfofailed":"Das Ändern der SP-Informationen ist fehlgeschlagen.",
"provision.spinfo.addspinfofailed":"SP-Informationen konnten nicht hinzugefügt werden.",
"provision.spinfo.addspinfosuccess":"SP-Informationen wurden erfolgreich hinzugefügt.",
"provision.spinfo.deletespinfosuccess":"SP-Informationen wurden erfolgreich gelöscht.",
"provision.spinfo.deletespinfofailed":"Das Löschen der SP-Informationen ist fehlgeschlagen.",
"provision.spinfo.modifyspinfosuccess":"SP-Informationen wurden erfolgreich geändert.",
"provision.spinfo.beginlettervalidate":"Bitte beginnen Sie mit einem Buchstaben.",

"provision.accesscode.message.invalid":"Ungültige Zeichen.",
"provision.spinfo.msg.certificateformatinfo":"Die Start- und Endkennungen ----BEGIN CERTIFICATION---- und -----END CERTIFICATION---- dürfen nicht ausgelassen werden.",
"provision.accesscode.table.enterpriseName":"Name des Unternehmens",
"provision.accesscode.table.callout":"Ausgehender Anruf",
"provision.accesscode.table.callType":"Anruftyp",
"provision.accesscode.table.call":"Eingehende und ausgehende Anrufe",

"provision.accesscode.table.callin":"Eingehender Anruf",
"provision.tenantspace.feature.appCubeSupport":"AppCube",
"provision.tenantspace.feature.selectAppCubeSupport.description":"Aktivieren Sie die Funktion AppCube, damit der Mandantenbereich die Funktion AppCube integrieren kann.",
"provision.tenantspace.feature.appcube.close.error":"Bevor Sie die Funktion AppCube deaktivieren\, deaktivieren Sie die {0}-Basisfunktionen.",
"provision.tenantspace.feature.case.open.confirmmsg":"Die Funktion AppCube ist die Grundlage für die Ausführung der Fallfunktion und wird gleichzeitig aktiviert.",
"provision.tenantspace.feature.newcase.open.customcenter.confirmmsg":"Die Funktionen AppCube und Customer Center werden ebenfalls aktiviert, da sie die Grundlage für die Ausführung der Case 2.0-Funktion bilden.",
"provision.tenantspace.feature.appcubeIkbs.close.error":"Deaktivieren Sie die Knowledge Base-Funktion, bevor Sie die Funktion AppCube deaktivieren.",
"provision.tenantspace.feature.knowledge.open.confirmmsg":"Die Funktion AppCube ist die Grundlage für die Ausführung der Knowledge Base-Funktion und wird gleichzeitig aktiviert.",

"provision.appversionmanagement.systemType":"Systemtyp",
"provision.appversionmanagement.versionNumber":"Versionsnummer",
"provision.appversionmanagement.pleaseEnter":"-Eingabetaste-",
"provision.appversionmanagement.pleaseSelect":"-Auswahl-",
"provision.appversionmanagement.versionName":"Versionsname",
"provision.appversionmanagement.new":"Hinzufügen",
"provision.appversionmanagement.batchDelete":"Stapel löschen",
"provision.appversionmanagement.forceUpdate":"Erzwungene Aktualisierung",
"provision.appversionmanagement.updateContent":"Inhalt aktualisieren",
"provision.appversionmanagement.downloadAddress":"Adresse zum Herunterladen",
"provision.appversionmanagement.appPackage":"Versionspaket",
"provision.appversionmanagement.updateTime":"Aktualisierungszeit",
"provision.appversionmanagement.iOS":"IOS",
"provision.appversionmanagement.android":"Android",
"provision.appversionmanagement.androidH5":"Android H5",
"provision.appversionmanagement.harmony":"HarmonyOS",
"provision.appversionmanagement.sureDeleteVersionNumber":"Möchten Sie die Versionsinformationen wirklich löschen?",
"provision.appversionmanagement.iOSH5":"IOS H5",
"provision.appversionmanagement.historicalVersion":"Historische Version",
"provision.appversionmanagement.selectDataDeleted":"Wählen Sie die zu löschenden Daten aus.",
"provision.appversionmanagement.latestVersion":"Letzte Version",
"provision.appversionmanagement.sureDeleteData":"Möchten Sie die Daten wirklich löschen?",
"provision.appversionmanagement.cannotEnterNumberSmaller":"Der Wert muss größer als die maximale Versionsnummer sein.",
"provision.appversionmanagement.cannotContainSpecialCharacters":"Der Wert darf nicht die folgenden speziellen characters: ~!{'@'}#$%&*()/\\_=+{}:'\<>?[]",

"provision.appversionmanagement.queryFailed":"Suchfehler.",
"provision.appsitesmanagement.pleaseEnter":"-Auswahl-",

"provision.appsitesmanagement.siteName":"Standortname",
"provision.appsitesmanagement.batchDelete":"Stapel löschen",
"provision.appsitesmanagement.siteDesc ":"Standortbeschreibung",
"provision.appsitesmanagement.new":"Hinzufügen",
"provision.appsitesmanagement.siteAddr ":"Standortadresse",
"provision.appsitesmanagement.videoAvailable ":"Video verfügbar",
"provision.appsitesmanagement.creator ":"Ersteller",
"provision.appsitesmanagement.appcubeAvailable ":"AppCube verfügbar",
"provision.appsitesmanagement.modifyTime ":"Zeit ändern",
"provision.appsitesmanagement.modifier ":"Modifizierer",
"provision.appsitesmanagement.createTime ":"Erstellungszeit",
"provision.appsitesmanagement.sureSelectDataDeleted":"Wählen Sie die zu löschenden Daten aus.",
"provision.appsitesmanagement.sureDeleteData":"Das Löschen dieser Website kann dazu führen, dass Benutzer sich nicht bei der mobilen App anmelden können. Möchten Sie sie wirklich löschen?",
"provision.appsitesmanagement.queryFailed ":"Suchfehler.",
"provision.appsitesmanagement.sureBatchDeleteData":"Das Löschen dieser Websites kann dazu führen, dass sich Benutzer nicht bei der mobilen App anmelden können. Möchten Sie sie wirklich löschen?",
"provision.appsitesmanagement.cannotContainSpecialCharacters":"Der Wert darf nicht die folgenden speziellen characters: ~!{'@'}#$%&*()/\\_=+{}:'\<>?[]",
"provision.appsitesmanagement.unable":"Nein",
"provision.appsitesmanagement.enable":"Ja",
"provision.appsitesmanagement.protocol.check":"Beachten Sie, dass es sich bei dem von der eingegebenen Standortadresse verwendeten Netzwerkprotokoll nicht um ein sicheres Protokoll handelt. Wenn Sie sicher sind, dass Sie es verwenden\, können Sicherheitsrisiken bestehen.",
"provision.callcenterinstanceslist.softPhone.authtype":"Authentifizierungsmodus",
"provision.appsitesmanagement.details ":"Standortdetails",
"provision.softphone.label.authtype.uap":"UAP-Authentifizierung",
"provision.softphone.label.authtype.unified":"Einheitliche Authentifizierung",
"provision.tenantspace.feature.softphonenounifiedauth":"Einheitliche Authentifizierung mit Softphone-Nummer",
"provision.tenantspace.feature.softphonenounifiedauth.description":"Aktivieren Sie die Funktion zur einheitlichen Authentifizierung von Softphone-Nummern, damit der Mieterraum die einheitliche Authentifizierung von Softphone-Nummern unterstützt.",
"provision.systemparam.url.safe.tip":"Es werden unsichere Protokolle verwendet, die Sicherheitsrisiken verursachen",
"provision.log.downloadIdpMetadtaXml":"IDP-Metadaten-Xml herunterladen",

"provision.tenantspace.save.successwithsamevccid":"Die Speicherung war erfolgreich. Es existiert ein Mandantenbereich, dessen VCC-ID mit dem aktuellen Mandantenbereich identisch ist. Wenden Sie sich an den Administrator.",

"provision.appversion.msg.uploadfile.data.fail":"Falsche Dateiparameter.",

"provision.appversion.msg.uploadfile.type.fail":"Falsches Dateiformat.",
"provision.appversion.msg.uploadfile.0.success":"Dateiverifizierung erfolgreich.",
"provision.appversion.msg.uploadfile.1.fail":"Verifizierung des Dateinamens fehlgeschlagen.",
"provision.appversion.msg.uploadfile.2.fail":"Dateiformatverifizierung fehlgeschlagen.",
"provision.appversion.msg.uploadfile.3.fail":"Die Überprüfung der Dateigröße ist fehlgeschlagen. Überprüfen Sie den Systemparameter Maximale Größe eines hochgeladenen Installationspakets für mobile Apps auf der Seite Einstellungen > Systemparameterkonfiguration.",
"provision.appversion.msg.uploadfile.fail":"Datei konnte nicht hochgeladen werden. Ändern und erneut hochladen.",
"provision.appversion.msg.uploadfile.4.fail":"Die Datei birgt Injektionsrisiken. Führen Sie eine Analyse durch.",
"provision.appversion.msg.uploadfile.9.fail":"Dateiverifizierung fehlgeschlagen.",
"provision.appversion.msg.process.fail":"Die App-Versionsinformationen konnten nicht verarbeitet werden.",
"provision.appversion.msg.uploadfile.strem.fail":"Dateistream konnte nicht generiert werden.",
"provision.callcent.packagefile.saveinfo":"Erstellung erfolgreich. Das Versionspaket wird verarbeitet. Warten Sie, bis die Seite das Verarbeitungsergebnis des Versionspakets aktualisiert hat.",
"provision.appversionmanagement.appPackageTips":"Es kann nur eine APK-Datei hochgeladen werden.",
"provision.callcent.packagefile.updateinfo":"Update erfolgreich. Das Versionspaket wird verarbeitet. Warten Sie, bis die Seite das Verarbeitungsergebnis des Versionspakets aktualisiert hat.",
"provision.ccp.redis.packagefile.success":"Das Versionspaket wurde erfolgreich hochgeladen.",
"provision.ccp.redis.packagefile.failed":"Das Versionspaket konnte nicht hochgeladen werden.",
"provision.appversion.msg.process.modify":"App-Version ändern",
"provision.ccp.redis.packagefile.inprogress":"Das Versionspaket wird hochgeladen.",

"provision.appversion.msg.process.add":"App-Version hinzufügen",
"provision.appversion.msg.throttling.fail":"Erstellen oder ändern Sie das Limit für den Schnittstellendatenverkehr alle 2 Minuten. Versuchen Sie es später erneut.",
"provision.appversionmanagement.protocol.check":"Beachten Sie, dass das von der eingegebenen Download-Adresse verwendete Netzwerkprotokoll nicht sicher ist. Wenn Sie sicher sind, dass Sie es verwenden\, können Sicherheitsrisiken bestehen.",
"provision.whiteListAddress.channel.push":"Push-Adresse für Multimedia-Kanalnachrichten",
"provision.whiteListAddress.downlink.message.apifabric.push":"Multimedia-Nachricht-Downstream-API-Fabric-Adresse",
"provision.appversionmanagement.regVersion":"Die Versionsnummer besteht aus Buchstaben\, Ziffern und Punkten. Er darf nicht mit einem Punkt beginnen oder enden.",
"provision.callcent.platformserver.kafka":"Nachrichten-Middleware (Kafka)",
"provision.callcent.platformserver.kafka.switch.panel":"Gibt an, ob der Dienst aktiviert werden soll.",
"provision.whiteListAddress.callout.push":"Push-Adresse für das Ergebnis des ausgehenden Anrufs",
"provision.whiteListAddress.internal.message.notification":"IP-Adresse und Port zum Aufrufen des App-Dienstes für interne Nachrichtenbenachrichtigungen",

"provision.callcent.platformserver.account":"Dienstkonto",
"provision.callcent.platformserver.es":"Suche nach Diensten (ElasticSearch)",
"provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.label":"Anrufparameter",
"provision.callcent.platformserver.certificateChoose":"Zertifikat auswählen",

"provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callPeriod.label":"Anrufdauer (1s bis 60s)",
"provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.placeholder":"Stellen Sie diesen Parameter basierend auf der Anzahl der gleichzeitigen IVR-Sitzungen\, der Anzahl der gleichzeitigen Kernnetzwerksitzungen\ und der Anzahl der vom Mandanten konfigurierten gleichzeitigen Leitungssitzungen ein.",
"provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callNums.label":"Anzahl der Anrufdaten (1 bis 10)",
"provision.tenantspace.feature.VoiceNotificationSupport.featureParams.label":"Anrufparameter",
"provision.tenantspace.feature.VoiceNotificationSupport.featureParams.placeholder":"Stellen Sie diesen Parameter basierend auf der Anzahl der gleichzeitigen IVR-Sitzungen\, der Anzahl der gleichzeitigen Kernnetzwerksitzungen\ und der Anzahl der vom Mandanten konfigurierten gleichzeitigen Leitungssitzungen ein.",
"provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callPeriod.label":"Anrufdauer (1s bis 60s)",
"provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callNums.label":"Anzahl der Anrufdaten (1 bis 10)",
"provision.subscribedetail.pageTitle":"Abonnementdetails mit einem Klick",

"provision.subscribedetail.button.save":"Speichern",
"provision.subscribedetail.button.reset":"Zurücksetzen",
"provision.subscribedetail.button.search":"Abfrage",
"provision.subscribedetail.button.delete":"Löschen",
"provision.subscribedetail.label.operate":"Betrieb",
"provision.subscribedetail.label.companyName":"Unternehmen",
"provision.subscribedetail.button.update":"Bearbeiten",
"provision.subscribedetail.label.phoneNo":"Telefonnummer der Kontaktperson",
"provision.subscribedetail.label.emailAddr":"E-Mail-Adresse der Kontaktperson",
"provision.subscribedetail.label.createTime":"Angewendet am",
"provision.subscribedetail.label.companySize":"Unternehmensgröße",
"provision.subscribedetail.label.enableStatus":"Abonnement",
"provision.subscribedetail.label.remark":"Anmerkung",
"provision.subscribedetail.label.emailStatus":"E-Mail wird gesendet",
"provision.subscribedetail.label.contactName":"Name der Kontaktperson",
"provision.subscribedetail.success.save":"Erfolgreich gespeichert.",
"provision.subscribedetail.success.delete":"Löschen erfolgreich.",
"provision.subscribedetail.success.title":"Erfolg",
"provision.subscribedetail.delete.confirm":"Möchten Sie die Abonnementdetails wirklich löschen?",
"provision.subscribedetail.delete.title":"Bestätigung löschen",
"provision.subscribedetail.error.title":"Fehler",
"provision.subscribedetail.error.save":"Speichern fehlgeschlagen.",
"provision.subscribedetail.error.delete":"Löschen fehlgeschlagen.",
"provision.subscribedetail.error.query":"Abfrage fehlgeschlagen.",
"provision.subscribedetail.error.query.maxDay_30":"Das Abfrageintervall darf nicht länger als 30 Tage sein.",
"provision.subscribedetail.enableStatus.value_1":"Erfolgreich abonniert",
"provision.subscribedetail.emailStatus.value_0":"Senden fehlgeschlagen",
"provision.subscribedetail.enableStatus.value_0":"Abonnement fehlgeschlagen",
"provision.subscribedetail.emailStatus.value_1":"Erfolgreich gesendet",
"provision.subscribedetail.validate.alphavalidate":"Es gibt ungültige Zeichen. Es werden nur Buchstaben\, Ziffern\ und Unterstriche (_) unterstützt.",
"provision.subscribedetail.validate.laterThanEndTime":"Die Startzeit darf nicht nach der Endzeit liegen.",
"provision.subscribedetail.validate.beginwithalpha":"Muss mit einem Brief beginnen.",
"provision.subscribedetail.label.createSource":"Quelle",
"provision.subscribedetail.createSource.value_0":"Offizielle Website von HUAWEI CLOUD",
"provision.subscribedetail.createSource.value_1":"Offizielle Software-Cloud-Website",


"provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport":"App-Anrufaufgabe",
"provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport.description":"Diese Funktion ermöglicht es Agenten des Mandanten, Anrufaufgaben in mobilen Apps zu bearbeiten.",
"provision.tenantspace.feature.onlineCustomerServiceAssets.description":"Diese Funktion ermöglicht es dem Mandanten, mit AppCube entwickelte JS-Online-Chat-Clients in Seiten von Drittanbietern einzubetten, um den Online-Chat zu implementieren.",
"provision.tenantspace.feature.onlineCustomerServiceAssets.open.confirmmsg":"AppCube und intelligente IVR-Funktionen werden ebenfalls aktiviert, da sie die Grundlage für die Ausführung der Online-Kundendienstfunktion darstellen.",
"provision.tenantspace.feature.onlineCustomerServiceAssets":"Ressource des Online-Kundendienstes",
"provision.tenantspace.feature.smartivr.close.error":"Deaktivieren Sie die Online-Kundenservice-Asset-Funktion, bevor Sie die intelligente IVR-Funktion deaktivieren.",
"provision.tenantspace.feature.dualCallAppSupport.open.confirmmsg":"Die Funktion AppCube wird ebenfalls aktiviert, da sie die Grundlage für die Ausführung der App-Aufrufaufgabe ist.",
"provision.tenant.management.tenantexport.addTask":"Erstellen einer Exportaufgabe",
"provision.tenant.management.tenantexport.viewTask":"Abfragen von Exportaufgaben",
"provision.tenant.management.tenantexport.cancel":"Abbrüche",
"provision.tenant.management.tenantexport.exceedlimit1":"Es können maximal \{0} Datensätze exportiert werden.",
"provision.tenant.management.tenantexport.exceedlimit2":", grenzen Sie den Datenzeitbereich ein oder fügen Sie Datenfilterbedingungen hinzu.",

"provision.tenant.management.tenantexport.confirm":"Bestätigung",
"provision.tenant.management.tenantexport.addTaskSuccess":"Die Exportaufgabe wurde erfolgreich hinzugefügt.",
"provision.tenant.management.tenantexport.addTaskError":"Exportaufgabe konnte nicht hinzugefügt werden. Bitte überprüfen Sie das Protokoll.",
"provision.label.tenantexport.exception":"Bei der Generierung der Exportaufgabe ist eine Ausnahme aufgetreten. Bitte überprüfen Sie das Protokoll.",
"provision.label.tenantexport.parainvalid":"Die Exportbedingung konnte nicht verifiziert werden.",
"provision.label.tenantexport.paracomplexity":"Die Komplexität des Kennworts entspricht nicht den Anforderungen.",
"provision.common.message.error":"Fehler",
"provision.label.tenantexport.parainnull":"Wählen Sie die zu exportierenden Daten aus.",
"provision.common.message.success":"Erfolg",
"provision.label.tenantexport.addExportTask":"Hinzufügen einer Exportaufgabe",
"provision.label.tenantexport.viewExportTask":"Anzeigen von Exportaufgaben",
"provision.label.tenantexport.confirm":"Bestätigung",
"provision.label.information":"Hinweise",
"provision.label.tenantexport.confirmMessage":"Möchten Sie wirklich eine Exportaufgabe erstellen?",
"provision.label.tenantexport.refresh":"Aktualisieren",
"provision.label.tenantexport.download":"Herunterladen",
"provision.label.tenantexport.exportEndTime":"Endzeit des Exports",
"provision.label.tenantexport.exportBeginTime":"Startzeit des Exports",
"provision.label.tenantexport.exportTaskStatus":"Aufgabenstatus exportieren",
"provision.label.tenantexport.operation":"Betrieb",
"provision.label.tenantexport.conditions":"Suchkriterien",
"provision.label.tenantexport.addExportTaskSuccess":"Die Exportaufgabe wurde erfolgreich hinzugefügt.",
"provision.label.tenantexport.addExportTaskError":"Exportaufgabe konnte nicht hinzugefügt werden. Bitte überprüfen Sie das Protokoll.",
"provision.label.tenantexport.success":"Erfolg",
"provision.label.tenantexport.exportiong":"Wird exportiert",
"provision.label.tenantexport.zippwd":"Komprimiertes Kennwort",
"provision.label.tenantexport.passValidateFailed":"Der Wert muss Buchstaben\, Ziffern\ und Sonderzeichen _{'@'}% enthalten.",
"provision.label.tenantexport.inputzippwd":"Geben Sie das Komprimierungskennwort ein.",
"provision.label.tenantexport.importdata":"Daten werden importiert",
"provision.label.tenantexport.popwindowmessage":"Geben Sie das Kennwort zum Erstellen komprimierter Dateien ein. Das Kennwort muss Buchstaben\, Ziffern\ und Sonderzeichen _{'@'}% enthalten.",
"provision.label.tenantexport.unprocessed":"Unverarbeitet",
"provision.tenantexport.download":"Herunterladen von Mieterinformationen",
"provision.tenant.management.tenantcreate.param.content.rule":"Der Eingabeparameter besteht aus Buchstaben\, Ziffern\ und Unterstrichen (_)\. Er muss mit einem Buchstaben beginnen und mindestens zwei Zeichen enthalten.",
"provision.tenant.management.tenantcreate.param.exceed":"Die Länge des Eingabeparameters muss weniger als 30 Zeichen betragen.",
"provision.label.tenantexport.failed":"fehlschlagen",
"provision.tenant.management.tenantcreate.param.failed":"Der Eingabeparameter ist ungültig.",
"provision.tenantspace.feature.transfer.onlinecheck.description":"Aktivieren Sie die Online-Abfragefunktion von Dump-Ressourcen, und Mietraum unterstützt die Online-Abfrage von Dump-Ressourcen",
"provision.tenantspace.feature.transfer.onlinecheck":"Abbildressourcen online anzeigen",
"provision.tenantspace.detail.tenantspace.cannot.open.uploadfeature":"Ob der Ressourcenabbild und die Online-Abfragefunktion von Abbildressourcen gleichzeitig aktiviert werden sollen",
"provision.tenantspace.open.uploadfeature":"Öffnen bestätigen",
"provision.vcallcenter.message.unknowdesc":"Unbekannte Ausnahme.",
"provision.vcallcenter.message.unknowsolution":"Wenden Sie sich an die Ingenieure von Huawei, um den Fehler zu analysieren und zu beheben",
"provision.tenantspace.detail.tenantspace.cannot.close.uploadfeature":"Bitte deaktivieren Sie zuerst die Online-Überprüfungsfunktion für die Dump-Ressource",
"provision.whiteListAddress.resourcedump.css":"Ressourcenabbild-CSS-Server",
"provision.vcallcenter.message.unknowcause":"Unbekannte Ursache",
"provision.whiteListAddress.resourcedump.cssproxy":"Ressourcenabbild-CSS-Server-Agent",
"provision.systemparam.algorithm.unsafe":"Es wird ein unsicherer Algorithmus verwendet, der ein Sicherheitsrisiko darstellt",

"provision.systemparam.algorithm.suggest":"Achtung: Seien Sie konsistent mit UAP",

"provision.tenantspace.feature.contractdigitalsignature":"Digitale Signatur des Vertrags",
"provision.tenantspace.feature.contractdigitalsignature.description":"Diese Funktion ermöglicht digitale Signaturen für elektronische Verträge.",
"provision.callcenterinstanceslist.vcallcenter.ccdis":"CCDIS-Server",

"provision.callcenterinstanceslist.ccdis.ccdisId":"Kennung",
"provision.callcenterinstanceslist.ccdis.ccdisIP":"Primäre IP-Adresse",
"provision.callcenterinstanceslist.ccdis.ccdisbackupIP":"IP-Adresse im Standby-Modus",
"provision.callcenterinstanceslist.ccdis.remark":"Nutzungsbeschreibung",
"provision.callcenterinstanceslist.ccdis.poolNodeId2":"CTI-Knoten, zu dem das Standby-CCDIS gehört",
"provision.callcenterinstanceslist.ccdis.poolNodeId":"CTI-Knoten, zu dem das aktive CCDIS gehört",
"provision.callcenterinstanceslist.ccdis.progId":"ProgID",
"provision.callcenterinstanceslist.ccdis.tenantSpaceName":"Name des Mieters",
"provision.callcenterinstanceslist.ccdis.syncconfim.msg":"Möchten Sie die CCDIS-Konfigurationen wirklich synchronisieren?",
"provision.tenantspace.config.chooseCcdis":"Wählen Sie einen CCDIS-Server aus.",
"provision.callcenterinstanceslist.ccdis.ccdisDeleteConfirm":"Möchten Sie den ausgewählten CCDIS-Server wirklich löschen?",
"provision.callcenterinstanceslist.ccdis.ccdisNetId":"Aktive NE-ID",
"provision.callcenterinstanceslist.ccdis.ccdisUpdate":"Bearbeitung",
"provision.callcent.error.updateccdis.associationvcallcener":"Der ausgewählte CCDIS-Server ist dem Mandanten zugeordnet und kann nicht aktualisiert werden.",
"provision.callcent.error.deleteccdis.associationvcallcener":"Der ausgewählte CCDIS-Server ist dem Mandanten zugeordnet und kann nicht gelöscht werden.",
"provision.tenantspace.list.search.date.error":"Ungültiges Ablaufdatum des Mandanten. Bitte geben Sie einen korrekten Zeitraum ein.",

"provision.target.selectDate":"Datum auswählen",
"provision.callcent.error.deleteinfo.from.cms.failed":"Das CMS kann zugehörige Informationen nicht löschen.",

"provision.callcent.error.deleteinfo.from.cms.bad.input":"CMS-Eingabe konnte nicht verifiziert werden.",
"provision.callcent.error.deleteinfo.from.cms.unknown.error":"Im CMS ist ein unbekannter Fehler aufgetreten.",
"chat.usefulexpressions.message.saveSuccess":"Gemeinsamer Ausdruck erfolgreich gespeichert.",
"chat.client.greeting.dear":"Der Ehrenwerte",
"chat.agent.title.enterText":"Bitte geben Sie ein",
"chat.usefulexpressions.message.contentLength":"Stellen Sie sicher, dass der allgemeine Ausdruck maximal 1000 Zeichen enthält.",
"chat.agent.title.transfer":"Weitergeleitet an",
"provision.callcenterinstanceslist.vcallcenter.comma":";",
"provision.callcenterinstanceslist.config.SysAdminAccount":"Systemadministratorkonto",
"chat.skill.label.inputName":"Name",
"chat.session.label.evaluation":"Sitzungsbewertung",
"chat.session.label.exitQueueTime":"Zeit außerhalb der Warteschlange",

"provision.callcent.error.entrynotexsit":"Der Vertrauenslisteneintrag existiert nicht.",
"chat.agent.label.send":"Wird gesendet",
"chat.session.endType.customerEnd":"Ende des Kunden",
"chat.skill.message.success":"Erfolg.",
"chat.skill.label.skillDetail":"Fertigkeitsdetails",
"chat.skill.message.isExisted":"Der Skill-Name existiert bereits.",
"chat.usefulexpressions.message.selectExpressions":"Bitte wählen Sie einen gängigen Ausdruck.",
"chat.weChatConfig.message.skillExisted":"Die ausgewählte Skill-Warteschlange wurde an ein anderes offizielles Konto gebunden.",
"chat.thirdportal.chatWindow.label.userName":"Name",
"chat.agentConsole.message.content.audio":"[Sprachnachricht]",
"chat.client.label.loginTitle":"Anmeldung",
"OPERLOGS.TENANTSPACE.SYNC":"Synchronisieren Sie den Mieterbereich.",
"chat.skill.label.maxQueueTimeInTable":"Maximale Warteschlangenzeit",
"chat.client.label.chatTitle":"Kunde",
"chat.webconfig.label.greeting":"Grüße",
"chat.thirdportal.chatWindow.label.userEmail":"Postfach",
"chat.weChatConfig.title.deleteTitle":"Löschen eines WeChat-Konfigurationselements",
"chat.skill.message.willDeleteSkill":"Möchten Sie den Skill wirklich löschen?",
"chat.client.message.evaluationFail":"Zufriedenheitsumfrage fehlgeschlagen.",
"chat.weChatConfig.label.codeName":"Kanalzugangscode.",
"chat.weChatConfig.message.ensureDelete":"Möchten Sie das ausgewählte Konfigurationselement wirklich löschen?",
"chat.client.label.order":"Meine Bestellungen",
"chat.webconfig.label.intergratedcode":"Integrationscode",
"provision.callcent.error.ccInstidInvalid":"Ungültige Callcenter-Instanz.",
"chat.session.channel.all":"Alle",
"chat.skill.label.reset":"Zurücksetzen",
"chat.usefulexpressions.message.contentNotNull":"Geläufige Phrasen dürfen nicht leer sein.",
"chat.timerange.message.timeerror":"Das ausgewählte Zeitformat ist falsch.",
"chat.agent.label.offline":"Offline",
"chat.session.label.allChannel":"Alle Zugriffskanäle",
"chat.weChatConfig.stepname.verify":"Zur Überprüfung einreichen",
"provision.callcenterinstanceslist.softPhone.vcallcenterid":"ID eines virtuellen Callcenters.",
"provision.systemparam.url.unsafe":"(unsicher)",
"provision.tenantspace.dataUpload.resourceType":"Ressourcenabbildtyp",
"chat.agent.label.chatBot":"Intelligenter Kundenservice",
"chat.weChatConfig.title":"Konfiguration der Bereitstellung sozialer Medien",
"chat.session.label.subChannel":"Unterzugriffskanal",
"chat.timerange.label.sevendays":"Letzte 7 Tage",
"chat.thirdportal.chatWindow.title":"Online-Kundendienst",
"chat.weChatConfig.stepname.success":"Der Zugriff war erfolgreich.",
"provision.tenantspace.dataUpload.reportData":"Berichtsdaten",
"provision.callcenterinstanceslist.vcallcenter.vccId":"VCC-ID",
"provision.callcent.error.redisfail":"CC-AI Redis konnte nicht aktualisiert werden.",
"provision.callcenterinstanceslist.config.startworkno":"Startnummer der Arbeits-ID",
"provision.tenantspace.config.agentcall.zero":"Die Anzahl der gleichzeitigen Sprachanrufe darf nicht 0 sein.",
"chat.weChatConfig.stepname.selectchannel":"Wahl eines Zugriffskanals",
"chat.weChatConfig.label.nextStep":"Der nächste Schritt",
"chat.thirdportal.chatWindow.label.userPhone":"Zahl",
"provision.callcenterinstanceslist.vcallcenterDeatil.vccEnabled":"Gibt an, ob der VDN aktiviert werden soll.",
"chat.skill.message.warning":"Warnungen",
"chat.timerange.message.timeempty":"Die ausgewählte Zeit darf nicht leer sein.",
"provision.batchimport.message.uploaded.button":"Hochladen",
"chat.session.label.channel":"Sitzungszugriffskanal",
"provision.callcenterinstanceslist.config.agentUsage":"Anzahl der zugewiesenen Agenten",
"chat.session.label.created_startTime":"Startzeit",
"provision.callcenterinstanceslist.vcallcenterDeatil.ccInstId":"Anrufzentralennummer.",
"chat.session.label.evaluationTime":"Zeit der Sitzungsbewertung",
"chat.agent.label.waiting":"Warten auf",
"chat.session.channel.facebook":"Facebook",
"provision.callcenterinstanceslist.vcallcenterDeatil.vccName":"VDN-Name",
"chat.skill.message.failDeleteSkill":"Skill konnte nicht gelöscht werden.",
"chat.weChatConfig.message.unknown":"Überprüfungsstatus unbekannt!",
"provision.tenantspace.table.maxcallnums":"Maximale Anzahl gleichzeitiger Sprachanrufe (≥ Summe der Anzahl der Sprachagenten und der Anzahl der Audio- und Video-IVR-Kanäle)",
"chat.webconfig.label.basesettings":"Basiskonfiguration",
"provision.callcenterinstanceslist.vcallcenterDeatil.tenantSpaceId":"Mieter-ID",
"chat.agentConsole.message.error.notification":"Es kann keine neue Nachrichtenerinnerung erstellt werden. Bitte überprüfen Sie Ihre Browserkonfiguration.",
"chat.weChatConfig.label.state":"Status",
"chat.client.evaluate.message":"Bitte kommentieren Sie meinen Service\, vielen Dank!",
"chat.session.channel.web":"Webseite",
"chat.client.label.promotions":"Tipps",
"chat.skill.label.name":"Benutzername",
"chat.session.channel.twitter":"Twitter",
"chat.agent.message.changeFailed":"Der Agentenstatus konnte nicht geändert werden.",
"chat.client.label.input":"Bitte geben Sie ein",
"chat.agentConsole.message.content.video":"[Videonachricht]",
"chat.webconfig.label.intergratemsg":"Live-Chat in Ihre Website integrieren",
"chat.agentConsole.message.content.connectMessage":"Sie haben eine neue Nachricht!",
"provision.sysparam.config.reset.multiple.error":"Die folgenden Parameter können nicht zurückgesetzt werden:",
"chat.session.label.endReason":"Grund für Sitzungsende",
"chat.session.label.createdTime":"Zeit der Sitzungserstellung.",
"provision.callcent.error.pagechangednowrefresh":"Der Seiteninhalt hat sich geändert und muss aktualisiert werden.",
"chat.session.label.search":"Suche nach",
"chat.session.label.sessionId":"Sitzungs-ID",
"chat.session.label.allEndType":"Alle Anschlussarten",
"chat.skill.label.deleteSkills":"Löschung",
"chat.session.label.reset":"Zurücksetzen",
"chat.usefulexpressions.message.saveFail":"Der allgemeine Ausdruck konnte nicht gespeichert werden.",
"chat.usefulexpressions.message.failDeleteExpression":"Der allgemeine Ausdruck konnte nicht gelöscht werden.",
"chat.client.label.login":"Anmeldung",
"provision.tenantspace.dataUpload.choose":"Bitte wählen Sie ...",
"chat.agent.title.createCase":"Erstellen eines Falls",
"chat.session.channel.wechat":"Wechat",
"chat.weChatConfig.label.qcode":"Code-Hosting scannen",
"chat.skill.label.priority":"Priorität",
"chat.skill.label.ok":"Bestätigt",
"chat.weChatConfig.label.delete":"Löschung",
"chat.skill.message.delete":"Löschung",
"chat.webconfig.label.windowTitle.placeholder":"Geben Sie den Titel ein, der im Chat-Fenster des Clients angezeigt werden soll.",
"chat.webconfig.message.saveFail":"Konfiguration konnte nicht gespeichert werden.",
"chat.skill.message.priorityLength":"Stellen Sie sicher, dass die Priorität nicht mehr als fünf Ziffern enthält.",
"chat.skill.message.createSuccess":"Skill erfolgreich erstellt.",
"provision.callcenterinstanceslist.whiteList.whiteListAdd":"Hinzufügen einer Vertrauensliste",
"chat.session.endType.agentEnd":"Agentenende",
"chat.skill.label.skillId":"Nein.",
"provision.tenantspace.ccName":"Callcenter",
"chat.session.label.enterQueueTime":"Zeit der Warteschlangenerstellung",
"chat.weChatConfig.message.emptyDeleteArray":"Wählen Sie das zu löschende Konfigurationselement aus.",
"chat.session.title.basic":"Grundlegende Sitzungsinformationen",
"chat.client.label.yes":"Es ist der",
"chat.skill.label.maxQueueTime":"Maximale Warteschlangenzeit (s)",
"chat.usefulexpressions.message.willDeleteExpression":"Möchten Sie den allgemeinen Ausdruck wirklich löschen?",
"chat.client.button.submit":"Senden",
"chat.timerange.label.ok":"Bestätigt",
"chat.skill.label.cancel":"Zum Abbrechen",
"provision.callcent.error.savewhiteaddripinfo":"Das Adressformat der Vertrauensliste ist falsch.",
"chat.skill.message.willDeleteAllSkills":"Möchten Sie wirklich alle Fähigkeiten löschen?",
"provision.callcenterinstanceslist.vcallcenterDeatil.accessCode":"Zugangscode, der dem VDN zugewiesen wurde.",
"chat.session.label.endType":"Sitzungsendtyp",
"chat.thirdportal.chatWindow.button.startChat":"Ein Gespräch beginnen",
"chat.weChatConfig.label.secret":"Schlüsselübergabe",
"provision.callcenterinstanceslist.vcallcenter.agentNum":"Anzahl der zugewiesenen Agenten",
"provision.tenantspace.dataUpload.voiceFile":"Stimmendatei",
"chat.agentConsole.message.content.locate":"[Standortmeldung]",
"chat.agent.label.historicalBill":"Historische Rechnungen",
"provision.callcenterinstanceslist.vcallcenter.period":".",
"chat.session.label.endTime":"Endzeit der Sitzung",
"chat.client.label.accountNumber":"Kontonummer",
"chat.client.message.hiMyWorkno":"Hallo\, meine Mitarbeiter-ID ist",
"chat.agent.label.mybill":"Meine Rechnung.",
"chat.weChatConfig.label.bindSkill":"Binden einer Skill-Warteschlange",
"chat.skill.label.submit":"Senden",
"chat.client.message.evaluationSuccess":"Zufriedenheitsumfrage erfolgreich.",
"chat.session.title.detail":"Sitzungsdetails",
"chat.webconfig.message.configdirective":"Kopieren Sie den folgenden Code und fügen Sie ihn in das </body>-Tag auf Ihrer Website ein\, speichern Sie den Code und geben Sie ihn frei, um schnell auf die Huawei Service Cloud zuzugreifen. Nach der Freigabe des Codes\ wird der Huawei Service Cloud-Eintrag in der unteren rechten Ecke Ihrer Website angezeigt. Sie können auf klicken, um eine Echtzeit-Konversation mit der Kundenservice-Workbench zu führen.",
"chat.weChatConfig.label.verify":"Überprüfung",
"chat.session.label.remark":"Kommentar zu",
"chat.usefulexpressions.message.createSuccess":"Gemeinsamer Ausdruck wurde erfolgreich erstellt.",
"provision.callcent.error.saveucsserverdesinfo.ucsServernoexist":"Der CCUCS-Server existiert nicht.",
"chat.common.message.delete":"Löschung",
"chat.agent.title.userInformation":"Kundeninformationen",
"chat.client.label.rateService":"Bitte bewerten Sie den Dienst",
"chat.client.greeting.evening":"Guten Abend!",
"provision.batchimport.message.import.button":"Importieren",
"chat.skill.message.saveSuccess":"Skill erfolgreich gespeichert.",
"chat.weChatConfig.label.create":"Hinzufügen",
"chat.webconfig.label.functionalsettings":"Funktionseinstellungen",
"chat.skill.label.createSkill":"Erstellen",
"provision.tenantspace.popuppageurl.modifyprompt":"Ändern Sie zuerst die IP-Adresse in der Vertrauensliste.",
"provision.tenantspace.table.maxVideoCallnums":"Maximale Anzahl gleichzeitiger Videoanrufe (\u2265 Anzahl Videoagenten)",
"chat.usefulexpressions.message.willDeleteExpressions":"Möchten Sie den ausgewählten allgemeinen Ausdruck wirklich löschen?",
"chat.webconfig.button.save":"Gespeichert",
"chat.agent.label.billDetail":"Rechnungsdetails",
"chat.session.label.skillQueueId":"Skill-Warteschlangen-ID.",
"chat.agent.label.online":"Online",
"chat.client.label.bill":"Meine Rechnung.",
"chat.client.label.like":"Ich schätze, Sie mögen",
"chat.webconfig.label.bandingskill":"Binden einer Skill-Warteschlange",
"provision.callcenterinstanceslist.whitelist.singledeleteconfim":"Möchten Sie die ausgewählte Vertrauenslistenadresse wirklich löschen?",
"chat.timerange.label.customizes":"Anpassung",
"provision.callcenterinstanceslist.vcallcenterDeatil.vccId":"VCC-ID",
"chat.agentConsole.message.error.emptyMessage":"[Die Nachricht enthält keinen anzuzeigenden Inhalt.]",
"chat.client.label.header":"Telco+",
"chat.client.greeting.info":"Wie kann ich Ihnen helfen?",
"chat.skill.message.saveFail":"Die Fähigkeit konnte nicht gespeichert werden.",
"provision.callcenterinstanceslist.obsServer.taskRefreshPeriod":"Aufgabenaktualisierungszeitraum",
"chat.session.channel.mobile":"Mobiles Endgerät",
"chat.weChatConfig.label.facebook":"Facebook",
"provision.tenantspace.gw.serverIP":"IP-Adresse des Gatewayservers",
"chat.timerange.label.oneday":"Letzter Tag",
"chat.agent.title.close":"Zum Herunterfahren",
"chat.session.title.history":"Historische Chataufzeichnungen",
"chat.agent.label.customerSource":"Kundenquelle: Mobile App – persönliches Zentrum",
"chat.skill.message.createFail":"Die Fähigkeit konnte nicht entwickelt werden.",
"chat.client.transfer.link":"Zum manuellen Service weiterleiten?",
"provision.callcenterinstanceslist.vcallcenterDeatil.maxCalls":"Maximale Anzahl gleichzeitiger Anrufe",
"chat.skill.label.agents":"Agenten",
"chat.session.label.servedAgent":"Servicemitarbeiter",
"chat.weChatConfig.message.codeNameExisted":"Der Kanalzugriffscode ist bereits vorhanden.",
"chat.weChatConfig.label.verifycode":"Token",
"chat.skill.label.edit":"Bearbeitung",
"chat.skill.label.save":"Gespeichert",
"chat.weChatConfig.stepname.selectmode":"Auswählen einer Konfigurationsmethode",
"chat.usefulexpressions.label.createExpressionTab":"Erstellen gängiger Sätze",
"chat.agent.label.money":"Betrag",
"chat.webconfig.message.saveSuccess":"Konfiguration erfolgreich gespeichert.",
"provision.callcent.error.whiteaddrduplicate":"Doppelte Adresse in der Vertrauensliste.",
"chat.weChatConfig.label.configId":"Konfigurations-ID",
"chat.skill.label.createSkillTab":"Eine Fähigkeit entwickeln",
"chat.skill.message.completeInputs":"Bitte vervollständigen Sie alle Einträge.",
"chat.client.label.privacyHintContent":"Um die Servicequalität zu verbessern\, wird Ihr Gespräch vom System aufgezeichnet. Möchten Sie fortfahren?",
"provision.callcent.error.paramValidateFailed":"Hinzufügen fehlgeschlagen. Die Werte von obsId\", progId\ und heartbeatPort müssen nur Ziffern enthalten.",
"chat.agent.title.userMenu":"Kundenmenü",
"provision.tenantspace.obs.name":"OBS-Servername",
"chat.common.message.warning":"Warnungen",
"chat.client.label.evaluation":"Zufriedenheitsumfrage",
"chat.webconfig.label.transHuman":"Weiterleitung zum manuellen Kundendienst",
"provision.tenantspace.customerAndmobileAgent.conflict":"Die Mobile Agent-Funktion und die 1:1-Funktion können nicht gleichzeitig aktiviert werden.",
"chat.timerange.label.alldays":"Alle Erstellungszeiten",
"provision.ccworknos.table.virtualcallcenterNumber":"VCC-ID",
"chat.session.endType.overtimeEnd":"Ende der Zeitüberschreitung",
"chat.weChatConfig.label.AppSecret":"Entwicklerkennwort",
"chat.skill.message.error":"Fehlschläge",
"chat.skill.label.description":"Beschreibung",
"chat.weChatConfig.message.error":"Fehlschläge",
"chat.usefulexpressions.label.expression":"Geläufige Phrasen",
"provision.callcenterinstanceslist.vcallcenterDeatil.agentNum":"Anzahl der zugewiesenen Agenten",
"provision.tenantspace.updateTenantSpaceStatus":"Möchten Sie den Status der Mietfläche wirklich ändern?",
"chat.skill.label.delete":"Löschung",
"chat.usefulexpressions.message.isExisted":"Der allgemeine Phrasenname existiert bereits.",
"chat.weChatConfig.message.appIdExisted":"Die offizielle Kontoentwickler-ID ist bereits vorhanden.",
"chat.client.label.password":"Das Kennwort",
"chat.agent.label.balance":"Einnahmen und Ausgaben",
"chat.client.message.gladtoServe":"Es ist eine Freude, Ihnen zu dienen!",
"chat.weChatConfig.label.verifying":"Überprüfung",
"provision.tenantspace.dataUpload.selectType":"Wählen Sie einen Diensttyp für den Ressourcenabbilddienst aus.",
"chat.thirdportal.title":"Kunden-Webportal",
"chat.webconfig.title":"Webkanal aktivieren",
"chat.agent.label.goAway":"Um zu gehen",
"chat.agent.label.total":"Gesamt",
"chat.weChatConfig.label.tenantId":"Mieter-ID",
"chat.agentConsole.message.content.image":"[Bildnachricht]",
"provision.callcenterinstanceslist.vcallcenterDeatil.password":"Konfigurieren Sie das Administratorkennwort.",
"chat.weChatConfig.label.save":"Gespeichert",
"chat.weChatConfig.label.appId":"Entwickler-ID.",
"provision.callcenterinstanceslist.config.differentPassword":"Die beiden Passwörter stimmen nicht überein.",
"provision.callcenterinstanceslist.whitelist.batchdeleteconfim":"Möchten Sie wirklich alle ausgewählten Vertrauenslistenadressen löschen?",
"chat.skill.message.selectSkills":"Bitte wählen Sie eine Fertigkeit aus.",
"chat.client.label.privacyHint":"Datenschutztipps",
"chat.timerange.label.thirtydays":"Letzte 30 Tage",
"chat.session.label.skillQueueName":"Skill-Warteschlangenname",
"chat.common.message.success":"Erfolg.",
"provision.callcenterinstanceslist.whiteList.whiteNumberAdd":"Hinzufügen einer Nummer zur Vertrauensliste",
"provision.callcenterinstanceslist.vcallcenter.tenantSpaceId":"Mieter-ID",
"chat.webconfig.label.windowTitle":"Seitentitel",
"provision.tenantspace.config.agent.zero":"Die Anzahl der Sprachagenten darf nicht 0 sein.",
"chat.client.greeting.afternoon":"Guten Tag!",
"provision.callcent.selectwhitelist":"Wählen Sie eine Vertrauenslistenadresse aus.",
"provision.tenantspace.dataUpload.contactRecord":"Kontaktdatensatz",
"chat.session.label.created_endTime":"Endzeit",
"provision.callcenterwhitelist.whiteList":"Adressen der Vertrauensliste",
"chat.client.greeting.morning":"Guten Morgen!",
"chat.common.message.error":"Fehlschläge",
"chat.agent.label.consumption":"Meine Ausgaben",
"chat.usefulexpressions.message.createFail":"Die allgemeine Phrase konnte nicht erstellt werden.",
"provision.tenantspace.updateTenantSpaceStatus.confirm":"Bestätigen der Statusänderung der Mietfläche",
"chat.webconfig.label.greeting.placeholder":"Geben Sie den Begrüßungsinhalt ein, der im Chat-Fenster des Kunden angezeigt werden soll.",
"chat.skill.label.skillName":"Name",
"chat.weChatConfig.label.search":"Suche nach",
"provision.tenantspace.feature.customeragenttype":"Leiter einzeln",
"ccprovision.recorddrive.config.sftp.accountname":"SFTP-Serverkonto",
"provision.callcenterinstanceslist.vcallcenterDeatil.configWorkNo":"Konfigurationsadministrator-ID",
"chat.weChatConfig.message.deleteError":"Konfigurationselement konnte nicht gelöscht werden.",
"chat.session.label.alias":"Kundenname",
"chat.weChatConfig.message.savesuccess":"Zugriff erfolgreich.",
"chat.client.alert.message.invildtoken":"Ihre Sitzung ist abgelaufen\ oder Sie haben sich an einem anderen Ort angemeldet. Bitte schließen Sie das Chat-Fenster und versuchen Sie es erneut. Vielen Dank!",
"chat.client.label.forgotPassword":"Passwort vergessen",
"chat.weChatConfig.link.wechat":"WeChat Hinter den Kulissen",
"chat.client.label.more":"Mehr",
"provision.callcenterinstanceslist.vcallcenter.vcallcenterDeatil":"VCC-Einzelheiten",
"chat.skill.message.willDeleteSkills":"Möchten Sie die ausgewählte Kompetenz wirklich löschen?",
"chat.skill.label.account":"Konto",
"chat.skill.label.maxQueueNumber":"Maximale Anzahl von Warteschlangen",
"chat.weChatConfig.label.Domain":"Serveradresse",
"chat.timerange.label.threedays":"Letzte 3 Tage",
"chat.agent.title.createSkill":"Eine Fähigkeit entwickeln",
"chat.client.message.noAgent":"Leider\, der Kundenservice ist beschäftigt. Versuchen Sie es später erneut.",
"chat.webconfig.label.language":"Standardsprache",
"chat.webconfig.message.demodirective":"Sie können auch die folgende URL im unsichtbaren Fenster des Chrome-Browsers öffnen, um auf die vom System bereitgestellte Demoseite zuzugreifen.",
"chat.usefulexpressions.label.expressionDetail":"Geläufige Phrasendetails",
"chat.client.label.no":"Nein.",
"provision.tenantspace.dataUpload.cdrData":"CDR-Daten",

"chat.weChatConfig.label.wechat":"Wechat",
"provision.tenantspace.feature.ivr.analysis":"IVR-Analyse",
"provision.tenantspace.feature.ivr.analysis.description":"Die Funktion der IVR-Analyse ist aktiviert und der Mandant unterstützt die IVR-Analysefunktion.",
"provision.tenantspace.feature.customercenter":"Kundenzentrum",
"provision.tenantspace.feature.customercenter.open.confirmmsg":"Die Funktion AppCube ist die Grundlage für den Betrieb der Kundencenter-Funktion und wird gleichzeitig aktiviert.",
"provision.tenantspace.feature.appcube.close.confirmmsg":"Nach dem Abschluss werden die Geschäftsdaten (z. B. Arbeitsauftragsdaten, Kontaktdaten usw.) im AppCube gelöscht und können nicht wiederhergestellt werden. Bitte bestätigen Sie, ob Sie schließen möchten oder nicht",
"provision.tenantspace.feature.whatsapp.cloudapi":"WhatsApp-Cloud-API-Verbindung",
"provision.tenantspace.common.customercenter.explain":"Aktivieren Sie die Kundencenter-Funktion, bieten Sie lokales Kundenmanagement für Mieter und zeichnen Sie die historischen Informationen verschiedener Kontaktkanäle für denselben Kunden auf.",

"provision.tenantspace.common.whatsapp.cloudapi.explain":"Diese Funktion ermöglicht es dem Mandanten, über die Kanalkonfiguration eine Verbindung zur WhatsApp-Cloud-API herzustellen, um Textnachrichten zu verarbeiten. Derzeit\ kann diese Funktion nur demonstriert und nicht kommerziell genutzt werden.",
"provision.tenantspace.feature.mobileAppH5":"MobileApp Version H5",
"provision.tenantspace.common.mobileAppH5.explain":"Durch die Aktivierung der Funktionen von MobileApp H5 können Mandanten automatisch Mobile-Agent-bezogene Funktionen auf dem Appcube installieren und den Zugriff über Mobiltelefone unterstützen",
"provision.tenantspace.feature.mobileAppH5.open.confirmmsg":"Die Funktion AppCube ist die Grundlage für die Ausführung von MobileApp-Funktionen und wird gleichzeitig aktiviert.",

"provision.tenantspace.feature.customercenter.close.error ":"Bevor Sie die Kundencenter-Funktion deaktivieren\, stellen Sie sicher, dass die Funktion Fall 2.0 deaktiviert ist.",
"provision.tenantspace.vdn.id":"VDN-ID",

"provision.tenantspace.config.error.148":"Das Authentifizierungssystem wurde einem verbundenen Benutzer zugeordnet und kann nicht geändert werden.",

"provision.log.config.agent.track.config.title":"Konfiguration der Agentenverfolgung",
"provision.log.config.agent.track.config.refresh":"Aktualisieren",
"provision.log.config.agent.track.config.tenantSpaceName":"Name des Mieterbereichs",
"provision.log.config.agent.track.config.workNo":"Agenten-ID",
"provision.log.config.agent.track.config.tenantSpaceName.error.hints":"Nur Ziffern, Buchstaben und Unterstriche sind zulässig",
"provision.log.config.agent.track.config.tenantSpaceName.length.error.hints":"Geben Sie einen Mandantenbereichsnamen mit 8 bis 30 Zeichen ein, der mit einem Buchstaben beginnt und nur Ziffern, Buchstaben und Unterstriche enthält.",
"provision.log.config.agent.track.config.logLevel":"Protokollebene",
"provision.log.config.agent.track.config.createTime":"Erstellungszeit",
"provision.log.config.agent.track.config.endTime":"Endzeit der Ablaufverfolgung",
"provision.log.config.agent.track.config.operation":"Betrieb",
"provision.log.config.agent.track.config.delete":"Löschen",
"provision.log.config.agent.track.config.batch.delete":"Stapel löschen",
"provision.log.config.agent.track.config.add":"Hinzufügen",
"provision.log.config.agent.track.config.batchDelete.hints.pleaseSelect":"Wählen Sie die Konfigurationsinformationen des Agenten-Ablaufverfolgungsprotokolls aus",
"provision.log.config.agent.track.config.batchDelete.hints.exceed.max.operate.num":"Es können maximal 100 Datensätze gleichzeitig gelöscht werden.",
"provision.log.config.agent.track.config.create.title":"Agenten-Ablaufverfolgungskonfiguration erstellen",
"provision.log.config.agent.track.config.create.confirm":"Bestätigen",
"provision.log.config.agent.track.config.create.cancel":"Abbrechen",
"provision.log.config.agent.track.config.create.endTime.placeholder":"Bitte wählen Sie die Endzeit der Nachverfolgung aus",
"provision.log.config.agent.track.config.create.must":"Erforderlich",
"provision.log.config.agent.track.config.create.tenantSpaceName.min.length":"Der Name des Mandantenbereichs muss mindestens acht Zeichen enthalten.",
"provision.log.config.agent.track.config.create.workNol.check.hints":"Geben Sie eine ganze Zahl zwischen 101 und 50000 ein",
"provision.log.config.agent.track.config.create.workNol.check.hints.1":"Geben Sie eine ganze Zahl innerhalb von 50000 ein",
"provision.log.config.agent.track.config.create.end.check.hints":"Wählen Sie einen Zeitpunkt zwischen dem aktuellen Tag und den nächsten drei Tagen aus.",
"provision.log.config.agent.track.config.success":"Erfolgreich",
"provision.log.config.agent.track.config.failed":"Fehlgeschlagen",
"provision.log.config.agent.track.config.delete.success":"Erfolgreich gelöscht",
"provision.log.config.agent.track.config.delete.failed.11":"Löschen fehlgeschlagen.",
"provision.log.config.agent.track.config.delete.failed.10":"Der Löschvorgangsparameter ist falsch.",
"provision.log.config.agent.track.config.create.top.hints":"Nachdem die Agentenprotokoll-Ablaufverfolgungsfunktion für den entsprechenden Mandanten aktiviert wurde, verbindet der Agent den Anruf und sammelt die Ablaufverfolgungsprotokolle für den CCManagement-Dienst. Dieser Vorgang wirkt sich auf die Systemleistung aus. Sie müssen diese Funktion basierend auf den tatsächlichen Anforderungen an die Nachverfolgung aktivieren.",
"provision.log.config.agent.track.config.create.success":"Erstellt erfolgreich.",
"provision.log.config.agent.track.config.create.failed":"Erstellung fehlgeschlagen.",
"provision.log.config.agent.track.config.create.failed.1":"Die Länge des Namens des Mandantenbereichs ist ungültig.",
"provision.log.config.agent.track.config.create.failed.2":"Der Name des Mandantenbereichs enthält Sonderzeichen.",
"provision.log.config.agent.track.config.create.failed.3":"Der Name des Mandantenbereichs konnte nicht überprüft werden.",
"provision.log.config.agent.track.config.create.failed.4":"Arbeits-ID konnte nicht überprüft werden.",
"provision.log.config.agent.track.config.create.failed.5":"Protokollebene konnte nicht geprüft werden.",
"provision.log.config.agent.track.config.create.failed.6":"Endzeit der Ablaufverfolgung konnte nicht überprüft werden.",
"provision.log.config.agent.track.config.create.failed.7":"Die maximale Anzahl konfigurierbarer",
"provision.log.config.agent.track.config.create.failed.8":"Für den Agenten existiert bereits eine Protokollverfolgungskonfiguration.",
"provision.log.config.agent.track.config.create.failed.9":"Erstellung fehlgeschlagen.",
"provision.log.config.agent.track.config.max.length":"Die maximale Länge beträgt",

"provision.tenant.management.tenantname.contact.blocklist.check":"Ungültige Zeichen. Die folgenden Sonderzeichen sind nicht allowed: & < > \" ; % / \\ ^ [ ] = + @",

}