import request from '@/utils/request'

// 外呼数据导入重复文件下载url
export const downloadDataImportFileUrl = '/service-cloud/u-route/isales/data/downloadDataImportFile'
// 外呼数据导入失败文件下载url
export const downloadFailDataImportFileUrl = '/service-cloud/u-route/isales/data/downloadFailDataImportFile'

/**
 * 查询外呼任务列表
 * @param data
 * @returns List taskList
 */
export const queryTaskByCondition = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryTaskByCondition',
        method: 'post',
        data
    })
}

/**
 * 查询外呼任务数量
 * @param data
 * @returns number taskInfoCount
 */
export const queryTaskInfoCount = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryTaskInfoCount',
        method: 'post',
        data
    })
}

/**
 * 外呼任务列表查询待呼叫量
 * @param data
 * @returns List tobeCallCountList
 */
export const queryTobeCallCount = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryTobeCallCountByTaskId',
        method: 'post',
        data
    })
}

/**
 * 启动外呼任务
 * @param data
 * @returns Object result
 */
export const startTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/startTask',
        method: 'post',
        data
    })
}

/**
 * 暂停外呼任务
 * @param data
 * @returns Object result
 */
export const pauseTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/pauseTask',
        method: 'post',
        data
    })
}

/**
 * 查询外呼结果
 * @param data
 * @returns Object result
 */
export function viewTaskResult(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/data/viewTaskResult',
        method: 'post',
        data
    })
}

/**
 * 查询所有业务结果
 * @param data
 * @returns Object result
 */
export function queryAllCodeAndNameOfTenant(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryAllCodeAndNameOfTenant',
        method: 'post',
        data,
    })
}

/**
 * 根据任务Id查询座席工号
 * @param data
 * @returns Object result
 */
export function queryUserAccountByTaskId(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryUserAccountByTaskId',
        method: 'post',
        data,
    })
}

/**
 * 根据条件导出外呼结果
 * @param data
 * @returns Object result
 */
export function taskCallDateExportByCondition(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/taskCallDateExportByCondition',
        method: 'post',
        data,
    })
}

/**
 * 查询组网环境
 * @param data
 * @returns Object result
 */
export function queryCmsEnvironment(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryCmsEnvironment',
        method: 'post',
        data,
    })
}

/**
 * 查询导出结果
 * @param data
 * @returns Object result
 */
export function queryExportResult(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryExportResult',
        method: 'post',
        data,
    })
}

/**
 * 查询导出数据量
 * @param data
 * @returns Object result
 */
export function queryExportResultCount(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryExportResultCount',
        method: 'post',
        data,
    })
}

/**
 * 根据用户查询座席信息
 * @param data
 * @returns Object result
 */
export function queryAgentByUserId(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/skill/queryAgentByUserId',
        method: 'post',
        data,
    })
}

/**
 * 根据用户查询外呼数据结果
 * @param data
 * @returns Object result
 */
export function queryDataResultByAgent(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/data/queryDataResultByAgent',
        method: 'post',
        data,
    })
}

/**
 * 记录外呼业务结果
 * @param data
 * @returns Object result
 */
export function recordBusiResult(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/businessResult/recordBusiResult',
        method: 'post',
        data,
    })
}

/**
 * 查询业务结果日志
 * @param data
 * @returns Object result
 */
export function queryBusiResultLog(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/busiResultLog/queryBusiResultLog',
        method: 'post',
        data,
    })
}

/**
 * 分页查询通话记录
 * @param data
 * @returns Object result
 */
export function queryCallRecordPageInfo(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/callrecord/queryCallRecordPageInfo',
        method: 'post',
        data,
    })
}

/**
 * 分页查询回访记录
 * @param data
 * @returns Object result
 */
export function queryQaRecordPageInfo(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/callrecord/queryQaRecordPageInfo',
        method: 'post',
        data,
    })
}

/**
 * 统计脚本答卷
 * @param data
 * @returns Object result
 */
export function statisticalAnswers(data) {
    return request({
        url: '/service-cloud/rest/ccsurvey/v1/answerstatistics/statisticalAnswers',
        method: 'post',
        data,
    })
}
/**
 * 问卷详情查询
 * @param data
 * @returns Object result
 */
export function querySurveyScriptById(data) {
    return request({
        url: '/service-cloud/rest/ccsurvey/v1/surveyscript/querySurveyScriptById',
        method: 'post',
        data,
    })
}

/**
 * 任务监控统计
 * @param data
 * @returns Object result
 */
export function viewStatistics(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/viewStatistics',
        method: 'post',
        data,
    })
}

/**
 * 任务监控反馈统计
 * @param data
 * @returns Object result
 */
export function viewFeedBack(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/viewFeedBack',
        method: 'post',
        data,
    })
}

/**
 * 呼叫结果统计
 * @param data
 * @returns Object result
 */
export function callResultStatistics(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/callResultStatistics',
        method: 'post',
        data,
    })
}

/**
 * 呼叫结果失败数量日统计
 * @param data
 * @returns Object result
 */
export function queryObResultMapByFailCodes(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/obResult/queryObResultMapByFailCodes',
        method: 'post',
        data,
    })
}
/**
 * 呼叫结果失败数量统计
 * @param data
 * @returns Object result
 */
export function viewFailedDetail(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/viewFailedDetail',
        method: 'post',
        data,
    })
}

/**
 * 呼叫成功日统计
 * @param data
 * @returns Object result
 */
export function viewDailyResult(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/viewDailyResult',
        method: 'post',
        data,
    })
}

/**
 * 呼叫统计接口
 * @param data
 * @returns Object result
 */
export function viewCallStatistics(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/viewCallStatistics',
        method: 'post',
        data,
    })
}

/**
 * 查询呼叫质量统计接口
 * @param data
 * @returns Object result
 */
export function queryCallQualityData(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryCallQualityData',
        method: 'post',
        data,
    })
}

/**
 * 查询呼叫监控接口
 * @param data
 * @returns Object result
 */
export function queryCallMonitorData(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryCallMonitorData',
        method: 'post',
        data,
    })
}

/**
 * 查询OBS数据统计接口
 * @param data
 * @returns Object result
 */
export function viewObslogResult(data) {
    return request({
        url: '/service-cloud/rest/isales/v1/task/viewObslogResult',
        method: 'post',
        data,
    })
}

/**
 * 外呼任务结果导出结果下载路径
 * @type {string}
 */
export const downloadResultDataUrl = '/service-cloud/u-route/isales/task/downloadDataFile'

/**
 * 批量启动外呼任务
 * @param data
 * @returns Object result
 */
export const batchStartTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/batchStartTask',
        method: 'post',
        data
    })
}

/**
 * 批量暂停外呼任务
 * @param data
 * @returns Object result
 */
export const batchPauseTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/batchPauseTask',
        method: 'post',
        data
    })
}

/**
 * 查询批量修改的任务参数
 *
 * @param data 入参
 * @return 任务参数
 */
export const queryBatchTaskParams = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryBatchTaskParams',
        method: 'post',
        data
    })
}

/**
 * 删除外呼任务
 *
 * @param data 入参
 * @return result
 */
export const deleteTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/deleteTask',
        method: 'post',
        data
    })
}

/**
 * 查询外呼数据
 *
 * @param data 入参
 * @return result
 */
export const queryTaskCallData = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/query',
        method: 'post',
        data
    })
}

/**
 * 查询外呼数据可呼叫量
 *
 * @param data 入参
 * @return result
 */
export const queryCanBeCallData = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/queryCanBeCallData',
        method: 'post',
        data
    })
}

/**
 * 查询号码类型
 *
 * @param data 入参
 * @return result
 */
export const queryCallNoTypeByTaskId = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/retrypolicy/queryCallNoTypeByTaskId',
        method: 'post',
        data
    })
}

/**
 * 查询外呼号码属性
 *
 * @param data 入参
 * @return result
 */
export const queryDataPropList = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/dataProp/queryDataPropList',
        method: 'post',
        data
    })
}

/**
 * 根据dataId查询外呼数据的所有号码
 *
 * @param data 入参
 * @return result
 */
export const queryAllCallNoByDataId = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/queryAllCallNoByDataId',
        method: 'post',
        data
    })
}

/**
 * 新建外呼数据
 *
 * @param data 入参
 * @return result
 */
export const createCallData = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/create',
        method: 'post',
        data
    })
}

/**
 * 修改外呼数据
 *
 * @param data 入参
 * @return result
 */
export const updateCallData = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/update',
        method: 'post',
        data
    })
}

/**
 * 查询预览外呼可添加的最大号码数量
 *
 * @param data 入参
 * @return result
 */
export const queryPreviewCallNoMaxLength = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/queryPreviewCallNoMaxLength',
        method: 'post',
        data
    })
}

/**
 * 删除外呼数据
 *
 * @param data 入参
 * @return result
 */
export const deleteCallData = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/delete',
        method: 'post',
        data
    })
}

/**
 * 查询外呼数据导入结果
 *
 * @param data 入参
 * @return result
 */
export const queryDataImportTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/queryDataImportTask',
        method: 'post',
        data
    })
}

/**
 * 修改外呼结果数据
 *
 * @param data 入参
 * @return result
 */
export const savePropInTaskInfo = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/savePropInTaskInfo',
        method: 'post',
        data
    })
}

/**
 * 批量修改外呼任务
 *
 * @param data 入参
 * @return result
 */
export const batchUpdateAgentTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/batchUpdateAgentTask',
        method: 'post',
        data
    })
}

/**
 * 查询外呼模版列表
 *
 * @param data 入参
 * @return result
 */
export const queryOutboundCallTemplateListAndCount = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/queryOutboundCallTemplateListAndCount',
        method: 'post',
        data
    })
}

/**
 * 暂停外呼模版
 *
 * @param data 入参
 * @return result
 */
export const suspendOutboundCallTemplate = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/suspendOutboundCallTemplate',
        method: 'post',
        data
    })
}

/**
 * 发布外呼模版
 *
 * @param data 入参
 * @return result
 */
export const releaseOutboundCallTemplate = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/releaseOutboundCallTemplate',
        method: 'post',
        data
    })
}

/**
 * 删除外呼模版
 *
 * @param data 入参
 * @return result
 */
export const deleteOutboundCallTemplate = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/deleteOutboundCallTemplate',
        method: 'post',
        data
    })
}