export default {
  "webclient.body.message.bot.recommend": "Supongo que quieres preguntar",
  "webclient.body.message.card.url": "Copiar enlace",
  "webclient.body.message.cobrowse.agree": "ACUERDO",
  "webclient.body.message.cobrowse.cancel": "Cancelación",
  "webclient.body.message.cobrowse.target": "Explorar destinos:",
  "webclient.body.message.cobrowse.title": "El agente solicita una exploración conjunta con usted",
  "webclient.body.message.file.download": "Descargar",
  "webclient.body.message.file.download.again": "Descargar de nuevo",
  "webclient.body.message.locate.latitude": "Latitud",
  "webclient.body.message.locate.longitude": "Longitud",
  "webclient.body.message.recall": "retiró un mensaje",
  "webclient.body.name.assistant": "Asistente inteligente",
  "webclient.body.tip.leave.click": "Haga clic aquí para dejar un mensaje",
  "webclient.body.tip.leave.message": "Si es necesario, por favor",
  "webclient.body.tip.leave.message.finish": "Su mensaje se ha enviado correctamente. Puedes dejar otro mensaje.",
  "webclient.body.tip.privacy": "Para mejorar la calidad del servicio, conservaremos el historial de chat entre usted y el representante de servicio al cliente o el historial de chat del robot. Garantizaremos la seguridad de los datos mediante el cifrado o el control de los derechos de acceso.",
  "webclient.body.tip.queue.cancel": "Cancelación de colas",
  "webclient.body.tip.queue.cancel.done": "Se canceló correctamente la cola, vuelva a cola, continúe enviando mensajes",
  "webclient.body.tip.queue.cancel.robot": "Se canceló correctamente la cola, el robot de conexión...",
  "webclient.body.tip.queue.part1": "Usted es el número ",
  "webclient.body.tip.queue.part2": " en la cola y necesita esperar ",
  "webclient.body.tip.queue.part3": " segundos. ",
  "webclient.body.tip.queue.timeout": "Se ha agotado el tiempo de espera de la cola",
  "webclient.body.tip.session.end": "Sesión finalizada",
  "webclient.body.tip.session.reconnect": "reiniciar sesión",
  "webclient.body.tip.session.timeout": "No respondió dentro del tiempo especificado, el sistema ha finalizado automáticamente la sesión. Puede",
  "webclient.body.tip.token.invalid": "El token ha caducado o se ha iniciado sesión de forma diferente",
  "webclient.body.title.call.disconnect": "Llamada desconectada",
  "webclient.body.title.call.end": "Llamada finalizada",
  "webclient.body.title.call.fail": "Error al llamar",
  "webclient.body.title.call.queue": "Llamada en cola",
  "webclient.body.title.call.start": "Llamar",
  "webclient.body.title.call.timeout": "Tiempo de espera de la cola de llamadas",
  "webclient.body.title.call.transfer": "Transferencia de llamadas",
  "webclient.box.tip.click.cannot.link": "No se puede conectar a la puerta de enlace WebRTC",
  "webclient.box.tip.click.check.device.fail": "Error al comprobar si el inquilino admite Click-to-Call",
  "webclient.box.tip.click.no.audio.input": "Dispositivos de entrada de audio no disponibles",
  "webclient.box.tip.click.no.audio.output": "Dispositivos de salida de audio no disponibles",
  "webclient.box.tip.click.no.video.input": "Dispositivos de entrada de vídeo no disponibles",
  "webclient.box.tip.click.not.support.browser": "El navegador no soporta WebRTC",
  "webclient.box.tip.click.not.support.channel": "Click-to-Call no soportado por el canal",
  "webclient.box.tip.click.not.support.tenant": "WebRTC no soportado por el inquilino",
  "webclient.box.tip.cobrowse.no.auth": "El agente no tiene el permiso de Co-browsing",
  "webclient.chat.nickname.system": "Sistema",
  "webclient.dialog.evaluate.placeholder": "Por favor, introduzca su opinión",
  "webclient.dialog.evaluate.title": "Evalúe el servicio de atención al cliente!",
  "webclient.dialog.leave.format.content": "El contenido del mensaje no puede estar vacío y no puede contener caracteres especiales",
  "webclient.dialog.leave.format.phone": "El número no puede estar vacío y solo puede contener dígitos, - o +",
  "webclient.dialog.leave.phone": "Ingrese un número de teléfono móvil",
  "webclient.dialog.leave.success": "Mensaje dejado correctamente",
  "webclient.dialog.leave.text": "Por favor, deje un mensaje",
  "webclient.dialog.leave.title": "dejando el mensaje",
  "webclient.dialog.selectArea.title": "Seleccione el área que desea bloquear",
  "webclient.dialog.video.default": "Predeterminado",
  "webclient.dialog.video.hide": "Ocultar vídeo local",
  "webclient.dialog.video.large": "Tamaño grande",
  "webclient.dialog.video.medium": "Tamaño medio",
  "webclient.dialog.video.pause": "Pausar vídeo",
  "webclient.dialog.video.share.off": "Cancelar Compartir",
  "webclient.dialog.video.share.on": "Compartilhamento de área de trabalho",
  "webclient.dialog.video.size": "Cambio de tamaño de vídeo",
  "webclient.dialog.video.small": "Tamaño pequeño",
  "webclient.dialog.video.start": "Vídeo sin silenciar",
  "webclient.footer.label.send.message": "Enviar mensaje",
  "webclient.footer.label.share.direct": "Compartir directamente",
  "webclient.footer.label.share.sensitive": "Selección de un área sensible",
  "webclient.footer.label.stop.direct": "Dejar de compartir",
  "webclient.footer.placeholder.richtext": "Por favor, introduzca la pregunta que desea consultar",
  "webclient.footer.send.fail.content.big": "El contenido enviado supera la longitud máxima (500 caracteres)",
  "webclient.footer.send.fail.file.format": "El formato del archivo no cumple con los requisitos",
  "webclient.footer.send.fail.file.other": "Error al enviar el archivo",
  "webclient.footer.send.fail.file.screen": "Error al enviar el archivo de captura de pantalla",
  "webclient.footer.send.fail.file.size.audio": "El tamaño del archivo de audio no puede exceder ",
  "webclient.footer.send.fail.file.size.common": "El tamaño del archivo no puede exceder ",
  "webclient.footer.send.fail.file.size.video": "El tamaño del archivo de vídeo no puede exceder ",
  "webclient.footer.send.fail.no.file": "Por favor, seleccione el archivo",
  "webclient.footer.send.fail.picture.with.text": "Actualmente, las imágenes no se pueden enviar junto con textos o emoticonos",
  "webclient.footer.tip.cobrowse.share": "Esta operación compartirá la página de su navegador, que puede contener sus datos personales. ¿Estás seguro de que quieres continuar?",
  "webclient.footer.tip.connect.fail": "Error de conexión. Inténtelo de nuevo o póngase en contacto con el administrador",
  "webclient.footer.tip.download": "El contenido de chat descargado está en formato HTML y el contenido multimedia en el contenido de chat es válido en el tiempo.Se recomienda convertirlo a un archivo PDF inmediatamente después de descargarlo. Pasos para convertir HTML a PDF: 1. Abra el archivo HTML que desea convertir en el navegador; 2. Pulse Ctrl+P (Windows) o Comando+P (Mac) para abrir el cuadro de diálogo de impresión; 3. En el cuadro de diálogo Imprimir, establezca la impresora de destino en Guardar como PDF; 4. Haga clic en el botón Guardar para convertir la página HTML a PDF y guardarla en el equipo local.",
  "webclient.footer.tooltip.audio": "Audiencias",
  "webclient.footer.tooltip.cobrowse": "Compartir",
  "webclient.footer.tooltip.download": "Descargar conversaciones",
  "webclient.footer.tooltip.emotion": "Emoción",
  "webclient.footer.tooltip.evaluation": "Evaluar",
  "webclient.footer.tooltip.file": "Cargar archivos",
  "webclient.footer.tooltip.more": "Más",
  "webclient.footer.tooltip.send": "Envíanos",
  "webclient.footer.tooltip.send.way": "Modo de envío",
  "webclient.footer.tooltip.video": "Vídeos",
  "webclient.header.title.input": "La otra parte está escribiendo",
  "webclient.header.title.service": "Servicio al cliente",
  "webclient.header.tooltip.close": "Cerrar",
  "webclient.header.tooltip.transfer.agent": "Transformar en agente",
  "webclient.header.tooltip.transfer.robot": "Transformarse en robot",
}