export default {
"message.center.label.delete" : "Delete",
"message.center.label.messageCenter" : "Notification Center",
"message.center.label.modify" : "Modify",
"message.center.label.senderName" : "Sender",
"message.center.label.sendTime" : "Sent at",
"message.center.label.bulletinType" : "Bulletin type",
"message.center.label.expireTime" : "Expired on",
"message.center.message.selectMessages" : "Select a message.",
"message.center.message.willDeleteMessages" : "Are you sure you want to delete the selected message?",
"message.center.label.reply" : "Reply",
"message.center.message.delete" : "Delete",
"message.center.label.isRead" : "Mark messages as read",
"message.center.label.refresh" : "Refresh",
"message.center.button.query" : "Query",
"message.center.label.search" : "Search",
"message.center.label.allSelect" : "Select all",
"message.center.label.select" : "Filter",
"message.center.label.newMessage" : "Send notification",
"message.center.label.receiverGroup" : "Recipient group",
"message.center.label.receiver" : "Recipient",
"message.center.label.download" : "Download",
"message.center.nocitiction.interal" : "Internal Bulletin",
"message.center.nocitiction.system" : "System Bulletin",
"message.center.nocitiction.outbox" : "Outbox",
"message.center.nocitiction.email" : "Email ",
"message.center.nocitiction.message" : "SMS",
"message.center.nocitiction.ineermessage" : "Internal Message",
"message.center.nocitiction.bulletin" : "Bulletin",
"message.center.nocitiction.inbox" : "Inbox",
"message.center.nocitiction.callback.record" : "Callback Record",
"message.center.nocitiction.sending.records" : "Sending Record",
"message.center.nocitiction.draftBox" : "Draft Box",
"message.center.nocitiction.modifyInternalMessage" : "Modify Internal Message",
"message.center.nocitiction.modifyBulletin" : "Modify Bulletin",
"message.center.nocitiction.sending.records.status" : "Status",
"message.center.nocitiction.sender" : "Sender",
"message.center.nocitiction.sending.records.smsType" : "SMS Message Mode",
"message.center.nocitiction.callback.status" : "Callback Status",
"message.center.nocitiction.callback.attachment" : "Attachment",
"message.center.nocitiction.callback.receivetime" : "Receive Time",
"message.center.nocitiction.sending.records.sended" : "Instant sending",
"message.center.nocitiction.sending.records.unsend" : "Scheduled sending",
"message.template.type.system" : "System Notification",
"message.template.type.internal" : "Internal Notification",
"message.template.mediaType.email" : "Email",
"message.template.mediaType.internal" : "Internal Message",
"message.center.label.deleteisread" : "Delete all read messages ",
"message.template.mediaType.shortmessage" : "SMS",
"message.template.message.type" : "Notification Type",
"message.template.message.mediaType" : "Message Sending Mode",
"message.template.message.messageTemplateId" : "Template ID",
"message.template.message.searchMessageTemplateName" : "Enter a template name.",
"message.template.message.messageTemplateName" : "Template Name",
"message.template.message.createTime" : "Created at",
"message.template.message.messageTemplateCreate" : "Create Notification Template",
"message.template.type.out" : "External Notification",
"message.template.button.view" : "View",
"message.template.message.operate" : "Operation",
"message.template.button.delete" : "Delete",
"message.template.message.basicInformation" : "Basic Information",
"message.template.message.inputTemplateName" : "Enter a template name.",
"message.template.message.notificationContent" : "Notification Content",
"message.template.message.recipient" : "Recipient",
"message.template.message.selectRecipient" : "Select a recipient.",
"message.template.message.inputRecipient" : "Input a recipient.",
"message.template.message.inputTitle" : "Enter a title.",
"message.template.message.cancel" : "Cancel",
"message.template.message.generate" : "Generate Template",
"message.template.message.ccOrBccRecipientError" : "The message is sent successfully. A Cc or Bcc recipient does not exist.",
"message.template.message.recipientError" : "Some recipients do not exist. Select another recipient",
"message.template.message.agentRecipientError" : "Some recipients are not bound to agents. Select another recipient",
"message.template.message.nameError" : "The template name cannot be empty or exceed 200 characters.",
"message.template.message.queryagentbebindedfailed" : "Failed to query the user bound to the agent",
"message.template.message.titleError" : "The template title cannot exceed 200 characters.",
"message.template.message.createSuccess" : "The notification template is successfully created.",
"message.center.button.clear" : "Reset",
"message.template.message.agentbebinded" : "The selected recipient is not bound to an agent",
"message.template.message.titleLengthError" : "The title cannot exceed 200 characters or contain special characters.",
"message.template.message.typeError" : "Incorrect notification type.",
"message.template.message.nameLengthError" : "The name cannot be empty and cannot exceed 200 characters or contain special characters.",
"message.template.button.edit" : "Edit",
"message.template.message.createTemplateFail" : "Failed to create the notification template.",
"message.template.message.contentLengthError" : "The content cannot be empty and cannot exceed the length.",
"message.template.message.descriptionLengthError" : "The description exceeds the limit.",
"message.template.message.nameDuplicationError" : "Duplicate template name.",
"message.template.message.contentError" : "The content cannot be empty.",
"message.template.message.frequently" : "The number of sent messages exceeds the daily threshold.",
"message.template.message.sendEmailError" : "The sender email address is empty.",
"message.template.message.messageTemplateModify" : "Modify Template",
"message.template.message.mediaTypeError" : "Incorrect sending mode.",
"message.template.message.phoneError" : "The phone number does not exist.",
"message.template.message.deleteSuccess" : "The notification template is successfully deleted.",
"message.template.message.modifySuccess" : "The notification template is successfully modified.",
"message.template.message.modifyTemplateFail" : "Failed to modify the notification template.",
"message.template.message.save" : "Save",
"message.template.message.deleteTemplate" : "Deleting a Notification Template",
"message.template.message.willDeleteTemplate" : "Deleting the template may cause the referenced function to be unavailable. Are you sure you want to continue?",
"message.template.message.noneTemplate" : "The template does not exist.",
"ccnotification.notifition.sendmessage.sysmessage" : "System Notification",
"ccnotification.notifition.sendmessage.internal" : "Internal Message",
"ccnotification.notifition.sendmessage.internalmessage" : "Internal Notification",
"ccnotification.notifition.sendmessage.bulletin" : "Bulletin",
"ccnotification.notifition.sendmessage.bulletinType" : "Bulletin Type",
"ccnotification.notifition.sendmessage.enterBulletinType" : "Select a bulletin type.",
"ccnotification.notifition.sendmessage.priority" : "Priority",
"ccnotification.notifition.sendmessage.priorityGeneral" : "Low",
"ccnotification.notifition.sendmessage.priorityUrgent" : "Medium",
"ccnotification.notifition.sendmessage.priorityExtraUrgent" : "High",
"ccnotification.notifition.sendmessage.expireTime" : "Expired On",
"ccnotification.notifition.sendmessage.priorityFault" : "Critical",
"ccnotification.notifition.sendmessage.inputBulletinType" : "Enter the bulletin type name.",
"ccnotification.notifition.sendmessage.bulletinTypeName" : "Bulletin Type Name",
"ccnotification.notifition.sendmessage.recipient" : "Recipient",
"ccnotification.notifition.sendmessage.invalidSect" : "Expiration Time Segment",
"ccnotification.notifition.sendmessage.send" : "Send",
"ccnotification.notifition.sendmessage.enterrecipient" : "Select a recipient.",
"ccnotification.notifition.sendmessage.recipientGroup" : "Recipient Group",
"ccnotification.notifition.sendmessage.enterRecipientGroup" : "Select a recipient group.",
"ccnotification.notifition.sendmessage.selectGroupOrRecipient" : "Select either the recipient or the recipient group.",
"message.template.message.deleteTemplateFail" : "Failed to delete the notification template.",
"ccnotification.notifition.sendmessage.enterCCRecipient" : "Select a Cc recipient.",
"ccnotification.notifition.sendmessage.bccRecipient" : "Bcc",
"ccnotification.notifition.sendmessage.enterBCCRecipient" : "Select a Bcc recipient.",
"ccnotification.notifition.sendmessage.notifitiontitle" : "Enter a title.",
"ccnotification.notifition.sendmessage.selecttenant" : "Select a tenant.",
"ccnotification.notifition.sendmessage.selectuser" : "Select an employee.",
"ccnotification.notifition.sendmessage.maxselectcount" : "A maximum of 100 employees can be selected.",
"ccnotification.notifition.sendmessage.entertenantname" : "Enter a tenant space name.",
"ccnotification.notifition.sendmessage.enteraccount" : "Enter a business account.",
"ccnotification.notifition.sendmessage.ccRecipient" : "Cc",
"ccnotification.notifition.sendmessage.selectall" : "Select All",
"ccnotification.notifition.sendmessage.enterusername" : "Enter a name.",
"ccnotification.notifition.sendmessage.cancelSelectall" : "Cancel All",
"ccnotification.notifition.sendmessage.sendsuccess" : "The message is successfully sent.",
"ccnotification.notifition.sendmessage.savesuccess" : "The message is successfully saved.",
"ccnotification.notifition.sendmessage.paramvalidatefailed" : "Param validate failed.",
"ccnotification.notifition.sendmessage.receiverPhoneIsNull" : "The recipient or recipient number is empty.",
"ccnotification.notifition.sendmessage.sendTimeFailed" : "The sending time is earlier than the current time.",
"ccnotification.notifition.sendmessage.sendfailed" : "Sending failed.",
"ccnotification.notifition.sendmessage.sendexception" : "Sending error.",
"ccnotification.notifition.sendmessage.messagetitle" : "Title",
"ccnotification.notifition.sendmessage.selectmode" : "Select by",
"ccnotification.notifition.sendmessage.selectbyworkno" : "Employee ID",
"ccnotification.notifition.sendmessage.delaysend" : "Scheduled Notification",
"ccnotification.notifition.sendmessage.selectbyorg" : "Organization Unit",
"ccnotification.notifition.sendmessage.selectbyskill" : "Skill queue",
"ccnotification.notifition.sendmessage.delaynotice" : "This notice will be sent on",
"ccnotification.notifition.sendmessage.senddate" : "Sending Date",
"ccnotification.notifition.sendmessage.preview" : "Preview ",
"ccnotification.notifition.sendmessage.sendtime" : "Sending Time",
"ccnotification.notifition.sendmessage.sendtimebeforenow" : "The scheduled sending time must be 5 minutes later than the current time.",
"ccnotification.notifition.sendmessage.limitdate" : "Select a date earlier than 2038-1-19.",
"message.center.message.messageQueryResultIsEmpty" : "The message query result is empty.",
"ccnotification.center.message.deleteAllRead" : "Are you sure you want to delete all read internal messages?",
"message.template.message.recipientLengthError" : "The number of recipients exceeds the limit.",
"ccnotification.center.message.toIsRead" : "Are you sure you want to mark the selected messages as read? ",
"ccnotification.notifition.receivermessage.all" : "All",
"ccnotification.notifition.receivermessage.isread" : "Read ",
"ccnotification.notifition.receivermessage.receiverSuccess" : "Received",
"ccnotification.notifition.receivermessage.unread" : "Unread ",
"ccnotification.notifition.sendmessage.isSendall" : "Sent",
"ccnotification.notifition.sendmessage.isSendallSuccess" : "Sent(Success)",
"ccnotification.notifition.sendmessage.isSendallFail" : "Sent(Fail)",
"ccnotification.notifition.sendmessage.unSend" : "To Be Sent",
"ccnotification.notifition.sendmessage.referenceTemplate" : "Reference Template",
"ccnotification.notifition.sendmessage.templateselect" : "Select Template",
"ccnotification.notifition.sendmessage.templateselected" : "This notice has referenced the",
"ccnotification.notifition.sendmessage.smsconfignull" : "The SMS message configuration is empty. Choose Settings > SMS Configuration to configure SMS information.",
"ccnotification.notifition.variable.searchVariableName" : "Enter a variable name.",
"ccnotification.notifition.variable.searchTempdateName" : "Enter a template name.",
"ccnotification.notifition.variable.variableType" : "Select a variable type.",
"ccnotification.notification.variable.variablevalue" : "Variable Value",
"ccnotification.notification.variable.templatevariable" : "Template variable",
"ccnotification.notification.variable.publicvariable" : "Public variable",
"ccnotification.notification.variable.template" : "Template",
"ccnotification.notification.variable.templatetype" : "Template Type",
"ccnotification.notification.variable.templateselect" : "Select a template.",
"ccnotification.notifition.variable.templatename" : "Template Name",
"ccnotification.notification.variable.nameexist" : "The variable name exists.",
"ccnotification.notification.variable.editfailed" : "Modification failed.",
"ccnotification.notifition.variable.variablename" : "Variable Name",
"ccnotification.notifition.variable.createvariable" : "Create",
"ccnotification.notifition.variable.selectvariable" : "Select Variable",
"ccnotification.notifition.variable.insertvariable" : "Insert Variable",
"ccnotification.notifition.variable.syntax.error" : "Syntax Error!",
"ccnotification.notifition.variable.not.exist" : "does not exist!",
"ccnotification.notifition.variable.been.used" : "is referenced by a template and cannot be deleted.",
"ccnotification.notifition.variable.variable" : "Variable",
"ccnotification.notifition.variable.close.syntax.error1" : "The template content contains syntax errors near",
"ccnotification.notifition.variable.namerule" : "The variable name can only contain alphabets digits _ and .",
"ccnotification.notifition.variable.close.syntax.error2" : "Check whether ${} is closed.",
"ccnotification.notifition.template.label.templatetype" : "Template Type",
"ccnotification.notifition.template.label.templateid" : "Template ID",
"ccnotification.notifition.template.label.hwtemplateid" : "HUAWEI CLOUD Template ID",
"ccnotification.notifition.template.label.signatureName" : "Signature Name",
"ccnotification.notifition.template.label.varnumber" : "Number of Variables",
"ccnotification.notifition.template.label.mspcontent" : "SMS Template Content",
"ccnotification.notifition.template.title.setting" : "Set Variable",
"ccnotification.notifition.template.button.addvar" : "Create",
"ccnotification.notifition.template.label.index" : "No.",
"ccnotification.notifition.template.label.type" : "Type",
"ccnotification.notifition.template.label.des" : "Description",
"ccnotification.notifition.template.label.value" : "Default Value",
"ccnotification.notifition.template.label.operation" : "Operation",
"ccnotification.notifition.template.button.up" : "Up",
"ccnotification.notifition.template.button.down" : "Down",
"ccnotification.notifition.template.message.money" : "- The value can contain 1 to 20 digits. Only digits and decimal points that can properly express the amount can be transferred.",
"ccnotification.notifition.template.message.dateTime" : "- Date: yyyy-MM-dd - Time: HH:mm:ss ",
"ccnotification.notifition.template.message.text" : "- The value is a string of 1 to 20 characters and cannot contain 000",
"ccnotification.notifition.template.message.charDigit" : "- The value is a string of 1 to 20 digits and letters.",
"ccnotification.notifition.template.message.phone" : "- The value is a string of 1 to 15 digits.",
"ccnotification.notifition.template.message.error" : "Invalid input values.",
"ccnotification.notifition.template.placeholder.hwtemplateid" : "Enter a HUAWEI CLOUD template ID",
"ccnotification.notifition.template.placeholder.signatureName" : "Enter a signature name",
"ccnotification.notifition.template.placeholder.varnumber" : "Enter the number of variables",
"ccnotification.notifition.template.placeholder.des" : "Describe the variable",
"ccnotification.notifition.template.placeholder.var" : "Enter values that meet the requirements.",
"ccnotification.notifition.template.message.hwsms" : "HUAWEI CLOUD SMS Messages",
"ccnotification.notifition.template.message.mspsms" : "SMS Gateway",
"ccnotification.smshuawei.message.fail" : "Failed",
"ccnotification.smshuawei.message.updatesuccess" : "The SMS channel is modified successfully.",
"ccnotification.smshuawei.message.updatefail" : "Failed to modify the SMS channel.",
"ccnotification.smshuawei.message.deletesuccess" : "The SMS channel is deleted successfully.",
"ccnotification.smshuawei.message.deletefail" : "Failed to delete the SMS channel.",
"ccnotification.smshuawei.message.paramisempty" : "Empty parameter.",
"ccnotification.smshuawei.message.specialCharacters" : "Special characters are not allowed.",
"ccnotification.smshuawei.message.invalidappadress" : "Invalid app access address.",
"ccnotification.smshuawei.label.appKey" : "App Key",
"ccnotification.smshuawei.label.certTip.withProxy" : "Please upload the proxy server certificate",
"ccnotification.smshuawei.label.certTip.withoutProxy" : "Upload the HUAWEI CLOUD service certificate",
"ccnotification.smshuawei.label.appSecret" : "App Secret",
"ccnotification.smshuawei.label.appAdress" : "App Access Address",
"ccnotification.smshuawei.label.signatureChannelId" : "Signature Channel ID",
"ccnotification.notifition.template.label.signatureChannelId" : "Enter a Signature Channel ID",
"ccnotification.smshuawei.label.signatureChannelId.length" : "The length of signatureChannelId exceeds the value range.",
"ccnotification.smshuawei.label.config.explain" : "The configuration data must be the same as that of HUAWEI CLOUD SMS.",
"ccnotification.smshuawei.label.certVerifyEnabled" : "Whether to enable certificate verification",
"ccnotification.smshuawei.message.securityInfo" : "If certificate verification is turned off, there is a risk of peer identity spoofing. It is recommended to enable certificate verification.",
"ccnotification.smstemplate.message.var" : "The type, number, description, or default value of variables does not meet requirements.",
"ccnotification.smstemplate.message.fail" : "Failed to create the HUAWEI CLOUD template.",
"ccnotification.smstemplate.message.smstemplate" : "The HUAWEI CLOUD template information is empty.",
"ccnotification.smstemplate.message.smstemplateid" : "The HUAWEI CLOUD template ID cannot be empty, too long, or does not comply with specifications.",
"ccnotification.smstemplate.message.signatureName" : "signature name cannot be empty, cannot be too long, or does not comply with the specifications.",
"ccnotification.smstemplate.message.receiverPhone" : "Recipients cannot be duplicated.",
"ccnotification.smstemplate.message.number" : "The maximum digit is 10.",
"ccnotification.smstemplate.message.var.number" : "The input variable number is different from the number of added variables.",
"ccnotification.common.spec.message" : "Cannot enter special characters.",
"ccnotification.center.message.var.type" : "Variable type:",
"ccnotification.center.message.var.value" : "Variable value:",
"ccnotification.center.message.var.des" : "Variable description:",
"ccnotification.template.message.delete" : "The template has been referenced and cannot be deleted.",
"ccnotification.notifition.gatewaycontact.stop" : "Connection stopped",
"ccnotification.notifition.gatewaycontact.success" : "Connection succeeded",
"ccnotification.notifition.gatewaycontact.failed" : "Connection error",
"ccnotification.gatewayconfig.label.gatewayname" : "Gateway Name",
"ccnotification.gatewayconfig.label.inputgatewayname" : "Enter a gateway name",
"ccnotification.gatewayconfig.label.lastmodifytime" : "Last Modified At",
"ccnotification.gatewayconfig.label.configType" : "Config Type",
"ccnotification.gatewayconfig.label.mailHost" : "Host",
"ccnotification.gatewayconfig.label.protocol" : "Protocol Type",
"ccnotification.gatewayconfig.label.userid" : "User ID",
"ccnotification.gatewayconfig.label.mailPassword" : "User Password",
"ccnotification.gatewayconfig.label.defaultemailaddr" : "Default Email Address",
"ccnotification.gatewayconfig.label.defaultsendname" : "Default User Name",
"ccnotification.gatewayconfig.label.auth" : "Authentication Flag",
"ccnotification.gatewayconfig.label.code" : "Encoding Mode",
"ccnotification.gatewayconfig.label.SSLEncryptionMode" : "Encryption Mode",
"ccnotification.gatewayconfig.label.mailPort" : "Port",
"ccnotification.gatewayconfig.label.certFile" : "Certificate File",
"ccnotification.gatewayconfig.label.selectCert" : "Select Certificate",
"ccnotification.gatewayconfig.label.inputCertCode" : "Enter the certificate code.",
"ccnotification.gatewayconfig.label.certCode" : "Certificate Code",
"ccnotification.gatewayconfig.label.certScene" : "Application Scenario",
"ccnotification.gatewayconfig.label.certType" : "Certificate Type",
"ccnotification.common.warning.message" : "Select a record.",
"ccnotification.gatewayconfig.label.serviceType" : "Service Type",
"ccnotification.gatewayconfig.label.sourceAddrTon" : "Source Address Code Type",
"ccnotification.gatewayconfig.label.sourceAddrNpi" : "Source Address Code Scheme",
"ccnotification.gatewayconfig.label.destAddrTon" : "Destination Address Code Type",
"ccnotification.gatewayconfig.label.esmClass" : "Message Type",
"ccnotification.gatewayconfig.label.destAddrNpi" : "Destination Address Code Scheme",
"ccnotification.gatewayconfig.label.dataCoding" : "Message Format",
"ccnotification.gatewayconfig.label.protocolId" : "Protocol ID",
"ccnotification.gatewayconfig.label.registeredDelivery" : "Return Status Confirmation Report",
"ccnotification.gatewayconfig.label.replaceIfPresentFlag" : "Replacement Flag",
"ccnotification.gatewayconfig.label.defaultsendno" : "Default Sending Number",
"ccnotification.gatewayconfig.label.smDefaultMsgId" : "Default Message ID",
"ccnotification.gatewayconfig.label.RcvAndSendMode" : "TX/RX mode",
"ccnotification.gatewayconfig.label.defaultreceinfo" : "Default Receiving ID",
"ccnotification.gatewayconfig.label.smppPduType" : "SMPP PDU Type",
"ccnotification.gatewayconfig.label.recFlowController" : "Uplink Traffic Controller Name",
"ccnotification.gatewayconfig.label.smHost" : "SMG Address",
"ccnotification.gatewayconfig.label.smPort" : "SMG Port",
"ccnotification.gatewayconfig.label.systemId" : "Login Name",
"ccnotification.gatewayconfig.label.smPassword" : "Login Password",
"ccnotification.gatewayconfig.label.interfaceVersion" : "Login Message Version Number",
"ccnotification.gatewayconfig.label.systemType" : "ESME Type",
"ccnotification.gatewayconfig.label.localPort" : "Client Port",
"ccnotification.gatewayconfig.label.heartbeatInterval" : "Heartbeat Interval(ms)",
"ccnotification.gatewayconfig.label.resendNumber" : "Resending Times",
"ccnotification.gatewayconfig.label.resendWaitTime" : "Resending Waiting Time(ms)",
"ccnotification.gatewayconfig.label.heartbeatNoRespCount" : "Max Connection Times Upon No Heartbeat Response",
"ccnotification.gatewayconfig.label.readTimeout" : "Receiving Timeout Interval(ms)",
"ccnotification.gatewayconfig.label.maxRcvError" : "Max Error Packets Received",
"ccnotification.gatewayconfig.label.addrTon" : "ESME Address Type Code",
"ccnotification.gatewayconfig.label.addrNpi" : "ESME Address Encoding Mode",
"ccnotification.gatewayconfig.label.slipWindowSize" : "Sliding Window Size",
"ccnotification.gatewayconfig.label.addressRange" : "ESME Address Range",
"ccnotification.gatewayconfig.label.transactionTimeout" : "Transaction Timeout Interval(ms)",
"ccnotification.gatewayconfig.label.maxMessageLength" : "Max Message Length (bytes)",
"ccnotification.gatewayconfig.label.deviceTypeDescript" : "Device Type Description",
"ccnotification.gatewayconfig.label.serverSocketPort" : "Port for receiving upstream messages",
"ccnotification.gatewayconfig.label.splitType" : "Oversized SMS Splitting Mode",
"ccnotification.gatewayconfig.label.maxContentLength" : "Max Content Length(bytes)",
"ccnotification.gatewayconfig.label.bindMode" : "Receiving And Sending Mode",
"ccnotification.gatewayconfig.label.deviceDescript" : "Device Description",
"ccnotification.gatewayconfig.label.senderPoolMinCount" : "Min Sending Thread Pools",
"ccnotification.gatewayconfig.label.senderPoolMaxCount" : "Max Sending Thread Pools",
"ccnotification.gatewayconfig.label.receiverPoolMinCount" : "Min Receiving Thread Pools",
"ccnotification.gatewayconfig.label.senderPoolQueueCapacity" : "Sending Thread Pool Queue Length",
"ccnotification.gatewayconfig.label.receiverPoolQueueCapacity" : "Receiving Thread Pool Queue Length",
"ccnotification.gatewayconfig.label.receiverPoolMaxCount" : "Max Receiving Thread Pools",
"ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecv" : "Max Receiving Rate",
"ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSend" : "Max Sending Rate",
"ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicy" : "Extra Traffic Processing Policy",
"ccnotification.gatewayconfig.label.splitMsgConcatMode" : "Long Message Concatenation Mode",
"ccnotification.gatewayconfig.label.addgatewayconfig" : "Add Gateway Configuration",
"ccnotification.gatewayconfig.label.gatewayipaddrconfig" : "Gateway Address Interconnection Configuration",
"ccnotification.gatewayconfig.label.messageprotocol" : "Message protocol parameters",
"ccnotification.gatewayconfig.label.modifygateway" : "Modify Gateway",
"ccnotification.gatewayconfig.label.addgateway" : "Create Gateway",
"ccnotification.gatewayconfig.label.viewgateway" : "View Gateway",
"ccnotification.gatewayconfig.label.mailHosttitle" : "Host address\,for example\,127.0.0.1 or www.mail.com",
"ccnotification.gatewayconfig.label.authtitle" : "Authentication Flag\,The options are true and false",
"ccnotification.gatewayconfig.label.mailPorttitle" : "The port number ranges from 0 to 65535. The default SMTP port number is 25.",
"ccnotification.gatewayconfig.label.sourceAddrTontitle" : "The Source Address Code Type is a number",
"ccnotification.gatewayconfig.label.SSLEncryptionModetitle" : "Encryption mode\, NONE: plaintext transmission (default port: 25)TLS: encrypted transmission using TLS (default port: 465) STARTTLS: encrypted transmission using the STARTTLS command (default port: 587)",
"ccnotification.gatewayconfig.label.sourceAddrNpititle" : "The Source Address Code Scheme is a number",
"ccnotification.gatewayconfig.label.destAddrTontitle" : "The Destination Address Code Type is a number",
"ccnotification.gatewayconfig.label.destAddrNpititle" : "The Destination Address Code Scheme is a number",
"ccnotification.gatewayconfig.label.dataCodingtitle" : "0: ASCII string 4: binary information 8: UCS2 encoding",
"ccnotification.gatewayconfig.label.registeredDeliverytitle" : "The Return Status Confirmation Report is a number",
"ccnotification.gatewayconfig.label.replaceIfPresentFlagtitle" : "0: Replace\, 1: Not replace\, 2-255: Reserved",
"ccnotification.gatewayconfig.label.defaultsendnotitle" : "Prefix rule of the sender number. For example, if 130 is configured, numbers starting with 130 are matched. Multiple numbers are separated by semicolons (;).",
"ccnotification.gatewayconfig.label.smppPduTypetitle" : "1:submit_sm  2:data_sm",
"ccnotification.gatewayconfig.label.defaultreceinfotitle" : "Accepted number prefix rule: For example, if 130 is configured, numbers starting with 130 are matched. Multiple numbers are separated by semicolons (;).",
"ccnotification.gatewayconfig.label.recFlowControllertitle" : "Name of the traffic controller that controls the uplink message rate.",
"ccnotification.gatewayconfig.label.smHosttitle" : "SMG address\, for example\, 127.0.0.1",
"ccnotification.gatewayconfig.label.smPorttitle" : "SMG port number range: an integer ranging from 0 to 65535",
"ccnotification.gatewayconfig.label.interfaceVersiontitle" : "The login message version number must be a number.",
"ccnotification.gatewayconfig.label.heartbeatIntervaltitle" : "The Heartbeat Interval is a proper number",
"ccnotification.gatewayconfig.label.heartbeatNoRespCounttitle" : "The Max Connection Times Upon No Heartbeat Response is a proper number",
"ccnotification.gatewayconfig.label.resendNumbertitle" : "The Resending Times is a proper number",
"ccnotification.gatewayconfig.label.resendWaitTimetitle" : "The Resending Waiting Time is a proper number",
"ccnotification.gatewayconfig.label.readTimeouttitle" : "The Receiving Timeout Interval is a proper number\,0: never time out",
"ccnotification.gatewayconfig.label.maxRcvErrortitle" : "The Max Error Packets Received is a proper number",
"ccnotification.gatewayconfig.label.slipWindowSizetitle" : "The Sliding Window Size is a proper number",
"ccnotification.gatewayconfig.label.transactionTimeouttitle" : "The Transaction Timeout Interval is a proper number",
"ccnotification.gatewayconfig.label.maxMessageLengthtitle" : "The maximum message length (byte) is a number\, which is specified in the protocol\, for example\, 2048.",
"ccnotification.gatewayconfig.label.splitTypetitle" : "Splitting mode of an overlong SMS message. The options are as follows: 0: not split; 1: not split; 2: split physically; 3: split overlong SMS message.",
"ccnotification.gatewayconfig.label.maxContentLengthtitle" : "The maximum content length (bytes) is a number\, which is used as the matching splitting basis\, for example\, 140.",
"ccnotification.gatewayconfig.label.bindModetitle" : "Indicates the TX/RX mode. 1: RX mode; 2: TX mode; 9: TX/RX mode.",
"ccnotification.gatewayconfig.label.senderPoolMinCounttitle" : "The Min Sending Thread Pools is a number\, for example\, 20.",
"ccnotification.gatewayconfig.label.senderPoolMaxCounttitle" : "The Max Sending Thread Pools is a number\, for example\, 200.",
"ccnotification.gatewayconfig.label.senderPoolQueueCapacitytitle" : "The Sending Thread Pool Queue Length is a number\, for example\, 2000.",
"ccnotification.gatewayconfig.label.receiverPoolMinCounttitle" : "The Min Receiving Thread Pools is a number\, for example\, 20.",
"ccnotification.gatewayconfig.label.receiverPoolMaxCounttitle" : "The Max Receiving Thread Pools is a number\, for example\, 200.",
"ccnotification.gatewayconfig.label.receiverPoolQueueCapacitytitle" : "The Receiving Thread Pool Queue Length is a number\, for example\, 20.",
"ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecvtitle" : "The Max Receiving Rate is a number",
"ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSendtitle" : "The Max Sending Rate is a number",
"ccnotification.gatewayconfig.label.splitMsgConcatModetitle" : "Long SMS message combination mode. 0: GSM user data header; 1: SMPP parameter.",
"ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicytitle" : "Currently\, only Ignore is supported.",
"ccnotification.gatewayconfig.label.addgatewaysuccess" : "Gatewayconfig add successfully!",
"ccnotification.gatewayconfig.label.addgatewayfailed" : "Gatewayconfig add failed!",
"ccnotification.gatewayconfig.label.modifygatewayfailed" : "Gatewayconfig modify failed!",
"ccnotification.gatewayconfig.label.modifygatewaysuccess" : "Gatewayconfig modify successfully!",
"ccnotification.gatewayconfig.label.deletegatewaysuccess" : "Gatewayconfig delete successfully!",
"ccnotification.gatewayconfig.label.deletegatewayfailed" : "Gatewayconfig delete failed!",
"ccnotification.gatewayconfig.label.configNameAlreadyExists" : "The configuration name already exists",
"ccnotification.gatewayconfig.label.configNameIsError" : "The configuration name must contain a maximum of 10 digits or letters",
"ccnotification.gatewayconfig.label.requestParamIsNull" : "The request parameter is empty",
"ccnotification.gatewayconfig.label.mailProtocolIsError" : "The email protocol type must be SMTP\, POP3.",
"ccnotification.gatewayconfig.label.configTypeIsError" : "The configuration type is incorrect. The internal configuration type of the parameter is inconsistent",
"ccnotification.gatewayconfig.label.paramIsNotNumber" : "The parameter value can contain only digits",
"ccnotification.gatewayconfig.label.authIsError" : "The authentication ID can only be true or false",
"ccnotification.gatewayconfig.label.paramIsNotInRange" : "The parameter value range is incorrect",
"ccnotification.gatewayconfig.label.sslencryptionmodeIsError" : "The email encryption mode can only be NONE\, STARTTLS\, or TLS",
"ccnotification.gatewayconfig.label.hostIsError" : "The email host can only be 127.0.0.1 or www.mail.com formatting",
"ccnotification.gatewayconfig.label.defaultsendnoIsError" : "The format of the default email address is incorrect",
"ccnotification.gatewayconfig.label.dataCodingIsError" : "The SMS message format must be 0:ASCII string\,4:binary information\,8: UCS2 code",
"ccnotification.gatewayconfig.label.smppPduTypeIsError" : "The SMS SMPP PDU type can only be 1:submit_sm\,2:data_sm",
"ccnotification.gatewayconfig.label.paramContainsSpecialChar" : "The parameter contains special characters",
"ccnotification.gatewayconfig.label.splitTypeIsError" : "The long SMS message splitting mode can only be set to 0:disabled\,1:not split\,2:physical splitting\,3:ultra-long split",
"ccnotification.gatewayconfig.label.bindModeIsError" : "SMS sending and receiving mode 1:receiving mode\,2:transmit mode\,9:TX/RX mode",
"ccnotification.gatewayconfig.label.splitMsgConcatModeIsError" : "Long short message combination mode 0:GSM user data header\,1:SMPP parameter",
"ccnotification.gatewayconfig.label.paramMaxLengthIsError" : "The parameter value cannot exceed 500 characters",
"ccnotification.gatewayconfig.label.paramIsNegative" : "The number cannot be negative",
"ccnotification.gatewayconfig.label.maxsmGatewayIsError" : "A maximum of 10 SMS gateways can be configured in the same tenant space",
"ccnotification.gatewayconfig.label.maxEmailGatewayIsError" : "A maximum of five email receiving or sending gateways can be configured for the same tenant space",
"ccnotification.gatewayconfig.label.willDeletegateway" : "Are you sure you want to delete the selected Gateway Config?",
"ccnotification.gatewayconfig.label.selectdeletegatewayconfig" : "Please select the gateway configuration to be deleted!",
"ccnotification.gatewayconfig.label.checkConfigName" : "The value is a string of less than 10 digits and letters",
"ccnotification.gatewayconfig.label.specialStrValidate" : "Only uppercase letters, lowercase letters, and numbers are allowed.",
"ccnotification.gatewayconfig.label.positive" : "The number cannot be negative.",
"ccnotification.gatewayconfig.label.errorstylenumber" : "Incorrect number format.",
"ccnotification.sms.huawei" : "Huawei SMS",
"ccnotification.sms.gateway" : "Gateway SMS",
"ccnotification.notifition.recmessagerouterconfig.index" : "Index",
"ccnotification.notification.recmessagerouterconfig.inbox" : "In-Box",
"ccnotification.notification.recmessagerouterconfig.satisfaction" : "Satisfaction Survey",
"ccnotification.notification.recmessagerouterconfig.caseserver" : "Service Invoking",
"ccnotification.notification.recmessagerouterconfig.intelligentOrder" : "Intelligent Case Creation",
"ccnotification.notification.recmessagerouterconfig.emailOnlineChat" : "Online Chat Through the Email Channel",
"ccnotification.notification.recmessagerouterconfig.mediaType" : "Media Type",
"ccnotification.notification.recmessagerouterconfig.serviceType" : "Implementation Method",
"ccnotification.notification.recmessagerouterconfig.serviceName" : "Service Name",
"ccnotification.notification.recmessagerouterconfig.retryTimes" : "Retry Times",
"ccnotification.notification.recmessagerouterconfig.receiver" : "Recipient",
"ccnotification.notification.recmessagerouterconfig.receiveAddress" : "Recipient Address",
"ccnotification.notification.recmessagerouterconfig.placeholder.receiver" : "Please input recipient address",
"ccnotification.notification.recmessagerouterconfig.deletenum.outstripping" : "A maximum of 100 records can be deleted at a time.",
"ccnotification.notification.recmessagerouterconfig.willdeleteselectconfig" : "Are you sure you want to delete the selected configuration?",
"ccnotification.notification.recmessagerouterconfig.selectconfig" : "Please select a config.",
"ccnotification.notification.recmessagerouterconfig.selectitem" : "Please select a record.",
"ccnotification.notification.recmessagerouterconfig.selectgateway" : "Select Gateway",
"ccnotification.notification.recmessagerouterconfig.gateway" : "Gateway",
"ccnotification.notification.recmessagerouterconfig.mobilerule" : "Please enter mobile numbers. Separate multiple mobile numbers with semicolons",
"ccnotification.notification.recmessagerouterconfig.defaultreceiveemail" : "Default Receive Email",
"ccnotification.notification.recmessagerouterconfig.defaultsendemail" : "Default Send Email",
"ccnotification.notification.recmessagerouterconfig.gatewaynotexist" : "The selected gateway does not exist.",
"ccnotification.notification.recmessagerouterconfig.receiveaddressexist" : "The receive address already exists.",
"ccnotification.notification.recmessagerouterconfig.receivernotexist" : "The selected recipient does not exist.",
"ccnotification.notification.label.upload" : "Upload",
"ccnotification.notification.label.attachment" : "Attachment",
"ccnotification.notification.label.downloadAttachment" : "Download attachment",
"ccnotification.notification.label.hasUplaodtAttachment" : "Uploaded Attachments",
"ccnotification.notification.label.selectAttachment" : "Please select an email attachment.",
"ccnotification.notification.label.uploadAttachmentSuccess" : "Upload attachment success",
"ccnotification.notification.label.uploadAttachmentFailed" : "Upload attachment failed",
"ccnotification.notification.fileUploadRiskWarning" : "The file you uploaded has a security risk\, please confirm whether to continue uploading?",
"ccnotification.notification.label.fileUploadFileNameError" : "The file name you uploaded does not meet the requirements",
"ccnotification.notification.label.fileUploadFileTypeError" : "The file type you uploaded does not meet the requirements",
"ccnotification.notification.label.fileUploadFileSizeError" : "The file size you uploaded exceeds the limit",
"ccnotification.notification.label.fileUploadFileIOError" : "There was a parsing error with the file you uploaded",
"ccnotification.notification.label.deleteAttachmentSuccess" : "Delete attachment success",
"ccnotification.notification.label.deleteAttachmentFailed" : "Delete attachment failed",
"ccnotification.notification.label.isUploadAttachment" : "Upload Attachment",
"ccnotification.notification.label.isDownloadAttachment": "If the attachment you are downloading is in Office document format, please take necessary security precautions.",
"ccnotification.notification.label.isDeleteAttachment" : "Delete Attachment",
"ccnotification.notification.label.attachmentIsEmpty" : "The attachment is empty.",
"ccnotification.notification.label.attachmentExceed10" : "A maximum of 10 attachments can be uploaded.",
"ccnotification.notification.label.queryAttachmentFailed" : "Failed to query the attachment",
"ccnotification.notification.label.createTemFileFailed" : "Failed to create the temporary file",
"ccnotification.notification.label.serverIsNotobs" : "Attachments can only be downloaded from the OBS file server",
"ccnotification.notification.label.downloadFromObsFailed" : "Failed to download files from OBS   ",
"ccnotification.notification.label.compressZipFileFailed" : "Failed to compress the .zip file",
"ccnotification.notification.message.noAttachement" : "You have not uploaded any attachment.",
"ccnotification.notification.label.incorrectAttachmentType" : "Incorrect email attachment type",
"ccnotification.notification.label.attachmentSizeError" : "The email attachment size exceeds 10 MB",
"ccnotification.notification.label.nameContainSpecialChar" : "The email attachment name contains special characters",
"ccnotification.center.message.setIsRead" : "Mark Messages as Read ",
"ccnotification.notification.label.uploadPermissionFailed" : "Check upload email attachment permission failed",
"ccnotification.notification.label.downPermissionFailed" : "Check download email attachment permission failed",
"ccnotification.notification.label.checkAttachNameLegnth" : "The email attachment name cannot exceed 100 characters ",
"ccnotification.notification.systemmonitor.gatewayName" : "GatewayName",
"ccnotification.notification.systemmonitor.mspId" : "MspId",
"ccnotification.notification.systemmonitor.modifyTime" : "Modified At",
"ccnotification.notification.systemmonitor.status" : "Status",
"ccnotification.notification.systemmonitor.rebuilding" : "Rebuilding",
"ccnotification.notification.systemmonitor.disconnected" : "Disconnected",
"ccnotification.notification.label.rebuildSuccess" : "Rebuilding Success",
"ccnotification.notification.label.rebuildContainsErrorId" : "Rebuild failed\, please click the query button to update the list and try again\.",
"ccnotification.notification.label.rebuildFailed" : "Rebuilding  Failed",
"ccnotification.notification.label.discounnectedSuccess" : "Disconnected Success",
"ccnotification.gatewayconfig.label.selectrebuildgatewayconfig" : "Select the gateway configuration to be rebuilt",
"ccnotification.notification.label.discounnectedFailed" : "Disconnected Failed",
"ccnotification.gatewayconfig.label.selectredisconnectedgatewayconfig" : "Select the gateway configuration to be disconnected",
"ccnotification.gatewayconfig.label.statusisnoterror" : "the status is not ERROR.",
"ccnotification.gatewayconfig.label.selectredgatewayconfig" : "Selected gateway monitoring:",
"ccnotification.gatewayconfig.label.INIT" : "Initialization",
"ccnotification.caseserver.fail.titleAndContentNull" : "Empty title and content",
"ccnotification.caseserver.fail.duplicateContent" : "Duplicate content",
"ccnotification.caseserver.fail.categoryNotExist" : "Non-existent case category",
"ccnotification.flowcontrol.getwayset" : "Set",
"ccnotification.flowcontrol.setFlowType" : "Data Limit Type",
"ccnotification.flowcontrol.setThreshold" : "Set The Data Threshold",
"ccnotification.flowcontrol.eachThreshold" : "Daily Monomedia Data Threshold",
"ccnotification.flowcontrol.title" : "Set Notification Configuration",
"ccnotification.flowcontrol.messageThreshold" : "Daily Data Threshold For SMS Messages",
"ccnotification.flowcontrol.emailThreshold" : "Daily Data Threshold For Emails",
"ccnotification.flowcontrol.totalThreshold" : "Daily Total Data Threshold",
"ccnotification.flowcontrol.modifySuccess" : "The notification configuration is modified successfully.",
"ccnotification.flowcontrol.modifyFailed" : "Failed to modify the notification configuration. Check the input parameters or network.",
"ccnotification.flowcontrol.getFailed" : "Failed to obtain the notification configuration. Check the network.",
"ccnotification.flowcontrol.countryCode" : "Country Code",
"ccnotification.notification.label.fileInjectionRiskException" : "Email attachment injection risk exception",
"ccnotification.flowcontrol.shortMsgCode" : "SMS Message Number",
"ccnotification.notifition.sendmessage.entercompanyName" : "Enter a company name.",
"ccnotification.flowcontrol.setShortMsgCode" : "Set the number for SMS messages.",
"ccnotification.common.title.warning" : "Warning",
"ccnotification.title.flowcontrol.receiveSmsFlag" : "Receive MO SMS Messages",
"ccnotification.common.button.ok" : "OK",
"ccnotification.common.title.fail" : "Failed",
"ccnotification.common.title.success" : "Successful",
"ccnotification.common.title.tips" : "Tips",
"ccnotification.agent.contact.downloadFail" : "Download failed",
"ccnotification.common.title.errorsave" : "Error",
"ccnotification.common.title.confirm" : "Confirm",
"ccnotification.common.button.delete" : "Delete",
"ccnotification.common.title.delete" : "Delete",
"ccnotification.agent.operation.searchworkno" : "Please enter the Employee ID",
"ccnotification.common.title.confreset" : "Confirm Reset",
"ccnotification.agent.pageurls.isconfreset" : "Are you sure you want to reset it?",
"ccnotification.agent.message.updateCtiAndDbFail" : "Update cti and Db failed.",
"ccnotification.common.label.name" : "Name",
"ccnotification.agent.label.account" : "Account",
"ccnotification.common.button.create" : "New",
"ccnotification.common.button.new" : "New",
"ccnotification.agent.contact.workno" : "Employee ID",
"ccnotification.urlconfig.pageurls.urlvalidate" : "The urls must start with https://",
"ccnotification.common.message.maxLength" : "The text content can contain a maximum of 4096 characters.",
"ccnotification.common.operation.noMoreThan7Days" : "The time range cannot exceed 7 days.",
"ccnotification.common.message.emptyContent" : "The content cannot be empty.",
"ccnotification.chatserver.fail.assign" : "Failed agent allocation",
"ccnotification.chatserver.fail.barred" : "Blocked agent allocation",
"ccnotification.common.message.maxExpireTime" : "Select an expiration time no later than {0}.",
"ccnotification.bulletinType.title.bulletinTypeName" : "Bulletin Type Name",
"ccnotification.bulletinType.title.bulletintypeInvalidSect" : "Default Expiration Time",
"ccnotification.bulletinType.title.createBulletinType" : "Create Bulletin Type",
"ccnotification.bulletinType.title.editBulletinType" : "Modify Bulletin Type",
"ccnotification.bulletinType.message.searchPlaceHolder" : "Bulletin Type Name",
"ccnotification.bulletinType.title.bulletinTypeDesc" : "Description",
"ccnotification.bulletinType.message.createBulletinTypeSuccessful" : "The bulletin type is created successfully.",
"ccnotification.bulletinType.message.deleteBulletinTypeSuccessful" : "The bulletin type is deleted successfully.",
"ccnotification.bulletinType.message.editBulletinTypeSuccessful" : "The bulletin type is modified successfully.",
"ccnotification.bulletinType.message.betchDeleteBulletinTypeSuccessful" : "The bulletin types are deleted in batches successfully.",
"ccnotification.bulletinType.message.createBulletinTypeFailed" : "Failed to create the bulletin type.",
"ccnotification.common.button.finish" : "Complete",
"ccnotification.bulletinType.message.betchDeleteBulletinTypeFailed" : "Failed to delete the bulletin types in batches.",
"ccnotification.bulletinType.message.deleteBulletinTypeFailed" : "Failed to delete the bulletin type.",
"ccnotification.bulletinType.message.invalidSectCheck" : "The default expiration time must range from 1 to 2000 days.",
"ccnotification.bulletinType.message.nameContainSpecialChar" : "The name cannot contain special characters.",
"ccnotification.bulletinType.message.decimealsCheck" : "The default expiration time cannot contain a decimal.",
"ccnotification.bulletinType.message.willDelete" : "Are you sure you want to delete the selected bulletin type?",
"ccnotification.bulletinType.message.selectItem" : "Select a bulletin type.",
"ccnotification.bulletinType.message.nameIsNotUnique" : "The bulletin type name already exists.",
"ccnotification.bulletinType.message.releaseTime" : "release time",
"ccnotification.bulletinType.message.periodicallySend" : "periodically send",
"ccnotification.bulletinType.message.sendingFrequency" : "sending frequency",
"ccnotification.bulletinType.message.weekly" : "weekly",
"ccnotification.bulletinType.message.everyDay" : "every day",
"ccnotification.bulletinType.message.perMonth" : "per month",
"ccnotification.bulletinType.message.Monday" : "Monday",
"ccnotification.bulletinType.message.Tuesday" : "Tuesday",
"ccnotification.bulletinType.message.Thursday" : "Thursday",
"ccnotification.bulletinType.message.Wednesday" : "Wednesday",
"ccnotification.bulletinType.message.Friday" : "Friday",
"ccnotification.bulletinType.message.Saturday" : "Saturday",
"ccnotification.bulletinType.message.Sunday" : "Sunday",
"ccnotification.bulletinType.message.daysOfMonth" : "Day 0",
"ccnotification.bulletinType.message.sendTimeParamsError1" : "Publish time cannot be earlier than current time",
"ccnotification.bulletinType.message.sendTimeParamsError2" : "Release time cannot be later than expiration time",
"ccnotification.bulletinType.title.Urgent" : "Medium",
"ccnotification.bulletinType.title.General" : "Low",
"ccnotification.bulletinType.title.Rushse" : "High",
"ccnotification.bulletinType.title.Faults" : "Critical",
"ccnotification.notifition.template.message.phoneLabel" : "Phone number",
"ccnotification.notifition.template.message.dateTimeLabel" : "Date and time",
"ccnotification.notifition.template.message.charDigitLabel" : "Digit and letter",
"ccnotification.notifition.template.message.moneyLabel" : "Amount",
"ccnotification.notifition.template.message.textLabel" : "Text",
"ccnotification.common.message.sendIntervalShort" : "Frequent sending. Sending interval (s): {n}.",
"ccnotification.notifition.sendmessage.selectOutbox" : "Select the outbox.",
"ccnotification.notifition.gatewaycontact.connecting" : "Connecting",
"ccnotification.gatewayconfig.message.authInfo" : "Setting false has security risks. You are advised to set true to enable password authentication.",
"ccnotification.caseserver.fail.serviceException" : "Abnormal Service",
"ccn.smshuawei.message.table.label" : "HUAWEI CLOUD SMS",
"ccn.smsnoconfig.table.header.countrycode" : "Sender Country Code",
"ccn.smsnoconfig.pop.title.createtitle" : "Add SMS Number",
"ccn.smsnoconfig.pop.title.edittitle" : "Edit SMS Number",
"ccnotification.bulletinType.message.editBulletinTypeFailed" : "Failed to modify the bulletin type.",
"ccnotification.notifition.sendmessage.entersender" : "Select a sender number.",
"ccnotification.notifition.sendmessage.sender" : "Sender Number",
"ccn.smsnoconfig.table.header.countrycode.desc" : "Country",
"ccnotification.flowcontrol.recipient.countrycode" : "Recipient Country Code",
"ccn.smsnoconfig.pop.check.duplicatecode" : "The number already exists.",
"ccn.smsnoconfig.pop.check.selectwarning" : "Select the record you want to delete.",
"ccn.smsnoconfig.pop.check.deletewarning" : "Are you sure you want to delete the selected data?",
"ccn.smsnoconfig.pop.check.deletesuccess" : "Deletion successful.",
"ccn.smsnoconfig.pop.check.deletefailed" : "Deletion failed.",
"ccnotification.gatewayconfig.message.securityInfo" : "NONE indicates that data is transmitted in plaintext. Therefore data may leak. STARTTLS is an insecure protocol. Using insecure protocols poses security risks. TLS protocol is recommended.",
"ccnotification.notification.emailsignature.placeholder.name" : "Please enter a signature name",
"ccnotification.notification.emailsignature.name" : "Signature Name",
"ccnotification.notification.emailsignature.description" : "Signature Description",
"ccnotification.notification.emailsignature.content" : "Signature Content",
"ccnotification.notification.emailsignature.nameexist" : "The signature name already exists.",
"ccnotification.notification.emailsignature.noitemselect" : "Does not select any data.",
"ccnotification.notification.emailsignature.willdeleteselectitem" : "Are you sure you want to delete the selected data?",
"ccnotification.notification.emailsignature.reachmaxcount" : "The number of email signatures exceeds the maximum.",
"ccnotification.notification.emailsignature.selection.username" : "User Name",
"ccnotification.notification.emailsignature.content.length.exceed" : "The length of the signature content cannot exceed 4096.",
"ccnotification.notification.label.uploadAttachmentTips" : "Email attachments can be in the following format: doc, docx, xls, xlsx, txt, png, jpg, xml, json, csv, bmp, zip, ppt, pptx, pdf, The file size cannot exceed 10 MB.The files in the compressed package must also comply with the preceding format.",
"ccnotification.notification.special.charactor.check" : "The entered content cannot contain < = > / \\ \" eval and exec.",
"ccnotification.notification.recmessagerouterconfig.updatenum.outstripping" : "A maximum of 100 records can be updated at a time.",
"ccnotification.notification.uploadfail.duplicateFileName" : "Duplicate file name.",
"ccn.smsnoconfig.table.label" : "SMS Number",
"ccnotification.sms.rcvAndSendMode.receiveSms" : "Receive SMS messages",
"ccnotification.sms.rcvAndSendMode.sendSms" : "Send SMS Messages",
"ccn.smsnoconfig.pop.search.reminder" : "Enter a number.",
"ccnotification.sms.rcvAndSendMode.receiveAndSendSms" : "Send and receive SMS messages",
"ccnotification.sms.smppPduTye.data.sm" : "data_sm",
"ccnotification.sms.gms.user.data.head" : "GSM User Data Header",
"ccnotification.sms.smppPduTye.submit.sm" : "submit_sm",
"ccnotification.sms.smpp.parameters" : "SMPP parameters",
"ccnotification.sms.smpp.replaceIfPresentFlag.replace" : "Replace",
"ccnotification.sms.smpp.replaceIfPresentFlag.not.replace" : "Not replace",
"ccnotification.sms.smpp.stow.away" : "Stow away",
"ccnotification.sms.smpp.ESME.Connection.Configuration" : "ESME Connection Configuration",
"ccnotification.gatewayconfig.label.splitType.ultraLong" : "ultra-long split",
"ccnotification.gatewayconfig.label.splitType.noSplit" : "not split",
"ccnotification.gatewayconfig.label.splitType.disabled" : "disabled",
"ccnotification.gatewayconfig.label.dataCoding.ASCII" : "ASCII string",
"ccnotification.gatewayconfig.label.dataCoding.Binary" : "binary information",
"ccnotification.gatewayconfig.label.splitType.physical" : "physical splitting",
"ccnotification.gatewayconfig.label.basic.configuration" : "Basic Configuration",
"ccnotification.notification.recmessagerouterconfig.defaultsendnumber" : "Default sending number.",
"ccnotification.notification.recmessagerouterconfig.defaultreceivenumber" : "Default receiving number.",
"ccnotification.gatewayconfig.label.advancedconfig" : "Advanced Settings",
"ccnotification.gatewayconfig.label.dataCoding.UCS2" : "UCS2 encoding",
"ccnotification.template.message.organizationStructure" : "Organization structure",
  "ccnotification.template.message.alignment" : "The number of card options cannot exceed",
  "ccnotification.email.signature.message.uploadImgTypeError" : "Only JPG JPEG and PNG images can be uploaded.",
  "ccnotification.email.signature.knowledge.message.uploadImgSizeError" : "The size of the image to be uploaded cannot exceed {}.",
  "ccnotification.email.signature.knowledge.message.uploadError" : "The email signature contains images of an improper size or format. Delete them and try again.",
  "ccn.flow.grayrule.editsuccess" : "Modified successfully.",
  "ccn.voiceedit.addsuccess" : "Adding successfully.",
  "ccn.voiceedit.addfail" : "Adding failed.",
  "ccnotification.notification.emailsignature.selection.userEmail" : "User email address",
  "ccnotification.notification.emailsignature.selection.userPosition" : "User Position",
  "ccn.voiceedit.deletesuccess" : "Deleted successfully.",
  "ccnotification.notifition.template.create" : "Creating a Notification Template",
  "ccnotification.template.message.channelType" : "Channel Type",
  "pop.org.window.org" : "Home Organization",
  "ccnotification.template.message.add.variable": "Additions",
  "ccnotification.template.message.content": "Template Content",
  "ccnotification.template.message.enterDescription": "Please enter a description.",
  "ccnotification.notification.systemmonitor.batchrebuild":"Batch Rebuild",
  "ccnotification.notification.systemmonitor.batchdisconnect":"Batch Disconnect",
  "ccnotification.gatewayconfig.label.send.prefix":"Prefix of the sender number",
  "ccnotification.gatewayconfig.label.receive.prefix":"Accepted Number Prefix",
  "ccnotification.signature.add":"Create Email Signature",
  "ccnotification.signature.update":"Modify Email Signature",
  "ccnotification.signature.view":"View Email Signature",
  "ccnotification.gatewayconfig.label.select.sendNumber.title":"Selecting the sender number",
  "ccnotification.gatewayconfig.label.add.sendNumber.title":"Adding a sender number",
  "ccn.whitelist.urlvalidatefailed": "Please enter IP or domain name",
  "ccnotification.notification.notificationRoute.recipientAddress" : "Recipient Address",
  "ccnotification.notification.notificationRoute.implementationMode" : "Implementation mode",
  "ccnotification.notification.notificationRoute.edit" : "Edit",
  "ccnotification.notification.notificationRoute.newNotificationRoute" : "New Notification Route",
  "ccnotification.notification.notificationRoute.editNotificationRoute" : "Edit Notification Route",
  "ccnotification.notification.notificationRoute.inbox" : "Inbox",
  "ccnotification.notification.notificationRoute.serviceInvoking" : "Service Invoking",
  "ccnotification.notification.notificationRoute.selectconfig" : "Please select a config.",
  "ccnotification.notification.notificationRoute.willdeleteselectconfig" : "Are you sure you want to delete the selected configuration?",
  "ccnotification.notification.notificationRoute.deletenum.outstripping" : "A maximum of 100 records can be deleted at a time.",
  "ccnotification.notification.notificationRoute.delSuccess" : "Deleted successfully.",
  "ccnotification.notification.notificationRoute.delFail" : "Deletion failed.",
  "ccnotification.notification.notificationRoute.gateway" : "Gateway",
  "ccnotification.notification.notificationRoute.gatewaynotexist" : "The selected gateway does not exist.",
  "ccnotification.notification.notificationRoute.receivernotexist" : "The selected recipient does not exist.",
  "ccnotification.notification.notificationRoute.receiveaddressexist" : "The receive address already exists.",
  "ccnotification.notification.notificationRoute.intelligentOrder" : "Intelligent Case Creation",
  "ccnotification.notification.notificationRoute.mobilerule" : "Please enter mobile numbers. Separate multiple mobile numbers with semicolons",
  "ccnotification.notification.notificationRoute.inputPhoneNum" : "Please enter a mobile number",
  "ccnotification.notification.notificationRoute.receiveAddrLen" : "The length cannot exceed 1024 characters",
  "ccnotification.notification.notificationRoute.retryNumLimitRule" : "The input value is out of range:0-3",
  "ccn.variable.willdeleteselectvariable": "Are you sure you want to delete the selected variables?",
  "ccn.variable.deleteselectvariable": "Delete Variable",
  "ccn.variable.variableisreferenced": "Variables are referenced by flow.",
  "ccn.variable.view.references": "View References",
  "ccn.variable.view.references.none": "The variable is not currently referenced.",
  "ccn.variable.selectvariable": "Please select a variable parameter.",
  "ccn.variable.field": "Notification Variables",
  "ccn.unit.bytes": "Bytes",
  'ccn.unit.millisecond': 'millisecond',
  "ccn.message.fullscreen": "Maximize",
  'ccn.sms.config.batch.delete': 'Batch Delete',
  'ccn.sms.config.receiver.uplinkMess': 'Receiving an MO SMS message',
  "ccn.sms.config.create.smsConfig": "Creating SMS Configurations",
  "ccn.sms.config.modify.smsConfig": "Modifying SMS Configurations",
  "ccnotification.smstemplate.message.edit.fail": "Failed to modify the HUAWEI CLOUD SMS template.",
  "ccnotification.message.choose": "Please select data.",
  "ccnotification.message.add.attachement": "Add Attachment",
  "ccnotification.message.defaultsenderid.error": "The default sender number is not in the allowed range. Please select a correct number.",
  "ccnotification.template.message.emailAddressEmpty": "Recipient email not configured:",
  "ccn.countrycode.type": "Country Code Prefix Type",
  "ccn.countrycode.type.with.plus": "Prefixed with +",
  "ccn.countrycode.type.without.plus": "Not Prefixed with +"
}