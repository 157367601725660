export default {
  "webclient.body.message.bot.recommend": "أعتقد أنك تريد أن تسأل",
  "webclient.body.message.card.url": "نسخ الرابط",
  "webclient.body.message.cobrowse.agree": "تأكيد",
  "webclient.body.message.cobrowse.cancel": "إلغاء",
  "webclient.body.message.cobrowse.target": "تصفح الوجهات：",
  "webclient.body.message.cobrowse.title": "طلب الوكيل تصفحًا تعاونيًا معك",
  "webclient.body.message.file.download": "تحميل",
  "webclient.body.message.file.download.again": "تحميل مرة أخرى",
  "webclient.body.message.locate.latitude": "خط العرض",
  "webclient.body.message.locate.longitude": "خط الطول",
  "webclient.body.message.recall": "تم سحب رسالة",
  "webclient.body.name.assistant": "مساعد ذكي",
  "webclient.body.tip.leave.click": "انقر هنا لترك رسالة",
  "webclient.body.tip.leave.message": "إذا لزم الأمر، من فضلك",
  "webclient.body.tip.leave.message.finish": "تم إرسال رسالتك بنجاح. يمكنك ترك رسالتك مرة أخرى",
  "webclient.body.tip.privacy": "لتحسين جودة الخدمة، نحتفظ بسجلات الدردشات التي أجريتها مع ممثلي خدمة العملاء والروبوتات، ونضمن أمان البيانات من خلال التشفير والتحكم في الوصول وما إلى ذلك.",
  "webclient.body.tip.queue.cancel": "إلغاء قائمة الانتظار",
  "webclient.body.tip.queue.cancel.done": "تم إلغاء قائمة الانتظار بنجاح. الرجاء الاستمرار في إرسال الرسالة مرة أخرى في قائمة الانتظار",
  "webclient.body.tip.queue.cancel.robot": "تم إلغاء قائمة الانتظار بنجاح. جارٍ الاتصال بالروبوت...",
  "webclient.body.tip.queue.part1": "أنت حالياً في المرتبة رقم",
  "webclient.body.tip.queue.part2": "نتوقع أن ننتظر",
  "webclient.body.tip.queue.part3": "نتوقع أن ننتظر",
  "webclient.body.tip.queue.timeout": "انتهت مهلة الانتظار",
  "webclient.body.tip.session.end": "انتهت الجلسة",
  "webclient.body.tip.session.reconnect": "إعادة بدء الجلسة",
  "webclient.body.tip.session.timeout": "لم تقم بالرد خلال الوقت المحدد، تم إنهاء الجلسة تلقائيًا. يمكنك",
  "webclient.body.tip.token.invalid": "الرمز المميز غير صالح أو تم تسجيله في مكان آخر",
  "webclient.body.title.call.disconnect": "تم قطع اتصال المكالمة",
  "webclient.body.title.call.end": "انتهت المكالمة",
  "webclient.body.title.call.fail": "فشلت المكالمة",
  "webclient.body.title.call.queue": "في الطابور",
  "webclient.body.title.call.start": "جاري المكالمة",
  "webclient.body.title.call.timeout": "انتهت مهلة وضع المكالمة في قائمة الانتظار",
  "webclient.body.title.call.transfer": "جاري تحويل المكالمة",
  "webclient.box.tip.click.cannot.link": "لا يمكن الاتصال ببوابة WebRTC",
  "webclient.box.tip.click.check.device.fail": "فشل التحقق مما إذا كان النقر للاتصال مدعومًا أم لا",
  "webclient.box.tip.click.no.audio.input": "جهاز إدخال الصوت غير متوفر",
  "webclient.box.tip.click.no.audio.output": "جهاز إخراج الصوت غير متوفر",
  "webclient.box.tip.click.no.video.input": "جهاز إدخال الفيديو غير متوفر",
  "webclient.box.tip.click.not.support.browser": "المتصفح الحالي لا يدعم WebRTC",
  "webclient.box.tip.click.not.support.channel": "القناة الحالية لا تدعم النقر للاتصال",
  "webclient.box.tip.click.not.support.tenant": "المستأجر الحالي لا يدعم WebRTC",
  "webclient.box.tip.cobrowse.no.auth": "ليس لدى الموظف إذن الاستعراض المشترك",
  "webclient.chat.nickname.system": "النظام",
  "webclient.dialog.evaluate.placeholder": "الرجاء إدخال تقييمك",
  "webclient.dialog.evaluate.title": "يُرجى تقييم خدمة العملاء!",
  "webclient.dialog.leave.format.content": "لا يمكن أن يكون محتوى الرسالة فارغًا ولا يمكن أن يحتوي على أحرف خاصة",
  "webclient.dialog.leave.format.phone": "لا يمكن أن يكون الرقم فارغًا ويمكن أن يحتوي على أرقام فقط، - أو",
  "webclient.dialog.leave.phone": "يُرجى إدخال رقم الجوال",
  "webclient.dialog.leave.success": "تم ترك الرسالة بنجاح",
  "webclient.dialog.leave.text":  "اترك رسالة",
  "webclient.dialog.leave.title": "ترك رسالة",
  "webclient.dialog.selectArea.title": "يُرجى تحديد المنطقة المراد حظرها",
  "webclient.dialog.video.default": "افتراضي",
  "webclient.dialog.video.hide": "إخفاء الفيديو المحلي",
  "webclient.dialog.video.large": "كبير",
  "webclient.dialog.video.medium": "متوسط",
  "webclient.dialog.video.pause": "كتم صوت الفيديو",
  "webclient.dialog.video.share.off": "إيقاف المشاركة",
  "webclient.dialog.video.share.on": "مشاركة سطح المكتب",
  "webclient.dialog.video.size": "حجم الفيديو",
  "webclient.dialog.video.small": "صغير",
  "webclient.dialog.video.start": "بدء الفيديو",
  "webclient.footer.label.send.message": "إرسال رسالة",
  "webclient.footer.label.share.direct": "مشاركة مباشرة",
  "webclient.footer.label.share.sensitive": "اختر منطقة حساسة",
  "webclient.footer.label.stop.direct": "إيقاف المشاركة",
  "webclient.footer.placeholder.richtext": "الرجاء إدخال السؤال الذي ترغب في طرحه",
  "webclient.footer.send.fail.content.big": "تجاوز المحتوى المرسل الحد الأقصى للطول (500 حرف)",
  "webclient.footer.send.fail.file.format": "تنسيق الملف غير مطابق للمتطلبات",
  "webclient.footer.send.fail.file.other": "فشل إرسال الملف",
  "webclient.footer.send.fail.file.screen": "فشل إرسال ملف لقطة الشاشة",
  "webclient.footer.send.fail.file.size.audio": "لا يمكن أن يتجاوز حجم الصوت ",
  "webclient.footer.send.fail.file.size.common": "لا يمكن أن يتجاوز حجم الملف ",
  "webclient.footer.send.fail.file.size.video": "لا يمكن أن يتجاوز حجم الفيديو ",
  "webclient.footer.send.fail.no.file": "الرجاء اختيار ملف",
  "webclient.footer.send.fail.picture.with.text": "لا يمكن إرسال الرسائل التي تحتوي على صور ونص أو رموز",
  "webclient.footer.tip.cobrowse.share": "ستؤدي العملية الحالية إلى مشاركة صفحة متصفحك وقد تحتوي على بياناتك الشخصية، هل تريد المتابعة؟",
  "webclient.footer.tip.connect.fail": "فشل الاتصال، يُرجى إعادة المحاولة أو الاتصال بالمسؤول",
  "webclient.footer.tip.download": "محتوى الدردشة الذي قمت بتنزيله بتنسيق HTML، ومحتوى الوسائط المتعددة في محتوى الدردشة صالح للوقت.من المستحسن تحويله إلى ملف PDF بمجرد تنزيله. خطوات تحويل HTML إلى PDF: 1. افتح ملف HTML الذي تريد تحويله في المتصفح ؛2. اضغط على Ctrl+P (في Windows) أو Command+P (في Mac) لفتح مربع حوار الطباعة؛3. في شاشة الطباعة ، حدد الطابعة المستهدفة كحفظ كملف PDF ؛4. انقر فوق الزر \"حفظ\" لتحويل صفحات HTML إلى تنسيق PDF وحفظها على الكمبيوتر المحلي.",
  "webclient.footer.tooltip.audio": "الصوت",
  "webclient.footer.tooltip.cobrowse": "مشاركة",
  "webclient.footer.tooltip.download": "تنزيل المحادثات",
  "webclient.footer.tooltip.emotion": "العواطف",
  "webclient.footer.tooltip.evaluation": "تقييم",
  "webclient.footer.tooltip.file": "إيداع ملفات",
  "webclient.footer.tooltip.more": "المزيد",
  "webclient.footer.tooltip.send": "أرسِل",
  "webclient.footer.tooltip.send.way": "طريقة الإرسال",
  "webclient.footer.tooltip.video": "فيديو",
  "webclient.header.title.input": "يقوم الطرف الآخر بالكتابة",
  "webclient.header.title.service": "AICC خدمة عملاء",
  "webclient.header.tooltip.close": "إغلاق",
  "webclient.header.tooltip.transfer.agent": "تحويل إلى عمل",
  "webclient.header.tooltip.transfer.robot": "تحويل الروبوت",
}