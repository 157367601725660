export default {
  "oifde.index.title":"ODFS – Online-Dialogflussdienst",
  "oifde.common.lang":"Sprache",
  "oifde.common.language.chinese":"Chinesisch",
  "oifde.common.language.english":"Englisch",
  "oifde.common.time.startDate":"Startdatum",
  "oifde.common.time.endDate":"Enddatum",
  "oifde.common.selectPlaceHolder":"Geben Sie ein Stichwort ein.",
  "oifde.common.browserSupporteMsg":"Der Browser unterstützt diese Funktion nicht.",
  "oifde.common.validation.required":"Dieses Feld ist obligatorisch",
  // "oifde.common.validation.extendFaqRange":"Nur chinesische Zeichen, englische Buchstaben, Ziffern, Leerzeichen und die folgenden Sonderzeichen sind allowed: ( ) [ ] {\'@\'} {\'{\'} {\'}\'},, 。. -",
  "oifde.common.validation.nameRange":"",
  "oifde.common.validation.cellNameRange":"",
  "oifde.shortMessage.validation.text":"",
  "oifde.common.validation.nameNoSpace":"Der Wert darf nicht mit einem Leerzeichen beginnen oder enden.",
  "oifde.common.validation.openNameCharReg":"",
  "oifde.common.validation.kgNameCharReg":"",
  "oifde.common.validation.nameCharRegNotChinese":"Der Wert darf nur chinesische Zeichen, englische Buchstaben, Ziffern, Unterstriche (_), und Bindestriche (-) enthalten.",
  // "oifde.common.validation.nameCharReg":"Nur chinesische Zeichen, englische Buchstaben, Ziffern, Leerzeichen, Unterstriche (_), und einfache Anführungszeichen (\') sind zulässig.",
  // "oifde.common.validation.schemaNameCharReg":"Nur chinesische Zeichen, englische Buchstaben, Ziffern, Leerzeichen und einfache Anführungszeichen (\') sind zulässig.",
  "oifde.common.validation.schemaRelationNameCharReg":"Der Wert darf nur chinesische Zeichen, englische Buchstaben, Ziffern, Punkte (.), Unterstriche und Bindestriche (-) enthalten.",
  "oifde.common.validation.charRegWithDot":"Der Wert muss eine ganze Zahl zwischen 0 und 2147483647 sein.",
  "oifde.common.validation.int":"Der Wert darf nicht kleiner als {0} sein.",
  "oifde.common.validation.min":"Der Wert darf nicht größer als {0} sein.",
  "oifde.common.validation.max":"Die Variablen FLOW und GLOBAL werden unterstützt. Der Wert kann auch eine ganze Zahl zwischen 1 und 120 sein. Entspricht der Wert nicht den Anforderungen, wird der Standardwert 5 verwendet.",
  "oifde.common.validation.collectTimeoutRule":"Der Wertebereich reicht von {0} bis {1}.",
  "oifde.common.validation.numberRange":"Das Timeout-Intervall für den Dialogfluss reicht von {0} bis {1} Sekunden.",
  "oifde.common.validation.sessionDTRange":"Die Wartezeit auf eine Antwort, nachdem ein Agent stumm ist, liegt zwischen {0} und {1} Sekunden.",
  "oifde.common.validation.slientHelpRange":"Der Konfidenzschwellenwert liegt zwischen {0} und {1}.",
  "oifde.common.validation.confidenceRange":"Das Intervall zum Speichern von IVR-Flow-Orchestrierungsdaten muss eine positive Ganzzahl sein, die nicht kleiner als {0} Minute(n) und nicht größer als {1} Minute(n) sein darf.",
  "oifde.common.validation.flowSaveIntervalRange":"Das URL-Format ist ungültig.",
  "oifde.common.validation.urlValidate":"Der gemeinsame Schlüssel muss 8 bis 20 Zeichen enthalten.",
  "oifde.common.validation.appSecretLenCheck":"Der Wert muss eine positive ganze Zahl sein.",
  "oifde.common.validation.positiveInt":"Der Wert muss 0 oder eine positive ganze Zahl sein",
  "oifde.common.validation.digits":"Bitte geben Sie nur Ziffern ein.",
  "oifde.common.validation.numbers":"Der Wert darf nur chinesische Zeichen, englische Buchstaben, Ziffern, Unterstriche (_), und Bindestriche (-) enthalten.",
  // "oifde.common.validation.nodeNameCharReg":"Der Wert darf den folgenden speziellen characters: ~ `! # {\'$\'} % ^ & * ( ) + nicht enthalten: [ ] {\'{\'} {\'}\'} {\'|\'} \' “ : < > /?",
  "oifde.common.validation.validateFalse":"Geben Sie eine positive Ganzzahl zwischen 0 und 255 ein.",
  "oifde.common.validation.mrcpIdValueFalse":"Das Intervall der IVR-Flow-Knoten reicht von {0} bis {1}.",
  "oifde.common.validation.flowMaxElementsRange":"Bestätigen",
  "oifde.common.dialog.confirm.title":"Durch diesen Vorgang werden die Daten dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
  "oifde.common.dialog.confirm.content":"Ja",
  "oifde.common.dialog.confirm.buttonSure":"Nein",
  "oifde.common.dialog.confirm.buttonCancel":"Die Daten wurden erfolgreich gelöscht!",
  "oifde.common.dialog.confirm.successInfo":"Der Löschvorgang wurde abgebrochen.",
  "oifde.common.dialog.confirm.cancelInfo":"Der Vorgang wurde erfolgreich ausgeführt!",
  "oifde.common.dialog.confirm.operationSucc":"Vorgang konnte nicht ausgeführt werden!",
  "oifde.common.dialog.confirm.operation_failed":"Hinzufügen",
  "oifde.common.actions.add":"Bearbeiten",
  "oifde.common.actions.edit":"Löschen",
  "oifde.common.actions.delete":"Herunterladen",
  "oifde.common.actions.download":"Hilfeinformationen anzeigen.",
  "oifde.common.help":"-Auswahl-",
  "oifde.common.select":"Einzelheiten",
  "oifde.common.detail":"Keine Daten",
  "oifde.common.noData":"Intelligente Roboterschnittstelle",
  "oifde.serviceInterface.oifdeInterface":"Gemeinsame IVR-Schnittstelle",
  "oifde.serviceInterface.ccivrInterface":"Selbstdefinierte Schnittstelle",
  "oifde.serviceInterface.selfDefinedInterface":"Innere Schnittstelle",
  "oifde.serviceInterface.innerInterface":"Das Format ist korrekt.",
  "oifde.serviceInterface.jsonValidateSuccess":"JSON überprüfen",
  "oifde.serviceInterface.jsonChek":"Schnittstellenzertifikat",
  "oifde.serviceInterface.interfaceCert":"Code",
  "oifde.serviceInterface.certCode":"Szenario",
  "oifde.serviceInterface.certScene":"Abgelaufen",
  "oifde.serviceInterface.expireTime":"Aktualisiert",
  "oifde.serviceInterface.certUpdateTime":"Der Nachrichtentext entspricht nicht dem JSON-Standardformat.",
  "oifde.serviceInterface.jsonValidate":"Für die Schnittstelle wurde ein unsicheres Netzwerkprotokoll angegeben.",
  "oifde.serviceInterface.safeWarning":"Die URL (IP-Adresse und Portnummer) ist nicht in der Vertrauensliste und kann nur verwendet werden, nachdem Sie den Systemadministrator kontaktiert haben, um sie der Vertrauensliste hinzuzufügen.",
  "oifde.serviceInterface.whiteListCheckWarning":"Schnittstellenname",
  "oifde.serviceInterface.interfaceName":"Unternehmenscode",
  "oifde.serviceInterface.bizCode":"Beschreibung",
  "oifde.serviceInterface.description":"Aktualisiert",
  "oifde.serviceInterface.updatetime":"Betrieb",
  "oifde.serviceInterface.operation":"Geschäftsschnittstellenkonfiguration hinzufügen",
  "oifde.serviceInterface.addServiceInterface":"Konfiguration der Geschäftsschnittstelle bearbeiten",
  "oifde.serviceInterface.modifyServiceInterface":"Konfigurationsdetails der Geschäftsschnittstelle",
  "oifde.serviceInterface.serviceInterfaceDetails":"Details der inneren Schnittstelle",
  "oifde.serviceInterface.innerInterfaceDetails":"Grundlegende Informationen",
  "oifde.serviceInterface.baseInfo":"URL anfordern",
  "oifde.serviceInterface.reqURL":"Unterschreiben",
  "oifde.serviceInterface.signBody":"Nein",
  "oifde.serviceInterface.signBodyDetail.no":"Ja",
  "oifde.serviceInterface.signBodyDetail.yes":"Ob die Signatur für einen Dritten verifiziert werden soll, wenn die Signatur als Schnittstellenparameter an den Dritten übertragen wird.",
  "oifde.serviceInterface.signDescription":"Gemeinsamer Schlüssel",
  "oifde.serviceInterface.appSecret":"Geben Sie den gemeinsamen Schlüssel ein.",
  "oifde.serviceInterface.inputAppSecret":"Schnittstellencode",
  "oifde.serviceInterface.busiCode":"Anforderungsmethode",
  "oifde.serviceInterface.interfaceType":"Dieses Feld ist obligatorisch.",
  "oifde.serviceInterface.required":"Abfrageparameter",
  "oifde.serviceInterface.query_param":"Wert",
  "oifde.serviceInterface.pathParamValue":"Pfad",
  "oifde.serviceInterface.outParamPath":"Eingabeparameter",
  "oifde.serviceInterface.in_param":"Ausgabeparameter",
  "oifde.serviceInterface.out_param":"Name",
  "oifde.serviceInterface.name":"Datentyp",
  "oifde.serviceInterface.dataType":"Verschlüsselt",
  "oifde.serviceInterface.encryptFlag":"Ganzzahl",
  "oifde.serviceInterface.dataTypes.INT":"Charakter",
  "oifde.serviceInterface.dataTypes.STRING":"Gleitkommazahl",
  "oifde.serviceInterface.dataTypes.FLOAT":"Lange Ganzzahl",
  "oifde.serviceInterface.dataTypes.LONG":"Liste",
  "oifde.serviceInterface.dataTypes.LIST":"Objekt",
  "oifde.serviceInterface.dataTypes.OBJECT":"Nachrichtenkopfzeile",
  "oifde.serviceInterface.msgHeaders":"Nachrichtentext",
  "oifde.serviceInterface.msgBody":"Schlüssel",
  "oifde.serviceInterface.headerKey":"Wert",
  "oifde.serviceInterface.headerValue":"Durch diesen Vorgang wird die Geschäftsschnittstelle dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
  "oifde.serviceInterface.deleteConfirm":"Das folgende obligatorische Feld ist leer: Basisinformationen > Schnittstellenname.",
  "oifde.serviceInterface.miss_ifs_name":"Bestätigen",
  "oifde.serviceInterface.prompt":"OK",
  "oifde.serviceInterface.ok":"Das folgende obligatorische Feld ist leer: Basisinformationen > URL anfordern.",
  "oifde.serviceInterface.miss_ifs_request_url":"Das folgende Pflichtfeld ist leer: Eingabeparameter > Name.",
  "oifde.serviceInterface.miss_ifs_in_parameter_name":"Das folgende Pflichtfeld ist leer: Ausgabeparameter > Name.",
  "oifde.serviceInterface.miss_ifs_out_parameter_name":"Das folgende Pflichtfeld ist leer: Ausgabeparameter > Datentyp.",
  "oifde.serviceInterface.miss_ifs_out_parameter_type":"Das folgende Pflichtfeld ist leer: Nachrichtenkopfzeile > Schlüssel.",
  "oifde.serviceInterface.miss_ifs_header_key":"Das folgende Pflichtfeld ist leer: Nachrichtentext.",
  "oifde.serviceInterface.miss_ifs_msgBody":"Automatischer Nachrichtentext",
  "oifde.serviceInterface.auto_msgBody":"Manueller Nachrichtentext",
  "oifde.serviceInterface.manual_msgBody":"Geben Sie einen Nachrichtentext ein.",
  "oifde.serviceInterface.promptMessage":"Speichern abgebrochen!",
  "oifde.serviceInterface.actions.cancelSuccess":"Dieser Vorgang speichert den'automatischen Nachrichtentext' als Konfiguration der Serviceschnittstelle. Möchten Sie wirklich fortfahren?",
  "oifde.serviceInterface.actions.auto_savePrompt":"Die URL (IP-Adresse und Portnummer) ist nicht in der Vertrauensliste enthalten und kann nur verwendet werden, nachdem Sie sich an den Systemadministrator gewandt haben, um sie der Vertrauensliste hinzuzufügen. Fortfahren?",
  "oifde.serviceInterface.actions.urlNotInWhitelist":"Dieser Vorgang speichert den'manuellen Nachrichtentext' als Konfiguration der Serviceschnittstelle. Möchten Sie wirklich fortfahren?",
  "oifde.serviceInterface.actions.manual_savePrompt":"Bestätigen",
  "oifde.serviceInterface.actions.prompt":"Ja",
  "oifde.serviceInterface.actions.confirm":"Nein",
  "oifde.serviceInterface.actions.cancel":"Q&A aufzeichnen",
  "oifde.qarecord.interface.name":"Zeichnet die Fragebogen-ID, den Fragentitel, die Absicht der Benutzerantwort und die ursprüngliche Benutzerantwort auf.",
  "oifde.qarecord.interface.desc":"Abrufen des APIFabric-Tokens",
  "oifde.apiToken.interface.name":"Schnittstelle, die zum Abrufen des API-Fabric-Tokens verwendet wird",
  "oifde.apiToken.interface.desc":"Tokenpräfix",
  "oifde.apiToken.applyType.desc":"Inhalt des Tokens",
  "oifde.apiToken.accessToken.desc":"App-Schlüssel",
  "oifde.apiToken.appKey.desc":"App-Geheimnis",
  "oifde.apiToken.appSecret.desc":"Fragen- und Antwort-ID",
  "oifde.qarecord.inputparam.seriesId":"Fragenbezeichnung",
  "oifde.qarecord.inputparam.questionTitle":"Fragentyp",
  "oifde.qarecord.inputparam.questionType":"Absicht der Antwort",
  "oifde.qarecord.inputparam.answerIntent":"Originalantwort",
  "oifde.qarecord.inputparam.originalAnswer":"Systemeinstellungen",
  "oifde.sysMgr.sysCfg":"API-Kennworteinstellungen",
  "oifde.sysMgr.apiKeyCfg":"Festlegen",
  "oifde.sysMgr.configuration":"API-Kennwort",
  "oifde.sysMgr.apiKey":"API-Passwort bestätigen",
  "oifde.sysMgr.apiKeyComfirm":"Die API-Passwörter stimmen nicht überein.",
  "oifde.sysMgr.apiKeyNotSame":"Geben Sie ein API-Passwort ein.",
  "oifde.sysMgr.inputKey":"Abbrechen",
  "oifde.sysMgr.cancel":"Speichern",
  // "  ""oifde.sysMgr.save"":""Das Kennwort muss mindestens zwei der folgenden Typen enthalten: Großbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen ~ `! {\'@\'} # {\'$\'} % ^ * ( ) - +,
  // Das API-Passwort wurde erfolgreich festgelegt!"","","
  "oifde.sysMgr.apiRuleRange":"Geben Sie ein API-Passwort ein.",
  "oifde.sysMgr.success_set_key":"Das API-Kennwort muss 16 bis 64 Zeichen enthalten.",
  "oifde.sysMgr.requiredAPIKey":"Die RPA-ZUGRIFFSINFO wurde erfolgreich festgelegt!",
  "oifde.sysMgr.APIKeyLenCheck":"Das RPA-Benutzerkonto ist erforderlich.",
  "oifde.sysMgr.success_set_rpa":"Der geheime RPA-Schlüssel ist erforderlich.",
  "oifde.sysMgr.requiredRpaUserName":"Das RPA-Benutzerkonto darf nicht mehr als 64 Zeichen enthalten.",
  "oifde.sysMgr.requiredRpaSecretKey":"Der geheime RPA-Schlüssel darf nicht mehr als 128 Zeichen enthalten.",
  "oifde.sysMgr.rpaUserNameLenCheck":"Geben Sie den RPA-Benutzernamen ein.",
  "oifde.sysMgr.rpaSecretKeyLenCheck":"Geben Sie den geheimen RPA-Schlüssel ein.",
  "oifde.sysMgr.inputRpaUserName":"Legt die Datenlöschdauer fest.",
  "oifde.sysMgr.inputRpaSecretKey":"Parametername",
  "oifde.sysMgr.dataClean.setTime":"Wert",
  "oifde.sysMgr.dataClean.serviceName":"Beschreibung",
  "oifde.sysMgr.dataClean.paramValue":"Intervall, in dem die Datenbereinigung durchgeführt wird.",
  "oifde.sysMgr.dataClean.description":"Liste der Domänen, die an die Textanalyse gebunden sind.",
  "oifde.sysMgr.dataClean.descriptionContent":"Bearbeiten einer Liste von Domänen, die an Textanalysen gebunden sind",
  "oifde.sysMgr.dataClean.tcaContent":"Betrieb",
  "oifde.sysMgr.dataClean.editTcaFlag":"Datenbereinigungsintervall ändern",
  "oifde.sysMgr.dataClean.operation":"Tage",
  "oifde.sysMgr.dataClean.editTime":"h",
  "oifde.sysMgr.dataClean.day":"min",
  "oifde.sysMgr.dataClean.hour":"Ob die Aufzeichnung in einem intelligenten IVR-Anrufablauf aktiviert werden soll.",
  "oifde.sysMgr.dataClean.minute":"Aufzeichnung im intelligenten IVR-Anrufablauf ändern",
  "oifde.sysMgr.dataClean.flagDescription":"Ob die Protokollierung von Schnittstellenaufrufen von Drittanbietern aktiviert werden soll.",
  "oifde.sysMgr.dataClean.editFlag":"Protokollierung des Schnittstellenaufrufs von Drittanbietern bearbeiten",
  "oifde.sysMgr.dataClean.recordThirdApi":"Ob die Aufzeichnung in gängigen IVR-Anrufabläufen aktiviert werden soll.",
  "oifde.sysMgr.dataClean.editInterfaceFlag":"Aufzeichnung in gängigen IVR-Anrufabläufen bearbeiten",
  "oifde.sysMgr.dataClean.ccvirrecordFlag":"Aktivieren",
  "oifde.sysMgr.dataClean.editCcvirrecordFlag":"Deaktivieren",
  "oifde.sysMgr.dataClean.open":"Aufzeichnen",
  "oifde.sysMgr.dataClean.close":"Nicht aufzeichnen",
  "oifde.sysMgr.dataClean.record":"Sekunden",
  "oifde.sysMgr.dataClean.noRecord":"Ob Testfälle in einem Bericht aufgezeichnet werden sollen.",
  "oifde.sysMgr.dataClean.second":"Aufzeichnung von Testfällen ändern",
  "oifde.sysMgr.dataClean.reportFlagDesc":"Timeout-Intervall für Dialogflüsse.",
  "oifde.sysMgr.dataClean.reportFlagTitle":"Zeitüberschreitungsintervall für Dialogfluss ändern",
  "oifde.sysMgr.dataClean.sessionDTDesc":"Zeit zum Warten auf eine Antwort, nachdem ein Agent schweigt.",
  "oifde.sysMgr.dataClean.editSessionDT":"Konfidenzschwelle für einen stillen Agenten, um das einzelne Hilfesuchen eines Chatbots zu erkennen.",
  "oifde.sysMgr.dataClean.slientHelpDesc":"Zeit zur automatischen Deaktivierung der Nachverfolgung.",
  "oifde.sysMgr.dataClean.confidenceDesc":"Zeit zum Warten auf Antwort nach Stillschweigen des Agenten ändern",
  "oifde.sysMgr.dataClean.traceLogAutoCloseDesc":"Vertrauen ändern",
  "oifde.sysMgr.dataClean.editSlientHelp":"Zeit der automatischen Deaktivierung für Tracking bearbeiten",
  "oifde.sysMgr.dataClean.confidence":"Zeigt an, ob die Funktion zum geplanten Speichern für die IVR-Prozessorchestrierung aktiviert werden soll.",
  "oifde.sysMgr.dataClean.traceLogAutoClose":"Bearbeiten des Kennzeichens für geplantes Speichern der IVR-Prozessorchestrierung",
  "oifde.sysMgr.dataClean.flowIvrSaveFlagDesc":"Intervall zum Speichern der IVR-Flow-Orchestrierungsdaten.",
  "oifde.sysMgr.dataClean.editFlowSaveFlag":"Bearbeiten des Intervalls zum Speichern von IVR-Flow-Orchestrierungsdaten",
  "oifde.sysMgr.dataClean.flowIvrSaveIntervalDesc":"Max. Diagrammelemente, die von einem IVR-Fluss unterstützt werden.",
  "oifde.sysMgr.dataClean.editFlowSaveInterval":"Max bearbeiten. Von einem IVR-Fluss unterstützte Diagrammelemente",
  "oifde.sysMgr.dataClean.flowMaxElementsDesc":"Parameterwert",
  "oifde.sysMgr.dataClean.editFlowMaxElements":"Haupteinstellungen",
  "oifde.sysMgr.dataClean.paramValueTca":"Schlüssel",
  "oifde.sysMgr.encryptSecretCfg":"Schlüssel bestätigen",
  "oifde.sysMgr.encryptSecret":"Die Schlüssel stimmen nicht überein.",
  "oifde.sysMgr.encryptSecretComfirm":"Geben Sie einen Schlüssel ein.",
  "oifde.sysMgr.encryptSecretNotSame":"Der Schlüssel wurde erfolgreich festgelegt!",
  // "  ""oifde.sysMgr.inputEncryptSecret"":""Das Verschlüsselungsgeheimnis entspricht nicht den Anforderungen. Er muss mindestens zwei der folgenden Typen enthalten: Großbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen ~ `! {\'@\'} # {\'$\'} % ^ * ( ) - +,
  // Der Schlüssel darf nicht mit der Raum-ID des Mandanten identisch sein."","","
  "oifde.sysMgr.success_set_encryptSecret":"Der Schlüssel muss 16 Zeichen enthalten.",
  "oifde.sysMgr.encryptSecretRuleRange":"Intelligente Engine-Parametereinstellungen",
  "oifde.sysMgr.encryptSecretNoSame":"Das Kennwort darf nicht mit der Raum-ID des Mandanten identisch sein.",
  // "oifde.sysMgr.encryptSecretLenCheck":"Der Schlüssel ist obligatorisch, wenn Sie eine verschlüsselte Variable verwenden. Wählen Sie Systemverwaltung“ > "Systemkonfiguration“ und klicken Sie auf "Schlüsseleinstellungen", um den Schlüssel festzulegen."",
    "oifde.sysMgr.parameterSetting":"RPA-Einstellungen",
    "oifde.sysMgr.apiNoSame":"RPA-Benutzerkonto",
    "oifde.sysMgr.variableSecretNotExists":"RPA-Geheimschlüssel",
    "oifde.sysMgr.rpaCfg":"Orchestrierung",
    "oifde.sysMgr.rpaUserNameCfg":"Graue Flussregel",
    "oifde.sysMgr.rpaSecretKeyCfg":"Flussverfolgung",
    "oifde.route.flow":"Flussanalyse",
    "oifde.route.rule":"IVR-Übersicht",
    "oifde.route.trace":"Indikatoranalyse",
    "oifde.route.flowAnalysis":"Versionsvergleich und Analyse",
    "oifde.route.ivrOverview":"Optimierungsvorschläge",
    "oifde.route.indicatorAnalysis":"Alarmereignis",
    "oifde.route.versionComparisonAndAnalysis":"Fluss hinzufügen",
    "oifde.route.optimizationSuggestions":"Zusammengesetzten Ansichtsbereich bearbeiten",
    "oifde.route.alarmEvent":"{0} bearbeiten",
    "oifde.route.addDiagram":"Anzeigen {0}",
    "oifde.route.editCellDiagram":"Fluss bearbeiten",
    "oifde.route.edit":"Fluss anzeigen",
    "oifde.route.view":"Flowverfolgung anzeigen",
    "oifde.route.editDiagram":"Elementverfolgung im zusammengesetzten Diagramm anzeigen",
    "oifde.route.viewDiagram":"Ressourcenvorlage",
    "oifde.route.viewTraceDiagram":"Flussprüfung",
    "oifde.route.viewCellTraceDiagram":"Semantisches Verständnis",
    "oifde.route.voiceTemplateTitle":"Semantisches Verständnis",
    "oifde.route.staticCheck":"Wissensmanagement",
    "oifde.route.toc.sysadmin":"Domänenverwaltung",
    "oifde.route.toc.user":"Absichtsverwaltung",
    "oifde.route.tuc.template.index":"Entitätsverwaltung",
    "oifde.route.tuc.template.domain":"FAQ-Verwaltung",
    "oifde.route.tuc.template.intent":"Diagrammverwaltung",
    "oifde.route.tuc.template.entity":"Systemkonfiguration",
    "oifde.route.tuc.template.faq":"Blocklist und Vertrauensliste",
    "oifde.route.tuc.template.kg":"Historischer Dialog",
    "oifde.route.tuc.template.config":"Überprüfung der historischen Botschaft",
    "oifde.route.tuc.template.bwlist":"Fragengruppierungsaufgabe",
    "oifde.route.tuc.history.index":"Schulungsüberprüfung",
    "oifde.route.tuc.history.dialog":"Dialogtest",
    "oifde.route.tuc.history.mining":"Wissenstest",
    "oifde.route.tuc.ops.index":"Leistungsstatistiken",
    "oifde.route.tuc.ops.test":"Wartung",
    "oifde.route.tuc.ops.tucTest":"Vorgangsprotokoll",
    "oifde.route.tuc.ops.perf":"Korpustest",
    "oifde.route.tuc.ops.node":"Server",
    "oifde.route.tuc.ops.log":"Qualitätsprüfung",
    "oifde.route.tuc.ops.corpustest":"TTS/ASR",
    "oifde.route.tuc.ops.server":"Virtueller 2D-Mensch",
    "oifde.route.tuc.ops.qiAsrServer":"Modellverwaltung",
    "oifde.route.tuc.ops.asr":"Parameter der intelligenten Engine",
    "oifde.route.tuc.ops.avatar":"Graue Wissensregel",
    "oifde.route.tuc.ops.modelMgr":"Lizenz",
    "oifde.route.tuc.ops.sysparam":"Gemeinsames IVR",
    "oifde.route.tuc.ops.kgrule":"Intelligenter Chatbot",
    "oifde.route.tuc.ops.license":"Zugangscode",
    "oifde.route.keyPressRobot":"System",
    "oifde.route.RobotManage":"Systemeinstellungen",
    "oifde.route.accessCodeManage":"Whitelist",
    "oifde.route.sysMgr.sysMgr":"Historischer Ruf",
    "oifde.route.sysMgr.sysCfg":"Fluss",
    "oifde.route.sysMgr.whiteList":"Ressource",
    "oifde.route.sysMgr.recordHistory":"Geschäftsschnittstelle",
    "oifde.route.navigation":"Variable",
    "oifde.route.resources":"Komplexe Zelle",
    "oifde.route.serviceInterface":"Gespeicherte Prozedur",
    "oifde.route.variable":"Datentabelle",
    "oifde.route.cell":"Nicht verwendete Vorlagen anzeigen",
    "oifde.route.procedure":"Nicht verwendete globale Variablen anzeigen",
    "oifde.route.dataTable":"Nicht verwendete Flussvariablen anzeigen",
    "oifde.route.unusedResource.template":"Anzeigen nicht verwendeter Elemente des zusammengesetzten Diagramms",
    "oifde.route.unusedResource.globalvars":"Nicht verwendete Schnittstellen anzeigen",
    "oifde.route.unusedResource.flowvars":"Nicht verwendete gespeicherte Prozeduren anzeigen",
    "oifde.route.unusedResource.complexcell":"Nicht verwendete Grauregeln anzeigen",
    "oifde.route.unusedResource.interface":"Wissensdiagramm",
    "oifde.route.unusedResource.procedure":"Wissensschema",
    "oifde.route.unusedResource.rule":"Details zum Schema {0}",
    "oifde.route.knowledgegraph.knowledgegraph":"Diagrammwörterbuch",
    "oifde.route.knowledgegraph.kgModel":"Entitätsverwaltung für {0}",
    "oifde.route.knowledgegraph.editSchema":"Entitätsattribut für {0}",
    "oifde.route.knowledgegraph.dicmgr":"Beziehungsmanagement für {0}",
    "oifde.route.knowledgegraph.entitymgr":"Beziehungsattribut für {0}",
    "oifde.route.knowledgegraph.entityAttribute":"Diagrammvisualisierung für {0}",
    "oifde.route.knowledgegraph.relationmgr":"Diagrammexport",
    "oifde.route.knowledgegraph.relationAttribute":"Diagrammimport",
    "oifde.route.knowledgegraph.kgView":"Häufige Fragen zu Diagrammen für {0}",
    "oifde.route.knowledgegraph.kgExport":"Synonym für {0}",
    "oifde.route.knowledgegraph.importExcel":"Erweiterte Frage",
    "oifde.route.knowledgegraph.kgFAQ":"Code",
    "oifde.route.knowledgegraph.kgSynonyms":"Name",
    "oifde.route.knowledgegraph.extendQuestion":"Version",
    "oifde.flow.code":"Szenariotyp",
    "oifde.flow.name":"Status",
    "oifde.flow.version":"Graue Regel",
    "oifde.flow.scenarioType":"Erstellt",
    "oifde.flow.status":"Geändert",
    "oifde.flow.rule":"Grau",
    "oifde.flow.createTime":"Keine Regel konfiguriert",
    "oifde.flow.updateTime":"Standardablauf",
    "oifde.flow.grayFlow":"Veröffentlichen",
    "oifde.flow.noRule":"Löschen",
    "oifde.flow.defaultFlow":"Bearbeiten",
    "oifde.flow.actions.publish":"Ungültig machen",
    "oifde.flow.actions.delete":"Aktualisierung",
    "oifde.flow.actions.edit":"Festlegen",
    "oifde.flow.actions.invalid":"Kopierfluss",
    "oifde.flow.actions.upgrade":"Der Flow wurde erfolgreich kopiert!",
    "oifde.flow.actions.setting":"Flow konnte nicht kopiert werden!",
    "oifde.flow.actions.copy":"Dieser Vorgang löscht den Flow dauerhaft. Möchten Sie wirklich fortfahren?",
    "oifde.flow.actions.copySuccess":"Der Löschvorgang wurde abgebrochen.",
    "oifde.flow.actions.copyFailed":"Der Flow wurde erfolgreich gelöscht!",
    "oifde.flow.actions.deleteConfirm":"Diese Operation wird den Fluss unfreigegeben. Möchten Sie wirklich fortfahren?",
    "oifde.flow.actions.deleteCancel":"Der Flow wurde nicht veröffentlicht.",
    "oifde.flow.actions.deleteOk":"Die Entwertung wird aufgehoben.",
    "oifde.flow.actions.invalidConfirm":"Eine neue Version des Flows wurde erfolgreich erstellt!",
    "oifde.flow.actions.invalidOk":"Der Flow wurde erfolgreich konfiguriert!",
    "oifde.flow.actions.invalidCancel":"Der Flow wurde erfolgreich veröffentlicht!",
    "oifde.flow.actions.createOk":"Ja",
    "oifde.flow.actions.settingOk":"Bestätigen",
    "oifde.flow.actions.publishOk":"Nein",
    "oifde.flow.actions.confirm":"Vorgang fehlgeschlagen!",
    "oifde.flow.actions.prompt":"Fehlercode",
    "oifde.flow.actions.cancel":"Fehlermeldung",
    "oifde.flow.actions.error":"Statische Prüfung",
    "oifde.flow.actions.errorCode":"Wählen Sie eine Regel aus.",
    "oifde.flow.actions.errorMessage":"Wählen Sie einen Veröffentlichungsmodus aus.",
    "oifde.flow.actions.staticcheck":"Wählen Sie aus, ob die Protokollfunktion aktiviert werden soll.",
    "oifde.flow.rules.ruleId":"Wählen Sie aus, ob die strenge Validierung aktiviert werden soll.",
    "oifde.flow.rules.upgradeMode":"Geben Sie eine neue Version an.",
    "oifde.flow.rules.logSwitchOn":"Die neue Version muss einzigartig sein.",
    "oifde.flow.rules.validationMode":"Die neue Version darf nicht mehr als 64 Zeichen enthalten.",
    "oifde.flow.rules.newVersion":"Geben Sie einen neuen Flow-Namen an.",
    "oifde.flow.rules.newVersionDuplicated":"Wählen Sie einen Arbeitsmodus.",
    "oifde.flow.rules.newVersionLength":"Hauptfluss",
    "oifde.flow.rules.newFlowName":"Ausnahmefluss",
    "oifde.flow.rules.workMode":"Subflow",
    "oifde.flow.enums.scenarioType.mainFlow":"Entwurf",
    "oifde.flow.enums.scenarioType.errorFlow":"Veröffentlicht",
    "oifde.flow.enums.scenarioType.subFlow":"Für ungültig erklärt",
    "oifde.flow.enums.status.init":"Alle exportieren",
    "oifde.flow.enums.status.valid":"Nicht exportieren",
    "oifde.flow.enums.status.invalid":"Abhängigkeiten exportieren",
    "oifde.flow.enums.tabsValue.all":"Name",
    "oifde.flow.enums.tabsValue.none":"Version",
    "oifde.flow.enums.tabsValue.depend":"Veröffentlichungsmodus",
    "oifde.flow.publish.flowName":"Upgrade und Veröffentlichung",
    "oifde.flow.publish.flowVersion":"Graufreigabe",
    "oifde.flow.publish.upgradeMode":"Graue Regel",
    "oifde.flow.publish.upgrade":"-Auswahl-",
    "oifde.flow.publish.gray":"Arbeitsmodus",
    "oifde.flow.publish.ruleName":"Allgemein",
    "oifde.flow.publish.selectPlaceholder":"Überwachen",
    "oifde.flow.publish.publishMode":"Einfügen",
    "oifde.flow.publish.normal":"Nicht konfiguriert",
    "oifde.flow.publish.monitor":"Originalflussname",
    "oifde.flow.publish.insert":"Neuer Flussname",
    "oifde.flow.publish.notConfig":"Protokollfunktion",
    "oifde.flow.copy.flowName":"Aktivieren",
    "oifde.flow.copy.newFlowName":"Deaktivieren",
    "oifde.flow.setting.logSwitch":"Ändern",
    "oifde.flow.setting.logSwitchOn":"Überprüfungsmodus festlegen",
    "oifde.flow.setting.logSwitchOff":"Aktivieren",
    "oifde.flow.setting.title":"Deaktivieren",
    "oifde.flow.setting.validationMode":"Aktuelle Versionen",
    "oifde.flow.setting.validationModeOn":"Neue Version",
    "oifde.flow.setting.validationModeOff":"Zugangscode",
    "oifde.flow.upgrade.currentVersions":"Regel",
    "oifde.flow.upgrade.newVersion":"Vorlage",
    "oifde.flow.exportFlowList.accessCode":"Schnittstelle",
    "oifde.flow.exportFlowList.rule":"Variable",
    "oifde.flow.exportFlowList.template":"Exportieren",
    "oifde.flow.exportFlowList.interface":"Sie haben keine bestimmten Datensätze ausgewählt. Alle Datensätze exportieren? Sie können den Vorgang auch abbrechen und einen bestimmten Datensatz zum Exportieren auswählen.",
    "oifde.flow.exportFlowList.variable":"Informationen",
    "oifde.flow.exportFlowList.title":"Wählen Sie mindestens einen Datensatz aus.",
    "oifde.flow.exportFlowList.askExportAll":"Exportieren",
    "oifde.flow.exportFlowList.prompt":"Alle exportieren",
    "oifde.flow.exportFlowList.promptMessage":"Gespeicherte Prozedur",
    "oifde.flow.exportFlowList.export":"Zusammengesetztes Diagrammelement",
    "oifde.flow.exportFlowList.exportAll":"Importfluss",
    "oifde.flow.exportFlowList.procedure":"Datei auswählen",
    "oifde.flow.exportFlowList.complexCell":"Hochladen",
    "oifde.flow.importFlow.title":"Die Datei muss eine ZIP-Datei sein.",
    "oifde.flow.importFlow.select":"Doppelte Namen zulassen",
    "oifde.flow.importFlow.upload":"Ergebnisse importieren",
    "oifde.flow.importFlow.prompt":"Name",
    "oifde.flow.importFlow.cover":"Typ",
    "oifde.flow.importFlow.resultTitle":"Ergebnis",
    "oifde.flow.importFlow.name":"Aufforderung",
    "oifde.flow.importFlow.type":"Wählen Sie die Datei aus, die Sie hochladen möchten.",
    "oifde.flow.importFlow.result":"Die Datei muss eine ZIP-Datei sein.",
    "oifde.flow.importFlow.error":"Importieren",
    "oifde.flow.importFlow.message":"Der Vorgang war erfolgreich.",
    "oifde.flow.importFlow.fileType":"Die Datei ist zu groß.",
    "oifde.flow.importFlow.import":"Datei konnte nicht hochgeladen werden!",
    "oifde.flow.importFlow.success":"Die Dateigröße darf 20 MB nicht überschreiten.",
    "oifde.flow.importFlow.bigFile":"Daten werden importiert ...“ Bitte fragen Sie die neuesten Daten später ab.",
    "oifde.flow.importFlow.uploadFail":"Statistikstatus:",
    "oifde.flow.importFlow.bigFilePrompt":"Zuletzt abgeschlossen:",
    "oifde.flow.importFlow.timeout":"Prüfen",
    "oifde.flow.usage.usageStatus":"Nicht verwendete Ressourcen anzeigen",
    "oifde.flow.usage.lasttime":"Vorlagenverwendung",
    "oifde.flow.usage.check":"Globale Variablenverwendung",
    "oifde.flow.usage.showUnused":"Nutzung der Flussvariablen",
    "oifde.flow.usage.templateUsageTitle":"Wird verwendet",
    "oifde.flow.usage.globalVarsUsageTitle":"Nie verwendet",
    "oifde.flow.usage.flowVarsUsageTitle":"Keine Daten",
    "oifde.flow.usage.inuse":"Ausstehende Ausführung",
    "oifde.flow.usage.unuse":"Wird ausgeführt",
    "oifde.flow.usage.status.norecord":"Abgeschlossen",
    "oifde.flow.usage.status.init":"Fehlgeschlagen",
    "oifde.flow.usage.status.running":"Name",
    "oifde.flow.usage.status.finish":"Typ",
    "oifde.flow.usage.status.fail":"Beschreibung",
    "oifde.flow.usage.name":"Vorlage",
    "oifde.flow.usage.type":"Globale Variable",
    "oifde.flow.usage.desc":"Flussvariable",
    "oifde.flow.usage.resourceType.template":"Zusammengesetztes Diagrammelement",
    "oifde.flow.usage.resourceType.globalvars":"Schnittstelle",
    "oifde.flow.usage.resourceType.flowvars":"Gespeicherte Prozedur",
    "oifde.flow.usage.resourceType.complexcell":"Graue Regel",
    "oifde.flow.usage.resourceType.interface":"Verwendung der Elemente des zusammengesetzten Diagramms",
    "oifde.flow.usage.resourceType.procedure":"Schnittstellennutzung",
    "oifde.flow.usage.resourceType.rule":"Verwendung gespeicherter Verfahren",
    "oifde.flow.usage.complexcellUsageTitle":"Verwendung grauer Regeln",
    "oifde.flow.usage.interfaceUsageTitle":"Wählen Sie eine neue Flow-Vorlage aus",
    "oifde.flow.usage.procedureUsageTitle":"Flowvorlage",
    "oifde.flow.usage.ruleUsageTitle":"Leerer Fluss",
    "oifde.flow.flowTypeSelect":"Fluss eingehender Anrufe",
    "oifde.flow.flowTemplate":"Fluss ausgehender Anrufe",
    "oifde.flow.add.blankFlow":"Anormaler Fluss",
    "oifde.flow.add.callInFlow":"Geben Sie den Inhalt manuell ein.",
    "oifde.flow.add.outBoundFlow":"Ressourcenvorlagenverwaltung",
    "oifde.flow.add.exceptionFlow":"Verwaltung des Kanaltyps",
    "oifde.template.setInputFlag":"Stilverwaltung",
    "oifde.template.template":"Name",
    "oifde.template.channelTypeMgr":"Kanal hinzufügen",
    "oifde.template.responseStyleMgr":"Kanal bearbeiten",
    "oifde.template.channelTypeName":"Name",
    "oifde.template.createChannelType":"Kanal hinzufügen",
    "oifde.template.editChannelType":"Kanal bearbeiten",
    "oifde.template.responseStyleName":"Name",
    "oifde.template.createResponseStyle":"Stil hinzufügen",
    "oifde.template.editResponseStyle":"Stil bearbeiten",
    "oifde.template.selectChannelType":"Wählen Sie einen Kanal.",
    "oifde.template.selectResponseStyle":"Wählen Sie einen Stil.",
    "oifde.template.selectLang":"Wählen Sie eine Sprache.",
    "oifde.template.editContent":"Vorlageninhalt hinzufügen",
    "oifde.template.setDefault":"Als Standard festlegen",
    "oifde.template.name":"Name",
    "oifde.template.type":"Typ",
    "oifde.template.desc":"Beschreibung",
    "oifde.template.content":"Inhalt",
    "oifde.template.createTime":"Erstellt",
    "oifde.template.updateTime":"Geändert",
    "oifde.template.detail":"Inhaltsdetails",
    "oifde.template.select":"Typ",
    "oifde.template.contentName":"Inhalt",
    "oifde.template.deleteContent":"Löschen",
    "oifde.template.addContent":"Vorlageninhalt hinzufügen",
    "oifde.template.tipContent":"Geben Sie den Vorlageninhalt ein.",
    "oifde.template.tipForVoice":"Geben Sie den absoluten Pfad zur Sprachdatei ein.",
    "oifde.template.tipForVideo":"Geben Sie den absoluten Pfad zur Videodatei ein.",
    "oifde.template.addLimit":"Es sind maximal drei Inhaltsdatensätze zulässig.",
    "oifde.template.deleteLimit":"Es ist mindestens ein Inhaltsdatensatz erforderlich.",
    "oifde.template.selectLanguage":"Wählen Sie eine Sprache aus. Wenn Sie eine Sprache löschen möchten, klicken Sie auf das Schließen-Symbol.",
    "oifde.template.addNewIntent":"Absicht hinzufügen",
    "oifde.template.setIntentTemplate":"Absichtsvorlage konfigurieren",
    "oifde.template.addTopNode":"Obersten Knoten hinzufügen",
    "oifde.template.selectTemplate":"Vorlage auswählen",
    "oifde.template.selectAction":"Auswählen",
    "oifde.template.actions.create":"Hinzufügen",
    "oifde.template.actions.delete":"Löschen",
    "oifde.template.actions.edit":"Bearbeiten",
    "oifde.template.actions.editbaseInfo":"Ressourcenvorlage bearbeiten",
    "oifde.template.actions.addbaseInfo":"Ressourcenvorlage hinzufügen",
    "oifde.template.actions.success":"Erfolg",
    "oifde.template.actions.fail":"Fehler",
    "oifde.template.actions.createSuccess":"Die Vorlage wurde erfolgreich erstellt!",
    "oifde.template.actions.saveSuccess":"Die Vorlage wurde erfolgreich gespeichert!",
    "oifde.template.actions.updateSuccess":"Die Vorlage wurde erfolgreich geändert!",
    "oifde.template.actions.deleteSuccess":"Die Vorlage wurde erfolgreich gelöscht!",
    "oifde.template.actions.cancelDelete":"Der Löschvorgang wurde abgebrochen.",
    "oifde.template.actions.deletePrompt":"Durch diesen Vorgang wird die Vorlage endgültig gelöscht. Möchten Sie wirklich fortfahren?",
    "oifde.template.actions.deleteChannelType":"Durch diesen Vorgang wird der Kanal dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
    "oifde.template.actions.deleteResponseStyle":"Durch diesen Vorgang wird der Stil dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
    "oifde.template.actions.prompt":"Bestätigen",
    "oifde.template.actions.confirm":"Ja",
    "oifde.template.actions.cancel":"Nein",
    "oifde.template.actions.append":"Hinzufügen",
    "oifde.template.update.templateName":"Name",
    "oifde.template.update.templateType":"Typ",
    "oifde.template.update.templateDesc":"Beschreibung",
    "oifde.template.update.intents":"Absichtsliste",
    "oifde.template.typeDetail.tts":"TTS",
  "oifde.template.typeDetail.sms":"SMS",
  "oifde.template.typeDetail.staticvoice":"Statische Stimme",
  "oifde.template.typeDetail.videovoice":"Videodatei",
  "oifde.template.typeDetail.multiMedia":"Multimedia",
  "oifde.template.typeDetail.cardTemplate":"Kartenvorlage",
  "oifde.template.language.chinese":"Chinesisch",
  "oifde.template.language.english":"Englisch",
  "oifde.template.language.spanish":"Spanisch",
  "oifde.template.voicename.cn_kefuman_common":"Gnadevoll",
  "oifde.template.voicename.cn_chengshuxing_common":"Reif",
  "oifde.template.voicename.cn_roumeiqian_common":"Weich",
  "oifde.template.voicename.cn_tianmeiru_common":"Süß",
  "oifde.template.voicename.cn_ziranzhen_common":"Natürlich",
  "oifde.template.defaultValueValidate.ttsSpeedValueDesc":"Wertebereich: [-500.500]",
  "oifde.template.defaultValueValidate.ttsVolumeValueDesc":"Wertebereich: [0.100]",
  "oifde.template.defaultValueValidate.ttsPitchValueDesc":"Wertebereich: [-500.500]",
  "oifde.template.validate.content":"Der Vorlageninhalt ist obligatorisch.",
  "oifde.template.validate.templateName":"Geben Sie einen Vorlagennamen ein.",
  "oifde.template.validate.templateType":"Wählen Sie einen Vorlagentyp aus.",
  "oifde.template.validate.templateNameLength":"Der Vorlagenname darf nicht mehr als 256 Zeichen enthalten.",
  "oifde.template.validate.templateDescLength":"Die Vorlagenbeschreibung darf nicht mehr als 1024 Zeichen enthalten.",
  "oifde.template.validate.templateContentLength":"Der Vorlageninhalt darf nicht mehr als 2048 Zeichen enthalten.",
  "oifde.template.validate.doubleNameCheck":"Der Vorlagenname ist bereits vorhanden.",
  "oifde.template.validate.intentDsp":"Geben Sie eine Vorlagenbeschreibung ein.",
  "oifde.template.validate.intentcode":"Geben Sie einen Absichtscode ein.",
  "oifde.template.validate.nameDup":"Der Name ist bereits vorhanden.",
  "oifde.template.validate.contentLengthError":"Der Vorlageninhalt muss 1 bis 4096 Zeichen enthalten.",
  "oifde.template.intentionInfo.addIntent":"Absicht hinzufügen",
  "oifde.template.intentionInfo.intentDsp":"Beschreibung",
  "oifde.template.intentionInfo.intentcode":"Code",
  "oifde.template.intentionInfo.intentList":"Liste",
  "oifde.template.message.intentListLength":"Die Absichtsliste darf maximal 4096 Zeichen enthalten.",
  "oifde.template.message.intentNameDuplicate":"Der Absichtsname ist bereits vorhanden.",
  "oifde.template.message.validateResult":"Informationen",
  "oifde.template.sysErrorTemplateTitle":"Anormale Ressourcenvorlage",
  "oifde.template.sysErrorTemplate":"Vorlage für ungewöhnliche Ressourcen bearbeiten",
  "oifde.template.contentDetail":"Einzelheiten",
  "oifde.template.aiccTemplateNullTips":"Der Vorlagentyp darf nicht leer sein.",
  "oifde.template.content.exist":"Der derzeit ausgewählte Vorlageninhalt wurde hinzugefügt",
  "oifde.template.selectResource":"Ressourcen auswählen",
  "oifde.template.message.searchResourceName":"Nach Ressourcennamen suchen",
  "oifde.template.title.ResourceName":"Ressourcenname",
  "oifde.template.title.ResourceType":"Ressourcentyp",
  "oifde.template.multipart.type.location":"Adresse",
  "oifde.rule.flowRule":"Graue Flussregel",
  "oifde.rule.kgRule":"Graue Wissensregel",
  "oifde.rule.name":"Name",
  "oifde.rule.beginNum":"Startnummer",
  "oifde.rule.endNum":"Endnummer",
  "oifde.rule.language":"Sprache",
  "oifde.rule.select":"Auswählen",
  "oifde.rule.number":"Anzahl",
  "oifde.rule.manage":"Verwalten",
  "oifde.rule.actions.error":"Beim Aufrufen der Schnittstelle ist ein Fehler aufgetreten.",
  "oifde.rule.actions.errorCode":"Fehlercode",
  "oifde.rule.actions.errorMessage":"Fehlermeldung",
  "oifde.rule.actions.create":"Hinzufügen",
  "oifde.rule.actions.delete":"Löschen",
  "oifde.rule.actions.edit":"Bearbeiten",
  "oifde.rule.actions.success":"Erfolg",
  "oifde.rule.actions.addSuccess":"Die Regel wurde erfolgreich hinzugefügt!",
  "oifde.rule.actions.updateSuccess":"Die Regel wurde erfolgreich geändert!",
  "oifde.rule.actions.deleteSuccess":"Die Regel wurde erfolgreich gelöscht!",
  "oifde.rule.actions.editbaseInfo":"Grundlegende Informationen bearbeiten",
  "oifde.rule.actions.addbaseInfo":"Erstellen grundlegender Informationen",
  "oifde.rule.actions.confirm":"Bestätigen",
  "oifde.rule.actions.sure":"Ja",
  "oifde.rule.actions.cancel":"Nein",
  "oifde.rule.actions.cancelDelete":"Der Löschvorgang wurde abgebrochen.",
  "oifde.rule.actions.deleteConfirm":"Durch diesen Vorgang wird die Regel dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
  "oifde.rule.actions.deleteFailed":"Löschung fehlgeschlagen! Überprüfen Sie, ob die Regel einem Freigabe-Flow zugeordnet ist. Brechen Sie die Freigabe zuerst ab.",
  "oifde.rule.actions.viewBoundFlow":"Gebundene Prozesse anzeigen",
  "oifde.rule.rules.ruleNameValidator":"Der Regelname ist bereits vorhanden.",
  // "  ""oifde.rule.rules.beginNumValidator"":""Die Startnummer darf nur Buchstaben, Ziffern, Leerzeichen und die folgenden Sonderzeichen enthalten: _ – \' :,
  // Die Endnummer darf nur Buchstaben, Ziffern, Leerzeichen und die folgenden Sonderzeichen enthalten: _ – \' :,"","
// "oifde.rule.rules.endNumValidator":"Die Endnummer darf nur Buchstaben, Ziffern, Leerzeichen und die folgenden Sonderzeichen enthalten: _ – \' :",
    "oifde.rule.rules.beginNumLengthValidator":"Die Endnummer muss größer oder gleich der Startnummer sein.",
    "oifde.rule.update.ruleName":"Regelname",
    "oifde.rule.update.beginNum":"Startnummer",
    "oifde.rule.update.newRuleName":"Geben Sie einen Regelnamen ein.",
    "oifde.rule.update.newBeginNum":"Geben Sie eine Startnummer ein.",
    "oifde.rule.update.newEndNum":"Geben Sie eine Endnummer ein.",
    "oifde.rule.update.endNum":"Endnummer",
    "oifde.rule.message.add_name_list":"Blockliste/Vertrauensliste hinzufügen",
    "oifde.rule.message.name_list_info":"Details zur Blockliste/Vertrauensliste",
    "oifde.rule.message.succeed_save":"Die Blocklist/Vertrauensliste wurde erfolgreich gespeichert!",
    "oifde.rule.message.fail_save":"Blocklist/Vertrauensliste konnte nicht gespeichert werden!",
    "oifde.rule.message.failed_query":"Abfrage konnte nicht ausgeführt werden!",
    "oifde.rule.message.select_prompt":"Wählen Sie mindestens einen Datensatz aus.",
    "oifde.rule.message.confirm_name_list":"Möchten Sie die Blocklist/Vertrauenslisten wirklich löschen?",
    "oifde.rule.message.succeed_delete":"Die Blocklist/Vertrauensliste wurde erfolgreich gelöscht!",
    "oifde.rule.message.fail_delete":"Blocklist/Vertrauensliste konnte nicht gelöscht werden!",
    "oifde.rule.message.mgr_name_list":"Blocklist/Trustlist verwalten – ",
    "oifde.rule.message.add_user_ids":"Abonnentennummer hinzufügen",
    "oifde.rule.message.batch_input_prompt":"Drücken Sie nach jedem Datensatz die Eingabetaste, um ihn hinzuzufügen. Jeder Datensatz kann 4 bis 64 Zeichen enthalten.",
    "oifde.rule.message.user_number":"Teilnehmernummer",
    "oifde.rule.message.user_ids_number":"Der Blocklist/Vertrauensliste können maximal 5000 Teilnehmernummern hinzugefügt werden.",
    "oifde.rule.message.user_ids_number_once":"Der Blocklist/Vertrauensliste können maximal 100 Teilnehmernummern in einem Batch hinzugefügt werden.",
    "oifde.rule.message.user_id_not_exceed":"Jede Teilnehmernummer kann 4 bis 64 Zeichen enthalten.",
    "oifde.rule.message.save_user_ids_success":"Die Teilnehmernummern wurden erfolgreich gespeichert!",
    "oifde.rule.message.save_user_ids_fail":"Die Teilnehmernummern konnten nicht gespeichert werden!",
    "oifde.rule.message.confirm_delete_user_ids":"Möchten Sie die Teilnehmernummern wirklich löschen?",
    "oifde.rule.message.succeed_delete_user_ids":"Die Teilnehmernummern wurden erfolgreich gelöscht!",
    "oifde.rule.message.failed_delete_user_ids":"Die Teilnehmernummern konnten nicht gelöscht werden!",
    "oifde.rule.message.import_user_ids":"Abonnentennummer importieren",
    "oifde.rule.message.import_words_hint_text":"Textdateien können importiert werden (*.txt). Das Dateicodierungsformat ist UTF-8.",
    "oifde.rule.message.overwrite_import":"Vorhandene Konfiguration überschreiben",
    "oifde.rule.message.success_import":"Der Import war erfolgreich.",
    "oifde.rule.message.failed_import_file":"Datei konnte nicht importiert werden!",
    "oifde.accesscode.usingRecord":"Paketerfassung",
    "oifde.accesscode.messageTips":"Bitte nehmen Sie sich einen Moment Zeit, um Feedback zu den Verbesserungen zu geben, die Sie gerne sehen würden.",
    "oifde.accesscode.accessCode":"Chatbot-Zugangscode",
    "oifde.accesscode.accessCodeSearch":"Chatbot-Name oder Zugangscode",
    "oifde.accesscode.accessCodeDesc":"Beschreibung",
    "oifde.accesscode.description":"Zugangscode-ID",
    "oifde.accesscode.descPlaceholder":"Diese Beschreibung wird als Flow-Beschreibung verwendet, wenn der Flow als Konfiguration des angerufenen Teilnehmers ausgewählt wird. Der Wert muss global einmalig sein.",
    "oifde.accesscode.flowCode":"Flusscode",
    "oifde.accesscode.flowName":"Flussname",
    "oifde.accesscode.domainId":"Domänen-ID",
    "oifde.accesscode.dialogType":"Dialogtyp",
    "oifde.accesscode.callingnumber":"Anrufnummer",
    "oifde.accesscode.callednumber":"Angerufene Nummer:",
    "oifde.accesscode.processTimes":"Anzahl der Anrufe, die nur vom Agenten bearbeitet wurden",
    "oifde.accesscode.startcall":"Anruf starten",
    "oifde.accesscode.sendprompt":"Drücken Sie die Eingabetaste, um Ihre Nachricht zu senden.",
    "oifde.accesscode.callingnumberinput":"Geben Sie die Anrufnummer ein.",
    "oifde.accesscode.isCheck":"Rechtschreibprüfung",
    "oifde.accesscode.isCheckHelp":"Rechtschreibfehler in Kundenaussagen werden automatisch korrigiert, bevor die Absicht erkannt wird.",
    "oifde.accesscode.sendContent":"Senden",
    "oifde.accesscode.trl":"Geben Sie von rechts nach links ein",
    "oifde.accesscode.actions.edit":"Bearbeiten",
    "oifde.accesscode.actions.testCalledNum":"Testanruf",
    "oifde.accesscode.actions.delete":"Löschen",
    "oifde.accesscode.actions.editbaseInfo":"Chatbot ändern",
    "oifde.accesscode.actions.addbaseInfo":"Chatbot hinzufügen",
    "oifde.accesscode.actions.success":"Erfolg",
    "oifde.accesscode.actions.fail":"Fehler",
    "oifde.accesscode.actions.createSuccess":"Erstellung erfolgreich.",
    "oifde.accesscode.actions.updateSuccess":"Änderung erfolgreich.",
    "oifde.accesscode.actions.deleteSuccess":"Löschung erfolgreich.",
    "oifde.accesscode.actions.cancelDelete":"Der Löschvorgang wurde abgebrochen.",
    "oifde.accesscode.actions.deletePrompt":"Dieser Vorgang löscht den Chatbot dauerhaft. Möchten Sie wirklich fortfahren?",
    "oifde.accesscode.actions.prompt":"Bestätigen",
    "oifde.accesscode.actions.confirm":"Ja",
    "oifde.accesscode.actions.cancel":"Nein",
    "oifde.accesscode.actions.flowChatbot":"Chatbot für Flusstests",
    "oifde.accesscode.actions.callforwarding":"Anrufweiterleitung",
    "oifde.accesscode.actions.voicefile":"Sprachdatei:",
    "oifde.accesscode.actions.videofile":"Videodatei:",
    "oifde.accesscode.dialogTypeDetail.voiceNavigation":"Sprachnavigation",
    "oifde.accesscode.dialogTypeDetail.smartOutboundCall":"Intelligenter ausgehender Anruf",
    "oifde.accesscode.dialogTypeDetail.chatbot":"Chatbot",
    "oifde.accesscode.dialogTypeDetail.ivrProcess":"IVR-Fluss",
    "oifde.accesscode.validate.accessCode":"Geben Sie einen Flow-Zugangscode ein.",
    "oifde.accesscode.validate.accessCodeCharReg":"Es sind nur Buchstaben und Ziffern zulässig.",
    "oifde.accesscode.validate.doubleAccessCodeCheck":"Der Zugangscode ist bereits vorhanden.",
    "oifde.accesscode.validate.dialogType":"Wählen Sie einen Dialogtyp aus.",
    "oifde.accesscode.validate.flowCode":"Wählen Sie einen Flusscode aus.",
    "oifde.accesscode.validate.accessCodeLength":"Der Zugangscode darf maximal 20 Zeichen enthalten.",
    "oifde.accesscode.validate.flowCodeLength":"Der Flusscode darf maximal 64 Zeichen enthalten.",
    "oifde.accesscode.validate.descriptionLengthValidator":"Die Beschreibung des Zugangscodes darf maximal 512 Zeichen enthalten.",
    "oifde.accesscode.validate.domainIdMax":"Die Domänen-ID darf 2147483647 nicht überschreiten.",
    "oifde.accesscode.validate.domainIdCheck":"Die Domänen-ID ist keine gültige Nummer.",
    "oifde.accesscode.more":"Mehr",
    "oifde.accesscode.bindDomain":"Domäne binden",
    "oifde.accesscode.bindFaqGroup":"FAQ-Gruppe binden",
    "oifde.accesscode.bindKg":"Wissensdiagramm binden",
    "oifde.accesscode.robotName":"Chatbot-Name",
    "oifde.accesscode.domain":"Domäne",
    "oifde.accesscode.faqGroup":"FAQ-Gruppe",
    "oifde.accesscode.knowledgeGraph":"Wissensdiagramm",
    "oifde.accesscode.setTrace":"Trace-Einstellungen",
    "oifde.accesscode.callingNumberTrace":"Anrufnummernverfolgung:",
    "oifde.accesscode.traceOpen":"Aktivieren",
    "oifde.accesscode.traceClose":"Deaktivieren",
    "oifde.accesscode.hideTraceSet":"Kollabieren",
    "oifde.accesscode.setTraceSuccess":"Die Einstellungen wurden erfolgreich angewendet!",
    "oifde.accesscode.recordState.disable":"Deaktivieren",
    "oifde.accesscode.recordState.enable":"Aktivieren",
    "oifde.accesscode.accessCodeSubscribed":"Der Chatbot-Zugangscode wird verwendet.",
    "oifde.accesscode.bindScene":"Referenzen anzeigen",
    "oifde.accesscode.chatBot.search":"Suche",
    "oifde.accesscode.chatBot.reset":"Zurücksetzen",
    "oifde.accesscode.searchScene":"Wählen Sie ein Szenario aus.",
    "oifde.accesscode.sceneName":"Szenario",
    "oifde.accesscode.subName":"Referenzname",
    "oifde.accesscode.createTime":"Referenzzeit",
    "oifde.accesscode.chatBot.chatChanel":"Multimediakanal",
    "oifde.accesscode.chatBot.agentAssistant":"Agentenassistent",
    "oifde.accesscode.chatBot.intelligentOB":"IntelligentOB",
    "oifde.accesscode.chatBot.intelligentTrain":"Intelligenter Zug",
    "oifde.toc.statusCode.SERVER_BUSY":"Das System ist derzeit ausgelastet. Versuchen Sie es später.",
    "oifde.toc.statusCode.NEED_LOGIN":"Sie haben sich schon lange nicht mehr angemeldet oder Vorgänge ausgeführt. Melden Sie sich erneut an.",
    "oifde.toc.statusCode.ACCOUNT_INCONSISTENT":"Sie haben sich mit einem anderen Konto am lokalen Computer angemeldet. Melden Sie sich erneut an, um diesen Dienst weiterhin zu nutzen.",
    "oifde.toc.statusCode.ACCOUNT_SWITCHED":"Das Anmeldekonto wurde geändert. Diese Seite wird aktualisiert, um das neueste Anmeldekonto anzuzeigen.",
    "oifde.toc.statusCode.INCORRECT_VERIFYCODE":"Der Verifizierungscode ist falsch. Geben Sie die richtige ein.",
    "oifde.toc.statusCode.INVALID_JSON":"Die Anfrage ist nicht im JSON-Format.",
    "oifde.toc.statusCode.HTTPS_REQUIRED":"Es wird nur HTTPS unterstützt.",
    "oifde.toc.statusCode.EMPTY_ACCOUNT":"Die Kontonummer ist leer.",
    "oifde.toc.statusCode.INVALID_ACCOUNT":"Authentifizierung fehlgeschlagen! Melden Sie sich erneut an.",
    "oifde.toc.statusCode.INSUFFICIENT_PERMISSION":"Sie haben nicht die erforderliche Berechtigung. Wenden Sie sich an den Administrator.",
    "oifde.toc.statusCode.VERIFYCODE_EXPIRED":"Der Verifizierungscode ist abgelaufen. Versuchen Sie es später erneut.",
    "oifde.toc.statusCode.ACCOUNT_LOCKED":"Das Konto wurde vorübergehend gesperrt. Versuchen Sie es später erneut oder wenden Sie sich an den Administrator, um das Konto zu entsperren.",
    "oifde.toc.statusCode.ACCOUNT_CHANGED":"Das Konto wurde geändert. Melden Sie sich erneut an.",
    "oifde.toc.statusCode.ACCOUNT_DISABLED":"Das Konto wurde gesperrt. Wenden Sie sich an den Administrator.",
    "oifde.toc.statusCode.UNLOCK_REQUIRED":"Das Konto wurde vorübergehend gesperrt. Entsperren Sie das Konto und versuchen Sie es erneut.",
    "oifde.toc.statusCode.ADMIN_DISABLED":"Die Verwaltungsfunktion wurde deaktiviert. Verwenden Sie ein allgemeines Konto, um sich im System anzumelden.",
    "oifde.toc.statusCode.SESSION_OVERFLOW":"Die Anzahl der Online-Nutzer hat die Obergrenze erreicht. Versuchen Sie es später erneut.",
    "oifde.toc.statusCode.SESSION_EXPIRED":"Die aktuelle Anmeldung ist ungültig. Melden Sie sich erneut an.",
    "oifde.toc.statusCode.SESSION_DUPLICATED":"Sie sind bereits angemeldet. Um sich erneut anzumelden, müssen Sie sich zuerst abmelden.",
    "oifde.toc.statusCode.INCORRECT_TENANT_SPACE_ID":"Die Raum-ID des Mieters ist falsch.",
    "oifde.toc.statusCode.INCORRECT_TENANT_SPACE_NAME":"Der Name des Mieterbereichs ist falsch.",
    "oifde.toc.statusCode.INCORRECT_ACCOUNT":"Das Konto ist falsch.",
    "oifde.toc.statusCode.INCORRECT_PASSWORD":"Das Kennwort entspricht nicht den Anforderungen.",
    "oifde.toc.statusCode.INCORRECT_GENDER":"Das Geschlecht ist falsch.",
    "oifde.toc.statusCode.INCORRECT_OWNER":"Der Besitzer hat unrecht.",
    "oifde.toc.statusCode.INCORRECT_TITLE":"Die Länge darf 8 Zeichen nicht überschreiten.",
    "oifde.toc.statusCode.INCORRECT_TELEPHONE":"Die Telefonnummer ist falsch.",
    "oifde.toc.statusCode.INCORRECT_EMAIL":"Die E-Mail-Adresse ist falsch.",
    "oifde.toc.statusCode.INCORRECT_ROLE_NAME":"Der Rollenname ist falsch.",
    "oifde.toc.statusCode.EMPTY_TENANT_SPACE":"Der Mieterraum darf nicht leer sein.",
    "oifde.toc.statusCode.ACCOUNT_DUPLICATED":"Das Konto ist bereits vorhanden.",
    "oifde.toc.statusCode.ROLE_DUPLICATED":"Die Rolle ist bereits vorhanden.",
    "oifde.toc.statusCode.TENANT_SPACE_DUPLICATED":"Der Mieterplatz ist bereits vorhanden.",
    "oifde.toc.statusCode.ACCOUNT_NOT_FOUND":"Das Konto existiert nicht.",
    "oifde.toc.statusCode.ROLE_NOT_FOUND":"Die Rolle existiert nicht.",
    "oifde.toc.statusCode.TENANT_SPACE_NOT_FOUND":"Der Mieterraum existiert nicht.",
    "oifde.toc.statusCode.ACCOUNT_FORBIDDEN":"Operationen auf diesem Konto sind verboten.",
    "oifde.toc.statusCode.ROLE_FORBIDDEN":"Operationen in dieser Rolle sind verboten.",
    "oifde.toc.statusCode.TENANT_SPACE_FORBIDDEN":"Operationen auf dieser Mietfläche sind verboten.",
    // "oifde.toc.statusCode.INCORRECT_ACCOUNT_INPUT":"Das Konto kann 6 bis 32 Zeichen enthalten, bestehend aus Buchstaben, Ziffern und den folgenden Sonderzeichen:. {\'@\'}: -",
    "oifde.toc.statusCode.INCORRECT_PASSWORD_UPDATE":"Das Kennwort darf das gleiche Zeichen nicht mehr als dreimal hintereinander enthalten. Außerdem können die letzten drei historischen Passwörter nicht wiederverwendet werden. Das Kennwort kann 8 bis 20 Zeichen enthalten, bestehend aus Ziffern, Kleinbuchstaben, Großbuchstaben und den folgenden speziellen characters: ~ `! {'@'} # {'$'} % ^ * ( ) - +: {'|'}",
    "oifde.toc.statusCode.COMMON_ERROR":"Serverfehler.",
    "oifde.toc.statusCode.SERVICE_UNAVAILABLE":"Der Dienst ist derzeit nicht verfügbar.",
    "oifde.toc.statusCode.AUTHORIZE_FAILED":"Validierung fehlgeschlagen!",
    "oifde.toc.statusCode.TRANSIT_FAILED":"Die Anfrage hat das Zeitlimit überschritten.",
    "oifde.toc.statusCode.TRANSIT_ERROR":"Die Anfrage wurde erfolgreich gesendet, aber der Peer hat einen Fehler zurückgegeben.",
    "oifde.toc.statusCode.RESPONSE_FAILED":"Der Server konnte die Antwort nicht verarbeiten!",
    "oifde.toc.statusCode.REFRESH_TIMEOUT":"Es wurde schon lange keine Operation mehr durchgeführt. Melden Sie sich erneut an.",
    "oifde.logparam.logParamMgr":"Flume-Log-Parametereinstellungen",
    "oifde.logparam.logParam":"Dynamische Parameter",
    "oifde.logparam.logName":"Parametername",
    "oifde.logparam.source":"Parameterquelle",
    "oifde.logparam.sourceName":"Parameterquellenname",
    "oifde.logparam.transFlag":"Wert konvertieren",
    "oifde.logparam.sourceValue":"Quellwert",
    "oifde.logparam.targetValue":"Zielwert",
    "oifde.logparam.actions.edit":"Bearbeiten",
    "oifde.logparam.actions.transDetail":"Wert konvertieren",
    "oifde.logparam.actions.delete":"Löschen",
    "oifde.logparam.actions.editLogParamBaseInfo":"Dynamischen Parameter bearbeiten",
    "oifde.logparam.actions.addLogParamBaseInfo":"Dynamischen Parameter hinzufügen",
    "oifde.logparam.actions.success":"Erfolg",
    "oifde.logparam.actions.createSuccess":"Erstellung erfolgreich.",
    "oifde.logparam.actions.updateSuccess":"Änderung erfolgreich.",
    "oifde.logparam.actions.deleteSuccess":"Löschung erfolgreich.",
    "oifde.logparam.actions.cancelDelete":"Der Löschvorgang wurde abgebrochen.",
    "oifde.logparam.actions.deletePrompt":"Durch diesen Vorgang wird der dynamische Parameter dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
    "oifde.logparam.actions.prompt":"Bestätigen",
    "oifde.logparam.actions.confirm":"Ja",
    "oifde.logparam.actions.cancel":"Nein",
    "oifde.logparam.actions.add":"Hinzufügen",
    "oifde.logparam.sourceDetail.IVRRequestInformation":"IVR-Anforderung",
    "oifde.logparam.sourceDetail.IVRResponseInformation":"IVR-Reaktion",
    "oifde.logparam.sourceDetail.TOCRequestInformation":"TOC-Anforderung",
    "oifde.logparam.sourceDetail.TOCResponseInformation":"TOC-Antwort",
    "oifde.logparam.sourceDetail.InterfaceRequestInformation":"Schnittstellenanforderung",
    "oifde.logparam.sourceDetail.InterfaceResponseInformation":"Schnittstellenreaktion",
    "oifde.logparam.transFlagDetail.YES":"Ja",
    "oifde.logparam.transFlagDetail.NO":"Nein",
    "oifde.logparam.validate.logName":"Geben Sie einen Parameternamen ein.",
    "oifde.logparam.validate.source":"Wählen Sie eine Parameterquelle aus.",
    "oifde.logparam.validate.sourceName":"Geben Sie einen Parameterquellennamen ein.",
    "oifde.logparam.validate.sourceValue":"Geben Sie einen Quellwert ein.",
    "oifde.logparam.validate.targetValue":"Geben Sie einen Zielwert ein.",
    "oifde.logparam.validate.doublelogNameCheck":"Der dynamische Parametername ist bereits vorhanden.",
    "oifde.logparam.validate.logNameLength":"Der dynamische Parametername darf maximal 64 Zeichen enthalten.",
    "oifde.logparam.validate.sourceNameLength":"Der Name der Parameterquelle darf maximal 64 Zeichen enthalten.",
    "oifde.logparam.validate.sourceValueLength":"Maximale Länge: 64",
    "oifde.logparam.validate.targetValueLength":"Maximale Länge: 64",
    "oifde.logparam.message.logParamNotNull":"Wählen Sie aus, ob der Wert konvertiert werden soll.",
    "oifde.logparam.message.validateFailed":"Validierung fehlgeschlagen!",
    "oifde.logparam.message.validateResult":"Informationen",
    "oifde.tenantinfo.tenanInfoMgr":"Statische Parameter",
    "oifde.tenantinfo.serviceName":"Name",
    "oifde.tenantinfo.serviceValue":"Wert",
    "oifde.tenantinfo.actions.edit":"Bearbeiten",
    "oifde.tenantinfo.actions.editTenantBaseInfo":"Statischen Parameter bearbeiten",
    "oifde.tenantinfo.actions.success":"Erfolg",
    "oifde.tenantinfo.actions.updateSuccess":"Änderung erfolgreich.",
    "oifde.tenantinfo.validate.serviceValue":"Geben Sie einen Wert ein.",
    "oifde.tenantinfo.validate.serviceValueLength":"Der Wert darf maximal 64 Zeichen enthalten.",
    "oifde.variable.innerTitle":"Integrierte Variable",
    "oifde.variable.customTitle":"Benutzerdefinierte Variable",
    "oifde.variable.variableName":"Name der Variablen",
    "oifde.variable.variableDesc":"Beschreibung",
    "oifde.variable.variableType":"Datentyp",
    "oifde.variable.scope":"Umfang",
    "oifde.variable.defaultValue":"Standardwert",
    "oifde.variable.encryptType":"Verschlüsselungstyp",
    "oifde.variable.timezoneDesc":"Verwenden Sie diese Variable, um den Versatz der Zeitzone festzulegen, in der der Mieter wohnt. Der Wert liegt zwischen -12 und +12.",
    "oifde.variable.channeltypeDesc":"Verwenden Sie diese Variable, um den Kanal des Mandanten festzulegen.",
    "oifde.variable.responsestyleDesc":"Verwenden Sie diese Variable, um den Stil des Mieters festzulegen.",
    "oifde.variable.langDesc":"Verwenden Sie diese Variable, um die Sprache des Mandanten festzulegen.",
    "oifde.variable.ttsSpeedDesc":"Verwenden Sie diese Variable, um die TTS-Sprachgeschwindigkeit des Mandanten einzustellen.",
    "oifde.variable.ttsVolumeDesc":"Verwenden Sie diese Variable, um das TTS-Volumen des Mandanten festzulegen.",
    "oifde.variable.ttsPitchDesc":"Verwenden Sie diese Variable, um die TTS-Grundfrequenz des Mieters einzustellen.",
    "oifde.variable.voiceNameDesc":"Verwenden Sie diese Variable, um den TTS-Lautsprecher des Mandanten festzulegen.",
    "oifde.variable.variableTypeDetail.int":"Ganzzahl",
    "oifde.variable.variableTypeDetail.string":"Charakter",
    "oifde.variable.variableTypeDetail.long":"Lange Ganzzahl",
    "oifde.variable.variableTypeDetail.float":"Gleitkommazahl",
    "oifde.variable.variableTypeDetail.list":"Liste",
    "oifde.variable.variableTypeDetail.object":"Objekt",
    "oifde.variable.scopes.input":"Eingabeparameter",
    "oifde.variable.scopes.output":"Ausgabeparameter",
    "oifde.variable.scopes.local":"Lokal",
    "oifde.variable.actions.edit":"Bearbeiten",
    "oifde.variable.actions.delete":"Löschen",
    "oifde.variable.actions.editVariableBaseInfo":"Variable bearbeiten",
    "oifde.variable.actions.addVariableBaseInfo":"Variable hinzufügen",
    "oifde.variable.actions.success":"Erfolg",
    "oifde.variable.actions.createSuccess":"Erstellung erfolgreich!",
    "oifde.variable.actions.updateSuccess":"Änderung erfolgreich!",
    "oifde.variable.actions.deleteSuccess":"Löschung erfolgreich!",
    "oifde.variable.actions.cancelDelete":"Der Löschvorgang wird abgebrochen",
    "oifde.variable.actions.deletePrompt":"Dieser Vorgang löscht die Variable dauerhaft. Möchten Sie wirklich fortfahren?",
    "oifde.variable.actions.prompt":"Bestätigen",
    "oifde.variable.actions.confirm":"Ja",
    "oifde.variable.actions.cancel":"Nein",
    "oifde.variable.actions.add":"Hinzufügen",
    "oifde.variable.validate.variableName":"Geben Sie einen Variablennamen ein.",
    "oifde.variable.validate.variableNameRange":"Nur Buchstaben, Ziffern und Unterstriche (_) sind zulässig.",
    "oifde.variable.validate.variableType":"Wählen Sie einen Variablentyp aus.",
    "oifde.variable.validate.scope":"Wählen Sie den Umfang aus.",
    "oifde.variable.validate.doubleVariableNameCheck":"Der Variablenname ist bereits vorhanden.",
    "oifde.variable.validate.intValueCheck":"Der Standardwert für Ganzzahldaten ist falsch. Er reicht von -2147483648 bis +2147483647.",
    "oifde.variable.validate.longValueCheck":"Der Standardwert für Long-Integer-Daten ist ungültig. Er reicht von -9223372036854775808 bis 9223372036854775807",
    "oifde.variable.validate.floatValueCheck":"Der Standardwert für Gleitkommadaten hat ein falsches Format. Der Wert muss im Format 100 oder 99.99 vorliegen.",
    // "oifde.variable.validate.listValueCheck":"Das Format des Standardlistendatenwerts ist falsch. Der Wert muss im Format [1,2,3] oder [a","b","c“] vorliegen und kann [].", sein",
// "oifde.variable.validate.objectValueCheck":"Das Format des Vorgabe-Objektdatenwerts ist falsch. Der Wert muss im Format {\'{\'}key1“:"value1","key2“:"value2“{\'}\'}, sein, und der Wert kann {\'{\'}{\'}\'}.", sein",
"oifde.variable.validate.variableNameLength":"Der Variablenname umfasst mehr als 1000 Zeichen.",
    "oifde.variable.validate.variableDescLength":"Die Variablenbeschreibung umfasst mehr als 1000 Zeichen.",
    "oifde.variable.validate.defaultValueLength":"Der Standardwert enthält mehr als 1024 Zeichen.",
    "oifde.variable.encryptFlag.zero":"Keine Verschlüsselung",
    "oifde.variable.encryptFlag.one":"Zur Speicherung verschlüsselt und beim Abrufen entschlüsselt",
    "oifde.variable.encryptFlag.two":"Für Speicherung und Abruf verschlüsselt",
    "oifde.diagram.NMS_PleaseSelect":"-Auswahl-",
    "oifde.diagram.NMS_ParamName":"Parameter",
    "oifde.diagram.NMS_ParamValue":"Wert",
    "oifde.diagram.NMS_DiagramProperties":"Flussattribut",
    "oifde.diagram.NMS_BasicProperties":"Basisattribut",
    "oifde.diagram.NMS_DiagramCode":"Flusscode",
    "oifde.diagram.NMS_DiagramName":"Flussname",
    "oifde.diagram.NMS_ScenarioType":"Szenariotyp",
    "oifde.diagram.NMS_Desciption":"Beschreibung",
    "oifde.diagram.NMS_NodeCode":"Knotencode",
    "oifde.diagram.NMS_NodeName":"Knotenname",
    "oifde.diagram.NMS_AdvanceProperties":"Erweitertes Attribut",
    "oifde.diagram.NMS_ServiceName":"Dienstname",
    "oifde.diagram.NMS_ServiceParams":"Dienstparameter",
    "oifde.diagram.NMS_ServiceDesciption":"Servicebeschreibung",
    "oifde.diagram.add":"Hinzufügen",
    "oifde.diagram.remove":"Löschen",
    "oifde.diagram.NMS_BranchProperties":"Zweigstellenattribut",
    "oifde.diagram.NMS_BranchCondition":"Abzweigbedingung",
    "oifde.diagram.NMS_BranchService":"Service-Zweigstelle",
    "oifde.diagram.NMS_NodeProperties":"Knotenattribut",
    "oifde.diagram.NMS_FlowVariable":"Flussvariable",
    "oifde.diagram.NMS_FlowVariableName":"Name der Variablen",
    "oifde.diagram.NMS_FlowVariableDesc":"Beschreibung",
    "oifde.diagram.NMS_FlowVariableType":"Datentyp",
    "oifde.diagram.NMS_FlowVariableDefaultValue":"Standardwert",
    "oifde.diagram.NMS_FlowVariableEncryptType":"Verschlüsselungstyp",
    "oifde.diagram.NMS_FlowVariableEncryptTypeWarn":"* Beachten Sie, dass diese Variable im Klartext gespeichert wird, wenn der Verschlüsselungstyp nicht verschlüsselt ist. Weisen Sie dieser Variablen keine sensiblen Daten zu.",
    "oifde.diagram.NMS_FlowVariableEncryptTypeTips":"* Wenn der Verschlüsselungstyp Verschlüsselung ist, wird empfohlen, einer Variablen einen Wert zuzuweisen, um Informationslecks zu vermeiden.",
    "oifde.diagram.NMS_FlowVariableOption":"Betrieb",
    "oifde.diagram.NMS_AddVariable":"Variable hinzufügen",
    "oifde.diagram.NMS_ModifyVariable":"Variable ändern",
    "oifde.diagram.variableTypeDetail.int":"Ganzzahl",
    "oifde.diagram.variableTypeDetail.string":"Charakter",
    "oifde.diagram.variableTypeDetail.long":"Lange Ganzzahl",
    "oifde.diagram.variableTypeDetail.float":"Gleitkommazahl",
    "oifde.diagram.variableTypeDetail.list":"Tisch",
    "oifde.diagram.variableTypeDetail.object":"Objekt",
    "oifde.diagram.error.NMS_flowCodeError":"Der Flusscode darf nicht leer sein oder mehr als 64 Zeichen umfassen.",
    "oifde.diagram.error.NMS_flowNameError":"Der Flow-Name darf nicht leer sein oder mehr als 1000 Zeichen umfassen.",
    "oifde.diagram.error.NMS_scenarioTypeError":"Dieser Szenariotyp darf nicht leer sein.",
    "oifde.diagram.error.NMS_flowDescError":"Die Flussbeschreibung darf maximal 1000 Zeichen enthalten.",
    "oifde.diagram.error.NMS_VariableNameError":"Der Variablenname darf maximal 1000 Zeichen enthalten.",
    "oifde.diagram.error.NMS_VariableDescError":"Die Variablenbeschreibung darf maximal 1000 Zeichen enthalten.",
    "oifde.diagram.error.NMS_VariableDefaultValueError":"Der Standardwert der Variablen darf maximal 1024 Zeichen enthalten.",
    "oifde.diagram.error.NMS_NodeCodeError":"Der Knotencode darf nicht leer sein oder mehr als 64 Zeichen umfassen.",
    "oifde.diagram.error.NMS_NodeNameError":"Der Knotenname darf nicht leer sein oder mehr als 1000 Zeichen umfassen.",
    "oifde.diagram.error.NMS_NodeDescError":"Die Knotenbeschreibung darf maximal 1000 Zeichen enthalten.",
    "oifde.diagram.error.NMS_actionParamNameError":"Der Parametername darf maximal {0} Zeichen enthalten.",
    "oifde.diagram.error.NMS_actionParamValueError":"Der Parameterwert darf maximal 4096 Zeichen enthalten.",
    "oifde.diagram.error.NMS_actionParamDescError":"Die Parameterbeschreibung darf maximal 500 Zeichen enthalten.",
    "oifde.diagram.error.NMS_transitToStartNodeError":"Es konnte nicht zum Startknoten gesprungen werden!",
    "oifde.diagram.error.NMS_endNodetransitToError":"Es konnte nicht vom Endknoten zu einem anderen Knoten gesprungen werden!",
    "oifde.diagram.error.NMS_startNodeNotFind":"Der Startknoten wurde nicht gefunden.",
    "oifde.diagram.error.NMS_startNodeNotUnique":"Der Startknoten ist nicht eindeutig.",
    "oifde.diagram.error.NMS_startNodeNotBranchError":"Der Startknoten kann nur einen Zweig haben.",
    "oifde.diagram.error.NMS_endNodeNotFind":"Der Endknoten wurde nicht gefunden.",
    "oifde.diagram.error.NMS_branchError":"Der Zweig muss über den Start- und Endknoten verfügen.",
    "oifde.diagram.error.NMS_nullBranchNotNuique":"Ein Knoten kann nur einen Standardzweig haben.",
    "oifde.diagram.error.NMS_checkDefaultNextNodeForView":"Das Diagrammelement des Ansichtstyps kann nicht mehr als einen Zweig haben.",
    "oifde.diagram.error.NMS_actionParamValueEmptyError":"Der Parameterwert darf nicht leer sein.",
    "oifde.diagram.error.NMS_nmsFlowError":"Der Flow konnte nicht analysiert werden, da der nmsFlow-Knoten nicht gefunden werden kann!",
    "oifde.diagram.error.NMS_rootError":"Der Flow konnte nicht analysiert werden, da der Stammknoten nicht gefunden werden kann!",
    "oifde.diagram.error.NMS_analiseXmlFailed":"Die Flow-Leinwand konnte nicht analysiert werden!",
    "oifde.diagram.complexCell.name":"Name des Diagrammelements",
    "oifde.diagram.complexCell.variable":"Diagrammelementvariable",
    "oifde.diagram.speechRecognition.recognitionParam":"Erkennungsparameter",
    "oifde.diagram.speechRecognition.resultParam":"Ergebnisverarbeitung",
    "oifde.diagram.varType":"Variablentyp",
    "oifde.diagram.usedVar":"Verwendete Variable",
    "oifde.diagram.usedCell":"Diagrammelement",
    "oifde.diagram.fastGetCell":"Diagrammelement suchen",
    "oifde.diagram.fastByVar":"Variable suchen",
    "oifde.diagram.aimCell":"Zieldiagrammelement",
    "oifde.diagram.saveAndPublish":"Speichern und veröffentlichen",
    "oifde.diagram.NMS_VoiceTemplate":"Antwortvorlage",
    "oifde.navbar.tenantId":"Aktuelle Mandanten-ID",
    "oifde.navbar.tenantName":"Aktueller Mieter",
    "oifde.navbar.current":"Aktuelle Anmeldung",
    "oifde.navbar.success":"Letzte erfolgreiche Anmeldung",
    "oifde.navbar.failed":"Letzte fehlgeschlagene Anmeldung",
    "oifde.navbar.logOut":"Abmelden",
    "oifde.navbar.switchUser":"Benutzer wechseln",
    "oifde.navbar.screenfull":"Vollbild",
    "oifde.navbar.switchLang":"Sprache ändern",
    "oifde.navbar.menuOpen":"Menü anzeigen",
    "oifde.navbar.menuClose":"Menü ausblenden",
    "oifde.navbar.changePwd":"Kennwort ändern",
    "oifde.navbar.required":"Dieses Feld ist obligatorisch.",
    "oifde.navbar.action.message":"Aufforderung",
    "oifde.navbar.action.success":"Erfolg",
    "oifde.navbar.action.successMessage":"Der Benutzer wurde erfolgreich gewechselt!",
    "oifde.navbar.action.changePasswordSuccess":"Das Kennwort wurde erfolgreich geändert!",
    "oifde.navbar.password.logOut":"Abmelden",
    "oifde.navbar.password.oldPassword":"Altes Kennwort",
    "oifde.navbar.password.newPassword":"Neues Kennwort",
    "oifde.navbar.password.confirmNewPassword":"Kennwort bestätigen",
    "oifde.navbar.password.passwordExpired":"Ihr Passwort ist abgelaufen. Ändern Sie es.",
    "oifde.navbar.password.firstTimeLogin":"Sie verwenden das ursprüngliche Kennwort. Ändern Sie es.",
    "oifde.navbar.password.passwordWillExpire":"Ihr Passwort läuft bald ab. Ändern Sie es.",
    "oifde.navbar.password.passwordNotSame":"Die beiden Passwörter sind unterschiedlich.",
    "oifde.journeylogmgr.flowAnalysisMrg":"Flussanalyseeinstellungen",
    "oifde.journeylogmgr.accessCodeSetting":"Access-Code-Einstellungen",
    "oifde.trackmgr.track":"Spureinstellungen",
    "oifde.trackmgr.accessCodeTrack":"Zugangscode verfolgen",
    "oifde.trackmgr.callingNumberTrack":"Anrufnummer verfolgen",
    "oifde.trackmgr.callingNumber":"Anrufnummer",
    "oifde.trackmgr.accessCode":"Zugangscode",
    "oifde.trackmgr.choose":"-Auswahl-",
    "oifde.trackmgr.validate.accessCode":"Wählen Sie den Zugangscode aus.",
    "oifde.trackmgr.validate.callingNumber":"Geben Sie eine Anrufnummer ein.",
    "oifde.trackmgr.validate.needNumber":"Geben Sie eine Zahl ein.",
    "oifde.trackmgr.validate.callingNumberLength":"Die Anrufnummer darf maximal 64 Zeichen enthalten.",
    "oifde.trackmgr.validate.callingNumberDub":"Die Anrufnummer muss eindeutig sein.",
    // "oifde.trackmgr.validate.pattern":"Nur Buchstaben, Ziffern, Leerzeichen und die folgenden Sonderzeichen sind zulässig: - \' : {\'@\'} /",
    "oifde.trackmgr.actions.delete":"Löschen",
    "oifde.trackmgr.actions.success":"Erfolg",
    "oifde.trackmgr.actions.updateSuccess":"Änderung erfolgreich.",
    "oifde.trackmgr.actions.add":"Hinzufügen",
    "oifde.trackmgr.actions.deleteSuccess":"Löschung erfolgreich.",
    "oifde.trackmgr.actions.cancelDelete":"Der Löschvorgang wurde abgebrochen.",
    "oifde.trackmgr.actions.cancelUpdate":"Änderung abgebrochen.",
    "oifde.trackmgr.actions.deletePrompt":"Durch diesen Vorgang werden die Trackdaten dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
    "oifde.trackmgr.actions.updatePrompt":"Dieser Vorgang wirkt sich auf die Systemleistung aus, und die Einstellung wird {0} h später automatisch gelöscht. Möchten Sie wirklich fortfahren?",
    "oifde.trackmgr.actions.prompt":"Bestätigen",
    "oifde.trackmgr.actions.confirm":"Ja",
    "oifde.trackmgr.actions.cancel":"Nein",
    "oifde.trace.sessionLog":"Dialogprotokollabfrage",
    "oifde.trace.traceLog":"Protokollverfolgungsabfrage",
    "oifde.trace.beginTime":"Startzeit",
    "oifde.trace.endTime":"Endzeit",
    "oifde.trace.callingNumber":"Anrufnummer",
    "oifde.trace.calledNumber":"Angerufene Nummer",
    "oifde.trace.createTime":"Erstellt",
    "oifde.trace.flowId":"Ablauf-ID",
    "oifde.trace.flowName":"Flussname",
    "oifde.trace.logInfo":"Protokollinformationen",
    "oifde.trace.errorInfo":"Fehlerinformationen",
    "oifde.trace.callId":"Anrufinstanz-ID",
    "oifde.trace.nodeId":"Knoten-ID",
    "oifde.trace.nodeName":"Knotenname",
    "oifde.trace.actionContent":"Anweisungsname",
    "oifde.trace.nodeIndex":"Ausführungsknotennummer.",
    "oifde.trace.ivrRequest":"IVR-Anforderung",
    "oifde.trace.actionParams":"Ausführungsknotenparameterinformationen",
    "oifde.trace.processResult":"Ausführungsergebnis",
    "oifde.trace.transitionOn":"Ausführungsergebniszweig",
    "oifde.trace.reset":"Zurücksetzen",
    "oifde.trace.validate.needNumber":"Geben Sie Ziffern oder Buchstaben ein.",
    "oifde.trace.validate.callingNumber":"Geben Sie eine Anrufnummer ein.",
    "oifde.trace.validate.callingNumberLength":"Die Anrufnummer darf maximal 64 Zeichen enthalten.",
    "oifde.trace.clickToDetail":"Klicken Sie, um weitere anzuzeigen.",
    "oifde.trace.clickToHideDetail":"Klicken Sie, um weniger anzuzeigen.",
    "oifde.trace.currentNodeInfo":"Informationen zum aktuellen Knoten",
  "oifde.trace.clickToFlow":"Track anzeigen",
  "oifde.trace.nodeTrace":"Knotenprotokollinformationen",
  "oifde.trace.nodeParams":"Knoteneingabe- und -ausgabeparameter",
  "oifde.trace.traceReason":"Fehlerursache",
  "oifde.trace.traceAdvice":"Lösung",
  "oifde.trace.reasonAdvice":"Ursache und Vorschlag",
  "oifde.expression.epxr_input_head":"Maximale Länge:",
  "oifde.expression.expr_type_illegal":"Das Ausdrucksformat ist ungültig.",
  "oifde.expression.expr_key_repeats":"Doppelter Bedingungsname.",
  "oifde.expression.expr_var":"Geben Sie eine Variable ein. Beginnen Sie den Wert mit GLOBAL (für eine globale Variable), FLOW (für eine Flussvariable), SYS (für eine Systemvariable) oder TOC (für eine TUC-Variable) oder IVRREQUEST (für eine IVR-Anforderungsvariable).",
  "oifde.expression.expr_var_fag":"Geben Sie eine Variable ein. Beginnen Sie den Wert mit GLOBAL (für eine globale Variable), FLOW (für eine Flussvariable).",
  "oifde.expression.expr_var_string":"Geben Sie eine Variable des Zeichenfolgentyps ein. Beginnen Sie den Wert mit GLOBAL (für eine globale Variable), FLOW (für eine Flussvariable), SYS (für eine Systemvariable) oder TOC (für eine TUC-Variable) oder IVRREQUEST (für eine IVR-Anforderungsvariable).",
  // "oifde.expression.expr_number":"Geben Sie einen numerischen Wert ein. Die verfügbaren Operatoren zwischen Werten sind +, –, *,“ und "/."",",
  "oifde.expression.expr_number_noAction":"Geben Sie einen numerischen Wert ein.",
  "oifde.expression.expr_var_list":"Geben Sie eine Listenvariable ein.",
  "oifde.expression.expr_var_number":"Geben Sie eine int-Variable ein.",
  "oifde.expression.allVar":"Beginnen Sie den Wert mit GLOBAL (für eine globale Variable), FLOW (für eine Flussvariable), SYS (für eine Systemvariable) oder TOC (für eine TUC-Variable) oder IVRREQUEST (für eine IVR-Anforderungsvariable).",
  "oifde.expression.flowAndGlobalVar":"Geben Sie eine Variable ein. Beginnen Sie den Wert mit GLOBAL (für eine globale Variable), FLOW (für eine Flussvariable).",
  // "oifde.expression.expr_string":"Geben Sie einen Wert des Zeichenfolgentyps ein. Schließen Sie den Wert in einfache oder doppelte Anführungszeichen (\' bzw. “). ein. Der Zeichenfolgenoperator ist das Pluszeichen (+).",
  // "oifde.expression.expr_simple_string":"Geben Sie eine Zeichenfolgenvariable oder eine beliebige Zeichenfolge ein. Schließen Sie die Zeichenfolge nicht in einfache oder doppelte Anführungszeichen (\' bzw. “)., ein",
  "oifde.expression.expr_boolean":"Geben Sie einen booleschen Wert ein. Die verfügbaren Vergleichsoperatoren zwischen numerischen Werten oder Zeichenfolgen sind >, <, >=, und!=. Die booleschen Operatoren sind &&, ||, und!=. Die Zeichenfolgenmethode unterstützt startsWith.",
  "oifde.expression.varFlow":"Flussvariable",
  "oifde.expression.varGlobal":"Globale Variable",
  "oifde.expression.varToc":"Absichtsvariable",
  "oifde.expression.varSys":"Systemvariable",
  "oifde.expression.varIvrRequest":"IVR-Anforderungsvariable",
  "oifde.expression.expr_var_paramName":"Geben Sie eine Variable ein. Beginnen Sie den Wert mit GLOBAL (für eine globale Variable) oder FLOW (für eine Flussvariable).",
  "oifde.procedure.procedureMgr":"Gespeicherte Prozeduren",
  "oifde.procedure.procedureDBMgr":"Datenbanken für gespeicherte Verfahren",
  "oifde.procedure.procedureName":"Gespeicherte Prozedur",
  "oifde.procedure.procedureDesc":"Beschreibung",
  "oifde.procedure.procedureValue":"Wert",
  "oifde.procedure.operation":"Betrieb",
  "oifde.procedure.baseInfo":"Grundlegende Informationen",
  "oifde.procedure.in_param":"Eingabeparameter",
  "oifde.procedure.paramName":"Parameter",
  "oifde.procedure.paramDesc":"Beschreibung",
  "oifde.procedure.dataType":"Datentyp",
  "oifde.procedure.out_param":"Ausgabeparameter",
  "oifde.procedure.addProcedureInterface":"Gespeicherte Prozedur hinzufügen",
  "oifde.procedure.modifyProcedureInterface":"Gespeicherte Prozedur ändern",
  "oifde.procedure.addProcedureDB":"Gespeicherte Prozedurdatenbank hinzufügen",
  "oifde.procedure.modifyProcedureDB":"Gespeicherte Prozedurdatenbank bearbeiten",
  "oifde.procedure.required":"Dieses Feld ist obligatorisch.",
  // "oifde.procedure.urlRule":"Url sollte mit “jdbc:mysql oder “jdbc:zenith" beginnen."",
  "oifde.procedure.actions.editList":"Datentyp in Liste bearbeiten",
  "oifde.procedure.actions.edit":"Bearbeiten",
  "oifde.procedure.actions.delete":"Löschen",
  "oifde.procedure.actions.success":"Erfolg",
  "oifde.procedure.actions.createSuccess":"Erstellung erfolgreich.",
  "oifde.procedure.actions.updateSuccess":"Änderung erfolgreich.",
  "oifde.procedure.actions.deleteSuccess":"Löschung erfolgreich.",
  "oifde.procedure.actions.cancelDelete":"Der Löschvorgang wurde abgebrochen.",
  "oifde.procedure.actions.deletePrompt":"Dieser Vorgang löscht die gespeicherte Prozedur endgültig. Möchten Sie wirklich fortfahren?",
  "oifde.procedure.actions.prompt":"Bestätigen",
  "oifde.procedure.actions.confirm":"Ja",
  "oifde.procedure.actions.cancel":"Nein",
  "oifde.procedure.actions.ok":"OK",
  "oifde.procedure.actions.miss_pro_name":"Das folgende obligatorische Feld ist leer: Basisinformationen > Name der gespeicherten Prozedur.",
  "oifde.procedure.actions.miss_pro_value":"Das folgende obligatorische Feld ist leer: Basisinformationen > Gespeicherter Prozedurwert.",
  "oifde.procedure.actions.miss_pro_in_parameter_name":"Das folgende Pflichtfeld ist leer: Eingabeparameter > Parametername.",
  "oifde.procedure.actions.miss_pro_out_parameter_name":"Das folgende Pflichtfeld ist leer: Ausgabeparameter > Parametername.",
  "oifde.procedure.actions.miss_pro_dbId":"Das folgende obligatorische Feld ist leer: Basisinformationen > Datenbankverbindung.",
  "oifde.procedure.dataTypes.INT":"Ganzzahl",
  "oifde.procedure.dataTypes.STRING":"Charakter",
  "oifde.procedure.dataTypes.FLOAT":"Gleitkommazahl",
  "oifde.procedure.dataTypes.LONG":"Lange Ganzzahl",
  "oifde.procedure.dataTypes.LIST":"Liste",
  "oifde.procedure.db.dbType":"Datenbanktyp",
  "oifde.procedure.db.dbDriver":"Datenbanktreiber",
  "oifde.procedure.db.dbUrl":"Datenbank-URL",
  "oifde.procedure.db.dbUser":"Datenbankbenutzer",
  "oifde.procedure.db.dbPassword":"Datenbankkennwort",
  "oifde.procedure.dbTypes.MYSQL":"MySQL",
  "oifde.procedure.dbTypes.GAUSS":"GaußDB",
  "oifde.procedure.dbTypes.ORACLE":"Oracle",
  "oifde.procedure.ruleMysql":"Die MySQL-Datenbank muss mit jdbc:mysql:// beginnen.",
  // "oifde.procedure.ruleGauss":"Die GaussDB muss beginnen mit jdbc:zenith:{\'@\'}.",
  // "oifde.procedure.ruleOracle":"Der Name der Oracle-Datenbank muss beginnen mit jdbc:oracle:thin:{\'@\'}.",
  "oifde.procedure.ruleRange":"Die URL darf nur Buchstaben, Ziffern und die folgenden Sonderzeichen enthalten:.? =: - _ und /. Es darf nur mit einem Buchstaben, einer Ziffer oder einem Schrägstrich enden (/).",
  // "oifde.procedure.oracleRuleRange":"Die URL darf nur Buchstaben, Ziffern und die folgenden speziellen characters:.? {\'@\'} enthalten: - _ und /. Sie darf nur mit einem Buchstaben, einer Ziffer oder einem Schrägstrich enden (/).",
  "oifde.procedure.driverRule":"Der Wert darf nur Buchstaben, Ziffern, Punkte (.), und Bindestriche (-) enthalten.",
  "oifde.procedure.driverStart":"Der Wert darf nicht mit einem Punkt (.) bzw. einem Bindestrich (-) beginnen.",
  "oifde.procedure.driverEnd":"Der Wert darf nicht mit einem Punkt (.) bzw. einem Bindestrich (-) enden.",
  "oifde.procedure.ruleLength":"Geben Sie die IP-Adresse und die Portnummer in die URL ein.",
  "oifde.procedure.dbUrlWarning":"*Beachten Sie, dass die IP-Adresse, die Portnummer oder der Domänenname in der Datenbank-URL nicht in der Vertrauensliste enthalten sind. Wenden Sie sich an den Systemadministrator, um die IP-Adresse, die Portnummer oder den Domänennamen hinzuzufügen.",
  "oifde.complexcell.cellTitle":"Zusammengesetztes Diagrammelement",
  "oifde.complexcell.catalogTitle":"Elementkatalog für zusammengesetzte Diagramme",
  "oifde.complexcell.catalog.name":"Katalogname",
  "oifde.complexcell.catalog.desc":"Katalogbeschreibung",
  "oifde.complexcell.catalog.tips.sizeLimit":"Es sind maximal 100 Kataloge zulässig.",
  "oifde.complexcell.cell.name":"Name des Diagrammelements",
  "oifde.complexcell.cell.desc":"Beschreibung",
  "oifde.complexcell.cell.height":"Höhe",
  "oifde.complexcell.cell.width":"Breite",
  "oifde.complexcell.cell.icon":"Symbol",
  "oifde.complexcell.cell.catalog":"Katalog",
  "oifde.complexcell.cell.actions.editInfo":"Grundlegende Informationen ändern",
  "oifde.complexcell.cell.actions.editDiagram":"Leinwand bearbeiten",
  "oifde.complexcell.cell.actions.copy":"Replizieren",
  "oifde.complexcell.cell.validation.bigFile":"Die Dateigröße darf {0} nicht überschreiten.",
  "oifde.complexcell.cell.viewReference":"Referenzen anzeigen",
  "oifde.complexcell.cell.actions.copySuccess":"Kopieren erfolgreich.",
  "oifde.table.search":"Suche",
  "oifde.table.add":"Hinzufügen",
  "oifde.table.actions":"Aktionen",
  "oifde.table.cancel":"Abbrechen",
  "oifde.table.confirm":"Bestätigen",
  "oifde.table.create":"Erstellen",
  "oifde.table.delete":"Löschen",
  "oifde.table.manage":"Verwalten",
  "oifde.table.save":"Speichern",
  "oifde.table.reset":"Zurücksetzen",
  "oifde.table.filter":"Filtern",
  "oifde.tagsView.refresh":"Aktualisieren",
  "oifde.tagsView.close":"Schließen",
  "oifde.tagsView.closeOthers":"Andere schließen",
  "oifde.tagsView.closeAll":"Alle schließen",
  "oifde.mxgraph.failInfo":"Die Daten konnten nicht abgefragt werden!",
  "oifde.mxgraph.alreadyConnected":"Knoten bereits verbunden",
  "oifde.mxgraph.cancel":"Abbrechen",
  "oifde.mxgraph.close":"Schließen",
  "oifde.mxgraph.collapse-expand":"Reduzieren/Erweitern",
  "oifde.mxgraph.containsValidationErrors":"Validierungsfehler gefunden",
  "oifde.mxgraph.done":"Fertig",
  "oifde.mxgraph.doubleClickOrientation":"Doppelklicken, um die Ausrichtung zu ändern",
  "oifde.mxgraph.error":"Fehler",
  "oifde.mxgraph.errorSavingFile":"Datei konnte nicht gespeichert werden!",
  "oifde.mxgraph.ok":"OK",
  "oifde.mxgraph.updatingDocument":"Das Dokument wird aktualisiert. Bitte warten ...",
  "oifde.mxgraph.updatingSelection":"Aktualisieren der Auswahl. Bitte warten ...",
  "oifde.mxgraph.about":"Über",
  "oifde.mxgraph.actualSize":"Tatsächliche Größe",
  "oifde.mxgraph.add":"Hinzufügen",
  "oifde.mxgraph.addLayer":"Ebene hinzufügen",
  "oifde.mxgraph.addProperty":"Eigenschaft hinzufügen",
  "oifde.mxgraph.addToExistingDrawing":"Zu vorhandener Zeichnung hinzufügen",
  "oifde.mxgraph.addWaypoint":"Wegpunkt hinzufügen",
  "oifde.mxgraph.advanced":"Erweitert",
  "oifde.mxgraph.align":"Ausrichten",
  "oifde.mxgraph.alignment":"Ausrichtung",
  "oifde.mxgraph.allChangesLost":"Alle Änderungen gehen verloren.",
  "oifde.mxgraph.angle":"Winkel",
  "oifde.mxgraph.apply":"Anwenden",
  "oifde.mxgraph.arc":"Bogen",
  "oifde.mxgraph.arrange":"Anordnen",
  "oifde.mxgraph.arrow":"Pfeil",
  "oifde.mxgraph.arrows":"Pfeile",
  "oifde.mxgraph.automatic":"Automatisch",
  "oifde.mxgraph.autosave":"Automatisch speichern",
  "oifde.mxgraph.autosize":"Automatische Größe",
  "oifde.mxgraph.background":"Hintergrund",
  "oifde.mxgraph.backgroundColor":"Hintergrundfarbe",
  "oifde.mxgraph.backgroundImage":"Hintergrundbild",
  "oifde.mxgraph.basic":"Grundlagen",
  "oifde.mxgraph.block":"Blockieren",
  "oifde.mxgraph.blockquote":"Blockzitat",
  "oifde.mxgraph.bold":"Fett",
  "oifde.mxgraph.borderWidth":"Rahmenbreite",
  "oifde.mxgraph.borderColor":"Rahmenfarbe",
  "oifde.mxgraph.bottom":"Unten",
  "oifde.mxgraph.bottomAlign":"Unten ausrichten",
  "oifde.mxgraph.bottomLeft":"Unten links",
  "oifde.mxgraph.bottomRight":"Rechts unten",
  "oifde.mxgraph.bulletedList":"Aufzählungsliste",
  "oifde.mxgraph.cannotOpenFile":"Die Datei kann nicht geöffnet werden.",
  "oifde.mxgraph.center":"Vertikale Ausrichtung",
  "oifde.mxgraph.change":"Ändern",
  "oifde.mxgraph.changeOrientation":"Ausrichtung ändern",
  "oifde.mxgraph.circle":"Kreis",
  "oifde.mxgraph.classic":"Klassisch",
  "oifde.mxgraph.clearDefaultStyle":"Standardstil löschen",
  "oifde.mxgraph.clearWaypoints":"Wegpunkte löschen",
  "oifde.mxgraph.clipart":"Clipart",
  "oifde.mxgraph.collapse":"Kollabieren",
  "oifde.mxgraph.collapseExpand":"Reduzieren/Erweitern",
  "oifde.mxgraph.collapsible":"Zusammenklappbar",
  "oifde.mxgraph.comic":"Komiker",
  "oifde.mxgraph.connect":"Verbinden",
  "oifde.mxgraph.connection":"Verbindung",
  "oifde.mxgraph.connectionPoints":"Anschlusspunkte",
  "oifde.mxgraph.connectionArrows":"Verbindungspfeile",
  "oifde.mxgraph.constrainProportions":"Proportionen einschränken",
  "oifde.mxgraph.copy":"Kopieren",
  "oifde.mxgraph.copyConnect":"Verbindung kopieren",
  "oifde.mxgraph.create":"Erstellen",
  "oifde.mxgraph.curved":"Gekrümmt",
  "oifde.mxgraph.custom":"Benutzerdefiniert",
  "oifde.mxgraph.cut":"Ausschneiden",
  "oifde.mxgraph.dashed":"Gestrichelt",
  "oifde.mxgraph.decreaseIndent":"Einzug verkleinern",
  "oifde.mxgraph.default":"Standard",
  "oifde.mxgraph.delete":"Löschen",
  "oifde.mxgraph.deleteColumn":"Spalte löschen",
  "oifde.mxgraph.deleteRow":"Zeile löschen",
  "oifde.mxgraph.diagram":"Diagramm",
  "oifde.mxgraph.diamond":"Diamant",
  "oifde.mxgraph.diamondThin":"Diamant (dünn)",
  "oifde.mxgraph.direction":"Richtung",
  "oifde.mxgraph.distribute":"Verteilen",
  "oifde.mxgraph.divider":"Teiler",
  "oifde.mxgraph.documentProperties":"Dokumenteigenschaften",
  "oifde.mxgraph.dotted":"Gepunktet",
  "oifde.mxgraph.drawing":"Zeichnung{0}",
  "oifde.mxgraph.drawingEmpty":"Zeichnung ist leer.",
  "oifde.mxgraph.drawingTooLarge":"Das Diagramm ist zu groß.",
  "oifde.mxgraph.duplicate":"Duplizieren",
  "oifde.mxgraph.duplicateIt":"{0} doppelt",
  "oifde.mxgraph.east":"Osten",
  "oifde.mxgraph.edit":"Bearbeiten",
  "oifde.mxgraph.editData":"Daten bearbeiten",
  "oifde.mxgraph.editDiagram":"Quelle",
  "oifde.mxgraph.editImage":"Bild bearbeiten",
  "oifde.mxgraph.editLink":"Verknüpfung bearbeiten",
  "oifde.mxgraph.editStyle":"Stil bearbeiten",
  "oifde.mxgraph.editTooltip":"Tooltip bearbeiten",
  "oifde.mxgraph.enterGroup":"Gruppe aufrufen",
  "oifde.mxgraph.enterValue":"Wert eingeben",
  "oifde.mxgraph.enterName":"Namen eingeben",
  "oifde.mxgraph.enterPropertyName":"Eigenschaftsnamen eingeben",
  "oifde.mxgraph.entityRelation":"Entitätsbeziehung",
  "oifde.mxgraph.exitGroup":"Gruppe verlassen",
  "oifde.mxgraph.expand":"Erweitern",
  "oifde.mxgraph.export":"Exportieren",
  "oifde.mxgraph.extras":"Extras",
  "oifde.mxgraph.file":"Datei",
  "oifde.mxgraph.fileNotFound":"Datei nicht gefunden",
  "oifde.mxgraph.filename":"Dateiname",
  "oifde.mxgraph.fill":"Ausfüllen",
  "oifde.mxgraph.fillColor":"Füllfarbe",
  "oifde.mxgraph.fitPage":"Eine Seite",
  "oifde.mxgraph.fitPageWidth":"Seitenbreite",
  "oifde.mxgraph.fitTwoPages":"Zwei Seiten",
  "oifde.mxgraph.fitWindow":"Fenster einpassen",
  "oifde.mxgraph.flip":"Umdrehen",
  "oifde.mxgraph.flipH":"Horizontal spiegeln",
  "oifde.mxgraph.flipV":"Vertikal spiegeln",
  "oifde.mxgraph.font":"Schriftart",
  "oifde.mxgraph.fontFamily":"Schriftfamilie",
  "oifde.mxgraph.fontColor":"Schriftfarbe",
  "oifde.mxgraph.fontSize":"Schriftgröße",
  "oifde.mxgraph.format":"Formatieren",
  "oifde.mxgraph.formatPanel":"Anzeige formatieren",
  "oifde.mxgraph.formatPdf":"PDF",
  "oifde.mxgraph.formatPng":"PNG",
  "oifde.mxgraph.formatGif":"GIF",
  "oifde.mxgraph.formatJpg":"JPEG",
  "oifde.mxgraph.formatSvg":"SVG",
  "oifde.mxgraph.formatXml":"XML",
  "oifde.mxgraph.formatted":"Formatiert",
  "oifde.mxgraph.formattedText":"Formatierter Text",
  "oifde.mxgraph.gap":"Lücke",
  "oifde.mxgraph.general":"Allgemein",
  "oifde.mxgraph.glass":"Glas",
  "oifde.mxgraph.global":"Global",
  "oifde.mxgraph.gradient":"Gradient",
  "oifde.mxgraph.gradientColor":"Farbe",
  "oifde.mxgraph.grid":"Gitter",
  "oifde.mxgraph.gridSize":"Rastergröße",
  "oifde.mxgraph.group":"Gruppe",
  "oifde.mxgraph.guides":"Guides",
  "oifde.mxgraph.heading":"Überschrift",
  "oifde.mxgraph.height":"Höhe",
  "oifde.mxgraph.help":"Hilfe",
  "oifde.mxgraph.hide":"Ausblenden",
  "oifde.mxgraph.hideIt":"{0} ausblenden",
  "oifde.mxgraph.hidden":"Ausgeblendet",
  "oifde.mxgraph.home":"Startseite",
  "oifde.mxgraph.horizontal":"Horizontal",
  "oifde.mxgraph.horizontalFlow":"Horizontaler Fluss",
  "oifde.mxgraph.horizontalTree":"Horizontaler Baum",
  "oifde.mxgraph.html":"HTML",
  "oifde.mxgraph.image":"Bild",
  "oifde.mxgraph.images":"Bilder",
  "oifde.mxgraph.import":"Importieren",
  "oifde.mxgraph.increaseIndent":"Einzug vergrößern",
  "oifde.mxgraph.insert":"Einfügen",
  "oifde.mxgraph.insertColumnBefore":"Spalte links einfügen",
  "oifde.mxgraph.insertColumnAfter":"Spalte rechts einfügen",
  "oifde.mxgraph.insertHorizontalRule":"Horizontale Linie einfügen",
  "oifde.mxgraph.insertImage":"Bild einfügen",
  "oifde.mxgraph.insertLink":"Link einfügen",
  "oifde.mxgraph.insertRowBefore":"Zeile oben einfügen",
  "oifde.mxgraph.insertRowAfter":"Zeile unterhalb einfügen",
  "oifde.mxgraph.invalidName":"Ungültiger Name",
  "oifde.mxgraph.invalidOrMissingFile":"Die Datei fehlt oder ist ungültig.",
  "oifde.mxgraph.isometric":"Isometrie",
  "oifde.mxgraph.italic":"Kursiv",
  "oifde.mxgraph.layers":"Ebenen",
  "oifde.mxgraph.landscape":"Landschaft",
  "oifde.mxgraph.laneColor":"Fahrspurfarbe",
  "oifde.mxgraph.layout":"Layout",
  "oifde.mxgraph.left":"Links",
  "oifde.mxgraph.leftAlign":"Links ausrichten",
  "oifde.mxgraph.leftToRight":"Von links nach rechts",
  "oifde.mxgraph.line":"Linie",
  "oifde.mxgraph.link":"Verknüpfung",
  "oifde.mxgraph.lineJumps":"Liniensprünge",
  "oifde.mxgraph.lineend":"Zeilenende",
  "oifde.mxgraph.lineheight":"Linienhöhe",
  "oifde.mxgraph.linestart":"Zeilenanfang",
  "oifde.mxgraph.linewidth":"Linienbreite",
  "oifde.mxgraph.loading":"Laden",
  "oifde.mxgraph.lockUnlock":"Sperren/Entsperren",
  "oifde.mxgraph.manual":"Handbuch",
  "oifde.mxgraph.middle":"Horizontale Ausrichtung",
  "oifde.mxgraph.misc":"Verschiedenes",
  "oifde.mxgraph.more":"Mehr",
  "oifde.mxgraph.moreResults":"Weitere Ergebnisse",
  "oifde.mxgraph.move":"Verschieben",
  "oifde.mxgraph.moveSelectionTo":"Auswahl nach {0} verschieben",
  "oifde.mxgraph.navigation":"Navigation",
  "oifde.mxgraph.new":"Neu",
  "oifde.mxgraph.noColor":"Keine Farbe.",
  "oifde.mxgraph.noFiles":"Keine Dateien.",
  "oifde.mxgraph.noMoreResults":"Keine weiteren Ergebnisse.",
  "oifde.mxgraph.none":"Keine",
  // "oifde.mxgraph.noResultsFor":"Keine Ergebnisse für \'{0}\'",
  "oifde.mxgraph.normal":"Normal",
  "oifde.mxgraph.north":"Norden",
  "oifde.mxgraph.numberedList":"Numerierte Liste",
  "oifde.mxgraph.opacity":"Opazität",
  "oifde.mxgraph.open":"Öffnen",
  "oifde.mxgraph.openArrow":"Pfeil öffnen",
  "oifde.mxgraph.openFile":"Datei öffnen",
  "oifde.mxgraph.openLink":"Link öffnen",
  "oifde.mxgraph.openSupported":"Nur XML-Dateien, die aus diesem System exportiert wurden, werden unterstützt.",
  "oifde.mxgraph.openInNewWindow":"In neuem Fenster öffnen",
  "oifde.mxgraph.openInThisWindow":"In diesem Fenster öffnen",
  "oifde.mxgraph.options":"Optionen",
  "oifde.mxgraph.organic":"Bio",
  "oifde.mxgraph.orthogonal":"Orthogonal",
  "oifde.mxgraph.outline":"Umriss",
  "oifde.mxgraph.oval":"Oval",
  "oifde.mxgraph.pages":"Seiten",
  "oifde.mxgraph.pageView":"Seitenansicht",
  "oifde.mxgraph.pageScale":"Seitenskala",
  "oifde.mxgraph.pageSetup":"Seite einrichten",
  "oifde.mxgraph.panTooltip":"Leertaste+Zum Scrollen ziehen",
  "oifde.mxgraph.paperSize":"Papierformat",
  "oifde.mxgraph.paste":"Einfügen",
  "oifde.mxgraph.pasteHere":"Hier einfügen",
  "oifde.mxgraph.pattern":"Muster",
  "oifde.mxgraph.perimeter":"Umfang",
  "oifde.mxgraph.placeholders":"Platzhalter",
  "oifde.mxgraph.plusTooltip":"Klicken Sie zum Verbinden und Klonen (Strg+Klicken zum Klonen, Umschalttaste+Klicken zum Verbinden). Zum Verbinden ziehen (Strg+Zum Klonen ziehen).",
  "oifde.mxgraph.portrait":"Porträt",
  "oifde.mxgraph.position":"Position",
  "oifde.mxgraph.posterPrint":"Posterdruck",
  "oifde.mxgraph.preview":"Vorschau",
  "oifde.mxgraph.print":"Drucken",
  "oifde.mxgraph.radialTree":"Radialbaum",
  "oifde.mxgraph.redo":"Wiederholen",
  "oifde.mxgraph.remindSave":"Sie haben schon lange keine Daten mehr gespeichert. Speichern Sie die Daten umgehend.",
  "oifde.mxgraph.removeFormat":"Formatierung löschen",
  "oifde.mxgraph.removeFromGroup":"Aus Gruppe entfernen",
  "oifde.mxgraph.removeIt":"{0} entfernen",
  "oifde.mxgraph.removeWaypoint":"Wegpunkt entfernen",
  "oifde.mxgraph.rename":"Umbenennen",
  "oifde.mxgraph.renameIt":"{0} umbenennen",
  "oifde.mxgraph.replace":"Ersetzen",
  "oifde.mxgraph.replaceIt":"{0} ist bereits vorhanden. Möchten Sie sie ersetzen?",
  "oifde.mxgraph.replaceExistingDrawing":"Bestehende Zeichnung ersetzen",
  "oifde.mxgraph.reset":"Zurücksetzen",
  "oifde.mxgraph.resetView":"Ansicht zurücksetzen",
  "oifde.mxgraph.reverse":"Rückwärts",
  "oifde.mxgraph.right":"Rechts",
  "oifde.mxgraph.rightAlign":"Rechts ausrichten",
  "oifde.mxgraph.rightToLeft":"Von rechts nach links",
  "oifde.mxgraph.rotate":"Drehen",
  "oifde.mxgraph.rotateTooltip":"Klicken und ziehen Sie, um zu drehen, und klicken Sie, um um 90 Grad zu drehen",
  "oifde.mxgraph.rotation":"Drehung",
  "oifde.mxgraph.rounded":"Gerundet",
  "oifde.mxgraph.save":"Speichern",
  "oifde.mxgraph.saveAs":"Speichern unter",
  "oifde.mxgraph.saved":"Gespeichert",
  "oifde.mxgraph.scrollbars":"Scrollleisten",
  "oifde.mxgraph.search":"Suche",
  "oifde.mxgraph.searchShapes":"Suchknoten",
  "oifde.mxgraph.selectAll":"Alle auswählen",
  "oifde.mxgraph.selectEdges":"Kanten auswählen",
  "oifde.mxgraph.selectFont":"Schriftart auswählen",
  "oifde.mxgraph.selectNone":"Keine auswählen",
  "oifde.mxgraph.selectVertices":"Scheitelpunkte auswählen",
  "oifde.mxgraph.setAsDefaultStyle":"Als Standardstil festlegen",
  "oifde.mxgraph.shadow":"Schatten",
  "oifde.mxgraph.shape":"Form",
  "oifde.mxgraph.sharp":"Scharf",
  "oifde.mxgraph.sidebarTooltip":"Klicken Sie, um den Knotenbereich zu erweitern oder zu reduzieren.",
  "oifde.mxgraph.simple":"Einfach",
  "oifde.mxgraph.simpleArrow":"Einfacher Pfeil",
  "oifde.mxgraph.size":"Größe",
  "oifde.mxgraph.solid":"Fest",
  "oifde.mxgraph.sourceSpacing":"Quellabstand",
  "oifde.mxgraph.south":"Süden",
  "oifde.mxgraph.spacing":"Abstand",
  "oifde.mxgraph.straight":"Gerade",
  "oifde.mxgraph.strokeColor":"Linienfarbe",
  "oifde.mxgraph.style":"Stil",
  "oifde.mxgraph.subscript":"Tiefgestellt",
  "oifde.mxgraph.superscript":"Hochgestellt",
  "oifde.mxgraph.table":"Tisch",
  "oifde.mxgraph.targetSpacing":"Zielabstand",
  "oifde.mxgraph.text":"Text",
  "oifde.mxgraph.textAlignment":"Textausrichtung",
  "oifde.mxgraph.textOpacity":"Textopazität",
  "oifde.mxgraph.toBack":"Nach hinten",
  "oifde.mxgraph.toFront":"Nach vorne",
  "oifde.mxgraph.tooltips":"Tooltips",
  "oifde.mxgraph.top":"Oben",
  "oifde.mxgraph.topAlign":"Oben ausrichten",
  "oifde.mxgraph.topLeft":"Oben links",
  "oifde.mxgraph.topRight":"Oben rechts",
  "oifde.mxgraph.transparent":"Transparent",
  "oifde.mxgraph.turn":"Um 90 Grad drehen",
  "oifde.mxgraph.uml":"UML",
  "oifde.mxgraph.underline":"Unterstreichen",
  "oifde.mxgraph.undo":"Rückgängig",
  "oifde.mxgraph.ungroup":"Gruppierung aufheben",
  "oifde.mxgraph.url":"URL",
  "oifde.mxgraph.untitledLayer":"Ebene ohne Titel",
  "oifde.mxgraph.vertical":"Vertikal",
  "oifde.mxgraph.verticalFlow":"Vertikaler Fluss",
  "oifde.mxgraph.verticalTree":"Vertikaler Baum",
  "oifde.mxgraph.view":"Ansicht",
  "oifde.mxgraph.waypoints":"Wegpunkte",
  "oifde.mxgraph.west":"Westen",
  "oifde.mxgraph.width":"Breite",
  "oifde.mxgraph.wordWrap":"Wortumbruch",
  "oifde.mxgraph.writingDirection":"Schreibrichtung",
  "oifde.mxgraph.zoom":"Zoom",
  "oifde.mxgraph.zoomIn":"Einzoomen",
  "oifde.mxgraph.zoomOut":"Auszoomen",
  "oifde.mxgraph.success":"Erfolg",
  "oifde.mxgraph.prompt":"Aufforderung",
  "oifde.mxgraph.confirm":"OK",
  "oifde.mxgraph.NMS_DiagramProperties":"Prozessattribut",
  "oifde.mxgraph.NMS_BasicProperties":"Basisattribut",
  "oifde.mxgraph.NMS_DiagramID":"Prozess-ID",
  "oifde.mxgraph.NMS_Status":"Status",
  "oifde.mxgraph.NMS_DiagramCode":"Prozesscode",
  "oifde.mxgraph.NMS_Valid":"Gültig",
  "oifde.mxgraph.NMS_Invalid":"Ungültig",
  "oifde.mxgraph.NMS_DiagramName":"Prozessname",
  "oifde.mxgraph.NMS_Region":"Region",
  "oifde.mxgraph.NMS_ScenarioType":"Szenariotyp",
  "oifde.mxgraph.NMS_Desciption":"Beschreibung",
  "oifde.mxgraph.NMS_NodeProperties":"Knotenattribut",
  "oifde.mxgraph.NMS_NodeCode":"Knotencode",
  "oifde.mxgraph.NMS_NodeName":"Knotenname",
  "oifde.mxgraph.NMS_BranchProperties":"Zweigstellenattribut",
  "oifde.mxgraph.NMS_BranchCondition":"Abzweigbedingung",
  "oifde.mxgraph.NMS_DefaultBranch":"Standardzweig",
  "oifde.mxgraph.NMS_AdvanceProperties":"Erweitertes Attribut",
  "oifde.mxgraph.NMS_ServiceName":"Dienstname",
  "oifde.mxgraph.NMS_ServiceParams":"Dienstparameter",
  "oifde.mxgraph.NMS_ServiceDesciption":"Servicebeschreibung",
  "oifde.mxgraph.NMS_PleaseSelect":"Bitte auswählen",
  "oifde.mxgraph.NMS_ParamName":"Parameter",
  "oifde.mxgraph.NMS_ParamValue":"Wert",
  "oifde.mxgraph.NMS_ParamDescription":"Beschreibung",
  "oifde.mxgraph.NMS_FlowSaveSuccess":"Der Flow wurde erfolgreich gespeichert!",
  "oifde.mxgraph.NMS_FlowSaveFail":"Flow konnte nicht gespeichert werden!",
  "oifde.mxgraph.NMS_FlowOpenFail":"Flow konnte nicht geöffnet werden!",
  "oifde.mxgraph.NMS_ActionTypesGetFail":"Die Informationen zum Diagrammelement konnten nicht abgerufen werden!",
  "oifde.mxgraph.NMS_ActionParamsGetFail":"Diagrammelementparameter konnten nicht abgerufen werden!",
  "oifde.mxgraph.NMS_FlowsGetFail":"Die Flow-Liste konnte nicht abgerufen werden!",
  "oifde.mxgraph.NMS_NodesGetFail":"Die Knotenliste des Flows {0} konnte nicht abgerufen werden!",
  "oifde.mxgraph.NMS_InterfacesGetFail":"Die Liste der Zugriffsschnittstellen konnte nicht abgerufen werden!",
  "oifde.mxgraph.NMS_ContentTemplateGetFail":"Die Liste der Inhaltsvorlagen konnte nicht abgerufen werden!",
  "oifde.mxgraph.NMS_EditorModelLoadFail":"Die Editor-Modellkonfiguration konnte nicht geladen werden!",
  "oifde.mxgraph.NMS_RegionInfoLoadFail":"Regionsinformationen konnten nicht geladen werden!",
  "oifde.mxgraph.NMS_IntentCodeLoadFail":"Absichtscode konnte nicht geladen werden!",
  "oifde.mxgraph.NMS_PleaseSelectTemplate":"Wählen Sie eine Vorlage aus.",
  "oifde.mxgraph.NMS_TargetFlow":"Zielfluss",
  "oifde.mxgraph.NMS_TargetNode":"Zielknoten",
  "oifde.mxgraph.NMS_TemplateCode":"Vorlagencode",
  "oifde.mxgraph.NMS_flow":"Fluss",
  "oifde.mxgraph.NMS_ScenarioType_main":"Hauptfluss",
  "oifde.mxgraph.NMS_ScenarioType_sub":"Unterfluss",
  "oifde.mxgraph.NMS_ScenarioType_error":"Ausnahmeszenario",
  "oifde.mxgraph.NMS_flowCodeError":"Der Flusscode darf nicht leer sein oder mehr als 64 Zeichen umfassen.",
  "oifde.mxgraph.NMS_flowNameError":"Der Flow-Name darf nicht leer sein oder mehr als 1000 Zeichen umfassen.",
  "oifde.mxgraph.NMS_flowDescError":"Die Flussbeschreibung darf maximal 1000 Zeichen enthalten.",
  "oifde.mxgraph.NMS_nmsFlowError":"Flow-Parsing fehlgeschlagen! Der nmsFlow-Knoten wurde nicht gefunden.",
  "oifde.mxgraph.NMS_rootError":"Flow-Parsing fehlgeschlagen! Der Stammknoten wurde nicht gefunden.",
  "oifde.mxgraph.NMS_VariableNameError":"Der Variablenname darf nicht mehr als 1000 Zeichen enthalten.",
  "oifde.mxgraph.NMS_VariableDescError":"Die Variablenbeschreibung darf nicht mehr als 1000 Zeichen enthalten.",
  "oifde.mxgraph.NMS_VariableDefaultValueError":"Der Standardwert der Variablen darf nicht mehr als 1024 Zeichen enthalten.",
  "oifde.mxgraph.NMS_NodeCodeError":"Der Knotencode darf nicht leer sein oder mehr als 64 Zeichen umfassen.",
  "oifde.mxgraph.NMS_NodeNameError":"Der Knotenname darf nicht leer sein oder mehr als 1000 Zeichen umfassen.",
  "oifde.mxgraph.NMS_NodeDescError":"Die Knotenbeschreibung darf maximal 1000 Zeichen enthalten.",
  "oifde.mxgraph.NMS_actionParamNameError":"Der Parametername darf maximal 64 Zeichen enthalten.",
  "oifde.mxgraph.NMS_actionParamValueEmptyError":"Der Wert darf nicht leer sein.",
  "oifde.mxgraph.NMS_actionParamValueError":"Der Parameterwert darf maximal 4096 Zeichen enthalten.",
  "oifde.mxgraph.NMS_actionParamDescError":"Die Parameterbeschreibung darf nicht mehr als 500 Zeichen enthalten.",
  "oifde.mxgraph.NMS_transitToStartNodeError":"Es konnte nicht zum Startknoten gesprungen werden!",
  "oifde.mxgraph.NMS_endNodetransitToError":"Der Endknoten kann nicht zu einem anderen Knoten springen.",
  "oifde.mxgraph.NMS_startNodeNotFind":"Der Startknoten wurde nicht gefunden.",
  "oifde.mxgraph.NMS_startNodeNotUnique":"Der Startknoten ist nicht eindeutig.",
  "oifde.mxgraph.NMS_startNodeNotBranchError":"Der Startknoten kann nur einen Zweig haben.",
  "oifde.mxgraph.NMS_endNodeNotFind":"Der Endknoten wurde nicht gefunden.",
  "oifde.mxgraph.NMS_analiseXmlFailed":"Die Flow-Leinwand konnte nicht analysiert werden!",
  "oifde.mxgraph.NMS_branchError":"Der Zweig muss über den Start- und Endknoten verfügen.",
  "oifde.mxgraph.NMS_nullBranchNotNuique":"Ein Knoten kann nur einen Standardzweig haben.",
  "oifde.mxgraph.NMS_checkDefaultNextNodeForView":"Das Diagrammelement des Ansichtstyps kann nicht mehr als einen Zweig haben.",
  "oifde.mxgraph.NMS_nodeRefError":"Der Knoten wird von einem anderen Flow referenziert und kann nicht gelöscht werden.",
  "oifde.mxgraph.NMS_ValidateError":"Validierung fehlgeschlagen! Prüfen Sie die rot markierten Teile.",
  "oifde.mxgraph.saveAndNopublish":"Der Flow wurde erfolgreich gespeichert, konnte aber nicht veröffentlicht werden!",
  "oifde.mxgraph.publishSuccess":"Der Flow wurde erfolgreich gespeichert und veröffentlicht!",
  "oifde.mxgraph.publishFailed":"Flow konnte nicht gespeichert und veröffentlicht werden!",
  "oifde.mxgraph.saveAndPublish":"Speichern und veröffentlichen",
  "oifde.mxgraph.NMS_SpaceValidate":"Der Wert darf nicht mit einem Leerzeichen beginnen.",
  "oifde.mxgraph.NMS_KeyRepeats":"Doppelter Bedingungsname.",
  "oifde.mxgraph.NMS_encryptValidate":"Die verschlüsselte IVRREQUEST-Variable, die FLOW-Variable und die GLOBAL-Variable können nur verschlüsselten FLOW-Variablen und GLOBAL-Variablen zugewiesen werden.",
  "oifde.mxgraph.NMS_timeSelectLengthValidate":"Es muss mindestens ein Datensatz für den Servicezeitraum konfiguriert werden.",
  "oifde.knowledgegraph.actions.edit":"Bearbeiten",
  "oifde.knowledgegraph.actions.view":"Ansicht",
  "oifde.knowledgegraph.actions.delete":"Löschen",
  "oifde.knowledgegraph.actions.clean":"Löschen",
  "oifde.knowledgegraph.actions.prompt":"Informationen",
  "oifde.knowledgegraph.actions.confirm":"OK",
  "oifde.knowledgegraph.actions.nameRequiredTip":"Geben Sie einen Namen ein.",
  "oifde.knowledgegraph.actions.selectTip":"-Auswahl-",
  "oifde.knowledgegraph.actions.cancel":"Abbrechen",
  "oifde.knowledgegraph.actions.cancelDelete":"Der Löschvorgang wurde abgebrochen.",
  "oifde.knowledgegraph.actions.success":"Erfolg",
  "oifde.knowledgegraph.actions.updateSuccess":"Änderung erfolgreich!",
  "oifde.knowledgegraph.actions.deleteSuccess":"Löschung erfolgreich!",
  "oifde.knowledgegraph.actions.createSuccess":"Hinzufügen erfolgreich!",
  "oifde.knowledgegraph.actions.createFailed":"Hinzufügen fehlgeschlagen!",
  "oifde.knowledgegraph.actions.modifyFailed":"Änderung fehlgeschlagen!",
  "oifde.knowledgegraph.actions.deleteFailed":"Löschung fehlgeschlagen!",
  "oifde.knowledgegraph.actions.saveSuccess":"Speichern erfolgreich!",
  "oifde.knowledgegraph.actions.saveFailed":"Speichern fehlgeschlagen!",
  "oifde.knowledgegraph.actions.addSchema":"Schema hinzufügen",
  "oifde.knowledgegraph.actions.editSchema":"Schema bearbeiten",
  "oifde.knowledgegraph.actions.addDic":"Diagrammwörterbuch hinzufügen",
  "oifde.knowledgegraph.actions.editDic":"Diagrammwörterbuch bearbeiten",
  "oifde.knowledgegraph.actions.addEntity":"Entität hinzufügen",
  "oifde.knowledgegraph.actions.editEntity":"Entität bearbeiten",
  "oifde.knowledgegraph.actions.addRelation":"Beziehung hinzufügen",
  "oifde.knowledgegraph.actions.editRelation":"Beziehung bearbeiten",
  "oifde.knowledgegraph.actions.addAttribute":"Attribut hinzufügen",
  "oifde.knowledgegraph.actions.editAttribute":"Attribut bearbeiten",
  "oifde.knowledgegraph.actions.addSynonyms":"Synonym hinzufügen",
  "oifde.knowledgegraph.actions.editSynonyms":"Synonym bearbeiten",
  "oifde.knowledgegraph.actions.addExtQuestion":"Neue erweiterte Frage",
  "oifde.knowledgegraph.actions.editExtQuestion":"Erweiterte Frage bearbeiten",
  "oifde.knowledgegraph.actions.viewSchema":"Schema anzeigen",
  "oifde.knowledgegraph.actions.graphicPreview":"Diagramm anzeigen",
  "oifde.knowledgegraph.actions.addSchemaEntity":"Konzept hinzufügen",
  "oifde.knowledgegraph.actions.editSchemaEntity":"Konzept bearbeiten",
  "oifde.knowledgegraph.model.schemaName":"Schemaname",
  "oifde.knowledgegraph.model.schemaCode":"Schemacode",
  "oifde.knowledgegraph.model.schemaDescription":"Beschreibung",
  "oifde.knowledgegraph.model.modifyTime":"Geändert",
  "oifde.knowledgegraph.model.operation":"Betrieb",
  "oifde.knowledgegraph.model.createUser":"Erstellt von",
  "oifde.knowledgegraph.model.beforeDeletePrompt":"Das Schema kann nicht gelöscht werden, da es dem folgenden Diagrammwörterbuch zugeordnet ist:“",
  "oifde.knowledgegraph.model.beforeEditPrompt":"Das aktuelle Schema kann nicht geändert werden, da es mit dem folgenden Diagrammwörterbuch verknüpft ist:“",
  "oifde.knowledgegraph.model.deletePrompt":"Durch diesen Vorgang werden das Schema und die zugehörigen Konzepte, Beziehungen und Attribute dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.editSchema.entity":"Konzept",
  "oifde.knowledgegraph.editSchema.entityName":"Konzeptname",
  "oifde.knowledgegraph.editSchema.relation":"Beziehung",
  "oifde.knowledgegraph.editSchema.relationName":"Name der Beziehung",
  "oifde.knowledgegraph.editSchema.headEntity":"Leitkonzept",
  "oifde.knowledgegraph.editSchema.tailEntity":"Schwanzkonzept",
  "oifde.knowledgegraph.editSchema.selectTip":"---Auswahl---",
  "oifde.knowledgegraph.editSchema.objectName":"Konzept/Beziehung",
  "oifde.knowledgegraph.editSchema.attributeName":"Attributname",
  "oifde.knowledgegraph.editSchema.attributeType":"Attributtyp",
  "oifde.knowledgegraph.editSchema.unit":"Einheit",
  "oifde.knowledgegraph.editSchema.type":"Typ",
  "oifde.knowledgegraph.editSchema.schemaNoDataTip":"Fügen Sie im linken Bereich Konzept- und Beziehungsdaten hinzu. Um den Attributinhalt anzuzeigen, klicken Sie auf Ansicht rechts neben dem entsprechenden Konzept oder der entsprechenden Beziehung.",
  "oifde.knowledgegraph.editSchema.deleteEntityPrompt":"Dieser Vorgang löscht das Konzept und seine Attribute dauerhaft. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.editSchema.deleteRelationPrompt":"Dieser Vorgang löscht die Beziehung und ihre Attribute endgültig. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.editSchema.deleteAttributePrompt":"Dieser Vorgang löscht das Attribut dauerhaft. Möchten Sie den Vorgang wirklich fortsetzen?",
  "oifde.knowledgegraph.editSchema.dataType.int":"Ganzzahl",
  "oifde.knowledgegraph.editSchema.dataType.float":"Gleitkommazahl",
  "oifde.knowledgegraph.editSchema.dataType.boolean":"Boolesch",
  "oifde.knowledgegraph.editSchema.dataType.string":"Charakter",
  "oifde.knowledgegraph.editSchema.dataType.date":"Datum",
  "oifde.knowledgegraph.dicmgr.dicName":"Diagrammname",
  "oifde.knowledgegraph.dicmgr.dicCode":"Diagrammcode",
  "oifde.knowledgegraph.dicmgr.dicFunction":"Diagrammnutzung",
  "oifde.knowledgegraph.dicmgr.modifyTime":"Geändert",
  "oifde.knowledgegraph.dicmgr.schemaName":"Zugeordnetes Schema",
  "oifde.knowledgegraph.dicmgr.kgState":"Status",
  "oifde.knowledgegraph.dicmgr.operation":"Betrieb",
  "oifde.knowledgegraph.dicmgr.moreOperation":"Mehr",
  "oifde.knowledgegraph.dicmgr.entityMgr":"Entität verwalten",
  "oifde.knowledgegraph.dicmgr.relationMgr":"Beziehungsmanagement",
  "oifde.knowledgegraph.dicmgr.kgView":"Diagrammvisualisierung",
  "oifde.knowledgegraph.dicmgr.kgFAQ":"Häufig gestellte Fragen zu Diagrammen",
  "oifde.knowledgegraph.dicmgr.active":"Aktiv",
  "oifde.knowledgegraph.dicmgr.inactive":"Inaktiv",
  "oifde.knowledgegraph.dicmgr.creating":"Erstellen",
  "oifde.knowledgegraph.dicmgr.trainAll":"Alle trainieren",
  "oifde.knowledgegraph.dicmgr.trainSuccess":"Die Schulung ist erfolgreich!",
  "oifde.knowledgegraph.dicmgr.trainFail":"Schulung fehlgeschlagen!",
  "oifde.knowledgegraph.dicmgr.changeState":"Status ändern",
  "oifde.knowledgegraph.dicmgr.synonyms":"Synonym",
  "oifde.knowledgegraph.dicmgr.extendQuestion":"Erweiterte Frage",
  "oifde.knowledgegraph.dicmgr.deletePrompt":"Dieser Vorgang löscht das Wörterbuch und die zugehörigen Entitäten, Beziehungen und Attribute dauerhaft. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.entitymgr.entityName":"Entitätsname",
  "oifde.knowledgegraph.entitymgr.schemaEntityName":"Konzept des zugehörigen Schemas",
  "oifde.knowledgegraph.entitymgr.beforeDeletePrompt":"Löschen Sie die Beziehung der Entität, und löschen Sie dann die Entität.",
  "oifde.knowledgegraph.entitymgr.deletePrompt":"Dieser Vorgang löscht die Entität dauerhaft. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.entitymgr.entityAttributeMgr.deletePrompt":"Dieser Vorgang löscht das Entitätsattribut dauerhaft. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.relationmgr.headEntity":"Hauptentität",
  "oifde.knowledgegraph.relationmgr.tailEntity":"Schwanzentität",
  "oifde.knowledgegraph.relationmgr.relation":"Beziehung",
  "oifde.knowledgegraph.relationmgr.doubleRelation":"Zweiseitige Beziehung",
  "oifde.knowledgegraph.relationmgr.doubleRelationTrue":"Ja",
  "oifde.knowledgegraph.relationmgr.doubleRelationFalse":"Nein",
  "oifde.knowledgegraph.relationmgr.doublePrompt":"Die Beziehung kann nicht hergestellt werden, weil die Kopf- und Schwanzentitäten identisch sind. Wählen Sie eine andere Kopf- oder Schwanzentität aus.",
  "oifde.knowledgegraph.relationmgr.deletePrompt":"Dieser Vorgang löscht die Beziehung und die zugehörigen Attribute endgültig. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.attributemgr.attributeName":"Attributname",
  "oifde.knowledgegraph.attributemgr.attribute":"Attributwert",
  "oifde.knowledgegraph.attributemgr.description":"Attributbeschreibung",
  "oifde.knowledgegraph.attributemgr.deletePrompt":"Dieser Vorgang löscht das Attribut endgültig. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.kgFAQ.title":"Häufig gestellte Fragen zu Diagrammen",
  "oifde.knowledgegraph.kgFAQ.name":"Diagrammwörterbuch:",
  "oifde.knowledgegraph.kgFAQ.welcomeContent":"Hallo! Wie kann ich Ihnen heute helfen?",
  "oifde.knowledgegraph.kgSynonyms.keyword":"Name",
  "oifde.knowledgegraph.kgSynonyms.synonyms":"Synonym",
  "oifde.knowledgegraph.kgSynonyms.number":"Nein.",
  "oifde.knowledgegraph.kgSynonyms.synonymsDetail":"Synonymdetails",
  "oifde.knowledgegraph.kgSynonyms.name":"Name",
  "oifde.knowledgegraph.kgSynonyms.deletePrompt":"Dieser Vorgang löscht den Eintrag und alle seine Synonyme dauerhaft. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.kgSynonyms.deleteSynonymsPrompt":"Durch diesen Vorgang wird das Synonym dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
  "oifde.knowledgegraph.kgSynonyms.type.type":"Typ",
  "oifde.knowledgegraph.kgSynonyms.type.entity":"Entität",
  "oifde.knowledgegraph.kgSynonyms.type.relation":"Beziehung",
  "oifde.knowledgegraph.kgSynonyms.type.property":"Attribut",
  "oifde.knowledgegraph.kgView.queryType":"Abfragetyp",
  "oifde.knowledgegraph.kgView.entityName":"Entitätsname",
  "oifde.knowledgegraph.kgView.displayType":"Anzeigemodus",
  "oifde.knowledgegraph.kgView.queryByName":"Nach Entitätsname",
  "oifde.knowledgegraph.validation.schemaName":"Geben Sie einen Schemanamen ein.",
  "oifde.knowledgegraph.validation.dicName":"Geben Sie einen Wörterbuchnamen ein.",
  "oifde.knowledgegraph.validation.selectRequired":"Dieses Feld ist obligatorisch.",
  "oifde.knowledgegraph.validation.attrValue":"Geben Sie einen Attributwert ein.",
  "oifde.knowledgegraph.validation.required":"Der Wert ist obligatorisch.",
  "oifde.knowledgegraph.errorCode.100010000000":"Systemfehler.",
  "oifde.knowledgegraph.errorCode.100010000003":"Der Name muss eindeutig sein.",
  "oifde.knowledgegraph.errorCode.100010000002":"Formatierungsfehler.",
  "oifde.knowledgegraph.errorCode.100051001013":"Dieses Schema wird derzeit verwendet.",
  "oifde.knowledgegraph.errorCode.100051001012":"Für den Namen wurde bereits ein Synonym konfiguriert.",
  "oifde.knowledgegraph.errorCode.100010000001":"Datenbankfehler.",
  "oifde.knowledgegraph.errorCode.100051000110":"Die Anzahl hat die Obergrenze von 10 erreicht.",
  "oifde.knowledgegraph.errorCode.100051000111":"Die Anzahl hat die Obergrenze von 190 erreicht.",
  "oifde.knowledgegraph.errorCode.100051000113":"Die für den GES-Dienst konfigurierte URL ist falsch.",
  "oifde.knowledgegraph.errorCode.100051000112":"Diagramm wird vorbereitet ...Bitte warten.",
  "oifde.knowledgegraph.errorCode.100061000005":"Der Wissensgraph ist erfolgreich gebunden!",
  "oifde.knowledgegraph.errorCode.100010000005":"Der Eingabeparameter ist falsch.",
  "oifde.knowledgegraph.errorCode.100051000114":"Die erweiterte Frage für die Diagramm-FAQ konnte nicht gespeichert werden!",
  "oifde.knowledgegraph.errorCode.100061000006":"Verbindung zum ODFS konnte nicht hergestellt werden!",
  "oifde.knowledgegraph.extendFaq.setting":"Erweiterte Frage festlegen",
  "oifde.knowledgegraph.extendFaq.import":"Importieren",
  "oifde.knowledgegraph.extendFaq.export":"Alle exportieren",
  "oifde.knowledgegraph.extendFaq.extendFaq":"FAQ-Vorlagenliste",
  "oifde.knowledgegraph.extendFaq.faqTemplate":"FAQ-Vorlage",
  "oifde.knowledgegraph.extendFaq.description":"Beschreibung",
  "oifde.knowledgegraph.extendFaq.updateTime":"Aktualisiert",
  "oifde.knowledgegraph.extendFaq.status":"Status",
  "oifde.knowledgegraph.extendFaq.setTemplate":"Erweiterte Frage festlegen",
  "oifde.knowledgegraph.extendFaq.use":"Verwenden/Nicht verwenden",
  "oifde.knowledgegraph.extendFaq.question":"Frage",
  "oifde.knowledgegraph.extendFaq.reply":"Antworten",
  "oifde.knowledgegraph.extendFaq.similarQuestion":"Ähnliche Frage",
  "oifde.knowledgegraph.extendFaq.similarReply":"Ähnliche Antwort",
  "oifde.knowledgegraph.extendFaq.edit":"Ändern -",
  "oifde.knowledgegraph.extendFaq.inUse":"Wird verwendet",
  "oifde.knowledgegraph.extendFaq.notUse":"Wird nicht verwendet",
  "oifde.knowledgegraph.extendFaq.operation":"Betrieb",
  "oifde.knowledgegraph.extendFaq.updateSuccess":"Der Status wurde erfolgreich geändert!",
  "oifde.knowledgegraph.extendFaq.updateFailed":"Status konnte nicht geändert werden!",
  "oifde.knowledgegraph.extendFaq.updateTemplateSuccess":"Die FAQ-Vorlage wurde erfolgreich geändert!",
  "oifde.knowledgegraph.extendFaq.updateTemplateFailed":"Die FAQ-Vorlage konnte nicht geändert werden!",
  "oifde.whitelist.tenantId":"Mieter-Raum-ID",
  "oifde.whitelist.tenantSpace":"Tenant Space",
  "oifde.whitelist.whiteIp":"IP-Adresse oder Domänenname der Vertrauensliste",
  "oifde.whitelist.whitePort":"Vertrauenslistenport",
  "oifde.whitelist.whiteType":"Vertrauenslistentyp",
  "oifde.whitelist.description":"Beschreibung",
  "oifde.whitelist.whiteTypes.INTERFACE":"Schnittstelle",
  "oifde.whitelist.whiteTypes.PROCEDURE":"Gespeicherte Prozedur",
  "oifde.whitelist.addWhiteList":"Vertrauensliste hinzufügen",
  "oifde.whitelist.editWhiteList":"Vertrauensliste bearbeiten",
  "oifde.whitelist.whiteIpLen":"Die IP-Adresse oder der Domänenname der Vertrauensliste darf maximal 128 Zeichen enthalten.",
  "oifde.whitelist.whitePortLen":"Die Portnummer der Vertrauensliste darf maximal 20 Zeichen enthalten.",
  "oifde.whitelist.descriptionLen":"Die Beschreibung darf maximal 1024 Zeichen enthalten.",
  "oifde.whitelist.number":"Der Wert muss aus Ziffern bestehen.",
  "oifde.whitelist.whiteIpListNotNull":"Wählen Sie mindestens eine Vertrauensliste aus, die gelöscht werden soll.",
  "oifde.whitelist.deletePrompt":"Dieser Vorgang löscht die ausgewählte Vertrauensliste endgültig. Möchten Sie wirklich fortfahren?",
  "oifde.whitelist.ipRange":"Die IP-Adresse darf nur Buchstaben, Ziffern, Punkte (.), Unterstriche (_), und Bindestriche (-) enthalten. Es darf nicht mit einem Bindestrich beginnen oder enden.",
  "oifde.operationlog.generate_test_case":"Automatische Fallerstellung",
  "oifde.operationlog.stop_auto_generate":"Unterbrochene Fallerstellung",
  "oifde.operationlog.logging_module":"Modul",
  "oifde.operationlog.operation_log":"Vorgangsprotokoll",
  "oifde.operationlog.operator":"Konto",
  "oifde.operationlog.operation_obj_type":"Operationsobjekttyp",
  "oifde.operationlog.startTime":"Startzeit",
  "oifde.operationlog.endTime":"Endzeit",
  "oifde.operationlog.operationType":"Vorgangstyp",
  "oifde.operationlog.targetObjType":"Objekttyp",
  "oifde.operationlog.targetObjId":"Objekt-ID",
  "oifde.operationlog.targetObjName":"Objekt",
  "oifde.operationlog.result":"Ergebnis",
  "oifde.operationlog.detail":"Einzelheiten",
  "oifde.operationlog.operationTime":"Letzter Vorgang",
  "oifde.operationlog.loginIp":"IP-Adresse für die Anmeldung",
  "oifde.operationlog.check":"Validieren",
  "oifde.operationlog.query":"Abfrage",
  "oifde.operationlog.create":"Erstellen",
  "oifde.operationlog.copy":"Kopieren",
  "oifde.operationlog.paste":"Einfügen",
  "oifde.operationlog.clone":"Klonen",
  "oifde.operationlog.import":"Importieren",
  "oifde.operationlog.export":"Exportieren",
  "oifde.operationlog.update":"Aktualisieren",
  "oifde.operationlog.delete":"Löschen",
  "oifde.operationlog.enable":"Aktivieren",
  "oifde.operationlog.disable":"Deaktivieren",
  "oifde.operationlog.set_test":"Auf Test einstellen",
  "oifde.operationlog.hang_up":"Auflegen",
  "oifde.operationlog.release":"Veröffentlichen",
  "oifde.operationlog.invalidate":"Ungültig machen",
  "oifde.operationlog.upgrade":"Aktualisierung",
  "oifde.operationlog.set_white_list":"Graue Regeln festlegen",
  "oifde.operationlog.intent_template":"Absichtsvorlage",
  "oifde.operationlog.user_entity":"Benutzerentität",
  "oifde.operationlog.system_entity":"Systemeinheit",
  "oifde.operationlog.voice_content":"Stimmendatei",
  "oifde.operationlog.system_parameters":"Systemparameter",
  "oifde.operationlog.intent_parameters":"Absichtsparameter",
  "oifde.operationlog.call_session":"Anrufdialog",
  "oifde.operationlog.cluster_task":"Clustering-Aufgabe",
  "oifde.operationlog.history_dialogue":"Historischer Dialog",
  "oifde.operationlog.test_case_group":"Testfallgruppe",
  "oifde.operationlog.test_case":"Testfall",
  "oifde.operationlog.faq":"FAQ-Gruppe",
  "oifde.operationlog.faq_element":"Fragen und Antworten",
  "oifde.operationlog.log_parameters":"Protokollparameter",
  "oifde.operationlog.tps_interface":"Serviceschnittstelle",
  "oifde.operationlog.nms_interface":"Dialogprozessschnittstelle",
  "oifde.operationlog.white_list":"Graue Wissensregel",
  "oifde.operationlog.white_list_users":"Teilnehmernummer in grauer Wissensregel",
  "oifde.operationlog.sensitive_words":"Sensibles Wort",
  "oifde.operationlog.stop_words":"Stoppwort",
  "oifde.operationlog.navigation_info":"Navigationsinformationen",
  "oifde.operationlog.command":"Befehl",
  "oifde.operationlog.api_key":"API-Schlüssel",
  "oifde.operationlog.trace_log":"Tracking-Protokolleinstellungen",
  "oifde.operationlog.succeed":"Erfolg",
  "oifde.operationlog.failed":"Fehler",
  "oifde.operationlog.domain":"Domäne",
  "oifde.operationlog.service_node":"Dienstknoten",
  "oifde.operationlog.online":"Online",
  "oifde.operationlog.offline":"Offline",
  "oifde.operationlog.flow":"Fluss",
  "oifde.operationlog.performance_data":"Leistungsdaten",
  "oifde.operationlog.login":"Anmelden",
  "oifde.operationlog.logout":"Abmeldung",
  "oifde.operationlog.change_password":"Passwort ändern",
  "oifde.operationlog.reset_password":"Passwort zurücksetzen",
  "oifde.operationlog.authorize":"Zertifizierung",
  "oifde.operationlog.tenant_space":"Tenant Space",
  "oifde.operationlog.account":"Konto",
  "oifde.operationlog.role":"Rolle",
  "oifde.operationlog.password_rule":"Kennwortregel",
  "oifde.operationlog.password_rule_group":"Kennwortregelgruppe",
  "oifde.operationlog.user_account":"Konto",
  "oifde.operationlog.user_role":"Rolle",
  "oifde.operationlog.knowledge_graph":"Wissensdiagramm",
  "oifde.operationlog.knowledge_graph_synonym":"Synonym des Wissensgraphen",
  "oifde.operationlog.acess_code":"Zugangscode",
  "oifde.operationlog.gray_rule":"Graue Regel",
  "oifde.operationlog.resource_template":"Ressourcenvorlage",
  "oifde.operationlog.business_interface":"Geschäftsschnittstelle",
  "oifde.operationlog.variable_manage":"Variables Management",
  "oifde.operationlog.flume_log_cfg":"Flume-Protokollkonfiguration",
  "oifde.operationlog.procedure_db":"Datenbank für gespeicherte Verfahren",
  "oifde.operationlog.procedure_interface":"Gespeicherte Prozedur",
  "oifde.operationlog.complexcellcatalog":"Elementkatalog des zusammengesetzten Diagramms",
  "oifde.operationlog.complexcell":"Zusammengesetztes Diagrammelement",
  "oifde.operationlog.lock_account":"Konto sperren",
  "oifde.operationlog.unlock_account":"Konto entsperren",
  "oifde.operationlog.kick_account":"Sitzungen löschen",
  "oifde.operationlog.start":"Starten",
  "oifde.operationlog.stop":"Stopp",
  "oifde.operationlog.log_open_task":"Aufgabe zur Protokollaktivierung",
  "oifde.operationlog.activate":"Aktivieren",
  "oifde.operationlog.deactivate":"Pause",
  "oifde.operationlog.illeagal_status":"Illegaler Status",
  "oifde.operationlog.file":"Datei",
  "oifde.operationlog.trace_log_query":"Protokollverfolgung und Abfrage",
  "oifde.operationlog.staticcheck":"Statische Prüfung",
  "oifde.operationlog.unkonw":"Unbekannt",
  "oifde.operationlog.interface_white_list":"Vertrauensliste",
  "oifde.operationlog.license":"Lizenz",
  "oifde.operationlog.test_corpus":"Korpustest",
  "oifde.operationlog.test":"Testen",
  "oifde.operationlog.refresh":"Aktualisieren",
  "oifde.operationlog.train":"Zug",
  "oifde.operationlog.trainAll":"Alle trainieren",
  "oifde.operationlog.lable_corpus":"Markierter Korpus",
  "oifde.operationlog.knowledge_graph_schema":"Wissensschema",
  "oifde.operationlog.knowledge_graph_ask_template":"Erweiterte Frage",
  "oifde.operationlog.knowledge_graph_entity":"Diagrammwörterbuch-Entität",
  "oifde.operationlog.knowledge_graph_relation":"Graph-Wörterbuchbeziehung",
  "oifde.operationlog.knowledge_graph_object_attribute":"Graph-Wörterbuch-Beziehungsattribut",
  "oifde.operationlog.knowledge_graph_object_entity":"Diagramm-Wörterbuch-Entitätsattribut",
  "oifde.operationlog.knowledge_graph_schema_entity":"Wissensschemaentität",
  "oifde.operationlog.knowledge_graph_schema_relation":"Beziehung des Wissensschemas",
  "oifde.operationlog.knowledge_graph_schema_attribute":"Wissensschemaattribut",
  "oifde.operationlog.knowledge_graph_ask_template_group":"Erweiterte Frage darstellen",
  "oifde.operationlog.knowledge_graph_ask_template_element":"FAQ-Vorlage für erweiterte Fragen zu Diagrammen",
  "oifde.operationlog.encrypt_Secret":"Geheimnis verschlüsseln",
  "oifde.operationlog.flow_trace_log":"Konfiguration der Prozesstestverfolgung",
  "oifde.operationlog.channel_type":"Kanal",
  "oifde.operationlog.response_style":"Stil",
  "oifde.operationlog.resource_template_exce":"Ausnahmeressourcenvorlage",
  "oifde.operationlog.setting":"Einrichtung",
  "oifde.operationlog.quick_release":"Publizieren mit einem Klick",
  "oifde.operationlog.download":"Herunterladen",
  "oifde.operationlog.upload":"Hochladen",
  "oifde.operationlog.binding_domain":"Chatbot-Domäne",
  "oifde.operationlog.binding_faqgroup":"Chatbot-FAQ-Gruppe",
  "oifde.operationlog.binding_kg":"Chatbot-Diagramm",
  "oifde.operationlog.binding":"Binden",
  "oifde.operationlog.guideflow":"Ablauf des Erstellungsassistenten",
  "oifde.operationlog.sensitive_group":"Sensible Wortgruppe",
  "oifde.operationlog.recordHistoryLog":"Dialogprotokoll",
  "oifde.operationlog.sentiment_analysis":"Modell der Stimmungsanalyse",
  "oifde.operationlog.use":"Verwendet",
  "oifde.operationlog.unused":"Wird nicht verwendet",
  "oifde.operationlog.tenantAsrName":"ASR-Konfiguration",
  "oifde.operationlog.tenantAsrTtsName":"TTS/ASR-Verwaltung",
  "oifde.operationlog.MRCPServerManagement":"Serververwaltung",
  "oifde.operationlog.insert":"Einfügen",
  "oifde.operationlog.exclude_entity":"Entitäten ausschließen",
  "oifde.sysConfig.sysParam":"Systemparameter",
  "oifde.sysConfig.intent_params":"Absichtsparameter",
  "oifde.sysConfig.code":"Variabler Code",
  "oifde.sysConfig.description":"Beschreibung der Variablen",
  "oifde.sysConfig.value":"Variabler Wert",
  "oifde.sysConfig.update":"Anwenden",
  "oifde.sysConfig.abort":"Abbrechen",
  "oifde.sysConfig.updateAll":"Alle Parameter anwenden",
  "oifde.sysConfig.restore_defaults":"Standardwerte wiederherstellen",
  "oifde.sysConfig.successful_operation":"Vorgang erfolgreich.",
  "oifde.sysConfig.confirm_apply_intent_param":"Die Änderung der Intentionsparameter wird die Intentionserkennung erheblich beeinflussen. Möchten Sie wirklich fortfahren?",
  "oifde.sysConfig.succeed_apply_intent_param":"Die Absichtsparameter wurden erfolgreich geändert!",
  "oifde.sysConfig.failed_apply_intent_param":"Absichtsparameter konnte nicht geändert werden!",
  "oifde.sysConfig.confirm_restore_defaults":"Möchten Sie die Standardwerte wirklich wiederherstellen?",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.NAME":"Chinesisches Vorverarbeitungsmodul",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.NAME":"Pinyin verwenden",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.DESC":"Ob Chinesisch in Pinyin umgewandelt werden soll.",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.TRUE":"Ja",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.FALSE":"Nein",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.NAME":"Ton verwenden",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.DESC":"Ob der Ton in Pinyin verwendet werden soll.",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.TRUE":"Ja",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.FALSE":"Nein",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.MULTI":"Jedes Vorkommen",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.ONCE":"Nur ein Vorkommen",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.TRUE":"Aktivieren",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.FALSE":"Deaktivieren",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.TRUE":"Aktivieren",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.FALSE":"Deaktivieren",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NAME":"N-Gram-Funktionsextraktionsmodul",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.NAME":"Min. N-Wert",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.DESC":"Minimaler N-Wert im N-Gram-Algorithmus.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.NAME":"Maximaler N-Wert",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.DESC":"Maximaler N-Wert im N-Gram-Algorithmus.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.NAME":"Min. Schwellenwert für die Wortgewichtung",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.DESC":"Ein Wort wird ignoriert, wenn seine Gewichtung unter diesem Schwellenwert liegt.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.NAME":"Statistiksammlung zu wiederholten Wörtern",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.DESC":"Ob Statistiken für jedes oder nur ein Vorkommen eines innerhalb eines Satzes wiederholten Wortes gesammelt werden sollen.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.NAME":"TSF-Algorithmus",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.DESC":"Ob der TSF-Algorithmus aktiviert werden soll.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.NAME":"Satzranderweiterung",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.DESC":"Ob Tags vor und nach einem Satz hinzugefügt werden sollen, wenn das N-Gram-Feature extrahiert wird.",
  "oifde.sysConfig.ENERGY_FILTER.NAME":"Absichtsmengen-Steuermodul",
  "oifde.sysConfig.ENERGY_FILTER.MAX.NAME":"Maximale Absichten",
  "oifde.sysConfig.ENERGY_FILTER.MAX.DESC":"Maximale Anzahl von Absichten, die in der endgültigen Entscheidung zurückgegeben wurden.",
  "oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.NAME":"Unbekanntes Absichtsfiltermodul",
  "oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.NAME":"Energieschwelle",
  "oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.DESC":"Die Absichten werden als unbekannt betrachtet, wenn die Summe aller erfassten Absichtsenergien kleiner oder gleich dem Schwellenwert ist.",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.NAME":"Absichtsklassifizierungsmodul",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.NAME":"μ",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.DESC":"μ Wert der logistischen Funktion des Absichtsklassifizierers.",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.NAME":"γ",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.DESC":"γ Wert der logistischen Funktion des Absichtsklassifizierers.",
  "oifde.sysConfig.INTENT_DECISION_MAKER.NAME":"Modul zur Absichtsentscheidungsfindung",
  "oifde.sysConfig.INTENT_DECISION_MAKER.TOP.NAME":"Obergrenze",
  "oifde.sysConfig.INTENT_DECISION_MAKER.TOP.DESC":"Die Absicht gilt als bekannt, wenn sie die Obergrenze des Absichtsentscheiders überschreitet.",
  "oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.NAME":"Untergrenze",
  "oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.DESC":"Die Absicht gilt als unbekannt, wenn sie unter die Untergrenze des Absichtsentscheiders fällt.",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.NAME":"CRF-Steckplatzmarkierungsmodul",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.NAME":"Lernrate",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.DESC":"Rate, mit der das CRF-Modelllernen durchgeführt wird.",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.NAME":"Anzahl der Baumknoten",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.DESC":"Anzahl der Knoten, die innerhalb jedes Baums im GBRT-Algorithmus enthalten sind.",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.NAME":"Baummenge",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.DESC":"Anzahl der Bäume im GBRT-Algorithmus.",
  "oifde.errorCode.100010000000":"Systemfehler.",
  "oifde.errorCode.100010000002":"Das Format ist falsch.",
  "oifde.errorCode.100051005001":"Blocklist und Trustlist konnten nicht gespeichert werden!",
  "oifde.errorCode.100070000001":"Die Daten konnten nicht importiert werden, da sie mit einer vorhandenen Ressource identisch sind!",
  "oifde.errorCode.100051005002":"Blocklist oder Trustlist konnte nicht gelöscht werden!",
  "oifde.errorCode.100051000006":"Die Datei konnte nicht importiert werden, da das Format ungültig ist!",
  "oifde.errorCode.100051005005":"Die Datei konnte nicht importiert werden, da sie zu groß ist!",
  "oifde.errorCode.100051005006":"Die Dienstnummer darf maximal 64 Zeichen enthalten!",
  "oifde.errorCode.100051005015":"Die Datei darf maximal 5000 Zeilen enthalten!",
  "oifde.errorCode.100051005003":"Die Datei konnte nicht importiert werden, da sie nicht gefunden werden kann!",
  "oifde.errorCode.100051005004":"Die Datei konnte nicht importiert werden, da das System die Datei nicht lesen kann!",
  "oifde.errorCode.100051006013":"Datei konnte nicht importiert werden!",
  "oifde.errorCode.100051005025":"Die Gesamtzahl darf 5000 nicht überschreiten!",
  "oifde.errorCode.100051005011":"Servicenummer konnte nicht hinzugefügt werden!",
  "oifde.fileTask.EMPTY_COMMAND":"Der Vorgangsbefehl darf nicht leer sein!",
  "oifde.fileTask.UNKNOWN_COMMAND":"Der Vorgangsbefehl ist nicht identifiziert!",
  "oifde.fileTask.INVALID_SALT":"Die Anfrage ist ungültig.",
  "oifde.fileTask.INVALID_REQUEST":"Die Anfrage ist ungültig.",
  "oifde.fileTask.EMPTY_TASK_ID":"Die Aufgaben-ID darf nicht leer sein.",
  "oifde.fileTask.TASK_NOT_FOUND":"Die Aufgabe wurde nicht gefunden.",
  "oifde.fileTask.SERVER_BUSY":"Das System ist derzeit ausgelastet.",
  "oifde.fileTask.SERVICE_UNAVAILABLE":"Der Dienst ist derzeit nicht verfügbar.",
  "oifde.fileTask.UPLOAD_EXCEPTION":"Der Upload wurde unterbrochen.",
  "oifde.fileTask.INVALID_ITEM_ENCODING":"Der Codierungsmodus ist ungültig.",
  "oifde.fileTask.INVALID_FILE_EXTENSION":"Die Dateinamenerweiterung ist ungültig.",
  "oifde.fileTask.WRITE_FILE_FAILED":"Datei konnte nicht gespeichert werden.",
  "oifde.fileTask.READ_FILE_FAILED":"Datei konnte nicht gelesen werden.",
  "oifde.fileTask.INVALID_FILE_PATH":"Der Dateipfad ist falsch.",
  "oifde.fileTask.FILE_NOT_EXIST":"Die Datei existiert nicht.",
  "oifde.fileTask.FILE_TOO_LARGE":"Das komprimierte Paket enthält Dateien, die zu groß sind.",
  "oifde.fileTask.ENTRY_TOO_LARGE":"Das komprimierte Paket enthält übergroße Dateien.",
  "oifde.fileTask.TOO_MANY_ENTRIES":"Das komprimierte Paket enthält zu viele Dateien.",
  "oifde.fileTask.INVALID_ENTRY_PATH":"Der Dateipfad im komprimierten Paket ist ungültig.",
  "oifde.fileTask.INVALID_ENTRY_EXTENSION":"Die Dateinamenerweiterung im komprimierten Paket ist ungültig.",
  "oifde.fileTask.UNZIPPED_TOO_LARGE":"Die Größe des dekomprimierten Pakets ist zu groß.",
  "oifde.fileTask.UNZIP_FAILED":"Paket konnte nicht dekomprimiert werden.",
  "oifde.fileTask.DOWNLOAD_EXCEPTION":"Der Download wurde unterbrochen.",
  "oifde.fileTask.UNDEFINED_ERROR":"Während der Aufgabenausführung ist ein Fehler aufgetreten.",
  "oifde.fileTask.INVALID_TASK_ARGS":"Der Aufgabenparameter fehlt.",
  "oifde.fileTask.INVALID_TASK_CONTENT":"Die Aufgabendaten sind ungültig.",
  "oifde.fileTask.GRAPH_IS_ON":"Ein aktives Diagramm kann nicht importiert werden.",
  "oifde.fileTask.INCORRECT_PARAMETERS":"Der Parameter ist ungültig.",
  "oifde.fileTask.KG_NO_SCHEMA":"Das Diagramm ist keinem Wissensschema zugeordnet.",
  "oifde.fileTask.IMPORT_KG_ENTITY_TOO_BIG":"Es sind maximal 1 Million Diagrammentitäten zulässig",
  "oifde.fileTask.IMPORT_KG_RELATION_TOO_BIG":"Es sind maximal 100 Millionen Diagrammbeziehungen zulässig.",
  "oifde.fileTask.IMPORT_SCHEMA_TOO_BIG":"In einem Wissensschema sind maximal 10.000-Konzeptentitäten zulässig.",
  "oifde.fileTask.IMPORT_SCHEMA_RELATION_TOO_BIG":"In einem Wissensschema sind maximal 10.000-Beziehungen zulässig.",
  "oifde.importFile.white_list":"Alle vorhandenen Einstellungen überschreiben",
  "oifde.importFile.test_case_group":"Alle vorhandenen Einstellungen überschreiben",
  "oifde.importFile.knowledge_graph":"Doppelte Namen überschreiben",
  "oifde.importFile.knowledge_graph_ask_template":"Alle vorhandenen Einstellungen überschreiben",
  "oifde.recordHistory.recordHistory":"Anrufverlauf",
  "oifde.recordHistory.recordDetail":"Anrufdetails",
  "oifde.recordHistory.userSaying":"Text anfordern",
  "oifde.recordHistory.systemReply":"Antworttext",
  "oifde.recordHistory.reqTime":"Angefordert",
  "oifde.recordHistory.respTime":"Reagierte",
  "oifde.recordHistory.callNumber":"Teilnehmernummer",
  "oifde.recordHistory.callStatus":"Überwachung eingehender Anrufe",
  "oifde.recordHistory.kgStatus":"Wissensüberwachung",
  "oifde.recordHistory.callNumberLength":"Die Teilnehmernummer darf nicht mehr als 64 Zeichen enthalten.",
  "oifde.recordHistory.oneCondition":"Entweder das Datum oder die Mobiltelefonnummer ist erforderlich.",
  "oifde.recordHistory.respSource":"Antwortquelle",
  "oifde.recordHistory.silentSeat":"Stummer Agent",
  "oifde.recordHistory.bot":"Chatbot",
  "oifde.recordHistory.customer":"Kunde",
  "oifde.error.messageHead":"Frontend-Schnittstelle -",
  "oifde.error.messageBody":"Anforderungsfehler. Fehlercode:",
  "oifde.error.messageTail":"Überprüfen Sie, ob Netzwerk und Server ordnungsgemäß funktionieren.",
  "oifde.testMgr.noSelection":"Testfälle können nicht generiert werden, da keine Domäne oder FAQ-Gruppe ausgewählt wurde.",
  "oifde.testMgr.abortSuccess":"Unterbrechung erfolgreich!",
  "oifde.testMgr.abortFailed":"Unterbrechung fehlgeschlagen!",
  "oifde.testMgr.getStatusWarn":"Testfälle können derzeit nicht generiert werden, da ein vorhandener Prozess die Generierung eines Testfalls angefordert hat. Bitte warten ...",
  "oifde.testMgr.noDomain":"Es wurde keine Domäne ausgewählt.",
  "oifde.testMgr.noFaqGroup":"Es wurde keine FAQ-Gruppe ausgewählt.",
  "oifde.testMgr.requestFailed":"Die Schnittstelle zum automatischen Generieren von Testfällen konnte nicht aufgerufen werden!",
  "oifde.testMgr.getCorpusTestCase":"Fall generieren",
  "oifde.testMgr.abortCorpusTestCase":"Unterbrechen",
  "oifde.testMgr.domain":"Domäne",
  "oifde.testMgr.faqGroup":"FAQ-Gruppe",
  "oifde.testMgr.getCorpusTestCaseConfig":"Fall generieren",
  "oifde.testMgr.allDomain":"Wählen Sie alle Domänen aus",
  "oifde.testMgr.allFaqGroup":"Wählen Sie alle FAQ-Gruppen aus",
  "oifde.testMgr.testMgr":"Wissenstest",
  "oifde.testMgr.chatBotTestCaseMgr":"Dialogtest",
  "oifde.testMgr.botTestCaseMgr":"Testfall verwalten",
  "oifde.testMgr.testCase":"Testfall",
  "oifde.testMgr.case_group":"Testfallgruppen",
  "oifde.testMgr.case_amount":"Testfälle",
  "oifde.testMgr.case_tested":"Geprüfte Menge",
  "oifde.testMgr.case_passed":"Bestanden",
  "oifde.testMgr.case_failed":"Fehlgeschlagen",
  "oifde.testMgr.case_passed_rate":"Erfolgsquote",
  "oifde.testMgr.test_time":"Geprüft",
  "oifde.testMgr.add_test_case_group":"Testfallgruppe hinzufügen",
  "oifde.testMgr.test_case_group_details":"Einzelheiten",
  "oifde.testMgr.descriptionLen":"Die Beschreibung darf maximal 1024 Zeichen enthalten.",
  "oifde.testMgr.number":"Es sind nur Ziffern zulässig.",
  "oifde.testMgr.auto_testing_template":"Vorlage herunterladen",
  "oifde.testMgr.execute_test":"Ausführen",
  "oifde.testMgr.manage":"Verwalten",
  "oifde.testMgr.case_id":"Fall-ID",
  "oifde.testMgr.userId":"Abonnenten-ID",
  "oifde.testMgr.expected_output":"Erwartetes Ausgabeschlüsselwort oder Absichtsname",
  "oifde.testMgr.actual_output":"Tatsächliche Ausgabe",
  "oifde.testMgr.actual_output_title":"Tatsächliche Ausgabe (Absichtsname in Klammern)",
  "oifde.testMgr.event":"Ereignis",
  "oifde.testMgr.note_test":"Nicht getestet",
  "oifde.testMgr.expected_output_with_memo":"Erwartete Ausgabeschlüsselwörter oder Absichtsnamen (mehrere Einträge durch Kommas trennen)",
  "oifde.testMgr.add_test_case":"Testfall hinzufügen",
  "oifde.testMgr.test_case_details":"Einzelheiten",
  "oifde.testMgr.execute_all":"Alle ausführen",
  "oifde.testMgr.batchExec":"Ausführen",
  "oifde.testMgr.succeed_save_case":"Der Testfall wurde erfolgreich gespeichert!",
  "oifde.testMgr.succeed_test_case":"Der Testfall wurde erfolgreich ausgeführt!",
  "oifde.testMgr.natureColon":":",
  "oifde.testMgr.failed_query":"Abfrage fehlgeschlagen!",
  "oifde.testMgr.succeed_save_group":"Die Testfallgruppe wurde erfolgreich gespeichert!",
  "oifde.testMgr.fail_save_data":"Die Testfallgruppe konnte nicht gespeichert werden!",
  "oifde.testMgr.select_prompt":"Wählen Sie mindestens einen Datensatz aus.",
  "oifde.testMgr.confirm_delete_case_group":"Möchten Sie die Testfallgruppe wirklich löschen?",
  "oifde.testMgr.failed_delete_group":"Testfallgruppe konnte nicht gelöscht werden.",
  "oifde.testMgr.succeed_delete_group":"Die Testfallgruppe wurde erfolgreich gelöscht!",
  "oifde.testMgr.confirm_delete_case":"Möchten Sie den Testfall wirklich löschen?",
  "oifde.testMgr.succeed_delete_case":"Der Testfall wurde erfolgreich gelöscht!",
  "oifde.testMgr.failed_delete_case":"Testfall konnte nicht gelöscht werden!",
  "oifde.testMgr.import_test_cases":"Testfall importieren",
  "oifde.testMgr.import_test_cases_hint":"Es können maximal 200 Testfälle im TXT-Format importiert werden.",
  "oifde.testMgr.overwrite_import":"Alle Einstellungen überschreiben",
  "oifde.testMgr.failed_import_file":"Datei konnte nicht importiert werden!",
  "oifde.testMgr.success_import":"Die Datei wurde erfolgreich importiert!",
  "oifde.hisdialog.startTime_must_earlier_endTime":"Die Startzeit muss vor der Endzeit liegen. Setzen Sie die Suchkriterien zurück.",
  "oifde.hisdialog.direction.UPLOAD":"Geben Sie den Anforderungstext ein.",
  "oifde.hisdialog.direction.DOWNLOAD":"Antworttext",
  "oifde.chatBotTest.rangeFilter":"Ausgewählte Datensätze filtern",
  "oifde.chatBotTest.botAccessCode":"Chatbot-Zugangscode",
  "oifde.chatBotTest.callingNum":"Anrufnummer",
  "oifde.chatBotTest.caseTimeRange":"Zeitbereich",
  "oifde.chatBotTest.getChatBotTestCase":"Dialogfall generieren",
  "oifde.chatBotTest.getChatBotTestCaseConfig":"Dialogfall generieren",
  "oifde.chatBotTest.abortChatBotTestCase":"Erzeugung eines Unterbrechungsdialogfalls",
  "oifde.chatBotTest.allAccessCode":"Wählen Sie alle Chatbot-Zugangscodes aus",
  "oifde.chatBotTest.deletePrompt":"Dieser Vorgang löscht die Testfallgruppe dauerhaft. Möchten Sie wirklich fortfahren?",
  "oifde.chatBotTest.deleteTestCase":"Dieser Vorgang löscht die ausgewählten Testfälle dauerhaft. Möchten Sie wirklich fortfahren?",
  "oifde.chatBotTest.checkPrompt":"Das gleichzeitige Ausführen mehrerer Testfälle kann das System verlangsamen. Möchten Sie wirklich fortfahren?",
  "oifde.chatBotTest.cancleExcute":"Die Hinrichtung wird abgebrochen.",
  "oifde.chatBotTest.inputTestCaseGroupName":"Name der Testfallgruppe",
  "oifde.chatBotTest.doTest":"Ausführen",
  "oifde.chatBotTest.executeSuccess":"Ausführung erfolgreich.",
  "oifde.chatBotTest.executeFailed":"Ausführung fehlgeschlagen!",
  "oifde.chatBotTest.downTestReport":"Testbericht herunterladen",
  "oifde.chatBotTest.downAllReport":"Alle Berichte herunterladen",
  "oifde.chatBotTest.testCaseGroup":"Testfallgruppen",
  "oifde.chatBotTest.totalNumber":"Testfälle",
  "oifde.chatBotTest.testTimes":"Geprüfte Menge",
  "oifde.chatBotTest.passTimes":"Bestanden",
  "oifde.chatBotTest.failTimes":"Fehlgeschlagen",
  "oifde.chatBotTest.passRate":"Erfolgsquote",
  "oifde.chatBotTest.testTime":"Geprüft",
  "oifde.chatBotTest.status":"Status",
  "oifde.chatBotTest.status.initial":"Initialisieren",
  "oifde.chatBotTest.status.waiting":"Warten",
  "oifde.chatBotTest.status.running":"Wird ausgeführt",
  "oifde.chatBotTest.status.finish":"Fertig stellen",
  "oifde.chatBotTest.status.failed":"Fehler",
  "oifde.chatBotTest.status.success":"Erfolg",
  "oifde.chatBotTest.description":"Beschreibung",
  "oifde.chatBotTest.addTestCaseGroup":"Testfallgruppe hinzufügen",
  "oifde.chatBotTest.editTestCaseGroup":"Testfallgruppe ändern",
  "oifde.chatBotTest.testCaseGroupName":"Name der Fallgruppe",
  "oifde.chatBotTest.robotName":"Chatbot-Name",
  "oifde.chatBotTest.callingId":"Anrufnummer",
  "oifde.chatBotTest.prompt":"Informationen",
  "oifde.chatBotTest.promptMessage":"Wählen Sie mindestens einen Datensatz aus.",
  "oifde.chatBotTest.downloadMessage":"Sie haben {0} Datensätze ausgewählt. Möchten Sie wirklich fortfahren?",
  "oifde.chatBotTest.downloadAllMessage":"Sie haben keine bestimmten Datensätze ausgewählt. Alle Datensätze exportieren? Sie können den Vorgang auch abbrechen und einen bestimmten Datensatz zum Exportieren auswählen.",
  "oifde.testCase.name":"Name des Testfalls",
  "oifde.testCase.status":"Status",
  "oifde.testCase.caseID":"Fall-ID",
  "oifde.testCase.doAll":"Alle ausführen",
  "oifde.testCase.caseName":"Fallname",
  "oifde.testCase.executeStatus":"Status",
  "oifde.testCase.description":"Beschreibung",
  "oifde.testCase.caseDetail":"Falldetails",
  "oifde.testCase.traceLog":"Flussspur",
  "oifde.testCase.callDetail":"Einzelheiten",
  "oifde.testCase.addTestCase":"Testfall hinzufügen",
  "oifde.testCase.editTestCase":"Testfall ändern",
  "oifde.testCase.caseDetailMgr":"Falldetails verwalten",
  "oifde.testCase.detailDialog":"Falldetails",
  "oifde.testCase.detail.reqText":"Text anfordern",
  "oifde.testCase.detail.expectedReturn":"Erwarteter Antworttext",
  "oifde.testCase.detail.channelAssociatedData":"Zugehörige Daten",
  "oifde.testCase.detail.operation":"Betrieb",
  "oifde.testCase.detail.realResponse":"Tatsächlicher Antworttext",
  "oifde.testCase.detail.close":"Schließen",
  "oifde.rate.accessCode":"Wählen Sie einen Chatbot-Zugangscode aus.",
  "oifde.rate.callCountBoard":"Anrufstatistik-Dashboard",
  "oifde.rate.resolveRate":"Netto-Self-Service-Lösungsrate",
  "oifde.rate.dayOption":"Nach Tag",
  "oifde.rate.hourOption":"Jede Stunde",
  "oifde.rate.monthOption":"Letzte 30 Tage",
  "oifde.rate.dateSelect":"Wählen Sie ein Datum.",
  "oifde.rate.index":"Nein.",
  "oifde.rate.time":"Datum und Uhrzeit",
  "oifde.rate.dataDimension":"Wählen Sie die Datendimension aus.",
  "oifde.rate.accessAmount":"Verbundene Anrufe",
  "oifde.rate.exitNoConsultCount":"Anrufe wurden direkt getrennt",
  "oifde.rate.callStaffNoConsultCount":"Anrufe werden nur vom Agenten bearbeitet",
  "oifde.rate.exitConsultingCount":"Anrufe werden nur von Chatbot bearbeitet",
  "oifde.rate.callStaffConsultCount":"Anrufe vom Chatbot an den Agenten weitergeleitet",
  "oifde.rate.interfaceTimes":"Aufrufzeiten der Schnittstelle",
  "oifde.rate.interfaceSuccessTimes":"Erfolgreich aufgerufene Schnittstellen",
  "oifde.rate.successRate":"Schnittstellenaufruf-Erfolgsquote",
  "oifde.rate.autonomyRate":"Netto-Self-Service-Lösungsrate",
  "oifde.rate.interactCount":"Dialogrunden",
  "oifde.rate.averageInteractPerCall":"Durchschnittliche Dialogrunden",
  "oifde.rate.accessNum":"Verbundene Anrufe",
  "oifde.rate.dayconditionTips":"Der Zugangscode fehlt in den Suchkriterien.",
  "oifde.rate.hourConditionTips":"Das Datum oder der Zugangscode fehlt in den Suchkriterien.",
  "oifde.rate.rateForCharts":"Netto-Self-Service-Lösungsrate",
  "oifde.rate.interfaceRequestCount":"Schnittstellenaufrufe",
  "oifde.rate.interfaceSuccessCount":"Erfolgreiche Aufrufe",
  "oifde.rate.interfaceSuccessRate":"Aufruferfolgsquote",
  "oifde.dashBoard.noData":"- (Gelöschte Daten können nicht angezeigt werden.)",
  "oifde.dashBoard.serviceCapabilityDashBoard":"Servicefähigkeits-Dashboard",
  "oifde.dashBoard.knowledgeDashBoard":"Wissens-Dashboard",
  "oifde.dashBoard.service.consultCount":"Antworten insgesamt",
  "oifde.dashBoard.service.responseSuccessCount":"Erfolgreiche Antworten",
  "oifde.dashBoard.service.unknowCount":"Unbekannte Antworten",
  "oifde.dashBoard.service.responseRate":"Antworterfolgsquote",
  "oifde.dashBoard.service.feedbackCount":"Gesamtes Feedback",
  "oifde.dashBoard.service.positiveFeedbackCount":"Zufriedenes Feedback",
  "oifde.dashBoard.service.positiveFeedbackRate":"Zufriedenheitsrate",
  "oifde.dashBoard.knowledge.dateConditionTips":"Wählen Sie ein Datum.",
  "oifde.dashBoard.knowledge.topTenChatBot":"Die 10 häufigsten Chatbot-Zugangscodes",
  "oifde.dashBoard.knowledge.accessCode":"Zugangscode",
  "oifde.dashBoard.knowledge.name":"Name",
  "oifde.dashBoard.knowledge.callCount":"Besuche",
  "oifde.dashBoard.knowledge.topTenknowledge":"Die 10 wichtigsten nützlichen Wissensentitäten",
  "oifde.dashBoard.knowledge.intent":"Absicht",
  "oifde.dashBoard.knowledge.faq":"Häufig gestellte Fragen",
  "oifde.dashBoard.knowledge.hitCount":"Treffer",
  "oifde.dashBoard.chartView.title":"Antworterfolgsquote",
  "oifde.dashBoard.chartView.countTile":"Antworten insgesamt",
  "oifde.dashBoard.chartView.rateTile":"Antworterfolgsquote",
  "oifde.dashBoard.chartView.leftTile":"Antworten insgesamt",
  "oifde.dashBoard.chartView.rightTile":"Antworterfolgsquote",
  "oifde.dashBoard.graph.title":"Gültige Wissensstatistiken",
  "oifde.dashBoard.graph.faq":"Häufig gestellte Fragen",
  "oifde.dashBoard.graph.intent":"Absicht",
  "oifde.dashBoard.graph.kg":"Diagramm",
  "oifde.dashBoard.graph.talk":"Chat",
  "oifde.dashBoard.graph.countTitle":"Gesamt",
  "oifde.dashBoard.graph.hitTitle":"Treffer",
  "oifde.dashBoard.graph.hitRateTitle":"Trefferrate",
  "oifde.dashBoard.graph.amount":"Anzahl",
  "oifde.dashBoard.graph.percent":"Prozentsatz",
  "oifde.dashBoard.graph.count":"Gesamt",
  "oifde.dashBoard.graph.hit":"Treffer",
  "oifde.dashBoard.graph.hitRate":"Trefferrate",
  "oifde.dashBoard.pie.title":"Wissensverteilung treffen",
  "oifde.dashBoard.pie.intentTitle":"Absicht",
  "oifde.dashBoard.pie.kgTitle":"Diagramm",
  "oifde.dashBoard.pie.faqTitle":"Häufig gestellte Fragen",
  "oifde.dashBoard.pie.talkTitle":"Chat",
  "oifde.dashBoard.pie.intent":"Absicht",
  "oifde.dashBoard.pie.kg":"Diagramm",
  "oifde.dashBoard.pie.faq":"Häufig gestellte Fragen",
  "oifde.dashBoard.pie.talk":"Chat",
  "oifde.chatbot.errorCode.100010000002":"Das Format ist falsch.",
  "oifde.chatbot.errorCode.100051000031":"Die Anzahl der Eingabe-IDs überschreitet die Obergrenze.",
  "oifde.chatbot.errorCode.100051000023":"Fallgruppe konnte nicht gelöscht werden!",
  "oifde.chatbot.errorCode.100010000000":"Systemfehler.",
  "oifde.chatbot.errorCode.100010000001":"Datenbankfehler.",
  "oifde.chatbot.errorCode.100010000003":"Der Name muss eindeutig sein.",
  "oifde.chatbot.errorCode.100010000005":"Der Eingabeparameter ist ungültig.",
  "oifde.chatbot.errorCode.100023000004":"Eingabe-ID konnte nicht verifiziert werden.",
  "oifde.chatbot.errorCode.100051000022":"Testfall konnte nicht gespeichert werden.",
  "oifde.chatbot.errorCode.100051000032":"Testfälle konnten nicht gelöscht werden.",
  "oifde.chatbot.errorCode.100051000026":"Der Test kann nicht ausgeführt werden, da die Anzahl der ausgewählten Testgruppen zu groß ist.",
  "oifde.chatbot.errorCode.100051000028":"Das Testergebnis wurde nicht gefunden.",
  "oifde.chatbot.errorCode.100051000109":"Die Anzahl der Testfälle übersteigt 200.",
  "oifde.serverMgr.serverType":"Servertyp",
  "oifde.serverMgr.mrcpId":"Gruppen-ID",
  "oifde.serverMgr.asrServer":"ASR-Server",
  "oifde.serverMgr.ttsServer":"TTS-Server",
  "oifde.serverMgr.confirmDelete":"Möchten Sie die Daten wirklich löschen?",
  "oifde.serverMgr.addServer":"Server hinzufügen",
  "oifde.serverMgr.editServer":"Server ändern",
  "oifde.qiAsrServerMgr.asrVendor":"Anbieter",
  "oifde.qiAsrServerMgr.asrServerName":"Servername",
  "oifde.qiAsrServerMgr.asrServerAddress":"Serveradresse",
  "oifde.qiAsrServerMgr.asrAppKey":"Benutzername/AK",
  "oifde.qiAsrServerMgr.fileServerAddress":"Dateiserveradresse",
  "oifde.qiAsrServerMgr.folder":"Standardordner",
  "oifde.qiAsrServerMgr.description":"Beschreibung",
  "oifde.qiAsrServerMgr.addQiAsrServer":"QC-ASR-Serverinformationen hinzufügen",
  "oifde.qiAsrServerMgr.editQiAsrServer":"QC-ASR-Serverinformationen bearbeiten",
  "oifde.qiAsrServerMgr.asrServerInfo":"Informationen zum ASR-Server",
  "oifde.qiAsrServerMgr.name":"Name",
  "oifde.qiAsrServerMgr.asrServerIP":"IP-Adresse des ASR-Servers",
  "oifde.qiAsrServerMgr.asrServerPort":"ASR-Serverport",
  "oifde.qiAsrServerMgr.asrServerUser":"ASR-Serverbenutzer",
  "oifde.qiAsrServerMgr.asrServerPW":"Kennwort des ASR-Servers",
  "oifde.qiAsrServerMgr.asrServerPWConform":"Kennwort des ASR-Servers bestätigen",
  "oifde.qiAsrServerMgr.nextStep":"Weiter",
  "oifde.qiAsrServerMgr.preStep":"Zurück",
  "oifde.qiAsrServerMgr.fileServerInfo":"Dateiserverinformationen",
  "oifde.qiAsrServerMgr.fileServerType":"Dateiservertyp",
  "oifde.qiAsrServerMgr.fileServerIP":"IP-Adresse des Dateiservers",
  "oifde.qiAsrServerMgr.fileServerPort":"Dateiserverport",
  "oifde.qiAsrServerMgr.fileServerUser":"Benutzername des Dateiservers",
  "oifde.qiAsrServerMgr.fileServerPW":"Kennwort des Dateiservers",
  "oifde.qiAsrServerMgr.fileServerPWConfirm":"Dateiserverkennwort bestätigen",
  "oifde.qiAsrServerMgr.voiceUploadFolder":"Pfad zum Hochladen von Aufzeichnungsdateien",
  "oifde.qiAsrServerMgr.qiResultFolder":"Umsetzungsergebnispfad",
  "oifde.qiAsrServerMgr.sftp":"SFTP",
  "oifde.qiAsrServerMgr.passwordError":"Die zweimal eingegebenen Kennwörter sind inkonsistent.",
  "oifde.qiAsrServerMgr.ipOrDomainNameError":"Falsche IP-Adresse oder falscher Domänenname.",
  "oifde.qiAsrServerMgr.ipError":"Falsche IP-Adresse.",
  "oifde.qiAsrServerMgr.filePathError":"Das Pfadformat ist falsch.",
  "oifde.qiAsrServerMgr.portTips":"Wenn es sich bei der ASR-Serveradresse um einen Domänennamen handelt, ist die Portnummer optional.",
  "oifde.qiAsrServerMgr.asrServerNameError":"Der ASR-Servername ist mit dem Standard-ASR-Servernamen identisch.",
  "oifde.asrMgr.tenantId":"Mieter-Raum-ID",
  "oifde.asrMgr.tenantSapce":"Tenant Space",
  "oifde.asrMgr.asr":"ASR-Serverlieferant",
  "oifde.asrMgr.qiAsrServerName":"Inspektions-ASR-Server",
  "oifde.asrMgr.mrcpid":"ASR-Gruppenkennung",
  "oifde.asrMgr.path":"Pfad der ASR-Grammatikdatei",
  "oifde.asrMgr.slotName":"ASR-Steckplatz",
  "oifde.asrMgr.groupID":"TTS-Gruppenkennung",
  "oifde.asrMgr.addAsr":"TTS/ASR hinzufügen",
  "oifde.asrMgr.editAsr":"TTS/ASR ändern",
  "oifde.asrMgr.selectOne":"Wählen Sie mindestens eine der folgenden Optionen aus: ASR-Serveranbieter, ASR-Server und TTS-Server.",
  "oifde.asrMgr.TENANT_SPACE_NOT_FOUND":"Der Mieterraum wurde nicht gefunden.",
  "oifde.asrMgr.languageTips":"Standard: Gibt an, dass die Sprache nicht unterschieden wird.",
  "oifde.license.license":"Lizenzverwaltung",
  "oifde.license.import":"Laden/Aktualisieren",
  "oifde.license.setlicense":"Lizenz zuweisen",
  "oifde.license.queryCurrentLicense":"Aktuelle Gleichzeitigkeit und Akkumulation abfragen",
  "oifde.license.queryHistoryLicense":"Abfrage historischer Gleichzeitigkeit",
  "oifde.license.valateDate":"Ablaufdatum",
  "oifde.license.totalLicense":"Lizenzmenge",
  "oifde.license.LIN8OIAPAA01":"Anzahl der gleichzeitigen Agentenassistenten",
  "oifde.license.LIN8OIAPVA01":"Anzahl der gleichzeitigen virtuellen Agenten",
  "oifde.license.LIN8OIAPVA02":"Kumulierte Anzahl der Anrufe von Virtual Agents",
  "oifde.license.PERMANENT":"Permanent",
  "oifde.license.processTime":"Verarbeitet",
  "oifde.license.at_least_one_serviceValue":"Legen Sie mindestens einen Parameter fest.",
  "oifde.license.failed_set_license":"Lizenz konnte nicht konfiguriert werden!",
  "oifde.license.success_set_license":"Die Lizenz wurde erfolgreich konfiguriert!",
  "oifde.license.import_template_hint_dat":"Es können nur Dateien im DAT-Format hochgeladen werden.",
  "oifde.license.import_template_need_dat":"Die hochzuladende Datei muss im DAT-Format vorliegen.",
  "oifde.license.maxHisInfo":"Historischer Maximalwert",
  "oifde.license.detailHisInfo":"Geschichtliche Details",
  "oifde.license.sys":"System",
  "oifde.request.timeout":"Timeout der Schnittstellenanfrage",
  "oifde.analysis.validate.time":"Erforderlichkeitsdatum",
  "oifde.analysis.validate.accessCode":"Roboter-Zugangscode erforderlich",
  "oifde.analysis.validate.timeInterval":"Das Intervall beträgt nicht mehr als 7 Tage.",
  "oifde.analysis.chart.title":"Statistiken der IVR-Knotenverzweigung",
  "oifde.analysis.chart.timeOther":"Zeitüberschreitung oder andere Taste drücken",
  "oifde.analysis.chart.button":"Tastendruck ",
  "oifde.analysis.chart.default":"Standardzweig des Systems",
  "oifde.analysis.childrenView":"Unterknoten anzeigen",
  "oifde.service.invoke.breakMinTime.desc":"Einheit: Sekunden;",
  "oifde.ivrJourney.nodeOverview":"Knotenübersicht",
  "oifde.ivrJourney.trendAnalysis":"Trendanalyse",
  "oifde.ivrJourney.keyDistribution":"Schlüsselverteilung",
  "oifde.ivrJourney.nodeVersionComparison":"Knotenversionsvergleich",
  "oifde.ivrJourney.businessRanking":"Unternehmensrankings",
  "oifde.ivrJourney.no":"Rangfolge",
  "oifde.ivrJourney.nodeName":"Knotenname",
  "oifde.ivrJourney.visitsTotal":"Besuche",
  "oifde.ivrJourney.yoyValue":"MoM-Wert",
  "oifde.ivrJourney.increaseValue":"MoM-Rate",
  "oifde.ivrJourney.visitorTotal":"Besuche",
  "oifde.ivrJourney.successRate":"Erfolgsquote",
  "oifde.ivrJourney.failedRate":"Ausfallrate",
  "oifde.ivrJourney.hangUpRate":"Rate halten",
  "oifde.ivrJourney.journeyNumTrend":"Werttrendverteilung",
  "oifde.ivrJourney.efficiencyTrend":"Effizienztrendverteilung",
  "oifde.ivrJourney.complexityTrend":"Komplexitätstrendverteilung",
  "oifde.ivrJourney.repeatNum":"Wiederholen",
  "oifde.ivrJourney.directCalls":"Direkt",
  "oifde.ivrJourney.nonDirectCalls":"Nicht direkt",
  "oifde.ivrJourney.journeyNum":"Besuche",
  "oifde.ivrJourney.hangupNum":"Auflegen",
  "oifde.ivrJourney.successCalls":"Anzahl der Erfolge",
  "oifde.ivrJourney.failCalls":"Anzahl der Fehler",
  "oifde.ivrJourney.directRate":"Direktrate",
  "oifde.ivrJourney.nonDirectRate":"Keine direkte Rate",
  "oifde.ivrJourney.hangupRate":"Aufhängrate",
  "oifde.ivrJourney.failRate":"Ausfallrate",
  "oifde.ivrJourney.repeatCalls":"Wiederholen",
  "oifde.ivrJourney.repeatOnceCalls":"Einmal wiederholen",
  "oifde.ivrJourney.repeatTwiceCalls":"Zweimal anhören",
  "oifde.ivrJourney.repeatRate":"Wiederholfrequenz",
  "oifde.ivrJourney.node":"Knoten-",
  "oifde.ivrJourney.averageTime":"Durchschnittliche Servicedauer",
  "oifde.ivrJourney.averagePathLength":"Durchschnittliche Pfadlänge",
  "oifde.ivrJourney.timeRangeCheck30":"Das Intervall darf 30 Tage nicht überschreiten.",
  "oifde.ivrJourney.dateTimrRange":"Datenzeitbereich:",
  "oifde.ivrJourney.total":"Gesamt",
  "oifde.ivrJourney.inflowDistribution":"Zuflussverteilung",
  "oifde.ivrJourney.outflowDistribution":"Abflussverteilung",
  "oifde.ivrJourney.totalDistribution":"Gesamtverteilung",
  "oifde.ivrJourney.detailDistribution":"Detaillierte Verteilung",
  "oifde.ivrJourney.outflowDetailDistribution":"Detaillierte Outflow-Verteilung",
  "oifde.ivrJourney.outflowDetailDistributionAmount":"Detaillierter Verteilungsbetrag für Abfluss",
  "oifde.ivrJourney.outflowDetailDistributionPercentage":"Prozentsatz der detaillierten Abflussverteilung",
  "oifde.ivrJourney.nodeTrafficChart":"Knotenverkehrsdiagramm (Anzahl der Indikatoren)",
  "oifde.ivrJourney.indicatorQuantityStatistics":"Statistik der Indikatormenge",
  "oifde.ivrJourney.indicateName":"Indikatorname",
  "oifde.ivrJourney.alarmContent":"Inhalt der Vorwarnung",
  "oifde.ivrJourney.alarmTime":"Vorwarnzeit",
  "oifde.ivrJourney.createTime":"Datenzeit der Vorwarnung",
  "oifde.ivrJourney.alarmLevel":"Vorwarnstufe",
  "oifde.ivrJourney.alarmIndicator":"Vorwarnanzeigen",
  "oifde.ivrJourney.remindType":"Erinnerungsmodus",
  "oifde.ivrJourney.receivers":"Empfänger",
  "oifde.ivrJourney.sendResult":"Ergebnis wird gesendet",
  "oifde.ivrJourney.low":"Niedrig",
  "oifde.ivrJourney.middle":"Mitte",
  "oifde.ivrJourney.high":"Hoch",
  "oifde.ivrJourney.noNeedToSend":"Es muss nicht gesendet werden",
  "oifde.ivrJourney.sendFail":"Senden fehlgeschlagen",
  "oifde.ivrJourney.sendSuccess":"Erfolgreich gesendet",
  "oifde.ivrJourney.sms":"SMS",
  "oifde.ivrJourney.email":"E-Mail-Adresse",
  "oifde.ivrJourney.smsAndEmail":"SMS; E-Mail",
  "oifde.ivrJourney.other":"Sonstiges",
  "oifde.ivrJourney.nowData":"Aktueller Wert",
  "oifde.ivrJourney.label":"Identifizierer",
  "oifde.ivrJourney.transToManualCalls":"Anzahl der an manuelle Anrufe weitergeleiteten Anrufe",
  "oifde.ivrJourney.transToNextLevelManuCalls":"Fliessen in Menüs niedrigerer Ebenen",
  "oifde.ivrJourney.timeOutOrPressAnotherKey":"Zeitüberschreitung oder andere Taste drücken",
  "oifde.ivrJourney.buttons":"Schaltfläche",
  "oifde.ivrJourney.defaultBranch":"Standardzweig",
  "oifde.ivrJourney.count":"Zeiten",
  "oifde.ivrJourney.versionName":"Versionsname",
  "oifde.ivrJourney.compareRange":"Vergleichsumfang",
  "oifde.ivrJourney.pleaseSelectAccessCode":"Bitte wählen Sie einen Roboter aus",
  "oifde.ivrJourney.pleaseSelectAccessCodeFirst":"Bitte wählen Sie zuerst einen Roboter aus",
  "oifde.ivrJourney.oneDay":"1 Tag",
  "oifde.ivrJourney.threeDay":"3 Tage",
  "oifde.ivrJourney.halfMonth":"Einen halben Monat",
  "oifde.ivrJourney.oneMonth":"Einen Monat",
  "oifde.ivrJourney.chooseAccessCode":"Wählen Sie einen Roboter aus",
  "oifde.ivrJourney.chooseVersion":"Flow-Version auswählen",
  "oifde.ivrJourney.releaseDate":"Freigabezeit",
  "oifde.ivrJourney.nodeVersionCompareStatistics":"Statistiken zum Knotenversionsvergleich",
  "oifde.ivrJourney.validIVRService":"Gültiger IVR-Dienst",
  "oifde.ivrJourney.transToManualCallsSucc":"Übertragen an manuell erfolgreich",
  "oifde.ivrJourney.selfService":"Selbstbedienung",
  "oifde.ivrJourney.repeatIncomingCalls":"Wiederholte eingehende Anrufe",
  "oifde.ivrJourney.returnKeysByErrors":"Schlüsselfehler-Rückgabe",
  "oifde.ivrJourney.pleaseSelectVersion":"Wählen Sie eine Flow-Version aus.",
  "oifde.ivrJourney.pleaseSelectCompareRange":"Wählen Sie einen Vergleichsbereich aus",
  "oifde.ivrJourney.logTime":"LogTime",
  "oifde.ivrJourney.validIVRCalls":"Gültiges IVR-Dienstvolumen",
  "oifde.ivrJourney.IVRCalls":"Selbstbedienungsvolumen",
  "oifde.ivrJourney.IVRTransManualRate":"Anteil des manuellen Verkehrs (%)",
  "oifde.ivrJourney.IVRRate":"Rate des reinen IVR-Dienstes (%)",
  "oifde.ivrJourney.IVRRateNoSymbol":"Reine IVR-Servicerate",
  "oifde.ivrJourney.transManualSuccCalls":"Anzahl der erfolgreichen Anrufe, die an manuelle Dienste weitergeleitet wurden",
  "oifde.ivrJourney.transManualSuccRate":"Erfolgsquote für Übertragung auf manuellen Service (%)",
  "oifde.ivrJourney.avgIVRTime":"Durchschnittliche Anrufdauer (s)",
  "oifde.ivrJourney.avgIVRTimeNoSymbol":"Durchschnittliche Anrufdauer",
  "oifde.ivrJourney.IVRErrorRate":"IVR-Systemfehlerrate (%)",
  "oifde.ivrJourney.IVRErrorRateNoSymbol":"IVR-Systemfehlerrate",
  "oifde.ivrJourney.IVRTotalCalls":"Anzahl der eingehenden IVR-Anrufe",
  "oifde.ivrJourney.IVRSrvTime":"Gesamtdauer des IVR-Dienstes (s)",
  "oifde.ivrJourney.wrongKeyCalls":"Anzahl der aufgrund von Schlüsselfehlern zurückgegebenen Schlüssel",
  "oifde.ivrJourney.distinctCustomers":"Anzahl der Kunden",
  "oifde.ivrJourney.IVRSrvCustomers":"Anzahl der reinen IVR-Kunden",
  "oifde.ivrJourney.callNum":"Anruflautstärke",
  "oifde.ivrJourney.ivrRepeatCallNum":"IVR-Wiederholungsanrufe",
  "oifde.ivrJourney.resolveRate":"Rate der einmaligen Problembehebung",
  "oifde.ivrJourney.serviceRate":"Effektive Servicerate",
  "oifde.ivrJourney.indicatorDesc":"Indikatorbeschreibung",
  "oifde.ivrJourney.validIVRServiceDesc":"Anzahl der IVR-Anrufe, bei denen die Serviceabwicklung oder -abfrage erfolgreich ist, die Serviceberatungsstimme mehr als die Hälfte abgespielt wird oder die SMS, MMS, WAP-Push oder E-Mail erfolgreich ausgelöst werden",
  "oifde.ivrJourney.transToManualCallsDesc":"Anzahl der IVR-Anrufe, die versucht werden, an den manuellen Service weitergeleitet zu werden",
  "oifde.ivrJourney.transToManualCallsSuccDesc":"Anzahl der IVR-Anrufe, die erfolgreich an einen Agenten weitergeleitet wurden",
  "oifde.ivrJourney.selfServiceDesc":"Anzahl der IVR-Anrufe, die nicht an den manuellen Service weitergeleitet werden",
  "oifde.ivrJourney.repeatIncomingCallsDesc":"Anzahl der Kunden, die innerhalb von zwei Stunden wiederholt dieselbe Rufnummer verwenden, um die Kundendienst-Hotline anzurufen",
  "oifde.ivrJourney.returnKeysByErrorsDesc":"Im Navigationsknoten wird nach dem falschen Drücken einer Taste die von der Sternchentaste (*) zurückgegebene Nummer als Aufforderung ausgewählt.",
  "oifde.ivrJourney.indicatorSetting":"Anzeigeeinstellung",
  "oifde.ivrJourney.indicatorSettingLengthError":"Wählen Sie mindestens {count} Indikatoren aus.",
  "oifde.ivrJourney.extendedMetrics":"Erweiterte Metriken",
  "oifde.ivrJourney.optimizeVoiceSequence":"Optimierung der Sprachübertragungssequenz",
  "oifde.ivrJourney.optimizeVoiceDefinition":"Stimmdefinitionsoptimierung",
  "oifde.ivrJourney.pleaseSelectDateRange":"Wählen Sie einen Zeitraum aus.",
  "oifde.ivrJourney.reverseFlow":"Durchflussrichtung umkehren",
  "oifde.ivrJourney.currentVoicePlayContent":"Aktuelle Sprachübertragungsinhalte",
  "oifde.ivrJourney.repeatCallsNum":"Wiederholte Hörzeiten",
  "oifde.ivrJourney.optimizeSuggestion":"Optimierungsvorschläge",
  "oifde.ivrJourney.repeatCallOptimizeSuggestion":"Die Anzahl der Wiedergabezeiten des aktuellen Knotens ist zu hoch. Prüfen Sie, ob der Sprachinhalt unklar oder schwer zu verstehen ist. Es wird empfohlen, den Sprachinhalt zu optimieren.",
  "oifde.ivrJourney.operationStatus":"Betriebsstatus",
  "oifde.ivrJourney.poor":"Schwach",
  "oifde.ivrJourney.general":"Allgemein",
  "oifde.ivrJourney.good":"Gut",
  "oifde.ivrJourney.fluctuationService":"Fluktuationsdienst",
  "oifde.ivrJourney.IVRIncomingCallVolumeTrend":"Trend der Lautstärke eingehender IVR-Anrufe",
  "oifde.ivrJourney.efficiencyOverview":"Effizienzübersicht",
  "oifde.ivrJourney.repeatedCallRate":"Rate wiederholter Anrufe",
  "oifde.ivrJourney.valueOverview":"Wertübersicht",
  "oifde.ivrJourney.pureIVRServiceVolume":"Reines IVR-Dienstvolumen",
  "oifde.ivrJourney.complexityOverview":"Komplexitätsübersicht",
  "oifde.ivrJourney.personTime":"Zeiten",
  "oifde.ivrJourney.times":"Zeiten",
  "oifde.ivrJourney.noData":"Keine",
  "oifde.ivrJourney.usageStatistics":"Aktuelle Schlüsselnutzungsstatistiken für die Sprachwiedergabe",
  "oifde.ivrJourney.playingOrder":"Empfohlene Sprachübertragungssequenz",
  "oifde.ivrJourney.journeySum":"Genutzte Zeiten",
  "oifde.ivrJourney.forever":"Unbefristet gültig",
  "oifde.ivrJourney.slectTimeForever":"Gibt den Gültigkeitszeitraum an.",
  "oifde.ivrJourney.alarmIndex":"Statistische Indikatoren",
  "oifde.ivrJourney.alarmCondition":"Vorwarnzustand",
  "oifde.ivrJourney.alarmValidity":"Gültigkeitsdauer der Vorwarnung",
  "oifde.ivrJourney.selectAlarmCondition":"Wählen Sie den Warnungszustand aus, der gelöscht werden soll.",
  "oifde.ivrJourney.remindObject":"Erinnerungsobjekt",
  "oifde.ivrJourney.transfManualRate":"Rate der Übertragung auf manuell",
  "oifde.ivrJourney.absoluteValue":"Absoluter Wert",
  "oifde.ivrJourney.highThan":"Höher als",
  "oifde.ivrJourney.equal":"Ist gleich",
  "oifde.ivrJourney.lessThen":"Weniger als",
  "oifde.ivrJourney.createAlarmConfig":"Erstellen einer Service-Voralarmbedingung",
  "oifde.ivrJourney.editAlarmConfig":"Bearbeiten einer Service-Voralarmbedingung",
  "oifde.ivrJourney.selectAccesscodeAndFlow":"Wählen Sie einen Roboter und eine Prozessversion aus.",
  "oifde.ivrJourney.selectAlarmIndex":"Bitte wählen Sie einen statistischen Indikator.",
  "oifde.ivrJourney.fillAlarmCondition":"Bitte füllen Sie die Voralarmbedingungen aus.",
  "oifde.ivrJourney.selectAlarmLevel":"Wählen Sie eine Warnstufe aus.",
  "oifde.ivrJourney.selectTime":"Wählen Sie eine gültige Zeit aus.",
  "oifde.ivrJourney.selectAlramRemindType":"Wählen Sie mindestens einen Erinnerungsmodus aus.",
  "oifde.ivrJourney.selectRemindObject":"Wählen Sie ein Erinnerungsobjekt aus.",
  "oifde.ivrJourney.remindObjectMaxNum":"Für das Benachrichtigungsobjekt können maximal 20 Zeichen ausgewählt werden.",
  "oifde.ivrJourney.batchDetele":"Stapel löschen",
  "oifde.ivrJourney.selectSmsGateway":"Wählen Sie ein SMS-Gateway aus.",
  "oifde.ivrJourney.selectMailGateway":"Wählen Sie ein E-Mail-Gateway aus.",
  "oifde.ivrJourney.indicatorComparisonBarChart":"Zählervergleichsbalkendiagramm",
  "oifde.ivrJourney.indicatorComparisonStatistics":"Zählervergleichsstatistiken",
  "oifde.ivrJourney.beforeRelease":"Vor der Freigabe",
  "oifde.ivrJourney.afterRelease":"Nach der Freigabe",
  "oifde.ivrJourney.beforeReleaseRepeatRate":"Wiedergaberate vor Freigabe",
  "oifde.ivrJourney.afterReleaseRepeatRate":"Wiedergaberate nach Freigabe",
  "oifde.ivrJourney.beforeReleaseHangupNum":"Anzahl der Abbrüche vor der Freigabe",
  "oifde.ivrJourney.afterReleaseHangupNum":"Anzahl der Abbrüche nach der Freigabe",
  "oifde.headerTitle.flowManagement":"Flussverwaltung",
  "oifde.headerTitle.flowConfiguration":"Ablaufkonfiguration",
  "oifde.trackmgr.robotsTrack":"Roboter verfolgen",
  "oifde.trackmgr.robotsSource":"Quelle",
  "oifde.maintain.odfsLogOpen":"Aktivieren der ODFS-Protokollfunktion",
  "oifde.maintenance.level":"Ebene",
  "oifde.maintenance.startTime":"Effektive Zeit",
  "oifde.maintenance.duration":"Dauer (Minuten)",
  "oifde.maintenance.startLog":"Protokoll aktivieren",
  "oifde.maintenance.stop_success":"Protokoll aktivieren",
  "oifde.maintenance.start_success":"Die Protokollfunktion wurde erfolgreich aktiviert!",
  "oifde.route.transferRecord":"Prozessübertragungsdatensatz",
  "oifde.route.audioVideoResource":"Audio- und Videoressourcen",
  "oifde.serviceInterface.thirdAPILog":"Schnittstellenaufruf von Datensätzen",
  "oifde.serviceInterface.statusCode":"Statuscode",
  "oifde.serviceInterface.invokingTime":"Zeit wird aufgerufen",
  "oifde.serviceInterface.callId":"Anrufkennung",
  "oifde.serviceInterface.invokingDetails":"Anrufdetails",
  "oifde.title.chooseFlow":"Fluss auswählen",
  "oifde.tip.inputFlowName":"Geben Sie einen Flow-Namen ein",
  "oifde.error.createfail":"Lokale Daten konnten nicht hinzugefügt werden!",
  "oifde.error.gateway.fail":"Die Informationen konnten nicht mit der CTI-Plattform synchronisiert werden. Bitte überprüfen Sie die Gateway-Konfiguration.",
  "oifde.error.NMS.fail":"Synchronisation mit dem NMS fehlgeschlagen!",
  "oifde.flowname.maxlength":"Der Robotername ist zu lang.",
  "oifde.audioVideoResource.setLanguage":"Spracheinstellung",
  "oifde.audioVideoResource.preview":"Vorschau",
  "oifde.audioVideoResource.viewReferences":"Referenzen anzeigen",
  "oifde.audioVideoResource.cancelUpdate":"Aktualisierung abbrechen",
  "oifde.audioVideoResource.updateBefore":"Vor der Aktualisierung",
  "oifde.audioVideoResource.updateAfter":"Nach der Aktualisierung",
  "oifde.audioVideoResource.templateName":"Geben Sie einen Vorlagennamen ein.",
  "oifde.audio.speedRange":"Wertebereich: [- 200, 500]",
  "oifde.route.sysAudio":"Stimmenverwaltung",
  "oifde.title.chooseGrayRule":"Graue Regel auswählen",
  "oifde.title.chatbot":"Chatbot",
  "oifde.audioVideoResource.cancelUpdate.confirm":"Möchten Sie die Aktualisierung wirklich abbrechen?",
  "oifde.audioVideoResource.cancelUpdate.success":"Aktualisierung erfolgreich abgebrochen!",
  "oifde.audioVideoResource.cancelUpdate.fail":"Aktualisierung konnte nicht abgebrochen werden!",
  // "oifde.audioVideoResource.upload.tips":"Tipp:Laden Sie dieselbe Datei mehrmals hoch\, ändern Sie den Dateinamen\ oder wechseln Sie den Datei-Upload.",
  "oifde.robotReply.displayName":"Roboterantwort",
  "oifde.robotReply.replyWay":"Antwortweise",
  "oifde.robotReply.replyTemplate":"Antwortvorlage",
  "oifde.robotReply.selectReplyTemplate":"Vorlage auswählen",
  "oifde.robotReply.replyVariable":"Antwortvariable",
  "oifde.robotReply.replyResource":"Ressource antworten",
  "oifde.robotReply.selectReplyResource":"Ressource auswählen",
  "oifde.robotReply.replMode":"Antwortmodus",
  "oifde.robotReply.replyWayBreve":"Weg",
  "oifde.robotReply.replyResourceBreve":"Ressource",
  "oifde.robotReply.replyVariableBreve":"Variable",
  "oifde.robotReply.replyTemplateBreve":"Vorlage",
  "oifde.robotReply.replModeBreve":"Modus",
  "oifde.param.maxLength":"Die Parameterlänge darf , nicht überschreiten",
  "oifde.tip.oldNode":"Veraltete Diagrammelemente werden nicht empfohlen",
  "oifde.diagram.element.expired":"Abgelaufenes Element",
  "oifde.diagram.element.basic":"Basiselement",
  "oifde.diagram.element.logic":"Logisches Element",
  "oifde.diagram.element.business":"Dienstelement",
  "oifde.diagram.element.composite":"Verbundelement",
  "oifde.diagram.node.type":"Knotentyp",
  "oifde.validation.repeats.varName":"Doppelter Variablenname.",
  "oifde.procedure.mysqlUrlRule":"Falsches URL-Format. Weitere Informationen zum URL-Format finden Sie unter jdbc:mysql://ip:port/db_name?prop1=value1&prop2=value2.",
  // "oifde.procedure.oracleUrlRule":"Falsches URL-Format. Das URL-Format ist jdbc:oracle:thin:{\'@\'}ip:port:SID bzw. jdbc:oracle:thin:{\'@\'}//ip:port/service.",
  // "oifde.procedure.gaussUrlRule":"Falsches URL-Format. Weitere Informationen zum URL-Format finden Sie unter jdbc:zenith:{\'@\'}ip:port[?key=value[&key=value]..].",
  "oifde.chatBotTest.testCaseGroupName.maxLen":"Der Fallgruppenname darf nicht länger als 128 Zeichen sein.",
  "oifde.chatBotTest.testCaseName.maxLen":"Der Fallname darf nicht länger als 128 Zeichen sein.",
  "oifde.chatBotTest.createCase.maxLen":"Die Anzahl der ausgewählten Testfälle darf 100 nicht überschreiten.",
  "oifde.procedure.selectProcedure":"Gespeicherte Prozedur auswählen",
  "oifde.procedure.resultProcess":"Ergebnisverarbeitung",
  "oifde.robotReply.title.chooseTemplate":"Antwortvorlage auswählen",
  "oifde.robotReply.title.chooseResource":"Antwortressource auswählen",
  "oifde.element.varAssign":"Variablenzuweisung",
  "oifde.promptType.title":"Aufforderungstyp",
  "oifde.interfaceInvoke.header":"Schnittstellennachricht-Meader",
  "oifde.interfaceInvoke.body":"Schnittstelleneingabeparameter",
  "oifde.interfaceInvoke.queryParam":"Schnittstellenabfrageparameter",
  "oifde.interfaceInvoke.method.return":"Rückgabewert der Methode",
  "oifde.unit.millisecond":"Millisekunde",
  "oifde.title.transData":"Abrufen zugehöriger Daten",
  "oifde.robotReply.title.addResource":"Antwortressource hinzufügen",
  "oifde.mxgraph.saveDraft":"Entwurf speichern",
  "ccivr.nms.conditionService.expression.placeholder":"Geben Sie einen Bedingungsausdruck ein.",
  "oifde.diagram.error.basicEnd.length":"Der Wert darf 4096 Zeichen nicht überschreiten.",
  "oifde.tableData.template":"Datenvorlage herunterladen",
  "oifde.tableData.download.template":"Vorlage herunterladen",
  "oifde.tableData.import.max":"Es können maximal 10.000 Datensätze importiert werden.",
  "oifde.tableData.import.tableName":"Datentabellenname",
  "oifde.tableData.diagram.elementName":"Datentabelle",
  "oifde.tableData.diagram.searchCriteria":"Suchkriterien",
  "oifde.tableData.diagram.searchPage":"Suchseite",
  "oifde.tableData.diagram.searchResult":"Suchergebnis",
  "oifde.tableData.diagram.returnRecords":"Rückgabedatensätze",
  "oifde.tableData.diagram.total":"Gesamt",
  "oifde.procedure.db.dbName":"Datenbankname",
  "oifde.procedure.procedureDbName":"Name der Datenbank für gespeicherte Prozeduren",
  // "oifde.procedure.dbNameWarning":"Der Datenbankname darf nur Buchstaben\, Ziffern, Leerzeichen\, Unterstriche\ und einfache Anführungszeichen enthalten.",
  "oifde.procedure.dbNameError":"Der Name der Datenbank für die gespeicherte Prozedur darf nicht leer sein oder maximal 64 Zeichen enthalten.",
  "oifde.procedure.actions.deleteOnlyOneDb":"Löschen fehlgeschlagen! Sie können nicht alle Datenbankverbindungsinformationen löschen.",
  "oifde.serviceInterface.actions.deleteDbInfo":"Dieser Vorgang löscht die ausgewählten Datenbankinformationen. Möchten Sie wirklich fortfahren?",
  "oifde.procedure.actions.deleteDBFailed":"Löschen fehlgeschlagen! Die Datenbankverbindung wird von der gespeicherten Prozedur {0} referenziert und kann nicht gelöscht werden. Bitte überprüfen Sie.",
  "oifde.procedure.actions.deleteDBFailed2":"Löschen fehlgeschlagen! Die Datenbankverbindung wurde von {1} gespeicherten Prozeduren referenziert, z. B. {0}. Bitte überprüfen Sie dies.",
  "oifde.procedure.actions.deleteFailed":"Löschen fehlgeschlagen!",
  "oifde.procedure.actions.createDBFailed":"Erstellung fehlgeschlagen! Die Anzahl der Datenbanken erreicht die Obergrenze.",
  "oifde.procedure.databaseConnection":"Datenbankverbindung",
  "oifde.workday.workDayName":"Name des Arbeitskalenders",
  "oifde.workday.workDayDesc":"Beschreibung",
  "oifde.workday.workWeekDay":"Wochenauswahl",
  "oifde.workday.servicePeriod":"Servicedauer",
  "oifde.workday.specificNonServiceDay":"Angegebenes Datum der Nichtdienstleistung",
  "oifde.workday.specificServiceDay":"Angegebenes Servicedatum",
  "oifde.workday.servicePeriodDesc":"Servicedauer. 1 bis 10 Datensätze konfigurieren",
  "oifde.workday.specificNonServiceTip":"Wenn ein angegebenes Nicht-Service-Datum konfiguriert ist, wird das Nicht-Service-Datum als Nicht-Werktag mit der höchsten Priorität betrachtet.",
  "oifde.workday.specificServiceTip":"Wenn ein konfiguriertes angegebenes Servicedatum in der Wochenauswahl nicht verfügbar ist, wird das Servicedatum als Arbeitstag betrachtet.",
  "oifde.workday.name":"Name",
  "oifde.workday.time":"Zeit",
  "oifde.workday.date":"Datum",
  "oifde.workday.addWorkDayTitle":"Arbeitskalender hinzufügen",
  "oifde.workday.editWorkDayTitle":"Arbeitskalender ändern",
  "oifde.workday.required":"Dieses Feld ist obligatorisch.",
  "oifde.workday.updateTime":"Geändert",
  "oifde.workday.operation":"Betrieb",
  "oifde.workday.actions.edit":"Bearbeiten",
  "oifde.workday.actions.delete":"Löschen",
  "oifde.workday.actions.prompt":"Bestätigen",
  "oifde.workday.actions.confirm":"Ja",
  "oifde.workday.actions.cancel":"Nein",
  "oifde.workday.actions.ok":"OK",
  "oifde.workday.actions.deletePrompt":"Durch diesen Vorgang wird der Arbeitskalender dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
  "oifde.workday.actions.cancelDelete":"Der Löschvorgang wurde abgebrochen.",
  "oifde.workday.actions.deleteSuccess":"Löschung erfolgreich.",
  "oifde.workday.actions.updateSuccess":"Änderung erfolgreich.",
  "oifde.workday.actions.createSuccess":"Erstellung erfolgreich.",
  "oifde.workday.actions.missWorkDayName":"Der Name des Arbeitskalenders darf nicht leer sein.",
  "oifde.workday.actions.missWorkWeekDay":"Die Wochenauswahl darf nicht leer sein.",
  "oifde.workday.actions.minServicePeriod":"Es muss mindestens ein Datensatz für den Servicezeitraum konfiguriert werden.",
  "oifde.workday.actions.missPeriodName":"Der Name des Servicezeitraums darf nicht leer sein.",
  "oifde.workday.actions.missPeriodTime":"Der Servicezeitraum darf nicht leer sein.",
  "oifde.workday.actions.missNonServiceName":"Der Name des angegebenen Nicht-Service-Datums darf nicht leer sein.",
  "oifde.workday.actions.missNonServiceDate":"Angegebenes Nicht-Service-Datum darf nicht leer sein.",
  "oifde.workday.actions.missServiceName":"Der Name des angegebenen Servicedatums darf nicht leer sein.",
  "oifde.workday.actions.missServiceDate":"Angegebenes Servicedatum darf nicht leer sein.",
  "oifde.workday.tip.overServicePeriod":"Die Anzahl der Servicezeitraum-Datensätze darf 10 nicht überschreiten.",
  "oifde.workday.tip.overNonServiceDate":"Die Anzahl der Datensätze für das angegebene Nicht-Servicedatum darf 50 nicht überschreiten.",
  "oifde.workday.tip.overServiceDate":"Die Anzahl der Datensätze für das angegebene Servicedatum darf 50 nicht überschreiten.",
  "oifde.workday.tip.overWorkDayCount":"Die Anzahl der Arbeitskalenderdatensätze darf 1000 nicht überschreiten.",
  // "oifde.workday.actions.nameError":"Alle Namen dürfen nur chinesische Zeichen, englische Buchstaben, Ziffern, Leerzeichen, Unterstriche (_), Bindestriche (-) und einfache Anführungszeichen enthalten (\').",
  "oifde.tableData.diagram.addCriteria":"Gruppe hinzufügen",
  "oifde.tableData.diagram.logic":"Logik",
  "oifde.diagram.message.recoverData":"Daten werden wiederhergestellt",
  "oifde.diagram.message.recoverDataDesc":"Möchten Sie den Ansichtsbereich mit den zuletzt temporär gespeicherten Prozessdaten rendern?",
  "oifde.button.openVariable":"Variablen->, öffnen",
  "oifde.button.closeVariable":"Variablen ausblenden <",
  "oifde.varGlobal.builtIn":"Global(integriert)",
  "oifde.varGlobal.custom":"Global",
  "oifde.route.workDay":"Arbeitskalender",
  "oifde.dataTable.tableName":"Tabellenname",
  "oifde.dataTable.tableId":"Tabellen-ID",
  "oifde.dataTable.tableField":"Tabellenfeld",
  "oifde.dataTable.fieldId":"Feld-ID",
  "oifde.dataTable.fieldName":"Feldname",
  "oifde.dataTable.fieldType":"Feldtyp",
  "oifde.dataTable.fieldLength":"Feldlänge",
  "oifde.dataTable.defaultValue":"Standardwert",
  "oifde.dataTable.deleteTips":"Dieser Vorgang löscht die Datentabelle und die Daten in der Datentabelle dauerhaft. Möchten Sie wirklich fortfahren?",
  "oifde.dataTable.deleteBatchTips":"Es wurde keine Datentabelle zum Löschen ausgewählt.",
  "oifde.dataTable.editDataTable":"Datentabelle bearbeiten",
  "oifde.dataTable.addDataTable":"Hinzufügen einer Datentabelle",
  "oifde.dataTable.editDataTableField":"Bearbeiten eines Tabellenfelds",
  "oifde.dataTable.addDataTableField":"Hinzufügen eines Tabellenfelds",
  "oifde.dataTable.validation.tableId":"Nur Buchstaben, Ziffern und Unterstriche sind zulässig. Der Wert darf nicht mit einer Ziffer beginnen.",
  "oifde.dataTable.validation.presetField":"ID, TENANTID, CREATETIME und REMOVETIME sind voreingestellte Felder, bei denen nicht zwischen Groß- und Kleinschreibung unterschieden wird. Verwenden Sie sie nicht.",
  "oifde.dataTable.fieldsMaxSize":"Die Anzahl der Tabellenfelder darf 20 nicht überschreiten.",
  "oifde.dataTable.fieldsNotEmpty":"Das Tabellenfeld darf nicht leer sein.",
  "oifde.dataTable.exceedMaxSize":"Die Anzahl der Datentabellen darf 40 nicht überschreiten.",
  "oifde.dataTable.saveAndInsert":"Daten speichern und hinzufügen",
  "oifde.service.table.dialog.title":"Daten",
  "oifde.service.table.dialog.selectField":"Feld auswählen",
  "oifde.service.table.dialog.inputValue":"Eingabefeldwert",
  "oifde.service.table.dialog.addRecord":"Daten hinzufügen",
  "oifde.service.table.dialog.editRecord":"Daten bearbeiten",
  "oifde.service.table.dialog.integer":"Es sind nur Ganzzahlen zulässig.",
  "oifde.service.table.dialog.string":"Die maximale Länge eines Werts des Zeichenfolgentyps beträgt 10000",
  "oifde.service.table.dialog.oneday":"Letzter Tag",
  "oifde.service.table.dialog.threeday":"Letzte 3 Tage",
  "oifde.service.table.dialog.sevenday":"Letzte 7 Tage",
  "oifde.service.table.dialog.custom":"Benutzerdefiniert",
  "oifde.service.table.dialog.batchdelete":"Löschvorgang des Stapels bestätigen",
  "oifde.service.table.dialog.delete":"Löschvorgang bestätigen",
  "oifde.dataTable.criteria":"Kriterien",
  "oifde.dataTable.diagram.fieldName":"Feldname",
  "oifde.dataTable.diagram.fieldValue":"Feldwert",
  "oifde.service.table.import.max.data":"Die Gesamtmenge der importierten Daten übersteigt 10000",
  "oifde.service.table.import.file.empty":"Der Inhalt der gelesenen Datei ist leer",
  "oifde.service.table.import.file.read":"Parsen der Datei fehlgeschlagen!",
  "oifde.service.table.import.excute.max":"Eine Importaufgabe wird ausgeführt. Warten Sie, bis der letzte Import abgeschlossen ist",
  "oifde.service.table.import.field.error":"Das Tabellenfeld existiert nicht.",
  "oifde.service.table.import.string.max":"Die Summe der Werte des Zeichenfolgentyps übersteigt 10000.",
  "oifde.service.table.import.fieldType.error":"Der Feldtyp und der Wert stimmen nicht überein.",
  "oifde.title.chooseGreyRule":"Graue Regel auswählen",
  "oifde.conditionService.param.tips":"Wenn ein bedingter Ausdruck Zeichenfolgenkonstanten enthält, müssen dem Ausdruck Anführungszeichen hinzugefügt werden.",
  "oifde.common.btn.batch.operation":"Batchbetrieb",
  "oifde.char.invalid":"Ungültige Zeichen",
  "oifde.validate.validChar":"Zulässiger Zeichenbereich:",
  "oifde.service.table.dialog.nodatedelete":"Es wurden keine zu löschenden Daten ausgewählt",
  "oifde.service.table.dialog.exceedMaxSize":"Es können maximal 10.000 Datensätze hinzugefügt werden",
  "oifde.variable.rightList.tips":"Die rechts angezeigten Variablen können in das Variablentextfeld gezogen werden.",
  "oifde.service.table.import.field.length.error":"Die Feldlänge überschreitet den Grenzwert.",
  "oifde.screenfull.cancel":"Vollbild beenden",
  "oifde.variable.sys.callMediaType":"Medientyp anrufen.",
  "oifde.variable.sys.systemDate":"Systemdatum(JJJJMMTT)",
  "oifde.variable.sys.systemMonth":"Systemjahr und -monat (JJJJMM)",
  "oifde.variable.sys.currentTime":"Aktuelle Systemzeit(jjjjMMttHHmmss)",
  "oifde.variable.sys.systemTime":"Aktuelle Systemzeit (HHmmss)",
  "oifde.variable.sys.timeStamp":"Gibt den Zeitstempel an. Der Wert ist eine Zeichenfolge. 01. Januar 1970 00:00:00 GMT (Peking-Zeit, 01.01.1970 08:00:00) Gesamtanzahl der Sekunden seit.",
  "oifde.variable.sys.createTime":"Startzeit des Anrufs",
  "oifde.variable.sys.callingNumber":"Kundennummer, d. h. die Anrufnummer, wenn ein Anruf beim Cloud-Kontaktzentrum getätigt wird, und die angerufene Nummer, wenn ein Anruf beim Cloud-Kontaktzentrum getätigt wird. Zeichenfolgentyp. Die Nummer wird durch den IVR-Prozess übertragen.",
  "oifde.variable.sys.calledNumber":"Zugriffscode eines Systemflusses. Zeichenfolgentyp. Die Nummer wird vom IVR-Prozess übertragen und ist der Prozesszugangscode. Sie können sich bei der WAS-Konfigurationskonsole der CTI-Plattform anmelden, um die Nummer anzuzeigen.",
  "oifde.variable.sys.realCalledNumber":"Zugangscode, der in der Konfiguration des angerufenen Teilnehmers des Flows konfiguriert ist, d. h. die Hotline-Nummer, die zum Anrufen des Flows gewählt wird.",
  "oifde.variable.sys.callId":"ODFS-Sitzungs-ID. Zeichenfolgentyp. Die ID wird vom IVR-Prozess übertragen, d. h. IVRREQUEST.call_id.",
  "oifde.variable.sys.ctiCallid":"Vom IVR-Prozess übertragene CTI-Anruf-ID, d. h. IVRREQUEST.cti_callid in der IVR-Anforderung.",
  "oifde.variable.sys.tenantId":"Gibt die aktuelle Mandanten-ID an.",
  "oifde.variable.sys.language":"Aktuelle Kundensprache",
  "oifde.variable.ivrRequest.ctiCallid":"Von Huawei IVR übertragene CTI-Anruf-ID. In anderen Szenarien bleibt dieser Parameter leer. Der Wert darf maximal 32 Zeichen enthalten.",
  "oifde.variable.ivrRequest.input":"Dialoginteraktionsergebnis.",
  "oifde.variable.ivrRequest.IACmd":"Sonderfelder für den intelligenten Abgleich und die intelligente Auftragsabwicklung.",
  "oifde.variable.ivrRequest.TextSource":"Quelle des Anfragetexts.",
  "oifde.variable.ivrRequest.transinData":"Zwischen dem Zugangsteilnehmer und dem OIAP vereinbarte kanalbezogene Datenparameter, z. B. vom IVR übertragene Datenpaketparameter.",
  "oifde.variable.ivrRequest.beginPlay":"Startzeit der Durchsagewiedergabe.",
  "oifde.variable.ivrRequest.endPlay":"Endzeit der Stimmenwiedergabe.",
  "oifde.variable.ivrRequest.callId":"Eindeutige ID eines Anrufs. Der Wert ist mit dem Wert von userid identisch und wird vom Flow verwendet.",
  "oifde.variable.ivrRequest.interIdx":"Anzahl aufeinander folgender Interaktionen mit einem Benutzer, die in der ODFS aufgezeichnet wird.",
  "oifde.variable.ivrRequest.feedback":"Zufriedenheit: 1 (zufrieden) 0 (unzufrieden). Der Wert ist eine Ganzzahl.",
  "oifde.variable.ivrRequest.feedbackContent":"Gibt den Grund für die Unzufriedenheit an. Wenn die Rückmeldung zufriedenstellend ist, ist der Wert Null.",
  "oifde.variable.ivrRequest.channelType":"Kundenzugangskanaltyp. Zum Beispiel: WEB, INSTAGRAM, TELEGRAM, WECHAT, FACEBOOK, TWITTER, EMAIL, LINE, Whatsapp, 5G, SMS",
  "oifde.variable.ivrRequest.ieValue":"Titel der empfangenen E-Mail, wenn auf den E-Mail-Kanal zugegriffen wird.",
  "oifde.variable.toc.ChatBot":"Auf die Absicht konfigurierte Steckplatzparameter",
  "oifde.variable.toc.ChatBotIntentCode":"Der Intentionscode wird für die übereinstimmende Intention bei der Spracherkennung zurückgegeben.",
  "oifde.variable.toc.ChatBotRespContent":"Von der übereinstimmenden Absicht zurückgegebene Antwortinformationen, einschließlich der Steckplatz-ID und der in der Antwort konfigurierten Antwort.",
  "oifde.variable.toc.ChatBotScenarioName":"Name der Absichtsvorlage, die nach der Spracherkennung abgeglichen wird.",
  "oifde.variable.toc.ChatBotResponse":"Identifizieren Sie den gesamten Content in der Benutzeroberfläche.",
  "oifde.datatable.open.type":"Öffnen Sie die exportierte Datei im UTF-8-Format.",
  "oifde.datatable.dateValue.tip":"Zeitstempel, in Millisekunden. Geben Sie eine lange Ganzzahl oder einen Zeichenwert oder eine Variable ein.",
  "oifde.datatable.intList.tip":"Beispiel: [1, 2, 3].",
  // "oifde.datatable.stringList.tip":"Beispiel: [“a,“b",“c"]."",
"oifde.expression.expr_number_varOrValue":"Geben Sie einen Wert oder eine Variable vom numerischen Typ ein.",
    "oifde.expression.expr_list_varOrValue":"Geben Sie einen Wert oder eine Variable des Listentyps ein.",
    "oifde.service.table.import.index":"Zeile {0}",
    "oifde.flow.rule.noRule":"Die graue Regel konnte nicht gefunden werden. Überprüfen Sie, ob die Regel gelöscht wurde.",
    "oifde.cell.viewRefrenceInfo":"Referenzinformationen",
    "oifde.cell.viewRefrenceInfo.flow":"Prozessreferenz",
    "oifde.cell.viewRefrenceInfo.complexcell":"Komplexer Zellbezug",
    "oifde.accessCode.select.max":"{0} Datensätze wurden ausgewählt. Es können maximal {1} Datensätze ausgewählt werden.",
    "oifde.mxgraph.deleteDraft":"Entwurf löschen",
    "oifde.mxgraph.deleteDraft.confirm":"Möchten Sie den Entwurf wirklich löschen?",
    "oifde.mxgraph.deleteDraft.noDraftExist":"Es sind keine Entwurfsdaten vorhanden.",
    "oifde.resource.needApproval":"Antwortressourcen wurden erfolgreich erstellt und können erst nach Genehmigung durch den Systemadministrator verwendet werden.",
    // "oifde.common.validation.accessCode.description":"Zeilenumbrüche und die folgenden Sonderzeichen sind nicht allowed: “[]\'&()=;<>,",
    "oifde.tableData.import.tableNameBreve":"Tabellenname",
    "oifde.flow.actions.publish.existDraft":"Der aktuelle Prozess enthält Entwurfsdaten. Bearbeiten und freigeben oder den Entwurf ignorieren und direkt freigeben.",
    "oifde.export.finish.success":"Erfolgreich exportiert!",
    "oifde.input.tips.varAndConst":"Geben Sie eine Variable oder Konstante ein. Verwenden Sie Anführungszeichen vor und nach der Zeichenfolge.",
    "oifde.export.keyPressRobot.title":"Exportieren allgemeiner IVR-Datensätze",
    "oifde.export.password":"Kennwort komprimieren",
    "oifde.export.password.placeholder":"Bitte geben Sie das Komprimierungskennwort ein.",
    // "oifde.export.password.error.tips":"Geben Sie ein Kennwort mit 8 bis 12 Zeichen ein, um komprimierte Dateien zu generieren. Das Kennwort muss Ziffern, Buchstaben und Sonderzeichen enthalten, außer /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r.",
    // "oifde.export.password.error.message":"Der Wert muss Ziffern, Buchstaben und Sonderzeichen enthalten, außer /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r",
    "oifde.export.task.status":"Aufgabenstatus",
    "oifde.export.task.fail":"Export fehlgeschlagen!",
    "oifde.export.businessInterface.title":"Exportieren benutzerdefinierter Schnittstellendatensätze",
    "oifde.export.variable.title":"Exportieren benutzerdefinierter Variablendatensätze",
    "oifde.export.complexcell.title":"Elementdatensätze für zusammengesetzte Diagramme exportieren",
    "oifde.export.accessCode.title":"Exportieren von Datensätzen intelligenter Roboter",
    "oifde.export.intelligentIVRFlow.title":"Exportieren von Prozessdatensätzen",
    "oifde.export.intelligentIVRFlow.button":"Exportfluss",
    "oifde.route.2dDigitalHumanImg":"Virtueller Mensch",
    "oifde.route.add2dDigitalHumanImg":"Virtuelle Menschen erschaffen",
    "oifde.digitalHuman.outputSetting":"Ausgabeeinstellungen",
    "oifde.digitalHuman.standingPosition":"Positionierung",
    "oifde.digitalHuman.stationTemplate":"Positionsvorlage",
    "oifde.digitalHuman.preciseAdjustment":"Genau einstellen",
    "oifde.digitalHuman.left":"Links",
    "oifde.digitalHuman.center":"Mitte",
    "oifde.digitalHuman.right":"Rechts",
    "oifde.digitalHuman.imageProportion":"Bildverhältnis",
    "oifde.exportdata.message.overData":"Die Anzahl der exportierten Datensätze übersteigt 10000. Bitte wählen Sie eine andere Abfragebedingung.",
    "oifde.flow.title.publishFlow":"Freigabefluss",
    "oifde.flow.title.upgrade":"Upgrade-Ablauf",
    "oifde.flow.action.editInfo":"Informationen bearbeiten",
    "oifde.flow.title.editInfo":"Bearbeiten von grundlegenden Flow-Informationen",
    "oifde.robot.title.editInfo":"Bearbeiten grundlegender Chatbot-Informationen",
    "oifde.input.placeholder.searchName":"Suchname",
    "oifde.avatar.tenantId":"Mieter-ID",
    "oifde.avatar.tenantSpace":"Mieterfläche",
    "oifde.avatar.avatarName":"Rollenname",
    "oifde.avatar.avatarCode":"Rollencode",
    "oifde.avatar.headerImageUrl":"Bild",
    "oifde.avatar.serverAddress":"Serveradresse",
    "oifde.avatar.avatarAppKey":"Serverbenutzername/AK",
    "oifde.avatar.create":"Erstellen eines virtuellen menschlichen Dienstes",
    "oifde.avatar.update":"Virtuellen menschlichen Dienst bearbeiten",
    "oifde.avatar.headerImage":"Profilbild",
    "oifde.avatar.poseImage":"Bild posieren",
    "oifde.avatar.actionList":"Aktionsliste",
    "oifde.avatar.form.message":"Virtuelle 2D-Menschendaten",
    "oifde.avatar.service.message":"Serverinformationen",
    "oifde.avatar.serverPort":"Serverport",
    "oifde.avatar.userName":"Serverbenutzername",
    "oifde.avatar.pwd":"Serverkennwort",
    "oifde.avatar.checkPwd":"Serverkennwort bestätigen",
    "oifde.avatar.resolution":"Auflösung",
    "oifde.avatar.imgRule":"Es werden nur die Formate JPG, PNG und JPEG unterstützt. Die Dateigröße darf 2 MB nicht überschreiten.",
    "oifde.avatar.action_1":"Grüße",
    "oifde.avatar.action_2":"Hände nach vorn",
    "oifde.avatar.action_3":"Nein",
    "oifde.avatar.action_4":"Hände zusammengelegt",
    "oifde.avatar.action_5":"Linke Hand spreizen",
    "oifde.avatar.action_6":"Rechte Hand ausbreiten",
    "oifde.avatar.selectTitle":"Wählen Sie ein Avatarbild aus",
    "oifde.digitalHuman.imgLimit":"Es werden nur JPG-, PNG- und JPEG-Bilddateien unterstützt. Die Dateigröße darf 2 MB nicht überschreiten.",
    "oifde.digitalHuman.imageProportion0-5":"0.5",
    "oifde.digitalHuman.imageProportion0-75":"0.75",
    "oifde.digitalHuman.imageProportion1":"1",
    "oifde.digitalHuman.canvasNoSupport":"Der aktuelle Browser unterstützt Canvas nicht. Aktualisieren oder ändern Sie zuerst den Browser.",
    "oifde.digitalHuman.noRoleData":"Keine Rollendaten. Wenden Sie sich an das Betriebspersonal, um die Rollendaten hinzuzufügen.",
    "oifde.digitalHuman.sizeError":"Falsche Bildauflösung. Laden Sie ein Hintergrundbild im Format {0} x {1} hoch.",
    "oifde.title.add.complexCell":"Neues Verbundelement",
    "oifde.title.add.complexCellCatalog":"Erstellen eines Verzeichnisses für zusammengesetzte Diagrammelemente",
    "oifde.title.edit.complexCellCatalog":"Verzeichnis für zusammengesetzte Elemente bearbeiten",
    "oifde.title.add.rule":"Neue Regel",
    "oifde.title.edit.rule":"Regel bearbeiten",
    "oifde.ivrJourney.pleaseSelectData":"Bitte Daten auswählen",
    "oifde.digitalHuman.needRole":"Wählen Sie eine Rolle aus.",
    "oifde.digitalHuman.actions.deletePrompt":"Dieser Vorgang wird den virtuellen Menschen dauerhaft löschen. Fortfahren?",
    "oifde.avatar.pleaseSelectData":"Die geänderten Daten werden nach dem Schließen nicht gespeichert. Möchten Sie wirklich fortfahren?",
    "oifde.avatar.validation.ttsSoundSpeed":"Geben Sie eine Dezimalstelle zwischen 0,5 und 1,5 ein.",
    "oifde.avatar.validation.scale":"Geben Sie eine Dezimalstelle zwischen 0,5 und 1,0 ein.",
    "oifde.digitalHuman.actions.savePrompt":"Diese Operation wird den virtuellen Menschen aktualisieren. Fortfahren?",
    "oifde.digitalHuman.coordinate":"Koordinate",
    "oifde.digitalHuman.dimensions":"Abmessungen",
    "oifde.digitalHuman.imgTypeError":"Falscher Typ. Es werden nur die Formate JPG, PNG und JPEG unterstützt.",
    "oifde.expression.expr_var_flow":"Geben Sie eine Prozessvariable ein, die mit FLOW beginnt",
    "oifde.expression.expr_string_or_var":"Geben Sie einen Wert vom Typ Variable oder Zeichenfolge ein. Verwenden Sie doppelte Anführungszeichen vor und nach der Zeichenfolge.",
    "oifde.common.actions.deleteConfig":"Durch diesen Vorgang wird die Konfiguration dauerhaft gelöscht. Möchten Sie wirklich fortfahren?",
    // "oifde.expression.numberCharStartWith0":"Verwenden Sie doppelte Anführungszeichen vor und nach einer numerischen Zeichenfolge, die mit 0 beginnt. Beispiel: 05 muss als “05 eingegeben werden. Andernfalls wird der Wert als “5" betrachtet."",

}