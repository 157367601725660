import zhLocale from './sumweb.i18n.zh_CN'
import enLocale from './sumweb.i18n.en_US'
import frLocale from './sumweb.i18n.fr_FR'
import esLocale from './sumweb.i18n.es_ES'
import ptLocale from './sumweb.i18n.pt_BR'
import thLocale from './sumweb.i18n.th_TH'
import arLocale from './sumweb.i18n.ar_SA'
import deLocale from './sumweb.i18n.de_DE'

const sumwebLocale = {
  zh_CN: {
    ...zhLocale,
  },
  en_US: {
    ...enLocale,
  },
  fr_FR: {
    ...frLocale,
  },
  es_ES: {
    ...esLocale,
  },
  pt_BR: {
    ...ptLocale,
  },
  th_TH: {
    ...thLocale,
  },
  ar_SA: {
    ...arLocale,
  },
  de_DE: {
    ...deLocale,
  }
}

export default sumwebLocale