export default {
  'SM.COMMON.LABEL.SURVEY_TITLE_SORT': 'تسلسل السؤال',
  'SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT': 'يرجى إدخال الاسم',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE': 'العميل',
  'SM.ORGSTAFF.TITLE.CONTACT_PHONE': 'رقم الهاتف',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION_INPUT': 'اسم العملية:',
  'SM.TENANTSPACE.LDAP_TITLE': 'مصادقة LDAP',
  'SM.COMMON.TITLE.MEASUREMENT_UNIT': 'نوع القياس',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_INFORMATION': 'معلومات الموظف',
  'SM.COMMON.LABEL.SYSMENU_DESCRIPTION': 'وصف القائمة',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE_GROUP': 'مجموعة رمز الشحن',
  'SM.ORGSTAFF.TITLE.REGION_IPRANGE': 'مقطع IP',
  'SM.COMMON.TITLE.SYSMENU_ERROR': 'خطأ',
  'SM.COMMON.TIPS.MENU_REBUILD_INFO':
    'تم إرسال معلومات إعادة إنشاء فهرس القائمة إلى قائمة انتظار الرسائل. الرجاء الانتظار.',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT_NO_PERIOD': 'تحديد الموظف',
  'SM.USERGROUP.DELETEG_GROUP.CONTENT': 'بعد حذف مجموعة مستخدم، يمكن إعادة إنشائها فقط.',
  'SM.ORGSTAFF.TITLE.CONTACT_FIRST_NAME': 'الاسم الأول',
  'SM.COMMON.LABEL.SURVEY_OPTION_NAME': 'اسم الخيارات',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_DISCARDED': 'حذف',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_PASSWORD': 'كلمة المرور الحالية',
  'common.statemachineui.button.add': 'إضافة',
  'SM.COMMON.LABEL.OPERLOG_SERRCH_CRITERIA': 'معايير البحث',
  'SM.AUTH.TITLE.USERAUTHVIEW_TITLE_AUTHNAME_CONF': 'اسم الإذن',
  'SM.COMMON.MESSAGE.DELETE_SUCCESS': 'تم الحذف بنجاح.',
  'SM.AUC.LABEL.LOGINSESSION_AUTHSTATUS': 'حالة الاعتماد',
  'SM.FEDERATION.FEDERATIONAPP.NAME': 'UserAccount',
  'SM.ORGSTAFF.LABEL.IMPORT_TEMPLATE': 'استيراد قالب ملف',
  'SM.FEDERATION.IMPORT.UNDO': 'معلق',
  'SM.ORGSTAFF.LABEL.STAFFAUTH_AUTHSOURCE': 'مصدر الإذن',
  'SM.FEDERATION.IMPORT.FILENAMEVALIDATEFAIL':
    'اسم الملف غير صحيح. حدد ملفًا يحتوي اسمه على أحرف أو حروف أو أرقام أو سفلية صينية فقط (_).',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTID': 'المرفقات',
  'SM.AUTH.MESSAGE.ROLE_OPP_WITH_PARENT': 'الدور المحدد مقابل الدور الموروث:',
  'SM.AUTH.MESSAGE.ROLE_NOT_EXSIT': 'الدور غير موجود.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_ALLSUCCESS': 'نجاح إعادة تعيين كلمة مرور الموظف',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_TYPE': 'نوع الدور',
  'SM.ORGSTAFF.LABEL.ORG_BASICINFO': 'معلومات أساسية',
  'SM.AUTH.TIPS.ENTER_ORGNAME': 'إدخال اسم المنظمة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_SUCCESS':
    'تم تغيير كلمة مرور الموظف بنجاح. فشل تغيير ما يلي:',
  'SM.ORGSTAFF.TITLE.ROLE_BASIC': 'الدور الأساسي',
  'SM.TENANT.EDIT': 'تحرير المستأجر',
  'SM.AUTH.MESSAGE.TIME_LOG2': 'يوم (أيام).',
  'SM.AUTH.TITLE.INPUT_ROLE_TMPL_NAME': 'إدخال اسم قالب الدور',
  'SM.AUTH.MESSAGE.TIME_LOG1': 'لا يمكن أن يتجاوز النطاق الزمني',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_VALID': 'صالح',
  'SM.FEDERATION.IMPORT.FILESUFFIXVALIDATEFAIL': 'تنسيق ملف غير صحيح. حدد ملف XLSX.',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_NAME': 'اسم الاستبيان',
  'SM.COMMON.MESSAGE.DATADICT_SAVE_SUCCESSFULLY': 'تم تنفيذ العملية بنجاح.',
  'SM.ORGSTAFF.LABEL.BANK_NAME': 'اسم البنك',
  'SM.ORGSTAFF.LABEL.WORKGROUP_NAME': 'اسم مجموعة العمل',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_PARAM': 'إصدار بطاقة هوية مصرفية',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE_A': 'تفعيل',
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEAUNIT': 'وحدة قياس الهدف',
  'SM.FEDERATION.COMMON.CREATE.FAIL': 'فشل الإنشاء.',
  'SM.AUTH.TITLE.ROLE_SHORTCUT_MENU_TOOLBAR': 'قائمة الاختصارات',
  'SM.AUTH.TITLE.USERAUTHVIEW_STAFF_BE_NAME': 'منزل الموظف BE',
  'SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE': 'كلمة المرور لا تتوافق مع القواعد.',
  'SM.AUC.LABEL.LOGINLOG_LOGIN_IP_ADDRESS': 'IP تسجيل الدخول',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_FUNCTION': 'إذن الوظيفة الأساسية',
  'SM.AUTH.LABEL.ROLETMPL_ASSINGED_PERMISSION': 'منح الإذن للدور',
  'SM.ORGSTAFF.TITLE.ADDR_DESCRIPTION': 'الوصف',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGIONRESTRICTION': 'تقييد تسجيل الدخول',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD_INPUT': 'اسم الحقل PARENT_ID:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD': 'اسم الحقل',
  'SM.ORGSTAFF.TITLE.COMMON_STAFF_INFO': 'قائمة حسابات الموظفين',
  'SM.TENANTSPACE.LDAP_DOMAIN': 'المجال',
  'SM.AUC.LABEL.LOGINLOG_EMPLOYEE_NAME': 'اسم الموظف',
  'SM.AUTH.TITLE.ROLE_EXTENDED_PROPERTY': 'سمة موسعة',
  'SM.ORGSTAFF.LABEL.CREATESOURCE': 'إنشاء مصدر',
  'SM.ORGSTAFF.LABEL.ORG_REGION': 'المنطقة',
  'SM.ORGSTAFF.TITLE.CONTACT_INFO': 'مدير',
  'SM.COMMON.LABEL.HEADING_SEARCHCRITERIA': 'معايير البحث',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_PUBLIC': 'العامة',
  'SM.ORGSTAFF.TITLE.TEAM_NAMEEXIST': 'اسم الفريق موجود بالفعل.',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMID': 'معرف الفريق',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD': 'اسم الحقل PARENT_ID',
  'SM.ORGSTAFF.TITLE.EMP_BELONG_DEPT': 'القسم',
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_SUCCESS': 'نجحت الإضافة.',
  'SM.AUTH.MESSAGE.DATAPER_OBJ_TREE_ROOT': 'شجرة كائن الإذن',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_ONE_ROLE': 'حدد دورًا واحدًا على الأقل.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARSEFAIL': 'فشل تحليل معلومات المستخدم الخارجي.',
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEASYS': 'نظام قياس الهدف',
  'SM.COMMON.LABEL.AREATYPE': 'نوع المنطقة',
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_NAME': 'منزل الدور BE',
  'SM.COMMON.LABEL.HEADING_BASEINFO': 'معلومات أساسية',
  'SM.COMMON.MESSAGE.DELETE_CURRENCY_STD_MIN_MEASUREMENT':
    'تحتاج العملة إلى استخدام الوحدة القياسية والوحدة الدنيا ، هل أنت متأكد من حذفها؟',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT': 'تسلسل التصنيف',
  'SM.ORGSTAFF.TIPS.ENTER_EMPNAME': 'يرجى إدخال اسم الموظف.',
  'SM.ORGSTAFF.MESSAGE.BE_MAKE_SURE_DELETE': 'هل تريد بالتأكيد حذف العضو؟',
  'SM.AUTH.TITLE.INPUT_AUTHID_AUTHNAME': 'إدخال معرف إذن أو اسم',
  'SM.ORGSTAFF.OPTION.QUERYSUBDEPT': 'قسم الاستعلام عن المستوى الأدنى',
  'SM.FEDERATION.MAPPINGRULE.NAME': 'قاعدة التعيين',
  'SM.AUTH.LABEL.ROLEBUSINESS_ENTITY_ID': 'معرّف الدور BE',
  'SM.COMMON.LABEL.MENU_ACCESS_TIME': 'وقت الوصول إلى القائمة',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYPE': 'نوع الكائن',
  'SM.AUTH.TIPS.ROLETMPL_HAS_OPP_AUTH': 'فشل الحفظ لوجود إذن حصري متبادل.',
  'SM.AUTH.MESSAGE.BIZ_OBJ_POLICY_DUPLICATE': 'توجد سياسة لها نفس الدور والنطاق.',
  'SM.ORGSTAFF.TITLE.IMPORT_EMPLOYEE_TITLE': 'استيراد موظف',
  'SM.AUTH.LABEL.ROLE_ID_B_INPUT': 'معرف الدور (B):',
  'SM.EMPLOYEE.CANCEL.CONFIG.CONFIRM': 'هل تريد بالتأكيد إلغاء التكوين؟',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESETPWD': 'إعادة تعيين كلمة المرور',
  'SM.AUTH.LABEL.ROLE_ID_B': 'معرف الدور (B)',
  'SM.AUTH.LABEL.ROLE_ID_A': 'معرف الدور (A)',
  'SM.EMPLOYEE.SKILL.INFO': 'معلومات المهارة',
  'SM.EMPLOYEE.FEATURE.CONFLICT':
    'لا يمكن تمكين التعرف الذكي والتسجيل ثنائي المسار في نفس الوقت. إذا تم تمكينهما، فسيتم إلغاء تحديد الآخر.',
  'SM.EMPLOYEE.BATCH.CONFIG': 'تكوين الحزمة',
  'SM.COMMON.LABEL.SURVEY_CLASS_Code': 'كود التصنيف',
  'SM.COMMON.LABEL.CURRENCY_TYPE_CODE': 'الكود',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME_INPUT': 'اسم الدور:',
  'SM.ORGSTAFF.MESSAGE.SUCCESS.SAVE': 'النجاح',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH': 'معرّف الإذن الأصلي',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_NORMAL': 'عادي',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE_SETTING': 'الإعداد',
  'SM.AUTH.MESSAGE.ROLETMPL_TIME_CONDITION': 'يجب أن يكون الوقت بعد وقت البدء.',
  'SM.AUTH.LABEL.DETAIL_INFO': 'حدد عقدة أذونات في شجرة التنقل على اليسار لعرض معلومات حول الإذن.',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_MENU': 'معايير البحث',
  'SM.COMMON.TITLE.PRJ_EXPORT': 'وقد بدأت للتصدير',
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_INFO': 'معلومات قالب الدور',
  'SM.COMMON.BUTTON.ATTACHMENT_ADDACCESSORY': 'حفظ',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT_INPUT': 'المشغل:',
  'SM.AUC.LABEL.LOGINSESSION_LOGINSTATUS': 'حالة تسجيل الدخول',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_SET_ROLE': 'إضافة أدوار في دفعات',
  'SM.COMMON.LABEL.OPERLOG_RESET': 'إعادة الضبط',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR': 'بريد إلكتروني غير صالح',
  'SM.AUTH.LABEL.ROLE_ID_A_INPUT': 'معرف الدور (A):',
  'SM.AUTH.TITLE.USERAUTHVIEW_ACCOUNTLOGIN': 'الحساب',
  'SM.COMMON.TITLE.SURVEY_ITEM_NAME_INFO': 'اسم العنصر معلومات',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESUME': 'استئناف',
  'SM.COMMON.LABEL.CURRENCY_GROUPSIZE': 'حجم المجموعة',
  'SM.ORGSTAFF.OPTION.WEEKLIST_SEC': 'ثانياً',
  'SM.AUTH.LABEL.FUNCAUTH_MODULE_INPUT': 'معرف النظام الفرعي:',
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_ERROR': 'يرجى اختيار القياس القياسي الصحيح.',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_TIME': 'وقت التعديل',
  'SM.FEDERATION.IMPORT.FILESIZEVALIDATEFAIL': 'حدد ملفًا أقل من 10 ميغابايت.',
  'SM.AUTH.LABEL.ROLETMPL_OPERTIME': 'وقت العملية',
  'SM.AUC.LABEL.LOGINSESSION_LOCKSTATUS': 'حالة القفل',
  'SM.ORGSTAFF.BUTTON.RESET_TEAM': 'إعادة الضبط',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MIDDLENAME': 'الاسم الأوسط',
  'SM.COMMON.LABEL.ERATE_TODATE': 'إلى',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAM': 'فريق العمل',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_STATUS': 'الحالة',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT': 'وحدة العملة',
  'SM.ORGSTAFF.MESSAGE.CONTACT_ACCOUNT': 'الحساب',
  'SM.AUTH.MESSAGE.SAVE_DEPENDENT_SELF': 'تعتمد على الإذن نفسه ، وهذا غير مسموح به.',
  'SM.ORGSTAFF.BUTTON.IMPORT': 'استيراد',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU': 'فعالة OU',
  'SM.ORGSTAFF.LABEL.TEAM_TO': 'إلى',
  'SM.COMMON.TITLE.PROPERTY_SUCCESS': 'معلومات',
  'SM.AUTH.BUTTON.ROLETMPL_SEARCH': 'البحث',
  'SM.FEDERATION.IMPORT.FAILREASON': 'سبب الفشل',
  'SM.AUTH.BUTTON.CLEAR': 'مسح',
  'SM.FEDERATION.IMPORT.DOWLOADIMPORTRESULT': 'تحميل',
  'SM.ORGSTAFF.LABEL.ORG_ADDITIONINFO': 'معلومات اضافية',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SAT': 'السبت',
  'SM.AUTH.LABEL.ROLE_TYPE_INPUT': 'نوع الدور:',
  'SM.COMMON.TIPS.POPUP_INFO_TITLE': 'معلومات',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE': 'هيكل الشجرة',
  'SM.COMMON.LABEL.CHARGECODE_RETIRE': 'إلغاء',
  'SM.COMMON.TITLE.ACCESS.FILTER': 'الرجاء إدخال كلمات أساسية للتصفية',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG': 'إصدار بطاقة هوية مصرفية طول',
  'SM.COMMON.LABEL.MENU_ACCESS_STRART_TIME': 'وقت البدء',
  'SM.ORGSTAFF.TITLE.ADDR_TEMPATE_ID': 'معرف القالب',
  'SM.AUTH.LABEL.ROLE_CODE': 'رمز الدور',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIV': 'الخامس',
  'SM.AUTH.TITLE.SELECT_OPEN_LOGIN': 'اختر تسجيل الدخول فتح القائمة',
  'SM.COMMON.LABEL.CHARGECODE_SEARCH_CRITERIA': 'معايير البحث',
  'SM.AUTH.LABEL.ROLE_DESC_INPUT': 'الوصف:',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TERMINATE_SECONDMENT': 'إنهاء الإعارة',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIR': 'أولاً',
  'SM.COMMON.TITLE.ITEM_NOTE': 'عرض الصورة',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW': 'عرض الأذونات',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_HEAD': 'رئيس الاستبيان',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE_INPUT': 'هيكل الشجرة:',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG_PARAM': 'إصدار بطاقة هوية مصرفية طول',
  'SM.AUTH.LABEL.ROLE_COPY_MODE_INPUT': 'وضع النسخ:',
  'SM.AUTH.LABEL.TITLE_CLEAR_SUCCESS': 'موجه',
  'SM.ORGSTAFF.AUTHTYPE.DEFAULT': 'افتراضي',
  'SM.AUTH.LABEL.FUNCAUTH_BASICINFO': 'معلومات أساسية',
  'SM.COMMON.BUTTON.CHARGECODE_ADDMEMBER': 'إضافة رمز الشحن',
  'SM.GADGET.VALUE.MENUPERM': 'أذونات القائمة',
  'SM.AUC.MESSAGE.RULEGROUPNAME': 'اسم مجموعة القاعدة',
  'SM.COMMON.LABEL.CURRENCY_DECIMALSYMBOL': 'الرمز العشري',
  'SM.ORGSTAFF.LABEL.INPUT_ROLE_NAME': 'الرجاء إدخال اسم الدور',
  'SM.AUTH.MESSAGE.ROLE_SELECT_A_ROLE': 'حدد دورًا.',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_VALUE_TYPE': 'نوع القيمة',
  'SM.COMMON.LABEL.SURVEY_ITEM_NAME': 'اسم العنصر',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NAME_EXIST': 'فشل إنشاء الموظف لأن الحساب موجود بالفعل في النظام.',
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE': 'نوع البنك',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_FUNCTION': 'إذن وظيفة منح',
  'SM.COMMON.LABEL.SYSMENU_GRANT_MENU_AUTH': 'إدارة إذن القائمة',
  'SM.ORGSTAFF.LABEL.ORGANIZATION': 'وحدة التنظيم',
  'SM.AUTH.TIPS.FUNCAUTH_NO': 'لا',
  'SM.COMMON.TITLE.BANKCARD_MDF': 'تعديل البطاقة المصرفية',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVACY': 'مستوى خصوصية الحقل',
  'SM.COMMON.LABEL.SYSMENU_MENU_AUTH_ROLE_LIST': 'قائمة الأدوار المعينة',
  'SM.AUTH.LABEL.ROLETMPL_BASIC_FUNCTION_PERMISSION': 'إذن الوظيفة الأساسية',
  'SM.ORGSTAFF.LABEL.AFFILIATED_BE': 'التابعة BE',
  'SM.AUTH.MESSAGE.ROLE_ADD_SUCCESSFUL': 'تمت الإضافة بنجاح.',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_GROUP': 'هل تريد بالتأكيد حذف مجموعة كود الشحن؟',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IS_LOCKED': 'تم قفل الموظف.',
  'SM.COMMON.MESSAGE.ERATE_CODE_COMPARE':
    'لا يمكن أن يكون رمز العملة المصدر هو نفسه رمز العملة الهدف.',
  'SM.ORGSTAFF.OPTION.WEEKLIST_LST': 'الأخير',
  'SM.ORGSTAFF.TITLE.BE_ADDRESSSETTING': 'العنوان',
  'SM.AUTH.LABEL.THIRD_DATASOURCE': 'مصدر البيانات الثالث',
  'SM.ORGSTAFF.LABEL.STAFF_INFO': 'معلومات الموظف',
  'SM.TENANT.INFO': 'معلومات المستأجر',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_EXPORTALL': 'تصدير',
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNT': 'الحساب',
  'SM.SM.COMMON.TITLE.NO_CHINAWORD': 'لا يُسمح بالرموز الخاصة.',
  'SM.AUC.LABEL.BANK_LIST': 'قائمة البنوك',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_SELECTED_OU_FIRST':
    'الرجاء تحديد عقدة في شجرة العلاقات التنظيمية أولاً.',
  'SM.FEDERATION.COMMON.RESET': 'إعادة الضبط',
  'SM.AUTH.LABEL.ROLE_ROLE_NAME': 'اسم الدور',
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED': 'إلغاء التنشيط',
  'SM.ORGSTAFF.TITLE.EDIT_ORG': 'تحرير OU',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.ISUSED':
    'لا يمكن حذف البيانات أو تعديلها لأنه تم استخدامها من قبل المستأجر.',
  'SM.AUTH.LABEL.ROLE_ASSIGN_STAFF': 'الموظف المعين',
  'SM.COMMON.LABEL.SEARCH_NAME': 'يرجى إدخال الاسم',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_LIST': 'قائمة تنسيق عرض العنوان',
  'SM.COMMON.LABEL.CURRENCY_TYPE_INPUT_TIPS': 'إدخال اسم نوع العملة',
  'SM.COMMON.LABEL.MEASUREMENT_NOTIFICATION_NAME': 'الاسم في الإخطار',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_DELETE':
    'بعد تعطيلها، سيتم حذف علاقة المؤسسة والعلاقة التابعة لها، هل تؤكد على الحذف؟',
  'SM.ORGSTAFF.LABEL.REGION_LAYER': 'طبقة المنطقة',
  'SM.TENANT.TENANTSPACENAME': 'TenantSpaceName',
  'SM.COMMON.TITLE.BANKCARD_CONFIRM': 'معلومات',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP': 'عنوان IP',
  'SM.ORGSTAFF.MESSAGE.DELETE_MSG': 'هل تريد بالتأكيد حذفه؟',
  'SM.AUTH.LABEL.FUNCAUTH_MODULE': 'معرف النظام الفرعي',
  'SM.COMMON.MESSAGE.CURRENCY_MEASUREMNET_CONFIRMCOVERMSG':
    'يحتوي نوع العملة الحالي بالفعل على وحدة عملة قياسية أو وحدة عملة دنيا. هل أنت متأكد من رغبتك في المتابعة؟',
  'SM.AUC.MESSAGE.PWDRULE_ACCOUNT': 'قاعدة الحساب',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD': 'كلمة مرور جديدة',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_MDY': 'تعديل الموظف',
  'SM.TENANT.DELETE_CONFIRM': 'لا يمكن استعادة المستأجرين المحذوفين. هل تريد بالتأكيد حذفها؟',
  'SM.AUTH.TITLE.ROLE_BASIC_FUNCTION_PERMISSION': 'إذن الوظيفة الأساسية',
  'SM.AUTH.TIPS.FUNCAUTH_STOPUSE': 'تعليق',
  'SM.COMMON.BUTTON.RESET': 'إعادة الضبط',
  'SM.ORGSTAFF.MESSAGE.SRC_ACCOUNT': 'حساب المصدر غير موجود.',
  'SM.FEDERATION.COMMON.CONFIRM': 'تأكيد',
  'SM.COMMON.MESSAGE.SYSMENU_DEACTIVATED_NOT_SUPPORT':
    'لا تدعم حالة القائمة المعطلة إدارة الأذونات.',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_RECOVER':
    'بعد استئنافها ، ستكون العلاقة التنظيمية والعلاقة التابعة لها متاحة ،هل تؤكد على استئنافها؟',
  'SM.ORGSTAFF.TITLE.MEASUREMENT_CONV': 'تحويل القياس',
  'SM.COMMON.TIPS.AREA_DELETE_SUCCESS': 'تم الحذف بنجاح.',
  'SM.COMMON.TITLE.ERROR': 'خطأ',
  'SM.PROJECT.TITLE.IMPORT_ORG_PAR': 'استيراد حزمة الاسمية',
  'SM.AUTH.MESSAGE.ROLE_SURE_TO_DELETE': 'هل تريد بالتأكيد الحذف؟',
  'SM.SM.COMMON.TITLE.NO_CHARGECODE': 'لا ChargeCode.',
  'SM.COMMON.LABEL.CREATE_TIME': 'تم الإنشاء في',
  'SM.ORGSTAFF.TITLE.ERROR': 'خطأ',
  'SM.FEDERATION.MAPPINGRULE.REMOTE': 'معلومات المستخدم الخارجي',
  'SM.COMMON.MESSAGE.DATADICT_SURE_DELETE': 'هل تريد بالتأكيد الحذف؟',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORBIDFOREVER': 'تعطيل بشكل دائم',
  'SM.ORGSTAFF.MESSAGE.EXPORT_LIMIT': 'عدد الصادرات أكثر من 10,000 لا ينصح للتصدير.',
  'SM.AUTH.TITLE.ROLE_BASIC_DATA_PERMISSION': 'إذن البيانات الأساسية',
  'SM.COMMON.LABEL.MEASUREMENT_CONV': 'تحويل القياس',
  'SM.COMMON.LABEL.OPERLOG_SEARCH': 'البحث',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SET_ROLE': 'تعيين الدور',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BE': 'BE ID',
  'SM.COMMON.LABEL.SYSMENU_MENUNAME': 'اسم القائمة',
  'SM.ORGSTAFF.LABEL.TEAM_INCLUDESUB': 'تضمين Sub-OU',
  'SM.AUTH.TITLE.BELONG_ROLE_TMPL': 'قالب دور المالك',
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_FAX': 'فاكس المكتب',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_DESC': 'الوصف',
  'SM.COMMON.TITLE.SUCCESS_SUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.AUC.LABEL.USERLOGUI_LOGINIDACCOUNT': 'تسجيل الدخول إلى الحساب',
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE_AND_CREATE': 'نسخ وظيفة إذن والبيانات إذن وإنشاء دور',
  'SM.COMMON.LABEL.CURRENCY_DECIMALPOSITIONS': 'الأماكن العشرية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD':
    "يمكن أن تحتوي القيمة على أرقام وحروف وشرطات سفلية (_), واصلة (-) وفترات (.), وعلامات ({'@'}).",
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL_INPUT': 'قالب الدور المراد اختياره:',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYTYPE': 'النوع',
  'SM.COMMON.LABEL.CHARGECODE_NAME': 'الاسم',
  'SM.COMMON.LABEL.OPERLOG_ENDTIME': 'وقت الانتهاء',
  'SM.AUTH.MESSAGE.SAVE_SUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.ORGSTAFF.LABEL.USER_COUNT': 'عدد المستخدمين',
  'SM.COMMON.LABEL.SURVEY_CREATED_ACCOUNT': 'تم الإنشاء بواسطة',
  'SM.COMMON.LABEL.AREAINFO_MAINTAIN': 'إدارة رمز المنطقة',
  'SM.AUTH.MESSAGE.ROLE_NAME_SAME': 'اسم الدور موجود بالفعل.',
  'SM.ORGSTAFF.TITLE.REGION_ENDIP': 'نهاية IP',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MAC_ADDR': 'عنوان MAC',
  'SM.COMMON.BUTTON.EDIT': 'تعديل',
  'SM.ORGSTAFF.OPTION.YES': 'نعم',
  'SM.COMMON.BUTTON.SAVE': 'حفظ',
  'SM.AUTH.BUTTON.CONFIRM_DELETE': 'تأكيد الحذف',
  'SM.ORGSTAFF.MESSAGE.STAFF_SUSPEND_CONFIRM': 'هل تريد بالتأكيد تعليق حساب الموظف؟',
  'SM.COMMON.LABEL.SYSMENU_STATUSDATE': 'تاريخ الحالة',
  'SM.AUTH.LABEL.ORGANIZATION_INPUT': 'الوحدة التنظيمية:',
  'SM.ORGSTAFF.BUTTON.SAVE': 'حفظ',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDAY': 'عطلة',
  'sm.assign.employee': 'تعيين موظف',
  'SM.AUTH.LABEL.ROLE_INFO': 'معلومات الدور',
  'SM.ORGSTAFF.LABEL.TEAM_CONTACTINFO': 'معلومات الاتصال',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_NAME': 'اسم الوحدة',
  'SM.FEDERATION.COMMON.UPDATE.SUCCESS': 'نجح التعديل.',
  'SM.AUTH.TITLE.ERROR': 'خطأ',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_BE': 'BE',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_DAUTH': 'إذن البيانات الأساسية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR': 'يحتوي اسم المستخدم على أحرف خاصة غير صالحة.',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_ID': 'معرف الاستبيان',
  'SM.AUTH.LABEL.CLEAR_CONFIRM_CLEAR_DATA': 'هل تريد بالتأكيد حذف الأذونات غير المستخدمة مؤخرًا؟',
  'SM.COMMON.LABEL.CURRENCY_DISPFORMAT_DISPLAYTYPE': 'نوع العرض',
  'SM.FEDERATION.COMMON.SUBMIT': 'إرسال',
  'SM.FEDERATION.COMMON.DOWLOADTEM': 'تنزيل قالب',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NO_ENTITY': 'حدد دورًا واحدًا على الأقل أو BE.',
  'SM.AUTH.LABEL.ROLE_CODE_INPUT': 'رمز الدور:',
  'SM.ORGSTAFF.TIPS.BE_LOGO':
    'حدد صورة PNG، GIF، أو JPG التي لا يتجاوز حجمها 50 كيلوبايت و145*50 بكسل.',
  'SM.COMMON.LABEL.OPERLOG_STARTTIME': 'وقت البدء',
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_USEPARENT':
    'إذا تم استخدام بيانات العقدة الأصلية، فسيتم مسح تكوين المنطقة الزمنية الحالية. هل أنت متأكد من رغبتك في المتابعة؟',
  'SM.ORGSTAFF.LABEL.REGION_DESCRIPTION': 'الوصف',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE': 'حفظ',
  'SM.COMMON.LABEL.SURVEY_TITLE_TYPE': 'نوع السؤال',
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_CFG': 'تنسيق العنوان',
  'SM.ORGSTAFF.LABEL.STAFF_OPRATION': 'العملية',
  'SM.AUTH.TITLE.ROLE_GRANTABLE_FUNCTION_PERMISSION': 'إذن وظيفة منح',
  'SM.ORGSTAFF.BUTTON.COMMON_OK': 'حسناً',
  'SM.AUTH.LABEL.ROLE_COPY_MODE': 'وضع النسخ',
  'SM.ORGSTAFF.MESSAGE.TIMEZONE_TIME_ERROR':
    'لا يمكن أن يكون وقت البدء أكبر من وقت الانتهاء أو مساوياً له.',
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_FAIL': 'فشلت الإضافة.',
  'SM.MENU.ADD.CUSTOM.MENU': 'إضافة قائمة',
  'SM.EMPLOYEE.SELECTED.EMPLOYEE': 'الموظفين المختارين',
  'SM.ORGSTAFF.TITLE.BANK_TITLE': 'البنك',
  'SM.FEDERATION.COMMON.DETAIL': 'التفاصيل',
  'SM.COMMON.LABEL.OPERLOG_LOGIN_IP': 'IP تسجيل الدخول',
  'SM.USERGROUP.DELETEG_GROUP.TITLE': 'هل تريد حذف مجموعة المستخدمين المحددة؟',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_RATIO': 'نسبة وحدة القياس',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_EMPLOYEE': 'تصدير الكل',
  'SM.ORGSTAFF.TITLE.ERROR_TITLE': 'خطأ',
  'SM.COMMON.LABEL.SYSMENU_CURRENCYLANGUAGE': 'اللغة الحالية',
  'SM.COMMON.BUTTON.SEARCH': 'البحث',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_NOT_ALLOW_4A': 'السماح بتسجيل الدخول إلى صفحة ويب مباشرة',
  'SM.COMMON.LABEL.ERATE_DCURRENCY_CODE': 'رمز العملة المستهدف',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE_INPUT': 'نوع الإذن:',
  'SM.AUTH.LABEL.ROLE_SUPERCODE': 'رمز دور الوالدين',
  'SM.ORGSTAFF.MESSAGE.ERROR.MAXLIMIT': 'لا يجوز أن يتجاوز عدد المنشآت تحت المستأجر 999 منشأة',
  'SM.ORGSTAFF.BUTTON.ADDR_LINE': 'خط',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_FRONT': 'أشهر (30 يوما) للاحتياط',
  'SM.AUTH.LABEL.FUNCAUTH_DEPENDENT': 'يعتمد على الإذن',
  'SM.COMMON.MESSAGE.EXPORT_VIEW':
    '، يُرجى الانتقال إلى القائمة "إدارة النظام> التكوين المستند إلى المشروع> Project" لعرض النتائج التي تم تصديرها.',
  'SM.AUTH.BUTTON.SAVE': 'حفظ',
  'SM.COMMON.LABEL.MODULE_SHORTNAME': 'الاسم المختصر',
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION_INPUT': 'الوصف:',
  'SM.COMMON.TIPS.BANKCARD_LENGTH': 'أدخل عددًا صحيحًا لا يحتوي على أكثر من رقمين.',
  'SM.ORGSTAFF.MESSAGE.REFRESH_SUCCESS': 'تم إعادة تحديثه بنجاح.',
  'SM.ORGSTAFF.LABEL.REGION_CODE': 'رمز المنطقة',
  'SM.AUTH.OPTION.ROLE_REMOVE_INFO_OF_TARGET_ROLE': 'حذف معلومات الدور الهدف',
  'SM.ORGSTAFF.TITLE.ORG_ACCESS_EMP': 'الوصول إلى الموظف المعين',
  'SM.ORGSTAFF.MESSAGE.AUTHENTICATION': 'التحقق من الهوية',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_QUERY_LIST': 'قائمة الموظفين',
  'SM.AUTH.TITLE.THIRD_DATASOURCE':
    'تم تنفيذ العملية بنجاح (مصدر البيانات الثالث مغلق، لأن مصدر البيانات فارغ.)',
  'SM.FEDERATION.IMPORT.SUCCESSCOUNT': 'السجلات الناجحة',
  'SM.AUTH.TIPS.ROLETMPL_ASSIGN_PERMISSON': 'تعيين إذن',
  'SM.AUTH.MESSAGE.ROLETMPL_CANT_COPY_PERMISSION': 'لا يمكنك نسخ إذن معين. هل تريد المتابعة؟',
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL': 'تحديد قالب الدور',
  'SM.COMMON.LABEL.MEASUREMENT_YES': 'نعم',
  'SM.AUTH.LABEL.ASSIGNRIGHTS': 'أذونات قابلة للتعيين',
  'SM.AUTH.LABEL.ROLE_GENERALROLELIST': 'قائمة الأدوار العامة',
  'SM.COMMON.LABEL.CHOOSE_OU_ADD': 'اختر OU لإضافة',
  'SM.COMMON.MESSAGE.ERATE_TIME_COMPARE': 'يجب أن يكون وقت الانتهاء بعد وقت البدء.',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_NAME': 'الموظف',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEFAILED': 'فشل الحفظ',
  'SM.COMMON.TITLE.ERATE_ERROR': 'خطأ',
  'SM.ORGSTAFF.TITLE.WORKGROUP': 'مجموعة العمل',
  'SM.ORGSTAFF.LABEL.ORG_RECOVER_TIPS':
    'وبعد استئنافها، ستكون المنظمة أو المنظمة التابعة لها متاحة. هل أنت متأكد من استئناف؟',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTH': 'تاريخ الميلاد',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_LIST': 'وحدة قياس العملة',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH_INPUT': 'معرّف الإذن الأصلي:',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTDESC': 'الوصف',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_HISTORY': 'تاريخ الإعارة',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD_INPUT': 'اسم الحقل:',
  'SM.COMMON.BUTTON.CHARGECODE_EDIT': 'تعديل',
  'SM.ORGSTAFF.TITLE.EMPTY': 'لا يمكن أن يكون كل من البريد الإلكتروني و ContactPhone فارغين',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTYPE': 'نوع القفل',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLEVIEW': 'عرض الدور',
  'SM.COMMON.BUTTON.CHARGECODE_SAVE': 'حفظ',
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_PUB': 'الدور العام',
  'SM.COMMON.LABEL.ATTACHMENT_DELETE_INFO': 'هل أنت متأكد من رغبتك في حذف الملف؟',
  'SM.COMMON.LABEL.MODULE_MODULETYPE': 'الفئة',
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION': 'حذف سؤال',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME_INPUT': 'اسم قالب الدور:',
  'SM.EMPLOYEE.CANNOT.CHOOSE.WORKNO': 'لا يمكنك تحديد موظف تم تعيين معرف موظف له.',
  'SM.AUTH.MESSAGE.OK': 'حسناً',
  'SM.AUTH.LABEL.ROLE_DERIVE_PARENTROLE': 'الدور العام الموروث',
  'SM.ORGSTAFF.TITLE.CONTACT_DESC': 'الوصف',
  'SM.TENANT.LABEL': 'المستأجر',
  'SM.AUTH.TIPS.ROLE_INPUTROLENAME': 'إدخال اسم دور',
  'SM.COMMON.LABEL.HOLIDAY_THURSDAY': 'الخميس',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NODE_INFO':
    'حدد عقدة أصلية في شجرة التنقل على اليسار وقم بإدارة قوالب الأدوار في شجرة قالب الدور.',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG2': 'يوم (أيام).',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG1': 'لا يمكن أن يتجاوز النطاق الزمني',
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPAWDAUTHTYPE': 'حالة مصادقة كلمة المرور الأخيرة',
  'SM.USERPWDGROUP.RANGE.VALIDATE': 'النطاق %-%',
  'SM.COMMON.TITLE.ACCESS.SELECT_STAFF': 'تحديد وصول الموظفين',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID_INPUT': 'معرف الإذن:',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_9': 'أيلول/سبتمبر',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_8': 'أغسطس',
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT_INPUT': 'الحساب:',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_7': 'يوليو',
  'SM.COMMON.TIPS.CHARGECODE_CODEINPUT': 'يمكنك فقط إدخال الحروف والأرقام.',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_6': 'يونيو',
  'SM.COMMON.LABEL.CURRENCY_BASIC_INFO': 'معلومات أساسية',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_5': 'أيار/مايو',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_4': 'نيسان/أبريل',
  'SM.TENANTSPACE.LDAP_SERVERURL': 'عنوان URL للخادم',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_3': 'مارس',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_2': 'فبراير',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_1': 'يناير',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE': 'نوع مصدر قيمة البيانات',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_EXT_TITLE':
    'يمكن أن يتراوح حجم الملف المراد تحميله من {1} كيلوبايت إلى 0 كيلوبايت. {2} الملفات مسموح بها ولكن {3} الملفات غير مسموح بها.',
  'SM.ORGSTAFF.BUTTON.BE_COPYPARENT': 'نسخ بيانات العقدة الأصلية',
  'SM.COMMON.MESSAGE.CURRENCY_DECIMALNOZEROFLAG': 'إزالة الأصفار الأخيرة',
  'SM.ORGSTAFF.LABEL.ORG_LEVEL': 'مستوى OU',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_CONFIDENTIAL': 'سرية',
  'SM.COMMON.LABEL.HIGHRISKLOGList': 'مراقبة عملية عالية المخاطر',
  'SM.AUC.LABEL.LOGINSESSION_LOCKFLAG': 'قفل العلم',
  'SM.ORGSTAFF.TITLE.CONTACT': 'الإتصال',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_LIST': 'قائمة تحويل القياس',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE_DETAIL': 'الأدوار التي يمكن أن تمنح إذن الوظيفة للآخرين',
  'SM.COMMON.LABEL.SURVEY_OPTION_TYPE': 'نوع الخيار',
  'SM.ORGSTAFF.LABEL.TEAM_PARENTORG': 'المستوى الأعلى OU',
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE': 'الدور المستهدف',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_DELETE': 'حذف',
  'SM.COMMON.LABEL.SYSMENU_USEPARENT': 'استخدام بيانات الوالدين',
  'SM.USERGROUP.SHOW.LIST': 'مجموعات المستخدمين',
  'SM.ORGSTAFF.LABEL.OU_HIERARCHY': 'OU التسلسل الهرمي',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_EXT_TITLE':
    'فشل التحميل. تنسيقات الملفات المسموح بها: 0؛ تنسيقات الملفات غير مسموح بها: {1}.',
  'SM.AUTH.TITLE.ASSIGNED_EMPLOYEE': 'الموظف المعين',
  'SM.ORGSTAFF.MESSAGE.SELECT_ORG': 'حدد OU.',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_SUCCESS': 'النجاح',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID_INPUT': 'معرف الدور:',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTIME': 'قفل الوقت',
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED_S': 'إلغاء التنشيط',
  'SM.COMMON.LABEL.BANKCARD_TYPE_PARAM': 'نوع البطاقة',
  'SM.COMMON.LABEL.SYSMENU_MENUCRUMB': 'مسار',
  'SM.AUTH.TITLE.PERMISSION_RECOVERY': 'إعادة تدوير الإذن',
  'SM.ORGSTAFF.TITLE.IMPORT_MAC_TITLE': 'تقييد استيراد تسجيل الدخول',
  'SM.ORGSTAFF.LABEL.REGION_LEVEL': 'مستوى المنطقة',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA': 'معايير البحث',
  'SM.COMMON.MESSAGE.MODULE_MODULEADDRESSNOTMATCH': 'عنوان الوحدة النمطية غير متطابق.',
  'SM.ORGSTAFF.LABEL.ALLORG': 'Todas las Organizaciones',
  'SM.COMMON.TIPS.PLZ_SAVE_CURRENCY_MEASUREMENT_UNIT': 'يرجى حفظ وحدة قياس العملة.',
  'SM.AUTH.MESSAGE.OPERATE_WITH_ROLES':
    'تم إنشاء الإذن بنجاح، وتعيينه إلى المشغل الحالي والأدوار التالية:',
  'SM.ORGSTAFF.LABEL.SELECT_EXCEL': 'الرجاء تحديد ملف excel',
  'SM.COMMON.LABEL.BANKCARD_BANKCODE': 'كود البنك لإصدار البطاقة',
  'SM.AUTH.TITLE.USERAUTHVIEW_DETAIL_INFO': 'حدد عقدة إذن ذات URL.',
  'SM.COMMON.LABEL.HOLIDAY_WEDNESDAY': 'Wed',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE': 'القائمة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORCE_DELETE': 'فرض الحذف',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_CODE': 'الكود',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_DEFAULT': 'القياس الافتراضي',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTDATE': 'DST تاريخ البدء',
  'SM.ORGSTAFF.TITLE.MEASUREMENT': 'وحدة القياس',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD': 'فشل التحقق من كلمة مرور الموظف.',
  'SM.ORGSTAFF.LABEL.BANK_TYPE': 'نوع البنك',
  'SM.COMMOM.BUTTON.EDIT': 'تعديل',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE': 'Deactive',
  'SM.TENANT.PHONE': 'تليفون',
  'SM.COMMON.LABEL.MEASUREMENT_NO': 'لا',
  'SM.ORGSTAFF.TITLE.ORG_ASSIGNED_EMP': 'الموظفون المعينون',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MIN':
    'حجم الملف الذي تم تحميله أصغر من حجم الحد الأدنى 0 كيلوبايت.',
  'SM.COMMON.LABEL.HOLIDAY_TUESDAY': 'الثلاثاء',
  'SM.ORGSTAFF.LABEL.BE_NAME': 'كن اسم',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD': 'حقل',
  'SM.ORGSTAFF.LABEL.STAFF_INFO_BASIC': 'معلومات أساسية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESET': 'إعادة الضبط',
  'SM.AUTH.MESSAGE.ROLETMPL_QUERY_FAILED': 'فشل البحث.',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED': 'تجاهل',
  'SM.COMMON.LABEL.OPERLOG_OPER_TYPE': 'نوع العملية',
  'SM.ORGSTAFF.TIPS.INVALID_IP_ADDRESS': 'عنوان IP غير صالح.',
  'SM.USERGROUP.LABEL.NAME': 'اسم مجموعة المستخدمين',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_ENDDATE': 'تاريخ الانتهاء',
  'SM.COMMON.TIPS.DIGITS_ONLY': 'يرجى إدخال الأرقام.',
  'SM.ORGSTAFF.LABEL.ACCESS.ORG_REQUIRED': 'يرجى تحديد منظمة.',
  'SM.COMMON.LABEL.CURRENCY_ROUNDMODE': 'وضع التقريب',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER_INPUT': 'تصفية حسب BE:',
  'SM.COMMON.BUTTON.MEASUREMENT_EXPORT': 'تصدير',
  'SM.ORGSTAFF.LABEL.INPUT_REGION_NAME': 'الرجاء إدخال اسم المنطقة',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_FORMAT': 'قالب تنسيق العنوان',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SEGMENT': 'مقطع عنوان IP',
  'SM.ORGSTAFF.LABEL.TEAM_STATUS': 'الحالة',
  'SM.ORGSTAFF.LABEL.ADDR_ORDER': 'تسلسل العرض',
  'SM.COMMON.LABEL.CHARGECODE': 'رمز الشحن',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IP_TYPE': 'نوع IP',
  'SM.COMMON.LABEL.SURVEY_NOT_INPUT': 'عدم السماح بالإدخال اليدوي',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_FAILED': 'فشل الحذف.',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_ACCESSORY': 'حذف',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME': 'الموظف',
  'SM.AUTH.TIPS.ROLE_PARENTROLE': 'اسم الدور',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SEARCH': 'البحث',
  'SM.COMMON.LABEL.EDIT_CURRENCY_EXCHANGE_RATE': 'تعديل سعر الصرف',
  'SM.COMMON.LABEL.MEASUREMENT_IMPORT_RESULT': 'نتيجة الاستيراد',
  'SM.ORGSTAFF.TITLE.MSGBOX_INFO': 'نصائح',
  'SM.ORGSTAFF.LABEL.CHANNEL_ID': 'معرف القناة',
  'SM.COMMON.LABEL.ATTR_LANGUAGE': 'اللغة',
  'SM.COMMON.LABEL.OPERLOG_MODULE': 'الوحدة النمطية',
  'SM.COMMON.MESSAGE.SYSMENU_SAVESUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_NAME': 'الاسم',
  'SM.ORGSTAFF.TITLE.CURRENCY_TYPE': 'نوع العملة',
  'SM.COMMON.TIPS.ONLYONE_UNDERLINE_FORBIDDEN': 'لا يمكن أن تحتوي قيمة الإدخال على أحرف خاصة.',
  'SM.COMMON.LABEL.MODULE_ADDRESS': 'عنوان URL',
  'SM.AUTH.MESSAGE.ROLE_DELETE_SUCCESSFUL': 'تم الحذف بنجاح.',
  'SM.COMMON.MESSAGE.OPERATE_FAIL': 'فشلت العملية.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION': 'معايير تصفية البيانات',
  'SM.ORGSTAFF.LABEL.BREGION_NAME': 'المنطقة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD_CONFIRM': 'تأكيد كلمة المرور الجديدة',
  'SM.ORGSTAFF.LABEL.STAFF_DESC': 'الوصف',
  'SM.COMMON.LABEL.CHARGECODE_GROUPTYPE': 'نوع المجموعة',
  'SM.AUTH.LABEL.ROLE_ID': 'معرف الدور',
  'SM.COMMON.LABEL.CHARGECODE_RELEASE': 'الافراج',
  'SM.COMMON.LABEL.EDIT_AREAINFO_MAINTAIN': 'تعديل معلومات المنطقة',
  'SM.COMMON.LABEL.SYSMENU_VISITCOUNT': 'عدد الزيارات',
  'SM.AUTH.LABEL.ROLE_NO_INFO': 'إدارة الأدوار في شجرة الأدوار على اليسار.',
  'SM.AUC.MESSAGE.LOGINSESSION_LOGOUT_STATUS': 'لم يتم تسجيل الدخول',
  'SM.AUTH.LABEL.FUNCAUTH_STATUS': 'الحالة',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMNAME': 'اسم الفريق',
  'SM.COMMON.MESSAGE.VALIDATE_RANGELENGTHB': 'أدخل قيمة من 0 إلى 1 حرفًا.',
  'SM.EMPLOYEE.CANNOT.CHOOSE.STATUS': 'لا يمكن تحديد الموظفين في حالة تعطيل.',
  'SM.ORGSTAFF.TITLE.COMMON_QUERYCONDITION_TITLE': 'معايير البحث',
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION_TYPE': 'حذف نوع السؤال',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_SIGNTIME': 'وقت تسجيل الدخول',
  'SM.ORGSTAFF.MESSAGE.BE_CANNOTDELETECURRENCY': 'لا يمكن حذف العملة الافتراضية.',
  'SM.ORGSTAFF.TITLE.CONTACT_MIDDLE_NAME': 'الاسم الأوسط',
  'SM.ORGSTAFF.MESSAGE.SELECT_EMPLOYEE': 'يرجى اختيار موظف',
  'SM.COMMON.TIPS.CHARGECODE_NAME': 'لا يمكن أن تحتوي قيمة الإدخال على أحرف خاصة.',
  'SM.COMMON.LABEL.CHARGECODE_DRAFT': 'مشروع',
  'SM.COMMON.LABEL.SYSMENU_LABEL': 'قائمة النظام',
  'SM.AUTH.MESSAGE.PASSWORD_LENTH_LESS_THAN_8':
    'كلمة مرور قصيرة تجلب مخاطر أمنية. هل تريد بالتأكيد استخدام كلمة المرور الجديدة هذه؟',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE_INPUT': 'نوع العملية:',
  'SM.AUTH.LABEL.CONFIRM_DELETE_DEPEND_AUTH': 'تأكيد حذف الإذن تعتمد؟',
  'SM.AUTH.LABEL.BIZ_OBJ_FULL_NAME': 'اسم الكائن بالكامل',
  'SM.ORGSTAFF.LABEL.REGION_DISPLAYORDER': 'تسلسل العرض',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_LASTNAME': 'الاسم الأخير',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_RATIO': 'نسبة وحدة القياس',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED_D': 'تجاهل',
  'SM.COMMON.LABEL.OPERLOG_OPER_TIME': 'وقت العملية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SUSPEND': 'تعليق',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RELEASE': 'هل تريد بالتأكيد تغييرها إلى حالة تحرير؟',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_PAUSE': 'تعليق',
  'SM.COMMON.TITLE.SURVEY_CLASS_NAME_INFO': 'معلومات اسم التصنيف',
  'SM.AUTH.TITLE.PERMISSION_SELECTION': 'تحديد إذن',
  'SM.AUTH.TIPS.ROLETMPL_CREATE_AND_ASSIGN': 'إنشاء دور وتعيين إذن',
  'SM.AUC.LABEL.KIND': 'الإعلانات المبوبة',
  'SM.COMMON.LABEL.SURVEY_ALLOW_INPUT': 'السماح بالإدخال اليدوي',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RETIRE': 'هل تريد بالتأكيد تغييرها إلى الحالة "تم الإلغاء"؟',
  'SM.COMMON.BUTTON.COMMON_ADD': 'إضافة',
  'SM.COMMON.LABEL.ATTACHMENT_LIMIT_INFO': 'لا قيود',
  'SM.COMMON.LABEL.SYSMENU_CONFIGURESYSMENU': 'تكوين قائمة النظام',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_IS_WRITEABLE': 'قابل للكتابة',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD_INPUT': 'حقل الفرز:',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_NAME': 'الموظف',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CLEAR': 'مسح',
  'SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB': 'إدخال 0 حرف على الأقل.',
  'SM.AUTH.LABEL.ROLE_TMPL_LIST': 'قائمة قالب الدور',
  'SM.ORGSTAFF.BUTTON.FORBID_FOREVER': 'تعطيل بشكل دائم',
  'SM.AUTH.LABEL.COMPARE_RESULT': 'نتيجة المقارنة',
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_ID': 'منزل الدور BE ID',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_SUNIT': 'وحدة قياسية',
  'SM.AUTH.LABEL.ROLE_DERIVE': 'وراثة الأدوار',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_BENAME': 'منزل الدور العام BE',
  'SM.COMMON.MESSAGE.SYSMENU_AUTH_ASSIGNED_MUTIL_MENUS':
    'تم تعيين الإذن الموجود في القائمة إلى قوائم متعددة. إذا تجاهلت القائمة، سيتم إلغاء كافة الأذونات. هل تريد المتابعة؟',
  'SM.AUC.MESSAGE.LOGINSESSION_LOCKSTATUS': 'مغلق',
  'SM.COMMON.TIPS.CURRENCY_DECIMALPOSITIONS_TIPS':
    'تشير القيمة -1 إلى عرض المنازل العشرية استنادًا إلى دقة تخزين العملة.',
  'SM.AUTH.LABEL.MODULE_INPUT': 'الوحدة النمطية:',
  'SM.COMMON.LABEL.CURRENCY_MINIMUM_MEASUREMENT': 'دقيقة. علم الوحدة',
  'SM.FEDERATION.COMMON.CANEL': 'إلغاء',
  'SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB': 'يُسمح بحد أقصى 0 حرفًا.',
  'SM.AUTH.TITLE.USER_PERM_COMPARE': 'مقارنة أذونات المستخدم',
  'SM.AUTH.TITLE.INPUT_AUTH_OBJ_NAME': 'إدخال اسم كائن إذن',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MARITALSTATUS': 'الحالة الاجتماعية',
  'SM.AUTH.TITLE.INPUT_USER_LOGINID': 'إدخال حساب مستخدم',
  'SM.ORGSTAFF.TIPS.RESTRICTIP': 'أدخل عنوان IP بالتنسيق xx.* أو xx.xx.* أو xx.xx.xx.*.',
  'SM.AUTH.LABEL.DATAAUTHMGR_TAB_AUTH_ROLE_VIEW': 'الأدوار المرتبطة بإذن البيانات',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_LIST': 'قائمة الأدوار',
  'SM.AUTH.MESSAGE.ROLE_THE_ROLES_PERMISSION_OPPED':
    'فشل الحفظ لأن إذن الوظيفة الأساسية له علاقة حصرية.',
  'SM.AUTH.TITLE.MENU_NAME': 'اسم القائمة',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTCODE': 'نوع المرفق',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_PARENTS_TITLE': 'معلومات وراثة الدور',
  'SM.AUTH.LABEL.BE_BASIC_INFORMATION': 'معلومات أساسية',
  'SM.ORGSTAFF.LABEL.ORG_IMPORTDATA': 'استيراد البيانات',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID': 'رمز قاموس البيانات',
  'SM.ROLE.SELECT.ERRORCAUSE': 'سبب الاستثناء:',
  'SM.ORGSTAFF.TITLE.TEAM_SUCCESS': 'معلومات',
  'SM.FEDERATION.FEDERATIONAPP.CLIENTNAME': 'نظام مصادقة الطرف الثالث',
  'SM.COMMON.BUTTON.SURVEY_ENABLE': 'ممكّن',
  'SM.FEDERATION.COMMON.OPERATION.DELETE': 'حذف',
  'SM.FEDERATION.FEDERATIONAPP.REMOTETIP': 'القيمة هي حقل التعيين المطابق للمستخدم الخارجي.',
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE': 'نسخ معلومات الدور (فقط نسخ وظيفة الإذن والبيانات الإذن)',
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_TIPS': 'يرجى إدخال الرقم.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_STARTDATE': 'تاريخ البدء',
  'SM.COMMON.MESSAGE.PROPERTY_SUCCESS_CREATE_INFO': 'تم تنفيذ العملية بنجاح.',
  'SM.FEDERATION.FEDERATIONAPP.CLIENTURL': 'عنوان URL',
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAIL': 'فشل مصادقة كلمة المرور',
  'SM.AUTH.HEADVALUE.AUTHUSERVIEW_AUTH_DATA': 'من فضلك اختر...',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE': 'إذن الدور غير متوفر',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEEXCLUDING':
    'علاقة الوراثة موجودة بالفعل ولا يمكن استبعاد الدور.',
  'SM.COMMON.MESSAGE.SYSMENU_AUTHIDREQUIRE': 'أدخل معرف إذن القائمة.',
  'SM.COMMON.LABEL.ATTACHMENT_SAVE_SUCCESS': 'تمت إضافة المرفق بنجاح.',
  'SM.ORGSTAFF.MESSAGE.SURE_TO_DO': 'هل تريد المتابعة؟',
  'SM.ORGSTAFF.LABEL.ORG_FORCEDELETE': 'حذف بشكل دائم',
  'SM.ORGSTAFF.TIPS.STAFF_LOCALE':
    'لغة تسجيل دخول الموظف الافتراضية التي يتم استخدامها عند عدم تحديد اللغة عند تسجيل الدخول.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MAX':
    'تجاوز حجم الملف الذي تم تحميله الحد الأقصى 0 كيلوبايت.',
  'SM.COMMON.LABEL.HIGHRISKLOG_PARAMS': 'المعلمة',
  'SM.ORGSTAFF.MESSAGE.STAFF_PWD_RESET_CONFIRM': 'هل تريد بالتأكيد إعادة تعيين كلمة المرور؟',
  'SM.ORGSTAFF.TIPS.RESTRICTMAC': 'يُرجى إدخال عنوان MAC الصحيح.',
  'SM.ORGSTAFF.MESSAGE.SEARCH_USERNAME': 'أدخل اسم موظف.',
  'SM.ORGSTAFF.LABEL.BE_LOGO': 'الشعار',
  'SM.COMMON.LABEL.MENU_ACCESS_END_TIME': 'وقت الانتهاء',
  'SM.MENU.SEARCH.INPUT': 'اسم قائمة البحث',
  'SM.ORGSTAFF.BUTTON.ADDR_CREATE': 'جديد',
  'SM.ORGSTAFF.LABEL.TO_BE_ASSIGNED_ROLE': 'ليتم تعيين دور',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_TIMESAME':
    'لا يمكن أن يكون وقت البدء ووقت الانتهاء لمنطقة زمنية متطابقين.',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TIMERANGEERROR':
    'يجب أن يكون وقت نهاية تسجيل الدخول لاحقًا ثم وقت بدء تسجيل الدخول. ربط عنوان MAC',
  'SM.ORGSTAFF.MESSAGE.ORG_NOT_EXSIT': 'وحدة OU غير موجودة.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD_INPUT': 'اسم معرف الحقل:',
  'SM.AUTH.MESSAGE.ROLETMPL_SHOWNAME': 'قالب الدور',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME': 'اسم قالب الدور',
  'SM.AUTH.BUTTON.IMPORT_ROLE': 'استيراد دور',
  'SM.ORGSTAFF.MESSAGE.ORG_ROLE_DELETE':
    'في حالة حذف هذه البيانات، سيتم حذف العلاقة بين المستخدم والدور تلقائيًا. هل تريد المتابعة؟',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_OPERARION': 'العملية',
  'SM.COMMON.LABEL.HOLIDAY_MONDAY': 'مون',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDDATE': 'تاريخ انتهاء DST',
  'SM.ORGSTAFF.LABEL.ORG_NAME': 'اسم OU',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_BE': 'BE',
  'SM.AUTH.TITLE.CONFIRM_DEL': 'تأكيد',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMLEVER': 'مستوى الفريق',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_DATAAUTHLIST': 'قائمة كائنات الأذونات',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_ROLE_TMPL': 'تحديد قالب الدور',
  'SM.COMMON.LABEL.MENU_INFO_TITLE': 'القائمة معلومات أساسية',
  'SM.COMMON.LABEL.SURVEY_TYPE_NAME': 'اسم الفئة',
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_PHONE': 'هاتف المكتب',
  'SM.AUTH.LABEL.ROLE_DESC': 'الوصف',
  'SM.COMMON.BUTTON.MEASUREMENT_DELETE': 'حذف',
  'SM.ORGSTAFF.TITLE.POPUP_NEW_PROPERTY': 'خاصية جديدة',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEEN_EXTENDED': 'فشل حذف الدور لأنه موروث أو ورث دورًا آخر.',
  'SM.COMMON.BUTTON.CANCEL': 'إلغاء',
  'SM.COMMON.LABEL.SYSMENU_IDEL': 'الافراج',
  'SM.EMPLOYEE.SOFTCONSOLE.GROUP': 'الصفحة الرئيسية مجموعة المرور',
  'SM.ROLE.SELECT.ERRORMSG': 'استثناء الوصف:',
  'SM.AUTH.LABEL.LOGINLOG_RESET': 'إعادة الضبط',
  'SM.ORGSTAFF.LABEL.DEPTMACRESTRICT': 'تقييد عنوان MAC',
  'SM.COMMON.LABEL.MODULE_ID': 'معرف الهوية',
  'SM.COMMON.LABEL.BANKCARD_CREATETIME': 'إضافة الوقت',
  'SM.AUTH.TIPS.DATA_AUTH_PARAM_INVALID':
    "اسم الجدول ، يمكن أن يحتوي اسم العمود فقط على أحرف ورقم و '_' ويمكن أن يبدأ فقط بأحرف.",
  'SM.ORGSTAFF.LABEL.BE_ID': 'BE ID',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORDAGAIN': 'تأكيد كلمة المرور',
  'SM.AUTH.LABEL.ROLE_ID_INPUT': 'معرف الدور:',
  'SM.AUTH.TITLE.AUTHNAMEINFO': 'معلومات اسم الإذن',
  'SM.ORGSTAFF.LABEL.BANK_LEVEL': 'مستوى البنك',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_ADD_ROLE': 'إضافة دور',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYCODE': 'رمز العملة',
  'SM.ORGSTAFF.MESSAGE.FORBID_EXSIT_EMPLOYEE':
    'فشل تعطيل القسم بشكل دائم بسبب وجود موظفين تحت القسم.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE_INPUT': 'جدول:',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_NAME': 'وحدة',
  'SM.ORGSTAFF.LABEL.ORG_FORBID': 'تعطيل',
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERYCONDITION_TITLE': 'معايير البحث',
  'SM.AUTH.LABEL.PERMISSION_CLASS_INPUT': 'تصنيف الأذونات:',
  'SM.AUTH.TITLE.ROLE_BUSINESS_ENTITY': 'BE ID',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FAILURE': 'فشل',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_ASSIGN': 'تعيين دور',
  'SM.AUTH.MESSAGE.ROLE_ERROR_SUBROLES': 'لا يمكن حذف الدور لأنه يحتوي على أدوار فرعية.',
  'SM.AUC.MESSAGE.RULEGROUP': 'مجموعة القاعدة',
  'SM.AUTH.LABEL.BIZ_OBJ_IS_OWNER': 'هو المالك',
  'SM.AUTH.BUTTON.ADDAUTH': 'إضافة',
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_NOTUSEPARENT':
    'إذا تم إلغاء بيانات العقدة الأصلية، فلن يتم استخدام تكوين العقدة الأصلية. هل تريد المتابعة؟',
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG': 'طول رقم البطاقة',
  'SM.ORGSTAFF.OPTION.STAFF_RETAIN_PERMISSION': 'الاحتفاظ بإذن المشغل المستهدف',
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT': 'الحساب',
  'SM.AUTH.BUTTON.ROLE_DESELECT_ALL': 'إلغاء تحديد الكل',
  'SM.COMMON.BUTTON.MEASUREMENT_ADD': 'جديد',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_INVALID': 'غير صالح',
  'SM.ORGSTAFF.MESSAGE.SELECT_WG_INFO': 'يرجى تحديد مجموعة عمل.',
  'SM.AUTH.MESSAGE.DATAPER_CONFIRM_DEL': 'هل تريد بالتأكيد الحذف؟',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYP_NAME': 'اسم نوع الكائن',
  'SM.AUTH.OPTION.ROLE_AUTH_REMOVE': 'حذف',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LIST': 'قائمة الموظفين',
  'SM.AUTH.MESSAGE.FUNCAUTH_NORMAL': 'عادي',
  'SM.AUC.MESSAGE.LOGINSESSION_LOGININ_STATUS': 'سجّل الدخول',
  'SM.AUTH.LABEL.FUNCAUTH_INFO': 'معلومات أساسية',
  'SM.ORGSTAFF.TITLE.TEAM_ASSIGNEDEMPL': 'الموظفون المعينون (لا يمكن ربط أكثر من 2000 موظف.)',
  'SM.ORGSTAFF.MESSAGE.SELECT_REGION': 'حدد منطقة.',
  'SM.COMMON.LABEL.CURRENCY_FORMAT_DISPLAY': 'تنسيق المعاينة',
  'SM.ORGSTAFF.TITLE.ASSIGNE_EMPLOYEE': 'تعيين موظف',
  'SM.FEDERATION.FEDERATIONAPP.EDIT': 'تعديل التطبيق المتحد',
  'SM.COMMON.LABEL.SYSMENU_PARENT': 'معرف القائمة الأصلية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_CREATE_FAILD': 'فشل إنشاء الموظف. يُرجى تأكيد معلومات الحساب.',
  'SM.PWDRULE.PLEASE.INPUT': 'أدخل اسم مجموعة قاعدة',
  'SM.ORGSTAFF.MESSAGE.SELECTEDOU_CANNOT_SELECTED_AGAIN': 'لا يمكن تحديد المؤسسة المحددة مرة أخرى.',
  'SM.FEDERATION.IMPORT.RESULT': 'نتيجة استيراد المستخدم المتحد',
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_MUSTEXIST':
    'يجب أن يحتوي نظام القياس على وحدة قياس قياس قياسية.',
  'sm.edit.usergroup': 'تحرير مجموعة مستخدمين',
  'SM.FEDERATION.IMPORT.FAILCOUNT': 'السجلات الفاشلة',
  'SM.ORGSTAFF.TIP.ACCESS_USER_DESC':
    'الموظفون الذين لا ينتمون إلى OU ولكن يُسمح لهم بالوصول إلى موارد OU واستخدامها',
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_CONFIGURATE': 'تكوين قالب الدور',
  'SM.AUTH.LABEL.OPERATION_TYPE': 'نوع العملية',
  'SM.COMMON.LABEL.BANKCARD_SEARCH_CONDITION': 'شرط البحث',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_ID': 'معرف الدور',
  'SM.COMMON.MESSAGE.OPERATEFAIL': 'فشلت العملية.',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_ID': 'معرف الكائن',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF_INPUT': 'تكوين شجرة إضافي:',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_TITLE': 'مرفق جديد',
  'SM.AUTH.LABEL.FUNCAUTH_UPLOAD': 'تحميل المرفقات',
  'SM.FEDERATION.IMPORT.USER': 'استيراد مستخدم اتحادي',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_TYPE': 'نوع الوحدة',
  'SM.COMMON.LABEL.MEASUREMENT_ERROR_DESCRIPTION': 'وصف الخطأ',
  'SM.FEDERATION.FEDERATIONAPP.DELETETIP': 'هل تريد بالتأكيد حذف البيانات؟',
  'SM.AUTH.LABEL.ROLE_NORMALROLE': 'الدور المشترك',
  'SM.COMMON.TITLE.MEASUREMENT_INFO': 'معلومات أساسية',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_EXT': 'معلومات موسعة',
  'SM.COMMON.LABEL.SYSMENU_SELECTORGANIZATION': 'تحديد إذن',
  'SM.COMMON.BUTTON.ADD_MENU_ACCESS_TIME':
    'إضافة وقت الوصول إلى القائمة. إذا لم يتم التهيئة، فيمكن الوصول إلى القائمة في أي وقت.',
  'SM.AUTH.TITLE.ROLE_TMPLATE_ID': 'معرف قالب الدور',
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_DUTY': 'دور الواجب',
  'SM.AUTH.TITLE.USERAUTHVIEW_FUNCTIONLIST': 'قائمة أذونات الوظيفة',
  'SM.EMPLOYEE.ALLOCATE.WORKNO': 'تعيين معرف موظف',
  'SM.FEDERATION.COMMON.NEW': 'إنشاء',
  'SM.ORGSTAFF.LABEL.ORG_PARENTORG': 'الوالد OU',
  'SM.AUTH.LABEL.ROLETMPL_START_TIME_INPUT': 'وقت البدء:',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PVT': 'نوع صلاحية كلمة المرور',
  'sm.add.usergroup': 'إنشاء مجموعة مستخدمين',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_FORMATE': 'تنسيق العرض',
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPWDAUTHTIME': 'وقت مصادقة آخر كلمة مرور',
  'SM.ORGSTAFF.LABEL.BANK_CODE': 'رمز البنك',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID_INPUT': 'الدور يكون:',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.EXISTS':
    'اسم نظام مصادقة الطرف الثالث أو عنوان URL أو اسم قاعدة التعيين موجود بالفعل.',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT': 'الحساب',
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION': 'سؤال جديد',
  'SM.FEDERATION.FEDERATIONAPP.NEW': 'إنشاء تطبيق اتحادي',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_EDIT': 'تعديل الاستبيان',
  'SM.ORGSTAFF.TITLE.BE_PRECISION': 'دقة العملة',
  'SM.ORGSTAFF.BUTTON.ADD_IN': 'إضافة في',
  'SM.AUTH.LABEL.PERMRECOVERY_ROLE_NAME': 'الدور بإذن',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_NAME': 'اسم الكائن',
  'SM.COMMON.MESSAGE.CHARGECODE_CREATE_DATA': 'هل تريد بالتأكيد إضافة رموز الشحن هذه؟',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME_INPUT': 'اسم إذن الوظيفة:',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME': 'اسم الدور',
  'SM.COMMON.MESSAGE.AREA_DELETE_INFO': 'هل تريد بالتأكيد الحذف؟',
  'SM.AUTH.TITLE.EMPOLYEE_ROLESCOPE': 'الموظف',
  'SM.ORGSTAFF.LABEL.SELECTED_ROLE': 'الدور المحدد',
  'SM.EMPLOYEE.SELECTED.WORKNO.NOT.ENOUGH':
    'عدد معرفات الموظف التي يمكن تخصيصها لنوع الموظف المحدد غير كاف.',
  'SM.AUTH.BUTTON.ADD': 'جديد',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE': 'جدول',
  'SM.ORGSTAFF.LABEL.ORG_EMPLOYEE_QUERY_INFO': 'معايير البحث',
  'SM.COMMON.MESSAGE.PROJECT_CODE': 'رمز المشروع هو:',
  'SM.AUTH.LABEL.FUNCAUTH_OPPAUTHNAME': 'تصريح متبادل حصري',
  'SM.ORGSTAFF.LABEL.REGION_SHORTNAME': 'الاسم المختصر للمنطقة',
  'SM.COMMON.LABEL.SYSMENU_MENUURL': 'عنوان URL للقائمة',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME_INPUT': 'اسم الإذن:',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_NAME': 'اسم الدور',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_TITLE': 'وحدة قياس العملة',
  'SM.COMMON.MESSAGE.ERATE_VLUEW_RANGE': 'تتراوح القيمة من 0 إلى {1}.',
  'SM.AUTH.TITLE.USERAUTHVIEW_DATA_OBJECT_TREE': 'شجرة كائن الإذن',
  'SM.AUTH.TITLE.ROLE_LOGIN_MENU_UPON': 'قائمة العرض التلقائي عند تسجيل الدخول',
  'SM.COMMON.LABEL.HIGHRISKLOG_PERATOR': 'المشغل',
  'SM.ORGSTAFF.TITLE.TEAM_OPERSUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.COMMON.LABEL.HIGHRISKLOG': 'مراقبة عملية عالية المخاطر',
  'SM.AUTH.LABEL.USERAUTHVIEW_SEARCH': 'استعلام',
  'SM.COMMON.LABEL.AREAID': 'معرف المنطقة',
  'SM.FEDERATION.COMMON.UPDATE.FAIL': 'فشل التعديل.',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_USERS': 'فشل حذف الدور لأن الدور مرتبط بأحد الموظفين.',
  'SM.AUTH.LABEL.USER_ACCOUNTB': 'الحساب (ب)',
  'SM.AUTH.LABEL.USER_ACCOUNTA': 'الحساب (أ)',
  'SM.AUTH.MESSAGE.FUNCAUTH_PAUTHNAME': 'اسم الإذن',
  'SM.ORGSTAFF.TITLE.TEAM_AVAILABLEEMPL': 'الموظفين المتوفرين',
  'SM.COMMON.LABEL.HOLIDAY_YEAR': 'السنة',
  'SM.ORGSTAFF.LABEL.FUNCAUTH_OPPAUTHNAME': 'تصريح متبادل حصري',
  'SM.COMMON.LABEL.MODULE_NAME': 'الاسم',
  'SM.COMMON.LABEL.OPERLOG_CONTENT': 'محتوى التشغيل',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_ID': 'معرف الكائن',
  'SM.ORGSTAFF.MESSAGE.DELETE_TITLE': 'تأكيد',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW_DATAAUTHLIST': 'قائمة كائنات الأذونات',
  'SM.COMMON.BUTTON.PROPERTY_CREATE': 'جديد',
  'SM.AUTH.MESSAGE.DATAPER_DEL_SUCCESS': 'تم الحذف بنجاح.',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE': 'مصدر الإذن',
  'SM.COMMON.LABEL.SURVEY_APPLICATION_SCOPE': 'نطاق التطبيق',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DESC': 'الوصف',
  'SM.COMMON.LABEL.HOLIDAY_IMPORT': 'استيراد عطلة',
  'SM.ORGSTAFF.LABEL.TEAM_ORG_EMPLOYEE': 'تنظيم الموظفين',
  'SM.ORGSTAFF.LABEL.TEAM_EXTENDPRO': 'السمات الموسعة',
  'SM.AUTH.MESSAGE.PERM_RECOVERY':
    'إذا قمت بالنقر فوق "موافق"، يتم إبطال أذونات جميع الأدوار التالية. إذا قمت بالنقر فوق "إلغاء"، لن يتم تنفيذ أي عملية.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLODATPERSON': 'تم الرفع بواسطة',
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_INFO': 'معلومات العنوان',
  'SM.ORGSTAFF.TITLE.ADDR_LINE_NUMBER': 'رقم الخط',
  'SM.AUTH.OPTION.GEANTABLE_FUNCTION_PERM': 'إذن وظيفة منح',
  'SM.AUTH.MESSAGE.ROLETMPL_CANNOT_SELECT_MODULE': 'لا يمكن تحديد الوحدة النمطية.',
  'SM.COMMON.LABEL.SURVEY_MUTI_CHOOSE': 'خيارات متعددة',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_41':
    'إذا كان النطاق الزمني قصيرًا جدًا، فسيتأثر أمان كلمة المرور. هل أنت متأكد من تغييره؟',
  'SM.FEDERATION.COMMON.CREATE.SUCCESS': 'تم الإنشاء بنجاح.',
  'SM.COMMON.LABEL.BANKCARD_BANKCODE_PARAM': 'كود البنك لإصدار البطاقة',
  'SM.AUTH.LABEL.FUNCAUTH_DESC_INPUT': 'الوصف:',
  'SM.AUTH.TITLE.ROLE_MUTUALLY_EXCLUSIVE_PERMISSION': 'قائمة الأذونات الحصرية المتبادلة',
  'SM.COMMON.LABEL.ERATE_INFO_LIST': 'قائمة أسعار صرف العملات',
  'SM.FEDERATION.COMMON.DELETE.SUCCESS': 'تم الحذف بنجاح.',
  'SM.FEDERATION.COMMON.OPERATION': 'العملية',
  'SM.AUTH.LABEL.DATAAUTHMGR_ATTRIBUTE': 'توسيع السمة',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.INVALIDFIELD': 'الحقل غير صالح.',
  'SM.ORGSTAFF.MESSAGE.SAVE_SUGGESTION': 'معلومات',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_39':
    'سيؤثر فتح هذه القاعدة على أمان كلمة المرور، يرجى التأكد من استمرار الفتح؟',
  'SM.COMMON.MESSAGE.SYSMENU_ACCESS_TIME_ERROR':
    'يجب أن يكون وقت انتهاء وقت الوصول إلى القائمة أكبر من وقت البدء.',
  'SM.ORGSTAFF.LABEL.TEAM_ORGANIZATION': 'وحدة التنظيم',
  'SM.ORGSTAFF.LABEL.ROLETMPL_PERMISSION_NAME': 'اسم إذن الوظيفة',
  'SM.ROLE.SELECT.ERRORSLOUTION': 'التعامل مع الاقتراحات:',
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_DEPARTMENT': 'القسم',
  'SM.AUTH.MESSAGE.ROLE_CAN_NOT_ADD_ITSELF': 'لا يمكن أن يرث دوره الخاص.',
  'SM.COMMON.BUTTON.BANKCARD_RESET': 'إعادة الضبط',
  'SM.FEDERATION.IMPORT.FAILFILE': 'ملفات فاشلة',
  'SM.AUTH.BUTTON.CANCEL': 'إلغاء',
  'SM.ORGSTAFF.HINT.PLEASE_INPUT': 'يُرجى الإدخال',
  'SM.FEDERATION.FEDERATIONAPP.ACCOUNT': 'الحساب',
  'SM.ORGSTAFF.BUTTON.OURELATIONSHIP_RELOAD': 'تحديث',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SVD': 'الفاصل الزمني لمهلة جلسة العمل',
  'SM.COMMON.MESSAGE.SAVESUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.COMMON.LABEL.HOLIDAY_SUNDAY': 'الشمس',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_NAME': 'اسم الدور العام',
  'SM.AUTH.TITLE.ROLE_PERMISSION_TIPS':
    'لإجراء عمليات دفعية على شجرة الأذونات، استخدم القوائم المختصرة على عقد الأذونات.',
  'SM.ORGSTAFF.MESSAGE.STAFF_FORBID_CONFIRM':
    'بعد تعطيل الموظف بشكل دائم، لا يمكن إنشاء حساب الموظف إلا بشكل متكرر بعد حذفه. هل أنت متأكد من رغبتك في تعطيل الموظف بشكل دائم؟',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME': 'اسم الدور',
  'SM.ORGSTAFF.LABEL.ADDR_ADDERSS': 'العنوان',
  'SM.AUTH.LABEL.PERMISSION_INPUT': 'السلطة:',
  'SM.COMMON.LABEL.CURRENCY_TYPE_SYMBOL': 'رمز',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_RATE': 'معدل',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_12':
    'سيؤثر تعطيل هذه القاعدة على أمان كلمة المرور. هل أنت متأكد من أنك تريد تعطيله؟',
  'SM.ORGSTAFF.MESSAGE.CONTAINS_SUB_ORGS': 'تحتوي المنظمة على منظمات فرعية.',
  'SM.FEDERATION.FEDERATIONAPP.MAPPINGRULE': 'قاعدة تعيين المستخدم الموحدة',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_10':
    'تؤثر الأحرف الخاصة الصالحة على أمان كلمة المرور. هل أنت متأكد من أنك تريد استخدامها؟',
  'SM.COMMON.LABEL.SYSMENU_DIS_POSTION': 'عرض الموقف',
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE': 'دور المصدر',
  'SM.ORGSTAFF.LABEL.STAFF_LOCALE': 'اللغة الافتراضية',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_END': 'بيانات الشهر (الشهر هو 30 يومًا).',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_MEA': 'قياس',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGINTIMERANGE': 'مقطع وقت تسجيل الدخول',
  'SM.COMMON.LABEL.BANKCARD_LIST': 'قائمة البطاقات المصرفية',
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE_INPUT': 'دور المصدر:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF': 'علم التكوين الديناميكي',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_VIEWFILE': 'عرض',
  'SM.COMMON.LABEL.HOLIDAY_BASICINFORMATION': 'معلومات أساسية',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_FUNCTION': 'وظيفة',
  'SM.ORGSTAFF.LABEL.REGION_DST_SWITCHINTERVAL': 'إزاحة DST',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_ID': 'رمز الدور المشترك',
  'SM.COMMON.BUTTON.MENUVSEARCH': 'إعادة إنشاء مؤشر القائمة',
  'SM.ORGSTAFF.TITLE.ADDRESS_FORMAT': 'تنسيق العنوان',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FILE_FORMAT': 'تنسيق الملف المصدّر',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTNAME': 'اسم المرفق',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME': 'وقت الانتهاء',
  'SM.COMMON.BUTTON.ACCESS_RELATION': 'إضافة مؤسسة وصول',
  'SM.AUTH.LABEL.BIZ_OBJ_LIST': 'قائمة كائنات الأعمال',
  'SM.ORGSTAFF.TIPS.ENTER_ORGNAME': 'أدخل اسم مجموعة العمل.',
  'SM.ORGSTAFF.TITLE.OPPAUTH_TITLE_MUTEXAUTH_INFOLIST': 'قائمة الأذونات الحصرية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DELETE_FAILED': 'فشل حذف المستخدم.',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_COUNT': 'تصدير الكمية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PWDRULEGROUP': 'مجموعة قواعد الحساب وكلمة المرور',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE': 'تفعيل',
  'SM.COMMON.LABEL.HOLIDAY_IMPORTDATA': 'استيراد البيانات',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET': 'إعادة تعيين كلمة مرور الموظف',
  'SM.COMMON.LABEL.SYSMENU_VERSION': 'الإصدار',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEENAME': 'الموظف',
  'SM.ORGSTAFF.MESSAGE.DELETE_CONFIRM': 'هل تريد بالتأكيد الحذف؟',
  'SM.COMMON.TITLE.CONFIRM_DEL': 'تأكيد',
  'SM.ORGSTAFF.MESSAGE.STAFF_RESUME_CONFIRM': 'هل أنت متأكد من رغبتك في استئناف حساب الموظف؟',
  'SM.COMMON.LABEL.CREATE_OPER': 'تم الإنشاء بواسطة',
  'SM.FEDERATION.IMPORT.FAIL': 'فشل',
  'SM.TENANT.CREATETIME': 'وقت الإنشاء',
  'SM.ORGSTAFF.LABEL.BE_TYPE': 'نوع BE',
  'SM.AUTH.OPTION.ROLE_AUTH_ADD': 'إضافة',
  'SM.AUTH.TITLE.ROLE_NOT_HOLD': 'قائمة المعلومات غير القابلة للمنح',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_CREATE': 'تحويل قياس جديد',
  'SM.COMMON.LABEL.SURVEY_PROVINCE': 'المقاطعات',
  'SM.COMMON.LABEL.MEASUREMENT_UPLOAD_FILE': 'تحميل ملف',
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADINFO': 'حدد تنسيق المرفقات:',
  'SM.COMMON.TITLE.SYSMENU_MENUNAME': 'معلومات اسم القائمة',
  'SM.ORGSTAFF.LABEL.STAFF_SESSION_VALIDITY_PERIOD': 'فترة صلاحية الجلسة (دقيقة)',
  'SM.AUTH.MESSAGE.SELECTA_ROLE': 'حدد دورًا.',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME': 'اسم الإذن',
  'SM.ORGSTAFF.LABEL.ADDR_ADDRESS_NAME': 'اسم العنوان',
  'SM.AUTH.BUTTON.EXPORT_CURRENT_ROLE': 'تصدير العنصر المحدد',
  'SM.AUC.LABEL.LOGINLOG_OPER_TIME': 'وقت العملية',
  'SM.ORGSTAFF.LABEL.TEAM_ASSIGN': 'يمكن تعيين 2000 موظف كحد أقصى.',
  'SM.COMMON.BUTTON.CHARGECODE_SUCCESS': 'معلومات',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_TEST': 'هل تريد بالتأكيد تغييرها إلى حالة الاختبار؟',
  'SM.FEDERATION.COMMON.QUERY.CONDITION': 'معايير البحث',
  'SM.ORGSTAFF.LABEL.TEAM_ACCESS_EMPLOYEE': 'زيارة الموظفين',
  'SM.ORGSTAFF.LABEL.TEAM_CODE': 'رمز OU',
  'SM.ROLE.MESSAGE.DELETE_CONFIRM': 'بعد حذف الدور، يمكن إعادة إنشائه فقط. هل تريد الحذف؟',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_ORG': 'منظمة Roam',
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERY_AUTHTYPE': 'نوع الإذن',
  'SM.ORGSTAFF.TIPS.ENTER_OUNAME': 'أدخل اسم المنظمة.',
  'SM.COMMON.TIPS.SPECIAL_CHAR_AVAILABLE': 'يجب أن تكون قيمة الإدخال رقمية أو ألفا أو _.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST': 'قائمة المرفقات',
  'SM.AUTH.LABEL.ROLETMPL_HOME_ENTITY': 'منزل الإذن BE',
  'SM.FEDERATION.COMMON.REFRESH': 'تحديث',
  'SM.ORGSTAFF.TITLE.USER_ACCOUNT': 'حساب الموظف',
  'SM.COMMON.LABEL.SYSMENU_STATUS': 'الحالة',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_ALLOWED': 'السماح',
  'SM.ORGSTAFF.LABEL.RESTRICTTYPE': 'نوع تقييد تسجيل الدخول',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SUCCESS_MSG': 'تم حذف الأذونات غير المتوفرة خلال الوقت بنجاح.',
  'SM.FEDERATION.COMMON.DELETE.FAIL': 'فشل الحذف.',
  'SM.AUC.MESSAGE.OPERLOG_TIPS_LOGOUT': 'إجبار على الخروج',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE_DETAIL': 'الأدوار التي لا يمكن استخدام إذن الدالة',
  'SM.ORGSTAFF.TITLE.TEAM_DELETEFSUCCESS': 'تم الحذف بنجاح.',
  'SM.AUC.ACCOUNT.LOCK.SEARCH.ACCOUNT': 'البحث عن حساب',
  'SM.AUTH.LABEL.PERMISSION': 'إذن',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD': 'فرز الميدان',
  'SM.ORGSTAFF.BUTTON.CANCEL': 'إلغاء',
  'SM.ORGSTAFF.LABEL.ORG_DISPLAYORDER': 'تسلسل العرض',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_AUTHVIEW': 'عرض الأذونات',
  'SM.ORGSTAFF.LABEL.ORGIMPORT_TIP':
    'تأكد من استيراد معلومات شركة الاتصالات إلى النظام وأن المشغل الحالي هو المسؤول.',
  'SM.COMMON.BUTTON.MEASUREMENT_IMPORT': 'استيراد',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMTYPE': 'نوع الفريق',
  'SM.AUTH.LABEL.FUNCAUTH_ATTRIBUTE': 'توسيع السمة',
  'SM.AUTH.TITLE.INFO': 'معلومات',
  'SM.ORGSTAFF.OPTION.NO': 'لا',
  'SM.ORGSTAFF.MESSAGE.OPERATESUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNTRANGE': 'نطاق الحساب',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE': 'اسم الخدمة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TIMERANGE': 'النطاق الزمني',
  'SM.AUTH.MESSAGE.ROLETMPL_INFORMATION': 'التفاصيل',
  'SM.COMMON.LABEL.CHARGECODE_CODE': 'الكود',
  'SM.ORGSTAFF.OPTION.WEEKDAY_TUE': 'الثلاثاء',
  'SM.AUTH.LABEL.ROLETMPL_SEARCH_CRITERIA': 'معايير البحث',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP': 'استبيان',
  'SM.TENANT.VIEW': 'زيارة المستأجر',
  'SM.AUC.LABEL.LOGINSESSION_SESSION': 'حالة الجلسة',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_CONTBEADMIN_ERR':
    'لا يمكن استخدام حساب مسؤول النظام كحساب المصدر.',
  'SM.AUC.MESSAGE.RULEGROUPList': 'قائمة القاعدة',
  'SM.USERGROUP.DELETEG_EMPLOYEE.CONTENT':
    'بعد حذف موظف من مجموعة مستخدمين، يمكنك إضافة الموظف إلى مجموعة المستخدمين مرة أخرى إذا كنت تريد استعادة الموظف.',
  'SM.AUTH.MESSAGE.ROLETMPL_DELETE_SUCCESS': 'تم الحذف بنجاح.',
  'SM.COMMON.LABEL.CREATE_AREAINFO_MAINTAIN': 'معلومات المنطقة الجديدة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_GENDER': 'الجنس',
  'SM.AUTH.TITLE.ROLE_DISALLOWED_FUNCTION_PERMISSION': 'إذن الوظيفة المستبعدة',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID': 'الدور BE',
  'SM.COMMON.LABEL.OPERLOG_SESSION_ID': 'معرف الجلسة',
  'SM.ORGSTAFF.TITLE.CONTACT_FULL_NAME': 'الاسم الكامل',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_VST': 'نوع مهلة الجلسة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT': 'إعارة',
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE_INPUT': 'الدور المستهدف:',
  'SM.AUTH.LABEL.MODULE': 'الوحدة النمطية',
  'SM.AUTH.LABEL.ORGANIZATION': 'وحدة التنظيم',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE_INPUT': 'مصدر الإذن:',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_CODE': 'رمز الوحدة',
  'SM.MENU.DELETE.CUSTOM.ROLE.FAIL':
    'تم حذف القائمة بنجاح. فشل حذف الإذن المقترن بالقائمة. تحقق مما إذا كان الإذن مشارًا إليه بواسطة قوائم أو أدوار أخرى.',
  'SM.AUTH.LABEL.FUNCAUTH_DESC': 'الوصف',
  'SM.ORGSTAFF.BUTTON.ADD': 'جديد',
  'SM.ORGSTAFF.TITLE.SELECT_ROLE': 'تحديد الأدوار',
  'SM.ORGSTAFF.TITLE.QUERYFAIL_TITLE': 'فشل البحث.',
  'SM.AUC.LABEL.PWD_PWDRULE': 'قاعدة كلمة المرور',
  'SM.ORGSTAFF.LABEL.ORG_CHANNEL_CLASS': 'تصنيف القناة',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_SUCCESSFULLY': 'تم الحذف بنجاح.',
  'SM.AUTH.BUTTON.ROLE_SELECT_ALL': 'تحديد الكل',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_QUERY': 'البحث',
  'SM.COMMON.BUTTON.BANKCARD_SEARCH': 'البحث',
  'SM.AUTH.TITLE.ROLE_TMPL_ROLE_LIST': 'قائمة الأدوار',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA_INPUT': 'إذن البيانات:',
  'SM.ORGSTAFF.LABEL.BE_SHORT_NAME': 'BE الاسم المختصر',
  'SM.ORGSTAFF.LABEL.NO': 'لا',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEINHER': 'الشيئين حصريان ولا يسمح بالميراث.',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ID': 'معرف الدور',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID_INPUT': 'رمز قاموس البيانات:',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLOADTIME': 'وقت التحميل',
  'SM.AUTH.LABEL.ROLE_EXCLUDINGROLE': 'باستثناء الدور',
  'SM.ORGSTAFF.TITLE.TEAM_SAVESUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT': 'المشغل',
  'common.baseui.LABEL.HEADING_BASEINFO': 'معلومات أساسية',
  'SM.ORGSTAFF.MESSAGE.ENDEARLIERSTART': 'لا يمكن أن يكون تاريخ البدء بعد تاريخ الانتهاء.',
  'SM.ORGSTAFF.TITLE.CONTACT_MOBILE_PHONE': 'رقم الجوال',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE': 'رمز الشحن',
  'SM.ORGSTAFF.TITLE.BE_INFORMATION': 'معلومات',
  'SM.AUTH.MESSAGE.ROLETMPL_CAN_NOT_DELETE': 'فشل حذف العقدة لأن العقدة تحتوي على عقد فرعية.',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE_DETAIL': 'الأدوار التي يمكنها استخدام إذن الدالة',
  'SM.AUTH.LABEL.USER_ACCOUNTA_INPUT': 'الحساب (A):',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT': 'اختر الموظف.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE_INPUT': 'رمز الإذن:',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_NAME': 'اسم كائن الإذن',
  'SM.COMMON.LABEL.CHARGECODE_FORBIDEN_BE': 'ممنوع BE',
  'SM.ORGSTAFF.LABEL.ORG_TYPE': 'نوع OU',
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_BE': 'BE',
  'SM.COMMON.LABEL.AREALIST': 'قائمة المناطق',
  'SM.AUTH.MESSAGE.FUNCAUTH_FUNCTION': 'وظيفة',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_SUCCESS': 'تم الحذف بنجاح.',
  'SM.ORGSTAFF.LABEL.ORG_ORGID': 'معرف OU',
  'SM.EMPLOYEE.PLATFORM.CONFIG': 'تهيئة النظام الأساسي',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_DATA': 'إذن البيانات',
  'SM.ROLE.SELECT.BATCH': 'الرجاء تحديد دور',
  'SM.FEDERATION.IMPORT.STARTTIME': 'وقت البدء',
  'SM.ORGSTAFF.LABEL.TEAM_MANAGER': 'مدير',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_PARENT_ROLETYPE': 'الدور العام',
  'SM.ORGSTAFF.TITLE.SELECT_ORG': 'تحديد مجموعة العمل',
  'SM.COMMON.LABEL.SYSMENU_MENUID': 'معرف القائمة',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORD': 'كلمة مرور جديدة',
  'SM.ORGSTAFF.LABEL.STAFF_ADD_ROLE': 'إضافة دور العلاقة',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTTIME': 'وقت بدء التوقيت الصيفي',
  'SM.ORGSTAFF.TITLE.BE_DEFAULT': 'العملة الافتراضية',
  'SM.COMMON.BUTTON.BANKCARD_SAVE': 'حفظ',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD': 'اسم معرف الحقل',
  'SM.ORGSTAFF.MESSAGE.BE_SELECTREGION': 'اختر المنطقة',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE': 'معرف الإذن',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_FAILD': 'فشل إعادة تعيين كلمة مرور الموظف',
  'SM.COMMON.TITLE.ACCESS.SELECT_ALL_STAFF': 'اختيار جميع موظفي المنظمة',
  'SM.COMMON.MESSAGE.DELETE_CONFIRM': 'هل تريد بالتأكيد الحذف؟',
  'SM.AUC.LABEL.LOGINSESSION_LOCK': 'قفل',
  'SM.COMMON.LABEL.CHARGECODE_DATAGIRD_CATALOG': 'كتالوج',
  'SM.AUTH.TIPS.ROLETMPL_VIEW_DETAIL_PERMISSION':
    'عرض تفاصيل حول الأذونات التي لا يمكنك نسخها في قالب الدور.',
  'SM.FEDERATION.MAPPINGRULE.LOCAL': 'معلومات المستخدم المحلي',
  'SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER': 'تعيين مدير',
  'SM.AUTH.BUTTON.USERAUTHVIEW_SEARCH': 'البحث',
  'SM.COMMON.LABEL.CHARGECODE_TEST': 'اختبار',
  'SM.COMMON.LABEL.HOLIDAY_FRIDAY': 'الجمعة',
  'SM.AUTH.LABEL.ROLE_NAME_INPUT': 'اسم الدور:',
  'SM.AUTH.LABEL.MENU_AUTHID': 'معرف الإذن',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MATCH_VALUE': 'قيمة المطابقة',
  'SM.ORGSTAFF.LABEL.AUTHVIEW_AUTH_BENAME': 'منزل الإذن BE',
  'SM.ORGSTAFF.TITLE.CONTACT_ID': 'معرف جهة الاتصال',
  'SM.COMMON.LABEL.CURRENCY_TYPE_NAME': 'الاسم',
  'SM.AUTH.BUTTON.CLEAR_ALL_TIPS': 'مسح الإذن غير المتاح',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS': 'الحالة',
  'SM.ORGSTAFF.MESSAGE.SEARCH_ROLE_NAME': 'أدخل اسم دور',
  'SM.ORGSTAFF.TITLE.ASSIGNED_MANAGER': 'المديرون المعينون',
  'SM.ORGSTAFF.LABEL.REGION_DST_MODE': 'وضع DST',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR':
    'يمكن أن تحتوي القيمة فقط على أرقام وحروف وأحرف صينية وشرطة سفلية (_), واصلة (-) ونقاط (.), وعلامات (@).',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAMINFO': 'معلومات الفريق',
  'SM.AUTH.LABEL.FUNCAUTH_LOADMORE': 'تحميل المزيد',
  'SM.COMMON.LABEL.HIGHRISKLOG_SERVICENAME': 'اسم الخدمة',
  'SM.COMMON.LABEL.SYSMENU_GRANT_AUTH': 'إدارة الإذن',
  'SM.COMMON.TIPS.POPUP_LABEL_DELETE': 'هل تريد بالتأكيد الحذف؟',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_STANDARD': 'معيار وحدة العلم',
  'SM.ORGSTAFF.TITLE.NEW_MANAGER': 'إضافة مدراء',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF_INPUT': 'اسم الإذن:',
  'SM.EMPLOYEE.CONFIG.EMPLOYEE': 'تكوين الموظفين',
  'SM.ORGSTAFF.LABEL.ORG_SELECTORG': 'اختر OU',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_ACCOUNT': 'الحساب',
  'SM.ORGSTAFF.LABEL.REGION_CONFIG_TIMEZONE':
    'المنطقة التي تفتقر إلى معلومات المنطقة الزمنية، يرجى تكوين المنطقة الزمنية للمنطقة أو المنطقة الأصلية.',
  'SM.COMMON.LABEL.SYSMENU_CREATEDATE': 'تاريخ الإنشاء',
  'SM.COMMON.LABEL.SURVEY_INTERLOCUTION': 'سؤال وجواب',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS': 'النجاح',
  'SM.COMMON.TITLE.MEASUREMENT_STDLIST': 'قائمة نظام القياس',
  'SM.ORGSTAFF.LABEL.CHANNEL_NAME': 'اسم القناة',
  'SM.COMMON.LABEL.CHARGECODE_STATUS': 'الحالة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TITLE': 'العنوان',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID': 'معرف الدور',
  'SM.AUTH.LABEL.ROLE_SUPERCODE_INPUT': 'رمز دور الوالدين:',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_ACCOUNT': 'الحساب',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_DRAFT': 'هل تريد بالتأكيد تغييرها إلى الحالة مسودة؟',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_SUSPEND':
    'بعد تعليقها ، لن تكون العلاقة التنظيمية وعلاقة التبعية متاحة ، هل تؤكد على تعليقها؟',
  'SM.COMMON.BUTTON.SURVEY_NEW_ANSWER_OPTIONS': 'خيار إجابة جديد',
  'SM.ORGSTAFF.LABEL.TEAM_TDISPLAYORDER': 'تسلسل العرض',
  'SM.ORGSTAFF.LABEL.ORG_SUBTYPE': 'نوع فرعي OU',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_DELETE_ROLE': 'حذف دور',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTHPLACE': 'مكان الميلاد',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_ID': 'معرف إذن الوظيفة',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_ID': 'معرّف الدور العام',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST': 'معرف قائمة نوع العملية',
  'SM.ORGSTAFF.LABEL.ATTR_ATTRSETTING': 'السمات الموسعة',
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_FAX': 'فاكس المنزل',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE': 'الامتياز الميداني',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_ADDR': 'العنوان',
  'SM.COMMON.LABEL.CHARGECODE_OPERATION': 'العملية',
  'SM.AUTH.LABEL.ROLE_NAME': 'اسم الدور',
  'SM.COMMON.TIPS.SPECIAL_CHAR_CANNOT_START_NUMBER': 'لا يسمح للبدء بالرقم.',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_FORMAT': 'تنسيق العرض',
  'SM.COMMON.MESSAGE.OPERATESUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.AUTH.TITLE.USERAUTHVIEW_USERHAVAEROLES': 'دور المستخدم',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_DETAIL': 'التفاصيل',
  'SM.AUTH.LABEL.DETAIL': 'التفاصيل',
  'SM.ORGSTAFF.MESSAGE.DSTOFFSET.OUTRANAGE': 'إزاحة التوقيت الصيفي ليست في النطاق من 1 إلى 86400.',
  'SM.ORGSTAFF.LABEL.ORG_STOP_TIPS':
    'وبعد تعليقها، لن تكون المنظمة أو المنظمة التابعة لها متاحة. هل أنت متأكد من تعليق؟',
  'SM.AUTH.MESSAGE.DATAPER_NO_PERM': 'ليس لديك إذن العملية.',
  'SM.ORGSTAFF.TITLE.REGION_EX_ATTR': 'السمات الموسعة',
  'SM.COMMON.LABEL.ERATE_EFFDATE': 'الوقت الفعال',
  'SM.ORGSTAFF.MESSAGE.BE_COPYCURRENCY': 'هل تريد بالتأكيد نسخ العقدة الأصلية؟',
  'SM.ORGSTAFF.MESSAGE.ADDR_USE_DELETE': 'فشل الحذف لأنه تمت الإشارة إلى العنوان.',
  'SM.ORGSTAFF.MESSAGE.USERRESTRICT_CONFIRM_SAVE':
    'يمكن لمقطع وقت تسجيل الدخول الاحتفاظ بسجل واحد فقط، وسيتم حذف مقطع وقت تسجيل الدخول الآخر عند إضافته.',
  'SM.AUTH.MESSAGE.ROLE_INHERITANCE_RELATIONSHIP_EXISTS': 'الميراث موجود بالفعل.',
  'SM.COMMON.LABEL.PROVINCE': 'مقاطعة',
  'SM.ORGSTAFF.MESSAGE.MAX_TREE_LEVEL': 'يمكنك فقط إنشاء ما يصل إلى 6 مستويات من المنظمات الفرعية.',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT_DESCRIPTION': 'نطاق الإدخال هو 1-100',
  'SM.ORGSTAFF.TITLE.ADDR_NAME': 'الاسم',
  'SM.COMMON.LABEL.SURVEY_DESC': 'الوصف',
  'SM.AUTH.TITLE.SELECT_SHORT_CUT': 'تحديد قائمة الاختصارات',
  'SM.AUTH.LABEL.OPERATION_TYPE_INPUT': 'نوع العملية:',
  'SM.COMMON.BUTTON.DELETE': 'حذف',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCKBYHAND': 'فتح يدويا',
  'SM.COMMON.LABEL.BANKCARD_NAME': 'اسم البطاقة',
  'SM.COMMON.LABEL.OPERLOG_ACCOUNT': 'الحساب',
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE_PARAM': 'نوع البنك',
  'SM.COMMON.LABEL.SYSMENU_AFFECTED_MENU': 'القائمة المتأثرة',
  'SM.AUTH.LABEL.ROLE': 'الدور',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_DISALLOWED': 'رفض',
  'SM.ORGSTAFF.AUTHTYPE.LABEL': 'نوع المصادقة',
  'SM.ORGSTAFF.TITLE.CONTACT_LAST_NAME': 'الاسم الأخير',
  'SM.COMMON.LABEL.SYSMENU_LANGUAGE': 'اللغة',
  'SM.AUTH.TITLE.ROLE_ASSIGNMENT_TIME': 'وقت التعيين',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP_ADDR': 'عنوان IP',
  'SM.COMMON.LABEL.MEASUREMENT_IGNORE_IMPORT':
    'أثناء استيراد ملف دفعي، إذا فشل استيراد ملف، فإن استيراد الملفات التالية سيستمر.',
  'SM.COMMON.MESSAGE.BANKCARD_DELETE_CONFIRM': 'هل تريد بالتأكيد الحذف؟',
  'SM.AUTH.LABEL.BIZ_OBJ_NAME': 'الاسم',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_ID': 'معرف كائن الإذن',
  'SM.ORGSTAFF.TITLE.ADDRESS_DISPLAY_FORMAT': 'تنسيق عرض العنوان',
  'SM.FEDERATION.COMMON.OPERATION.EDIT': 'تحرير',
  'SM.AUTH.LABEL.ROLE_SCOPE': 'نطاق الدور',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_ERROR': 'خطأ في الحذف.',
  'SM.ORGSTAFF.LABEL.TEAM_BUSINESSENTITY': 'كن اسم',
  'SM.ORGSTAFF.LABEL.STAFF_ACCOUNT': 'الحساب',
  'SM.ORGSTAFF.LABEL.BE_CODE': 'BE كود',
  'SM.AUTH.LABEL.ROLE_SUPERID': 'معرف الدور الرئيسي',
  'SM.ORGSTAFF.OPTION.WEEKDAY_THU': 'الخميس',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID': 'ممنوع',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK2': 'سيتم أيضًا تحديد الأدوار:',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK1':
    'إذا قمت بحذف الإذن، ومعلومات التفويض للموظفين ذوي الصلة وما يلي',
  'SM.COMMON.LABEL.SEARCHCONDITION': 'معايير البحث',
  'SM.AUTH.MESSAGE.FUNCAUTH_DISABLE': 'غير متوفر',
  'SM.COMMON.TIPS.CURRENCY_GROUPSIZE_TIPS':
    'عدد الأرقام المفصولة بواسطة فواصل في مبلغ. تشير القيمة 0 إلى أن المبلغ غير منفصل بفواصل.',
  'SM.COMMON.MESSAGE.SYSMENU_DELSUCCESS': 'تم الحذف بنجاح.',
  'SM.AUC.LABEL.LOGINLOG_OPER_CONTENT': 'محتوى التشغيل',
  'SM.AUTH.MESSAGE.FUNCAUTH_READONLY': 'غير قابل للتعيين',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_CLIENT': 'عنوان IP للعميل',
  'common.baseui.MESSAGE.VALIDATE_MAXLENGTHB': 'يُسمح بحد أقصى 0 حرفًا.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_BE': 'BE',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_TYPE': 'نوع الدور',
  'SM.ORG.DISABLE.FAILED.EMPLOYEE': 'قم بحذف كافة الموظفين في المؤسسة، ثم قم بتعطيل المؤسسة.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_DELETE_ROLE': 'حذف الأدوار في دفعات',
  'SM.AUC.LABEL.LOGINSESSION_LOCKBYHAND': 'قفل يدويا',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID': 'معرف الإذن',
  'SM.AUTH.TITLE.SUCCESS': 'معلومات',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE_INPUT': 'نوع مصدر قيمة البيانات:',
  'SM.ORGSTAFF.LABEL.TEAM_REMOVE': 'إزالة',
  'SM.COMMON.LABEL.OU_HIERARCHY_NAME': 'اسم OU RelationShip',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_POSTFIX': 'عرض Postfix',
  'SM.AUTH.MESSAGE.AUTH_DATA_OBJECT_TREE': 'شجرة كائن الإذن',
  'SM.AUTH.LABEL.ATTR_LANGUAGE': 'اللغة',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT_INPUT': 'الحساب:',
  'SM.AUTH.MESSAGE.DELETE_CONFIRM': 'هل تريد بالتأكيد الحذف؟',
  'SM.AUTH.LABEL.AUTH_CONF_DATA': 'إعدادات أذونات البيانات',
  'SM.ORGSTAFF.OPTION.WEEKLIST_TID': 'الثالث',
  'SM.ORGSTAFF.OPTION.MONTH_9': 'أيلول/سبتمبر',
  'SM.ORGSTAFF.OPTION.MONTH_8': 'أغسطس',
  'SM.ORGSTAFF.OPTION.MONTH_7': 'يوليو',
  'SM.ORGSTAFF.OPTION.MONTH_6': 'يونيو',
  'SM.ORGSTAFF.OPTION.MONTH_5': 'أيار/مايو',
  'common.bremui.label.description': 'الوصف',
  'SM.ORGSTAFF.OPTION.MONTH_4': 'نيسان/أبريل',
  'SM.ORGSTAFF.OPTION.MONTH_3': 'مارس',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME_INPUT': 'وقت الانتهاء:',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDTIME': 'وقت انتهاء DST',
  'SM.ORGSTAFF.OPTION.MONTH_2': 'فبراير',
  'SM.ORGSTAFF.OPTION.MONTH_1': 'يناير',
  'SM.COMMON.BUTTON.TOP': 'إلى الأعلى',
  'SM.AUC.MESSAGE.PWDR_MANAGE': 'قواعد الإدارة',
  'SM.AUC.MESSAGE.RULEGROUPListEDIT': ' تحرير مجموعة قواعد',
  'SM.AUC.MESSAGE.RULEGROUPListADD': 'إضافة مجموعة قواعد',
  'SM.COMMON.MESSAGE.CHARGECODE_OPERATION_SUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.AUC.LABEL.LOGINSESSION_LOCK_EMPLOYEE': 'مغلق بواسطة',
  'SM.COMMON.MESSAGE.ERATE_NUMBER_INVALID': 'رقم غير صالح',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH': 'إذن الوظيفة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MATCH_MODE': 'وضع المطابقة',
  'SM.AUC.LABEL.BANK_NAME_INPUT': 'إدخال اسم البنك',
  'SM.ORGSTAFF.LABEL.PARENT_BE': 'الوالد BE',
  'SM.ORGSTAFF.TITLE.CURRENCY_DISPLAY_FORMAT': 'تنسيق عرض العملة',
  'SM.AUTH.MESSAGE.ROLETMPL_ROLETMPL_EXISTS': 'اسم قالب الدور موجود بالفعل.',
  'SM.ORGSTAFF.MESSAGE.INPUT_ORG_NAME': 'أدخل اسم OU',
  'SM.ORGSTAFF.OPTION.MONTH_12': 'كانون الأول/ديسمبر',
  'SM.ORGSTAFF.BUTTON.REMOVE_OUT': 'إزالة خارج',
  'SM.ORGSTAFF.OPTION.MONTH_11': 'نوفمبر',
  'SM.COMMON.LABEL.CURRENCY_GROUPSYMBOL': 'فاصل',
  'SM.ORGSTAFF.OPTION.MONTH_10': 'أكتوبر',
  'SM.AUTH.LABEL.ROLE_GRANTED_AUTH_TYPE': 'النوع الممنوح',
  'SM.FEDERATION.IMPORT.IPLODATIP':
    'جاري استيراد البيانات... عرض التفاصيل في منطقة نتيجة استيراد المستخدم الموحد.',
  'SM.ORGSTAFF.MESSAGE.RECOVERORG': 'استئناف OU.',
  'SM.COMMON.BUTTON.VIEW': 'عرض',
  'SM.AUTH.LABEL.CLEAR_POPUP_TITLE': 'حذف البيانات منتهية الصلاحية',
  'SM.AUTH.TIPS.ENTER_AUTHNAME': 'أدخل اسم الإذن',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER': 'تصفية حسب BE',
  'SM.AUTH.TITLE.TO_ASSIGNED_EMPLOYEE': 'لتعيين موظف',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_STATUS': 'الحالة',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEASYS': 'نظام قياس المصدر',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMCODE': 'رمز الفريق',
  'SM.ORGSTAFF.TITLE.OPERATION': 'العملية',
  'SM.COMMON.BUTTON.OK': 'حسناً',
  'SM.AUTH.BUTTON.USERAUTHVIEW_RESET': 'إعادة الضبط',
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_EXCHAGE_TIPS': 'الرجاء إدخال عدد صحيح.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_CREATEDATE': 'تاريخ الإنشاء',
  'SM.AUTH.BUTTON.EXPORT': 'تصدير',
  'SM.ORGSTAFF.LABEL.REGION_DAYLIGHTSAVINGTIME': 'DST',
  'SM.AUC.MESSAGE.LOGIN.IP': 'عنوان IP لتسجيل الدخول',
  'SM.COMMON.TITLE.SURVEY_QUESTION_NAME_INFO': 'معلومات اسم السؤال',
  'SM.AUTH.MESSAGE.OPERATE_WITHOUT_ROLES': 'تم إنشاء الإذن بنجاح، وتعيينه للمشغل الحالي.',
  'SM.AUTH.TITLE.ASSIGN_EMPLOYEE': 'تعيين موظف',
  'SM.ORGSTAFF.LABEL.SEARCH_ALL': 'البحث عن جميع الموظفين',
  'SM.ORGSTAFF.TITLE.CURRENCY_EXCHANGE_RATE': 'سعر صرف العملة',
  'SM.COMMON.MESSAGE.SYSMENU_CONFIRM_MODIFY_MENU_STATUS_TO': 'هل أنت متأكد من أنك تريد:',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDESCRIPTION': 'الوصف',
  'SM.ORGSTAFF.TITLE.CONTACT_EMPLOYEE_CODE': 'الاتصال بالموظف',
  'SM.COMMON.LABEL.ERATE_RANGEDATE': 'فترة الصلاحية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NATIONALITY': 'الجنسية',
  'SM.AUTH.TITLE.INPUT_PERMISSION_NAME': 'إدخال اسم إذن',
  'SM.FEDERATION.MAPPINGRULE.DESC': 'الوصف',
  'SM.AUTH.TIPS.ROLETMPL_WARNING': 'تحذير',
  'SM.ORGSTAFF.MESSAGE.BANK_STATUS_ERROR': 'فشل الحفظ بسبب انتهاء صلاحية معلومات البنك.',
  'SM.AUTH.TITLE.ROLE_HORTCUT_MENU': 'قائمة الاختصارات',
  'SM.COMMON.LABEL.SURVEY_SINGAL_CHOOSE': 'اختيار واحد',
  'SM.COMMON.LABEL.CHARGE_CODE_GROUP': 'مجموعة رمز الشحن',
  'SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM':
    'بعد حذف الموظف، لا يمكن استعادة الحساب المقابل للموظف ويمكن إعادة إنشائه فقط. حذف أم لا؟',
  'SM.AUTH.OPTION.ROLE_RETAIN_INFO_OF_TARGET_ROLE': 'الاحتفاظ بمعلومات دور الهدف',
  'SM.COMMON.LABEL.ERATE_EXCHANGE_RATE': 'سعر الصرف',
  'SM.ORGSTAFF.TITLE.ADDR_CFG_LIST': 'تنسيق عرض العنوان',
  'SM.AUTH.LABEL.ROLE_CONFIG_INFO': 'تكوين معلومات الدور',
  'SM.COMMOM.MESSAGE.BESEXCEPTION': 'لا يمكن إكمال العملية الحالية. اتصل بالمسؤول.',
  'SM.MENU.ADD.CUSTOM.PERM_REPEAT': 'اسم إذن مكرر',
  'SM.COMMON.TIPS.NEED_TO_NEW_STD_MIN_CURRENCY_UNIT':
    'تحتاج إلى تكوين وحدة قياسية من العملة وأصغر وحدة.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_NAME': 'اسم الدور',
  'SM.ORGSTAFF.TITLE.NEW_ORG': 'إنشاء OU',
  'SM.AUTH.LABEL.ROLE_ROLE_ID': 'معرف الدور',
  'SM.AUTH.TIPS.FUNCAUTH_YES': 'نعم',
  'SM.AUTH.TITLE.INPUT_ROLE_NAME': 'إدخال اسم الدور',
  'SM.COMMON.LABEL.NEW_CURRENCY_EXCHANGE_RATE': 'سعر الصرف الجديد',
  'SM.AUTH.LABEL.ROLE_PARENTS_TITLE': 'معلومات وراثة الدور',
  'SM.ORGSTAFF.TITLE.ATTR_ATTRSETTING': 'سمة موسعة',
  'SM.COMMON.LABEL.DATA_OPRATION': 'العملية',
  'SM.AUC.LABEL.VALUE': 'القيمة',
  'SM.AUC.MESSAGE.PWDR_VALIDATE': 'قاعدة التحقق',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPEXIST': 'عنوان IP مكرر.',
  'SM.ORGSTAFF.TIPS.MAC_FORMAT_ERROR': 'خطأ في تنسيق عنوان MAC',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST_INPUT': 'معرف قائمة نوع العملية:',
  'SM.COMMON.MESSAGE.MODIFY_AREAID_TYPE': 'المعلومات موجودة بالفعل. تغيير رمز أو نوع المنطقة.',
  'SM.ORGSTAFF.OPTION.STAFF_REMOVE_PERMISSION': 'إزالة إذن المشغل الهدف',
  'SM.AUTH.BUTTON.ROLE_DELETE_FOREVER': 'حذف بشكل دائم',
  'SM.COMMON.BUTTON.CHARGECODE_ADD': 'جديد',
  'SM.AUTH.BUTTON.CLOSE': 'إغلاق',
  'SM.COMMON.BUTTON.BANKCARD_CANCEL': 'إلغاء',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME_INPUT': 'اسم الدور:',
  'SM.COMMON.LABEL.ERATE_DESCRIPTION': 'الوصف',
  'SM.ORGSTAFF.TITLE.SUCCESS': 'معلومات',
  'SM.AUTH.MESSAGE.PERM_RECOVERY_SUCCESS': 'تم إبطال الإذن بنجاح.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_COPYPERMISSION': 'إذن نسخ',
  'SM.USERGROUP.DELETEG_EMPLOYEE.TITLE':
    'هل تريد حذف الموظفين المحددين من مجموعة المستخدمين الحالية؟',
  'SM.COMMON.BUTTON.ADD': 'جديد',
  'SM.COMMON.BUTTON.PROPERTY_CONFIRM': 'معلومات',
  'SM.AUTH.TITLE.ROLE_DELEVER_DATA_PERMISSION': 'منح إذن البيانات',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_DAUTH': 'إذن بيانات قابل للمنح',
  'SM.ORGSTAFF.LABEL.ORG_CODE': 'رمز OU',
  'SM.COMMON.TIPS.MENU_REBUILD': 'معلومات',
  'SM.AUTH.LABEL.MENU_AUTHID_INPUT': 'معرف الإذن:',
  'SM.AUC.LABEL.PWD_CUSTOMIZEDRULE': 'قاعدة مخصصة',
  'SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS': 'هل تريد بالتأكيد تعطيل المؤسسة؟',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICTIONTYPE': 'وضع المعالجة',
  'SM.COMMON.LABEL.AREANAME': 'اسم المنطقة',
  'SM.COMMON.TIPS.HOME_PHONE':
    'يرجى إدخال رقم الهاتف الصحيح، على سبيل المثال. (+)(0123-)1234567890(-0123)',
  'SM.ORGSTAFF.TITLE.CONTACT_TYPE': 'نوع الاتصال',
  'SM.ORGSTAFF.MESSAGE.ADDR_LEVELS_HIGH': 'العنوان ليس تسلسليًا.',
  'SM.COMMOM.LABEL.SURVEY_INFO': 'معلومات الاستبيان',
  'SM.ORGSTAFF.LABEL.REGION_NAME': 'اسم المنطقة',
  'SM.ORGSTAFF.LABEL.BE_DESCRIPTION': 'الوصف',
  'SM.COMMON.BUTTON.CHARGECODE_CANCEL': 'إلغاء',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_INPUT': 'يرجى إدخال اسم OU',
  'SM.ORGSTAFF.TIPS.DESC': 'عدم إدخال أحرف خاصة <>',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ORG': 'المنظمة المالكة',
  'SM.AUTH.LABEL.ROLE_OPERTYPE': 'نوع العملية',
  'SM.ORGSTAFF.TITLE.NEW_ACCESS_USER': 'إضافة موظفين معتمدين',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE': 'نوع الإذن',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_NAME': 'اسم الكائن',
  'SM.ORGSTAFF.OPTION.WEEKDAY_WED': 'الأربعاء',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_ADD': 'اختر OU',
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION': 'الوصف',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_TARGETNO': 'الحساب المستهدف',
  'SM.AUTH.TITLE.ROLE_OPPAUTHNAME': 'تصريح متبادل حصري',
  'SM.ORGSTAFF.BUTTON.ADDR_ADD': 'إضافة',
  'SM.FEDERATION.COMMON.SUCCESS': 'النجاح',
  'SM.AUTH.LABEL.DATA': 'إذن البيانات',
  'SM.FEDERATION.DOWNLOAD.RESULT': 'تنزيل نتيجة استيراد المستخدم الموحد',
  'SM.TENANTSPACE.LDAP_BASICDN': 'DN الأساسية',
  'SM.AUTH.MESSAGE.FUNCAUTH_INTERFACE': 'واجهة',
  'SM.COMMON.BUTTON.PROPERTY_CREATE_GROUP': 'مجموعات السمات الموسعة الجديدة',
  'SM.AUTH.LABEL.ROLETMPL_START_TIME': 'وقت البدء',
  'SM.COMMON.LABEL.MODULE_MODULELIST': 'قائمة الوحدات النمطية',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE': 'نوع العملية',
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNTRANGE': 'نطاق الحساب',
  'SM.ORGSTAFF.MESSAGE.BE_SELECT_DEFAULT_CURRENCY': 'حدد العملة الافتراضية.',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_SOURCENO': 'الحساب المصدر',
  'SM.AUTH.MESSAGE.CHANGE_SCOPE_SUGGESTION':
    'إذا غيرت العقدة الأصلية نطاق الدور، فيجب تعديل نطاق العقدة التابعة ليصبح نفس نطاق العقدة الأصلية.',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_SECRET': 'سر',
  'SM.ORGSTAFF.TIPS.LOGINID': 'عدم إدخال أحرف خاصة باستثناء @.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD41':
    'تأمين بسبب فشل تسجيل الدخول المتتالي خلال الفترة المحددة، بالدقائق',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD40': 'قاعدة مخصصة',
  'SM.COMMON.LABEL.BANKCARD_NAME_PARAM': 'اسم البطاقة',
  'SM.ORGSTAFF.LABEL.ORG_IMPORT': 'استيراد',
  'SM.COMMON.LABEL.ERATE_SCURRENCY_CODE': 'رمز العملة المصدر',
  'SM.AUTH.BUTTON.DELETE': 'حذف',
  'SM.ORGSTAFF.LABEL.TEAM_DESCRIPTION': 'الوصف',
  'SM.ORG.DISABLE.FAILED.PARENT': 'تعطيل جميع منظمات الأطفال في المنظمة قبل تعطيل المنظمة الحالية.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD39': 'يمكن أن تحتوي كلمة المرور على أرقام فقط',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD38':
    'الأحرف التي لا يمكن تضمينها في كلمة مرور',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD37':
    'لا يمكن أن تكون كلمة المرور مماثلة للحساب أو مجموعة فرعية من الحساب',
  'SM.ORGSTAFF.TITLE.REGION_USEPARENT': 'وراثة بيانات المنطقة المتفوقة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD36':
    'قائمة تنسيقات كلمات المرور المحظورة، مثل ABCABC وABCDEF',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD35': 'كلمة المرور الافتراضية',
  'SM.SERVLET.EMPLOYEE': 'الموظف',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD34':
    'الحد الأدنى لفترة صلاحية كلمة المرور، بالساعات',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_EQUALTOTARGET_ERROR':
    'لا يمكن تضمين الحساب المصدر في الحساب المستهدف.',
  'SM.AUTH.LABEL.ASSIGNEMPLOYEE': 'تعيين الموظف',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD33':
    'لا يمكن أن تكون كلمة المرور أحرفًا متتالية أو أرقامًا مثل AB و 123 و AA و abc',
  'SM.COMMON.LABEL.OPERLOG_EMPLOYEE_NAME_NORMAL': 'اسم الموظف',
  'SM.AUTH.MESSAGE.FUNCAUTH_LANGUAGE': 'اللغة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD32':
    'الحد الأدنى لعدد الأحرف من كل نوع التي يجب أن تكون موجودة في كلمة المرور',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD31': 'الحد الأدنى لطول الحساب',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD30': 'الحد الأقصى لطول الحساب',
  'SM.AUTH.TITLE.ROLE_PERMISSION_COMPARE': 'مقارنة أذونات الدور',
  'SM.COMMON.LABEL.COLON': ':',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_INPUT': 'الوصف:',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_EDIT': 'تعديل تحويل وحدة القياس',
  'SM.ORGSTAFF.OPTION.WEEKDAY_MON': 'الاثنين',
  'SM.AUTH.LABEL.OWNEDRIGHTS': 'بيرميسوس بروبيوس',
  'SM.AUTH.LABEL.FUNCAUTH_STATUS_INPUT': 'الحالة:',
  'SM.COMMON.LABEL.MEASUREMENT_RESULT_LIST': 'قائمة النتائج',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS': 'العلاقة المتبادلة الحصرية موجودة بالفعل.',
  'SM.ORGSTAFF.BUTTON.TEAM_OK': 'حسناً',
  'SM.AUTH.MESSAGE.OPERATEFAIL': 'فشل الحفظ.',
  'SM.COMMON.LABEL.HOLIDAY': 'عطلة',
  'SM.AUTH.TITLE.ORG_SELECTION': 'اختيار المنظمة',
  'SM.PROJECT.TITLE.EXPORT_SELECTED_EMPLOYEE': 'تصدير العنصر المحدد',
  'SM.COMMON.LABEL.MODULE_MODULE': 'الوحدة النمطية',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LOGINID': 'الحساب',
  'SM.AUTH.BUTTON.PERM_RECOVERY': 'إعادة التدوير',
  'SM.FEDERATION.FEDERATIONAPP.LIST': 'تطبيقات اتحادية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD19':
    'تغيير كلمة المرور القسرية عند تسجيل الدخول الأول أو بعد إعادة تعيين كلمة المرور',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD18':
    'عدد الأيام التي يتم إخطار المستخدمين قبل انتهاء صلاحية كلمة المرور',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEED_EXCLUDED': 'فشل حذف الدور لأنه يستبعد دورًا آخر.',
  'SM.TENANTSPACE.LDAP_RDNPREFIX': 'بادئة RDN',
  'SM.FEDERATION.IMPORT.UPLOADSELECT': 'حدد ملفًا.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD15':
    'الحد الأقصى لعدد مرات إعادة محاولة كلمة المرور',
  'SM.COMMON.LABEL.ADD_EXCHANGE_RATE': 'إضافة / تعديل سعر صرف العملات المرتبطة.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD14':
    'الحد الأقصى لفترة صلاحية كلمة المرور، بالأيام',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD13': 'لا يمكن أن تحتوي كلمة المرور على الاسم',
  'SM.ORGSTAFF.LABEL.ACCESS.USER_REQUIRED': 'يرجى اختيار موظف.',
  'SM.AUTH.LABEL.ROLE_EXCLUDEDROLE': 'الدور المستبعد',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD12':
    'يجب أن تكون كلمة المرور مختلفة عن الحساب أو الحساب بترتيب عكسي',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_12': 'كانون الأول/ديسمبر',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD11': 'كلمة مرور غير صالحة',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_11': 'نوفمبر',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TIME': 'وقت العملية',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD10': 'أحرف خاصة صالحة',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_10': 'أكتوبر',
  'SM.ORGSTAFF.LABEL.TEAM_REFRESH': 'تحديث',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE': 'قاعدة الوصول',
  'SM.AUTH.TITLE.USERAUTHVIEW_MENU_AUTHID': 'معرف الإذن',
  'SM.ORGSTAFF.TITLE.REGION_ADDRESS': 'العنوان',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_AUTH_TREE': 'تحديد وظيفة إذن',
  'SM.ROLE.PARENT.ROLE': 'دور المستوى الأعلى',
  'SM.COMMON.LABEL.SURVEY_DRAFT': 'مشروع',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS_MEW': 'النجاح',
  'SM.COMMON.LABEL.SELECTED_OUS': 'OUs المحددة',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_RESET': 'إعادة الضبط',
  'SM.ORGSTAFF.LABEL.ORG_IMPORTSUCCESS': 'تم استيراد البيانات بنجاح.',
  'SM.FEDERATION.FEDERATIONAPP.EMAIL': 'البريد الإلكتروني',
  'SM.COMMON.LABEL.PROPERTY_CREATE_PROPERTY': 'سمة جديدة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD09':
    'الحد الأقصى لعدد الأحرف المتتالية في كلمة المرور',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME': 'اسم البنك المصدر للبطاقة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD08':
    'يجب أن تكون كلمة المرور مختلفة عن كلمات المرور الأخيرة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD07': 'يجب أن تحتوي كلمة المرور على أحرف خاصة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD06': 'يجب أن تحتوي كلمة المرور على أحرف صغيرة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD05': 'يجب أن تحتوي كلمة المرور على أحرف كبيرة',
  'SM.AUTH.BUTTON.OK': 'حسناً',
  'SM.ORGSTAFF.TITLE.BE_CURRENCY': 'العملة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD04': 'يجب أن تحتوي كلمة المرور على أحرف',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD03': 'يجب أن تحتوي كلمة المرور على أرقام',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD02': 'الحد الأدنى لطول كلمة المرور',
  'SM.FEDERATION.COMMON.FAIL': 'خطأ',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD01': 'الحد الأقصى لطول كلمة المرور',
  'SM.ORGSTAFF.LABEL.ORG_SELECTREGION': 'اختر المنطقة',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHDAY': 'الشهر/اليوم',
  'SM.GADGET.VALUE.INTFPERM': 'أذونات API',
  'SM.ORGSTAFF.MESSAGE.STARTANDTEND_ISNULL':
    'لا يمكن أن يكون تاريخا البدء والانتهاء فارغين في نفس الوقت.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION': 'اسم العملية',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_TAIL': 'ذيل الاستبيان',
  'SM.COMMON.LABEL.CHARGECODE_DESC': 'الوصف',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_ID': 'معرف الهوية',
  'SM.COMMON.MESSAGE.SYSMENU_MENUNAMEREQUIRE': 'أدخل اسم القائمة.',
  'SM.ORGSTAFF.LABEL.DEPTIPRESTRICT': 'تقييد IP',
  'SM.ORGSTAFF.TITLE.CONTACT_TITLE': 'العنوان',
  'SM.COMMON.LABEL.CURRENCY_STANDARD_MEASUREMENT': 'معيار وحدة العلم',
  'SM.COMMON.LABEL.THE_MENU': '?',
  'SM.AUTH.LABEL.FUNCAUTH_ISDY': 'علم التكوين الديناميكي',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_EXCLUDED': 'فشل حذف الدور لأنه يستبعد دورًا آخر.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FIRSTNAME': 'الاسم الأول',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_METRIC': 'علم النظام المتري',
  'SM.COMMON.LABEL.MENU_ATTR_TITLE': 'توسيع السمة',
  'SM.ORGSTAFF.LABEL.INH_BY_SUB': 'السماح بالإرث عن طريق Sub-OU',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID2':
    'وهو أكبر من الحد الأقصى للعدد، الحد الأقصى للعدد هو:',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID1': 'عدد دور الموظف الواجب الخاص بالمنظمة:',
  'SM.AUTH.TITLE.INPUT_MENU_NAME': 'إدخال اسم قائمة',
  'SM.ORGSTAFF.AUTHTYPE.LDAP': 'LDAP',
  'SM.COMMON.BUTTON.CREATE': 'جديد',
  'SM.ORGSTAFF.MESSAGE.BE_SUBBE':
    'تم تكوين BEs أو OUs أو الفرق أو الأدوار ذات المستوى الأدنى لـ BE.',
  'SM.COMMON.LABEL.CHARGECODE_GROUPMEMBER': 'رموز الشحن',
  'SM.COMMON.TITLE.BANKCARD_ADD': 'بطاقة مصرفية جديدة',
  'SM.COMMON.LABEL.OPERLOG_OPER_LIST': 'قائمة سجل العملية',
  'SM.ORGSTAFF.TITLE.REGION_STARTIP': 'بدء IP',
  'SM.FEDERATION.IMPORT.ENDTIME': 'وقت الانتهاء',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME': 'اسم إذن الوظيفة',
  'SM.COMMON.LABEL.ACCESS_RELATION': 'المنظمات التي يمكن الوصول إليها',
  'SM.ORGSTAFF.MESSAGE.BLANK_VIEW': 'إدارة الإدارات في شجرة OU.',
  'common.bremui.title.operation': 'العملية',
  'SM.TENANT.TENANTNAME': 'TenantName',
  'SM.AUTH.TITLE.USERAUTHVIEW_OPERTYPE': 'نوع العملية',
  'SM.ORGSTAFF.LABEL.ONLINEOPERATORNUM': 'إجمالي المشغلين عبر الإنترنت',
  'SM.ORGSTAFF.LABEL.ORG_INFO': 'معلومات OU',
  'SM.TENANTSPACE.AUTHTYPE.TITLE': 'نوع المصادقة',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_PREFIX': 'عرض البادئة',
  'SM.ORGSTAFF.LABEL.BANK_CHILEBEUSED': 'السماح للطفل باستخدام BE',
  'SM.AUC.MESSAGE.LOGINSESSION_UNLOCKSTATUS': 'غير مقفل',
  'SM.ORGSTAFF.LABEL.ADDR_DISPLAY_ORDER': 'تسلسل عرض العنوان غير صحيح.',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE_ROLE_POLICY': 'سياسة الوصول إلى الدور',
  'SM.AUTH.TITLE.ROLE_GRANTABLE_DATA_PERMISSION': 'إذن بيانات قابل للمنح',
  'SM.ORGSTAFF.OPTION.ROLE_OPTION_MODE': 'وضع التشغيل',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CANCLE': 'إلغاء',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE_INPUT': 'اسم الخدمة:',
  'SM.ORGSTAFF.TITLE.CONTACT_EMAIL': 'البريد الإلكتروني',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_TYPE': 'نوع كائن الإذن',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_ACCESSORY': 'جديد',
  'SM.ORGSTAFF.MESSAGE.STAFF_INFOAUTH_CONFIRM':
    'يرجى التأكد من أن معلومات الموظف التي تم إدخالها قد تم تفويضها من قبلي.',
  'SM.EMPLOYEE.OPERATE.MAX.HUNDRED': 'يمكن تشغيل 100 موظف كحد أقصى في كل مرة.',
  'SM.ORGSTAFF.MESSAGE.PWD.CHECKMSG': 'كلمتا المرور مختلفتان.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF_INPUT': 'علم التكوين الديناميكي:',
  'SM.GADGET.VALUE.FUNCPERM': 'أذونات العملية',
  'SM.COMMON.TITLE.SUCCESS': 'معلومات',
  'SM.COMMON.BUTTON.CHARGECODE_CONFIRM': 'معلومات',
  'SM.ORGSTAFF.BUTTON.DELETE': 'حذف',
  'SM.FEDERATION.COMMON.QUERY': 'البحث',
  'SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD': 'يُرجى إدخال كلمة أساسية',
  'SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS':
    'الوحدة: الثانية. النطاق: 1 إلى 86400. في حالة ترك هذه المعلمة فارغة، يتم استخدام القيمة الافتراضية 3600.',
  'SM.COMMON.BUTTON.IMPORT': 'استيراد',
  'SM.AUTH.LABEL.ROLETMPL_FUNCTION_PERMISSION': 'إذن الوظيفة',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_SUCCESS': 'تم حذف ملف الملحقات بنجاح',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE_PERMISSION':
    'عندما لا يكون نطاق الاستخدام موظفًا، لا يمكنك تعيين أدوار للموظفين',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SUN': 'الأحد',
  'SM.ORGSTAFF.VALIDATOR.AFTER_TODAY': 'يجب أن يكون الوقت الذي تم إدخاله قبل أو يساوي 00:00 اليوم.',
  'SM.COMMON.LABEL.HOLIDAY_SATURDAY': 'السبت',
  'SM.AUTH.LABEL.ROLE_STAFF_LIST': 'قائمة الموظفين المعينين',
  'SM.COMMON.LABEL.ERATE_EXPDATE': 'وقت انتهاء الصلاحية',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_COMMON_ROLETYPE': 'الدور المشترك',
  'SM.EMPLOYEE.CHECKED-IN.PASSWORD': 'كلمة مرور تسجيل الدخول',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICT_MODE': 'وضع التقييد',
  'SM.ORGSTAFF.LABEL.ORG_STOP': 'تعليق',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_MODULE': 'الوحدة النمطية',
  'oms.operationparam.msg.unsafeinfo':
    'لاحظ أن بروتوكول الشبكة المستخدم بواسطة العنوان الحالي غير آمن. إذا قررت استخدامه ، فقد تكون هناك مخاطر أمنية.',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_ADD': 'إضافة موظف',
  'SM.ORGSTAFF.MESSAGE.TEAM_BLANK_VIEW': 'إدارة الفرق في شجرة OU.',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_NAME': 'اسم الدور العام',
  'SM.ORGSTAFF.MESSAGE.BE_DELETESUCCESS': 'تم الحذف بنجاح.',
  'SM.AUTH.OPTION.DISALLOWED_FUNCTION_PERM': 'إذن الوظيفة المستبعدة',
  'SM.ORGSTAFF.TIPS.UNLOCK.USER': 'أدخل كلمة مرور المشغل الحالي للتأكيد:',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHSOURCE': 'مصدر الإذن',
  'SM.COMMON.MESSAGE.SYSMENU_MAX_EDIT_LIMIT': 'الحد الأقصى للتحرير.',
  'SM.ORGSTAFF.LABEL.TEAM_ADD': 'جديد',
  'SM.AUTH.TITLE.ROLE_FUNCAUTH_FAIL':
    'فشل حفظ بعض أذونات الوظائف. هذه الأذونات حصرية أو لا يمكن منحها لك.',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_BE_FIRST': 'الرجاء اختيار BE أولاً',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN': 'إصدار بطاقة هوية مصرفية',
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADSIZE': 'ماكس. حجم المرفق (بايت):',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SINGLE': 'عنوان IP واحد',
  'SM.AUTH.LABEL.USER_ACCOUNTB_INPUT': 'الحساب (ب):',
  'SM.COMMON.LABEL.CURRENCY_TYPE': 'نوع العملة',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_ACCOUNT': 'تم التعديل بواسطة',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_SELECTORG': 'اختر OU',
  'SM.ORGSTAFF.TITLE.COMMON_CONTACT_INFORMATION': 'معلومات الاتصال',
  'SM.ORGSTAFF.TITLE.STAFF_LOGIN_RESTRICT': 'تقييد تسجيل الدخول',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF': 'تكوين شجرة إضافي',
  'SM.COMMOM.BUTTON.IMPORT': 'استيراد',
  'SM.ORGSTAFF.BUTTON.TEAM_CANCEL': 'إلغاء',
  'SM.FEDERATION.FEDERATIONAPP.VALDATEJSON': 'يجب أن يكون عنصر التكوين بتنسيق JSON.',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_PATTERN': 'وضع النسخ',
  'SM.COMMON.LABEL.ERATE_SEARCH_CRITERIA': 'معايير البحث',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC': 'الوصف',
  'SM.TENANT.ADMINACCOUNT': 'AdminAccount',
  'SM.COMMON.LABEL.OPERLOG_FAILUER': 'فشل',
  'SM.COMMON.TIPS.SPECIAL_CHAR_FORBIDDEN': 'لا يمكن أن تحتوي قيمة الإدخال على أحرف خاصة.',
  'SM.AUTH.LABEL.BIZ_OBJ_SCOPE': 'النطاق',
  'SM.COMMON.LABEL.CURRENCY_FORMAT_TITLE': 'تنسيق عرض العملة',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ASSOCIATION_LOG': 'سجل جمعية الدور',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_OPP_FUNCTION': 'إذن الوظيفة المستبعدة',
  'SM.TENANT.CREATE': 'إضافة مستأجر',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARAMNULL': 'معلمة الطلب فارغة.',
  'SM.ORGSTAFF.LABEL.ORG_SHORT_NAME': 'OU اسم مختصر',
  'SM.ORGSTAFF.MESSAGE.EMPLOYEE_SECONDMENT_CONFIRM': 'هل تريد بالتأكيد الإنهاء؟',
  'SM.COMMON.LABEL.OPERLOG_RESULT': 'نتيجة العملية',
  'SM.ORGSTAFF.MESSAGE.ORG_PARENT_FORBID': 'تم تعليق OU الأصل.',
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAILTIME': 'أوقات فشل مصادقة كلمة المرور',
  'SM.COMMON.LABEL.BANKCARD_OPERATION': 'العملية',
  'SM.ORGSTAFF.LABEL.BE_LANGUAGE': 'اللغة',
  'SM.AUTH.LABEL.ROLETMPL_OPERATOR': 'المشغل',
  'SM.EMPLOYEE.INSPECTION.GROUP': 'الصفحة الرئيسية مجموعة QC',
  'SM.AUC.LABEL.LOGINSESSION_SURE_LOCK': 'هل تريد بالتأكيد قفل المستخدم؟',
  'SM.AUTH.LABEL.EMPLOYEEINCLUDED': 'تضمين الموظفين الفرعيين',
  'SM.COMMON.LABEL.CURRENCY_TYPE_FLAG': 'الظاهري',
  'SM.ORGSTAFF.LABEL.ORG_RECOVER': 'استئناف',
  'SM.AUTH.TITLE.CONFIRM_PERM_RECOVERY': 'تأكيد',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTNAME': 'اسم المرفق',
  'SM.ORGSTAFF.TITLE.CONFIRM_DEL': 'تأكيد',
  'SM.AUTH.TITLE.USER_PERMISSION_COMPARE': 'مقارنة إذن الموظفين',
  'SM.ORGSTAFF.LABEL.TEAM_MANAGERINFO': 'معلومات المدير',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_DISPLAYFORMAT': 'قالب تنسيق عرض العنوان',
  'SM.ORGSTAFF.MESSAGE.ERROR_QUERY': 'فشل الاستعلام.',
  'SM.AUTH.LABEL.ROLEPERMISSION_LOGL_IST': 'قائمة سجل أذونات الدور',
  'SM.AUTH.LABEL.ROLE_TYPE': 'نوع الدور',
  'SM.COMMON.LABEL.SYSMENU_IDEL_I': 'الإفراج',
  'SM.ORGSTAFF.TITLE.TEAM_DELETE_CONFIRM': 'تأكيد',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_AND_SUB': 'تصدير العنصر المحدد',
  'SM.AUTH.MESSAGE.FUNCAUTH_MENU': 'القائمة',
  'SM.ORGSTAFF.LABEL.BE_BIZCODE': 'BE رمز الأعمال',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_NAME_LANG': 'لغة اسم الاستبيان',
  'SM.COMMON.LABEL.SURVEY_TITLE_NAME': 'اسم السؤال',
  'SM.COMMON.LABEL.SURVEY_SECTION': 'إقليمي',
  'SM.COMMON.TIPS.ERATE_ERATE_TIPS':
    'أدخل سلسلة رقمية موجبة بحد أقصى 13 خانة متكاملة و 6 خانات عشرية ولا يمكن أن يكون الأخير 0.',
  'SM.ORGSTAFF.BUTTON.ORG_EDIT': 'تعديل',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_BENAME': 'منزل الدور المشترك BE',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEACCESSLIMIT':
    'يمكن للموظفين الذين يمكن الوصول إليهم إضافة ما يصل إلى 100 لكل دفعة',
  'SM.COMMON.LABEL.BANKCARD_TYPE': 'نوع البطاقة',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME_PARAM': 'اسم البنك المصدر للبطاقة',
  'SM.AUTH.TITLE.USERAUTHVIEW_NAME': 'الموظف',
  'SM.COMMON.LABEL.HIGHRISKLOG_OPERATIONNAME': 'اسم العملية',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTCODE': 'نوع المرفق',
  'SM.ORGSTAFF.LABEL.ORG_DELETE_CONFIRM':
    'بعد حذف مؤسسة، لا يمكن استعادة الحساب المقابل للمؤسسة ويمكن إعادة إنشائه فقط. حذف أم لا؟',
  'SM.ORGSTAFF.MESSAGE.FOLLOW_TENANT': 'اتبع تكوين المستأجر',
  'SM.FEDERATION.FEDERATIONAPP.VALDATEHTTP': 'تنسيق URL غير صالح',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCK': 'فتح القفل',
  'SM.COMMON.MESSAGE.CHARGECODE_PERFORM_DELETION': 'هل تريد بالتأكيد الحذف؟',
  'SM.COMMOM.MESSAGE.SYSMENU_MENU_STATUS_NOT_CONSISTENT': 'حالة القائمة غير متناسقة.',
  'SM.COMMON.LABEL.SYSMENU_SELECT_MENUS': 'القائمة المحددة',
  'SM.ORGSTAFF.LABEL.ADDR_DISP_DETAIL_LIST': 'تنسيق قائمة التفاصيل',
  'SM.COMMON.TITLE.MEASUREMENT_UNITLIST': 'قائمة وحدة القياس',
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION_TYPE': 'نوع السؤال الجديد',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_USERVIEW': 'عرض الموظف',
  'SM.ORGSTAFF.LABEL.WORKGROUP_CONTENTS': 'محتويات مسار مجموعة العمل',
  'SM.FEDERATION.USER.TEMPLATE': 'تنزيل قالب استيراد المستخدم الموحد',
  'SM.AUTH.LABEL.LOGINLOG_SEARCH': 'البحث',
  'SM.AUTH.LABEL.THIRD_DATASOURCE_NAME': 'اسم مصدر البيانات',
  'SM.COMMON.LABEL.SURVEY_REMARKS': 'ملاحظات',
  'SM.COMMON.LABEL.SURVEY_CREATED_TIME': 'تم الإنشاء في',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP_LIST': 'قائمة الاستبيانات',
  'SM.AUTH.LABEL.ROLE_DERIVE_NORMALROLE': 'دور مشترك مشتق',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_BASIC': 'الدور',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPFORMATERROR': 'عنوان IP غير صالح',
  'SM.ORGSTAFF.LABEL.BUSINESSENTITY_DISPLAY_NO': 'تسلسل العرض',
  'SM.ORGSTAFF.LABEL.TEAM_SEARCH': 'البحث',
  'SM.AUTH.BUTTON.ROLETMPL_RESET': 'إعادة الضبط',
  'SM.COMMON.MESSAGE.MEASUREMENT_SAVE_CONFIRM':
    'عندما تقوم بحفظ وحدة قياس قياسية، فإنها تحل محل وحدة قياسية موجودة وتعدل الوحدات القياسية لوحدات القياس الأخرى.',
  'SM.COMMON.LABEL.SYSMENU_AUTHID': 'معرف إذن القائمة',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE': 'الإذن الممنوح للدور',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_CONFIRM': 'هل تريد بالتأكيد الحذف؟',
  'SM.COMMON.LABEL.CURRENCY_CREATE_TITLE': 'نوع العملة معلومات أساسية',
  'SM.COMMON.LABEL.MODULE_BASICINFO': 'معلومات أساسية',
  'SM.AUTH.MESSAGE.DATAPER_TREE_ROOT': 'شجرة أذونات البيانات',
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_PHONE': 'هاتف المنزل',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE': 'الإذن المتاح للدور',
  'SM.SM.COMMON.TITLE.GROUP_NAME_EXIST': 'تم تحديد اسم مجموعة المستخدمين.',
  'SM.COMMON.BUTTON.ADD_ADD': 'إضافة',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_ID': 'معرف الدور',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVE': 'خطأ',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TO': 'إلى',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYNAME': 'اسم العملة',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEAUNIT': 'وحدة قياس المصدر',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEE': 'الموظف',
  'SM.COMMON.LABEL.CURRENCY_TYPE_ID': 'معرف الهوية',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHWEEKDAY': 'الشهر/الأسبوع/اليوم',
  'SM.COMMON.BUTTON.SURVEY_DELETE_ANSWER_OPTIONS': 'حذف خيارات الإجابات',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION_INPUT': 'معايير تصفية البيانات:',
  'SM.FEDERATION.FEDERATIONAPP.PHONE': 'رقم الاتصال',
  'SM.AUTH.LABEL.PERMISSION_CLASS': 'تصنيف الأذونات',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_MENU': 'القائمة',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMSHORTNAME': 'اسم الفريق المختصر',
  'SM.ORGSTAFF.TITLE.REGION_ADMINREGION': 'المنطقة الإدارية',
  'SM.FEDERATION.IMPORT.IMPORTRESULT': 'نتيجة الاستيراد',
  'SM.AUTH.BUTTON.AUTH_EDIT': 'تعديل',
  'SM.ORGSTAFF.LABEL.ADDR_REGION_LAYER': 'مستوى المنطقة الإدارية',
  'SM.AUTH.NO.SELECT': 'لم يتم تحديد إذن.',
  'SM.ORGSTAFF.BUTTON.INCREASE': 'إضافة',
  'SM.AUTH.MESSAGE.OPERATE_SUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_RESULT': 'نتائج البحث',
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG_PARAM': 'طول رقم البطاقة',
  'SM.AUTH.TITLE.STAFFROLELOG_LIST': 'قائمة سجل دور الموظف',
  'SM.ORGSTAFF.MESSAGE.SAVESUCCESS': 'تم تنفيذ العملية بنجاح.',
  'SM.ORGSTAFF.MESSAGE.DSTFLAG_WITHOUT_TIMEZONE': 'لا يمكن لأي منطقة زمنية فتح التوقيت الصيفي.',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_DATA': 'هل تريد بالتأكيد حذف رمز الشحن؟',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT': 'الحساب',
  'SM.FEDERATION.IMPORT.STATUS': 'الحالة',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL': 'عادي',
  'SM.COMMON.TITLE.ACCESS.SELECT_ORG': 'تحديد مؤسسة Access',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_PATH': 'مسار',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPRANGEERROR': 'تم تكوين مقطع عنوان IP بشكل غير صحيح.',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF': 'اسم الإذن',
  'SM.ORGSTAFF.TITLE.REGION_TIMEZONE': 'المنطقة الزمنية',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FTH': 'رابعاً',
  'SM.FEDERATION.IMPORT.UPLOADFILE': 'ملف',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_ID': 'معرف الهوية',
  'SM.ORGSTAFF.OPTION.WEEKDAY_FRI': 'الجمعة',
  'SM.COMMON.LABEL.SURVEY_CLASS_NAME': 'اسم الفئة',
  'SM.AUC.ACCOUNT.LOCK.USER.NOT.EXIT': 'المستخدم غير موجود',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA': 'إذن البيانات',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_CREATE': 'استبيان جديد',
  'SM.LABEL.APPCUBE.SWITCH': 'صفحة AppCube-Integrated',
  'SM.LABEL.APPCUBE.APP': 'تحديد التطبيق',
  'SM.LABEL.APPCUBE.MENU': 'تحديد القائمة',
  'SM.ROLE.TEMPLATE': 'قالب الدور',
  'SM.EMPLOYEE.ROLE.EFFECT': 'ستصبح معلومات دور الموظف سارية المفعول في غضون [] دقيقة.',
  'SM.ROLE.AUTH.EFFECT': 'ستصبح معلومات الأذونات الخاصة بالدور سارية المفعول خلال [] دقيقة.',
  'SM.MENU.NAME.SPECIAL.CHAR': "!#{'$'}%&()￥+:\"{'{'}{'}'},\\/'[]=-^<>: لا يمكن إدخال أحرف خاصة",
  'SM.COMMON.MESSAGE.DUTY.FORBIDDEN': 'يحتوي الموظفون المحددون على موظفين تم تعطيلهم بالفعل.',
  'SM.LABEL.ACCOUNT': 'يُرجى إدخال حسابك',
  'SM.LABEL.USERNAME': 'الرجاء إدخال اسم مستخدم',
  'SM.LABEL.EMAIL': 'يُرجى إدخال عنوان البريد الإلكتروني',
  'SM.LABEL.PHONE': 'يُرجى إدخال رقم الاتصال',
  'SM.LABEL.PASSWORD': 'يُرجى إدخال كلمة المرور',
  'SM.LABEL.DESCRIPTION': 'الرجاء إدخال وصف',
  'SM.ORGSTAFF.BUTTON.SaveAndConfigure': 'حفظ وتكوين',
  'SM.ROLE.BUTTON.DELETE': 'هل أنت متأكد من رغبتك في حذف الموظف المحدد؟',
  'SM.ROLE.ADD': 'دور جديد',
  'SM.ROLE.ADD.NAME': 'الرجاء إدخال اسم الدور',
  'SM.ROLE.ADD.DECRI': 'يُرجى إدخال ملاحظة',
  'sumweb.export.confirm.exportall': 'لم تقم بتحديد أي سجلات. هل تريد تصدير كافة السجلات؟ يمكنك أيضاً نقر سجل معين لتصديره.',
  'sumweb.export.export.select': 'لقد حددت سجل {0}. هل أنت متأكد من رغبتك في المتابعة؟.',
  'sumweb.export.org': 'تصدير معلومات المؤسسة',
  'sumweb.export.employee': 'تصدير معلومات الموظف',
  'sumweb.view.import.list': 'قائمة الاستيراد',
  "sumweb.import.success": "تم إنشاء مهمة استيراد. هل تريد بالتأكيد عرض قائمة الاستيراد؟",
  "SM.ROLE.EDIT": "تحرير الدور",
  "SM.AUTH.BUTTON.CONFIRM.CLOSE": "تأكيد الإغلاق",
  "sumweb.agent.config": "تكوين المقاعد",
  "sumweb.employee.export.nodata": "لا توجد بيانات موظف تفي بالمتطلبات.",
  "sumweb.employee.view.config": "عرض التكوين"
}
