<template>
  <div>
    <sweet-dialog v-model="dialogVisible"
                  :title="$t('isales.work.title.selectAgent')"
                  :width="cookieUtils.getCookie('u-locale') === 'zh_CN' ? '896px' : '1248px'" :close-on-click-modal="false" class="aicc-dialog-limit-height">
      <div class="aicc-align--absolute-left" style="align-items: flex-start">
        <div class="skill-wrap">
          <div class="aicc-align--absolute-left aicc-m-bottom--small">
            <sweet-input v-model="skillName"
                         :placeholder="$t('isales.management.placeholder.skillName')"
                         @keydown.enter="searchPageFirst">
              <template #prefix>
                <sweet-icon class="input-icon" @click="searchPageFirst"
                            :title="$t('chat.session.label.search')">
                  <search />
                </sweet-icon>
              </template>
            </sweet-input>
          </div>
          <aicc-table ref="skillTable"
                      :tableData="skillList"
                      :tableColumns="skillTableColumns"
                      :tableTools="{ showPagination: true, columnConfig: false }"
                      :paginationConfig="skillPaginationConfig"
                      :height="430"
                      @radio-change="handleSkillChange"
                      @handlePagination="handleSkillPagination"></aicc-table>
        </div>
        <div class="agent-wrap">
          <sweet-radio-group v-model="isSpecifiedAgent" class="ml-4">
            <sweet-radio :label="false">{{ $t('isales.taskinfo.field.noSpecifiedAgent') }}</sweet-radio>
            <sweet-radio :label="true">{{ $t('isales.taskinfo.field.specifiedAgent') }}</sweet-radio>
          </sweet-radio-group>
          <aicc-table class="aicc-m-top--small"
                      ref="agentTable"
                      :tableData="agentList"
                      :tableColumns="agentTable"
                      :tableTools="{ showPagination: true, checkedInfo: false, columnConfig: false }"
                      :paginationConfig="agentPaginationConfig"
                      :row-key="getRowKey"
                      :height="430"
                      @handlePagination="handleAgentPagination">
            <template #agentType="scope">{{ $t(AGENT_TYPE[scope.row.agentType]) }}</template>
            <template #role="scope">
              <span v-if="cookieUtils.getCookie('u-locale')==='zh_CN'">{{ scope.row.ccRoleName }}</span>
              <span v-else>{{ scope.row.roleEnName }}</span>
            </template>
          </aicc-table>
        </div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
      </template>
    </sweet-dialog>
  </div>
</template>

<script>
import {
  querySkill,
  queryAgentAndSkill
} from '@/views/isales/api/manualCallTask'
import appConfig from '@/config'

const AGENT_TYPE = {
  '4': 'isales.taskinfo.field.audioAgentType',
  '11': 'isales.taskinfo.field.videoAgentType',
  '5': 'isales.taskinfo.field.mediaAgentType',
  '99': 'isales.taskinfo.field.versatileAgentType'
}

export default {
  name: 'ChooseAgent',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    agents: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      AGENT_TYPE,
      skillName: '',
      isSpecifiedAgent: false,
      skillList: [],
      skillTableColumns: [
        {
          type: 'radio',
          radioKey: 'skillId', // 单选绑定的字段名
          width: '50px'
        },
        {
          label: this.$t('isales.taskinfo.field.skillName'), // 技能队列
          prop: 'name',
        }
      ],
      skillPaginationConfig: {
        offset: 0,
        pageSize: 10,
        limit: 10, // 每页条数
        total: 0, //总条数
        layout: appConfig.pagination.LAYOUT.BASIC
      },
      agentList: [],
      agentTableColumns: [
        {
          label: this.$t('isales.agent.label.workNo'), // 座席工号
          prop: 'workNo',
          // width: '100px',
        },
        {
          label: this.$t('isales.label.agent.accountcode'), // 业务帐号
          prop: 'accountCode',
          // width: '150px',
          'show-overflow-tooltip': true
        },
        {
          label: this.$t('isales.label.agent.agentType'), // 座席类型
          slot: 'agentType',
          // width: '120px',
        },
        {
          label: this.$t('isales.label.agent.role'), // 角色
          slot: 'role',
          'min-width': '100px',
        },
      ],
      agentTableColumnsHasSelection: [
        {
          type: 'selection', // 多选
          selectable: this.selectable,
          width: '45px',
          'show-overflow-tooltip': false,
          'reserve-selection': true
        },
        {
          label: this.$t('isales.agent.label.workNo'), // 座席工号
          prop: 'workNo',
        },
        {
          label: this.$t('isales.label.agent.accountcode'), // 业务帐号
          prop: 'accountCode',
          'show-overflow-tooltip': true
        },
        {
          label: this.$t('isales.label.agent.agentType'), // 座席类型
          slot: 'agentType',
        },
        {
          label: this.$t('isales.label.agent.role'), // 角色
          slot: 'role',
          'min-width': '100px',
        },
      ],
      agentPaginationConfig: {
        pageNum: 1,
        offset: 0,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        limit: 10, // 每页条数
        total: 0, //总条数
        layout: appConfig.pagination.LAYOUT.ADVANCED
      },
      selectdSkillId: '',
      selectdSkillName: '',
      agentTable: [
        {
          label: this.$t('isales.agent.label.workNo'), // 座席工号
          prop: 'workNo',
        },
        {
          label: this.$t('isales.label.agent.accountcode'), // 业务帐号
          prop: 'accountCode',
          'show-overflow-tooltip': true
        },
        {
          label: this.$t('isales.label.agent.agentType'), // 座席类型
          slot: 'agentType',
        },
        {
          label: this.$t('isales.label.agent.role'), // 角色
          slot: 'role',
          'min-width': '100px',
        },
      ]
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  watch: {
    isSpecifiedAgent(val) {
      if (val) {
        this.agentTable = this.agentTableColumnsHasSelection
      } else {
        this.agentTable = this.agentTableColumns
      }
    }
  },
  async created() {
    await this.getSkillList()
    this.setSelectdSkill()
    await this.getAgentList()
  },
  methods: {
    convertAgentList() {
      this.agentList.forEach(row => {
        if (this.agents.some(item => row.accountCode === item.accountCode)) {
          this.$nextTick(() => {
            this.$refs.agentTable.table.toggleRowSelection(row)
          })
        }
      })
    },
    searchPageFirst(){
      this.skillPaginationConfig.offset = 0;
      this.$refs.skillTable.setCurrentPage(1);
      this.getSkillList();
    },
    async getSkillList() {
      const { limit, offset } = this.skillPaginationConfig
      const result = await querySkill({ limit, offset, name: this.skillName })
      this.skillList = result.skills
      this.skillPaginationConfig.total = result.count
    },
    async getAgentList() {
      const { limit, offset } = this.agentPaginationConfig
      const result = await queryAgentAndSkill({ limit, offset, skillId: this.selectdSkillId })
      this.agentList = result.data.workNos
      this.agentPaginationConfig.total = result.data.total
      // 组件回显功能有问题，先不做回显，每次都是空白待选
      // this.convertAgentList()
    },
    setSelectdSkill() {
      if (!this.skillList.length) return
      this.selectdSkillId = this.skillList[0].skillId
      this.selectdSkillName = this.skillList[0].name
      this.$refs.skillTable.setRadioCurrentValue(this.selectdSkillId)
    },
    handleSkillChange({ row, tableRadio }) {
      console.log(row, tableRadio)
      this.selectdSkillId = tableRadio
      this.selectdSkillName = row.name
      // 重置回第一页，同时会触发handlePagination
      this.$refs.agentTable.handleInitChange()
      this.getAgentList()
    },
    handleSkillPagination(data) {
      Object.assign(this.skillPaginationConfig, data)
      this.getSkillList()
    },
    handleAgentPagination(data) {
      Object.assign(this.agentPaginationConfig, data)
      this.getAgentList()
    },
    selectable() {
      return this.isSpecifiedAgent
    },
    async confirm() {
      let selectedAgents = []
      if (this.isSpecifiedAgent) { // 指定座席
        selectedAgents = this.$refs.agentTable.table.getSelectionRows()
      } else { // 不指定座席时，查询技能队列下所有座席
        const result = await queryAgentAndSkill({ limit: this.agentPaginationConfig.total, offset: 0, skillId: this.selectdSkillId })
        selectedAgents = result.data.workNos
      }
      this.dialogVisible = false
      console.log(this.selectdSkillId, this.selectdSkillName)
      this.$emit('confirm', selectedAgents, this.selectdSkillId, this.selectdSkillName)
    },
    getRowKey(row) {
      return row.workNo
    }
  }
}
</script>

<style lang="less" scoped>
.skill-wrap {
  width: 33%;
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid rgba(238,238,238,1);
}
.agent-wrap {
  width: 67%;
  padding-right: 1rem;
}
</style>