export default {
  'aicc.loading': 'Chargement',
  'aicc.select': 'Sélectionner',
  'aicc.numberOnly': 'La valeur ne peut contenir que des chiffres',
  'aicc.unorganized': 'Aucune organisation',
  'aicc.organized': 'Organismes organisés',
  'aicc.columnConfig': 'Configuration des colonnes',
  'aicc.selectAll': 'Toutes les colonnes',
  'aicc.selectedColumns': 'Colonnes sélectionnées',
  'aicc.selectedColumnsTip': '(vous pouvez les glisser pour les organiser)',
  'aicc.columnSettings': "Paramètres d'en-tête",
  'aicc.selectedInfo': '{n} éléments sélectionnés',
  'aicc.advancedSearch': 'Recherche avancée',
  'aicc.lengthLimit': 'La longueur ne peut pas dépasser {limit} caractères',
  'aicc.ok': 'OK',
  'aicc.error': 'Erreur',
  'aicc.search': 'Rechercher',
  'aicc.reset': 'Réinitialiser',
  'aicc.skip': 'Sauter',
  'aicc.goto': 'Aller à la caisse',
  'aicc.more': 'Plus',
  'aicc.intRangeLimit': 'Entrez un entier allant de {min} à {max}',
  'aicc.MaxLength': "Saisissez une chaîne de caractères composée d'un maximum de 0 caractères",
  'aicc.previous': 'Précédent',
  'aicc.next': 'Suivant',
  'aicc.noData': 'Pas de données disponibles',
  'aicc.lengthMinLimit': 'Doit contenir au moins {0} caractères',
  'aicc.traceId.error':
    "TraceId: 0. Envoyer l'information TraceId au personnel d'exploitation et d'entretien pour la localisation des pannes",
  'aicc.apibuilder.dev': "Développement d'interface",
  'aicc.timeout.desc': 'Expiration de la session',
  'aicc.timeout.solution': 'Votre connexion a expiré. Vous devez vous connecter à nouveau.',
  'aicc.input': 'S\'il vous plaît entrer',
}
