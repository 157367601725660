export default {
  "ccm.agent.button.search": "Query",
  "ccm.agent.button.clear": "Reset",
  "ccm.agent.button.refresh": "Refresh",
  "ccm.agent.button.export": "Export",
  "ccm.agent.button.edit": "Edit",
  "ccm.agent.button.cancel": "Cancel",
  "ccm.agent.button.submit": "Submit",
  "ccm.agent.button.ok": "OK",
  "ccm.agent.button.create": "New",
  "ccm.agent.button.createComplete": "Complete",
  "ccm.agent.button.save": "Save",
  "ccm.agent.button.delete": "Delete",

  "ccm.transfer.number.pop.create.title": "Creating a Forwarded-to External Number",
  "ccm.transfer.number.pop.modify.title": "Edit Forwarded-to External Number",
  "ccm.transfer.number.pop.delete.title": "Delete",
  "ccm.transfer.number.pop.delete.message": "Are you sure you want to delete the selected external number?",
  "ccm.transfer.number.search.number": "Please enter number",
  "ccm.transfer.number.search.name": "Please enter a name",
  "ccm.transfer.number.label.index": "Index",
  "ccm.transfer.number.label.number": "Number",
  "ccm.transfer.number.label.name": "Name",
  "ccm.agent.message.addSkill": "Adding Skill Queue",
  "ccm.transfer.number.label.flag": "User Number Forwarding",
  "ccm.transfer.number.label.flag.yes": "Yes",
  "ccm.transfer.number.label.flag.no": "No",
  "ccm.pageinterface.custTableConfig": "Basic Table Configuration",
  "ccm.agent.button.coloumedit":"Field Settings",

  "ccm.satisfactionconfig.group.createsuccess": "The policy group is added successfully.",
  "ccm.satisfactionconfig.group.deletesuccess": "The policy group is deleted successfully.",
  "ccm.satisfactionconfig.strategy.createsuccess": "Succeeded in adding the channel satisfaction policy.",
  "ccm.satisfactionconfig.strategy.updateuccess": "Succeeded in modifying the channel satisfaction policy.",
  "ccm.satisfactionconfig.strategy.deletesuccess": "Succeeded in deleting the channel satisfaction policy.",
  "ccm.satisfactionconfig.strategy.surveytyperequired": "The survey type cannot be empty.",

  "ccm.agent.message.weight.limit": "[1-100]",
  "ccm.agent.message.agentweight.limit": "[1-300]",
  "ccm.agent.button.downloadvideoterminal": "Download OpenEye",
  "ccm.agent.label.querymobileagent": "Query the softphone number",
  "ccm.agent.label.modifymobileagent": "Edit the softphone number",
  "ccm.agent.label.mobileagentremark": "Remarks",
  "ccm.agent.label.mobileagententer": "Enter",
  "ccm.agent.label.isaireconition": "TRUE",
  "ccm.agent.label.noaireconition": "FALSE",
  "ccm.agent.label.startworkno": "Start Employee ID",
  "ccm.agent.label.endworkno": "End Employee ID",
  "ccm.callreason.case.check.error": "Work order type {0} does not exist",
  "ccm.agent.label.inputstartworkno": "Please enter the start employee ID",
  "ccm.agent.label.inputendworkno": "Please enter the end employee ID",
  "ccm.agent.label.batchselect": "Select Agents in Batches",
  "ccm.agent.label.batchselectway": "Batch Selection Mode",
  "ccm.agent.label.selectagent": "Selected Agents",
  "ccm.agent.ten.mediaagents": "Number of Multimedia Agents",
  "ccm.agent.ten.versatileagents": "Versatile Agents",
  "ccm.agent.ten.allowedTTSAndASRCallNum": "Number of intelligent IVR channels",
  "ccm.agent.label.airecog": "Intelligent Recognition",
  "ccm.agent.label.singlePhoneFlag": "Phone-Only Agent",
  "ccm.agent.label.dualChannelRecFlag": "Dual Track Recording",
  "ccm.agent.message.addAIRecogFail": "The number of enabled intelligent agents cannot exceed the number of agents allocated when the tenant is enabled.",
  "ccm.agent.message.ctipasswdrepeat": "The new password cannot be the same as the last five old passwords.",
  "ccm.agent.message.ctipasswdsimple": "The password does not meet the platform complexity requirements. Contact O M personnel to check the password.",
  "ccm.agent.message.ctipasswdless": "The password length cannot be less than 8.",
  "ccm.agent.label.transparentDisplayFlag": "Agent Number Anonymization Flag",
  "ccm.agent.label.selfPhoneNotNull": "When the agent number anonymization feature is enabled, the agent fixed-line phone number or mobile number cannot be empty.",
  "ccm.agent.label.theAgent": "Agent:",
  "ccm.agent.label.bindTheSkill": "Before you use the skill queue, unbind the called route first.",
  "ccm.agent.label.groupUseTheSkill": ". please delete the configuration of the skill queue in the skill group first.",
  "ccm.agent.label.groupBind": "The skill queue has been bound to the skill group ",
  "ccm.agent.label.skillQueue": "Skill Queue:",
  "ccm.agent.label.cti": "cti:",
  "ccm.agent.contact.agent.recall": "The agent has withdrawn the message",
  "ccm.agent.contact.user.recall": "The user has withdrawn the message",
  "ccm.agent.label.deleteAgentFailed": "A skill queue has been bound to a business account. Delete the skill queue configuration of the agent first.",
  "ccm.agent.label.deleteCtiFailed": "Cti platform delete data failed.",
  "ccm.agent.label.useTheSkill": "Skill queue {0} has been bound to agent ID {1}. Delete the configuration of the skill queue under the agent first.",
  "ccm.agent.label.information": "Tips",
  "ccm.agent.recall.fail": "Failed to call back the customer.",
  "ccm.agent.recall.param.callid.empty": "No callback number is available.",
  "ccm.agent.recall": "Callback",
  "ccm.agent.recall.agent.calltype.invalid": "The current call type does not support the callback operation.",
  "ccm.agent.recall.agent.status.invalid": "The agent is not in idle or busy state and cannot call back.",
  "ccm.agent.label.addChildReason": "Cause settings should not exceed level 6",
  "ccm.agent.label.paramsConfig": "System Parameter Configuration",
  "ccm.agent.label.registrationMessage": "Registration Info",
  "ccm.agent.label.basicInfo": "Basic Info",
  "ccm.agent.label.serverAddress": "Registration Server Address",
  "ccm.agent.label.fixedLine": "Fixed-Line/Mobile Number",
  "ccm.agent.label.audio": "Voice agent",
  "ccm.agent.label.video": "Video agent",
  "ccm.agent.label.mediaagent": "Multimedia agent",
  "ccm.agent.label.versatileagent": "Versatile agent",
  "ccm.agent.label.on": "Turn on",
  "ccm.agent.label.off": "Shutdown",
  "ccm.agent.label.selectSkill": "Select Skill Queue",
  "ccm.speciallist.msg.partexport": "Are you sure you want to export the selected records? ",
  "ccm.agent.label.workNo": "Employee ID",
  "ccm.agent.label.sipAccount": "Softphone Number",
  "ccm.agent.label.sipPwd": "Softphone Password",
  "ccm.agent.label.agentType": "Agent Type",
  "ccm.agent.label.agentTel": "Agent Mobile/Fixed-Line Number",
  "ccm.agent.label.validTime": "Password Validity Period (Days)",
  "ccm.agent.label.timeRemaining": "Password Expiration (Day)",
  "ccm.agent.label.ccRole": "Role",
  "ccm.agent.label.ccRoleId": "Platform Role",
  "ccm.agent.label.serviceAccountId": "Associated Service Cloud Account ID",
  "ccm.agent.label.serviceAccountName": "Service Cloud Account Name",
  "ccm.agent.label.serviceAccountCode": "Account",
  "ccm.agent.label.skills": "Skill Queue",
  "ccm.agent.label.serviceCode": "Service Code",
  "ccm.agent.label.duration": "Duration",
  "ccm.agent.label.queueTime": "Queuing Duration",
  "ccm.agent.label.availabilityTime": "Available Duration",
  "ccm.agent.label.mutetime": "Silence Duration",
  "ccm.agent.label.calltime": "No Mute Call Duration",
  "ccm.agent.label.ringingTime": "Ringing Duration",
  "ccm.agent.label.serviceLevel": "Waiting Duration Percentage (%)",
  "ccm.agent.label.transferInformation": "Transfer Or Not",
  "ccm.agent.label.transfer": "Number Of Transfers",
  "ccm.agent.label.ivrUsage": "Number of calls forwarded to the IVR",
  "ccm.agent.label.originId": "Transferor Work ID",
  "ccm.agent.label.queueName": "Transfer To SkillQueue",
  "ccm.agent.label.wrapUpCode": "Global State",
  "ccm.agent.label.uuiInfo": "UUI Information",
  "ccm.agent.label.overallState": "Overall Status",
  "ccm.agent.label.maxWaitTime": "Max. Waiting Time (s)",
  "ccm.agent.label.maxWaitNum": "Max. Calls in Queue",
  "ccm.agent.label.agentList": "Service Account",
  "ccm.agent.label.Account": "Account",
  "ccm.agent.label.adjustTime": "Duration (s) in Arranging State",
  "ccm.agent.label.description": "Description",
  "ccm.agent.label.subtype": "Subtype",
  "ccm.agent.contact.subtype.none": "None",
  "ccm.agent.label.skillId": " Skill Queue ID",
  "ccm.agent.label.skillName": "Skill Queue Name",
  "ccm.agent.label.maxWaitTimeSpan": "Value [1\u201360000]",
  "ccm.agent.label.maxAgentWrapupTime": "Value [0\u20133600]",
  "ccm.agent.label.operate": "Operation",
  "ccm.agent.label.ordinaryAgent": "Common Agent",
  "ccm.agent.label.configAdmin": "Configuration Administrator",
  "ccm.agent.label.weight": "Weight",
  "ccm.agent.label.skillWeight": "Agent Skill Weight",
  "ccm.agent.label.agentWeight": "Agent Weight",
  "ccm.agent.label.skillWeightTip": "When an agent enters multiple queues, the calls in the skill queue with the highest weight are processed first.",
  "ccm.agent.label.agentWeightTip": "Calls are preferentially allocated to agents with the highest weight.",
  "ccm.agent.label.waitSkillWeight": "Enter a skill weight.",
  "ccm.agent.label.waitAgentWeight": "Enter an agent weight.",
  "ccm.agent.label.param.index": "No.",
  "ccm.agent.label.paramValue": "Value",
  "ccm.agent.label.CommomAgent": "Common Agent",
  "ccm.agent.label.qualityChecker": "Quality Checker",
  "ccm.agent.label.CallOutAgent": "Callout Agent",
  "ccm.agent.label.skillpara": "Skill Parameter Configuration",
  "ccm.agent.label.timeOut": "Skill Timeout",
  "ccm.agent.label.busy": "Skill Busy",
  "ccm.agent.label.noAgents": "Call Processing on Nobody On-Duty",
  "ccm.agent.label.transferType": "Process Method",
  "ccm.agent.label.deviceType": "Device Type",
  "ccm.agent.label.ivrChoice": "Select IVR",
  "ccm.agent.label.skillChoice": "Select Skill",
  "ccm.agent.label.release": "Release",
  "ccm.agent.label.transfer": "Transfer",
  "ccm.agent.label.ivr": "IVR",
  "ccm.agent.label.withinFifteenDays": "Within 15 days",
  "ccm.agent.label.withinSevenDays": "Within 7 days",
  "ccm.agent.label.expired": "Expired",
  "ccm.agent.label.waitVoiceConfig": "Queuing and waiting configuration",
  "ccm.agent.label.waitVoiceSelect": "Please select a waiting tone",
  "ccm.agent.label.waitVoiceDefault": "Default Wait Tone",
  "ccm.agent.label.waitVoiceCustom": "Customizing the Wait Tone",
  "ccm.agent.label.parameterType": "Queuing Method",
  "ccm.agent.label.playVoice": "Play the waiting tone",
  "ccm.agent.label.answerType": "Answer Type",
  "ccm.agent.label.answerType0": "Report employee ID",
  "ccm.agent.label.answerType2": "Report no voice",
  "ccm.agent.label.keepVoiceConfig": "Keeping and waiting configuration",
  "ccm.agent.label.keepWaitType": "Keeping Method",
  "ccm.agent.label.keepVoiceDefault": "Default Keeping Tone",
  "ccm.agent.label.keepVoiceSelect": "Please select a keeping tone",
  "ccm.agent.label.keepVoiceCustom": "Customizing the Keeping Tone",
  "ccm.agent.label.createWorkOrdeType": "Associated Case Type",
  "ccm.satisfactiontemplate.message.resetfail": "Failed to reset the content of the satisfaction survey template.",
  "ccm.satisfactionconfig.msg.templateenpty": "Select an SMS template.",
  "ccm.satisfactionconfig.msg.timelimitenpty": "The valid reply time is null.",
  "ccm.agent.label.promptParameters": "Prompt Tone",
  "ccm.agent.label.queueParameters": "Queuing",
  "ccm.agent.label.queueTransferType": "Transferred-to Object Type",
  "ccm.agent.label.queueTransferObj": "Transferred-to Object",
  "ccm.agent.label.busyRatePrefix": "When the device usage exceeds ",
  "ccm.agent.label.busyRateSuffix": ", calls cannot be transferred to the queue.",
  "ccm.agent.label.busyJudging": "Call Transfer on Busy or Overflow",
  "ccm.agent.label.deviceUsageThresholdPrefix": "When the device usage is greater than or equals to ",
  "ccm.agent.label.deviceUsageThresholdSuffix": ", the queue is busy.",
  "ccm.agent.label.waitTimePrefix": "When the predicted waiting duration exceeds ",
  "ccm.agent.label.waitTimeSuffix": ", the queue is busy.",
  "ccm.agent.label.queuingCallsMaxNumPrefix": "When the number of queuing calls exceeds ",
  "ccm.agent.label.queuingCallsMaxNumSuffix": ", the queue overflows.",
  "ccm.agent.label.scaleFactorPrefix": "When the number of queuing calls exceeds (",
  "ccm.agent.label.scaleFactorSuffix": "x Number of signed-in agents), the queue overflows.",
  "ccm.speciallist.msg.sltype.illegal": "Invalid special list type.",
  "ccm.agent.message.queryctiagentmessage": "Failed to find agent information in CTI.",
  "ccm.agent.message.nulagentincti": "The currently selected agent is not created in CTI,You need to select the role information.",
  "ccm.agent.message.isornoairecognition": "Intelligent Status",
  "ccm.agent.message.querycenterfailed": "Failed to query the intelligent agent of the call center.",
  "ccm.agent.message.aiagentnummore": "The number of enabled intelligent agents cannot exceed the number of agents allocated when the tenant is enabled.",
  "ccm.agent.message.aiagentclosed": "The tenant intelligent identification feature is disabled. Contact the system administrator.",
  "ccm.agent.message.updateagentandskillfail": "Failed to update the association between the agent and skill queue.",
  "ccm.agent.message.worknull": "The employee ID is empty.",
  "ccm.agent.message.airecognull": "The intelligent recognition flag is empty.",
  "ccm.agent.message.worknonoexit": "The employee ID does not exist.",
  "ccmanagement.agent.batchmodify.agentnum": "By Employee ID",
  "ccmanagement.agent.batchmodify.agentlis": "By Segment",
  "ccm.agent.button.batchmodify": "Batch Modify",
  "ccm.agent.message.worknostartbig": "The start employee ID cannot be greater than the end employee ID.",
  "ccm.agent.message.typenosame": "The skill queue types must be the same.",
  "ccm.agent.message.agentandskillnotmatch": "The skill type must match the agent type",
  "ccm.agent.message.agenttypecannotbenull": "Select an agent type if the skill queue is selected.",
  "ccm.agent.message.agentnumreachmax": "Number of target agent type already reached maximum",
  "ccm.agent.message.omsAgentTypeCheck": "The selected role does not match the agent type for an online agent.",
  "ccm.agent.message.audioagentzero": "The count of audio agents is 0. The audio click-to-dial skill queue cannot be configured.",
  "ccm.agent.message.videoagentzero": "The count of video agents is 0. The video click-to-dial skill queue cannot be configured.",
  "ccm.agent.message.omsAgentTypeBatchCheck": "The selected role or agent type does not match when the agent (employee ID [{0}]) is enabled online.",
  "ccm.agent.message.agentnumcannotmuchthanmax": "Number of target agent type can not much than maximum",
  "ccm.agent.message.agentnummaxoperation": "Select up to 100 agents.",
  "ccm.agent.message.searchAccount": "Enter an Agent ID.",
  "ccm.agent.message.searchSipAccount": "Enter a SIP Softphone Number.",
  "ccm.agent.message.searchAccountName": "Enter an Account.",
  "ccm.agent.message.warning": "Warning",
  "ccm.agent.message.selectAgents": "Please select an agent.",
  "ccm.agent.message.selectSkills": "Please select a skill queue.",
  "ccm.agent.message.clear": "Clear All",
  "ccm.agent.message.delete": "Delete",
  "ccm.agent.message.deleteSkill": "Delete Skill Queue",
  "ccm.agent.message.willClearAgents": "Are you sure you want to clear the selected agents?",
  "ccm.agent.message.willDeleteAgents": "Are you sure you want to delete the selected agent?",
  "ccm.agent.message.willDeleteSkills": "Are you sure you want to delete {0} skill queues?",
  "ccm.agent.message.willDeleteSkillGroups": "Are you sure you want to delete the selected skill groups?",
  "ccm.agent.message.agentConfSuccess": "Agent information is configured successfully.",
  "ccm.agent.message.agentConfFail": "Failed to configure agent information.",
  "ccm.agent.message.accRepeatFail": "The Account parameter is duplicate.",
  "ccm.agent.message.agentNotFoundFail": "Failed to find agent information.",
  "ccm.agent.message.agentCreateFail": "Failed to create agent in CTI.",
  "ccm.agent.message.agentUpdateFail": "Update log failed.",
  "ccm.agent.message.description.limit": "The skill queue description is too long",
  "ccm.agent.message.name.limit": "The name is too long.",
  "ccm.agent.message.name.grouplimit": "The name cannot exceed 64.",
  "ccm.agent.message.sysParamCofig": "Failed to configure system parameters.",
  "ccm.agent.message.sysParamCofig.openVersatileAgentFail": "Change the agent type on the Agent Management page. The number of versatile agents and video agents cannot be greater than the maximum number of video agents that a tenant can subscribe to.",
  "ccm.agent.message.sysParamCofig.closeVersatileAgentFail": "Change the agent type on the Agent Management page. A versatile agent cannot be associated with a video or video click-to-dial skill queue.",
  "ccm.agent.message.deleteAgentSkillFail": "Failed to delete the association of the agent skill queue.",
  "ccm.agent.message.updateAgentAndSkillFail": "Failed to update the association of the agent skill queue.",
  "ccm.agent.message.createAgentAndSkillFail": "Failed to create the association of the agent skill queue.",
  "ccm.agent.message.searchSkillName": "Enter the skill queue name.",
  "ccm.agent.message.searchsoftphone": "Enter the softphone number.",
  "ccm.agent.message.skillConfSuccess": "The skill queue information is configured successfully.",
  "ccm.agent.message.deleteSkillConfSuccess": "The skill queue information is deleted successfully.",
  "ccm.agent.message.createSkillConfSuccess": "The skill queue information is created successfully.",
  "ccm.agent.message.modifySkillConfSuccess": "The skill queue information is modified successfully.",
  "ccm.agent.message.modifymobileagentsuccess": "Successfully modified information about the mobile agent softphone number.",
  "ccm.agent.message.skillConfFail": "Failed to configure the skill queue information.",
  "ccm.agent.message.deleteSkillConfFail": "Failed to delete the skill queue information.",
  "ccm.agent.message.createSkillConfFail": "Failed to create the skill queue information.",
  "ccm.agent.message.modifySkillConfFail": "Failed to modify the skill queue information.",
  "ccm.agent.message.modifymobileagentfail": "Failed to modify information about the mobile agent softphone number.",
  "ccm.agent.message.idIsEmpty": "The skill queue ID is empty.",
  "ccm.agent.message.skillHasbeenUsedByAgent": "The skill queue has been occupied by an agent. Delete the agent first.",
  "ccm.agent.message.updateCtiAndDbSuccess": "Synchronous success.",
  "ccm.agent.message.deleteSkillAndAgentFail": "Delete skill and agent failed.",
  "ccm.agent.message.addSkillFail": "Add skill failed.",
  "ccm.agent.message.addSkillSuccess": "Skill queue added successfully.",
  "ccm.agent.message.maxSkillFail": "A maximum of 100 skill queue policies can be configured.",
  "ccm.agent.message.modifySkillFail": "Update skill failed.",
  "ccm.agent.message.queryCallCenterFail": "Query call center failed.",
  "ccm.agent.message.refreshSkillException": "Refresh skill exception.",
  "ccm.agent.message.updateCtiAndDbFail": "Update cti and Db failed.",
  "ccm.agent.message.inputWeight": "Enter a Weight Value.",
  "ccm.agent.message.agentCTIFail": "Query Agents modified in gateway failed.Please check gateway configuration.",
  "ccm.agent.message.longCharacters": "The number can contain a maximum of 25 characters.",
  "ccm.agent.message.longCharacters.form": "The number can contain a maximum of 25 characters",
  "ccm.agent.message.specialCharacters": "The number contains special characters and letters.",
  "ccm.agent.message.export": "Export",
  "ccm.agent.message.export.skill": "Export Skill Queue",
  "ccm.agent.info.message.export": "Exporting Agent Information",
  "ccm.agent.message.selected": "You have selected\u0020",
  "ccm.agent.message.willExportAgent": " \u0020messages. Are you sure to export?",
  "ccm.agent.message.allExportAgent": "Are you sure you want to export all agent information?",
  "ccm.agent.message.configvalidatefailed": "The Gateway Configuration item validate failed",
  "ccm.agent.message.batchExport": "Batch Export",
  "ccm.agent.message.allExport": "Export All",
  "ccm.agent.message.searchTimeRemaining": "Password expiration",
  "ccm.agent.message.redislocknull": "Failed to obtain the Redis lock.",
  "ccm.agent.message.webRTCVerification": "This parameter cannot be configured when the WebRTC feature is enabled.",
  "ccm.agent.title.agentEdit": "Agent Info Configuration",
  "ccm.agent.title.batchagentEdit": "Batch Agent Info Configuration",
  "ccm.agent.title.selectskill": "Select Skill Queue",
  "ccm.agent.title.serviceAccount": "Associated Service Cloud Account",
  "ccm.agent.title.skillModify": "Modify Skill Queue",
  "ccm.agent.title.skillCreate": "New Skill Queue",
  "ccm.agent.title.specialListHis": "Special List History",
  "ccm.satisfactionconfig.message.switchtip": "After this function is enabled, the system automatically displays the satisfaction survey page after the web channel service is complete. After other multimedia channel services are complete, the system automatically pushes the satisfaction template content.",
  "ccm.satisfactionconfig.message.timelimittip": "If the customer reply time exceeds the valid reply time, the reply does not take effect.",
  "ccm.agent.tips.confSuccess": "Configuration Successfully.",
  "ccm.agent.tips.noagent": "No agent exists in the selected number segment.",
  "ccm.agent.tips.confFail": "Configuration Failed",
  "ccm.speciallist.msg.enable.fail": "Enabling failed",
  "ccm.agent.pageurls.create": "New",
  "ccm.agent.pageurls.sernum": "No.",
  "ccm.agent.pageurls.pagename": "Page Name",
  "ccm.agent.pageurls.accessaddr": "Access Address",
  "ccm.agent.pageurls.openMode": "Open Mode",
  "ccm.agent.pageurls.configpage": "Config Page",
  "ccm.agent.pageurls.config": "Config",
  "ccm.agent.pageurls.isenable": "Enable",
  "ccm.agent.pageurls.operation": "Operation",
  "ccm.agent.pageurls.delete": "Delete",
  "ccm.agent.pageurls.edit": "Edit",
  "ccm.agent.pageurls.moveup": "Up",
  "ccm.agent.pageurls.movedown": "Down",
  "ccm.agent.pageurls.createcall": "New Incoming Call Screen Pop-up",
  "ccm.agent.pageurls.config.screen.pop.up": "Config Screen Pop-up",
  "ccm.agent.pageurls.unsaved.data.exists":"Unsaved data exists. Please save it first.",
  "ccm.agent.pageurls.errorsave": "Error",
  "ccm.agent.pageurls.errorinfo": "Creation failed.",
  "ccm.agent.pageurls.successinfo": "Created successfully.",
  "ccm.agent.pageurls.successinfo.noPoint": "Created successfully",
  "ccm.agent.pageurls.maxcalling": "The maximum number of incoming call pop-up screens is {limit}.",
  "ccm.agent.pageurls.updatecall": "Edit Incoming Call Screen Pop-up",
  "ccm.agent.pageurls.confdelete": "Confirm Delete",
  "ccm.agent.pageurls.confreset": "Confirm Reset",
  "ccm.agent.pageurls.isconfdelete": "Are you sure you want to delete it?",
  "ccm.agent.pageurls.isconfreset": "Are you sure you want to reset it?",
  "ccm.agent.pageurls.success": "Successful",
  "ccm.agent.pageurls.delsuccess": "Deleted successfully.",
  "ccm.agent.pageurls.fail": "Error",
  "ccm.agent.pageurls.delfail": "Deletion failed.",
  "ccm.agent.pageurls.adressval": "The access address must start with 'https://' or 'http://'.",
  "ccm.agent.pageurls.adressval.noPoint": "The access address must start with 'https://' or 'http://'",
  "ccm.agent.pageurls.updateinfo": "Updated successfully.",
  "ccm.agent.moveup.error": "Move up failed.",
  "ccm.agent.moveup.success": "Move up successfully.",
  "ccm.agent.movedown.error": "Move down failed.",
  "ccm.agent.movedown.success": "Move down successfully.",
  "ccm.agent.pageurls.errorupdate": "Update failed.",
  "ccm.agent.pageurls.confnaming": ". Ensure that the name does not exist in the system.",
  "ccm.agent.pageurls.confonlynaming": ". Ensure that the name is unique.",
  "ccm.agent.pageurls.maxcallingnum": ". Ensure that the number of pop-up screens does not exceed 5.",
  "ccm.agent.pageurls.whitelisturl": ". Please contact the system administrator to add the url to the trustlist.",
  "ccm.agent.pageurls.typenosupported": "Multimedia does not support internal page configuration.",
  "ccm.agent.pageurls.independentlynosupported": "Internal page configuration is not supported for independent opening.",
  "ccm.agent.pageurls.integrationType": "Integration Type",
  "ccm.pageurls.select.embedding": "Embed",
  "ccm.pageurls.select.independently": "Independently opened",
  "ccm.pageurls.select.urlParameter": "URL GET REQUEST Parameter",
  "ccm.pageurls.select.webApi": "Web API",
  "ccm.pageurls.select.postParameter": "URL POST REQUEST Parameter",
  "ccm.agent.pageurl.httptip": "(Not safe)",
  "ccm.agent.protocol.check": "Note that the network protocol used by the url address is not a secure protocol. If you decide to use this protocol, security risks may exist.",
  "ccm.agent.url.params.tips": "Note that the way the URL GET REQUEST Parameter is made exposes your parameters to an open address.",
  "ccm.agent.contact.accountname": "Account Name",
  "ccm.agent.contact.caller": "Caller Number",
  "ccm.agent.contact.called": "Called Number",
  "ccm.agent.contact.acceptno": "Handled Number",
  "ccm.agent.contact.customername": "Customer Name",
  "CCM.AGENT.CONTACT.ORG": "Agent Organization",
  "ccm.agent.contact.allcalltype": "All Call Types",
  "ccm.agent.contact.callserno": "Call S/N",
  "ccm.agent.contact.minCallDuration": "Call Duration Start",
  "ccm.agent.contact.maxCallDuration": "Call Duration End",
  "ccm.agent.contact.lessThan": "less than",
  "ccm.agent.contact.callDurationError": "The call duration start time is shorter than the call duration end time",
  "ccm.agent.contact.talkDurationError":"The call duration cannot exceed 31 days.",
  "ccm.agent.contact.fuzzyCallNoError":"The contact number range (fuzzy search) cannot exceed 31 days.",
  "ccm.agent.contact.fuzzyCallNo": "Contact Number (Fuzzy Search)",
  "ccm.agent.contact.workno": "Employee ID",
  "ccm.agent.contact.begintime": "Start Time                                                  ",
  "ccm.agent.contact.endtime": "End Time",
  "ccm.agent.contact.calltime": "Call Duration (s)",
  "ccm.agent.contact.recordtime": "Recording Duration(s)",
  "ccm.agent.contact.hungup": "Hang-up Party",
  "ccm.agent.contact.oircall": "Original Called Number",
  "ccm.agent.contact.contactno": "Contact Number",
  "ccm.agent.contact.callid": "Call ID",
  "ccm.agent.contact.assistantCallid": "Collaborative Call ID",
  "ccm.agent.contact.calltype": "Call Type",
  "ccm.agent.contact.recordingfiles": "Recording File",
  "ccm.agent.contact.userhungup": "User",
  "ccm.agent.contact.agenthungup": "Agent",
  "ccm.agent.contact.robothungup": "Robot",
  "ccm.agent.contact.otherhungup": "Others",
  "ccm.agent.contact.skillhungup": "Transfer to Skill Queue",
  "ccm.agent.contact.businesshungup": "Transfer to Agent",
  "ccm.agent.contact.numhungup": "Transfer to External Number",
  "ccm.agent.contact.ivrhungup": "Forward to IVR",
  "ccm.agent.contact.callin": "Incoming Call",
  "ccm.agent.contact.callout": "Outgoing Call",
  "ccm.agent.contact.callinandout": "Double call",
  "ccm.agent.contact.predictiveCall": "Predicted outbound call",
  "ccm.agent.contact.preCallout": "Preview outgoing call",
  "ccm.agent.contact.assistant": "Collaborative call",
  "ccm.agent.contact.clicktocall": "Click-to-dial",
  "ccm.agent.contact.multi-media": "Multimedia",
  "ccm.agent.contact.audio-visual": "Audio/Video",
  "ccm.agent.contact.web": "Web",
  "ccm.agent.contact.weChat": "WeChat",
  "ccm.agent.contact.faceBook": "Facebook",
  "ccm.agent.contact.twitter": "X (Twitter)",
  "ccm.agent.contact.fiveg": "5G RCS",
  "ccm.agent.contact.email": "Email",
  "ccm.agent.contact.Email": "Email",
  "ccm.agent.contact.voiceBot": "Voice",
  "ccm.agent.contact.line": "LINE",
  "ccm.agent.contact.whatsapp": "WhatsApp",
  "ccm.agent.contact.sms": "SMS",
  "ccm.agent.contact.Instagram": "Instagram",
  "ccm.agent.contact.Telegram": "Telegram",
  "ccm.agent.contact.others-channels": "Others Channels",
  "ccm.agent.contact.satiscomment": "Satisfaction evaluation",
  "ccm.ivr.button.traceInfo": "Click to view the current trace log.",
  "ccm.agent.title.IVRTrace": "IVR trace",
  "ccm.agent.contact.noAuth": "No permission.",
  "ccm.agent.contact.authHandle": "Contact related management personnel.",
  "ccm.agent.contact.Voice": "Voice",
  "ccm.agent.contact.vidio": "Video",
  "ccm.agent.contact.mediaType": "Media Type",
  "ccm.agent.contact.reset": "Reset",
  "ccm.agent.contact.resetAll": "All Reset",
  "ccm.agent.contact.refreshAll": "Refresh",
  "ccm.agent.contact.play": "Play",
  "ccm.agent.contact.playcomfirm": "Confirm Play",
  "ccm.agent.contact.playdefault": "The recording duration is empty. Do you want to use the default duration 60 minutes?",
  "ccm.agent.contact.download": "Download",
  "ccm.agent.contact.downloadrecord": "Download Recording",
  "ccm.agent.contact.downloadvideo": "Download Video",
  "ccm.agent.contact.playLeaveMessage": "The following message is played:",
  "ccm.agent.contact.playRecording": "playing a recording",
  "ccm.agent.contact.playVideo": "playing a video",
  "ccm.agent.contact.norecording": "No recording.",
  "ccm.agent.contact.customizes": "Customization",
  "ccm.agent.contact.downloadFail": "Download failed.",
  "ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE": "The recording information is empty.",
  "ccm.agent.contact.QUERU_SFTP_FAIL_CODE": "The SFTP information is empty.",
  "ccm.agent.contact.QUERU_VCCINFO_FAIL_CODE": "The virtual call center information is empty.",
  "ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE": "Failed to download the recording file from the SFTP server.",
  "ccm.agent.contact.CONNECT_FAIL_CODE": "Failed to connect to the SFTP server.",
  "ccm.agent.contact.QUERY_SERVER_FAIL_CODE": "Failed to invoke the query service.",
  "ccm.agent.contact.SFTP_INCORRECT_FILE_CODE": "The sftp server information is incorrect",
  "ccm.agent.contact.resourceloading": "Loading resources...",
  "ccm.agent.contact.playfail": "Playback failed.",
  "ccm.agent.contact.resourcefail": "An error occurs during recording loading.",
  "ccm.agent.contact.download.frequently": "You tried to download it too may times. Please try again later.",
  "ccm.agent.contact.contactdetail": "Contact Details",
  "ccm.agent.contact.talkReason": "Call Reason",
  "ccm.agent.contact.sameContactRecord": "Same Call Contact Record",
  "ccm.agent.contact.transferRecord": "Transfer Record",
  "ccm.agent.contact.talkRemark": "Call Remarks",
  "ccm.agent.contact.voiceVidio": "Audio/Video",
  "ccm.agent.contact.multimedia": "Multimedia",
  "ccm.agent.contact.clicktodial": "Click to Dial",
  "ccm.agent.contact.audioclicktodial": "Voice Click to Dial",
  "ccm.agent.contact.videoclicktodial": "Video Click to Dial",
  "ccm.agent.contact.callerPresent": "User's Display Number",
  "ccm.agent.contact.agentPresent": "Agent's Display Number",
  "ccm.agent.contact.reasonCode": "Reason Code",
  "ccm.agent.contact.relCallBackFlag": "Release Callback Flag",
  "ccm.agent.contact.talkCallbackFlag": "Talk Callback Flag",
  "ccm.agent.contact.nohaveevent": "No such event",
  "ccm.agent.contact.calledhangup": "End of call, called hang up",
  "ccm.agent.contact.callershutdown": "End of call, caller shutdown",
  "ccm.agent.contact.callerfailed": "Outbound caller failed",
  "ccm.agent.contact.calledfailed": "Outbound called failed",
  "ccm.agent.contact.callernook": "Outbound caller no answer",
  "ccm.agent.contact.callednook": "Outbound called no answer",
  "ccm.agent.contact.startcallfailed": "Start call failed",
  "ccm.agent.contact.otherexception": "Other Exception",
  "ccm.agent.contact.recordInfo": "Recording File Details",
  "ccm.agent.contact.gateway.fail": "Request to the gateway Exception. Please check the gateway configuration.",
  "ccm.agent.contact.gateway.exception": "Gateway internal Exception. Please check the gateway configuration.",
  "ccm.agent.operation.noMoreThan1Days": "The time range cannot exceed 1 days.",
  "ccm.agent.operation.noMoreThan3Days": "The time range cannot exceed 3 days.",
  "ccm.agent.operation.noMoreThan7Days": "The time range cannot exceed 7 days.",
  "ccm.agent.operation.noMoreThan30Days": "The time range cannot exceed 30 days.",
  "ccm.agent.operation.noMoreThan31Days": "The time range cannot exceed 31 days.",
  "ccm.agent.operation.noMoreThan90Days": "The time range cannot exceed 90 days.",
  "ccm.agent.label.thirtyonedays": "31 Days",
  "ccm.agent.label.ninetydays": "90 Days",
  "ccm.agent.operation.searchcountid": "Service Account",
  "ccm.agent.operation.countid": "Account Number",
  "ccm.agent.operation.searchworkno": "Please enter the Employee ID",
  "ccm.agent.operation.optype": "Operation Type",
  "ccm.agent.operation.optime": "Operation Time",
  "ccm.agent.operation.opresult": "Result",
  "ccm.agent.operation.ID": "Operation No.",
  "ccm.agent.operation.failCode": "Failure Reason Code",
  "ccm.agent.operation.vdnNotEnabled": "The VDN is not enabled.",
  "ccm.recognition.msg.unsubscribeConfirm": "If intelligent recognition is disabled, intelligent agent assistant is also disabled.",
  "ccm.agent.operation.HOLD": "Hold",
  "ccm.agent.operation.UNHOLD": "Unhold",
  "ccm.agent.operation.MUTE": "Mute",
  "ccm.agent.operation.UNMUTE": "Unmute",
  "ccm.agent.operation.RECORDPLAY": "Play Recording",
  "ccm.agent.operation.LISTEN": "Listen Quality Check",
  "ccm.agent.operation.INSERT": "Insert Quality Check",
  "ccm.agent.operation.CANCELLISTEN": "Cancel Listening",
  "ccm.agent.operation.CANCELINSERT": "Cancel Insertion",
  "ccm.agent.operation.SWITCH": "Switch",
  "ccm.agent.operation.INTERCEPT": "Intercept",
  "ccm.agent.operation.FORCELOGOUT": "Force Exit",
  "ccm.agent.operation.FORCEIDEL": "Force Idle",
  "ccm.agent.operation.FORCEBUSY": "Force Busy",
  "ccm.agent.operation.HELP": "Consult",
  "ccm.agent.operation.CALLINNER": "Internal Call",
  "ccm.agent.operation.TWOSTAGEDIALING": "Two-stage Dialing",
  "ccm.agent.operation.WHISPER": "Whisper",
  "ccm.agent.operation.CANCELWHISPER": "Cancel Whisper",
  "ccm.agent.operation.SAYBUSY": "Busy",
  "ccm.agent.operation.SAYFREE": "Idle",
  "ccm.agent.operation.REST": "Request for Rest",
  "ccm.agent.operation.CANCELREST": "Cancel Rest",
  "ccm.agent.operation.CONFERENCE": "Three-Party Conference",
  "ccm.agent.operation.AllOpType": "All Operation Types",
  "ccm.agent.operation.CALLOUT": "CallOut",
  "ccm.agent.operation.ANSWER": "Answer",
  "ccm.agent.operation.mediaswitch": "Audio/Video Switch",
  "ccm.agent.operation.RELEASE": "Release",
  "ccm.agent.operation.ADJUSTSKILL": "Adjust Skill queue",
  "ccm.agent.operation.FORCEDROPCALL": "Forcibly release",
  "ccm.agent.operation.ADJUSTVIDEOMODE": "Adjusting the Video Window Size",
  "ccm.agent.login.logIP": "Sign-In IP Address",
  "ccm.agent.login.ID": "Operation Sequence",
  "ccm.agent.login.LOGIN": "Sign In",
  "ccm.agent.login.LOGOUT": "Sign Out",
  "ccm.agent.login.FORCELOGOUT": "Force Sign Out",
  "ccm.agent.login.FORCELOGIN": "Force Sign In",
  "ccm.speciallist.msg.delete.fail": "Deletion failed",
  "ccm.agent.Transfer.CallForwarding": "Transfer Call ",
  "ccm.agent.Transfer.Intercept": "Intercept",
  "ccm.agent.Transfer.tranfermode": "Transfer Mode",
  "ccm.agent.Transfer.devicetype": "Destination Device Type",
  "ccm.agent.Transfer.deviceDesc": "Destination Device",
  "ccm.agent.Transfer.deviceAddr": "Destination Device Address",
  "ccm.agent.Transfer.theAgent": "Agent",
  "ccm.contactitemconfig.message.itemValidatefirst": "The first letter must be a letter, underscore (_), or dollar sign ($)",
  "ccm.agent.restrecord.reststatus": "Rest State",
  "ccm.agent.restrecord.reststatus.resting": "Resting",
  "ccm.agent.restrecord.reststatus.normal.endrest": "Rest ended normally",
  "ccm.agent.restrecord.reststatus.timeout.endrest": "Rest timed out",
  "ccm.agent.restrecord.reststatus.timeout.Forcible.endrest": "Rest forced to end",
  "ccm.agent.restrecord.reststatus.Abnormal.endrest": "Rest ended abnormally",
  "ccm.agent.restrecord.rest.beginTime": "Rest Start Time",
  "ccm.agent.restrecord.rest.endTime": "Rest End Time",
  "ccm.agent.restrecord.rest.duration": "Rest Duration",
  "ccm.agent.restrecord.rest.currentstatus": "Current Status",
  "ccm.agent.restrecord.rest.reason": "Rest Reason",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "Keyword",
  "casemanagement.common.label.nodata": "No data.",
  "casemanagement.user.title.name": "User Name",
  "casemanagement.user.title.select": "Select",
  "casemanagement.user.title.account": "Account",
  "casemanagement.user.button.cancel": "Cancel",
  "casemanagement.user.button.ok": "OK",
  "ccm.agent.verify.fail": "Verification failed.",
  "ccm.agent.password.wrong": "Failed to verify the password.",
  "ccm.agent.password.agentNull": "The agent information is empty.",
  "ccm.agent.tips.inputpassword": "Enter the password of the current sign-in account.",
  "ccm.agent.title.agentpassword": "Account Password",
  "ccm.agent.button.close": "Close",
  "ccm.agent.title.agentinfo": "Agent Info",
  "ccm.agent.title.querysippwd": "Check Softphone Password",
  "ccm.agent.skill.nameInfo": "Skill Queue Name:",
  "ccm.agent.skill.skillExist": "already exists. Enter another name.",
  "ccm.agent.ten.tenantInfor": "Tenant Info",
  "ccm.agent.ten.tenantName": "Tenant Name",
  "ccm.agent.ten.companyName": "Company",
  "ccm.agent.ten.createDate": "Creation Time",
  "ccm.agent.ten.expiryDate": "Expiration Date",
  "ccm.agent.ten.contactInfor": "Contact Method",
  "ccm.agent.ten.phonenumber": "Mobile Number",
  "ccm.agent.ten.email": "Email",
  "ccm.agent.ten.resourceinfor": "Resource Information",
  "ccm.agent.ten.maxAgents": "Voice Agents",
  "ccm.agent.ten.maxCalls": "Max. Concurrent Voice Calls",
  "ccm.agent.ten.maxVideoAgents": "Video Agent Quantity",
  "ccm.agent.ten.maxVideoCalls": "Max. Concurrent Video Calls",
  "ccm.agent.ten.ivrchannels": "Audio IVR Channel Quantity",
  "ccm.agent.ten.videoIvrchannels": "Video IVR Channel Quantity",
  "ccm.agent.ten.ttschannelnums": "TTS Quantity",
  "ccm.agent.ten.asrchannelnums": "ASR Quantity",
  "ccm.agent.ten.recordtime": "Recording Retention Period",
  "ccm.agent.ten.vdnid": "VDN ID",
  "ccm.agent.ten.tenantId": "Tenant Space ID",
  "ccm.agent.ten.sipInfo": "SIP Address and Port",
  "ccm.agent.ten.agentSipInfo": "IP address and port number of the agent registration server",
  "ccm.agent.ten.anonymousSipInfo": "IP address and port number of the anonymous call server",
  "ccm.satisfactiontemplate.message.savefail": "Saving the satisfaction survey template failed.",
  "ccm.satisfactiontemplate.message.confirm":"Confirm whether to update the template content based on the satisfaction level.",
  "ccm.satisfactiontemplate.message.queryfail":"Failed to query the satisfaction survey template.",
  "ccm.satisfactiontemplate.tipscontent":"The satisfaction survey message template is used to send satisfaction survey messages to customers in the Instagram, Telegram, WhatsApp, Line, Web Chat, Face Book, X (Twitter), 5G RCS, and SMS channels.",
  "ccm.satisfaction.changetips":"Note: The satisfaction level configuration is changed. Modify the satisfaction survey template accordingly.",
  "ccm.satisfactionconfig.message.question":"The satisfaction survey message template is used to send satisfaction survey messages to customers in the Instagram, Telegram, WhatsApp, Line, Web Chat, Face Book, X (Twitter), 5G RCS, and SMS channels.",
  "ccm.satisfactionlevel.satislevel.config":"Satisfaction Level Configuration",
  "ccm.satisfactionlevel.satislevel.button":"Buttons",
  "ccm.satisfactionlevel.satislevel.description":"Description",
  "ccm.satisfactionlevel.satislevel.operat":"Operation",
  "ccm.satisfactionlevel.satislevel.moveup":"Up",
  "ccm.satisfactionlevel.satislevel.movedown":"Down",
  "ccm.agent.calledroute.createcalledroute": "New",
  "ccm.agent.calledroute.errorsave": "Error",
  "ccm.agent.calledroute.createfail": "Failed to add local data.",
  "ccm.agent.calledroute.createsuccess": "Added successfully.",
  "ccm.agent.calledroute.updatecalledroute": "Edit",
  "ccm.agent.calledroute.title.create": "Creating a Called Party Configuration",
  "ccm.agent.calledroute.title.updatecalledroute": "Edit Called Party Configuration",
  "ccm.agent.calledroute.confdelete": "Confirm Delete",
  "ccm.agent.calledroute.isconfdelete": "Are you sure you want to delete the selected called party?",
  "ccm.agent.calledroute.success": "Success",
  "ccm.agent.calledroute.delsuccess": "Deleted successfully.",
  "ccm.agent.calledroute.fail": "Failure",
  "ccm.agent.calledroute.exception": "Check the Redis status or CC-Gateway configuration.",
  "ccm.agent.calledroute.delfail": "Failed to delete the local data.",
  "ccm.agent.calledroute.editfail": "Failed to edit the local data.",
  "ccm.agent.calledroute.editsuccess": "Edited successfully.",
  "ccm.agent.calledroute.create": "New",
  "ccm.agent.calledroute.accesscode": "Access Code",
  "ccm.agent.calledroute.devicetype": "Device Type",
  "ccm.agent.calledroute.devicedesc": "Device Name",
  "ccm.agent.calledroute.extcode": "Extension Code",
  "ccm.agent.calledroute.delete": "Delete",
  "ccm.agent.calledroute.edit": "Edit",
  "ccm.agent.calledroute.pagename": "Page Name",
  "ccm.agent.calledroute.operation": "Operation",
  "ccm.agent.calledroute.search.devicedesc": "Please enter a device description.",
  "ccm.calledroute.title.selectaccesscode": "Select Access Code",
  "ccm.calledroute.tip.selectaccesscode": "Please select an access code first.",
  "ccm.accesscode.placeholder.accessdesc": "Please enter an access code description.",
  "ccm.agent.calledroute.refresh": "Refresh",
  "ccm.agent.calledroute.refreshsuccess": "Refresh successed.",
  "ccm.agent.calledroute.ivr": "Please Choose IVR.",
  "ccm.agent.calledroute.skill": "Skill Queue",
  "ccm.calledroute.button.cancel": "Cancel",
  "ccm.calledroute.button.finish": "Complete",
  "ccm.agent.calledroute.placeholder.extcode": "Please enter an extension code.",
  "ccm.agent.calledroute.placeholder.accesscode": "Please select an access code. ",
  "ccm.agent.calledroute.placeholder.ivr": "Please select an IVR.",
  "ccm.agent.calledroute.placeholder.skill": "Please select a skill queue.",
  "ccm.calledroute.title.selectivrflow": "Select IVR",
  "ccm.calledroute.title.selectskill": "Select Skill Queue",
  "ccm.calledroute.tip.selectivrexception": "Failed to query IVR Flows in CTI.",
  "ccm.calledroute.message.selectcalledroute": "Please select the called party configuration.",
  "ccm.agent.calledroute.dnid": "Configuration ID",
  "ccm.agent.calledroute.create.accesscode.none": "The system access code configured for the called party is not allocated.",
  "ccm.agent.calledroute.create.accesscode.repeat": "The combination of the system access code and extension code for the called party must be unique.",
  "ccm.agent.calledroute.create.accesscode.noexist": "The access code referenced by the called party configuration does not exist.",
  "ccm.agent.calledroute.create.skill.none": " The skill queue referenced by the called party configuration does not exist.",
  "ccm.agent.calledroute.create.ivr.limit": "If the device type is IVR, the media type of the access code can only be click-to-dial, callback request, or common call.",
  "ccm.agent.calledroute.create.gateway.none": "The routing script referenced by the called party configuration does not exist.",
  "ccm.agent.calledroute.create.media.match": "The system access code configured for the called party is different from the media type configured for the called party.",
  "ccm.agent.calledroute.gateway.fail": "Failed to synchronize the information the CTI platform. Please check the gateway configuration.",
  "ccm.agent.calledroute.delete.skill.default": "The called party configuration is referenced by the default private skill queue and cannot be deleted.",
  "ccm.agent.calledroute.delete.skill.use": "The called party configuration is referenced by a skill queue and cannot be deleted.",
  "ccm.agent.calledroute.delete.work.use": "The called party configuration is referenced by the agent and cannot be deleted.",
  "ccm.agent.calledroute.nmsfail": "Synchronization to the NMS failed.",
  "ccm.agent.calledroute.nmsdata.error": "Failed to synchronize data to the NMS. The called party configuration data of the NMS is inconsistent with the local data of the CCM.",
  "ccm.agent.calledroute.ccivrdata.error": "Failed to synchronize data to the NMS. The CC-IVR process data is incorrect.",
  "ccm.agent.calledroute.nmsconnect.fail": "connect NMS failed.",
  "ccm.agent.accesscode.description.notempty": "The Access Code description can not be empty.",
  "ccm.agent.accesscode.description.containspatial": "Access code description cannot contain special characters % _ ^ & * < > $ /",
  "ccm.satisfactiontemplate.tips": "Note: The system uses the default language of the tenant to generate the template content, The satisfaction survey message template is used to send satisfaction survey messages to customers in the Instagram, Telegram, WhatsApp, Line, Web Chat, Face Book, X (Twitter), 5G RCS, and SMS channels.",
  "ccm.accesscode.label.index": "No.",
  "ccm.accesscode.label.accesscode": "Access Code",
  "ccm.accesscode.label.accesscodedesc": "Access Code Description",
  "ccm.accesscode.title.accesscodedesc": "Edit Access Code Description",
  "ccm.ivr.label.processid": "Process ID",
  "ccm.ivr.label.processdesc": "Process Description",
  "ccm.ivr.label.processaccesscode": "Flow Code",
  "ccm.ivr.label.processfilename": "File Name",
  "ccm.ivr.placeholder.ivrdesc": "Please enter the process description.",

  "ccm.skill.label.skillname": "Skill Queue Name",
  "ccm.skill.label.skilldesc": "Description",
  "ccm.skill.label.maxwaittime": "Max. Queuing Duration",
  "ccm.skill.label.maxwaitnum": "Max. Calls in Queue",
  "ccm.skill.placeholder.skilldesc": "Please enter a skill queue name.",
  "ccm.skill.message.skillpara": "Can not config skill itself",
  "ccm.agent.calledroute.recordexist": "The access code is bound to the extension code. Please enter a new extension code.",
  "ccm.agent.calledroute.require": "Enter all mandatory fields.",
  "ccm.agent.calledroute.extcodewarn": "The extension code can contain only digits.",
  "ccm.agent.calledroute.extlengthcodewarn": "The length of extension code can not be larger than 12",
  "ccm.agent.calledroute.accesscodewarn": "The access code can contain only digits!",
  "ccm.agent.calledroute.device": "Select a device.",

  "ccm.agent.accesscode.index": "No.",
  "ccm.agent.accesscode.accesscode": "Access Code",
  "ccm.agent.accesscode.description": "Access Code Description",
  "ccm.agent.accesscode.n400number": "Service Number",
  "ccm.agent.accesscode.description.require": "The access code description cannot be empty.",
  "ccm.agent.accesscode.description.limit": "The access code description cannot contain more than 50 characters.",
  "ccm.agent.sysparamconfig.paramwarn": "Please select a valid parameter value.",
  "ccm.agent.sysparamconfig.resttimewarn": "The value must be an integer.",
  "ccm.agent.sysparamconfig.worknowarn": "Use commas (,) to separate agent IDs.",
  "ccm.agent.sysparamconfig.worknonumwarn": "The number of agent IDs cannot exceed 10.",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkRepeat": "There is duplication in the selected section!",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkLimit": "No more than five seats are allowed.",
  "ccm.agent.sysparamconfig.positiveinteger": "Please enter a  positive integer!",
  "ccm.agent.sysparamconfig.resttimenumberwarn": "The rest duration must range from 1 to 1440(not contains 1440)",
  "ccm.agent.sysparamconfig.worknorangewarn": "The agent ID must range from 101 to 50000.",
  "ccm.agent.sysparamconfig.multimediawarn": "The number of concurrent multimedia messages must range from 1 to 60.",
  "ccm.agent.sysparamconfig.outboundtimecheck": "The time limit for automatic outbound call preview must range from 1s to 3600s.",
  "ccm.agent.sysparamconfig.checkWebhookValidityDays": "Webhook certification must be valid between 7 and 365 days",
  "ccm.agent.sysparamconfig.verifyTemplateIdLength": "The internal message template ID must be an integer and cannot be a negative number.",
  "ccm.agent.sysparamconfig.refreshSuccess": "One-click reset succeeded.",
  "ccm.agent.sysparamconfig.resetAllParamsFail": "The parameter configuration file is missing. Please check.",
  "ccm.agent.sysparamconfig.refreshAllSysParamSuccess": "Refresh successed.",
  "ccm.agent.sysparamconfig.refreshAllSysParamFail": "System error, Refresh failed.",
  "ccm.vdn.label.index": "months",
  "ccm.agent.message.sysparamservicerr": "Internal service error. Please check the log.",
  "ccm.agent.message.download.err": "Download failed. Contact system O&M engineers.",
  "ccm.vdn.label.defaultvoice": "default",

  "ccm.recognition.label.sucess": "Success",
  "ccm.recognition.label.fail": "Failure",
  "ccm.recognition.label.ordersucess": "Subscription succeeded.",
  "ccm.recognition.label.orderfail": "Subscription failed.",
  "ccm.recognition.label.orderfailwhilte": "Subscription failed. Failed to synchronize the trustlist.",
  "ccm.recognition.label.cancelordersucess": "The subscription is canceled successfully.",
  "ccm.recognition.label.updatesucess": "Update tenant fault success.",
  "ccm.recognition.label.updatefail": "Update tenant fault failed.",
  "ccm.recognition.label.cancelorderfail": "Unsubscription failed.",
  "ccm.recognition.label.orderfailpd": "Subscription failed. Try again later.",
  "ccm.recognition.label.cancelorderfailpd": "Failed to cancel the subscription. Try again later.",
  "ccm.recognition.label.characteristics": "Feature",
  "ccm.recognition.label.intellrecogn": "Intelligent Recognition",
  "ccm.recognition.label.intellagent": "Intelligent Agent Assistant",
  "ccm.recognition.label.processaccesscode": "Enter a flow access code.",
  "ccm.recognition.label.save": "Save",
  "ccm.recognition.label.openIvrAgentSuccess": "The intelligent agent assistant is enabled successfully.",
  "ccm.recognition.label.closeIvrAgentSuccess": "The intelligent agent assistant is disabled successfully.",
  "ccm.recognition.label.mobileagent": "Mobile Agent/One-Click Bidirectional Call",
  "ccm.recognition.label.modifymobileagentsuccess": "Successfully modified the mobile agent status!",
  "ccm.recognition.label.openmobileagentsuccess": "Successfully enabled the mobile agent.!",
  "ccm.recognition.label.closemobileagentsuccess": "Successfully disabled the mobile agent.!",
  "ccm.recognition.label.modifymobileagentfailed": "Failed to modify the mobile agent status.!",
  "ccm.recognition.label.modifymobileagentclose": "The mobile agent and one-click bidirectional call features are not enabled for the tenant space!",
  "ccm.recognition.label.modifymobileagentflowidnull": "The flow ID is empty!",
  "ccm.recognition.label.openBreakDown": "Breakdown Voice",
  "ccm.recognition.label.breakDownVoice": "Breakdown Voice",
  "ccm.recognition.label.voiceNotification": "Voice Notification Flag",
  "ccm.recognition.label.openVoiceNotificationSuccess": "The voice notification feature is enabled successfully.",
  "ccm.recognition.label.closeVoiceNotificationSuccess": "The voice notification feature is disabled successfully.",
  "ccm.recognition.label.modifyVoiceNotificationClose": "The voice notification feature is not enabled for the tenant space.",


  "ccm.callreason.label.input": "Please enter",
  "ccm.callreason.label.callreason": "Call Reason",
  "ccm.callreason.title.callreason": "Associated Call Reason",
  "ccm.callreason.label.parentcallreason": "Parent Call Reason",
  "ccm.callReason.title.createCallReason": "Create Call Reason",
  "ccm.callReason.title.editCallReason": "Edit Call Reason",
  "ccm.callreason.label.selectedcallreason": "Selected Call Reason",
  "ccm.callreason.label.mark": "Remarks",
  "ccm.callreason.label.enable": "Enable",
  "ccm.callreason.label.disable": "Disable",
  "ccm.callreason.label.setcallreason": "Call Reason Configuration",
  "ccm.callreason.label.name": "Name",
  "ccm.callreason.label.status": "Status",
  "ccm.agent.label.deletecallreason": "Current reason Contains child reason Cannot be deleted",
  "ccm.agent.label.notselectcallreason": "Please select the call reason",
  "ccm.agent.contact.talkcontent": "Conversational Content",
  "ccm.callreason.input.specialStrValidateFailed": "The call reason name cannot contain special characters.",
  "ccm.callreason.label.allreason": "All Reasons",
  "ccm.callreason.label.selectedreason": "Selected Reasons",

  "ccm.calledconfig.label.numbers": "Numbers",
  "ccm.calledconfig.label.querysoftnum": "View Softphone Numbers",
  "ccm.calledconfig.label.add": "Add",
  "ccm.calledconfig.label.selectcalled": "Select Called",
  "ccm.calledconfig.table.devtype": "Purpose Device Type",
  "ccm.calledconfig.table.skillormumbers": "Purpose Device",
  "ccm.calledconfig.table.selectnumbers": "Select Numbers",
  "ccm.calledconfig.table.addnumbers": "Add Numbers",
  "ccm.calledconfig.title.numbersadded": "Numbers Added",
  "ccm.calledconfig.message.deletemobileagent": "Delete Mobile Agent",
  "ccm.calledconfig.message.willdeletemobileagent": "Are you sure you want to delete the selected mobile agent?",
  "ccm.calledconfig.message.deletemobileagentsuccess": "The mobile agent configuration is deleted successfully",
  "ccm.calledconfig.message.deletemobileagentfail": "Failed to delete the association of the mobile agent configuration",
  "ccm.calledconfig.message.savemobileagentsuccess": "The mobile agent configuration is saved successfully",
  "ccm.calledconfig.message.savemobileagentfail": "Failed to save the association of the mobile agent configuration",
  "ccm.calledconfig.message.numbersinvalid": "More than 15 or less than 1 numbers",
  "ccm.calledconfig.message.nocalled": "Please choose called",
  "ccm.calledconfig.message.duplicatecalled": "Duplicate called",
  "ccm.calledconfig.message.nonumbers": "Please choose numbers",
  "ccm.calledconfig.message.noskill": "Please choose skill queue.",
  "ccm.satisfactionlevel.title.templateupdate": "Update Template",
  "ccm.calledconfig.message.willdeleteassociation": "Are you sure you want to delete the call reason association information",
  "ccm.calledconfig.message.callreasontips": "Please note: The associated TT at the current level is unavailable. Select a subset call reason to set the associated TT.",
  "casemanagement.casedraft.label.casetype" : "Case Type",
  "casemanagement.casedraft.label.selectcasetype": "Select Case Type",

  "ccm.callout.lable.index": "Index",
  "ccm.callout.lable.type": "Parameter Type",
  "ccm.callout.lable.number": "Parameter Value",
  "ccm.callout.lable.default": "Default Value",
  "ccm.callout.lable.reset": "Reset",
  "ccm.callout.lable.caller": "Callout Caller",
  "ccm.callout.placeholder.caller": "Please enter the calling number of the outgoing call",
  "ccm.callout.title.fail": "Fail",
  "ccm.callout.title.success": "Success",
  "ccm.callout.message.confreset": "Reset Confirm",
  "ccm.callout.message.isconfreset": "Are you sure you want to reset the callout caller?",
  "ccm.callout.message.choosecaller": "Please choose a callout caller",
  "ccm.callout.message.nocallers": "No callout callers have been chosen",
  "ccm.callout.message.editsuccess": "Success to edit the callout caller",
  "ccm.callout.message.editfail": "Fail to edit the callout caller",
  "ccm.callout.message.resetsuccess": "Succeeded in resetting the parameter.",
  "ccm.callout.message.resetfail": "Failed to reset parameters.",
  "ccm.agentparam.message.editsuccess": "Edited successfully.",
  "ccm.agentparam.message.editfail": "Editing failed.",
  "ccm.agent.mobileagent.notice": "Notice",
  "ccm.agent.mobileagent.confirm": "Confirm",
  "ccm.agent.mobileagent.success": "Succeed",
  "ccm.agent.mobileagent.fail": "Failed",
  "ccm.agent.mobileagent.label.nodata": "No matching data found!",
  "ccm.agent.mobileagent.label.choosehint": "Please choose agents before delete them!",
  "ccm.agent.mobileagent.label.deletehint": "Are you sure to delete?",
  "ccm.agent.mobileagent.label.deletecomplete": "Delete agent complete!",
  "ccm.agent.label.usedMode": "Type",
  "ccm.agent.label.usedMode.phone": "Phone Number",
  "ccm.agent.label.usedMode.sipAccount": "Sip Account",
  "ccm.agent.message.searchAgentServiceNo": "Please enter agent service number",
  "ccm.agent.message.nocomments": "No comments",
  "ccm.agent.message.deletefailed": "Delete agent failed",
  "ccm.agent.message.createfailed": "Create agent failed",

  "ccm.urlconfig.label.talkurl": "Connection Callback URL",
  "ccm.urlconfig.label.talkmethod": "Method of Connection Callback",
  "ccm.urlconfig.label.relurl": "Hang-up Callback URL",
  "ccm.urlconfig.label.relmethod": "HTTP request method",
  "ccm.urlconfig.message.addsuccess": "Success to add the urls",
  "ccm.urlconfig.message.addfail": "Fail to add the urls",
  "ccm.urlconfig.message.modifysuccess": "Success to modify the urls",
  "ccm.urlconfig.message.modifyfail": "Fail to modify the urls",
  "ccm.urlconfig.message.deletesuccess": "Success to reset the urls",
  "ccm.urlconfig.message.deletefail": "Fail to reset the urls",
  "ccm.urlconfig.pageurls.urlvalidate": "The urls must start with http:// or https://",
  "ccm.agent.message.validatormsg": "Password-related inputs should be blank or not blank at all",
  "ccm.agent.message.validatordigit": "Single Phone Agent Setup Password should be numeric only",
  "ccm.agent.agentcontact.error": "Query fail",
  "ccm.agent.agentcontact.nosearch": "Cannot be queried in non-business handling.",
  "ccm.urlconfig.message.httpurl.safetip": "(Not safe)",
  "ccm.callback.url.callback": "Bidirectional Call Callback Configuration",
  "ccm.callback.url.callnotify": "Voice Notification Callback Configuration",
  "ccm.urlconfig.label.oauth.label": "OAuth 2.0 Authorization Information",
  "ccm.agent.message.samepassword": "The new password cannot be the same as the current password",
  "ccm.urlconfig.label.key": "Secret key",
  "ccm.urlconfig.label.appsecret": "Shared Key",
  "ccm.urlconfig.modify.authtype1": "Shared Key",
  "ccm.urlconfig.modify.authtype2": "OAuth 2.0",
  "ccm.urlconfig.modify.authtype3": "None",
  "ccm.urlconfig.label.oauth.ak": "AK",
  "ccm.urlconfig.label.oauth.sk": "SK",
  "ccm.urlconfig.label.oauth.oauth2loginurl": "OAuth 2.0 Authentication Login URL",

  "ccm.callback.url.auth.tip": "Callback URL Authentication Mode",
  "ccm.urlconfig.modify.callnotify.ak.tooshort": "The key must contain at least 16 characters.",
  "ccm.urlconfig.modify.oauth.ak.tooshort": "The AK must contain at least 16 characters.",
  "ccm.urlconfig.modify.oauth.sk.tooshort": "The SK must contain at least 16 characters.",
  "ccm.urlconfig.modify.authbothnull": "The authorization information cannot be empty.",
  "ccm.urlconfig.label.oauth.oauth2loginurl.illegal": "Invalid login URL.",

  "ccm.urlconfig.label.relurl.voicemsg.alerttip": "If no callback URL is transferred when the voice notification creation API is invoked, this URL is called back upon voice notification completion. Otherwise, the transferred URL is called back.",
  "ccm.urlconfig.label.oauth.voicemsg.alerttip": "If a callback URL is transferred when the voice notification creation API is invoked and the following authentication information is configured, this information is used during callback of the URL upon voice notification completion. Otherwise, no authentication is performed during the callback.",
  "ccm.urlconfig.label.relurl.callback.alerttip": "If no callback URL is transferred when the bidirectional call creation API is invoked, this URL is called back upon bidirectional call release. Otherwise, the transferred URL is called back.",
  "ccm.urlconfig.label.oauth.callback.alerttip": "If a callback URL is transferred when the bidirectional call creation API is invoked and the following authentication information is configured, this information is used during callback of the URL upon bidirectional call release. Otherwise, no authentication is performed during the callback.",

  "ccm.urlconfig.label.noramalOrITA": "Select the CEC bidirectional call or ITA bidirectional call type.",
  "ccm.urlconfig.label.radio.radioType1": "CEC bidirectional call",
  "ccm.urlconfig.label.radio.radioType2": "ITA bidirectional call",
  "ccm.urlconfig.message.warning": "Ensure that the CDR push URL has been configured on the ITA. Otherwise, the ITA cannot push CDRs to the CEC. An example push URL is as follows:",
  "ccm.urlconfig.message.tip.warning": "Ensure that you have purchased ITA-related services. Otherwise, calls will be affected.",
  "ccm.urlconfig.message.oauth.tip.warning": "No callback URL authentication may cause security risks. Are you sure you want to use this authentication mode?",
  "ccm.urlconfig.label.noramalOrITA.input": "ITA Parameter Configuration",
  "ccm.urlconfig.label.input.appId": "AccessKey",
  "ccm.urlconfig.label.input.appSecret": "SecretKey",
  "ccm.urlconfig.message.missing.appIdOrAppSecret": "The access key or secret key are not set in the ITA bidirectional call configuration.",
  "ccm.urlconfig.message.overlimit.appId": "The access key configured for ITA bidirectional calls cannot exceed 128 characters.",
  "ccm.urlconfig.message.overlimit.appSecret": " The secret Key configured for ITA bidirectional calls cannot exceed 512 characters.",
  "ccm.urlconfig.label.numberAllocationMode1": "Automatic",
  "ccm.urlconfig.label.numberAllocationMode2": "Specified",
  "ccm.urlconfig.label.input.appKey": "AppKey",
  "ccm.urlconfig.label.input.domainName": "ITA Domain Name",
  "ccm.urlconfig.label.xNumber": "X Number",
  "ccm.urlconfig.message.missing.appKeyOrDomainName": "The app key and domain name in the ITA bidirectional call configuration must be set.",
  "ccm.urlconfig.message.overlimit.appKey": "The app key in the ITA bidirectional call configuration cannot exceed 128 characters.",
  "ccm.urlconfig.message.overlimit.domainName": "The domain name in the ITA bidirectional call configuration cannot exceed 128 characters.",
  "ccm.urlconfig.message.overlimit.xNumber": "The X number configured for ITA bidirectional calls cannot exceed 20 characters.",
  "ccm.urlconfig.message.notNumber.xNumber": "The X number configured for ITA bidirectional calls is not number.",

  "ccm.satisfactionconfig.web.switch.remark": "After this switch is turned on, the satisfaction survey will be pushed to the customer after the service is complete.",
  "ccm.satisfactionconfig.param.faile" : "Incorrect parameter.",
  "ccm.satisfactionconfig.select.ivr.process": "Please select a satisfaction survey IVR flow.",
  "ccm.satisfactionconfig.web.satisfaction.survey": "Web Channel Satisfaction Survey",
  "ccm.satisfactionconfig.sms.satisfaction.survey": "SMS Satisfaction Survey",
  "ccm.satisfactionconfig.ivr.voice.satisfaction.survey": "IVR Voice Satisfaction Survey",
  "ccm.satisfactionconfig.audio.and.video.remark": "When both the IVR voice and SMS satisfaction survey switches are turned on and the agent hangs up first, no SMS satisfaction survey will be conducted if an IVR voice satisfaction survey has been conducted",
  "ccm.satisfactionconfig.audio.and.video": "Voice or Video Channel Satisfaction Survey",
  "ccm.satisfactionconfig.satisfaction.sending.channel": "SMS Sending Channel",
  "ccm.satisfactionconfig.sms.switch.remark": "After this switch is turned on, a satisfaction survey SMS message will be sent to the customer after the call ends",
  "ccm.satisfactionconfig.satisfaction.process": "Satisfaction Survey Flow",
  "ccm.satisfactionconfig.satisfaction.switch": "Switch",
  "ccm.satisfactionconfig.message.audio": "Satisfaction Survey",
  "ccm.satisfactionconfig.message.media": "Multimedia channels",
  "ccm.satisfactionconfig.message.satisreport": "Satisfaction Survey Report",
  "ccm.satisfactionconfig.message.satis": "Satisfaction Survey",
  "ccm.satisfactionconfig.message.staistip1": "After this switch is turned on, the customer will be transferred to the satisfaction survey after the agent hangs up.",
  "ccm.satisfactionconfig.message.surveymethod": "Survey Method",
  "ccm.satisfactionconfig.message.surveyconfig": "Survey Configuration",
  "ccm.satisfactionconfig.message.flowname": "Process Name",
  "ccm.satisfactionconfig.message.save": "Save",
  "ccm.satisfactionconfig.message.success": "Success",
  "ccm.satisfactionconfig.message.failed": "Failed",
  "ccm.satisfactionconfig.message.createsuccess": "Successfully added the satisfaction survey configuration.",
  "ccm.satisfactionconfig.message.createfailed": "New satisfaction survey configuration failed.",
  "ccm.satisfactionconfig.message.updatesuccess": "Successfully updated the satisfaction survey configuration.",
  "ccm.satisfactionconfig.message.updatefailed": "Update of satisfaction survey configuration failed",
  "ccm.satisfactionconfig.message.enterflowname": "Please enter the process name",
  "ccm.satisfactionconfig.message.number": "Number",
  "ccm.satisfactionconfig.message.flowaccesscode": "Process access code",
  "ccm.satisfactionconfig.message.version": "version number",
  "ccm.satisfactionconfig.message.confirm": "Confirm",
  "ccm.satisfactionconfig.message.cancel": "Cancel",
  "ccm.satisfactionconfig.message.sms": "SMS",
  "ccm.satisfactionconfig.message.chooseivrflow": "Choose IVR flow",
  "ccm.satisfactionconfig.message.failtogetivrflow": "Please turn on satisfaction survey",

  "ccm.agent.leavemessage.serviceContactNumber": "Contact Number",
  "ccm.agent.leavemessage.allStatus": "All message status ",
  "ccm.agent.leavemessage.status.toBeAssigned": "To be allocated ",
  "ccm.agent.leavemessage.status.toBeProcessed": "To be processed ",
  "ccm.agent.leavemessage.status.unprocessed": "Unprocessed ",
  "ccm.agent.leavemessage.status.inProcess": "Processing ",
  "ccm.agent.leavemessage.status.solved": "Processed ",
  "ccm.agent.leavemessage.status.allocationFailed": "Allocation failed",
  "ccm.agent.leavemessage.contactNumber": "Contact number ",
  "ccm.agent.leavemessage.workNo": "agent",
  "ccm.agent.leavemessage.channel": "Channel ",
  "ccm.agent.leavemessage.status": "Status",
  "ccm.agent.leavemessage.auditAccount": "Audit Account",
  "ccm.agent.leavemessage.auditcomments": "Audit Comments",
  "ccm.agent.leavemessage.auditdate": "Update Time ",
  "ccm.agent.leavemessage.assign": "Assign",
  "ccm.agent.leavemessage.title.assign": "Assigning Messages",
  "ccm.agent.leavemessage.process": "Process",
  "ccm.agent.leavemessage.messageprocess": "Process Message",
  "ccm.agent.leavemessage.inputauditcomments": "Enter suggestions.",
  "ccm.agent.leavemessage.processsuccess": "The message is processed successfully.",
  "ccm.agent.leavemessage.processfail": "Failed to process the message.",
  "ccm.agent.leavemessage.lengthcodewarn": "A message can contain a maximum of 300 characters.",
  "ccm.agent.leavemessage.downloadLeaveMessage": "Download Message",
  "ccm.agent.leavemessage.assignSuccess": "The message is allocated successfully",
  "ccm.agent.leavemessage.assignFailed": "Failed to allocate the message.",
  "ccm.agent.leavemessage.paramisempty": "Empty parameter.",
  "ccm.agent.leavemessage.QUERU_LEAVEMESSAGE_FAIL_CODE": "Empty SFTP information for querying messages.",
  "ccm.agent.leavemessage.statusFailed": "Failed to change the message record status.",
  "ccm.agent.leavemessage.cannotBeModified": "Cannot modify the message in the current state.",
  "ccm.agent.leavemessage.notLoginIn": "The agent has not signed in.",
  "ccm.agent.leavemessage.notAgent": "The account is not bound to an agent.",
  "ccm.agent.leavemessage.view": "View Messages",
  "ccm.agent.leavemessage.channelType": "Channel Type",
  "ccm.agent.label.positiveEmotionNumber": "Positive ",
  "ccm.agent.label.negativeEmotionNumber": "Negative",
  "ccm.agent.label.sensitiveWordNumber": "Forbidden words",
  "ccm.agent.label.positiveEmotion": "Good job. Keep it up.",
  "ccm.agent.label.negativeEmotion": "Improper wording. Use an appropriate script.",
  "ccm.agent.label.sensitiveWord": "Forbidden words are detected. Mind your wording.",

  "ccm.agent.videoDownload.disabled": "The video download function is disabled",
  "ccm.agent.videoDownload.noPermission": "The agent has no permission to download",
  "ccm.agent.videoDownload.RSETimeout": "Requesting the RSE service timed out",

  "ccm.agent.log.downloadVideoTerminal": "Download the OpenEye Video Terminal",

  "ccm.page.label.status": "Page Status",
  "ccm.agent.page.release": "Release",
  "ccm.agent.page.cancel.release": "Unreleased",
  "ccm.label.pageName": "Page Name",
  "ccm.page.label.pageInput": "Enter a page name",
  "ccm.label.page.createPage": "New Page",
  "ccm.label.page.modifyPage": "Modify Page",
  "ccm.page.select.blockLayout": "Block layout",
  "ccm.page.select.tileLayout": "Grid layout",
  "ccm.agent.message.pageConfSuccess": "Operation succeeded.",
  "ccm.agent.message.pageConfFail": "Operation failed",
  "ccm.agent.message.inSpecialStrFail": "The page name cannot contain special characters.",
  "ccm.agent.message.samePageNameFail": "Duplicate page name.",
  "ccm.agent.message.numInRowFail": "The value must be an integer ranging from 2 to 4.",
  "ccm.agent.message.pageNumFail": "A maximum of {0} pages are allowed.",
  "ccm.agent.message.pageLayoutFail": "The page layout or number of rows is not selected.",
  "ccm.agent.message.quotingDataOperationFail": "The data is referenced by the pop-up screen. Operation failed.",
  "ccm.agent.message.dataIntegrityFail": "Incomplete data configured for the page layout or interface set. Operation failed.",
  "ccm.label.page.delete.confirm": "Are you sure you want to delete it?",
  "ccm.pageconfig.label.layoutType": "Layout type",
  "ccm.pageconfig.label.numInRow": "Number of rows",
  "ccm.agent.message.pageLayout": "Page Layout",
  "ccm.agent.message.pageInterface": "Page interface",
  "ccm.agent.label.numInRow": "Enter an integer ranging from 2 to 4.",
  "ccm.agent.message.noReleaseFail": "Only unreleased data can be deleted.",
  "ccm.agent.message.pageNameValidateFail": "Special characters are not allowed",
  "ccm.agent.message.itemNameValidateFail": "The following special characters are not allowed &<>\";%/[]=+{'@'}",
  "ccm.agent.page.pageParameter": "Page parameters",
  "ccm.agent.label.parameter": "Parameter 1",
  "ccm.agent.input.userNumber": "User number",

  "ccm.agent.button.restSoftPhonePwd": "Reset Softphone Password",
  "ccm.agent.button.restSoftPhonePwdError": "Bind the agent.",
  "ccm.agent.button.restSoftPhonePwd.tips": "Resetting the softphone password will affect the softphone in use, requiring the agent to log in again. Are you sure you want to reset the softphone password?",
  "ccm.agent.button.unregister": "Unregister ",
  "ccm.agent.button.restores": "Restores",
  "ccm.agent.ccagentinfo.unregister.success": "Unregister Success",
  "ccm.agent.ccagentinfo.unregister.fail": "Unregister Fail",
  "ccm.agent.ccagent.modify.fail": "Intelligent recognition and dual-track recording cannot be enabled at the same time.",
  "ccm.agent.ccagent.qcmodify.fail": "Select an audio agent type.",
  "ccm.agent.softPhoneStatus": "softphone number status",
  "ccm.agent.softPhoneStatusNode1": "Node 1",
  "ccm.agent.softPhoneStatusNode2": "Node 2",
  "ccm.agent.softPhoneStatus.Registered": "Registered",
  "ccm.agent.softPhoneStatus.UnRegistered": "Not Registered",


  "ccm.custdata.title.create": "Creating a Base Table",
  "ccm.custdata.title.modify": "Editing a Base Table",

  "ccm.custdata.button.detail": "Details",
  "ccm.custdata.button.release": "Release",
  "ccm.custdata.button.withdraw": "Withdraw",
  "ccm.custdata.button.abandon": "Discard",
  "ccm.custdata.button.return": "Return",
  "ccm.custdata.button.moveTop": "Top",
  "ccm.custdata.button.moveUp": "Up",
  "ccm.custdata.button.moveDown": "Down",
  "ccm.custdata.button.moveBottom": "Bottom",
  "ccm.custdata.button.exportTemplate": "Export Template",
  "ccm.custdata.button.importData": "Import Data",
  "ccm.custdata.button.importRecord": "Import result view",

  "ccm.custdata.title.edit": "Edit Data",
  "ccm.custdata.title.coloum.add": "Creating a Field Column",
  "ccm.custdata.title.coloum.edit": "Edit Field Column",
  "ccm.custdata.title.coloum.detail": "Viewing Field Details",
  "ccm.custdata.label.tableName": "Table Name",
  "ccm.custdata.label.columnNum": "Fields",
  "ccm.custdata.label.status": "Status",
  "ccm.custdata.label.description": "Description",
  "ccm.custdata.label.columnNo": "No",
  "ccm.custdata.label.columnName": "Field Name",
  "ccm.custdata.label.columnType": "Field Type",
  "ccm.custdata.label.columnLength": "Field length",
  "ccm.custdata.label.valueRange": "Value range",
  "ccm.custdata.label.isIndex": "Used as index",
  "ccm.custdata.label.isEncrypt": "Encrypted",
  "ccm.custdata.label.isMandatory": "Mandatory",
  "ccm.custdata.label.isMaskDisplay": "Masked",
  "ccm.custdata.label.maskStyle": "Mask style",
  "ccm.custdata.label.importantTips": "Note: Personal data or sensitive data must be encrypted for storage and masked for display.",
  "ccm.custdata.label.relaBaseColumn": "Field in Underlying Table",
  "ccm.custdata.label.dataImportRecord": "Data Import Record",
  "ccm.custdata.label.beginTime": "Start Time",
  "ccm.custdata.label.endTime": "End Time",
  "ccm.custdata.label.sucNum": "Number of Successful Records",
  "ccm.custdata.label.failNum": "Number of Failed Records",
  "ccm.custdata.label.dualCall.sucNum": "Number of Successful Records",
  "ccm.custdata.label.dualCall.failNum": "Number of Failed Records",
  "ccm.custdata.label.template": "_Template",
  "ccm.custdata.label.failreaon": "Failure Cause",

  "ccm.custdata.option.all": "All",
  "ccm.custdata.option.status.draft": "Draft",
  "ccm.custdata.option.status.release": "Release",
  "ccm.custdata.option.status.abandon": "Discard",
  "ccm.custdata.option.columnType.string": "String",
  "ccm.custdata.option.columnType.number": "Number",
  "ccm.custdata.option.columnType.date": "Date",
  "ccm.custdata.option.columnType.time": "DateTime",
  "ccm.custdata.option.columnType.dictionary": "Dictionary",
  "ccm.custdata.option.isIndex.yes": "Yes",
  "ccm.custdata.option.isIndex.no": "No",
  "ccm.custdata.option.isEncrypt.yes": "Yes",
  "ccm.custdata.option.isEncrypt.no": "No",
  "ccm.custdata.option.isMandatory.yes": "Required",
  "ccm.custdata.option.isMandatory.no": "This field is optional",
  "ccm.custdata.option.isMaskDisplay.yes": "Yes",
  "ccm.custdata.option.isMaskDisplay.no": "No",
  "ccm.custdata.option.maskStyle.retainLast4": "Reserve last four digits",
  "ccm.custdata.option.maskStyle.retainFirst4": "Reserve first four digits",
  "ccm.custdata.option.maskStyle.maskLast4": "Mask last four digits",
  "ccm.custdata.option.maskStyle.maskFirst4": "Mask first four digits",
  "ccm.custdata.option.maskStyle.maskAll": "Mask all digits",
  "ccm.custdata.option.maskStyle.maskDate": "Date mask",
  "ccm.custdata.option.maskStyle.maskEmail": "Email mask",
  "ccm.custdata.option.maskStyle.maskBankAccountNum": "Bank account mask",
  "ccm.custdata.option.maskStyle.maskIdCardNum": "ID card number mask",
  "ccm.custdata.option.importDoing": "Processing",
  "ccm.custdata.option.importDone": "Completed",
  "ccm.custdata.option.importFail": "Failed",

  "ccm.custdata.msg.msgValidateFailed": "Special characters are not allowed.",
  "ccm.custdata.msg.dictionaryValidateFailed": "Incorrect format of the data dictionary value range",
  "ccm.custdata.msg.inputTableName": "Enter a table name.",
  "ccm.custdata.msg.confirmDelete": "Are you sure you want to delete it?",
  "ccm.custdata.msg.confirmRelease": "Are you sure you want to release it?",
  "ccm.custdata.msg.confirmWithdraw": "Are you sure you want to withdraw it?",
  "ccm.custdata.msg.confirmAbandon": "Are you sure you want to discard it?",
  "ccm.custdata.msg.operationSuccess": "Operation succeeded.",
  "ccm.custdata.msg.operationFailed": "Operation failed.",
  "ccm.custdata.msg.notAllowOperation": "This operation is not allowed. Refresh the page and try again.",
  "ccm.custdata.msg.duplicateTableName": "Duplicate table name. Rename it.",
  "ccm.custdata.msg.deletedItemNotExist": "The deletion item does not exist. Refresh the page and try again.",
  "ccm.custdata.msg.columnNoMatch": "The table field is inconsistent with that in the database. Refresh the table and try again.",
  "ccm.custdata.msg.duplicateColumnName": "Duplicate field name. Rename it.",
  "ccm.custdata.msg.updatedItemNotExist": "The field to be updated does not exist. Refresh the page and try again.",
  "ccm.custdata.msg.typeColumnNumValidateFailed": "The number of fields exceeds the upper limit: Only one index field, and a maximum of 30 non-encrypted fields and 20 encrypted fields are allowed.",
  "ccm.custdata.msg.confirmReturn": "The sorting is not complete. Are you sure you want to return to the page for viewing the customized table?", "ccm.custdata.msg.completeSort": "Complete the sorting first.",
  "ccm.custdata.msg.sortNoChange": "The sorting does not change.",
  "ccm.custdata.msg.dictionaryInputFormat": "Format:  Key 1:Value 1, Key 2:Value 2",
  "ccm.custdata.msg.indexColumnValidateFailed": "A field used as an index is mandatory and cannot be encrypted.",
  "ccm.custdata.msg.uploadSelect": "Select a file.",
  "ccm.custdata.msg.fileNameValidateFail": "Incorrect file name. Select a file whose name contains only Chinese characters, lowercase letters, digits, or underscores(_).",
  "ccm.custdata.msg.fileSuffixValidateFail" : "Incorrect file format. Select a CSV file.",
  "ccm.custdata.msg.fileSizeValidateFail": "Select a file less than 20 MB.",
  "ccm.custdata.msg.importDataSuccess": "Exporting data...View details in Import Result.",
  "ccm.custdata.msg.importDataFail": "Failed to import data.",
  "ccm.custdata.msg.reachUpperTableNum": "The number of customized tables has reached the upper limit.",
  "ccm.custdata.msg.missRequiredColumn": "A customized table can be released only when it contains at least one index field and other information fields.",
  "ccm.custdata.msg.tableBeUsed": "The customized table is referenced by a configuration page object. Ensure that the table is not referenced before performing this operation.",
  "ccm.custdata.msg.tableExistInstData": "The customized table contains instance data and cannot be withdrawn for modification.",
  "ccm.custdata.msg.selectItem": "Select an item before sorting.",
  "ccm.custdata.operlog.downloadTemplate": "Downloading the Basic Table Template",
  "ccm.custdata.operlog.uploadDataFile": "Uploading the Basic Table Data File",
  "ccm.custdata.operlog.downloadData": "Downloading Basic Table Data",
  "ccm.custdata.operlog.authFailed": "Auth failed",
  "ccm.custdata.operlog.addExportTask": "New export task",
  "ccm.custdata.operlog.viewExportTask": "Export task view",
  "ccm.custdata.operlog.confirm": "Confirm",
  "ccm.custdata.operlog.confirmMessage": "Are you sure to create a new export task?",
  "ccm.custdata.operlog.refresh": "Refresh",
  "ccm.custdata.operlog.download": "download",
  "ccm.custdata.operlog.exportBeginTime": "Export start time",
  "ccm.custdata.operlog.exportEndTime": "Export end time",
  "ccm.custdata.operlog.conditions": "Conditions",
  "ccm.custdata.operlog.exportTaskStatus": "Status",
  "ccm.custdata.operlog.displayMask": "Display Mask",
  "ccm.custdata.operlog.operation": "Operate",
  "ccm.custdata.operlog.addExportTaskSuccess": "New export task succeeded",
  "ccm.custdata.operlog.addExportTaskError": "New export task failed, please check the log",
  "ccm.custdata.operlog.exportiong": "Exporting",
  "ccm.custdata.operlog.success": "Success",
  "ccm.custdata.operlog.failed": "Failed",
  "ccm.custdata.operlog.passValidateFailed": "Must contain letters , numbers , special characters _ {'@'} {'%'}",
  "ccm.custdata.operlog.zippwd": "Compressed password",
  "ccm.custdata.operlog.inputzippwd": "Enter compression password",
  "ccm.custdata.operlog.importdata": "Import Data",
  "ccm.custdata.operlog.popwindowmessage": "Please enter the password used to generate the compressed file. The compressed password should contain letters, numbers, special characters _ {'@'} {'%'}",


  "ccm.querycustdata.msg.inputIndexData": "Please input",
  "ccm.querycustdata.msg.noData": "No data",
  "ccm.querycustdata.msg.selectTable": "Select a table name",

  "ccm.agent.updateSoftPhone.queryNodeFailed": "Failed to query tenant node information.",
  "ccm.agent.updateSoftPhone.modifyLocalPwd": "Failed to change the local softphone password.",
  "ccm.agent.updateSoftPhone.newPasswordFailed": "Failed to verify the new password.",
  "ccm.agent.updateSoftPhone.accountPasswordFailed": "Failed to verify the account password.",
  "ccm.agent.updateSoftPhone.updateGatewayFailed": "Failed to change the gateway password.",
  "ccm.agent.updateSoftPhone.updateFailed": "Failed to change password",
  "ccm.agent.updateSoftPhone.updateSuccess": "Password changed successfully.",
  "ccm.agent.updateSoftPhone.unFoundInfo": "The call center is not synchronized.",
  "ccm.agent.updateSoftPhone.unErrorInfo": "System Exception.",
  "ccm.agent.updateSoftPhone.updateOpertionSuccess": "Warning",
  "ccm.agent.updateSoftPhone.reason": "The reason",

  "ccm.agent.pageurls.pagetype": "Page type",
  "ccm.pageurls.select.externalInterface": "External page",
  "ccm.pageurls.select.internalInterface": "Internal page",

  "ccm.pageinterface.title": "Page Interface",
  "ccm.pageinterface.objectList": "Object List",
  "ccm.pageinterface.addObject": "Add Object",
  "ccm.pageinterface.objectName": "Object Name",
  "ccm.pageinterface.custTableName": "Underlying Table Name",
  "ccm.pageinterface.selectedAttributeNum": "Selected Object Attributes",
  "ccm.pageinterface.pleaseSelectAttribute": "Select an object attribute.",
  "ccm.pageinterface.interfaceName": "Interface name",
  "ccm.pageinterface.inputInterfaceName": "Enter",
  "ccm.pageinterface.interfaceType.internal": "Internal interface",
  "ccm.pageinterface.interfaceType.external": "External interface",
  "ccm.pageinterface.selectAttributeTitle": "Select Object Attribute",
  "ccm.pageinterface.pleaseSelectInterfaceType": "Select an interface type.",
  "ccm.pageinterface.saveSuccess": "The page interface set is saved successfully.",
  "ccm.pageinterface.optionalAttribute": "Optional Attribute",
  "ccm.pageinterface.selectedAttribute": "Selected Attribute",
  "ccm.pageinterface.viewAttribute": "View Object Attribute",
  "ccm.pageinterface.interfaceType": "Interface type",
  "ccm.pageinterface.interfaceType.externalExplain": "External interfaces are configured on the API Management page. Contact the system administrator.",
  "ccm.pageinterface.selectedAll": "Select All",
  "ccm.pageinterface.cancelAll": "Cancel All",
  "ccm.agent.callacceptno.set": "Set",
  "ccm.recognition.label.interconnection.parameters": "Interconnection parameters",
  "ccm.recognition.label.api.fabric.id": "API Fabric Id",
  "ccm.recognition.label.appSecret.download": "download SK",
  "ccm.recognition.label.appSecret.reset": "reset SK",
  "ccm.recognition.queryAK.error": "An error occurred when querying the APP key information.",
  "ccm.recognition.queryAK.not.exist": "The current tenant space does not have an app key.",
  "ccm.vcallcenter.config.popup.authentication": "Authenticate",
  "ccm.vcallcenter.config.popup.currentaccount.pwd": "Current account password",
  "ccm.vcallcenter.config.popup.authentication.failed": "Authentication failed.",
  "ccm.recognition.resetSK.success": "SK reset successfully.",
  "ccm.recognition.resetSK.failed": "Failed to reset the SK.",
  "ccm.recognition.label.appSecret.download.appId.empty.error": "The appId field cannot be empty.",
  "ccm.recognition.label.appSecret.download.querySK.error": "Failed to query the SK.",
  "ccm.recognition.label.appSecret.download.SK.not.exist": "The SK information does not exist.",
  "ccm.vcallcenter.config.popup.current.user.locked.error": "The current user is locked and will be unlocked 10 minutes later.",
  "ccm.recognition.label.appSecret.download.SK.only.once.error": "If you forget the key, reset the key and download it again.",
  "ccm.recognition.label.appSecret.download.SK.io.error": "Failed to download the SK. I/O exception.",

  "ccm.agent.pageurlparam.urlParamName": "Screen Pop-up URL Parameter",
  "ccm.agent.pageurlparam.relSysParamType": "Associated System Data Parameter Type",
  "ccm.agent.pageurlparam.calldata": "Call Data",
  "ccm.agent.pageurlparam.channelassociateddata": "Channel Associated Data",
  "ccm.agent.pageurlparam.relSysParamName": "Associated System Data Parameter",
  "ccm.agent.pageurlparam.paramIsConfiged": "The screen pop-up URL parameter or associated system data parameter already exists.",
  "ccm.agent.pageurlparam.checkSpecialChar": "Enter digits, letters, underscores (_), and hyphens (-). The dot (.) must be in the middle.",
  "ccm.agent.pageurlparam.checkSpecialChar.noPoint": "Enter digits, letters, underscores (_), and hyphens (-). The dot (.) must be in the middle",
  "ccm.agent.pageurlparam.checkSpecialChar.name": "Enter digits, letters, underscores (_), and hyphens (-)",
  "ccm.agent.pageurlparam.paramLengthLimit": "The parameter length cannot exceed 64 characters",
  "ccm.agent.pageurlparam.paramCountLimit": "A maximum of {limit} pop-up URL parameters can be configured.",
  "ccm.skillcreate.maxnumber.failed": "The number of tenant skill queues cannot exceed the upper limit: ",

  "ccm.agent.restReason.create": "Add",
  "ccm.agent.restReason.createReason": "Add Rest Reason",
  "ccm.agent.restReason.operation": "Operation",
  "ccm.agent.restReason.delete": "Delete",
  "ccm.agent.restReason.edit": "Edit Rest Reason",
  "ccm.agent.restReason.restCauseCode": "Rest Reason Code",
  "ccm.agent.restReason.confOnlyCauseCode": "Operation failed. The reason code already exists.",
  "ccm.agent.restReason.restCauseDesc": "Description",
  "ccm.agent.restReason.restCauseTime": "Rest Duration",
  "ccm.agent.restReason.restCauseTime.formate": "The rest duration must be in HH:MM:SS format.",
  "ccm.agent.restReason.restCauseTime.limit": "The rest duration must be greater than 0 seconds and less than 24 hours.",
  "ccm.agent.restReason.restCauseDesc.limit": "The rest reason description can contain a maximum of 100 characters.",
  "ccm.agent.restReason.restCauseDesc.limit.form": "The rest reason description can contain a maximum of 100 characters",
  "ccm.agent.restReason.selectRestReasons": "Select a rest reason.",
  "ccm.agent.restReason.selectRestReasons.limit": "A maximum of 10 rest reasons can be deleted in batches.",
  "ccm.agent.restReason.pleaseRefresh": "Synchronize and try again.",
  "ccm.agent.restReason.updateFailed": "Modification failed.",
  "ccm.agent.restReason.createFailed": "Adding failed.",
  "ccm.agent.restReason.partDeleteFailed": "Partial deletion failed.",
  "ccm.agent.restReason.isusing.warning": "Rest reason codes are in effect. This may cause the rest reason to be incorrectly displayed in the rest record.",

  "ccm.certificate.management.search.certcode": "Please enter Certificate Code",
  "ccm.certificate.management.search.scenename": "Please enter Certificate Scene",
  "ccm.certificate.management.certcode": "Certificate Name",
  "ccm.certificate.management.scenename": "Certificate Description",
  "ccm.certificate.management.type": "Certificate Type",
  "ccm.certificate.management.expiretime": "Expire Time",
  "ccm.certificate.management.updatetime": "Update Time",
  "ccm.certificate.management.passwd": "Certificate Password",
  "ccm.certificate.management.certificatefile": "Certificate File",
  "ccm.certificate.management.certificatecrlfile": "CRL File",
  "ccm.certificate.management.certificatefile.notupload": "Please upload cert file.",
  "ccm.certificate.management.certificatefile.parseerror": "Failed to parse the certificate. Check whether the certificate format or password is correct.",
  "ccm.certificate.management.certificatefile.certcodeexist": "The certificate code already exists.",
  "ccm.certificate.management.search.status": "Please choose Certificate Status",
  "ccm.certificate.management.status": "Certificate Status",
  "ccm.certificate.management.status.value.inuse": "In use",
  "ccm.certificate.management.status.value.discard": "Discard",
  "ccm.certificate.management.status.oper.reuse": "Reuse",
  "ccm.certificate.management.status.oper.discard": "Discard",
  "ccm.certificate.management.status.confirm.reuse": "Reuse Confirmation",
  "ccm.certificate.management.status.confirm.discard": "Abandonment Confirmation",
  "ccm.certificate.management.status.confirmReuse": "Are you sure you want to continue using this certificate?",
  "ccm.certificate.management.status.confirmDiscard": "Please check whether the certificate is not used in any service scenario. And discard this certificate?",
  "ccm.certificate.management.status.update.success": "Succeeded in changing the certificate status.",
  "ccm.certificate.management.status.update.failed": "Failed to change the certificate status.",
  "ccm.certificate.management.certificatefile.status.error": "The certificate has been discarded and cannot be modified.",
  "ccm.certificate.message.tip.warning": "The signature algorithm and key length of the certificate do not meet requirements, which is risky. Are you sure you want to continue?",
  "ccm.certificate.management.certificatefile.suffixerror": "Only files in jks,cer,crt,pem,pfx,p12 format can be uploaded.",
  "ccm.certificate.management.certificatecrlfile.suffixerror": "The Certificate Revocation List file must be in .crl format.",
  "ccm.certificate.management.certificatefile.suffixerror.pre": " type certificates can contain only files with the extension ",
  "ccm.certificate.management.certificatefile.suffixerror.suffix": "or empty extension.",
  "ccm.certificate.management.certificate.crlfile.sizeerror": "The CRL file cannot exceed 2048 KB.",
  "ccm.certificate.management.certificatefile.certfile.sizeerror": "The certificate file size cannot exceed 20 KB.",
  "ccm.certificate.management.certificatefile.maxrecord": "The maximum number of certificates is {0}.",
  "ccm.certificate.management.certificatefile.fileempty": "The file is empty.",
  "ccm.certificate.management.certificatefile.typeerror": "Incorrect file type.",
  "ccm.certificate.management.certificatefile.injectionerror": "The file has injection risks.",
  "ccm.certificate.management.certificatefile.uploadcertfile": "Upload Certificate File",
  "ccm.certificate.management.certificatefile.uploadcertcrlfile": "Upload CRL File",
  "ccm.certificate.management.certificate.crlfile.certhasexpired": "The certificate has expired.",
  "ccm.certificate.management.certificate.crlfile.crlfileinvalid": "Incorrect CRL file format.",
  "ccm.certificate.management.update.certfile": "Update Certificate File",
  "ccm.certificate.management.add.title": "Adding a Certificate",
  "ccm.certificate.management.update.title": "Updating Certificates",
  "ccm.certificate.management.update.crlfile": "Update CRL File",
  "ccm.certificate.management.update.cancel.certfile": "Are you sure you want to cancel the certificate update?",
  "ccm.certificate.management.update.cancel.crlfile": "Are you sure you want to cancel the crl file update?",
  "ccm.certificate.management.certificate.tip": "Tips:",
  "ccm.certificate.management.certificatefile.certcodeerror": "The cert code is error.",
  "ccm.certificate.management.certificatefile.certsceneerror": "The cert scene is error.",
  "ccm.certificate.management.certificatefile.certpwderror": "The cert password is error.",
  "ccm.certificate.management.certificatefile.certtypeerror": "The cert type is error.",
  "ccm.certificate.management.warn.selectcerttype": "Please select cert type.",

  "ccm.basicdata.msg.queryfail": "Failed to query the basic data.",
  "ccm.basicdata.msg.modifyfail": "Failed to modify the basic data.",
  "ccm.basicdata.msg.modifysuccess": "The basic data is modified successfully.",
  "ccm.basicdata.msg.deletefail": "Failed to delete the basic data.",
  "ccm.basicdata.msg.deletesuccess": "The basic data is deleted successfully.",
  "ccm.basicdata.msg.nodata": "No data",
  "ccm.basicdata.msg.nodatamodify": "No data needs to be modified.",
  "ccm.basicdata.msg.error": "Error",
  "ccm.basicdata.error.syserror": "System error. Contact the maintenance personnel",
  "ccm.basicdata.error.capacitylimit": "The data capacity has reached the upper limit {0}",
  "ccm.basicdata.error.firstlinenotmatch": "The first line in the imported file is inconsistent with that in the template",
  "ccm.basicdata.error.datatypenotmatch": "The data format is inconsistent with the field definition format",
  "ccm.basicdata.error.databaseerror": "The index data is duplicate. As a result, the data fails to be imported to the database in batches",
  "ccm.basicdata.error.suffix": ".",

  "ccm.note.config": "Ring Back Tone Configuration",
  "ccm.note.select": "Select An Ring Back Tone",
  "ccm.note.config.policy": "Agent Ring Back Tone",
  "ccm.note.config.agent.ring.back.tone": "Agent Ring Back Tone Configuration",
  "ccm.note.config.filepath": "Select Note File",
  "ccm.note.config.choosenote": "Please Select a Note File",
  "ccm.worknobeforevoice.config.choosenote": "Select a value for Voice Before Employee ID.",
  "ccm.worknoaftervoice.config.choosenote": "Select a value for Voice After Employee ID.",
  "ccm.note.config.policy.systemdefault": "System Default",
  "ccm.note.config.policy.custom": "Customization",
  "ccm.note.config.error": "Error",
  "ccm.note.config.save": "Save",
  "ccm.note.config.failed": "Configure Note Failed",
  "ccm.note.config.success": "Success",
  "ccm.note.config.configsuccess": "Configure Note Success.",
  "ccm.note.config.searchnotename": "Please Input Name",
  "ccm.note.config.notename": "Name",

  "ccm.recognition.label.anonymous": "Anonymous Outbound Call Flag",
  "ccm.recognition.label.openanonymoussuccess": "Successfully enabled the Anonymous Outbound Call Flag. Please select a click-to-call access code configured for the called route.",
  "ccm.recognition.label.closeanonymoussuccess": "Successfully disabled the Anonymous Outbound Call Flag",
  "ccm.recognition.label.clicktocallaccesscode": "Click-to-call Access Code",
  "ccm.recognition.label.calledroutesuccess": "Successfully configured The called route",
  "ccm.anonymous.message.fail.feature": "The feature auth of anonymous outbound call is abnormal",
  "ccm.anonymous.message.fail.open": "Failed to open the flag. Check the CTI configuration",
  "ccm.anonymous.message.fail.close": "Failed to close the flag. check the CTI configuration",
  "ccm.anonymous.message.fail.notmatch": "The feature flag does not match the current status",
  "ccm.anonymous.message.fail.paranull": "The access code in parameter is empty",
  "ccm.anonymous.message.fail.cti": "Failed to query the anonymous outbound call flow on CTI",
  "ccm.anonymous.message.fail.save": "Failed to save the called route. Please check the CTI configuration",
  "ccm.anonymous.message.fail.delete": "Failed to delete the called route. Please check the CTI configuration",
  "ccm.satisfactionlevel.message.savesuccess": "Satisfaction level saved successfully.If a satisfaction process has been configured, release it again in Flow Management. Otherwise, the satisfaction result will be incorrectly displayed. The new satisfaction survey will be based on the results of this configuration.",
  "ccm.satisfactionlevel.message.savefail": "Failed to save the satisfaction level.",
  "ccm.satisfactionlevel.message.resetsuccess": "Satisfaction level reset successfully.If a satisfaction process has been configured, release it again in Flow Management. Otherwise, the satisfaction result will be incorrectly displayed. The new satisfaction survey will be based on the results of this configuration.",
  "ccm.satisfactionlevel.message.resetfail": "Failed to reset the satisfaction level.",
  "ccm.satisfactionlevel.message.satislevel": "Satisfaction Level",
  "ccm.satisfactionlevel.message.satistip": "Ensure that the satisfaction levels used in the satisfaction survey of each channel are the same. such as voice satisfaction survey, SMS satisfaction survey, and web satisfaction survey.",
  "ccm.satisfactionlevel.message.nopara": "The description cannot be empty.",
  "ccm.satisfactionlevel.message.samedesc": "Duplicate description exists. Please modify it.",
  "ccm.satisfactionlevel.message.leastcalltype": "Please select at least one call type.",
  "ccm.agent.contact.isExistTalkReason": "Call Reason Set or Not",
  "ccm.agent.callreason.need": "Please select at least one call reason.",
  "ccm.agent.callreason.modify.success": "Modifying the call reason successfully.",
  "ccm.agent.callreason.modify.failed": "Modifying the call reason failed.",
  "ccm.agent.callreason.delete.confirm": "Are you sure you want to delete the call reason?",
  "ccm.common.label.yes": "Yes",
  "ccm.common.label.no": "No",
  "ccm.common.label.error": "Error",
  "ccm.label.contactexport.selectrange": "Select Range",
  "ccm.label.contactexport.selectdata": "Select Data",
  "ccm.label.contactexport.selectall": "All",
  "ccm.label.contactexport.curpage": "Current page",
  "ccm.label.contactexport.exception": "An error occurred when generating the export task. Check the logs.",
  "ccm.label.contactexport.parainvalid": "Failed to verify the export conditions.",
  "ccm.label.contactexport.parainnull": "Select the data to be exported.",
  "ccm.label.chatRecord.channeltypeinnull": "Select channel types.",
  "ccm.transtask.label.task.chatrecordall.uniqueness": "You cannot add all channel types because tasks exist for some channel types.",
  "ccm.transtask.label.task.chatrecord.uniqueness": "Tasks exist for some selected channel types.",
  "ccm.label.contactexport.exceedlimit1": "A maximum of {0} data records can be exported",
  "ccm.label.contactexport.exceedlimit2": ". Narrow down the data time range or add data filter criteria.",
  "ccm.label.contactexport.nocount": "No contact record meets the conditions.",
  "ccm.label.dualcallexport.nocount": "There are no double call logs that meet the conditions.",
  "ccm.operlog.contactexport.download": "Download contact records",

  "ccm.contactitemconfig.label.contact.recorddata": "Contact Record Data Item",
  "ccm.contactitemconfig.label.contact.extended.information.dataset": "Extended Contact Information Dataset",
  "ccm.contactitemconfig.label.data.item.name": "Data Item Name",
  "ccm.contactitemconfig.label.data.item.identification": "Data Item ID",
  "ccm.contactitemconfig.label.data.item.category": "Data Type",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility": "Visible Contact Record",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.yes": "Yes",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.no": "No",
  "ccm.contactitemconfig.label.data.item.sequence.number": "No.",
  "ccm.contactitemconfig.label.data.extended.set": "Source Dataset",
  "ccm.contactitemconfig.label.data.extended.dataset.mapping.field": "Dataset Mapping Field",
  "ccm.contactitemconfig.label.data.extended.writeback.extended.field": "Extended Writeback Field",
  "ccm.contactitemconfig.label.data.extended.Type": "Data Type",
  "ccm.contactitemconfig.label.data.extended.Type.String": "String",
  "ccm.contactitemconfig.label.data.extended.Type.DataDictionary": "Data dictionary",
  "ccm.contactitemconfig.label.data.extended.dictionary.value": "Data Dictionary Value",
  "ccm.contactitemconfig.label.data.item.basic": "Basic data",
  "ccm.contactitemconfig.label.data.item.extended": "Extension data",
  "ccm.contactitemconfig.label.data.item.basicext": "Basic extended data",
  "ccm.contactitemconfig.label.data.extended.dataset.name": "Dataset Name",
  "ccm.contactitemconfig.label.data.extended.dataset.source.interface": "Dataset Source Interface",
  "ccm.contactitemconfig.message.init.basicdata.success": "The basic data is initialized successfully.",
  "ccm.contactitemconfig.message.confOnlyDataItemName": "Operation failed because the data item name already exists.",
  "ccm.contactitemconfig.message.confOnlyDataItemCode": "Operation failed because the data item ID already exists.",
  "ccm.contactitemconfig.message.confOnlyDatasetName": "Operation failed because the dataset name already exists.",
  "ccm.contactitemconfig.message.DatasetName.inuse": "The dataset cannot be deleted because it is already configured in the contact record data item.",
  "ccm.contactitemconfig.message.itemValidate": "The first letter must be a letter, underscore (_), or dollar sign ($). Other letters can be underscore (_), dollar sign ($), letters, or digits.",

  "ccm.agent.contact.pictures": "pictures",

  "ccm.agent.message.videoNotEnough": "The number of video resources is insufficient because both video agents and agents configured with video skill queues occupy video resources. Allocate video resources properly.",

  "ccm.callreasonconfig.message.deletecallreason.failed": "Deleted failed.",
  "ccm.callreasonconfig.message.deletecallreason.used": "Deleted failed, the call reason has been used.",
  "ccm.callreasonconfig.message.deletecallreason.success": "Deleted successfully.",
  "ccm.common.label.success": "Successful",

  "ccm.agent.message.agenttypebothempty": "An agent type is not configured for the selected agent. You need to configure the agent type.",

  "ccm.agent.button.batchconfig": "Batch Configure",
  "ccm.agent.button.config": "Configure",
  "ccm.agent.button.cancelconfig": "Cancel Configuration",
  "ccm.agent.message.cancelconfig": "Cancel Configuration",
  "ccm.agent.message.willcancelconfig": "If you cancel the configuration, it will be unavailable for the agents who are using the configuration. Are you sure you want to cancel the configuration?",
  "ccm.agent.callreason.exceedmaxnum": "A maximum of five call reasons are allowed.",

  "ccm.agent.clear.message.agentnotexist": "Failed to cancel the configuration because the agent does not exist.",
  "ccm.agent.message.agentnotexist": "The agent does not exist. Refresh the Agent Management page and try again.",
  "ccm.agent.message.agenttypeinvalid": "The agent type is invalid.",

  "ccm.basicpage.not.sk.download": "No SK download permission is available.",

  "ccm.label.contactexport.selectdatarange": "Data Scope",
  "ccm.label.contactexport.selectdatafield": "Export Field",
  "ccm.label.contactexport.currentfield": "Field in the current list",
  "ccm.label.contactexport.customfield": "Custom field",

  "ccm.transserver.label.edit": "Resource Dump Server",
  "ccm.transserver.label.servername": "Server Name",
  "ccm.transserver.label.servertype": "Type",
  "ccm.transserver.label.serveraddr": "Address",
  "ccm.transserver.label.obs": "OBS server",
  "ccm.transserver.label.objobs": "OBS",
  "ccm.transserver.label.obsaddr": "OBS Address",
  "ccm.transserver.label.view": "View",
  "ccm.transserver.delete.success": "The dump server is deleted successfully.",
  "ccm.transserver.delete.fail": "Failed to delete the dump server.",
  "ccm.transserver.discard.confirm": "Are you sure you want to discard the task?",
  "ccm.transserverparam.label.info": "Basic Information",
  "ccm.transserverparam.label.bucket": "Data Bucket",
  "ccm.transserverparam.label.ak": "AK",
  "ccm.transserverparam.label.sk": "SK",
  "ccm.transserverparam.label.isagent": "Enable Proxy",
  "ccm.transserverparam.label.agentip": "Proxy Server Address",
  "ccm.transserverparam.label.agentport": "Port",
  "ccm.transserverparam.label.proxyUserName": "Username",
  "ccm.transserverparam.label.password": "Password",
  "ccm.transtask.label.taskedit": "Resource Dump Task",
  "ccm.transtask.label.taskid": "Task Id",
  "ccm.transtask.label.taskname": "Task Name",
  "ccm.transtask.label.tasktype": "Type",
  "ccm.transtask.label.taskupload": "Upload Server",
  "ccm.transtask.label.status": "Status",
  "ccm.transtask.label.start": "Start",

  "ccm.transtask.label.contactRecord": "Contact record",
  "ccm.transtask.label.chatRecord": "Multimedia chat data",
  "ccm.transtask.label.intelligentInspection": "AI inspection data",
  "ccm.transtask.label.cdrData": "CDR data",
  "ccm.transtask.label.voiceFile": "Voice file",
  "ccm.transtask.label.reportData": "Report data",
  "ccm.transtask.label.notEnabled": "Disabled",
  "ccm.transtask.label.discarded": "Discarded",
  "ccm.transtask.label.View": "View",
  "ccm.transtask.label.enable": "Enable",
  "ccm.transtask.label.pause": "Pause",
  "ccm.transtask.label.discard": "Discard",
  "ccm.transtask.label.restores": "Restore",
  "ccm.transtask.label.cantDelete": "The server cannot be deleted because it is already referenced by a dump task.",
  "ccm.transtask.label.delete": "The dump task is deleted successfully.",
  "ccm.transserver.delete.time.fail": "Delete the task 10 minutes after it is discarded.",
  "ccm.transtask.label.cantsave": "The server cannot be modified because it has been referenced by a dump task.",
  "ccm.transtask.label.savefail": "Failed to add a dump server because a maximum of {0} dump servers are allowed.",
  "ccm.transtask.label.saveexit": "Failed to add a dump server because the server name already exists.",
  "ccm.transtask.label.datatype": "Dump Data Type",
  "ccm.transtask.label.dataname": "Dump Server Name",
  "ccm.transtask.label.datapath": "Dump File Path",
  "ccm.transtask.label.datapath.tips": "OBS path",
  "ccm.transtask.label.dumpinterval": "Dump Duration",
  "ccm.transtask.label.dumpintervalInitial": "Dump Duration Start",
  "ccm.transtask.label.reportedir": "Dump Report",
  "ccm.transtask.label.reports": "Dump Report",
  "ccm.transtask.label.contactrcd": "Contact Record Dump",
  "ccm.transtask.label.chatrecordinf": "Multimedia Chat Data Dump Setting",
  "ccm.transtask.label.chatrecordday": "Chat Data Dump Duration (Day)",
  "ccm.transtask.label.datacondition": "Data Filter Criteria",
  "ccm.transtask.label.exportdata": "Export data field:",
  "ccm.transtask.label.contactrcddata": "Fields in contact record list",
  "ccm.transtask.label.contactchoose": "Customized fields in contact record",
  "ccm.transtask.label.cancel": "Cancel",
  "ccm.transtask.label.save": "OK",
  "ccm.transtask.label.day": "Day",
  "ccm.transtask.label.week": "Week",
  "ccm.transtask.label.month": "Month",
  "ccm.transtask.label.vdnReport": "VDN Traffic Report",
  "ccm.transtask.label.vdnAccessCodeReport": "VDN Traffic Report by Access Code",
  "ccm.transtask.label.ivrReport": "IVR Data Report",
  "ccm.transtask.label.ivrAccessCodeReport": "IVR Data Statistics Report by Access Code",
  "ccm.transtask.label.skillReport": "Skill Queue Traffic Report",
  "ccm.transtask.label.skillAccessCodeReport": "Skill Queue Traffic Report by Access Code",
  "ccm.transtask.label.agentReport": "Agent Performance Summary Report",
  "ccm.transtask.label.agentOutBoundReport": "Agent Outbound Call Summary Report",
  "ccm.transtask.label.agentOperationReport": " Agent Connection Operation Report",
  "ccm.transtask.label.abandonedRingReport": "Summary Report on Calls Abandoned During Ringing",
  "ccm.transtask.label.monday": "Monday",
  "ccm.transtask.label.tuesday": "Tuesday",
  "ccm.transtask.label.wednesday": "Wednesday",
  "ccm.transtask.label.thurs": "Thursday",
  "ccm.transtask.label.friday": "Friday",
  "ccm.transtask.label.saturday": "Saturday",
  "ccm.transtask.label.sunday": "Sunday",
  "ccm.transtask.label.taskfail": "Failed to create the task failed because a maximum of {0} tasks of the contact record type are allowed.",
  "ccm.transtask.label.tasknameexits": "Failed to create the task because the task name already exists.",
  "ccm.transtask.config.pathvalidate": "The dump file path cannot contain './''.",

  "ccm.transtask.label.skillSummaryReport": "Traffic Summary Report",
  "ccm.transtask.label.task.uniqueness": "Only one report data task can be created at the same dump interval for the same dump data type.",
  "ccm.transtask.label.task.otheruniquene": "Only one voice file, AI inspection data or CDR data type task can be created for the same dump data type.",
  "ccm.transtask.label.updateexsit": "Operation failed because the server name already exists.",

  "ccm.transserver.label.css.edit": "Cloud Search Server Configuration",
  "ccm.transserver.label.css.serveraddr": "CSS Address",
  "ccm.transserverparam.label.cssClusterPass": "Password",
  "ccm.transserver.label.css": "CSS server",
  "ccm.transserver.label.cloudsearchserver.css": "CSS Configuration",
  "ccm.transserver.label.cssaddr": "CSS service address",
  "ccm.transserver.label.cssServerPort": "CSS service port",
  "ccm.transserverparam.label.login.cssClusterUser": "Cluster login user name",
  "ccm.transtask.label.isOpenCssServer": "Specifies whether to enable CSS.",
  "ccm.transtask.label.certTip.proxyCert": "Please select a proxy server Cert.",
  "ccm.transtask.label.certTip.cssCert": "Please Select a CSS Cert.",
  "ccm.transserver.delete.css.confirm": "Are you sure you want to delete the CSS configuration?",
  "ccm.transserver.delete.css.success": "CSS configuration deleted successfully.",
  "ccm.transserver.delete.css.fail": "Failed to delete the CSS configuration information.",

  "ccm.agent.ten.timezone": "Time Zone",
  "ccm.agent.ten.timezoneOffset": "Time Zone Offset",
  "ccm.agent.ten.daylightSavingTime": "DST",
  "ccm.agent.ten.DSTOffset": "DST Offset (min)",
  "ccm.agent.ten.DSTStartDate": "DST Start Date",
  "ccm.agent.ten.DSTStartTime": "DST Start Time",
  "ccm.agent.ten.DSTEndDate": "DST End Date",
  "ccm.agent.ten.DSTEndTime": "DST End Time",
  "ccm.agent.ten.systemdefault": "Default time zone",
  "ccm.agent.ten.dstOn": "Enabled",
  "ccm.agent.ten.dstOff": "Disabled",
  "ccmanagement.tenantspace.option.month_1": "January",
  "ccmanagement.tenantspace.option.month_2": "February",
  "ccmanagement.tenantspace.option.month_3": "March",
  "ccmanagement.tenantspace.option.month_4": "April",
  "ccmanagement.tenantspace.option.month_5": "May",
  "ccmanagement.tenantspace.option.month_6": "June",
  "ccmanagement.tenantspace.option.month_7": "July",
  "ccmanagement.tenantspace.option.month_8": "August",
  "ccmanagement.tenantspace.option.month_9": "September",
  "ccmanagement.tenantspace.option.month_10": "October",
  "ccmanagement.tenantspace.option.month_11": "November",
  "ccmanagement.tenantspace.option.month_12": "December",
  "ccmanagement.tenantspace.option.weeklist_fir": "First",
  "ccmanagement.tenantspace.option.weeklist_sec": "Second",
  "ccmanagement.tenantspace.option.weeklist_tid": "Third",
  "ccmanagement.tenantspace.option.weeklist_fth": "Fourth",
  "ccmanagement.tenantspace.option.weeklist_fthtolast": "Last but three",
  "ccmanagement.tenantspace.option.weeklist_tidtolast": "Last but two",
  "ccmanagement.tenantspace.option.weeklist_sectolast": "Last but one",
  "ccmanagement.tenantspace.option.weeklist_firtolast": "Last",
  "ccmanagement.tenantspace.option.weekday_sun": "Sunday",
  "ccmanagement.tenantspace.option.weekday_mon": "Monday",
  "ccmanagement.tenantspace.option.weekday_tue": "Tuesday",
  "ccmanagement.tenantspace.option.weekday_wed": "Wednesday",
  "ccmanagement.tenantspace.option.weekday_thu": "Thursday",
  "ccmanagement.tenantspace.option.weekday_fri": "Friday",
  "ccmanagement.tenantspace.option.weekday_sat": "Saturday",

  "ccm.taskrecord.label.executeBeginTime": "Start Time",
  "ccm.taskrecord.label.executeEndTime": "End Time",
  "ccm.taskrecord.label.executeStatus": "Task Status",
  "ccm.taskrecord.label.executeSuccess": "Successful",
  "ccm.taskrecord.label.executeFail": "Failed",
  "ccm.taskrecord.label.executing": "Executing",
  "ccm.taskrecord.label.reexecute": "Re-execute",
  "ccm.taskrecord.label.choosetask": "Select Task",
  "ccm.taskrecord.message.reexecutetranstasksuccess": "Re-execution successful.",
  "ccm.taskrecord.message.reexecutetranstaskerror": "Re-execution failed.",
  "ccm.transserver.delete.confirm": "Are you sure you want to delete the server?",
  "ccm.transtask.delete.confirm": "Are you sure you want to delete the dump task and historical task records?",

  "ccm.agent.message.existDualchannelrecAgent": "Intelligent recognition and dual-track recording cannot be enabled at the same time, whereas dual-track recording has been enabled for some of the agents to be modified in batches.",
  "ccm.agent.message.existAIAgent": "Intelligent recognition and dual-track recording cannot be enabled at the same time, whereas intelligent recognition has been enabled for some of the agents to be modified in batches.",

  "ccm.agent.contact.preemptionCallout": "Preempted outbound call",
  "ccm.agent.sysparamconfig.emailMaxRecipientsNoCheck": "The maximum number of recipients forwarded by mail agents must be between 1 and 50",

  "ccm.systemparam.success.save": "Parameter saved successfully.",
  "ccm.systemparam.error.save": "Failed to save parameters.",
  "ccm.systemparam.success.reset": "Parameter reset successfully.",
  "ccm.systemparam.error.reset": "Failed to reset parameters.",
  "ccm.systemparam.success.refresh": "Parameter updated successfully.",
  "ccm.systemparam.error.refresh": "Failed to refresh parameters.",
  "ccm.systemparam.error.notmatchrule": "The parameter does not meet the verification rule.",
  "ccm.systemparam.error.noparamselected": "Please select a parameter first.",
  "ccm.sysparam.config.search": "querying",
  "ccm.sysparam.config.reset": "reset",
  "ccm.sysparam.config.resetAbb": "reset",
  "ccm.sysparam.config.refresh": "refresh",
  "ccm.sysparam.config.itemname": "Parameter Name",
  "ccm.sysparam.config.value": "Parameter Value",
  "ccm.sysparam.config.itemdesc": "Description",
  "ccm.sysparam.config.opterate": "Operation",
  "ccm.sysparam.config.save": "Save",
  "ccm.sysparam.config.mod": "Edit",
  "ccm.sysparam.config.checkmessage": "Contains special characters. (" < ", " > ", " / ", etc.)",
  "ccm.sysparam.config.itemname.tips": "Enter a parameter name.",
  "ccm.sysparam.config.select.prompt": "hints",
  "ccm.sysparam.config.select.null": "Please select a parameter.",
  "ccm.sysparam.config.select.count": "A maximum of 10 parameters can be selected.",
  "ccm.sysparam.config.item.detail": "Parameter information",
  "ccm.sysparam.config.item.catalog": "Parameter Type",
  "ccm.sysparam.config.title": "Tenant parameters",
  "ccm.sysparam.success.save": "Success",
  "ccm.sysparam.error.save": "Errors",
  "ccm.sysparam.config.reset.multiple.error": "The following parameters cannot be reset:",
  "ccm.sysparam.config.agent.title": "Agent parameters",

  "ccm.sysparam.error.validate.inner": "Validate parameter failed",
  "ccm.sysparam.error.validate.ruleinvalid": "The verification rule is invalid",
  "ccm.sysparam.error.validate.ruleregexinvalid": "Validate rule syntax error",
  "ccm.sysparam.error.validate.notmatchrule": "The input parameter does not match the verification rule",
  "ccm.sysparam.error.validate.notmatchruleregex": "The input parameter does not match the regular expression of the verification rule",
  "ccm.sysparam.error.validate.watermarkinfo.notmatchruleregex": "The parameter only allows 0 to 20 english characters containing uppercase and lowercase letters, numbers or -_/@.",
  "ccm.sysparam.error.validate.notmatchrulealpha": "Please enter alphanumerics or underscores, and only start with letters or underscores",
  "ccm.sysparam.error.validate.notmatchruledigits": "Please enter a valid positive integer",
  "ccm.sysparam.error.validate.notmatchruleemail": "Please enter a valid email address",
  "ccm.sysparam.error.validate.notmatchruleip": "Please enter a valid IPV4 or IPV6 address",
  "ccm.sysparam.error.validate.notmatchrulemaxlength": "Maximum parameter length",
  "ccm.sysparam.error.validate.notmatchrulemax": "The input parameter exceeds the maximum",
  "ccm.sysparam.error.validate.notmatchruleminlength": "The input parameter is less than the minimum length",
  "ccm.sysparam.error.validate.notmatchrulemin": "The input parameter is lower than the minimum",
  "ccm.sysparam.error.validate.notmatchrulenumber": "Please enter a number",
  "ccm.sysparam.error.validate.notmatchruleoption": "The input parameter is not in the enumeration range",
  "ccm.sysparam.error.validate.notmatchrulerangelength": "The input parameter exceeds the maximum length or is below the minimum length",
  "ccm.sysparam.error.validate.notmatchrulerange": "The input parameter exceeds the maximum value or is below the minimum value",
  "ccm.sysparam.error.validate.notmatchrulerequired": "Required fields",
  "ccm.sysparam.error.validate.notmatchruleurl": "Please enter a valid URL address",
  "ccm.sysparam.error.validate.ccme.alarmThreshold": "The alarm severity is inconsistent with the alarm threshold. The alarm threshold of low severity must be smaller than (equal to) the alarm threshold of high severity",
  "ccm.sysparam.error.validate.ccme.daysAlarmOverSync": "The input parameter must be less than the configured time (7 days by default) for migrating offline users to the history table",
  "ccm.calledconfig.message.overmaxnum": "The maximum quantity of {0} has been reached.",
  "ccm.calledconfig.message.samename": "The same name already exists.",
  "ccm.calledconfig.message.nullparent": "The parent node does not exist.",
  "ccm.ivrflow.placeholder.ivrflowdesc": "Please enter an ivr flow description.",

  "ccm.transserver.label.obsnotinwhite": "Operation failed. Contact the system administrator to add the OBS service address to the resource dump OBS server trustlist.",
  "ccm.transserver.label.proxynotinwhite": "Operation failed. Contact the system administrator to add the proxy server address to the proxy trustlist of the OBS server.",
  "ccm.urlconfig.message.talkurlnotinwhite": "Operation failed. Contact the system administrator to add the connection callback URL to the callback trustlist.",
  "ccm.urlconfig.message.relurlnotinwhite": "Operation failed. Contact the system administrator to add the hand-up callback URL to the callback trustlist.",
  "ccm.urlconfig.message.urlnotinwhite": "Operation failed. Contact the system administrator to add the URL to the trustlist.",

  "ccm.speciallist.title.specialList": "Special List",
  "ccm.speciallist.label.expirationDate": "Expiration Time",
  "ccm.speciallist.label.reason": "Reason for Joining",
  "ccm.speciallist.label.operTime": "Creation Time",
  "ccm.speciallist.label.operator": "Operator",
  "ccm.speciallist.label.blocklist": "Blocklist",
  "ccm.speciallist.label.redlist": "Redlist",
  "ccm.speciallist.label.line": "Line",
  "ccm.speciallist.msg.userNo": "Subscriber number",
  "ccm.speciallist.msg.sltype": "Special List Type",
  "ccm.speciallist.msg.success": "successful",
  "ccm.speciallist.msg.transferToHistory": "Transfer to History",
  "ccm.speciallist.msg.modify": "Modify",
  "ccm.speciallist.msg.delete": "Delete",
  "ccm.speciallist.msg.restores": "Restores",
  "ccm.speciallist.msg.successNum": "{limit} successful",
  "ccm.speciallist.msg.operationsuccess": "Operation succeeded",
  "ccm.speciallist.msg.error": "Errors",
  "ccm.speciallist.msg.fail": "failed",
  "ccm.speciallist.msg.errorreason": "Failure Cause",
  "ccm.speciallist.msg.errorNum": "{limit} failed",
  "ccm.speciallist.msg.crateerror": "Adding failed",
  "ccm.speciallist.msg.modifyerror": "Modification failed",
  "ccm.speciallist.msg.reviewerror": "Approval failed",
  "ccm.speciallist.msg.operationdoing": "Other reviewer are approving",
  "ccm.speciallist.msg.deleteyerror": "Failed to delete the special list",
  "ccm.speciallist.msg.crateerrorreason": "The subscriber number already exists",
  "ccm.speciallist.msg.transfererror": "Failed to transfer to history",
  "ccm.speciallist.msg.transferout": "The number of historical records has reached {0}",
  "ccm.speciallist.msg.willdelete": "Are you sure you want to delete these {limit} items?",
  "ccm.common.msg.willdelete": "Are you sure you want to delete the selected {limit} records?",
  "ccm.speciallist.msg.confirmdelete": "Are you sure you want to delete the selected special list?",
  "ccm.speciallist.msg.confirmdeletehis": "Are you sure you want to delete the selected special list history?",
  "ccm.speciallist.msg.selectspeciallist": "Please select a special list",
  "ccm.speciallist.msg.selectspeciallisthis": "Please select a special list history",
  "ccm.speciallist.msg.endtime": "The expiration date cannot be later than January 19, 2038",
  "ccm.speciallist.msg.endtimeerror": "The expiration time must be later than the current time",
  "ccm.speciallist.msg.tip": "Note: A maximum of 1000 records can be imported each time",
  "ccm.speciallist.msg.warning": "Warning",
  "ccm.speciallist.msg.selectonly": "Only one special list can be selected for modification",
  "ccm.speciallist.msg.besureexport": "Are you sure you want to export all data?",
  "ccm.speciallist.msg.restoreout": "A tenant space can store a maximum of 10,000 special list records",
  "ccm.speciallist.msg.exists": "The selected user is already included in the special list",
  "ccm.speciallist.msg.restoreerror": "Failed to restore the special list",
  "ccm.speciallist.msg.restoresuccess": "Restoration succeeded",
  "ccm.speciallist.msg.download": "Downloading the Special List Import Template",
  "ccm.speciallist.msg.import.template": "Special List Import Template",
  "ccm.speciallist.msg.limit": "The number of imported contents exceeds 1000",
  "ccm.speciallist.msg.maxlimit": "The maximum number of records exceeds {limit}",
  "ccm.speciallist.msg.size": "The size of the file to be imported exceeds 10 MB",
  "ccm.speciallist.msg.norecord": "No record can be exported",
  "ccm.speciallist.msg.operationerror": "Operation failed",
  "ccm.speciallist.msg.suffix": "The file name extension is incorrect",
  "ccm.speciallist.msg.maxCountQuery": "The current data volume is too large. The system displays only the latest {0} data records.",
  "ccm.speciallist.button.cancel": "Cancel",
  "ccm.speciallist.button.finish": "Finish",
  "ccm.speciallist.button.history": "Transfer to History",
  "ccm.speciallist.button.modify": "Modify",
  "ccm.levelMessage.title.modify": "Modify Level Management",
  "ccm.speciallist.title.modify": "Modify Special List",
  "ccm.speciallistreminder.button.modify": "Modify Special List Reminder",
  "ccm.speciallist.button.import": "Import",
  "ccm.speciallist.title.import": "Import Special List",
  "ccm.speciallist.button.add": "Add",
  "ccm.levelMessage.title.add": "Create Level Management",
  "ccm.speciallist.title.add": "Create Special List",
  "ccm.speciallistreminder.button.add": "Create Special List Reminder",
  "ccm.speciallisthis.operlog.downloadData": "Export Special List History",
  "ccm.speciallist.operlog.downloadData": "Exporting Special List Data",
  "ccm.speciallist.operlog.downloadtemplate": "Download Template",
  "ccm.datatask.checkNum": "The value is a positive integer.",
  "ccm.agent.ten.cobrowseMaxNumber": "Maximum number of web page collaboration connections",

  "ccm.nms.satisfactionservice.buttonNumber": "The number of satisfaction survey keys is not more than 5 and not less than 3.",

  "ccm.satisfactionservice.noevaluation": "Not evaluated",
  "ccm.satisfactionservice.evaluationerror": "Evaluation failed",
  "ccm.asr.create.case": "Create Case",
  "ccm.asr.create.case.info": "You can click Create Case only during a call or after a call ends.",
  "ccm.asr.create.case.chat.info": "You can click Create Case only during a chat or after a chat ends.",
  "ccm.asr.create.case.text.info": "One-click case creation is not supported because there is no text content.",

  "ccm.ucconfig.message.userId": " Directory (Tenant) ID",
  "ccm.ucconfig.message.clientId": " Application (Client) ID",
  "ccm.ucconfig.message.clientSecret": " Application (Client) Password",
  "ccm.ucconfig.message.title": " Registering Applications with the Microsoft Identity Platform",
  "ccm.ucconfig.message.integration": " Microsoft Teams Integration",
  "ccm.ucconfig.message.info.alert": "Please copy the following address to Microsoft for application registration as the Accept Authentication Redirect URI. Copy the registration result and fill in the following registration application information.",
  "ccm.ucconfig.message.copy": "Copy",
  "ccm.ucconfig.message.register": "Registering Application Information",
  "ccm.ucconfig.message.required.fields": "Required fields",
  "ccm.ucconfig.message.required.limit": "The length cannot exceed 64 characters.",
  "ccm.ucconfig.message.urlTitle": " Redirect URI configured to the Microsoft platform to accept authentication responses:",
  "ccm.ucconfig.message.success": "Success",
  "ccm.ucconfig.message.failed": "fail",
  "ccm.ucconfig.message.updatesuccess": "UC integration configuration saved successfully.",
  "ccm.ucconfig.message.updatefailed": "Failed to save the UC integration configuration.",

  "ccm.agent.label.authType": "Authentication Mode",
  "ccm.agent.label.authtype.uap": "UAP Authentication",
  "ccm.agent.label.authtype.unified": "Unified authentication",

  "ccm.agent.message.synToCcpFailed": "Failed to synchronize the agent authentication mode to the cc-provision.",
  "ccm.agent.message.redisFailed": "Failed to obtain the Redis lock.",
  "ccm.agent.message.synToUapFailed": "Failed to synchronize the agent authentication mode to the UAP.",
  "ccm.skillcreate.repeat.fail": "The skill queue name already exists.",

  "ccm.create.case.confirm": "The case type is empty. When creating case, the case type needs to be manually selected. Are you sure you want to continue?",
  "ccm.case.title.selectWorkOrder": "Selecting a work order category",
  "ccm.case.label.recommendedWorkOrder0": "Recommended TT Category 1",
  "ccm.case.label.recommendedWorkOrder1": "Recommended TT Category 2",
  "ccm.case.label.recommendedWorkOrder2": "Recommended TT Category 3",

  "ccm.recognition.label.policy.information": "Policy Information",
  "ccm.recognition.label.editPolicy.information": "Edit Policy Information",
  "ccm.agent.label.callDispatchModel": "Call Allocation Mode",
  "ccm.agent.label.restQueueRule": "Rest Queuing Policy",
  "ccm.agent.label.ifReleaseNoAnswerCall": "Release Calls Not Answered for a Long Period",
  "ccm.agent.label.ifBusyNoAnswerAgent": "Set Agents Who Do Not Answer for a Long Period to the Busy State",
  "ccm.agent.label.asyncUserDispatchMode": "Offline Message Retrieve Condition",
  "ccm.agent.label.asyncUserDispatchMode.zero": "by batch count",
  "ccm.agent.label.asyncUserDispatchMode.one": "by batch count and skill",
  "ccm.agent.label.asyncUserDispatchMode.two": "by batch count and online status",
  "ccm.agent.label.asyncUserDispatchMode.three": "by batch count,skill and online status",
  "ccm.agent.label.asyncUserDispatchMode.four": "by batch count,agent,skill and online status",
  "ccm.agent.label.callDispatchModel.best": "Optimal",
  "ccm.agent.label.callDispatchModel.incomingCall": "Incoming Call Skill Allocation Prior to Outgoing Call Skill Allocation",
  "ccm.agent.label.callDispatchModel.average": "Average",
  "ccm.agent.label.callDispatchModel.manySkills": "Agent with most skills",
  "ccm.agent.label.restQueueRule.applyFirst": "Agent who applies for a rest first",
  "ccm.agent.label.restQueueRule.lessRest": "Agent with the least rest on the current day",


  "ccm.agent.label.maxTalkTime": "Maximum call duration (s)",
  "ccm.agent.label.maxTalkTimeRange": "Value [1-86400]",
  "ccm.agent.label.busyRate": "Queue Usage (%)",
  "ccm.agent.label.busyRateRange": "Value [0-100]",
  "ccm.agent.label.conditionOfBusyTransfer": "Forwarding on Busy Condition",
  "ccm.agent.label.deviceUsage": "Device Usage (%)",
  "ccm.agent.label.waitTime": "Waiting duration (s)",
  "ccm.agent.label.deviceUsageThreshold": "Device Usage Threshold (%)",
  "ccm.agent.label.deviceUsageThresholdRange": "Value [0-100]",
  "ccm.agent.label.waitTimeRange": "Value [0-9999]",
  "ccm.agent.label.calcOfMaxCallNum": "Maximum call queuing data calculation method",
  "ccm.agent.label.fixedValue": "Fixed value",
  "ccm.agent.label.signedinAgentsPercentage": "Percentage of signed-in agents",
  "ccm.agent.label.queuingCallsMaxNum": "Maximum number of queuing calls",
  "ccm.agent.label.scaleFactor": "Scale Factor",
  "ccm.agent.label.queuingCallsMaxNumRange": "Value [0-10000]",
  "ccm.agent.label.scaleFactorRange": "Value [0-10000]",
  "ccm.agent.label.queuingCallsMaxNumRangeTwo": "Value [1-10000]",
  "ccm.agent.label.scaleFactorRangeTwo": "Value [1-10000]",

  "ccm.policy.label.updatesucess": "The policy information is updated successfully.",
  "ccm.policy.label.updateVerificationFailed": "Request parameter verification fails.",
  "ccm.policy.label.updateFailed": "Failed to update the policy information.",

  "ccm.agent.conditionOfBusyTransfer.error": "Configure the CFB condition parameters.",
  "ccm.agent.calcOfMaxCallNum.error": "Configure the maximum number of queuing calls.",
  "ccm.contact.detail.message.sending.record": "Message Sending Record",
  "ccm.contact.detail.sendmessage": "Send",
  "ccm.contact.detail.receiver": "Recipient",
  "ccm.contact.detail.sending.time": "Sending time",
  "ccm.contact.detail.view.message.content": "View",
  "ccm.contact.detail.message.content": "Message Content",
  "ccm.agent.export.agentinfo.tips": "The exported data contains personal data, which must be correctly used and protected.",
  "ccm.message.center.nocitiction.sending.records.status": "send status",

  "title.contact.agent.name": "Service account name",
  "ccm.contact.tip.not.get.workname": "The account name corresponding to the agent is not obtained\uFF01",
  "ccm.contact.tip.no.workname": "The corresponding agent is not obtained\uFF01",

  "ccm.common.ajax.searchErr": "Search error.",
  "ccm.dual.call.record.normal.end": "Normal End",
  "ccm.dual.call.record.unexpected.end": "Unexpected end",
  "ccm.dual.call.record.caller": "Caller Number",
  "ccm.dual.call.record.called": "Called Number",
  "ccm.dual.call.record.startTime": "Time when a call is initiated",
  "ccm.dual.call.record.endTime": "Call End Time",
  "ccm.dual.call.record.agentTalkingTime": "Calling Party Answer Time",
  "ccm.dual.call.record.talkingTime": "Called Answer Time",
  "ccm.dual.call.record.talkDuration": "Call Duration (s)",
  "ccm.dual.call.record.reasonCode": "Release Reason",
  "ccm.dual.call.record.option": "Operation",
  "ccm.operlog.dualcallexport.download": "Download dual call records",
  "ccm.dual.call.record.callCompletionRate": "Call completion rate",
  "ccm.dual.call.record.noMatchingDualCallRecordExists": "No matching dual call record exists.",
  "ccm.dual.call.record.dualCallType": "Dual Call Type",
  "ccm.dual.call.record.statistics": "Statistics",
  "ccm.sysparam.url.safe.tip": "HTTP is insecure and may cause security risks.",

  "ccm.agent.contact.multimedia.web": "Multimedia-Web",
  "ccm.agent.contact.multimedia.whatsapp": "Multimedia-Whatsapp",
  "ccm.agent.contact.multimedia.line": "Multimedia-Line",
  "ccm.agent.contact.multimedia.wechat": "Multimedia-WeChat",
  "ccm.agent.contact.multimedia.facebook": "Multimedia-Facebook ",
  "ccm.agent.contact.multimedia.twitter": "Multimedia-X (Twitter)",
  "ccm.agent.contact.multimedia.fiveG": "Multimedia-5G RCS",
  "ccm.agent.contact.multimedia.email": "Multimedia-Email",
  "ccm.agent.contact.multimedia.instagram": "Multimedia-Instagram",
  "ccm.agent.contact.multimedia.telegram": "Multimedia-Telegram",

  "ccm.agent.label.answerType4": "Report employee ID and customized voices",
  "ccm.agent.label.preVoiceSelect": "Voice Before Employee ID",
  "ccm.agent.label.afterVoiceSelect": "Voice After Employee ID",
  "ccm.agent.resetskillrecord.adjustbusinessaccount": "Adjust Service Account",
  "ccm.agent.resetskillrecord.adjustedbusinessaccount": "Adjusted Service Account",
  "ccm.agent.resetskillrecord.adjustagentworkno": "Adjusting Agent ID",
  "ccm.agent.resetskillrecord.adjustedagentworkno": "Adjusted Agent ID",
  "ccm.agent.resetskillrecord.adjusttime": "Adjust Time",
  "ccm.agent.resetskillrecord.skillbeforeadjust": "Skill Queue Before Adjustment",
  "ccm.agent.resetskillrecord.skillafteradjust": "Skill Queue After Adjustment",
  "ccm.agent.resetskillrecord.adjustresult": "Adjust Result",
  "ccm.callreason.label.createrelatecase": "Relate Cases",
  "SM.ORGSTAFF.LABEL.ORGANIZATION": "Organization Unit",

  "ccm.idauth.title.idauthconfig": "Identity Authentication Process",
  "ccm.idauth.title.name": "Authentication Mode Name",
  "ccm.idauth.title.ivr": "Authentication Process",
  "ccm.idauth.title.create": "Add Identity Authentication Process",
  "ccm.idauth.title.edit": "Modify Identity Authentication Process",
  "ccm.idauth.create.error.name": "The authentication mode exists.",
  "ccm.idauth.create.error.limit": "A maximum of five identity authentication processes can be added.",
  "ccm.idauth.title.code": "Authentication Mode Code",
  "ccm.idauth.create.error.format": "The authentication mode code can contain only digits, letters, and underscores (_).",
  "ccm.idauth.create.error.length": "The length of the authentication mode code exceeds the upper limit.",

  "ccmanagement.satisfactionconfig.acceptdate.timeerror.laterThanEndTime": "The start date must be earlier than the end date.",
  "ccmanagement.satisfactionconfig.calltime.timeerror.laterThanEndTime": "The minimum call duration must be less than the maximum call duration.",
  "ccmanagement.satisfactionconfig.acceptdata.timeVerification": "Select a date later than the current time and earlier than 2038-01-19.",
  "ccmanagement.satisfaction.strategy.fail.unknown": "Saving failed.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnull": "The minimum or maximum call duration cannot be empty.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputlength": "The minimum or maximum call duration can contain a maximum of 12 digits.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnumber": "The value must be a positive integer.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnagetive": "The minimum or maximum call duration cannot be less than 0.",
  "ccmanagement.satisfaction.strategy.fail.calltime.startafterend": "The minimum call duration must be less than the maximum call duration.",
  "ccmanagement.satisfaction.strategy.fail.calltime.overlimit": "A maximum of 100 call duration policies can be saved.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnull": "The start or end time cannot be empty.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputlength": "The length of the start or end time cannot exceed the upper limit.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnumber": "The start or end time must be a number.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputparse": "The start or end time is in an incorrect format.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.startafterend": "The start time must be earlier than the end time.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.overlimit": "A maximum of 100 handling time policies can be saved.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnull": "The start or end date cannot be empty.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnumber": "The start or end date is in an incorrect format.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputparse": "The start or end date is in an incorrect format.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.startafterend": "The start date must be earlier than the end date.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.overlimit": "A maximum of 100 handling date policies can be saved.",
  "ccmanagement.satisfaction.strategy.calltime.minvalue": "Minimum Call Duration (s)",
  "ccmanagement.satisfaction.strategy.calltime.maxvalue": "Maximum Call Duration (s)",
  "ccmanagement.satisfaction.strategy.save": "Save",
  "ccmanagement.satisfaction.strategy.delete": "Delete",
  "ccmanagement.satisfaction.strategy.calltime.interval": "Call Duration Range",
  "ccmanagement.satisfaction.strategy.operation": "Operation",
  "ccmanagement.satisfaction.strategy.calltime.lengthlimit": "The minimum or maximum call duration can contain a maximum of 12 digits.",
  "ccmanagement.satisfaction.strategy.deleteselect": "Are you sure you want to delete the selected policies?",
  "ccmanagement.satisfaction.strategy.calltime.second": "s",
  "ccmanagement.satisfaction.strategy.addsuccess": "Saving successful.",
  "ccmanagement.satisfaction.strategy.success": "Success",
  "ccmanagement.satisfaction.strategy.fail": "Failure",
  "ccmanagement.satisfaction.strategy.saveerror": "Failure",
  "ccmanagement.satisfaction.strategy.deletesuccess": "Deletion successful.",
  "ccmanagement.satisfaction.strategy.deletefail": "Deletion failed.",
  "ccmanagement.satisfaction.strategy.accepttime.timeStart": "Start Time",
  "ccmanagement.satisfaction.strategy.accepttime.accepttime": "Handling Time",
  "ccmanagement.satisfaction.strategy.accepttime.hour": "hour",
  "ccmanagement.satisfaction.strategy.accepttime.minute": "minute",
  "ccmanagement.satisfaction.strategy.accepttime.timeEnd": "End Time",
  "ccmanagement.satisfaction.strategy.hourformatvalidate": "The hour is in an incorrect format.",
  "ccmanagement.satisfaction.strategy.hourvalidate": "The hour cannot be greater than 24.",
  "ccmanagement.satisfaction.strategy.acceptdate.dateStart": "Start Date",
  "ccmanagement.satisfaction.strategy.acceptdate.dateEnd": "End Date",
  "ccmanagement.satisfaction.strategy.acceptdate.startdate": "Start Date",
  "ccmanagement.satisfaction.strategy.acceptdate.enddate": "End Date",
  "ccmanagement.satisfaction.strategy.minutevalidate": "The minute is in an incorrect format.",

  "ccm.satisfactionconfig.message.duplicateconfig": "The priority of each policy for the same channel must be unique.",
  "ccm.satisfactionconfig.message.numberlimit": "A maximum of 100 satisfaction survey channel configurations are allowed in a tenant space.",
  "ccm.satisfactionconfig.message.channelused": "The policy group is referenced by a channel and cannot be deleted.",
  "ccm.satisfactionconfig.message.smstitle": "SMS",
  "ccm.satisfactionconfig.message.smscloud": "Huawei Cloud SMS",
  "ccm.satisfactionconfig.message.smsgateway": "SMS Gateway",
  "ccm.satisfactionconfig.message.smschannel": "SMS Push Channel",
  "ccm.satisfactionconfig.message.smstemplate": "SMS Template",
  "ccm.satisfactionconfig.message.validtime": "Valid Reply Period (min)",
  "ccm.satisfactionconfig.message.strategygroup": "Satisfaction Survey Policy Group",
  "ccm.satisfactionconfig.message.strategy": "Satisfaction Survey Policy",
  "ccm.satisfactionconfig.message.groupcreate": "Add",
  "ccm.satisfactionconfig.message.groupbatchdelete": "Batch Delete",
  "ccm.satisfactionconfig.message.strategygroupsrv": "Satisfaction Survey Policy Channel Configuration",
  "ccm.satisfactionconfig.message.strategygroupid": "Policy Group ID",
  "ccm.satisfactionconfig.message.strategygroupname": "Policy Group Name",
  "ccm.satisfactionconfig.title.strategygroup": "Creating a Policy Group",
  "ccm.satisfactionconfig.message.strategygroupdesc": "Policy Group Description",
  "ccm.satisfactionconfig.message.strategygroupmember": "Policy",
  "ccm.satisfactionconfig.message.strategygroupmemconfig": "Policy Configuration",
  "ccm.satisfactionconfig.message.strategygroupmemchoose": "Select policies.",
  "ccm.satisfactionconfig.message.strategychanneltitle": "Configure Channel Policy",
  "ccm.satisfactionconfig.add.strategychanneltitle": "Creating a Channel Satisfaction Policy",
  "ccm.satisfactionconfig.update.strategychanneltitle": "Edit Channel Satisfaction Strategy",
  "ccm.satisfactionconfig.message.strategychannelid": "Channel Policy ID",
  "ccm.satisfactionconfig.message.channelstrategyconfig": "Channel Satisfaction Survey Policy",
  "ccm.satisfactionconfig.message.channeltype": "Channel Type",
  "ccm.satisfactionconfig.message.channelname": "Channel",
  "ccm.satisfactionconfig.message.grouppriority": "Priority",
  "ccm.satisfactionconfig.message.surveytype": "Survey Type",
  "ccm.satisfactionconfig.message.surveystrategygroup": "Survey Policy",
  "ccm.satisfactionconfig.message.audiochannel": "Voice or video channel",
  "ccm.satisfactionconfig.message.webchannel": "Web multimedia channel",
  "ccm.satisfactionconfig.message.audiofeedbacktype": "IVR voice",
  "ccm.satisfactionconfig.message.smsfeedbacktype": "SMS",
  "ccm.satisfactionconfig.message.webfeedbacktype": "Web",
  "ccm.satisfactionconfig.message.nofeedbacktype": "No survey",
  "ccm.satisfactionconfig.message.operate": "Operation",
  "ccm.satisfactionconfig.message.delete": "Delete",
  "ccm.satisfactionconfig.message.update": "Edit",
  "ccm.satisfactionconfig.message.comfirmtitle": "Confirm",
  "ccm.satisfactionconfig.message.comfirm": "Are you sure you want to delete the policy group?",
  "ccm.satisfactionconfig.message.choosestrategy": "*Select the satisfaction survey strategy",
  "ccm.satisfactionconfig.message.validatename": "The policy group name cannot be empty or duplicate.",
  "ccm.satisfactionconfig.message.calltypeout": "Outbound call",
  "ccm.satisfactionconfig.message.calltypein": "Inbound call",
  "ccm.satisfactionconfig.message.prioritytip": "*A smaller value indicates a higher priority. The policy with a higher priority is preferentially matched to execute the survey.",
  "ccm.satisfactionconfig.message.accepttime": "Handling Time",
  "ccm.satisfactionconfig.message.acceptdate": "Handling Date",
  "ccm.satisfactionconfig.message.acceptagent": "Handling Agent",
  "ccm.satisfactionconfig.message.callskill": "Skill Queue",
  "ccm.satisfactionconfig.message.calltype": "Call Type",
  "ccm.satisfactionconfig.message.calltime": "Call Duration",
  "ccm.satisfactionconfig.message.creategroup": "Add Policy Group",
  "ccm.satisfactionconfig.message.configstrategydata": "Configure Policy Data",
  "ccm.satisfactionconfig.message.memberstrategychoose": "Available Policies",
  "ccm.satisfactionconfig.message.strategychoosed": "Selected Policies",
  "ccm.satisfactionconfig.message.memberstrategyconfig": "Strategy group member configuration",
  "ccm.satisfactionconfig.message.validatemaxnumber": "A maximum of 100 policy groups can be created.",

  "ccm.note.config.agent.ring.setUp": "Prompt Information Configuration",
  "ccm.note.config.agent.page.ring": "Ringtones",
  "ccm.note.config.agent.page.ring.audition": "Audition",
  "ccm.note.config.agent.page.ring.choose": "Select a ringtone.",
  "ccm.note.config.agent.page.ring.title": "This configuration applies only when the page is hidden, such as when the browser is minimized, or when the page is tabbed in the background.",
  "ccm.note.config.agent.page.ring.tipTitle": "If a dialog box is configured, the dialog box is valid only after the agent manually configures Allow this website to use pop-up windows on the browser during connection integration.",
  "ccm.note.config.agent.page.ring.warn": "You are advised to perform a call test to prevent the ringtone from overlapping with the ringtone configured on the phone.",
  "ccm.note.config.agent.page.windowTip": "Pop-up dialog box reminder",
  "ccm.note.config.agent.page.ring.select": "Select ringtone",
  "ccm.note.config.agent.page.ring.setUp": "Page Message Notification Configuration",
  "ccm.note.config.agent.page.ring.voice": "Audio and video incoming call notification",
  "ccm.note.config.agent.page.ring.media": "Multimedia incoming call notification",
  "ccm.note.config.agent.page.ring.newMedia": "New multimedia message notification",

  "ccm.contact.customer.config.label": "Contact Customer Information Area",
  "ccm.contact.customer.config.type": "Contact customer information area data",
  "ccm.contact.customer.config.visible": "Visible",
  "ccm.contact.customer.config.writeBack": "Write Back Contact Record",
  "ccm.contact.customer.config.style": "Display Style",
  "ccm.contact.customer.config.key": "Data Key",
  "ccm.contact.customer.config.value": "Data Value",
  "ccm.contact.customer.config.foregroundColor": "Foreground Color",
  "ccm.contact.customer.config.backgroundColor": "Background Color",
  "ccm.contact.customer.config.fonts": "Font",
  "ccm.contact.customer.config.regular": "Regular",
  "ccm.contact.customer.config.bold": "Bold",
  "ccm.contact.customer.config.italic": "Italic",
  "ccm.contact.customer.config.underline": "Underlined",
  "ccm.contact.customer.config.preview": "Preview",
  "ccm.contact.customer.config.datasourceType": "Data Source Type",
  "ccm.contact.customer.config.datasource.callData": "Call-associated data",
  "ccm.contact.customer.config.datasource.msgDataset": "Information dataset",
  "ccm.contact.customer.config.datasource.customData": "Local data",
  "ccm.contact.customer.config.datasource.objId": "Data Source Object",
  "ccm.contact.customer.config.datasource.objName": "Data Source Object Mapping Field",
  "ccm.contact.customer.config.writeBackField": "Extended Contact Record Writeback Field",
  "ccm.contact.customer.config.writeBackFieldError": "The extended contact record writeback field cannot be in use.",
  "ccm.contact.customer.config.dataItemCodeError": "Data Item ID is incorrect.",
  "ccm.contact.customer.config.dataItemError": "The data item name or ID must be unique.",
  "ccm.contact.customer.config.referenced": "The data item has been referenced by the contact customer information area.",
  "ccm.contact.record.data.referenced": "The data item has been referenced by the contact record data item.",

  "ccm.verifyidentity.noMoreThan30Days": "The time range cannot exceed 30 days.",
  "ccm.verifyidentity.loginId": "Business Account",
  "ccm.verifyidentity.workNo": "Agent ID",
  "ccm.verifyidentity.callId": "Call SN",
  "ccm.verifyidentity.userId": "Customer Number",
  "ccm.verifyidentity.verifyName": "Authentication Mode",
  "ccm.verifyidentity.verifyTime": "Authentication Time",
  "ccm.verifyidentity.verifyResult": "Authentication Result",
  "ccm.verifyidentity.NOTRETURN": "Not returned",
  "ccm.verifyidentity.SUCCESS": "Pass",
  "ccm.verifyidentity.FAIL": "Fail",
  "ccm.verifyidentity.ERROR": "Error",
  "ccm.verifyidentity.fail.unknown": "Query failed",
  "ccm.verifyidentity.fail.WORKNOPARSEERROR": "The agent ID must be a number.",
  "ccm.verifyidentity.fail.CALLIDPARSEERROR": "The call SN must be a number.",
  "ccm.verifyidentity.fail.USERIDPARSEERROR": "The customer number cannot contain special characters.",
  "ccm.verifyidentity.fail.VERIFYRESULTPARSEERROR": "Incorrect authentication result parameter.",
  "ccm.verifyidentity.fail.TIMEPARAMNULLERROR": "The query time range cannot be empty.",
  "ccm.verifyidentity.fail.INPUTNULLERROR": "The query parameters cannot be empty.",
  "ccm.verifyidentity.fail.VERIFYTIMERANGEERROR": "Incorrect query time range.",
  "ccm.verifyidentity.fail.LIMITOVERLIMIT": "The value of the pagination query parameter exceeds the upper limit.",
  "ccm.verifyidentity.label": "Identity Authentication",

  "ccm.transserver.label.cssnotinwhite": "Operation failed. Contact the system administrator to add the CSS service address \",to the resource dump CSS server trustlist.",
  "ccm.transserver.label.cssproxynotinwhite": "Operation failed. Contact the system administrator to add the proxy server \",address to the proxy trustlist of the CSS server.",

  "ccm.whatsapp.template.message.id": "Template Message ID",
  "ccm.whatsapp.template.message.name": "Message Name",
  "ccm.whatsapp.whatsApp.channel": "WhatsApp Channel",
  "ccm.whatsapp.whatsApp.message.template": "WhatsApp Template",
  "ccm.whatsapp.select.whatsApp.message.template": "Please select a WhatsApp template message.",
  "ccm.whatsapp.select.whatsApp.channel": "Please select a WhatsApp channel.",
  "ccm.agent.mobileagent.syserror": "Failed to enable intelligent recognition. Contact the system administrator to check environment configurations.",

  "ccm.agent.contact.info": "Document Information",
  "ccm.agent.contact.name": "Document Name",
  "ccm.agent.contact.searchName": "Document Name",
  "ccm.agent.contact.status": "Document Status",
  "ccm.agent.contact.signaturedate": "Signature Date",
  "ccm.agent.contact.updatedate": "Update Date",
  "ccm.agent.contact.invalid": "Invalidation",
  "ccm.agent.contact.preview": "Preview",
  "ccm.contact.label.unsigned": "Unsigned",
  "ccm.contact.label.signed": "Signed",
  "ccm.contact.label.inactive": "Expired",
  "ccm.contact.msg.inactivesuccess": "Invalidation succeeded.",
  "ccm.contact.msg.dataverification": "Failed to verify the request parameter.",
  "ccm.contact.msg.dataEmpty": "The operation data does not exist in the AICC.",
  "ccm.operlog.contactfile.download": "Download File",
  "ccm.contract.management.confirm": "The document contains sensitive data. Are you sure you want to continue?",
  "ccm.contract.management.confirmtitle": "Confirm Operate",
  "ccm.contract.management.invalid.confirm": "Are you sure you want to invalidate this document?",

  "ccm.agent.label.notreply": " Can't reply",
  "ccm.agent.contact.itacall": "ITA bidirectional call",

  "ccm.chatbot.pop.title": "Choosing a robot",
  "ccm.chatbot.pop.table.botname": "Robot Name",
  "ccm.chatbot.label.assist": "Robot assistant",
  "ccm.chatbot.invalid.empty": "The robot assistant cannot be empty.",
  "ccm.chatbot.invalid.bot": "This robot is invalid",
  "ccm.chatbot.query.name.fail": "Failed to obtain the robot name.",
  "ccm.chatbot.query.list.fail": "Failed to obtain the robot list.",

  "ccm.transtask.label.expirationDuration": "Dump file retention period",

  "ccm.agent.speciallist.validityPeriod": "Effective Period",
  "ccm.agent.speciallist.begintime": "Effective Time",
  "ccm.agent.speciallist.endtime": "Expiration Time",
  "ccm.agent.speciallist.levelName": "Special List Level",
  "ccm.speciallist.label.startDate": "Effective Time",
  "ccm.speciallist.msg.necessary": "The user number and special list type are mandatory.",
  "ccm.agent.speciallist.addList": "Join the Harassment Record",
  "ccm.speciallist.msg.levelName": "level",
  "ccm.speciallevel.speciallist.use": "Available",
  "ccm.speciallevel.speciallist.unuse": "Unavailable",
  "ccm.speciallevel.speciallist.restrictMode": "Restriction mode",
  "ccm.speciallevel.speciallist.restrictHours": "Restricted Duration (Hour)",
  "ccm.speciallevel.speciallist.remark": "Remark",
  "ccm.speciallevel.speciallist.levelName": "Level Name",
  "ccm.speciallevel.speciallist.levelMessage": "Level Management",
  "ccm.speciallevel.speciallist.level": "Level",
  "ccm.speciallevel.tip.start": "Are you sure you want to enable it?",
  "ccm.speciallevel.tip.stop": "Are you sure you want to disable it?",
  "ccm.speciallist.msg.userNum": "User number",
  "ccm.speciallist.msg.userno.error": "The format of the subscriber number is incorrect.",
  "ccm.speciallist.msg.dateerror": "You need to enter the effective time and expiration time.",

  "ccm.agent.title.specialListReminder": "Special list reminder",
  "ccm.speciallist.msg.level": "Special list level",
  "ccm.speciallist.msg.typeName": "Special list type",
  "ccm.speciallist.label.msgContent": "Reminder Message",
  "ccm.speciallist.msg.empty": "The reminder message cannot be null.",
  "ccm.speciallist.msg.overlimit": "The length of the reminder message exceeds 256 characters.",
  "ccm.speciallist.msg.selectslreminder": " Select a special list reminder.",
  "ccm.speciallist.msg.selectonlyslreminder": "Can modify only one special list reminder.",
  "ccm.speciallist.msg.deleteerror": " Failed to delete the special list reminder.",
  "ccm.speciallist.msg.typeidempty": " special list type cannot be empty.",
  "ccm.speciallist.msg.specialchar": "The reminder message cannot contain special characters.",
  "ccm.speciallist.msg.alreadyexist": "Already has a special list reminder of the same type and level.",

  "ccm.agent.message.selectMaxAgents": "You can process a maximum of 20 at a time.",

  "ccm.speciallist.msg.approve": "Approval",
  "ccm.speciallist.label.approve": "Approval comments",
  "ccm.speciallist.poptitle.approve": "Approving Harassment Records",
  "ccm.speciallist.poptitle.view": "Viewing Harassment Records",
  "ccm.speciallist.title.approve": "Approval Information",
  "ccm.speciallist.msg.view": "View",
  "ccm.speciallist.msg.applyTimeFrom": "Application Start Time",
  "ccm.speciallist.msg.applyTimeTo": "Application End Time",
  "ccm.speciallist.msg.applyTime": "Application Time",
  "ccm.botherlist.label.contactrecorddetail": "Contact Details",
  "ccm.botherlist.label.comment": "Opinions",

  "ccm.botherlist.label.approveStatus.toApprove": "To be approved",
  "ccm.botherlist.label.approveStatus.approved": "Approved",
  "ccm.botherlist.label.approveStatus.rejected": "Rejected",

  "ccm.botherlist.label.approveComment.toApprove": "Agreed",
  "ccm.botherlist.label.approveComment.approved": "Reject",

  "ccm.agent.addbother.success": "Succeeded in create the bother record.",
  "ccm.agent.addbother.fail": "Adding failed.",
  "ccm.agent.contact.joinreason": "Reason for Joining",
  "ccm.agent.contact.callserialno": "Call Serial No",
  "ccm.agent.contact.contactinfo": "Contact Info",
  "ccm.agent.contact.createbother": "Create Bother Record",

  "ccm.speciallist.tip.timeVerification": "Select a time later than the current time and earlier than 2038-01-19.",
  "ccm.speciallist.msg.datecompareerror": "The expiration time cannot be later than the effective time.",


  "ccm.speciallevel.msg.selectspecialLevel": "Please select a special level",
  "ccm.botherlist.label.approveComment.agree": "Agree",
  "ccm.botherlist.label.approveComment.reject": "Reject",
  "ccm.agent.contact.callinandcallout": "Incoming and outgoing calls",
  "ccm.speciallevel.msg.deleteyerror": "Failed to delete the special list level.",

  "ccm.agent.addbother.msg.emailOrPhoneValidate": "Incorrect format.",
  "ccm.ccagent.management.advanced.search": "Advanced Search",
  "ccm.ccagent.management.batch.operation":"Batch operation",
  "ccm.ccagent.management.additions":"Add",
  "ccm.ccagent.management.default.options":"Default Options",
  "ccm.ccagent.management.cannot.exceed.25":"The number cannot exceed 25 characters.",
  "ccm.ivrvoice.play.error":"Loading resource exception, please check related configuration.",
  "ccm.contact.details.tab.title.audio":'Voice',
  "ccm.contact.details.tab.title.video":'Video',
  "ccm.agent.contact.transfercontent": 'ASR Content',
  "ccm.agent.leavemessage.opinionempty": "Audit comments is mandatory.",
  'ccm.contact.placeholder.searchCustomerNo': 'Contact number',
  "ccm.agent.label.oneday": '1 Day',
  "ccm.agent.label.threedays": '3 Days',
  "ccm.agent.label.sevendays": '7 Days',
  "ccm.agent.label.thirtydays": '30 Days',
  "ccm.contactitemconfig.message.noBaseData":"No basic data is available",

  "ccm.label.downloadAttachment.confirm":"Are you sure you want to download the selected attachment?",
  "ccm.contact.detail.talk.content.seachtips":"Search results: {num}",
  "ccm.contact.detail.talk.content.not.support":"The search text cannot contain\"/()",
  "ccm.contact.detail.talk.content.seach.remark":"Only text chat content displayed on this page can be searched",
  "ccm.contact.detail.talk.content.seach.remark.emaile":"Only messages that have been displayed on the search page can be searched. After the search is complete, only matched messages are displayed.",
  "ccm.download.passwd.validator.specialStr": "Only the following special characters are allowed: _{'@'}{'%'}",
  "ccm.login.record.noMatchingLoginRecordExists": 'No available sign-in log.',

  "ccm.agent.message.searchSkillQueueName": "Skill Queue Name",
  "ccm.agent.button.skill.synchronize": "Synchronize",
  "ccm.skillgroup.label.groupid": "Skill Group ID",
  "ccm.skillgroup.label.groupname": "Skill Group Name",
  "ccm.skillgroup.message.groupBind": "The current skill cannot be selected for the agent type bound to the skill group.",
  "ccm.skillgroup.label.orgname": "Organization",
  "ccm.agent.label.skillgroup": "Skill Group",
  "ccm.skillgroup.label.searchname": "Skill Group Name",
  "ccm.skillgroup.label.groupskills": "Associating Skill Queues",
  "ccm.skillgroup.title.add": "Adding Skill Group",
  "ccm.skillgroup.title.update": "Update Skill Group",
  "ccm.agent.message.skillweight.invalid": "The skill weight must range from 1 to 300.",
  "ccm.agent.message.agentweight.invalid": "The agent weight must range from 1 to 100.",
  "ccm.agent.message.skillweight.outlimit": "The weight value must range from 1 to 100.",
  "ccm.skillgroup.message.noskill": "The skill group skill list cannot be empty.",
  "ccm.skillgroup.msg.deleteerror": "Please select the skill group to be deleted.",
  "ccm.skillgroup.create.fail": "Failed to create the skill group.",
  "ccm.skillgroup.message.skillgroup": "Please enter a skill group name.",
  "ccm.skillgroup.message.nameValidateFail": "The skill group cannot contain the following special characters: [<>\"()]&=;,",
  "ccm.skillgroup.message.namePrefixSuffix": "The skill group cannot start or end with a space",
  "ccm.skillgroup.message.nameLimit": "The skill group name is too long.",
  "ccm.skillgroup.message.nameExistence": "The skill group name already exists.",
  "ccm.skillgroup.update.fail": "Failed to update the skill group.",
  "ccm.skillgroup.query.fail": "Failed to get skill group settings.",
  "ccm.skillgroup.update.failtip": "Failed to update the skill group. Synchronize the skill queue and skill group data first.",
  "ccm.agent.download.forbidden": "No download permission.",
  "ccm.skillgroup.create.failtip": "Failed to create the skill group. Synchronize the skill queue and skill group data first.",
  "ccm.skillgroup.create.limit": "A maximum of 2000 skill groups can be created.",
  "ccm.skillgroup.msg.deleteTips": "The current skill group has been associated with agent {0}. Remove the association and then delete it.",
  "ccm.title.org.all": "Please select an organization",
  'ccm.title.template.select.email': 'Select Email Template',
  'ccm.title.template.select.sms': 'Select SMS Template',
  "ccm.sendmsg.whatsapp.withoutTemplate": "No content. Select a template first.",
  "ccm.skillgroup.msg.deleteFailed": "Failed to delete the skill group.",
  "ccm.agent.label.skillType": "Skill Type",
  "ccm.agent.label.selectSkillGroup": "Select Skillset",
  "ccm.skill.label.defaultskill": "Default Skill Queue",
  "ccm.agent.leavemessage.overtime":"Processing timed out. Please open the pop-up window again.",
  "ccm.notifition.sendmessage.smsconfignull":"The SMS message configuration is empty. Choose Configuration Center > Access Configuration > SMS Configuration to configure SMS information.",

  "iolp.label.basicInfo": "Basic Info",
  "iolp.label.workflow": "Workflow",
  "iolp.workflow.node.title": "Succeeded in setting node attributes",
  "iolp.workflow.line.title": "Succeeded in setting line attributes",
  "iolp.workflow.node.label.name": "Name",
  "iolp.workflow.node.label.nodeType": "Node Type",
  "iolp.workflow.node.label.nodeId": "Node ID",
  "iolp.workflow.node.label.executeType": "Execution Type",
  "iolp.workflow.node.label.autoNodeInfo": "Next Node ID",
  "iolp.workflow.node.label.executeContent": "Execution Content",
  "iolp.workflow.node.label.explain": "Explanation",
  "iolp.workflow.node.label.associateKbs": "Associated Knowledge",
  "iolp.workflow.node.label.associateBusiness": "Associated Service Handling Link",
  "iolp.workflow.node.label.hintTextInfo": "Information",
  "iolp.workflow.node.label.sms": "SMS Template",
  "iolp.workflow.node.label.associateJs": "Associated JS Function",
  "iolp.workflow.nodetype.andsign": "Task",
  "iolp.workflow.nodetype.fork": "Branch",
  "iolp.workflow.nodetype.andjoin": "Combination",
  "iolp.workflow.executetype.common": "Common",
  "iolp.workflow.executetype.SMS": "SMS",
  "iolp.workflow.executetype.email": "Email",
  "iolp.workflow.executetype.IVR": "Trans IVR",
  "iolp.workflow.executetype.auto": "Auto",
  "iolp.workflow.executetype.subFlow": "Sub Flow",
  "iolp.workflow.executetype.case": "Create Case",
  "iolp.configur.service.label.basicInfo": "Basic Info",
  "iolp.configur.service.label.workflow": "Guided Process",
  "iolp.configur.service.label.directory.name": "Category Name",
  "iolp.configur.service.label.name": "Name",
  "iolp.configur.service.label.workflow.name": "Process Name",
  "iolp.configur.service.label.serviceCode": "Service Code",
  "iolp.configur.service.label.upperLevelServiceCode": "Upper-level Service Code",
  "iolp.configur.service.label.imageUrl": "Image Url",
  "iolp.configur.service.label.customizedPageUrl": "Customized Page URL",
  "iolp.configur.service.label.customizedPageContainerId": "Top container ID on the customized page",
  "iolp.configur.servicelabel.shortcutButtonPage": "Shortcut Button Page",
  "iolp.configur.service.label.shortcutButtonContainerId": "Top container ID on the shortcut button page",
  "iolp.configur.service.label.search.tips": "Please enter a name",
  "iolp.configur.service.add": "New",
  "iolp.configur.service.operate.edit": "Edit",
  "iolp.configur.service.operate.delete": "Delete",
  "iolp.configur.service.tips.customizedPageUrl": "For example: CustomerInfoDemo.html",
  "iolp.configur.service.add.select.title": "Selected Category Name",
  "iolp.configur.service.add.parent.title": "Parent Category Name",
  "iolp.configur.service.dialog.root.directory.title": "No Parent Category",
  "iolp.configur.service.add.operate.title": "New Type",
  "iolp.configur.service.dialog.add.directory.title": "Create Service Guidance Category",
  "iolp.configur.service.dialog.modify.directory.title": "Edit Service Guidance Category",
  "iolp.configur.service.dialog.add.workflow.title": "Create Service Guidance Process",
  "iolp.configur.service.dialog.modify.workflow.title": "Edit Service Guidance Process",
  "iolp.configur.servicelabel.label.serialNumber": "Serial Number",
  "iolp.configur.servicelabel.label.status": "Status",
  "iolp.configur.servicelabel.label.description": "Description",
  "iolp.configur.servicelabel.button.saveDraft": "Save as Draft",
  "iolp.configur.servicelabel.status.draft": "Draft",
  "iolp.configur.servicelabel.button.publish": "Published",
  "iolp.configur.servicelabel.common.tips.error": "Error",
  "iolp.configur.servicelabel.common.tips.success": "Success",
  "iolp.tree.tips": "You cannot add any node under a leaf node.",
  "iolp.configur.servicelabel.save.successful": "Save successful.",
  "iolp.configur.servicelabel.release.successful": "Publish successful.",
  "iolp.configur.servicelabel.save.failed": "Saving failed.",
  "iolp.configur.servicelabel.release.failed": "Failed to publish.",
  "iolp.configur.servicelabel.contact.administrator": "Failed to generate the service code. Please contact the administrator.",
  "iolp.configur.servicelabel.select.node": "Select a node.",
  "iolp.configur.servicelabel.confirm.delete": "Delete",
  "iolp.configur.servicelabel.confirm.deleteAll": "Are you sure you want to delete the current node and its subnodes?",
  "iolp.configur.servicelabel.confirm.deleteCurrent": "Are you sure you want to delete the current node?",
  "iolp.configur.servicelabel.delete.successful": "Deleted successfully.",
  "iolp.configur.servicelabel.delete.failed": "Deletion failed.",
  "iolp.configur.servicelabel.tips.info": "Information",
  "iolp.workflow.label.graphZoomOut": "Zoom Out",
  "iolp.workflow.label.graphZoomIn": "Zoom In",
  "iolp.workflow.label.previous": "Cancel",
  "iolp.workflow.label.next": "Recovery",
  "iolp.workflow.label.cleanGraph": "Clear Canvas",
  "iolp.workflow.label.taskElement": "Function Diagram Element",
  "iolp.workflow.label.decisionElement": "Branch Diagram Element",
  "iolp.workflow.label.operation": "Operation",
  "iolp.workflow.label.information": "Information",
  "iolp.workflow.label.unmatchedFlow": "The corresponding service cannot be matched",
  "iolp.workflow.button.restart": "Restart Flow",
  "iolp.workflow.button.hideFlowChart": "Hide Flowchart",
  "iolp.workflow.button.showFlowChart": "Show Flowchart",
  "iolp.workflow.label.currentNodeName": "Current Node Name",
  "iolp.workflow.label.customizeInfo": "Customize Info",
  "iolp.workflow.label.flowInfo": "Information and Explanation",
  "iolp.workflow.button.kbs": "Knowledge Base",
  "iolp.workflow.button.service": "Business Handling",
  "iolp.workflow.title.name": "Business Process",
  "iolp.configur.service.label.service.title": "Service Category",
  "iolp.configur.service.label.none.desc": "No service category configuration is available. Create a service category first.",
  "iolp.run.service.label.customizedPage": "Customization Information",
  "iolp.run.service.label.overview": "Process Overview",
  "iolp.run.service.label.nodeName": "Node Name: ",
  "agentconsole.ccnotification.recipient.validate.error": "Each number can contain only digits and a maximum of 20 digits.",
  "ccm.sendwhatsapp.content.too.long": "The template content is too long.",
  "ccm.sendwhatsapp.body.too.long": "The body content is too long.",
  "ccm.speciallist.tip.time.max": "Please select a time earlier than the project expiration time (2038-01-19)",
  "ccm.satisfactionconfig.message.textmsg": "Text Message",
  "ccm.speciallist.tip.startTime.min": "The effective time must be later than the current time.",
  "ccm.custdata.operlog.specCharValidate": "Cannot contain special characters except _ @ %",
  "ccm.calledconfig.message.casetype": "Case Type",
  "ccm.satisfaction.message.resetconfirm": "Are you sure you want to reset the satisfaction level and satisfaction survey template? Each channel needs to use the reset satisfaction level and template content.",
  "ccm.contactitemconfig.message.itemValidateall": "Enter underscore (_), dollar sign ($), letters, or digits",
  "ccm.speciallist.msg.disable.fail": "Disabling failed",
  "ccm.speciallist.tip.endTime.min": "The expiration time must be later than the current time.",
  "ccm.satisfactiontemplate.title":"Satisfaction Survey Template Content Configuration",
  "ccm.satisfactiontemplate.icontitle": "Update template content by satisfaction level",
  "ccm.speciallist.msg.withoutauth": "You do not have the operation permission.",
  "ccm.sysparam.url.unsafe":"(unsafe)",
  "ccm.satisfaction.leveltips":"Note: All channels use the same satisfaction configuration.",
  "ccm.calledconfig.message.selectcasetype":"Select a case type",
  "ccm.notifition.sendmessage.largerthanlimit":"The number of recipients exceeds the maximum {n}.",
  "ccm.agent.callreason.must": "Call reason is mandatory.",
  "iolp.workflow.efficiency.analysis":"Efficiency Analysis",
  "iolp.configur.servicelabel.label.version": " Release Version",
  "iolp.configur.servicelabel.label.version.number": "Number of Versions",
  "iolp.configur.servicelabel.status.abandon": " Invalid",
  "iolp.configur.servicelabel.status.publish": " Release",
  "iolp.configur.servicelabel.status.unpublish": " Unpublished",
  "iolp.configur.service.operate.copy": " Replication",
  "iolp.configur.service.operate.publish": " Release",
  "iolp.configur.service.operate.cancel": " Cancel Publishing",
  "iolp.configur.servicelabel.publish.successful": " published successfully",
  "iolp.configur.servicelabel.publish.failed": "Failed to publish",
  "iolp.configur.servicelabel.cancel.successful": " release canceled successfully.",
  "iolp.configur.servicelabel.cancel.failed": "Failed to cancel",
  "iolp.configur.servicelabel.copy.successful": " copied successfully.",
  "iolp.configur.servicelabel.copy.failed": " Replication Failed",
  "iolp.configur.servicelabel.confirm.delete.flow": " Are you sure you want to delete the current version?",
  "iolp.process.utilization.efficiency.analysis":"Process utilization efficiency analysis",
  "iolp.flow.number.of.contacts":"Number of Contacts (pieces)",
  "iolp.flow.first.solution.rate":"First solution rate (%)",
  "iolp.flow.please.select.time.before.today":"Please select a time before today",
  "iolp.flow.the.interval.validate":"The interval is 1 day or 3 - 30 days.",
  "iolp.flow.analysis.date.time":"Date/Time",
  "ccm.empty.template": "The template does not exist. Check the notification template.",
  "ccm.empty.variables": "The template variable does not exist. Check the notification template.",
  "ccm.no.meeting.variables": "The template variable does not contain the Meeting_URL variable. Check the notification template.",
  "ccm.agent.meeting.failed": "The agent fails to create a conference.",
  "ccm.agent.link.failed": "The agent fails to generate the SMS link.",
  "ccm.agent.meeting.receiver.check.error": "The number of recipients cannot exceed 1.",
  "ccm.agent.contact.timeouthungup": "Timeout Shutdown",
  "ccm.agent.contact.skilltimeouthungup": "Transfer to skill queue upon timeout",
  "ccm.sms.meeting.link": "conference link SMS",
  "ccm.agent.sms.meeting.gateway.commonerror": "Failed to create the conference. Error code: {n}",
  "ccm.agent.sms.meeting.gateway.error": "Failed to create the conference. Error code: {n}. Do not create the conference in mute, hold, or multi-party mode.",
  "ccm.contactRecordDetail.notSetTraceLogError": "No flow track is found. Add tracing settings and view the flow track of the corresponding contact record.",
  "ccm.agent.callreason.modify.exceeds.limit": "Failed to modify the call reason. The interval exceeds the validity period of the call reason.",
  "ccm.label.appcube.switch": "AppCube-Integrated Page",
  "ccm.label.appcube.app": "Select Application",
  "ccm.label.appcube.menu": "Select Menu",
  "ccm.contactitemconfig.label.data.item.sequence.number.seq": "Field Order",
  "ccm.contactitemconfig.label.data.item.add": "Create contact record field",
  "ccm.contactitemconfig.label.data.item.edit": "Edit contact record field",
  "ccm.contactitemconfig.label.data.item.tips": "The dataset source interface must be configured on the interface management page. Contact the system operation administrator.",
  "ccm.contactitemconfig.label.data.item.basic.item": "Basic extended field",
  "ccm.contactitemconfig.label.data.item.custom.item": "User-defined extended field",
  "ccm.contactitemconfig.label.dataInfo.item.add": "Create Data Item",
  "ccm.contactitemconfig.label.dataInfo.item.edit": "Edit Data Item",
  "ccm.custdata.label.data.table.template": "Data table template",
  "ccm.custdata.msg.uploadType": "Duplicate data import method",
  "ccm.custdata.msg.uploadTypeInfo": "If there is duplicate data\, if you choose to overwrite the original data\, the data record will be updated\, if you choose not to overwrite the original data\, it will be regarded as failed data",
  "ccm.custdata.msg.uploadType.cover": "Overwrite original data",
  "ccm.custdata.msg.uploadType.notcover": "Do not overwrite original data",
  "ccm.pageconfig.label.page": "Page",
  "ccm.pageconfig.label.publishpage": "Publish page",
  "ccm.transtask.label.intelligentInspectionConfig":"AI Inspection Data Dump Setting",
  "ccm.validate.portLengthError":"Port range should be 1-65535",
  "ccm.resource.dump.server.add":"Create a Resource Dump Server",
  "ccm.resource.dump.server.edit":"Edit Resource Dump Server",
  "ccm.resource.dump.server.view":"View Resource Dump Server",
  "ccm.resource.dump.css.add":"Create a Cloud Search Server",
  "ccm.resource.dump.css.edit":"Edit Cloud Search Server",
  "ccm.resource.dump.css.view":"View Cloud Search Server",
  "ccm.resource.dump.task.add":"Create a Resource Dump Task",
  "ccm.resource.dump.task.edit":"Edit a Resource Dump Task",
  "ccm.resource.dump.task.view":"View a Resource Dump Task",
  "ccm.transtask.label.intelligentInspectionDay":"AI Inspection Data Dump Duration",
  "ccm.transtask.css.server.num":"The number of Cloud Search Server cannot exceed 1.",
  "ccm.contact.customer.config.num":"The number of contact customer information column cannot exceed 10.",
  "ccm.export.password.error.tips": "The password of the compressed file contains 8 to 12 characters, including letters, digits, and special characters {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><.",
  "ccm.export.password.error.message": "The value must contain letters, digits, and special characters {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.agent.ccagentinfo.modifyselfphone.success":"Succeeded in modifying the fixed-line/mobile number",
  "ccm.agent.ccagentinfo.modifyselfphone.fail":"Failed to modify the fixed-line/mobile number",
  "ccm.export.table.taskTime": "Task Time",
  "ccm.export.table.taskStatus": "Task Status",
  "ccm.agent.contact.multimedia.sms":"Multimedia-SMS",
  "ccm.agent.label.skill":"Skill",
  "ccm.agent.button.randomRestSoftPhonePwd":"Reset the softphone password",
  "ccm.agent.label.skillGroup":"Skill Queue Group",
  "ccm.agent.message.searchSkillGroupName":"Name of skill queue group",
  "ccm.skill.label.skillGroupName":"Name of skill queue group",
  "ccm.agent.button.restSoftPhonePwd.remark":"If you reset agent softphone passwords to random passwords in batches\, agents must reset their own softphone passwords before using the softphone function. The administrator can also click Reset Softphone Password in the list to manually change the password for the agent.",
  "ccm.agent.management.exporting.agent.information":"Exporting Agent Information",
  "ccm.agent.clear.message.clearcommonerror":"Failed to cancel the configuration. The service is unavailable.",
  "ccm.contactitemconfig.label.dataInfo.item.name.exist": "Data Item Name already exists.",
  "ccm.agent.button.downloadvrc": "Download VRC",
  "ccm.dual.call.callback.url.config":"Callback URL Configuration",
  "ccm.dual.call.callback.url.auth":"Interface Invoking URL Authentication",
  "ccm.dual.call.callback.url.authType":"Callback URL Authentication Mode",
  "ccm.dual.call.callback.url.ita":"ITA Parameter Configuration",
  "ccm.dual.call.callback.url.xNumberType":"X Number Allocation Mode",
  "ccm.agent.management.select.agent":"Selecting Agents By Employee ID",
  'ccm.password.length.limit.min': 'The password is too short. Enter {min}-{max} characters',
  'ccm.password.length.limit.max': 'The password is too long. Enter {min}-{max} characters',
  "ccm.agent.operation.SCREENMONITOR": "Screen Monitoring",
  'ccm.mobile.agent.front.add': 'Creating a Mobile Agent',
  'ccm.mobile.agent.front.edit': 'Edit Mobile Agent',
  'ccm.mobile.agent.front.checkNum': 'Please select at least one number.',
  'ccm.mobile.agent.front.maxNum': 'The number cannot exceed 15.',
  'ccm.contactitemconfig.label.contactData.add': 'Creating a Contact Record Data Set',
  'ccm.contactitemconfig.label.contactData.edit': 'Editing a Contact Record Data Set',
  'ccm.agent.button.downloading':'Downloading...',
  'ccm.label.operationexport.nodata': 'No operation record meets the conditions.',
  'ccm.label.transferexport.nodata': 'No forwarding record meets the conditions.',
  'ccm.label.leaveexport.nodata': 'No rest record meets the requirements.',
  'ccm.label.send.whatsapp': 'Send Whatsapp',
  "ccm.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "The call center name cannot contain special characters",
  'ccm.common.validation.numbers': 'Please enter only digits',
  'ccm.common.validation.click.number': 'Please click on the number',
  'ccm.ccagent.agent.weight.tips': 'A larger value indicates a higher priority. When there are multiple idle agents, the system preferentially allocates incoming calls to the agent with a higher weight.',
  'ccm.ccagent.skill.weight.tips': 'A larger value indicates a higher priority. When there are calls in multiple queues, the system allocates the calls in the queue with a higher weight to the idle agent.',
  'ccm.ccagent.batch.modify.special.character.verification': 'cannot contain’',
  'ccm.resource.dump.obs.cert.warn.tip': 'Please Contact the O&M personnel to import the OBS trust certificate to the certificate library.',
  "ccm.audiovideoworkbench.mood" : "Customer Mood",
  "ccm.audiovideoworkbench.intention" : "Intention",
  "ccm.audiovideoworkbench.complain" : "Complain",
  "ccm.audiovideoworkbench.aiswitch" : "Large Language Model",
  "ccm.audiovideoworkbench.airecommendation" : "Intelligent Recommendation",
  "ccm.audiovideoworkbench.case.list" : "Case List",
}