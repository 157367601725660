export default {
  'ccivr.ivr.ivrflow.templateName': 'ชื่อเทมเพลต',
  'ccivr.ivr.tracelog.calledNumbererror': 'ไม่สามารถยืนยันหมายเลขโทรได้!',
  'ccivr.ivr.voice.scene': 'สถานการณ์การใช้งาน',
  'ccivr.nms.process.saveerror.noexistend': 'การบันทึกล้มเหลว มีองค์ประกอบสิ้นสุดแผนภาพอย่างน้อยหนึ่งรายการ',
  'ccivr.ivr.error.paramlimit': 'ความยาวรวมของพารามิเตอร์ต้องไม่เกิน 4000 bytes',
  'ccivr.nms.tansferservice.chooseSkill': 'เลือกลำดับทักษะ',
  'ccivr.nms.shortmessageservice.countryCode': 'รหัสประเทศ',
  'ccivr.nms.playset.open': 'เปิดใช้งาน',
  'ccivr.ivrflow.grayrule.beginnum': 'หมายเลขเริ่มต้น',
  'ccivr.ivr.voiceedit.editfail': 'แก้ไขล้มเหลว',
  'ccivr.nms.playcollectservice.videofile': 'ไฟล์วิดีโอ',
  'ccivr.nms.timeselectservice.monday': 'วันจันทร์',
  'ccivr.ivr.ivrflow.name': 'ชื่อ',
  'ccivr.ivr.voiceupload.sizewrong': 'ขนาดไฟล์เกิน 2 MB นำเข้าไฟล์อีกครั้ง',
  'ccivr.ivr.voiceupload.referenceFlow': 'ข้อมูลโฟลว์อ้างอิง',
  'ccivr.ivr.voiceupload.ivrVoiceUsage': 'ข้อมูลอ้างอิง',
  'ccivr.nms.conditionService.processLayout': 'การจัดกระบวนการ',
  'ccivr.ivrflow.unlock.confirm': 'ยืนยันสถานะการปลดล็อกในการแก้ไข?',
  'ccivr.nms.playservice.multivoice.type.number': 'จำนวน',
  'ccivr.nms.businessinvokeservice.value': 'ค่า',
  'ccivr.nms.tansferservice.transferLabor': 'โอนไปยังลำดับทักษะ',
  'ccivr.ivrflow.cancelrelease.referenced': 'โฟลว์ได้รับการอ้างอิงโดยรหัสการเข้าถึง',
  'ccivr.ivrflow.cancelrelease.referenced.by.satisfaction.survey': 'โฟลว์ถูกอ้างอิงโดยการกําหนดค่าการสํารวจความพึงพอใจและไม่สามารถลบได้',
  'ccivr.ivrflow.cancelrelease.referenced.by.idauth.survey': 'กระบวนการนี้ได้รับการอ้างอิงโดยการกําหนดค่าการตรวจสอบความถูกต้องและไม่สามารถยกเลิกการเผยแพร่ได้',
  'ccivr.ivr.bussinessinvoke.interfacename': 'ชื่ออินเทอร์เฟซ',
  'ccivr.nms.playservice.multivoice.language24': 'ประเทศไทย',
  'ccivr.nms.playservice.multivoice.language81': 'ภาษาไทย',
  'ccivr.nms.playservice.multivoice.language23': 'อุซเบกิสถาน',
  'ccivr.flowtest.hashangup': 'การทดสอบสิ้นสุดลงและการโทรจะถูกเผยแพร่ออกมา',
  'ccivr.nms.playservice.multivoice.language22': 'ตุรกี',
  'ccivr.nms.playservice.multivoice.language21': 'สวีเดน',
  'ccivr.nms.timeselectservice.saturday': 'วันเสาร์',
  'ccivr.nms.playservice.multivoice.language20': 'สเปน',
  'ccivr.nms.playset.malevoice': 'เสียงผู้ชาย',
  'ccivr.nms.timeselectservice.selectworktime': 'วันธรรมดา',
  'ccivr.ivr.voiceupload.deletefail': 'การลบล้มเหลว',
  'ccivr.ivr.voiceupload.fileovermax': 'จํานวนเสียง IVR ที่จะจัดการเกินจํานวนสูงสุด',
  'ccivr.ivr.voiceedit.choosefile': 'เลือกไฟล์',
  'ccivr.nms.satisfactionservice.isNull': 'พารามิเตอร์ความพึงพอใจว่างเปล่า',
  'ccivr.ivr.ivrflow.operate.release.fail': 'เผยแพร่ล้มเหลว',
  'ccivr.nms.playservice.multivoice.language19': 'สโลวัก',
  'ccivr.ivr.voiceupload.languagemanage': 'การจัดการภาษา',
  'ccivr.nms.playservice.multivoice.language18': 'รัสเซีย',
  'ccivr.nms.playservice.multivoice.language17': 'โปรตุเกส',
  'ccivr.nms.playservice.multivoice.language16': 'ขัด',
  'ccivr.flowtest.client': 'ลูกค้า',
  'ccivr.nms.playservice.multivoice.language15': 'นอร์เวย์',
  'ccivr.nms.playservice.multivoice.language14': 'เกาหลี',
  'ccivr.nms.playservice.multivoice.language13': 'ญี่ปุ่น',
  'ccivr.nms.playservice.multivoice.language12': 'อิตาลี',
  'ccivr.nms.leavemessageservice.playbi': 'เล่นเสียงบี๊บ',
  'ccivr.nms.playservice.multivoice.language11': 'ไอซ์แลนด์',
  'ccivr.nms.playservice.multivoice.language10': 'ฮังการี',
  'ccivr.ivr.voiceupload.vedioformatwrong': 'รูปแบบไฟล์ไม่ถูกต้อง นำเข้าไฟล์ 3GP',
  'ccivr.ivr.voiceedit.inputtext': 'ป้อนข้อความ',
  'ccivr.ivr.ivrflow.unreleased': 'ยังไม่ได้เผยแพร่',
  'ccivr.nms.SubFlowService.processjump': 'ข้ามไปที่',
  'ccivr.nms.businessinvokeservice.opertaion': 'การดำเนินการ',
  'ccivr.nms.playcollectservice.voiceprompts': 'พรอมต์เสียง',
  'ccivr.ivr.voiceupload.checkcallcenterparamfailed': 'การตรวจสอบพารามิเตอร์ Callcenter ล้มเหลว',
  'ccivr.ivr.tracelog.errorInfo': 'ข้อความผิดพลาด',
  'ccivr.ivr.voiceedit.sftpinfoisnull': 'ข้อมูล SFTP ของศูนย์บริการเป็นค่าว่าง',
  'ccivr.nms.queryservice.queueInfo': 'ข้อมูลลำดับจะถูกสอบถามก่อนที่จะโอนสายไปยังลำดับทักษะ',
  'ccivr.nms.queryservice.queueSpecialInfo': 'ข้อมูลรายการพิเศษใช้เพื่อสอบถามข้อมูลรายการพิเศษที่หมายเลขโทรเป็น',
  'ccivr.nms.queryservice.specialList.falg': 'ID ที่มีอยู่ในรายการพิเศษ',
  'ccivr.ivr.voiceupload.audioformat': 'ALAW\, 8bit\, 8000Hz\, 64 kbps\, mono',
  'ccivr.nms.interface.urltip': 'ใช้ URL ที่ขึ้นต้นด้วย HTTP หรือ HTTPS',
  'ccivr.ivr.voiceupload.pleaserecording': 'อัปโหลดไฟล์หลังจากบันทึก',
  'ccivr.ivrvoice.play.error': 'กำลังโหลดข้อยกเว้นทรัพยากรโปรดตรวจสอบการกำหนดค่าที่เกี่ยวข้อง',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease.info': 'แน่ใจไหมว่าต้องการยกเลิกรุ่นนี้',
  'ccivr.ivr.voiceupload.recording': 'บันทึก',
  'ccivr.ivr.voiceupload.textcontent': 'ข้อความ',
  'ccivr.ivr.bussinessinvoke.long': 'จำนวนเต็มยาว',
  'ccivr.nms.playcollectservice.advancedconfig': 'ตั้งค่าขั้นสูง',
  'ccivr.ivrflow.operate.ensurereplace.info': 'แน่ใจไหมว่าต้องการแทนที่ขั้นตอนที่เผยแพร่ออกมา',
  'ccivr.ivr.voiceupload.playrecord': 'เล่น',
  'ccivr.ivr.voiceupload.playtype': 'โหมดเปิดเสียง',
  'ccivr.ivr.voiceupload.fileformatwrong': 'ประเภทไม่ถูกต้อง',
  'ccivr.nms.playcollectservice.collectnumtimeout': 'การรวบรวมตัวเลขหมดเวลา',
  'ccivr.ivr.voice.recordupload': 'การบันทึกออนไลน์',
  'ccivr.ivr.voiceupload.deleteodfsivrfail': 'การลบล้มเหลว IVR ถูกครอบครอง',
  'ccivr.ivr.voiceupload.deletereferenceivrfail': 'การลบเสียงล้มเหลว เสียงถูกอ้างอิง',
  'ccivr.nms.assignservice.assignmentParameters': 'เตรียมพารามิเตอร์การกำหนด',
  'ccivr.nms.conditionService.conditionService': 'การตัดสินแบบมีเงื่อนไข',
  'ccivr.ivr.voiceedit.idisnull': 'ID ว่างเปล่า',
  'ccivr.ivr.tracelog.maxNumberValidate': 'จำนวนเบอร์โทรเกินขีด จำกัด!',
  'ccivr.ivrflow.grayrule.rule': 'กฎการเผยแพร่ไม่เป็นทางการ',
  'ccivr.nms.playset.status': 'การตั้งค่า TTS ขั้นสูง',
  'ccivr.nms.timeselectservice.tuesday': 'วันอังคาร',
  'ccivr.ivr.tracelog.result.transfailed': 'ล้มเหลว',
  'ccivr.ivr.tracelog.result': 'ผลลัพธ์ของขั้นตอน',
  'ccivr.ivr.bussinessinvoke.updateinterface': 'แก้ไขอินเทอร์เฟซ',
  'ccivr.ivr.voiceedit.statusisnull': 'พารามิเตอร์ของสถานะเป็นค่าว่าง',
  'ccivr.ivr.tracelog.createTime': 'เวลาที่เริ่มต้นขั้นตอน',
  'ccivr.nms.leavemessageservice.max.recordtime': 'ค่าสูงสุด ระยะเวลาในการบันทึก',
  'ccivr.ivr.voicereview.text': 'ข้อความ',
  'ccivr.nms.playservice.multivoice.language': 'ภาษา',
  'ccivr.nms.queryservice.type': 'เลือกประเภท',
  'ccivr.nms.label.certType': 'ประเภทใบรับรอง',
  'ccivr.nms.menuservice.moveup': 'ขึ้น',
  'ccivr.ivr.ivrflow.deleteconfim': 'แน่ใจไหมว่าต้องการลบโฟลว์ IVR',
  'ccivr.nms.tansferservice.callForwarding': 'การกำหนดค่าการโอนสาย',
  'ccivr.ivr.voiceedit.pathisnull': 'การค้นหาข้อมูลเส้นทาง SFTP ของศูนย์บริการล้มเหลว',
  'ccivr.flowtest.hangup': 'วางสาย',
  'ccivr.nms.menuservice.tip': 'หมดเวลาหรือคีย์ไม่ถูกต้อง',
  'ccivr.ivr.ivrflow.batchdeleteconfim': 'คุณแน่ใจหรือไม่ว่าต้องการลบโฟลว์ IVR ที่เลือกทั้งหมด? ตรวจสอบให้แน่ใจว่าไม่ได้เผยแพร่โฟลว์ IVR',
  'ccivr.ivrflow.updateivrinfo.editing': 'กำลังแก้ไขโฟลว์และไม่สามารถแก้ไขได้',
  'ccivr.nms.process.saveerror.alreadyreleased': 'สถานะการไหลผิดปกติโปรดเปิดผ้าใบอีกครั้ง',
  'ccivr.nms.leavemessageservice.localtionID': 'ID สถานที่',
  'ccivr.ivr.voiceupload.tipvideoformat': 'ปัจจุบันรองรับไฟล์วิดีโอ 3GP เท่านั้น ขนาดไฟล์ต้องไม่เกิน 5 MB',
  'ccivr.ivr.voiceupload.audiosampleratefailed': 'อัตราการสุ่มตัวอย่างไฟล์เสียงไม่ใช่ 8000HZ',
  'ccivr.ivr.voicereview.reviewsuggestion': 'ความคิดเห็น',
  'ccivr.ivr.ivrflow.operate.cancelrelease': 'ยกเลิกการเปิดตัว',
  'ccivr.nms.process.paramname': 'ชื่อ',
  'ccivr.nms.playservice.playfile': 'ไฟล์เสียง',
  'ccivr.nms.queryservice.audio': 'เสียง',
  'ccivr.nms.label.inputCertCode': 'ป้อนรหัสใบรับรอง',
  'ccivr.ivr.voicereview.edit': 'แก้ไข',
  'ccivr.ivr.tracelog.callInfo': 'ข้อมูลการโทร',
  'ccivr.nms.playservice.multivoice.type': 'ประเภท',
  'ccivr.ivr.success': 'สำเร็จ',
  'ccivr.nms.tansferservice.chooseZNIvr': 'เลือก Intelligent IVR',
  'ccivr.ivrflow.release.grayrule': 'เผยแพร่ไม่เป็นทางการ',
  'ccivr.nms.process.saveerror.dataerror': 'ไม่สามารถตรวจสอบข้อมูลองค์ประกอบไดอะแกรม',
  'ccivr.nms.timeselectservice.min': 'นาที',
  'ccivr.nms.timeselectservice.timeSelectType':'โหมดการกําหนดค่า',
  'ccivr.nms.timeselectservice.customConfig':'ปรับแต่ง',
  'ccivr.nms.timeselectservice.workDayType':'ภาษาไทย',
  'ccivr.nms.timeselectservice.workDay':'ปฏิทินการทํางาน',
  'ccivr.nms.delete': 'ลบ',
  'ccivr.ivr.variable.errorspecial': 'ไม่มีประเภทนี้',
  'ccivr.ivr.tracelog.deleteFailed': 'ลบล้มเหลว!',
  'ccivr.ivr.voiceupload.tmpformaterror': 'ประเภทไฟล์ชั่วคราวไม่ถูกต้อง',
  'ccivr.nms.queryservice.getDataAssign': 'ผลการค้นหา',
  'ccivr.ivr.voiceupload.checkuploadparmfailed': 'การตรวจสอบการอัปโหลดไฟล์ล้มเหลว',
  'ccivr.ivrflow.grayrule.inputrulename': 'ป้อนชื่อกฎ',
  'ccivr.ivr.tracelog.addFailed': 'เพิ่มไม่สำเร็จ!',
  'ccivr.ivr.variable.addvariable': 'เพิ่มตัวแปร',
  'ccivr.nms.tansferservice.onlineagent': 'จำนวนเจ้าหน้าที่ออนไลน์',
  'ccivr.ivrflow.updateivrinfo.fail': 'แก้ไขแอตทริบิวต์โฟลว์ IVR ไม่สำเร็จ',
  'ccivr.ivr.voiceedit.addsuccess': 'เพิ่มสำเร็จ',
  'ccivr.ivr.variable.defaultlimit': 'ค่าเริ่มต้นต้องมีอักขระไม่เกิน 120 ตัว',
  'ccivr.whitelist.deletetip': 'กรุณาเลือกรายการที่คุณต้องการลบ',
  'ccivr.ivr.ivrflow.operate.clone.fail': 'ไม่สามารถอัพเกรดโฟลว์ได้',
  'ccivr.nms.conditionService.maxroute': 'จำนวนสาขาที่มีเงื่อนไขสูงสุดคือ 20',
  'ccivr.nms.businessinvokeservice.servicename': 'ชื่ออินเทอร์เฟซ',
  'ccivr.nms.process.saveerror.menukeynoconnection': 'การบันทึกล้มเหลว ตรวจสอบว่าแต่ละปุ่มในการกำหนดค่าเมนูมีเส้นหรือไม่',
  'ccivr.ivr.voiceedit.deletesuccess': 'ลบสำเร็จ',
  'ccivr.ivr.voicereview.operat': 'การดำเนินการ',
  'ccivr.nms.playservice.chosetts': 'เนื้อหาที่จะเล่นจะถูกเลือกจากข้อความ TTS ที่อัปโหลดและตรวจสอบแล้ว ต้องใช้ทรัพยากร TTS',
  'ccivr.nms.transferservice.choose.recordfilename.info': 'เมื่ออินเทอร์เฟซถูกเรียกเพื่อดาวน์โหลดไฟล์บันทึกตัวแปรจะถูกใช้เพื่อเก็บค่าชั่วคราว',
  'ccivr.nms.shortmessageservice.setParameter': 'ตั้งค่าตัวแปร',
  'ccivr.ivr.voice.scene.note': 'โทนเสียง',
  'ccivr.ivr.bussinessinvoke.validate': 'การยืนยันล้มเหลว',
  'ccivr.flowtest.begin.tip': 'ป้อนหมายเลขโทรเพื่อเริ่มการทดสอบ',
  'ccivr.nms.process.description': 'คำอธิบาย',
  'ccivr.ivr.voiceedit.filenamecheckfail': 'การตรวจสอบชื่อไฟล์ล้มเหลว',
  'ccivr.nms.leavemessageservice.choose.recordfilename': 'บันทึกชื่อแฟ้ม',
  'ccivr.nms.queryservice.skillInfo': 'ข้อมูลลำดับ',
  'ccivr.ivr.ivrflow.ivrname': 'ชื่อ',
  'ccivr.ivr.ivrusage.ivrname': 'สถานการณ์อ้างอิง',
  'ccivr.ivr.ivrusage.skillName': 'ชื่อคิวทักษะ',
  'ccivr.ivr.voicereview.search': 'ค้นหา',
  'ccivr.nms.playcollectservice.collectnumerror': 'การรวบรวมตัวเลขล้มเหลว',
  'ccivr.nms.businessinvokeservice.title': 'เรียกใช้อินเทอร์เฟซ',
  'ccivr.nms.businessinvokeservice.inputflowValidate': 'ข้อมูลที่ป้อนไม่ถูกต้องโปรดแก้ไข',
  'ccivr.ivr.error.sftp': 'ข้อมูล SFTP ไม่ถูกต้อง',
  'ccivr.nms.playcollectservice.times': 'ครั้ง',
  'ccivr.nms.assignservice.othersDataAssign': 'กำหนดข้อมูลอื่น ๆ',
  'ccivr.nms.SubFlowService.jumpnode': 'Jump Node',
  'ccivr.nms.playcollectservice.maxfailtimes': 'ค่าสูงสุดเวลาล้มเหลว',
  'ccivr.nms.tansferservice.selectPath': 'เลือกเส้นทาง',
  'ccivr.flowtest.operatefail': 'ล้มเหลว',
  'ccivr.ivr.bussinessinvoke.string': 'Character String',
  'ccivr.nms.varibalesselect.sysvariables': 'ตัวแปรระบบ',
  'ccivr.ivrflow.grayrule.name': 'ชื่อกฎ',
  'ccivr.nms.process.saveerror.noselectType': 'เลือกประเภทแบบสอบถาม',
  'ccivr.whitelist.urlvalidatefailed': 'โปรดป้อน IP หรือชื่อโดเมน',
  'ccivr.ivrflow.grayrule.bindingflow': 'การไหลที่ผูกพัน:',
  'ccivr.ivr.ivrflow.operate.ensurerelease': 'ยืนยันการเผยแพร่',
  'ccivr.ivrflow.createivrflow.exceptionfail': 'มีขั้นตอนการจัดการข้อยกเว้นอยู่แล้ว การเพิ่มล้มเหลว',
  'ccivr.ivr.tracelog.result.default': 'ค่าเริ่มต้น',
  'ccivr.ivr.success.import': 'เพิ่มสำเร็จแล้ว',
  'ccivr.ivrflow.grayrule.inputendnum': 'หมายเลขสิ้นสุดต้องมากกว่าหรือเท่ากับหมายเลขเริ่มต้น',
  'ccivr.ivr.voicereview.uploadtime': 'เวลาอัปโหลด',
  'ccivr.nms.playservice.placeholder': 'การเล่นเสียง',
  'ccivr.ivr.bussinessinvoke.int': 'จำนวนเต็ม',
  'ccivr.ivr.voicereview.language': 'ภาษา',
  'ccivr.nms.tansferservice.add': 'ใหม่',
  'ccivr.nms.tansferservice.parameter': 'ค่า',
  'ccivr.ivrflow.grayrule.numrange': 'กลุ่มตัวเลข',
  'ccivr.nms.playcollectservice.Failedreceivenum': 'การรวบรวมตัวเลขล้มเหลว',
  'ccivr.ivr.voiceedit.filenameformaterror': 'รูปแบบชื่อไฟล์ไม่ถูกต้อง',
  'ccivr.ivr.variable.typeisnull': 'ประเภทว่าง',
  'ccivr.nms.playcollectservice.changtovideoO': 'วิดีโอ',
  'ccivr.nms.playset.speed': 'ความเร็วในการพูด',
  'ccivr.ivr.voiceupload.referenceFlowByCCIVR': 'การอ้างอิงการไหลของ IVR',
  'ccivr.nms.timeselectservice.hour': 'ชั่วโมง',
  'ccivr.nms.satisfactionservice.menubuttonconfig': 'การกำหนดค่าปุ่มความพึงพอใจ',
  'ccivr.nms.satisfactionservice.singleproblem.menubuttonconfig': 'ปรับแต่งปุ่มตรวจสอบปัญหาเดียว',
  'ccivr.ivrflow.grayrule.info': 'รายละเอียด',
  'ccivr.nms.tansferservice.record': 'บันทึก',
  'ccivr.nms.conditionService.simpleExp': 'นิพจน์ทั่วไป',
  'ccivr.ivrflow.cancelrelease.error': 'โฟลว์อ้างอิงโดยลำดับทักษะ',
  'ccivr.nms.process.saveerror': 'การบันทึกล้มเหลว ตรวจสอบโหนดการไหลและสายเชื่อมต่อ',
  'ccivr.whitelist.whiteip': 'Trustlist IP',
  'ccivr.nms.SubFlowService.Rotorflow': 'โอนไปยัง Subflow',
  'ccivr.nms.conditionService.relationOperator': 'ผู้ประกอบการ',
  'ccivr.nms.playcollectservice.changtovoice': 'TTS',
  'ccivr.nms.tansferservice.transSkill': 'การเรียกจะถูกโอนไปยังลำดับทักษะที่เกี่ยวข้อง รองรับพารามิเตอร์ที่กําหนดเอง ตัวอย่าง: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
  'ccivr.nms.conditionService.complexExp': 'นิพจน์ที่ซับซ้อน',
  'ccivr.ivr.tracelog.errorpop': 'การค้นหาล้มเหลว!',
  'ccivr.nms.timeselectservice.tips3': 'ช่วงเวลาให้บริการต้องเรียงลำดับจากน้อยไปมาก แต่ละช่วงเวลาไม่สามารถซ้อนทับกันได้',
  'ccivr.nms.timeselectservice.tips2': 'เวลาสิ้นสุดของบริการต้องช้ากว่าเวลาเริ่มต้น',
  'ccivr.nms.tansferservice.transferZNIvr': 'การโทรของลูกค้าจะถูกโอนไปยัง IVR อัจฉริยะที่เกี่ยวข้อง',
  'ccivr.nms.timeselectservice.tips1': 'วันที่ตรงกับวันและวันในสัปดาห์ที่เลือกคือวันทำงาน',
  'ccivr.ivr.voicereview.name': 'ชื่อ',
  'ccivr.nms.businessinvokeservice.outputparam': 'แสดงผลพารามิเตอร์',
  'ccivr.ivr.tracelog.flowName': 'FlowName',
  'ccivr.ivr.voiceupload.opreationdbfailed': 'อัปเดตข้อความเสียง IVR ของฐานข้อมูลล้มเหลว',
  'ccivr.ivr.voiceupload.pleaseuploadfile': 'ไฟล์ว่างเปล่า กรุณานำเข้าไฟล์',
  'ccivr.ivr.voicereview.voice': 'เสียง',
  'ccivr.flowtest.callingnumber': 'เบอร์ต้นทาง',
  'ccivr.ivr.ivrflow.alreadyreleased': 'การเปิดตัวอย่างเป็นทางการ',
  'ccivr.ivr.voicereview.nosubmit': 'ไม่ได้ส่ง',
  'ccivr.nms.menuservice.menubuttonconfig': 'การกำหนดค่าคีย์เมนู',
  'ccivr.ivr.ivrflow.operate.release.success': 'เผยแพร่ความสำเร็จ',
  'ccivr.whitelist.port': 'ท่าเรือ',
  'ccivr.nms.shortmessageservice.hwCloudMessage': 'Huawei Cloud',
  'ccivr.nms.timeselectservice.servicetimeperiod': 'ช่วงเวลาให้บริการ',
  'ccivr.nms.assignservice.transferDataAssign': 'รับ TransferData',
  'ccivr.nms.assignservice.setTransferData': 'การกำหนดค่าการโอน',
  'ccivr.nms.tansferservice.setTransfer': 'การกำหนดค่าการโอน',
  'ccivr.ivr.ivrflow.no': 'ไม่',
  'ccivr.flowtest.testoperate': 'การดำเนินงาน:',
  'ccivr.ivr.voicereview.choosevoicetype': 'ประเภท',
  'ccivr.nms.process.saveerror.flownoexist': 'ไม่มีการไหล',
  'ccivr.whitelist.descinfo': 'คำอธิบาย',
  'ccivr.nms.brach.duplicatebranch': 'มีสาขาอยู่แล้ว เลือกไฟล์อื่น',
  'ccivr.ivr.voiceupload.authvalidatefailed': 'การตรวจสอบสิทธิ์การอัปโหลดไฟล์ล้มเหลว',
  'ccivr.nms.tansferservice.processName': 'ป้อนชื่อโฟลว์',
  'ccivr.ivr.voicereview.reviewstatus': 'สถานะ',
  'ccivr.nms.queryservice.Dataassignment': 'สอบถามข้อมูล',
  'ccivr.nms.tansferservice.transferThirdParty': 'โอนไปยังบุคคลที่สาม',
  'ccivr.nms.tansferservice.transferThirdParty.recordingSettings': 'การตั้งค่าการบันทึก',
  'ccivr.nms.tansferservice.transferThirdParty.enableRecording': 'เปิดใช้งานการบันทึก',
  'ccivr.nms.tansferservice.transferThirdParty.recordingPrompt': 'การบันทึกพร้อมท์',
  'ccivr.nms.tansferservice.sample': 'ตัวอย่าง: 28956666 หรือ {\'$\'}{\'{\'}FLOW.currentUserNum{\'}\'}',
  'ccivr.nms.tansferservice.empty': 'พารามิเตอร์ ว่างเปล่า',
  'ccivr.nms.modulename': 'ชื่อองค์ประกอบ',
  'ccivr.nms.conditionService.logicOR': 'หรือ',
  'ccivr.nms.menuservice.samenamevalidate': 'ปุ่มซ้ำ:',
  'ccivr.ivr.ivrflow.updateTime': 'เวลาอัปเดต',
  'ccivr.ivr.voiceupload.tipvideomodel': 'คุณสามารถตั้งค่าไฟล์เสียงหรือวิดีโอของภาษาต่างๆได้ในเวลาเดียวกัน ในระหว่างการเล่นวิดีโอระบบจะเล่นไฟล์วิดีโอที่เกี่ยวข้องโดยอัตโนมัติตามภาษาที่ผู้ใช้เลือก',
  'ccivr.ivr.voicereview.pass': 'อนุมัติ',
  'ccivr.ivr.voiceedit.import': 'นำเข้า',
  'ccivr.nms.shortmessageservice.satisfactionMessage': 'แบบสำรวจความพึงพอใจ',
  'ccivr.ivr.ivrflow.exceptionHandingFlow': 'ขั้นตอนการจัดการข้อยกเว้น',
  'ccivr.ivr.voiceadvice.close': 'ปิด',
  'ccivr.nms.process.parameterisEncrypt': 'การเข้ารหัส',
  'ccivr.ivr.ivrflow.currentHandler': 'ผู้ดูแล',
  'ccivr.nms.satisfactionservice.buttonNumber': 'จํานวนคีย์สํารวจความพึงพอใจไม่เกิน 9 และไม่น้อยกว่า 3',
  'ccivr.nms.satisfactionservice.tip': 'โปรดกําหนดค่าระดับความพึงพอใจในเมนู การตั้งค่าความพึงพอใจ หากโฟลว์ได้รับการเผยแพร่คุณสามารถอัปเกรดแก้ไขบันทึกและปล่อยโฟลว์อีกครั้งเพื่อแทนที่โฟลว์เดิมเพื่อใช้ระดับความพึงพอใจที่อัปเดตแล้ว',
  'ccivr.ivr.bussinessinvoke.URL': 'ขอ URL',
  'ccivr.ivr.voiceupload.selectvoice': 'เลือกไฟล์เสียง',
  'ccivr.nms.tansferservice.timedPrompt': 'นายไม่ได้เก็บมันมานานมากแล้ว ช่วยเก็บให้ทันเวลาด้วยครับ',
  'ccivr.nms.tansferservice.processLayout': 'การจัดกระบวนการ',
  'ccivr.ivr.voice.recordtimeout.fail': 'หมดเวลาการบันทึกแล้ว',
  'ccivr.nms.shortmessageservice.messageTemplateContent': 'เทมเพลต',
  'ccivr.ivr.bussinessinvoke.errormessage': 'เลือกอินเทอร์เฟซที่จะลบ',
  'ccivr.ivr.ivrflow.mainflow': 'กระบวนการหลัก',
  'ccivr.nms.process.samenamevalidate': 'ชื่อพารามิเตอร์ที่ซ้ำกัน:',
  'ccivr.nms.interface.nmsexception': 'การสื่อสารกับ ODFS ตรงตามข้อยกเว้น! โปรดตรวจสอบการกำหนดค่า',
  'ccivr.nms.interface.whitelistcheckfailed': 'URL (ที่อยู่ IP และหมายเลขพอร์ต) ไม่อยู่ในรายการที่เชื่อถือได้ สามารถเรียกอินเทอร์เฟสได้เฉพาะหลังจากที่คุณติดต่อผู้ดูแลระบบเพื่อเพิ่ม URL ไปยังรายการที่เชื่อถือได้เท่านั้น ไปต่อเหรอ?',
  'ccivr.nms.interface.whitelistwarn': 'URL (ที่อยู่ IP และหมายเลขพอร์ต) ไม่อยู่ในรายการที่เชื่อถือได้ สามารถเรียกอินเทอร์เฟสได้เฉพาะหลังจากที่คุณติดต่อผู้ดูแลระบบเพื่อเพิ่ม URL ไปยังรายการที่เชื่อถือได้เท่านั้น',
  'ccivr.ivr.tracelog.result.timeout': 'หมดเวลา',
  'ccivr.ivrflow.grayrule.rulename': 'ชื่อกฎ',
  'ccivr.ivr.voice.scene.error': 'ข้อผิดพลาด',
  'ccivr.ivr.voice.scene.questionnaires': 'แบบสอบถาม',
  'ccivr.ivr.voice.scene.beforeReportWorkNo': 'ก่อนรายงานบัตรประจําตัวพนักงาน',
  'ccivr.ivr.voice.scene.afterReportWorkNo': 'หลังจากรายงาน ID พนักงาน',
  'ccivr.nms.shortmessageservice.fiveGMessage': 'ข้อความ 5G',
  'ccivr.nms.conditionService.Parameter_2': 'พารามิเตอร์ 2',
  'ccivr.ivr.voicereview.vedio': 'วิดีโอ',
  'ccivr.ivr.voicereview.filePath': 'พาธของแฟ้ม:',
  'ccivr.ivr.voicereview.textContent': 'เนื้อหาไฟล์',
  'ccivr.ivr.voiceedit.filenameisnull': 'ชื่อไฟล์เป็นค่าว่าง',
  'ccivr.nms.conditionService.Parameter_1': 'พารามิเตอร์ 1',
  'ccivr.ivr.ivrflow.subflow': 'กระบวนการย่อย',
  'ccivr.nms.shortmessageservice.addressee': 'เบอร์มือถือ',
  'ccivr.nms.shortmessageservice.phoneNumber': 'หมายเลขโทรศัพท์',
  'ccivr.nms.playservice.inputtitle': 'พรอมต์เสียง',
  'ccivr.ivrflow.createivrflow.sameivrflowname': 'ชื่อโฟลว์มีอยู่แล้ว',
  'ccivr.ivr.tracelog.sameNumberValidate': 'มีหมายเลขโทรศัพท์เดิมอยู่แล้ว!',
  'ccivr.nms.playcollectservice.close': 'ปิด',
  'ccivr.nms.playcollectservice.required': 'จำเป็นต้องกรอก',
  'ccivr.nms.menuname.specialStrValidateFailed': 'ความยาวไม่เกิน 255 ตัวอักษร',
  'ccivr.nms.process.saveerror.noexistconnection': 'การบันทึกล้มเหลว บรรทัดเริ่มต้นต้องพร้อมใช้งานสำหรับองค์ประกอบไดอะแกรมยกเว้นจุดสิ้นสุดและโอนไปยังองค์ประกอบไดอะแกรมขั้นตอนย่อย',
  'ccivr.nms.queryservice.queryType': 'ประเภทการค้นหา',
  'ccivr.nms.playset.femalevoice': 'เสียงผู้หญิง',
  'ccivr.nms.playservice.multivoice.type.telnum': 'หมายเลขโทรศัพท์',
  'ccivr.flowtest.test': 'ทดสอบ',
  'ccivr.nms.tansferservice.transNum': 'หมายเลขโอนสาย',
  'ccivr.ivr.voicereview.filename': 'ชื่อไฟล์',
  'ccivr.ivr.voicereview.scene': 'สถานการณ์การใช้งาน',
  'ccivr.nms.tansferservice.transAppointWorkNo': 'การโทรเข้าจะถูกโอนไปยังเจ้าหน้าที่ที่ระบุ',
  'ccivr.ivr.variable.descvalueerror': 'คำอธิบายประกอบด้วยอักขระพิเศษ โปรดแก้ไข',
  'ccivr.nms.satisfactionservice.type': 'ประเภทการสํารวจความพึงพอใจ',
  'ccivr.nms.satisfactionservice.title': 'การสำรวจค่าความพึงพอใจ',
  'ccivr.nms.singleproblem.title': 'การสํารวจคําถามเดียว',
  'ccivr.ivr.tracelog.title': 'การติดตาม Log',
  'ccivr.nms.timeselectservice.selectdate': 'วันที่',
  'ccivr.nms.menuservice.contentvalidate': 'หลังจากแก้ไขเสร็จแล้วให้เพิ่มการกำหนดค่าคีย์',
  'ccivr.nms.process.initialvalue': 'ค่าเริ่มต้น',
  'ccivr.whitelist.imordomainname': 'IP หรือชื่อโดเมน',
  'ccivr.ivr.tracelog.addAccessCode': 'เพิ่มรหัสการเข้าถึง',
  'ccivr.nms.playcollectservice.ttsvoice': 'TTS',
  'ccivr.nms.playcollectservice.entercollectwaittime': 'ป้อนเวลารอการรวบรวมตัวเลข',
  'ccivr.nms.playcollectservice.collectTimeout': 'ระยะเวลาหมดเวลาการเก็บตัวเลข',
  'ccivr.nms.ChangeMediaService.label': 'สื่อมวลชน',
  'ccivr.nms.playcollectservice.collectTimeout.error': 'เวลาหมดเวลาของหมายเลขกํากับไม่ถูกต้อง (1-120)',
  'ccivr.nms.playservice.contentisnull': 'ไม่สามารถปล่อยเนื้อหาการออกเสียงว่างได้',
  'ccivr.ivr.variable.willdeleteselectvariable': 'แน่ใจไหมว่าต้องการลบตัวแปรที่เลือก',
  'ccivr.nms.playservice.TTS': 'การเล่นเสียง TTS',
  'ccivr.ivr.tracelog.transitionOn': 'แยกสาขาองค์ประกอบ',
  'ccivr.ivr.name.notnull': 'ชื่อต้องไม่เว้นว่าง',
  'ccivr.nms.callendservice.checkbox': 'ต้องเรียกใช้อินเทอร์เฟซเมื่อสิ้นสุดการโทร',
  'ccivr.ivr.voiceupload.formatwrong': 'รูปแบบไฟล์ไม่ถูกต้อง นำเข้าไฟล์ WAV',
  'ccivr.ivr.voiceupload.fileheaderwrong': '%ue44%ue1f%ue25%ue4c%ue40%ue2a%ue35%ue22%ue07%ue40%ue14%ue34%ue21%ue44%ue21%ue48%ue43%ue0a%ue48%ue44%ue1f%ue25%ue4c wav, mp3 %ue01%ue23%ue38%ue13%ue32%ue2d%ue31%ue1b%ue42%ue2b%ue25%ue14%ue44%ue1f%ue25%ue4c%ue40%ue2a%ue35%ue22%ue07%ue2d%ue37%ue48%ue19%ue41%ue17%ue19',
  'ccivr.ivr.voiceupload.vedioheaderwrong': '%ue44%ue1f%ue25%ue4c%ue27%ue34%ue14%ue35%ue42%ue2d%ue19%ue35%ue49%ue44%ue21%ue48%ue44%ue14%ue49%ue2d%ue22%ue39%ue48%ue43%ue19%ue23%ue39%ue1b%ue41%ue1a%ue1a 3gp %ue42%ue1b%ue23%ue14%ue40%ue1b%ue25%ue35%ue48%ue22%ue19%ue44%ue1f%ue25%ue4c%ue27%ue34%ue14%ue35%ue42%ue2d%ue2d%ue37%ue48%ue19%ue40%ue1e%ue37%ue48%ue2d%ue2d%ue31%ue1e%ue42%ue2b%ue25%ue14%ue44%ue1f%ue25%ue4c%ue27%ue34%ue14%ue35%ue42%ue2d',
  'ccivr.nms.process.basicinformation': 'ข้อมูลพื้นฐาน',
  'ccivr.nms.playcollectservice.choosefile': 'เสียง',
  'ccivr.ivr.voice.filechoose': 'เลือกไฟล์เสียง',
  'ccivr.ivr.voiceedit.editsuccess': 'แก้ไขสำเร็จ',
  'ccivr.ivr.voicereview.refresh': 'รีเฟรช',
  'ccivr.ivr.voiceedit.addfail': 'การเพิ่มล้มเหลว',
  'ccivr.nms.tansferservice.estimateTime': 'เวลาโดยประมาณ',
  'ccivr.nms.queryservice.estimateTime.prompte': 'เวลารอคิวที่คาดว่าจะคํานวณตามรูปแบบคิว ผล ที่ ได้ อาจ ต่าง ออก ไป. ฉะนั้น จง ใช้ อย่าง ถูก ต้อง.',
  'ccivr.nms.tansferservice.transNumType': 'หมายเลขโอนสายอาจเป็นหมายเลขโทรศัพท์มือถือ\,หมายเลขโทรศัพท์พื้นฐานหรือพารามิเตอร์ที่กำหนดเอง ตัวอย่าง:{\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
  'ccivr.nms.queryservice.paramName': 'ขอพารามิเตอร์',
  'ccivr.ivr.tracelog.callingNumberplaceHolder': 'โปรดป้อนหมายเลขโทร',
  'ccivr.ivr.tracelog.nodeName': 'ชื่อองค์ประกอบ',
  'ccivr.whitelist.desc': 'คำอธิบาย',
  'ccivr.ivr.voice.fileupload': 'อัปโหลดไฟล์',
  'ccivr.ivr.tracelog.addcallingnumber': 'เพิ่ม CallingNumber',
  'ccivr.nms.playcollectservice.mulen': 'สูงสุด จำนวนหลักที่ได้รับ',
  'ccivr.nms.playset.lowspeed': 'ต่ำ',
  'ccivr.ivr.voicereview.english': 'ภาษาอังกฤษ',
  'ccivr.ivr.bussinessinvoke.cancel': 'ยกเลิก',
  'ccivr.nms.process.parameterconfiguration': 'การกำหนดค่าพารามิเตอร์',
  'ccivr.nms.playcollectservice.onenumbers': 'คอลเลกชันหลากหลายดิจิตอล',
  'ccivr.ivr.tracelog.selecttip': 'กรุณาเลือกหมายเลขโทร!',
  'ccivr.nms.playservice.multivoice.type.time': 'เวลา (hh: mm: ss)',
  'ccivr.flowtest.stoptest': 'หยุด',
  'ccivr.ivr.ivrflow.operate.cancelrelease.fail': 'ยกเลิกการเผยแพร่ไม่สำเร็จ',
  'ccivr.ivr.bussinessinvoke.tip': 'โปรดป้อนค่าหรือตัวแปร',
  'ccivr.ivr.bussinessinvoke.createinterface': 'สร้างอินเทอร์เฟซ',
  'ccivr.nms.playset.sound': 'เสียงประกอบ',
  'ccivr.ivrflow.unlock': 'ปลดล็อก',
  'ccivr.nms.tansferservice.mustbeaccesscode': 'หมายเลขโทรต้องเป็นรหัสการเข้าถึงที่จัดสรรโดยระบบหรือพารามิเตอร์ที่กำหนดเอง ตัวอย่าง: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
  'ccivr.flowtest.flowtest': 'การทดสอบการไหล',
  'ccivr.ivr.voiceupload.stoprecording': 'หยุด',
  'ccivr.ivrflow.release.formal': 'การเปิดตัวอย่างเป็นทางการ',
  'ccivr.ivr.voiceedit.updatefail': 'การอัพเดทล้มเหลว.',
  'ccivr.ivr.tracelog.deleteSuccess': 'ลบสำเร็จแล้ว!',
  'ccivr.nms.leavemessageservice.placeholder': 'ทิ้งข้อความไว้',
  'ccivr.flowtest.success': 'สำเร็จ',
  'ccivr.nms.shortmessageservice.period': 'ระยะเวลาตอบกลับที่ถูกต้อง (นาที)',
  'ccivr.ivr.ivrflow.operate.ensurerelease.info': 'แน่ใจไหมว่าต้องการเผยแพร่ขั้นตอนนี้',
  'ccivr.ivr.ivrflow.type': 'ประเภท',
  'ccivr.ivr.voiceupload.referenceFlowByNMS': 'การอ้างอิงเทมเพลตทรัพยากร',
  'ccivr.ivr.voiceupload.voiceUsageByTenant': 'ข้อมูลอ้างอิงสถานการณ์ผู้เช่า',
  'ccivr.ivr.voiceupload.voiceUsageBySkill': 'การอ้างอิงคิวทักษะ',
  'ccivr.ivr.voiceedit.tip': 'พร้อมท์',
  'ccivr.ivr.bussinessinvoke.interfacedesc': 'คำอธิบายอินเทอร์เฟซ',
  'ccivr.ivr.ivrflow.operate.release.cancelexecptionhanding': 'โปรดยกเลิกสถานะการเผยแพร่ของกระบวนการอื่น ๆ ภายใต้ผู้เช่า',
  'ccivr.nms.LanguageService.placeholder': 'เลือกภาษา',
  'ccivr.nms.menuservice.voicenotice': 'พรอมต์เสียง',
  'ccivr.ivr.bussinessinvoke.saveserror': 'การบันทึกล้มเหลว',
  'ccivr.nms.interface.warn': 'โปรโตคอลเครือข่ายที่ใช้โดยอินเทอร์เฟซไม่ปลอดภัยและอาจก่อให้เกิดความเสี่ยงด้านความปลอดภัย',
  'ccivr.ivr.bussinessinvoke.interfacetype': 'ประเภทอินเทอร์เฟซ',
  'ccivr.ivr.ivrflow.filtercondition': 'เกณฑ์การกรอง',
  'ccivr.ivr.voiceedit.querycallcenterfailed': 'การค้นหาข้อมูล SFTP ของศูนย์บริการล้มเหลว',
  'ccivr.ivrflow.error.deleteivrflow.association': 'โฟลว์เชื่อมโยงกับโฟลว์อื่นและไม่สามารถลบได้',
  'ccivr.ivr.voiceedit.statuserror': 'พารามิเตอร์ของสถานะคือข้อผิดพลาด',
  'ccivr.ivr.voicereview.notpass': 'ถูกปฏิเสธ',
  'ccivr.nms.playservice.multivoice.content': 'เนื้อหา',
  'ccivr.nms.playset.highspeed': 'สูง',
  'ccivr.ivr.bussinessinvoke.savesuccess': 'บันทึกสำเร็จแล้ว.',
  'ccivr.nms.varibalesselect.globalvariables': 'ตัวแปรสากล',
  'ccivr.ivrflow.grayrule.inputendlength': 'ความยาวของหมายเลขเริ่มต้นต้องตรงกับหมายเลขสิ้นสุด',
  'ccivr.ivr.tracelog.calledNumber': 'เรียกว่าหมายเลข',
  'ccivr.nms.timeselectservice.friday': 'วันศุกร์',
  'ccivr.ivr.voicereview.passed': 'ได้รับการอนุมัติ',
  'ccivr.ivr.voiceupload.audiofilereadfailed': 'การอ่านคุณสมบัติไฟล์เสียงล้มเหลว',
  'ccivr.ivr.voiceupload.fileinjectionerror': 'ตรวจสอบข้อยกเว้นความเสี่ยงในการฉีดไฟล์',
  'ccivr.nms.process.customparameter': 'พารามิเตอร์การไหล',
  'ccivr.ivr.voiceupload.uploadfiletosftpfailed': 'การอัปโหลดไฟล์ไปยัง sftp ล้มเหลว',
  'ccivr.nms.shortmessageservice.mspMessage': 'เกตเวย์ SMS',
  'ccivr.ivrflow.operate.ensurereplace': 'ยืนยันการแทนที่',
  'ccivr.ivr.variable.string': 'String(ตัวอักษร)',
  'ccivr.ivr.voiceedit.filenamenull': 'เนื้อหาชื่อไฟล์เป็นค่าว่าง',
  'ccivr.nms.playset.intermediatespeed': 'ปานกลาง',
  'ccivr.ivr.tracelog.deleteconfirminfo': 'จะลบหมายเลขโทรปัจจุบันหรือไม่:',
  'ccivr.ivr.fail': 'ล้มเหลว',
  'ccivr.ivr.voiceupload.delete': 'ลบ',
  'ccivr.ivr.ivrflow.filter': 'กรอง',
  'ccivr.nms.playcollectservice.failtitle': 'การเล่นเสียงสำหรับการรวบรวมตัวเลขล้มเหลว',
  'ccivr.ivr.bussinessinvoke.basicconfig': 'การกำหนดค่าพื้นฐาน',
  'ccivr.nms.label.certCode': 'รหัสใบรับรอง',
  'ccivr.nms.timeselectservice.wednesday': 'วันพุธ',
  'ccivr.ivr.tracelog.result.recordend': 'บันทึกความสำเร็จ',
  'ccivr.ivrflow.variable.settings': 'การตั้งค่าตัวแปร',
  'ccivr.ivr.tracelog.deleteconfirminfocode': 'จะลบรหัสการเข้าถึงปัจจุบันหรือไม่:',
  'ccivr.flowtest.flowname': 'ชื่อ',
  'ccivr.ivr.ivrflow.operate.gateway.pulishrepeat': 'ชื่อกระบวนการซ้ํากันหรือรุ่นได้รับการเผยแพร่แล้ว',
  'ccivr.ivr.ivrflow.version': 'เวอร์ชั่น',
  'ccivr.ivr.voiceupload.english': 'ภาษาอังกฤษ',
  'ccivr.nms.add': 'ใหม่',
  'ccivr.nms.playservice.chosemultivoice': 'ตั้งค่าเนื้อหาที่จะเล่นจากตัวเลือกต่อไปนี้',
  'ccivr.ivr.tracelog.addSuccess': 'เพิ่มสำเร็จแล้ว!',
  'ccivr.ivr.tracelogprompt': 'สิ่งนี้จะส่งผลต่อประสิทธิภาพของระบบการกำหนดค่านี้จะถูกลบโดยอัตโนมัติหลังจาก {0} ชั่วโมงคุณยังคงดำเนินต่อไป',
  'ccivr.flowtest.presskey': 'ปุ่มต่างๆ',
  'ccivr.nms.label.certScene': 'สถานการณ์การใช้งาน',
  'ccivr.ivr.tracelog.selecttipcode': 'กรุณาเลือกรหัสการเข้าถึง!',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed128': 'ค่าต้องไม่มีอักขระพิเศษและความยาวต้องไม่เกิน 128 อักขระ',
  'ccivr.ivr.voice.exception.recordfailmessage': 'ข้อผิดพลาดในการบันทึก ตรวจสอบว่าคุณมีสิทธิ์ใช้ไมโครโฟนหรือไม่',
  'ccivr.ivrflow.updateivrinfo.success': 'แก้ไขแอ็ตทริบิวต์ของโฟลว์ IVR สำเร็จแล้ว',
  'ccivr.nms.businessinvokeservice.second': 'ประการที่สอง',
  'ccivr.ivr.tracelog.index': 'ลำดับ',
  'ccivr.nms.tansferservice.operat': 'การดำเนินการ',
  'ccivr.ivr.voice.recordtimeout.failmes': 'ระยะเวลาการบันทึกสูงสุดคือ 2 นาที บันทึกอีกครั้ง',
  'ccivr.nms.tansferservice.transferZNIvrCode': 'โอนไปยัง Intelligent IVR',
  'ccivr.nms.process.saveerror.noresult': 'ไม่ใช่เอนทิตี',
  'ccivr.nms.leavemessageservice.saveleavemessage': 'บันทึกข้อมูลข้อความ',
  'ccivr.nms.playservice.multivoice.language9': 'กรีก',
  'ccivr.flowtest.platform': 'แพลตฟอร์ม',
  'ccivr.nms.playservice.multivoice.language8': 'เยอรมัน',
  'ccivr.ivrflow.grayrule.grayinfo': 'หากตัวเลขตรงกับกฎสีเทาระบบจะดำเนินการโฟลว์ของเวอร์ชันนี้<br /> สำหรับตัวเลขที่ไม่ตรงกับกฎสีเทาระบบจะดำเนินการขั้นตอนที่เผยแพร่อย่างเป็นทางการ<br /> หากไม่มีขั้นตอนที่เผยแพร่อย่างเป็นทางการการดำเนินการจะล้มเหลว',
  'ccivr.nms.playservice.multivoice.language7': 'ฝรั่งเศส',
  'ccivr.nms.playservice.multivoice.language6': 'ฟินแลนด์',
  'ccivr.nms.playservice.multivoice.language5': 'ดัตช์',
  'ccivr.nms.playservice.multivoice.language4': 'เดนมาร์ก',
  'ccivr.nms.playservice.multivoice.language3': 'เช็ก',
  'ccivr.nms.playservice.multivoice.language2': 'ภาษาจีน (กวางตุ้ง)',
  'ccivr.nms.playservice.multivoice.language1': 'ภาษาอังกฤษ',
  'ccivr.nms.playservice.multivoice.language0': 'ผู่ตงฮัว',
  'ccivr.nms.playcollectservice.interrupt': 'กดปุ่มใด ๆ เพื่อขัดจังหวะการเล่น',
  'ccivr.ivr.description.limit': 'คำอธิบายข้อความต้องมีไม่เกิน 1024 bytes',
  'ccivr.ivr.voice.vediochoose': 'เลือกไฟล์วิดีโอ',
  'ccivr.ivr.variable.name': 'ตัวแปร',
  'ccivr.nms.tansferservice.delete': 'ลบ',
  'ccivr.ivr.changtovoice': 'TTS',
  'ccivr.ivr.voiceupload.isconfdelete': 'แน่ใจไหมว่าต้องการลบ',
  'ccivr.ivr.ivrflow.clone': 'อัพเกรด',
  'ccivr.nms.callendservice.title': 'สิ้นสุด',
  'ccivr.ivr.variable.nameisnull': 'ชื่อว่างเปล่า',
  'ccivr.nms.playcollectservice.chooseparam': 'บันทึกการป้อนข้อมูลของผู้ใช้',
  'ccivr.nms.process.parametertype': 'ประเภทพารามิเตอร์',
  'ccivr.ivrflow.createivrflow.fail': 'การเพิ่มล้มเหลว',
  'ccivr.nms.process.isParameterisEncrypt': 'เข้ารหัสหรือไม่',
  'ccivr.nms.playcollectservice.entermaxfailtimes': 'ป้อนจำนวนสูงสุดของความล้มเหลว',
  'ccivr.nms.businessinvokeservice.paramname': 'ชื่อ',
  'ccivr.ivr.tracelog.callingNumber': 'CallingNumber',
  'ccivr.nms.playset.close': 'ปิดการใช้งาน',
  'ccivr.nms.queryservice.queryParam': 'ขอพารามิเตอร์',
  'ccivr.nms.varibalesselect.processvaribales': 'ตัวแปรการไหล',
  'ccivr.nms.conditionService.moduleDescription': 'คำอธิบายโมดูล',
  'ccivr.ivr.voicereview.reset': 'รีเซ็ต',
  'ccivr.ivr.voicereview.choosetenantspace': 'เลือกผู้เช่า',
  'ccivr.nms.queryservice.video': 'วิดีโอ',
  'ccivr.nms.process.String': 'Character String',
  'ccivr.nms.tansferservice.skillId': 'รหัสทักษะ',
  'ccivr.ivr.textcontent.notnull': 'ข้อมูลข้อความต้องไม่ว่าง',
  'ccivr.nms.varibalesselect.selectvariables': 'เลือกตัวแปร',
  'ccivr.nms.queryservice.querySkillMessage': 'สอบถามข้อมูล',
  'ccivr.ivr.variable.idisnull': 'ID ว่างเปล่า',
  'ccivr.ivrflow.createivrflow.mainfail': 'กระแสหลักมีอยู่แล้ว การเพิ่มล้มเหลว',
  'ccivr.ivrflow.variable.selectvariable': 'โปรดเลือกพารามิเตอร์ตัวแปร',
  'ccivr.ivr.error.paramNumlimit': 'จำนวนพารามิเตอร์ทั้งหมดต้องไม่เกิน 16',
  'ccivr.ivr.voiceupload.fileioerror': 'ข้อยกเว้นของไฟล์ IO',
  'ccivr.ivrflow.unlock.success': 'ปลดล็อกสถานะสำเร็จแล้ว',
  'ccivr.ivr.voiceedit.deleteodfsivrfail': 'การลบล้มเหลว ข้อมูล IVR ถูกครอบครอง!',
  'ccivr.nms.playset.extendparam': 'TTS ขยายพารามิเตอร์',
  'ccivr.flowtest.operatehangup': 'วางสาย',
  'ccivr.ivr.ivrflow.new': 'ใหม่',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease': 'ยืนยันการยกเลิกการเผยแพร่',
  'ccivr.nms.process.saveerror.unSucctransfer': 'การบันทึกล้มเหลว องค์ประกอบแผนภาพการถ่ายโอนมีความสำเร็จในการถ่ายโอน',
  'ccivr.nms.process.interfaceerr': 'ข้อผิดพลาดของอินเทอร์เฟซ โปรดตรวจสอบบันทึก',
  'ccivr.nms.process.processinformation': 'ข้อมูลการไหล',
  'ccivr.ivrflow.updateivrinfo.released': 'การไหลเผยแพร่แล้ว ยกเลิกการเผยแพร่และปรับเปลี่ยนแอ็ตทริบิวต์',
  'ccivr.nms.playcollectservice.second': 'วินาที',
  'ccivr.nms.timeselectservice.timeperiod': 'ช่วงเวลา',
  'ccivr.ivrflow.variable.typeerror': 'ประเภทตัวแปรอินพุตไม่สอดคล้องกัน',
  'ccivr.ivr.tracelog.accessCode': 'รหัสการเข้าถึงขั้นตอน',
  'ccivr.ivr.variable.defaulttypeerror': 'ประเภทค่าเริ่มต้นไม่ตรงกับประเภทตัวแปร',
  'ccivr.nms.conditionService.logicAND': 'และ',
  'ccivr.ivr.tracelog.result.transsuccess': 'สำเร็จ',
  'ccivr.ivr.ivrflow.property': 'แอตทริบิวต์',
  'ccivr.ivr.tracelog.actionParams': 'องค์ประกอบพารามิเตอร์หลัก',
  'ccivr.ivr.ivrflow.ivrtype': 'ประเภท',
  'ccivr.nms.queryservice.info': 'ข้อมูลการจัดลำดับจะถูกสอบถามโดยใช้โฟลว์ IVR เมื่อการโทรถูกโอนไปยังลำดับทักษะ',
  'ccivr.nms.queryservice.specialList.flagInfo': 'ค่า 0 แสดงว่าไม่มีรายการพิเศษ 1 บัตรประจําตัวมีอยู่ในรายการพิเศษ',
  'ccivr.nms.queryservice.specialList.flag': 'ธงของการอยู่ในรายการพิเศษ',
  'ccivr.ivr.error.uncommitted': 'ผู้ดูแลระบบไม่สามารถเล่นไฟล์ที่ไม่ได้ส่งมา',
  'ccivr.nms.timeselectservice.sunday': 'วันอาทิตย์',
  'ccivr.nms.leavemessageservice.end': 'ตามค่าเริ่มต้นการบันทึกจะสิ้นสุดลงด้วยปุ่มปอนด์ (#)',
  'ccivr.ivr.ivrflow.operate.cancelrelease.success': 'ยกเลิกสำเร็จแล้ว',
  'ccivr.ivr.voiceedit.deleteoldfilefailed': 'การลบไฟล์เก่าล้มเหลว',
  'ccivr.ivr.ivrflow.selectivrflow': 'เลือกขั้นตอน IVR',
  'ccivr.ivr.voice.clicktorecord': 'คลิกบันทึก',
  'ccivr.nms.tansferservice.transferWorkNo': 'โอนไปยังเจ้าหน้าที่',
  'ccivr.nms.shortmessageservice.processInformation': 'ข้อความ',
  'ccivr.ivr.name.existence': 'ชื่อนี้มีอยู่แล้ว โปรดป้อนรายการอื่น',
  'ccivr.nms.leavemessageservice.choose': 'เลือก',
  'ccivr.ivr.voiceadvice.cancel': 'ยกเลิก',
  'ccivr.nms.menuservice.title': 'การกำหนดค่าเมนู',
  'ccivr.ivr.ivrflow.operate.release.needexecptionhanding': 'โปรดกำหนดค่าและเผยแพร่กระบวนการจัดการข้อยกเว้นอย่างเป็นทางการ',
  'ccivr.ivr.tracelog.result.playover': 'เล่นให้สำเร็จ',
  'ccivr.nms.tansferservice.moduleDescription': 'คำอธิบายโมดูล',
  'ccivr.nms.timeselectservice.thursday': 'วันพฤหัสบดี',
  'ccivr.nms.conditionService.parameterEmpty': 'พารามิเตอร์ 1 ว่างเปล่า',
  'ccivr.nms.queryservice.paramValue': 'ค่า',
  'ccivr.ivr.voiceupload.inputlanguageerror': 'ไม่มีภาษา!',
  'ccivr.ivr.voice.clicktostop': 'หยุดคลิก',
  'ccivr.nms.playcollectservice.title': 'เล่นเสียงและรวบรวมตัวเลข',
  'ccivr.nms.tansferservice.transCallingnum': 'เบอร์ต้นทาง',
  'ccivr.ivr.ivrflow.state': 'สถานะ',
  'ccivr.ivr.voiceupload.defaultNoDe': 'ไม่สามารถลบภาษาเริ่มต้นได้',
  'ccivr.ivr.error.other': 'การดาวน์โหลดล้มเหลว',
  'ccivr.ivr.tracelog.cellType': 'ประเภทองค์ประกอบ',
  'ccivr.ivr.voiceupload.tipformat': 'ขณะนี้รองรับเฉพาะไฟล์เสียง WAV เท่านั้น ขนาดไฟล์ต้องไม่เกิน 2 MB',
  'ccivr.ivr.voiceupload.tipformat1M': 'ขณะนี้รองรับเฉพาะไฟล์เสียง WAV เท่านั้น ขนาดไฟล์ต้องไม่เกิน 1 MB',
  'ccivr.ivr.voiceupload.amountLimit5': 'สามารถเพิ่มไฟล์เสียงได้สูงสุดห้าไฟล์ในสถานการณ์นี้',
  'ccivr.ivr.variable.int': 'จำนวนเต็ม',
  'ccivr.ivr.voiceupload.new': 'ใหม่',
  'ccivr.ivr.voiceupload.choosestatus': 'เลือกสถานะการตรวจสอบ',
  'ccivr.ivrflow.grayrule.editsuccess': 'แก้ไขสำเร็จแล้ว',
  'ccivr.nms.businessinvokeservice.inpuparam': 'ใส่พารามิเตอร์',
  'ccivr.ivr.bussinessinvoke.bussinessinvoke': 'การกำหนดค่าอินเทอร์เฟซ',
  'ccivr.nms.brach.label': 'เลือกเงื่อนไข',
  'ccivr.ivr.voiceedit.pathcheckfailed': 'ตรวจสอบข้อมูลเส้นทาง SFTP ล้มเหลว',
  'ccivr.ivr.ivrflow.placeholder.ivrname': 'ป้อนชื่อโฟลว์',
  'ccivr.ivrflow.operate.replace.fail': 'แทนที่ล้มเหลว',
  'ccivr.flowtest.enter.phone': 'ป้อนหมายเลขโทรศัพท์',
  'ccivr.ivr.voicereview.filter': 'กรอง',
  'ccivr.flowtest.test.enter.callingnumber': 'กรุณากรอกหมายเลขโทรศัพท์ก่อน',
  'ccivr.nms.tansferservice.sampleForCallingnum': 'ตัวอย่าง: 660001 หรือ $ {\'$\'}{\'{\'}FLOW.accessCode{\'}\'}',
  'ccivr.ivr.voiceupload.tipmodel': 'คุณสามารถตั้งค่าไฟล์เสียงหรือข้อความของภาษาต่างๆพร้อมกันได้ (ความยาวสูงสุดต้องไม่เกิน 1024 bytes) ในระหว่างการเล่นเสียงระบบจะเล่นไฟล์เสียงที่เกี่ยวข้องโดยอัตโนมัติตามภาษาที่ผู้ใช้เลือก',
  'ccivr.ivr.voiceedit.passwdisnull': 'การค้นหาข้อมูลรหัสผ่าน SFTP ของศูนย์บริการล้มเหลว',
  'ccivr.common.warning.message': 'เลือกบันทึก',
  'ccivr.nms.conditionService.conditionalEdit': 'การแก้ไขเงื่อนไข',
  'ccivr.ivr.ivrflow.operate.ensureclone': 'ยืนยัน Flow Upgrading',
  'ccivr.nms.LanguageService.label': 'ภาษา',
  'ccivr.ivrflow.grayrule.existgrayflow': 'มีรุ่นสีเทาอยู่แล้ว',
  'ccivr.nms.satisfactionservice.descripLength': 'คำอธิบายคีย์แบบสำรวจความพึงพอใจไม่เกิน 200',
  'ccivr.flowtest.operatesuccess': 'สำเร็จ',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed': 'ค่าต้องไม่มีอักขระพิเศษและความยาวต้องไม่เกิน 50 bytes',
  'ccivr.nms.interface.outparamvalidate': 'ค่าพารามิเตอร์สามารถมีได้เฉพาะตัวเลขตัวอักษรและอักขระพิเศษต่อไปนี้: ._-',
  'ccivr.nms.interface.paramvalidate': 'พารามิเตอร์ควรมีเฉพาะตัวเลขอักขระภาษาอังกฤษหรือ _-',
  'ccivr.nms.interface.paramKeyValidate': 'อย่าใช้ชื่อพารามิเตอร์ที่กําหนดโดยตัวแปรในตัว (callId\, businessReqId\, callSorId\, currentTime) มิฉะนั้น\ การกําหนดค่าจะไม่ถูกต้อง',
  'ccivr.ivr.voiceupload.texttovoice': 'ข้อความที่ป้อนจะถูกแปลงเป็นเสียงโดยอัตโนมัติ',
  'ccivr.ivr.voicereview.upload': 'อัปโหลดแล้ว',
  'ccivr.nms.shortmessageservice.sendType': 'โหมดการส่งข้อความ',
  'ccivr.nms.playcollectservice.receivednum': 'ตัวเลขที่ได้รับ',
  'ccivr.nms.brach': 'สาขา',
  'ccivr.ivr.ivrflow.operate.queryInfo': 'ดูทั้งหมด',
  'ccivr.nms.tansferservice.transInputWorkNo': 'ป้อน ID ของเจ้าหน้าที่ที่โอนสายไปให้',
  'ccivr.ivr.ivrflow.yes': 'ใช่',
  'ccivr.nms.assignservice.Dataassignment': 'การกำหนดข้อมูล',
  'ccivr.ivr.error.download': 'ไม่สามารถดาวน์โหลดไฟล์จากเซิร์ฟเวอร์ SFTP',
  'ccivr.ivr.voicereview.tenantspace': 'ผู้เช่าบ้าน',
  'ccivr.ivr.voiceupload.audiochannelfailed': 'ไฟล์เสียงไม่ใช่ไฟล์โมโน',
  'ccivr.ivr.error.overpower': 'ผู้เช่าไม่ได้รับอนุญาต',
  'ccivr.ivr.voiceedit.inputmore': 'ค่าต้องไม่เกิน',
  'ccivr.nms.process.success': 'บันทึกโฟลว์สำเร็จแล้ว',
  'ccivr.nms.shortmessageservice.selectTemplate': 'เลือกเทมเพลต',
  'ccivr.whitelist.whiteport': 'Trustlist port',
  'ccivr.ivr.voiceadvice.setdefault': 'ตั้งเป็นค่าเริ่มต้น',
  'ccivr.nms.label.selectCert': 'เลือกใบรับรอง',
  'ccivr.ivr.variable.namevaluerule': 'ชื่อประกอบด้วยอักขระพิเศษ โปรดแก้ไข',
  'ccivr.ivr.element.namecheck': 'ชื่อประกอบด้วยอักขระพิเศษ โปรดแก้ไข',
  'ccivr.nms.leavemessageservice.recordparam': 'พารามิเตอร์การบันทึก',
  'ccivr.ivrflow.grayrule.selectgrayrule': 'เลือกกฎสีเทา',
  'ccivr.nms.leavemessageservice.leavemessage': 'ทิ้งข้อความไว้',
  'ccivr.nms.tansferservice.passingParameters': 'พารามิเตอร์การถ่ายโอน',
  'ccivr.ivr.voiceadvice.submit': 'ส่ง',
  'ccivr.nms.interface.certFile': 'ไฟล์ใบรับรอง',
  'ccivr.ivr.ivrflow.operate': 'การดำเนินการ',
  'ccivr.ivrflow.grayrule.endnum': 'หมายเลขสิ้นสุด',
  'ccivr.ivrflow.release.formalinfo': 'หลังจากการเปิดตัว, การไหลของรุ่นนี้จะถูกดําเนินการสําหรับตัวเลขทั้งหมด.<br /> หากคุณต้องการแก้ไขโฟลว์คุณต้องอัปเกรดเวอร์ชันใหม่และแก้ไขเวอร์ชัน',
  'ccivr.ivr.voiceupload.queryReferenceFlow': 'ดู',
  'ccivr.ivrflow.grayrule.inputbeginnum': 'ตัวอย่าง: 12366668888',
  'ccivr.nms.conditionService.add': 'ใหม่',
  'ccivr.nms.conditionService.createCondition': 'เพิ่มเงื่อนไข',
  'ccivr.ivr.ivrflow.operate.clone.success': 'กระบวนการอัพเกรดสําเร็จแล้ว',
  'ccivr.nms.shortmessageservice.normalMessage': 'ข้อความสั้น ๆ',
  'ccivr.ivr.bussinessinvoke.float': 'ลอย',
  'ccivr.nms.leavemessageservice.choose.localtionID': 'บันทึก localtionID',
  'ccivr.ivr.voicereview.play': 'เล่น',
  'ccivr.flowtest.fail': 'ความล้มเหลว',
  'ccivr.ivr.voiceupload.vedionamewrong': 'การอัปโหลดไฟล์ ชื่อไฟล์ ตรวจสอบล้มเหลว!',
  'ccivr.ivr.variable.management': 'การจัดการตัวแปร',
  'ccivr.nms.playcollectservice.Receivemaxnum': 'ค่าสูงสุด จำนวนเลขที่ได้รับ',
  'ccivr.ivr.ivrflow.operate.release': 'ปล่อย',
  'ccivr.ivrflow.error.deleteivrflow.part.released': 'โฟลว์ IVR ที่เลือกบางส่วนได้รับการเผยแพร่แล้ว ยกเลิกการเผยแพร่ก่อนที่จะลบออก',
  'ccivr.ivr.name.errorspecial': 'ชื่อประกอบด้วยอักขระพิเศษ โปรดแก้ไข',
  'ccivr.ivr.tracelog.result.syserr': 'ระบบผิดพลาด',
  'ccivr.ivr.ivrflow.operate.delete': 'ลบ',
  'ccivr.nms.playservice.chose': 'เนื้อหาการเล่นจะถูกเลือกจากไฟล์เสียงที่ได้รับการอัปโหลดและตรวจสอบ',
  'ccivr.ivr.variable.variableisreferenced': 'ตัวแปรอ้างอิงโดยโฟลว์',
  'ccivr.ivr.bussinessinvoke.save': 'บันทึก',
  'ccivr.nms.playcollectservice.tip8': 'ในระหว่างการรวบรวมตัวเลขผู้ใช้ป้อนรหัสปอนด์ (#) เพื่อสิ้นสุดการรวบรวมตัวเลข กรุณาแจ้งด้วยเสียง',
  'ccivr.ivr.message.uploadimport.success': 'เพิ่มสำเร็จแล้ว',
  'ccivr.nms.playcollectservice.tip7': 'เมื่อจำนวนครั้งความล้มเหลวสะสมเกินค่าสูงสุดระบบจะดำเนินการออกจากความล้มเหลวทั่วไป',
  'ccivr.ivr.variable.textvaluelimit': 'ข้อความต้องมีอักขระไม่เกิน 120 ตัว',
  'ccivr.nms.playcollectservice.tip6': 'หากการรวบรวมตัวเลขล้มเหลว คุณไม่จำเป็นต้องเล่นเสียง หากไม่ได้เลือกไฟล์เสียงระบบจะเล่นเสียงเตือนอีกครั้งหลังจากความล้มเหลว',
  'ccivr.nms.playcollectservice.tip5': 'หากข้อผิดพลาดเกิดขึ้นระหว่างการรวบรวมตัวเลข ระบบจะบันทึกข้อผิดพลาดดำเนินการรวบรวมตัวเลขโดยอัตโนมัติและรอการรวบรวมตัวเลขต่อไป',
  'ccivr.nms.playcollectservice.tip4': 'หากเวลาที่ผู้สมัครสมาชิกหยุดอินพุตเกินเวลารอการรวบรวมหลักที่กำหนดไว้ล่วงหน้า ระบบจะบันทึกความล้มเหลว นอกจากนี้ระบบจะดำเนินการหมดเวลาการรวบรวมตัวเลขโดยอัตโนมัติและรอการรวบรวมตัวเลข',
  'ccivr.whitelist.whitelistmanage': 'การจัดการรายการที่เชื่อถือได้',
  'ccivr.nms.playcollectservice.tip3': 'ในระหว่างการรวบรวมตัวเลขผู้ใช้ป้อนรหัสปอนด์ (#) เพื่อสิ้นสุดการรวบรวมตัวเลข กรุณาแจ้งด้วยเสียง(ไม่จําเป็นต้องกดปุ่มปอนด์ (#) เพื่อรวบรวมตัวเลขในองค์ประกอบไดอะแกรมการกําหนดค่าเมนู)',
  'ccivr.nms.playcollectservice.tip2': 'โปรดเลือกพารามิเตอร์ประเภทสตริงที่คุณเพิ่มในข้อมูลกระบวนการ',
  'ccivr.nms.playcollectservice.tip1': 'เลือกพารามิเตอร์เพื่อบันทึกอินพุตของผู้ใช้',
  'ccivr.flowtest.begintest': 'เริ่ม',
  'ccivr.nms.process.paramnameNoCN': 'ชื่อพารามิเตอร์ไม่ถูกต้อง',
  'ccivr.nms.menuservice.button': 'ปุ่มต่างๆ',
  'ccivr.ivr.ivrflow.operate.edit': 'แก้ไข',
  'ccivr.ivr.bussinessinvoke.error': 'ข้อผิดพลาด',
  'ccivr.ivr.voiceupload.submit': 'ส่ง',
  'ccivr.ivr.voiceupload.onlyOneData': 'เก็บบันทึกภาษาอย่างน้อยหนึ่งภาษา',
  'ccivr.ivrflow.operate.replace.success': 'แทนที่สำเร็จแล้ว',
  'ccivr.ivr.voiceupload.audiosamplebitfailed': 'หมายเลขเฟรมไฟล์เสียงไม่ใช่ 8BIT',
  'ccivr.nms.playservice.multivoice.type.date': 'วันที่ (yyyymmdd)',
  'ccivr.ivr.name.toolong': 'ชื่อเกินความยาวสูงสุดที่อนุญาต กรุณาลองอีกครั้ง.',
  'ccivr.ivr.voicereview.all': 'ทั้งหมด',
  'ccivr.ivr.error.releaseFailed': 'ล้มเหลวในการเผยแพร่สคริปต์โดยอัตโนมัติ',
  'ccivr.nms.leavemessageservice.recordfile': 'การบันทึกไฟล์',
  'ccivr.nms.conditionService.logicOperator': 'ตรรกะ',
  'ccivr.nms.tansferservice.queueindex': 'ตำแหน่งลำดับ',
  'ccivr.nms.tansferservice.queuenum': 'จํานวนคิว',
  'ccivr.ivr.voicereview.filetype': 'ประเภท',
  'ccivr.ivr.tracelog.result.hangup': 'วางสาย',
  'ccivr.ivr.tracelog.traceConfig': 'การกำหนดค่าการติดตามรหัสการเข้าถึง',
  'ccivr.ivr.variable.deleteselectvariable': 'ลบตัวแปร',
  'ccivr.ivr.ivrflow.operate.create.accesscode.fail': 'ไม่สามารถเพิ่มรหัสการเข้าถึงเป็น ODFS ในขณะที่เผยแพร่โฟลว์ IVR',
  'ccivr.nms.playcollectservice.voicefile': 'ไฟล์เสียง',
  'ccivr.nms.conditionService.condition': 'ชื่อเงื่อนไข',
  'ccivr.ivr.voiceupload.chinese': 'ชาวจีน',
  'ccivr.ivr.ivrflow.edit': 'การแก้ไข',
  'ccivr.ivr.voiceadvice.confirm': 'ตกลง',
  'ccivr.ivr.voiceadvice.lengthError': 'ความยาวไม่เกิน 100 ตัวอักษร',
  'ccivr.nms.process.sysparam': 'พารามิเตอร์ของระบบ',
  'ccivr.nms.conditionService.operat': 'การดำเนินการ',
  'ccivr.grayrule.errorinfo.containspecialcha': 'พารามิเตอร์ ruleName ประกอบด้วยอักขระพิเศษ',
  'ccivr.nms.ChangeMediaService.placeholder': 'เปลี่ยนสื่อ',
  'ccivr.nms.userLevelService.placeholder': 'ตั้งค่าระดับผู้ใช้',
  'ccivr.nms.userLevelService.label': 'ระดับผู้ใช้',
  'ccivr.nms.userLevelService.example': 'ตัวอย่าง: 1-14 or FLOW.ชื่อตัวแปร',
  'ccivr.nms.answerService.placeholder': 'คําตอบ',
  'ccivr.nms.ringService.placeholder': 'เสียงเรียกเข้า',
  'ccivr.nms.ringService.videomodel': 'โหมดวิดีโอ',
  'ccivr.nms.ringService.sendonly': 'ส่งเท่านั้น',
  'ccivr.nms.ringService.recvonly': 'รับเท่านั้น',
  'ccivr.nms.ringService.sendrecv': 'โอนถ่ายได้',
  'ccivr.ivrflow.error.deleteivrflow.released': 'โฟลว์ IVR ที่เลือกถูกนำออกใช้แล้ว ยกเลิกการเผยแพร่ก่อนที่จะลบ',
  'ccivr.ivr.voicereview.refuse': 'ปฏิเสธ',
  'ccivr.ivrflow.cancelrelease.accesscode.delete.failed': 'ไม่สามารถลบรหัสการเข้าถึงเป็น ODFS ในขณะที่ยกเลิกโฟลว์ IVR ที่เผยแพร่ออกมา',
  'ccivr.ivr.bussinessinvoke.validatemessage': 'มีพารามิเตอร์ที่มีชื่อเดียวกันอยู่แล้ว',
  'ccivr.nms.leavemessageservice.flownamerepeat': 'ชื่อไฟล์บันทึกและ localtionID ไม่สามารถใช้ตัวแปรเดียวกันได้',
  'ccivr.nms.playservice.interrupt': 'กดปุ่มใด ๆ เพื่อขัดจังหวะการเล่น',
  'ccivr.nms.timeselectservice.title': 'การเลือกเวลา',
  'ccivr.ivr.voiceupload.selectvideo': 'เลือกไฟล์วิดีโอ',
  'ccivr.nms.process.saveerror.connection': 'การบันทึกล้มเหลว มีบรรทัดไม่ถูกต้อง',
  'ccivr.nms.playcollectservice.collectwaittime': 'รอเวลาสำหรับการรวบรวมตัวเลข',
  'ccivr.nms.conditionService.delete': 'ลบ',
  'ccivr.nms.playcollectservice.playset.reset': 'รีเซ็ต',
  'ccivr.nms.playcollectservice.enterCollectTimeout': 'โปรดป้อนช่วงเวลาหมดเวลาสําหรับการเก็บรวบรวมตัวเลข',
  'ccivr.nms.process.saveerror.select': 'เลือกประเภทการเปลี่ยนแปลง',
  'ccivr.ivr.voiceadvice.currentdefault': 'ค่าเริ่มต้น',
  'ccivr.nms.playservice.chosevideo': 'เนื้อหาที่จะเล่นจะถูกเลือกจากวิดีโอที่ได้รับการอัปโหลดและตรวจสอบ',
  'ccivr.ivr.ivrflow.flowquote': 'มีการอ้างอิง',
  'ccivr.nms.queryservice.skillMsg': 'ข้อมูลการจัดลำดับ',
  'ccivr.nms.queryservice.specialListQuery': 'ข้อมูลรายการพิเศษ',
  'ccivr.ivr.variable.specialcharacters': 'ไม่อนุญาตให้ใช้อักขระพิเศษหรืออักษรจีน',
  'ccivr.ivr.voicereview.waitreview': 'เพื่อตรวจสอบ',
  'ccivr.ivr.voice.exception.recordfail': 'ข้อผิดพลาดในการบันทึก',
  'ccivr.ivr.error.advicelimit': 'ความคิดเห็นของบทวิจารณ์ต้องมีอักขระไม่เกิน 100 ตัว',
  'ccivr.nms.businessinvokeservice.selectservice': 'เลือกอินเทอร์เฟซ',
  'ccivr.nms.queryservice.assignmentParameters': 'พารามิเตอร์ที่จะตั้งค่า',
  'ccivr.ivr.voicereview.chinese': 'ภาษาจีน',
  'ccivr.ivrflow.unlock.fail': 'สถานะปลดล็อกล้มเหลว',
  'ccivr.ivr.tracelog.callid': 'Callid',
  'ccivr.ivr.variable.modifyvariable': 'แก้ไขตัวแปร',
  'ccivr.ivr.pageurls.errorsave': 'ข้อผิดพลาด',
  'ccivr.nms.shortmessageservice.smsType': 'ช่องทาง',
  'ccivr.ivr.ivrflow.operate.ensureclone.info': 'คุณแน่ใจหรือไม่ว่าต้องการอัพเกรดโฟลว์',
  'ccivr.nms.transInputWorkNo.sample': 'ตัวอย่าง: 28956666 หรือ {\'$\'}{\'{\'}FLOW.currentWorkNo{\'}\'}',
  'ccivr.nms.shortmessageservice.addresseeDesc': 'เลือก, เบอร์มือถือเบอร์เดียว ถ้าว่าง จะส่งให้ผู้โทร',
  'ccivr.flowtest.enter': 'ป้อนหมายเลขโทร.',
  'ccivr.nms.businessinvokeservice.overtime': 'ช่วงหมดเวลา',
  'ccivr.ivr.voiceupload.audiotypefailed': 'รูปแบบไฟล์เสียงไม่ใช่ ALAW',
  'ccivr.nms.process.number': 'จำนวนเต็ม',
  'ccivr.nms.playcollectservice.changetomultivoice': 'เสียงแปรผัน',
  'ccivr.ivr.error.connect': 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ SFTP',
  'ccivr.flowtest.test.error.tip': 'โฟลว์ไม่ได้กำหนดค่าหรือใช้',
  'ccivr.flowtest.pleasebegintest': 'กระบวนการสิ้นสุดลงแล้ว',
  'ccivr.ivr.variable.desclimit': 'คำอธิบายต้องมีอักขระไม่เกิน 120 ตัว',
  'ccivr.nms.playservice.multivoice.type.price': 'ราคา',
  'ccivr.ivr.tracelog.result.finshed': 'เสร็จแล้ว',
  'ccivr.nms.playcollectservice.onenumber': 'คอลเลกชันหนึ่งหลัก',
  'ccivr.nms.menuservice.movedown': 'ลง',
  'ccivr.ivr.variable.defaultValue': 'ค่าเริ่มต้น',
  'ccivr.nms.process.saveerror.existnullentity': 'มีองค์ประกอบแผนภาพข้อมูลที่ว่างเปล่า',
  'ccivr.ivrflow.operate.replace': 'แทนที่',
  'ccivr.ivrflow.flowAccessCode': 'รหัสการเข้าถึงประมวลผล',
  'ccivr.ivr.voiceedit.cancel': 'ยกเลิก',
  'ccivr.nms.tansferservice.samenamevalidate': 'ชื่อพารามิเตอร์ที่ซ้ำกัน',
  'ccivr.nms.businessinvokeservice.info2': 'หากเกินช่วงหมดเวลาการเรียกใช้โมดูลความล้มเหลวในการเรียกใช้จะถูกดำเนินการ',
  'ccivr.nms.businessinvokeservice.info1': 'ต้องกำหนดค่าอินเทอร์เฟซที่มีอยู่ในหน้าการกำหนดค่าอินเทอร์เฟซ',
  'ccivr.ivr.variable.defaultvalueerror': 'ค่าเริ่มต้นประกอบด้วยอักขระพิเศษ โปรดแก้ไข',
  'ccivr.ivr.voicereview.thailand': 'ไทย',
  'ccivr.ivr.voicereview.portuguese': 'โปรตุเกส',
  'ccivr.ivr.voicereview.spanish': 'สเปน',
  'ccivr.ivr.voicereview.french': 'ฝรั่งเศส',
  'ccivr.ivr.voicereview.arabic': 'ภาษาอาหรับ',
  'ccivr.ivr.voicereview.german': 'เยอรมัน',
  'ccivr.ivr.voicereview.language0': 'ชาวจีน',
  'ccivr.ivr.voicereview.language1': 'ภาษาอังกฤษ',
  'ccivr.ivr.voicereview.language24': 'ไทย',
  'ccivr.ivr.voicereview.language17': 'โปรตุเกส',
  'ccivr.ivr.voicereview.language7': 'ฝรั่งเศส',
  'ccivr.ivr.voicereview.language81': 'ภาษาอาหรับ',
  'ccivr.ivr.voicereview.language8': 'เยอรมัน',
  'ccivr.ivr.voicereview.language20': 'สเปน',
  'ccivr.ivr.voiceupload.selectLangNull': 'ไม่มีภาษาที่เลือก โปรดเลือกภาษา',
  'ccivr.ivr.voiceupload.selectLangRe': 'ภาษาปัจจุบันถูกเลือกแล้ว โปรดเลือกภาษาอื่น',
  'ccivr.nms.assignservice.ieDataAssign': 'การกําหนดข้อมูล IE',
  'ccivr.nms.assignservice.ietype': 'ประเภท IE',
  'ccivr.ivrflow.ivrflowtype.validateFailed': 'ประเภทการไหลไม่ถูกต้อง',
  'ccivr.ivrflow.error.deleteivrflow.fail': 'ไม่มีกระแส',
  'ccivr.ivr.languageid.repeat.fail': 'ไม่มี wikaId',
  'ccivr.ivr.ivrtextexception.fail': 'ไม่สามารถเพิ่มข้อความ IVR ได้',
  'ccivr.ivr.illegal.fail': 'พารามิเตอร์ไม่ถูกต้อง',
  'ccivr.ivr.type.fail': 'ประเภทอัปโหลดไม่ถูกต้อง',
  'ccivr.ivr.delete.fail': 'ล้มเหลวในการลบไฟล์',
  'ccivr.ivr.create.exception': 'การเพิ่มล้มเหลว',
  'ccivr.whitelist.validator.ip': 'ที่อยู่ IP ไม่ถูกต้อง (IPv4 หรือ IPv6 หรือชื่อโดเมน)',
  'ccivr.interface.typeerror': 'ปัจจุบันรองรับเฉพาะ GET และ POST เท่านั้น',
  'ccivr.nms.process.parameterencryptips': 'โปรดทราบว่าพารามิเตอร์ที่ไม่ได้เข้ารหัสจะเก็บค่าพารามิเตอร์ไว้ในข้อความธรรมดาซึ่งอาจก่อให้เกิดความเสี่ยงด้านความปลอดภัยหากใช้',
  'ccivr.ivrflow.specify.accessCode': 'ระบุรหัสการเข้าถึงช่องสัญญาณ',
  'ccivr.ivr.transferRecord.startTime': 'เวลาเริ่มต้น',
  'ccivr.ivr.transferRecord.endTime': 'เวลาสิ้นสุด',
  'ccivr.ivr.transferRecord.callNumber': 'หมายเลขโทรศัพท์',
  'ccivr.ivr.transferRecord.flowAccessCode': 'รหัสการเข้าถึงกระบวนการ',
  'ccivr.ivr.transferRecord.transNumber': 'หมายเลขการส่งต่อ',
  'ccivr.ivr.transferRecord.transResult': 'การถ่ายโอนผล',
  'ccivr.ivr.transferRecord.recordedFile': 'แฟ้มบันทึก',
  'ccivr.ivr.transferRecord.play': 'การเล่น',
  'ccivr.ivr.transferRecord.download': 'การดาวน์โหลด',
  'ccivr.ivr.transferRecord.success': 'ความสําเร็จ',
  'ccivr.ivr.transferRecord.fail': 'ล้มเหลว',
  'ccivr.ivr.transferRecord.noMoreThan90Days': 'ช่วงระยะเวลาไม่เกิน 90 วัน',
  'ccivr.transferrecord.download.record': 'ดาวน์โหลดบันทึกการถ่ายโอนการไหล:',
  'ccivr.transferrecord.play.record': 'การเล่นกระบวนการถ่ายโอนบันทึก:',
  'ccivr.transferrecord.play.timeCompare': 'เวลาเริ่มต้นไม่สามารถช้ากว่าเวลาสิ้นสุด',
  'ccivr.ivr.transferRecord.init': 'การเริ่มต้นใช้งาน',
  'ccivr.ivr.transferRecord.noright': ' ผู้ใช้ปัจจุบันไม่มีสิทธิ์ดาวน์โหลดบันทึก',
  'ccivr.ivr.transferRecord.query.failed ': ' ล้มเหลวในการ Query Transfer Recordings',
  'ccivr.ivr.transferRecord.connect.uap.failed ': ' ล้มเหลวในการเชื่อมต่อกับเซิร์ฟเวอร์ไฟล์',
  'ccivr.ivr.transferRecord.connect.file.notexist': 'ดาวน์โหลดไฟล์บันทึกไม่สําเร็จ โปรดตรวจสอบว่ามีไฟล์อยู่หรือไม่',
  'ccivr.nms.questionnaireService.placeholder': 'แบบสอบถาม',
  'ccivr.nms.questionnaireService.surveyname': 'ชื่อแบบสอบถาม',
  'ccivr.nms.questionnaireService.question': 'คําถามแบบสอบถาม',
  'ccivr.nms.questionnaireService.info': 'องค์ประกอบแผนภาพแบบสอบถามจะใช้ในงานโทรออกอัตโนมัติ จําเป็นต้องเชื่อมโยงกระบวนการแบบสอบถาม IVR',
  'ccivr.nms.tansferservice.idleAgentCount': 'จํานวนตัวแทนที่ไม่ได้ใช้งาน',
  'ccivr.nms.process.saveerror.queuing': 'ป้อนอย่างน้อยหนึ่งตําแหน่งคิวจํานวนตัวแทนออนไลน์และเวลารอที่คาดว่าจะ',
  'ccivr.nms.process.saveerror.queues': 'ป้อนอย่างน้อยหนึ่งตําแหน่งคิวจํานวนตัวแทนออนไลน์จํานวนตัวแทนที่ไม่ได้ใช้งานและเวลารอที่คาดหวัง',
  'ccivr.ivr.voicepop.inputname': 'ป้อนชื่อเสียง IVR',
  'ccivr.ivr.voicereview.reviewmethod': 'โหมดการตรวจสอบทรัพยากรเสียง',
  'ccivr.ivr.voicereview.manualreview': 'รีวิวด้วยตนเอง',
  'ccivr.ivr.voicereview.autopass': 'ผ่านอัตโนมัติ',
  'ccivr.ivr.voiceupload.exceedLimit': 'จํานวนไฟล์ IVR เกินขีดจํากัด',
  'ccivr.ivr.voiceupload.file.content': 'เนื้อหา',
  'ccivr.ivr.voiceupload.file.update.content': 'ปรับปรุงเนื้อหา',
  'ccivr.ivr.voiceupload.file.update.time': 'อัพเดทเวลา',
  'ccivr.ivr.voiceupload.file.update.status': 'สถานะ',
  'ccivr.ivr.voiceupload.file.update.comments': 'ความคิดเห็น',
  'ccivr.ivr.voiceupload.file.update.operation': 'ปฏิบัติการ',
  'ccivr.ivr.voiceupload.file.update.delete': 'ลบ',
  'ccivr.ivr.voiceupload.file.update.submit': 'ส่ง',
  'ccivr.ivr.voiceupload.file.update': 'ปรับปรุง',
  'ccivr.ivr.flowAccessCode.repeat': 'มีรหัสการเข้าถึงอยู่แล้ว',
  'ccivr.ivr.error.releaseFailed.tips': 'การเผยแพร่แบบสอบถามอัตโนมัติล้มเหลว โปรดปรับแต่งและเผยแพร่แบบสอบถามด้วยตนเองหลังจากการเผยแพร่แบบสอบถามด้วยตนเอง',
  'ccivr.ivr.ivrflow.operate.add': 'เพิ่ม',
  'ccivr.nms.playservice.chose.survey': 'เลือกหรือเพิ่มเสียงเสียงจากเสียงที่อัปโหลดและตรวจสอบแล้ว',
  'ccivr.nms.playservice.chosetts.survey': 'เลือกหรือเพิ่มข้อความจากข้อความ TTS ที่อัปโหลดและตรวจสอบแล้ว ต้องมีทรัพยากร TTS',
  'ccivr.nms.satisfactionservice.buttonNumber.Number': 'จํานวนคีย์ไม่เกิน9และไม่น้อยกว่า1',
  'ccivr.nms.lightweight.tips': 'โปรดดําเนินการในแบบสอบถามแบบเบา',
  'ccivr.conditionService.param.tips': 'เมื่อตั้งค่าพารามิเตอร์ 2 เป็นค่าคงที่ของสตริง จะต้องเพิ่มเครื่องหมายคำพูดเพื่อใช้งานได้อย่างถูกต้อง',
  'ccivr.nms.conditionService.sample': 'เงื่อนไขการป้อนแสดงความคิดเห็น การแสดงความคิดเห็นสามารถมีชื่อพารามิเตอร์ค่าและการดำเนินการที่ซับซ้อน พารามิเตอร์อ้างอิงใน FLOW รูปแบบชื่อพารามิเตอร์ ตัวอย่าง: SYS.currentNumber == FLOW.RechargeNumber && (FLOW.AccountBalance - 20)> = FLOW.BillAmount',
  'ccivr.nms.conditionService.tips': 'นิพจน์ที่มีเงื่อนไขสามารถประกอบด้วยตัวแปรค่าและการดําเนินการที่ซับซ้อน ตัวดําเนินการเปรียบเทียบระหว่างค่าหรือสตริงอักขระสนับสนุน > < >= <= !=. ตัวดําเนินการ Boolean รองรับ && {\'|\'}{\'|\'} == !=. ตัวอย่าง: SYS.currentNumber==FLOW.RechargeNumber && (FLOW.AccountBalance-20)>=FLOW.BillAmount',
  'ccivr.TimeSelectService.branch.wrongtime': 'ผิดเวลา',
  'ccivr.ivr.voiceupload.VoiceNotificationTextDes': 'เนื้อหาเทมเพลตไม่สามารถเริ่มต้นด้วยตัวแปรได้ ความยาวตัวแปรแต่ละตัวไม่เกิน 32 ไบต์ ขอแนะนําให้แยกออกเป็นหลายตัวแปรหากตัวแปรแต่ละตัวมีความยาวมากกว่า 32 ไบต์.',
  'ccivr.playservice.branch.query': 'การสืบค้นผิดพลาด',
  'ccivr.businessInvokeService.err': 'ล้มเหลวในการเรียกอินเทอร์เฟซ',
  'ccivr.playservice.branch.ring': 'เสียงเรียกเข้าล้มเหลว',
  'ccivr.playservice.branch.userLevel': 'การตั้งค่าล้มเหลว',
  'ccivr.tansferservice.branch.transferErr': 'การโอนผิดพลาด',
  'ccivr.nms.tansferservice.transferLastAgent.type.time': 'ช่วงเวลา',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.label': 'มิน',
  'ccivr.nms.transferservice.choose.recordfilename.remark': 'ใช้ในโหมดพูลของศูนย์บริการลูกค้า',
  'ccivr.playservice.branch.change': 'การเปลี่ยนผิดพลาด',
  'ccivr.nms.conditionService.conditionBranch': 'สาขาเงื่อนไข',
  'ccivr.playservice.branch.playerr': 'การเล่นผิดพลาด',
  'ccivr.PlayCollectService.branch.err': 'ล้มเหลวในการเล่นประกาศและรวบรวมตัวเลข',
  'ccivr.nms.playcollectservice.warntype': 'ประเภท Prompt',
  'ccivr.ivr.bussinessinvoke.paramTip': 'ชื่อพารามิเตอร์, ตัวอย่าง: GLOBAL.language หรือ FLOW.testid',
  'ccivr.nms.tansferservice.transferLastAgent.sampleForCallingnum': 'ตัวอย่าง: 660001 หรือ {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday': 'วันนั้น',
  'ccivr.ivr.description.containspecialcharacter': 'แม่แบบมีอักขระพิเศษ~',
  'ccivr.ivr.voiceupload.VoiceNotificationTextrule': 'กฎเทมเพลตการแจ้งเตือนเสียงเพิ่มเติม โปรดดูที่สัญลักษณ์ i ในช่องป้อนข้อมูล',
  'ccivr.ivr.voiceupload.referenceText': 'ข้อความแจ้งเตือนเสียง',
  'ccivr.nms.queryservice.specifiedSkill.result': 'ชื่อคิว',
  'ccivr.nms.tansferservice.transferLastAgent.transCallingnum': 'หมายเลขโทรเข้า',
  'ccivr.ivrvoice.play.voicetimelimit': 'ลองฟังเสียงที่ยาวขึ้น โปรดลดเนื้อหาข้อความแล้วลองใช้เอฟเฟกต์การฟังอีกครั้ง',
  'ccivr.ivr.voice.pitch': 'เสียง',
  'ccivr.ivr.voice.voicename': 'สีเสียง',
  'ccivr.ivr.description.variablelimit': 'ตัวแปรแม่แบบต้องไม่เกิน 10 ตัว',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.sample': 'ตัวอย่าง: 100, โอนไปยังตัวแทนที่ติดต่อล่าสุดภายใน 100 นาที',
  'ccivr.nms.tansferservice.transferLastAgent.type.none.info': 'โอนไปยังเจ้าหน้าที่ที่ติดต่อล่าสุด',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.info': 'ถ่ายโอนไปยังเจ้าหน้าที่ที่ติดต่อล่าสุดในช่วงระยะเวลา',
  'ccivr.ivr.voiceupload.query': 'รายละเอียด',
  'ccivr.ivr.voice.scene.voicenotification': 'การแจ้งเตือนด้วยเสียง',
  'ccivr.ivr.voiceupload.auditionTextlimit': 'เนื้อหาข้อความทดลองใช้ต้องไม่เกิน 300 ไบต์',
  'ccivr.nms.leavemessageservice.agent': 'ระบุการประมวลผลตัวแทน',
  'ccivr.ivr.voicereview.voiceId': 'การแสดงตัวด้วยเสียง',
  'ccivr.nms.tansferservice.transferLastAgent.mustbeaccesscode': 'หมายเลขโทรโทรเข้าคือหมายเลขโทรเข้าของลูกค้า เช่น SYS.callingNumber{}}',
  'ccivr.nms.queryservice.specifiedSkill': 'ชื่อคิว',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime': 'เวลาเริ่มต้น',
  'ccivr.nms.queryservice.specifiedSkill.paramValue.sample': 'ตัวอย่าง: 101 หรือ {\'$\'}{\'{\'}FLOW.workNo{\'}\'}',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.sample': 'โปรดเลือกเวลาเริ่มต้น',
  'ccivr.ivr.transferRecord.query.failed': 'ล้มเหลวในการสืบค้นการบันทึกการถ่ายโอนข้อมูล',
  'ccivr.ivr.transferRecord.connect.uap.failed': 'การเชื่อมต่อเซิร์ฟเวอร์แฟ้มล้มเหลว',
  'ccivr.ivr.voice.speed': 'ความเร็วเสียง',
  'ccivr.nms.queryservice.specifiedSkill.paramValue': 'เลขประจําตัว',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.info': 'โอนไปยังเจ้าหน้าที่ที่ติดต่อมาเมื่อเร็ว ๆ นี้ตั้งแต่เวลาเริ่มต้นจนถึงปัจจุบัน',
  'ccivr.nms.leavemessageservice.choose.agent': 'ระบุหมายเลขประจํางาน',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday.info': 'โอนไปยังเจ้าหน้าที่ที่ติดต่อล่าสุดในวันเดียวกัน',
  'ccivr.ivr.description.variableerror': 'รูปแบบตัวแปรแม่แบบผิดพลาด',
  'ccivr.ivr.voiceadvice.audition': 'ลองดู',
  'ccivr.ivr.connectsftp.uploadfile.exception': 'การเชื่อมต่อ SFTP ล้มเหลว  การอัปโหลดแฟ้มล้มเหลว',
  'ccivr.ivr.description.voiceNotificationlimit': 'คําอธิบายข้อความต้องไม่เกิน 500 ไบต์',
  'ccivr.nms.leavemessageservice.choose.agent.sample': 'ตัวอย่าง: 101 หรือ {\'$\'}{\'{\'}FLOW.workNo{\'}\'}',
  'ccivr.nms.tansferservice.transferLastAgent.type': 'โหมดผู้รู้จัก',
  'ccivr.ivr.voice.volume': 'ระดับเสียง',
  'ccivr.nms.tansferservice.transferLastAgent.type.none': 'ปริยาย',
  'ccivr.ivr.voiceupload.VoiceNotificationtipmodel': 'คุณสามารถตั้งค่าไฟล์เสียงหรือพากย์เสียงในภาษาต่าง ๆ ได้พร้อม ๆ กัน (ต้องไม่เกิน 500 ไบต์) การเปิดเสียงจะสลับการเล่นไฟล์ที่สอดคล้องกันโดยอัตโนมัติตามตัวเลือกภาษาของผู้ใช้',
  'ccivr.ivr.voiceupload.fileformatconverterror': 'เกิดข้อผิดพลาดในการแปลงรูปแบบแฟ้ม หรือการปรับแต่ง ffmpeg ผิดพลาด',
  'ccivr.nms.tansferservice.transferLastAgent': 'แปลงวิดีโอสําหรับคนรู้จัก',
  'ccivr.leaveMessage.maxtime.range': 'ระยะเวลาการบันทึกสูงสุดอยู่ในช่วง 10 ถึง 180 วินาที',
  'ccivr.ivr.voiceupload.fileName.validate': 'ไม่สามารถมีอักขระพิเศษดังต่อไปนี้:',
  'ccivr.nms.play.method': 'วิธีการ',
  'ccivr.nms.play.source': 'ทรัพยากร',
  'ccivr.ivr.ivrflow.operate.release.referencedflow': 'ไม่สามารถยกเลิกการเผยแพร่ได้ กระแสได้รับการอ้างอิง',
  'ccivr.ivr.voice.scene.2d.avatar': 'มนุษย์เสมือน',
  'ccivr.ivr.voice.picturechoose': 'การเลือกแฟ้มภาพ',
  'ccivr.ivr.voiceupload.selectpicture': 'เลือกแฟ้มภาพ',
  'ccivr.ivr.voiceupload.tippictureformat': 'ปัจจุบันรองรับเฉพาะไฟล์ภาพ JPG, PNG และ JPEG เท่านั้น ความละเอียดของไฟล์ต้องไม่เกิน 720p และขนาดไฟล์ต้องไม่เกิน 2 MB',
  'ccivr.ivr.voiceupload.tippicturemodel': 'คุณสามารถตั้งค่าไฟล์ภาพเป็นภาษาต่าง ๆ ได้ในเวลาเดียวกัน ระบบจะสลับไปยังไฟล์ภาพที่สอดคล้องกันโดยอัตโนมัติตามภาษาที่ผู้ใช้เลือกในระหว่างการสังเคราะห์บุคคลดิจิทัล',
  'ccivr.ivr.voiceupload.tip2DVideoformat': 'ปัจจุบันรองรับเฉพาะไฟล์วิดีโอ MP4 เท่านั้น ความละเอียดของไฟล์ต้องไม่เกิน 720p และขนาดไฟล์ต้องไม่เกิน 5 MB',
  'ccivr.ivr.voiceupload.tip2Dvideomodel': 'คุณสามารถตั้งค่าไฟล์วิดีโอเป็นภาษาต่าง ๆ ได้ในเวลาเดียวกัน ในระหว่างองค์ประกอบของมนุษย์ดิจิทัล ระบบจะสลับไปยังไฟล์วิดีโอที่เกี่ยวข้องโดยอัตโนมัติตามภาษาที่ผู้ใช้เลือก',
  'ccivr.ivr.voiceupload.vedioMp4formatwrong': 'รูปแบบไฟล์ไม่ถูกต้อง โปรดนําเข้าไฟล์ MP4',
  'ccivr.ivr.voiceupload.tipvideo2Dformat': 'ปัจจุบันรองรับเฉพาะไฟล์วิดีโอในรูปแบบ mp4 เท่านั้น และขนาดไฟล์ต้องไม่เกิน 5 MB',
  'ccivr.ivr.voicereview.picture': 'รูปภาพ',
  'ccivr.ivr.voicereview.2dVideo': 'วิดีโอมนุษย์เสมือน',
  'ccivr.ivr.voiceupload.tipPicture2Dformat': 'ปัจจุบันรองรับเฉพาะไฟล์รูปภาพในรูปแบบ jpg, png และ jpeg ที่มีขนาดไฟล์ไม่เกิน 2 MB',
  'ccivr.ivr.voiceupload.pictureformatwrong': 'รูปแบบไฟล์ไม่ถูกต้อง โปรดนําเข้าไฟล์ในรูปแบบ jpg, png, หรือ jpeg',
  'ccivr.ivr.voiceupload.file.updateTitle': 'แก้ไขทรัพยากรเสียงและวิดีโอ',
  'ccivr.ivr.voiceupload.file.createTitle': 'ทรัพยากรเสียงและวิดีโอใหม่',
  'ccivr.ivr.voiceupload.tippictureresolution': 'ความละเอียดของภาพต้องไม่เกิน 720P',
  'ccivr.ivr.voiceupload.tipvideoresolution': 'ความละเอียดวิดีโอต้องไม่เกิน 720P',
  'oifde.service.invoke.businessVirtualHumanService.name': 'การตอบกลับบุคคลเสมือน',
}
