export default {
  "ccd.agent.button.signIn": "Anmelden",
  "ccd.agent.button.signOut": "Abmelden",
  "ccd.agent.button.sayBusy": "Beschäftigt setzen",
  "ccd.agent.button.sayFree": "Leerlauf einstellen",
  "ccd.agent.button.rest": "Ruhe",
  "ccd.agent.button.cancelRest": "Ruhezeit abbrechen",
  "ccd.agent.button.restSkillQueue": "Kompetenzen zurücksetzen",
  "ccd.agent.button.queryStatus": "Status abfragen",
  "ccd.agent.button.transfer": "Übertragung",
  "ccd.agent.button.hold": "Anhalten",
  "ccd.agent.button.getHold": "Halten abbrechen",
  "ccd.agent.button.callOut": "Aufruf",
  "ccd.agent.button.threeParty": "Drei Parteien",
  "ccd.agent.button.innerHelp": "Beratung",
  "ccd.agent.button.mediaswitch": "Audio-/Videoschalter",
  "ccd.agent.button.mute": "Stummschalten",
  "ccd.agent.button.twostage": "Wähltastatur",
  "ccd.agent.button.endMute": "Stummschaltung aufheben",
  "ccd.agent.button.answer": "Antworten",
  "ccd.agent.button.hangUp": "Schließen",
  "ccd.agent.button.screenShare": "Desktop-Freigabe",
  "ccd.agent.button.notscreenShare": "Desktopfreigabe abbrechen",
  "ccd.agent.label.offline": "Offline",
  "ccd.agent.label.idle": "Leerlauf",
  "ccd.agent.label.busy": "Ausgelastet",
  "ccd.agent.label.rest": "Ruhe",
  "ccd.agent.label.talk": "Gespräch",
  "ccd.agent.label.adjust": "Zusammenfassung",
  "ccd.agent.label.reply": "Antworten",
  "ccd.agent.message.AgentIsEmpty": "Das Girokonto ist nicht an einen Agenten gebunden.",
  "ccd.agent.message.agentNoConfSkill": "Der Agent ist nicht mit einer Skill-Warteschlange konfiguriert.",
  "ccd.agent.message.AgentIsSupend": "Der aktuelle Agent ist eingefroren.",
  "ccd.agent.tips.confSuccess": "Die Konfiguration war erfolgreich.",
  "ccd.agent.tips.confFail": "Konfiguration fehlgeschlagen.",
  "ccd.agent.tips.media.agentType.no.support.callout": "Multimedia-Agenten können keine ausgehenden Anrufe tätigen",
  "ccd.agent.tips.error": "Fehler",
  "ccd.agent.tips.success": "Erfolg",
  "ccd.agent.tips.info": "Tipps",
  "ccd.agent.tips.welcome": "Willkommen zurück. Einen schönen Tag noch!",
  "ccd.agent.webrtc.label.offline": "Nicht registriert",
  "ccd.agent.webrtc.label.online": "Registriert",
  "ccd.agent.webrtc.label.talk": "Anrufe",
  "ccd.agent.webrtc.label.connetfailed": "Fehlgeschlagen",
  "ccd.agent.webrtc.label.buildfailed": "Die Verbindung konnte nicht eingerichtet werden",
  "ccd.agent.webrtc.label.connect": "Anmelden",
  "ccd.agent.webrtc.label.sippwd": "Softphone-Kennwort",
  "ccd.agent.webrtc.label.unsupportbrower": "Der Browser unterstützt diese Funktion nicht.",
  "ccd.agent.webrtc.label.missmic": "Das Mikrofon ist nicht verfügbar.",
  "ccd.agent.webrtc.label.misspeaker": "Der Sprecher ist nicht verfügbar.",
  "ccd.agent.webrtc.label.videopop": "Videofenster",
  "ccd.agent.webrtc.label.sotpsendvideo": "Videos nicht mehr senden",
  "ccd.agent.webrtc.label.hidelocalvideo": "Lokales Video ausblenden",
  "ccd.agent.webrtc.label.videosize": "Videogröße",
  "ccd.agent.webrtc.label.large": "groß",
  "ccd.agent.webrtc.label.medium": "mittel",
  "ccd.agent.webrtc.label.small": "Klein",
  "ccd.agent.webrtc.label.localvideo": "Lokales Video",
  "ccd.agent.webrtc.label.remotevideo": "Fernvideo",
  "ccd.agent.webrtc.label.accept": "Antworten",
  "ccd.agent.webrtc.label.reject": "Ablehnen",
  "ccd.agent.webrtc.label.logoutregistered": "Ob Sie sich abmelden möchten.",
  "ccd.agent.webrtc.label.passwordvalidator": "Die Länge des eingegebenen Zeichens überschreitet den Wertebereich. Die Länge darf höchstens 50 Zeichen betragen.",
  "ccd.agent.webrtc.label.busy.tip": "Chamada",
  "ccd.agent.webrtc.label.busy.info": "Tipps",
  "ccd.agent.webrtc.label.busy.confirm": "Bestätigen",
  "ccd.agent.webrtc.label.button.yes": "Ja",
  "ccd.agent.webrtc.label.button.no": "Nein",
  "ccd.agent.webrtc.label.sippwd.placeholder": "Geben Sie das Softphone-Passwort ein.",
  "ccd.agent.webrtc.label.remote.name": "Andere Partei",
  "ccd.agent.webrtc.label.local.name": "Agent",
  "ccd.agent.webrtc.label.changeVoice": "Zur Stimme übertragen",
  "ccd.agent.agentevent.success": "Erfolg",
  "ccd.agent.agentevent.failed": "Fehlgeschlagen",
  "ccd.agent.agentevent.warning": "Warnung",
  "ccd.agent.agentevent.info": "Tipps",
  "ccd.agent.agentevent.AgentState_Force_SetNotReady": "Der Agent wurde erfolgreich besetzt.",
  "ccd.agent.agentevent.AgentState_CancelNotReady_Success": "Der Agent hat die Belegung erfolgreich abgebrochen.",
  "ccd.agent.agentevent.AgentState_Ready": "Der Agent wurde erfolgreich in den Leerlauf versetzt.",
  "ccd.agent.agentevent.AgentState_CancelWork_Success": "Der Agent hat den Arbeitsstatus erfolgreich beendet.",
  "ccd.agent.agentevent.AgentState_SetRest_Success": "Der Agent ruhte sich erfolgreich aus.",
  "ccd.agent.agentevent.AgentState_CancelRest_Success": "Der Agent hat den Rest erfolgreich abgebrochen.",
  "ccd.agent.agentevent.AgentState_SetWork_Success": "Der Agent wechselt in den Arbeitszustand.",
  "ccd.agent.agentevent.AgentState_Work": "Der Agent wechselt in den Nachbearbeitungsstatus.",
  "ccd.agent.agentevent.AgentState_Busy": "Der Agent arbeitet",
  "ccd.agent.agentevent.AgentEvent_Customer_Alerting": "Das Telefon klingelt.",
  "ccd.agent.agentevent.AgentEvent_Ringing": "Der eingehende Anruf des Agenten",
  "ccd.agent.agentevent.AgentEvent_Hold": "Der Anruf wurde erfolgreich gehalten.",
  "ccd.agent.agentevent.AgentEvent_Hold.desc": "Der Agent befindet sich bereits in der Warteschleife",
  "ccd.agent.agentevent.AgentEvent_Talking":"Der Agent ist in den Status Sprechend  übergegangen.",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.success": "Audio/Video-Umschaltung erfolgreich.",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.failed": "Audio/Video-Umschaltung fehlgeschlagen.",
  "ccd.agent.agentevent.AgentMediaEvent_Play": "Die Wiedergabe der Aufzeichnung beginnt.",
  "ccd.agent.agentevent.AgentMediaEvent_PausePlayDone": "Die Wiedergabe der Aufnahme wurde erfolgreich angehalten.",
  "ccd.agent.agentevent.AgentMediaEvent_ResumePlayDone": "Die Wiedergabe der Aufnahme wurde erfolgreich fortgesetzt.",
  "ccd.agent.agentevent.AgentMediaEvent_JumpPlayDone": "Die Aufnahme wurde erfolgreich vor- oder zurückgespult.",
  "ccd.agent.agentevent.AgentMediaEvent_StopPlayDone": "Aufzeichnungswiedergabe gestoppt.",
  "ccd.agent.agentevent.AgentEvent_Conference": "Der Drei-Parteien-Anruf war erfolgreich.",
  "ccd.agent.agentevent.AgentEvent_Call_Out_Fail": "Der Agent kann keinen externen Anruf initiieren.",
  "ccd.agent.agentevent.AgentEvent_Inside_Call_Fail": "Der Agent kann keinen internen Anruf initiieren.",
  "ccd.agent.agentevent.AgentEvent_Call_Release": "Der Agent beendet den Anruf",
  "ccd.agent.agentevent.AgentConf_SelfJoinResult": "Agent nimmt an einer Konferenz teil",
  "ccd.agent.agentevent.AgentEvent_No_Answer": "Der Agent nimmt den Anruf lange Zeit nicht an.",
  "ccd.agent.agentevent.AgentEvent_Customer_Release": "Der Kunde beendet den Anruf.",
  "ccd.agent.agentevent.AgentEvent_Auto_Answer": "Automatische Antwort des Agenten",
  "ccd.agent.agentevent.AgentEvent_Connect_Fail": "Verbindungsfehler",
  "ccd.agent.agentevent.AgentEvent_Consult_Fail": "Fehler bei der Agentenberatung",
  "ccd.agent.agentevent.AgentEvent_ReturnFromIvr": "Der Agent wurde erfolgreich vom IVR zurückgegeben.",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut": "Die Vorschau des ausgehenden Anrufs war erfolgreich.",
  "ccd.agent.agentevent.AgentEvent_Preview": "Der Agent empfängt die Vorschauanfrage erfolgreich.",
  "ccd.agent.agentevent.AgentEvent_Agent_Occupyed": "Der Agent wurde bereits getrennt",
  "ccd.agent.agentevent.AgentEvent_Release_Preview": "Der Vorschauanruf wurde erfolgreich freigegeben.",
  "ccd.agent.agentevent.AgentOther_ShutdownService": "Der Agent hat sich erfolgreich abgemeldet.",
  "ccd.agent.agentevent.AgentOther_InService": "Der Agent hat sich erfolgreich angemeldet.",
  "ccd.agent.agentevent.AgentOther_PhoneAlerting": "Der Agent ruft an",
  "ccd.agent.agentevent.AgentOther_PhoneOffhook": "Der Agent ist vom Haken gegangen",
  "ccd.agent.agentevent.AgentOther_PhoneRelease": "Der Agent hat aufgelegt",
  "ccd.agent.agentevent.AgentOther_PhoneUnknow": "Der Telefonstatus des Agenten ist unbekannt.",
  "ccd.agent.agentevent.AgentOther_All_Agent_Busy": "Alle Agenten sind beschäftigt.",
  "ccd.agent.agentevent.AgentOther_Restskill_Queue": "Skill-Warteschlangenvorgang erfolgreich.",
  "ccd.agent.agentevent.callType": "Anruftyp:",
  "ccd.agent.agentevent.callId": "Anrufnummer:",
  "ccd.agent.agentevent.type.main": "Hauptanruf",
  "ccd.agent.agentevent.type.assistant": "Unterstützungsanruf",
  "ccd.agent.agentevent.otherPhone": "Telefonnummer/Arbeits-ID des anrufenden Teilnehmers:",
  "ccd.agent.agentevent.feature.caller": "Anrufernummer:",
  "ccd.agent.agentevent.feature.called": "Angerufene Nummer:",
  "ccd.agent.agentevent.feature.callin": "Häufiger eingehender Anruf",
  "ccd.agent.agentevent.feature.callout": "Allgemeiner ausgehender Anruf",
  "ccd.agent.agentevent.mediaAblity": "Medientyp:",
  "ccd.agent.agentevent.mediaAblity.voice": "Audio",
  "ccd.agent.agentevent.mediaAblity.video": "Video",
  "ccd.agent.agentevent.mediaAblity.voiceandvideo": "Audio und Video",
  "ccd.agent.agentevent.mediaAblity.text": "Text",
  "ccd.agent.agentevent.AgentEvent_Conference.partner": "Anrufnummer:",
  "ccd.agent.agentevent.AgentEvent_No_Answer.number": "Nummer der aufgelegten Partei:",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.controlid": "Vorschau der Steuerungsblock-ID für ausgehende Anrufe:",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.dialeddigits": "Vorschau der ausgehenden angerufenen Nummer:",
  "ccd.agent.flash.calling": "Anrufe",
  "ccd.agent.flash.message": "Nachricht",
  "ccd.agent.openeye.deamonready": "Der OpenEye wurde gestartet.",
  "ccd.agent.openeye.deamonready.failinfo": "OpenEye konnte nicht gestartet werden.",
  "ccd.agent.openeye.deamonready.fail": "OpenEye konnte nicht gestartet werden. Melden Sie sich manuell beim OpenEye an. die automatische Anrufbeantworterfunktion kann nicht verwendet werden.",
  "ccd.agent.query.sip.fail": "SIP-Daten konnten nicht abgefragt werden. Melden Sie sich erneut als Agent an oder melden Sie sich manuell beim OpenEye an.",
  "ccd.agent.openeye.sipconfig.success": "Das SIP wurde erfolgreich konfiguriert.",
  "ccd.agent.openeye.poolsipconfig.success": "Der POOL wurde erfolgreich konfiguriert.",
  "ccd.agent.openeye.sipconfig.fail": "Das SIP konnte nicht konfiguriert werden. Melden Sie sich erneut als Agent an oder melden Sie sich manuell beim OpenEye an.",
  "ccd.agent.openeye.poolsipconfig.fail": "Pool konnte nicht konfiguriert werden. Melden Sie sich erneut als Agent an oder melden Sie sich manuell beim OpenEye an.",
  "ccd.agent.openeye.register": "Die Softphone-Nummer wurde erfolgreich registriert.",
  "ccd.agent.openeye.already.register": "Die Softphone-Nummer wurde registriert.",
  "ccd.agent.openeye.register.fail": "Die Softphone-Nummer ist bereits registriert oder aus anderen Gründen.",
  "ccd.agent.openeye.deregister": "Die Softphone-Nummer wurde erfolgreich deaktiviert.",
  "ccd.agent.openeye.deregister.fail": "Die Softphone-Nummer konnte nicht deaktiviert werden. Melden Sie sich manuell vom OpenEye ab.",
  "ccd.agent.openeye.autoanswer": "Der Anruf wird automatisch beantwortet.",
  "ccd.agent.openeye.autoanswer.fail": "Anruf konnte nicht automatisch angenommen werden.",
  "ccd.agent.openeye.videowindowparam": "Die Videoparameter wurden erfolgreich initialisiert.",
  "ccd.agent.openeye.videowindowparam.fail": "Videoparameter konnten nicht initialisiert werden. Daher ist die Videoanruffunktion anormal.",
  "ccd.agent.openeye.videolayoutmode.success": "Der Bildanordnungsmodus des Videofensters konnte erfolgreich eingestellt werden.",
  "ccd.agent.openeye.videolayoutmode.fail": "Der Bildanordnungsmodus des Videofensters konnte nicht festgelegt werden.",
  "ccd.agent.openeye.videodisplaymode.success": "Der Zuschneidemodus des Videofensters wurde erfolgreich eingestellt.",
  "ccd.agent.openeye.videodisplaymode.fail": "Der Zuschneidemodus des Videofensters konnte nicht eingestellt werden.",
  "ccd.agent.frontpage.callreason": "Anrufgrund",
  "ccd.agent.callrelease.end": "Anruf beendet",
  "ccd.agent.resetphonepass.inputcur": "Aktuelles Kontokennwort",
  "ccd.agent.resetphonepass.input": "Kennwort",
  "ccd.agent.resetphonepass.inputagain": "Passwort bestätigen",
  "ccd.agent.openeye.share": "Bitte wählen Sie ...",
  "ccd.ccaction.title.shareapp": "Gemeinsames Programm auswählen",
  "ccd.agent.openeye.switchshare.success": "Die Desktop-Freigabefunktion wurde erfolgreich aktiviert.",
  "ccd.agent.openeye.switchshare.fail": "Die Desktop-Sharing-Funktion konnte nicht aktiviert werden.",
  "ccd.agent.openeye.getapplist.fail": "Die Liste der geöffneten Anwendungen konnte nicht abgerufen werden.",
  "ccd.agent.openeye.setshareapp.fail": "Bildschirmfreigabe konnte nicht eingerichtet werden.",
  "ccd.agent.openeye.startshare.fail": "Freigabe konnte nicht gestartet werden.",
  "ccd.agent.openeye.stopshare.fail": "Freigabe konnte nicht beendet werden.",
  "ccd.agent.button.offlineSignOut": "Mobil verlassen",
  "ccd.agent.button.offlineSignIn": "Mobil wechseln",
  "ccd.agent.button.ok": "OK",
  "ccd.agent.button.close": "Abbrechen",
  "ccd.agent.message.phone": "Angerufene Nummer",
  "ccd.agent.login.fail": "Registrierung fehlgeschlagen.",
  "ccd.agent.login.failinfo": ". Daher ist die interne Hilfefunktion nicht verfügbar.",
  "ccd.agent.login.multimedia.failinfo": ". Daher sind Sprach- und Videoanrufe sowie interne Hilfefunktionen nicht verfügbar.",
  "ccd.agent.reset.success": "Restreservierung erfolgreich.",
  "ccd.agent.openeye.initsuccess": "Der OpenEye wurde erfolgreich initialisiert.",
  "ccd.agent.pagination.total": "Gesamt:",
  "ccd.agent.title.consult": "Beratung",
  "ccd.agent.warning.sharescreen": "Achten Sie auf die Privatsphäre, wenn Sie den Desktop freigeben",
  "ccd.agentmonitor.span.listening": "Zuhören",
  "ccd.agentmonitor.span.insert": "Einfügen",
  "ccd.agentmonitor.span.whisper": "Flüstern",
  "ccd.message.title.audioIncomingCallNotify": "Benachrichtigung über eingehende Audio- und Videoanrufe",
  "ccd.message.title.mediaIncomingCallNotify": "Multimedia-Benachrichtigung über eingehende Anrufe",
  "ccd.message.title.mediaNewMsgNotify": "Benachrichtigung über neue Multimedia-Nachricht",
  "ccd.agentMonitor.label.switchlistenorinsert":"Im Status Vor der Inspektion kann ein Agent, der geflüstert wird, nicht auf die Schaltfläche Mithören oder Einfügen klicken.",
  "ccd.agent.agentevent.quality.calloutconnectfail": "Kann keinen ausgehenden Anruf tätigen, da die QK läuft.",
  "ccd.agent.agentevent.calloutconnectfail": "Der Abonnent ist besetzt oder die Nummer existiert nicht.",
  "ccd.agent.tips.maxCountQuery.info": "Das aktuelle Datenvolumen ist zu groß. Das System zeigt nur die neuesten {0} Datensätze an.",
  "ccd.media.tips.new.online.customer": "Sie haben einen neuen Online-Kunden",
  "ccd.agent.warning.notification.cancel": "Abbrechen",
  "ccd.agent.warning.notification.setting": "Einstellungen",
  "ccd.agent.warning.notification.allowed": "Um Sitzungsbenachrichtigungen sicherzustellen, muss die Website Benachrichtigungen anzeigen können!",
  "ccd.agent.msg.notification.allowed": "AICC-Websiteanfrage, dem Browser die Anzeige von Benachrichtigungen zu erlauben!",
  "ccd.agent.msg.notification.permission.denied": "Der Benutzer lehnt die Autorisierung ab und kann die Benachrichtigung nicht anzeigen. Bitte ändern Sie die Einstellungen im Browser.",
  "ccd.agent.msg.queryMsg.specialListQuery": "Erinnerung an spezielle Liste",
  "ccd.agent.message.AgentIsProhibited": "Die Vermietung wurde verboten.",
  "ccd.agent.message.repeatPhonenum": "Die Telefonnummer, die der Agent für die Anmeldung verwendet hat, wurde von einem anderen Agent für die Anmeldung verwendet. Überprüfen Sie die Konfiguration.",
  "ccd.agent.message.destinationAgentFailed": "Der Zielagent konnte nicht besetzt werden.",
  "ccd.agent.message.noAgent": "Kein Agent im Dienst.",
  "ccd.agent.warning.waserror": "Auf WAS-Ressourcenfehler kann nicht zugegriffen werden, oder es tritt ein interner Fehler auf.",
  "ccd.agent.message.eventMethodErr": "Die Methode zum Abrufen des Agentenereignisses ist falsch.",
  "ccd.agent.message.restInvalid": "Der über die URL übertragene Parameter oder die Adresse entspricht nicht der Definition.",
  "ccd.agent.message.restNoRight": "Der Agent ist nicht berechtigt, die Schnittstelle aufzurufen. Der Agent wurde möglicherweise gezwungen, sich abzumelden.",
  "ccd.agent.message.invalidWasURL": "Der auf dem Server konfigurierte WAS-Verbindungspfad ist falsch.",
  "ccd.agent.message.loginInvalidPara": "Anmeldung fehlgeschlagen. Der Anmeldeparameter ist leer oder ungültig.",
  "ccd.agent.message.userOrPasswdInvalid": "Anmeldung fehlgeschlagen. Das Konto oder Kennwort ist falsch.",
  "ccd.agent.message.logOutInvalidParam": "Anmeldung fehlgeschlagen. Der Parameter ist ungültig.",
  "ccd.agent.message.AgentAlreadyLogout": "Der Agent hat sich abgemeldet.",
  "ccd.agent.message.loginInvalidParam": "Die Telefonnummer ist ungültig oder wurde von einem anderen Agenten angemeldet.",
  "ccd.agent.message.AgentInnerError": "Vorgang fehlgeschlagen. Es kann ein interner Fehler auftreten, oder es ist keine Ressource verfügbar, oder der Agent befindet sich nicht im Leerlauf.",
  "ccd.agent.message.setStatErr": "Agentenstatus konnte nicht festgelegt werden.",
  "ccd.agent.message.logOutStatusErr": "Abmeldung fehlgeschlagen. Möglicherweise müssen Sie sich um ein ausstehendes Geschäft kümmern.",
  "ccd.agent.message.callOutPhoneErr": "Die Nummer des ausgehenden Anrufs ist ungültig.",
  "ccd.agent.message.answerNoCall": "Kein Anruf zu beantworten.",
  "ccd.agent.message.beginMuteNoCall": "Kein Anruf für Stummschaltung.",
  "ccd.agent.message.beginMuteStateErr": "Ein Anrufstatusfehler tritt auf, wenn Sie sich stummschalten.",
  "ccd.agent.message.connectHoldNoCall": "Kein Anruf zu halten.",
  "ccd.agent.message.noHoldCall": "Der Anruf wird nicht gehalten.",
  "ccd.agent.message.endMuteNoCall": "Es besteht kein Anruf, wenn Sie die Stummschaltung abbrechen.",
  "ccd.agent.message.endMuteStateErr": "Es tritt ein Anrufstatusfehler auf, wenn Sie die Stummschaltung abbrechen.",
  "ccd.agent.message.threePartyNoCall": "Kein Anruf für Sie, um einen Drei-Parteien-Anruf zu initiieren.",
  "ccd.agent.message.threePartyNoHoldCall": "Es wird kein Anruf gehalten, um einen Drei-Teilnehmer-Anruf zu initiieren.",
  "ccd.agent.message.transferNoCall": "Kein Anruf zum Weiterleiten.",
  "ccd.agent.message.holdNoCall": "Kein Anruf zu halten.",
  "ccd.agent.message.holdCallStatusErr": "Es tritt ein Anrufstatusfehler auf, wenn Sie den Anruf halten.",
  "ccd.agent.message.getHoldNoHoldCall": "Kein Anruf zum Aufheben.",
  "ccd.agent.message.getHoldCallStatusErr": "Es tritt ein Anrufstatusfehler auf, wenn Sie den Anruf beenden.",
  "ccd.agent.message.releaseNoCall": "Kein Anruf zum Auflegen.",
  "ccd.agent.message.insultNoCall": "Kein Anruf zur Beratung.",
  "ccd.agent.message.releaseNumberNoCall": "Kein Anruf zur Freigabe einer bestimmten Telefonnummer.",
  "ccd.agent.message.mediaSwitchNoCall": "Während der Audio-/Videoumschaltung erfolgt kein Anruf.",
  "ccd.agent.message.mediaSwitchSuccess": "Audio/Video-Umschaltung erfolgreich.",
  "ccd.agent.message.mediaSwitchFailed": "Umschaltung von Audio/Video fehlgeschlagen.",
  "ccd.agent.message.releaseNumberNoNumber": "Kein Anruf der Telefonnummer zur Freigabe.",
  "ccd.agent.message.loginInvalidPhone": "Ungültige Telefonnummer.",
  "ccd.agent.message.InvalidNumber": "Ungültige Nummer.",
  "ccd.agent.message.accoutnLocked": "Das Konto ist gesperrt. Melden Sie sich später an.",
  "ccd.agent.message.agentNotLogin": "Der Agent meldet sich nicht an.",
  "ccd.agent.callout.notrest": "Brechen Sie die Pause ab und tätigen Sie dann einen ausgehenden Anruf.",
  "ccd.agent.message.monitorSelf": "Der inspizierte Agent und der Inspektor können nicht identisch sein.",
  "ccd.agent.message.hangupnocall": "Der Anruf kann nicht beendet werden. Wenn der Anruf in der Warteschleife ist, brechen Sie bitte zuerst die Warteschleife ab.",
  "ccd.agent.message.default": "Interner Fehler. Überprüfen Sie das Protokoll.",
  "ccd.agent.message.wait": "Der Anruf wird bearbeitet. Versuchen Sie es später erneut.",
  "ccd.agent.message.reset": "Zu viele Anrufverarbeitungsfehler beim Versuch, den Agentenstatus zu synchronisieren",
  "ccd.agent.videocall.paramError": "Parameterfehler beim Einstellen des Anzeigemodus eines Videoanrufs mit drei Teilnehmern.",
  "ccd.agent.videocall.noCall": "Es tritt kein Anruffehler auf, wenn der Anzeigemodus eines Videoanrufs mit drei Teilnehmern angepasst wird.",
  "ccd.agent.videocall.typeError": "Der Anruftyp ist falsch, wenn der Anzeigemodus für einen Videoanruf mit drei Teilnehmern angepasst wird.",
  "ccd.agent.videocall.stateError": "Der Anrufstatus ist falsch, wenn der Anzeigemodus für einen Videoanruf mit drei Teilnehmern angepasst wird.",
  "ccd.agent.videocall.displayError": "Eine Ausnahme tritt auf, wenn der Präsentationsmodus eines Videoanrufs mit drei Teilnehmern angepasst wird.",
  "ccd.agent.previewcall.invalParam": "Die Vorschauparameter für ausgehende Anrufe sind falsch.",
  "ccd.agent.videocall.adjustvideo": "Anpassen der Videogröße",
  "ccd.cccbar.label.inputNumber": "Geben Sie eine Zahl ein.",
  "ccd.cccbar.label.voice": "Audio",
  "ccd.cccbar.label.video": "Video",
  "ccd.cccbar.label.cancel": "Abbrechen",
  "ccd.cccbar.label.send": "Senden",
  "ccd.cccbar.label.yourself": "Ich selbst",
  "ccd.cccbar.label.nocall": "Kein Anruf.",
  "ccd.cccbar.label.callout": "Anruf",
  "ccd.cccbar.label.callcenter": "Anrufzentrale",
  "ccd.cccbar.label.cccbar": "Verbindungsleiste",
  "ccd.cccbar.label.empty": "Alle löschen",
  "ccd.cccbar.label.backspace": "Rücktaste",
  "ccd.cccbar.label.total": "Gesamt:",
  "ccd.cccbar.message.calltip": "Hallo, ein neuer Anruf kommt!",
  "ccd.cccbar.message.pleaseAnswer": "Nehmen Sie den Anruf an.",
  "ccd.cccbar.message.syserrormessage": "Systemfehler.",
  "ccd.transfer.label.callTransfer": "Anruf weiterleiten",
  "ccd.transfer.label.skill": "Skill-Warteschlange",
  "ccd.transfer.label.transferToSkill": "Zur Kompetenzwarteschlange weiterleiten",
  "ccd.transfer.label.skillName": "Skill-Warteschlange",
  "ccd.transfer.label.queueSize": "In der Warteschlange warten",
  "ccd.transfer.label.queueSize.ccbar": "In der Warteschlange warten",
  "ccd.transfer.label.loggedOnAgents": "Verfügbarer Agent",
  "ccd.transfer.label.loggedOnAgents.ccbar": "Verfügbarer Agent",
  "ccd.transfer.label.transferType": "Übertragungstyp",
  "ccd.transfer.label.transferTypeColon": "Übertragungstyp:",
  "ccd.transfer.label.transferTypeColon.ccbar": "Übertragungstyp:",
  "ccd.transfer.label.agent": "Agent",
  "ccd.transfer.label.transferToAgent": "Weiterleitung an Agenten",
  "ccd.transfer.label.statusUnknow": "Unbekannt",
  "ccd.transfer.label.externalNumber": "Extern",
  "ccd.transfer.label.transferToExNumber": "Weiterleiten an externe Nummer",
  "ccd.transfer.label.successTransfer": "Erfolgstransfer",
  "ccd.transfer.label.releaseTransfer": "Übertragung freigeben",
  "ccd.transfer.label.talkingTransfer": "Gesprächsweiterleitung",
  "ccd.transfer.label.hangUpTransfer": "Aufgelegte Übertragung",
  "ccd.transfer.label.threeParthTransfer": "Drei-Parteien-Transfer",
  "ccd.transfer.label.ivr": "IVR",
  "ccd.transfer.button.refresh": "Aktualisieren",
  "ccd.transfer.button.cancel": "Abbrechen",
  "ccd.transfer.button.sure": "OK",
  "ccd.transfer.message.NoAgentInSkill": "In der aktuellen Skill-Warteschlange befindet sich kein Online-Agent.",
  "ccd.transfer.message.NoIvrOnline": "Es ist kein IVR-Flow verfügbar.",
  "ccd.transfer.message.inputExNumber": "Die zu übertragende Nummer",
  "ccd.transfer.message.refreshTab": "Aktualisieren Sie die Seite nicht wiederholt.",
  "ccd.transfer.message.inputIvrName": "Geben Sie einen IVR für die Übertragung ein.",
  "ccd.transfer.message.microsoftTeamsLogin": "Anmelden",
  "ccd.transfer.message.inputTeamsUser": "Geben Sie das Teamkonto ein.",
  "ccd.innerhelp.label.help": "Beratung",
  "ccd.innerhelp.label.innerHelpToSkill": "Beratung von der Skill-Queue anfordern",
  "ccd.innerhelp.label.innerHelpToAgent": "Konsultation vom Agenten anfordern",
  "ccd.innerhelp.label.twoPartyHelp": "Zweiseitige Beratung",
  "ccd.innerhelp.label.threePartyHelp": "Drei-Parteien-Beratung",
  "ccd.innerhelp.label.helpType": "Beratungsart",
  "ccd.innerhelp.message.inputExNumhelp": "Geben Sie eine Nummer für die Konsultation ein",
  "ccd.callinner.label.callinner": "Interner Anruf",
  "ccd.agent.label.recordPlay": "Stimmenwiedergabe",
  "ccd.agent.title.warning": "Alarm",
  "ccd.agent.message.misconnection": "Die Sitzungspersistenzschnittstelle konnte nicht aufgerufen werden.",
  "ccm.agent.message.selectIvr": "Wählen Sie einen IVR-Flow aus.",
  "ccd.agent.status.occupy": "Besetzt",
  "ccd.agent.status.askAnswer": "Auf Antwort warten",
  "ccd.agent.status.talk": "Gespräch",
  "ccd.agent.status.hold": "Anhalten",
  "ccd.agent.status.mute": "Stummschalten",
  "ccd.agent.status.callout": "Ausgehender Anruf",
  "ccd.agent.status.threeParty": "Drei Parteien",
  "ccd.agent.status.help": "Beratung",
  "ccd.agent.status.innerCall": "Interner Anruf",
  "ccd.agent.status.innerTwo": "Beratung",
  "ccd.agent.status.innerThree": "Drei Parteien",
  "ccd.agent.message.checkAgentStatus": "Führen Sie diesen Vorgang aus, wenn der Inspektor im Leerlauf ist.",
  "ccd.agent.message.needLogin": "Melden Sie sich als Agent an und versuchen Sie es erneut.",
  "ccd.agent.tips.restTooLong": "Der Rest läuft aus.",
  "ccd.callout.message.numberFormat": "Eine Telefonnummer darf nur die Ziffern, das Nummernzeichen (#), und das Sternchen (*), sowie maximal 24 Zeichen enthalten.",
  "ccd.quality.message.noProvider": "Der NOPROVIDER-Fehler tritt während der Qualitätsprüfung auf.",
  "ccd.quality.message.noAuth": "Während der Qualitätsprüfung tritt keine Berechtigungsausnahme auf.",
  "ccd.quality.message.resourceUnable": "Während der Inspektion tritt eine Ausnahme auf. Überprüfen Sie die relevanten Protokolle.",
  "ccd.quality.message.noAgentInfo": "Während der Qualitätsüberprüfung wurden keine Agenteninformationen gefunden.",
  "ccd.quality.message.statusErr": "Der Status ist während der Qualitätsprüfung anormal.",
  "ccd.quality.message.inspectInspectorErr": "Der inspizierte Agent kann kein Inspektor sein.",
  "ccd.record.message.jumpBack": "Während des Zurückspulens tritt ein Statusfehler auf.",
  "ccd.record.message.jumpForw": "Bei der Weiterleitung ist ein Statusfehler aufgetreten.",
  "ccd.record.message.pausePlay": "Beim Anhalten der Wiedergabe tritt ein Statusfehler auf.",
  "ccd.record.message.pauseRecord": "Bei Aufzeichnungspausen tritt ein Statusfehler auf.",
  "ccd.record.message.resumePlay": "Bei der Fortsetzung der Wiedergabe tritt ein Statusfehler auf.",
  "ccd.record.message.resumeRecord": "Beim Fortsetzen der Aufzeichnung tritt ein Statusfehler auf.",
  "ccd.record.message.startPlay": "Beim Start der Wiedergabe tritt ein Statusfehler auf.",
  "ccd.record.message.startRecord": "Beim Start der Aufzeichnung tritt ein Statusfehler auf.",
  "ccd.record.message.stopPlay": "Beim Stoppen der Wiedergabe tritt ein Statusfehler auf.",
  "ccd.quality.message.qualityTitle": "Qualitätsprüfung",
  "ccd.quality.message.listen": "Zuhören",
  "ccd.quality.message.stopListen": "Hören Sie auf",
  "ccd.quality.message.insert": "Einfügen",
  "ccd.quality.message.stopInsert": "Einfügen stoppen",
  "ccd.quality.message.switch": "Umschalten",
  "ccd.quality.message.intercept": "Abfangen",
  "ccd.quality.message.whisper": "Flüstern",
  "ccd.quality.message.cancelwhisper": "Flüstern stoppen",
  "ccd.quality.message.forceexit": "Ausgang erzwingen",
  "ccd.quality.message.forcerest": "Leerlauf erzwingen",
  "ccd.quality.message.forcebusy": "Beschäftigt erzwingen",
  "ccd.quality.message.back": "Zurück",
  "ccd.quality.message.searchWorkno": "Geben Sie die Agenten-ID ein, nach der gesucht werden soll.",
  "ccd.quality.message.interceptErr": "Der zu überprüfende Agent befindet sich nicht in einem Anruf und kann nicht abgefangen werden.",
  "ccd.ccaction.message.retCode": "Fehler Arbeit abbrechen, wenn Sie beschäftigt sind. Fehlercode:",
  "ccd.ccaction.message.retMessage": ".-Fehlermeldung:",
  "ccd.ccaction.message.errorRetCode": "Fehler! Fehlercode:",
  "ccd.ccaction.message.retMessEnd": ".",
  "ccd.ccaction.message.statusError": "Im aktuellen Status kein Anruf zum Stummschalten.",
  "ccd.ccaction.message.abilityError": "Sie können den Benutzer in der aktuellen Medienfunktion nicht stummschalten.",
  "ccd.ccaction.message.unHold": "Kein Anruf im aktuellen Status zu halten.",
  "ccd.ccaction.message.holdError": "Der aktuelle Anruf kann nicht gehalten werden.",
  "ccd.ccaction.message.confJoinErr": "Es wird kein Anruf gehalten. Ein Anruf mit drei Teilnehmern ist nicht zulässig.",
  "ccd.ccaction.message.confJoinFail": "Der Agent meldet sich nicht an und der Drei-Parteien-Anruf schlägt fehl.",
  "ccd.ccaction.message.recording": "Die Stimme kann nur abgespielt werden, wenn sich der Agent im Leerlauf befindet. Bitte ändern Sie den Agentenstatus.",
  "ccd.ccaction.message.recordFail": "Der Agent meldet sich nicht an und die Sprachwiedergabe schlägt fehl.",
  "ccd.ccaction.message.repeateBusy": "Der Agent ist gerade beschäftigt. Stellen Sie die Belegung nicht wiederholt ein.",
  "ccd.ccaction.message.repeateRest": "Der Agent ist Ruhe. Beschäftigt anzeigen nicht erzwingen.",
  "ccd.ccaction.message.isForceBusy": "Der Agent wurde als beschäftigt angezeigt.Bitte nicht wiederholt als beschäftigt anzeigen.",
  "ccd.ccaction.message.inputWorkNo": "Die Agenten-ID ist leer. Bitte geben Sie die Agenten-ID ein.",
  "ccd.ccaction.message.inputIvrName": "Der IVR-Flussname ist leer. Geben Sie den Namen des IVR-Flusses ein.",
  "ccd.ccaction.message.logoutfailed": "Abmeldung fehlgeschlagen. Bitte überprüfen Sie den Agentenstatus.",
  "ccd.ccaction.message.error": "Fehler",
  "ccd.ccaction.message.resignin": "Sitzkennwort ist ungültig. Bitte melden Sie sich erneut an!",
  "ccd.ccaction.message.rest": "Ruhe",
  "ccd.ccaction.message.seconds": "Sekunden",
  "ccd.ccaction.message.minutes": "Minuten",
  "ccd.ccaction.message.hour": "Stunden",
  "ccd.ccaction.message.search.restCauseDesc": "Geben Sie die Beschreibung des Restgrunds ein.",
  "ccd.callmessage.workbench": "Audio- und Video-Workbench",
  "ccd.callmessage.chatOnlineWorkbench": "Online-Chat-Workbench",
  "ccd.callmessage.whiteNumberExist": "Die Nummer existiert nicht in der Vertrauensliste.",
  "ccd.agentstate.notTalking": "Der ausgewählte Agent befindet sich nicht im Sprechstatus.",
  "ccd.outgoing.out.workbench": "Ausgehende Workbench",
  "ccd.agent.videomode.triangular": "Dreieckig",
  "ccd.agent.videomode.maximizeUser": "Benutzer maximieren",
  "ccd.agent.videomode.maximizeAgent": "Agent maximieren",
  "ccd.agent.videomode.maximizeThreeparty": "Drei Parteien maximieren",
  "ccd.agent.mediaswitch.audio": "Audioanruf",
  "ccd.agent.mediaswitch.video": "Videoanruf",
  "ccd.agent.mediaswitch.switchaudiofailed": "Wir haben bereits einen Audioanruf.",
  "ccd.agent.mediaswitch.switchvideofailed": "Bereits in einem Videoanruf.",
  "ccd.agent.mediaswitch.unsupportedagenttype": "Der aktuelle Agent unterstützt keine Videoanrufe.",
  "ccd.agent.void.call": "Ausgehender Sprachanruf",
  "ccd.agent.chat.mutimedia.call": "Ausgehender Multimedia-Anruf",
  "ccd.agent.chat.start": "Starten einer Sitzung",
  "ccd.agent.chat.channle.choose": "Kanalauswahl",
  "ccd.login.message.logoutReason.sessionInvalid": "Die Sitzung ist ungültig. Bitte melden Sie sich erneut an.",
  "ccd.ccaction.message.registerPhone": "Registrieren Sie das Softphone.",
  "ccd.ccaction.message.loginagent": "Melden Sie sich als Agent an.",
  "ccd.contact.message.timeerror": "Die Startzeit muss vor der Endzeit liegen.",
  "ccd.contact.message.timerangeerror": "Der Zeitraum darf {0} Tage nicht überschreiten.",
  "ccd.contact.message.timeisempty": "Der Zeitraum darf nicht leer sein.",
  "ccd.ccaction.message.registerPhoneFailed": "Die WebRTC-Registrierung ist fehlgeschlagen. Derzeit sind keine internen Hilfefunktionen verfügbar.",
  "ccd.agent.message.selectTeams": "Wählen Sie ein Teamkonto aus.",
  "ccd.skill.selectskill": "Wählen Sie mindestens eine Skill-Warteschlange aus.",
  "ccd.agent.message.qc.restskill": "Die Skill-Warteschlange kann nicht zurückgesetzt werden, wenn ein Inspektor einen eingehenden Anruf nicht beantwortet.",
  "ccd.agentMonitor.label.selectAgentOne": "Bitte beenden Sie den Agenten, der gerade verarbeitet wird, und wählen Sie einen anderen Agentenvorgang.",
  "ccd.quality.message.qualityAgentTitle": "Agentenmonitor",
  "ccd.agentMonitor.forceRelease.noAudio": "Es kann kein Audio- oder Videoanruf freigegeben werden.",
  "ccd.agentMonitor.intercept.noAudio": "Es kann kein Sprach- oder Videoanruf abgefangen werden.",
  "ccd.transfer.label.transferNumber": "Gibt an, ob es sich um einen Self-Service-Prozess handelt.",
  "ccd.agent.forcesignout.confirm": "Das Abmelden wirkt sich auf den Verbindungsdienst aus, den der Agent verarbeitet. Möchten Sie die Abmeldung wirklich erzwingen?",
  "ccd.ccagent.contract.preview": "Vorschau",
  "ccd.label.search": "Suche",
  "ccd.label.org.all": "Alle zugänglichen Organisationen",
  "ccd.title.call.three": "Drei-Wege-Anruf",
  "ccd.agent.message.calloutagent.restskill": "Der Callout-Agent beantwortet keine eingehenden Anrufe und kann die Skill-Warteschlangen nicht zurücksetzen.",
  "ccd.agent.message.videoLimit": "Die Ressourcen des Videoagenten reichen nicht aus, und der Agent kann sich nicht beim Videomedienserver anmelden.",
  "ccd.agent.message.withoutskill": "Die Skill-Warteschlange fehlt.",
  "ccdesktop.login.wertc.failed": "Registrierung des Webrtc-Softphones fehlgeschlagen",
  "ccd.agent.label.skillGroupOrSkill": "Kompetenzgruppe/Kompetenzwarteschlange",
  "ccd.transfer.placeholder.agentName": "Geben Sie den Agentennamen ein",
  "ccd.agent.videocall.displayWarn": "Die Größe des Videofensters kann nur angepasst werden, wenn alle drei Parteien Videos abspielen.",
  "ccd.ccaction.message.nosupport": "Dieser Vorgang wird auf der Webseite nicht unterstützt. Verwenden Sie Open Eye zum Stummschalten.",
  "ccd.ccbar.before.signin.tips": "Bevor Sie die Verbindungsfunktion verwenden, klicken Sie auf, um sich anzumelden.",
  "ccd.ccbar.calling.number": "Anrufnummer",
  "ccd.ccbar.mediaSwitch.failed": "Medienwechsel fehlgeschlagen.",
  "ccd.ccbar.mediaSwitch.notsupport": "Audio- und Videoumschaltung ist für interne Anrufe im Videomodus auf der MCU nicht zulässig.",
  "ccd.webrtc.message.bad": "Schlechtheit",
  "ccd.webrtc.message.poor": "Schwach",
  "ccd.webrtc.message.good": "Gut",
  "ccd.webrtc.message.recive": "Empfangen",
  "ccd.webrtc.message.Packetloss": "Paketverlust",
  "ccd.webrtc.message.delay": "Verzögerung",
  "ccd.webrtc.message.jitter": "Jittern",
  "ccd.webrtc.message.send": "Senden",
  "ccd.webrtc.message.signal": "Signal",
  "ccd.webrtc.message.medium": "Mittel",
  "ccd.webrtc.login.success": "Die Softphone-Anmeldung war erfolgreich",
  "ccd.webrtc.login.fail": "Softphone-Anmeldungsfehler",
  "ccd.webrtc.login.fail.noPwd": "Es ist kein Erstkennwort verfügbar. Setzen Sie das Softphone-Passwort zurück und versuchen Sie es erneut.",
  "ccd.webrtc.login.fail.pop.tips": "Die automatische Anmeldung beim Softphone ist fehlgeschlagen. Sie können die Sprachfunktion nicht verwenden. Erneut beim Softphone anmelden?",
  "ccd.webrtc.login.try": "Wiederholen",
  "ccd.webrtc.login.ignore": "Ignorieren",
  "ccd.webrtc.login.fail.top.tips": "Die Softphone-Anmeldung ist anormal und verwandte Funktionen wie Sprache können nicht verwendet werden.",
  "ccd.ccbar.webrtc.network.signal.pool": "Das aktuelle Netzwerksignal ist schwach, was sich auf den Anruf auswirken kann.",
  "ccd.agentMonitor.label.switchwhisper":"Sie können nicht auf Flüstern klicken, wenn sich ein Agent im Status Vorabhören oder Pro Einfügung befindet.",
  "ccd.agent.webrtc.register.tip":"Softphone registrieren?",
  "ccd.consult.message.two.inputExNumber":"Bitte geben Sie die Nummer für die Zwei-Parteien-Beratung ein",
  "ccd.consult.message.three.inputExNumber":"Bitte geben Sie die Nummer für die Drei-Parteien-Beratung ein",
  "ccd.ccbar.mediaSwitch.sfunotsupport":"Der aktuelle Anruf unterstützt keinen Medienwechsel.",
  "ccd.agent.tips.welcome.agentlogout":"Willkommen!",
  "ccd.agent.message.continuously.online":"Der Online-Service wird nach dem Check-in beibehalten. Bitte melden Sie sich rechtzeitig nach Abschluss der Arbeit ab.",
  "ccd.agent.vrpserviceaddress.isempty" :"Die VRP-Serveradresse ist leer. Wenden Sie sich zur Konfiguration an den Systemadministrator.",
  "ccd.agent.initializeagentex.success" :"Der Agent hat sich erfolgreich beim VRC registriert. Ihr Desktop wird überwacht und Ihr Anruf wird am Bildschirm aufgezeichnet. Bitte behalten Sie einen guten Arbeitsstatus bei.",
  "ccd.agent.initializeagentex.passworderror" :"Falsche Agenten-ID oder falsches Kennwort. Registrierung beim VRC fehlgeschlagen.",
  "ccd.agent.initializeagentex.alreadyregistered" :"Der Agent hat sich beim VRC registriert.",
  "ccd.agent.vrpexe.closed" :"Die VRC-Software wird heruntergefahren.",
  "ccd.agent.startMonitorEx.uninitialize" :"Die vrc-Verbindung ist ungültig. Melden Sie sich erneut beim aktuellen Agenten an.",
  "ccd.agent.startMonitorEx.qcnotexit" :"Das QK-Objekt existiert nicht. Prüfen Sie, ob der Attendant ordnungsgemäß mit dem VRC verbunden ist.",
  "ccd.agent.startMonitorEx.qctimelimitexceed" :"Die zugehörige Verbindung zum vrc ist fehlgeschlagen. Bitte melden Sie sich erneut an.",
  "ccd.agent.startMonitorEx.qcunsigned":"Der Agent hat sich nicht angemeldet. Überprüfen Sie, ob sich der Agent angemeldet hat oder erneut anmelden muss.",
  "ccd.agent.startMonitorEx.othererror":"Die Verbindung zum Server konnte nicht hergestellt werden. Wenden Sie sich an das Wartungspersonal oder versuchen Sie es später erneut.",
  "ccd.ccbar.callinner.noauth":"Der Agent hat keine interne Anrufberechtigung.",
  "ccd.webrtc.not.connected":"Softphone nicht verbunden",
}
