<template>
  <aicc-back-header
    class="oifde-back-header"
    :is-show-back-button="false"
    pageType="pageWithFixedTitle"
    :fixedTitle="title"
  ></aicc-back-header>
  <div class="aicc-oifde">
    <oifde-menu class="aicc-oifde-menu" :menus="permissionStore.routes"></oifde-menu>
    <div class="aicc-oifde-content">
      <router-view v-slot="{ Component }">
        <keep-alive :include="baseStore.keepAliveRouteNames">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>
<script>
import { useBaseStore } from '@/views/base/stores'
import { useOifdePermissionStore } from '@oifde/stores/permission'
import OifdeMenu from '@oifde/components/OifdeMenu/OifdeMenu.vue'
import AiccBackHeader from '@/views/base/frame/components/AiccBackHeader.vue'

export default {
  name: 'oifde-layout',
  components: {
    AiccBackHeader,
    OifdeMenu
  },
  setup() {
    const permissionStore = useOifdePermissionStore()
    const baseStore = useBaseStore()

    return { permissionStore, baseStore }
  },
  data() {
    return {
      isSysAdmin: false,
      fixedTitle: '',
      routeTitle: '',
      title: ''
    }
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        console.log(newVal)
        if (newVal.meta && newVal.meta.title) {
          this.routeTitle = this.$t(newVal.meta.title)
        }
      }
    }
  },
  computed: {
    title() {
      return this.fixedTitle + (this.routeTitle ? ' / ' + this.routeTitle : '')
    }
  },
  created() {
    this.isSysAdmin =
      window.localStorage.getItem('isAdmin') === 'true' &&
      this.cookieUtils.getCookie('tenant_space_id') === 'defaultSpace'
    this.fixedTitle = this.isSysAdmin ? this.$t('oifde.headerTitle.flowManagement') : this.$t('oifde.headerTitle.flowConfiguration')
    this.routeTitle = this.$t(this.$route.meta.title)
  }
}
</script>

<style lang="less" scoped>
.aicc-oifde {
  display: flex;
}

.aicc-oifde-menu {
  // 不缩小适应
  flex-shrink: 0;
}

.aicc-oifde-content {
  // 自动放大占满剩余空间
  flex: 1;
  width: calc(100% - 230px);
}

.oifde-back-header {
  z-index: 1;
}
</style>
