// 非系统管理员-系统管理
import Layout from '@oifde/components/OifdeLayout/OifdeLayout.vue'

export default [{
  path: '/aiccOifde/sysmgr',
  component: Layout,
  redirect: '/aiccOifde/sysmgr/syscfg',
  alwaysShow: true,
  meta: {
    title: 'oifde.route.sysMgr.sysMgr'
  },
  children: [
    {
      path: '/aiccOifde/sysmgr/syscfg', // 系统设置
      name: 'oifdeSystemConfiguration',
      meta: {
        title: 'oifde.route.sysMgr.sysCfg',
        noCache: false,
        menuId: 'oifde-system-settings'
      },
      component: () => import('@/views/oifde/views/sysmgr/syscfg.vue')
    },
    {
      path: '/aiccOifde/sysmgr/rule', // 流程灰度规则
      name: 'oifdeRule',
      meta: {
        title: 'oifde.flow.rule',
        noCache: true,
        menuId: 'oifde-gray-rules'
      },
      component: () => import('@/views/oifde/views/rule/ruleList.vue')
    },
    {
      path: '/aiccOifde/sysmgr/recordHistory', // 呼叫历史管理
      name: 'oifdeRecordHistory',
      meta: {
        title: 'oifde.route.sysMgr.recordHistory',
        noCache: false,
        menuId: 'oifde-record-history'
      },
      component: () => import('@/views/oifde/views/recordHistory/recordHistory.vue')
    }
  ]
}]