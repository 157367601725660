import ccmessagingEnUS from './ccmessaging_en_US'
import ccmessagingZhCN from './ccmessaging_zh_CN'
import ccmessagingEsEs from './ccmessaging_es_ES'
import ccmessagingThTH from './ccmessaging_th_TH'
import ccmessagingPtBr from './ccmessaging_pt_BR'
import ccmessagingFrFr from './ccmessaging_fr_FR'
import ccmessagingFaIr from './ccmessaging_fa_IR'
import ccmessagingArSa from './ccmessaging_ar_SA'
import ccmessagingDeDe from './ccmessaging_de_DE'

const ccmessaging = {
    en_US: {
        ...ccmessagingEnUS,
    },
    zh_CN: {
        ...ccmessagingZhCN,
    },
    es_ES: {
        ...ccmessagingEsEs,
    },
    th_TH: {
        ...ccmessagingThTH,
    },
    pt_BR: {
        ...ccmessagingPtBr,
    },
    fr_FR: {
        ...ccmessagingFrFr
    },
    fa_IR: {
        ...ccmessagingFaIr,
    },
  ar_SA: {
    ...ccmessagingArSa
  },
    de_DE: {
        ...ccmessagingDeDe,
    },
}

export default ccmessaging