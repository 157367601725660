import ccnotificationEnUS from './ccnotification_en_US'
import ccnotificationZhCN from './ccnotification_zh_CN'
import ccnotificationEsES from './ccnotification_es_ES'
import ccnotificationFrFR from './ccnotification_fr_FR'
import ccnotificationPtBR from './ccnotification_pt_BR'
import ccnotificationThTH from './ccnotification_th_TH'
import ccnotificationarSA from "./ccnotification_ar_SA"
import ccnotificationDeDE from './ccnotification_de_DE';

const ccOther = {
    en_US: {
        ...ccnotificationEnUS,
    },
    zh_CN: {
        ...ccnotificationZhCN,
    },
    es_ES: {
        ...ccnotificationEsES,
    },
    fr_FR: {
        ...ccnotificationFrFR,
    },
    pt_BR: {
        ...ccnotificationPtBR
    },
    th_TH: {
        ...ccnotificationThTH
    },
  ar_SA: {
    ...ccnotificationarSA
  },
    de_DE: {
        ...ccnotificationDeDE
    }
}

export default ccOther