<template>
  <div>
    <sweet-form ref="taskInfoForm"
                class="isales-task-info-form"
                :model="form"
                :rules="rules"
                :disabled="isView || (isBatchEdit && basicInfoDisalbed)"
                :label-position="labelPosition"
                label-width="auto">
      <!--基本信息-->
      <sweet-card class="aicc-m-bottom--small">
          <div class="aicc-align--absolute-left aicc-p-bottom--medium">
            <span class="card-header">{{ $t('isales.task.promotion.basicInfo') }}</span>
            <sweet-icon v-show="isBatchEdit" class="aicc-m-left--small" @click="basicInfoDisalbed = !basicInfoDisalbed">
              <i v-show="basicInfoDisalbed" class="sweetui-icon-edit-l" :title="$t('isales.data.title.edit')"></i>
              <i v-show="!basicInfoDisalbed" class="sweetui-icon-undo" :title="$t('isales.data.title.undoEdit')"></i>
            </sweet-icon>
          </div>
            <!--任务名称-->
            <sweet-form-item v-if="!isBatchEdit && !isTemplate" prop="taskName" required>
              <!--使用label插槽时不能直接设置required属性和required校验（要用validator），否则显示异常-->
              <template #label>
                <sweet-tooltip class="box-item" effect="light" :placement="topPlacement">
                  <template #content>
                    <strong>
                      {{$t('isales.rbtrecognition.remark.tip')}}<br/>`~!@#$%^&*()+=|{}'":;[]/?.&#60;&#62;<br/>~！@#￥%……&*（）——+|{}【】‘；：”“’,、？\
                    </strong>
                  </template>
                  <div class="aicc-align--absolute-left">
                    <span class="icon-class">{{ $t('isales.taskmanagement.field.taskName') }}</span>
                    <sweet-icon><warning style="transform: rotateX(180deg)" /></sweet-icon>
                  </div>
                </sweet-tooltip>
              </template>
              <sweet-input class="width-400px" v-model="form.taskName"/>
            </sweet-form-item>
            <!--模版名称-->
            <sweet-form-item v-if="isTemplate" :label="$t('isales.tasktemplate.field.templateName')" prop="taskName" required>
              <sweet-input class="width-400px" v-model="form.taskName"/>
            </sweet-form-item>
            <!--任务模板-->
            <sweet-form-item v-if="!isView && !isBatchEdit && !isTemplate" :label="$t('isales.taskInfo.title.taskTemplate')">
              <sweet-input class="width-400px" v-model="taskTemplate" readonly @click="selectTemplateDialogVisible = true"/>
            </sweet-form-item>
            <!--主叫号码-->
            <sweet-form-item :label="$t('isales.taskinfo.field.callNo')" :required="!isBatchEdit" prop="callerNo">
              <sweet-input class="width-400px" v-model="form.callerNo" readonly @click="showChooseCallNo = true"/>
            </sweet-form-item>
            <!--任务起止时间-->
            <sweet-form-item v-if="!isTemplate" :label="$t('isales.taskmanagement.title.taskTime')" :required="!isBatchEdit" prop="taskTime">
              <div class="width-400px">
                <sweet-date-picker
                    v-model="form.taskTime"
                    type="daterange"
                    :start-placeholder="this.$t('isales.taskinfo.field.beginDate')"
                    :end-placeholder="this.$t('isales.taskinfo.field.endDate')"
                    format="YYYY-MM-DD"
                    @change="changeTaskTime"
                    :disabled-date="disabledDate"
                    :prefix-icon="isView ? this.customPrefix: ''"
                />
              </div>
            </sweet-form-item>
            <!--试呼次数-->
            <sweet-form-item :label="$t('isales.taskinfo.field.callTimes')" :required="!isBatchEdit && !isTemplate" prop="callTimes">
              <sweet-input-number v-model="form.callTimes" controls-position="right" :precision="0" :min="1"></sweet-input-number>
            </sweet-form-item>
            <!--外呼失败短信模板-->
            <sweet-form-item :label="$t('isales.taskinfo.field.smsTemplate')">
              <sweet-input class="width-400px" v-model="form.smsTemplateName" @click="selectSmsDialogVisible = true" clearable @clear="clearSmsTemplate" @change="changeSmsTemplate"/>
            </sweet-form-item>
            <!--问卷调查-->
            <sweet-form-item :label="$t('isales.taskinfo.field.survey')">
              <sweet-input class="width-400px" v-model="form.surveyName" @click="selectSurveyDialogVisible = true" clearable @clear="clearSurvey" @change="changeSurvey"/>
            </sweet-form-item>
            <!--人工与IVR问卷-->
            <sweet-form-item v-if="form.scenarioType==='4'" :label="$t('isales.tasktype.field.manualIvr.info')">
              <sweet-input v-model="form.flowName" @click="selectAutoIvrDialogVisible=true"
                           readonly="true"
                           class="width-400px"
                           :placeholder="$t('isales.taskinfo.placeholder.choose')"/>
            </sweet-form-item>
            <!--选择座席-->
            <sweet-form-item :label="$t('isales.taskmanagement.title.setAgent')" :required="!isBatchEdit && !isTemplate" prop="agents">
              <sweet-button v-if="!isView" class="task-icon aicc-m-right--small" :icon="CirclePlus" type="icon" @click="chooseAgent"/>
              <sweet-tag v-if="!isView" v-for="(agent, index) in form.agents"
                         :key="agent.accountCode"
                         :closable="!isView"
                         @close="deleteAgent(agent, index)"
                         effect="plain"
                         style="margin-bottom: 5px"
                         class="aicc-m-right--small">
                {{ agent.accountCode }}
              </sweet-tag>
              <sweet-button v-if="isView" v-for="(agent, index) in form.agents">
                {{ agent.accountCode }}
              </sweet-button>
            </sweet-form-item>
      </sweet-card>
    </sweet-form>
      <!--自动提取数据规则-->
    <sweet-form ref="conditionForm"
                class="isales-task-info-form"
                :model="form"
                :rules="rules"
                :disabled="isView || (isBatchEdit && conditionDisalbed)"
                :label-position="labelPosition"
                label-width="auto">
      <sweet-card>
        <div class="aicc-align--absolute-left aicc-p-bottom--medium">
          <div class="card-header">{{ this.$t('isales.taskmanagement.field.pickUpRule') }}</div>
          <sweet-icon v-show="isBatchEdit" class="aicc-m-left--small" @click="conditionDisalbed = !conditionDisalbed">
            <i v-show="conditionDisalbed" class="sweetui-icon-edit-l" :title="$t('isales.data.title.edit')"></i>
            <i v-show="!conditionDisalbed" class="sweetui-icon-undo" :title="$t('isales.data.title.undoEdit')"></i>
          </sweet-icon>
          <sweet-switch style="margin-left: 36px" v-model="form.pickUp.isAutoPickUp"/>
        </div>
        <template v-if="form.pickUp.isAutoPickUp">
              <sweet-form-item :label="$t('isales.management.title.busiresult')" required prop="pickUp.busiResult">
                <div class="width-400px">
                  <sweet-select v-model="form.pickUp.busiResult"
                                @click="initBusiResultCodes"
                                :placeholder="$t('isales.taskinfo.placeholder.choose')"
                                :fit-input-width="true"
                                :show-resize="false">
                    <sweet-option v-for="item in busiResultCodes" :key="item.code" :label="item.name" :value="item.code"/>
                    <template #empty>
                      <div style="text-align: center;padding: 10px">{{ $t('isales.statistics.business.noData') }}</div>
                    </template>
                  </sweet-select>
                </div>
              </sweet-form-item>
              <!--业务结果达成量-->
              <sweet-form-item prop="pickUp.quotas" required>
                <template #label>
                  <sweet-tooltip class="box-item" effect="light" :content="$t('isales.taskInfo.title.quotasTips')" :placement="topPlacement">
                    <div class="aicc-align--absolute-left">
                      <span class="icon-class">{{ $t('isales.taskInfo.title.quotas') }}</span>
                      <sweet-icon><warning style="transform: rotateX(180deg)" /></sweet-icon>
                    </div>
                  </sweet-tooltip>
                </template>
                <sweet-input-number v-model.number="form.pickUp.quotas" controls-position="right" :precision="0" class="width-400px"></sweet-input-number>
              </sweet-form-item>
              <!--完成呼叫数-->
              <sweet-form-item prop="pickUp.callCompletedNum">
                <template #label>
                  <sweet-tooltip class="box-item" effect="light" :content="$t('isales.taskInfo.title.callCompletedNumTips')" :placement="topPlacement">
                    <div class="aicc-align--absolute-left">
                      <span class="icon-class">{{ $t('isales.taskInfo.title.callCompletedNum') }}</span>
                      <sweet-icon><warning style="transform: rotateX(180deg)" /></sweet-icon>
                    </div>
                  </sweet-tooltip>
                </template>
                <sweet-input-number class="width-400px" v-model.number="form.pickUp.callCompletedNum" controls-position="right" :precision="0"></sweet-input-number>
              </sweet-form-item>
              <!--坐席单次可领数据量-->
              <sweet-form-item :label="$t('isales.taskInfo.title.pickUpNum')" prop="pickUp.pickUpNum">
                <sweet-input-number v-model.number="form.pickUp.pickUpNum" controls-position="right" :precision="0"></sweet-input-number>
              </sweet-form-item>
          <!--数据提取条件-->
          <sweet-form-item prop="pickUp.primaryCondition" required>
            <template #label><span>{{ $t('isales.taskInfo.title.pickUpCondition') }}</span></template>
            <div class="condition-wrap">
              <div style="width: 900px">
                <!--主条件-->
                <aicc-table v-if="form.pickUp.primaryCondition.length" :tableData="form.pickUp.primaryCondition"
                            :tableColumns="conditionTableColumns"
                            :tableTools="{ columnConfig: false }"
                            class="margin-bottom-10px-to-rem"
                            size="small">
                  <template #condition="scope">
                    <div class="aicc-align--absolute-left aicc-p-bottom--small aicc-p-top--small">
                      <sweet-input v-model="scope.row.propName" class="width-180px-to-rem aicc-m-right--medium" readonly />
                      <div class="width-180px-to-rem aicc-m-right--medium">
                        <sweet-select v-model="scope.row.operator" value-key="value" :fit-input-width="true" :show-resize="false">
                          <sweet-option v-for="item in OPERATOR" :value="item.value" :label="item.label"></sweet-option>
                        </sweet-select>
                      </div>
                      <sweet-form-item :prop="`pickUp.primaryCondition.${scope.$index}.value`"
                                       :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }]">
                        <sweet-input v-model="scope.row.value" class="width-180px-to-rem aicc-m-right--medium" />
                      </sweet-form-item>
                      <div class="width-180px-to-rem">
                        <sweet-select v-if="scope.$index < (form.pickUp.primaryCondition.length - 1)"
                                      v-model="form.pickUp.operator"
                                      value-key="value" :fit-input-width="true" :show-resize="false">
                          <sweet-option v-for="item in LOGIC" :value="item.value" :label="item.label"></sweet-option>
                        </sweet-select>
                      </div>
                    </div>
                  </template>
                  <template #action="scope">
                    <sweet-button class="task-icon" :icon="Delete" type="icon" @click="deletePrimaryCondition(scope)"/>
                  </template>
                </aicc-table>
                <!--添加主条件-->
                <div v-if="!isView" class="isales-task-info-add aicc-align--absolute-left">
                  <sweet-button class="task-icon" style="margin-right: 12px" :icon="CirclePlus" type="icon" @click="addCondition">
                    <span class="create-button">{{ $t('isales.tasklogic.field.addCondition') }}</span>
                  </sweet-button>
                </div>
                <!--子条件-->
                <div v-if="form.pickUp.primaryCondition.length && form.pickUp.subConditions.length"
                     class="aicc-m-top--medium">
                  <aicc-table :tableData="form.pickUp.subConditions"
                              :tableColumns="conditionTableColumns"
                              :tableTools="{ columnConfig: false }"
                              class="margin-bottom-10px-to-rem"
                              size="small">
                    <template #condition="scope">
                      <div v-for="(item, index) in scope.row.subConditions"
                           class="aicc-align--absolute-left aicc-p-bottom--small aicc-p-top--small"
                           :class="{'margin-bottom-10px-to-rem': index < (scope.row.subConditions.length - 1)}">
                        <sweet-input v-model="item.propName" class="width-180px-to-rem aicc-m-right--medium" readonly />
                        <div class="width-180px-to-rem aicc-m-right--medium">
                          <sweet-select v-model="item.operator" value-key="value" :fit-input-width="true" :show-resize="false">
                            <sweet-option v-for="operator in OPERATOR" :value="operator.value" :label="operator.label"></sweet-option>
                          </sweet-select>
                        </div>
                        <sweet-form-item :prop="`pickUp.subConditions.${scope.$index}.subConditions.${index}.value`"
                                         :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }]">
                          <sweet-input v-model="item.value" class="width-180px-to-rem aicc-m-right--medium" />
                        </sweet-form-item>
                        <div class="width-180px-to-rem">
                          <sweet-select v-if="index < (scope.row.subConditions.length - 1)"
                                        v-model="scope.row.operator"
                                        value-key="value"
                                        disabled :fit-input-width="true" :show-resize="false">
                            <sweet-option v-for="logic in LOGIC" :value="logic.value" :label="logic.label"></sweet-option>
                          </sweet-select>
                        </div>
                      </div>
                    </template>
                    <template #action="scope">
                      <sweet-button class="task-icon" :icon="Delete" type="icon" @click="deleteSubCondition(scope)"/>
                    </template>
                  </aicc-table>
                </div>
                <!--添加子条件-->
                <div v-show="!isView && this.form.pickUp.primaryCondition.length"
                     class="isales-task-info-add aicc-align--absolute-left">
                  <sweet-button class="task-icon" style="margin-right: 12px" :icon="CirclePlus" type="icon" @click="addSubCondition">
                    <span class="create-button">{{ $t('isales.tasklogic.field.addSubCondition') }}</span>
                  </sweet-button>
                </div>
              </div>
            </div>
          </sweet-form-item>
        </template>
      </sweet-card>
    </sweet-form>
    <!--选择座席-->
    <ChooseAgent v-if="showChooseAgent"
                 v-model:show="showChooseAgent"
                 :agents="form.agents"
                 @confirm="confirmChooseAgent"></ChooseAgent>
    <!--单选主叫号码-->
    <ChooseSingleCallNo v-if="showChooseCallNo"
                        v-model:show="showChooseCallNo"
                        :call-no="form.callerNo"
                        @confirm="confirmChooseCallNo"></ChooseSingleCallNo>
    <!--选择任务模板-->
    <isalesTemplateSelect v-if="selectTemplateDialogVisible"
                          v-model:show="selectTemplateDialogVisible"
                          :taskTypeList="[TASK_TYPE.MANUAL]"
                          @isalesTemplateSelectConfirm="isalesTemplateSelectConfirm"></isalesTemplateSelect>
    <!--选择问卷-->
    <isalesSurveySelect v-if="selectSurveyDialogVisible"
                        v-model:show="selectSurveyDialogVisible"
                        @isalesSurveySelectConfirm="isalesSurveySelectConfirm"></isalesSurveySelect>
    <!--选择外呼失败短信模板-->
    <isalesSmsSelect v-if="selectSmsDialogVisible"
                     v-model:show="selectSmsDialogVisible"
                     @isalesSmsSelectConfirm="isalesSmsSelectConfirm"></isalesSmsSelect>
    <!--数据提取条件-选择属性-->
    <ChooseProp v-if="showChooseProp"
                v-model:show="showChooseProp"
                :selectedAttributes=selectedAttributes
                mode="pickUp"
                @confirm="confirmChooseCondition"></ChooseProp>
    <isalesAutoIvrSelect v-model:show="selectAutoIvrDialogVisible"
                         @isalesAutoIvrSelectConfirm="isalesAutoIvrSelectConfirm"></isalesAutoIvrSelect>
  </div>
</template>

<script>
import ChooseAgent from '@/views/isales/components/ChooseAgent/ChooseAgent.vue'
import ChooseProp from '@/views/isales/components/ChooseProp/ChooseProp.vue'
import ChooseSingleCallNo from '@/views/isales/components/ChooseSingleCallNo/ChooseSingleCallNo.vue'
import isalesTemplateSelect from '@/views/isales/views/systemAutoCallTask/isalesTemplateSelect.vue'
import isalesSurveySelect from '@/views/isales/views/systemAutoCallTask/isalesSurveySelect.vue'
import isalesSmsSelect from '@/views/isales/views/systemAutoCallTask/isalesSmsSelect.vue'
import { queryResultCodeAndNameOfTenant } from '@/views/isales/api/manualCallTask'
import { OPERATOR, LOGIC, TASK_TYPE } from '@/views/isales/views/taskManagement/enum'
import { Delete, CirclePlus, Edit } from '@element-plus/icons-vue'
import isalesAutoIvrSelect from "@/views/isales/views/systemAutoCallTask/isalesAutoIvrSelect.vue";
import {h, shallowRef} from 'vue'

export default {
  name: 'FormManualTaskInfo',
  components: {
    ChooseAgent,
    ChooseProp,
    isalesTemplateSelect,
    ChooseSingleCallNo,
    isalesSurveySelect,
    isalesSmsSelect,
    isalesAutoIvrSelect
  },
  props: {
    form: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'create'
    },
  },
  computed: {
    isView() {
      return this.queryType === 'view'
    },
    isEdit() {
      return this.queryType === 'edit'
    },
    isCreate() {
      return this.queryType === 'create'
    },
    isBatchEdit() {
      return this.queryType === 'batchEdit'
    },
    isTemplate() {
      return this.queryMode === 'template'
    },
    rules() {
      const taskNameValidator = (rule, value, callback) => {
        if (!this.isBatchEdit && !this.form.taskName) {
          callback($t('isales.business.result.message.must'))
        } else {
          callback()
        }
      }
      const agentsValidator = (rule, value, callback) => {
        if (!this.isBatchEdit && !this.isTemplate && !this.form.agents.length) {
          callback($t('isales.business.result.message.must'))
        } else {
          callback()
        }
      }
      const quotasValidator = (rule, value, callback) => {
        // 自定义label，如果直接设置required规则，显示会有问题，使用validator规避
        // 0时属于范围校验
        if (!value && value !== 0) {
          callback($t('isales.business.result.message.must'))
        } else if (value < 1 || value > 2147483647) {
          callback($t('isales.taskpolicy.message.rangeAutoCallTime', [1, 2147483647]))
        } else {
          callback()
        }
      }
      const callCompletedNumValidator = (rule, value, callback) => {
        if (!value && value !== 0) { // 非必填，可以为空，但不能为0（不在范围内）
          callback()
        } else if (value < 1 || value > 100000) {
          callback($t('isales.taskpolicy.message.rangeAutoCallTime', [1, 100000]))
        } else {
          callback()
        }
      }
      const pickUpNumValidator = (rule, value, callback) => {
        if (!value && value !== 0) { // 非必填，可以为空，但不能为0（不在范围内）
          callback()
        } else if (value < 1 || value > 100) {
          callback($t('isales.taskpolicy.message.rangeAutoCallTime', [1, 100]))
        } else {
          callback()
        }
      }
      const pickUpConditionValidator = (rule, value, callback) => {
        if (!this.form.pickUp.primaryCondition.length) {
          callback($t('isales.business.result.message.must'))
        } else {
          callback()
        }
      }
      return {
        taskName: [
          { validator: taskNameValidator,  trigger: ['blur', 'change'] },
          { validator: this.$Validate.checkSpecialChar,  trigger: ['blur', 'change'] },
          { maxLength: 100, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change'] }
        ],
        callerNo: [
          { required: !this.isBatchEdit, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] },
          { validator: this.$Validate.numberOnlyValidator,  trigger: ['blur', 'change'] }
        ],
        taskTime: [
          { required: !this.isBatchEdit, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
        ],
        callTimes: [
          { required: !this.isBatchEdit, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] },
          { min: 1, max: 6, validator: this.callTimesValidator, trigger: ['blur', 'change'] }
        ],
        agents: [
          { validator: agentsValidator }
        ],
        'pickUp.busiResult': [
          { required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change'] }
        ],
        'pickUp.quotas': [
          { validator: quotasValidator, trigger: ['blur', 'change'] }
        ],
        'pickUp.callCompletedNum': [
          { validator: callCompletedNumValidator, trigger: ['blur', 'change'] }
        ],
        'pickUp.pickUpNum': [
          { validator: pickUpNumValidator, trigger: ['blur', 'change'] }
        ],
        'pickUp.primaryCondition': [
          { validator: pickUpConditionValidator }
        ]
      }
    },
  },
  data() {
    return {
      topPlacement: window.isRTL ? 'top-end' : 'top-start',
      labelPosition: window.isRTL ? 'right' : 'left',
      OPERATOR,
      LOGIC,
      TASK_TYPE,
      Delete,
      CirclePlus,
      showChooseAgent: false,
      selectTemplateDialogVisible: false,
      showChooseCallNo: false,
      selectSurveyDialogVisible: false,
      selectSmsDialogVisible: false,
      showChooseProp: false,
      isOpenDataRule: false,
      busiResultCodes: [],
      conditionTableColumns: [
        {
          label: this.$t('isales.taskInfo.title.filterCondition'), // 筛选条件
          slot: 'condition'
        },
        {
          label: this.$t('isales.common.title.operation'), // 操作
          slot: 'action',
          width: '80px'
        }
      ],
      selectedAttributes: [], // 记录所有已选择的属性，给弹窗中的选项置灰
      addConditionType: 'primary',
      taskTemplate: '',
      basicInfoDisalbed: true,
      conditionDisalbed: true,
      queryType:'',
      queryMode:'',
      selectAutoIvrDialogVisible: false,
      customPrefix : shallowRef({
        render() {
          return h('p', '')
        },
      }),
    }
  },
  async created() {
    this.queryType = this.$route.query.type;
    this.queryMode = this.$route.query.mode;
    await this.initBusiResultCodes()
  },
  methods: {
    async initBusiResultCodes() { // 查询业务结果选项列表
      this.busiResultCodes = await queryResultCodeAndNameOfTenant()
    },
    addCondition() {
      this.showChooseProp = true
      this.addConditionType = 'primary'
    },
    addSubCondition() {
      this.showChooseProp = true
      this.addConditionType = 'sub'
    },
    confirmChooseCondition(data) {
      if (!data.length) return
      if (this.addConditionType === 'primary') {
        this.setPrimaryCondition(data)
      } else {
        this.setSubCondition(data)
      }
      this.$refs.conditionForm.validateField('pickUpCondition')
    },
    setPrimaryCondition(data) {
      data.forEach(item => {
        let count = item.count
        while (count) {
          this.form.pickUp.primaryCondition.push({
            propName: item.propName,
            propId: item.propId,
            mapField: item.mapField,
            value: '',
            operator: 'eq'
          })
          if (!this.selectedAttributes.includes(item.propName)) {
            this.selectedAttributes.push(item.propName)
          }
          count--
        }
      })
    },
    setSubCondition(data) {
      if (!data.length) {
        return
      } else {
        let temp = []
        data.forEach(item => {
          let count = item.count
          while (count) {
            temp.push({
              propName: item.propName,
              propId: item.propId,
              mapField: item.mapField,
              value: '',
              operator: 'eq'
            })
            if (!this.selectedAttributes.includes(item.propName)) {
              this.selectedAttributes.push(item.propName)
            }
            count--
          }
        })
        this.form.pickUp.subConditions.push({
          operator: 'and',
          subConditions: temp
        })
      }
    },
    deletePrimaryCondition(scope) { // 删除主条件
      const index = scope.$index
      this.form.pickUp.primaryCondition.splice(index, 1)
      const selectedAttributeIndex = this.selectedAttributes.findIndex(item => item === scope.row.propName)
      if (selectedAttributeIndex !== -1) {
        this.selectedAttributes.splice(selectedAttributeIndex, 1)
      }
      // 如果主条件删空了，要清空子条件
      if (!this.form.pickUp.primaryCondition.length) {
        this.form.pickUp.subConditions.splice(0)
        // 不能直接重新给数组赋值，需要操作原数组，否则传给弹窗的数据会失去响应式
        this.selectedAttributes.splice(0)
      }
      // 如果主条件已经删空了，不需要校验
      if (this.form.pickUp.primaryCondition.length) {
        this.$refs.conditionForm.validateField('pickUpCondition')
      }
    },
    deleteSubCondition(scope) { // 删除子条件
      const index = scope.$index
      scope.row.subConditions.forEach(item => {
        const selectedAttributeIndex = this.selectedAttributes.findIndex(selectedItem => selectedItem === item.propName)
        if (selectedAttributeIndex !== -1) {
          this.selectedAttributes.splice(selectedAttributeIndex, 1)
        }
      })
      this.form.pickUp.subConditions.splice(index, 1)
    },
    chooseAgent() {
      this.showChooseAgent = true
    },
    confirmChooseAgent(selectedAgents, selectdSkillId, selectdSkillName) {
      this.form.agents = selectedAgents
      this.form.skillId = selectdSkillId
      this.form.skillName = selectdSkillName
      this.$refs.taskInfoForm.validateField('agents')
    },
    confirmChooseCallNo(data) {
      this.form.callerNo = data
    },
    isalesTemplateSelectConfirm(data) {
      console.log(data)
      if (!data.taskId) {
        return
      }
      // data的信息不完整，触发父组件使用taskId查询详情设置form
      if (data.taskId) {
        this.$emit('templateSelectConfirm', data.taskId)
      }
      this.taskTemplate = data.taskName
    },
    clearSurvey() {
      this.form.surveyId = ""
      this.form.surveyName = ""
      this.form.scenarioType = "";
    },
    changeSurvey() {
      if (!this.form.surveyName) {
        this.form.surveyId = "";
        this.form.scenarioType = "";
      }
    },
    isalesSurveySelectConfirm(data) {
      const { scriptId, scriptName, versionNo,scenarioType } = data
      if (!scriptId) {
        return
      }
      this.form.surveyId = scriptId
      this.form.surveyName = `${scriptName}_V${versionNo}`
      this.form.scenarioType = scenarioType;
    },
    changeSmsTemplate() {
      if (!this.form.smsTemplateName) {
        this.form.smsTemplateId = "";
      }
    },
    clearSmsTemplate() {
      this.form.smsTemplateId = ""
      this.form.smsTemplateName = ""
    },
    isalesSmsSelectConfirm(data) {
      const { smsName, id } = data
      if (!id) {
        return
      }
      this.form.smsTemplateId = id
      this.form.smsTemplateName = smsName
      console.log(data)
    },
    deleteAgent(agent, index) {
      this.form.agents.splice(index, 1)
    },
    changeTaskTime(data) {
      this.form.beginDate = data[0]
      this.form.endDate = data[1]
    },
    setSelectedAttributes() {
      // 从返回体中取出已选的属性值
      let temp = []
      this.form.pickUp.subConditions.forEach(conditions => {
        temp.push(...conditions.subConditions)
      })
      temp.push(...this.form.pickUp.primaryCondition)
      temp = temp.map((item) => item.propName)
      // 清空原数组
      this.selectedAttributes.splice(0)
      this.selectedAttributes.push(...temp)
    },
    async validateBatchEdit() { // 批量修改的基本校验
      if (this.basicInfoDisalbed && this.conditionDisalbed) {
        this.$message({
          type: 'warning',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.taskinfo.message.batchUpdate.paramsNull')
        })
        return false
      }
      let validateType = []
      if (!this.basicInfoDisalbed) {
        validateType.push('taskInfo')
      }
      if (!this.conditionDisalbed) {
        validateType.push('condition')
      }
      const { taskInfoValid, conditionValid } = await this.validate(validateType)
      console.log({ taskInfoValid, conditionValid })
      if (taskInfoValid !== true || conditionValid !== true) {
        return false
      }
      return true
    },
    getBatchEditDisablesStatus() { // 提供给父页面获取form的编辑状态
      return { basicInfoDisalbed: this.basicInfoDisalbed, conditionDisalbed: this.conditionDisalbed }
    },
    async validate(type=['taskInfo', 'condition']) {
      let taskInfoValid = true
      let conditionValid = true
      if (type.includes('taskInfo')) {
        taskInfoValid = await this.$refs.taskInfoForm.validate().catch(err => err)
      }
      if (type.includes('condition')) {
        conditionValid = await this.$refs.conditionForm.validate().catch(err => err)
        if (conditionValid !== true) {
          // 主条件会有一个index为-1的rule，原因未知，暂时规避
          if (conditionValid['pickUp.primaryCondition.-1.value']) {
            delete conditionValid['pickUp.primaryCondition.-1.value']
          }
          if (!Object.keys(conditionValid).length) {
            conditionValid = true
          }
        }
      }
      return { taskInfoValid, conditionValid }
    },
    callTimesValidator (rule, value, callback) {
      if (!value && value !== 0) { // 不填的时候，不校验
        callback()
      } else if ( Number(value) < rule.min || Number(value) > rule.max) {
        callback($t('isales.taskpolicy.message.rangeAutoCallTime', [rule.min, rule.max]))
      } else {
        callback()
      }
    },
    isalesAutoIvrSelectConfirm(data) {
      if (data) {
        this.form.ivrAccessCode = data.flowAccessCode;
        this.form.flowName = data.flowname;
      }
    },
    disabledDate(date) {
      const currentDate = new Date();
      let today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      return date.getTime() < today.getTime();
    },
  }
}
</script>

<style lang="less" scoped>
.card-header {
  font-size: var(--16px-to-rem);
  color: #1C1C1C;
  font-weight: bold;
}
.isales-task-info-add {
  font-size: 14px;
  color: #366BFC;
  font-weight: bold;
}
.width-180px-to-rem {
  width: var(--180px-to-rem) !important;
}
.task-icon {
  font-size: 18px !important;
  color: #366BFC;
}
.margin-bottom-10px-to-rem {
  margin-bottom: 0.625rem;
}
.required-label:before {
  content: '*';
  color: var(--el-color-danger);
  margin-right: 4px;
}
.condition-wrap {
  padding: 1.25rem;
  background: #FBFBFB;
  border-radius: 8px;
}
.width-400px {
  width: 400px;
  border-radius: 4px;
}
.icon-class{
  margin-right: 9px;
}
body.RTL{
  .icon-class{
    margin-left: 9px;
    margin-right: unset;
  }
}
.create-button {
  font-size: 14px;
  font-weight: bold;
}
</style>