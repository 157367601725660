export default {
  "specialCharacters" : "حرف خاص",
  "announcements" : "اعلانات",
  "digit" : "عدد",
  "postCode" : "الرمز البريدي",
  "mainTitle" : "سحابة خدمة العملاء",
  "mailMessageManagement" : "إدارة الرسائل البريدية",
  "howToUseTheCallCenter" : "دليل المستخدم",
  "pendingMessages" : "الرسائل المعلقة ({n})",
  "frontDeskWork" : "مكتب العمل الأمامي",
  "receiveGroup" : "مجموعة الاستلام",
  "schedule" : "جدولة التحول",
  "passwordExpireMessage" : "كلمة المرور التي قمت بتعيينها على وشك الانتهاء. لا يمكنك تسجيل الدخول إلى النظام بعد انتهاء صلاحية كلمة المرور. قم بتغيير كلمة المرور الآن.",
  "selectedShortcutMenu" : "القائمة المختصرة المحددة",
  "descriptionOfInvalidandUnqualifiedPasswords" : "وصف كلمة المرور غير صالح وغير مؤهل:",
  "verifyCodeInvalid" : "رمز التحقق غير صالح.",
  "configMenuSearchPlaceholder" : "كلمات البحث في قائمة البحث",
  "notificationDetails" : "تفاصيل الإشعار",
  "settingShortcutEntry" : "تعيين إدخال الاختصار",
  "bulletinType" : "نوع النشرة",
  "changePasswordSuccessAndLogout" : "تم تغيير كلمة المرور بنجاح! النظام على وشك الخروج تلقائياً!",
  "systemError" : "خطأ في النظام",
  "outboundTask" : "مهمة صادرة",
  "oldPasswordEmpty" : "أدخل كلمة مرور.",
  "confirm" : "تأكيد",
  "letter" : "رسالة",
  "urbanArea" : "المنطقة الحضرية",
  "knowledgeCenter" : "مركز المعرفة",
  "lowercaseLetters" : "أحرف صغيرة",
  "numberOfCallLosses" : "التخلي عن المكالمات",
  "changePasswordSuccess" : "تم تعيين كلمة المرور الجديدة بنجاح. يرجى تذكر كلمة المرور الجديدة. ستعود الصفحة في غضون 3 ثوانٍ.",
  "account" : "الحساب",
  "subTitle" : "واضح واجهة المستخدم للخدمة في نهاية المطاف",
  "addStaff" : "إضافة طاقم عمل",
  "passwordRuleForbidName" : "لا يمكن احتواء الاسم",
  "passwordNotSame" : "كلمات المرور غير متناسقة.",
  "myToDo" : "بلدي للقيام",
  "signOut" : "تسجيل الخروج",
  "calledSettings" : "الإعدادات التي تم الاتصال بها",
  "systemBulletin" : "نشرة النظام",
  "setSkillQueue" : "تعيين قائمة انتظار المهارات",
  "passwordFirstChange" : "كلمة المرور الحالية هي كلمة المرور الأولية. لضمان الأمان، قم بتغيير كلمة المرور على الفور.",
  "passwordInvalid" : "لا تتوافق كلمة المرور الحالية مع قواعد كلمة المرور. يُرجى إعادة تعيينها وفقًا للقواعد.",
  "accountLocked" : "تم تأمين المستخدم نظرًا لتجاوز عدد حالات فشل تغيير كلمة المرور الحد. سيتم عرض صفحة تسجيل الدخول في 3 ثوانٍ.",
  "more" : "المزيد",
  "message" : "رسالة",
  "internalBulletin" : "نشرة داخلية",
  "continueLogin" : "متابعة تسجيل الدخول",
  "pageInvalid" : "الصفحة غير صالحة. يرجى إعادة تحميل الصفحة.",
  "callsToday" : "المكالمات اليوم:",
  "newPasswordEmpty" : "أدخل كلمة مرور جديدة.",
  "helpCenter" : "مركز المساعدة",
  "totalNumberOfCalls" : "إجمالي المكالمات",
  "LogoutMessage" : "هل تريد بالتأكيد الخروج؟",
  "ok" : "حسناً",
  "ConfigureFirstBot" : "تكوين الروبوت",
  "internalMessage" : "رسالة داخلية",
  "messageTime" : "وقت الرسالة",
  "createCaseOrder" : "إنشاء حالة",
  "newPassword" : "كلمة مرور جديدة",
  "passwordHasExpried" : "انتهت صلاحية كلمة المرور",
  "confirmPassword" : "تأكيد كلمة المرور",
  "taskProgress" : "تقدم المهمة",
  "welcomeBack" : "مرحبا بعودتك",
  "passWordRuleMaxLength" : "يمكن أن تحتوي كلمة المرور على [1] حرف كحد أقصى",
  "uppercaseLetters" : "أحرف كبيرة",
  "copyrightInfomation" : "حقوق النشر محفوظة لشركة Huawei Software Technologies Co., Ltd. جميع الحقوق محفوظة.",
  "passWordRuleLength" : "تحتوي كلمة المرور على [1] إلى [2] حرفًا",
  "homePage" : "الصفحة الرئيسية",
  "configFirstAgent" : "تكوين الموظف",
  "forum" : "المنتدى",
  "sendNotification" : "إرسال إشعار",
  "resetSkillQueue" : "إعادة تعيين قائمة انتظار المهارة",
  "passwordRuleForbidContinueCharacter" : "لا يمكن أن يتجاوز عدد الأحرف المتكررة المتتالية [1]",
  "workbenchConfiguration" : "تكوين طاولة العمل",
  "necessaryContains" : "إلزامي",
  "entryIsEmpty" : "إدخال القائمة المختصرة فارغ. الرجاء الإعداد.",
  "accountEmpty" : "أدخل حسابًا.",
  "useCallFunction" : "كيفية استخدام وظيفة الاستدعاء",
  "passwordForceChange" : "كلمة المرور الحالية هي كلمة المرور الأولية. لأغراض أمنية، قم بتغيير كلمة المرور على الفور.",
  "notificationCenter" : "مركز الإشعارات",
  "name" : "الاسم",
  "virtualCallCenterTrendsOfTheDay" : "اتجاهات مركز الاتصال الافتراضي لليوم",
  "sender" : "المرسل",
  "messageManagement" : "إدارة الرسائل",
  "agentManagement" : "إدارة الوكيل",
  "alarmmessagedetail" : "لديك {0} تنبيهات فحص جديدة لعرضها. انقر لعرض التفاصيل",
  "province" : "مقاطعة",
  "totalNumberOfPeopleInLine" : "المكالمات في قائمة الانتظار",
  "oldPassword" : "كلمة المرور القديمة",
  "queueNumbers" : "عدد قوائم الانتظار الحالية:",
  "passwordFirstChangeMessage" : "كلمة المرور الحالية هي كلمة المرور الأولية، وهي غير آمنة. هل تريد تغيير كلمة المرور الآن؟",
  "performingOutboundCallTask" : "إجراء مهمة مكالمة صادرة",
  "error" : "خطأ",
  "quickEntry" : "الدخول السريع",
  "noOrganization" : "منظمة غير منتسبة",
  "specialCharacterCandidates" : "مرشح شخصية خاصة:",
  "passwordRuleForbidAccount" : "لا يمكن أن يكون الحساب بترتيب إيجابي أو سلبي",
  "alarmmessage" : "رسالة تحذير",
  "changePassword" : "تغيير كلمة المرور",
  "verifyCode" : "رمز التحقق",
  "configCenter" : "مركز التكوين",
  "password" : "كلمة المرور",
  "passwordExpireTitle" : "إشعار انتهاء صلاحية كلمة المرور",
  "alarmnote" : "ملاحظة فحص الجودة",
  "goToSettings" : "انتقل إلى الإعدادات",
  "keywordFilter" : "أدخل كلمة أساسية",
  "notificationReply" : "هل أنت متأكد من حذف هذا الإعلام؟",
  'notificationDeleteConfirm': 'هل تريد بالتأكيد حذف هذا الإعلام؟',
  "robotManagement" : "إدارة الروبوت",
  "workOrder" : "طلب العمل",
  "passwordFirstChangeTitle" : "تذكير كلمة المرور الأولية",
  "caseHome" : "القضية الرئيسية",
  "passwordIsAboutToExpire" : "كلمة المرور على وشك الانتهاء. قم بتغيير كلمة المرور.",
  "verifyCodeEmpty" : "أدخل رمز التحقق.",
  "passWordRuleMinLength" : "يجب أن تحتوي كلمة المرور على [1] حرف على الأقل",
  "averageCallTime" : "متوسط وقت التحدث:",
  "cancel" : "إلغاء",
  "signIn" : "تسجيل الدخول",
  "address" : "العنوان",
  'id':'معرف الهوية',
  'decs':'الوصف',
  'cause':'لماذا؟',
  'action':'إجراءات الإصلاح',
  'privacystatement':'بيان خصوصية AICC',
  personalCenter:"المركز الشخصي",
  'noagent':"الحساب غير مرتبط بموظف. اتصل بالمسؤول لتكوينه",
  'nickName':"الاسم المستعار للجلسة",
  'socailChatNum':"عدد العملاء الذين يخدمون الجلسة في نفس الوقت",
  'avatar':"صورة رأس المحادثة",
  'confirmClose':"تأكيد الإغلاق",
  'importListView': "عرض مهام الاستيراد",
  'listView': "عرض المهام",
  'minorityLanguageTips': 'عذرًا ، يدعم نظام WFM اللغتين الصينية والإنجليزية فقط. يرجى التبديل إلى لغة أخرى.',
  'minorityLanguageTipsAgent': `عذرًا، لا يدعم نظام WFM اللغة البرتغالية والتايلاندية. يرجى التبديل إلى لغة أخرى.`,
  "sureCloseCurrentPage": "هل تريد بالتأكيد إغلاق الصفحة؟?",

}