export default {
  "ccmessaging.chat.chat.closeDialog": "هل أنت متأكد من إغلاقه؟",
  "ccmessaging.chat.silentagent.config.add.error": "فشل في إنشاء تكوين الموظف الصوتي الصامت",
  "ccmessaging.chat.silentagent.config.add.success": "تم تكوين الموظف الصوتي الصامت بنجاح",
  "ccmessaging.flowControl.deleteSuccess": "تم حذف تكوين التحكم في التدفق بنجاح.",
  "ccmessaging.chat.fiveGCard.add": "بطاقة 5G جديدة",
  "ccmessaging.chat.fiveGCard.edit": "تحرير بطاقة 5G",
  "ccmessaging.chat.fiveGCarouselCard.add": "إضافة بطاقة دائرية 5G",
  "ccmessaging.chat.fiveGCarouselCard.edit": "تحرير بطاقة دائرية 5G",
  "ccmessaging.chat.webCard.add": "إضافة بطاقة ويب",
  "ccmessaging.chat.webCard.edit": "تحرير بطاقة ويب",
  "ccmessaging.chat.coBrowseSite.add": "إضافة عنوان تعاون صفحة ويب",
  "ccmessaging.chat.coBrowseSite.edit": "تحرير عنوان تعاون صفحة الويب",
  "ccmessaging.chat.richTextPage.add": "إضافة نص غني",
  "ccmessaging.chat.richTextPage.edit": "تحرير النص المنسق",
  "ccmessaging.chat.chatPhrase.add": "إضافة عبارات عامة",
  "ccmessaging.chat.chatPhrase.edit": "تحرير العبارات الشائعة",
  "ccmessaging.chat.audioLibrary.add": "إضافة صوت",
  "ccmessaging.chat.audioLibrary.edit": "تحرير الصوت",
  "ccmessaging.chat.videoLibrary.add": "فيديو جديد",
  "ccmessaging.chat.videoLibrary.edit": "تحرير الفيديو",
  "ccmessaging.chat.imgLibrary.add": "إضافة صورة",
  "ccmessaging.chat.imgLibrary.edit": "تحرير الصورة",
  "ccmessaging.chat.emoLibrary.add": "إضافة رموز تعبيرية مخصصة",
  "ccmessaging.chat.emoLibrary.edit": "تحرير رموز تعبيرية مخصصة",
  "ccmessaging.chat.docLibrary.add": "إضافة مستند",
  "ccmessaging.chat.docLibrary.edit": "تحرير مستند",
  "ccmessaging.chat.locate.add": "عنوان جديد",
  "ccmessaging.chat.locate.edit": "تحرير العنوان",
  "ccmessaging.chat.richText.idInput": "أدخل معرّفًا.",
  "ccmessaging.chat.email.check.contentOverMax": "يتجاوز حجم نص الرسالة الحد.",
  "ccmessaging.chat.multimedia.descriptionSuperLong": "يتجاوز طول الوصف الحد الأقصى.",
  "ccmessaging.chat.multi.chatcard.width.medium": "عرض متوسط",
  "cmessaging.chat.channelconfig.pushWebMessageKey": "مفتاح",
  "ccmessaging.chat.channelconfig.Certification": "طريقة التحقق",
  "ccmessgaing.postChannelConfig.apiFabricAK": "ApiFabricAK",
  "ccmessaging.channelconfig.sms.selectAutoReply": "قالب الرد التلقائي للرسائل القصيرة",
  "ccmessaging.chat.channelconfig.agentWorkTime": "وقت عمل الموظف",
  "chat.usefulexpressions.message.saveSuccess": "تم حفظ العبارة العامة بنجاح.",
  "ccmessgaing.postChannelConfig.success": "النجاح",
  "ccmessaging.socialPostOperation.field.status.approved": "اكتملت",
  "ccmessaging.chat.agentconsole.selectCaseType": "تحديد فئة طلب عمل",
  "ccmessaging.chat.phrase.addPhraseSuccess": "تم إنشاء عبارة عامة بنجاح",
  "ccmessaging.chat.speciallist.unuselevel": "(غير متوفر)",
  "ccmessaging.ruleconfig.tips4": "سيقوم `\"\\*{'@'}\\*.com\" بحظر جميع رسائل البريد التي تحمل اسم المجال \".com\". توخي الحذر عند استخدام هذا الأمر.`",
  "ccmessaging.chat.chatcard.linking": "ربط",
  "chat.client.greeting.dear": "عزيزتي",
  "ccmessaging.ruleconfig.tips3": "`مربع نص المحتوى باستثناء \"\\*\" \"\\?\" يجب استيفاء قواعد عنوان البريد الإلكتروني.`",
  "ccmessaging.chat.agentconsole.emailNotSupport": "قناة البريد الإلكتروني لا تدعم رسائل الوسائط المتعددة.",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.assigning.tip": "لا يمكن تعيين الرسائل المهملة.",
  "ccmessaging.ruleconfig.tips2": "`على سبيل المثال. يشير \"\\*{'@'}huawei.com\" إلى رفض جميع رسائل البريد الإلكتروني التي يكون اسم نطاقها \"huawei.com\"",
  "ccmessaging.ruleconfig.tips1": "`يمكنك إدخال أحرف البدل في عنوان البريد الإلكتروني. يشير \"\\*\" إلى تطابق 0 أو أكثر من الأحرف. يشير \"\\?\" إلى أن الأحرف من 0 إلى 1 متطابقة.`",
  "ccmessaging.chat.multi.chatcard.delete": "تمت الإشارة إلى القالب ولا يمكن حذفه. معرف بطاقة NVOD المشار إليه هو:",
  "ccmessaging.chat.agent.transferSessionFail": "فشل نقل الجلسة",
  "chat.agent.title.enterText": "يُرجى إدخال",
  "ccmessaging.socialPostOperation.field.publishTimeSet": "إعدادات وقت الإصدار",
  "ccmessaging.chat.channelconfig.robotApplicationKey": "مفتاح تطبيق Chatbot",
  "chat.usefulexpressions.message.contentLength": "تأكد من أن طول العبارة العامة أقل من أو يساوي 1000 حرف.",
  "ccmessaging.socialPostOperation.field.createUserName": "مقدم الطلب",
  "ccmessaging.chat.whatsapp.templateHeaderType.text": "نص",
  "ccmessaging.chat.multimediaasccociation.releasetitle": "تحرير بيانات الوسائط المتعددة",
  "ccmessaging.chat.chatpersonalization.prompt": "موجه",
  "ccmessaging.socialPostOperation.select.pleaseChoose": "الرجاء الاختيار",
  "chat.agent.title.transfer": "تحويل إلى",
  "ccmessaging.chat.agentconsole.hisconversation": "محادثة تاريخية",
  "ccmessaging.emailcatalog.selectcatalog": "تحديد الدليل",
  "ccmessaging.chat.channelconfig.close": "إغلاق",
  "ccmessaging.chat.chatconfig.channelOccupied": "القناة مشغولة بالفعل.",
  "ccmessaging.chat.chatcard.mediaHeight": "ارتفاع الوسائط",
  "ccmessgaing.postChannelConfig.addUsers": "إضافة",
  "ccmessaging.chat.emailmessage.disAssigned": "لم يتم تعيينه",
  "chat.skill.label.inputName": "الاسم",
  "ccmessaging.socialPostOperation.tips.approveSuccess": "تمت الموافقة بنجاح",
  "ccmessgaing.postChannelConfig.editSuccess": "نجح في تحديث معلومات التكوين لقناة الوسائط المتعددة الاجتماعية.",
  "ccmessaging.chat.phrase.phraseTypeRepeat": "فئة العبارة العامة مكررة",
  "ccmessaging.chat.channelconfig.email.receiverLimit": "الحد الأقصى للمستلمين",
  "chat.session.label.evaluation": "تقييم الحوار",
  "ccmessaging.socialPostOperation.youTube.embedding.tips": "يسمح للآخرين بتضمين مقاطع الفيديو الخاصة بك في مواقع الويب الخاصة بهم.",
  "ccmessaging.chat.chatpersonalization.delete": "حذف",
  "ccmessaging.chat.silentAgent.workMode.common": "شائعة",
  "ccmessaging.chat.agentconsole.novoEmail": "بريد إلكتروني جديد",
  "ccmessaging.ruleconfig.deleteFail": "فشل حذف القاعدة.",
  "ccmessaging.flowControl.periodType.day": "يوميًا",
  "ccmessaging.emailMessageManage.forward": "إعادة توجيه السجل",
  "ccmessaging.email.attachment.fileType.tip4": "M.",
  "ccmessaging.email.attachment.fileType.tip3": "لا يمكن أن يتجاوز حجم الملف",
  "ccmessaging.email.attachment.fileType.tip2": ",",
  "ccmessaging.email.attachment.fileType.tip1": "مرفقات البريد الإلكتروني تدعم تنسيقات الملفات التالية:",
  "chat.session.label.exitQueueTime": "وقت إنهاء قائمة الانتظار",
  "ccmessaging.chat.chatmanage.autoEndSessionMorethan": "لا يمكن أن يتجاوز وصف الفاصل الزمني الذي لا يوجد رد فيه العميل 100 حرف.",
  "ccmessaging.flowControl.limitNum": "الحد الأقصى للرسائل",
  "ccmessaging.chat.whatsapp.languageESMX": "الإسبانية (المكسيك)",
  "ccmessaging.chat.chatholiday.workbench": "تكوين طاولة العمل",
  "ccmessaging.socialPostOperation.youTube.tags.tips": "أدخل فاصلة بعد كل تسمية",
  "ccmessaging.flowControl.operate": "العملية",
  "ccmessaging.chat.chatpersonalization.tips1": "لم يتم التحقق من التكوين الذي تم إرساله!",
  "ccmessaging.chatbot.label.useIntelligentRobot": "الاتصال بالروبوت الذكي",
  "chat.session.endType.customerEnd": "نهاية حسب العميل",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsg": "حدد رسالة قالب WhatsApp المطابقة.",
  "ccmessaging.chat.channelconfig.webhookConfirmPassword": "Webhook تأكيد كلمة المرور",
  "ccmessaging.chat.multi.chatcard.choose": "تحديد البطاقة",
  "ccmessaging.chat.channelconfig.selectConfigurationMethod": "اختيار طريقة التهيئة",
  "ccmessaging.chat.channelconfig.wechat.qr.label3": "نجاح التفويض",
  "ccmessaging.chat.chatmanage.agentIntervalTime": "فترة مهلة رد الوكيل",
  "ccmessaging.chat.channelconfig.wechat.qr.label2": "إعادة تفويض",
  "chat.agent.label.send": "إرسال",
  "ccmessgaing.postChannelConfig.selectByChannelName": "اسم القناة",
  "ccmessaging.socialPostOperation.field.publishStatus": "حالة الإصدار",
  "ccmessaging.chat.chatholiday.sunday": "الأحد",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.context": "لا يوجد نص في مربع النص. لا تحتاج إلى التحقق من الإملاء.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.mistake": "لا أخطاء إملائية",
  "ccmessaging.emailcatalog.moveOtherCatalogFailed": "لا يمكن نقل رسائل البريد الإلكتروني للموظفين الآخرين.",
  "ccmessaging.chat.channelconfig.weChatBackground": "خادم WeChat",
  "ccmessaging.chat.specialChannel.update": "تعديل قاعدة قائمة خاصة",
  "ccmessaging.channelconfig.sms.autoReply.tips": "يتم إرسال رد تلقائي بعد اتصال الموظف بنجاح.",
  "ccmessaging.chat.phrase.AllCount": "مجموع الكلمات",
  "ccmessaging.chat.chatcard.queryTemplateFail": "فشل قالب الاستعلام",
  "ccmessaging.chat.system.error": "النظام مشغول. يُرجى إعادة المحاولة لاحقًا.",
  "ccmessaging.chat.template.languageCode": "اللغة",
  "ccmessaging.chat.channelconfig.name": "الاسم",
  "ccmessaging.fiveG.label.chatRobotAddress": "عنوان Chatbot",
  "ccmessaging.silentagent.delete.selectone": "يرجى تحديد سجل واحد على الأقل.",
  "ccmessaging.chatbot.label.avatarImport": "تحميل الصورة الرمزية",
  "ccmessaging.chat.chatmanage.intervalTime": "فترة مهملة رد العميل",
  "ccmessaging.email.label.defaultReceiveGateway": "عنوان البريد الإلكتروني الافتراضي",
  "ccmessaging.flowControl.description": "الوصف",
  "chat.skill.message.success": "النجاح",
  "chat.skill.label.skillDetail": "تفاصيل المهارة",
  "chat.skill.message.isExisted": "قائمة انتظار المهارات موجودة بالفعل.",
  "ccmessaging.chat.chatholiday.designation.selectrule": "لا يمكن أن يكون التاريخ المحدد هو نفس التاريخ المحدد",
  "ccmessaging.chat.agentconsole.agentStatus.error": "لا يمكن بدء مكالمة لأن الموظف ليس متصلاً أو يتحدث.",
  "ccmessaging.chat.channelconfig.trybutton": "جرب",
  "ccmessaging.chat.agentconsole.promptConetnt.cannot.rollback": "لا يمكن سحب الرسالة",
  "chat.common.message.modify": "تعديل",
  "ccmessaging.chat.agentconsole.multimedia": "الوسائط المتعددة",
  "ccmessaging.chat.email.autosave": "تم حفظ تحريراتك تلقائيًا في مسودة.",
  "ccmessaging.chat.chatconfig.channelType": "نوع القناة",
  "ccmessaging.chat.chatmanage.specialCharacters": "تحتوي القيمة على أحرف خاصة. يرجى تعديله.",
  "ccmessaging.socialPostOperation.search.searchTitle": "الموضوعات",
  "ccmessaging.chat.chatpersonalization.specialStrValidateFailed": "لا يمكن أن تحتوي القيمة على أحرف خاصة.",
  "ccmessaging.chat.channelconfig.delete.exist.asyncMsg": "فشل حذف قناة [{n}]. حاول مرة أخرى بعد تعيين الرسائل دون اتصال.",
  "ccmessaging.chat.agentconsole.promptConetnt.userInfoNull": "تم الحصول على UserInfomation غير صحيح ، سيتم قطع اتصال الجلسة!",
  "ccmessgaing.postChannelConfig.subChannelId": "معرف القناة",
  "chat.thirdportal.chatWindow.label.userName": "الاسم",
  "ccmessaging.chat.agentconsole.send.repeat": "لا تنقر بشكل متكرر!",
  "ccmessaging.chat.chatpersonalization.saveSuccess": "تم الحفظ بنجاح.",
  "ccmessaging.chat.channelconfig.privateKeyValidate": "يجب أن تكون القيمة بتنسيق JSON. يمكن أن تحتوي قيمة المفتاح في JSON على أحرف وأرقام فقط والأحرف الخاصة التالية: _~!{'@'}#$%^&*- أي تكوين",
  "ccmessaging.chat.contact.callinandcallout": "مكالمة واردة وصادرة",
  "ccmessaging.chat.email.channel.receiveGateway": "بوابة استقبال البريد الإلكتروني",
  "ccmessaging.chat.chatcard.tabs.displaytext": "نص عرض البطاقة",
  "ccmessaging.chat.facebookpageconfig.deleteSuccess": "تم حذف إعدادات الصفحة الرئيسية لـ Facebook بنجاح.",
  "ccmessaging.chat.chatmanage.workTime": "وقت عمل الموظف (بتنسيق 24 ساعة)",
  "ccmessaging.chat.async.asyncUserNum": "عدد المستخدمين غير المتصلين",
  "ccmessaging.chat.multimedia.deleteMultiMediaType": "حذف فئة الوسائط المتعددة هذه",
  "ccmessaging.chat.whatsapp.templateHeaderType": "رأس",
  "ccmessaging.chat.chatpersonalization.welcome": "تحية طيبة",
  "ccmessaging.chat.chatcard.tabs": "خيارات البطاقة",
  "ccmessaging.chat.speciallist.restrictUnit": "ساعة",
  "ccmessaging.chat.agentconsole.video": "فيديو",
  "ccmessaging.chat.channelconfig.envName": "ديف البيئة التسمية",
  "chat.usefulexpressions.message.selectExpressions": "حدد عبارة عامة.",
  "ccmessaging.ruleconfig.emailAddressExists": "عنوان البريد الإلكتروني موجود بالفعل",
  "ccmessaging.chat.chatholiday.working.day": "يوم العمل",
  "chat.weChatConfig.message.skillExisted": "تم ربط قائمة انتظار المهارات المحددة بحساب عام آخر.",
  "ccmessaging.chat.multimedia.richTextContentLongLimit": "يمكن أن يحتوي محتوى النص المنسق على 5000 حرف كحد أقصى.",
  "ccmessaging.socialPostOperation.youTube.view.orderType": "وضع الفرز",
  "ccmessaging.chat.channelconfig.cert.empty": "لا يمكن أن تكون الشهادة فارغة عندما يستخدم عنوان الدفع بروتوكول HTTPS.",
  "ccmessaging.operlogs.multimedialibrary.save.image": "احفظ تكوين البيانات في مكتبة الوسائط المتعددة وقم بتحميل بيانات الملف من نوع الصورة.",
  "chat.agentConsole.message.content.audio": "[رسالة صوتية]",
  "ccmessaging.chat.webCard.copyUrl": "نسخ الرابط",
  "ccmessaging.chat.emailmessage.assignEmailSuccess": "تم تعيين البريد الإلكتروني بنجاح.",
  "ccmessaging.chat.chatholiday.designation.type": "نوع التاريخ",
  "ccmessaging.socialPostOperation.tips.approvingDelete": "لا يمكن حذف البيانات التي في الحالة \"يتم الموافقة عليها\"",
  "ccmessaging.chat.multiMedia.fileUploadNameError": "تنسيق اسم الملف الذي تم تحميله غير صحيح.",
  "ccmessaging.chat.channelconfig.apiKey": "مفتاح API",
  "chat.webconfig.label.greeting": "تحية",
  "chat.client.label.loginTitle": "تسجيل الدخول",
  "ccmessaging.chat.email.channel.modifydraftsuccess": "نجح التعديل.",
  "chat.skill.label.maxQueueTimeInTable": "ماكس. وقت الانتظار",
  "ccmessaging.socialPostOperation.edit.publishInformation": "معلومات الافراج",
  "ccmessaging.operlogs.emailAttachment.download": "تنزيل مرفقات البريد الإلكتروني",
  "ccmessaging.chat.agentconsole.label.ongoingwx": "لا يمكن للقناة الحالية إرسال معلومات الموقع!",
  "ccmessaging.chat.channelconfig.defaultReply": "الرد الافتراضي",
  "ccmessaging.channelconfig.email.freeTimeText": "الرد التلقائي خلال غير ساعات العمل",
  "ccmessgaing.postChannelConfig.deleteFailed.db": "فشل الحذف. فشلت عملية قاعدة البيانات.",
  "ccmessaging.ruleconfig.selectRule": "الرجاء تحديد قاعدة",
  "chat.client.label.chatTitle": "العميل",
  "ccmessaging.chat.multimediaasccociation.examinationpassed": "تمت الموافقة",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.failTip": "فشل جمع إحصائيات عن عناصر التوقيع في العقد.",
  "ccmessaging.chat.multimedia.chatPhraseManagement": "مكتبة العبارات الشائعة",
  "ccmessaging.chat.silentagent.aceesscode.empty": "لا يمكن أن يكون رمز الوصول خاليًا!",
  "ccmessaging.pop.org.window.org": "مع OU",
  "ccmessaging.chat.multimedia.documentUploadTipFormat": "يمكن تحميل الملفات بتنسيق Word أو PDF أو zip. يدعم الملف المضغوط تنسيقات Word وpdf وppt وexcel. لا يمكن أن يتجاوز حجم الملف {n} ميغابايت.",
  "ccmessaging.chat.templateMsg.chooseMultimedia": "حدد مورد وسائط متعددة.",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoError": "فشل حذف بيانات الوسائط المتعددة!",
  "ccmessaging.socialPostOperation.field.publishAt": "وقت الإصدار",
  "chat.thirdportal.chatWindow.label.userEmail": "البريد الإلكتروني",
  "ccmessaging.chat.email.localImage.height": "الارتفاع",
  "ccmessaging.cobrowsesite.mediaurl.format.error": "تنسيق موقع الاستعراض المشترك غير صحيح.",
  "ccmessaging.chat.chatpersonalization.add": "إضافة",
  "ccmessaging.chat.chatconfig.reset": "إعادة الضبط",
  "ccmessaging.chat.iframeagentconsole.mail": "البريد الإلكتروني",
  "ccmessaging.socialPostOperation.tips.cancelError": "لا يمكن إبطال بيانات عدم الموافقة",
  "ccmessaging.ruleconfig.invalidSMSNumber": "رقم الجوال غير صالح.",
  "ccmessaging.chat.speciallist.use": "متوفر",
  "ccmessaging.chat.email.tips.amendFailOtherErrors": "فشل التعديل: أخطاء أخرى.",
  "ccmessaging.socialPostOperation.field.image": "صورة",
  "ccmessaging.chat.async.asyncMsgTrending": "اتجاه الرسائل دون اتصال",
  "ccmessaging.chat.channelconfig.timerange": "النطاق الزمني",
  "ccmessaging.chat.channelconfig.select.algorithm.safetips": "هذه الخوارزمية ليست قوية بما فيه الكفاية. إذا كان النظام الأساسي يدعم هذه الخوارزمية، يُنصح باستخدام خوارزمية تشفير أعلى.",
  "ccmessaging.chat.agentconsole.multimedianame": "اسم الوسائط المتعددة",
  "ccmessaging.chat.silentagent.DeleteFail": "فشل حذف تكوين الموظف الصامت.",
  "ccmessaging.chat.chatmanage.autoEndSessionRange": "يجب أن يتراوح نطاق رقم الفاصل الزمني للتذكير بلا رد من العميل بين 0 و60",
  "ccmessaging.chat.chatholiday.designation.show": "كل سنة",
  "ccmessaging.chat.channelconfig.skill": "قائمة المهارات (رمز الوصول+الرمز الفرعي)",
  "ccmessaging.chat.webcard.pop.confirm.suffix": "هل أنت متأكد من رغبتك في المتابعة؟",
  "ccmessaging.ruleconfig.maxSMSNumberCount": "يمكن إضافة 10 أرقام جوال كحد أقصى.",
  "ccmessaging.socialPostOperation.field.postType": "قناة الإصدار",
  "ccmessaging.chat.silentagent.sendContent.supervise": "تعذر على \"العامل الصامت\" إرسال رسالة في WorkMode المشرف!",
  "chat.common.message.fail": "فشل",
  "ccmessaging.chat.portraitchange.customize": "مخصص",
  "ccmessaging.chat.chatcard.image": "صورة",
  "ccmessaging.chat.chatholiday.description": "الوصف",
  "ccmessaging.socialPostOperation.tips.saveApproveSuccess": "تم الإرسال بنجاح. في انتظار الموافقة.",
  "ccmessaging.chat.multimedia.videoUploadTipFormat": "يدعم ملف الفيديو الذي تم تحميله حاليًا تنسيق mp4 فقط ، ويجب ألا يتجاوز حجم الملف {n}M.",
  "chat.weChatConfig.title.deleteTitle": "حذف عناصر تكوين WeChat",
  "chat.web.evaluation.star5": "راض جدا",
  "chat.web.evaluation.star4": "راضية",
  "chat.web.evaluation.star3": "عام",
  "ccmessaging.message.selectEmail.null": "حدد بريدًا إلكترونيًا.",
  "chat.web.evaluation.star2": "غير راضين",
  "chat.web.evaluation.star1": "غير راضٍ جداًً",
  "ccmessaging.socialPostOperation.field.status": "الحالة",
  "chat.skill.message.willDeleteSkill": "هل تريد بالتأكيد حذف المهارة؟",
  "chat.weChatConfig.label.codeName": "رمز الوصول إلى القناة",
  "ccmessaging.chat.multimediaasccociation.isrelease": "هل تريد بالتأكيد إصدار بيانات الوسائط المتعددة إلى قناة الجيل الخامس؟",
  "chat.client.message.evaluationFail": "فشل استطلاع الرضا.",
  "ccmessaging.socialPostOperation.tips.publishFailed": "فشل النشر.",
  "ccmessaging.chat.emailmessage.receiver": "المتلقي",
  "ccmessaging.chat.email.tips.deleteFailOtherErrors": "فشل الحذف: أخطاء أخرى.",
  "ccmessaging.socialPostOperation.youTube.view.subscriber": "1 مشتركين",
  "chat.client.label.order": "أوامري",
  "ccmessaging.chat.speciallist.smschannels": "SMS",
  "chat.weChatConfig.message.ensureDelete": "هل تريد بالتأكيد حذف عنصر التكوين المحدد؟",
  "ccmessaging.silentagent.agent.holiday.worktime.error": "تكوين الموظف في غير وقت العمل غير صحيح. تتداخل مقاطع الوقت.",
  "ccmessaging.chat.chatpersonalization.welcomeLimit": "يمكن تكوين أربع رسائل تحية شخصية بحد أقصى.",
  "ccmessaging.chat.whatsapp.languageESES": "الإسبانية (إسبانيا)",
  "ccmessaging.chat.multimediaasccociation.unreviewed": "لم تتم المراجعة",
  "ccmessaging.socialPostOperation.button.approve": "الموافقة",
  "ccmessaging.chat.phrase.prompt": "موجه",
  "ccmessaging.chat.social.media.enterprise.post.add.failed": "فشلت الإضافة.",
  "ccmessaging.chat.email.tips.deleteFailExistSub": "فشل الحذف: يحتوي الدليل الحالي على أدلة فرعية.",
  "ccmessaging.ruleconfig.ruleContentKeyword": "كلمة أساسية لمحتوى القاعدة",
  "ccmessaging.chat.multimedia.audioUploadTipFormat": "يجب أن يكون الملف الصوتي المراد تحميله بتنسيق MP3 أو AAC أو M4A ولا يمكن أن يتجاوز حجم الملف {n}M.",
  "ccmessaging.flowControl.periodType.week": "أسبوعي",
  "ccmessaging.channelconfig.email.worktime.autoReply.tips": "الرد على البريد الإلكتروني للعميل المستلم خلال ساعات العمل بعد الافتتاح",
  "ccmessaging.channelconfig.sms.selectFreeTimeText": "قالب الرد على الرسائل النصية في أيام عدم العمل",
  "chat.webconfig.label.intergratedcode": "كود متكامل",
  "ccmessaging.flowControl.addFail": "فشل إضافة تكوين التحكم في التدفق.",
  "ccmessaging.socialPostOperation.youTube.publishAtTips": "هذا الإعداد ممكن فقط إذا تم تعيين النطاق العام إلى خاص",
  "ccmessaging.chat.chatconfig.delete": "حذف",
  "ccmessaging.chat.channelconfig.logo": "الصورة الرمزية",
  "chat.session.channel.all": "الكل",
  "ccmessaging.chat.whatsapp.languagePTBR": "البرتغالية (البرازيل)",
  "ccmessaging.socialPostOperation.search.searchContent": "يرجى إدخال المحتوى.",
  "ccmessaging.chat.channelconfig.submit": "سومبيت",
  "ccmessaging.chat.phrase.delete": "حذف",
  "ccmessaging.chat.sumbitverification.Url": "عنوان URL",
  "ccmesaging.chat.agentconsole.label.callReason": "سبب المكالمة",
  "ccmessaging.flowControl.alarmInterval": "فاصل التنبيه",
  "chat.skill.label.reset": "إعادة الضبط",
  "ccmessaging.flowControl.tipTextInterval": "الفاصل الزمني للمطالبة",
  "ccmessaging.chat.multimedia.multiMediaInfoInputInvalid": "بيانات إدخال الوسائط المتعددة غير صالحة!",
  "ccmessaging.silentagent.btn.add.period": "إضافة فترة زمنية",
  "ccmessaging.chat.multimedia.download": "تحميل",
  "ccmessaging.chat.richText.save": "حفظ",
  "chat.usefulexpressions.message.contentNotNull": "لا يمكن أن تكون العبارة العامة فارغة.",
  "ccmessaging.chat.chatemail.details": "عرض",
  "chat.timerange.message.timeerror": "تنسيق الوقت غير صحيح.",
  "ccmessaging.chat.channelconfig.robotSet": "تكوين Chatbot",
  "ccmessaging.chat.whatsapp.templateDescriptionPlaceholder": "أدخل وصف القالب.",
  "ccmessaging.flowControl.periodType": "فترة المدى",
  "ccmessaging.emailcatalog.moveCatalogFailed": "فشل نقل دليل.",
  "chat.session.label.allChannel": "جميع أنواع القنوات",
  "ccmessaging.socialPostOperation.youTube.view.lookNum": "38 وجهات النظر منذ 2 أيام",
  "ccmessaging.socialPostOperation.field.approveStatus.pass": "مرت",
  "ccmessaging.chat.channelconfig.searchCertCode": "رمز شهادة البحث",
  "ccmessaging.socialPostOperation.youTube.labelsDetail": "يمكن أن تساعد العلامات المشاهدين في العثور على الفيديو الخاص بك إذا كانت هناك كلمات بها أخطاء إملائية سهلة في محتوى الفيديو. بخلاف ذلك ، فإن العلامات ليست مفيدة جدًا للعثور على مقاطع الفيديو.",
  "ccmessaging.chat.multimedia.multiMediaTypeInputEmpty": "بيانات إدخال فئة الوسائط المتعددة فارغة!",
  "ccmessaging.chat.whatsapp.templateBodyTextPlaceholder": "أدخل محتوى النص الأساسي ، والمحتوى في {'{{}}'} قابل للتحرير في نص القالب.",
  "ccmessaging.chat.sumbitverification.whatsApp.success": "تحقيق النجاح",
  "ccmessaging.chat.multimedia.imageManagement": "مكتبة الصور",
  "ccmessaging.message.sendstatus.undeliverable": "فشل",
  "ccmessaging.socialPostOperation.tips.deleteApproveSuccess": "تم إرسال الحذف بنجاح. في انتظار الموافقة.",
  "ccmessaging.chat.web.channel.clickivr": "انقر للحصول على رمز وصول IVR.",
  "ccmessaging.chat.agentconsole.label.endsession": "هل سيتم إنهاء الجلسة؟",
  "ccmessaging.chat.templateMsg.sendTemplateMsg": "إرسال",
  "ccmessaging.chat.email.channel.title.reply": "الرد:",
  "ccmessgaing.postChannelConfig.deleteSuccess": "نجح حذف معلومات التكوين لقناة الوسائط المتعددة الاجتماعية.",
  "ccmessaging.emailcatalog.existEmail": "رسائل البريد الإلكتروني موجودة في الدليل.",
  "ccmessaging.chat.email.tips.amendFailDuplicateName": "فشل التعديل: اسم مكرر.",
  "chat.agent.label.offline": "غير متصل",
  "ccmessaging.chat.chatemail.saveDraft": "حفظ كمسودة",
  "ccmessaging.ruleconfig.timeerror.startTime": "* لا يمكن أن يكون وقت البدء قبل الوقت الحالي.",
  "ccmessaging.chat.phrase.isSavePhrase": "هل تريد بالتأكيد حفظ العبارة العامة؟",
  "ccmessaging.email.label.defaultSendGateway": "عنوان البريد الإلكتروني الافتراضي",
  "ccmessaging.chat.channelconfig.email.selectSignature": "توقيع البريد الإلكتروني",
  "ccmessaging.chat.agentconsole.chat.userSettings": "إعدادات المستخدم",
  "ccmessaging.chat.multimediaasccociation.releaseresult": "نتيجة الإصدار",
  "ccmessaging.chat.channelconfig.lastagent.time": "النطاق الزمني",
  "ccmessaging.emailAdress.contentNotNull": "لا يمكن أن يكون عنوان البريد الإلكتروني فارغًا.",
  "ccmessaging.flowControl.deleteSelect": "هل تريد بالتأكيد حذف القاعدة المحددة؟",
  "ccmessaging.chat.channelconfig.update": "تحديث",
  "ccmessaging.chat.multimediaasccociation.releaseerror": "فشل تحرير بيانات الوسائط المتعددة!",
  "ccmessaging.chat.multimedia.name": "الاسم",
  "ccmessaging.chat.agentconsole.multimediaid": "معرف الوسائط المتعددة",
  "ccmessaging.socialPostOperation.youTube.audience": "الجمهور",
  "ccmessaging.chat.channelconfig.select.algorithm": "تحديد خوارزمية تشفير",
  "ccmessaging.chat.agentconsole.sendmessage": "إرسال رسالة",
  "ccmessaging.chatbot.label.basicInformation": "معلومات أساسية",
  "ccmessaging.chat.chatconfig.deletesuccess": "تم الحذف بنجاح.",
  "chat.weChatConfig.stepname.verify": "إرسال للتحقق",
  "ccmessaging.chat.channelconfig.webhookPassword.newPassword": "كلمة مرور Webhook الجديدة",
  "ccmessaging.chat.emailmessage.assignEmail.inputAgentName": "أدخل اسم حساب الموظف.",
  "ccmessaging.chat.chatcard.cardId": "معرف القالب",
  "ccmessaging.chat.whatsapp.languageESAR": "الإسبانية (الأرجنتين)",
  "ccmessaging.socialPostOperation.tips.imgAndVideoChoose": "لا يمكن تحميل الصور ومقاطع الفيديو في نفس الوقت.",
  "ccmessaging.flowControl.title.modify": "تحرير قاعدة قائمة خاصة",
  "ccmessaging.chat.speciallist.haverestrictall": "جميع قنوات هذا النوع من القنوات مقيدة. لا حاجة لتقييد هذه القناة.",
  "ccmessaging.chat.multi.chatcard.width.small": "عرض أصغر",
  "ccmessaging.chat.workbench.transfer.skill": "قائمة انتظار المهارات",
  "ccmessaging.chat.emailmessage.all": "الكل",
  "ccmessaging.chat.chatcard.name": "أدخل الاسم.",
  "ccmessaging.chat.agentconsole.browserecord": "تاريخ التصفح",
  "ccmessaging.chat.phrase.addType": "فئة جديدة",
  "ccmessaging.chat.multimedia.multiMediaNameExist": "اسم بيانات الوسائط المتعددة موجود بالفعل في هذه الفئة!",
  "ccmessaging.chat.chatcard.messageTemplateCreate": "إنشاء قالب بطاقة",
  "ccmessaging.socialPostOperation.youTube.scopePrivate": "خاص",
  "ccmessaging.chat.whatsapp.only.send.template": "Quando um novo usurio do WhatsApp ou o intervalo de contato exceder 24 horas, somente mensagens modelo podem ser enviadas.",
  "chat.agent.label.chatBot": "Chatbot",
  "ccmessaging.chat.localFile.authFail": "لا يوجد إذن إرسال ملف محلي",
  "ccmessaging.chat.querymessage": "استعلام عن رسالة محفوظات الوسائط",
  "ccmessaging.chat.channelconfig.noReplyIntervalTimeValidate": "لا يمكن أن يكون الفاصل الزمني لـ \"رد العميل\" أكبر من الفاصل الزمني لـ \"إشعار عدم رد العميل\".",
  "chat.weChatConfig.title": "تهيئة تمكين الوسائط الاجتماعية",
  "chat.timerange.label.confirmSocailChatNum": "الحد الأقصى لعدد عملاء الخدمة المتزامنة هو 0 سيستخدمون التكوين العام",
  "ccmessaging.socialPostOperation.field.publishStatus.unpublished": "غير منشور",
  "chat.session.label.subChannel": "قناة الوصول الفرعية",
  "ccmessaging.chat.single.media.resource.invalid": "البطاقات التي تشير إلى موارد وسائط غير صالحة.",
  "ccmessaging.chat.channelconfig.personalSetting": "إعدادات مخصصة",
  "chat.weChatConfig.stepname.success": "نجاح الوصول",
  "ccmessaging.chat.chatholiday.friday": "الجمعة",
  "ccmessaging.chat.emailmessage.handler": "معالج",
  "ccmessaging.chat.whatsapp.header": "رأس",
  "ccmessaging.chat.phrase.updatePhraseTypeSucess": "نجح تحديث نوع العبارة العامة.",
  "ccmessaging.chat.agentconsole.intention": "النية",
  "ccmessaging.chat.multimediaasccociation.examinationfailed": "فشل",
  "ccmessaging.chat.channelconfig.whatsAppBackground": "خادم WhatsApp",
  "ccmessaging.chat.channelconfig.pageId": "معرف الصفحة",
  "ccmessaging.chat.sumbitverification.whatsApp.fail": "توليد الفشل",
  "ccmessaging.chat.channelconfig.smsRepeate": "تم استخدام بوابة SMS. انتقل إلى صفحة تكوين توجيه الرسائل لعرض بوابة الرسائل النصية.",
  "chat.webconfig.label.language.en": "الإنجليزية",
  "chat.timerange.label.sevendays": "آخر 7 أيام",
  "chat.webconfig.label.language.pt": "البرتغالية",
  "cmessaging.chat.channelconfig.setAgentTimeout": "نقل الجلسة بسبب عدم رد الوكيل",
  "ccmessaging.chat.label.extCode": "رمز التمديد",
  "ccmessaging.chat.channelconfig.gender": "الجنس",
  "ccmessaging.chat.channelconfig.phoneNumber": "رقم الهاتف",
  "ccmessaging.chat.chatmanage.endTimeNum": "يجب أن يكون وقت الانتهاء رقمًا.",
  "ccmessaging.chat.multimedia.imageUploadChannelFormat": "تدعم قنوات WhatsApp و Web و Telegram و LINE و WeChat و Facebook و Twitter تنسيقات JPG و PNG و JPEG.",
  "ccmesaging.chat.agentconsole.label.historicalcontact": "الاتصال التاريخي",
  "ccmessaging.chat.channelconfig.changeLogo": "تغيير الصورة الرمزية",
  "ccmessaging.silentagent.agent.holiday.worktime.empty": "تكوين الموظف في غير وقت العمل له تكوين فارغ.",
  "ccmessaging.chat.connnect.offlineuser.failed": "فشل بدء مكالمة دون اتصال كموظف.",
  "ccmessaging.chat.channelconfig.silentAgentDelete": "هل تريد بالتأكيد حذف تكوين موظف كتم الصوت المحدد؟",
  "ccmessgaing.postChannelConfig.unauthorized": "لم تتم مصادقة العملية.",
  "ccmessaging.ruleconfig.content": "المحتوى",
  "ccmessaging.chat.multimedia.finish": "إنهاء",
  "ccmessaging.chat.multimedia.latitude": "خط العرض",
  "ccmessaging.chat.channelconfig.email.signature.name": "اسم توقيع البريد الإلكتروني",
  "ccmessaging.chat.label.accessCode": "رمز الوصول",
  "ccmessaging.chat.whatsapp.templateButtonType": "نوع زر القالب",
  "ccmessaging.chat.chatpersonalization.welcometime": "النطاق الزمني",
  "chat.common.message.finish": "حسناً",
  "ccmessaging.chat.emailmessage.fail.assign.UnAssignedEmail.tip": "لا يمكن تعيين رسائل البريد غير المعينة.",
  "ccmessaging.email.label.gatewayName": "اسم العبّارة",
  "ccmessaging.chat.whatsapp.templateHeaderType.none": "N / A",
  "ccmessaging.chat.multimedia.imageUploadTipFormat": "يدعم ملف الصورة الذي تم تحميله حاليًا تنسيقات jpg وpng وjpeg فقط ويجب ألا يتجاوز حجم الملف {n}M.",
  "ccmessaging.chat.multi.chatcard.sort.vertical": "عمودي",
  "ccmessaging.chatofflineCall.noSupportText": "هذه المكالمة هي مكالمة غريبة!",
  "ccmessaging.chat.label.skill": "قائمة انتظار المهارات",
  "ccmessaging.chat.email.channel.savedraftfail": "فشل حفظ البريد الإلكتروني كمسودة.",
  "ccmessaging.chat.workbench.body.transfer": "تحويل المكالمة",
  "chat.weChatConfig.stepname.selectchannel": "تحديد قناة الوصول",
  "ccmessaging.chat.channelconfig.PreferredLastAgent": "وضع الموظف الأخير",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaType": "هل تريد بالتأكيد حذف نوع الوسائط المتعددة وبيانات الوسائط المتعددة الخاصة به؟",
  "ccmessaging.chat.chatholiday.checkHolidaySize": "هناك العديد من الإدخالات، يرجى حذف الإدخالات الإضافية أولاً",
  "ccmesaging.chat.agentconsole.label.uploadimage": "تحميل الصورة",
  "chat.weChatConfig.label.nextStep": "التالي",
  "ccmessaging.chat.chatholiday.tip.descinvalid": "لا يمكن أن يتجاوز الوصف 20 حرفًا",
  "ccmessaging.chat.channelconfig.web.clickToCall": "انقر للاتصال",
  "ccmessaging.chat.social.media.multimedia.manage": "معالجة رسائل الوسائط المتعددة",
  "chat.thirdportal.chatWindow.label.userPhone": "عدد",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfoError": "فشل تعديل بيانات الوسائط المتعددة!",
  "ccmessaging.chat.multimedia.saveMultiMediaInfoError": "فشل حفظ بيانات الوسائط المتعددة!",
  "ccmessaging.chat.agentconsole.recommendedCaseType": "فئة أمر العمل الموصى بها",
  "ccmessaging.socialPostOperation.youTube.permissionsKnow": "فهم أنواع التراخيص.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime": "* لا يمكن أن يكون وقت البدء بعد وقت الانتهاء.",
  "ccmessaging.chat.chatpersonalization.concurrentNumberPriority": "تتمثل أولوية تكوين عدد عملاء الوسائط المتعددة الذين يخدمهم الوكلاء في نفس الوقت فيما يلي: مستوى الموظف> مستوى المؤسسة> مستوى المستأجر. تشير القيمة 0 إلى أن تكوين هذا المستوى لا يسري وأنه يتم استخدام تكوين المستويات الأخرى.",
  "ccmessaging.socialPostOperation.youTube.videoToKids": "هل هذا الفيديو للأطفال؟",
  "ccmessaging.chat.channelconfig.queryType": "وضع التحديد",
  "ccmessaging.chat.social.media.enterprise.tips.open": "تتكشف",
  "ccmessaging.multimedialibrary.cobrowsesite.card.target": "تصفح الهدف:",
  "chat.timerange.message.timeempty": "لا يمكن أن يكون الوقت فارغًا.",
  "ccmessaging.chat.workbench.transfer.agent": "الوكيل",
  "ccmessaging.chat.whatsapp.buttons": "أزرار",
  "ccmessaging.chat.multimedia.cardManagement": "قالب البطاقة",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed": "طول النص المراد التحقق منه يتجاوز 1024.",
  "ccmessaging.chat.chatmanage.cancel": "إلغاء",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noSigns.failTip": "لم يتم العثور على أيقونة موقع التوقيع.",
  "ccmessaging.chat.email.button.batchAssignment": "تعيين الدفعات",
  "ccmessaging.chat.chatpersonalization.hour": "ساعة",
  "ccmessaging.chat.whatsapp.templateButton.none": "N / A",
  "ccmesaging.chat.agentconsole.label.robotChat": "الحوار مع Chatbot",
  "ccmessaging.socialPostOperation.field.publishStatus.published": "تم نشره",
  "ccmessgaing.chat.web.channel.Agent": "وكيل الصوت والفيديو",
  "ccmessaging.chat.chatconfig.rollBack": "سحب",
  "ccmessaging.chat.phrase.enterPhrase": "الرجاء إدخال عبارة عامة",
  "ccmessaging.socialPostOperation.youTube.audienceRule": "مطلوب بموجب القانون للامتثال لقانون حماية خصوصية الأطفال على الإنترنت (COPPA) و / أو القوانين الأخرى أينما كنت. يجب عليك الإشارة إلى ما إذا كان الفيديو الخاص بك مخصصًا للأطفال.",
  "ccmessaging.socialPostOperation.youTube.category.gaming": "الألعاب",
  "ccmessaging.chat.agentconsole.spellcheck.previous": "السابق",
  "ccmessaging.chat.whatsapp.templateBodyParamDescPlaceholder": "أدخل وصف المعلمة.",
  "ccmessaging.ruleconfig.timeerror.dateValidateFailed": "يرجى تحديد تاريخ لاحق للوقت الحالي وقبل 2038-1-19",
  "ccmessaging.chat.channelconfig.searchChannelName": "البحث عن اسم القناة",
  "ccmessaging.chat.emailmessage.assignOtherEmailFailed": "لا يمكن تعيين البريد الإلكتروني من المرسل لأنه قيد المعالجة.",
  "ccmessaging.chat.warning.sharescreen": "انتبه للخصوصية الشخصية عند مشاركة سطح المكتب",
  "ccmessaging.chat.webcard.pop.confirm.prefix": "ستتم إعادة توجيه الرسالة إلى العنوان الخارجي، ومجال موقع الويب هو",
  "ccmessaging.chat.email.channel.draftOverMax": "تجاوز عدد مسودات البريد الإلكتروني الحد الأقصى.",
  "ccmessaging.chat.silentagent.silent.skill.empty": "لا يمكن أن تكون قائمة انتظار مهارة الموظف الصامت فارغة",
  "ccmessaging.chatbot.message.specialStrValidateFailed": "لا يمكن أن يحتوي المحتوى الذي تم إدخاله على characters: & < > /. التالي",
  "ccmessaging.chatbot.label.pleaseSelect": "الرجاء الاختيار",
  "ccmessaging.chat.channelconfig.silentAgentSkillList": "قائمة انتظار مهارات العامل الصامت",
  "ccmessaging.chat.channelconfig.offTimeRange": "ضمن النطاق الزمني",
  "ccmessaging.chat.whatsapp.selectTemplateOrgPlaceHolder": "اختر منظمة",
  "ccmessaging.chat.webclient.chatjs.inpuStatus": "الطرف الآخر هو الكتابة.",
  "ccmessaging.socialPostOperation.youTube.view.editVideo": "تحرير الفيديو",
  "ccmessaging.chat.template.whatsAppTemplateName": "اسم قالب WhatsApp",
  "ccmessaging.chat.whatsapp.templateHeaderText": "محتوى نص الرأس",
  "ccmessaging.socialPostOperation.youTube.categoryDetail": "أضف الفيديو حسب الطلب إلى فئة حتى يتمكن المشاهد من العثور عليها",
  "chat.skill.message.warning": "تحذير",
  "chat.session.label.created_startTime": "وقت البدء",
  "ccmessaging.chat.agentconsole.signInCallNum": "عدد مكالمات تسجيل الدخول الحالية:",
  "ccmessaging.chat.emailmessage.handleStatus": "حالة المعالجة",
  "ccmessaging.chat.email.button.advancedSearch": "البحث المتقدم",
  "ccmessaging.chat.agent.organizational.structure": "الهيكل التنظيمي",
  "ccmessaging.chat.channelconfig.fiveGBackground": "رسالة 5G CSP",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod": "ساري حتى:",
  "ccmessaging.chat.social.media.enterprise.tips.close": "أضعاف",
  "ccmessaging.chat.shortcut.shortcutRepeat": "تم استخدام مفتاح الاختصار.",
  "ccmessaging.chat.channelconfig.today": "اليوم الحالي",
  "ccmessaging.chat.emailmessage.unassigned": "سيتم تعيينه",
  "ccmessaging.chat.chatcard.multiMediaId": "موارد وسائل الإعلام",
  "ccmessaging.socialPostOperation.tips.videoType": "يدعم تنسيق MP4.",
  "ccmessaging.chat.silentagent.config.del.error": "فشل حذف تكوين الموظف الصوتي الصامت.",
  "ccmessaging.chat.agentconsole.queuing": "قوائم الانتظار",
  "ccmesaging.chat.cobrowse.isAcceptWebMax": "تم تجاوز الحد الأقصى لعدد اتصالات الاستعراض المشترك المسموح به من قبل المستأجر الحالي.",
  "ccmessaging.chat.channelconfig.clickToCallSkillList": "قائمة انتظار مهارة النقر للاتصال",
  "ccmessaging.chat.multi.card": "بطاقة 5G الدوارة",
  "ccmessaging.chat.email.check.localImageOverMax": "يتجاوز حجم الصور المحلية التي تم تحميلها في نص البريد الإلكتروني الحد الأقصى.",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentLengthExceeds": "غير مسموح بمحتوى نصي أطول من الحد!",
  "ccmessaging.chat.multimediaasccociation.deleteassociationinfo": "هل تريد بالتأكيد حذف نتيجة الإصدار لبيانات الوسائط المتعددة وقناة الجيل الخامس المرتبطة بها؟",
  "ccmessaging.chat.channelconfig.add": "جديد",
  "ccmessaging.chat.multimedia.exceedFileSize": "عدد ملفات الوسائط المتعددة التي تم تحميلها هو خطأ أو يتجاوز الحد الأقصى : {n }",
  "chat.session.label.channel": "قناة الوصول إلى الحوار",
  "ccmessaging.ruleconfig.dateEnd": "وقت الانتهاء",
  "ccmessaging.chat.template.table.button": "زر",
  "ccmessaging.chat.channelconfig.select.algorithm.configtips": "قم بتكوين خوارزمية التشفير المدعومة استنادًا إلى متطلبات النظام الأساسي المفتوح للقناة.",
  "ccmessaging.chat.channelconfig.queueReminderContent": "محتوى تذكير قائمة الانتظار",
  "chat.client.label.system": "النظام مشغول. يُرجى إعادة المحاولة لاحقًا.",
  "ccmessaging.ruleconfig.deleteSelectRule": "هل تريد بالتأكيد حذف القاعدة المحددة؟",
  "ccmessaging.chat.whatsapp.templateButtonNamePlaceholder": "أدخل اسم زر.",
  "ccmessaging.emailcatalog.moveCatalogPartialSuccess": "فشل نقل بعض دلائل البريد الإلكتروني.",
  "ccmessaging.socialPostOperation.edit.must": "مطلوب",
  "ccmessaging.chat.sumbitverification.AppSecret": "مفتاح",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.prompt": "نصيحة",
  "ccmessgaing.postChannelConfig.channelType": "تكوين القناة",
  "ccmessaging.chat.channelconfig.codeName": "رمز الوصول إلى القناة.",
  "ccmessaging.chat.multimedia.cancel": "إلغاء",
  "ccmessaging.chat.agentconsole.promptConetnt.rollBackMessage": "لقد سحبت رسالة",
  "ccmessaging.chat.facebookpageconfig.pageidexist": "معرف الصفحة الرئيسية لفيسبوك موجود بالفعل.",
  "ccmessaging.chat.chatcard.button.addtabs": "إضافة خيارات البطاقة",
  "ccmessaging.chat.agentconsole.promptConetnt.userAlreadyInSession": "فشل الإرسال لأن المشترك في مكالمة.",
  "ccmessaging.chat.agentconsole.promptConetnt.userBusy": "انتظر حتى يصبح الموظف خاملاً",
  "ccmessaging.chat.agentconsole.spellcheck.next": "التالي",
  "chat.session.label.evaluationTime": "وقت تقييم الحوار",
  "chat.common.message.cancel": "إلغاء",
  "ccmessaging.socialPostOperation.field.approverName": "الموافقة",
  "chat.skill.label.skill": "المهارة",
  "ccmessaging.email.label.selectGateway": "تحديد العبّارة",
  "chat.session.channel.facebook": "الفيسبوك",
  "chat.agent.label.waiting": "انتظر",
  "chat.skill.message.failDeleteSkill": "فشل حذف المهارة.",
  "ccmessaging.channelconfig.sms.autoReply": "الرد التلقائي",
  "ccmessaging.chat.channelconfig.worktimeerror": "الرجاء إدخال الفترة الزمنية العادية!",
  "ccmessaging.chat.agentconsole.label.defaultwelcome": "مرحبا بكم في نظام خدمة العملاء عبر الإنترنت!",
  "ccmessaging.chat.richText.openInNewWindow": "فتح في نافذة جديدة",
  "ccmesaging.chat.agentconsole.label.incallmessage": "رسالة مكالمة واردة",
  "ccmessaging.channelconfig.algorithm": "خوارزمية تشفير الربط البيني",
  "ccmessaging.chat.whatsapp.templateFooterText": "محتوى نص تذييل الصفحة",
  "ccmessaging.chat.chatcard.tabsname": "اسم خيار البطاقة",
  "ccmessaging.chat.agentconsole.accesschannel": "قناة الوصول",
  "ccmessaging.chat.async.asyncUserTrending": "اتجاه المستخدم غير المتصل",
  "ccmessaging.chat.social.media.enterprise.post.title": "اسم البريد",
  "ccmessaging.socialPostOperation.youTube.category.news": "الأخبار والسياسة",
  "ccmessaging.chat.sumbitverification.fiveG.tips": "يُرجى الانتقال إلى صفحة تكوين النظام الأساسي للرسائل 5G لتكوين عنوان موقع الويب.",
  "ccmessaging.socialPostOperation.field.publishTyle.time": "الإصدار المجدول",
  "ccmessaging.chat.channelconfig.channelID": "معرف القناة",
  "ccmessaging.chat.silentagent.DeleteSuccess": "تم حذف تكوين الموظف الصامت بنجاح.",
  "chat.weChatConfig.message.unknown": "حالة التحقق غير معروفة.",
  "ccmessaging.socialPostOperation.field.content": "المحتويات",
  "ccmessaging.chat.tinyMCE.yaHei": "مايكروسوفت YaHei",
  "ccmessaging.chat.chatmanage.startTimeRange": "يجب أن يتراوح رقم وقت البدء من 0 إلى 24",
  "ccmessaging.chat.speciallist.level": "مستوى القائمة الخاصة",
  "ccmessaging.chat.multimedia.mediaFileType": "أنواع ملفات الوسائط المتعددة",
  "ccmessaging.chat.email.agentNumber": "معرف الموظف",
  "ccmessaging.chat.email.forward.prefix": "إعادة التوجيه:",
  "ccmessaging.ruleconfig.timeerror.endTime": "* لا يمكن أن يكون وقت الانتهاء قبل الوقت الحالي.",
  "ccmessaging.chat.phrase.phraseType": "نوع العبارة",
  "ccmessaging.flowControl.tipText": "رسالة مطالبة",
  "chat.webconfig.label.basesettings": "التكوين الأساسي",
  "ccmessaging.chat.chatholiday.other.prompt": "التاريخ المحدد هو نفس التاريخ الموجود",
  "ccmessaging.chat.email.channel.receivererror": "لا يحتوي المستلم على المستخدم المحدد.",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.startGreaterThanEnd": "لا يمكن أن يكون وقت بدء التحية المخصصة أكبر من وقت الانتهاء أو مساويًا له!",
  "ccmessaging.chat.chatpersonalization.avatarerror": "حدد صورة.!",
  "ccmessaging.silentagent.button.create": "جديد",
  "ccmessaging.chat.channelconfig.sessionTransferConfig": "نقل الجلسة",
  "ccmessaging.chatbot.label.avatarModifing": "تغيير الصورة الرمزية",
  "ccmessaging.chat.facebookpageconfig.numoversize": "يتجاوز عدد صفحات Facebook الرئيسية الحد الأعلى لهذه القناة.",
  "chat.weChatConfig.label.state": "الحالة",
  "ccmessaging.chat.chatmanage.endTime": "وقت الانتهاء",
  "ccmessaging.chat.channelconfig.voiceAgentSkill": "قائمة انتظار مهارة الموظف",
  "ccmessaging.operlogs.multimedialibrary.save.audio": "احفظ تكوين البيانات في مكتبة الوسائط المتعددة وقم بتحميل بيانات الملف الصوتي.",
  "chat.client.evaluate.message": "يرجى التعليق على خدمتي. شكرا لك!",
  "ccmessaging.flowControl.count.beyondMaxLimit": "يتجاوز عدد قواعد التحكم في التدفق في مساحة المستأجر الحد الأعلى.",
  "ccmessaging.socialPostOperation.tips.submitSuccess": "تم الإرسال بنجاح",
  "ccmessaging.socialPostOperation.field.postConfigInfo": "معلومات القناة",
  "ccmessaging.chat.email.tips.amendSuccess": "نجح التعديل.",
  "ccmessaging.chat.chatmanage.sessionEndTextMorethan": "لا يمكن أن يحتوي وصف ختام مربع الحوار على أكثر من 100 حرف.",
  "ccmessaging.chat.channelconfig.endchat": "هل سيتم إنهاء الجلسة؟",
  "ccmessaging.chat.sumbitverification.VerityCode": "رمز التحقق",
  "ccmessaging.chat.channelconfig.time.timeCross": "الفترة {0} تتداخل مع الفترة {1}.",
  "ccmessaging.chat.channelconfig.refreshAccessToken": "فشل تحديث accessToken.",
  "ccmessaging.chat.channelconfig.copySuccess": "نجح النسخ.",
  "ccmessaging.chat.template.namePlaceholder": "أدخل اسم رسالة.",
  "ccmessaging.socialPostOperation.youTube.category.shortMovies": "أفلام قصيرة",
  "ccmessaging.chat.phrase.isSavePhraseType": "هل تريد بالتأكيد حفظ نوع العبارة العامة؟",
  "ccmessaging.socialPostOperation.youTube.category": "الفئة",
  "ccmessaging.chat.EMOTICONS.beyondFileSize": "عدد ملفات الوسائط المتعددة التي تم تحميلها خطأ أو تجاوز الحد الأقصى : {n} كيلوبايت!",
  "ccmessaging.chat.channelconfig.orgConfiguration": "تكوين OU",
  "ccmessaging.socialPostOperation.youTube.view.share": "مشاركة",
  "ccmessaging.chat.phrase.updateSuccess": "نجاح التحديث",
  "ccmessaging.chat.chatcard.delete": "حذف",
  "ccmessaging.chat.phrase.create": "إنشاء عبارات عامة",
  "ccmessaging.channelconfig.email.selectFreeTimeText": "قالب البريد الإلكتروني",
  "ccmessaging.chat.multimediaasccociation.releasesuccess": "تم تحرير بيانات الوسائط المتعددة بنجاح!",
  "ccmessaging.chat.channelconfig.minute": "دقيقة",
  "ccmessaging.socialPostOperation.youTube.scopePublic": "العامة",
  "ccmessaging.socialPostOperation.edit.basicInformation": "معلومات أساسية",
  "ccmessaging.chat.whatsapp.templateChannel": "قناة القالب",
  "ccmessaging.chat.richText.cancel": "إلغاء",
  "ccmessaging.multimedialibrary.cobrowsesite.card.title": "يطلب الوكيل تصفحًا تعاونيًا مع المستخدم.",
  "ccmessaging.chat.webclient.chathtml.customer": "العميل",
  "ccmessaging.chat.chatholiday.designation.norepeating": "غير متكرر",
  "ccmessaging.chat.whatsapp.templateHeaderTextPlaceholder": "أدخل محتوى نص الرأس.",
  "ccmessaging.chat.channelconfig.apiKeySecret": "API مفتاح السر",
  "ccmessaging.chat.multimedia.selectFile": "تحديد ملف",
  "chat.agentConsole.message.error.notification": "لا يمكن إنشاء إعلام الرسالة الجديدة. يرجى التحقق من تكوين المتصفح!",
  "ccmessaging.chat.agentconsole.allResults": "جميع النتائج",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfo": "حذف بيانات الوسائط المتعددة هذه",
  "ccmessaging.chat.emailmessage.operation.noMoreThanOneDay": "لا يمكن أن يتجاوز نطاق وقت الاستعلام يومًا واحدًا.",
  "ccmessaging.chat.chatholiday.designation.end": "تاريخ الانتهاء",
  "ccmessaging.chat.tinyMCE.SimHei": "SimHei",
  "ccmessaging.chat.multimedia.multiMediaTypeNameExist": "اسم فئة الوسائط المتعددة موجود بالفعل!",
  "ccmessaging.silentagent.dialog.title.edit": "تحرير وكيل الصوت الصامت",
  "ccmessaging.chat.multimedia.multiMediaTypeInputInvalid": "بيانات إدخال فئة الوسائط المتعددة غير صالحة!",
  "ccmessaging.socialPostOperation.youTube.category.car": "السيارات والمركبات",
  "chat.client.label.promotions": "موجه",
  "chat.session.channel.web": "ويب",
  "ccmessaging.chat.channelconfig.Previous": "السابق",
  "ccmessaging.chat.email.param.empty": "لا يمكن أن يكون عنوان البريد الإلكتروني أو المحتوى فارغًا.",
  "ccmessaging.chat.ifame.param": "المعلمة",
  "ccmessaging.chat.sumbitverification.line.tips": "قم بتكوين عنوان URL لـ Webhook في مدير الحساب الرسمي للخط.",
  "ccmessaging.chat.channelconfig.endOfSession": "إنهاء الجلسة بسبب عدم رد العميل",
  "cmessaging.chat.answerUser.error": "فشل إنشاء جلسة عمل مستخدم.",
  "ccmessaging.chat.agentconsole.contract.statisticSigns": "إحصائيات التوقيع",
  "chat.common.message.promotions": "معلومات",
  "chat.agent.title.changeWorkMode": "تغيير وضع العمل",
  "ccmessaging.chat.portraitchange.systemAvatar": "الصورة الرمزية للنظام",
  "ccmessaging.chat.agentconsole.contract.upload.sameContract.failTip": "المشترك لديه عقد غير منتهية الصلاحية.",
  "ccmessaging.chat.async.channelSelect": "تصفية القناة",
  "ccmessaging.chat.agentconsole.voice": "صوت",
  "ccmessaging.chat.chatcard.audio": "الصوت",
  "ccmessaging.chat.template.name": "اسم رسالة القالب",
  "ccmessaging.chat.email.tips.deleteSuccess": "تم الحذف بنجاح.",
  "ccmessaging.chat.facebookpageconfig.modifySuccess": "تم تعديل صفحة Facebook الرئيسية بنجاح.",
  "ccmessaging.socialPostOperation.button.creatPost": "بوست",
  "ccmessaging.chat.chatholiday.repeat": "تكرار القواعد",
  "ccmessaging.chat.channelconfig.nonWorkTimeDay": "غير يوم العمل",
  "ccmessaging.chat.chatconfig.channelAccessCode": "رمز الوصول إلى القناة",
  "ccmessaging.flowControl.selectRule": "حدد تكوين التحكم في التدفق.",
  "ccmessaging.chat.chatcard.strcontentTip": "تحتوي طلبات HTTP على مخاطر أمنية.",
  "cmessaging.chat.channelconfig.pushWebCertTypeOAuth": "مصادقة OAuth",
  "ccmessaging.chat.multimedia.locationManagement": "مكتبة العناوين",
  "ccmessaging.chat.multi.chatcard.width": "عرض البطاقة",
  "ccmessaging.chat.template.table.buttonPlaceholder": "الرد السريع على الزر.",
  "chat.skill.label.name": "اسم المستخدم",
  "chat.session.channel.twitter": "تويتر",
  "ccmessaging.chat.chatholiday.wednesday": "الأربعاء",
  "ccmessaging.chat.channelconfig.modifyChannel": "تعديل القناة",
  "ccmessgaing.postChannelConfig.channelConfig": "تكوين القناة",
  "ccmessgaing.postChannelConfig.add": "إضافة قناة",
  "ccmessaging.chat.template.whatsAppChannel": "قناة WhatsApp",
  "ccmessaging.chat.channelconfig.infobipPassword": "كلمة المرور",
  "chat.agent.message.changeFailed": "فشل تغيير حالة الموظف.",
  "ccmessaging.chat.phrase.saveType": "حفظ النوع",
  "chat.agentConsole.message.content.video": "[رسالة فيديو]",
  "ccmessaging.chat.emailmessage.channelAccessCode": "رمز الوصول إلى قناة البريد الإلكتروني",
  "chat.client.label.input": "يُرجى إدخال",
  "ccmessaging.chat.agentconsole.userInputStatus": "معاينة حالة إدخال المستخدم.",
  "chat.agentConsole.message.content.connectMessage": "رسالة جديدة!",
  "ccmessaging.chat.channelconfig.webhookPassword.noValidPassword": "لا توجد كلمة مرور متاحة",
  "ccmessaging.chat.channelconfig.copy": "نسخ",
  "ccmessaging.socialPostOperation.facebook.tips.emptyPost": "لا يمكن لفيسبوك نشر منشورات فارغة. يرجى تحديد عنصر واحد على الأقل (محتوى أو صورة أو فيديو).",
  "ccmessaging.socialPostOperation.tab.history": "تاريخ الموافقة",
  "ccmessaging.chat.chatholiday.holidays.explanation": "يمكنك تحديد أيام معينة كغير أيام عمل ، على سبيل المثال ، 13 سبتمبر 2019 (الجمعة ، مهرجان منتصف الخريف الصيني).",
  "ccmessaging.chat.channelconfig.time": "النطاق الزمني (دقيقة)",
  "ccmessaging.chat.card": "بطاقة",
  "ccmessaging.chat.agentconsole.spellcheck.failed": "فشل التحقق الإملائي.",
  "ccmesaging.chat.agentconsole.label.fontstyle": "نمط الخط",
  "ccmessaging.chat.whatsapp.templateHeaderType.image": "صورة",
  "ccmessgaing.postChannelConfig.placeSelectChannelType": "تحديد نوع قناة",
  "ccmessaging.chatbot.label.defaultReply": "الرد الافتراضي",
  "ccmessgaing.chatbot.message.female": "أنثى",
  "ccmessgaing.postChannelConfig.confirm": "حسناً",
  "ccmessaging.chat.uploadLocalFile": "كارغار أرشيفو المحلية",
  "ccmessaging.chat.multimedia.description": "الوصف",
  "ccmessaging.emailcatalog.existSubCatalog": "يحتوي الدليل على أدلة فرعية.",
  "ccmessaging.socialPostOperation.tips.deleteNoApprove": "الموافقة فارغة وسيتم حذفها مباشرة بعد التقديم. هل أنت متأكد من رغبتك في المتابعة؟",
  "ccmessaging.messagecontroll.timeerror.beyondMaxLimit": "لا يمكن أن يكون وقت الانتهاء بعد وقت البدء 90 يومًا!",
  "ccmessaging.socialPostOperation.field.approveStatus": "نتيجة الموافقة",
  "ccmessaging.chat.agentconsole.IpAddress": "عنوان IP",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byCard": "فشل حذف فئة الوسائط المتعددة لأن بعض الموارد مشار إليها بالفعل بواسطة البطاقات.",
  "ccmessaging.socialPostOperation.youTube.category.music": "الموسيقى",
  "chat.webconfig.label.intergratemsg": "دمج الدردشة الحية إلى موقع الويب الخاص بك",
  "ccmessaging.chat.agentconsole.waittime": "وقت الانتظار",
  "ccmessaging.chat.chatmanage.success": "النجاح",
  "ccmessaging.chat.chatcard.selectMultiMediaId": "حدد مورد الوسائط المتعددة المطابق.",
  "ccmessaging.chat.whatsapp.templateButtonContentPlaceholder": "أدخل محتوى الزر.",
  "chat.session.label.createdTime": "وقت إنشاء الحوار",
  "ccmessaging.flowControl.usage": "الاستخدام",
  "ccmesaging.chat.agentconsole.label.Realtimetransfer": "الترجمة في الوقت الحقيقي",
  "ccmessaging.chatbot.button.edit": "تحرير",
  "ccmessaging.chat.channelconfig.silentAgentSelect": "يرجى تحديد تكوين موظف كتم الصوت المراد حذفه.",
  "ccmessaging.chat.email.check.containGatewayEmail": "لا ترسل رسائل البريد الإلكتروني إلى القنوات أو تلقي عناوين البريد الإلكتروني.",
  "ccmessaging.socialPostOperation.field.applicationType.create": "بوست",
  "chat.session.label.endReason": "نهاية سبب الحوار",
  "ccmessaging.chat.channelconfig.twitterBackground": "خادم تويتر",
  "ccmessaging.ruleconfig.SMSChannel": "قناة SMS",
  "ccmessaging.channelconfig.email.freeTimeText.tips": "الرد على بريد العميل المستلم خلال غير ساعات العمل بعد الافتتاح",
  "ccmessaging.chat.multimediaasccociation.refreshfailed": "فشل تحديث البيانات!",
  "ccmessaging.chat.channelconfig.wechat.qr.label": "مسح رمز QR ضوئيًا",
  "ccmessaging.chat.channelconfig.twittertips": "يمكنك الوصول إلى المواقع التالية. انقر على إرسال لمصادقة تويتر.",
  "ccmessaging.socialPostOperation.button.saveDraft": "حفظ كمسودة",
  "ccmessaging.chat.emailmessage.handlerType": "نوع المعالج",
  "ccmessaging.chat.whatsapp.templateHeaderType.locate": "الموقع",
  "ccmessaging.chat.chatconfig.idisnull": "المعرف فارغ.",
  "ccmessaging.chat.webCard.copyUrl.success": "نجاح نسخ الرابط",
  "ccmessaging.chat.phrase.phraseTypeLimit": "يصل عدد أنواع العبارات الشائعة إلى الحد الأعلى 100.",
  "ccmessaging.chat.multimedia.richTextManagement": "نص غني",
  "ccmessaging.ruleconfig.timeerror.empty": "* لا يمكن أن يكون وقت البدء ووقت الانتهاء فارغين.",
  "ccmessaging.ruleconfig.ID": "معرف الهوية",
  "ccmessgaing.chatbot.message.male": "ذكر",
  "ccmessaging.socialPostOperation.youTube.category.science": "العلوم والتكنولوجيا",
  "ccmessaging.chat.channelconfig.identityCertificate": "شهادة الهوية",
  "ccmessaging.chat.social.media.enterprise.post.cancel": "إلغاء",
  "ccmessaging.chat.channelconfig.assistantSet": "تكوين مساعد الروبوت",
  "ccmessaging.ruleconfig.contentNotNull": "لا يمكن أن يكون المحتوى فارغًا.",
  "chat.agent.label.downagainfile": "تحميل مرة أخرى",
  "ccmessaging.socialPostOperation.youTube.scopeUnlisted": "غير مدرج علناً",
  "ccmessaging.chat.agentconsole.spellcheck.wrongly.writing": "مكتوبة بشكل خاطئ",
  "ccmessaging.chat.emailmessage.sender": "المرسل",
  "ccmessaging.operlogs.signature.submit": "تقديم عقد موقع",
  "ccmessaging.socialPostOperation.youTube.category.education": "التعليم",
  "ccmessaging.chat.agentconsole.promptConetnt.delectMessageRecord": "لقد قمت بحذف سجل",
  "ccmessaging.chat.emailmessage.agent": "الوكيل",
  "ccmessaging.chat.chatmanage.startTimeNum": "يجب أن يكون وقت البدء رقمًا.",
  "chat.session.label.search": "البحث",
  "ccmessaging.chat.email.localImage.confirm": "تأكيد",
  "chat.session.label.sessionId": "معرف الحوار",
  "ccmessaging.chat.chatcard.failTip": "فشل الإضافة وصل عدد الخيارات المضافة إلى الحد الأقصى.",
  "ccmessaging.chat.agentconsole.nocontact": "لا توجد جهات اتصال حاليًا",
  "ccmessaging.message.sendstatus.read": "اقرأ",
  "ccmessaging.chat.channelconfig.qryaddressconfigfailed": "فشل الاستعلام عن قاعدة التحقق من chatbotAddress الخاصة بقناة الجيل الخامس. لم يتم تكوين قاعدة التحقق.",
  "chat.skill.label.deleteSkills": "حذف",
  "ccmessaging.ruleconfig.all": "جميع القنوات",
  "ccmessaging.fiveG.label.appSecret": "التطبيق السري",
  "ccmesaging.chat.cobrowse.title": "دعوة التصفح المشترك",
  "ccmessaging.socialPostOperation.tips.delConfirm": "هل تريد بالتأكيد حذف البيانات المحددة؟",
  "ccmessaging.chat.channelconfig.delete": "حذف",
  "ccmessaging.chat.email.channel.emailaddressinvalid": "عنوان البريد الإلكتروني غير صالح.",
  "ccmessaging.chat.chatconfig.configurationId": "معرف التكوين",
  "ccmessaging.chat.multimedia.latitudeValidateFailed": "الجزء الصحيح لخطوط العرض هو -90 ~ 90، والجزء العشري هو 0 إلى 6 أرقام!",
  "ccmessaging.chat.channelconfig.accountKeyRepeat": "يتم تكرار accountKey في قناة ال WhatsApp",
  "ccmessaging.chat.channelconfig.channelIntegrate": "تكامل القنوات",
  "ccmessaging.chat.email.channel.paramiserror": "المعلمة غير صالحة.",
  "ccmessaging.ruleconfig.ruleDescribe": "وصف القاعدة",
  "chat.session.label.allEndType": "جميع أنواع النهاية",
  "ccmessaging.emailcatalog.modifyTopCatalog": "لا يمكن تعديل الدليل الافتراضي.",
  "ccmessaging.chat.chatpersonalization.concurrentAgentsNumber": "عدد عملاء الوسائط المتعددة الذين يخدمهم الوكلاء في نفس الوقت",
  "ccmessaging.socialPostOperation.facebook.publishAt.tips": "يجب أن يتم نشرها في غضون 20 دقيقة إلى 75 يوما بعد إنشائها.",
  "ccmessaging.chat.chatcard.multimediaid": "معرف الوسائط المتعددة",
  "ccmesaging.chat.agentconsole.label.uploadattachment": "تحميل المرفقات",
  "ccmessaging.chat.multimediaasccociation.deleteassociation": "حذف نتيجة الإصدار",
  "chat.usefulexpressions.message.saveFail": "فشل حفظ العبارة العامة.",
  "ccmessaging.chat.emailmessage.abandoned": "مهجورة",
  "ccmessaging.chat.channelconfig.infobipUserName": "اسم المستخدم",
  "chat.session.label.reset": "إعادة الضبط",
  "ccmessaging.ruleconfig.applicationChannel": "قناة",
  "chat.usefulexpressions.message.failDeleteExpression": "فشل حذف العبارة العامة.",
  "chat.agent.title.createCase": "إنشاء حالة",
  "ccmessaging.chat.channelconfig.accessTokenSecret": "الوصول إلى الرمز السري",
  "chat.client.label.login": "تسجيل الدخول",
  "ccmessaging.chat.multimedia.preview": "معاينة",
  "ccmessgaing.postChannelConfig.operation": "العملية",
  "ccmessaging.chat.channelconfig.auth.duplicateAuth": "تم تخويل الحساب الرسمي ولا يحتاج إلى تكوينه مرة أخرى.",
  "ccmessaging.chat.emailmessage.moving": "تحريك",
  "ccmessgaing.postChannelConfig.channelEdit": "تحرير تكوين القناة",
  "ccmessaging.socialPostOperation.field.status.toApproved": "سيتم الموافقة عليها",
  "ccmessaging.chat.multimedia.selectImage": "تحديد صورة",
  "ccmessaging.chat.emailmessage.robot": "روبوت",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.fifthDayTip": "صالحة لمدة أقل من 15 يومًا",
  "ccmessaging.chat.emailmessage.sendTime": "تم الإرسال",
  "ccmessaging.chat.multimedia.multiMediaManagement": "إعدادات مكتبة الوسائط المتعددة",
  "ccmessaging.chat.channelconfig.skillList": "قائمة انتظار المهارات",
  "ccmessaging.chat.chatcard.whatsappTemplateCreate": "إنشاء قالب WhatsApp",
  "ccmessaging.chat.speciallist.restrictMode": "وضع التقييد",
  "chat.skill.label.priority": "الأولوية",
  "ccmessaging.chat.channelconfig.asyncFlag": "رسائل من دون الاتصال بالإنترنت",
  "ccmessaging.chat.channelconfig.asyncTip": "إذا تم تعطيل هذه الوظيفة، فستفقد الرسائل الموجودة دون اتصال.",
  "ccmessaging.chat.chatmanage.useRobot": "الاتصال بـ Chatbot",
  "ccmessaging.chat.chatmanage.workTimeMorethan": "لا يمكن أن يحتوي وصف وقت العمل على أكثر من 100 حرف.",
  "ccmessaging.chat.multimedia.emoticonsUploadTipFormat": "يمكن أن يكون ملف الرموز الشخصية الذي تم تحميله بتنسيق JPG أو JPEG أو PNG أو GIF ولا يمكن أن يتجاوز حجم الملف {n} كيلوبايت.",
  "chat.session.channel.wechat": "WeChat",
  "ccmessaging.chat.iframeagentconsole.mailExpand": "التفاصيل",
  "ccmessaging.flowControl.updateFail": "فشل تحديث تكوين التحكم في التدفق.",
  "ccmessaging.chat.multiMedia.fileUploadTypeError": "تنسيق نوع الملف الذي تم تحميله غير صحيح.",
  "ccmessaging.chat.channelconfig.asyncTime": "الحد الزمني لتوجيه الرسائل من دون الاتصال بالإنترنت",
  "ccmessaging.chat.channelconfig.checkConfirmTokenEqualToToken": "يجب إدخال نفس القيمة",
  "ccmessaging.chat.channelconfig.advanceConfig": "المزيد",
  "ccmessaging.chat.chatcard.deleteTips": "فشل التحقق من المعلمة.",
  "ccmessgaing.postChannelConfig.refreshToken": "تحديث رمز الدخزل",
  "ccmessaging.chat.agentconsole.contract.upload.noSelectSigns.failTip": "لم يتم تحديد عنصر التوقيع للعقد.",
  "chat.weChatConfig.label.qcode": "رمز QR",
  "ccmessaging.chat.chatcard.tallHeight": "طويل القامة الارتفاع",
  "ccmessaging.chat.multimedia.play": "الصوت",
  "chat.skill.label.ok": "حسناً",
  "chat.weChatConfig.label.delete": "حذف",
  "ccmessaging.chat.agentconsole.sendEmail": "إرسال بريد إلكتروني",
  "chat.skill.message.delete": "حذف",
  "ccmessaging.ruleconfig.createdTime": "وقت الإنشاء",
  "ccmessaging.chat.chatholiday.otherdays.explanation": "يمكنك اختيار أيام معينة كأيام عمل. الحد الأقصى للنطاق الزمني هو سنة واحدة. على سبيل المثال: يمكنك اختيار يوم 13 يوليو 2019 (السبت، وقت إضافي مؤقت مطلوب) كيوم عمل.",
  "chat.webconfig.label.windowTitle.placeholder": "الرجاء إدخال العنوان المعروض في نافذة الدردشة على العميل.",
  "ccmessaging.chat.template.table.parameterContent": "المعلمة المحتوى",
  "ccmessaging.chat.agentconsole.label.skillNoAgent": "الموظف الموجود في قائمة انتظار المهارات غير متصل.",
  "ccmessaging.email.valid.signatureSearch": "يمكن أن تحتوي على ما يصل إلى 10 أحرف أو أرقام.",
  "ccmessaging.chat.channelconfig.addressValidate": "يجب أن تكون القيمة بتنسيق sip:Number{'@'}botplatform.rcs.chinamobile.com ويجب أن تحتوي على أقل من 100 حرف.",
  "chat.skill.message.createSuccess": "تم إنشاء المهارة بنجاح.",
  "ccmessaging.chat.multimedia.modifyRichText": "تحرير النص المنسق",
  "ccmessaging.chat.chatmanage.sessionEndTextnotnull": "لا يمكن أن يكون وصف نهاية الجلسة فارغًا.",
  "chat.webconfig.message.saveFail": "فشل حفظ التكوين.",
  "ccmessaging.chatbot.label.turnManualPrompt": "المطالبة بالنقل إلى الوكيل",
  "ccmessaging.chat.multimediaasccociation.checkmediainfofailed": "لم يتم إصدار بيانات الوسائط المتعددة إلى قناة 5G المرتبطة أو لم تتم الموافقة عليها!",
  "chat.skill.message.priorityLength": "تأكد من أن الأولوية أقل من أو تساوي خمسة أرقام.",
  "ccmessaging.chat.multimedia.audioManagement": "المكتبة الصوتية",
  "ccmessaging.chat.channelconfig.turnArtificial": "الكلمة الرئيسية للنقل إلى وكيل",
  "ccmessaging.chat.async.statistics.asynMsgTotal": "إجمالي عدد الرسائل دون اتصال",
  "ccmesaging.chat.agentconsole.title.release": "الافراج",
  "ccmessaging.flowControl.selectStartTime": "حدد وقت البدء.",
  "ccmessaging.chat.channelconfig.voiceAccess": "الاستجابة الصوتية التفاعلية عند الاتصال",
  "ccmessaging.chat.agentconsole.only.pdf.supported": "يتم دعم ملفات PDF فقط",
  "ccmessaging.chat.agentconsole.smsTemplate": "قالب SMS",
  "ccmessaging.address.protocol.check": "بروتوكول الشبكة المستخدم بواسطة العنوان غير آمن. قد يؤدي استخدام هذا البروتوكول إلى مخاطر أمنية.",
  "ccmessaging.chat.channelconfig.agentTimeout.emailTimeOutTips": "تخصيص الفاصل الزمني للمهلة لعدم وجود رد موظف",
  "ccmessaging.chat.chatcard.up": "تحريك لأعلى",
  "ccmessaging.chat.channelconfig.sub": "نسخ ولصق التعليمات البرمجية التالية في تسمية موقع الويب الخاص بك، وحفظ ونشر، يمكنك إكمال الوصول السريع إلى هواوي ServiceCloud. بعد الإصدار، ستظهر بوابة Huawei Service Cloud في الزاوية اليمنى السفلى من موقع الويب الخاص بك. انقر للانتقال إلى طاولة عمل خدمة العملاء. محادثة في الوقت الحقيقي.",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgName": "اسم رسالة قالب WhatsApp",
  "ccmessaging.chatbot.label.timeoutReply": "مهلة الرد",
  "ccmessaging.chat.agentconsole.label.filesTwitterNotsupport": "تويتر لا يدعم الصوت، تحديد الموقع، أو الفيديو أطول من 30 ثانية!",
  "chat.skill.label.skillId": "لا ..",
  "ccmessaging.chat.phrase.edit": "تحرير",
  "ccmessaging.socialPostOperation.youTube.view.commentNum": "14 تعليقات",
  "chat.session.endType.agentEnd": "إنهاء حسب الوكيل",
  "ccmessaging.operlogs.multimedialibrary.save.emoticons": "احفظ تكوين البيانات في مكتبة الوسائط المتعددة وقم بتحميل بيانات الملف من نوع الرموز التعبيرية.",
  "ccmessaging.chat.channelconfig.edit": "العملية",
  "ccmessaging.chat.phrase.save": "حفظ",
  "ccmessaging.chat.specialChannel.create": "إنشاء قاعدة قائمة خاصة",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaInfo": "هل تريد بالتأكيد حذف بيانات الوسائط المتعددة هذه؟",
  "ccmessaging.chat.chatemail.forwardedTime": "إعادة توجيه في",
  "ccmessaging.chat.emailmessage.assignEmailPartialSuccess": "فشل تعيين بعض رسائل البريد الإلكتروني.",
  "ccmessaging.socialPostOperation.youTube.disclosureScopeDetail": "حدد من يمكنه مشاهدة الفيديو الخاص بك",
  "ccmessaging.chat.silentAgent.workMode.transfer": "نقل",
  "ccmessaging.chat.channelconfig.assertionSigningKey": "مفتاح توقيع التأكيد",
  "ccmessaging.chat.contact.callin": "مكالمة واردة",
  "ccmessaging.chat.channelconfig.routeType": "وضع التوجيه",
  "ccmessaging.chatofflineCall.channelName": "اسم القناة",
  "ccmessaging.chat.channelconfig.weChatMessageEncryptionSwitch": "محول تشفير رسائل WeChat",
  "ccmessaging.chat.channelconfig.noLimit": "غير محدد",
  "ccmessaging.silentagent.delete.select.morethan.100": "يمكن حذف 100 سجل بيانات كحد أقصى في المرة الواحدة.",
  "ccmessaging.chat.workbench.body.iolp": "إرشادات الخدمة",
  "ccmessaging.chat.speciallist.restrictHours": "المدة المقيدة",
  "ccmessaging.chat.channelconfig.time.timeNoRange": "في الفترة {n}، يجب أن يكون وقت البدء قبل وقت الانتهاء.",
  "ccmessaging.chat.chatholiday.savechatholidayfailed": "فشل حفظ تكوين العطلة",
  "ccmessaging.chat.chatholiday.dateConfiguration": "تكوين التاريخ",
  "ccmessaging.chatbot.button.cancel": "إلغاء",
  "chat.session.label.enterQueueTime": "وقت دخول قائمة الانتظار",
  "ccmessaging.chat.email.channel.paramisempty": "لا يمكن أن تكون المعلمة فارغة.",
  "ccmessaging.ruleconfig.add": "إضافة",
  "ccmessaging.chat.email.button.bulkMove": "نقل السائبة",
  "ccmessaging.chat.async.asyncMsgNum": "عدد الرسائل دون اتصال",
  "chat.weChatConfig.message.emptyDeleteArray": "حدد عنصر التكوين المراد حذفه.",
  "ccmessaging.chat.facebookpageconfig.saveSuccess": "تمت إضافة صفحة الفيسبوك الرئيسية بنجاح.",
  "ccmessaging.chat.social.media.enterprise.manage": "معالجة رسائل حساب المؤسسة",
  "ccmessaging.chat.chatcard.messageTemplateModify": "تعديل قالب",
  "chat.skill.label.maxQueueTime": "الحد الأقصى لوقت قائمة الانتظار (ث)",
  "ccmessaging.chat.whatsapp.template.updateTime": "تم تحديثه",
  "ccmessaging.chat.emailmessage.channel": "الاستجابة التلقائية للقناة",
  "ccmessaging.chat.channelconfig.timeoutReply": "مهلة الرد",
  "ccmessaging.chat.agentconsole.contract.pdfUploadMaxSizeTip": "لا يمكن أن يتجاوز حجم العقد {n}M.",
  "ccmessaging.socialPostOperation.youTube.knowledgePermission": "المشاع الإبداعي - الإسناد",
  "ccmessaging.chat.multimediaasccociation.deleteSuccess": "تم حذف نتيجة الإصدار بنجاح!",
  "ccmessaging.ruleconfig.validityPeriod": "فترة الصلاحية",
  "ccmessaging.chat.agentconsole.noHiscontact": "لا توجد جلسة تاريخية",
  "ccmessaging.chat.chatcard.create": "حفظ",
  "ccmessaging.chat.agentconsole.customerinformation": "معلومات العميل",
  "ccmessaging.chat.multimedia.nameSuperLong": "يتجاوز طول الاسم الحد الأقصى.",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftFailed": "فشل إرسال البريد الإلكتروني لأن المشترك يجري مكالمة. فشل حفظ البريد الإلكتروني كمسودة.",
  "ccmessaging.chat.call.end.state": "تكوين حالة الموظف بعد انتهاء المكالمة",
  "chat.confirm.yes": "نعم",
  "ccmessaging.chat.chatholiday.isDelete.otherdays": "هل تريد بالتأكيد حذف تاريخ الاستثناء هذا؟",
  "ccmessaging.socialPostOperation.field.publishChannel": "تحرير قناة",
  "chat.session.title.basic": "معلومات الحوار الأساسية",
  "ccmessaging.message.location.latitude": "خط العرض:",
  "ccmessaging.chat.whatsapp.templateContent": "محتوى القالب",
  "ccmessaging.chat.chatemail.cancelhandled": "تمت معالجة الإلغاء",
  "chat.client.label.yes": "نعم",
  "ccmessaging.chatbot.label.avatarImportFailed": "فشل تحميل الصورة!",
  "chat.client.button.submit": "إرسال",
  "ccmessaging.chat.multimedia.getRichTextInfoError": "فشل الحصول على بيانات النص المنسق.",
  "ccmessaging.chat.chatholiday.saturday": "السبت",
  "ccmessaging.chat.channelconfig.queueReminderInterval": "الفاصل الزمني للتذكير بقائمة الانتظار",
  "ccmessaging.chat.channelconfig.keyEscrow": "مفتاح",
  "ccmessaging.chat.email.tips.isDeleteDirectory": "هل تريد حذف الدليل الحالي؟",
  "chat.skill.label.cancel": "إلغاء",
  "ccmessaging.chat.chatpersonalization.operating": "العملية",
  "chat.usefulexpressions.message.willDeleteExpression": "هل تريد بالتأكيد حذف العبارة العامة؟",
  "ccmessaging.socialPostOperation.tips.cannotDel": "لا يمكن حذف المشاركات التي سيتم الموافقة عليها.",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.templatemsg": "لا يمكن حذف المورد لأنه تمت الإشارة إليه مسبقاً بواسطة رسالة قالب WhatsApp. معرف رسالة القالب:",
  "ccmessaging.chat.web.agent.wrogmess": "صياغة غير لائقة! يرجى توخي الحذر في استخدام الكلمات المناسبة.",
  "ccmessaging.chatbot.label.name": "اسم الشهرة",
  "ccmessaging.chat.agentconsole.fivegNotSupport": "قنوات الجيل الخامس لا تدعم رسائل الوسائط المتعددة",
  "ccmessaging.char.channelconfig.duplicateCodeName": "اسم القناة موجود بالفعل.",
  "ccmessaging.socialPostOperation.youTube.view.dataResolve": "تحليل البيانات",
  "ccmessaging.operlogs.multimedialibrary.modify.richtext": "تعديل النص المنسق",
  "ccmessaging.chat.template.table.parameterPlaceholder": "أدخل معلمة.",
  "ccmessaging.chat.email.reply": "الرد",
  "ccmessaging.chat.chatmanage.autoEndSession": "إذا لم يرد الضيف خلال الوقت المحدد، فسيتم إنهاء مربع الحوار تلقائيًا.",
  "chat.timerange.label.ok": "حسناً",
  "ccmessaging.chat.whatsapp.templateAddButton": "إنشاء",
  "ccmessaging.chat.chatholiday.weekend.explanation": "يمكنك تعيين أيام معينة في الأسبوع كغير أيام عمل، على سبيل المثال، السبت والأحد.",
  "ccmessaging.chat.chatholiday.nonWorkbench": "التكوين في غير أيام العمل",
  "ccmessaging.chat.chatcard.NoneTitle": "بدون عنوان",
  "ccmessaging.chat.silentAgent.workMode.insert": "إدراج",
  "ccmessaging.chat.phrase.add": "إضافة",
  "ccmessaging.service.time.label": "تكوين تقويم العمل",
  "ccmessaging.chat.channelconfig.workTimeDay": "يوم العمل",
  "ccmessaging.chat.speciallist.msgchoosechannel": "يرجى تحديد نوع القناة أولاً.",
  "chat.session.label.endType": "نوع نهاية الحوار",
  "chat.skill.message.willDeleteAllSkills": "هل تريد بالتأكيد حذف جميع المهارات؟",
  "chat.thirdportal.chatWindow.button.startChat": "بدء الدردشة",
  "ccmessaging.chat.tinyMCE.FangSong": "FangSong",
  "ccmessaging.chat.chatconfig.deletechannelAccessCode": "حذف تكوين القناة",
  "ccmessgaing.postChannelConfig.repeatedly": "القناة موجودة بالفعل.",
  "ccmessaging.chat.whatsapp.templateLanguage": "اللغة",
  "ccmessaging.chat.chatcard.mediaFileType": "نوع ملف الوسائط",
  "ccmessaging.chat.channelconfig.appSecret": "التطبيق السري",
  "ccmessaging.chat.email.tips.addFailOtherErrors": "فشلت الإضافة: أخطاء أخرى.",
  "ccmessaging.chat.sumbitverification.Appld": "معرف التطبيق",
  "ccmessaging.chat.agentconsole.photo": "الصورة",
  "ccmessaging.chat.chatcard.selectMultiMediaTips": "الرجاء تحديد موارد الوسائط المتعددة.",
  "ccmessaging.chat.chatcard.closeDialog": "هل تريد بالتأكيد إغلاق مربع الحوار هذا؟",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.fail": "فشل workMode الخاص بالتغيير الصامت العامل!",
  "ccmessaging.chat.phrase.currentCount": "عدد الكلمات الحالية",
  "ccmessaging.chat.channelconfig.email.ccLimit": "الحد الأقصى من المستلمين الذين تم إرسال نسخ إليهم",
  "ccmessaging.chat.fivegCard": "بطاقة 5G",
  "ccmessaging.chat.speciallist.choosechannel": "تحديد قناة",
  "ccmessaging.chat.channelconfig.assistantAccessCode": "رمز وصول المساعد",
  "ccmessaging.message.sendstatus.unread": "غير مقروء",
  "ccmessaging.chat.phrase.saveTypeSuccess": "حفظ فئة اللغة العامة بنجاح",
  "ccmessaging.chat.template.table.no": "لا ..",
  "chat.weChatConfig.label.secret": "مفتاح",
  "ccmesaging.chat.agentconsole.commom.label": "علامة",
  "ccmessaging.chat.speciallist.remark": "الوصف",
  "ccmessaging.chat.channelconfig.cloudApi.accessToken.tips": "فترة صلاحية الرمز المميز الذي تم إنشاؤه بواسطة Meta للمطورين للوصول إلى Cloud API. أدخل فترة صلاحية أقل بيومين من فترة الصلاحية الفعلية. على سبيل المثال\n، إذا كانت فترة الصلاحية الفعلية 60 يومًا\n، فيُنصح بتعيين فترة الصلاحية إلى 58 يومًا. في حالة ترك هذه المعلمة فارغة\n، لا تنتهي صلاحية الرمز المميز\n، وهو عرضة للتسرب. توخي الحذر عند ترك هذه المعلمة فارغة.",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFail": "فشل إجراء عمليات على الرسائل التي لم يتم إرسالها بنجاح",
  "chat.client.label.accountNumber": "الحساب",
  "ccmessaging.chat.whatsapp.languageZU": "الزولو",
  "ccmessaging.chat.channelconfig.emailRepeate": "تم استخدام بوابة استلام البريد الإلكتروني. انتقل إلى صفحة تكوين توجيه الرسائل لعرض بوابة استلام البريد الإلكتروني.",
  "ccmessaging.chat.social.media.enterprise.post.batch.reply": "دفعة الرد",
  "ccmessaging.chat.channelconfig.turnManualPrompt": "المطالبة بالنقل إلى الوكيل",
  "chat.agent.label.historicalBill": "مشروع قانون تاريخي",
  "ccmessaging.chat.chatholiday.designation.oneday": "يوم واحد",
  "ccmessaging.chat.channelconfig.triggerText": "موجه الرسائل دون اتصال",
  "ccmessaging.message.selectEmail.count": "يمكن اختيار 30 رسالة بريد إلكتروني كحد أقصى في كل مرة.",
  "ccmessaging.chat.email.channel.confdeletedraft": "هل تريد بالتأكيد حذف مسودة البريد الإلكتروني؟",
  "ccmessaging.chat.agentconsole.promptConetnt.userOffline": "المستخدم الحالي غير متصل!",
  "ccmessaging.chat.multimedia.videoManagement": "مكتبة الفيديو",
  "ccmessaging.chat.multimedia.documentManagement": "مكتبة المستندات",
  "ccmessgaing.postChannelConfig.cancel": "إلغاء",
  "ccmessgaing.postChannelConfig.apiKey": "apiKey",
  "ccmessaging.socialPostOperation.tips.titleError": "لا يمكن أن تحتوي على < و >",
  "ccmessaging.chat.agentconsole.myconversation": "محادثتي",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.nolist": "يرجى تعيين تحية مخصصة!",
  "ccmesaging.chat.cobrowse.agentMax": "يتعاون الوكيل حاليًا على صفحة الويب، يرجى المحاولة مرة أخرى",
  "ccmessaging.chat.channelconfig.email.personal": "الرد بالبريد الإلكتروني الشخصي",
  "ccmessaging.chat.chatemail.maxNumberImages": "وصل عدد الصور المحلية التي تم تحميلها إلى 20 صورة كحد أقصى.",
  "ccmessaging.chat.multi.chatcard.alignment": "وضع محاذاة الصور",
  "chat.agentConsole.message.content.locate": "[رسالة الموقع]",
  "ccmessaging.chat.channelconfig.delete.exist.session": "فشل حذف قناة [{n}]. حاول مرة أخرى بعد انتهاء الجلسة.",
  "ccmessaging.chat.channelconfig.robotAccessCode": "رمز الوصول إلى Chatbot",
  "ccmessaging.chat.facebookpageconfig.modify": "تحرير الصفحة الرئيسية للفيسبوك",
  "ccmessaging.chatbot.label.update": "تحديث",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.success": "نجاح التغيير الخاص بـ SactAgent WorkMode!",
  "ccmessaging.emailcatalog.parentName": "اسم الدليل الأصلي",
  "ccmessaging.chat.channelconfig.selectRobotAssistant": "تحديد مساعد الروبوت",
  "ccmessaging.chat.webcard.pop.error.suffix": "غير موجود في القائمة البيضاء للعنوان. اتصل بموظفي العمليات لإضافته إلى القائمة البيضاء.",
  "chat.client.message.hiMyWorkno": "مرحباً",
  "ccmessaging.chat.email.channel.modifydraft": "تأكيد",
  "chat.session.label.endTime": "وقت انتهاء الحوار",
  "ccmessaging.chat.chatcard.success": "النجاح",
  "ccmessaging.ruleconfig.maxValidityPeriod": "تم تعيين فترة الصلاحية إلى الحد الأقصى.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byWhatsAppTemplateMsg": "فشل حذف فئة الوسائط المتعددة لأن بعض الموارد مشار إليها مسبقاً بواسطة رسالة قالب WhatsApp.",
  "ccmessaging.chat.multimedia.upload": "تحميل",
  "ccmessaging.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": "يجب أن تحتوي كلمة المرور على ثلاثة أنواع على الأقل مما يلي: أحرف كبيرة، وأحرف صغيرة، وأرقام، والأحرف الخاصة التالية: {n}",
  "ccmessaging.chat.social.media.enterprise.default.username": "مستخدم مجهول",
  "chat.session.title.detail": "تفاصيل الحوار",
  "ccmessaging.chat.speciallist.channels": "القنوات المقيدة",
  "chat.agent.label.mybill": "فواتيري",
  "ccmessaging.ruleconfig.invalidation": "الإبطال",
  "ccmessaging.chat.phrase.delPhraseError": "فشل حذف العبارة العامة",
  "ccmessaging.chat.agentconsole.robotAssistant": "مساعد الروبوت",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns": "إحصائيات",
  "chat.skill.label.submit": "إرسال",
  "chat.weChatConfig.label.bindSkill": "ربط قائمة انتظار المهارة",
  "ccmessgaing.postChannelConfig.deleteFailed": "فشل حذف معلومات التكوين الخاصة بقناة الوسائط المتعددة الاجتماعية.",
  "ccmessaging.chat.chatcard.descriptionPlaceholder": "أدخل وصف البطاقة.",
  "chat.weChatConfig.label.verify": "التحقق",
  "ccmessaging.operlogs.multimedialibrary.save.video": "احفظ تكوين البيانات في مكتبة الوسائط المتعددة وقم بتحميل بيانات ملف الفيديو.",
  "ccmessaging.chat.silentagent.config.willdel": "هل تريد بالتأكيد حذف تكوين الموظف الصوتي الصامت المحدد؟",
  "ccmessaging.chat.multimedia.richTextTypeEmpty": "فئة النص المنسق غير موجودة.",
  "ccmessaging.socialPostOperation.facebook.comment": "تعليق",
  "ccmessaging.chat.phrase.saveError": "فشل الحفظ",
  "ccmessaging.socialPostOperation.youTube.category.Comedy": "كوميديا",
  "ccmessaging.chat.channelconfig.afterhalf": "، يمكن النقر فوق لإزالة المتصفح لاعتراض)",
  "ccmessaging.chat.agentconsole.enter.total.number": "الرجاء إدخال العدد الإجمالي للتوقيعات",
  "ccmessaging.chat.channelconfig.wechat.encryption.tip": "قد يؤدي تعطيل تشفير الرسائل إلى مخاطر أمنية. يجب أن يكون الإعداد متسقًا مع الإعداد الموجود على منصة حسابات WeChat الرسمية.",
  "chat.client.message.evaluationSuccess": "استطلاع الرضا ناجح.",
  "ccmessaging.emailcatalog.deleteTopCatalog": "لا يمكن حذف الدليل الافتراضي.",
  "ccmessaging.chat.multimedia.edit": "تحرير",
  "chat.webconfig.message.configdirective": "انسخ الشفرة التالية والصقها قبل تسمية موقع الويب الخاص بك </body>. احفظ الرمز وأطلقه لإكمال الوصول السريع إلى Huawei Service Cloud. بعد الإصدار، يتم عرض إدخال Huawei Service Cloud في الزاوية اليمنى السفلى من موقع الويب الخاص بك. يمكنك النقر فوقه للتحدث مع طاولة عمل خدمة العملاء في الوقت الفعلي.",
  "ccmessaging.flowControl.title.create": "إنشاء التحكم في التدفق",
  "ccmessaging.chat.tinyMCE.simsun": "سيمسون",
  "chat.session.label.remark": "تعليق",
  "ccmessaging.chat.chatmanage.autoEndSessionNum": "لا يرد العميل على الفاصل الزمني للإعلام يجب أن يكون رقمًا.",
  "ccmessaging.emailcatalog.catalogname": "اسم الكتالوج",
  "ccmessaging.chat.multimedia.fileSize": "حجم الملف",
  "ccmessaging.chat.chatcard.input": "أدخل اسم قالب.",
  "ccmessaging.chat.channelconfig.auth.relation.timeout": "قد يكون الترخيص قد انتهت صلاحيته. امسح رمز QR الخاص بـ WeChat ضوئيًا لإعادة التفويض.",
  "ccmessaging.channelconfig.email.worktime.autoReply": "الرد التلقائي خلال ساعات العمل",
  "ccmessaging.chat.whatsapp.languageVI": "الفيتنامية",
  "ccmessaging.flowControl.addSuccess": "تمت إضافة تكوين التحكم في التدفق بنجاح.",
  "ccmessaging.chat.email.channel.ccandbcc.containchannelemail": "لا يمكن أن يكون المستلمون Cc وBcc عناوين البريد الإلكتروني العامة لشركاء القنوات.",
  "ccmessaging.chat.chatcard.edit": "تحرير",
  "ccmessaging.chat.chatholiday.designation.start": "تاريخ البدء",
  "ccmessaging.ruleconfig.emailaddress.error": "يحظر إرسال العناوين التالية:",
  "ccmessaging.chat.whatsapp.languageUZ": "الأوزبكية",
  "ccmessaging.chat.channelconfig.time.timePeriod": "يرجى ملء أو حذف الفترة {n}",
  "chat.usefulexpressions.message.createSuccess": "تم إنشاء العبارة العامة بنجاح.",
  "ccmessaging.chatbot.label.gender": "الجنس",
  "ccmessaging.chat.agentconsole.spellcheck.replaceall": "استبدال الكل",
  "ccmessaging.chat.emailmessage.title": "عنوان البريد الإلكتروني",
  "ccmessaging.chat.whatsapp.languageUS": "الإنجليزية (الولايات المتحدة)",
  "ccmessaging.chat.whatsapp.languageUR": "الأوردو",
  "ccmessaging.chat.channelconfig.queueReminder": "تذكير قائمة الانتظار",
  "ccmessaging.chat.facebookpageconfig.tokenSecret": "رمزية سر",
  "ccmessaging.chat.chatcard.viewTips": "تم حذف قالب البطاقة ولا يمكن معاينته.",
  "ccmessaging.socialPostOperation.field.video": "فيديو",
  "ccmessaging.chat.chatcard.id": "أدخل معرف قالب.",
  "ccmessaging.chat.channelconfig.createChannel": "إنشاء قناة",
  "ccmessaging.chat.multimedia.delete": "حذف",
  "ccmessaging.chat.whatsapp.languageUK": "الأوكرانية",
  "ccmessaging.chat.phrase.enter_keyword": "أدخل كلمة بحث أساسية.",
  "ccmessaging.chat.channelconfig.NonworkingTimeReminder": "إشعار وقت غير العمل",
  "ccmessaging.service.time.select.weekday": "تحديد تواريخ العمل",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftSuccess": "فشل إرسال البريد الإلكتروني لأن المشترك يجري مكالمة. يتم حفظ البريد الإلكتروني كمسودة.",
  "chat.common.message.delete": "حذف",
  "ccmessaging.chat.channelconfig.startTime": "وقت البدء",
  "ccmessaging.chat.chatmanage.edit": "تحرير",
  "ccmessaging.chat.chatholiday.tuesday": "الثلاثاء",
  "chat.agent.title.userInformation": "معلومات العميل",
  "ccmessaging.chat.channelconfig.timeZone": "المنطقة الزمنية",
  "ccmessaging.chat.agentconsole.current": "الحالي",
  "ccmessaging.chat.chatmanage.save": "حفظ",
  "ccmessaging.chat.agentconsole.message.notworktime": "الوكيل ليس في وقت العمل.",
  "ccmessgaing.chat.web.channel.ivr": "IVR",
  "ccmessaging.chat.whatsapp.languageTR": "التركية",
  "ccmessaging.chat.phrase.phraseTypeEmpty": "نوع العبارة العامة المراد حذفها غير موجود. الرجاء تحديد نوع عبارة عامة.",
  "ccmessaging.chat.speciallist.emailchannels": "البريد الإلكتروني",
  "ccmessaging.chat.agentconsole.spellcheck.language": "اللغة",
  "ccmessaging.chat.chatcard.video": "فيديو",
  "ccmessaging.socialPostOperation.tips.imageType": "يدعم تنسيقات PNG و JPG و GIF و BMP و TIFF.",
  "ccmessaging.chat.template.butttonName": "تحديد",
  "ccmessaging.chat.chatcard.details": "تفاصيل البطاقة",
  "ccmessaging.flowControl.channelType": "نوع القناة",
  "ccmessaging.chat.chatcard.multimedianame": "اسم ملف الوسائط المتعددة",
  "ccmessaging.chat.chatholiday.tip.choosedate": "يرجى تحديد تاريخ",
  "ccmessaging.chat.whatsapp.languageTH": "التايلاندية",
  "ccmessaging.chat.phrase.isDeletePhrase": "هل تريد بالتأكيد حذف هذه العبارة؟",
  "ccmessaging.chat.agentconsole.mailDraft": "مسودة البريد الإلكتروني",
  "ccmessaging.chat.whatsapp.languageTE": "التيلجو",
  "ccmessaging.chat.whatsapp.languageTA": "التاميل",
  "cmessaging.chat.channelconfig.pushWebCertTypeSignature": "مصادقة التوقيع",
  "ccmessaging.chat.agentconsole.image": "صورة",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeSuccess": "تم حذف فئة الوسائط المتعددة بنجاح!",
  "ccmessaging.chat.speciallist.channelsType": "نوع القناة المقيدة",
  "ccmessaging.chat.whatsapp.languageSW": "السواحيلية",
  "ccmessaging.chat.email.channel.attendeeError": "عدد مستلمي نسخة يتجاوز الحد.",
  "ccmessaging.flowControl.periodType.month": "شهرياً",
  "ccmessaging.chat.whatsapp.languageSV": "السويدية",
  "ccmessaging.operlogs.preview.contract": "معاينة العقد، المستخدم:",
  "ccmessaging.chat.whatsapp.languageSR": "الصربية",
  "ccmessaging.chat.whatsapp.languageSQ": "الألبانية",
  "ccmessaging.chat.multimedia.modifyMultiMediaType": "تم تعديل فئة الوسائط المتعددة بنجاح!",
  "ccmessaging.chat.email.channel.sendGateway": "بوابة إرسال البريد الإلكتروني",
  "ccmessaging.chat.workbench.transfer.skillqueue": "انتظر في قائمة الانتظار",
  "ccmessaging.chat.whatsapp.languageSL": "السلوفينية",
  "ccmessaging.chat.whatsapp.languageSK": "السلوفاكية",
  "chat.client.greeting.evening": "مساء الخير!",
  "ccmessaging.chat.webcard.pop.confirm.outside": "ما إذا كنت تريد الانتقال إلى روابط الطرف الثالث؟",
  "ccmessaging.chat.phrase.delPhraseTypeError": "فشل حذف نوع العبارة العامة",
  "ccmessaging.chat.chatholiday.operate": "تعمل",
  "ccmessaging.chat.async.statistics.asynChannelTotal": "إجمالي عدد القنوات غير المتصلة",
  "ccmessaging.chat.channelconfig.alphavalidateFailed": "أحرف غير صالحة. يمكن أن تحتوي القيمة على أحرف وأرقام وتسطير سفلي فقط (_). يجب أن يكون الحرف الأول حرفاً أو تسطير سفلي.",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.moving.tip": "لا يمكن نقل الرسائل المسقطة.",
  "ccmessaging.chat.multimedia.saveMultiMediaType": "تم حفظ فئة الوسائط المتعددة بنجاح!",
  "ccmessaging.chat.chatcard.willdeletechatcard": "هل تريد بالتأكيد حذف هذه البطاقة؟",
  "ccmessaging.chat.sumbitverification.tips.qr": "في وضع التخويل هذا، لا يلزم المزيد من التكوين. انتقل إلى الخطوة التالية.",
  "ccmessaging.chat.sumbitverification.facebook.tips": "انتقل إلى صفحة مطور Facebook Messenger لتكوين موقع الويب ورمز التحقق.",
  "ccmessaging.chat.channelconfig.saveAndToNext": "حفظ وانتقل إلى الخطوة التالية",
  "ccmessaging.chat.whatsapp.languageRU": "الروسية",
  "ccmessaging.chat.phrase.delPhraseTypeSuccess": "تم حذف نوع العبارة العامة بنجاح",
  "chat.client.label.rateService": "خدمة Score",
  "chat.weChatConfig.label.create": "جديد",
  "chat.skill.message.saveSuccess": "تم حفظ المهارة بنجاح.",
  "ccmessaging.chat.whatsapp.languageRO": "الرومانية",
  "ccmessaging.chat.emailmessage.assigned": "تم تعيينه",
  "ccmessaging.chat.channelconfig.turnAI": "الاتصال بـ Chatbot",
  "ccmessaging.chat.chatpersonalization.savefailed": "فشل الحفظ.",
  "cmessaging.chat.channelconfig.isPushWebMessage": "رسالة دفع",
  "ccmessaging.chat.agentconsole.spellcheck.replace": "استبدال",
  "ccmessaging.chat.agentconsole.noUserArrive": "لا يوجد مستخدم متاح الآن!",
  "ccmessaging.chat.chatconfig.modify": "تعديل",
  "chat.webconfig.label.functionalsettings": "إعدادات الوظيفة",
  "ccmessaging.silentagent.voiceaccess.empty": "لا يمكن أن يكون IVR الصوتي المسمى بتكوين المسار فارغًا",
  "chat.webconfig.button.save": "حفظ",
  "ccmessaging.chat.emailmessage.operation.noMoreThanSevenDay": "لا يمكن أن يتجاوز نطاق وقت الاستعلام سبعة أيام.",
  "chat.skill.label.createSkill": "إنشاء",
  "ccmessaging.chat.channelconfig.facebookBackground": "الفيسبوك",
  "ccmessaging.chat.webclient.chatapi.failTransToAgent": "فشل التحويل إلى الموظف، يُرجى المحاولة مرة أخرى أو الاتصال بالمسؤول",
  "ccmessaging.chatbot.label.selectRobotAvatar": "تحديد الصورة الرمزية الروبوت",
  "ccmessgaing.postChannelConfig.createFailed": "فشل إنشاء معلومات التكوين الخاصة بقناة الوسائط المتعددة الاجتماعية.",
  "ccmessaging.chat.offlineCall.select.noChannel": "القناة غير موجودة.",
  "ccmessaging.chat.multimedia.multiMediaInfoInputEmpty": "بيانات إدخال الوسائط المتعددة فارغة!",
  "ccmessaging.socialPostOperation.ceratePageTitle": "تشغيل الحساب/النشر",
  "ccmessaging.chat.channelconfig.publicConfiguration": "التكوين الشائع",
  "ccmessaging.chat.chatholiday.designation.repeating": "تتكرر كل عام",
  "chat.usefulexpressions.message.willDeleteExpressions": "هل تريد بالتأكيد حذف العبارة العامة المحددة؟",
  "ccmessaging.chat.multimedia.cobrowseSite": "عنوان تعاون صفحة الويب",
  "ccmesaging.chat.cobrowse.webSiteButton": "التصفح المشترك",
  "ccmessaging.operlogs.upload.contract": "عقد التحميل",
  "ccmessaging.socialPostOperation.youTube.category.nonprofits": "المنظمات غير الربحية والنشاط",
  "ccmessaging.chat.multimedia.deleteLimit": "تمت الإشارة إلى المورد بواسطة قالب بطاقة ولا يمكن حذفه. معرف قالب البطاقة:{0}",
  "ccmessaging.chat.chatcard.replay": "إعادة التشغيل",
  "ccmessaging.socialPostOperation.field.operation": "العملية",
  "ccmessaging.chat.webclient.chatapi.evaluation": "يرجى تقييم هذه الخدمة ، شكرا لك!",
  "ccmessaging.chat.email.tips.addMaximumLimit": "بحد أقصى 20 دليل فرعي على نفس المستوى.",
  "ccmessaging.chat.multimedia.richTextContentEmpty": "محتوى النص المنسق فارغ.",
  "chat.agent.label.billDetail": "تفاصيل الفاتورة",
  "ccmessaging.chat.agentconsole.thumbnail": "الصورة المصغرة",
  "ccmessaging.socialPostOperation.youTube.normalPermission": "رخصة YouTube القياسية",
  "ccmessaging.chat.whatsapp.languagePL": "البولندية",
  "ccmessgaing.postChannelConfig.appKey": "appKey",
  "chat.client.label.bill": "فواتيري",
  "chat.session.label.skillQueueId": "معرف قائمة انتظار المهارات",
  "ccmessaging.chat.channelconfig.time.timePeriod0": "يرجى ملء الفترة {n}",
  "ccmessaging.chat.phrase.success": "النجاح",
  "ccmessaging.chat.channelconfig.email.range": "أدخل عددًا صحيحًا موجبًا يتراوح من 1 إلى 100",
  "ccmessaging.chatbot.message.modifySuccess": "تم التعديل بنجاح.",
  "ccmessaging.chat.emailmessage.assignStatus": "حالة التعيين",
  "ccmessaging.chat.whatsapp.languagePA": "البنجابية",
  "ccmessaging.chat.channelconfig.email.number": "تكوين كمية عنوان البريد الإلكتروني",
  "chat.client.label.like": "قد يعجبك",
  "ccmessaging.socialPostOperation.tips.uploadVideo": "يُرجى تحميل ملف فيديو",
  "ccmessaging.chat.channelconfig.clicktocall.empty": "لا يمكن أن تكون قائمة انتظار المهارة أو رمز وصول IVR فارغة!",
  "ccmessaging.chatbot.label.intelligentRobotAccessCode": "رمز وصول الروبوت",
  "ccmessaging.chat.async.requestError": "فشل الطلب.",
  "ccmessaging.chat.agentconsole.spellcheck.title": "اختبار الإملائي",
  "ccmessaging.chat.emailmessage.skillQueue": "قائمة انتظار المهارات",
  "ccmessaging.chat.speciallist.unuse": "غير متوفر",
  "ccmessaging.message.timeerror.laterThanCurTime": "لا يمكن أن يكون وقت البدء بعد الوقت الحالي.",
  "ccmessaging.socialPostOperation.youTube.embedding": "السماح بالتضمين",
  "ccmessaging.chat.chatmanage.agentSessionEndText": "المطالبة بإعادة تعيين الوكيل",
  "ccmessaging.chat.whatsapp.template.dailyLimit": "أوقات توفر القالب في اليوم",
  "ccmessaging.flowControl.createTime": "تم الإنشاء في",
  "ccmessaging.webCollaboration.ruleName": "اسم القاعدة",
  "ccmessaging.webCollaboration.pageMatchType": "نوع مطابقة الصفحة",
  "ccmessaging.webCollaboration.pageMatchConfiguration": "تكوين مطابقة الصفحة",
  "ccmessaging.webCollaboration.pageElementSelector": "منتقي عناصر الصفحة",
  "ccmessaging.webCollaboration.createTime": "وقت الإنشاء",
  "ccmessaging.webCollaboration.operation": "العمليات",
  "cobrowse.sensitiveinfo.pagematchtype.commonmatch": "مطابقة عالمية",
  "cobrowse.sensitiveinfo.pagematchtype.urlmatch": "مطابقة استنادًا إلى مسار URL",
  "cobrowse.sensitiveinfo.pagematchtype.pageidmatch": "مطابقة حسب معرف الصفحة",
  "chat.webconfig.label.bandingskill": "ربط قائمة انتظار المهارة",
  "chat.agent.label.online": "عبر الإنترنت",
  "ccmessaging.chat.facebookpageconfig.config": "التكوين",
  "ccmesaging.chat.agentconsole.label.commonreply": "الردود الشائعة",
  "ccmessgaing.postChannelConfig.deleteFailed.inUse": "فشل الحذف. القناة لديها معلومات الافراج.",
  "ccmessaging.chat.channelconfig.savechannelconfigfailed": "فشل حفظ تكوين القناة!",
  "ccmessaging.chat.channelconfig.checkNumber": "القيمة غير صالحة.",
  "ccmessaging.fiveG.label.appId": "معرف التطبيق",
  "ccmessaging.chat.templateMsg.sendTemplateMsgTips": "إرسال رسالة قالب",
  "ccmessaging.chat.channelconfig.webhookPassword.oldPassword": "فترة القفل كلمة مرور Webhook",
  "chat.timerange.label.customizes": "حسب الطلب",
  "ccmessaging.chat.multimedia.beyondFileSize": "يتجاوز حجم ملف الوسائط المتعددة الذي تم تحميله الحد الأقصى : {n} ميغابايت!",
  "ccmessaging.chat.whatsapp.languageNL": "الهولندية",
  "ccmessaging.chat.chatcard.willdeletechatTemplate": "هل تريد بالتأكيد حذف قالب WhatsApp؟",
  "ccmessaging.ruleconfig.ruleName": "اسم القاعدة",
  "chat.client.greeting.info": "ماذا يمكنني أن أفعل لك؟",
  "ccmessaging.chat.multimedia.operating": "التشغيل",
  "ccmessaging.chat.whatsapp.languageNB": "النرويجية",
  "ccmesaging.chat.cobrowse.refused": "رفض",
  "ccmessaging.chat.iframeagentconsole.mailCollapse": "مدسوس",
  "ccmessaging.channelconfig.sms.freeTimeText": "عدم العمل يوم الرد",
  "ccmessaging.chat.chatcard.saveSuccess": "تم الحفظ بنجاح",
  "ccmessaging.chat.agentconsole.asyncTimeBeyond": "فشل الإرسال بسبب تجاوز الفاصل الزمني للرد غير المسموح به.",
  "ccmessaging.chat.email.channel.deletedraft": "تأكيد",
  "ccmessaging.chat.sumbitverification.Url.infobipMessAgeUrl": "url يُستخدم لإرسال رسالة إلى الموظف",
  "ccmessaging.chat.whatsapp.templateCreate": "إنشاء قالب WhatsApp",
  "chat.agent.label.refusefile": "رفض",
  "chat.agentConsole.message.error.emptyMessage": "[لا تحتوي الرسالة على محتوى يمكن عرضه.]",
  "ccmessaging.chat.agentconsole.select.contract.uploaded": "يُرجى تحديد المستند المراد تحميله",
  "ccmesaging.chat.agentconsole.label.expression": "الرموز",
  "ccmessaging.silentagent.dialog.title.create": "وكيل صوت صامت جديد",
  "ccmessaging.chat.whatsapp.languageMS": "الملايو",
  "ccmessaging.chat.whatsapp.languageMR": "ماراثي",
  "ccmessaging.chat.email.tips.deleteFailExistEmail": "فشل الحذف: البريد موجود في الدليل الحالي.",
  "ccmessaging.chat.whatsapp.languageML": "المالايالامية",
  "ccmessaging.chat.whatsapp.languageMK": "المقدونية الشمالية",
  "ccmessaging.chat.chatcard.tabs.selectType": "نوع الخيار",
  "ccmessaging.chat.chatconfig.refresh": "تحديث",
  "ccmessaging.chat.multimediaasccociation.unpublishconfigempty": "لم يتم تكوين قناة الجيل الخامس التي سيتم إصدار بيانات الوسائط المتعددة إليها.",
  "ccmessaging.chat.chatpersonalization.changeAvatar": "تغيير الصورة الرمزية",
  "ccmessaging.socialPostOperation.tips.imageNumTip": "يمكن تحميل 9 صور كحد أقصى.",
  "ccmessaging.chat.whatsapp.languageFIL": "الفلبين",
  "ccmessaging.service.time.choose.time": "تحديد أيام عدم العمل",
  "chat.client.label.header": "Telco+",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.oneDayTip": "صالحة لمدة أقل من يوم واحد. يرجى التعديل!",
  "chat.weChatConfig.label.verifycode": "الرمز المميز",
  "ccmessaging.chat.chatmanage.sessionEndText": "الخاتمة",
  "ccmessaging.chat.whatsapp.languageLV": "اللاتفية",
  "ccmessaging.chatbot.button.avatarImportLimit": "لا يمكن أن يتجاوز حجم الملف المراد تحميله 6 ميغابايت ويمكن أن يكون الملف بتنسيق JPG أو PNG.",
  "ccmessaging.chat.channelconfig.webHookException": "فشل الحصول على وقت صالح لكلمة مرور webHook",
  "ccmessaging.chat.whatsapp.languageLT": "الليتوانية",
  "ccmessaging.chat.channelconfig.email.signature": "توقيع البريد الإلكتروني",
  "ccmessaging.chatbot.message.success": "النجاح",
  "ccmessaging.operlogs.multimedialibrary.save": "حفظ تكوين بيانات مكتبة الوسائط المتعددة",
  "ccmessaging.chat.agentconsole.select.mandatory.signature": "يرجى اختيار توقيع إلزامي",
  "ccmessaging.chat.multi.chatcard.alignment.left": "محاذاة اليسار",
  "ccmessaging.chat.whatsapp.languageLO": "اللاوسية",
  "chat.skill.message.saveFail": "فشل حفظ المهارة.",
  "ccmessaging.chat.social.media.enterprise.post.reply.tips": "إضافة رد...",
  "chat.session.channel.mobile": "محطة متنقلة",
  "ccmessaging.chat.channelconfig.successfulAccess": "الوصول الناجح",
  "chat.weChatConfig.label.facebook": "الفيسبوك",
  "ccmessaging.socialPostOperation.facebook.threeDay": "3 أيام",
  "ccmessaging.chat.agentconsole.chat.close": "انتهت الجلسة الحالية",
  "ccmessaging.chat.sumbitverification.whatsApp.prompt": "نصيحة",
  "ccmessaging.chat.channelconfig.email.placeholder": "1 - 100 ، اختياري",
  "ccmessaging.multimedia.tip.not.exist": "مورد الوسائط المتعددة غير موجود",
  "ccmessaging.chat.channelconfig.lastModifyTime": "آخر تعديل في",
  "ccmessaging.chat.whatsapp.languageENGB": "الإنجليزية (المملكة المتحدة)",
  "ccmessaging.operlogs.multimedialibrary.create.richtext": "إضافة نص غني",
  "ccmessaging.chat.multiMedia.fileUploadInjectionRisk": "هذا التحميل لديه مخاطر الحقن. تحقق من المحتوى وحاول مرة أخرى.",
  "ccmessaging.chat.webcard.pop.error.prefix": "نطاق الموقع الإلكتروني",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgId": "معرف رسالة قالب WhatsApp",
  "ccmessaging.chat.chatconfig.willdeletechannelAccessCode": "هل تريد بالتأكيد حذف تكوين القناة المحددة؟",
  "ccmessaging.chat.whatsapp.languageKO": "الكورية",
  "ccmessaging.chat.whatsapp.languageKN": "كنداون",
  "ccmessaging.chat.multimedia.nameContainsSpecialCharacters": "يحتوي الاسم على أحرف خاصة.",
  "ccmessaging.chat.social.media.enterprise.post.topcomment.cancel": "إلغاء التعليق",
  "ccmessaging.chat.whatsapp.languageKK": "الكازاخستاني",
  "ccmessaging.chat.speciallist.confirmdelete": "هل أنت متأكد من رغبتك في الحذف؟",
  "ccmessaging.chat.social.media.enterprise.post.comment": "تعليق على",
  "ccmessaging.chat.sms.channel.gateway": "بوابة SMS",
  "ccmessaging.socialPostOperation.youTube.category.sport": "الرياضة",
  "ccmessaging.chat.social.media.enterprise.post.all.reply": "كل الرد",
  "chat.timerange.label.oneday": "آخر يوم",
  "ccmessaging.chat.speciallist.deletelevel": "(مهجورة)",
  "chat.agent.title.close": "إغلاق",
  "ccmessaging.chat.chatcard.invalidUrl": "عنوان URL لا يبدأ بـ http://,https://",
  "ccmessaging.socialPostOperation.tips.fileTip": "يتجاوز حجم الملف الحد الأعلى. الحد الأقصى للحجم هو {n} ميغابايت.",
  "ccmessaging.chat.chatcard.operate": "العملية",
  "chat.agent.label.customerSource": "المصدر: تطبيق الجوال - المركز الشخصي",
  "ccmessaging.chat.workbench.transfer.noagents": "لا يوجد موظف متصل في قائمة انتظار المهارات الحالية.",
  "ccmessaging.chat.email.channel.deletedraftfail": "فشل حذف مسودة البريد الإلكتروني.",
  "ccmessaging.socialPostOperation.edit.approveInformation": "معلومات الموافقة",
  "ccmessaging.chat.chatcard.deleteSuccess": "تم الحذف بنجاح",
  "ccmessaging.chat.chatcard.down": "تحريك للأسفل",
  "chat.session.title.history": "سجلات الدردشة التاريخية",
  "ccmessaging.chat.whatsapp.languageJA": "اليابانية",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoSuccess": "تم حذف بيانات الوسائط المتعددة بنجاح!",
  "ccmessaging.chatbot.label.robotName": "اسم الروبوت",
  "chat.skill.message.createFail": "فشل إنشاء المهارة.",
  "ccmessaging.chat.channelconfig.selectAccessChannel": "تحديد قناة الوصول",
  "ccmessaging.ruleconfig.SMStips4": `"\\ *" يمنع الرسائل النصية المرسلة من جميع أرقام الهواتف المحمولة. استخدمها بحذر`,
  "ccmessaging.chat.multimediaasccociation.deleteError": "فشل حذف نتيجة التحرير!",
  "ccmessaging.ruleconfig.SMStips3": `على سبيل المثال، يشير "132 \\ *" إلى رفض استلام الرسائل القصيرة المرسلة من جميع أرقام الجوال التي تبدأ بـ "132"`,
  "ccmessaging.chat.agentconsole.notSupport": "لا تدعم القناة هذا النوع من رسائل الوسائط المتعددة.",
  "ccmessaging.ruleconfig.SMStips2": `على سبيل المثال، "\\ * 9988" يشير إلى رفض استلام الرسائل القصيرة المرسلة من جميع أرقام الجوال التي تنتهي بـ "9988"`,
  "ccmessaging.ruleconfig.SMStips1": `يمكن إدخال أحرف البدل لرقم الجوال. يشير "\\ *" إلى تطابق الأحرف من 0 إلى أكثر من الأحرف، "\\?"يشير إلى تطابق من 0 إلى 1 حرف`,
  "ccmessaging.flowControl.title.view": "عرض التحكم في التدفق",
  "ccmessaging.chat.connnect.offlineuser.userId.beyondMaxLength": "UserId هو أكثر من 128",
  "ccmessaging.chat.chatconfig.skillId": "قائمة انتظار المهارات",
  "ccmessaging.chat.whatsapp.languageIT": "الإيطالية",
  "ccmessaging.chat.email.channel.deletedraftsuccess": "تم حذف مسودة البريد الإلكتروني بنجاح.",
  "ccmessaging.chat.email.forward": "إلى الأمام",
  "ccmessaging.chat.whatsapp.languageZHTW": "الصينية التقليدية (تايوان، الصين)",
  "ccmessaging.chat.whatsapp.templateName": "اسم القالب",
  "ccmesaging.chat.cobrowse.accept": "قبول",
  "ccmessaging.chat.multi.chatcard.sort": "اتجاه ترتيب البطاقة",
  "ccmessaging.chat.social.media.enterprise.post.load.failed": "فشل تحميل المرفق.",
  "ccmessaging.chatofflineCall.strangeOfflineTip": "لم يتم العثور على جلسة تاريخية.",
  "ccmessaging.chat.whatsapp.languageID": "الإندونيسية",
  "chat.client.transfer.link": "هل تريد بالتأكيد تحويل المكالمة إلى موظف؟",
  "ccmessaging.chat.chatcard.folded": "مطوية",
  "ccmessaging.char.call.work.state": "حالة الالتفاف",
  "ccmessaging.chat.chatconfig.add": "جديد",
  "ccmessaging.chat.multimedia.fileName": "اسم الملف",
  "ccmessaging.chat.chatholiday.designation.days": "عدة أيام",
  "ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl": "عنوان URL غير صالح!",
  "ccmessgaing.chat.web.channel.webrtc.share": "مشاركة سطح المكتب ينطوي على عرض الخصوصية. يرجى التأكيد.",
  "ccmessaging.chat.whatsapp.languageHU": "الهنغارية",
  "ccmessaging.chat.whatsapp.languageHR": "الكرواتية",
  "ccmessaging.chat.multimediaasccociation.expirationtime": "تنتهي عند",
  "chat.skill.label.agents": "الوكيل",
  "ccmessaging.chat.channelconfig.scanCodeHosting": "رمز QR",
  "ccmessaging.chat.chatcard.cardName": "الاسم",
  "ccmessaging.chat.card.template": "قالب WhatsApp",
  "ccmessaging.chat.successaccess": "الوصول الناجح",
  "ccmessaging.chat.agentconsole.label.messageRejected": "تم رفض الرسالة من قبل تويتر لأنها تبدو مثل البريد العشوائي، يرجى استخدام مرادف أو إعادة المحاولة لاحقًا:",
  "ccmessaging.chat.whatsapp.languageHI": "الهندية",
  "ccmessaging.chat.whatsapp.templateBodyText": "محتوى النص الأساسي",
  "ccmessaging.chat.whatsapp.languageHE": "العبرية",
  "ccmessaging.chatbot.label.avatarImportSuccess": "تم تحميل الصورة بنجاح!",
  "ccmessaging.chat.whatsapp.languageHA": "الهوسا",
  "ccmessaging.socialPostOperation.button.cancel": "الانسحاب",
  "ccmessaging.chat.phrase.updateError": "خطأ في التحديث",
  "ccmessaging.chat.emailmessage.assignEmailFailed": "فشل تعيين البريد الإلكتروني.",
  "ccmessaging.chat.whatsapp.languageGU": "غوجارات",
  "ccmessaging.chat.silentagent.config.accesscode.same": "تكوين الموظف الصامت لـ IVR الصوتي المسمى المسار موجود بالفعل في التأجير الحالي.",
  "ccmessaging.chat.chatpersonalization.MaximumConcurrent": "الحد الأقصى لعملاء الخدمة المتزامنة",
  "ccmessaging.chat.refresh.fail": "فشل التحديث",
  "ccmessaging.sms.label.defaultReceiveGateway": "الرقم الافتراضي للمستلم",
  "ccmessaging.chat.chatpersonalization.cancel": "إلغاء",
  "ccmessaging.chat.channelconfig.offLastAgentType": "نوع الموظف الأخير",
  "chat.weChatConfig.message.codeNameExisted": "رمز الوصول إلى القناة موجود بالفعل.",
  "ccmessaging.chat.whatsapp.languageGA": "الأيرلندية",
  "ccmessgaing.postChannelConfig.postChannelName": "اسم القناة",
  "ccmessaging.socialPostOperation.youTube.noKids": "ليس للأطفال",
  "ccmessaging.socialPostOperation.field.approveComment": "الموافقة التعليقات",
  "ccmessaging.chat.agentconsole.uploadfail": "فشل إيداع الملف",
  "ccmessaging.chat.chatcard.description": "الوصف",
  "chat.session.label.servedAgent": "موظف الخدمة",
  "ccmessaging.chat.facebookpageconfig.isDeleteFacebookPage": "هل تريد بالتأكيد حذف إعدادات الصفحة الرئيسية لفيسبوك؟",
  "ccmessaging.chatofflineCall.invalidUserIdTip": "معرف المشترك غير صالح.",
  "ccmessaging.chat.whatsapp.languageFR": "الفرنسية",
  "ccmessaging.ruleconfig.validityPeriodDay": "فترة صلاحية الرمز المميز للوصول (أيام)",
  "ccmessaging.chat.channelconfig.reCreateScenario": "توليد",
  "ccmessaging.chat.email.localImage.width": "العرض",
  "ccmessaging.chat.whatsapp.languageFI": "الفنلندية",
  "ccmessaging.chat.contact.callout": "مكالمة صادرة",
  "ccmessaging.sms.label.defaultSendGateway": "رقم المرسل الافتراضي",
  "ccmessaging.chat.whatsapp.languageFA": "الفارسية",
  "chat.skill.label.edit": "تحرير",
  "ccmessaging.chat.phrase.updatePhraseError": "فشل تحديث اللغة العامة",
  "ccmessaging.chat.whatsapp.languageET": "الإستونية",
  "ccmessaging.chat.whatsapp.languageES": "الأسبانية",
  "chat.skill.label.save": "حفظ",
  "ccmessaging.emailcatalog.moveEmailQuantityExceedsMaxValue": "يتجاوز عدد رسائل البريد المنقولة 30 أو يتجاوز طول المعلمة الحد الأقصى.",
  "ccmessaging.chat.chatemail.handled": "تمت المعالجة",
  "ccmessaging.chat.whatsapp.languageEN": "الإنجليزية",
  "ccmessaging.chat.whatsapp.languageEL": "اليونانية",
  "ccmessaging.chat.channelconfig.importAvatartype": "نوع صورة الصورة الرمزية غير صحيح.",
  "ccmesaging.chat.cobrowse.noWebSiteAuth": "ليس لدى الموظف إذن الاستعراض المشترك.",
  "ccmessaging.socialPostOperation.youTube.labels": "التسمية",
  "ccmessaging.chat.multimedia.addCategory": "إضافة فئة",
  "ccmessaging.chat.multimedia.id": "معرف الهوية",
  "chat.weChatConfig.stepname.selectmode": "تحديد طريقة التكوين",
  "ccmessaging.socialPostOperation.youTube.category.film": "الأفلام والرسوم المتحركة",
  "ccmessaging.chat.agentconsole.total.number.signatures": "العدد الإجمالي للتوقيعات",
  "ccmessaging.chat.channelconfig.guestTimeout.timeOutTips": "تخصيص مهلة عدم الرد للضيف. في حالة عدم التمكين، تكون المهلة الافتراضية {n} دقيقة.",
  "ccmessaging.chat.agentconsole.loading": "تحميل أكثر",
  "chat.common.message.dateEnd": "وقت الانتهاء",
  "ccmessaging.chat.channelconfig.range.placeholder": "{0}-{1}",
  "ccmessgaing.postChannelConfig.deleteFailed.permission": "فشل الحذف. فشل التحقق من الأذونات.",
  "ccmessaging.flowcontrol.target.deleted": "تم حذف كائن التحكم في التدفق. احذف قاعدة التحكم في التدفق المقابلة.",
  "ccmessaging.socialPostOperation.field.approveStatus.rejected": "رفض",
  "ccmessaging.chat.web.agent.prohibitedmess": "لغة محظورة! يرجى الانتباه إلى الكلمات.",
  "ccmessaging.chat.chatconfig.operate": "العملية",
  "ccmessaging.chat.email.channel.BccSenderError": "عدد مستلمي BCC يتجاوز الحد.",
  "ccmessaging.chat.whatsapp.templateButtonContent": "محتوى زر القالب",
  "ccmessaging.chat.speciallist.haverestrictchannel": "هذه القناة مقيدة. لا حاجة لتقييدها مرة أخرى.",
  "ccmessaging.chat.workbench.transfer.skillsearch": "الرجاء إدخال اسم قائمة انتظار مهارة",
  "ccmessaging.chat.whatsapp.languageDE": "الألمانية",
  "ccmessaging.chat.email.tips.addFailDuplicateName": "فشلت الإضافة: اسم مكرر.",
  "chat.agent.label.money": "المبلغ",
  "ccmessaging.chat.whatsapp.languageDA": "الدنماركية",
  "ccmessaging.socialPostOperation.facebook.awful": "فظيعة",
  "ccmessaging.chat.chatmanage.freeTimeTextMorethan": "لا يمكن أن يحتوي وصف إعلام غير وقت العمل على أكثر من 100 حرف.",
  "ccmessaging.chat.whatsapp.templateDescription": "وصف القالب",
  "ccmessaging.chat.chatcard.cardTitlePlaceholder": "أدخل عنوان البطاقة.",
  "chat.webconfig.message.saveSuccess": "تم حفظ التكوين بنجاح.",
  "ccmessaging.chat.chatconfig.deletefail": "فشل الحذف.",
  "chat.usefulexpressions.label.createExpressionTab": "إنشاء عبارة عامة",
  "chat.common.message.create": "إنشاء",
  "chat.weChatConfig.label.configId": "معرف التكوين",
  "ccmessaging.chat.whatsapp.languageCS": "التشيكية",
  "ccmessaging.chat.speciallist.leveName": "اسم نوع القائمة الخاصة",
  "ccmessaging.chat.agentconsole.label.filesWechatNotsupport": "لا يدعم WeChat الصوت الذي يستمر لفترة أطول من 60 ثانية.",
  "ccmessaging.chat.email.check.localImageType": "تنسيق الصورة غير معتمد.",
  "ccmessaging.channelconfig.email.selectAutoReply": "قالب البريد الإلكتروني",
  "ccmessaging.chat.channelconfig.pushMessageUrlValid": "فشل حفظ تكوين القناة. تحقق من صحة عنوان أو شهادة دفع الرسالة.",
  "cmessaging.chat.channelconfig.pushWebMessageUrl": "رسالة دفع العنوان",
  "ccmessaging.chat.chinese.separator": ":",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFailWithChannel": "يمكن تشغيل رسائل الويب فقط",
  "ccmessaging.chatbot.message.error": "فشل",
  "ccmessaging.chat.chatholiday.new": "جديد",
  "ccmessaging.chat.channelconfig.checkmessage": "`الرموز الخاصة التالية غير مسموح بها <>'/\\`",
  "ccmessaging.chat.channelconfig.checkNickName": `الرموز الخاصة التالية غير مسموح بها +~!{'@'}#$%&*()/=+{'{'}{'}'};:'\\",<.>?`,
  "ccmessaging.chatbot.message.modifyFailure": "فشل التعديل.",
  "ccmessaging.chat.whatsapp.languageCA": "الكاتالونية",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyFiveG": "تدعم رسائل بطاقة الجيل الخامس قنوات الجيل الخامس فقط!",
  "ccmessaging.chat.chatcard.cardNamePlaceholder": "أدخل اسم قالب البطاقة.",
  "ccmessaging.chat.chatpersonalization.welcomeText": "تحية شخصية",
  "chat.skill.label.createSkillTab": "إنشاء مهارة",
  "ccmessaging.chat.chatmanage.freeTimeText": "إشعار وقت غير العمل",
  "ccmessaging.flowControl.name": "اسم التحكم في التدفق",
  "ccmessaging.chat.facebookpageconfig.facebookconfig": "إعدادات الصفحة الرئيسية لفيسبوك",
  "chat.common.message.warning": "تحذير",
  "ccmessaging.socialPostOperation.field.status.draft": "مشروع",
  "ccmessaging.chat.whatsapp.languageBN": "البنغالية",
  "ccmessaging.chat.social.media.enterprise.post.reply": "الرد",
  "ccmessaging.chat.channelconfig.delete.exist.asyncUser": "فشل حذف قناة [{n}]. حاول مرة أخرى بعد تعيين المستخدمين غير المتصلين.",
  "ccmessaging.channelconfig.email.reply.each.eamil": "الرد تلقائيًا على كل بريد إلكتروني",
  "ccmessaging.chat.whatsapp.languageBG": "البلغارية",
  "ccmessaging.chat.whatsapp.templateButton.qickReply": "الرد السريع",
  "ccmessaging.chat.channelconfig.importAvatarSize": "حجم الصورة الرمزية يتجاوز 6 ميغابايت.",
  "ccmessaging.chat.agentconsole.setHandledNumber": "تعيين رقم تمت معالجته",
  "ccmessaging.chat.multi.chatcard.alignment.right": "محاذاة اليمين",
  "ccmessaging.chat.channelconfig.guestTimeout": "فترة المهلة المخصصة لعدم رد العميل ورسالة إنهاء الجلسة. في حال تعطيل هذه الوظيفة، تبلغ فترة المهلة الافتراضية 20 دقائق.",
  "chat.skill.message.completeInputs": "يرجى إكمال جميع المدخلات.",
  "ccmessaging.socialPostOperation.field.publishType": "نوع المنشور",
  "ccmessaging.chat.whatsapp.languageAZ": "الأذربيجانية",
  "ccmessaging.chat.channelconfig.thirdPartySecretKey": "مفتاح مصادقة الطرف الثالث",
  "ccmessaging.chat.channelconfig.apifabric.sk": "APP سر",
  "chat.client.label.privacyHintContent": "لتحسين جودة الخدمة، سيتم تسجيل محادثتك من قبل النظام. هل أنت متأكد من رغبتك في الاستمرار في الحديث؟",
  "ccmessaging.socialPostOperation.field.channelName": "اسم القناة",
  "ccmessaging.chat.whatsapp.languageAR": "العربية",
  "ccmessaging.chat.chatcard.deleteWhatsAppTemplate.Fail": "فشل الحذف لأن القالب تمت الإشارة إليه مسبقاً بواسطة رسالة قالب WhatsApp.",
  "chat.agent.title.OfflineCall": "مكالمة غير متصلة",
  "ccmessaging.chat.email.card.reply": "الرد",
  "ccmessaging.chat.selectchannel.vaildate1": "يجب أن يكون رمز الوصول إلى القناة فريدًا.",
  "ccmessaging.chat.channelconfig.selectRobot": "اختيار الروبوت",
  "ccmessaging.chat.whatsapp.languageAF": "الهولندية, جنوب أفريقيا",
  "chat.agent.title.userMenu": "قائمة العملاء",
  "ccmessaging.chat.channelconfig.appId": "معرف التطبيق",
  "ccmessaging.chat.whatsapp.body": "الجسم",
  "ccmessaging.silentagent.agent.workday.worktime.error": "تكوين وقت عمل الموظف غير صحيح. تتداخل مقاطع الوقت.",
  "chat.client.label.evaluation": "استبيان الرضا",
  "ccmessaging.chat.email.channel.modifydraftfail": "فشل التعديل.",
  "ccmessaging.chat.channelconfig.bsp": "مزود حلول الأعمال",
  "ccmessaging.chat.agentconsole.signature.variable": "التوقيع{n}",
  "ccmessaging.socialPostOperation.tips.postTypesChoose": "حدد قناة تحرير واحدة على الأقل",
  "ccmessaging.chat.agentconsole.information": "معلومات",
  "chat.timerange.label.alldays": "كل وقت الإنشاء",
  "ccmessaging.chat.phrase.shortcut": "مفاتيح الاختصار",
  "ccmessaging.chat.emailmessage.assigning": "تعيين",
  "ccmessaging.chat.chatholiday.otherdays.exception": "تاريخ الاستثناء",
  "ccmessaging.chat.chatcard.dial": "الاتصال الهاتفي",
  "ccmessaging.chat.multimediaasccociation.refreshsuccess": "تم تحديث البيانات بنجاح!",
  "ccmessaging.chat.channelconfig.hour": "ساعة",
  "ccmessaging.chat.chatmanage.savesuccess": "تم الحفظ بنجاح.",
  "ccmessaging.socialPostOperation.youTube.addLabel": "إضافة علامة",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime2": "لا يمكن أن يكون وقت البدء بعد وقت الانتهاء.",
  "ccmessaging.chat.channelconfig.checkName": "لا يمكن أن يكون الاسم عبارة عن كلمة system، etc",
  "ccmessaging.chat.facebookpageconfig.saveError": "فشل إضافة صفحة Facebook الرئيسية.",
  "chat.agent.title.workMode": "وضع العمل",
  "ccmessaging.chat.channelconfig.trustCertificate": "شهادة الثقة",
  "ccmessaging.chat.chatmanage.freeTimeTextnotnull": "لا يمكن أن يكون وصف تذكير وقت عدم العمل فارغًا.",
  "ccmessaging.chat.email.channel.confdmodifydraft": "هل أنت متأكد من أنك قمت بمعالجة البريد الإلكتروني؟",
  "ccmessaging.ruleconfig.deleteSuccess": "تم حذف القاعدة بنجاح.",
  "ccmessaging.chat.chatconfig.inquire": "البحث",
  "ccmessaging.message.location.longitude": "خط الطول:",
  "ccmessaging.chat.chatmanage.endTimeRange": "يجب أن يتراوح رقم وقت الانتهاء من 0 إلى 24.",
  "chat.agent.chatOnlineWorkbench": "منضدة الدردشة عبر الإنترنت",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.not.finished": "ليس كل ما كتبته بشكل خاطئ تم تصحيحه. إنهاء الشيك؟",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.cancel.confirmation": "سيتم التخلص من المحتوى المُصحح. هل حقًا تريد إلغاء التحقق؟",
  "ccmessaging.chat.sumbitverification.whatsApp.tips": "عنوان URL المستخدم من قِبل موفر خدمة WhatsApp لمعالجة الرسائل",
  "ccmessaging.chat.chatpersonalization.welcometimeerror": "أدخل مقطع وقت عادي!",
  "chat.webconfig.label.transHuman": "التحويل إلى الوكيل",
  "ccmessaging.chat.email.reply.separator": ":",
  "ccmessaging.chat.silentAgent.workMode.supervise": "الإشراف",
  "ccmessaging.char.call.Idle.state": "حالة الخمول",
  "ccmessaging.chat.offlineCall.userId": "معرف المستخدم",
  "ccmessaging.chat.sessiontransfer.tips": "لا يمكن نقل سوى الموظفين الخاملين والموظفين الذين لا تصل مكالماتهم المتزامنة إلى الحد الأعلى.",
  "ccmessaging.chat.channelconfig.messagePushUrl.not.configure.trustList": "العنوان غير موثوق به. يرجى الاتصال بمسؤول النظام.",
  "ccmessaging.chat.sumbitverification.Url.infobipReportUrl": "url يُستخدم لإرسال التقرير إلى الموظف",
  "ccmessgaing.postChannelConfig.appId": "معرف العميل",
  "chat.skill.label.delete": "حذف",
  "ccmessaging.socialPostOperation.field.publishTime": "وقت الإصدار المقدر",
  "ccmessaging.chat.phrase.deleteType": "حذف نوع",
  "chat.weChatConfig.label.AppSecret": "كلمة مرور المطور",
  "ccmessaging.chat.agentconsole.document": "وثيقة",
  "ccmessaging.chat.chatcard.fail": "فشل",
  "ccmessaging.chat.channelconfig.noReplyTime": "الحد الزمني للمكالمات الصادرة من الوكيل",
  "ccmessaging.chat.multi.card.add": "اختر بطاقة",
  "ccmessaging.chat.multimediaasccociation.refresh": "تحديث",
  "ccmessaging.socialPostOperation.youTube.category.howto": "كيفيه &نمط",
  "ccmessaging.chat.email.check.localImageNumberOverMax": "يتجاوز عدد الصور المحلية التي تم تحميلها في نص البريد الإلكتروني الحد الأقصى.",
  "ccmessaging.chat.chatcard.deleteFail": "فشل الحذف",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noStaticSigns.failTip": "يرجى عد العقود مع التوقيعات أولا.",
  "ccmessaging.chat.richText.add": "إنشاء",
  "ccmessaging.chat.channelconfig.email.totalEmailNumber": "لا يمكن أن يتجاوز العدد الإجمالي للمستلم ومستلم النسخ الكربوني وعناوين البريد الإلكتروني للمستلم السري 100",
  "ccmessaging.flowControl.alarmThreshold": "عتبة التنبيه",
  "ccmessaging.chat.social.media.enterprise.post.add.success": "نجحت الإضافة.",
  "ccmessaging.ruleconfig.dateStart": "وقت البدء",
  "ccmessaging.chat.emailmessage.assignEmail.queryAgentName": "أدخل حساب نشاط تجاري.",
  "ccmessaging.chat.multimedia.uploadTime": "وقت التحميل",
  "chat.session.endType.overtimeEnd": "إنهاء بسبب المهلة",
  "ccmessaging.chat.phrase.cancel": "إلغاء",
  "ccmessaging.chat.chatholiday.delete": "حذف",
  "chat.skill.label.description": "الوصف",
  "ccmessaging.chat.whatsapp.languageZHHK": "الصينية التقليدية (هونغ كونغ، الصين)",
  "ccmessaging.chat.chatmanage.fail": "فشل",
  "ccmessaging.service.time.select.holiday": "تحديد تواريخ الراحة",
  "chat.skill.message.error": "خطأ",
  "ccmessaging.chat.speciallist.restrictStyle": "وضع التطبيق",
  "chat.weChatConfig.message.error": "خطأ",
  "ccmessaging.chat.chatmanage.savefailed": "فشل الحفظ.",
  "chat.usefulexpressions.label.expression": "عبارات شائعة",
  "ccmessaging.socialPostOperation.field.publishTyle.now": "الإصدار في الوقت الحقيقي",
  "ccmessaging.chat.channelconfig.offBeginTime": "من وقت البدء إلى الوقت الحالي",
  "ccmessaging.chat.social.media.enterprise.post.reply.cancel": "إلغاء",
  "ccmessaging.chatofflineCall.noSelectChanelTip": "لم يتم تحديد أي قناة.",
  "ccmessaging.chat.whatsapp.templateHeaderType.video": "فيديو",
  "ccmessaging.chat.multimedia.descriptionContainsSpecialCharacters": "يحتوي الوصف على أحرف خاصة.",
  "ccmessaging.chat.speciallist.delete": "مهجورة",
  "ccmessaging.chat.facebookpageconfig.idNotExist": "البيانات غير موجودة.",
  "ccmessaging.chat.channelconfig.turnAssistant": "الاتصال بالمساعد",
  "ccmessaging.chat.channelconfig.checkWhatsAppAccessTokenDuration": "يجب أن تكون القيمة عددًا صحيحًا يتراوح من 1 إلى 60.",
  "ccmessaging.chat.chatemail.delete": "حذف",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyWebCard": "تدعم رسائل بطاقة الويب قنوات الويب فقط!",
  "ccmessaging.chat.agentconsole.messageloading": "تحميل",
  "ccmessaging.chat.agentconsole.cobrowse": "تصفح مشترك",
  "ccmessaging.chat.channelconfig.hostingMethod": "طريقة الاستضافة",
  "ccmessaging.chat.channelconfig.channelSecret": "سر القناة",
  "ccmessaging.chat.channelconfig.onlyContainSubType": "عرض قوائم انتظار المهارة فقط لنوع القناة عندما يقوم موظف بتحويل جلسة عمل.",
  "chat.usefulexpressions.message.isExisted": "اسم العبارة العامة موجود بالفعل.",
  "ccmessgaing.postChannelConfig.apiFabricSK": "ApiFabricSK",
  "ccmessaging.socialPostOperation.button.edit": "تحرير",
  "ccmessaging.socialPostOperation.button.delete": "حذف",
  "ccmessaging.chat.agentconsole.label.NoAudioDuration": "فشل الحصول على مدة الصوت. يرجى تحميله مرة أخرى.",
  "chat.weChatConfig.message.appIdExisted": "معرّف مطور الحساب العام الذي تم إدخاله موجود بالفعل.",
  "ccmessaging.chat.agentconsole.send.contract": "إرسال العقد",
  "ccmessaging.chat.multimediaasccociation.release": "الافراج",
  "ccmessaging.socialPostOperation.tips.youTubeFileChoose": "يمكن تحميل مقاطع الفيديو فقط من خلال قناة Youtube.",
  "chat.agent.label.balance": "الدخل والمصروفات",
  "ccmessaging.chat.async.statistics": "مراقبة الرسائل دون اتصال",
  "chat.client.label.password": "كلمة المرور",
  "ccmessaging.chat.phrase.updatePhraseSucess": "تم تحديث اللغة العامة بنجاح",
  "ccmessaging.chat.whatsapp.templateNamePlaceholder": "أدخل اسم قالب.",
  "chat.weChatConfig.label.verifying": "التحقق",
  "ccmessaging.chat.chatholiday.thursday": "الخميس",
  "ccmessaging.chat.channelconfig.updatecontactfailed": "فشل تحديث جهة الاتصال!",
  "ccmessaging.chat.facebookpageconfig.modifyError": "فشل تعديل الصفحة الرئيسية لفيسبوك.",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfo": "تم تعديل بيانات الوسائط المتعددة بنجاح!",
  "ccmessaging.chat.channelconfig.nochatonline": "لا توجد جلسة مستمرة حالياً!",
  "ccmessaging.socialPostOperation.youTube.yesKids": "نعم، المحتوى مخصص للأطفال",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError": "فشل حذف فئة مكتبة الوسائط المتعددة!",
  "ccmessgaing.postChannelConfig.delete": "حذف",
  "ccmessaging.socialPostOperation.link": "زيارة النص الأصلي",
  "ccmessaging.chat.channelconfig.welcomeMessage": "تحية",
  "chat.client.message.gladtoServe": ". ماذا يمكنني أن أفعل لك؟",
  "ccmessgaing.postChannelConfig.editFailed": "فشل التحديث. تحقق من معلمات النموذج واتصال الشبكة.",
  "ccmessaging.socialPostOperation.tips.approveSet": "يُرجى التحقق من عنصر تكوين جهة الموافقة.",
  "ccmesaging.chat.agentconsole.label.service": "الخدمة",
  "ccmessaging.chat.channelconfig.robotConfig": "تكوين الروبوت",
  "ccmessaging.chat.multimedia.saveMultiMediaInfo": "تم حفظ بيانات الوسائط المتعددة بنجاح!",
  "chat.weChatConfig.label.appId": "معرف المطور",
  "ccmessaging.emailcatalog.moveCatalogSuccess": "تم نقل الدليل بنجاح.",
  "ccmessaging.chat.multimedia.modifyCategory": "تعديل فئة",
  "ccmessaging.chat.facebookpageconfig.pageId": "معرف الصفحة",
  "ccmessaging.chat.multimedia.createRichText": "إنشاء نص غني",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.timeOverlap": "لا يمكن تداخل الفترة الزمنية للتحية الشخصية!",
  "chat.thirdportal.title": "بوابة العملاء على الويب",
  "ccmessaging.chat.channelconfig.checkAgentTimeOutInterval": "لا يمكن أن يكون الفاصل الزمني للمهلة لعدم رد الموظف أطول من الفاصل الزمني للمطالبة لعدم الرد.",
  "chat.webconfig.title": "تمكين قناة الويب",
  "chat.agent.label.goAway": "بعيداً",
  "chat.weChatConfig.label.tenantId": "معرف المستأجر",
  "chat.agent.label.total": "المجموع",
  "ccmessaging.chat.speciallist.repeatlevel": "مستوى القائمة الخاص موجود بالفعل. لا تقم بإنشائه مرة أخرى.",
  "ccmessaging.ruleconfig.SMSNumberExists": "رقم الجوال موجود.",
  "ccmessaging.chat.channelconfig.agentTimeout": "تخصيص الفاصل الزمني للمهلة لعدم رد الموظف ومطالبته بإعادة تعيين الموظف",
  "ccmessaging.chat.agentconsole.history": "التاريخ",
  "chat.agentConsole.message.content.image": "[رسالة مصورة]",
  "ccmessaging.silentagent.agent.workday.worktime.empty": "تكوين وقت العمل للموظف له تكوين فارغ.",
  "ccmessaging.socialPostOperation.tips.submit": "الموافق فارغ. سيتم نشره مباشرة بعد التقديم. هل أنت متأكد من رغبتك في المتابعة؟",
  "ccmessaging.chat.clickCallClient.denial": "انتهت صلاحية الرابط. إذا لزم الأمر ، اتصل بالوكيل مرة أخرى لإرسال الرابط.",
  "ccmessaging.chat.whatsapp.template.no": "لا ..",
  "ccmessaging.chat.channelconfig.scenario.status.noExistent": "مفتاح السيناريو: لا",
  "ccmessaging.chat.phrase.phraseList": "قائمة العبارات",
  "chat.weChatConfig.label.save": "حفظ",
  "ccmessaging.chat.channelconfig.webhookPassword": "Webhook كلمة المرور",
  "ccmessaging.chat.channelconfig.cloudApi.bsp.tips": "حاليًا ، يمكن توصيل واجهة برمجة تطبيقات سحابة WhatsApp للتعامل مع الرسائل النصية فقط.",
  "ccmessaging.chatbot.button.avatarImport": "تحميل",
  "chat.client.label.privacyHint": "تحذير لحماية الخصوصية",
  "chat.skill.message.selectSkills": "حدد مهارة.",
  "ccmessaging.chatbot.label.commonLanguageSetting": "تكوين العبارة الشائعة",
  "ccmessaging.socialPostOperation.edit.placeholder": "يُرجى إدخال",
  "chat.timerange.label.thirtydays": "آخر 30 يوم",
  "ccmessaging.chat.agentconsole.chat.userId": "معرف المستخدم",
  "ccmessaging.chat.phrase.error": "خطأ",
  "chat.session.label.skillQueueName": "اسم قائمة انتظار المهارة",
  "ccmessaging.chat.phrase.delPhraseSucess": "تم حذف العبارة العامة بنجاح",
  "ccmessaging.socialPostOperation.youTube.category.entertainment": "الترفيه",
  "ccmessaging.chat.multimedia.longitudeValidateFailed": "الجزء الصحيح لخط الطول هو -180 ~ 180، والجزء العشري هو 0 إلى 6 أرقام!",
  "ccmessaging.chat.async.statistics.updateTime": "وقت تحديث البيانات",
  "ccmessaging.chat.chatholiday.specified.date": "التاريخ المحدد",
  "ccmessaging.chat.whatsapp.languageZHCN": "الصينية المبسطة",
  "ccmessaging.email.label.InputGatewayName": "الرجاء إدخال اسم بوابة",
  "ccmessaging.chat.channelconfig.envNameValidate": "يجب أن تكون القيمة سلسلة أقل من أو تساوي 24 حرفًا ويمكن أن تحتوي على أحرف وأرقام فقط.",
  "ccmessaging.chat.channelconfig.checkEncodingAESKey": "طول المفتاح هو 43 ويمكن أن يحتوي فقط على AZ ، az ، 0-9",
  "ccmessaging.chat.social.media.enterprise.tips.imgnum": "يمكن إضافة صورة واحدة كحد أقصى.",
  "ccmessgaing.postChannelConfig.accessToken": "رمز الدخزل",
  "ccmessaging.chat.template.table.parameterDescription": "وصف المعلمة",
  "ccmessaging.chat.chatcard.tabs.selectcontent": "تحديد المحتوى",
  "chat.common.message.success": "النجاح",
  "ccmessaging.chat.channelconfig.accessToken": "رمز الوصول",
  "ccmessaging.chat.agent.refreshFail": "لا تقم بتحديث الصفحة بشكل متكرر",
  "ccmesaging.chat.agentconsole.label.screenshot": "لقطات الشاشة",
  "ccmessaging.chat.channelconfig.routeTypeSkill": "توجيه قائمة انتظار المهارات",
  "ccmessaging.chat.chatcard.saveFail": "فشل الحفظ",
  "ccmesaging.chat.cobrowse.webSite": "عنوان URL للاستعراض المشترك:",
  "ccmessaging.chat.multi.card.id": "هوية البطاقة",
  "ccmessaging.silentagent.dialog.title.view": "عرض وكيل الصوت الصامت",
  "ccmessaging.chat.chatcard.shortHeight": "ارتفاع قصير",
  "chat.webconfig.label.windowTitle": "عنوان نافذة الدردشة",
  "ccmessaging.chat.agentconsole.label.filesFacebookNotsupport": "لا يدعم Facebook Messenger حاليًا تلقي معلومات الموقع",
  "chat.agent.label.recivefile": "الاستلام",
  "ccmessaging.emailcatalog.add": "إنشاء دليل",
  "chat.common.message.dateStart": "وقت البدء",
  "ccmessaging.socialPostOperation.youTube.view.unfolding": "توسيع",
  "ccmessaging.chat.chatpersonalization.edit": "تحرير",
  "ccmessaging.chat.email.AgentAccountName": "حساب الوكيل",
  "ccmessaging.chat.agentconsole.filterByCondition": "معايير الفلتر",
  "ccmessaging.chat.channelconfig.KeyValidate": "يمكن أن تحتوي القيمة على أحرف وأرقام وأحرف خاصة فقط _~!{'@'}#$%^&*- مكون",
  "ccmessaging.chat.channelconfig.whatsappNameValidate": "يمكن أن تحتوي القيمة على أحرف وأرقام وأحرف خاصة فقط _~!{'@'}#$%^&*-. مكون",
  "ccmessaging.chat.multimedia.emoticonsManagement": "التعبيرات",
  "ccmessaging.chat.chatemail.sender": "المرسل:",
  "ccmessaging.chat.chatpersonalization.save": "حفظ",
  "cmessaging.chat.answerUser.Tips": "إنشاء جلسة مستخدم",
  "ccmessaging.chat.chatcard.mediumHeight": "ارتفاع متوسط",
  "ccmessaging.chat.multi.card.num.error": "إضافة 1 إلى 5 بطاقات مفردة.",
  "ccmessaging.chat.channelconfig.baseConfig": "التكوين الأساسي",
  "ccmessaging.chatbot.label.pleaseInput": "يُرجى إدخال",
  "chat.client.greeting.afternoon": "مساء الخير!",
  "ccmessgaing.postChannelConfig.edit": "تحرير",
  "ccmessaging.socialPostOperation.button.channelConfig": "إعدادات القناة",
  "ccmessaging.chat.template.buttonSend": "إرسال",
  "ccmessaging.chat.agentconsole.spellcheck.correctly.writing": "مكتوبة بشكل صحيح",
  "ccmessaging.chat.channelconfig.email.signature.desc": "الوصف",
  "ccmessaging.ruleconfig.duplicateName": "اسم القاعدة مكرر.",
  "ccmessaging.emailcatalog.canNotAdd": "يسمح بحد أقصى مستويين من الدلائل.",
  "ccmessaging.flowControl.periodType.time": "التخصيص",
  "ccmessaging.chat.chatholiday.date": "التاريخ",
  "ccmessaging.chat.phrase.update": "تحرير العبارات الشائعة",
  "ccmessaging.chat.agentconsole.label.ongoingsession": "لا توجد جلسة مستمرة حالياً!",
  "ccmessaging.chat.multimedia.richTextContentSuperLong": "يمكن أن يحتوي النص المنسق على 5000 حرف كحد أقصى.",
  "ccmessaging.chat.web.agent.assist": "وكيل مساعد",
  "ccmessaging.socialPostOperation.youTube.category.pets": "الحيوانات الأليفة والحيوانات",
  "ccmessaging.chatbot.label.welcomeMessage": "تحية",
  "ccmessaging.chat.channelconfig.chatbotAddress": "عنوان Chatbot",
  "ccmessgaing.postChannelConfig.operation.person": "موظفو العمليات",
  "ccmessaging.socialPostOperation.youTube.category.travel": "السفر والمناسبات",
  "ccmessaging.chat.speciallist.selectchannel": "القناة المعينة",
  "ccmessaging.emailcatalog.duplicateName": "الاسم موجود بالفعل. استخدم اسماً آخر",
  "ccmessaging.chat.channelconfig.submitVerification": "تعليمات الدمج",
  "ccmessaging.chatbot.label.save": "حفظ",
  "ccmessaging.flowControl.selectStop": "قم بتعطيل تكوين التحكم في التدفق أولاً.",
  "ccmessaging.chat.chatholiday.monday": "الاثنين",
  "ccmessgaing.postChannelConfig.maxLength": "الحد الأقصى للطول هو {n} أحرف",
  "ccmessaging.chat.workbench.transfer.skilllogin": "الوكيل المتوفر",
  "ccmessaging.chat.chatmanage.startTime": "وقت البدء",
  "ccmessaging.ruleconfig.effective": "فعال",
  "ccmessaging.chat.multimedia.viewRichText": "عرض النص الغني",
  "ccmessaging.chat.chatholiday.noworking": "غير يوم العمل",
  "chat.confirm.ok": "حسناً",
  "ccmessaging.socialPostOperation.res.recall": "تم الانسحاب بنجاح",
  "ccmessaging.chat.multimedia.whatsAppTemplate": "قالب WhatsApp",
  "ccmessaging.chat.channelconfig.email.address.limit": "أرقام",
  "ccmessaging.emailcatalog.modify": "تعديل الدليل",
  "ccmessaging.ruleconfig.maxEmailAddressCount": "يمكن إدخال 10 عناوين بريد إلكتروني كحد أقصى في المحتوى",
  "chat.agent.label.consumption": "استهلاكي",
  "ccmessaging.chat.workbench.transfer.skillname": "اسم قائمة انتظار المهارة",
  "ccmessaging.multimedialibrary.tips": "يتم استخدام هذا العنوان بواسطة الوكيل لإرساله إلى المستخدم للتعاون في صفحة الويب. قم بتكوينه بشكل صحيح.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed.front": "طول النص المراد التحقق منه يتجاوز 1024.",
  "ccmessaging.chat.chatholiday.calendar.preview": "معاينة التقويم",
  "chat.confirm.no": "لا",
  "ccmessaging.chat.agentconsole.quoteReply": "اقتبس هذا الرد",
  "ccmessaging.flowControl.alarmInterval.unit": "دقيقة",
  "chat.session.label.created_endTime": "وقت الانتهاء",
  "ccmessgaing.postChannelConfig.channelCreate": "إنشاء قناة",
  "ccmesaging.chat.cobrowse.isAcceptWeb": "هل تريد بالتأكيد قبول دعوة الاستعراض المشترك؟",
  "ccmessaging.chat.whatsapp.templateBodyParamDesc": "وصف المعلمة",
  "ccmessaging.chat.agentconsole.software": "بيئة البرمجيات",
  "ccmessaging.chat.multimedia.categoryName": "اسم الفئة",
  "ccmessaging.chat.email.tips.addSuccess": "تمت الإضافة بنجاح.",
  "chat.client.greeting.morning": "صباح الخير!",
  "ccmessaging.chat.channelconfig.beforhalf": "يمكنك النقر فوق الزر \"جربها\" لرؤية تأثير التكامل. (إذا كان عنوان URL صحيحًا وتظهر علامة اعتراض في الزاوية العلوية اليمنى من المتصفح",
  "ccmessaging.ruleconfig.emailChannel": "قناة البريد الإلكتروني",
  "ccmessaging.chat.async.statistics.asynUserTotal": "إجمالي عدد المستخدمين غير المتصلين",
  "ccmessaging.chat.agentconsole.talkNum": "مكالمة اليوم تهم:",
  "ccmessaging.chatbot.label.useRobotAssistants": "الوصول إلى مساعد الروبوت",
  "chat.common.message.error": "خطأ",
  "ccmessaging.socialPostOperation.youTube.disclosureScope": "النطاق العام",
  "ccmessaging.chat.agentconsole.contract.upload.failTip": "فشل إيداع العقد.",
  "chat.usefulexpressions.message.createFail": "فشل إنشاء العبارة العامة.",
  "ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum": "رقم الجوال غير صالح",
  "ccmessaging.chat.close": "ثواني بعد إغلاق الصفحة تلقائيًا",
  "ccmessaging.chat.sumbitverification.tips": "يُرجى الانتقال إلى معلومات تكوين واجهة النظام الأساسي العام WeChat للحصول على عنوان URL للتكوين ورمز التحقق",
  "ccmessaging.chat.multimediaasccociation.approvalstatus": "مراجعة الحالة",
  "ccmessaging.chat.channelconfig.infoConfiguration": "تكوين المعلومات",
  "ccmessaging.flowControl.enable": "تمكين",
  "ccmessaging.chat.offlineCall.channelType": "نوع القناة",
  "ccmessaging.chat.chatholiday.designation.cancel": "إلغاء",
  "ccmessaging.fiveG.label.certCode": "ملف الشهادة",
  "ccmessaging.chat.chatpersonalization.nickname": "اسم الشهرة",
  "ccmessaging.chat.multi.chatcard.sort.flow": "يطفو",
  "ccmessaging.pop.org.window.noorg": "بدون OU",
  "ccmessaging.chat.silentagent.config.del.success": "تم حذف تكوين موظف الصوت الصامت بنجاح.",
  "ccmessaging.chatbot.label.avatar": "الصورة الرمزية",
  "chat.webconfig.label.greeting.placeholder": "الرجاء إدخال محتوى رسالة التحية المعروضة في نافذة الدردشة الخاصة بالعميل.",
  "ccmessaging.chat.richText.nameInput": "أدخل اسمًا.",
  "ccmessaging.silentagent.placeholder.selectaudioskill": "حدد قائمة انتظار مهارات الصوت.",
  "ccmessaging.chat.chatholiday.designation.alert": "يجب أن يكون تاريخ الانتهاء بعد تاريخ البدء",
  "ccmessaging.chatbot.check.systeam": "نظام",
  "ccmessaging.char.agentstatus.after.callends": "\u062A\u0635\u0628\u062D \u0623\u0648\u0644\u0648\u064A\u0629 \u0627\u0639\u062F\u0627\u062F\u0627\u062A \u062D\u0627\u0644\u0629 \u0627\u0644\u0645\u0648\u0638\u0641 \u0646\u0627\u0641\u0630\u0629 \u0627\u0644\u0645\u0641\u0639\u0648\u0644 \u0628\u0639\u062F \u0627\u0646\u062A\u0647\u0627\u0621 \u0627\u0644\u0645\u0643\u0627\u0644\u0645\u0629 \u0643\u0645\u0627 \u064A\u0644\u064A: \u0645\u0633\u062A\u0648\u0649 \u0627\u0644\u0645\u0624\u0633\u0633\u0629 > \u0645\u0633\u062A\u0648\u0649 \u0627\u0644\u0645\u0633\u062A\u0623\u062C\u0631. \u0641\u064A \u062D\u0627\u0644\u0629 \u062A\u0631\u0643 \u0647\u0630\u0647 \u0627\u0644\u062D\u062F\u0648\u062F \u0641\u0627\u0631\u063A\u0629\u060C \u0644\u0627 \u064A\u0633\u0631\u064A \u0645\u0641\u0639\u0648\u0644 \u0627\u0644\u0627\u0639\u062F\u0627\u062F\u0627\u062A \u0648\u064A\u062A\u0645 \u0627\u0633\u062A\u062E\u062F\u0627\u0645 \u0627\u0644\u0627\u0639\u062F\u0627\u062F\u0627\u062A \u0639\u0644\u0649 \u0645\u0633\u062A\u0648\u0649 \u0627\u0644\u0645\u0633\u062A\u0623\u062C\u0631.",
  "ccmessaging.chat.chatconfig.selectchannelAccessCode": "حدد تكوين القناة.",
  "chat.weChatConfig.label.search": "البحث",
  "ccmessaging.chat.agentconsole.replyEmail": "رد البريد الإلكتروني",
  "ccmessaging.chat.multimedia.multiMediaTypeIdNotExist": "فئة مكتبة الوسائط المتعددة المحددة غير موجودة، يرجى تحديد فئة مكتبة الوسائط المتعددة!",
  "chat.skill.label.skillName": "الاسم",
  "ccmessaging.chat.chatcard.mediaType": "نوع الوسائط",
  "ccmessaging.socialPostOperation.field.remark": "ملاحظات",
  "ccmessaging.chat.agentconsole.beyondLimitNum": "يتجاوز عدد الرسائل المرسلة الحد الأعلى.",
  "ccmessaging.chat.chatholiday.weekly": "أسبوعي",
  "ccmessaging.chat.whatsapp.templateButtonName": "اسم زر القالب",
  "ccmessgaing.postChannelConfig.createSuccess": "نجح في إنشاء معلومات التكوين لقناة الوسائط المتعددة الاجتماعية.",
  "ccmessaging.socialPostOperation.field.title": "الموضوع",
  "ccmessaging.emailcatalog.pleaseSelectCatalog": "حدد دليلًا أولاً.",
  "ccmessaging.chat.channelconfig.email.bccLimit": "الحد الأقصى من المستلمين الذي تم إرسال نسخ مخفية إليهم",
  "ccmessaging.ruleconfig.emailAdress": "عنوان البريد الإلكتروني",
  "ccmessaging.chat.agentconsole.sendLocalFile": "إرسال الملفات المحلية",
  "ccmessaging.chat.facebookpageconfig.deleteError": "فشل حذف إعدادات الصفحة الرئيسية لفيسبوك.",
  "ccmessaging.flowControl.periodValue": "يوم",
  "ccmessaging.chat.multimedia.fileUploadLabel": "الرجاء تحديد ملف لتحميله",
  "ccmessaging.chat.agentconsole.label.NoVideoDuration": "فشل الحصول على مدة الفيديو، يُرجى التحميل مرة أخرى!",
  "ccmesaging.chat.agentconsole.label.seeMore": "عرض المزيد",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime": "فترة مهملة رد العميل المتصل",
  "ccmessaging.chat.phrase.enterPhraseType": "الرجاء إدخال نوع عبارة عامة",
  "ccmessaging.chat.chatemail.forwarded": "إعادة توجيه",
  "ccmessaging.chat.email.check.uploadATTACHMENTPermissionAndFeature": "لا يوجد إذن لتحميل المرفقات، أو لم يتم تمكين هذه الوظيفة.",
  "ccmessaging.chat.phrase.phraseRepeat": "اسم مشترك مكرر",
  "ccmessaging.chat.multimedia.audioUploadChannelFormat": "تدعم قناة WhatsApp تنسيق ACC.\nتدعم قناة ويب تنسيقات MP3 وAAC.\nتدعم قناتي Instagram وLINE تنسيق M4A.\nتدعم قناتي WeChat وFacebook تنسيق MP3.\n تدعم قناة Telegram تنسيقات MP3 و M4A.",
  "ccmessaging.ruleconfig.invalidEmailAddress": "عنوان بريد إلكتروني غير صالح",
  "ccmessaging.chat.channelconfig.apifabric.ak": "مفتاح APP",
  "ccmessaging.chat.phrase.editType": "تحرير النوع",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentNull": "لا يُسمح بإرسال محتوى فارغ!",
  "ccmessaging.chat.channelconfig.checkSocialChatNum": "لا يمكن أن تحتوي على الكسور العشرية",
  "ccmessaging.socialPostOperation.button.batchDelete": "حذف الحزمة",
  "ccmessaging.chat.agentconsole.chatPhrase": "عبارات شائعة",
  "ccmessaging.cobrowsesite.protocol.check": "يتم استخدام بروتوكولات غير آمنة\، مما يشكل مخاطر أمنية.",
  "ccmessaging.chat.chatcard.cardTitle": "عنوان",
  "ccmessaging.chat.channelconfig.offToday": "في اليوم الحالي",
  "ccmessaging.chat.whatsapp.template.dailyLimitDescription": "الرجاء إدخال عدد المرات التي يتوفر فيها القالب في اليوم.",
  "ccmessgaing.postChannelConfig.failed": "فشل",
  "ccmessaging.socialPostOperation.youTube.permissions": "الرخصة",
  "ccmessaging.chat.silentagent.sendContent.noOIAPNode": "تعذر على \"العامل الصامت\" الحصول على معلومات العقدة الحالية!",
  "ccmessaging.message.sendstatus.deliverable": "تم الإرسال",
  "chat.session.label.alias": "اسم العميل",
  "ccmessaging.socialPostOperation.field.applicationType": "نوع التطبيق",
  "ccmessaging.chat.channelconfig.chooseChannel": "تحديد القناة",
  "ccmessaging.flowControl.period.select": "تحديد الفترة",
  "ccmessaging.chat.repeatClick": "لا تنقر بشكل متكرر!",
  "ccmessaging.chat.webCard": "البطاقاتWeb",
  "ccmessaging.chat.multimedia.saveMultiMediaTypeError": "فشل حفظ فئة مكتبة الوسائط المتعددة!",
  "ccmessaging.chat.channelconfig.pleaseSelect": "من فضلك اختر...",
  "ccmessaging.chat.multimedia.modifyMultiMediaTypeError": "فشل تعديل فئة مكتبة الوسائط المتعددة!",
  "ccmessaging.chatbot.label.selectRobotAssistants": "تحديد مساعد الروبوت",
  "chat.weChatConfig.message.deleteError": "فشل حذف عنصر التكوين.",
  "chat.weChatConfig.message.savesuccess": "تم الوصول بنجاح.",
  "ccmessaging.chat.social.media.enterprise.post.comment.tips": "إضافة تعليق...",
  "chat.client.alert.message.invildtoken": "انتهت مهلة الحوار أو قمت بتسجيل الدخول في مكان آخر. يُرجى إغلاق نافذة الدردشة والمحاولة مرة أخرى. شكرًا لك!",
  "ccmessaging.chat.phrase.updatePhraseTypeError": "فشل تحديث نوع العبارة العامة.",
  "ccmessaging.chat.phrase.addPhraseError": "فشل إنشاء عبارة عامة",
  "ccmessaging.chat.whatsapp.footer": "تذييل",
  "ccmessaging.chat.agentconsole.talkavg": "متوسط مدة المكالمة (بالثانية):",
  "ccmessaging.chat.whatsapp.templateButton.clicktoAction": "الإجراء",
  "ccmesaging.chat.cobrowse.webSitePlaceholder": "أدخل عنوان URL الخاص بالمشاركة.",
  "ccmessaging.chat.multimedia.documentUploadChannelFormat": "تدعم قناة WhatsApp تنسيقات DOC و DOCX و PDF و PPT و PPTX و XLSX و XLS.\nتدعم قناة الويب تنسيقات DOC و DOCX و PDF و وتنسيقات ZIP.\nتدعم قناة Telegram تنسيقات PDF وZIP.",
  "ccmessaging.flowControl.targetId": "كائن التحكم في التدفق",
  "ccmessaging.chat.multi.media.resource.invalid": "البطاقات التي تشير إلى موارد وسائط غير صالحة. اسم البطاقة:",
  "ccmessaging.smsNumber.contentNotNull": "لا يمكن أن يكون رقم الجوال فارغًا.",
  "ccmessaging.chat.channelconfig.scenario.status.existent": "مفتاح السيناريو: نعم",
  "ccmessaging.chat.whatsapp.templateFooterTextPlaceholder": "أدخل محتوى نص التذييل.",
  "chat.webconfig.label.language.zh": "الصينية",
  "chat.client.label.forgotPassword": "نسيت كلمة المرور",
  "ccmessaging.chat.agentconsole.locate": "الموقع",
  "chat.weChatConfig.link.wechat": "خادم WeChat",
  "ccmessaging.chat.template.card": "رسالة قالب WhatsApp",
  "cmessaging.chat.multimedia.numOverMaxSum": "كمية بيانات الوسائط المتعددة التي قمت بحفظها تتجاوز الحد",
  "cmessaging.chat.multimediatype.numOverMaxSum": "لقد قمت بحفظ أكثر من الحد الأقصى لعدد الفئات",
  "ccmessaging.flowControl.deleteFail": "فشل حذف تكوين التحكم في التدفق.",
  "chat.client.label.more": "المزيد",
  "ccmessaging.flowControl.updateSuccess": "تم تحديث تكوين التحكم في التدفق بنجاح.",
  "ccmessaging.chat.agentconsole.upload": "تحميل ملف",
  "ccmessaging.socialPostOperation.youTube.category.people": "الأشخاص والمدونات",
  "ccmessaging.chat.tinymce.button.clear": "مسح",
  "ccmessaging.chat.phrase.phrase": "عبارة",
  "ccmessaging.chat.agentconsole.defaultOrgName": "منظمة غير منتسبة",
  "chat.skill.message.willDeleteSkills": "هل تريد بالتأكيد حذف المهارات المحددة؟",
  "ccmessaging.chat.template.Description": "الوصف",
  "ccmessaging.chat.agentconsole.promptConetnt.userNoArrive": "لا يوجد مستخدم متصل، أو لم يتم تحديد أي مستخدم!",
  "ccmessaging.service.time.close.alert": "إغلاق سوف يمحو جميع البيانات في الجدول، تأكيد إغلاق",
  "ccmessaging.chat.email.channel.savedraftsuccess": "تم حفظ البريد الإلكتروني كمسودة بنجاح.",
  "chat.skill.label.maxQueueNumber": "الحد الأقصى لرقم قائمة الانتظار",
  "ccmessaging.chat.whatsapp.languagePTPT": "البرتغالية (البرتغال)",
  "ccmessaging.chat.agentconsole.nowconversation": "المحادثة الحالية",
  "chat.skill.label.account": "الحساب",
  "chat.weChatConfig.label.Domain": "عنوان الخادم",
  "chat.timerange.label.threedays": "آخر 3 أيام",
  "ccmessaging.message.sendstatus.pending": "الإنتظار",
  "ccmessaging.chat.chatcard.cancel": "إلغاء",
  "ccmessaging.chat.multi.chatcard.sort.horizontal": "التسوية",
  "ccmessaging.chatbot.label.selectIntelligentRobot": "اختر روبوت ذكي",
  "ccmessaging.chat.chatcard.messageTemplateDelete": "حذف قالب",
  "ccmessaging.chat.agentconsole.promptConetnt.operateRollBackFail": "الرسائل التي تم إرسالها لأكثر من دقيقتين لا يمكن سحبها",
  "chat.client.label.download": "تحميل",
  "ccmessaging.chat.chatcard.deleteLimit": "تمت الإشارة إلى القالب ولا يمكن حذفه.",
  "chat.agent.title.createSkill": "إنشاء مهارة",
  "ccmessaging.chat.email.localImage.lable": "ملف",
  "ccmessgaing.postChannelConfig.users.overSize": "ويتجاوز عدد أفراد العملية 10 أفراد.",
  "ccmessaging.socialPostOperation.button.upload": "تحميل",
  "ccmessaging.chat.chatholiday.designation.complete": "مكتمل",
  "ccmessaging.chat.multimedia.longitude": "خط الطول",
  "chat.webconfig.label.language": "اللغة الافتراضية",
  "ccmessaging.chat.email.check.uploadImagePermissionAndFeature": "لا يوجد إذن لتحميل الصور المحلية، أو لم يتم تمكين هذه الوظيفة.",
  "ccmessaging.chat.sumbitverification.whatsApp.cloudapi.tips": "انتقل إلى صفحة WhatsApp في ميتا للمطورين لتكوين عنوان URL ورمز التحقق.",
  "ccmessaging.chat.chatmanage.autoEndSessionnotnull": "لا يمكن أن يكون وصف الفاصل الزمني لعدم استجابة العميل فارغًا.",
  "ccmessaging.chat.multiMedia.fileUploadError": "يحتوي الملف الذي تم تحميله على تنسيق خاطئ",
  "chat.client.message.noAgent": "عذرا، الوكيل مشغول. حاول مرة أخرى لاحقًا.",
  "ccmessaging.chat.facebookpageconfig.add": "إضافة صفحة الفيسبوك الرئيسية",
  "ccmessaging.chat.phrase.isDeletePhraseType": "هل تريد بالتأكيد حذف نوع العبارة العامة والعبارات العامة الخاصة بها؟",
  "chat.client.label.no": "لا",
  "ccmessaging.chat.offlineSwitch.closed": "فشل الإرسال بسبب تعطيل وظيفة الرسائل دون اتصال للقناة.",
  "chat.webconfig.message.demodirective": "يمكنك أيضًا فتح العنوان التالي في النافذة المخفية لمتصفح Chrome للوصول إلى صفحة العرض التقديمي التي يوفرها النظام:",
  "chat.usefulexpressions.label.expressionDetail": "تفاصيل العبارة العامة",
  "ccmessaging.chat.chatholiday.isDelete.specified": "هل تريد بالتأكيد حذف هذا التاريخ المحدد؟",
  "chat.weChatConfig.label.wechat": "WeChat",
  "ccmessaging.chat.web.channel.called.config": "تكوين الطرف المتصل بـ CTD",
  "ccmessaging.ruleconfig.SMSNumber": "رقم الجوال",
  "ccmessaging.chat.channelconfig.turnArtificial.tip": "مفصولة بفواصل منقوطة صينية أو إنجليزية",
  "ccmessaging.chat.channelconfig.asyncTime.tip": "إذا تجاوزت الفترة، التي تأتي عقب تشغيل سيناريو عدم الاتصال الإنترنت، هذه القيمة، فستُخزَّن رسائل العملاء غير المتصلين بالإنترنت مباشرة كما لن يتم توجيهها إلى الوكلاء بعد الآن.",
  "ccmessaging.chat.channelconfig.noReplyTime.tip": "إذا تجاوزت الفترة، التي تأتي عقب آخر مرة اتصل فيها العميل بالوكيل، هذه القيمة، فلن يتمكن الوكيل من إجراء مكالمة صادرة إلى العميل.",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime.tip": "ي سيناريو المكالمات الصادرة، إذا كان العميل لا يرد في غضون هذه الفترة، فإن الجلسة ستنتهي تلقائيًا.",
  "ccmessaging.chat.multimedia.deleteLimit.usedbyODFS": "لا يمكن حذف هذا المورد لأنه تمت الإشارة إليه بواسطة IVR الذكي",
  "ccmessaging.chat.search.phraseName": "البحث عن عبارات شائعة",
  "ccmessaging.chat.search.name": "اسم البحث",
  "ccmessaging.chat.chatcard.templateType": "نوع القالب",
  "ccmessaging.chat.chatcard.templateTypeList0": "نوع عادي",
  "ccmessaging.chat.chatcard.templateTypeList1": "خيارات البطاقة",
  "ccmessaging.chat.chatcard.alignment": "نمط عرض البطاقة",
  "ccmessaging.chat.chatcard.transverse": "أفقي",
  "ccmessaging.chat.chatcard.longitudinal": "عمودي",
  "ccmessaging.chat.chatcard.tabMediaResource": "الموارد الإعلامية للصور",
  "ccmessaging.chat.chatcard.location": "الموقع",
  "ccmessaging.chat.chatcard.nameExist": "الاسم موجود بالفعل. الرجاء تعديله",
  "ccmessaging.chat.emailmessage.sendStatus": "إرسال النتائج",
  "ccmessaging.ruleconfig.duplicateEmailAddress": "عنوان بريد إلكتروني مكرر",
  "ccmessaging.chat.richtext.httpTip": "الارتباط الذي تم إدراجه يستخدم بروتوكولًا غير آمن. هناك مخاطر أمنية. هل تريد إرساله؟",
  "ccmessaging.chat.riskTip": "تحذير من المخاطر",
  "ccmessaging.chat.channelconfig.hrefUrl.not.configure.trustList": "عنوان الارتباط التشعبي غير موجود في القائمة البيضاء. الرجاء الاتصال بمسؤول النظام",
  "ccmessaging.chat.chatconfig.deleteFacebookPage": "حذف الصفحة الرئيسية لـ Facebook",
  "ccmessaging.chat.email.attachment.tips.maxOnceUpload": "يمكن تحميل خمسة مرفقات كحد أقصى.",
  "ccmessaging.chat.email.attachment.tips.maxTotalUpload": "عدد المرفقات التي تم تحميلها يتجاوز 10.",
  "cmessaging.chat.multimedia.agentLogoutHangupResult": " إجبار الخروج ، الدورة انتهت ، يرجى إغلاق وظيفة إدارة",
  "ccmessaging.chat.voice.record.tip": "مدة التسجيل لا تزيد عن 10 دقائق",
  "ccmessaging.chat.voice.record.stop": "إيقاف التسجيل",
  "ccmessaging.chat.voice.record.start": "اضغط على  للتسجيل",
  "ccmessaging.chat.voice.record.send": "إرسال صوت",
  "ccmessaging.chat.dy.uploadLocalFile": "الملفات المحلية",
  "ccmessaging.ruleconfig.duplicateSmsAddress": "رقم جوال مكرر",
  "ccmessaging.chat.voice.record": "سجل",
  "ccmessaging.chat.multimedia.categorizeEmpty": "يُرجى اختيار تصنيف أو إنشاء تصنيف أولاً",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.usedbyODFS": "فشل حذف فئة مكتبة الوسائط المتعددة!يوجد مورد مشار إليه بواسطة IVR الذكي!",
  "ccmessaging.chat.offline.userIsInService": "يوجد مستخدمون خدمات المقاعد",
  "ccmessaging.chat.channelconfig.search.by.robotNameKeyword": "البحث عن اسم الروبوت أو رمز الوصول",
  "ccmessaging.chat.channelconfig.searchSkillQueueName": "البحث عن اسم قائمة انتظار المهارة",
  "ccmessaging.chat.user.online": "المستخدم على الانترنت.",
  "ccmessaging.chat.async.echarts.user": "عدد الأشخاص (مرات)",
  "ccmessaging.chat.async.echarts.message": "الرسائل (عدد)",
  "cobrowse.sessionrecord.roomId": "رقم الغرفة",
  "cobrowse.sessionrecord.searchroomId": "يرجإدخال رقم الغرفة",
  "cobrowse.sessionrecord.workNo": "رقم الوكيل",
  "cobrowse.sessionrecord.searchworkNo": "الرجاء إدخال رقم الموظف",
  "cobrowse.sessionrecord.caller": "المتصل",
  "cobrowse.sessionrecord.searchcaller": "الرجاء إدخال معلومات المتصل",
  "cobrowse.sessionrecord.startAt": "وقت البدء",
  "cobrowse.sessionrecord.endAt": "وقت الانتهاء",
  "cobrowse.sessionrecord.duration": "المدة",
  "cobrowse.sessionrecord.positiveInteger.checkmsg": "أدخل عددًا صحيحًا موجبًا",
  "cobrowse.sensitiveinfo.pageMatchValue": "اعدادات تطابق الصفحة",
  "cobrowse.sensitiveinfo.rulenamecheck": "يمكن أن يحتوي اسم القاعدة على أحرف وأرقام وعلامات سفلية (_), ونقاط (.). يحتوي اسم القاعدة على 128 حرفًا كحد أقصى.",
  "cobrowse.sensitiveinfo.url.check": "تنسيق مسار URL الذي تم تكوينه لمطابقة الصفحة غير صحيح",
  "cobrowse.sensitiveinfo.cssselectorcheck": "CSS خطأ في بناء جملة محدد",
  "cobrowse.sensitiveinfo.pageIdCheck": "يمكن أن يحتوي معرف الصفحة على أحرف وأرقام و_-: فقط. وبحد أقصى 1024 حرفًا.",
  "cobrowse.sensitiveinfo.create": "إنشاء قاعدة معلومات حساسة جديدة",
  "cobrowse.sensitiveinfo.edit": "تحرير قاعدة معلومات حساسة جديدة",
  "cobrowse.sensitiveinfo.createnameexist": "الاسم موجود بالفعل",
  "cobrowse.sensitiveinfo.exceedmaxcount": "يمكن اعدادات ما يصل إلى 100 قاعدة حساسة لكل مستأجر",
  "cobrowse.sensitiveinfo.delete": "=حذف قاعدة المعلومات الحساسة",
  "cobrowse.sensitiveinfo.willdeletetips": "هل تريد بالتأكيد حذف قاعدة المعلومات الحساسة المحددة؟",
  "cobrowse.sensitiveinfo.deletenum.outstripping": "يمكن حذف 100 سجل كحد أقصى في المرة الواحدة",
  "cobrowse.common.tips.selectempty": "يُرجى تحديد البيانات التي سيتم تشغيلها أولاً",
  "cobrowse.sessionrecord.workNo.rules": "يجب أن يكون في النطاق من 1 إلى 65535",
  "ccmessaging.chat.chatconfig.batch.delete": "حذف دفعة",
  "ccmessaging.chat.chatconfig.create": "جديد",
  "ccmessaging.channelconfig.export.datascope": "نطاق البيانات",
  "ccmessaging.channelconfig.export.allscope": "الكل",
  "ccmessaging.channelconfig.export.curPage": "الصفحة الحالية",
  "ccmessaging.channelconfig.export.customField": "حقل مخصص",
  "ccmessaging.channelconfig.export.selectedField": "حقل التصدير",
  "ccmessaging.channelconfig.export.selectAll": "الكل",
  "ccmessaging.channelconfig.export.zipPwd": "كلمة مرور مضغوطة",
  "ccmessaging.channelconfig.export.zipPwdHint": "الرجاء إدخال كلمة مرور الضغط",
  'ccmessaging.channelconfig.export.pwdMsg': "كلمة المرور لإنشاء ملف مضغوط. القيمة عبارة عن سلسلة من 8 إلى 12 حرفًا ويجب أن تحتوي على أحرف وأرقام وأحرف خاصة (باستثناء مسافات {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, وأحرف السطر الجديد وأحرف الجدولة ).",
  'ccmessaging.channelconfig.export.pwdMsgError': "القيمة عبارة عن سلسلة من 8 إلى 12 حرفًا ويجب أن تحتوي على أحرف وأرقام وأحرف خاصة (باستثناء مسافات {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, وأحرف السطر الجديد وأحرف الجدولة).",
  "ccmessaging.channelconfig.export": "تصدير",
  "ccmessaging.channelconfig.viewExportTask": "تصدير عرض المهمة",
  "ccmessaging.channelconfig.export.title": "تصدير تكوين القناة",
  'ccmessaging.channelconfig.export.exportAll': 'لم تقم بتحديد سجلات معينة. هل تريد تصدير كافة السجلات؟ يمكنك أيضًا إلغاء العملية وتحديد سجل معين للتصدير.',
  'ccmessaging.channelconfig.export.exportAllTitle': "تصدير كافة التكوينات",
  "ccmessaging.channelconfig.export.allExportConfirm": "جميع التصدير",
  'ccmessaging.channelconfig.export.exportSelect': "لقد حددت {0} سجلات. هل أنت متأكد من رغبتك في المتابعة؟",
  "ccmessaging.channelconfig.export.exportBeginTime": "وقت بدء التصدير",
  "ccmessaging.channelconfig.export.exportEndTime": "وقت انتهاء التصدير",
  "ccmessaging.channelconfig.export.exportTaskStatus": "الحالة",
  "ccmessaging.channelconfig.export.exporting": "تصدير",
  "ccmessaging.channelconfig.export.unprocessed": "غير المجهزة",
  "ccmessaging.channelconfig.export.resultMsg": "تم إنشاء مهمة التصدير بنجاح. هل تريد الانتقال إلى قائمة التصدير لعرضها؟",
  "ccmessaging.channelconfig.export.resultSuccess": "تم الإنشاء بنجاح.",
  "ccmessaging.channelconfig.export.exception": "حدث خطأ عند إنشاء مهمة التصدير. تحقق من السجلات.",
  "ccmessaging.channelconfig.export.error": "فشل تصدير مهمة جديدة\، يُرجى التحقق من السجل",
  "ccmessaging.channelconfig.export.paramInvalid": "فشل التحقق من شروط التصدير.",
  "ccmessaging.channelconfig.export.exceedlimit": "يمكن تصدير {0} كحد أقصى سجلات البيانات",
  "ccmessaging.channelconfig.export.nodata": "لا يوجد تكوين قناة يفي بالشروط.",
  "ccmessaging.chat.holiday.deleteWorkDay": "حذف تواريخ العمل المحددة",
  "ccmessaging.chat.holiday.deleteAllWorkDay": "حذف كافة تواريخ العمل المحددة",
  "ccmessaging.chat.holiday.deleteHoliday": "حذف تاريخ الراحة المحدد",
  "ccmessaging.chat.holiday.deleteAllHoliday": "حذف كل تواريخ الراحة المحددة",
  "ccmessaging.multimedia.leftMenu.category": 'الفئة',
  "ccmessaging.multimedia.leftMenu.createCategory": 'إنشاء فئة',
  "ccmessaging.multimedia.leftMenu.editCategory": 'تحرير فئة',
  "ccmessaging.multimedia.audio.create": 'إنشاء صوت',
  "ccmessaging.multimedia.audio.edit": 'تحرير الصوت',
  "ccmessaging.multimedia.video.create": 'إنشاء فيديو',
  "ccmessaging.multimedia.video.edit": 'تحرير الفيديو',
  "ccmessaging.multimedia.image.create": 'إنشاء صورة',
  "ccmessaging.multimedia.image.edit": 'تحرير صورة',
  "ccmessaging.multimedia.emoticon.create": 'إنشاء رمز تعبيري',
  "ccmessaging.multimedia.emoticon.edit": 'تحرير الرموز',
  "ccmessaging.multimedia.document.create": 'إنشاء مستند',
  "ccmessaging.multimedia.document.edit": 'تحرير مستند',
  "ccmessaging.multimedia.richText.create": 'إنشاء نص غني',
  "ccmessaging.multimedia.richText.edit": 'تحرير نص غني',
  "ccmessaging.multimedia.local.create": 'إنشاء عنوان',
  "ccmessaging.multimedia.local.edit": 'تحرير عنوان',
  'ccmessaging.multimedia.whatsAppTemplate.create': 'إنشاء قالب WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplate.edit': 'تحرير قالب WhatsApp',
  "ccmessaging.multimedia.siteAddr.create": 'إنشاء شركة مراجعة موقع ويب',
  "ccmessaging.multimedia.siteAddr.edit": 'تحرير المشاركة في استعراض الموقع',
  "ccmessaging.multimedia.cardFiveG.create": 'إنشاء بطاقة الجيل الخامس',
  "ccmessaging.multimedia.cardFiveG.edit": 'تحرير بطاقة الجيل الخامس',
  "ccmessaging.multimedia.carouselCardFiveG.create": 'إنشاء بطاقة دائرية من الجيل الخامس',
  "ccmessaging.multimedia.carouselCardFiveG.edit": 'تحرير بطاقة دائرية من الجيل الخامس',
  'ccmessaging.multimedia.whatsAppTemplateMsg.create': 'إنشاء رسالة قالب WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplateMsg.edit': 'تحرير رسالة قالب WhatsApp',
  "ccmessaging.multimedia.cardWeb.create": 'إنشاء بطاقة ويب',
  "ccmessaging.multimedia.cardWeb.edit": 'تحرير بطاقة ويب',
  "chat.agent.qualityCheckWorkbench.title": "فحص الجودة",
  "chat.agent.qualityCheckWorkbench.currentAgent": "مقعد المراقبة الحالية",
  "chat.agent.qualityCheckWorkbench.subscribeSessionFailed": "فشل الاشتراك وكيل الدورة",
  "chat.agent.qualityCheckWorkbench.cancelSubscribeSessionFailed": "فشل إلغاء الاشتراك وكيل الدورة",
  "chat.agent.qualityCheckWorkbench.pollMsgFailed": "فشل في الحصول على مقعد من المعلومات",
  "chat.agent.qualityCheckWorkbench.closeConfirm": "يرجى التأكد من أن دورة الوسائط المتعددة فحص الجودة في المقعد الحالي قد انتهت ؟",
  "chat.agent.qualityCheckWorkbench.listen": "إلغاء إدراج",
  "chat.agent.qualityCheckWorkbench.insert": "إدراج",
  "chat.agent.qualityCheckWorkbench.intercept": "اعتراض",
  "chat.agent.qualityCheckWorkbench.changeQualityStatus.fail": "فشل تعديل مركز مراقبة الجودة",
  "chat.agent.msg.redis.connection.exception.description": "عطل في النظام الحالي، يُرجى الانتظار",
  "ccmessaging.chat.phrase.editPhraseTypeFail": "فشل تحرير نوع العبارة العامة",
  "ccmessaging.chat.phrase.editPhraseTypeSucess": "تم تحرير نوع العبارة العامة بنجاح",
  "ccmessaging.chat.phrase.editPhraseFail": "فشل تحرير العبارة العامة",
  "ccmessaging.chat.phrase.editPhraseSucess": "تم تحرير العبارة العامة بنجاح",
  "ccmessaging.chat.multimedia.editMultiMediaInfo": "تم تحرير بيانات الوسائط المتعددة بنجاح",
  "ccmessaging.chat.multimedia.editMultiMediaInfoError": "فشل تحرير بيانات الوسائط المتعددة",
  "ccmessaging.chat.multimedia.editMultiMediaType": "تم تحرير نوع الوسائط المتعددة بنجاح",
  "ccmessaging.chat.multimedia.editMultiMediaTypeError": "فشل تحرير نوع الوسائط المتعددة",
  "ccmessaging.chat.multimedia.createMultiMediaInfo": "تم إنشاء بيانات وسائط متعددة بنجاح",
  "ccmessaging.chat.multimedia.createMultiMediaInfoError": "فشل إنشاء بيانات وسائط متعددة",
  "ccmessaging.chat.multimedia.createMultiMediaType": "تم إنشاء نوع وسائط متعددة بنجاح",
  "ccmessaging.chat.multimedia.createMultiMediaTypeError": "فشل إنشاء نوع وسائط متعددة",
  "ccmessaging.chat.phrase.createTypeSuccess": "تم إنشاء نوع عبارة عامة بنجاح",

  "ccmessaging.chat.multimedia.createVideoInfo": "تم إنشاء بيانات فيديو بنجاح",
  "ccmessaging.chat.multimedia.createVideoInfoError": "فشل إنشاء بيانات الفيديو",
  "ccmessaging.chat.multimedia.createVideoType": "تم إنشاء نوع فيديو بنجاح",
  "ccmessaging.chat.multimedia.createVideoTypeError": "فشل إنشاء نوع الفيديو",
  "ccmessaging.chat.multimedia.editVideoInfo": "تم تحرير بيانات الفيديو بنجاح",
  "ccmessaging.chat.multimedia.editVideoInfoError": "فشل تحرير بيانات الفيديو",
  "ccmessaging.chat.multimedia.editVideoType": "تم تحرير نوع الفيديو بنجاح",
  "ccmessaging.chat.multimedia.editVideoTypeError": "فشل تحرير نوع الفيديو",
  "ccmessaging.chat.multimedia.deleteVideoInfo": "تم حذف بيانات الفيديو بنجاح",
  "ccmessaging.chat.multimedia.deleteVideoInfoError": "فشل حذف بيانات الفيديو",
  "ccmessaging.chat.multimedia.deleteVideoType": "تم حذف نوع الفيديو بنجاح",
  "ccmessaging.chat.multimedia.deleteVideoTypeError": "فشل حذف نوع الفيديو",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.usedbyODFS": "فشل حذف نوع الفيديو. الموارد المشار إليها بواسطة IVR الذكي موجودة.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byCard": "فشل حذف نوع الفيديو، لأن بعض الموارد قد تمت الإشارة إليها بالفعل بواسطة البطاقات.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byWhatsAppTemplateMsg": "فشل حذف نوع الفيديو، نظرًا لأن بعض الموارد تمت الإشارة إليها بالفعل بواسطة رسالة قالب WhatsApp.",
  "ccmessaging.chat.multimedia.createAudioInfo": "تم إنشاء بيانات صوتية بنجاح",
  "ccmessaging.chat.multimedia.createAudioInfoError": "فشل إنشاء بيانات صوتية",
  "ccmessaging.chat.multimedia.createAudioType": "تم إنشاء نوع الصوت بنجاح",
  "ccmessaging.chat.multimedia.createAudioTypeError": "فشل إنشاء نوع الصوت",
  "ccmessaging.chat.multimedia.editAudioInfo": "تم تحرير بيانات الصوت بنجاح",
  "ccmessaging.chat.multimedia.editAudioInfoError": "فشل تحرير بيانات الصوت",
  "ccmessaging.chat.multimedia.editAudioType": "تم تحرير نوع الصوت بنجاح",
  "ccmessaging.chat.multimedia.editAudioTypeError": "فشل تحرير نوع الصوت",
  "ccmessaging.chat.multimedia.deleteAudioInfo": "تم حذف بيانات الصوت بنجاح",
  "ccmessaging.chat.multimedia.deleteAudioInfoError": "فشل حذف بيانات الصوت",
  "ccmessaging.chat.multimedia.deleteAudioType": "تم حذف نوع الصوت بنجاح",
  "ccmessaging.chat.multimedia.deleteAudioTypeError": "فشل حذف نوع الصوت",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.usedbyODFS": "فشل حذف نوع الصوت. الموارد المشار إليها بواسطة IVR الذكي موجودة.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byCard": "فشل حذف نوع الصوت، نظرًا لأن بعض الموارد قد تمت الإشارة إليها بالفعل بواسطة البطاقات",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byWhatsAppTemplateMsg": "فشل حذف نوع الصوت، نظرًا لأن بعض الموارد تمت الإشارة إليها بالفعل بواسطة رسالة قالب WhatsApp.",
  "ccmessaging.chat.multimedia.createImageInfo": "تم إنشاء بيانات الصورة بنجاح",
  "ccmessaging.chat.multimedia.createImageInfoError": "فشل إنشاء بيانات الصورة",
  "ccmessaging.chat.multimedia.createImageType": "تم إنشاء نوع صورة بنجاح",
  "ccmessaging.chat.multimedia.createImageTypeError": "فشل إنشاء نوع الصورة",
  "ccmessaging.chat.multimedia.editImageInfo": "تم تحرير بيانات الصورة بنجاح",
  "ccmessaging.chat.multimedia.editImageInfoError": "فشل تحرير بيانات الصورة",
  "ccmessaging.chat.multimedia.editImageType": "تم تحرير نوع الصورة بنجاح",
  "ccmessaging.chat.multimedia.editImageTypeError": "فشل تحرير نوع الصورة",
  "ccmessaging.chat.multimedia.deleteImageInfo": "تم حذف بيانات الصورة بنجاح",
  "ccmessaging.chat.multimedia.deleteImageInfoError": "فشل حذف بيانات الصورة",
  "ccmessaging.chat.multimedia.deleteImageType": "تم حذف نوع الصورة بنجاح",
  "ccmessaging.chat.multimedia.deleteImageTypeError": "فشل حذف نوع الصورة",
  "ccmessaging.chat.multimedia.deleteImageTypeError.usedbyODFS": "فشل حذف نوع الصورة. الموارد المشار إليها بواسطة IVR الذكي موجودة.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byCard": "فشل حذف نوع الصورة، لأن بعض الموارد قد تمت الإشارة إليها بالفعل بواسطة البطاقات.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byWhatsAppTemplateMsg": "فشل حذف نوع الصورة، نظرًا لأن بعض الموارد تمت الإشارة إليها بالفعل بواسطة رسالة قالب WhatsApp.",
  "ccmessaging.chat.multimedia.createDocumentInfo": "تم إنشاء بيانات المستند بنجاح",
  "ccmessaging.chat.multimedia.createDocumentInfoError": "فشل إنشاء بيانات المستند",
  "ccmessaging.chat.multimedia.createDocumentType": "تم إنشاء نوع مستند بنجاح",
  "ccmessaging.chat.multimedia.createDocumentTypeError": "فشل إنشاء نوع المستند",
  "ccmessaging.chat.multimedia.editDocumentInfo": "تم تحرير بيانات المستند بنجاح",
  "ccmessaging.chat.multimedia.editDocumentInfoError": "فشل تحرير بيانات المستند",
  "ccmessaging.chat.multimedia.editDocumentType": "تم تحرير نوع المستند بنجاح",
  "ccmessaging.chat.multimedia.editDocumentTypeError": "فشل تحرير نوع المستند",
  "ccmessaging.chat.multimedia.deleteDocumentInfo": "تم حذف بيانات المستند بنجاح",
  "ccmessaging.chat.multimedia.deleteDocumentInfoError": "فشل حذف بيانات المستند",
  "ccmessaging.chat.multimedia.deleteDocumentType": "تم حذف نوع المستند بنجاح",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError": "فشل حذف نوع المستند",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.usedbyODFS": "فشل حذف نوع المستند. الموارد المشار إليها بواسطة IVR الذكي موجودة.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byCard": "فشل حذف نوع المستند، لأن بعض الموارد قد تمت الإشارة إليها بالفعل بواسطة البطاقات.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byWhatsAppTemplateMsg": "فشل حذف نوع المستند، نظرًا لأن بعض الموارد تمت الإشارة إليها بالفعل بواسطة رسالة قالب WhatsApp.",
  "ccmessaging.chat.multimedia.createLocateInfo": "تم إنشاء بيانات الموقع بنجاح",
  "ccmessaging.chat.multimedia.createLocateInfoError": "فشل إنشاء بيانات الموقع",
  "ccmessaging.chat.multimedia.createLocateType": "تم إنشاء نوع الموقع بنجاح",
  "ccmessaging.chat.multimedia.createLocateTypeError": "فشل إنشاء نوع الموقع",
  "ccmessaging.chat.multimedia.editLocateInfo": "تم تحرير بيانات الموقع بنجاح",
  "ccmessaging.chat.multimedia.editLocateInfoError": "فشل تحرير بيانات الموقع",
  "ccmessaging.chat.multimedia.editLocateType": "تم تحرير نوع الموقع بنجاح",
  "ccmessaging.chat.multimedia.editLocateTypeError": "فشل تحرير نوع الموقع",
  "ccmessaging.chat.multimedia.deleteLocateInfo": "تم حذف بيانات الموقع بنجاح",
  "ccmessaging.chat.multimedia.deleteLocateInfoError": "فشل حذف بيانات الموقع",
  "ccmessaging.chat.multimedia.deleteLocateType": "تم حذف نوع الموقع بنجاح",
  "ccmessaging.chat.multimedia.deleteLocateTypeError": "فشل حذف نوع الموقع",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.usedbyODFS": "فشل حذف نوع الموقع. الموارد المشار إليها بواسطة IVR الذكي موجودة.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byCard": "فشل حذف نوع الموقع، نظرًا لأن بعض الموارد قد تمت الإشارة إليها بالفعل بواسطة البطاقات.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byWhatsAppTemplateMsg": "فشل حذف نوع الموقع، نظرًا لأن بعض الموارد تمت الإشارة إليها بالفعل بواسطة رسالة قالب WhatsApp.",
  "ccmessaging.chat.multimedia.createEmotionInfo": "تم إنشاء بيانات الرموز بنجاح",
  "ccmessaging.chat.multimedia.createEmotionInfoError": "فشل إنشاء بيانات الرموز",
  "ccmessaging.chat.multimedia.createEmotionType": "تم إنشاء نوع رمز تعبيري بنجاح",
  "ccmessaging.chat.multimedia.createEmotionTypeError": "فشل إنشاء نوع رمز تعبيري",
  "ccmessaging.chat.multimedia.editEmotionInfo": "تم تحرير بيانات الرموز بنجاح",
  "ccmessaging.chat.multimedia.editEmotionInfoError": "فشل تحرير بيانات الرموز",
  "ccmessaging.chat.multimedia.editEmotionType": "تم تحرير نوع الرمز التعبيري بنجاح",
  "ccmessaging.chat.multimedia.editEmotionTypeError": "فشل تحرير نوع الرموز",
  "ccmessaging.chat.multimedia.deleteEmotionInfo": "تم حذف بيانات الرموز بنجاح",
  "ccmessaging.chat.multimedia.deleteEmotionInfoError": "فشل حذف بيانات الرموز",
  "ccmessaging.chat.multimedia.deleteEmotionType": "تم حذف نوع الرموز بنجاح",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError": "فشل حذف نوع الرموز",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.usedbyODFS": "فشل حذف نوع الرموز. الموارد المشار إليها بواسطة IVR الذكي موجودة.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byCard": "فشل حذف نوع الرموز، نظرًا لأن بعض الموارد قد تمت الإشارة إليها بالفعل بواسطة البطاقات.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byWhatsAppTemplateMsg": "فشل حذف نوع الرموز، نظرًا لأن بعض الموارد تمت الإشارة إليها بالفعل بواسطة رسالة قالب WhatsApp.",
  "ccmessaging.chat.multimedia.createRichTextInfo": "تم إنشاء بيانات نص غنية بنجاح",
  "ccmessaging.chat.multimedia.createRichTextInfoError": "فشل إنشاء بيانات نص غنية",
  "ccmessaging.chat.multimedia.createRichTextType": "تم إنشاء كتابة نص ثري بنجاح",
  "ccmessaging.chat.multimedia.createRichTextTypeError": "فشل إنشاء نوع نص غني",
  "ccmessaging.chat.multimedia.editRichTextInfo": "تم تحرير بيانات النص المنسق بنجاح",
  "ccmessaging.chat.multimedia.editRichTextInfoError": "فشل تحرير بيانات النص المنسق",
  "ccmessaging.chat.multimedia.editRichTextType": "تم تحرير كتابة النص المنسق بنجاح",
  "ccmessaging.chat.multimedia.editRichTextTypeError": "فشل تحرير نوع النص المنسق",
  "ccmessaging.chat.multimedia.deleteRichTextInfo": "تم حذف بيانات النص المنسق بنجاح",
  "ccmessaging.chat.multimedia.deleteRichTextInfoError": "فشل حذف بيانات النص المنسق",
  "ccmessaging.chat.multimedia.deleteRichTextType": "تم حذف نوع النص المنسق بنجاح",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError": "فشل حذف نوع النص المنسق",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.usedbyODFS": "فشل حذف نوع النص المنسق. الموارد المشار إليها بواسطة IVR الذكي موجودة.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byCard": "فشل حذف نوع النص المنسق، نظرًا لأن بعض الموارد قد تمت الإشارة إليها بالفعل بواسطة البطاقات.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byWhatsAppTemplateMsg": "فشل حذف نوع النص المنسق، نظرًا لأن بعض الموارد تمت الإشارة إليها بالفعل بواسطة رسالة قالب واتساب.",
  "ccmessaging.chat.multimedia.createSiteAddrInfo": "تم إنشاء بيانات عنوان تعاون صفحة ويب بنجاح",
  "ccmessaging.chat.multimedia.createSiteAddrInfoError": "فشل إنشاء بيانات عنوان تعاون صفحة ويب",
  "ccmessaging.chat.multimedia.createSiteAddrType": "تم إنشاء نوع عنوان تعاون لصفحة ويب بنجاح",
  "ccmessaging.chat.multimedia.createSiteAddrTypeError": "فشل إنشاء نوع عنوان تعاون صفحة ويب",
  "ccmessaging.chat.multimedia.editSiteAddrInfo": "تم تحرير بيانات عنوان تعاون صفحة ويب بنجاح",
  "ccmessaging.chat.multimedia.editSiteAddrInfoError": "فشل تحرير بيانات عنوان تعاون صفحة ويب",
  "ccmessaging.chat.multimedia.editSiteAddrType": "تم تحرير نوع عنوان تعاون صفحة ويب بنجاح",
  "ccmessaging.chat.multimedia.editSiteAddrTypeError": "فشل تحرير نوع عنوان تعاون صفحة ويب",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfo": "تم حذف بيانات عنوان تعاون صفحة ويب بنجاح",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfoError": "فشل حذف بيانات عنوان تعاون صفحة ويب",
  "ccmessaging.chat.multimedia.deleteSiteAddrType": "تم حذف نوع عنوان تعاون صفحة ويب بنجاح",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError": "تم حذف نوع عنوان تعاون صفحة ويب بنجاح",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.usedbyODFS": "فشل حذف نوع عنوان تعاون صفحة ويب. الموارد المشار إليها بواسطة IVR الذكي موجودة.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byCard": "فشل حذف نوع عنوان تعاون صفحة ويب، نظرًا لأن بعض الموارد قد تمت الإشارة إليها بالفعل بواسطة البطاقات.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byWhatsAppTemplateMsg": "فشل حذف نوع عنوان تعاون صفحة ويب، نظرًا لأن بعض الموارد تمت الإشارة إليها بالفعل بواسطة رسالة قالب تطبيق واتساب.",
  "ccmessaging.chat.chatcard.createFiveGCardFail": "فشل إنشاء بطاقة الجيل الخامس",
  "ccmessaging.chat.chatcard.createFiveGCardSuccess": "تم إنشاء بطاقة الجيل الخامس بنجاح",
  "ccmessaging.chat.chatcard.editFiveGCardFail": "فشل تحرير بطاقة الجيل الخامس",
  "ccmessaging.chat.chatcard.editFiveGCardSuccess": "تم تحرير بطاقة الجيل الخامس بنجاح",
  "ccmessaging.chat.chatcard.deleteFiveGCardFail": "فشل حذف بطاقة الجيل الخامس",
  "ccmessaging.chat.chatcard.deleteFiveGCardSuccess": "تم حذف بطاقة الجيل الخامس بنجاح",
  "ccmessaging.chat.chatcard.createFiveGCarouselFail": "فشل إنشاء دائري 5G",
  "ccmessaging.chat.chatcard.createFiveGCarouselSuccess": "تم إنشاء دائري 5G بنجاح",
  "ccmessaging.chat.chatcard.editFiveGCarouselFail": "فشل تحرير دائري 5G",
  "ccmessaging.chat.chatcard.editFiveGCarouselSuccess": "تم تحرير دائري 5G بنجاح",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselFail": "فشل حذف دائري 5G",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselSuccess": "تم حذف دائرية الجيل الخامس بنجاح",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeFail": "فشل إنشاء تنسيق قالب WhatsApp",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeSuccess": "تم إنشاء قياس قالب WhatsApp بنجاح",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeFail": "فشل تحرير تنسيق قالب WhatsApp",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeSuccess": "تم تحرير تنسيق قالب WhatsApp بنجاح",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeFail": "فشل حذف تنسيق قالب WhatsApp",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeSuccess": "تم حذف قياس قالب WhatsApp بنجاح",
  "ccmessaging.chat.chatcard.createWebCardFail": "فشل إنشاء بطاقة ويب",
  "ccmessaging.chat.chatcard.createWebCardSuccess": "تم إنشاء بطاقة ويب بنجاح",
  "ccmessaging.chat.chatcard.editWebCardFail": "فشل تحرير بطاقة ويب",
  "ccmessaging.chat.chatcard.editWebCardSuccess": "تم تحرير بطاقة ويب بنجاح",
  "ccmessaging.chat.chatcard.deleteWebCardFail": "فشل حذف بطاقة ويب",
  "ccmessaging.chat.chatcard.deleteWebCardSuccess": "تم حذف بطاقة ويب بنجاح",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateSuccess": "تم إنشاء قالب WhatsApp بنجاح",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateFail": "فشل إنشاء قالب WhatsApp",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateSuccess": "تم تحرير قالب WhatsApp بنجاح",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateFail": "فشل تحرير قالب WhatsApp",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateSuccess": "تم حذف قالب WhatsApp بنجاح",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFail": "فشل حذف قالب WhatsApp",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFailMsg": "فشل حذف قالب WhatsApp. تتم الإشارة إلى القالب بواسطة رسالة قالب.",
  "ccmessaging.multimedia.download.office.risktips":"أنت على وشك تنزيل مستندات Office، مما قد يشكل مخاطر أمنية. يُوصى بإجراء عمليات مكافحة الفيروسات وتمكين إعدادات الأمان لبرنامج Office.",
  "ccmessaging.chat.multimedia.documentUploadRiskTips":"يتم إرسال هذا المستند إلى المستخدمين من قِبل الموظفين. لا تقم بتحميل الملفات التي تحتوي على مخاطر أمنية.",
  "ccmessaging.config.names.channels.web": "Web",
  "ccmessaging.config.names.channels.instagram": "Instagram",
  "ccmessaging.config.names.channels.telegram": "Telegram",
  "ccmessaging.config.names.channels.whatsapp": "WhatsApp",
  "ccmessaging.config.names.channels.line": "LINE",
  "ccmessaging.config.names.channels.wechat": "WeChat",
  "ccmessaging.config.names.channels.facebook": "Facebook",
  "ccmessaging.config.names.channels.twitter": "X (Twitter)",
  "ccmessaging.config.names.channels.rcs": "5G RCS",
  "ccmessaging.config.names.channels.email": "Email",
  "ccmessaging.config.names.channels.sms": "SMS",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg":"رسائل WhatsApp التفاعلية",
  "ccmessaging.chat.whatsapp.interactiveMsgNamePlaceholder":"الرجاء إدخال اسم رسالة تفاعلية",
  "ccmessaging.chat.whatsapp.interactiveMsgName":"اسم الرسالة",
  "ccmessaging.chat.whatsapp.interactiveMsgTitle":"العنوان",
  "ccmessaging.chat.whatsapp.interactiveMsgDescription":"وصف الرسالة",
  "ccmessaging.chat.whatsapp.interactiveMsgDescriptionPlaceholder":"الرجاء إدخال وصف الرسالة التفاعلية",
  "ccmessaging.chat.whatsapp.interactiveMsgType":"نوع الرسالة",
  "ccmessaging.chat.whatsapp.orgName":"اسم المؤسسة",
  "ccmessaging.chat.chatcard.queryInteractiveMsg":"فشل الاستعلام عن الرسائل التفاعلية",
  "ccmessaging.chat.whatsapp.interactiveType.buttons":"زر رسالة",
  "ccmessaging.chat.whatsapp.interactiveType.list":"سرد الرسالة",
  "ccmessaging.chat.whatsapp.delete.interactiveMsg":"حذف الرسائل التفاعلية",
  "ccmessaging.chat.whatsapp.willdeleteinteractiveMsg":"هل تريد بالتأكيد حذف هذه الرسالة التفاعلية؟",
  "ccmessaging.chat.whatsapp.interactiveTypes":"نوع الرسالة التفاعلية",
  "ccmessaging.chat.whatsapp.interactiveMsgTitlePlaceholder":"الرجاء إدخال محتوى عنوان تفاعلي",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContent":"محتوى الجسم",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContentPlaceholder":"يرجى إدخال محتوى النص الأساسي.",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContent":"محتوى تذييل الصفحة",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContentPlaceholder":"الرجاء إدخال محتوى التذييل.",
  "ccmessaging.chat.whatsapp.interactiveMsg.pleaseChoose":"يُرجى التحديد",
  "ccmessaging.chat.whatsapp.interactiveMsg.attachments":"المرفقات",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.create":"رسالة تفاعلية جديدة",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.update":"تعديل رسالة تفاعلية",
  "ccmessaging.chat.whatsapp.interactiveType.list.title":"قائمة",
  "ccmessaging.chat.whatsapp.interactiveType.list.titlePlaceholder":"الرجاء إدخال عنوان القائمة.",
  "ccmessaging.chat.whatsapp.interactiveMsg.openRow.info":"عنوان المجموعة فارغ. لا يمكن تعيين الخيار.",
  "ccmessaging.chat.whatsapp.interactiveType.section.titlePlaceholder":"الرجاء إدخال عنوان المجموعة.",
  "ccmessaging.chat.whatsapp.interactiveType.row.titlePlaceholder":"أدخل عنوان خيار.",
  "ccmessaging.chat.whatsapp.interactiveType.row.descPlaceholder":"أدخل وصف خيار.",
  "ccmessaging.chat.whatsapp.interactiveMsg.sectionTitle":"الرجاء إدخال عنوان القائمة.",
  "ccmessaging.chat.whatsapp.interactiveMsg.queryInteractiveError":"فشل الاستعلام عن الرسائل التفاعلية",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError":"فشل حفظ الرسالة التفاعلية. بعد تجميع القائمة، لا يمكن أن تكون خيارات التجميع خالية.",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.duplicateName":"فشل الحفظ. اسم الرسالة التفاعلية موجود بالفعل.",
  "ccmessaging.chat.whatsapp.interactiveMsg.noHeader":"لا يوجد",
  "ccmessaging.chat.whatsapp.interactiveMsg.headerContent":"محتوى الرأس",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.buttons":"فشل حفظ الرسالة التفاعلية. لا يوجد زر إعداد.",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.lists":"فشل حفظ الرسالة التفاعلية، لا توجد قائمة إعدادات.",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.interactiveMsg":"تمت الإشارة إلى المورد بواسطة رسائل WhatsApp التفاعلية ولا يمكن حذفه. اسم الرسالة التفاعلية:",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.multiMedia.notExist":"فشل الحفظ. مورد الوسائط المتعددة غير موجود.",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError.repeateButtonContent":"فشل الحفظ. يجب أن يكون محتوى الزر فريدًا.",
  "ccmessaging.chat.case.title":"Case Title",
  "ccmessaging.chat.case.type":"Case Type",
  "ccmessaging.chat.case.callReason":"Call Reason",
  "ccmessaging.chat.case.puzzled":"puzzled",
  "ccmessaging.chat.case.calm":"calm",
  "ccmessaging.chat.case.happy":"happy",
  "ccmessaging.chat.case.upset":"upset",
  "ccmessaging.chat.case.surprise":"surprise",
  "ccmessaging.chat.case.pleasant":"pleasant",
  "ccmessaging.chat.case.angry":"angry",
  "ccmessaging.chat.case.little.angry":"a little anger",
  "ccmessaging.chat.case.very.angry":"very mad",
  "ccmessaging.chat.case.very.knowledgeId":"1228840300949016576",
  "ccmessaging.chat.case.complaint.yes":"yes",
  "ccmessaging.chat.case.complaint.shi":"yes",
  "ccmessaging.chat.degree.view":"العميل 360 درجة",
}
