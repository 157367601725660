<template>
    <sweet-dialog :append-to-body="true"
                  v-model="dialogVisible"
                  :title="$t('ccmessaging.chat.multimedia.preview')"
                  width="458px" draggable align-center
                  @opened="handleOpen" @closed="handleClosed" :close-on-click-modal="false">
        <div class="chat-card-view">
            <!-- 5G卡片 -->
            <div v-if="type !== 3 && cardInfos.length === 1" class="five-g-card-single">
                <!-- 展示卡片图片 -->
                <div v-if="cardInfos[0].mediaType === 'IMAGE'" class="five-g-card-image">
                    <img :src="cardInfos[0].mediaUrl" alt="">
                </div>

                <!-- 展示卡片视频 -->
                <div v-if="cardInfos[0].mediaType === 'VIDEO'" class="five-g-card-video">
                    <video :src="cardInfos[0].mediaUrl" controls="controls" controlslist="nodownload"
                           disablepictureinpicture>
                    </video>
                </div>

                <!-- 展示卡片音频 -->
                <div v-if="cardInfos[0].mediaType === 'AUDIO'" class="five-g-card-audio">
                    <aicc-audio :src="cardInfos[0].mediaUrl">
                    </aicc-audio>
                </div>

                <!-- 卡片标题与描述 -->
                <div class="five-g-card-title">{{ cardInfos[0].title }}</div>
                <div class="five-g-card-description">{{ cardInfos[0].description }}</div>

                <!-- 按钮 -->
                <sweet-button class="card-button" v-for="(tab, index) in cardInfos[0].CardTabs" :key="index" type="primary" :title="tab.name">
                    {{ tab.name }}
                </sweet-button>

            </div>

            <!-- 5G轮播卡片 -->
            <div class="carousel-card" v-if="type !== 3 && cardInfos.length > 1 && !cardIsRTL">
                <sweet-button :disabled="leftButtonStatus" @click="carouselIndex--" text class="left-button" size="larger">
                    <i class="sweetui-icon-left"></i>
                </sweet-button>
                <sweet-button :disabled="rightButtonStatus" @click="carouselIndex++" text class="right-button"
                              size="larger">
                    <i class="sweetui-icon-right"></i>
                </sweet-button>
                <div :style="carouselStyle" class="carousel-card-content">
                    <div class="carousel-card-item" v-for="(card, cardIndex) in cardInfos" :key="cardIndex">
                        <div class="five-g-card-single">
                            <!-- 展示卡片图片 -->
                            <div v-if="card.mediaType === 'IMAGE'" class="five-g-card-image">
                                <img :src="card.mediaUrl" alt="">
                            </div>

                            <!-- 展示卡片视频 -->
                            <div v-if="card.mediaType === 'VIDEO'" class="five-g-card-video">
                                <video :src="card.mediaUrl" controls="controls" controlslist="nodownload"
                                       disablepictureinpicture>
                                </video>
                            </div>

                            <!-- 展示卡片音频 -->
                            <div v-if="card.mediaType === 'AUDIO'" class="five-g-card-audio">
                                <aicc-audio :src="card.mediaUrl">
                                </aicc-audio>
                            </div>

                            <!-- 卡片标题与描述 -->
                            <div class="five-g-card-title">{{ card.title }}</div>
                            <div class="five-g-card-description">{{ card.description }}</div>

                            <sweet-button class="card-button" v-for="(tab, index) in card.CardTabs" :key="index"
                                          type="primary" :title="tab.name">
                                {{ tab.name }}
                            </sweet-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-card" v-if="type !== 3 && cardInfos.length > 1 && cardIsRTL">
                <sweet-button :disabled="leftButtonStatus" @click="carouselIndex--" text class="right-button" size="larger">
                    <i class="sweetui-icon-right"></i>
                </sweet-button>
                <sweet-button :disabled="rightButtonStatus" @click="carouselIndex++" text class="left-button"
                              size="larger">
                    <i class="sweetui-icon-left"></i>
                </sweet-button>
                <div :style="carouselStyle" class="carousel-card-content">
                    <div class="carousel-card-item" v-for="(card, cardIndex) in cardInfos" :key="cardIndex">
                        <div class="five-g-card-single">
                            <!-- 展示卡片图片 -->
                            <div v-if="card.mediaType === 'IMAGE'" class="five-g-card-image">
                                <img :src="card.mediaUrl" alt="">
                            </div>

                            <!-- 展示卡片音频 -->
                            <div v-if="card.mediaType === 'AUDIO'" class="five-g-card-audio">
                                <aicc-audio :src="card.mediaUrl">
                                </aicc-audio>
                            </div>

                            <!-- 展示卡片视频 -->
                            <div v-if="card.mediaType === 'VIDEO'" class="five-g-card-video">
                                <video :src="card.mediaUrl" controls="controls" controlslist="nodownload"
                                       disablepictureinpicture>
                                </video>
                            </div>

                            <!-- 卡片标题与描述 -->
                            <div class="five-g-card-title">{{ card.title }}</div>
                            <div class="five-g-card-description">{{ card.description }}</div>

                            <sweet-button class="card-button" v-for="(tab, index) in card.CardTabs" :key="index"
                                          type="primary" :title="tab.name">
                                {{ tab.name }}
                            </sweet-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- web卡片--(原始类型图片没有tab， 横向图片) -->
            <div v-if="type === 3 && cardInfos.length >= 1 && cardInfos[0].mediaType === 'IMAGE' && cardInfos[0].alignment != 'longitudinal' " class="web-card">
                <div v-for="(webCard, webCardIndex) in cardInfos" :key="webCardIndex" class="web-card-content">
                    <div class="web-card-main">
                        <!-- 展示web卡片图片 -->
                        <div class="web-card-image">
                            <img v-if="cardInfos[0].mediaUrl" :src="webCard.mediaUrl" alt="" class="img">
                            <img v-else src="@ccme/assets/img/online-chat-workbench/webCard_default_img.png" alt="">
                        </div>

                        <!-- web卡片标题与描述 -->
                        <div class="web-card-text">
                            <div class="text-title" :title="cardInfos[0].title" v-text="cardInfos[0].title"></div>
                            <div class="text-description" :title="cardInfos[0].description"
                                 v-text="cardInfos[0].description"></div>
                        </div>

                    </div>

                    <div v-if="cardInfos[0].cardRedirectUrl && cardInfos[0].cardRedirectUrl !== '' " class="web-card-url-wrap">
                        <sweet-button text class="web-card-url" @click.stop="copyWebCardUrl(cardInfos[0].cardRedirectUrl)">
                            {{ $t('ccmessaging.chat.webCard.copyUrl') }}
                        </sweet-button>
                    </div>
                </div>
            </div>

            <!-- web普通卡片 -->
            <div v-else-if="type === 3 && cardInfos.length >= 1 && cardInfos[0].cardTemplateType === 0 " class="five-g-card-single">
                <!-- 展示卡片图片 -->
                <div v-if="cardInfos[0].mediaType === 'IMAGE'" class="five-g-card-image">
                    <img :src="cardInfos[0].mediaUrl" alt="" class="img">
                </div>

                <!-- 展示卡片视频 -->
                <div v-if="cardInfos[0].mediaType === 'VIDEO'" class="five-g-card-video">
                    <video :src="cardInfos[0].mediaUrl" controls="controls" controlslist="nodownload"
                           disablepictureinpicture>
                    </video>
                </div>

                <!-- 位置 -->
                <div v-if="cardInfos[0].mediaType === 'LOCATE'" class="five-g-card-image">
                    <img
                        src="@/views/ccmanagement/assets/image/map-default-thumbail.png"
                    />
                </div>

                <!-- 卡片标题与描述 -->
                <div class="five-g-card-title">
                        <span :title="cardInfos[0].title" style="overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;">
                            {{ cardInfos[0].title }}   </span>
                </div>
                <div class="five-g-card-description">
                        <span :title="cardInfos[0].description" style="overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;">
                            {{ cardInfos[0].description }}   </span>
                </div>
                <div v-if="cardInfos[0].cardRedirectUrl && cardInfos[0].cardRedirectUrl !== '' " class="web-card-url-wrap">
                    <sweet-button text class="web-card-url" @click.stop="copyWebCardUrl(cardInfos[0].cardRedirectUrl)">
                        {{ $t('ccmessaging.chat.webCard.copyUrl') }}
                    </sweet-button>
                </div>

                <!-- 按钮 -->
                <div v-if="cardInfos[0].alignment === 'longitudinal'" class="longitudinal-card-button">
                    <sweet-button  class="longitudinal-on-card-button"   v-for="(tab, index) in cardInfos[0].CardTabs" :key="index" type="primary" >
                        <sweet-image v-if="tab.tabMediaUrl " style="width: 20px; height: 20px;vertical-align:middle;float: left; margin-inline-end:10px;"
                                     :src="tab.tabMediaUrl" :fit="fit"/>
                        <span style="display: block;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;font-family:PingFang SC;color:#366bfc;" :title="tab.displaytext"> {{ tab.displaytext }} </span>
                    </sweet-button>
                </div>
            </div>
            <!--             web卡片选项 -->
            <div v-if="type === 3 && cardInfos.length >= 1 && cardInfos[0].cardTemplateType === 1 && cardInfos[0].alignment === 'longitudinal'" class="five-g-card-single">
                <!-- 按钮 -->
                <div  class="longitudinal-card-button">
                    <sweet-button  class="longitudinal-on-card-button"   v-for="(tab, index) in cardInfos[0].CardTabs" :key="index" type="primary" >
                        <sweet-image v-if="tab.tabMediaUrl" style="width: 20px; height: 20px;vertical-align:middle;float: left; margin-inline-end:10px "
                                     :src="tab.tabMediaUrl" :fit="fit"/>
                        <span style="display: block;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;font-family:PingFang SC;color:#366bfc;" :title="tab.displaytext"> {{ tab.displaytext }} </span>
                    </sweet-button>
                </div>
            </div>


            <div v-if="type === 3 && cardInfos.length >= 1 && cardInfos[0].alignment === 'transverse'"  class="transverse-card-button" >
                <!-- 按钮 -->
                <sweet-button class="transverse-on-card-button"
                              v-for="(tab, index) in cardInfos[0].CardTabs" :key="index" type="primary" >
                    <sweet-image v-if="tab.tabMediaUrl" style="min-width: 20px;max-width: 20px; height: 20px;vertical-align:middle;float: left; margin-inline-end:10px "
                                 :src="tab.tabMediaUrl" :fit="fit"/>
                    <span style="display: block;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;font-family:PingFang SC;color:#1c1c1c;" :title="tab.displaytext"> {{ tab.displaytext }} </span>
                </sweet-button>
            </div>
        </div>
    </sweet-dialog>
</template>

<script>
import { queryMultiCards } from '@/views/ccmessaging/api/online-chat-workbench/index.js'
import { debounce, tipHandler } from "@/views/ccmessaging/utils/index.js"

const baseMediaUrl = '/service-cloud/u-route/ccmessaging/getMultiMediaLibraryFile?id='

export default {
    name: "ChatCardView",

    props: {
        show: {
            type: Boolean,
            default: false
        },
        id: String, // 卡片id
        type: { // 卡片类型， 0: 5G卡片; 1: 5G轮播卡片; 3: web卡片
            type: Number,
            default: 0,
        },
    },

    computed: {
        dialogVisible: {
            get() {
                return this.show
            },
            set(val) {
                this.$emit('update:show', val)
            }
        },

        leftButtonStatus() {
            return this.carouselIndex === 0
        },

        rightButtonStatus() {
            return this.carouselIndex >= this.cardInfos?.length - 1
        },

        carouselStyle() {
            if (this.cardIsRTL) {
                return `transform: translateX(${this.carouselIndex * 400}px);`
            }
            return `transform: translateX(-${this.carouselIndex * 400}px);`
        }

    },

    data() {
        return {
            cardIsRTL: window.isRTL,
            // 卡片集合
            cardInfos: [],
            // 卡片内容的多媒体类型
            mediaType: '',
            // 卡片内容的多媒体url
            mediaUrl: '',
            // 轮播图索引
            carouselIndex: 0
        }
    },
    methods: {

        /**
         * 处理弹框打开
         */
        handleOpen() {
            this.queryCards()
        },

        /**
         * 处理弹框关闭
         */
        handleClosed() {
            this.cardInfos = []
            this.mediaType = ''
            this.mediaUrl = ''
        },

        /**
         * 查询卡片信息
         */
        queryCards() {
            const params = {
                id: this.id,
            }
            queryMultiCards(params).then(result => {
                let cardInfo = result.result
                if (cardInfo.cardType === 1) {
                    this.cardInfos = cardInfo.singleCardList
                } else {
                    this.cardInfos[0] = cardInfo
                }

                for (let i = 0; i < this.cardInfos.length; i++) {
                    this.mediaType = this.cardInfos[i].mediaType

                    if (this.type === 3) {
                        if (this.cardInfos[i].mediaType === "IMAGE" && this.cardInfos[i].multiMediaId) {
                            this.mediaUrl = location.protocol + '//' + location.host + baseMediaUrl
                                + this.cardInfos[i].multiMediaId
                        } else if (this.cardInfos[i].mediaType === "VIDEO" && this.cardInfos[i].multiMediaId) {
                            this.mediaUrl = location.protocol + '//' + location.host + baseMediaUrl
                                + this.cardInfos[i].multiMediaId + '&isShowThumb=false'
                        } else {
                            this.mediaUrl = "";
                        }
                        if (this.cardInfos[i].CardTabs.length > 0) {
                            let cardTabs = [];
                            for (let j = 0; j < this.cardInfos[i].CardTabs.length; j++) {
                                if (this.cardInfos[i].CardTabs[j].name == "redirectUrl" && !this.cardInfos[i].CardTabs[j].multiMediaId) {
                                    this.cardInfos[i].cardRedirectUrl = this.cardInfos[i].CardTabs[j].selectcontent
                                } else {
                                    if (this.cardInfos[i].CardTabs[j].multiMediaId) {
                                        this.cardInfos[i].CardTabs[j].tabMediaUrl = location.protocol + '//' + location.host + baseMediaUrl
                                            + this.cardInfos[i].CardTabs[j].multiMediaId
                                    }
                                    cardTabs.push(this.cardInfos[i].CardTabs[j]);
                                }
                            }
                            this.cardInfos[i].CardTabs = cardTabs;
                        }
                    } else if (this.mediaType === 'IMAGE') {
                        this.mediaUrl = location.protocol + '//' + location.host + baseMediaUrl
                            + this.cardInfos[i].multiMediaId
                    } else if (this.mediaType === 'VIDEO') {
                        this.mediaUrl = location.protocol + '//' + location.host + baseMediaUrl
                            + this.cardInfos[i].multiMediaId + '&isShowThumb=false'
                    } else if (this.mediaType === 'AUDIO') {
                        this.mediaUrl = location.protocol + '//' + location.host + baseMediaUrl
                            + this.cardInfos[i].multiMediaId
                    }

                    this.cardInfos[i].mediaUrl = this.mediaUrl
                }
            })
        },

        /**
         * 复制卡片链接
         * @param cardRedirectUrl web卡片链接
         */
        copyWebCardUrl(cardRedirectUrl) {
            const inputElement = document.createElement("input")
            inputElement.value = cardRedirectUrl
            document.body.appendChild(inputElement)
            inputElement.select()
            if (document.execCommand("Copy")) {
                debounce(tipHandler($t('ccmessaging.chat.webCard.copyUrl.success'), 'success', 56, 5000, false))
            }
            inputElement.remove()
        },
    },
}
</script>

<style lang="less" scoped>
.chat-card-view {
    padding-bottom: 10px;

    .carousel-card {
        width: 100%;
        overflow: hidden;
        position: relative;

        .left-button,
        .right-button {
            position: absolute;
            top: 50%;
            border-radius: 50% !important;
            background: rgba(0, 0, 0, 0.1);
            padding-inline-start: 10px;
            padding-inline-end: 10px;
            z-index: 99;
        }

        .left-button {
            left: 5px;
        }

        .right-button {
            right: 5px;
        }

        .left-button+.right-button {
            margin-inline-start: 0 !important;
        }

        .carousel-card-content {
            display: flex;
            transition: all 0.5s;
        }


        .carousel-card-item {
            padding: 10px 0;
            width: 100%;
            height: 100%;
            flex-shrink: 0;
        }
    }

    .five-g-card-single {
        vertical-align: middle;
        line-height: 1.3;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: left;
        border-radius: 8px;
        box-shadow: 0 0 3px 0 #00000026;
        background: var(--color-bg--h1);
        color: var(--color-text--h1);
        width: 300px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 2px;
        word-wrap: break-word;

        .five-g-card-image {
            img {
                height: auto;
                width: 280px;
                display: block;
                margin: auto;
            }
        }

        .five-g-card-video {
            video {
                width: 100%;
                max-height: 10rem;
            }
        }

        .five-card-url-wrap {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .five-card-url {
                cursor: copy
            }
        }

        .five-g-card-audio {
            width: 100%;
            max-height: 10rem;
        }

        .five-g-card-title {
            font-size: var(--14px-to-rem);
            color: #333;
            font-weight: 500;
        }

        .five-g-card-description {
            font-size: var(--12px-to-rem);
            color: #777;
            font-weight: 400;
            margin-top: 6px;
        }

        .card-button {
            width: 100% !important;
            border-radius: 15px !important;
            background: #F4F6FA;
            color: #366BFC;
            border: #366BFC;
        }

        .longitudinal-card-button {
            .longitudinal-on-card-button {
                margin:5px;
                width: 100% !important;
                border-radius: 15px !important;
                background: #F4F6FA;
                color: #366BFC;
                border: #366BFC;
            }

        }


        .agent-locat-span-width-104 {
            width: 104px;
            height: 20px;
            font-family: PingFangSC-Regular;
            font-size: var(--14px-to-rem);
            color: #505050;
            font-weight: 400;
        }
        .agent-locat-width-109 {
            margin-left: 24.45px;
            width: 109px;
            height: 20px;
            font-family: PingFangSC-Regular;
            font-size: var(--14px-to-rem);
            color: #505050;
            font-weight: 400;
        }

        .card-button+.card-button {
            margin-inline-start: 0 !important;
        }
        .web-card-url-wrap {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .web-card-url {
                color:#366bfc;
                font-family:PingFang SC;
                cursor: copy
            }
        }
    }

    .transverse-card-button {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        background: var(--color-bg--h1);
        margin-top: 5px;

        .transverse-on-card-button {
            min-width: 100px;
            max-width:300px;
            margin:16px;
            width: 5px !important;
            border-radius: 5px !important;
            background-color: #F4F6FA;
            color:black;
            border: None;
        }
    }

    .web-card {
        position: relative;

        .web-card-content {
            max-width: 18.75rem;
            max-height: 40rem;
            display: flex;
            flex-direction: column;
            position: relative;
            text-align: left;
            align-items: flex-start;
            background: var(--color-bg--h1);
            border: 1px solid var(--color-border);
            border-radius: 8px;
            padding: 10px;
            left: 50%;
            transform: translateX(-50%);
        }

        .web-card-content-withtab {
            max-width: 18.75rem;
            max-height: 40rem;
            display: flex;
            flex-direction: column;
            position: relative;
            text-align: left;
            align-items: flex-start;
            background: var(--color-bg--h1);
            border: 1px solid var(--color-border);
            border-radius: 8px;
            padding: 10px;
            left: 50%;
            transform: translateX(-50%);
        }

        .longitudinal-card-button {
            width:  100%;
            .longitudinal-on-card-button {
                margin:5px;
                width: 100% !important;
                border-radius: 15px !important;
                background: #F4F6FA;
                color: #366BFC;
                border: #366BFC;
            }

        }

        .web-card-main {
            width: 100%;
            display: flex;
            flex: 1;
            gap: 1rem;

            .web-card-image {
                display: inline-block;
                justify-content: center;
                width: 90px;
                height: 90px;
                transform: rotate(360deg);
                overflow: hidden;
                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            .web-card-text {
                flex: 1;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;

                .text-title {
                    transform: rotate(360deg);
                    letter-spacing: 0;
                    font-family:PingFang SC;
                    font-weight:500;
                    color:#1c1c1c;
                    font-size:14px;
                    line-height:20px;

                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }

                .text-description {
                    transform: rotate(360deg);
                    letter-spacing: 0;
                    font-family:PingFang SC;
                    color:#9e9e9e;
                    font-size:12px;
                    line-height:17px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    margin-top:4px;
                }
            }
        }

        .web-card-url-wrap {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .web-card-url {
                color:#366bfc;
                font-family:PingFang SC;
                cursor: copy;
            }
        }
    }


}

body.RTL {
    .chat-card-view {
        .five-g-card-single {
            right: 50%;
            transform: translateX(50%);
            text-align: right;
        }
    }

    .web-card {
        .web-card-content {
            text-align: right;
            right: 50%;
            transform: translateX(50%);
        }

        .web-card-url-wrap {
            justify-content: flex-start;
        }
    }
}
</style>