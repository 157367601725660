export default {
  'oifde.index.title': 'ODFS-การบริการโฟลว์โต้ตอบแบบออนไลน์ (Online Dialog Flow Service)',
  'oifde.common.help': 'ข้อมูลช่วยเหลือสามารถดูได้',
  'oifde.common.select': '-เลือก-',
  'oifde.common.selectPlaceHolder': 'กรอกคำสำคัญ',
  'oifde.common.lang': 'ภาษา',
  'oifde.common.noData': 'ไม่มีข้อมูล',
  'oifde.common.language.chinese': 'ภาษาจีน',
  'oifde.common.language.english': 'ภาษาอังกฤษ',
  'oifde.common.validation.required': 'จำเป็น',
  'oifde.common.validation.extendFaqRange': 'อนุญาตให้ใช้เฉพาะอักขระจีน ตัวอักษร ช่องว่าง ตัวเลข เครื่องหมายทวิภาค (:) เครื่องหมายคำถาม (?) และอักขระต่อไปนี้: () [] {\'@\'}{\'|\'}{\'{\'}{\'}\'}，,。.\'_-',
  'oifde.common.validation.nameRange': 'ไม่อนุญาตให้ใช้อักขระพิเศษต่อไปนี้: <>!{\'@\'}#{\'$\'}%^&*?;"~/\\',
  'oifde.common.validation.cellNameRange': 'ไม่อนุญาตให้ใช้อักขระพิเศษต่อไปนี้: < > {\'@\'} # $ % ^ * ; " ~ / \\',
  'oifde.shortMessage.validation.text': 'ไม่อนุญาตให้ใช้อักขระพิเศษต่อไปนี้: < > {\'@\'} # $ % ^ * ; " ~ / \\',
  'oifde.common.validation.nameNoSpace': 'ค่าไม่สามารถขึ้นต้นหรือลงท้ายด้วยช่องว่าง',
  'oifde.common.validation.nodeNameCharReg': 'ค่าสามารถมีได้เฉพาะตัวอักษร ตัวเลข อักขระจีน ขีดล่าง (_) และขีดกลาง (-) แต่ไม่สามารถขึ้นต้นหรือลงท้ายด้วยช่องว่างได้',
  'oifde.common.validation.nameCharRegNotChinese': 'ค่าสามารถมีได้เฉพาะตัวอักษร ตัวเลข ขีดล่าง และขีดกลางเท่านั้น',
  'oifde.common.validation.nameCharReg': 'ค่าสามารถมีได้เฉพาะตัวอักษร อักขระจีน ตัวเลข ขีดล่าง และขีดกลางเท่านั้น',
  'oifde.common.validation.schemaNameCharReg': 'อนุญาตให้ใช้เฉพาะตัวอักษร ตัวเลข อักขระจีน ช่องว่าง ขีดล่าง (_) และเครื่องหมายอัญประกาศ (\') เท่านั้น',
  'oifde.common.validation.schemaRelationNameCharReg': 'อนุญาตให้ใช้เฉพาะตัวอักษร ตัวเลข อักขระจีน ช่องว่าง และเครื่องหมายอัญประกาศ (\') เท่านั้น',
  'oifde.common.validation.openNameCharReg': 'อนุญาตให้ใช้เฉพาะตัวอักษร ตัวเลข อักขระจีน ช่องว่าง  _ - และ " เท่านั้น',
  'oifde.common.validation.kgNameCharReg': 'อนุญาตให้ใช้เฉพาะตัวอักษร ตัวเลข อักขระจีน ช่องว่าง เครื่องหมายขีดล่าง (_) ขีดกลาง (-) ลูกน้ำ (,) เครื่องหมายอัญประกาศเดี่ยว (\') อัฒภาค (;) จุด (.) และเครื่องหมายแอท ({\'@\'}) เท่านั้น',
  'oifde.common.validation.charRegWithDot': 'ค่าสามารถมีได้เฉพาะตัวอักษร ตัวเลข อักขระจีน จุด (.) ขีดล่าง (_) และขีดกลาง (-)',
  'oifde.common.validation.int': 'อนุญาตเฉพาะจำนวนเต็มตั้งแต่ 0 ถึง 2147483647',
  'oifde.common.validation.min': 'ค่าต้องไม่น้อยกว่า {0}',
  'oifde.common.validation.max': 'ค่าต้องไม่มากกว่า {0}',
  'oifde.common.validation.numberRange': 'ช่วงค่า ({0} - {1})',
  'oifde.common.validation.sessionDTRange': 'ช่วงหมดเวลาของโฟลว์การโต้ตอบมีตั้งแต่ {0} ถึง {1} วินาที',
  'oifde.common.validation.slientHelpRange': 'เวลาในการรอการตอบกลับหลังจากเจ้าหน้าที่เงียบอยู่ในช่วง {0} ถึง {1} วินาที',
  'oifde.common.validation.confidenceRange': 'ค่าเกณฑ์ความเชื่อมั่นจะอยู่ในช่วงตั้งแต่ {0} ถึง {1}',
  'oifde.common.validation.urlValidate': 'รูปแบบ URL ไม่ถูกต้อง',
  'oifde.common.validation.positiveInt': 'ค่าต้องเป็นจำนวนเต็มบวก',
  'oifde.common.validation.digits': 'ค่าต้องเป็น 0 หรือจำนวนเต็มบวก',
  'oifde.common.validation.numbers': 'โปรดป้อนเฉพาะตัวเลขเท่านั้น',
  'oifde.common.validation.validateFalse': 'ไม่อนุญาตให้อักขระพิเศษต่อไปนี้: ~ ` ! # {\'$\'} % ^ & * ( ) + = [ ] {\'{\'} {\'}\'} \\ {\'|\'} \' " : < > / ?',
  'oifde.common.validation.mrcpIdValueFalse': 'ใส่จํานวนเต็มบวกตั้งแต่ 0 ถึง 255',
  'oifde.common.dialog.confirm.title': 'ข้อความพร้อมรับ',
  'oifde.common.dialog.confirm.content': 'การดำเนินการนี้จะลบข้อมูลอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.common.dialog.confirm.buttonSure': 'ใช่',
  'oifde.common.dialog.confirm.buttonCancel': 'ไม่ใช่',
  'oifde.common.dialog.confirm.successInfo': 'การลบสำเร็จแล้ว',
  'oifde.common.dialog.confirm.cancelInfo': 'การลบยกเลิกแล้ว',
  'oifde.common.dialog.confirm.operationSucc': 'การดำเนินการสำเร็จ',
  'oifde.common.dialog.confirm.operation_failed': 'การดำเนินการล้มเหลว',
  'oifde.common.actions.add': 'เพิ่ม',
  'oifde.common.actions.edit': 'แก้ไข',
  'oifde.common.actions.delete': 'ลบ',
  'oifde.common.actions.download': 'ดาวน์โหลด',
  'oifde.common.time.startDate': 'วันที่เริ่มต้น',
  'oifde.common.time.endDate': 'วันที่สิ้นสุด',
  'oifde.serviceInterface.oifdeInterface': 'อินเตอร์เฟซหุ่นยนต์อัจฉริยะ',
  'oifde.serviceInterface.ccivrInterface': 'คอนเน็กเตอร์ IVR ทั่วไป',
  'oifde.serviceInterface.jsonValidateSuccess': 'รูปแบบถูกต้อง',
  'oifde.serviceInterface.jsonChek': 'ตรวจสอบ JSON',
  'oifde.serviceInterface.interfaceCert': 'ใบรับรองอินเทอร์เฟซ',
  'oifde.serviceInterface.certCode': 'รหัส',
  'oifde.serviceInterface.certScene': 'แผนการ',
  'oifde.serviceInterface.expireTime': 'หมดอายุเมื่อ',
  'oifde.serviceInterface.certUpdateTime': 'อัปเดตเมื่อ',
  'oifde.serviceInterface.jsonValidate': 'เนื้อหาข้อความอยู่ในรูปแบบ JSON แบบ non-standard ตรวจสอบรูปแบบ',
  'oifde.serviceInterface.safeWarning': '* หมายเหตุ: โปรโตคอลเครือข่ายที่ใช้โดยอินเทอร์เฟซไม่ปลอดภัยและอาจก่อให้เกิดความเสี่ยงด้านความปลอดภัย',
  'oifde.serviceInterface.whiteListCheckWarning': 'URL (ที่อยู่ IP และหมายเลขพอร์ต) ไม่อยู่ในรายการที่เชื่อถือได้ และสามารถใช้ได้หลังจากที่คุณติดต่อผู้ดูแลระบบเพื่อเพิ่มลงในรายการที่เชื่อถือได้เท่านั้น',
  'oifde.serviceInterface.interfaceName': 'ชื่ออินเทอร์เฟซ',
  'oifde.serviceInterface.bizCode': 'รหัสธุรกิจ',
  'oifde.serviceInterface.description': 'คำอธิบาย',
  'oifde.serviceInterface.updatetime': 'เวลาอัปเดต',
  'oifde.serviceInterface.operation': 'การดำเนินการ',
  'oifde.serviceInterface.addServiceInterface': 'เพิ่มการกำหนดค่าอินเทอร์เฟซทางธุรกิจ',
  'oifde.serviceInterface.modifyServiceInterface': 'แก้ไขการกำหนดค่าอินเทอร์เฟซทางธุรกิจ',
  'oifde.serviceInterface.baseInfo': 'ข้อมูลพื้นฐาน',
  'oifde.serviceInterface.reqURL': 'URL คำขอ',
  'oifde.serviceInterface.busiCode': 'รหัสอินเทอร์เฟซ',
  'oifde.serviceInterface.interfaceType': 'วิธีการร้องขอ',
  'oifde.serviceInterface.required': 'จำเป็น',
  'oifde.serviceInterface.query_param': 'พารามิเตอร์การสืบค้น',
  'oifde.serviceInterface.pathParamValue': 'ค่า',
  'oifde.serviceInterface.outParamPath': 'เส้นทาง',
  'oifde.serviceInterface.in_param': 'พารามิเตอร์อินพุต',
  'oifde.serviceInterface.out_param': 'พารามิเตอร์เอาต์พุต',
  'oifde.serviceInterface.name': 'ชื่อ',
  'oifde.serviceInterface.dataType': 'ประเภทข้อมูล',
  'oifde.serviceInterface.encryptFlag': 'การเข้ารหัส',
  'oifde.serviceInterface.dataTypes.INT': 'จำนวนเต็ม',
  'oifde.serviceInterface.dataTypes.STRING': 'อักขระ',
  'oifde.serviceInterface.dataTypes.FLOAT': 'เลขทศนิยม',
  'oifde.serviceInterface.dataTypes.LONG': 'จำนวนเต็มแบบยาว',
  'oifde.serviceInterface.dataTypes.LIST': 'รายการ',
  'oifde.serviceInterface.dataTypes.OBJECT': 'วัตถุ',
  'oifde.serviceInterface.msgHeaders': 'ส่วนหัวของข้อความ',
  'oifde.serviceInterface.msgBody': 'เนื้อหาของข้อความ',
  'oifde.serviceInterface.headerKey': 'คีย์',
  'oifde.serviceInterface.headerValue': 'ค่า',
  'oifde.serviceInterface.deleteConfirm': 'อินเทอร์เฟซทางธุรกิจจะถูกลบอย่างถาวร คุณต้องการทำต่อไปหรือไม่?',
  'oifde.serviceInterface.miss_ifs_name': 'ฟิลด์บังคับขาดหายไป: ข้อมูลพื้นฐาน> ชื่ออินเทอร์เฟซ',
  'oifde.serviceInterface.prompt': 'ข้อความพร้อมรับ',
  'oifde.serviceInterface.ok': 'ตกลง',
  'oifde.serviceInterface.miss_ifs_request_url': 'ฟิลด์บังคับขาดหายไป: ข้อมูลพื้นฐาน> URL คำขอ',
  'oifde.serviceInterface.miss_ifs_in_parameter_name': 'ฟิลด์บังคับขาดหายไป: พารามิเตอร์อินพุต> ชื่อ',
  'oifde.serviceInterface.miss_ifs_out_parameter_name': 'ฟิลด์บังคับขาดหายไป: พารามิเตอร์เอาต์พุต> ชื่อ',
  'oifde.serviceInterface.miss_ifs_out_parameter_type': 'ฟิลด์บังคับขาดหายไป: พารามิเตอร์เอาต์พุต> ประเภทข้อมูล',
  'oifde.serviceInterface.miss_ifs_header_key': 'ฟิลด์บังคับขาดหายไป: ส่วนหัวของข้อความ> คีย์',
  'oifde.serviceInterface.miss_ifs_msgBody': 'ฟิลด์บังคับขาดหายไป: เนื้อหาข้อความ',
  'oifde.serviceInterface.auto_msgBody': 'เนื้อหาข้อความสร้างขึ้นโดยอัตโนมัติ',
  'oifde.serviceInterface.manual_msgBody': 'กรอกเนื้อหาข้อความด้วยตนเอง',
  'oifde.serviceInterface.promptMessage': 'กรอกเนื้อหาข้อความ',
  'oifde.serviceInterface.actions.cancelSuccess': 'ยกเลิกการบันทึก',
  'oifde.serviceInterface.actions.auto_savePrompt': 'การดําเนินการนี้จะบันทึก「เนื้อหาข้อความอัตโนมัติ」เป็นการกําหนดค่าอินเทอร์เฟซบริการ คุณแน่ใจหรือว่าต้องการดําเนินการต่อ?',
  'oifde.serviceInterface.actions.urlNotInWhitelist': 'URL (ที่อยู่ IP และหมายเลขพอร์ต) ไม่อยู่ในรายการที่เชื่อถือได้ สามารถใช้ได้เฉพาะหลังจากที่คุณติดต่อผู้ดูแลระบบเพื่อเพิ่ม URL ไปยังรายการที่เชื่อถือได้เท่านั้น ไปต่อเหรอ?',
  'oifde.serviceInterface.actions.manual_savePrompt': 'การดําเนินการนี้จะบันทึก「เนื้อหาข้อความด้วยตนเอง」เป็นการกําหนดค่าอินเทอร์เฟซบริการ คุณแน่ใจหรือว่าต้องการดําเนินการต่อ?',
  'oifde.serviceInterface.actions.prompt': 'ยืนยัน',
  'oifde.serviceInterface.actions.confirm': 'ใช่ค่ะ',
  'oifde.serviceInterface.actions.cancel': 'ไม่ ไม่',
  'oifde.sysMgr.sysCfg': 'การตั้งค่าระบบ',
  'oifde.sysMgr.apiKeyCfg': 'การตั้งค่ารหัสผ่าน API',
  'oifde.sysMgr.configuration': 'ตั้ง',
  'oifde.sysMgr.apiKey': 'รหัสผ่าน API',
  'oifde.sysMgr.apiKeyComfirm': 'ยืนยันรหัสผ่าน API',
  'oifde.sysMgr.apiKeyNotSame': 'รหัสผ่าน API 2 รหัสไม่ตรงกัน',
  'oifde.sysMgr.inputKey': 'กรอกรหัสผ่าน',
  'oifde.sysMgr.cancel': 'ยกเลิก',
  'oifde.sysMgr.save': 'บันทึก',
  'oifde.sysMgr.apiRuleRange': 'รหัสผ่านต้องมีอย่างน้อยสองประเภทดังต่อไปนี้: ตัวเลข ตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก และอักขระพิเศษ (~ `!{\'@\'}#{\'$\'}%^*()-+_={\'|\'},./<>?;\':"[]{\'{\'}{\'}\'}&).',
'oifde.sysMgr.apiNoSame': 'รหัสผ่านต้องไม่เหมือนกับ ID พื้นที่ของผู้เช่า',
  'oifde.sysMgr.success_set_key': 'ตั้งรหัสผ่าน API สำเร็จแล้ว',
  'oifde.sysMgr.requiredAPIKey': 'จำเป็นต้องมีรหัสผ่าน API',
  'oifde.sysMgr.APIKeyLenCheck': 'รหัสผ่าน API ต้องมีอักขระ 16 ถึง 64 ตัว',
  'oifde.sysMgr.dataClean.setTime': 'ตั้งค่าระยะเวลาการล้างข้อมูล',
  'oifde.sysMgr.dataClean.serviceName': 'ชื่อพารามิเตอร์',
  'oifde.sysMgr.dataClean.paramValue': 'ค่า',
  'oifde.sysMgr.dataClean.description': 'คำอธิบาย',
  'oifde.sysMgr.dataClean.descriptionContent': 'ตั้งค่าระยะเวลาการล้างข้อมูล',
  'oifde.sysMgr.dataClean.operation': 'การดำเนินการ',
  'oifde.sysMgr.dataClean.editTime': 'ปรับเปลี่ยนระยะเวลาการล้างข้อมูล',
  'oifde.sysMgr.dataClean.day': 'วัน',
  'oifde.sysMgr.dataClean.flagDescription': 'เปิดใช้งานการบันทึกในโฟลว์การโทรหรือไม่',
  'oifde.sysMgr.dataClean.editFlag': 'ปรับเปลี่ยนการบันทึกในโฟลว์การโทร',
  'oifde.sysMgr.dataClean.open': 'เปิดใช้งาน',
  'oifde.sysMgr.dataClean.close': 'ปิดใช้งาน',
  'oifde.sysMgr.dataClean.record': 'บันทึก',
  'oifde.sysMgr.dataClean.noRecord': 'ไม่บันทึก',
  'oifde.sysMgr.dataClean.second': 's',
  'oifde.sysMgr.dataClean.reportFlagDesc': 'บันทึกเคสทดสอบลงในรายงานหรือไม่',
  'oifde.sysMgr.dataClean.reportFlagTitle': 'ปรับเปลี่ยนตัวบ่งชี้การบันทึกเคสทดสอบ',
  'oifde.sysMgr.dataClean.sessionDTDesc': 'ช่วงหมดเวลาสำหรับโฟลว์การโต้ตอบ',
  'oifde.sysMgr.dataClean.editSessionDT': 'ปรับเปลี่ยนช่วงหมดเวลาของโฟลว์การโต้ตอบ',
  'oifde.sysMgr.dataClean.slientHelpDesc': 'เวลาในการรอการตอบกลับหลังจากเจ้าหน้าที่เงียบ',
  'oifde.sysMgr.dataClean.editSlientHelp': 'ปรับเปลี่ยนเวลารอการตอบกลับหลังจากเจ้าหน้าที่เงียบ',
  'oifde.sysMgr.dataClean.confidenceDesc': 'ค่าเกณฑ์ความเชื่อมั่นสำหรับเอเจนต์ที่ไม่มีการตอบสนองจะจดจำการค้นหาความช่วยเหลือเดียวที่หามาจากแชทบอท',
  'oifde.sysMgr.dataClean.confidence': 'แก้ไขความเชื่อมั่น',
  'oifde.sysMgr.dataClean.tcaContent': 'รายการโดเมนที่เชื่อมโยงกับการวิเคราะห์ข้อความ',
  'oifde.sysMgr.dataClean.editTcaFlag': 'แก้ไขรายการโดเมนที่เชื่อมโยงกับการวิเคราะห์ข้อความ',
  'oifde.sysMgr.dataClean.paramValueTca': 'ค่าพารามิเตอร์',
  'oifde.sysMgr.encryptSecretCfg': 'การตั้งค่าคีย์',
  'oifde.sysMgr.encryptSecret': 'คีย์',
  'oifde.sysMgr.encryptSecretComfirm': 'การยืนยันคีย์',
  'oifde.sysMgr.encryptSecretNotSame': 'คีย์ทั้งสองไม่ตรงกัน',
  'oifde.sysMgr.inputEncryptSecret': 'กรอกคีย์',
  'oifde.sysMgr.success_set_encryptSecret': 'ตั้งค่าคีย์สำเร็จแล้ว',
  'oifde.sysMgr.encryptSecretRuleRange': 'การเข้ารหัสลับไม่ตรงตามข้อกำหนด ต้องมีตัวเลข ตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก หรืออักขระพิเศษ 2 ชนิดหรือมากกว่า ช่วงของอักขระพิเศษคือ ~`!{\'@\'}#{\'$\'}%^*()-+_={\'|\'},./<>?;\':"[]{\'{\'}{\'}\'}&',
  'oifde.sysMgr.encryptSecretNoSame': 'คีย์ไม่สามารถเหมือนกับ ID พื้นที่ของผู้เช่า',
  'oifde.sysMgr.encryptSecretLenCheck': 'คีย์ต้องมีอักขระ 16 ตัว',
  'oifde.sysMgr.parameterSetting': 'การกำหนดค่าพารามิเตอร์เครื่องยนต์อัจฉริยะ',
  'oifde.sysMgr.variableSecretNotExists': 'ตัวแปรใช้ชนิดการเข้ารหัส โปรดไปที่การตั้งค่าการจัดการระบบ-> การตั้งค่า -> ลับเพื่อตั้งค่าคีย์ลับก่อน.',
  'oifde.route.flow': 'การเรียบเรียงโฟลว์',
  'oifde.route.rule': 'กฎโฟลว์สีเทา',
  'oifde.route.trace': 'ติดตามโฟลว์',
  'oifde.route.flowAnalysis': 'การวิเคราะห์การไหล',
  'oifde.route.ivrOverview': 'ภาพรวม IVR',
  'oifde.route.indicatorAnalysis': 'การวิเคราะห์ตัวบ่งชี้',
  'oifde.route.versionComparisonAndAnalysis': 'การเปรียบเทียบและการวิเคราะห์เวอร์ชัน',
  'oifde.route.optimizationSuggestions': 'ข้อเสนอแนะการเพิ่มประสิทธิภาพ',
  'oifde.route.alarmEvent': 'เหตุการณ์ปลุก',
  'oifde.route.addDiagram': 'เพิ่มโฟลว์',
  'oifde.route.editCellDiagram': 'แก้ไขแคนวาสคอมโพสิต',
  'oifde.route.edit': 'แก้ไข {0}',
  'oifde.route.view': 'ดู {0}',
  'oifde.route.editDiagram': 'แก้ไขโฟลว',
  'oifde.route.viewDiagram': 'ดูโฟลว์',
  'oifde.route.viewTraceDiagram': 'ดูการติดตามโฟลว์',
  'oifde.route.viewCellTraceDiagram': 'ดูการติดตามองค์ประกอบแผนภาพคอมโพสิต',
  'oifde.route.voiceTemplateTitle': 'เทมเพลตทรัพยากร',
  'oifde.route.staticCheck': 'ตรวจสอบโฟลว์',
  'oifde.route.toc.sysadmin': 'ความเข้าใจความหมาย',
  'oifde.route.toc.user': 'ความเข้าใจความหมาย',
  'oifde.route.unusedResource.complexcell': 'ดูองค์ประกอบแผนภาพคอมโพสิตที่ไม่ได้ใช้',
  'oifde.route.unusedResource.interface': 'ดูอินเทอร์เฟซที่ไม่ได้ใช้',
  'oifde.route.unusedResource.procedure': 'ดูขั้นตอนที่จัดเก็บไว้ที่ไม่ได้ใช้',
  'oifde.route.unusedResource.rule': 'ดูกฎสีเทาที่ไม่ได้ใช้',
  'oifde.route.unusedResource.template': 'ดูเทมเพลตที่ไม่ได้ใช้',
  'oifde.route.unusedResource.globalvars': 'ดูตัวแปรส่วนกลางที่ไม่ได้ใช้',
  'oifde.route.unusedResource.flowvars': 'ดูตัวแปรโฟลว์ที่ไม่ได้ใช้',
  'oifde.route.tuc.template.index': 'การจัดการความรู้',
  'oifde.route.tuc.template.domain': 'การจัดการโดเมน',
  'oifde.route.tuc.template.intent': 'การจัดการเจตนา',
  'oifde.route.tuc.template.entity': 'การจัดการเอนทิตี',
  'oifde.route.tuc.template.faq': 'การจัดการคำถามที่พบบ่อย',
  'oifde.route.tuc.template.kg': 'การจัดการกราฟ',
  'oifde.route.tuc.template.config': 'การปรับแต่งระบบ',
  'oifde.route.tuc.template.bwlist': 'Blocklist และ Trustlist',
  'oifde.route.tuc.history.index': 'ประวัติการโต้ตอบ',
  'oifde.route.tuc.history.dialog': 'การตรวจทานข้อความในอดีต',
  'oifde.route.tuc.history.mining': 'งานการจัดกลุ่มคำถาม',
  'oifde.route.tuc.ops.index': 'ตรวจสอบการฝึก',
  'oifde.route.tuc.ops.test': 'การทดสอบการโต้ตอบ',
  'oifde.route.tuc.ops.tucTest': 'การทดสอบความรู้',
  'oifde.route.tuc.ops.perf': 'สถิติประสิทธิภาพ',
  'oifde.route.tuc.ops.node': 'การจัดการการบำรุงรักษา',
  'oifde.route.tuc.ops.log': 'การลงบันทึกการดำเนินการ',
  'oifde.route.tuc.ops.corpustest': 'การทดสอบคลังข้อมูล',
  'oifde.route.tuc.ops.server': 'การจัดการเซิร์ฟเวอร์',
  'oifde.route.tuc.ops.qiAsrServer': 'การตรวจสอบ ASR',
  'oifde.route.tuc.ops.asr': 'TTS/ASR',
  'oifde.route.tuc.ops.avatar': 'มนุษย์เสมือนจริง 2 มิติ',
  'oifde.route.tuc.ops.modelMgr': 'โมเดลการวิเคราะห์ความรู้สึก',
  'oifde.route.tuc.ops.sysparam': 'การกำหนดค่าพารามิเตอร์เครื่องยนต์อัจฉริยะ',
  'oifde.route.tuc.ops.kgrule': 'กฎสีเทาของความรู้',
  'oifde.route.tuc.ops.license': 'การจัดการใบอนุญาต',
  'oifde.route.keyPressRobot': 'IVR ปกติ',
  'oifde.route.RobotManage': 'แชทบอทอัจฉริยะ',
  'oifde.route.accessCodeManage': 'รหัสการเข้าถึง',
  'oifde.route.sysMgr.sysMgr': 'การจัดการระบบ',
  'oifde.route.sysMgr.sysCfg': 'การปรับแต่งระบบ',
  'oifde.route.sysMgr.whiteList': 'รายการที่อนุญาต',
  'oifde.route.sysMgr.recordHistory': 'การโทรในอดีต',
  'oifde.route.navigation': 'การจัดการโฟลว์',
  'oifde.route.resources': 'การจัดการทรัพยากร',
  'oifde.route.serviceInterface': 'อินเทอร์เฟซธุรกิจ',
  'oifde.route.variable': 'การจัดการตัวแปร',
  'oifde.route.cell': 'เซลล์ที่ซับซ้อน',
  'oifde.route.procedure': 'กระบวนงานที่จัดเก็บ',
  'oifde.route.dataTable': 'เอกสารข้อมูล',
  'oifde.route.knowledgegraph.knowledgegraph': 'กราฟความรู้',
  'oifde.route.knowledgegraph.kgModel': 'การสร้างแบบจำลองความรู้',
  'oifde.route.knowledgegraph.editSchema': 'รายละเอียด Schema- {0}',
  'oifde.route.knowledgegraph.dicmgr': 'พจนานุกรมกราฟ',
  'oifde.route.knowledgegraph.entitymgr': '{0} การจัดการเอนทิตี',
  'oifde.route.knowledgegraph.entityAttribute': '{0} แอตทริบิวต์ของเอนทิตี',
  'oifde.route.knowledgegraph.relationmgr': '{0} การจัดการความสัมพันธ์',
  'oifde.route.knowledgegraph.relationAttribute': '{0} แอตทริบิวต์ความสัมพันธ์',
  'oifde.route.knowledgegraph.kgView': '{0} การแสดงภาพกราฟ',
  'oifde.route.knowledgegraph.kgExport': 'การส่งออกกราฟ',
  'oifde.route.knowledgegraph.importExcel': 'การนำเข้ากราฟ',
  'oifde.route.knowledgegraph.kgFAQ': '{0} คำถามที่พบบ่อยเกี่ยวกับกราฟ',
  'oifde.route.knowledgegraph.kgSynonyms': '{0} คำพ้องความหมาย',
  'oifde.route.knowledgegraph.extendQuestion': 'คำถามเพิ่มเติม',
  'oifde.flow.flowTypeSelect': 'เลือกเทมเพลตโฟลว์ใหม่',
  'oifde.flow.flowTemplate': 'เทมเพลตโฟลว์',
  'oifde.flow.code': 'รหัส',
  'oifde.flow.name': 'ชื่อ',
  'oifde.flow.version': 'เวอร์ชัน',
  'oifde.flow.scenarioType': 'ประเภทแผนการ',
  'oifde.flow.status': 'สถานะ',
  'oifde.flow.rule': 'กฎสีเทา',
  'oifde.flow.createTime': 'เวลาที่สร้าง',
  'oifde.flow.updateTime': 'เวลาที่ปรับเปลี่ยน',
  'oifde.flow.grayFlow': 'สีเทา',
  'oifde.flow.noRule': 'ไม่มีการกำหนดค่ากฎ',
  'oifde.flow.defaultFlow': 'โฟลว์เริ่มต้น',
  'oifde.flow.actions.publish': 'เผยแพร่',
  'oifde.flow.actions.delete': 'ลบ',
  'oifde.flow.actions.edit': 'แก้ไข',
  'oifde.flow.actions.invalid': 'ทำให้ใช้การไม่ได้',
  'oifde.flow.actions.upgrade': 'อัปเกรด',
  'oifde.flow.actions.copy': 'คัดลอกโฟลว์',
  'oifde.flow.actions.copySuccess': 'คัดลอกโฟลว์สำเร็จแล้ว',
  'oifde.flow.actions.copyFailed': 'คัดลอกโฟลว์ล้มเหลว',
  'oifde.flow.actions.setting': 'ตั้ง',
  'oifde.flow.actions.deleteConfirm': 'การดำเนินการนี้จะลบโฟลว์อย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.flow.actions.deleteCancel': 'การลบยกเลิกแล้ว',
  'oifde.flow.actions.deleteOk': 'การลบสำเร็จแล้ว',
  'oifde.flow.actions.invalidConfirm': 'ปฏิบัติการนี้จะทําให้กระแสหลุดลอย คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.flow.actions.invalidOk': 'กระแสไม่ได้เผยแพร่',
  'oifde.flow.actions.invalidCancel': 'ยกเลิกการทำให้ใช้การไม่ได้',
  'oifde.flow.actions.createOk': 'สร้างโฟลว์เวอร์ชันใหม่สำเร็จแล้ว',
  'oifde.flow.actions.settingOk': 'กำหนดค่าโฟลว์สำเร็จแล้ว',
  'oifde.flow.actions.publishOk': 'เผยแพร่สำเร็จ',
  'oifde.flow.actions.confirm': 'ตกลง',
  'oifde.flow.actions.prompt': 'เคล็ดลับ',
  'oifde.flow.actions.cancel': 'ยกเลิก',
  'oifde.flow.actions.staticcheck': 'การตรวจสอบแบบคงที่',
  'oifde.flow.actions.error': 'การดำเนินการล้มเหลว',
  'oifde.flow.actions.errorCode': 'รหัสข้อผิดพลาด',
  'oifde.flow.actions.errorMessage': 'ข้อความแสดงข้อผิดพลาด',
  'oifde.flow.rules.ruleId': 'กฎเป็นสิ่งจำเป็น',
  'oifde.flow.rules.upgradeMode': 'โหมดการเผยแพร่เป็นสิ่งจำเป็น',
  'oifde.flow.rules.logSwitchOn': 'ฟังก์ชันการลงบันทึกเป็นสิ่งจำเป็น',
  'oifde.flow.rules.validationMode': 'จำเป็นสำหรับการตรวจสอบความถูกต้องที่เข้มงวด',
  'oifde.flow.rules.newVersion': 'เวอร์ชันใหม่เป็นสิ่งจำเป็น',
  'oifde.flow.rules.newVersionDuplicated': 'เวอร์ชันใหม่ต้องเป็นเอกลักษณ์',
  'oifde.flow.rules.newVersionLength': 'เวอร์ชันใหม่ต้องมีอักขระไม่เกิน 64 ตัว',
  'oifde.flow.rules.newFlowName': 'ชื่อโฟลว์ใหม่เป็นสิ่งจำเป็น',
  'oifde.flow.rules.workMode': 'โหมดการทำงานเป็นสิ่งจำเป็น',
  'oifde.flow.enums.scenarioType.mainFlow': 'โฟลว์หลัก',
  'oifde.flow.enums.scenarioType.errorFlow': 'โฟลว์ยกเว้น',
  'oifde.flow.enums.scenarioType.subFlow': 'โฟลว์ย่อย',
  'oifde.flow.enums.status.init': 'ฉบับร่าง',
  'oifde.flow.enums.status.valid': 'เผยแพร่',
  'oifde.flow.enums.status.invalid': 'ทำให้ใช้การไม่ได้',
  'oifde.flow.enums.tabsValue.all': 'ส่งออกทั้งหมด',
  'oifde.flow.enums.tabsValue.none': 'ไม่มีการส่งออก',
  'oifde.flow.enums.tabsValue.depend': 'การส่งออกที่พึ่งพา',
  'oifde.flow.publish.flowName': 'ชื่อ',
  'oifde.flow.publish.flowVersion': 'เวอร์ชัน',
  'oifde.flow.publish.upgradeMode': 'โหมดการเผยแพร่',
  'oifde.flow.publish.upgrade': 'อัปเกรดและเผยแพร่',
  'oifde.flow.publish.gray': 'การเผยแพร่แบบสีเทา',
  'oifde.flow.publish.ruleName': 'กฎสีเทา',
  'oifde.flow.publish.selectPlaceholder': 'เลือก',
  'oifde.flow.publish.publishMode': 'โหมดการทำงาน',
  'oifde.flow.publish.normal': 'ทั่วไป',
  'oifde.flow.publish.monitor': 'เฝ้าสังเกต',
  'oifde.flow.publish.insert': 'แทรก',
  'oifde.flow.publish.notConfig': 'ไม่ได้กำหนดค่า',
  'oifde.flow.copy.flowName': 'ชื่อโฟลว์เดิม',
  'oifde.flow.copy.newFlowName': 'ชื่อโฟลวใหม่',
  'oifde.flow.setting.logSwitch': 'ฟังก์ชั่นการลงบันทึก',
  'oifde.flow.setting.logSwitchOn': 'เปิดใช้งาน',
  'oifde.flow.setting.logSwitchOff': 'ปิดใช้งาน',
  'oifde.flow.setting.title': 'การตั้งค่า',
  'oifde.flow.setting.validationMode': 'การตรวจสอบความถูกต้องที่เข้มงวด',
  'oifde.flow.setting.validationModeOn': 'เปิดใช้งานแล้ว',
  'oifde.flow.setting.validationModeOff': 'ปิดใช้งานแล้ว',
  'oifde.flow.upgrade.currentVersions': 'รายการเวอร์ชันปัจจุบัน',
  'oifde.flow.upgrade.newVersion': 'เวอร์ชั่นใหม่',
  'oifde.flow.exportFlowList.accessCode': 'รหัสการเข้าถึง',
  'oifde.flow.exportFlowList.rule': 'กฎ',
  'oifde.flow.exportFlowList.template': 'เทมเพลต',
  'oifde.flow.exportFlowList.interface': 'อินเทอร์เฟซ',
  'oifde.flow.exportFlowList.variable': 'ตัวแปร',
  'oifde.flow.exportFlowList.title': 'คุณยังไม่ได้เลือกเร็กคอร์ดที่ต้องการ ส่งออกบันทึกทั้งหมดหรือไม่? คุณยังสามารถยกเลิกการดําเนินการและเลือกเร็กคอร์ดที่ต้องการส่งออกได้',
  'oifde.flow.exportFlowList.askExportAll': 'คุณยังไม่ได้เลือกเร็กคอร์ดที่ต้องการ ส่งออกบันทึกทั้งหมดหรือไม่? คุณยังสามารถยกเลิกการดําเนินการและเลือกเร็กคอร์ดที่ต้องการส่งออกได้',
  'oifde.flow.exportFlowList.prompt': 'ข้อความพร้อมรับ',
  'oifde.flow.exportFlowList.promptMessage': 'เลือกอย่างน้อยหนึ่งการบันทึก',
  'oifde.flow.exportFlowList.export': 'ส่งออก',
  'oifde.flow.exportFlowList.exportAll': 'ส่งออกทั้งหมด',
  'oifde.flow.exportFlowList.procedure': 'กระบวนงานที่จัดเก็บ',
  'oifde.flow.exportFlowList.complexCell': 'องค์ประกอบแผนภาพคอมโพสิต',
  'oifde.flow.importFlow.title': 'นำเข้าโฟลว์',
  'oifde.flow.importFlow.select': 'เลือกไฟล์',
  'oifde.flow.importFlow.upload': 'อัปโหลด',
  'oifde.flow.importFlow.prompt': 'สามารถนำเข้าไฟล์ ZIP ได้',
  'oifde.flow.importFlow.cover': 'อนุญาตให้มีชื่อซ้ำกัน',
  'oifde.flow.importFlow.resultTitle': 'ผลลัพธ์การนำเข้า',
  'oifde.flow.importFlow.name': 'ชื่อ',
  'oifde.flow.importFlow.type': 'ประเภท',
  'oifde.flow.importFlow.result': 'ผลลัพธ์',
  'oifde.flow.importFlow.error': 'ข้อความพร้อมรับ',
  'oifde.flow.importFlow.message': 'เลือกไฟล์ที่คุณต้องการอัปโหลด',
  'oifde.flow.importFlow.fileType': 'รูปแบบของไฟล์ที่จะอัปโหลดต้องเป็น ZIP',
  'oifde.flow.importFlow.import': 'นำเข้า',
  'oifde.flow.importFlow.success': 'การดำเนินการสำเร็จ',
  'oifde.flow.importFlow.bigFile': 'ไฟล์ที่จะอัปโหลดมีขนาดใหญ่เกินไป',
  'oifde.flow.importFlow.uploadFail': 'การอัปโหลดล้มเหลว',
  'oifde.flow.importFlow.bigFilePrompt': 'ขนาดของไฟล์ที่จะอัปโหลดใหญ่กว่า 20 MB',
  'oifde.flow.importFlow.timeout': 'กําลังนําเข้าข้อมูล... โปรดสอบถามข้อมูลล่าสุดในภายหลัง',
  'oifde.flow.usage.usageStatus': 'สถานะสถิติ:',
  'oifde.flow.usage.lasttime': 'เวลาเสร็จสมบูรณ์ล่าสุด:',
  'oifde.flow.usage.check': 'ตรวจสอบ',
  'oifde.flow.usage.showUnused': 'ดูทรัพยากรที่ไม่ได้ใช้',
  'oifde.flow.usage.complexcellUsageTitle': 'สถิติการใช้องค์ประกอบแผนภาพคอมโพสิต',
  'oifde.flow.usage.interfaceUsageTitle': 'สถิติการใช้งานอินเทอร์เฟซ',
  'oifde.flow.usage.procedureUsageTitle': 'สถิติการใช้งานกระบวนงานที่เก็บไว้',
  'oifde.flow.usage.ruleUsageTitle': 'สถิติการใช้กฎสีเทา',
  'oifde.flow.usage.templateUsageTitle': 'สถิติการใช้เทมเพลต',
  'oifde.flow.usage.globalVarsUsageTitle': 'สถิติการใช้ตัวแปรส่วนกลาง',
  'oifde.flow.usage.flowVarsUsageTitle': 'สถิติการใช้ตัวแปรโฟลว์',
  'oifde.flow.usage.inuse': 'อยู่ในการใช้งาน',
  'oifde.flow.usage.unuse': 'ไม่เคยใช้',
  'oifde.flow.usage.status.norecord': 'ไม่มีการบันทึก',
  'oifde.flow.usage.status.init': 'จะถูกดำเนินการ',
  'oifde.flow.usage.status.running': 'กำลังดำเนินการ',
  'oifde.flow.usage.status.finish': 'เสร็จสมบูรณ์',
  'oifde.flow.usage.status.fail': 'ล้มเหลว',
  'oifde.flow.usage.name': 'ชื่อ',
  'oifde.flow.usage.type': 'ประเภท',
  'oifde.flow.usage.desc': 'คำอธิบาย',
  'oifde.flow.usage.resourceType.complexcell': 'องค์ประกอบแผนภาพคอมโพสิต',
  'oifde.flow.usage.resourceType.interface': 'อินเทอร์เฟซ',
  'oifde.flow.usage.resourceType.procedure': 'กระบวนงานที่จัดเก็บ',
  'oifde.flow.usage.resourceType.rule': 'กฎสีเทา',
  'oifde.flow.usage.resourceType.template': 'เทมเพลต',
  'oifde.flow.usage.resourceType.globalvars': 'ตัวแปรส่วนกลาง',
  'oifde.flow.usage.resourceType.flowvars': 'ตัวแปรโฟลว์',
  'oifde.flow.add.blankFlow': 'โฟลว์ที่ว่างเปล่า',
  'oifde.flow.add.callInFlow': 'โฟลว์การโทรเข้า',
  'oifde.flow.add.outBoundFlow': 'โฟลว์การโทรออก',
  'oifde.flow.add.exceptionFlow': 'โฟลว์ผิดปกติ',
  'oifde.template.setInputFlag': 'ป้อนเนื้อหาด้วยตนเอง',
  'oifde.template.sysErrorTemplateTitle': 'เทมเพลตทรัพยากรผิดปกติ',
  'oifde.template.sysErrorTemplate': 'แก้ไขเทมเพลตทรัพยากรที่ผิดปกติ',
  'oifde.template.contentDetail': 'รายละเอียด',
  'oifde.template.aiccTemplateNullTips': 'ประเภทเทมเพลตต้องไม่ว่างเปล่า',
  'oifde.template.template': 'การจัดการเทมเพลตทรัพยากร',
  'oifde.template.channelTypeMgr': 'การจัดการประเภทช่อง',
  'oifde.template.responseStyleMgr': 'การจัดการสไตล์',
  'oifde.template.channelTypeName': 'ชื่อช่อง',
  'oifde.template.createChannelType': 'เพิ่มช่อง',
  'oifde.template.editChannelType': 'แก้ไขช่อง',
  'oifde.template.responseStyleName': 'ชื่อสไตล์',
  'oifde.template.createResponseStyle': 'เพิ่มสไตล์',
  'oifde.template.editResponseStyle': 'แก้ไขสไตล์',
  'oifde.template.selectChannelType': 'เลือกช่อง',
  'oifde.template.selectResponseStyle': 'เลือกสไตล์',
  'oifde.template.selectLang': 'เลือกภาษา',
  'oifde.template.editContent': 'เพิ่มเนื้อหาแม่แบบ',
  'oifde.template.setDefault': 'ตั้งเป็นค่าเริ่มต้น',
  'oifde.template.name': 'ชื่อ',
  'oifde.template.type': 'ประเภทเทมเพลต',
  'oifde.template.desc': 'คำอธิบาย',
  'oifde.template.content': 'เนื้อหาเทมเพลต',
  'oifde.template.createTime': 'เวลาที่สร้าง',
  'oifde.template.updateTime': 'เวลาที่ปรับเปลี่ยน',
  'oifde.template.detail': 'รายละเอียดเนื้อหาเทมเพลต',
  'oifde.template.select': 'ประเภทเทมเพลต',
  'oifde.template.contentName': 'เนื้อหา',
  'oifde.template.deleteContent': 'ลบ',
  'oifde.template.addContent': 'เพิ่มเนื้อหาเทมเพลต',
  'oifde.template.tipContent': 'กรอกเนื้อหาข้อความที่นี่',
  'oifde.template.tipForVoice': 'ป้อนเส้นทางที่สมบูรณ์ของไฟล์เสียง',
  'oifde.template.tipForVideo': 'ป้อนเส้นทางที่สมบูรณ์ของไฟล์วิดีโอ',
  'oifde.template.addLimit': 'อนุญาตให้มีการบันทึกเนื้อหาได้สูงสุดสามรายการ',
  'oifde.template.deleteLimit': 'ต้องมีการบันทึกเนื้อหาอย่างน้อยหนึ่งรายการ',
  'oifde.template.selectLanguage': 'เลือกภาษา หากคุณต้องการลบภาษา ให้คลิกไอคอนปิด',
  'oifde.template.addNewIntent': 'เพิ่มเจตนา',
  'oifde.template.setIntentTemplate': 'การกำหนดค่าเทมเพลตเจตนา',
  'oifde.template.addTopNode': 'เพิ่มโหนดด้านบน',
  'oifde.template.selectTemplate': 'เลือกเท็มเพลต',
  'oifde.template.selectAction': 'เลือก',
  'oifde.template.actions.create': 'เพิ่ม',
  'oifde.template.actions.delete': 'ลบ',
  'oifde.template.actions.edit': 'แก้ไข',
  'oifde.template.actions.editbaseInfo': 'แก้ไขแม่แบบทรัพยากร',
  'oifde.template.actions.addbaseInfo': 'เพิ่มแม่แบบทรัพยากร',
  'oifde.template.actions.success': 'สำเร็จ',
  'oifde.template.actions.fail': 'ล้มเหลว',
  'oifde.template.actions.createSuccess': 'การสร้างสำเร็จแล้ว',
  'oifde.template.actions.saveSuccess': 'การบันทึกสำเร็จแล้ว',
  'oifde.template.actions.updateSuccess': 'การปรับเปลี่ยนสำเร็จแล้ว',
  'oifde.template.actions.deleteSuccess': 'การลบสำเร็จแล้ว',
  'oifde.template.actions.cancelDelete': 'การลบยกเลิกแล้ว',
  'oifde.template.actions.deletePrompt': 'การดำเนินการนี้จะลบเทมเพลตอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.template.actions.deleteChannelType': 'การดำเนินการนี้จะลบช่องอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.template.actions.deleteResponseStyle': 'การดำเนินการนี้จะลบสไตล์อย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.template.actions.prompt': 'ข้อความพร้อมรับ',
  'oifde.template.actions.confirm': 'ตกลง',
  'oifde.template.actions.cancel': 'ยกเลิก',
  'oifde.template.actions.append': 'เพิ่ม',
  'oifde.template.update.templateName': 'ชื่อเทมเพลต',
  'oifde.template.update.templateType': 'ประเภทเทมเพลต',
  'oifde.template.update.templateDesc': 'คำอธิบาย',
  'oifde.template.update.intents': 'รายการเจตนา',
  'oifde.template.typeDetail.tts': 'TTS',
  'oifde.template.typeDetail.sms': 'SMS',
  'oifde.template.typeDetail.staticvoice': 'เสียงคงที่',
  'oifde.template.typeDetail.videovoice': 'ไฟล์วิดีโอ',
  'oifde.template.typeDetail.multiMedia': 'มัลติมีเดีย',
  'oifde.template.typeDetail.cardTemplate': 'เทมเพลตบัตร',
  'oifde.template.language.chinese': 'ภาษาจีน',
  'oifde.template.language.english': 'ภาษาอังกฤษ',
  'oifde.template.language.spanish': 'ภาษาสเปน',
  'oifde.template.validate.content': 'เนื้อหาเทมเพลตเป็นสิ่งจำเป็น',
  'oifde.template.validate.templateName': 'ชื่อเทมเพลตเป็นสิ่งจำเป็น',
  'oifde.template.validate.templateType': 'ประเภทเทมเพลตเป็นสิ่งจำเป็น',
  'oifde.template.validate.doubleNameCheck': 'มีชื่อเทมเพลตอยู่แล้ว',
  'oifde.template.validate.intentDsp': 'คำอธิบายเจตนาเป็นสิ่งจำเป็น',
  'oifde.template.validate.intentcode': 'รหัสเจตนาเป็นสิ่งจำเป็น',
  'oifde.template.validate.nameDup': 'มีชื่ออยู่แล้ว',
  'oifde.template.validate.contentLengthError': 'เนื้อหาเทมเพลตต้องไม่ว่างเปล่าหรือมีอักขระมากกว่า 4096 ตัว',
  'oifde.template.intentionInfo.addIntent': 'เพิ่มเจตนา',
  'oifde.template.intentionInfo.intentDsp': 'คำอธิบายเจตนา',
  'oifde.template.intentionInfo.intentcode': 'รหัสเจตนา',
  'oifde.template.intentionInfo.intentList': 'รายการเจตนา',
  'oifde.template.message.intentListLength': 'รายการเจตนาต้องมีอักขระไม่เกิน 4096 ตัว',
  'oifde.template.message.intentNameDuplicate': 'ชื่อเจตนาซ้ำกัน',
  'oifde.template.message.validateResult': 'ข้อความพร้อมรับ',
  'oifde.template.content.exist': 'เพิ่มเนื้อหาแม่แบบที่เลือกในปัจจุบันแล้ว',
  'oifde.template.selectResource': 'เลือกทรัพยากร',
  'oifde.template.message.searchResourceName': 'ค้นหาชื่อทรัพยากร',
  'oifde.template.title.ResourceName': 'ชื่อทรัพยากร',
  'oifde.template.title.ResourceType': 'ประเภททรัพยากร',
  'oifde.template.multipart.type.location': 'ที่อยู่',
  'oifde.rule.flowRule': 'กฎโฟลว์สีเทา',
  'oifde.rule.kgRule': 'กฎสีเทาของความรู้',
  'oifde.rule.name': 'ชื่อกฎ',
  'oifde.rule.beginNum': 'หมายเลขเริ่มต้น',
  'oifde.rule.endNum': 'หมายเลขสิ้นสุด',
  'oifde.rule.language': 'ภาษา',
  'oifde.rule.select': 'เลือก',
  'oifde.rule.number': 'จำนวน',
  'oifde.rule.manage': 'การจัดการ',
  'oifde.rule.actions.error': 'เกิดข้อผิดพลาดระหว่างการเรียกใช้อินเทอร์เฟซ',
  'oifde.rule.actions.errorCode': 'รหัสข้อผิดพลาด',
  'oifde.rule.actions.errorMessage': 'ข้อความแสดงข้อผิดพลาด',
  'oifde.rule.actions.create': 'เพิ่ม',
  'oifde.rule.actions.delete': 'ลบ',
  'oifde.rule.actions.edit': 'แก้ไข',
  'oifde.rule.actions.success': 'สำเร็จ',
  'oifde.rule.actions.addSuccess': 'เพิ่มสำเร็จแล้ว',
  'oifde.rule.actions.updateSuccess': 'อัปเดตสำเร็จแล้ว',
  'oifde.rule.actions.deleteSuccess': 'ลบสำเร็จแล้ว',
  'oifde.rule.actions.editbaseInfo': 'แก้ไขข้อมูลพื้นฐาน',
  'oifde.rule.actions.addbaseInfo': 'สร้างข้อมูลพื้นฐาน',
  'oifde.rule.actions.confirm': 'เคล็ดลับ',
  'oifde.rule.actions.sure': 'ตกลง',
  'oifde.rule.actions.cancel': 'ยกเลิก',
  'oifde.rule.actions.cancelDelete': 'การลบยกเลิกแล้ว',
  'oifde.rule.actions.deleteConfirm': 'การดำเนินการนี้จะลบกฎอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.rule.actions.deleteFailed': 'การลบล้มเหลว ตรวจสอบว่ากฎนั้นเกี่ยวข้องกับโฟลว์ที่เผยแพร่หรือไม่ ยกเลิกการเผยแพร่ก่อน',
  'oifde.rule.actions.viewBoundFlow': 'ดูกระบวนการที่เชื่อมโยงแล้ว',
  'oifde.rule.rules.ruleNameValidator': 'มีชื่อกฏอยู่แล้ว',
  'oifde.rule.rules.beginNumValidator': 'หมายเลขเริ่มต้นสามารถมีได้เฉพาะตัวอักษร ตัวเลข ขีดล่าง (_) ขีดกลาง (-) เครื่องหมายอัญประกาศเดี่ยว (\') เครื่องหมายทวิภาค (:) ช่องว่าง จุด (.) เครื่องหมายแอท ({\'@\'}) และเครื่องหมายทับ (/)',
'oifde.rule.rules.endNumValidator': 'หมายเลขสิ้นสุดสามารถมีได้เฉพาะตัวอักษร ตัวเลข ขีดล่าง (_) ขีดกลาง (-) เครื่องหมายอัญประกาศเดี่ยว (\') เครื่องหมายทวิภาค (:) ช่องว่าง จุด (.) เครื่องหมายแอท ({\'@\'}) และเครื่องหมายทับ (/)',
'oifde.rule.rules.beginNumLengthValidator': 'หมายเลขสิ้นสุดต้องมากกว่าหรือเท่ากับหมายเลขเริ่มต้น',
  'oifde.rule.update.ruleName': 'ชื่อกฎ',
  'oifde.rule.update.beginNum': 'หมายเลขเริ่มต้น',
  'oifde.rule.update.newRuleName': 'ชื่อกฎเป็นสิ่งจำเป็น',
  'oifde.rule.update.newBeginNum': 'หมายเลขเริ่มต้นเป็นสิ่งจำเป็น',
  'oifde.rule.update.newEndNum': 'หมายเลขสิ้นสุดเป็นสิ่งจำเป็น',
  'oifde.rule.update.endNum': 'หมายเลขสิ้นสุด',
  'oifde.rule.message.add_name_list': 'เพิ่มรายการบล็อก / รายการที่เชื่อถือได้',
  'oifde.rule.message.name_list_info': 'ข้อมูลรายการบล็อก / รายการที่เชื่อถือได้',
  'oifde.rule.message.succeed_save': 'บันทึกรายการบล็อก / รายการที่เชื่อถือได้สำเร็จ',
  'oifde.rule.message.fail_save': 'บันทึกรายการบล็อก / รายการที่เชื่อถือล้มเหลว',
  'oifde.rule.message.failed_query': 'การสืบค้นล้มเหลว',
  'oifde.rule.message.select_prompt': 'เลือกอย่างน้อยหนึ่งการบันทึก',
  'oifde.rule.message.confirm_name_list': 'คุณแน่ใจหรือไม่ว่าต้องการลบรายการบล็อก / รายการที่เชื่อถือได้?',
  'oifde.rule.message.succeed_delete': 'ลบรายการบล็อก / รายการที่เชื่อถือได้สำเร็จ',
  'oifde.rule.message.fail_delete': 'ลบรายการบล็อก / รายการที่เชื่อถือได้ล้มเหลว',
  'oifde.rule.message.mgr_name_list': 'จัดการรายการบล็อก / รายการที่เชื่อถือได้ -',
  'oifde.rule.message.add_user_ids': 'เพิ่มหมายเลขสมาชิก',
  'oifde.rule.message.batch_input_prompt': 'ทุกครั้งที่คุณกด Enter การบันทึกจะถูกเพิ่ม การบันทึกเดียวประกอบด้วยอักขระ 4 ถึง 64 ตัว',
  'oifde.rule.message.user_number': 'หมายเลขผู้ใช้',
  'oifde.rule.message.user_ids_number': 'หมายเลขผู้ใช้รายการบล็อกและเชื่อถือได้มีได้ถึง 5,000',
  'oifde.rule.message.user_ids_number_once': 'จำนวนหมายเลขผู้ใช้รายการบล็อกและเชื่อถือได้สูงสุดครั้งละ 100',
  'oifde.rule.message.user_id_not_exceed': 'หมายเลขสมาชิกเดียวประกอบด้วยอักขระ 4 ถึง 64 ตัว',
  'oifde.rule.message.save_user_ids_success': 'บันทึกหมายเลขสมาชิกสำเร็จ',
  'oifde.rule.message.save_user_ids_fail': 'บันทึกหมายเลขสมาชิกล้มเหลว',
  'oifde.rule.message.confirm_delete_user_ids': 'แน่ใจหรือไม่ว่าต้องการลบหมายเลขสมาชิก?',
  'oifde.rule.message.succeed_delete_user_ids': 'ลบหมายเลขสมาชิกสำเร็จ',
  'oifde.rule.message.failed_delete_user_ids': 'ลบหมายเลขสมาชิกล้มเหลว',
  'oifde.rule.message.import_user_ids': 'นำเข้าหมายเลขสมาชิก',
  'oifde.rule.message.import_words_hint_text': 'ไฟล์ข้อความสามารถนำเข้าได้ (*. txt) รูปแบบการเข้ารหัสไฟล์คือ UTF-8',
  'oifde.rule.message.overwrite_import': 'เขียนทับการกำหนดค่าที่มีอยู่',
  'oifde.rule.message.success_import': 'การนำเข้าสำเร็จ',
  'oifde.rule.message.failed_import_file': 'การนำเข้าไฟล์ล้มเหลว',
  'oifde.accesscode.usingRecord': 'บริการเซสชันการจับแพ็คเก็ต',
  'oifde.accesscode.messageTips': 'บอกสิ่งที่คุณไม่พึงพอใจ ฉันจะปรับปรุง',
  'oifde.accesscode.more': 'เพิ่มเติม',
  'oifde.accesscode.bindDomain': 'โดเมนที่ถูกผูกไว้',
  'oifde.accesscode.bindFaqGroup': 'กลุ่มคำถามที่พบบ่อยที่ถูกผูกไว้',
  'oifde.accesscode.bindKg': 'กราฟความรู้ที่ถูกผูกไว้',
  'oifde.accesscode.robotName': 'ชื่อ Chatbot',
  'oifde.accesscode.domain': 'โดเมน',
  'oifde.accesscode.faqGroup': 'กลุ่มคำถามที่พบบ่อย',
  'oifde.accesscode.knowledgeGraph': 'กราฟความรู้',
  'oifde.accesscode.setTrace': 'การตั้งค่าการติดตาม',
  'oifde.accesscode.callingNumberTrace': 'การติดตามหมายเลขการโทร:',
  'oifde.accesscode.traceOpen': 'เปิดใช้งาน',
  'oifde.accesscode.traceClose': 'ปิดใช้งาน',
  'oifde.accesscode.hideTraceSet': 'พับ',
  'oifde.accesscode.setTraceSuccess': 'การตั้งค่าสำเร็จ',
  'oifde.accesscode.accessCode': 'รหัสการเข้าถึง Chatbot',
  'oifde.accesscode.accessCodeSearch': 'ชื่อ Chatbot หรือรหัสการเข้าถึง Chatbot',
  'oifde.accesscode.accessCodeDesc': 'คำอธิบาย',
  'oifde.accesscode.description': 'ID รหัสเข้าถึง',
  'oifde.accesscode.descPlaceholder': 'คําอธิบายนี้ใช้เป็นคําอธิบายการไหลเมื่อโฟลว์ถูกเลือกเป็นการกําหนดค่าของฝ่ายที่เรียกว่า คุณค่าต้องไม่เหมือนใครในระดับโลก',
  'oifde.accesscode.flowCode': 'รหัสโฟลว์',
  'oifde.accesscode.flowName': 'ชื่อโฟลว์',
  'oifde.accesscode.domainId': 'ID โดเมน',
  'oifde.accesscode.dialogType': 'ประเภทการโต้ตอบ',
  'oifde.accesscode.callingnumber': 'หมายเลขการโทร',
  'oifde.accesscode.callednumber': 'หมายเลขที่โทร:',
  'oifde.accesscode.processTimes': 'จํานวนการโทรที่ดําเนินการโดยตัวแทนเท่านั้น',
  'oifde.accesscode.startcall': 'เริ่มการโทร',
  'oifde.accesscode.sendprompt': 'กดปุ่ม ENTER',
  'oifde.accesscode.callingnumberinput': 'กรอกหมายเลขการโทร',
  'oifde.accesscode.isCheck': 'ตรวจสอบการสะกด',
  'oifde.accesscode.isCheckHelp': 'หากมีข้อผิดพลาดในการสะกดคำในคำพูดของลูกค้า เจตนาจะได้รับการจดจำหลังจากแก้ไขข้อผิดพลาดแล้ว',
  'oifde.accesscode.sendContent': 'ส่ง',
  'oifde.accesscode.trl': 'กรอกจากขวาไปซ้าย',
  'oifde.accesscode.actions.edit': 'แก้ไข',
  'oifde.accesscode.actions.testCalledNum': 'การโทรทดสอบ',
  'oifde.accesscode.actions.delete': 'ลบ',
  'oifde.accesscode.actions.editbaseInfo': 'ปรับเปลี่ยน Chatbot',
  'oifde.accesscode.actions.addbaseInfo': 'เพิ่ม Chatbot',
  'oifde.accesscode.actions.success': 'สำเร็จ',
  'oifde.accesscode.actions.fail': 'ล้มเหลว',
  'oifde.accesscode.actions.createSuccess': 'สร้างสำเร็จแล้ว',
  'oifde.accesscode.actions.updateSuccess': 'ปรับเปลี่ยนสำเร็จแล้ว',
  'oifde.accesscode.actions.deleteSuccess': 'ลบสำเร็จแล้ว',
  'oifde.accesscode.actions.cancelDelete': 'การลบยกเลิกแล้ว',
  'oifde.accesscode.actions.deletePrompt': 'แชทบอทจะถูกลบอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.accesscode.actions.prompt': 'ข้อความพร้อมรับ',
  'oifde.accesscode.actions.confirm': 'ตกลง',
  'oifde.accesscode.actions.cancel': 'ยกเลิก',
  'oifde.accesscode.actions.flowChatbot': 'Chatbot ทดสอบโฟลว์',
  'oifde.accesscode.actions.callforwarding': 'โอนสาย',
  'oifde.accesscode.actions.voicefile': 'ไฟล์เสียง:',
  'oifde.accesscode.actions.videofile': 'ไฟล์วิดีโอ',
  'oifde.accesscode.dialogTypeDetail.voiceNavigation': 'การนำทางด้วยเสียง',
  'oifde.accesscode.dialogTypeDetail.smartOutboundCall': 'การโทรออกอัจฉริยะ',
  'oifde.accesscode.dialogTypeDetail.chatbot': 'Chatbot',
  'oifde.accesscode.dialogTypeDetail.ivrProcess': 'โฟลว์ IVR',
  'oifde.accesscode.validate.accessCode': 'รหัสการเข้าถึงโฟลว์เป็นสิ่งจำเป็น',
  'oifde.accesscode.validate.accessCodeCharReg': 'อนุญาตให้ใช้เฉพาะตัวอักษรและตัวเลขเท่านั้น',
  'oifde.accesscode.validate.doubleAccessCodeCheck': 'มีรหัสการเข้าถึงอยู่แล้ว',
  'oifde.accesscode.validate.dialogType': 'ประเภทการโต้ตอบเป็นสิ่งจำเป็น',
  'oifde.accesscode.validate.flowCode': 'โฟลว์เป็นสิ่งจำเป็น',
  'oifde.accesscode.validate.accessCodeLength': 'รหัสการเข้าถึงต้องมีอักขระไม่เกิน 20 ตัว',
  'oifde.accesscode.validate.flowCodeLength': 'รหัสโฟลว์ต้องมีอักขระไม่เกิน 64 ตัว',
  'oifde.accesscode.validate.descriptionLengthValidator': 'คำอธิบายรหัสการเข้าถึงประกอบด้วยอักขระมากกว่า 512 ตัว',
  'oifde.accesscode.validate.domainIdMax': 'ID โดเมนมากกว่า 2147483647',
  'oifde.accesscode.validate.domainIdCheck': 'ID โดเมนไม่ใช่ตัวเลขที่ถูกต้อง',
  'oifde.accesscode.recordState.disable': 'ปิดใช้งาน',
  'oifde.accesscode.recordState.enable': 'เปิดใช้งาน',
  'oifde.accesscode.accessCodeSubscribed': 'ใช้รหัสบอทแล้ว',
  'oifde.accesscode.bindScene': 'ดูข้อมูลอ้างอิง',
  'oifde.accesscode.chatBot.search': 'ผลลัพธ์การค้นหา',
  'oifde.accesscode.chatBot.reset': 'รีเซ็ต',
  'oifde.accesscode.searchScene': 'เลือกสถานการณ์',
  'oifde.accesscode.sceneName': 'สถานการณ์การใช้งาน',
  'oifde.accesscode.subName': 'ชื่ออ้างอิง',
  'oifde.accesscode.createTime': 'เวลาอ้างอิง',
  'oifde.accesscode.chatBot.chatChanel': 'ช่องมัลติมีเดีย',
  'oifde.accesscode.chatBot.agentAssistant': 'ผู้ช่วยตัวแทน',
  'oifde.accesscode.chatBot.intelligentOB': 'ชาญฉลาด',
  'oifde.accesscode.chatBot.intelligentTrain': 'การฝึกอบรมอัจฉริยะ',
  'oifde.toc.statusCode.SERVER_BUSY': 'ระบบไม่ว่าง',
  'oifde.toc.statusCode.NEED_LOGIN': 'คุณยังไม่ได้ลงชื่อเข้าใช้หรือไม่ได้ดำเนินการเป็นเวลานาน ลงชื่อเข้าใช้อีกครั้ง',
  'oifde.toc.statusCode.ACCOUNT_INCONSISTENT': 'คุณได้ลงชื่อเข้าใช้คอมพิวเตอร์เฉพาะที่โดยใช้บัญชีอื่น หากต้องการใช้บริการต่อ ลงชื่อเข้าใช้อีกครั้ง',
  'oifde.toc.statusCode.ACCOUNT_SWITCHED': 'บัญชีที่ลงชื่อเข้าใช้มีการเปลี่ยนแปลง บัญชีที่ลงชื่อเข้าใช้ล่าสุดจะแสดงในหน้าปัจจุบัน',
  'oifde.toc.statusCode.INCORRECT_VERIFYCODE': 'รหัสยืนยันไม่ถูกต้อง กรอกที่ถูกต้อง',
  'oifde.toc.statusCode.INVALID_JSON': 'คำขอไม่อยู่ในรูปแบบ JSON',
  'oifde.toc.statusCode.HTTPS_REQUIRED': 'ใช้ HTTPS เพื่อเข้าถึง',
  'oifde.toc.statusCode.EMPTY_ACCOUNT': 'หมายเลขบัญชีว่างเปล่า',
  'oifde.toc.statusCode.INVALID_ACCOUNT': 'การยืนยันล้มเหลว ลงชื่อเข้าใช้อีกครั้ง',
  'oifde.toc.statusCode.INSUFFICIENT_PERMISSION': 'การอนุญาตของคุณไม่เพียงพอ ติดต่อผู้ดูแลระบบเพื่อกำหนดการอนุญาต',
  'oifde.toc.statusCode.VERIFYCODE_EXPIRED': 'รหัสการตรวจสอบความถูกต้องหมดอายุ ลองใหม่อีกครั้งในภายหลัง',
  'oifde.toc.statusCode.ACCOUNT_LOCKED': 'บัญชีถูกล็อกชั่วคราว ลองอีกครั้งในภายหลังหรือติดต่อผู้ดูแลระบบเพื่อปลดล็อกบัญชี',
  'oifde.toc.statusCode.ACCOUNT_CHANGED': 'บัญชีมีการเปลี่ยนแปลง กรุณาลงชื่อเข้าใช้อีกครั้ง',
  'oifde.toc.statusCode.ACCOUNT_DISABLED': 'บัญชีถูกระงับ ติดต่อผู้ดูแลระบบ',
  'oifde.toc.statusCode.UNLOCK_REQUIRED': 'บัญชีถูกล็อกชั่วคราว ปลดล็อกบัญชีก่อน',
  'oifde.toc.statusCode.ADMIN_DISABLED': 'ฟังก์ชันการจัดการถูกปิดใช้งาน ใช้บัญชีทั่วไปเพื่อลงชื่อเข้าใช้ระบบ',
  'oifde.toc.statusCode.SESSION_OVERFLOW': 'จำนวนผู้ใช้ออนไลน์ถึงขีดสูงสุดแล้ว ลองอีกครั้งในภายหลัง',
  'oifde.toc.statusCode.SESSION_EXPIRED': 'การลงชื่อเข้าใช้ปัจจุบันไม่ถูกต้อง ลงชื่อเข้าใช้อีกครั้ง',
  'oifde.toc.statusCode.SESSION_DUPLICATED': 'คุณได้ลงชื่อเข้าใช้ หากคุณต้องการลงชื่อเข้าใช้อีกครั้ง ออกจากระบบก่อน',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_ID': 'ID พื้นที่ของผู้เช่าไม่ถูกต้อง',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_NAME': 'ชื่อพื้นที่ของผู้เช่าไม่ถูกต้อง',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT': 'บัญชีไม่ถูกต้อง',
  'oifde.toc.statusCode.INCORRECT_PASSWORD': 'รหัสผ่านไม่ตรงตามข้อกำหนดของกฎรหัสผ่าน',
  'oifde.toc.statusCode.INCORRECT_GENDER': 'เพศไม่ถูกต้อง',
  'oifde.toc.statusCode.INCORRECT_OWNER': 'เจ้าของไม่ถูกต้อง',
  'oifde.toc.statusCode.INCORRECT_TITLE': 'ความยาวของตำแหน่งต้องมีอักขระไม่เกิน 8 ตัว',
  'oifde.toc.statusCode.INCORRECT_TELEPHONE': 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
  'oifde.toc.statusCode.INCORRECT_EMAIL': 'ที่อยู่อีเมลไม่ถูกต้อง',
  'oifde.toc.statusCode.INCORRECT_ROLE_NAME': 'ชื่อบทบาทไม่ถูกต้อง',
  'oifde.toc.statusCode.EMPTY_TENANT_SPACE': 'พื้นที่ของผู้เช่าว่างเปล่า',
  'oifde.toc.statusCode.ACCOUNT_DUPLICATED': 'มีบัญชีอยู่แล้ว',
  'oifde.toc.statusCode.ROLE_DUPLICATED': 'มีบทบาทอยู่แล้ว',
  'oifde.toc.statusCode.TENANT_SPACE_DUPLICATED': 'มีพื้นที่ของผู้เช่าอยู่แล้ว',
  'oifde.toc.statusCode.ACCOUNT_NOT_FOUND': 'ไม่มีบัญชี',
  'oifde.toc.statusCode.ROLE_NOT_FOUND': 'ไม่มีบทบาท',
  'oifde.toc.statusCode.TENANT_SPACE_NOT_FOUND': 'ไม่มีพื้นที่ของผู้เช่า',
  'oifde.toc.statusCode.ACCOUNT_FORBIDDEN': 'อย่าดำเนินการกับบัญชี',
  'oifde.toc.statusCode.ROLE_FORBIDDEN': 'อย่าดำเนินการกับบทบาท',
  'oifde.toc.statusCode.TENANT_SPACE_FORBIDDEN': 'อย่าดำเนินการกับพื้นที่ของผู้เช่า',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT_INPUT': 'บัญชีต้องมีอักขระ 6 ถึง 32 ตัว รวมทั้งตัวอักษร ตัวเลข และอักขระพิเศษต่อไปนี้: {\'@\'} _ -',
  'oifde.toc.statusCode.INCORRECT_PASSWORD_UPDATE': 'จำนวนอักขระที่ซ้ำกันติดต่อกันในรหัสผ่านต้องไม่เกิน 3 ตัว นอกจากนี้รหัสผ่านใหม่ของผู้ใช้ที่มีอยู่จะต้องไม่เหมือนกับรหัสผ่านล่าสุดสามรหัส รหัสผ่านต้องมีอักขระ 8 ถึง 20 ตัวและต้องประกอบด้วยตัวเลข ตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ และอักขระพิเศษต่อไปนี้: ~`!{\'@\'}#{\'$\'}%^*()-+_{\'|\'},./<>?;\':"[]{\'{\'}{\'}\'}&',
'oifde.toc.statusCode.COMMON_ERROR': 'ข้อผิดพลาดของเซิร์ฟเวอร์',
  'oifde.toc.statusCode.SERVICE_UNAVAILABLE': 'การบริการไม่พร้อมใช้งาน',
  'oifde.toc.statusCode.AUTHORIZE_FAILED': 'การตรวจสอบความถูกต้องล้มเหลว',
  'oifde.toc.statusCode.TRANSIT_FAILED': 'การส่งต่อคำขอหมดเวลาแล้ว',
  'oifde.toc.statusCode.TRANSIT_ERROR': 'ส่งต่อคำขอสำเร็จแล้ว แต่เพียร์ปลายทางส่งกลับข้อผิดพลาด',
  'oifde.toc.statusCode.RESPONSE_FAILED': 'เซิร์ฟเวอร์ล้มเหลวในการประมวลผลการตอบสนอง',
  'oifde.toc.statusCode.REFRESH_TIMEOUT': 'ไม่มีการดำเนินการเป็นเวลานาน ลงชื่อเข้าใช้อีกครั้ง',
  'oifde.logparam.logParamMgr': 'การกำหนดค่าพารามิเตอร์ Flume Log',
  'oifde.logparam.logParam': 'การกำหนดค่าพารามิเตอร์แบบไดนามิก',
  'oifde.logparam.logName': 'ชื่อพารามิเตอร์',
  'oifde.logparam.source': 'แหล่งที่มาของพารามิเตอร์',
  'oifde.logparam.sourceName': 'ชื่อแหล่งที่มาของพารามิเตอร์',
  'oifde.logparam.transFlag': 'ค่าการแปลง',
  'oifde.logparam.sourceValue': 'ค่าแหล่งที่มา',
  'oifde.logparam.targetValue': 'ค่าเป้าหมาย',
  'oifde.logparam.actions.edit': 'แก้ไข',
  'oifde.logparam.actions.transDetail': 'แปลงค่า',
  'oifde.logparam.actions.delete': 'ลบ',
  'oifde.logparam.actions.editLogParamBaseInfo': 'แก้ไขพารามิเตอร์แบบไดนามิก',
  'oifde.logparam.actions.addLogParamBaseInfo': 'เพิ่มพารามิเตอร์แบบไดนามิก',
  'oifde.logparam.actions.success': 'สำเร็จ',
  'oifde.logparam.actions.createSuccess': 'สร้างสำเร็จแล้ว',
  'oifde.logparam.actions.updateSuccess': 'ปรับเปลี่ยนสำเร็จแล้ว',
  'oifde.logparam.actions.deleteSuccess': 'ลบสำเร็จแล้ว',
  'oifde.logparam.actions.cancelDelete': 'การลบยกเลิกแล้ว',
  'oifde.logparam.actions.deletePrompt': 'พารามิเตอร์แบบไดนามิกนี้จะถูกลบอย่างถาวร คุณต้องการทำต่อไปหรือไม่?',
  'oifde.logparam.actions.prompt': 'ข้อความพร้อมรับ',
  'oifde.logparam.actions.confirm': 'ตกลง',
  'oifde.logparam.actions.cancel': 'ยกเลิก',
  'oifde.logparam.actions.add': 'เพิ่ม',
  'oifde.logparam.sourceDetail.IVRRequestInformation': 'คำขอ IVR',
  'oifde.logparam.sourceDetail.IVRResponseInformation': 'การตอบสนอง IVR',
  'oifde.logparam.sourceDetail.TOCRequestInformation': 'คำขอ TOC',
  'oifde.logparam.sourceDetail.TOCResponseInformation': 'การตอบสนอง TOC',
  'oifde.logparam.sourceDetail.InterfaceRequestInformation': 'คำขออินเทอร์เฟซ',
  'oifde.logparam.sourceDetail.InterfaceResponseInformation': 'การตอบสนองของอินเทอร์เฟซ',
  'oifde.logparam.transFlagDetail.YES': 'ใช่',
  'oifde.logparam.transFlagDetail.NO': 'ไม่ใช่',
  'oifde.logparam.validate.logName': 'ชื่อพารามิเตอร์เป็นสิ่งจำเป็น',
  'oifde.logparam.validate.source': 'แหล่งที่มาของพารามิเตอร์เป็นสิ่งจำเป็น',
  'oifde.logparam.validate.sourceName': 'ชื่อแหล่งที่มาของพารามิเตอร์เป็นสิ่งจำเป็น',
  'oifde.logparam.validate.sourceValue': 'ค่าแหล่งที่มาเป็นสิ่งจำเป็น',
  'oifde.logparam.validate.targetValue': 'ค่าเป้าหมายเป็นสิ่งจำเป็น',
  'oifde.logparam.validate.doublelogNameCheck': 'มีชื่อพารามิเตอร์แบบไดนามิกอยู่แล้ว',
  'oifde.logparam.validate.logNameLength': 'ชื่อพารามิเตอร์แบบไดนามิกมีอักขระมากกว่า 64 ตัว',
  'oifde.logparam.validate.sourceNameLength': 'ชื่อแหล่งที่มาของพารามิเตอร์มีอักขระมากกว่า 64 ตัว',
  'oifde.logparam.validate.sourceValueLength': 'ค่าแหล่งที่มามีอักขระมากกว่า 64 ตัว',
  'oifde.logparam.validate.targetValueLength': 'ค่าเป้าหมายมีอักขระมากกว่า 64 ตัว',
  'oifde.logparam.message.logParamNotNull': 'ไม่ได้กรอกข้อมูลการแปลงพารามิเตอร์',
  'oifde.logparam.message.validateFailed': 'การตรวจสอบความถูกต้องล้มเหลว',
  'oifde.logparam.message.validateResult': 'ข้อความพร้อมรับ',
  'oifde.tenantinfo.tenanInfoMgr': 'การกำหนดค่าพารามิเตอร์คงที่',
  'oifde.tenantinfo.serviceName': 'ชื่อ',
  'oifde.tenantinfo.serviceValue': 'ค่า',
  'oifde.tenantinfo.actions.edit': 'แก้ไข',
  'oifde.tenantinfo.actions.editTenantBaseInfo': 'แก้ไขพารามิเตอร์คงที่',
  'oifde.tenantinfo.actions.success': 'สำเร็จ',
  'oifde.tenantinfo.actions.updateSuccess': 'ปรับเปลี่ยนสำเร็จแล้ว',
  'oifde.tenantinfo.validate.serviceValue': 'ค่าเป็นสิ่งจำเป็น',
  'oifde.tenantinfo.validate.serviceValueLength': 'ค่ามีอักขระมากกว่า 64 ตัว',
  'oifde.variable.innerTitle': 'ตัวแปรในตัว',
  'oifde.variable.customTitle': 'ตัวแปรที่กำหนดเอง',
  'oifde.variable.variableName': 'ชื่อตัวแปร',
  'oifde.variable.variableDesc': 'คำอธิบาย',
  'oifde.variable.variableType': 'ประเภทข้อมูล',
  'oifde.variable.scope': 'ขอบเขตของฟังก์ชัน',
  'oifde.variable.defaultValue': 'ค่าเริ่มต้น',
  'oifde.variable.encryptType': 'ประเภทการเข้ารหัส',
  'oifde.variable.timezoneDesc': 'ตั้งค่าพารามิเตอร์นี้เป็นเขตเวลาของผู้เช่า ค่ามีตั้งแต่ -12 ถึง +12 ตัวอย่างเช่นตั้งค่าพารามิเตอร์นี้เป็น 8 สำหรับ GMT + 8',
  'oifde.variable.channeltypeDesc': 'สามารถกำหนดค่าเป็นช่องของผู้เช่าได้',
  'oifde.variable.responsestyleDesc': 'ค่าสามารถกำหนดเป็นสไตล์ของผู้เช่าได้',
  'oifde.variable.langDesc': 'คุณสามารถตั้งค่าพารามิเตอร์นี้เป็นภาษาของผู้เช่า',
  'oifde.variable.variableTypeDetail.int': 'จำนวนเต็ม',
  'oifde.variable.variableTypeDetail.string': 'อักขระ',
  'oifde.variable.variableTypeDetail.long': 'จำนวนเต็มแบบยาว',
  'oifde.variable.variableTypeDetail.float': 'เลขทศนิยม',
  'oifde.variable.variableTypeDetail.list': 'รายการ',
  'oifde.variable.variableTypeDetail.object': 'วัตถุ',
  'oifde.variable.scopes.input': 'พารามิเตอร์อินพุต',
  'oifde.variable.scopes.output': 'พารามิเตอร์เอาต์พุต',
  'oifde.variable.scopes.local': 'เฉพาะที่',
  'oifde.variable.actions.edit': 'แก้ไข',
  'oifde.variable.actions.delete': 'ลบ',
  'oifde.variable.actions.editVariableBaseInfo': 'แก้ไขตัวแปร',
  'oifde.variable.actions.addVariableBaseInfo': 'เพิ่มตัวแปร',
  'oifde.variable.actions.success': 'สำเร็จ',
  'oifde.variable.actions.createSuccess': 'สร้างสำเร็จแล้ว',
  'oifde.variable.actions.updateSuccess': 'ปรับเปลี่ยนสำเร็จแล้ว',
  'oifde.variable.actions.deleteSuccess': 'ลบสำเร็จแล้ว',
  'oifde.variable.actions.cancelDelete': 'การลบยกเลิกแล้ว',
  'oifde.variable.actions.deletePrompt': 'ตัวแปรนี้จะถูกลบอย่างถาวร คุณต้องการทำต่อไปหรือไม่?',
  'oifde.variable.actions.prompt': 'ข้อความพร้อมรับ',
  'oifde.variable.actions.confirm': 'ตกลง',
  'oifde.variable.actions.cancel': 'ยกเลิก',
  'oifde.variable.actions.add': 'เพิ่ม',
  'oifde.variable.validate.variableName': 'ชื่อตัวแปรเป็นสิ่งจำเป็น',
  'oifde.variable.validate.variableNameRange': 'อนุญาตให้ใช้เฉพาะตัวอักษร ตัวเลข และขีดล่างเท่านั้น',
  'oifde.variable.validate.variableType': 'ประเภทตัวแปรเป็นสิ่งจำเป็น',
  'oifde.variable.validate.scope': 'ขอบเขตของฟังก์ชันเป็นสิ่งจำเป็น',
  'oifde.variable.validate.doubleVariableNameCheck': 'มีชื่อตัวแปรอยู่แล้ว',
  'oifde.variable.validate.intValueCheck': 'ค่าเริ่มต้นของข้อมูลจำนวนเต็มไม่ถูกต้อง ข้อมูลจำนวนเต็มมีตั้งแต่ -2147483648 ถึง +2147483647',
  'oifde.variable.validate.longValueCheck': 'ค่าเริ่มต้นของข้อมูลจำนวนเต็มแบบยาวไม่ถูกต้อง ข้อมูลช่วงจาก -9223372036854775808 ถึง +9223372036854775807',
  'oifde.variable.validate.floatValueCheck': 'ค่าเริ่มต้นของข้อมูลทศนิยมอยู่ในรูปแบบที่ไม่ถูกต้อง ค่าต้องอยู่ในรูปแบบ 100 หรือ 99.99',
  'oifde.variable.encryptFlag.zero': 'ค่าไม่ได้เข้ารหัส',
  'oifde.variable.encryptFlag.one': 'ค่าจะถูกเข้ารหัสสำหรับการจัดเก็บและถอดรหัสเมื่อได้รับ',
  'oifde.variable.encryptFlag.two': 'ค่าจะถูกเข้ารหัสสำหรับการจัดเก็บและไม่ได้รับการถอดรหัสเมื่อได้รับ',
  'oifde.diagram.varType': 'ประเภทตัวแปร',
  'oifde.diagram.usedVar': 'ตัวแปรที่ใช้',
  'oifde.diagram.usedCell': 'องค์ประกอบแผนภาพ',
  'oifde.diagram.fastGetCell': 'การค้นหาตำแหน่งองค์ประกอบแผนภาพอย่างรวดเร็ว',
  'oifde.diagram.fastByVar': 'การค้นหาตำแหน่งตัวแปรอย่างรวดเร็ว',
  'oifde.diagram.aimCell': 'องค์ประกอบแผนภาพเป้าหมาย',
  'oifde.diagram.saveAndPublish': 'บันทึกและประกาศ',
  'oifde.diagram.NMS_PleaseSelect': 'เลือก',
  'oifde.diagram.NMS_ParamName': 'พารามิเตอร์',
  'oifde.diagram.NMS_ParamValue': 'ค่า',
  'oifde.diagram.NMS_DiagramProperties': 'แอตทริบิวต์โฟลว์',
  'oifde.diagram.NMS_BasicProperties': 'แอตทริบิวต์พื้นฐาน',
  'oifde.diagram.NMS_DiagramCode': 'รหัสโฟลว์',
  'oifde.diagram.NMS_DiagramName': 'ชื่อโฟลว์',
  'oifde.diagram.NMS_ScenarioType': 'ประเภทแผนการ',
  'oifde.diagram.NMS_Desciption': 'คำอธิบาย',
  'oifde.diagram.NMS_NodeCode': 'รหัสโหนด',
  'oifde.diagram.NMS_NodeName': 'ชื่อโหนด',
  'oifde.diagram.NMS_AdvanceProperties': 'แอตทริบิวต์ขั้นสูง',
  'oifde.diagram.NMS_ServiceName': 'ชื่อการบริการ',
  'oifde.diagram.NMS_ServiceParams': 'พารามิเตอร์การบริการ',
  'oifde.diagram.NMS_ServiceDesciption': 'คำอธิบายการบริการ',
  'oifde.diagram.add': 'เพิ่ม',
  'oifde.diagram.remove': 'ลบ',
  'oifde.diagram.NMS_BranchProperties': 'แอตทริบิวต์สาขา',
  'oifde.diagram.NMS_BranchCondition': 'สภาวะสาขา',
  'oifde.diagram.NMS_BranchService': 'สาขาการบริการ',
  'oifde.diagram.NMS_NodeProperties': 'แอตทริบิวต์โหนด',
  'oifde.diagram.NMS_FlowVariable': 'ตัวแปรโฟลว์',
  'oifde.diagram.NMS_FlowVariableName': 'ชื่อตัวแปร',
  'oifde.diagram.NMS_FlowVariableDesc': 'คำอธิบาย',
  'oifde.diagram.NMS_FlowVariableType': 'ประเภทข้อมูล',
  'oifde.diagram.NMS_FlowVariableDefaultValue': 'ค่าเริ่มต้น',
  'oifde.diagram.NMS_FlowVariableEncryptType': 'ประเภทการเข้ารหัส',
  'oifde.diagram.NMS_FlowVariableEncryptTypeWarn': '* โปรดทราบว่าตัวแปรนี้จะถูกเก็บไว้ในข้อความธรรมดาเมื่อประเภทการเข้ารหัสไม่ได้เข้ารหัส อย่ามอบหมายข้อมูลที่ละเอียดอ่อนให้กับตัวแปรนี้',
  'oifde.diagram.NMS_FlowVariableEncryptTypeTips': '* โปรดทราบว่าเมื่อประเภทการเข้ารหัสเป็นการเข้ารหัสขอแนะนําให้กําหนดค่าให้กับตัวแปรเพื่อป้องกันการรั่วไหลของข้อมูล',
  'oifde.diagram.NMS_FlowVariableOption': 'การดำเนินการ',
  'oifde.diagram.NMS_AddVariable': 'เพิ่มตัวแปร',
  'oifde.diagram.NMS_ModifyVariable': 'ปรับเปลี่ยนตัวแปร',
  'oifde.diagram.NMS_VoiceTemplate': 'เทมเพลตการตอบสนอง',
  'oifde.diagram.variableTypeDetail.int': 'จำนวนเต็ม',
  'oifde.diagram.variableTypeDetail.string': 'อักขระ',
  'oifde.diagram.variableTypeDetail.long': 'จำนวนเต็มแบบยาว',
  'oifde.diagram.variableTypeDetail.float': 'เลขทศนิยม',
  'oifde.diagram.variableTypeDetail.list': 'ตาราง',
  'oifde.diagram.variableTypeDetail.object': 'วัตถุ',
  'oifde.diagram.error.NMS_flowCodeError': 'รหัสโฟลว์ต้องไม่ว่างเปล่าหรือมีอักขระมากกว่า 64 ตัว',
  'oifde.diagram.error.NMS_flowNameError': 'ชื่อโฟลว์ต้องไม่ว่างเปล่าหรือมีอักขระมากกว่า 1,000 ตัว',
  'oifde.diagram.error.NMS_scenarioTypeError': 'ประเภทแผนการนี้ต้องไม่ว่างเปล่า',
  'oifde.diagram.error.NMS_flowDescError': 'คำอธิบายโฟลว์ต้องมีอักขระไม่เกิน 1,000 ตัว',
  'oifde.diagram.error.NMS_nmsFlowError': 'การแยกวิเคราะห์โฟลว์ล้มเหลว ไม่พบโหนด nmsFlow',
  'oifde.diagram.error.NMS_rootError': 'การแยกวิเคราะห์โฟลว์ล้มเหลว ไม่พบโหนดราก',
  'oifde.diagram.error.NMS_VariableNameError': 'ชื่อตัวแปรต้องมีอักขระไม่เกิน 1,000 ตัว',
  'oifde.diagram.error.NMS_VariableDescError': 'คำอธิบายตัวแปรต้องมีอักขระไม่เกิน 1,000 ตัว',
  'oifde.diagram.error.NMS_VariableDefaultValueError': 'ค่าเริ่มต้นของตัวแปรต้องมีอักขระไม่เกิน 1024 ตัว',
  'oifde.diagram.error.NMS_NodeCodeError': 'รหัสโหนดต้องไม่ว่างเปล่าหรือมีอักขระมากกว่า 64 ตัว',
  'oifde.diagram.error.NMS_NodeNameError': 'ชื่อโหนดต้องไม่ว่างเปล่าหรือมีอักขระมากกว่า 1,000 ตัว',
  'oifde.diagram.error.NMS_NodeDescError': 'คำอธิบายโหนดต้องมีอักขระไม่เกิน 1,000 ตัว',
  'oifde.diagram.error.NMS_actionParamNameError': 'ชื่อพารามิเตอร์ต้องมีอักขระไม่เกิน {0} ตัว',
  'oifde.diagram.error.NMS_actionParamValueError': 'ค่าพารามิเตอร์ต้องมีอักขระไม่เกิน 4096 ตัว',
  'oifde.diagram.error.NMS_actionParamDescError': 'คำอธิบายพารามิเตอร์ต้องมีอักขระไม่เกิน 500 ตัว',
  'oifde.diagram.error.NMS_transitToStartNodeError': 'ข้ามไปยังโหนดเริ่มต้นล้มเหลว',
  'oifde.diagram.error.NMS_endNodetransitToError': 'โหนดสิ้นสุดไม่สามารถข้ามไปยังโหนดอื่นได้',
  'oifde.diagram.error.NMS_startNodeNotFind': 'ไม่พบโหนดเริ่มต้น',
  'oifde.diagram.error.NMS_startNodeNotUnique': 'โหนดเริ่มต้นไม่มีความเป็นเอกลักษณ์',
  'oifde.diagram.error.NMS_startNodeNotBranchError': 'โหนดเริ่มต้นไม่มีสาขาหรือมีมากกว่าหนึ่งสาขา',
  'oifde.diagram.error.NMS_endNodeNotFind': 'ไม่พบโหนดสิ้นสุด',
  'oifde.diagram.error.NMS_analiseXmlFailed': 'แยกวิเคราะห์โฟลว์แคนวาสล้มเหลว',
  'oifde.diagram.error.NMS_branchError': 'สาขาไม่มีโหนดเริ่มต้นหรือโหนดสิ้นสุด',
  'oifde.diagram.error.NMS_nullBranchNotNuique': 'โหนดสามารถมีสาขาตั้งต้นได้เพียงสาขาเดียว',
  'oifde.diagram.error.NMS_checkDefaultNextNodeForView': 'องค์ประกอบแผนภาพของชนิดมุมมองไม่สามารถมีสาขามากกว่าหนึ่งสาขา',
  'oifde.diagram.error.NMS_actionParamValueEmptyError': 'ค่าพารามิเตอร์ต้องไม่ว่างเปล่า',
  'oifde.diagram.complexCell.name': 'ชื่อองค์ประกอบแผนภาพ',
  'oifde.diagram.complexCell.variable': 'ตัวแปรองค์ประกอบแผนภาพ',
  'oifde.diagram.speechRecognition.recognitionParam': 'พารามิเตอร์การรับรู้',
  'oifde.diagram.speechRecognition.resultParam': 'การประมวลผลผลลัพธ์',
  'oifde.navbar.tenantId': 'ID ผู้เช่าเข้าสู่ระบบในปัจจุบัน',
  'oifde.navbar.tenantName': 'ผู้เช่าปัจจุบัน',
  'oifde.navbar.current': 'เวลาลงชื่อเข้าใช้ปัจจุบัน',
  'oifde.navbar.success': 'เวลาลงชื่อเข้าใช้ครั้งล่าสุดที่ประสบความสำเร็จ',
  'oifde.navbar.failed': 'เวลาลงชื่อเข้าใช้ครั้งล่าสุดที่ล้มเหลว',
  'oifde.navbar.logOut': 'ลงชื่อออก',
  'oifde.navbar.switchUser': 'สลับผู้ใช้',
  'oifde.navbar.screenfull': 'เต็มจอ',
  'oifde.navbar.switchLang': 'การเปลี่ยนภาษา',
  'oifde.navbar.menuOpen': 'ขยายเมนู',
  'oifde.navbar.menuClose': 'พับเมนู',
  'oifde.navbar.changePwd': 'เปลี่ยนรหัสผ่าน',
  'oifde.navbar.required': 'จำเป็น',
  'oifde.navbar.action.message': 'ข้อความพร้อมรับ',
  'oifde.navbar.action.success': 'สำเร็จ',
  'oifde.navbar.action.successMessage': 'เปลี่ยนผู้ใช้สำเร็จ',
  'oifde.navbar.action.changePasswordSuccess': 'รหัสผ่านเปลี่ยนแล้ว',
  'oifde.navbar.password.logOut': 'ลงชื่อออก',
  'oifde.navbar.password.oldPassword': 'รหัสผ่านเก่า',
  'oifde.navbar.password.newPassword': 'รหัสผ่านใหม่',
  'oifde.navbar.password.confirmNewPassword': 'ยืนยันรหัสผ่าน',
  'oifde.navbar.password.passwordExpired': 'รหัสผ่านของคุณหมดอายุ เปลี่ยน',
  'oifde.navbar.password.firstTimeLogin': 'คุณกำลังใช้รหัสผ่านเริ่มต้น เปลี่ยน',
  'oifde.navbar.password.passwordWillExpire': 'รหัสผ่านของคุณกำลังจะหมดอายุ เปลี่ยน',
  'oifde.navbar.password.passwordNotSame': 'รหัสผ่านสองรหัสแตกต่างกัน',
  'oifde.journeylogmgr.flowAnalysisMrg': 'การตั้งค่าการวิเคราะห์กระบวนการ',
  'oifde.journeylogmgr.accessCodeSetting': 'การตั้งค่ารหัสการเข้าถึง',
  'oifde.trackmgr.track': 'การกำหนดค่าการติดตาม',
  'oifde.trackmgr.accessCodeTrack': 'รหัสการเข้าถึงการติดตาม',
  'oifde.trackmgr.callingNumberTrack': 'หมายเลขการโทรติดตาม',
  'oifde.trackmgr.callingNumber': 'หมายเลขการโทร',
  'oifde.trackmgr.accessCode': 'รหัสการเข้าถึง',
  'oifde.trackmgr.choose': 'เลือก',
  'oifde.trackmgr.validate.accessCode': 'รหัสการเข้าถึงเป็นสิ่งจำเป็น',
  'oifde.trackmgr.validate.callingNumber': 'หมายเลขการโทรเป็นสิ่งจำเป็น',
  'oifde.trackmgr.validate.needNumber': 'กรอกตัวเลข',
  'oifde.trackmgr.validate.callingNumberLength': 'หมายเลขการโทรมีอักขระมากกว่า 64 ตัว',
  'oifde.trackmgr.validate.callingNumberDub': 'หมายเลขการโทรซ้ำ',
  'oifde.trackmgr.validate.pattern': 'อนุญาตให้ใช้เฉพาะตัวอักษร ตัวเลข ช่องว่าง จุด (.) _ - \' : {\'@\'} และ /',
'oifde.trackmgr.actions.delete': 'ลบ',
  'oifde.trackmgr.actions.success': 'สำเร็จ',
  'oifde.trackmgr.actions.updateSuccess': 'ปรับเปลี่ยนสำเร็จแล้ว',
  'oifde.trackmgr.actions.add': 'เพิ่ม',
  'oifde.trackmgr.actions.deleteSuccess': 'ลบสำเร็จแล้ว',
  'oifde.trackmgr.actions.cancelDelete': 'การลบยกเลิกแล้ว',
  'oifde.trackmgr.actions.deletePrompt': 'ข้อมูลการติดตามนี้จะถูกลบอย่างถาวร คุณต้องการทำต่อไปหรือไม่?',
  'oifde.trackmgr.actions.prompt': 'ข้อความพร้อมรับ',
  'oifde.trackmgr.actions.confirm': 'ตกลง',
  'oifde.trackmgr.actions.cancel': 'ยกเลิก',
  'oifde.trace.traceReason': 'สาเหตุข้อผิดพลาด',
  'oifde.trace.traceAdvice': 'ข้อเสนอแนะข้อผิดพลาด',
  'oifde.trace.reasonAdvice': 'สาเหตุและข้อเสนอแนะ',
  'oifde.trace.sessionLog': 'การสืบค้นการลงบันทึกการโต้ตอบ',
  'oifde.trace.traceLog': 'การสืบค้นการติดตามการลงบันทึก',
  'oifde.trace.beginTime': 'เวลาเริ่มต้น',
  'oifde.trace.endTime': 'เวลาสิ้นสุด',
  'oifde.trace.callingNumber': 'หมายเลขการโทร',
  'oifde.trace.calledNumber': 'หมายเลขที่โทร',
  'oifde.trace.createTime': 'เวลาที่สร้าง',
  'oifde.trace.flowId': 'ID โฟลว์',
  'oifde.trace.flowName': 'ชื่อโฟลว์',
  'oifde.trace.logInfo': 'ข้อมูลการลงบันทึก',
  'oifde.trace.errorInfo': 'ข้อมูลข้อผิดพลาด',
  'oifde.trace.callId': 'ID อินสแตนซ์การโทร',
  'oifde.trace.nodeId': 'ID โหนด',
  'oifde.trace.nodeName': 'ชื่อโหนด',
  'oifde.trace.actionContent': 'ชื่อคำสั่ง',
  'oifde.trace.nodeIndex': 'หมายเลขโหนดการดำเนินการ',
  'oifde.trace.ivrRequest': 'คำขอ IVR',
  'oifde.trace.actionParams': 'ข้อมูลพารามิเตอร์โหนดการดำเนินการ',
  'oifde.trace.processResult': 'ผลการดำเนินการ',
  'oifde.trace.transitionOn': 'สาขาผลการดำเนินการ',
  'oifde.trace.reset': 'รีเซ็ต',
  'oifde.trace.validate.needNumber': 'กรอกตัวเลขหรือตัวอักษร',
  'oifde.trace.validate.callingNumber': 'หมายเลขการโทรเป็นสิ่งจำเป็น',
  'oifde.trace.validate.callingNumberLength': 'หมายเลขการโทรมีอักขระมากกว่า 64 ตัว',
  'oifde.trace.clickToDetail': 'คลิกเพื่อแสดงเพิ่มเติม',
  'oifde.trace.clickToHideDetail': 'คลิกเพื่อยุบเพิ่มเติม',
  'oifde.trace.currentNodeInfo': 'ข้อมูลโหนดปัจจุบัน',
  'oifde.trace.clickToFlow': 'ดูแทร็ก',
  'oifde.trace.nodeTrace': 'ข้อมูลการลงบันทึกโหนด',
  'oifde.trace.nodeParams': 'พารามิเตอร์อินพุตและเอาต์พุตของโหนด',
  'oifde.expression.epxr_input_head': 'ความยาวสูงสุด:',
  'oifde.expression.expr_type_illegal': 'รูปแบบนิพจน์ไม่ถูกต้อง',
  'oifde.expression.expr_key_repeats': 'ชื่อเงื่อนไขซ้ํากัน',
  'oifde.expression.expr_var': 'กรอกตัวแปร ตัวแปรส่วนกลางเริ่มต้นด้วย GLOBAL ตัวแปรโฟลว์เริ่มต้นด้วย FLOW ตัวแปรระบบเริ่มต้นด้วย SYS และตัวแปร TUC เริ่มต้นด้วย TOC',
  'oifde.expression.expr_var_fag': 'กรอกตัวแปร ตัวแปรส่วนกลางเริ่มต้นด้วย GLOBAL และตัวแปรโฟลว์เริ่มต้นด้วย FLOW',
  'oifde.expression.expr_var_paramName': 'กรอกตัวแปร ตัวแปรส่วนกลางเริ่มต้นด้วย GLOBAL และตัวแปรโฟลว์เริ่มต้นด้วย FLOW',
  'oifde.expression.expr_var_string': 'กรอกตัวแปรประเภทสตริง ตัวแปรส่วนกลางเริ่มต้นด้วย GLOBAL ตัวแปรโฟลว์เริ่มต้นด้วย FLOW ตัวแปรระบบเริ่มต้นด้วย SYS และตัวแปร TUC เริ่มต้นด้วย TOC',
  'oifde.expression.expr_number': 'กรอกค่าของประเภทตัวเลข ตัวดำเนินการที่มีอยู่ระหว่างค่ามีดังนี้: + - * /',
  'oifde.expression.expr_number_noAction': 'กรอกค่าของประเภทตัวเลข',
  'oifde.expression.expr_var_list': 'ป้อนตัวแปรรายการ',
  'oifde.expression.expr_var_number': 'ป้อนตัวแปร int',
  'oifde.expression.allVar': 'ตัวแปรส่วนกลางเริ่มต้นด้วย GLOBAL ตัวแปรโฟลว์เริ่มต้นด้วย FLOW ตัวแปรระบบเริ่มต้นด้วย SYS และตัวแปร TUC เริ่มต้นด้วย TOC',
  'oifde.expression.flowAndGlobalVar': 'ตัวแปรส่วนกลางเริ่มต้นด้วย GLOBAL และตัวแปรโฟลว์เริ่มต้นด้วย FLOW',
  'oifde.expression.expr_string': 'กรอกค่าของประเภทสตริง ใช้เครื่องหมายอัญประกาศเดี่ยวหรือเครื่องหมายอัญประกาศคู่ก่อนและหลังสตริงอักขระ ตัวดำเนินการสตริงอักขระคือเครื่องหมายบวก (+)',
  'oifde.expression.expr_simple_string': 'กรอกตัวแปรสตริงหรือสตริงอักขระใด ๆ อย่าใช้เครื่องหมายอัญประกาศก่อนและหลังสตริงอักขระ',
  'oifde.expression.expr_boolean': 'กรอกค่าของประเภทบูลีน ตัวดำเนินการเปรียบเทียบระหว่างค่าตัวเลขหรือสตริงอักขระรองรับ > <>= <= != วิธีการสตริงรองรับ startWith ตัวดำเนินการค่าบูลีนรองรับ &&  || == !=',
  'oifde.expression.varFlow': 'ตัวแปรโฟลว์',
  'oifde.expression.varGlobal': 'ตัวแปรส่วนกลาง',
  'oifde.expression.varToc': 'ตัวแปรเจตนา',
  'oifde.expression.varSys': 'ตัวแปรระบบ',
  'oifde.expression.varIvrRequest': 'ตัวแปรคำขอ IVR',
  'oifde.procedure.procedureMgr': 'การจัดการกระบวนงานที่จัดเก็บไว้',
  'oifde.procedure.procedureDBMgr': 'การจัดการฐานข้อมูลกระบวนงานที่จัดเก็บไว้',
  'oifde.procedure.procedureName': 'กระบวนงานการจัดเก็บ',
  'oifde.procedure.procedureDesc': 'คำอธิบาย',
  'oifde.procedure.procedureValue': 'ค่า',
  'oifde.procedure.operation': 'การดำเนินการ',
  'oifde.procedure.baseInfo': 'ข้อมูลพื้นฐาน',
  'oifde.procedure.in_param': 'พารามิเตอร์อินพุต',
  'oifde.procedure.paramName': 'พารามิเตอร์',
  'oifde.procedure.paramDesc': 'คำอธิบาย',
  'oifde.procedure.dataType': 'ประเภทข้อมูล',
  'oifde.procedure.out_param': 'พารามิเตอร์เอาต์พุต',
  'oifde.procedure.addProcedureInterface': 'เพิ่มการกำหนดค่ากระบวนงานที่จัดเก็บไว้',
  'oifde.procedure.modifyProcedureInterface': 'ปรับเปลี่ยนการกำหนดค่ากระบวนงานที่จัดเก็บไว้',
  'oifde.procedure.addProcedureDB': 'เพิ่มการกำหนดค่าฐานข้อมูลกระบวนงานที่จัดเก็บไว้',
  'oifde.procedure.modifyProcedureDB': 'แก้ไขการกำหนดค่าฐานข้อมูลกระบวนงานที่จัดเก็บไว้',
  'oifde.procedure.required': 'จำเป็น',
  'oifde.procedure.ruleMysql': 'ฐานข้อมูล MySQL ต้องขึ้นต้นด้วย jdbc:mysql://',
  'oifde.procedure.ruleGauss': 'ฐานข้อมูล Gauss ต้องขึ้นต้นด้วย jdbc:zenith:{\'@\'}',
  'oifde.procedure.ruleOracle': 'ชื่อฐานข้อมูล Oracle ต้องขึ้นต้นด้วย jdbc:oracle:thin:{\'@\'}',
  'oifde.procedure.ruleRange': 'URL สามารถมีได้เฉพาะตัวเลข ตัวอักษร และอักขระพิเศษ (.?=:-_&/) และสามารถลงท้ายด้วยตัวเลข ตัวอักษร หรือเครื่องหมายทับ (/) เท่านั้น',
  'oifde.procedure.oracleRuleRange': 'URL สามารถมีได้เฉพาะตัวเลข ตัวอักษร และอักขระพิเศษ (.?{\'@\'}=:-_&/) และสามารถลงท้ายด้วยตัวเลขตัวอักษรหรือเครื่องหมายทับ (/) เท่านั้น',
  'oifde.procedure.driverRule': 'ค่าสามารถมีได้เฉพาะตัวเลข ตัวอักษร จุด (.) และขีดกลาง (-)',
  'oifde.procedure.driverStart': 'ค่าต้องไม่ขึ้นต้นด้วยจุด (.) หรือขีดกลาง (-)',
  'oifde.procedure.driverEnd': 'ค่าต้องไม่ลงท้ายด้วยจุด (.) หรือขีดกลาง (-)',
  'oifde.procedure.ruleLength': 'ไม่ได้ป้อนที่อยู่ IPและหมายเลขพอร์ตใน URL',
  'oifde.procedure.dbUrlWarning': '*โปรดทราบว่า IP, พอร์ต หรือโดเมนใน URL ฐานข้อมูลไม่อยู่ในบัญชีขาว คุณต้องติดต่อผู้ดูแลระบบเพิ่มเพื่อเรียกใช้อย่างถูกต้อง',
  'oifde.procedure.actions.editList': 'แก้ไขประเภทข้อมูลในรายการ',
  'oifde.procedure.actions.edit': 'แก้ไข',
  'oifde.procedure.actions.delete': 'ลบ',
  'oifde.procedure.actions.success': 'สำเร็จ',
  'oifde.procedure.actions.updateSuccess': 'การสร้างสำเร็จแล้ว',
  'oifde.procedure.actions.deleteSuccess': 'การปรับเปลี่ยนสำเร็จแล้ว',
  'oifde.procedure.actions.createSuccess': 'การลบสำเร็จแล้ว',
  'oifde.procedure.actions.cancelDelete': 'การลบยกเลิกแล้ว',
  'oifde.procedure.actions.deletePrompt': 'การดำเนินการนี้จะลบกระบวนงานที่จัดเก็บไว้อย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.procedure.actions.prompt': 'ข้อความพร้อมรับ',
  'oifde.procedure.actions.confirm': 'ใช่',
  'oifde.procedure.actions.cancel': 'ไม่ใช่',
  'oifde.procedure.actions.ok': 'ตกลง',
  'oifde.procedure.actions.miss_pro_name': 'ฟิลด์บังคับขาดหายไป: ข้อมูลพื้นฐาน -> ชื่อกระบวนงานที่จัดเก็บไว้',
  'oifde.procedure.actions.miss_pro_value': 'ฟิลด์บังคับขาดหายไป: ข้อมูลพื้นฐาน -> ค่ากระบวนงานที่จัดเก็บไว้',
  'oifde.procedure.actions.miss_pro_in_parameter_name': 'ฟิลด์บังคับขาดหายไป: พารามิเตอร์อินพุต> ชื่อพารามิเตอร์',
  'oifde.procedure.actions.miss_pro_out_parameter_name': 'ฟิลด์บังคับขาดหายไป: พารามิเตอร์เอาต์พุต> ชื่อพารามิเตอร์',
  'oifde.procedure.actions.miss_pro_dbId': 'ไม่มีฟิลด์ที่เลือก: การเชื่อมต่อฐานข้อมูล',
  'oifde.procedure.dataTypes.INT': 'จำนวนเต็ม',
  'oifde.procedure.dataTypes.STRING': 'อักขระ',
  'oifde.procedure.dataTypes.FLOAT': 'เลขทศนิยม',
  'oifde.procedure.dataTypes.LONG': 'จำนวนเต็มแบบยาว',
  'oifde.procedure.dataTypes.LIST': 'รายการ',
  'oifde.procedure.db.dbType': 'ประเภทฐานข้อมูล',
  'oifde.procedure.db.dbDriver': 'ตัวขับฐานข้อมูล',
  'oifde.procedure.db.dbUrl': 'URL ฐานข้อมูล',
  'oifde.procedure.db.dbUser': 'ผู้ใช้ฐานข้อมูล',
  'oifde.procedure.db.dbPassword': 'รหัสผ่านฐานข้อมูล',
  'oifde.procedure.dbTypes.MYSQL': 'MYSQL',
  'oifde.procedure.dbTypes.GAUSS': 'GAUSS',
  'oifde.procedure.dbTypes.ORACLE': 'ORACLE',
  'oifde.complexcell.cellTitle': 'องค์ประกอบแผนภาพคอมโพสิต',
  'oifde.complexcell.catalogTitle': 'แค็ตตาล็อกองค์ประกอบแผนภาพคอมโพสิต',
  'oifde.complexcell.catalog.name': 'ชื่อแค็ตตาล็อก',
  'oifde.complexcell.catalog.desc': 'คำอธิบายแค็ตตาล็อก',
  'oifde.complexcell.catalog.tips.sizeLimit': 'อนุญาตให้มีแคตตาล็อกได้สูงสุด 100 รายการ',
  'oifde.complexcell.cell.name': 'ชื่อองค์ประกอบแผนภาพ',
  'oifde.complexcell.cell.desc': 'คำอธิบาย',
  'oifde.complexcell.cell.height': 'ความสูง',
  'oifde.complexcell.cell.width': 'ความกว้าง',
  'oifde.complexcell.cell.icon': 'สัญลักษณ์',
  'oifde.complexcell.cell.catalog': 'แค็ตตาล็อก',
  'oifde.complexcell.cell.actions.editInfo': 'ปรับเปลี่ยนข้อมูลพื้นฐาน',
  'oifde.complexcell.cell.actions.editDiagram': 'แก้ไขแคนวาส',
  'oifde.complexcell.cell.actions.copy': 'ถ่ายซ้ำ',
  'oifde.complexcell.cell.validation.bigFile': 'ไฟล์ที่อัปโหลดต้องมีขนาดไม่เกิน {0}',
  'oifde.complexcell.cell.viewReference':'ดูการอ้างอิง',
  'oifde.complexcell.cell.actions.copySuccess':'คัดลอกเรียบร้อยแล้ว',
  'oifde.table.search': 'ค้นหา',
  'oifde.table.add': 'เพิ่ม',
  'oifde.table.actions': 'ดำเนินการ',
  'oifde.table.cancel': 'ยกเลิก',
  'oifde.table.confirm': 'ยืนยัน',
  'oifde.table.create': 'สร้าง',
  'oifde.table.delete': 'ลบ',
  'oifde.table.manage': 'จัดการ',
  'oifde.table.save': 'บันทึก',
  'oifde.table.reset': 'รีเซ็ต',
  'oifde.table.filter': 'ตัวกรอง',
  'oifde.tagsView.refresh': 'รีเฟรช',
  'oifde.tagsView.close': 'ปิด',
  'oifde.tagsView.closeOthers': 'ปิดสิ่งอื่น',
  'oifde.tagsView.closeAll': 'ปิดทั้งหมด',
  'oifde.mxgraph.saveAndNopublish': 'แคนวาสได้รับการบันทึกสำเร็จ แต่การเผยแพร่โฟลว์ล้มเหลว',
  'oifde.mxgraph.publishSuccess': 'บันทึกและประกาศสำเร็จ',
  'oifde.mxgraph.publishFailed': 'บันทึกและประกาศล้มเหลว',
  'oifde.mxgraph.saveAndPublish': 'บันทึกและประกาศ',
  'oifde.mxgraph.failInfo': 'สืบค้นข้อมูลล้มเหลว!',
  'oifde.mxgraph.alreadyConnected': 'เชื่อมต่อโหนดแล้ว',
  'oifde.mxgraph.cancel': 'ยกเลิก',
  'oifde.mxgraph.close': 'ปิด',
  'oifde.mxgraph.collapse-expand': 'ยุบ / ขยาย',
  'oifde.mxgraph.containsValidationErrors': 'พบข้อผิดพลาดในการตรวจสอบความถูกต้อง',
  'oifde.mxgraph.done': 'เรียบร้อย',
  'oifde.mxgraph.doubleClickOrientation': 'ดับเบิลคลิกเพื่อเปลี่ยนการวางแนว',
  'oifde.mxgraph.error': 'ข้อผิดพลาด',
  'oifde.mxgraph.errorSavingFile': 'เกิดข้อผิดพลาดในการบันทึกไฟล์',
  'oifde.mxgraph.ok': 'ตกลง',
  'oifde.mxgraph.updatingDocument': 'กำลังอัปเดตเอกสาร กรุณารอสักครู่...',
  'oifde.mxgraph.updatingSelection': 'กำลังอัปเดตการเลือก กรุณารอสักครู่...',
  'oifde.mxgraph.about': 'เกี่ยวกับ',
  'oifde.mxgraph.actualSize': 'ขนาดจริง',
  'oifde.mxgraph.add': 'เพิ่ม',
  'oifde.mxgraph.addLayer': 'เพิ่มชั้น',
  'oifde.mxgraph.addProperty': 'เพิ่มคุณสมบัติ',
  'oifde.mxgraph.addToExistingDrawing': 'เพิ่มในภาพเขียนที่มีอยู่',
  'oifde.mxgraph.addWaypoint': 'เพิ่ม Waypoint',
  'oifde.mxgraph.advanced': 'ขั้นสูง',
  'oifde.mxgraph.align': 'วางแนว',
  'oifde.mxgraph.alignment': 'การวางแนว',
  'oifde.mxgraph.allChangesLost': 'การเปลี่ยนแปลงทั้งหมดจะหายไป',
  'oifde.mxgraph.angle': 'มุม',
  'oifde.mxgraph.apply': 'ใช้',
  'oifde.mxgraph.arc': 'อาร์ค',
  'oifde.mxgraph.arrange': 'จัด',
  'oifde.mxgraph.arrow': 'ลูกศร',
  'oifde.mxgraph.arrows': 'ลูกศร',
  'oifde.mxgraph.automatic': 'อัตโนมัติ',
  'oifde.mxgraph.autosave': 'บันทึกอัตโนมัติ',
  'oifde.mxgraph.autosize': 'ปรับขนาดอัตโนมัติ',
  'oifde.mxgraph.background': 'พื้นหลัง',
  'oifde.mxgraph.backgroundColor': 'สีพื้นหลัง',
  'oifde.mxgraph.backgroundImage': 'รูปพื้นหลัง',
  'oifde.mxgraph.basic': 'พื้นฐาน',
  'oifde.mxgraph.block': 'บล็อค',
  'oifde.mxgraph.blockquote': 'Blockquote',
  'oifde.mxgraph.bold': 'ตัวหนา',
  'oifde.mxgraph.borderWidth': 'ความหนาขอบ',
  'oifde.mxgraph.borderColor': 'สีขอบ',
  'oifde.mxgraph.bottom': 'ด้านล่าง',
  'oifde.mxgraph.bottomAlign': 'จัดแนวด้านล่าง',
  'oifde.mxgraph.bottomLeft': 'ล่างซ้าย',
  'oifde.mxgraph.bottomRight': 'ล่างขวา',
  'oifde.mxgraph.bulletedList': 'รายการหัวข้อย่อย',
  'oifde.mxgraph.cannotOpenFile': 'ไม่สามารถเปิดไฟล์',
  'oifde.mxgraph.center': 'จัดในแนวตั้ง',
  'oifde.mxgraph.change': 'เปลี่ยน',
  'oifde.mxgraph.changeOrientation': 'เปลี่ยนการวางแนว',
  'oifde.mxgraph.circle': 'วงกลม',
  'oifde.mxgraph.classic': 'คลาสสิก',
  'oifde.mxgraph.clearDefaultStyle': 'ล้างสไตล์เริ่มต้น',
  'oifde.mxgraph.clearWaypoints': 'ล้าง Waypoint',
  'oifde.mxgraph.clipart': 'ภาพตัดปะ',
  'oifde.mxgraph.collapse': 'พับ',
  'oifde.mxgraph.collapseExpand': 'ยุบ / ขยาย',
  'oifde.mxgraph.collapsible': 'พับได้',
  'oifde.mxgraph.comic': 'คอมมิก',
  'oifde.mxgraph.connect': 'เชื่อมต่อ',
  'oifde.mxgraph.connection': 'การเชื่อมต่อ',
  'oifde.mxgraph.connectionPoints': 'จุดเชื่อมต่อ',
  'oifde.mxgraph.connectionArrows': 'ลูกศรเชื่อมต่อ',
  'oifde.mxgraph.constrainProportions': 'สัดส่วนที่จำกัด',
  'oifde.mxgraph.copy': 'คัดลอก',
  'oifde.mxgraph.copyConnect': 'คัดลอกการเชื่อมต่อ',
  'oifde.mxgraph.create': 'สร้าง',
  'oifde.mxgraph.curved': 'โค้ง',
  'oifde.mxgraph.custom': 'กำหนดเอง',
  'oifde.mxgraph.cut': 'ตัด',
  'oifde.mxgraph.dashed': 'ประ',
  'oifde.mxgraph.decreaseIndent': 'ลดการย่อหน้า',
  'oifde.mxgraph.default': 'ค่าเริ่มต้น',
  'oifde.mxgraph.delete': 'ลบ',
  'oifde.mxgraph.deleteColumn': 'ลบคอลัมน์',
  'oifde.mxgraph.deleteRow': 'ลบแถว',
  'oifde.mxgraph.diagram': 'แผนภาพ',
  'oifde.mxgraph.diamond': 'สี่เหลี่ยมขนมเปียกปูน',
  'oifde.mxgraph.diamondThin': 'สี่เหลี่ยมขนมเปียกปูน (บาง)',
  'oifde.mxgraph.direction': 'ทิศทาง',
  'oifde.mxgraph.distribute': 'กระจาย',
  'oifde.mxgraph.divider': 'ตัวแบ่ง',
  'oifde.mxgraph.documentProperties': 'คุณสมบัติเอกสาร',
  'oifde.mxgraph.dotted': 'จุด',
  'oifde.mxgraph.drawing': 'การวาด {0}',
  'oifde.mxgraph.drawingEmpty': 'ภาพเขียนว่างเปล่า',
  'oifde.mxgraph.drawingTooLarge': 'แผนภาพใหญ่เกินไป',
  'oifde.mxgraph.duplicate': 'ทำซ้ำ',
  'oifde.mxgraph.duplicateIt': 'ทำซ้ำ {0}',
  'oifde.mxgraph.east': 'ทิศตะวันออก',
  'oifde.mxgraph.edit': 'แก้ไข',
  'oifde.mxgraph.editData': 'แก้ไขข้อมูล',
  'oifde.mxgraph.editDiagram': 'แหล่งที่มา',
  'oifde.mxgraph.editImage': 'แก้ไขรูปภาพ',
  'oifde.mxgraph.editLink': 'แก้ไขลิงก์',
  'oifde.mxgraph.editStyle': 'แก้ไขสไตล์',
  'oifde.mxgraph.editTooltip': 'แก้ไข Tooltip',
  'oifde.mxgraph.enterGroup': 'กรอกกลุ่ม',
  'oifde.mxgraph.enterValue': 'กรอกค่า',
  'oifde.mxgraph.enterName': 'กรอกชื่อ',
  'oifde.mxgraph.enterPropertyName': 'กรอกชื่อคุณสมบัติ',
  'oifde.mxgraph.entityRelation': 'ความสัมพันธ์เอนทิตี',
  'oifde.mxgraph.exitGroup': 'ออกจากกลุ่ม',
  'oifde.mxgraph.expand': 'ขยาย',
  'oifde.mxgraph.export': 'ส่งออก',
  'oifde.mxgraph.extras': 'พิเศษ',
  'oifde.mxgraph.file': 'ไฟล์',
  'oifde.mxgraph.fileNotFound': 'ไม่พบไฟล์',
  'oifde.mxgraph.filename': 'ชื่อไฟล์',
  'oifde.mxgraph.fill': 'กรอก',
  'oifde.mxgraph.fillColor': 'เติมสี',
  'oifde.mxgraph.fitPage': 'หนึ่งหน้า',
  'oifde.mxgraph.fitPageWidth': 'ความกว้างของหน้า',
  'oifde.mxgraph.fitTwoPages': 'สองหน้า',
  'oifde.mxgraph.fitWindow': 'พอดีหน้าต่าง',
  'oifde.mxgraph.flip': 'กลับภาพ',
  'oifde.mxgraph.flipH': 'กลับภาพทางแนวนอน',
  'oifde.mxgraph.flipV': 'กลับภาพทางแนวตั้ง',
  'oifde.mxgraph.font': 'แบบอักษร',
  'oifde.mxgraph.fontFamily': 'ตระกูลแบบอักษร',
  'oifde.mxgraph.fontColor': 'สีแบบอักษร',
  'oifde.mxgraph.fontSize': 'ขนาดแบบอักษร',
  'oifde.mxgraph.format': 'รูปแบบ',
  'oifde.mxgraph.formatPanel': 'แผงรูปแบบ',
  'oifde.mxgraph.formatPdf': 'PDF',
  'oifde.mxgraph.formatPng': 'PNG',
  'oifde.mxgraph.formatGif': 'GIF',
  'oifde.mxgraph.formatJpg': 'JPEG',
  'oifde.mxgraph.formatSvg': 'SVG',
  'oifde.mxgraph.formatXml': 'XML',
  'oifde.mxgraph.formatted': 'จัดรูปแบบแล้ว',
  'oifde.mxgraph.formattedText': 'ข้อความที่จัดรูปแบบ',
  'oifde.mxgraph.gap': 'ช่องว่าง',
  'oifde.mxgraph.general': 'ทั่วไป',
  'oifde.mxgraph.glass': 'แก้ว',
  'oifde.mxgraph.global': 'ครอบคลุม',
  'oifde.mxgraph.gradient': 'เกรเดียนต์',
  'oifde.mxgraph.gradientColor': 'สี',
  'oifde.mxgraph.grid': 'ตาราง',
  'oifde.mxgraph.gridSize': 'ขนาดตาราง:',
  'oifde.mxgraph.group': 'กลุ่ม',
  'oifde.mxgraph.guides': 'คำแนะนำ',
  'oifde.mxgraph.heading': 'หัวเรื่อง',
  'oifde.mxgraph.height': 'ความสูง',
  'oifde.mxgraph.help': 'ช่วยเหลือ',
  'oifde.mxgraph.hide': 'ซ่อน',
  'oifde.mxgraph.hideIt': 'ซ่อน {0}',
  'oifde.mxgraph.hidden': 'ถูกซ่อน',
  'oifde.mxgraph.home': 'หน้าแรก',
  'oifde.mxgraph.horizontal': 'แนวนอน',
  'oifde.mxgraph.horizontalFlow': 'โฟลว์ในแนวนอน',
  'oifde.mxgraph.horizontalTree': 'ต้นไม้แนวนอน',
  'oifde.mxgraph.html': 'HTML',
  'oifde.mxgraph.image': 'รูปภาพ',
  'oifde.mxgraph.images': 'รูปภาพ',
  'oifde.mxgraph.import': 'นำเข้า',
  'oifde.mxgraph.increaseIndent': 'เพิ่มย่อหน้า',
  'oifde.mxgraph.insert': 'แทรก',
  'oifde.mxgraph.insertColumnBefore': 'แทรกคอลัมน์ทางซ้าย',
  'oifde.mxgraph.insertColumnAfter': 'แทรกคอลัมน์ทางขวา',
  'oifde.mxgraph.insertHorizontalRule': 'แทรกกฎแนวนอน',
  'oifde.mxgraph.insertImage': 'แทรกรูปภาพ',
  'oifde.mxgraph.insertLink': 'แทรกลิงค์',
  'oifde.mxgraph.insertRowBefore': 'แทรกแถวด้านบน',
  'oifde.mxgraph.insertRowAfter': 'แทรกแถวด้านล่าง',
  'oifde.mxgraph.invalidName': 'ชื่อไม่ถูกต้อง',
  'oifde.mxgraph.invalidOrMissingFile': 'ไฟล์ไม่ถูกต้องหรือหายไป',
  'oifde.mxgraph.isometric': 'มีมิติเท่ากัน',
  'oifde.mxgraph.italic': 'ตัวอักษรเอียง',
  'oifde.mxgraph.layers': 'ชั้น',
  'oifde.mxgraph.landscape': 'แนวนอน',
  'oifde.mxgraph.laneColor': 'สีเลน',
  'oifde.mxgraph.layout': 'เค้าโครง',
  'oifde.mxgraph.left': 'ซ้าย',
  'oifde.mxgraph.leftAlign': 'จัดชิดซ้าย',
  'oifde.mxgraph.leftToRight': 'ซ้ายไปขวา',
  'oifde.mxgraph.line': 'บรรทัด',
  'oifde.mxgraph.link': 'ลิงก์',
  'oifde.mxgraph.lineJumps': 'ข้ามบรรทัด',
  'oifde.mxgraph.lineend': 'สิ้นสุดบรรทัด',
  'oifde.mxgraph.lineheight': 'ความสูงระยะบรรทัด',
  'oifde.mxgraph.linestart': 'เริ่มบรรทัด',
  'oifde.mxgraph.linewidth': 'ความกว้างบรรทัด',
  'oifde.mxgraph.loading': 'กำลังโหลด',
  'oifde.mxgraph.lockUnlock': 'ล็อก / ปลดล็อก',
  'oifde.mxgraph.manual': 'ปรับด้วยมือ',
  'oifde.mxgraph.middle': 'จัดวางในแนวนอน',
  'oifde.mxgraph.misc': 'เบ็ดเตล็ด',
  'oifde.mxgraph.more': 'เพิ่มเติม',
  'oifde.mxgraph.moreResults': 'ผลลัพธ์เพิ่มเติม',
  'oifde.mxgraph.move': 'ย้าย',
  'oifde.mxgraph.moveSelectionTo': 'ย้ายที่เลือกไปที่ {0}',
  'oifde.mxgraph.navigation': 'การนำทาง',
  'oifde.mxgraph.new': 'ใหม่',
  'oifde.mxgraph.noColor': 'ไม่มีสี',
  'oifde.mxgraph.noFiles': 'ไม่มีไฟล์',
  'oifde.mxgraph.noMoreResults': 'ไม่มีผลลัพธ์เพิ่มเติม',
  'oifde.mxgraph.none': 'ไม่มี',
  'oifde.mxgraph.noResultsFor': 'ไม่พบผลลัพธ์สำหรับ \'{0}\'',
  'oifde.mxgraph.normal': 'ปกติ',
  'oifde.mxgraph.north': 'ทิศเหนือ',
  'oifde.mxgraph.numberedList': 'รายการลำดับเลข',
  'oifde.mxgraph.opacity': 'ความทึบ',
  'oifde.mxgraph.open': 'เปิด',
  'oifde.mxgraph.openArrow': 'เปิดลูกศร',
  'oifde.mxgraph.openFile': 'เปิดไฟล์',
  'oifde.mxgraph.openLink': 'เปิดลิงก์',
  'oifde.mxgraph.openSupported': 'รองรับไฟล์ XML ที่ส่งออกจากระบบนี้เท่านั้น',
  'oifde.mxgraph.openInNewWindow': 'เปิดในหน้าต่างใหม่',
  'oifde.mxgraph.openInThisWindow': 'เปิดในหน้าต่างนี้',
  'oifde.mxgraph.options': 'ตัวเลือก',
  'oifde.mxgraph.organic': 'ออร์แกนิก',
  'oifde.mxgraph.orthogonal': 'มุมฉาก',
  'oifde.mxgraph.outline': 'เค้าโครง',
  'oifde.mxgraph.oval': 'วงรี',
  'oifde.mxgraph.pages': 'หน้า',
  'oifde.mxgraph.pageView': 'มุมมองหน้า',
  'oifde.mxgraph.pageScale': 'มาตราส่วนหน้า',
  'oifde.mxgraph.pageSetup': 'ตั้งค่าหน้ากระดาษ',
  'oifde.mxgraph.panTooltip': 'Space + ลากเพื่อเลื่อน',
  'oifde.mxgraph.paperSize': 'ขนาดกระดาษ',
  'oifde.mxgraph.paste': 'วาง',
  'oifde.mxgraph.pasteHere': 'วางที่นี่',
  'oifde.mxgraph.pattern': 'รูปแบบ',
  'oifde.mxgraph.perimeter': 'เส้นรอบรูป',
  'oifde.mxgraph.placeholders': 'ตัวยึด',
  'oifde.mxgraph.plusTooltip': 'คลิกเพื่อเชื่อมต่อและสำเนา (ctrl + คลิกเพื่อสำเนา, shift + คลิกเพื่อเชื่อมต่อ) ลากเพื่อเชื่อมต่อ (ctrl + ลากเพื่อสำเนา)',
  'oifde.mxgraph.portrait': 'รูปคน',
  'oifde.mxgraph.position': 'ตำแหน่ง',
  'oifde.mxgraph.posterPrint': 'พิมพ์โปสเตอร์',
  'oifde.mxgraph.preview': 'ดูตัวอย่าง',
  'oifde.mxgraph.print': 'พิมพ์',
  'oifde.mxgraph.radialTree': 'ต้นไม้เรเดียล',
  'oifde.mxgraph.redo': 'ทำซ้ำ',
  'oifde.mxgraph.removeFormat': 'ล้างการจัดรูปแบบ',
  'oifde.mxgraph.removeFromGroup': 'นำออกจากกลุ่ม',
  'oifde.mxgraph.removeIt': 'นำ {0} ออก',
  'oifde.mxgraph.removeWaypoint': 'นำ Waypoint ออก',
  'oifde.mxgraph.rename': 'เปลี่ยนชื่อ',
  'oifde.mxgraph.renameIt': 'เปลี่ยนชื่อ {0}',
  'oifde.mxgraph.replace': 'แทนที่',
  'oifde.mxgraph.replaceIt': '{0} มีอยู่แล้ว คุณต้องการแทนที่หรือไม่',
  'oifde.mxgraph.replaceExistingDrawing': 'แทนที่ภาพเขียนที่มีอยู่',
  'oifde.mxgraph.reset': 'รีเซ็ต',
  'oifde.mxgraph.resetView': 'รีเซ็ตมุมมอง',
  'oifde.mxgraph.reverse': 'กลับด้าน',
  'oifde.mxgraph.right': 'ขวา',
  'oifde.mxgraph.rightAlign': 'จัดชิดขวา',
  'oifde.mxgraph.rightToLeft': 'ขวาไปซ้าย',
  'oifde.mxgraph.rotate': 'หมุน',
  'oifde.mxgraph.rotateTooltip': 'คลิกและลากเพื่อหมุนและคลิกเพื่อหมุน 90 องศา',
  'oifde.mxgraph.rotation': 'การหมุน',
  'oifde.mxgraph.rounded': 'มีรูปร่างกลม',
  'oifde.mxgraph.save': 'บันทึก',
  'oifde.mxgraph.saveAs': 'บันทึกเป็น',
  'oifde.mxgraph.saved': 'บันทึกแล้ว',
  'oifde.mxgraph.scrollbars': 'แถบเลื่อน',
  'oifde.mxgraph.search': 'ค้นหา',
  'oifde.mxgraph.searchShapes': 'ค้นหาโหนด',
  'oifde.mxgraph.selectAll': 'เลือกทั้งหมด',
  'oifde.mxgraph.selectEdges': 'เลือกขอบ',
  'oifde.mxgraph.selectFont': 'เลือกแบบอักษร',
  'oifde.mxgraph.selectNone': 'ไม่เลือกสิ่งใด',
  'oifde.mxgraph.selectVertices': 'เลือกจุดยอด',
  'oifde.mxgraph.setAsDefaultStyle': 'ตั้งเป็นสไตล์เริ่มต้น',
  'oifde.mxgraph.shadow': 'เงา',
  'oifde.mxgraph.shape': 'รูปร่าง',
  'oifde.mxgraph.sharp': 'คม',
  'oifde.mxgraph.sidebarTooltip': 'คลิกเพื่อขยายหรือยุบแผงโหนด',
  'oifde.mxgraph.simple': 'เรียบง่าย',
  'oifde.mxgraph.simpleArrow': 'ลูกศรเรียบง่าย',
  'oifde.mxgraph.size': 'ขนาด',
  'oifde.mxgraph.solid': 'ทรงตัน',
  'oifde.mxgraph.sourceSpacing': 'ระยะห่างของแหล่งที่มา',
  'oifde.mxgraph.south': 'ทิศใต้',
  'oifde.mxgraph.spacing': 'ระยะห่าง',
  'oifde.mxgraph.straight': 'ตรง',
  'oifde.mxgraph.strokeColor': 'สีเส้น',
  'oifde.mxgraph.style': 'สไตล์',
  'oifde.mxgraph.subscript': 'ดรรชนีล่าง',
  'oifde.mxgraph.superscript': 'ดรรชนีบน',
  'oifde.mxgraph.table': 'ตาราง',
  'oifde.mxgraph.targetSpacing': 'ระยะห่างเป้าหมาย',
  'oifde.mxgraph.text': 'ข้อความ',
  'oifde.mxgraph.textAlignment': 'การจัดแนวข้อความ',
  'oifde.mxgraph.textOpacity': 'ความทึบของข้อความ',
  'oifde.mxgraph.toBack': 'ไปด้านหลัง',
  'oifde.mxgraph.toFront': 'ไปด้านหน้า',
  'oifde.mxgraph.tooltips': 'Tooltip',
  'oifde.mxgraph.top': 'ด้านบนสุด',
  'oifde.mxgraph.topAlign': 'จัดแนวด้านบน',
  'oifde.mxgraph.topLeft': 'บนซ้าย',
  'oifde.mxgraph.topRight': 'บนขวา',
  'oifde.mxgraph.transparent': 'โปร่งใส',
  'oifde.mxgraph.turn': 'หมุน 90 องศา',
  'oifde.mxgraph.uml': 'UML',
  'oifde.mxgraph.underline': 'ขีดเส้นใต้',
  'oifde.mxgraph.undo': 'ย้อนกลับ',
  'oifde.mxgraph.ungroup': 'ยกเลิกการจัดกลุ่ม',
  'oifde.mxgraph.url': 'URL',
  'oifde.mxgraph.untitledLayer': 'ชั้นที่ไม่มีชื่อ',
  'oifde.mxgraph.vertical': 'แนวตั้ง',
  'oifde.mxgraph.verticalFlow': 'โฟลว์แนวตั้ง',
  'oifde.mxgraph.verticalTree': 'ต้นไม้แนวตั้ง',
  'oifde.mxgraph.view': 'ดู',
  'oifde.mxgraph.waypoints': 'Waypoint',
  'oifde.mxgraph.west': 'ทิศตะวันตก',
  'oifde.mxgraph.width': 'ความกว้าง',
  'oifde.mxgraph.wordWrap': 'ตัดคำ',
  'oifde.mxgraph.writingDirection': 'ทิศทางการเขียน',
  'oifde.mxgraph.zoom': 'ขยายภาพ',
  'oifde.mxgraph.zoomIn': 'ขยายเข้า',
  'oifde.mxgraph.zoomOut': 'ขยายออก',
  'oifde.mxgraph.success': 'สำเร็จ',
  'oifde.mxgraph.prompt': 'ข้อความพร้อมรับ',
  'oifde.mxgraph.NMS_DiagramProperties': 'แอตทริบิวต์กระบวนการ',
  'oifde.mxgraph.NMS_BasicProperties': 'แอตทริบิวต์พื้นฐาน',
  'oifde.mxgraph.NMS_DiagramID': 'ID กระบวนการ',
  'oifde.mxgraph.NMS_Status': 'สถานะ',
  'oifde.mxgraph.NMS_DiagramCode': 'รหัสกระบวนการ',
  'oifde.mxgraph.NMS_Valid': 'ถูกต้อง',
  'oifde.mxgraph.NMS_Invalid': 'ไม่ถูกต้อง',
  'oifde.mxgraph.NMS_DiagramName': 'ชื่อกระบวนการ',
  'oifde.mxgraph.NMS_Region': 'ภูมิภาค',
  'oifde.mxgraph.NMS_ScenarioType': 'ประเภทแผนการ',
  'oifde.mxgraph.NMS_Desciption': 'คำอธิบาย',
  'oifde.mxgraph.NMS_NodeProperties': 'แอตทริบิวต์โหนด',
  'oifde.mxgraph.NMS_NodeCode': 'รหัสโหนด',
  'oifde.mxgraph.NMS_NodeName': 'ชื่อโหนด',
  'oifde.mxgraph.NMS_BranchProperties': 'แอตทริบิวต์สาขา',
  'oifde.mxgraph.NMS_BranchCondition': 'สภาวะสาขา',
  'oifde.mxgraph.NMS_DefaultBranch': 'สาขาที่เป็นค่าเริ่มต้น',
  'oifde.mxgraph.NMS_AdvanceProperties': 'แอตทริบิวต์ขั้นสูง',
  'oifde.mxgraph.NMS_ServiceName': 'ชื่อการบริการ',
  'oifde.mxgraph.NMS_ServiceParams': 'พารามิเตอร์การบริการ',
  'oifde.mxgraph.NMS_ServiceDesciption': 'คำอธิบายการบริการ',
  'oifde.mxgraph.NMS_PleaseSelect': 'กรุณาเลือก',
  'oifde.mxgraph.NMS_ParamName': 'พารามิเตอร์',
  'oifde.mxgraph.NMS_ParamValue': 'ค่า',
  'oifde.mxgraph.NMS_ParamDescription': 'คำอธิบาย',
  'oifde.mxgraph.NMS_FlowSaveSuccess': 'บันทึกโฟลว์สำเร็จแล้ว',
  'oifde.mxgraph.NMS_FlowSaveFail': 'บันทึกโฟลว์ล้มเหลว',
  'oifde.mxgraph.NMS_FlowOpenFail': 'เปิดโฟลว์ล้มเหลว',
  'oifde.mxgraph.NMS_ActionTypesGetFail': 'รับข้อมูลองค์ประกอบแผนภาพล้มเหลว',
  'oifde.mxgraph.NMS_ActionParamsGetFail': 'รับพารามิเตอร์องค์ประกอบแผนภาพล้มเหลว',
  'oifde.mxgraph.NMS_FlowsGetFail': 'รับรายการโฟลว์ล้มเหลว',
  'oifde.mxgraph.NMS_NodesGetFail': 'รับรายการโหนดของโฟลว์ {0} ล้มเหลว',
  'oifde.mxgraph.NMS_InterfacesGetFail': 'รับรายการอินเทอร์เฟซการเข้าถึงล้มเหลว',
  'oifde.mxgraph.NMS_ContentTemplateGetFail': 'รับรายการเทมเพลตเนื้อหาล้มเหลว',
  'oifde.mxgraph.NMS_EditorModelLoadFail': 'โหลดการกำหนดค่าโมเดลตัวแก้ไขล้มเหลว',
  'oifde.mxgraph.NMS_RegionInfoLoadFail': 'โหลดข้อมูลภูมิภาคล้มเหลว',
  'oifde.mxgraph.NMS_IntentCodeLoadFail': 'โหลดรหัสเจตนาล้มเหลว',
  'oifde.mxgraph.NMS_PleaseSelectTemplate': 'เลือกเท็มเพลต',
  'oifde.mxgraph.NMS_TargetFlow': 'โฟลว์เป้าหมาย',
  'oifde.mxgraph.NMS_TargetNode': 'โหนดเป้าหมาย',
  'oifde.mxgraph.NMS_SearchTemplate': '',
  'oifde.mxgraph.NMS_TemplateCode': 'รหัสเทมเพลต',
  'oifde.mxgraph.NMS_flow': 'โฟลว์',
  'oifde.mxgraph.NMS_ScenarioType_main': 'โฟลว์หลัก',
  'oifde.mxgraph.NMS_ScenarioType_sub': 'โฟลว์ย่อย',
  'oifde.mxgraph.NMS_ScenarioType_error': 'แผนการยกเว้น',
  'oifde.mxgraph.NMS_flowCodeError': 'รหัสโฟลว์ต้องไม่ว่างเปล่าหรือมีอักขระมากกว่า 64 ตัว',
  'oifde.mxgraph.NMS_flowNameError': 'ชื่อโฟลว์ต้องไม่ว่างเปล่าหรือมีอักขระมากกว่า 1,000 ตัว',
  'oifde.mxgraph.NMS_flowDescError': 'คำอธิบายโฟลว์ต้องมีอักขระไม่เกิน 1,000 ตัว',
  'oifde.mxgraph.NMS_nmsFlowError': 'การแยกวิเคราะห์โฟลว์ล้มเหลว ไม่พบโหนด nmsFlow',
  'oifde.mxgraph.NMS_rootError': 'การแยกวิเคราะห์โฟลว์ล้มเหลว ไม่พบโหนดราก',
  'oifde.mxgraph.NMS_VariableNameError': 'ชื่อตัวแปรต้องมีอักขระไม่เกิน 1,000 ตัว',
  'oifde.mxgraph.NMS_VariableDescError': 'คำอธิบายตัวแปรต้องมีอักขระไม่เกิน 1,000 ตัว',
  'oifde.mxgraph.NMS_VariableDefaultValueError': 'ค่าเริ่มต้นของตัวแปรต้องมีอักขระไม่เกิน 1024 ตัว',
  'oifde.mxgraph.NMS_NodeCodeError': 'รหัสโหนดต้องไม่ว่างเปล่าหรือมีอักขระมากกว่า 64 ตัว',
  'oifde.mxgraph.NMS_NodeNameError': 'ชื่อโหนดต้องไม่ว่างเปล่าหรือมีอักขระมากกว่า 1,000 ตัว',
  'oifde.mxgraph.NMS_NodeDescError': 'คำอธิบายโหนดต้องมีอักขระไม่เกิน 1,000 ตัว',
  'oifde.mxgraph.NMS_actionParamNameError': 'ชื่อพารามิเตอร์ต้องมีอักขระไม่เกิน 64 ตัว',
  'oifde.mxgraph.NMS_actionParamValueEmptyError': 'ค่าต้องไม่ว่างเปล่า',
  'oifde.mxgraph.NMS_actionParamValueError': 'ค่าพารามิเตอร์ต้องมีอักขระไม่เกิน 4096 ตัว',
  'oifde.mxgraph.NMS_actionParamDescError': 'คำอธิบายพารามิเตอร์ต้องมีอักขระไม่เกิน 500 ตัว',
  'oifde.mxgraph.NMS_transitToStartNodeError': 'ข้ามไปยังโหนดเริ่มต้นล้มเหลว',
  'oifde.mxgraph.NMS_endNodetransitToError': 'โหนดสิ้นสุดไม่สามารถข้ามไปยังโหนดอื่นได้',
  'oifde.mxgraph.NMS_startNodeNotFind': 'ไม่พบโหนดเริ่มต้น',
  'oifde.mxgraph.NMS_startNodeNotUnique': 'โหนดเริ่มต้นไม่มีความเป็นเอกลักษณ์',
  'oifde.mxgraph.NMS_startNodeNotBranchError': 'โหนดเริ่มต้นไม่มีสาขาหรือมีมากกว่าหนึ่งสาขา',
  'oifde.mxgraph.NMS_endNodeNotFind': 'ไม่พบโหนดสิ้นสุด',
  'oifde.mxgraph.NMS_analiseXmlFailed': 'แยกวิเคราะห์โฟลว์แคนวาสล้มเหลว',
  'oifde.mxgraph.NMS_branchError': 'สาขาไม่มีโหนดเริ่มต้นหรือโหนดสิ้นสุด',
  'oifde.mxgraph.NMS_nullBranchNotNuique': 'โหนดสามารถมีสาขาตั้งต้นได้เพียงสาขาเดียว',
  'oifde.mxgraph.NMS_checkDefaultNextNodeForView': 'องค์ประกอบแผนภาพของชนิดมุมมองไม่สามารถมีสาขามากกว่าหนึ่งสาขา',
  'oifde.mxgraph.NMS_nodeRefError': 'โหนดถูกอ้างอิงโดยโฟลว์อื่นและไม่สามารถลบได้',
  'oifde.mxgraph.NMS_ValidateError': 'ข้อผิดพลาดการตรวจสอบความถูกต้อง ตรวจสอบข้อมูลในสีแดง',
  'oifde.mxgraph.NMS_SpaceValidate': 'ค่าไม่สามารถขึ้นต้นด้วยช่องว่าง',
  'oifde.mxgraph.NMS_KeyRepeats': 'ชื่อเงื่อนไขซ้ํากัน',
  'oifde.mxgraph.NMS_encryptValidate': 'Ivrquest ตัวแปรประมวลผล และตัวแปรทั่วไปสําหรับการเข้ารหัสประเภทของการเข้ารหัสนั้นสามารถกําหนดได้เฉพาะกับตัวแปรประมวลผลและตัวแปรทั่วไปของประเภทการเข้ารหัสเท่านั้น!',
  'oifde.knowledgegraph.actions.edit': 'แก้ไข',
  'oifde.knowledgegraph.actions.view': 'ดู',
  'oifde.knowledgegraph.actions.delete': 'ลบ',
  'oifde.knowledgegraph.actions.clean': 'ล้าง',
  'oifde.knowledgegraph.actions.prompt': 'ข้อมูล',
  'oifde.knowledgegraph.actions.confirm': 'ยืนยัน',
  'oifde.knowledgegraph.actions.nameRequiredTip': 'ชื่อเป็นสิ่งจำเป็น',
  'oifde.knowledgegraph.actions.selectTip': '---เลือก---',
  'oifde.knowledgegraph.actions.cancel': 'ยกเลิก',
  'oifde.knowledgegraph.actions.cancelDelete': 'การลบยกเลิกแล้ว',
  'oifde.knowledgegraph.actions.success': 'สำเร็จ',
  'oifde.knowledgegraph.actions.updateSuccess': 'การปรับเปลี่ยนสำเร็จแล้ว',
  'oifde.knowledgegraph.actions.deleteSuccess': 'การลบสำเร็จแล้ว',
  'oifde.knowledgegraph.actions.createSuccess': 'การเพิ่มสำเร็จแล้ว',
  'oifde.knowledgegraph.actions.createFailed': 'การเพิ่มล้มเหลว',
  'oifde.knowledgegraph.actions.modifyFailed': 'การปรับเปลี่ยนล้มเหลว',
  'oifde.knowledgegraph.actions.deleteFailed': 'การลบล้มเหลว',
  'oifde.knowledgegraph.actions.saveSuccess': 'การบันทึกสำเร็จแล้ว',
  'oifde.knowledgegraph.actions.saveFailed': 'การบันทึกล้มเหลว',
  'oifde.knowledgegraph.actions.addSchema': 'เค้าร่างใหม่',
  'oifde.knowledgegraph.actions.editSchema': 'แก้ไขเค้าร่าง',
  'oifde.knowledgegraph.actions.addDic': 'พจนานุกรมกราฟใหม่',
  'oifde.knowledgegraph.actions.editDic': 'แก้ไขพจนานุกรมกราฟ',
  'oifde.knowledgegraph.actions.addEntity': 'เอนทิตีใหม่',
  'oifde.knowledgegraph.actions.editEntity': 'แก้ไขเอนทิตี',
  'oifde.knowledgegraph.actions.addRelation': 'ความสัมพันธ์ใหม่',
  'oifde.knowledgegraph.actions.editRelation': 'แก้ไขความสัมพันธ์',
  'oifde.knowledgegraph.actions.addAttribute': 'แอตทริบิวต์ใหม่',
  'oifde.knowledgegraph.actions.editAttribute': 'แก้ไขแอตทริบิวต์',
  'oifde.knowledgegraph.actions.addSynonyms': 'คำพ้องใหม่',
  'oifde.knowledgegraph.actions.editSynonyms': 'แก้ไขคำพ้อง',
  'oifde.knowledgegraph.actions.addExtQuestion': 'คำถามเพิ่มเติมใหม่',
  'oifde.knowledgegraph.actions.editExtQuestion': 'แก้ไขคำถามเพิ่มเติม',
  'oifde.knowledgegraph.actions.viewSchema': 'ดูเค้าร่าง',
  'oifde.knowledgegraph.actions.graphicPreview': 'ดูตัวอย่างกราฟิก',
  'oifde.knowledgegraph.actions.addSchemaEntity': 'แนวคิดใหม่',
  'oifde.knowledgegraph.actions.editSchemaEntity': 'แก้ไขแนวคิด',
  'oifde.knowledgegraph.model.schemaName': 'ชื่อเค้าร่าง',
  'oifde.knowledgegraph.model.schemaCode': 'รหัสเค้าร่าง',
  'oifde.knowledgegraph.model.schemaDescription': 'คำอธิบาย',
  'oifde.knowledgegraph.model.modifyTime': 'เวลาที่ปรับเปลี่ยน',
  'oifde.knowledgegraph.model.operation': 'การดำเนินการ',
  'oifde.knowledgegraph.model.createUser': 'ผู้สร้าง',
  'oifde.knowledgegraph.model.beforeDeletePrompt': 'ไม่สามารถลบเค้าร่างได้เนื่องจากมีการเชื่อมโยงกับพจนานุกรมกราฟ พจนานุกรมกราฟที่เกี่ยวข้อง:',
  'oifde.knowledgegraph.model.beforeEditPrompt': 'ไม่สามารถปรับเปลี่ยนเค้าร่างปัจจุบันเนื่องจากมีการเชื่อมโยงกับพจนานุกรมกราฟ พจนานุกรมกราฟที่เกี่ยวข้อง:',
  'oifde.knowledgegraph.model.deletePrompt': 'การดำเนินการนี้จะลบเค้าร่างและแนวคิด ความสัมพันธ์ และแอตทริบิวต์ที่อยู่ในเค้าร่างอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.editSchema.entity': 'แนวคิด',
  'oifde.knowledgegraph.editSchema.entityName': 'ชื่อแนวคิด',
  'oifde.knowledgegraph.editSchema.relation': 'ความสัมพันธ์',
  'oifde.knowledgegraph.editSchema.relationName': 'ชื่อความสัมพันธ์',
  'oifde.knowledgegraph.editSchema.headEntity': 'แนวคิดส่วนต้น',
  'oifde.knowledgegraph.editSchema.tailEntity': 'แนวคิดส่วนท้าย',
  'oifde.knowledgegraph.editSchema.selectTip': '---เลือก---',
  'oifde.knowledgegraph.editSchema.objectName': 'แนวคิด / ความสัมพันธ์',
  'oifde.knowledgegraph.editSchema.attributeName': 'ชื่อแอตทริบิวต์',
  'oifde.knowledgegraph.editSchema.attributeType': 'ประเภทแอตทริบิวต์',
  'oifde.knowledgegraph.editSchema.unit': 'หน่วย',
  'oifde.knowledgegraph.editSchema.type': 'ประเภท',
  'oifde.knowledgegraph.editSchema.schemaNoDataTip': 'เพิ่มข้อมูลแนวคิดและความสัมพันธ์ในหน้าต่างด้านซ้ายและคลิกปุ่มมุมมองทางด้านขวาของแนวคิดหรือความสัมพันธ์ที่เกี่ยวข้องเพื่อดูเนื้อหาแอตทริบิวต์ของข้อมูลที่เกี่ยวข้อง',
  'oifde.knowledgegraph.editSchema.deleteEntityPrompt': 'การดำเนินการนี้จะลบแนวคิดและแอตทริบิวต์อย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.editSchema.deleteRelationPrompt': 'การดำเนินการนี้จะลบความสัมพันธ์และแอตทริบิวต์อย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.editSchema.deleteAttributePrompt': 'การดำเนินการนี้จะลบแอตทริบิวต์อย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.editSchema.dataType.int': 'จำนวนเต็ม',
  'oifde.knowledgegraph.editSchema.dataType.float': 'เลขทศนิยม',
  'oifde.knowledgegraph.editSchema.dataType.boolean': 'บูลีน',
  'oifde.knowledgegraph.editSchema.dataType.string': 'อักขระ',
  'oifde.knowledgegraph.editSchema.dataType.date': 'วันที่',
  'oifde.knowledgegraph.dicmgr.dicName': 'ชื่อกราฟ',
  'oifde.knowledgegraph.dicmgr.dicCode': 'รหัสกราฟ',
  'oifde.knowledgegraph.dicmgr.dicFunction': 'การใช้กราฟ',
  'oifde.knowledgegraph.dicmgr.modifyTime': 'เวลาที่ปรับเปลี่ยน',
  'oifde.knowledgegraph.dicmgr.schemaName': 'เค้าร่างที่เกี่ยวข้อง',
  'oifde.knowledgegraph.dicmgr.kgState': 'สถานะกราฟ',
  'oifde.knowledgegraph.dicmgr.operation': 'การดำเนินการ',
  'oifde.knowledgegraph.dicmgr.moreOperation': 'เพิ่มเติม',
  'oifde.knowledgegraph.dicmgr.entityMgr': 'จัดการเอนทิตี',
  'oifde.knowledgegraph.dicmgr.relationMgr': 'การจัดการความสัมพันธ์',
  'oifde.knowledgegraph.dicmgr.kgView': 'การแสดงภาพกราฟ',
  'oifde.knowledgegraph.dicmgr.kgFAQ': 'คำถามที่พบบ่อยเกี่ยวกับกราฟ',
  'oifde.knowledgegraph.dicmgr.active': 'เปิดใช้งาน',
  'oifde.knowledgegraph.dicmgr.inactive': 'ปิดใช้งาน',
  'oifde.knowledgegraph.dicmgr.creating': 'กำลังสร้าง',
  'oifde.knowledgegraph.dicmgr.trainAll': 'ฝึกทั้งหมด',
  'oifde.knowledgegraph.dicmgr.trainSuccess': 'การฝึกสำเร็จแล้ว',
  'oifde.knowledgegraph.dicmgr.trainFail': 'การฝึกล้มเหลว',
  'oifde.knowledgegraph.dicmgr.changeState': 'ปรับเปลี่ยนสถานะ',
  'oifde.knowledgegraph.dicmgr.synonyms': 'คำพ้อง',
  'oifde.knowledgegraph.dicmgr.extendQuestion': 'คำถามเพิ่มเติม',
  'oifde.knowledgegraph.dicmgr.deletePrompt': 'การดำเนินการนี้จะลบพจนานุกรมและเอนทิตี ความสัมพันธ์ และแอตทริบิวต์ที่มีอยู่ในพจนานุกรมอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.entitymgr.entityName': 'ชื่อเอนทิตี',
  'oifde.knowledgegraph.entitymgr.schemaEntityName': 'แนวคิดเค้าร่างที่เกี่ยวข้อง',
  'oifde.knowledgegraph.entitymgr.beforeDeletePrompt': 'ลบความสัมพันธ์ของเอนทิตีแล้วลบเอนทิตี',
  'oifde.knowledgegraph.entitymgr.deletePrompt': 'การดำเนินการนี้จะลบเอนทิตีอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.entitymgr.entityAttributeMgr.deletePrompt': 'การดำเนินการนี้จะลบแอตทริบิวต์เอนทิตีอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.relationmgr.headEntity': 'เอนทิตีส่วนต้น',
  'oifde.knowledgegraph.relationmgr.tailEntity': 'เอนทิตีส่วนท้าย',
  'oifde.knowledgegraph.relationmgr.relation': 'ความสัมพันธ์',
  'oifde.knowledgegraph.relationmgr.doubleRelation': 'ความสัมพันธ์สองทาง',
  'oifde.knowledgegraph.relationmgr.doubleRelationTrue': 'ใช่',
  'oifde.knowledgegraph.relationmgr.doubleRelationFalse': 'ไม่ใช่',
  'oifde.knowledgegraph.relationmgr.doublePrompt': 'เอนทิตีส่วนต้นเหมือนกับเอนทิตีส่วนท้ายและไม่สามารถสร้างความสัมพันธ์ได้ เลือกเอนทิตีส่วนต้นหรือเอนทิตีส่วนท้ายอื่น',
  'oifde.knowledgegraph.relationmgr.deletePrompt': 'การดำเนินการนี้จะลบความสัมพันธ์และแอตทริบิวต์ที่มีอยู่ในความสัมพันธ์อย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.attributemgr.attributeName': 'ชื่อแอตทริบิวต์',
  'oifde.knowledgegraph.attributemgr.attribute': 'ค่าแอตทริบิวต์',
  'oifde.knowledgegraph.attributemgr.description': 'คำอธิบายแอตทริบิวต์',
  'oifde.knowledgegraph.attributemgr.deletePrompt': 'การดำเนินการนี้จะลบแอตทริบิวต์อย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.kgFAQ.title': 'คำถามที่พบบ่อยเกี่ยวกับกราฟ',
  'oifde.knowledgegraph.kgFAQ.name': 'พจนานุกรมกราฟ:',
  'oifde.knowledgegraph.kgFAQ.welcomeContent': 'สวัสดี! ฉันเป็นผู้ช่วยที่ชาญฉลาด ฉันยินดีที่จะช่วยคุณ',
  'oifde.knowledgegraph.kgSynonyms.keyword': 'ชื่อ',
  'oifde.knowledgegraph.kgSynonyms.synonyms': 'คำพ้อง',
  'oifde.knowledgegraph.kgSynonyms.number': 'หมายเลข',
  'oifde.knowledgegraph.kgSynonyms.synonymsDetail': 'รายละเอียดคำพ้อง',
  'oifde.knowledgegraph.kgSynonyms.name': 'ชื่อ',
  'oifde.knowledgegraph.kgSynonyms.deletePrompt': 'การดำเนินการนี้จะลบการกรอกข้อมูลและคำพ้องทั้งหมดอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.kgSynonyms.deleteSynonymsPrompt': 'การดำเนินการนี้จะลบคำพ้องอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.knowledgegraph.kgSynonyms.type.type': 'ประเภท',
  'oifde.knowledgegraph.kgSynonyms.type.entity': 'เอนทิตี',
  'oifde.knowledgegraph.kgSynonyms.type.relation': 'ความสัมพันธ์',
  'oifde.knowledgegraph.kgSynonyms.type.property': 'แอตทริบิวต์',
  'oifde.knowledgegraph.kgView.queryType': 'ประเภทการสืบค้น',
  'oifde.knowledgegraph.kgView.entityName': 'ชื่อเอนทิตี',
  'oifde.knowledgegraph.kgView.displayType': 'โหมดการแสดง',
  'oifde.knowledgegraph.kgView.queryByName': 'สืบค้นตามชื่อเอนทิตี',
  'oifde.knowledgegraph.validation.schemaName': 'ชื่อเค้าร่างเป็นสิ่งจำเป็น',
  'oifde.knowledgegraph.validation.dicName': 'ชื่อพจนานุกรมเป็นสิ่งจำเป็น',
  'oifde.knowledgegraph.validation.selectRequired': 'จำเป็น',
  'oifde.knowledgegraph.validation.attrValue': 'ค่าแอตทริบิวต์เป็นสิ่งจำเป็น',
  'oifde.knowledgegraph.validation.required': 'ค่าเป็นสิ่งจำเป็น',
  'oifde.knowledgegraph.errorCode.100010000000': 'ข้อผิดพลาดของระบบ',
  'oifde.knowledgegraph.errorCode.100010000003': 'ชื่อจะต้องเป็นเอกลักษณ์',
  'oifde.knowledgegraph.errorCode.100010000002': 'รูปแบบผิดพลาด',
  'oifde.knowledgegraph.errorCode.100051001013': 'มีการใช้งานเค้าร่าง',
  'oifde.knowledgegraph.errorCode.100051001012': 'มีการกำหนดค่าคำพ้องสำหรับชื่อ',
  'oifde.knowledgegraph.errorCode.100010000001': 'ฐานข้อมูลผิดพลาด',
  'oifde.knowledgegraph.errorCode.100051000110': 'จำนวนถึงขีดจำกัดสูงสุด 10',
  'oifde.knowledgegraph.errorCode.100051000111': 'จำนวนถึงขีดจำกัดสูงสุด 190',
  'oifde.knowledgegraph.errorCode.100051000113': 'URL ที่กำหนดค่าสำหรับการบริการ GES ไม่ถูกต้อง',
  'oifde.knowledgegraph.errorCode.100051000112': 'กำลังจัดทำกราฟ ...',
  'oifde.knowledgegraph.errorCode.100061000005': 'กราฟความรู้ได้ถูกผูกไว้',
  'oifde.knowledgegraph.errorCode.100010000005': 'พารามิเตอร์อินพุตไม่ถูกต้อง',
  'oifde.knowledgegraph.errorCode.100051000114': 'บันทึกคำถามเพิ่มเติมสำหรับคำถามที่พบบ่อยเกี่ยวกับกราฟล้มเหลว',
  'oifde.knowledgegraph.errorCode.100061000006': 'เชื่อมต่อกับ ODFS ล้มเหลว',
  'oifde.knowledgegraph.extendFaq.setting': 'ตั้งคำถามเพิ่มเติม',
  'oifde.knowledgegraph.extendFaq.import': 'นำเข้า',
  'oifde.knowledgegraph.extendFaq.export': 'ส่งออกทั้งหมด',
  'oifde.knowledgegraph.extendFaq.extendFaq': 'รายการเทมเพลตคำถามที่พบบ่อย',
  'oifde.knowledgegraph.extendFaq.faqTemplate': 'เทมเพลตคำถามที่พบบ่อย',
  'oifde.knowledgegraph.extendFaq.description': 'คำอธิบาย',
  'oifde.knowledgegraph.extendFaq.updateTime': 'อัปเดตเมื่อ',
  'oifde.knowledgegraph.extendFaq.status': 'สถานะ',
  'oifde.knowledgegraph.extendFaq.setTemplate': 'ตั้งคำถามเพิ่มเติม',
  'oifde.knowledgegraph.extendFaq.use': 'ใช้ / ไม่ใช้',
  'oifde.knowledgegraph.extendFaq.question': 'คำถาม',
  'oifde.knowledgegraph.extendFaq.reply': 'ตอบ',
  'oifde.knowledgegraph.extendFaq.similarQuestion': 'คำถามที่คล้ายกัน',
  'oifde.knowledgegraph.extendFaq.similarReply': 'คำตอบที่คล้ายกัน',
  'oifde.knowledgegraph.extendFaq.edit': 'ปรับเปลี่ยน-',
  'oifde.knowledgegraph.extendFaq.inUse': 'ใช้งานอยู่',
  'oifde.knowledgegraph.extendFaq.notUse': 'ไม่ได้ใช้งาน',
  'oifde.knowledgegraph.extendFaq.operation': 'การดำเนินการ',
  'oifde.knowledgegraph.extendFaq.updateSuccess': 'เปลี่ยนสถานะสำเร็จ',
  'oifde.knowledgegraph.extendFaq.updateFailed': 'เปลี่ยนสถานะล้มเหลว',
  'oifde.knowledgegraph.extendFaq.updateTemplateSuccess': 'ปรับเปลี่ยนเทมเพลตคำถามที่พบบ่อยสำเร็จ',
  'oifde.knowledgegraph.extendFaq.updateTemplateFailed': 'ปรับเปลี่ยนเทมเพลตคำถามที่พบบ่อยล้มเหลว',
  'oifde.whitelist.tenantId': 'ID พื้นที่ผู้เช่า:',
  'oifde.whitelist.tenantSpace': 'พื้นที่ของผู้เช่า',
  'oifde.whitelist.whiteIp': 'ที่อยู่ IP ของรายการที่เชื่อถือได้หรือชื่อโดเมน',
  'oifde.whitelist.whitePort': 'พอร์ตที่เชื่อถือได้',
  'oifde.whitelist.whiteType': 'ประเภทรายการที่เชื่อถือได้',
  'oifde.whitelist.description': 'คำอธิบาย',
  'oifde.whitelist.whiteTypes.INTERFACE': 'อินเทอร์เฟซ',
  'oifde.whitelist.whiteTypes.PROCEDURE': 'กระบวนงานที่จัดเก็บ',
  'oifde.whitelist.addWhiteList': 'เพิ่มรายการที่เชื่อถือได้',
  'oifde.whitelist.editWhiteList': 'แก้ไขรายการที่เชื่อถือได้',
  'oifde.whitelist.whiteIpLen': 'ที่อยู่ IP หรือชื่อโดเมนของรายการที่เชื่อถือได้สามารถมีอักขระได้สูงสุด 128 ตัว',
  'oifde.whitelist.whitePortLen': 'หมายเลขพอร์ตของรายการที่เชื่อถือได้สามารถมีอักขระได้สูงสุด 20 ตัว',
  'oifde.whitelist.descriptionLen': 'คำอธิบายสามารถมีอักขระได้สูงสุด 1024 ตัว',
  'oifde.whitelist.number': 'ค่าต้องเป็นตัวเลข',
  'oifde.whitelist.whiteIpListNotNull': 'เลือกรายการที่เชื่อถือได้อย่างน้อยหนึ่งรายการที่จะลบ',
  'oifde.whitelist.deletePrompt': 'การดำเนินการนี้จะลบรายการที่เชื่อถือได้ที่เลือกอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.whitelist.ipRange': 'ที่อยู่ IPสามารถมีได้เฉพาะตัวอักษร ตัวเลข จุด (.) ขีดล่าง (_) และขีดกลาง (-) และไม่สามารถขึ้นต้นหรือลงท้ายด้วยขีดกลาง (-) ได้',
  'oifde.operationlog.generate_test_case': 'การสร้างเคสอัตโนมัติ',
  'oifde.operationlog.stop_auto_generate': 'การสร้างเคสที่ถูกขัดจังหวะ',
  'oifde.operationlog.logging_module': 'โมดูล',
  'oifde.operationlog.operation_log': 'การลงบันทึกการดำเนินการ',
  'oifde.operationlog.operator': 'บัญชี',
  'oifde.operationlog.operation_obj_type': 'ประเภทวัตถุการดำเนินการ',
  'oifde.operationlog.startTime': 'เวลาเริ่มต้น',
  'oifde.operationlog.endTime': 'เวลาสิ้นสุด',
  'oifde.operationlog.operationType': 'ประเภทการดำเนินงาน',
  'oifde.operationlog.targetObjType': 'ประเภทวัตถุ',
  'oifde.operationlog.targetObjId': 'ID วัตถุ',
  'oifde.operationlog.targetObjName': 'วัตถุ',
  'oifde.operationlog.result': 'ผลลัพธ์',
  'oifde.operationlog.detail': 'รายละเอียด',
  'oifde.operationlog.operationTime': 'เวลาการดำเนินการ',
  'oifde.operationlog.loginIp': 'ที่อยู่ IPในการลงชื่อเข้าใช้',
  'oifde.operationlog.check': 'ตรวจสอบความถูกต้อง',
  'oifde.operationlog.query': 'การสืบค้น',
  'oifde.operationlog.create': 'สร้าง',
  'oifde.operationlog.copy': 'คัดลอก',
  'oifde.operationlog.paste': 'วาง',
  'oifde.operationlog.clone': 'สำเนา',
  'oifde.operationlog.import': 'นำเข้า',
  'oifde.operationlog.export': 'ส่งออก',
  'oifde.operationlog.update': 'อัปเดต',
  'oifde.operationlog.delete': 'ลบ',
  'oifde.operationlog.enable': 'เปิดใช้งาน',
  'oifde.operationlog.disable': 'ปิดใช้งาน',
  'oifde.operationlog.set_test': 'ตั้งเป็นการทดสอบ',
  'oifde.operationlog.hang_up': 'วางสาย',
  'oifde.operationlog.release': 'เผยแพร่',
  'oifde.operationlog.invalidate': 'ทำให้ใช้การไม่ได้',
  'oifde.operationlog.upgrade': 'อัปเกรด',
  'oifde.operationlog.set_white_list': 'ตั้งกฎระดับสีเทา',
  'oifde.operationlog.intent_template': 'เทมเพลตเจตนา',
  'oifde.operationlog.user_entity': 'เอนทิตีผู้ใช้',
  'oifde.operationlog.system_entity': 'เอนทิตีระบบ',
  'oifde.operationlog.voice_content': 'ไฟล์เสียง',
  'oifde.operationlog.system_parameters': 'พารามิเตอร์ของระบบ',
  'oifde.operationlog.intent_parameters': 'พารามิเตอร์เจตนา',
  'oifde.operationlog.call_session': 'การโต้ตอบการโทร',
  'oifde.operationlog.cluster_task': 'งานการคลัสเตอร์',
  'oifde.operationlog.history_dialogue': 'ประวัติการโต้ตอบ',
  'oifde.operationlog.test_case_group': 'กลุ่มเคสทดสอบ',
  'oifde.operationlog.test_case': 'เคสทดสอบ',
  'oifde.operationlog.faq': 'กลุ่มคำถามที่พบบ่อย',
  'oifde.operationlog.faq_element': 'ถาม-ตอบ',
  'oifde.operationlog.log_parameters': 'พารามิเตอร์การลงบันทึก',
  'oifde.operationlog.tps_interface': 'อินเทอร์เฟซการบริการ',
  'oifde.operationlog.nms_interface': 'อินเทอร์เฟซกระบวนการโต้ตอบ',
  'oifde.operationlog.white_list': 'กฎสีเทาของความรู้',
  'oifde.operationlog.white_list_users': 'หมายเลขสมาชิกในกฎสีเทาของความรู้',
  'oifde.operationlog.sensitive_words': 'คำที่ละเอียดอ่อน',
  'oifde.operationlog.stop_words': 'คำหยุด',
  'oifde.operationlog.navigation_info': 'ข้อมูลการนำทาง',
  'oifde.operationlog.command': 'คำสั่ง',
  'oifde.operationlog.api_key': 'คีย์ API',
  'oifde.operationlog.trace_log': 'การกำหนดค่าการลงบันทึกการติดตาม',
  'oifde.operationlog.succeed': 'สำเร็จ',
  'oifde.operationlog.failed': 'ล้มเหลว',
  'oifde.operationlog.domain': 'โดเมน',
  'oifde.operationlog.service_node': 'โหนดการบริการ',
  'oifde.operationlog.online': 'ออนไลน์',
  'oifde.operationlog.offline': 'ออฟไลน์',
  'oifde.operationlog.flow': 'กระบวนการ',
  'oifde.operationlog.performance_data': 'ข้อมูลประสิทธิภาพ',
  'oifde.operationlog.login': 'ลงชื่อเข้าใช้',
  'oifde.operationlog.logout': 'ลงชื่อออก',
  'oifde.operationlog.change_password': 'เปลี่ยนรหัสผ่าน',
  'oifde.operationlog.reset_password': 'รีเซ็ตรหัสผ่าน',
  'oifde.operationlog.authorize': 'ใบรับรอง',
  'oifde.operationlog.tenant_space': 'พื้นที่ของผู้เช่า',
  'oifde.operationlog.account': 'บัญชี',
  'oifde.operationlog.role': 'บทบาท',
  'oifde.operationlog.password_rule': 'กฎรหัสผ่าน',
  'oifde.operationlog.password_rule_group': 'กลุ่มกฎรหัสผ่าน',
  'oifde.operationlog.user_account': 'บัญชี',
  'oifde.operationlog.user_role': 'บทบาท',
  'oifde.operationlog.knowledge_graph': 'กราฟความรู้',
  'oifde.operationlog.knowledge_graph_synonym': 'คำพ้องกราฟความรู้',
  'oifde.operationlog.acess_code': 'รหัสการเข้าถึง',
  'oifde.operationlog.gray_rule': 'กฎสีเทา',
  'oifde.operationlog.resource_template': 'เทมเพลตทรัพยากร',
  'oifde.operationlog.business_interface': 'อินเทอร์เฟซธุรกิจ',
  'oifde.operationlog.variable_manage': 'การจัดการตัวแปร',
  'oifde.operationlog.flume_log_cfg': 'การกำหนดค่า Flume Log',
  'oifde.operationlog.procedure_db': 'ฐานข้อมูลกระบวนงานที่จัดเก็บไว้',
  'oifde.operationlog.procedure_interface': 'กระบวนงานที่จัดเก็บไว้',
  'oifde.operationlog.complexcellcatalog': 'แค็ตตาล็อกองค์ประกอบแผนภาพคอมโพสิต',
  'oifde.operationlog.complexcell': 'องค์ประกอบแผนภาพคอมโพสิต',
  'oifde.operationlog.lock_account': 'ล็อกบัญชี',
  'oifde.operationlog.unlock_account': 'ปลดล็อกบัญชี',
  'oifde.operationlog.kick_account': 'ล้างเซสชัน',
  'oifde.operationlog.start': 'เริ่ม',
  'oifde.operationlog.stop': 'หยุด',
  'oifde.operationlog.log_open_task': 'งานการเปิดใช้งานการลงบันทึก',
  'oifde.operationlog.activate': 'เปิดใช้งาน',
  'oifde.operationlog.deactivate': 'หยุด',
  'oifde.operationlog.illeagal_status': 'สถานะผิดกฎหมาย',
  'oifde.operationlog.file': 'ไฟล์',
  'oifde.operationlog.trace_log_query': 'การสืบค้นและการติดตามการลงบันทึก',
  'oifde.operationlog.staticcheck': 'การตรวจสอบแบบคงที่',
  'oifde.operationlog.unkonw': 'ไม่รู้',
  'oifde.operationlog.interface_white_list': 'รายการที่เชื่อถือได้',
  'oifde.operationlog.license': 'ใบอนุญาต',
  'oifde.operationlog.test_corpus': 'การทดสอบคลังข้อมูล',
  'oifde.operationlog.test': 'ทดสอบ',
  'oifde.operationlog.refresh': 'รีเฟรช',
  'oifde.operationlog.train': 'ฝึก',
  'oifde.operationlog.trainAll': 'ฝึกทั้งหมด',
  'oifde.operationlog.lable_corpus': 'ติดแท็กคลังข้อมูล',
  'oifde.operationlog.knowledge_graph_schema': 'เค้าร่างความรู้',
  'oifde.operationlog.knowledge_graph_ask_template': 'คำถามเพิ่มเติม',
  'oifde.operationlog.knowledge_graph_entity': 'เอนทิตีพจนานุกรมกราฟ',
  'oifde.operationlog.knowledge_graph_relation': 'ความสัมพันธ์พจนานุกรมกราฟ',
  'oifde.operationlog.knowledge_graph_object_attribute': 'แอตทริบิวต์ความสัมพันธ์พจนานุกรมกราฟ',
  'oifde.operationlog.knowledge_graph_object_entity': 'แอตทริบิวต์เอนทิตีพจนานุกรมกราฟ',
  'oifde.operationlog.knowledge_graph_schema_entity': 'เอนทิตีเค้าร่างความรู้',
  'oifde.operationlog.knowledge_graph_schema_relation': 'ความสัมพันธ์ของเค้าร่างความรู้',
  'oifde.operationlog.knowledge_graph_schema_attribute': 'แอตทริบิวต์เค้าร่างความรู้',
  'oifde.operationlog.knowledge_graph_ask_template_group': 'คำถามเพิ่มเติมเกี่ยวกับกราฟ',
  'oifde.operationlog.knowledge_graph_ask_template_element': 'เทมเพลตคำถามที่พบบ่อยสำหรับคำถามเพิ่มเติมเกี่ยวกับกราฟ',
  'oifde.operationlog.encrypt_Secret': 'เข้ารหัสลับ',
  'oifde.operationlog.flow_trace_log': 'การกำหนดค่าการติดตามการทดสอบกระบวนการ',
  'oifde.operationlog.channel_type': 'ช่อง',
  'oifde.operationlog.response_style': 'สไตล์',
  'oifde.operationlog.resource_template_exce': 'เทมเพลตทรัพยากรยกเว้น',
  'oifde.operationlog.setting': 'จัดเตรียม',
  'oifde.operationlog.quick_release': 'การประกาศด้วยคลิกเดียว',
  'oifde.operationlog.upload': 'ดาวน์โหลด',
  'oifde.operationlog.binding_domain': 'อัปโหลด',
  'oifde.operationlog.binding_faqgroup': 'โดเมน Chatbot',
  'oifde.operationlog.binding_kg': 'กลุ่มคำถามที่พบบ่อยของ Chatbot',
  'oifde.operationlog.binding': 'กราฟ Chatbot',
  'oifde.operationlog.download': 'ผูก',
  'oifde.operationlog.guideflow': 'โฟลว์ตัวช่วยการสร้าง',
  'oifde.operationlog.sensitive_group': 'กลุ่มคำที่ละเอียดอ่อน',
  'oifde.operationlog.recordHistoryLog': 'การลงบันทึกการโต้ตอบ',
  'oifde.operationlog.sentiment_analysis': 'โมเดลการวิเคราะห์ความรู้สึก',
  'oifde.operationlog.use': 'ใช้',
  'oifde.operationlog.unused': 'ยังไม่ได้ใช้',
  'oifde.operationlog.tenantAsrName': 'การกำหนดค่า ASR',
  'oifde.operationlog.tenantAsrTtsName': 'การจัดการ TTS / ASR',
  'oifde.operationlog.MRCPServerManagement': 'การจัดการเซิร์ฟเวอร์',
  'oifde.operationlog.insert': 'แทรก',
  'oifde.operationlog.exclude_entity': 'ไม่รวมหน่วยงาน',
  'oifde.sysConfig.sysParam': 'พารามิเตอร์ของระบบ',
  'oifde.sysConfig.intent_params': 'พารามิเตอร์เจตนา',
  'oifde.sysConfig.code': 'รหัสตัวแปร',
  'oifde.sysConfig.description': 'คำอธิบายตัวแปร',
  'oifde.sysConfig.value': 'ค่าตัวแปร',
  'oifde.sysConfig.update': 'ใช้',
  'oifde.sysConfig.abort': 'ยกเลิก',
  'oifde.sysConfig.updateAll': 'ใช้พารามิเตอร์ทั้งหมด',
  'oifde.sysConfig.restore_defaults': 'คืนกลับสู่ค่าเริ่มต้น',
  'oifde.sysConfig.successful_operation': 'การดำเนินการสำเร็จ',
  'oifde.sysConfig.confirm_apply_intent_param': 'การปรับเปลี่ยนพารามิเตอร์เจตนาจะส่งผลต่อการจดจำเจตนาอย่างมาก คุณแน่ใจว่าต้องการใช้การปรับเปลี่ยนหรือไม่?',
  'oifde.sysConfig.succeed_apply_intent_param': 'ใช้พารามิเตอร์เจตนาสำเร็จ',
  'oifde.sysConfig.failed_apply_intent_param': 'เปลี่ยนค่าของพารามิเตอร์เจตนาล้มเหลว',
  'oifde.sysConfig.confirm_restore_defaults': 'คุณแน่ใจว่าต้องการคืนกลับสู่ค่าเริ่มต้นหรือไม่?',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.NAME': 'โมดูลการประมวลผลล่วงหน้าสำหรับภาษาจีน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.NAME': 'ใช้พินอิน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.DESC': 'ระบุว่าจะแปลงภาษาจีนเป็นพินอินหรือไม่',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.TRUE': 'เปิดใช้งาน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.FALSE': 'ปิดใช้งาน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.NAME': 'ใช้โทน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.DESC': 'ระบุว่าจะใช้โทนในพินอินหรือไม่',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.TRUE': 'เปิดใช้งาน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.FALSE': 'ปิดใช้งาน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.MULTI': 'หลายครั้ง',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.ONCE': 'หนึ่งครั้ง',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.TRUE': 'เปิดใช้งาน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.FALSE': 'ปิดใช้งาน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.TRUE': 'เปิดใช้งาน',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.FALSE': 'ปิดใช้งาน',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NAME': 'โมดูลการสกัดคุณสมบัติ N-Gram',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.NAME': 'ขั้นต่ำ ค่า N',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.DESC': 'ค่า N ขั้นต่ำในอัลกอริทึม N-Gram',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.NAME': 'สูงสุด ค่า N',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.DESC': 'ค่า N สูงสุดในอัลกอริทึม N-Gram',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.NAME': 'ขั้นต่ำ เกณฑ์น้ำหนักคำ',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.DESC': 'เมื่อน้ำหนักคำน้อยกว่าเกณฑ์ให้เพิกเฉย',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.NAME': 'รวบรวมคำเดียวกันหนึ่งครั้งหรือมากกว่า',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.DESC': 'เมื่อมีการแสดงคำเดียวกันในประโยคหลายครั้ง สถิติจะถูกรวบรวมอย่างน้อยหนึ่งครั้ง',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.NAME': 'อัลกอริทึม TSF',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.DESC': 'ระบุว่าจะเปิดใช้อัลกอริทึม TSF หรือไม่',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.NAME': 'การขยายขอบประโยค',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.DESC': 'ระบุว่าจะเพิ่มแท็กก่อนและหลังประโยคเมื่อสกัดคุณสมบัติ N-Gram',
  'oifde.sysConfig.ENERGY_FILTER.NAME': 'โมดูลควบคุมปริมาณเจตนา',
  'oifde.sysConfig.ENERGY_FILTER.MAX.NAME': 'สูงสุด เจตนา',
  'oifde.sysConfig.ENERGY_FILTER.MAX.DESC': 'จำนวนเจตนาสูงสุดที่ส่งคืนในการตัดสินใจขั้นสุดท้าย',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.NAME': 'โมดูลการกรองเจตนาที่ไม่รู้จัก',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.NAME': 'เกณฑ์พลังงาน',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.DESC': 'เมื่อผลรวมของพลังงานเจตนาที่ตรวจพบทั้งหมดน้อยกว่าหรือเท่ากับเกณฑ์แสดงว่าเจตนาเป็นเจตนาที่ไม่รู้จัก',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.NAME': 'โมดูลการจำแนกเจตนา',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.NAME': 'μ',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.DESC': 'ค่า μ ของฟังก์ชันโลจิสติกของตัวจำแนกเจตนา',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.NAME': 'γ',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.DESC': 'ค่า γ ของฟังก์ชันโลจิสติกของตัวจำแนกเจตนา',
  'oifde.sysConfig.INTENT_DECISION_MAKER.NAME': 'โมดูลการตัดสินใจเจตนา',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.NAME': 'ขีดจำกัดบน',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.DESC': 'ขีดจำกัดบนของผู้ตัดสินใจเจตนา หากเกินขีดจำกัดบนแสดงว่าเจตนานั้นเป็นเจตนาที่ทราบ',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.NAME': 'ขีดจำกัดล่าง',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.DESC': 'ขีดจำกัดล่างของผู้ตัดสินใจเจตนา หากค่าต่ำกว่าขีดจำกัดล่างแสดงว่าเจตนาเป็นเจตนาที่ไม่ทราบ',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NAME': 'โมดูลการทำเครื่องหมายช่อง CRF',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.NAME': 'อัตราการเรียนรู้',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.DESC': 'อัตราการเรียนรู้โมเดล CRF',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.NAME': 'จำนวนโหนดต้นไม้',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.DESC': 'จำนวนโหนดในแต่ละต้นไม้ในอัลกอริทึม GBRT',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.NAME': 'ปริมาณต้นไม้',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.DESC': 'จำนวนต้นไม้ในอัลกอริทึม GBRT',
  'oifde.errorCode.100010000000': 'ข้อผิดพลาดของระบบ',
  'oifde.errorCode.100010000002': 'รูปแบบไม่ถูกต้อง',
  'oifde.errorCode.100051005001': 'บันทึกรายการบล็อก / รายการที่เชื่อถือได้ล้มเหลว',
  'oifde.errorCode.100070000001': 'ข้อมูลที่จะนำเข้าเหมือนกับทรัพยากรที่มีอยู่ ซึ่งนำไปสู่ความล้มเหลวในการนำเข้า',
  'oifde.errorCode.100051005002': 'ลบรายการบล็อกหรือรายการที่เชื่อถือได้ล้มเหลว',
  'oifde.errorCode.100051000006': 'นำเข้าล้มเหลว รูปแบบไฟล์ไม่ถูกต้อง',
  'oifde.errorCode.100051005005': 'นำเข้าล้มเหลว ไฟล์มีขนาดใหญ่เกินไป',
  'oifde.errorCode.100051005006': 'หมายเลขการบริการต้องมีอักขระไม่เกิน 64 ตัว',
  'oifde.errorCode.100051005015': 'จำนวนบรรทัดไฟล์ต้องไม่เกิน 5,000 บรรทัด',
  'oifde.errorCode.100051005003': 'นำเข้าล้มเหลว ไม่พบไฟล์',
  'oifde.errorCode.100051005004': 'นำเข้าไฟล์ล้มเหลว ข้อผิดพลาดเกิดขึ้นเมื่อระบบอ่านไฟล์',
  'oifde.errorCode.100051006013': 'นำเข้าล้มเหลว',
  'oifde.errorCode.100051005025': 'จำนวนรวมต้องไม่เกิน 5,000',
  'oifde.errorCode.100051005011': 'เพิ่มหมายเลขบริการล้มเหลว',
  'oifde.fileTask.EMPTY_COMMAND': 'คำสั่งการดำเนินการว่างเปล่า',
  'oifde.fileTask.UNKNOWN_COMMAND': 'คำสั่งการดำเนินการไม่สามารถระบุได้',
  'oifde.fileTask.INVALID_SALT': 'คำขอไม่ถูกต้อง',
  'oifde.fileTask.INVALID_REQUEST': 'คำขอไม่ถูกต้อง',
  'oifde.fileTask.EMPTY_TASK_ID': 'ID งานว่างเปล่า',
  'oifde.fileTask.TASK_NOT_FOUND': 'ไม่พบงาน',
  'oifde.fileTask.SERVER_BUSY': 'ระบบไม่ว่าง',
  'oifde.fileTask.SERVICE_UNAVAILABLE': 'การบริการไม่พร้อมใช้งาน',
  'oifde.fileTask.UPLOAD_EXCEPTION': 'การอัปโหลดหยุดอย่างผิดปกติ',
  'oifde.fileTask.INVALID_ITEM_ENCODING': 'โหมดการเข้ารหัสไม่ถูกต้อง',
  'oifde.fileTask.INVALID_FILE_EXTENSION': 'นามสกุลไฟล์ไม่ถูกต้อง',
  'oifde.fileTask.WRITE_FILE_FAILED': 'บันทึกไฟล์ล้มเหลว',
  'oifde.fileTask.READ_FILE_FAILED': 'อ่านไฟล์ล้มเหลว',
  'oifde.fileTask.INVALID_FILE_PATH': 'เส้นทางไฟล์ไม่ถูกต้อง',
  'oifde.fileTask.FILE_NOT_EXIST': 'ไม่มีไฟล์',
  'oifde.fileTask.FILE_TOO_LARGE': 'ขนาดของไฟล์ใหญ่เกินไป',
  'oifde.fileTask.ENTRY_TOO_LARGE': 'แพ็กเกจบีบอัดประกอบด้วยไฟล์ขนาดใหญ่',
  'oifde.fileTask.TOO_MANY_ENTRIES': 'แพ็กเกจบีบอัดมีไฟล์มากเกินไป',
  'oifde.fileTask.INVALID_ENTRY_PATH': 'เส้นทางไฟล์ในแพ็กเกจบีบอัดผิดปกติ',
  'oifde.fileTask.INVALID_ENTRY_EXTENSION': 'นามสกุลไฟล์ในแพ็กเกจบีบอัดไม่ถูกต้อง',
  'oifde.fileTask.UNZIPPED_TOO_LARGE': 'ขนาดของแพ็กเกจที่ขยายใหญ่เกินไป',
  'oifde.fileTask.UNZIP_FAILED': 'ขยายแพ็กเกจล้มเหลว',
  'oifde.fileTask.DOWNLOAD_EXCEPTION': 'การดาวน์โหลดหยุดอย่างผิดปกติ',
  'oifde.fileTask.UNDEFINED_ERROR': 'เกิดข้อผิดพลาดระหว่างการดำเนินงาน',
  'oifde.fileTask.INVALID_TASK_ARGS': 'พารามิเตอร์งานขาดหายไป',
  'oifde.fileTask.INVALID_TASK_CONTENT': 'ข้อมูลงานไม่ถูกต้อง',
  'oifde.fileTask.GRAPH_IS_ON': 'ไม่สามารถนำเข้ากราฟที่ใช้งานอยู่ได้',
  'oifde.fileTask.INCORRECT_PARAMETERS': 'พารามิเตอร์ไม่ถูกต้อง',
  'oifde.fileTask.KG_NO_SCHEMA': 'กราฟไม่เกี่ยวข้องกับการสร้างโมเดลความรู้',
  'oifde.fileTask.IMPORT_KG_ENTITY_TOO_BIG': 'อนุญาตให้มีเอนทิตีกราฟได้สูงสุด 1 ล้านรายการ',
  'oifde.fileTask.IMPORT_KG_RELATION_TOO_BIG': 'อนุญาตให้มีความสัมพันธ์กราฟได้สูงสุด 100 ล้านรายการ',
  'oifde.fileTask.IMPORT_SCHEMA_TOO_BIG': 'อนุญาตให้มีเอนทิตีแนวคิดสูงสุด 10,000 รายการในการสร้างโมเดลความรู้',
  'oifde.fileTask.IMPORT_SCHEMA_RELATION_TOO_BIG': 'อนุญาตให้มีความสัมพันธ์สูงสุด 10,000 รายการในการสร้างโมเดลความรู้',
  'oifde.importFile.white_list': 'เขียนทับการตั้งค่าที่มีอยู่ทั้งหมด',
  'oifde.importFile.test_case_group': 'เขียนทับการตั้งค่าที่มีอยู่ทั้งหมด',
  'oifde.importFile.knowledge_graph': 'ไฟล์ที่มีชื่อซ้ำกันสามารถเขียนทับได้',
  'oifde.importFile.knowledge_graph_ask_template': 'เขียนทับการตั้งค่าที่มีอยู่ทั้งหมด',
  'oifde.recordHistory.recordHistory': 'ประวัติการโทร',
  'oifde.recordHistory.recordDetail': 'รายละเอียดการโทร',
  'oifde.recordHistory.userSaying': 'ข้อความร้องขอ',
  'oifde.recordHistory.systemReply': 'ข้อความตอบสนอง',
  'oifde.recordHistory.reqTime': 'เวลาร้องขอ',
  'oifde.recordHistory.respTime': 'เวลาตอบสนอง',
  'oifde.recordHistory.callNumber': 'หมายเลขสมาชิก',
  'oifde.recordHistory.callStatus': 'การเฝ้าสังเกตการโทรเข้า',
  'oifde.recordHistory.kgStatus': 'การเฝ้าสังเกตความรู้',
  'oifde.recordHistory.callNumberLength': 'หมายเลขสมาชิกต้องมีอักขระไม่เกิน 64 ตัว',
  'oifde.recordHistory.oneCondition': 'ต้องระบุวันที่หรือหมายเลขโทรศัพท์มือถือ',
  'oifde.recordHistory.respSource': 'แหล่งที่มาการตอบกลับ',
  'oifde.recordHistory.silentSeat': 'เจ้าหน้าที่เงียบ',
  'oifde.recordHistory.bot': 'Chatbot',
  'oifde.recordHistory.customer': 'ลูกค้า',
  'oifde.error.messageHead': 'อินเทอร์เฟซส่วนหน้า -',
  'oifde.error.messageBody': 'ข้อผิดพลาดการร้องขอ รหัสข้อผิดพลาด:',
  'oifde.error.messageTail': 'ตรวจสอบว่าเครือข่ายหรือเซิร์ฟเวอร์เป็นปกติหรือไม่',
  'oifde.testMgr.noSelection': 'ไม่สามารถสร้างเคสทดสอบเนื่องจากไม่ได้เลือกโดเมนหรือกลุ่มคำถามที่พบบ่อย',
  'oifde.testMgr.abortSuccess': 'การขัดจังหวะสำเร็จ',
  'oifde.testMgr.abortFailed': 'การขัดจังหวะล้มเหลว',
  'oifde.testMgr.getStatusWarn': 'ไม่สามารถใช้เคสทดสอบได้เนื่องจากกระบวนการกำลังร้องขอเคสทดสอบ กรุณารอสักครู่...',
  'oifde.testMgr.noDomain': 'ไม่ได้เลือกโดเมน',
  'oifde.testMgr.noFaqGroup': 'ไม่ได้เลือกกลุ่มคำถามที่พบบ่อย',
  'oifde.testMgr.requestFailed': 'เรียกใช้อินเทอร์เฟซสำหรับการสร้างเคสทดสอบโดยอัตโนมัติล้มเหลว',
  'oifde.testMgr.getCorpusTestCase': 'สร้างเคส',
  'oifde.testMgr.abortCorpusTestCase': 'ขัดจังหวะ',
  'oifde.testMgr.domain': 'โดเมน',
  'oifde.testMgr.faqGroup': 'กลุ่มคำถามที่พบบ่อย',
  'oifde.testMgr.getCorpusTestCaseConfig': 'สร้างเคส',
  'oifde.testMgr.allDomain': 'เลือกโดเมนทั้งหมด',
  'oifde.testMgr.allFaqGroup': 'เลือกกลุ่มคำถามที่พบบ่อยทั้งหมด',
  'oifde.testMgr.testMgr': 'การทดสอบความรู้',
  'oifde.testMgr.chatBotTestCaseMgr': 'การทดสอบการโต้ตอบ',
  'oifde.testMgr.botTestCaseMgr': 'จัดการเคสทดสอบ',
  'oifde.testMgr.testCase': 'เคสทดสอบ',
  'oifde.testMgr.case_group': 'กลุ่มเคสทดสอบ',
  'oifde.testMgr.case_amount': 'เคสทดสอบ',
  'oifde.testMgr.case_tested': 'ได้รับการทดสอบ',
  'oifde.testMgr.case_passed': 'ผ่าน',
  'oifde.testMgr.case_failed': 'ล้มเหลว',
  'oifde.testMgr.case_passed_rate': 'อัตราการผ่าน',
  'oifde.testMgr.test_time': 'ทดสอบที่',
  'oifde.testMgr.add_test_case_group': 'เพิ่มกลุ่มเคสทดสอบ',
  'oifde.testMgr.test_case_group_details': 'รายละเอียด',
  'oifde.testMgr.descriptionLen': 'คำอธิบายสามารถมีอักขระได้สูงสุด 1024 ตัว',
  'oifde.testMgr.number': 'อนุญาตเฉพาะตัวเลขเท่านั้น',
  'oifde.testMgr.auto_testing_template': 'ดาวน์โหลดเทมเพลต',
  'oifde.testMgr.execute_test': 'ดําเนินการ',
  'oifde.testMgr.manage': 'จัดการ',
  'oifde.testMgr.case_id': 'ID เคส',
  'oifde.testMgr.userId': 'ID สมาชิก',
  'oifde.testMgr.expected_output': 'ชื่อเจตนาหรือคำสำคัญเอาต์พุตที่คาดหวัง',
  'oifde.testMgr.actual_output': 'เอาต์พุตจริง',
  'oifde.testMgr.actual_output_title': 'เอาต์พุตจริง (ชื่อเจตนาในวงเล็บ)',
  'oifde.testMgr.event': 'เหตุการณ์',
  'oifde.testMgr.note_test': 'ไม่ถูกทดสอบ',
  'oifde.testMgr.expected_output_with_memo': 'ชื่อเจตนาหรือคำสำคัญเอาต์พุตที่คาดหวังคั่นด้วยเครื่องหมายจุลภาค',
  'oifde.testMgr.add_test_case': 'เพิ่มเคสทดสอบ',
  'oifde.testMgr.test_case_details': 'รายละเอียด',
  'oifde.testMgr.execute_all': 'ดำเนินการทั้งหมด',
  'oifde.testMgr.batchExec': 'ดําเนินการ',
  'oifde.testMgr.succeed_save_case': 'บันทึกเคสทดสอบสำเร็จแล้ว',
  'oifde.testMgr.succeed_test_case': 'ดำเนินการเคสทดสอบสำเร็จแล้ว',
  'oifde.testMgr.natureColon': ':',
  'oifde.testMgr.failed_query': 'การสืบค้นล้มเหลว',
  'oifde.testMgr.succeed_save_group': 'บันทึกกลุ่มเคสทดสอบสำเร็จแล้ว',
  'oifde.testMgr.fail_save_data': 'บันทึกกลุ่มเคสทดสอบล้มเหลว',
  'oifde.testMgr.select_prompt': 'เลือกอย่างน้อยหนึ่งการบันทึก',
  'oifde.testMgr.confirm_delete_case_group': 'คุณแน่ใจว่าต้องการลบกลุ่มเคสทดสอบใช่หรือไม่?',
  'oifde.testMgr.failed_delete_group': 'ลบกลุ่มเคสทดสอบล้มเหลว',
  'oifde.testMgr.succeed_delete_group': 'ลบกลุ่มเคสทดสอบสำเร็จแล้ว',
  'oifde.testMgr.confirm_delete_case': 'คุณแน่ใจว่าต้องการลบเคสทดสอบใช่หรือไม่?',
  'oifde.testMgr.succeed_delete_case': 'ลบเคสทดสอบสำเร็จแล้ว',
  'oifde.testMgr.failed_delete_case': 'ลบเคสทดสอบล้มเหลว',
  'oifde.testMgr.import_test_cases': 'นำเข้าเคสทดสอบ',
  'oifde.testMgr.import_test_cases_hint': 'สามารถนำเข้าเคสทดสอบในรูปแบบ TXT ได้สูงสุด 200 รายการ',
  'oifde.testMgr.overwrite_import': 'เขียนทับการตั้งค่าเดียวกันทั้งหมด',
  'oifde.testMgr.failed_import_file': 'การนำเข้าไฟล์ล้มเหลว',
  'oifde.testMgr.success_import': 'นำเข้าไฟล์สำเร็จแล้ว',
  'oifde.hisdialog.startTime_must_earlier_endTime': 'เวลาเริ่มต้นต้องมาก่อนเวลาสิ้นสุด รีเซ็ตเกณฑ์การค้นหา',
  'oifde.hisdialog.direction.UPLOAD': 'ข้อความร้องขอ',
  'oifde.hisdialog.direction.DOWNLOAD': 'ข้อความตอบกลับ',
  'oifde.chatBotTest.rangeFilter': 'กรองการบันทึกที่เลือก',
  'oifde.chatBotTest.botAccessCode': 'รหัสการเข้าถึง Chatbot',
  'oifde.chatBotTest.callingNum': 'หมายเลขการโทร',
  'oifde.chatBotTest.caseTimeRange': 'ช่วงเวลา',
  'oifde.chatBotTest.getChatBotTestCase': 'สร้างเคสการโต้ตอบ',
  'oifde.chatBotTest.getChatBotTestCaseConfig': 'สร้างเคสการโต้ตอบ',
  'oifde.chatBotTest.abortChatBotTestCase': 'ขัดจังหวะการสร้างเคสการโต้ตอบ',
  'oifde.chatBotTest.allAccessCode': 'เลือกรหัสการเข้าถึง chatbot ทั้งหมด',
  'oifde.chatBotTest.deletePrompt': 'กลุ่มเคสทดสอบจะถูกลบอย่างถาวร คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.chatBotTest.deleteTestCase': 'การดําเนินการนี้จะลบกรณีการทดสอบที่เลือกอย่างถาวร คุณแน่ใจหรือว่าต้องการดําเนินการต่อ?',
  'oifde.chatBotTest.checkPrompt': 'เคสทดสอบหลายเคสที่ทำงานพร้อมกันอาจทำให้ระบบช้าลง คุณแน่ใจว่าต้องการดำเนินการต่อหรือไม่?',
  'oifde.chatBotTest.cancleExcute': 'การดำเนินการถูกยกเลิก',
  'oifde.chatBotTest.inputTestCaseGroupName': 'กรอกชื่อกลุ่มเคสทดสอบ',
  'oifde.chatBotTest.doTest': 'ดําเนินการ',
  'oifde.chatBotTest.executeSuccess': 'การดำเนินการสำเร็จแล้ว',
  'oifde.chatBotTest.executeFailed': 'การดำเนินการล้มเหลว',
  'oifde.chatBotTest.downTestReport': 'ดาวน์โหลดรายงานการทดสอบ',
  'oifde.chatBotTest.downAllReport': 'ดาวน์โหลดรายงานทั้งหมด',
  'oifde.chatBotTest.testCaseGroup': 'กลุ่มเคสทดสอบ',
  'oifde.chatBotTest.totalNumber': 'เคสทดสอบ',
  'oifde.chatBotTest.testTimes': 'ได้รับการทดสอบ',
  'oifde.chatBotTest.passTimes': 'ผ่าน',
  'oifde.chatBotTest.failTimes': 'ล้มเหลว',
  'oifde.chatBotTest.passRate': 'อัตราการผ่าน',
  'oifde.chatBotTest.testTime': 'ทดสอบที่',
  'oifde.chatBotTest.status': 'สถานะ',
  'oifde.chatBotTest.status.initial': 'เริ่มการทํางาน',
  'oifde.chatBotTest.status.waiting': 'กําลังรออยู่',
  'oifde.chatBotTest.status.running': 'กําลังทํางานอยู่',
  'oifde.chatBotTest.status.finish': 'เสร็จสิ้นแล้ว',
  'oifde.chatBotTest.status.failed': 'ล้มเหลว',
  'oifde.chatBotTest.status.success': 'สําเร็จ',
  'oifde.chatBotTest.description': 'คำอธิบาย',
  'oifde.chatBotTest.addTestCaseGroup': 'เพิ่มกลุ่มเคสทดสอบ',
  'oifde.chatBotTest.editTestCaseGroup': 'ปรับเปลี่ยนกลุ่มเคสทดสอบ',
  'oifde.chatBotTest.testCaseGroupName': 'ชื่อกลุ่มเคส',
  'oifde.chatBotTest.robotName': 'ชื่อ Chatbot',
  'oifde.chatBotTest.callingId': 'หมายเลขการโทร',
  'oifde.chatBotTest.prompt': 'ข้อมูล',
  'oifde.chatBotTest.promptMessage': 'เลือกอย่างน้อยหนึ่งการบันทึก',
  'oifde.chatBotTest.downloadMessage': 'คุณได้เลือก {0} เร็กคอร์ด คุณแน่ใจหรือว่าต้องการดําเนินการต่อ?',
  'oifde.chatBotTest.downloadAllMessage': 'คุณยังไม่ได้เลือกเร็กคอร์ดที่ต้องการ ส่งออกบันทึกทั้งหมดหรือไม่? คุณยังสามารถยกเลิกการดําเนินการและเลือกเร็กคอร์ดที่ต้องการส่งออกได้',
  'oifde.testCase.name': 'ชื่อเคสทดสอบ',
  'oifde.testCase.status': 'สถานะ',
  'oifde.testCase.caseID': 'ID เคส',
  'oifde.testCase.doAll': 'ดำเนินการทั้งหมด',
  'oifde.testCase.caseName': 'ชื่อเคส',
  'oifde.testCase.executeStatus': 'สถานะ',
  'oifde.testCase.description': 'คำอธิบาย',
  'oifde.testCase.caseDetail': 'รายละเอียดเคส',
  'oifde.testCase.traceLog': 'ติดตามโฟลว์',
  'oifde.testCase.callDetail': 'รายละเอียด',
  'oifde.testCase.addTestCase': 'เพิ่มเคสทดสอบ',
  'oifde.testCase.editTestCase': 'ปรับเปลี่ยนเคสทดสอบ',
  'oifde.testCase.caseDetailMgr': 'จัดการรายละเอียดเคส',
  'oifde.testCase.detailDialog': 'รายละเอียดเคส',
  'oifde.testCase.detail.reqText': 'ข้อความร้องขอ',
  'oifde.testCase.detail.expectedReturn': 'ข้อความตอบสนองที่คาดหวัง',
  'oifde.testCase.detail.channelAssociatedData': 'ข้อมูลที่เกี่ยวข้อง',
  'oifde.testCase.detail.operation': 'การดำเนินการ',
  'oifde.testCase.detail.realResponse': 'ข้อความตอบสนองจริง',
  'oifde.testCase.detail.close': 'ปิด',
  'oifde.rate.accessCode': 'เลือกรหัสการเข้าถึง chatbot',
  'oifde.rate.callCountBoard': 'แผงแสดงสถิติการโทร',
  'oifde.rate.resolveRate': 'อัตราความละเอียดการบริการตนเองสุทธิ',
  'oifde.rate.dayOption': 'ตามวัน',
  'oifde.rate.hourOption': 'ตามชั่วโมง',
  'oifde.rate.monthOption': '30 วันที่แล้ว',
  'oifde.rate.dateSelect': 'เลือกวันที่',
  'oifde.rate.index': 'หมายเลข',
  'oifde.rate.time': 'วันที่และเวลา',
  'oifde.rate.dataDimension': 'เลือกมิติข้อมูล',
  'oifde.rate.accessAmount': 'การโทรที่เชื่อมต่อ',
  'oifde.rate.exitNoConsultCount': 'การโทรถูกตัดการเชื่อมต่อโดยตรง',
  'oifde.rate.callStaffNoConsultCount': 'การโทรดำเนินการโดยเจ้าหน้าที่เท่านั้น',
  'oifde.rate.exitConsultingCount': 'การโทรดำเนินการโดย Chatbot เท่านั้น',
  'oifde.rate.callStaffConsultCount': 'โอนสายจาก Chatbot ไปยังเจ้าหน้าที่',
  'oifde.rate.interfaceTimes': 'เวลาเรียกใช้อินเทอร์เฟซ',
  'oifde.rate.interfaceSuccessTimes': 'เรียกใช้อินเทอร์เฟซสำเร็จ',
  'oifde.rate.successRate': 'อัตราความสำเร็จการเรียกใช้อินเทอร์เฟซ',
  'oifde.rate.autonomyRate': 'อัตราความละเอียดการบริการตนเองสุทธิ',
  'oifde.rate.interactCount': 'รอบการโต้ตอบ',
  'oifde.rate.averageInteractPerCall': 'รอบการโต้ตอบเฉลี่ย',
  'oifde.rate.accessNum': 'การโทรที่เชื่อมต่อ',
  'oifde.rate.dayconditionTips': 'เกณฑ์การค้นหาไม่มีรหัสการเข้าถึง',
  'oifde.rate.hourConditionTips': 'เกณฑ์การค้นหาไม่มีวันที่หรือรหัสการเข้าถึง',
  'oifde.rate.rateForCharts': 'อัตราความละเอียดการบริการตนเองสุทธิ',
  'oifde.rate.interfaceRequestCount': 'เวลาเรียกใช้อินเทอร์เฟซ',
  'oifde.rate.interfaceSuccessCount': 'เวลาเรียกใช้ที่สำเร็จ',
  'oifde.rate.interfaceSuccessRate': 'อัตราความสำเร็จการเรียกใช้',
  'oifde.dashBoard.noData': '--- (ไม่สามารถแสดงข้อมูลที่ถูกลบได้)',
  'oifde.dashBoard.serviceCapabilityDashBoard': 'แผงแสดงความสามารถในการให้บริการ',
  'oifde.dashBoard.knowledgeDashBoard': 'แผงแสดงความรู้',
  'oifde.dashBoard.service.consultCount': 'การตอบกลับทั้งหมด',
  'oifde.dashBoard.service.responseSuccessCount': 'การตอบกลับที่สำเร็จ',
  'oifde.dashBoard.service.unknowCount': 'การตอบกลับที่ไม่รู้จัก',
  'oifde.dashBoard.service.responseRate': 'อัตราการตอบกลับที่สำเร็จ',
  'oifde.dashBoard.service.feedbackCount': 'ผลป้อนกลับทั้งหมด',
  'oifde.dashBoard.service.positiveFeedbackCount': 'ผลป้อนกลับที่พึงพอใจ',
  'oifde.dashBoard.service.positiveFeedbackRate': 'อัตราความพึงพอใจ',
  'oifde.dashBoard.knowledge.dateConditionTips': 'เลือกวันที่',
  'oifde.dashBoard.knowledge.topTenChatBot': 'รหัสการเข้าถึง Chatbot 10 อันดับแรก',
  'oifde.dashBoard.knowledge.accessCode': 'รหัสการเข้าถึง',
  'oifde.dashBoard.knowledge.name': 'ชื่อ',
  'oifde.dashBoard.knowledge.callCount': 'เยี่ยมชม',
  'oifde.dashBoard.knowledge.topTenknowledge': 'ความรู้ที่เป็นประโยชน์ 10 อันดับแรก',
  'oifde.dashBoard.knowledge.intent': 'เจตนา',
  'oifde.dashBoard.knowledge.faq': 'คำถามที่พบบ่อย',
  'oifde.dashBoard.knowledge.hitCount': 'การเปิดดู',
  'oifde.dashBoard.chartView.title': 'อัตราความสำเร็จการตอบกลับ',
  'oifde.dashBoard.chartView.countTile': 'การตอบกลับทั้งหมด',
  'oifde.dashBoard.chartView.rateTile': 'อัตราความสำเร็จการตอบกลับ',
  'oifde.dashBoard.chartView.leftTile': 'การตอบกลับทั้งหมด',
  'oifde.dashBoard.chartView.rightTile': 'อัตราความสำเร็จการตอบกลับ',
  'oifde.dashBoard.graph.title': 'สถิติความรู้ที่ถูกต้อง',
  'oifde.dashBoard.graph.faq': 'คำถามที่พบบ่อย',
  'oifde.dashBoard.graph.intent': 'เจตนา',
  'oifde.dashBoard.graph.kg': 'กราฟ',
  'oifde.dashBoard.graph.talk': 'แชท',
  'oifde.dashBoard.graph.countTitle': 'รวม',
  'oifde.dashBoard.graph.hitTitle': 'การเปิดดู',
  'oifde.dashBoard.graph.hitRateTitle': 'อัตราการเปิดดู',
  'oifde.dashBoard.graph.amount': 'จำนวน',
  'oifde.dashBoard.graph.percent': 'ร้อยละ',
  'oifde.dashBoard.graph.count': 'รวม',
  'oifde.dashBoard.graph.hit': 'การเปิดดู',
  'oifde.dashBoard.graph.hitRate': 'อัตราการเปิดดู',
  'oifde.dashBoard.pie.title': 'การกระจายการเปิดดูความรู้',
  'oifde.dashBoard.pie.intentTitle': 'เจตนา',
  'oifde.dashBoard.pie.kgTitle': 'กราฟ',
  'oifde.dashBoard.pie.faqTitle': 'คำถามที่พบบ่อย',
  'oifde.dashBoard.pie.talkTitle': 'แชท',
  'oifde.dashBoard.pie.intent': 'เจตนา',
  'oifde.dashBoard.pie.kg': 'กราฟ',
  'oifde.dashBoard.pie.faq': 'คำถามที่พบบ่อย',
  'oifde.dashBoard.pie.talk': 'แชท',
  'oifde.chatbot.errorCode.100010000002': 'รูปแบบไม่ถูกต้อง',
  'oifde.chatbot.errorCode.100051000031': 'จำนวน ID อินพุตเกินขีดจำกัดสูงสุด',
  'oifde.chatbot.errorCode.100051000023': 'ลบกลุ่มเคสล้มเหลว',
  'oifde.chatbot.errorCode.100010000000': 'ข้อผิดพลาดของระบบ',
  'oifde.chatbot.errorCode.100010000001': 'ฐานข้อมูลผิดพลาด',
  'oifde.chatbot.errorCode.100010000003': 'ชื่อจะต้องเป็นเอกลักษณ์',
  'oifde.chatbot.errorCode.100010000005': 'พารามิเตอร์อินพุตไม่ถูกต้อง',
  'oifde.chatbot.errorCode.100023000004': 'ตรวจสอบ ID อินพุตล้มเหลว',
  'oifde.chatbot.errorCode.100051000022': 'บันทึกเคสทดสอบล้มเหลว',
  'oifde.chatbot.errorCode.100051000032': 'ลบเคสทดสอบล้มเหลว',
  'oifde.chatbot.errorCode.100051000026': 'ไม่สามารถดำเนินการทดสอบได้เนื่องจากกลุ่มทดสอบที่เลือกมีจำนวนมากเกินไป',
  'oifde.chatbot.errorCode.100051000028': 'ไม่พบผลการทดสอบ',
  'oifde.chatbot.errorCode.100051000109': 'จำนวนเคสทดสอบเกิน 200',
  'oifde.serverMgr.serverType': 'ประเภทเซิร์ฟเวอร์',
  'oifde.serverMgr.mrcpId': 'ID กลุ่ม',
  'oifde.serverMgr.asrServer': 'เซิร์ฟเวอร์ ASR',
  'oifde.serverMgr.ttsServer': 'เซิร์ฟเวอร์ TTS',
  'oifde.serverMgr.confirmDelete': 'คุณแน่ใจว่าต้องการลบข้อมูลใช่หรือไม่',
  'oifde.serverMgr.addServer': 'เพิ่มเซิร์ฟเวอร์',
  'oifde.serverMgr.editServer': 'ปรับเปลี่ยนเซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.asrVendor': 'ผู้ผลิต',
  'oifde.qiAsrServerMgr.asrServerName': 'ชื่อเซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.asrServerAddress': 'ที่อยู่เซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.asrAppKey': 'ชื่อผู้ใช้/AK',
  'oifde.qiAsrServerMgr.fileServerAddress': 'ที่อยู่เซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.folder': 'โฟลเดอร์ปริยาย',
  'oifde.qiAsrServerMgr.description': 'คําอธิบาย',
  'oifde.qiAsrServerMgr.addQiAsrServer': 'เพิ่มการตรวจสอบคุณภาพข้อมูลเซิร์ฟเวอร์ ASR',
  'oifde.qiAsrServerMgr.editQiAsrServer': 'แก้ไขการตรวจสอบคุณภาพข้อมูลเซิร์ฟเวอร์ ASR',
  'oifde.qiAsrServerMgr.asrServerInfo': 'ข้อมูลเซิร์ฟเวอร์ ASR',
  'oifde.qiAsrServerMgr.name': 'ชื่อ',
  'oifde.qiAsrServerMgr.asrServerIP': 'ที่อยู่เซิร์ฟเวอร์ ASR',
  'oifde.qiAsrServerMgr.asrServerPort': 'ASR พอร์ตเซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.asrServerUser': 'ผู้ใช้เซิร์ฟเวอร์ ASR',
  'oifde.qiAsrServerMgr.asrServerPW': 'รหัสผ่านเซิร์ฟเวอร์ ASR',
  'oifde.qiAsrServerMgr.asrServerPWConform': 'ยืนยันรหัสผ่านเซิร์ฟเวอร์ ASR',
  'oifde.qiAsrServerMgr.nextStep': 'ถัดไป',
  'oifde.qiAsrServerMgr.preStep': 'ขั้นก่อนหน้า',
  'oifde.qiAsrServerMgr.fileServerInfo': 'ข้อมูลเซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.fileServerType': 'ชนิดเซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.fileServerIP': 'ที่อยู่เซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.fileServerPort': 'แฟ้มฝั่งเซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.fileServerUser': 'ชื่อผู้ใช้ของเซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.fileServerPW': 'รหัสผ่านเซิร์ฟเวอร์',
  'oifde.qiAsrServerMgr.fileServerPWConfirm': 'ยืนยันรหัสผ่านเซิร์ฟเวอร์ไฟล์',
  'oifde.qiAsrServerMgr.voiceUploadFolder': 'บันทึกไฟล์อัปโหลดพาธ',
  'oifde.qiAsrServerMgr.qiResultFolder': 'เขียนพาธผลลัพธ์',
  'oifde.qiAsrServerMgr.sftp': 'SFTP',
  'oifde.qiAsrServerMgr.passwordError': 'รหัสผ่านที่ป้อน 2 ครั้งไม่ตรงกัน.',
  'oifde.qiAsrServerMgr.ipOrDomainNameError': 'ip หรือโดเมนป้อนข้อมูลผิดพลาด',
  'oifde.qiAsrServerMgr.ipError': 'ip ป้อนข้อมูลผิดพลาด',
  'oifde.qiAsrServerMgr.filePathError': 'รูปแบบพาธผิดพลาด',
  'oifde.qiAsrServerMgr.portTips': 'พอร์ตจะไม่มีเมื่อที่อยู่เซิร์ฟเวอร์ ASR เป็นชื่อโดเมน',
  'oifde.qiAsrServerMgr.asrServerNameError': 'ทําซ้ํากับชื่อเซิร์ฟเวอร์ ASR ปริยายของระบบ',
  'oifde.asrMgr.tenantId': 'ID พื้นที่ผู้เช่า:',
  'oifde.asrMgr.tenantSapce': 'พื้นที่ของผู้เช่า',
  'oifde.asrMgr.asr': 'ผู้จำหน่ายเซิร์ฟเวอร์ ASR',
  'oifde.asrMgr.qiAsrServerName': 'การตรวจสอบคุณภาพเซิร์ฟเวอร์ ASR',
  'oifde.asrMgr.mrcpid': 'ID กลุ่ม ASR',
  'oifde.asrMgr.path': 'เส้นทางไฟล์ ASR Grammar',
  'oifde.asrMgr.slotName': 'สล็อต ASR',
  'oifde.asrMgr.groupID': 'ID กลุ่ม TTS',
  'oifde.asrMgr.addAsr': 'เพิ่ม TTS / ASR',
  'oifde.asrMgr.editAsr': 'ปรับเปลี่ยน TTS / ASR',
  'oifde.asrMgr.selectOne': 'เลือกอย่างน้อยหนึ่งอย่างต่อไปนี้: ผู้จัดหาเซิร์ฟเวอร์ ASR, เซิร์ฟเวอร์ ASR และเซิร์ฟเวอร์ TTS',
  'oifde.asrMgr.TENANT_SPACE_NOT_FOUND': 'ไม่พบพื้นที่ผู้เช่า',
  'oifde.asrMgr.languageTips': 'default บ่งบอกถึงภาษาที่ไม่แยกแยะ',
  'oifde.license.license': 'การจัดการใบอนุญาต',
  'oifde.license.import': 'โหลด / อัปเดต',
  'oifde.license.setlicense': 'มอบหมายใบอนุญาต',
  'oifde.license.queryCurrentLicense': 'สืบค้นการทำงานพร้อมกันและการสะสมในปัจจุบัน',
  'oifde.license.queryHistoryLicense': 'สืบค้นประวัติการทำงานพร้อมกัน',
  'oifde.license.valateDate': 'วันหมดอายุ',
  'oifde.license.totalLicense': 'จำนวนใบอนุญาตทั้งหมด',
  'oifde.license.LIN8OIAPAA01': 'จำนวนผู้ช่วยเจ้าหน้าที่ที่ทำงานพร้อมกัน',
  'oifde.license.LIN8OIAPVA01': 'จำนวนเจ้าหน้าที่เสมือนที่ทำงานพร้อมกัน',
  'oifde.license.LIN8OIAPVA02': 'จำนวนการโทรที่สะสมของเจ้าหน้าที่เสมือน',
  'oifde.license.PERMANENT': 'ถาวร',
  'oifde.license.processTime': 'ระยะเวลาประมวลผล',
  'oifde.license.at_least_one_serviceValue': 'ต้องมีอย่างน้อยหนึ่งค่าที่ไม่ว่างเปล่า',
  'oifde.license.failed_set_license': 'การกำหนดค่าใบอนุญาตล้มเหลว',
  'oifde.license.success_set_license': 'กำหนดค่าใบอนุญาตสำเร็จ',
  'oifde.license.import_template_hint_dat': 'สามารถอัปโหลดไฟล์ในรูปแบบ .dat ได้',
  'oifde.license.import_template_need_dat': 'ไฟล์ที่จะอัปโหลดต้องอยู่ในรูปแบบ .dat',
  'oifde.license.maxHisInfo': 'มูลค่าสูงสุดในอดีต',
  'oifde.license.detailHisInfo': 'รายละเอียดในอดีต',
  'oifde.license.sys': 'ระบบ',
  'oifde.request.timeout': 'คําขออินเทอร์เฟซหมดเวลา',
  'oifde.analysis.validate.time': 'วันที่ต้องใช้',
  'oifde.analysis.validate.accessCode': 'ต้องระบุรหัสการเข้าถึงหุ่นยนต์',
  'oifde.analysis.validate.timeInterval': 'ช่วงเวลาไม่เกิน 7 วัน',
  'oifde.analysis.chart.title': 'สถิติสาขาของโหนด IVR',
  'oifde.analysis.chart.timeOther': 'หมดเวลาหรือกดปุ่มอื่น',
  'oifde.analysis.chart.button': 'กดคีย์ ',
  'oifde.analysis.chart.default': 'สาขาปริยายของระบบ',
  'oifde.analysis.childrenView': 'ดูโหนดย่อย',
  'oifde.service.invoke.breakMinTime.desc': 'หน่วย: วินาที;',
  'oifde.ivrJourney.nodeOverview': 'ภาพรวมโหนด',
  'oifde.ivrJourney.trendAnalysis': 'การวิเคราะห์แนวโน้ม',
  'oifde.ivrJourney.keyDistribution': 'การแจกจ่ายปุ่ม',
  'oifde.ivrJourney.nodeVersionComparison': 'การเปรียบเทียบรุ่นโหนด',
  'oifde.ivrJourney.businessRanking': 'กระดานผู้นําธุรกิจ',
  'oifde.ivrJourney.no': 'การจัดอันดับ',
  'oifde.ivrJourney.nodeName': 'ชื่อโหนด',
  'oifde.ivrJourney.visitsTotal': 'การเข้าชม',
  'oifde.ivrJourney.yoyValue': 'ค่าเทียบเท่า',
  'oifde.ivrJourney.increaseValue': 'ดอกเบี้ย',
  'oifde.ivrJourney.visitorTotal': 'การเข้าชม',
  'oifde.ivrJourney.successRate': 'อัตราความสําเร็จ',
  'oifde.ivrJourney.failedRate': 'อัตราการล้มเหลว',
  'oifde.ivrJourney.hangUpRate': 'อัตราการแขวน',
  'oifde.ivrJourney.journeyNumTrend': 'เยี่ยมชมเทรนด์ดิสรัปชั่น',
  'oifde.ivrJourney.EfficiencyTrend': 'การกระจายแนวโน้มประสิทธิภาพ',
  'oifde.ivrJourney.complexityTrend': 'การกระจายแนวโน้มความซับซ้อน',
  'oifde.ivrJourney.repeatNum': 'ซ้อม',
  'oifde.ivrJourney.directCalls': 'สายตรง',
  'oifde.ivrJourney.nonDirectCalls': 'ไม่ตรง',
  'oifde.ivrJourney.journeyNum': 'เข้าชม',
  'oifde.ivrJourney.hangupNum': 'แขวน',
  'oifde.ivrJourney.successCalls': 'จํานวนความสําเร็จ',
  'oifde.ivrJourney.failCalls': 'จํานวนครั้งของความล้มเหลว',
  'oifde.ivrJourney.directRate': 'อัตราตรง',
  'oifde.ivrJourney.nonDirectRate': 'ไม่ตรง',
  'oifde.ivrJourney.hangupRate': 'อัตราการวางสาย',
  'oifde.ivrJourney.failRate': 'อัตราความล้มเหลว',
  'oifde.ivrJourney.repeatCalls': 'ซ้อม',
  'oifde.ivrJourney.repeatOnceCalls': 'ซ้อมอีกครั้ง',
  'oifde.ivrJourney.repeatTwiceCalls': 'ซ้อมสองครั้ง',
  'oifde.ivrJourney.repeatRate': 'อัตราการซ้อม',
  'oifde.ivrJourney.averageTime': 'ระยะเวลาเฉลี่ยของบริการ',
  'oifde.ivrJourney.averagePathLength': 'ความยาวเส้นทางเฉลี่ย',
  'oifde.ivrJourney.timeRangeCheck30': 'ระยะเวลาต้องไม่เกิน 30 วัน',
  'oifde.ivrJourney.dateTimrRange': 'ช่วงเวลาข้อมูล:',
  'oifde.ivrJourney.total': 'รวมทั้งหมด',
  'oifde.ivrJourney.inflowDistribution': 'การกระจายการไหล',
  'oifde.ivrJourney.outflowDistribution': 'กระจาย',
  'oifde.ivrJourney.totalDistribution': 'การกระจายโดยรวม',
  'oifde.ivrJourney.detailDistribution': 'รายละเอียดการกระจาย',
  'oifde.ivrJourney.outflowDetailDistribution': 'การไหลออกการกระจายโดยละเอียด',
  'oifde.ivrJourney.outflowDetailDistributionAmount': 'การไหลออกรายละเอียดจํานวนเงินที่แจกจ่าย',
  'oifde.ivrJourney.outflowDetailDistributionPercentage': 'การไหลออกรายละเอียดเปอร์เซ็นต์การกระจาย',
  'oifde.ivrJourney.nodeTrafficChart': 'แผนภูมิการสื่อสารของโหนด (จํานวนตัวชี้วัด)',
  'oifde.ivrJourney.indicatorQuantityStatistics': 'สถิติปริมาณตัวชี้วัด',
  'oifde.ivrJourney.indicateName': 'ชื่อตัวชี้วัด',
  'oifde.ivrJourney.alarmContent': 'เนื้อหาก่อนการเตือน',
  'oifde.ivrJourney.alarmTime': 'เวลาเตือนล่วงหน้า',
  'oifde.ivrJourney.createTime': 'เวลาข้อมูลก่อนการเตือน',
  'oifde.ivrJourney.alarmLevel': 'ระดับก่อนการเตือน',
  'oifde.ivrJourney.alarmIndicator': 'อินดิเคเตอร์เตือนล่วงหน้า',
  'oifde.ivrJourney.remindType': 'โหมดเตือนความจํา',
  'oifde.ivrJourney.receivers': 'ผู้รับ',
  'oifde.ivrJourney.sendResult': 'กําลังส่งผลลัพธ์',
  'oifde.ivrJourney.low': 'ต่ํา',
  'oifde.ivrJourney.middle': 'ระดับกลาง',
  'oifde.ivrJourney.high': 'สูง',
  'oifde.ivrJourney.noNeedToSend': 'ไม่จําเป็นต้องส่ง',
  'oifde.ivrJourney.sendFail': 'การส่งล้มเหลว',
  'oifde.ivrJourney.sendSuccess': 'ส่งสําเร็จแล้ว',
  'oifde.ivrJourney.sms': 'SMS',
  'oifde.ivrJourney.email': 'อีเมล์',
  'oifde.ivrJourney.smsAndEmail': 'SMS; อีเมล',
  'oifde.ivrJourney.other': 'อื่นๆ',
  'oifde.ivrJourney.nowData': 'ค่าปัจจุบัน',
  'oifde.ivrJourney.label': 'การแสดงตัว',
  'oifde.ivrJourney.transToManualCalls': 'แปลงปริมาณเทียม',
  'oifde.ivrJourney.transToNextLevelManuCalls': 'ปริมาณของเมนูระดับล่าง',
  'oifde.ivrJourney.timeOutOrPressAnotherKey': 'หมดเวลาหรือกดปุ่มอื่น ๆ',
  'oifde.ivrJourney.buttons': 'ปุ่มพิมพ์',
  'oifde.ivrJourney.defaultBranch': 'สาขาปริยาย',
  'oifde.ivrJourney.count': 'จํานวนครั้ง',
  'oifde.ivrJourney.versionName': 'ชื่อรุ่น',
  'oifde.ivrJourney.compareRange': 'ช่วงความคมชัด',
  'oifde.ivrJourney.pleaseSelectAccessCode': 'โปรดเลือกหุ่นยนต์',
  'oifde.ivrJourney.pleaseSelectAccessCodeFirst': 'โปรดเลือกหุ่นยนต์ก่อน',
  'oifde.ivrJourney.oneDay': '1 วัน',
  'oifde.ivrJourney.threeDay': '3 วัน',
  'oifde.ivrJourney.halfMonth': 'ครึ่งเดือน',
  'oifde.ivrJourney.oneMonth': 'หนึ่งเดือน',
  'oifde.ivrJourney.chooseAccessCode': 'เลือกหุ่นยนต์',
  'oifde.ivrJourney.chooseVersion': 'เลือกรุ่นกระบวนการ',
  'oifde.ivrJourney.releaseDate': 'เวลาโพสต์',
  'oifde.ivrJourney.nodeVersionCompareStatistics': 'สถิติเปรียบเทียบรุ่นโหนด',
  'oifde.ivrJourney.validIVRService': 'บริการ IVR ที่ถูกต้อง',
  'oifde.ivrJourney.transToManualCallsSucc': 'โอนไปยังคู่มือที่ประสบความสําเร็จ',
  'oifde.ivrJourney.selfService': 'บริการตนเอง',
  'oifde.ivrJourney.repeatIncomingCalls': 'สายเรียกเข้าซ้ําแล้วซ้ําอีก',
  'oifde.ivrJourney.returnKeysByErrors': 'การคืนค่าข้อผิดพลาดที่สําคัญ',
  'oifde.ivrJourney.pleaseSelectVersion': 'โปรดเลือกเวอร์ชันกระบวนการ',
  'oifde.ivrJourney.pleaseSelectCompareRange': 'โปรดเลือกเปรียบเทียบช่วง',
  'oifde.ivrJourney.logTime': 'วันที่',
  'oifde.ivrJourney.validIVRCalls': 'ปริมาณบริการ ICR ที่ถูกต้อง',
  'oifde.ivrJourney.IVRCalls': 'จํานวนบริการตนเอง',
  'oifde.ivrJourney.IVRTransManualRate': 'อัตราส่วนของการสื่อสารมนุษย์ (%)',
  'oifde.ivrJourney.IVRRate': 'อัตราบริการ IVR บริสุทธิ์ (%)',
  'oifde.ivrJourney.IVRRateNoSymbol': 'อัตราบริการ IVR บริสุทธิ์',
  'oifde.ivrJourney.transManualSuccCalls': 'แปลงจํานวนความสําเร็จของมนุษย์',
  'oifde.ivrJourney.transManualSuccRate': 'แปลงอัตราความสําเร็จของมนุษย์ (%)',
  'oifde.ivrJourney.avgIVRTime': 'ระยะเวลาการโทรเฉลี่ย (วินาที)',
  'oifde.ivrJourney.avgIVRTimeNoSymbol': 'ระยะเวลาการโทรเฉลี่ย',
  'oifde.ivrJourney.IVRErrorRate': 'ข้อผิดพลาดของระบบ IVR (%)',
  'oifde.ivrJourney.IVRErrorRateNoSymbol': 'ข้อผิดพลาดของระบบ IVR',
  'oifde.ivrJourney.IVRTotalCalls': 'ปริมาณการฉีด IVR',
  'oifde.ivrJourney.IVRSrvTime': 'ระยะเวลารวมของบริการ IVR (วินาที)',
  'oifde.ivrJourney.wrongKeyCalls': 'จํานวนการกลับค่าของปุ่มไม่ถูกต้อง',
  'oifde.ivrJourney.distinctCustomers': 'จํานวนลูกค้า',
  'oifde.ivrJourney.IVRSrvCustomers': 'จํานวนลูกค้า IVR ล้วนๆ',
  'oifde.ivrJourney.callNum': 'ปริมาณการโทร',
  'oifde.ivrJourney.ivrRepeatCallNum': 'IVR โทรซ้ำ',
  'oifde.ivrJourney.resolveRate': 'อัตราความละเอียด IVR ครั้งเดียว',
  'oifde.ivrJourney.serviceRate': 'อัตราค่าบริการที่แท้จริง',
  'oifde.ivrJourney.node': 'โหนด',
  'oifde.ivrJourney.indicatorDesc': 'Indicator Description',
  'oifde.ivrJourney.validIVRServiceDesc': 'จํานวนการโทร IVR ที่การจัดการหรือการสอบถามบริการประสบความสําเร็จเสียงการให้คําปรึกษาด้านบริการถูกเล่นมากกว่าครึ่งหนึ่งหรือ SMS, MMS, WAP Push หรืออีเมลถูกเรียกใช้สําเร็จ',
  'oifde.ivrJourney.transToManualCallsDesc': 'จํานวนสาย IVR ที่พยายามส่งต่อไปยังบริการด้วยตนเอง',
  'oifde.ivrJourney.transToManualCallsSuccDesc': 'จํานวนการโทร IVR ที่โอนไปยังเอเจนต์สําเร็จแล้ว',
  'oifde.ivrJourney.selfServiceDesc': 'จํานวนการโทร IVR ที่ไม่ได้ส่งต่อไปยังบริการด้วยตนเอง',
  'oifde.ivrJourney.repeatIncomingCallsDesc': 'จํานวนลูกค้าที่ใช้หมายเลขโทรเดิมเพื่อโทรสายด่วนบริการลูกค้าซ้ํา ๆ ภายในสองชั่วโมง',
  'oifde.ivrJourney.returnKeysByErrorsDesc': 'ในโหนดการนําทาง หลังจากกดปุ่มไม่ถูกต้อง ตัวเลขที่ส่งคืนโดยปุ่ม (*) ดอกจันจะถูกเลือกตามพร้อมท์',
  'oifde.ivrJourney.indicatorSetting': 'การตั้งค่าตัวบ่งชี้',
  'oifde.ivrJourney.indicatorSettingLengthError': 'เลือกตัวบ่งชี้อย่างน้อย {count} ตัว',
  'oifde.ivrJourney.extendedMetrics': 'ตัวชี้วัดส่วนขยาย',
  'oifde.ivrJourney.optimizeVoiceSequence': 'ปรับลําดับการออกอากาศด้วยเสียงให้เหมาะสม',
  'oifde.ivrJourney.optimizeVoiceDefinition': 'ปรับความคมชัดเสียงให้เหมาะสม',
  'oifde.ivrJourney.pleaseSelectDateRange': 'โปรดเลือกช่วงเวลา',
  'oifde.ivrJourney.reverseFlow': 'ทิศทางการไหลย้อนกลับ',
  'oifde.ivrJourney.currentVoicePlayContent': 'เนื้อหาการออกอากาศเสียงในปัจจุบัน',
  'oifde.ivrJourney.repeatCallsNum': 'จํานวนการฟังซ้ํา',
  'oifde.ivrJourney.optimizeSuggestion': 'แนะนําการปรับให้เหมาะสม',
  'oifde.ivrJourney.repeatCallOptimizeSuggestion': 'โหนดปัจจุบันมีจํานวนการฟังซ้ํามากเกินไป ตรวจสอบว่ามีเนื้อหาเสียงที่อธิบายไม่ชัดหรือเข้าใจยากหรือไม่ ขอแนะนําให้ปรับเนื้อหาเสียงให้เหมาะสมที่สุด',
  'oifde.ivrJourney.operationStatus': 'สถานะการดําเนินงาน',
  'oifde.ivrJourney.poor': 'แย่',
  'oifde.ivrJourney.general': 'ทั่วไป',
  'oifde.ivrJourney.good': 'ดีครับ',
  'oifde.ivrJourney.fluctuationService': 'บริการผันผวน',
  'oifde.ivrJourney.IVRIncomingCallVolumeTrend': 'แนวโน้มปริมาณสายเรียกเข้า IVR',
  'oifde.ivrJourney.efficiencyOverview': 'ภาพรวมประสิทธิภาพ',
  'oifde.ivrJourney.repeatedCallRate': 'อัตราการโทรซ้ํา',
  'oifde.ivrJourney.valueOverview': 'ภาพรวมมูลค่า',
  'oifde.ivrJourney.pureIVRServiceVolume': 'ปริมาณบริการ IVR บริสุทธิ์',
  'oifde.ivrJourney.complexityOverview': 'ภาพรวมความซับซ้อน',
  'oifde.ivrJourney.personTime': 'จํานวนคน (ครั้ง)',
  'oifde.ivrJourney.times': 'ครั้ง',
  'oifde.ivrJourney.noData': 'ไม่มี',
  'oifde.ivrJourney.usageStatistics': 'สถิติการใช้ปุ่มแป้นพิมพ์เสียงปัจจุบัน',
  'oifde.ivrJourney.playingOrder': 'แนะนําลําดับการออกอากาศด้วยเสียง',
  'oifde.ivrJourney.journeySum': 'จํานวนครั้ง',
  'oifde.ivrJourney.forever': 'ใช้ได้ถาวร',
  'oifde.ivrJourney.slectTimeForever': 'ระบุระยะเวลาที่ใช้งานได้',
  'oifde.ivrJourney.alarmIndex': 'ตัวชี้วัดทางสถิติ',
  'oifde.ivrJourney.alarmCondition': 'เงื่อนไขก่อนการเตือน',
  'oifde.ivrJourney.alarmValidity': 'ระยะเวลาการเตือนล่วงหน้า',
  'oifde.ivrJourney.selectAlarmCondition': 'โปรดเลือกเงื่อนไขการแจ้งเตือนที่จะลบ',
  'oifde.ivrJourney.remindObject': 'วัตถุเตือนความจํา',
  'oifde.ivrJourney.transfManualRate': 'อัตราโอนไปยังคู่มือ',
  'oifde.ivrJourney.absoluteValue': 'ค่าสัมบูรณ์',
  'oifde.ivrJourney.highThan': 'สูงกว่า',
  'oifde.ivrJourney.equal': 'เท่ากับ',
  'oifde.ivrJourney.lessThen': 'น้อยกว่า',
  'oifde.ivrJourney.createAlarmConfig': 'การสร้างเงื่อนไขการเตือนภัยล่วงหน้าของบริการ',
  'oifde.ivrJourney.editAlarmConfig': 'การแก้ไขเงื่อนไขการเตือนภัยล่วงหน้าของบริการ',
  'oifde.ivrJourney.selectAccesscodeAndFlow': 'เลือกรุ่นของหุ่นยนต์และกระบวนการ',
  'oifde.ivrJourney.selectAlarmIndex': 'โปรดเลือกตัวบ่งชี้ทางสถิติ',
  'oifde.ivrJourney.fillAlarmCondition': 'กรุณากรอกเงื่อนไขการเตือนภัยล่วงหน้าให้ครบถ้วน',
  'oifde.ivrJourney.selectAlarmLevel': 'โปรดเลือกระดับการเตือน',
  'oifde.ivrJourney.selectTime': 'โปรดเลือกเวลาที่ถูกต้อง',
  'oifde.ivrJourney.selectAlramRemindType': 'เลือกโหมดเตือนความจําอย่างน้อยหนึ่งโหมด',
  'oifde.ivrJourney.selectRemindObject': 'โปรดเลือกวัตถุเตือนความจํา',
  'oifde.ivrJourney.remindObjectMaxNum': 'สามารถเลือกอักขระได้สูงสุด 20 ตัวสําหรับอ็อบเจ็กต์การแจ้งเตือน',
  'oifde.ivrJourney.batchDetele': 'ลบแบทช์',
  'oifde.ivrJourney.selectSmsGateway': 'โปรดเลือกเกตเวย์ SMS',
  'oifde.ivrJourney.selectMailGateway': 'โปรดเลือกเกตเวย์อีเมล',
  'oifde.ivrJourney.indicatorComparisonBarChart': 'แผนภูมิคอลัมน์เปรียบเทียบตัวชี้วัด',
  'oifde.ivrJourney.indicatorComparisonStatistics': 'สถิติเปรียบเทียบตัวชี้วัด',
  'oifde.ivrJourney.beforeRelease': 'ก่อนเผยแพร่',
  'oifde.ivrJourney.afterRelease': 'ก่อนเผยแพร่',
  'oifde.ivrJourney.beforeReleaseRepeatRate': 'อัตราการฟังซ้ําก่อนโพสต์',
  'oifde.ivrJourney.afterReleaseRepeatRate': 'อัตราการฟังซ้ําหลังโพสต์',
  'oifde.ivrJourney.beforeReleaseHangupNum': 'จํานวนการเมานท์ก่อนเผยแพร่',
  'oifde.ivrJourney.afterReleaseHangupNum': 'จํานวนการเมานท์หลังจากเผยแพร่',
  'oifde.headerTitle.flowManagement': 'การจัดการกระบวนการ',
  'oifde.headerTitle.flowConfiguration': 'ปรับแต่งกระบวนการ',
  'oifde.trackmgr.robotsTrack': 'ติดตามหุ่นยนต์',
  'oifde.trackmgr.robotsSource': 'แหล่งที่มา',
  'oifde.maintain.odfsLogOpen': 'ล็อก ODFS เปิดใช้',
  'oifde.maintenance.level': 'ระดับ',
  'oifde.maintenance.startTime': 'เวลาที่มีผลบังคับใช้',
  'oifde.maintenance.duration': 'ระยะเวลา (นาที)',
  'oifde.maintenance.startLog': 'บันทึกการเปิด',
  'oifde.maintenance.stop_success': 'หยุดบันทึกเรียบร้อยแล้ว',
  'oifde.maintenance.start_success': 'เปิดใช้ปูมบันทึกเรียบร้อยแล้ว',
  'oifde.route.transferRecord': 'บันทึกการถ่ายโอนกระบวนการ',
  'oifde.route.audioVideoResource': 'บันทึกการถ่ายโอนกระบวนการ',
  'oifde.serviceInterface.thirdAPILog': 'บันทึกการเรียกใช้อินเทอร์เฟซ',
  'oifde.serviceInterface.statusCode': 'รหัสสถานะ',
  'oifde.serviceInterface.invokingTime': 'เวลาในการโทร',
  'oifde.serviceInterface.callId': 'iifde ID',
  'oifde.serviceInterface.invokingDetails': 'รายละเอียดการโทร',
  'oifde.title.chooseFlow': 'เลือกโฟลว์',
  'oifde.tip.inputFlowName': 'ป้อนชื่อโฟลว์',
  'oifde.error.createfail': 'เพิ่มข้อมูลในเครื่องไม่สำเร็จ',
  'oifde.error.gateway.fail': 'ไม่สามารถซิงโครไนซ์ข้อมูลบนแพลตฟอร์ม CTI กรุณาตรวจสอบการกำหนดค่าเกตเวย์',
  'oifde.error.NMS.fail': 'การซิงโครไนซ์กับ NMS ล้มเหลว',
  'oifde.flowname.maxlength': 'ชื่อหุ่นยนต์ยาวเกินไป',
  'oifde.audioVideoResource.setLanguage': 'การตั้งค่าภาษา',
  'oifde.audioVideoResource.preview': 'แสดงตัวอย่าง',
  'oifde.audioVideoResource.viewReferences': 'ดูการอ้างอิง',
  'oifde.audioVideoResource.cancelUpdate': 'ยกเลิกการปรับปรุง',
  'oifde.audioVideoResource.updateBefore': 'การปรับปรุงก่อน',
  'oifde.audioVideoResource.updateAfter': 'หลังจากการปรับปรุง',
  'oifde.audioVideoResource.templateName': "โปรดป้อนชื่อแม่แบบ",
  'oifde.audio.speedRange': 'ช่วงเริ่มต้น [-200,500]',
  'oifde.route.sysAudio': 'Gerenciamento de voz',
  'oifde.param.maxLength': 'ความยาวพารามิเตอร์ไม่เกิน ',
  'oifde.tip.oldNode': 'ไม่แนะนําให้ใช้องค์ประกอบไดอะแกรมที่เลิกใช้แล้ว',
  'oifde.diagram.element.expired': 'องค์ประกอบหมดอายุ',
  'oifde.diagram.element.basic': 'องค์ประกอบพื้นฐาน',
  'oifde.diagram.element.logic': 'องค์ประกอบตรรกะ',
  'oifde.diagram.element.business': 'องค์ประกอบบริการ',
  'oifde.diagram.element.composite': 'องค์ประกอบคอมโพสิต',
  'oifde.diagram.node.type': 'ประเภทโหนด',
  'oifde.title.chooseGrayRule': 'เลือกกฎสีเทา',
  'oifde.title.chatbot': 'แชทบอท',
  'oifde.audioVideoResource.cancelUpdate.confirm': 'ยืนยันการยกเลิกการปรับปรุงหรือไม่',
  'oifde.audioVideoResource.cancelUpdate.success': 'ยกเลิกการอัปเดตความสําเร็จ',
  'oifde.audioVideoResource.cancelUpdate.fail': 'ยกเลิกการอัปเดตล้มเหลว',
  'oifde.audioVideoResource.upload.tips': 'เคล็ดลับ:อัปโหลดไฟล์เดิมซ้ํา ๆ เปลี่ยนชื่อไฟล์หรือเปลี่ยนชื่อไฟล์.',
  'oifde.robotReply.displayName': 'หุ่นยนต์ตอบกลับ',
  'oifde.robotReply.replyWay': 'ตอบทาง',
  'oifde.robotReply.replyWayBreve': 'ทาง',
  'oifde.robotReply.replyResourceBreve': 'ทรัพยากร',
  'oifde.robotReply.replyVariableBreve': 'ตัวแปร',
  'oifde.robotReply.replyTemplateBreve': 'ภาษาไทย',
  'oifde.robotReply.replModeBreve': 'โหมด',
  'oifde.robotReply.replyTemplate': 'ตอบกลับแม่แบบ',
  'oifde.robotReply.selectReplyTemplate': 'เลือกแม่แบบ',
  'oifde.robotReply.replyVariable': 'ตอบ ตัวแปร',
  'oifde.robotReply.replyResource': 'ตอบทรัพยากร',
  'oifde.robotReply.selectReplyResource': 'เลือกทรัพยากร',
  'oifde.robotReply.replMode': 'โหมดตอบกลับ',
  'oifde.validation.repeats.varName': 'ชื่อตัวแปรที่ซ้ํากัน',
  'oifde.procedure.mysqlUrlRule': 'รูปแบบ URL ไม่ถูกต้อง, การอ้างอิงรูปแบบ URL: jdbc:mysql://ip:port/db_name? prop1=value1&prop2=value2',
  'oifde.procedure.oracleUrlRule': 'รูปแบบ URL ไม่ถูกต้อง, การอ้างอิงรูปแบบ URL: jdbc:oracle:thin:{\'@\'}ip:port:SID หรือ jdbc:oracle:thin:{\'@\'}/ip:port/service',
  'oifde.procedure.gaussUrlRule': 'รูปแบบ URL ไม่ถูกต้อง การอ้างอิงรูปแบบ URL: jdbc:zenith:{\'@\'}ip:port[? คีย์=value[&key=value]..]',
  'oifde.chatBotTest.testCaseGroupName.maxLen': 'ชื่อกลุ่มกรณีต้องมีความยาวไม่เกิน 128 ตัวอักษร',
  'oifde.chatBotTest.testCaseName.maxLen': 'ชื่อกรณีต้องมีความยาวไม่เกิน 128 ตัวอักษร',
  'oifde.chatBotTest.createCase.maxLen': 'จํานวนกรณีทดสอบที่เลือกต้องไม่เกิน 100 กรณี',
  'oifde.procedure.selectProcedure': 'เลือกขั้นตอนที่เก็บไว้',
  'oifde.procedure.resultProcess': 'การประมวลผลผลลัพธ์',
  'oifde.robotReply.title.chooseTemplate': 'เลือกเทมเพลตการตอบกลับ',
  'oifde.robotReply.title.chooseResource': 'เลือกทรัพยากรการตอบกลับ',
  'oifde.element.varAssign': 'การกําหนดตัวแปร',
  'oifde.promptType.title': 'ชนิดพร้อมท์',
  'oifde.interfaceInvoke.header': 'ส่วนหัวข้อความอินเทอร์เฟซ',
  'oifde.interfaceInvoke.body': 'ส่วนต่อประสาน',
  'oifde.interfaceInvoke.queryParam': 'พารามิเตอร์การสืบค้นอินเทอร์เฟซ',
  'oifde.interfaceInvoke.method.return': 'วิธีการส่งกลับค่า',
  'oifde.unit.millisecond': 'มิลลิวินาที',
  'oifde.title.transData': 'การได้รับข้อมูลที่เกี่ยวข้อง',
  'oifde.procedure.db.dbName': 'ชื่อฐานข้อมูล',
  'oifde.procedure.procedureDbName': 'กระบวนการจัดเก็บข้อมูลชื่อฐานข้อมูล',
  'oifde.procedure.dbNameWarning':'ชื่อฐานข้อมูลรองรับเฉพาะตัวอักษรตัวเลขจีนวรรคขีดขีดและเครื่องหมายคําพูดภาษาอังกฤษ',
  'oifde.procedure.dbNameError':'ชื่อฐานข้อมูลกระบวนการจัดเก็บข้อมูลไม่สามารถเป็นสตริงว่างหรือความยาวต้องไม่เกิน 64 บิต',
  'oifde.procedure.actions.deleteOnlyOneDb':'การลบล้มเหลวไม่สามารถลบข้อมูลการเชื่อมต่อฐานข้อมูลทั้งหมด',
  'oifde.serviceInterface.actions.deleteDbInfo':'การกระทํานี้จะลบข้อมูลฐานข้อมูลที่เลือก ดําเนินการต่อหรือไม่',
  'oifde.procedure.actions.deleteDBFailed':'การลบล้มเหลวการเชื่อมต่อฐานข้อมูลถูกอ้างอิงโดยกระบวนการจัดเก็บข้อมูล {0} ไม่สามารถลบได้โปรดตรวจสอบ',
  'oifde.procedure.actions.deleteDBFailed2':'การลบล้มเหลว การเชื่อมต่อฐานข้อมูลนี้ถูกอ้างอิงโดย {0} พื้นที่จัดเก็บข้อมูลทั้งหมด {1} รายการ และไม่สามารถลบได้ โปรดตรวจสอบ',
  'oifde.procedure.actions.deleteFailed':'การลบล้มเหลว',
  'oifde.procedure.actions.createDBFailed':'การสร้างล้มเหลว ฐานข้อมูลมีจํานวนจํากัดสูงสุด',
  'oifde.robotReply.title.addResource': 'เพิ่มทรัพยากรการตอบกลับ',
  'oifde.sysMgr.dataClean.flowIvrSaveFlagDesc': 'การจัดเรียงกระบวนการ IVR จะเปิดการแสดงตัวที่บันทึกไว้ตามเวลาหรือไม่',
  'oifde.sysMgr.dataClean.flowIvrSaveIntervalDesc': 'ช่วงเวลาการบันทึกเวลาการเรียงลําดับกระบวนการ IVR',
  'oifde.mxgraph.saveDraft': 'บันทึกแบบร่าง',
  'ccivr.nms.conditionService.expression.placeholder':'โปรดป้อนพจนานุกรมเงื่อนไข',
  'oifde.common.validation.appSecretLenCheck': 'ความยาวคีย์ที่ใช้ร่วมกันต้องอยู่ระหว่าง 8 ถึง 20',
  'oifde.serviceInterface.selfDefinedInterface': 'ปรับแต่งส่วนติดต่อ',
  'oifde.serviceInterface.innerInterface': 'อินเทอร์เฟซในตัว',
  'oifde.diagram.error.basicEnd.length': 'ห้ามเกิน 4096 บิต!',
  'oifde.common.detail': 'รายละเอียด',
  'oifde.serviceInterface.innerInterfaceDetails': 'รายละเอียดอินเทอร์เฟซในตัว',
  'oifde.serviceInterface.signBody': 'ลายเซ็น',
  'oifde.serviceInterface.signBodyDetail.no': 'ไม่ใช่',
  'oifde.serviceInterface.signBodyDetail.yes': 'ใช่',
  'oifde.serviceInterface.signDescription': 'ลายเซ็นของ Sign ถูกส่งไปยังบุคคลที่สามเป็นพารามิเตอร์อินเทอร์เฟซ ต้องมีการตรวจสอบลายเซ็นของ Sign ภายนอก',
  'oifde.serviceInterface.appSecret': 'แชร์คีย์',
  'oifde.serviceInterface.inputAppSecret': 'โปรดป้อนคีย์ที่ใช้ร่วมกัน',
  'oifde.template.defaultValueValidate.ttsVolumeValueDesc': 'ช่วงต้นทาง [0,100]',
  'oifde.tableData.template': 'ดาวน์โหลดเทมเพลตข้อมูล',
  'oifde.tableData.download.template': 'ดาวน์โหลดแม่แบบ',
  'oifde.tableData.import.max': 'นําเข้าข้อมูลได้ถึง 10,000 รายการ',
  'oifde.tableData.import.tableName': 'ชื่อตารางข้อมูล',
  'oifde.tableData.diagram.elementName': 'ตารางข้อมูล',
  'oifde.tableData.diagram.searchCriteria': 'เกณฑ์การค้นหา',
  'oifde.tableData.diagram.searchPage': 'ค้นหาหน้า',
  'oifde.tableData.diagram.searchResult': 'ผลลัพธ์การค้นหา',
  'oifde.tableData.diagram.returnRecords': 'บันทึกการส่งคืน',
  'oifde.tableData.diagram.total': 'รวมทั้งหมด',
  'oifde.workday.workDayName': 'ปฏิทินงานชื่อบริษัท',
  'oifde.workday.workDayDesc': 'คำอธิบาย',
  'oifde.workday.workWeekDay': 'การเลือกสัปดาห์',
  'oifde.workday.servicePeriod': 'ระยะเวลาการให้บริการ',
  'oifde.workday.specificNonServiceDay': 'ระบุวันที่ไม่ใช้บริการ',
  'oifde.workday.specificServiceDay': 'วันที่บริการที่ระบุ',
  'oifde.workday.servicePeriodDesc': 'ระยะเวลาการให้บริการ กําหนดค่าระเบียน 1 ถึง 10 รายการ',
  'oifde.workday.specificNonServiceTip': 'หากมีการกําหนดค่าวันที่ไม่ใช่บริการที่ระบุ วันที่ไม่ใช่บริการจะถือเป็นวันที่ไม่ใช่วันทํางานที่มีลําดับความสําคัญสูงสุด',
  'oifde.workday.specificServiceTip': 'หากวันที่ของบริการที่ระบุที่กําหนดค่าไว้ไม่พร้อมใช้งานในการเลือกสัปดาห์ วันที่ของบริการจะถือเป็นวันทํางาน',
  'oifde.workday.name': 'ชื่อบริษัท',
  'oifde.workday.time': 'ช่วงเวลา',
  'oifde.workday.date': 'วันที่',
  'oifde.workday.addWorkDayTitle': 'เพิ่มปฏิทินการทํางาน',
  'oifde.workday.editWorkDayTitle': 'แก้ไขปฏิทินการทํางาน',
  'oifde.workday.required': 'จำเป็น',
  'oifde.workday.updateTime': 'แก้ไขแล้ว',
  'oifde.workday.operation': 'การดําเนินงาน',
  'oifde.workday.actions.edit': 'แก้ไข',
  'oifde.workday.actions.delete': 'ลบ',
  'oifde.workday.actions.prompt': 'ข้อความพร้อมรับ',
  'oifde.workday.actions.confirm': 'ใช่',
  'oifde.workday.actions.cancel': 'ไม่ใช่',
  'oifde.workday.actions.ok': 'ตกลง',
  'oifde.workday.actions.deletePrompt': 'การดําเนินการนี้จะลบปฏิทินการทํางานอย่างถาวร คุณแน่ใจหรือว่าต้องการดําเนินการต่อ?',
  'oifde.workday.actions.cancelDelete': 'การลบยกเลิกแล้ว',
  'oifde.workday.actions.deleteSuccess': 'การปรับเปลี่ยนสำเร็จแล้ว',
  'oifde.workday.actions.updateSuccess': 'การสร้างสำเร็จแล้ว',
  'oifde.workday.actions.createSuccess': 'การลบสำเร็จแล้ว',
  'oifde.workday.actions.missWorkDayName': 'ชื่อปฏิทินงานไม่สามารถเว้นว่างไว้ได้',
  'oifde.workday.actions.missWorkWeekDay': 'การเลือกสัปดาห์ไม่สามารถเว้นว่างไว้ได้',
  'oifde.workday.actions.minServicePeriod': 'ต้องกําหนดค่าเร็กคอร์ดอย่างน้อยหนึ่งรายการสําหรับระยะเวลาบริการ',
  'oifde.workday.actions.missPeriodName': 'ชื่อช่วงเวลาบริการไม่สามารถเว้นว่างไว้ได้',
  'oifde.workday.actions.missPeriodTime': 'ระยะเวลาการให้บริการ เวลาไม่สามารถเว้นว่างได้',
  'oifde.workday.actions.missNonServiceName': 'ชื่อวันที่ไม่ใช่บริการที่ระบุไม่สามารถเว้นว่างได้',
  'oifde.workday.actions.missNonServiceDate': 'วันที่ที่ไม่ใช่บริการที่ระบุไม่สามารถเว้นว่างได้',
  'oifde.workday.actions.missServiceName': 'ชื่อวันที่บริการที่ระบุไม่สามารถเว้นว่างได้',
  'oifde.workday.actions.missServiceDate': 'ต้องไม่เว้นว่างวันที่บริการที่ระบุ',
  'oifde.workday.tip.overServicePeriod': 'จํานวนระเบียนระยะเวลาการให้บริการต้องไม่เกิน 10',
  'oifde.workday.tip.overNonServiceDate': 'จํานวนของระเบียนวันที่ไม่ใช้บริการที่ระบุต้องไม่เกิน 50',
  'oifde.workday.tip.overServiceDate': 'จํานวนระเบียนวันที่บริการที่ระบุต้องไม่เกิน 50',
  'oifde.workday.tip.overWorkDayCount': 'จํานวนระเบียนปฏิทินการทํางานต้องไม่เกิน 1,000 รายการ',
  'oifde.workday.actions.nameError': 'ชื่อทั้งหมดได้รับอนุญาตเฉพาะตัวอักษรจีน, ตัวอักษรภาษาอังกฤษ, ตัวเลข, ช่องว่าง, ขีดเส้นใต้ (_), ยัติภังค์ (-) และเครื่องหมายอัญประกาศเดี่ยว (\').',
  'oifde.tableData.diagram.addCriteria': 'เพิ่มกลุ่ม',
  'oifde.tableData.diagram.logic': 'ตรรกะ',
  'oifde.diagram.message.recoverData': 'เรียกคืนข้อมูล',
  'oifde.diagram.message.recoverDataDesc': 'ต้องการแสดงผืนผ้าใบโดยใช้ข้อมูลกระบวนการที่บันทึกไว้ชั่วคราวครั้งสุดท้ายหรือไม่',
  'oifde.button.openVariable': 'Abrir Variável >',
  'oifde.button.closeVariable': 'เก็บตัวแปร <',
  'oifde.varGlobal.builtIn': 'ตัวแปรทั่วไป (ในตัว)',
  'oifde.varGlobal.custom': 'ตัวแปรทั่วไป',
  'oifde.route.workDay': 'ปฏิทินงาน',
  'oifde.dataTable.tableName': 'ชื่อตาราง',
  'oifde.dataTable.tableId': 'รหัสตาราง',
  'oifde.dataTable.tableField': 'ฟิลด์ตาราง',
  'oifde.dataTable.fieldId': 'หมายเลขฟิลด์',
  'oifde.dataTable.fieldName': 'ชื่อฟิลด์',
  'oifde.dataTable.fieldType': 'ประเภทฟิลด์',
  'oifde.dataTable.fieldLength': 'ความยาวฟิลด์',
  'oifde.dataTable.defaultValue': 'ค่าปริยาย',
  'oifde.dataTable.deleteTips': 'การดําเนินการนี้จะลบแผ่นข้อมูลและข้อมูลในแผ่นข้อมูลอย่างถาวร ดําเนินการต่อหรือไม่',
  'oifde.dataTable.deleteBatchTips': 'ขณะนี้ยังไม่เลือกแผ่นข้อมูลที่จะลบ',
  'oifde.dataTable.editDataTable': 'แก้ไขแผ่นข้อมูล',
  'oifde.dataTable.addDataTable': 'เพิ่มแผ่นข้อมูล',
  'oifde.dataTable.editDataTableField': 'แก้ไขฟิลด์ตาราง',
  'oifde.dataTable.addDataTableField': 'เพิ่มฟิลด์ตาราง',
  'oifde.dataTable.validation.tableId': 'อนุญาตเฉพาะตัวอักษร ตัวเลข ขีดขีดล่างเท่านั้น ไม่สามารถเริ่มด้วยตัวเลขได้',
  'oifde.dataTable.validation.presetField': 'ID, TENANTID, CREATETIME, MODIFYTIME เป็นฟิลด์ที่เตรียมไว้และตัวพิมพ์เล็กไม่ไว กรุณาอย่าใช้',
  'oifde.dataTable.fieldsMaxSize': 'ฟิลด์ตารางต้องไม่เกิน 20 ฟิลด์',
  'oifde.dataTable.fieldsNotEmpty': 'ฟิลด์ตารางไม่สามารถเว้นว่างได้',
  'oifde.dataTable.exceedMaxSize': 'จํานวนแผ่นข้อมูลต้องไม่เกิน 40 แผ่น',
  'oifde.dataTable.saveAndInsert': 'บันทึกและเพิ่มข้อมูล',
  'oifde.service.table.dialog.title': 'ข้อมูล',
  'oifde.service.table.dialog.selectField': 'เลือกฟิลด์',
  'oifde.service.table.dialog.inputValue': 'ค่าฟิลด์อินพุต',
  'oifde.service.table.dialog.addRecord': 'เพิ่มข้อมูล',
  'oifde.service.table.dialog.editRecord': 'แก้ไขข้อมูล',
  'oifde.service.table.dialog.integer': 'อนุญาตเฉพาะจํานวนเต็มเท่านั้น',
  'oifde.service.table.dialog.string': 'ความยาวสูงสุดของค่าประเภทสตริงคือ 10000',
  'oifde.service.table.dialog.oneday': 'วันสุดท้าย',
  'oifde.service.table.dialog.threeday': 'วันที่ 3 ล่าสุด',
  'oifde.service.table.dialog.sevenday': '7 วันสุดท้าย',
  'oifde.service.table.dialog.custom': 'กําหนดเอง',
  'oifde.service.table.dialog.batchdelete': 'ยืนยันการลบแบทช์',
  'oifde.service.table.dialog.delete': 'ยืนยันการลบ',
  'oifde.dataTable.criteria': 'เกณฑ์',
  'oifde.dataTable.diagram.fieldName': 'ชื่อฟิลด์',
  'oifde.dataTable.diagram.fieldValue': 'ค่าฟิลด์',
  'oifde.service.table.import.max.data': 'นําเข้าข้อมูลมากกว่า 10,000',
  'oifde.service.table.import.file.empty': 'เนื้อหาของไฟล์ที่อ่านแล้วว่างเปล่า',
  'oifde.service.table.import.file.read': 'การแยกแฟ้มล้มเหลว',
  'oifde.service.table.import.excute.max': 'มีงานนําเข้าที่กําลังดําเนินการอยู่ โปรดรอให้การนําเข้าครั้งสุดท้ายเสร็จสมบูรณ์',
  'oifde.service.table.import.field.error': 'ไม่มีฟิลด์ ',
  'oifde.service.table.import.string.max': 'ความยาวค่าของประเภทสตริงและมากกว่า 10,000',
  'oifde.service.table.import.fieldType.error': 'ชนิดฟิลด์และค่าไม่ตรงกัน',
  'oifde.common.validation.flowSaveIntervalRange': 'ช่วงเวลาการบันทึกเวลาในการจัดเรียงกระบวนการ IVR คือจํานวนเต็ม ต้องไม่เกิน {0} นาที ไม่เกิน {1} นาที',
  'oifde.sysMgr.dataClean.editFlowSaveFlag': 'แก้ไขกระบวนการ IVR จัดเรียงข้อมูลประจําตัวตามเวลา',
  'oifde.sysMgr.dataClean.editFlowSaveInterval': 'แก้ไขช่วงเวลาการบันทึกเวลาการเรียงลําดับกระบวนการ IVR',
  'oifde.title.chooseGreyRule': 'เลือกกฎสีเทา',
  'oifde.conditionService.param.tips': 'นิพจน์เงื่อนไขมีค่าคงที่สตริงที่ต้องเพิ่มอักขระเพื่อให้ทํางานได้อย่างถูกต้อง',
  'oifde.common.btn.batch.operation': 'การทํางานของแบทช์',
  'oifde.char.invalid': 'อักขระไม่ถูกต้อง',
  'oifde.service.table.dialog.nodatedelete': 'ยังไม่มีการเลือกข้อมูลที่จะลบ',
  'oifde.service.table.dialog.exceedMaxSize': 'สามารถเพิ่มข้อมูลได้สูงสุด 10000 รายการ',
  'oifde.validate.validChar': 'ช่วงอักขระที่อนุญาต: ',
  'oifde.variable.rightList.tips': 'ตัวแปรที่แสดงทางด้านขวาสามารถลากไปยังกล่องข้อความตัวแปร',
  'oifde.service.table.import.field.length.error': 'ความยาวฟิลด์เกินขีดจํากัด',
  'oifde.screenfull.cancel': 'ออกจากแบบเต็มหน้าจอ',
  'oifde.variable.sys.callMediaType': 'ชนิดของสื่อของ',
  'oifde.variable.sys.systemDate': 'วันที่ของระบบ(YYYYMMDD)',
  'oifde.variable.sys.systemMonth': 'เดือนของระบบ(YYYYMM)',
  'oifde.variable.sys.currentTime': 'เวลาปัจจุบันของระบบ(yyyyMMddHHmmss)',
  'oifde.variable.sys.systemTime': 'เวลาปัจจุบันของระบบ(HHmmss)',
  'oifde.variable.sys.timeStamp': 'ประทับเวลา ประเภทสตริง วันที่ 01 มกราคม 1970 เวลา 00:00 น. เวลา 00:00 น. ตามเวลากรีนิช (08:00 น. เวลา 08:00 น. ตามเวลาปักกิ่ง) เป็นจํานวนวินาทีรวมในปัจจุบัน',
  'oifde.variable.sys.createTime': 'เวลาเริ่มต้นของ',
  'oifde.variable.sys.callingNumber': 'หมายเลขลูกค้า เมื่อคลิกไปยังศูนย์ติดต่อระบบคลาวด์ ศูนย์ติดต่อระบบคลาวด์จะระบุหมายเลขโทรเข้าที่ด้านข้างของลูกค้า ประเภทสตริง หมายเลขนี้ถูกเข้ารหัสโดยกระบวนการ IVR',
  'oifde.variable.sys.calledNumber': 'รหัสการเข้าถึงกระบวนการของระบบ ประเภทสตริง หมายเลขนี้ถูกนําเข้ามาโดยกระบวนการ IVR และสําหรับรหัสการเข้าถึงกระบวนการ คุณสามารถเข้าสู่ระบบเพื่อตรวจสอบสถานะ WAS ของแพลตฟอร์ม CTI ได้',
  'oifde.variable.sys.realCalledNumber': 'รหัสการเข้าถึงซึ่งเรียกกระบวนการนี้เรียกว่ารหัสการเข้าถึงที่กําหนดค่าไว้ในการกําหนดค่า ซึ่งก็คือหมายเลขฮอตไลน์ที่เรียกเข้าในกระบวนการนี้',
  'oifde.variable.sys.callId': 'การแสดงตัววาระงาน ODFS, ประเภทสตริง การแสดงตัวนี้ถูกนําเข้ามาโดยกระบวนการ IVR ซึ่งก็คือ IVRREQUEST.call_id',
  'oifde.variable.sys.ctiCallid': 'callid ของ CTI ที่แท้จริงที่เข้ามาในกระบวนการ IVR คือพารามิเตอร์การร้องขอ IVRREQUEST.cti_callid',
  'oifde.variable.sys.tenantId': 'ID ผู้เช่าปัจจุบัน',
  'oifde.variable.sys.language': 'ภาษาของลูกค้าปัจจุบัน',
  'oifde.variable.ivrRequest.ctiCallid': 'Callid ของ CTI ที่นําเข้าจาก Huawei IVR จริง ค่านี้ว่างสําหรับฉากอื่น ๆ ไม่เกิน 32 บิต',
  'oifde.variable.ivrRequest.input': 'ผลการโต้ตอบสนทนา',
  'oifde.variable.ivrRequest.IACmd': 'การจับคู่อย่างชาญฉลาดและคําสั่งอัจฉริยะกรอกข้อมูลเฉพาะ',
  'oifde.variable.ivrRequest.TextSource': 'แหล่งที่มาของข้อความที่ร้องขอ',
  'oifde.variable.ivrRequest.transinData': 'พารามิเตอร์ของแพกเกจที่นําเข้ามาโดยผู้เชื่อมต่อกับ OIAP เช่น พารามิเตอร์ของแพกเกจที่นําเข้ามาโดย IVR',
  'oifde.variable.ivrRequest.beginPlay': 'เวลาเริ่มการออกเสียง',
  'oifde.variable.ivrRequest.endPlay': 'เวลาสิ้นสุดการออกเสียง',
  'oifde.variable.ivrRequest.callId': 'ธงของ เท่านั้นที่เหมือนกันกับ userid ให้โพรเซสใช้',
  'oifde.variable.ivrRequest.interIdx': 'จํานวนการโต้ตอบ จํานวนการโต้ตอบที่บันทึกไว้ด้านข้างของ ODFS',
  'oifde.variable.ivrRequest.feedback': 'ความพึงพอใจของข้อเสนอแนะ: 1 (ความพึงพอใจ); 0 (ไม่พอใจ) จํานวนเต็ม',
  'oifde.variable.ivrRequest.feedbackContent': 'สาเหตุของความไม่พอใจของคําติชม (หากคําติชมพอใจคือว่างเปล่า)',
  'oifde.variable.ivrRequest.channelType': 'ประเภทช่องทางการเข้าถึงของลูกค้า ตัวอย่างเช่น WEB, INSTAGRAM, TELEGRAM, WECHAT, FACEBOOK, TWITTER, EMAIL, LINE, WHATSAPP, 5G, SMS',
  'oifde.variable.ivrRequest.ieValue': 'ส่วนหัวของข้อความที่ได้รับเมื่อเข้าถึงช่องทางอีเมล',
  'oifde.variable.toc.ChatBot': 'พารามิเตอร์บิตของสล็อตที่ถูกปรับแต่งโดยเจตนา',
  'oifde.variable.toc.ChatBotIntentCode': 'การจดจําเสียงความตั้งใจที่จะส่งกลับรหัสเจตนา',
  'oifde.variable.toc.ChatBotRespContent': 'ข้อความตอบกลับที่ส่งคืนโดยเจตนาที่ตรงกับการจดจําเสียง รวมถึงการตอบกลับที่กําหนดค่าไว้ในช่องสัญญาณและการตอบสนอง',
  'oifde.variable.toc.ChatBotScenarioName': 'ชื่อของแม่แบบเจตนาที่ตรงกับการจดจําเสียงหลังจากประมวลผลการจดจําเสียง',
  'oifde.variable.toc.ChatBotResponse': 'ระบุทุกอย่างในอินเทอร์เฟซ',
  'oifde.datatable.open.type': 'ส่งออกโปรดเปิดโดยใช้รูปแบบ UTF-8',
  'oifde.datatable.dateValue.tip': 'ประทับเวลาเป็นมิลลิวินาที ป้อนจํานวนเต็มยาวหรือค่าอักขระหรือตัวแปร ',
  'oifde.datatable.intList.tip': 'ตัวอย่าง: [1, 2, 3] ',
  'oifde.datatable.stringList.tip': 'ตัวอย่าง: ["a", "b", "c"] ',
  'oifde.expression.expr_number_varOrValue': 'ป้อนค่าหรือตัวแปรของชนิดตัวเลข ',
  'oifde.expression.expr_list_varOrValue': 'ป้อนค่าหรือตัวแปรของชนิดรายการ ',
  'oifde.service.table.import.index': 'บรรทัดที่ {0}',
  'oifde.flow.rule.noRule': 'ไม่พบกฎสีเทา ตรวจสอบว่ากฎถูกลบหรือไม่',
  'oifde.procedure.databaseConnection': 'การเชื่อมต่อฐานข้อมูล',
  'oifde.cell.viewRefrenceInfo': 'ข้อมูลอ้างอิง',
  'oifde.cell.viewRefrenceInfo.flow': 'การอ้างอิงกระบวนการ',
  'oifde.cell.viewRefrenceInfo.complexcell': 'การอ้างอิงกระบวนการ',
  'oifde.accessCode.select.max': 'เลือก {0} เร็กคอร์ดแล้ว สามารถเลือกเร็กคอร์ดได้สูงสุด {1} รายการ',
  'oifde.mxgraph.deleteDraft': 'ลบแบบร่าง',
  'oifde.mxgraph.deleteDraft.confirm': 'ลบฉบับร่างหรือไม่',
  'oifde.mxgraph.deleteDraft.noDraftExist': 'ไม่มีข้อมูลร่าง',
  'oifde.resource.needApproval': 'ทรัพยากรการตอบกลับถูกสร้างขึ้นสําเร็จและสามารถใช้งานได้หลังจากได้รับการอนุมัติจากผู้ดูแลระบบเท่านั้น',
  'oifde.common.validation.accessCode.description': 'ไม่อนุญาตให้แบ่งบรรทัดและอักขระพิเศษต่อไปนี้: "[]\'&()=;<>,',
  'oifde.tableData.import.tableNameBreve': 'ชื่อตาราง',
  'oifde.flow.actions.publish.existDraft': 'กระบวนการปัจจุบันมีข้อมูลแบบร่างอยู่ โปรดแก้ไขหรือละเว้นการโพสต์แบบร่างโดยตรง',
  'oifde.export.finish.success': 'ส่งออกเรียบร้อยแล้ว',
  'oifde.input.tips.varAndConst': 'ป้อนตัวแปรหรือค่าคงที่ ใช้เครื่องหมายอัญประกาศก่อนและหลังสตริง',
  'oifde.export.keyPressRobot.title': 'ส่งออกระเบียน IVR ทั่วไป',
  'oifde.export.password': 'บีบอัดรหัสผ่าน',
  'oifde.export.password.placeholder': 'โปรดป้อนรหัสผ่านการบีบอัด',
  'oifde.export.password.error.tips': 'ป้อนรหัสผ่านสําหรับสร้างไฟล์บีบอัด 8-12 หลัก ต้องมีตัวเลข ตัวอักษร และอักขระพิเศษ ยกเว้น /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.password.error.message': 'ต้องมีตัวเลข ตัวอักษร อักขระพิเศษ ยกเว้น /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.task.status': 'สถานะงาน',
  'oifde.export.task.fail': 'การส่งออกล้มเหลว',
  'oifde.export.businessInterface.title': 'การส่งออกระเบียนอินเทอร์เฟซแบบกําหนดเอง',
  'oifde.export.variable.title': 'การส่งออกระเบียนตัวแปรที่กําหนดเอง',
  'oifde.export.complexcell.title': 'ส่งออกบันทึกองค์ประกอบไดอะแกรมคอมโพสิต',
  'oifde.export.accessCode.title': 'การส่งออกบันทึกหุ่นยนต์อัจฉริยะ',
  'oifde.export.intelligentIVRFlow.title': 'การส่งออกระเบียนกระบวนการ',
  'oifde.export.intelligentIVRFlow.button': 'การไหลของการส่งออก',
  'oifde.route.2dDigitalHumanImg': 'มนุษย์เสมือน',
  'oifde.route.add2dDigitalHumanImg': 'สร้างมนุษย์เสมือน',
  'oifde.digitalHuman.outputSetting': 'การตั้งค่าเอาต์พุต',
  'oifde.digitalHuman.standingPosition': 'การวางตําแหน่ง',
  'oifde.digitalHuman.stationTemplate': 'แม่แบบการวางตําแหน่ง',
  'oifde.digitalHuman.preciseAdjustment': 'ปรับค่าได้อย่างแม่นยํา',
  'oifde.digitalHuman.left': 'ซ้าย',
  'oifde.digitalHuman.center': 'ศูนย์กลาง',
  'oifde.digitalHuman.right': 'ถูกแล้ว',
  'oifde.digitalHuman.imageProportion': 'อัตราส่วนของภาพ',
  'oifde.exportdata.message.overData': 'ส่งออกเกินขีดจํากัดสูงสุด 10,000 โปรดเลือกเงื่อนไขการค้นหาอีกครั้ง',
  'oifde.flow.title.publishFlow': 'โฟลว์การปลดปล่อย',
  'oifde.flow.title.upgrade': 'โฟลว์การอัปเกรด',
  'oifde.flow.action.editInfo': 'แก้ไขข้อมูล',
  'oifde.flow.title.editInfo': 'การแก้ไขข้อมูลโฟลว์พื้นฐาน',
  'oifde.robot.title.editInfo': 'การแก้ไขข้อมูลแชทบอทพื้นฐาน',
  'oifde.input.placeholder.searchName': 'ชื่อของการค้นหา',
  'oifde.avatar.tenantId': 'ID ผู้เช่า',
  'oifde.avatar.tenantSpace': 'พื้นที่เช่า',
  'oifde.avatar.avatarName': 'รูปโปรไฟล์',
  'oifde.avatar.avatarCode': 'รหัสบทบาท',
  'oifde.avatar.headerImageUrl': 'โพสรูปภาพ',
  'oifde.avatar.serverAddress': 'ที่อยู่เซิร์ฟเวอร์',
  'oifde.avatar.avatarAppKey': 'ชื่อผู้ใช้/AK ของเซิร์ฟเวอร์',
  'oifde.avatar.create': 'สร้างบริการมนุษย์เสมือน',
  'oifde.avatar.update': 'แก้ไขบริการมนุษย์เสมือน',
  'oifde.avatar.headerImage': 'รูปโปรไฟล์',
  'oifde.avatar.poseImage': 'โพสรูปภาพ',
  'oifde.avatar.actionList': 'รายการการกระทํา',
  'oifde.avatar.form.message': 'ข้อมูลมนุษย์เสมือนจริง 2 มิติ',
  'oifde.avatar.service.message': 'ข้อมูลเซิร์ฟเวอร์',
  'oifde.avatar.serverPort':'พอร์ตเซิร์ฟเวอร์',
  'oifde.avatar.userName':'ชื่อผู้ใช้ของเซิร์ฟเวอร์',
  'oifde.avatar.pwd':'รหัสผ่านเซิร์ฟเวอร์',
  'oifde.avatar.checkPwd': 'ยืนยันรหัสผ่านเซิร์ฟเวอร์',
  'oifde.avatar.resolution': 'ความละเอียด',
  'oifde.avatar.imgRule': 'รองรับเฉพาะรูปแบบ JPG, PNG และ JPEG เท่านั้น ขนาดไฟล์ต้องไม่เกิน 2 MB',
  'oifde.avatar.action_1': 'การทักทาย',
  'oifde.avatar.action_2': 'ยกมือไปข้างหน้า',
  'oifde.avatar.action_3': 'พยักหน้า',
  'oifde.avatar.action_4': 'จับมือ',
  'oifde.avatar.action_5': 'กางมือซ้ายออก',
  'oifde.avatar.action_6': 'แผ่มือขวาออกไป',
  'oifde.avatar.selectTitle': 'เลือกรูปอวตาร',
  'oifde.digitalHuman.imgLimit': 'รองรับเฉพาะไฟล์ภาพ JPG, PNG และ JPEG เท่านั้น ขนาดไฟล์ต้องไม่เกิน 2 MB',
  'oifde.digitalHuman.imageProportion0-5': '0.5',
  'oifde.digitalHuman.imageProportion0-75': '0.75',
  'oifde.digitalHuman.imageProportion1': '1',
  'oifde.digitalHuman.canvasNoSupport': 'เบราว์เซอร์ปัจจุบันไม่รองรับผืนผ้าใบ อัปเกรดหรือเปลี่ยนเบราว์เซอร์ก่อน',
  'oifde.digitalHuman.noRoleData': 'ไม่มีข้อมูลบทบาท ติดต่อเจ้าหน้าที่ฝ่ายปฏิบัติการเพื่อเพิ่มข้อมูลบทบาท',
  'oifde.digitalHuman.sizeError': 'ความละเอียดของภาพไม่ถูกต้อง อัปโหลดภาพพื้นหลังในรูปแบบ {0} x {1}',
  'oifde.title.add.complexCell': 'เมตาคอมโพสิตใหม่',
  'oifde.title.add.complexCellCatalog': 'เมตาไดเร็กทอรีคอมโพสิตใหม่',
  'oifde.title.edit.complexCellCatalog': 'แก้ไขเมตาแค็ตตาล็อกแบบผสม',
  'oifde.title.add.rule': 'กฎใหม่',
  'oifde.title.edit.rule': 'แก้ไขกฎ',
  'oifde.ivrJourney.pleaseSelectData': 'โปรดเลือกข้อมูล',
  'oifde.digitalHuman.needRole': 'เลือกบทบาท',
  'oifde.digitalHuman.actions.deletePrompt': 'การดําเนินการนี้จะลบมนุษย์เสมือนอย่างถาวร ดําเนินการต่อหรือไม่',
  'oifde.avatar.pleaseSelectData': 'ข้อมูลที่แก้ไขแล้วจะไม่ถูกบันทึกหลังจากปิด คุณแน่ใจหรือไม่ว่าต้องการดําเนินการต่อ',
  'oifde.digitalHuman.actions.savePrompt': 'ปฏิบัติการนี้จะอัปเดตมนุษย์เสมือน ดําเนินการต่อหรือไม่',
  'oifde.avatar.validation.ttsSoundSpeed': 'ป้อนทศนิยมหนึ่งตําแหน่งระหว่าง 0.5 ถึง 1.5',
  'oifde.avatar.validation.scale': 'ป้อนทศนิยมหนึ่งตําแหน่งระหว่าง 0.5 ถึง 1.0',
  'oifde.digitalHuman.coordinate': 'ประสานงาน',
  'oifde.digitalHuman.dimensions': 'มิติข้อมูล',
  'oifde.digitalHuman.imgTypeError': 'ประเภทไม่ถูกต้อง รองรับเฉพาะรูปแบบ JPG, PNG และ JPEG เท่านั้น',
  'oifde.expression.expr_var_flow': 'ป้อนตัวแปรโพรเซสที่ขึ้นต้นด้วย FLOW',
  'oifde.expression.expr_string_or_var': 'ป้อนค่าของประเภทตัวแปรหรือสตริงตัวอักษร ใช้เครื่องหมายอัญประกาศคู่ก่อนและหลังสตริง',
  'oifde.common.actions.deleteConfig': 'การดําเนินการนี้จะลบการตั้งค่าอย่างถาวร คุณแน่ใจหรือไม่ว่าต้องการดําเนินการต่อ',
  'oifde.expression.numberCharStartWith0': 'ใช้เครื่องหมายอัญประกาศคู่ก่อนและหลังสตริงตัวเลขที่ขึ้นต้นด้วย 0 เช่น 05 ต้องป้อนเป็น "05" มิฉะนั้น ค่าจะถูกพิจารณาเป็น 5',
}
