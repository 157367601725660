import i18n from '@/lang'
import { getVdnTimeZoneTime } from '@/views/cms/utils/timeUtils'
import AiccElMessage from '@/utils/el-message'

const $t = i18n.global.t

export function checkCallNo(rule, value, callback) {
  //校验除去@以及.的非法字符
  var reg = /^[^!#$%^&*()+=\\[\]{}|\\:;<>?\\/~]*$/
  if (value == '' || (reg.test(value) && value.length <= 64)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('cms.calllink.msg.numberInvalid')))
  }
}

export function checkCallerNo(rule, value, callback) {
  if (value && value.length > 64) {
    callback(new Error($t('cms.failure.callerNo.maxLength.error')))
  }
  var regEn = /['"\/<>\\]/gi
  if (value && regEn.test(value)) {
    //校验不通过
    callback(new Error($t('ccmessaging.chat.channelconfig.checkmessage')))
  } else {
    callback()
  }
}

export function checkCalleeNo(rule, value, callback) {
  var reg = /^\d{1,25}$/
  if (value == '' || reg.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('cms.calllink.msg.numberInvalid')))
  }
}

export function checkStartAndEndTime(rule, value, callback) {
  var preDateTime = new Date().getTime() - 24 * 60 * 60 * 1000
  var preZeroTimeZoneTime = getVdnTimeZoneTime(preDateTime)
  var preZeroTimeZoneTimeStr = new Date(preZeroTimeZoneTime).toLocaleString()
  var diff = 0
  if (value[0] instanceof Date) {
    diff = value[0].getTime() - preZeroTimeZoneTime
  } else {
    diff = value[0].$d.getTime() - preZeroTimeZoneTime
  }
  if (diff < 0) {
    callback(new Error($t('cms.report.message.nomorethanerror') + preZeroTimeZoneTimeStr))
  }
  var afterDateTime = new Date().getTime() + 24 * 60 * 60 * 1000
  var afterZeroTimeZoneTime = getVdnTimeZoneTime(afterDateTime) - 1000
  var afterZeroTimeZoneTimeStr = new Date(afterZeroTimeZoneTime).toLocaleString()
  var diffEnd = 0
  if (value[1] instanceof Date) {
    diffEnd = afterZeroTimeZoneTime - value[1].getTime()
  } else {
    diffEnd = afterZeroTimeZoneTime - value[1].$d.getTime()
  }
  if (diffEnd < 0) {
    callback(new Error($t('cms.calllink.msg.dateTooLate') + afterZeroTimeZoneTimeStr))
  }
  callback()
}

// ccdis账户值校验
export const validateCcdisAuthAccountValue = (rule, value, callback) => {
  if (value !== '') {
    if (value < 0 || value > 100) {
      callback(
        new Error(
          $t('ccnotification.notification.notificationRoute.retryNumLimitRule')
            .replaceAll('0', 1)
            .replaceAll('3', 100)
        )
      )
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// uap端口校验
export const validateUapPortValue = (rule, value, callback) => {
  if (value !== '') {
    if (value < 0 || value > 65535) {
      callback(
        new Error(
          $t('ccnotification.notification.notificationRoute.retryNumLimitRule')
            .replaceAll('0', 1)
            .replaceAll('3', 65535)
        )
      )
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 校验0或正整数
export const validateNumber = (rule, value, callback) => {
  let numberReg = /^[0-9]*$/
  if (value !== '') {
    if (!numberReg.test(value)) {
      callback(new Error($t('oifde.common.validation.digits')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 小数校验
export const validateDecimals = (rule, value, callback) => {
  let numberReg = /^((([^0][0-9]+|0)\.([0-9]{1,23}))$)|^(([1-9]+)\.([0-9]{1,23})$)/
  if (value !== '') {
    if (numberReg.test(value)) {
      callback(new Error($t('cms.report.field.number')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 呼入流水号校验
export const checkCallId = (rule, value, callback) => {
  let numberReg = /^[0-9-]{1,25}$/
  if (value !== '') {
    if (!numberReg.test(value)) {
      callback(new Error($t('cms.calldetail.msg.callIdInvalid')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const checkStartTime = (rule, value, callback) => {
  let diff = 0
  let secondsPerDay = 86400000
  if (window.localStorage.getItem('isIndependentDeployment') === 'true') {
    let endTime = value[1]
    let startTime = value[0]
    diff = new Date(endTime).getTime() - new Date(startTime).getTime()
    if (diff > secondsPerDay) {
      callback(new Error($t('cms.report.message.timeRangeCheck')))
    } else {
      callback()
    }
  } else {
    let endTime = value[1]
    let startTime = value[0]
    diff = new Date(endTime).getTime() - new Date(startTime).getTime()
    if (diff > secondsPerDay * 5) {
      callback(new Error($t('cms.report.message.timeRangeCheckSaas')))
    } else {
      callback()
    }
  }
}

export const checkEndTime = (rule, value, callback) => {
  let endYear = new Date().getFullYear()
  let endMonth = new Date().getMonth() + 1
  let endDay = new Date().getDate()
  let endStr = endYear + '-' + endMonth + '-' + endDay + ' 23:59:59'
  let diff = new Date(endStr).getTime() - new Date(value[1]).getTime()
  if (diff < 0) {
    callback(new Error($t('cms.calldetail.msg.timeTooLate') + endStr))
  } else {
    callback()
  }
}

export const endTimeCheck = (rule, value, callback) => {
  if (!(value === null)) {
    var startTime = value[0]
    var endTime = value[1]

    if (
      (!(startTime === null) && endTime === null) ||
      (startTime === null && !(endTime === null))
    ) {
      callback(new Error($t('cms.report.message.startOrEndTimeNoExist')))
    }
    if (!(startTime === null) && !(endTime === null)) {
      var endMillSecond = new Date(endTime).getTime()
      if (endMillSecond > new Date().getTime()) {
        callback(new Error($t('cms.report.message.nowTimeCheck')))
      }
      var startMillSecond = new Date(startTime).getTime()
      if (startMillSecond > endMillSecond) {
        callback(new Error($t('cms.report.message.startTimeLessThanEndTime')))
      }
      var day7 = 7 * 24 * 60 * 60 * 1000
      if (endMillSecond - startMillSecond > day7) {
        callback(new Error($t('cms.report.message.timeCheckError')))
      }
    }
    callback()
  }
  callback()
}

export const timeCheck = (rule, value, callback) => {
  let startTime = value[0]
  if (!(startTime instanceof Date)) {
    startTime = new Date(startTime)
  }
  let endTime = value[1]
  if (!(endTime instanceof Date)) {
    endTime = new Date(endTime)
  }

  let startMonth = startTime.getMonth() + 1
  let endMonth = endTime.getMonth() + 1

  if (endMonth !== startMonth) {
    callback(new Error($t('cms.report.message.monthRangeCheck')))
  }
  let startYear = startTime.getFullYear()
  let endYear = new Date().getFullYear()
  if (endYear - startYear > 1) {
    callback(new Error($t('cms.report.message.maxGarpMonth')))
  }

  let nowMonth = new Date().getMonth() + 1
  if (endYear - startYear === 1 && nowMonth >= startMonth) {
    callback(new Error($t('cms.report.message.maxGarpMonth')))
  }
  callback()
}

export const recordPlaybackTimeCheck = (rule, value, callback) => {
  let startTime = value[0]
  if (!(startTime instanceof Date)) {
    startTime = startTime.$d
  }
  let startMonth = startTime.getMonth() + 1
  let endTime = value[1]
  if (!(endTime instanceof Date)) {
    endTime = endTime.$d
  }
  let endMonth = endTime.getMonth() + 1
  if (endMonth !== startMonth) {
    callback(new Error($t('cms.report.message.monthRangeCheck')))
  }
  let startYear = startTime.getFullYear()
  let endYear = endTime.getFullYear()
  if (endYear - startYear >= 1) {
    callback(new Error($t('cms.report.message.monthRangeCheck')))
  }
  callback()
}

// 校验整数
export const checkPositiveInteger = (rule, value, callback) => {
  let reg = /^\d+$/
  if (value === '' || reg.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('cms.input.format.invalid')))
  }
}

export const releaseCauseValidate = (rule, value, callback) => {
  let reg = /[~#$%&*()=+{}\[\];'"|!]/
  if (value === '' || !reg.test(value)) {
    callback()
    return
  }
  callback(new Error($t('cms.errormessage.inputspecialchar')))
}

// 业务帐号校验
export const accountCodeValidate = (rule, value, callback) => {
  let reg = /^[a-zA-Z0-9_\-@\\.]+$/
  if (value === '' || reg.test(value)) {
    callback()
    return
  }
  callback(new Error($t('cms.normal.text.validate')))
}

// 坐席姓名校验
export const accountNameValidate = (rule, value, callback) => {
  let reg = /[~#$%&*()/=+{}\[\];'"|!,]/
  if (value === '' || !reg.test(value)) {
    callback()
    return
  }
  callback(new Error($t('cms.errormessage.inputspecialchar')))
}

// 来电原因姓名校验
export const callReasonNameValidate = (rule, value, callback) => {
  let reg = /[~!@#$%&*()/=+{};:",<>?\[\]]/
  if (value === '' || !reg.test(value)) {
    callback()
    return
  }
  callback(new Error($t('ccm.callreason.input.specialStrValidateFailed')))
}

// 校验正整数(不包含0)
export const checkPositiveNumber = (rule, value, callback) => {
  let reg = /^[1-9]\d*$/
  if (value === '' || reg.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('cms.fullscreen.message.digitsCheck')))
  }
}

export const startAndEndTimeCheck = (rule, value, callback) => {
  if (value) {
    var startTime = value[0]
    var endTime = value[1]

    if (
      (!(startTime === null) && endTime === null) ||
      (startTime === null && !(endTime === null))
    ) {
      callback(new Error($t('cms.report.message.startOrEndTimeNoExist')))
    }
    if (!(startTime === null) && !(endTime === null)) {
      var startMillSecond = new Date(startTime).getTime()
      var endMillSecond = new Date(endTime).getTime()
      if (startMillSecond > new Date().getTime()) {
        callback(new Error($t('cms.report.message.nowTimeCheck')))
      }
      if (startMillSecond > endMillSecond) {
        callback(new Error($t('cms.report.message.startTimeLessThanEndTime')))
      }
    }
    callback()
  }
  callback()
}

// 校验两个时间是否是同一天
export const sameDayCheck = (rule, value, callback) => {
  if (value) {
    let startTime = value[0]
    let endTime = value[1]
    if (
      startTime.getFullYear() === endTime.getFullYear() &&
      startTime.getMonth() === endTime.getMonth() &&
      startTime.getDate() === endTime.getDate()
    ) {
      callback()
    } else {
      callback(new Error($t('cms.incoming.record.not.sameday')))
    }
  } else {
    callback()
  }
}

export const isInSevenDay = (rule, value, callback) => {
  if (value) {
    let startTime = value[0]
    const eT = new Date(new Date().setHours(0, 0, 0)).getTime()
    const sT = new Date(startTime.setHours(0, 0, 0)).getTime()
    if (sT + 7 * 24 * 60 * 60 * 1000 >= eT) {
      callback()
    } else {
      callback(new Error($t('cms.requiredcallback.message.allowTimeRange')))
    }
  } else {
    callback()
  }
}

export const causeCodeValidate = (rule, value, callback) => {
  if (value !== null) {
    if (value < 0 || value > 45) {
      callback(new Error($t('cms.incoming.releasecause.causecode.tip')))
    }
    callback()
  }
  callback()
}

export function downError(resp) {
  let returnMessage = resp.cause || ''
  let returncode = returnMessage.substring(
    returnMessage.indexOf('!') + 1,
    returnMessage.indexOf(':')
  )
  if (returncode == '80071002002-1') {
    // 下载过于频繁，请稍后再试
    AiccElMessage.error($t('cms.voiceinspection.message.frequentDownload'), $t('aicc.error'), {
      confirmButtonText: $t('aicc.ok')
    })
  } else if (returncode == '80071002002-2') {
    // 参数不合法
    AiccElMessage.error($t('cms.voiceinspection.message.invalidParam'), $t('aicc.error'), {
      confirmButtonText: $t('aicc.ok')
    })
  } else if (returncode == '80071002002-3') {
    // 质检关系不合法
    AiccElMessage.error($t('cms.voiceinspection.message.invalidQcRelation'), $t('aicc.error'), {
      confirmButtonText: $t('aicc.ok')
    })
  } else if (returncode == '80071002002-4') {
    // 没找到匹配的录音记录
    AiccElMessage.error($t('cms.voiceinspection.message.noRecordFound'), $t('aicc.error'), {
      confirmButtonText: $t('aicc.ok')
    })
  } else if (returncode == '80071002002-5') {
    // sftp服务器信息未配置
    AiccElMessage.error($t('cms.voiceinspection.message.noSftpFound'), $t('aicc.error'), {
      confirmButtonText: $t('aicc.ok')
    })
  } else if (returncode == '80071002002-6') {
    // 下载失败
    AiccElMessage.error($t('cms.voiceinspection.message.downloadFailed'), $t('aicc.error'), {
      confirmButtonText: $t('aicc.ok')
    })
  } else if (returncode == '80071002002-7') {
    // 导出过于频繁，请稍后再试
    AiccElMessage.error($t('cms.voiceinspection.message.frequentExport'), $t('aicc.error'), {
      confirmButtonText: $t('aicc.ok')
    })
  } else if (returncode == '80071002002-8') {
    // 导出失败
    AiccElMessage.error($t('cms.report.message.exportFailed'), $t('aicc.error'), {
      confirmButtonText: $t('aicc.ok')
    })
  } else if (returncode == '80071002002-9') {
    let maxNum = returnMessage.substring(
      returnMessage.lastIndexOf(' ') + 1,
      returnMessage.indexOf('.')
    )
    // 导出数据超过1万条
    AiccElMessage.error(
      $t('cms.report.message.voicedataOverError').replace('0', maxNum),
      $t('aicc.error'),
      {
        confirmButtonText: $t('aicc.ok')
      }
    )
  } else if (returncode == '80071002002-10') {
    // 查询数据为空
    AiccElMessage.error($t('cms.export.message.nocount'), $t('aicc.error'), {
      confirmButtonText: $t('aicc.ok')
    })
  }
}

export function checkSysParamItemName(rule, value, callback) {
  if (value && value.length > 64) {
    callback(new Error($t('cms.failure.callerNo.maxLength.error')))
  }
  const regEn = /['"\/\\]/gi
  if (value && regEn.test(value)) {
    //校验不通过
    callback(new Error($t('ccmessaging.chat.channelconfig.checkmessage')))
  } else {
    callback()
  }
}

export function passwordValidate(rule, value, callback) {
  if (value === '········') {
    callback()
  }
  let regUpper = /[A-Z]/
  let regLower = /[a-z]/
  let regNum = /[0-9]/
  let regSpecial = /[~!@#$%&*()-/_=+{};:'",<.>?\[\]]/
  let complex = 0
  if (regLower.test(value)) {
    ++complex
  }
  if (regUpper.test(value)) {
    ++complex
  }
  if (regNum.test(value)) {
    ++complex
  }
  if (regSpecial.test(value)) {
    ++complex
  }
  if (complex < 3) {
    callback(new Error($t('cms.callcenterinstanceslist.message.passValidateFailed')))
  } else {
    callback()
  }
}

export function cmsPwdLengthRange(rule, value, callback) {
  if (value === '········') {
    callback()
  }
  if (value.length < 16 || value.length > 32) {
    callback(new Error($t('cms.callcenterinstanceslist.message.wasPsdLengthRangeValidateFailed')))
  } else {
    callback()
  }
}

export function uapPwdLengthRange(rule, value, callback) {
  if (value.length < 6 || value.length > 32) {
    callback(new Error($t('cms.callcenterinstanceslist.message.uapPsdLengthRangeValidateFailed')))
  } else {
    callback()
  }
}

export function validateIP(rule, value, callback) {
  if (value === '' || typeof value === 'undefined' || value == null) {
    callback(new Error($t('cms.sysparameter.edit.validate.ip')))
  } else {
    const reg =
      /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    if (!reg.test(value) && value !== '') {
      callback(new Error($t('cms.sysparameter.edit.validate.ip')))
    } else {
      callback()
    }
  }
}

export default {}
