export default {
  'oifde.custom.displayName': 'خدمت تجاری',
  'oifde.common.displayName': 'گره مشترک',
  'oifde.basic.displayName': 'خدمت پایه ای',
  'oifde.test.displayName': 'خدمت نمونه آزمون',
  'oifde.start.displayName': 'آغاز',
  'oifde.start.description': 'آغاز',
  'oifde.start.keywords': 'آغاز',
  'oifde.commonBusinessInvokeService.displayName': 'فراخوانی رابط تجاری',
  'oifde.commonBusinessInvokeService.description': 'فراخوانی رابط تجاری (commonBusinessInvokeService)',
  'oifde.commonBusinessInvokeService.keywords': 'فراخوانی رابط تجاری',
  'oifde.procedureService.displayName': 'رویه ذخیره شده',
  'oifde.procedureService.description': 'رویه ذخیره شده (procedureService)',
  'oifde.procedureService.keywords': 'رویه ذخیره شده',
  'oifde.conditionService.displayName': 'قضاوت شرط',
  'oifde.conditionService.description': 'قضاوت وضعیت (شرایط سرویس)',
  'oifde.conditionService.keywords': 'قضاوت شرط',
  'oifde.callEndService.displayName': 'پایان تماس',
  'oifde.callEndService.description': 'پایان تماس (callEndService)',
  'oifde.callEndService.keywords': 'پایان تماس',
  'oifde.sendSmsService.displayName': 'ارسال اس ام اس',
  'oifde.sendSmsService.description': 'ارسال پیامک (sendSmsService)',
  'oifde.sendSmsService.keywords': 'ارسال اس ام اس',
  'oifde.commonVoiceService.displayName': 'واکنش',
  'oifde.commonVoiceService.description': 'پاسخ (CommonResponseService)',
  'oifde.commonVoiceService.keywords': 'واکنش',
  'oifde.transforFlowService.displayName': 'انتقال جریان',
  'oifde.transforFlowService.description': 'انتقال جریان (transforFlowService)',
  'oifde.transforFlowService.keywords': 'انتقال روند',
  'oifde.callTransferService.displayName': 'انتقال تماس',
  'oifde.callTransferService.description': 'انتقال تماس (CallTransferService)',
  'oifde.callTransferService.keywords': 'انتقال تماس',
  'oifde.speechRecognitionService.displayName': 'شناخت معنایی',
  'oifde.speechRecognitionService.description': 'تشخیص معنایی (SpeechRecognitionService)',
  'oifde.speechRecognitionService.keywords': 'شناخت معنایی',
  'oifde.speechRecognitionService.confidence2TopError': 'يجب أن يكون الفاصل الزمني للثقة بين 0 و1',
  'oifde.keyboardRecognitionService.displayName': 'تشخیص کلید',
  'oifde.keyboardRecognitionService.description': 'تشخیص کلید (KeyboardRecognitionService)',
  'oifde.keyboardRecognitionService.keywords': 'تشخیص کلید',
  'oifde.textVariableToChatbotService.displayName': 'متن به ربات چت',
  'oifde.textVariableToChatbotService.description': 'ارسال متن به ربات چت (textVariableToChatbotService)',
  'oifde.textVariableToChatbotService.keywords': 'متن به ربات چت',
  'oifde.checkRegularService.displayName': 'اعتبار سنجی قانون',
  'oifde.checkRegularService.description': 'اعتبار سنجی قانون (ServiceRegularService را بررسی کنید)',
  'oifde.checkRegularService.keywords': 'اعتبار سنجی قانون',
  'oifde.dateProcessService.displayName': 'پردازش تاریخ',
  'oifde.dateProcessService.description': 'پردازش تاریخ (dateProcessService)',
  'oifde.dateProcessService.keywords': 'پردازش تاریخ',
  'oifde.errTimesClearService.displayName': 'پاکسازی تعداد خطاها',
  'oifde.errTimesClearService.description': 'پاکسازی شمارش خطا (errTimesClearService)',
  'oifde.errTimesClearService.keywords': 'پاکسازی تعداد خطاها',
  'oifde.CircleService.displayName': 'حلقه',
  'oifde.CircleService.description': 'Logic Loop (CircleService)',
  'oifde.CircleService.keywords': 'حلقه',
  'oifde.service.invoke.user.level': 'سطح کاربر',
  'oifde.UserLevelService.displayName': 'سطح کاربر',
  'oifde.UserLevelService.keywords': 'سطح کاربر',
  'oifde.UserLevelService.description': 'سطح کاربر (UserLevelService)',
  'oifde.service.invoke.user.level.reminder': 'این پارامتر را به یک عدد صحیح غیر منفی یا یک متغیر نویسه تنظیم کنید. مقدار پیش فرض ۰ و مقدار بیشینه ۱۴ است. تماس های مشتریان سطح بالا بعد از انتقال تماس به سرویس دستی، ابتدا پردازش می شوند.',
  'oifde.compositeTestService.displayName': 'مرکب از عنصر نمودار',
  'oifde.compositeTestService.description': 'نمونه عنصر نمودار ترکیبی (compositeTestService)',
  'oifde.compositeTestService.keywords': 'مرکب از عنصر نمودار',
  'oifde.service.condition.condgroup.desc': 'تنظیم خصیصه های شرایط قضاوت.',
  'oifde.service.condition.condgroup.displayName': 'شرط های قضاوت',
  'oifde.service.condition.condgroup.keyTitle': 'شرط',
  'oifde.service.condition.condgroup.valueTitle': 'عبارت شرط',
  'oifde.service.invokemethod.group.desc': 'همجستی روش',
  'oifde.service.invokemethod.group.displayName': 'همجستی روش',
  'oifde.service.invokemethod.group.value1Title': 'بازگشت شیء',
  'oifde.service.invokemethod.group.value2Title': 'نام روش',
  'oifde.service.invokemethod.group.value3Title': 'پارامترهای روش',
  'oifde.service.invokemethod.group.value1Tips': 'مقدار بازگشت روش دریافت شیء',
  'oifde.service.invokemethod.group.value2Tips': 'برگزیدن یک نام روش.',
  'oifde.service.invokemethod.group.value3Tips': 'پارامترهای متد ۱',
  'oifde.service.invokemethod.group.value4Tips': 'پارامترهای متد ۲',
  'oifde.service.invokemethod.group.value5Tips': 'پارامترهای متد ۳',
  'oifde.service.invokemethod.group.mathodType1': 'روش دستکاری رشته',
  'oifde.service.invokemethod.group.mathodType2': 'روش عملکرد آرایه',
  'oifde.service.invokemethod.group.mathodType3': 'روش عملیات نگاشت',
  'oifde.service.invokemethod.strSplit.desc': 'شکافتن یک رشته بر اساس جداساز داده شده',
  'oifde.service.invokemethod.strSubstring.desc': 'استخراج زیر رشته ها',
  'oifde.service.invokemethod.strIndexOf.desc': 'به اشتراک اولین رخداد یک رشته مشخص شده می یابد',
  'oifde.service.invokemethod.strStartsWith.desc': 'بررسی این که آیا رشته با پیشوند مشخص شده آغاز می شود یا خیر',
  'oifde.service.invokemethod.strEndsWith.desc': 'بررسی این که آیا یک رشته با پسوند مشخص شده پایان یابد',
  'oifde.service.invokemethod.strParseJSON.desc': 'یک رشته نویسه ی JSON را به یک شیء تبدیل می کند',
  'oifde.service.invokemethod.strParseInt.desc': 'رشته را به یک عدد صحیح تبدیل می کند',
  'oifde.service.invokemethod.strParseFloat.desc': 'رشته را به یک عدد نقطۀ شناور تبدیل می کند',
  'oifde.service.invokemethod.strLength.desc': 'طول رشته نویسه',
  'oifde.service.invokemethod.strToUpperCase.desc': 'نویسه های کوچک رشته را به بزرگ تبدیل می کند',
  'oifde.service.invokemethod.strToLowerCase.desc': 'نویسه های رشته ی بزرگ را به حالت کوچک تبدیل می کند',
  'oifde.service.invokemethod.arrayAdd.desc': 'افزودن عنصر به آرایه',
  'oifde.service.invokemethod.arrayAddAll.desc': 'همۀ عناصر از مجموعۀ دیگر را به یک آرایه اضافه می کند',
  'oifde.service.invokemethod.arrayContains.desc': 'تعیین می کند که آیا عنصر در آرایه است یا نه.',
  'oifde.service.invokemethod.arrayGet.desc': 'عناصر را با مقدار نمایه در آرایه وارد می کند',
  'oifde.service.invokemethod.arrayRemoveIdx.desc': 'عنصر مشترک مشخص شده را از آرایه حذف می کند',
  'oifde.service.invokemethod.arrayRemoveAllIdx.desc': 'عناصر مشترک را در آرایه ای که در مجموعۀ مشخص شده موجود است حذف می کند',
  'oifde.service.invokemethod.arrayClear.desc': 'همۀ عناصر در یک آرایه حذف می شود',
  'oifde.service.invokemethod.arraySize.desc': 'به اندازه ی آرایه',
  'oifde.service.invokemethod.arraySubList.desc': 'تا او را در هم باز گرداند و باز به هم ملحق شود',
  'oifde.service.invokemethod.arrayJoin.desc': 'تبدیل آرایه به رشته',
  'oifde.service.invokemethod.mapPut.desc': 'افزودن عنصر به نقشه',
  'oifde.service.invokemethod.mapGet.desc': 'به مقدار نگاشت کلید مشخص شده در نقشه می برد',
  'oifde.service.invokemethod.mapRemove.desc': 'جفت مقدار کلید مشخص شده را از نقشه حذف می کند',
  'oifde.service.invokemethod.mapClear.desc': 'پاک کردن تمام جفتهای مقدار کلید نقشه',
  'oifde.service.invokemethod.mapSize.desc': 'تعداد کلیدهای نقشه به دست می آورد',
  'oifde.service.invokemethod.strSplit.parm1.desc': 'رشتۀ نویسه ای که باید تقسیم شود',
  'oifde.service.invokemethod.strSplit.parm2.desc': 'جداساز',
  'oifde.service.invokemethod.strSubstring.parm1.desc': 'رشتۀ نویسه باید کوتاه شود',
  'oifde.service.invokemethod.strSubstring.parm2.desc': 'شروع زیرنویس (عدد صحیح)',
  'oifde.service.invokemethod.strSubstring.parm3.desc': 'پایان زیرنویس (عدد صحیح)',
  'oifde.service.invokemethod.strIndexOf.parm1.desc': 'رشته برای جستجو',
  'oifde.service.invokemethod.strIndexOf.parm2.desc': 'رشته مشخص شده',
  'oifde.service.invokemethod.strStartsWith.parm1.desc': 'رشتۀ نویسه باید بررسی شود',
  'oifde.service.invokemethod.strStartsWith.parm2.desc': 'رشتۀ پیشوند مشخص شده',
  'oifde.service.invokemethod.strEndsWith.parm1.desc': 'رشتۀ نویسه باید بررسی شود',
  'oifde.service.invokemethod.strEndsWith.parm2.desc': 'رشتۀ پسوند مشخص شده',
  'oifde.service.invokemethod.strParseJSON.parm1.desc': 'رشتۀ نویسه برای تبدیل',
  'oifde.service.invokemethod.strParseInt.parm1.desc': 'رشتۀ نویسه برای تبدیل',
  'oifde.service.invokemethod.strParseFloat.parm1.desc': 'رشتۀ نویسه برای تبدیل',
  'oifde.service.invokemethod.strLength.parm1.desc': 'رشتۀ نویسه ای که باید محاسبه شود',
  'oifde.service.invokemethod.strToUpperCase.parm1.desc': 'رشتۀ نویسه برای تبدیل',
  'oifde.service.invokemethod.strToLowerCase.parm1.desc': 'رشتۀ نویسه برای تبدیل',
  'oifde.service.invokemethod.arrayAdd.parm1.desc': 'آرایه ای که باید اضافه شود (این روش آرایه ای را که قرار است اضافه شود اصلاح می کند)',
  'oifde.service.invokemethod.arrayAdd.parm2.desc': 'موقعیت اضافه شده (تریلر پیش فرض)',
  'oifde.service.invokemethod.arrayAdd.parm3.desc': 'عناصر اضافه شده',
  'oifde.service.invokemethod.arrayAddAll.parm1.desc': 'آرایه ای که باید اضافه شود (این روش آرایه ای را که قرار است اضافه شود اصلاح می کند)',
  'oifde.service.invokemethod.arrayAddAll.parm2.desc': 'موقعیت اضافه شده (تریلر پیش فرض)',
  'oifde.service.invokemethod.arrayAddAll.parm3.desc': 'عناصر اضافه شده',
  'oifde.service.invokemethod.arrayContains.parm1.desc': 'آرایه ای که باید شناسایی شود',
  'oifde.service.invokemethod.arrayContains.parm2.desc': 'عناصر قابل قضاوت',
  'oifde.service.invokemethod.arrayGet.parm1.desc': 'آرایه مورد استفاده',
  'oifde.service.invokemethod.arrayGet.parm2.desc': 'زیرنویس آرایه',
  'oifde.service.invokemethod.arrayRemoveIdx.parm1.desc': 'آرایه مورد استفاده',
  'oifde.service.invokemethod.arrayRemoveIdx.parm2.desc': 'اشتراک آرایه را حذف کنید',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm1.desc': 'آرایه مورد استفاده',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm2.desc': 'مجموعه زیرنویس آرایه را مشخص می کند',
  'oifde.service.invokemethod.arrayClear.parm1.desc': 'آرایه ای که باید پاک شود',
  'oifde.service.invokemethod.arraySize.parm1.desc': 'آرایه طولی که باید محاسبه شود',
  'oifde.service.invokemethod.arraySubList.parm1.desc': 'آرایه ای که باید رهگیری شود',
  'oifde.service.invokemethod.arraySubList.parm2.desc': 'گرفتن موقعیت آغاز',
  'oifde.service.invokemethod.arraySubList.parm3.desc': 'وقفه موقعیت پایانی',
  'oifde.service.invokemethod.arrayJoin.parm1.desc': 'آرایه ای که باید تبدیل شود',
  'oifde.service.invokemethod.arrayJoin.parm2.desc': 'رشته اتصال',
  'oifde.service.invokemethod.mapPut.parm1.desc': 'نقشه ای که باید اضافه شود (این روش نقشه اضافه شدن را اصلاح می کند)',
  'oifde.service.invokemethod.mapPut.parm2.desc': 'کلید (فقط نوع رشته پشتیبانی می شود)',
  'oifde.service.invokemethod.mapPut.parm3.desc': 'عناصر اضافه شده',
  'oifde.service.invokemethod.mapGet.parm1.desc': 'نقشه قابل پردازش',
  'oifde.service.invokemethod.mapGet.parm2.desc': 'کلید (فقط نوع رشته پشتیبانی می شود)',
  'oifde.service.invokemethod.mapRemove.parm1.desc': 'نقشه قابل پردازش',
  'oifde.service.invokemethod.mapRemove.parm2.desc': 'کلید (فقط نوع رشته پشتیبانی می شود)',
  'oifde.service.invokemethod.mapClear.parm1.desc': 'نگاشت برای پاک کردن',
  'oifde.service.invokemethod.mapSize.parm1.desc': 'نگاشت مقدار مورد محاسبه',
  'oifde.service.invokemethod.strSplit.resultObj.desc': 'شیء بازگشته از نوع آرایه است و رشته را پس از شکافت ذخیره می کند.',
  'oifde.service.invokemethod.strSubstring.resultObj.desc': 'شیء بازگشته، یک رشته کاراکتری است که یک زیر رشته ای از انشعاب است.',
  'oifde.service.invokemethod.strIndexOf.resultObj.desc': 'شیء بازگشته ، یک مقدار مشترک است. مقدار يک عدد صحیح است.',
  'oifde.service.invokemethod.strStartsWith.resultObj.desc': 'شیء بازگشته نتیجه آشکارسازی است. مقدار یک عدد صحیح از ۰ یا ۱ است.',
  'oifde.service.invokemethod.strEndsWith.resultObj.desc': 'شیء بازگشته نتیجه آشکارسازی است. مقدار یک عدد صحیح از ۰ یا ۱ است.',
  'oifde.service.invokemethod.strParseJSON.resultObj.desc': 'شیء بازگشته ، شیء تبدیل شده JSON است.',
  'oifde.service.invokemethod.strParseInt.resultObj.desc': 'شیء بازگشته ، یک عدد صحیح پس از تبدیل است.',
  'oifde.service.invokemethod.strParseFloat.resultObj.desc': 'شیء بازگشته یک عدد نقطه شناور تبدیل شده است.',
  'oifde.service.invokemethod.strLength.resultObj.desc': 'شیء بازگشتی ، طول یک رشته نویسه است. مقدار یک عدد صحیح است.',
  'oifde.service.invokemethod.strToUpperCase.resultObj.desc': 'شیء بازگشته ، رشته نویسه پس از تبدیل است.',
  'oifde.service.invokemethod.strToLowerCase.resultObj.desc': 'شیء بازگشته ، رشته نویسه پس از تبدیل است.',
  'oifde.service.invokemethod.arrayAdd.resultObj.desc': 'شیء بازگشته ، افزودن نتیجه است. اگر جمع موفقیت آمیز باشد، ۱ را باز می گردانند. در غیر این صورت، صفر بازگشت.',
  'oifde.service.invokemethod.arrayAddAll.resultObj.desc': 'شیء بازگشته ، افزودن نتیجه است. اگر جمع موفقیت آمیز باشد، ۱ را باز می گردانند. در غیر این صورت، صفر بازگشت.',
  'oifde.service.invokemethod.arrayContains.resultObj.desc': 'شیء بازگشته نتیجه ی قضاوت است. مقدار یک عدد صحیح از ۰ یا ۱ است.',
  'oifde.service.invokemethod.arrayGet.resultObj.desc': 'عنصری که شیء آن آرایه است را باز می کند.',
  'oifde.service.invokemethod.arrayRemoveIdx.resultObj.desc': 'شیء بازگشته نتیجه حذف است. اگر حذف موفقیت آمیز باشد، عنصر مشترک متناظر باز می گردد. در غير اين صورت، "نالد" برگشته.',
  'oifde.service.invokemethod.arrayRemoveAllIdx.resultObj.desc': 'شیء بازگشته نتیجه حذف است. اگر حذف موفقیت آمیز باشد ، ۱ را باز می گردانند. در غیر این صورت، صفر بازگشت.',
  'oifde.service.invokemethod.arrayClear.resultObj.desc': 'شیء بازگشتی خالی است ، و جعبه متن کم شده و نمی توان آن را ویرایش کرد.',
  'oifde.service.invokemethod.arraySize.resultObj.desc': 'شیء بازگشته، طول آرایه است. مقدار یک عدد صحیح است.',
  'oifde.service.invokemethod.arraySubList.resultObj.desc': 'شیء بازگشته یک زیرمجموعه است.',
  'oifde.service.invokemethod.arrayJoin.resultObj.desc': 'شیء بازگشته ، رشته نویسه تبدیل شده است.',
  'oifde.service.invokemethod.mapPut.resultObj.desc': 'منطق شیء بازگشته به شرح زیر است: اگر کلید وجود نداشته باشد، صفر برگردانده می شود. اگر کلید وجود داشته باشد ، مقدار جایگزین شده و نوع شیء بازگشت داده می شوند.',
  'oifde.service.invokemethod.mapGet.resultObj.desc': 'مقدار که شیء برای کلید مشخص شده نگاشت می شود را بازگرداند.',
  'oifde.service.invokemethod.mapRemove.resultObj.desc': 'بازگشت شیء: این روش مقدار مربوط به این کلید را برمی گرداند؛ اگر نگاشت در نگاشت کلید نداشته باشد، صفر را باز می گرداند.',
  'oifde.service.invokemethod.mapClear.resultObj.desc': 'شیء بازگشتی خالی است ، و جعبه متن کم شده و نمی توان آن را ویرایش کرد.',
  'oifde.service.invokemethod.mapSize.resultObj.desc': 'شیء بازگشته ، تعداد کلیدهای در نقشه است. مقدار یک عدد صحیح است.',
  'oifde.service.invoke.cachegroup.desc': 'تنظیم خصیصه های متغیر متناظر.',
  'oifde.service.invoke.cachegroup.displayName': 'خصیصه های متغیر',
  'oifde.service.invoke.cachegroup.keyTitle': 'متغیر نهانگاه',
  'oifde.service.invoke.cachegroup.valueTitle': 'پاسخ خصیصه',
  'oifde.service.invoke.interfaceType': 'نوع واسط',
  'oifde.service.invoke.interfaceType.desc': 'از نوع رابط.',
  'oifde.service.invoke.interfaceId': 'واسط درخواست شده',
  'oifde.service.invoke.interfaceId.desc': 'رابط درخواست شد',
  'oifde.service.invoke.paramgroup.desc': 'فهرست پارامترهای ورودی واسط.',
  'oifde.service.invoke.paramgroup.name': 'پارامترهای ورودی واسط',
  'oifde.service.invoke.startTime': 'زمان آغاز',
  'oifde.service.invoke.startTime.desc': 'تنسيق HH:MM:SS مثال: 08:00:00',
  'oifde.service.invoke.endTime': 'زمان پایان',
  'oifde.service.invoke.endTime.desc': 'تنسيق HH:MM:SS مثال: 23:00:00',
  'oifde.service.invoke.sendCircle': 'ارسال فاصله',
  'oifde.service.invoke.sendCircle.desc': 'فترة استدعاء الواجهة، بالأيام.عدد صحيح، لا يزيد عن 365.إذا تم تعيين -1 على أنه لا يتم الاستدعاء وفقًا للفاصل الزمني، فسيتم تشغيل عنصر مخطط النهاية في أي وقت.',
  'oifde.service.invoke.CallTransferCallingNum': 'شماره تماس',
  'oifde.service.invoke.CallTransferCallingNum.desc': 'اگر نوع انتقال به ۵ تنظیم شود ، این مقدار به عنوان شماره تماس استفاده می شود (به این ترتیب، تماس به طرف سوم منتقل می شود).',
  'oifde.service.invoke.CallTransferCallingNum.lastAgentDesc': 'الرقم الطالب هو رقم المكالمة الواردة للعميل.مثل SYS.callingNumber',
  'oifde.service.invoke.CallTransferType': 'نوع التحويل',
  'oifde.service.invoke.CallTransferType.desc': 'نوع انتقال ديالوگ گزینه ها به صورت زیر هستند: ۱ انتقال به خدمت دستی (به صف مهارت با کد مسیریابی) ۲ انتقال به یک شماره ی ِ ۳: انتقال به خدمت دستی) به صف مهارت (با نام مهارت) ۴: انتقال به خدمت دستی) بر اساس شناسه (۵: انتقال به یک عدد شخص ثانویه ۶: دستیار دیجیتال ( ۷: انتقال به خدمت دستی) به آخرین نماینده ، فقط چندرسانه ای در حال حاضر پشتیبانی می شود ؛ ۸: انتقال به خدمت دستی ) به صف مهارتی که نماینده متعلق به آن است ، فقط چند رسانه ای در حال حاضر پشتیبانی می شود ( ۳۱: انتقال به یک پیام چند رسانه ای (به آخرین نماینده ۳۲; انتقال به پیام چند رسانه ای (به یک صف مهارت)؛ ۳۳: انتقال به یک پیام چند رسانه ای (به یک نماینده).',
  'oifde.service.invoke.CallTransferRouteValue': 'قيمة المسار',
  'oifde.service.invoke.CallTransferRouteValue.desc': 'با ارزش مسير انتقال تماس بگير تنظیم این پارامتر بر اساس نوع انتقال تماس.',
  'oifde.service.invoke.CallTransferRouteTypePolicy': 'نهج التوجيه',
  'oifde.service.invoke.CallTransferRoutePolicyType.percentage': 'النسبة المئوية',
  'oifde.service.invoke.CallTransferRoutePolicyType.numberSegment': 'مقطع رقمي',
  'oifde.service.invoke.CallTransferRouteDefaultSkillName': 'قائمة انتظار المهارات الافتراضية',
  'oifde.service.invoke.CallTransferMatchingType': 'قاعدة المطابقة',
  'oifde.service.invoke.CallTransferMatchingType.prefix': 'بادئة',
  'oifde.service.invoke.CallTransferMatchingType.suffix': 'لاحقة',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentTotalError': 'النسبة المئوية الإجمالية لا تساوي 100',
  'oifde.service.invoke.CallTransferTransData': 'داده های وابسته',
  'oifde.service.invoke.CallTransferTransData.desc': 'اطلاعات مربوط به حمل در زمان انتقال تماس به یک نماینده.',
  'oifde.service.invoke.CallTransferTransData.desc2': 'به طور متناوب شما می توانید چند جریان یا متغیر جهانی را وارد کنید، برای مثال، {"data":${FLOW.XXX},"type":${GLOBAL.XXX}، "time":"2000"}.',
  'oifde.service.invoke.textVariable': 'متغیر متن',
  'oifde.service.invoke.textVariable.desc': 'متغیر متنی ارسال شده به گپ. یک مقدار باید قبل از اجرای گره به این متغیر اختصاص داده شود.',
  'oifde.service.invoke.KeyboardInputRegExp': 'عبارت قواعد',
  'oifde.service.invoke.KeyboardInputRegExp.desc': 'عبارت بررسی می کند که آیا متن وارد شده با قاعده مشخص شده برخورد می کند یا خیر. مقدار می تواند یک عبارت منظم باشد. مثال: ^(1([0-9]{10})|([*]|[#]))$.',
  'oifde.service.invoke.voiceType': 'نوع پاسخ',
  'oifde.service.invoke.voiceSource': 'پاسخ به متن',
  'oifde.service.invoke.staticVoice': 'صدای ایستا',
  'oifde.service.invoke.TTSVoice': 'TTS',
  'oifde.service.invoke.multiVoice': 'صدای متغير',
  'oifde.service.invoke.multiVoiceLanguage': 'زبان',
  'oifde.service.invoke.multiVoiceType': 'نوع',
  'oifde.service.invoke.voiceTemplateCode': 'پاسخ الگو',
  'oifde.service.invoke.modelType': 'حالت پاسخ',
  'oifde.service.invoke.modelType.desc': 'این که محاوره می تواند در هم باز شود یا خیر.',
  'oifde.service.invoke.timeOut': 'فاصله اتمام وقت',
  'oifde.service.invoke.timeOut.desc': 'فاصله زمانی. مقدار یک عدد صحیح است. مثال: 15.',
  'oifde.service.invoke.voiceVariable': 'متغیر پاسخی',
  'oifde.service.invoke.voiceVariable.mix.desc': 'متغیر صدا یا متنی که در پاسخ قرار گرفته است. یک مقدار باید قبل از اینکه گره اختصاص دادهشود به این متغیر اختصاص داده شود (برای یک نوع صدا و متن ، متغیر پاسخ در پروندۀ ضبط ~ $TTS ~ ضبط کردن پرونده نشانی وب است.)',
  'oifde.service.invoke.voiceVariable.tts.desc': 'متغیر صدا یا متنی که در پاسخ قرار گرفته است. یک مقدار باید قبل از اینکه گره اختصاص دادهشود به این متغیر اختصاص داده شود) برای نوع متن پاسخ ، متغیر پاسخ در الگو متن TTS است. فقط یک متن TTS پشتیبانی می شود (',
  'oifde.service.invoke.voiceVariable.voice.desc': 'متغیر صدا یا متنی که در پاسخ قرار گرفته است. یک مقدار باید قبل از اینکه گره اختصاص دادهشود به این متغیر اختصاص داده شود ) برای نوع صدای ایستا ، متغیر پاسخ در الگو پروندۀ ضبط می باشد. فقط یک نشانی وب پشتیبانی می شود. (',
  'oifde.service.invoke.voiceVariable.default.desc': 'متغیر صدا یا متنی که در پاسخ قرار گرفته است. یک مقدار باید قبل از اجرای گره به این متغیر اختصاص داده شود.',
  'oifde.service.invoke.voiceVariable.multiVoice.telnum.desc': 'متغیر صدا یا متنی که در پاسخ قرار گرفته است. یک مقدار باید قبل از اینکه گره اختصاص دادهشود به این متغیر اختصاص داده شود (برای یک نوع شماره تلفن، متغیر پاسخ در الگو واقعی متغیر پاسخی، متن عددی است. اگر ورودی نادرست باشد، صدای پخش غیرطبیعی است.)',
  'oifde.service.invoke.voiceVariable.multiVoice.number.desc': 'متغیر صدا یا متنی که در پاسخ قرار گرفته است. یک مقدار باید قبل از اینکه گره اختصاص دادهشود به این متغیر اختصاص داده شود (برای یک نوع عدد، متغیر پاسخ در الگو واقعی متغیر پاسخ، متن عددی است. اگر ورودی نادرست باشد، اجرای صدا غیر عادی است.)',
  'oifde.service.invoke.voiceVariable.multiVoice.time.desc': 'متغیر صدا یا متنی که در پاسخ قرار گرفته است. یک مقدار باید قبل از اینکه گره اختصاص دادهشود به این متغیر اختصاص داده شود (برای یک نوع زمانی متغیر پاسخ برابر با:ح.m:s، اگر ورودی اشتباه باشد، صدای پخش غیرطبیعی است.)',
  'oifde.service.invoke.voiceVariable.multiVoice.date.desc': 'متغیر صدا یا متنی که در پاسخ قرار گرفته است. یک مقدار باید قبل از اینکه گره اختصاص دادهشود به این متغیر اختصاص داده شود (برای نوع تاریخ، متغیر پاسخ متغیر است:yyymd ، اگر ورودی نادرست باشد، پخش صدای غیرطبیعی است.)',
  'oifde.service.invoke.voiceVariable.multiVoice.price.desc': 'متغیر صدا یا متنی که در پاسخ قرار گرفته اند. یک مقدار باید قبل از اینکه گره اختصاص دادهشود به این متغیر اختصاص داده شود (برای یک نوع قیمت، متغیر پاسخ در الگو واقعی محتوای پاسخ، متن عددی است. واحد: دقیقه. اگر ورودی نادرست باشد، صدای پخش غیر عادی است.)',
  'oifde.service.invoke.offsetType': 'انحراف دوره',
  'oifde.service.invoke.offsetType.desc': 'انحراف به روز یا ساعت',
  'oifde.service.invoke.offsetType.month': 'بر ماه',
  'oifde.service.invoke.offsetType.hour': 'بر ساعت',
  'oifde.service.invoke.sourceVariable': 'رشته نویسه ی متن',
  'oifde.service.invoke.sourceVariable.desc': 'متغیر رشته ی متن. فرمت YyMM در صورتی که انحراف به ماه باشد و yyMMdHms است اگر انحراف به ساعت باشد. به عنوان مثال: ۲۰۲۲۱۳ یا ۲۰۲۰۸. یک مقدار باید قبل از اجرای گره به این متغیر اختصاص داده شود.',
  'oifde.service.invoke.destVariable': 'رشته نویسۀ هدف',
  'oifde.service.invoke.destVariable.desc': 'متغیر رشته ی هدف برای ذخیرۀ نتیجه استفاده شده است.',
  'oifde.service.invoke.offsetMonths': 'تعداد انحرافها',
  'oifde.service.invoke.offsetMonths.desc': 'تعداد انحرافات. مقدار یک عدد صحیح مثبت یا منفی است. تنظیم این پارامتر بر اساس دورۀ آفست) بر ماه یا ساعت (. برای مثال ، اگر انحراف بر ماه انتخاب شود ، مقدار - ۶ نشان می دهد که رشتۀ نویسه منبع به آنچه شش ماه پیش استفاده شده است بازگشت داده شده است.',
  'oifde.service.invoke.keyBoardType': 'نوع ورودی صفحه کلید',
  'oifde.service.invoke.keyBoardType.desc': 'از نوع ورودی صفحه کلید مقدار می تواند کلید_فون یا گذرواژه باشد، یا خالی بماند.',
  'oifde.service.invoke.inputVariable': 'متغیر',
  'oifde.service.invoke.inputVariable.desc': 'متغیر برای ذخیرۀ ورودی صفحه کلید کاربر.',
  'oifde.service.invoke.inputVariableTitle': 'تعيين المتغيرات',
  'oifde.service.invoke.inputVariableValue': 'قيم المتغيرات',
  'oifde.service.invoke.conditionTitle': 'الفرع الشرطي',
  'oifde.service.invoke.branchGroup.timeout': 'انتهت المهلة',
  'oifde.service.invoke.branchGroup.nomatch': 'نوماتش',
  'oifde.service.invoke.branchGroup.error': 'خطأ',
  'oifde.service.invoke.branchGroup.silentAgent': 'الوكيل الصامت',
  'oifde.service.invoke.targetFlowCode': 'کد روند هدف',
  'oifde.service.invoke.targetNodeId': 'شناسه ی سرۀ هدف',
  'oifde.service.invoke.voiceType.staticVoice': 'صدای ایستا',
  'oifde.service.invoke.voiceType.TTSVoice': 'TTS',
  'oifde.service.invoke.voiceType.video': 'ویدئو',
  'oifde.service.invoke.smsTemplateCode': 'الگو پیام کوتاه',
  'oifde.service.invoke.smsContent': 'محتویات پیام کوتاه',
  'oifde.service.invoke.smsContent.desc': 'محتوای پیام کوتاه.',
  'oifde.service.invoke.modelType.voiceBreak': 'بازشناختی وقفه',
  'oifde.service.invoke.modelType.noVoiceBreak': 'فقط بازپخش ، فشار کلید تشخیصی یا کلید ندارد',
  'oifde.service.invoke.modelType.keyboardBreak': 'وقفه با فشارهای کلید',
  'oifde.service.invoke.modelType.voiceAndKeyboard': 'بازشناخت و فشارهای کلید',
  'oifde.service.invoke.modelType.voiceAndNobreak': 'شناسایی پس از بازخورد',
  'oifde.service.invoke.modelType.voiceAndInputAndNobreak': 'شناسایی و فشار کلید پس از پخش',
  'oifde.service.invoke.KeyboardInputRegExp.MobileAll': 'تأیید فرمت شماره تلفن همراه (با # و *)',
  'oifde.service.invoke.KeyboardInputRegExp.Mobile': 'اعتبارسنجی الگو شماره تلفن همراه (بدون # یا *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordAll': 'اعتبار سنجی فرمت رمز عبور (با # و *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordStar': 'تأیید فرمت رمز عبور (با *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberAll': 'اعتبارسنجی شماره موبایل (با # و *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberStar': 'تایید شماره موبایل (با *)',
  'oifde.service.invoke.KeyboardInputRegExp.Month': 'اعتبارسنجی ورودی ماه',
  'oifde.service.invoke.context': 'متن',
  'oifde.service.invoke.context.desc': 'اطلاعات متن به TUC منتقل شد.',
  'oifde.service.invoke.vendorParam': 'پارامتر بسط یافته',
  'oifde.service.invoke.vendorParam.desc': 'پارامتر تشخيص صوتي که به IVR برگشت',
  'oifde.service.invoke.input': 'محتویات شناخت معنایی',
  'oifde.service.invoke.input.desc': 'محتواي شناسايي معنایي به "تيو سي" منتقل شد محتوای می تواند متغیر باشد. اگر این حوزه خالی باشد ، محتوای کاربر وارد شده به TUC انتقال می شود.',
  'oifde.service.invoke.event': 'رویداد',
  'oifde.service.invoke.event.desc': 'اين واقعه به مرکز پزشکي فرستاده شده',
  'oifde.service.invoke.event.cleanup': 'پاک کردن نهانگاه نشست',
  'oifde.service.invoke.event.cleanCurrent': 'پاک کردن نهانگاه متن جاری',
  'oifde.service.invoke.procedureCode': 'روند ذخیره شده',
  'oifde.service.invoke.complexcellgroupin.name': 'پارامترهای ورودی',
  'oifde.service.invoke.complexcellgroupin.desc': 'پارامترهاي ورودی',
  'oifde.service.invoke.complexcellgroupout.name': 'پارامترهای خروجی',
  'oifde.service.invoke.complexcellgroupout.desc': 'پارامترهاي خروجی',
  'oifde.service.invoke.targetIntentCount': 'تعداد قصدها',
  'oifde.service.invoke.targetIntentCount.desc': 'تعداد بیشینۀ نیتها توسط TUC بازگردانده شده است.',
  'oifde.service.invoke.confidence2Top': 'فاصله اعتماد',
  'oifde.service.invoke.confidence2Top.desc': 'فاصله بین اعتماد به نفس چند نیت و قصد اول، زمانی که قصد چند منظور باید برگردانده شود.',
  'oifde.service.invoke.index.desc': 'شمارش معکوس',
  'oifde.service.invoke.index': 'شمارشگر',
  'oifde.service.invoke.oblist.desc': 'فهرست حلقه ها.',
  'oifde.service.invoke.oblist': 'حلقه ها',
  'oifde.service.invoke.object.desc': 'شیء حلقه ای.',
  'oifde.service.invoke.object': 'حلقه کردن شیء',
  'oifde.service.invoke.voiceType.multi-media': 'چندرسانه ای',
  'oifde.service.invoke.modelType.inputAndNobreak': 'بدون وقفه بعد از مجموعه رقم',
  'oifde.service.invoke.maxCollectLength': 'تعداد ارقام جمع شده',
  'oifde.service.invoke.maxCollectLength.desc': 'تنظیم تعداد بیشینه ارقام جمع آوری شده.',
  'oifde.service.invoke.collectTimeout': 'اتمام وقت مجموعۀ رقمی',
  'oifde.service.invoke.collectTimeout.desc': 'فاصلۀ اتمام وقت مجموعه رقم را تنظیم می کند. متغیرهای FLO و GLOBAL پشتیبانی می شوند. مقدار نیز می تواند یک عدد صحیح از ۱ تا ۱۲۰ باشد. اگر مقدار مورد نیاز را برآورده نکند ، از مقدار پیش فرض ۵ استفاده می شود.',
  'oifde.service.invoke.cancelCollectFlag': 'کلید لغو',
  'oifde.service.invoke.cancelCollectFlag.yes': 'استفاده از کلید ستاره (*)',
  'oifde.service.invoke.cancelCollectFlag.no': 'از کلید ستاره (*) استفاده نکنید',
  'oifde.service.invoke.confirmCollectFlag': 'تأیید کلید',
  'oifde.service.invoke.confirmCollectFlag.yes': 'استفاده از کلید (#) پوند',
  'oifde.service.invoke.confirmCollectFlag.no': 'استفاده از کلید پوند (#)',
  'oifde.service.invoke.ttsAdvancedSetting': 'تنظیمات پیشرفته TTSName',
  'oifde.service.invoke.ttsAdvancedSetting.desc': 'این که آیا این تنظیمات برای مشخص کردن پخش صدای TTS- بر مبنای TTS فعال شود یا خیر.',
  'oifde.service.invoke.ttsAdvancedSetting.open': 'به کار انداختن',
  'oifde.service.invoke.ttsAdvancedSetting.close': 'غیرفعال سازی',
  'oifde.service.invoke.ttsSoundEffect': 'صدا',
  'oifde.service.invoke.ttsSoundEffect.desc': 'برگزیدن این که آیا از صدای مرد یا زن برای پخش صدای TTS استفاده شود.',
  'oifde.service.invoke.ttsSoundEffect.male': '.مَر',
  'oifde.service.invoke.ttsSoundEffect.female': 'مونث',
  'oifde.service.invoke.ttsSoundSpeed': 'سرعت صوت',
  'oifde.service.invoke.ttsSoundSpeed.desc': 'سرعتی را که پخش زمين پخش صوتی بر اساس TTS انجام می شود را در اینجا برگزینید.',
  'oifde.service.invoke.ttsSoundSpeed.low': 'آروم',
  'oifde.service.invoke.ttsSoundSpeed.normal': 'متوسط',
  'oifde.service.invoke.ttsSoundSpeed.high': 'سريع',
  'oifde.service.invoke.ttsMRCPVendorSetting.open': 'به کار انداختن',
  'oifde.service.invoke.ttsMRCPVendorSetting.close': 'غیرفعال سازی',
  'oifde.service.invoke.ttsMRCPVendorSetting': 'مقادیر پیش فرض برای پارامترهای TTS گسترش یافته.',
  'oifde.service.invoke.ttsMRCPVendorSetting.desc': 'پس از مقادیر پیش فرض برای پارامترهای TTS با فعال شدن ، تعریفها اثر صوتی انتخاب شده و سرعت صوت را جای نوشت می کنند.',
  'oifde.service.invoke.ttsMRCPVendor': 'پارامتر بسطی TTS',
  'oifde.service.invoke.ttsMRCPVendor.desc': 'پارامترهای کارساز TTS گسترده. تنظیم پارامترها بر اساس نیازهای کارساز TTS\. یک مثال سرعت=GLOBAL.tssspeed;P [pitch=GLulm=GLOBAL . نام_نام صوتی=GLOBAL. نام صوتی; متغیرهای GLOBAL و FLOF پشتیبانی می شوند. اگر مقدار متغیر در محدوده استاندارد نیست ، از مقدار پیش فرض استفاده می شود. محدودهٔ مقدار سرعت برابر [۵۰۰،۵۰۰] و مقدار پیش فرض ۰ است. محدوده مقدار حجم] ۰، ۱۰۰] و مقدار پیش فرض ۵۰ است. محدودهٔ ارزش گام برابر [۵۰۰،۵۰۰] و مقدار پیش فرض ۰ است. مقدار پیش فرض نام صدا، cnnnn_kefuman_com است.',
  'oifde.service.invoke.asrAdvancedSetting': 'تنظیمات پیشرفته ی R',
  'oifde.service.invoke.asrAdvancedSetting.desc': 'این که آیا این تنظیمات برای مشخص کردن تشخیص ASR فعال شود یا خیر.',
  'oifde.service.invoke.asrAdvancedSetting.open': 'به کار انداختن',
  'oifde.service.invoke.asrAdvancedSetting.close': 'غیرفعال سازی',
  'oifde.service.invoke.asrRecognizeType': 'نوع شناسایی',
  'oifde.service.invoke.asrRecognizeType.desc': 'از نوع شناسايي "ع.ار". در حال حاضر تنها شناسایی مشترک پشتیبانی می شود.',
  'oifde.service.invoke.asrRecognizeType.normal': 'مشترک',
  'oifde.service.invoke.asrRecognizeType.intelligent': 'باهوش',
  'oifde.service.invoke.asrNoInputTimeout': 'توقۀ اتمام وقت سکوت مشترک',
  'oifde.service.invoke.asrNoInputTimeout.desc': 'فاصلۀ اتمام وقت هنگامی که یک مشترک در طی شناسایی ایس آر در ثانیه صحبت نمی کند. مقدار پیش فرض ۱۰۰ است.',
  'oifde.service.invoke.asrRecognitionTimeout': 'فاصله اتمام وقت شناسایی',
  'oifde.service.invoke.asrRecognitionTimeout.desc': 'فاصله زمانی که زمان تشخیص MACR ، به ثانیه تموم شود. مقدار پیش فرض ۲۰۰ است.',
  'oifde.service.invoke.asrSpeechCompleteTimeout': 'وقفه ی اتمام وقت مشترک',
  'oifde.service.invoke.asrSpeechCompleteTimeout.desc': 'فاصلۀ اتمام وقت هنگامی که مشترک طی تشخیص ایس آر در ثانیه مکث می کند. مقدار پیش فرض ۵۰۰ است.',
  'oifde.service.invoke.asrMRCPVendor': 'پارامتر گسترش یافته ASR',
  'oifde.service.invoke.asrMRCPVendor.desc': 'پارامتر گسترش یافته کارساز ASR. این پارامتر را بر اساس نیازهای فروشنده تنظیم کنید.',
  'oifde.service.invoke.voiceType.card': 'کارت',
  'oifde.service.invoke.operationType': 'نوع عمل',
  'oifde.service.invoke.operationType.open': 'آغاز ضبط',
  'oifde.service.invoke.operationType.close': 'توقف ضبط',
  'oifde.service.invoke.processRecord': 'ضبط کامل',
  'oifde.service.invoke.processRecord.open': 'بله.',
  'oifde.service.invoke.processRecord.close': 'نه.',
  'oifde.service.invoke.recordType': 'نوع ضبط',
  'oifde.service.invoke.recordType.audio': 'فقط صوتی',
  'oifde.service.invoke.recordType.video': 'صوتی و ویدئو',
  'oifde.yugaoRecordService.displayName': 'ضبط VoiceCyber',
  'oifde.yugaoRecordService.description': 'VoiceCyber Recording (voiceCyberRecordService)',
  'oifde.yugaoRecordService.keywords': 'ضبط VoiceCyber',
  'oifde.jsonDataProcessService.displayName': 'درج داده های JSON:',
  'oifde.jsonDataProcessService.description': 'احضار داده های JSON(jsonDataProcessService).',
  'oifde.jsonDataProcessService.keywords': 'درج داده های JSON:',
  'oifde.timeSelectService.displayName': 'گزینش زمان',
  'oifde.timeSelectService.description': 'انتخاب زمان (timeSelectService).',
  'oifde.timeSelectService.keywords': 'گزینش زمان',
  'oifde.queryMessageService.displayName': 'پرس وجوی اطلاعات',
  'oifde.queryMessageService.description': 'جستجو اطلاعات (queryMessageService).',
  'oifde.queryMessageService.keywords': 'پرس وجوی اطلاعات',
  'oifde.service.invoke.recordMode': 'حالت ضبط',
  'oifde.service.invoke.recordMode.onlyuser': 'فقط ثبت نام',
  'oifde.service.invoke.recordMode.onlyivr': 'فقط ضبط',
  'oifde.service.invoke.recordMode.all': 'مشترک ضبط و آي و آر',
  'oifde.service.invoke.timeSelect.NonWorkTime': 'شاخه زمان مشخص نشده',
  'oifde.service.invoke.timeSelect.weekParameter': 'گزینش هفته',
  'oifde.service.invoke.timeSelect.serviceTimeParameter': 'دوره خدمت',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter': 'تاریخ خدمت مشخص شده',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter': 'تاریخ غیر سرویس مشخص شد',
  'oifde.service.invoke.timeSelect.serviceTimeParameter.desc': 'دوره خدمت ، پیکربندی 1 تا 10 رکورد.',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.desc': 'تاریخ خدمت مشخص شده. بیشینه ',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.desc': 'تاریخ غیر سرویس مشخص شده است. بیشینه ۵۰ رکورد پشتیبانی می شود.',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.title': 'اگر یک تاریخ سرویس پیکربندی شده در گزینش هفته موجود نشود، تاریخ خدمت به عنوان یک روز کاری در نظر گرفته می شود.',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.title': 'اگر یک تاریخ غیر خدمت مشخص شده پیکربندی شود، تاریخ غیر سرویس با بالاترین اولویت در نظر گرفته می شود.',
  'oifde.service.invoke.workdays.Monday': 'دوشنبه',
  'oifde.service.invoke.workdays.Tuesday': 'سه شنبه',
  'oifde.service.invoke.workdays.Wednesday': 'چهارشنبه',
  'oifde.service.invoke.workdays.Thursday': 'پنج شنبه',
  'oifde.service.invoke.workdays.Friday': 'جمعه',
  'oifde.service.invoke.workdays.Saturday': 'شنبه',
  'oifde.service.invoke.workdays.Sunday': 'یکشنبه',
  'oifde.service.interfaceType.selfDefinedInterface': 'واسط تعریف شده خود',
  'oifde.service.interfaceType.innerInterface': 'واسط درونی',
  'oifde.qarecord.interface.name': 'ضبط Q&A',
  'oifde.qarecord.interface.desc': 'شناسه پرسشنامه، عنوان پرسش، قصد پاسخ به کاربر و پاسخ اصلی را ضبط می کند.',
  'oifde.apiToken.interface.name':'بدست آوردن نشانه APIFric',
  'oifde.apiToken.interface.desc':'واسط استفاده شده برای به دست آوردن نشانه APIFabric',
  'oifde.apiToken.applyType.desc': 'پیشوند توکن',
  'oifde.apiToken.accessToken.desc': 'محتویات نشان',
  'oifde.apiToken.appKey.desc': 'کلید کاربرد',
  'oifde.apiToken.appSecret.desc': 'راز کاربرد',
  'oifde.qarecord.inputparam.seriesId': 'پرسش و شناسه ي پاسخ',
  'oifde.qarecord.inputparam.questionTitle': 'عنوان پرسش',
  'oifde.qarecord.inputparam.questionType': 'نوع پرسش',
  'oifde.qarecord.inputparam.answerIntent': 'پاسخ دادن به ورودی',
  'oifde.qarecord.inputparam.originalAnswer': 'پاسخ اصلی',
  'oifde.service.invoke.queryMsg.MessageQueryType': 'نوع پرس وجو',
  'oifde.service.invoke.queryMsg.MessageQueryType.desc': 'برنامهٔ اطلاعات صفح: زمانی که فراخوانی به صف مهارت منتقل می شود، فرایندآي وی آر قادر است پرس وجوی اطلاعات باشد.',
  'oifde.service.invoke.queryMsg.skillMsg': 'اطلاعات صف',
  'oifde.service.invoke.queryMsg.skillInfo': 'اطلاعات صف',
  'oifde.service.invoke.queryMsg.specifiedSkill': 'نام صف',
  'oifde.service.invoke.queryMsg.specialListQuery': 'اطلاعات ویژۀ فهرست',
  'oifde.service.invoke.queryMsg.result': 'پاسخ پرس وجو',
  'oifde.service.invoke.queryMsg.MessageQueryQueueIndex': 'موقعیت صف',
  'oifde.service.invoke.queryMsg.MessageQueryOnlineNum': 'تعداد نمایندگان برخط',
  'oifde.service.invoke.queryMsg.MessageIdleAgentNum': 'تعداد نمایندگان بیکار',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime': 'زمان تخمینی انتظار',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime.desc': 'زمان انتظار انتظار برای صف بر اساس مدل صف محاسبه می شود. نتيجه متفاوته لطفا درست ازش استفاده کن',
  'oifde.service.invoke.queryMsg.params': 'پارامتر درخواست',
  'oifde.service.invoke.queryMsg.skillQueue': 'صف مهارت',
  'oifde.service.invoke.queryMsg.agentId': 'کارت شناسايي نماینده',
  'oifde.service.invoke.queryMsg.specialList': 'شناسه ای در فهرست ویژه',
  'oifde.service.invoke.breakMinTime': 'میزان کم کردن زمان پخش صدا',
  'oifde.service.invoke.modelType.customization': 'مقدار متغیر سفارشی',
  'oifde.service.invoke.voiceTtsMixed': 'صدا و متن',
  'oifde.service.invoke.ttsSoundEffectCust': 'جلوه صدای سفارشی',
  'oifde.service.invoke.ttsSoundSpeedCust': 'سرعت سفارشی',
  'oifde.service.invoke.modelType.customization.desc': 'وقفه نشان می دهد که آیا پخش صدا می تواند قطع شود یا خیر. اگر پخش صدا ممکن است قطع شود، حداقل مدت زمان پخش صدا را تنظیم کنید.',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRoutingCode': 'التحويل إلى قائمة انتظار المهارة (تحديد رمز تمديد المسار)',
  'oifde.service.invoke.CallTransferType.transferToIVR': 'انتقال به تلفن گویا',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBySkillName': 'تحويل إلى قائمة انتظار مهارة (اسم محدد)',
  'oifde.service.invoke.CallTransferType.transferToAgentByID': 'نقل إلى موظف',
  'oifde.service.invoke.CallTransferType.transferToThirdPartyNumber': 'به یک شماره شخص ثالث انتقال دهید',
  'oifde.service.invoke.CallTransferType.onlineDigitalAssistant': 'دستیار دیجیتال آنلاین',
  'oifde.service.invoke.CallTransferType.transferToLastAgent': 'نقل إلى ضيف الوسائط المتعددة المألوفة',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBelongAgent': 'تبدیل به صف مهارت) صف مهارتی که عامل چند رسانه ای در آن قرار دارد (',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRouteConfig': 'التحويل إلى قائمة انتظار المهارات (مسار مخصص)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToLastAgent': 'اترك رسالة إلى ضيف مألوف',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToSkillQueue': 'إرسال رسالة إلى قائمة انتظار المهارة (حدد اسمًا أو رمز وصول)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToAgent': 'تحويل رسالة إلى موظف',
  'oifde.service.invoke.CallTransferTypeGroup.multimediaMessage': 'رسائل الوسائط المتعددة',
  'oifde.service.invoke.CallTransferRecordFlag': 'فعال سازی ضبط',
  'oifde.service.invoke.CallTransferRecordFlag.desc': '',
  'oifde.service.invoke.recordPath': 'مسیر ضبط',
  'oifde.service.invoke.recordPath.desc': 'وارد کردن متغیر برای ذخیرۀ نام پروندۀ ضبط.',
  'oifde.service.invoke.recordServiceNodeID': 'شناسه خدمت ضبط',
  'oifde.service.invoke.recordServiceNodeID.desc': 'متغیری را وارد کنید که شناسه گره سرویس ضبط را ذخیره می کند (که در حالت مرکز تماس استفاده می شود).',
  'oifde.service.invoke.languageType.language0': 'پوتونگوا',
  'oifde.service.invoke.languageType.language1': 'انگلیسی',
  'oifde.service.invoke.languageType.language2': 'چینی (کانتونی)',
  'oifde.service.invoke.languageType.language3': 'کشور چک',
  'oifde.service.invoke.languageType.language4': 'دانمارکی',
  'oifde.service.invoke.languageType.language5': 'هلندی',
  'oifde.service.invoke.languageType.language6': 'فنلاندی',
  'oifde.service.invoke.languageType.language7': 'فرانسوی',
  'oifde.service.invoke.languageType.language8': 'آلمانی',
  'oifde.service.invoke.languageType.language9': 'یونانی',
  'oifde.service.invoke.languageType.language10': 'مجارستانی',
  'oifde.service.invoke.languageType.language11': 'ایسلندی',
  'oifde.service.invoke.languageType.language12': 'ایتالیایی',
  'oifde.service.invoke.languageType.language13': 'ژاپنی',
  'oifde.service.invoke.languageType.language14': 'کره ای',
  'oifde.service.invoke.languageType.language15': 'نروژی',
  'oifde.service.invoke.languageType.language16': 'لهستانی',
  'oifde.service.invoke.languageType.language17': 'کره ای',
  'oifde.service.invoke.languageType.language18': 'نروژی',
  'oifde.service.invoke.languageType.language19': 'لهستانی',
  'oifde.service.invoke.languageType.language20': 'پرتغالی',
  'oifde.service.invoke.languageType.language21': 'روسی',
  'oifde.service.invoke.languageType.language22': 'اسلواکی',
  'oifde.service.invoke.languageType.language23': 'اسپانیایی',
  'oifde.service.invoke.languageType.language24': 'سوئدی',
  'oifde.service.invoke.languageType.language80': 'ترکی',
  'oifde.service.invoke.languageType.language81': 'ازبکستان',
  'oifde.service.invoke.languageType.language82': 'تایلند',
  'oifde.service.invoke.languageType.language83': 'کیسواحیلی',
  'oifde.service.invoke.languageType.language84': 'عربی',
  'oifde.service.invoke.languageType.language85': 'هندی',
  'oifde.service.invoke.languageType.language86': 'فیلیپینی',
  'oifde.service.invoke.languageType.language87': 'کامبوج',
  'oifde.service.invoke.languageType.language88': 'بنگالی',
  'oifde.service.invoke.languageType.language89': 'فلسطینی',
  'oifde.service.invoke.languageType.language90': 'ویتنامی',
  'oifde.service.invoke.languageType.language92': 'اندونزیایی',
  'oifde.service.invoke.languageType.language93': 'نپالی',
  'oifde.service.invoke.variableType.type1': 'شماره تلفن',
  'oifde.service.invoke.variableType.type3': 'شماره',
  'oifde.service.invoke.variableType.type4': 'time(hh:mm:ss)',
  'oifde.service.invoke.variableType.type5': 'Date(yyyymmdd)',
  'oifde.service.invoke.variableType.type6': 'قيمت',
  'oifde.basicOdfsSubFlowService.displayName': 'العملية الفرعية (الذكية)',
  'oifde.basicCcivrSubFlowService.displayName': 'العملية الفرعية (عادية)',
  'oifde.yugaoRecordServiceNew.displayName': 'تسجيل التقاط',
  'oifde.conditionServiceNew.displayName': 'الحكم على الفرع',
  'oifde.service.invoke.callTranseferService.transData.wait': 'قائمة الانتظار',
  'oifde.service.invoke.callTranseferService.transData.data': 'نقل البيانات',
  'oifde.service.invoke.callTranseferService.transData.isSpecifyAccessCode': 'رمز الوصول للقناة المحددة',
  'oifde.service.invoke.callTranseferService.transData.type': 'وضع الضيف المألوف',
  'oifde.service.invoke.callTranseferService.transData.time': 'الإطار الزمني (بالدقائق)',
  'oifde.service.invoke.callTranseferService.transData.timeTips': 'على سبيل المثال، 100، نقل إلى الموظف الذي تم الاتصال به مؤخرًا في غضون 100 دقيقة.تتراوح القيمة الموصى بها للوقت من 1 إلى 10080.',
  'oifde.service.invoke.callTranseferService.transData.timeErrorTips': 'أدخل عددًا صحيحًا موجبًا أو متغيرًا',
  'oifde.service.invoke.callTranseferService.transData.starttime': 'نطاق التاريخ',
  'oifde.service.invoke.callTranseferService.transData.starttimeTips': 'الوكلاء الذين تم الاتصال بهم مؤخرًا في نطاق التاريخ المحدد',
  'oifde.service.invoke.callTranseferService.transData.type.selectTime': 'تحديد الدقائق',
  'oifde.service.invoke.callTranseferService.transData.type.selectStartTime': 'تحديد تاريخ',
  'oifde.service.invoke.callTranseferService.transData.type.currentDay': 'في نفس اليوم',
  'oifde.service.invoke.callTranseferService.transData.paramValueTips': "أدخل قيمة معلمة.",
  'oifde.service.invoke.callTranseferService.transData.varTips': " يمكن إدخال المتغيرات. تبدأ المتغيرات العالمية بـ GLOBAL ومتغيرات التدفق ومتغيرات النظام تبدأ بـ SYS ومتغيرات TUC تبدأ بـ TOC ومتغيرات طلب IVR تبدأ بـ IVRREQUEST. استخدم {'${}'} لالتفاف المتغيرات، على سبيل المثال، {'${FLOW.variable}'}.",
  'oifde.service.invoke.ruleValidationService.name': 'التحقق من صحة القاعدة V2',
  'oifde.service.invoke.ruleValidationService.variable': 'المتغيرات المراد التحقق منها',
  'oifde.service.invoke.ruleValidationService.specialEndKey': 'نهاية مفتاح خاص',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option1': 'مع #',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option2': 'مع * علامة',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option3': 'مع علامة # أو *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option4': 'بدون مفاتيح خاصة',
  'oifde.service.invoke.ruleValidationService.containsChar': 'يحتوي على أحرف',
  'oifde.service.invoke.ruleValidationService.containsChar.option1': 'مطابقة الأرقام',
  'oifde.service.invoke.ruleValidationService.containsChar.option2': 'تطابق الأحرف الكبيرة',
  'oifde.service.invoke.ruleValidationService.containsChar.option3': 'مطابقة الحروف الصغيرة',
  'oifde.service.invoke.ruleValidationService.containsChar.option4': 'مطابقة الحروف والأرقام والشرطات السفلية',
  'oifde.service.invoke.ruleValidationService.containsChar.option5': 'حرف فارغ',
  'oifde.service.invoke.ruleValidationService.validationRules': 'قاعدة التحقق',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParam': 'معلمات القاعدة',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParamValueTips': "يمكن إدخال المتغيرات. المتغيرات العامة تبدأ بـ GLOBAL ، ومتغيرات العملية تبدأ بـ FLOW ، ومتغيرات النظام تبدأ بـ SYS ، يبدأ متغير TUC بـ TOC، ويبدأ متغير طلب IVR بـ IVRREQUEST.مثال: FLOW.variable",
  'oifde.service.invoke.ruleValidationService.validationRules.minLength': 'الحد الأدنى للطول',
  'oifde.service.invoke.ruleValidationService.validationRules.minLength.valueTips': 'الرجاء إدخال الحد الأدنى للطول.',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength': 'الحد الأقصى للطول',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength.valueTips': 'الرجاء إدخال الحد الأقصى للطول.',
  'oifde.service.invoke.ruleValidationService.validationRules.rangeLength': 'نطاق الطول',
  'oifde.service.invoke.ruleValidationService.validationRules.min': 'الحد الأدنى للقيمة',
  'oifde.service.invoke.ruleValidationService.validationRules.min.valueTips': 'الرجاء إدخال الحد الأدنى للقيمة.',
  'oifde.service.invoke.ruleValidationService.validationRules.max': 'الحد الأقصى للقيمة',
  'oifde.service.invoke.ruleValidationService.validationRules.max.valueTips': 'الرجاء إدخال القيمة القصوى.',
  'oifde.service.invoke.ruleValidationService.validationRules.range': 'نطاق القيمة',
  'oifde.service.invoke.ruleValidationService.validationRules.email': 'البريد الإلكتروني',
  'oifde.service.invoke.ruleValidationService.validationRules.url': 'عنوان URL',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat': 'تنسيق الوقت',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat.valueTips': 'الرجاء إدخال تنسيق الوقت، مثل التاريخ (yyy-MM-dd) أو التاريخ والوقت (yyy-MM-dd HH:mm:ss).',
  'oifde.service.invoke.ruleValidationService.validationRules.number': 'رقم عشري',
  'oifde.service.invoke.ruleValidationService.validationRules.digits': 'عدد صحيح موجب',
  'oifde.service.invoke.ruleValidationService.validationRules.ip': 'عنوان IP',
  'oifde.service.invoke.ruleValidationService.validationRules.alpha': 'شرطة سفلية أبجدية رقمية (لا تبدأ برقم)',
  'oifde.service.invokemethod.offsetMonth.desc': 'الإزاحة حسب الشهر',
  'oifde.service.invokemethod.offsetMonth.parm1.desc': 'متغير الوقت المراد إزاحةه ، نوع السلسلة ، التنسيق هو yyyyMM',
  'oifde.service.invokemethod.offsetMonth.parm2.desc': 'عدد الإزاحة ، عدد صحيح ، يمكن أن يكون قيمة سالبة',
  'oifde.service.invokemethod.offsetMonth.resultObj.desc':'إرجاع البيانات بعد إزاحة الكائن.',
  'oifde.service.invokemethod.offsetHour.desc': 'الإزاحة بالساعة',
  'oifde.service.invokemethod.offsetHour.parm1.desc':'متغير الوقت المراد إزاحةه. نوع السلسلة بتنسيق yyyyMMdHmmss',
  'oifde.service.invokemethod.offsetHour.parm2.desc':' عدد الإزاحة، عدد صحيح، يمكن أن تكون قيمة سالبة',
  'oifde.service.invokemethod.offsetHour.resultObj.desc':'إرجاع البيانات بعد إزاحة الكائن.' ,
  'oifde.interfaceInvoke.displayName': 'استدعاء الواجهة',
  'oifde.service.invoke.yugaoRecord.type': 'النوع:',
  'oifde.service.invoke.yugaoRecord.mode': 'الوضع:',
  'oifde.service.invoke.subflow.subFlowNode': 'العملية الفرعية',
  'oifde.service.invokemethod.group.mathodType4': 'طريقة عمل التاريخ',
  'oifde.service.invoke.basicAvatarReplyService.name': 'رد الصورة الرمزية',
  'oifde.service.invoke.basicAvatarReplyService.actionType': 'العملية',
  'oifde.service.invoke.basicAvatarReplyService.start': 'بدء',
  'oifde.service.invoke.basicAvatarReplyService.image': 'صورة',
  'oifde.service.invoke.basicAvatarReplyService.avatarImage': 'الصورة الرمزية',
  'oifde.service.invoke.basicAvatarReplyService.ttsSpeaker': 'مكبر الصوت',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting': 'تكوين الفيديو المركب',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting.desc': 'تكوين الفيديو المركب، والذي يؤثر بشكل مباشر على تأثير عرض الشخص الرقمي. قم بتمكين هذه الوظيفة عند الحاجة.',
  'oifde.service.invoke.basicAvatarReplyService.imageSetting': 'إعدادات الصورة والفيديو',
  'oifde.service.invoke.basicAvatarReplyService.action':'الإجراءات',
  'oifde.service.invoke.basicAvatarReplyService.frontImage': 'الصورة الأمامية',
  'oifde.service.invoke.basicAvatarReplyService.backendImage': 'الصورة الخلفية',
  'oifde.service.invoke.basicAvatarReplyService.frontVideo': 'الفيديو الأمامي',
  'oifde.service.invoke.basicAvatarReplyService.backendVideo': 'الفيديو الخلفي',
  'oifde.service.invoke.basicAvatarReplyService.xPosition': 'أبسيسسا',
  'oifde.service.invoke.basicAvatarReplyService.yPosition': 'ترتيب',
  'oifde.service.invoke.basicAvatarReplyService.scale': 'مقياس',
  'oifde.service.invoke.basicAvatarReplyService.beginTime': 'عرض وقت البدء (مللي ثانية)',
  'oifde.service.invoke.basicAvatarReplyService.duration': 'مدة العرض (مللي ثانية)',
  'oifde.service.invoke.businessVirtualHumanService.name': 'پاسخ شخصی مجازی',
  'service.invoke.virtualHumanConfig': 'پیکربندی مجازی انسان',
  'service.virtualHuman.videoParam': 'پارامترهای ویرایش ویدئو',
  'service.virtualHuman.captions': 'زیرنویسها',
  'service.invoke.asrAdvancedSetting.open': 'به کار انداختن',
  'service.invoke.asrAdvancedSetting.close': 'غیرفعال سازی',
}
