import taskElement from "@/views/ccmanagement/pages/iolp-diagram/iolpElementInfo/TaskElement.vue";

export const customNodeMap = {
  taskElement: {
    component: taskElement,
    data: {
      title: 'taskElement',
      nodeType: 'task'
    },
    outgoingEdges: 1
  },
  decisionElement: {
    component: taskElement,
    data: {
      title: 'decisionElement',
      nodeType: 'decision'
    }
  },
}
