export default {
  "oifde.index.title": "ODFS — Service de flux de dialogue en ligne",
  "oifde.common.lang": "Langue",
  'oifde.common.noData': 'Pas de données',
  "oifde.common.language.chinese": "Chinois",
  "oifde.common.language.english": "Français",
  "oifde.common.time.startDate": "Date de début",
  "oifde.common.time.endDate": "Date de fin",
  "oifde.common.selectPlaceHolder": "Saisissez un mot-clé.",
  "oifde.common.browserSupporteMsg": "Le navigateur ne supporte pas cette fonction.",
  "oifde.common.validation.required": "Ce champ est obligatoire",
  "oifde.common.validation.extendFaqRange": "Seuls les caractères chinois lettres anglaises chiffres espaces et les caractères spéciaux suivants sont autorisés",
  "oifde.common.validation.nameRange": "Les caractères spéciaux suivants ne sont pas autorisés",
  "oifde.common.validation.cellNameRange": 'Non pris en charge: < > {\'@\'} # {\'$\'} % ^ * ; " ~ / \\',
  "oifde.common.validation.nameNoSpace": "La valeur ne peut pas commencer ou se terminer par un espace.",
  "oifde.common.validation.openNameCharReg": "Seuls les caractères chinois lettres anglaises chiffres espaces souligne (_) traits d'union (-) et guillemets simples (\\) sont autorisés.",
  "oifde.common.validation.kgNameCharReg": "Seuls les caractères chinois les espaces des lettres anglaises et les caractères spéciaux suivants sont autorisés",
  "oifde.common.validation.nameCharRegNotChinese": "La valeur ne peut contenir que des lettres anglaises, des chiffres de (_) de soulignement et des traits d'union.",
  "oifde.common.validation.nameCharReg": "La valeur ne peut contenir que des caractères chinois Lettres anglaises chiffres (_) traits de soulignement et traits d'union (-).",
  "oifde.common.validation.schemaNameCharReg": "Seuls les caractères chinois lettres anglaises chiffres espaces souligne (_) et guillemets simples (\\) sont autorisés.",
  "oifde.common.validation.schemaRelationNameCharReg": "Seuls les caractères chinois lettres anglaises chiffres espaces et guillemets simples (\\) sont autorisés.",
  "oifde.common.validation.charRegWithDot": "La valeur ne peut contenir que des caractères chinois Lettres anglaises chiffres points (.) souligne (_) et traits d'union (-).",
  "oifde.common.validation.int": "La valeur doit être un entier compris entre 0 et 2147483647.",
  "oifde.common.validation.min": "La valeur ne peut pas être inférieure à {0}.",
  "oifde.common.validation.max": "La valeur ne peut pas être supérieure à {0}.",
  "oifde.common.validation.collectTimeoutRule": "Les variables FLOW et GLOBAL sont prises en charge. La valeur peut aussi être un entier compris entre 1 et 120. Si la valeur ne répond pas aux exigences, la valeur par défaut 5 est utilisée.",
  "oifde.common.validation.numberRange": "La plage de valeurs va de {0} à {1}.",
  "oifde.common.validation.sessionDTRange": "L'intervalle de temps d'attente du flux de dialogue varie de {0} à {1} secondes.",
  "oifde.common.validation.slientHelpRange": "Le temps d'attente d'une réponse après qu'un agent est silencieux varie de {0} à {1} secondes.",
  "oifde.common.validation.confidenceRange": "Le seuil de confiance varie de {0} à {1}.",
  "oifde.common.validation.flowSaveIntervalRange": "L'intervalle de sauvegarde de l'orchestration du flux IVR est un entier positif. La valeur ne peut pas être inférieure à {0} minutes et ne peut pas être supérieure à {1} minutes",
  "oifde.common.validation.urlValidate": "Le format de l'URL est incorrect.",
  "oifde.common.validation.appSecretLenCheck": "La clé partagée doit contenir de 8 à 20 caractères.",
  "oifde.common.validation.positiveInt": "La valeur doit être un entier positif.",
  "oifde.common.validation.digits": "La valeur doit être 0 ou un entier positif",
  "oifde.common.validation.numbers": "Veuillez n'entrer que des chiffres.",
  "oifde.common.validation.nodeNameCharReg": "La valeur ne peut contenir que des caractères chinois Lettres anglaises chiffres (_) traits de soulignement et traits d'union (-).",
  "oifde.common.validation.validateFalse": "La valeur ne peut pas contenir les caractères spéciaux suivants",
  "oifde.common.validation.mrcpIdValueFalse": "Entrez un nombre entier positif allant de 0 à 255.",
  "oifde.common.validation.flowMaxElementsRange": "L'intervalle entre les nœuds de flux IVR varie de {0} à {1}.",
  "oifde.common.dialog.confirm.title": "Confirmer",
  "oifde.common.dialog.confirm.content": "Cette opération supprimera définitivement les données. êtes-vous sûr de vouloir continuer ?",
  "oifde.common.dialog.confirm.buttonSure": "Oui",
  "oifde.common.dialog.confirm.buttonCancel": "Non",
  "oifde.common.dialog.confirm.successInfo": "Les données sont supprimées avec succès.",
  "oifde.common.dialog.confirm.cancelInfo": "La suppression est annulée.",
  "oifde.common.dialog.confirm.operationSucc": "L'opération est effectuée avec succès.",
  "oifde.common.dialog.confirm.operation_failed": "Impossible d'effectuer l'opération.",
  "oifde.common.actions.add": "Ajouter",
  "oifde.common.actions.edit": "Modifier",
  "oifde.common.actions.delete": "Supprimer",
  "oifde.common.actions.download": "Téléchargement",
  "oifde.common.help": "Afficher les informations d'aide.",
  "oifde.common.select": "-Sélectionner-",
  "oifde.common.detail": "Détails",
  "oifde.serviceInterface.oifdeInterface": "Interface Robot Intelligente",
  "oifde.serviceInterface.ccivrInterface": "Interface IVR commune",
  "oifde.serviceInterface.selfDefinedInterface": "Interface auto-définie",
  "oifde.serviceInterface.innerInterface": "Interface intérieure",
  "oifde.serviceInterface.thirdAPILog": "Interface invoquant des enregistrements",
  "oifde.serviceInterface.accessCode": "Code d'accès",
  "oifde.serviceInterface.callId": "ID d'instance d'appel",
  "oifde.serviceInterface.result": "Résultat",
  "oifde.serviceInterface.statusCode": "Interface invoquant le code d'état",
  "oifde.serviceInterface.invokingTime": "Interface invoquant l'heure",
  "oifde.serviceInterface.invokingDetails": "Invoquer les détails",
  "oifde.serviceInterface.request": "Paramètre requis",
  "oifde.serviceInterface.selectAccessCode": "Sélectionnez A Robot Access Code.",
  "oifde.serviceInterface.timeIntervalExceed": "L'intervalle entre l'heure de début et l'heure de fin ne peut pas dépasser 7 jours.",
  "oifde.serviceInterface.timeMandatory": "L'heure de début et l'heure de fin sont obligatoires.",
  "oifde.serviceInterface.jsonValidateSuccess": "Le format est correct.",
  "oifde.serviceInterface.jsonChek": "Vérifier JSON",
  "oifde.serviceInterface.interfaceCert": "Certificat d'interface",
  "oifde.serviceInterface.certCode": "Code",
  "oifde.serviceInterface.certScene": "Scénario",
  "oifde.serviceInterface.expireTime": "Expiré",
  "oifde.serviceInterface.certUpdateTime": "Mise à jour",
  "oifde.serviceInterface.jsonValidate": "Le corps du message n'est pas conforme au format JSON standard.",
  "oifde.serviceInterface.safeWarning": "Un protocole réseau non sécurisé est spécifié pour l'interface.",
  "oifde.serviceInterface.whiteListCheckWarning": "L'URL (adresse IP et numéro de port) ne figure pas dans la liste de confiance et ne peut être utilisée qu'après avoir contacté l'administrateur système pour l'ajouter à la liste de confiance.",
  "oifde.serviceInterface.interfaceName": "Nom de l'interface",
  "oifde.serviceInterface.bizCode": "Code d'entreprise",
  "oifde.serviceInterface.description": "Descriptif",
  "oifde.serviceInterface.updatetime": "Mise à jour",
  "oifde.serviceInterface.operation": "Opération",
  "oifde.serviceInterface.addServiceInterface": "Ajouter Configuration de l'interface métier",
  "oifde.serviceInterface.modifyServiceInterface": "Modifier la configuration de l'interface d'entreprise",
  "oifde.serviceInterface.serviceInterfaceDetails": "Détails de configuration de l'interface d'affaires",
  "oifde.serviceInterface.innerInterfaceDetails": "Détails de l'interface intérieure",
  "oifde.serviceInterface.baseInfo": "Informations de base",
  "oifde.serviceInterface.reqURL": "URL de la demande",
  "oifde.serviceInterface.signBody": "Signer",
  "oifde.serviceInterface.signBodyDetail.no": "Non",
  "oifde.serviceInterface.signBodyDetail.yes": "Oui",
  "oifde.serviceInterface.signDescription": "Indique s'il faut vérifier la signature pour un tiers lorsque la signature est transférée au tiers en tant que paramètre d'interface.",
  "oifde.serviceInterface.appSecret": "Clé partagée",
  "oifde.serviceInterface.inputAppSecret": "Entrez la clé partagée.",
  "oifde.serviceInterface.busiCode": "Code d'interface",
  "oifde.serviceInterface.interfaceType": "Méthode de demande",
  "oifde.serviceInterface.required": "Ce champ est obligatoire.",
  "oifde.serviceInterface.query_param": "Paramètre de requête",
  "oifde.serviceInterface.pathParamValue": "Valeur",
  "oifde.serviceInterface.outParamPath": "Chemin",
  "oifde.serviceInterface.in_param": "Paramètre d'entrée",
  "oifde.serviceInterface.out_param": "Paramètre de sortie",
  "oifde.serviceInterface.name": "Nom et prénom",
  "oifde.serviceInterface.dataType": "Type de données",
  "oifde.serviceInterface.encryptFlag": "Chiffré",
  "oifde.serviceInterface.dataTypes.INT": "Entier",
  "oifde.serviceInterface.dataTypes.STRING": "Caractère",
  "oifde.serviceInterface.dataTypes.FLOAT": "Nombre à virgule flottante",
  "oifde.serviceInterface.dataTypes.LONG": "Long entier",
  "oifde.serviceInterface.dataTypes.LIST": "Liste",
  "oifde.serviceInterface.dataTypes.OBJECT": "Objet",
  "oifde.serviceInterface.msgHeaders": "En-tête de message",
  "oifde.serviceInterface.msgBody": "Corps du message",
  "oifde.serviceInterface.headerKey": "Clé",
  "oifde.serviceInterface.headerValue": "Valeur",
  "oifde.serviceInterface.deleteConfirm": "Cette opération supprimera définitivement l'interface métier. êtes-vous sûr de vouloir continuer ?",
  "oifde.serviceInterface.miss_ifs_name": "Le champ obligatoire suivant est vide",
  "oifde.serviceInterface.prompt": "Confirmer",
  "oifde.serviceInterface.ok": "OK",
  "oifde.serviceInterface.success": "Succès",
  "oifde.serviceInterface.fail": "échec",
  "oifde.serviceInterface.miss_ifs_request_url": "Le champ obligatoire suivant est vide",
  "oifde.serviceInterface.miss_ifs_in_parameter_name": "Le champ obligatoire suivant est vide",
  "oifde.serviceInterface.miss_ifs_out_parameter_name": "Le champ obligatoire suivant est vide",
  "oifde.serviceInterface.miss_ifs_out_parameter_type": "Le champ obligatoire suivant est vide",
  "oifde.serviceInterface.miss_ifs_header_key": "Le champ obligatoire suivant est vide",
  "oifde.serviceInterface.miss_ifs_msgBody": "Le champ obligatoire suivant est vide",
  "oifde.serviceInterface.auto_msgBody": "Corps de message automatique",
  "oifde.serviceInterface.manual_msgBody": "Corps du message manuel",
  "oifde.serviceInterface.promptMessage": "Entrez un corps de message.",
  "oifde.serviceInterface.actions.cancelSuccess": "Enregistrement annulé!",
  "oifde.serviceInterface.actions.auto_savePrompt": "Cette opération enregistrera le corps du message automatique comme configuration de l'interface de service. êtes-vous sûr de vouloir continuer ?",
  "oifde.serviceInterface.actions.manual_savePrompt": "Cette opération enregistrera le corps du message manuel comme configuration de l'interface de service. êtes-vous sûr de vouloir continuer ?",
  "oifde.serviceInterface.actions.urlNotInWhitelist": "L'URL (adresse IP et numéro de port) n'est pas dans la liste de confiance. Il ne peut être utilisé qu'après avoir contacté l'administrateur système pour ajouter l'URL à la liste de confiance. Continuer ?",
  "oifde.serviceInterface.actions.prompt": "Confirmer",
  "oifde.serviceInterface.actions.confirm": "Oui",
  "oifde.serviceInterface.actions.cancel": "Non",
  "oifde.qarecord.interface.name": "Enregistrer Q&A",
  "oifde.qarecord.interface.desc": "Enregistre le titre de la question de l'ID du questionnaire l'intention de la réponse de l'utilisateur et la réponse originale de l'utilisateur.",
  'oifde.apiToken.interface.name':'Obtenir le jeton APIFabric',
  'oifde.apiToken.interface.desc':'Interface utilisée pour obtenir le jeton API Fabric',
  'oifde.apiToken.applyType.desc': 'Token prefix',
  'oifde.apiToken.accessToken.desc': 'Contenu du token',
  'oifde.apiToken.appKey.desc': 'App Key',
  'oifde.apiToken.appSecret.desc': 'Secret d\'application',
  "oifde.qarecord.inputparam.seriesId": "ID de question et réponse",
  "oifde.qarecord.inputparam.questionTitle": "Titre de la question",
  "oifde.qarecord.inputparam.questionType": "Type de question",
  "oifde.qarecord.inputparam.answerIntent": "Intention de réponse",
  "oifde.qarecord.inputparam.originalAnswer": "Réponse originale",
  "oifde.sysMgr.sysCfg": "Paramètres du système",
  "oifde.sysMgr.apiKeyCfg": "Paramètres de mot de passe API",
  "oifde.sysMgr.configuration": "Définir",
  "oifde.sysMgr.apiKey": "Mot de passe API",
  "oifde.sysMgr.apiKeyComfirm": "Confirmer le mot de passe de l'API",
  "oifde.sysMgr.apiKeyNotSame": "Les mots de passe de l'API ne correspondent pas.",
  "oifde.sysMgr.inputKey": "Entrez un mot de passe API.",
  "oifde.sysMgr.cancel": "Annuler",
  "oifde.sysMgr.save": "Enregistrer",
  "oifde.sysMgr.apiRuleRange": "Le mot de passe doit contenir au moins deux types de",
  "oifde.sysMgr.success_set_key": "Le mot de passe de l'API est défini avec succès!",
  "oifde.sysMgr.requiredAPIKey": "Entrez un mot de passe API.",
  "oifde.sysMgr.APIKeyLenCheck": "Le mot de passe de l'API doit contenir de 16 à 64 caractères.",
  "oifde.sysMgr.success_set_rpa": "Le RPA ACCESS INFO est défini avec succès!",
  "oifde.sysMgr.requiredRpaUserName": "Le compte d'utilisateur RPA est requis.",
  "oifde.sysMgr.requiredRpaSecretKey": "La clé secrète RPA est requise.",
  "oifde.sysMgr.rpaUserNameLenCheck": "Le compte utilisateur RPA ne peut pas contenir plus de 64 caractères.",
  "oifde.sysMgr.rpaSecretKeyLenCheck": "La clé secrète RPA ne peut pas contenir plus de 128 caractères.",
  "oifde.sysMgr.inputRpaUserName": "Entrez le nom d'utilisateur RPA.",
  "oifde.sysMgr.inputRpaSecretKey": "Entrez RPA Secret Key.",
  "oifde.sysMgr.dataClean.setTime": "Définit la durée d'effacement des données.",
  "oifde.sysMgr.dataClean.serviceName": "Nom du paramètre",
  "oifde.sysMgr.dataClean.paramValue": "Valeur",
  "oifde.sysMgr.dataClean.description": "Descriptif",
  "oifde.sysMgr.dataClean.descriptionContent": "Intervalle auquel le nettoyage des données est effectué.",
  "oifde.sysMgr.dataClean.operation": "Opération",
  "oifde.sysMgr.dataClean.editTime": "Modifier l'intervalle de nettoyage des données",
  "oifde.sysMgr.dataClean.day": "jours",
  "oifde.sysMgr.dataClean.hour": "heure(s)",
  "oifde.sysMgr.dataClean.minute": "minute(s)",
  "oifde.sysMgr.dataClean.flagDescription": "Activer ou non l'enregistrement dans un flux d'appels IVR intelligent.",
  "oifde.sysMgr.dataClean.editFlag": "Modifier l'enregistrement dans le flux d'appels IVR intelligent",
  "oifde.sysMgr.dataClean.recordThirdApi": "Indique s'il faut activer l'interface tierce invoquant le journal",
  "oifde.sysMgr.dataClean.editInterfaceFlag": "Modifier le journal d'appel de l'interface tiers",
  "oifde.sysMgr.dataClean.ccvirrecordFlag": "Activer ou non l'enregistrement dans un flux d'appels IVR commun.",
  "oifde.sysMgr.dataClean.editCcvirrecordFlag": "Modifier l'enregistrement dans le flux d'appels IVR commun",
  "oifde.sysMgr.dataClean.open": "Activer",
  "oifde.sysMgr.dataClean.close": "Désactiver",
  "oifde.sysMgr.dataClean.record": "Enregistrement",
  "oifde.sysMgr.dataClean.noRecord": "Ne pas enregistrer",
  "oifde.sysMgr.dataClean.second": "secondes",
  "oifde.sysMgr.dataClean.reportFlagDesc": "Indique s'il faut enregistrer les cas de test dans un rapport.",
  "oifde.sysMgr.dataClean.reportFlagTitle": "Modifier l'enregistrement du cas de test",
  "oifde.sysMgr.dataClean.sessionDTDesc": "Intervalle de temporisation pour les flux de dialogue.",
  "oifde.sysMgr.dataClean.editSessionDT": "Intervalle de temporisation de flux Modifier la boîte de dialogue",
  "oifde.sysMgr.dataClean.slientHelpDesc": "Il est temps d'attendre une réponse après qu'un agent soit silencieux.",
  "oifde.sysMgr.dataClean.confidenceDesc": "Seuil de confiance pour qu'un agent silencieux reconnaisse une seule demande d'aide à partir d'un chatbot.",
  "oifde.sysMgr.dataClean.traceLogAutoCloseDesc": "Désactivation automatique du temps pour le suivi.",
  "oifde.sysMgr.dataClean.editSlientHelp": "Modifier le délai d'attente de la réponse après le silence de l'agent",
  "oifde.sysMgr.dataClean.confidence": "Modifier la confiance",
  "oifde.sysMgr.dataClean.traceLogAutoClose": "Modifier le temps de désactivation automatique pour le suivi",
  "oifde.sysMgr.dataClean.flowIvrSaveFlagDesc": "Indique s'il faut activer la fonction de sauvegarde programmée pour l'orchestration du processus IVR",
  "oifde.sysMgr.dataClean.editFlowSaveFlag": "Modification de l'indicateur d'enregistrement planifié de l'orchestration du processus IVR",
  "oifde.sysMgr.dataClean.flowIvrSaveIntervalDesc": "Intervalle de sauvegarde de l'orchestration du flux IVR",
  "oifde.sysMgr.dataClean.editFlowSaveInterval": "Modification de l'intervalle de sauvegarde de l'orchestration du flux IVR",
  "oifde.sysMgr.dataClean.flowMaxElementsDesc": "Nombre maximum de nœuds pris en charge par le flux IVR",
  "oifde.sysMgr.dataClean.editFlowMaxElements": "Modifier le nombre maximum de nœuds pris en charge par le flux IVR",
  "oifde.sysMgr.dataClean.tcaContent": "Liste des domaines liés à l'analyse de texte",
  "oifde.sysMgr.dataClean.editTcaFlag": "Modifier la liste des royaumes liés à l'analyse de texte",
  "oifde.sysMgr.dataClean.paramValueTca": "Valeur du paramètre",
  "oifde.sysMgr.encryptSecretCfg": "Paramètres clés",
  "oifde.sysMgr.encryptSecret": "Clé",
  "oifde.sysMgr.encryptSecretComfirm": "Confirmer la clé",
  "oifde.sysMgr.encryptSecretNotSame": "Les clés ne correspondent pas.",
  "oifde.sysMgr.inputEncryptSecret": "Entrez une clé.",
  "oifde.sysMgr.success_set_encryptSecret": "La clé est réglée avec succès.",
  "oifde.sysMgr.encryptSecretRuleRange": "Le secret de chiffrement ne répond pas aux exigences. Il doit contenir au moins deux types de",
  "oifde.sysMgr.encryptSecretNoSame": "La clé ne peut pas être identique à l'identifiant de l'espace locataire.",
  "oifde.sysMgr.encryptSecretLenCheck": "La clé doit contenir 16 caractères.",
  "oifde.sysMgr.parameterSetting": "Paramètres intelligents des paramètres du moteur",
  "oifde.sysMgr.apiNoSame": "Le mot de passe ne peut pas être le même que l'identifiant de l'espace locataire.",
  "oifde.sysMgr.variableSecretNotExists": "La clé est obligatoire lorsque vous utilisez une variable chiffrée. Choisissez Gestion du système > Configuration du système et cliquez sur Paramètres clés pour définir la clé.",
  "oifde.sysMgr.rpaCfg": "Paramètres RPA",
  "oifde.sysMgr.rpaUserNameCfg": "Compte utilisateur RPA",
  "oifde.sysMgr.rpaSecretKeyCfg": "Clé secrète RPA",
  "oifde.route.flow": "Orchestration",
  "oifde.route.rule": "Règle de gris de flux",
  "oifde.route.trace": "Suivi de flux",
  "oifde.route.flowAnalysis": "Analyse de flux",
  'oifde.route.ivrOverview': 'Vue d\'ensemble de l\'IVR',
  'oifde.route.indicatorAnalysis': 'Analyse des indicateurs',
  'oifde.route.versionComparisonAndAnalysis': 'Comparaison et analyse des versions',
  'oifde.route.optimizationSuggestions': 'Suggestions d\'optimisation',
  'oifde.route.alarmEvent': 'Événement d\'alarme',
  "oifde.route.addDiagram": "Ajouter un flux",
  "oifde.route.editCellDiagram": "Modifier la toile composite",
  "oifde.route.edit": "Modifier {0}",
  "oifde.route.view": "Voir {0}",
  "oifde.route.editDiagram": "Modifier le flux",
  "oifde.route.viewDiagram": "Afficher le flux",
  "oifde.route.viewTraceDiagram": "Afficher le suivi de flux",
  "oifde.route.viewCellTraceDiagram": "Afficher le suivi des éléments de diagramme composite",
  "oifde.route.voiceTemplateTitle": "Modèles",
  "oifde.route.staticCheck": "Contrôle de flux",
  "oifde.route.toc.sysadmin": "Compréhension sémantique",
  "oifde.route.toc.user": "Compréhension sémantique",
  "oifde.route.tuc.template.index": "Gestion des connaissances",
  "oifde.route.tuc.template.domain": "Gestion de Domaine",
  "oifde.route.tuc.template.intent": "Gestion des intentions",
  "oifde.route.tuc.template.entity": "Gestion des entités",
  "oifde.route.tuc.template.faq": "Gestion des FAQ",
  "oifde.route.tuc.template.kg": "Gestion des graphiques",
  "oifde.route.tuc.template.config": "Configuration du système",
  "oifde.route.tuc.template.bwlist": "Liste de blocage et liste de confiance",
  "oifde.route.tuc.history.index": "Boîte de dialogue historique",
  "oifde.route.tuc.history.dialog": "Examen des messages historiques",
  "oifde.route.tuc.history.mining": "Question Clustering Tache",
  "oifde.route.tuc.ops.index": "Vérification de la formation",
  "oifde.route.tuc.ops.test": "Test de boîte dialogue",
  "oifde.route.tuc.ops.tucTest": "Test de connaissances",
  "oifde.route.tuc.ops.perf": "Statistiques de performance",
  "oifde.route.tuc.ops.node": "Maintenance",
  "oifde.route.tuc.ops.log": "Journal des opérations",
  "oifde.route.tuc.ops.corpustest": "Test Corpus",
  "oifde.route.tuc.ops.server": "Serveurs",
  "oifde.route.tuc.ops.qiAsrServer": "Inspection ASR",
  "oifde.route.tuc.ops.asr": "TTS/ASR",
  'oifde.route.tuc.ops.avatar': 'Homme virtuel 2D',
  "oifde.route.tuc.ops.modelMgr": "Gestion des modèles",
  "oifde.route.tuc.ops.sysparam": "Paramètre de moteur intelligent",
  "oifde.route.tuc.ops.kgrule": "Règle de gris des connaissances",
  "oifde.route.tuc.ops.license": "Licences",
  "oifde.route.keyPressRobot": "RVI commun",
  "oifde.route.RobotManage": "Chatbot intelligent",
  "oifde.route.accessCodeManage": "Code d'accès",
  "oifde.route.sysMgr.sysMgr": "Système",
  "oifde.route.sysMgr.sysCfg": "Configuration",
  "oifde.route.sysMgr.whiteList": "Liste blanche",
  "oifde.route.sysMgr.recordHistory": "Appel historique",
  "oifde.route.navigation": "Flux",
  "oifde.route.resources": "Ressources",
  "oifde.route.serviceInterface": "Interface d'affaires",
  "oifde.route.variable": "Variable",
  "oifde.route.cell": "Cellule complexe",
  "oifde.route.procedure": "Procédure stockée",
  'oifde.route.dataTable': 'Fiche technique',
  "oifde.route.unusedResource.template": "Afficher les modèles inutilisés",
  "oifde.route.unusedResource.globalvars": "Afficher les variables globales inutilisées",
  "oifde.route.unusedResource.flowvars": "Afficher les variables de débit inutilisées",
  "oifde.route.unusedResource.complexcell": "Afficher les éléments de diagramme composite inutilisés",
  "oifde.route.unusedResource.interface": "Afficher les interfaces inutilisées",
  "oifde.route.unusedResource.procedure": "Afficher les procédures stockées inutilisées",
  "oifde.route.unusedResource.rule": "Afficher les règles grises inutilisées",
  "oifde.route.knowledgegraph.knowledgegraph": "Graphique de connaissances",
  "oifde.route.knowledgegraph.kgModel": "Schéma de connaissances",
  "oifde.route.knowledgegraph.editSchema": "Détails du schéma {0}",
  "oifde.route.knowledgegraph.dicmgr": "Dictionnaire graphique",
  "oifde.route.knowledgegraph.entitymgr": "Gestion des entités pour {0}",
  "oifde.route.knowledgegraph.entityAttribute": "Attribut d'entité pour {0}",
  "oifde.route.knowledgegraph.relationmgr": "Gestion des relations pour {0}",
  "oifde.route.knowledgegraph.relationAttribute": "Attribut de relation pour {0}",
  "oifde.route.knowledgegraph.kgView": "Visualisation graphique pour {0}",
  "oifde.route.knowledgegraph.kgExport": "Exportation de graphiques",
  "oifde.route.knowledgegraph.importExcel": "Importation de graphiques",
  "oifde.route.knowledgegraph.kgFAQ": "FAQ graphique pour {0}",
  "oifde.route.knowledgegraph.kgSynonyms": "Synonyme de {0}",
  "oifde.route.knowledgegraph.extendQuestion": "Question étendue",
  "oifde.flow.code": "Code",
  "oifde.flow.name": "Nom et prénom",
  "oifde.flow.version": "Version",
  "oifde.flow.scenarioType": "Type de scénario",
  "oifde.flow.status": "Statut",
  "oifde.flow.rule": "Règle grise",
  "oifde.flow.createTime": "Créé",
  "oifde.flow.updateTime": "Modifié",
  "oifde.flow.grayFlow": "Gris",
  "oifde.flow.noRule": "Aucune règle configurée",
  "oifde.flow.defaultFlow": "Flux par défaut",
  "oifde.flow.actions.publish": "Publier",
  "oifde.flow.actions.delete": "Supprimer",
  "oifde.flow.actions.edit": "Modifier",
  "oifde.flow.actions.invalid": "Invalider",
  "oifde.flow.actions.upgrade": "Mise à niveau",
  "oifde.flow.actions.setting": "Définir",
  "oifde.flow.actions.copy": "Flux de copie",
  "oifde.flow.actions.copySuccess": "Le flux est copié avec succès.",
  "oifde.flow.actions.copyFailed": "Impossible de copier le flux.",
  "oifde.flow.actions.deleteConfirm": "Cette opération supprimera définitivement le flux. êtes-vous sûr de vouloir continuer ?",
  "oifde.flow.actions.deleteCancel": "La suppression est annulée.",
  "oifde.flow.actions.deleteOk": "Le flux est supprimé avec succès.",
  "oifde.flow.actions.invalidConfirm": "Cette opération apportera le flux non libéré. êtes-vous sûr de vouloir continuer ?",
  "oifde.flow.actions.invalidOk": "Le flux a non publié.",
  "oifde.flow.actions.invalidCancel": "L'invalidation est annulée.",
  "oifde.flow.actions.createOk": "Une nouvelle version du flux est créée avec succès.",
  "oifde.flow.actions.settingOk": "Le flux est configuré avec succès.",
  "oifde.flow.actions.publishOk": "Le flux est publié avec succès.",
  "oifde.flow.actions.confirm": "Oui",
  "oifde.flow.actions.prompt": "Confirmer",
  "oifde.flow.actions.cancel": "Non",
  "oifde.flow.actions.error": "L'opération a échoué.",
  "oifde.flow.actions.errorCode": "Code d'erreur",
  "oifde.flow.actions.errorMessage": "Message d'erreur",
  "oifde.flow.actions.staticcheck": "Contrôle statique",
  "oifde.flow.rules.ruleId": "Sélectionnez une règle.",
  "oifde.flow.rules.upgradeMode": "Sélectionnez un mode de publication.",
  "oifde.flow.rules.logSwitchOn": "Sélectionnez si vous souhaitez activer la fonction de journalisation.",
  "oifde.flow.rules.validationMode": "Sélectionnez si vous souhaitez activer la validation stricte.",
  "oifde.flow.rules.newVersion": "Spécifiez une nouvelle version.",
  "oifde.flow.rules.newVersionDuplicated": "La nouvelle version doit être unique.",
  "oifde.flow.rules.newVersionLength": "La nouvelle version ne peut pas contenir plus de 64 caractères.",
  "oifde.flow.rules.newFlowName": "Spécifiez un nouveau nom de flux.",
  "oifde.flow.rules.workMode": "Sélectionnez un mode de travail.",
  "oifde.flow.enums.scenarioType.mainFlow": "Flux principal",
  "oifde.flow.enums.scenarioType.errorFlow": "Débit d'exception",
  "oifde.flow.enums.scenarioType.subFlow": "Sous-flux",
  "oifde.flow.enums.status.init": "ébauche",
  "oifde.flow.enums.status.valid": "Publié",
  "oifde.flow.enums.status.invalid": "Invalidé",
  "oifde.flow.enums.tabsValue.all": "Tout exporter",
  "oifde.flow.enums.tabsValue.none": "Ne pas exporter",
  "oifde.flow.enums.tabsValue.depend": "Dépendances d'exportation",
  "oifde.flow.publish.flowName": "Nom et prénom",
  "oifde.flow.publish.flowVersion": "Version",
  "oifde.flow.publish.upgradeMode": "Mode Publier",
  "oifde.flow.publish.upgrade": "Mise à jour et publication",
  "oifde.flow.publish.gray": "Libération grise",
  "oifde.flow.publish.ruleName": "Règle grise",
  "oifde.flow.publish.selectPlaceholder": "-Sélectionner-",
  "oifde.flow.publish.publishMode": "Mode de travail",
  "oifde.flow.publish.normal": "Fréquent",
  "oifde.flow.publish.monitor": "Moniteur",
  "oifde.flow.publish.insert": "Insérer",
  "oifde.flow.publish.notConfig": "Non configuré",
  "oifde.flow.copy.flowName": "Nom d'écoulement original",
  "oifde.flow.copy.newFlowName": "Nouveau nom de flux",
  "oifde.flow.setting.logSwitch": "Fonction de journal",
  "oifde.flow.setting.logSwitchOn": "Activer",
  "oifde.flow.setting.logSwitchOff": "Désactiver",
  "oifde.flow.setting.title": "Modifier",
  "oifde.flow.setting.validationMode": "Définir le mode de validation",
  "oifde.flow.setting.validationModeOn": "Activer",
  "oifde.flow.setting.validationModeOff": "Désactiver",
  "oifde.flow.upgrade.currentVersions": "Versions actuelles",
  "oifde.flow.upgrade.newVersion": "Nouvelle version",
  "oifde.flow.exportFlowList.accessCode": "Code d'accès",
  "oifde.flow.exportFlowList.rule": "Règle",
  "oifde.flow.exportFlowList.template": "Modèle",
  "oifde.flow.exportFlowList.interface": "L'interface",
  "oifde.flow.exportFlowList.variable": "Variable",
  "oifde.flow.exportFlowList.title": "Exporter",
  'oifde.flow.exportFlowList.askExportAll': 'Vous n\'avez pas sélectionné d\'enregistrements spécifiques. Exporter tous les enregistrements ? Vous pouvez également annuler l\'opération et sélectionner un enregistrement spécifique à exporter.',
  "oifde.flow.exportFlowList.prompt": "Informations",
  "oifde.flow.exportFlowList.promptMessage": "Sélectionnez au moins un enregistrement.",
  "oifde.flow.exportFlowList.export": "Exporter",
  "oifde.flow.exportFlowList.exportAll": "Tout exporter",
  "oifde.flow.exportFlowList.procedure": "Procédure stockée",
  "oifde.flow.exportFlowList.complexCell": "élément de diagramme composite",
  "oifde.flow.importFlow.title": "Flux d'importation",
  "oifde.flow.importFlow.select": "Sélectionner un fichier",
  "oifde.flow.importFlow.upload": "Téléchargement",
  "oifde.flow.importFlow.prompt": "Le fichier doit être un fichier ZIP.",
  "oifde.flow.importFlow.cover": "Autoriser les noms en double",
  "oifde.flow.importFlow.resultTitle": "Importer les résultats",
  "oifde.flow.importFlow.name": "Nom et prénom",
  "oifde.flow.importFlow.type": "Type",
  "oifde.flow.importFlow.result": "Résultat",
  "oifde.flow.importFlow.error": "Inviter",
  "oifde.flow.importFlow.message": "Sélectionnez le fichier que vous souhaitez télécharger.",
  "oifde.flow.importFlow.fileType": "Le fichier doit être un fichier ZIP.",
  "oifde.flow.importFlow.import": "Importer",
  "oifde.flow.importFlow.success": "L'opération est réussie.",
  "oifde.flow.importFlow.bigFile": "La taille du fichier est trop grande.",
  "oifde.flow.importFlow.uploadFail": "Impossible de télécharger le fichier.",
  "oifde.flow.importFlow.bigFilePrompt": "La taille du fichier ne peut pas dépasser 20 Mo.",
  "oifde.flow.importFlow.timeout": "Importation de données... Veuillez interroger les données les plus récentes ultérieurement.",
  "oifde.flow.usage.usageStatus": "état des statistiques",
  "oifde.flow.usage.lasttime": "Dernier terminé",
  "oifde.flow.usage.check": "Vérifier",
  "oifde.flow.usage.showUnused": "Voir les ressources inutilisées",
  "oifde.flow.usage.templateUsageTitle": "Utilisation du modèle",
  "oifde.flow.usage.globalVarsUsageTitle": "Utilisation globale des variables",
  "oifde.flow.usage.flowVarsUsageTitle": "Utilisation variable de flux",
  "oifde.flow.usage.inuse": "En cours d'utilisation",
  "oifde.flow.usage.unuse": "Jamais utilisé",
  "oifde.flow.usage.status.norecord": "Pas de données",
  "oifde.flow.usage.status.init": "En attente d'exécution",
  "oifde.flow.usage.status.running": "Exécution",
  "oifde.flow.usage.status.finish": "Terminé",
  "oifde.flow.usage.status.fail": "échec",
  "oifde.flow.usage.name": "Nom et prénom",
  "oifde.flow.usage.type": "Type",
  "oifde.flow.usage.desc": "Descriptif",
  "oifde.flow.usage.resourceType.template": "Modèle",
  "oifde.flow.usage.resourceType.globalvars": "Variable globale",
  "oifde.flow.usage.resourceType.flowvars": "Variable de débit",
  "oifde.flow.usage.resourceType.complexcell": "élément de diagramme composite",
  "oifde.flow.usage.resourceType.interface": "L'interface",
  "oifde.flow.usage.resourceType.procedure": "Procédure stockée",
  "oifde.flow.usage.resourceType.rule": "Règle grise",
  "oifde.flow.usage.complexcellUsageTitle": "Utilisation de l'élément de diagramme composite",
  "oifde.flow.usage.interfaceUsageTitle": "Utilisation de l'interface",
  "oifde.flow.usage.procedureUsageTitle": "Utilisation de la procédure stockée",
  "oifde.flow.usage.ruleUsageTitle": "Utilisation des règles grises",
  "oifde.flow.flowTypeSelect": "Sélectionner un nouveau modèle de flux",
  "oifde.flow.flowTemplate": "Modèle de flux",
  "oifde.flow.add.blankFlow": "Flux vide",
  "oifde.flow.add.callInFlow": "Flux d'appels entrants",
  "oifde.flow.add.outBoundFlow": "Flux d'appels sortants",
  "oifde.flow.add.exceptionFlow": "Débit anormal",
  "oifde.template.setInputFlag": "Saisie manuelle du contenu",
  "oifde.template.template": "Gestion des modèles de ressources",
  "oifde.template.channelTypeMgr": "Gestion des types de canaux",
  "oifde.template.responseStyleMgr": "Gestion des styles",
  "oifde.template.channelTypeName": "Nom et prénom",
  "oifde.template.createChannelType": "Ajouter un canal",
  "oifde.template.editChannelType": "Modifier le canal",
  "oifde.template.responseStyleName": "Nom et prénom",
  "oifde.template.createResponseStyle": "Ajouter un style",
  "oifde.template.editResponseStyle": "Modifier le style",
  "oifde.template.selectChannelType": "Sélectionnez un canal.",
  "oifde.template.selectResponseStyle": "Sélectionnez un style.",
  "oifde.template.selectLang": "Sélectionnez une langue.",
  "oifde.template.editContent": "Ajouter le contenu du modèle",
  "oifde.template.setDefault": "Définir par défaut",
  "oifde.template.name": "Nom et prénom",
  "oifde.template.type": "Type",
  "oifde.template.desc": "Descriptif",
  "oifde.template.content": "Contenu",
  "oifde.template.createTime": "Créé",
  "oifde.template.updateTime": "Modifié",
  "oifde.template.detail": "Détails du contenu",
  "oifde.template.select": "Type",
  "oifde.template.contentName": "Contenu",
  "oifde.template.deleteContent": "Supprimer",
  "oifde.template.addContent": "Ajouter un contenu de modèle",
  "oifde.template.tipContent": "Entrez le contenu du modèle.",
  "oifde.template.tipForVoice": "Entrez le chemin absolu du fichier vocal.",
  "oifde.template.tipForVideo": "Entrez le chemin absolu du fichier vidéo.",
  "oifde.template.addLimit": "Un maximum de trois enregistrements de contenu sont autorisés.",
  "oifde.template.deleteLimit": "Au moins un enregistrement de contenu est requis.",
  "oifde.template.selectLanguage": "Sélectionnez une langue. Si vous voulez supprimer une langue, cliquez sur l'icône de fermeture.",
  "oifde.template.addNewIntent": "Ajouter une intention",
  "oifde.template.setIntentTemplate": "Configurer le modèle d'intention",
  "oifde.template.addTopNode": "Ajouter un nœud supérieur",
  "oifde.template.selectTemplate": "Sélectionner un modèle",
  "oifde.template.selectAction": "Sélectionner",
  "oifde.template.actions.create": "Ajouter",
  "oifde.template.actions.delete": "Supprimer",
  "oifde.template.actions.edit": "Modifier",
  "oifde.template.actions.editbaseInfo": "Modifier le modèle de ressource",
  "oifde.template.actions.addbaseInfo": "Ajouter un modèle de ressource",
  "oifde.template.actions.success": "Succès",
  "oifde.template.actions.fail": "échec",
  "oifde.template.actions.createSuccess": "Le modèle est créé avec succès.",
  "oifde.template.actions.saveSuccess": "Le modèle est enregistré avec succès.",
  "oifde.template.actions.updateSuccess": "Le modèle est modifié avec succès.",
  "oifde.template.actions.deleteSuccess": "Le modèle est supprimé avec succès.",
  "oifde.template.actions.cancelDelete": "La suppression est annulée.",
  "oifde.template.actions.deletePrompt": "Cette opération supprimera définitivement le modèle. êtes-vous sûr de vouloir continuer ?",
  "oifde.template.actions.deleteChannelType": "Cette opération supprimera définitivement le canal. êtes-vous sûr de vouloir continuer ?",
  "oifde.template.actions.deleteResponseStyle": "Cette opération supprimera définitivement le style. êtes-vous sûr de vouloir continuer ?",
  "oifde.template.actions.prompt": "Confirmer",
  "oifde.template.actions.confirm": "Oui",
  "oifde.template.actions.cancel": "Non",
  "oifde.template.actions.append": "Ajouter",
  "oifde.template.update.templateName": "Nom et prénom",
  "oifde.template.update.templateType": "Type",
  "oifde.template.update.templateDesc": "Descriptif",
  "oifde.template.update.intents": "Liste d'intentions",
  "oifde.template.typeDetail.tts": "TTS",
  "oifde.template.typeDetail.sms": "SMS",
  "oifde.template.typeDetail.staticvoice": "Voix statique",
  "oifde.template.typeDetail.videovoice": "Fichier vidéo",
  "oifde.template.typeDetail.multiMedia": "Multimédia",
  "oifde.template.typeDetail.cardTemplate": "Modèle de carte",
  "oifde.template.language.chinese": "Chinois",
  "oifde.template.language.english": "Français",
  "oifde.template.language.spanish": "Espagnol",
  "oifde.template.voicename.cn_kefuman_common": "Gracieuse",
  "oifde.template.voicename.cn_chengshuxing_common": "Mature",
  "oifde.template.voicename.cn_roumeiqian_common": "Doux",
  "oifde.template.voicename.cn_tianmeiru_common": "Douce",
  "oifde.template.voicename.cn_ziranzhen_common": "Naturel",
  "oifde.template.defaultValueValidate.ttsSpeedValueDesc": "plage de valeurs : [-500,500]",
  "oifde.template.defaultValueValidate.ttsVolumeValueDesc": "plage de valeurs : [0,100]",
  "oifde.template.defaultValueValidate.ttsPitchValueDesc": "plage de valeurs : [-500,500]",
  "oifde.template.validate.content": "Le contenu du modèle est obligatoire.",
  "oifde.template.validate.templateName": "Saisissez un nom de modèle.",
  "oifde.template.validate.templateType": "Sélectionnez un type de modèle.",
  "oifde.template.validate.templateNameLength": "Le nom du modèle ne peut pas contenir plus de 256 caractères.",
  "oifde.template.validate.templateDescLength": "La description du modèle ne peut pas contenir plus de 1024 caractères.",
  "oifde.template.validate.templateContentLength": "Le contenu du modèle ne peut pas contenir plus de 2048 caractères.",
  "oifde.template.validate.doubleNameCheck": "Le nom du modèle existe déjà.",
  "oifde.template.validate.intentDsp": "Saisissez une description du modèle.",
  "oifde.template.validate.intentcode": "Entrez un code d'intention.",
  "oifde.template.validate.nameDup": "Le nom existe.",
  "oifde.template.validate.contentLengthError": "Le contenu du modèle ne peut pas être vide ou contenir plus de 4096 caractères.",
  "oifde.template.intentionInfo.addIntent": "Ajouter une intention",
  "oifde.template.intentionInfo.intentDsp": "Descriptif",
  "oifde.template.intentionInfo.intentcode": "Code",
  "oifde.template.intentionInfo.intentList": "Liste",
  "oifde.template.message.intentListLength": "La liste d'intentions peut contenir un maximum de 4096 caractères.",
  "oifde.template.message.intentNameDuplicate": "Le nom d'intention existe déjà.",
  "oifde.template.message.validateResult": "Informations",
  "oifde.template.sysErrorTemplateTitle": "Modèle de ressource anormale",
  "oifde.template.sysErrorTemplate": "Modifier le modèle de ressource anormale",
  "oifde.template.contentDetail": "Détails",
  "oifde.template.aiccTemplateNullTips": "Le type de modèle ne peut pas être vide.",
  'oifde.template.content.exist': 'Le contenu du modèle actuellement sélectionné a été ajouté',
  'oifde.template.selectResource': 'Sélectionner une ressource',
  'oifde.template.message.searchResourceName': 'Rechercher le nom de la ressource',
  'oifde.template.title.ResourceName': 'Nom de la ressource',
  'oifde.template.title.ResourceType': 'Type de ressource',
  'oifde.template.multipart.type.location': "L'adresse",
  "oifde.rule.flowRule": "Règle de gris d'écoulement",
  "oifde.rule.kgRule": "Règle de gris des connaissances",
  "oifde.rule.name": "Nom et prénom",
  "oifde.rule.beginNum": "Numéro de départ",
  "oifde.rule.endNum": "Numéro de fin",
  "oifde.rule.language": "Langue",
  "oifde.rule.select": "Sélectionner",
  "oifde.rule.number": "Quantité",
  "oifde.rule.manage": "Gérer",
  "oifde.rule.actions.error": "Une erreur s'est produite lors de l'appel de l'interface.",
  "oifde.rule.actions.errorCode": "Code d'erreur",
  "oifde.rule.actions.errorMessage": "Message d'erreur",
  "oifde.rule.actions.create": "Ajouter",
  "oifde.rule.actions.delete": "Supprimer",
  "oifde.rule.actions.edit": "Modifier",
  "oifde.rule.actions.success": "Succès",
  "oifde.rule.actions.addSuccess": "La règle est ajoutée avec succès.",
  "oifde.rule.actions.updateSuccess": "La règle est modifiée avec succès.",
  "oifde.rule.actions.deleteSuccess": "La règle est supprimée avec succès.",
  "oifde.rule.actions.editbaseInfo": "Modifier les informations de base",
  "oifde.rule.actions.addbaseInfo": "Créer des informations de base",
  "oifde.rule.actions.confirm": "Confirmer",
  "oifde.rule.actions.sure": "Oui",
  "oifde.rule.actions.cancel": "Non",
  "oifde.rule.actions.cancelDelete": "La suppression est annulée.",
  "oifde.rule.actions.deleteConfirm": "Cette opération supprimera définitivement la règle. êtes-vous sûr de vouloir continuer ?",
  "oifde.rule.actions.deleteFailed": "La suppression a échoué. Vérifiez si la règle est associée à un flux de libération. Annulez d'abord la publication.",
  'oifde.rule.actions.viewBoundFlow': 'Affichage des processus liés',
  "oifde.rule.rules.ruleNameValidator": "Le nom de la règle existe déjà.",
  "oifde.rule.rules.beginNumValidator": "Le numéro de départ ne peut contenir que des lettres, des chiffres, des espaces et les caractères spéciaux suivants",
  "oifde.rule.rules.endNumValidator": "Le numéro de fin ne peut contenir que des lettres, des chiffres, des espaces et les caractères spéciaux suivants",
  "oifde.rule.rules.beginNumLengthValidator": "Le numéro de fin doit être supérieur ou égal au numéro de début.",
  "oifde.rule.update.ruleName": "Nom de la règle",
  "oifde.rule.update.beginNum": "Numéro de départ",
  "oifde.rule.update.newRuleName": "Saisissez un nom de règle.",
  "oifde.rule.update.newBeginNum": "Entrez un numéro de départ.",
  "oifde.rule.update.newEndNum": "Entrez un numéro de fin.",
  "oifde.rule.update.endNum": "Numéro de fin",
  "oifde.rule.message.add_name_list": "Ajouter une liste de blocage/une liste de confiance",
  "oifde.rule.message.name_list_info": "Détails de la Blocklist/Trustlist",
  "oifde.rule.message.succeed_save": "La liste de blocage/liste de confiance est enregistrée avec succès.",
  "oifde.rule.message.fail_save": "Impossible d'enregistrer la liste de blocage/liste de confiance.",
  "oifde.rule.message.failed_query": "Impossible d'exécuter la requête.",
  "oifde.rule.message.select_prompt": "Sélectionnez au moins un enregistrement.",
  "oifde.rule.message.confirm_name_list": "êtes-vous sûr de vouloir supprimer la liste de blocage/les listes de confiance?",
  "oifde.rule.message.succeed_delete": "La liste de blocage/liste de confiance est supprimée avec succès.",
  "oifde.rule.message.fail_delete": "Impossible de supprimer la liste de blocage/liste de confiance.",
  "oifde.rule.message.mgr_name_list": "Gérer la liste de blocage/la liste de confiance —",
  "oifde.rule.message.add_user_ids": "Ajouter un numéro d'abonné",
  "oifde.rule.message.batch_input_prompt": "Appuyez sur Entrée après chaque enregistrement pour l'ajouter. Chaque enregistrement peut contenir de 4 à 64 caractères.",
  "oifde.rule.message.user_number": "Numéro d'abonné",
  "oifde.rule.message.user_ids_number": "Un maximum de 5000 numéros d'abonnés peuvent être ajoutés à la liste de blocage/liste de confiance.",
  "oifde.rule.message.user_ids_number_once": "Un maximum de 100 numéros d'abonnés peuvent être ajoutés à la liste de blocage/liste de confiance dans un lot.",
  "oifde.rule.message.user_id_not_exceed": "Chaque numéro d'abonné peut contenir de 4 à 64 caractères.",
  "oifde.rule.message.save_user_ids_success": "Les numéros d'abonnés sont enregistrés avec succès.",
  "oifde.rule.message.save_user_ids_fail": "Impossible d'enregistrer les numéros d'abonnés.",
  "oifde.rule.message.confirm_delete_user_ids": "êtes-vous sûr de vouloir supprimer les numéros d'abonnés ?",
  "oifde.rule.message.succeed_delete_user_ids": "Les numéros d'abonnés sont supprimés avec succès.",
  "oifde.rule.message.failed_delete_user_ids": "Impossible de supprimer les numéros d'abonnés.",
  "oifde.rule.message.import_user_ids": "Importer le numéro d'abonné",
  "oifde.rule.message.import_words_hint_text": "Les fichiers texte peuvent être importés (*.txt). Le format d'encodage du fichier est UTF-8.",
  "oifde.rule.message.overwrite_import": "écraser la configuration existante",
  "oifde.rule.message.success_import": "L'importation est réussie.",
  "oifde.rule.message.failed_import_file": "Impossible d'importer le fichier.",
  "oifde.accesscode.usingRecord": "Capture de paquets",
  "oifde.accesscode.messageTips": "S'il vous plaît prendre un moment pour fournir des commentaires sur les améliorations que vous aimeriez voir.",
  "oifde.accesscode.accessCode": "Code d'accès au chatbot",
  "oifde.accesscode.accessCodeSearch": "Nom du chatbot ou code d'accès",
  "oifde.accesscode.accessCodeDesc": "Descriptif",
  "oifde.accesscode.description": "Code d'accès ID",
  "oifde.accesscode.descPlaceholder": "Cet description est utilisé comme description de flux lorsque le flux est sélectionné comme configuration de la partie appelée. La valeur doit être unique au monde.",
  "oifde.accesscode.flowCode": "Code de flux",
  "oifde.accesscode.flowName": "Nom du flux",
  "oifde.accesscode.domainId": "ID de domaine",
  "oifde.accesscode.dialogType": "Type de dialogue",
  "oifde.accesscode.processTimes": "Nombre d'appels traités uniquement par l'agent",
  "oifde.accesscode.callingnumber": "Numéro d'appel",
  "oifde.accesscode.callednumber": "Numéro appelé",
  "oifde.accesscode.startcall": "Démarrer l'appel",
  "oifde.accesscode.sendprompt": "Appuyez sur Entrée pour envoyer votre message.",
  "oifde.accesscode.callingnumberinput": "Entrez le numéro d'appel.",
  "oifde.accesscode.isCheck": "Vérifier l'orthographe",
  "oifde.accesscode.isCheckHelp": "Les fautes d'orthographe dans les relevés des clients sont corrigées automatiquement avant que l'intention ne soit reconnue.",
  "oifde.accesscode.sendContent": "Envoyer",
  "oifde.accesscode.trl": "Tapez de droite à gauche",
  "oifde.accesscode.actions.edit": "Modifier",
  "oifde.accesscode.actions.testCalledNum": "Appel de test",
  "oifde.accesscode.actions.delete": "Supprimer",
  "oifde.accesscode.actions.editbaseInfo": "Modifier le chatbot",
  "oifde.accesscode.actions.addbaseInfo": "Ajouter un chatbot",
  "oifde.accesscode.actions.success": "Succès",
  "oifde.accesscode.actions.fail": "échec",
  "oifde.accesscode.actions.createSuccess": "Création réussie.",
  "oifde.accesscode.actions.updateSuccess": "Modification réussie.",
  "oifde.accesscode.actions.deleteSuccess": "Suppression réussie.",
  "oifde.accesscode.actions.cancelDelete": "La suppression est annulée.",
  "oifde.accesscode.actions.deletePrompt": "Cette opération supprimera définitivement le chatbot. êtes-vous sûr de vouloir continuer ?",
  "oifde.accesscode.actions.prompt": "Confirmer",
  "oifde.accesscode.actions.confirm": "Oui",
  "oifde.accesscode.actions.cancel": "Non",
  "oifde.accesscode.actions.flowChatbot": "Chatbot de test de débit",
  "oifde.accesscode.actions.callforwarding": "Transfert d'appel",
  "oifde.accesscode.actions.voicefile": "Fichier vocal",
  "oifde.accesscode.actions.videofile": "Fichier vidéo",
  "oifde.accesscode.dialogTypeDetail.voiceNavigation": "Navigation vocale",
  "oifde.accesscode.dialogTypeDetail.smartOutboundCall": "Appel sortant intelligent",
  "oifde.accesscode.dialogTypeDetail.chatbot": "Chatbot",
  "oifde.accesscode.dialogTypeDetail.ivrProcess": "Flux IVR",
  "oifde.accesscode.validate.accessCode": "Entrez un code d'accès de flux.",
  "oifde.accesscode.validate.accessCodeCharReg": "Seuls les lettres et les chiffres sont autorisés.",
  "oifde.accesscode.validate.doubleAccessCodeCheck": "Le code d'accès existe déjà.",
  "oifde.accesscode.validate.dialogType": "Sélectionnez un type de boîte de dialogue.",
  "oifde.accesscode.validate.flowCode": "Sélectionnez un code de flux.",
  "oifde.accesscode.validate.accessCodeLength": "Le code d'accès peut contenir un maximum de 20 caractères.",
  "oifde.accesscode.validate.flowCodeLength": "Le code de flux peut contenir un maximum de 64 caractères.",
  "oifde.accesscode.validate.descriptionLengthValidator": "La description du code d'accès peut contenir un maximum de 512 caractères.",
  "oifde.accesscode.validate.domainIdMax": "L'ID de domaine ne peut pas dépasser 2147483647.",
  "oifde.accesscode.validate.domainIdCheck": "L'ID de domaine n'est pas un numéro valide.",
  "oifde.accesscode.more": "Plus",
  "oifde.accesscode.bindDomain": "Domaine lié",
  "oifde.accesscode.bindFaqGroup": "Lier le groupe FAQ",
  "oifde.accesscode.bindKg": "Lier le graphique de connaissances",
  "oifde.accesscode.robotName": "Nom du chatbot",
  "oifde.accesscode.domain": "Domaine",
  "oifde.accesscode.faqGroup": "FAQ Groupe",
  "oifde.accesscode.knowledgeGraph": "Graphique de connaissances",
  "oifde.accesscode.setTrace": "Paramètres de trace",
  "oifde.accesscode.callingNumberTrace": "Traçage du numéro d'appel",
  "oifde.accesscode.traceOpen": "Activer",
  "oifde.accesscode.traceClose": "Désactiver",
  "oifde.accesscode.hideTraceSet": "Réduire",
  "oifde.accesscode.setTraceSuccess": "Les paramètres sont appliqués avec succès.",
  "oifde.accesscode.recordState.disable": "Désactiver",
  "oifde.accesscode.recordState.enable": "Activer",
  "oifde.accesscode.accessCodeSubscribed": "Le code d'accès du chatbot est en cours d'utilisation.",
  "oifde.accesscode.bindScene": "Voir les références",
  "oifde.accesscode.chatBot.search": "Rechercher",
  "oifde.accesscode.chatBot.reset": "Réinitialiser",
  "oifde.accesscode.searchScene": "Sélectionnez un scénario.",
  "oifde.accesscode.sceneName": "Scénario",
  "oifde.accesscode.subName": "Nom de référence",
  "oifde.accesscode.createTime": "Temps de référence",
  "oifde.accesscode.chatBot.chatChanel": "Chaîne multimédia",
  "oifde.accesscode.chatBot.agentAssistant": "Assistant d'agent",
  "oifde.accesscode.chatBot.intelligentOB": "Sortant intelligent",
  "oifde.accesscode.chatBot.intelligentTrain": "Train intelligent",
  "oifde.toc.statusCode.SERVER_BUSY": "Le système est actuellement occupé. Essayez plus tard.",
  "oifde.toc.statusCode.NEED_LOGIN": "Vous n'avez pas ouvert de session ou effectué d'opérations depuis longtemps. Reconnectez-vous.",
  "oifde.toc.statusCode.ACCOUNT_INCONSISTENT": "Vous vous êtes connecté à l'ordinateur local en utilisant un autre compte. Pour continuer à utiliser ce service, connectez-vous à nouveau.",
  "oifde.toc.statusCode.ACCOUNT_SWITCHED": "Le compte de connexion a été modifié. Cette page sera mise à jour pour afficher le dernier compte de connexion.",
  "oifde.toc.statusCode.INCORRECT_VERIFYCODE": "Le code de vérification est incorrect. Entrez le bon.",
  "oifde.toc.statusCode.INVALID_JSON": "La requête n'est pas au format JSON.",
  "oifde.toc.statusCode.HTTPS_REQUIRED": "Seul HTTPS est pris en charge.",
  "oifde.toc.statusCode.EMPTY_ACCOUNT": "Le numéro de compte est vide.",
  "oifde.toc.statusCode.INVALID_ACCOUNT": "L'authentification a échoué. Reconnectez-vous.",
  "oifde.toc.statusCode.INSUFFICIENT_PERMISSION": "Vous n'avez pas la permission requise. Contactez l'administrateur.",
  "oifde.toc.statusCode.VERIFYCODE_EXPIRED": "Le code de vérification a expiré. Réessayez plus tard.",
  "oifde.toc.statusCode.ACCOUNT_LOCKED": "Le compte a été verrouillé temporairement. Réessayez plus tard ou contactez l'administrateur pour déverrouiller le compte.",
  "oifde.toc.statusCode.ACCOUNT_CHANGED": "Le compte a été modifié. Reconnectez-vous.",
  "oifde.toc.statusCode.ACCOUNT_DISABLED": "Le compte a été suspendu. Contactez l'administrateur.",
  "oifde.toc.statusCode.UNLOCK_REQUIRED": "Le compte a été verrouillé temporairement. Déverrouillez le compte et essayez à nouveau.",
  "oifde.toc.statusCode.ADMIN_DISABLED": "La fonction de gestion a été désactivée. Utilisez un compte commun pour vous connecter au système.",
  "oifde.toc.statusCode.SESSION_OVERFLOW": "Le nombre d'utilisateurs en ligne a atteint la limite supérieure. Réessayez plus tard.",
  "oifde.toc.statusCode.SESSION_EXPIRED": "La connexion actuelle n'est pas valide. Reconnectez-vous.",
  "oifde.toc.statusCode.SESSION_DUPLICATED": "Vous êtes déjà connecté. Pour vous connecter à nouveau, vous devez d'abord vous déconnecter.",
  "oifde.toc.statusCode.INCORRECT_TENANT_SPACE_ID": "L'identifiant de l'espace locataire est incorrect.",
  "oifde.toc.statusCode.INCORRECT_TENANT_SPACE_NAME": "Le nom de l'espace locataire est incorrect.",
  "oifde.toc.statusCode.INCORRECT_ACCOUNT": "Le compte est incorrect.",
  "oifde.toc.statusCode.INCORRECT_PASSWORD": "Le mot de passe ne répond pas aux exigences.",
  "oifde.toc.statusCode.INCORRECT_GENDER": "Le sexe est incorrect.",
  "oifde.toc.statusCode.INCORRECT_OWNER": "Le propriétaire est incorrect.",
  "oifde.toc.statusCode.INCORRECT_TITLE": "La longueur ne peut pas dépasser 8 caractères.",
  "oifde.toc.statusCode.INCORRECT_TELEPHONE": "Le numéro de téléphone est incorrect.",
  "oifde.toc.statusCode.INCORRECT_EMAIL": "L'adresse e-mail est incorrecte.",
  "oifde.toc.statusCode.INCORRECT_ROLE_NAME": "Le nom du rôle est incorrect.",
  "oifde.toc.statusCode.EMPTY_TENANT_SPACE": "L'espace locataire ne peut pas être vide.",
  "oifde.toc.statusCode.ACCOUNT_DUPLICATED": "Le compte existe déjà.",
  "oifde.toc.statusCode.ROLE_DUPLICATED": "Le rôle existe déjà.",
  "oifde.toc.statusCode.TENANT_SPACE_DUPLICATED": "L'espace locataire existe déjà.",
  "oifde.toc.statusCode.ACCOUNT_NOT_FOUND": "Le compte n'existe pas.",
  "oifde.toc.statusCode.ROLE_NOT_FOUND": "Le rôle n'existe pas.",
  "oifde.toc.statusCode.TENANT_SPACE_NOT_FOUND": "L'espace locataire n'existe pas.",
  "oifde.toc.statusCode.ACCOUNT_FORBIDDEN": "Les opérations sur ce compte sont interdites.",
  "oifde.toc.statusCode.ROLE_FORBIDDEN": "Les opérations sur ce rôle sont interdites.",
  "oifde.toc.statusCode.TENANT_SPACE_FORBIDDEN": "Les opérations sur cet espace locataire sont interdites.",
  "oifde.toc.statusCode.INCORRECT_ACCOUNT_INPUT": "Le compte peut contenir de 6 à 32 caractères composés de lettres chiffres et les caractères spéciaux suivants",
  "oifde.toc.statusCode.INCORRECT_PASSWORD_UPDATE": "Le mot de passe ne peut pas contenir le même caractère répété plus de 3 fois consécutives. De plus, les 3 derniers mots de passe historiques ne peuvent pas être réutilisés. Le mot de passe peut contenir de 8 à 20 caractères composés de chiffres minuscules lettres majuscules et les caractères spéciaux suivants",
  "oifde.toc.statusCode.COMMON_ERROR": "Erreur du serveur.",
  "oifde.toc.statusCode.SERVICE_UNAVAILABLE": "Le service est actuellement indisponible.",
  "oifde.toc.statusCode.AUTHORIZE_FAILED": "La validation a échoué.",
  "oifde.toc.statusCode.TRANSIT_FAILED": "La requête a expiré.",
  "oifde.toc.statusCode.TRANSIT_ERROR": "La requête a été envoyée avec succès mais l'homologue a renvoyé une erreur.",
  "oifde.toc.statusCode.RESPONSE_FAILED": "Le serveur n'a pas réussi à traiter la réponse.",
  "oifde.toc.statusCode.REFRESH_TIMEOUT": "Aucune opération n'a été effectuée depuis longtemps. Reconnectez-vous.",
  "oifde.logparam.logParamMgr": "Paramètres du journal Flume",
  "oifde.logparam.logParam": "Paramètres dynamiques",
  "oifde.logparam.logName": "Nom du paramètre",
  "oifde.logparam.source": "Source de paramètre",
  "oifde.logparam.sourceName": "Paramètre Nom de la source",
  "oifde.logparam.transFlag": "Convertir la valeur",
  "oifde.logparam.sourceValue": "Valeur source",
  "oifde.logparam.targetValue": "Valeur cible",
  "oifde.logparam.actions.edit": "Modifier",
  "oifde.logparam.actions.transDetail": "Convertir la valeur",
  "oifde.logparam.actions.delete": "Supprimer",
  "oifde.logparam.actions.editLogParamBaseInfo": "Modifier le paramètre dynamique",
  "oifde.logparam.actions.addLogParamBaseInfo": "Ajouter un paramètre dynamique",
  "oifde.logparam.actions.success": "Succès",
  "oifde.logparam.actions.createSuccess": "Création réussie.",
  "oifde.logparam.actions.updateSuccess": "Modification réussie.",
  "oifde.logparam.actions.deleteSuccess": "Suppression réussie.",
  "oifde.logparam.actions.cancelDelete": "La suppression est annulée.",
  "oifde.logparam.actions.deletePrompt": "Cette opération supprimera définitivement le paramètre dynamique. êtes-vous sûr de vouloir continuer ?",
  "oifde.logparam.actions.prompt": "Confirmer",
  "oifde.logparam.actions.confirm": "Oui",
  "oifde.logparam.actions.cancel": "Non",
  "oifde.logparam.actions.add": "Ajouter",
  "oifde.logparam.sourceDetail.IVRRequestInformation": "Demande IVR",
  "oifde.logparam.sourceDetail.IVRResponseInformation": "Réponse de l'IVR",
  "oifde.logparam.sourceDetail.TOCRequestInformation": "Demande de TOC",
  "oifde.logparam.sourceDetail.TOCResponseInformation": "Réponse TOC",
  "oifde.logparam.sourceDetail.InterfaceRequestInformation": "Demande d'interface",
  "oifde.logparam.sourceDetail.InterfaceResponseInformation": "Réponse de l'interface",
  "oifde.logparam.transFlagDetail.YES": "Oui",
  "oifde.logparam.transFlagDetail.NO": "Non",
  "oifde.logparam.validate.logName": "Saisissez un nom de paramètre.",
  "oifde.logparam.validate.source": "Sélectionnez une source de paramètre.",
  "oifde.logparam.validate.sourceName": "Saisissez un nom de source de paramètre.",
  "oifde.logparam.validate.sourceValue": "Entrez une valeur source.",
  "oifde.logparam.validate.targetValue": "Entrez une valeur cible.",
  "oifde.logparam.validate.doublelogNameCheck": "Le nom du paramètre dynamique existe déjà.",
  "oifde.logparam.validate.logNameLength": "Le nom du paramètre dynamique peut contenir un maximum de 64 caractères.",
  "oifde.logparam.validate.sourceNameLength": "Le nom du paramètre source peut contenir un maximum de 64 caractères.",
  "oifde.logparam.validate.sourceValueLength": "Le plus long:64",
  "oifde.logparam.validate.targetValueLength": "Le plus long:64",
  "oifde.logparam.message.logParamNotNull": "Sélectionnez si vous souhaitez convertir la valeur.",
  "oifde.logparam.message.validateFailed": "La validation a échoué.",
  "oifde.logparam.message.validateResult": "Informations",
  "oifde.tenantinfo.tenanInfoMgr": "Paramètres statiques",
  "oifde.tenantinfo.serviceName": "Nom et prénom",
  "oifde.tenantinfo.serviceValue": "Valeur",
  "oifde.tenantinfo.actions.edit": "Modifier",
  "oifde.tenantinfo.actions.editTenantBaseInfo": "Modifier le paramètre statique",
  "oifde.tenantinfo.actions.success": "Succès",
  "oifde.tenantinfo.actions.updateSuccess": "Modification réussie.",
  "oifde.tenantinfo.validate.serviceValue": "Entrez une valeur.",
  "oifde.tenantinfo.validate.serviceValueLength": "La valeur peut contenir un maximum de 64 caractères.",
  "oifde.variable.innerTitle": "Variable intégrée",
  "oifde.variable.customTitle": "Variable personnalisée",
  "oifde.variable.variableName": "Nom de la variable",
  "oifde.variable.variableDesc": "Descriptif",
  "oifde.variable.variableType": "Type de données",
  "oifde.variable.scope": "Champ d'application",
  "oifde.variable.defaultValue": "Valeur par défaut",
  "oifde.variable.encryptType": "Type de chiffrement",
  "oifde.variable.timezoneDesc": "Utilisez cette variable pour définir le décalage du fuseau horaire dans lequel réside le locataire. La valeur varie de –12 à +12.",
  "oifde.variable.channeltypeDesc": "Utilisez cette variable pour définir le canal du locataire.",
  "oifde.variable.responsestyleDesc": "Utilisez cette variable pour définir le style du locataire.",
  "oifde.variable.langDesc": "Utilisez cette variable pour définir la langue du locataire.",
  "oifde.variable.ttsSpeedDesc": "Utilisez cette variable pour définir la vitesse vocale TTS du locataire.",
  "oifde.variable.ttsVolumeDesc": "Utilisez cette variable pour définir le volume TTS du locataire.",
  "oifde.variable.ttsPitchDesc": "Utilisez cette variable pour définir la fréquence fondamentale TTS du locataire.",
  "oifde.variable.voiceNameDesc": "Utilisez cette variable pour définir le haut-parleur TTS du locataire.",
  "oifde.variable.variableTypeDetail.int": "Entier",
  "oifde.variable.variableTypeDetail.string": "Caractère",
  "oifde.variable.variableTypeDetail.long": "Long entier",
  "oifde.variable.variableTypeDetail.float": "Nombre à virgule flottante",
  "oifde.variable.variableTypeDetail.list": "Liste",
  "oifde.variable.variableTypeDetail.object": "Objet",
  "oifde.variable.scopes.input": "Paramètre d'entrée",
  "oifde.variable.scopes.output": "Paramètre de sortie",
  "oifde.variable.scopes.local": "Locale",
  "oifde.variable.actions.edit": "Modifier",
  "oifde.variable.actions.delete": "Supprimer",
  "oifde.variable.actions.editVariableBaseInfo": "Modifier la variable",
  "oifde.variable.actions.addVariableBaseInfo": "Ajouter une variable",
  "oifde.variable.actions.success": "Succès",
  "oifde.variable.actions.createSuccess": "Création réussie",
  "oifde.variable.actions.updateSuccess": "Modification réussie",
  "oifde.variable.actions.deleteSuccess": "Suppression réussie",
  "oifde.variable.actions.cancelDelete": "La suppression est annulée",
  "oifde.variable.actions.deletePrompt": "Cette opération supprimera définitivement la variable. êtes-vous sûr de vouloir continuer ?",
  "oifde.variable.actions.prompt": "Confirmer",
  "oifde.variable.actions.confirm": "Oui",
  "oifde.variable.actions.cancel": "Non",
  "oifde.variable.actions.add": "Ajouter",
  "oifde.variable.validate.variableName": "Saisissez un nom de variable.",
  "oifde.variable.validate.variableNameRange": "Seuls les chiffres de lettres et les (_) de soulignement sont autorisés.",
  "oifde.variable.validate.variableType": "Sélectionnez un type de variable.",
  "oifde.variable.validate.scope": "Sélectionnez la portée.",
  "oifde.variable.validate.doubleVariableNameCheck": "Le nom de la variable existe déjà.",
  "oifde.variable.validate.intValueCheck": "La valeur par défaut pour les données entières est incorrecte. Il va de –2147483648 à +2147483647.",
  "oifde.variable.validate.longValueCheck": "La valeur par défaut pour les données entières longues n'est pas valide.",
  "oifde.variable.validate.floatValueCheck": "La valeur par défaut pour les données en virgule flottante est dans un format incorrect.",
  "oifde.variable.validate.variableNameLength": "Le nom de la variable contient plus de 1000 caractères.",
  "oifde.variable.validate.variableDescLength": "La description de la variable contient plus de 1000 caractères.",
  "oifde.variable.validate.defaultValueLength": "La valeur par défaut contient plus de 1024 caractères.",
  "oifde.variable.validate.listValueCheck": "Le format de la valeur par défaut des données de liste est incorrect. Le format doit être [1, 2, 3] ou [\"a\", \"b\", \"c\"]. La valeur peut être vide.",
  "oifde.variable.validate.objectValueCheck": "Le format de la valeur par défaut des données du type objet est incorrect. Le format doit être {'{'}\"key1\":\"value1\",\"key2\":\"value2\"{'}'}. La valeur peut être vide.",
  "oifde.variable.encryptFlag.zero": "Pas de chiffrement",
  "oifde.variable.encryptFlag.one": "Chiffré pour le stockage et déchiffré lors de la récupération",
  "oifde.variable.encryptFlag.two": "Chiffré pour le stockage et la récupération",
  "oifde.diagram.NMS_PleaseSelect": "-Sélectionner-",
  "oifde.diagram.NMS_ParamName": "Paramètre",
  "oifde.diagram.NMS_ParamValue": "Valeur",
  "oifde.diagram.NMS_DiagramProperties": "Attribut de flux",
  "oifde.diagram.NMS_BasicProperties": "Attribut de base",
  "oifde.diagram.NMS_DiagramCode": "Code de flux",
  "oifde.diagram.NMS_DiagramName": "Nom du flux",
  "oifde.diagram.NMS_ScenarioType": "Type de scénario",
  "oifde.diagram.NMS_Desciption": "Descriptif",
  "oifde.diagram.NMS_NodeCode": "Code de nœud",
  "oifde.diagram.NMS_NodeName": "Nom du nœud",
  "oifde.diagram.NMS_AdvanceProperties": "Attribut avancé",
  "oifde.diagram.NMS_ServiceName": "Nom du service",
  "oifde.diagram.NMS_ServiceParams": "Paramètre de service",
  "oifde.diagram.NMS_ServiceDesciption": "Description du service",
  "oifde.diagram.add": "Ajouter",
  "oifde.diagram.remove": "Supprimer",
  "oifde.diagram.NMS_BranchProperties": "Attribut de branche",
  "oifde.diagram.NMS_BranchCondition": "état de la branche",
  "oifde.diagram.NMS_BranchService": "Direction des services",
  "oifde.diagram.NMS_NodeProperties": "Attribut de nœud",
  "oifde.diagram.NMS_FlowVariable": "Variable de débit",
  "oifde.diagram.NMS_FlowVariableName": "Nom de la variable",
  "oifde.diagram.NMS_FlowVariableDesc": "Descriptif",
  "oifde.diagram.NMS_FlowVariableType": "Type de données",
  "oifde.diagram.NMS_FlowVariableDefaultValue": "Valeur par défaut",
  "oifde.diagram.NMS_FlowVariableEncryptType": "Type de chiffrement",
  "oifde.diagram.NMS_FlowVariableOption": "Opération",
  "oifde.diagram.NMS_AddVariable": "Ajouter une variable",
  "oifde.diagram.NMS_ModifyVariable": "Modifier la variable",
  "oifde.diagram.NMS_FlowVariableEncryptTypeWarn": "* Notez que si le type de chiffrement est non chiffré, la variable sera stockée en texte brut. N'attribuez pas de données sensibles à la variable.",
  "oifde.diagram.NMS_FlowVariableEncryptTypeTips": "* Notez que lorsque le type de chiffrement est le chiffrement, il est recommandé d'utiliser une variable pour lui attribuer une valeur afin d'éviter les fuites d'informations.",
  "oifde.diagram.variableTypeDetail.int": "Entier",
  "oifde.diagram.variableTypeDetail.string": "Caractère",
  "oifde.diagram.variableTypeDetail.long": "Long entier",
  "oifde.diagram.variableTypeDetail.float": "Nombre à virgule flottante",
  "oifde.diagram.variableTypeDetail.list": "Tableau",
  "oifde.diagram.variableTypeDetail.object": "Objet",
  "oifde.diagram.error.NMS_flowCodeError": "Le code de flux ne peut pas être vide ou dépasser 64 caractères.",
  "oifde.diagram.error.NMS_flowNameError": "Le nom du flux ne peut pas être vide ou dépasser 1000 caractères.",
  "oifde.diagram.error.NMS_scenarioTypeError": "Ce type de scénario ne peut pas être vide.",
  "oifde.diagram.error.NMS_flowDescError": "La description du flux peut contenir un maximum de 1000 caractères.",
  "oifde.diagram.error.NMS_VariableNameError": "Le nom de la variable peut contenir un maximum de 1000 caractères.",
  "oifde.diagram.error.NMS_VariableDescError": "La description de la variable peut contenir un maximum de 1000 caractères.",
  "oifde.diagram.error.NMS_VariableDefaultValueError": "La valeur par défaut de la variable peut contenir un maximum de 1024 caractères.",
  "oifde.diagram.error.NMS_NodeCodeError": "Le code de nœud ne peut pas être vide ou dépasser 64 caractères.",
  "oifde.diagram.error.NMS_NodeNameError": "Le nom du nœud ne peut pas être vide ou dépasser 1000 caractères.",
  "oifde.diagram.error.NMS_NodeDescError": "La description du nœud peut contenir un maximum de 1000 caractères.",
  "oifde.diagram.error.NMS_actionParamNameError": "Le nom du paramètre peut contenir un maximum de {0} caractères.",
  "oifde.diagram.error.NMS_actionParamValueError": "La valeur du paramètre peut contenir un maximum de 4096 caractères.",
  "oifde.diagram.error.NMS_actionParamDescError": "La description du paramètre peut contenir un maximum de 500 caractères.",
  "oifde.diagram.error.NMS_transitToStartNodeError": "Impossible de passer au nœud de démarrage.",
  "oifde.diagram.error.NMS_endNodetransitToError": "Impossible de passer du nœud d'extrémité à un autre nœud.",
  "oifde.diagram.error.NMS_startNodeNotFind": "Le nœud de départ n'est pas trouvé.",
  "oifde.diagram.error.NMS_startNodeNotUnique": "Le nœud de départ n'est pas unique.",
  "oifde.diagram.error.NMS_startNodeNotBranchError": "Le nœud de démarrage ne peut avoir qu'une seule branche.",
  "oifde.diagram.error.NMS_endNodeNotFind": "Le nœud d'extrémité n'est pas trouvé.",
  "oifde.diagram.error.NMS_branchError": "La branche doit avoir le nœud de début et le nœud de fin.",
  "oifde.diagram.error.NMS_nullBranchNotNuique": "Un nœud ne peut avoir qu'une seule branche par défaut.",
  "oifde.diagram.error.NMS_checkDefaultNextNodeForView": "L'élément de diagramme du type de vue ne peut pas avoir plus d'une branche.",
  "oifde.diagram.error.NMS_actionParamValueEmptyError": "La valeur du paramètre ne peut pas être vide.",
  "oifde.diagram.error.NMS_nmsFlowError": "Impossible d'analyser le flux car le nœud nmsFlow ne peut pas être trouvé.",
  "oifde.diagram.error.NMS_rootError": "Impossible d'analyser le flux car le nœud racine ne peut pas être trouvé.",
  "oifde.diagram.error.NMS_analiseXmlFailed": "Impossible d'analyser la toile de flux.",
  "oifde.diagram.complexCell.name": "Nom de l'élément de diagramme",
  "oifde.diagram.complexCell.variable": "Variable d'élément de diagramme",
  'oifde.diagram.speechRecognition.recognitionParam': 'Paramètres de reconnaissance',
  'oifde.diagram.speechRecognition.resultParam': 'Traitement des résultats',
  "oifde.diagram.varType": "Type de variable",
  "oifde.diagram.usedVar": "Variable utilisée",
  "oifde.diagram.usedCell": "élément de diagramme",
  "oifde.diagram.fastGetCell": "Trouver un élément de diagramme",
  "oifde.diagram.fastByVar": "Trouver une variable",
  "oifde.diagram.aimCell": "élément de diagramme cible",
  "oifde.diagram.saveAndPublish": "Enregistrer et publier",
  "oifde.diagram.NMS_VoiceTemplate": "Modèle de réponse",
  "oifde.navbar.tenantId": "ID locataire actuel",
  "oifde.navbar.tenantName": "Locataire actuel",
  "oifde.navbar.current": "Connexion actuelle",
  "oifde.navbar.success": "Dernière connexion réussie",
  "oifde.navbar.failed": "Dernière connexion échouée",
  "oifde.navbar.logOut": "Se déconnecter",
  "oifde.navbar.switchUser": "Changer d'utilisateur",
  "oifde.navbar.screenfull": "Plein écran",
  "oifde.navbar.switchLang": "Changer de langue",
  "oifde.navbar.menuOpen": "Afficher le menu",
  "oifde.navbar.menuClose": "Masquer le menu",
  "oifde.navbar.changePwd": "Changer le mot de passe",
  "oifde.navbar.required": "Ce champ est obligatoire.",
  "oifde.navbar.action.message": "Inviter",
  "oifde.navbar.action.success": "Succès",
  "oifde.navbar.action.successMessage": "L'utilisateur est commuté avec succès.",
  "oifde.navbar.action.changePasswordSuccess": "Le mot de passe est changé avec succès.",
  "oifde.navbar.password.logOut": "Se déconnecter",
  "oifde.navbar.password.oldPassword": "Ancien mot de passe",
  "oifde.navbar.password.newPassword": "Nouveau mot de passe",
  "oifde.navbar.password.confirmNewPassword": "Confirmer le mot de passe",
  "oifde.navbar.password.passwordExpired": "Votre mot de passe a expiré. Modifiez-le.",
  "oifde.navbar.password.firstTimeLogin": "Vous utilisez le mot de passe initial. Modifiez-le.",
  "oifde.navbar.password.passwordWillExpire": "Votre mot de passe est sur le point d'expirer. Modifiez-le.",
  "oifde.navbar.password.passwordNotSame": "Les deux mots de passe sont différents.",
  "oifde.journeylogmgr.flowAnalysisMrg": "Paramètres d'analyse de processus",
  "oifde.journeylogmgr.accessCodeSetting": "Paramètres du code d'accès",
  "oifde.trackmgr.track": "Paramètres de piste",
  "oifde.trackmgr.accessCodeTrack": "Code d'accès de piste",
  "oifde.trackmgr.callingNumberTrack": "Suivre le numéro d'appel",
  "oifde.trackmgr.callingNumber": "Numéro d'appel",
  "oifde.trackmgr.accessCode": "Code d'accès",
  "oifde.trackmgr.choose": "-Sélectionner-",
  "oifde.trackmgr.validate.accessCode": "Sélectionnez le code d'accès.",
  "oifde.trackmgr.validate.callingNumber": "Entrez un numéro d'appel.",
  "oifde.trackmgr.validate.needNumber": "Entrez un nombre.",
  "oifde.trackmgr.validate.callingNumberLength": "Le numéro appelant peut contenir un maximum de 64 caractères.",
  "oifde.trackmgr.validate.callingNumberDub": "Le numéro d'appel doit être unique.",
  "oifde.trackmgr.validate.pattern": "Seules les lettres, les chiffres, les espaces et les caractères spéciaux suivants sont autorisés",
  "oifde.trackmgr.actions.delete": "Supprimer",
  "oifde.trackmgr.actions.success": "Succès",
  "oifde.trackmgr.actions.updateSuccess": "Modification réussie.",
  "oifde.trackmgr.actions.add": "Ajouter",
  "oifde.trackmgr.actions.deleteSuccess": "Suppression réussie.",
  "oifde.trackmgr.actions.cancelDelete": "La suppression est annulée.",
  "oifde.trackmgr.actions.cancelUpdate": "Modification annulée.",
  "oifde.trackmgr.actions.deletePrompt": "Cette opération supprimera définitivement les données de la piste. êtes-vous sûr de vouloir continuer ?",
  "oifde.trackmgr.actions.updatePrompt": "Cette configuration sera automatiquement désactivée {0} heure(s) plus tard, ce qui affectera les performances du système. êtes-vous sûr de vouloir continuer ?",
  "oifde.trackmgr.actions.prompt": "Confirmer",
  "oifde.trackmgr.actions.confirm": "Oui",
  "oifde.trackmgr.actions.cancel": "Non",
  "oifde.trace.sessionLog": "Requête du journal de la boîte de dialogue",
  "oifde.trace.traceLog": "Requête de piste de journal",
  "oifde.trace.beginTime": "Heure de début",
  "oifde.trace.endTime": "Heure de fin",
  "oifde.trace.callingNumber": "Numéro d'appel",
  "oifde.trace.calledNumber": "Numéro appelé",
  "oifde.trace.createTime": "Créé",
  "oifde.trace.flowId": "ID de flux",
  "oifde.trace.flowName": "Nom du flux",
  "oifde.trace.logInfo": "Informations de journal",
  "oifde.trace.errorInfo": "Informations sur les erreurs",
  "oifde.trace.callId": "ID d'instance d'appel",
  "oifde.trace.nodeId": "ID de nœud",
  "oifde.trace.nodeName": "Nom du nœud",
  "oifde.trace.actionContent": "Nom de l'instruction",
  "oifde.trace.nodeIndex": "Nœud d'exécution No.",
  "oifde.trace.ivrRequest": "Demande IVR",
  "oifde.trace.actionParams": "Informations sur le paramètre du nœud d'exécution",
  "oifde.trace.processResult": "Résultat de l'exécution",
  "oifde.trace.transitionOn": "Branche des résultats d'exécution",
  "oifde.trace.reset": "Réinitialiser",
  "oifde.trace.validate.needNumber": "Entrez des chiffres ou des lettres.",
  "oifde.trace.validate.callingNumber": "Entrez un numéro d'appel.",
  "oifde.trace.validate.callingNumberLength": "Le numéro appelant peut contenir un maximum de 64 caractères.",
  "oifde.trace.clickToDetail": "Cliquez pour en voir plus.",
  "oifde.trace.clickToHideDetail": "Cliquez pour afficher moins.",
  "oifde.trace.currentNodeInfo": "Informations actuelles sur le nœud",
  "oifde.trace.clickToFlow": "Affichage des pistes",
  "oifde.trace.nodeTrace": "Informations sur le journal des nœuds",
  "oifde.trace.nodeParams": "Paramètres d'entrée et de sortie de nœud",
  "oifde.trace.traceReason": "Cause de l'erreur",
  "oifde.trace.traceAdvice": "La solution",
  "oifde.trace.reasonAdvice": "Cause et suggestion",
  "oifde.expression.epxr_input_head": "Longueur maximale",
  "oifde.expression.expr_type_illegal": "Le format de l'expression n'est pas valide.",
  "oifde.expression.expr_key_repeats": "Dupliquer le nom de la condition.",
  "oifde.expression.expr_var": "Entrez une variable. Démarrez la valeur avec GLOBAL (pour une variable globale), FLOW (pour une variable de flux), SYS (pour une variable système) ou TOC (pour une variable TUC).",
  "oifde.expression.expr_var_fag": "Entrez une variable. Démarrer la valeur avec GLOBAL (pour une variable globale) FLOW (pour une variable de flux).",
  "oifde.expression.expr_var_string": "Saisissez une variable de type chaîne. Démarrez la valeur avec GLOBAL (pour une variable globale), FLOW (pour une variable de flux), SYS (pour une variable système) ou TOC (pour une variable TUC).",
  "oifde.expression.expr_number": "Entrez une valeur du type numérique. Les opérateurs disponibles entre les valeurs sont + – * et /.",
  "oifde.expression.expr_number_noAction": "Entrez une valeur du type numérique.",
  'oifde.expression.expr_var_list': 'Saisissez une variable de liste.',
  'oifde.expression.expr_var_number': 'Entrez une variable int.',
  'oifde.expression.allVar': 'Démarrez la valeur avec GLOBAL (pour une variable globale), FLOW (pour une variable de flux), SYS (pour une variable système) ou TOC (pour une variable TUC).',
  'oifde.expression.flowAndGlobalVar': 'Démarrer la valeur avec GLOBAL (pour une variable globale) FLOW (pour une variable de flux).',
  "oifde.expression.expr_string": "Entrez une valeur du type de chaîne. Enfermer la valeur entre guillemets simples ou doubles (L'opérateur de chaîne de caractères est le signe plus (+).",
  "oifde.expression.expr_simple_string": "Saisissez une variable chaîne de caractères ou n'importe quelle chaîne de caractères. Ne pas placer la chaîne de caractères entre guillemets simples ou doubles (.",
  "oifde.expression.varFlow": "Variable de débit",
  "oifde.expression.varGlobal": "Variable globale",
  "oifde.expression.varToc": "Intent variable",
  "oifde.expression.varSys": "Variable système",
  "oifde.expression.varIvrRequest": "Variable de requête IVR",
  "oifde.expression.expr_var_paramName": "Entrez une variable. Démarrez la valeur avec GLOBAL (pour une variable globale) ou FLOW (pour une variable de flux).",
  "oifde.procedure.procedureMgr": "Procédures stockées",
  "oifde.procedure.procedureDBMgr": "Bases de données de procédures stockées",
  "oifde.procedure.procedureName": "Procédure stockée",
  "oifde.procedure.procedureDesc": "Descriptif",
  "oifde.procedure.procedureValue": "Valeur",
  "oifde.procedure.operation": "Opération",
  "oifde.procedure.baseInfo": "Informations de base",
  "oifde.procedure.in_param": "Paramètre d'entrée",
  "oifde.procedure.paramName": "Paramètre",
  "oifde.procedure.paramDesc": "Descriptif",
  "oifde.procedure.dataType": "Type de données",
  "oifde.procedure.out_param": "Paramètre de sortie",
  "oifde.procedure.addProcedureInterface": "Ajouter une procédure stockée",
  "oifde.procedure.modifyProcedureInterface": "Modifier la procédure stockée",
  "oifde.procedure.addProcedureDB": "Ajouter une base de données de procédures stockées",
  "oifde.procedure.modifyProcedureDB": "Modifier la base de données de procédures stockées",
  "oifde.procedure.required": "Ce champ est obligatoire.",
  "oifde.procedure.urlRule": "Url doit commencer par jdbc",
  "oifde.procedure.actions.editList": "Modifier le type de données dans la liste",
  "oifde.procedure.actions.edit": "Modifier",
  "oifde.procedure.actions.delete": "Supprimer",
  "oifde.procedure.actions.success": "Succès",
  "oifde.procedure.actions.createSuccess": "Création réussie.",
  "oifde.procedure.actions.updateSuccess": "Modification réussie.",
  "oifde.procedure.actions.deleteSuccess": "Suppression réussie.",
  "oifde.procedure.actions.cancelDelete": "La suppression est annulée.",
  "oifde.procedure.actions.deletePrompt": "Cette opération supprimera définitivement la procédure stockée. êtes-vous sûr de vouloir continuer ?",
  "oifde.procedure.actions.prompt": "Confirmer",
  "oifde.procedure.actions.confirm": "Oui",
  "oifde.procedure.actions.cancel": "Non",
  "oifde.procedure.actions.ok": "OK",
  "oifde.procedure.actions.miss_pro_name": "Le champ obligatoire suivant est vide",
  "oifde.procedure.actions.miss_pro_value": "Le champ obligatoire suivant est vide",
  "oifde.procedure.actions.miss_pro_in_parameter_name": "Le champ obligatoire suivant est vide",
  "oifde.procedure.actions.miss_pro_out_parameter_name": "Le champ obligatoire suivant est vide",
  'oifde.procedure.actions.miss_pro_dbId': 'Champ obligatoire manquant : connexion à la base de données.',
  "oifde.procedure.dataTypes.INT": "Entier",
  "oifde.procedure.dataTypes.STRING": "Caractère",
  "oifde.procedure.dataTypes.FLOAT": "Nombre à virgule flottante",
  "oifde.procedure.dataTypes.LONG": "Long entier",
  "oifde.procedure.dataTypes.LIST": "Liste",
  "oifde.procedure.db.dbType": "Type de base de données",
  "oifde.procedure.db.dbDriver": "Pilote de base de données",
  "oifde.procedure.db.dbUrl": "URL de la base de données",
  "oifde.procedure.db.dbUser": "Utilisateur de base de données",
  "oifde.procedure.db.dbPassword": "Mot de passe de la base de données",
  "oifde.procedure.dbTypes.MYSQL": "MySQL",
  "oifde.procedure.dbTypes.GAUSS": "GaussDB",
  "oifde.procedure.dbTypes.ORACLE": "Oracle",
  "oifde.procedure.ruleMysql": "La base de données MySQL doit commencer par jdbc:mysql://",
  "oifde.procedure.ruleGauss": "La base de données GAUSS doit commencer par jdbc:zenith:{'@'}",
  "oifde.procedure.ruleOracle": "La base de données Oracle doit commencer par jdbc:oracle:thin:{'@'}.",
  "oifde.procedure.ruleRange": "L'URL ne peut contenir que des lettres chiffres et les caractères spéciaux suivants",
  "oifde.procedure.oracleRuleRange": "L'URL ne peut contenir que des lettres chiffres et les caractères spéciaux suivants",
  "oifde.procedure.driverRule": "La valeur ne peut contenir que des lettres, des chiffres, des (.) de points et des traits d'union.",
  "oifde.procedure.driverStart": "La valeur ne peut pas commencer par un (.) de point ou un (-) de trait d'union.",
  "oifde.procedure.driverEnd": "La valeur ne peut pas se terminer par une (.) de point ou une (-) de trait d'union.",
  "oifde.procedure.ruleLength": "Entrez l'adresse IP et le numéro de port dans l'URL.",
  "oifde.procedure.dbUrlWarning": "*Notez que l'adresse IP, le numéro de port ou le nom de domaine dans l'URL de la base de données ne sont pas dans la liste blanche. Vous devez contacter l'administrateur système pour ajouter l'adresse IP, le numéro de port ou le nom de domaine.",
  "oifde.complexcell.cellTitle": "élément de diagramme composite",
  "oifde.complexcell.catalogTitle": "Catalogue d'éléments de diagramme composite",
  "oifde.complexcell.catalog.name": "Nom du catalogue",
  "oifde.complexcell.catalog.desc": "Description du catalogue",
  "oifde.complexcell.catalog.tips.sizeLimit": "Un maximum de cinq catalogues sont autorisés.",
  "oifde.complexcell.cell.name": "Nom de la cellule",
  "oifde.complexcell.cell.desc": "Descriptif",
  "oifde.complexcell.cell.height": "Hauteur",
  "oifde.complexcell.cell.width": "Largeur",
  "oifde.complexcell.cell.icon": "Symbole",
  "oifde.complexcell.cell.catalog": "Catalogues",
  "oifde.complexcell.cell.actions.editInfo": "Modifier les informations",
  "oifde.complexcell.cell.actions.editDiagram": "Modifier la toile",
  "oifde.complexcell.cell.actions.copy": "Répliquer",
  "oifde.complexcell.cell.validation.bigFile": "La taille du fichier ne peut pas dépasser {0}.",
  'oifde.complexcell.cell.viewReference':'Voir les références',
  'oifde.complexcell.cell.actions.copySuccess':'Réplication réussie',
  "oifde.table.search": "Rechercher",
  "oifde.table.add": "Ajouter",
  "oifde.table.actions": "Actions",
  "oifde.table.cancel": "Annuler",
  "oifde.table.confirm": "Confirmer",
  "oifde.table.create": "Créer",
  "oifde.table.delete": "Supprimer",
  "oifde.table.manage": "Gérer",
  "oifde.table.save": "Enregistrer",
  "oifde.table.reset": "Réinitialiser",
  "oifde.table.filter": "Filtrer",
  "oifde.tagsView.refresh": "Actualiser",
  "oifde.tagsView.close": "Fermer",
  "oifde.tagsView.closeOthers": "Fermer Autres",
  "oifde.tagsView.closeAll": "Fermer tout",
  "oifde.mxgraph.failInfo": "Impossible d'interroger les données.",
  "oifde.mxgraph.alreadyConnected": "Nœuds déjà connectés",
  "oifde.mxgraph.cancel": "Annuler",
  "oifde.mxgraph.close": "Fermer",
  "oifde.mxgraph.'collapse-expand'": "Réduire/Développer",
  "oifde.mxgraph.containsValidationErrors": "Erreur de validation trouvée",
  "oifde.mxgraph.done": "Terminé",
  "oifde.mxgraph.doubleClickOrientation": "Double-cliquez pour modifier l'orientation",
  "oifde.mxgraph.error": "Erreur",
  "oifde.mxgraph.errorSavingFile": "Impossible d'enregistrer le fichier.",
  "oifde.mxgraph.ok": "OK",
  "oifde.mxgraph.updatingDocument": "Mise à jour du document. Veuillez patienter...",
  "oifde.mxgraph.updatingSelection": "Mise à jour de la sélection. Veuillez patienter...",
  "oifde.mxgraph.about": "à propos",
  "oifde.mxgraph.actualSize": "Taille réelle",
  "oifde.mxgraph.add": "Ajouter",
  "oifde.mxgraph.addLayer": "Ajouter un calque",
  "oifde.mxgraph.addProperty": "Ajouter une propriété",
  "oifde.mxgraph.addToExistingDrawing": "Ajouter au dessin existant",
  "oifde.mxgraph.addWaypoint": "Ajouter un point de cheminement",
  "oifde.mxgraph.advanced": "Avancé",
  "oifde.mxgraph.align": "Aligner",
  "oifde.mxgraph.alignment": "Alignement",
  "oifde.mxgraph.allChangesLost": "Tous les changements seront perdus.",
  "oifde.mxgraph.angle": "Angle",
  "oifde.mxgraph.apply": "Appliquer",
  "oifde.mxgraph.arc": "Arc",
  "oifde.mxgraph.arrange": "Organiser",
  "oifde.mxgraph.arrow": "Flèche",
  "oifde.mxgraph.arrows": "Flèches",
  "oifde.mxgraph.automatic": "Automatique",
  "oifde.mxgraph.autosave": "Enregistrement automatique",
  "oifde.mxgraph.autosize": "Taille automatique",
  "oifde.mxgraph.background": "Contexte",
  "oifde.mxgraph.backgroundColor": "Couleur de fond",
  "oifde.mxgraph.backgroundImage": "Image de fond",
  "oifde.mxgraph.basic": "Basique",
  "oifde.mxgraph.block": "Blocage",
  "oifde.mxgraph.blockquote": "Blockquote",
  "oifde.mxgraph.bold": "Gras",
  "oifde.mxgraph.borderWidth": "Largeur de bordure",
  "oifde.mxgraph.borderColor": "Couleur de la bordure",
  "oifde.mxgraph.bottom": "Bas",
  "oifde.mxgraph.bottomAlign": "Alignement inférieur",
  "oifde.mxgraph.bottomLeft": "En bas à gauche",
  "oifde.mxgraph.bottomRight": "En bas à droite",
  "oifde.mxgraph.bulletedList": "Liste à puces",
  "oifde.mxgraph.cannotOpenFile": "Impossible d'ouvrir le fichier.",
  "oifde.mxgraph.center": "Alignement vertical",
  "oifde.mxgraph.change": "Changer",
  "oifde.mxgraph.changeOrientation": "Changer l'orientation",
  "oifde.mxgraph.circle": "Cercle",
  "oifde.mxgraph.classic": "Classique",
  "oifde.mxgraph.clearDefaultStyle": "Effacer le style par défaut",
  "oifde.mxgraph.clearWaypoints": "Effacer les points de cheminement",
  "oifde.mxgraph.clipart": "Clipart",
  "oifde.mxgraph.collapse": "Réduire",
  "oifde.mxgraph.collapseExpand": "Réduire/Développer",
  "oifde.mxgraph.collapsible": "Pliable",
  "oifde.mxgraph.comic": "Bande dessinée",
  "oifde.mxgraph.connect": "Connectez-vous",
  "oifde.mxgraph.connection": "Connexion",
  "oifde.mxgraph.connectionPoints": "Points de connexion",
  "oifde.mxgraph.connectionArrows": "Flèches de connexion",
  "oifde.mxgraph.constrainProportions": "Proportions de contraintes",
  "oifde.mxgraph.copy": "Copier",
  "oifde.mxgraph.copyConnect": "Copier la connexion",
  "oifde.mxgraph.create": "Créer",
  "oifde.mxgraph.curved": "Courbé",
  "oifde.mxgraph.custom": "Personnalisé",
  "oifde.mxgraph.cut": "Couper",
  "oifde.mxgraph.dashed": "Pointillé",
  "oifde.mxgraph.decreaseIndent": "Diminuer l'indentation",
  "oifde.mxgraph.default": "Par défaut",
  "oifde.mxgraph.delete": "Supprimer",
  "oifde.mxgraph.deleteColumn": "Supprimer la colonne",
  "oifde.mxgraph.deleteRow": "Supprimer une ligne",
  "oifde.mxgraph.diagram": "Diagramme",
  "oifde.mxgraph.diamond": "Diamant",
  "oifde.mxgraph.diamondThin": "Diamant (mince)",
  "oifde.mxgraph.direction": "Direction",
  "oifde.mxgraph.distribute": "Distribuer",
  "oifde.mxgraph.divider": "Diviseur",
  "oifde.mxgraph.documentProperties": "Propriétés du document",
  "oifde.mxgraph.dotted": "Pointillé",
  "oifde.mxgraph.drawing": "Dessin{0}",
  "oifde.mxgraph.drawingEmpty": "Le dessin est vide.",
  "oifde.mxgraph.drawingTooLarge": "Le diagramme est trop grand.",
  "oifde.mxgraph.duplicate": "Dupliquer",
  "oifde.mxgraph.duplicateIt": "Dupliquer {0}",
  "oifde.mxgraph.east": "Est",
  "oifde.mxgraph.edit": "Modifier",
  "oifde.mxgraph.editData": "Modifier les données",
  "oifde.mxgraph.editDiagram": "Source",
  "oifde.mxgraph.editImage": "Modifier l'image",
  "oifde.mxgraph.editLink": "Modifier le lien",
  "oifde.mxgraph.editStyle": "Modifier le style",
  "oifde.mxgraph.editTooltip": "Modifier l'infobulle",
  "oifde.mxgraph.enterGroup": "Entrer dans le groupe",
  "oifde.mxgraph.enterValue": "Entrez la valeur",
  "oifde.mxgraph.enterName": "Entrez le nom",
  "oifde.mxgraph.enterPropertyName": "Entrez le nom de la propriété",
  "oifde.mxgraph.entityRelation": "Relation d'entité",
  "oifde.mxgraph.exitGroup": "Quitter le groupe",
  "oifde.mxgraph.expand": "Développer",
  "oifde.mxgraph.export": "Exporter",
  "oifde.mxgraph.extras": "Extras",
  "oifde.mxgraph.file": "Fichier",
  "oifde.mxgraph.fileNotFound": "Fichier non trouvé",
  "oifde.mxgraph.filename": "Nom de fichier",
  "oifde.mxgraph.fill": "Remplir",
  "oifde.mxgraph.fillColor": "Couleur de remplissage",
  "oifde.mxgraph.fitPage": "Une page",
  "oifde.mxgraph.fitPageWidth": "Largeur de la page",
  "oifde.mxgraph.fitTwoPages": "Deux pages",
  "oifde.mxgraph.fitWindow": "Fenêtre d'ajustement",
  "oifde.mxgraph.flip": "Retourner",
  "oifde.mxgraph.flipH": "Retourner horizontal",
  "oifde.mxgraph.flipV": "Renverser la verticale",
  "oifde.mxgraph.font": "Police",
  "oifde.mxgraph.fontFamily": "Famille de polices",
  "oifde.mxgraph.fontColor": "Couleur de police",
  "oifde.mxgraph.fontSize": "Taille de police",
  "oifde.mxgraph.format": "Format",
  "oifde.mxgraph.formatPanel": "Panneau de formatage",
  "oifde.mxgraph.formatPdf": "PDF",
  "oifde.mxgraph.formatPng": "PNG",
  "oifde.mxgraph.formatGif": "GIF",
  "oifde.mxgraph.formatJpg": "JPEG",
  "oifde.mxgraph.formatSvg": "SVG",
  "oifde.mxgraph.formatXml": "XML",
  "oifde.mxgraph.formatted": "Formaté",
  "oifde.mxgraph.formattedText": "Texte formaté",
  "oifde.mxgraph.gap": "écart",
  "oifde.mxgraph.general": "Généralités",
  "oifde.mxgraph.glass": "Verre",
  "oifde.mxgraph.global": "Global",
  "oifde.mxgraph.gradient": "Dégradé",
  "oifde.mxgraph.gradientColor": "Couleur",
  "oifde.mxgraph.grid": "Grille",
  "oifde.mxgraph.gridSize": "Taille de la grille",
  "oifde.mxgraph.group": "Groupe",
  "oifde.mxgraph.guides": "Guides",
  "oifde.mxgraph.heading": "Rubrique",
  "oifde.mxgraph.height": "Hauteur",
  "oifde.mxgraph.help": "Aide",
  "oifde.mxgraph.hide": "Masquer",
  "oifde.mxgraph.hideIt": "Masquer {0}",
  "oifde.mxgraph.hidden": "Caché",
  "oifde.mxgraph.home": "Accueil",
  "oifde.mxgraph.horizontal": "Horizontal",
  "oifde.mxgraph.horizontalFlow": "Débit horizontal",
  "oifde.mxgraph.horizontalTree": "Arbre horizontal",
  "oifde.mxgraph.html": "HTML",
  "oifde.mxgraph.image": "L'image",
  "oifde.mxgraph.images": "Images",
  "oifde.mxgraph.import": "Importer",
  "oifde.mxgraph.increaseIndent": "Augmenter l'indentation",
  "oifde.mxgraph.insert": "Insérer",
  "oifde.mxgraph.insertColumnBefore": "Insérer une colonne à gauche",
  "oifde.mxgraph.insertColumnAfter": "Insérer une colonne à droite",
  "oifde.mxgraph.insertHorizontalRule": "Insérer une règle horizontale",
  "oifde.mxgraph.insertImage": "Insérer une image",
  "oifde.mxgraph.insertLink": "Insérer un lien",
  "oifde.mxgraph.insertRowBefore": "Insérer une ligne au-dessus",
  "oifde.mxgraph.insertRowAfter": "Insérer une ligne ci-dessous",
  "oifde.mxgraph.invalidName": "Nom non valide",
  "oifde.mxgraph.invalidOrMissingFile": "Le fichier est manquant ou invalide.",
  "oifde.mxgraph.isometric": "Isométrique",
  "oifde.mxgraph.italic": "Italique",
  "oifde.mxgraph.layers": "Couches",
  "oifde.mxgraph.landscape": "Paysage",
  "oifde.mxgraph.laneColor": "Lanecolor",
  "oifde.mxgraph.layout": "Mise en page",
  "oifde.mxgraph.left": "Gauche",
  "oifde.mxgraph.leftAlign": "Aligner à gauche",
  "oifde.mxgraph.leftToRight": "De gauche à droite",
  "oifde.mxgraph.line": "Ligne",
  "oifde.mxgraph.link": "Lien",
  "oifde.mxgraph.lineJumps": "Sauts de ligne",
  "oifde.mxgraph.lineend": "Fin de ligne",
  "oifde.mxgraph.lineheight": "Hauteur de ligne",
  "oifde.mxgraph.linestart": "Début de ligne",
  "oifde.mxgraph.linewidth": "Largeur de ligne",
  "oifde.mxgraph.loading": "Chargement",
  "oifde.mxgraph.lockUnlock": "Verrouiller/Déverrouiller",
  "oifde.mxgraph.manual": "Manuelle",
  "oifde.mxgraph.middle": "Alignement horizontal",
  "oifde.mxgraph.misc": "Divers",
  "oifde.mxgraph.more": "Plus",
  "oifde.mxgraph.moreResults": "Plus de résultats",
  "oifde.mxgraph.move": "Déplacer",
  "oifde.mxgraph.moveSelectionTo": "Déplacer la sélection vers {0}",
  "oifde.mxgraph.navigation": "La navigation",
  "oifde.mxgraph.new": "Nouveau",
  "oifde.mxgraph.noColor": "Pas de couleur.",
  "oifde.mxgraph.noFiles": "Pas de fichiers.",
  "oifde.mxgraph.noMoreResults": "Plus de résultats.",
  "oifde.mxgraph.none": "Aucun",
  "oifde.mxgraph.noResultsFor": "Aucun résultat pour {0}",
  "oifde.mxgraph.normal": "Normal",
  "oifde.mxgraph.north": "Nord",
  "oifde.mxgraph.numberedList": "Liste numérotée",
  "oifde.mxgraph.opacity": "Opacité",
  "oifde.mxgraph.open": "Ouvrir",
  "oifde.mxgraph.openArrow": "Ouvrir la flèche",
  "oifde.mxgraph.openFile": "Ouvrir le fichier",
  "oifde.mxgraph.openLink": "Ouvrir le lien",
  "oifde.mxgraph.openSupported": "Seuls les fichiers XML exportés à partir de ce système sont pris en charge.",
  "oifde.mxgraph.openInNewWindow": "Ouvrir dans une nouvelle fenêtre",
  "oifde.mxgraph.openInThisWindow": "Ouvrir dans cette fenêtre",
  "oifde.mxgraph.options": "Options",
  "oifde.mxgraph.organic": "Biologique",
  "oifde.mxgraph.orthogonal": "Orthogonal",
  "oifde.mxgraph.outline": "Contour",
  "oifde.mxgraph.oval": "Ovale",
  "oifde.mxgraph.pages": "Pages",
  "oifde.mxgraph.pageView": "Affichage de la page",
  "oifde.mxgraph.pageScale": "échelle de page",
  "oifde.mxgraph.pageSetup": "Mise en page",
  "oifde.mxgraph.panTooltip": "Espace + glisser pour faire défiler",
  "oifde.mxgraph.paperSize": "Taille de papier",
  "oifde.mxgraph.paste": "Coller",
  "oifde.mxgraph.pasteHere": "Coller ici",
  "oifde.mxgraph.pattern": "Modèle",
  "oifde.mxgraph.perimeter": "Périmètre",
  "oifde.mxgraph.placeholders": "Placeholder",
  "oifde.mxgraph.plusTooltip": "Cliquez pour se connecter et cloner (ctrl+cliquer pour cloner shift+cliquer pour se connecter). Faites glisser pour se connecter (ctrl+drag pour cloner).",
  "oifde.mxgraph.portrait": "Portrait",
  "oifde.mxgraph.position": "Position",
  "oifde.mxgraph.posterPrint": "Impression d'affiches",
  "oifde.mxgraph.preview": "Aperçu",
  "oifde.mxgraph.print": "Imprimer",
  "oifde.mxgraph.radialTree": "Arbre radial",
  "oifde.mxgraph.redo": "Refaire",
  "oifde.mxgraph.remindSave": "Vous n'avez pas sauvegardé les données depuis longtemps. Sauvegardez les données rapidement.",
  "oifde.mxgraph.removeFormat": "Effacer la mise en forme",
  "oifde.mxgraph.removeFromGroup": "Retirer du groupe",
  "oifde.mxgraph.removeIt": "Supprimer {0}",
  "oifde.mxgraph.removeWaypoint": "Supprimer Waypoint",
  "oifde.mxgraph.rename": "Renommer",
  "oifde.mxgraph.renameIt": "Renommer {0}",
  "oifde.mxgraph.replace": "Remplacer",
  "oifde.mxgraph.replaceIt": "{0} existe déjà. Voulez-vous le remplacer?",
  "oifde.mxgraph.replaceExistingDrawing": "Remplacer le dessin existant",
  "oifde.mxgraph.reset": "Réinitialiser",
  "oifde.mxgraph.resetView": "Réinitialiser la vue",
  "oifde.mxgraph.reverse": "Inverser",
  "oifde.mxgraph.right": "Droite",
  "oifde.mxgraph.rightAlign": "Aligner à droite",
  "oifde.mxgraph.rightToLeft": "De droite à gauche",
  "oifde.mxgraph.rotate": "Rotation",
  "oifde.mxgraph.rotateTooltip": "Cliquez et faites glisser pour pivoter et cliquez pour tourner de 90 degrés",
  "oifde.mxgraph.rotation": "Rotation",
  "oifde.mxgraph.rounded": "Arrondi",
  "oifde.mxgraph.save": "Enregistrer",
  "oifde.mxgraph.saveAs": "Enregistrer sous",
  "oifde.mxgraph.saved": "Enregistré",
  "oifde.mxgraph.scrollbars": "Barres de défilement",
  "oifde.mxgraph.search": "Rechercher",
  "oifde.mxgraph.searchShapes": "Nœuds de recherche",
  "oifde.mxgraph.selectAll": "Sélectionner tout",
  "oifde.mxgraph.selectEdges": "Sélectionner les bords",
  "oifde.mxgraph.selectFont": "Sélectionner une police",
  "oifde.mxgraph.selectNone": "Sélectionner Aucun",
  "oifde.mxgraph.selectVertices": "Sélectionner des sommets",
  "oifde.mxgraph.setAsDefaultStyle": "Définir comme style par défaut",
  "oifde.mxgraph.shadow": "Ombre",
  "oifde.mxgraph.shape": "Forme",
  "oifde.mxgraph.sharp": "Sharp",
  "oifde.mxgraph.sidebarTooltip": "Cliquez sur pour développer ou réduire le panneau des nœuds.",
  "oifde.mxgraph.simple": "Simple",
  "oifde.mxgraph.simpleArrow": "Flèche simple",
  "oifde.mxgraph.size": "Taille",
  "oifde.mxgraph.solid": "Solide",
  "oifde.mxgraph.sourceSpacing": "Espacement des sources",
  "oifde.mxgraph.south": "Sud",
  "oifde.mxgraph.spacing": "Espacement",
  "oifde.mxgraph.straight": "Droite",
  "oifde.mxgraph.strokeColor": "Couleur de la ligne",
  "oifde.mxgraph.style": "Le style",
  "oifde.mxgraph.subscript": "Indice",
  "oifde.mxgraph.superscript": "Exposé",
  "oifde.mxgraph.table": "Tableau",
  "oifde.mxgraph.targetSpacing": "Espacement de la cible",
  "oifde.mxgraph.text": "Texte",
  "oifde.mxgraph.textAlignment": "Alignement du texte",
  "oifde.mxgraph.textOpacity": "Opacité du texte",
  "oifde.mxgraph.toBack": "Vers Retour",
  "oifde.mxgraph.toFront": "Vers l'avant",
  "oifde.mxgraph.tooltips": "Info-bulles",
  "oifde.mxgraph.top": "Haut de page",
  "oifde.mxgraph.topAlign": "Alignement supérieur",
  "oifde.mxgraph.topLeft": "En haut à gauche",
  "oifde.mxgraph.topRight": "En haut à droite",
  "oifde.mxgraph.transparent": "Transparent",
  "oifde.mxgraph.turn": "Tournez 90 degrés",
  "oifde.mxgraph.uml": "UML",
  "oifde.mxgraph.underline": "Souligné",
  "oifde.mxgraph.undo": "Annuler",
  "oifde.mxgraph.ungroup": "Dégrouper",
  "oifde.mxgraph.url": "URL",
  "oifde.mxgraph.untitledLayer": "Couche sans titre",
  "oifde.mxgraph.vertical": "Verticale",
  "oifde.mxgraph.verticalFlow": "écoulement vertical",
  "oifde.mxgraph.verticalTree": "Arbre vertical",
  "oifde.mxgraph.view": "Voir",
  "oifde.mxgraph.waypoints": "Points de cheminement",
  "oifde.mxgraph.west": "Ouest",
  "oifde.mxgraph.width": "Largeur",
  "oifde.mxgraph.wordWrap": "Wrap de mots",
  "oifde.mxgraph.writingDirection": "Direction d'écriture",
  "oifde.mxgraph.zoom": "Zoom",
  "oifde.mxgraph.zoomIn": "Zoom avant",
  "oifde.mxgraph.zoomOut": "Zoom arrière",
  "oifde.mxgraph.success": "Succès",
  "oifde.mxgraph.prompt": "Inviter",
  "oifde.mxgraph.confirm": "OK",
  "oifde.mxgraph.NMS_DiagramProperties": "Attribut de processus",
  "oifde.mxgraph.NMS_BasicProperties": "Attribut de base",
  "oifde.mxgraph.NMS_DiagramID": "ID de processus",
  "oifde.mxgraph.NMS_Status": "Statut",
  "oifde.mxgraph.NMS_DiagramCode": "Code de processus",
  "oifde.mxgraph.NMS_Valid": "Valide",
  "oifde.mxgraph.NMS_Invalid": "Invalide",
  "oifde.mxgraph.NMS_DiagramName": "Nom du processus",
  "oifde.mxgraph.NMS_Region": "Région",
  "oifde.mxgraph.NMS_ScenarioType": "Type de scénario",
  "oifde.mxgraph.NMS_Desciption": "Descriptif",
  "oifde.mxgraph.NMS_NodeProperties": "Attribut de nœud",
  "oifde.mxgraph.NMS_NodeCode": "Code de nœud",
  "oifde.mxgraph.NMS_NodeName": "Nom du nœud",
  "oifde.mxgraph.NMS_BranchProperties": "Attribut de branche",
  "oifde.mxgraph.NMS_BranchCondition": "état de la branche",
  "oifde.mxgraph.NMS_DefaultBranch": "Branche par défaut",
  "oifde.mxgraph.NMS_AdvanceProperties": "Attribut avancé",
  "oifde.mxgraph.NMS_ServiceName": "Nom du service",
  "oifde.mxgraph.NMS_ServiceParams": "Paramètre de service",
  "oifde.mxgraph.NMS_ServiceDesciption": "Description du service",
  "oifde.mxgraph.NMS_PleaseSelect": "Veuillez sélectionner",
  "oifde.mxgraph.NMS_ParamName": "Paramètre",
  "oifde.mxgraph.NMS_ParamValue": "Valeur",
  "oifde.mxgraph.NMS_ParamDescription": "Descriptif",
  "oifde.mxgraph.NMS_FlowSaveSuccess": "Le flux est sauvegardé avec succès.",
  "oifde.mxgraph.NMS_FlowSaveFail": "Impossible d'enregistrer le flux.",
  "oifde.mxgraph.NMS_FlowOpenFail": "Impossible d'ouvrir le flux.",
  "oifde.mxgraph.NMS_ActionTypesGetFail": "Impossible d'obtenir les informations sur les éléments du diagramme.",
  "oifde.mxgraph.NMS_ActionParamsGetFail": "Impossible d'obtenir les paramètres des éléments du diagramme.",
  "oifde.mxgraph.NMS_FlowsGetFail": "Impossible d'obtenir la liste des flux.",
  "oifde.mxgraph.NMS_NodesGetFail": "Impossible d'obtenir la liste des nœuds de flow {0}.",
  "oifde.mxgraph.NMS_InterfacesGetFail": "Impossible d'obtenir la liste des interfaces d'accès.",
  "oifde.mxgraph.NMS_ContentTemplateGetFail": "Impossible d'obtenir la liste des modèles de contenu.",
  "oifde.mxgraph.NMS_EditorModelLoadFail": "Impossible de charger la configuration du modèle de l'éditeur.",
  "oifde.mxgraph.NMS_RegionInfoLoadFail": "Impossible de charger les informations de région.",
  "oifde.mxgraph.NMS_IntentCodeLoadFail": "Impossible de charger le code d'intention.",
  "oifde.mxgraph.NMS_PleaseSelectTemplate": "Sélectionnez un modèle.",
  "oifde.mxgraph.NMS_TargetFlow": "Flux cible",
  "oifde.mxgraph.NMS_TargetNode": "Nœud cible",
  "oifde.mxgraph.NMS_TemplateCode": "Code de modèle",
  "oifde.mxgraph.NMS_flow": "Flux",
  "oifde.mxgraph.NMS_ScenarioType_main": "Flux principal",
  "oifde.mxgraph.NMS_ScenarioType_sub": "Sous-flux",
  "oifde.mxgraph.NMS_ScenarioType_error": "Scénario d'exception",
  "oifde.mxgraph.NMS_flowCodeError": "Le code de flux ne peut pas être vide ou dépasser 64 caractères.",
  "oifde.mxgraph.NMS_flowNameError": "Le nom du flux ne peut pas être vide ou dépasser 1000 caractères.",
  "oifde.mxgraph.NMS_flowDescError": "La description du flux peut contenir un maximum de 1000 caractères.",
  "oifde.mxgraph.NMS_nmsFlowError": "échec de l'analyse de flux. Le nœud nmsFlow n'est pas trouvé.",
  "oifde.mxgraph.NMS_rootError": "échec de l'analyse de flux. Le nœud racine n'est pas trouvé.",
  "oifde.mxgraph.NMS_VariableNameError": "Le nom de la variable ne peut pas contenir plus de 1000 caractères.",
  "oifde.mxgraph.NMS_VariableDescError": "La description de la variable ne peut pas contenir plus de 1000 caractères.",
  "oifde.mxgraph.NMS_VariableDefaultValueError": "La valeur par défaut de la variable ne peut pas contenir plus de 1024 caractères.",
  "oifde.mxgraph.NMS_NodeCodeError": "Le code de nœud ne peut pas être vide ou dépasser 64 caractères.",
  "oifde.mxgraph.NMS_NodeNameError": "Le nom du nœud ne peut pas être vide ou dépasser 1000 caractères.",
  "oifde.mxgraph.NMS_NodeDescError": "La description du nœud peut contenir un maximum de 1000 caractères.",
  "oifde.mxgraph.NMS_actionParamNameError": "Le nom du paramètre peut contenir un maximum de 64 caractères.",
  "oifde.mxgraph.NMS_actionParamValueEmptyError": "La valeur ne peut pas être vide.",
  "oifde.mxgraph.NMS_actionParamValueError": "La valeur du paramètre peut contenir un maximum de 4096 caractères.",
  "oifde.mxgraph.NMS_actionParamDescError": "La description du paramètre ne peut pas contenir plus de 500 caractères.",
  "oifde.mxgraph.NMS_transitToStartNodeError": "Impossible de passer au nœud de démarrage.",
  "oifde.mxgraph.NMS_endNodetransitToError": "Le nœud d'extrémité ne peut pas sauter à un autre nœud.",
  "oifde.mxgraph.NMS_startNodeNotFind": "Le nœud de départ n'est pas trouvé.",
  "oifde.mxgraph.NMS_startNodeNotUnique": "Le nœud de départ n'est pas unique.",
  "oifde.mxgraph.NMS_startNodeNotBranchError": "Le nœud de démarrage ne peut avoir qu'une seule branche.",
  "oifde.mxgraph.NMS_endNodeNotFind": "Le nœud d'extrémité n'est pas trouvé.",
  "oifde.mxgraph.NMS_analiseXmlFailed": "Impossible d'analyser la toile de flux.",
  "oifde.mxgraph.NMS_branchError": "La branche doit avoir le nœud de début et le nœud de fin.",
  "oifde.mxgraph.NMS_nullBranchNotNuique": "Un nœud ne peut avoir qu'une seule branche par défaut.",
  "oifde.mxgraph.NMS_checkDefaultNextNodeForView": "L'élément de diagramme du type de vue ne peut pas avoir plus d'une branche.",
  "oifde.mxgraph.NMS_nodeRefError": "Le nœud est référencé par un autre flux et ne peut pas être supprimé.",
  "oifde.mxgraph.NMS_ValidateError": "La validation a échoué! Vérifiez les pièces marquées en rouge.",
  "oifde.mxgraph.saveAndNopublish": "Le flux est enregistré avec succès mais n'a pas pu être publié.",
  "oifde.mxgraph.publishSuccess": "Le flux est enregistré et publié avec succès.",
  "oifde.mxgraph.publishFailed": "Impossible d'enregistrer et de publier le flux.",
  "oifde.mxgraph.saveAndPublish": "Enregistrer et publier",
  "oifde.mxgraph.NMS_SpaceValidate": "La valeur ne peut pas commencer par un espace.",
  "oifde.mxgraph.NMS_KeyRepeats": "Dupliquer le nom de la condition.",
  "oifde.mxgraph.NMS_encryptValidate": "La variable cryptée IVRREQUEST la variable FLOW et la variable GLOBAL peuvent être affectées uniquement aux variables cryptées FLOW et aux variables GLOBAL.",
  "oifde.mxgraph.NMS_timeSelectLengthValidate": "Au moins un enregistrement doit être configuré pour la période de service.",
  "oifde.knowledgegraph.actions.edit": "Modifier",
  "oifde.knowledgegraph.actions.view": "Voir",
  "oifde.knowledgegraph.actions.delete": "Supprimer",
  "oifde.knowledgegraph.actions.clean": "Effacer",
  "oifde.knowledgegraph.actions.prompt": "Informations",
  "oifde.knowledgegraph.actions.confirm": "OK",
  "oifde.knowledgegraph.actions.nameRequiredTip": "Entrez un nom.",
  "oifde.knowledgegraph.actions.selectTip": "-Sélectionner-",
  "oifde.knowledgegraph.actions.cancel": "Annuler",
  "oifde.knowledgegraph.actions.cancelDelete": "La suppression est annulée.",
  "oifde.knowledgegraph.actions.success": "Succès",
  "oifde.knowledgegraph.actions.updateSuccess": "Modification réussie.",
  "oifde.knowledgegraph.actions.deleteSuccess": "Suppression réussie.",
  "oifde.knowledgegraph.actions.createSuccess": "Ajout réussi.",
  "oifde.knowledgegraph.actions.createFailed": "L'ajout a échoué.",
  "oifde.knowledgegraph.actions.modifyFailed": "échec de la modification.",
  "oifde.knowledgegraph.actions.deleteFailed": "La suppression a échoué.",
  "oifde.knowledgegraph.actions.saveSuccess": "Sauvegarde réussie.",
  "oifde.knowledgegraph.actions.saveFailed": "L'enregistrement a échoué.",
  "oifde.knowledgegraph.actions.addSchema": "Ajouter un schéma",
  "oifde.knowledgegraph.actions.editSchema": "Modifier le schéma",
  "oifde.knowledgegraph.actions.addDic": "Ajouter un dictionnaire graphique",
  "oifde.knowledgegraph.actions.editDic": "Modifier le dictionnaire graphique",
  "oifde.knowledgegraph.actions.addEntity": "Ajouter une entité",
  "oifde.knowledgegraph.actions.editEntity": "Modifier l'entité",
  "oifde.knowledgegraph.actions.addRelation": "Ajouter une relation",
  "oifde.knowledgegraph.actions.editRelation": "Modifier la relation",
  "oifde.knowledgegraph.actions.addAttribute": "Ajouter un attribut",
  "oifde.knowledgegraph.actions.editAttribute": "Modifier l'attribut",
  "oifde.knowledgegraph.actions.addSynonyms": "Ajouter un synonyme",
  "oifde.knowledgegraph.actions.editSynonyms": "Modifier le synonyme",
  "oifde.knowledgegraph.actions.addExtQuestion": "Nouvelle question étendue",
  "oifde.knowledgegraph.actions.editExtQuestion": "Modifier la question étendue",
  "oifde.knowledgegraph.actions.viewSchema": "Voir le schéma",
  "oifde.knowledgegraph.actions.graphicPreview": "Voir le graphique",
  "oifde.knowledgegraph.actions.addSchemaEntity": "Ajouter un concept",
  "oifde.knowledgegraph.actions.editSchemaEntity": "Modifier le concept",
  "oifde.knowledgegraph.model.schemaName": "Nom du schéma",
  "oifde.knowledgegraph.model.schemaCode": "Code de schéma",
  "oifde.knowledgegraph.model.schemaDescription": "Descriptif",
  "oifde.knowledgegraph.model.modifyTime": "Modifié",
  "oifde.knowledgegraph.model.operation": "Opération",
  "oifde.knowledgegraph.model.createUser": "Créé par",
  "oifde.knowledgegraph.model.beforeDeletePrompt": "Le schéma ne peut pas être supprimé car il est associé au dictionnaire graphique suivant",
  "oifde.knowledgegraph.model.beforeEditPrompt": "Le schéma courant ne peut pas être modifié car il est associé au dictionnaire graphique suivant",
  "oifde.knowledgegraph.model.deletePrompt": "Cette opération supprimera définitivement le schéma et ses concepts associés, les relations et les attributs. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.editSchema.entity": "Le concept",
  "oifde.knowledgegraph.editSchema.entityName": "Nom du concept",
  "oifde.knowledgegraph.editSchema.relation": "Relation",
  "oifde.knowledgegraph.editSchema.relationName": "Nom de la relation",
  "oifde.knowledgegraph.editSchema.headEntity": "Concept de tête",
  "oifde.knowledgegraph.editSchema.tailEntity": "Concept de queue",
  "oifde.knowledgegraph.editSchema.selectTip": "---Sélectionner---",
  "oifde.knowledgegraph.editSchema.objectName": "Concept/Relations",
  "oifde.knowledgegraph.editSchema.attributeName": "Nom de l'attribut",
  "oifde.knowledgegraph.editSchema.attributeType": "Type d'attribut",
  "oifde.knowledgegraph.editSchema.unit": "Unité",
  "oifde.knowledgegraph.editSchema.type": "Type",
  "oifde.knowledgegraph.editSchema.schemaNoDataTip": "Ajoutez des données de concept et de relation dans le volet gauche. Pour afficher le contenu de son attribut, cliquez sur Afficher à droite du concept ou de la relation correspondant.",
  "oifde.knowledgegraph.editSchema.deleteEntityPrompt": "Cette opération supprimera définitivement le concept et ses attributs. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.editSchema.deleteRelationPrompt": "Cette opération supprimera définitivement la relation et ses attributs. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.editSchema.deleteAttributePrompt": "Cette opération supprimera définitivement l'attribut. êtes-vous sûr de vouloir continuer l'opération ?",
  "oifde.knowledgegraph.editSchema.dataType.int": "Entier",
  "oifde.knowledgegraph.editSchema.dataType.float": "Nombre à virgule flottante",
  "oifde.knowledgegraph.editSchema.dataType.boolean": "Booléen",
  "oifde.knowledgegraph.editSchema.dataType.string": "Caractère",
  "oifde.knowledgegraph.editSchema.dataType.date": "Date",
  "oifde.knowledgegraph.dicmgr.dicName": "Nom du graphique",
  "oifde.knowledgegraph.dicmgr.dicCode": "Code graphique",
  "oifde.knowledgegraph.dicmgr.dicFunction": "Utilisation du graphique",
  "oifde.knowledgegraph.dicmgr.modifyTime": "Modifié",
  "oifde.knowledgegraph.dicmgr.schemaName": "Schéma associé",
  "oifde.knowledgegraph.dicmgr.kgState": "Statut",
  "oifde.knowledgegraph.dicmgr.operation": "Opération",
  "oifde.knowledgegraph.dicmgr.moreOperation": "Plus",
  "oifde.knowledgegraph.dicmgr.entityMgr": "Gérer l'entité",
  "oifde.knowledgegraph.dicmgr.relationMgr": "Gestion des relations",
  "oifde.knowledgegraph.dicmgr.kgView": "Visualisation graphique",
  "oifde.knowledgegraph.dicmgr.kgFAQ": "FAQ sur les graphiques",
  "oifde.knowledgegraph.dicmgr.active": "Actif",
  "oifde.knowledgegraph.dicmgr.inactive": "Inactif",
  "oifde.knowledgegraph.dicmgr.creating": "Création",
  "oifde.knowledgegraph.dicmgr.trainAll": "Train Tout",
  "oifde.knowledgegraph.dicmgr.trainSuccess": "La formation est réussie.",
  "oifde.knowledgegraph.dicmgr.trainFail": "L'entraînement a échoué.",
  "oifde.knowledgegraph.dicmgr.changeState": "Modifier le statut",
  "oifde.knowledgegraph.dicmgr.synonyms": "Synonyme",
  "oifde.knowledgegraph.dicmgr.extendQuestion": "Question étendue",
  "oifde.knowledgegraph.dicmgr.deletePrompt": "Cette opération supprimera définitivement le dictionnaire et les relations et attributs liés aux entités. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.entitymgr.entityName": "Nom de l'entité",
  "oifde.knowledgegraph.entitymgr.schemaEntityName": "Concept de schéma associé",
  "oifde.knowledgegraph.entitymgr.beforeDeletePrompt": "Supprimez la relation de l'entité, puis supprimez l'entité.",
  "oifde.knowledgegraph.entitymgr.deletePrompt": "Cette opération supprimera définitivement l'entité. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.entitymgr.entityAttributeMgr.deletePrompt": "Cette opération supprimera définitivement l'attribut d'entité. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.relationmgr.headEntity": "Entité principale",
  "oifde.knowledgegraph.relationmgr.tailEntity": "Entité de queue",
  "oifde.knowledgegraph.relationmgr.relation": "Relation",
  "oifde.knowledgegraph.relationmgr.doubleRelation": "Relation bidirectionnelle",
  "oifde.knowledgegraph.relationmgr.doubleRelationTrue": "Oui",
  "oifde.knowledgegraph.relationmgr.doubleRelationFalse": "Non",
  "oifde.knowledgegraph.relationmgr.doublePrompt": "La relation ne peut pas être établie parce que les entités de tête et de queue sont les mêmes. Sélectionnez une autre entité tête ou queue.",
  "oifde.knowledgegraph.relationmgr.deletePrompt": "Cette opération supprimera définitivement la relation et ses attributs associés. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.attributemgr.attributeName": "Nom de l'attribut",
  "oifde.knowledgegraph.attributemgr.attribute": "Valeur d'attribut",
  "oifde.knowledgegraph.attributemgr.description": "Description de l'attribut",
  "oifde.knowledgegraph.attributemgr.deletePrompt": "Cette opération supprimera définitivement l'attribut. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.kgFAQ.title": "FAQ sur les graphiques",
  "oifde.knowledgegraph.kgFAQ.name": "Dictionnaire graphique",
  "oifde.knowledgegraph.kgFAQ.welcomeContent": "Bonjour! Comment puis-je vous aider aujourd'hui?",
  "oifde.knowledgegraph.kgSynonyms.keyword": "Nom et prénom",
  "oifde.knowledgegraph.kgSynonyms.synonyms": "Synonyme",
  "oifde.knowledgegraph.kgSynonyms.number": "Non. Non.",
  "oifde.knowledgegraph.kgSynonyms.synonymsDetail": "Détails du synonyme",
  "oifde.knowledgegraph.kgSynonyms.name": "Nom et prénom",
  "oifde.knowledgegraph.kgSynonyms.deletePrompt": "Cette opération supprimera définitivement l'entrée et tous ses synonymes. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.kgSynonyms.deleteSynonymsPrompt": "Cette opération supprimera définitivement le synonyme. êtes-vous sûr de vouloir continuer ?",
  "oifde.knowledgegraph.kgSynonyms.type.type": "Type",
  "oifde.knowledgegraph.kgSynonyms.type.entity": "Entité",
  "oifde.knowledgegraph.kgSynonyms.type.relation": "Relation",
  "oifde.knowledgegraph.kgSynonyms.type.property": "Attribut",
  "oifde.knowledgegraph.kgView.queryType": "Type de requête",
  "oifde.knowledgegraph.kgView.entityName": "Nom de l'entité",
  "oifde.knowledgegraph.kgView.displayType": "Mode d'affichage",
  "oifde.knowledgegraph.kgView.queryByName": "Par nom d'entité",
  "oifde.knowledgegraph.validation.schemaName": "Saisissez un nom de schéma.",
  "oifde.knowledgegraph.validation.dicName": "Saisissez un nom de dictionnaire.",
  "oifde.knowledgegraph.validation.selectRequired": "Ce champ est obligatoire.",
  "oifde.knowledgegraph.validation.attrValue": "Entrez une valeur d'attribut.",
  "oifde.knowledgegraph.validation.required": "La valeur est obligatoire.",
  "oifde.knowledgegraph.errorCode.100010000000": "Erreur système.",
  "oifde.knowledgegraph.errorCode.100010000003": "Le nom doit être unique.",
  "oifde.knowledgegraph.errorCode.100010000002": "Erreur de format.",
  "oifde.knowledgegraph.errorCode.100051001013": "Ce schéma est actuellement utilisé.",
  "oifde.knowledgegraph.errorCode.100051001012": "Un synonyme a déjà été configuré pour le nom.",
  "oifde.knowledgegraph.errorCode.100010000001": "Erreur de base de données.",
  "oifde.knowledgegraph.errorCode.100051000110": "Le nombre a atteint la limite supérieure de 10.",
  "oifde.knowledgegraph.errorCode.100051000111": "Le nombre a atteint la limite supérieure de 190.",
  "oifde.knowledgegraph.errorCode.100051000113": "L'URL configurée pour le service GES est incorrecte.",
  "oifde.knowledgegraph.errorCode.100051000112": "Préparation du graphique...Veuillez patienter.",
  "oifde.knowledgegraph.errorCode.100061000005": "Le graphe de connaissances est lié avec succès.",
  "oifde.knowledgegraph.errorCode.100010000005": "Le paramètre d'entrée est incorrect.",
  "oifde.knowledgegraph.errorCode.100051000114": "Impossible d'enregistrer la question étendue pour la FAQ du graphique.",
  "oifde.knowledgegraph.errorCode.100061000006": "Impossible de se connecter à l'ODFS.",
  "oifde.knowledgegraph.extendFaq.setting": "Définir une question étendue",
  "oifde.knowledgegraph.extendFaq.import": "Importer",
  "oifde.knowledgegraph.extendFaq.export": "Tout exporter",
  "oifde.knowledgegraph.extendFaq.extendFaq": "FAQ Liste des modèles",
  "oifde.knowledgegraph.extendFaq.faqTemplate": "Modèle de FAQ",
  "oifde.knowledgegraph.extendFaq.description": "Descriptif",
  "oifde.knowledgegraph.extendFaq.updateTime": "Mise à jour",
  "oifde.knowledgegraph.extendFaq.status": "Statut",
  "oifde.knowledgegraph.extendFaq.setTemplate": "Définir une question étendue",
  "oifde.knowledgegraph.extendFaq.use": "Utiliser/Ne pas utiliser",
  "oifde.knowledgegraph.extendFaq.question": "Question",
  "oifde.knowledgegraph.extendFaq.reply": "Répondre",
  "oifde.knowledgegraph.extendFaq.similarQuestion": "Question similaire",
  "oifde.knowledgegraph.extendFaq.similarReply": "Réponse similaire",
  "oifde.knowledgegraph.extendFaq.edit": "Modifier -",
  "oifde.knowledgegraph.extendFaq.inUse": "En cours d'utilisation",
  "oifde.knowledgegraph.extendFaq.notUse": "Non utilisé",
  "oifde.knowledgegraph.extendFaq.operation": "Opération",
  "oifde.knowledgegraph.extendFaq.updateSuccess": "Le statut est changé avec succès.",
  "oifde.knowledgegraph.extendFaq.updateFailed": "Impossible de modifier le statut.",
  "oifde.knowledgegraph.extendFaq.updateTemplateSuccess": "Le modèle de FAQ est modifié avec succès.",
  "oifde.knowledgegraph.extendFaq.updateTemplateFailed": "Impossible de modifier le modèle de FAQ.",
  "oifde.whitelist.tenantId": "ID de l'espace locataire",
  "oifde.whitelist.tenantSpace": "Espace locataire",
  "oifde.whitelist.whiteIp": "Adresse IP de la liste de confiance ou nom de domaine",
  "oifde.whitelist.whitePort": "Port de liste de confiance",
  "oifde.whitelist.whiteType": "Type de liste de confiance",
  "oifde.whitelist.description": "Descriptif",
  "oifde.whitelist.whiteTypes.INTERFACE": "L'interface",
  "oifde.whitelist.whiteTypes.PROCEDURE": "Procédure stockée",
  "oifde.whitelist.addWhiteList": "Ajouter une liste de confiance",
  "oifde.whitelist.editWhiteList": "Modifier la liste de confiance",
  "oifde.whitelist.whiteIpLen": "L'adresse IP ou le nom de domaine de la liste de confiance peut contenir un maximum de 128 caractères.",
  "oifde.whitelist.whitePortLen": "Le numéro de port de la liste de confiance peut contenir un maximum de 20 caractères.",
  "oifde.whitelist.descriptionLen": "La description peut contenir un maximum de 1024 caractères.",
  "oifde.whitelist.number": "La valeur doit être des chiffres.",
  "oifde.whitelist.whiteIpListNotNull": "Sélectionnez au moins une liste de confiance à supprimer.",
  "oifde.whitelist.deletePrompt": "Cette opération supprimera définitivement la liste de confiance sélectionnée. êtes-vous sûr de vouloir continuer ?",
  "oifde.whitelist.ipRange": "L'adresse IP ne peut contenir que des lettres, des chiffres, des points (.) des (_) de soulignement et des traits d'union. Il ne peut pas commencer ou se terminer par un trait d'union.",
  "oifde.operationlog.generate_test_case": "Génération automatique de cas",
  "oifde.operationlog.stop_auto_generate": "Génération de cas interrompue",
  "oifde.operationlog.logging_module": "Module",
  "oifde.operationlog.operation_log": "Journal des opérations",
  "oifde.operationlog.operator": "Compte",
  "oifde.operationlog.operation_obj_type": "Type d'objet d'opération",
  "oifde.operationlog.startTime": "Heure de début",
  "oifde.operationlog.endTime": "Heure de fin",
  "oifde.operationlog.operationType": "Type d'opération",
  "oifde.operationlog.targetObjType": "Type d'objet",
  "oifde.operationlog.targetObjId": "ID de l'objet",
  "oifde.operationlog.targetObjName": "Objet",
  "oifde.operationlog.result": "Résultat",
  "oifde.operationlog.detail": "Détails",
  "oifde.operationlog.operationTime": "Dernière opération",
  "oifde.operationlog.loginIp": "Adresse IP de connexion",
  "oifde.operationlog.check": "Valider",
  "oifde.operationlog.query": "Requête",
  "oifde.operationlog.create": "Créer",
  "oifde.operationlog.copy": "Copier",
  "oifde.operationlog.paste": "Coller",
  "oifde.operationlog.clone": "Cloner",
  "oifde.operationlog.import": "Importer",
  "oifde.operationlog.export": "Exporter",
  "oifde.operationlog.update": "Mise à jour",
  "oifde.operationlog.delete": "Supprimer",
  "oifde.operationlog.enable": "Activer",
  "oifde.operationlog.disable": "Désactiver",
  "oifde.operationlog.set_test": "Définir pour tester",
  "oifde.operationlog.hang_up": "Raccrocher",
  "oifde.operationlog.release": "Publier",
  "oifde.operationlog.invalidate": "Invalider",
  "oifde.operationlog.upgrade": "Mise à niveau",
  "oifde.operationlog.set_white_list": "Définir des règles de gris",
  "oifde.operationlog.intent_template": "Modèle d'intention",
  "oifde.operationlog.user_entity": "Entité utilisateur",
  "oifde.operationlog.system_entity": "Entité système",
  "oifde.operationlog.voice_content": "Fichier vocal",
  "oifde.operationlog.system_parameters": "Paramètre système",
  "oifde.operationlog.intent_parameters": "Paramètre d'intention",
  "oifde.operationlog.call_session": "Boîte de dialogue d'appel",
  "oifde.operationlog.cluster_task": "Tache de clustering",
  "oifde.operationlog.history_dialogue": "Boîte de dialogue historique",
  "oifde.operationlog.test_case_group": "Groupe de cas de test",
  "oifde.operationlog.test_case": "Cas de test",
  "oifde.operationlog.faq": "Groupe FAQ",
  "oifde.operationlog.faq_element": "Questions et réponses",
  "oifde.operationlog.log_parameters": "Paramètre journal",
  "oifde.operationlog.tps_interface": "Interface de service",
  "oifde.operationlog.nms_interface": "Interface de processus de dialogue",
  "oifde.operationlog.white_list": "Règle grise de la connaissance",
  "oifde.operationlog.white_list_users": "Numéro d'abonné dans la règle grise de connaissance",
  "oifde.operationlog.sensitive_words": "Mot sensible",
  "oifde.operationlog.stop_words": "Mot d'arrêt",
  "oifde.operationlog.navigation_info": "Informations de navigation",
  "oifde.operationlog.command": "Commande",
  "oifde.operationlog.api_key": "Clé API",
  "oifde.operationlog.trace_log": "Suivre les paramètres du journal",
  "oifde.operationlog.succeed": "Succès",
  "oifde.operationlog.failed": "échec",
  "oifde.operationlog.domain": "Domaine",
  "oifde.operationlog.service_node": "Nœud de service",
  "oifde.operationlog.online": "En ligne",
  "oifde.operationlog.offline": "Hors ligne",
  "oifde.operationlog.flow": "Flux",
  "oifde.operationlog.performance_data": "Données de performance",
  "oifde.operationlog.login": "S'inscrire",
  "oifde.operationlog.logout": "Déconnexion",
  "oifde.operationlog.change_password": "Changer le mot de passe",
  "oifde.operationlog.reset_password": "Réinitialiser le mot de passe",
  "oifde.operationlog.authorize": "Certification",
  "oifde.operationlog.tenant_space": "Espace locataire",
  "oifde.operationlog.account": "Compte",
  "oifde.operationlog.role": "Rôle",
  "oifde.operationlog.password_rule": "Règle du mot de passe",
  "oifde.operationlog.password_rule_group": "Groupe de règles de mot de passe",
  "oifde.operationlog.user_account": "Compte",
  "oifde.operationlog.user_role": "Rôle",
  "oifde.operationlog.knowledge_graph": "Graphique de connaissances",
  "oifde.operationlog.knowledge_graph_synonym": "Synonyme du graphe de connaissances",
  "oifde.operationlog.acess_code": "Code d'accès",
  "oifde.operationlog.gray_rule": "Règle grise",
  "oifde.operationlog.resource_template": "Modèle de ressource",
  "oifde.operationlog.business_interface": "Interface d'affaires",
  "oifde.operationlog.variable_manage": "Gestion des variables",
  "oifde.operationlog.flume_log_cfg": "Configuration du journal Flume",
  "oifde.operationlog.procedure_db": "Base de données de procédures stockées",
  "oifde.operationlog.procedure_interface": "Procédure stockée",
  "oifde.operationlog.complexcellcatalog": "Catalogue d'éléments de diagramme composite",
  "oifde.operationlog.complexcell": "élément de diagramme composite",
  "oifde.operationlog.lock_account": "Verrouiller le compte",
  "oifde.operationlog.unlock_account": "Déverrouiller le compte",
  "oifde.operationlog.kick_account": "Effacer les sessions",
  "oifde.operationlog.start": "Démarrer",
  "oifde.operationlog.stop": "Arrêter",
  "oifde.operationlog.log_open_task": "Tache d'activation de journal",
  "oifde.operationlog.activate": "Activer",
  "oifde.operationlog.deactivate": "Pause",
  "oifde.operationlog.illeagal_status": "Statut illégal",
  "oifde.operationlog.file": "Fichier",
  "oifde.operationlog.trace_log_query": "Traçage des journaux et requête",
  "oifde.operationlog.staticcheck": "Contrôle statique",
  "oifde.operationlog.unkonw": "Inconnu",
  "oifde.operationlog.interface_white_list": "Liste de confiance",
  "oifde.operationlog.license": "Licence",
  "oifde.operationlog.test_corpus": "Test du corpus",
  "oifde.operationlog.test": "Tester",
  "oifde.operationlog.refresh": "Actualiser",
  "oifde.operationlog.train": "Train",
  "oifde.operationlog.trainAll": "Entraînez tous",
  "oifde.operationlog.lable_corpus": "Tagged corpus",
  "oifde.operationlog.knowledge_graph_schema": "Schéma de connaissances",
  "oifde.operationlog.knowledge_graph_ask_template": "Question étendue",
  "oifde.operationlog.knowledge_graph_entity": "Entité du dictionnaire graphique",
  "oifde.operationlog.knowledge_graph_relation": "Relation du dictionnaire graphique",
  "oifde.operationlog.knowledge_graph_object_attribute": "Attribut de relation de dictionnaire de graphique",
  "oifde.operationlog.knowledge_graph_object_entity": "Attribut d'entité du dictionnaire graphique",
  "oifde.operationlog.knowledge_graph_schema_entity": "Entité de schéma de connaissances",
  "oifde.operationlog.knowledge_graph_schema_relation": "Relation du schéma de connaissances",
  "oifde.operationlog.knowledge_graph_schema_attribute": "Attribut de schéma de connaissances",
  "oifde.operationlog.knowledge_graph_ask_template_group": "Graphique question étendue",
  "oifde.operationlog.knowledge_graph_ask_template_element": "Modèle de FAQ pour les questions étendues de graphique",
  "oifde.operationlog.encrypt_Secret": "Chiffrer le secret",
  "oifde.operationlog.flow_trace_log": "Configuration du suivi des tests de processus",
  "oifde.operationlog.channel_type": "Canal",
  "oifde.operationlog.response_style": "Le style",
  "oifde.operationlog.resource_template_exce": "Modèle de ressource d'exception",
  "oifde.operationlog.setting": "Mettre en place",
  "oifde.operationlog.quick_release": "Publication en un clic",
  "oifde.operationlog.download": "Téléchargement",
  "oifde.operationlog.upload": "Téléchargement",
  "oifde.operationlog.binding_domain": "Domaine Chatbot",
  "oifde.operationlog.binding_faqgroup": "Groupe de FAQ sur les chatbots",
  "oifde.operationlog.binding_kg": "Graphique de chatbot",
  "oifde.operationlog.binding": "Lier",
  "oifde.operationlog.guideflow": "Flux d'assistant de création",
  "oifde.operationlog.sensitive_group": "Groupe de mots sensibles",
  "oifde.operationlog.recordHistoryLog": "Journal des boîtes de dialogue",
  "oifde.operationlog.sentiment_analysis": "Modèle d'analyse du sentiment",
  "oifde.operationlog.use": "Utilisé",
  "oifde.operationlog.unused": "Non utilisé",
  "oifde.operationlog.tenantAsrName": "Configuration ASR",
  "oifde.operationlog.tenantAsrTtsName": "Gestion TTS/ASR",
  "oifde.operationlog.MRCPServerManagement": "Gestion des serveurs",
  "oifde.operationlog.insert": "Insérer",
  "oifde.operationlog.exclude_entity": "Exclure les entités",
  "oifde.sysConfig.sysParam": "Paramètres du système",
  "oifde.sysConfig.intent_params": "Paramètres d'intention",
  "oifde.sysConfig.code": "Code de variable",
  "oifde.sysConfig.description": "Description de la variable",
  "oifde.sysConfig.value": "Valeur variable",
  "oifde.sysConfig.update": "Appliquer",
  "oifde.sysConfig.abort": "Annuler",
  "oifde.sysConfig.updateAll": "Appliquer tous les paramètres",
  "oifde.sysConfig.restore_defaults": "Restaurer les valeurs par défaut",
  "oifde.sysConfig.successful_operation": "Opération réussie.",
  "oifde.sysConfig.confirm_apply_intent_param": "La modification des paramètres d'intention aura une incidence significative sur la reconnaissance de l'intention. êtes-vous sûr de vouloir continuer ?",
  "oifde.sysConfig.succeed_apply_intent_param": "Les paramètres d'intention sont modifiés avec succès.",
  "oifde.sysConfig.failed_apply_intent_param": "Impossible de modifier le paramètre intention.",
  "oifde.sysConfig.confirm_restore_defaults": "êtes-vous sûr de vouloir restaurer les valeurs par défaut & #160;?",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.NAME": "Module de pré-traitement chinois",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.NAME": "Utilisez Pinyin",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.DESC": "S'il faut convertir le chinois en pinyin.",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.TRUE": "Oui",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.FALSE": "Non",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.NAME": "Utiliser le ton",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.DESC": "S'il faut utiliser le ton en pinyin.",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.TRUE": "Oui",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.FALSE": "Non",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.MULTI": "Chaque occurrence",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.ONCE": "Une seule occurrence",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.TRUE": "Activer",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.FALSE": "Désactiver",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.TRUE": "Activer",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.FALSE": "Désactiver",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NAME": "Module d'extraction de caractéristiques N-Gram",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.NAME": "Valeur min. N",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.DESC": "Valeur minimale de N dans l'algorithme de N-Gram.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.NAME": "Valeur N max",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.DESC": "Valeur N maximale dans l'algorithme N-Gram.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.NAME": "Seuil min. de poids des mots",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.DESC": "Un mot est ignoré lorsque son poids est inférieur à ce seuil.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.NAME": "Collecte de statistiques sur les mots répétés",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.DESC": "S'il faut collecter des statistiques pour chaque occurrence d'un mot répété à l'intérieur d'une phrase ou une seule.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.NAME": "Algorithme TSF",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.DESC": "S'il faut activer l'algorithme TSF.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.": "Indique s'il faut ajouter des balises avant et après une phrase lorsque la fonctionnalité N-Gram est extraite.",
  "oifde.sysConfig.ENERGY_FILTER.NAME": "Module de contrôle de quantité d'intention",
  "oifde.sysConfig.ENERGY_FILTER.MAX.NAME": "Max. Intentions",
  "oifde.sysConfig.ENERGY_FILTER.MAX.DESC": "Nombre maximal d'intentions retournées dans la décision finale.",
  "oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.NAME": "Module de filtrage d'intention inconnue",
  "oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.NAME": "Seuil d'énergie",
  "oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.DESC": "Les intentions sont considérées comme inconnues si la somme de toutes les énergies intentionnelles détectées est inférieure ou égale au seuil.",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.NAME": "Module de classification des intentions",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.NAME": "μ",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.DESC": "μ valeur de la fonction logistique des classificateurs d'intention.",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.NAME": "γ",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.DESC": "γ valeur de la fonction logistique des classificateurs d'intention.",
  "oifde.sysConfig.INTENT_DECISION_MAKER.NAME": "Module de prise de décision d'intention",
  "oifde.sysConfig.INTENT_DECISION_MAKER.TOP.NAME": "Limite supérieure",
  "oifde.sysConfig.INTENT_DECISION_MAKER.TOP.DESC": "L'intention est considérée comme connue si elle dépasse la limite supérieure du décideur d'intention.",
  "oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.NAME": "Limite inférieure",
  "oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.DESC": "L'intention est considérée comme inconnue si elle se situe au-dessous de la limite inférieure du décideur d'intention.",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.NAME": "Module de marquage de fente CRF",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.NAME": "Taux d'apprentissage",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.DESC": "Taux d'exécution de l'apprentissage du modèle CRF.",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.NAME": "Quantité de nœud d'arbre",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.DESC": "Nombre de nœuds contenus dans chaque arbre dans l'algorithme GBRT.",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.NAME": "Quantité d'arbre",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.DESC": "Nombre d'arbres dans l'algorithme GBRT.",
  "oifde.errorCode.100010000000": "Erreur système.",
  "oifde.errorCode.100010000002": "Le format est incorrect.",
  "oifde.errorCode.100051005001": "Impossible d'enregistrer la liste de blocage et la liste de confiance.",
  "oifde.errorCode.100070000001": "Impossible d'importer les données car elles sont identiques à celles d'une ressource existante.",
  "oifde.errorCode.100051005002": "Impossible de supprimer la liste de blocage ou la liste d'approbation.",
  "oifde.errorCode.100051000006": "Impossible d'importer le fichier car le format n'est pas valide.",
  "oifde.errorCode.100051005005": "Impossible d'importer le fichier car il est trop volumineux.",
  "oifde.errorCode.100051005006": "Le numéro de service peut contenir un maximum de 64 caractères.",
  "oifde.errorCode.100051005015": "Le fichier peut contenir un maximum de 5000 lignes.",
  "oifde.errorCode.100051005003": "Impossible d'importer le fichier car il est introuvable.",
  "oifde.errorCode.100051005004": "Impossible d'importer le fichier car le système ne peut pas lire le fichier.",
  "oifde.errorCode.100051006013": "Impossible d'importer le fichier.",
  "oifde.errorCode.100051005025": "Le nombre total ne peut dépasser 5000.",
  "oifde.errorCode.100051005011": "Impossible d'ajouter le numéro de service.",
  "oifde.fileTask.EMPTY_COMMAND": "La commande d'opération ne peut pas être vide.",
  "oifde.fileTask.UNKNOWN_COMMAND": "La commande d'opération n'est pas identifiée.",
  "oifde.fileTask.INVALID_SALT": "La requête n'est pas valide.",
  "oifde.fileTask.INVALID_REQUEST": "La requête n'est pas valide.",
  "oifde.fileTask.EMPTY_TASK_ID": "L'ID de la tache ne peut pas être vide.",
  "oifde.fileTask.TASK_NOT_FOUND": "La tache n'est pas trouvée.",
  "oifde.fileTask.SERVER_BUSY": "Le système est actuellement occupé.",
  "oifde.fileTask.SERVICE_UNAVAILABLE": "Le service est actuellement indisponible.",
  "oifde.fileTask.UPLOAD_EXCEPTION": "Le téléchargement a été interrompu.",
  "oifde.fileTask.INVALID_ITEM_ENCODING": "Le mode d'encodage n'est pas valide.",
  "oifde.fileTask.INVALID_FILE_EXTENSION": "L'extension du nom de fichier n'est pas valide.",
  "oifde.fileTask.WRITE_FILE_FAILED": "Impossible d'enregistrer le fichier.",
  "oifde.fileTask.READ_FILE_FAILED": "Impossible de lire le fichier.",
  "oifde.fileTask.INVALID_FILE_PATH": "Le chemin du fichier est incorrect.",
  "oifde.fileTask.FILE_NOT_EXIST": "Le fichier n'existe pas.",
  "oifde.fileTask.FILE_TOO_LARGE": "Le paquet compressé contient des fichiers trop volumineux.",
  "oifde.fileTask.ENTRY_TOO_LARGE": "Le paquet compressé contient des fichiers surdimensionnés.",
  "oifde.fileTask.TOO_MANY_ENTRIES": "Le paquet compressé contient trop de fichiers.",
  "oifde.fileTask.INVALID_ENTRY_PATH": "Le chemin d'accès du fichier dans le paquet compressé n'est pas valide.",
  "oifde.fileTask.INVALID_ENTRY_EXTENSION": "L'extension de nom de fichier dans le paquet compressé n'est pas valide.",
  "oifde.fileTask.UNZIPPED_TOO_LARGE": "La taille du paquet décompressé est trop grande.",
  "oifde.fileTask.UNZIP_FAILED": "Impossible de décompresser le paquet.",
  "oifde.fileTask.DOWNLOAD_EXCEPTION": "Le téléchargement a été interrompu.",
  "oifde.fileTask.UNDEFINED_ERROR": "Une erreur s'est produite lors de l'exécution de la tache.",
  "oifde.fileTask.INVALID_TASK_ARGS": "Le paramètre de tache est manquant.",
  "oifde.fileTask.INVALID_TASK_CONTENT": "Les données de la tache ne sont pas valides.",
  "oifde.fileTask.GRAPH_IS_ON": "Impossible d'importer un graphique actif.",
  "oifde.fileTask.INCORRECT_PARAMETERS": "Le paramètre n'est pas valide.",
  "oifde.fileTask.KG_NO_SCHEMA": "Le graphique n'est pas associé à un schéma de connaissances.",
  "oifde.fileTask.IMPORT_KG_ENTITY_TOO_BIG": "Un maximum de 1 million d'entités graphiques sont autorisées",
  "oifde.fileTask.IMPORT_KG_RELATION_TOO_BIG": "Un maximum de 100 millions de relations de graphe sont autorisées.",
  "oifde.fileTask.IMPORT_SCHEMA_TOO_BIG": "Un maximum de 10000 entités conceptuelles sont autorisées dans un schéma de connaissance.",
  "oifde.fileTask.IMPORT_SCHEMA_RELATION_TOO_BIG": "Un maximum de 10000 relations sont autorisées dans un schéma de connaissance.",
  "oifde.importFile.white_list": "écraser tous les paramètres existants",
  "oifde.importFile.test_case_group": "écraser tous les paramètres existants",
  "oifde.importFile.knowledge_graph": "écraser les noms en double",
  "oifde.importFile.knowledge_graph_ask_template": "écraser tous les paramètres existants",
  "oifde.recordHistory.recordHistory": "Historique des appels",
  "oifde.recordHistory.recordDetail": "Détails de l'appel",
  "oifde.recordHistory.userSaying": "Demande de texte",
  "oifde.recordHistory.systemReply": "Texte de réponse",
  "oifde.recordHistory.reqTime": "Demandé",
  "oifde.recordHistory.respTime": "Répondu",
  "oifde.recordHistory.callNumber": "Numéro d'abonné",
  "oifde.recordHistory.callStatus": "Surveillance des appels entrants",
  "oifde.recordHistory.kgStatus": "Surveillance des connaissances",
  "oifde.recordHistory.callNumberLength": "Le numéro d'abonné ne peut pas contenir plus de 64 caractères.",
  "oifde.recordHistory.oneCondition": "La date ou le numéro de téléphone mobile est requis.",
  "oifde.recordHistory.respSource": "Répondre Source",
  "oifde.recordHistory.silentSeat": "Agent silencieux",
  "oifde.recordHistory.bot": "Chatbot",
  'oifde.recordHistory.customer': 'CLIENTS',
  "oifde.error.messageHead": "Interface frontend -",
  "oifde.error.messageBody": "Erreur de demande. Code d'erreur",
  "oifde.error.messageTail": "Vérifiez si le réseau et le serveur fonctionnent correctement.",
  "oifde.testMgr.noSelection": "Les cas de test ne peuvent pas être générés car aucun domaine ou groupe de FAQ n'est sélectionné.",
  "oifde.testMgr.abortSuccess": "Interruption réussie.",
  "oifde.testMgr.abortFailed": "L'interruption a échoué.",
  "oifde.testMgr.getStatusWarn": "Les cas de test ne peuvent pas actuellement être générés car un processus existant a demandé de générer un cas de test. Veuillez patienter...",
  "oifde.testMgr.noDomain": "Aucun domaine n'est sélectionné.",
  "oifde.testMgr.noFaqGroup": "Aucun groupe de FAQ n'est sélectionné.",
  "oifde.testMgr.requestFailed": "Impossible d'appeler l'interface pour générer automatiquement des cas de test.",
  "oifde.testMgr.getCorpusTestCase": "Générer un cas",
  "oifde.testMgr.abortCorpusTestCase": "Interrompre",
  "oifde.testMgr.domain": "Domaine",
  "oifde.testMgr.faqGroup": "FAQ Groupe",
  "oifde.testMgr.getCorpusTestCaseConfig": "Générer un cas",
  "oifde.testMgr.allDomain": "Sélectionner tous les domaines",
  "oifde.testMgr.allFaqGroup": "Sélectionner tous les groupes FAQ",
  "oifde.testMgr.testMgr": "Test de connaissances",
  "oifde.testMgr.chatBotTestCaseMgr": "Test de boîte de dialogue",
  "oifde.testMgr.botTestCaseMgr": "Gérer le cas de test",
  "oifde.testMgr.testCase": "Cas de test",
  "oifde.testMgr.case_group": "Groupes de cas de test",
  "oifde.testMgr.case_amount": "Cas de test",
  "oifde.testMgr.case_tested": "Quantité testée",
  "oifde.testMgr.case_passed": "Passé",
  "oifde.testMgr.case_failed": "échec",
  "oifde.testMgr.case_passed_rate": "Taux de réussite",
  "oifde.testMgr.test_time": "Testé",
  "oifde.testMgr.add_test_case_group": "Ajouter un groupe de cas de test",
  "oifde.testMgr.test_case_group_details": "Détails",
  "oifde.testMgr.descriptionLen": "La description peut contenir un maximum de 1024 caractères.",
  "oifde.testMgr.number": "Seuls les chiffres sont autorisés.",
  "oifde.testMgr.auto_testing_template": "Télécharger le modèle",
  "oifde.testMgr.execute_test": "Exécuter",
  "oifde.testMgr.manage": "Gérer",
  "oifde.testMgr.case_id": "ID de cas",
  "oifde.testMgr.userId": "ID d'abonné",
  "oifde.testMgr.expected_output": "Mot-clé de la sortie prévue ou nom de l'intention",
  "oifde.testMgr.actual_output": "Sortie réelle",
  "oifde.testMgr.actual_output_title": "Sortie réelle (nom de l'intention dans les parenthèses)",
  "oifde.testMgr.event": "événement",
  "oifde.testMgr.note_test": "Non testé",
  "oifde.testMgr.expected_output_with_memo": "Mots-clés de sortie attendus ou noms d'intention (séparez les entrées multiples par des virgules)",
  "oifde.testMgr.add_test_case": "Ajouter un cas de test",
  "oifde.testMgr.test_case_details": "Détails",
  "oifde.testMgr.execute_all": "Exécuter tout",
  'oifde.testMgr.batchExec': 'Exécuter',
  "oifde.testMgr.succeed_save_case": "Le cas de test est enregistré avec succès.",
  "oifde.testMgr.succeed_test_case": "Le cas de test est exécuté avec succès.",
  "oifde.testMgr.natureColon": ":",
  "oifde.testMgr.failed_query": "La requête a échoué.",
  "oifde.testMgr.succeed_save_group": "Le groupe de cas de test est enregistré avec succès.",
  "oifde.testMgr.fail_save_data": "Impossible d'enregistrer le groupe de cas de test.",
  "oifde.testMgr.select_prompt": "Sélectionnez au moins un enregistrement.",
  "oifde.testMgr.confirm_delete_case_group": "êtes-vous sûr de vouloir supprimer le groupe de cas de test & #160;?",
  "oifde.testMgr.failed_delete_group": "Impossible de supprimer le groupe de cas de test.",
  "oifde.testMgr.succeed_delete_group": "Le groupe de cas de test est supprimé avec succès.",
  "oifde.testMgr.confirm_delete_case": "êtes-vous sûr de vouloir supprimer le cas de test & #160;?",
  "oifde.testMgr.succeed_delete_case": "Le cas de test est supprimé avec succès.",
  "oifde.testMgr.failed_delete_case": "Impossible de supprimer le cas de test.",
  "oifde.testMgr.import_test_cases": "Importer le cas de test",
  "oifde.testMgr.import_test_cases_hint": "Un maximum de 200 cas de test au format TXT peuvent être importés.",
  "oifde.testMgr.overwrite_import": "écraser tous les mêmes paramètres",
  "oifde.testMgr.failed_import_file": "Impossible d'importer le fichier.",
  "oifde.testMgr.success_import": "Le fichier est importé avec succès.",
  "oifde.hisdialog.startTime_must_earlier_endTime": "L'heure de début doit être antérieure à l'heure de fin. Réinitialiser les critères de recherche.",
  "oifde.hisdialog.direction.UPLOAD": "Saisissez le texte de la demande.",
  "oifde.hisdialog.direction.DOWNLOAD": "Texte de réponse",
  "oifde.chatBotTest.rangeFilter": "Filtrer les enregistrements sélectionnés",
  "oifde.chatBotTest.botAccessCode": "Code d'accès au chatbot",
  "oifde.chatBotTest.callingNum": "Numéro d'appel",
  "oifde.chatBotTest.caseTimeRange": "Plage de temps",
  "oifde.chatBotTest.getChatBotTestCase": "Générer une boîte de dialogue",
  "oifde.chatBotTest.getChatBotTestCaseConfig": "Générer une boîte de dialogue",
  "oifde.chatBotTest.abortChatBotTestCase": "Interrompre la génération de cas de dialogue",
  "oifde.chatBotTest.allAccessCode": "Sélectionner tous les codes d'accès au chatbot",
  "oifde.chatBotTest.deletePrompt": "Cette opération supprimera définitivement le groupe de cas de test. êtes-vous sûr de vouloir continuer ?",
  'oifde.chatBotTest.deleteTestCase': 'Cette opération supprimera définitivement les cas de test sélectionnés. Êtes-vous sûr de vouloir continuer ?',
  "oifde.chatBotTest.checkPrompt": "L'exécution simultanée de plusieurs cas de test peut ralentir le système. êtes-vous sûr de vouloir continuer ?",
  "oifde.chatBotTest.cancleExcute": "L'exécution est annulée.",
  "oifde.chatBotTest.inputTestCaseGroupName": "Groupe de cas de test",
  "oifde.chatBotTest.doTest": "Exécuter",
  "oifde.chatBotTest.executeSuccess": "Exécution réussie.",
  "oifde.chatBotTest.executeFailed": "L'exécution a échoué.",
  "oifde.chatBotTest.downTestReport": "Télécharger le rapport de test",
  "oifde.chatBotTest.downAllReport": "Télécharger tous les rapports",
  "oifde.chatBotTest.testCaseGroup": "Groupes de cas de test",
  "oifde.chatBotTest.totalNumber": "Cas de test",
  "oifde.chatBotTest.testTimes": "Quantité testée",
  "oifde.chatBotTest.passTimes": "Passé",
  "oifde.chatBotTest.failTimes": "échec",
  "oifde.chatBotTest.passRate": "Taux de réussite",
  "oifde.chatBotTest.testTime": "Testé",
  "oifde.chatBotTest.status": "Statut",
  'oifde.chatBotTest.status.initial': 'Initialiser',
  'oifde.chatBotTest.status.waiting': 'Attente',
  'oifde.chatBotTest.status.running': 'Course',
  'oifde.chatBotTest.status.finish': 'Finir',
  'oifde.chatBotTest.status.failed': 'Fail',
  'oifde.chatBotTest.status.success': 'Succès',
  "oifde.chatBotTest.description": "Descriptif",
  "oifde.chatBotTest.addTestCaseGroup": "Ajouter un groupe de cas de test",
  "oifde.chatBotTest.editTestCaseGroup": "Modifier le groupe de cas de test",
  "oifde.chatBotTest.testCaseGroupName": "Nom du groupe de cas",
  "oifde.chatBotTest.robotName": "Nom du chatbot",
  "oifde.chatBotTest.callingId": "Numéro d'appel",
  "oifde.chatBotTest.prompt": "Informations",
  "oifde.chatBotTest.promptMessage": "Sélectionnez au moins un enregistrement.",
  'oifde.chatBotTest.downloadMessage': 'Vous avez sélectionné {0} enregistrements. Êtes-vous sûr de vouloir continuer ?',
  'oifde.chatBotTest.downloadAllMessage': 'enregistrements ? Vous pouvez également annuler l\'opération et sélectionner un enregistrement spécifique à exporter.',
  "oifde.testCase.name": "Nom du cas de test",
  "oifde.testCase.status": "Statut",
  "oifde.testCase.caseID": "ID de cas",
  "oifde.testCase.doAll": "Exécuter tout",
  "oifde.testCase.caseName": "Nom du cas",
  "oifde.testCase.executeStatus": "Statut",
  "oifde.testCase.description": "Descriptif",
  "oifde.testCase.caseDetail": "Détails du cas",
  "oifde.testCase.traceLog": "Piste de flux",
  "oifde.testCase.callDetail": "Détails",
  "oifde.testCase.addTestCase": "Ajouter un cas de test",
  "oifde.testCase.editTestCase": "Modifier le cas de test",
  "oifde.testCase.caseDetailMgr": "Gérer les détails du cas",
  "oifde.testCase.detailDialog": "Détails du cas",
  "oifde.testCase.detail.reqText": "Demande de texte",
  "oifde.testCase.detail.expectedReturn": "Texte de réponse attendue",
  "oifde.testCase.detail.channelAssociatedData": "Données associées",
  "oifde.testCase.detail.operation": "Opération",
  "oifde.testCase.detail.realResponse": "Texte de réponse réel",
  "oifde.testCase.detail.close": "Fermer",
  "oifde.rate.accessCode": "Sélectionnez un code d'accès chatbot.",
  "oifde.rate.callCountBoard": "Tableau de bord des statistiques d'appels",
  "oifde.rate.resolveRate": "Taux net de résolution en libre-service",
  "oifde.rate.dayOption": "Par jour",
  "oifde.rate.hourOption": "Par heure",
  "oifde.rate.monthOption": "30 derniers jours",
  "oifde.rate.dateSelect": "Sélectionnez une date.",
  "oifde.rate.index": "Non. Non.",
  "oifde.rate.time": "Date et heure",
  "oifde.rate.dataDimension": "Sélectionnez la dimension des données.",
  "oifde.rate.accessAmount": "Appels connectés",
  "oifde.rate.exitNoConsultCount": "Appels directement déconnectés",
  "oifde.rate.callStaffNoConsultCount": "Appels traités uniquement par l'agent",
  "oifde.rate.exitConsultingCount": "Appels traités uniquement par Chatbot",
  "oifde.rate.callStaffConsultCount": "Appels transférés du chatbot à l'agent",
  "oifde.rate.interfaceTimes": "Interface Heures d'appel",
  "oifde.rate.interfaceSuccessTimes": "Interfaces invoquées avec succès",
  "oifde.rate.successRate": "Interface invoquant le taux de réussite",
  "oifde.rate.autonomyRate": "Taux de résolution libre-service",
  "oifde.rate.interactCount": "Ronds de dialogue",
  "oifde.rate.averageInteractPerCall": "Moyenne des rondes de dialogue",
  "oifde.rate.accessNum": "Appels connectés",
  "oifde.rate.dayconditionTips": "Le code d'accès est manquant dans les critères de recherche.",
  "oifde.rate.hourConditionTips": "La date ou le code d'accès est manquant dans les critères de recherche.",
  "oifde.rate.rateForCharts": "Taux net de résolution en libre-service",
  "oifde.rate.interfaceRequestCount": "Invocations d'interface",
  "oifde.rate.interfaceSuccessCount": "Invocations réussies",
  "oifde.rate.interfaceSuccessRate": "Taux de réussite de l'invocation",
  "oifde.dashBoard.noData": "- (Les données supprimées ne peuvent pas être affichées.)",
  "oifde.dashBoard.serviceCapabilityDashBoard": "Tableau de bord de la capacité de service",
  "oifde.dashBoard.knowledgeDashBoard": "Tableau de bord des connaissances",
  "oifde.dashBoard.service.consultCount": "Total des réponses",
  "oifde.dashBoard.service.responseSuccessCount": "Réponses réussies",
  "oifde.dashBoard.service.unknowCount": "Réponses inconnues",
  "oifde.dashBoard.service.responseRate": "Réponse Taux de réussite",
  "oifde.dashBoard.service.feedbackCount": "Total des commentaires",
  "oifde.dashBoard.service.positiveFeedbackCount": "Rétroaction Satisfaite",
  "oifde.dashBoard.service.positiveFeedbackRate": "Taux de satisfaction",
  "oifde.dashBoard.knowledge.dateConditionTips": "Sélectionnez une date.",
  "oifde.dashBoard.knowledge.topTenChatBot": "Top 10 des codes d'accès aux chatbots",
  "oifde.dashBoard.knowledge.accessCode": "Code d'accès",
  "oifde.dashBoard.knowledge.name": "Nom et prénom",
  "oifde.dashBoard.knowledge.callCount": "Visites",
  "oifde.dashBoard.knowledge.topTenknowledge": "Top 10 des entités de connaissances utiles",
  "oifde.dashBoard.knowledge.intent": "Intention",
  "oifde.dashBoard.knowledge.faq": "Foire aux questions",
  "oifde.dashBoard.knowledge.hitCount": "Hits",
  "oifde.dashBoard.chartView.title": "Réponse Taux de réussite",
  "oifde.dashBoard.chartView.countTile": "Total des réponses",
  "oifde.dashBoard.chartView.rateTile": "Taux de réussite des réponses",
  "oifde.dashBoard.chartView.leftTile": "Total des réponses",
  "oifde.dashBoard.chartView.rightTile": "Taux de réussite des réponses",
  "oifde.dashBoard.graph.title": "Statistiques de connaissances valides",
  "oifde.dashBoard.graph.faq": "Foire aux questions",
  "oifde.dashBoard.graph.intent": "Intention",
  "oifde.dashBoard.graph.kg": "Graphique",
  "oifde.dashBoard.graph.talk": "Chatter",
  "oifde.dashBoard.graph.countTitle": "Total",
  "oifde.dashBoard.graph.hitTitle": "Hits",
  "oifde.dashBoard.graph.hitRateTitle": "Taux de succès",
  "oifde.dashBoard.graph.amount": "Quantité",
  "oifde.dashBoard.graph.percent": "Pourcentage",
  "oifde.dashBoard.graph.count": "Total",
  "oifde.dashBoard.graph.hit": "Hits",
  "oifde.dashBoard.graph.hitRate": "Taux de succès",
  "oifde.dashBoard.pie.title": "Hit Distribution des connaissances",
  "oifde.dashBoard.pie.intentTitle": "Intention",
  "oifde.dashBoard.pie.kgTitle": "Graphique",
  "oifde.dashBoard.pie.faqTitle": "Foire aux questions",
  "oifde.dashBoard.pie.talkTitle": "Chatter",
  "oifde.dashBoard.pie.intent": "Intention",
  "oifde.dashBoard.pie.kg": "Graphique",
  "oifde.dashBoard.pie.faq": "Foire aux questions",
  "oifde.dashBoard.pie.talk": "Chatter",
  "oifde.chatbot.errorCode.100010000002": "Le format est incorrect.",
  "oifde.chatbot.errorCode.100051000031": "Le nombre d'IDs d'entrée dépasse la limite supérieure.",
  "oifde.chatbot.errorCode.100051000023": "Impossible de supprimer le groupe de cas.",
  "oifde.chatbot.errorCode.100010000000": "Erreur système.",
  "oifde.chatbot.errorCode.100010000001": "Erreur de base de données.",
  "oifde.chatbot.errorCode.100010000003": "Le nom doit être unique.",
  "oifde.chatbot.errorCode.100010000005": "Le paramètre d'entrée n'est pas valide.",
  "oifde.chatbot.errorCode.100023000004": "Impossible de vérifier l'ID d'entrée.",
  "oifde.chatbot.errorCode.100051000022": "Impossible d'enregistrer le cas de test.",
  "oifde.chatbot.errorCode.100051000032": "Impossible de supprimer les cas de test.",
  "oifde.chatbot.errorCode.100051000026": "Le test ne peut pas être exécuté car le nombre de groupes de test sélectionnés est trop grand.",
  "oifde.chatbot.errorCode.100051000028": "Le résultat du test est introuvable.",
  "oifde.chatbot.errorCode.100051000109": "Le nombre de cas de test dépasse 200.",
  "oifde.serverMgr.serverType": "Type de serveur",
  "oifde.serverMgr.mrcpId": "ID de groupe",
  "oifde.serverMgr.asrServer": "Serveur ASR",
  "oifde.serverMgr.ttsServer": "Serveur TTS",
  "oifde.serverMgr.confirmDelete": "êtes-vous sûr de vouloir supprimer les données ?",
  "oifde.serverMgr.addServer": "Ajouter un serveur",
  "oifde.serverMgr.editServer": "Modifier le serveur",
  "oifde.qiAsrServerMgr.asrVendor": "Fournisseur",
  "oifde.qiAsrServerMgr.asrServerName": "Nom du serveur",
  "oifde.qiAsrServerMgr.asrServerAddress": "Adresse du serveur",
  "oifde.qiAsrServerMgr.asrAppKey": "Nom d'utilisateur/AK",
  "oifde.qiAsrServerMgr.fileServerAddress": "Adresse du serveur de fichiers",
  "oifde.qiAsrServerMgr.folder": "Dossier par défaut",
  "oifde.qiAsrServerMgr.description": "Description",
  "oifde.qiAsrServerMgr.addQiAsrServer": "Ajouter des informations sur le serveur ASR",
  "oifde.qiAsrServerMgr.editQiAsrServer": "Modifier les informations du serveur ASR",
  "oifde.qiAsrServerMgr.asrServerInfo": "Informations sur le serveur ASR",
  "oifde.qiAsrServerMgr.name": "Nom",
  "oifde.qiAsrServerMgr.asrServerIP": "Adresse du serveur ASR",
  "oifde.qiAsrServerMgr.asrServerPort": "Port du serveur ASR",
  "oifde.qiAsrServerMgr.asrServerUser": "Utilisateur du serveur ASR",
  "oifde.qiAsrServerMgr.asrServerPW": "Mot de passe du serveur ASR",
  "oifde.qiAsrServerMgr.asrServerPWConform": "Confirmer le mot de passe du serveur ASR",
  "oifde.qiAsrServerMgr.nextStep": "Suivant",
  "oifde.qiAsrServerMgr.preStep": "Précédent",
  "oifde.qiAsrServerMgr.fileServerInfo": "Informations sur le serveur de fichiers",
  "oifde.qiAsrServerMgr.fileServerType": "Type de serveur de fichiers",
  "oifde.qiAsrServerMgr.fileServerIP": "Adresse du serveur de fichiers",
  "oifde.qiAsrServerMgr.fileServerPort": "Port du serveur de fichiers",
  "oifde.qiAsrServerMgr.fileServerUser": "Nom d'utilisateur du serveur de fichiers",
  "oifde.qiAsrServerMgr.fileServerPW": "Mot de passe du serveur de fichiers",
  "oifde.qiAsrServerMgr.fileServerPWConfirm": "Confirmer le mot de passe du serveur de fichiers",
  "oifde.qiAsrServerMgr.voiceUploadFolder": "chemin d'envoi des fichiers 'enregistrement",
  "oifde.qiAsrServerMgr.qiResultFolder": "Transcrire le chemin du résultat",
  "oifde.qiAsrServerMgr.sftp": "SFTP",
  "oifde.qiAsrServerMgr.passwordError": "Les deux mots de passe entrés sont incohérents.",
  "oifde.qiAsrServerMgr.ipOrDomainNameError": "L'adresse IP ou le nom de domaine est incorrect.",
  "oifde.qiAsrServerMgr.ipError": "Erreur d'adresse IP incorrecte",
  "oifde.qiAsrServerMgr.filePathError": "Le format du chemin est incorrect",
  "oifde.qiAsrServerMgr.portTips": "Lorsque l'adresse du serveur ASR est un nom de domaine, le numéro de port est optionnel.",
  "oifde.qiAsrServerMgr.asrServerNameError": "Dupliquer le nom du serveur ASR par défaut.",
  "oifde.asrMgr.tenantId": "ID de l'espace locataire",
  "oifde.asrMgr.tenantSapce": "Espace locataire",
  "oifde.asrMgr.asr": "Fournisseur de serveur ASR",
  "oifde.asrMgr.qiAsrServerName": "Inspecter le serveur ASR",
  "oifde.asrMgr.mrcpid": "ID de groupe ASR",
  "oifde.asrMgr.path": "Chemin du fichier de grammaire ASR",
  "oifde.asrMgr.slotName": "Emplacement ASR",
  "oifde.asrMgr.groupID": "ID de groupe TTS",
  "oifde.asrMgr.addAsr": "Ajouter TTS/ASR",
  "oifde.asrMgr.editAsr": "Modifier TTS/ASR",
  "oifde.asrMgr.selectOne": "Sélectionnez au moins une des options suivantes",
  "oifde.asrMgr.TENANT_SPACE_NOT_FOUND": "L'espace locataire n'est pas trouvé.",
  "oifde.asrMgr.languageTips": "default indique que les langues ne sont pas différenciées",
  "oifde.license.license": "Gestion des licences",
  "oifde.license.import": "Charger/Mettre à jour",
  "oifde.license.setlicense": "Attribuer une licence",
  "oifde.license.queryCurrentLicense": "Interroger la concurrence actuelle et l'accumulation",
  "oifde.license.queryHistoryLicense": "Interroger Concurrence historique",
  "oifde.license.valateDate": "Date d'expiration",
  "oifde.license.totalLicense": "Quantité de licence",
  "oifde.license.LIN8OIAPAA01": "Nombre d'assistants d'agent simultanés",
  "oifde.license.LIN8OIAPVA01": "Nombre d'agents virtuels simultanés",
  "oifde.license.LIN8OIAPVA02": "Nombre cumulé d'appels d'agents virtuels",
  "oifde.license.PERMANENT": "Permanent",
  "oifde.license.processTime": "Traitement",
  "oifde.license.at_least_one_serviceValue": "Définir au moins un paramètre.",
  "oifde.license.failed_set_license": "Impossible de configurer la licence.",
  "oifde.license.success_set_license": "La licence est configurée avec succès.",
  "oifde.license.import_template_hint_dat": "Seuls les fichiers au format.dat peuvent être téléchargés.",
  "oifde.license.import_template_need_dat": "Le fichier à télécharger doit être au format .dat.",
  "oifde.license.maxHisInfo": "Valeur maximale historique",
  "oifde.license.detailHisInfo": "Détails historiques",
  "oifde.license.sys": "Système",
  "oifde.request.timeout": "Délai d'expiration de la demande d'interface",
  "oifde.analysis.validate.time": "Date requise",
  "oifde.analysis.validate.accessCode": "Code d'accès robot Requis",
  "oifde.analysis.validate.timeInterval": "L'intervalle ne dépasse pas 7 jours.",
  "oifde.analysis.chart.title": "Statistiques de branchement de nœud IVR",
  "oifde.analysis.chart.timeOther": "Time out ou appuyez sur une autre touche",
  "oifde.analysis.chart.button": "Appuyez sur la touche ",
  "oifde.analysis.chart.default": "Branche par défaut du système",
  "oifde.analysis.childrenView": "Afficher les sous-nœuds",
  "oifde.service.invoke.breakMinTime.desc": "Unité: secondes;",
  "oifde.ivrJourney.nodeOverview": "Vue d'ensemble des nœuds",
  "oifde.ivrJourney.trendAnalysis": "Analyse des tendances",
  "oifde.ivrJourney.keyDistribution": "Répartition des clés",
  "oifde.ivrJourney.nodeVersionComparison": "Comparaison des versions de nœuds",
  "oifde.ivrJourney.businessRanking": "Classement des services",
  "oifde.ivrJourney.no": "Classement",
  "oifde.ivrJourney.nodeName": "Nom du nœud",
  "oifde.ivrJourney.visitsTotal": "Visites",
  "oifde.ivrJourney.yoyValue": "Année après année",
  "oifde.ivrJourney.increaseValue": "Augmentation ou diminution",
  "oifde.ivrJourney.visitorTotal": "Visites",
  "oifde.ivrJourney.successRate": "Taux de réussite",
  "oifde.ivrJourney.failedRate": "Taux d'échec",
  "oifde.ivrJourney.hangUpRate": "Taux de raccrochage",
  "oifde.ivrJourney.journeyNumTrend": "Distribution de la tendance de la valeur",
  "oifde.ivrJourney.efficiencyTrend": "Répartition des tendances de l'efficacité",
  "oifde.ivrJourney.complexityTrend": "Répartition des tendances de complexité",
  "oifde.ivrJourney.repeatNum": "Réécoute",
  "oifde.ivrJourney.directCalls": "Direct",
  "oifde.ivrJourney.journeyNum": "Visites",
  "oifde.ivrJourney.nonDirectCalls": "Non Direct",
  "oifde.ivrJourney.travelNum": "Visites",
  "oifde.ivrJourney.hangupNum": "Hang Up",
  "oifde.ivrJourney.successCalls": "Nombre de réussites",
  "oifde.ivrJourney.failCalls": "Nombre d'échecs",
  "oifde.ivrJourney.directRate": "Taux direct",
  "oifde.ivrJourney.nonDirectRate": "Tarif non direct",
  "oifde.ivrJourney.hangupRate": "Raccrocher le taux",
  "oifde.ivrJourney.failRate": "Taux d'échec",
  "oifde.ivrJourney.repeatCalls": "Réécouter",
  "oifde.ivrJourney.repeatOnceCalls": "Réécouter une fois",
  "oifde.ivrJourney.repeatTwiceCalls": "Réécouter deux fois",
  "oifde.ivrJourney.repeatRate": "Fréquence de réécoute",
  "oifde.ivrJourney.averageTime": "Durée moyenne de service",
  "oifde.ivrJourney.averagePathLength": "Longueur moyenne du chemin",
  "oifde.ivrJourney.timeRangeCheck30": "L'intervalle de temps ne peut pas dépasser 30 jours.",
  "oifde.ivrJourney.dateTimrRange": "Plage de temps des données :",
  "oifde.ivrJourney.total": "Total général",
  "oifde.ivrJourney.inflowDistribution": "Distribution d'entrée",
  "oifde.ivrJourney.outflowDistribution": "Distribution des flux sortants",
  "oifde.ivrJourney.totalDistribution": "Distribution globale",
  "oifde.ivrJourney.detailDistribution": "Distribution détaillée",
  "oifde.ivrJourney.outflowDetailDistribution": "Distribution détaillée du flux sortant",
  "oifde.ivrJourney.outflowDetailDistributionAmount": "Quantité de distribution détaillée du flux sortant",
  "oifde.ivrJourney.outflowDetailDistributionPercentage": "Pourcentage de distribution détaillée du flux sortant",
  "oifde.ivrJourney.nodeTrafficChart": "Diagramme de trafic de nœuds (nombre d'indicateurs)",
  "oifde.ivrJourney.indicatorQuantityStatistics": "Statistiques d'indicateurs",
  "oifde.ivrJourney.indicateName": "Nom de l'indicateur",
  "oifde.ivrJourney.alarmContent": "Pré-avertissement Contenu",
  "oifde.ivrJourney.alarmTime": "Temps de pré-avertissement",
  "oifde.ivrJourney.createTime": "Temps de pré-avertissement des données",
  "oifde.ivrJourney.alarmLevel": "Niveau de pré-avertissement",
  "oifde.ivrJourney.alarmIndicator": "Indicateurs de pré-avertissement",
  "oifde.ivrJourney.remindType": "Mode de rappel",
  "oifde.ivrJourney.receivers": "Destinataire",
  "oifde.ivrJourney.sendResult": "Envoi du résultat",
  "oifde.ivrJourney.low": "Faible",
  "oifde.ivrJourney.middle": "Moyenne",
  "oifde.ivrJourney.high": "Élevé",
  "oifde.ivrJourney.noNeedToSend": "Pas besoin d'envoyer",
  "oifde.ivrJourney.sendFail": "L'envoi a échoué",
  "oifde.ivrJourney.sendSuccess": "Envoyé avec succès",
  "oifde.ivrJourney.sms": "SMS",
  "oifde.ivrJourney.email": "Courriel",
  "oifde.ivrJourney.smsAndEmail": "SMS; Courriel",
  "oifde.ivrJourney.other": "Autre",
  "oifde.ivrJourney.nowData": "Valeur actuelle",
  "oifde.ivrJourney.label": "Identification",
  "oifde.ivrJourney.transToManualCalls": "Transfert à la main-d'œuvre",
  "oifde.ivrJourney.transToNextLevelManuCalls": "Quantité d'entrée dans le menu de couche inférieure",
  "oifde.ivrJourney.timeOutOrPressAnotherKey": "Délai d'expiration ou appuyez sur une autre touche",
  "oifde.ivrJourney.buttons": "Clé",
  "oifde.ivrJourney.defaultBranch": "Branche par défaut",
  "oifde.ivrJourney.count": "Nombre de fois",
  "oifde.ivrJourney.versionName": "Nom de la version",
  "oifde.ivrJourney.compareRange": "Nom de la version",
  "oifde.ivrJourney.pleaseSelectAccessCode": "Veuillez sélectionner un robot",
  "oifde.ivrJourney.pleaseSelectAccessCodeFirst": "Veuillez d'abord sélectionner un robot",
  "oifde.ivrJourney.oneDay": "1 jour",
  "oifde.ivrJourney.threeDay": "3 jours",
  "oifde.ivrJourney.halfMonth": "Un demi-mois",
  "oifde.ivrJourney.oneMonth": "Un mois",
  "oifde.ivrJourney.chooseAccessCode": "Sélectionner un robot",
  "oifde.ivrJourney.chooseVersion": "Sélection de la version",
  "oifde.ivrJourney.releaseDate": "Délai de publication",
  "oifde.ivrJourney.nodeVersionCompareStatistics": "Comparaison des versions des nœuds",
  "oifde.ivrJourney.validIVRService": "Service IVR valide",
  "oifde.ivrJourney.transToManualCallsSucc": "Transféré en manuel avec succès",
  "oifde.ivrJourney.selfService": "Libre-service",
  "oifde.ivrJourney.repeatIncomingCalls": "Appels entrants répétés",
  "oifde.ivrJourney.returnKeysByErrors": "Retour d'erreur de clé",
  "oifde.ivrJourney.pleaseSelectVersion": "Sélectionnez une version de flux.",
  "oifde.ivrJourney.pleaseSelectCompareRange": "Veuillez sélectionner la gamme de comparaison",
  "oifde.ivrJourney.logTime": "DATE",
  "oifde.ivrJourney.validIVRCalls": "Volume de service IVR valide",
  "oifde.ivrJourney.IVRCalls": "Volume libre-service",
  "oifde.ivrJourney.IVRTransManualRate": "Proportion de trafic manuel (%)",
  "oifde.ivrJourney.IVRRate": "Taux de service IVR pur (%)",
  "oifde.ivrJourney.IVRRateNoSymbol": "Taux de service IVR pur",
  "oifde.ivrJourney.transManualSuccCalls": "Succès du transfert manuel",
  "oifde.ivrJourney.transManualSuccRate": "Taux de réussite du transfert manuel (%)",
  "oifde.ivrJourney.avgIVRTime": "Durée moyenne de l'appel (seconde)",
  "oifde.ivrJourney.avgIVRTimeNoSymbol": "Durée moyenne de l'appel",
  "oifde.ivrJourney.IVRErrorRate": "Taux d'erreur du système IVR (%)",
  "oifde.ivrJourney.IVRErrorRateNoSymbol": "Taux d'erreur du système IVR",
  "oifde.ivrJourney.IVRTotalCalls": "Volume d'appels entrants IVR",
  "oifde.ivrJourney.IVRSrvTime": "Durée totale du service (seconde)",
  "oifde.ivrJourney.wrongKeyCalls": "Nombre d'erreurs de clé retournées",
  "oifde.ivrJourney.distinctCustomers": "Nombre de clients",
  "oifde.ivrJourney.IVRSrvCustomers": "Nombre de clients IVR purs",
  "oifde.ivrJourney.callNum": "Volume d'appels",
  "oifde.ivrJourney.ivrRepeatCallNum": "Appels répétés IVR",
  "oifde.ivrJourney.resolveRate": "Taux de résolution unique",
  "oifde.ivrJourney.serviceRate": "Taux de service effectif",
  "oifde.ivrJourney.node": "nœud ",
  "oifde.ivrJourney.indicatorDesc": "Indicator Description",
  "oifde.ivrJourney.validIVRServiceDesc": "Nombre d'appels IVR dans lesquels le traitement du service ou la requête est réussi, la voix de consultation de service est jouée plus de la moitié, ou les SMS, MMS, WAP Push ou email sont déclenchés avec succès",
  "oifde.ivrJourney.transToManualCallsDesc": "Nombre d'appels SVI qui tentent d'être transférés au service manuel",
  "oifde.ivrJourney.transToManualCallsSuccDesc": "Nombre d'appels RVI qui ont été transférés avec succès à un agent",
  "oifde.ivrJourney.selfServiceDesc": "Nombre d'appels RVI qui ne sont pas transférés au service manuel",
  "oifde.ivrJourney.repeatIncomingCallsDesc": "Nombre de clients qui utilisent le même numéro d'appel pour appeler la ligne d'assistance du service à la clientèle de façon répétée dans les deux heures",
  "oifde.ivrJourney.returnKeysByErrorsDesc": "Dans le nœud de navigation, après une mauvaise pression sur une touche, le numéro renvoyé par la touche astérisque (*) est sélectionné comme invité",
  "oifde.ivrJourney.indicatorSetting": "Réglage de l'indicateur",
  "oifde.ivrJourney.indicatorSettingLengthError": "Sélectionnez au moins {count} indicateurs.",
  "oifde.ivrJourney.extendedMetrics": "Indicateur d'extension",
  "oifde.ivrJourney.optimizeVoiceSequence": "Optimisation de la séquence de lecture vocale",
  "oifde.ivrJourney.optimizeVoiceDefinition": "Optimisation de l'intelligibilité vocale",
  "oifde.ivrJourney.pleaseSelectDateRange": "Veuillez sélectionner une plage de temps.",
  "oifde.ivrJourney.reverseFlow": "Direction d'écoulement inverse",
  "oifde.ivrJourney.currentVoicePlayContent": "Contenu de la lecture vocale actuelle",
  "oifde.ivrJourney.repeatCallsNum": "Nombre de répétitions",
  "oifde.ivrJourney.optimizeSuggestion": "Suggestions d'optimisation",
  "oifde.ivrJourney.repeatCallOptimizeSuggestion": "Le nombre de répétitions sur le nœud courant est trop élevé. Vérifiez si le contenu vocal n'est pas clair ou difficile à comprendre. Nous vous conseillons d'optimiser le contenu vocal.",
  "oifde.ivrJourney.operationStatus": "État de l'opération",
  "oifde.ivrJourney.poor": "Faible",
  "oifde.ivrJourney.general": "Généralités",
  "oifde.ivrJourney.good": "Bien",
  "oifde.ivrJourney.fluctuationService": "Service de fluctuation",
  "oifde.ivrJourney.IVRIncomingCallVolumeTrend": "Tendance du volume d'appels entrants IVR",
  "oifde.ivrJourney.efficiencyOverview": "Aperçu de l'efficacité",
  "oifde.ivrJourney.repeatedCallRate": "Taux de numérotation répété",
  "oifde.ivrJourney.valueOverview": "Aperçu de la valeur",
  "oifde.ivrJourney.pureIVRServiceVolume": "Volume de service IVR pur",
  "oifde.ivrJourney.complexityOverview": "Aperçu de la complexité",
  "oifde.ivrJourney.personTime": "quantité",
  "oifde.ivrJourney.times": "fois",
  "oifde.ivrJourney.noData": "Aucun",
  "oifde.ivrJourney.usageStatistics": "Statistiques d'utilisation des touches de lecture vocale en cours",
  "oifde.ivrJourney.playingOrder": "Séquence de lecture vocale recommandée",
  "oifde.ivrJourney.journeySum": "Temps d'utilisation",
  "oifde.ivrJourney.forever": "Valide en permanence",
  "oifde.ivrJourney.slectTimeForever": "Spécifie la période de validité.",
  "oifde.ivrJourney.alarmIndex": "Indicateurs statistiques",
  "oifde.ivrJourney.alarmCondition": "Condition de pré-avertissement",
  "oifde.ivrJourney.alarmValidity": "Période de validité de pré-avertissement",
  "oifde.ivrJourney.selectAlarmCondition": "Veuillez sélectionner la condition d'alerte à supprimer.",
  "oifde.ivrJourney.remindObject": "Objet rappel",
  "oifde.ivrJourney.transfManualRate": "Taux de transfert à taux manuel",
  "oifde.ivrJourney.absoluteValue": "valeur absolue",
  "oifde.ivrJourney.highThan": "Supérieur à",
  "oifde.ivrJourney.equal": "Est égal à",
  "oifde.ivrJourney.lessThen": "Moins de",
  "oifde.ivrJourney.createAlarmConfig": "Création d'une condition de pré-alarme de service",
  "oifde.ivrJourney.editAlarmConfig": "Modification d'une condition de pré-alarme de service",
  "oifde.ivrJourney.selectAccesscodeAndFlow": "Sélectionnez une version de robot et de traitement.",
  "oifde.ivrJourney.selectAlarmIndex": "Veuillez sélectionner un indicateur statistique.",
  "oifde.ivrJourney.fillAlarmCondition": "Veuillez remplir les conditions préalables à l'alarme.",
  "oifde.ivrJourney.selectAlarmLevel": "Veuillez sélectionner un niveau d'avertissement.",
  "oifde.ivrJourney.selectTime": "Veuillez sélectionner une heure valide.",
  "oifde.ivrJourney.selectAlramRemindType": "Sélectionnez au moins un mode de rappel.",
  "oifde.ivrJourney.selectRemindObject": "Veuillez sélectionner un objet de rappel.",
  "oifde.ivrJourney.remindObjectMaxNum": "Un maximum de 20 caractères peut être sélectionné pour l'objet de notification.",
  "oifde.ivrJourney.batchDetele": "Suppression par lot",
  "oifde.ivrJourney.selectSmsGateway": "Veuillez sélectionner une passerelle SMS.",
  "oifde.ivrJourney.selectMailGateway": "Veuillez sélectionner une passerelle de messagerie.",
  "oifde.ivrJourney.indicatorComparisonBarChart": "Diagramme à barres de comparaison d'indicateurs",
  "oifde.ivrJourney.indicatorComparisonStatistics": "Statistiques de comparaison des compteurs",
  "oifde.ivrJourney.beforeRelease": "Avant la publication",
  "oifde.ivrJourney.afterRelease": "Après la publication",
  "oifde.ivrJourney.beforeReleaseRepeatRate": "Taux de répétition avant publication",
  "oifde.ivrJourney.afterReleaseRepeatRate": "Taux de répétition après publication",
  "oifde.ivrJourney.beforeReleaseHangupNum": "Nombre d'appels raccrochés avant publication",
  "oifde.ivrJourney.afterReleaseHangupNum": "Nombre d'appels raccrochés après la libération",
  "oifde.headerTitle.flowManagement": "Gestion des processus",
  "oifde.headerTitle.flowConfiguration": "Configuration du processus",
  'oifde.maintain.odfsLogOpen': 'Activation du journal ODFS',
  'oifde.maintenance.level': 'Niveaux',
  'oifde.maintenance.startTime': 'Date d\'entrée en vigueur',
  'oifde.maintenance.duration': 'Durée (minutes)',
  'oifde.maintenance.startLog': 'Journaux activés',
  'oifde.maintenance.stop_success': 'L\'arrêt du journal a réussi',
  'oifde.maintenance.start_success': 'Le journal est activé avec succès',
  'oifde.route.transferRecord': 'Enregistrement de transfert',
  'oifde.route.audioVideoResource': 'Ressource audio et vidéo',
  'oifde.serviceInterface.tierAPILog': 'Enregistrement d\'appel d\'interface',
  'oifde.title.chooseFlow': 'Choisissez le flux',
  'oifde.tip.inputFlowName': 'Entrez un nom de flux',
  'oifde.error.createfail': 'Impossible d\'ajouter des données locales!',
  'oifde.error.gateway.fail': 'Impossible de synchroniser les informations de la plate-forme CTI! Veuillez vérifier la configuration de la passerelle.',
  'oifde.error.NMS.fail': 'Échec de la synchronisation avec le NMS!',
  'oifde.flowname.maxlength': 'Le nom du robot est trop long.',
  'oifde.audioVideoResource.setLanguage': 'Langue Modifier',
  'oifde.audioVideoResource.preview': 'Aperçu',
  'oifde.audioVideoResource.viewReferences': 'Voir les références',
  'oifde.audioVideoResource.cancelUpdate': 'Annuler la mise à jour',
  'oifde.audioVideoResource.updateBefore': 'Avant la mise à jour',
  'oifde.audioVideoResource.updateAfter': 'Après la mise à jour',
  'oifde.audioVideoResource.templateName': "Saisissez un nom de modèle.",
  'oifde.audio.speedRange': 'La plage de valeurs est [-200, 500]',
  'oifde.route.sysAudio': 'Gestion de la voix',
  'oifde.param.maxLength': 'La longueur du paramètre ne peut pas dépasser ',
  'oifde.tip.oldNode': 'Les éléments de diagramme obsolètes ne sont pas recommandés',
  'oifde.diagram.element.expired': 'Élément expiré',
  'oifde.diagram.element.basic': 'Élément de base',
  'oifde.diagram.element.logic': 'Élément logique',
  'oifde.diagram.element.business': 'Élément de service',
  'oifde.diagram.element.composite': 'Élément composite',
  'oifde.diagram.node.type': 'Type de nœud',
  'oifde.title.chooseGrayRule': 'Choisissez la règle grise',
  'oifde.title.chatbot': 'Chat bot',
  'oifde.audioVideoResource.cancelUpdate.confirm': 'Êtes-vous sûr d\'annuler la mise à jour?',
  'oifde.audioVideoResource.cancelUpdate.success': 'La mise à jour a été annulée avec succès',
  'oifde.audioVideoResource.cancelUpdate.fail': 'Échec de l\'annulation de la mise à jour',
  'oifde.audioVideoResource.upload.tips': 'Astuce:Téléchargez le même fichier à plusieurs reprises, changez le nom du fichier ou changez le téléchargement du fichier.',
  'oifde.robotReply.displayName': 'Réponse de robot',
  'oifde.robotReply.replyWay': 'Répondre Way',
  'oifde.robotReply.replyWayBreve': 'Manière',
  'oifde.robotReply.replyResourceBreve': 'Ressource',
  'oifde.robotReply.replyVariableBreve': 'Variable',
  'oifde.robotReply.replyTemplateBreve': 'Modèle',
  'oifde.robotReply.replModeBreve': 'Mode',
  'oifde.robotReply.replyTemplate': 'Modèle de réponse',
  'oifde.robotReply.selectReplyTemplate': 'Sélectionner le modèle',
  'oifde.robotReply.replyVariable': 'Répondre Variable',
  'oifde.robotReply.replyResource': 'Ressource de réponse',
  'oifde.robotReply.selectReplyResource': 'Sélectionner une ressource',
  'oifde.robotReply.replMode': 'Mode de réponse',
  'oifde.validation.repeats.varName': 'Nom de variable en double.',
  'oifde.procedure.mysqlUrlRule': 'Format d\'URL incorrect. Pour plus de détails sur le format d\'URL, voir jdbc:mysql://ip:port/db_name. prop1=valeur1&prop2=valeur2',
  'oifde.procedure.oracleUrlRule': 'Format d\'URL incorrect. Pour plus de détails sur le format d\'URL, voir jdbc:oracle:thin:{\'@\'}ip:port:SID ou jdbc:oracle:thin:{\'@\'}/ip:port/service',
  'oifde.procedure.gaussUrlRule': 'Format d\'URL incorrect. Pour plus de détails sur le format d\'URL, voir jdbc:zenith:{\'@\'}ip:port[? clé=valeur[&key=valeur]..]',
  'oifde.chatBotTest.testCaseGroupName.maxLen': 'Le nom du groupe de cas ne peut pas dépasser 128 caractères.',
  'oifde.chatBotTest.testCaseName.maxLen': 'Le nom de la casse ne peut pas dépasser 128 caractères.',
  'oifde.chatBotTest.createCase.maxLen': 'Le nombre de cas de test sélectionnés ne peut dépasser 100.',
  'oifde.procedure.selectProcedure': 'Sélectionnez une procédure stockée',
  'oifde.procedure.resultProcess': 'Traitement des résultats',
  'oifde.robotReply.title.chooseTemplate': 'Sélectionner le modèle de réponse',
  'oifde.robotReply.title.chooseResource': 'Sélectionner la ressource de réponse',
  'oifde.element.varAssign': 'Affectation variable',
  'oifde.promptType.title': 'Type d\'invite',
  'oifde.interfaceInvoke.header': 'en-tête du message d\'interface',
  'oifde.interfaceInvoke.body': 'Paramètre d\'entrée de l\'interface',
  'oifde.interfaceInvoke.queryParam': 'Paramètre de requête d\'interface',
  'oifde.interfaceInvoke.method.return': 'Valeur de retour de la méthode',
  'oifde.unit.millisecond': 'milliseconde',
  'oifde.title.transData': 'Obtention des données associées',
  'oifde.robotReply.title.addResource': 'Ajouter une ressource de réponse',
  'oifde.mxgraph.saveDraft': 'Enregistrer le brouillon',
  'ccivr.nms.conditionService.expression.placeholder':'Saisissez une expression de condition',
  'oifde.diagram.error.basicEnd.length': 'La valeur ne peut pas dépasser 4096 caractères.',
  'oifde.tableData.template': 'Télécharger le modèle de données',
  'oifde.tableData.download.template': 'Télécharger le modèle',
  'oifde.tableData.import.max': 'Un maximum de 10000 enregistrements peuvent être importés.',
  'oifde.tableData.import.tableName': 'Nom de la table de données',
  'oifde.procedure.db.dbName':'Nom de la base de données',
  'oifde.procedure.procedureDbName':'Nom de la base de données',
  'oifde.procedure.dbNameWarning':'Le nom de la base de données ne peut contenir que des lettres\, des chiffres\, des caractères chinois\, des espaces\, des soulignements (_) et des guillemets simples.',
  'oifde.procedure.dbNameError':'Le nom de la base de données de la procédure stockée ne peut pas être une chaîne vide ou une chaîne de 64 caractères.',
  'oifde.procedure.actions.deleteOnlyOneDb':'Échec de la suppression. Impossible de supprimer toutes les informations de connexion à la base de données.',
  'oifde.serviceInterface.actions.deleteDbInfo':'Cette opération va supprimer les informations de la base de données sélectionnée. Voulez-vous vraiment continuer ?',
  'oifde.procedure.actions.deleteDBFailed':'Échec de la suppression. La connexion à la base de données a été référencée par la procédure stockée {0} et ne peut pas être supprimée.',
  'oifde.procedure.actions.deleteDBFailed2':'Échec de la suppression. La connexion à la base de données a été référencée par {1} procédures stockées, telles que {0}.',
  'oifde.procedure.actions.deleteFailed':'Échec de la suppression',
  'oifde.procedure.actions.createDBFailed':'Échec de la création. Le nombre de bases de données a atteint le maximum.',
  'oifde.tableData.diagram.elementName': 'Tableau de données',
  'oifde.tableData.diagram.searchCriteria': 'Critères de recherche',
  'oifde.tableData.diagram.searchPage': 'Page de recherche',
  'oifde.tableData.diagram.searchResult': 'Résultat de la recherche',
  'oifde.tableData.diagram.returnRecords': 'Retour d\'enregistrements',
  'oifde.tableData.diagram.total': 'Total',
  'oifde.workday.workDayName': 'Nom du calendrier de travail',
  'oifde.workday.workDayDesc': 'Descriptif',
  'oifde.workday.workWeekDay': 'Sélection de la semaine',
  'oifde.workday.servicePeriod': 'Période de service',
  'oifde.workday.specificNonServiceDay': 'Date de non-service spécifiée',
  'oifde.workday.specificServiceDay': 'Date de service spécifiée',
  'oifde.workday.servicePeriodDesc': 'Période de service. Configurer 1 à 10 enregistrements',
  'oifde.workday.specificNonServiceTip': 'Si une date de non-service spécifiée est configurée, la date de non-service est considérée comme un jour non-ouvrable avec la priorité la plus élevée.',
  'oifde.workday.specificServiceTip': 'Si une date de service spécifiée configurée n\'est pas disponible dans la sélection de la semaine, la date de service est considérée comme un jour ouvrable.',
  'oifde.workday.name': 'Nom',
  'oifde.workday.time': 'Temps',
  'oifde.workday.date': 'Date',
  'oifde.workday.addWorkDayTitle': 'Ajouter un calendrier de travail',
  'oifde.workday.editWorkDayTitle': 'Modifier le calendrier de travail',
  'oifde.workday.required': 'Ce champ est obligatoire.',
  'oifde.workday.updateTime': 'Modifié',
  'oifde.workday.operation': 'Opération',
  'oifde.workday.actions.edit': 'Modifier',
  'oifde.workday.actions.delete': 'supp.',
  'oifde.workday.actions.prompt': 'Confirmer',
  'oifde.workday.actions.confirm': 'Oui',
  'oifde.workday.actions.cancel': 'Non',
  'oifde.workday.actions.ok': 'OK',
  'oifde.workday.actions.deletePrompt' : 'Cette opération supprimera définitivement le calendrier de travail. Êtes-vous sûr de vouloir continuer ?',
  'oifde.workday.actions.cancelDelete': 'La suppression est annulée.',
  'oifde.workday.actions.deleteSuccess': 'Suppression réussie.',
  'oifde.workday.actions.updateSuccess': 'Modification réussie.',
  'oifde.workday.actions.createSuccess': 'Création réussie.',
  'oifde.workday.actions.missWorkDayName': 'Le nom du calendrier de travail ne peut pas être vide.',
  'oifde.workday.actions.missWorkWeekDay': 'La sélection de la semaine ne peut pas être vide.',
  'oifde.workday.actions.minServicePeriod': 'Au moins un enregistrement doit être configuré pour la période de service.',
  'oifde.workday.actions.missPeriodName': 'Le nom de la période de service ne peut pas être vide.',
  'oifde.workday.actions.missPeriodTime': 'Le temps de période de service ne peut pas être vide.',
  'oifde.workday.actions.missNonServiceName': 'Le nom de la date de non-service spécifiée ne peut pas être vide.',
  'oifde.workday.actions.missNonServiceDate': 'La date de non-service spécifiée ne peut pas être vide.',
  'oifde.workday.actions.missServiceName': 'Le nom de la Date du service spécifiée ne peut pas être vide.',
  'oifde.workday.actions.missServiceDate': 'La date de service spécifiée ne peut pas être vide.',
  'oifde.workday.tip.overServicePeriod': 'Le nombre d\'enregistrements de période de service ne peut dépasser 10.',
  'oifde.workday.tip.overNonServiceDate': 'Le nombre d\'enregistrements de dates de non-service spécifiée ne peut pas dépasser 50.',
  'oifde.workday.tip.overServiceDate': 'Le nombre d\'enregistrements de la date de service spécifiée ne peut pas dépasser 50.',
  'oifde.workday.tip.overWorkDayCount': 'Le nombre d\'enregistrements du calendrier de travail ne peut pas dépasser 1000.',
  'oifde.workday.actions.nameError': 'Tous les noms ne sont autorisés que les caractères chinois, les lettres anglaises, les chiffres, les espaces, les traits de soulignement (_), les traits d\'union et les guillemets simples (\').',
  'oifde.tableData.diagram.addCriteria': 'Ajouter un groupe',
  'oifde.tableData.diagram.logic': 'logique',
  'oifde.diagram.message.recoverData': 'Restaurer les données',
  'oifde.diagram.message.recoverDataDesc': 'Voulez -vous rendre le canevas avec les dernières données de processus temporairement sauvegardées & #160;?',
  'oifde.button.openVariable': 'Variable ouverte >',
  'oifde.button.closeVariable': 'Réduire les variables <',
  'oifde.varGlobal.builtIn': 'globale(intégrée)',
  'oifde.varGlobal.custom': 'globales',
  'oifde.route.workDay': 'Calendrier de travail',
  'oifde.trackmgr.robotsSource': 'Sources',
  'oifde.dataTable.tableName': 'Nom de la table',
  'oifde.dataTable.tableId': 'ID de table',
  'oifde.dataTable.tableField': 'Champs de table',
  'oifde.dataTable.fieldId': 'ID de champ',
  'oifde.dataTable.fieldName': 'Nom du champ',
  'oifde.dataTable.fieldType': 'Type de champ',
  'oifde.dataTable.fieldLength': 'Longueur du champ',
  'oifde.dataTable.defaultValue': 'Valeur par défaut',
  'oifde.dataTable.deleteTips': 'Cette opération supprimera définitivement la table de données et les données de la table de données. Voulez -vous vraiment continuer ?',
  'oifde.dataTable.deleteBatchTips': 'Aucune table de données à supprimer n\'est sélectionnée',
  'oifde.dataTable.editDataTable': 'Modification d\'une table de données',
  'oifde.dataTable.addDataTable': 'Ajout d\'une table de données',
  'oifde.dataTable.editDataTableField': 'Modifier les champs de la table',
  'oifde.dataTable.addDataTableField': 'Ajout d\'un champ de table',
  'oifde.dataTable.validation.tableId': 'Seuls les lettres, les chiffres et les soulignements (_) sont autorisés et ne peuvent pas commencer par un chiffre',
  'oifde.dataTable.validation.presetField': 'ID, TENANTID, CREATETIME et MODIFYTIME sont des champs prédéfinis et insensibles à la casse. Ne les utilisez pas',
  'oifde.dataTable.fieldsMaxSize': 'Le nombre de champs de la table ne peut pas dépasser 20',
  'oifde.dataTable.fieldsNotEmpty': 'Le champ de la table ne peut pas être vide',
  'oifde.dataTable.exceedMaxSize': 'Le nombre de tables de données ne peut pas dépasser 40',
  'oifde.dataTable.saveAndInsert': 'Enregistrer et ajouter des données',
  'oifde.service.table.dialog.title': 'Données',
  'oifde.service.table.dialog.selectField': 'Sélectionner le champ',
  'oifde.service.table.dialog.inputValue': 'Valeur du champ de saisie',
  'oifde.service.table.dialog.addRecord': 'Ajouter des données',
  'oifde.service.table.dialog.editRecord': 'Modifier les données',
  'oifde.service.table.dialog.integer': 'Seuls les entiers sont autorisés.',
  'oifde.service.table.dialog.string': 'La longueur maximale d\'une valeur du type chaîne est 10000.',
  'oifde.service.table.dialog.oneday': 'Last 1 day',
  'oifde.service.table.dialog.threeday': 'Les 3 derniers jours',
  'oifde.service.table.dialog.sevenday': 'Les 7 derniers jours',
  'oifde.service.table.dialog.custom': 'Custom',
  'oifde.service.table.dialog.batchdelete': 'Confirmer la suppression du lot',
  'oifde.service.table.dialog.delete': 'Confirmer la suppression',
  'oifde.dataTable.criteria': 'Critères',
  'oifde.dataTable.diagram.fieldName': 'Nom du champ',
  'oifde.dataTable.diagram.fieldValue': 'Valeur du champ',
  'oifde.service.table.import.max.data': 'Le nombre total de données importées dépasse 10000',
  'oifde.service.table.import.file.empty': 'Le contenu du fichier lu est vide.',
  'oifde.service.table.import.file.read': 'Échec de l\'analyse du fichier',
  'oifde.service.table.import.excute.max': 'Une tâche d\'importation en cours d\'exécution existe déjà. Veuillez patienter jusqu\'à ce que l\'importation soit terminée.',
  'oifde.service.table.import.field.error': 'Le champ de la table n\'existe pas.',
  'oifde.service.table.import.string.max': 'La longueur totale de la valeur du type de chaîne dépasse 10000 caractères',
  'oifde.service.table.import.fieldType.error': 'Le type de champ et la valeur ne correspondent pas.',
  'oifde.title.chooseGreyRule': 'Choisissez la règle grise',
  'oifde.conditionService.param.tips': 'Si l\'expression de condition contient une constante de chaîne de caractères, ajoutez des guillemets pour l\'utiliser correctement',
  'oifde.common.btn.batch.operation': 'Opération lots',
  'oifde.char.invalid': 'Caractères non valides',
  'oifde.validate.validChar': 'Gamme de caractères autorisée : ',
  'oifde.service.table.dialog.nodatedelete': 'Aucune donnée à supprimer n\'est sélectionnée',
  'oifde.service.table.dialog.exceedMaxSize': 'Un maximum de 10000 enregistrements peuvent être ajoutés',
  'oifde.variable.rightList.tips': 'Les variables affichées à droite peuvent être glissées dans la zone de texte des variables.',
  'oifde.service.table.import.field.length.error': 'La longueur du champ dépasse la limite.',
  'oifde.screenfull.cancel': 'Quitter le plein écran',
  'oifde.variable.sys.callMediaType': 'Type de média de l\'appel',
  'oifde.variable.sys.systemDate': 'Date du système(YYYYMMDD)',
  'oifde.variable.sys.systemMonth': 'Année/mois du système(YYYYMM)',
  'oifde.variable.sys.currentTime': 'Heure actuelle du système(yyyyMMddHHmmss)',
  'oifde.variable.sys.systemTime': 'Heure actuelle du système(HHmmss)',
  'oifde.variable.sys.timeStamp': 'Horodatage, qui est une chaîne de caractères. Nombre total de secondes entre 00:00:00:00 GMT le 1er janvier 1970 (08:00:00 GMT le 1er janvier 1970) et aujourd\'hui.',
  'oifde.variable.sys.createTime': 'Heure de début de l\'appel',
  'oifde.variable.sys.callingNumber': 'Numéro du client. Ce paramètre indique le numéro d\'appel pour les appels entrants vers le centre de contact en nuage. Ce paramètre indique le numéro appelé pour les appels sortants vers le centre de contact en nuage. Type de chaîne de caractères. Le numéro est transféré par le processus IVR.',
  'oifde.variable.sys.calledNumber': 'Code d\'accès au flux système. Type de chaîne de caractères. Le numéro est transféré par le processus IVR et est le code d\'accès du processus. Vous pouvez vous connecter à la console de configuration WAS de la plate-forme CTI pour afficher le numéro.',
  'oifde.variable.sys.realCalledNumber': 'Code d\'accès, qui est configuré dans la configuration de l\'appelé dans le flux, c\'est-à-dire le numéro de hotline composé pour les appels entrants dans le flux.',
  'oifde.variable.sys.callId': 'ID de session ODFS, qui est une chaîne de caractères. L\'ID est transféré par le processus IVR, c\'est-à-dire IVRREQUEST.call_id.',
  'oifde.variable.sys.ctiCallid': 'ID d\'appel CTI de l\'appel réel transféré par le flux IVR, c\'est-à-dire le paramètre IVRREQUEST.cti_callid dans la requête IVR.',
  'oifde.variable.sys.tenantId': 'ID du locataire actuel',
  'oifde.variable.sys.language': 'Langue du client actuel',
  'oifde.variable.ivrRequest.ctiCallid': 'Indique l\'ID d\'appel CTI de l\'appel réel transféré par l\'IVR de Huawei. Ce paramètre est laissé vide dans d\'autres scénarios. La valeur contient un maximum de 32 caractères.',
  'oifde.variable.ivrRequest.input': 'Résultat de l\'interaction de la conversation.',
  'oifde.variable.ivrRequest.IACmd': 'Remplissez des champs dédiés pour la correspondance intelligente et les ordres de travail intelligents.',
  'oifde.variable.ivrRequest.TextSource': 'La source du texte de la demande.',
  'oifde.variable.ivrRequest.transinData': 'Paramètres de données associés au canal convenus entre la partie d\'accès et l\'OIAP, tels que les paramètres de paquets de données envoyés par l\'IVR.',
  'oifde.variable.ivrRequest.beginPlay': 'Heure de début de la lecture vocale.',
  'oifde.variable.ivrRequest.endPlay': 'Heure de fin de la lecture de l\'annonce.',
  'oifde.variable.ivrRequest.callId': 'ID unique de l\'appel. La valeur est la même que celle de l\'identifiant utilisateur et est utilisée pour le processus.',
  'oifde.variable.ivrRequest.interIdx': 'Nombre de temps d\'interaction, c\'est-à-dire le nombre d\'interactions consécutives avec les utilisateurs enregistrées dans l\'ODFS.',
  'oifde.variable.ivrRequest.feedback': 'Satisfaction de la rétroaction : 1 (satisfaisant) 0 (insatisfait). Type entier.',
  'oifde.variable.ivrRequest.feedbackContent': 'Raison de la rétroaction insatisfaisante (en blanc si la rétroaction est satisfaisante).',
  'oifde.variable.ivrRequest.channelType': 'Type de canal d\'accès client. Par exemple, WEB, INSTAGRAM, TELEGRAM, WECHAT, FACEBOOK, TWITTER, EMAIL, LINE, WHATSAPP, 5G, SMS',
  'oifde.variable.ivrRequest.ieValue': 'Titre de l\'e-mail reçu lors de l\'accès au canal e-mail',
  'oifde.variable.toc.ChatBot': 'Paramètres d\'emplacement configurés sur l\'intention',
  'oifde.variable.toc.ChatBotIntentCode': 'Code d\'intention de retour correspondant à la reconnaissance vocale',
  'oifde.variable.toc.ChatBotRespContent': 'Indique les informations de réponse retournées par l\'intention correspondante de reconnaissance vocale, y compris les emplacements et les réponses configurées dans la réponse.',
  'oifde.variable.toc.ChatBotScenarioName': 'Nom du modèle d\'intention mis en correspondance après l\'exécution de la reconnaissance vocale.',
  'oifde.variable.toc.ChatBotResponse': 'Identifier tout le contenu de l\'interface',
  'oifde.datatable.open.type': 'Une fois le fichier exporté, ouvrez-le au format UTF-8.',
  'oifde.datatable.dateValue.tip': 'Horodatage, en millisecondes. Saisissez un entier long ou une valeur de caractère ou une variable.',
  'oifde.datatable.intList.tip': 'Exemple : [1, 2, 3].',
  'oifde.datatable.stringList.tip': 'Exemple: ["a","b","c"].',
  'oifde.expression.expr_number_varOrValue': 'Entrez une valeur ou une variable de type numérique.',
  'oifde.expression.expr_list_varOrValue': 'Saisissez une valeur ou une variable du type de liste.',
  'oifde.service.table.import.index': 'Ligne {0}',
  'oifde.flow.rule.noRule': 'Impossible de trouver la règle grise. Vérifiez si la règle est supprimée.',
  'oifde.procedure.databaseConnection': 'Connexion à la base de données',
  'oifde.cell.viewRefrenceInfo': 'Informations de référence',
  'oifde.cell.viewRefrenceInfo.flow': 'Référence du processus',
  'oifde.cell.viewRefrenceInfo.complexcell': 'Références d\'entités composées',
  'oifde.accessCode.select.max': 'Les enregistrements {0} ont été sélectionnés. Un maximum d\'enregistrements {1} peut être sélectionné.',
  'oifde.mxgraph.deleteDraft': 'Supprimer le brouillon',
  'oifde.mxgraph.deleteDraft.confirm': 'Voulez -vous vraiment supprimer le brouillon & #160;?',
  'oifde.mxgraph.deleteDraft.noDraftExist': 'Aucune donnée provisoire n\'existe',
  'oifde.resource.needApproval': 'Les ressources de réponse sont créées avec succès et ne peuvent être utilisées qu\'après avoir été approuvées par l\'administrateur système.',
  'oifde.common.validation.accessCode.description': 'Les sauts de ligne et les caractères spéciaux suivants ne sont pas autorisés: "[]\'&()=;<>,',
  'oifde.tableData.import.tableNameBreve': 'Nom de la table',
  'oifde.flow.actions.publish.existDraft': 'Le processus actuel contient des données brouillons. Modifiez les données avant de les publier ou ignorez les données brouillons et publiez-les directement.',
  'oifde.export.finish.success': 'Exporté avec succès.',
  'oifde.input.tips.varAndConst': 'Saisissez une variable ou une constante. Utilisez des guillemets avant et après la chaîne.',
  'oifde.export.keyPressRobot.title': 'Exporter les enregistrements IVR ordinaires',
  'oifde.export.password': 'Compresser le mot de passe',
  'oifde.export.password.placeholder': 'Veuillez saisir le mot de passe compressé',
  'oifde.export.password.error.tips': 'Veuillez saisir le mot de passe utilisé pour générer un fichier compressé de 8 à 12 caractères, qui doit contenir des chiffres, des lettres et des caractères spéciaux à l\'exception de /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.password.error.message': 'Doit contenir des chiffres, des lettres et des caractères spéciaux sauf /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.task.status': 'État de la tâche',
  'oifde.export.task.fail': 'Échec de l\'exportation',
  'oifde.export.businessInterface.title': 'Exportation d\'enregistrements d\'interface personnalisés',
  'oifde.export.variable.title': 'Exportation d\'enregistrements variables personnalisés',
  'oifde.export.complexcell.title': 'Exporter des enregistrements composites d\'éléments de diagramme',
  'oifde.export.accessCode.title': 'Exportation d\'enregistrements de robots intelligents',
  'oifde.export.intelligentIVRFlow.title': 'Exportation des enregistrements de processus',
  'oifde.export.intelligentIVRFlow.button': 'Flux d\'exportation',
  'oifde.route.2dDigitalHumanImg': 'Humain virtuel',
  'oifde.route.add2dDigitalHumanImg': 'Créer un humain virtuel',
  'oifde.digitalHuman.outputSetting': 'Paramètre de sortie',
  'oifde.digitalHuman.standingPosition': 'Positionnement',
  'oifde.digitalHuman.stationTemplate': 'Modèle de positionnement',
  'oifde.digitalHuman.preciseAdjustment': 'Ajustez avec précision',
  'oifde.digitalHuman.left': 'Gauche',
  'oifde.digitalHuman.center': 'Centre',
  'oifde.digitalHuman.right': 'Droite',
  'oifde.digitalHuman.imageProportion': 'Ratio d\'image',
  'oifde.exportdata.message.overData': 'Le nombre d\'exportations a dépassé la limite maximale de 10 000. Veuillez sélectionner à nouveau les critères de recherche.',
  'oifde.flow.title.publishFlow': 'Flux de libération',
  'oifde.flow.title.upgrade': 'Flux de mise à niveau',
  'oifde.flow.action.editInfo': 'Modifier les informations',
  'oifde.flow.title.editInfo': 'Modification des informations de flux de base',
  'oifde.robot.title.editInfo': 'Modification des informations de base du chatbot',
  'oifde.input.placeholder.searchName': 'Nom de recherche',
  'oifde.avatar.tenantId':'tenant ID',
  'oifde.avatar.tenantSpace':'Espace locataire',
  'oifde.avatar.avatarName':'Nom du rôle',
  'oifde.avatar.avatarCode':'Code de rôle',
  'oifde.avatar.headerImageUrl':'Image',
  'oifde.avatar.serverAddress':'Adresse du serveur',
  'oifde.avatar.avatarAppKey':'Nom d\'utilisateur du serveur/AK',
  'oifde.avatar.create':'Créer un service humain virtuel',
  'oifde.avatar.update':'Modifier le service humain virtuel',
  'oifde.avatar.headerImage': 'Photo de profil',
  'oifde.avatar.poseImage': 'Posez l\'image',
  'oifde.avatar.actionList':'Liste des actions',
  'oifde.avatar.form.message': 'Information humaine virtuelle 2D',
  'oifde.avatar.service.message':'Informations sur le serveur',
  'oifde.avatar.serverPort':'Port du serveur',
  'oifde.avatar.userName':'Nom de l\'utilisateur du serveur',
  'oifde.avatar.pwd':'Mot de passe du serveur',
  'oifde.avatar.checkPwd':'Confirmer le mot de passe du serveur',
  'oifde.avatar.resolution': 'Résolution',
  'oifde.avatar.imgRule': 'Seuls les formats JPG, PNG et JPEG sont pris en charge. La taille du fichier ne peut pas dépasser 2 MB.',
  'oifde.avatar.action_1': 'Salutations',
  'oifde.avatar.action_2': 'Les mains en avant',
  'oifde.avatar.action_3': 'Nod',
  'oifde.avatar.action_4': 'Les mains jointes',
  'oifde.avatar.action_5': 'Écartez votre main gauche',
  'oifde.avatar.action_6': 'Écartez votre main droite',
  'oifde.avatar.selectTitle': 'Sélectionnez une image d\'avatar',
  'oifde.digitalHuman.imgLimit': 'Seuls les fichiers image JPG, PNG et JPEG sont pris en charge. La taille du fichier ne peut pas dépasser 2 Mo.',
  'oifde.digitalHuman.imageProportion0-5': '0.5',
  'oifde.digitalHuman.imageProportion0-75': '0.75',
  'oifde.digitalHuman.imageProportion1': '1',
  'oifde.digitalHuman.canvasNoSupport': 'Le navigateur actuel ne supporte pas le canevas. Mettez d\'abord à jour ou modifiez le navigateur.',
  'oifde.digitalHuman.noRoleData': 'Aucune donnée de rôle. Contactez le personnel d\'exploitation pour ajouter les données de rôle.',
  'oifde.digitalHuman.sizeError': 'Résolution d\'image incorrecte. Téléchargez une image d\'arrière-plan au format {0} x {1}.',
  'oifde.title.add.complexCell': 'Nouvelle entité composite',
  'oifde.title.add.complexCellCatalog': 'Nouveau répertoire d\'éléments composites',
  'oifde.title.edit.complexCellCatalog': 'Modifier le catalogue d\'éléments composites',
  'oifde.title.add.rule': 'Nouvelle règle',
  'oifde.title.edit.rule': 'Modifier la règle',
  'oifde.ivrJourney.pleaseSelectData': 'Veuillez sélectionner les données',
  'oifde.digitalHuman.needRole': 'Sélectionnez un rôle.',
  'oifde.digitalHuman.actions.deletePrompt': 'Cette opération supprimera définitivement l\'humain virtuel. Êtes-vous sûr de vouloir continuer ?',
  'oifde.avatar.validation.ttsSoundSpeed': 'Entrez une décimale entre 0,5 et 1,5.',
  'oifde.avatar.validation.scale': 'Entrez une décimale entre 0,5 et 1,0.',
  'oifde.avatar.pleaseSelectData': 'Les données modifiées ne seront pas sauvegardées après la fermeture. Êtes-vous sûr de vouloir continuer ?',
  'oifde.digitalHuman.actions.savePrompt': 'Cette opération mettra à jour l\'humain virtuel. Êtes-vous sûr de vouloir continuer ?',
  'oifde.digitalHuman.coordinate': 'Coordonnée',
  'oifde.digitalHuman.dimensions': 'Dimensions',
  'oifde.digitalHuman.imgTypeError': 'Type incorrect. Seuls les formats JPG, PNG et JPEG sont pris en charge.',
  'oifde.expression.expr_var_flow': 'Entrez une variable de processus commençant par FLOW',
  'oifde.expression.expr_string_or_var': 'Entrez une valeur du type variable ou chaîne de caractères. Utilisez des guillemets doubles avant et après la chaîne.',
  'oifde.common.actions.deleteConfig': 'Cette opération supprimera définitivement la configuration. Êtes-vous sûr de vouloir continuer ?',
  'oifde.expression.numberCharStartWith0': 'Utilisez des guillemets doubles avant et après une chaîne numérique commençant par 0. Par exemple, 05 doit être inscrit comme "05". Sinon, la valeur est considérée comme 5.',
}
