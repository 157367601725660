export default {
    'aicc.loading': 'Laden',
    'aicc.select': 'auswählen',
    'aicc.numberOnly': 'Kann nur Ziffern enthalten',
    'aicc.unorganized': 'Unorganisiert',
    'aicc.organized': 'Organisiert',
    'aicc.columnConfig': 'Spaltenkonfiguration',
    'aicc.selectAll': 'Alle ausgewählt',
    'aicc.selectedColumns': 'Ausgewählte Spalten',
    'aicc.selectedColumnsTip': '(kann zum Anordnen gezogen werden)',
    'aicc.columnSettings': 'Spalteneinstellungen',
    'aicc.selectedInfo': '{n} ausgewählt',
    'aicc.advancedSearch': 'Erweiterte Suche',
    'aicc.lengthLimit': 'Länge darf {limit} Zeichen nicht überschreiten',
    'aicc.ok': 'OK',
    'aicc.error': 'Fehler',
    'aicc.search': 'Suche',
    'aicc.reset': 'Zurücksetzen',
    'aicc.skip': 'Überspringen',
    'aicc.goto': 'Zur Kasse gehen',
    'aicc.more': 'Mehr',
    'aicc.intRangeLimit': 'Geben Sie eine Ganzzahl zwischen {min} und {max} ein',
    'aicc.MaxLength': 'Geben Sie eine Zeichenfolge mit maximal 0 Zeichen ein',
    'aicc.previous': 'Zurück',
    'aicc.next': 'Weiter',
    'aicc.noData': 'Keine Daten verfügbar',
    'aicc.lengthMinLimit': 'Muss mindestens {0} Zeichen enthalten',
    'aicc.traceId.error': 'TraceId:0. Senden Sie die Informationen zum TraceId zur Fehlerlokalisierung an das Betriebs- und Wartungspersonal.',
    'aicc.apibuilder.dev': 'Schnittstellenentwicklung',
    'aicc.tenantSpace.management': 'Tenant Management',
    'aicc.timeout.desc': 'Sitzungsablauf',
    'aicc.timeout.solution': 'Ihre Anmeldung hat das Zeitlimit überschritten. Sie müssen sich erneut anmelden.',
    'aicc.input': 'Bitte geben Sie ein',

}
