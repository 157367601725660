export default {
  "ccm.agent.label.promptParameters": "เสียงเตือน",
  "ccm.agent.label.queueParameters": "การจัดคิว",
  "ccm.agent.label.queueTransferType": "โอนไปยังประเภทออบเจ็กต์",
  "ccm.agent.label.queueTransferObj": "โอนไปยังประเภทออบเจ็กต์",
  "ccm.agent.label.busyRatePrefix": "เมื่อการใช้งานอุปกรณ์เกิน",
  "ccm.agent.label.busyRateSuffix": "๐ ไม่สามารถโอนสายไปยังคิวได้",
  "ccm.agent.label.busyJudging": "การโอนสายเมื่อไม่ว่างหรือล้น",
  "ccm.agent.label.deviceUsageThresholdPrefix": "เมื่อการใช้งานอุปกรณ์มีค่ามากกว่าหรือเท่ากับ",
  "ccm.agent.label.deviceUsageThresholdSuffix": "๏ คิวไม่ว่าง",
  "ccm.agent.label.waitTimePrefix": "เมื่อเกินระยะเวลารอคอยที่คาดการณ์ไว้",
  "ccm.agent.label.waitTimeSuffix": "๏ คิวไม่ว่าง",
  "ccm.agent.label.queuingCallsMaxNumPrefix": "เมื่อเกินจํานวนการโทรเข้าคิว",
  "ccm.agent.label.queuingCallsMaxNumSuffix": "๏ คิวล้น",
  "ccm.agent.label.scaleFactorPrefix": "เมื่อจํานวนการโทรเข้าคิวเกิน (",
  "ccm.agent.label.scaleFactorSuffix": "x จํานวนตัวแทนที่ลงชื่อ) คิวล้น",

  "ccm.transfer.number.pop.create.title": "ใหม่",
  "ccm.transfer.number.pop.modify.title": "แก้ไข",
  "ccm.transfer.number.pop.delete.title": "ลบ",
  "ccm.transfer.number.pop.delete.message": "คุณแน่ใจหรือไม่ว่าต้องการลบหมายเลขภายนอกที่เลือก",
  "ccm.transfer.number.search.number": "กรุณาใส่หมายเลข",
  "ccm.transfer.number.search.name": "กรุณาใส่ชื่อ",
  "ccm.transfer.number.label.index": "ดัชนี",
  "ccm.agent.message.addSkill": "การเพิ่มคิวทักษะ",
  "ccm.satisfactiontemplate.message.confirm":"ยืนยันว่าจะอัปเดตเนื้อหาแม่แบบตามระดับความพึงพอใจหรือไม่",
  "ccm.satisfactionconfig.msg.templateenpty": "เลือกแม่แบบ SMS",
  "ccm.satisfactionconfig.msg.timelimitenpty": "เวลาตอบกลับที่ถูกต้องเป็นค่าว่าง",
  "ccm.satisfactiontemplate.message.queryfail":"สอบถามแม่แบบแบบสํารวจความพึงพอใจไม่สําเร็จ",
  "ccm.satisfactiontemplate.tipscontent":"เทมเพลตข้อความแบบสํารวจความพึงพอใจใช้เพื่อส่งข้อความแบบสํารวจความพึงพอใจไปยังลูกค้าในช่องทาง Instagram, Telegram, WhatsApp, LINE, Web Chat, Face Book, X (Twitter), 5G RCS และ SMS",
  "ccm.satisfaction.changetips":"หมายเหตุ: มีการเปลี่ยนแปลงการกําหนดค่าระดับความพึงพอใจ ปรับเปลี่ยนแม่แบบแบบสํารวจความพึงพอใจให้สอดคล้องกัน",
  "ccm.satisfactionconfig.group.createsuccess":"เพิ่มกลุ่มนโยบายสําเร็จแล้ว",
  "ccm.satisfactionconfig.group.deletesuccess":"ลบกลุ่มนโยบายสําเร็จแล้ว",
  "ccm.satisfactionconfig.strategy.createsuccess":"ประสบความสําเร็จในการเพิ่มนโยบายความพึงพอใจช่องทาง",
  "ccm.satisfactionconfig.strategy.updateuccess":"ประสบความสําเร็จในการปรับเปลี่ยนนโยบายความพึงพอใจของช่องทาง",
  "ccm.satisfactionconfig.strategy.deletesuccess":"ประสบความสําเร็จในการลบนโยบายความพึงพอใจของช่อง",
  "ccm.satisfactionconfig.strategy.surveytyperequired":"ประเภทการสํารวจไม่สามารถเว้นว่างไว้ได้",
  "ccm.satisfactionconfig.message.question":"เทมเพลตข้อความแบบสํารวจความพึงพอใจใช้เพื่อส่งข้อความแบบสํารวจความพึงพอใจไปยังลูกค้าในช่องทาง Instagram, Telegram, WhatsApp, LINE, Web Chat, Face Book, X (Twitter), 5G RCS และ SMS",
  "ccm.satisfactionlevel.satislevel.config":"การกําหนดค่าระดับความพึงพอใจ",
  "ccm.satisfactionlevel.satislevel.button":"ปุ่มต่าง ๆ",
  "ccm.satisfactionlevel.satislevel.description":"คําอธิบาย",
  "ccm.satisfactionlevel.satislevel.operat":"การดําเนินการ",
  "ccm.satisfactionlevel.satislevel.moveup":"ขึ้นไป",
  "ccm.satisfactionlevel.satislevel.movedown":"ลงไป",
  "ccm.transfer.number.label.number": "หมายเลข",
  "ccm.transfer.number.label.name": "ชื่อ",
  "ccm.transfer.number.label.flag": "หมายเลขแฟล็กไปข้างหน้า",
  "ccm.transfer.number.label.flag.yes": "ใช่",
  "ccm.transfer.number.label.flag.no": "ไม่",
  "ccm.pageinterface.custTableConfig": "การกําหนดค่าตารางพื้นฐาน",
  "ccm.agent.button.coloumedit":"การกําหนดค่าฟิลด์",

  "iolp.configur.service.label.none.desc": "ไม่มีการกําหนดค่าประเภทบริการที่ใช้ได้ สร้างหมวดหมู่บริการก่อน",
  "ccm.transtask.label.friday": "วันศุกร์",
  "ccm.agent.contact.preview": "ดูตัวอย่าง",
  "ccm.agent.label.waitVoiceSelect": "โปรดเลือกเสียงรอสาย",
  "ccm.agent.label.noAgents": "การประมวลผลการโทรบน Nobody On-Duty",
  "ccm.custdata.operlog.refresh": "รีเฟรช",
  "ccm.callreason.case.check.error": "ชนิดคําสั่ง {0} ไม่มีอยู่.",
  "ccm.agent.label.waitTimeRange": "ช่วงค่า: [0-9999]",
  "ccm.agent.message.weight.limit": "[1-100]",
  "ccm.agent.message.agentweight.limit": "[1-300]",
  "ccm.agent.contact.isExistTalkReason": "ตั้งค่าเหตุผลการโทรหรือไม่",
  "ccm.agent.contact.QUERU_SFTP_FAIL_CODE": "ข้อมูล SFTP ว่างเปล่า",
  "ccm.note.config.notename": "ชื่อ",
  "ccm.certificate.management.update.certfile": "อัปเดตไฟล์ใบรับรอง",
  "ccm.certificate.management.add.title": "การเพิ่มใบรับรอง",
  "ccm.certificate.management.update.title": "กําลังอัปเดตใบรับรอง",
  "ccm.transtask.label.reportData": "รายงานข้อมูล",
  "ccm.agent.sysparamconfig.emailMaxRecipientsNoCheck": "จํานวนผู้รับสูงสุดที่ส่งต่อโดยตัวแทนไปรษณีย์จะต้องอยู่ระหว่าง 1 ถึง 50",
  "ccm.urlconfig.label.oauth.callback.alerttip": "ส่ง URL ของการโทรเพื่อสร้างอินเทอร์เฟซแบบคู่ขนาน หากกําหนดค่าข้อมูลต่อไปนี้ จะใช้บอท มิเช่นนั้นจะไม่เกิดขึ้นเมื่อเกิดการย้อนกลับไป",
  "ccm.certificate.management.certificate.crlfile.certhasexpired": "ใบรับรองหมดอายุแล้ว",
  "ccm.ivr.placeholder.ivrdesc": "กรุณาป้อนคำอธิบายกระบวนการ",
  "ccm.calledconfig.message.nonumbers": "กรุณาเลือกตัวเลข",
  "ccm.callout.lable.default": "ค่าเริ่มต้น",
  "ccm.verifyidentity.fail.USERIDPARSEERROR": "หมายเลขยืนยันไม่สามารถมีอักขระพิเศษได้",
  "ccm.pageinterface.saveSuccess": "ชุดหน้าอินเทอร์เฟซถูกบันทึกเรียบร้อยแล้ว.",
  "ccm.speciallist.msg.selectonly": "สามารถเลือกรายการพิเศษได้เพียงรายการเดียวสําหรับการแก้ไข",
  "ccm.custdata.button.exportTemplate": "ส่งออกเทมเพลต",
  "ccm.chatbot.query.list.fail": "ล้มเหลวในการรับรายการหุ่นยนต์",
  "ccm.ivr.button.traceInfo": "คลิกเพื่อดูบันทึกการติดตามปัจจุบัน",
  "ccm.verifyidentity.callId": "หมายเลขการโทร",
  "ccm.verifyidentity.verifyName": "ยืนยันชื่อ",
  "ccm.contactitemconfig.label.data.item.identification": "ID รายการข้อมูล",
  "ccm.chatbot.query.name.fail": "ล้มเหลวในการรับชื่อบอท",
  "ccm.veridifyentity.NOTRETURN": "ไม่ส่งคืน",
  "ccm.agent.message.pageLayoutFail": "ไม่ได้เลือกเค้าโครงหน้าหรือจำนวนแถว",
  "ccm.speciallist.msg.suffix": "ส่วนต่อท้ายการนําเข้าชื่อแฟ้มไม่ถูกต้อง",
  "ccm.agent.leavemessage.downloadLeaveMessage": "ดาวน์โหลดข้อความ",
  "ccm.custdata.operlog.exportEndTime": "ส่งออกเวลาสิ้นสุด",
  "ccm.agent.restrecord.rest.currentstatus": "สถานะปัจจุบัน",
  "ccm.agent.ccagentinfo.unregister.success": "ล็อกเอาต์สําเร็จ",
  "ccm.speciallist.msg.tip": "สามารถนําเข้าข้อมูลได้สูงสุด 1000 รายการต่อครั้ง",
  "ccm.agent.message.createAgentAndSkillFail": "สร้างการเชื่อมโยงของลำดับทักษะเจ้าหน้าที่ไม่สำเร็จ",
  "ccm.recognition.label.openBreakDown": "เสียงแยกย่อย",
  "ccm.contact.detail.receiver": "ผู้รับ",
  "ccm.agent.button.close": "ปิด",
  "ccm.certificate.management.type": "ประเภทใบรับรอง",
  "ccm.speciallist.msg.confirmdeletehis": "คุณแน่ใจว่าต้องการลบประวัติรายการพิเศษที่เลือกหรือไม่",
  "ccm.sysparam.success.save": "ความสําเร็จ",
  "ccm.satisfactionconfig.message.accepttime": "เวลาในการจัดการ",
  "ccm.agent.label.callDispatchModel.incomingCall": "การจัดสรรทักษะการโทรเข้าก่อนการจัดสรรทักษะการโทรออก",
  "ccm.recognition.label.breakDownVoice": "เสียงแยกย่อย",
  "ccm.custdata.label.status": "สถานะ",
  "ccm.recognition.label.openIvrAgentSuccess": "เปิดใช้งานผู้ช่วยเจ้าหน้าที่อัจฉริยะสำเร็จแล้ว",
  "ccm.calledroute.title.selectskill": "เลือกลำดับทักษะ",
  "ccmanagement.satisfaction.strategy.accepttime.accepttime": "เวลาจัดการ",
  "ccm.transtask.label.dataname": "ชื่อเซิร์ฟเวอร์การถ่ายโอนข้อมูล",
  "ccm.agent.message.longCharacters": "จำนวนเลข สามารถมีอักขระได้สูงสุด 24 ตัว",
  "ccm.agent.message.longCharacters.form": "จำนวนเลข สามารถมีอักขระได้สูงสุด 24 ตัว",
  "ccm.speciallist.msg.partexport": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกเร็กคอร์ดที่เลือก",
  "ccm.veridentity.verifyName": "โหมดการตรวจสอบความถูกต้อง",
  "ccm.sysparam.config.itemname.tips": "ป้อนชื่อพารามิเตอร์",
  "ccm.accesscode.label.accesscodedesc": "คำอธิบายรหัสการเข้าถึง",
  "ccm.accesscode.title.accesscodedesc": "แก้ไขคําอธิบายรหัสการเข้าถึง",
  "ccm.satisfactionconfig.message.chooseivrflow": "เลือก IVR flow",
  "ccm.custdata.label.endTime": "เวลาสิ้นสุด",
  "ccm.agent.calledroute.success": "สำเร็จ",
  "ccm.botherlist.label.approveComment.toApprove": "เห็นด้วย",
  "ccm.agent.contact.assistantCallid": "ID รวมสาย",
  "ccm.veridifyentity.noMoreThan30Days": "ระยะเวลาต้องไม่เกิน 30 วัน",
  "ccm.contact.customer.config.referenced": "รายการข้อมูลได้รับการอ้างอิงจากพื้นที่ข้อมูลลูกค้าที่ติดต่อ.",
  "ccm.contact.record.data.referenced": "รายการข้อมูลถูกอ้างอิงโดยรายการข้อมูลบันทึกรายชื่อผู้ติดต่อ.",
  "ccm.agent.label.description": "คำอธิบาย",
  "ccm.agent.label.endworkno": "สิ้นสุด ID พนักงาน",
  "ccm.contactitemconfig.label.data.extended.dataset.source.interface": "อินเตอร์เฟซแหล่งที่มาของชุดข้อมูล",
  "ccm.transtask.label.day": "เดย์",
  "ccm.agent.message.updateCtiAndDbFail": "อัปเดต cti และ Db ล้มเหลว",
  "ccm.transserver.label.obsnotinwhite": "การดําเนินการล้มเหลว โปรดติดต่อผู้ดูแลระบบเพื่อเพิ่มที่อยู่บริการ OBS ลงในรายการที่เชื่อถือได้ของเซิร์ฟเวอร์ OBS ที่ถ่ายโอนทรัพยากร",
  "ccm.agent.recall.param.callid.empty": "ไม่มีหมายเลขโทรกลับ",
  "ccm.agent.calledroute.placeholder.accesscode": "กรุณาเลือกรหัสการเข้าถึง",
  "ccm.label.contactexport.customfield": "สนามที่กําหนดเอง",
  "ccm.systemparam.success.refresh": "อัปเดตพารามิเตอร์สําเร็จ",
  "ccm.speciallist.msg.errorreason": "ความล้มเหลว สาเหตุ",
  "ccm.contactitemconfig.label.data.item.sequence.number": "หมายเลข",
  "ccm.agent.pageurls.accessaddr": "ที่อยู่ในการเข้าถึง",
  "ccm.recognition.label.orderfailpd": "การสมัครสมาชิกล้มเหลว กรุณาลองอีกครั้งในภายหลัง",
  "ccm.custdata.operlog.confirmMessage": "คุณแน่ใจหรือไม่ว่าจะสร้างงานส่งออกใหม่",
  "ccm.verifyidentity.NOTRETURN": "ไม่ส่งคืน",
  "ccm.urlconfig.label.xNumber": "ป้อนหมายเลข X",
  "ccm.satisfactionconfig.message.cancel": "ยกเลิก",
  "ccm.agent.calledroute.editsuccess": "แก้ไขสำเร็จ.",
  "ccm.speciallist.msg.transfererror": "ไม่สามารถถ่ายโอนไปยังประวัติได้",
  "ccm.agent.leavemessage.status.unprocessed": "ยังไม่ได้ดำเนินการ",
  "ccm.satisfactionconfig.message.comfirm": "คุณแน่ใจว่าต้องการลบกลุ่มนโยบายหรือไม่",
  "ccm.agent.contact.audio-visual": "เสียง / วิดีโอ",
  "ccm.agent.skill.skillExist": "มีอยู่แล้ว. ป้อนชื่ออื่น",
  "ccm.transtask.label.taskfail": "สร้างงานไม่สําเร็จ เนื่องจากอนุญาตให้มีงานประเภทเร็กคอร์ดของผู้ติดต่อได้สูงสุด {0} งาน",
  "ccm.ivr.label.processid": "รหัสกระบวนการ",
  "ccm.agent.label.parameter": "พารามิเตอร์ 1",
  "ccm.agent.title.agentEdit": "การกำหนดค่าข้อมูลเจ้าหน้าที่",
  "ccm.speciallist.msg.modifyerror": "การแก้ไขล้มเหลว",
  "ccm.label.contactexport.selectdatafield": "ส่งออกสนาม",
  "ccm.agent.contact.preCallout": "ดูตัวอย่างสายโทรออก",
  "ccm.agent.pageurls.pagetype": "ประเภทหน้าจอ",
  "ccm.agent.calledroute.create.accesscode.none": "ไม่มีการจัดสรรรหัสการเข้าถึงระบบที่กำหนดค่าสำหรับบุคคลที่ถูกเรียก",
  "ccm.transtask.label.vdnReport": "รายงานประสิทธิภาพ VDN",
  "ccm.basicdata.msg.nodata": "ไม่มีข้อมูล",
  "ccm.agent.tips.inputpassword": "ป้อนรหัสผ่านของบัญชีลงชื่อเข้าใช้ปัจจุบัน",
  "ccm.contact.customer.config.writeBackFieldError": "ไม่สามารถใช้งานฟิลด์การเขียนข้อมูลเร็กคอร์ดผู้ติดต่อแบบขยายได้.",
  "ccm.contact.customer.config.dataItemCodeError": "ID รายการข้อมูลไม่ถูกต้อง.",
  "ccm.agent.Transfer.theAgent": "ตัวแทน",
  "ccm.agent.restReason.restCauseTime.formate": "ระยะเวลาต้องอยู่ในรูปแบบ HH: MM: SS",
  "ccm.transtask.label.updateexsit": "ปฏิบัติการล้มเหลว ชื่อเซิร์ฟเวอร์มีอยู่แล้ว",
  "ccm.speciallist.msg.endtimeerror": "เวลาหมดอายุต้องช้ากว่าเวลาปัจจุบัน",
  "ccm.custdata.button.moveDown": "ลง",
  "ccm.custdata.option.isMaskDisplay.yes": "ใช่",
  "ccm.contactitemconfig.label.data.extended.dictionary.value": "ค่าพจนานุกรมข้อมูล",
  "ccm.verifyidentity.fail.WORKNOPARSEERROR": "เลขที่งานต้องเป็นตัวเลข",
  "ccm.transtask.label.monday": "วันจันทร์",
  "ccm.satisfactionconfig.message.validatename": "ชื่อกลุ่มนโยบายไม่สามารถเว้นว่างหรือซ้ํากันได้",
  "ccm.agent.ten.videoIvrchannels": "ปริมาณช่องวิดีโอ IVR",
  "ccm.satisfactionconfig.message.calltime": "ระยะเวลาการโทร",
  "ccm.calledconfig.message.savemobileagentsuccess": "บันทึกการตั้งค่ามือถือเจ้าหน้าที่เรียบร้อยแล้ว",
  "ccm.custdata.msg.updatedItemNotExist": "ไม่มีพื้นที่จะอัพเดต รีเฟรชหน้านี้แล้วลองอีกครั้ง",
  "ccm.agent.contact.callin": "สายเรียกเข้า",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.no": "ไม่",
  "ccm.agent.contact.vidio": "วิดีโอ",
  "ccm.satisfactionconfig.message.failed": "ล้มเหลว",
  "ccm.agent.contact.callid": "ID ผู้โทร",
  "ccm.transtask.label.skillAccessCodeReport": "Skill Queue รายงานการจราจรด้วยรหัสการเข้าถึง",
  "ccm.agent.contact.contactdetail": "รายละเอียดการติดต่อ",
  "ccm.custdata.operlog.exportTaskStatus": "สถานะ",
  "ccm.verifyidentity.ERROR": "ผิดพลาด",
  "ccm.agent.label.positiveEmotionNumber": "เชิงบวก",
  "ccm.speciallist.msg.view": "ดูทั้งหมด",
  "ccm.satisfactionconfig.message.smscloud": "ข้อความคลาวด์ของหัวเว่ย",
  "ccm.agent.label.waitVoiceCustom": "ปรับแต่งเสียงรอสาย",
  "ccm.agent.button.edit": "แก้ไข",
  "ccm.agent.accesscode.description.limit": "คำอธิบายรหัสการเข้าถึงต้องมีอักขระไม่เกิน 50 ตัว",
  "ccm.agent.calledroute.isconfdelete": "แน่ใจไหมว่าต้องการลบคู่สายที่ถูกเลือก?",
  "ccm.custdata.operlog.exportBeginTime": "เวลาเริ่มต้นการส่งออก",
  "ccm.custdata.option.maskStyle.retainLast4": "สำรองสี่หลักสุดท้าย",
  "ccm.custdata.label.tableName": "ชื่อตาราง",
  "ccm.custdata.title.edit": "แก้ไขข้อมูล",
  "ccm.custdata.title.coloum.add": "การสร้างคอลัมน์ฟิลด์",
  "ccm.custdata.title.coloum.edit": "แก้ไขคอลัมน์ฟิลด์",
  "ccm.custdata.title.coloum.detail": "การดูรายละเอียดฟิลด์",
  "ccm.agent.pageurls.updateinfo": "อัปเดตเรียบร้อยแล้ว.",
  "ccm.agent.moveup.error": "เลื่อนขึ้นล้มเหลว.",
  "ccm.agent.moveup.success": "เลื่อนขึ้นสําเร็จแล้ว.",
  "ccm.agent.movedown.error": "การย้ายลงล้มเหลว.",
  "ccm.agent.movedown.success": "ย้ายลงสําเร็จแล้ว.",
  "ccm.urlconfig.label.input.appKey": "AppKey",
  "ccm.satisfactionconfig.message.numberlimit": "สามารถกําหนดค่าช่องแบบสํารวจความพึงพอใจได้สูงสุด 100 ช่องในพื้นที่ของผู้เช่า",
  "ccm.contactitemconfig.label.data.extended.Type": "ประเภทข้อมูล",
  "ccm.pageinterface.interfaceType.externalExplain": "อินเทอร์เฟซภายนอกได้รับการกำหนดค่าบนหน้าการจัดการ API ติดต่อผู้ดูแลระบบ",
  "ccm.speciallist.button.modify": "ปรับเปลี่ยน",
  "ccm.levelMessage.title.modify": "ปรับเปลี่ยนการจัดการระดับ",
  "ccm.speciallist.title.modify": "ปรับเปลี่ยนรายการพิเศษ",
  "ccm.speciallistreminder.button.modify": "แก้ไขการแจ้งเตือนรายการพิเศษ",
  "ccm.agent.contact.multimedia.web": "มัลติมีเดีย-WEB",
  "ccm.pageurls.select.embedding": "แบบฝัง",
  "ccm.agent.contact.talkcontent": "เนื้อหาการสนทนา",
  "ccm.agent.callreason.modify.failed": "ล้มเหลวในการแก้ไขเหตุผลการโทร",
  "ccm.agent.callreason.delete.confirm": "คุณแน่ใจหรือไม่ว่าต้องการลบเหตุผลการโทร",
  "ccm.speciallist.msg.reviewerror": "การอนุมัติล้มเหลว",
  "ccm.speciallist.msg.sltype.illegal": "ประเภทรายการพิเศษที่ผิดกฎหมาย",
  "ccm.agent.contact.play": "เล่น",
  "ccm.custdata.msg.selectItem": "เลือกรายการก่อนจัดเรียง",
  "ccm.speciallevel.msg.deleteyerror": "ไม่สามารถลบระดับรายการพิเศษได้",
  "ccm.speciallist.button.finish": "สมบูรณ์",
  "ccm.agent.message.allExport": "ส่งออกทั้งหมด",
  "ccm.agent.updateSoftPhone.modifyLocalPwd": "เปลี่ยนรหัสผ่านซอฟต์โฟนในเครื่องไม่สำเร็จ",
  "ccm.custdata.msg.dictionaryValidateFailed": "รูปแบบของช่วงค่าพจนานุกรมข้อมูลไม่ถูกต้อง",
  "ccm.contactitemconfig.label.data.item.basic": "ข้อมูลพื้นฐาน",
  "ccm.contract.management.invalid.confirm": "คุณแน่ใจหรือไม่ว่าต้องการทําให้เอกสารนี้เป็นโมฆะ?",
  "ccm.agent.message.deleteSkillAndAgentFail": "ลบทักษะและเจ้าหน้าที่ ล้มเหลว",
  "ccm.create.case.confirm": "ประเภทคดีว่างเปล่า เมื่อสร้างเคส ต้องเลือกประเภทเคสด้วยตนเอง คุณแน่ใจหรือว่าต้องการดําเนินการต่อ?",
  "ccm.agent.label.audio": "เจ้าหน้าที่เสียง",
  "ccm.agent.label.on": "เปิด",
  "ccm.agent.login.ID": "ลำดับการทำงาน",
  "ccm.satisfactionconfig.web.satisfaction.survey": "แบบสํารวจความพึงพอใจของช่องทางเว็บ",
  "ccm.agent.calledroute.require": "ป้อนฟิลด์บังคับทั้งหมด",
  "ccm.agent.label.afterVoiceSelect": "หลังจากแจ้ง ID เจ้าหน้าที่แล้ว",
  "ccm.agent.message.delete": "ลบ",
  "ccm.agent.message.pageInterface": "หน้าอินเทอร์เฟซ",
  "ccm.recognition.resetSK.failed": "รีเซ็ต SK ไม่สำเร็จ",
  "ccm.agent.contact.endtime": "เวลาสิ้นสุด",
  "ccm.agent.ten.anonymousSipInfo": "ที่อยู่ IP และหมายเลข Port ของเซิร์ฟเวอร์โทรที่ไม่ระบุชื่อ",
  "ccm.custdata.msg.duplicateTableName": "ชื่อตารางซ้ำกัน เปลี่ยนชื่อ",
  "ccm.calledconfig.message.callreasontips": "หมายเหตุ: ไม่สามารถใช้งานคําสั่งการเชื่อมโยงระดับปัจจุบันได้ โปรดเลือกชุดย่อยเพื่อตั้งค่าคําสั่งที่เชื่อมโยงกัน",
  "ccm.agent.pageurls.configpage": "หน้ากำหนดค่า",
  "ccm.agent.tips.confFail": "การกำหนดค่าล้มเหลว",
  "ccm.agent.contact.called": "เบอร์ปลายทาง",
  "ccm.speciallist.operlog.downloadData": "ส่งออกข้อมูลรายการพิเศษ",
  "ccm.systemparam.error.noparamselected": "โปรดเลือกพารามิเตอร์ก่อน",
  "ccm.satisfactionconfig.message.webchannel": "ช่องมัลติมีเดียบนเว็บ",
  "ccm.agent.leavemessage.auditdate": "เวลาอัปเดต",
  "ccm.agent.message.videoagentzero": "จำนวนเจ้าหน้าที่วิดีโอคือ 0 ไม่สามารถกำหนดค่าลำดับทักษะการคลิกเพื่อหมุนวิดีโอได้",
  "ccmanagement.satisfaction.strategy.accepttime.timeEnd": "เวลาสิ้นสุด",
  "ccm.calledconfig.table.devtype": "ประเภทอุปกรณ์วัตถุประสงค์",
  "ccm.agent.label.deviceUsageThresholdRange": "ช่วงค่า: [0-100]",
  "ccm.agent.message.cancelconfig": "ยกเลิกการตั้งค่า",
  "ccm.agent.contact.preemptionCallout": "การโทรขาออกล่วงหน้า",
  "ccm.urlconfig.message.urlnotinwhite": "การดําเนินการล้มเหลว โปรดติดต่อผู้ดูแลระบบเพื่อเพิ่ม URL ไปยังรายการที่เชื่อถือได้",
  "ccm.agent.title.querysippwd": "ตรวจสอบรหัสผ่าน Softphone",
  "ccm.policy.label.updateVerificationFailed": "ไม่สามารถตรวจสอบพารามิเตอร์ที่ร้องขอได้",
  "ccm.agent.label.scaleFactorRangeTwo": "ช่วงค่า: [1-10000]",
  "ccm.custdata.operlog.zippwd": "รหัสผ่านที่บีบอัด",
  "ccm.dual.call.record.talkingTime": "เวลารับสายของฝ่ายที่ถูกเรียก",
  "ccm.note.config.agent.ring.setUp": "ปรับแต่งข้อความแจ้งเตือน",
  "ccm.dual.call.record.dualCallType": "ประเภทการโทรแบบสองทิศทาง",
  "ccm.agent.leavemessage.processsuccess": "ประมวลผลข้อความเรียบร้อยแล้ว",
  "ccm.agent.contact.caller": "เบอร์ต้นทาง",
  "ccm.agent.calledroute.delete.skill.default": "การกำหนดค่าปาร์ตี้ที่เรียกว่าถูกอ้างอิงโดยลำดับทักษะส่วนตัวเริ่มต้นและไม่สามารถลบได้",
  "ccm.agent.label.transferType": "วิธีการดำเนินการ",
  "ccm.agent.restrecord.reststatus": "สถานะพัก",
  "ccm.contact.detail.view.message.content": "ดูเนื้อหาข้อความ",
  "ccm.agent.message.updateagentandskillfail": "อัปเดตการเชื่อมโยงระหว่างเจ้าหน้าที่และลำดับทักษะไม่สำเร็จ",
  "ccm.agent.pageurls.delfail": "การลบล้มเหลว.",
  "ccm.agent.resetskillrecord.adjusttime": "ปรับเวลา",
  "ccm.contact.customer.config.value": "มูลค่าข้อมูล",
  "ccm.satisfactionconfig.message.comfirmtitle": "Confirm",
  "ccm.agent.operation.CONFERENCE": "การประชุมสาย",
  "ccm.calledroute.button.cancel": "ยกเลิก",
  "ccm.urlconfig.label.input.domainName": "ชื่อโดเมน ITA",
  "ccm.agent.label.transfer": "โอน",
  "ccm.taskrecord.message.reexecutetranstaskerror": "การประมวลผลอีกครั้งล้มเหลว",
  "ccm.agent.message.allExportAgent": "แน่ใจไหมว่าต้องการส่งออกข้อมูลเจ้าหน้าที่ทั้งหมด",
  "ccm.custdata.operlog.inputzippwd": "ป้อนรหัสผ่านการบีบอัด",
  "ccm.satisfactionlevel.message.samedesc": "มีคําอธิบายซ้ํากันอยู่กรุณาแก้ไข",
  "ccm.ucconfig.message.clientSecret": "รหัสผ่านแอปพลิเคชัน (ไคลเอ็นต์)",
  "ccm.agent.operation.countid": "เลขที่บัญชีผู้ใช้",
  "ccm.note.config.agent.page.ring.select": "เลือกเสียงเรียกเข้า",
  "ccm.agent.restReason.updateFailed": "การแก้ไขล้มเหลว.",
  "ccm.agent.label.qualityChecker": "ตัวตรวจสอบคุณภาพ",
  "ccm.agent.contact.playRecording": "เล่นการบันทึก",
  "ccm.agent.label.callDispatchModel.manySkills": "การจัดสรรทักษะพิเศษที่มีทักษะมากขึ้น",
  "ccm.agent.contact.downloadFail": "การดาวน์โหลดล้มเหลว",
  "ccm.custdata.msg.sortNoChange": "การเรียงลำดับไม่เปลี่ยนแปลง",
  "ccm.policy.label.updateFailed": "ไม่สามารถอัปเดตข้อมูลกรมธรรม์ได้",
  "casemanagement.user.button.ok": "ตกลง",
  "ccm.agent.button.search": "ค้นหา",
  "ccm.agent.label.serviceAccountName": "ชื่อบัญชีบริการคลาวด์",
  "ccm.contactitemconfig.label.contact.extended.information.dataset": "ชุดข้อมูลของข้อมูลการติดต่อแบบขยาย",
  "ccm.agent.export.agentinfo.tips": "ข้อมูลที่ส่งออกมีข้อมูลส่วนบุคคลซึ่งต้องใช้และป้องกันอย่างถูกต้อง",
  "ccm.speciallist.msg.userNum": "หมายเลขผู้ใช้",
  "ccm.transserver.delete.css.success": "ลบการกําหนดค่า CSS เรียบร้อยแล้ว",
  "ccm.agent.contact.calledfailed": "การเรียกขาออกล้มเหลว",
  "ccm.agent.updateSoftPhone.updateOpertionSuccess": "คำเตือน",
  "ccm.idauth.title.edit": "การแก้ไขกระบวนการรับรองความถูกต้อง",
  "ccm.urlconfig.message.deletesuccess": "รีเซ็ต URL สำเร็จ",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnumber": "ค่าต้องเป็นจํานวนเต็มบวก",
  "ccm.ivr.label.processaccesscode": "รหัสขั้นตอน",
  "ccm.agent.pageurlparam.channelassociateddata": "ช่องข้อมูลที่เกี่ยวข้อง",
  "ccm.veridifyentity.callId": "โทร SN",
  "ccm.satisfactionconfig.message.switchtip": "หลังจากเปิดใช้งานคุณลักษณะนี้ ระบบจะแสดงหน้าแบบสํารวจความพึงพอใจโดยอัตโนมัติหลังจากสิ้นสุดการให้บริการช่องทางเว็บ หลังจากการให้บริการช่องทางสื่ออื่นๆ เสร็จสิ้นแล้ว ระบบจะผลักดันเนื้อหาตามรูปแบบความพึงพอใจโดยอัตโนมัติ",
  "ccm.satisfactionconfig.message.timelimittip": "หากเวลาการตอบกลับจากลูกค้าเกินเวลาการตอบกลับที่ถูกต้อง การตอบกลับจะไม่ถูกต้องอีกต่อไป",
  "ccm.agent.operation.AllOpType": "ประเภทการทำงานทั้งหมด",
  "ccm.agent.Transfer.tranfermode": "โหมดถ่ายโอน",
  "ccm.calledconfig.table.selectnumbers": "เลือกหมายเลข",
  "ccm.agent.protocol.check": "โปรดทราบว่าโปรโตคอลเครือข่ายที่ใช้โดยที่อยู่ไม่ใช่โปรโตคอลที่ปลอดภัย หากคุณตัดสินใจใช้โปรโตคอลนี้ ความเสี่ยงด้านความปลอดภัยอาจมีอยู",
  "ccm.common.label.success": "ความสําเร็จ",
  "ccm.certificate.management.certificatefile.notupload": "กรุณาอัปโหลดไฟล์ใบรับรอง",
  "ccm.speciallist.msg.selectspeciallist": "วโปรดเลือกรายการพิเศษ",
  "ccm.speciallist.msg.selectspeciallisthis": "โปรดเลือกประวัติรายการพิเศษ",
  "ccm.agent.ten.companyName": "บริษัท",
  "ccm.agent.label.calcOfMaxCallNum": "วิธีการคํานวณข้อมูลคิวการโทรสูงสุด",
  "ccm.speciallevel.speciallist.level": "ระดับ",
  "ccm.agent.label.serverAddress": "ที่อยู่เซิร์ฟเวอร์การลงทะเบียน",
  "ccm.verifyidentity.fail.CALLIDPARSEERROR": "รหัสการโทรต้องเป็นตัวเลข",
  "ccm.agent.pageurls.openMode": "วิธีการเปิด",
  "ccm.agent.operation.noMoreThan1Days": "ช่วงเวลาต้องไม่เกิน 1 วัน",
  "ccm.pageinterface.selectedAll": "เลือกทั้งหมด",
  "ccm.calledconfig.label.selectcalled": "เลือกโทร",
  "ccm.calledconfig.message.deletemobileagentsuccess": "ลบการตั้งค่ามือถือเจ้าหน้าที่สำเร็จแล้ว",
  "ccm.note.config.agent.page.ring.warn": "ขอแนะนําให้ทําการทดสอบทางไฟฟ้าเพื่อป้องกันการทับซ้อนของเสียงริงโทนที่กําหนดค่าไว้บนโทรศัพท์",
  "ccmanagement.satisfaction.strategy.addsuccess": "การออมที่ประสบความสําเร็จ",
  "ccm.transtask.label.vdnAccessCodeReport": "รายงานการจราจร VDN ตามรหัสการเข้าถึง",
  "ccm.calledroute.title.selectaccesscode": "เลือกรหัสการเข้าถึง",
  "ccm.calledroute.tip.selectaccesscode": "โปรดเลือกรหัสการเข้าถึงก่อน",
  "ccm.botherlist.label.contactrecorddetail": "รายละเอียดการติดต่อ",
  "ccm.transserver.delete.fail": "ไม่สามารถลบเซิร์ฟเวอร์ดัมพ์ได้",
  "ccm.message.center.nocitiction.sending.records.status": "ส่งสถานะ",
  "ccm.agent.updateSoftPhone.queryNodeFailed": "ไม่สามารถค้นหาข้อมูลโหนด",
  "ccm.agent.label.startworkno": "เริ่ม ID พนักงาน",
  "ccm.agent.message.pageConfSuccess": "การดำเนินการสำเร็จ.",
  "ccm.dual.call.record.caller": "หมายเลขผู้โทร",
  "ccm.agent.password.wrong": "ไม่สามารถยืนยันรหัสผ่าน",
  "ccm.satisfactionconfig.message.validtime": "ระยะเวลาการตอบกลับที่ถูกต้อง",
  "ccm.agent.label.negativeEmotionNumber": "เชิงลบ",
  "ccm.custdata.option.isMandatory.no": "ไม่ใช่",
  "ccm.certificate.management.updatetime": "เวลาอัปเดต",
  "ccm.agent.calledroute.ivr": "กรุณาเลือก IVR",
  "ccm.speciallist.button.add": "เพิ่ม",
  "ccm.levelMessage.title.add": "สร้างการจัดการระดับ",
  "ccm.speciallist.title.add": "สร้างรายการพิเศษ",
  "ccm.speciallistreminder.button.add": "สร้างการแจ้งเตือนรายการพิเศษ",
  "title.contact.agent.name": "ชื่อบัญชีบริการ",
  "ccm.agent.label.cti": "cti:",
  "ccm.agent.videoDownload.RSETimeout": "การร้องขอบริการ RSE หมดเวลา",
  "ccm.agent.title.IVRTrace": "การติดตาม IVR",
  "ccm.custdata.option.columnType.string": "ประเภทตัวอักษร",
  "ccm.speciallist.msg.crateerrorreason": "หมายเลขสมาชิกมีอยู่แล้ว",
  "ccm.agent.contact.multimedia.instagram": "มัลติมีเดีย-Instagram",
  "ccm.agent.message.agentConfSuccess": "กำหนดค่าข้อมูลเจ้าหน้าที่เรียบร้อยแล้ว",
  "ccm.speciallevel.tip.stop": "คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งาน",
  "ccm.agent.message.agentnumreachmax": "จำนวนประเภทเจ้าหน้าที่ ถึงค่าสูงสุด",
  "ccm.agent.label.skillChoice": "เลือกทักษะ",
  "ccm.agent.operation.ANSWER": "รับสาย",
  "ccm.speciallist.msg.overlimit": "ความยาวของข้อความเตือนความจําเกิน 256 ตัวอักษร",
  "ccm.transtask.label.abandonedRingReport": "รายงานสรุปเกี่ยวกับสายที่ถูกทิ้งระหว่างเสียงกริ่ง",
  "ccm.contact.detail.message.sending.record": "บันทึกการส่งข้อความ",
  "ccm.chatbot.invalid.empty": "ผู้ช่วยหุ่นยนต์ต้องไม่ว่าง",
  "ccm.idauth.title.code": "การเข้ารหัสวิธีการตรวจสอบความถูกต้อง",
  "ccm.custdata.msg.reachUpperTableNum": "จำนวนตารางที่กำหนดเองถึงขีด จำกัด สูงสุด",
  "ccm.agent.page.cancel.release": "ยังไม่ได้เผยแพร่",
  "ccm.recognition.label.cancelorderfail": "การยกเลิกการสมัครล้มเหลว",
  "ccm.agent.calledroute.extcode": "รหัสเพิ่มเติม",
  "ccm.agent.pageurls.maxcallingnum": ". ตรวจสอบให้แน่ใจว่าจำนวนหน้าจอป๊อปอัปไม่เกิน 5",
  "ccm.sysparam.error.validate.notmatchruleoption": "พารามิเตอร์อินพุตไม่อยู่ในช่วงการแจงนับ",
  "ccm.agent.calledroute.device": "เลือกอุปกรณ์",
  "ccm.agent.operation.WHISPER": "การกระซิบ",
  "ccm.agent.contact.predictiveCall": "การโทรขาออกที่คาดการณ์ไว้",
  "ccm.taskrecord.label.reexecute": "ประมวลผลอีกครั้ง",
  "ccm.agent.contact.others-channels": "ช่องอื่น ๆ",
  "ccm.agent.contact.skillhungup": "โอนไปยังลำดับทักษะ",
  "ccm.callout.lable.caller": "หมายเลขที่โทรออก",
  "ccm.agent.sysparamconfig.positiveinteger": "กรุณาใส่จำนวนเต็มบวก!",
  "ccm.certificate.management.scenename": "รายละเอียดของใบรับรอง",
  "ccm.agent.restrecord.reststatus.Abnormal.endrest": "การพักสิ้นสุดอย่างผิดปกติ",
  "ccm.taskrecord.label.choosetask": "การเลือกงาน",
  "ccm.speciallist.msg.restores": "เรียกคืน",
  "ccm.agent.calledroute.devicedesc": "ชื่ออุปกรณ์",
  "ccm.agent.recall.fail": "ล้มเหลวในการโทรกลับลูกค้า",
  "ccm.transtask.label.isOpenCssServer": "CSS ระบุว่าจะเปิดใช้งาน CSS หรือไม่",
  "ccm.agent.mobileagent.label.deletehint": "แน่ใจหรือว่าจะลบ",
  "ccm.agent.label.isaireconition": "จริง",
  "ccm.calledconfig.message.nullparent": "ไม่มีโหนดแม่อยู่",
  "ccm.speciallist.msg.applyTimeTo": "เวลาสิ้นสุดแอปพลิเคชัน",
  "ccm.ucconfig.message.updatesuccess": "บันทึกการกําหนดค่าการรวม UC เรียบร้อยแล้ว",
  "ccm.agent.restReason.isusing.warning": "รหัสเหตุผลในการพักมีผลใช้งานอยู่ ซึ่งอาจทำให้แสดงเหตุผลในการพักไม่ถูกต้องในบันทึกการพัก",
  "ccm.agent.contact.callerPresent": "หมายเลขที่แสดงของผู้ใช้",
  "ccm.custdata.label.maskStyle": "รูปแบบรูปแบบ",
  "ccm.verifyentity.verifyTime": "เวลาตรวจสอบความถูกต้อง",
  "ccm.agent.button.restSoftPhonePwdError": "ผูกเจ้าหน้าที่",
  "ccm.speciallist.msg.transferToHistory": "โอนไปยังประวัติ",
  "ccm.dual.call.record.normal.end": "สิ้นสุดปกติ",
  "ccm.note.config.agent.page.ring.media": "แจ้งเตือนมัลติมีเดีย",
  "ccm.speciallist.msg.deleteerror": "ไม่สามารถลบการแจ้งเตือนรายการพิเศษ",
  "ccm.agent.restReason.restCauseDesc.limit": "คำอธิบายเหตุผลที่เหลือสามารถมีอักขระได้สูงสุด 100 ตัว",
  "ccm.agent.restReason.restCauseDesc.limit.form": "คําอธิบายเหตุผลที่เหลือสามารถมีอักขระได้สูงสุด 100 ตัว",
  "ccm.agent.pageurls.successinfo": "สร้างสำเร็จ.",
  "ccm.agent.pageurls.successinfo.noPoint": "สร้างสำเร็จ",
  "ccm.agent.ten.versatileagents": "เอเจนต์อเนกประสงค์",
  "ccm.satisfactionconfig.message.calltypein": "สายเรียกเข้า",
  "ccm.contact.customer.config.type": "ติดต่อข้อมูลลูกค้า ข้อมูลพื้นที่",
  "ccm.transtask.label.voiceFile": "ไฟล์เสียง",
  "ccm.anonymous.message.fail.paranull": "รหัสเข้าถึงในพารามิเตอร์ว่างเปล่า",
  "ccm.certificate.management.certificatefile.certcodeerror": "รหัสใบรับรองมีข้อผิดพลาด",
  "ccm.agent.label.maxWaitNum": "ค่าสูงสุด ลำดับการโทร",
  "ccm.satisfactionconfig.message.enterflowname": "กรุณาป้อนชื่อกระบวนการ",
  "ccm.systemparam.error.refresh": "ล้มเหลวในการอัปเดตพารามิเตอร์",
  "ccm.note.config.agent.page.ring.newMedia": "การแจ้งเตือนข้อความใหม่สําหรับมัลติมีเดีย",
  "ccm.agent.message.pageConfFail": "การดำเนินการล้มเหลว",
  "ccm.transtask.label.certTip.proxyCert": "โปรดเลือกใบรับรองพร็อกซี",
  "ccm.agent.pageurls.integrationType": "ประเภทการประกอบ",
  "ccm.recognition.label.updatesucess": "อัปเดตความสำเร็จของผู้เช่า",
  "ccm.agent.calledroute.delfail": "ลบข้อมูลในเครื่องไม่สำเร็จ",
  "ccm.agent.message.sysParamCofig.openVersatileAgentFail": "เปลี่ยนประเภทตัวแทนในหน้าการจัดการตัวแทนจํานวนตัวแทนและตัวแทนวิดีโออเนกประสงค์ไม่สามารถเกินจํานวนตัวแทนวิดีโอสูงสุดที่ผู้เช่าสามารถเป็นสมาชิกได้",
  "ccm.custdata.button.importRecord": "นำเข้าผลลัพธ์",
  "ccm.custdata.msg.confirmWithdraw": "แน่ใจไหมว่าต้องการถอน",
  "ccm.satisfactionconfig.message.operate": "การดําเนินการ",
  "ccm.agent.message.skillConfFail": "กำหนดค่าข้อมูลลำดับทักษะไม่สำเร็จ",
  "ccm.agent.message.validatormsg": "รหัสผ่านที่ใส่ ควรว่างเปล่าหรือไม่ว่างเลย",
  "ccm.recognition.label.sucess": "สำเร็จ",
  "ccm.agent.calledroute.exception": "ตรวจสอบสถานะ Redis หรือการกำหนดค่า CC-Gateway",
  "ccm.satisfactionconfig.message.strategygroupmember": "นโยบาย",
  "ccm.speciallisthis.operlog.downloadData": "ประวัติการส่งออกรายการพิเศษ",
  "ccm.agent.message.skillHasbeenUsedByAgent": "เจ้าหน้าที่ถือครองลำดับทักษะแล้ว ลบเจ้าหน้าที่ก่อน.",
  "ccm.agent.leavemessage.notLoginIn": "เจ้าหน้าที่ยังไม่ได้ลงชื่อเข้าใช้",
  "ccm.agent.label.workNo": "บัตรประจําตัวพนักงาน",
  "ccm.custdata.label.columnType": "ประเภทฟิลด์",
  "ccm.satisfactionconfig.message.strategy": "นโยบายการสํารวจความพึงพอใจ",
  "ccm.urlconfig.pageurls.urlvalidate": "URL ต้องขึ้นต้นด้วย http: // หรือ https: //",
  "ccm.callout.lable.number": "ค่าพารามิเตอร์",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkRepeat": "มีการทำซ้ำในส่วนที่เลือก!",
  "ccm.contact.msg.dataEmpty": "ไม่มีข้อมูลการทํางานใน AICC",
  "ccm.contact.msg.dataverification": "ไม่สามารถตรวจสอบพารามิเตอร์ที่ร้องขอได้",
  "ccm.speciallist.msg.enable.fail": "การเปิดใช้งานล้มเหลว",
  "ccm.agent.message.updateCtiAndDbSuccess": "ซิงค์เรียบร้อยแล้ว",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnumber": "เวลาเริ่มต้นหรือสิ้นสุดต้องเป็นตัวเลข",
  "ccm.agent.message.batchExport": "การส่งออกแบทช์",
  "ccm.agent.contact.clicktodial": "คลิกเพื่อโทรออก",
  "ccm.custdata.label.dataImportRecord": "บันทึกการนำเข้าข้อมูล",
  "ccm.custdata.option.maskStyle.maskFirst4": "มาสก์สี่หลักแรก",
  "ccm.label.contactexport.exceedlimit2": "จำกัดช่วงเวลาข้อมูลให้แคบลงหรือเพิ่มเกณฑ์กรองข้อมูล",
  "ccm.transserver.label.edit": "เซิร์ฟเวอร์การถ่ายโอนข้อมูลทรัพยากร",
  "ccm.transtask.label.exportdata": "ส่งออกเขตข้อมูล:",
  "ccm.agent.accesscode.index": "เลขที่",
  "ccm.satisfactionconfig.message.save": "บันทึก",
  "ccm.certificate.management.certcode": "ชื่อใบรับรอง",
  "ccm.agent.message.ctipasswdsimple": "รหัสผ่านไม่เป็นไปตามข้อกําหนดความซับซ้อนของแพลตฟอร์ม ติดต่อเจ้าหน้าที่ O M เพื่อตรวจสอบรหัสผ่าน",
  "ccm.agent.calledroute.fail": "ล้มเหลว",
  "ccm.custdata.label.isEncrypt": "เข้ารหัส",
  "ccm.agent.message.agentNotFoundFail": "ไม่พบข้อมูลเจ้าหน้าที่",
  "ccm.agent.message.existDualchannelrecAgent": "ไม่สามารถเปิดใช้งานการระบุอัจฉริยะและการบันทึกแบบคู่ในขณะเดียวกัน เอเยนต์ที่มีการเปิดใช้งานการบันทึกแบบคู่มีอยู่ในกลุ่มตัวแทนที่มีการเปิดใช้งานการบันทึกแบบคู่",
  "ccm.transtask.label.contactchoose": "ฟิลด์ที่กําหนดเองในระเบียนผู้ติดต่อ",
  "ccm.satisfactionconfig.message.grouppriority": "ลําดับความสําคัญ",
  "ccm.agent.label.sipPwd": "รหัสผ่าน Softphone",
  "ccm.recognition.label.modifymobileagentsuccess": "แก้ไขสถานะมือถือเจ้าหน้าที่สำเร็จ!",
  "ccm.agent.operation.RECORDPLAY": "เล่นไฟล์เสียงที่บันทึก",
  "ccm.label.contactexport.curpage": "หน้าปัจจุบัน",
  "ccm.transserver.label.servertype": "ประเภท",
  "ccm.label.contactexport.exceedlimit1": "สามารถส่งออกบันทึกข้อมูลได้สูงสุด {0} รายการ",
  "ccm.agent.contact.pictures": "รูปภาพ",
  "ccm.agent.operation.CANCELINSERT": "ยกเลิกการแทรก",
  "ccm.agent.page.release": "ปล่อย",
  "ccm.agent.label.waitSkillWeight": "ใส่น้ำหนักทักษะ",
  "ccm.satisfactionconfig.audio.and.video": "แบบสํารวจความพึงพอใจของช่องสัญญาณเสียงหรือวิดีโอ",
  "ccm.agent.pageurls.isconfreset": "แน่ใจไหมว่าต้องการรีเซ็ต",
  "ccm.agent.page.pageParameter": "พารามิเตอร์ของหน้า",
  "ccm.agent.operation.optype": "ประเภทการทำงาน",
  "ccm.agent.label.skillWeight": "ลำดับทักษะของเจ้าหน้าที่",
  "ccm.case.label.recommendedWorkOrder0": "ประเภทคําสั่งงานที่แนะนํา 1",
  "ccm.botherlist.label.approveStatus.approved": "เห็นด้วย",
  "ccm.agent.Transfer.devicetype": "ประเภทอุปกรณ์ปลายทาง",
  "ccm.agent.leavemessage.QUERU_LEAVEMESSAGE_FAIL_CODE": "ข้อมูล SFTP ว่าง สำหรับการค้นหาข้อความ",
  "ccm.agent.pageurls.confreset": "ยืนยันการรีเซ็ต",
  "ccm.agent.operation.noMoreThan3Days": "ช่วงเวลาต้องไม่เกิน 3 วัน",
  "ccm.case.label.recommendedWorkOrder2": "ประเภทคําสั่งงานที่แนะนํา 3",
  "ccm.case.label.recommendedWorkOrder1": "ประเภทคําสั่งงานที่แนะนํา 2",
  "ccm.agent.sysparamconfig.outboundtimecheck": "การ จำกัด เวลาสำหรับการดูตัวอย่างการโทรออกอัตโนมัติต้องอยู่ในช่วง 1 ถึง 3600 วินาที",
  "ccm.agent.resetskillrecord.adjustresult": "ปรับผลการค้นหา",
  "ccmanagement.satisfaction.strategy.calltime.second": "s",
  "ccm.speciallist.msg.restoreout": "พื้นที่ผู้เช่าสามารถจัดเก็บบันทึกรายการพิเศษได้สูงสุด {0}",
  "ccm.custdata.option.importDoing": "กำลังประมวลผล",
  "ccm.agent.contact.CONNECT_FAIL_CODE": "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ SFTP",
  "ccm.basicdata.msg.modifysuccess": "แก้ไขข้อมูลพื้นฐานเรียบร้อยแล้ว",
  "ccm.sysparam.error.validate.notmatchruleminlength": "พารามิเตอร์อินพุตน้อยกว่าความยาวขั้นต่ำ",
  "ccm.contactitemconfig.message.itemValidate": "ตัวอักษรแรกต้องเป็นตัวอักษรเน้น (_), หรือเครื่องหมายดอลลาร์ ($). ตัวอักษรอื่น ๆ สามารถเน้นย้ําสัญญาณ (_), ดอลลาร์ตัวอักษรหรือตัวเลข",
  "ccm.agent.contact.callednook": "ขาออก ไม่มีคำตอบ",
  "ccm.urlconfig.modify.callnotify.ak.tooshort": "ความยาวคีย์ต้องมีอักขระอย่างน้อย 16 ตัว",
  "ccm.recognition.label.appSecret.reset": "รีเซ็ต SK",
  "ccm.recognition.msg.unsubscribeConfirm": "การปิดการจดจําอัจฉริยะจะปิด Assistant พร้อมกัน",
  "ccm.agent.contact.acceptno": "หมายเลขที่กำลังดำเนินการ",
  "ccm.satisfactionconfig.message.updatefailed": "การอัปเดตการกำหนดค่าแบบสำรวจความพึงพอใจล้มเหลว",
  "ccm.speciallist.msg.applyTimeFrom": "เวลาเริ่มต้นแอปพลิเคชัน",
  "ccm.agent.contact.resetAll": "รีเซ็ตทั้งหมด",
  "ccm.transtask.label.cancel": "ยกเลิก",
  "ccm.anonymous.message.fail.open": "ล้มเหลวในการเปิดธง ตรวจสอบการตั้งค่า CTI",
  "ccm.idauth.create.error.length": "ความยาวสูงสุดของการเข้ารหัสการตรวจสอบความถูกต้อง",
  "ccm.asr.create.case.chat.info": "คลิกที่ สร้างกรณีระหว่างหรือหลังการแชท",
  "ccm.agent.label.subtype": "ประเภทย่อย",
  "ccm.agent.message.aiagentnummore": "จำนวนเจ้าหน้าที่อัจฉริยะที่เปิดใช้งานต้องไม่เกินจำนวนเจ้าหน้าที่ที่จัดสรรเมื่อเปิดใช้งานผู้เช่า",
  "ccm.taskrecord.label.executeFail": "การประมวลผลล้มเหลว",
  "ccm.agent.message.modifymobileagentsuccess": "แก้ไขข้อมูลเกี่ยวกับหมายเลขโทรศัพท์มือถือของมือถือเจ้าหน้าที่เรียบร้อยแล้ว",
  "casemanagement.user.title.account": "ชื่อบัญชี",
  "ccm.certificate.management.certificatefile.suffixerror.pre": "ใบรับรองประเภทสามารถมีได้เฉพาะไฟล์ที่มีนามสกุลเท่านั้น",
  "ccm.transtask.label.task.uniqueness": "สามารถสร้างงานข้อมูลรายงานได้เพียงหนึ่งงานในช่วงเวลาการถ่ายโอนข้อมูลเดียวกันสําหรับประเภทการถ่ายโอนข้อมูลเดียวกัน",
  "ccm.operlog.contactfile.download": "ดาวน์โหลดไฟล์",
  "ccm.agent.message.agentnumcannotmuchthanmax": "จำนวนประเภทเจ้าหน้าที่ต้องไม่เกินสูงสุด",
  "ccm.callout.lable.index": "ลำดับ",
  "ccmanagement.satisfaction.strategy.minutevalidate": "นาทีอยู่ในรูปแบบที่ไม่ถูกต้อง",
  "ccm.satisfactionconfig.message.failtogetivrflow": "กรุณาเปิดแบบสำรวจความพึงพอใจ",
  "ccm.agent.restReason.selectRestReasons": "เลือกเหตุผลที่เหลือ",
  "ccm.agent.clear.message.agentnotexist": "ไม่สามารถยกเลิกการกำหนดค่าได้เนื่องจากไม่มีเจ้าหน้าที่",
  "ccmanagement.satisfaction.strategy.deletesuccess": "ลบสําเร็จ",
  "ccm.callreason.label.selectedreason": "เหตุผลที่เลือก",
  "ccm.agent.message.searchAccount": "ป้อน ID เจ้าหน้าที่",
  "ccm.certificate.management.certificatefile.certtypeerror": "ประเภทใบรับรองมีข้อผิดพลาด",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnull": "เวลาเริ่มต้นหรือสิ้นสุดไม่สามารถเว้นว่างได้",
  "ccm.agent.contact.callinandcallout": "สายเรียกเข้าและขาออก",
  "ccm.speciallist.msg.restoreerror": "ไม่สามารถเรียกคืนรายการพิเศษได้",
  "ccmanagement.satisfaction.strategy.calltime.lengthlimit": "ระยะเวลาการโทรต่ําสุดหรือสูงสุดสามารถมีตัวเลขได้สูงสุด 12 หลัก",
  "ccm.agent.calledroute.create.ivr.limit": "หากประเภทอุปกรณ์เป็น IVR ประเภทสื่อของรหัสการเข้าถึงสามารถคลิกเพื่อโทร ,เรียกร้องกลับหรือการโทรเท่านั้น",
  "ccm.agent.contact.playcomfirm": "ยืนยันการเล่น",
  "ccm.sysparam.error.validate.notmatchrulerangelength": "พารามิเตอร์อินพุตเกินความยาวสูงสุดหรือต่ำกว่าความยาวขั้นต่ำ",
  "ccm.contactitemconfig.label.data.extended.dataset.mapping.field": "ฟิลด์การแมปชุดข้อมูล",
  "ccm.accesscode.label.index": "เลขที่",
  "ccm.transserverparam.label.proxyUserName": "Username",
  "ccm.common.ajax.searchErr": "การค้นหาผิดพลาด",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnumber": "วันที่เริ่มต้นหรือสิ้นสุดอยู่ในรูปแบบที่ไม่ถูกต้อง",
  "ccm.agent.label.weight": "น้ำหนัก",
  "ccm.vdn.label.defaultvoice": "ค่าเริ่มต้น",
  "ccm.transtask.label.dumpinterval": "ระยะเวลาการถ่ายโอนข้อมูล",
  "ccm.agent.operation.INSERT": "เพิ่มการตรวจสอบคุณภาพ",
  "ccm.contact.customer.config.foregroundColor": "สีพื้นหน้า",
  "ccm.satisfactionconfig.message.strategychannelid": "รหัสนโยบายช่อง",
  "ccm.agent.label.batchselectway": "โหมดการเลือกแบทช์",
  "ccm.calledroute.title.selectivrflow": "เลือก IVR",
  "ccm.agent.Transfer.deviceAddr": "ที่อยู่อุปกรณ์ปลายทาง",
  "ccm.agent.label.agentWeightTip": "Agent ที่มี weight สูงสุดจะได้รับสายก่อน",
  "ccm.contact.customer.config.datasource.objName": "แหล่งข้อมูล สนามการแมปออบเจ็กต์",
  "ccm.note.config.choosenote": "กรุณาเลือกไฟล์บันทึกย่อ",
  "ccm.agent.softPhoneStatus.Registered": "จดทะเบียน",
  "ccm.satisfactionlevel.message.satislevel": "ระดับความพึงพอใจ",
  "ccm.note.config.agent.ring.back.tone": "การตั้งค่าเสียงรอสาย",
  "ccm.pageinterface.addObject": "เพิ่มวัตถุ",
  "ccm.transtask.label.discarded": "ยกเลิก",
  "ccm.agent.label.inputstartworkno": "กรุณาป้อน ID พนักงานเริ่มต้น",
  "ccm.sysparam.error.validate.notmatchruleemail": "โปรดป้อนที่อยู่อีเมลที่ถูกต้อง",
  "ccm.satisfactionconfig.message.audio": "แบบสํารวจความพึงพอใจ",
  "ccm.agent.button.export": "ส่งออก",
  "ccm.contactitemconfig.label.data.extended.Type.String": "สตริง",
  "ccm.contact.msg.inactivesuccess": "ใช้งานไม่ได้บความสําเร็จ",
  "ccm.agent.contact.calltype": "ประเภทการโทร",
  "ccm.agent.message.createfailed": "สร้างเจ้าหน้าที่ล้มเหลว",
  "ccm.dual.call.record.noMatchingDualCallRecordExists": "ไม่มีบันทึกการโทรแบบสองทิศทางที่ตรงตามเกณฑ์",
  "ccm.agent.calledroute.updatecalledroute": "แก้ไข",
  "ccm.agent.calledroute.title.create": "การสร้างการกําหนดค่าของฝ่ายที่โทรเข้ามา",
  "ccm.agent.calledroute.title.updatecalledroute": "แก้ไขการกําหนดค่าของฝ่ายที่ถูกเรียก",
  "ccm.transtask.label.cdrData": "ข้อมูล CDR",
  "ccm.agent.leavemessage.auditcomments": "ความคิดเห็นจากผู้ตรวจสอบ",
  "ccmanagement.satisfaction.strategy.operation": "Operation",
  "ccm.speciallist.msg.typeName": "ชนิดรายการพิเศษ",
  "ccm.satisfactionconfig.message.strategychanneltitle": "กําหนดค่านโยบายช่องสัญญาณ",
  "ccm.satisfactionconfig.add.strategychanneltitle": "การสร้างนโยบายความพึงพอใจของช่องทางการขาย",
  "ccm.satisfactionconfig.update.strategychanneltitle": "แก้ไขกลยุทธ์ความพึงพอใจของช่องรายการ",
  "ccm.transtask.label.contactrcd": "บันทึกการติดต่อการถ่ายโอนข้อมูล",
  "ccm.agent.calledroute.accesscodewarn": "รหัสการเข้าถึงสามารถมีได้เพียงตัวเลข!",
  "ccm.whatsapp.template.message.name": "ชื่อจดหมาย",
  "ccm.speciallist.msg.delete.fail": "การลบล้มเหลว",
  "ccm.agent.contact.clicktocall": "คลิกเพื่อโทร",
  "ccm.agent.ten.asrchannelnums": "ปริมาณ ASR",
  "ccm.skillgroup.message.skillgroup": "โปรดป้อนชื่อกลุ่มทักษะ",
  "ccm.agent.label.scaleFactor": "ค่าสัมประสิทธิ์สัดส่วน",
  "ccm.custdata.msg.confirmDelete": "แน่ใจไหมว่าต้องการลบ",
  "ccm.speciallist.msg.errorNum": "{limit}ล้มเหลว",
  "ccm.certificate.management.passwd": "รหัสผ่านของใบรับรอง",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnull": "ไม่สามารถเว้นว่างระยะเวลาการโทรต่ําสุดหรือสูงสุดได้",
  "ccm.transtask.label.dumpintervalInitial": "เริ่มการถ่ายโอนข้อมูลระยะเวลา",
  "ccm.agent.message.willDeleteSkills": "คุณแน่ใจหรือไม่ว่าต้องการลบคิวทักษะ {0} รายการ",
  "ccm.agent.message.willDeleteSkillGroups": "คุณแน่ใจหรือไม่ว่าต้องการลบกลุ่มทักษะที่เลือก",
  "ccm.custdata.operlog.confirm": "ยืนยัน",
  "ccm.agent.contact.multi-media": "มัลติมีเดีย",
  "ccm.pageinterface.pleaseSelectAttribute": "เลือกแอตทริบิวต์วัตถุ",
  "ccm.contactitemconfig.message.itemValidatefirst": "ตัวอักษรตัวแรกต้องเป็นตัวอักษรขีดล่าง (_), หรือเครื่องหมายดอลลาร์ ($)",
  "ccm.urlconfig.message.httpurl.safetip": "(ไม่ปลอดภัย)",
  "ccm.agent.message.agentConfFail": "กำหนดค่าข้อมูลเจ้าหน้าที่ไม่สำเร็จ",
  "ccm.satisfactionconfig.message.flowname": "ชื่อกระบวนการ",
  "ccm.custdata.label.importantTips": "หมายเหตุ: ข้อมูลส่วนบุคคลหรือข้อมูลที่ละเอียดอ่อนจะต้องเข้ารหัสเพื่อจัดเก็บและปิดหน้ากากเพื่อแสดง",
  "ccm.recognition.label.appSecret.download.querySK.error": "ไม่สามารถสอบถาม SK",
  "ccm.agent.contact.oircall": "หมายเลขโทรศัพท์เดิม",
  "ccm.agent.contact.weChat": "WeChat",
  "ccm.agent.label.scaleFactorRange": "ช่วงค่า: [0-10000]",
  "ccm.agent.restrecord.reststatus.timeout.Forcible.endrest": "การพักถูกบังคับให้สิ้นสุด",
  "ccmanagement.agent.batchmodify.agentnum": "ตาม ID พนักงาน",
  "ccm.speciallist.msg.necessary": "ต้องมีหมายเลขผู้ใช้",
  "ccm.recognition.label.closeanonymoussuccess": "ปิดใช้งานธงการโทรออกที่ไม่ระบุชื่อสําเร็จ",
  "ccm.transserver.label.servername": "ชื่อเซิร์ฟเวอร์",
  "ccm.agent.message.numInRowFail": "ค่าต้องเป็นจำนวนเต็มตั้งแต่ 2 ถึง 4",
  "ccm.transtask.label.status": "สถานะ",
  "ccm.agent.label.callDispatchModel": "โหมดการกระจายการโทร",
  "ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE": "ข้อมูลการบันทึกว่าง",
  "ccm.recognition.label.cancelordersucess": "ยกเลิกการสมัครสมาชิกเรียบร้อยแล้ว",
  "ccm.agent.leavemessage.statusFailed": "ไม่สามารถเปลี่ยนสถานะบันทึกข้อความ",
  "ccm.agent.message.sysParamCofig.closeVersatileAgentFail": "เปลี่ยนประเภทตัวแทนในหน้าการจัดการตัวแทนเอเจนต์อเนกประสงค์ไม่สามารถเชื่อมโยงกับคิวทักษะการคลิกต่อวิดีโอหรือวิดีโอได้",
  "ccm.skillcreate.repeat.fail": "ชื่อคิวทักษะมีอยู่แล้ว",
  "ccm.speciallist.msg.selectonlyslreminder": "สามารถแก้ไขการแจ้งเตือนรายการพิเศษได้เพียงหนึ่งรายการเท่านั้น",
  "ccm.skill.label.skillname": "ชื่อคิวทักษะ",
  "ccm.agent.title.skillCreate": "ลำดับทักษะใหม่",
  "ccm.custdata.option.maskStyle.maskEmail": "ซ่อนอีเมล",
  "ccm.agent.contact.QUERY_SERVER_FAIL_CODE": "เรียกใช้บริการค้นหาไม่สำเร็จ",
  "ccm.satisfactionconfig.message.strategygroupname": "ชื่อกลุ่มนโยบาย",
  "ccm.satisfactionconfig.title.strategygroup": "การสร้างกลุ่มนโยบาย",
  "ccm.sysparam.config.itemdesc": "คําอธิบาย",
  "ccm.agent.label.keepVoiceCustom": "ปรับแต่งเสียงค้างไว้",
  "ccm.agent.label.numInRow": "ป้อนจำนวนเต็มตั้งแต่ 2 ถึง 4",
  "ccm.transserver.label.view": "ดูทั้งหมด",
  "ccm.custdata.label.isMaskDisplay": "การซ่อน",
  "ccm.custdata.label.columnNo": "ลำดับ",
  "ccm.urlconfig.label.oauth.oauth2loginurl": "โปรดป้อน URL Login ของ OAuth 2.0",
  "ccm.agent.button.batchconfig": "ตั้งค่าชุด",
  "ccm.agent.pageurls.errorsave": "ข้อผิดพลาด",
  "ccm.certificate.management.update.cancel.certfile": "แน่ใจไหมว่าต้องการยกเลิกการอัปเดตใบรับรอง",
  "ccm.common.label.error": "เกิดข้อผิดพลาด",
  "ccm.agent.operation.noMoreThan31Days": "ช่วงเวลาต้องไม่เกิน 31 วัน",
  "ccm.agent.label.validTime": "ระยะเวลาการใช้งานรหัสผ่าน (วัน)",
  "ccm.agent.operation.UNHOLD": "ยกเลิกการพักสาย",
  "ccm.speciallist.msg.endtime": "วันหมดอายุต้องไม่เกินวันที่ 19 มกราคม 2038",
  "ccmanagement.satisfaction.strategy.fail.calltime.input.length": "ระยะเวลาการโทรต่ําสุดหรือสูงสุดสามารถมีตัวเลขได้สูงสุด 12 หลัก",
  "ccm.speciallist.label.line": "จํานวนแถว",
  "ccm.agent.operation.CANCELREST": "ยกเลิกการพัก",
  "ccm.certificate.management.expiretime": "เวลาหมดอายุ",
  "ccm.custdata.option.importDone": "เสร็จเรียบร้อย",
  "ccm.label.contactexport.exception": "เกิดข้อผิดพลาดขณะสร้างงานส่งออก ตรวจสอบบันทึก",
  "ccm.satisfactionconfig.message.validatemaxnumber": "สามารถสร้างกลุ่มนโยบายได้สูงสุด 100 กลุ่ม",
  "ccm.certificate.management.certificatecrlfile": "ไฟล์ CRL",
  "ccm.pageinterface.objectList": "รายการวัตถุ",
  "ccm.agent.label.information": "เคล็ดลับ",
  "ccm.agent.label.selectagent": "เจ้าหน้าที่ที่เลือก",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "คำสำคัญ",
  "ccm.satisfactionconfig.message.surveytype": "ประเภทการสํารวจ",
  "ccm.verifyidentity.FAIL": "ล้มเหลว",
  "ccm.basicdata.error.datatypenotmatch": "รูปแบบข้อมูลไม่สอดคล้องกับรูปแบบการกำหนดฟิลด์",
  "ccm.agent.recall": "โทรกลับ",
  "ccm.pageinterface.interfaceType.external": "อินเทอร์เฟซภายนอก",
  "ccm.dual.call.record.talkDuration": "ระยะเวลาการโทร",
  "ccm.custdata.label.columnLength": "ความยาวฟิลด์",
  "ccm.agent.title.selectskill": "เลือกลำดับทักษะ",
  "ccm.pageurls.select.externalInterface": "หน้าจอภายนอก",
  "ccm.agent.contact.itacall": "ITA การโทรแบบสองทิศทาง",
  "ccm.agent.message.specialCharacters": "หมายเลขประกอบด้วยอักขระพิเศษและตัวอักษร",
  "ccm.custdata.msg.completeSort": "เรียงลำดับให้เสร็จก่อน",
  "ccm.transserver.delete.css.confirm": "คุณแน่ใจหรือไม่ว่าต้องการลบการกําหนดค่า CSS",
  "ccm.agent.restReason.delete": "ลบ",
  "ccm.agent.contact.accountname": "ชื่อบัญชี",
  "ccm.contact.customer.config.datasource.msgDataset": "ข้อมูลชุดข้อมูล",
  "ccm.agent.message.ctipasswdrepeat": "รหัสผ่านใหม่ ต้องไม่เหมือนกับรหัสผ่านเก่าห้ารายการล่าสุด",
  "ccm.speciallist.msg.operationerror": "ปฏิบัติการล้มเหลว",
  "ccm.agent.contact.relCallBackFlag": "เผยแพร่ค่าสถานะเรียกกลับ",
  "ccm.agent.label.batchselect": "เลือกเอเจนต์ในแบทช์",
  "ccm.ucconfig.message.title": "การลงทะเบียนแอปพลิเคชันด้วย Microsoft Identity Platform",
  "ccm.callreason.label.enable": "เปิดใช้งาน",
  "ccm.agent.message.agenttypeinvalid": "ประเภทเจ้าหน้าที่ไม่ถูกต้อง",
  "ccm.agent.message.worknonoexit": "ไม่มี ID พนักงาน",
  "ccm.contactitemconfig.label.data.extended.set": "ชุดข้อมูลของแหล่งที่มา",
  "ccm.agent.message.worknull": "รหัสพนักงานว่าง",
  "ccm.satisfactionconfig.message.update": "แก้ไข",
  "ccm.agent.label.busy": "ทักษะไม่ว่าง",
  "ccm.speciallist.msg.exists": "ผู้ใช้ที่เลือกถูกรวมไว้ในรายการพิเศษแล้ว",
  "ccm.satisfactionconfig.message.surveystrategygroup": "นโยบายการสํารวจ",
  "ccm.recognition.label.modifymobileagentflowidnull": "รหัสโฟลว์ว่างเปล่า!",
  "ccm.agent.message.configvalidatefailed": "การตรวจสอบรายการการตั้งค่าเกตเวย์ล้มเหลว",
  "ccm.agent.label.agentList": "บัญชีบริการ",
  "ccm.sysparam.error.validate.notmatchrulerange": "พารามิเตอร์อินพุตเกินค่าสูงสุดหรือต่ำกว่าค่าต่ำสุด",
  "ccm.calledconfig.message.willdeleteassociation": "คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลการเชื่อมโยงเหตุผลของการโทร",
  "ccm.agent.label.ninetydays": "90 วัน",
  "ccm.agent.url.params.tips": "โปรดทราบว่าวิธีที่สร้าง URL GET REQUEST พารามิเตอร์จะแสดงพารามิเตอร์ของคุณไปยังที่อยู่ที่เปิดอยู่",
  "ccm.contact.customer.config.visible": "มองเห็นได้",
  "ccm.agent.updateSoftPhone.accountPasswordFailed": "ไม่สามารถยืนยันรหัสผ่านบัญชี",
  "ccm.contact.customer.config.preview": "ดูตัวอย่าง",
  "ccm.agent.message.sysParamCofig": "ไม่สามารถกำหนดค่าพารามิเตอร์ของระบบ",
  "ccm.custdata.operlog.success": "สำเร็จ",
  "ccm.agent.leavemessage.inputauditcomments": "ป้อนคำแนะนำ",
  "ccm.agent.mobileagent.label.nodata": "ไม่พบข้อมูลที่ตรงกัน!",
  "ccm.agent.operation.SAYBUSY": "ไม่ว่าง",
  "ccm.asr.create.case.text.info": "ไม่สามารถสร้างเคสได้เนื่องจากไม่มีเนื้อหาข้อความ",
  "ccm.note.config.agent.page.ring": "ริงโทน",
  "ccm.agent.pageurls.moveup": "ขึ้น",
  "ccm.custdata.operlog.downloadTemplate": "การดาวน์โหลดเทมเพลตตารางพื้นฐาน",
  "ccm.agent.leavemessage.notAgent": "บัญชีไม่ผูกมัดกับเจ้าหน้าที่",
  "ccm.agent.pageurls.confdelete": "ยืนยันการลบ",
  "ccm.agent.password.agentNull": "ข้อมูลเจ้าหน้าที่ว่าง",
  "ccm.agent.operation.TWOSTAGEDIALING": "การโทรสองขั้นตอน",
  "ccm.label.contactexport.nocount": "ไม่มีบันทึกรายชื่อที่ตรงตามเงื่อนไข",
  "ccm.label.dualcallexport.nocount": "ไม่มีบันทึกการโทรสองครั้งที่มีสิทธิ์",
  "ccm.recognition.label.processaccesscode": "ป้อนรหัสการเข้าถึงขั้นตอน",
  "ccm.transtask.label.saturday": "วันเสาร์",
  "ccm.custdata.label.columnNum": "ฟิลด์",
  "ccm.agent.leavemessage.workNo": "เจ้าหน้าที่",
  "ccm.agent.contact.callinandout": "โทรพร้อมกันสองสาย",
  "ccm.agent.contact.user.recall": "ข้อความถูกถอนโดยผู้ใช้",
  "ccm.agent.mobileagent.label.deletecomplete": "ลบเจ้าหน้าที่สำเร็จ!",
  "ccm.agent.calledroute.refresh": "รีเฟรช",
  "ccm.verifyidentity.fail.TIMEPARAMNULLERROR": "เวลาค้นหาต้องไม่ว่างเปล่า",
  "ccm.contactitemconfig.label.data.extended.dataset.name": "ชื่อชุดข้อมูล",
  "ccm.agent.label.adjustTime": "ระยะเวลาในการจัดเรียง",
  "ccm.agent.contact.gateway.exception": "ข้อยกเว้นภายในเกตเวย์ กรุณาตรวจสอบการกำหนดค่าเกตเวย์",
  "ccm.speciallist.msg.success": "ประสบความสําเร็จ",
  "ccm.contact.detail.message.content": "เนื้อหาข้อความ",
  "ccm.custdata.msg.fileSizeValidateFail": "เลือกไฟล์ที่มีขนาดไม่เกิน 20 MB",
  "ccm.speciallist.label.reason": "เหตุผลในการเข้าร่วม",
  "ccm.callout.message.editfail": "แก้ไขคำบรรยายภาพผู้โทรไม่สำเร็จ",
  "ccm.note.config.agent.page.ring.voice": "แจ้งเตือนเสียง",
  "ccm.agent.restrecord.rest.beginTime": "เวลาเริ่มต้นการพัก",
  "ccm.agent.speciallist.validityPeriod": "ระยะเวลาที่มีผล",
  "ccm.agent.speciallist.begintime": "เวลาที่มีประสิทธิภาพ",
  "ccm.transserverparam.label.bucket": "ถังข้อมูล",
  "ccm.agent.calledroute.editfail": "แก้ไขข้อมูลในเครื่องไม่สำเร็จ",
  "ccm.agent.contact.resourcefail": "เกิดข้อผิดพลาดระหว่างการโหลดการบันทึก",
  "ccm.satisfactionconfig.message.creategroup": "เพิ่มกลุ่มนโยบาย",
  "ccm.agent.contact.calledhangup": "วางสายเรียกว่าวางสาย",
  "ccm.agent.calledroute.skill": "คิวทักษะ",
  "ccm.sysparam.config.checkmessage": "มีอักขระพิเศษ (\"<\", \">\", \"/\", ฯลฯ)",
  "ccm.contact.customer.config.regular": "ทั่วไป",
  "ccm.basicpage.not.sk.download": "ไม่มีการอนุญาตดาวน์โหลด SK",
  "ccm.satisfactionconfig.sms.satisfaction.survey": "แบบสํารวจความพึงพอใจของ SMS",
  "ccm.agent.label.restQueueRule.lessRest": "ผู้ที่มีเปอร์เซ็นต์เวลาพักผ่อนน้อยในวันปัจจุบันจะได้รับลําดับความสําคัญ",
  "ccm.recognition.label.interconnection.parameters": "พารามิเตอร์การเชื่อมต่อ",
  "ccm.agent.label.transparentDisplayFlag": "สถานะการไม่เปิดเผยชื่อหมายเลขตัวแทน",
  "ccm.calledconfig.message.numbersinvalid": "มากกว่า 15 หรือน้อยกว่า 1 หมายเลข",
  "ccm.agent.operation.noMoreThan7Days": "ช่วงเวลาต้องไม่เกิน 7 วัน",
  "ccm.agent.callreason.modify.success": "เหตุผลการโทรได้รับการแก้ไขเรียบร้อยแล้ว",
  "ccm.speciallist.msg.specialchar": "ข้อความเตือนความจําต้องไม่มีอักขระพิเศษ",
  "ccm.speciallevel.speciallist.use": "ใช้ได้",
  "ccm.custdata.option.status.draft": "ร่าง",
  "ccm.agent.contact.transferRecord": "บันทึกการโอน",
  "ccm.pageinterface.viewAttribute": "ดูคุณสมบัติของวัตถุ",
  "ccm.urlconfig.message.addfail": "เพิ่ม URL ไม่สำเร็จ",
  "ccm.skill.message.skillpara": "ไม่สามารถกำหนดค่าทักษะเองได้",
  "ccm.agent.label.callDispatchModel.average": "กระจายอย่างสม่ําเสมอ",
  "ccm.idauth.title.name": "โหมดการตรวจสอบสิทธิ์ชื่อ",
  "ccm.agent.label.serviceAccountCode": "ชื่อบัญชี",
  "ccm.agent.label.thirtyonedays": "31 วัน",
  "ccm.page.label.pageInput": "ป้อนชื่อหน้า",
  "ccm.recognition.label.appSecret.download.appId.empty.error": "ฟิลด์ appId ต้องไม่ว่าง",
  "ccm.agent.contact.line": "LINE",
  "ccm.transtask.label.delete": "งานถ่ายโอนข้อมูลถูกลบเรียบร้อยแล้ว",
  "ccm.certificate.management.certificatefile.certpwderror": "รหัสผ่านใบรับรองมีข้อผิดพลาด",
  "ccm.agent.label.sensitiveWord": "ภาษาต้องห้าม! โปรดตั้งใจฟังคําพูด",
  "ccm.idauth.title.create": "สร้างกระบวนการรับรองความถูกต้อง",
  "ccm.speciallist.msg.typeidempty": "ชนิดรายการพิเศษไม่สามารถเว้นว่างไว้ได้",
  "ccm.agent.label.deviceType": "ประเภทอุปกรณ์",
  "ccm.agent.label.ivr": "IVR",
  "ccm.agent.leavemessage.assign": "กำหนด",
  "ccm.agent.leavemessage.title.assign": "การกําหนดข้อความ",
  "ccm.custdata.operlog.popwindowmessage": "กรุณาป้อนรหัสผ่านที่ใช้สร้างไฟล์บีบอัด รหัสผ่านที่บีบอัดควรประกอบด้วยตัวอักษรตัวเลขอักขระพิเศษ _ {'@'} {'%'}",
  "ccm.agent.label.bindTheSkill": "ก่อนที่คุณจะใช้ลำดับทักษะให้เลิกมัดเส้นทางที่เรียกก่อน",
  "ccm.agent.label.groupUseTheSkill": " โปรดลบการกําหนดค่าของคิวทักษะในกลุ่มทักษะก่อน",
  "ccm.agent.label.groupBind": "คิวสกิลถูกผูกไว้กับกลุ่มสกิล ",
  "ccm.transtask.label.month": "เดือน",
  "ccm.agent.contact.gateway.fail": "การร้องขอไปยังข้อยกเว้นเกตเวย์ กรุณาตรวจสอบการกำหนดค่าเกตเวย์",
  "ccm.recognition.label.mobileagent": "มือถือของเจ้าหน้าที่",
  "ccm.urlconfig.label.relmethod": "วิธีการขอ HTTP",
  "ccm.agent.contact.robothungup": "โรบอท",
  "ccm.agent.pageurls.maxcalling": "จํานวนหน้าจอป็อปอัพสายเรียกเข้าสูงสุดคือ {limit} หน้าจอ.",
  "ccm.contact.customer.config.underline": "ขีดเส้นใต้",
  "ccm.contactitemconfig.message.confOnlyDataItemCode": "การดำเนินการล้มเหลวเนื่องจาก ID รายการข้อมูลนี้มีอยู่แล้ว.",
  "ccm.agent.leavemessage.channel": "ช่องทาง",
  "ccm.vdn.label.index": "เดือน",
  "ccm.agent.message.updateAgentAndSkillFail": "ไม่สามารถอัปเดตการเชื่อมโยงของลำดับทักษะเจ้าหน้าที่",
  "ccm.pageinterface.selectedAttribute": "แอตทริบิวต์ที่เลือก",
  "ccm.agent.calledroute.devicetype": "ประเภทอุปกรณ์",
  "ccm.verifyidentity.SUCCESS": "ผ่าน",
  "ccm.contact.customer.config.backgroundColor": "สีพื้นหลัง",
  "ccm.transserverparam.label.login.cssClusterUser": "Cluster Login ชื่อผู้ใช้",
  "ccm.agent.label.parameterType": "วิธีการจัดลำดับ",
  "ccm.agent.contact.talkRemark": "หมายเหตุการโทร",
  "ccm.transtask.label.thurs": "วันพฤหัสบดี",
  "ccm.pageinterface.cancelAll": "ยกเลิกทั้งหมด",
  "SM.ORGSTAFF.LABEL.ORGANIZATION": "องค์กร",
  "ccm.agent.message.searchSkillName": "โปรดป้อนชื่อคิวทักษะ",
  "ccm.recognition.label.intellagent": "ผู้ช่วยเจ้าหน้าที่อัจฉริยะ",
  "ccm.label.pageName": "ชื่อหน้า",
  "ccm.agent.ccagentinfo.unregister.fail": "ล็อกเอาต์ล้มเหลว",
  "ccm.agent.operation.CALLOUT": "โทรออก",
  "ccm.sysparam.error.validate.notmatchruledigits": "โปรดป้อนจำนวนเต็มบวกที่ถูกต้อง",
  "ccm.agent.calledroute.nmsfail": "การซิงโครไนซ์กับ NMS ล้มเหลว",
  "ccm.agent.message.agentCTIFail": "เจ้าหน้าที่แบบสอบถามที่แก้ไขเกตเวย์ล้มเหลวกรุณาตรวจสอบการกำหนดค่าเกตเวย์",
  "ccm.transtask.label.notEnabled": "ปิดการใช้งาน",
  "ccm.agent.button.cancel": "ยกเลิก",
  "ccm.custdata.label.description": "คำอธิบาย",
  "ccm.agent.addbother.msg.emailOrPhoneValidate": "รูปแบบไม่ถูกต้อง",
  "ccm.basicdata.error.firstlinenotmatch": "บรรทัดแรกในไฟล์ที่นำเข้าไม่สอดคล้องกับในเทมเพลต",
  "ccm.agent.message.dataIntegrityFail": "กำหนดค่าข้อมูลไม่สมบูรณ์สำหรับโครงร่างหน้าหรือชุดอินเทอร์เฟซ การดำเนินการล้มเหลว.",
  "ccm.contract.management.confirm": "เอกสารมีข้อมูลที่ละเอียดอ่อน คุณแน่ใจหรือว่าต้องการดําเนินการต่อ?",
  "ccm.contract.management.confirmtitle": "ยืนยันการใช้งาน",
  "ccm.speciallist.msg.restoresuccess": "การฟื้นฟูก็สําเร็จ",
  "ccm.pageinterface.title": "หน้าอินเทอร์เฟซ",
  "ccm.agent.callreason.need": "โปรดเลือกเหตุผลการโทรอย่างน้อยหนึ่งข้อ",
  "ccm.satisfactionconfig.message.version": "เวอร์ชั่น",
  "ccm.agent.sysparamconfig.resttimenumberwarn": "ระยะเวลาพักต้องอยู่ระหว่าง 1 ถึง 1440 (ไม่มี 1440)",
  "ccm.agent.pageurls.edit": "แก้ไข",
  "ccm.speciallist.tip.time.max": "โปรดเลือกเวลาน้อยกว่าการหมดอายุของโปรเจ็กต์ (2038-01-19)",
  "ccm.agent.label.singlePhoneFlag": "ตัวแทนทางโทรศัพท์เท่านั้น",
  "ccm.agent.message.addAIRecogFail": "จำนวนเจ้าหน้าที่เชี่ยวชาญที่เปิดใช้งานต้องไม่เกินจำนวนเจ้าหน้าที่ที่จัดสรรเมื่อเปิดใช้งานผู้เช่า",
  "ccm.agent.message.searchSipAccount": "ป้อนหมายเลขซอฟต์โฟน SIP",
  "ccm.transtask.label.enable": "เปิดใช้งาน",
  "ccm.agent.restrecord.reststatus.timeout.endrest": "หมดเวลาพัก",
  "ccm.agent.operation.FORCEIDEL": "บังคับให้ว่าง",
  "ccm.agent.button.refresh": "รีเฟรช",
  "ccm.agent.updateSoftPhone.newPasswordFailed": "ไม่สามารถยืนยันรหัสผ่านใหม่",
  "ccm.agent.restReason.restCauseDesc": "คำอธิบาย",
  "ccm.agent.message.omsAgentTypeCheck": "บทบาทที่เลือกไม่ตรงกับประเภทของตัวแทนออนไลน์",
  "ccm.agent.message.videoNotEnough": "จำนวนทรัพยากรวิดีโอไม่เพียงพอเนื่องจากทั้งเอเจนต์วิดีโอและเอเจนต์ที่กำหนดค่าด้วยคิวทักษะวิดีโอจะใช้ทรัพยากรวิดีโอ จัดสรรทรัพยากรวิดีโอให้เหมาะสม",
  "ccm.satisfactionconfig.message.smstitle": "SMS",
  "ccm.agent.ten.resourceinfor": "ข้อมูลทรัพยากร",
  "ccm.agent.label.deviceUsage": "การใช้งานอุปกรณ์ (%)",
  "ccm.speciallist.msg.warning": "คําเตือน",
  "ccm.custdata.option.maskStyle.maskBankAccountNum": "รูปแบบบัญชีธนาคาร",
  "ccm.basicdata.msg.queryfail": "ไม่สามารถค้นหาข้อมูลพื้นฐาน",
  "ccm.agent.message.agenttypebothempty": "ประเภทของตัวแทนไม่ได้ถูกกําหนดค่าสําหรับตัวแทนที่เลือก คุณต้องกําหนดค่าประเภทของตัวแทน",
  "ccm.agent.contact.multimedia": "แอป",
  "ccm.agent.message.querycenterfailed": "ไม่สามารถค้นหาเจ้าหน้าที่ที่เชี่ยวชาญของศูนย์บริการ",
  "ccm.ucconfig.message.integration": "การผสานรวม Microsoft Teams",
  "ccm.ucconfig.message.info.alert": "โปรดคัดลอกที่อยู่ต่อไปนี้ไปยัง Microsoft สําหรับการลงทะเบียนแอปพลิเคชันเป็น URI การเปลี่ยนเส้นทางการยอมรับการรับรองความถูกต้อง สําเนาผลการลงทะเบียนและกรอกข้อมูลการสมัครลงทะเบียนดังต่อไปนี้",
  "ccm.ucconfig.message.copy": "สําเนา",
  "ccm.ucconfig.message.register": "การลงทะเบียนข้อมูลการสมัคร",
  "ccm.ucconfig.message.required.fields": "ฟิลด์ที่จําเป็น",
  "ccm.ucconfig.message.required.limit": "ความยาวไม่เกิน 64 ตัวอักษร",
  "ccm.verifyidentity.userId": "รหัสผู้ใช้",
  "ccm.agent.pageurlparam.calldata": "ข้อมูลการโทร",
  "ccm.custdata.option.status.abandon": "ทิ้ง",
  "ccm.agent.label.release": "ปล่อย",
  "ccm.verifyentity.verifyResult": "ผลการตรวจสอบความถูกต้อง",
  "ccm.agent.message.synToCcpFailed": "การซิงโครไนซ์โหมดการตรวจสอบตัวแทนกับโพรวิชั่น cc ล้มเหลว",
  "ccm.agent.pageurls.adressval": "ที่อยู่ในการเข้าถึงต้องขึ้นต้นด้วย \"https: //\" หรือ \"http: //\".",
  "ccm.agent.pageurls.adressval.noPoint": "ที่อยู่ในการเข้าถึงต้องขึ้นต้นด้วย \"https: //\" หรือ \"http: //\"",
  "ccmanagement.satisfaction.strategy.acceptdate.dateStart": "วันที่เริ่มต้น",
  "ccm.agent.ten.tenantName": "ชื่อผู้เช่า",
  "ccm.calledconfig.label.querysoftnum": "ดูหมายเลขซอฟต์โฟน",
  "ccm.callreason.label.createrelatecase": "กรณีศึกษาที่เกี่ยวข้อง",
  "ccmanagement.satisfaction.strategy.fail.accepttime.startafterend": "เวลาเริ่มต้นต้องเร็วกว่าเวลาสิ้นสุด",
  "ccm.agent.updateSoftPhone.updateSuccess": "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
  "ccm.agent.input.userNumber": "หมายเลขผู้ใช้",
  "ccmanagement.satisfaction.strategy.fail.calltime.startafterend": "ระยะเวลาการโทรขั้นต่ําต้องน้อยกว่าระยะเวลาการโทรสูงสุด",
  "ccm.skill.placeholder.skilldesc": "โปรดป้อนชื่อคิวทักษะ",
  "ccm.sysparam.error.validate.notmatchruleip": "โปรดป้อนที่อยู่ IPV4 หรือ IPV6 ที่ถูกต้อง",
  "ccm.contact.label.signed": "เซ็นแล้ว",
  "ccm.ucconfig.message.failed": "ล้มเหลว",
  "ccm.pageurls.select.internalInterface": "หน้าจอภายใน",
  "ccm.agent.calledroute.refreshsuccess": "ีเฟรชสําเร็จ",
  "ccm.transtask.label.ivrAccessCodeReport": "รายงานสถิติข้อมูล IVR ตามรหัสการเข้าถึง",
  "ccm.contact.customer.config.writeBackField": "ขยายเขตข้อมูลการเขียนบันทึกการติดต่อ",
  "ccm.verifyidentity.label": "การยืนยันตัวตน",
  "ccm.agent.calledroute.ccivrdata.error": "ไม่สามารถซิงโครไนซ์ข้อมูลกับ NMS ข้อมูลกระบวนการ CC-IVR ไม่ถูกต้อง",
  "ccm.certificate.management.search.certcode": "โปรดป้อนรหัสใบรับรอง",
  "ccm.systemparam.error.reset": "ล้มเหลวในการรีเซ็ตพารามิเตอร์",
  "ccm.sysparam.config.item.detail": "ข้อมูลพารามิเตอร์",
  "ccm.agent.contact.multimedia.facebook": "มัลติมีเดีย-Facebook ",
  "ccm.certificate.management.certificate.tip": "เคล็ดลับ:",
  "ccm.agent.pageurls.sernum": "เลขที่",
  "ccm.whatsapp.whatsApp.message.template": "ข้อความเทมเพลต WhatsApp",
  "ccm.agent.message.aiagentclosed": "คุณสมบัติการระบุผู้เช่าอัจฉริยะถูกปิดใช้งาน ติดต่อผู้ดูแลระบบ",
  "ccm.agent.callreason.exceedmaxnum": "อนุญาตให้มีเหตุผลการโทรได้สูงสุด 5 เหตุผล",
  "ccm.agent.label.configAdmin": "ผู้ดูแลการตั้งค่า",
  "ccm.satisfactionconfig.message.satisreport": "รายงานการสำรวจความพึงพอใจ",
  "ccm.agent.label.maxTalkTimeRange": "ช่วงค่า: [1-86400]",
  "ccm.certificate.management.certificatefile.typeerror": "ประเภทไฟล์ไม่ถูกต้อง",
  "ccm.agent.button.save": "บันทึก",
  "ccm.agent.restReason.restCauseCode": "รหัสเหตุผลการพัก",
  "ccm.agent.message.agentandskillnotmatch": "ประเภททักษะต้องตรงกับประเภทเจ้าหน้าที่",
  "ccm.custdata.operlog.addExportTaskSuccess": "งานส่งออกใหม่สำเร็จ",
  "ccm.label.page.delete.confirm": "แน่ใจไหมว่าต้องการลบ?",
  "ccm.calledconfig.message.overmaxnum": "สูงสุด {0} ถึง",
  "ccm.custdata.option.maskStyle.maskIdCardNum": "หมายเลขบัตรประชาชน",
  "ccm.systemparam.success.reset": "รีเซ็ตพารามิเตอร์สําเร็จ",
  "ccm.agent.updateSoftPhone.unFoundInfo": "คอลเซ็นเตอร์ไม่ซิงโครไนซ์",
  "ccm.sysparam.error.validate.notmatchrulerequired": "รายการที่ต้องการ",
  "ccm.custdata.operlog.uploadDataFile": "การอัปโหลดไฟล์ข้อมูลตารางพื้นฐาน",
  "ccm.whatsapp.select.whatsApp.channel": "โปรดเลือกช่อง WhatsApp",
  "ccm.transserver.label.proxynotinwhite": "การดําเนินการล้มเหลว โปรดติดต่อผู้ดูแลระบบเพื่อเพิ่มที่อยู่ของเซิร์ฟเวอร์พร็อกซีในรายการพร็อกซี่ของเซิร์ฟเวอร์ OBS",
  "ccm.agent.leavemessage.cannotBeModified": "ไม่สามารถแก้ไขข้อความในสถานะปัจจุบัน",
  "ccm.dual.call.record.endTime": "เวลาสิ้นสุดการโทร",
  "ccm.transtask.label.tasknameexits": "สร้างงานไม่สําเร็จเนื่องจากชื่องานมีอยู่แล้ว",
  "ccm.recognition.label.anonymous": "ธงการโทรออกที่ไม่ระบุชื่อ",
  "ccm.custdata.option.status.release": "ปล่อย",
  "ccm.contact.customer.config.key": "คีย์ข้อมูล",
  "ccm.custdata.msg.tableExistInstData": "ตารางที่กำหนดเองมีข้อมูลอินสแตนซ์และไม่สามารถถอนออกเพื่อแก้ไขได้",
  "ccm.transtask.config.pathvalidate": "พาธของไฟล์ดัมพ์ต้องไม่มี \"./\".",
  "ccm.custdata.operlog.downloadData": "การดาวน์โหลดข้อมูลตารางพื้นฐาน",
  "ccm.agent.label.queuingCallsMaxNumRangeTwo": "ช่วงค่า: [1-10000]",
  "ccm.speciallist.msg.sltype": "ประเภทรายการพิเศษ",
  "ccm.satisfactionlevel.message.resetsuccess": "Satisfaction levels and satisfaction survey templates are reset successfully. If a satisfaction process has been configured, release the process on the Process Management page. Otherwise, the satisfaction results will be incorrectly displayed. The new satisfaction survey will be based on the results of this configuration.",
  "ccm.speciallist.msg.userno.error": "รูปแบบการป้อนหมายเลขผู้ใช้ไม่ถูกต้อง",
  "ccm.systemparam.error.notmatchrule": "พารามิเตอร์ไม่ตรงกับกฎการตรวจสอบ",
  "ccm.agent.leavemessage.process": "กระบวนการ",
  "ccm.agent.label.skillQueue": "คิวทักษะ",
  "ccm.certificate.management.certificatefile.maxrecord": "จํานวนสูงสุดของใบรับรองคือ {0}",
  "ccm.contact.detail.sending.time": "ส่งเวลา",
  "ccm.agent.message.searchAgentServiceNo": "กรุณากรอกหมายเลขบริการของเจ้าหน้าที่",
  "ccm.agent.calledroute.create.gateway.none": "ไม่มีสคริปต์การกำหนดเส้นทางที่อ้างอิงโดยการตั้งค่าของบุคคลที่ไม่มีอยู่",
  "ccm.agent.label.sipAccount": "หมายเลขซอฟต์โฟน",
  "ccm.agent.updateSoftPhone.updateGatewayFailed": "เปลี่ยนรหัสผ่านเกตเวย์ไม่สำเร็จ",
  "ccm.agent.sysparamconfig.verifyTemplateIdLength": "ID เทมเพลตข้อความภายในต้องมี 18 อักขระ",
  "ccm.agent.message.validatordigit": "รหัสผ่านการตั้งค่าตัวแทนโทรศัพท์เดี่ยวควรเป็นตัวเลขเท่านั้น",
  "ccm.agent.leavemessage.messageprocess": "ประมวลผลข้อความ",
  "ccm.agent.label.agentType": "ประเภทเจ้าหน้าที่",
  "ccm.agent.message.deleteSkill": "ลบลำดับทักษะ",
  "ccm.agent.label.notselectcallreason": "โปรดเลือกเหตุผลการโทร",
  "ccm.recognition.label.orderfailwhilte": "การสมัครสมาชิกล้มเหลว ซิงโครไนซ์รายการที่เชื่อถือไม่สำเร็จ",
  "ccm.urlconfig.label.relurl": "วางสาย URL โทรกลับ",
  "ccm.agent.message.modifySkillFail": "อัปเดตทักษะล้มเหลว",
  "ccm.transtask.label.contactRecord": "บันทึกการติดต่อ",
  "ccm.transserver.discard.confirm": "คุณแน่ใจว่าต้องการทิ้งงานหรือไม่",
  "ccm.label.page.modifyPage": "ปรับเปลี่ยนหน้า",
  "ccm.satisfactionconfig.message.media": "ช่องทางแอป",
  "ccm.speciallist.msg.limit": "จํานวนเนื้อหาที่นําเข้าเกิน 1000",
  "ccm.satisfactionconfig.message.groupbatchdelete": "ลบแบทช์",
  "ccm.custdata.title.modify": "การแก้ไขตารางฐาน",
  "ccm.satisfactionconfig.web.switch.remark": "หลังจากเปิดสวิตช์นี้ การสํารวจความพึงพอใจจะถูกผลักไปยังลูกค้าหลังจากบริการเสร็จสมบูรณ์",
  "ccm.agent.label.authtype.uap": "การตรวจสอบความถูกต้อง UAP",
  "ccm.idauth.create.error.limit": "กระบวนการตรวจสอบสิทธิ์สนับสนุนการสร้างเพียงห้า",
  "ccm.whatsapp.template.message.id": "ID ข้อความของแม่แบบ",
  "ccm.agent.contact.talkReason": "เหตุผลในการโทร",
  "ccm.satisfactionconfig.message.createsuccess": "เพิ่มการกำหนดค่าแบบสำรวจความพึงพอใจเรียบร้อยแล้ว",
  "ccmanagement.satisfactionconfig.calltime.timeerror.laterThanEndTime": "ระยะเวลาการโทรขั้นต่ําต้องน้อยกว่าระยะเวลาการโทรสูงสุด",
  "ccm.transtask.label.restores": "เรียกคืน",
  "ccm.pageinterface.inputInterfaceName": "ป้อน",
  "ccm.agent.contact.startcallfailed": "เริ่มการโทรล้มเหลว",
  "ccm.agent.message.deleteSkillConfSuccess": "ลบข้อมูลลำดับทักษะสำเร็จแล้ว",
  "ccm.transtask.label.taskname": "ชื่องาน",
  "ccm.custdata.operlog.conditions": "เงื่อนไข",
  "ccm.label.contactexport.selectdata": "เลือกข้อมูล",
  "ccm.agent.message.agenttypecannotbenull": "เลือกประเภทเจ้าหน้าที่หากเลือกลำดับทักษะ",
  "ccm.agent.pageurlparam.relSysParamType": "ประเภทพารามิเตอร์ข้อมูลระบบที่เกี่ยวข้อง",
  "ccm.agent.label.inputendworkno": "กรุณาป้อน ID พนักงานปลายทาง",
  "ccm.agent.message.pageNameValidateFail": "ไม่อนุญาตให้ใช้อักขระพิเศษ",
  "ccm.agent.message.itemNameValidateFail": "ไม่อนุญาตให้ใช้อักขระพิเศษต่อไปนี้ &<>\";%/[]=+{'@'}",
  "ccm.calledconfig.message.willdeletemobileagent": "แน่ใจไหมว่าต้องการลบมือถือเจ้าหน้าที่ที่เลือก",
  "ccm.label.page.createPage": "เพจใหม่",
  "ccm.agent.sysparamconfig.refreshAllSysParamSuccess": "รีเฟรชสําเร็จ",
  "ccm.recognition.label.openmobileagentsuccess": "เปิดใช้งานมือถือเจ้าหน้าที่สำเร็จแล้ว!",
  "ccm.callreasonconfig.message.deletecallreason.failed": "ล้มเหลวในการลบ",
  "ccm.speciallist.msg.dateerror": "จําเป็นต้องป้อนเวลาที่มีผลบังคับใช้และเวลาหมดอายุ",
  "ccm.agent.contact.assistant": "รวมสาย",
  "ccm.custdata.operlog.authFailed": "การตรวจสอบสิทธิ์ล้มเหลว",
  "ccm.agent.label.useTheSkill": "คิวทักษะ {0} ถูกผูกไว้กับ ID เจ้าหน้าที่ {1} แล้ว ลบการกําหนดค่าของคิวทักษะภายใต้เอเจนต์ก่อน",
  "ccm.agent.sysparamconfig.worknowarn": "ใช้ลูกน้ำ (,) เพื่อแยกรหัสเจ้าหน้าที่",
  "ccm.agent.title.agentinfo": "ข้อมูลเจ้าหน้าที่",
  "ccm.agent.ten.agentSipInfo": "ที่อยู่ IP และหมายเลข Port ของเซิร์ฟเวอร์ลงทะเบียนตัวแทน",
  "ccmanagement.satisfaction.strategy.acceptdate.startdate": "วันที่เริ่มต้น",
  "ccm.satisfactionconfig.message.calltype": "ประเภทการโทร",
  "ccm.dual.call.record.option": "การดําเนินงาน",
  "ccm.botherlist.label.comment": "ความเห็น",
  "ccm.agent.contact.playVideo": "กําลังเล่นวิดีโอ",
  "ccm.callout.message.confreset": "รีเซ็ตยืนยัน",
  "ccm.custdata.label.isMandatory": "บังคับ",
  "ccm.note.config.agent.page.ring.title": "การกําหนดค่านี้จะมีผลเฉพาะเมื่อหน้าถูกซ่อนไว้ เช่น ย่อเบราว์เซอร์ หรือหน้าแท็บพื้นหลังเท่านั้น",
  "ccm.calledconfig.table.skillormumbers": "อุปกรณ์วัตถุประสงค์",
  "ccm.agent.restrecord.reststatus.normal.endrest": "การพักสิ้นสุดตามปกติ",
  "ccm.agent.label.playVoice": "เล่นเสียงรอ",
  "ccm.agent.calledroute.confdelete": "ยืนยันการลบ",
  "ccm.contact.label.unsigned": "ยังไม่ได้เซ็น",
  "ccm.satisfactionlevel.message.savefail": "ล้มเหลวในการบันทึกระดับความพึงพอใจ",
  "ccm.agent.label.createWorkOrdeType": "ประเภทเคสที่เกี่ยวข้อง",
  "ccm.transtask.label.datacondition": "เกณฑ์การกรองข้อมูล",
  "ccm.transtask.label.agentOutBoundReport": "รายงานสรุปการโทรออกของตัวแทน",
  "ccm.agent.message.warning": "คำเตือน",
  "ccm.agent.sysparamconfig.multimediawarn": "จำนวนข้อความแอปพร้อมกันต้องอยู่ในช่วง 1 ถึง 60",
  "ccm.systemparam.success.save": "บันทึกพารามิเตอร์สําเร็จ",
  "ccm.custdata.operlog.exportiong": "การส่งออก",
  "ccm.agent.calledroute.errorsave": "ข้อผิดพลาด",
  "ccm.agent.message.searchsoftphone": "ป้อนหมายเลขซอฟต์โฟน",
  "ccm.agent.contact.calltime": "ระยะเวลาการโทร",
  "ccm.agent.button.downloadvideoterminal": "ดาวน์โหลด OpenEye",
  "ccm.calledconfig.label.numbers": "ตัวเลข",
  "ccm.custdata.label.isIndex": "ใช้เป็นดัชนี",
  "ccm.agent.message.synToUapFailed": "การซิงโครไนซ์โหมดการตรวจสอบความถูกต้องของเอเจนต์กับ UAP ล้มเหลว",
  "ccm.sysparam.error.validate.notmatchrulemaxlength": "ความยาวพารามิเตอร์สูงสุด",
  "ccm.urlconfig.label.talkmethod": "วิธีการเชื่อมต่อ",
  "ccm.custdata.label.columnName": "ชื่อฟิลด์",
  "ccm.agent.message.nocomments": "ไม่มีความคิดเห็น",
  "ccm.agent.message.willDeleteAgents": "แน่ใจไหมว่าต้องการลบเจ้าหน้าที่ที่เลือก",
  "ccm.custdata.msg.columnNoMatch": "ฟิลด์ตารางไม่สอดคล้องกับข้อมูลในฐานข้อมูล รีเฟรชตารางแล้วลองอีกครั้ง",
  "ccm.transserverparam.label.info": "ข้อมูลพื้นฐาน",
  "ccm.agent.contact.recordingfiles": "การบันทึกไฟล์",
  "ccm.agent.message.selectMaxAgents": "คุณสามารถประมวลผลได้สูงสุด20ครั้งต่อครั้ง",
  "ccmanagement.satisfaction.strategy.fail": "Failure",
  "ccm.agent.message.existAIAgent": "ไม่สามารถเปิดใช้งานการจดจําอัจฉริยะและการบันทึกแบบคู่ในขณะเดียวกัน เอเย่นต์ที่เปิดใช้งานการจดจําอัจฉริยะมีอยู่ในกลุ่มตัวแทนที่เปิดใช้งานการจดจําอัจฉริยะ",
  "ccm.agent.label.fixedValue": "ค่าคงที่",
  "ccm.satisfactionconfig.message.delete": "ลบออก",
  "ccm.agent.operation.FORCELOGOUT": "บังคับให้ออกจากระบบ",
  "ccm.agent.mobileagent.notice": "ข้อสังเกต",
  "ccm.agent.operation.optime": "เวลาทำการ",
  "ccm.certificate.management.certificate.crlfile.sizeerror": "ไฟล์ CRL ต้องมีขนาดไม่เกิน 2048 KB",
  "ccm.agent.title.specialListReminder": "การแจ้งเตือนรายการพิเศษ",
  "ccm.recognition.label.fail": "ล้มเหลว",
  "ccm.certificate.management.certificatefile.certsceneerror": "ใบรับรองมีข้อผิดพลาด",
  "ccm.agent.calledroute.pagename": "ชื่อหน้า",
  "ccm.agent.leavemessage.channelType": "ประเภทช่องทาง",
  "ccm.satisfactionconfig.message.strategygroupsrv": "นโยบายการสํารวจความพึงพอใจ การกําหนดค่าช่องทาง",
  "ccm.transserverparam.label.agentport": "Port",
  "ccm.transserverparam.label.agentip": "ที่อยู่เซิร์ฟเวอร์พร็อกซี",
  "ccm.agent.label.dualChannelRecFlag": "การบันทึกแทร็คคู่",
  "ccm.custdata.operlog.operation": "ดำเนินการ",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.overlimit": "สามารถบันทึกนโยบายวันที่จัดการได้สูงสุด 100 รายการ",
  "ccm.agent.operation.HOLD": "ถือสาย",
  "ccm.custdata.option.columnType.time": "วันเวลา",
  "ccm.satisfactionconfig.message.duplicateconfig": "ลําดับความสําคัญของแต่ละนโยบายสําหรับช่องทางเดียวกันต้องไม่ซ้ํากัน",
  "ccm.botherlist.label.approveComment.approved": "ปฏิเสธ",
  "ccm.satisfactionconfig.message.callskill": "คิวทักษะ",
  "ccm.callreason.label.setcallreason": "การกำหนดค่าเหตุผลการโทร",
  "ccm.satisfactionconfig.message.audiofeedbacktype": "เสียง IVR",
  "ccm.callout.placeholder.caller": "โปรดป้อนหมายเลขโทรของสายออก",
  "ccm.speciallist.msg.level": "ระดับรายการพิเศษ",
  "ccm.basicdata.error.syserror": "ระบบผิดพลาด. ติดต่อเจ้าหน้าที่ซ่อมบำรุง",
  "ccm.certificate.management.certificatefile.uploadcertcrlfile": "อัปโหลดไฟล์ CRL",
  "ccm.satisfactionconfig.message.acceptagent": "ตัวแทนการจัดการ",
  "ccm.satisfactionconfig.message.channelstrategyconfig": "นโยบายการสํารวจความพึงพอใจของช่อง",
  "ccm.agent.resetskillrecord.adjustagentworkno": "การปรับ ID ตัวแทน",
  "ccm.speciallist.msg.alreadyexist": "มีการแจ้งเตือนรายการพิเศษประเภทและระดับเดียวกันอยู่แล้ว",
  "ccm.contactitemconfig.label.data.item.basicext": "ข้อมูลขยายพื้นฐาน",
  "ccm.callout.title.success": "สำเร็จ",
  "ccm.querycustdata.msg.selectTable": "เลือกชื่อตาราง",
  "ccm.custdata.label.template": "_เทมเพลต",
  "ccm.agent.label.registrationMessage": "ข้อมูลการลงทะเบียน",
  "ccm.transserver.label.serveraddr": "อีเมล์",
  "ccm.agent.button.submit": "ตกลง",
  "ccm.agent.calledroute.create.accesscode.noexist": "ไม่มีรหัสการเข้าถึงที่อ้างอิงโดยการกำหนดค่าบุคคลที่ไม่มีอยู่",
  "ccm.custdata.option.isMandatory.yes": "ต้องมี",
  "ccm.note.config.filepath": "เลือกไฟล์บันทึกย่อ",
  "ccm.agent.contact.multimedia.telegram": "มัลติมีเดีย-Telegram",
  "ccm.verifyidentity.verifyTime": "ตรวจสอบเวลา",
  "ccm.veridentity.fail.CALLIDPARSEERROR": "SN การโทรต้องเป็นตัวเลข",
  "ccm.agent.contact.playLeaveMessage": "เล่นข้อความต่อไปนี้",
  "ccm.datatask.checkNum": "ค่านี้เป็นจํานวนเต็มบวก",
  "ccm.agent.Transfer.Intercept": "การดึงสาย",
  "ccm.satisfactionconfig.message.smschannel": "ช่องกด SMS",
  "ccmanagement.satisfaction.strategy.calltime.maxvalue": "ระยะเวลาการโทรสูงสุด",
  "ccm.agent.conditionOfBusyTransfer.error": "กําหนดค่าเงื่อนไขการส่งต่อไม่ว่าง",
  "ccm.agent.resetskillrecord.skillbeforeadjust": "คิวทักษะก่อนปรับ",
  "ccm.agent.label.mobileagententer": "ป้อน",
  "ccm.agent.label.expired": "หมดอายุ",
  "ccm.agent.pageurls.createcall": "หน้าจอสายเรียกโทรเข้า",
  "ccm.agent.pageurls.config.screen.pop.up": "หน้าจอการตั้งค่าป็อปอัพ",
  "ccm.agent.pageurls.unsaved.data.exists":"มีข้อมูลที่ยังไม่ได้บันทึกอยู่ เก็บไว้ก่อนเถอะ.",
  "ccm.agent.label.preVoiceSelect": "ประกาศก่อนแจ้งรหัสตัวแทน",
  "ccm.accesscode.label.accesscode": "หมายเลขช่องทางการเข้าถึง",
  "ccm.speciallevel.speciallist.levelMessage": "การบริหารจัดการระดับ",
  "casemanagement.user.title.name": "ชื่อผู้ใช้",
  "ccm.satisfactionconfig.message.confirm": "ยืนยัน",
  "ccm.note.config.agent.page.ring.tipTitle": "หากกําหนดค่าการแจ้งเตือนกล่องป๊อปอัป ปุ่มป๊อปอัปจะทํางานเมื่อมีการผสานรวมอยู่ ให้นั่งบนเบราเซอร์ด้วยตนเอง \"อนุญาตให้เว็บไซต์นี้ใช้ป๊อปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัปอัป",
  "ccm.speciallist.tip.startTime.min": "เวลาที่มีผลบังคับใช้ต้องมากกว่าเวลาปัจจุบัน",
  "ccm.agent.label.usedMode": "ประเภท",
  "ccm.agent.calledroute.operation": "การดำเนินการ",
  "ccm.agent.title.batchagentEdit": "การกำหนดค่าข้อมูลเจ้าหน้าที่แบทช์",
  "ccm.contactitemconfig.label.data.item.category": "ประเภทข้อมูล",
  "ccm.agent.contact.allcalltype": "ประเภทการโทรทั้งหมด",
  "ccm.basicdata.msg.nodatamodify": "ไม่จำเป็นต้องแก้ไขข้อมูล",
  "ccm.basicdata.msg.modifyfail": "แก้ไขข้อมูลพื้นฐานไม่สำเร็จ",
  "ccm.speciallist.msg.approve": "การอนุมัติ",
  "ccm.speciallist.label.approve": "ความเห็นในการอนุมัติ",
  "ccm.speciallist.poptitle.approve": "กําลังอนุมัติบันทึกการล่วงละเมิด",
  "ccm.speciallist.poptitle.view": "การดูบันทึกการล่วงละเมิด",
  "ccm.speciallist.title.approve": "ข้อมูลการอนุมัติ",
  "ccm.agent.button.clear": "รีเซ็ท",
  "ccm.basicdata.error.databaseerror": "ข้อมูลดัชนีซ้ำกัน ด้วยเหตุนี้จึงไม่สามารถนำเข้าข้อมูลไปยังฐานข้อมูลเป็นชุดได้",
  "ccm.recognition.label.openanonymoussuccess": "สําเร็จในการเปิดใช้งานธงการโทรออกที่ไม่ระบุชื่อ เลือกรหัสการเข้าถึงที่คลิกเพื่อโทรที่กําหนดค่าสําหรับบุคคลที่เรียก",
  "ccm.satisfactionconfig.message.channeltype": "ประเภทของช่อง",
  "ccm.sysparam.error.validate.notmatchrulemin": "พารามิเตอร์อินพุตต่ำกว่าค่าต่ำสุด",
  "ccm.satisfactionconfig.param.faile": "พารามิเตอร์ที่ไม่ถูกต้อง",
  "ccm.agent.updateSoftPhone.updateFailed": "ล้มเหลวในการเปลี่ยนรหัสผ่าน",
  "ccm.agent.calcOfMaxCallNum.error": "กําหนดค่าจํานวนสูงสุดของการโทรเข้าคิว",
  "ccm.contact.tip.not.get.workname": "ไม่ได้รับชื่อบัญชีที่สอดคล้องกันสำหรับที่นั่ง！",
  "ccm.contactitemconfig.message.DatasetName.inuse": "ไม่สามารถลบชุดข้อมูลได้เนื่องจากถูกกำหนดค่าไว้ในรายการข้อมูลบันทึกการติดต่อแล้ว",
  "ccm.sysparam.url.safe.tip": "การใช้โปรโตคอลที่ไม่ปลอดภัย HTTP มีความเสี่ยงด้านความปลอดภัย",
  "ccm.agent.label.keepWaitType": "วิธีที่จะทําให้การรอคอย",
  "ccm.transtask.label.tasktype": "ประเภท",
  "ccm.veridentity.workNo": "รหัสตัวแทน",
  "casemanagement.common.label.nodata": "ไม่มีข้อมูล.",
  "ccm.agent.label.Account": "ชื่อบัญชี",
  "ccm.custdata.option.columnType.date": "วันที่",
  "ccm.callout.lable.type": "ประเภทพารามิเตอร์",
  "ccm.agent.calledroute.create.media.match": "รหัสการเข้าถึงระบบที่กำหนดค่าสำหรับบุคคลที่เรียกนั้น แตกต่างจากประเภทสื่อที่กำหนดค่าสำหรับบุคคลที่ถูกเรียก",
  "ccm.agent.label.noaireconition": "เท็จ",
  "ccm.agent.label.maxWaitTime": "ค่าสูงสุด รอเวลา(วินาที)",
  "ccm.transserver.label.cssnotinwhite": "การดําเนินการล้มเหลว โปรดติดต่อผู้ดูแลระบบเพื่อเพิ่มที่อยู่บริการ CSS ลงในรายการที่เชื่อถือได้ของเซิร์ฟเวอร์ CSS ที่ถ่ายโอนทรัพยากร",
  "ccm.chatbot.label.assist": "ผู้ช่วยหุ่นยนต์",
  "ccm.verifyidentity.loginId": "บัญชีธุรกิจ",
  "ccm.custdata.button.moveTop": "ขึ้นบนสุด",
  "ccm.agent.message.agentUpdateFail": "บันทึกการอัปเดตล้มเหลว",
  "ccm.transtask.label.task.chatrecordall.uniqueness": "คุณไม่สามารถเพิ่มประเภทช่องทั้งหมดได้เนื่องจากมีงานสําหรับบางประเภทช่อง",
  "ccm.satisfactionconfig.message.strategygroupdesc": "คําอธิบายกลุ่มนโยบาย",
  "ccm.agent.message.clear": "ลบทั้งหมด",
  "ccm.agent.restrecord.rest.reason": "เหตุผลในการพัก",
  "ccm.skill.label.skilldesc": "คำอธิบาย",
  "ccm.pageinterface.interfaceType.internal": "อินเทอร์เฟซภายใน",
  "ccm.calledroute.button.finish": "เสร็จสมบูรณ์",
  "ccm.agent.label.operate": "การดำเนินการ",
  "ccm.transtask.label.ivrReport": "รายงานข้อมูล IVR",
  "ccm.agent.contact.contactno": "เบอร์ติดต่อ",
  "ccm.agent.resetskillrecord.adjustedbusinessaccount": "บัญชีบริการที่ปรับแล้ว",
  "ccm.agent.title.agentpassword": "รหัสผ่านบัญชี",
  "ccm.agent.title.serviceAccount": "บัญชีบริการคลาวด์ที่เข้าร่วม",
  "ccm.urlconfig.label.oauth.ak": "อาก้า",
  "ccm.agent.button.delete": "ลบ",
  "ccm.vcallcenter.config.popup.authentication": "รับรองความถูกต้อง",
  "ccm.note.config.searchnotename": "กรุณาใส่ชื่อ",
  "ccm.custdata.operlog.addExportTaskError": "งานส่งออกใหม่ล้มเหลวกรุณาตรวจสอบการบันทึก",
  "ccm.sysparam.error.validate.notmatchruleurl": "โปรดป้อนที่อยู่ URL ที่ถูกต้อง",
  "ccm.agent.restReason.create": "เพิ่ม",
  "ccm.agent.softPhoneStatus": "สถานะหมายเลขโทรศัพท์",
  "ccm.anonymous.message.fail.save": "ล้มเหลวในการบันทึกเส้นทางที่เรียก โปรดตรวจสอบการกําหนดค่า CTI",
  "ccm.agent.label.video": "เจ้าหน้าที่วิดีโอ",
  "ccm.sysparam.error.validate.notmatchrulemax": "พารามิเตอร์อินพุตเกินค่าสูงสุด",
  "ccm.agent.title.specialListHis": "ประวัติรายการพิเศษ",
  "ccm.custdata.operlog.specCharValidate": "ไม่สามารถมีอักขระพิเศษยกเว้น _ @ %",
  "ccm.agent.message.selectAgents": "กรุณาเลือกเจ้าหน้าที่",
  "ccm.agent.contact.joinreason": "เหตุผลในการเข้าร่วม",
  "ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE": "ไม่สามารถดาวน์โหลดไฟล์บันทึกจากเซิร์ฟเวอร์ SFTP",
  "ccm.agent.message.redislocknull": "ไม่สามารถรับ Redis lock",
  "ccm.sysparam.error.validate.inner": "ตรวจสอบพารามิเตอร์ล้มเหลว",
  "ccm.sysparam.config.reset": "รีเซ็ต",
  "ccm.urlconfig.message.deletefail": "รีเซ็ต URL ไม่สำเร็จ",
  "ccm.agent.label.waitAgentWeight": "ป้อนน้ำหนักเจ้าหน้าที่",
  "ccm.agent.contact.info": "ข้อมูลเอกสาร",
  "ccm.calledroute.message.selectcalledroute": "กรุณาเลือกการกำหนดค่าปาร์ตี้",
  "ccm.agent.login.LOGIN": "เข้าสู่ระบบ",
  "ccm.custdata.msg.operationSuccess": "การดำเนินการสำเร็จ",
  "ccm.agent.contact.recordInfo": "การบันทึกรายละเอียดไฟล์",
  "ccm.agent.operation.CANCELLISTEN": "ยกเลิกการฟัง",
  "ccm.agent.mobileagent.success": "สำเร็จ",
  "ccm.agent.contact.SFTP_INCORRECT_FILE_CODE": "ข้อมูลเซิร์ฟเวอร์ sftp ไม่ถูกต้อง",
  "ccm.agent.pageurls.whitelisturl": ". กรุณาติดต่อผู้ดูแลระบบเพื่อเพิ่ม url ในรายการที่ไว้วางใจ.",
  "ccm.agent.ten.cobrowseMaxNumber": "จํานวนสูงสุดของการเชื่อมต่อการทํางานร่วมกันของหน้าเว็บ",
  "ccm.idauth.title.ivr": "กระบวนการตรวจสอบ",
  "ccm.speciallist.msg.selectslreminder": "เลือกการแจ้งเตือนรายการพิเศษ",
  "ccm.agent.contact.email": "อีเมล",
  "ccm.agent.contact.Email": "Email",
  "ccm.pageurls.select.webApi": "เว็บ API",
  "ccm.callreason.label.name": "ชื่อ",
  "ccm.agent.calledroute.createcalledroute": "สร้าง",
  "ccm.taskrecord.label.executing": "กําลังดําเนินการ",
  "ccm.recognition.label.appSecret.download.SK.not.exist": "ไม่มีข้อมูล SK",
  "ccm.skill.label.maxwaittime": "สูงสุด ระยะเวลาในการจัดลำดับ",
  "ccm.agent.label.skillpara": "การกำหนดพารามิเตอร์ของทักษะ",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.yes": "ใช่",
  "ccm.agent.contact.subtype.none": "ไม่มี",
  "ccm.agent.leavemessage.lengthcodewarn": "ข้อความสามารถมีอักขระได้สูงสุด 300 ตัว",
  "ccm.agent.mobileagent.confirm": "ยืนยัน",
  "ccm.recognition.label.save": "บันทึก",
  "ccm.agent.updateSoftPhone.unErrorInfo": "ข้อยกเว้นของระบบ",
  "ccm.basicdata.msg.error": "ข้อผิดพลาด",
  "ccm.custdata.operlog.passValidateFailed": "ต้องประกอบด้วยตัวอักษรตัวเลขอักขระพิเศษ _ {'@'} {'%'}",
  "ccm.agent.message.createSkillConfSuccess": "สร้างข้อมูลลำดับทักษะสำเร็จ",
  "ccm.dual.call.record.statistics": "สถิติ",
  "ccm.agent.ccagent.qcmodify.fail": "เลือกประเภทตัวแทนเสียง",
  "ccm.agent.contact.norecording": "ไม่มีการบันทึก",
  "ccm.speciallist.msg.delete": "ลบออก",
  "ccm.satisfactionconfig.message.strategychoosed": "นโยบายที่เลือก",
  "ccm.pageinterface.selectAttributeTitle": "เลือก Object Attribute",
  "ccm.agent.pageurls.movedown": "ลง",
  "ccm.agent.label.maxWaitTimeSpan": "ค่า [1–60000]",
  "ccm.custdata.msg.dictionaryInputFormat": "รูปแบบ: คีย์ 1: 1ค่า, คีย์ 2: 2 ค่า",
  "ccm.speciallist.msg.empty": "ข้อความเตือนความจําต้องไม่เป็นโมฆะ",
  "ccm.contactitemconfig.message.confOnlyDataItemName": "การดำเนินการล้มเหลวเนื่องจากชื่อรายการข้อมูลนี้มีอยู่แล้ว.",
  "ccm.agent.pageurls.confnaming": ". ตรวจสอบให้แน่ใจว่าไม่มีชื่ออยู่ในระบบ.",
  "ccm.agent.login.FORCELOGIN": "บังคับให้ลงชื่อเข้าใช้",
  "ccm.transtask.label.pause": "หยุดชั่วคราว",
  "ccm.agent.pageurlparam.urlParamName": "พารามิเตอร์ URL Pop-up ของหน้าจอ",
  "ccm.agent.accesscode.n400number": "หมายเลขบริการ",
  "ccm.custdata.button.withdraw": "ถอน",
  "ccm.ucconfig.message.success": "ความสําเร็จ",
  "ccm.satisfactionconfig.satisfaction.switch": "ผู้โดยสาร",
  "ccm.agent.label.off": "ปิด",
  "ccm.calledconfig.message.casetype": "ประเภทเคส",
  "ccm.satisfactionconfig.message.configstrategydata": "กําหนดค่าข้อมูลนโยบาย",
  "ccm.speciallist.label.blocklist": "รายการที่ถูกบล็อก",
  "ccm.agent.contact.agent.recall": "เจ้าหน้าที่ได้ถอนข้อความ",
  "ccm.agent.contact.customername": "ชื่อลูกค้า",
  "CCM.AGENT.CONTACT.ORG": "องค์กรตัวแทน",
  "ccm.agent.calledroute.placeholder.ivr": "กรุณาเลือก IVR",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputparse": "วันที่เริ่มต้นหรือสิ้นสุดอยู่ในรูปแบบที่ไม่ถูกต้อง",
  "ccm.agent.operation.ID": "หมายเลขการดำเนินการ",
  "ccm.agent.operation.HELP": "ปรึกษา",
  "ccm.speciallist.button.cancel": "ยกเลิก",
  "ccm.agent.leavemessage.status.inProcess": "กำลังประมวลผล",
  "ccm.certificate.management.certificate.crlfile.crlfileinvalid": "รูปแบบไฟล์ CRL ไม่ถูกต้อง",
  "ccm.agent.button.ok": "ตกลง",
  "ccmanagement.satisfaction.strategy.success": "Success",
  "ccm.custdata.button.release": "ปล่อย",
  "ccm.agent.message.samePageNameFail": "ชื่อหน้าซ้ำกัน",
  "ccm.satisfactionlevel.message.savesuccess": "The satisfaction level and satisfaction survey template are saved successfully. If the satisfaction process has been configured, release the process on the Process Management page. Otherwise, the satisfaction result will be incorrectly displayed. The new satisfaction survey will be based on the results of this configuration.",
  "ccm.custdata.option.importFail": "ล้มเหลว",
  "ccm.satisfactionconfig.message.staistip1": "หลังจากเปิดสวิตช์นี้ ลูกค้าจะถูกโอนไปยังแบบสํารวจความพึงพอใจหลังจากตัวแทนวางสาย",
  "ccm.agent.mobileagent.fail": "ล้มเหลว",
  "ccm.label.contactexport.parainnull": "เลือกข้อมูลที่จะส่งออก",
  "ccm.agent.restReason.operation": "การดำเนินการ",
  "ccm.agent.videoDownload.noPermission": "เจ้าหน้าที่ไม่ได้รับอนุญาตให้ดาวน์โหลด",
  "ccm.custdata.option.isEncrypt.no": "ไม่ใช่",
  "ccm.satisfactionconfig.message.success": "สำเร็จ",
  "ccm.speciallist.operlog.downloadtemplate": "ดาวน์โหลดเทมเพลต",
  "ccm.satisfactionconfig.message.strategygroupmemchoose": "เลือกนโยบาย",
  "ccm.custdata.msg.indexColumnValidateFailed": "ฟิลด์ที่ใช้เป็นดัชนีเป็นข้อมูลบังคับและไม่สามารถเข้ารหัสได้",
  "ccm.custdata.operlog.viewExportTask": "ส่งออกมุมมองงาน",
  "ccm.satisfactionconfig.message.surveyconfig": "การกำหนดค่าแบบสำรวจ",
  "ccm.agent.message.modifymobileagentfail": "ไม่สามารถแก้ไขข้อมูลเกี่ยวกับหมายเลขโทรศัพท์มือถือของเจ้าหน้าที่",
  "ccm.ivr.label.processfilename": "ชื่อไฟล์",
  "ccm.custdata.msg.tableBeUsed": "ตารางที่กำหนดเองอ้างอิงโดยออบเจ็กต์หน้าการตั้งค่า ตรวจสอบให้แน่ใจว่าไม่ได้อ้างอิงตารางก่อนดำเนินการนี้",
  "ccm.agent.contact.agenthungup": "เจ้าหน้าที่",
  "ccm.agent.message.typenosame": "ประเภทลำดับทักษะต้องเหมือนกัน",
  "ccm.agent.contact.contactinfo": "ข้อมูลการติดต่อ",
  "ccm.agent.leavemessage.paramisempty": "พารามิเตอร์ว่างเปล่า.",
  "ccm.agent.message.queryctiagentmessage": "ไม่พบข้อมูลเจ้าหน้าที่ใน CTI",
  "ccm.agent.calledroute.delsuccess": "ลบเรียบร้อยแล้ว",
  "ccm.satisfactionconfig.message.webfeedbacktype": "Web",
  "ccm.agent.operation.SAYFREE": "ว่าง",
  "ccm.callout.message.choosecaller": "กรุณาเลือกผู้โทร",
  "ccm.satisfactionconfig.message.smsgateway": "เกตเวย์ SMS",
  "ccm.contactitemconfig.message.init.basicdata.success": "เริ่มต้นข้อมูลพื้นฐานเรียบร้อยแล้ว.",
  "ccm.agent.ccagent.modify.fail": "ไม่สามารถเปิดใช้งานการรับรู้อัจฉริยะและการบันทึกแบบคู่ในขณะเดียวกัน",
  "ccm.agent.label.mediaagent": "เจ้าหน้าที่มัลติมีเดีย",
  "ccm.anonymous.message.fail.cti": "ล้มเหลวในการสอบถามการโทรออกที่ไม่ระบุชื่อบน CTI",
  "ccm.custdata.label.relaBaseColumn": "ฟิลด์ในตารางอ้างอิง",
  "ccm.agent.contact.invalid": "เป็นโมฆะ",
  "casemanagement.user.title.select": "เลือก",
  "ccm.custdata.msg.notAllowOperation": "ไม่อนุญาตให้ดำเนินการนี้ รีเฟรชหน้านี้แล้วลองอีกครั้ง",
  "ccm.basicdata.error.suffix": ".",
  "ccm.agent.label.paramsConfig": "การกำหนดค่าพารามิเตอร์ระบบ",
  "ccm.agent.operation.noMoreThan90Days": "ช่วงเวลาต้องไม่เกิน 90 วัน",
  "ccm.agent.contact.workno": "ID พนักงาน",
  "ccm.agent.label.answerType": "ประเภทคำตอบ",
  "ccm.agent.verify.fail": "การยืนยันล้มเหลว",
  "ccm.note.config": "การกำหนดค่าเสียงรอสาย",
  "ccm.note.select": "เลือกโทนเสียงเรียกเข้า",
  "ccm.agent.restReason.restCauseTime.limit": "ระยะเวลาพักต้องมากกว่า 0 วินาทีและน้อยกว่า 24 ชั่วโมง",
  "ccm.skillcreate.maxnumber.failed": "จำนวนลำดับทักษะของผู้เช่าต้องไม่เกินขีด จำกัด: ",
  "ccm.taskrecord.label.executeStatus": "สถานะงาน",
  "ccm.pageinterface.interfaceName": "ชื่ออินเทอร์เฟซ",
  "ccm.verifyidentity.fail.VERIFYTIMERANGEERROR": "ช่วงเวลาไม่ถูกต้องสำหรับการสืบค้น",
  "ccm.page.label.status": "สถานะของหน้า",
  "ccm.agent.operation.UNMUTE": "เปิดเสียง",
  "ccm.sysparam.error.save": "ข้อผิดพลาด",
  "ccm.ivrflow.placeholder.ivrflowdesc": "ป้อนคําอธิบายกระบวนการ",
  "ccm.agent.message.redisFailed": "ล้มเหลวในการรับล็อค Redis",
  "ccm.calledconfig.message.deletemobileagentfail": "ลบการเชื่อมโยงของการตั้งค่าเจ้าหน้าที่มือถือไม่สำเร็จ",
  "ccm.recognition.label.clicktocallaccesscode": "รหัสเข้าถึงคลิกเพื่อโทร",
  "ccm.whatsapp.select.whatsApp.message.template": "โปรดเลือกข้อความเทมเพลต WhatsApp",
  "ccm.sysparam.error.validate.notmatchrulenumber": "โปรดป้อนตัวเลข",
  "ccmanagement.satisfaction.strategy.save": "Save",
  "ccm.agent.pageurls.errorinfo": "การสร้างล้มเหลว.",
  "ccm.agent.operation.SWITCH": "สลับสถานะให้เจ้าหน้าที่",
  "ccm.label.contactexport.selectrange": "เลือกช่วง",
  "ccm.transserver.delete.time.fail": "ลบงานสิบนาทีหลังจากทิ้งงาน",
  "ccm.transserver.label.css": "เซิร์ฟเวอร์ CSS",
  "ccm.agent.label.serviceAccountId": "ID บัญชีบริการคลาวด์ที่เกี่ยวข้อง",
  "ccm.agent.message.deleteAgentSkillFail": "ลบการเชื่อมโยงของลำดับทักษะเจ้าหน้าที่ไม่สำเร็จ",
  "ccmanagement.satisfaction.strategy.hourformatvalidate": "ชั่วโมงอยู่ในรูปแบบที่ไม่ถูกต้อง",
  "ccm.agent.message.isornoairecognition": "สถานะอัจฉริยะ",
  "ccm.agent.ten.phonenumber": "เบอร์มือถือ",
  "ccm.page.select.tileLayout": "เค้าโครงตาราง",
  "ccm.agent.label.sensitiveWordNumber": "คําต้องห้าม",
  "ccm.agent.sysparamconfig.worknorangewarn": "ID เจ้าหน้าที่ต้องอยู่ในช่วง 101 ถึง 50000",
  "ccm.satisfactionconfig.message.strategygroupid": "รหัสกลุ่มนโยบาย",
  "ccm.satisfactionconfig.ivr.voice.satisfaction.survey": "แบบสํารวจความพึงพอใจด้านเสียงของ IVR",
  "ccm.agent.ten.recordtime": "ระยะเวลาการเก็บบันทึกเสียง",
  "ccm.agent.operation.FORCEDROPCALL": "บังคับให้ออกจากการสนทนา",
  "ccm.agent.agentcontact.nosearch": "ไม่สามารถสอบถามได้ในการจัดการที่ไม่ใช่ธุรกิจ",
  "ccm.agent.recall.agent.calltype.invalid": "ประเภทการโทรปัจจุบันไม่รองรับการโทรกลับ",
  "ccm.agent.ten.vdnid": "รหัส VDN",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkLimit": "ห้ามเกินห้าที่นั่ง",
  "ccm.contactitemconfig.message.itemValidateall": "ใส่เครื่องหมายดอลลาร์ (_), เครื่องหมายขีดล่าง ตัวอักษรหรือตัวเลข ($)",
  "ccm.note.config.policy": "เอเจนต์ริงแบ็คโทน",
  "ccm.speciallist.msg.disable.fail": "การปิดใช้งานล้มเหลว",
  "ccm.agent.label.ordinaryAgent": "เจ้าหน้าที่ทั่วไป",
  "ccm.agent.label.paramValue": "ค่า",
  "ccm.transserverparam.label.cssClusterPass": "รหัสผ่าน",
  "ccm.agent.label.agentWeight": "น้ำหนักของทักษะ",
  "ccm.satisfactionlevel.message.resetfail": "ล้มเหลวในการรีเซ็ตระดับความพึงพอใจ",
  "ccm.agent.calledroute.recordexist": "รหัสการเข้าถึงถูกผูกไว้กับรหัสส่วนขยาย กรุณาป้อนรหัสส่วนขยายใหม่",
  "ccm.agent.contact.authHandle": "ติดต่อเจ้าหน้าที่บริหารที่เกี่ยวข้อง",
  "ccm.custdata.button.return": "กลับ",
  "ccm.agent.label.busyRate": "การใช้คิว (%)",
  "ccm.urlconfig.message.talkurlnotinwhite": "การดําเนินการล้มเหลว โปรดติดต่อผู้ดูแลระบบเพื่อเพิ่ม URL การโทรกลับไปยังรายการการโทรกลับ",
  "ccm.agent.calledroute.edit": "แก้ไข",
  "ccm.satisfactionconfig.message.prioritytip": "* ค่าที่น้อยกว่าแสดงถึงความสําคัญที่สูงขึ้น นโยบายที่มีลําดับความสําคัญสูงกว่าจะได้รับการจับคู่เป็นพิเศษเพื่อดําเนินการสํารวจ",
  "casemanagement.user.button.cancel": "ยกเลิก",
  "ccm.custdata.option.isEncrypt.yes": "ใช่",
  "ccm.custdata.label.failNum": "จำนวนบันทึกที่ล้มเหลว",
  "ccm.custdata.label.dualCall.sucNum": "จำนวนบันทึกที่สำเร็จ",
  "ccm.custdata.label.dualCall.failNum": "จำนวนบันทึกที่ล้มเหลว",
  "ccm.speciallist.msg.deleteyerror": "ไม่สามารถลบรายการพิเศษได้",
  "ccm.contact.customer.config.italic": "ตัวเอียง",
  "ccm.agent.calledroute.delete.skill.use": "การกำหนดค่าปาร์ตี้ที่เรียกว่าถูกอ้างอิงโดยลำดับทักษะและไม่สามารถลบได้",
  "ccm.transtask.label.sunday": "วันอาทิตย์",
  "ccm.agent.label.waitVoiceDefault": "ค่าปริยายของเสียงรอรับสาย",
  "ccmanagement.satisfaction.strategy.calltime.interval": "ช่วงระยะเวลาการโทร",
  "ccm.urlconfig.message.relurlnotinwhite": "การดําเนินการล้มเหลว โปรดติดต่อผู้ดูแลระบบเพื่อเพิ่ม URL การโทรกลับไปยังรายการการโทรกลับ",
  "ccm.speciallevel.speciallist.restrictHours": "ระยะเวลาที่จํากัด (ชั่วโมง)",
  "ccm.agent.contact.multimedia.twitter": "มัลติมีเดีย-X (Twitter)",
  "ccm.satisfactionconfig.select.ivr.process": "โปรดเลือกแบบสํารวจความพึงพอใจของโฟลว์ IVR",
  "ccm.urlconfig.label.oauth.sk": "เอสเค",
  "ccm.agent.message.sysparamservicerr": "ข้อผิดพลาดของบริการภายใน กรุณาตรวจสอบบันทึก",
  "ccm.agent.message.download.err": "การดาวน์โหลดล้มเหลว ติดต่อวิศวกร O&M ของระบบ",
  "ccm.custdata.option.isIndex.no": "ไม่",
  "ccm.agent.accesscode.description.containspatial": "คำอธิบายรหัสการเข้าถึงต้องไม่มีอักขระพิเศษ% _ ^ & * < > $ /",
  "ccm.custdata.msg.confirmAbandon": "แน่ใจไหมว่าต้องการทิ้ง",
  "ccm.agent.calledroute.delete": "ลบ",
  "ccm.agent.restReason.restCauseTime": "ระยะเวลาพัก",
  "ccmanagement.satisfactionconfig.acceptdata.timeVerification": "เลือกวันที่ช้ากว่าเวลาปัจจุบันและเร็วกว่า 2038-01-19",
  "ccm.transserverparam.label.sk": "เอสเค",
  "ccm.vcallcenter.config.popup.current.user.locked.error": "ผู้ใช้ปัจจุบันถูกล็อกและจะปลดล็อก 10 นาทีต่อมา",
  "ccm.certificate.management.certificatefile": "ไฟล์ใบรับรอง",
  "ccm.sysparam.config.select.null": "โปรดเลือกพารามิเตอร์",
  "ccm.note.config.configsuccess": "กำหนดค่าความสำเร็จของบันทึก.",
  "ccm.certificate.management.warn.selectcerttype": "กรุณาเลือกประเภทใบรับรอง",
  "ccm.note.config.agent.page.ring.audition": "ลองดู",
  "ccm.transtask.label.reports": "รายงานการถ่ายโอนข้อมูล",
  "ccm.agent.calledroute.nmsconnect.fail": "เชื่อมต่อ NMS ล้มเหลว",
  "ccm.chatbot.pop.title": "เลือกหุ่นยนต์",
  "ccm.recognition.label.calledroutesuccess": "เส้นทางที่เรียกได้รับการกําหนดค่าเรียบร้อยแล้ว",
  "ccm.agent.contact.web": "Web",
  "ccm.agent.message.omsAgentTypeBatchCheck": "บทบาทหรือประเภทเจ้าหน้าที่ที่เลือกไม่ตรงกันเมื่อเปิดใช้งานเจ้าหน้าที่ (รหัสพนักงาน [{0}]) แบบออนไลน์",
  "ccm.speciallist.label.msgContent": "ลบข้อความ",
  "ccm.agent.message.willExportAgent": "ข้อความ คุณแน่ใจหรือไม่ว่าจะส่งออก",
  "ccm.calledconfig.message.deletemobileagent": "ลบ Mobile Agent",
  "ccm.agent.label.deviceUsageThreshold": "เกณฑ์การใช้อุปกรณ์ (%)",
  "ccm.agent.button.restSoftPhonePwd.tips": "การรีเซ็ตรหัสผ่านซอฟต์โฟนจะส่งผลต่อซอฟต์โฟนที่กำลังใช้อยู่ เจ้าหน้าที่จะต้องล็อกอินเข้าใหม่อีกครั้ง คุณแน่ใจหรือไม่ว่าต้องการรีเซ็ตรหัสผ่านซอฟต์โฟน?",
  "ccm.transtask.label.tuesday": "วันอังคาร",
  "ccm.agent.operation.MUTE": "ปิดเสียง",
  "ccm.custdata.msg.importDataFail": "นำเข้าข้อมูลไม่สำเร็จ",
  "ccm.agent.mobileagent.label.choosehint": "กรุณาเลือกเจ้าหน้าที่ก่อนลบ!",
  "ccm.skill.label.maxwaitnum": "ค่าสูงสุด ลำดับโทร",
  "ccm.recognition.label.closemobileagentsuccess": "ปิดการใช้งานเจ้าหน้าที่มือถือเรียบร้อยแล้ว!",
  "ccm.anonymous.message.fail.delete": "ล้มเหลวในการลบเส้นทางที่เรียก โปรดตรวจสอบการกําหนดค่า CTI",
  "ccm.sysparam.config.save": "บันทึก",
  "ccm.transserver.label.css.serveraddr": "CSS Address",
  "ccm.verifyidentity.fail.unknown": "การค้นหาล้มเหลว",
  "ccm.agent.restrecord.rest.duration": "ระยะเวลาพัก",
  "ccm.agent.operation.vdnNotEnabled": "ไม่ได้เปิดใช้งาน VDN",
  "ccm.sysparam.config.opterate": "ปฏิบัติการ",
  "ccm.callout.title.fail": "ล้มเหลว",
  "ccm.recognition.queryAK.error": "เกิดข้อผิดพลาดเมื่อสอบถามข้อมูลคีย์ของแอป",
  "ccm.satisfactionconfig.message.surveymethod": "วิธีการสำรวจ",
  "ccm.agent.label.theAgent": "เจ้าหน้าที่:",
  "ccm.operlog.dualcallexport.download": "ดาวน์โหลดบันทึกการโทรแบบสองทิศทาง",
  "ccm.callout.message.nocallers": "ไม่ได้เลือกผู้เรียกไฮไลต์",
  "ccm.agent.callacceptno.set": "การตั้งค่า",
  "ccm.agent.leavemessage.status.solved": "ประมวลผลเสร็จแล้ว",
  "ccm.recognition.queryAK.not.exist": "พื้นที่ผู้เช่าปัจจุบันไม่มีคีย์แอป",
  "ccm.agent.login.LOGOUT": "ออกจากระบบ",
  "ccm.contactitemconfig.message.confOnlyDatasetName": "การดำเนินการล้มเหลวเนื่องจากชื่อชุดข้อมูลนี้มีอยู่แล้ว.",
  "ccm.transserver.delete.success": "เซิร์ฟเวอร์ดัมพ์ถูกลบเรียบร้อยแล้ว",
  "ccm.agent.addbother.fail": "การเพิ่มล้มเหลว",
  "ccm.verifyidentity.workNo": "หมายเลขตัวแทน",
  "ccm.agent.contact.resourceloading": "กำลังโหลดทรัพยากร ...",
  "ccm.agent.message.searchTimeRemaining": "การหมดอายุของรหัสผ่าน",
  "ccm.transtask.label.cantDelete": "ไม่สามารถลบเซิร์ฟเวอร์ได้เนื่องจากเซิร์ฟเวอร์ถูกอ้างอิงโดยงานถ่ายโอนข้อมูลอยู่แล้ว",
  "ccm.agent.contact.reset": "รีเซ็ต",
  "ccm.custdata.option.isIndex.yes": "ใช่",
  "ccm.agent.operation.failCode": "รหัสข้อผิดพลาด",
  "ccm.agent.label.versatileagent": "เอเย่นต์ทั้งหมด",
  "ccm.speciallevel.tip.start": "ยืนยันการเปิดใช้งานหรือไม่",
  "ccm.ucconfig.message.urlTitle": "เปลี่ยนเส้นทาง URI ที่กําหนดค่าไปยังแพลตฟอร์ม Microsoft เพื่อยอมรับการตอบกลับการตรวจสอบความถูกต้อง:",
  "ccm.calledconfig.table.addnumbers": "เพิ่มหมายเลข",
  "ccm.agent.message.inSpecialStrFail": "ชื่อหน้าต้องไม่มีอักขระพิเศษ",
  "ccm.agent.ten.maxVideoAgents": "ปริมาณเจ้าหน้าที่วิดีโอ",
  "ccm.agent.message.ctipasswdless": "ความยาวรหัสผ่านต้องไม่น้อยกว่า 8",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnagetive": "ระยะเวลาการโทรต่ําสุดหรือสูงสุดต้องไม่น้อยกว่า 0",
  "ccm.chatbot.invalid.bot": "หุ่นยนต์นี้ใช้ไม่ได้",
  "ccm.urlconfig.label.relurl.voicemsg.alerttip": "หากเรียกใช้เพื่อสร้างอินเทอร์เฟซการแจ้งเตือนด้วยเสียง URL จะไม่ถูกส่งกลับเมื่อการแจ้งเตือนด้วยเสียงสิ้นสุด มิฉะนั้น URL ขาเข้าที่เข้ามาจะกลับไปยัง",
  "ccm.recognition.label.appSecret.download.SK.only.once.error": "หากคุณลืมคีย์ให้รีเซ็ตคีย์แล้วดาวน์โหลดอีกครั้ง",
  "ccm.sysparam.config.mod": "แก้ไข",
  "ccm.speciallist.msg.size": "ขนาดของไฟล์ที่จะนําเข้าเกิน 10 MB",
  "ccm.agent.contact.signaturedate": "วันที่ลงลายมือชื่อ",
  "ccm.recognition.label.closeIvrAgentSuccess": "ผู้ช่วยเจ้าหน้าที่เชี่ยวชาญถูกปิดใช้งานเรียบร้อยแล้ว",
  "ccm.transserver.label.css.edit": "การกําหนดค่าเซิร์ฟเวอร์การค้นหาบนคลาวด์",
  "ccm.agent.speciallist.addList": "เพิ่มประวัติสแปม",
  "ccm.speciallist.label.expirationDate": "เวลาหมดอายุ",
  "ccm.agent.label.queuingCallsMaxNum": "จํานวนสูงสุดของการโทรเข้าคิว",
  "ccm.transtask.label.datapath": "พาธของไฟล์การถ่ายโอนข้อมูล",
  "ccm.transtask.label.reportedir": "รายงานการถ่ายโอนข้อมูล",
  "ccm.agent.label.timeOut": "ตั้งค่า Skill Timeout",
  "ccm.sysparam.config.item.catalog": "ประเภทพารามิเตอร์",
  "ccm.agent.leavemessage.contactNumber": "เบอร์ติดต่อ",
  "ccm.agent.resetskillrecord.adjustedagentworkno": "ID ตัวแทนที่ปรับแล้ว",
  "ccm.agent.operation.searchworkno": "กรุณากรอก ID พนักงาน",
  "ccm.agent.contact.twitter": "X (Twitter)",
  "ccm.agent.ten.sipInfo": "ที่อยู่ SIP และพอร์ต",
  "ccm.certificate.management.certificatefile.parseerror": "ไม่สามารถแยกวิเคราะห์ใบรับรอง ตรวจสอบว่ารูปแบบใบรับรองหรือรหัสผ่านถูกต้องหรือไม่",
  "ccm.agent.login.logIP": "ที่อยู่ IP สำหรับลงชื่อเข้าใช้",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility": "บันทึกการติดต่อที่มองเห็นได้",
  "ccm.transtask.label.task.otheruniquene": "สามารถสร้างไฟล์เสียง ข้อมูลการตรวจสอบอัจฉริยะ หรือประเภทข้อมูล CDR ได้เพียงไฟล์เดียวสําหรับประเภทข้อมูลการถ่ายโอนข้อมูลเดียวกัน",
  "ccm.botherlist.label.approveComment.agree": "เห็นด้วย",
  "ccmanagement.satisfaction.strategy.acceptdate.dateEnd": "วันสิ้นสุด",
  "ccm.custdata.msg.confirmRelease": "แน่ใจไหมว่าต้องการเผยแพร่",
  "ccm.recognition.label.updatefail": "การอัปเดตผู้เช่าล้มเหลว",
  "ccm.agent.contact.audioclicktodial": "เสียงคลิกเพื่อโทร",
  "ccm.agent.ten.maxVideoCalls": "ค่าสูงสุด การโทรวิดีโอพร้อมกัน",
  "ccm.callout.message.editsuccess": "แก้ไขคำบรรยายภาพผู้โทรสำเร็จ",
  "ccm.agent.contact.customizes": "กำหนดเอง",
  "ccmanagement.satisfaction.strategy.calltime.minvalue": "ระยะเวลาการโทรขั้นต่ํา",
  "ccm.satisfactionconfig.message.strategygroup": "กลุ่มนโยบายการสํารวจความพึงพอใจ",
  "ccmanagement.satisfaction.strategy.delete": "Delete",
  "ccm.note.config.failed": "กำหนดค่าหมายเหตุล้มเหลว",
  "ccm.taskrecord.label.executeEndTime": "เวลาสิ้นสุดการดําเนินการ",
  "ccm.agent.contact.status": "สถานะเอกสาร",
  "ccm.speciallevel.speciallist.unuse": "ไม่พร้อมใช้งาน",
  "ccm.custdata.msg.fileNameValidateFail": "ชื่อไฟล์ต้องประกอบด้วยตัวอักษรจีน, ตัวพิมพ์เล็ก, ตัวเลข หรือขีดล่างเท่านั้น (_)",
  "ccm.callreason.label.callreason": "เหตุผลในการโทร",
  "ccm.callreason.title.callreason": "เหตุผลการโทรที่เชื่อมโยงกัน",
  "ccm.callreason.label.parentcallreason": "เหตุผลในการเรียกผู้ปกครอง",
  "ccm.callReason.title.createCallReason": "สร้างเหตุผลในการโทร",
  "ccm.callReason.title.editCallReason": "แก้ไขเหตุผลการโทร",
  "ccm.agent.label.authtype.unified": "การตรวจสอบความถูกต้องแบบครบวงจร",
  "ccm.agent.calledroute.dnid": "การกำหนดค่า ID",
  "ccm.agent.label.answerType4": "ID ตัวแทนที่ผู้ใช้กําหนด",
  "ccm.agent.restReason.partDeleteFailed": "การลบบางส่วนล้มเหลว",
  "ccm.agent.contact.videoclicktodial": "วิดีโอคลิกเพื่อโทร",
  "ccm.agent.contact.multimedia.whatsapp": "มัลติมีเดีย-Whatsapp",
  "ccm.satisfactionconfig.message.createfailed": "การกำหนดค่าแบบสำรวจความพึงพอใจใหม่ล้มเหลว",
  "ccm.agent.label.waitVoiceConfig": "การตั้งค่าการเข้าคิวและรอ",
  "ccm.agent.operation.opresult": "ผลลัพธ์",
  "ccm.agent.mobileagent.syserror": "ไม่สามารถเปิดใช้งานการจดจําอัจฉริยะได้ ติดต่อผู้ดูแลระบบเพื่อตรวจสอบการกําหนดค่าสภาพแวดล้อม",
  "ccm.agent.pageurlparam.paramIsConfiged": "หน้าจอป็อปอัพพารามิเตอร์ URL หรือพารามิเตอร์ข้อมูลระบบที่เกี่ยวข้องมีอยู่แล้ว.",
  "ccm.veridentity.userId": "หมายเลขลูกค้า",
  "ccm.agent.label.answerType2": "ไม่มีรายงานประกาศ",
  "ccm.satisfactionconfig.message.flowaccesscode": "ประมวลผลรหัสการเข้าถึง",
  "ccm.agent.label.answerType0": "รายงาน ID พนักงาน",
  "ccm.agent.label.skills": "คิวทักษะ",
  "ccm.agent.label.serviceCode": "ประเภทของบริการ",
  "ccm.agent.label.duration": "ระยะเวลา",
  "ccm.agent.label.queueTime": "ระยะเวลาในการเข้าคิว",
  "ccm.agent.label.availabilityTime": "ระยะเวลาที่พร้อมใช้งาน",
  "ccm.agent.label.mutetime": "ระยะเวลาปิดเสียง",
  "ccm.agent.label.calltime": "ไม่มีระยะเวลาปิดเสียงการโทร",
  "ccm.agent.label.ringingTime": "ระยะเวลาเสียงกริ่ง",
  "ccm.agent.label.serviceLevel": "เปอร์เซ็นต์ระยะเวลารอ (%)",
  "ccm.agent.label.transferInformation": "โอนสายหรือไม่",
  "ccm.agent.label.transfer": "จํานวนการโอนสาย",
  "ccm.agent.label.ivrUsage": "จํานวนการโอน ivr",
  "ccm.agent.label.originId": "ID งานของผู้โอน",
  "ccm.agent.label.queueName": "โอนคิวทักษะ",
  "ccm.agent.label.wrapUpCode": "สถานะแบบองค์รวม",
  "ccm.agent.label.uuiInfo": "ข้อมูลเกี่ยวกับ uui",
  "ccm.agent.label.overallState": "สถานะโดยรวม",
  "ccm.note.config.agent.page.ring.setUp": "ปรับแต่งการแจ้งเตือนหน้าเว็บ",
  "ccm.agent.ten.maxAgents": "เจ้าหน้าที่เสียง",
  "ccm.agent.operation.FORCEBUSY": "การบังคับให้ไม่ว่าง",
  "ccm.note.config.success": "สำเร็จ",
  "ccm.agent.message.audioagentzero": "จำนวนเจ้าหน้าที่เสียงคือ 0 ไม่สามารถกำหนดการตั้งค่าลำดับทักษะเสียงคลิกเพื่อโทร",
  "ccm.agent.contact.callserialno": "หมายเลขประจําเครื่อง",
  "ccm.agent.ten.ivrchannels": "ปริมาณช่องสัญญาณเสียง IVR",
  "ccm.anonymous.message.fail.feature": "การตรวจสอบสิทธิ์คุณสมบัติของการโทรออกที่ไม่ระบุชื่อไม่ปกติ",
  "ccm.label.contactexport.selectdatarange": "ขอบเขตข้อมูล",
  "ccm.agent.pageurls.pagename": "ชื่อหน้า",
  "ccm.agent.sysparamconfig.paramwarn": "กรุณาเลือกค่าพารามิเตอร์ที่ถูกต้อง",
  "ccm.policy.label.updatesucess": "อัปเดตข้อมูลนโยบายเรียบร้อยแล้ว",
  "ccm.agent.label.notreply": "ไม่สามารถตอบกลับได้",
  "ccm.callreasonconfig.message.deletecallreason.used": "ล้มเหลวในการลบเหตุผลการโทร เหตุผลการโทรกําลังใช้อยู่",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.startafterend": "วันที่เริ่มต้นต้องเร็วกว่าวันที่สิ้นสุด",
  "ccm.custdata.button.importData": "นำเข้าข้อมูล",
  "ccm.callreason.input.specialStrValidateFailed": "ชื่อเหตุผลการโทรต้องไม่มีอักขระพิเศษ",
  "ccm.chatbot.pop.table.botname": "ชื่อบอท",
  "ccm.agent.calledroute.placeholder.extcode": "กรุณาป้อนรหัสส่วนขยาย",
  "ccm.asr.create.case.info": "ระหว่างหรือหลังสิ้นสุดการโทร ให้คลิก สร้างกรณีปัญหา",
  "ccm.agent.speciallist.endtime": "การเพิกถอนเวลา",
  "ccm.worknoaftervoice.config.choosenote": "โปรดเลือกเสียงหลังจาก ID พนักงาน",
  "ccm.agent.leavemessage.status.allocationFailed": "การจัดตําแหน่งล้มเหลว",
  "ccm.custdata.option.all": "ทั้งหมด",
  "ccm.agent.pageurls.create": "ใหม่",
  "ccm.agent.label.modifymobileagent": "แก้ไขหมายเลขซอฟต์โฟน",
  "ccm.transserver.label.cssaddr": "ที่อยู่บริการ CSS",
  "ccm.agent.calledroute.extcodewarn": "รหัสส่วนขยายสามารถมีได้เฉพาะตัวเลขเท่านั้น",
  "ccm.recognition.label.api.fabric.id": "API Fabric ID",
  "ccm.agent.label.deletecallreason": "เหตุผลปัจจุบันประกอบด้วยเหตุผลย่อยไม่สามารถลบได้",
  "ccm.agent.login.FORCELOGOUT": "บังคับให้ออกจากระบบ",
  "ccm.agent.label.signedinAgentsPercentage": "โดย สัดส่วนตัวแทนเข้าสู่ระบบ",
  "ccm.recognition.label.ordersucess": "สมัครสมาชิกสำเร็จแล้ว",
  "ccm.sysparam.config.select.count": "สามารถเลือกพารามิเตอร์ได้สูงสุด 10 ตัว",
  "ccmanagement.satisfaction.strategy.hourvalidate": "ชั่วโมงต้องไม่เกิน 24",
  "ccm.recognition.resetSK.success": "SK รีเซ็ตสำเร็จ",
  "ccm.custdata.option.maskStyle.maskAll": "มาสก์ตัวเลขทั้งหมด",
  "ccm.transserver.delete.confirm": "คุณแน่ใจหรือไม่ว่าคุณต้องการลบมัน",
  "ccm.pageinterface.selectedAttributeNum": "แอตทริบิวต์วัตถุที่เลือก",
  "ccm.calledconfig.message.nocalled": "กรุณาเลือกโทร",
  "ccm.agent.pageurls.typenosupported": "แอปไม่รองรับการกำหนดค่าหน้าภายใน.",
  "ccm.agent.message.idIsEmpty": "Skill Queue IDว่าง",
  "ccm.agent.pageurlparam.paramCountLimit": "สามารถกำหนดค่าพารามิเตอร์ URL ป๊อปอัปได้สูงสุด {limit} รายการ.",
  "ccm.agent.message.webRTCVerification": "ไม่สามารถกำหนดค่าพารามิเตอร์นี้ได้เมื่อเปิดใช้งานฟีเจอร์ WebRTC",
  "ccm.agent.contact.callserno": "โทรหมายเลขประจําเครื่อง",
  "ccm.agent.contact.minCallDuration": "ระยะเวลาการโทรเริ่มต้น",
  "ccm.agent.contact.maxCallDuration": "สิ้นสุดระยะเวลาการโทร",
  "ccm.agent.contact.lessThan": "น้อยกว่า",
  "ccm.agent.contact.callDurationError":"เวลาเริ่มต้นระยะเวลาการโทรสั้นกว่าเวลาสิ้นสุดระยะเวลาการโทร",
  "ccm.agent.contact.talkDurationError":"ระยะเวลาการโทรต้องไม่เกิน 31 วัน",
  "ccm.agent.contact.fuzzyCallNoError":"ช่วงหมายเลขติดต่อ (การค้นหาแบบคลุมเครือ) ต้องไม่เกิน 31 วัน",
  "ccm.agent.contact.fuzzyCallNo": "หมายเลขผู้ติดต่อ (การสอบถามแบบคลุมเครือ)",
  "ccm.agent.leavemessage.status": "สถานะ",
  "ccm.speciallist.tip.endTime.min": "เวลาหมดอายุต้องมากกว่าเวลาปัจจุบัน",
  "ccm.agent.sysparamconfig.resttimewarn": "ค่าต้องเป็นจำนวนเต็ม",
  "ccm.speciallist.msg.levelName": "ระดับ",
  "ccm.callreason.label.selectedcallreason": "เลือกเหตุผลการโทร",
  "ccm.custdata.button.moveUp": "ขึ้น",
  "ccm.agent.label.querymobileagent": "สอบถามหมายเลขโทรศัพท์อ่อน",
  "ccm.agent.label.addChildReason": "สาเหตุการตั้งค่าไม่ควรเกินระดับ 6",
  "ccm.ucconfig.message.updatefailed": "ไม่สามารถบันทึกการกําหนดค่าการรวม UC ได้",
  "ccm.pageinterface.custTableName": "ชื่อตารางที่อ้างอิง",
  "ccmanagement.satisfaction.strategy.fail.accepttime.overlimit": "สามารถบันทึกนโยบายเวลาการจัดการได้สูงสุด 100 รายการ",
  "ccm.agent.pageurls.updatecall": "แก้ไขป็อปอัพหน้าจอสายเรียกเข้า",
  "ccmanagement.satisfaction.strategy.fail.unknown": "การออมล้มเหลว",
  "ccm.agent.ten.contactInfor": "วิธีการติดต่อ",
  "ccm.verifyidentity.fail.LIMITOVERLIMIT": "พารามิเตอร์การแบ่งหน้าของข้อความค้นหาเกินขีดจำกัด",
  "ccm.agent.contact.otherexception": "ข้อยกเว้นอื่น ๆ",
  "ccm.speciallist.msg.operationdoing": "ผู้ตรวจสอบอื่น ๆ ได้รับการอนุมัติ",
  "ccm.callout.message.resetsuccess": "รีเซ็ตพารามิเตอร์สำเร็จแล้ว",
  "ccm.certificate.management.certificatefile.certcodeexist": "มีรหัสใบรับรองอยู่แล้ว",
  "ccm.certificate.management.search.status": "โปรดเลือกสถานะใบรับรอง",
  "ccm.certificate.management.status": "สถานะใบรับรอง",
  "ccm.certificate.management.status.value.inuse": "ในการใช้งาน",
  "ccm.certificate.management.status.value.discard": "ทอดทิ้ง",
  "ccm.certificate.management.status.oper.reuse": "นํากลับมาใช้ใหม่",
  "ccm.certificate.management.status.oper.discard": "ทอดทิ้ง",
  "ccm.certificate.management.status.confirm.reuse": "การยืนยันการนํากลับมาใช้ใหม่",
  "ccm.certificate.management.status.confirm.discard": "การยืนยันการละทิ้ง",
  "ccm.certificate.management.status.confirmReuse": "โปรดยืนยันการใช้ใบรับรองนี้ต่อไปหรือไม่?",
  "ccm.certificate.management.status.confirmDiscard": "โปรดยืนยันว่าคุณได้ใช้ใบรับรองนี้โดยไม่มีสถานการณ์ทางธุรกิจแล้ว และยกเลิกใบรับรองนี้หรือไม่",
  "ccm.certificate.management.status.update.success": "แก้ไขสถานะใบรับรองเรียบร้อยแล้ว",
  "ccm.certificate.management.status.update.failed": "ไม่สามารถแก้ไขสถานะใบรับรองได้",
  "ccm.certificate.management.certificatefile.status.error": "ใบรับรองถูกยกเลิกและไม่สามารถแก้ไขได้",
  "ccm.contactitemconfig.label.data.item.name": "ชื่อรายการข้อมูล",
  "ccm.agent.message.deleteSkillConfFail": "ลบข้อมูลลำดับทักษะไม่สำเร็จ",
  "ccm.agent.message.deleteSkillConfFailID": "การลบ {0} ล้มเหลว",
  "ccm.agent.message.modifySkillConfSuccess": "แก้ไขข้อมูลลำดับทักษะเรียบร้อยแล้ว",
  "ccm.agent.calledroute.placeholder.skill": "กรุณาเลือกลำดับทักษะ",
  "ccm.custdata.label.failreaon": "สาเหตุล้มเหลว",
  "ccm.pageurls.select.independently": "เปิดอย่างอิสระ",
  "ccm.agent.label.agentTel": "หมายเลขโทรศัพท์มือถือ / โทรศัพท์พื้นฐานของเจ้าหน้าที่",
  "ccm.transtask.label.taskedit": "งานการถ่ายโอนข้อมูลทรัพยากร",
  "ccm.custdata.msg.confirmReturn": "การเรียงลำดับยังไม่สมบูรณ์ แน่ใจไหมว่าต้องการกลับไปที่หน้าเพื่อดูตารางที่กำหนดเอง",
  "ccm.querycustdata.msg.inputIndexData": "กรุณาใส่ข้อมูล",
  "ccm.agent.calledroute.extlengthcodewarn": "ความยาวของรหัสส่วนขยายต้องไม่เกิน 12",
  "ccm.agent.contact.numhungup": "โอนไปยังหมายเลขภายนอก",
  "ccm.agent.label.restQueueRule": "นโยบายการเข้าคิวพักผ่อน",
  "ccm.agent.contact.multimedia.fiveG": "มัลติมีเดีย-5G RCS",
  "ccm.transtask.label.chatrecordinf": "มัลติมีเดียแชทข้อมูลการตั้งค่าการถ่ายโอนข้อมูล",
  "ccm.agent.leavemessage.allStatus": "สถานะข้อความทั้งหมด",
  "ccm.satisfactionlevel.message.nopara": "คําอธิบายไม่สามารถว่างเปล่า",
  "ccm.speciallist.label.startDate": "เวลาที่มีผลบังคับใช้",
  "ccm.transserver.label.cssproxynotinwhite": "การดําเนินการล้มเหลว โปรดติดต่อผู้ดูแลระบบเพื่อเพิ่มที่อยู่ของเซิร์ฟเวอร์พร็อกซีในรายการพร็อกซี่ของเซิร์ฟเวอร์ CSS",
  "ccm.agent.restReason.pleaseRefresh": "ซิงค์โครไนซ์แล้วลองอีกครั้ง",
  "ccm.whatsapp.whatsApp.channel": "ช่อง WhatsApp",
  "ccm.callreason.label.input": "กรุณาใส่",
  "ccm.transtask.label.saveexit": "เพิ่มเซิร์ฟเวอร์ดัมพ์ไม่สําเร็จ เนื่องจากชื่อเซิร์ฟเวอร์มีอยู่แล้ว",
  "ccm.custdata.msg.typeColumnNumValidateFailed": "จำนวนเลขฟิลด์เกินขีด จำกัด สูงสุด: อนุญาตให้ใช้ฟิลด์ เพียงฟิลด์เดียวและฟิลด์ที่ไม่เข้ารหัส สูงสุด 30 และฟิลด์ที่เข้ารหัส 20 ฟิลด์",
  "ccm.agent.contact.reasonCode": "รหัสเหตุผล",
  "ccm.satisfactionconfig.message.groupcreate": "เพิ่ม",
  "ccm.urlconfig.label.oauth.voicemsg.alerttip": "ส่ง URL รีเซ็ตการโทรเพื่อสร้างอินเทอร์เฟซการแจ้งเตือนด้วยเสียง ถ้ามีการปรับค่าข้อมูลต่อไปนี้ ให้ใช้ข้อมูลสอดแนมต่อไปนี้เมื่อการแจ้งเตือนด้วยเสียงสิ้นสุดลง มิเช่นนั้นจะไม่เกิดขึ้นเมื่อเกิดการย้อนกลับไป",
  "ccmanagement.satisfaction.strategy.accepttime.timeStart": "เวลาเริ่มต้น",
  "ccm.sysparam.config.search": "สอบถาม",
  "ccm.certificate.management.certificatecrlfile.suffixerror": "ไฟล์รายการเพิกถอนใบรับรองต้องอยู่ในรูปแบบ. crl",
  "ccm.agent.ten.createDate": "เวลาสร้าง",
  "ccm.custdata.button.abandon": "ทิ้ง",
  "ccm.label.contactexport.parainvalid": "ยืนยันเงื่อนไขการส่งออกไม่สำเร็จ",
  "ccm.agent.restrecord.reststatus.resting": "ขณะพัก",
  "ccm.note.config.agent.page.ring.choose": "โปรดเลือกเสียงเรียกเข้า",
  "ccm.agent.contact.playfail": "การเล่นล้มเหลว",
  "ccm.transtask.label.start": "การเริ่มต้น",
  "ccm.agent.ten.allowedTTSAndASRCallNum": "จํานวนช่อง IVR อัจฉริยะ",
  "ccm.veridifyentity.fail.unknown": "Query ล้มเหลว",
  "ccm.agent.contact.sameContactRecord": "บันทึกรายชื่อผู้ติดต่อเดียวกัน",
  "ccm.agent.sysparamconfig.worknonumwarn": "จำนวนรหัสเจ้าหน้าที่ต้องไม่เกิน 10",
  "ccm.agent.calledroute.nmsdata.error": "ไม่สามารถซิงโครไนซ์ข้อมูลกับ NMS ได้ ข้อมูลการตั้งค่าบุคคลที่เรียกว่า NMS ไม่สอดคล้องกับข้อมูลภายในของ CCM",
  "ccm.agent.operation.LISTEN": "ฟังการตรวจสอบคุณภาพ",
  "ccm.speciallevel.speciallist.remark": "ข้อสังเกต",
  "ccm.speciallist.msg.willdelete": "คุณแน่ใจหรือไม่ว่าต้องการลบ {limit} รายการนี้?",
  "ccm.common.msg.willdelete": "คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูล {limit} รายการที่เลือก",
  "ccm.calledconfig.title.numbersadded": "เพิ่มตัวเลขแล้ว",
  "ccm.ivr.label.processdesc": "คำอธิบายกระบวนการ",
  "ccm.custdata.operlog.failed": "ล้มเหลว",
  "ccm.agent.message.willClearAgents": "แน่ใจไหมว่าต้องการลบเจ้าหน้าที่ที่เลือก",
  "ccm.pageurls.select.urlParameter": "URL GET REQUEST พารามิเตอร์",
  "ccm.note.config.error": "ข้อผิดพลาด",
  "ccm.agent.operation.mediaswitch": "สวิตช์เสียง / วิดีโอ",
  "ccm.sysparam.error.validate.notmatchruleregex": "พารามิเตอร์อินพุตไม่ตรงกับนิพจน์ทั่วไปของกฎการตรวจสอบ",
  "ccm.contact.customer.config.fonts": "ตัวอักษร",
  "ccm.sysparam.config.agent.title": "พารามิเตอร์ของตัวแทน",
  "ccm.agent.ten.expiryDate": "วันหมดอายุ",
  "ccm.accesscode.placeholder.accessdesc": "กรุณาป้อนคำอธิบายรหัสการเข้าถึง",
  "ccm.agent.sysparamconfig.refreshAllSysParamFail": "ระบบผิดพลาด การรีเฟรชล้มเหลว",
  "ccm.transserverparam.label.ak": "อาก้า",
  "ccm.agent.softPhoneStatusNode1": "โหนด 1",
  "ccm.agent.softPhoneStatusNode2": "โหนด 2",
  "ccm.speciallevel.speciallist.restrictMode": "โหมด จํากัด",
  "ccm.agent.contact.nohaveevent": "ไม่มีเหตุการณ์ดังกล่าว",
  "ccm.agent.label.authType": "โหมดการตรวจสอบสิทธิ์",
  "ccm.common.label.no": "ไม่ค่ะ",
  "ccm.speciallist.msg.modify": "แก้ไข",
  "ccm.satisfactionconfig.message.sms": "ข้อความ",
  "ccm.taskrecord.message.reexecutetranstasksuccess": "ประมวลผลอีกครั้งสําเร็จ",
  "ccm.agent.leavemessage.processfail": "ประมวลผลข้อความไม่สำเร็จ",
  "ccm.agent.contact.talkCallbackFlag": "พูดคุยโทรกลับป้ายชื่อ",
  "ccm.agent.button.unregister": "ออกจากระบบ",
  "ccm.agent.contact.begintime": "เวลาเริ่มต้น",
  "ccm.agent.calledroute.createsuccess": "เพิ่มเรียบร้อยแล้ว.",
  "ccm.agent.button.cancelconfig": "ยกเลิกการตั้งค่า",
  "ccm.agent.pageurls.errorupdate": "การอัพเดทล้มเหลว.",
  "ccm.custdata.msg.operationFailed": "การดำเนินการล้มเหลว.",
  "ccm.satisfactionconfig.message.smstemplate": "แม่แบบ SMS",
  "ccm.agent.restReason.createReason": "เพิ่มเหตุผลในการพัก",
  "ccm.urlconfig.message.modifyfail": "แก้ไข URL ไม่สำเร็จ",
  "ccm.agent.leavemessage.assignSuccess": "ข้อความได้รับการจัดสรรเรียบร้อยแล้ว",
  "ccm.agent.contact.businesshungup": "โอนไปยังเจ้าหน้าที่",
  "ccm.agent.label.param.index": "หมายเลข",
  "ccm.callout.message.isconfreset": "แน่ใจไหมว่าต้องการรีเซ็ตผู้โทร",
  "ccm.verifyidentity.fail.VERIFYRESULTPARSEERROR": "พารามิเตอร์ผลการตรวจสอบไม่ถูกต้อง",
  "ccm.agent.button.create": "ใหม่",
  "ccm.agent.calledroute.create.skill.none": "ไม่มีลำดับทักษะที่อ้างถึงโดยการกำหนดค่าคู่สายที่ไม่มีอยู่",
  "ccm.transtask.label.certTip.cssCert": "โปรดเลือกใบรับรอง CSS",
  "ccm.agent.label.keepVoiceSelect": "โปรดเลือกโทนเสียงที่รักษาไว้",
  "ccm.transtask.label.wednesday": "วันพุธ",
  "ccm.agent.message.pageLayout": "เค้าโครงหน้า",
  "ccm.agent.message.name.limit": "ชื่อมันยาวเกินไป",
  "ccm.agent.message.name.grouplimit": "ชื่อต้องไม่เกิน 64 ชื่อ",
  "ccm.certificate.management.certificatefile.certfile.sizeerror": "ขนาดไฟล์ใบรับรองต้องไม่เกิน 20 KB",
  "ccm.agent.message.agentCreateFail": "ไม่สามารถสร้างเจ้าหน้าที่ใน CTI",
  "ccm.agent.ten.maxCalls": "ค่าสูงสุด การโทรด้วยเสียงพร้อมกัน",
  "ccm.agent.contact.whatsapp": "WhatsApp",
  "ccm.pageconfig.label.layoutType": "ประเภทเค้าโครง",
  "ccm.agent.contact.agentPresent": "หมายเลขตัวแทน",
  "ccm.calledconfig.message.duplicatecalled": "เรียกซ้ำ",
  "ccm.label.chatRecord.channeltypeinnull": "ยังไม่ได้เลือกประเภทของช่อง",
  "ccm.satisfactionconfig.satisfaction.process": "กระแสการสํารวจความพึงพอใจ",
  "ccm.calledroute.tip.selectivrexception": "ไม่สามารถค้นหา IVR Flows ใน CTI",
  "ccm.agent.softPhoneStatus.UnRegistered": "ยังไม่ได้ลงทะเบียน",
  "ccm.agent.accesscode.description.notempty": "คำอธิบายรหัสการเข้าถึงต้องไม่ว่าง",
  "ccm.agent.contact.sms": "SMS",
  "ccm.agent.contact.Instagram": "Instagram",
  "ccm.agent.contact.Telegram": "Telegram",
  "ccm.transtask.label.chatRecord": "ข้อมูลการแชทมัลติมีเดีย",
  "ccm.transtask.label.intelligentInspection": "ข้อมูลการตรวจสอบ AI",
  "ccm.satisfactionconfig.message.acceptdate": "วันที่จัดการ",
  "ccm.agent.pageurlparam.relSysParamName": "พารามิเตอร์ข้อมูลระบบที่เกี่ยวข้อง",
  "ccm.urlconfig.modify.authtype3": "ไม่มี",
  "ccm.urlconfig.modify.authtype1": "คีย์ที่ใช้ร่วมกัน",
  "ccm.agent.message.agentnummaxoperation": "เลือกเจ้าหน้าที่ ได้สูงสุด 100 คน",
  "ccm.agent.label.basicInfo": "ข้อมูลพื้นฐาน",
  "ccm.speciallist.msg.datecompareerror": "เวลาหมดอายุควรมากกว่าเวลาที่มีผลบังคับใช้",
  "ccm.transtask.label.task.chatrecord.uniqueness": "มีงานประเภทช่องที่เลือกไว้บางรายการอยู่แล้ว",
  "ccm.basicdata.msg.deletefail": "ลบข้อมูลพื้นฐานไม่สำเร็จ",
  "ccm.satisfactionconfig.audio.and.video.remark": "เมื่อสวิตช์ทั้งแบบสํารวจความพึงพอใจด้วยเสียงและ SMS ของ IVR เปิดอยู่และเอเจนต์วางสายก่อน จะไม่มีการสํารวจความพึงพอใจด้วย SMS หากทําการสํารวจความพึงพอใจด้วยเสียงของ IVR",
  "ccm.sysparam.config.value": "ค่าพารามิเตอร์",
  "ccm.agent.contact.voiceVidio": "เสียง / วิดีโอ",
  "ccm.custdata.option.maskStyle.maskDate": "รูปแบบวันที่",
  "ccm.recognition.label.appSecret.download": "ดาวน์โหลด SK",
  "ccm.agent.operation.REST": "ขอพัก",
  "ccm.calledconfig.message.savemobileagentfail": "บันทึกการเชื่อมโยงของการตั้งค่ามือถือเจ้าหน้าที่ไม่สำเร็จ",
  "ccm.certificate.management.certificatefile.uploadcertfile": "อัปโหลดไฟล์ใบรับรอง",
  "ccm.contact.customer.config.label": "ติดต่อส่วนข้อมูลลูกค้า",
  "ccm.verifyidentity.verifyResult": "ตรวจสอบผลลัพธ์",
  "ccm.urlconfig.message.modifysuccess": "แก้ไข URL สำเร็จ",
  "ccm.callreason.label.mark": "หมายเหตุ",
  "ccm.satisfactionconfig.message.channelused": "กลุ่มนโยบายถูกอ้างอิงโดยแชนแนลและไม่สามารถลบได้",
  "ccm.transtask.label.datapath.tips": "เส้นทางของ OBS",
  "ccm.custdata.msg.importDataSuccess": "กำลังส่งออกข้อมูล ... ดูรายละเอียดในผลลัพธ์การนำเข้า",
  "ccm.agent.label.keepVoiceDefault": "ค่าปริยายของโทนเสียง",
  "ccm.agent.label.selectSkill": "เลือกลำดับทักษะ",
  "ccm.transserver.label.cssServerPort": "พอร์ตบริการ CSS",
  "ccm.agent.label.keepVoiceConfig": "กําลังรักษาและรอการกําหนดค่า",
  "ccm.transtask.label.agentOperationReport": "รายงานการดําเนินงานการเชื่อมต่อตัวแทน",
  "ccm.agent.contact.createbother": "สร้างบันทึกของทั้งสองฝ่าย",
  "ccm.custdata.option.columnType.dictionary": "พจนานุกรม",
  "ccmanagement.satisfaction.strategy.accepttime.minute": "minute",
  "ccm.agent.calledroute.search.devicedesc": "กรุณาป้อนคำอธิบายอุปกรณ์",
  "ccm.agent.pageurls.isconfdelete": "แน่ใจไหมว่าต้องการลบ",
  "ccm.agent.contact.updatedate": "วันที่อัพเดท",
  "ccm.agent.log.downloadVideoTerminal": "ดาวน์โหลด OpenEye Video Terminal",
  "ccm.contact.detail.sendmessage": "ส่งข้อความ",
  "ccm.agent.Transfer.CallForwarding": "โอนสาย",
  "ccm.satisfactionconfig.message.strategygroupmemconfig": "การกําหนดค่านโยบาย",
  "ccm.pageurls.select.postParameter": "URL POST REQUEST พารามิเตอร์",
  "ccm.agent.message.modifySkillConfFail": "แก้ไขข้อมูลลำดับทักษะไม่สำเร็จ",
  "ccm.agent.restrecord.rest.endTime": "เวลาสิ้นสุดการพัก",
  "ccm.agent.calledroute.gateway.fail": "ไม่สามารถซิงโครไนซ์ข้อมูลบนแพลตฟอร์ม CTI กรุณาตรวจสอบการกำหนดค่าเกตเวย์",
  "ccm.pageinterface.optionalAttribute": "แอตทริบิวต์เสริม",
  "ccm.contact.customer.config.datasource.customData": "ข้อมูลท้องถิ่น",
  "ccmanagement.satisfaction.strategy.deleteselect": "คุณแน่ใจว่าต้องการลบนโยบายที่เลือกหรือไม่",
  "ccm.agent.contact.callerfailed": "โทรออกไม่สำเร็จ",
  "ccm.agent.resetskillrecord.skillafteradjust": "คิวทักษะหลังจากปรับ",
  "ccm.agent.message.selectSkills": "กรุณาเลือกลำดับทักษะ",
  "ccm.sysparam.config.resetAbb": "รีเซ็ต",
  "ccm.agent.label.airecog": "การรับรู้อัจฉริยะ",
  "ccm.certificate.management.certificatefile.suffixerror": "สามารถอัปโหลดได้เฉพาะไฟล์ในรูปแบบ jks, cer, crt, pem, pfx, p12",
  "ccm.agent.message.inputWeight": "ป้อนค่าน้ำหนัก",
  "ccm.agent.leavemessage.assignFailed": "จัดสรรข้อความไม่สำเร็จ",
  "ccm.agent.restReason.selectRestReasons.limit": "สามารถลบเหตุผลที่เหลือได้สูงสุด 10 เหตุผลในแบทช์",
  "ccmanagement.satisfaction.strategy.deletefail": "การลบล้มเหลว",
  "ccm.vcallcenter.config.popup.authentication.failed": "รับรองความถูกต้องล้มเหลว.",
  "ccm.satisfactionconfig.message.smsfeedbacktype": "SMS",
  "ccm.custdata.msg.msgValidateFailed": "ไม่อนุญาตให้ใช้อักขระพิเศษ",
  "ccm.agent.contact.voiceBot": "เสียง",
  "ccm.agent.label.deleteAgentFailed": "คิวทักษะถูกผูกไว้กับบัญชีบริการ ลบการกําหนดค่าของคิวทักษะภายใต้ตัวแทน",
  "ccm.transtask.label.discard": "ยกเลิก",
  "ccm.agent.Transfer.deviceDesc": "อุปกรณ์ปลายทาง",
  "ccm.basicdata.error.capacitylimit": "ความจุข้อมูลถึงขีด จำกัด สูงสุดแล้ว {0}",
  "ccm.dual.call.record.callCompletionRate": "อัตราการเสร็จสิ้นการโทร",
  "ccm.agent.ten.ttschannelnums": "ปริมาณ TTS",
  "ccm.speciallist.button.import": "นําเข้า",
  "ccm.speciallist.title.import": "นําเข้ารายการพิเศษ",
  "ccm.recognition.label.modifymobileagentclose": "คุณสมบัติมือถือเจ้าหน้าที่ ไม่ได้เปิดใช้งานสำหรับพื้นที่ผู้เช่า!",
  "ccm.contact.customer.config.style": "รูปแบบการแสดงผล",
  "ccm.dual.call.record.unexpected.end": "จุดจบที่ไม่คาดคิด",
  "ccm.agent.contact.playdefault": "ระยะเวลาการบันทึกว่างเปล่า คุณต้องการใช้ระยะเวลาเริ่มต้น 60 นาทีหรือไม่?",
  "ccm.satisfactionconfig.sms.switch.remark": "หลังจากเปิดสวิตช์นี้ ข้อความ SMS แบบสํารวจความพึงพอใจจะถูกส่งถึงลูกค้าหลังจากสิ้นสุดการโทร",
  "ccm.satisfactionconfig.satisfaction.sending.channel": "ช่องทางการส่ง SMS",
  "ccm.agent.message.refreshSkillException": "รีเฟรชข้อยกเว้นทักษะ",
  "ccm.recognition.label.policy.information": "ข้อมูลนโยบาย",
  "ccm.recognition.label.editPolicy.information": "แก้ไขข้อมูลนโยบาย",
  "ccm.agent.leavemessage.status.toBeAssigned": "ที่ต้องจัดสรร",
  "ccm.agent.leavemessage.status.toBeProcessed": "ที่จะดําเนินการ",
  "ccm.speciallist.msg.applyTime": "เวลาสมัคร",
  "ccm.agent.contact.callershutdown": "วางสาย ปิดผู้โทร",
  "ccm.satisfactionlevel.message.satistip": "ตรวจสอบให้แน่ใจว่าระดับความพึงพอใจที่ใช้ในการสํารวจความพึงพอใจของแต่ละช่องจะเหมือนกันเช่น การสํารวจความพึงพอใจของเสียง การสํารวจความพึงพอใจ SMS และการสํารวจความพึงพอใจในเว็บ",
  "ccm.anonymous.message.fail.close": "ล้มเหลวในการปิดธง ตรวจสอบการตั้งค่า CTI",
  "ccm.taskrecord.label.executeBeginTime": "เวลาเริ่มต้นการประมวลผล",
  "ccm.agent.label.usedMode.sipAccount": "บัญชีการเชื่อมต่อ",
  "ccm.callback.url.callnotify": "การกําหนดค่าการโทรกลับการแจ้งเตือนด้วยเสียง",
  "ccm.callreason.label.allreason": "เหตุผลทั้งหมด",
  "ccmanagement.satisfactionconfig.acceptdate.timeerror.laterThanEndTime": "วันที่เริ่มต้นต้องเร็วกว่าวันที่สิ้นสุด",
  "ccm.agent.ten.mediaagents": "จำนวนเจ้าหน้าที่แอป",
  "ccm.agent.contact.callernook": "ผู้โทรออกไม่มีคำตอบ",
  "ccm.satisfactionconfig.message.nofeedbacktype": "ไม่มีการสํารวจ",
  "ccm.contact.customer.config.datasource.callData": "ข้อมูลที่เกี่ยวข้องกับการโทร",
  "ccm.custdata.button.detail": "รายละเอียด",
  "ccm.label.contactexport.currentfield": "ฟิลด์ในรายการปัจจุบัน",
  "ccm.speciallist.msg.confirmdelete": "แน่ใจหรือไม่ที่จะลบรายการพิเศษที่เลือก",
  "ccm.agent.ten.tenantId": "ID พื้นที่ผู้เช่า",
  "ccmanagement.satisfaction.strategy.saveerror": "Failure",
  "ccm.custdata.msg.inputTableName": "ป้อนชื่อตาราง",
  "ccm.agent.label.ifBusyNoAnswerAgent": "จะแสดงสถานะไม่ว่างเมื่อตัวแทนไม่รับสายเป็นเวลานานหรือไม่",
  "ccm.agent.label.asyncUserDispatchMode": "เงื่อนไขการรับข้อความแบบออฟไลน์",
  "ccm.agent.label.asyncUserDispatchMode.zero": "ตามจํานวนแบทช์",
  "ccm.agent.label.asyncUserDispatchMode.one": "ตามจํานวนแบทช์และทักษะ",
  "ccm.agent.label.asyncUserDispatchMode.two": "ตามจํานวนแบทช์และสถานะออนไลน์",
  "ccm.agent.label.asyncUserDispatchMode.three": "ตามจํานวนแบตช์ ทักษะ และสถานะออนไลน์",
  "ccm.agent.label.asyncUserDispatchMode.four": "ตามจํานวนแบทช์ เจ้าหน้าที่ ทักษะ และสถานะออนไลน์",
  "ccm.transserverparam.label.password": "Password",
  "ccm.agent.videoDownload.disabled": "ฟังก์ชันดาวน์โหลดวิดีโอถูกปิดใช้งาน",
  "ccm.common.label.yes": "ค่ะ",
  "ccm.basicdata.msg.deletesuccess": "ลบข้อมูลพื้นฐานเรียบร้อยแล้ว",
  "ccm.callreason.label.status": "สถานะ",
  "ccm.contact.customer.config.writeBack": "เขียนบันทึกการติดต่อกลับ",
  "ccm.agent.label.ccRoleId": "บทบาทของแพลตฟอร์ม",
  "ccm.case.title.selectWorkOrder": "เลือกประเภท Work Order",
  "ccm.agent.title.skillModify": "แก้ไขลำดับทักษะ",
  "ccm.pageinterface.pleaseSelectInterfaceType": "เลือกประเภทอินเทอร์เฟซ",
  "ccm.speciallist.msg.withoutauth": "คุณไม่ได้รับอนุญาติให้ทําการผ่าตัด",
  "ccm.agent.label.ifReleaseNoAnswerCall": "ว่าจะปล่อยสายไหม ถ้าจนท.ไม่รับสายนาน",
  "ccm.speciallist.msg.fail": "ล้มเหลว",
  "ccm.agent.pageurl.httptip": "(ไม่ปลอดภัย)",
  "ccm.agent.label.withinSevenDays": "ภายใน 7 วัน",
  "ccm.certificate.management.update.cancel.crlfile": "แน่ใจไหมว่าต้องการยกเลิกการอัปเดตไฟล์ crl",
  "ccm.speciallist.label.operator": "ผู้ประกอบการ",
  "ccm.agent.label.waitTime": "ระยะเวลารอ (s)",
  "ccm.agent.contact.userhungup": "ผู้ใช้",
  "ccm.agent.label.ivrChoice": "เลือก IVR",
  "ccm.agent.message.noReleaseFail": "สามารถลบได้เฉพาะข้อมูลที่ยังไม่ได้เผยแพร่เท่านั้น",
  "ccm.calledconfig.label.add": "เพิ่ม",
  "ccm.sysparam.error.validate.notmatchrule": "พารามิเตอร์อินพุตไม่ตรงกับกฎการตรวจสอบ",
  "ccm.custdata.msg.missRequiredColumn": "ตารางที่กำหนดเองสามารถนำออกใช้ได้ก็ต่อเมื่อมีฟิลด์ และฟิลด์ข้อมูลอื่น ๆ อย่างน้อยหนึ่งฟิลด์",
  "ccm.agent.operation.RELEASE": "ปล่อย",
  "ccm.sysparam.error.validate.watermarkinfo.notmatchruleregex": "พารามิเตอร์อนุญาตให้ใช้อักขระภาษาอังกฤษ 0 ถึง 20 ตัวที่มีตัวพิมพ์ใหญ่และตัวพิมพ์เล็กตัวเลขหรือ -_/@",
  "ccm.systemparam.error.save": "ล้มเหลวในการบันทึกพารามิเตอร์",
  "ccm.dual.call.record.called": "หมายเลขที่เรียก",
  "ccm.agent.addbother.success": "ประสบความสําเร็จในการสร้างบันทึกรบกวน",
  "ccm.callreasonconfig.message.deletecallreason.success": "ลบเรียบร้อยแล้ว",
  "ccm.transserver.label.obsaddr": "ที่อยู่ OBS",
  "ccm.agent.operation.CALLINNER": "การโทรภายใน",
  "ccm.agent.pageurlparam.paramLengthLimit": "ความยาวพารามิเตอร์ต้องไม่เกิน 64 อักขระ",
  "ccm.agent.contact.multimedia.wechat": "มัลติมีเดีย-WeChat",
  "ccm.recognition.label.orderfail": "การสมัครสมาชิกล้มเหลว",
  "ccm.transtask.label.savefail": "เพิ่มเซิร์ฟเวอร์ดัมพ์ไม่สําเร็จ เนื่องจากอนุญาตให้มีเซิร์ฟเวอร์ดัมพ์ได้สูงสุด {0} ตัว",
  "ccm.custdata.label.valueRange": "ช่วงค่า",
  "ccm.agent.operation.ADJUSTSKILL": "ปรับคิวทักษะ",
  "ccm.agent.leavemessage.view": "ดูข้อความ",
  "ccm.speciallist.msg.norecord": "ไม่มีเร็กคอร์ดที่สามารถส่งออกได้",
  "ccm.urlconfig.message.addsuccess": "เพิ่ม URL สำเร็จ",
  "ccm.recognition.label.appSecret.download.SK.io.error": "ดาวน์โหลด SK ไม่สำเร็จ ข้อยกเว้น I / O",
  "ccm.contactitemconfig.label.contact.recorddata": "รายการข้อมูลบันทึกการติดต่อ",
  "ccm.speciallist.msg.error": "ข้อผิดพลาด",
  "ccm.agent.pageurls.config": "ตั้งค่า",
  "ccm.custdata.button.moveBottom": "ลงล่างสุด",
  "ccm.agent.calledroute.create.accesscode.repeat": "การรวมกันของรหัสการเข้าถึงระบบและรหัสส่วนขยายสำหรับฝ่ายที่เรียกจะต้องไม่ซ้ำกัน",
  "ccm.custdata.option.isMaskDisplay.no": "ไม่ใช่",
  "ccm.custdata.msg.deletedItemNotExist": "ไม่มีรายการการลบ รีเฟรชหน้านี้แล้วลองอีกครั้ง",
  "ccm.transtask.label.taskupload": "อัปโหลดเซิร์ฟเวอร์",
  "ccm.transtask.label.expirationDuration": "การถ่ายโอนข้อมูลระยะเวลาการเก็บรักษาไฟล์",
  "ccm.agent.calledroute.accesscode": "หมายเลขช่องทางการเข้าถึง",
  "ccm.transtask.label.View": "ดูทั้งหมด",
  "ccm.dual.call.record.startTime": "เวลาเริ่มต้นการโทร",
  "ccm.contact.tip.no.workname": "ไม่ได้รับที่นั่งที่สอดคล้องกัน！",
  "ccm.agent.contact.joinเหตุผล": "เหตุผลสําหรับการเข้าร่วม",
  "ccm.ucconfig.message.userId": "ID ไดเร็กทอรี (ผู้เช่า)",
  "ccm.speciallist.msg.transferout": "จํานวนบันทึกทางประวัติศาสตร์มีถึง {0} รายการ",
  "ccm.satisfactionconfig.message.channelname": "ช่องทาง",
  "ccm.urlconfig.label.input.appSecret": "SecretKey",
  "ccm.agent.updateSoftPhone.reason": "เหตุผล",
  "ccm.agent.contact.download.frequently": "คุณพยายามดาวน์โหลดหลายครั้งเช่นกัน กรุณาลองใหม่อีกครั้งในภายหลัง.",
  "ccm.recognition.label.modifymobileagentfailed": "แก้ไขสถานะเจ้าหน้าที่มือถือไม่สำเร็จ!",
  "ccmanagement.satisfaction.strategy.fail.calltime.overlimit": "สามารถบันทึกนโยบายระยะเวลาการโทรได้สูงสุด 100 นโยบาย",
  "ccm.agent.contact.hungup": "ฝ่ายที่วางสาย",
  "ccm.agent.label.skillId": "ID คิวทักษะ",
  "ccm.ccagent.management.additions": "เพิ่มเติม",
  "ccm.note.config.save": "บันทึก",
  "ccm.agent.pageurlparam.checkSpecialChar": "ป้อนตัวเลข ตัวอักษร เครื่องหมายขีดล่าง (_), และเครื่องหมายขีด (-) จุด (.) ต้องอยู่ตรงกลาง.",
  "ccm.agent.pageurlparam.checkSpecialChar.noPoint": "ป้อนตัวเลข ตัวอักษร เครื่องหมายขีดล่าง (_), และเครื่องหมายขีด (-) จุด (.) ต้องอยู่ตรงกลาง",
  "ccm.agent.pageurlparam.checkSpecialChar.name": "ป้อนตัวเลข ตัวอักษร เครื่องหมายขีดล่าง (_), และเครื่องหมายขีด (-)",
  "ccm.agent.contact.otherhungup": "อื่น ๆ",
  "ccm.sysparam.error.validate.ruleregexinvalid": "ตรวจสอบข้อผิดพลาดทางไวยากรณ์ของกฎ",
  "ccm.agent.contact.multimedia.line": "มัลติมีเดีย-Line",
  "ccm.agent.pageurls.confonlynaming": ". ตรวจสอบให้แน่ใจว่าชื่อไม่ซ้ำกัน.",
  "ccm.agent.ten.email": "อีเมล",
  "ccm.speciallist.button.history": "โอนไปยังประวัติ",
  "ccm.page.select.blockLayout": "เค้าโครงบล็อก",
  "ccm.agent.message.description.limit": "คําอธิบายคิวทักษะยาวเกินไป",
  "ccm.agent.sysparamconfig.resetAllParamsFail": "ไฟล์การตั้งค่าพารามิเตอร์หายไป โปรดตรวจสอบ",
  "ccm.agent.accesscode.description.require": "คำอธิบายรหัสการเข้าถึงต้องไม่ว่าง",
  "ccm.sysparam.url.unsafe": "(ไม่ปลอดภัย)",
  "ccm.agent.sysparamconfig.checkWebhookValidityDays": "การรับรองเว็บฮุคจะต้องใช้ได้ระหว่าง 7 ถึง 365 วัน",
  "ccm.asr.create.case": "สร้างเคส",
  "ccm.transserver.delete.css.fail": "ไม่สามารถลบข้อมูลการกําหนดค่า CSS ได้",
  "ccm.speciallist.msg.besureexport": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกข้อมูล?",
  "ccm.speciallevel.msg.selectspecialLevel": "โปรดเลือกระดับพิเศษ",
  "ccm.agent.label.skillName": "ชชื่อคิวทักษะ",
  "ccm.agent.contact.name": "ชื่อเอกสาร",
  "ccm.agent.contact.searchName": "ชื่อเอกสาร",
  "ccm.agent.agentcontact.error": "การค้นหาล้มเหลว",
  "ccm.agent.label.positiveEmotion": "ทัศนคติดีมาก! กรุณาตามมันไป",
  "ccm.agent.restReason.edit": "แก้ไขเหตุผลการพัก",
  "ccm.satisfactionconfig.message.memberstrategychoose": "นโยบายที่ใช้ได้",
  "ccm.urlconfig.label.oauth.oauth2loginurl.illegal": "URL ล็อกอินไม่ถูกต้อง",
  "ccm.agent.label.deleteCtiFailed": "แพลตฟอร์ม Cti ลบข้อมูลล้มเหลว",
  "ccm.worknobeforevoice.config.choosenote": "โปรดเลือกเสียงก่อน ID พนักงาน",
  "ccm.agent.button.restSoftPhonePwd": "รีเซ็ตรหัสผ่านซอฟต์โฟน",
  "ccm.agent.message.export": "ส่งออก",
  "ccm.agent.message.export.skill": "ส่งออกคิวทักษะ",
  "ccm.agent.info.message.export": "กําลังส่งออกข้อมูลเอเจนต์",
  "ccm.verifyidentity.noMoreThan30Days": "ช่วงเวลาของการค้นหาต้องไม่เกิน 30 วัน.",
  "ccm.speciallist.msg.download": "กําลังดาวน์โหลดเทมเพลตการนําเข้ารายการพิเศษ",
  "ccm.speciallist.msg.import.template": "แม่แบบนําเข้ารายการพิเศษ",
  "ccm.agent.button.config": "กําหนดค่า",
  "ccm.custdata.operlog.displayMask": "รูปแบบแสดงผล",
  "ccm.agent.message.agentnotexist": "ไม่พบเจ้าหน้าที่ รีเฟรชหน้า การจัดการเจ้าหน้าที่ แล้วลองใหม่อีกครั้ง",
  "ccm.certificate.management.search.scenename": "ป้อนชื่อใบรับรอง",
  "ccm.agent.message.createSkillConfFail": "สร้างข้อมูลลำดับทักษะไม่สำเร็จ",
  "ccm.satisfactionconfig.message.audiochannel": "ช่องสัญญาณเสียงหรือวิดีโอ",
  "ccm.agent.leavemessage.auditAccount": "บัญชีผู้ตรวจสอบ",
  "ccm.satisfactionconfig.message.calltypeout": "โทรออก",
  "ccm.agent.contact.ivrhungup": "ส่งต่อไปยัง IVR",
  "ccm.pageinterface.interfaceType": "ประเภทอินเทอร์เฟซ",
  "ccm.agent.operation.CANCELWHISPER": "ยกเลิกการกระซิบ",
  "ccm.contact.customer.config.datasource.objId": "วัตถุแหล่งข้อมูล",
  "ccm.recognition.label.intellrecogn": "การรับรู้อัจฉริยะ",
  "ccm.agent.label.negativeEmotion": "คําพูดที่ไม่เหมาะสม! ระวังการใช้คําที่เหมาะสม",
  "ccm.verifyidentity.fail.INPUTNULLERROR": "พารามิเตอร์การค้นหาต้องไม่ว่างเปล่า",
  "ccm.contact.label.inactive": "หมดอายุแล้ว",
  "ccm.sysparam.error.validate.notmatchrulealpha": "โปรดป้อนตัวอักษรและตัวเลขหรือขีดล่าง และขึ้นต้นด้วยตัวอักษรหรือขีดล่างเท่านั้น",
  "ccm.agent.label.CommomAgent": "เจ้าหน้าที่ทั่วไป",
  "ccm.callout.lable.reset": "รีเซ็ต",
  "ccm.agent.label.timeRemaining": "รหัสผ่านหมดอายุ (วัน)",
  "ccm.transtask.label.week": "สัปดาห์",
  "ccm.speciallist.msg.maxCountQuery": "ข้อมูลปัจจุบันมีขนาดใหญ่เกินไป ระบบจะแสดงข้อมูลล่าสุด {0} รายการเท่านั้น",
  "ccm.agent.pageurls.delete": "ลบ",
  "ccm.agent.contact.downloadvideo": "ดาวน์โหลดวิดีโอ",
  "ccm.speciallist.msg.operationsuccess": "ปฏิบัติการสําเร็จแล้ว",
  "ccm.recognition.label.characteristics": "ลักษณะเฉพาะ",
  "ccm.satisfactionconfig.message.updatesuccess": "อัปเดตการกำหนดค่าแบบสำรวจความพึงพอใจเรียบร้อยแล้ว",
  "ccm.botherlist.label.approveStatus.rejected": "ปฏิเสธ",
  "ccm.certificate.management.certificatefile.suffixerror.suffix": "หรือส่วนขยายว่าง",
  "ccm.pageinterface.objectName": "ชื่อวัตถุ",
  "ccm.agent.sysparamconfig.refreshSuccess": "รีเซ็ตสำเร็จในคลิกเดียว",
  "ccm.urlconfig.label.talkurl": "พูดคุย Url",
  "ccm.agent.label.fixedLine": "โทรศัพท์บ้าน / หมายเลขโทรศัพท์มือถือ",
  "ccm.agent.operation.ADJUSTVIDEOMODE": "การปรับขนาดหน้าต่างวิดีโอ",
  "ccm.calledconfig.message.samename": "ชื่อเดียวกันมีอยู่แล้ว",
  "ccm.agent.pageurls.independentlynosupported": "ไม่รองรับการกำหนดค่าหน้าภายในสำหรับการเปิดแบบอิสระ.",
  "ccm.satisfactionconfig.message.satis": "แบบสำรวจความพึงพอใจ",
  "ccm.querycustdata.msg.noData": "ไม่มีข้อมูล",
  "ccm.agent.message.airecognull": "ป้ายชื่อระบบจดจำอัจฉริยะว่าง",
  "ccm.note.config.policy.systemdefault": "ค่าปริยายของระบบ",
  "ccm.custdata.label.sucNum": "จำนวนบันทึกที่สำเร็จ",
  "ccm.agent.contact.noAuth": "ไม่ได้รับอนุญาต",
  "ccm.transtask.label.cantsave": "ไม่สามารถแก้ไขเซิร์ฟเวอร์ได้เนื่องจากมีการอ้างอิงโดยงานถ่ายโอนข้อมูล",
  "ccm.agent.label.usedMode.phone": "หมายเลขโทรศัพท์",
  "ccm.agent.contact.download": "ดาวน์โหลด",
  "ccm.agent.ten.tenantInfor": "ข้อมูลผู้เช่า",
  "ccm.agent.message.addSkillFail": "เพิ่มทักษะล้มเหลว",
  "ccm.agent.leavemessage.serviceContactNumber": "เบอร์โทรติดต่อ",
  "ccm.agent.restReason.confOnlyCauseCode": "การดำเนินการล้มเหลว. มีรหัสอยู่แล้ว",
  "ccm.agent.message.skillConfSuccess": "กำหนดค่าข้อมูลลำดับทักษะสำเร็จแล้ว",
  "ccm.taskrecord.label.executeSuccess": "การประมวลผลสําเร็จ",
  "ccm.speciallist.msg.crateerror": "การเพิ่มล้มเหลว",
  "ccm.urlconfig.label.relurl.callback.alerttip": "ที่อยู่ URL ที่จะส่งกลับเมื่อมีการเรียกใช้เพื่อสร้างอินเทอร์เฟซแบบคู่ขนาน กลับไปยัง URL ที่อยู่ URL ที่จะส่งกลับเมื่อสิ้นสุดการโทร มิฉะนั้นจะส่งกลับไปยังที่อยู่ URL ที่อยู่ URL ที่เข้ามา",
  "ccm.agent.contact.recordtime": "ระยะเวลาในการบันทึก(วินาที)",
  "ccm.transtask.label.skillReport": "รายงานประสิทธิภาพของคิวทักษะ",
  "ccm.custdata.operlog.addExportTask": "งานส่งออกใหม่",
  "ccm.transtask.label.skillSummaryReport": "รายงานสรุปคิวทักษะ",
  "ccm.agent.contact.QUERU_VCCINFO_FAIL_CODE": "ข้อมูลศูนย์บริการ ว่าง",
  "ccm.label.contactexport.selectall": "ทั้งหมด",
  "ccm.speciallist.label.redlist": "เรดลิสต์",
  "ccm.agent.pageurls.success": "สำเร็จ",
  "ccm.transserver.label.obs": "เซิร์ฟเวอร์ OBS",
  "ccm.agent.label.queuingCallsMaxNumRange": "ช่วงค่า: [0-10000]",
  "ccm.note.config.policy.custom": "การปรับแต่ง",
  "ccm.transtask.label.agentReport": "รายงานสรุปผลการดําเนินงานของตัวแทน",
  "ccm.vcallcenter.config.popup.currentaccount.pwd": "รหัสผ่านบัญชีปัจจุบัน",
  "ccm.transtask.label.contactrcddata": "ฟิลด์ในรายชื่อผู้ติดต่อ",
  "ccm.agent.accesscode.description": "คำอธิบายรหัสการเข้าถึง",
  "ccm.idauth.title.idauthconfig": "การกําหนดค่ากระบวนการรับรองความถูกต้อง",
  "ccm.agent.label.callDispatchModel.best": "เหมาะสมที่สุด",
  "ccm.agent.label.restQueueRule.applyFirst": "ครั้งแรก สมัครสําหรับการพักผ่อนลําดับความสําคัญ",
  "ccm.custdata.msg.duplicateColumnName": "ชื่อช่องที่ซ้ำกัน เปลี่ยนชื่อ",
  "ccm.agent.calledroute.create": "ใหม่",
  "ccm.contactitemconfig.label.data.extended.Type.DataDictionary": "พจนานุกรมข้อมูล",
  "ccm.agent.operation.searchcountid": "บัญชีบริการ",
  "ccm.callreason.label.disable": "ปิดการใช้งาน",
  "ccm.transserver.label.objobs": "โอบีเอส",
  "ccm.urlconfig.label.appsecret": "คีย์ที่ใช้ร่วมกัน",
  "ccm.urlconfig.label.key": "กุญแจลับ",
  "ccm.agent.resetskillrecord.adjustbusinessaccount": "ปรับบัญชีบริการ",
  "ccm.transtask.label.save": "ได้ค่ะ",
  "ccm.callout.message.resetfail": "รีเซ็ตพารามิเตอร์ไม่สำเร็จ",
  "ccm.speciallist.msg.successNum": "{limit}ประสบความสําเร็จ",
  "ccm.agent.recall.agent.status.invalid": "เอเย่นต์ไม่ได้อยู่ในสถานะว่างหรือยุ่งและไม่สามารถโทรกลับได้",
  "ccm.agent.message.pageNumFail": "จํานวนการกําหนดค่าหน้าต้องไม่เกิน {0}",
  "ccm.transtask.label.taskid": "หมายเลขงาน",
  "ccm.contact.customer.config.dataItemError": "ชื่อรายการข้อมูลหรือ ID ต้องไม่ซ้ํากัน.",
  "ccm.agent.button.batchmodify": "ปรับเปลี่ยนแบทช์",
  "ccm.custdata.operlog.importdata": "นำเข้าข้อมูล",
  "ccm.urlconfig.label.input.appId": "AccessKey",
  "ccm.agent.button.restores": "เรียกคืน",
  "ccm.agent.restReason.createFailed": "การเพิ่มล้มเหลว",
  "ccm.agent.label.skillWeightTip": "ถ้า Agent ได้รับหลาย queue, Skill Queue ที่มี weight สูงสุดจะได้รับสายก่อน",
  "ccm.agent.label.maxTalkTime": "ระยะเวลาการโทรสูงสุด (s)",
  "ccm.veridentity.fail.WORKNOPARSEERROR": "รหัสตัวแทนต้องเป็นตัวเลข",
  "ccm.custdata.option.maskStyle.retainFirst4": "สำรองสี่หลักแรก",
  "ccm.transserverparam.label.isagent": "เปิดใช้งานพร็อกซี",
  "ccm.agent.tips.confSuccess": "การกำหนดค่าสำเร็จ",
  "ccm.agent.tips.noagent": "ไม่มีเจ้าหน้าที่ในส่วนหมายเลขที่เลือก",
  "ccm.speciallist.label.operTime": "เวลาสร้าง",
  "ccm.agent.operation.noMoreThan30Days": "ช่วงเวลาต้องไม่เกิน 30 วัน",
  "ccm.agent.contact.fiveg": "5G RCS",
  "ccm.sysparam.config.reset.multiple.error": "ไม่สามารถรีเซ็ตพารามิเตอร์ต่อไปนี้:",
  "ccm.agent.message.quotingDataOperationFail": "ข้อมูลอ้างอิงโดยหน้าจอป๊อปอัป การดำเนินการล้มเหลว.",
  "ccm.agent.contact.refreshAll": "รีเฟรช",
  "ccm.custdata.operlog.download": "ดาวน์โหลด",
  "ccm.agent.pageurls.fail": "ข้อผิดพลาด",
  "ccm.recognition.label.cancelorderfailpd": "ยกเลิกการสมัครไม่สำเร็จ กรุณาลองอีกครั้งในภายหลัง",
  "ccmanagement.satisfaction.strategy.accepttime.hour": "hour",
  "ccm.transserver.label.cloudsearchserver.css": "การกําหนดค่า CSS",
  "ccm.agent.message.queryCallCenterFail": "สอบถามคอลเซ็นเตอร์ล้มเหลว",
  "ccmanagement.satisfaction.strategy.fail.accepttime.input.length": "ความยาวของเวลาเริ่มต้นหรือสิ้นสุดต้องไม่เกินขีด จํากัด บน",
  "ccm.speciallevel.speciallist.levelName": "ชื่อระดับ",
  "ccm.agent.calledroute.createfail": "เพิ่มข้อมูลในเครื่องไม่สำเร็จ",
  "ccm.speciallist.tip.timeVerification": "เลือกเวลาหลังเวลาปัจจุบันและก่อนหน้า 2038-01-19",
  "ccm.sysparam.error.validate.ruleinvalid": "กฎการตรวจสอบไม่ถูกต้อง",
  "ccm.sysparam.error.validate.ccme.alarmThreshold": "ความรุนแรงของการปลุกไม่สอดคล้องกับเกณฑ์การปลุก เกณฑ์การเตือนที่มีความรุนแรงต่ําต้องน้อยกว่า (เท่ากับ) เกณฑ์การเตือนที่มีความรุนแรงสูง",
  "ccm.sysparam.error.validate.ccme.daysAlarmOverSync": "พารามิเตอร์อินพุตต้องน้อยกว่าเวลาที่กําหนดค่าไว้ (7 วันตามค่าเริ่มต้น) สําหรับการย้ายผู้ใช้ออฟไลน์ไปยังตารางประวัติ",
  "ccm.agent.message.nulagentincti": "ไม่ได้สร้างเจ้าหน้าที่ที่เลือกในปัจจุบันใน CTI คุณต้องเลือกข้อมูลบทบาท",
  "ccm.agent.contact.satiscomment": "การประเมินความพึงพอใจ",
  "ccm.note.config.agent.page.windowTip": "การแจ้งเตือนกล่องโต้ตอบ",
  "ccm.agent.label.maxAgentWrapupTime": "ค่า [0–3600]",
  "ccmanagement.agent.batchmodify.agentlis": "ตามการจัดกลุ่ม",
  "ccm.agent.message.deletefailed": "ลบเจ้าหน้าที่ล้มเหลว",
  "ccm.sysparam.config.select.prompt": "ใบ้",
  "ccm.agent.contact.Voice": "เสียง",
  "ccm.agent.accesscode.accesscode": "หมายเลขช่องทางการเข้าถึง",
  "ccm.custdata.msg.fileSuffixValidateFail": "รูปแบบไฟล์ไม่ถูกต้อง เลือกไฟล์ CSV",
  "ccm.agent.message.accRepeatFail": "พารามิเตอร์บัญชีซ้ำกัน",
  "ccm.dual.call.record.agentTalkingTime": "เวลารับสายของฝ่ายโทร",
  "ccm.anonymous.message.fail.notmatch": "ธงคุณสมบัติไม่ตรงกับสถานะปัจจุบัน",
  "ccm.sysparam.config.itemname": "ชื่อพารามิเตอร์",
  "ccm.transtask.delete.confirm": "คุณแน่ใจหรือไม่ว่าคุณต้องการลบงานทิ้งและประวัติการทํางาน",
  "ccm.agent.label.conditionOfBusyTransfer": "เงื่อนไขการโอนไม่ว่าง",
  "ccm.speciallist.title.specialList": "รายการพิเศษ",
  "ccm.transtask.label.chatrecordday": "จํานวนวันก่อนที่ข้อมูลแชทจะถูกทิ้ง",
  "ccm.agent.label.ccRole": "บทบาท",
  "ccm.agent.label.withinFifteenDays": "ภายใน 15 วัน",
  "ccm.agent.contact.downloadrecord": "ดาวน์โหลดการบันทึก",
  "ccm.agent.message.willcancelconfig": "การยกเลิกการตั้งค่าจะทําให้ตัวแทนที่ใช้ไม่สามารถใช้งานได้ คุณแน่ใจหรือไม่ว่าคุณต้องการยกเลิกการตั้งค่า",
  "ccm.contact.customer.config.bold": "ตัวหนา",
  "ccm.botherlist.label.approveStatus.toApprove": "รอการอนุมัติ",
  "ccm.dual.call.record.reasonCode": "เหตุผลที่ปล่อย",
  "ccm.speciallist.msg.userNo": "หมายเลขสมาชิก",
  "ccm.agent.message.selected": "คุณได้เลือก",
  "ccm.speciallist.msg.maxlimit": "จํานวนเร็กคอร์ดสูงสุดเกิน {limit} รายการ",
  "ccm.agent.pageurls.isenable": "เปิดใช้งาน",
  "ccm.agent.pageurls.delsuccess": "ลบสำเร็จ.",
  "ccm.agent.button.createComplete": "เสร็จสมบูรณ์",
  "ccm.sysparam.config.refresh": "รีเฟรช",
  "ccm.contact.customer.config.datasourceType": "ประเภทแหล่งข้อมูล",
  "ccm.transtask.label.datatype": "ประเภทข้อมูลการถ่ายโอนข้อมูล",
  "ccm.custdata.option.maskStyle.maskLast4": "รุปแบบสี่หลักสุดท้าย",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnull": "วันที่เริ่มต้นหรือสิ้นสุดไม่สามารถเว้นว่างได้",
  "ccm.certificate.management.certificatefile.injectionerror": "ไฟล์มีความเสี่ยงในถูกจู่โจม หรือโจมตี",
  "ccm.calledconfig.message.noskill": "กรุณาเลือกลำดับทักษะ",
  "ccm.pageconfig.label.numInRow": "จำนวนแถว",
  "ccm.agent.label.CallOutAgent": "เจ้าหน้าที่ Outbound",
  "ccm.satisfactionconfig.message.number": "จำนวน",
  "ccm.agent.message.searchAccountName": "ป้อนบัญชี",
  "ccm.agentparam.message.editsuccess": "แก้ไขเรียบร้อยแล้ว",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputparse": "เวลาเริ่มต้นหรือสิ้นสุดอยู่ในรูปแบบที่ไม่ถูกต้อง",
  "ccm.calledconfig.message.selectcasetype": "เลือกประเภทเคส",
  "ccm.agent.calledroute.delete.work.use": "การตั้งค่าของคู่สายที่เรียกว่าถูกอ้างถึงโดยเจ้าหน้าที่และไม่สามารถลบได้",
  "ccm.contactitemconfig.label.data.extended.writeback.extended.field": "ฟิลด์การเขียนกลับแบบขยาย",
  "ccm.ucconfig.message.clientId": "ID แอปพลิเคชัน (ไคลเอ็นต์)",
  "ccm.agent.speciallist.levelName": "ระดับรายการพิเศษ",
  "ccm.certificate.management.update.crlfile": "อัปเดตไฟล์ CRL",
  "ccm.certificate.management.certificatefile.fileempty": "ไฟล์ว่างเปล่า",
  "ccm.custdata.title.create": "การสร้างตารางฐาน",
  "ccm.agent.contact.multimedia.email": "มัลติมีเดีย-Email",
  "ccm.idauth.create.error.format": "การตรวจสอบสิทธิ์การเข้ารหัสรหัสเฉพาะตัวเลข ตัวอักษร และขีดล่างเท่านั้น",
  "ccmanagement.satisfaction.strategy.acceptdate.enddate": "วันสิ้นสุด",
  "ccm.custdata.option.columnType.number": "จำนวน",
  "ccm.idauth.create.error.name": "ชื่อโหมดการตรวจสอบสิทธิ์มีอยู่แล้ว",
  "ccm.agentparam.message.editfail": "การแก้ไขล้มเหลว",
  "ccm.agent.label.mobileagentremark": "หมายเหตุ",
  "ccm.agent.operation.INTERCEPT": "การดึงสาย",
  "ccm.agent.message.worknostartbig": "ID พนักงานเริ่มต้นต้องไม่มากกว่า ID พนักงานปลายทาง",
  "ccm.agent.contact.faceBook": "Facebook",
  "ccm.agent.label.busyRateRange": "ช่วงค่า: [0-100]",
  "ccm.agent.pageurls.operation": "การดำเนินการ",
  "ccm.contactitemconfig.label.data.item.extended": "ขยายข้อมูล",
  "ccm.sysparam.config.title": "พารามิเตอร์ผู้เช่า",
  "ccm.custdata.label.beginTime": "เวลาเริ่มต้น",
  "ccm.agent.contact.callout": "โทรออก",
  "ccm.custdata.msg.uploadSelect": "เลือกไฟล์",
  "ccm.agent.contact.mediaType": "ประเภทสื่อ",
  "ccm.botherlist.label.approveComment.reject": "ปฏิเสธ",
  "ccm.ivrvoice.play.error": "กำลังโหลดข้อยกเว้นทรัพยากรโปรดตรวจสอบการกำหนดค่าที่เกี่ยวข้อง.",
  "ccm.contact.details.tab.title.audio": 'เสียง',
  "ccm.contact.details.tab.title.video": 'วิดีโอ',
  "ccm.contact.placeholder.searchCustomerNo": 'เบอร์โทรติดต่อ',
  "ccm.agent.contact.transfercontent": 'ผลลัพธ์ ASR',
  "ccm.agent.label.oneday": '1 วัน',
  "ccm.agent.label.threedays": '3 วัน',
  "ccm.agent.label.sevendays": '7 วัน',
  "ccm.agent.label.thirtydays": '30 วัน',
  "ccm.contactitemconfig.message.noBaseData": "ไม่มีข้อมูลพื้นฐาน",
  "ccm.label.downloadAttachment.confirm": "คุณแน่ใจหรือไม่ว่าต้องการดาวน์โหลดสิ่งที่แนบมาที่เลือก?",
  "ccm.contact.detail.talk.content.seachtips": "ผลการค้นหา: {num}",
  "ccm.contact.detail.talk.content.not.support": "ข้อความค้นหาไม่สามารถมี\"/()",
  "ccm.contact.detail.talk.content.seach.remark": "สามารถค้นหาได้เฉพาะเนื้อหาการแชทแบบข้อความที่แสดงบนหน้านี้เท่านั้น",
  "ccm.contact.detail.talk.content.seach.remark.emaile": "สามารถค้นหาได้เฉพาะข้อความที่แสดงในหน้าค้นหา หลังจากการค้นหาเสร็จสมบูรณ์ จะแสดงเฉพาะข้อความที่ตรงกันเท่านั้น",
  "ccm.download.passwd.validator.specialStr": "อักขระพิเศษ อนุญาตเฉพาะ _ {'@'} {'%'}",
  "ccm.agent.label.selfPhoneNotNull": "เมื่อเปิดใช้งานคุณสมบัติการไม่เปิดเผยชื่อหมายเลขเอเจนต์ หมายเลขโทรศัพท์สายประจําหรือหมายเลขโทรศัพท์มือถือของเอเจนต์จะไม่สามารถเว้นว่างไว้ได้",
  "ccm.login.record.noMatchingLoginRecordExists": 'ไม่มีบันทึกการลงชื่อเข้าใช้ที่มีสิทธิ์',
  "ccm.agent.message.searchSkillQueueName": "ชื่อคิวทักษะ",
  "ccm.agent.button.skill.synchronize": "ซิงโครไนซ์",
  "ccm.skillgroup.label.groupid": "รหัสผ่าน",
  "ccm.skillgroup.message.groupBind": "ไม่สามารถเลือกชนิดของการแสดงออกที่เชื่อมโยงกับกลุ่มทักษะได้",
  "ccm.skillgroup.label.groupname": "ชื่อกลุ่มทักษะ",
  "ccm.skillgroup.label.orgname": "หน่วยงาน",
  "ccm.agent.label.skillgroup": "กลุ่มทักษะ",
  "ccm.skillgroup.label.searchname": "ชื่อกลุ่มทักษะ",
  "ccm.skillgroup.label.groupskills": "การเชื่อมโยงคิวทักษะ",
  "ccm.skillgroup.title.add": "การเพิ่มกลุ่มทักษะ",
  "ccm.skillgroup.title.update": "อัปเดตกลุ่มทักษะ",
  "ccm.skillgroup.msg.deleteerror": "โปรดเลือกกลุ่มทักษะที่จะลบ",
  "ccm.skillgroup.create.fail": "ล้มเหลวในการสร้างกลุ่มทักษะ",
  "ccm.skillgroup.update.fail": "ล้มเหลวในการอัพเดทกลุ่มทักษะ",
  "ccm.skillgroup.update.failtip": "ไม่สามารถอัปเดตกลุ่มทักษะได้ ซิงโครไนซ์คิวทักษะและข้อมูลกลุ่มทักษะก่อน",
  "ccm.skillgroup.query.fail": "ล้มเหลวในการรับค่าปรับแต่งกลุ่มทักษะ",
  "ccm.skillgroup.message.nameValidateFail": "กลุ่มทักษะไม่สามารถมีอักขระพิเศษต่อไปนี้: [<>\"()]&=;,",
  "ccm.skillgroup.message.namePrefixSuffix": "กลุ่มทักษะไม่สามารถเริ่มหรือจบด้วยช่องว่าง",
  "ccm.skillgroup.message.nameLimit": "ชื่อกลุ่มทักษะเกินความยาว",
  "ccm.skillgroup.message.nameExistence": "มีชื่อกลุ่มทักษะอยู่แล้ว",
  "ccm.agent.message.skillweight.invalid": "น้ําหนักทักษะต้องอยู่ในช่วงตั้งแต่ 1 ถึง 100",
  "ccm.agent.message.agentweight.invalid": "น้ําหนักของตัวแทนต้องอยู่ระหว่าง 1 ถึง 300",
  "ccm.agent.message.skillweight.outlimit": "ค่าน้ําหนักต้องอยู่ในช่วงตั้งแต่ 1 ถึง 100",
  "ccm.agent.download.forbidden": "ไม่มีสิทธิ์ดาวน์โหลด",
  "ccm.title.org.all": "โปรดเลือกองค์กร",
  'ccm.title.template.select.email': 'เลือกเทมเพลตอีเมล',
  'ccm.title.template.select.sms': 'เลือกเทมเพลต SMS',
  "ccm.skillgroup.create.limit": "สามารถสร้างกลุ่มทักษะได้สูงสุด 2000 กลุ่ม",
  "ccm.skillgroup.msg.deleteTips": "กลุ่มทักษะปัจจุบันเชื่อมโยงกับเจ้าหน้าที่ {0} แล้ว ลบการเชื่อมโยงแล้วลบออก",
  "ccm.skillgroup.create.failtip": "การสร้างกลุ่มทักษะล้มเหลว โปรดซิงค์ข้อมูลของกลุ่มทักษะและทักษะก่อน",
  "ccm.sendmsg.whatsapp.withoutTemplate": "ไม่มีเนื้อหา โปรดเลือกเทมเพลตก่อน",
  "ccm.skillgroup.msg.deleteFailed": "การลบกลุ่มทักษะล้มเหลว",
  "ccm.agent.label.skillType": "ประเภททักษะ",
  "ccm.agent.label.selectSkillGroup": "เลือกกลุ่มทักษะ",
  "ccm.skill.label.defaultskill": "คิวทักษะปริยาย",
  "ccm.agent.leavemessage.overtime": "หมดเวลาการประมวลผลแล้ว โปรดเปิดหน้าต่างป็อปอัพอีกครั้ง",
  "ccm.agent.message.samepassword": "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านปัจจุบัน",
  "ccm.agent.skill.nameInfo": "ชื่อคิวทักษะ:",
  "ccm.agent.ten.daylightSavingTime": "เวลาฤดูร้อน",
  "ccm.agent.ten.DSTEndDate": "วันที่สิ้นสุดของฤดูร้อน",
  "ccm.agent.ten.DSTEndTime": "เวลาสิ้นสุดฤดูร้อน",
  "ccm.agent.ten.dstOff": "ปิดการใช้งาน",
  "ccm.agent.ten.DSTOffset": "การเบี่ยงเบนเวลาฤดูร้อน (นาที)",
  "ccm.agent.ten.dstOn": "เปิดใช้งานแล้ว",
  "ccm.agent.ten.DSTStartDate": "วันเริ่มต้นฤดูร้อน",
  "ccm.agent.ten.DSTStartTime": "เวลาเริ่มต้นฤดูร้อน",
  "ccm.agent.ten.systemdefault": "เขตเวลาเริ่มต้น",
  "ccm.agent.ten.timezone": "โซนเวลา",
  "ccm.agent.ten.timezoneOffset": "ชดเชยโซนเวลา",
  "ccm.callback.url.auth.tip": "โปรดเลือกวิธีการใช้ที่อยู่ URL ของโพรเซส",
  "ccm.callback.url.callback": "การกําหนดค่าการโทรกลับแบบสองทิศทาง",
  "ccm.certificate.message.tip.warning": "อัลกอริทึมลายเซ็นและความยาวของคีย์ของใบรับรองไม่เป็นไปตามข้อกําหนดซึ่งมีความเสี่ยง คุณแน่ใจหรือว่าต้องการดําเนินการต่อ?",
  "ccm.nms.satisfactionservice.buttonNumber": "จํานวนคีย์แบบสํารวจความพึงพอใจไม่เกิน 5 และไม่น้อยกว่า 3",
  "ccm.operlog.contactexport.download": "ดาวน์โหลดบันทึกการติดต่อ",
  "ccm.recognition.label.closeVoiceNotificationSuccess": "คุณสมบัติการแจ้งเตือนด้วยเสียงถูกปิดใช้งานสําเร็จแล้ว",
  "ccm.recognition.label.modifyVoiceNotificationClose": "คุณสมบัติการแจ้งเตือนด้วยเสียงไม่ได้เปิดใช้งานสําหรับพื้นที่ผู้เช่า",
  "ccm.recognition.label.openVoiceNotificationSuccess": "เปิดใช้งานคุณสมบัติการแจ้งเตือนด้วยเสียงสําเร็จแล้ว",
  "ccm.recognition.label.voiceNotification": "แฟล็กการแจ้งเตือนด้วยเสียง",
  "ccm.satisfaction.message.resetconfirm": "คุณแน่ใจหรือไม่ว่าต้องการรีเซ็ตระดับความพึงพอใจและเทมเพลตการสํารวจความพึงพอใจ แต่ละช่องจําเป็นต้องใช้ระดับความพึงพอใจรีเซ็ตและเนื้อหาเทมเพลต",
  "ccm.satisfactionconfig.message.choosestrategy": "กลุ่มนโยบายถูกอ้างอิงโดยช่องทางและไม่สามารถลบได้",
  "ccm.satisfactionconfig.message.memberstrategyconfig": "*เลือกกลยุทธ์การสํารวจความพึงพอใจ",
  "ccm.satisfactionlevel.message.leastcalltype": "โปรดเลือกประเภทการโทรอย่างน้อยหนึ่งประเภท",
  "ccm.satisfactionservice.evaluationerror": "การประเมินผลล้มเหลว",
  "ccm.satisfactionservice.noevaluation": "ยังไม่ได้ประเมิน",
  "ccm.satisfactiontemplate.message.resetfail": "ไม่สามารถรีเซ็ตเนื้อหาของเทมเพลตการสํารวจความพึงพอใจได้",
  "ccm.satisfactiontemplate.message.savefail": "การบันทึกเทมเพลตแบบสํารวจความพึงพอใจล้มเหลว",
  "ccm.urlconfig.label.noramalOrITA": "เลือกการโทรแบบสองทิศทาง CEC หรือประเภทการโทรแบบสองทิศทาง ITA",
  "ccm.urlconfig.label.noramalOrITA.input": "การกําหนดค่าพารามิเตอร์ ITA",
  "ccm.urlconfig.label.numberAllocationMode1": "อัตโนมัติ",
  "ccm.urlconfig.label.numberAllocationMode2": "ระบุ",
  "ccm.urlconfig.label.oauth.label": "ข้อมูลการอนุญาต OAuth 2.0",
  "ccm.urlconfig.label.radio.radioType1": "CEC การโทรแบบสองทิศทาง",
  "ccm.urlconfig.label.radio.radioType2": "ITA การโทรแบบสองทิศทาง",
  "ccm.urlconfig.message.missing.appIdOrAppSecret": "ไม่มีการตั้งค่าคีย์การเข้าถึงหรือคีย์ลับในการกําหนดค่าการโทรแบบสองทิศทางของ ITA",
  "ccm.urlconfig.message.missing.appKeyOrDomainName": "ต้องตั้งค่าคีย์แอปและชื่อโดเมนในการกําหนดค่าการโทรแบบสองทิศทางของ ITA",
  "ccm.urlconfig.message.notNumber.xNumber": "หมายเลข X ที่กําหนดค่าสําหรับการโทรแบบสองทิศทางของ ITA ไม่ใช่หมายเลข",
  "ccm.urlconfig.message.oauth.tip.warning": "การไม่มีการตรวจสอบ URL ของการติดต่อกลับอาจทําให้เกิดความเสี่ยงด้านความปลอดภัย คุณแน่ใจหรือไม่ว่าต้องการใช้โหมดการตรวจสอบสิทธิ์นี้",
  "ccm.urlconfig.message.overlimit.appId": "รหัสการเข้าถึงที่กําหนดค่าสําหรับการโทรแบบสองทิศทาง ITA ต้องมีความยาวไม่เกิน 128 อักขระ",
  "ccm.urlconfig.message.overlimit.appKey": "ปุ่มแอปในการกําหนดค่าการโทรแบบสองทิศทางของ ITA ต้องไม่เกิน 128 อักขระ",
  "ccm.urlconfig.message.overlimit.appSecret": "คีย์ลับที่กําหนดค่าสําหรับการโทรแบบสองทิศทางของ ITA ต้องมีความยาวไม่เกิน 512 อักขระ",
  "ccm.urlconfig.message.overlimit.domainName": "ชื่อโดเมนในการกําหนดค่าการโทรแบบสองทิศทางของ ITA ต้องไม่เกิน 128 ตัวอักษร",
  "ccm.urlconfig.message.overlimit.xNumber": "หมายเลข X ที่กําหนดค่าไว้สําหรับการโทรแบบสองทิศทาง ITA ต้องมีความยาวไม่เกิน 20 อักขระ",
  "ccm.urlconfig.message.tip.warning": "ตรวจสอบให้แน่ใจว่าคุณได้ซื้อบริการที่เกี่ยวข้องกับ ITA ไม่อย่างนั้น การโทรจะมีผล",
  "ccm.urlconfig.message.warning": "ตรวจสอบว่า URL พุช CDR ได้รับการกําหนดค่าบน ITA แล้ว มิฉะนั้น ITA ไม่สามารถผลักดัน CDRs ให้กับ CEC ได้ ตัวอย่าง Push URL มีดังนี้:",
  "ccm.urlconfig.modify.authbothnull": "ข้อมูลการอนุมัติไม่สามารถเว้นว่างไว้ได้",
  "ccm.urlconfig.modify.authtype2": "OAuth 2.0",
  "ccm.urlconfig.modify.oauth.ak.tooshort": "AK ต้องมีอักขระอย่างน้อย 16 ตัว",
  "ccm.urlconfig.modify.oauth.sk.tooshort": "SK ต้องมีอักขระอย่างน้อย 16 ตัว",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputlength": "ความยาวของเวลาเริ่มต้นหรือสิ้นสุดต้องไม่เกินขีด จํากัด บน",
  "ccmanagement.tenantspace.option.month_1": "มกราคม",
  "ccmanagement.tenantspace.option.month_2": "กุมภาพันธ์",
  "ccmanagement.tenantspace.option.month_3": "มีนาคม",
  "ccmanagement.tenantspace.option.month_4": "เมษายน",
  "ccmanagement.tenantspace.option.month_5": "พฤษภาคม",
  "ccmanagement.tenantspace.option.month_6": "มิถุนายน",
  "ccmanagement.tenantspace.option.month_7": "กรกฎาคม",
  "ccmanagement.tenantspace.option.month_8": "สิงหาคม",
  "ccmanagement.tenantspace.option.month_9": "กันยายน",
  "ccmanagement.tenantspace.option.month_10": "ตุลาคม",
  "ccmanagement.tenantspace.option.month_11": "พฤศจิกายน",
  "ccmanagement.tenantspace.option.month_12": "ธันวาคม",
  "ccmanagement.tenantspace.option.weekday_mon": "วันจันทร์",
  "ccmanagement.tenantspace.option.weekday_tue": "วันอังคาร",
  "ccmanagement.tenantspace.option.weekday_wed": "วันพุธ",
  "ccmanagement.tenantspace.option.weekday_thu": "วันพฤหัสบดี",
  "ccmanagement.tenantspace.option.weekday_fri": "วันศุกร์",
  "ccmanagement.tenantspace.option.weekday_sat": "วันเสาร์",
  "ccmanagement.tenantspace.option.weekday_sun": "วันอาทิตย์",
  "ccmanagement.tenantspace.option.weeklist_fir": "ครั้งแรก",
  "ccmanagement.tenantspace.option.weeklist_sec": "ครั้งที่สอง",
  "ccmanagement.tenantspace.option.weeklist_tid": "คนที่สาม",
  "ccmanagement.tenantspace.option.weeklist_fth": "ลําดับที่ 4",
  "ccmanagement.tenantspace.option.weeklist_fthtolast": "สุดท้าย แต่สาม",
  "ccmanagement.tenantspace.option.weeklist_tidtolast": "สุดท้าย แต่สอง",
  "ccmanagement.tenantspace.option.weeklist_sectolast": "สุดท้าย แต่หนึ่ง",
  "ccmanagement.tenantspace.option.weeklist_firtolast": "ล่าสุด",
  "ccm.notifition.sendmessage.smsconfignull":"การกําหนดค่าข้อความ SMS ว่างเปล่า ศูนย์การกําหนดค่า > ศูนย์การกําหนดค่า > การกําหนดค่า SMS เพื่อกําหนดค่าข้อมูล SMS",

  "iolp.label.basicInfo": "ข้อมูลพื้นฐาน",
  "iolp.label.workflow": "เวิร์กโฟลว์",
  "iolp.workflow.node.title": "การตั้งค่าแอตทริบิวต์โหนดสําเร็จ",
  "iolp.workflow.line.title": "การตั้งค่าคุณสมบัติการเชื่อมต่อเสร็จสมบูรณ์",
  "iolp.workflow.node.label.name": "ชื่อ",
  "iolp.workflow.node.label.nodeType": "ประเภทโหนด",
  "iolp.workflow.node.label.nodeId": "โหนดid",
  "iolp.workflow.node.label.executeType": "ประเภทการดําเนินการ",
  "iolp.workflow.node.label.autoNodeInfo": "ผลลัพธ์ของโหนดอัตโนมัติตรงกับหมายเลข",
  "iolp.workflow.node.label.executeContent": "ประมวลผลเนื้อหา",
  "iolp.workflow.node.label.explain": "ธุรกิจ",
  "iolp.workflow.node.label.associateKbs": "สมาคมความรู้",
  "iolp.workflow.node.label.associateBusiness": "ลิงก์การจัดการบริการที่เชื่อมโยงกัน",
  "iolp.workflow.node.label.hintTextInfo": "รายละเอียดข้อมูล",
  "iolp.workflow.node.label.sms": "เทมเพลต SMS",
  "iolp.workflow.node.label.associateJs": "คุณสมบัติ js ที่เกี่ยวข้อง",
  "iolp.workflow.nodetype.andsign": "โหนดงาน",
  "iolp.workflow.nodetype.fork": "โหนดการตัดสินใจ",
  "iolp.workflow.nodetype.andjoin": "การรวมกัน",
  "iolp.workflow.executetype.common": "ชนิดปกติ",
  "iolp.workflow.executetype.SMS": "ส่งข้อความ",
  "iolp.workflow.executetype.email": "ส่งข้อความ",
  "iolp.workflow.executetype.IVR": "ย้ายไปยัง ivr อัตโนมัติ",
  "iolp.workflow.executetype.auto": "งานอัตโนมัติ",
  "iolp.workflow.executetype.subFlow": "ไปต่อกระบวนการย่อยของธุรกิจอื่น ๆ",
  "iolp.workflow.executetype.case": "สร้างคําสั่ง",
  "iolp.configur.service.label.basicInfo": "ข้อมูลพื้นฐาน",
  "iolp.configur.service.label.workflow": "กระบวนการแนะนํา",
  "iolp.configur.service.label.name": "ชื่อ",
  "iolp.configur.service.label.workflow.name": "ชื่อกระบวนการ",
  "iolp.configur.service.label.directory.name": "ชื่อหมวดหมู่",
  "iolp.configur.service.label.serviceCode": "รหัสธุรกิจ",
  "iolp.configur.service.label.upperLevelServiceCode": "การเข้ารหัสธุรกิจระดับสูง",
  "iolp.configur.service.label.imageUrl": "ที่อยู่รูปภาพ",
  "iolp.configur.service.label.customizedPageUrl": "ที่อยู่หน้าที่กําหนดเอง",
  "iolp.configur.service.label.customizedPageContainerId": "การระบุตัวคอนเทนเนอร์ของหน้าเว็บแบบกําหนดเอง",
  "iolp.configur.servicelabel.shortcutButtonPage": "ทางลัดปุ่มที่อยู่หน้า",
  "iolp.configur.service.label.shortcutButtonContainerId": "ปุ่มด่วนแสดงตัวคอนเทนเนอร์",
  "iolp.configur.service.label.search.tips": "โปรดป้อนชื่อ",
  "iolp.configur.service.add": "สร้างใหม่",
  "iolp.configur.service.operate.edit": "แก้ไข",
  "iolp.configur.service.operate.delete": "ลบ",
  "iolp.configur.service.tips.customizedPageUrl": "ตัวอย่าง: CustomerInfoDemo.html",
  "iolp.configur.service.add.select.title": "เลือกชื่อหมวดหมู่",
  "iolp.configur.service.add.parent.title": "ชื่อหมวดหมู่ย่อย",
  "iolp.configur.service.dialog.root.directory.title": "ไม่มีหมวดหมู่แม่",
  "iolp.configur.service.add.operate.title": "เพิ่มประเภท",
  "iolp.configur.service.dialog.add.directory.title": "สร้างหมวดหมู่การบูตธุรกิจใหม่",
  "iolp.configur.service.dialog.modify.directory.title": "แก้ไขหมวดหมู่การบูตธุรกิจ",
  "iolp.configur.service.dialog.add.workflow.title": "สร้างกระบวนการแนะนําธุรกิจใหม่",
  "iolp.configur.service.dialog.modify.workflow.title": "แก้ไขกระบวนการแนะนําธุรกิจ",
  "iolp.configur.servicelabel.label.serialNumber": "เรียงลําดับ",
  "iolp.configur.servicelabel.label.status": "สถานะ",
  "iolp.configur.servicelabel.label.description": "รายละเอียดข้อมูล",
  "iolp.configur.servicelabel.button.saveDraft": "บันทึกเป็นแบบร่าง",
  "iolp.configur.servicelabel.status.draft": "ร่าง",
  "iolp.configur.servicelabel.button.publish": "เผยแพร่แล้ว",
  "iolp.configur.servicelabel.common.tips.error": "ข้อผิดพลาด",
  "iolp.configur.servicelabel.common.tips.success": "ความสําเร็จ",
  "iolp.tree.tips": "คุณไม่สามารถเพิ่มโหนดใด ๆ ภายใต้โหนดใบไม้ได้",
  "iolp.configur.servicelabel.save.successful": "บันทึกความสําเร็จ",
  "iolp.configur.servicelabel.release.successful": "การเผยแพร่สำเร็จ",
  "iolp.configur.servicelabel.save.failed": "การบันทึกล้มเหลว",
  "iolp.configur.servicelabel.release.failed": "ล้มเหลวในการโพสต์",
  "iolp.configur.servicelabel.contact.administrator": "การสร้างรหัสบริการล้มเหลว โปรดติดต่อผู้ดูแลระบบ",
  "iolp.configur.servicelabel.select.node": "โปรดเลือกโหนด",
  "iolp.configur.servicelabel.confirm.delete": "ลบ",
  "iolp.configur.servicelabel.confirm.deleteAll": "แน่ใจหรือไม่ว่าต้องการลบโหนดปัจจุบันและโหนดย่อย?",
  "iolp.configur.servicelabel.confirm.deleteCurrent": "แน่ใจหรือไม่ว่าต้องการลบโหนดปัจจุบัน?",
  "iolp.configur.servicelabel.delete.successful": "ลบสําเร็จ",
  "iolp.configur.servicelabel.delete.failed": "การลบล้มเหลว",
  "iolp.configur.servicelabel.tips.info": "การแจ้งเตือน",
  "iolp.workflow.label.graphZoomOut": "ซูมออก",
  "iolp.workflow.label.graphZoomIn": "ซูมเข้า",
  "iolp.workflow.label.previous": "ยกเลิก",
  "iolp.workflow.label.next": "การกู้คืน",
  "iolp.workflow.label.cleanGraph": "ล้างผ้าใบ",
  "iolp.workflow.label.taskElement": "องค์ประกอบไดอะแกรมฟังก์ชั่น",
  "iolp.workflow.label.decisionElement": "องค์ประกอบของแผนภาพสาขา",
  "iolp.workflow.label.operation": "การดําเนินการ",
  "iolp.workflow.label.information": "ข้อมูล",
  "iolp.workflow.label.unmatchedFlow": "ไม่สามารถจับคู่บริการที่เกี่ยวข้องได้",
  "iolp.workflow.button.restart": "รีบูตกระบวนการ",
  "iolp.workflow.button.hideFlowChart": "แผนผังที่ซ่อนอยู่",
  "iolp.workflow.button.showFlowChart": "แสดงแผนภูมิการไหล",
  "iolp.workflow.label.currentNodeName": "ชื่อโหนดปัจจุบัน",
  "iolp.workflow.label.customizeInfo": "ข้อมูลที่กําหนดเอง",
  "iolp.workflow.label.flowInfo": "ข้อมูลกระบวนการทางธุรกิจ",
  "iolp.workflow.button.kbs": "ฐานความรู้",
  "iolp.workflow.button.service": "การจัดการธุรกิจ",
  "iolp.workflow.title.name": "กระบวนการทางธุรกิจ",
  "iolp.configur.service.label.service.title": "ประเภทธุรกิจ",
  "iolp.run.service.label.customizedPage": "ข้อมูลการปรับแต่ง",
  "iolp.run.service.label.overview": "ภาพรวมกระบวนการ",
  "iolp.run.service.label.nodeName": "ชื่อโหนด:",
  "agentconsole.ccnotification.recipient.validate.error": "แต่ละหมายเลขสามารถมีได้เฉพาะตัวเลขและสูงสุด20หลัก",
  "ccm.sendwhatsapp.content.too.long": "เนื้อหาเทมเพลตยาวเกินไป",
  "ccm.sendwhatsapp.body.too.long": "เนื้อหาร่างกายยาวเกินไป",
  "ccm.notifition.sendmessage.largerthanlimit":"จํานวนผู้รับเกินจํานวนสูงสุด {n}",
  "ccm.satisfactiontemplate.tips": "หมายเหตุ: ระบบจะสร้างเนื้อหาเทมเพลตโดยใช้ภาษาเริ่มต้นของผู้เช่า การสํารวจความพึงพอใจของ Instagram, Whatsapp, Line, Web Chat, Face Book, X (Twitter), 5G RCS, SMS จะตรวจสอบเนื้อหาเทมเพลตข้อความด้วยความพึงพอใจนี้เพื่อส่งข้อความแบบสํารวจความพึงพอใจให้กับลูกค้า",
  "ccm.ccagent.management.batch.operation":"การทํางานของแบทช์",
  "ccm.satisfactionlevel.title.templateupdate": "อัปเดตตามแม่แบบระบบ",
  "ccm.skillgroup.message.noskill": "ห้ามเว้นว่างรายการทักษะของกลุ่มทักษะ",
  "ccm.ccagent.management.advanced.search": "การค้นหาขั้นสูง",
  "ccm.satisfactionconfig.message.textmsg": "ข้อความข้อความ",
  "ccm.ccagent.management.cannot.exceed.25":"ตัวเลขต้องไม่เกิน 25 ตัวอักษร",
  "ccm.satisfactiontemplate.title":"การกําหนดค่าเนื้อหาแม่แบบการสํารวจความพึงพอใจ",
  "ccm.satisfactiontemplate.icontitle": "อัปเดตเนื้อหาเทมเพลตตามระดับความพึงพอใจ",
  "ccm.satisfaction.leveltips":"หมายเหตุ: ทุกช่องทางใช้การกําหนดค่าความพึงพอใจเดียวกัน",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputlength": "ระยะเวลาการโทรขั้นต่ําหรือสูงสุดสามารถมีได้สูงสุด 12 หลัก",
  "ccm.ccagent.management.default.options":"ตัวเลือกเริ่มต้น",
  "ccm.agent.callreason.must": "จําเป็นสําหรับเหตุผลในการโทร.",
  "iolp.workflow.efficiency.analysis":"การวิเคราะห์ประสิทธิภาพ",
  "iolp.configur.servicelabel.label.version": "เวอร์ชันที่วางจําหน่าย",
  "iolp.configur.servicelabel.label.version.number": "จํานวนรุ่น",
  "iolp.configur.servicelabel.status.abandon": "ไม่ถูกต้อง",
  "iolp.configur.servicelabel.status.publish": "ปล่อย",
  "iolp.configur.servicelabel.status.unpublish": "ยังไม่ได้เผยแพร่",
  "iolp.configur.service.operate.copy": "การจําลองแบบ",
  "iolp.configur.service.operate.publish": "ปล่อย",
  "iolp.configur.service.operate.cancel": "ยกเลิกการเผยแพร่",
  "iolp.configur.servicelabel.publish.successful": "เผยแพร่เรียบร้อยแล้ว",
  "iolp.configur.servicelabel.publish.failed": "ไม่สามารถเผยแพร่ได้",
  "iolp.configur.servicelabel.cancel.successful": "ยกเลิกรุ่นเรียบร้อยแล้ว",
  "iolp.configur.servicelabel.cancel.failed": "ยกเลิกไม่สําเร็จ",
  "iolp.configur.servicelabel.copy.successful": "คัดลอกเรียบร้อยแล้ว.",
  "iolp.configur.servicelabel.copy.failed": "การจําลองแบบล้มเหลว",
  "iolp.configur.servicelabel.confirm.delete.flow": "แน่ใจหรือว่าต้องการลบเวอร์ชันปัจจุบัน",
  "iolp.process.utilization.efficiency.analysis":"การวิเคราะห์ประสิทธิภาพการใช้กระบวนการ",
  "iolp.flow.number.of.contacts":"จํานวนหน้าสัมผัส(ชิ้น)",
  "iolp.flow.first.solution.rate":"อัตราการแก้ปัญหาครั้งแรก (%)",
  "iolp.flow.please.select.time.before.today":"โปรดเลือกเวลาก่อนวันนี้",
  "iolp.flow.the.interval.validate":"ช่วงเวลาคือ 1 วันหรือ 3 - 30 วัน",
  "iolp.flow.analysis.date.time":"วันที่/เวลา",
  "ccm.empty.template": "ไม่มีแม่แบบนี้อยู่ ตรวจสอบเทมเพลตการแจ้งเตือน",
  "ccm.empty.variables": "ตัวแปรแม่แบบไม่มีอยู่ ตรวจสอบเทมเพลตการแจ้งเตือน",
  "ccm.no.meeting.variables": "ตัวแปรแม่แบบไม่มีตัวแปร Meeting_URL ตรวจสอบเทมเพลตการแจ้งเตือน",
  "ccm.agent.meeting.failed": "ตัวแทนล้มเหลวในการสร้างการประชุม",
  "ccm.agent.link.failed": "ตัวแทนล้มเหลวในการสร้างลิงก์ SMS",
  "ccm.agent.meeting.receiver.check.error": "จํานวนผู้รับต้องไม่เกิน 1 คน",
  "ccm.agent.contact.timeouthungup": "หมดเวลา",
  "ccm.agent.contact.skilltimeouthungup": "คิวทักษะการหมุนหมดเวลา",
  "ccm.agent.leavemessage.opinionempty": "ความคิดเห็นการตรวจสอบเป็นข้อบังคับ",
  "ccm.sms.meeting.link": "ลิงก์การประชุม SMS",
  "ccm.agent.sms.meeting.gateway.commonerror": "ล้มเหลวในการสร้างการประชุมสาย รหัสข้อผิดพลาด: {n}",
  "ccm.agent.sms.meeting.gateway.error": "ล้มเหลวในการสร้างการประชุมสาย รหัสข้อผิดพลาด: {n} อย่าสร้างการประชุมในโหมดปิดเสียง พักสาย หรือหลายฝ่าย",
  "ccm.contactRecordDetail.notSetTraceLogError": "ไม่พบร่องรอยของโพรเซส โปรดเพิ่มการตั้งค่าการติดตามและดูเส้นทางของโพรเซสที่เชื่อมโยงกับโพรเซส",
  "ccm.agent.callreason.modify.exceeds.limit": "ไม่สามารถแก้ไขเหตุผลในการโทรได้ ช่วงเวลาเกินระยะเวลาการใช้งานของเหตุผลการโทร",
  "ccm.label.appcube.switch": "\u0E2B\u0E19\u0E49\u0E32\u0E23\u0E27\u0E21 AppCube",
  "ccm.label.appcube.app": "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E41\u0E2D\u0E1B\u0E1E\u0E25\u0E34\u0E40\u0E04\u0E0A\u0E31\u0E19",
  "ccm.label.appcube.menu": "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E40\u0E21\u0E19\u0E39",
  "ccm.contactitemconfig.label.data.item.sequence.number.seq": "การสั่งซื้อภาคสนาม",
  "ccm.contactitemconfig.label.data.item.add": "สร้างฟิลด์ระเบียนผู้ติดต่อ",
  "ccm.contactitemconfig.label.data.item.edit": "แก้ไขฟิลด์ระเบียนผู้ติดต่อ",
  "ccm.contactitemconfig.label.data.item.tips": "อินเตอร์เฟซแหล่งที่มาของชุดข้อมูลจะต้องกําหนดค่าในหน้าการจัดการอินเตอร์เฟซ ติดต่อผู้ดูแลระบบปฏิบัติการระบบ",
  "ccm.contactitemconfig.label.data.item.basic.item": "ฟิลด์ขยายพื้นฐาน",
  "ccm.contactitemconfig.label.data.item.custom.item": "ฟิลด์ขยายที่ผู้ใช้กําหนด",
  "ccm.contactitemconfig.label.dataInfo.item.add": "สร้างรายการข้อมูล",
  "ccm.contactitemconfig.label.dataInfo.item.edit": "แก้ไขรายการข้อมูล",
  "ccm.custdata.label.data.table.template": "เทมเพลตตารางข้อมูล",
  "ccm.custdata.msg.uploadType": "วิธีการนำเข้าข้อมูลซ้ำ",
  "ccm.custdata.msg.uploadTypeInfo": "หากมีข้อมูลซ้ำ หากคุณเลือกที่จะเขียนทับข้อมูลเดิม บันทึกข้อมูลจะถูกปรับปรุง หากคุณเลือกที่จะไม่เขียนทับข้อมูลเดิม จะถือว่าเป็นข้อมูลที่ล้มเหลว",
  "ccm.custdata.msg.uploadType.cover": "เขียนทับข้อมูลเดิม",
  "ccm.custdata.msg.uploadType.notcover": "ห้ามเขียนทับข้อมูลเดิม",
  "ccm.pageconfig.label.page": "เพจ",
  "ccm.pageconfig.label.publishpage": "เผยแพร่หน้าเพจ",
  "ccm.transtask.label.intelligentInspectionConfig":"AI การตรวจสอบการตั้งค่าการถ่ายโอนข้อมูล",
  "ccm.validate.portLengthError":"ช่วงพอร์ตควรอยู่ที่ 1-65535",
  "ccm.resource.dump.server.add":"สร้างเซิร์ฟเวอร์การถ่ายโอนข้อมูลทรัพยากร",
  "ccm.resource.dump.server.edit":"แก้ไขเซิร์ฟเวอร์การถ่ายโอนข้อมูลทรัพยากร",
  "ccm.resource.dump.server.view":"ดูเซิร์ฟเวอร์การถ่ายโอนข้อมูลทรัพยากร",
  "ccm.resource.dump.css.add":"สร้างเซิร์ฟเวอร์การค้นหาบนคลาวด์",
  "ccm.resource.dump.css.edit":"แก้ไขเซิร์ฟเวอร์การค้นหาบนคลาวด์",
  "ccm.resource.dump.css.view":"ดูเซิร์ฟเวอร์การค้นหาบนคลาวด์",
  "ccm.resource.dump.task.add":"สร้างงานการทิ้งทรัพยากร",
  "ccm.resource.dump.task.edit":"แก้ไขงานการทิ้งทรัพยากร",
  "ccm.resource.dump.task.view":"ดูงานการทิ้งทรัพยากร",
  "ccm.transtask.label.intelligentInspectionDay":"AI ตรวจสอบข้อมูลระยะเวลาการถ่ายโอนข้อมูล",
  "ccm.transtask.css.server.num":"จํานวนของ Cloud Search Server ต้องไม่เกิน 1",
  "ccm.contact.customer.config.num":"จํานวนคอลัมน์ข้อมูลลูกค้าที่ติดต่อต้องไม่เกิน 10.",
  "ccm.export.password.error.tips": "รหัสผ่านของแฟ้มบีบอัดประกอบด้วยอักขระ 8 ถึง 12 ตัว รวมทั้งตัวอักษร ตัวเลข และอักขระพิเศษ {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.export.password.error.message": "ต้องมีตัวอักษร ตัวเลข อักขระพิเศษ {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.agent.ccagentinfo.modifyselfphone.success":"แก้ไขคำพื้นฐาน / หมายเลขโทรศัพท์มือถือสำเร็จ",
  "ccm.agent.ccagentinfo.modifyselfphone.fail":"แก้ไขคำพื้นฐาน/หมายเลขโทรศัพท์มือถือล้มเหลว",
  "ccm.export.table.taskTime": "เวลาของงาน",
  "ccm.export.table.taskStatus": "สถานะงาน",
  "ccm.agent.contact.multimedia.sms":"\u0E21\u0E31\u0E25\u0E15\u0E34\u0E21\u0E35\u0E40\u0E14\u0E35\u0E22-SMS",
  "ccm.agent.label.skill":"\u0E17\u0E31\u0E01\u0E29\u0E30",
  "ccm.agent.button.randomRestSoftPhonePwd":"รีเซ็ตรหัสผ่านของซอฟต์โฟน",
  "ccm.agent.label.skillGroup":"\u0E01\u0E25\u0E38\u0E48\u0E21\u0E04\u0E34\u0E27\u0E17\u0E31\u0E01\u0E29\u0E30",
  "ccm.agent.message.searchSkillGroupName":"ชื่อของกลุ่มคิวทักษะ",
  "ccm.skill.label.skillGroupName":"\u0E0A\u0E37\u0E48\u0E2D\u0E02\u0E2D\u0E07\u0E01\u0E25\u0E38\u0E48\u0E21\u0E04\u0E34\u0E27\u0E17\u0E31\u0E01\u0E29\u0E30",
  "ccm.agent.button.restSoftPhonePwd.remark":"\u0E2B\u0E32\u0E01\u0E04\u0E38\u0E13\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E0B\u0E2D\u0E1F\u0E15\u0E4C\u0E42\u0E1F\u0E19\u0E02\u0E2D\u0E07\u0E40\u0E2D\u0E40\u0E08\u0E19\u0E15\u0E4C\u0E40\u0E1B\u0E47\u0E19\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E41\u0E1A\u0E1A\u0E2A\u0E38\u0E48\u0E21\u0E40\u0E1B\u0E47\u0E19\u0E0A\u0E38\u0E14 \u0E40\u0E2D\u0E40\u0E08\u0E19\u0E15\u0E4C\u0E08\u0E30\u0E15\u0E49\u0E2D\u0E07\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E0B\u0E2D\u0E1F\u0E15\u0E4C\u0E42\u0E1F\u0E19\u0E02\u0E2D\u0E07\u0E15\u0E19\u0E40\u0E2D\u0E07\u0E01\u0E48\u0E2D\u0E19\u0E17\u0E35\u0E48\u0E08\u0E30\u0E43\u0E0A\u0E49\u0E1F\u0E31\u0E07\u0E01\u0E4C\u0E0A\u0E31\u0E19\u0E0B\u0E2D\u0E1F\u0E15\u0E4C\u0E42\u0E1F\u0E19 \u0E1C\u0E39\u0E49\u0E14\u0E39\u0E41\u0E25\u0E23\u0E30\u0E1A\u0E1A\u0E22\u0E31\u0E07\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E04\u0E25\u0E34\u0E01 \u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19 Softphone \u0E43\u0E19\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E2A\u0E4D\u0E32\u0E2B\u0E23\u0E31\u0E1A\u0E15\u0E31\u0E27\u0E41\u0E17\u0E19\u0E44\u0E14\u0E49\u0E14\u0E49\u0E27\u0E22\u0E15\u0E19\u0E40\u0E2D\u0E07",
  "ccm.agent.management.exporting.agent.information":"กําลังส่งออกข้อมูลเอเจนต์",
  "ccm.agent.clear.message.clearcommonerror":"\u0E01\u0E32\u0E23\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E41\u0E15\u0E48\u0E07\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27 \u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccm.contactitemconfig.label.dataInfo.item.name.exist": "มีชื่อรายการข้อมูลอยู่แล้ว.",
  "ccm.agent.button.downloadvrc": "ดาวน์โหลด VRC",
  "ccm.dual.call.callback.url.config":"การกําหนดค่า URL โทรกลับ",
  "ccm.dual.call.callback.url.auth":"อินเทอร์เฟซการเรียกการรับรองความถูกต้องของ URL",
  "ccm.dual.call.callback.url.authType":"โหมดการตรวจสอบสิทธิ์ URL โทรกลับ",
  "ccm.dual.call.callback.url.ita":"การกําหนดค่าพารามิเตอร์ ITA",
  "ccm.dual.call.callback.url.xNumberType":"โหมดการจัดสรรหมายเลข X",
  "ccm.agent.management.select.agent":"การเลือกเอเจนต์ตามรหัสพนักงาน",
  'ccm.password.length.limit.min': 'รหัสผ่านสั้นเกินไป ป้อนอักขระ {min}-{max} ตัว',
  'ccm.password.length.limit.max': 'รหัสผ่านยาวเกินไป ป้อนอักขระ {min}-{max} ตัว',
  "ccm.agent.operation.SCREENMONITOR": "การตรวจสอบหน้าจอ",
  'ccm.mobile.agent.front.add': 'การสร้างเจ้าหน้าที่เคลื่อนที่',
  'ccm.mobile.agent.front.edit': 'แก้ไขเจ้าหน้าที่เคลื่อนที่',
  'ccm.mobile.agent.front.checkNum': 'โปรดเลือกหมายเลขอย่างน้อยหนึ่งหมายเลข',
  'ccm.mobile.agent.front.maxNum': 'หมายเลขต้องไม่เกิน 15',
  'ccm.contactitemconfig.label.contactData.add': 'การสร้างชุดข้อมูลบันทึกรายชื่อผู้ติดต่อ',
  'ccm.contactitemconfig.label.contactData.edit': 'การแก้ไขชุดข้อมูลบันทึกรายชื่อผู้ติดต่อ',
  'ccm.agent.button.downloading':'กําลังดาวน์โหลด',
  'ccm.label.operationexport.nodata': 'ไม่มีบันทึกการดําเนินการที่ตรงตามเงื่อนไข',
  'ccm.label.transferexport.nodata': 'ไม่มีบันทึกการส่งต่อตรงตามเงื่อนไข',
  'ccm.label.leaveexport.nodata': 'ไม่มีบันทึกการพักที่ตรงตามข้อกําหนด',
  'ccm.label.send.whatsapp': 'ส่ง Whatsapp',
  "ccm.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "ชื่อศูนย์บริการลูกค้าต้องไม่มีอักขระพิเศษ",
  'ccm.common.validation.numbers': 'โปรดป้อนเฉพาะตัวเลขเท่านั้น',
  'ccm.common.validation.click.number': 'โปรดคลิกที่หมายเลข',
  'ccm.ccagent.agent.weight.tips': 'ค่าที่มากกว่าจะบ่งบอกถึงระดับความสําคัญที่สูงกว่า เมื่อมีเอเจนต์ที่ไม่ได้ใช้งานหลายคน ระบบจะจัดสรรสายเรียกเข้าไปยังเอเจนต์ที่มีค่าน้ําหนักมากกว่า',
  'ccm.ccagent.skill.weight.tips': 'ค่าที่มากกว่าจะบ่งบอกถึงระดับความสําคัญที่สูงกว่า เมื่อมีสายเรียกเข้าในหลายคิว ระบบจะจัดสรรสายในคิวที่มีน้ําหนักมากกว่าให้กับเอเจนต์ที่ว่าง',
  'ccm.ccagent.batch.modify.special.character.verification': 'ไม่สามารถบรรจุได้’',
  'ccm.resource.dump.obs.cert.warn.tip': 'โปรดติดต่อเจ้าหน้าที่ O&M เพื่อนําเข้าใบรับรองความน่าเชื่อถือ OBS ไปยังไลบรารีใบรับรอง',
  "ccm.audiovideoworkbench.mood" : "อารมณ์",
  "ccm.audiovideoworkbench.intention" : "เจตนา",
  "ccm.audiovideoworkbench.complain" : "ความเอนเอียงที่จะบ่น",
  "ccm.audiovideoworkbench.aiswitch" : "รูปแบบภาษาขนาดใหญ่",
  "ccm.audiovideoworkbench.airecommendation" : "แนะนําอัจฉริยะ",
};